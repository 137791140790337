(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.TopicManagerService
   * @description
   * # TopicService
   * Service in the collaboreApp.
   */
  angular
    .module('collaboreApp')
    .service('DocumentManagerService',
      DocumentManagerService
    );

  /** @ngInject */
  function DocumentManagerService(COLLAB_CONF, COLLAB_VALUES, $q, $window, $rootScope, $injector, $timeout, MainService, DocumentsAjax, ModalsService, TopicManagerService, UtilsService, DocumentResource, CommunicationAjax, ErreurCollabService, ResultDocumentAutonome) {

    var _this = this;

    _this.loadingDocuments = [];

    _this.init = init;
    _this.instanceDoc = instanceDoc;
    _this.getDocumentByGuid = getDocumentByGuid;
    _this.searchDocumentsAutonomes = searchDocumentsAutonomes;

    _this.deleteDocumentPerso = deleteDocumentPerso;
    _this.initListDocumentsByDate = initListDocumentsByDate;
    _this.getDocumentsByContenu = getDocumentsByContenu;
    _this.getListDocumentsByDate = getListDocumentsByDate;
    _this.destroy = destroy;
    _this.getUrlStream = getUrlStream;
    _this.getStream = getStream;
    _this.openDocument = openDocument;
    _this.downloadDocument = downloadDocument;
    _this.downloadSelectedDocument = downloadSelectedDocument;
    _this.downloadAllForContenu = downloadAllForContenu;
    _this.loadingDownload = loadingDownload;

    _this.getTemporaryDocBox = getTemporaryDocBox;

    _this.signeDevis = signeDevis;
    _this.sendDemandeDevisParFax = sendDemandeDevisParFax;
    _this.renameDocument = renameDocument;

    _this.exportTopicToGed = exportTopicToGed;
    _this.sendDocumentToGed = sendDocumentToGed;

    _this.getListDocFromRetourUpload = getListDocFromRetourUpload;

    _this.submitDocumentToOCR = submitDocumentToOCR;

    function postServlet(action, stringModel, obj) {
      var deferred = $q.defer();
      try {
        if (!action) throw new Error('Le paramètre "action" n\'existe pas !');
        if (!_.isObject(obj)) obj = {};

        var sendParams = angular.merge({}, obj);
        sendParams.action = action;

        if(_.isNil(stringModel)) stringModel = 'Document';

        DocumentsAjax
          .post(sendParams, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, ['document', 'result'], stringModel, deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    function getDocumentByGuid(guid){
      var deferred = $q.defer();
      postServlet('getDocumentByGuid', 'Document', {guid: guid})
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    function searchDocumentsAutonomes(guid){
      var deferred = $q.defer();

      var params = {action: 'searchDocumentsAutonomes'};
      if(_.isObject(guid)) params.guid = guid.guid;
      else if(_.isString(guid)) params.guid = guid;

      DocumentsAjax
        .post(params, function (json) {
          if(_.isObject(json)){
            if(json.success) deferred.resolve(new ResultDocumentAutonome(json));
            else deferred.reject(json.message);
          }
          else {
            ErreurCollabService.logErreur(json);
            deferred.reject("Erreur avec le retour de \"searchDocumentsAutonomes\"");
          }
        }, function (msg) {
          deferred.reject(msg);
        });
      return deferred.promise;
    }

    /**
     * @name deleteDocumentPerso
     * @desc Supprime un document
     * @param {Object} document - Objet du fichier
     * @returns {Object} Promesse
     */
    function deleteDocumentPerso(document) {

      var deferred = $q.defer();

      document.deleteDocument(scope.idGroupe).then(function(){

        deferred.resolve();
        // Supprime le document du pool d'instance
        scope._deleteInstance(document.getGuid());

      },function(error){

        deferred.reject(error.msg);
      });

      return deferred.promise;
    }

    function init(sourceTopic){
      _this.documentsByContenu = null;
      _this.loadingDocuments = [];
      if(_.isObject(sourceTopic)) return _this.documentsByContenu = initDocumentsByContenu(sourceTopic);
    }

    function initListDocumentsByDate(sourceTopic){
      init(sourceTopic);
      return getListDocumentsByDate();
    }

    /*
    function loadingDownload(name){
      _this.loadingDocuments[name] = true;
      var onFocus = UtilsService.addEvent($window,'blur',function(){
        $timeout(function(){
          for(var variable in _this.loadingDocuments){
            delete _this.loadingDocuments[variable];
          }
        });
        onFocus.silence();
      });
    }*/

    /**
     * Permet de compter le nombre de documents et de le renseigner dans "nbDocuments" pour chaque objet
     * @param obj
     */
    /*
    function recurseCountDocuments(obj){
      var tabs = Object.keys(obj);
      if((tabs.length === 2 && !_.isNil(obj.documents) && !_.isNil(obj.nbDocuments)) || !_.isNil(obj.libelle)) {
        return;
      }
      tabs.map(function(property) {
        if(_.isObject(obj[property]) && !_.isNil(obj[property].documents)) {
          recurseCountDocuments(obj[property]);
          obj.nbDocuments += obj[property].nbDocuments;
        }
      });
    }*/
    function recurseCountDocuments(obj, prevObj){
      var tabs = _.keys(obj);
      tabs = _.pull(tabs, 'nbDocuments', 'documents');
      if(tabs.length === 0 || !_.isNil(obj.libelle)) {
        return;
      }
      tabs.map(function(property) {
        if(_.isObject(obj[property])) {
          if(_.isArray(obj[property])){
            recurseCountDocuments(obj[property], obj);
          }
          else if(!_.isNil(obj[property].documents)) {
            recurseCountDocuments(obj[property], obj);
            var propertyInt = parseInt(property);
            if(!_.isNaN(propertyInt) && !_.isNil(prevObj.nbDocuments)) {
              prevObj.nbDocuments += obj[property].documents.length;
            }
            else if(!_.isNil(obj.nbDocuments)){
              if(!_.isNil(obj[property].nbDocuments)){
                obj.nbDocuments += obj[property].nbDocuments;
              }
              else if(!_.isNil(obj[property].documents)){
                obj.nbDocuments += obj[property].documents.length;
              }
            }
          }
        }
      });
    }

    /**
     * Permet d'ajouter des document à un objet sous une propriété
     * @param key
     * @param doc
     * @param params
     */
    function addDocumentForIdContenu(key, doc, params){

      if(_.isString(key)) {
        if(_.isNil(_this.documentsByContenu)) _this.documentsByContenu = {nbDocuments: 0};

        var properties = [];

        // Si il y a un point dans le string
        if(_.includes(key, '.')) properties = key.split('.');
        else properties.push(key);

        var objet = properties.reduce(function(accum, property) {
          var isArray = false;
          if(_.endsWith(property, '[]')) {
            property = _.trimEnd(property, '[]');
            isArray = true;
          }
          if(_.isNil(accum[property])) {
            if(isArray) {
              accum[property] = [];
            }
            else {
              accum[property] = {
                documents: [],
                nbDocuments: 0
              };
            }
          }
          return accum[property];
        }, _this.documentsByContenu);

        if(!_.isNil(params)) {
          if(_.isArray(objet)) {
            _.merge(doc, params);
          }
          else if(_.isObject(objet)) {
            _.merge(objet, params);
          }
        }

        if(!_.isNil(doc) && _.isObject(doc)) {
          // Si "object" est un array
          if(_.isArray(objet)) {
            // Si "doc" est un objet contenant une propriété "documents" qui est un array
            // alors ajoute l'objet complet au tableau "objet"
            if(_.isArray(doc.documents)) {
              objet.push(doc);
            }
            // Si "doc" est un objet document alors ajoute le document dans l'array "objet"
            else if(_.isString(doc.guid) || _.isArray(doc)){
              addDocumentInList(doc, objet);
            }
          }
          // Si "object" est un object
          else if(_.isObject(objet)) {
            // Si "doc" est un objet contenant une propriété "documents" qui est un array
            // alors ajoute le nombre de documents au nombre de doc de l'objet
            if(_.isArray(doc.documents)) {
              objet.nbDocuments += doc.documents.length;
            }
            else if(_.isString(doc.guid) || _.isArray(doc)) {
              objet.nbDocuments += addDocumentInList(doc, objet.documents);
            }
          }
        }


      }
    }

    function initDocumentsByContenu(sourceTopic){
      //if(_.isNil(order)) order = 'DESC';

      if(_.isObject(sourceTopic)) {
        var topic = angular.copy(sourceTopic);
        var idTopic = topic.getIdTopic();
        // Si il y a un événement
        if(topic.eventIsPresent()){

          // Si le topic a un document
          if(_.isObject(topic.getDocument())) addDocumentForIdContenu('topic', topic.getDocument());

          //--------------- Evenement -------------------
          var evenement = topic.getEvenement();

          // Si il y a des documents dans l'événement
          if(_.isObject(evenement)) {

            //--------------- Liste doc événement -------------------
            if(_.isArray(evenement.getListeDocument()) && evenement.getListeDocument().length) {
              evenement.getListeDocument().map(function(doc){
                doc.setTmpIdTopic(idTopic);
                return doc;
              });
              addDocumentForIdContenu('evenement', evenement.getListeDocument());

              //_this.documentsByContenu.evenement.documents = orderBy(_this.documentsByContenu.evenement.documents, 'dateUpload', order);
            }
            //--------------- Fin liste doc événement -------------------

            //--------------- Courrier -------------------
            if(evenement.getListeCourrier().length) {
              var courriersByGroupeDestinataire = {};

              // Parcour les courriers
              for(var i = 0; i < evenement.getListeCourrier().length; i++) {
                var courrier = evenement.getListeCourrier()[i];
                var docCourrier = courrier.getDocument();
                if(_.isObject(docCourrier)) {

                  docCourrier.tmpIdTopic = idTopic;

                  // Si pas de type ajoute le type courrier_simple
                  if(_.isNil(docCourrier.getTypeDocument())) docCourrier.setTypeDocument(COLLAB_CONF.TYPE_DOC_ENUMS.COURRIER_SIMPLE);

                  if(_.isObject(courrier.groupeDestinataire) && !_.isNil(courrier.groupeDestinataire.idGroupe)) {
                    if(_.isNil(courriersByGroupeDestinataire[courrier.groupeDestinataire.idGroupe])) {
                      courriersByGroupeDestinataire[courrier.groupeDestinataire.idGroupe] = {
                        groupeDestinataire: courrier.groupeDestinataire,
                        documents: []
                      };
                    }
                    courriersByGroupeDestinataire[courrier.groupeDestinataire.idGroupe].documents.push(docCourrier);
                  }
                  else if(!_.isNil(courrier.nomPrenom)){
                    if(_.isNil(courriersByGroupeDestinataire[courrier.nomPrenom])) {
                      courriersByGroupeDestinataire[courrier.nomPrenom] = {
                        nomPrenom: courrier.nomPrenom,
                        documents: []
                      };
                    }
                    courriersByGroupeDestinataire[courrier.nomPrenom].documents.push(docCourrier);
                  }
                }
              }

              var tabIdGroupeDest = _.keys(courriersByGroupeDestinataire);
              if(tabIdGroupeDest.length) {
                tabIdGroupeDest.map(function(id){
                  addDocumentForIdContenu('courrier.destinataires[]', courriersByGroupeDestinataire[id], {
                    libelle: 'Courrier'
                  });
                });
              }
            }
            //--------------- Fin Courrier -------------------

            //--------------- Sinistre -----------------------
            if(_.isObject(evenement.getSinistre()) && _.isObject(evenement.getSinistre().getInfosSinistre()) && _.isObject(evenement.getSinistre().getInfosSinistre().getDocumentDeclarationSinistre())) {
              var doc = evenement.getSinistre().getInfosSinistre().getDocumentDeclarationSinistre();
              doc.tmpIdContenu = evenement.getSinistre().getIdContenu();
              doc.tmpIdTopic = idTopic;
              if(_.isNil(doc.getTypeDocument())) doc.setTypeDocument(COLLAB_CONF.TYPE_DOC_ENUMS.DECLARATION_SINISTRE);
              // Ajoute le document du courrier à la liste des documents courriers
              addDocumentForIdContenu('sinistre', doc);
            }
            //--------------- Fin Sinistre -------------------

            //--------------- Liste devis ou contrats -----------------------
            if(evenement.getListeDevis().length || evenement.getListeContrats().length){

              //--------------- Liste devis -----------------------
              if(evenement.getListeDevis().length) {
                for(var l = 0; l < evenement.getListeDevis().length; l++) {
                  var devis = evenement.getListeDevis()[l];

                  var arrayType = 'intervention';
                  var objetDocumentsDevis = {
                    idContenu: devis.getIdContenu(),
                    libelle: 'Intervention',
                    destinataire: devis.getDestinataire(),
                    persoICS: devis.getPersoICS(),
                    documents: []
                  };

                  // Si c'est un devis et pas une intervention
                  if(devis.isContenuDevis()) {

                    objetDocumentsDevis.libelle = 'Devis';
                    arrayType = 'devis';

                    // Si il y a un devis
                    if(_.isObject(devis.documentDevis)) {
                      devis.documentDevis.withEtat = true;
                      devis.documentDevis.accepte = false;
                      devis.documentDevis.refuse = false;
                      devis.documentDevis.attente = false;

                      // Si devis accepte
                      if(devis.isAccepte()){
                        devis.documentDevis.accepte = true;
                      }
                      // Si devis en attente
                      else if(devis.isWaiting()){
                        devis.documentDevis.attente = true;
                      }
                      // Si devis refusé
                      else if(devis.isRefuse()){
                        devis.documentDevis.refuse = true;
                      }

                      devis.documentDevis.tmpIdContenu = devis.getIdContenu();
                      devis.documentDevis.tmpIdTopic = idTopic;
                      if(_.isNil(devis.documentDevis.getTypeDocument())) devis.documentDevis.setTypeDocument(COLLAB_CONF.TYPE_DOC_ENUMS.DEVIS);

                      // Ajoute le document devis à la liste des documents du contenu devis
                      addDocumentInList(devis.documentDevis, objetDocumentsDevis.documents);
                    }

                    // Si il y a un devis signé
                    if(_.isObject(devis.documentDevisSigne)) {

                      devis.documentDevisSigne.withEtat = true;
                      devis.documentDevisSigne.signe = true;
                      devis.documentDevisSigne.tmpIdContenu = devis.getIdContenu();
                      devis.documentDevisSigne.tmpIdTopic = idTopic;
                      if(_.isNil(devis.documentDevisSigne.getTypeDocument())) devis.documentDevisSigne.setTypeDocument(COLLAB_CONF.TYPE_DOC_ENUMS.DEVIS_SIGNE);

                      // Ajoute le document devis signe à la liste des documents du contenu devis
                      addDocumentInList(devis.documentDevisSigne, objetDocumentsDevis.documents);
                    }

                  }

                  // Si il y a une facture
                  if(_.isObject(devis.getFacture()) && devis.getFacture().getListeDocument().length){
                    devis.getFacture().getListeDocument().map(function(doc){
                      doc.tmpIdContenu = devis.getFacture().getIdContenu();
                      doc.tmpIdTopic = idTopic;
                      if(_.isNil(doc.getTypeDocument())) doc.setTypeDocument(COLLAB_CONF.TYPE_DOC_ENUMS.FACTURE);
                      return doc;
                    });
                    // Ajoute les documents facture à la liste des documents du contenu devis
                    addDocumentInList(devis.getFacture().getListeDocument(), objetDocumentsDevis.documents);
                  }

                  // Ajout les autres document commun dans les demandes
                  addDocumentsFromContenu(devis, objetDocumentsDevis.documents);

                  //objetDocumentsDevis.documents = orderBy(objetDocumentsDevis.documents, 'dateUpload', order);

                  addDocumentForIdContenu(arrayType + '.contenus[]', objetDocumentsDevis);
                }
              }
              //--------------- Fin Liste devis -----------------------

              //--------------- Liste contrats -----------------------
              if(evenement.getListeContrats().length) {
                // Pour chaque contrat du contenu événement
                for(var c = 0; c < evenement.getListeContrats().length; c++) {
                  var contrat = evenement.getListeContrats()[c];

                  var objetDocumentsContrats = {
                    idContenu: contrat.getIdContenu(),
                    libelle: 'Contrat',
                    destinataire: contrat.getDestinataire(),
                    persoICS: contrat.getPersoICS(),
                    documents: []
                  };

                  // Si il y des contrats signé pas le fournisseur
                  if(contrat.getListDocumentContratFournisseurSigne().length){

                    for(var d = 0; d < contrat.getListDocumentContratFournisseurSigne().length; d++) {
                      var doc = contrat.getListDocumentContratFournisseurSigne()[d];

                      doc.isContrat = true;
                      doc.accepte = false;
                      doc.refuse = false;
                      doc.attente = false;
                      doc.tmpIdContenu = contrat.getIdContenu();
                      doc.tmpIdTopic = idTopic;
                      if(_.isNil(doc.getTypeDocument())) doc.setTypeDocument(COLLAB_CONF.TYPE_DOC_ENUMS.DOC_CONTRAT_FOURNISSEUR_SIGNE);

                      if(doc.isContratFournisseurAccepte())     doc.accepte = true;
                      else if(doc.isContratFournisseurRefuse()) doc.refuse = true;
                      else if(doc.isContratFournisseur())       doc.attente = true;
                    }
                    // Ajoute les documents contrat signé par le fournisseur à la liste des documents du contenu contrat
                    addDocumentInList(doc, objetDocumentsContrats.documents);
                  }

                  // Ajout les autres document commun dans les demandes
                  addDocumentsFromContenu(contrat, objetDocumentsContrats.documents);

                  addDocumentForIdContenu('contrat.contenus[]', objetDocumentsContrats);
                }
              }
              //--------------- Fin Liste contrats -----------------------
            }
            //--------------- Fin de liste devis ou contrats -----------------------
          }
          //--------------- Fin Evenement -------------------

        }

        //--------------- Messages -------------------
        // Si il y a des contenus de type message
        if(_.isArray(topic.getContenusMessage()) && topic.getContenusMessage().length){
          var documents = [];
          for(var m = 0; m < topic.getContenusMessage().length; m++) {
            // Ajout les autres document commun dans les demandes
            addDocumentsFromContenu(topic.getContenusMessage()[m], documents);
          }

          var documentsMessageByCreateur = {};
          if(documents.length){
            documents.map(function(doc) {
              if(_.isNil(documentsMessageByCreateur[doc.groupeCreateur.idGroupe])) {
                documentsMessageByCreateur[doc.groupeCreateur.idGroupe] = {
                  groupeCreateur: doc.groupeCreateur,
                  documents: []
                };
              }
              documentsMessageByCreateur[doc.groupeCreateur.idGroupe].documents.push(doc);
            });
          }

          var tabIdCreateur = _.keys(documentsMessageByCreateur);
          if(tabIdCreateur.length) {
            tabIdCreateur.map(function(id){
              //documentsMessageByCreateur[id].documents = orderBy(documentsMessageByCreateur[id].documents, 'dateUpload', order);
              addDocumentForIdContenu('message.createur[]', documentsMessageByCreateur[id], {
                libelle: 'Message'
              });
            });
          }
        }
        //--------------- Fin Messages -------------------
      }
      recurseCountDocuments(_this.documentsByContenu);
      return _this.documentsByContenu;
    }

    function getDocumentsByContenu(){
      return _this.documentsByContenu;
    }

    function groupDocumentByDate(keyContenu, objetContenus, newObj, doc){

      /*
      console.log(keyContenu);
      console.log(newObj);
      console.log(objetContenus);
      console.log(doc);
      */

      if(_.isObject(newObj) && _.isObject(objetContenus) && _.isObject(doc)) {

        var currentContenu = objetContenus[keyContenu];

        var type = currentContenu.libelle;
        var obj = {};
        if(_.isNil(newObj[type])) obj[type] = {};
        else obj = newObj[type];

        var addDoc = true;
        // Si il y a un destinataire dans l'objet contenu actuel
        if(_.isObject(currentContenu.destinataire)) {

          // Si l'objet de ce type n'a pas encore un objet sous la clé de l'idGroupe du destinataire
          if(_.isNil(obj[currentContenu.destinataire.idGroupe])) {
            obj[currentContenu.destinataire.idGroupe] = {
              groupe: currentContenu.destinataire,
              documents: []
            };
          }
          obj[currentContenu.destinataire.idGroupe].documents.push(doc);
          addDoc = false;
        }

        // Si il y a une persoICS dans l'objet contenu actuel
        if(_.isObject(currentContenu.persoICS)) {
          obj[currentContenu.destinataire.idGroupe].persoICS = currentContenu.persoICS;
        }

        if(addDoc) {
          if(!_.isArray(obj.documents)) obj.documents = [];
          obj.documents.push(doc);
        }

        newObj[type] = obj;
      }
      return newObj;
    }

    function recurseListDocumentsByDate(objetContenus, tab){

      if(_.isObject(objetContenus) && _.isArray(objetContenus.documents)) {
        objetContenus.documents.map(function(doc){
          addDocumentInList(doc, tab);
        });
      }

      var tabs = _.keys(objetContenus)
      tabs = _.pull(tabs, 'nbDocuments', 'documents', 'nbDocuments');
      tab = tabs.reduce(function(arrayAccum, keyContenu) {
        if(_.isObject(objetContenus[keyContenu])) {
          if(_.isArray(objetContenus[keyContenu])){
            objetContenus[keyContenu].map(function(item){
              arrayAccum = recurseListDocumentsByDate(item, arrayAccum);
            });
          }
          if(_.isArray(objetContenus[keyContenu].documents)) {
            objetContenus[keyContenu].documents.map(function(doc){
              addDocumentInList(doc, arrayAccum);
            });
          }
          if(!_.isArray(objetContenus[keyContenu]) && (!_.isNil(objetContenus[keyContenu].documents) || !_.isNil(objetContenus[keyContenu].nbDocuments)))
            arrayAccum = recurseListDocumentsByDate(objetContenus[keyContenu], arrayAccum);
        }
        return arrayAccum;
      }, tab);
      return tab;
    }

    function getListDocumentsByDate(){
      var results = [];
      var objetContenus = getDocumentsByContenu();
      if(_.isObject(objetContenus)) {
        results = recurseListDocumentsByDate(objetContenus, []);
        /*
        if(order === 'ASC') results.sort(function(a,b){return new Date(a.dateUpload).getTime() - new Date(b.dateUpload).getTime()});
        else results.sort(function(a,b){return new Date(b.dateUpload).getTime() - new Date(a.dateUpload).getTime()});*/
      }
      return results;
    }

    /**
     * Permet d'ajouter les documents d'un contenu demande
     * @param demande
     * @param objetDocumentsDevis
     */
    function addDocumentsFromContenu(demande, listDocs){

      // Si le contenu à des documents
      if(_.isArray(demande.getListeDocument()) && demande.getListeDocument().length){
        demande.getListeDocument().map(function(doc){
          doc.tmpIdContenu = demande.getIdContenu();
          doc.tmpIdTopic = demande.getIdTopic();
          return doc;
        });
        // Ajoute les documents à la liste des documents du contenu devis
        addDocumentInList(demande.getListeDocument(), listDocs);
      }

      // Si il y a des documents envoyé par le fournisseur
      if(_.isArray(demande.getListeDocumentFournisseur()) && demande.getListeDocumentFournisseur().length){
        demande.getListeDocumentFournisseur().map(function(doc){
          doc.tmpIdContenu = demande.getIdContenu();
          doc.tmpIdTopic = demande.getIdTopic();
          if(_.isNil(doc.getTypeDocument())) doc.setTypeDocument(COLLAB_CONF.TYPE_DOC_ENUMS.DOC_FOURNISSEUR);
          return doc;
        });
        // Ajoute les documents fournisseur à la liste des documents du contenu devis
        addDocumentInList(demande.getListeDocumentFournisseur(), listDocs);
      }

      // Si il y a des documents comptable
      if(_.isArray(demande.getListeDocumentComptable()) && demande.getListeDocumentComptable().length){
        demande.getListeDocumentComptable().map(function(doc){
          doc.tmpIdContenu = demande.getIdContenu();
          doc.tmpIdTopic = demande.getIdTopic();
          return doc;
        });
        // Ajoute les documents comptable à la liste des documents du contenu devis
        addDocumentInList(demande.getListeDocumentComptable(), listDocs);
      }
    }

    /**
     * Ajoute un document à l'objet des documents si il n'existe pas déjà
     * @param type
     * @param document
     * @returns {*}
     */
    function addDocumentInList(doc, list) {
      var nbAdd = 0;
      if(_.isObject(doc) && _.isArray(list)) {

        if(!_.isArray(doc)){
          doc = instanceDoc(doc);
        }
        else if(_.isArray(doc) && doc.length) {
          for(var d = 0; d < doc.length; d++) {
            doc[d] = instanceDoc(doc[d]);
          }
        }

        if(_.isArray(doc)) {
          for(var j = 0; j < doc.length; j++) {
            nbAdd += addDocumentIfNotExist(doc[j], list);
          }
        }
        else nbAdd += addDocumentIfNotExist(doc, list);
      }
      return nbAdd;
    }

    /**
     * Ajoute un document dans une liste
     * @param doc
     * @param list
     */
    function addDocumentIfNotExist(doc, list){
      var nbAdd = 0;
      if(_.isObject(doc) && !_.isArray(doc) && _.isArray(list)) {
        var exist = false;
        for(var i = 0 ;i < list.length ; i++){
          if(list[i].guid === doc.guid){
            exist = true;
            break;
          }
        }
        if(!exist) {
          list.push(doc);
          nbAdd++;
        }
      }
      return nbAdd;
    }

    /**
     * Permet d'instancier un document en Document
     * @param doc
     * @returns {*}
     */
    function instanceDoc(doc){
      if(!_.isArray(doc) && _.isObject(doc) && !doc.isModel){
        var Document = $injector.get('Document');
        doc = new Document(doc);
        Document = null;
      }
      return doc;
    }

    /**
     * Permet de récupérer un guid ou un idContenu
     * @param guidOrIdContenu
     * @returns {{guid: null, idContenu: null}}
     */
    function extractGuidOrIdContenu(guidOrIdContenu, onlySelected){
      var retour = {
        guid: null,
        idContenu: null
      };
      // Guid
      if(_.isString(guidOrIdContenu)) retour.guid = guidOrIdContenu;
      // idContenu
      else if(_.isNumber(guidOrIdContenu)) retour.idContenu = guidOrIdContenu;
      // Document ou Contenu
      else if(_.isObject(guidOrIdContenu)){
        // Document
        if(!_.isNil(guidOrIdContenu.guid)) {
          retour.guid = (_.isNil(onlySelected) || (onlySelected && guidOrIdContenu.selected)) ? guidOrIdContenu.guid : null;
          if(!_.isNil(onlySelected) && onlySelected){
            guidOrIdContenu.selected = false;
            guidOrIdContenu.checked = false;
          }
        }
        // Contenu
        else if(!_.isNil(guidOrIdContenu.idContenu)) retour.idContenu = guidOrIdContenu.idContenu;
      }
      return retour;
    }

    /**
     *
     * @param action
     * @param {Document|String|Document[]|String[]} guid
     * @param idTopic
     * @param compress
     * @returns {string}
     */
    function generateUrlDocumentServlet(action, guidIdContenuOrObject, idContenu, idTopic, thumbnail, onlySelected){
      if(_.isNil(action)) throw new Error("Il manque l'action");
      if(action != "stream" && action != "download") throw new Error("L'action doit être \"stream\" ou \"download\"");

      var url = [];
      url.push(COLLAB_VALUES.CONF_URL.PATH_TOMCAT);
      url.push(COLLAB_CONF.SERVLET_DOCUMENT);
      url.push("?action=" + action);
      if(_.isArray(guidIdContenuOrObject)){
        var guids = [];
        var contenus = [];
        for(var i = 0; i < guidIdContenuOrObject.length; i++){
          var d = guidIdContenuOrObject[i];
          var retour = extractGuidOrIdContenu(d, onlySelected);
          if(!_.isNil(retour.guid)) guids.push("guids[]=" + retour.guid);
          else if(!_.isNil(retour.idContenu)) contenus.push("contenu[]=" + retour.idContenu);
        }
        if(!guids.length && !contenus.length) throw new Error("Aucun guid ou idContenu trouvé", guidIdContenuOrObject);
        if(guids.length) url.push("&" + guids.join("&"));
        else if(contenus.length) url.push("&" + contenus.join("&"));
      }
      else {
        var retour = extractGuidOrIdContenu(guidIdContenuOrObject, onlySelected);
        if(_.isNil(retour.guid) && _.isNil(retour.idContenu)) throw new Error("Aucun guid ou idContenu trouvé", guidIdContenuOrObject);
        if(!_.isNil(retour.guid)) url.push("&guid=" + retour.guid);
        else if(!_.isNil(retour.idContenu)) url.push("&contenu=" + retour.idContenu);
      }

      if(!_.isNil(idContenu)) url.push("&idContenu=" + idContenu);
      if(!_.isNil(idTopic)) url.push("&idTopic=" + idTopic);
      if(!_.isNil(thumbnail) && thumbnail) url.push("&thumbnail=true");
      url.push("&token=" + $rootScope.token);

      return url.join("");
    }

    /**
     * Permet de récupérer une url du stream d'un document
     * @param guid
     * @param idContenu
     * @param idTopic
     * @returns {string}
     */
    function getUrlStream(guid, idContenu, idTopic, thumbnail){
      return generateUrlDocumentServlet("stream", guid, idContenu, idTopic, thumbnail);
    }

    /**
     * Permet d'avoir le stream du document
     * @param {String} guid - guid du fichier
     * @param idTopic
     */
    function openDocument(guid, idContenu, idTopic){
      $window.open(getUrlStream(guid, idContenu, idTopic));
    }

    /**
     * Recupère un PDF avec son guid en arrayBuffer et le converti en URL
     * @param guid
     * @param idContenu
     * @param idTopic
     * @returns {*}
     */
    function getStream(guid, idContenu, idTopic) {
      var deferred = $q.defer();

      var docResource = new DocumentResource();
      docResource.guid = guid;
      docResource.idContenu = idContenu;
      docResource.idTopic = idTopic;
      docResource.$stream()
        .then(function(retpdf) {
          var url = window.URL || window.webkitURL || window.mozURL;
          var retour = url.createObjectURL(retpdf.data);
          deferred.resolve(retour);
        })
        .catch(function(msg){
          if(msg.status === 404) deferred.reject('Document Introuvable');
          else deferred.reject(msg);
        });
      return deferred.promise;
    }

    /**
     * Permet de télécharger un ou plusieur document
     * @param {String|Array} guid - guid du fichier
     * @param idContenu
     * @param idTopic
     */
    function downloadDocument(guid, idContenu, idTopic){
      $window.location.href = generateUrlDocumentServlet("download", guid, idContenu, idTopic);
    }

    /**
     * Télécharge tous les document qui sont selected
     * @param guid
     * @param idContenu
     * @param idTopic
     */
    function downloadSelectedDocument(guid, idContenu, idTopic){
      $window.location.href = generateUrlDocumentServlet("download", guid, idContenu, idTopic, null, true);
    }

    /**
     * Télécharge tous les document d'un ou plusieur contenu
     * @param itemContenu
     * @param property
     */
    function downloadAllForContenu(itemContenu, property, idContenu, idTopic){
      var url = generateUrlDocumentServlet('download', itemContenu, idContenu, idTopic);
      if(!_.isNil(property) && property === 'listeDocumentFournisseur') url += 'propertyListDocument=docFournisseur&';
      $window.location.href = url;
    }

    function hideLoading() {
      for(var variable in _this.loadingDocuments){
        delete _this.loadingDocuments[variable];
      }
    }

    function loadingDownload(name){
      _this.loadingDocuments[name] = true;
      angular.element($window).one('focus', hideLoading);
    }

    function destroy() {
      init();
    }

    function getTemporaryDocBox(){
      var deferred = $q.defer();
      postServlet('getTemporaryDocBox', 'TemporaryDocBox')
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    function signeDevis(objParams){

      var deferred = $q.defer();
      var bpa,date,tampon,contenu,page,coordonnees,sendMail,sendMailWhenAcceptOrDeclineDevis;

      if(objParams.hasOwnProperty('bpa'))                                             bpa = objParams.bpa;
      if(objParams.hasOwnProperty('date'))                                            date = objParams.date;
      if(objParams.hasOwnProperty('tampon') && _.isObject(objParams.tampon))    tampon = objParams.tampon.idTampon;
      if(objParams.hasOwnProperty('contenu') && _.isObject(objParams.contenu))  contenu = objParams.contenu.idContenu;
      if(objParams.hasOwnProperty('page'))                                            page = objParams.page;
      if(objParams.hasOwnProperty('coordonnees'))                                     coordonnees = objParams.coordonnees;
      if(objParams.hasOwnProperty('sendMail'))                                        sendMail = objParams.sendMail;
      if(objParams.hasOwnProperty('sendMailWhenAcceptOrDeclineDevis'))                sendMailWhenAcceptOrDeclineDevis = objParams.sendMailWhenAcceptOrDeclineDevis;

      var params = {
        idContenu: contenu
      };

      if(bpa)         params.bpa = bpa;
      if(date)        params.date = date;
      if(tampon)      params.idTampon = tampon;
      if(page)        params.page = page;
      if(coordonnees) params.coordonnees = coordonnees;
      if(sendMail)    params.sendMail = sendMail;
      if(sendMailWhenAcceptOrDeclineDevis)    params.sendMailWhenAcceptOrDeclineDevis = sendMailWhenAcceptOrDeclineDevis;
      //console.log(page);
      //console.log(coordonnees);

      DocumentsAjax.signeDevis(params,function(json){
          if(json.nb!=='0') deferred.resolve(json.Documents[0]);
          else deferred.reject('Pas de doc créé');

        },
        function(error){
          var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
          deferred.reject('Problème avec DocumentManagerService.signeDevis(), '+msgErreur);
        });

      return deferred.promise;
    }

    function sendDemandeDevisParFax(idContenu, numeros){
      var deferred = $q.defer();

      var params = {
        idContenu: idContenu,
        numeros: numeros
      };

      CommunicationAjax.sendFax(params,function(json){
        deferred.resolve(json);
      },function(error){
        ErreurCollabService.erreurServlet(error,'Erreur avec DocumentManagerService.sendDemandeDevisParFax()',deferred);
      });

      return deferred.promise;
    }

    /**
     * Permet de renommer un document
     * @param document
     */
    function renameDocument(document, topic){

      ModalsService
        .input('Renommer '+document.getNomFichier(), 'Nouveau nom: ', 'input', {inputValue: document.getNom(), btnConfirmText: 'Oui !'})
        .then(function onSuccess(retour){

          if (retour.inputValue === false){
            return false;
          }
          if (retour.inputValue === '') {
            retour.sweet.showInputError('Le nom ne peux pas être vide');
            return false;
          }
          // Renomme le document
          document
            .rename(retour.inputValue)
            .then(function(){
              retour.close();
              TopicManagerService.recurseUpdateDocumentInTopic(document, topic);
              $rootScope.$broadcast('refreshListContenus', true);
            })
            .catch(function(msg){
              ModalsService.alertErreur(msg);
            });
        });
    }

    /**
     *
     * @param topic
     * @returns {*}
     */
    function exportTopicToGed(topic){
      var deferred = $q.defer();
      try {
        if(!_.isObject(topic)) throw new Error("Pas de topic !");

        var evenement = topic.getEvenement();
        var portefeuille = topic.getPortefeuille();
        var infosEvenement = UtilsService.getInfosEvenement(evenement);
        if(_.isNil(portefeuille) && _.isObject(infosEvenement)) portefeuille = infosEvenement.portefeuille;

        if(!_.isObject(portefeuille)) throw new Error("Pas de portefeuille trouvé pour le dossier " + topic.idTopic);

        // Si la ged n'est pas activé
        if(!portefeuille.getCanSendToGed()) throw new Error("La ged n'est pas activée");

        // Si pas dévénement
        if(!_.isObject(evenement)) throw new Error("Pas d'événement trouvé dans ce dossier");

        var params = {
          idTopic: topic.getIdTopic(),
          documentTopic: topic.getDocument(),
          hasMessage: (topic.getContenusMessage().length > 0),
          hasSinistre: topic.hasSinistre(),
          hasCourriers: (evenement.getListeCourrier().length > 0),
          hasPieceJointe: (evenement.getListeDocument().length > 0),
          hasDevisOuIntervention: (evenement.getListeDevis().length > 0),
          hasDemandesContrats: (evenement.getListeContrats().length > 0)
        };

        // Si GedV2
        if(portefeuille.getGedV2Enabled()){

          params.exportTopic = true;

          // Emit de "sendGedV2" sans fermer la modal
          params.onEmit = function(emitFromSendGedV2){
            //console.log("DocumentManager.exportTopicToGed onEmit modalComponent sendGedV2", emitFromSendGedV2);
            if(_.isObject(emitFromSendGedV2) && _.isObject(emitFromSendGedV2.obj)){
              if(_.isObject(emitFromSendGedV2.obj.docsAndTopic) && _.isObject(emitFromSendGedV2.obj.docsAndTopic.topic)){
                deferred.notify({'topic': emitFromSendGedV2.obj.docsAndTopic.topic});
                //TopicManagerService.recurseUpdateDocumentInTopic(emitFromSendGedV2.obj.docsAndTopic.topic.documents, topic);
              }
            }
          };

          var options = {
            size: 'full',
            backdrop: 'static'
          };

          ModalsService
            .modalComponent('sendGedV2', params, options)
            .then(function () {
              //console.log("DocumentManager.exportTopicToGed then modalComponent sendGedV2", e);
              deferred.resolve();
            })
            .catch(function (msg) {
              //console.log("DocumentManager.exportTopicToGed catch modalComponent sendGedV2", msg);
              if (msg !== 'cancel') deferred.reject(msg);
              else deferred.resolve();
            });
        }
        // Si GedV1
        else if(portefeuille.getGedV1Enabled()){

          params.hasDevisSigne = evenement.hasAnySignedDevis();
          params.idEvenement = evenement.getIdContenu();
          params.tagImmeuble = evenement.getTagForType('Immeuble');
          params.tagMandat = evenement.getTagForType('Mandat');
          params.portefeuille = portefeuille;

          ModalsService
            .modalComponent('modalSendGed', params, {backdrop: 'static'})
            .then(function (topic) {
              deferred.resolve(topic);
            })
            .catch(function (msg) {
              if (msg !== 'cancel') deferred.reject(msg);
              else deferred.resolve();
            });
        }
      }
      catch(e) {
        ModalsService.alertErreur(e);
        deferred.reject();
      }
      return deferred.promise;
    }

    function sendDocumentToGed(document, topic, folderGed){
      var deferred = $q.defer();
      try {
        if(!document) throw new Error("Pas de document !");

        if(_.isNil(topic) || !topic) topic = TopicManagerService.currentTopicStates.getTopic();

        var evenement = topic.getEvenement();
        var portefeuille = topic.getPortefeuille();
        var infosEvenement = UtilsService.getInfosEvenement(evenement);
        if(_.isNil(portefeuille) && _.isObject(infosEvenement)) portefeuille = infosEvenement.portefeuille;

        if(!_.isObject(portefeuille)) throw new Error("Pas de portefeuille trouvé pour le dossier " + topic.idTopic);

        // Si la ged n'est pas activé
        if(!portefeuille.getCanSendToGed()) throw new Error("La ged n'est pas activée");

        var params = {
          documents: (!_.isArray(document)) ? [document] : document,
          idTopic: topic.getIdTopic(),
          onEmit: function(emitFromSendGed){
            if(_.isObject(emitFromSendGed) && _.isObject(emitFromSendGed.obj) && _.isArray(emitFromSendGed.obj.documents)){
              TopicManagerService.recurseUpdateDocumentInTopic(emitFromSendGed.obj.documents, topic);
              $rootScope.$broadcast('refreshListContenus');
              $rootScope.$broadcast('refreshDocBox');
            }
          }
        };

        // Si GedV2
        if(portefeuille.getGedV2Enabled()){

          if(!_.isNil(folderGed)) params.folderGed = folderGed;

          var options = {
            size: 'full',
            backdrop: 'static'
          };

          ModalsService
            .modalComponent('sendGedV2', params, options)
            .then(function () {
              deferred.resolve();
            })
            .catch(function (msg) {
              if (msg !== 'cancel') ModalsService.alertErreur(msg);
              else deferred.resolve();
            });
        }
        // Si GedV1
        else if(portefeuille.getGedV1Enabled()){
          // TODO: Viré la vérification de l'événement car maintenant avec le portefeuille dans la topicInfo plus besoin, d'un évenement
          if(!_.isObject(evenement)) throw new Error("Pas d'événement trouvé dans ce dossier");

          params.idEvenement = evenement.getIdContenu();
          params.numImmeuble = infosEvenement.numImmeuble;
          params.numMandat = infosEvenement.numMandat;
          params.idPortefeuille = portefeuille.idPortefeuille;

          ModalsService
            .modalComponent('sendGed', params, {backdrop: 'static'})
            .then(function () {
              deferred.resolve();
            })
            .catch(function (msg) {
              if (msg !== 'cancel') ModalsService.alertErreur(msg);
              else deferred.resolve();
            });
        }
      }
      catch(e) {
        ModalsService.alertErreur(e.message);
        deferred.reject();
      }
      return deferred.promise;
    }

    /**
     * Permet de récupérer une liste de Document venant du retour d'un upload
     * @param obj
     * @returns {null|*}
     */
    function getListDocFromRetourUpload(obj){
      if(!_.isObject(obj) || !_.isObject(obj.response)) return null;
      var listDoc =  null;
      var reponse = obj.response;
      if ((!_.isNil(reponse.nb) && reponse.nb !== '0') || (_.isArray(reponse.result) && reponse.result.length)) {
        if(_.isArray(reponse.Documents))   listDoc = reponse.Documents;
        else if(_.isArray(reponse.result)) listDoc = reponse.result;
      }
      if(!_.isArray(listDoc) || (_.isArray(listDoc) && _.isEmpty(listDoc))) return null;

      return listDoc.map(function(doc){
        return instanceDoc(doc);
      });
    }

    /**
     * Permet de soumettre un document à l'OCR
     * @param guid
     * @returns {*}
     */
    function submitDocumentToOCR(guidOdDocument, force){
      var deferred = $q.defer();
      try {
        if(_.isNil(guidOdDocument) || (_.isObject(guidOdDocument) && !guidOdDocument.isDocument)) throw new Error("Le paramètre doit être un guid ou un Document");
        var guid = guidOdDocument;
        var params = {
          guid: guid
        };
        if(_.isObject(guidOdDocument)) params.guid = guidOdDocument.guid;
        if(!_.isNil(force) && force) params.forceRetry = true;

        postServlet('submitDocumentToOCR', 'Document', params)
          .then(function (ret) {
            deferred.resolve(ret);
          })
          .catch(function (reason) {
            deferred.reject(reason);
          });
      }
      catch(e) {
        ModalsService.alertErreur(e);
        deferred.reject();
      }
      return deferred.promise;
    }
  }
})();
