(function () {
  'use strict';

  /**
   * @desc Composant widget de liste des documents
   * @example <documents-widget></documents-widget>
   */

  angular
    .module('collaboreApp')
    .component('documentsWidget', {
      templateUrl: 'app/topics/components/documents-widget/documents-widget.component.html',
      bindings: {
        //documents: '<',
        sidebar: '<',
        onEmit: '&',

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<'
      },
      controllerAs: 'documentswidgetctrl',
      controller: DocumentsWidgetController
    });

  /*@ngInject*/
  function DocumentsWidgetController($rootScope, $timeout, $q, $filter, $scope, sweet, ConfigService, ModalsService, ContenuService, ErreurCollabService, DocumentManagerService, UtilsService, TopicManagerService) {

    var _this = this;
    _this.loading = true;
    _this.moment = moment;
    _this.open = true;

    _this.canSendToGed = false;
    _this.eventModifiable = false;

    _this.listByDate = [];
    _this.options = {
      showTypeDoc: true,
      showListByDate: false,
      orderBy: 'nom'
    };

    _this.topicManagerService = TopicManagerService;
    _this.documentManagerService = DocumentManagerService;
    _this.onClickClose = onClickClose;

    _this.countDocFiltered = countDocFiltered;
    _this.onClickShowFlatList = onClickShowFlatList;
    _this.onClickTriListBy = onClickTriListBy;
    _this.onClickTriListByNom = onClickTriListByNom;
    _this.onClickTriListByDate = onClickTriListByDate;

    _this.onCheckOption = onCheckOption;

    _this.onClickOpenCategorie = onClickOpenCategorie;
    _this.onChangeDocumentCheckbox = onChangeDocumentCheckbox;
    _this.filtreDocument = filtreDocument;
    _this.onClickOpenDocument = onClickOpenDocument;
    _this.onClickDownloadDocument = onClickDownloadDocument;
    //_this.canRename = canRename;
    _this.onClickRenameDocument = onClickRenameDocument;

    _this.onClickSendDocumentToGed = onClickSendDocumentToGed;
    _this.startDrag = startDrag;
    //_this.onEmitFromLigneDocumentWidgetComponent = onEmitFromLigneDocumentWidgetComponent;

    _this.documentsByContenu = null;
    _this.categoriesOpen = {};

    // Champ de recherche
    _this.search = {
      actif: false,
      inputSearch: '',
      currentSearch: {},
      submitSearch: function () {
        if (this.inputSearch !== '') {
          this.actif = true;
          this.currentSearch.nom = angular.copy(this.inputSearch);
        }
      },
      clearFilter: function () {
        this.actif = false;
        this.inputSearch = '';
        this.currentSearch = {};
      }
    };

    _this.$onInit = function onInit() {
      /*
      if (_this.resolve && _this.modalInstance) {
          _this.documents = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'documents'));
      }*/
      init();

      $scope.$watch('documentswidgetctrl.topicManagerService.currentTopicStates', function(newVal, oldVal) {
        if(_.isObject(newVal)) {

          var evenement = newVal.getTopic().getEvenement();
          _this.hasEvenement = (evenement);
          _this.canSendToGed = TopicManagerService.currentTopicHasGed();
          _this.canSendToGedV2 = TopicManagerService.currentTopicHasGedV2Enabled();
          _this.eventModifiable = TopicManagerService.currentTopicIsModifiableByAgence();

          if(_.isNil(_this.documentsByContenu)) initDocuments(newVal.getTopic());
        }
        else{
          _this.hasEvenement = false;
          _this.canSendToGed = false;
          _this.canSendToGedV2 = false;
          _this.eventModifiable = false;
        }
      }, true);

      $scope.$on('refreshDocumentsWidget', function (ev, topicStates) {
        _this.loading = true;
        $timeout(function(){
          if(_.isNil(topicStates)) initDocuments();
          else initDocuments(topicStates.topic);
        });
      });
    };

    _this.$onChanges = function onChanges(changes){
      /*
      if (_.isObject(changes.documents) && !changes.documents.isFirstChange()){
        init(changes.documents.currentValue);
      }*/
    };

    _this.$onDestroy = function onDestroy(){
      DocumentManagerService.destroy();
    };

    function init(){
      _this.agenceICS = UtilsService.getParametrage('agenceICS');

      initPreferenceOptionWidgetDocument();
      _this.sendToComfactEnable = UtilsService.getParametrageComfact('allowSendToComfactfacture');

      // Tableau des documents après filtrage
      _this.documentFiltered = false;

      // Liste des documents selectionné avec la checkbox
      _this.documentsSelected = [];
      _this.documentsSelectedGed = [];

      if(_this.sidebar){
        _this.configScrollbar = {
          autoHideScrollbar: false,
          theme: 'dark-thin',
          advanced: {
            updateOnContentResize: true
          },
          scrollButtons: {
            scrollAmount: 'auto', // scroll amount when button pressed
            enable: false // enable scrolling buttons by default
          },
          axis: 'y', // enable 2 axis scrollbars by default
          setHeight: 200,
          scrollInertia: 100
        };
      }
    }

    /**
     * Initialisation des documents du widget
     * @param topic
     */
    function initDocuments(topic){
      /*
      _this.loading = true;
      WorkerService.processData(_this.topicManagerService.currentTopicStates.getTopic()).then(function(data){
        console.log(data);
        _this.loading = false;
      });*/

      if(!topic) topic = _this.topicManagerService.currentTopicStates.getTopic();
      DocumentManagerService.init(topic);
      getListDocuments();
      //$rootScope.$broadcast('refreshDocBox');
      _this.loading = false;
    }

    function getListDocuments(){
      if(_this.options.showListByDate) {
        _this.documentsByContenu = null;
        _this.listByDate = DocumentManagerService.getListDocumentsByDate();
        initSelectedDocument(_this.listByDate);
      }
      else {
        _this.documentsByContenu = DocumentManagerService.getDocumentsByContenu();
        _this.listByDate = null;
        initSelectedDocument(_this.documentsByContenu);
      }
    }

    function initSelectedDocument(obj){
      if(_this.documentsSelected.length) {
        _this.documentsSelected.map(function(doc){
          recurseSelectedDocument(doc, obj);
        });
      }
    }

    function recurseSelectedDocument(doc, obj){
      if(_.isObject(obj)) {
        if(_.isArray(obj)) {
          obj.map(function (item){
            recurseSelectedDocument(doc, item);
          });
        }
        else {
          if(_.isObject(obj) && obj.isModel && obj.model === 'Document') {
            if(obj.getGuid() === doc.getGuid() && obj.getNomFichier() === doc.getNomFichier()) {
              obj.selected = true;
              obj.$$hashKey = doc.$$hashKey;
            }
          }
          else {
            Object.keys(obj).map(function (property){
              recurseSelectedDocument(doc, obj[property]);
            });
          }
        }
      }
    }

    /**
     * Permet de setter les preférences du widget de document
     * @param val
     */
    function initPreferenceOptionWidgetDocument(){
      _this.customPreference = ConfigService.getPreferencesCustom();
      if(_.isObject(_this.customPreference) && _.isObject(_this.customPreference.optionsWidgetDocument)) {
        _this.options = _this.customPreference.optionsWidgetDocument;
      }
    }

    /**
     * Permet de setter les preférences du widget de document
     * @param val
     */
    function setPreferenceOptionWidgetDocument(val){
      _this.customPreference = ConfigService.getPreferencesCustom();
      if(!_.isObject(_this.customPreference)) _this.customPreference = {};
      _this.customPreference.optionsWidgetDocument = val;
      ConfigService.setPreferencesCustom(_this.customPreference);
    }

    /**
     * Permet de compter le nombre de document dans un objet. Groupé par objet ou non
     * @param tab
     * @param group
     * @returns {number}
     */
    function countDocFiltered(tab, group){
      var nb = 0;
      if(_.isArray(tab)) {
        if(group) nb = (tab.length) ? 1 : 0;
        else nb = tab.length;
      }
      else if(_.isObject(tab)) {
        nb = _.keys(tab).reduce(function(nbTotal, key) {
          nbTotal += countDocFiltered(tab[key], group);
          return nbTotal;
        }, nb)
      }
      return nb;
    }

    /**
     * Lors du clique sur le bouton pour voir la liste des documents version à plat
     */
    function onClickShowFlatList(){
      _this.options.showListByDate = !_this.options.showListByDate;
      setPreferenceOptionWidgetDocument(_this.options);
      getListDocuments();
    }

    /**
     * Lors du clique sur le bouton trier selon loeparamètre (pas utilisé)
     */
    function onClickTriListBy(val){
      _this.options.orderBy = val;
      setPreferenceOptionWidgetDocument(_this.options);
    }

    /**
     * Lors du clique sur le bouton pour trier par nom ascendant ou descendant
     */
    function onClickTriListByNom(){
      if(!_.isNil(_this.options.orderBy)) {
        if(_this.options.orderBy === 'nom') _this.options.orderBy = '-nom';
        else _this.options.orderBy = 'nom';
      }
      else _this.options.orderBy = '-nom';
      setPreferenceOptionWidgetDocument(_this.options);
    }

    /**
     * Lors du clique sur le bouton pour trier par date upload croissante ou décroissante
     */
    function onClickTriListByDate(){
      if(!_.isNil(_this.options.orderBy)) {
        if(_this.options.orderBy === 'dateUpload') _this.options.orderBy = '-dateUpload';
        else _this.options.orderBy = 'dateUpload';
      }
      else _this.options.orderBy = '-dateUpload';
      setPreferenceOptionWidgetDocument(_this.options);
    }

    function onCheckOption(type){
      setPreferenceOptionWidgetDocument(_this.options);
    }

    /**
     * Lors du clique sur le bouton pour ouvrire une categorie de document
     */
    function onClickOpenCategorie(cat) {
      _this.categoriesOpen[cat] = !_this.categoriesOpen[cat];
    }

    function filtreDocument(categorie) {
      var retour = false;
      for (var i = 0; i < categorie.documents.length; i++) {
        retour = UtilsService.test(categorie.documents[i].nom, _this.search.currentSearch, 'ig');
        if (retour) {
          break;
        }
      }
      return retour;
    }

    // ctrl.close({$value: {action: 'addMessage', message: obj.message}});

    function onClickClose(){
      close();
    }

    /**
     * Lors du changement d'état de la checkbox du document
     * @param doc
     */
    function onChangeDocumentCheckbox(doc) {
      if (!doc.selected) {
        doc.selected = true;
        _this.documentsSelected.push(doc);

        // Si Gev2 alors on ajoute le doc seulement si il n'est pas déjà en ged
        if(_this.canSendToGedV2 && !doc.isSentToGed()) _this.documentsSelectedGed.push(doc);
        // Si en gedv1
        else if(_this.canSendToGed && !_this.canSendToGedV2) _this.documentsSelectedGed.push(doc);
      }
      else {
        doc.selected = false;

        _.remove(_this.documentsSelected, function(item){
          if(item.guid === doc.guid) return true;
          return false;
        });
        _.remove(_this.documentsSelectedGed, function(item){
          if(item.guid === doc.guid) return true;
          return false;
        });

        //_this.documentsSelected = UtilsService.removeWith(_this.documentsSelected, {$$hashKey: doc.$$hashKey});
        //_this.documentsSelectedGed = UtilsService.removeWith(_this.documentsSelectedGed, {$$hashKey: doc.$$hashKey});
      }
    }

    /**
     * Lors du clique sur le bouton pour ouvrir un document
     * @param document
     */
    function onClickOpenDocument(document) {
      DocumentManagerService.openDocument(document);
    }

    /**
     * Telecharge un ou plusieur fichiers select ou non
     * @param documents
     */
    function onClickDownloadDocument(documents) {
      DocumentManagerService.loadingDownload('loadingWidgetDocs');
      DocumentManagerService.downloadDocument(documents);

      if(_.isArray(documents) && documents.length){
        for(var i = 0; i < documents.length; i++){
          delete documents[i].selected;
        }
      }
      _this.documentsSelected = [];
      _this.documentsSelectedGed = [];
    }

    /**
     * Permet de savoir si je peux ranommer le document
     * @param document
     * @returns {boolean}
     */
    /*
    function canRename(document){

      // Si je ne suis une agence ICS
      if(!_this.agenceICS || _.isNil(_this.agenceICS)) return false;

      // Si événément pas modifiable
      if(_this.hasEvenement && !_.isNil(_this.topicManagerService.currentTopicStates) && !_this.topicManagerService.currentTopicStates.getTopic().getEvenement().isModifiable()){
        return false;
      }
      return true;
    }*/

    /**
     * Permet de renommer un document
     * @param document
     */
    function onClickRenameDocument(document){
      DocumentManagerService.renameDocument(document, TopicManagerService.currentTopicStates.getTopic());
    }

    function onClickSendDocumentToGed(document){
      DocumentManagerService.sendDocumentToGed(document, TopicManagerService.currentTopicStates.getTopic()).then(function(){
        _this.documentsSelected = [];
        _this.documentsSelectedGed = [];
      });
    }

    /**
     * Lors du start d'un drag and drop
     * @param event
     * @param ui
     */
    function startDrag(event, ui) {
      angular.element(ui.helper).addClass('ui-draggable-helper');
    }

    /*
    function onEmitFromLigneDocumentWidgetComponent(obj){
      try{
        if (!obj || !_.isObject(obj)) {
          throw new Error('Il manque l\'objet "obj" !');
        }
        if(obj.action === 'documentsSelected' && _.isArray(obj.documentsSelected)) {
          _this.documentsSelected = obj.documentsSelected;
        }
      }
      catch(err){
        console.log('[DocumentsWidgetController.onEmitFromLigneDocumentWidgetComponent] Erreur : '+err.message);
      }
    }*/

    function emit(objet) {
      _this.onEmit({
        obj: objet
      });
    }

    /**
     * Si ouvert en mode Modal
     */
    function close(){
      if (_this.modalInstance) _this.dismiss({$value: 'cancel'});
      else emit({action: 'close'});
    }
  }
})();
