(function() {

    'use strict';

    /**
     * @desc Composant pour gerer les categories des topics
     * @example <admin-gestion-categories-topics></admin-gestion-categories-topics>
     */
    angular
        .module('collaboreApp')
        .component('adminGestionCategoriesTopics',{
            templateUrl: 'app/parametrage/admin/admin-gestion-categories-topics/admin-gestion-categories-topics.component.html',
            controllerAs: 'admingestioncategoriestopicsctrl',
            controller: AdminGestionCategoriesTopicsCtrl
        });

    /** @ngInject */
    function AdminGestionCategoriesTopicsCtrl(TopicService, AdminTopicService, ModalsService){
        var _this = this;

        _this.currentEditCategorieTopic = null;
        _this.listCategorieTopic = [];

        _this.onClickAddNewCategorieTopic = onClickAddNewCategorieTopic;
        _this.categorieTopicIsDisabled = categorieTopicIsDisabled;
        _this.onClickRandomTextColor = onClickRandomTextColor;
        _this.onSubmitEditCategorieTopic = onSubmitEditCategorieTopic;
        _this.onClickCancelEditCategorieTopic = onClickCancelEditCategorieTopic;
        _this.onEmitFromSelectCategorieTopic = onEmitFromSelectCategorieTopic;
        _this.onEmitFromLigneCategorie = onEmitFromLigneCategorie;

        _this.$onInit = function(){
            _this.customSettings = {
                control: 'hue',
                theme: 'bootstrap',
                letterCase: 'uppercase',
                position: 'bottom left'
            };
            init();
        };

        function init(groupe){
            initListCategorieTopic();
        }

        /**
         * Initialisation de la list des catégorie de topic
         */
        function initListCategorieTopic(){
            _this.currentEditCategorieTopic = null;
            _this.loadingList = true;
            TopicService
                .getListCategorieTopic()
                .then(function onSuccess(listeCat){
                    _this.listCategorieTopic = listeCat;
                })
                .finally(function onFinally(){
                    _this.loadingList = false;
                });
        }

        /**
         * Permet d'init les infos pour le forulaire d'édition/ajout d'une categorie topic
         * @param categorieTopic
         */
        function initEditCategorieTopic(categorieTopic){
            _this.currentEditCategorieTopic = {
                libelle: '',
                description: '',
                textColor: '#000000'
            };
            if(!_.isNil(categorieTopic)) {
                // console.log(categorieTopic);
                _this.currentEditCategorieTopic = angular.copy(categorieTopic);
                if(_.isObject(categorieTopic.categorieTopicParent)){
                    _this.currentEditCategorieTopic.idCategorieTopicParent = categorieTopic.categorieTopicParent.idCategorieTopic;
                    delete _this.currentEditCategorieTopic.categorieTopicParent;
                }
            }
        }

        /**
         * Lors du clique sur le bouton pour ajouter une nouvelle categorie Topic
         */
        function onClickAddNewCategorieTopic(){
            initEditCategorieTopic();
        }

        function categorieTopicIsDisabled(cat){
            // Si la categorie de topic actuellement en cours de modification et la même que celle passé en paramètre
            if(_this.currentEditCategorieTopic.idCategorieTopic === cat.getIdCategorieTopic()) {
                return true;
            }

            // Si la categorie qui est modifié actuellement à des enfants on ne peux pas choisir un de ses enfants en parent
            return recurseCategorieTopicIsDisabled(cat, _this.currentEditCategorieTopic);
        }

        function recurseCategorieTopicIsDisabled(cat, currentCategorieTopic){
            var disabled = false;
            if(_.isObject(currentCategorieTopic) && currentCategorieTopic.getListEnfantsCategorieTopic() && currentCategorieTopic.getListEnfantsCategorieTopic().length) {
                for(var c = 0; c < currentCategorieTopic.getListEnfantsCategorieTopic().length; c++) {
                    if(cat.idCategorieTopic === currentCategorieTopic.getListEnfantsCategorieTopic()[c].getIdCategorieTopic()) {
                        disabled = true;
                    }
                    else {
                        disabled = recurseCategorieTopicIsDisabled(cat, currentCategorieTopic.getListEnfantsCategorieTopic()[c]);
                    }
                    if(disabled) break;
                }
            }
            return disabled;
        }

        /**
         * Lors du clique sur le bouton pour obtenir une couleur aléatoire
         */
        function onClickRandomTextColor(){
            _this.currentEditCategorieTopic.textColor = getRandomColor();
        }

        /**
         * Lors du submit du formulaire
         * @param form
         */
        function onSubmitEditCategorieTopic(form){
            if(form.$invalid) {
                ModalsService.alertErreur("Il manque des renseignements");
                return;
            }
            saveCategorieTopic();
        }

        /**
         * Lors du clique sur le bouton pour annuler l'édition de la categorie topic
         */
        function onClickCancelEditCategorieTopic(){
            _this.currentEditCategorieTopic = null;
        }


        function getRandomColor() {
            var letters = '0123456789ABCDEF'.split('');
            var color = '#';
            for (var i = 0; i < 6; i++ ) {
                color += letters[Math.round(Math.random() * 15)];
            }
            return color;
        }

        /**
         * Sauvegarde de la catégorie Topic
         */
        function saveCategorieTopic(){
            _this.loadingSave = true;
            AdminTopicService
                .saveCategorieTopic(_this.currentEditCategorieTopic)
                .then(function onSuccess(cat){
                    initListCategorieTopic();
                })
                .catch(function onError(msg){
                    ModalsService.alertErreur(msg);
                })
                .finally(function onFinally(){
                    _this.loadingSave = false;
                });
        }

        /**
         * Lors d'une remonté d'info depuis le composant de selection de catégorie topic
         * @param obj
         */
        function onEmitFromSelectCategorieTopic(obj){
            try {
                if (!obj) throw new Error('Pas d\'objet');
                if (_.isObject(obj)) {
                    if(obj.action === 'selectCategorieTopic') {
                        if(_.isObject(obj.categorieTopic)) {
                            _this.currentEditCategorieTopic.idCategorieTopicParent = obj.categorieTopic.idCategorieTopic;
                        }
                        else _this.currentEditCategorieTopic.idCategorieTopicParent = null;

                    }
                }
            }
            catch (err) {
                console.log('[AdminGestionCategoriesTopicsCtrl.onEmitFromSelectCategorieTopic] Erreur : ' + err.message);
            }
        }

        function onEmitFromLigneCategorie(obj){
            try {
                if (!obj)
                    throw new Error('Pas de "obj"');

                if(obj.action === 'showLoadingList')
                    _this.loadingList = true;
                if(obj.action === 'hideLoadingList')
                    _this.loadingList = false;

                if(obj.action === 'refreshList')
                    initListCategorieTopic();

                if(obj.action === 'editCategorieTopic')
                    initEditCategorieTopic(obj.categorieTopic);

            }
            catch (err) {
                console.log('[AdminGestionCategoriesTopicsCtrl.onEmitFromLigneCategorie] Erreur : ' + err.message);
            }
        }

    }
})();
