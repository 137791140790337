(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.RelanceAutoConfig
   * @description
   * # RelanceAutoConfig
   * Service in the collaboreApp.
   */

  angular.module('collaboreApp').factory('RelanceAutoConfig', RelanceAutoConfigModel);

  /** @ngInject */
  function RelanceAutoConfigModel(Main, MainService, UtilsService) {

    var RelanceAutoConfig = function(objetData) {
      Main.apply(this, arguments);
      this.hydrate(objetData);
    };

    RelanceAutoConfig.prototype = {

      model: 'RelanceAutoConfig',
      isRelanceAutoConfig: true,

      getId: function(){
        return this.id;
      },
      setId: function(val){
        this.id = val;
      },

      getContenu: function () {
        return this.contenu;
      },
      setContenu: function (c) {
        if (_.isObject(c) && !c.isModel) c = MainService.initContenuByType(c)
        this.contenu = c;
      },

      // dateCreation
      getDateCreation: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateCreation, format);
        }
        return this.dateCreation;
      },
      getDateCreationAgo: function(){
        var retour = this.dateCreation;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },
      setDateCreation: function(date){
        this.dateCreation = date;
      },

      // dateModification
      getDateModification: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateModification, format);
        }
        return this.dateModification;
      },
      getDateModificationAgo: function(){
        var retour = this.dateModification;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },
      setDateModification: function(date){
        this.dateModification = date;
      },

      // dateDepartRelance
      getDateDepartRelance: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateDepartRelance, format);
        }
        return this.dateDepartRelance;
      },
      getDateDepartRelanceAgo: function(){
        var retour = this.dateDepartRelance;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },
      setDateDepartRelance: function(date){
        this.dateDepartRelance = date;
      },

      // typeDateDepartRelance
      getTypeDateDepartRelance: function(){
        return this.typeDateDepartRelance;
      },
      setTypeDateDepartRelance: function(val){
        this.typeDateDepartRelance = val;
      },

      // dateProchaineRelance
      getDateProchaineRelance: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateProchaineRelance, format);
        }
        return this.dateProchaineRelance;
      },
      getDateProchaineRelanceAgo: function(){
        var retour = this.dateProchaineRelance;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },
      setDateProchaineRelance: function(date){
        this.dateProchaineRelance = date;
      },

      getDateProchaineRelanceApresChangement: function(dateDepart, periode, format){
        if(_.isNil(dateDepart) && _.isNil(this.getDateDepartRelance())) return null;
        if(_.isNil(dateDepart)) dateDepart = this.getDateDepartRelance();

        return UtilsService.calculDateProchaineRelance(dateDepart, periode, format, this);
      },

      // periode
      getPeriode: function(){
        return this.periode;
      },
      setPeriode: function(val){
        if(_.isObject(val) && !val.isModel) {
          var Periode = Main.$injector.get('Periode');
          val = new Periode(val);
          Periode = null;
        }
        this.periode = val;
      },

      // enabled
      getEnabled: function(){
        return this.enabled;
      },
      setEnabled: function(bool){
        this.enabled = bool;
      },

      // heureRelance
      getHeureRelance: function(){
        return this.heureRelance;
      },
      setHeureRelance: function(val){
        this.heureRelance = val;
      },

      // erreur
      getErreur: function(){
        return this.erreur;
      },
      setErreur: function(val){
        this.erreur = val;
      }
    };

    angular.extend(RelanceAutoConfig.prototype, Main.prototype);

    return RelanceAutoConfig;
  }
})();
