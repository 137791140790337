(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.ContenuEvenementContrat
   * @description
   * # ContenuEvenementContrat
   * Service in the collaboreApp.
   */

  angular.module('collaboreApp').factory('ContenuEvenementContrat', ContenuEvenementContratModel);

  /** @ngInject */
  function ContenuEvenementContratModel(COLLAB_CONF, $q, Contenu, Document, $injector, UtilsService) {

    var ContenuEvenementContrat = function () {
      Contenu.apply(this, arguments);
      this.typeContenu = COLLAB_CONF.TYPE_CONTENU_CONTRAT;
    };

    ContenuEvenementContrat.prototype = {

      model: 'ContenuEvenementContrat',
      isContrat: true,

      getDestinataire: function(){
        if(_.isArray(this.getDestinataires()) && !_.isEmpty(this.getDestinataires())) {
          return this.getDestinataires()[0];
        }
        return null;
      },

      getNomFournisseur: function(){
        if(this.getPersoICS()) {
          return this.getPersoICS().getNom();
        }
        if(this.getDestinataire()) {
          return this.getDestinataire().getDefaultLibelle();
        }
        return 'NC';
      },

      // Groupement
      getGroupement: function () {
        return this.groupement;
      },
      setGroupement: function (string) {
        this.groupement = string;
      },

      /**
       * Permet de récupérer le document de mande de contrat
       * @returns {*}
       */
      getDocumentDemande: function () {
        return this.getDocumentWithTypeFromListeDocument(COLLAB_CONF.TYPE_DOC_ENUMS.DEMANDE_CONTRAT);
      },

      // Document contrat signé par le fournisseur en PDF
      // Version qui va récupérer le document dans la liste des documents du contenu, donc pas de setter pour cette version
      getListDocumentContratFournisseurSigne: function () {
        if(!_.isArray(this.listDocumentContratFournisseurSigne)) {
          this.listDocumentContratFournisseurSigne = [];
        }
        return this.listDocumentContratFournisseurSigne;
        //return this.getListDocumentWithTypeFromListeDocumentFournisseur(COLLAB_CONF.TYPE_DOC_ENUMS.DOC_CONTRAT_FOURNISSEUR_SIGNE);
      },
      getListDocumentContratFournisseurSigneWithGuid: function (docOrGuid) {
        var docRetour = null;
        if(!_.isNil(docOrGuid) && this.getListDocumentContratFournisseurSigne().length) {

          var guid = null;
          if(_.isObject(docOrGuid)){
            guid = docOrGuid.getGuid();
          }
          else if(_.isString(docOrGuid)) {
            guid = docOrGuid;
          }

          for(var i = 0; i < this.getListDocumentContratFournisseurSigne().length; i++) {
            var doc = this.getListDocumentContratFournisseurSigne()[i];
            if(doc.getGuid() === guid) {
              docRetour = doc;
              break;
            }
          }
        }
        return docRetour;
      },
      /**
       * Permet de récupérer le prochain contrat signé par le fournisseur qui est en attente
       * @param docOrGuid
       * @returns {*}
       */
      getNextDocumentWaitingFromListDocumentContratFournisseurSigne: function () {
        var docRetour = null;
        if(this.getListDocumentContratFournisseurSigne().length) {
          for(var i = 0; i < this.getListDocumentContratFournisseurSigne().length; i++) {
            var doc = this.getListDocumentContratFournisseurSigne()[i];
            if(doc.isWaiting()) {
              docRetour = doc;
              break;
            }
          }
        }
        return docRetour;
      },
      /**
       * Permet de récupérer le prochain contrat signé par le fournisseur qui est accepté
       * @param docOrGuid
       * @returns {*}
       */
      getNextDocumentAcceptedFromListDocumentContratFournisseurSigne: function () {
        var docRetour = null;
        if(this.getListDocumentContratFournisseurSigne().length) {
          for(var i = 0; i < this.getListDocumentContratFournisseurSigne().length; i++) {
            var doc = this.getListDocumentContratFournisseurSigne()[i];
            if(doc.isAccepted() && !doc.isSigned()) {
              docRetour = doc;
              break;
            }
          }
        }
        return docRetour;
      },
      setListDocumentContratFournisseurSigne: function (arrayDocuments) {
        var arrayListeDocumentContratFournisseurSigne = [];
        if(_.isArray(arrayDocuments) && arrayDocuments.length){
          for(var i = 0 ; i < arrayDocuments.length ;  i++){
            if (!arrayDocuments[i].isModel) {
              arrayDocuments[i] = new Document(arrayDocuments[i]);
            }
            arrayListeDocumentContratFournisseurSigne.push(arrayDocuments[i]);
          }
        }
        this.listDocumentContratFournisseurSigne = arrayListeDocumentContratFournisseurSigne;
      },
      addDocumentContratFournisseurSigne: function(val){
        if(_.isObject(val)){

          if(!val.isModel) val = new Document(val);
          var listDocs = this.getListDocumentContratFournisseurSigne();
          var exist = false;
          if(listDocs.length) {
            for(var i = 0; i < listDocs.length; i++){
              if(listDocs[i].getGuid() === val.getGuid()) {
                exist = true;
                break;
              }
            }
          }
          if(!exist) {
            val.setTypeDocument(COLLAB_CONF.TYPE_DOC_ENUMS.DOC_CONTRAT_FOURNISSEUR_SIGNE);
            this.getListDocumentContratFournisseurSigne().push(val);
          }
        }
      },
      deleteDocumentFromListDocumentContratFournisseurSigne: function(guid){
        var _this = this;
        var deferred = $q.defer();
        this.deleteDocumentFromListeDocumentFournisseur(guid)
          .then(function(){
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });
        return deferred.promise;
      },

      /**
       * Permet d'ajouter au contrat des documents contrats signé par le fournisseur
       * @param guid
       * @returns {*}
       */
      saveDocumentsContratFournisseurSigne: function (guid) {

        var ContratService = $injector.get('ContratService');
        var _this = this;
        var deferred = $q.defer();
        var params = {
          idContrat: _this.getIdContenu()
        };
        if(_.isArray(guid)) {
          params.listGuidDocumentContratFournisseurSigne = guid;
        }
        else if(_.isString(guid)) {
          params.listGuidDocumentContratFournisseurSigne = [guid];
        }

        ContratService.addDocumentsContratFournisseurSigne(params)
          .then(function (contrat) {
            _this.setDataFromList(contrat);
            deferred.resolve(contrat);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      /*
      deleteDocumentContratFournisseurSigne: function () {

          var ContratService = $injector.get('ContratService');
          var _this = this;
          var deferred = $q.defer();

          ContratService.deleteDocumentContratFournisseurSigne(_this.getIdContenu())
              .then(function (contrat) {
                  _this.setData(contrat);
                  deferred.resolve(_this);
              })
              .catch(function (msg) {
                  deferred.reject(msg);
              });

          return deferred.promise;
      },*/

      // Document contrat signé par l'agence en PDF
      /*
      getListDocumentContratAgenceSigne: function () {
          return this.getListDocumentWithTypeFromListeDocument(COLLAB_CONF.TYPE_DOC_ENUMS.DOC_CONTRAT_AGENCE_SIGNE);
      },*/

      getListDocumentContratAgenceSigne: function () {
        return this.listDocumentContratAgenceSigne;
      },
      setListDocumentContratAgenceSigne: function (arrayDocuments) {
        var arrayListeDocumentContratAgenceSigne = [];
        if(_.isArray(arrayDocuments) && arrayDocuments.length){
          for(var i = 0 ; i < arrayDocuments.length ;  i++){
            if (!arrayDocuments[i].isModel) {
              arrayDocuments[i] = new Document(arrayDocuments[i]);
            }
            arrayListeDocumentContratAgenceSigne.push(arrayDocuments[i]);
          }
        }
        this.listDocumentContratAgenceSigne = arrayListeDocumentContratAgenceSigne;
      },
      addDocumentContratAgenceSigne: function(val){
        if(_.isObject(val)){

          if(!val.isModel) val = new Document(val);
          var listDocs = this.getListeDocument();
          var exist = false;
          if(listDocs.length) {
            for(var i = 0; i < listDocs.length; i++){
              if(listDocs[i].getGuid() === val.getGuid()) {
                exist = true;
                break;
              }
            }
          }
          if(!exist) {
            val.setTypeDocument(COLLAB_CONF.TYPE_DOC_ENUMS.DOC_CONTRAT_AGENCE_SIGNE);
            this.addDocument(val);
          }
        }
      },
      /**
       * Permet de récupérer le document signé par rapport au guid du document source( quie st le document fournisseur signé qui à permis de générer le document signé)
       * @param guid
       * @returns {*}
       */
      getDocumentFromListContratAgenceSigneForGuidDocumentSource: function (guid) {
        var docs = this.getListDocumentContratAgenceSigne();
        if(_.isArray(docs) && docs.length) {
          var docFound = null;
          for(var i = 0; i < docs.length; i++) {
            if(!_.isNil(docs[i].getDocumentSource()) && docs[i].getDocumentSource().getGuid() === guid){
              docFound = docs[i];
              break;
            }
          }
          return docFound;
        }
        return null;
      },

      // dateDocumentContratFournisseurSigne
      getDateDocumentContratFournisseurSigne: function(format){
        var doc = this.getDocumentContratFournisseurSigne();
        if(_.isObject(doc)){
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(doc.getDateUpload(), format);
        }
        return null;
      },
      getDateDocumentContratFournisseurSigneAgo: function(){
        var retour = this.dateDocumentContratFournisseurSigne;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },
      setDateDocumentContratFournisseurSigne: function(date){
        this.dateDocumentContratFournisseurSigne = date;
      },

      // DateDocumentContratAgenceSigne
      getDateDocumentContratAgenceSigne: function (format) {
        var doc = this.getDocumentContratAgenceSigne();
        if(_.isObject(doc)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(doc.getDateUpload(), format);
        }
        return null;
      },

      //******** Syndic **************/

      /**
       * Raccourci pour avoir la date de validation du contrat signé par le fournisseur
       * @returns {*}
       */
      getDateAcceptation: function(format){
        if(this.isAccepte() && this.getDateValidationSyndic()) return this.getDateValidationSyndic(format);
      },

      /**
       * Raccourci pour avoir la date de refus du contrat signé par le fournisseur
       * @returns {*}
       */
      getDateRefus: function(format){
        if(this.isRefuse() && this.getDateRefusedBySyndic()) return this.getDateRefusedBySyndic(format);
      },

      // AcceptedBySyndic
      getAcceptedBySyndic: function () {
        return this.acceptedBySyndic;
      },
      setAcceptedBySyndic: function (bool) {
        this.acceptedBySyndic = bool;
      },

      // RefusedBySyndic
      getRefusedBySyndic: function () {
        return this.refusedBySyndic;
      },
      setRefusedBySyndic: function (bool) {
        this.refusedBySyndic = bool;
      },

      // DateRefusedBySyndic
      getDateRefusedBySyndic: function (format) {
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateRefusedBySyndic, format);
        }
        return this.dateRefusedBySyndic;
      },
      setDateRefusedBySyndic: function (date) {
        this.dateRefusedBySyndic = date;
      },

      // GroupeValidationSyndic
      getGroupeValidationSyndic: function () {
        return this.groupeValidationSyndic;
      },
      setGroupeValidationSyndic: function (val) {
        this.groupeValidationSyndic = val;
      },

      // DateValidationSyndic
      getDateValidationSyndic: function (format) {
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateValidationSyndic, format);
        }
        return this.dateValidationSyndic;
      },
      setDateValidationSyndic: function (date) {
        this.dateValidationSyndic = date;
      },

      // vuParFournisseur
      getVuParFournisseur: function () {
        return this.vuParFournisseur;
      },
      setVuParFournisseur: function (bool) {
        this.vuParFournisseur = bool;
      },

      changeMontant: function (val) {
        var _this = this;
        var deferred = $q.defer();
        var params = {
          idContrat: _this.getIdContenu(),
          montant: val
        };
        var ContratService = $injector.get('ContratService');
        ContratService.updateFieldByField(params)
          .then(function (contrat) {
            //_this.setMontant(contrat.getMontant());
            _this.setData(contrat);
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      accepterContratBySyndic: function (sendMailWhenAcceptOrDeclineContrat, doc, listProxyDestinataire) {
        var _this = this;
        var deferred = $q.defer();
        var params = {
          idContrat: _this.getIdContenu(),
          sendMailWhenAcceptOrDeclineContrat: sendMailWhenAcceptOrDeclineContrat
        };
        if(_.isObject(doc)) {params.guidDocumentContratFournisseurSigne = doc.getGuid();}
        if(_.isArray(listProxyDestinataire)){
          var ProxyDestinataire = $injector.get('ProxyDestinataire');
          params.listProxyDestinataire = angular.toJson(listProxyDestinataire.map(function(dest) {
            return new ProxyDestinataire(dest);
          }));
          ProxyDestinataire = null;
        }

        var ContratService = $injector.get('ContratService');
        ContratService.accepteContratBySyndic(params)
          .then(function (contrat) {
            _this.setData(contrat);
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      refuserContratBySyndic: function (sendMailWhenAcceptOrDeclineContrat, doc) {

        var _this = this;
        var deferred = $q.defer();
        var params = {
          idContrat: _this.getIdContenu(),
          sendMailWhenAcceptOrDeclineContrat: sendMailWhenAcceptOrDeclineContrat
        };
        if(_.isObject(doc)) {params.guidDocumentContratFournisseurSigne = doc.getGuid();}

        var ContratService = $injector.get('ContratService');
        ContratService.refuseContratBySyndic(params)
          .then(function (contrat) {
            _this.setData(contrat);
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      relancer: function () {
        var ContratService = $injector.get('ContratService');
        var _this = this;
        var deferred = $q.defer();

        ContratService.doRelance(_this.getIdContenu())
          .then(function (contrat) {
            _this.hydrate(contrat);
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      /**
       * Permet de dupliquer un contrat
       * @param newDesc
       */
      dupliquer: function (obj) {
        var ContratService = $injector.get('ContratService');
        var _this = this;
        var deferred = $q.defer();

        ContratService.duplicateContrat(_this.getIdContenu(), obj)
          .then(function (contrat) {
            deferred.resolve(contrat);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      hasContratFournisseurSigne: function () {
        var docs = this.getListDocumentContratFournisseurSigne();
        if (_.isArray(docs) && docs.length) {
          return true;
        }
        return false;
      },
      hasContratAgenceSigne: function () {
        var docs = this.getListDocumentContratAgenceSigne();
        if (_.isArray(docs) && docs.length) {
          return true;
        }
        return false;
      },
      hasDocumentDemande: function () {
        var doc = this.getDocumentDemande();
        if (_.isObject(doc)) {
          return true;
        }
        return false;
      },

      isWaiting: function () {
        if (!this.getAcceptedBySyndic() && !this.getRefusedBySyndic() && this.isCreated() && !this.isCloture()) {
          return true;
        }
        return false;
      },

      isAccepte: function () {
        if (this.getAcceptedBySyndic()) {
          return true;
        }
        return false;
      },

      isRefuse: function () {
        if (this.getRefusedBySyndic()) {
          return true;
        }
        return false;
      },

      isSignedByFournisseur: function () {
        if (this.hasContratFournisseurSigne()) {
          return true;
        }
        return false;
      },

      /**
       * Permet de savoir si tous les contrats fournisseur signé ont été signé par l'agence
       * @returns {boolean}
       */
      isSignedByAgence: function () {
        var docsFournisseurSigne = this.getListDocumentContratFournisseurSigne();
        var nbDocFournisseurSigne = (_.isArray(docsFournisseurSigne)) ? docsFournisseurSigne.length : 0;

        var docsAgenceSigne = this.getListDocumentContratAgenceSigne();
        var nbDocAgenceSigne = (_.isArray(docsAgenceSigne)) ? docsAgenceSigne.length : 0;


        if(nbDocAgenceSigne !== 0 && nbDocFournisseurSigne !== 0) {
          var exit = false;
          var nbDocFournisseurAccepted = 0;
          var nbDocFournisseurRefused = 0;
          // Parcour les documents fournisseur signé
          for(var f = 0; f < docsFournisseurSigne.length; f++){
            var docFournisseur = docsFournisseurSigne[f];
            // Si ce document est accepté
            if(docFournisseur.isAccepted()) {
              nbDocFournisseurAccepted++;
              // Si il n'existe pas de document agence signé pour ce document fournisseur signé
              // On sort car pas la peine d'aller plus loin pour considérer la demande comme pas ecore traité
              if(_.isNil(this.getDocumentFromListContratAgenceSigneForGuidDocumentSource(docFournisseur.getGuid()))){
                exit = true;
                break;
              }
            }
            // Sinon si en attente
            else if(docFournisseur.isWaiting()) {
              exit = true;
              break;
            }
            else if(docFournisseur.isRefused()) {
              nbDocFournisseurRefused++;
            }
          }

          if(exit) {
            return false;
          }

          // Si tout est accepté
          // Ou
          // Si le nombre total est égale au nombre d'accepté plus le nombre de refusé et qu'il y en a au moins un accepté
          if(nbDocFournisseurSigne == nbDocFournisseurAccepted || ((nbDocFournisseurSigne == (nbDocFournisseurAccepted+nbDocFournisseurRefused)) && nbDocFournisseurAccepted != 0)){
            return true;
          }
        }
        return false;
      },

      // Méthode qui permet de se calquer au devis
      isSigne: function () {
        if (this.isSignedByAgence()) {
          return true;
        }
        return false;
      },

      canAddContrat: function(){
        if(!this.isCloture() && !this.hasContratFournisseurSigne()){
          return true;
        }
        return false;
      },

      generateDemande: function () {

        var _this = this;
        var deferred = $q.defer();

        var ContratService = $injector.get('ContratService');
        ContratService.regenerateDocument(_this.getIdContenu(), _this.getDemandeOptions())
          .then(function (contrat) {
            //_this.setListeDocument(devis.getListeDocument());
            //_this.setDateModification(devis.getDateModification());
            _this.hydrate(contrat);
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      /**
       * Update un contrat par proxyContrat
       */
      updateContratWithProxyContrat: function (event) {

        var ContratService = $injector.get('ContratService');
        var ProxyContenuContrat = $injector.get('ProxyContenuContrat');
        var _this = this;
        var deferred = $q.defer();
        var params = {
          action: 'updateContrat',
          idContrat: _this.getIdContenu(),
          json: new ProxyContenuContrat(_this).toJson()
        };
        ContratService.updateContratWithProxyContrat(params)
          .then(function (listContrat) {
            // Si il y a un evenement en paramètre alors on utilise la méthode "addContrat" pour ajouter/update les contrats retournés
            if(event) event.addContrat(listContrat);
            // Si pas d'event alors set la nouvelles data du devis
            else _this.setDataFromList(listContrat);

            deferred.resolve(listContrat);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      retryContrat: function () {
        var ContratService = $injector.get('ContratService');
        var deferred = $q.defer();
        ContratService.retryContrat(this.getIdContenu())
          .then(function (success) {
            deferred.resolve(success);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      getEtatDemande: function(){
        var retour = null;
        if(!this.isCloture()) {

          // Si la demande est refusée
          if(this.isRefuse()){
            retour = ContenuEvenementContrat.ETATS.ATTENTE_VALIDATION_CONTRAT;
          }
          // Si demande en attente (Pas validé/refusé)
          else if(this.isWaiting() || this.isWaitingFromWebsocket()) {

            // Si la demande a un document signé par le fournisseur alors elle est en attente de validation/refus
            if(this.isSignedByFournisseur()) {
              retour = ContenuEvenementContrat.ETATS.ATTENTE_VALIDATION_CONTRAT;
            }
            // Si la demande n'a pas de contrat signé par le fournisseur
            else {
              retour = ContenuEvenementContrat.ETATS.ATTENTE_CONTRAT;
            }
          }
          // Si la demande n'est pas en attente et signé par le fournisseur et pas signée par l'agence alors en attente de signature
          else if(this.isSignedByFournisseur() && !this.isSignedByAgence()) {
            retour = ContenuEvenementContrat.ETATS.ATTENTE_SIGNATURE_CONTRAT;
          }
          else if(this.isSignedByAgence()){
            retour = ContenuEvenementContrat.ETATS.CLOTURE
          }

        }
        else {
          retour = ContenuEvenementContrat.ETATS.CLOTURE;
        }
        return retour;
      },

      getLibelleEtatDemande: function(){
        var etat = this.getEtatDemande();
        return (!_.isNil(etat)) ? etat.LIBELLE : null;
      },

      getTypeEtatDemande: function(){
        var etat = this.getEtatDemande();
        return (!_.isNil(etat)) ? etat.TYPE : null;
      }
    };

    ContenuEvenementContrat.LIBELLE_ETATS = {
      ATTENTE_CONTRAT: 'Contrats en attentes de signature par le fournisseur',
      ATTENTE_VALIDATION_CONTRAT: 'Contrats en attentes de validation ou refusés',
      ATTENTE_SIGNATURE_CONTRAT: 'Contrats validés en attentes de signature',
      CLOTURE: 'Demandes traitées ou cloturées',
    };

    ContenuEvenementContrat.TYPE_ETATS = {
      ATTENTE_CONTRAT: 'attenteContrat',
      ATTENTE_VALIDATION_CONTRAT: 'attenteValidationContrat',
      ATTENTE_SIGNATURE_CONTRAT: 'attenteSignatureContrat',
      CLOTURE: 'demandeCloture',
    };

    ContenuEvenementContrat.ETATS = {
      ATTENTE_CONTRAT: {
        LIBELLE: ContenuEvenementContrat.LIBELLE_ETATS.ATTENTE_CONTRAT,
        TYPE: ContenuEvenementContrat.TYPE_ETATS.ATTENTE_CONTRAT
      },
      ATTENTE_VALIDATION_CONTRAT: {
        LIBELLE: ContenuEvenementContrat.LIBELLE_ETATS.ATTENTE_VALIDATION_CONTRAT,
        TYPE: ContenuEvenementContrat.TYPE_ETATS.ATTENTE_VALIDATION_CONTRAT
      },
      ATTENTE_SIGNATURE_CONTRAT: {
        LIBELLE: ContenuEvenementContrat.LIBELLE_ETATS.ATTENTE_SIGNATURE_CONTRAT,
        TYPE: ContenuEvenementContrat.TYPE_ETATS.ATTENTE_SIGNATURE_CONTRAT
      },
      CLOTURE: {
        LIBELLE: ContenuEvenementContrat.LIBELLE_ETATS.CLOTURE,
        TYPE: ContenuEvenementContrat.TYPE_ETATS.CLOTURE
      }
    };

    // Copie le prototype de Contenu dans le prototype de ContenuEvenementContrat
    // ContenuEvenementContrat hérite donc des methodes de Contenu
    angular.extend(ContenuEvenementContrat.prototype, Contenu.prototype);

    // Liste des documents fournisseurs sans l'éventuel contrat signé par le fournisseur
    ContenuEvenementContrat.prototype.getListeDocumentFournisseur = function(){
      if(!_.isArray(this.listeDocumentFournisseur)){
        this.listeDocumentFournisseur = [];
      }
      else if(_.isArray(this.listeDocumentFournisseur) && this.listeDocumentFournisseur.length) {
        var liste = [];
        for(var i = 0; i < this.listeDocumentFournisseur.length; i++){
          if(!this.listeDocumentFournisseur[i].isContratFournisseur()) {
            liste.push(this.listeDocumentFournisseur[i]);
          }
        }
        return liste;
      }
      return this.listeDocumentFournisseur;
    };

    ContenuEvenementContrat.prototype.setListeDocumentFournisseur = function(arrayDocuments){
      var listDocumentContratFournisseurSigne = [];
      var arrayListeDocumentFournisseur = [];
      if(_.isArray(arrayDocuments)){
        for(var i = 0 ; i < arrayDocuments.length ;  i++){
          if (!arrayDocuments[i].isModel) {
            arrayDocuments[i] = new Document(arrayDocuments[i]);
          }
          if(arrayDocuments[i].isContratFournisseur()) {
            listDocumentContratFournisseurSigne.push(arrayDocuments[i]);
          }
          else {
            arrayListeDocumentFournisseur.push(arrayDocuments[i]);
          }
        }
      }
      this.listeDocumentFournisseur = arrayListeDocumentFournisseur;
      this.setListDocumentContratFournisseurSigne(listDocumentContratFournisseurSigne);
    };

    ContenuEvenementContrat.prototype.setListeDocument = function(arrayDocuments){
      var listDocumentContratAgenceSigne = [];
      var arrayListeDocument = [];
      if(_.isArray(arrayDocuments)){
        for(var i = 0 ; i < arrayDocuments.length ;  i++){
          if (!arrayDocuments[i].isModel) {
            arrayDocuments[i] = new Document(arrayDocuments[i]);
          }
          if(arrayDocuments[i].isContratAgence()) {
            listDocumentContratAgenceSigne.push(arrayDocuments[i]);
          }
          arrayListeDocument.push(arrayDocuments[i]);
        }
      }
      this.listeDocument = arrayListeDocument;
      this.setListDocumentContratAgenceSigne(listDocumentContratAgenceSigne);
    };

    return ContenuEvenementContrat;
  }
})();
