'use strict';

/**
 * @ngdoc function
 * @name collaboreApp.controller:TopicsFiltreCtrl
 * @description
 * # TopicsFiltreCtrl
 * Controller of the collaboreApp
 */

var TopicsFiltreCtrl = function(COLLAB_CONF,$rootScope,$scope,$state,$timeout,$filter,sweet,TagService,FiltreService,UtilsService){

	var vm = this;
	vm.filtreTopics = $rootScope.current.filtreTopics;
	var noClose = false;


	// Tabs (1er onglet actif au départ)
	vm.tabActive = 0;

	/*
	vm.tabsFiltre = {
		'paramFiltre': true,
		'listeFiltrePerso': false
	};*/

	if($rootScope.current.filtreTopic){
		vm.tabActive = 1;
		/*
		vm.tabsFiltre = {
			'paramFiltre': false,
			'listeFiltrePerso': true
		};*/
	}


	vm.selectTab = function(index){
		vm.tabActive = index;
		/*
		if(nom === 'paramFiltre'){
			vm.tabsFiltre.paramFiltre = true;
			vm.tabsFiltre.listeFiltrePerso = false;
		}
		else if(nom === 'listeFiltrePerso'){
			vm.tabsFiltre.paramFiltre = false;
			vm.tabsFiltre.listeFiltrePerso = true;
		}*/
	};


	vm.filtrerTopics = function(){
		FiltreService.filtrerTopics({notifyAndReload:true});
		$scope.$emit('togglePopoverFiltre');
	};

	/*
	vm.onChangeTopicEvent = function(){

		if(!angular.isObject($rootScope.current.filtreTopics)){
			$rootScope.current.filtreTopics = {};
		}

		var objFiltre = $rootScope.current.filtreTopics;
		// Si avec evenement et sans evenement
		if(objFiltre.withEvent && objFiltre.withoutEvent){
			objFiltre.classe = 0;
		}
		// Si seulement avec evenement
		else if(objFiltre.withEvent && !objFiltre.withoutEvent){
			objFiltre.classe = 2;
		}
		// Si seulement sans evenement
		else if(!objFiltre.withEvent && objFiltre.withoutEvent){
			objFiltre.classe = 1;
		}else{
			objFiltre.classe = 0;
		}

	};*/

	vm.onChangeClotureEvent = function(){


		if(!angular.isObject($rootScope.current.filtreTopics)){
			$rootScope.current.filtreTopics = {};
		}

		// Si cloturé et non cloturé
		if($rootScope.current.filtreTopics.withCloture && $rootScope.current.filtreTopics.withoutCloture){
			$rootScope.current.filtreTopics.cloturer = 0;
      $rootScope.current.filtreTopics.others.clotureEnable = true;
		}
		// Si seulement cloturé
		else if($rootScope.current.filtreTopics.withCloture && !$rootScope.current.filtreTopics.withoutCloture){
			$rootScope.current.filtreTopics.cloturer = 1;
      $rootScope.current.filtreTopics.others.clotureEnable = false;
		}
		// Si seulement non cloturé
		else if(!$rootScope.current.filtreTopics.withCloture && $rootScope.current.filtreTopics.withoutCloture){
			$rootScope.current.filtreTopics.cloturer = 2;
      $rootScope.current.filtreTopics.others.clotureEnable = false;
		}else{
			$rootScope.current.filtreTopics.cloturer = 0;
      $rootScope.current.filtreTopics.others.clotureEnable = true;
		}

	};

	vm.onChangeUrgentEvent = function(){


		if(!angular.isObject($rootScope.current.filtreTopics)){
			$rootScope.current.filtreTopics = {};
		}

		// Si urgent et non urgent
		if($rootScope.current.filtreTopics.withUrgent && $rootScope.current.filtreTopics.withoutUrgent){
			$rootScope.current.filtreTopics.urgent = 0;
		}
		// Si seulement urgent
		else if($rootScope.current.filtreTopics.withUrgent && !$rootScope.current.filtreTopics.withoutUrgent){
			$rootScope.current.filtreTopics.urgent = 1;
		}
		// Si seulement non urgent
		else if(!$rootScope.current.filtreTopics.withUrgent && $rootScope.current.filtreTopics.withoutUrgent){
			$rootScope.current.filtreTopics.urgent = 2;
		}else{
			$rootScope.current.filtreTopics.urgent = 0;
		}

	};

  vm.onChangeSinistreEvent = function(){

    if(!angular.isObject($rootScope.current.filtreTopics))$rootScope.current.filtreTopics = {};

    // Si sinistre et sans sinistre
    if($rootScope.current.filtreTopics.withSinistre && $rootScope.current.filtreTopics.withoutSinistre) $rootScope.current.filtreTopics.sinistre = 0;

    // Si seulement sinistre
    else if($rootScope.current.filtreTopics.withSinistre && !$rootScope.current.filtreTopics.withoutSinistre) $rootScope.current.filtreTopics.sinistre = 1;

    // Si seulement non sinistre
    else if(!$rootScope.current.filtreTopics.withSinistre && $rootScope.current.filtreTopics.withoutSinistre) $rootScope.current.filtreTopics.sinistre = 2;
    else $rootScope.current.filtreTopics.sinistre = 0;
  };

	vm.selectTagType = function(tag){
		FiltreService.selectTagType(tag);
	};

	vm.deselectTagType = function(){
		//$rootScope.current.filtreTopics.tagType = $filter('removeWith')($rootScope.current.filtreTopics.tagType,{libelle:tag.libelle});
		FiltreService.deselectTagType();
	};


	/*****************************************************/
	/*						Filtres perso                */
	/*****************************************************/

	// Liste des filtres perso
	vm.arrayFiltresPerso = [];

	/**
	 * Recupère la liste des filtres enregistrés
	 */
	vm.getFiltresPerso = function(){

		vm.loadingListeFiltre = COLLAB_CONF.MIDDLE_LOADING;
		FiltreService.getFiltrePersoTopics().then(function(filtres){
			vm.arrayFiltresPerso = filtres;
			vm.loadingListeFiltre = false;
		},function(msg){
			vm.loadingListeFiltre = false;
			console.error(msg);
		});
	};

	// Execute la recherche de filtre perso lors de l'appel du controller
	vm.getFiltresPerso();

	/**
	 * Lors du clique sur le bouton sauvegarder de la popover des filtres
	 */
	vm.onClickSauvegarderFiltre = function(){
		vm.pendingSave = true;
		noClose = true;
	};

	/**
	 * Lors du clique sur le bouton retour la popover des filtres
	 */
	vm.onClickRetourFiltre = function(){
		vm.pendingSave = false;
		noClose = false;
		vm.libelleNewFiltre = '';
	};

	vm.onClickCancelSaveFiltre = function(){
		vm.pendingSave = false;
		noClose = false;
		vm.libelleNewFiltre = '';

		// Si variable temporaire de filtre
		if(angular.isObject(vm.tmpFiltreTopics)){
			// Remet le filtre comme avant
			$rootScope.current.filtreTopics = angular.copy(vm.tmpFiltreTopics);
			vm.tmpFiltreTopics = false;
		}

		vm.pendingEditFiltre = false;
	};


	/**
	 * Sauvegarde du filtre
	 */
	vm.onClickConfirmSaveFiltre = function(){

		vm.submittedLibelleNewFiltre = true;

		var libelle = vm.libelleNewFiltre;
		if(!libelle || libelle===''){
			return;
		}

		vm.loadingSaveFiltre = COLLAB_CONF.MIDDLE_LOADING;

		var idfiltre = false;
		if(angular.isObject(vm.pendingEditFiltre)){
			idfiltre = vm.pendingEditFiltre.idFiltre;
		}

		// Sauvegarde/Update du filtre
		FiltreService.saveFiltrePersoTopics(libelle,idfiltre).then(function(newFiltrePerso){

			if(angular.isObject(vm.pendingEditFiltre)){
				vm.arrayFiltresPerso  = $filter('removeWith')(vm.arrayFiltresPerso,{'idFiltre':newFiltrePerso.idFiltre});
			}
			// Ajoute le nouveau filtre à la liste
			vm.arrayFiltresPerso.push(newFiltrePerso);

			// Select l'onglet de la liste
			vm.tabActive = 1;
			/*
			vm.tabsFiltre = {
				'paramFiltre': false,
				'listeFiltrePerso': true
			};*/
			// Cache le loading
			vm.loadingSaveFiltre = false;

			// Variable de sauvegarde en cours repasse à false
			vm.pendingSave = false;

			// Variable d'objet en cours de modification repasse à false
			vm.pendingEditFiltre = false;

			// Libelle du filtre repasse à vide
			vm.libelleNewFiltre = '';

			// Variable temporaire de filtre repasse à false
			vm.tmpFiltreTopics = false;

			// Reset le filtre
			FiltreService.resetFiltre();

		},function(msg){
			// Cache le loading
			vm.loadingSaveFiltre = false;

			// Variable de sauvegarde en cours repasse à false
			vm.pendingSave = false;

			// Variable d'objet en cours de modification repasse à false
			vm.pendingEditFiltre = false;

			// Libelle du filtre repasse à vide
			vm.libelleNewFiltre = '';

			// Variable temporaire de filtre repasse à false
			vm.tmpFiltreTopics = false;

			// Reset le filtre
			FiltreService.resetFiltre();
			console.error(msg);
		});
	};


	/**
	 * Filtre grace à un filtre perso
	 * @param objFiltrePerso
	 */
	vm.activeFiltre = function(objFiltrePerso){
		$rootScope.current.filtreTopic = objFiltrePerso;
		FiltreService.resetFiltre();
		var objSearch = FiltreService.parseFiltrePersoToFiltreCollabore(objFiltrePerso);
		FiltreService.filtreBy(objSearch, {notifyAndReload: true});
	};

	/**
	 * Modifie un filtre perso
	 * @param objFiltrePerso
	 */
	vm.onClickEditFiltre = function(objFiltrePerso){

		// Stock le filtre actuel dans une variable temporaire
		vm.tmpFiltreTopics = angular.copy($rootScope.current.filtreTopics);

		vm.pendingEditFiltre = objFiltrePerso;

		// Select l'onglet de parametrage de filtre
		vm.tabActive = 0;
		/*
		vm.tabsFiltre = {
			'paramFiltre': true,
			'listeFiltrePerso': false
		};*/


		vm.libelleNewFiltre = vm.pendingEditFiltre.titre;

		// Crée un objet de recherche simple
		var objSearch = FiltreService.parseFiltrePersoToFiltreCollabore(objFiltrePerso);

		// Recupère l'objet simple pour en faire un objet du formulaire de filtre
		FiltreService.parseFiltreCollabToForm(objSearch);
	};

	vm.onClickConfirmEditFiltre = function(){

		vm.loadingSaveFiltre = COLLAB_CONF.MIDDLE_LOADING;
		FiltreService.updateFiltrePersoTopics().then(function(){
			vm.loadingSaveFiltre = false;
		},function(msg){
			console.log(msg);
			vm.loadingSaveFiltre = false;
		});
	};
	vm.onClickCancelEditFiltre = function(){
		vm.pendingEditFiltre = false;
	};


	/**
	 * Lors du clique sur le bouton "Supprimer" d'un filtre perso
	 * @param objFiltrePerso
	 */
	vm.onClickDeleteFiltre = function(objFiltrePerso){
		vm.pendingDeleteFiltre = objFiltrePerso;
	};

	/**
	 * Lors du clique sur le bouton "Confirmer" de la confirmation de suppression d'un filtre perso
	 * @param objFiltrePerso
	 */
	vm.onClickConfirmDeleteFiltre = function(){

		vm.loadingDeleteFiltre = COLLAB_CONF.MIDDLE_LOADING;

		FiltreService.deleteFiltrePersoTopics(vm.pendingDeleteFiltre).then(function(){
			vm.pendingDeleteFiltre = false;
			vm.loadingDeleteFiltre = false;
			vm.getFiltresPerso();
		},function(msg){
			console.log(msg);
			vm.pendingDeleteFiltre = false;
			vm.loadingDeleteFiltre = false;
			vm.getFiltresPerso();
		});
	};

	/**
	 * Lors du clique sur le bouton "Annuler" de la confirmation de suppression du filtre perso
	 */
	vm.onClickCancelDeleteFiltre = function(){
		vm.pendingDeleteFiltre = false;
		vm.loadingDeleteFiltre = false;
	};

	/*					Fin Filtres perso                */
	/*****************************************************/

	var nbMaxTagsSuggeres = COLLAB_CONF.NB_MAX_TAG_SUGGERES_FOR_FILTRE_TYPE_TAG;
	var promiseTimeout, promiseTag;



	vm.canClosePopoverFilter = function(){
		if(!noClose){
			//$scope.toggle();
			vm.onClickCloseFiltre();
		}
	};

	vm.onClickCloseFiltre = function(){
		$scope.$emit('togglePopoverFiltre');
	};

	$scope.tagSuggestions = [];
	$scope.loadingSuggestion = false;

	var loadingSuggestion = function(bool){
		if(bool){
			$scope.loadingSuggestion = COLLAB_CONF.MINI_LOADING;
		}else{
			$scope.loadingSuggestion = false;
		}
	};

	var cancel  = function(promise){
		// If the promise does not contain a hook into the deferred timeout,
		// the simply ignore the cancel request.
		if (
			promise &&
			promise._httpTimeout &&
			promise._httpTimeout.resolve
		) {
			promise._httpTimeout.resolve();
		}
	};


	$scope.search = {typeTag:''};
	// Ecoute la variable du champ tag
	$scope.$watch('search.typeTag', function(newValue){

		loadingSuggestion(false);
		$scope.tagSuggestions = [];

		if(promiseTimeout) {$timeout.cancel(promiseTimeout);}
		cancel(promiseTag);

		// Si newValue est defini
		if(angular.isDefined(newValue)){

			newValue = $filter('ltrim')(newValue,'#');

			// Si il y a 2 caractères ou plus
			if(newValue.length>=COLLAB_CONF.NB_CARACTERES_AVANT_APPEL_RECHERCHE_TAG_SERVLET){

				loadingSuggestion(true);

				promiseTimeout = $timeout(function(){

					// Appel des tags avec le string en like
					promiseTag = TagService.getWithType('Projet',newValue,nbMaxTagsSuggeres).then(function(tags){

						var n = 0;
						var tagsSuggere = [];
						// Pour chaque tags retourné par la servlet
						angular.forEach(tags,function(tag){
							// Si on a pas atteind le nombre max de tags suggérés
							if(n<nbMaxTagsSuggeres){

								var tagsWhere = [];
								if(angular.isArray($rootScope.current.filtreTopics.tagType)){
									// Si il y a des tags déjà choisis
									if($rootScope.current.filtreTopics.tagType.length){
										tagsWhere = UtilsService.where($rootScope.current.filtreTopics.tagType,{libelle:tag.libelle});
									}
								}


								// Si le tag suggere n'est pas dans le tableau des tags
								if(!tagsWhere.length){
									//if(!tabTmp.length){
									tagsSuggere.push(tag);
									n++;
								}
							}
						});

						$scope.tagSuggestions = tagsSuggere;
						loadingSuggestion(false);
					},function(){
						loadingSuggestion(false);
					});
				}, 800, false);


			}else{
				//$scope.tagSuggestions = [];
			}
		}else{

			//loadingSuggestion(false);
		}
	});
};

angular
	.module('collaboreApp')
	.controller('TopicsFiltreCtrl',[
		'COLLAB_CONF',
		'$rootScope',
		'$scope',
		'$state',
		'$timeout',
		'$filter',
		'sweet',
		'TagService',
		'FiltreService',
		'UtilsService',
		TopicsFiltreCtrl
	]);
