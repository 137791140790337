(function () {
  'use strict';


  /**
   * @ngdoc service
   * @name collaboreApp.LieuCommunCapfun
   * @description
   * # LieuCommunCapfun
   * Service in the collaboreApp.
   */
  angular
    .module('collaboreApp')
    .factory('LieuCommunCapfun', LieuCommunCapfunModel);

  function LieuCommunCapfunModel (Main, UtilsService) {

    function LieuCommunCapfun(objet) {
      Main.call(this, objet);
    }

    LieuCommunCapfun.prototype = {

      model: 'LieuCommunCapfun',
      isLieuCommunCapfun: true,

      // lieu
      getLieu: function(){
        return this.lieu;
      },
      setLieu: function(val){
        this.lieu = val;
      },

      // camping
      getCamping: function(){
        return this.camping;
      },
      setCamping: function(val){
        this.camping = val;
      }
    };

    angular.extend(LieuCommunCapfun.prototype, Main.prototype);
    return LieuCommunCapfun;
  }
})();
