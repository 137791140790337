(function () {
  'use strict';

  /**
   * @desc Liste des TopicStates - liste des topics de layout-topic-list-component
   * @example <list-topic-states list-topic-states="<Array>" topic-detail="<String>(true|false)" topic-states-limit-per-page="<Int>"></list-topic-states>
   */

  angular
    .module('collaboreApp')
    .component('listTopicStates', {
      templateUrl: 'app/topics/list-topic-states.component.html',
      bindings: {
        //listTopicStates: '<',			// <Object> (One-way binding) Liste des TopicStates
        objResultats: '<',
        topicStatesSearch: '<',			// <Object> (One-way binding) Paramètres de recherche
        //topicDetail: '@',				// <String> (NoBinding) Permet de savoir si le topc doit etre afficher en mode page detail
        topicStatesLimitPerPage: '@',	// <String> (NoBinding) Nombre de TopicStates à afficher
        noPagination: '@',				// <String> (One-way binding) Pagination ou non
        typePagination: '@',			// <String> (One-way binding) Type de pagination (lazyload|normal)
        customPreference: '<',
        onUpdate: '&',					// <Function> permet de mettre à jour des données dans l'hote
        onClickMoreResult: '&'
      },
      controllerAs: 'listtopicstatesctrl',
      controller: ListTopicStatesController
    });

  /*@ngInject*/
  function ListTopicStatesController(COLLAB_CONF, $stateParams, $rootScope, $scope, $state, $injector, $timeout, FiltreService, TopicStatesService, ConfigService) {

    var ctrl = this;
    ctrl.$state = $state;
    ctrl.erreur = null;
    /******** Méthodes du controller ********/

    //ctrl.isTopicDetail = false;							// Permet d'afficher le topic en version page detail
    ctrl.loadingListTopicStates = {show: false};		// Loading des TopicStates
    ctrl.loadingMoreSearch = false;							// Loading pour la pagination en lazy load

    //ctrl.openLastContenu = openLastContenu;				// Recupère le dernier topic
    ctrl.clickMoreTopics = clickMoreTopics;				// Recupère le dernier topic
    ctrl.onClickClearFiltre = onClickClearFiltre;		// Clear le filtre

    /****** Listeners ******/
      //var listenerOnSocketConnected = null;
    var listenerOnRemoveTopic = $scope.$on('removeTopic', onRemoveTopic);			// Ecoute l'événement 'removeTopic' pour le supprimer un topic de la liste des topicStates

    // Permet de mettre à jour les infos dans la liste des topics
    var listenerOnUpdateCollab = $scope.$on('updateCollab', function (evt, params) {
      if(_.isObject(params.listTopicStates)) updateListTopicStates(params.listTopicStates);
    });

    /****** Initilisation du component ******/
    ctrl.$onInit = function () {

      // Paramétrages de l'app
      var defaultParametrage = $rootScope.current.userGroupe.getDefaultParametrage();
      if (defaultParametrage) {
        if (defaultParametrage.hasOwnProperty('displayDetailsInTopicList')) {
          ctrl.displayDetailsInTopicList = defaultParametrage.getDisplayDetailsInTopicList();
        }
      }

      ctrl.suffixTitre = '';

      var idChannelFiltre = FiltreService.filtreIsChannel();

      if (idChannelFiltre) {
        var channel = $rootScope.current.userGroupe.getChannel(idChannelFiltre);
        if (channel) ctrl.suffixTitre = ' pour le channel "' + channel.nom + '"';
      }

      // ---- Recupération d'éventuel configuration de personnalisation -----
      if(_.isNil(ctrl.customPreference)) ctrl.customPreference = ConfigService.getPreferencesCustom();
      // ------- Fin de la personnalisation --------

      /*
       // Si on demande le topic en version page detail
       if(ctrl.topicDetail === 'true'){
       ctrl.isTopicDetail = true;
       }*/

      // Si il y a un nombre limit de topic par page
      if (angular.isDefined(ctrl.topicStatesLimitPerPage)) {
        // Si il y a "false"
        if (ctrl.topicStatesLimitPerPage === 'false') {
          ctrl.topicStatesLimitPerPage = false;
        }
        // Si autres
        else {
          ctrl.topicStatesLimitPerPage = parseInt(ctrl.topicStatesLimitPerPage);	// Formate en int
          // TODO : Si on met autre chose qu'un nombre que ce passe t-il ?
        }

      }
      else {
        ctrl.topicStatesLimitPerPage = COLLAB_CONF.LIST_TOPIC_LIMIT_PAR_PAGE;	// Limit au nombre par défaut
        /*
         // Si ce n'est pas le detail d'un topic donc certainement la liste des topic
         if(!ctrl.isTopicDetail){
         ctrl.topicStatesLimitPerPage = COLLAB_CONF.LIST_TOPIC_LIMIT_PAR_PAGE;	// Limit au nombre par défaut
         }
         // Si page de detail
         else{
         ctrl.topicStatesLimitPerPage = false;	// Passe à false car le detail de la page n'a pas de limit
         }*/
      }

      // Si il y a un objet resulats
      if (angular.isDefined(ctrl.objResultats)) {


        var listTopicStates = [];
        var nbTotalTopicStates = 0;

        if (angular.isObject(ctrl.objResultats)) {
          if (ctrl.objResultats.hasOwnProperty('listeTopicsStates')) {
            listTopicStates = ctrl.objResultats.listeTopicsStates;
          }
          if (ctrl.objResultats.hasOwnProperty('nbTotalTopics')) {
            nbTotalTopicStates = ctrl.objResultats.nbTotalTopics;
          }
        }

        ctrl.listTopicStates = listTopicStates;					// Liste des TopicsStates
        ctrl.nbTotalTopicStates = nbTotalTopicStates;			// Nombre total de topicStates


        /*
        $scope.$watchCollection('listtopicstatesctrl.listTopicStates', function () {
            ctrl.loadingMoreSearch.show = false;
        });*/
      }
      // Si pas d'objet resultats on fait la recherche
      else if(_.isObject(ctrl.topicStatesSearch)) {
        $timeout(function(){
          searchTopicStates(ctrl.topicStatesSearch);
        });
      }

      // Si pas de pagination
      if (ctrl.noPagination === 'true') {
        ctrl.withPagination = false;
        ctrl.typePagination = false;
      }
      else {
        ctrl.withPagination = true;
        // Si demande de pagination en lazyload
        if (ctrl.typePagination === 'lazyload') {
          ctrl.typePagination = 'lazyload';
        } else {
          ctrl.typePagination = 'normal';		// Pagination de type "normal"
        }
      }
    };

    ctrl.$onChanges = function(changes){
      if(_.isObject(changes.topicStatesSearch) && !changes.topicStatesSearch.isFirstChange() && changes.topicStatesSearch.currentValue){
        if (_.isNil(ctrl.objResultats)) {
          searchTopicStates(changes.topicStatesSearch.currentValue);
        }
        else {
          ctrl.loadingMoreSearch = false;
        }
      }
      if(_.isObject(changes.customPreference) && changes.customPreference.currentValue){
        ctrl.customPreference = changes.customPreference.currentValue;
      }
    };

    ctrl.$onDestroy = function () {
      listenerOnRemoveTopic();
      listenerOnUpdateCollab();
    };

    function updateListTopicStates(listTopicStates){
      if(_.isArray(ctrl.listTopicStates) && ctrl.listTopicStates.length && _.isArray(listTopicStates) && listTopicStates.length) {
        for(var i = 0; i < ctrl.listTopicStates.length; i++){
          for(var j = 0; j < listTopicStates.length; j++){
            if(listTopicStates[j].idTopicState === ctrl.listTopicStates[i].idTopicState){
              delete ctrl.listTopicStates[i].pending;
              ctrl.listTopicStates[i] = listTopicStates[j];
              /*
              ctrl.listTopicStates[i].getTopic().setTopicInformations(listTopicStates[j].getTopic().getTopicInformations());
              ctrl.listTopicStates[i].getTopic().setHistoriques(listTopicStates[j].getTopic().getHistoriques());
              var event = listTopicStates[j].getTopic().getEvenement();
              if(!_.isNil(event)) ctrl.listTopicStates[i].getTopic().setEvenement(event)*/
              break;
            }
          }
        }
      }
    }
    /*
    function onUpdateCollab(json){
      if(_.isArray(ctrl.listTopicStates) && ctrl.listTopicStates.length && _.isObject(json) && json.success && _.isArray(json.TopicStates) && json.TopicStates.length) {
        var TopicStates = $injector.get('TopicStates');
        for(var i = 0; i < ctrl.listTopicStates.length; i++){
          for(var j = 0; j < json.TopicStates.length; j++){
            if(json.TopicStates[j].idTopicState === ctrl.listTopicStates[i].idTopicState){
              var topicState = new TopicStates(json.TopicStates[j]);
              delete ctrl.listTopicStates[i].pending;
              ctrl.listTopicStates[i].getTopic().setTopicInformations(topicState.getTopic().getTopicInformations());
              ctrl.listTopicStates[i].getTopic().setHistoriques(topicState.getTopic().getHistoriques());
              break;
            }
          }
        }
        TopicStates = null;
      }
    }*/

    function searchTopicStates(obj){
      ctrl.paramsSearch = angular.copy(obj);
      getTopicStates(ctrl.paramsSearch);
    }

    /**
     * Recupère les TopicStates via Ajax
     * @param params
     */
    function getTopicStates(params) {

      ctrl.erreur = null;
      ctrl.loadingListTopicStates.show = true;	// Loading

      updateHote({
        erreur: null,
        loadingFiltreListTopicStates: true	// Passe le loading de l'hote à true
      });

      if ($stateParams.action === 'rechercheAnnuaire') {
        var stopFunction = true;
        // Si cas ou on ouvre l'annuaire alors on arrete pas la fonction
        if(($stateParams.noperso && $stateParams.immeuble && $stateParams.mandat && !$stateParams.lot) || (!$stateParams.nofourni && !$stateParams.noperso && !$stateParams.immeuble && !$stateParams.mandat && !$stateParams.lot)) stopFunction = false;

        if(stopFunction) {
          //UtilsService.resetStateParamsAction();
          return;
        }
      }

      TopicStatesService.getListTopicStates(params).then(function (obj) {
        ctrl.listTopicStates = obj.listTopicStates; // Liste d'instance de TopicStates
        ctrl.nbTotalTopicStates = obj.total;			// Nombre total de TopicsStates trouvées
        ctrl.erreur = obj.erreur;

        var objForHost = {
          nbTotalTopicStates: obj.total,	// Met à jour le nombre total de topic stats dans l'hote
          infosDashboard: obj.infosDashboard,
          loadingListTopicStates: false,	// Passe le loading de l'hote à false
          erreur: obj.erreur
        };

        updateHote(objForHost);

        var nbPageTopic = Math.ceil(obj.total / ctrl.topicStatesLimitPerPage);

        // Si différent de la page 1 et qu'il n'y à pas de resultat alors renvoi sur la dernière pages
        if (params.page !== 1 && !obj.listTopicStates.length) {
          $state.go($state.current.name, {'topicPage': nbPageTopic});
        }

      }).finally(function () {
        ctrl.loadingMoreSearch = false;
        ctrl.loadingListTopicStates.show = false;
        updateHote({
          loadingListTopicStates: false	// Passe le loading de l'hote à false
        });
      });
    }

    /**
     * Permet de mettre à jour des données dans l'hote
     */
    function updateHote(obj) {
      ctrl.onUpdate({
        objUpdate: obj
      });
    }

    function clickMoreTopics() {
      ctrl.loadingMoreSearch = true;
      ctrl.paramsSearch.page++;
      getTopicStates( ctrl.paramsSearch);
    }

    function onClickClearFiltre() {
      $scope.$emit('cleanFiltre');
    }


    /**
     * Supprimer un topicStates de la liste des topicStates
     * @param event
     * @param currentTopicStates
     */
    function onRemoveTopic(event, idTopicState) {
      // Parcour les topicStates
      for (var indexTS = 0; indexTS < ctrl.listTopicStates.length; indexTS++) {
        // Si le topic actuellement supprimé est celui de la bouclse
        if (ctrl.listTopicStates[indexTS].getIdTopicState() === idTopicState) {
          // Suppréssion du topic de la liste des topics
          ctrl.listTopicStates.splice(indexTS, 1);
        }
        /*
         // Si le topic actuellement supprimé est celui de la bouclse
         if(ctrl.listTopicStates[indexTS].getTopic().getIdTopic() === currentTopicStates.getTopic().getIdTopic()){
         // Suppréssion du topic de la liste des topics
         ctrl.listTopicStates.splice(indexTS,1);
         }*/
      }
    }

    /**
     * PAS UTILISE POUR LE MOMENT
     * Lors du changement d'état de la checkbox du topic
     * @param {void}
     * @returns {void}
     */
    /*
     vm.onChangeTopicCheckbox = function(){

     var topicsChecked = UtilsService.where(vm.topicStates,{checked:true});
     var nbTopic = vm.topicStates.length;
     var nbTopicChecked = topicsChecked.length;
     vm.nbTopicChecked = nbTopicChecked;

     // Si le nombre de topics total est égale au nombre de topic selectionnés
     if(nbTopic===nbTopicChecked) {

     $scope.$emit('change_alltopicsChecked',true);
     }
     else {
     $scope.$emit('change_alltopicsChecked',false);
     }

     $scope.$emit('change_nbTopicsChecked', nbTopicChecked);
     };

     // Ecoute le changement sur la checbox principal
     $scope.$on('change_topicChecked', function(event,data){
     angular.forEach(vm.topicStates,function(topicState){
     console.log(data);
     topicState.checked = data;
     });

     var topicsChecked = UtilsService.where(vm.topicStates,{checked:true});
     $scope.$emit('change_nbTopicsChecked', topicsChecked.length);
     });
     */

    /***** OLD A VOIR *****/
    /*
     /**
     * Affiche le bouton
     * @returns {boolean}


     function displayBtnNonLu(){
     var retour = false;
     switch(ctrl.etat){
     case 1:
     if($rootScope.nbTopicCoupCoeur){
     retour = true;
     }
     break;
     case 2:
     if($rootScope.nbTopicArchiveEtCache){
     retour = true;
     }
     break;
     default:
     if($rootScope.nbTopicNormal){
     retour = true;
     }
     }
     return retour;
     }
     function displayNewTopicState(){

     var arrayNewInstanceTopicStates = TopicStatesService.addNewTopicStates($rootScope.arrayNewTopicStateTmp);

     angular.forEach(arrayNewInstanceTopicStates,function(topicState){
     vm.topicStates.unshift(topicState);
     });
     $rootScope.arrayNewTopicStateTmp = [];


     //var arrayNewTopicState = $rootScope.arrayNewTopicState;
     //
     //var nbTopicState = TopicStatesService.getNbTotal();
     //
     //angular.forEach(arrayNewTopicState,function(topicState){
     //var instanceTopicState = TopicStatesService.setTopicState();
     //vm.topicStates.push(instanceTopicState);
     //nbTopicState++;
     //});
     //TopicStatesService.setNbTotal(nbTopicState);
     //
     //
     //$rootScope.nbNewTopicState = false;
     //$rootScope.arrayNewTopicState = [];
     }
     // Lors du clique sur la notification des nouveaux topics
     $scope.$on('onClickNotifNewTopic', function(){
     displayNewTopicState();
     });
     */
  }

})();

