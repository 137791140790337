(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .component('adminGestionAjoutGroupe', {
      templateUrl: 'app/parametrage/admin/admin-gestion-groupes/admin-gestion-ajout-groupe.component.html',
      controller: AdminGestionAjoutGroupeController,
      controllerAs: 'admingestionajoutgroupectrl',
      bindings: {
        groupe: '<',
        createGroupeNoUser: '<',  // Permet de savoir si le composan est utilisé pour créer un groupe non user
        onSubmit: '&',

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&'
      }
    });

  /** @ngInject */
  function AdminGestionAjoutGroupeController($rootScope, UtilsService, GroupesAdminService, ModalsService, COLLAB_VALUES) {
    var ctrl = this;
    ctrl.nomNewGroupe = null;
    ctrl.assignTopic = COLLAB_VALUES.ASSIGN_TOPIC_DEFAULT;
    ctrl.assignTopicCloture = COLLAB_VALUES.ASSIGN_TOPIC_CLOTURE_DEFAULT;
    ctrl.listCollaborateurs = [];         // Liste des groupes user fils du groupe, cette liste bouge
    ctrl.listGroupeFilsFixed = [];        // Liste des groupes user fils du groupe, cette liste ne change pas
    ctrl.listCollaborateursAjoute = [];   // Liste des groupes user qui vont être ajouté dans le groupe
    ctrl.onChangeCollaborateur = onChangeCollaborateur;
    ctrl.onClickRemoveCollaborateur = onClickRemoveCollaborateur;
    ctrl.onChangeAssignTopic = onChangeAssignTopic;
    ctrl.onClickSave = onClickSave;
    ctrl.onClickClose = onClickClose;



    ctrl.$onInit = function () {

      if(ctrl.resolve) {
        ctrl.isModal = true;
        ctrl.groupe = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'groupe'));
        ctrl.createGroupeNoUser = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'createGroupeNoUser'));
      }
      if(!ctrl.groupe) ModalsService.alertErreur('Pas de groupe');

      if(ctrl.createGroupeNoUser) {
        //--------------- CKEDITOR ---------------------------
        ctrl.instanceCKEDITOR = false;

        var ckeditor = UtilsService.ckeditorBase();
        ckeditor.editorOptions.height = 200;
        // Editor options.
        ctrl.instanceCKEDITOR = ckeditor.editor;
        ctrl.ckeditorOptions 	= ckeditor.editorOptions;	// Options CKEDITOR
        ctrl.ckeditorOnReady 	= ckeditorOnReady;	// Fonction onReady de CKEDITOR

        // Called when the editor is completely ready.
        function ckeditorOnReady() {
          // Ajout l'instance qui vien d'être créé dans le pool d'instance de CKEDITOR
          $rootScope._poolInstancesCKEDITOR[0] = ctrl.instanceCKEDITOR;
        }

        /**
         * Destroy une éventuelle instance de CKEDITOR
         */
        function destroyCKEDITOR(){
          if(ctrl.instanceCKEDITOR){
            ctrl.instanceCKEDITOR.removeAllListeners();
            ctrl.instanceCKEDITOR.destroy();
          }
        }

        //--------------- FIN CKEDITOR ---------------------------
      }
      else createListCollaborateurs(ctrl.groupe);

    };

    ctrl.$onDestroy = function () {
    };

    /**
     * Permet de créer la liste des collaborateurs
     * @param groupe
     */
    function createListCollaborateurs(groupe){
      if(angular.isObject(groupe)) {
        if(angular.isArray(groupe.groupesFils) && groupe.groupesFils.length) {
          for(var i = 0; i < groupe.groupesFils.length; i++) {
            if(groupe.groupesFils[i].hasUser()) {
              addCollaborateurInList(groupe.groupesFils[i]);
            }
          }
        }
        else if(groupe.hasUser()) {
          addCollaborateurInList(groupe);
        }
      }
      ctrl.listGroupeFilsFixed = angular.copy(ctrl.listCollaborateurs);
    }

    /**
     * Permet d'ajouter le collaborateur dans la liste
     * @param groupe
     */
    function addCollaborateurInList(groupe){
      var exist = false;
      if(ctrl.listCollaborateurs.length) {
        for(var i = 0; i < ctrl.listCollaborateurs.length; i++) {
          if(ctrl.listCollaborateurs[i]) {
            if(ctrl.listCollaborateurs[i].idGroupe === groupe.idGroupe) {
              exist = true;
              break;
            }
          }
        }
      }
      if(!exist && groupe.hasUser()) ctrl.listCollaborateurs.push(groupe);
    }

    /**
     * Lors de la selection/deselection d'un groupe depuis le composant choose-...
     * @param obj
     */
    function onChangeCollaborateur(obj){
      if(obj.hasOwnProperty('collaborateurs')) {

        if(!ctrl.createGroupeNoUser) {
          // Si il y a des groupe fils cochés
          if (obj.collaborateurs.length) {

            // Si il y a des groupeFils dans la liste fixed
            if (ctrl.listGroupeFilsFixed.length) {
              // Récupère un array avec les valeur de "obj.collaborateurs" qui ne sont pas présente dans "listGroupeFilsFixed"
              ctrl.listCollaborateursAjoute = _.differenceBy(obj.collaborateurs, ctrl.listGroupeFilsFixed, 'idGroupe');
            }
            // Si pas de groupeFils fixed alors on gard tout les groupe coché
            else ctrl.listCollaborateursAjoute = angular.copy(obj.collaborateurs);
          }
          // Si pas de groupe coché alors liste a ajouter vide
          else ctrl.listCollaborateursAjoute = [];
        }
        ctrl.listCollaborateurs = angular.copy(obj.collaborateurs);
      }
    }

    /**
     * Permet de retirer un groupe user de la liste temporaire des groupes à ajouter
     * @param groupe
     */
    function onClickRemoveCollaborateur(groupe) {
      if(ctrl.listCollaborateursAjoute.length){
        var indexFound = null;
        // Parcour les groupes à ajouter
        for(var i = 0; i < ctrl.listCollaborateursAjoute.length; i++) {
          if(ctrl.listCollaborateursAjoute[i].idGroupe === groupe.idGroupe) {
            indexFound = i;
            break;
          }
        }
        if(angular.isNumber(indexFound)) ctrl.listCollaborateursAjoute.splice(indexFound, 1);
      }

      var listCollaborateur = [];
      if(ctrl.listCollaborateurs.length) {
        var index2Found = null;
        for(var j = 0; j < ctrl.listCollaborateurs.length; j++) {
          if(ctrl.listCollaborateurs[j].idGroupe !== groupe.idGroupe) {
            listCollaborateur.push(ctrl.listCollaborateurs[j]);
          }
        }
      }
      ctrl.listCollaborateurs = listCollaborateur;
    }

    function onChangeAssignTopic(){
      if(_.isNil(ctrl.assignTopic) || !ctrl.assignTopic){
        ctrl.assignTopicCloture = false;
      }
    }

    /**
     * Lors du clique sur le bouton sauvegarder
     */
    function onClickSave(){
      var listIdGroupe = [];

      if(!ctrl.createGroupeNoUser) {

        if(ctrl.listCollaborateursAjoute.length) {
          for(var i = 0; i < ctrl.listCollaborateursAjoute.length; i++) {
            listIdGroupe.push(ctrl.listCollaborateursAjoute[i].getIdGroupe());
          }
        }

        if(!listIdGroupe.length) ModalsService.alertErreur('Pas de collaborateur sélectionné !');
        else {
          ctrl.loading = true;
          GroupesAdminService
            .addListGroupeInGroupe(ctrl.groupe.getIdGroupe(), listIdGroupe, !ctrl.assignTopic, ctrl.assignTopicCloture)
            .then(function(groupe){
              groupe.open = true;
              emit({action: 'refreshGroupe', groupe: groupe});
            })
            .catch(function (msg) {
              ModalsService.alertErreur(msg);
            })
            .finally(function(){
              ctrl.loading = false;
            });
        }
      }
      else {
        if(ctrl.listCollaborateurs.length) {
          for(var j = 0; j < ctrl.listCollaborateurs.length; j++) {
            listIdGroupe.push(ctrl.listCollaborateurs[j].getIdGroupe());
          }
        }

        var newNomGroupe = null;
        if(!_.isNil(ctrl.nomNewGroupe)) newNomGroupe = UtilsService.cleanNomGroupe(ctrl.nomNewGroupe);

        ctrl.loading = true;
        GroupesAdminService
          .createGroupeNoUserInGroupe(ctrl.groupe.getIdGroupe(), newNomGroupe, listIdGroupe, ctrl.instanceCKEDITOR.getData(), !ctrl.assignTopic, ctrl.assignTopicCloture)
          .then(function(groupe){
            groupe.open = true;
            emit({action: 'refreshGroupe', groupe: groupe});
          })
          .catch(function (msg) {
            ModalsService.alertErreur(msg);
          })
          .finally(function(){
            ctrl.loading = false;
          });
      }
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      if(ctrl.isModal) ctrl.close({$value: objRetour});
      else ctrl.onSubmit({obj: objRetour})
    }


    function onClickClose(){
      ctrl.dismiss({$value: 'cancel'});
    }
  }
})();

