(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.Receipt
   * @description
   * # Receipt
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('Receipt', ReceiptModel);

  /** @ngInject */
  function ReceiptModel(Main, Barcode, ReceiptLineItem, MatchedKeyword, MatchedLineItemsReceipt, ReceiptVAT) {

    function Receipt(data) {
      Main.apply(this, arguments);
      this.hydrate(data);
    }

    Receipt.prototype = {

      model: 'Receipt',
      isReceipt: true,

      // amount
      getAmount: function(){
        return this.amount;
      },
      setAmount: function(val){
        this.amount = val;
      },

      // amount_change
      getAmountChange: function(){
        return this.amountChange;
      },
      setAmountChange: function(val){
        this.amountChange = val;
      },

      // amount_shipping
      getAmountShipping: function(){
        return this.amountShipping;
      },
      setAmountShipping: function(val){
        this.amountShipping = val;
      },

      // amountexvat
      getAmountexvat: function(){
        return this.amountexvat;
      },
      setAmountexvat: function(val){
        this.amountexvat = val;
      },

      // barcodes
      getBarcodes: function(){
        return this.barcodes;
      },
      setBarcodes: function(list){
        if(_.isArray(list)){
          list = list.map(function(item) {
            if(!item.isModel) item = new Barcode(item);
            return item;
          });
        }
        this.barcodes = list;
      },

      // currency
      getCurrency: function(){
        return this.currency;
      },
      setCurrency: function(val){
        this.currency = val;
      },

      // customer_address
      getCustomerAddress: function(){
        return this.customerAddress;
      },
      setCustomerAddress: function(val){
        this.customerAddress = val;
      },

      getCustomerAdresse: function(){
        var adresse = [];
        if(!_.isEmpty(this.getCustomerHouseNumber())) adresse.push(this.getCustomerHouseNumber());
        if(!_.isEmpty(this.getCustomerStreetName())) adresse.push(this.getCustomerStreetName());
        if(_.isEmpty(adresse)) adresse.push(this.getCustomerAddress());
        return adresse.join(' ');
      },

      // customerBankAccountNumber
      getCustomerBankAccountNumber: function(){
        return this.customerBankAccountNumber;
      },
      setCustomerBankAccountNumber: function(val){
        this.customerBankAccountNumber = val;
      },

      // customerBankAccountNumberBic
      getCustomerBankAccountNumberBic: function(){
        return this.customerBankAccountNumberBic;
      },
      setCustomerBankAccountNumberBic: function(val){
        this.customerBankAccountNumberBic = val;
      },

      // customerCity
      getCustomerCity: function(){
        return this.customerCity;
      },
      setCustomerCity: function(val){
        this.customerCity = val;
      },

      // customerCocNumber
      getCustomerCocNumber: function(){
        return this.customerCocNumber;
      },
      setCustomerCocNumber: function(val){
        this.customerCocNumber = val;
      },

      // customerCountry
      getCustomerCountry: function(){
        return this.customerCountry;
      },
      setCustomerCountry: function(val){
        this.customerCountry = val;
      },

      // customerEmail
      getCustomerEmail: function(){
        return this.customerEmail;
      },
      setCustomerEmail: function(val){
        this.customerEmail = val;
      },

      // customerHouseNumber
      getCustomerHouseNumber: function(){
        return this.customerHouseNumber;
      },
      setCustomerHouseNumber: function(val){
        this.customerHouseNumber = val;
      },

      // customerMunicipality
      getCustomerMunicipality: function(){
        return this.customerMunicipality;
      },
      setCustomerMunicipality: function(val){
        this.customerMunicipality = val;
      },

      // customerName
      getCustomerName: function(){
        return this.customerName;
      },
      setCustomerName: function(val){
        this.customerName = val;
      },

      // customerNumber
      getCustomerNumber: function(){
        return this.customerNumber;
      },
      setCustomerNumber: function(val){
        this.customerNumber = val;
      },

      // customerPhone
      getCustomerPhone: function(){
        return this.customerPhone;
      },
      setCustomerPhone: function(val){
        this.customerPhone = val;
      },

      // customerProvince
      getCustomerProvince: function(){
        return this.customerProvince;
      },
      setCustomerProvince: function(val){
        this.customerProvince = val;
      },

      // customerReference
      getCustomerReference: function(){
        return this.customerReference;
      },
      setCustomerReference: function(val){
        this.customerReference = val;
      },

      // customerStreetName
      getCustomerStreetName: function(){
        return this.customerStreetName;
      },
      setCustomerStreetName: function(val){
        this.customerStreetName = val;
      },

      // customerVatNumber
      getCustomerVatNumber: function(){
        return this.customerVatNumber;
      },
      setCustomerVatNumber: function(val){
        this.customerVatNumber = val;
      },

      // customerWebsite
      getCustomerWebsite: function(){
        return this.customerWebsite;
      },
      setCustomerWebsite: function(val){
        this.customerWebsite = val;
      },

      // customerZipcode
      getCustomerZipcode: function(){
        return this.customerZipcode;
      },
      setCustomerZipcode: function(val){
        this.customerZipcode = val;
      },

      // date
      getDate: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format) format = null;
          return UtilsService.parseDate(this.date, format);
        }
        return this.date;
      },
      setDate: function(val){
        this.date = val;
      },

      // documentLanguage
      getDocumentLanguage: function(){
        return this.documentLanguage;
      },
      setDocumentLanguage: function(val){
        this.documentLanguage = val;
      },

      // documentSubject
      getDocumentSubject: function(){
        return this.documentSubject;
      },
      setDocumentSubject: function(val){
        this.documentSubject = val;
      },

      /**
       * invoice
       * receipt
       * bank_transaction
       * bank_overview
       * parking
       * petrol
       * ticket
       * boarding_pass
       * booking_payment_confirmation
       * other
       * @returns {*}
       */
      getDocumentType: function(){
        return this.documentType;
      },
      setDocumentType: function(val){
        this.documentType = val;
      },

      // hash
      getHash: function(){
        return this.hash;
      },
      setHash: function(val){
        this.hash = val;
      },

      // hashDuplicate
      getHashDuplicate: function(){
        return this.hashDuplicate;
      },
      setHashDuplicate: function(val){
        this.hashDuplicate = val;
      },

      // invoiceNumber
      getInvoiceNumber: function(){
        return this.invoiceNumber;
      },
      setInvoiceNumber: function(val){
        this.invoiceNumber = val;
      },

      /**
       * invoice
       * credit_invoice
       * @returns {*}
       */
      getInvoiceType: function(){
        return this.invoiceType;
      },
      setInvoiceType: function(val){
        this.invoiceType = val;
      },

      // lines
      getLines: function(){
        return this.lines;
      },
      setLines: function(list){
        if(_.isArray(list)){
          list = list.map(function(item) {
            if(!item.isModel) item = new ReceiptLineItem(item);
            return item;
          });
        }
        this.lines = list;
      },

      // matchedKeywords
      getMatchedKeywords: function(){
        return this.matchedKeywords;
      },
      setMatchedKeywords: function(list){
        if(_.isArray(list)){
          list = list.map(function(item) {
            if(!item.isModel) item = new MatchedKeyword(item);
            return item;
          });
        }
        this.matchedKeywords = list;
      },

      // matchedLineitems
      getMatchedLineitems: function(){
        return this.matchedLineitems;
      },
      setMatchedLineitems: function(list){
        if(_.isArray(list)){
          list = list.map(function(item) {
            if(!item.isModel) item = new MatchedLineItemsReceipt(item);
            return item;
          });
        }
        this.matchedLineitems = list;
      },

      // matchedPurchaseOrderId
      getMatchedPurchaseOrderId: function(){
        return this.matchedPurchaseOrderId;
      },
      setMatchedPurchaseOrderId: function(val){
        this.matchedPurchaseOrderId = val;
      },

      // merchantAddress
      getMerchantAddress: function(){
        return this.merchantAddress;
      },
      setMerchantAddress: function(val){
        this.merchantAddress = val;
      },

      getMerchantAdresse: function(){
        var adresse = [];
        if(!_.isEmpty(this.getMerchantHouseNumber())) adresse.push(this.getMerchantHouseNumber());
        if(!_.isEmpty(this.getMerchantStreetName())) adresse.push(this.getMerchantStreetName());
        if(_.isEmpty(adresse)) adresse.push(this.getMerchantAddress());
        return adresse.join(' ');
      },

      // merchantBankAccountNumber
      getMerchantBankAccountNumber: function(){
        return this.merchantBankAccountNumber;
      },
      setMerchantBankAccountNumber: function(val){
        this.merchantBankAccountNumber = val;
      },

      // merchantBankAccountNumberBic
      getMerchantBankAccountNumberBic: function(){
        return this.merchantBankAccountNumberBic;
      },
      setMerchantBankAccountNumberBic: function(val){
        this.merchantBankAccountNumberBic = val;
      },

      // merchantBankDomesticAccountNumber
      getMerchantBankDomesticAccountNumber: function(){
        return this.merchantBankDomesticAccountNumber;
      },
      setMerchantBankDomesticAccountNumber: function(val){
        this.merchantBankDomesticAccountNumber = val;
      },

      // merchantBankAccountNumber
      getMerchantBankDomesticBankCode: function(){
        return this.merchantBankDomesticBankCode;
      },
      setMerchantBankDomesticBankCode: function(val){
        this.merchantBankDomesticBankCode = val;
      },

      // merchantChainLiabilityAmount
      getMerchantChainLiabilityAmount: function(){
        return this.merchantChainLiabilityAmount;
      },
      setMerchantChainLiabilityAmount: function(val){
        this.merchantChainLiabilityAmount = val;
      },

      // merchantChainLiabilityBankAccountNumber
      getMerchantChainLiabilityBankAccountNumber: function(){
        return this.merchantChainLiabilityBankAccountNumber;
      },
      setMerchantChainLiabilityBankAccountNumber: function(val){
        this.merchantChainLiabilityBankAccountNumber = val;
      },

      // merchantCity
      getMerchantCity: function(){
        return this.merchantCity;
      },
      setMerchantCity: function(val){
        this.merchantCity = val;
      },

      // merchantCocNumber
      getMerchantCocNumber: function(){
        return this.merchantCocNumber;
      },
      setMerchantCocNumber: function(val){
        this.merchantCocNumber = val;
      },

      // merchantCountry
      getMerchantCountry: function(){
        return this.merchantCountry;
      },
      setMerchantCountry: function(val){
        this.merchantCountry = val;
      },

      // merchantCountryCode
      getMerchantCountryCode: function(){
        return this.merchantCountryCode;
      },
      setMerchantCountryCode: function(val){
        this.merchantCountryCode = val;
      },

      // merchantEmail
      getMerchantEmail: function(){
        return this.merchantEmail;
      },
      setMerchantEmail: function(val){
        this.merchantEmail = val;
      },

      // merchantHouseNumber
      getMerchantHouseNumber: function(){
        return this.merchantHouseNumber;
      },
      setMerchantHouseNumber: function(val){
        this.merchantHouseNumber = val;
      },

      // merchantId
      getMerchantId: function(){
        return this.merchantId;
      },
      setMerchantId: function(val){
        this.merchantId = val;
      },

      // merchantMainActivityCode
      getMerchantMainActivityCode: function(){
        return this.merchantMainActivityCode;
      },
      setMerchantMainActivityCode: function(val){
        this.merchantMainActivityCode = val;
      },

      // merchantId
      getMerchantMunicipality: function(){
        return this.merchantMunicipality;
      },
      setMerchantMunicipality: function(val){
        this.merchantMunicipality = val;
      },

      // merchantName
      getMerchantName: function(){
        return this.merchantName;
      },
      setMerchantName: function(val){
        this.merchantName = val;
      },

      // merchantPhone
      getMerchantPhone: function(){
        return this.merchantPhone;
      },
      setMerchantPhone: function(val){
        this.merchantPhone = val;
      },

      // merchantProvince
      getMerchantProvince: function(){
        return this.merchantProvince;
      },
      setMerchantProvince: function(val){
        this.merchantProvince = val;
      },

      // merchantStreetName
      getMerchantStreetName: function(){
        return this.merchantStreetName;
      },
      setMerchantStreetName: function(val){
        this.merchantStreetName = val;
      },

      // merchantVatNumber
      getMerchantVatNumber: function(){
        return this.merchantVatNumber;
      },
      setMerchantVatNumber: function(val){
        this.merchantVatNumber = val;
      },

      // merchantWebsite
      getMerchantWebsite: function(){
        return this.merchantWebsite;
      },
      setMerchantWebsite: function(val){
        this.merchantWebsite = val;
      },

      // merchantZipcode
      getMerchantZipcode: function(){
        return this.merchantZipcode;
      },
      setMerchantZipcode: function(val){
        this.merchantZipcode = val;
      },

      // orderNumber
      getOrderNumber: function(){
        return this.orderNumber;
      },
      setOrderNumber: function(val){
        this.orderNumber = val;
      },

      // packageNumber
      getPackageNumber: function(){
        return this.packageNumber;
      },
      setPackageNumber: function(val){
        this.packageNumber = val;
      },

      // paymentAuthCode
      getPaymentAuthCode: function(){
        return this.paymentAuthCode;
      },
      setPaymentAuthCode: function(val){
        this.paymentAuthCode = val;
      },

      // paymentCardAccountNumber
      getPaymentCardAccountNumber: function(){
        return this.paymentCardAccountNumber;
      },
      setPaymentCardAccountNumber: function(val){
        this.paymentCardAccountNumber = val;
      },

      // paymentCardBank
      getPaymentCardBank: function(){
        return this.paymentCardBank;
      },
      setPaymentCardBank: function(val){
        this.paymentCardBank = val;
      },

      // paymentCardIssuer
      getPaymentCardIssuer: function(){
        return this.paymentCardIssuer;
      },
      setPaymentCardIssuer: function(val){
        this.paymentCardIssuer = val;
      },

      // paymentCardNumber
      getPaymentCardNumber: function(){
        return this.paymentCardNumber;
      },
      setPaymentCardNumber: function(val){
        this.paymentCardNumber = val;
      },

      // paymentDueDate
      getPaymentDueDate: function(){
        return this.paymentDueDate;
      },
      setPaymentDueDate: function(val){
        this.paymentDueDate = val;
      },

      // paymentSlipCode
      getPaymentSlipCode: function(){
        return this.paymentSlipCode;
      },
      setPaymentSlipCode: function(val){
        this.paymentSlipCode = val;
      },

      // paymentSlipCustomerNumber
      getPaymentSlipCustomerNumber: function(){
        return this.paymentSlipCustomerNumber;
      },
      setPaymentSlipCustomerNumber: function(val){
        this.paymentSlipCustomerNumber = val;
      },

      // paymentSlipReferenceNumber
      getPaymentSlipReferenceNumber: function(){
        return this.paymentSlipReferenceNumber;
      },
      setPaymentSlipReferenceNumber: function(val){
        this.paymentSlipReferenceNumber = val;
      },

      /**
       * cash
       * creditcard
       * pin
       * bank
       * @returns {*}
       */
      getPaymentmethod: function(){
        return this.paymentmethod;
      },
      setPaymentmethod: function(val){
        this.paymentmethod = val;
      },

      // purchasedate
      getPurchasedate: function(){
        return this.purchasedate;
      },
      setPurchasedate: function(val){
        this.purchasedate = val;
      },

      // purchasetime
      getPurchasetime: function(){
        return this.purchasetime;
      },
      setPurchasetime: function(val){
        this.purchasetime = val;
      },

      // rawText
      getRawText: function(){
        return this.rawText;
      },
      setRawText: function(val){
        this.rawText = val;
      },

      // receiptNumber
      getReceiptNumber: function(){
        return this.receiptNumber;
      },
      setReceiptNumber: function(val){
        this.receiptNumber = val;
      },

      // server
      getServer: function(){
        return this.server;
      },
      setServer: function(val){
        this.server = val;
      },

      // shopNumber
      getShopNumber: function(){
        return this.shopNumber;
      },
      setShopNumber: function(val){
        this.shopNumber = val;
      },

      // tableGroup
      getTableGroup: function(){
        return this.tableGroup;
      },
      setTableGroup: function(val){
        this.tableGroup = val;
      },

      // tableNumber
      getTableNumber: function(){
        return this.tableNumber;
      },
      setTableNumber: function(val){
        this.tableNumber = val;
      },

      // terminalNumber
      getTerminalNumber: function(){
        return this.terminalNumber;
      },
      setTerminalNumber: function(val){
        this.terminalNumber = val;
      },

      // transactionNumber
      getTransactionNumber: function(){
        return this.transactionNumber;
      },
      setTransactionNumber: function(val){
        this.transactionNumber = val;
      },

      // transactionReference
      getTransactionReference: function(){
        return this.transactionReference;
      },
      setTransactionReference: function(val){
        this.transactionReference = val;
      },

      /**
       * purchase_none
       * vat_eu
       * vat_reverse_charged
       * vat_exempted
       * vat_margin
       * @returns {*}
       */
      getVatContext: function(){
        return this.vatContext;
      },
      setVatContext: function(val){
        this.vatContext = val;
      },

      // vatamount
      getVatamount: function(){
        return this.vatamount;
      },
      setVatamount: function(val){
        this.vatamount = val;
      },

      // vatitems
      getVatitems: function(){
        return this.vatitems;
      },
      setVatitems: function(list){
        if(_.isArray(list)){
          list = list.map(function(item) {
            if(!item.isModel) item = new ReceiptVAT(item);
            return item;
          });
        }
        this.vatitems = list;
      },

      hasCustomerDatas: function(){
        if(this.getCustomerName()) return true;
        if(this.getCustomerReference()) return true;
        if(this.getCustomerNumber()) return true;
        if(this.getCustomerCocNumber()) return true;
        if(this.getCustomerVatNumber()) return true;
        if(this.getCustomerPhone()) return true;
        if(this.getCustomerEmail()) return true;
        if(this.getCustomerWebsite()) return true;
        if(this.getCustomerHouseNumber()) return true;
        if(this.getCustomerStreetName()) return true;
        if(this.getCustomerAddress()) return true;
        if(this.getCustomerZipcode()) return true;
        if(this.getCustomerCity()) return true;
        if(this.getCustomerProvince()) return true;
        if(this.getCustomerCountry()) return true;
        if(this.getCustomerBankAccountNumber()) return true;
        if(this.getCustomerBankAccountNumberBic()) return true;
        return false;
      },

      hasMerchantDatas: function(){
        if(this.getMerchantName()) return true;
        if(this.getMerchantPhone()) return true;
        if(this.getMerchantEmail()) return true;
        if(this.getMerchantWebsite()) return true;
        if(this.getMerchantAdresse()) return true;
        if(this.getMerchantZipcode()) return true;
        if(this.getMerchantCity()) return true;
        if(this.getMerchantProvince()) return true;
        if(this.getMerchantCountry()) return true;
        if(this.getMerchantCocNumber()) return true;
        if(this.getMerchantVatNumber()) return true;
        if(this.getMerchantBankAccountNumber()) return true;
        if(this.getMerchantBankAccountNumberBic()) return true;
        if(this.getMerchantBankDomesticAccountNumber()) return true;
        if(this.getMerchantBankDomesticBankCode()) return true;
        return false;
      }
    };

    angular.extend(Receipt.prototype, Main.prototype);

    return Receipt;
  }
})();
