(function () {
    'use strict';

    /**
     * @desc Formulaire de contenu type Message
     * @example <contenu-message-formulaire></contenu-message-formulaire>
     */

    angular
        .module('collaboreApp')
        .component('piecesJointes', {
            templateUrl: 'app/topics/pieces-jointes.component.html',
            bindings: {
                contenu: '<itemContenu',
                isDevis: '<',
                property: '@',
                documents: '<',
                allowDelete: '<',
                disableDownload: '<',
                saveAuto: '<',
                onOpen: '&',
                onUpdate: '&'
            },
            controllerAs: 'piecesjointesctrl',
            controller: PiecesJointesController

        });

    /*@ngInject*/
    function PiecesJointesController($scope, $q, $attrs, COLLAB_CONF, $filter, sweet, UtilsService, DocumentManagerService, ModalsService) {
        var ctrl = this;

        ctrl.loadingDocuments = DocumentManagerService.loadingDocuments;

        ctrl.getPiecesJointesAsync = getPiecesJointesAsync;
        ctrl.showTousTelecharger = showTousTelecharger;
        ctrl.showTelerchargerSelection = showTelerchargerSelection;
        ctrl.deleteFromContenu = deleteFromContenu;
        ctrl.clickDocument = clickDocument;
        ctrl.downloadAllForContenu = downloadAllForContenu;
        ctrl.download = download;
        ctrl.downloadSelected = downloadSelected;
        ctrl.nbDocumentChecked = nbDocumentChecked;

        ctrl.onClickOpen = onClickOpen;

        ctrl.$onInit = function () {

            //ctrl.contenu = angular.copy(ctrl.itemContenu);
            ctrl.listeDocuments = [];

            if (ctrl.isDevis) {
                var devis = angular.copy(ctrl.documents);
                devis.img = COLLAB_CONF.MIDDLE_LOADING_SRC;
                ctrl.listeDocuments = [devis];
            } else {
                if (angular.isArray(ctrl.documents)) {
                    ctrl.listeDocuments = angular.copy(ctrl.documents);
                    angular.forEach(ctrl.listeDocuments, function (document) {
                        document.img = COLLAB_CONF.MIDDLE_LOADING_SRC;
                    });
                }
            }

        };


        function convertToDataURLviaCanvas(url, callbackOnload, callbackError, outputFormat) {
            var img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = function () {
                var canvas = document.createElement('CANVAS');
                var ctx = canvas.getContext('2d');
                var dataURL;
                canvas.height = this.height;
                canvas.width = this.width;
                ctx.drawImage(this, 0, 0);
                dataURL = canvas.toDataURL(outputFormat);
                callbackOnload(dataURL);
                canvas = null;
            };
            img.onerror = function () {
                if (callbackError) {
                    callbackError();
                }
            };
            img.src = url;
        }

        function getPiecesJointesAsync(index) {

            var deferred = $q.defer();

            var doc = ctrl.listeDocuments[index];


            var next = parseInt(index) + 1;

            if (doc) {

                if ($filter('isImage')(doc.extension)) {
                    if (doc.guid) {

                        var url = DocumentManagerService.getUrlStream(doc.guid, ctrl.contenu.getIdContenu(), null, true);

                        convertToDataURLviaCanvas(url,
                            // Success
                            function (base64Img) {
                                doc.img = base64Img;
                                deferred.resolve(next);
                            },
                            // Erreur
                            function () {
                                doc.img = COLLAB_CONF.IMG_NO_EXIST;
                                deferred.resolve(next);
                            },
                            'image/jpeg');

                    } else {
                        deferred.resolve(next);
                    }
                } else {
                    deferred.resolve(next);
                }
            } else {
                deferred.reject();
            }

            deferred.promise.then(getPiecesJointesAsync);
        }


        $scope.$watchCollection('piecesjointesctrl.documents', function (newVal, oldVal) {

            var nbCurrent = 0, nbOld = 0;
            if (angular.isArray(newVal)) {
                nbCurrent = newVal.length;
            } else if (angular.isObject(newVal)) {
                nbCurrent = 1;
            }

            if (angular.isArray(oldVal)) {
                nbOld = oldVal.length;
            } else if (angular.isObject(oldVal)) {
                nbOld = 1;
            }

            // Si suppression d'un document
            if (nbCurrent < nbOld) {

                if (angular.isArray(oldVal) || angular.isArray(newVal)) {

                    angular.forEach(oldVal, function (docOld) {

                        var isPresent = false;
                        angular.forEach(newVal, function (docNew) {
                            if (docNew.getGuid() === docOld.getGuid()) {
                                isPresent = true;
                            }
                        });

                        // Si pas présent dans le nouveau tableau
                        if (!isPresent) {
                            var listeDocTmp = [];
                            // Parcour les document qui sont dans listeDoc
                            angular.forEach(ctrl.listeDocuments, function (doc) {
                                if (doc.getGuid() !== docOld.getGuid()) {
                                    listeDocTmp.push(doc);
                                }
                            });
                            ctrl.listeDocuments = listeDocTmp;
                        }

                    });

                } else if (angular.isObject(newVal)) {
                    delete ctrl.listeDocuments[0];
                }

            } else if (nbCurrent > nbOld) {
                // Si il y a déjà des documents
                if (ctrl.listeDocuments.length) {
                    var indexGo = false;

                    // Parcour les nouveaux doc
                    angular.forEach(newVal, function (docNew) {

                        var isPresent = false;

                        // Parcour les doc actuel
                        angular.forEach(ctrl.listeDocuments, function (doc) {
                            // Si le nouveau doc est différent
                            if (doc.getGuid() === docNew.getGuid()) {
                                isPresent = true;
                            }
                        });

                        if (!isPresent) {
                            indexGo = ctrl.listeDocuments.length;
                            ctrl.listeDocuments.push(docNew);
                        }
                    });

                    if (indexGo !== false) {
                        getPiecesJointesAsync(indexGo);
                    }

                } else {
                    if (angular.isArray(newVal)) {
                        ctrl.listeDocuments = newVal;
                        angular.forEach(ctrl.listeDocuments, function (document) {
                            document.img = COLLAB_CONF.MIDDLE_LOADING_SRC;
                        });

                    } else if (angular.isObject(newVal)) {
                        newVal.img = COLLAB_CONF.MIDDLE_LOADING_SRC;
                        ctrl.listeDocuments.push(newVal);
                    }

                    getPiecesJointesAsync(0);
                }

            } else {
                if (angular.isArray(newVal)) {
                    ctrl.listeDocuments = angular.copy(newVal);
                }
                else if (angular.isObject(newVal)) {
                    ctrl.listeDocuments = [angular.copy(newVal)];
                }

                getPiecesJointesAsync(0);
            }

        });

        function showTousTelecharger() {
            var retour = false;
            if (angular.isArray(ctrl.listeDocuments) && !ctrl.disableDownload) {
                if (ctrl.listeDocuments.length >= 2) {
                    retour = true;
                }
            }
            return retour;
        }

        function showTelerchargerSelection() {
            var retour = false;
            if (angular.isArray(ctrl.listeDocuments) && !ctrl.disableDownload) {
                if (nbDocumentChecked() && ctrl.listeDocuments.length >= 2) {
                    retour = true;
                }
            }
            return retour;
        }


        function onUpdate(document, action) {
            var objUpdate = {
                objUpdate: {
                    //'listeDocument':ctrl.listeDocuments,
                    contenu: ctrl.contenu,
                    saveAuto: ctrl.saveAuto,
                    document: document,
                    action: action,
                    property: ctrl.property
                }
            };

            ctrl.onUpdate(objUpdate);
            /*
             if(ctrl.saveAuto) {

             ctrl.onUpdate(objUpdate).then(function (contenuEvenement) {
             if (angular.isArray(ctrl.listeDocuments)) {
             ctrl.listeDocuments = contenuEvenement.listeDocument;
             } else {
             ctrl.listeDocuments = [];
             }
             });
             }else{
             ctrl.onUpdate(objUpdate);
             }*/
        }

        function deleteFromContenu(document) {

            ModalsService.confirm('Suppression du document', 'Voulez-vous vraiment supprimer le document ?').then(function (modal) {

                var error = false;
                var valGuid = document.guid;
                if (angular.isDefined(ctrl.property) && ctrl.property !== '') {

                    // Recupere la liste des document d'un contenu sans le doc a supprimer
                    ctrl.listeDocuments = UtilsService.removeWith(ctrl.listeDocuments, {guid: valGuid});

                    // Si le contenu à) bien la propriété envoyé
                    if (ctrl.contenu.hasOwnProperty(ctrl.property)) ctrl.contenu[ctrl.property] = ctrl.listeDocuments;
                    else error = true;

                }
                else {
                    if (!ctrl.isDevis) {

                        // Recupere la liste des document d'un contenu sans le doc a supprimer
                        ctrl.listeDocuments = UtilsService.removeWith(ctrl.listeDocuments, {guid: valGuid});
                        ctrl.contenu.listeDocument = ctrl.listeDocuments;

                    } else {
                        ctrl.contenu.documentDevis = '';
                        ctrl.listeDocuments = '';
                    }
                }

                if (!error) {

                    ctrl.contenu.setUpdate(true);

                    onUpdate(document, 'delete');

                    // var valGuid = document.guid;
                    // var docObjet = false;
                    //
                    // if(!ctrl.isDevis){
                    //
                    // 	for(var i = 0; i < ctrl.listeDocuments.length ; i++){
                    // 		if(ctrl.listeDocuments[i].guid===valGuid){
                    // 			docObjet = ctrl.listeDocuments[i];
                    // 			break;
                    // 		}
                    // 	}
                    // }else{
                    // 	docObjet = ctrl.contenu.documentDevis;
                    // }
                    //
                    //
                    //
                    // if(!ctrl.isDevis){
                    //
                    // 	// Recupere la liste des document d'un contenu sans le doc a supprimer
                    // 	ctrl.listeDocuments = UtilsService.removeWith(ctrl.listeDocuments,{guid:valGuid});
                    // 	ctrl.contenu.listeDocument = ctrl.listeDocuments;
                    //
                    // }else{
                    // 	ctrl.contenu.documentDevis = '';
                    // 	ctrl.listeDocuments = '';
                    // }
                    //
                    //
                    //
                    // if(ctrl.saveAuto){
                    //
                    //
                    // 	ctrl.contenu.deleteDocument(docObjet).then(function(){
                    // 	//ctrl.contenu.setUpdate(true);
                    // 	//DocumentService.deleteDocumentPerso(docObjet).then(function(){
                    //
                    //
                    //
                    //
                    //
                    // 	}/*,function(msgErreur){
                    //
                    // 		sweet.show({
                    // 			title: 'Il y a eu une erreur...',
                    // 			text: msgErreur,
                    // 			type: 'error'});
                    //
                    // 	}*/).finally(function(){
                    // 		onUpdate(document);
                    // 	});
                    // }else{
                    // 	onUpdate(document);
                    // }
                }
                modal.close();
            });


        }

        function clickDocument(document) {

            if (angular.isArray(ctrl.listeDocuments)) {
                if (document.checked) {
                    document.checked = false;
                } else {
                    document.checked = true;
                }


                var nb = 0;
                for (var i = 0; i < ctrl.listeDocuments.length; i++) {
                    if (ctrl.listeDocuments[i].checked) {
                        nb++;
                    }
                }

                if (nb > COLLAB_CONF.NB_MAX_DOCUMENT_SELECTED_FOR_DOWNLOAD) {
                    document.checked = false;
                    sweet.show({
                        title: 'Trop de fichiers sélectionnés',
                        text: 'Vous pouvez sélectionnez que <strong>' + COLLAB_CONF.NB_MAX_DOCUMENT_SELECTED_FOR_DOWNLOAD + '</strong> fichiers.<br/>Veuillez utiliser le bouton "Télécharger toutes les pièces jointes".',
                        type: 'error',
                        html: true
                    });

                }
            }
        }

        function downloadAllForContenu() {
          DocumentManagerService.loadingDownload('loadingDocDownload' + ctrl.contenu.getIdContenu());
          DocumentManagerService.downloadAllForContenu(ctrl.contenu, ctrl.property, ctrl.contenu.getIdContenu());
        }

        /**
         * Telecharge un ou plusieur fichiers select ou non
         * @param documents
         */
        function download(documents) {
          DocumentManagerService.loadingDownload('loadingDocDownload' + ctrl.contenu.getIdContenu());
          DocumentManagerService.downloadDocument(documents, ctrl.contenu.getIdContenu());
        }

        /**
         * Telecharge seulement les fichiers selected ou checked
         * @param documents
         */
        function downloadSelected() {
          DocumentManagerService.loadingDownload('loadingDocDownload' + ctrl.contenu.getIdContenu());
          DocumentManagerService.downloadSelectedDocument(ctrl.listeDocuments, ctrl.contenu.getIdContenu());
        }

        function nbDocumentChecked() {
            var nb = 0;
            if (angular.isArray(ctrl.listeDocuments)) {
                var documents = ctrl.listeDocuments;
                for (var i = 0; i < documents.length; i++) {
                    if (documents[i].checked) {
                        nb++;
                    }
                }
            }
            return nb;
        }

        function onClickOpen(document) {
            if (angular.isDefined($attrs.onOpen)) {
                ctrl.onOpen({
                    open: {
                        document: document
                    }
                });
                //document.openView = true;
                /*
                 for (var i = 0; i < ctrl.listeDocuments.length; i++){
                 if (ctrl.listeDocuments[i].guid === document.guid) {
                 console.log('ok');
                 document.openView = true;
                 }
                 else{
                 console.log('pas ok');
                 ctrl.listeDocuments[i].openView = false;
                 }
                 }
                 console.log(ctrl.listeDocuments);*/

            }
            else {
              DocumentManagerService.openDocument(document.guid, ctrl.contenu.getIdContenu());
            }
        }
    }

})();
