(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.DemandeOptions
   * @description
   * # DemandeOptions
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('DemandeOptions', DemandeOptionsModel);

  /** @ngInject */
  function DemandeOptionsModel(Main) {

    function DemandeOptions(contenuData) {
      Main.apply(this, arguments);
      this.hydrate(contenuData);
    }

    DemandeOptions.prototype = {

      model: 'DemandeOptions',
      isDemandeOptions: true,

      // hideProprietaireInDocument
      getHideProprietaireInDocument: function(){
        return this.hideProprietaireInDocument;
      },
      setHideProprietaireInDocument: function(val){
        this.hideProprietaireInDocument = val;
      }

    };

    angular.extend(DemandeOptions.prototype, Main.prototype);

    return DemandeOptions;
  }
})();
