(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.DocBoxService
   * @description
   * # DocBoxService
   * Service in the collaboreApp.
   */
  angular
    .module('collaboreApp')
    .service('DocBoxService',
      DocBoxService
    );

  /** @ngInject */
  function DocBoxService($q, MainService, DocBoxResource, ModalsService) {

    var _this = this;
    _this.getListSharedDocument = getListSharedDocument;
    _this.addDocument = addDocument;
    _this.shareWith = shareWith;
    _this.deleteDocument = deleteDocument;

    function postServlet(action, obj) {
      var deferred = $q.defer();
      try {
        if (!action) throw new Error('Le paramètre "action" n\'existe pas !');
        if (!_.isObject(obj)) obj = {};

        var sendParams = _.merge({}, obj);
        sendParams.action = action;

        DocBoxResource
          .post(sendParams, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, ['result'], 'SharedDocument', deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Recupère une liste de document partagé que le groupe peut voir
     * @returns {*}
     */
    function getListSharedDocument(){
      var deferred = $q.defer();
      postServlet('getListSharedDocument')
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet d'ajouter un ou plusieur document à la doc box
     * @param guids
     * @returns {*}
     */
    function addDocument(guids, sharingWith){
      var deferred = $q.defer();

      if(!guids) throw new Error('Le guids est vide !');

      if(!_.isArray(guids))  guids = [guids];

      var sharingWithIdGroupe = [];
      var objectShare = {
        idGroupe: null,
        allowDeleting: false,
        allowEditing: false,
        allowSharing: false
      };

      var params = {
        guids: guids
      };

      if(!_.isNil(sharingWith)) {
        if(_.isArray(sharingWith)) {
          sharingWithIdGroupe = sharingWith.map(function(groupe){
            var share = angular.copy(objectShare);
            if(_.isObject(groupe) && groupe.model === 'Groupe') share.idGroupe = groupe.idGroupe;
            else if(_.isNumber(groupe)) share.idGroupe = groupe;
            return share;
          });
        }
        else if(_.isNumber(sharingWith)) {
          var share = angular.copy(objectShare);
          share.idGroupe = sharingWith;
          sharingWithIdGroupe = [share];
        }
        else if(_.isObject(sharingWith) && sharingWith.model === 'Groupe') {
          var share = angular.copy(objectShare);
          share.idGroupe = sharingWith.idGroupe;
          sharingWithIdGroupe = [share];
        }
        params.shareWith = sharingWithIdGroupe;
      }

      postServlet('addSharedDocument', params)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    function shareWith(listIdSharedDocument, listIdGroupe){
      var deferred = $q.defer();

      if(!listIdSharedDocument) throw new Error('Le paramètre "listIdSharedDocument" est vide !');
      if(!listIdGroupe) throw new Error('Le paramètre "listIdGroupe" est vide !');

      if(!_.isArray(listIdSharedDocument))  listIdSharedDocument = [listIdSharedDocument];
      if(!_.isArray(listIdGroupe))  listIdGroupe = [listIdGroupe];

      var sharingWithIdGroupe = [];
      var objectShare = {
        idGroupe: null,
        allowDeleting: true,
        allowEditing: true,
        allowSharing: true
      };

      if(!_.isNil(listIdGroupe)) {
        if(_.isArray(listIdGroupe)) {
          sharingWithIdGroupe = listIdGroupe.map(function(groupe){
            var share = angular.copy(objectShare);
            if(_.isObject(groupe) && groupe.model === 'Groupe') share.idGroupe = groupe.idGroupe;
            else if(_.isNumber(groupe)) share.idGroupe = groupe;
            return share;
          });
        }
        else if(_.isNumber(listIdGroupe)) {
          var share = angular.copy(objectShare);
          share.idGroupe = listIdGroupe;
          sharingWithIdGroupe = [share];
        }
        else if(_.isObject(listIdGroupe) && listIdGroupe.model === 'Groupe') {
          var share = angular.copy(objectShare);
          share.idGroupe = listIdGroupe.idGroupe;
          sharingWithIdGroupe = [share];
        }
      }

      if(_.isArray(listIdSharedDocument)) {
        var arrayIdSharedDocument = listIdSharedDocument.map(function(sharedDocument){
          if(_.isObject(sharedDocument) && sharedDocument.model === 'SharedDocument') return sharedDocument.idSharedDocument;
          else if(_.isNumber(sharedDocument)) return sharedDocument;
        });
        listIdSharedDocument = arrayIdSharedDocument;
      }
      else if(_.isObject(listIdSharedDocument) && listIdSharedDocument.model === 'SharedDocument') {
        listIdSharedDocument = listIdSharedDocument.idSharedDocument;
      }

      var params = {
        idSharedDocument: listIdSharedDocument,
        shareWith: sharingWithIdGroupe.map(function(share){
          return angular.toJson(share);
        })
      };

      postServlet('shareWith', params)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de retirer un ou plusieur document de la doc box
     * @param guids
     * @returns {*}
     */
    function deleteDocument(listId){
      var deferred = $q.defer();

      if(!listId) throw new Error('La listId est incorrect !');
      if(!_.isArray(listId)) {
        listId = [listId];
      }

      postServlet('deleteSharedDocument', {idSharedDocument: listId})
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }
  }
})();
