(function () {
  "use strict";

  angular.module("collaboreApp").factory("Contrattab", ContrattabModel);

  /** @ngInject */
  function ContrattabModel(Main) {
    function Contrattab(data) {
      Main.apply(this, arguments);
      this.hydrate(data);
    }

    Contrattab.prototype = {
      model: "Contrattab",
      isContrattab: true,
      isModel: true,

      // tabImput
      getTabImput: function () {
        return this.tabImput;
      },
      setTabImput: function (val) {
        this.tabImput = val;
      },

      // tabQP
      getTabQP: function () {
        return this.tabQP;
      },
      setTabQP: function (val) {
        this.tabQP = val;
      },

      /**
       * Pourcentage de repartition par rapport au basRap du contrat parent
       * Setter par le contrat lors des instanciations des contratab
       * @returns
       */
      getPercentQP: function () {
        return this.percentQP;
      },
      setPercentQP: function (val) {
        this.percentQP = val;
      },
      calcPercentQP: function (baseRepContrat){
        if(!_.isEmpty(baseRepContrat)){
          var baseRep = math.numeric(baseRepContrat,'number');
          if(baseRep != 0) this.percentQP = math.round(math.divide(math.multiply(this.tabQP, 100), baseRep),2);
          else this.percentQP = 0;
        }
      },

      // tabBat
      getTabBat: function () {
        return this.tabBat;
      },
      setTabBat: function (val) {
        this.tabBat = val;
      },

      // idCpte
      getIdCpte: function () {
        return this.idCpte;
      },
      setIdCpte: function (val) {
        this.idCpte = val;
      },

      // refCon
      getRefCon: function () {
        return this.refCon;
      },
      setRefCon: function (val) {
        this.refCon = val;
      },

      // tabLot
      getTabLot: function () {
        return this.tabLot;
      },
      setTabLot: function (val) {
        this.tabLot = val;
      },

      // modele
      getModele: function () {
        return this.modele;
      },
      setModele: function (modele) {
        // Si val est un objet mais qu'il n'est pas un model
        if (!modele.isModel) {
          var Modele = Main.$injector.get("Modele");
          modele = new Modele(modele);
          Modele = null;
        }
        this.modele = modele;
      }
    };

    angular.extend(Contrattab.prototype, Main.prototype);

    return Contrattab;
  }
})();
