(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name collaboreApp.LigneCalculatrice
     * @description
     * # LigneCalculatrice
     * Service in the collaboreApp.
     */

    angular.module('collaboreApp')
        .factory('LigneCalculatrice', LigneCalculatriceModel);

    /** @ngInject */
    function LigneCalculatriceModel(Main , Groupe) {

        function LigneCalculatrice(objetData) {
            Main.apply(this, arguments);
            this.solde = 0;
            this.hydrate(objetData);
        }

        LigneCalculatrice.prototype = {

            model: 'LigneCalculatrice',
            isLigneCalculatrice: true,

            // idCalculatrice
            getIdCalculatrice: function(){
                return this.idCalculatrice;
            },
            setIdCalculatrice: function(id){
                this.idCalculatrice = id;
            },

            // groupeCreateur
            getGroupeCreateur: function(){
                return this.idGroupeCreateur;
            },
            setGroupeCreateur: function(groupe){
                var instanceGroupe = groupe;
                // Si c'est l'objet document on recupèrte le guid
                if(angular.isObject(groupe) && !groupe.hasOwnProperty('isModel')){
                    instanceGroupe = new Groupe(groupe);
                }
                this.idGroupeCreateur = instanceGroupe;
            },

            // idTopic
            getIdTopic: function(){
                return this.idTopic;
            },
            setIdTopic: function(id){
                this.idTopic = id;
            },

            // dateCreation
            getDateCreation: function(){
                return this.dateCreation;
            },
            setDateCreation: function(dateCreation){
                this.dateCreation = dateCreation;
            },

            // dateSinistre
            getDate: function(){
                return this.date;
            },
            setDate: function(date){
                this.date= date;
            },

            // libelle
            getLibelle: function(){
                return this.libelle;
            },
            setLibelle: function(libelle){
                this.libelle = libelle;
            },

            // Debit
            getDebit: function(){
                if (arguments.length) {
                    this.setDebit(arguments[0]);
                } else {
                    return this.debit;
                }
            },
            setDebit: function(debit){
                this.debit = debit;
            },

            // Credit
            getCredit: function(){
                if (arguments.length) {
                    this.setCredit(arguments[0]);
                } else {
                    return this.credit;
                }
            },
            setCredit: function(credit){
                this.credit= credit;
            },

            // Solde
            getSolde: function(){
                return this.solde;
            },
            setSolde: function(solde){
                this.solde = solde;
            },

            // soldePrecedent
            getSoldePrecedent: function(){
                return this.soldePrecedent;
            },
            setSoldePrecedent: function(solde){
                this.soldePrecedent = solde;
                //this.calculeSolde();
            },

            // Commentaire
            getCommentaire: function(){
                return this.commentaire;
            },
            setCommentaire: function(commentaire){
                this.commentaire = commentaire;
            },

            calculeSolde: function(){
                var credit = 0;
                var debit = 0;
                if (this.getCredit()){
                    credit = this.getCredit();
                }
                if(this.getDebit()){
                    debit = this.getDebit()
                }
                return credit - debit;
            }
        };
        angular.extend(LigneCalculatrice.prototype, Main.prototype);

        LigneCalculatrice.prototype.toJson = function(){
            var obj = angular.copy(this);
            delete obj.solde;
            delete obj.idGroupeCreateur;
            delete obj.dateCreation;
            delete obj.soldePrecedent;
            return angular.toJson(obj);
        };

        return LigneCalculatrice;

    }
})();
