(function () {
  'use strict';

  /**
   * @desc Composant d'ajout de document
   * @example <add-documents></add-documents>
   */

  angular
    .module('collaboreApp')
    .component('parametrageCollab', {
      templateUrl: 'app/parametrage/parametrage-collab.component.html',
      bindings: {


        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<'
      },
      controllerAs: 'parametragecollabctrl',
      controller: ParametrageCollabController
    });

  /*@ngInject*/
  function ParametrageCollabController($rootScope, $stateParams, $timeout, ModalsService, UtilsService) {

    var _this = this;

    _this.notMobile = UtilsService.notMobile;

    _this.onClickTab = onClickTab;
    _this.onClickClose = onClickClose;

    _this.$onInit = function () {

      _this.myGroupe = UtilsService.getCurrentUser();
      _this.isAdmin = UtilsService.getCurrentUser().isAdmin();
      _this.isSuperAdmin = UtilsService.getCurrentUser().isSuperAdmin();
      _this.currentTab = 'mon_compte';

      if(_this.resolve && _this.modalInstance) {
        //_this.contenu = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'contenu'));
      }
      else {
        $rootScope.$broadcast('minimizeLeftSidebar');
      }
      init();
    };

    _this.$onChanges = function(changes){
      /*
      if(_.isObject(changes.contenu) && !changes.contenu.isFirstChange()){
          _this.contenu = changes.contenu.currentValue;
          init(changes.contenu.currentValue);
      }
      */
    }

    /**
     * Initialisation
     * @param contenu
     */
    function init(contenu){

      try{
        if(!_.isNil($stateParams.currentTab)) {
          _this.currentTab = $stateParams.currentTab;
        }
        else {
          _this.currentTab = 'mon_compte';
          _this.currentTitle = 'Mon Compte';
        }

        if(!_.isNil($stateParams.currentTitle)) {
          _this.currentTitle = $stateParams.currentTitle;
        }
      }
      catch(err) {
        ModalsService.alertErreur(err.message);
      }
    }

    function onClickTab(){
      $timeout(function(){
        init();
      });
    }

    /**
     * Fermeture du composant si en mode modal
     */
    function onClickClose(){
      _this.dismiss({$value: 'cancel'});
    }

    /**
     * Permet d'emit une information vers le parent
     * @param obj
     */
    /*
    function emit(valObj){

        if(!_this.modalInstance) {
            var object = {obj: {}};
            object.obj = _.merge(object.obj, valObj);
            // Si pas de "contenu" dans obj
            if(!object.obj.hasOwnProperty('contenu')) {
                object.obj.contenu = angular.copy(_this.contenu)
            }
            _this.onEmit(object);
        }
        else {
            if(_.isObject(valObj) && !valObj.hasOwnProperty('contenu')) {
                valObj.contenu = angular.copy(_this.contenu)
            }
            _this.close({$value: valObj});
        }
    }*/
  }
})();
