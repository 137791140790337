(function() {

  'use strict';

  /**
   * @desc Composant AdminCollab
   * @example <admin-collab-comptes></admin-collab-comptes>
   */
  angular
      .module('collaboreApp')
      .component('adminCollabComptes',{
        templateUrl: 'app/admin/comptes/admin-collab-comptes.component.html',
        controllerAs: 'admincollabcomptesctrl',
        controller: AdminCollabComptesCtrl
      });

  /** @ngInject */
  function AdminCollabComptesCtrl($uibModal, $injector, COLLAB_CONF, COLLAB_VALUES, $q, sweet, FileUploader, AdminCollabService, UtilsService, ModalsService){
    var ctrl = this;
    var nomSociete;

    ctrl.userNoAgence = false;
    ctrl.isGroupePortefeuille = false;
    ctrl.onSelectSociete = onSelectSociete;		        // Lors de la selection d'une société
    ctrl.onSelectPortefeuille = onSelectPortefeuille;   // Lors de la selection d'un portefeuille

    ctrl.isSuperAdmin = UtilsService.isSuperAdmin;
    // ------- Nouveaux comptes -------------
    ctrl.listComptes = [];						    // Liste des comptes à creer
    ctrl.loadingSubmit = false;                     // Loading lors du submit de la création de compte

    ctrl.assignTopic = COLLAB_VALUES.ASSIGN_TOPIC_DEFAULT;
    ctrl.assignTopicCloture = COLLAB_VALUES.ASSIGN_TOPIC_CLOTURE_DEFAULT;
    ctrl.addCompte = addCompte;					    // Lors du clique sur l'ajout d'un compte
    ctrl.removeCompte = removeCompte;			    // Lors du clique sur la suppression d'un compte
    ctrl.onBlurLogin = onBlurLogin;	                // Lorsque la souris clique en dehors du champ login
    ctrl.onKeyUpLogin = onKeyUpLogin;	            // Lors de la frappe sur le champ Login
    ctrl.onKeyUpNomPrenom = onKeyUpNomPrenom;	    // Lors de la frappe sur les champs Nom et Prénom
    ctrl.onBlurMdp = onBlurMdp;	                    // Lorsque la souris clique en dehors du champ Mdp
    ctrl.saveNewComptes = saveNewComptes;	        // Sauvegardes les nouveaux comptes
    ctrl.onClickUploadExcel = onClickUploadExcel;	        // Permet d'uploader le fichier excel pour creer les comptes user
    ctrl.onChangeAssignTopic = onChangeAssignTopic;

    // ------- Liste des comptes -------------
    ctrl.orderBy = 'libelle';
    ctrl.reverse = false;
    ctrl.beginPagination = 0;       // Début du limiteTo
    ctrl.limitPagination = 10;      // Pas du limitTo
    ctrl.currentPage = 1;           // Page actuelle
    ctrl.filtreList = '';

    var arrayAllowFilter = [
      'idGroupe',
      'libelle',
      'defaultLibelle',
      'dieze',
      'user.email',
      'groupeInformations.nom',
      'groupeInformations.prenom',
    ];
    ctrl.userResultsAfterFilter = [];

    ctrl.searchSociete = searchSociete;	                // Permet de recherche une societe
    ctrl.onClickRefresh = onClickRefresh;	            // Lorsque l'on rafraichi la page
    ctrl.onSubmitFiltre = onSubmitFiltre;	            // Lorsque l'on soumet le fomulaire pour filtrer la liste
    ctrl.changeTri = changeTri;	                        // Lors du changement de tri du tableau
    ctrl.onChangePage = onChangePage;	                // Lors du changement de page
    ctrl.ngClassSortNumeric = ngClassSortNumeric;	    // Permet d'avoir la class qu'il faut pour le tri numérique
    ctrl.ngClassSortAlpha = ngClassSortAlpha;	        // Permet d'avoir la class qu'il faut pour le tri alpha
    ctrl.ngClassSortAmount = ngClassSortAmount;	        // Permet d'avoir la class qu'il faut pour un tri autre
    ctrl.onClickOpenEditGroupeUser = onClickOpenEditGroupeUser;	 // Lors du clique sur le bouton pour modifier le groupe user
    ctrl.onClickOpenHierarchieGroupesNonUser = onClickOpenHierarchieGroupesNonUser;	 // Lors du clique sur le bouton pour voir les groupes parent de l'user
    ctrl.onClickChangeSMTP = onClickChangeSMTP;	        // Lors du clique sur le bouton pour changer le SMTP
    ctrl.onClickResetSMTP = onClickResetSMTP;	        // Lors du clique sur le bouton pour reste le SMTP
    ctrl.onClickSavePrivilege = onClickSavePrivilege;   // Lors du clique sur le bouton pour sauvegarde le privilège
    ctrl.onClickSaveEtat = onClickSaveEtat;             // Lors du clique sur le bouton pour sauvegarde l'état
    ctrl.onClickRenommerAgence = onClickRenommerAgence; // Lors du clique sur le bouton pour renommer l'agence
    ctrl.onClickSupprimerAgence = onClickSupprimerAgence; // Lors du clique sur le bouton pour supprimer l'agence

    ctrl.$onInit = function () {
      initSociete();
      /*
                  AdminCollabService
                      .getGroupesAll()
                      .then(function (groupesAll) {
                          ctrl.societe.list = groupesAll;
                      })
                      .finally(function () {
                          ctrl.societe.loading = false;
                      });*/
    };


    // Upload ddu fichier excel
    ctrl.uploaderExcel = new FileUploader({
      autoUpload: false,
      url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_GROUPE,
      alias: 'fileName',
      removeAfterUpload: true,
      queueLimit: 1,
      filters: [{
        name: 'sizeLimit',
        fn: function (item) {

          if (item.size <= COLLAB_CONF.SIZE_LIMIT_UPLOAD) {

            var type = item.type.slice(item.type.lastIndexOf('/') + 1);
            if (type !== 'vnd.ms-excel' && type !== 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
              sweet.show({
                title: '<span class="text-danger">' + item.name + '</span> doit être un XLS ou XLSX !',
                text: 'XLS ou XLSX obligatoire',
                type: 'error',
                html: true
              });
            } else {
              return true;
            }

          } else {
            sweet.show({
              title: '<span class="text-danger">' + item.name + '</span> trop gros !',
              text: '50 Mo Maximum',
              type: 'error',
              html: true
            });
          }
        }
      }]
    });

    /**
     * Après l'ajout du fichier
     * @param fileItem
     */
    ctrl.uploaderExcel.onAfterAddingFile = function(fileItem){
      if (ctrl.societe.selected) {
        fileItem.formData.push({action: 'createGroupeUserWithFileExcel'});
        fileItem.formData.push({token: UtilsService.getCurrentUser().getToken()});
        fileItem.formData.push({idGroupe: ctrl.societe.selected.idGroupe});
      }
    };

    /**
     * Lorsqu'un fichier est bien upload
     * @param fileItem
     * @param response
     */
    ctrl.uploaderExcel.onSuccessItem = function (fileItem, response) {
      if (response.hasOwnProperty('createGroupeUserWithExcelResults') && response.createGroupeUserWithExcelResults.length === 1) {
        var result = response.createGroupeUserWithExcelResults[0];
        if(result.success) {
          if(result.users.length) {
            var Groupe = $injector.get('Groupe');
            for(var i = 0; i < result.users.length; i++) {
              if(result.users[i].groupe) result.users[i].groupe = new Groupe(result.users[i].groupe);
            }
            Groupe = null;
          }
          var modalInstance = $uibModal.open({
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'modalRecapCompteCollab.html',
            controller: function(resultCreateGroupeUserWithExcelResults) {
              this.chekKO = '<i class="fa fa-times text-danger"></i>';
              this.chekOK = '<i class="fa fa-check-square-o text-success"></i>';

              this.resultCreateGroupeUserWithExcelResults = resultCreateGroupeUserWithExcelResults;
              this.close = function () {
                modalInstance.dismiss({$value: 'cancel'});
              };
            },
            controllerAs: '$ctrl',
            size: 'lg',
            resolve: {
              resultCreateGroupeUserWithExcelResults: function () {
                return result;
              }
            }
          });

          modalInstance.result.then(function () {
          }, function () {
          });

          onClickRefresh();
        }
        else  ModalsService.alertErreur('Erreur', result.message);
      }
    };

    ctrl.uploaderExcel.onCompleteAll = function () {
      ctrl.loadingSubmit = false;
    };

    ctrl.uploaderExcel.onErrorItem = function () {
      ctrl.loadingSubmit = false;
    };

    function onClickUploadExcel(){
      ctrl.uploaderExcel.uploadAll();
    }

    function onChangeAssignTopic(){
      if(_.isNil(ctrl.assignTopic) || !ctrl.assignTopic){
        ctrl.assignTopicCloture = false;
      }
    }
    function initSociete() {
      ctrl.societe = {
        search: '',
        loading: false,
        list: [],
        selected: null,
        groupesUser: {
          loading: false,
          list: []
        }
      };
    }

    function initPortefeuille() {
      ctrl.portefeuille = {
        loading: false,
        list: [],
        selected: false
      };
    }

    function onClickRefresh() {
      ctrl.loadingListeUsers = true;
      getListeComptes().finally(function(){
        ctrl.loadingListeUsers = false;
      });
    }

    function onSelectSociete($item) {
      ctrl.societe.selected = $item;
      initPortefeuille();
      if (ctrl.societe.selected) {

        ctrl.societe.groupesUser.loading = true;
        initListeComptes();

        ctrl.portefeuille.loading = true;
        AdminCollabService
            .getGroupesPortefeuille(ctrl.societe.selected.dieze)
            .then(function (groupePortefeuilles) {
              ctrl.portefeuille.list = groupePortefeuilles;
              /*
              if (groupePortefeuilles.length === 1) {
                  ctrl.portefeuille.selected = groupePortefeuilles[0];
              }
              else if (groupePortefeuilles.length === 0) {
                  ctrl.portefeuille.selected = false;
              }
              else if(groupePortefeuilles.length > 1) {
                  for(var g = 0; g < groupePortefeuilles.length; g++){

                      if(groupePortefeuilles[g].libelle === '@'+UtilsService.ltrim(ctrl.societe.selected.dieze, '#').toLowerCase().ucfirst()){
                          ctrl.portefeuille.selected = groupePortefeuilles[g];
                          break;
                      }


                  }
              }*/

              if (ctrl.listComptes.length) {
                // Parcour les compte déjà en cours de création
                for (var j = 0; j < ctrl.listComptes.length; j++) {
                  var resetPass = true;
                  // Si il y avait déjà une société selected avant de changer
                  if (nomSociete) {
                    // Si la MDP généré à partir de la société old est différent du mot de passe du compte
                    // alors il a surement été changé on n'y touche pas
                    if (nomSociete !== ctrl.listComptes[j].mdp) {
                      resetPass = false;
                    }
                  }

                  if (resetPass) {
                    ctrl.listComptes[j].mdp = UtilsService.ltrim(ctrl.societe.selected.dieze, '#');
                  }
                }
              }

              nomSociete = angular.copy(UtilsService.ltrim(ctrl.societe.selected.dieze, '#'));
            })
            .finally(function () {
              ctrl.portefeuille.loading = false;
            });
      }
    }

    function onSelectPortefeuille(groupe) {
      ctrl.isGroupePortefeuille = false;
      if(angular.isObject(groupe)) {
        if(groupe.libelle === '@'+UtilsService.ltrim(ctrl.societe.selected.dieze, '#').toLowerCase().ucfirst()) {
          ctrl.isGroupePortefeuille = true;
        }
      }
    }

    function initListeComptes() {
      ctrl.societe.groupesUser.loading = true;
      getListeComptes().finally(function(){
        ctrl.societe.groupesUser.loading = false;
      });
    }

    function getListeComptes() {
      var defer = $q.defer();
      if (ctrl.societe.selected) {
        AdminCollabService
            .getGroupesUser(ctrl.societe.selected.dieze)
            .then(function (groupes) {
              defer.resolve(groupes);
              ctrl.societe.groupesUser.list = groupes;
              ctrl.userResultsAfterFilter = groupes;
            })
            .catch(function(msg){
              defer.reject(msg);
            });
      }
      return defer.promise;
    }

    // ------- Nouveaux comptes -------------

    function addCompte() {
      ctrl.listComptes.push({
        open: true,
        login: '',
        loginAutoGenerate: true,
        loginReadOnly: true,
        mdp: nomSociete,
        mdpReadOnly: true,
        email: '',
        telephone: '',
        portable: '',
        nom: '',
        prenom: '',
        immeuble: '0000',
        immeubleReadOnly: true,
        privilege: '$membre'
      });
    }

    function removeCompte(index) {
      ctrl.listComptes.splice(index, 1);
    }

    function onBlurLogin(compte) {
      if (compte.login === '') {
        compte.login = genereLogin(compte);
      }
    }

    function onKeyUpLogin(compte) {
      compte.loginAutoGenerate = false;
      if (!compte.login || compte.login === '') {
        compte.loginAutoGenerate = true;
      }
    }

    function onKeyUpNomPrenom(compte) {
      if (compte.loginAutoGenerate) {
        if (compte.nom !== '' && compte.prenom !== '') {
          compte.login = genereLogin(compte);
        }
      }
    }

    function onBlurMdp(compte) {
      if (!compte.mdp || compte.mdp === '') {
        compte.mdp = UtilsService.ltrim(ctrl.societe.selected.dieze, '#');
      }
    }

    function genereLogin(compte) {
      var nom = UtilsService.latinize(compte.nom.toLowerCase());
      var prenom = UtilsService.latinize(compte.prenom.toLowerCase());
      return UtilsService.cleanSpecialChar(prenom.substr(0, 1) + nom);
    }

    function saveNewComptes(formNewUtilisateurs) {
      if (!formNewUtilisateurs.$valid) {
        return;
      }

      ctrl.loadingSubmit = true;
      var obj = {
        utilisateurs: []
      };

      if (ctrl.listComptes.length) {
        for(var i = 0; i < ctrl.listComptes.length; i++) {
          var user = ctrl.listComptes[i];

          var utilisateur = {
            noperso: user.login,
            mdp: user.mdp,
            identites: {
              2: user.email
            },
            email: user.email,
            societe: nomSociete,
            dontAssignTopics: !ctrl.assignTopic,
            assignTopicCloture: ctrl.assignTopicCloture
          };

          if(!ctrl.userNoAgence && angular.isObject(ctrl.portefeuille.selected)) {
            //utilisateur.qualites = [];
            utilisateur.idGroupeParent = ctrl.portefeuille.selected.idGroupe;
            //utilisateur.portefeuille = UtilsService.ltrim(ctrl.portefeuille.selected.dieze.toLowerCase(), '#');
            //utilisateur.immeuble = user.immeuble;
          }

          if (user.portable && user.portable !== '') {        utilisateur.identites[1] = user.portable;}
          if (user.prenom && user.prenom !== '') {            utilisateur.identites[4] = user.prenom;}
          if (user.nom && user.nom !== '') {                  utilisateur.identites[5] = user.nom.toLocaleUpperCase();}
          if (user.telephone && user.telephone !== '') {      utilisateur.identites[6] = user.telephone;}

          obj.utilisateurs.push(utilisateur);
        }
      }

      AdminCollabService
          .addUtilisateurs(obj)
          .then(function(recap){
            sweet.show('Terminé', 'Création des comptes terminée ' + recap, 'success');
            onClickRefresh();
            ctrl.listComptes = [];
          })
          .catch(function(msg){
            sweet.show('Problème...', msg, 'error');
          })
          .finally(function(){
            ctrl.loadingSubmit = false;
          });
    }

    // ------- Liste des comptes -------------

    function searchSociete(val){

      return AdminCollabService
          .searchGroupesAll(val)
          .then(function (groupesAll) {

            return groupesAll;
          });

    }


    function onSubmitFiltre() {
      ctrl.filtreList = angular.copy(ctrl.filtreText);
      if(_.isEmpty(ctrl.filtreList)) ctrl.userResultsAfterFilter = ctrl.societe.groupesUser.list;
      else{
        var reg = new RegExp(ctrl.filtreList, 'ig');
        ctrl.userResultsAfterFilter = ctrl.societe.groupesUser.list.reduce(function(list, user) {
          // Parcour le tableau des attribut à tester pour le filtre
          for (var j = 0; j < arrayAllowFilter.length; j++) {
            var propertie = arrayAllowFilter[j];
            var valPropertie = null;
            if(propertie.contains('.')){
              var tabProperties = propertie.split('.');
              if(tabProperties.length === 2){
                valPropertie = user[tabProperties[0]][tabProperties[1]];
              }
            }
            else valPropertie = user[arrayAllowFilter[j]];

            // Si le text tapé dans le champ de recherche est trouvé dans la valeur de l'attribut
            if (!_.isNil(valPropertie) && reg.test(valPropertie)) {
              list.push(user);
              break;
            }
          }
          return list;
        }, []);
      }
    }

    function ngClassSortNumeric() {
      var retour = 'fa-sort-numeric-asc';
      if (ctrl.reverse) {
        return 'fa-sort-numeric-desc';
      }
      return retour;
    }

    function ngClassSortAlpha() {
      var retour = 'fa-sort-alpha-asc';
      if (ctrl.reverse) {
        return 'fa-sort-alpha-desc';
      }
      return retour;
    }

    function ngClassSortAmount() {
      var retour = 'fa-sort-amount-asc';
      if (ctrl.reverse) {
        return 'fa-sort-amount-desc';
      }
      return retour;
    }

    function changeTri(tri) {
      if (ctrl.orderBy !== tri) {
        ctrl.orderBy = tri;
        ctrl.reverse = true;
      }
      else if (ctrl.reverse === true) {
        ctrl.reverse = false;
      }
      else {
        ctrl.reverse = true;
      }
    }

    function onChangePage() {
      ctrl.beginPagination = (ctrl.currentPage * ctrl.limitPagination) - ctrl.limitPagination;
    }

    function onClickOpenEditGroupeUser(compte) {
      var options = {
        size: 'lg',
        backdrop: 'static'
      };

      ModalsService
          .modalComponent('adminCollabEditGroupeUser', {groupe: compte}, options)
          .then(function (groupe) {
            compte.setData(groupe);
            onClickRefresh();
          })
          .catch(function (msg) {
            if (msg !== 'cancel') {
              ModalsService.alertErreur(msg);
            }
          });
    }

    function onClickOpenHierarchieGroupesNonUser(compte) {
      // console.log(compte);

      var options = {
        size: 'lg'
      };

      ModalsService.modalComponent('adminCollabModalGroupe', {compte: compte}, options, function(){

      });
    }

    function onClickChangeSMTP(compte) {
      ModalsService.modalChangeSMTP(compte, null, function(){

      });
    }

    function onClickResetSMTP(compte) {

      sweet.show({
        title: 'RAZ SMTP',
        text: 'Supprimer le SMTP de ' + compte.getNom() + ' ?',
        type: 'warning',
        html:true,
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Oui !',
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      },function(isConfirm){
        if (isConfirm) {
          AdminCollabService
              .resetSmtpForGroupe(compte.idGroupe)
              .then(function(groupe){
                UtilsService.setChamp(compte, groupe, 'host');
                UtilsService.setChamp(compte, groupe, 'port');
                UtilsService.setChamp(compte, groupe, 'auth');
                UtilsService.setChamp(compte, groupe, 'tls');
                UtilsService.setChamp(compte, groupe, 'userSmtp');
                UtilsService.setChamp(compte, groupe, 'mdpClaire');
                sweet.show('Terminé', '', 'success');
              })
              .catch(function(msg){
                sweet.show('Problème...', msg, 'error');
              });
        }

      });

    }

    function onClickSavePrivilege(compte) {
      if(compte.getNewPrivilege() === compte.getPrivilege()) return;
      ctrl.loadingSavePrivilege = true;
      AdminCollabService
          .changePrivilegeForGroupe(compte.idGroupe, compte.getNewPrivilege())
          .then(function(groupe){
            if(compte.isUser() && groupe.isUser()) {
              compte.user.privilege = groupe.user.privilege;
              delete compte.newPrivilege;
            }
          })
          .catch(function(msg){
            sweet.show('Problème...', msg, 'error');
          })
          .finally(function(){
            ctrl.loadingSavePrivilege = false;
            delete compte.popoverPrivilegeIsOpen;
          });
    }

    function onClickSaveEtat(compte) {
      if(compte.getNewEtat() === compte.getEtat()) return;
      ctrl.loadingEtatPrivilege = true;
      AdminCollabService
          .changeEtatForGroupe(compte.idGroupe, compte.getNewEtat())
          .then(function(groupe){
            if(compte.isUser() && groupe.isUser()) {
              compte.user.etat = groupe.user.etat;
              delete compte.newEtat;
            }
          })
          .catch(function(msg){
            sweet.show('Problème...', msg, 'error');
          })
          .finally(function(){
            ctrl.loadingEtatPrivilege = false;
            delete compte.popoverEtatIsOpen;
          });
    }

    function onClickRenommerAgence(){
      var options = {
        size: 'lg',
        backdrop: 'static'
      };

      ModalsService
          .modalComponent('adminCollabEditAgence', {groupe: ctrl.societe.selected}, options)
          .then(function (obj) {
            if(_.isObject(obj) && obj.action === 'updateGroupe') {
              ctrl.societe.selected = obj.groupe;
              onClickRefresh();
            }
          })
          .catch(function (msg) {
            if (msg !== 'cancel') {
              ModalsService.alertErreur(msg);
            }
          });
    }

    function onClickSupprimerAgence(){
      if (ctrl.societe.selected) {
        ModalsService
            .confirm('Suppression société', 'Supprimer la société ' + ctrl.societe.selected.dieze + ' ?', {type: 'warning'})
            .then(function (modal) {
              AdminCollabService
                  .deleteSociete(ctrl.societe.selected.idGroupe)
                  .then(function(){
                    ModalsService.success("Supprimé !");
                  })
                  .catch(function(msg){
                    ModalsService.alertErreur(msg);
                  });
            });
      }
    }
  }
})();
