(function() {
    'use strict';

    /**
     * @ngdoc factory
     * @name collaboreApp.ProxyContenuMessage
     * @description
     * # ProxyContenuEvenement
     */

    angular.module('collaboreApp').factory('ProxyContenuMessage', ProxyContenuMessageModel);

    /** @ngInject */
    function ProxyContenuMessageModel(ProxyContenu) {

        function ProxyContenuMessage(objetData) {
            ProxyContenu.apply(this, arguments);
            var scope = this;

            // Si c'est un objet
            if (angular.isObject(objetData)) {

                // Hydratation
                angular.forEach(objetData,function(val,key){
                    var setter = 'set' + key.ucfirst();
                    if(angular.isFunction(scope[setter])) {
                        scope[setter](val);
                    }
                });
            }
            this.typeContenu = 1;
            this.typeMessage = 'classic';
        }

        ProxyContenuMessage.prototype = {

            // message
            getMessage: function(){
                return this.message;
            },
            setMessage: function(val){
                this.message = val;
            },

            // progression
            getProgression: function(){
                return this.progression;
            },
            setProgression: function(val){
                this.progression = val;
            },

            // sms
            getSms: function(){
                return this.sms;
            },
            setSms: function(val){
                this.sms = val;
            },

            // allowReplyToSMS
            getAllowReplyToSMS: function(){
                return this.allowReplyToSMS;
            },
            setAllowReplyToSMS: function(bool){
                this.allowReplyToSMS = bool;
            },

            // typeMessage
            getTypeMessage: function(){
                return this.typeMessage;
            },
            setTypeMessage: function(val){
                this.typeMessage = val;
            }

        };

        angular.extend(ProxyContenuMessage.prototype, ProxyContenu.prototype);

        return ProxyContenuMessage;
    }
})();