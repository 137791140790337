(function () {
    'use strict';

    angular
        .module('collaboreApp')
        .component('sendMail', {
            templateUrl: 'app/topics/components/send-mail/send-mail.component.html',
            controller: SendMailController,
            controllerAs: 'sendmailctrl',
            bindings: {
                chooseMail: '<',        // Permet d'afficher ou non le composant "choose-infos-contact"
                contenuParent: '<',
                destinataire: '<',      // Destinataire, peut être un Groupe, PersoICS, Perso ou String email ou une liste
                idTopic: '<',
                documents: '<',
                sujet: '<',
                message: '<',
                onEmit: '&',

                // Si Modal
                resolve: '<',
                close: '&',
                dismiss: '&',
                modalInstance: '<'
            }
        });

    /** @ngInject */
    function SendMailController($scope, MessageService, UtilsService, ErreurCollabService, ModalsService) {
        var ctrl = this;

        ctrl.portefeuille = null;       // Portefeuille
        ctrl.listeMailCustom = [];      // Liste des email libre
        ctrl.listePerso = [];        // liste des perso pour le composant choose infos contact
        ctrl.listePersoChecked = []; // liste des perso avec les email cochés
        ctrl.signatureSelected = null;  // Signature selectionnée


        ctrl.onEditorReady = onEditorReady;
        ctrl.onEmitFromChooseInfosContactComponent = onEmitFromChooseInfosContactComponent;
        ctrl.onRemoveDestinataire = onRemoveDestinataire;

        ctrl.onClickSend = onClickSend;
        ctrl.onClickClose = onClickClose;

        /********** Global **********/
        ctrl.$onInit = function () {
            if(ctrl.resolve && ctrl.modalInstance) {
                ctrl.chooseMail = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'chooseMail'));
                ctrl.contenuParent = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'contenuParent'));
                ctrl.destinataire = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'destinataire'));
                ctrl.idTopic = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'idTopic'));
                ctrl.documents = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'documents'));
                ctrl.sujet = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'sujet'));
                ctrl.message = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'message'));
            }
            if(!ctrl.destinataire) ModalsService.alertErreur('Pas de destinataire');
            if(!ctrl.idTopic)  ModalsService.alertErreur('Pas d\'idTopic !');

            // Si destinataire est un array
            if(angular.isArray(ctrl.destinataire)) initArray(ctrl.destinataire);

            // Si c'est un objet, tester les différent model
            else if(angular.isObject(ctrl.destinataire)) initModel(ctrl.destinataire);

            // Si c'est un string (pourquoi faire ? libelleDiese peut être ? ou mail et tel mais vraiment utile...)
            else if(angular.isString(ctrl.destinataire)) initString(ctrl.destinataire);


            if(angular.isObject(ctrl.sinistre)) initFromPerso(ctrl.sinistre.getInfosSinistre().getAssureur());
            else if(angular.isObject(ctrl.assureur)) initFromPerso(ctrl.assureur);

            ctrl.infosMail = initInfoMail();
        };

        ctrl.$onChanges = function(changes){
        };

        ctrl.$onDestroy = function(){
        };

        /**
         * Permet de
         * @returns {{listeEmail: Array, sendDocumentSinistre: boolean, message: string}}
         */
        function initInfoMail(){
            var infosMail = {
                listeEmail: [],
                sujet: (ctrl.sujet) ? ctrl.sujet : null,
                message: (ctrl.message) ? ctrl.message : '',
                documents: (ctrl.documents) ? ctrl.documents : []
            };
            return infosMail;
        }

        /**
         * Si le destinataire est un Array alors teste chaque itération pour savoir si c'est un Object ouString
         * @param arr
         */
        function initArray(arr){
            if(!arr.length) ModalsService.alertErreur('Pas de destinataire dans la liste');
            for(var i = 0; i < arr.length; i++){
                if(angular.isObject(arr[i])) initModel(arr[i]);
                else if(angular.isString(arr[i])) initString(arr[i]);
            }
        }

        /**
         * Si le destinataire est un Object
         * @param dest
         */
        function initModel(dest){
            if(!dest.isModel) ModalsService.alertErreur('Le destinataire doit être un model');
            initFromPerso(dest);
        }

        /**
         * Si le destinataire est un String
         * @param dest
         */
        function initString(dest) {
            // TODO: A faire si libelleDiese ou email
        }

        // ----------------- ChooseInfosContact -----------------
        /**
         * Permet d'init la liste des perso à envoyer au composant permetant de choisir les mails
         * @param perso
         */
        function initFromPerso(perso){
            if(angular.isObject(perso.fournisseur)) ctrl.listePerso.push(perso.fournisseur);
            if(angular.isObject(perso.compagnie)) ctrl.listePerso.push(perso.compagnie);
            if(angular.isObject(perso.portefeuille)) ctrl.portefeuille = perso.portefeuille;
        }

        /**
         * Lorsque l'on choisi des destinataires
         * @param obj
         */
        function onEmitFromChooseInfosContactComponent(obj){
            try {
                if (!angular.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');
                if(angular.isArray(obj.listeEmail)) ctrl.infosMail.listeEmail = obj.listeEmail;
                if(angular.isArray(obj.listePerso)) ctrl.listePersoChecked = obj.listePerso;
                if(angular.isArray(obj.listeMailCustom)) ctrl.listeMailCustom = obj.listeMailCustom;
                emit();
            }
            catch (err) {
                ErreurCollabService.logErreur('[MailSinistreController.onEmitFromChooseInfosContactComponent] Erreur : ',  err.message);
            }
        }
        // --- Fin ChooseInfosContact

        // ----------------- GroupeCollab -----------------
        /**
         * Lorsqu'un groupe est retiré des destinataires avec la petite croix
         * @param obj
         */
        function onRemoveDestinataire(obj){
            if(obj.groupe) {
                ctrl.listeMailCustom = angular.copy(ctrl.listeMailCustom.remove(obj.groupe));
                ctrl.infosMail.listeEmail = angular.copy(ctrl.infosMail.listeEmail.remove(obj.groupe));
                if(ctrl.listePersoChecked.length) {

                    var tmpListPerso = [];
                    ctrl.listePersoChecked.map(function(objPerso) {
                        var mails = [];
                        objPerso.emails.map(function(objMail) {
                            if(objMail.mail !== obj.groupe) mails.push(angular.copy(objMail));
                        });
                        if(mails.length) {
                            var tmpPerso = angular.copy(objPerso);
                            tmpPerso.emails = mails;
                            tmpListPerso.push(tmpPerso);
                        }
                    });
                    ctrl.listePersoChecked = tmpListPerso;
                }
                //ctrl.infosMail.listeEmail = angular.copy(ctrl.infosMail.listeEmail.remove(obj.groupe));
                emit();
            }
        }
        // --- Fin GroupeCollab

        // ----------------- CollabEditor -----------------
        /**
         * Lorsque l'editeur de message est prêt
         * @param obj
         */
        function onEditorReady(obj){
            ctrl.objEditorMessage = obj;
        }
        // --- Fin CollabEditor

        // ----------------- SelectSignatureContenu -----------------
        /**
         * Lors du choix d'une signature
         * @param obj
         */
        function onEmitSelectSignatureContenu(obj){
            try {
                if (!obj) throw new Error('Pas d\'obj !');
                if (angular.isObject(obj) && obj.hasOwnProperty('signature')) {
                    if(obj.action === 'changeSignature') ctrl.signatureSelected = obj.signature;
                }
            }
            catch (err) {
                ModalsService.alertErreur(err.message);
            }
        }
        // --- Fin SelectSignatureContenu

        /**
         * Lors du clique sur le bouton pour envoyer le message
         */
        function onClickSend(){
            try {
                var listDest = [];
                if(ctrl.listePersoChecked.length) {
                    ctrl.listePersoChecked.map(function(objPerso) {
                        var dest = {
                            listeEmail: [],
                            persoICS: (angular.isObject(objPerso.persoICS)) ? objPerso.persoICS : null
                        };
                        objPerso.emails.map(function(objMail) {
                            dest.listeEmail.push(objMail.mail);
                        });
                        listDest.push(dest);
                    });
                }
                if(ctrl.listeMailCustom.length) {
                    ctrl.listeMailCustom.map(function(mail){
                        listDest.push(mail);
                    });
                }
                if(!listDest.length) throw new Error('Aucun destinataire sélectionné !');

                // Verification du message
                if (angular.isUndefined(ctrl.objEditorMessage.getData()) || ctrl.objEditorMessage.getData() === '') throw new Error('Veuillez renseigner un message');

                var message = {
                    typeMessage: 'sendMessageFromSinistre',
                    sendByMail: true,
                    guids: [],
                    proxyMail: {
                        sujet: ctrl.infosMail.sujet
                    }
                };

                if(angular.isArray(ctrl.infosMail.documents)) {
                    ctrl.infosMail.documents.map(function(doc) {
                        if(doc.checked) message.guids.add(doc.getGuid());
                    });
                }

                var proxyMessage = new MessageService.ProxyContenuMessage(message);
                proxyMessage.setListeDestinataires(listDest);
                if(angular.isObject(ctrl.signatureSelected)) proxyMessage.setSignature(ctrl.signatureSelected.value);
                proxyMessage.setMessage(ctrl.objEditorMessage.getData());

                var obj = {
                    idTopic: ctrl.idTopic,
                    objJSON: proxyMessage
                };

                ctrl.loading = true;
                MessageService
                    .addMessageInTopic(obj)
                    .then(function(obj){
                        ctrl.close({$value: {action: 'addMessage', message: obj.message}});
                    })
                    .catch(function(error){
                        ModalsService.alertErreur(error);
                    })
                    .finally(function(){
                        ctrl.loading = false;
                    });
            }
            catch(err) {
                ModalsService.alertErreur(err.message);
                ctrl.loading = false;
            }
        }

        /**
         * Fermeture du composant si en mode modal
         */
        function onClickClose(){
            ctrl.dismiss({$value: 'cancel'});
        }

        /**
         * Traite les donnée pour les renvoyer au composant qui appel celui si
         */
        function emit(){
            if(!ctrl.modalInstance) {
                var onEmit = {
                    obj: {
                        mail: angular.copy(ctrl.infosMail)
                    }
                };
                onEmit.obj.mail.message = ctrl.objEditorMessage.getData();
                ctrl.onEmit(onEmit);
            }

            //deferred.notify(onEmit);

            /*
            if(!ctrl.isEnabled) objUpdate.obj.mail = false;
            else objUpdate.obj.mail.message = ctrl.objEditorMessage.getData();
            ctrl.onUpdate(objUpdate);*/
        }
    }
})();

