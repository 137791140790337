(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .factory('UserResource', UserResource);
  
  /** @ngInject */
  function UserResource(COLLAB_CONF, COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_AUTHENTIFICATION, null, {
      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      },
      getPortefeuilles: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_PORTEFEUILLE
      }
    });
  }
})();
