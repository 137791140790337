(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name collaboreApp.Immeuble
     * @description
     * # Immeuble
     * Service in the collaboreApp.
     */

    angular
        .module('collaboreApp')
        .factory('InfoImmeuble',
            InfoImmeubleModel
        );

    /** @ngInject */
    function InfoImmeubleModel(Main) {

        function InfoImmeuble(objetData) {
            // Change l'attribut "noimme" dans le JSON et "numImme" dans le Model Immeuble

            Main.call(this, objetData, null, null);
        }

        InfoImmeuble.prototype = {

            model: 'InfoImmeuble',
            isInfoImmeuble: true,

            getNomimme: function(){
                return this.nomimme;
            },
            setNomimme: function(val){
                this.nomimme = val;
            },

            getNom: function(){
                var nomImm = this.nomimme;
                if(angular.isUndefined(this.nomimme) || this.nomimme === ''){
                    nomImm = this.getAd1();
                    if(angular.isUndefined(nomImm) || nomImm === ''){
                        nomImm = this.getAd2();
                        if(angular.isUndefined(nomImm) || nomImm === ''){
                            nomImm = this.getNumImmeuble();
                        }
                    }
                }
                return nomImm;
            },
            setNom: function(val){
                this.nomimme = val;
            },

            getNoimme: function(){
                return this.noimme;
            },
            setNoimme: function(val){
                this.noimme = val;
            },

            getNumImmeuble: function(){
                return this.noimme;
            },
            setNumImmeuble: function(val){
                this.noimme = val;
            },

            getAd1: function(){
                return this.ad1;
            },
            setAd1: function(val){
                this.ad1 = val;
            },

            getAd2: function(){
                return this.ad2;
            },
            setAd2: function(val){
                this.ad2 = val;
            },

            getAd3: function(){
                return this.ad3;
            },
            setAd3: function(val){
                this.ad3 = val;
            },

            getAd4: function(){
                return this.ad4;
            },
            setAd4: function(val){
                this.ad4 = val;
            },

            getAdresse: function(){
                var retour = '';
                if(this.getAd1()!==''){
                    retour += this.getAd1()+' - ';
                }
                if(this.getAd2()!==''){
                    retour += this.getAd2()+' - ';
                }
                if(this.getAd3()!==''){
                    retour += this.getAd3()+' - ';
                }
                if(this.getAd4()!==''){
                    retour += this.getAd4()+' - ';
                }
                return retour.slice(0,-3);
            },

            //Nom du Gestionnaire
            getResponsable: function(){
                return this.responsable;
            },
            setResponsable: function(val){
                this.responsable = val;
            },

            //Nom du comptable
            getComptable: function(){
                return this.comptable;
            },
            setComptable: function(val){
                this.comptable = val;
            },

            //Arrété comptable
            getFinExercice: function(){
                return this.finExercice;
            },
            setFinExercice: function(val){
                this.finExercice= val;
            },

            //Fin du mandat
            getDatfin: function(){
                return this.datfin;
            },
            setDatfin: function(val){
                this.datfin = val;
            },

            //Date assemblée générale
            getRea_ag_fixee: function(){
                return this.Rea_ag_fixee;
            },
            setRea_ag_fixee: function(val){
                this.Rea_ag_fixee= val;
            },

            //Visites prevues
            getNbvip: function(){
                return this.nbvip;
            },
            setNbvip: function(val){
                this.nbvip= val;
            },

            //Visites realisees
            getNbvir: function(){
                return this.nbvir;
            },
            setNbvir: function(val){
                this.nbvir= val;
            },

            //Nombre lots
            getNbrlotp: function(){
                return this.nbrlotp;
            },
            setNbrlotp: function(val){
                this.nbrlotp= val;
            }

        };

        angular.extend(InfoImmeuble.prototype, Main.prototype);

        return InfoImmeuble;
    }
})();
