(function() {

    'use strict';

    /**
     * @desc Composant gerer mes groupes
     * @example <gerer-mes-groupes></gerer-mes-groupes>
     */
    angular
        .module('collaboreApp')
        .component('gererMesGroupes',{
            bindings: {
                onProgress: '&'
            },
            templateUrl: 'app/parametrage/mes-groupes/gerer-mes-groupes.component.html',
            controllerAs: 'gerermesgroupesctrl',
            controller: GererMesGroupesCtrl
        });


    /** @ngInject */
    function GererMesGroupesCtrl(COLLAB_CONF,$rootScope,GroupesService){
        var ctrl = this;
        ctrl.onProgress = onProgress;
        //var listeGroupes = [];
        ctrl.listGroupesFiltered = [];
        //ctrl.onSubmitSearchGroupe = onSubmitSearchGroupe;

        ctrl.loading = {
            'show': true,
            'content': COLLAB_CONF.MIDDLE_LOADING
        };

        //var listeChannel = $rootScope.current.userGroupe.getListeChannel();

        /**
         * Executé pour chaque groupe
         * @param groupe
         */
        /*
        function execOnEachGroupe(groupe){

            groupe.accueil = false;
            groupe.channel = false;

            if(listeChannel.length){

                for(var i = 0 ; i < listeChannel.length ; i++){
                    var currentChannel = listeChannel[i];
                    var groupes = currentChannel.getGroupeChannel();

                    if(groupes.length){
                        for(var j = 0; j < groupes.length ; j++){
                            if(currentChannel.getType().getIdTypeChannel()===COLLAB_CONF.CHANNEL_TYPE_ACCUEIL){
                                if(groupes[j].idGroupe===groupe.idGroupe){
                                    groupe.accueil = true;
                                }
                            }
                            if(currentChannel.getType().getIdTypeChannel()===COLLAB_CONF.CHANNEL_TYPE_CHANNEL){
                                if(groupes[j].idGroupe===groupe.idGroupe){
                                    groupe.channel = true;
                                }
                            }
                        }
                    }
                }
            }
        }*/

        GroupesService.getGroupesParent($rootScope.current.userGroupe.getIdGroupe(), true).then(function(groupes){
           // listeGroupes = [groupes];
            ctrl.listGroupesFiltered = [groupes];
            //console.log(groupes);

            //GroupesService.recurseGroupes(groupes,execOnEachGroupe);

        }).finally(function(){
            ctrl.loading.show = false;
        });

        ctrl.$onInit = function(){
        };


        function onProgress(progress){
          ctrl.progress = progress;
        }



        /*
        function onSubmitSearchGroupe(){


            ctrl.listGroupesFiltered = [];
            if(listeGroupes.length){
                ctrl.listGroupesFiltered = [searchLibelle(0,listeGroupes,listeGroupes)];
            }

            console.log(ctrl.listGroupesFiltered);

            //ctrl.listGroupesFiltered = $filter('filter')(listGroupes,{'libelle': ctrl.search});
            //console.log(ctrl.listGroupesFiltered);
            //ctrl.listeModules = UtilsService.groupBy(groupesFiltered,'libelle');
        }



        function searchLibelle(index,listeGroupe,prevGroupe){
            if(UtilsService.test(listeGroupe[index].libelle,ctrl.search,'ig')){
                return prevGroupe;
            }else{

                var nb = angular.copy(listeGroupe[index].groupes.length);
                if(nb){

                    for(var i = 0 ; i < nb ; i++){
                        prevGroupe = searchLibelle(i,listeGroupe[index].groupes,listeGroupe[index]);

                    }

                }else{
                    prevGroupe.groupes.splice(index,1);
                }

                return prevGroupe;
            }

        }*/
    }
})();
