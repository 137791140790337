'use strict';

/**
 * @ngdoc service
 * @name collaboreApp.DocumentManager
 * @description
 * # DocumentManager
 * Service in the collaboreApp.
 */

angular.module('collaboreApp').service('DocumentService', ['COLLAB_CONF','COLLAB_VALUES','ErreurCollabService','$timeout','$rootScope','$q','$window', '$filter', 'Document','DocumentsAjax','UtilsService','CommunicationAjax', function(COLLAB_CONF,COLLAB_VALUES,ErreurCollabService,$timeout,$rootScope,$q,$window, $filter, Document, DocumentsAjax,UtilsService,CommunicationAjax) {

  var scope = this;
  scope._pool = {};
  scope.idGroupe = false;
  scope.loadingDocuments = [];

  scope._retrieveInstance = function(documentId, documentData) {
    var instance = scope._pool[documentId];

    if (instance) {

      var tmp;
      if(!angular.isArray(instance.type)){
        tmp = [instance.type];
      }else{
        tmp = instance.type;
      }

      tmp.push(documentData.type);
      documentData.type = $filter('unique')(tmp);

      instance.setData(documentData);
    } else {
      if(!angular.isArray(documentData.type)){
        documentData.type = [documentData.type];
      }
      instance = new Document(documentData);
      scope._pool[documentId] = instance;
    }

    return instance;
  };

  scope._deleteInstance = function(documentId) {
    var instance = scope._pool[documentId];
    if (instance) {
      delete scope._pool[documentId];
    }
  };

  scope._search = function(documentId) {
    return scope._pool[documentId];
  };

  scope._loadPerso = function(objetId, deferred) {

    var documentAjax = new DocumentsAjax();
    documentAjax.guid = objetId;
    documentAjax.$get(function(json){
      if(json.nb!=='0'){
        var document = scope._retrieveInstance(json.Documents[0].guid, json.Documents[0]);
        deferred.resolve(document);
      }else{
        deferred.reject();
      }
    });
  };

  scope._loadSociete = function(objetId, deferred) {

    var documentAjax = new DocumentsAjax();
    documentAjax.guid = objetId;
    documentAjax.$getDocSociete(function(json){
      if(json.nb!=='0'){
        var document = scope._retrieveInstance(json.Documents[0].guid, json.Documents[0]);
        deferred.resolve(document);
      }else{
        deferred.reject();
      }
    });
  };
  scope._loadAllDocuments = function(deferred) {

    var documentAjax = new DocumentsAjax();
    /*
        var promiseDocPerso =  documentAjax.$get(function(json) {
          if (json.nb !== '0') {
            angular.forEach(json.Documents, function (objetData) {
              objetData.type = 0;
              var document = scope._retrieveInstance(objetData.guid, objetData);
              deferred.resolve(document);
            });
          }
        });

        var promiseDocSociete = documentAjax.$getDocSociete(function(jsonSociete) {

          if (jsonSociete.nb !== '0') {
            angular.forEach(jsonSociete.Documents, function (objetDataSociete) {
              objetDataSociete.type = 1;
              var documentSociete = scope._retrieveInstance(objetDataSociete.guid, objetDataSociete);
              deferred.resolve(documentSociete);
            });
          }
        });


        // Attend le retour des deux appels
        $q.all([promiseDocPerso, promiseDocSociete]).then(function(data){
          var documents = scope.poolToArray();

          deferred.resolve(documents);
          console.log(documents);
        },function(msg){
          deferred.reject(msg);
        });*/


    documentAjax.$get(function(json){
      //var documents = [];

      if(json.nb!=='0'){
        angular.forEach(json.Documents,function(objetData) {
          objetData.type = 0;
          scope._retrieveInstance(objetData.guid, objetData);
          //documents.push(document);
        });
      }

      documentAjax.$getDocSociete(function(jsonSociete){

        if(jsonSociete.nb!=='0'){
          angular.forEach(jsonSociete.Documents,function(objetDataSociete) {
            objetDataSociete.type = 1;
            scope._retrieveInstance(objetDataSociete.guid, objetDataSociete);
            //documents.push(documentSociete);
          });
        }

        var documents = scope.poolToArray();
        deferred.resolve(documents);
        //console.log(documents);
      },function(){
        deferred.reject();
      });

    },function(){
      deferred.reject();
    });
  };
  /*
  scope._loadAllDocuments = function(deferred) {

    var documentAjax = new DocumentsAjax();
    documentAjax.$get(function(json){
      var documents = [];

      if(json.nb!=='0'){
        angular.forEach(json.Documents,function(objetData) {
          objetData.type = 0;
          var document = scope._retrieveInstance(objetData.guid, objetData);
          documents.push(document);
        });
      }

      deferred.resolve(documents);
    },function(){
      deferred.reject();
    });
  };*/

  /* Public Methods */

  scope.poolToArray = function(){
    var documents = [];
    angular.forEach(scope._pool, function(doc){
      documents.push(doc);
    });
    return documents;
  };

  scope.conf = function(objParams){
    if(angular.isObject(objParams)){
      if(angular.isDefined(objParams.idGroupe)){
        scope.idGroupe = objParams.idGroupe;
      }
    }
  };

  /**
   * Crée une instance d'un document
   * @param {Object} document
   * @returns {Document}
   */
  scope.newDocument = function(document) {

    var instance = document;
    if(angular.isObject(document) && !document.hasOwnProperty('isModel')){
      instance = new Document(document);
    }
    return instance;
  };

  /**
   * Crée un pool d'instance de document
   * @param {Array} documents - Tableau de documents
   * @returns {void}
   */
  scope.initDocuments = function(documents) {


    var instances = [];
    if(angular.isArray(documents)){
      if(documents.length){
        angular.forEach(documents,function(document){
          var instance = scope.setDocument(document);
          instances.push(instance);
        });
      }
    }
    return instances;
  };

  /**
   * @name find
   * @desc Recupère l'instances d'un document par le biais de son guid
   * @param {String} guid - Guid du document
   * @returns {Object} Instance Document
   */
  scope.find = function(guid) {
    var retour = false;
    var document = scope._search(guid);
    if (document) {
      retour = document;
    }
    return retour;
  };

  /**
   * @name setDocument
   * @desc Crée une instance de document ou récupère si existante
   * @param {Object} objetData - Objet du document
   * @returns {Object} instance du document
   */
  /*
  scope.setDocument = function(objetData) {

    var document = scope._search(objetData.guid);
    if (document) {
      document.setData(objetData);
    } else {
      document = scope._retrieveInstance(objetData.guid,objetData);
    }
    return document;
  };*/

  /**
   * @name getDocument
   * @desc Recupère l'instances d'un document parle biais de son guid
   * @returns {Array} Tableau des objets document
   * @param guid
   */
  scope.getDocument = function(guid) {
    var deferred = $q.defer();
    var document = scope._search(guid);
    if (document) {
      deferred.resolve(document);
    } else {
      scope._loadPerso(guid, deferred);
    }
    return deferred.promise;
  };

  /**
   * @name getListDocument
   * @desc Recupère les instances des documents perso
   * @returns {Array} Tableau des objets document
   */
  scope.getListDocument = function() {
    var deferred = $q.defer();
    var documents = [];
    angular.forEach(scope._pool,function(document){
      documents.push(document);
    });
    if(documents.length){
      deferred.resolve(documents);
    }/*else{
      scope._loadAllDocuments(deferred);
    }*/

    return deferred.promise;
  };

  /**
   * @name refreshDocument
   * @desc Recupère les infos d'un document par le biais de la servlet
   * @param {String} guid - Guid du document
   * @returns Promesse {Array} Tableau d'objets Document
   */
  scope.refreshDocument = function(guid) {
    var deferred = $q.defer();
    scope._loadPerso(guid,deferred);
    return deferred.promise;
  };

  /**
   * @name refreshListDocument
   * @desc Recupère la liste des documents par le biais de la servlet
   * @returns Promesse {Array} Tableau d'objets Document
   */
  scope.refreshListDocument = function() {
    var deferred = $q.defer();
    scope._loadAllDocuments(deferred);
    return deferred.promise;
  };

  /**
   * @name loadDocumentsPerso
   * @desc Recupère les documents perso par le biais de la servlet
   * @returns {Array} Tableau des objets document
   */
  scope.loadDocumentsPerso = function() {
    var deferred = $q.defer();

    var documentAjax = new DocumentsAjax();
    documentAjax.$get(function(pomiseDocument){
      var documents = [];

      angular.forEach(pomiseDocument.Documents,function(documentData) {
        var document = scope._retrieveInstance(documentData.guid, documentData);
        documents.push(document);
      });

      deferred.resolve(documents);
    },function(){
      deferred.reject();
    });

    return deferred.promise;
  };



  /**
   * @name setDocument
   * @desc Crée une instance de document ou récupère si existante
   * @param {Object} documentData - Objet du document
   * @returns {Object} instance du document
   */
  scope.setDocument = function(documentData) {

    var document = scope._search(documentData.guid);
    if (document) {
      document.setData(documentData);
    } else {
      document = scope._retrieveInstance(documentData.guid,documentData);
    }
    return document;

    //var document = scope._retrieveInstance(documentData.guid,documentData);
    //return document;
  };
  /*
  scope.sendDocumentPerso = function(groupe,file) {
    var deferred = $q.defer();
    var documentAjax = new DocumentsAjax();
    documentAjax.idGroupe = groupe;
    documentAjax.fileName = file;
    documentAjax.$sendFile(function(pomiseDocument){
      console.log(pomiseDocument);
      //deferred.resolve(pomiseDocument);
    });
    return deferred.promise;
  };
  */

  /**
   * @name addTagInManyDocument
   * @desc Ajoute un tag à plusieur document
   * @param {Array,Array} arrayDocuments,arrayTags - Tableau de document, Tableau de tags à ajouter
   * @returns {Object} Promesse
   */
  /*
 scope.addTagInManyDocument = function(arrayDocuments,arrayTags){

   var globalDeferred = $q.defer();
   var promises = [];
   var retourDocument = {};
   retourDocument.documentsTagInsert = [];
   retourDocument.documentsTagNoInsert = [];

   if(angular.isArray(arrayDocuments)){

     var tagAjax = new TagAjax();

     angular.forEach(arrayDocuments,function(retour){

       var deferred = $q.defer();
       tagAjax.guid = document.getGuid();
       tagAjax.tags = arrayTags;
       tagAjax.$addInDocument(function(tag){

         if(tag.nb!=='0'){
           retourDocument.documentsTagInsert.push(document);
         }else{
           retourDocument.documentsTagNoInsert.push(document);
         }

         deferred.resolve(document);
       },function(){
         retourDocument.documentsTagNoInsert.push(document);
         deferred.resolve(document);
       });



       // Cumul les promesses
       promises.push(deferred.promise);
     });
   }

   $q.all(promises).then(function(){
      // Si il y a des documents qui n'ont pas inseré le tag reject la promesse
     if(retourDocument.documentsTagNoInsert.length){
       globalDeferred.reject(retourDocument);

     // Si tout les tags sont bien ajoutées
     }else{
       globalDeferred.resolve(retourDocument);
     }
   });

   return globalDeferred.promise;
 };*/

  /**
   * @name recursiveAddTags
   * @desc: Ajoute un tag à plusieurs document
   * @param {Array} arrayDocuments - Tableau de document
   * @param {Array} arrayTags - Tableau de tags à ajouter
   * @param {Object} params - Paramètres pour la recursivité
   * @returns {Object} Promesse
   */
  scope.recursiveAddTags = function(arrayDocuments,arrayTags,isType,params){

    if(!angular.isDefined(params)) {
      params = {};
      params.deferred = false;
      params.retourDocument = false;
      params.n = 0;
    }
    if(!params.deferred){
      params.deferred = $q.defer();
    }

    if(!params.retourDocument){
      params.retourDocument = {};
      params.retourDocument.documentsTagInsert = [];
      params.retourDocument.documentsTagNoInsert = [];
    }

    if(!params.n){
      params.n = 0;
    }


    //if(!deferred){
    //  deferred = $q.defer();
    //}

    //if(!retourDocument){
    //  retourDocument = {};
    //  retourDocument.documentsTagInsert = [];
    //  retourDocument.documentsTagNoInsert = [];
    //}

    if(angular.isArray(arrayDocuments)){

      var indexMaxDocuments = (arrayDocuments.length)-1;

      //if(!n){
      //  n = 0;
      //}
      var documentObjet = arrayDocuments[params.n];

      if(angular.isObject(documentObjet)){
        documentObjet.addTags(arrayTags).then(function(tags){
          params.retourDocument.tags = tags;
          params.retourDocument.documentsTagInsert.push(documentObjet);
          if(params.n<indexMaxDocuments){
            params.n++;
            scope.recursiveAddTags(arrayDocuments,arrayTags,isType,params);
          }else{
            params.deferred.resolve(params.retourDocument);
            //return deferred.promise;
          }
        },function(){
          params.retourDocument.documentsTagNoInsert.push(documentObjet);
          if(params.n<indexMaxDocuments){
            params.n++;
            scope.recursiveAddTags(arrayDocuments,arrayTags,isType,params);
          }else{
            params.deferred.resolve(params.retourDocument);
            //return deferred.promise;
          }
        });
      }
    }


    return params.deferred.promise;

  };


  /**
   * @name addTags
   * @desc Ajoute un tag au document
   * @param {Array} arrayTags - Tableau de tags à ajouter
   * @returns {Object} Promesse
   */
  /*
 scope.addTags = function(guid,arrayTags){

   var deferred = $q.defer();
   var tagAjax = new TagAjax();
   tagAjax.guid = guid;
   tagAjax.tags = arrayTags;

   tagAjax.$addInDocument(function(retour){

     deferred.resolve(retour);

   },function(){
     deferred.reject('Problème Ajax [DocumentManager.addTags()]');
   });


   return deferred.promise;
 };*/

  /**
   * @name deleteTag
   * @desc Supprime un tag au document
   * @param {Object} tag - Tags à supprimer
   * @returns {Object} Promesse
   */
  /*
 scope.deleteTag = function(guid,tag){

   var deferred = $q.defer();
   var tagAjax = new TagAjax();
   tagAjax.guid = guid;
   tagAjax.tag = tag.idTag;

   tagAjax.$deleteTagInDocument(function(retour){

     if(retour.Resultats[0].success){
       deferred.resolve();
     }else{
       deferred.reject('Problème retour [DocumentManager.deleteTagInDocument()]');
     }
   },function(){
     deferred.reject('Problème Ajax [DocumentManager.deleteTagInDocument()]');
   });

   return deferred.promise;
 };*/

  /**
   * @name deleteDocumentPerso
   * @desc Supprime un document
   * @param {Object} document - Objet du fichier
   * @returns {Object} Promesse
   */
  scope.deleteDocumentPerso = function(document) {


    var deferred = $q.defer();

    document.deleteDocument(scope.idGroupe).then(function(){

      deferred.resolve();
      // Supprime le document du pool d'instance
      scope._deleteInstance(document.getGuid());

    },function(error){

      deferred.reject(error.msg);
    });

    return deferred.promise;
  };

  /**
   * @name deleteDocumentsPerso
   * @desc Supprime plusieur documents
   * @param {Array} arrayFile - array de fichiers
   * @returns {Object} Promesse
   */
  scope.deleteDocumentsPerso = function(arrayFile) {

    var globalDeferred = $q.defer();
    var promises = [];

    var retourFichier = {};
    retourFichier.documentsDelete = [];
    retourFichier.documentsNoDelete = [];

    if(angular.isArray(arrayFile)){

      angular.forEach(arrayFile,function(document){

        var deferred = $q.defer();
        scope.deleteDocumentPerso(document).then(function(){
          retourFichier.documentsDelete.push(document);
          deferred.resolve(document);
        },function(){
          retourFichier.documentsNoDelete.push(document);
          deferred.resolve(document);
        });

        // Cumul les promesses
        promises.push(deferred.promise);
      });
    }

    // Quand toutes les promesses ok
    $q.all(promises).then(function(){
      // Si il y a des documents non delete on reject la promesse
      if(retourFichier.documentsNoDelete.length){
        globalDeferred.reject(retourFichier);

        // Si pas de document non delete alors on resolve
      }else{
        globalDeferred.resolve(retourFichier);
      }

    });

    return globalDeferred.promise;
  };

  scope.destroy = function(){
    scope._pool = {};
  };

  /**
   * @name rechercheByTags
   * @desc Retourne un tableau de document filtré par tags
   * @param {Array} arrayTags - array de tags
   * @returns {Object} Promesse
   */
  scope.rechercheByTags = function(arrayTags){

    var deferred = $q.defer();
    var error = {etat:false};

    if(angular.isArray(arrayTags)){

      var idTags = [];

      if(arrayTags.length){
        angular.forEach(arrayTags,function(tag){
          idTags.push(tag.idTag);
        });
      }

      var documentAjax = new DocumentsAjax();
      documentAjax.tags = idTags;
      documentAjax.$rechercheByTags(function(json){
        var documents = [];

        if(json.nb!=='0'){
          angular.forEach(json.Documents,function(document){
            var instanceDocument = scope._retrieveInstance(document.guid, document);
            documents.push(instanceDocument);
          });
        }

        documentAjax.type = 1;
        documentAjax.tags = idTags;
        documentAjax.$rechercheByTags(function(jsonSociete) {
          if(jsonSociete.nb!=='0'){
            angular.forEach(jsonSociete.Documents,function(documentSociete){
              var instanceDocument = scope._retrieveInstance(documentSociete.guid, documentSociete);
              documents.push(instanceDocument);
            });
          }
        });

        //var documents = scope.poolToArray();
        documents = $filter('unique')(documents);
        deferred.resolve(documents);
      });
    }else{
      error.etat = true;
      error.msg = 'Pas de Tags [DocumentService.rechercheByTags()]';
    }

    if(error.etat){
      deferred.reject(error);
    }

    return deferred.promise;
  };

  /**
   * @name getStream
   * @desc Permet d'avoir le stream du document
   * @param {String} guid - guid du fichier
   * @returns {String} stream du fichier
   */
  scope.getStream = function(guid){
    var deferred = $q.defer();
    var documentAjax = new DocumentsAjax();
    documentAjax.guid = guid;
    documentAjax.$stream(function(stream){
      deferred.resolve(stream);
    },function(){
      deferred.reject();
    });

    return deferred.promise;
  };

  scope.getDescriptionUpdated = function(guid, description){
    var deferred = $q.defer();

    var params={
      'guid':guid,
      'description':description
    };

    DocumentsAjax.updateNewDescription(params,function(retour){
      if (retour.Resultats[0].success === 1){
        deferred.resolve(1);
      }
      else{
        deferred.resolve(0);
      }
    }, function(error){
      var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
      deferred.reject('Problème avec DocumentService.getDescriptionUpdated(), '+msgErreur);
    });
    return deferred.promise;
  };

  /**
   * @name setStreamInArray
   * @desc Permet d'avoir le stream de chaque document
   * @param {Array} arrayDocuments - Tableau de documents
   * @returns {Array} Tableau de documents avec les strem injecté dans chaque document
   */
  scope.setStreamInArray = function(arrayDocuments){
    var deferred = $q.defer();
    var promises = [];

    if(angular.isArray(arrayDocuments)){

      angular.forEach(arrayDocuments,function(document,indexDocument){

        if($filter('isImage')(document.getExtension())) {
          var promise = scope.getStream(document.getGuid()).then(function(stream){
            arrayDocuments[indexDocument].setStream(stream);
            promises.push(promise);

          });
        }else{
          arrayDocuments[indexDocument].setStream(false);
        }
      });

    }

    $q.all(promises).then(function(){
      deferred.resolve(arrayDocuments);
    });

    return deferred.promise;
  };

  scope.imageExists = function(url) {
    var deferred = $q.defer();

    var img = new Image();
    img.onload = function() {

      deferred.resolve(img);
    };
    img.onerror = function() { deferred.reject(); };
    img.src = url;

    return deferred.promise;
  };

  /**
   * @deprecated Utiliser DocumentManagerService.getUrlStream
   * @name getUrlView
   * @desc Permet d'avoir l'url du document
   * @param {String} guid - guid du fichier
   * @returns {void}
   */
  scope.getUrlView = function(guid,compress){
    var varCompress = '';
    if(compress){
      varCompress = '&compress=true';
    }
    var url = COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_DOCUMENTS_DOWNLOAD+'?type=1&guids[]='+guid+'&token='+$rootScope.token+varCompress;
    return url;
  };


  /**
   * @deprecated Utiliser DocumentManagerService.getStream
   * Recupère un PDF avec son guid en arrayBuffer et le converti en URL
   * @param guid
   * @returns {promise|*|Promise|a.fn.promise|capability.promise|webix.promise}
   */
  scope.getPDFToUrl = function(guid){
    var deferred = $q.defer();

    var Doc = new DocumentsAjax();
    Doc.guids = guid;

    var myPdf = Doc.$getPDF();
    myPdf.then(function(retpdf) {
      //console.log(retpdf);

      var url = window.URL || window.webkitURL || window.mozURL;
      var retour = url.createObjectURL(retpdf.data);
      deferred.resolve(retour);
      /*
      var docInitParams = {
        'data': retpdf.data
      };
      PDFJS.getDocument(docInitParams).then(function (pdf) {
      });*/
    },function(msg){
      if(msg.status === 404) {
        deferred.reject('Document Introuvable');
      }
      else {
        deferred.reject(msg);
      }
    });

    /*

    DocumentsAjax.$getPdf().$promise.then(function(myPdf) {
      PDFJS.getDocument({
        data: myPdf.data
      }).then(function (pdf) {
        console.log(pdf);
      });
    });*/

    /*
    Doc.$getPDF(function(ret){
      console.log(ret);
    });*/

    return deferred.promise;
  };


  scope.getImage = function(guid,compress){
    var deferred = $q.defer();

    var varCompress = '';
    if(compress){
      varCompress = '&compress=true';
    }
    var url = COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_DOCUMENTS_DOWNLOAD+'?type=1&guids[]='+guid+'&token='+$rootScope.token+varCompress;

    scope.imageExists(url).then(function(img){
      //console.log(img);
      deferred.resolve(img);
    },function(){
      deferred.reject();
    });

    return deferred.promise;
  };


  /*
  var hideLoading = function() {
    for(var variable in scope.loadingDocuments){
      delete scope.loadingDocuments[variable];
    }
  };
  */
  scope.loadingDownload = function(name){
    scope.loadingDocuments[name] = true;
    var onFocus = UtilsService.addEvent($window,'blur',function(){
      $timeout(function(){
        for(var variable in scope.loadingDocuments){
          delete scope.loadingDocuments[variable];
        }
      });
      onFocus.silence();
    });
  };

  /**
   * Permet de télécharger un ou plusieur document
   * @param {String|Array} guid - guid du fichier
   * @returns {void}
   */
  scope.download = function(guid){

    var guids = '';
    if(angular.isArray(guid)){


      angular.forEach(guid,function(doc){
        var id;
        if(angular.isObject(doc)){
          id = doc.guid;
        }else{
          id = doc;
        }
        guids += 'guids[]='+id+'&';
      });

      guids = guids.slice(0,-1);
    }else{
      guids = 'guids[]='+guid;
    }
    //$window.location.href = COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_DOCUMENTS_DOWNLOAD+'?type=0&'+guids+'&token='+$rootScope.token;
    $window.open(COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_DOCUMENTS_DOWNLOAD+'?type=0&'+guids+'&token='+$rootScope.token);
  };

  /**
   * Télécharge tous les document qui sont selected
   * @param array
   */
  scope.downloadSelected = function(array){

    var guids = '';
    if(angular.isArray(array)){
      angular.forEach(array,function(doc){
        if(doc.selected || doc.checked){
          var id;
          if(angular.isObject(doc)){
            id = doc.guid;
          }else{
            id = doc;
          }
          guids += 'guids[]='+id+'&';
        }
        doc.selected = false;
        doc.checked = false;
      });

      if(guids!==''){
        guids = guids.slice(0,-1);
        $window.location.href = COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_DOCUMENTS_DOWNLOAD+'?type=0&'+guids+'&token='+$rootScope.token;
      }
    }
  };

  /**
   * Télécharge tous les document d'un contenu
   * @param itemContenu
   */
  scope.downloadAllForContenu = function(itemContenu,property){

    var contenus = '';
    // Si tableau d'idContenu ou d'objet contenu
    if(angular.isArray(itemContenu)){
      angular.forEach(itemContenu,function(contenu){
        // Si objet contenu
        if(angular.isObject(contenu)) {
          contenus += 'contenu[]='+contenu.idContenu+'&';
          // Si idContenu
        }else{
          contenus += 'contenu[]='+contenu+'&';
        }
      });
    }else{
      // Si objet contenu
      if(angular.isObject(itemContenu)) {
        contenus += 'contenu[]='+itemContenu.idContenu+'&';
        // Si idContenu
      }else{
        contenus += 'contenu[]='+itemContenu+'&';
      }
    }
    if(contenus!==''){

      if(angular.isDefined(property) && property === 'listeDocumentFournisseur') {
        contenus += 'liste=docFournisseur&';
      }

      $window.location.href = COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_DOCUMENTS_DOWNLOAD+'?type=0&'+contenus+'token='+$rootScope.token;
    }
  };

  /*
  scope.viewPdfEvenement = function(contenu){

    if(angular.isObject(contenu)){
      contenu = contenu.idContenu;
    }

    $window.open(COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_TELECHARGEMENT_EVENEMENT_PDF+'?idContenu='+contenu+'&token='+$rootScope.token);

  };*/

  scope.generePdf = function(contenu,type){

    if(angular.isObject(contenu)){
      contenu = contenu.idContenu;
    }

    var url = COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_TELECHARGEMENT_PDF+'?idContenu='+contenu+'&token='+$rootScope.token;

    if(type){
      url += '&typePdf='+type;
    }

    $window.open(url);
  };

}]);
