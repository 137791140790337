'use strict';

/**
 * @desc Icon pour passer/enlever un topic en archive
 * @example <icon-archive topic-state="" icon-size="small"></icon-archive>
 */

angular
	.module('collaboreApp')
	.component('iconArchivage',{
		templateUrl: 'app/topics/components/icon-archivage.component.html',
		bindings : {
			idTopicState: '<',		// <Integer> Id du topicState
			state: '<',				// <Object> State du topicState
			iconSize: '@',			// <String> Taille de l'icone
			showLibelle: '<'
		},
		controllerAs: 'iconarchivagectrl',
		controller: ['$rootScope','$state','$scope', IconArchivageController]
	});

function IconArchivageController($rootScope,$state,$scope){

	var ctrl = this;

	ctrl.updateState = updateState;			// Update l'�tat du state du topic

    var listenerOnUpdateState = $scope.$on('updateState',function(event,idTopicState){
        if(ctrl.idTopicState === idTopicState){
            setLibelle(ctrl.state);
        }
    });

	ctrl.$onInit = function() {

		ctrl.loading = false;
		ctrl.libelleTooltip = '';

		switch(ctrl.iconSize){
			case 'small':
				ctrl.isSmall = true;
				break;
			default:
				ctrl.isSmall = false;
		}

		setLibelle(ctrl.state);
	};

	ctrl.$onDestroy = function(){
        listenerOnUpdateState();
	};

	/**
	 * Set le libelle du tooltip
	 * @param state
	 */
	function setLibelle(state){
		if(state.isArchive()){
			ctrl.libelleTooltip = 'Retirer des Archives';
		}else{
			ctrl.libelleTooltip = 'Ajouter aux Archives';
		}
	}

	/**
	 * Permet d'update le state
	 */
	function updateState(){

		try{

			if(angular.isUndefined(ctrl.idTopicState)){
				throw new Error('[iconArchivage] Il manque l\'idTopicState !');
			}

			ctrl.loading = true;
			ctrl.state.toggleAddRemoveToArchive(ctrl.idTopicState).then(function(state){

				setLibelle(state);

				// Supprim� de la liste si
				// Page list ou favoris et etat cach� ou archive
				// Page archives et etat diff�rent de cach� et archive
				if((($state.is('topics.list') || $state.is('topics.favoris')) && (state.isCache() || state.isArchive())) ||
					($state.is('topics.archives') && (!state.isCache() && !state.isArchive()))){
					$scope.$emit('removeTopic', ctrl.idTopicState);
				}

				$rootScope.$broadcast('updateState',ctrl.idTopicState);

			},function(msg){
				throw new Error('Erreur : '+msg);
			}).finally(function(){
				ctrl.loading = false;
			});
		}
		catch(err){
			console.log(err.message);
		}
	}




}


