(function () {
    'use strict';

    /**
     * @desc Bloc notes du topic
     * @example <topic-bloc-notes></topic-bloc-notes>
     */

    angular
        .module('collaboreApp')
        .component('topicBlocNotes',{
            templateUrl: 'app/topics/topic-bloc-notes/topic-bloc-notes.component.html',
            controllerAs: 'topicblocnotesctrl',
            bindings: {
                idTopic: '<',
                note: '<',
                onEmit: '&'
            },
            controller: TopicBlocNotesController
        });

    /*@ngInject*/
    function TopicBlocNotesController(COLLAB_CONF, $rootScope, TopicService) {

        var ctrl = this;
        ctrl.instanceCKEDITOR = false;			// Instance CKEDITOR pour l'objet

        ctrl.ckeditorOnReady = ckeditorOnReady;
        ctrl.onClickSaveNote = onClickSaveNote;
        //ctrl.noteIsEdit = false;

        ctrl.$onInit = function() {

            ctrl.ckeditorOptions = {
                sharedSpaces: {
                    top: 'topCkeditor',
                    bottom: 'bottomCkeditor'
                },
                on: {
                    instanceReady: function () {
                        this.dataProcessor.htmlFilter.addRules({
                            elements: {
                                img: function (el) {
                                    var isBase64 = UtilsService.test(el.attributes.src, 'base64', 'i');
                                    if (isBase64) {
                                        el.addClass('image-max-editor');
                                    }
                                }
                            }
                        });
                    }/*,
          change: function (ev) {
            if(!angular.equals(ev.editor.getData(), ctrl.note)) ctrl.noteIsEdit = true;
          }*/
                }
            };
        };

        ctrl.$onDestroy = function(){
        };

        /**
         * Lorsque l'instance du WYSIWYG de l'objet est ready
         * @param item
         */
        function ckeditorOnReady() {
            var note = '';
            if(angular.isDefined(ctrl.note)) note = angular.copy(ctrl.note);

            ctrl.instanceCKEDITOR.setData(note, {
                callback: function () {
                    var _this = this;
                    _this.resize('100%', 500, true, true);

                    // Ajout l'instance qui vien d'être créé dans le pool d'instance de CKEDITOR
                    $rootScope._poolInstancesCKEDITOR['topicBlocNotes'] = ctrl.instanceCKEDITOR;
                }
            });
        }

        function onClickSaveNote(){

            ctrl.loading = true;
            TopicService
                .saveNote(ctrl.idTopic, ctrl.instanceCKEDITOR.getData())
                .then(function(topic){
                    //ctrl.noteIsEdit = false;
                    emit({action: 'updateTopic', topic: topic});
                })
                .finally(function(){
                    ctrl.loading = false;
                })
        }

        function emit(obj) {
            ctrl.onEmit({obj: obj});
        }
    }
})();
