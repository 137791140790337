(function () {
	'use strict';

	/**
	 * @ngdoc service
	 * @name collaboreApp.Digicode
	 * @description
	 * # Digicode
	 * Service in the collaboreApp.
	 */

	angular
		.module('collaboreApp')
		.factory('Digicode',
			DigicodeModel
		);

	/** @ngInject */
	function DigicodeModel(Main) {

		function Digicode(objetData) {
			var objectConversion = {
				iiii: 'numImmeuble',
				bbbb: 'numBatiment',
				esc: 'numEscalier'
			};
			Main.apply(this, arguments);
			this.hydrate(objetData, null, objectConversion);
		}

		Digicode.prototype = {

			model: 'Digicode',
			isDigicode: true,

			// iiii
			getNumImmeuble: function(){
				return this.iiii;
			},
			setNumImmeubl: function(val){
				this.iiii = val;
			},

			// bbbb
			getNumBatiment: function(){
				return this.bbbb;
			},
			setNumBatiment: function(val){
				this.bbbb = val;
			},

			// esc
			getNumEscalier: function(){
				return this.esc;
			},
			setNumEscalier: function(val){
				this.esc = val;
			},

			// ad1
			getAd1: function(){
				return this.ad1;
			},
			setAd1: function(val){
				this.ad1 = val;
			},

			// ad2
			getAd2: function(){
				return this.ad2;
			},
			setAd2: function(val){
				this.ad2 = val;
			},

			// z1
			getZ1: function(){
				return this.z1;
			},
			setZ1: function(val){
				this.z1 = val;
			},

			// z2
			getZ2: function(){
				return this.z2;
			},
			setZ2: function(val){
				this.z2 = val;
			},

			// z3
			getZ3: function(){
				return this.z3;
			},
			setZ3: function(val){
				this.z3 = val;
			},

			// digicode
			getDigicode: function(){
				return this.digicode;
			},
			setDigicode: function(val){
				this.digicode = val;
			},

      // listBatiment
      getListBatiment: function(){
        return this.listBatiment;
      },
      setListBatiment: function(val){
        if(_.isArray(val) && val.length){
          val = val.map(function(item) {
            if(!item.isModel) item = new Digicode(item);
            return item;
          });
          this.listBatiment = val;
        }
      },

      // listEscalier
      getListEscalier: function(){
        return this.listEscalier;
      },
      setListEscalier: function(val){
        if(_.isArray(val) && val.length){
          val = val.map(function(item) {
            if(!item.isModel) item = new Digicode(item);
            return item;
          });
          this.listEscalier = val;
        }
      },
		};

		angular.extend(Digicode.prototype, Main.prototype);

		return Digicode;
	}
})();
