'use strict';

function escapeRegExp(string) {
	return string.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}
/**
 * @ngdoc filter
 * @name collaboreApp.filter:collaboreFilter
 * @function
 * @description
 * # collaboreFilter
 * Filter in the collaboreApp.
 */
/*
angular.module('collaboreApp').filter('unsafe', ['$sce', function ($sce) {
	return function (val) {
		return $sce.trustAsHtml(val);
	};
}]);*/

/**
* @name hasDieze
* @desc Check si le string à un dieze
* @param {String} val - la chaine à analyser
* @returns {Boolean}
*/
angular.module('collaboreApp').filter('hasDieze', [function () {
	return function (val) {
		var patt = new RegExp('#');
		return patt.test(val);
	};
}]);

/**
* @name firstDieze
* @desc Check si le 1er caraètre est un diese
* @param {String} val - la chaine à analyser
* @returns {Boolean}
*/
angular.module('collaboreApp').filter('firstDieze', [function () {
	return function (val) {
		var patt = new RegExp('^#');
		return patt.test(val);
	};
}]);

/*
angular.module('collaboreApp').filter('isEditable', ['UserService', function (UserService) {
	return function (contenu) {
		var retour = false;
		var libelleDieze = UserService.getGroupe().getLibelleDieze();

		if(contenu.getDestinataires()){
			angular.forEach(contenu.getDestinataires(),function(destinataire){

				if(angular.equals(destinataire.libelle+destinataire.dieze,libelleDieze)){

					retour = true;
				}
			});
		}
		return retour;
	};
}]);*/

/**
* @name isMail
* @desc Check si le string à un dieze
* @param {String} val - la chaine à analyser
* @returns {Boolean}
*/
var isMail = function () {
	return function (email) {
		var patt = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return patt.test(email);
	};
};

angular
	.module('collaboreApp')
	.filter('isMail', [
		isMail
	]);

/**
* @name isImage
* @desc Renvoi true si le parametre passé est une extension d'image
* @param {Object} string
* @returns {Boolean}
*/
var isImage = function () {
	return function (item) {
		if(item){
			var dotIndex = item.lastIndexOf('.');
			var ext = item.substring(dotIndex);
	    return (/\.(gif|jpg|jpeg|tiff|png)$/i).test(ext);
	  }
	};
};

angular.module('collaboreApp').filter('isImage', [
	isImage
]);

/**
* @name getIconFile
* @desc Renvoi true si le parametre passé est une extension d'image
* @param {Object} string
* @returns {Boolean}
*/
var getIconFile = function () {
	return function (item) {
		if(item){
			var dotIndex = item.lastIndexOf('.');
			var ext = item.substring(dotIndex);
			var icon = 'document-filetype-icon';

			if((/\.(pdf)$/i).test(ext)) {icon = 'document-filetype-pdf-icon';}
			else if((/\.(xls|xlsx)$/i).test(ext)) {icon = 'document-filetype-excel-icon';}
			else if((/\.(csv)$/i).test(ext)) {icon = 'document-filetype-csv-icon';}
			else if((/\.(doc|docx)$/i).test(ext)) {icon = 'document-filetype-word-icon';}
			else if((/\.(zip|7zip)$/i).test(ext)) {icon = 'document-filetype-zip-icon';}
			else if((/\.(rar)$/i).test(ext)) {icon = 'document-filetype-rar-icon';}
			else if((/\.(txt)$/i).test(ext)) {icon = 'document-filetype-text-icon';}

	    return icon;
	  }
	};
};

angular.module('collaboreApp').filter('getIconFile', [
	getIconFile
]);

/**
* @name getIconFile
* @desc Renvoi true si le parametre passé est une extension d'image
* @param {Object} string
* @returns {Boolean}
*/
var getIconFileFa = function ($filter) {
	return function (item) {
		if(item){
			var dotIndex = item.lastIndexOf('.');
			var ext = item.substring(dotIndex);
			var icon = 'fa-file-o';

			if((/\.(pdf)$/i).test(ext)) {icon = 'fa-file-pdf-o';}
			else if((/\.(xls|xlsx)$/i).test(ext)) {icon = 'fa-file-excel-o';}
			else if((/\.(doc|docx)$/i).test(ext)) {icon = 'fa-file-word-o';}
			else if((/\.(rar|zip|7zip)$/i).test(ext)) {icon = 'fa-file-zip-o';}
			else if((/\.(txt|csv|xml)$/i).test(ext)) {icon = 'fa-file-text';}
			else if($filter('isImage')(item)) {icon = 'fa-file-image-o';}

	    return icon;
	  }
	};
};

angular.module('collaboreApp').filter('getIconFileFa', [
	'$filter',
	getIconFileFa
]);

/**
 * @name isFileVisible
 * @desc Renvoi false si le fichier n'est pas visualisable avec le navigateur
 * @param {Object} string
 * @returns {Boolean}
 */
var isFileVisible = function ($filter) {
	return function (item) {
		if(item){
			var dotIndex = item.lastIndexOf('.');
			var ext = item.substring(dotIndex);

			if($filter('isImage')(item) || (/\.(pdf|txt)$/i).test(ext)){
				return true;
			}

			return false;
			/*
			if((/\.(xls|xlsx|doc|docx|rar|zip|7zip|exe|ppt|pptx|pot)$/i).test(ext)) {
				return false;
			}else{
				return true;
			}*/
		}
	};
};

angular.module('collaboreApp').filter('isFileVisible', [
	'$filter',
	isFileVisible
]);

/**
* @name dateParser
* @desc Renvoi la date formaté corectement pour la lecture FR
* @param {Object} paramDate - date au format en
* @returns {String} Date
*/
angular.module('collaboreApp').filter('dateParser', [
	'$filter',
	function ($filter) {
		return function (paramDate) {

			// Année et date de aujourd'hui
			var anneeDuJour = $filter('date')(new Date(),'yyyy');
			var dateDuJour = $filter('date')(new Date(),'yyyy-MM-dd');


			var dateTmp = $filter('date')(paramDate,'yyyy-MM-dd');

			// Si aujourd'hui retourne juste l'heure
			if(dateDuJour === dateTmp) {

				return $filter('date')(paramDate,'HH:mm');

				// Sinon retourne le jour et l'heure
			}else {

				// Année de la date donne au filtre
				var annee = $filter('date')(paramDate,'yyyy');
				var date = null;

				// Si pas la même année
				if(angular.equals(annee,anneeDuJour)){
					date = $filter('date')(paramDate,'d MMM');
				}else{
					date = $filter('date')(paramDate,'d MMM yyyy');
				}
				var heure = $filter('date')(paramDate,'HH:mm');

				return date+' <small class="text-muted">'+heure+'</small>';


				//return $filter('date')(paramDate,'dd/MM/yyyy à HH:mm');
			}
		};
	}
]);


/*
var escapeHtml = function () {
	return function (text) {
		var map = {
			'&': '&amp;',
			'<': '&lt;',
			'>': '&gt;',
			'"': '&quot;',
			"'": '&#039;'
		};
		return text.replace(/[&<>"']/g, function(m) { return map[m]; });
	};
};

angular.module('collaboreApp').filter('escapeHtml', [
	escapeHtml
]);
*/
/**
* Equivalent à stripTags sauf qu'il permet d'authoriser des tags
* @param {String} input - string à traiter
* @param {String} allowed - tag authorisés
* @returns {String} texte traité
* source: http://phpjs.org/functions/strip_tags/
*/

var deleteTags = function () {
	return function (input, allowed) {
		allowed = (((allowed || '') + '')
      .toLowerCase()
      .match(/<[a-z][a-z0-9]*>/g) || [])
      .join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
    var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
      commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
    return input.replace(commentsAndPhpTags, '')
      .replace(tags, function($0, $1) {
        return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
      });
	};
};

angular.module('collaboreApp').filter('deleteTags', [
	deleteTags
]);


/**
* @name isVideo
* @desc Renvoi la date formaté corectement pour la lecture FR
* @param {Object} paramDate - date au format en
* @returns {String} Date
*/
function ytVidId(url) {
  var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  return (url.match(p)) ? RegExp.$1 : false;
}
var isVideo = function ($sce) {
	var urlPattern = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/gi;


	return function (input) {
		if (input === null) {
      return '';
    }
		angular.forEach(input.match(urlPattern), function(url) {
      if(ytVidId(url)){
        input = input.replace(url, $sce.trustAs('html', '<img src="https://s.ytimg.com/yts/img/favicon-vfldLzJxy.ico" />'));
      }else{
        input = input.replace(url, $sce.trustAs('html', url));
      }

    });
    return input;
	};
};

angular.module('collaboreApp').filter('isVideo', [
	'$sce',
	isVideo
]);


angular.module('collaboreApp').filter('bytes', function() {
	return function(bytes, precision) {
		if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
			return '-';
		}
		if (typeof precision === 'undefined') {
			precision = 1;
		}
		var units = ['bytes', 'ko', 'Mo', 'Go', 'To', 'Po'],
			number = Math.floor(Math.log(bytes) / Math.log(1024));
		return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +  ' ' + units[number];
	};
});

angular.module('collaboreApp').filter('replaceAll', function() {
	return function(find, replace, str) {
		if(angular.isDefined(str)){
			return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
		}

	};
});

angular.module('collaboreApp').filter('cleanphone', function() {
    return function(input) {
        if(angular.isDefined(input)){
            input = input.replace(/[^0-9]/g, ''); // Garde que les charactère numérique, les autres sont remplacé par rien
            return input.trim();
        }

    };
});
