(function() {

  'use strict';

  angular
    .module('collaboreApp')
    .component('informationsClient',{
      templateUrl: 'app/components/informations-client/informations-client.component.html',
      bindings : {
        onEmit: '&'
      },
      controllerAs: 'informationsclientctrl',
      controller: InformationsClientController
    });

  /*@ngInject*/
  function InformationsClientController(){
  }
})();
