(function() {

  'use strict';

  /**
   * @desc Composant gerer les parametrage des modules
   * @example <module-parametrage></module-parametrage>
   */
  angular
    .module('collaboreApp')
    .component('moduleParametrage',{
      templateUrl: 'app/parametrage/module-parametrage/module-parametrage.html',
      controllerAs: 'moduleparametragectrl',
      controller: ModuleParametrageCtrl
    });

  /*@ngInject*/
  function ModuleParametrageCtrl($q, COLLAB_CONF, ModalsService, $scope,$rootScope,$filter,sweet,Module,ModuleValParam,ModuleService,UtilsService,ErreurCollabService){
    var ctrl = this;
    var listEditorSMS = [];

    ctrl.isAdmin = UtilsService.getCurrentUser().isAdmin();
    ctrl.isSuperAdmin = UtilsService.getCurrentUser().isSuperAdmin();

    ctrl.getOptionsForNameModuleReferenceParams = getOptionsForNameModuleReferenceParams;

    ctrl.onClickAddModule = onClickAddModule;
    ctrl.onClickEditModule = onClickEditModule;
    ctrl.onClickDeleteModule = onClickDeleteModule;

    ctrl.onSubmitSearchModule = onSubmitSearchModule;
    ctrl.onClickAddParameters = onClickAddParameters;
    //ctrl.onClickAddArrayString = onClickAddArrayString;
    ctrl.onClickDeleteParameters = onClickDeleteParameters;

    ctrl.onClickAddTexarea = onClickAddTexarea;
    ctrl.onClickEditWysiwyg = onClickEditWysiwyg;
    ctrl.onClickAddWysiwyg = onClickAddWysiwyg;
    ctrl.onClickAddWysiwyg = onClickAddWysiwyg;
    ctrl.onClickValidWysiwyg = onClickValidWysiwyg;
    //ctrl.onClickShowNewCategorie = onClickShowNewCategorie;
    //ctrl.onClickAddCategorie = onClickAddCategorie;
    ctrl.onChangeTypeModule = onChangeTypeModule;
    ctrl.onSubmit = onSubmit;
    ctrl.onCancel = onCancel;

    ctrl.modules = {
      search: '',
      //'listePage': COLLAB_CONF.ARRAY_PAGE_MODULE,
      listeType: [],
      liste: {
        loading: false,
        loadingTxt: COLLAB_CONF.MIDDLE_LOADING,
        items: []
      },
      edition: {
        actif: false,
        loading: false,
        saveSuccess: false,
        saveError: false,
        loadingTxt: COLLAB_CONF.MIDDLE_LOADING,
        module: false,								// Module en cours d'édition
        currentModuleType: false							// Module template (permet d'avoir les eventuel changements du module)
      }
    };
    ctrl.listeModules = null;

    ctrl.optionsSelect = {
      default: COLLAB_CONF.ARRAY_CATEGORIES_TEMPLATE,
      iframe: {
        'mode_ouverture': COLLAB_CONF.ARRAY_MODE_OUVERTURE_COLLAB_MODULE
      },
      'lien_externe': {
        'mode_ouverture': COLLAB_CONF.ARRAY_MODE_OUVERTURE_LIEN_EXTERNE_MODULE
      }
    };

    //--------------- CKEDITOR ---------------------------
    ctrl.instanceCKEDITOR = false;
    // Editor options.
    var ckeditorOptions = {
      height: 300,
      on: {
        instanceReady: function(obj) {
          ctrl.instanceCKEDITOR = obj.editor;
          this.dataProcessor.htmlFilter.addRules({
            elements: {
              img: function(el) {

                var isBase64 = UtilsService.test(el.attributes.src,'base64','i');
                if(isBase64){
                  el.addClass('image-max-editor');
                  //el.attributes.style = 'max-width:100%';
                }
              }
            }
          });
        }
      }
    };
    ctrl.ckeditorOptions 	= ckeditorOptions;	// Options CKEDITOR
    ctrl.ckeditorOnReady 	= ckeditorOnReady;	// Fonction onReady de CKEDITOR

    // Called when the editor is completely ready.
    function ckeditorOnReady() {
      // Ajout l'instance qui vien d'être créé dans le pool d'instance de CKEDITOR
      $rootScope._poolInstancesCKEDITOR[0] = ctrl.instanceCKEDITOR;

      if(ctrl.modules.edition.module){
        // Check si le module contient un WYSIWYG
        var hasWysiwyg = ctrl.modules.edition.module.hasWYSIWYG();
        if(hasWysiwyg){
          var index = 0;
          if(hasWysiwyg.listeParametres.length > 1) {
            for(var i = 0; i < hasWysiwyg.listeParametres.length; i++){
              if(hasWysiwyg.listeParametres[i].editMode){
                index = i;
                break;
              }
            }
          }
          var val = '';
          // Si la valeur du WYSIWYG n'est pas vide
          if(hasWysiwyg.listeParametres[index].getValeur() !== ''){

            val = hasWysiwyg.listeParametres[index].getValeur();
            if(hasWysiwyg.listeParametres[index].getReferenceParams().isParameters()) {
              val = hasWysiwyg.listeParametres[index].getValeur().val;
            }

          }

          ctrl.instanceCKEDITOR.setData(val);
        }
      }

    }

    /**
     * Destroy une éventuelle instance de CKEDITOR
     */
    function destroyCKEDITOR(){
      //var deferred = $q.defer();
      if(ctrl.instanceCKEDITOR){

        /*
         ctrl.instanceCKEDITOR.once('destroy', function(){
         console.log('destroy ?');
         deferred.resolve();
         });*/

        ctrl.instanceCKEDITOR.removeAllListeners();
        ctrl.instanceCKEDITOR.destroy();
      }
      //return deferred.promise;
    }

    //--------------- FIN CKEDITOR ---------------------------

    ctrl.$onInit = function(){

      // Liste les modules existants
      ctrl.modules.liste.loading = true;
      ModuleService.getListeModule().then(function(modules){
        //console.log(modules);
        ctrl.modules.liste.items = modules;
      }).finally(function(){
        ctrl.modules.liste.loading = false;
      });



      // Liste les type de module
      initListeType();
    };

    ctrl.$onDestroy = function(){
      destroyCKEDITOR();
    };

    function initListeType(){
      var deferred = $q.defer();

      // Liste les type de module
      ModuleService.getListeTypeModule().then(function(typeModules){
        var list = [];
        if(typeModules.length){
          for(var m = 0; m < typeModules.length; m++) {
            var add = true;
            if(
              (UtilsService.contains(COLLAB_CONF.LIST_MODULE_ONLY_CAPFUN, typeModules[m].typeObjet) && !$rootScope.isCapfunDiese) ||
              (UtilsService.contains(COLLAB_CONF.LIST_MODULE_ONLY_VO, typeModules[m].typeObjet) && !$rootScope.isVaguesOceanesDiese) ||
              (UtilsService.contains(COLLAB_CONF.LIST_MODULE_ONLY_CLICO, typeModules[m].typeObjet) && !$rootScope.isClicochicDiese)
            ){
              add = false;
            }
            if(add) list.push(typeModules[m]);
          }
        }
        ctrl.modules.listeType = list;
        deferred.resolve(list);
      });
      return deferred.promise;
    }

    /**
     * Instanciation de la variable module et du template de module
     */
    function initModule(){
      ctrl.modules.edition.module = new Module();
      ctrl.modules.edition.currentModuleType = Module.newModuleType();
    }

    /**
     * Permet de récuperer une liste d'option pour le champ select par rapport au type de reference
     * @param moduleRefParam
     * @returns {Array|*}
     */
    function getOptionsForNameModuleReferenceParams(typeModule, moduleRefParam) {
      var options = ctrl.optionsSelect.default;
      if(ctrl.optionsSelect.hasOwnProperty(typeModule.typeObjet) && ctrl.optionsSelect[typeModule.typeObjet].hasOwnProperty(moduleRefParam.reference)) {
        options = ctrl.optionsSelect[typeModule.typeObjet][moduleRefParam.reference];
      }
      return options;
    }

    /**
     * Lors du submit du formulaire de recherche
     */
    function onSubmitSearchModule(){
      //var modulesFiltered = UtilsService.fuzzyBy(ctrl.modules.liste.items,'libelle',ctrl.modules.search);
      var modulesFiltered = angular.copy($filter('filter')(ctrl.modules.liste.items,{libelle: ctrl.modules.search}));
      ctrl.listeModules = UtilsService.groupBy(modulesFiltered,'typeModule.libelle');
    }

    /**
     * Lors du clique sur ajouter un modules
     */
    function onClickAddModule(){

      ctrl.modules.edition.actif = true;
      ctrl.modules.edition.saveSuccess = false;
      ctrl.modules.edition.saveError = false;
      ctrl.modules.edition.loading = true;

      initListeType()
        .then(function(){
          initModule();
        })
        .finally(function(){
          ctrl.modules.edition.loading = false;
        });
      /*
      ModuleService.getListeTypeModule().then(function(typeModules){

        ctrl.modules.listeType = typeModules;

        initModule();

      }).finally(function(){
        ctrl.modules.edition.loading = false;
      });*/
    }

    /**
     * Lors du clique sur le bouton d'édition d'un module
     * @param module
     */
    function onClickEditModule(module){

      var idTypeModule = module.getTypeModule().getIdModuleType(),
        currentModuleType;

      for(var m = 0; m < ctrl.modules.listeType.length; m++){
        if(ctrl.modules.listeType[m].idModuleType === idTypeModule){
          currentModuleType = angular.copy(ctrl.modules.listeType[m]);
          break;
        }
      }

      ctrl.modules.edition.actif = true;
      ctrl.modules.edition.saveSuccess = false;
      ctrl.modules.edition.saveError = false;
      ctrl.modules.edition.module = new Module(angular.copy(module));				// Module en cours d'édition
      ctrl.modules.edition.currentModuleType = Module.newModuleType(angular.copy(currentModuleType));	// Module template

      //ctrl.modules.edition.currentModuleType = angular.copy(module);

      // Crée un objet qui groupe les valParam par typeParam en utilisant les valParam existant du module
      ctrl.modules.edition.module.setParamByRefParam(currentModuleType.listeParametres);



      destroyCKEDITOR();
    }

    /**
     * Lors du clique sur le bouton de suppression d'un module
     * @param module
     */
    function onClickDeleteModule(module){
      sweet.show({
        title: 'Supprimer un module',
        text: 'Supprimer le module "' + module.libelle + '" ?<br /><span class="text-danger">Attention si ce module est associé à des groupes, ils ne pourront plus y avoir accès !</span>',
        type: 'warning',
        html:true,
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Oui !',
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      },function(isConfirm){
        if(isConfirm){
          var idModule = module.getIdModule();
          ctrl.modules.edition.loading = true;
          ModuleService.deleteModule(idModule).then(function(){

            if(ctrl.modules.edition.module){
              if(ctrl.modules.edition.module.getIdModule() === idModule){
                onCancel();
              }
            }

            ctrl.modules.liste.items = UtilsService.removeWith(ctrl.modules.liste.items,{idModule:idModule});

            sweet.close();

          },function(msg){
            ErreurCollabService.alert(msg);
          }).finally(function(){
            ctrl.modules.edition.loading = false;
          });

        }else{
          sweet.close();
        }
      });
    }

    /**
     * Fonction appelée lors du changement de type dans le champs select
     */
    function onChangeTypeModule(){

      if(ctrl.modules.edition.module.typeModule){

        var listeParametresModuleRefParam = angular.copy(ctrl.modules.edition.module.typeModule.listeParametres);

        // Set la liste des paramètres du type de module dans le currentModule qui sert de template
        ctrl.modules.edition.currentModuleType.setListeParametres(listeParametresModuleRefParam);

        // Crée un objet qui groupe les valParam par typeParam avec les refParam venant de la liste des type
        // C'est lui qui est modifié et lors de la conversion en json il permet de reconstruire "moduleValParam"
        ctrl.modules.edition.module.setParamByRefParam(listeParametresModuleRefParam);

        var hasWysiwyg = ctrl.modules.edition.module.hasWYSIWYG();

        // Si il n'y à pas de wysiwyg on destroy les éventuels instance du wysiwyg ouvert avant
        if(!hasWysiwyg){
          destroyCKEDITOR();
        }

        // Supprime les ref du type
        delete ctrl.modules.edition.module.typeModule.listeParametres;

        //getOptionsForNameModuleReferenceParams(moduleparametragectrl.modules.edition.module.typeModule, moduleRefParam)

        //console.log(ctrl.modules.edition);

      }else{
        initModule();
        destroyCKEDITOR();
      }

    }

    /**
     * Fonction appelée lors de l'ajout de paramètres
     * @param refParam
     */
    function onClickAddParameters(refParam){

      // Crée un nouveau paramètre avec son type
      var newParam = {
        valeur: {
          key: '',
          val: ''
        },
        referenceParams: angular.copy(refParam)
      };
      // Supprime l'objet "listeParametres" de l'objet "referenceParams"
      delete newParam.referenceParams.listeParametres;

      // Crée un instance de ce paramètre
      var ModuleValParamInstance = new ModuleValParam(newParam);
      // L'ajoute au tableau des paramètre du groupe typeParam
      refParam.listeParametres.push(ModuleValParamInstance);
    }

    /**
     * Fonction appelée lors du clique sur le bouton delete d'un paramètre
     * @param moduleRefParam
     * @param index
     */
    function onClickDeleteParameters(moduleRefParam,index){
      moduleRefParam.listeParametres.splice(index,1);
    }

    function onClickAddTexarea(moduleRefParam){
      // Crée un nouveau paramètre avec son type
      var newParam = {
        valeur: {
          titre: '',
          val: ''
        },
        referenceParams: angular.copy(moduleRefParam)
      };

      // Supprime l'objet "listeParametres" de l'objet "referenceParams"
      delete newParam.referenceParams.listeParametres;

      // Crée un instance de ce paramètre
      var ModuleValParamInstance = new ModuleValParam(newParam);
      // L'ajoute au tableau des paramètre du groupe typeParam
      moduleRefParam.listeParametres.push(ModuleValParamInstance);
    }

    function onClickEditWysiwyg(moduleRefParam,parametre){
      if(moduleRefParam.listeParametres.length) {
        for(var i = 0; i < moduleRefParam.listeParametres.length; i++) {
          if(moduleRefParam.listeParametres[i].editMode) {
            moduleRefParam.listeParametres[i].valeur.val = ctrl.instanceCKEDITOR.getData();
          }
          moduleRefParam.listeParametres[i].editMode = false;
        }
      }
      destroyCKEDITOR();
      parametre.editMode = true;
    }

    function onClickAddWysiwyg(refParam){


      var index = refParam.listeParametres.length - 1;
      onClickValidWysiwyg(refParam,refParam.listeParametres[index]);

      // Crée un nouveau paramètre avec son type
      var newParam = {
        valeur: {
          titre: '',
          val: ''
        },
        editMode: true,
        referenceParams: angular.copy(refParam)
      };

      // Supprime l'objet "listeParametres" de l'objet "referenceParams"
      delete newParam.referenceParams.listeParametres;

      // Crée un instance de ce paramètre
      var ModuleValParamInstance = new ModuleValParam(newParam);
      // L'ajoute au tableau des paramètre du groupe typeParam
      refParam.listeParametres.push(ModuleValParamInstance);
    }

    function onClickValidWysiwyg(refParam,parametre){

      if(ctrl.instanceCKEDITOR.getData() === '') {
        ModalsService.alertErreur('Champ vide !');
        return;
      }

      parametre.valeur.val = ctrl.instanceCKEDITOR.getData();

      if(refParam.listeParametres.length) {
        for(var i = 0; i < refParam.listeParametres.length; i++) {
          refParam.listeParametres[i].editMode = false;
        }
      }

      destroyCKEDITOR();
    }

    /*
    function onClickShowNewCategorie(){
        ctrl.showNewCategorie = true;
    }

    function onClickAddCategorie(){
        console.log(ctrl.modules.edition.module);
    }*/

    /**
     * Fonction appelée lors du submit du formulaire
     */
    function onSubmit(){
      //console.log(ctrl.modules.edition.module.paramByRefParam);
      //console.log(ctrl.modules.edition.currentModuleType);
      //console.log(ctrl.modules.edition.currentModuleType.toJson());
      //return;

      ctrl.modules.edition.loading = true;

      // Si il y a un WYSIWYG dans le module
      var hasWysiwyg = ctrl.modules.edition.module.hasWYSIWYG();

      if(hasWysiwyg){

        var objVal;
        if(hasWysiwyg.listeParametres.length > 1) {

          for(var i = 0; i < hasWysiwyg.listeParametres.length; i++){
            if(hasWysiwyg.listeParametres[i].editMode){

              // Recupère la valeur de CKEDITOR pour remplacer dans les paramètres
              objVal = hasWysiwyg.listeParametres[i].getValeur();
              objVal.val = ctrl.instanceCKEDITOR.getData();
              hasWysiwyg.listeParametres[i].setValeur(objVal);
              break;
            }
          }
        }
        else if(!angular.isObject(hasWysiwyg.listeParametres[0].getValeur())) {
          // Recupère la valeur de CKEDITOR pour remplacer dans les paramètres
          hasWysiwyg.listeParametres[0].setValeur(ctrl.instanceCKEDITOR.getData());
        }
        else {
          objVal = hasWysiwyg.listeParametres[0].getValeur();
          objVal.val = ctrl.instanceCKEDITOR.getData();
          hasWysiwyg.listeParametres[0].setValeur(objVal);
        }
      }

      var jsonModule = ctrl.modules.edition.module.toJson();

      ModuleService.saveModule(jsonModule).then(function(module){
        destroyCKEDITOR();

        var exist = false;
        for(var i = 0; i < ctrl.modules.liste.items.length; i++){
          if(ctrl.modules.liste.items[i].idModule === module.getIdModule()){
            ctrl.modules.liste.items[i] = module;
            exist = true;
            break;
          }
        }

        if(!exist){
          ctrl.modules.liste.items.push(module);
        }
        ctrl.modules.edition.saveSuccess = true;
        ctrl.modules.edition.saveError = false;
        ctrl.modules.edition.module = false;
        ctrl.modules.edition.currentModuleType = false;

      },function(msg){

        ctrl.modules.edition.saveError = msg;
        ctrl.modules.edition.saveSuccess = false;

      }).finally(function(){
        ctrl.modules.edition.loading = false;
      });
    }

    /**
     * Fonction appelé lors du clique sur le bouton annulé du formulaire
     */
    function onCancel(){
      cancel();
    }

    /**
     * Fonction qui permet de vider d'annuler le formulaire
     */
    function cancel(){
      ctrl.modules.edition.actif = false;
      ctrl.modules.edition.module = false;
      ctrl.modules.edition.currentModuleType = false;
      ctrl.modules.edition.saveError = false;
      ctrl.modules.edition.saveSuccess = false;
      destroyCKEDITOR();
    }

    /**
     * Watcher qui écoute les changement dans la liste de modules pour recréer une liste de modules groupé par type
     */
    $scope.$watchCollection('moduleparametragectrl.modules.liste.items',function(newVal,oldVal){
      if(newVal !== oldVal){
        ctrl.listeModules = UtilsService.groupBy(newVal,'typeModule.libelle');
      }
    });


  }
})();
