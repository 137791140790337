'use strict';

/**
 * @desc Indique que le contenu est vue par
 * @example <contenu-vu-par></contenu-vu-par>
 */

angular
	.module('collaboreApp')
	.component('contenuVuPar',{
		templateUrl: 'app/topics/contenu-vu-par.component.html',
		bindings : {
			contenu: '='
		},
		controllerAs: 'contenuvuparctrl',
		controller: ['UserService','UtilsService',ContenuVuParController]
	});

function ContenuVuParController(UserService,UtilsService){

	var ctrl = this;
	var limitVuPar = 3;

	ctrl.$onInit = function() {
		displayPersoLu(ctrl.contenu);
	};

	ctrl.displayPersoLu = displayPersoLu;	// Permet d'afficher les id des collaborateur qui ont lu le contenu

	/**
	 * @desc Permet d'afficher les id des collaborateur qui ont lu le contenu
	 * @param {Object} itemContenu - objet contenu
	 * @returns {void} ajoute deux attribut à l'objet contenu (vuPar et vuParLesAutres)
	 */
	function displayPersoLu(itemContenu){

		var retour = '';
		if(itemContenu.hasOwnProperty('toutLeMondeALu')){
			// Si tout le monde à lu
			if(itemContenu.toutLeMondeALu){

				retour += '<i class="fa fa-check"></i> Vu par tout le monde';

				// Si le contenu n'est pas lu par tout le monde
			}else{
				// Si la liste des destinaire qui ont lu est bien un tableau
				if(angular.isArray(itemContenu.listeDestinatairesQuiOntLu)){

					itemContenu.listeDestinatairesQuiOntLu = UtilsService.removeWith(itemContenu.listeDestinatairesQuiOntLu,{idGroupe:UserService.getGroupe().getIdGroupe()});

					// Nombre de destinataire qui ont lu
					var nbDestQuiOntLu = itemContenu.listeDestinatairesQuiOntLu.length;

					// Si il est différent de 0
					if(nbDestQuiOntLu){

						retour += '<i class="fa fa-check"></i> Vu par ';

						// Pour chaque destinataire qui a lu jusq'au nombre limite
						for (var i = 0; i < limitVuPar; i++) {
							if(angular.isObject(itemContenu.listeDestinatairesQuiOntLu[i])){
								retour += itemContenu.listeDestinatairesQuiOntLu[i].libelle+itemContenu.listeDestinatairesQuiOntLu[i].dieze+', ';
							}
						}

						// Supprime la virgule à la fin de la chaine
						retour = UtilsService.rtrim(retour,', ');

						// Si le nombre de destinataire qui ont lu est plus grand que la limite d'affichage
						// ce sont alors les autres qui s'affiche dans une infobulle
						if(nbDestQuiOntLu>limitVuPar){

							var autresPerso = '';
							// Pour chaque autre destinataire qui est hors de la limite d'affichage
							for (var j = limitVuPar; j < nbDestQuiOntLu; j++) {
								if(angular.isObject(itemContenu.listeDestinatairesQuiOntLu[j])){
									autresPerso += itemContenu.listeDestinatairesQuiOntLu[j].libelle+itemContenu.listeDestinatairesQuiOntLu[j].dieze+'<br />';
								}

							}
							// Supprime la balise br à la fin de la chaine
							itemContenu.vuParLesAutres = UtilsService.rtrim(autresPerso,'<br />');
						}
					}else{
						retour += '<i class="fa fa-remove"></i> Vu par personne...';
					}
				}
			}
		}


		itemContenu.vuPar = retour;
	}

}



