(function() {
  'use strict';


  /**
   * @ngdoc service
   * @name collaboreApp.ContenuMessage
   * @description
   * # ContenuMessage
   * Service in the collaboreApp.
   */

  angular.module('collaboreApp').factory('ContenuMessage', ContenuMessageModel);

  /** @ngInject */
  function ContenuMessageModel(COLLAB_CONF, Contenu,$filter,UtilsService) {

    var ContenuMessage = function(contenuData) {
      Contenu.apply(this, arguments);
      this.typeContenu = COLLAB_CONF.TYPE_CONTENU_MESSAGE;
      this.previsualisationMsg = UtilsService.truncate(UtilsService.stripTags(this.message), 130, '...');
      //this.previsualisationMsg = UtilsService.stripTags(this.message);
    };

    ContenuMessage.prototype = {

      model: 'ContenuMessage',
      isMessage: true,

      getSms: function(){
        return this.sms;
      },
      setSms: function(val){
        this.sms = val;
      },

      getMessage: function(){
        return this.message;
      },
      getText: function(){
        return UtilsService.stripTags(this.message);
      },
      setMessage: function(message){

        if(message){
          this.message = $filter('replaceAll')('img-responsive','image-max-editor',message);
        }else{
          this.message = message;
        }

      },

      getMessageSms: function(){
        return this.messageSms;
      },
      setMessageSms: function(message){
        this.messageSms = message;
      },

      getProxyMessage: function(){
        return this.proxyMessage;
      },
      setProxyMessage: function(message){

        //this.message = message;
        //var dom = HTMLtoDOM(message);
        //var imgs = dom.getElementsByTagName("img");
        /*
         if(imgs.length){
         angular.forEach(imgs,function(img){
         var $img = $(img);

         if($img.hasClass('img-responsive')){
         $img.removeClass('img-responsive');
         }
         });

         }*/
        if(message){
          this.proxyMessage = $filter('replaceAll')('img-responsive','image-max-editor',message);
        }else{
          this.proxyMessage = message;
        }

        //console.log(imgs);
        /*
         var $msg = $(message);
         console.log($msg);*/
        //var imgs = $msg.find('img[src~="/smiley/"]');
        //console.log(imgs.eq(0).text());

      },

      getMailInfos: function() {
        return this.mailInfos;
      },
      setMailInfos: function(val) {
        var mailInfos = val;
        if(angular.isObject(mailInfos) && !mailInfos.isModel){
          var ProxyMail = Contenu.Main.$injector.get('ProxyMail');
          mailInfos = new ProxyMail(mailInfos);
          ProxyMail = null;
        }
        this.mailInfos = mailInfos;
      },

      getDestinatairesSms: function(){
        return this.destinatairesSms;
      },
      setDestinatairesSms: function(arrayDest){
        var destinatairesSms = [];
        if(_.isArray(arrayDest) && arrayDest.length) {
          var DestinataireSms = Contenu.Main.$injector.get('DestinataireSms');
          for(var i = 0; i < arrayDest.length; i++){
            if(_.isObject(arrayDest[i]) && !arrayDest[i].isModel){
              arrayDest[i] = new DestinataireSms(arrayDest[i]);
            }
            destinatairesSms.push(arrayDest[i]);
          }
          DestinataireSms = null;
        }
        this.destinatairesSms = destinatairesSms;
      },
      hasDestinatairesSms: function(){
        return !_.isEmpty(this.getDestinatairesSms());
      }

    };

    // Copie le prototype de Contenu dans le prototype de ContenuMessage
    // ContenuMessage hérite donc des methodes de Contenu
    angular.extend(ContenuMessage.prototype, Contenu.prototype);

    return ContenuMessage;
  }
})();
