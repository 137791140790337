(function() {
    'use strict';
    /**
     * @ngdoc factory
     * @name collaboreApp.ProxyTag
     * @description
     * # ProxyTag
     */

    angular.module('collaboreApp').factory('ProxyPersoIcs', ProxyPersoIcsModel);

    /** @ngInject */
    function ProxyPersoIcsModel(Main, ProxyRenseignement) {

        function ProxyPersoIcs(objetData) {
            Main.apply(this, arguments);
            this.hydrate(objetData);
        }

        ProxyPersoIcs.prototype = {

            model: 'ProxyPersoIcs',
            isProxy: true,


            // Id Perso ICS
            getIdPersoICS: function(){
                return this.idPersoICS;
            },
            setIdPersoICS: function(id){
                this.idPersoICS = id;
            },

            // numero
            getNumero: function(){
                return this.numero;
            },
            setNumero: function(val){
                this.numero = val;
            },

            // type
            getType: function(){
                return this.type;
            },
            setType: function(val){
                this.type = val;
            },

            // libelle
            getLibelle: function(){
                return this.libelle;
            },
            setLibelle: function(val){
                this.libelle = val;
            },

            // idPortefeuille
            getIdPortefeuille: function(){
                return this.idPortefeuille;
            },
            setIdPortefeuille: function(val){
                this.idPortefeuille = val;
            },
            setPortefeuille: function(val){
                if(angular.isObject(val)){
                    this.setIdPortefeuille(val.idPortefeuille);
                }
            },

            // codemetier
            getCodemetier: function(){
                return this.codemetier;
            },
            setCodemetier: function(val){
                this.codemetier = val;
            },

            // metier
            setMetier: function(val){
                this.codemetier = val;
            },

            // noperso
            getNoperso: function(){
                return this.noperso;
            },
            setNoperso: function(val){
                this.noperso = val;
            },

            // informations
            getInformations: function(){
                return this.informations;
            },
            setInformations: function(arrayVal){
                var liste = [];
                if(angular.isArray(arrayVal)){
                    for(var i = 0 ; i < arrayVal.length ;  i++){
                        var val = arrayVal[i];
                        if(!val.isModel || !val.isProxy) {
                            val = new ProxyRenseignement(val);
                        }
                        if(val.getDonnee() && val.getDonnee() !== '') {
                            liste.push(val);
                        }
                    }
                }
                this.informations = liste;
            }

        };

        angular.extend(ProxyPersoIcs.prototype, Main.prototype);

        return ProxyPersoIcs;
    }
})();