(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name collaboreApp.AdminTopicService
     * @description
     * # AdminTopicService
     * Service in the collaboreApp.
     */
    angular
        .module('collaboreApp')
        .service('AdminTopicService',
            AdminTopicService
        );

    /** @ngInject */
    function AdminTopicService(MainService, $q, ModalsService, AdminTopicAjax) {


        var _this = this;
        _this.saveCategorieTopic = saveCategorieTopic;
        _this.deleteCategorieTopic = deleteCategorieTopic;

        /**
         * Permet de sauvegarder une categorie de topic
         * @returns {*}
         */
        function saveCategorieTopic(obj){
            var deferred = $q.defer();
            try {
                if (!_.isObject(obj)) throw new Error('Le paramètre n\'est pas un objet !');
                var params= {
                    action: 'saveCategorieTopic',
                    libelleCategorieTopic: obj.libelle,
                    textColorCategorieTopic: obj.textColor
                };

                if(!_.isNil(obj.idCategorieTopic))  params.idCategorieTopic = obj.idCategorieTopic;
                if(!_.isNil(obj.description))       params.descriptionCategorieTopic = obj.description;
                if(obj.idCategorieTopicParent)      params.idCategorieTopicParent = obj.idCategorieTopicParent;
                else                                params.idCategorieTopicParent = null;

                AdminTopicAjax
                    .post(params)
                    .$promise
                    .then(function onSuccess(json){
                        deferred.resolve(MainService.convertJsonToModel(json, 'result', 'CategorieTopic', deferred));
                    })
                    .catch(function onError(err) {
                        deferred.reject(err);
                    });
            }
            catch (e) {
                ModalsService.alertErreur(e.message);
                deferred.reject(e.message);
            }
            return deferred.promise;
        }

        /**
         * Permet de récupérer la liste des categories de topic
         * @returns {*}
         */
        function deleteCategorieTopic(idCategorieTopic){
            var deferred = $q.defer();
            try {
                if (_.isNil(idCategorieTopic)) throw new Error('Le paramètre idCategorieTopic est obligatoire !');

                var params = {
                    action: 'deleteCategorieTopic',
                    idCategorieTopic: idCategorieTopic
                };

                AdminTopicAjax
                    .post(params)
                    .$promise
                    .then(function onSuccess(json){
                        deferred.resolve(MainService.convertJsonToModel(json, null, null, deferred));
                    })
                    .catch(function onError(err) {
                        deferred.reject(err);
                    });
            }
            catch (e) {
                ModalsService.alertErreur(e.message);
                deferred.reject(e.message);
            }
            return deferred.promise;
        }
    }
})();
