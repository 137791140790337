(function() {

    'use strict';

    /**
     * @desc Composant gerer mes groupes
     * @example <admin-gestion-des-groupes-module></admin-gestion-des-groupes-module>
     */
    angular
        .module('collaboreApp')
        .component('adminGestionDesGroupesModule',{
            bindings: {
                modules: '<'
            },
            templateUrl: 'app/parametrage/admin/gestion-des-groupes/admin-gestion-des-groupes-module.html',
            controllerAs: 'admingestiondesgroupesmodulectrl',
            controller: AdminGestionDesGroupesModuleCtrl
        });

    /** @ngInject */
    function AdminGestionDesGroupesModuleCtrl(COLLAB_CONF,UtilsService,ModuleService){
        var ctrl = this;

        ctrl.search = '';
        ctrl.listeModules = [];

        ctrl.onSubmitFilterModules = onSubmitFilterModules;
        ctrl.startDragModule = startDragModule;


        ctrl.loading = {
            'show': false,
            'content': COLLAB_CONF.MIDDLE_LOADING
        };
        
        ctrl.$onInit = function(){

            if(angular.isUndefined(ctrl.modules)){
                ctrl.loading.show = true;
                ModuleService.getListeModule().then(function(modules){
                    ctrl.modules = modules;
                    ctrl.listeModules = UtilsService.groupBy(modules,'typeModule.libelle');
                }).finally(function(){
                    ctrl.loading.show = false;
                });
            }
        };


        /**
         * Lors du start d'un drag and drop d'un module
         */
        function startDragModule(event, ui) {
            angular.element(ui.helper).addClass('ui-draggable-portefeuille-helper');
        }



        function onSubmitFilterModules(){

            var modulesFiltered = UtilsService.fuzzyBy(ctrl.modules,'libelle',ctrl.search);
            ctrl.listeModules = UtilsService.groupBy(modulesFiltered,'typeModule.libelle');

            //vm.modules.filterModule[key] = angular.copy(vm.modules.searchModule[key]);
        }

        
    }
})();