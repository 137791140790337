(function() {
	'use strict';

	angular.module('collaboreApp').factory('RecapMail', RecapMailModel);

	/*@ngInject*/
	function RecapMailModel(Main, Topic, Groupe, DestinataireMail, Document){

		function RecapMail(objetData){
            Main.apply(this, arguments);
            this.hydrate(objetData);
		}

		RecapMail.prototype = {

		    model: 'RecapMail',
		    isRecapMail: true,

			getIdRecapMail: function(){
				return this.idRecapMail;
			},
            setIdRecapMail: function(id){
				this.idRecapMail = id;
			},

            getDateCreation: function(){
                return this.dateCreation;
            },
            setDateCreation: function(date){
                this.dateCreation = date;
                this.setDateCreationFormatUS(date);
                this.setDateCreationFormatFR(date);
            },

            getDateCreationFormatUS: function(){
                return this.dateCreationFormatedUS;
            },
            setDateCreationFormatUS: function(date){
                this.dateCreationFormatedUS = moment(date).format('YYYY-MM-DD');
            },

            getDateCreationFormatFR: function(){
                return this.dateCreationFormatedFR;
            },
            setDateCreationFormatFR: function(date){
                this.dateCreationFormatedFR = moment(date).format('DD/MM/YYYY HH:mm:ss');
            },

            getDateEnvoie: function(){
                return this.dateEnvoie;
            },
            setDateEnvoie: function(date){
                this.dateEnvoie = date;
                this.setDateEnvoieFormatFR(date);
            },

            getDateEnvoieFormatFR: function(){
                return this.dateEnvoieFormatedFR;
            },
            setDateEnvoieFormatFR: function(date){
                this.dateEnvoieFormatedFR = moment(date).format('DD/MM/YYYY HH:mm:ss');
            },

            getEtat: function(){
                return this.etat;
            },
            setEtat: function(etat){
                this.etat = etat;
            },
            getTypeMail: function(format){
                var type = this.typeMail;
                if(format) {
                    switch(type) {
                        case 'fax':
                            type = 'Fax';
                            break;
                        case 'sms':
                            type = 'SMS';
                            break;
                        case 'newDevis':
                            if(this.getContenu().getDemandeDevisActive()){
                                type = 'Demande de Devis';
                            }
                            else {
                                type = 'Demande d\'Intervention';
                            }
                            break;
                        case 'relanceDevis':
                            if(this.getContenu().getDemandeDevisActive()){
                                type = 'Relance de Devis';
                            }
                            else {
                                type = 'Relance Intervention';
                            }
                            break;
                        case 'devisSigne':
                            type = 'Devis signé';
                            break;
                        case 'checkDevis':
                            if(this.getContenu().isAccepte()){
                                type = 'Devis Accepté';
                            }
                            if(this.getContenu().isRefuse()){
                                type = 'Devis Refusé';
                            }
                            break;
                        case 'sendMessageByMail':
                            type = 'Message';
                            break;
                        case 'diversNewMessage':
                            type = 'Message';
                            break;
                        case 'newDevisCopieGravitant':
                            type = 'Copie Demande de Devis';
                            break;
                        case 'newInterventionCopieGravitant':
                            type = 'Copie Demande d\'Intervention';
                            break;
                        default:
                            type = type;
                    }
                }
                return type;
            },
            setTypeMail: function(type){
                this.typeMail = type;
            },

            getTopic: function(){
                return this.topic;
            },
            setTopic: function(topic){
                if(angular.isObject(topic)) {
                    if(!topic.isModel) {
                        topic = new Topic(topic);
                    }
                    this.topic = topic;
                }
            },

            getContenu: function(){
                return this.contenu;
            },
            setContenu: function(contenu){
                if(angular.isObject(contenu)) {
                    if(!contenu.isModel) {
                        contenu = Topic.initContenu(contenu);
                    }
                    this.contenu = contenu;
                }
            },

            getExpediteur: function(){
                return this.expediteur;
            },
            setExpediteur: function(expediteur){
                if(angular.isObject(expediteur)) {
                    if(!expediteur.isModel) {
                        expediteur = new Groupe(expediteur);
                    }
                    this.expediteur = expediteur;
                }
            },

            getIdGroupeAll: function(){
                return this.idGroupeAll;
            },
            setIdGroupeAll: function(id){
                this.idGroupeAll = id;
            },

            getGroupeDestinataires: function(){
                return this.groupeDestinataires;
            },
            setGroupeDestinataires: function(liste){
                var listeDestinataires = [];
                if(angular.isArray(liste) && liste.length) {
                    for(var i = 0; i < liste.length; i++){
                        if(angular.isObject(liste[i]) && !liste[i].isModel){
                            liste[i] = new Groupe(liste[i]);
                        }
                        listeDestinataires.push(liste[i]);
                    }
                }
                this.groupeDestinataires = listeDestinataires;
            },

            getDestinataires: function(){
                return this.destinataires;
            },
            setDestinataires: function(liste){
                var listeDestinataires = [];
                if(angular.isArray(liste) && liste.length) {
                    for(var i = 0; i < liste.length; i++){
                        if(angular.isObject(liste[i]) && !liste[i].isModel){
                            liste[i] = new DestinataireMail(liste[i]);
                        }
                        listeDestinataires.push(liste[i]);
                    }
                }
                this.destinataires = listeDestinataires;
            },

            getSujetMail: function(length){
                var sujet = this.sujetMail;
                if(length && angular.isNumber(length) && sujet.length>length) {
                    sujet = sujet.substring(0,length) + '...';
                }
                return sujet;
            },
            setSujetMail: function(val){
                this.sujetMail = val;
            },

            getMessageMail: function(){
                return this.messageMail;
            },
            setMessageMail: function(val){
                this.messageMail = val;
            },

            hasMessageErreur: function(){
                return (this.hasOwnProperty('messageErreur') && this.messageErreur !== '');
            },
            getMessageErreur: function(){
                return this.messageErreur;
            },
            setMessageErreur: function(val){
                this.messageErreur = val;
            },

            getPiecesJointes: function(){
                return this.piecesJointes;
            },
            setPiecesJointes: function(liste){
                var listeDocument = [];
                if(angular.isArray(liste) && liste.length) {
                    for(var i = 0; i < liste.length; i++){
                        if(angular.isObject(liste[i]) && !liste[i].isModel){
                            liste[i] = new Document(liste[i]);
                        }
                        listeDocument.push(liste[i]);
                    }
                }
                this.piecesJointes = listeDocument;
            },


            canResend: function(){
                return (this.getEtat() === -1 || this.getEtat() === 0);
            }
		};

        angular.extend(RecapMail.prototype, Main.prototype);

		return RecapMail;
	}

})();