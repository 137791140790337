(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.ComfactUser
   * @description
   * # ComfactUser
   * Service in the collaboreApp.
   */

  angular.module('collaboreApp').factory('ComfactUser', ComfactUserModel);

  /** @ngInject */
  function ComfactUserModel(Main) {

    function ComfactUser(objetData) {
      Main.apply(this, arguments);
      this.hydrate(objetData);
    }

    ComfactUser.prototype = {

      model: 'ComfactUser',
      isComfactUser: true,

      // nom
      getNom: function(){
        return this.nom;
      },
      setNom: function(nom){
        this.nom = nom;
      },

      // login
      getLogin: function(){
        return this.login;
      },
      setLogin: function(login){
        this.login = login;
      },

      // mdp
      getMdp: function(){
        return this.mdp;
      },
      setMdp: function(mdp){
        this.mdp = mdp;
      },

      // service
      getService: function(){
        return this.service;
      },
      setService: function(service){
        this.service = service;
      },

      // permission
      getPermission: function(){
        return this.permission;
      },
      setPermission: function(permission){
        this.permission = permission;
      },

      // initiales
      getInitiales: function(){
        return this.initiales;
      },
      setInitiales: function(initiales){
        this.initiales = initiales;
      },

      // suppression
      getSuppression: function(){
        return this.suppression;
      },
      setSuppression: function(suppression){
        this.suppression = suppression;
      }
    };
    angular.extend(ComfactUser.prototype, Main.prototype);

    return ComfactUser;

  }
})();
