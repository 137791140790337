(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .component('modalSendGed', {
      templateUrl: 'app/topics/send-ged/v1/modal-send-ged.component.html',
      controller: ModalSendGedController,
      controllerAs: 'modalsendgedctrl',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      }
    });

  /** @ngInject */
  function ModalSendGedController($scope, GedService, UtilsService, ModalsService) {
    var ctrl = this;

    ctrl.nbTotalStep = 2;

    /********** Global **********/
    //ctrl.currentStep = 1;
    ctrl.libelleStep = {
      1: {
        libelle: 'Création PDF'
      },
      2: {
        libelle: 'Chemin GED'
      }
    };
    ctrl.gedChemin = null;

    ctrl.json = null;
    ctrl.topic = null;

    ctrl.onClickChangeEtape = onClickChangeEtape;
    ctrl.onClickPreviousStep = onClickPreviousStep;     // Lors du clique sur l'étape précédente
    ctrl.onClickNextStep = onClickNextStep;             // Lors du clique sur l'étape suivante
    ctrl.onClickExport = onClickExport;                 // Lors du clique sur le bouton envoyer
    ctrl.onClickClose = onClickClose;                   // Lors du clique sur le bouton Fermer ou la croix

    /********** Etape 1 **********/
    ctrl.onUpdateCreationPdf = onUpdateCreationPdf;

    /********** Etape 2 **********/
    ctrl.onUpdateCheminGed = onUpdateCheminGed;

    /********** Global **********/
    ctrl.$onInit = function () {

      ctrl.idTopic = angular.copy(getParam('idTopic'));
      ctrl.nomPdf = ctrl.idTopic;
      ctrl.documentTopic = getParam('documentTopic');
      ctrl.idEvenement = getParam('idEvenement');
      ctrl.hasMessage = getParam('hasMessage');
      ctrl.hasSinistre = getParam('hasSinistre');
      ctrl.hasCourriers = getParam('hasCourriers');
      ctrl.hasDevisSigne = getParam('hasDevisSigne');
      ctrl.hasPieceJointe = getParam('hasPieceJointe');
      ctrl.hasDevisOuIntervention = getParam('hasDevisOuIntervention');
      ctrl.tagImmeuble = getParam('tagImmeuble');
      ctrl.tagMandat = getParam('tagMandat');
      ctrl.portefeuille = getParam('portefeuille');
      if(!ctrl.idTopic) ModalsService.alertErreur('Pas d\'idTopic');

      // le t devant la variable signifie le test : si oui ou non il existe au niveau du topic
      ctrl.mainSendGed = {
        id: ctrl.idTopic,
        nomPdf: ctrl.nomPdf,
        gedChemin: ctrl.gedChemin,
        idEvenement: ctrl.idEvenement,
        tMessage: ctrl.hasMessage,
        tSinistre: ctrl.hasSinistre,
        tCourriers: ctrl.hasCourriers,
        tDevisSigne: ctrl.hasDevisSigne,
        tPieceJointe: ctrl.hasPieceJointe,
        tDevisOuIntervention: ctrl.hasDevisOuIntervention
      };

      ctrl.currentStep = getParam('step', 1);
      if (ctrl.tagImmeuble) ctrl.numImmeuble = ctrl.tagImmeuble.getValeurIcs(); else ctrl.numImmeuble = "0";
      if (ctrl.tagMandat) ctrl.numMandat = ctrl.tagMandat.getValeurIcs(); else ctrl.numMandat = "0";
      ctrl.idPortefeuille = ctrl.portefeuille.getIdPortefeuille();
    };

    ctrl.$onDestroy = function () {
      ctrl.mainSendGed = null;
    };

    function getParam(param, defaultVal){
      return UtilsService.getParamModalComponent(ctrl.resolve, param, defaultVal);
    }

    function onClickChangeEtape(num){
      ctrl.currentStep = num;
    }

    /**
     * Lors du clique sur l'étape précédente
     */
    function onClickPreviousStep(){
      if(ctrl.currentStep > 1) {
        $scope.$broadcast('getInfosCreationPdf');
        ctrl.currentStep = ctrl.currentStep-1;
      }
    }

    /**
     * Lors du clique sur l'étape suivante
     */
    function onClickNextStep(){
      if(ctrl.currentStep < ctrl.nbTotalStep) {
        $scope.$broadcast('getInfosCreationPdf');
        ctrl.currentStep = ctrl.currentStep+1;
      }
    }

    /**
     * Lors du clique sur le bouton terminé
     */
    function onClickExport(){

      ctrl.loading = true;
      ctrl.mainSendGed.gedChemin = ctrl.gedChemin;
      var pdfSendGed = {
        pdfGedChemin: ctrl.mainSendGed.gedChemin,
        pdfIdTopic: ctrl.mainSendGed.id,
        pdfNom: ctrl.mainSendGed.nomPdf, // Mettre le nom du topic
        pdfMessage: (_.isObject(ctrl.mainSendGed.infos) && ctrl.mainSendGed.infos.topicMessages) ? true : false,
        pdfSinistre: (_.isObject(ctrl.mainSendGed.infos) && ctrl.mainSendGed.infos.topicSinistre) ? true : false,
        pdfCourriers: (_.isObject(ctrl.mainSendGed.infos) && ctrl.mainSendGed.infos.topicCourriers) ? true : false,
        pdfDevisSigne: (_.isObject(ctrl.mainSendGed.infos) && ctrl.mainSendGed.infos.topicDevisSigne && !ctrl.mainSendGed.infos.topicDevisOuIntervention) ? true : false,
        pdfPieceJointe: (_.isObject(ctrl.mainSendGed.infos) && ctrl.mainSendGed.infos.topicPiecesJointes) ? true : false,
        pdfDevisOuIntervention: (_.isObject(ctrl.mainSendGed.infos) && ctrl.mainSendGed.infos.topicDevisOuIntervention) ? true : false
      };

      GedService
        .exportInGed(pdfSendGed)
        .then(function(topic){
          ctrl.sentWithSuccess = true;
          ctrl.topic = topic;
        })
        .catch(function(msg) {
          ctrl.sentWithSuccess = false;
          console.error(msg);
        })
        .finally(function(){
          ctrl.loading = false;
        });
    }

    function onClickClose(){
      if(ctrl.sentWithSuccess) ctrl.close({$value: ctrl.topic});
      else ctrl.dismiss({$value: 'cancel'});
    }

    /********** Etape 1 **********/

    function onUpdateCreationPdf(infos){
      ctrl.mainSendGed.infosCreationPdf = infos;
    }

    /********** Etape 2 **********/
    function onUpdateCheminGed(){
      ctrl.mainSendGed.gedChemin = ctrl.gedChemin;
    }

  }
})();

