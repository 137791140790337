(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name collaboreApp.MainService
     * @description
     * # MainService
     * Service in the collaboreApp.
     */

    angular
        .module('collaboreApp')
        .service('NotificationsToastServices',
            NotificationsToastServicesController
        );


	function NotificationsToastServicesController($rootScope, $state, toasty, NotificationsToast, UtilsService) {

		var ctrl = this;
		var notificationsContenus = {};
		var lastNotifNewTopic;
		var NotificationHTML5 = NotificationsToast.NotificationHTML5();

		ctrl.desktop = desktop;
		ctrl.success = success;
		ctrl.info = info;
		ctrl.error = error;
		ctrl.wait = wait;
		ctrl.clearAll = clearAll;
		ctrl.clear = clear;
		ctrl.newTopic = newTopic;
		ctrl.newContenu = newContenu;

		function desktop(obj,callBackAdd,callBackOnClick){

			var options = {};
			var title;
			if(obj.hasOwnProperty('title')) title = obj.title;
			else title = '';

			if(obj.hasOwnProperty('msg')) options.body = obj.msg;
			if(obj.hasOwnProperty('icon')) options.icon = obj.icon;

			if (NotificationHTML5.permission === 'granted') {

        if(options.hasOwnProperty('body')) options.body = UtilsService.replaceAll('<br />', '\n', options.body);

				var instance = new NotificationHTML5(title,options);

				instance.onclick = function () {
					if(callBackOnClick){
						callBackOnClick();
					}
				};
				instance.onerror = function () {
					// Something to do
				};
				instance.onshow = function () {
					if(callBackAdd){
						callBackAdd();
					}
				};
				instance.onclose = function () {
					// Something to do
				};

				setTimeout(instance.close.bind(instance), 10000);
			}
			else {
				ctrl.info(title, options.body, false,
					function(notif) {
						if(callBackAdd){
							callBackAdd(notif);
						}
					},function(){
						if(callBackOnClick){
							callBackOnClick(false);
						}
					}
				);
			}

			return false;
		}

		function success(titre,message,callBackAdd,callBackClick){
			toasty.success({
				title: titre,
				msg: message,
				limit: 1,
				timeout: 3000,
				/*
				onAdd: function() {
					console.log('Toasty ' + this.id + ' has been added!', this);
				},
				onRemove: function() {
					console.log('Toasty ' + this.id + ' has been removed!', this);
				},*/
				onAdd: function() {
					if(callBackAdd){
						callBackAdd(this);
					}
				},
				onClick: function() {
					if(callBackClick){
						callBackClick(this);
					}
				}
			});
		}

		function info(titre,message,timeout,callBackAdd,callBackClick){

			//var closeOnClick = false;
			//if(!timeout) closeOnClick = true;

			toasty.info({
				title: titre,
				msg: message,
				limit: 3,
				timeout: timeout,
				clickToClose: true,
				sound: true,
				shake: false,
				onAdd: function() {
					if(callBackAdd) callBackAdd(this);
				},
				onClick: function() {
					if(callBackClick) callBackClick(this);
				}
			});
		}

		function error(titre,message,callBackClick){

			toasty.error({
				title: titre,
				msg: message,
				timeout: 5000,
				onClick: function() {
					if(callBackClick){
						callBackClick(this);
					}
				}
			});
		}

		function wait(titre,message,callBackAdd,callBackClick){

			//ctrl.clearAll();
			toasty.wait({
				title: titre,
				msg: message,
				timeout: false,
				onAdd: function() {
					if(callBackAdd){
						callBackAdd(this);
					}
				},
				onClick: function() {
					if(callBackClick){
						callBackClick(this);
					}
				}
			});
		}

		function clearAll(){
			toasty.clear(); // Clear all
		}

		function clear(id){
			if(id){
				toasty.clear(id); // Remove a single toast by it's ID
			}
		}

		function newTopic(){

			//var idTopic = topicState.idTopic;
			var nb = $rootScope.arrayNewTopicStateTmp.length;
			var titre = 'Nouveau Topic !';
			var libelle = nb+' nouveau topic !<br />Cliquez-ici pour l\'afficher';

			if(nb>1){
				titre = 'Nouveaux Topics !';
				libelle = nb+' nouveaux topics !<br />Cliquez-ici pour les afficher';
			}
			/*
			if(notificationsContenus.hasOwnProperty(idTopic)){
				ctrl.clear(notificationsContenus[idTopic].id);
			}*/
			if(lastNotifNewTopic){
				ctrl.clear(lastNotifNewTopic.id);
			}

			toasty.info({
				title: titre,
				msg: libelle,
				timeout: false,
				clickToClose: true,
				sound: true,
				shake: false,
				onAdd: function() {
					lastNotifNewTopic = this;
				},
				onClick: function() {

					if($rootScope.isTopicList()) {
						$rootScope.$broadcast('onClickNotifNewTopic');
					}else{
						//$state.go('topics.detail', {'topicId': topicState.idTopic}, {'location': true, 'reload': false, 'inherit': false});
						$state.go('topics.list');
						$rootScope.arrayNewTopicStateTmp = [];
					}
					lastNotifNewTopic = false;
				}
			});

		}

		function newContenu(contenu,topicState) {

			var nb = 1;
			var idTopic = contenu.idTopic;
			var idContenu = contenu.idContenu;


			// Si une notif de contenu est déjà affichée pour ce topic
			// On la supprime
			if(notificationsContenus.hasOwnProperty(idTopic)){

				// Si nouveau contenu
				if(angular.isUndefined(contenu.dateModification) || contenu.dateModification === null) {
					// incrémente le nombre de nouveau contenu
					nb = notificationsContenus[idTopic].nb+1;
					notificationsContenus[idTopic].nb = nb;

					if(notificationsContenus[idTopic].hasOwnProperty('notification')){
						// Clear la précédente notification pour ce contenu
						ctrl.clear(notificationsContenus[idTopic].notification.id);
					}

				}else{
					// Si type message
					if(contenu.typeContenu===1) {
						if (notificationsContenus[idTopic].hasOwnProperty('notificationUpdateMessage')) {
							// Clear la précédente notification pour ce contenu
							ctrl.clear(notificationsContenus[idTopic].notificationUpdateMessage.id);
						}
						// Si type evenement
					}else if(contenu.typeContenu===2){
						if (notificationsContenus[idTopic].hasOwnProperty('notificationUpdateEvenement')) {
							// Clear la précédente notification pour ce contenu
							ctrl.clear(notificationsContenus[idTopic].notificationUpdateEvenement.id);
						}
					}

				}
			}

			var titre = 'Nouveau !',libelle;

			// Si type message
			if(contenu.typeContenu===1){

				// Si page actuel est la page du topic du message envoyé par WS
				if($rootScope.isTopicDetail() && topicState) {

					// Si modification d'un contenu
					if(angular.isDefined(contenu.dateModification) && contenu.dateModification!==null) {
						titre = 'Dernier message modifié pour ce Topic !';
						libelle = 'Cliquez-ici pour l\'afficher';

					// Si nouveau contenu
					}else{
						titre = 'Nouveau message pour ce Topic !';
						libelle = nb + ' nouveau message !<br />Cliquez-ici pour l\'afficher';
						if (nb > 1) {
							titre = 'Nouveaux messages pour ce Topic !';
							libelle = nb + ' nouveaux messages !<br />Cliquez-ici pour les afficher';
						}
					}


				}else{
					// Si modification d'un contenu
					if(angular.isDefined(contenu.dateModification) && contenu.dateModification!==null) {

						titre = 'Message modifié !';

					// Si nouveau contenu
					}else {
						titre = 'Nouveau message !';
						libelle = nb + ' nouveau message !';
						if (nb > 1) {
							titre = 'Nouveaux messages !';
							libelle = nb + ' nouveaux messages !';
						}
					}
				}

			// Si type evenement
			}else if(contenu.typeContenu===2){

				if($rootScope.isTopicDetail() && topicState) {
					titre = 'Nouveau dans ce Topic !';
				}

				if(angular.isUndefined(contenu.dateModification) || contenu.dateModification===null){
					libelle = 'Nouvel événement !';
				}else{
					libelle = 'Evénement modifié !';
				}
			}


			toasty.info({
				title: titre,
				msg: libelle,
				timeout: false,
				clickToClose: true,
				sound: true,
				shake: false,
				onAdd: function() {
					if(!notificationsContenus.hasOwnProperty(idTopic)){
						notificationsContenus[idTopic] = {
							'nb': 1
						};
					}

					// Si nouveau contenu
					if(angular.isUndefined(contenu.dateModification) || contenu.dateModification === null) {
						notificationsContenus[idTopic].notification = this;
					}else{
						// Si type message
						if(contenu.typeContenu===1) {
							notificationsContenus[idTopic].notificationUpdateMessage = this;
							// Si type evenement
						}else if(contenu.typeContenu===2){
							notificationsContenus[idTopic].notificationUpdateEvenement = this;
						}
					}
				},
				onClick: function() {

					var paramsUrl;
					// Si type message
					if(contenu.typeContenu===1){

						if(notificationsContenus[idTopic].hasOwnProperty('notification')){
							// Clear la précédente notification pour ce contenu
							ctrl.clear(notificationsContenus[idTopic].notification.id);
							delete notificationsContenus[idTopic].notification;
						}
						if(notificationsContenus[idTopic].hasOwnProperty('notificationUpdateMessage')){
							// Clear la précédente notification pour ce contenu
							ctrl.clear(notificationsContenus[idTopic].notificationUpdateMessage.id);
							delete notificationsContenus[idTopic].notificationUpdateMessage;
						}

						// Si c'est la page détail
						// Et que le topicState est renseigné
						// Donc c'est la page detail du topic du nouveau message
						if($rootScope.isTopicDetail() && topicState) {
							$rootScope.$broadcast('onClickNotifNewContenu');

							// Sinon on renvoie sur la page détail du topic au clique
						}else{
							paramsUrl = {'topicId': idTopic};
							paramsUrl.hash = 'contenu_' + idContenu;
							$state.go('topics.detail', paramsUrl, {'location': true, 'reload': false, 'inherit': false});
						}
						// Si type evenement
					}else if(contenu.typeContenu===2){

						delete notificationsContenus[idTopic].notificationUpdateEvenement;

						if($rootScope.isTopicDetail() && topicState) {
							$rootScope.$broadcast('onClickNotifEvenement',contenu);
						}else{
							paramsUrl = {'topicId': idTopic};
							paramsUrl.hash = 'Evenement';
							$state.go('topics.detail', paramsUrl, {'location': true, 'reload': false, 'inherit': false});
						}
					}

					// Si plus de notif affiché alors on delete le topic de l'obj des notifs
					if(!notificationsContenus[idTopic].hasOwnProperty('notification') &&
						!notificationsContenus[idTopic].hasOwnProperty('notificationUpdateMessage') &&
						!notificationsContenus[idTopic].hasOwnProperty('notificationUpdateEvenement')){

						delete notificationsContenus[idTopic];
					}
				}
			});
		}

	}
})();
