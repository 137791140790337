(function () {

  'use strict';

  /**
   * @desc Composant LayoutAdminCollab
   * @example <layout-admin-collab></layout-admin-collab>
   */
  angular
    .module('collaboreApp')
    .component('layoutAdminCollab', {
      templateUrl: 'app/admin/layout-admin-collab.component.html',
      controllerAs: 'layoutadmincollabctrl',
      controller: LayoutAdminCollabCtrl
    });

  /** @ngInject */
  function LayoutAdminCollabCtrl($state) {
    var ctrl = this;

    ctrl.selectTab = selectTab;

    ctrl.$onInit = function () {
      selectTab($state.current.name);
    };

    function selectTab(nom) {
      ctrl.tabComptes = false;
      ctrl.tabMails = false;
      ctrl.tabStats = false;
      //ctrl.tabCapfun = false;
      ctrl.tabMailbox = false;
      switch (nom) {
        case 'admin.comptes':
          ctrl.tabComptes = true;
          ctrl.tabActive = 0;
          break;
        case 'admin.mails':
          ctrl.tabMails = true;
          ctrl.tabActive = 1;
          break;
        case 'admin.stats':
          ctrl.tabStats = true;
          ctrl.tabActive = 2;
          break;

        /*
        case 'admin.capfun':
          ctrl.tabCapfun = true;
          ctrl.tabActive = 3;
          break;*/

        case 'admin.mailbox':
          ctrl.tabMailbox = true;
          ctrl.tabActive = 3;
          break;

        default:
          ctrl.tabActive = 0;
          ctrl.tabComptes = true;
      }
    }
  }
})();
