(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.State
   * @description
   * # State
   * Service in the collaboreApp.
   */

  angular.module('collaboreApp').factory('State', StateModel);

  /** @ngInject */
  function StateModel(Main, $q, TopicResource) {

    function State(objetData) {
      Main.apply(this, arguments);

      if(_.isString(objetData) && objetData==='prive'){
        objetData = {
          'idState': 2,
          'idNom': 3,
          'type': 3,
          'nom': 'Privé',
          'isState': true
        };
      }
      this.hydrate(objetData);
    }

    State.prototype = {

      model: 'State',
      isState: true,

      // Id State
      getIdState: function(){
        return this.idState;
      },
      setIdState: function(id){
        this.idState = id;
      },

      // id Nom
      getIdNom: function(){
        return this.idNom;
      },
      setIdNom: function(id){
        this.idNom = id;
      },

      // Nom
      getNom: function(){
        return this.nom;
      },
      setNom: function(nom){
        this.nom = nom;
      },

      // Type
      getType: function(){
        return this.type;
      },
      setType: function(type){
        this.type = type;
      },

      //*********** Type 1 ******************
      isCoupCoeur: function(){
        var retour = false;
        if(this.type===1 && this.idNom===1){retour = true;}
        return retour;
      },

      isArchive: function(){
        var retour = false;
        if(this.type===1 && this.idNom===2){retour = true;}
        return retour;
      },

      isCache: function(){
        var retour = false;
        if(this.type===1 && this.idNom===3){retour = true;}
        return retour;
      },

      isSpam: function(){
        var retour = false;
        if(this.type===1 && this.idNom===4){retour = true;}
        return retour;
      },

      isNormal: function(){
        var retour = false;
        if(this.type===1 && this.idNom===5){retour = true;}
        return retour;
      },

      isArchiveOrCache: function(){
        var retour = false;
        if(this.type===1 && (this.idNom===2 || this.idNom===3)){retour = true;}
        return retour;
      },

      //*********** Type 2 ******************

      isNonLu: function(){
        var retour = false;
        if(this.type===2 && this.idNom===1){retour = true;}
        return retour;
      },

      isLu: function(){
        var retour = false;
        if(this.type===2 && this.idNom===2){retour = true;}
        return retour;
      },

      //*********** Type 3 ******************

      isPublic: function(){
        var retour = false;
        if(this.type===3 && this.idNom===1){retour = true;}
        return retour;
      },

      isProtected: function(){
        var retour = false;
        if(this.type===3 && this.idNom===2){retour = true;}
        return retour;
      },

      isPrive: function(){
        var retour = false;
        if(this.type===3 && this.idNom===3){retour = true;}
        return retour;
      },

      toggleAddRemoveToCoupCoeur: function(idTopicState){
        var deferred = $q.defer();
        var idNom = 1; // Coup de coeur
        // Si déjà coup de coeur
        if(this.isCoupCoeur()){
          idNom = 5; // Normal
        }
        this.updateIdNom(idNom,idTopicState).then(function(state){
          deferred.resolve(state);
        },function(msg){
          deferred.reject(msg);
        });
        return deferred.promise;
      },

      toggleAddRemoveToArchive: function(idTopicState){
        var deferred = $q.defer();
        var idNom = 2; // Archive
        // Si déjà archive
        if(this.isArchive()){
          idNom = 5; // Normal
        }
        this.updateIdNom(idNom,idTopicState).then(function(state){
          // Si en archive après modification
          if(state.isArchive()){
            // Lecture du topic
            // TODO : Arriver à lire le topic
            //ts.getTopic().lire();
          }
          deferred.resolve(state);
        },function(msg){
          deferred.reject(msg);
        });
        return deferred.promise;
      },

      toggleAddRemoveToCache: function(idTopicState){
        var deferred = $q.defer();
        var idNom = 3; // Caché
        // Si déjà archive
        if(this.isCache()){
          idNom = 5; // Normal
        }
        this.updateIdNom(idNom,idTopicState).then(function(state){
          deferred.resolve(state);
        },function(msg){
          deferred.reject(msg);
        });
        return deferred.promise;
      },

      /**
       * Change l'état d'un topic
       * @param {Object} objParams - Objet des paramètres à envoyer en post
       * @returns {Promesse}
       */
      updateIdNom: function(idNom,idTopicState){
        var scope = this;

        var deferred = $q.defer();

        var topicAjax = new TopicResource();
        topicAjax.idTopicState = idTopicState;
        topicAjax.idNom = idNom;
        topicAjax.$updateState(function(json){
          var retour = false;
          if(json.nb!==0){

            if(json.TopicStates[0]) {
              // Met à jour l'idnom du state
              scope.setIdNom(json.TopicStates[0].state.idNom);
              //scope.setState(new State(json.TopicStates[0].state));
              retour = scope;
            }
          }

          deferred.resolve(retour);
        },function(error){
          var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
          deferred.reject('Problème avec State.updateIdNom(), '+msgErreur);
        });
        return deferred.promise;
      }


    };

    State.NORMAL = {
      'idState': 1,
      'idNom': 5,
      'nom': 'Normal',
      'type': 1
    };

    State.PRIVE = {
      'idState': 2,
      'idNom': 3,
      'nom': 'Privé',
      'type': 3
    };

    State.CACHE = {
      'idState': 3,
      'idNom': 3,
      'nom': 'Caché',
      'type': 1
    };

    State.PUBLIC = {
      'idState': 4,
      'idNom': 1,
      'nom': 'Public',
      'type': 3
    };

    State.COUP_COEUR = {
      'idState': 5,
      'idNom': 1,
      'nom': 'Coup de Coeur',
      'type': 1
    };

    State.AGENCE = {
      'idState': 6,
      'idNom': 2,
      'nom': 'Protected',
      'type': 3
    };

    State.PAS_LU = {
      'idState': 7,
      'idNom': 1,
      'nom': 'Pas lu',
      'type': 2
    };

    State.ARCHIVE = {
      'idState': 8,
      'idNom': 2,
      'nom': 'Archivé',
      'type': 1
    };

    State.LU = {
      'idState': 9,
      'idNom': 2,
      'nom': 'Lu',
      'type': 2
    };

    State.SPAM = {
      'idState': 10,
      'idNom': 4,
      'nom': 'Spam',
      'type': 1
    };

    /*
     State.normal = function(){
     return {
     'idState': 1,
     'idNom': 5,
     'nom': 'Normal',
     'type': 1
     };
     };

     State.prive = function(){
     return {
     'idState': 2,
     'idNom': 3,
     'nom': 'Privé',
     'type': 3
     };
     };
     */

    angular.extend(State.prototype, Main.prototype);

    return State;

  }
})();
