(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.Reporting
   * @description
   * # Reporting
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('Reporting', ReportingModel);

  /** @ngInject */
  function ReportingModel(Main, UtilsService) {

    function Reporting(data) {
      Main.call(this, data);
    }

    Reporting.prototype = {

      model: 'Reporting',
      isReporting: true,

      // Titre topic
      getTitre: function(){
        return this.titre;
      },
      setTitre: function(titre){
        this.titre = titre;
      },

      // Id Topic
      getIdTopic: function(){
        return this.idTopic;
      },
      setIdTopic: function(id){
        this.idTopic = id;
      },

      // idEvenement
      getIdEvenement: function(){
        return this.idEvenement;
      },
      setIdEvenement: function(id){
        this.idEvenement = id;
      },

      // expediteur
      getExpediteur: function(){
        return this.expediteur;
      },
      setExpediteur: function(createur){
        if(_.isObject(createur) && !createur.isModel){
          var Groupe = Main.$injector.get('Groupe');
          createur = new Groupe(createur);
          Groupe = null;
        }
        this.expediteur = createur;
      },

      // nbJour
      getNbJour: function(){
        return this.nbJour;
      },
      setNbJour: function(val){
        this.nbJour = val;
      },

      // tags
      getTags: function(){
        return this.tags;
      },
      setTags: function(arrayTags){
        var tags = [];
        if(_.isArray(arrayTags) && arrayTags.length) {
          var Tag = Main.$injector.get('Tag');
          for(var i = 0; i < arrayTags.length; i++) {
            if(_.isObject(arrayTags[i])) {
              if(!arrayTags[i].isModel) arrayTags[i] = new Tag(arrayTags[i]);
              tags.push(arrayTags[i]);
            }
          }
          Tag = null;
        }
        this.tags = tags;
      },

      // tagsType
      getTagsType: function(){
        return this.tagsType;
      },
      setTagsType: function(arrayTags){
        var tagsType = [];
        if(_.isArray(arrayTags) && arrayTags.length) {
          var Tag = Main.$injector.get('Tag');
          for(var i = 0; i < arrayTags.length; i++) {
            if(_.isObject(arrayTags[i])) {
              if(!arrayTags[i].isModel) arrayTags[i] = new Tag(arrayTags[i]);
              tagsType.push(arrayTags[i]);
            }
          }
          Tag = null;
        }
        this.tagsType = tagsType;
      },

      // Date de création
      getDateCreation: function(format){
        return this.getDateCrea(format);
      },
      getDateCrea: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateCrea, format);
        }
        return this.dateCrea;
      },
      setDateCrea: function(date){
        this.dateCrea = date;
      },

      // Date de cloture
      getDateCloture: function(format){
        return this.getDateClot(format);
      },
      getDateClot: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateClot, format);
        }
        return this.dateClot;
      },
      setDateClot: function(date){
        this.dateClot = date;
      },

      // Date d'echeance
      getDateEcheance: function(format){
        return this.getDateEche(format);
      },
      getDateEche: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateEche, format);
        }
        return this.dateEche;
      },
      setDateEche: function(date){
        this.dateEche = date;
      },

      // Objet
      getObjet: function () {
        return this.objet;
      },
      setObjet: function (objet) {
        this.objet = objet;
      },

      // categorieTopic
      getCategorieTopic: function(){
        return this.categorieTopic;
      },
      setCategorieTopic: function(categorieTopic){
        if(_.isObject(categorieTopic)){
          if (!categorieTopic.isModel) {
            var CategorieTopic = Main.$injector.get('CategorieTopic');
            categorieTopic = new CategorieTopic(categorieTopic);
            CategorieTopic = null;
          }
          this.categorieTopic = categorieTopic;
        }
      },

      // topicState
      getTopicState: function () {
        return this.topicState;
      },
      setTopicState: function (val) {
        if(_.isObject(val) && !val.isModel){
          var TopicStates = Main.$injector.get('TopicStates');
          val = new TopicStates(val);
          TopicStates = null;
        }
        this.topicState = val;
      },

      // nbDevisDemandes
      getNbDevisDemandes: function(){
        return this.nbDevisDemandes;
      },
      setNbDevisDemandes: function(val){
        this.nbDevisDemandes = val;
      },

      // nbDevisReponse
      getNbDevisReponse: function(){
        return this.nbDevisReponse;
      },
      setNbDevisReponse: function(val){
        this.nbDevisReponse = val;
      },

      // nbDevisValide
      getNbDevisValide: function(){
        return this.nbDevisValide;
      },
      setNbDevisValide: function(val){
        this.nbDevisValide = val;
      },

      // nbDevisRefuse
      getNbDevisRefuse: function(){
        return this.nbDevisRefuse;
      },
      setNbDevisRefuse: function(val){
        this.nbDevisRefuse = val;
      },

      // nbDevisSigne
      getNbDevisSigne: function(){
        return this.nbDevisSigne;
      },
      setNbDevisSigne: function(val){
        this.nbDevisSigne = val;
      },

      // nbFacture
      getNbFacture: function(){
        return this.nbFacture;
      },
      setNbFacture: function(val){
        this.nbFacture = val;
      },

      // nbSinistre
      getNbSinistre: function(){
        return this.nbSinistre;
      },
      setNbSinistre: function(val){
        this.nbSinistre = val;
      },

      // nbDevisIntervention
      getNbDevisIntervention: function(){
        return this.nbDevisIntervention;
      },
      setNbDevisIntervention: function(val){
        this.nbDevisIntervention = val;
      },

      // nbDevisInterventionFermee
      getNbDevisInterventionFermee: function(){
        return this.nbDevisInterventionFermee;
      },
      setNbDevisInterventionFermee: function(val){
        this.nbDevisInterventionFermee = val;
      },

      // nbDevisDemandesFermee
      getNbDevisDemandesFermee: function(){
        return this.nbDevisDemandesFermee;
      },
      setNbDevisDemandesFermee: function(val){
        this.nbDevisDemandesFermee = val;
      },

      // nbDemandeContrat
      getNbDemandeContrat: function(){
        return this.nbDemandeContrat;
      },
      setNbDemandeContrat: function(val){
        this.nbDemandeContrat = val;
      },

      // nbContratNonRecu
      getNbContratNonRecu: function(){
        return this.nbContratNonRecu;
      },
      setNbContratNonRecu: function(val){
        this.nbContratNonRecu = val;
      },

      // tauxReponse
      getTauxReponse: function(){
        return this.tauxReponse;
      },
      setTauxReponse: function(val){
        this.tauxReponse = val;
      },

      // tauxValide
      getTauxValide: function(){
        return this.tauxValide;
      },
      setTauxValide: function(val){
        this.tauxValide = val;
      },

      // tauxSigne
      getTauxSigne: function(){
        return this.tauxSigne;
      },
      setTauxSigne: function(val){
        this.tauxSigne = val;
      },

    };

    angular.extend(Reporting.prototype, Main.prototype);

    return Reporting;

  }
})();
