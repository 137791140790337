(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.Digicode
   * @description
   * Service pour faire appel au servlets du ICSCloud par le biais de Collabore. Migrer les méthodes qui appel ICSCloud dans ce service si possible
   */

  angular
      .module('collaboreApp')
      .service('ICSCloudService',
          ICSCloudServiceController
      );

  /** @ngInject */
  function ICSCloudServiceController(MainService, $q, Digicode, IcsCloudResource, ModalsService, ErreurCollabService) {
    var ctrl = this;

    ctrl.parseHierarchieModele = parseHierarchieModele;

    ctrl.getPlanModele = getPlanModele;
    ctrl.getDonneesImmeuble = getDonneesImmeuble;
    ctrl.getDigicode = getDigicode; // Permet de récupérer les digicodes d'un immeuble

    function getPlanModele(params){
      var deferred = $q.defer();

      try {

        if(!_.isObject(params)) throw new Error("Le paramètre doit être un objet");
        if(_.isNil(params.numImmeuble)) throw new Error("Il manque le numéro d'immeuble");
        if(_.isNil(params.portefeuille)) throw new Error("Il manque le portefeuille");

        var paramsSearch = {
          action: 'rechercheModele',
          numImmeuble: params.numImmeuble,
          idPortefeuille: (_.isObject(params.portefeuille)) ? params.portefeuille.idPortefeuille : params.portefeuille
        };

        if(!_.isNil(params.global)) paramsSearch.global = params.global;
        else{
          if(!_.isNil(params.cccccc)) paramsSearch.cccccc = params.cccccc;
          if(!_.isNil(params.libelle)) paramsSearch.libelle = params.libelle;
        }

        if(!_.isNil(params.codeMetier)) paramsSearch.codeMetier = params.codeMetier;
        if(!_.isNil(params.numeroFournisseur)) paramsSearch.numeroFournisseur = params.numeroFournisseur;
        if(!_.isNil(params.refContrat)) paramsSearch.refContrat = params.refContrat;

        IcsCloudResource
          .get(paramsSearch)
          .$promise
          .then(function onSuccess(json){
            var listModele = MainService.convertJsonToModel(json, 'result', 'Modele', deferred);
            deferred.resolve((_.isArray(listModele)) ? listModele[0] : null);
          })
          .catch(function onError(err) {
            deferred.reject(err);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Récupère les données de l'immeuble
     * @param numImmeuble
     * @param idPortefeuille
     * @returns {*}
     */
    function getDonneesImmeuble(numImmeuble, idPortefeuille){
      var deferred = $q.defer();
      var params = {action: 'rechercheInfoImmeuble', numImmeuble: numImmeuble, idPortefeuille: idPortefeuille};
      try {
        IcsCloudResource
          .get(params)
          .$promise
          .then(function onSuccess(json){
            deferred.resolve(MainService.convertJsonToModel(json, 'result', 'InfoImmeuble', deferred));
          })
          .catch(function onError(err) {
            deferred.reject(err);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Permet de récupérer les digicodes d'un immeuble
     * @param idPortefeuille
     * @param numeroImmeuble
     * @returns {Promise}
     */
    function getDigicode(idPortefeuille, numeroImmeuble){
      var deferred = $q.defer();

      try {
        if(!idPortefeuille) {throw new Error('Il manque l\'idPortefeuille !');}
        if(!numeroImmeuble) {throw new Error('Il manque le numéro d\'immeuble !');}

        var params = {
          idPortefeuille: idPortefeuille,
          iiii: numeroImmeuble
        };

        IcsCloudResource.getDigicode(params, function(json) {
          deferred.resolve(MainService.convertJsonToModel(json, 'WSDigicodes', Digicode));
        },function(error){
          ErreurCollabService.erreurServlet(error,'Erreur avec ICSCloudServiceController::getDigicode()',deferred);
        });
      }
      catch (err) {
        ErreurCollabService.logWarning(err.message);
        deferred.resolve([]);
      }

      return deferred.promise;
    }

    function parseHierarchieModele(planModele){
      var listeCompte = [];
      if(_.isObject(planModele)){
        if(planModele.hasListBatiment()){
          planModele.getListBatiment().map(function(batiment){
            parseListCompte(listeCompte, batiment);
            if(batiment.hasListRegroupement()){
              batiment.getListRegroupement().map(function(regroupement){
                parseListCompte(listeCompte, batiment, regroupement);
                if(regroupement.hasListRegroupement()){
                  regroupement.getListRegroupement().map(function(sousRegroupement){
                    parseListCompte(listeCompte, batiment, regroupement, sousRegroupement);
                  });
                }
              });
            }
          });
        }
      }
      return listeCompte;
    }

    function parseListCompte(
      listeCompte,
      batiment,
      regroupement,
      sousRegroupement
    ) {
      if (_.isObject(batiment)) {
        // Si seulement le batiment
        if (_.isNil(regroupement)) {
          if (batiment.hasListCompte()) {
            batiment.getListCompte().map(function (compte) {
              var objCompte = angular.copy(compte);
              objCompte.libelleBatiment = batiment.getLib();
              objCompte.batiment = angular.copy(batiment);
              delete objCompte.batiment.listRegroupement;
              delete objCompte.batiment.listCompte;
              listeCompte.push(objCompte);
            });
          }
        } else {
          if (_.isNil(sousRegroupement)) {
            if (regroupement.hasListCompte()) {
              regroupement.getListCompte().map(function (compte) {
                var objCompte = angular.copy(compte);
                objCompte.libelleRegroupement = regroupement.getLib();
                objCompte.libelleBatiment = batiment.getLib();
                objCompte.setClerep(regroupement.getClerep());
                objCompte.regroupement = angular.copy(regroupement);
                objCompte.batiment = batiment;
                delete objCompte.regroupement.listRegroupement;
                delete objCompte.regroupement.listCompte;
                delete objCompte.batiment.listRegroupement;
                delete objCompte.batiment.listCompte;
                listeCompte.push(objCompte);
              });
            }
          } else {
            if (sousRegroupement.hasListCompte()) {
              sousRegroupement.getListCompte().map(function (compte) {
                var objCompte = angular.copy(compte);
                objCompte.libelleSousRegroupement = sousRegroupement.getLib();
                objCompte.libelleRegroupement = regroupement.getLib();
                objCompte.libelleBatiment = batiment.getLib();
                objCompte.setClerep(regroupement.getClerep());
                objCompte.sousRegroupement = angular.copy(sousRegroupement);
                objCompte.regroupement = angular.copy(regroupement);
                objCompte.batiment = angular.copy(batiment);
                delete objCompte.sousRegroupement.listCompte;
                delete objCompte.regroupement.listRegroupement;
                delete objCompte.regroupement.listCompte;
                delete objCompte.batiment.listRegroupement;
                delete objCompte.batiment.listCompte;
                listeCompte.push(objCompte);
              });
            }
          }
        }
      }
      return listeCompte;
    }
  }
})();
