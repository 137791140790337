(function() {

    'use strict';

    /**
     * @desc Composant de recherche ICSCloud
     * @example <recherche-ics></recherche-ics>
     */
    angular
        .module('collaboreApp')
        .component('rechercheIcs',{
            templateUrl: 'app/components/recherche-ics/recherche-ics.component.html',
            controllerAs: 'rechercheicsctrl',
            bindings: {
                search: '<'
            },
            controller: RechercheIcsCtrl
        });

    /** @ngInject */
    function RechercheIcsCtrl(COLLAB_CONF, $q, $rootScope, $scope, AnnuaireService, UtilsService){
        var _this = this;

        _this.listeIdPortefeuille = [];
        _this.filtrePortefeuilles = {};        // Objet contenant entre autres le nombre de resultats par portefeuille

        _this.synchrone = true;
        _this.onEmitFromListePersonnaliteIcsComponent = onEmitFromListePersonnaliteIcsComponent;
        _this.onEmitFromListeImmeubleIcsComponent = onEmitFromListeImmeubleIcsComponent;

        _this.$onInit = function() {

            var listePortefeuilles = UtilsService.getCurrentUser().getListePortefeuille();
            if (listePortefeuilles.length) {
                _this.listeIdPortefeuille = listePortefeuilles.map(function(port){
                    var idPortefeuille = port.idPortefeuille;
                    if (!_this.filtrePortefeuilles.hasOwnProperty(idPortefeuille)) {
                        _this.filtrePortefeuilles[idPortefeuille] = {
                            idPortefeuille: idPortefeuille,
                            nom: port.nom,
                            display: true,
                            nb: 0
                        };
                    }
                    return idPortefeuille;
                });
            }
        };

        /**
         * Lorsque le composant liste-personnalite-ics emit une info
         * @param obj
         */
        function onEmitFromListePersonnaliteIcsComponent(obj){
            try{
                if (!obj || !_.isObject(obj)) {
                    throw new Error('Il manque l\'objet "obj" !');
                }
                if(obj.action === 'finishedCall') {
                    //_this.showListeImmeuble = true;
                    $scope.$broadcast('initListeImmeubleIcs');
                }

                /*
                if(obj.action === 'createEvenement') {
                    _this.showListTopic = false;
                    _this.showListTopicElasticsearch = true;
                    $rootScope.current.filtreTopics.rechercheElasticsearch.isActive = true;
                }*/
            }
            catch(err){
                console.log('[RechercheIcsCtrl.onEmitFromListePersonnaliteIcsComponent] Erreur : '+err.message);
            }
        }

        /**
         * Lorsque le composant liste-personnalite-ics emit une info
         * @param obj
         */
        function onEmitFromListeImmeubleIcsComponent(obj){
            try{
                if (!obj || !_.isObject(obj)) {
                    throw new Error('Il manque l\'objet "obj" !');
                }
                if(obj.action === 'finishedCall') {
                    $scope.$broadcast('initListeFournisseurIcs');
                    //_this.showListefournisseur = true;
                }
            }
            catch(err){
                console.log('[RechercheIcsCtrl.onEmitFromListePersonnaliteIcsComponent] Erreur : '+err.message);
            }
        }
    }
})();
