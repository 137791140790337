(function() {

    'use strict';

    /**
     * @desc Composant pour gerer mon compte
     * @example <mon-compte></mon-compte>
     */
    angular
        .module('collaboreApp')
        .component('monCompte',{
            templateUrl: 'app/parametrage/mon-compte/mon-compte.html',
            controllerAs: 'moncomptectrl',
            controller: MonCompteCtrl
        });


    /*@ngInject*/
    function MonCompteCtrl(COLLAB_CONF, sweet, $rootScope, ConfigService, ParamsService){
        var ctrl = this;

        ctrl.onSubmitSmtp = onSubmitSmtp;
        ctrl.passwordIdentique = passwordIdentique;
        ctrl.getClassNewPwd = getClassNewPwd;
        ctrl.getClassConfNewPwd = getClassConfNewPwd;
        ctrl.sendNewPassword = sendNewPassword;

        ctrl.userGroupe = $rootScope.current.userGroupe;

        ctrl.smtp = {
            'loading': {
                'show': false,
                'texte': COLLAB_CONF.MINI_LOADING_RING
            },
            'params': {
                'host': '',
                'port': 587,
                'tls': false,
                'auth': true,
                'userSmtp': '',
                'mdp': ''
            },
            'success': false,
            'erreur': {
                'show': false,
                'texte': ''
            }
        };

        ctrl.$onInit = function(){
            if(ctrl.userGroupe.getHost()){
                ctrl.smtp.params.host = ctrl.userGroupe.getHost();
            }

            if(angular.isDefined(ctrl.userGroupe.getPort())){
                ctrl.smtp.params.port = ctrl.userGroupe.getPort();
            }

            if(angular.isDefined(ctrl.userGroupe.getUserSmtp())){
                ctrl.smtp.params.userSmtp = ctrl.userGroupe.getUserSmtp();
            }

            if(angular.isDefined(ctrl.userGroupe.getAuth())){
                if(ctrl.userGroupe.getAuth()==='true'){
                    ctrl.smtp.params.auth = true;
                }
                else if(ctrl.userGroupe.getAuth()==='false'){
                    ctrl.smtp.params.auth = false;
                }
                else{
                    ctrl.smtp.params.auth = ctrl.userGroupe.getAuth();
                }
            }

            if(angular.isDefined(ctrl.userGroupe.getTls())){
                if(ctrl.userGroupe.getTls()==='true'){
                    ctrl.smtp.params.tls = true;
                }
                else if(ctrl.userGroupe.getTls()==='false'){
                    ctrl.smtp.params.tls = false;
                }
                else
                    {
                    ctrl.smtp.params.tls = ctrl.userGroupe.getTls();
                }
            }
        };

        ctrl.$onDestroy = function(){
            ParamsService.destroy();
        };

        /**
         * Lorsque le formulaire du SMTP est submit
         * @param formSmtp - Model du formulaire pour tester le $valid
         */
         function onSubmitSmtp(formSmtp){

            if(!formSmtp.$valid){
                return;
            }
            ctrl.smtp.loading.show = true;
            ctrl.smtp.erreur.show = false;
            ctrl.smtp.success = false;

            //console.log(ctrl.smtp.params);
            ParamsService.addSmtp(ctrl.smtp.params).then(function(){
                ctrl.smtp.success = true;

                ctrl.userGroupe.setHost(ctrl.smtp.params.host);
                ctrl.userGroupe.setUserSmtp(ctrl.smtp.params.userSmtp);
                ctrl.userGroupe.setPort(ctrl.smtp.params.port);
                ctrl.userGroupe.setAuth(ctrl.smtp.params.auth);
                ctrl.userGroupe.setTls(ctrl.smtp.params.tls);
                //ctrl.smtp.params.mdp = '';

            },function(msg){
                ctrl.smtp.erreur.show = true;
                ctrl.smtp.erreur.texte = msg;
            }).finally(function(){
                ctrl.smtp.loading.show = false;
            });

        }

        /**
         * Permet de reset le formulaire
         */
        var cleanForm = function(){
            ctrl.curPwd = '';
            ctrl.newPwd = '';
            ctrl.confirmPwd = '';
        };

        /**
         * Test si le nouveau password et la confirmation sont identiques
         * @returns {*}
         */
        function passwordIdentique(){
            //console.log('newPassword',formPwd.newPassword.$modelValue);
            //console.log('confirmNewPassword',formPwd.confirmNewPassword.$modelValue);
            var retour = null;
            if(angular.isDefined(ctrl.newPwd) && angular.isDefined(ctrl.confirmPwd)){
                retour = (ctrl.newPwd===ctrl.confirmPwd);
            }

            return retour;
        }

        /**
         * Class de l'input du nouveau mot de passe
         * @param formPwd
         * @returns {string}
         */
        function getClassNewPwd(formPwd){
            var classInput = 'input__label--hoshi-color-default';

            if(passwordIdentique() && !formPwd.newPassword.$error.minlength){
                classInput = 'input__label--hoshi-color-success';
            }
            else{
                if(passwordIdentique()===false || formPwd.newPassword.$error.minlength){
                    classInput = 'input__label--hoshi-color-danger';
                }
            }

            return classInput;
        }

        /**
         * Class de l'input de la confirmation du nouveau mot de passe
         * @returns {string}
         */
        function getClassConfNewPwd(){
            var classInput = 'input__label--hoshi-color-default';

            if(passwordIdentique()){
                classInput = 'input__label--hoshi-color-success';
            }else{
                if(passwordIdentique()===false){
                    classInput = 'input__label--hoshi-color-danger';
                }
            }

            return classInput;
        }

        function sendNewPassword() {

            ctrl.loadingPassword = COLLAB_CONF.LOADING;

            ParamsService.sendNewPwd(ctrl.curPwd, ctrl.newPwd).then(function(password){
                if (password.Resultats[0].success){
                    sweet.show({
                        title: 'Modification du mot de passe',
                        text: 'Mot de passe validé !',
                        type: 'success',
                        html: true
                    });
                    cleanForm();
                }
            }, function(){
                sweet.show({
                    title: 'Modification du mot de passe',
                    text: 'Mot de passe actuel saisi incorrect !',
                    type: 'error',
                    html: false
                });
                ctrl.curPwd = '';

            }).finally(function(){
                ctrl.loadingPassword = false;
            });


        }

    }
})();
