(function () {
    'use strict';

    angular
        .module('collaboreApp')
        .component('groupesLigneCheminGedTopic', {
            templateUrl: 'app/topics/send-ged/groupes-ligne-chemin-ged-topic.component.html',
            controller: GroupesLigneCheminGedTopicController,
            controllerAs: 'groupeslignechemingedtopicctrl',
            bindings: {
                dossierGroupe: '<',
                onChecked: '&'
            }
        });

    /** @ngInject */
    function GroupesLigneCheminGedTopicController($scope) {
        var ctrl = this;

        /********** Global **********/
        ctrl.loading = false;
        ctrl.onChangeCheckbox = onChangeCheckbox;
        ctrl.onCheckedCheminGed = onCheckedCheminGed;

        /********** Global **********/


        ctrl.$onInit = function () {

            /*            console.log(ctrl.dossierGroupe.nom);
                        console.log(ctrl.setTheGedChemin); */
        };
        ctrl.$onChanges = function (changes) {
            /*            console.log(changes); */
        };
        ctrl.$onDestroy = function () {
        };


        /**
         * Lorsqu'on coche la checkbox
         */
        function onChangeCheckbox(){
            if(ctrl.dossierGroupe.checked) {
                emitChecked({
                        id: ctrl.dossierGroupe.id,
                        cheminGed: ctrl.dossierGroupe.chemin
                    }
                );
            }
            else {
                emitChecked({
                        id: false,
                        cheminGed: null
                    }
                );
            }

        }

        /**
         * Lorsqu'un enfant est coché
         * @param objCheminFils
         */
        function onCheckedCheminGed(objCheminFils){
            emitChecked(objCheminFils);
        }


        /**
         * Permet de notifier le parent
         * @param objet
         */
        function emitChecked(objet){
            ctrl.onChecked(
                {
                    obj: objet
                }
            );
        }
    }
})();

