(function() {

  'use strict';

  /**
   * @desc Componsant pour afficher l'état d'une demande sous forme de label
   * @example <labels-etat-demande></labels-etat-demande>
   */

  angular
    .module('collaboreApp')
    .component('labelsEtatDemande',{
      templateUrl: 'app/topics/components/labels-etat-demande/labels-etat-demande.component.html',
      bindings : {
        contenu: '<',                   // contenu
        type: '<'
      },
      controllerAs: 'labelsetatdemandectrl',
      controller: LabelsEtatDemandeController
    });

  /*@ngInject*/
  function LabelsEtatDemandeController(){
    var _this = this;
    _this.$onInit = function() {
    };

    /**
     * Lors de changements depuis le parent
     * @param changes
     */
    _this.$onChanges = function (changes) {
    };

    _this.$onDestroy = function(){
    };


  }
})();
