(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.UserService
   * @description
   * # UserService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('UserService', UserService);

  /** @ngInject */
  function UserService($injector, $rootScope, COLLAB_CONF, COLLAB_VALUES, $timeout, $q, UserResource, AnnuaireService, DocumentService, ConfigService, WebsocketServices, Groupe, angularLoad, sweet, FichesService, ModuleService, $uibModalStack, UtilsService, SocketNotificationService, LoginResource, ModalsService) {

    var vm = this;
    // Initialisation de l'utilisateur à false
    var objectUser = false;
    var currentSweet = false;
    var instanceLivetchat = false;
    $rootScope.isConnected = false;

    $rootScope.$on('socket:error', function (ev, data) {
      console.log("socket:error");
      console.log("ev", ev);
      console.log("data", data);
    });

    /**
     * Objet "Groupe"
     * @param val
     */
    this.initGroupe = function(val){
      if(angular.isObject(val)) {
        if(!objectUser) {
          objectUser = {};
        }
        if(!val.isModel) {
          val = new Groupe(val);
        }
        objectUser.groupe = val;
        $rootScope.hasPortefeuille = objectUser.groupe.hasListePortefeuille();
        AnnuaireService.setGroupe(objectUser.groupe);
      }
      else if(val == false) {
        objectUser = false;
        $rootScope.hasPortefeuille = false;

      }

    };

    /**
     * Recupère l'objet "Groupe" de l'user avec toute les méthode en prototype
     * @returns {params.groupe|*}
     */
    this.getGroupe = function () {
      return objectUser.groupe;
    };

    /**
     * Permet de recupérer la liste des portefeuille
     * @param refresh
     * @returns {Promise}
     */
    this.getPortefeuilles = function (refresh) {

      var deferred = $q.defer();

      if (refresh) {
        UserResource.getPortefeuilles(function (json) {
          if (json.nb !== '0') {
            $rootScope.hasPortefeuille = true;
            // TODO : Parcour les portefeuilles et créer une instance Portefeuille de chaque portefeuille. Le retour JSON n'est pas complet pour faire ça pour le moment
            deferred.resolve(json.Portefeuilles);
          } else {
            $rootScope.hasPortefeuille = false;
            deferred.reject(json);
          }
        });
      }
      else {
        deferred.resolve(this.getGroupe().getListePortefeuille());
      }

      return deferred.promise;

    };

    /**
     * Check si objectUser est à false
     * @param void
     * @returns {Boolean}
     */
    this.isConnected = function () {
      return !!objectUser;
    };

    this.getFiche = function () {
      //var ficheCollab = AnnuaireService.getFicheForGroupe(objectUser.groupe);
      var ficheCollab = this.getGroupe().getUser().getFichePerso();
      return ficheCollab;
    };

    this.loginLiveTchat = function (dataChat) {
      if (COLLAB_CONF.ENABLE_LIVETCHAT) {

        angularLoad.loadScript(COLLAB_VALUES.CONF_URL.URL_COLLAB_NODEJS + '/js/scriptLiveTchat.js').then(function () {
          //console.log('Fini');
          if (('LiveTchat' in window)) {
            instanceLivetchat = new LiveTchat(dataChat);
            //LiveTchat.connectAndStart(dataChat);
          }
        });
      }
    };

    /**
     * Permet de s'authentifier
     * @param {String} utilisateur - Login de l'utilisateur ex: @michel
     * @param {String} mdp - Mot de passe de l'utilisateur
     * @returns {Object} (Promesse avec retour de l'objet si success)
     */
    this.connexion = function (varParams, forceManuelLogin) {

      var deferred = $q.defer();

      //$rootScope.mainLoadingCollab = true;
      //$rootScope.mainLoadingCollabText = 'Connexion Collab';

      if(!forceManuelLogin && COLLAB_CONF.AUTH_KERBEROS && _.isObject(varParams) && !varParams.extranet) {

        vm.connexionKerberos(varParams)
          .then(function(groupeObj){
            deferred.resolve(groupeObj);
          })
          .catch(function(msg){
            deferred.reject(msg);
          });
      }
      else {
        var config;

        // Création des paramètres pour la servlet de connexion
        if (varParams.login && varParams.mdp) {
          config = {
            nom: varParams.login,
            dieze: varParams.dieze,
            mdp: varParams.mdp
          };
        }
        else if (varParams.login && varParams.hash) {
          config = {
            nom: varParams.login,
            dieze: varParams.diese,
            hash: varParams.hash
          };
        }
        else if (varParams.token) {
          config = {
            token: varParams.token
          };
        }

        if(varParams.extranet && _.isObject(config)) config.extranet = varParams.extranet;

        // Soumet le login et le mot de passe à laservlet de connecion
        LoginResource.connexionCollab(config, function (json) {
            identificationSuccess(deferred, json, config);
          },
          // Erreur
          function (error) {
            identificationError(deferred, error);
          });
      }
      return deferred.promise;
    };

    this.connexionKerberos = function (varParams) {
      var deferred = $q.defer();
      var resource = LoginResource.connexionKerberos;

      if(_.isObject(varParams) && varParams.token) resource = LoginResource.connexionKerberosGet;

      resource(varParams, function(json){

          if(json.success) {
            if(_.isArray(json.result) && json.result.length) {
              $rootScope.mainLoadingCollab = false;
              ModalsService
                .modalComponent('chooseSociete', {users: json.result})
                .then(function(jsonGroupe){
                  deferred.resolve(jsonGroupe);
                })
                .catch(function(msg){
                  if(msg === 'backdrop click' || msg === 'cancel') msg = 'cancel';
                  deferred.reject(msg);
                });
            }
            else if(_.isObject(json.groupe)){

              var config = {
                token: json.groupe.token
              };
              identificationSuccess(deferred, json, config);

              ConfigService.setUserLogin(false);
              ConfigService.setUserPass(false);
              ConfigService.setUserDiese('');
            }
          }
          else identificationError(deferred, json.message);

        },
        // Erreur
        function (error) {
          identificationError(deferred, error);
        });
      return deferred.promise;
    }

    this.logout = function logout(logoutOnelogin){
      var deferred = $q.defer();
      LoginResource.logout(
        {logoutOnelogin: logoutOnelogin},
        function(json){
          deferred.resolve(json);
        },
        // Erreur
        function (error) {
          deferred.reject(error);
        });
      return deferred.promise;
    };

    /**
     * Permet d'identifier l'user
     * @param deferred
     * @param json
     * @param config
     */
    function identificationSuccess(deferred, json, config){
      // Si erreur
      if (!json.success) {
        $rootScope.mainLoadingCollab = false;
        var msg = 'Problème d\'authentification !';
        if(json.message) msg = json.message;

        $rootScope.$broadcast('erreur401', msg);
        deferred.reject(msg);
        return;
      }

      var groupeObj = new Groupe(json.groupe);
      var token = groupeObj.token;

      vm.initServices(json);

      // Set l'user dans le service
      vm.initGroupe(groupeObj);

      // Set le token dans le rootScope
      $rootScope.token = token;

      // Si l'authentification n'est pas pour l'extranet fournisseur alors on ne change pas les infos de l'user dans le local storage
      if(_.isObject(config) && (!config.extranet || config.authByHash)) ConfigService.setUserToken(token);

      $rootScope.isConnected = true;
      $rootScope.current.userGroupe = groupeObj;

      $rootScope.mailboxInfos = {
        nbMail: 0,
        nbTrashedMail: 0,
        nbNewMail: 0
      };

      if(groupeObj && groupeObj.groupeInformations && !_.isNil(groupeObj.groupeInformations.nbMailNonLu) && groupeObj.groupeInformations.nbMailNonLu > 0){
        $rootScope.mailboxInfos.nbNewMail = groupeObj.groupeInformations.nbMailNonLu;
      }

      SocketNotificationService.connectSocketIO(groupeObj).finally(function(){

        // Si la page est bien dans le context collab
        if (!$rootScope.notInCollab) {
          //vm.loginLiveTchat(config);

          var fiche = AnnuaireService.getFicheCurrentUser(),
            telPortableExist = false;

          angular.forEach(fiche.telephones, function (renseignement) {
            // Si le renseignement est un numéro de portable
            if (renseignement.getReference().isPortable()) {
              telPortableExist = true;
            }
          });

          // Si pas de téléphone portable
          if (!telPortableExist) vm.showPopupRenseignementTelephone(fiche);
        }

        //$rootScope.mainLoadingCollab = false;

        vm.initModules();

        $rootScope.isCapfun = UtilsService.isCapfun();
        $rootScope.isCapfunDiese = UtilsService.isCapfunDiese();
        $rootScope.isVaguesOceanesDiese = UtilsService.isVaguesOceanesDiese();
        $rootScope.isClicochicDiese = UtilsService.isClicochicDiese();

        $rootScope.isSiegeCapfunOrCapfunAdmin = (groupeObj.isDirectionCapfun() || (groupeObj.isCapfun() && groupeObj.isAdmin(true)));

        $rootScope.isICS = UtilsService.isICS();
        $rootScope.isIcsOrCapfun = UtilsService.isIcsOrCapfun();
        $rootScope.logoCollab = UtilsService.getLogoCollab();

        $rootScope.displayBtnFolderHistory = $rootScope.isCapfun;

        vm.initParamsCollab();

        var groupeInfos = groupeObj.getGroupeInformations();
        if(_.isObject(groupeInfos)){
          //$rootScope.simplifyViewForCamping = groupeInfos.getOnlyInGroupeCamping();
          $rootScope.simplifyViewForCamping = groupeObj.isAccueilCamping();
          if(_.isArray(groupeInfos.getListCampingInformations()) && groupeInfos.getListCampingInformations().length) {
            var campingInfos = groupeInfos.getListCampingInformations()[0];
            $rootScope.isInGroupeTechnique = campingInfos.isTypeCompteTechnique();
            $rootScope.isInGroupeMenage = campingInfos.isTypeCompteMenage();
            if(!_.isNil(campingInfos.getNom())) $rootScope.camping = campingInfos.getNom();
          }
        }

        $timeout(function(){
          // Broadcast de l'événement "connectionStateChanged"
          $rootScope.$broadcast('connectionStateChanged');
        });

        // Retourne l'user dans la promesse
        deferred.resolve(groupeObj);
      });
    }

    function identificationError(deferred, error) {

      var retour = {
        code: null,
        msg: 'Erreur : '
      };
      if(_.isObject(error)) {
        retour.msg += error.statusText;
        if (error.status === 401) {
          retour.code = 401;
          retour.msg = 'Aucun utilisateur ne correspond';
        }
        else if (error.status === 500 || error.status === -1) {
          retour.code = 500;
          retour.msg = 'Problème de connexion, reéssayer ultérieurement...';
        }
      }
      else if(_.isString(error)) {
        retour.code = 401;
        retour.msg += error;
      }
      //$rootScope.mainLoadingCollab = false;
      deferred.reject(retour);
    }

    this.showPopupRenseignementTelephone = function (fiche) {

      var texte = 'Vous n\'avez pas renseigné votre numéro de téléphone portable !';
      currentSweet = sweet.show({
        title: 'Téléphone Portable',
        text: texte,
        type: 'input',
        inputPlaceholder: 'Téléphone Portable',
        cancelButtonText: 'Plus tard !',
        html: true,
        showCancelButton: true,
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        animation: 'slide-from-top'
      }, function (inputValue) {

        if (inputValue === false) {
          return false;
        }
        if (inputValue === '') {
          sweet.showInputError('Veuillez renseigner un numéro de téléphone portable');
          return false;
        } else {
          var pattern = /^(0)[67](\s?\d{2}){4}$/ig;
          if (!pattern.test(inputValue)) {
            sweet.showInputError('Mauvais format !');
            return false;
          }
        }

        var espace = ' ';
        var espaceRegExp = espace.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
        var inputWithoutSpace = inputValue.replace(new RegExp(espaceRegExp, 'g'), '');

        var objParams = {
          'idFiche': fiche.idFiche,
          //'idState': 2, 		// Privé
          'idState': 4, 		// Publique
          'idReference': 1,	// Telephone portable
          'donnee': inputWithoutSpace
        };

        FichesService.addFiche(objParams).then(function (ficheAdd) {
          if (ficheAdd) {
            ficheAdd.idGroupe = vm.getGroupe().getIdGroupe();
          }
          //vm.getGroupe().getUser().setFichePerso(ficheAdd);
          AnnuaireService.setFiche(ficheAdd, true).then(function () {
            sweet.show('Merci !');
          });

        });
      });
    };

    /*
     this.refreshAnnuaire = function (){
     var deferred = $q.defer();
     // Initialise l'annuaire
     AnnuaireService.init().then(function() {
     deferred.resolve();
     });
     return deferred.promise;
     };*/

    this.initParamsCollab = function(){
      $rootScope.paramsCollab = {
        hideBtnMessage: angular.copy(UtilsService.getParametrage('hideBtnMessage')),
        collabizEnabled: angular.copy(UtilsService.getParametrage('collabizEnabled')),
        contratEnabled: angular.copy(UtilsService.getParametrage('contratEnabled')),
        displayWidgetTempDocBox: angular.copy(UtilsService.getParametrage('displayWidgetTempDocBox')),
        isAgenceICS: angular.copy(UtilsService.getParametrage('agenceICS')),
        associationEnabled: angular.copy(UtilsService.getParametrageSociete('associationEnabled')),
        searchByElasticSearchEnabled: angular.copy(UtilsService.getParametrageSociete('searchByElasticSearchEnabled'))
      };
    }

    /**
     * Permet d'init les modules de l'user connecté
     */
    this.initModules = function () {
      var headMenuLien = [];
      var modules = this.getGroupe().getListeModule();
      if (angular.isArray(modules)) {
        if (modules.length) {

          for (var i = 0; i < modules.length; i++) {
            var module = modules[i];
            var modeOuverture = false;

            /*
             // 'page' dans module est à abandonner si possible
             // Préférer les paramètres
             if(module.hasOwnProperty('page') && module.page) {
             modeOuverture = module.page;
             }
             else {*/
            modeOuverture = UtilsService.getParametreForModule(module, 'mode_ouverture');
            //}


            // 'new_onglet' est un ancien paramètre à abandonner si possible, le remplace par 'new_navigateur_onglet'
            if (modeOuverture === 'new_page' || modeOuverture === 'new_onglet' || modeOuverture === 'new_navigateur_onglet') {

              var objLien = {
                'idModule': module.idModule,
                'icon': 'fa fa-tasks fa-2x',
                'libelle': module.libelle
              };

              if (modeOuverture === 'new_onglet' || modeOuverture === 'new_navigateur_onglet') {

                objLien.target = '_blank';
                objLien.url = module.getUrlForIframe();

              }
              headMenuLien.push(objLien);
            }
            $rootScope.current.myModules.push(ModuleService.newModule(module));
          }
        }
      }

      $rootScope.headMenuLien = headMenuLien;
    };

    /**
     * Permet de créer un objet user plus simple et pratique pour l'application
     * @param {Object} json - Le JSON retourné par la servlet
     * @returns {Object} un object user
     */
    this.initServices = function (json) {

      var groupeObj = json.groupe;

      // Initialisation des topic states
      //TopicStatesService.initTopicStates(groupeObj.topicStates);

      // Configure un idGroupe dans le documentService
      DocumentService.conf({
        idGroupe: groupeObj.idGroupe
      });

      var NotificationService = $injector.get('NotificationService');
      NotificationService.initSocketNotification();
      NotificationService = null;

      if (groupeObj.hasOwnProperty('user')) {
        if (groupeObj.user.hasOwnProperty('fichePerso')) {
          AnnuaireService.setFiche(groupeObj.user.fichePerso);
        }
      }
      // Initialise les documents perso de l'utilisateur


      //DocumentService.initDocuments(groupeObj.listeDocument);
      /*
       DocumentService.refreshListDocument().then(function(documents){
       $rootScope.$broadcast('setListeDocument',documents);
       },function(msg){
       console.log(msg);
       });*/
    };

    /**
     * Permet de s'enregistrer
     * @param {String} utilisateur - Login de l'utilisateur ex: @michel
     * @param {String} mdp - Mot de passe de l'utilisateur
     * @returns {Object} (Promesse avec retour de l'objet si success)
     */
    /*
     this.inscription = function(utilisateur,mdp){

     var deferred = $q.defer();

     // Création des paramètres pour la servlet de connexion
     var config = {
     params: {
     nom: utilisateur,
     mdp: mdp
     }
     };


     // Soumet le login et le mot de passe à laservlet de connecion
     $http.get(COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_CONNEXION,config).success(function(json){
     // Si authentification OK
     if(json.nb==='1'){

     var groupeObj = json.Groupes[0];
     var userObj = groupeObj.user;


     // Recrée un obj user "propre" pour le retourner dans la promesse et l'utiliser par la suite
     var objUser = {
     id: userObj.id,
     login: userObj.fichePerso.nom,
     token: groupeObj.token
     };

     // Set l'user dans le service
     vm.initUser(objUser);
     vm.setToken(objUser.token);

     // Retourne l'user dans la promesse
     deferred.resolve(vm.getUser());

     // Broadcast de l'événement "connectionStateChanged"
     $rootScope.$broadcast('connectionStateChanged');

     // Si erreur d'authentification
     }else{
     deferred.reject('Aucun utilisateur ne correspond');
     }
     })
     // Erreur de la servlet
     .error(function(){
     deferred.reject('Problème avec '+COLLAB_CONF.SERVLET_CONNEXION);
     });

     return deferred.promise;
     };*/

    this.initRootscopeVars = function () {
      // Création de la variable "current" lors du démarrage de l'application
      // Elle permet de stocker des choses qui pourront être écoutés car direct dans le rootScope ca ne fonctionne pas
      $rootScope.current = {
        resultElasticSearch: false,
        prevUrl: null,
        pageTopic: 1,
        pageTopicAccueil: 1,
        pageTopicFavoris: 1,
        pageTopicArchives: 1,
        userGroupe: false,                // Objet groupe de l'user actuellement connecte
        filtreTopics: false,               // Filtre des topic actuel
        openPopoverNotifications: false,  // Variable pour afficher ou chacher les notifications
        tmpNewEvenement: {				// Objet qui permet de paramétrer un nouvel event
          destinataires: [],				// Variable temporaire des destinataire renseigné dans le modal annuaire
          tagsForOrigine: false			// Permet de préremplir l'origine lors de l'arrivé sur un nouvel événement
        },
        tmpDestinataires: [],             // Variable temporaire des destinataire renseigné dans le modal annuaire
        myModules: [],                    // Modules de l'user actuellement connecte
        rechercheGenerale: {
          paramRechercheGenerale: {},      // Stock les paramètres de la recherche generale
          resultatsRechercheGenerale: false
        }/*,
                 'channels': {
                 'idChannelAccueil': 0	// Id du channel accueil
                 }*/
      };

      // Instances $interval pour les servlet qui check toutes les n secondes
      $rootScope._poolIntervalServlet = {};

      // Instance $interval pour l'auto save
      $rootScope._poolIntervalAutoSaveContenu = {};

      // Instances de CKEDITOR
      $rootScope._poolInstancesCKEDITOR = {};


    };

    /**
     * Méthode de déconnexion
     * @param void
     * @returns void : set l'user à false et broadcast l'événement "connectionStateChanged"
     */
    this.deconnexion = function (logoutOnelogin) {

      $rootScope.isConnected = false;

      this.logout(logoutOnelogin);

      // Connect la socket
      /*WebsocketServices.disconnect(true);*/

      SocketNotificationService.logOut();
      /*
      if(SocketNotificationService.isConnected()) {
          SocketNotificationService.getInstanceSocketIO().removeAllListeners();
          SocketNotificationService.closeSocketIO(true);
      }*/

      this.initGroupe(false);

      // Set le token dans le localStorage
      ConfigService.setUserToken(false);

      // Reset le filtre
      ConfigService.setPreferencesFiltreTopics(false);

      $rootScope.token = false;

      // Efface les documents du pool
      DocumentService.destroy();

      // Efface les documents du pool
      AnnuaireService.destroy();

      // Efface les notifications
      // TODO : [Notification] Destroy les eventuels notifications

      // Efface les infos
      //InformationsServices.destroy();

      // Si il y a un modal sweet ouvert le ferme
      if (sweet) {
        sweet.close();
      }

      // Ferme les modal ui bootstrap
      $uibModalStack.dismissAll();

      // Stop toute les interval/timeout qui peuvent exister pour des appel servlet
      angular.forEach($rootScope._poolIntervalServlet, function (interval) {
        $timeout.cancel(interval);
        //$interval.cancel(interval);
      });

      if (COLLAB_CONF.ENABLE_LIVETCHAT && instanceLivetchat) {
        instanceLivetchat.destroy();
      }

      this.initRootscopeVars();

      //$rootScope = $rootScope.$new(true);

      $rootScope.$broadcast('connectionStateChanged');

    };

  }

})();
