(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.TagsService
   * @description
   * # TagsService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('TagsService', TagsService);

  /*@ngInject*/
  function TagsService(COLLAB_CONF, $injector, UtilsService) {

    var ctrl = this;
    ctrl.genereTag = genereTag;                                         // Permet de générer un tag
    ctrl.genereTagRapportVisite = genereTagRapportVisite;               // Permet de générer un tag normal RapportVisite
    ctrl.createTags = createTags;                                       // Permet de générer deux liste de tags (Tags et TagsType)
    ctrl.genereTagsAndFichesForSearch = genereTagsAndFichesForSearch;   // Permet de générer un objet avec les tags et les fiches
    ctrl.genereTagsAndFiches = genereTagsAndFiches;                     // Permet de générer un objet avec les tags et les fiches
    ctrl.genereTagTypePortefeuille = genereTagTypePortefeuille;
    ctrl.genereTagTypeType = genereTagTypeType;
    ctrl.genereTagTypeStatut = genereTagTypeStatut;
    ctrl.genereTagTypePresence = genereTagTypePresence;
    ctrl.genereTagTypeTechnicien = genereTagTypeTechnicien;
    ctrl.genereTagTypeMenage = genereTagTypeMenage;
    ctrl.genereTagTypeVisicopRefLiaisonECCA = genereTagTypeVisicopRefLiaisonECCA;
    ctrl.hasTagType = hasTagType;                                       // Permet de savoir si dans "tags" il y a un tab type demandé
    ctrl.hasTagTypeProprietaire = hasTagTypeProprietaire;               // Permet de savoir si dans "tags" il y a le tag type propriétaire
    ctrl.tagExistIn = tagExistIn;                                       // Permet de checker si un tag exist


    ctrl.getIconForTagType = getIconForTagType;                         // Permet de récupérer l'icon du tag type
    ctrl.getClassBadge = getClassBadge;                                 // Permet de récupérer la class pour le badge d'un tag
    ctrl.getLibelle = getLibelle;                                       // Permet de récupérer le libelle d'un tag

    ctrl.getTagsWithoutType = getTagsWithoutType;                       // Recupère dans "tags" les tags différent du type demandé
    ctrl.getTagsForType = getTagsForType;                               // Récupère dans "tags" une liste de tag typé avec le string demandé
    ctrl.getTagForType = getTagForType;                                 // Récupère dans "tags" le tag typé avec le string demandé

    ctrl.getTagTypeOrigine = getTagTypeOrigine;                         // Récupère dans "tags" le tag typé origine
    ctrl.getTagTypeImportant = getTagTypeImportant;                     // Récupère dans "tags" le tag typé important
    ctrl.getTagTypePortefeuille = getTagTypePortefeuille;               // Récupère dans "tags" le tag typé portefeuille
    ctrl.getTagTypeImmeuble = getTagTypeImmeuble;                       // Récupère dans "tags" le tag typé immeuble
    ctrl.getTagTypeProprietaire = getTagTypeProprietaire;               // Récupère dans "tags" le tag typé proprietaire
    ctrl.getTagTypeLotMulticamp = getTagTypeLotMulticamp;               // Récupère dans "tags" le tag typé LotMulticamp

    ctrl.deleteTagFromTags = deleteTagFromTags;          // Permet de supprimer un tag d'une liste de tags

    /**
     * Créé une instance de Tag
     * @param data
     * @returns {Tag|*}
     */
    function newTag(data, libelleTypeTag, valeurIcs, portefeuille, noParseLibelle, oldTag){
      var Tag = $injector.get('Tag');
      var objTag = null;
      var copyData = angular.copy(data);

      // Si data est in string alors c'est un libelle normal et un tag non typé
      if (_.isString(data) || _.isNumber(data)) {
        if(_.isNumber(copyData)) copyData = _.toString(copyData);
        // Si on doit parser le libelle
        if(!noParseLibelle)
          copyData = (oldTag) ? UtilsService.oldParseTag(copyData) : UtilsService.parseTag(copyData);

        // Si pas de diese devant
        if(!UtilsService.startsWith(copyData, '#'))
          copyData = '#' + copyData;

        objTag = {
          libelle: copyData
        };
      }
      else if (_.isObject(copyData)) {
        if(copyData.isModel && copyData.isTag) objTag = copyData;
        else {
          if (copyData.isPerso) copyData = copyData.perso;
          else if (copyData.isCollab) copyData = copyData.groupe;
          else if (copyData.isImmeuble) {
            if(copyData.hasOwnProperty("immeuble")) copyData = copyData.immeuble;
          }
          else if (copyData.isLotMulticamp) copyData = copyData.lotMulticamp;
          else if (copyData.isLieuCommunCapfun) copyData = copyData.lieuCommunCapfun;
        }

        if(_.isNil(objTag) && (!_.isNil(copyData) && copyData)) {
          // Génère le libelle du tag
          var libelle = (oldTag) ? Tag.genereOldLibelleTag(copyData) : Tag.genereLibelleTag(copyData);
          if(libelle){
            objTag = {
              libelle: libelle
            };
          }
        }
      }

      if(_.isObject(objTag) || !_.isNil(libelleTypeTag)) {
        if(!_.isObject(objTag)) objTag = {};
        if(_.isNumber(valeurIcs) || _.isString(valeurIcs)) objTag.valeurIcs = angular.copy(valeurIcs);

        if(_.isObject(portefeuille)){
          var Portefeuille = $injector.get('Portefeuille');
          objTag.portefeuille = new Portefeuille({
            idPortefeuille: portefeuille.idPortefeuille,
            nom: portefeuille.nom
          });
        }

        if(_.isString(libelleTypeTag)) objTag.typeTag = {
          libelle:angular.copy(libelleTypeTag)
        };


        if(!objTag.isTag){
          objTag = new Tag(objTag);
        }
      }

      return objTag;
    }

    /**
     * Permet de générer un tag
     * @param obj
     * @param libelleTypeTag
     * @param valeurIcs
     * @returns {boolean}
     */
    function genereTag(obj, libelleTypeTag, valeurIcs, portefeuille){
      return newTag(obj, libelleTypeTag, valeurIcs, portefeuille, null, null);
    }

    function genereTagRapportVisite(valeurIcs, portefeuille){
      return newTag(COLLAB_CONF.TAG_RAPPORT_VISITE, null, valeurIcs, portefeuille, null, null);
    }

    //-------------- Méthodes de génération de tag type --------------------/
    function genereTagTypeImmeuble(libelle,valeurIcs,portefeuille,oldTag){         return newTag(libelle, COLLAB_CONF.TAG_TYPE_IMMEUBLE, valeurIcs, portefeuille, null, oldTag);}
    function genereTagTypeLot(libelle,valeurIcs,portefeuille,oldTag){              return newTag(libelle, COLLAB_CONF.TAG_TYPE_LOT, valeurIcs, portefeuille, null, oldTag);}
    function genereTagTypeProprietaire(libelle,valeurIcs,portefeuille,oldTag){     return newTag(libelle, COLLAB_CONF.TAG_TYPE_PROPRIETAIRE, valeurIcs, portefeuille, null, oldTag);}
    function genereTagTypeCoproprietaire(libelle,valeurIcs,portefeuille,oldTag){   return newTag(libelle, COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE, valeurIcs, portefeuille, null, oldTag);}
    function genereTagTypeLocataire(libelle,valeurIcs,portefeuille,oldTag){        return newTag(libelle, COLLAB_CONF.TAG_TYPE_LOCATAIRE, valeurIcs, portefeuille, null, oldTag);}
    function genereTagTypeMandat(libelle,valeurIcs,portefeuille,oldTag){           return newTag(libelle, COLLAB_CONF.TAG_TYPE_MANDAT, valeurIcs, portefeuille, null, oldTag);}
    function genereTagTypeOrigineCollab(libelle,valeurIcs,portefeuille,oldTag){    return newTag(libelle, COLLAB_CONF.TAG_TYPE_ORIGINE_COLLAB, valeurIcs, portefeuille, null, oldTag);}
    function genereTagTypeOrigineICS(libelle,valeurIcs,portefeuille,oldTag){       return newTag(libelle, COLLAB_CONF.TAG_TYPE_ORIGINE_ICS, valeurIcs, portefeuille, null, oldTag);}
    function genereTagTypeOrigineCompta(libelle,valeurIcs,portefeuille,oldTag){    return newTag(libelle, COLLAB_CONF.TAG_TYPE_ORIGINE_COMPTA, valeurIcs, portefeuille, null, oldTag);}
    function genereTagTypeOrigineCapfun(libelle,valeurIcs,portefeuille,oldTag){    return newTag(libelle, COLLAB_CONF.TAG_TYPE_ORIGINE_CAPFUN, valeurIcs, portefeuille, null, oldTag);}
    function genereTagTypePortefeuille(libelle,valeurIcs,portefeuille,oldTag){     return newTag(libelle, COLLAB_CONF.TAG_TYPE_PORTEFEUILLE, valeurIcs, portefeuille, null, oldTag);}
    function genereTagTypeImportant(libelle,valeurIcs,portefeuille,oldTag){        return newTag(libelle, COLLAB_CONF.TAG_TYPE_IMPORTANT, valeurIcs, portefeuille, null, oldTag);}
    function genereTagTypeStatut(libelle,valeurIcs,portefeuille,oldTag){           return newTag(libelle, COLLAB_CONF.TAG_TYPE_STATUT, valeurIcs, portefeuille, null, oldTag);}
    function genereTagTypeType(libelle,valeurIcs,portefeuille,oldTag){             return newTag(libelle, COLLAB_CONF.TAG_TYPE_TYPE, valeurIcs, portefeuille, null, oldTag);}
    function genereTagTypeLotMulticamp(libelle,valeurIcs,portefeuille,oldTag){     return newTag(libelle, COLLAB_CONF.TAG_TYPE_LOT_MULTICAMP, valeurIcs, portefeuille, null, oldTag);}
    function genereTagTypeLieuCommunCapfun(libelle,valeurIcs,portefeuille,oldTag){ return newTag(libelle, COLLAB_CONF.TAG_TYPE_LIEU_COMMUN_CAPFUN, valeurIcs, portefeuille, null, oldTag);}
    function genereTagTypePresence(libelle,valeurIcs,portefeuille,oldTag){         return newTag(libelle, COLLAB_CONF.TAG_TYPE_PRESENCE, valeurIcs, portefeuille, null, oldTag);}
    function genereTagTypeClientCapfun(libelle,valeurIcs,portefeuille,oldTag){     return newTag(libelle, COLLAB_CONF.TAG_TYPE_CLIENT_CAPFUN, valeurIcs, portefeuille, null, oldTag);}
    function genereTagTypeTechnicien(libelle,valeurIcs,portefeuille,oldTag){       return newTag(libelle, COLLAB_CONF.TAG_TYPE_TECHNICIEN, valeurIcs, portefeuille, null, oldTag);}
    function genereTagTypeMenage(libelle,valeurIcs,portefeuille,oldTag){           return newTag(libelle, COLLAB_CONF.TAG_TYPE_MENAGE, valeurIcs, portefeuille, null, oldTag);}
    function genereTagTypeVisicopRefLiaisonECCA(libelle, valeurIcs,portefeuille,oldTag){   return newTag((_.isNil(libelle)) ? COLLAB_CONF.LIBELLE_TAG_TYPE_VISICOP_REF_LIAISON_ECCA : libelle, COLLAB_CONF.TAG_TYPE_VISICOP_REF_LIAISON_ECCA, valeurIcs, portefeuille, null, oldTag);}

    /**
     * Permet de génrer l'objet d'un tag Projet pour un mandat
     * @param collaborateur
     * @param numMandat
     * @returns {{libelle: string, typeTag: {libelle: string}}}
     */
    function getTagProjetMandat(collaborateur, numMandat, portefeuille, oldTag){
      var nom = UtilsService.getNom(collaborateur,true);
      return newTag('#MAN-' + numMandat + '-' + nom, COLLAB_CONF.TAG_TYPE_PROJET, false, portefeuille, true, oldTag);
    }

    /**
     * Permet de génrer l'objet d'un tag Projet pour un immeuble
     * @param item
     * @returns {{}}
     */
    function getTagProjetImmeuble(item, portefeuille, oldTag){
      var tag = null;
      if(_.isObject(item) && item.isImmeuble) {
        tag = newTag('#IMM-' + item.getNumImmeuble() + '-' + UtilsService.parseTag(item.getNom()).slice(1), COLLAB_CONF.TAG_TYPE_PROJET, false, portefeuille, true, oldTag);
      }
      return tag;
    }

    /**
     * Permet de génrer l'objet d'un tag Projet pour un lot
     * @param item
     * @returns {{}}
     */
    function getTagProjetLot(item, portefeuille, oldTag){
      var tag = null;
      // Si lot
      if(_.isObject(item) && item.isLot){
        tag = newTag('#LOT-' + item.getNumImmeuble() + '-' + item.getNumLot(), COLLAB_CONF.TAG_TYPE_PROJET, false, portefeuille, true, oldTag);
      }
      return tag;
    }

    /**
     * Permet de génrer l'objet d'un tag Projet pour un lot multicamp pour Capfun
     * @param item
     * @returns {{}}
     */
    function getTagProjetLotMulticamp(item, portefeuille, oldTag){
      var tag = null;
      // Si lot
      if(_.isObject(item) && item.isLotMulticamp){
        tag = newTag('#MOBILEHOME-' + UtilsService.parseLibelleTag(item.getPlace_name()).toUpperCase() + '-' + UtilsService.parseLibelleTag(item.getCamping()).toUpperCase(), COLLAB_CONF.TAG_TYPE_PROJET, false, portefeuille, true, oldTag);
      }
      return tag;
    }

    /**
     * Permet de génrer l'objet d'un tag Projet pour un lieu commun pour Capfun
     * @param item
     * @returns {{}}
     */
    function getTagProjetLieuCommunCapfun(item, portefeuille, oldTag){
      var tag = null;
      // Si lot
      if(_.isObject(item) && item.isLieuCommunCapfun){
        tag = newTag('#LIEU-' + UtilsService.parseLibelleTag(item.getLieu()).toUpperCase() + '-' + UtilsService.parseLibelleTag(item.getCamping()).toUpperCase(), COLLAB_CONF.TAG_TYPE_PROJET, false, portefeuille, true, oldTag);
      }
      return tag;
    }

    /**
     * Permet de test si les tag son les même
     * @param newTag
     * @param oldTag
     * @returns {boolean}
     */
    function isSameTag(newTag, oldTag){
      if(_.isObject(newTag) && _.isObject(oldTag)) {
        if(!_.isEqual(newTag.libelle, oldTag.libelle)) {
          return false;
        }
        if(!_.isEqual(newTag.valeurIcs, oldTag.valeurIcs)) {
          return false;
        }
        if((_.isObject(newTag.typeTag) && !_.isObject(oldTag.typeTag)) || (!_.isObject(newTag.typeTag) && _.isObject(oldTag.typeTag))) {
          return false;
        }
        if(_.isObject(newTag.typeTag) && _.isObject(oldTag.typeTag) && !_.isEqual(newTag.typeTag.libelle, oldTag.typeTag.libelle)) {
          return false;
        }
        if((_.isObject(newTag.portefeuille) && !_.isObject(oldTag.portefeuille)) || (!_.isObject(newTag.portefeuille) && _.isObject(oldTag.portefeuille))) {
          return false;
        }
        if(_.isObject(newTag.portefeuille) && _.isObject(oldTag.portefeuille) && !_.isEqual(newTag.portefeuille.idPortefeuille, oldTag.portefeuille.idPortefeuille)) {
          return false;
        }
        return true;
      }
      return false;
    }

    /**
     * Permet de générer deus liste de tags, une non typé et une typé
     * @param obj
     * @param oldTag = Permet de savoir si la fonction est executé pour la recherche par tags dans ce cas on génère deux tags et test les libelle
     * @returns {{tagsType: Array, tags: Array}}
     */
    function createTags(obj, oldTag){
      var retour = {
        tagsType: [],
        tags: []
      };

      try{
        if(!_.isObject(obj))
          throw new Error('Le paramètre obj doit être un objet');

        var objPortefeuille = obj.portefeuille;

        if(_.isObject(objPortefeuille)){
          retour.tagsType.push(genereTagTypePortefeuille(objPortefeuille.nom, objPortefeuille.idPortefeuille, objPortefeuille));
          if(oldTag) {
            retour.tagsType.push(genereTagTypePortefeuille(objPortefeuille.nom, objPortefeuille.idPortefeuille, objPortefeuille, oldTag));
          }
        }

        // Génére le tagType immeuble
        if(_.isObject(obj.immeuble)){
          var tagImm = genereTagTypeImmeuble(obj.immeuble.getNom(), obj.immeuble.getNumImmeuble(), objPortefeuille);
          retour.tagsType.push(tagImm);
          if(oldTag) {
            var olTagImm = genereTagTypeImmeuble(obj.immeuble.getNom(), obj.immeuble.getNumImmeuble(), objPortefeuille, oldTag);
            if(!isSameTag(olTagImm, tagImm)) {
              retour.tagsType.push(olTagImm);
            }
          }
        }

        /*
        if(_.isObject(obj.bail))
            retour.bail = obj.bail;*/

        if(!_.isNil(obj.mandat)){
          retour.tagsType.push(genereTagTypeMandat(obj.mandat, obj.mandat, objPortefeuille));
          if(oldTag) {
            retour.tagsType.push(genereTagTypeMandat(obj.mandat, obj.mandat, objPortefeuille, oldTag));
          }
        }

        if(_.isObject(obj.coproprietaire)){
          retour.tagsType.push(genereTagTypeCoproprietaire(obj.coproprietaire.getNomper(), obj.coproprietaire.getNoperso(), objPortefeuille));
          if(oldTag) {
            retour.tagsType.push(genereTagTypeCoproprietaire(obj.coproprietaire.getNomper(), obj.coproprietaire.getNoperso(), objPortefeuille, oldTag));
          }
        }

        if(_.isObject(obj.proprietaire)){
          retour.tagsType.push(genereTagTypeProprietaire(obj.proprietaire.getNomper(), obj.proprietaire.getNoperso(), objPortefeuille));
          if(oldTag) {
            retour.tagsType.push(genereTagTypeProprietaire(obj.proprietaire.getNomper(), obj.proprietaire.getNoperso(), objPortefeuille, oldTag));
          }
        }

        if(_.isObject(obj.locataire)){
          var tagLoc = genereTagTypeLocataire(obj.locataire.getNomper(), obj.locataire.getNoperso(), objPortefeuille);
          retour.tagsType.push(tagLoc);
          if(oldTag) {
            var olTagLoc = genereTagTypeLocataire(obj.locataire.getNomper(), obj.locataire.getNoperso(), objPortefeuille, oldTag);
            if(!isSameTag(tagLoc, olTagLoc)) {
              retour.tagsType.push(olTagLoc);
            }
          }
        }

        if(_.isObject(obj.salarie)){
          var profess = obj.salarie.getProfess();
          if (profess !== ''){
            retour.tags.push(newTag(profess));
            if(oldTag) {
              retour.tags.push(newTag(profess, null, null, null, null, oldTag));
            }
          }
        }

        // Génére le tagType Lot
        if(_.isObject(obj.lot)){
          retour.tagsType.push(genereTagTypeLot(obj.lot.getNumLot(), obj.lot.getNumImmeuble() + obj.lot.getNumLot(), objPortefeuille));
          if(oldTag) {
            retour.tagsType.push(genereTagTypeLot(obj.lot.getNumLot(), obj.lot.getNumImmeuble() + obj.lot.getNumLot(), objPortefeuille, oldTag));
          }
        }

        // Génére le tagType LotMulticamp
        if(_.isObject(obj.lotMulticamp)){
          retour.tagsType.push(genereTagTypeLotMulticamp(obj.lotMulticamp.getPlace_name(), obj.lotMulticamp.getPlace_id(), objPortefeuille));
          if(oldTag) retour.tagsType.push(genereTagTypeLotMulticamp(obj.lotMulticamp.getPlace_name(), obj.lotMulticamp.getPlace_id(), objPortefeuille, oldTag));

          // Génère tag typé "ClientCapfun"
          if(_.isObject(obj.clientCapfun)) retour.tagsType.push(genereTagTypeClientCapfun(obj.clientCapfun.nom, obj.clientCapfun.idPerson, objPortefeuille));

          // Génère tag typé "Type"
          retour.tagsType.push(genereTagTypeType(COLLAB_CONF.TAG_TYPE_TYPE_INTERVENTION, null, null));

          // Génère tag typé "Presence"
          retour.tagsType.push(genereTagTypePresence(COLLAB_CONF.TAG_TYPE_PRESENCE_FACULTATIVE, null, objPortefeuille));

          // Génère tag typé "Statut"
          retour.tagsType.push(genereTagTypeStatut(COLLAB_CONF.TAG_TYPE_STATUT_EN_ATTENTE, null, null));
        }

        // Génére le tagType LieuCommunCapfun
        if(_.isObject(obj.lieuCommunCapfun)){
          retour.tagsType.push(genereTagTypeLieuCommunCapfun(obj.lieuCommunCapfun.getLieu(), null, objPortefeuille));
          if(oldTag) retour.tagsType.push(genereTagTypeLieuCommunCapfun(obj.lieuCommunCapfun.getLieu(), null, objPortefeuille, oldTag));

          // Génère tag typé "Type"
          retour.tagsType.push(genereTagTypeType(COLLAB_CONF.TAG_TYPE_TYPE_INTERVENTION, null, null));

          // Génère tag typé "Statut"
          retour.tagsType.push(genereTagTypeStatut(COLLAB_CONF.TAG_TYPE_STATUT_EN_ATTENTE, null, null));
        }

        if(!_.isNil(obj.projet)){
          if(_.isObject(obj.projet))
            retour.tagsType.push(obj.origine.tagProjet);

          else if(_.isString(obj.projet)){

            if(obj.projet === 'lot' && _.isObject(obj.lot)){
              retour.tagsType.push(getTagProjetLot(obj.lot, objPortefeuille));
              if(oldTag) {
                retour.tagsType.push(getTagProjetLot(obj.lot, objPortefeuille, oldTag));
              }
            }

            else if(obj.projet === 'immeuble' && _.isObject(obj.immeuble)){
              retour.tagsType.push(getTagProjetImmeuble(obj.immeuble, objPortefeuille));
              if(oldTag) {
                retour.tagsType.push(getTagProjetImmeuble(obj.immeuble, objPortefeuille, oldTag));
              }
            }

            else if(obj.projet === 'mandat'){
              retour.tagsType.push(getTagProjetMandat(obj.origine, obj.mandat, objPortefeuille));
              if(oldTag) {
                retour.tagsType.push(getTagProjetMandat(obj.origine, obj.mandat, objPortefeuille, oldTag));
              }
            }

            else if(obj.projet === 'proprietaire'){
              retour.tagsType.push(newTag(obj.proprietaire, COLLAB_CONF.TAG_TYPE_PROJET));
              if(oldTag) {
                retour.tagsType.push(newTag(obj.proprietaire, COLLAB_CONF.TAG_TYPE_PROJET, null, null, null, oldTag));
              }
            }

            else if(obj.projet === 'coproprietaire'){
              retour.tagsType.push(newTag(obj.coproprietaire, COLLAB_CONF.TAG_TYPE_PROJET));
              if(oldTag) {
                retour.tagsType.push(newTag(obj.coproprietaire, COLLAB_CONF.TAG_TYPE_PROJET, null, null, null, oldTag));
              }
            }

            else if(obj.projet === 'locataire'){
              retour.tagsType.push(newTag(obj.locataire, COLLAB_CONF.TAG_TYPE_PROJET));
              if(oldTag) {
                retour.tagsType.push(newTag(obj.locataire, COLLAB_CONF.TAG_TYPE_PROJET, null, null, null, oldTag));
              }
            }

            else if(obj.projet === 'fournisseur'){
              retour.tagsType.push(newTag(obj.fournisseur, COLLAB_CONF.TAG_TYPE_PROJET));
              if(oldTag) {
                retour.tagsType.push(newTag(obj.fournisseur, COLLAB_CONF.TAG_TYPE_PROJET, null, null, null, oldTag));
              }
            }

            else if(obj.projet === 'client'){
              retour.tagsType.push(newTag(obj.client, COLLAB_CONF.TAG_TYPE_PROJET));
              if(oldTag) {
                retour.tagsType.push(newTag(obj.client, COLLAB_CONF.TAG_TYPE_PROJET, null, null, null, oldTag));
              }
            }
            else if(obj.projet === 'mh'){
              retour.tagsType.push(getTagProjetLotMulticamp(obj.lotMulticamp, objPortefeuille));
              if(oldTag) {
                retour.tagsType.push(getTagProjetLotMulticamp(obj.lotMulticamp, objPortefeuille, oldTag));
              }
            }
            else if(obj.projet === 'lieu'){
              retour.tagsType.push(getTagProjetLieuCommunCapfun(obj.lieuCommunCapfun, objPortefeuille));
              if(oldTag) {
                retour.tagsType.push(getTagProjetLieuCommunCapfun(obj.lieuCommunCapfun, objPortefeuille, oldTag));
              }
            }
          }
        }
        else if(_.isObject(obj.origine)){
          retour.tagsType.push(newTag(obj.origine, COLLAB_CONF.TAG_TYPE_PROJET));
          if(oldTag) {
            retour.tagsType.push(newTag(obj.origine, COLLAB_CONF.TAG_TYPE_PROJET, null, null, null, oldTag));
          }
        }

      }
      catch(err){
        console.error('[TagService.createTags] Erreur : '+err.message);
        console.log(err);
      }
      return retour;
    }


    function genereTagsAndFichesForSearch(obj, typeTag){
      return genereTagsAndFiches(obj, typeTag, true);
    }

    /**
     * Permet de générer un objet avec les tags et les fiches
     * @param obj
     * @param typeTag
     * @returns {{tag: null, tagsType: Array, tags: Array, fiches: null}}
     */
    function genereTagsAndFiches(obj, typeTag, withOldTag){
      var retour = {
        tagCtrl: {tags : []},
        addTag: null,
        disableEventCreation: false
      };

      if(!typeTag) typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE;


      // Si ajout d'un tag origine
      if(typeTag === COLLAB_CONF.TAG_TYPE_ORIGINE){
        var objForCreateTags = {};
        var addTags = {
          tag: null,
          tagsType: [],
          tags: [],
          fiches: null
        };

        var objPortefeuille = (_.isObject(obj.portefeuille)) ? obj.portefeuille : false;
        var objImmeuble = null;
        var valeurIcs = false;

        if(obj.type === 'ICS'){
          typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_ICS;
          if(_.isObject(objPortefeuille)){
            // Si le portefeuille est celui d'ICS on change le type de tag pour un tag origin compta
            if(objPortefeuille.nom === COLLAB_CONF.NOM_PORTEFEUILLE_ICS)
              typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_COMPTA;

          }

          if(_.isObject(obj.origine) && obj.origine.isCollaborateur){
            if(obj.origine.isPerso) obj.origine = obj.origine.perso;
          }

          // Si l'origine est un objet et un model
          if(_.isObject(obj.origine) && obj.origine.isModel) {

            var typePerso = null;

            // Si l'origine est une personnalite
            if(obj.origine.isPersonnalite) {
              valeurIcs = obj.origine.getNoperso();

              // Si il y a un typePerso alors c'est lui qu'on doit prendre en compte
              if(_.isString(obj.typePerso)){
                if(obj.typePerso === 'Coproprietaire')
                  typePerso = 'coproprietaire';

                else if(obj.typePerso === 'Proprietaire')
                  typePerso = 'proprietaire';

                else if(obj.typePerso === 'Locataire' || obj.typePerso === 'Colocataire')
                  typePerso = 'locataire';

              }
              // Sinon on va chercher dans l'origine
              else {

                // Si Copropriétaire
                if (obj.origine.isCoproprietaire())
                  typePerso = 'coproprietaire';

                // Si Propriétaire
                else if (obj.origine.isProprietaire())
                  typePerso = 'proprietaire';

                // Si Locataire
                else if (obj.origine.isLocataire())
                  typePerso = 'locataire';

                // Si Colocataire
                else if (obj.origine.isColocataire())
                  typePerso = 'colocataire';

                // Si Colocataire
                else if (obj.origine.isFournisseur())
                  typePerso = 'fournisseur';
              }

              if(!_.isNil(typePerso)) {
                if(_.isNil(addTags.fiches)) addTags.fiches = {};
                objForCreateTags[typePerso] = obj.origine;
                addTags.fiches[typePerso] = obj.origine;
              }
            }

            // Si l'origine est un immeuble
            else if(obj.origine.isImmeuble) {
              valeurIcs = obj.origine.getNumImmeuble();

              typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_IMMEUBLE;
              objForCreateTags.immeuble = obj.origine;

              if(_.isNil(addTags.fiches)) addTags.fiches = {};
              addTags.fiches.immeuble = obj.origine;

              objImmeuble = obj.origine;
            }

            // Si salarié comme origine
            else if(obj.origine.isSalarie) {
              valeurIcs = obj.origine.getNoperso();
              typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_GARDIEN;
              if(valeurIcs !== '000000') {
                if(_.isNil(addTags.fiches)) addTags.fiches = {};
                addTags.fiches.salarie = obj.origine;
              }
            }
            /*
            else if(obj.origine.isCollab) {
                valeurIcs = obj.origine.idGroupe;
                typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_IMMEUBLE;
                retour.fiches.groupe = obj.origine.groupe;
            }*/

            if(UtilsService.isICS()){
              if(obj.origine.isPersonnalite){
                if(_.isNil(addTags.fiches)) addTags.fiches = {};
                addTags.fiches.client = obj.origine;
              }
            }
          }

          // Si il y a un immeuble dans l'objet et qu'il n'y a pas encore d'immeuble dans les tag à créer
          if(_.isObject(obj.immeuble) && _.isNil(objForCreateTags.immeuble)) {
            if(_.isNil(addTags.fiches)) addTags.fiches = {};
            addTags.fiches.immeuble = obj.immeuble;
            objForCreateTags.immeuble = obj.immeuble;
            if(_.isNil(objImmeuble))
              objImmeuble = obj.immeuble;
          }

          // Si il y a un bail dans l'objet
          if(_.isObject(obj.bail)) {
            if(_.isNil(addTags.fiches)) addTags.fiches = {};
            addTags.fiches.bail = obj.bail;
            // Création d'un tag locataire si il y a un bail avec une personnalite
            if(!_.isNil(obj.bail.getPersonnalite())) {
              objForCreateTags.locataire = obj.bail.getPersonnalite();
              delete addTags.fiches.locataire;
            }
          }

          // Si il y a un lot dans l'objet
          if(_.isObject(obj.lot)) {
            objForCreateTags.lot = obj.lot;
            // Création d'un tag mandat si il y a un numéro de mandat dans le lot
            if(!_.isNil(obj.lot.mmmm)) {
              objForCreateTags.mandat = obj.lot.mmmm;
            }

            /*
            // TODO : Quand il y aura la perso dans le bail pour le lot alors il faudra ajouter le locataire
            // Si pas de bail mais qu'on a un lot avec un bail et un nomperlocat dedans (locataire)
            if(_.isNil(obj.bail) && _.isObject(obj.lot.bail) && !_.isNil(obj.lot.bail.noperlocat)) {
                objForCreateTags.locataire = obj.bail.noperlocat;
                addTags.fiches.locataire = obj.lot.bail.personnalite;
            }*/
          }
          // Si il y a un mandat dans l'objet
          else if(_.isString(obj.mandat) && obj.mandat !== "") {
            objForCreateTags.mandat = obj.mandat;
          }

          objForCreateTags.origine = obj.origine;
        }
        else if(obj.type === 'Capfun'){

          // Si l'origine est un lot multicamp
          if(obj.origine.isLotMulticamp) {
            valeurIcs = obj.origine.lotMulticamp.getPlace_id();

            typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_CAPFUN;
            objForCreateTags.lotMulticamp = obj.origine.lotMulticamp;

            if(_.isNil(addTags.fiches)) addTags.fiches = {};
            objForCreateTags.clientCapfun = obj.clientCapfun;
            addTags.fiches.lotMulticamp = obj.origine;
          }
          // Si l'origine est un lieu commun capfun
          else if(obj.origine.isLieuCommunCapfun) {
            valeurIcs = null;

            typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_CAPFUN;
            objForCreateTags.lieuCommunCapfun = obj.origine.lieuCommunCapfun;

            if(_.isNil(addTags.fiches)) addTags.fiches = {};
            addTags.fiches.lieuCommunCapfun = obj.origine;
          }

        }
        else if(obj.type === 'Immeuble'){
          if(obj.origine.isImmeuble) {
            valeurIcs = obj.origine.noimme;
            typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_IMMEUBLE;
          }
          else if(obj.origine.hasOwnProperty('noperso')) {
            valeurIcs = obj.origine.noperso;

            // Si model personnalite
            if(obj.origine.isPersonnalite) {
              // Si copropriétaire comme origine
              if(obj.origine.isCoproprietaire()) typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_ICS;
            }
            // Si salarié comme origine
            else if(obj.origine.isSalarie) {
              typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_GARDIEN;
              if(valeurIcs !== '000000') {
                if(_.isNil(addTags.fiches)) addTags.fiches = {};
                objForCreateTags.coproprietaire = addTags.fiches.salarie = obj.origine;
              }
            }
          }

          if(_.isObject(obj.coproprietaire)) {
            if(_.isNil(addTags.fiches)) addTags.fiches = {};
            objForCreateTags.coproprietaire = addTags.fiches.coproprietaire = obj.coproprietaire;
          }
        }
        else if(obj.type === 'Collab'){
          valeurIcs = obj.origine.idGroupe;
          typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_COLLAB;
          if(_.isNil(addTags.fiches)) addTags.fiches = {};
          addTags.fiches.groupe = obj.origine.groupe;
        }

        if(obj.type === 'Immeuble' || obj.type === 'ICS'){
          // Si il y a un immeuble dans l'objet et qu'il n'y a pas encore d'immeuble dans les tag à créer
          if(_.isObject(obj.immeuble) && _.isNil(objForCreateTags.immeuble)) {
            if(_.isNil(addTags.fiches)) addTags.fiches = {};
            addTags.fiches.immeuble = obj.immeuble;
            objForCreateTags.immeuble = obj.immeuble;
            if(_.isNil(objImmeuble))
              objImmeuble = obj.immeuble;
          }
        }

        // Si il y a un portefeuille dans l'objet
        if(_.isObject(obj.portefeuille)) {
          objForCreateTags.portefeuille = obj.portefeuille;
        }

        // Si il y a un projet dans l'objet
        if(!_.isNil(obj.projet) && obj.projet) {
          objForCreateTags.projet = obj.projet;

          // Si le tag projet doit etre le lot
          if(obj.projet === 'lot') {
            // Si on a bien un immeuble et qu'il une perso dedans alors c'est un propriétaire
            if(_.isObject(objImmeuble) && _.isObject(objImmeuble.personnalite)) {
              objForCreateTags.proprietaire = objImmeuble.personnalite;
            }
          }

          if(obj.projet === 'proprietaire' || obj.projet === 'coproprietaire' || obj.projet === 'locataire' || obj.projet === 'disableEventCreation') {
            retour.disableEventCreation = true;
          }
        }

        // Si il y a un proprietaire
        if(_.isObject(obj.proprietaire) && _.isNil(objForCreateTags.proprietaire)) {
          objForCreateTags.proprietaire = obj.proprietaire;
          if(obj.typePerso !== "Locataire") addTags.fiches.proprietaire = obj.proprietaire;

          //objForCreateTags.coproprietaire = false;
          //delete addTags.fiches.coproprietaire;

          // Si le tag projet n'est pas le lot
          //if(obj.projet !== 'lot') addTags.fiches.proprietaire = obj.proprietaire;
        }

        var objetsTags = createTags(objForCreateTags, withOldTag);
        addTags.tagsType = _.union(addTags.tagsType, objetsTags.tagsType);
        addTags.tags = _.union(addTags.tags, objetsTags.tags);

        // Création du tag actuel
        addTags.tag = newTag(obj.origine, typeTag, valeurIcs, objPortefeuille);
        // Ajoute le tag à la liste des tags
        addTags.tags.push(newTag(obj.origine));
        if(withOldTag) {
          // Ajoute le tag à la liste des tags
          addTags.tags.push(newTag(obj.origine, null, null, null, null, true));
        }

        retour.addTag = addTags;
      }
      else if(obj.hasOwnProperty('origine')){
        var tag = newTag(obj.origine, typeTag);
        addTags.tagCtrl.tags.push(tag);
        addTags.addTag = tag;
      }

      return retour;
    }

    /**
     * Permet de tester l'existence d'un tag dans une liste de tags
     * @param tags
     * @param tag
     * @param deleteTag (Permet de savoir si il faut delete le tag si trouvé, (utilisé avec "deleteTagFromTags" evite de dupliquer cette méthode))
     * @returns {*}
     */
    function tagExistIn(tags,tag,deleteTag){
      var retour = false;
      try{
        if(!_.isArray(tags)){
          throw new Error('Le 1er paramètre n\'est pas un array !');
        }

        if(tags.length){
          for(var i = 0 ; i < tags.length ; i++){

            if(angular.isObject(tag)){
              // Test du libelle tu tag
              if(tags[i].libelle === tag.libelle){

                // Si ce sont des tag type
                if(tags[i].hasOwnProperty('typeTag') && tag.hasOwnProperty('typeTag')){

                  // Si les libelle des typeTag sont les memes
                  if(tags[i].typeTag.libelle === tag.typeTag.libelle){

                    // Si il on tous les deux un attribut "valeurIcs"
                    if(tags[i].typeTag.hasOwnProperty('valeurIcs') && tag.typeTag.hasOwnProperty('valeurIcs')){

                      // Si les "valeursIcs" des deux sont des objet
                      if(_.isObject(tags[i].typeTag.valeurIcs) && _.isObject(tag.typeTag.valeurIcs)){

                        // Si "valeurIcs" à un attribut "type" et "valeur"
                        if(tags[i].typeTag.valeurIcs.hasOwnProperty('type') && tag.typeTag.valeurIcs.hasOwnProperty('type') &&
                          tags[i].typeTag.valeurIcs.hasOwnProperty('valeur') && tag.typeTag.valeurIcs.hasOwnProperty('valeur')){

                          // Si les attribut "type" et "valeur" sont identiques aux attributs "type et "valeur" du tag à supprimer
                          if((tags[i].typeTag.valeurIcs.type === tag.typeTag.valeurIcs.type) && (tags[i].typeTag.valeurIcs.valeur === tag.typeTag.valeurIcs.valeur)){

                            // Si il on tous les deux un attribut "idPortefeuille"
                            if(tags[i].typeTag.valeurIcs.hasOwnProperty('idPortefeuille') && tag.typeTag.valeurIcs.hasOwnProperty('idPortefeuille')){

                              // Si cet attribut "idPortefeuille" sont de même valeur
                              if(tags[i].typeTag.valeurIcs.idPortefeuille === tag.typeTag.valeurIcs.idPortefeuille){

                                retour = true;
                              }
                              // Si pas d'idPortefeuille dans les deux et que "valeur" et "type" sont quand même identique on supprime
                            }
                            else if(!tags[i].typeTag.valeurIcs.hasOwnProperty('idPortefeuille') && !tag.typeTag.valeurIcs.hasOwnProperty('idPortefeuille')){
                              retour = true;
                            }
                          }
                          // Si les attributs "type" et "valeur" n'existe dans aucun des deux et que "valeur" et "type" sont quand même identique on supprime
                        }
                        else if(!tags[i].typeTag.valeurIcs.hasOwnProperty('type') && !tag.typeTag.valeurIcs.hasOwnProperty('type') &&
                          !tags[i].typeTag.valeurIcs.hasOwnProperty('valeur') && !tag.typeTag.valeurIcs.hasOwnProperty('valeur')){
                          retour = true;
                        }


                      }
                      else{
                        // Si les valeur sont identiques
                        if(tags[i].typeTag.valeurIcs === tag.typeTag.valeurIcs){
                          retour = true;
                        }
                      }
                      // Si aucun des deux n'a d'attributs "valeurIcs" on supprime
                    }
                    else if(!tags[i].typeTag.hasOwnProperty('valeurIcs') && !tag.typeTag.hasOwnProperty('valeurIcs')){
                      retour = true;
                    }
                  }
                  // Aucun des deux n'a de typeTag
                }
                else if(!tags[i].hasOwnProperty('typeTag') && !tag.hasOwnProperty('typeTag')){
                  retour = true;
                }
              }
            }
            else if(_.isString(tag)){
              // Test du libelle tu tag
              if(_.isObject(tags[i]) && tags[i].hasOwnProperty('libelle') && tags[i].libelle === tag) {
                retour = true;
              }
              else if(_.isString(tags[i]) && tags[i] === tag) {
                retour = true;
              }

            }
            else{
              throw new Error('Le 2ème paramètre doit être un objet ou un string !');
            }

            if(retour){
              if(deleteTag){
                tags.splice(i,1);
                retour = tags;
              }

              break;
            }
          }
        }

        if(!retour && deleteTag){
          retour = tags;
        }

        return retour;

      }
      catch(err){
        console.log('[TagService.tagExistIn] Erreur : '+err.message);
        return retour;
      }
    }

    /**
     * Permet juste de savoir si un tag d'un certain type existe dans "tags"
     * @param tags
     * @param type
     * @returns {boolean}
     */
    function hasTagType(tags,type){
      var retour = false;
      var tagType = tags;

      // Si tags est un array
      if(_.isArray(tagType)){
        // Si cet array est différent de 0
        if(tagType.length){

          for(var i = 0 ; i < tagType.length ; i++){
            if(tagType[i].hasOwnProperty('typeTag')) {
              var TypeTag = tagType[i].typeTag;
              if (_.isObject(TypeTag)) {
                var lib = TypeTag.libelle;
                if(type===COLLAB_CONF.TAG_TYPE_ORIGINE){
                  if(UtilsService.contains(COLLAB_CONF.ARRAY_TAGS_ORIGINE,lib)){
                    retour = true;
                    break;
                  }
                }else{
                  if (lib === type) {
                    retour = true;
                    break;
                  }
                }

              }
            }
          }
        }
      }
      return retour;
    }

    /**
     * Permet de savoir si dans la liste des tags il y a un tag propriétaire
     * @param tags
     * @returns {boolean|*}
     */
    function hasTagTypeProprietaire(tags){
      return this.hasTagType(tags, COLLAB_CONF.TAG_TYPE_PROPRIETAIRE);
    }

    function getIconForTagType(typeTag,limit) {
      var classIcon = false;
      switch (typeTag) {
        case COLLAB_CONF.TAG_TYPE_PROJET:
          classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_PROJET];
          break;
        case COLLAB_CONF.TAG_TYPE_LOT:
          classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_LOT];
          break;
        case COLLAB_CONF.TAG_TYPE_PROPRIETAIRE:
          classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_PROPRIETAIRE];
          break;
        case COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE:
          classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE];
          break;
        case COLLAB_CONF.TAG_TYPE_LOCATAIRE:
          classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_LOCATAIRE];
          break;
        case COLLAB_CONF.TAG_TYPE_ORIGINE:
          classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_ORIGINE];
          break;
        case COLLAB_CONF.TAG_TYPE_IMMEUBLE:
          classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_IMMEUBLE];
          break;
        case COLLAB_CONF.TAG_TYPE_MANDAT:
          classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_MANDAT];
          break;
        case COLLAB_CONF.TAG_TYPE_PORTEFEUILLE:
          classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_PORTEFEUILLE];
          break;
        case COLLAB_CONF.TAG_NORMAL:
          classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_NORMAL];
          break;
        case COLLAB_CONF.TAG_TYPE_IMPORTANT:
          classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_IMPORTANT];
          break;
        default:
          if(UtilsService.contains(COLLAB_CONF.ARRAY_TAGS_ORIGINE, typeTag)){
            classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_ORIGINE];
          }
          else if(COLLAB_CONF.ICON_TAG.hasOwnProperty(typeTag)){
            classIcon = COLLAB_CONF.ICON_TAG[typeTag];
          }
          else {
            classIcon = (limit && limit > 1) ? COLLAB_CONF.ICON_TAG.Normaux : (!limit) ? COLLAB_CONF.ICON_TAG.Normaux : COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_NORMAL];
          }

      }

      return classIcon;
    }

    /**
     * Permet de récupérer la class css du badge d'un tag
     * @param tag
     * @param typeTag
     * @param classForced
     * @param xs
     * @returns {string}
     */
    function getClassBadge(tag, typeTag, classForced, xs){

      var type = typeTag;
      if(!typeTag || _.isNil(typeTag)){
        if(tag.hasOwnProperty('typeTag')) type = tag.typeTag.libelle;
        else type = COLLAB_CONF.TAG_NORMAL;
      }

      var badgeBorderedClass = 'badge-bordered';
      var badgeClass = '';
      switch(type){
        case COLLAB_CONF.TAG_TYPE_PROJET:
          badgeClass = 'badge-rouge-collabore ';
          break;
        //case COLLAB_CONF.TAG_TYPE_ORIGINE:
        //	badgeClass = 'alert-noir-collabore ';
        //	break;
        case COLLAB_CONF.TAG_NORMAL:
          badgeClass = 'badge-bleu-collabore ';
          break;
        case COLLAB_CONF.TAG_TYPE_STATUT:
          if(tag.isTagStatutTermine()) badgeClass = 'badge-success ';
          else if(tag.isTagStatutEnAttente()) badgeClass = 'badge-warning ';
          else if(tag.isTagStatutProbleme()) badgeClass = 'badge-danger ';
          else badgeClass = 'badge-default-collabore ';
          badgeBorderedClass = '';
          break;
        /*
              case 'Immeuble':
                  badgeClass = 'alert-orange-collabore ';
                  break;*/
        default:
          if(UtilsService.contains(COLLAB_CONF.ARRAY_TAGS_ORIGINE, type)){
            badgeClass = 'badge-noir-collabore ';
          }
          else {
            badgeClass = 'badge-default-collabore ';
          }
      }

      if(xs){badgeClass += 'badge-tag-xs ';}
      if(classForced) badgeClass = classForced;

      return badgeClass+badgeBorderedClass;
    }

    /**
     * Permet de récupérer le libelle d'un tag
     * @param tag
     * @returns {{type}|*|string}
     */
    function getLibelle(tag){
      if(_.isObject(tag)){
        if(tag.hasOwnProperty('libelle')) {
          if(UtilsService.startsWith(tag.libelle, '#')) {
            return angular.copy(tag.libelle.slice(1));
          }
          else if(UtilsService.endsWith(tag.libelle, '-.+')) {
            return angular.copy(tag.libelle.slice(0, -3));
          }
          return tag.libelle;
        }
        else if(tag.hasOwnProperty('type') && tag.type === 'F') {
          return '['+tag.codemetier+'] ' + tag.numero + ' | NoPerso: ' + tag.noperso;
        }
        else if(ctrl.typeTag === 'NoTag') {
          return tag;
        }
      }
      return '?';
    }

    /**
     * Recupère dans "tags" les tags différent du type demandé
     * @param type
     * @returns {Array}
     */
    function getTagsWithoutType(tags,type){
      var retour = [];
      var tagType = tags;

      // Si tags est un array
      if(_.isArray(tagType)){
        // Si cet array est différent de 0
        if(tagType.length){

          // Pour chaque tagsType
          angular.forEach(tagType,function(tag){

            if(tag.hasOwnProperty('typeTag')) {
              var TypeTag = tag.typeTag;

              if (_.isObject(TypeTag)) {
                var lib = TypeTag.libelle;

                if(type===COLLAB_CONF.TAG_TYPE_ORIGINE){
                  if(!UtilsService.contains(COLLAB_CONF.ARRAY_TAGS_ORIGINE,lib)){
                    retour.push(tag);
                  }
                }else{
                  if (lib !== type) {

                    retour.push(tag);
                  }
                }
              }
            }
          });
        }
      }
      return retour;
    }

    /**
     * Recupère un tag avec un certain type
     * @param tags
     * @param type
     * @returns {boolean}
     */
    function getTagForType(tags,type){
      var retour = false;
      var tagType = tags;

      // Si tags est un array
      if(_.isArray(tagType)){

        // Si cet array est différent de 0
        if(tagType.length){
          for(var i = 0 ; i < tagType.length ; i++){
            if(tagType[i].hasOwnProperty('typeTag')) {
              var TypeTag = tagType[i].typeTag;
              if (_.isObject(TypeTag)) {
                var lib = TypeTag.libelle;
                if(type===COLLAB_CONF.TAG_TYPE_ORIGINE){
                  if(UtilsService.contains(COLLAB_CONF.ARRAY_TAGS_ORIGINE,lib)){
                    retour = tagType[i];
                    break;
                  }
                }else{
                  if (lib === type) {
                    retour = tagType[i];
                    break;
                  }
                }
              }
            }
          }
        }
        return retour;
      }
    }

    /**
     * Récupère dans "tags" les tags typé avec le string demandé
     * @param type
     * @returns {Array}
     */
    function getTagsForType(tags,type){
      var retour = [];
      var tagType = tags;

      // Si tags est un array
      if(angular.isArray(tagType)){
        // Si cet array est différent de 0
        if(tagType.length){

          // Pour chaque tagsType
          angular.forEach(tagType,function(tag){

            if(tag.hasOwnProperty('typeTag')) {
              var TypeTag = tag.typeTag;

              if (angular.isObject(TypeTag)) {
                var lib = TypeTag.libelle;

                if(type===COLLAB_CONF.TAG_TYPE_ORIGINE){
                  if(UtilsService.contains(COLLAB_CONF.ARRAY_TAGS_ORIGINE,lib)){
                    retour.push(tag);
                  }
                }else{
                  if (lib === type) {

                    retour.push(tag);
                  }
                }
              }
            }
          });
        }
      }
      return retour;
    }

    /**
     * Recupère le tag de type origine dans la liste des tags
     * @param tags
     * @returns {boolean|*}
     */
    function getTagTypeOrigine(tags){
      return this.getTagForType(tags, COLLAB_CONF.TAG_TYPE_ORIGINE);
    }


    /**
     * Recupère le tag de type important dans la liste des tags
     * @param tags
     * @returns {boolean|*}
     */
    function getTagTypeImportant(tags){
      return this.getTagForType(tags, COLLAB_CONF.TAG_TYPE_IMPORTANT);
    }

    /**
     * Recupère le tag de type portefeuille dans la liste des tags
     * @param tags
     * @returns {boolean|*}
     */
    function getTagTypePortefeuille(tags){
      return this.getTagForType(tags, COLLAB_CONF.TAG_TYPE_PORTEFEUILLE);
    }

    /**
     * Recupère le tag de type immeuble dans la liste des tags
     * @param tags
     * @returns {boolean|*}
     */
    function getTagTypeImmeuble(tags){
      return this.getTagForType(tags, COLLAB_CONF.TAG_TYPE_IMMEUBLE);
    }

    /**
     * Recupère le tag de type proprietaire dans la liste des tags
     * @param tags
     * @returns {boolean|*}
     */
    function getTagTypeProprietaire(tags){
      return this.getTagForType(tags, COLLAB_CONF.TAG_TYPE_PROPRIETAIRE);
    }

    function getTagTypeLotMulticamp(tags){
      return this.getTagForType(tags, COLLAB_CONF.TAG_TYPE_LOT_MULTICAMP);
    }

    /**
     * Permet de supprimer un tag d'une liste de tags si il existe
     * @param tags
     * @param tag
     * @returns {Array}
     */
    function deleteTagFromTags(tags,tag){
      try{
        return this.tagExistIn(tags,tag,true);
      }
      catch(err){
        console.log('[TagsService.deleteTagFromTags] Erreur : '+err.message);
        return [];
      }
    }
  }
})();
