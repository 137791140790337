(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.ContenuEvenementDevis
   * @description
   * # ContenuEvenementDevis
   * Service in the collaboreApp.
   */

  angular.module('collaboreApp').factory('ContenuEvenementDevis', ContenuEvenementDevisModel);

  /** @ngInject */
  function ContenuEvenementDevisModel(COLLAB_CONF, $q, Contenu, Document, ContenuEvenementFacture, ContenuAjax, $injector, UtilsService) {

    var ContenuEvenementDevis = function () {
      Contenu.apply(this, arguments);
      this.typeContenu = COLLAB_CONF.TYPE_CONTENU_DEVIS_INTER;
      this.previsualisationMsg = this.description;
    };

    ContenuEvenementDevis.prototype = {

      model: 'ContenuEvenementDevis',
      isDevis: true,
      isIntervention: false,
      isFacture: false,

      getDestinataire: function(){
        if(_.isArray(this.getDestinataires()) && !_.isEmpty(this.getDestinataires())) {
          return this.getDestinataires()[0];
        }
        return null;
      },

      getNomFournisseur: function(){
        if(this.getPersoICS()) {
          return this.getPersoICS().getNom();
        }
        if(this.getDestinataire()) {
          return this.getDestinataire().getDefaultLibelle();
        }
        return 'NC';
      },

      // Groupement
      getGroupement: function () {
        return this.groupement;
      },
      setGroupement: function (string) {
        this.groupement = string;
      },

      // DescriptionDevis
      getDescriptionDevis: function () {
        return this.descriptionDevis;
      },
      setDescriptionDevis: function (string) {
        this.descriptionDevis = string;
      },

      // messageGravitants
      getMessageGravitants: function () {
        return this.messageGravitants;
      },
      setMessageGravitants: function (val) {
        this.messageGravitants = val;
      },

      // SendOneMailWithAllGravitants
      getSendOneMailWithAllGravitants: function(){
        return this.sendOneMailWithAllGravitants;
      },
      setSendOneMailWithAllGravitants: function(bool){
        this.sendOneMailWithAllGravitants = bool;
      },

      // DateEnvoi
      getDateEnvoi: function (format) {
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateEnvoi, format);
        }
        return this.dateEnvoi;
      },
      setDateEnvoi: function (date) {
        this.dateEnvoi = date;
      },
      getDateReceptionDevis: function (format) {
        return this.getDateEnvoi(format);
      },

      // DateReception
      getDateReception: function (format) {
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateReception, format);
        }
        return this.dateReception;
      },
      setDateReception: function (date) {
        this.dateReception = date;
      },

      // Array<Date> DateRelances
      getDateRelances: function () {
        return this.dateRelances;
      },
      setDateRelances: function (arrayDates) {
        if (!arrayDates) {
          arrayDates = [];
        } else {
          /*
           if(angular.isArray(arrayDates)){
           var dates = [];
           angular.forEach(arrayDates,function(date){
           console.log(date);
           //var newDate = new Date(date);

           var dateReformate = $filter('date')(date,'yyyy-MM-ddTHH:mm:ss');
           dates.push(dateReformate);
           });
           arrayDates = dates;
           }*/
        }
        this.dateRelances = arrayDates;
      },

      /**
       * Recupère la liste des dates de relance pour un devis
       * @returns {string}
       */
      getListeDateRelanceInHTML: function () {
        var retour = '';
        if (_.isArray(this.getDateRelances()) && !_.isEmpty(this.getDateRelances())) {
          for(var i = 0; i < this.getDateRelances().length; i++) {
            retour += UtilsService.parseDateHeure(this.getDateRelances()[i], 'D MMM YYYY à HH:mm') + '<br />';
          }
        }
        return retour;
      },

      /**
       * Recupère la dernière date de relance
       * @returns {*}
       */
      getDateLastRelance: function (format) {
        if(_.isNil(format)) format = 'D MMM YYYY';
        if (_.isArray(this.getDateRelances()) && !_.isEmpty(this.getDateRelances())) {
          return UtilsService.parseDateHeure(_.last(this.getDateRelances()), format);
        }
        return null;
      },

      // ContenuEvenementIntervention intervention
      getIntervention: function () {
        return this.intervention;
      },
      setIntervention: function (objectIntervention) {
        this.intervention = objectIntervention;
      },

      // Document Devis en PDF
      getDocumentDevis: function () {
        return this.documentDevis;
      },
      setDocumentDevis: function (document) {
        // Si c'est l'objet document on recupèrte le guid
        if (_.isObject(document) && !document.hasOwnProperty('isModel')) {
          document = new Document(document);
          document.setTypeDocument('devis');
        }

        this.documentDevis = document;
      },

      // listDocumentDevis
      getListDocumentDevis: function () {
        if(!angular.isArray(this.listDocumentDevis)) this.listDocumentDevis = [];
        return this.listDocumentDevis;
      },
      setListDocumentDevis: function (val) {
        var listDocument = [];
        var listDocumentTmp = [];
        if(angular.isArray(val)) listDocumentTmp = val;
        else if(angular.isObject(val)) listDocumentTmp.push(val);

        if(listDocumentTmp.length) {
          for(var i = 0; i < listDocumentTmp.length; i++) {
            if(angular.isObject(listDocumentTmp[i]) && !listDocumentTmp[i].hasOwnProperty('isModel')) listDocument.push(angular.copy(new Document(listDocumentTmp[i])));
          }
        }
        listDocumentTmp = null;
        this.listDocumentDevis = listDocument;
      },
      addDocumentDevis: function(val){
        if(angular.isObject(val)){

          if(!val.isModel) val = new Document(val);
          var listDocs = this.getListDocumentDevis();
          var exist = false;
          if(listDocs.length) {
            for(var i = 0; i < listDocs.length; i++){
              if(listDocs[i].getGuid() === val.getGuid()) {
                exist = true;
                break;
              }
            }
          }
          if(!exist) this.getListDocumentDevis().push(val);
        }
      },

      /**
       * Permet de modifie le document devis par le guid passé en paramètre
       * Si le param guid est un array de guid alors le premier guid est attribué à la demande et les autres duplique la demande
       * @param guid
       * return Objet ou Array
       */
      changeDocumentDevis: function (guid) {

        var DevisService = $injector.get('DevisService');
        var _this = this;
        var deferred = $q.defer();
        var params = {
          idDevis: _this.getIdContenu(),
          listGuidDocumentDevis: (_.isArray(guid)) ? guid : [guid]
        };
        //if(angular.isArray(guid)) params.listGuidDocumentDevis = guid;
        //else params.guidDocumentDevis = guid;

        DevisService.updateDocumentDevis(params)
          .then(function (devis) {
            _this.setDataFromList(devis);
            deferred.resolve(devis);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },



      deleteDocumentDevis: function () {

        var DevisService = $injector.get('DevisService');
        var _this = this;
        var deferred = $q.defer();

        DevisService.deleteDocumentDevis(_this.getIdContenu())
          .then(function (devis) {
            _this.setData(devis);
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },


      // Document Devis Signe en PDF
      getDocumentDevisSigne: function () {
        return this.documentDevisSigne;
      },
      setDocumentDevisSigne: function (document) {

        // Si c'est l'objet document on recupère le guid
        if (_.isObject(document) && !document.hasOwnProperty('isModel')) {
          document = new Document(document);
        }
        document.setTypeDocument('devis_signe');

        this.documentDevisSigne = document;
      },

      // DateDevisSigne
      getDateDevisSigne: function (format) {
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateDevisSigne, format);
        }
        return this.dateDevisSigne;
      },
      setDateDevisSigne: function (date) {
        this.dateDevisSigne = date;
      },


      /*
      // ContenuEvenementFacture facture
      getFactures: function () {
          if (!angular.isArray(this.factures)) {
              this.factures = [];
          }
          return this.factures;
      },
      setFactures: function (arrayFactures) {
          var arrayListeFacture = [];
          if (angular.isArray(arrayFactures)) {
              for (var i = 0; i < arrayFactures.length; i++) {
                  if (!arrayFactures[i].isModel) {
                      arrayFactures[i] = new ContenuEvenementFacture(arrayFactures[i])
                  }
                  arrayListeFacture.push(arrayFactures[i]);
              }
          }
          this.factures = arrayListeFacture;
      },
      addFacture: function (objectFacture) {
          var arrayListeFacture = this.getFactures();
          if (angular.isObject(objectFacture)) {
              if (!objectFacture.isModel) {
                  objectFacture = new ContenuEvenementFacture(objectFacture)
              }
              arrayListeFacture.push(objectFacture);
          }
          this.factures = arrayListeFacture;
      },*/

      // ContenuEvenementFacture facture
      getFacture: function () {
        /*
         if(!this.facture){
         this.facture = new ContenuEvenementFacture();
         }*/
        return this.facture;
      },
      setFacture: function (objectFacture) {
        if (objectFacture != null && !objectFacture.isModel) {
          objectFacture = new ContenuEvenementFacture(objectFacture);
        }
        this.facture = objectFacture;
      },
      deleteDocumentFromFacture: function (guid) {

        var _this = this;
        var deferred = $q.defer();

        var params = {
          idDevis: _this.getIdContenu(),
          guidDocumentFacture: guid
        };

        var DevisService = $injector.get('DevisService');
        DevisService.deleteDocumentFromFacture(params)
          .then(function (contenu) {
            _this.setData(contenu);
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },
      /*

       // PersoICs
       getPersoICS: function(){
       return this.persoICS;
       },
       setPersoICS: function(val,boolProxy){

       if(angular.isObject(val)){

       if(!val.isModel){

       val = new PersoIcs(val,boolProxy);
       }
       }
       this.persoICS = val;
       },*/

      //******** Conseil Syndical **************/

      // AcceptedByCS
      getAcceptedByCS: function () {
        return this.acceptedByCS;
      },
      setAcceptedByCS: function (bool) {
        this.acceptedByCS = bool;
      },

      // RefusedByCS
      getRefusedByCS: function () {
        return this.refusedByCS;
      },
      setRefusedByCS: function (bool) {
        this.refusedByCS = bool;
      },

      // DateRefusedByCS
      getDateRefusedByCS: function () {
        return this.dateRefusedByCS;
      },
      setDateRefusedByCS: function (date) {
        this.dateRefusedByCS = date;
      },

      // GroupeValidationCS
      getGroupeValidationCS: function () {
        return this.groupeValidationCS;
      },
      setGroupeValidationCS: function (val) {
        this.groupeValidationCS = val;
      },

      // DateValidationCS
      getDateValidationCS: function () {
        return this.dateValidationCS;
      },
      setDateValidationCS: function (date) {
        this.dateValidationCS = date;
      },


      //******** Syndic **************/

      /**
       * Raccourci pour avoir la date de validation du devis
       * @returns {*}
       */
      getDateAcceptation: function(format){
        if(this.isAccepte() && this.getDateValidationSyndic()) return this.getDateValidationSyndic(format);
      },

      /**
       * Raccourci pour avoir la date de refus du devis
       * @returns {*}
       */
      getDateRefus: function(format){
        if(this.isRefuse() && this.getDateRefusedBySyndic()) return this.getDateRefusedBySyndic(format);
      },

      // AcceptedBySyndic
      getAcceptedBySyndic: function () {
        return this.acceptedBySyndic;
      },
      setAcceptedBySyndic: function (bool) {
        this.acceptedBySyndic = bool;
      },

      // RefusedBySyndic
      getRefusedBySyndic: function () {
        return this.refusedBySyndic;
      },
      setRefusedBySyndic: function (bool) {
        this.refusedBySyndic = bool;
      },

      // DateRefusedBySyndic
      getDateRefusedBySyndic: function (format) {
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateRefusedBySyndic, format);
        }
        return this.dateRefusedBySyndic;
      },
      setDateRefusedBySyndic: function (date) {
        this.dateRefusedBySyndic = date;
      },

      // GroupeValidationSyndic
      getGroupeValidationSyndic: function () {
        return this.groupeValidationSyndic;
      },
      setGroupeValidationSyndic: function (val) {
        this.groupeValidationSyndic = val;
      },

      // DateValidationSyndic
      getDateValidationSyndic: function (format) {
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateValidationSyndic, format);
        }
        return this.dateValidationSyndic;
      },
      setDateValidationSyndic: function (date) {
        this.dateValidationSyndic = date;
      },

      // demandeDevisActive
      getDemandeDevisActive: function () {
        return this.demandeDevisActive;
      },
      setDemandeDevisActive: function (bool) {
        this.demandeDevisActive = bool;
      },


      isDemandeIntervention: function(){
        if(!this.getDemandeDevisActive()) return true;
        return false;
      },

      // vuParFournisseur
      getVuParFournisseur: function () {
        return this.vuParFournisseur;
      },
      setVuParFournisseur: function (bool) {
        this.vuParFournisseur = bool;
      },

      /****** Proxy ***********/

      // ProxyIntervention
      getProxyIntervention: function () {
        return this.proxyIntervention;
      },
      setProxyIntervention: function (proxyIntervention) {
        this.proxyIntervention = proxyIntervention;
      },

      // ProxyFacture
      getProxyFacture: function () {
        return this.proxyFacture;
      },
      setProxyFacture: function (proxyFacture) {
        this.proxyFacture = proxyFacture;
      },

      /*
       // ProxyPersoICS
       getProxyPersoICS: function(){
       return this.proxyPersoICS;
       },
       setProxyPersoICS: function(val){
       this.proxyPersoICS = val;
       },*/

      /*
       changeMontant: function(val){
       this.setMontant(val);
       this.setUpdate(true);
       },*/
      changeMontant: function (val) {
        var _this = this;
        var deferred = $q.defer();
        var params = {
          idDevis: _this.getIdContenu(),
          montant: val
        };
        var DevisService = $injector.get('DevisService');
        DevisService.updateFieldByField(params)
          .then(function (devis) {
            _this.setMontant(devis.getMontant());
            //_this.setData(devis);
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      /*
       accepter: function(){
       this.setAcceptedBySyndic(true);
       this.setRefusedBySyndic(false);
       this.setUpdate(true);
       },*/

      accepterDevisBySyndic: function (sendMailWhenAcceptOrDeclineDevis) {
        var _this = this;
        var deferred = $q.defer();
        var params = {
          idDevis: _this.getIdContenu(),
          sendMailWhenAcceptOrDeclineDevis: sendMailWhenAcceptOrDeclineDevis
        };
        var idTopic = _this.getIdTopic(); // Hack car le retour de la servlet est l'ancien system (Resultat)
        var DevisService = $injector.get('DevisService');
        DevisService.accepteDevisBySyndic(params)
          .then(function (devis) {

            _this.setAcceptedByCS(devis.getAcceptedByCS());
            _this.setAcceptedBySyndic(devis.getAcceptedBySyndic());
            _this.setDateValidationSyndic(devis.getDateValidationSyndic());
            _this.setDateValidationCS(devis.getDateValidationCS());
            _this.setDateModification(devis.getDateModification());

            //_this.setData(devis);
            // Hack car le retour de la servlet est l'ancien system (Resultat)
            if(!_.isNil(idTopic) && _.isNil(devis.idTopic)){
              _this.setIdTopic(idTopic);
            }
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      /*
       refuser: function(){
       this.setAcceptedBySyndic(false);
       this.setRefusedBySyndic(true);
       this.setUpdate(true);
       },*/
      refuserDevisBySyndic: function (sendMailWhenAcceptOrDeclineDevis) {

        var _this = this;
        var deferred = $q.defer();
        var params = {
          idDevis: _this.getIdContenu(),
          sendMailWhenAcceptOrDeclineDevis: sendMailWhenAcceptOrDeclineDevis
        };
        var idTopic = _this.getIdTopic(); // Hack car le retour de la servlet est l'ancien system (Resultat)
        var DevisService = $injector.get('DevisService');
        DevisService.refuseDevisBySyndic(params)
          .then(function (devis) {

            _this.setRefusedByCS(devis.getRefusedByCS());
            _this.setRefusedBySyndic(devis.getRefusedBySyndic());
            _this.setDateRefusedByCS(devis.getDateRefusedByCS());
            _this.setDateRefusedBySyndic(devis.getDateRefusedBySyndic());
            _this.setDateModification(devis.getDateModification());

            //_this.setData(devis);
            // Hack car le retour de la servlet est l'ancien system (Resultat)
            if(!_.isNil(idTopic) && _.isNil(devis.idTopic)){
              _this.setIdTopic(idTopic);
            }
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      /*
       relancer: function(){
       var dateRelances = this.getDateRelances();
       //var dateNowReformate = $filter('date')(new Date(),'yyyy-MM-ddTHH:mm:ss');
       dateRelances.push(new Date());
       this.setDateRelances(dateRelances);
       this.setUpdate(true);
       },*/

      relancer: function () {
        var DevisService = $injector.get('DevisService');
        var _this = this;
        var deferred = $q.defer();

        DevisService.doRelance(_this.getIdContenu())
          .then(function (devis) {
            _this.setDateRelances(devis.getDateRelances());
            _this.setDateModification(devis.getDateModification());
            //_this.hydrate(devis);
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      /**
       * Permet de dupliquer un devis avec une nouvelle description ou non
       * @param newDesc
       */
      dupliquer: function (obj) {
        var DevisService = $injector.get('DevisService');
        var _this = this;
        var deferred = $q.defer();

        DevisService.duplicateDevis(_this.getIdContenu(), obj)
          .then(function (devis) {
            //_this.hydrate(devis);
            deferred.resolve(devis);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      hasDevis: function () {
        if (this.getDocumentDevis() && this.getDocumentDevis() !== '') {
          return true;
        }
        return false;
      },
      hasDevisSigne: function () {
        if (this.getDocumentDevisSigne() && this.getDocumentDevisSigne() !== '') {
          return true;
        }
        return false;
      },
      hasDemandeDevis: function () {
        var retour = false;
        var listeDocuments = this.getListeDocument();
        if (listeDocuments.length) {
          for (var i = 0; i < listeDocuments.length; i++) {
            var document = listeDocuments[i];
            if (document.isDemandeDevis()) {
              retour = document;
              break;
            }
          }
        }
        return retour;
      },
      hasDemandeIntervention: function () {
        var retour = false;
        var listeDocuments = this.getListeDocument();
        if (listeDocuments.length) {
          for (var i = 0; i < listeDocuments.length; i++) {
            var document = listeDocuments[i];
            if (document.isDemandeIntervention()) {
              retour = document;
              break;
            }
          }
        }
        return retour;
      },
      hasDocumentDemande: function () {
        var retour = false;
        var listeDocuments = this.getListeDocument();
        if (listeDocuments.length) {
          for (var i = 0; i < listeDocuments.length; i++) {
            var document = listeDocuments[i];
            if (document.isDemandeIntervention() || document.isDemandeDevis()) {
              retour = document;
              break;
            }
          }
        }
        return retour;
      },
      hasFacture: function () {
        if (_.isObject(this.getFacture())) {
          return true;
        }
        return false;
      },
      hasFactureTraite: function () {
        return (_.isNil(this.getDateTraitementFacture())) ? false : true;
      },
      hasDocumentAttestationTvaSimple: function () {
        var retour = false;
        var listeDocuments = this.getListeDocument();
        if (listeDocuments.length) {
          for (var i = 0; i < listeDocuments.length; i++) {
            var document = listeDocuments[i];
            if (document.isAttestationTvaSimple()) {
              retour = document;
              break;
            }
          }
        }
        return retour;
      },

      getDateReceptionFacture: function (format) {
        if(this.hasFacture()) {
          return this.getFacture().getDateCreation(format);
        }
        return null;
      },

      /**
       * Permet de récupérer la date de traitement de la facture
       * @param format
       * @returns {*}
       */
      getDateTraitementFacture: function (format) {
        var dateTraitement = null;
        if (_.isObject(this.getFacture())){
          // Si il y a une facture et qu'il y a une liste de document comptable
          if (this.getListeDocumentComptable().length) {
            for(var i = 0; i < this.getListeDocumentComptable().length; i++) {
              // Si il y a un document comptable envoyé dans comfact alors on considère la facture comme traité
              // TODO: Peut être ajouter la facture dans le document source du document généré ? Mais problème il peut y avoir plusieur doc facture dans listeDocument de la facture
              if(this.getListeDocumentComptable()[i].isSentToComfactfacture()){
                dateTraitement = this.getListeDocumentComptable()[i].getDateSentToComfactfacture(format);
                break;
              }
            }
          }
          // Si pas de document comptable dans le devis alors on va regarder si la facture est traitée
          if(_.isNil(dateTraitement) && this.getFacture().isTraite()) {
            dateTraitement = this.getFacture().getDateSentToComfact(format);
          }
        }
        return dateTraitement;
      },

      /**
       * Recupère le document facture qui est pour le moment le prermier document de la liste des documents
       * @returns {*}
       */
      getDocumentFacture: function(){
        if(this.hasFacture()){
          return this.getFacture().getDocumentFacture();
        }
        return false;
      },



      isWaiting: function () {
        if (!this.getAcceptedBySyndic() && !this.getRefusedBySyndic() && this.isCreated() && !this.isCloture()) {
          return true;
        }
        return false;
      },

      isAccepte: function () {
        if (this.getAcceptedBySyndic()) {
          return true;
        }
        return false;
      },

      isRefuse: function () {
        if (this.getRefusedBySyndic()) {
          return true;
        }
        return false;
      },

      isSigne: function () {
        if (this.hasDevisSigne()) {
          return true;
        }
        return false;
      },

      canAddDevis: function(){
        if(!this.isCloture() && this.isContenuDevis() && !this.hasDevis()){
          return true;
        }
        return false;
      },

      canAddFacture: function(){
        if(!this.isCloture() &&
          (
            (
              this.isContenuDevis() && this.hasDevis() && this.isAccepte() && this.isSigne()
            ) ||
            this.isContenuIntervention()
          ) &&
          !this.hasFacture()){
          return true;
        }
        return false;
      },

      getDemandeDevis: function () {
        return this.hasDemandeDevis();
      },

      getDemandeIntervention: function () {
        return this.hasDemandeIntervention();
      },

      getDocumentDemande: function () {
        return this.hasDocumentDemande();
      },

      getDocumentAttestationTvaSimple: function () {
        return this.hasDocumentAttestationTvaSimple();
      },

      generateDemande: function () {

        var _this = this;
        var deferred = $q.defer();

        var DevisService = $injector.get('DevisService');
        DevisService.regenerateDocument(_this.getIdContenu(), _this.getDemandeOptions())
          .then(function (devis) {
            _this.setListeDocument(devis.getListeDocument());
            _this.setDateModification(devis.getDateModification());
            //_this.hydrate(devis);
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },
      /*
      generateDemande: function (Topic) {
          var deferred = $q.defer();
          ContenuAjax.generateDemande({idContenu: this.getIdContenu()}, function (json) {
              var retour = false;
              if (json.nb !== '0') {
                  if (json.Contenus[0]) {
                      retour = Topic.updateContenu(json.Contenus[0]);
                  }
              }
              deferred.resolve(retour);
          }, function (error) {
              var msgErreur = 'Erreur (' + error.status + ') : ' + error.statusText;
              deferred.reject('Problème avec ContenuEvenementDevis.generateDemande(), ' + msgErreur);
          });
          return deferred.promise;
      },

      generateAttestationTvaSimple: function (Topic) {
          var deferred = $q.defer();
          ContenuAjax.generateAttestationTvaSimple({idContenu: this.getIdContenu()}, function (json) {
              var retour = false;
              if (json.nb !== '0') {
                  if (json.Contenus[0]) {
                      retour = Topic.updateContenu(json.Contenus[0]);
                  }
              }
              deferred.resolve(retour);
          }, function (error) {
              var msgErreur = 'Erreur (' + error.status + ') : ' + error.statusText;
              deferred.reject('Problème avec ContenuEvenementDevis.generateAttestationTvaSimple(), ' + msgErreur);
          });
          return deferred.promise;
      },*/

      generateAttestationTvaSimple: function () {

        var _this = this;
        var deferred = $q.defer();

        var DevisService = $injector.get('DevisService');
        DevisService.regenerateDocumentAttestationTvaSimple(_this.getIdContenu())
          .then(function (devis) {
            _this.setListeDocument(devis.getListeDocument());
            _this.setDateModification(devis.getDateModification());
            //_this.hydrate(devis);
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      /**
       * Update un devis par proxyDevis
       */
      updateDevisWithProxyDevis: function (event) {

        var DevisService = $injector.get('DevisService');
        var ProxyContenuDevis = $injector.get('ProxyContenuDevis');
        var _this = this;
        var deferred = $q.defer();
        var params = {
          idDevis: _this.getIdContenu(),
          json: new ProxyContenuDevis(_this).toJson()
        };
        DevisService.updateDevisWithProxyDevis(params)
          .then(function (listDevis) {
            // Si il y a un evenement en paramètre alors on utilise la méthode "addDevis" pour ajouter/update les devis retournés
            if(event) event.addDevis(listDevis);
            // Si pas d'event alors set la nouvelles data du devis
            else _this.setDataFromList(listDevis);

            deferred.resolve(listDevis);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      retryDevis: function () {
        var DevisService = $injector.get('DevisService');
        var deferred = $q.defer();
        DevisService.retryDevis(this.getIdContenu())
          .then(function (success) {
            deferred.resolve(success);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      getEtatDemande: function(){
        var retour = null;
        if(!this.isCloture()) {

          // Si demande de devis
          if(this.getDemandeDevisActive()) {

            // Si la demande est refusée
            if(this.isRefuse()){
              retour = ContenuEvenementDevis.ETATS.ATTENTE_VALIDATION_DEVIS;
              if(UtilsService.getParametrage('validDevisWhenSignIt')){
                retour = ContenuEvenementDevis.ETATS.ATTENTE_SIGNATURE_REFUS_DEVIS;
              }
            }
            // Si demande en attente (Pas validé/refusé)
            else if(this.isWaiting() || this.isWaitingFromWebsocket()) {

              // Si la demande a un document devis alors elle est en attente de validation/refus
              if(this.hasDevis()) {
                retour = ContenuEvenementDevis.ETATS.ATTENTE_VALIDATION_DEVIS;
                if(UtilsService.getParametrage('validDevisWhenSignIt')){
                  retour = ContenuEvenementDevis.ETATS.ATTENTE_SIGNATURE_REFUS_DEVIS;
                }
              }
              // Si la demande n'a pas de document devis
              else {

                retour = ContenuEvenementDevis.ETATS.ATTENTE_DEVIS;

              }
            }
            // Si la demande n'est pas en attente et signée
            /*else if(this.isSigne()){

                // Si la demande de devis est acceptée
                if(this.isAccepte()) {

                    // Si le demande de devis a une facture alors elle est en attente de traitement
                    if(this.hasFacture()) {
                        // Si facture non traitée
                        if(!this.getFacture().isTraite()) {
                            retour = ContenuEvenementDevis.ETATS.FACTURE_RECU_NON_TRAITE;
                        }
                        else {
                            retour = ContenuEvenementDevis.ETATS.CLOTURE;
                        }
                    }
                    // Si la demande de devis n'a pas de facture
                    else {
                        retour = ContenuEvenementDevis.ETATS.EN_COURS;
                    }
                }
            }*/
            // Si la demande n'est pas en attente et pas signée alors en attente de signature
            else if(!this.isSigne()) {
              retour = ContenuEvenementDevis.ETATS.ATTENTE_SIGNATURE_DEVIS;
            }
          }
          /*
          // Si demande d'intervention
          else {
              // Si la demande a une facture alors elle est en attente de traitement
              if(this.hasFacture()) {
                  // Si facture non traitée
                  if(!this.getFacture().isTraite()) {
                      retour = ContenuEvenementDevis.ETATS.FACTURE_RECU_NON_TRAITE;
                  }
                  else {
                      retour = ContenuEvenementDevis.ETATS.CLOTURE;
                  }

              }
              // Si la demande d'intervention n'a pas de facture alors est en cours
              else {
                  retour = ContenuEvenementDevis.ETATS.EN_COURS;
              }
          }*/

          // Si la demande de devis est acceptée ou demande d'intervention
          if((this.getDemandeDevisActive() && this.isSigne() && this.isAccepte()) || !this.getDemandeDevisActive()) {

            // Si le demande a une facture alors elle est en attente de traitement
            if(this.hasFacture()) {
              // Si facture non traitée
              if(!this.getFacture().isTraite() && !this.hasFactureTraite()) {
                retour = ContenuEvenementDevis.ETATS.FACTURE_RECU_NON_TRAITE;
              }
              else {
                retour = ContenuEvenementDevis.ETATS.CLOTURE;
              }
            }
            // Si la demande n'a pas de facture
            else {
              retour = ContenuEvenementDevis.ETATS.EN_COURS;
            }
          }
        }
        else {
          retour = ContenuEvenementDevis.ETATS.CLOTURE;
        }
        return retour;
      },

      getLibelleEtatDemande: function(){
        var etat = this.getEtatDemande();
        return (!_.isNil(etat)) ? etat.LIBELLE : null;
      },

      getTypeEtatDemande: function(){
        var etat = this.getEtatDemande();
        return (!_.isNil(etat)) ? etat.TYPE : null;
      }
    };

    ContenuEvenementDevis.LIBELLE_ETATS = {
      ATTENTE_DEVIS: 'Devis en attentes de réception',
      ATTENTE_VALIDATION_DEVIS: 'Devis en attentes de validation ou refusés',
      ATTENTE_SIGNATURE_REFUS_DEVIS: 'Devis en attentes de signature ou refusés',
      ATTENTE_SIGNATURE_DEVIS: 'Devis validés en attentes de signature',
      EN_COURS: 'Interventions en cours',
      FACTURE_RECU_NON_TRAITE: 'Factures reçues attente de traitement',
      CLOTURE: 'Demandes traitées ou cloturées',
    };

    ContenuEvenementDevis.TYPE_ETATS = {
      ATTENTE_DEVIS: 'attenteDevis',
      ATTENTE_VALIDATION_DEVIS: 'attenteValidationDevis',
      ATTENTE_SIGNATURE_REFUS_DEVIS: 'attenteValidationSignatureDevis',
      ATTENTE_SIGNATURE_DEVIS: 'attenteSignatureDevis',
      EN_COURS: 'devisInterEnCours',
      FACTURE_RECU_NON_TRAITE: 'factureRecuNonTraite',
      CLOTURE: 'demandeCloture',
    };

    ContenuEvenementDevis.ETATS = {
      ATTENTE_DEVIS: {
        LIBELLE: ContenuEvenementDevis.LIBELLE_ETATS.ATTENTE_DEVIS,
        TYPE: ContenuEvenementDevis.TYPE_ETATS.ATTENTE_DEVIS
      },
      ATTENTE_VALIDATION_DEVIS: {
        LIBELLE: ContenuEvenementDevis.LIBELLE_ETATS.ATTENTE_VALIDATION_DEVIS,
        TYPE: ContenuEvenementDevis.TYPE_ETATS.ATTENTE_VALIDATION_DEVIS
      },
      ATTENTE_SIGNATURE_REFUS_DEVIS: {
        LIBELLE: ContenuEvenementDevis.LIBELLE_ETATS.ATTENTE_SIGNATURE_REFUS_DEVIS,
        TYPE: ContenuEvenementDevis.TYPE_ETATS.ATTENTE_SIGNATURE_REFUS_DEVIS
      },
      ATTENTE_SIGNATURE_DEVIS: {
        LIBELLE: ContenuEvenementDevis.LIBELLE_ETATS.ATTENTE_SIGNATURE_DEVIS,
        TYPE: ContenuEvenementDevis.TYPE_ETATS.ATTENTE_SIGNATURE_DEVIS
      },
      EN_COURS: {
        LIBELLE: ContenuEvenementDevis.LIBELLE_ETATS.EN_COURS,
        TYPE: ContenuEvenementDevis.TYPE_ETATS.EN_COURS
      },
      FACTURE_RECU_NON_TRAITE: {
        LIBELLE: ContenuEvenementDevis.LIBELLE_ETATS.FACTURE_RECU_NON_TRAITE,
        TYPE: ContenuEvenementDevis.TYPE_ETATS.FACTURE_RECU_NON_TRAITE
      },
      CLOTURE: {
        LIBELLE: ContenuEvenementDevis.LIBELLE_ETATS.CLOTURE,
        TYPE: ContenuEvenementDevis.TYPE_ETATS.CLOTURE
      }
    };

    // Copie le prototype de Contenu dans le prototype de ContenuEvenementDevis
    // ContenuEvenementDevis hérite donc des methodes de Contenu
    angular.extend(ContenuEvenementDevis.prototype, Contenu.prototype);

    return ContenuEvenementDevis;
  }
})();
