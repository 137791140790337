(function () {
    'use strict';
    /**
     * @ngdoc factory
     * @name collaboreApp.ProxyContenuContrat
     * @description
     * # ProxyContenuContrat
     */

    angular.module('collaboreApp').factory('ProxyContenuContrat', ProxyContenuContratModel);

    /** @ngInject */
    function ProxyContenuContratModel(ProxyContenu, ProxyPersoIcs, ProxyContenuFacture, ProxyDestinataire) {

        function ProxyContenuContrat(objetData) {
            ProxyContenu.apply(this, arguments);
            this.hydrate(objetData);
            this.typeContenu = 7;
        }

        ProxyContenuContrat.prototype = {

            // idEvenement
            getIdEvenement: function () {
                return this.idEvenement;
            },
            setIdEvenement: function (val) {
                this.idEvenement = val;
            },

            // tagFournisseur
            getTagFournisseur: function () {
                return this.tagFournisseur;
            },
            setTagFournisseur: function (val) {
                this.tagFournisseur = val;
            },

            // montant
            getMontant: function () {
                return this.montant;
            },
            setMontant: function (val) {
                this.montant = val;
            },

            // groupement
            getGroupement: function () {
                return this.groupement;
            },
            setGroupement: function (val) {
                this.groupement = val;
            },

            // TODO : Dois les garder pour le moment pour l'ancien enregistrement
            // dateRelances
          /*
            getDateRelances: function () {
                return this.dateRelances;
            },
            setDateRelances: function (val) {
                this.dateRelances = val;
            },*/

            // listDocumentContratFournisseurSigne
            getListDocumentContratFournisseurSigne: function () {
                return this.listDocumentContratFournisseurSigne;
            },
            setListDocumentContratFournisseurSigne: function (val) {
                var listDocument = [];
                if(angular.isArray(val) && val.length) {
                    for(var i = 0; i < val.length; i++) {
                        if(angular.isObject(val[i])) listDocument.push(val[i].guid);
                        else if(angular.isString(val[i])) listDocument.push(val[i]);
                    }
                }
                else if(angular.isObject(val)) listDocument.push(val.guid);
                // Sinon si c'est déjà le guid on le récupère
                else if(angular.isString(val)) listDocument.push(val);

                this.listDocumentContratFournisseurSigne = listDocument;
            },

            // listDocumentContratAgenceSigne
            getListDocumentContratAgenceSigne: function () {
                return this.listDocumentContratAgenceSigne;
            },
            setListDocumentContratAgenceSigne: function (val) {
                var listDocument = [];
                if(angular.isArray(val) && val.length) {
                    for(var i = 0; i < val.length; i++) {
                        if(angular.isObject(val[i])) listDocument.push(val[i].guid);
                        else if(angular.isString(val[i])) listDocument.push(val[i]);
                    }
                }
                else if(angular.isObject(val)) listDocument.push(val.guid);
                // Sinon si c'est déjà le guid on le récupère
                else if(angular.isString(val)) listDocument.push(val);

                this.listDocumentContratAgenceSigne = listDocument;
            },

            // persoICS
            getPersoICS: function () {
                return this.persoICS;
            },
            setPersoICS: function (val) {
                if (angular.isObject(val)) {
                    this.persoICS = new ProxyPersoIcs(val);
                }
            },

            // sendDocumentDemandeWhenCreateContrat
            getSendDocumentDemandeWhenCreateContrat: function () {
                return this.sendDocumentDemandeWhenCreateContrat;
            },
            setSendDocumentDemandeWhenCreateContrat: function (val) {
                this.sendDocumentDemandeWhenCreateContrat = val;
            },

            // sendMailWhenAcceptOrDeclineContrat
            getSendMailWhenAcceptOrDeclineContrat: function () {
                return this.sendMailWhenAcceptOrDeclineContrat;
            },
            setSendMailWhenAcceptOrDeclineContrat: function (val) {
                this.sendMailWhenAcceptOrDeclineContrat= val;
            }
        };

        angular.extend(ProxyContenuContrat.prototype, ProxyContenu.prototype);

        return ProxyContenuContrat;
    }
})();
