(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.GedLink
   * @description
   * # GedLink
   * Service in the collaboreApp.
   */

  angular.module('collaboreApp').factory('GedLink', GedLinkModel);

  /** @ngInject */
  function GedLinkModel(Main, UtilsService) {

    function GedLink(objetData) {
      Main.apply(this, arguments);
      this.hydrate(objetData);
    }

    GedLink.prototype = {

      model: 'GedLink',
      isGedLink: true,

      // Id GedLink
      getIdGedLink: function(){
        return this.idGedLink;
      },
      setIdGedLink: function(id){
        this.idGedLink = id;
      },

      // Nom
      getNom: function(){
        return this.nom;
      },
      setNom: function(nom){
        this.nom = nom;
      },

      // Guid
      getGuid: function(){
        return this.guid;
      },
      setGuid: function(guid){
        this.guid = guid;
      },

      // GuidGed
      getGuidGed: function(){
        return this.guidGed;
      },
      setGuidGed: function(guidGed){
        this.guidGed = guidGed;
      },

      // DateSentToGed
      getDateSentToGed: function (format) {
        if(!_.isNil(format)) {
          if(!_.isString(format) && format) format = null;
          return UtilsService.parseDate(this.dateSentToGed, format);
        }
        return this.dateSentToGed;
      },
      getDateSentToGedAgo: function(){
        var retour = this.dateSentToGed;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },
      setDateSentToGed: function (date) {
        this.dateSentToGed = date;
      },

      // pathGed
      getPathGed: function(){
        return this.pathGed;
      },
      setPathGed: function(pathGed){
        this.pathGed = pathGed;
      },
      getShortenedPathGed: function(){
        return GedLink.shortenPathGed(this.getPathGed());
      },

      // isRealDoc
      getIsRealDoc: function(){
        return this.isRealDoc;
      },
      setIsRealDoc: function(isRealDoc){
        this.isRealDoc = isRealDoc;
      },

      // idContenu
      getIdContenu: function(){
        return this.idContenu;
      },
      setIdContenu: function(idContenu){
        this.idContenu = idContenu;
      },

      // idTopic
      getIdTopic: function(){
        return this.idTopic;
      },
      setIdTopic: function(idTopic){
        this.idTopic = idTopic;
      },

      // portefeuille
      getPortefeuille: function () {
        return this.portefeuille;
      },
      setPortefeuille: function (val) {
        if(_.isObject(val) && !val.hasOwnProperty('isModel')){
          var Portefeuille = Main.$injector.get('Portefeuille');
          val = new Portefeuille(val);
          Portefeuille = null;
        }
        this.portefeuille = val;
      },

      /*
      // sentInCurrentContenu
      getSentInCurrentContenu: function(){
        return this.sentInCurrentContenu;
      },
      setSentInCurrentContenu: function(val){
        this.sentInCurrentContenu = val;
      },
      isSentInGed: function(){
        return (this.getSentInCurrentContenu() != null && this.getSentInCurrentContenu()) ;
      },*/

    };

    GedLink.shortenPathGed = function(chemin){
      var retour = chemin;
      var splitBy = null;
      if(chemin.contains("gerance")) splitBy = "gerance";
      else if(chemin.contains("syndic")) splitBy = "syndic";

      if(!_.isNil(splitBy)){
        var tabs = _.split(chemin, splitBy);
        if(_.isArray(tabs) && tabs.length == 2){
          retour = splitBy + tabs[1];
        }
      }
      return retour;
    };

    angular.extend(GedLink.prototype, Main.prototype);

    return GedLink;

  }
})();
