(function() {

    'use strict';

    /**
     * @desc Componsant pour choisir un destinataire
     * @example <choose-gravitants></choose-gravitants>
     */

    angular
        .module('collaboreApp')
        .component('chooseGravitants',{
            templateUrl: 'app/topics/components/choose-gravitants/choose-gravitants.component.html',
            bindings : {
                tagsType: '<',
                selectGravitant: '<',
                displayLibelle: '<',
                onInit: '&',
                onEmit: '&'
            },
            controllerAs: 'choosegravitantsctrl',
            controller: ChooseGravitantsController
        });

    /*@ngInject*/
    function ChooseGravitantsController($q, COLLAB_CONF, ModalsService, UtilsService, TagsService, GroupeService){

        var ctrl = this;
        ctrl.getIconTag = getIconTag;
        ctrl.onSelectGravitant = onSelectGravitant;
        ctrl.selectResult = selectResult;
        ctrl.resetResult = resetResult;

        ctrl.$onInit = function() {
            try {
                if(!ctrl.tagsType) throw new Error('Pas de tags typé');
                init(ctrl.tagsType);
            }
            catch(e) {
                ModalsService.alertErreur(e.message);
            }
        };

        /**
         * Lors de changements depuis le parent
         * @param changes
         */
        ctrl.$onChanges = function (changes) {
            if(angular.isObject(changes)) {
                if(angular.isObject(changes.tagsType) && !changes.tagsType.isFirstChange()) {
                    ctrl.tagsType = changes.tagsType.currentValue;
                    init(changes.tagsType.currentValue);
                }
            }
        };

        ctrl.$onDestroy = function(){
        };


        /**
         * Initlisation
         */
        function init(evenementTagsType){
          if(_.isNil(ctrl.displayLibelle)) ctrl.displayLibelle = true;

            initDefaultVarCommunicationGravitants();
            if(evenementTagsType) {
                var tagsType = [];

                // Recup le tag immeuble et l'ajoute au tableau des tags type
                var tagImmeuble = TagsService.getTagTypeImmeuble(evenementTagsType);
                if (tagImmeuble) tagsType.push(tagImmeuble);


                // Recup tout les tags
                angular.forEach(ctrl.blocsDestinatairesDemande, function (obj, type) {
                    if (!obj.noTagType) {
                        var tag = TagsService.getTagForType(evenementTagsType, type);
                        if (tag) tagsType.push(tag);
                    }
                });

                var idPortefeuille, portefeuille;
                if (UtilsService.getCurrentUser().hasListePortefeuille()) {
                    var portefeuilles = UtilsService.getCurrentUser().getListePortefeuille();
                    portefeuille = portefeuilles[0];
                    idPortefeuille = portefeuilles[0].getIdPortefeuille();
                    portefeuilles = null;
                }

                var blockSelected = null;

                var promises = [];
                // Parcour les tags typ où il faut trouver le groupe collab
                angular.forEach(tagsType, function (tag) {
                    var deferred = $q.defer();

                    var type = tag.getTypeTag().getLibelle();

                    var blocInfos = {};
                    var blocInfosPCS = {};
                    var blocInfosMCS = {};
                    var blocInfosCS = {};

                    if (angular.isDefined(tag.getPortefeuille())) {
                        portefeuille = tag.getPortefeuille();
                        idPortefeuille = portefeuille.getIdPortefeuille();
                    }

                    var isSubGroupeImmeuble = false;
                    var callSearch = false;

                    // Si le type actuel du tagtype n'est pas dans les blocs (genre immeuble)
                    if (!ctrl.blocsDestinatairesDemande.hasOwnProperty(type)) {
                        // Si le type est immeuble
                        if (type === COLLAB_CONF.TAG_TYPE_IMMEUBLE) {
                            isSubGroupeImmeuble = true;
                            callSearch = true;

                            blocInfosPCS = ctrl.blocsDestinatairesDemande['PCS'].infos;
                            blocInfosPCS.display = true;
                            blocInfosMCS = ctrl.blocsDestinatairesDemande['MCS'].infos;
                            blocInfosMCS.display = true;
                            blocInfosCS = ctrl.blocsDestinatairesDemande['CS'].infos;
                            blocInfosCS.display = true;

                            if(ctrl.selectGravitant && angular.isArray(ctrl.selectGravitant)) {
                                if(ctrl.selectGravitant.indexOf('PCS') !== -1){
                                    ctrl.blocsDestinatairesDemande['PCS'].checked = true;
                                    blockSelected = ctrl.blocsDestinatairesDemande['PCS'].infos;
                                }

                                if(ctrl.selectGravitant.indexOf('MCS') !== -1){
                                    ctrl.blocsDestinatairesDemande['MCS'].checked = true;
                                    blockSelected = ctrl.blocsDestinatairesDemande['MCS'].infos;
                                }

                                if(ctrl.selectGravitant.indexOf('CS') !== -1){
                                    ctrl.blocsDestinatairesDemande['CS'].checked = true;
                                    blockSelected = ctrl.blocsDestinatairesDemande['CS'].infos;
                                }
                            }


                        }
                    }
                    else {
                        ctrl.blocsDestinatairesDemande[type].checked = (ctrl.selectGravitant && angular.isArray(ctrl.selectGravitant) && ctrl.selectGravitant.indexOf(type) !== -1) ? true : false;
                        blocInfos = ctrl.blocsDestinatairesDemande[type].infos;
                        blocInfos.display = true;
                        callSearch = true;
                    }

                    if (callSearch) {

                        GroupeService
                            .searchGroupeByLibelleAndPortefeuilleInMySociete(idPortefeuille, tag.getValeurIcs())
                            .then(function(groupe){
                                initBloc(groupe, blocInfos, blocInfosPCS, blocInfosMCS, blocInfosCS);
                            })
                            .catch(function(msg){
                                blocInfos.erreur = {
                                    recherche: tag.getValeurIcs(),
                                    msg: msg
                                };
                            })
                            .finally(function(){
                                if (isSubGroupeImmeuble) {
                                    blocInfosPCS.loading = false;
                                    blocInfosMCS.loading = false;
                                    blocInfosCS.loading = false;
                                }
                                else  blocInfos.loading = false;
                                deferred.resolve();
                            });
                    }
                    else deferred.resolve();
                    promises.push(deferred.promise);
                });

                $q.all(promises).then(function(){

                    onSelectGravitant(blockSelected);

                    var groupes = [];
                    // Parcours les blocDestinataire de la mini communication
                    angular.forEach(ctrl.blocsDestinatairesDemande, function (obj) {
                      if(_.isObject(obj.infos.groupeCollab) && obj.infos.enabled) {
                        groupes.push(obj.infos.groupeCollab);
                      }
                    });

                    ctrl.onInit({
                      obj: {
                        listGroupes: groupes
                      }
                    });
                });
            }
        }

        /**
         * Valeur par defaut des variables pour la communication des gravitants
         */
        function initDefaultVarCommunicationGravitants() {
            var infosParBlock = {
                selectedResult: false,
                results: [],
                loading: true,		// Loading
                display: false,		// Block dispo
                enabled: false,		// Groupe fini de chargé il peux etre utilisé
                disabled: false,    // Desactive le champ
                groupeCollab: false,	// Objet du groupe collab
                isGroupeNonUser: false
            };

            ctrl.nbMCS = 0;
            ctrl.nbPCS = 0;
            ctrl.immeubleFind = {
                groupeCollab: false,
                display: false,		// Block dispo
                selectedResult: false,
                results: []
            };

            ctrl.blocsDestinatairesDemande = {};
            ctrl.blocsDestinatairesDemande[COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE] = {
                nom: 'Copropriétaire',
                name: 'copro',
                infos: angular.copy(infosParBlock),
                checked: false
            };
            ctrl.blocsDestinatairesDemande[COLLAB_CONF.TAG_TYPE_PROPRIETAIRE] = {
                nom: 'Propriétaire',
                name: 'prop',
                infos: angular.copy(infosParBlock),
                checked: false
            };
            ctrl.blocsDestinatairesDemande[COLLAB_CONF.TAG_TYPE_LOCATAIRE] = {
                nom: 'Locataire',
                name: 'loc',
                infos: angular.copy(infosParBlock),
                checked: false
            };
            ctrl.blocsDestinatairesDemande.CS = {
                nom: 'Conseil Syndical (Président et Membres)',
                name: 'cs',
                noTagType: true,
                infos: angular.copy(infosParBlock),
                checked: false
            };
            ctrl.blocsDestinatairesDemande.MCS = {
                nom: 'Membres CS',
                name: 'mcs',
                noTagType: true,
                infos: angular.copy(infosParBlock),
                checked: false
            };
            ctrl.blocsDestinatairesDemande.PCS = {
                nom: 'Président CS',
                name: 'pcs',
                noTagType: true,
                infos: angular.copy(infosParBlock),
                checked: false
            };

        }

        function initBloc(groupe, blocInfos, blocInfosPCS, blocInfosMCS, blocInfosCS) {
            if (groupe.isImmeuble()) {

                ctrl.immeubleFind.groupeCollab = groupe;

                if (!blocInfosPCS) blocInfosPCS = ctrl.blocsDestinatairesDemande['PCS'].infos;
                if (!blocInfosMCS) blocInfosMCS = ctrl.blocsDestinatairesDemande['MCS'].infos;
                if (!blocInfosCS) blocInfosCS = ctrl.blocsDestinatairesDemande['CS'].infos;

                var groupePCS = groupe.getGroupePresidentConseilSyndical();
                var groupeMCS = groupe.getGroupeMembreConseilSyndical();
                var groupeCS = groupe.getGroupeConseilSyndical();

                if (groupePCS) {
                    blocInfosPCS.groupeCollab = groupePCS;
                    if (groupePCS.getFiche()) blocInfosPCS.libelle = groupePCS.getFiche().getNomPrenom();
                    blocInfosPCS.enabled = true;
                    ctrl.nbPCS = groupePCS.getGroupeInformations().nbGroupesUser;
                    if (ctrl.nbPCS === 0) blocInfosPCS.disabled = true;
                }

                if (groupeMCS) {
                    blocInfosMCS.groupeCollab = groupeMCS;
                    if (groupeMCS.getFiche()) blocInfosMCS.libelle = groupeMCS.getFiche().getNomPrenom();
                    blocInfosMCS.enabled = true;
                    ctrl.nbMCS = groupeMCS.getGroupeInformations().nbGroupesUser;
                    if (ctrl.nbMCS === 0) blocInfosMCS.disabled = true;
                }

                if (groupeCS) {
                    blocInfosCS.groupeCollab = groupeCS;
                    if (groupeCS.getFiche()) blocInfosCS.libelle = groupeCS.getFiche().getNomPrenom();
                    blocInfosCS.enabled = true;
                    if (ctrl.nbPCS === 0 && ctrl.nbMCS === 0) blocInfosCS.disabled = true;
                }
                /*
                if (groupePCS && groupeMCS) {
                  blocInfosCS.groupeCollab = [groupePCS, groupeMCS];
                  blocInfosCS.enabled = true;
                  if (groupePCS.getGroupeInformations().nbGroupesUser === 0 || groupeMCS.getGroupeInformations().nbGroupesUser === 0) blocInfosCS.disabled = true;
                }*/
            }
            else {
                blocInfos.groupeCollab = groupe;
                blocInfos.libelle = groupe.getDefaultNom();
                blocInfos.enabled = true;
            }
        }

        function getIconTag(tag){
            var libelle = '';
            if(tag.hasOwnProperty('typeTag')) libelle = tag.typeTag.libelle;
            return TagsService.getIconForTagType(libelle);
        }

        /**
         * Permet de desactiver les bouton par rapport au groupe déjà coché
         */
        function checkStatusCheckbox(currentChange){

            if(currentChange) {
                // Si groupe CS coché et celui actuellement coché n'est pas le CS alors décoche et desactive PCS et MCS
                if(ctrl.blocsDestinatairesDemande.CS.checked && currentChange.name === 'cs') {
                    ctrl.blocsDestinatairesDemande.PCS.checked = false;
                    ctrl.blocsDestinatairesDemande.MCS.checked = false;
                }
                // Si groupe PCS ou MCS coché alors decoche et desactive CS
                else if(ctrl.blocsDestinatairesDemande.PCS.checked || ctrl.blocsDestinatairesDemande.MCS.checked)
                    ctrl.blocsDestinatairesDemande.CS.checked = false;
            }
            else {
                // Si groupe CS coché alors décoche et desactive PCS et MCS
                if(ctrl.blocsDestinatairesDemande.CS.checked) {
                    ctrl.blocsDestinatairesDemande.PCS.checked = false;
                    ctrl.blocsDestinatairesDemande.PCS.infos.disabled = true;

                    ctrl.blocsDestinatairesDemande.MCS.checked = false;
                    ctrl.blocsDestinatairesDemande.MCS.infos.disabled = true;
                }
                else {
                    // Si PCS est desactivé on le réactive idem pou MCS
                    if(ctrl.blocsDestinatairesDemande.PCS.infos.disabled && ctrl.nbPCS !== 0) ctrl.blocsDestinatairesDemande.PCS.infos.disabled = false;
                    if(ctrl.blocsDestinatairesDemande.MCS.infos.disabled && ctrl.nbMCS !== 0) ctrl.blocsDestinatairesDemande.MCS.infos.disabled = false;

                    // Si groupe PCS ou MCS coché alors decoche et desactive CS
                    if(ctrl.blocsDestinatairesDemande.PCS.checked || ctrl.blocsDestinatairesDemande.MCS.checked) {
                        ctrl.blocsDestinatairesDemande.CS.checked = false;
                        ctrl.blocsDestinatairesDemande.CS.infos.disabled = true;
                    }
                    // Sinon si il y a des users dans PCS ou dans MCS
                    else if(ctrl.nbPCS !== 0 || ctrl.nbMCS !== 0) ctrl.blocsDestinatairesDemande.CS.infos.disabled = false;
                }
            }
        }

        /**
         * Ajoute un groupe dans la liste passé en paramètre si il n'exitse pas déjà
         * @param groupe
         * @param destinataires
         */
        function addDestinataireIfNotExist(obj, destinataires){
            var exist = false;
            if(destinataires.length) {
                for(var d = 0; d < destinataires.length; d++){
                    if(destinataires[d].groupeCollab) {
                        if(angular.isObject(obj.groupeCollab) && destinataires[d].groupeCollab.idGroupe === obj.groupeCollab.idGroupe) {
                            exist = true;
                            break;
                        }
                        else if(destinataires[d].groupeCollab.idGroupe === obj.idGroupe) {
                            exist = true;
                            break;
                        }
                    }
                    else if(destinataires[d].idGroupe && destinataires[d].idGroupe === obj.idGroupe) {
                        exist = true;
                        break;
                    }
                }
            }
            if(!exist) destinataires.push(obj);
            return destinataires;
        }

        /**
         * Lors de la selection d'un gravitant
         */
        function onSelectGravitant(destinataireCom){
            var destinataires = [];
            var nbDestVisible = 0, nbDestVisibleErreur = 0;

            checkStatusCheckbox(destinataireCom);

            // Parcours les blocDestinataire de la mini communication
            angular.forEach(ctrl.blocsDestinatairesDemande, function (obj) {

                // Si visible
                if(obj.infos.display) {
                    nbDestVisible++;
                    // Si en erreur
                    if(obj.infos.erreur) nbDestVisibleErreur++;
                }

                if (obj.infos.enabled && obj.checked && obj.infos.groupeCollab) {

                    var tmp;
                    if(angular.isArray(obj.infos.groupeCollab) && obj.infos.groupeCollab.length) {
                        for(var g = 0; g < obj.infos.groupeCollab.length; g++) {
                            tmp = {
                                nom: obj.nom,
                                name: obj.name,
                                groupeCollab: obj.infos.groupeCollab[g]
                            };
                            destinataires = addDestinataireIfNotExist(tmp, destinataires);
                        }
                    }
                    else if(angular.isObject(obj.infos.groupeCollab)) {
                        tmp = {
                            nom: obj.nom,
                            name: obj.name,
                            groupeCollab: obj.infos.groupeCollab
                        };

                        destinataires = addDestinataireIfNotExist(tmp, destinataires);
                    }
                }
            });
            ctrl.onEmit({
                obj: {
                    destinataires: destinataires,
                    noDest: (nbDestVisible === nbDestVisibleErreur)
                }
            });
        }

        /**
         * Lors de la selection d'un gravitant dans la partie plusieur resultats trouvé pour un groupe
         * @param item
         * @param blockInfos
         */
        function selectResult(item, blockInfos) {
            if (item.isImmeuble()) ctrl.immeubleFind.selectedResult = item;
            else blockInfos.selectedResult = item;
            initBloc(item, blockInfos);

            checkStatusCheckbox();

            var destinataires = [];
            // Parcours les blocDestinataire de la mini communication
            angular.forEach(ctrl.blocsDestinatairesDemande, function (obj) {
                if (obj.infos.enabled && obj.checked && obj.infos.groupeCollab) {

                    if(angular.isArray(obj.infos.groupeCollab) && obj.infos.groupeCollab.length) {
                        for(var g = 0; g < obj.infos.groupeCollab.length; g++) {
                            destinataires = addDestinataireIfNotExist(obj.infos.groupeCollab[g], destinataires);
                        }
                    }
                    else if(angular.isObject(obj.infos.groupeCollab)) destinataires = addDestinataireIfNotExist(obj.infos.groupeCollab, destinataires);
                }
            });
            ctrl.onEmit({
                obj: {
                    destinataires: destinataires
                }
            })
        }

        function resetResult(item, blockInfos) {
            if (item.isImmeuble()) {
                ctrl.immeubleFind.groupeCollab = false;
                ctrl.immeubleFind.selectedResult = false;

                ctrl.blocsDestinatairesDemande['PCS'].infos.groupeCollab = false;
                ctrl.blocsDestinatairesDemande['PCS'].infos.disabled = false;
                ctrl.blocsDestinatairesDemande['PCS'].infos.enabled = false;
                ctrl.blocsDestinatairesDemande['PCS'].checked = false;
                delete ctrl.blocsDestinatairesDemande['PCS'].infos.libelle;

                ctrl.blocsDestinatairesDemande['MCS'].infos.groupeCollab = false;
                ctrl.blocsDestinatairesDemande['MCS'].infos.disabled = false;
                ctrl.blocsDestinatairesDemande['MCS'].infos.enabled = false;
                ctrl.blocsDestinatairesDemande['MCS'].checked = false;
                delete ctrl.blocsDestinatairesDemande['MCS'].infos.libelle;

                ctrl.blocsDestinatairesDemande['CS'].infos.groupeCollab = false;
                ctrl.blocsDestinatairesDemande['CS'].infos.disabled = false;
                ctrl.blocsDestinatairesDemande['CS'].infos.enabled = false;
                ctrl.blocsDestinatairesDemande['CS'].checked = false;
                delete ctrl.blocsDestinatairesDemande['CS'].infos.libelle;
            }
            else {
                blockInfos.selectedResult = false;
                blockInfos.groupeCollab = false;
                delete blockInfos.libelle;
            }
        }
    }
})();
