(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.DevisService
   * @description
   * # DevisService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('StatsTopicService', StatsTopicService);

  /*@ngInject*/
  function StatsTopicService(MainService, $q, IcsCloudResource, ExtranetResource, ModalsService, InfoImmeuble, UtilsService) {
    var ctrl = this;

    ctrl.getEtatDepenseImmeuble = getEtatDepenseImmeuble;

    /**
     * Récupère le tableau des dépenses
     * @param numImmeuble
     * @param idPortefeuille
     * @returns {*}
     */
    function getEtatDepenseImmeuble(numImmeuble, idPortefeuille) {
      var deferred = $q.defer();
      var params = {action: 'getEtatDepenseImmeuble', immeuble: numImmeuble, idPortefeuille: idPortefeuille};
      try {
        ExtranetResource
          .get(params)
          .$promise
          .then(function onSuccess(json) {
            if(json.success) {
              /**Charges du Syndicat**/
              json.html = UtilsService.replaceAll('<td class="td2_res"  colspan="7" align="center">','<td class="td2_res title-medium no-border no-padding"  colspan="7" align="center">', json.html);

              /**Total charges du syndicat**/
              json.html = UtilsService.replaceAll('<td class="td2_res" width=40%><span style="font-weight: bold;font-size:12px;">', '<td class="td2_res title-syndic" width=40%><span style="font-weight: bold;font-size:12px;">', json.html);

              /**Petit titre et petit total**/
              json.html = UtilsService.replaceAll('<td class="td1_res">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<','<td class="td1_res title-litle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<', json.html);
              json.html = UtilsService.replaceAll('<td class="td2_res">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<','<td class="td2_res title-litle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<', json.html);

              /**Total charge fonctionnement**/
              json.html = UtilsService.replaceAll('<td class="td1_res" style="font-weight: bold;font-size: 11px">Total Charges de Fonctionnement','<td class="td1_res title-litle-no-span" style="font-weight: bold;font-size: 11px">Total Charges de Fonctionnement',json.html);

              /**Total des traveaux en cours**/
              json.html = UtilsService.replaceAll('<td class="td1_res" style="font-weight: bold;font-size: 11px">Total Travaux en cours : Travaux hors budget', '<td class="td1_res title-litle-no-span" style="font-weight: bold; font-size: 1.3em;">Total Travaux en cours : Travaux hors budget', json.html);

              /**Suppression des border sous les petit totaux**/
              json.html = UtilsService.replaceAll('<td class="td2_res" colspan="7">&nbsp;</td>','<td class="td2_res no-border" colspan="7">&nbsp;</td>', json.html);
              json.html = UtilsService.replaceAll('<td class="td1_res" colspan="7">&nbsp;</td>','<td class="td1_res no-border" colspan="7">&nbsp;</td>', json.html);

              /**Suppression d'espace vide**/
              json.html = UtilsService.replaceAll('<td class="td2_res">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="text-decoration: underline;"></span></td><td class="td2_res" align="right">&nbsp;</td><td class="td2_res" align="right">&nbsp;</td><td class="td2_res" align="right">&nbsp;</td><td class="td2_res" align="right">&nbsp;</td><td class="td2_res" align="right">&nbsp;</td><td class="td2_res" align="right">&nbsp;</td></tr>','', json.html);
              json.html = UtilsService.replaceAll('<td class="td1_res">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="text-decoration: underline;"></span></td><td class="td1_res" align="right">&nbsp;</td><td class="td1_res" align="right">&nbsp;</td><td class="td1_res" align="right">&nbsp;</td><td class="td1_res" align="right">&nbsp;</td><td class="td1_res" align="right">&nbsp;</td><td class="td1_res" align="right">&nbsp;</td></tr>','', json.html);

              deferred.resolve(json);
            }
            else {
              deferred.reject(json.erreur);
            }
          })
          .catch(function onError(err) {
            deferred.reject(err);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }
  }
})();
