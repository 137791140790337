(function () {
  'use strict';


  /**
   * @ngdoc service
   * @name collaboreApp.SharedDocumentGroupe
   * @description
   * # SharedDocumentGroupe
   * Service in the collaboreApp.
   */
  angular
    .module('collaboreApp')
    .factory('SharedDocumentGroupe', SharedDocumentGroupeModel);

  function SharedDocumentGroupeModel (Main, Groupe, UtilsService) {

    function SharedDocumentGroupe(objet) {
      Main.call(this, objet);
    }

    SharedDocumentGroupe.prototype = {

      model: 'SharedDocumentGroupe',
      isSharedDocumentGroupe: true,

      // idTemporaryDocBoxDocument
      getIdDocBoxSharing: function(){
        return parseInt(this.idDocBoxSharing);
      },
      setIdDocBoxSharing: function(valInt){
        this.idDocBoxSharing = valInt;
      },

      // sharingDate
      getSharingDate: function (format) {
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.sharingDate, format);
        }
        return this.sharingDate;
      },
      setSharingDate: function (date) {
        this.sharingDate = date;
      },

      // groupe
      getGroupe: function(){
        return this.groupe;
      },
      setGroupe: function(val){
        if(_.isObject(val)){
          if(!val.isModel) val = new Groupe(val);
          this.groupe = val;
        }
      },

      // allowDeleting
      getAllowDeleting: function(){
        return this.allowDeleting;
      },
      setAllowDeleting: function(val){
        this.allowDeleting = val;
      },

      // allowSharing
      getAllowSharing: function(){
        return this.allowSharing;
      },
      setAllowSharing: function(val){
        this.allowSharing = val;
      },

      // allowEditing
      getAllowEditing: function(){
        return this.allowEditing;
      },
      setAllowEditing: function(val){
        this.allowEditing = val;
      }
    };

    angular.extend(SharedDocumentGroupe.prototype, Main.prototype);
    return SharedDocumentGroupe;

  }
})();
