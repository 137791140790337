(function () {
    'use strict';

    /**
     * @desc Calculatrice
     * @example <Calculatrice></Calculatrice>
     */

    angular
        .module('collaboreApp')
        .component('calculatrice',{
            templateUrl: 'app/topics/components/calculatrice/calculatrice.component.html',
            controllerAs: 'calculatricectrl',
            bindings: {
                idTopic: '<',
                onEmit: '&'
            },
            controller: CalculatriceController
        });

    /*@ngInject*/
    function CalculatriceController(LigneCalculatrice, CalculatriceService) {

        var ctrl = this;
        ctrl.ligneCalculatrice = [];
        ctrl.donneRemplie = true;

        ctrl.ajouterLigne = ajouterLigne;
        ctrl.supprimerLigne = supprimerLigne;
        ctrl.soldeTotal = soldeTotal;
        ctrl.onClickDownloadExcel = onClickDownloadExcel;


        ctrl.$onInit = function(){
            ctrl.newLigne = new LigneCalculatrice({
                idTopic: ctrl.idTopic
            });
            rechercheLigne();
        };


        /**
         * Permet de chercher les ligne dans la bdd pour les mettre dans le tableau
         */
        function rechercheLigne(){
            CalculatriceService.rechercheLignesCalculatrice(ctrl.newLigne)
                .then(function onSuccess(tableau){
                    for( var i = 0; i < tableau.length; i++){
                        ctrl.ligneCalculatrice[i] = tableau[i];
                    }
                })
                .catch(function onError(msg){
                    console.log(msg);
                })
                .finally(function onFinally(){
                    soldeTotal();
                });
        }


        /**
         * Permet de rajouter une ligne dans la bdd et de la rajouter dans le tableau
         */
        function ajouterLigne() {
            if (ctrl.newLigne.date == null) {
                ctrl.donneRemplie = false;
            }
            else {
                ctrl.donneRemplie = true;
                CalculatriceService.ajoutLigneCalculatrice(ctrl.newLigne)
                    .then(function onSuccess(tableau) {
                        for (var i = 0; i < tableau.length; i++) {
                            ctrl.ligneCalculatrice[i] = tableau[i];
                        }
                    })
                    .catch(function onError(msg) {
                        console.log(msg);
                    })
                    .finally(function onFinally() {
                    });
                ctrl.newLigne = new LigneCalculatrice({
                    idTopic: ctrl.idTopic
                });
            }
        }

        /**
         * Permet de supprimer une ligne dans la bdd et de la supprimer dans le tableau
         * @param index
         */
        function supprimerLigne(index)
        {
            CalculatriceService.supprimeLigneCalculatrice(ctrl.ligneCalculatrice[index])
                .then(function onSuccess(tableau){
                    ctrl.ligneCalculatrice.splice(index,1);
                    for( var i = 0; i < tableau.length; i++){
                        ctrl.ligneCalculatrice[i] = tableau[i];
                    }
                })
                .catch(function onError(msg){
                    console.log(msg);
                })
                .finally(function onFinally(){

                });
        }

        /**
         * Permet de calculer le solde de newLigne
         * @returns {*}
         */
        function soldeTotal(){
            ctrl.soldeGlobal = 0;
            if (ctrl.ligneCalculatrice.length == 0) {
                return ctrl.newLigne.calculeSolde();
            }else{
                ctrl.soldeGlobal = ctrl.newLigne.calculeSolde() + ctrl.ligneCalculatrice[ctrl.ligneCalculatrice.length - 1].getSolde();
            }
            return ctrl.soldeGlobal;
        }

        /**
         * Permet de télécharger le tableau en format Excel
          */
        function onClickDownloadExcel(){
            var params = ctrl.idTopic;
            CalculatriceService.downloadExcel(params)
                .then(function(data){
                    var headers = data.headers;
                    var windowUrl = (window.URL || window.webkitURL || window.mozURL);
                    var downloadableBlob = windowUrl.createObjectURL(data.response);
                    var fileNamePattern = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

                    var a = document.createElement('a');
                    a.href = downloadableBlob;
                    a.download = fileNamePattern.exec(headers['content-disposition'])[1];
                    a.target = '_blank';

                    document.body.appendChild(a);

                    a.click();

                    $timeout(function(){
                        document.body.removeChild(a);
                        windowUrl.revokeObjectURL(downloadableBlob);
                    }, 100);

                })
                .finally(function () {
                    ctrl.loading = false;
                });
        }
    }
})();
