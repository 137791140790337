(function() {

  'use strict';

  /**
   * @desc Permet de selectionner un dossier
   * @example <popover-search-topic></popover-search-topic>
   */
  angular
      .module('collaboreApp')
      .component('popoverSearchTopic',{
          templateUrl: 'app/topics/popover-search-topic/popover-search-topic.component.html',
          controllerAs: 'popoversearchtopicctrl',
          bindings: {
              mode: '@',                // 'inline' ou 'popover'
              popoverBtnText: '@',
              popoverBtnClass: '@',
              popoverIconClass: '@',
              popoverTooltip: '@',
              popoverPlacement: '@',
              popoverClass: '@',
              popoverAppendToBody: '<',

              btnValidIconClass: '@',
              btnValidText: '@',

              multiCheck: '<',
              onEmit: '&'
          },
          controller: PopoverSearchTopicCtrl
      });

  /** @ngInject */
  function PopoverSearchTopicCtrl($rootScope, UtilsService, $scope, ElasticSearchService){
    var _this = this;
    var listenerClose = null;

    _this.limit = 10;
    _this.currentPage = 1;
    _this.nbTotal = 0;

    _this.searchTopic = null;
    _this.searchTopicSubmited = null;
    _this.listTopicStates = [];
    _this.listTopicStatesChecked = [];     // Liste privée des topic cochés

    _this.onSubmitSearchTopic = onSubmitSearchTopic;
    _this.onClickChangePage = onClickChangePage;
    _this.onClickClearSearchTopic = onClickClearSearchTopic;
    _this.onCheckTopic = onCheckTopic;
    _this.onClickChoose = onClickChoose;
    _this.onClickClose = onClickClose;

    _this.$onInit = function() {
        _this.uuid = UtilsService.uuid.new();
        if(_.isNil(_this.mode))                 _this.mode = 'popover';
        if(_.isNil(_this.popoverTooltip))       _this.popoverTooltip = 'Rechercher un dossier';
        if(_.isNil(_this.popoverPlacement))     _this.popoverPlacement = 'auto';
        if(_.isNil(_this.popoverClass))         _this.popoverClass = 'popover-collabore popover-lg';
        if(_.isNil(_this.popoverAppendToBody))  _this.popoverAppendToBody = true;
        if(_.isNil(_this.btnValidText))         _this.btnValidText = 'Valider';
        if(_this.mode === 'popover') {
            $scope.$watch('popoversearchtopicctrl.popoverIsOpen', function(newVal){
                if(newVal) {
                  // Ferme les autres
                  $rootScope.$broadcast('closePopoverTopic', _this.uuid);
                  init();
                }
            });
        }
        else init();
    };

    _this.$onChanges = function(changes){
        if(_.isObject(changes.multiCheck) && !changes.multiCheck.isFirstChange()) {
            _this.multiCheck = changes.multiCheck.currentValue;
        }
    };

    _this.$onDestroy = function(){
        if(!_.isNil(listenerClose)) listenerClose();
    };

    /**
     * Permet d'init le composant
     */
    function init(){
      _this.searchTopic = null;
      _this.searchTopicSubmited = null;
      _this.currentPage = 1;
      _this.listTopicStates = [];
      _this.listTopicStatesChecked = [];
      if ((_this.mode === 'popover' && _this.popoverIsOpen) || _this.mode === 'inline') {

        //$rootScope.$broadcast('closePopoverTopic');
        listenerClose = $scope.$on('closePopoverTopic', function(e, notUuid){
          if(_.isNil(notUuid) || notUuid != _this.uuid) close();
        });
      }
    }

    function onSubmitSearchTopic(){
      _this.searchTopicSubmited = true;
      _this.currentPage = 1;
      callSearchTopic();
    }

    function onClickClearSearchTopic(){
      _this.searchTopic = null;
      _this.searchTopicSubmited = null;
      _this.currentPage = 1;
    }

    function onClickChangePage(){
      callSearchTopic();
    }

    /**
     * Permet d'init le composant
     */
    function callSearchTopic(){
      _this.listTopicStates = [];
      // Recupération de la liste des topics
      _this.loadingTopic = true;
      ElasticSearchService
        .searchTopic(_this.searchTopic, _this.currentPage, _this.limit)
        .then(function onSuccess(retourTopicsStates){
          _this.listTopicStates = retourTopicsStates.listTopicState;
          _this.nbTotal = retourTopicsStates.nbTotal;
        })
        .finally(function onFinally(){
            _this.loadingTopic = false;
        });
    }

    /**
     * Lors du clique sur une checkbox d'un dossier
     * @param currentTopic
     */
    function onCheckTopic(currentTopic){

        // Si pas de multi selection alors décoche les cases sauf celle selectionné
        if(_.isNil(_this.multiCheck) || !_this.multiCheck){
            resetCheckedTopic(_this.listTopicStates, currentTopic);
        }
        _this.listTopicStatesChecked = _this.listTopicStates.reduce(function (listChecked, topicState) {
          if(topicState.checked) listChecked.push(topicState);
          return listChecked;
        }, []);
    }

    /**
     * Permet de décocher les cases sauf celle actuellement selectionnée
     * @param listTopicStates
     * @param currentTopicChecked
     */
    function resetCheckedTopic(listTopicStates, currentTopicChecked){
        if(_.isArray(listTopicStates) && listTopicStates.length){
            for(var i = 0; i < listTopicStates.length; i++){
                if(_.isObject(currentTopicChecked) && listTopicStates[i].topic.idTopic !== currentTopicChecked.topic.idTopic) {
                  delete listTopicStates[i].checked;
                }
            }
        }
    }

    /**
     * Lors du clique sur le bouton pour sauvegarder les changements. utilisé dans le cas ou le topic existe déjà
     */
    function onClickChoose(){
      emitTopic();
      close();
    }

    function onClickClose(){
        close();
    }

    function close(){
        _this.popoverIsOpen = false;
        _this.searchTopic = null;
        _this.searchTopicSubmited = null;
        _this.currentPage = 1;
        _this.listTopicStates = [];
        _this.listTopicStatesChecked = [];
    }


    function emitTopic(){
        if(_this.multiCheck) {
            emit({
                action: 'selectListTopicStates',
                listTopicStates: _this.listTopicStatesChecked
            });
        }
        else {
            emit({
                action: 'selectTopicState',
                topicState: (_this.listTopicStatesChecked.length) ? _this.listTopicStatesChecked[0] : null
            });
        }
    }

    /**
     * Permet d'emit des infos vers le composant parent
     * @param objet
     */
    function emit(objet){
        _this.onEmit({
            obj: objet
        });
    }
  }
})();
