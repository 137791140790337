(function() {

  'use strict';

  /**
   * @desc Composant permetant de liste des société pour en choisir une
   * @example <choose-societe></choose-societe>
   */
  angular
    .module('collaboreApp')
    .component('chooseSociete',{
      templateUrl: 'app/components/choose-societe/choose-societe.component.html',
      bindings : {
        users: '<',
        changeDiese: '<',
        onEmit: '&',            // Permet de notifier le composant parent

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controllerAs: 'choosesocietectrl',
      controller: ChooseSocieteController
    });

  /*@ngInject*/
  function ChooseSocieteController($rootScope, $state, $injector, COLLAB_CONF, $q, UtilsService, ModalsService, UserService, LoginResource){

    var _this = this;
    _this.selectedUser = null;
    _this.error = null;

    _this.onSelectResult = onSelectResult;
    _this.onClickValidate = onClickValidate;
    _this.onClickClose = onClickClose;

    _this.$onInit = function() {
      if(_this.resolve) {
        _this.users = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'users'));
        _this.changeDiese = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'changeDiese'));
        initUsers(_this.users);
      }
      if(!_.isArray(_this.users) || !_this.users.length){
        if(COLLAB_CONF.AUTH_KERBEROS) {
          getListDieseFromKerberos()
            .then(function(users){
              initUsers(users);
            })
            .catch(function(msg){
              _this.error = msg;
            });
        }
        else ModalsService.alertErreur('Pas d\'users');
      }
    };

    /**
     * Lors de changements depuis le parent
     * @param changes
     */
    _this.$onChanges = function (changes) {
      if(_.isObject(changes)) {
        if(_.isObject(changes.users) && !changes.users.isFirstChange()) {
          initUsers(angular.copy(changes.users.currentValue));
        }
      }
    };

    _this.$onDestroy = function(){
    };

    function initUsers(users){
      _this.users = [];
      if(_.isArray(users)) {
        var groupe = UtilsService.getCurrentUser();
        if(_.isObject(groupe) && _.isArray(users) && users.length) {
          for(var i = 0; i < users.length; i++){
            if(users[i].idGroupe === groupe.idGroupe) users[i].currentGroupe = true;
          }
        }
        _this.users = users;
      }
    }

    /**
     * Permet de récupérer la liste des diese dispo avec les identifiants du Kerberos
     * @returns {*}
     */
    function getListDieseFromKerberos(){
      var deferred = $q.defer();
      _this.error = null;
      _this.loading = true;
      LoginResource.connexionKerberos({searchListDiese: true}, function(json){
          _this.loading = false;
          if(json.success) {
            if(_.isArray(json.result) && json.result.length) deferred.resolve(json.result);
            else deferred.reject("Pas d'users");
          }
          else {
            deferred.reject(json.message);
          }
        },
        // Erreur
        function (error) {
          deferred.reject(error.statusText);
          _this.loading = false;
        });
      return deferred.promise;
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      if(_this.resolve) _this.close({$value: objRetour});
      else _this.onEmit({obj: objRetour})
    }

    function close(){
      _this.dismiss({$value: 'cancel'});
    }

    /**
     * Lors de la selection d'un diese
     * @param result
     */
    function onSelectResult(result){
      if(_.isObject(result) && _.isNil(result.currentGroupe)) {
        if(_.isObject(_this.selectedUser) && _this.selectedUser.idUser === result.idUser) {
          _this.selectedUser = null;
        }
        else {
          _this.selectedUser = angular.copy(result);
        }

        /*
        for(var i = 0; i < _this.results.length; i++){
          if(_this.results[i].idUser !== result.idUser) delete _this.results[i].selected;
        }
        result.selected = true;*/
      }
    }


    /**
     * Lors du clique sur le bouton valider
     */
    function onClickValidate(){
      if(_.isNil(_this.selectedUser)){
        ModalsService.alertErreur('Veuillez faire un choix !');
        return;
      }

      var params = {
        login: _this.selectedUser.login,
        diese: _this.selectedUser.dieze,
        mdp: _this.selectedUser.mdp
      };

      UserService.connexionKerberos(params)
        .then(function(jsonGroupe){
          if(_this.changeDiese) {
            $rootScope.$broadcast('refreshDocBox');
            close();
            UtilsService.goRouteAskedOrLogin($rootScope.toState, $rootScope.toParams, true);
          }
          else {
            emit(jsonGroupe);
          }
        })
        .catch(function(msg){
        });

      /*
      UserService.connexion(params).then(function (groupeObj) {
        close();
        console.log('changeDiese', _this.changeDiese);
        if(_this.changeDiese) {
          UtilsService.goRouteAskedOrLogin($rootScope.toState);
        }
      });*/
    }

    /**
     * Fermeture du composant si en mode modal
     */
    function onClickClose(){
      close();
    }
  }
})();
