(function () {
  'use strict';

  angular.module('collaboreApp').factory('ModuleRefParam', ModuleRefParam);

  /** @ngInject */
  function ModuleRefParam() {

    var ModuleRefParamConstructor = function (data) {
      this.setData(data);
      //ModuleType.apply(this, arguments);
    };

    ModuleRefParamConstructor.prototype = {

      isModel: true,

      setData: function (data) {
        angular.extend(this, data);
      },

      // idModuleReferenceParams
      setIdModuleReferenceParams: function (id) {
        this.idModuleReferenceParams = id;
      },
      getIdModuleReferenceParams: function () {
        return this.idModuleReferenceParams;
      },

      // Libelle
      setLibelle: function (lib) {
        this.libelle = lib;
      },
      getLibelle: function () {
        return this.libelle;
      },

      // Reference
      setReference: function (val) {
        this.reference = val;
      },
      getReference: function () {
        return this.reference;
      },

      // Type
      setType: function (val) {
        this.type = val;
      },
      getType: function () {
        return this.type;
      },

      // Ordre
      setOrdre: function (val) {
        this.ordre = val;
      },
      getOrdre: function () {
        return this.ordre;
      },

      isObject: function() {
        if(this.isParameters()) return true;
        return false;
      },

      isText: function () {
        if (this.reference === 'text' || this.type === 'text') {
          return true;
        }
        return false;
      },

      isSelect: function () {
        if (this.type === 'select') {
          return true;
        }
        return false;
      },

      isUrl: function () {
        if (this.reference === 'url') {
          return true;
        }
        return false;
      },

      isCheckbox: function () {
        if (this.reference === 'checkbox') {
          return true;
        }
        return false;
      },

      isParameters: function () {
        if (this.reference === 'parametres' || this.type === 'parameters' || this.type === 'array_string' ||
          this.type === 'array_wysiwyg_and_titre' || this.type === 'array_textarea_and_titre' || this.type === 'array_textarea_sms_and_titre') {
          return true;
        }
        return false;
      }
    };

    return ModuleRefParamConstructor;
  }

})();
