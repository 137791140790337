(function () {

    'use strict';

    /**
     * @desc Champ permettant de changer le montant d'un contenu
     * @example <montant-contenu></montant-contenu>
     */

    angular
        .module('collaboreApp')
        .component('montantContenu', {
            bindings: {
                contenu: '<',	        // <ContenuEvenementDevis> Liste des devis/inter
                libelle: '@',           // Libelle devant le champ du montant
                tooltip: '@',           // Tooltip lors du survole
                onEmit: '&'             // <Function> Utilisé pour passer des informations au parents
            },
            templateUrl: 'app/topics/fournisseurs/montant-contenu/montant-contenu.component.html',
            controllerAs: 'montantcontenuctrl',
            controller: MontantContenuCtrl
        });

    /*@ngInject*/
    function MontantContenuCtrl(ModalsService) {

        var _this = this;
        _this.onClickFakeBouton = onClickFakeBouton;                // Lors du clique sur un bouton alors que la contenu est en attente de la websocket

        _this.updateMontant = updateMontant;          // Permet de modifier le montant de la contenu

        _this.$onInit = function () {
            if(!_.isObject(_this.contenu))  {
                ModalsService.alertErreur('Pas de contenu !');
            }
        };

        _this.$onChanges = function (changes) {
            if(_.isObject(changes)) {
                if(_.isObject(changes.contenu) && !changes.contenu.isFirstChange()) {
                    _this.contenu = changes.contenu.currentValue;
                }
            }
        };

        _this.$onDestroy = function () {
        };


        /**
         * Update le montant du contenu
         * @param montant
         */
        function updateMontant(montant) {
            _this.loading = true;
            // Set le montant du contenu
            _this.contenu.changeMontant(montant)
                .then(function(){
                    emit({action: 'changeMontant', montant: _this.contenu.getMontant()});
                })
                .catch(function (msg) {
                    ModalsService.alertErreur(msg);
                })
                .finally(function () {
                    _this.loading = false;
                });
        }

        /**
         * Lors du clique sur un bouton alors que la contenu est en attente de la websocket
         */
        function onClickFakeBouton(){
            ModalsService.info('En cours de création', 'Désolé la demande est en cours de création');
        }

        /**
         * Permet d'emit vers le parent
         * @param valObj
         */
        function emit(valObj){
            var object = {
                obj: {}
            };
            object.obj = _.merge(object.obj, valObj);
            _this.onEmit(object);
        }

    }
})();
