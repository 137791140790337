(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.FilterService
   * @description Nouveau service pour les filtres, A FAIRE
   * # FilterService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('FilterService', FilterService);

  /*@ngInject*/
  function FilterService($rootScope, MainService, COLLAB_CONF, ConfigService, UtilsService) {

    var ctrl = this;

    if(_.isNil(getFiltreTopics()) || !getFiltreTopics())
      setFiltreTopics(ConfigService.getPreferencesFiltreTopics());

    ctrl.getBaseFiltre = getBaseFiltre;           // Récupération du filtre de base
    ctrl.getFiltreTopics = getFiltreTopics;       // Getter du filtre actuel
    ctrl.setFiltreTopics = setFiltreTopics;       // Setter du filtre actuel
    ctrl.genereDescription = genereDescription;   // Récupération de la description du filtre actuel
    ctrl.cleanFiltre = cleanFiltre;               // Permet de clean le filtre
    ctrl.resetFiltre = resetFiltre;               // Permet de reset le filtre à 0

    /**
     * Init le filtre de base
     */
    function getBaseFiltre(){
      return angular.copy({
        idChannel: (angular.isDefined($rootScope.current.filtreTopics.idChannel) && $rootScope.current.filtreTopics.idChannel!==0) ? $rootScope.current.filtreTopics.idChannel : 0,
        libelle: false,
        isActive: false,
        tagType: false,
        tags: [],
        idPortefeuille: null,
        classe:'0', //Avec et sans event (0), sans event (1), avec event (2)
        cloturer: 2,  // Non cloturé par défaut
        urgent:0,
        sinistre: 0,
        withEvent: null,
        //'withoutEvent': true,
        withCloture: false,
        withoutCloture: true,
        withUrgent: true,
        withoutUrgent: true,
        withSinistre: true,
        withoutSinistre: true,
        listeFournisseurMail: [],
        listeFournisseurPersoICS: [],
        destinataires: [],
        filtreDashboard: null,
        rechercheElasticsearch: null,
        dateCreation: {
          startDate: null,
          endDate: null,
          searchAll: true
        },
        dateClotureDebut: null,
        dateClotureFin: null,
        stillOpenAt: null,
        others: {
          clotureEnable: false,
          nonLuEnable: false
        }
      });
    }

    function getFiltreTopics(){
      return $rootScope.current.filtreTopics;
    }
    function setFiltreTopics(obj){
      $rootScope.current.filtreTopics = angular.copy(obj);
    }

    function parseFiltreForUrl(params, noChangeCurrentFiltre){
      if(_.isNil(params) || params === false){
        params = getBaseFiltre();
        if(_.isNil(noChangeCurrentFiltre) || !noChangeCurrentFiltre) setFiltreTopics(params);
      }

      var search = {};

      if(params.idChannel===0)      search.idChannel = null;
      else                          search.idChannel = params.idChannel;

      if(params.classe==='0')       search.evenement = null;
      else if(params.classe==='1')  search.evenement = false;
      else if(params.classe==='2')  search.evenement = true;

      if(params.others.clotureEnable === true) {
        params.cloturer = 0;
        search.cloturer = true;
      }
      else if(params.others.clotureEnable === false) {
        if(params.isActive) {
          // Si cloturé et non cloturé
          if(params.withCloture && params.withoutCloture){        params.cloturer = 0;}
          // Si seulement cloturé
          else if(params.withCloture && !params.withoutCloture){  params.cloturer = 1;}
          // Si seulement non cloturé
          else if(!params.withCloture && params.withoutCloture){  params.cloturer = 2;}
        }
        else params.cloturer = 2;
      }

      if(params.cloturer===0)       search.cloturer = null;
      else if(params.cloturer===1)  search.cloturer = true;
      else if(params.cloturer===2)  search.cloturer = false;

      if(params.urgent===0)         search.urgent = null;
      else if(params.urgent===1)    search.urgent = true;
      else if(params.urgent===2)    search.urgent = false;

      if(params.sinistre===0)       search.sinistre = null;
      else if(params.sinistre===1)  search.sinistre = true;
      else if(params.sinistre===2)  search.sinistre = false;

      if(params.others.nonLuEnable === true) {
        if(_.isNil(noChangeCurrentFiltre) || !noChangeCurrentFiltre) $rootScope.current.filtreTopics.nonlu = params.nonlu = 1;
        search.nonlu = true;
      }
      else if(params.others.nonLuEnable === false) {
        if(_.isNil(noChangeCurrentFiltre) || !noChangeCurrentFiltre) $rootScope.current.filtreTopics.nonlu = params.nonlu = 0;
        search.nonlu = false;
      }

      if(params.nonlu===0)          search.nonlu = null;
      else if(params.nonlu===1)     search.nonlu = true;
      else if(params.nonlu===2)     search.nonlu = false;

      if(params.tagType===false)                search.tagType = null;
      else if(angular.isObject(params.tagType)) search.tagType = angular.toJson(params.tagType);

      if(params.tags===false)                   search.tags = null;
      else if(angular.isArray(params.tags))     search.tags = angular.toJson(params.tags);

      if(params.listeFournisseurMail===false)                 search.listeFournisseurMail = null;
      else if(angular.isArray(params.listeFournisseurMail))   search.listeFournisseurMail = angular.toJson(params.listeFournisseurMail);

      if(params.listeFournisseurPersoICS===false)               search.listeFournisseurPersoICS = null;
      else if(angular.isArray(params.listeFournisseurPersoICS)) search.listeFournisseurPersoICS = angular.toJson(params.listeFournisseurPersoICS);

      if(params.destinataires===false)          search.destinataires = null;
      else if(_.isArray(params.destinataires))  search.destinataires = angular.toJson(params.destinataires);

      if(_.isObject(params.filtreDashboard)) search.filtreDashboard = params.filtreDashboard;

      if(_.isObject(params.rechercheElasticsearch)) search.rechercheElasticsearch = params.rechercheElasticsearch;

      if(!_.isNil(params.idPortefeuille))     search.idPortefeuille = params.idPortefeuille;
      if(!_.isNil(params.dateCreation))       search.dateCreation = params.dateCreation;
      //if(!_.isNil(params.dateCreationDebut))  search.dateCreationDebut = params.dateCreationDebut;
      //if(!_.isNil(params.dateCreationFin))    search.dateCreationFin = params.dateCreationFin;
      if(!_.isNil(params.dateClotureDebut))   search.dateClotureDebut = params.dateClotureDebut;
      if(!_.isNil(params.dateClotureFin))     search.dateClotureFin = params.dateClotureFin;
      if(!_.isNil(params.stillOpenAt))        search.stillOpenAt = params.stillOpenAt;

    }

    function getFiltreByTags(idChannel, tags, resetFiltre, mergeFiltre){

      var currentFiltre = (!_.isNil(resetFiltre) && resetFiltre) ? angular.copy(getBaseFiltre()) : angular.copy(getFiltreTopics());

      if(_.isObject(mergeFiltre)) _.merge(currentFiltre, mergeFiltre);

      currentFiltre.classe = '0';
      currentFiltre.isActive = true;

      if(!_.isArray(tags)) tags = [tags];
      if(!_.isArray(currentFiltre.tags) || resetFiltre) currentFiltre.tags = [];

      currentFiltre.tags = _.unionWith(currentFiltre.tags, tags, _.isEqual);
      if(!_.isNil(idChannel)) currentFiltre.idChannel  = idChannel;

      var retour = scope.parseFiltreToUrl(currentFiltre, (_.isObject(mergeFiltre)));
      return retour.obj;
    }

    function getObjStateParams(obj, idChannel){
      if(_.isNil(idChannel)){
        if(_.isNil(obj.idChannel)) idChannel = $rootScope.current.filtreTopics.idChannel;
        else idChannel = obj.idChannel;
      }
      return {
        topicPage: 1,
        idChannel: idChannel,
        search: CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(angular.toJson(obj)))
      };
    }


    /**
     * Permet de générer une description par rapport au filtre passé en paramètre ou au filtre actuel
     * @param objFiltre
     * @returns {string}
     */
    function genereDescription(objFiltre){
      if(_.isNil(objFiltre)) objFiltre = getFiltreTopics();
      var libelle = '';
      switch(objFiltre.classe){
        case '0':
          libelle = 'dossiers';
          break;
        case '1':
          libelle = 'dossiers sans événements';
          break;
        case '2':
          libelle = 'dossiers avec événements';


          switch(objFiltre.urgent){
            case 1:
              libelle += ',urgent';
              break;
            case 2:
              libelle += ', pas urgent';
              break;
            default:
          }

          switch(objFiltre.sinistre){
            case 1:
              libelle += ' avec sinistre';
              break;
            case 2:
              libelle += ' sans sinistre';
              break;
            default:
          }

          break;
      }

      switch(objFiltre.cloturer){
        case 1:
          libelle += ', cloturés';
          break;
        case 2:
          libelle += ', non cloturées';
          break;
        default:
      }

      if(objFiltre.nonlu) libelle += ', non lu';

      if(_.isObject(objFiltre.tagType) || (_.isArray(objFiltre.tags) && objFiltre.tags.length)){
        libelle += ' filtrés par tags ';
      }

      /*
      if(_.isArray(objFiltre.listeDestinataireEvenement) && objFiltre.listeDestinataireEvenement.length){

        var listLibelle = objFiltre.listeDestinataireEvenement.map(function(dest){
          return dest.libelle;
        });
        libelle += ' destinataire ' + listLibelle.join(', ');
      }*/

      if(_.isArray(objFiltre.listeFournisseurMail) && objFiltre.listeFournisseurMail.length){
        libelle += ' filtrés par mails ';
      }

      if(_.isArray(objFiltre.listeFournisseurPersoICS) && objFiltre.listeFournisseurPersoICS.length){
        if(_.isArray(objFiltre.listeFournisseurMail) && objFiltre.listeFournisseurMail.length) {libelle += 'et';}
        else {libelle += ' filtrés';}

        libelle += ' par perso ICS ';
      }
      if((_.isArray(objFiltre.listeFournisseurPersoICS) && objFiltre.listeFournisseurPersoICS.length) || (_.isArray(objFiltre.listeFournisseurMail) && objFiltre.listeFournisseurMail.length)) {
        libelle += 'destinataires des demandes de devis ou interventions.';
      }

      return libelle;
    }

    /**
     * Sauvegarde le filtre dans les configs Collabore du localstorage
     * @param objFiltre
     */
    function saveFiltre(objFiltre){
      ConfigService.setPreferencesFiltreTopics(objFiltre);
    }

    /**
     * Clean le filtre
     */
    function cleanFiltre(){
      $rootScope.current.pageTopic = 1;
      var filtre = getFiltreTopics();
      var idChannel = filtre.idChannel;
      var rechercheElasticsearch = filtre.rechercheElasticsearch;
      filtre = resetFiltre();

      if(UtilsService.isICS()) {
        var chan = UtilsService.getCurrentUser().getChannelAccueil();
        idChannel = (chan) ? chan.getIdChannel() : null;
      }
      if(!_.isNil(idChannel)) filtre.idChannel = idChannel;

      if(rechercheElasticsearch) {
        rechercheElasticsearch.isActive = false;
        filtre.rechercheElasticsearch = rechercheElasticsearch;
      }
      setFiltreTopics(filtre);
    }

    /**
     * Permet de reset le filtre
     */
    function resetFiltre(){
      setFiltreTopics(getBaseFiltre());
      return getBaseFiltre();
    }
  }

})();
