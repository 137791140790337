(function () {

  'use strict';

  /**
   * @desc Composant annuaire
   * @example <choix-adresse></annuaire>
   */
  angular
    .module('collaboreApp')
    .component('choixAdresse', {
      templateUrl: 'app/annuaire/choix-adresse.component.html',
      controllerAs: 'choixadressectrl',
      controller: ChoixAdresseCtrl,
      bindings: {
        selectedAdresse: '<',                    // Les params passé dans un objet
        adresses: '<',
        onEmit: '&',

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<'
      }
    });

  /*@ngInject*/
  function ChoixAdresseCtrl(UtilsService) {
    var _this = this;
    _this.selected = false;

    _this.onClickConfirm = onClickConfirm;
    _this.onClickClose = onClickClose;

    _this.$onInit = function () {
      if(_this.resolve && _this.modalInstance) {
        _this.selectedAdresse = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'selectedAdresse'));
        _this.adresses = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'adresses'));
      }
      init();
    };

    _this.$onChanges = function(changes){
      if(_.isObject(changes.selectedAdresse) && !changes.selectedAdresse.isFirstChange() && changes.selectedAdresse.currentValue){
        _this.selectedAdresse = changes.selectedAdresse.currentValue;
        init();
      }
    };

    _this.$onDestroy = function () {
    };

    function init(){
      if (_.isObject(_this.selectedAdresse)) {
        if (!_.isArray(_this.adresses)) _this.adresses = [];
        if (_this.adresses.length) {

          if(_.isObject(_this.selectedAdresse)) {
            if(!_.isNil(_this.selectedAdresse.nomPrenom) || !_.isNil(_this.selectedAdresse.rue) || !_.isNil(_this.selectedAdresse.codePostal) || !_.isNil(_this.selectedAdresse.ville)) {
              for(var i = 0; i < _this.adresses.length; i++){
                if(_this.adresses[i].nomPrenom === _this.selectedAdresse.nomPrenom &&
                  _this.adresses[i].rue === _this.selectedAdresse.rue &&
                  _this.adresses[i].codePostal === _this.selectedAdresse.codePostal &&
                  _this.adresses[i].ville === _this.selectedAdresse.ville) {
                    _this.selected = _this.adresses[i];
                  break;
                }
              }
              /*
              if(!_this.selected) {

                  _this.selected = angular.copy(_this.params.selectedAdresse);
                  _this.selected.custom = true;

                  _this.adresses.push(_this.selected);
              }*/
            }

          }
        }
      }
    }

    function onClickConfirm() {
      emit({adresse: _this.selected});
    }

    function onClickClose() {
      close();
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      if(_this.modalInstance) _this.close({$value: objRetour});
      else _this.onEmit({obj: objRetour});
    }

    /**
     * Si ouvert en mode Modal
     */
    function close(){
      if(_this.modalInstance) _this.dismiss({$value: 'cancel'});
      else emit({action: 'closeChoixAdresse'});
    }
  }

})();
