(function () {
  'use strict';

  /**
   * @desc Dashboard Collab - Page de statistiques et d'informations utiles
   */

  angular
    .module('collaboreApp')
    .component('dashboardAccueil', {
      templateUrl:  'app/dashboard/dashboard-accueil/dashboard-accueil.component.html',
      bindings: {
        search: '<',
        idChannel: '<',
        onEmit: '&'
      },
      controllerAs: 'dashboardaccueilctrl',
      controller:   DashboardAccueilController
    });

  /** @ngInject */
  function DashboardAccueilController(COLLAB_CONF, $rootScope, $timeout, $scope, $state, DashboardService, FiltreService, UtilsService, SocketNotificationService, InfosDashboard) {

    var ctrl = this;
    ctrl.loading = false;
    ctrl.infos = null;
    ctrl.slide = 1;

    //var listenerOnSocketConnected = null;

    ctrl.onClickSlidePrevious = onClickSlidePrevious;
    ctrl.onClickSlideNext = onClickSlideNext;

    var pieColors = (function () {
      var colors = [],
        base = '#FF0000',
        i;

      for (i = 0; i < 10; i += 1) {
        // Start out with a darkened base color (negative brighten), and end
        // up with a much brighter color
        colors.push(Highcharts.Color(base).brighten((i - 3) / 7).get());
      }
      return colors;
    }());


    var datesNbTopicNonClotureAvecDateEcheanceDepasseeMoinsDe7Jours = {
      startDate: moment().subtract(7, 'days'),
      endDate: moment(),
      label: 'Moins de 7 jours'
    };

    var datesNbTopicNonClotureAvecDateEcheanceDepasseePlusDe7Jours = {
      startDate: moment().subtract(14, 'days'),
      endDate: moment().subtract(7, 'days'),
      label: 'Entre 7 et 14 jours'
    };

    var datesNbTopicNonClotureAvecDateEcheanceDepasseePlusDe14Jours = {
      startDate: null,
      endDate: moment().subtract(14, 'days'),
      label: 'Plus de 14 jours'
    }

    ctrl.$onInit = function(){

      $timeout(function(){
        $scope.$emit('maximizeLeftSidebar');
      });


      var baseFiltre = {};
      if (angular.isDefined(ctrl.search)) {
        var search = decodeSearch(ctrl.search);
        if(_.isObject(search)) {
          if(search.hasOwnProperty('idChannel')) {
            //if(search.hasOwnProperty('idChannel') && search.idChannel && search.idChannel != 0 && !_.isNil(search.idChannel)) {
            baseFiltre.idChannel = search.idChannel;
            onEmit(search);
          }
        }
      }
      else if (angular.isDefined(ctrl.idChannel)) {
        baseFiltre.idChannel = ctrl.idChannel;
      }

      initFiltres(baseFiltre);

      ctrl.chartsTopicEcheanceDepassee = {
        chartConfig: {

          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: 150,
            width: 150
          },
          title: {
            text: null
          },
          exporting: {
            enabled: false
          },
          legend: {
            enabled: false,
            //floating: true,
            //borderWidth: 1,
            align: 'left',
            verticalAlign: 'bottom',
            layout: 'vertical',
            x: 0,
            y: 0,
            //maxHeight: 250,
            labelFormatter: function () {
              return this.y + ' ' + this.name;
            },
            useHTML: true/*,
                navigation: {
                  activeColor: '#3E576F',
                  animation: true,
                  arrowSize: 12,
                  inactiveColor: '#CCC',
                  style: {
                    fontWeight: 'bold',
                    color: '#333',
                    fontSize: '12px'
                  }
                }*/
          },
          plotOptions: {
            pie: {
              allowPointSelect: false,
              cursor: 'pointer',
              colors: pieColors,
              showInLegend: true,
              dataLabels: {
                enabled: false,
                format: '<b>{point.name}</b><br>{point.y}',
                distance: -10/*,
                filter: {
                  property: 'percentage',
                  operator: '>',
                  value: 4
                }*/
              },
              point: {

                events: {
                  mouseOver: function () {
                    var desc = '';
                    var lien = '';
                    switch(this.type) {
                      case 'less7':
                        desc = 'depuis moins de 7 jours';
                        lien = $state.href('dashboard.reporting', ctrl.stateNbTopicNonClotureAvecDateEcheanceDepasseeMoinsDe7Jours, {reload:true});
                        break;
                      case '7to14':
                        desc = 'entre 7 et 14 jours';
                        lien = $state.href('dashboard.reporting', ctrl.stateNbTopicNonClotureAvecDateEcheanceDepasseePlusDe7Jours, {reload:true});
                        break;
                      case 'more14':
                        desc = 'depuis plus de 14 jours';
                        lien = $state.href('dashboard.reporting', ctrl.stateNbTopicNonClotureAvecDateEcheanceDepasseePlusDe14Jours, {reload:true});
                        break;
                      default:
                        desc = '';

                    }
                    angular.element('#nbTopicEcheanceDepasseAvecDate').html(this.y);
                    angular.element('#descriptionTopicEcheanceDepasseAvecDate').html(desc);
                    angular.element('#lienNbTopicEcheanceDepasseAvecDate').removeAttr('ng-href').attr('href', lien);
                    //ctrl.descriptionTopicEcheanceDepasseAvecDate = desc;
                  },
                  ///mouseOut: function () {
                  //},
                  legendItemClick: function () {
                    return false;
                  },
                  select: function () {
                  },
                  click: function () {
                    //$state.go('dashboard.reporting',  parseSearch({classe: '2', dateEcheance: this.searchByDateEcheance, nonCloture: true}), {location: true, reload: true, inherit: false});
                    $state.go('dashboard.reporting',  this.stateHref, {location: true, reload: true, inherit: false});
                    return false;
                  }
                }
              }
            }
          },
          series: [{
            name: 'Nombre Topic',
            data: [/*
                            {
                                type: 'less7',
                                name: 'Moins de 7 jours',
                                y: 0,
                                searchByDateEcheance: datesNbTopicNonClotureAvecDateEcheanceDepasseeMoinsDe7Jours
                            },
                            {
                                type: '7to14',
                                name: 'Entre 7 et 14 jours',
                                y: 0,
                                searchByDateEcheance: datesNbTopicNonClotureAvecDateEcheanceDepasseePlusDe7Jours
                            },
                            {
                                type: 'more14',
                                name: 'Plus de 14 jours',
                                y: 0,
                                searchByDateEcheance: datesNbTopicNonClotureAvecDateEcheanceDepasseePlusDe14Jours
                            }
                        */]
          }],
          credits: {
            enabled: false
          }
        }
      };

      SocketNotificationService.on('update-infos-dashboard', onUpdateInfosDashboard,
        function searchDashboardAfterConnectSocket(){
          searchInfosDashboard(baseFiltre);
        }, true);
    };

    ctrl.$onDestroy = function () {
      SocketNotificationService.removeListener('update-infos-dashboard');

      /*
      if(SocketNotificationService.isConnected()) {
          SocketNotificationService.getInstanceSocketIO().removeListener('update-infos-dashboard', onUpdateInfosDashboard);
      }*/
      //if(!_.isNil(listenerOnSocketConnected)) listenerOnSocketConnected();
    };

    function searchInfosDashboard(baseFiltre){
      ctrl.loading = true;
      ctrl.erreur = null;
      ctrl.loadingNbTopicAvecMessagesNonLu = true;
      ctrl.loadingNbDemandeInterventionEnCours = true;
      ctrl.loadingNbDemandeDevisEnCours = true;
      ctrl.loadingNbTopicNonClotureAvecDateEcheanceDepassee = true;

      ctrl.loadingNbTopicNonClotureAvecDateEcheanceDepasseeMoinsDe7Jours = true;
      ctrl.loadingNbTopicNonClotureAvecDateEcheanceDepasseePlusDe7Jours = true;
      ctrl.loadingNbTopicNonClotureAvecDateEcheanceDepasseePlusDe14Jours = true;

      ctrl.loadingNbTopicNonClotureSansDateEcheanceCreePlusDe7Jours = true;
      ctrl.loadingNbTopicNonClotureSansDateEcheanceCreePlusDe30Jours = true;
      ctrl.loadingNbFactureNonTraitee = true;
      ctrl.loadingNbFactureNonTraiteeArchives = true;

      ctrl.loadingNbTopicSansEventAvecTagToctoctoc = true;
      ctrl.loadingNbTopicSansEventAvecTagExtranet = true;
      ctrl.loadingNbTopicAvecTagRapportVisite = true;

      baseFiltre.desynchInfosDashboard = SocketNotificationService.isConnected();
      DashboardService
        .searchInfosDashboard(baseFiltre)
        .then(function(infos){
          if(_.isObject(infos)) {
            initVars(infos, baseFiltre);
          }
        })
        .catch(function(err){
          ctrl.erreur = err;
        })
        .finally(function(){
          ctrl.loading = false;
        });
    }

    function onUpdateInfosDashboard(json){
      if(_.isObject(json) && json.success && _.isObject(json.infosDashboard)) {
        json.infosDashboard = new InfosDashboard(json.infosDashboard);
        initVars(json);
      }
    }

    function initVars(objetRetour, baseFiltre){
      if(!_.isObject(objetRetour) || (_.isObject(baseFiltre) && baseFiltre.desynchInfosDashboard && objetRetour.asynchrone)) {
        return;
      }

      ctrl.resultElasticSearch = objetRetour.resultElasticSearch;
      /*
      if(!_.isObject(infos) || isFirstInit) {
          if(isFirstInit) ctrl.infos = infos;
          return;
      }*/

      var infos = objetRetour.infosDashboard;
      var chartObject = null;
      if(_.hasIn(ctrl.chartsTopicEcheanceDepassee.chartConfig, 'getChartObj')){
        chartObject = ctrl.chartsTopicEcheanceDepassee.chartConfig.getChartObj();
      }

      // Si pas de nb dans le 1er slide et pas de nb topic pour le deuxième slide alors N'affiche pas le bloc
      if(infos.nbTopicNonClotureAvecDateEcheanceDepassee === 0 && infos.nbTopicNonClotureSansDateEcheanceCreePlusDe7Jours === 0) {
        ctrl.hidePanelSlide = true;
      }
      // Si rien dan le slide 1 alors paasse au 2
      else if(infos.hasOwnProperty('nbTopicNonClotureAvecDateEcheanceDepassee') && infos.nbTopicNonClotureAvecDateEcheanceDepassee === 0) {
        ctrl.slide = 2;
        ctrl.panelSlideHideControlLeft = true;
      }
      // Si rien dan le slide 2 alors passe au 1
      else if(infos.hasOwnProperty('nbTopicNonClotureSansDateEcheanceCreePlusDe7Jours') && infos.nbTopicNonClotureSansDateEcheanceCreePlusDe7Jours === 0) {
        ctrl.slide = 1;
        ctrl.panelSlideHideControlRight = true;
      }

      if(infos.hasOwnProperty('nbTopicNonClotureAvecDateEcheanceDepasseeMoinsDe7Jours')){
        var data = {
          type: 'less7',
          name: 'Moins de 7 jours',
          y: infos.nbTopicNonClotureAvecDateEcheanceDepasseeMoinsDe7Jours,
          searchByDateEcheance: datesNbTopicNonClotureAvecDateEcheanceDepasseeMoinsDe7Jours,
          stateHref: ctrl.stateNbTopicNonClotureAvecDateEcheanceDepasseeMoinsDe7Jours
        };
        if(chartObject && !_.isNil(chartObject.series)) chartObject.series[0].addPoint(data);
        ctrl.chartsTopicEcheanceDepassee.chartConfig.series[0].data[0] = data;
      }

      if(infos.hasOwnProperty('nbTopicNonClotureAvecDateEcheanceDepasseePlusDe7Jours')){
        var data = {
          type: '7to14',
          name: 'Entre 7 et 14 jours',
          y: infos.nbTopicNonClotureAvecDateEcheanceDepasseePlusDe7Jours,
          searchByDateEcheance: datesNbTopicNonClotureAvecDateEcheanceDepasseePlusDe7Jours,
          stateHref: ctrl.stateNbTopicNonClotureAvecDateEcheanceDepasseePlusDe7Jours
        };
        if(chartObject && !_.isNil(chartObject.series))  chartObject.series[0].addPoint(data);
        ctrl.chartsTopicEcheanceDepassee.chartConfig.series[0].data[1] = data;
      }

      if(infos.hasOwnProperty('nbTopicNonClotureAvecDateEcheanceDepasseePlusDe14Jours')){
        var data = {
          type: 'more14',
          name: 'Plus de 14 jours',
          y: infos.nbTopicNonClotureAvecDateEcheanceDepasseePlusDe14Jours,
          searchByDateEcheance: datesNbTopicNonClotureAvecDateEcheanceDepasseePlusDe14Jours,
          stateHref: ctrl.stateNbTopicNonClotureAvecDateEcheanceDepasseePlusDe14Jours
        };
        if(chartObject && !_.isNil(chartObject.series)) chartObject.series[0].addPoint(data);
        ctrl.urlNbTopicNonClotureAvecDateEcheanceDepasseePlusDe14Jours = $state.href('dashboard.reporting', ctrl.stateNbTopicNonClotureAvecDateEcheanceDepasseePlusDe14Jours, {reload:true});
        ctrl.chartsTopicEcheanceDepassee.chartConfig.series[0].data[2] = data;
      }



      if(infos.hasOwnProperty('nbTopicAvecMessagesNonLu')) ctrl.loadingNbTopicAvecMessagesNonLu = false;
      if(infos.hasOwnProperty('nbDemandeInterventionEnCours')) ctrl.loadingNbDemandeInterventionEnCours = false;
      if(infos.hasOwnProperty('nbDemandeDevisEnCours')) ctrl.loadingNbDemandeDevisEnCours = false;
      if(infos.hasOwnProperty('nbTopicNonClotureAvecDateEcheanceDepassee')) ctrl.loadingNbTopicNonClotureAvecDateEcheanceDepassee = false;

      if(infos.hasOwnProperty('nbTopicNonClotureAvecDateEcheanceDepasseeMoinsDe7Jours')) ctrl.loadingNbTopicNonClotureAvecDateEcheanceDepasseeMoinsDe7Jours = false;
      if(infos.hasOwnProperty('nbTopicNonClotureAvecDateEcheanceDepasseePlusDe7Jours')) ctrl.loadingNbTopicNonClotureAvecDateEcheanceDepasseePlusDe7Jours = false;
      if(infos.hasOwnProperty('nbTopicNonClotureAvecDateEcheanceDepasseePlusDe14Jours')) ctrl.loadingNbTopicNonClotureAvecDateEcheanceDepasseePlusDe14Jours = false;

      if(infos.hasOwnProperty('nbTopicNonClotureSansDateEcheanceCreePlusDe7Jours')) ctrl.loadingNbTopicNonClotureSansDateEcheanceCreePlusDe7Jours = false;
      if(infos.hasOwnProperty('nbTopicNonClotureSansDateEcheanceCreePlusDe30Jours')) ctrl.loadingNbTopicNonClotureSansDateEcheanceCreePlusDe30Jours = false;
      if(infos.hasOwnProperty('nbFactureNonTraitee')) ctrl.loadingNbFactureNonTraitee = false;
      if(infos.hasOwnProperty('nbFactureNonTraiteeArchives')) ctrl.loadingNbFactureNonTraiteeArchives = false;

      if(infos.hasOwnProperty('nbTopicSansEventAvecTagExtranet')) ctrl.loadingNbTopicSansEventAvecTagExtranet = false;
      if(infos.hasOwnProperty('nbTopicSansEventAvecTagToctoctoc')) ctrl.loadingNbTopicSansEventAvecTagToctoctoc = false;
      if(infos.hasOwnProperty('nbTopicAvecTagRapportVisite')) ctrl.loadingNbTopicAvecTagRapportVisite = false;

      if(_.isNil(ctrl.infos)) {
        ctrl.infos = {};
      }
      ctrl.infos = _.merge(ctrl.infos, infos);
    }

    function onEmit(search){
      ctrl.onEmit({
        obj: {
          idChannel: search.idChannel
        }
      });
    }

    function initFiltres(baseFiltre){

      // Filtre de base par channel
      ctrl.stateByChannel =  FiltreService.getObjFiltreByChannel(baseFiltre.idChannel);

      // Filtre pour tag #Extranet
      ctrl.stateTagExtranet =  FiltreService.getObjFiltreByTagWithoutEvent(-1 , {libelle: UtilsService.parseTag(COLLAB_CONF.TAG_EXTRANET)}, true, true);

      // Filtre pour tag #Toctoctoc
      ctrl.stateTagToctoctoc =  FiltreService.getObjFiltreByTagWithoutEvent(-1 , {libelle: UtilsService.parseTag(COLLAB_CONF.TAG_TOCTOCTOC)}, true, true);

      // Filtre pour tag #RapportVisite
      ctrl.stateTagRapportVisite =  FiltreService.getObjFiltreByTags(null , {libelle: UtilsService.parseTag(COLLAB_CONF.TAG_RAPPORT_VISITE)}, true);

      // Permet de récupérer l'objet utilisé en paramètre du state des messages non lus
      ctrl.stateMessageNonLus =  _.merge({}, baseFiltre, FiltreService.getObjFiltreTopicNonLu(true));

      // Genère l'objet state du reporting pour filtrer sur les demandes de devis
      ctrl.stateInterventionEnCours = parseSearch(_.merge(angular.copy(baseFiltre), {classe: '2', demandeIntervention: true, nonCloture: true, nonArchive: true}));

      // Genère l'objet state du reporting pour filtrer sur les demandes de devis
      ctrl.stateDemandeInterventionEnCoursPlusDe10Jours = parseSearch(_.merge(angular.copy(baseFiltre), {
        classe: '2',
        demandeIntervention: true,
        nonCloture: true,
        nonArchive: true,
        dateCreation: {
          startDate: null,
          endDate: moment().subtract(10, 'days'),
          label: 'Plus de 10 jours'
        }
      }));

      // Genère l'objet state du reporting pour filtrer sur les demandes de devis
      ctrl.stateDemandeDevis = parseSearch(_.merge(angular.copy(baseFiltre), {classe: '2', demandeDevis: true, nonCloture: true, nonArchive: true}));

      // Genère l'objet state du reporting pour filtrer sur les demandes de devis non validées
      ctrl.stateDemandeDevisNonValide = parseSearch(_.merge(angular.copy(baseFiltre), {classe: '2', demandeDevisNonValide: true, nonCloture: true, nonArchive: true}));

      // Genère l'objet state du reporting pour filtrer sur les evenement sans date echeance inactif
      ctrl.stateEventInactifSansDateEcheancePlusDe7Jours = parseSearch(_.merge(angular.copy(baseFiltre), {
        classe: '2',
        topicSansEcheance: true,
        nonCloture: true,
        nonArchive: true,
        order: 'nbJour',
        sens: 'DESC',
        dateCreation: {
          startDate: null,
          endDate: moment().subtract(7, 'days'),
          label: 'Plus de 7 jours'
        }
      }));

      // Genère l'objet state du reporting pour filtrer sur les evenement sans date echeance inactif
      ctrl.stateEventInactifSansDateEcheancePlusDe30Jours = parseSearch(_.merge(angular.copy(baseFiltre), {
        classe: '2',
        topicSansEcheance: true,
        nonCloture: true,
        nonArchive: true,
        order: 'nbJour',
        sens: 'DESC',
        dateCreation: {
          startDate: null,
          endDate: moment().subtract(30, 'days'),
          label: 'Plus de 30 jours'
        }
      }));

      // Topics non cloturé avec date d'échéance dépassée
      ctrl.stateTopicEcheanceDepassee = parseSearch(_.merge(angular.copy(baseFiltre), {
        classe: '2',
        nonCloture: true,
        nonArchive: true,
        dateEcheance: {
          startDate: null,
          endDate: moment().subtract(1, 'days'),
          label: 'Echéance dépassée'
        }}));

      // Topics non cloturé avec date d'échéance dépassée de moins de 7 jours
      ctrl.stateNbTopicNonClotureAvecDateEcheanceDepasseeMoinsDe7Jours = parseSearch(_.merge(angular.copy(baseFiltre), {
        classe: '2',
        nonCloture: true,
        nonArchive: true,
        dateEcheance: datesNbTopicNonClotureAvecDateEcheanceDepasseeMoinsDe7Jours
      }));

      // Topics non cloturé avec date d'échéance dépassée entre 7 jours et 14 jours
      ctrl.stateNbTopicNonClotureAvecDateEcheanceDepasseePlusDe7Jours = parseSearch(_.merge(angular.copy(baseFiltre), {
        classe: '2',
        nonCloture: true,
        nonArchive: true,
        dateEcheance: datesNbTopicNonClotureAvecDateEcheanceDepasseePlusDe7Jours
      }));

      // Topics non cloturé avec date d'échéance dépassée de plus de 14 jours
      ctrl.stateNbTopicNonClotureAvecDateEcheanceDepasseePlusDe14Jours = parseSearch(_.merge(angular.copy(baseFiltre), {
        classe: '2',
        nonCloture: true,
        nonArchive: true,
        dateEcheance: datesNbTopicNonClotureAvecDateEcheanceDepasseePlusDe14Jours
      }));

    }

    function decodeSearch(search) {
      var parsedWordArray = CryptoJS.enc.Base64.parse(search);
      var parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
      return angular.fromJson(parsedStr);
    }

    function parseSearch(obj){
      var objCrypt = CryptoJS.enc.Utf8.parse(angular.toJson({filtreDashboard: obj}));
      return {
        search:CryptoJS.enc.Base64.stringify(objCrypt)
      };
    }

    function onClickSlidePrevious(){
      ctrl.slide = 1;
    }

    function onClickSlideNext(){
      ctrl.slide = 2;
    }

    /**
     * Genère l'objet state du reporting pour filtrer sur les demandes de devis
     * @returns {{search}}
     *
     function getObjStateInterventionEnCours(){
      return parseSearch({demandeIntervention: true, nonCloture: true});
    }*/

    /**
     * Genère l'objet state du reporting pour filtrer sur les demandes de devis
     * @returns {{search}}
     *
     function getObjStateDemandeDevis(){
      return parseSearch({demandeDevis: true, nonCloture: true});
    }*/

    /**
     * Genère l'objet state du reporting pour filtrer sur les demandes de devis non validées
     * @returns {{search}}
     *
     function getObjStateDemandeDevisNonValide(){
      return parseSearch({demandeDevisNonValide: true, nonCloture: true});
    }*/

    /**
     * Genère l'objet state du reporting pour filtrer sur les evenement sans date echeance inactif
     * @returns {{search}}
     *
     function getObjStateEventInactifSansDateEcheance(){
      return parseSearch({topicSansEcheance: true, nonCloture: true, order: 'nbJour', sens: 'DESC'});
    }*/

    /**
     * Permet de récupérer l'objet utilisé en paramètre du state des messages non lus
     * @returns {{topicPage, search}}
     *
     function getObjStateMessageNonLus() {
      return FiltreService.getObjFiltreTopicNonLu();
    }*/

  }
})();
