(function () {
    'use strict';

    angular
        .module('collaboreApp')
        .component('creationPdfTopic', {
            templateUrl: 'app/topics/send-ged/creation-pdf-topic.component.html',
            controller: CreationPdfTopicController,
            controllerAs: 'creationpdftopicctrl',
            bindings: {
                onUpdate: '&',
                param: '<'
            }
        });

    /** @ngInject */
    function CreationPdfTopicController($scope) {
        var ctrl = this;

        /********** Global **********/
        ctrl.loading = false;

        /********** Global **********/
        ctrl.$onInit = function () {
            ctrl.nomCreationPdf = angular.copy(ctrl.param.nomPdf);

            ctrl.infosCreationPdf = initInfos(ctrl.param.infos);

            $scope.$on('getInfosCreationPdf', function () {
                ctrl.onUpdate({infos: ctrl.param.infos});
            });
        };

        ctrl.$onChanges = function (changes) {
            if (changes.hasOwnProperty('param.nomPdf')) ctrl.nomCreationPdf = angular.copy(changes.param.nomPdf.currentValue);
            if (changes.hasOwnProperty('param.infos')) ctrl.infosCreationPdf = angular.copy(changes.param.infos.currentValue);
        };


        ctrl.$onDestroy = function () {
        };
        function initInfos(infos) {
            if (!infos) {
                return {
                    topicPiecesJointes: false,
                    topicCourriers: false,
                    topicDevisOuIntervention: false,
                    topicDevisSigne: false,
                    topicSinistre: false,
                    topicMessages: false
                };
            }
            return angular.copy(infos);
        }


    }
})();

