'use strict';

/**
 * @ngdoc service
 * @name collaboreApp.TamponService
 * @description
 * # TamponService
 * Service in the collaboreApp.
 */
angular.module('collaboreApp').service('TamponService', ['COLLAB_CONF','$q','TamponAjax', function(COLLAB_CONF,$q,TamponAjax) {

  var scope = this;

  scope.getTampons = function(){
    var deferred = $q.defer();

    TamponAjax.getTampons(function(json){
      if(json.nb!=='0'){
        deferred.resolve(json.Tampons);
      }else{
        deferred.reject('Pas de tampons');
      }
    },function(error){
      var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
      deferred.reject('Problème avec TamponService.getTampons(), '+msgErreur);
    });
    return deferred.promise;
  };

  scope.addTampon = function(texteTampon){

    var deferred = $q.defer();

    var params = {
      'texte' : texteTampon
    };

    TamponAjax.addTampon(params,function(json){

      if(json.Tampons[0]){
        deferred.resolve(json.Tampons[0]);
      }else{
        deferred.reject(false);
      }
    },function(error){
      var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
      deferred.reject('Problème avec TamponService.addTampon(), '+msgErreur);
    });

    return deferred.promise;
  };


  scope.deleteTampon = function(tampon){
    var deferred = $q.defer();

    var params = {
      'tampon' : tampon.idTampon
    };

    TamponAjax.deleteTampon(params,function(json){

      if(json.Resultats[0].success){
        deferred.resolve(true);
      }else{
        deferred.reject(false);
      }
    },function(error){
      var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
      deferred.reject('Problème avec TamponService.deleteTampon(), '+msgErreur);
    });

    return deferred.promise;
  };

}]);
