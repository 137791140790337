(function() {

  'use strict';

  /**
   * @desc Composant du choix de fournisseur
   * @example <choose-fournisseur></choose-fournisseur>
   */
  angular
    .module('collaboreApp')
    .component('chooseFournisseur',{
      templateUrl: 'app/components/choose-fournisseur/choose-fournisseur.component.html',
      controllerAs: 'choosefournisseurctrl',
      bindings: {
        search: '<',
        fournisseurs: '<',
        portefeuille: '<',
        immeuble: '<',
        typeMetier: "<",
        onEmit: '&'
      },
      controller: ChooseFournisseurCtrl
    });

  /** @ngInject */
  function ChooseFournisseurCtrl($scope, $q, $timeout, UtilsService, AnnuaireService, OcrFrontService, ContratIcsCloudService, ComfactService){
    var _this = this;
    _this.loading = false;
    _this.defaultSearchOCR = null;
    _this.defaultSearch = "";
    _this.lastSearch = "";
    _this.selectedFournisseur = null;
    _this.forceChoose = true;
    _this.listIdPortefeuille = [];
    _this.listPortefeuille = [];
    _this.listFournisseur = [];

    _this.callFournisseur = callFournisseur;
    _this.onSelect = onSelect;
    _this.groupeBy = groupeBy;
    _this.onKeyUp = onKeyUp;
    _this.onKeyDown = onKeyDown;
    _this.onClickClearFournisseur = onClickClearFournisseur;

    var listernerClearSelectedFournisseur = null, listenerFocus = null, listernerSelecteFournisseur = null;

    _this.$onInit = function() {
      listenerFocus = $scope.$on('focusFournisseur', function () {
        if(_.isNil(_this.selectedFournisseur)){
          $timeout(function(){
            _this.focusClear = true;
            $scope.$broadcast('focusSelectFournisseur');
          });
        }
        else{
          emit({action: 'nextField'});
        }
      });

      listernerClearSelectedFournisseur = $scope.$on("clearSelectedFournisseur", function (event, noEmit) {
        clearFournisseur();
      });

      listernerSelecteFournisseur = $scope.$on("selecteFournisseur", function (event, obj) {
        if(_.isObject(obj) && _.isArray(_this.listFournisseur) && _this.listFournisseur.length){
          for(var i = 0; i < _this.listFournisseur.length; i++){
            if(_this.listFournisseur[i].codeMetier == obj.codeMetier && _this.listFournisseur[i].numFournisseur == obj.numFournisseur){
              setSelectedFournisseur(_this.listFournisseur[i], true);
              break;
            }
          }
        }
      });
    };

    _this.$onChanges = function(changes){

      if(_.isObject(changes.portefeuille)){
        _this.forceChoose = true;
        if(_.isNil(_this.portefeuille)){
          var listPortefeuille = UtilsService.getCurrentUser().getListePortefeuille();
          if(_.isArray(listPortefeuille)){
            if(listPortefeuille.length === 1) _this.portefeuille = listPortefeuille[0];

            _this.listIdPortefeuille = listPortefeuille.map(function(port){
              return port.idPortefeuille;
            });
          }
        }
        else _this.listIdPortefeuille = [_this.portefeuille.idPortefeuille];
      }

      // Si changement dans la liste des fournisseur venant de l'hote
      if(_.isObject(changes.fournisseurs)){
        if(_.isArray(changes.fournisseurs.currentValue)){
          var listFourni = [];
          var listPersoFromICS = [];
          var listPersoFromOCR = [];
          if(_.isArray(_this.fournisseurs) && _this.fournisseurs.length){
            for(var i = 0; i < _this.fournisseurs.length; i++){
              var fourni = angular.copy(_this.fournisseurs[i]);

              if(!fourni.isObjetFournisseur) fourni = ComfactService.initObjetFournisseur(_this.fournisseurs[i]);

              listFourni.push(fourni);

              if(fourni.group === 'ics'){
                listPersoFromICS.push(fourni);
              }
              else if(fourni.group === 'ocr'){
                listPersoFromOCR.push(fourni);
              }
            }
          }
          _this.listFournisseur = listFourni;

          _this.selectedFournisseur = null;
          _this.filtreFournisseur = null;
          _this.focusClear = false;
          _this.lastSearch = "";

          if((_this.listFournisseur.length === 1 || listPersoFromICS.length === 1 || listPersoFromOCR.length === 1) && _this.forceChoose) {
            if(listPersoFromOCR.length === 1) setSelectedFournisseur(listPersoFromOCR[0], true);
            else if(listPersoFromICS.length === 1) setSelectedFournisseur(listPersoFromICS[0], true);
            else setSelectedFournisseur(_this.listFournisseur[0], true);
          }

          refreshViewSelect();
        }
      }

    };

    _this.$onDestroy = function(){
      if(!_.isNil(listenerFocus)) listenerFocus();
      if(!_.isNil(listernerClearSelectedFournisseur)) listernerClearSelectedFournisseur();
      if(!_.isNil(listernerSelecteFournisseur)) listernerSelecteFournisseur();
    };

    function refreshViewSelect(){
      $timeout(function() {
        var uiSelect = angular.element("[name=chooseFournisseur]").controller('uiSelect');
        if(!_.isNil(uiSelect)){
          uiSelect.items = _this.listFournisseur;
          uiSelect.refreshing = true;
        }
      });
    }

    /**
     * Permet d'appeler ICSCloud pour récupérer les fournisseurs
     * @returns {*}
     */
    function callFournisseurICSCloud(search){
      var deferred = $q.defer();

      var paramsBase = {
        avecemail: 0,
        type: 'F',
        usersCollab: 0,
        groupesCollab: 0,
        idPortefeuilles: _this.listIdPortefeuille,
        commencePar: false
      };

      if(_.isString(search)) paramsBase.recherche = search;
      else if(_.isObject(search)){
        if(!_.isNil(search.name))           paramsBase.recherche = search.name;
        if(!_.isNil(search.codeMetier))     paramsBase.codemetier = search.codeMetier;
        if(!_.isNil(search.numFournisseur)) paramsBase.numFournisseur = search.numFournisseur;
      }

      AnnuaireService
        .searchCollaborateur(paramsBase)
        .then(function (objectCollaborateur) {

          var listPersonnalite = [];

          // Si il y a des perso ou fournisseur
          if (_.isObject(objectCollaborateur) && _.isArray(objectCollaborateur.listePortefeuilles) && objectCollaborateur.listePortefeuilles.length) {
            objectCollaborateur.listePortefeuilles.map(function(portefeuille){
              if(_.isArray(portefeuille.getListePersonnalite()) && !_.isEmpty(portefeuille.getListePersonnalite())) {
                for(var p = 0; p < portefeuille.getListePersonnalite().length; p++){
                  var perso = angular.copy(portefeuille.getListePersonnalite()[p]);
                  perso.compteFournisseur = perso.getCompteFournisseur();
                  listPersonnalite.push(ComfactService.initObjetFournisseur(perso));
                }
              }
            });
          }

          deferred.resolve(listPersonnalite);
        })
        .catch(function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    /**
     * Permet d'appeler OCR-Front pour récupérer les comptes fournisseur
     * @param search
     * @returns {*}
     */
    function callFournisseurOCRFront(){
      var deferred = $q.defer();

      if(_.isObject(_this.defaultSearchOCR) && _this.defaultSearchOCR.isOcr && _.isObject(_this.portefeuille) && OcrFrontService.canCallOcrFront(_this.defaultSearchOCR)){

        var searchOcr = {
          portefeuille: _this.portefeuille,
          vatNumber: _this.defaultSearchOCR.vatNumber,
          phone: _this.defaultSearchOCR.phone,
          mail: _this.defaultSearchOCR.email,
          cocnumber: _this.defaultSearchOCR.cocNumber,
          customerBankAccountNumber: _this.defaultSearchOCR.customerBankAccountNumber,
        };

        OcrFrontService
          .getListFounisseur(searchOcr)
          .then(function(list){
            var listPersonnalite = [];
            if(_.isArray(list) && !_.isEmpty(list)) {
              listPersonnalite = list.map(function(obj){
                return ComfactService.initObjetFournisseur(obj);
              });

              listPersonnalite = _.uniqBy(listPersonnalite, 'value');
            }

            deferred.resolve(listPersonnalite);
          })
          .catch(function(err) {
            deferred.reject(err);
          });
      }
      else deferred.resolve([]);
      return deferred.promise;
    }

    /**
     * Recherche les fournisseur dans ICSCloud et OCR-Front
     * @param search
     * @param firstSearch
     * @returns {*}
     */
    function callFournisseur(search, firstSearch){
      if (_.isNil(search)) search = _this.lastSearch;
      if (
          (_.isNil(search) || _.isEmpty(search) || (_this.lastSearch === search && !firstSearch))
      )
        return $q.reject();

      _this.listFournisseur = [];
      _this.loadingSearch = true;
      if(_.isString(search)) _this.lastSearch = angular.copy(search);

      var promises = [];

      if (firstSearch) _this.loading = true;
      promises.push(callFournisseurOCRFront());
      promises.push(callFournisseurICSCloud(search));

      if(!promises.length) return $q.reject();

      return $q.all(promises).then(function(result) {

        var listOCR = result[0];
        var listICS = (!_.isNil(result[1])) ? result[1] : [];
        var listPerso = _.concat(listOCR, listICS);

        if(_this.forceChoose && listPerso.length === 1)
          setSelectedFournisseur(listPerso[0]);

        _this.listFournisseur = _.concat(_this.listFournisseur, listPerso);
      })
      .catch(function(err){
        console.error(err);
      })
      .finally(function(){
        _this.loadingSearch = false;
        _this.loading = false;
        refreshViewSelect();
      });
    }

    /**
     * Lors de la selection d'un fournisseur
     * @param item
     */
    function onSelect(item){
      setSelectedFournisseur(item);
    }

    function groupeBy(item){
      var sugg = (_.isObject(_this.immeuble)) ? "Suggestion(s) pour les contrats de l'immeuble" : "Suggestion(s)";
      var isGestion = ((!_.isNil(_this.typeMetier) && _this.typeMetier != '2'));
      if(isGestion){
        if(item.group === 'contrat') {
          if(!_.isNil(item.numImm)) {
            if(item.numImm == '0000') sugg = "Suggestion(s) pour les contrats généraux";
            else sugg = "Suggestion(s) pour les contrats de l'immeuble numéro " + item.numImm;
          }
          else if(_.isObject(_this.immeuble) && !_.isNil(_this.immeuble.libelle)) sugg += " " + _this.immeuble.libelle;
        }
      }
      else{
        if(item.group === 'contrat' && _.isObject(_this.immeuble) && !_.isNil(_this.immeuble.libelle)) sugg += " " + _this.immeuble.libelle;
      }
      return (item.group === 'contrat') ? sugg : "Suggestion(s)";
    }

    function setSelectedFournisseur(obj, onInit){
      _this.selectedFournisseur = (_.isObject(obj) && !obj.isObjetFournisseur) ? ComfactService.initObjetFournisseur(obj) : obj;
      _this.focusClear = false;
      emit({action: 'selectedFournisseur', next: !onInit, onInit: onInit, fournisseur: _this.selectedFournisseur, value: (_.isObject(_this.selectedFournisseur)) ? _this.selectedFournisseur.value : null});
    }

    function onKeyUp(search){
      if(_this.lastSearch != search && _.isEmpty(search)) callFournisseur(null, false);
    }

    function onKeyDown(event){
      if(event.keyCode === 9){
        if(event.shiftKey) emit({action: 'previousField'});
        else emit({action: 'nextField'});
        _this.focusClear = false;
      }
    }

    function onClickClearFournisseur(){
      clearFournisseur();
    }

    function clearFournisseur(){
      _this.selectedFournisseur = null;
      _this.listFournisseur = [];
      _this.filtreFournisseur = null;
      _this.focusClear = false;
      _this.lastSearch = "";
      _this.forceChoose = false;
      emit({action: 'selectedFournisseur', next: false, fournisseur: null});
    }

    function emit(obj){
      _this.onEmit({obj: obj});
    }

  }
})();
