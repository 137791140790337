(function() {

  'use strict';

  /**
   * @desc Composant recapShootingMail
   * @example <recap-shooting-mail></recap-shooting-mail>
   */
  angular
    .module('collaboreApp')
    .component('recapShootingMail',{
      templateUrl: 'app/recapShootingMail/recapShootingMail.component.html',
      controllerAs: 'recapshootingmailctrl',
      controller: RecapShootingMailCtrl
    });

  /** @ngInject */
  function RecapShootingMailCtrl($locale, RecapShootingMailService, UtilsService, ErreurCollabService, sweet, ModalsService){
    var ctrl = this;

    var defaultLimit = 100;

    ctrl.getEtatMessage = getEtatMessage;                               // Permet de retourner le message par rapport à l'état du mail
    ctrl.getRowEtat = getRowEtat;                               // Permet de retourner la class qu'il faut pour la ligne
    ctrl.clearDatesRecapShootingMails = clearDatesRecapShootingMails;           // Permet de remetre à 0 les dates
    ctrl.onClickRefresh = onClickRefresh;                       // Permet de refresh
    ctrl.onClickResendAll = onClickResendAll;                   // Permet de renvoyer tous le mails
    ctrl.onClickResend = onClickResend;                         // Permet de renvoyer le mail
    ctrl.canResend = canResend;                                 // Permet de savoir si le recapMail peut être renvoyer
    ctrl.isResend = isResend;                                   // Permet de savoir si le recapMail à déjà été renvoyé

    ctrl.nbRecapShootingMailResend = 0;
    ctrl.loadingListeRecapShootingMails = false;
    ctrl.listeRecapShootingMails = [];
    ctrl.listeRecapShootingMailsCanBeResend = [];
    ctrl.listeRecapShootingMailsAlreadyResend = [];
    ctrl.moment = moment;

    var defaultValuesDatePicker = {
      startDate: moment().startOf('isoWeek'),
      endDate:  moment().endOf('isoWeek'),
      label: 'Cette semaine (depuis Lundi)'
    };

    ctrl.limitAllowed = [10, 20, 30, 40, 50, 100, 150, 200, 250, 500]

    ctrl.filtre = {
      show: true,
      dateRecapShootingMail: {                        // Dates des recap mails
        showDates: false,
        isDefault: true,
        startDate: angular.copy(defaultValuesDatePicker.startDate),
        endDate: angular.copy(defaultValuesDatePicker.endDate),
        label: angular.copy(defaultValuesDatePicker.label)
      },
      searchSujet: null,
      searchCorps: null,
      searchDestinataire: null,
      limit: defaultLimit
    };

    ctrl.currentPage = 1;
    ctrl.nbTotal = 0;

    ctrl.optionsDatepicker = {
      showDropdowns: true,
      autoApply: false,
      clearLabel: 'Effacer',
      linkedCalendars: false,
      locale: {
        format: 'DD/MM/YYYY',
        separator: ' au ',
        applyLabel: 'Ok',
        fromLabel: 'Du',
        toLabel: 'au',
        cancelLabel: 'Annuler',
        customRangeLabel: 'Personnaliser',
        daysOfWeek: $locale.DATETIME_FORMATS.SHORTDAY,
        firstDay: 1,
        monthNames: $locale.DATETIME_FORMATS.STANDALONEMONTH
      },
      eventHandlers: {
        'show.daterangepicker': function(){
          ctrl.datePickerDateShow = true;
        },
        'hide.daterangepicker': function(){
          ctrl.datePickerDateShow = false;
        },
        'apply.daterangepicker': function() {
          searchRecapShootingMail(1);
        }
      },
      ranges: {
        'Aujourd\'hui': [moment(), moment()],
        'Hier': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Cette semaine (depuis Lundi)': [angular.copy(defaultValuesDatePicker.startDate), angular.copy(defaultValuesDatePicker.endDate)],
        'Les 7 derniers jours': [moment().subtract(7, 'days'), moment()],
        'Semaine dernière (Lun - Dim)': [moment().subtract(1, 'week').startOf('isoWeek'), moment().subtract(1, 'week').endOf('isoWeek')],
        'Les 14 derniers jours': [moment().subtract(14, 'days'), moment()],
        'Ce mois-ci': [moment().startOf('month'), moment().endOf('month')],
        'Les 30 derniers jours': [moment().subtract(30, 'days'), moment()],
        'Le mois dernier': [moment().subtract(1,'month').startOf('month'), moment().subtract(1,'month').endOf('month')],
        'Toute la période': [moment().startOf('month'), moment().add(1,'month').endOf('month')]
        //'Toute la période': [moment().year(2000).dayOfYear(1).startOf('hour'), moment()]
      },
      opens: 'right'
    };



    ctrl.$onInit = function() {
      searchRecapShootingMail(1);
    };


    /******* Privée *********/

    function searchRecapShootingMail(page){

      if(!_.isNil(page)) ctrl.currentPage = page;
        ctrl.listeRecapShootingMailsAlreadyResend = [];
      ctrl.loadingListeRecapShootingMails = true;

      var paramsSearch = {};

      if(ctrl.filtre.dateRecapShootingMail.startDate !== null){
        if(ctrl.filtre.dateRecapShootingMail.startDate._isAMomentObject) paramsSearch.dateDebut = ctrl.filtre.dateRecapShootingMail.startDate.format('YYYY-MM-DD');
        else paramsSearch.dateDebut = UtilsService.dateFormat(ctrl.filtre.dateRecapShootingMail.startDate,'yyyy-MM-dd');
      }
      if(ctrl.filtre.dateRecapShootingMail.endDate !== null){
        if(ctrl.filtre.dateRecapShootingMail.endDate._isAMomentObject) paramsSearch.dateFin = ctrl.filtre.dateRecapShootingMail.endDate.format('YYYY-MM-DD');
        else paramsSearch.dateFin = UtilsService.dateFormat(ctrl.filtre.dateRecapShootingMail.endDate,'yyyy-MM-dd');
      }

      if(!_.isNil(ctrl.filtre.searchSujet)) paramsSearch.searchSujet = ctrl.filtre.searchSujet;
      if(!_.isNil(ctrl.filtre.searchCorps)) paramsSearch.searchCorps = ctrl.filtre.searchCorps;
      if(!_.isNil(ctrl.filtre.searchDestinataire)) paramsSearch.searchDestinataire = ctrl.filtre.searchDestinataire;
      if(!_.isNil(ctrl.filtre.limit)) {
        paramsSearch.limit = ctrl.filtre.limit;
        paramsSearch.offset = (paramsSearch.limit*ctrl.currentPage)-paramsSearch.limit;
      }

      parseDisplayDates(ctrl.filtre.dateRecapShootingMail);

      RecapShootingMailService
        .getRecapShootingMail(paramsSearch)
        .then(function(retour){
          var emails = retour.emails;

          if(!_.isNil(retour.nbTotal)){
            ctrl.nbTotal = retour.nbTotal;
          }
          else {
            ctrl.nbTotal = 0;
            ctrl.filtre.limit = defaultLimit;
          }

          ctrl.listeRecapShootingMailsCanBeResend = [];
          if(emails.length) {
            var liste = [];
            for(var i = 0; i < emails.length; i++){
              if(emails[i].canResend()) {
                liste.push(emails[i]);
              }
            }
            ctrl.listeRecapShootingMailsCanBeResend = liste;
          }
          ctrl.listeRecapShootingMails = angular.copy(emails);
        })
        .finally(function(){
          ctrl.loadingListeRecapShootingMails = false;
        });
    }

    // Permet de parser les dates qui seront affichés pour les dates pickers
    function parseDisplayDates(obj){
      switch(obj.label) {
        case 'Aujourd\'hui':
          obj.showDates = obj.startDate.format('dddd DD MMMM YYYY').ucfirst();
          break;
        case 'Hier':
          obj.showDates = obj.startDate.format('dddd DD MMMM YYYY').ucfirst();
          break;
        case 'Ce mois-ci':
          obj.showDates = obj.startDate.format('MMMM YYYY').ucfirst();
          break;
        case 'Le mois dernier':
          obj.showDates = obj.startDate.format('MMMM YYYY').ucfirst();
          break;
        case 'Toute la période':
          obj.showDates = false;
          obj.startDate = null;
          obj.endDate = null;
          obj.isDefault = true;
          break;
        default:
          obj.showDates = 'Du ' + obj.startDate.format('DD MMM YYYY') + ' au ' + obj.endDate.format('DD MMM YYYY');
          obj.isDefault = false;
      }
    }


    /********* Public **********/

    function getRowEtat(recapShootingMail){

      var classRow = '';
      if(!ctrl.isResend(recapShootingMail)) {
        switch(recapShootingMail.getStatut()) {
          case 'Refuse':
            classRow = 'danger';
            break;
          case 'Envoye':
            classRow = 'success';
            if(recapShootingMail.isTempEmail()) classRow = '';
            break;
          default:
            classRow = '';
        }

      }
      else classRow = 'info';
      return classRow;
    }

    function getEtatMessage(recapShootingMail){

      var message = null;
      if(!ctrl.isResend(recapShootingMail)) {
        switch(recapShootingMail.getEtat()) {
          case '0':
            message = 'Échec (STATUS_BULLE_FAIL)';
            break;
          case '1':
            message = 'Envoyé (STATUS_BULLE_SENT)';
            break;
          case '2':
            message = 'En attente (STATUS_BULLE_TO_SEND)';
            break;
          case '5':
            message = 'Renvoi avec Smtp Ics OK (STATUS_BULLE_RESENT_OK_WITH_SMTPICS)';
            break;
          case '-5':
            message = 'Renvoi avec Smtp Ics NOK (STATUS_BULLE_RESENT_FAIL_WITH_SMTPICS)';
            break;
          default:
            message = null;
        }
      }
      return message;
    }

    function onClickResendAll(){

      if(ctrl.listeRecapShootingMailsCanBeResend.length){

        sweet.show({
          title: 'Confirmation',
          text: 'Voulez-vous vraiment demander le renvoi de <strong>'+ctrl.listeRecapShootingMailsCanBeResend.length+'</strong> mail(s) ?',
          type: 'warning',
          showCancelButton: true,
          //confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Renvoyer !',
          closeOnConfirm: false,
          showLoaderOnConfirm: true,
          html: true
        }, function(isConfirm) {
          if(isConfirm) {

            RecapShootingMailService
              .resendShootingMails(ctrl.listeRecapShootingMailsCanBeResend)
              .then(function(retour){

                var emails = retour.emails;

                ModalsService.success('Demande de renvoi effectué !<br /><strong>'+retour.message+'</strong>');
                if(emails.length){
                  for(var i = 0; i < emails.length; i++){
                    traiteRecapShootingMailResend(emails[i]);
                  }
                }

                ctrl.nbRecapShootingMailResend = angular.copy(emails.length);
                ctrl.listeRecapShootingMailsCanBeResend = [];
              })
              .catch(function(msg){
                ErreurCollabService.alert(msg);
              });
          }
        });
      }
    }

    function onClickRefresh(page){
      ctrl.nbRecapShootingMailResend = 0;
      searchRecapShootingMail(page);
    }

    function onClickResend(recapShootingMail){
      recapShootingMail.loading = true;
      RecapShootingMailService
        .resendShootingMail(recapShootingMail)
        .then(function(retour){
          var recapShootingMails = retour.emails;

          if(recapShootingMails.length === 1) {

            var currentRecapShootingMail = recapShootingMails[0];

            ctrl.nbRecapShootingMailResend++;

            traiteRecapShootingMailResend(currentRecapShootingMail);

            if(ctrl.listeRecapShootingMailsCanBeResend.length){
              // Parcour la liste des recapMail qui peuvent être renvoyer pour le retiré de la liste
              for(var j = 0; j < ctrl.listeRecapShootingMailsCanBeResend.length; j++){
                if(ctrl.listeRecapShootingMailsCanBeResend[j].id === currentRecapShootingMail.id) {
                  ctrl.listeRecapShootingMailsCanBeResend.splice(j,1);
                  break;
                }
              }
            }
          }
        })
        .finally(function(){
          recapShootingMail.loading = false;
        });
    }

    function traiteRecapShootingMailResend(recapShootingMail){
      // Si le recap traité n'est pas dans la liste des recap déjà rénvoyé on l'ajoute
      if(!isResend(recapShootingMail)) {
        ctrl.listeRecapShootingMailsAlreadyResend.push(recapShootingMail);
      }

      // Si il y a une liste de recapShootingMails affichées
      if(ctrl.listeRecapShootingMails.length) {
        // Parcour les liste des recapShootingMails affichés
        for (var j = 0; j < ctrl.listeRecapShootingMails.length; j++) {
          // Si le recap est le même que celui traité alors on le change dans la liste
          if (ctrl.listeRecapShootingMails[j].id === recapShootingMail.id) {
            ctrl.listeRecapShootingMails[j] = recapShootingMail;
            break;
          }
        }
      }
    }

    function canResend(recapMail){
      return RecapShootingMailService.checkIfExistInListe(recapMail, ctrl.listeRecapShootingMailsCanBeResend);
    }

    function isResend(recapMail){
      return RecapShootingMailService.checkIfExistInListe(recapMail, ctrl.listeRecapShootingMailsAlreadyResend);
    }

    function clearDatesRecapShootingMails(){
      ctrl.filtre.dateRecapShootingMail.startDate = angular.copy(defaultValuesDatePicker.startDate);
      ctrl.filtre.dateRecapShootingMail.endDate = angular.copy(defaultValuesDatePicker.endDate);
      ctrl.filtre.dateRecapShootingMail.label = angular.copy(defaultValuesDatePicker.label);

      ctrl.filtre.dateRecapShootingMail.showDates = false;
      ctrl.filtre.dateRecapShootingMail.isDefault = true;
    }
  }
})();
