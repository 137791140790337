(function () {

  'use strict';

  /**
   * @desc Composant AdminCollab
   * @example <admin-collab-stats></admin-collab-stats>
   */
  angular
    .module('collaboreApp')
    .component('adminCollabStats', {
      templateUrl: 'app/admin/stats/admin-collab-stats.component.html',
      controllerAs: 'admincollabstatsctrl',
      controller: AdminCollabStatsCtrl
    });

  /** @ngInject */
  function AdminCollabStatsCtrl(COLLAB_CONF, $scope, $locale, AdminCollabService, ModalsService, hotkeys, AdminParametrageService) {
    var ctrl = this;

    ctrl.listNbTopicByDiese = [];
    ctrl.order = 'nb';
    ctrl.sens = 'DESC';
    ctrl.editParams = false;

    ctrl.clearDatesCreation = clearDatesCreation;
    ctrl.search = search;
    ctrl.reset = reset;
    ctrl.openDate = openDate;
    ctrl.changeTri = changeTri;
    ctrl.onClickParam = onClickParam;
    ctrl.chartConfigNbTopicByDiese = {
      chartType: 'stock',
      chart: {
        type: 'bar'
      },
      title: {
        text: 'Nombre de topic par diese'
      },
      /*
       subtitle: {
       text: 'Nombre de topic par diese'
       },*/
      xAxis: {
        type: 'category',
        title: {
          text: null
        },
        min: 0,
        max: 4,
        scrollbar: {
          enabled: true
        },
        tickLength: 0
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Nombre de topic',
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        }
      },

      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Topics',
        data: []
      }]
    };
    //ctrl.moment = moment;

    var defaultValuesDatePicker = {
      startDate: moment().startOf('month'),
      endDate: moment().add(1, 'month').endOf('month'),
      label: 'Toute la période'
    };

    ctrl.optionsDatepicker = {
      showDropdowns: true,
      autoApply: false,
      clearLabel: 'Effacer',
      linkedCalendars: false,
      locale: {
        format: 'DD/MM/YYYY',
        separator: ' au ',
        applyLabel: 'Ok',
        fromLabel: 'Du',
        toLabel: 'au',
        cancelLabel: 'Annuler',
        customRangeLabel: 'Personnaliser',
        daysOfWeek: $locale.DATETIME_FORMATS.SHORTDAY,
        firstDay: 1,
        monthNames: $locale.DATETIME_FORMATS.STANDALONEMONTH
      },
      eventHandlers: {
        'show.daterangepicker': function (ev) {
          if (ev.model.datepicker === 'dateCreation') {
            ctrl.datePickerDateCreationShow = true;
          }
        },
        'hide.daterangepicker': function (ev) {
          if (ev.model.datepicker === 'dateCreation') {
            ctrl.datePickerDateCreationShow = false;
          }
        },
        'apply.daterangepicker': function (ev) {
          parseDisplayDates(ev.model);
        }
      },
      ranges: {

        'Aujourd\'hui': [moment(), moment()],
        'Hier': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Cette semaine (depuis Lundi)': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
        'Les 7 derniers jours': [moment().subtract(7, 'days'), moment()],
        'Semaine dernière (Lun - Dim)': [moment().subtract(1, 'week').startOf('isoWeek'), moment().subtract(1, 'week').endOf('isoWeek')],
        'Les 14 derniers jours': [moment().subtract(14, 'days'), moment()],
        'Ce mois-ci': [moment().startOf('month'), moment().endOf('month')],
        'Les 30 derniers jours': [moment().subtract(30, 'days'), moment()],
        'Le mois dernier': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Toute la période': [angular.copy(defaultValuesDatePicker.startDate), angular.copy(defaultValuesDatePicker.endDate)]
        //'Toute la période': [moment().year(2000).dayOfYear(1).startOf('hour'), moment()]
      },
      opens: 'right'
    };

    ctrl.filtre = {
      dureePeriode: 0,						// Durée de la période
      dateCreation: {                         // Dates de création
        datepicker: 'dateCreation',
        showDates: false,
        isDefault: true,
        startDate: angular.copy(defaultValuesDatePicker.startDate),
        endDate: angular.copy(defaultValuesDatePicker.endDate),
        label: angular.copy(defaultValuesDatePicker.label)
      }
    };

    ctrl.datePickerOptions = {
      maxDate: new Date(),
      startingDay: 1,
      showWeeks: true
    };

    ctrl.$onInit = function () {
      search();
      execTri();

      hotkeys.bindTo($scope)
        .add({
          combo: COLLAB_CONF.HOTKEYS_SHOW_FEATURES_ICS.combo,
          description: COLLAB_CONF.HOTKEYS_SHOW_FEATURES_ICS.description,
          callback: function() {
            ctrl.editParams = !ctrl.editParams;
          }
        });
    };

    // Permet de parser les dates qui seront affichés pour les dates pickers
    function parseDisplayDates(obj) {
      switch (obj.label) {
        case 'Aujourd\'hui':
          obj.showDates = obj.startDate.format('dddd DD MMMM YYYY').ucfirst();
          break;
        case 'Hier':
          obj.showDates = obj.startDate.format('dddd DD MMMM YYYY').ucfirst();
          break;
        case 'Ce mois-ci':
          obj.showDates = obj.startDate.format('MMMM YYYY').ucfirst();
          break;
        case 'Le mois dernier':
          obj.showDates = obj.startDate.format('MMMM YYYY').ucfirst();
          break;
        case 'Toute la période':
          obj.showDates = false;
          obj.startDate = null;
          obj.endDate = null;
          obj.isDefault = true;
          break;
        default:
          obj.showDates = 'Du ' + obj.startDate.format('DD MMM YYYY') + ' au ' + obj.endDate.format('DD MMM YYYY');
          obj.isDefault = false;
      }
    }

    function clearDatesCreation() {
      ctrl.filtre.dureePeriode = 0;
      ctrl.filtre.debutCreation = null;
      ctrl.filtre.finCreation = null;

      ctrl.filtre.dateCreation.startDate = angular.copy(defaultValuesDatePicker.startDate);
      ctrl.filtre.dateCreation.endDate = angular.copy(defaultValuesDatePicker.endDate);
      ctrl.filtre.dateCreation.label = angular.copy(defaultValuesDatePicker.label);

      ctrl.filtre.dateCreation.showDates = false;
      ctrl.filtre.dateCreation.isDefault = true;

    }

    function search() {
      ctrl.loadingNbTopicByDiese = true;


      var filtre = angular.copy(ctrl.filtre);

      // Si la date choisi est celle par default alors on supprime les dates
      if (filtre.dateCreation.isDefault) {
        filtre.dateCreation.startDate = null;
        filtre.dateCreation.endDate = null;
      }

      /*
       var date = null;
      if(ctrl.dateSearch) {
          date = moment(ctrl.dateSearch).format('YYYY-MM-DD');
      }*/


      AdminCollabService
        .getStatsNbTopicByDiese(filtre)
        .then(function (retour) {

          //var instanceChart = ctrl.chartConfigNbTopicByDiese.getChartObj();

          /*
           var datas = [];
           if(angular.isArray(retour)) {
           for(var i = 0; i < retour.length; i++) {
           datas.push([retour[i].diese, retour[i].nb]);
           }
           }

           console.log(datas);

           ctrl.chartConfigNbTopicByDiese.series[0].data = datas;

           //instanceChart.series[0].setData(datas);
           /*
           instanceChart.series[0].update({
           name: ctrl.donutTypeTags.selectedType.libelle
           });

           instanceChart.setTitle({
           text: '<strong>' + ctrl.donutTypeTags.selectedType.libelle + '</strong>'
           });*/

          ctrl.listNbTopicByDiese = retour;
        })
        .finally(function () {
          ctrl.loadingNbTopicByDiese = false;
        });
    }

    function reset(){
      ctrl.dateSearch = false;
      search();
    }

    function openDate(){
      ctrl.dateOpened = true;
    }

    function execTri(){
      ctrl.orderBy = ((ctrl.sens !== 'ASC') ? '-' : '')+ctrl.order;
    }

    function changeTri(tri) {
      if (ctrl.order !== tri) {
        ctrl.order = tri;
        ctrl.sens = 'DESC';
      } else {
        if (ctrl.sens === 'DESC') ctrl.sens = 'ASC'; else ctrl.sens = 'DESC';
      }
      execTri();
    }

    function onClickParam(obj, params) {
      if(!_.isNil(params)) {

        if(!obj.hasOwnProperty(params)) {
          ModalsService.alertErreur("Impossible de trouver la propriété \""+params+"\" dans l'objet en paramètre");
          console.error("params", params);
          console.error("obj", obj);
        }
        else {
          var valeur = obj[params];
          ModalsService.confirm(obj.diese, 'Voulez-vous vraiment <strong>'+((!valeur) ? 'Activer' : 'Désactiver')+'</strong> ce paramètre pour <strong>'+obj.diese+'</strong> ?')
            .then(function (modal) {
              AdminParametrageService
                .updateParametre(params, !valeur, obj.diese)
                .then(function(ret){
                  if(!_.isNil(ret)) {
                    obj[params] = angular.copy(ret[params]);
                    if(params === "exportElasticSearchInRealTimeEnabled"){
                      obj.searchByElasticSearchEnabled = angular.copy(ret.searchByElasticSearchEnabled);
                    }
                  }
                  modal.close();
                })
                .catch(function(msg){
                  ModalsService.alertErreur(msg);
                });
            });
        }
      }
    }
  }
})();
