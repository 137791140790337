(function () {
  'use strict';

  /**
   * @desc Formulaire des messages
   * @example <formulaire-message></formulaire-message>
   */

  angular
    .module('collaboreApp')
    .component('formulaireMessage', {
      templateUrl: 'app/topics/formulaire-message/formulaire-message.component.html',
      bindings: {
        allowAddDestinataire: '<',         // Permet d'autoriser ou non l'ajout de destinataire
        chooseContact: '<',         // Permet le choix des email/telephones comme destinataire
        chooseContactPersoExpanded:'<',          // Permet d'ouvrir ou fermer l'accordion "Personnalitées ICS" du composant "choose-infos-contact"
        disallowChooseDestinataires: '<', // Permet de desactiver le choix des destinataires
        useMiniToolbar: '<',    // Permet de savoir si on doit utiliser la version mini de la toolbar
        idTopic: '<',           // id du topic si pas de contenuParent ou contenu
        evenement: '<',         // Evenement si besoin
        contenu: '<',           // Contenu si modification
        idContenuRef: '<',      // Id du contenu en référence
        contenuParent: '<',     // Contenu parent si réponse à un contenu
        destinataires: '<',     // Liste des destinataires, change par rapoort au type de réponse (All ou non)
        destinatairesHide: '<', // Liste des destinataires caché
        params: '<',            // Différentes data venant du parent
        documents: '<',         // Liste des documents à ajouter et/ou checked
        textSujet: '<',         // Sujet par défaut du message si envoyé par email
        textMessage: '<',       // Texte par défaut du message
        typeFormulaire: '@',    // Type du formulaire (email ou sms)
        enableCustomMail: '<',  // Active les email custom(Objet, A etc...)
        enableCustomSms: '<',   // Active les sms custom(champ libre du numéro)
        editMessage: '<',       // Permet de savoir si le message est à éditer ou c'est un nouveau message

        disallowSelectType: '<', // Permet de desactiver le choix du type de formulaire
        hideBtnClose: '<',      // Permet de cacher le bouton close
        onSubmit: '&',
        onEmit: '&',

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<'
      },
      controllerAs: 'formulairemessagectrl',
      controller: FormulaireMessageController
    });

  /*@ngInject*/
  function FormulaireMessageController(uuid, $timeout, $q, $injector, $scope, GroupeService, DocumentManagerService, DocumentsTopicService, NotificationsToastServices, sweet, COLLAB_CONF, COLLAB_VALUES, AutoSaveService, MessageService, UtilsService, ModalsService, ErreurCollabService, TopicManagerService) {

    var ctrl = this;
    ctrl.idContenuParent = null;
    ctrl.important = false;

    //  -- Début Variables pour la gestion des documents à upload --
    var uploader = null;
    var deferredDocs = null;      // Variable pour la promesses des documents
    var listDocsUpload = [];      // Liste des documents upload, util en cas d'erreur d'ajout de contenu
    //var listDocsTopicChecked = [];  // Liste des documents déjà présent dans le topic qui sont ajouter en pièces jointes
    //  -- Fin Variables pour la gestion des documents à upload --

    ctrl.nouveauContenu = null;   // Nouveau Message
    ctrl.tagPortefeuille = null;
    ctrl.listeEmail = [];           // Une liste des emails choisis

    ctrl.uploaderAttachment = {
      action: 'uploadTypedDocument',
      typeDocument: COLLAB_CONF.TYPE_DOC_ENUMS.ATTACHMENT,
      sizeLimit: COLLAB_CONF.SIZE_LIMIT_ATTACHMENTS
    };

    /*
    // Tableau des pièces jointes à envoyer
    ctrl.documentDemande = null;		            // Document demande du devis actuel quand ouvert la communication
    ctrl.documentDevis = null;			            // Document devis du devis actuel quand ouvert la communication
    ctrl.documentDevisSigne = null;	                // Document devis du devis signe actuel quand ouvert la communication
    ctrl.documentAttestationTvaSimple = null;	    // Document attestation tva simplifiée quand ouvert par la communication
    ctrl.documentFacture = null;	                // Document facture
    ctrl.listDocumentContratFournisseurSigne = null;	// Document contrat fournisseur signe
    ctrl.listDocumentContratAgenceSigne = null;	        // Document contrat agence signe
    */

    ctrl.listeDestinataireFromDestinatairesComponent = []; // Liste des destinataire choisi depuis le composant "destinataires"
    var defaultReponseDestinataires = []; // Destinataires lors du reset

    //------------ ChooseInfosContactComponent ---------------/
    ctrl.listePersoForChooseInfosContact = [];          // Liste des perso pour le composant choose infos contact
    ctrl.listePersoCheckedFromChooseInfosContact = [];   // Liste des perso avec email coché venant du composant choose infos contact
    ctrl.listeMailCustomFromChooseInfosContact = [];     // Liste des email custom venant du composant choose infos contact
    ctrl.onEmitFromChooseInfosContactComponent = onEmitFromChooseInfosContactComponent; // Lors de la remontée d'info du composant <choose-infos-contact>

    // --- Fin ChooseInfosContactComponent

    ctrl.listValueFieldA = [];
    ctrl.valueFieldObjet = null;

    ctrl.listValueFieldTel = [];

    ctrl.onKeydownOnFieldDestinataire = onKeydownOnFieldDestinataire;   // Lorsqu'on appui sur une touche d'un champ
    ctrl.onBlurFromFieldDestinataire = onBlurFromFieldDestinataire;     // Lorsqu'on quitte le champ
    ctrl.onClickRemoveFieldValue = onClickRemoveFieldValue;             // Lorsqu'on supprime une value d'un champ
    ctrl.onEmitSelectSignatureContenu = onEmitSelectSignatureContenu;

    ctrl.onEditorReady = onEditorReady;                             // Lorsque l'editeur WYSIWYG est ready
    ctrl.onEditorSmsReady = onEditorSmsReady;                       // Lorsque l'editeur de SMS est ready
    ctrl.onChangeDestinataires = onChangeDestinataires;             // Lors de changements dans les destinataires
    ctrl.onRemoveDestinatairePerso = onRemoveDestinatairePerso;     // Lors de la suppression d'un destinataire Perso
    //ctrl.onDropDocumentFromGedPerso = onDropDocumentFromGedPerso;   // Lorsqu'on drop un document depuis le widget
    //ctrl.onCheckDocumentPartage = onCheckDocumentPartage;
    //ctrl.onClickRemovePiecesJointes = onClickRemovePiecesJointes;   // Lorsqu'on clique sur le buton pour retirer une pièce jointe
    ctrl.onClickSaveTmp = onClickSaveTmp;                           // Lorsqu'on clique sur le bouton de sauvegarde tempoaraire
    ctrl.onClickSave = onClickSave;                                 // Lorsqu'on clique sur le bouton de sauvegarde
    ctrl.onClickClose = onClickClose;                               // Si composant utilisé en modal alors ferme la modal
    //ctrl.onClickReset = onClickReset;                               // Lorsqu'on clique sur le bouton pour reset le conteny

    ctrl.onEmitFromUploadCollab = onEmitFromUploadCollab;
    ctrl.onChangeToggleSms = onChangeToggleSms;

    ctrl.isOverMaxSize = isOverMaxSize;                             // Permet de calculer la taille total des documents choisi
    ctrl.countPiecesJointesInCategorie = DocumentsTopicService.getNbDocumentInCategorie;
    ctrl.bytes = UtilsService.bytes;
    ctrl.truncate = truncate;
    ctrl.txtSendOneMailWithAllDest = COLLAB_VALUES.TEXTE_SEND_ONE_MAIL_FOR_ALL_DEST;

    ctrl.$onInit = function() {

      ctrl.uuid = uuid.new()
      if(ctrl.resolve && ctrl.modalInstance) {
        ctrl.allowAddDestinataire = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'allowAddDestinataire'));
        ctrl.chooseContact = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'chooseContact'));
        ctrl.chooseContactPersoExpanded = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'chooseContactPersoExpanded'));
        ctrl.disallowChooseDestinataires = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'disallowChooseDestinataires'));
        ctrl.useMiniToolbar = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'useMiniToolbar'));
        ctrl.idTopic = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'idTopic'));
        ctrl.evenement = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'evenement'));
        ctrl.contenu = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'contenu'));
        ctrl.idContenuRef = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'idContenuRef'));
        ctrl.contenuParent = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'contenuParent'));
        ctrl.destinataires = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'destinataires'));
        ctrl.destinatairesHide = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'destinatairesHide'));
        ctrl.params = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'params'));
        ctrl.documents = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'documents'));
        ctrl.textSujet = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'textSujet'));
        ctrl.textMessage = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'textMessage'));
        ctrl.typeFormulaire = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'typeFormulaire'));
        ctrl.enableCustomMail = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'enableCustomMail'));
        ctrl.enableCustomSms = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'enableCustomSms'));
        ctrl.disallowSelectType = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'disallowSelectType'));
        ctrl.hideBtnClose = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'hideBtnClose'));
        ctrl.editMessage = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'editMessage'));
      }

      /*
      if(ctrl.useMiniToolbar) {
          ctrl.toolbarEditor = [
              [
                  'FontSize',
                  'TextColor',
                  'BGColor',
                  'Bold',
                  'Italic',
                  'Underline',
                  '-',
                  'Blockquote',
                  '-',
                  'JustifyLeft',
                  'JustifyCenter',
                  'JustifyRight',
                  'JustifyBlock'
              ]
          ];
      }*/

      if(_.isNil(ctrl.allowAddDestinataire)) ctrl.allowAddDestinataire = true;
      ctrl.showOptionSendByMail = true;

      if(ctrl.editMessage === false && _.isObject(ctrl.contenu)){
        if(_.isNil(ctrl.contenuParent)) ctrl.contenuParent = angular.copy(ctrl.contenu);
      }

      // Si on a un contenu parent alors utilise sont idTopic
      if(_.isObject(ctrl.contenuParent) && ctrl.contenuParent.getIdTopic()) {
        ctrl.idTopic = ctrl.contenuParent.getIdTopic();
        ctrl.editMessage = false;
        ctrl.contenu = null;
      }

      // Si il y a un contenu alors c'est une modification
      else if(_.isObject(ctrl.contenu) && ctrl.contenu.getIdContenu()) {
        // Si on a un contenu alors utilise sont idTopic
        if(ctrl.contenu.getIdTopic()) ctrl.idTopic = ctrl.contenu.getIdTopic();

        ctrl.message = ctrl.contenu.getMessage(); // Recup le message à modifier
        ctrl.editMessage = true;
      }

      // TODO: Tous la params ne sont pas pris en compte encore
      if(_.isObject(ctrl.params)) {
        if(_.isObject(ctrl.params.tagPortefeuille)) ctrl.tagPortefeuille = ctrl.params.tagPortefeuille;
        if (!_.isNil(ctrl.params.idTopic) && _.isNil(ctrl.idTopic)) ctrl.idTopic = ctrl.params.idTopic;
        if (!_.isNil(ctrl.params.destinataires)) ctrl.destinataires = ctrl.params.destinataires;
        if (!_.isNil(ctrl.params.destinatairesHide)) ctrl.destinatairesHide = ctrl.params.destinatairesHide;
        if (!_.isNil(ctrl.params.destinatairesExclus)) ctrl.destinatairesExclus = ctrl.params.destinatairesExclus;
      }

      if(!ctrl.idTopic) ModalsService.alertErreur('Pas d\'idTopic');

      ctrl.nouveauContenu = new MessageService.ContenuMessage(ctrl.contenu);

      if(_.isObject(ctrl.contenuParent)) {
        ctrl.nouveauContenu.setIdContenuParent(ctrl.contenuParent.getIdContenu());

        if(ctrl.contenuParent.model === 'Contenu' && (ctrl.contenuParent.isContenuDevisInter() || ctrl.contenuParent.isContenuContrat())) {
          initModel(ctrl.contenuParent.getDestinataire());

          if(!_.isNil(ctrl.contenuParent.getDestinataire()) && !_.isNil(ctrl.contenuParent.getDestinataire().getGroupeInformations())){
            ctrl.emailFournisseur = ctrl.contenuParent.getDestinataire().getEmail();
          }

          ctrl.typeDestinataire = 'fournisseur';
          ctrl.allowAddDestinataire = false;
          ctrl.nouveauContenu.sendByMail = true;
          ctrl.chooseContactPersoExpanded = false;

          /*
          ctrl.documentDemande = angular.copy(ctrl.contenuParent.getDocumentDemande());
          if(ctrl.contenuParent.isContenuDevisInter()) {
            ctrl.documentDevis = angular.copy(ctrl.contenuParent.getDocumentDevis());
            ctrl.documentDevisSigne = angular.copy(ctrl.contenuParent.getDocumentDevisSigne());
            ctrl.documentAttestationTvaSimple = angular.copy(ctrl.contenuParent.getDocumentAttestationTvaSimple());
            ctrl.documentFacture = angular.copy(ctrl.contenuParent.getDocumentFacture());
          }
          else if(ctrl.contenuParent.isContenuContrat()) {
            ctrl.listDocumentContratFournisseurSigne = angular.copy(ctrl.contenuParent.getListDocumentContratFournisseurSigne());
            ctrl.listDocumentContratAgenceSigne = angular.copy(ctrl.contenuParent.getListDocumentContratAgenceSigne());
          }*/

          if(!_.isNil(ctrl.typeFormulaire)) {
            ctrl.showOptionSendByMail = false;
          }

          // Si il y a une perso ics de contenu alors on fait le recherche de contact dessus
          if(!_.isNil(ctrl.contenuParent.getPersoICS())){
            initModel({fournisseur: ctrl.contenuParent.getPersoICS(), isModel: true});
          }
          else {
            ctrl.searchPersoForGroupeCollab = true;
          }
        }
        else {
          ctrl.nouveauContenu.addDestinataire(this.contenuParent.getExpediteur());
          ctrl.nouveauContenu.sendByMail = UtilsService.getParametrage('onClickReplyToAllSendByMailEnabled');
          if (UtilsService.isCapfun()){
            ctrl.isEventIntervention = TopicManagerService.currentEventIsIntervention();
            if(ctrl.isEventIntervention) ctrl.nouveauContenu.setSms(true);
          }
          /*
          if (UtilsService.isCapfun() && ctrl.contenuParent.sms && TopicManagerService.currentEventIsIntervention()){
            ctrl.nouveauContenu.setSms(true);
          }*/
        }
      }

      // Si typeFormulaire est définit par le parent
      if(!_.isNil(ctrl.typeFormulaire)) {
        if(_.eq(ctrl.typeFormulaire, 'email')) {
          ctrl.nouveauContenu.sendByMail = true;  // Active l'envoie par email
          ctrl.nouveauContenu.sms = false;        // désactive l'envoie par sms
          ctrl.showOptionSendByMail = false;
        }
        else if(_.eq(ctrl.typeFormulaire, 'sms')) {
          ctrl.nouveauContenu.sendByMail = false; // Désactive l'envoie par email
          ctrl.nouveauContenu.sms = true;         // Active l'envoie par sms
          ctrl.showOptionSendByMail = false;
        }
        ctrl.disallowSelectType = true;               // Desactive la possibilité de passer en SMS
      }

      //if(_.isArray(ctrl.destinataires)) ctrl.nouveauContenu.setDestinataires(ctrl.destinataires);



      // Si destinataire est un array
      if(_.isArray(ctrl.destinataires) && ctrl.destinataires.length) {
        initArray(ctrl.destinataires);
      }

      // Si c'est un objet, tester les différent model
      else if(!_.isArray(ctrl.destinataires) && _.isObject(ctrl.destinataires)) {
        initModel(ctrl.destinataires);
      }

      // Si c'est un string (pourquoi faire ? libelleDiese peut être ? ou mail et tel mais vraiment utile...)
      else if(_.isString(ctrl.destinataires)) initString(ctrl.destinataires);

      // Si il y a un événement on recupère les groupes qui ne soint pas immeuble, copro etc...
      // Pour les ajouter aux destinataires cachés
      if(_.isObject(ctrl.evenement) && _.isNil(ctrl.destinatairesHide)) {
        ctrl.destinatairesHide = UtilsService.getDestinatairesHideFromEvenement(ctrl.evenement);
      }


      ctrl.templatesMessage = UtilsService.getModulesTemplatesObjet('template_message');

      // Liste des document du topic
      ctrl.listeDocuments = angular.copy(DocumentsTopicService.getListDocument());
      // Si reçoit des documents du composant parent
      // Il y avoir un bug avec les case à cocher des docs du topic si le doc existe il ne sea ps coché
      if(_.isArray(ctrl.documents)) {
        addDocumentInContenu(ctrl.documents);
        // TODO: Si le document n'existe pas alors l'ajouter ?
      }

      if(ctrl.contenuParent) {
        ctrl.idContenuParent = ctrl.contenuParent.idContenu;
      }
      if(ctrl.idContenuRef) {
        ctrl.idContenuParent = ctrl.idContenuRef;
      }

      // Recupère un éventuel contenu sauvegardé
      var objContenuSave = getContenuSave();

      // Si un contenu sauvegardé
      if (objContenuSave) {
        /*
        // Si il y a un titre
        if (objContenuSave.formTopicTitre) {
          ctrl.formTopicTitre = objContenuSave.formTopicTitre;
        }*/
        ctrl.message = objContenuSave.message;
        ctrl.messageSms = objContenuSave.messageSms;
        ctrl.nouveauContenu.setTags(objContenuSave.tags);
        if (_.isArray(objContenuSave.listeDocument) && objContenuSave.listeDocument.length) {
          ctrl.documents = objContenuSave.listeDocument;
          addDocumentInContenu(objContenuSave.listeDocument);
          ctrl.showPiecesJointes = true;
        }

        if(objContenuSave.valueFieldObjet)
          ctrl.valueFieldObjet = objContenuSave.valueFieldObjet

        if(ctrl.enableCustomMail){

          if(objContenuSave.listValueFieldA)
            ctrl.listValueFieldA = objContenuSave.listValueFieldA
        }

        if(ctrl.enableCustomSms){

          if(objContenuSave.listValueFieldTel)
            ctrl.listValueFieldTel = objContenuSave.listValueFieldTel
        }
      }


      /*
      if (_.isObject(ctrl.defaultParams)) {

        $scope.$watch('contenumessageformulairectrl.contenuFormulaireCtrl.defaultParams.sms', function (val) {
          ctrl.currentItemContenu.sms = val;
        });

        if (ctrl.defaultParams.sms && ctrl.messageSms === '') {
          ctrl.messageSms = ctrl.defaultParams.message;
        }

        if (ctrl.defaultParams.communication) {
          ctrl.openFromCommunication = true;
          ctrl.currentItemContenu.sendByMail = true;
        }

        if (ctrl.defaultParams.devis) {
          ctrl.documentDemande = angular.copy(ctrl.defaultParams.devis.getDocumentDemande());
          ctrl.documentDevis = angular.copy(ctrl.defaultParams.devis.getDocumentDevis());
          ctrl.documentDevisSigne = angular.copy(ctrl.defaultParams.devis.getDocumentDevisSigne());
          ctrl.documentAttestationTvaSimple = angular.copy(ctrl.defaultParams.devis.getDocumentAttestationTvaSimple());
          ctrl.currentItemContenu.setIdContenuParent(ctrl.defaultParams.devis.getIdContenu());
        }
      }*/
      ctrl.paramSendOneMailForAllDest = UtilsService.getParametrage('sendOneMailForAllDest');
    };

    ctrl.$onChanges = function(changes) {
    };

    ctrl.$onDestroy = function(){
    };

    /**
     * Si le destinataire est un Array alors teste chaque itération pour savoir si c'est un Object ouString
     * @param arr
     */
    function initArray(arr){
      if(!arr.length) ModalsService.alertErreur('Pas de destinataire dans la liste');
      arr.map(function(obj) {
        if(_.isObject(obj)) initModel(obj, true);
        else if(_.isString(obj)) initString(obj);
      });
      checkIfCanSendBySMS();
      checkIfCanShowSendOneMailWithAllDest();
    }

    /**
     * Si le destinataire est un Object
     * @param dest
     */
    function initModel(dest, fromArray){
      if(!dest.isModel) ModalsService.alertErreur('Le destinataire doit être un model');
      if(_.isObject(dest.fournisseur)) ctrl.listePersoForChooseInfosContact.push(dest.fournisseur);
      if(_.isObject(dest.compagnie)) ctrl.listePersoForChooseInfosContact.push(dest.compagnie);
      if(_.isObject(dest.portefeuille)) ctrl.portefeuille = dest.portefeuille;
      if(dest.isGroupe || dest.isDestinataireEmail) {
        ctrl.nouveauContenu.addDestinataire(dest);
        ctrl.listePersoForChooseInfosContact.push(dest);
      }
      if(!fromArray) {
        checkIfCanSendBySMS();
        checkIfCanShowSendOneMailWithAllDest();
      }
    }

    /**
     * Si le destinataire est un String
     * @param dest
     */
    function initString(dest) {
      // TODO: A faire si libelleDiese ou email
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      if(ctrl.modalInstance) ctrl.close({$value: objRetour});
      else ctrl.onEmit({obj: objRetour});
    }

    /**
     * Permet d'upload les pièces jointes
     */
    function uploadDocuments(idContenu){
      deferredDocs = $q.defer();

      // Si il y a bien des documents à upload
      if (_.isObject(uploader) && uploader.getNotUploadedItems().length){
        if(!_.isNil(idContenu)){
          _.forEach(uploader.getNotUploadedItems(), function(doc) {
            // Ajoute l'idContenu au document qui va être envoyé
            doc.formData.push({idContenu: idContenu});
          });
        }
        uploader.uploadAll();
      }
      else deferredDocs.resolve(listDocsUpload);

      return deferredDocs.promise;
    }

    // ----------------- Fields Email -----------------

    function isValidPhoneNumber(value){
      var retour = {
        add: false,
        value: value
      };
      // Clean la chaine qui doit être un SMS
      retour.value = UtilsService.cleanPhoneNumber(value);

      if(UtilsService.isTelMobile(value)) {
        retour.add = true;
      }

      /*
      var nb = retour.value.length;
      var withPlus = UtilsService.startsWith(retour.value, '+');
      // Si pas de plus au début du numéro alors doit faire 10 caractères
      // Si il y a un plus devant alors doit faire 12 caractère
      if(
          (
              !withPlus &&
              (
                  nb == 10 && (UtilsService.startsWith(retour.value, '06') || UtilsService.startsWith(retour.value, '07'))
              ) ||
              (
                  nb == 13 && (UtilsService.startsWith(retour.value, '0032') || UtilsService.startsWith(retour.value, '0033'))
              )
          )
          ||
          (
              withPlus && nb == 12 && (UtilsService.startsWith(retour.value, '+32') || UtilsService.startsWith(retour.value, '+33'))
          )
      ) {
          retour.add = true;
      }*/
      return retour;
    }

    function onKeydownOnFieldDestinataire(event, nameList){
      // Si press ";" ou Enter ou Espace
      if((event.keyCode === 190 || event.keyCode === 13 || event.keyCode === 32)) {

        var add = false;
        // Si pas SMS et que la valeur est une adresse email
        if(!ctrl.nouveauContenu.sms && UtilsService.isMail(event.currentTarget.value)) {
          add = true;
        }
        // Si SMS
        else if(ctrl.nouveauContenu.sms) {
          var ret = isValidPhoneNumber(event.currentTarget.value);
          add = ret.add;
          if(add)
            event.currentTarget.value = ret.value;
        }

        if(add) {
          event.preventDefault();
          ctrl[nameList] = _.unionWith(ctrl[nameList], [event.currentTarget.value], _.isEqual);
          event.currentTarget.value = '';
          checkIfCanShowSendOneMailWithAllDest();
        }
      }
      // Si backspace et valeur du champ vide alors retire le dernier element de la liste
      if(event.keyCode === 8 && event.currentTarget.value === '' && ctrl[nameList].length) {
        ctrl[nameList].pop();
      }
    }

    function onBlurFromFieldDestinataire(event, nameList){

      var add = false;
      // Si pas SMS et que la valeur est une adresse email
      if(!ctrl.nouveauContenu.sms && UtilsService.isMail(event.currentTarget.value)) {
        add = true;
      }
      // Si SMS
      else if(ctrl.nouveauContenu.sms) {
        var ret = isValidPhoneNumber(event.currentTarget.value);
        add = ret.add;
        if(add)
          event.currentTarget.value = ret.value;
      }

      if(add) {
        event.preventDefault();
        ctrl[nameList] = _.unionWith(ctrl[nameList], [event.currentTarget.value], _.isEqual);
        event.currentTarget.value = '';
        checkIfCanShowSendOneMailWithAllDest();
      }
    }

    function onClickRemoveFieldValue(val, nameList){
      ctrl[nameList] = _.pull(ctrl[nameList], val);
      checkIfCanShowSendOneMailWithAllDest();
    }

    // ----------------- Fin Fields Email -----------------

    // ----------------- ChooseInfosContact -----------------

    /**
     * Lorsque l'on choisi des destinataires
     * @param obj
     */
    function onEmitFromChooseInfosContactComponent(obj){
      //console.log(obj);
      try {
        if (!_.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');

        // Liste des perso avec email checked venant du choose infos contact
        if(_.isArray(obj.listePerso)) ctrl.listePersoCheckedFromChooseInfosContact = obj.listePerso;

        // Liste des email custom venant du choose infos contact
        if(_.isArray(obj.listeMailCustom)) ctrl.listeMailCustomFromChooseInfosContact = obj.listeMailCustom;

        if(_.isArray(obj.listeEmail)) ctrl.listeEmail = obj.listeEmail;

        updateDestinataires();
      }
      catch (err) {
        ErreurCollabService.logErreur('[FormulaireMessageController.onEmitFromChooseInfosContactComponent] Erreur : ',  err.message);
      }
    }
    // --- Fin ChooseInfosContact

    /**
     * Permet de creer la liste des destinataires par le biais de différent composant
     */
    function updateDestinataires(){
      ctrl.nouveauContenu.setDestinataires([]);

      // Si il y a des destinataires venant du composant "destinataires" alors on les rajoutes
      if(ctrl.listeDestinataireFromDestinatairesComponent.length) {
        ctrl.listeDestinataireFromDestinatairesComponent.map(function(mail) {
          ctrl.nouveauContenu.addDestinataire(mail);
        });
      }
      if(ctrl.listeMailCustomFromChooseInfosContact.length) {
        ctrl.listeMailCustomFromChooseInfosContact.map(function(mail){
          ctrl.nouveauContenu.addDestinataire(mail);
        });
      }

      checkIfCanSendBySMS();
      checkIfCanShowSendOneMailWithAllDest();
    }

    /**
     * Permet d'afficher ou cacher la case à cocher pour envoyer un email pour plusieur destinataire
     */
    function checkIfCanShowSendOneMailWithAllDest(){
      if(!COLLAB_CONF.ENABLE_SEND_MAIL_CC) {
        ctrl.showSendOneMailWithAllDest = false;
        ctrl.nouveauContenu.sendOneMailWithAllDest = null;
      }
      else {
        ctrl.showSendOneMailWithAllDest = false;
        if(ctrl.nouveauContenu.getDestinataires().length) {
          ctrl.showSendOneMailWithAllDest = UtilsService.checkIfCanShowSendOneMailWithAllDest(ctrl.nouveauContenu.getDestinataires());
        }
        else if(_.isArray(ctrl.listValueFieldA) && ctrl.listValueFieldA.length > 1) {
          ctrl.showSendOneMailWithAllDest = true;
        }
        if(!ctrl.showSendOneMailWithAllDest){
          ctrl.nouveauContenu.sendOneMailWithAllDest = false;
        }
        // Si l'envoie en copie n'a pas été changé par l'user alors on met la valeur du param par défaut
        if(!ctrl.sendOneMailWithAllDestChanged) ctrl.nouveauContenu.sendOneMailWithAllDest = angular.copy(ctrl.paramSendOneMailForAllDest);
      }
    }

    /**
     * Permet de checker si on propose d'envoyer par SMS par rapport au destinataires
     */
    function checkIfCanSendBySMS(){
      var dests = angular.copy(ctrl.nouveauContenu.getDestinataires(true));
      if(dests.length){
        // Retire le groupeUser actuel de la liste des destinataires pour tester si on peux envoyer par SMS
        dests = GroupeService.removeGroupeFromList(UtilsService.getCurrentUser(), dests);
        var asAtLeastOneGroupe = false;
        if(dests.length) {
          for(var d = 0; d < dests.length; d++) {
            if(_.isObject(dests[d]) && dests[d].isGroupe) {
              asAtLeastOneGroupe = true;
              break;
            }
          }
        }

        if(!asAtLeastOneGroupe){
          ctrl.hideSmsChoice = true;
          ctrl.nouveauContenu.sms = false;        // désactive l'envoie par sms
        }
        else ctrl.hideSmsChoice = false;
      }
    }

    function onChangeToggleSms(){
      var showWarningDestinataireEmailForSms = false;
      //console.log(ctrl.nouveauContenu);
      if(ctrl.nouveauContenu.sms){
        var dests = ctrl.nouveauContenu.getDestinataires(true);
        if(_.isArray(dests) && !_.isEmpty(dests)) {
          for(var d = 0; d < dests.length; d++){
            if(dests[d].isDestinataireEmail){
              showWarningDestinataireEmailForSms = true;
              break;
            }
          }
        }
      }
      ctrl.showWarningDestinataireEmailForSms = angular.copy(showWarningDestinataireEmailForSms);
    }

    /**
     * Lorsque l'editeur de message est prêt
     * @param obj
     */
    function onEditorReady(obj){
      ctrl.objEditorMessage = obj;
    }

    /**
     * Lorsque l'editeur de SMS est prêt
     * @param obj
     */
    function onEditorSmsReady(obj){
      ctrl.objEditorSms = obj;
    }

    /**
     * Lors du changement dans les destinataires
     * @param destinataires
     */
    function onChangeDestinataires(destinataires) {
      ctrl.listeDestinataireFromDestinatairesComponent = destinataires;
      updateDestinataires();
    }

    /**
     * Lors de la suppression d'un destinataire perso
     * @param destinataires
     */
    function onRemoveDestinatairePerso(destinataire) {
      if(destinataire.groupe) {

        if(_.isString(destinataire.groupe)) {
          ctrl.listeEmail = angular.copy(ctrl.listeEmail.remove(destinataire.groupe));

          var tmpListPerso = [];
          if(ctrl.listePersoCheckedFromChooseInfosContact.length) {
            ctrl.listePersoCheckedFromChooseInfosContact.map(function(objPerso) {
              var mails = [];
              objPerso.emails.map(function(objMail) {
                if(objMail.mail !== destinataire.groupe) mails.push(angular.copy(objMail));
              });
              if(mails.length) {
                var tmpPerso = angular.copy(objPerso);
                tmpPerso.emails = mails;
                tmpListPerso.push(tmpPerso);
              }
            });
          }
          ctrl.listePersoCheckedFromChooseInfosContact = tmpListPerso;
        }
        else if(_.isObject(destinataire.groupe)) {
          ctrl.listeEmail = angular.copy(ctrl.listeEmail.remove(destinataire.groupe.getEmail()));

          var tmpListPerso = [];
          if(ctrl.listePersoCheckedFromChooseInfosContact.length) {
            ctrl.listePersoCheckedFromChooseInfosContact.map(function(objPerso) {
              var mails = [];
              objPerso.emails.map(function(objMail) {
                if(objMail.groupes.length) {
                  objMail.groupes.map(function(groupe) {
                    if(groupe.idGroupe !== destinataire.groupe.idGroupe)
                      mails.push(angular.copy(objMail));
                  });
                }
              });
              if(mails.length) {
                var tmpPerso = angular.copy(objPerso);
                tmpPerso.emails = mails;
                tmpListPerso.push(tmpPerso);
              }
            });
          }
          ctrl.listePersoCheckedFromChooseInfosContact = tmpListPerso;
        }
      }
    }

    /**
     * Clean le contenu du LocalStorage
     */
    /*
    function onClickCleanReponseContenu() {

      sweet.show({
        title: 'Remettre à zero la réponse ?',
        text: 'Vous êtes sûr ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Oui !',
        closeOnConfirm: true
      }, function () {
        cleanReponseContenu();
      });
    }*/

    /**
     * Permet de clean le formulaire
     */
    /*
    function cleanReponseContenu() {
      if (!ctrl.idTopic) ctrl.formTopicTitre = '';
      ctrl.nouveauContenu.destinataires = angular.copy(defaultReponseDestinataires);

      // Si pas de "contenuParent" (nouveau Topic) OU pas d'idContenu nouveau contenu (réponse)
      if (!ctrl.nouveauContenu.idContenu || !ctrl.contenuParent) {
        ctrl.message = '';
        ctrl.messageSms = '';
      }
      // Si modification
      else {
        ctrl.message = ctrl.contenu.getMessage();
        ctrl.messageSms = ctrl.contenu.getMessageSms();
      }

      if (ctrl.objEditorSms) ctrl.objEditorSms.setData('');
      if (ctrl.objEditorMessage) ctrl.objEditorMessage.setData('');

      // Replace le message dans le WYSIWYG
      //if (ctrl.objEditorMessage) ctrl.objEditorMessage.setData(ctrl.message);

      if (ctrl.uploader.queue.length) {
        angular.forEach(ctrl.uploader.queue, function (doc) {
          removePiecesJointes(doc);
        });
      }

      //ctrl.allTags = [];
      ctrl.nouveauContenu.tags = [];
      ctrl.nouveauContenu.listeDocument = [];
      calcSommePiecesJointes();

      ctrl.currentTemplateMessage = null;
      ctrl.currentTemplateSms = null;

      // Supprime la sauvegarde du localstorage
      AutoSaveService.delete(getObjContenu(), ctrl.nouveauContenu.getIdContenu(), ctrl.contenuParent);

      //$scope.$digest();
    }*/

    /**
     * Permet de recupérer un éventuel contenu sauvegardé
     */
    function getContenuSave() {

      var objContenuSave;
      if (!_.isNil(ctrl.contenuParent)) {
        // Si premier message d'un topic avec evenement
        if (ctrl.contenuParent.isEvenement) {
          objContenuSave = AutoSaveService.get(ctrl.contenuParent.idContenu);
          if (_.isObject(objContenuSave) && objContenuSave.hasOwnProperty('reponse')) {
            // Test pour la nouvelle version du JSON sauvegardé
            if (objContenuSave.reponse.hasOwnProperty('obj')) objContenuSave = objContenuSave.reponse.obj;
            else objContenuSave = objContenuSave.reponse;
          }
        }
        // Si réponse d'un message
        else {
          objContenuSave = AutoSaveService.get(ctrl.contenuParent.idContenu);
          if (_.isObject(objContenuSave)) {
            if (ctrl.contenuParent && objContenuSave.hasOwnProperty('reponse')) {
              // Test pour la nouvelle version du JSON sauvegardé
              if (objContenuSave.reponse.hasOwnProperty('obj')) objContenuSave = objContenuSave.reponse.obj;
              else objContenuSave = objContenuSave.reponse;
            }
            else if (objContenuSave.hasOwnProperty('modification')) {
              // Test pour la nouvelle version du JSON sauvegardé
              if (objContenuSave.modification.hasOwnProperty('obj')) objContenuSave = objContenuSave.modification.obj;
              else objContenuSave = objContenuSave.modification;
            }
            else objContenuSave = false;
          }
        }

      }
      // Si nouveau message
      else {
        if(ctrl.idContenuParent) {
          objContenuSave = AutoSaveService.get(ctrl.idContenuParent);
        }
        else {
          objContenuSave = AutoSaveService.get('newMessage');
        }

        if (_.isObject(objContenuSave)) {
          // Test pour la nouvelle version du JSON sauvegardé
          if (objContenuSave.hasOwnProperty('nouveau') && objContenuSave.nouveau.hasOwnProperty('obj')) objContenuSave = objContenuSave.nouveau.obj;
          // Retro compatibilité
          else if (objContenuSave.hasOwnProperty('reponse')) {
            // Test pour la nouvelle version du JSON sauvegardé
            if (objContenuSave.reponse.hasOwnProperty('obj')) objContenuSave = objContenuSave.reponse.obj;
            else objContenuSave = objContenuSave.reponse;
          }
        }
      }
      return objContenuSave;
    }

    /**
     * Permet de recupérer un objet du contenu actuellement en cours de modification ou de création pour le stocker dans le local storage
     * @returns {*}
     */
    function getObjContenu() {
      // Est utilisé pour la sauvegarde automatique dans le local storage
      var copyForSave = angular.copy(ctrl.nouveauContenu);

      var messageSms = '';
      if (ctrl.objEditorSms) messageSms = ctrl.objEditorSms.getData();
      copyForSave.setMessageSms(messageSms);

      var message = '';
      if (ctrl.objEditorMessage) message = ctrl.objEditorMessage.getData();

      if(ctrl.enableCustomMail) {

        if(ctrl.listValueFieldA.length)
          copyForSave.listValueFieldA = ctrl.listValueFieldA;
      }
      if(ctrl.enableCustomSms) {

        if(ctrl.listValueFieldTel.length)
          copyForSave.listValueFieldTel = ctrl.listValueFieldTel;
      }

      if(!_.isNil(ctrl.valueFieldObjet) && ctrl.valueFieldObjet !== '')
        copyForSave.valueFieldObjet = ctrl.valueFieldObjet;

      copyForSave.setMessage(message);
      if (!ctrl.idTopic) copyForSave.formTopicTitre = ctrl.formTopicTitre;
      return copyForSave;
    }

    /**
     * Lorsqu'on clique sur le bouton pour retirer une pièce jointe
     * @param fileItem
     */
    /*
    function onClickRemovePiecesJointes(fileItem){
      removePiecesJointes(fileItem);
    }*/

    /**
     * Supprime une pièce jointe de la listre est de la BDD
     * @param {Object} fileItem - Item du fichier
     * @returns {void}
     */
    /*
    function removePiecesJointes(fileItem) {

      // Si item venant de la liste d'upload
      // Supprime le fichier de la liste d'upload
      if (!_.isNil(fileItem._file)) ctrl.uploader.removeFromQueue(fileItem);
      // Sinon si item venant de la GED perso
      else if (fileItem.dateUpload) removeDocumentFromContenu(fileItem);

      /*
      // Si item venant de la liste d'upload
      if (!_.isNil(fileItem._file)) {


        // Si object document existe
        if (_.isObject(fileItem._file.document)) {

          var docObjet = fileItem._file.document;
          DocumentManagerService
            .deleteDocumentPerso(docObjet)
            .then(function () {

              // Supprime la pièce jointe de la liste
              ctrl.uploader.removeFromQueue(fileItem);

              // Supprime le document des pièces jointes
              removeDocumentFromContenu(docObjet);

            })
            .catch(function (msg) {
              console.log('Erreur formulaireContenu.removeFromQueue()');
              console.log(msg);
            });

        }
        // Sinon supprime seulement le fichier de la liste d'upload
        else ctrl.uploader.removeFromQueue(fileItem);
      }
      // Sinon si item venant de la GED perso
      else if (fileItem.dateUpload) removeDocumentFromContenu(fileItem);
      *
    }*/

    /**
     * Lors du drop d'un fichier GED
     */
    /*
    function onDropDocumentFromGedPerso() {
      addDocumentInContenu(ctrl.itemPieceJointeDrop);
    }
    */

    /**
     * Lorsqu'on check un document
     * @param document
     */
    /*
    function onCheckDocumentPartage(document) {
      if (document.checked) addDocumentInContenu(document);
      else removeDocumentFromContenu(document);
    }*/

    /**
     * Ajouter un document au nouveau contneu
     * @param document
     */
    function addDocumentInContenu(document) {
      if(_.isArray(document)) {
        document.map(function(doc){
          ctrl.nouveauContenu.addDocument(doc);
          doc.checked = true;
        });
        ctrl.nouveauContenu.listeDocument = _.unionWith(ctrl.nouveauContenu.listeDocument, document, _.isEqual);
      }
      else if(_.isObject(document)) {
        ctrl.nouveauContenu.addDocument(document);
        document.checked = true;
        ctrl.nouveauContenu.listeDocument = _.unionWith(ctrl.nouveauContenu.listeDocument, [document], _.isEqual);

        //checkOrUncheckShortcutDocs(document, true);
      }
      //parcourDocumentsTopic(document);
      //calcSommePiecesJointes();
    }

    /**
     * Permet de retirer un document du nouveau contenu
     * @param document
     */
    /*
    function removeDocumentFromContenu(document) {
      if(_.isArray(document)) {
        document.map(function(doc){
          ctrl.nouveauContenu.removeDocument(doc);
          doc.checked = false;
        });
      }
      else if(_.isObject(document)) {
        ctrl.nouveauContenu.removeDocument(document);
        document.checked = false;

        //checkOrUncheckShortcutDocs(document, false);
      }
      listDocsTopicChecked = _.remove(listDocsTopicChecked, function(listDocsTopicChecked) {
        return (!_.isEqual(listDocsTopicChecked, document));
      });
      //parcourDocumentsTopic(document);
      //calcSommePiecesJointes();
    }*/

    /**
     * Permet de checker ou uncheck un document qui est en racourci
     * @param document
     * @param checked
     */
    /*
    function checkOrUncheckShortcutDocs(document, checked){
      if(!_.isNil(ctrl.documentDemande) && ctrl.documentDemande.guid === document.guid) {
        ctrl.documentDemande.checked = checked;
      }
      if(!_.isNil(ctrl.documentDevis) && ctrl.documentDevis.guid === document.guid) {
        ctrl.documentDevis.checked = checked;
      }
      if(!_.isNil(ctrl.documentDevisSigne) && ctrl.documentDevisSigne.guid === document.guid) {
        ctrl.documentDevisSigne.checked = checked;
      }
      if(!_.isNil(ctrl.documentAttestationTvaSimple) && ctrl.documentAttestationTvaSimple.guid === document.guid) {
        ctrl.documentAttestationTvaSimple.checked = checked;
      }
      if(!_.isNil(ctrl.documentFacture) && ctrl.documentFacture.guid === document.guid) {
        ctrl.documentFacture.checked = checked;
      }
    }*/

    /**
     * Parcour les documents du topic pour cocher ou pas
     * @param currentDocument
     */
    /*
    function parcourDocumentsTopic(currentDoc) {

      if (ctrl.idTopic) {
        if (ctrl.listeDocuments && ctrl.listeDocuments.nb) {

          var docs = [];
          if(_.isArray(currentDoc)) docs = currentDoc;
          else if(_.isObject(currentDoc)) docs.push(currentDoc);

          if(docs.length) {
            docs.map(function(currentDocument){
              ctrl.listeDocuments.categorie.map(function(categorie){
                if (categorie.documents.length) {
                  categorie.documents.map(function(doc){
                    if (doc.guid === currentDocument.guid)
                      doc.checked = currentDocument.checked;
                  });
                }
                if (categorie.hasOwnProperty('groupements') && _.isArray(categorie.groupements)) {
                  categorie.groupements.map(function(groupement){
                    groupement.documents.map(function(doc){
                      if (doc.guid === currentDocument.guid)
                        doc.checked = currentDocument.checked;
                    });
                  });
                }
              });
            });
          }
        }
      }
    }
    */

    /**
     * Permet de calculer le poid total des pièces jointes
     */
    /*
    function calcSommePiecesJointes() {

      var sizeTotal = 0;
      if (ctrl.nouveauContenu.listeDocument.length) {
        for (var i = 0; i < ctrl.nouveauContenu.listeDocument.length; i++) {
          sizeTotal = sizeTotal + ctrl.nouveauContenu.listeDocument[i].size;
        }
      }
      if (sizeTotal === 0) sizeTotal = false;

      ctrl.sommePiecesJointes = sizeTotal;
    }*/

    /**
     * Permet de calculer la taille total des documents choisi
     * @returns {boolean}
     */
    function isOverMaxSize() {
      if (ctrl.sommePiecesJointes >= ctrl.uploaderAttachment.sizeLimit) return true;
      return false;
    }

    /**
     * Permet de limiter le nombre de caractères pour le nom du fichier
     * @param libelle
     */
    function truncate(libelle){
      return UtilsService.truncate(libelle, 30, '...');
    }

    /**
     * Sauvegarde le contenu dans le LocalStorage
     */
    function saveContenuTmp(callback) {
      AutoSaveService.save(getObjContenu(), ctrl.nouveauContenu.getIdContenu(), ctrl.idContenuParent);
      NotificationsToastServices.success('', '<strong>Sauvegardé temporairement !</strong>');
      if (callback) callback();
    }

    /**
     * Lors du clique sur le bouton pour reset le reply
     */
    /*
    function onClickReset(){
      onClickCleanReponseContenu();
    }*/

    /**
     * Lorsqu'on clique sur le bouton pour sauvegarder temporairement le message
     */
    function onClickSaveTmp() {
      saveContenuTmp();
    }

    /**
     * Lors du clique sur le bouton sauvegarder
     */
    function onClickSave(){
      // Sauvegarde au cas où il y aurai un problème
      saveContenuTmp(function () {
        // Submit le contenu
        $timeout(function () {
          submitMessage();
        });
      });
    }

    /**
     * Sauvegarde du message
     */
    /*
    function submitMessage(){
      try {

        var canContinue = ctrl.onSubmit({onSubmit: {contenu: ctrl.nouveauContenu}});
        if (canContinue === false) return;

        //console.log('ctrl.nouveauContenu',ctrl.nouveauContenu);
        //console.log('canContinue',canContinue);
        //return;


        var ProxyDestinataire = $injector.get('ProxyDestinataire');
        var listDest = [];
        var listDestHide = [];
        //console.log(ctrl.listePersoCheckedFromChooseInfosContact);
        if(ctrl.listePersoCheckedFromChooseInfosContact.length) {

          ctrl.listePersoCheckedFromChooseInfosContact.map(function(objPerso) {

            if(objPerso.emails.length){

              objPerso.emails.map(function(objMail) {

                var dest = angular.copy({
                  email: null,
                  persoICS: (_.isObject(objPerso.persoICS)) ? objPerso.persoICS : null
                });

                // Si ce mail de la persoICS à des groupes collab, c'est eux qu'on prend en destinataire
                if(objMail.groupes.length){
                  objMail.groupes.map(function(groupe){

                    var proxyDest = null,
                      addProxyDest = false;
                    // On va checker dans la liste des destinataire si ce groupe n'y ai pas déjà
                    if(listDest.length) {
                      for(var i = 0; i < listDest.length; i++) {
                        if(!_.isNil(listDest[i].idGroupe) && listDest[i].idGroupe === groupe.idGroupe) {
                          proxyDest = listDest[i];
                          break;
                        }
                      }
                    }

                    // Si le proxyDest n'est pas encore dans la liste on le créé
                    if(_.isNil(proxyDest)){
                      addProxyDest = true;
                      // Création d'un ProxyDestinataire à partir du groupe
                      proxyDest = new ProxyDestinataire(groupe);
                    }


                    // Si il a seulement un groupe dans les destinataire alors on doit cummuler les email dans la meme liste de mail
                    if(ctrl.typeDestinataire === 'fournisseur') proxyDest.addInListeEmail(objMail.mail);

                    if(addProxyDest) {
                      if(ctrl.typeDestinataire !== 'fournisseur') proxyDest.persoICS = (_.isObject(objPerso.persoICS)) ? objPerso.persoICS : null;
                      listDest.push(proxyDest);
                    }
                  });
                }
                // Si pas de groupes alors on prends l'email
                else {
                  dest.email = objMail.mail;
                  listDest = _.unionWith(listDest, [dest], _.isEqual);
                }
              });
            }
          });
        }


        if(ctrl.nouveauContenu.getDestinataires().length) {
          ctrl.nouveauContenu.getDestinataires().map(function(dest) {
            var proxyDest = null;
            if(_.isObject(dest)){
              if(dest.model !== "ProxyDestinataire"){
                proxyDest = new ProxyDestinataire(dest);
                // Si le groupe n'est pas un user et qu'il est de la meme société que l'expediteur alors desactive les mails
                if(!dest.isUser() && UtilsService.getCurrentUser().isSameSocieteOfGroupe(dest)) {
                  proxyDest.setAllowSendEmail(false);
                }
              }
              else proxyDest = dest;
            }

            if(!_.isNil(proxyDest)) listDest = _.unionWith(listDest, [proxyDest], _.isEqual);
          });
        }

        if (_.isObject(ctrl.contenuParent) && ctrl.contenuParent.model === 'Contenu' && ctrl.contenuParent.hasOwnProperty('demandeDevisActive') && _.isObject(ctrl.contenuParent.getDestinataire())) {

          var exist = false;
          if(listDest.length) {
            for(var i = 0; i < listDest.length; i++) {
              if(!_.isNil(listDest[i].idGroupe) && listDest[i].idGroupe === ctrl.contenuParent.getDestinataire().getIdGroupe()) {
                exist = true;
                break;
              }
            }
          }

          if(!exist) listDest.push(new ProxyDestinataire(ctrl.contenuParent.getDestinataire()));
        }

        if(ctrl.listValueFieldA.length) {
          ctrl.listValueFieldA.map(function(dest) {
            listDest = _.unionWith(listDest, [new ProxyDestinataire(dest)], _.isEqual);
          });
        }

        // Si il y a une liste de numéro de téléphone libre
        if(ctrl.listValueFieldTel.length) {
          ctrl.listValueFieldTel.map(function(dest) {
            var proxyDest = new ProxyDestinataire();
            proxyDest.setTypeDestinataire('destinataireSms');
            proxyDest.setListeNumeroTelephone([dest]);
            listDest = _.unionWith(listDest, [proxyDest], _.isEqual);
          });
        }

        if(_.isArray(ctrl.destinatairesHide) && ctrl.destinatairesHide.length) {
          ctrl.destinatairesHide.map(function(dest){
            var proxyDest = new ProxyDestinataire(dest);
            proxyDest.setAllowSendEmail(false);
            listDestHide = _.unionWith(listDestHide, [proxyDest], _.isEqual);
          });
        }
        ProxyDestinataire = null;

        // Si sms alors test si il y a des destinataire autres que les "hide"
        // Si pas SMS il faut au moins un destinataire dans hide ou autre
        // Si formulaire email alors il doit y avoir un destinataire autre que les hides
        if((ctrl.nouveauContenu.sms && !listDest.length) || (!ctrl.nouveauContenu.sms && !listDest.length && !listDestHide.length) || (_.eq(ctrl.typeFormulaire, 'email') && !listDest.length)) throw new Error('Aucun destinataire sélectionné !');

        if(!_.isNil(ctrl.typeDestinataire)) {
          listDest.map(function(dest){
            dest.typeDestinataire = ctrl.typeDestinataire;
          });
        }

        // Si il y a des destinataires cachés
        if(listDestHide.length) {

          // Parcours les destinataires cachés
          for(var dh = 0; dh < listDestHide.length; dh++){
            var destHide = listDestHide[dh];
            var destExist = false;
            if(listDest.length) {
              // Parcours les destinataires
              for(var d = 0; d < listDest.length; d++){

                var dest = listDest[d];

                // Si il y a des idGroupe dans chaque dest et que ce sont les même
                if(!_.isNil(destHide.idGroupe) && !_.isNil(dest.idGroupe)) {
                  if(destHide.idGroupe === dest.idGroupe) {
                    destExist = true;
                    break;
                  }
                }
                // Si il n'y a pas d'idGroupe mais un email dans les dest et que ce sont les même
                else if(!_.isNil(destHide.email) && !_.isNil(dest.email)) {
                  if(destHide.email === dest.email) {
                    destExist = true;
                    break;
                  }
                }
                // Sinon si il n'y a pas d'email et d'idGroupe ou que ce ne sont pas les meme
                else if(!_.isNil(destHide.libelle) && _.isNil(destHide.dieze) && _.isNil(dest.libelle) && _.isNil(dest.dieze)) {
                  if(destHide.libelle === dest.libelle && destHide.dieze === dest.dieze) {
                    destExist = true;
                    break;
                  }
                }
              }
            }

            if(!destExist) {
              listDest.push(destHide);
            }
          }
        }

        //listDest = _.union(listDest, listDestHide);

        // Si SMS
        if(ctrl.nouveauContenu.sms) {
          if(!_.isObject(ctrl.objEditorSms)) throw new Error('Il y a un problème avec le formulaire de SMS !');
          // Verification du SMS
          if (_.isNil(ctrl.objEditorSms.getData()) || ctrl.objEditorSms.getData() === '') throw new Error('Veuillez renseigner un SMS');
        }
        // Si Message
        else {
          if(!_.isObject(ctrl.objEditorMessage)) throw new Error('Il y a un problème avec le formulaire de message !');
          // Verification du message
          if (_.isNil(ctrl.objEditorMessage.getData()) || ctrl.objEditorMessage.getData() === '') throw new Error('Veuillez renseigner un message');
        }

        // console.log(listDest);
        // return;
        ctrl.loading = true;

        // Si modification d'un contenu message
        if(ctrl.nouveauContenu.idContenu) {

          var obj = {
            idContenu: ctrl.nouveauContenu.idContenu,
            message: ctrl.objEditorMessage.getData()
          };

          MessageService
            .updateMessage(obj)
            .then(function(obj){

              // Supprime la sauvegarde du localstorage
              AutoSaveService.delete(getObjContenu(), ctrl.nouveauContenu.getIdContenu(), ctrl.idContenuParent);

              emit({action: 'updateMessage', contenu: obj.message});
            })
            .catch(function(error){
              ModalsService.alertErreur(error);
            })
            .finally(function(){
              hideLoadingAndNotif(notifWait);
              onClickClose();
            });
        }
        // Si nouveau contenu message
        else {

          // Si le contenu n'est pas un SMS et qu'il est envoyé par email
          if(!ctrl.nouveauContenu.sms && ctrl.nouveauContenu.sendByMail) {
            // Si trop de documents
            if (isOverMaxSize()) throw new Error('Le poid maximum pour les pièces jointes envoyés par email est de ' + ctrl.convertSize(ctrl.maxSommePiecesJointes) + ' !');
          }
          if(!ctrl.nouveauContenu.sendByMail) ctrl.nouveauContenu.sendOneMailWithAllDest = null;

          if(ctrl.important) ctrl.nouveauContenu.addTagType('Important', 'Important');

          uploadDocuments()
            .then(function(docsUpload){

              // TODO :  si composant utilisé pour la création de nouveau topic alors ajouter le titre et la verification

              var notifWait = null;
              NotificationsToastServices.wait('Patientez', 'Envoi du message en cours...', function (notif) {
                notifWait = notif;
              });

              var obj = {
                idTopic: ctrl.idTopic,
                objJSON: null
              };

              var proxyMessage = new MessageService.ProxyContenuMessage(ctrl.nouveauContenu);

              // Remplace les destinataire par listeDestinataires
              proxyMessage.setDestinataires(null);
              proxyMessage.setListeDestinataires(listDest);



              if(!_.isNil(ctrl.valueFieldObjet) && ctrl.valueFieldObjet !== '') {
                proxyMessage.setProxyMail({
                  sujet: ctrl.valueFieldObjet
                });
              }
              else if(ctrl.textSujet) {
                proxyMessage.setProxyMail({
                  sujet: ctrl.textSujet
                });
              }

              // Si le message n'est pas un SMS
              if(!proxyMessage.sms) {
                // Recupère le message de l'editeur WYSIWYG
                proxyMessage.setMessage(ctrl.objEditorMessage.getData());

                // Si il y a des documents
                if(docsUpload.length)
                  proxyMessage.setGuids(docsUpload);

                // Si il y a des documents ajouté depuis la liste des documents du topic
                //if(listDocsTopicChecked.length)
                //  proxyMessage.addGuids(listDocsTopicChecked);

                // Si il y a des documents venant du composant d'upload
                if(ctrl.nouveauContenu.listeDocument.length)
                  proxyMessage.addGuids(ctrl.nouveauContenu.listeDocument);

                // Si le message est envoyé par email et qu'il a une signature
                //proxyMessage.nouveauContenu.sendByMail &&
                // Si il a une signature
                if(_.isObject(ctrl.signatureSelected) && ctrl.signatureSelected.hasOwnProperty('value'))
                  proxyMessage.setSignature(ctrl.signatureSelected.value);

              }
              // Recupère le message de l'editeur SMS
              else
                proxyMessage.setMessage(ctrl.objEditorSms.getData());


              obj.objJSON = proxyMessage;

              //console.log(obj);
              //hideLoadingAndNotif(notifWait);
              //return;

              MessageService
                .addMessageInTopic(obj)
                .then(function(obj){
                  // Supprime la sauvegarde du localstorage
                  AutoSaveService.delete(getObjContenu(), ctrl.nouveauContenu.getIdContenu(), ctrl.idContenuParent);

                  emit({action: 'addMessage', contenu: obj.message});
                })
                .catch(function(error){
                  ModalsService.alertErreur(error);
                  // Si il y a des documents il faut les supprimer car l'insertion du contenu à planté
                  if(listDocsUpload.length) {
                    listDocsUpload.map(function(doc){
                      DocumentManagerService
                        .deleteDocumentPerso(doc)
                        .then(function () {

                        })
                        .catch(function (msg) {
                          ModalsService.alertErreur(msg);
                        })
                        .finally(function(){
                          listDocsUpload = [];
                        });
                    });
                  }
                })
                .finally(function(){
                  hideLoadingAndNotif(notifWait);
                  onClickClose();
                });
            })
            .catch(function(err) {
              // Si il y a des documents il faut les supprimer car l'insertion du contenu à planté
              if(listDocsUpload.length) {
                listDocsUpload.map(function(doc){
                  DocumentManagerService
                    .deleteDocumentPerso(doc)
                    .then(function () {

                    })
                    .catch(function (msg) {
                      ModalsService.alertErreur(msg);
                    })
                    .finally(function(){
                      listDocsUpload = [];
                    });
                });
              }
              hideLoadingAndNotif(notifWait);
            })
            .finally(function(){

            });
        }
      }
      catch(e) {
        ModalsService.alertErreur(e.message);
        hideLoadingAndNotif();
      }
    }*/
    function submitMessage(){
      try {

        var canContinue = ctrl.onSubmit({onSubmit: {contenu: ctrl.nouveauContenu}});
        if (canContinue === false) return;

        //console.log('ctrl.nouveauContenu',ctrl.nouveauContenu);
        //console.log('canContinue',canContinue);
        //return;


        var ProxyDestinataire = $injector.get('ProxyDestinataire');
        var listDest = [];
        var listDestHide = [];
        //console.log(ctrl.listePersoCheckedFromChooseInfosContact);
        if(ctrl.listePersoCheckedFromChooseInfosContact.length) {

          ctrl.listePersoCheckedFromChooseInfosContact.map(function(objPerso) {

            if(objPerso.emails.length){

              objPerso.emails.map(function(objMail) {

                var dest = angular.copy({
                  email: null,
                  persoICS: (_.isObject(objPerso.persoICS)) ? objPerso.persoICS : null
                });

                // Si ce mail de la persoICS à des groupes collab, c'est eux qu'on prend en destinataire
                if(objMail.groupes.length){
                  objMail.groupes.map(function(groupe){

                    var proxyDest = null,
                      addProxyDest = false;
                    // On va checker dans la liste des destinataire si ce groupe n'y ai pas déjà
                    if(listDest.length) {
                      for(var i = 0; i < listDest.length; i++) {
                        if(!_.isNil(listDest[i].idGroupe) && listDest[i].idGroupe === groupe.idGroupe) {
                          proxyDest = listDest[i];
                          break;
                        }
                      }
                    }

                    // Si le proxyDest n'est pas encore dans la liste on le créé
                    if(_.isNil(proxyDest)){
                      addProxyDest = true;
                      // Création d'un ProxyDestinataire à partir du groupe
                      proxyDest = new ProxyDestinataire(groupe);
                    }


                    // Si il a seulement un groupe dans les destinataire alors on doit cummuler les email dans la meme liste de mail
                    if(ctrl.typeDestinataire === 'fournisseur') proxyDest.addInListeEmail(objMail.mail);

                    if(addProxyDest) {
                      if(ctrl.typeDestinataire !== 'fournisseur') proxyDest.persoICS = (_.isObject(objPerso.persoICS)) ? objPerso.persoICS : null;
                      listDest.push(proxyDest);
                    }
                  });
                }
                // Si pas de groupes alors on prends l'email
                else {
                  dest.email = objMail.mail;
                  listDest = _.unionWith(listDest, [dest], _.isEqual);
                }
              });
            }
          });
        }


        if(ctrl.nouveauContenu.getDestinataires().length) {
          ctrl.nouveauContenu.getDestinataires().map(function(dest) {
            var proxyDest = null;
            if(_.isObject(dest)){
              if(dest.model !== "ProxyDestinataire"){
                proxyDest = new ProxyDestinataire(dest);

                // Si pas groupe MCS,Prop,Loca etc... et pas groupe user
                if(dest.isGroupe && dest.isAutre() && !dest.isUser() && dest.isMySociete()) {
                  proxyDest.setAllowSendEmail(false);
                }

                // Si le groupe n'est pas un user et qu'il est de la meme société que l'expediteur alors desactive les mails
                //if(!dest.isUser() && UtilsService.getCurrentUser().isSameSocieteOfGroupe(dest)) {
                //  proxyDest.setAllowSendEmail(false);
                //}
              }
              else proxyDest = dest;
            }

            if(!_.isNil(proxyDest)) listDest = _.unionWith(listDest, [proxyDest], _.isEqual);
          });
        }

        if (_.isObject(ctrl.contenuParent) && ctrl.contenuParent.model === 'Contenu' && ctrl.contenuParent.hasOwnProperty('demandeDevisActive') && _.isObject(ctrl.contenuParent.getDestinataire())) {

          var exist = false;
          if(listDest.length) {
            for(var i = 0; i < listDest.length; i++) {
              if(!_.isNil(listDest[i].idGroupe) && listDest[i].idGroupe === ctrl.contenuParent.getDestinataire().getIdGroupe()) {
                exist = true;
                break;
              }
            }
          }

          if(!exist) listDest.push(new ProxyDestinataire(ctrl.contenuParent.getDestinataire()));
        }

        if(ctrl.listValueFieldA.length) {
          ctrl.listValueFieldA.map(function(dest) {
            listDest = _.unionWith(listDest, [new ProxyDestinataire(dest)], _.isEqual);
          });
        }

        // Si il y a une liste de numéro de téléphone libre
        if(ctrl.listValueFieldTel.length) {
          ctrl.listValueFieldTel.map(function(dest) {
            var proxyDest = new ProxyDestinataire();
            proxyDest.setTypeDestinataire('destinataireSms');
            proxyDest.setListeNumeroTelephone([dest]);
            listDest = _.unionWith(listDest, [proxyDest], _.isEqual);
          });
        }

        if(_.isArray(ctrl.destinatairesHide) && ctrl.destinatairesHide.length) {
          ctrl.destinatairesHide.map(function(dest){
            var proxyDest = new ProxyDestinataire(dest);
            proxyDest.setAllowSendEmail(false);
            listDestHide = _.unionWith(listDestHide, [proxyDest], _.isEqual);
          });
        }
        ProxyDestinataire = null;

        // Si sms alors test si il y a des destinataire autres que les "hide"
        // Si pas SMS il faut au moins un destinataire dans hide ou autre
        // Si formulaire email alors il doit y avoir un destinataire autre que les hides
        if((ctrl.nouveauContenu.sms && !listDest.length) || (!ctrl.nouveauContenu.sms && !listDest.length && !listDestHide.length) || (_.eq(ctrl.typeFormulaire, 'email') && !listDest.length)) throw new Error('Aucun destinataire sélectionné !');

        if(!_.isNil(ctrl.typeDestinataire)) {
          listDest.map(function(dest){
            dest.typeDestinataire = ctrl.typeDestinataire;
          });
        }

        // Si il y a des destinataires cachés
        if(listDestHide.length) {

          // Parcours les destinataires cachés
          for(var dh = 0; dh < listDestHide.length; dh++){
            var destHide = listDestHide[dh];
            var destExist = false;
            if(listDest.length) {
              // Parcours les destinataires
              for(var d = 0; d < listDest.length; d++){

                var dest = listDest[d];

                // Si il y a des idGroupe dans chaque dest et que ce sont les même
                if(!_.isNil(destHide.idGroupe) && !_.isNil(dest.idGroupe)) {
                  if(destHide.idGroupe === dest.idGroupe) {
                    destExist = true;
                    break;
                  }
                }
                // Si il n'y a pas d'idGroupe mais un email dans les dest et que ce sont les même
                else if(!_.isNil(destHide.email) && !_.isNil(dest.email)) {
                  if(destHide.email === dest.email) {
                    destExist = true;
                    break;
                  }
                }
                // Sinon si il n'y a pas d'email et d'idGroupe ou que ce ne sont pas les meme
                else if(!_.isNil(destHide.libelle) && _.isNil(destHide.dieze) && _.isNil(dest.libelle) && _.isNil(dest.dieze)) {
                  if(destHide.libelle === dest.libelle && destHide.dieze === dest.dieze) {
                    destExist = true;
                    break;
                  }
                }
              }
            }

            if(!destExist) {
              listDest.push(destHide);
            }
          }
        }

        //listDest = _.union(listDest, listDestHide);

        // Si SMS
        if(ctrl.nouveauContenu.sms) {
          if(!_.isObject(ctrl.objEditorSms)) throw new Error('Il y a un problème avec le formulaire de SMS !');
          // Verification du SMS
          if (_.isNil(ctrl.objEditorSms.getData()) || ctrl.objEditorSms.getData() === '') throw new Error('Veuillez renseigner un SMS');
        }
        // Si Message
        else {
          if(!_.isObject(ctrl.objEditorMessage)) throw new Error('Il y a un problème avec le formulaire de message !');
          // Verification du message
          if (_.isNil(ctrl.objEditorMessage.getData()) || ctrl.objEditorMessage.getData() === '') throw new Error('Veuillez renseigner un message');
        }

        // console.log(listDest);
        // return;
        ctrl.loading = true;

        // Si modification d'un contenu message
        if(ctrl.nouveauContenu.idContenu) {

          var obj = {
            idContenu: ctrl.nouveauContenu.idContenu,
            message: ctrl.objEditorMessage.getData()
          };

          MessageService
            .updateMessage(obj)
            .then(function(obj){

              // Supprime la sauvegarde du localstorage
              AutoSaveService.delete(getObjContenu(), ctrl.nouveauContenu.getIdContenu(), ctrl.idContenuParent);

              emit({action: 'updateMessage', contenu: obj.message});
            })
            .catch(function(error){
              ModalsService.alertErreur(error);
            })
            .finally(function(){
              hideLoadingAndNotif(notifWait);
              onClickClose();
            });
        }
        // Si nouveau contenu message
        else {

          // Si le contenu n'est pas un SMS et qu'il est envoyé par email
          if(!ctrl.nouveauContenu.sms && ctrl.nouveauContenu.sendByMail) {
            // Si trop de documents
            if (isOverMaxSize()) throw new Error('Le poid maximum pour les pièces jointes envoyés par email est de ' + ctrl.convertSize(ctrl.maxSommePiecesJointes) + ' !');
          }
          if(!ctrl.nouveauContenu.sendByMail) ctrl.nouveauContenu.sendOneMailWithAllDest = null;

          if(ctrl.important) ctrl.nouveauContenu.addTagType('Important', 'Important');

          uploadDocuments()
            .then(function(docsUpload){

              var notifWait = null;
              NotificationsToastServices.wait('Patientez', 'Envoi du message en cours...', function (notif) {
                notifWait = notif;
              });

              var obj = {
                idTopic: ctrl.idTopic,
                objJSON: null
              };

              var proxyMessage = new MessageService.ProxyContenuMessage(ctrl.nouveauContenu);

              // Remplace les destinataire par listeDestinataires
              proxyMessage.setDestinataires(null);
              proxyMessage.setListeDestinataires(listDest);

              if(!_.isNil(ctrl.valueFieldObjet) && ctrl.valueFieldObjet !== '') {
                proxyMessage.setProxyMail({
                  sujet: ctrl.valueFieldObjet
                });
              }
              else if(ctrl.textSujet) {
                proxyMessage.setProxyMail({
                  sujet: ctrl.textSujet
                });
              }

              // Si le message n'est pas un SMS
              if(!proxyMessage.sms) {
                // Recupère le message de l'editeur WYSIWYG
                proxyMessage.setMessage(ctrl.objEditorMessage.getData());

                // Si il y a des documents
                if(docsUpload.length)
                  proxyMessage.setGuids(docsUpload);

                // Si il y a des documents venant du composant d'upload
                if(ctrl.nouveauContenu.listeDocument.length)
                  proxyMessage.addGuids(ctrl.nouveauContenu.listeDocument);

                // Si le message est envoyé par email et qu'il a une signature
                //proxyMessage.nouveauContenu.sendByMail &&
                // Si il a une signature
                if(_.isObject(ctrl.signatureSelected) && ctrl.signatureSelected.hasOwnProperty('value'))
                  proxyMessage.setSignature(ctrl.signatureSelected.value);

              }
              // Recupère le message de l'editeur SMS
              else
                proxyMessage.setMessage(ctrl.objEditorSms.getData());


              obj.objJSON = proxyMessage;

              //console.log(obj);
              //hideLoadingAndNotif(notifWait);
              //return;

              MessageService
                .addMessageInTopic(obj)
                .then(function(objRetourMessage){

                  if(!_.isObject(objRetourMessage.message)) throw new Error("Le retour de \"addMessage\" n'est pas bon");

                  // Supprime la sauvegarde du localstorage
                  AutoSaveService.delete(getObjContenu(), null, ctrl.idContenuParent);

                  emit({action: 'addMessage', contenu: objRetourMessage.message});
                })
                .catch(function(err){
                  console.error(err);
                  // Si il y a des documents il faut les supprimer car l'insertion du contenu à planté
                  if(listDocsUpload.length) {
                    listDocsUpload.map(function(doc){
                      DocumentManagerService
                        .deleteDocumentPerso(doc)
                        .then(function () {

                        })
                        .catch(function (msg) {
                          ModalsService.alertErreur(msg);
                        })
                        .finally(function(){
                          listDocsUpload = [];
                        });
                    });
                  }
                  ModalsService.alertErreur(err);
                })
                .finally(function(){
                  hideLoadingAndNotif(notifWait);
                  onClickClose();
                });
            })
            .catch(function(err) {
              console.error(err);
              // Si il y a des documents il faut les supprimer car l'insertion du contenu à planté
              if(listDocsUpload.length) {
                listDocsUpload.map(function(doc){
                  DocumentManagerService
                    .deleteDocumentPerso(doc)
                    .then(function () {

                    })
                    .catch(function (msg) {
                      ModalsService.alertErreur(msg);
                    })
                    .finally(function(){
                      listDocsUpload = [];
                    });
                });
              }
              hideLoadingAndNotif(notifWait);
            })
            .finally(function(){

            });

          /*
          MessageService
            .addMessageInTopic(obj)
            .then(function(objRetourMessage){

              if(!_.isObject(objRetourMessage.message)) throw new Error("Le retour de \"addMessage\" n'est pas bon");

              // Supprime la sauvegarde du localstorage
              AutoSaveService.delete(getObjContenu(), objRetourMessage.message.getIdContenu(), ctrl.idContenuParent);

              uploadDocuments(objRetourMessage.message.getIdContenu())
                .then(function(docsUpload){
                  if(_.isArray(docsUpload)){
                    for(var d = 0; d < docsUpload.length; d++){
                      objRetourMessage.message.listeDocument.push(docsUpload[d]);
                    }
                  }
                  emit({action: 'addMessage', contenu: objRetourMessage.message});
                })
                .catch(function(err) {
                  console.error(err);
                  hideLoadingAndNotif(notifWait);
                })
                .finally(function(){

                });

            })
            .catch(function(error){
              ModalsService.alertErreur(error);
            })
            .finally(function(){
              hideLoadingAndNotif(notifWait);
              onClickClose();
            });*/
        }
      }
      catch(e) {
        ModalsService.alertErreur(e.message);
        hideLoadingAndNotif();
      }
    }

    function hideLoadingAndNotif(notif) {
      if(_.isObject(notif)) NotificationsToastServices.clear(notif.id);
      ctrl.loading = false;
    }

    /**
     * Si ouvert en mode Modal
     */
    function onClickClose(){
      if(ctrl.modalInstance) ctrl.dismiss({$value: 'cancel'});
      else emit({action: 'closeReply', contenu: ctrl.contenuParent});
      //cleanReponseContenu();
    }

    /**
     * Lors du choix d'une signature
     * @param obj
     */
    function onEmitSelectSignatureContenu(obj){
      try {
        if (!obj) throw new Error('Pas d\'obj !');
        if (_.isObject(obj) && obj.hasOwnProperty('action') && obj.hasOwnProperty('signature')) {
          if(obj.action === 'changeSignature') ctrl.signatureSelected = obj.signature;
        }
      }
      catch (err) {
        ModalsService.alertErreur(err.message);
      }
    }

    /**
     * Lorsque le composant enfant upload-collab emit quelque chose
     * @param obj
     */
    function onEmitFromUploadCollab(obj){
      //console.log("formulaire-message",obj);
      try {
        if (!obj) throw new Error('Pas de "obj"');

        if(obj.action === 'updateDroppedDoc' && _.isArray(obj.docs)) {
          ctrl.nouveauContenu.listeDocument = _.unionBy([], angular.copy(obj.docs), 'guid');
          /*
          var docsRemove = [];
          var docsAdd = [];

          // Si plus de docs venant du composant mais bien des docs dans la liste des doc du contenu d'upload on retire tout
          if(!obj.docs.length && ctrl.nouveauContenu.listeDocument.length) docsRemove = angular.copy(ctrl.nouveauContenu.listeDocument);
          // Si il y a des docs venant du composant d'upload et pas de doc dans le contenu on ajoute tout
          else if(obj.docs.length && !ctrl.nouveauContenu.listeDocument.length) docsAdd = obj.docs;
          else if(obj.docs.length && ctrl.nouveauContenu.listeDocument.length && ctrl.nouveauContenu.listeDocument.length !== obj.docs.length) {
            for(var d = 0; d < ctrl.nouveauContenu.listeDocument.length; d++) {
              var docInContenu = ctrl.nouveauContenu.listeDocument[d];
              // Si le doc de la liste des docs n'est plus dans liste venant du composant upload on doit le retirer
              if(!UtilsService.where(obj.docs, {guid: docInContenu.getGuid()}).length) {
                docsRemove.push(docInContenu);
              }
            }

            for(var e = 0; e < obj.docs.length; e++) {
              var docFromComponent = obj.docs[e];
              // Si le doc venant du composant upload n'est pas dans la liste des docs du contenu on l'ajoute
              if(!UtilsService.where(ctrl.nouveauContenu.listeDocument, {guid: docFromComponent.getGuid()}).length) {
                docsAdd.push(docFromComponent);
              }
            }
          }
          if(docsRemove) removeDocumentFromContenu(docsRemove);
          if(docsAdd) addDocumentInContenu(docsAdd);*/
        }

        if(_.isObject(obj.uploader)){
          if(obj.action === 'uploaderReady'){
            uploader = obj.uploader;
          }
        }

        /*
        if(obj.action === 'onDrop' && _.isObject(obj.item)) {
          addDocumentInContenu(obj.item);
        }*/

        if(obj.action === 'onSuccessItem' && _.isObject(obj.fileItem) && _.isObject(obj.response)){
          var listDoc = DocumentManagerService.getListDocFromRetourUpload(obj);
          if(_.isArray(listDoc)){
            _.forEach(listDoc, function (document) {
              obj.fileItem._file.document = document;
              document.new = true;
              addDocumentInContenu(document);
              listDocsUpload.push(document);
            });
          }
        }
        if(obj.action === 'onCompleteAll'){
          deferredDocs.resolve(listDocsUpload);
        }

        if(obj.action === 'sommePiecesJointes' && !_.isNil(obj.sommePiecesJointes)) {
          ctrl.sommePiecesJointes = obj.sommePiecesJointes;
        }
      }
      catch (err) {
        console.log('[manageContrat.onEmitFromUploadCollab] Erreur : ' + err.message);
      }
    }
  }
})();
