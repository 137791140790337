(function() {

    'use strict';

    /**
     * @desc Composant gerer mes groupes
     * @example <admin-gestion-groupe></admin-gestion-groupe>
     */
    angular
        .module('collaboreApp')
        .component('adminGestionGroupe',{
            require: {
              adminGestionDesGroupesCtrl: '^adminGestionDesGroupes'
            },
            bindings: {
                groupe: '<',
                groupeParent: '<',
                onUpdate: '&'
            },
            templateUrl: 'app/parametrage/admin/gestion-des-groupes/admin-gestion-groupe.html',
            controllerAs: 'admingestiongroupectrl',
            controller: AdminGestionGroupeCtrl
        });


    /** @ngInject */
    function AdminGestionGroupeCtrl(COLLAB_CONF,$scope,$q,$timeout,sweet,ErreurCollabService,UtilsService,GroupesAdminService){
        var ctrl = this;

        ctrl.usersDrag = [];
        ctrl.loadingGroupe = COLLAB_CONF.MIDDLE_LOADING;
        ctrl.searchInGroupe = '';
        ctrl.filterInGroupe = '';
        ctrl.genereLibelle = genereLibelle;

        ctrl.startDragGroupe = startDragGroupe;                                             // Lors du Drag du groupe
        ctrl.onClickGroupe = onClickGroupe;                                                 // Lors du clique sur un header de groupe
        ctrl.onSubmitSearchGroupe = onSubmitSearchGroupe;                                   // Recherche d'un groupe
        ctrl.onSubmitFilterGroupe = onSubmitFilterGroupe;                                   // Recherche d'un groupe

        ctrl.onClickCreateNewGroupe = onClickCreateNewGroupe;                               // Création d'un groupe dans un groupe
        ctrl.onDropInGroupe = onDropInGroupe;                                               // Lors du drop d'un objet dans un groupe

        ctrl.onClickDeleteGroupeFromGroupe = onClickDeleteGroupeFromGroupe;                 // Suppression d'un groupe
        ctrl.onClickDeleteModuleFromGroupe = onClickDeleteModuleFromGroupe;                 // Suppression d'un module d'un groupe
        ctrl.onClickDeletePortefeuilleFromGroupe = onClickDeletePortefeuilleFromGroupe;     // Suppression d'un portefeuille d'un groupe
        ctrl.onDeleteUser = onDeleteUser;                                                   // Suppression d'un user d'un groupe

        ctrl.onUpdateGroupe = onUpdateGroupe;                                               // Lors de la modification d'un groupe

        ctrl.$onInit = function(){
          // Objet utilisé pour le drag d'un groupe
          ctrl.objectDrag = {
            groupeParent: ctrl.groupeParent,
            obj: ctrl.groupe
          };

          ctrl.genereObjGroupeParent = ctrl.adminGestionDesGroupesCtrl.genereObjGroupeParent;
        };

        /**
         * Permet de modifier les infos du portefeuille
         * @param portefeuille
         */
        function updatePortefeuille(portefeuille){
            if(ctrl.groupe.listePortefeuille.length){
                for(var i = 0 ; i < ctrl.groupe.listePortefeuille.length ; i++){
                    if(ctrl.groupe.listePortefeuille[i].idPortefeuille === portefeuille.idPortefeuille){
                        ctrl.groupe.listePortefeuille[i] = portefeuille;
                    }
                }
            }

        }
        /**
         * Permet de supprimer un portefeuille de la liste des portefeuilles
         * @param idPortefeuille
         */
        function removePortefeuille(idPortefeuille){
            if(ctrl.groupe.listePortefeuille.length){
                for(var i = 0 ; i < ctrl.groupe.listePortefeuille.length ; i++){
                    if(ctrl.groupe.listePortefeuille[i].idPortefeuille === idPortefeuille){
                        ctrl.groupe.listePortefeuille.splice(i,1);
                        ctrl.groupe.getGroupeInformations().nbPortefeuilles--;
                    }
                }
            }
        }

        /**
         * Lors du drag du groupe
         * @param event
         * @param ui
         */
        function startDragGroupe(event, ui){

            // Crée un listener qui écoute un evenement pour supprimer un groupeFils de son groupe parent
            var listenerRemoveGroupesFils = $scope.$on('removeGroupeFils',function(event,idGroupe, idGroupeFils){
                if(ctrl.onUpdate){
                    // Execute la méthode qui est dans l'attribut on-update du composant
                    // Le groupe actuellement en "Drag" est le groupe à supprimer lorsqu'il aurat été déplacé.
                    // (donc la méthode du composant parent car on supprime un groupe fils des groupes fils du parents)
                    ctrl.onUpdate({'action':{'removeGroupeFils':idGroupeFils,'fromGroupe':idGroupe}});
                }
                listenerRemoveGroupesFils(); // Supprime le listener
            });
            // Crée un listener qui écoute un evenement pour supprimer un groupeUser de son groupe parent
            var listenerRemoveGroupesUser = $scope.$on('removeGroupeUser',function(event,idGroupe, idGroupeUser){
                if(ctrl.onUpdate){
                    // Execute la méthode qui est dans l'attribut on-update du composant
                    // Le groupe actuellement en "Drag" est le groupe à supprimer lorsqu'il aurat été déplacé.
                    // (donc la méthode du composant parent car on supprime un groupe fils des groupes fils du parents)
                    ctrl.onUpdate({'action':{'removeGroupeUser':idGroupeUser,'fromGroupe':idGroupe}});
                }
                listenerRemoveGroupesUser(); // Supprime le listener
            });
            ctrl.adminGestionDesGroupesCtrl.startDragGroupe(event, ui);
        }

        /**
         * Lors du clique sur un groupe ouvre/ferme le groupe et appel les details
         * @param action
         * @param groupe
         */
        function onClickGroupe(action,groupe){

            var listenerUpdatePortefeuille,listenerRemovePortefeuille;
            if(action.open){
                groupe.loading = true;
                GroupesAdminService.getDetailGroupe(groupe.libelle+groupe.dieze).then(function(groupe){
                    ctrl.groupe = groupe;
                }).finally(function(){
                    groupe.loading = false;
                });

                listenerUpdatePortefeuille = $scope.$on('updatePortefeuille',function(event,newPortefeuille){
                    updatePortefeuille(newPortefeuille);
                    listenerUpdatePortefeuille(); // Supprime le listener
                });

                listenerRemovePortefeuille = $scope.$on('removePortefeuille',function(event,idPortefeuille){
                    removePortefeuille(idPortefeuille);
                    listenerRemovePortefeuille(); // Supprime le listener
                });
            }

            if(action.close){
                // Supprime les listener si ils existent encore
                if(listenerUpdatePortefeuille){
                    listenerUpdatePortefeuille();
                }
                if(listenerRemovePortefeuille){
                    listenerRemovePortefeuille();
                }
            }
        }

        /**
         * Filtre les groupes
         * @param searchVal
         * @param groupe
         * @returns {Array}
         */
        function filtreGroupes(searchVal,groupe) {
            var filtered = [];

             for(var i = 0 ; i < groupe.groupesFils.length ; i++){
                 var currentGroupe = groupe.groupesFils[i];
                 var testText = currentGroupe.getDefaultNom();
                 if(currentGroupe.getFiche()){
                     testText += ' '+currentGroupe.getFiche().getDefaultNom();
                 }

                 if(UtilsService.test(testText,searchVal,'ig')){
                     filtered.push(currentGroupe);
                 }
             }

            return filtered;
        }

        /**
         * Lors du submit de la recherche d'un groupe
         * @param groupe
         */
        function onSubmitSearchGroupe(groupe){

            var newval = groupe.filteredGroupes.search;

            if( !newval ) {
                groupe.filteredGroupes.show = false;
                groupe.filteredGroupes.loading = false;
            }
            else {
                groupe.filteredGroupes.show = false;
                groupe.filteredGroupes.loading = COLLAB_CONF.MIDDLE_LOADING;

                $timeout(function(){
                    groupe.filteredGroupes.groupes = filtreGroupes(newval,groupe);
                    //console.log(groupe.filteredGroupes.groupes);
                    groupe.filteredGroupes.loading = false;
                    groupe.filteredGroupes.show = true;
                });
            }
        }

        function onSubmitFilterGroupe(){
            ctrl.filterInGroupe = angular.copy(ctrl.searchInGroupe);
        }

        /**
         * Lors du clique sur la création d'un nouveau groupe
         * @param groupePapa
         */
        function onClickCreateNewGroupe(groupePapa){
            ctrl.adminGestionDesGroupesCtrl.onClickCreateNewGroupe(groupePapa);
            /*
            ctrl.adminGestionDesGroupesCtrl.onClickCreateNewGroupe(groupePapa,function(groupe){
                ctrl.groupe = groupe;
            });*/
        }

        /**
         * Lorsque l'on glisse un objet dans un groupe (portefeuille,module,groupe user et non user)
         * @param event
         * @param ui
         * @param groupeDestinataire
         */
        function onDropInGroupe(event,ui,groupeDestinataire){

            ctrl.adminGestionDesGroupesCtrl.onDropInGroupe(event,ui,groupeDestinataire);
            /*
            ctrl.adminGestionDesGroupesCtrl.onDropInGroupe(event,ui,groupeDestinataire,function(groupe){
                ctrl.groupe = groupe;
            });*/
            /*
            ctrl.adminGestionDesGroupesCtrl.onDropInGroupe(event,ui,groupeDestinataire,function(obj){

                if(obj.isPortefeuille){
                    ctrl.groupe.listePortefeuille.push(obj);
                }
                else if(obj.isModule){
                    ctrl.groupe.listeModule.push(obj);
                }
                else{
                    if(obj.isUser()){
                        ctrl.groupe.groupesUser.push(obj);
                    }else{
                        ctrl.groupe.groupesFils.push(obj);
                    }
                }
            });*/
        }

        /**
         * Lorsque 'lon delet un user d'un groupe
         * @param groupe
         */
        function onDeleteUser(obj){
            if(angular.isObject(obj)) {
                if(obj.hasOwnProperty('groupe')) {
                    return onClickDeleteGroupeFromGroupe({
                        groupeParent: ctrl.groupe,
                        obj: obj.groupe
                    });
                }
            }

            /*
            if(angular.isObject(obj)) {
                if(obj.hasOwnProperty('listeUsers')) {
                    ctrl.groupe.groupesUser = obj.listeUsers;
                }
                if(obj.hasOwnProperty('groupe')) {
                    onClickDeleteGroupeFromGroupe({
                        'groupeParent': ctrl.groupe,
                        'obj': obj.groupe
                    });
                }
            }*/
        }

        /**
         * Lorsque l'on delete un groupe non user d'un autre groupe
         * @param groupeDelete
         * @returns {IPromise<T>}
         */
        function onClickDeleteGroupeFromGroupe(groupeDelete){
            var deferred = $q.defer();
            var groupeParent = false,libelle = false,libelleGroupeParent = false;

            //console.log('groupeDelete',groupeDelete);


            if(angular.isObject(groupeDelete.obj)){
                if(groupeDelete.obj.hasOwnProperty('libelle')){
                    libelle = groupeDelete.obj.getLibelle();
                }else if(groupeDelete.obj.hasOwnProperty('nom')){
                    libelle = groupeDelete.obj.getNom();
                }

                if(groupeDelete.groupeParent){
                    groupeParent = groupeDelete.groupeParent;
                }else{
                    groupeParent = ctrl.groupeParent;
                }

                if(groupeParent){
                    libelleGroupeParent = groupeParent.libelle;
                }
            }else if(angular.isString(groupeDelete.obj)){
                libelle = groupeDelete.obj;
            }


            if(groupeDelete.obj.isUser()){
                if(groupeDelete.obj.getUser().getFichePerso()){
                    libelle = groupeDelete.obj.getUser().getFichePerso().getNomPrenom();
                }
            }

            var titre = 'Supprimer un utilisateur';
            var texte = 'Voulez-vous vraiment supprimer cet utilisateur de ce groupe ?';
            if(libelle){
                texte = 'Voulez-vous vraiment supprimer <span class="badge badge-destinataire destinataire-text badge-destinataire-samegroupe">'+libelle+'</span> de ce groupe ?';
                if(libelleGroupeParent){
                    texte = 'Voulez-vous vraiment supprimer <span class="badge badge-destinataire destinataire-text badge-destinataire-samegroupe">'+libelle+'</span> du groupe "<strong>'+libelleGroupeParent+'</strong>" ?';
                }
            }

            if(!groupeDelete.obj.isUser()){

                titre = 'Supprimer un groupe';
                texte = 'Voulez-vous vraiment supprimer ce groupe ?';
                if(libelle){
                    texte = 'Voulez-vous vraiment supprimer le groupe "<strong>'+libelle+'</strong>" ?';
                    if(libelleGroupeParent){
                        texte = 'Voulez-vous vraiment supprimer le groupe "<strong>'+libelle+'</strong>" du groupe "<strong>'+libelleGroupeParent+'</strong>" ?';
                    }
                }
            }

            sweet.show({
                title: titre,
                text: texte,
                type: 'warning',
                html: true,
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Oui !',
                closeOnConfirm: false,
                showLoaderOnConfirm: true
            },function(isConfirm){


                if(isConfirm){

                    if(angular.isObject(groupeDelete.obj)){

                        if(groupeDelete.obj.hasOwnProperty('idGroupe') && groupeParent){
                            var objParam = {
                                'idGroupeChild': groupeDelete.obj.getIdGroupe(),
                                'idGroupeParent': groupeParent.idGroupe
                            };

                            // Supprime le groupe/user du groupe
                            GroupesAdminService.deleteGroupeFromGroupe(objParam).then(function(){

                                if(groupeDelete.obj.isUser()){
                                    if(ctrl.groupe.groupesUser.length){
                                        for(var i = 0 ; i < ctrl.groupe.groupesUser.length ; i++){
                                            if(ctrl.groupe.groupesUser[i].idGroupe===groupeDelete.obj.idGroupe){
                                                ctrl.groupe.groupesUser.splice(i,1);
                                                ctrl.groupe.getGroupeInformations().nbGroupesUser--;
                                            }
                                        }
                                    }
                                }else{
                                    if(ctrl.onUpdate){
                                        ctrl.onUpdate({'action':{'removeGroupeFils':groupeDelete.obj.idGroupe,'fromGroupe':groupeParent.idGroupe}});
                                    }
                                }

                                /*
                                if(ctrl.onUpdate){
                                    ctrl.onUpdate({'action':{'updateGroupe':groupePapa}});
                                }*/


                                sweet.close();
                                deferred.resolve();


                            },function(msg){
                                ErreurCollabService.alert(msg);
                                deferred.reject(msg);
                            });
                        }
                    }else{
                        sweet.close();
                    }
                }else{
                    deferred.reject();
                    sweet.close();
                }
            });

            return deferred.promise;
        }

        /**
         * Lorsque l'on delete un module d'un groupe
         * @param module
         * @param groupe
         */
        function onClickDeleteModuleFromGroupe(module,groupe){
            var texte = 'Voulez-vous vraiment supprimer le module "<strong>'+module.getLibelle()+'</strong>" du groupe "<strong>'+groupe.getLibelle()+'</strong>"';
            sweet.show({
                title: 'Supprimer un module d\'un groupe',
                text: texte,
                type: 'warning',
                html:true,
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Oui !',
                closeOnConfirm: false,
                showLoaderOnConfirm: true
            },function(isConfirm){
                if(isConfirm){

                    var objParam = {
                        'idModule': module.getIdModule(),
                        'idGroupe': groupe.getIdGroupe()
                    };


                    GroupesAdminService.deleteModuleInGroupe(objParam).then(function(){
                        groupe.listeModule = UtilsService.removeWith(groupe.listeModule,{'idModule':module.idModule});
                        groupe.getGroupeInformations().nbModules = groupe.listeModule.length;
                        sweet.close();
                    },function(msg){
                        ErreurCollabService.alert(msg);
                    });
                }else{
                    sweet.close();
                }
            });
        }

        /**
         * Lorsque l'on delete un portefeuille d'un groupe
         * @param portefeuille
         * @param groupe
         */
        function onClickDeletePortefeuilleFromGroupe(portefeuille,groupe){
            var texte = 'Voulez-vous vraiment supprimer le portefeuille "<strong>'+portefeuille.getNom()+'</strong>" du groupe "<strong>'+groupe.getLibelle()+'</strong>"';
            sweet.show({
                title: 'Supprimer un portefeuille d\'un groupe',
                text: texte,
                type: 'warning',
                html:true,
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Oui !',
                closeOnConfirm: false,
                showLoaderOnConfirm: true
            },function(isConfirm){
                if(isConfirm){
                    var params = {
                        'idPortefeuille': portefeuille.getIdPortefeuille(),
                        'idGroupe': groupe.getIdGroupe()
                    };

                    GroupesAdminService.deletePortefeuilleFromGroupe(params).then(function(){

                        /*
                         var listePortefeuilles = groupe.getListePortefeuille();
                         listePortefeuilles = $filter('removeWith')(listePortefeuilles,{'idPortefeuille':portefeuille.getIdPortefeuille()});
                         groupe.setListePortefeuille(listePortefeuilles);*/

                        groupe.listePortefeuille = UtilsService.removeWith(groupe.listePortefeuille,{'idPortefeuille':portefeuille.idPortefeuille});
                        groupe.getGroupeInformations().nbPortefeuilles = groupe.listePortefeuille.length;
                        sweet.close();
                        /*updateGroupes().then(function(){
                         sweet.close();
                         });*/

                    },function(msg){
                        ErreurCollabService.alert(msg);
                    });
                }else{
                    sweet.close();
                }
            });
        }

        /**
         * Génère un libelle pour le groupe
         * @param groupe
         * @returns {*|String}
         */
        function genereLibelle(groupe){

            var libelle = groupe.getLibelle();

            if(groupe.isPortefeuille()){
                libelle = 'Portefeuille '+groupe.getLibelle();
            }else if(groupe.isImmeuble()){
                libelle = 'Immeuble '+groupe.getDefaultNom();
            }else if(groupe.isSyndic()){
                libelle = 'Syndic';
            }else if(groupe.isNormaux()){
                libelle = 'Copropriétaires/Porpriétaires/Locataires';
            }else if(groupe.isLocataire()){
                libelle = 'Locataires';
            }else if(groupe.isCoproprietaire()){
                libelle = 'Copropriétaires';
            }else if(groupe.isProprietaire()){
                libelle = 'Porpriétaires';
            }else if(groupe.isRI()){
                libelle = 'Responsable Immeuble';
            }else if(groupe.isNI()){
                libelle = 'Négociateur';
            }else if(groupe.isAI()){
                libelle = 'Assistant';
            }else if(groupe.isCI()){
                libelle = 'Comptable';
            }else if(groupe.isEI()){
                libelle = 'Employé';
            }else if(groupe.isCS()){
                libelle = 'Conseil Syndical';
            }else if(groupe.isPCS()){
                libelle = 'Président du Conseil Syndical';
            }else if(groupe.isMCS()){
                libelle = 'Membre du Conseil Syndical';
            }
            return libelle;
        }

        /**
         * Lors de la modification d'un composant "adminGestionGroupe" enfant de ce composant
         * @param action
         */
        function onUpdateGroupe(action){
            ctrl.adminGestionDesGroupesCtrl.fctOnUpdateGroupe(ctrl.groupe,action);
        }
    }
})();
