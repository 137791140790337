(function () {
  'use strict';

  /**
   * @desc Composant main pour les courrier
   * @example <documents></documents>
   */

  angular
    .module('collaboreApp')
    .component('documents', {
      templateUrl: 'app/topics/components/documents/documents.component.html',
      bindings: {
        idContenu: '<',
        idTopic: '<',
        document: '<',
        documents: '<',
        useLibelleType: '<',    // Permet d'afficher le libelle du type de document à la place du nom
        showNom: '<',           // Affiche ou non le nom des doc
        showLibelleBtn: '<',    // Affiche ou non le libelle des boutons
        truncateLength: '<',    // Permet de limiter le nombre de caractère à afficher
        hideBtnDownload: '<',   // Permet de cacher le bouton "Télécharger"
        hideBtnView: '<',       // Permet de cacher le bouton "Voir"
        allowDelete: '<',       // Permet de delete ou non un fichier
        noConfirmDelete: '<',
        type: '@',              // Permet de connaitre le type de document
        draggable: '<',
        classBtn: '@',
        classIcon: '@',
        withLabelType: '<',     // Permet d'afficher une colonne avec un label pour le type de fichier
        withSmallLabelType: '<',     // Permet d'afficher une colonne avec un label pour le type de fichier
        execEmitOpen: '<',          // Permet d'executer l'emit lors de la seleciton d'un fichier
        onEmit: '&'
      },
      controllerAs: 'documentsctrl',
      controller: DocumentsController
    });

  /*@ngInject*/
  function DocumentsController($q, $filter, ModalsService, TopicManagerService, ContenuService, UtilsService, DocumentManagerService) {

    var ctrl = this;
    ctrl.document = null;
    ctrl.documents = [];
    ctrl.truncate = truncate;
    ctrl.startDrag = startDrag;
    ctrl.onClickOpen = onClickOpen;
    //ctrl.onClickEnvoyerVersComfact = onClickEnvoyerVersComfact;
    ctrl.onClickDownload = onClickDownload;
    ctrl.onClickDelete = onClickDelete;

    ctrl.$onInit = function () {
      if(_.isNil(ctrl.showNom)) ctrl.showNom = true;
      if(_.isNil(ctrl.showLibelleBtn)) ctrl.showLibelleBtn = true;
      if(_.isNil(ctrl.classBtn)) ctrl.classBtn = 'btn-xs';
      if(_.isNil(ctrl.draggable)) ctrl.draggable = false;
      init();
    };

    ctrl.$onChanges = function(changes){
      if(_.isObject(changes.documents) && !changes.documents.isFirstChange()){
        ctrl.documents = changes.documents.currentValue;
        init();
      }
      if(_.isObject(changes.document) && !changes.document.isFirstChange()){
        ctrl.document = changes.document.currentValue;
      }
    }

    function init(){
      ctrl.eventModifiable = TopicManagerService.currentTopicIsModifiableByAgence();
      if(_.isObject(ctrl.documents)) {
        if (!_.isArray(ctrl.documents)) ctrl.documents = angular.copy([ctrl.documents]);
        else ctrl.documents = angular.copy(recurciveGetDoc(ctrl.documents));
        if(ctrl.documents.length){
          for(var d = 0; d < ctrl.documents.length; d++){
            ctrl.documents[d].lastGedLink = ctrl.documents[d].getLastGedLinks(ctrl.idContenu, ctrl.idTopic);
          }
        }
      }
    }

    function recurciveGetDoc(docs){
      var newList = docs.reduce(function(accum, doc){
        if(_.isArray(doc)) accum = _.concat(accum, recurciveGetDoc(doc));
        else accum.push(doc);
        return accum;
      }, []);
      return newList;
    }

    function getIconFileType(doc){
      var icon = null;

      switch(doc.extension) {
        case 'pdf':
          icon = 'fa-file-pdf-o';
          break;
        default:
          icon = 'fa-file-o';
      }
      return icon;
    }

    /**
     * Permet de limiter le nombre de caractères pour le nom du fichier
     * @param libelle
     */
    function truncate(libelle){
      if(ctrl.truncateLength) {
        libelle = UtilsService.truncate(libelle, ctrl.truncateLength, '...');
      }
      return libelle;
    }

    /**
     * Lors du start d'un drag and drop
     * @param event
     * @param ui
     */
    function startDrag(event, ui) {
      angular.element(ui.helper).addClass('ui-draggable-helper');
    }

    /**
     * Lors du clique sur le bouton pour ouvrir le document
     * @param document
     */
    /*
    function onClickEnvoyerVersComfact() {

        var options = {
            backdrop: 'static'
        };
        var params = {
            idTopic: ctrl.idTopic,
            idDevis: ctrl.idDevis,
            facture: ctrl.facture,
            isIntervention: ctrl.devisIsIntervention,
            tagsType: ctrl.tagsType,
            objHasDocuments: {
                demandeDevis: ctrl.hasDemande,
                devisSigne: ctrl.hasDevisSigne
            },
            showCloture: (!ctrl.eventCloture),
            isCloture: ctrl.eventCloture
        };

        ModalsService
            .modalComponent('comfactFacture', params, options)
            .then(function (obj) {

                if((obj.action === 'sentToComfact' || obj.action === 'sentToComfactAndCloture') && obj.hasOwnProperty('devis')){

                    var object = {
                        objUpdate: {
                            update: {
                                contenu: obj.devis
                            }
                        }
                    };
                    if(obj.action === 'sentToComfactAndCloture') object.objUpdate.refreshTopic = true;
                    ctrl.onUpdate(object);
                }
            })
            .catch(function (msg) {
                if (msg !== 'cancel') {
                    ModalsService.alertErreur(msg);
                }
            });
    }*/

    /**
     * Lors du clique sur le bouton pour ouvrir le document
     * @param document
     */
    function onClickOpen(document) {
      open(document);
    }

    /**
     * Lors du clique sur le bouton pour télécharger le document
     * @param document
     */
    function onClickDownload(document) {
      download(document);
    }

    /**
     * Lors du clique sur le bouton pour supprimer le document
     * @param document
     */
    function onClickDelete(document) {
      deleteDocument(document);
    }

    /**
     * Ouvre un document
     * @param document
     */
    function open(document) {
      if(ctrl.execEmitOpen && document.getExtension() === '.pdf') {
        emit(document, 'open');
      }
      else {
        DocumentManagerService.openDocument(document.guid, ctrl.idContenu, ctrl.idTopic);
      }
    }

    /**
     * Telecharge un fichier
     * @param document
     */
    function download(document) {
      DocumentManagerService.downloadDocument(document.guid, ctrl.idContenu, ctrl.idTopic);
    }

    function deleteDocument(document) {

      if(ctrl.noConfirmDelete) {
        emit(document, 'delete');
        return;
      }

      ModalsService
        .confirm('Suppression du document', 'Voulez-vous vraiment supprimer le document ?')
        .then(function (modal) {
          /*
      if(_.isString(ctrl.type)) {
          if(ctrl.type === 'documents_fournisseur') {

          }
          else if(ctrl.type === 'devis') {

          }
      }*/

          emit(document, 'delete');

          document.loading = true;

          modal.close();
        });
    }

    function emit(document, action) {
      var obj = {
        obj: {
          action: action,
          document: document,
          property: ctrl.type
        }
      };

      ctrl.onEmit(obj);
    }
  }
})();
