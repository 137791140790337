(function() {

  'use strict';

  /**
   * @desc Componsant pour choisir un destinataire
   * @example <destinataire></destinataire>
   */

  angular
    .module('collaboreApp')
    .component('destinataire',{
      templateUrl: 'app/topics/components/destinataire/destinataire.component.html',
      bindings : {
        loading: '<',
        allowAddDestinataire: '<',         // Permet de savoir si on donne la possiblité d'ajouter des destinataires
        mode: '@',                        // Mode du composannt (list)
        libelleTitre: '<',                // Permet de mettre un titre custom
        modalTitre: '<',                  // Permet de mettre un titre custom à la modal
        hideNoDest: '<',                  // Permet de cacher le message pas de destinataires
        idContenu: '<',                   // IdContenu
        typeContenu: '@',                 // Type de contenu (evenement, devis, message etc...)
        destinataires: '<',               // La liste des destinataires du contenu
        limitDisplayDestinataire: '<',    // Nombre de groupe à afficher avant d'afficher le reste en popover
        allowDeleteDestinataire: '<',     // Permet ou non de supprimer un groupe de la liste des destinataires
        currentModelEvenement: '<',       // Model actuel choisi dans la création de l'événement
        //onEndGetGroupeAll: '&',          // Lorsque le composant a fini de récupérer la hiérarchie
        onChangeDestinataires: '&'       // Lorsque la liste des destinataires change, notifie le composant parent
        //popoverPlacementAddDestinataire: '<'
      },
      controllerAs: 'destinatairectrl',
      controller: DestinataireController
    });

  /*@ngInject*/
  function DestinataireController($scope, $injector, $timeout, GroupeService, UtilsService, AnnuaireService){

    var _this = this;
    var listenerClose = null;
    _this.loading = false;
    _this.titre = false;
    _this.libreDestinataire = '';

    _this.triggerPopover = 'outsideClick';
    _this.triggerPopoverEnabled = true;
    _this.messageErreurDestinataireLibre = null;
    _this.itsMe = UtilsService.itsMe;
    _this.onPopoverIsOpenChange = onPopoverIsOpenChange;
    _this.onSubmitLibreDestinataire = onSubmitLibreDestinataire;
    _this.openRechercheAvance = openRechercheAvance;
    _this.onRemoveDestinataire = onRemoveDestinataire;
    _this.onClickSaveDestinataires = onClickSaveDestinataires;
    _this.close = close;
    _this.onClickCancel = onClickCancel;
    _this.onChangeCollaborateur = onChangeCollaborateur;
    //_this.onEndGetGroupeAllFromDestinataireComponent = onEndGetGroupeAllFromDestinataireComponent;

    _this.$onInit = function() {

      //if(_.isUndefined(_this.popoverPlacementAddDestinataire)) _this.popoverPlacementAddDestinataire = "'auto'";

      if(angular.isUndefined(_this.libelleTitre)) _this.titre = genereTitle(_this.libelleTitre);
      else if(_this.libelleTitre) _this.titre = _this.libelleTitre;

      if(angular.isUndefined(_this.modalTitre)) _this.modalTitre = "Destinataires";

      if(_.isNil(_this.allowAddDestinataire)) _this.allowAddDestinataire = true;


      _this.listeDestinataires = [];  // Liste des destinataires actuel du composant
      if(_this.destinataires) {
        var dest = [];
        if(_this.destinataires.length) {
          for(var i = 0; i < _this.destinataires.length; i++){
            if(!_this.itsMe(_this.destinataires[i])) dest.push(angular.copy(_this.destinataires[i]));
          }
        }
        _this.listeDestinataires = dest;
        initAutresDestinataires();
      }
      _this.newDestinataires = []; // Liste des destinatiares pour les nouveaux destinataire si contenu existe déjà

      if(!_.isNil(_this.mode)){
        if(_this.mode === 'list'){
          _this.titre = false;
          _this.allowAddDestinataire = false;
          _this.allowDeleteDestinataire = false;
        }
      }
    };

    /**
     * Lors de changements depuis le parent
     * @param changes
     */
    _this.$onChanges = function (changes) {
      if(changes.destinataires) {
        var dest = [];
        if(changes.destinataires.currentValue.length) {
          for(var i = 0; i < changes.destinataires.currentValue.length; i++){
            if(!_this.itsMe(changes.destinataires.currentValue[i])) dest.push(angular.copy(changes.destinataires.currentValue[i]));
          }
        }
        _this.listeDestinataires = dest;
        initAutresDestinataires();
      }
      if(changes.loading) {
        _this.loading = changes.loading.currentValue;
      }
    };

    _this.$onDestroy = function(){
      if(listenerClose) listenerClose();
    };


    /**
     * Initlisation
     */
    function init(){

      _this.configScrollbar = {
        autoHideScrollbar: false,
        theme: 'dark-3',
        advanced: {
          updateOnContentResize: true
        },
        scrollButtons: {
          scrollAmount: 'auto', // scroll amount when button pressed
          enable: false // enable scrolling buttons by default
        },
        axis: 'y', // enable 2 axis scrollbars by default
        //setHeight: 200,
        scrollInertia: 100
      };

      listenerClose = $scope.$on('closeTooltipDestinataire', function(){
        _this.close();
      });

      $timeout(function(){
        var $window = $injector.get('$window');
        var element = $window.document.getElementById('inputDestinataireLibre');
        if(element) element.focus();
      });

    }

    function initAutresDestinataires(){
      _this.autresDestinataires = [];
      if(_this.listeDestinataires.length) {
        var nb = 1;
        for(var d = 0; d < _this.listeDestinataires.length; d++) {

          // Si l'index du la boucle est plus grand que le nombre de destinataire display
          if(!_this.itsMe(_this.listeDestinataires[d])) {
            if(nb > _this.limitDisplayDestinataire) {
              _this.autresDestinataires.push(_this.listeDestinataires[d]);
            }
            nb++;
          }

        }
      }
    }

    function genereTitle(){
      var title = '';
      switch(_this.typeContenu) {
        case 'evenement':
          title = 'Evenement modifiable par les destinataires suivant';
          break;
        case 'message':
          title = 'Destinataires';
          break;
        default:
          title = 'Modifiable par les destinataires suivant';
      }
      return title;
    }

    /**
     * Executé lorsque la variable "popoverIsOpen" change
     * @param newVal
     */
    function onPopoverIsOpenChange(newVal){
      if(newVal) init();
      else if(listenerClose) listenerClose();
    }

    /**
     * Lors du submit du formulaire d'ajout de destinataire libre
     */
    function onSubmitLibreDestinataire(){
      _this.messageErreurDestinataireLibre = '';
      if(_this.libreDestinataire !== '') {
        var obj = null;

        // Si email
        if(UtilsService.isMail(_this.libreDestinataire)) obj = {isMail: true, libelle: _this.libreDestinataire, dieze: ''};
        // Si groupe collab
        else if(UtilsService.isStringGroupeCollab(_this.libreDestinataire)) obj = UtilsService.convertStringToGroupeCollab(_this.libreDestinataire);
        // Erreur
        else _this.messageErreurDestinataireLibre = 'Veuillez saisir : exemple#Societe OU un email';

        if(obj) {
          $scope.$broadcast('addCollaborateur', obj);
          _this.libreDestinataire = '';
        }
      }
    }

    /**
     * Lors du clique pour le bouton pour ouvrir l'annuaire
     */
    function openRechercheAvance(){
      _this.popoverIsOpen = false;
      var obj = {
        stringTo: 'destinataires',
        currentModelEvenement: _this.currentModelEvenement
        //limitDestinataire: false,
        //defautTypeAnnuaire: ctrl.defautTypeAnnuaire,
        //onlyFournisseur: ctrl.onlyFournisseur,
        //allCollaborateur: ctrl.allCollaborateur,
        /*infosEvent: {
          displayContratsLinks: true,
          numMandat: ctrl.numMandat,
          numImmeuble: ctrl.numImmeuble,
          portefeuille: ctrl.portefeuille
        }*/

      };

      //_this.triggerPopoverEnabled = false;
      //_this.triggerPopover = 'click';
      AnnuaireService
        .openAnnuaire(obj)
        .then(function(objRetour){
          //console.log(objRetour);
          //_this.beforeAdd({obj: objRetour});
          if(objRetour.hasOwnProperty('arrayContacts') && angular.isArray(objRetour.arrayContacts) && objRetour.arrayContacts.length){
            //$scope.$broadcast('addCollaborateur', objRetour.arrayContacts);
            // Si update contenu
            if(_this.idContenu) _this.newDestinataires = GroupeService.addGroupeToList(objRetour.arrayContacts, _this.newDestinataires);
            // Si nouveau contenu
            else {
              _this.listeDestinataires = GroupeService.addGroupeToList(objRetour.arrayContacts, _this.listeDestinataires);
              emitDestinataires();
            }
          }
        })
        .catch(function(msg){
          if(msg === 'cancel') {
            //_this.triggerPopoverEnabled = true;
          }
        })
        .finally(function(){
          $timeout(function(){
            _this.popoverIsOpen = true;
          });
        });
    }

    /**
     * Lorsqu'un groupe est retiré des destinataires avec la petite croix
     * @param obj
     */
    function onRemoveDestinataire(obj, closePopup){
      //console.log(obj);
      if(obj.groupe) {
        _this.listeDestinataires = GroupeService.removeGroupeFromList(obj.groupe, _this.listeDestinataires);
        _this.autresDestinataires = GroupeService.removeGroupeFromList(obj.groupe, _this.autresDestinataires);
        _this.newDestinataires = GroupeService.removeGroupeFromList(obj.groupe, _this.newDestinataires);
        if(closePopup) _this.popoverIsOpen = false;
        emitDestinataires();
      }
    }

    function onClickSaveDestinataires(){
      if(_this.idContenu) {

        var nouveauxDestinataire = [];
        if(_this.newDestinataires.length) {
          for(var i = 0; i < _this.newDestinataires.length; i++){
            if(_this.newDestinataires[i].isMail) nouveauxDestinataire.push(_this.newDestinataires[i].libelle);
            else nouveauxDestinataire.push(_this.newDestinataires[i].libelle + _this.newDestinataires[i].dieze);
          }
        }

        if(nouveauxDestinataire.length) {
          _this.loadingSaveDestinataires = true;
          var ContenuService = $injector.get('ContenuService');
          ContenuService
            .addDestinatairesToContenu(_this.idContenu, nouveauxDestinataire)
            .then(function(groupes){
              _this.newDestinataires = [];

              if(groupes.length) {
                for(var g = 0; g < groupes.length; g++){
                  _this.listeDestinataires.push(groupes[g]);
                }
                _this.onChangeDestinataires({destinataires: _this.listeDestinataires});
                init();
              }
            })
            .finally(function(){
              _this.loadingSaveDestinataires = false;
            });
        }
      }
    }

    function emitDestinataires(){
      initAutresDestinataires();
      if(!_this.idContenu) _this.onChangeDestinataires({destinataires: _this.listeDestinataires});
    }

    function close(){
      _this.popoverIsOpen = false;
    }

    function onClickCancel(){
      _this.close();
      //_this.newDestinataires = [];
      //$scope.$broadcast('clearListNewCollaborateurs');
    }


    function onChangeCollaborateur(obj){
      // Si update contenu
      if(_this.idContenu && obj.hasOwnProperty('newCollaborateurs')) _this.newDestinataires = angular.copy(obj.newCollaborateurs);
      // Si nouveau contenu
      else if(obj.hasOwnProperty('collaborateurs')) {
        _this.listeDestinataires = angular.copy(obj.collaborateurs);
        emitDestinataires();
      }
      //console.log(obj);
    }

    /*
    function onEndGetGroupeAllFromDestinataireComponent(groupeAll){
      _this.onEndGetGroupeAll({groupeAll: groupeAll});
    }
    */
  }

  Object.defineProperty(DestinataireController.prototype,
    'popoverIsOpen', {
      get: function () {
        return this._popoverIsOpen;
      },
      set: function (newValue) {
        this._popoverIsOpen = newValue;

        //Call method on update
        this.onPopoverIsOpenChange(this._popoverIsOpen);
      },
      enumerable: true,
      configurable: true
    });
})();
