(function() {

  'use strict';

  /**
   * @desc Formulaire de contenu type Message
   * @example <contenu-message-formulaire></contenu-message-formulaire>
   */

  angular
    .module('collaboreApp')
    .component('contenuMessageFormulaire', {
      require: {
        contenuFormulaireCtrl: '^contenuFormulaire'
      },
      templateUrl: 'app/topics/contenu-message-formulaire.component.html',
      bindings: {
        destinataires: '<',	// <Array> Liste des destinataires de base du message
        hideBtnClose: '<'
      },
      controllerAs: 'contenumessageformulairectrl',
      controller: ContenuMessageFormulaireController
    });

  /*@ngInject*/
  function ContenuMessageFormulaireController($injector, $rootScope, $timeout, COLLAB_CONF, COLLAB_VALUES, $state, $scope, $filter, sweet, TopicManagerService, DocumentService, DocumentsTopicService, ContenuMessage, AutoSaveService, NotificationsToastServices, UtilsService, UserService, ProxyContenuMessage) {

    var ctrl = this;
    var defaultReponseDestinataires = [];

    ctrl.showSendOneMailWithAllDest = false;
    ctrl.important = false;
    ctrl.notMobile = UtilsService.notMobile;
    ctrl.onFocusTextareaOnMobile = onFocusTextareaOnMobile;
    ctrl.onBlurTextareaOnMobile = onBlurTextareaOnMobile;

    // Destinataire
    ctrl.onChangeDestinataires = onChangeDestinataires;

    ctrl.parseObjetMessage = UtilsService.parseObjetMessage;	// Permet de garder les attributs style du HTML, sinon il sont supprim�
    ctrl.submitProgress = false;
    ctrl.openFromCommunication = false;
    //ctrl.allTags = [];
    ctrl.listeDestinataire = [];		// Liste des destinataires
    ctrl.nouveauxDestinataires = [];	// Tableau des nouveaux destinataires à rajouter si contenu sauvegardé
    ctrl.deletedDestinataires = [];		// Tableau des destinataires supprimé de la liste à supprimer du contenu si contenu sauvegardé et qu'il existe dans la liste

    // Tableau des pièces jointes à envoyer
    ctrl.documentDemande = false;		// Document demande du devis actuel quand ouvert la communication
    ctrl.documentDevis = false;			// Document devis du devis actuel quand ouvert la communication
    ctrl.documentDevisSigne = false;	// Document devis du devis signe actuel quand ouvert la communication
    ctrl.documentAttestationTvaSimple = false;	// Document attestation tva simplifiée quand ouvert par la communication
    ctrl.piecesJointesContenu = [];
    ctrl.documentsTopicPartage = {};


    // Tableau permettant d'exclure de la liste des pièces jointes de la vue les pièces jointes tout juste upload
    // Ne peux pas rajouter un attribut au document car sinon il resterais lors de la sauvegarde
    //ctrl.piecesJointesExclus = [];

    // Tableau pour lister les pièces jointe venant de la GED Perso
    // C'est à dire ceux en glissé déposé depuis le widget des documents
    //ctrl.documentsGed = [];

    var nbCaracteresPrefixMsg = (UtilsService.getCurrentUser().getDieze().length + COLLAB_CONF.NB_CARACTERES_AFTER_SOCIETE),
      nbCaracteresMaxSms = (COLLAB_CONF.OLD_NB_MAX_CARACTERES_SMS - nbCaracteresPrefixMsg);

    ctrl.nbResteCaracteresForSMS = nbCaracteresMaxSms;
    ctrl.nbCaracteresForSMS = nbCaracteresMaxSms;

    ctrl.ckeditorOnReady = ckeditorOnReady;					// Fonction executé lorsque le WYSIWYG est ready


    ctrl.onAddDestinataire = onAddDestinataire;				// Callback lors de l'ajout d'un destinataire
    ctrl.onDeleteDestinataire = onDeleteDestinataire;		// Callback lors de la suppression d'un destinataire
    ctrl.onSubmitContenu = onSubmitContenu;					// Lors de la soumission d'un contenu
    ctrl.saveReponseContenu = saveReponseContenu;			// Sauvegarde d'un contenu dans le local storage
    ctrl.cleanReponseContenu = cleanReponseContenu;			// Reset du contenu
    ctrl.getObjContenu = getObjContenu;						// Recupère l'objet contenu pour la sauvegarde
    ctrl.onUpdateTag = onUpdateTag;							// Update les tags
    ctrl.isOverMaxSize = isOverMaxSize;						// Permet de savoir si la taille total des pièces jointe ne dépasse pas limite
    ctrl.calcSommePiecesJointes = calcSommePiecesJointes;	// Calcul la somme total des pièces jointes
    ctrl.convertSize = convertSize;							// Converti le poid en string compréhensible
    ctrl.sommePiecesJointes = false;
    ctrl.maxSommePiecesJointes = COLLAB_CONF.SIZE_LIMIT_ATTACHMENTS; 					// Poids limit 4 Mo d'envoi par email
    ctrl.onCheckDocumentPartage = onCheckDocumentPartage;
    ctrl.countPiecesJointesInCategorie = DocumentsTopicService.getNbDocumentInCategorie;

    ctrl.currentTemplateMessage = null;
    ctrl.onChangeTemplateMsg = onChangeTemplateMsg;
    ctrl.templatesMessage = UtilsService.getModulesTemplatesObjet('template_message');

    ctrl.currentTemplateSms = null;
    ctrl.messageSms = '';
    ctrl.onChangeTemplateSms = onChangeTemplateSms;
    ctrl.templatesSms = UtilsService.getModulesTemplatesObjet('template_sms');

    ctrl.onEmitSelectSignatureContenu = onEmitSelectSignatureContenu;
    ctrl.onEditorSmsReady = onEditorSmsReady;

    ctrl.txtSendOneMailWithAllDest = COLLAB_VALUES.TEXTE_SEND_ONE_MAIL_FOR_ALL_DEST;

    ctrl.$onInit = function () {

      // Liste des document du topic
      ctrl.listeDocuments = angular.copy(DocumentsTopicService.getListDocument());

      ctrl.paramSendOneMailForAllDest = UtilsService.getParametrage('sendOneMailForAllDest');
      if(!UtilsService.notMobile()){
        ctrl.contenuFormulaireCtrl.instanceCKEDITOR = {
          data: '',
          instanceReady: true,
          getData: function(){
            return this.data;
          },
          setData: function(val){
            this.data = val;
          }
        };
      }

      var defaultDestinataires = [];
      if (angular.isObject(ctrl.contenuFormulaireCtrl.defaultParams)) defaultDestinataires = ctrl.contenuFormulaireCtrl.defaultParams.destinataires;

      // Recupère un éventuel contenu sauvegardé
      var objContenuSave = getContenuSave();

      //var idContenu = 0;

      var newContenu = {
        repondreOpen: false,
        repondreAllOpen: false,
        destinataires: [],
        reponseDestinataires: []
      };

      //console.log(ctrl);


      // Si le parent contenu est défini est différent de false
      if (angular.isDefined(ctrl.contenuFormulaireCtrl.parentContenu) && ctrl.contenuFormulaireCtrl.parentContenu !== false && !defaultDestinataires.length) {

        newContenu.repondreOpen = ctrl.contenuFormulaireCtrl.parentContenu.repondreOpen;
        newContenu.repondreAllOpen = ctrl.contenuFormulaireCtrl.parentContenu.repondreAllOpen;
        newContenu.destinataires = ctrl.contenuFormulaireCtrl.parentContenu.destinataires;
        newContenu.reponseDestinataires = ctrl.contenuFormulaireCtrl.parentContenu.reponseDestinataires;
        // Si edition d'un message, remplace tout le contenu
        if (ctrl.contenuFormulaireCtrl.parentContenu.editer) {
          newContenu = ctrl.contenuFormulaireCtrl.parentContenu;
        }

        // Si switch entre bouton "Répondre" et "Répondre à tous"
        $scope.$watch('contenumessageformulairectrl.contenuFormulaireCtrl.parentContenu.reponseDestinataires', function (newVal) {
          refreshDestinataires(newVal);
        }, true);

        // Si "parentContenu" est indéfini ou à false alors c'est le 1er contenu d'un événement ou un nouveau topic
      }
      /*else{

                  // Si "destinataires" est renseigné, initialise "reponseDestinataires" avec la liste des destinataire passé (dans le cas du 1er message, peut etre la liste des destinataire d'un événement)
                  if(angular.isObject(ctrl.contenuFormulaireCtrl.defaultParams) && angular.isArray(ctrl.contenuFormulaireCtrl.defaultParams.destinataires)){
                      newContenu.reponseDestinataires = ctrl.contenuFormulaireCtrl.defaultParams.destinataires;
                      newContenu.destinataires = ctrl.contenuFormulaireCtrl.defaultParams.destinataires;
                  }
              }*/


      ctrl.currentItemContenu = new ContenuMessage(newContenu);

      // Si il y a des params par défaut
      if (_.isObject(ctrl.contenuFormulaireCtrl.defaultParams)) {
        // Si "destinataires" est renseigné, initialise "reponseDestinataires" avec la liste des destinataire passé (dans le cas du 1er message, peut etre la liste des destinataire d'un événement)
        if (_.isArray(ctrl.contenuFormulaireCtrl.defaultParams.destinataires)) {
          $scope.$watch('contenumessageformulairectrl.contenuFormulaireCtrl.defaultParams.destinataires', function () {
            // Met les destinataires du bloc communiqué dans la liste des destinataire
            ctrl.currentItemContenu.listeDestinataires =  angular.copy(ctrl.contenuFormulaireCtrl.defaultParams.destinataires);
            checkIfCanShowSendOneMailWithAllDest(ctrl.currentItemContenu.listeDestinataires);

            // Si il y a des destinataires cachés
            if (_.isArray(ctrl.contenuFormulaireCtrl.defaultParams.destinatairesHide)) {
              // Parcour ces destinataires
              for (var d = 0; d < ctrl.contenuFormulaireCtrl.defaultParams.destinatairesHide.length; d++) {
                // Ajoute à la liste des destinataires
                ctrl.currentItemContenu.listeDestinataires.push(angular.copy(ctrl.contenuFormulaireCtrl.defaultParams.destinatairesHide[d]));
              }
            }
          }, true);

          $scope.$watch('contenumessageformulairectrl.contenuFormulaireCtrl.defaultParams.destinatairesExclus', function (destExclu) {
            ctrl.currentItemContenu.destinatairesExclus = angular.copy(destExclu);
          }, true);
        }
      }

      //console.log('contenu message formulaire',ctrl.currentItemContenu);
      ctrl.currentItemContenu.sms = false;

      if ($rootScope.isCapfun && !_.isNil(ctrl.contenuFormulaireCtrl.parentContenu) && ctrl.contenuFormulaireCtrl.parentContenu !== false){
        ctrl.isEventIntervention = TopicManagerService.currentEventIsIntervention();
        if(ctrl.isEventIntervention) ctrl.currentItemContenu.sms = true;
        /*
        if(ctrl.contenuFormulaireCtrl.parentContenu.sms && TopicManagerService.currentEventIsIntervention()) {
          ctrl.currentItemContenu.sms = true;
        }*/
      }

      // Si il y a des destinataires à ajouter en arrivant sur la page

      if ($rootScope.current.tmpNewEvenement.destinataires.length) {
        for (var g = 0; g < $rootScope.current.tmpNewEvenement.destinataires.length; g++) {
          ctrl.nouveauxDestinataires.push($rootScope.current.tmpNewEvenement.destinataires[g]);
        }
        $rootScope.current.tmpNewEvenement.destinataires = [];
        refreshDestinataires();
      }
      //ctrl.contenuFormulaireCtrl.addDefaultDestinataires(ctrl.nouveauxDestinataires);

      // Si le parent contenu est défini est différent de false
      if (angular.isDefined(ctrl.contenuFormulaireCtrl.parentContenu) && ctrl.contenuFormulaireCtrl.parentContenu !== false) {
        ctrl.currentItemContenu.sendByMail = UtilsService.getParametrage('onClickReplyToAllSendByMailEnabled');

        // Si le contenu parent et un evenement, c'est à dire que c'est le 1er message d'un événement
        if (ctrl.contenuFormulaireCtrl.parentContenu.isEvenement) refreshDestinataires(ctrl.currentItemContenu.destinataires);
      }

      // Si un contenu sauvegardé
      if (objContenuSave) {
        //console.log(objContenuSave);
        // Si il y a un titre
        if (objContenuSave.formTopicTitre) {
          ctrl.formTopicTitre = objContenuSave.formTopicTitre;
        }

        ctrl.message = objContenuSave.message;
        ctrl.messageSms = (!_.isNil(objContenuSave.messageSms)) ? objContenuSave.messageSms : '';

        //ctrl.allTags = objContenuSave.tags;
        ctrl.currentItemContenu.tags = objContenuSave.tags;
        if (_.isArray(objContenuSave.listeDocument)) {
          //ctrl.piecesJointesContenu = objContenuSave.listeDocument;
          if (objContenuSave.listeDocument.length) {
            for (var i = 0; i < objContenuSave.listeDocument.length; i++) {
              addDocumentInContenu(objContenuSave.listeDocument[i]);
            }
            ctrl.showPiecesJointes = true;
          }
          //calcSommePiecesJointes();
          //ctrl.showPiecesJointes = true;
        } else {
          ctrl.piecesJointesContenu = [];
        }
      }


      /************** Pieces jointes *******************/

      // Lorsqu'un fichier est bien upload
      ctrl.contenuFormulaireCtrl.uploader.onSuccessItem = function (fileItem, response) {
        if (response.nb !== '0') {
          angular.forEach(response.Documents, function (document) {
            // Ajoute le document dans le document manager
            var instanceDocument = DocumentService.setDocument(document);
            fileItem._file.document = instanceDocument;

            instanceDocument.new = true;
            addDocumentInContenu(instanceDocument);

            /*
                      // Ajoute le document dans le tableau des pièces jointes à joindre au contenu
                      ctrl.piecesJointesContenu.push(instanceDocument);
                      calcSommePiecesJointes();*/

            // Ajoute le fichier au tableau des pièces jointe à exclure
            //ctrl.piecesJointesExclus.push(instanceDocument);
          });
        }
      };

      // Après upload de tout les fichiers
      ctrl.contenuFormulaireCtrl.uploader.onCompleteAll = function () {
        if (ctrl.contenuFormulaireCtrl.notifWaitUpload.show) {
          NotificationsToastServices.clear(ctrl.contenuFormulaireCtrl.notifWaitUpload.obj.id);
          ctrl.contenuFormulaireCtrl.initNotifWaitUpload();
        }
      };

      if (_.isObject(ctrl.contenuFormulaireCtrl.defaultParams)) {

        $scope.$watch('contenumessageformulairectrl.contenuFormulaireCtrl.defaultParams.sms', function (val) {
          ctrl.currentItemContenu.sms = val;
        });

        //ctrl.currentItemContenu.sms = ctrl.contenuFormulaireCtrl.defaultParams.sms;
        if (ctrl.contenuFormulaireCtrl.defaultParams.sms && (ctrl.messageSms === '' || _.isNil(ctrl.messageSms))) {
          ctrl.messageSms = ctrl.contenuFormulaireCtrl.defaultParams.message;
        }

        if (ctrl.contenuFormulaireCtrl.defaultParams.communication) {
          ctrl.openFromCommunication = true;
          ctrl.currentItemContenu.sendByMail = true;
        }

        if (ctrl.contenuFormulaireCtrl.defaultParams.devis) {
          ctrl.documentDemande = ctrl.contenuFormulaireCtrl.defaultParams.devis.getDocumentDemande();
          ctrl.documentDevis = ctrl.contenuFormulaireCtrl.defaultParams.devis.getDocumentDevis();
          ctrl.documentDevisSigne = ctrl.contenuFormulaireCtrl.defaultParams.devis.getDocumentDevisSigne();
          ctrl.documentAttestationTvaSimple = ctrl.contenuFormulaireCtrl.defaultParams.devis.getDocumentAttestationTvaSimple();
          ctrl.currentItemContenu.setIdContenuParent(ctrl.contenuFormulaireCtrl.defaultParams.devis.getIdContenu());
        }

        if (ctrl.contenuFormulaireCtrl.defaultParams.prefixSms) {
          nbCaracteresPrefixMsg = (ctrl.contenuFormulaireCtrl.defaultParams.prefixSms.length + COLLAB_CONF.NB_CARACTERES_AFTER_SOCIETE),
            nbCaracteresMaxSms = (COLLAB_CONF.OLD_NB_MAX_CARACTERES_SMS - nbCaracteresPrefixMsg);

          ctrl.nbResteCaracteresForSMS = nbCaracteresMaxSms;
          ctrl.nbCaracteresForSMS = nbCaracteresMaxSms;
        }
      }

      //console.log(ctrl.contenuFormulaireCtrl.parentContenu);
    };

    function checkIfCanShowSendOneMailWithAllDest(listeDest){
      if(!COLLAB_CONF.ENABLE_SEND_MAIL_CC) {
        ctrl.showSendOneMailWithAllDest = false;
        ctrl.currentItemContenu.sendOneMailWithAllDest = null;
      }
      else {
        ctrl.showSendOneMailWithAllDest = UtilsService.checkIfCanShowSendOneMailWithAllDest(listeDest);
        if(!ctrl.showSendOneMailWithAllDest) ctrl.currentItemContenu.sendOneMailWithAllDest = false;
        // Si l'envoie en copie n'a pas été changé par l'user alors on met la valeur du param par défaut
        if(!ctrl.sendOneMailWithAllDestChanged) ctrl.currentItemContenu.sendOneMailWithAllDest = angular.copy(ctrl.paramSendOneMailForAllDest);
      }
    }

    /**
     * Permet de cacher le header lorsqu'on focus le textarea
     */
    function onFocusTextareaOnMobile(){
      $rootScope.hideHeaderLayout = true;
    }

    /**
     * Permet de réafficher le header lorsqu'on quitte le textarea
     */
    function onBlurTextareaOnMobile(){
      $rootScope.hideHeaderLayout = false;
    }

    var prevTemplateMsg = false, prevObjetMsg;

    /**
     * Lors de la selection du model dans le champ select des template message
     */
    function onChangeTemplateMsg(index) {

      if (!prevTemplateMsg) {
        prevObjetMsg = angular.copy(ctrl.contenuFormulaireCtrl.instanceCKEDITOR.getData());
      } else {
        if (prevTemplateMsg.objet == '') {
          prevObjetMsg = angular.copy(ctrl.contenuFormulaireCtrl.instanceCKEDITOR.getData());
        }
      }

      if (angular.isNumber(index)) {
        if (ctrl.templatesMessage.length <= (index + 1)) {
          ctrl.currentTemplateMessage = ctrl.templatesMessage[index]; // Preselectione le 1er modèle
        }
      }
      else if (angular.isObject(index)) {
        if (index.hasOwnProperty('idModuleValeurParams')) {
          // Si il y a des model event
          if (ctrl.templatesMessage.length) {
            // Pour chaque model
            for (var i = 0; i < ctrl.templatesMessage.length; i++) {
              // Si le model de la boucle en cours est égale à l'id du model demandé
              if (ctrl.templatesMessage[i].idModuleValeurParams === index.idModuleValeurParams) {
                ctrl.currentTemplateMessage = ctrl.templatesMessage[i];
                break;
              }
            }
          }
        }
      }

      // Si un model est selectionné
      if (angular.isObject(ctrl.currentTemplateMessage)) {
        // Si il y a des model event
        if (ctrl.templatesMessage.length) {
          // Pour chaque model
          for (var m = 0; m < ctrl.templatesMessage.length; m++) {
            var currentModel = ctrl.templatesMessage[m];


            // Si le model de la boucle en cours est égale au model choisi
            if (ctrl.currentTemplateMessage.idModuleValeurParams === currentModel.idModuleValeurParams) {

              // Si il y a un objet
              if (currentModel.objet !== '') {
                ctrl.tmpObjet = angular.copy(currentModel.objet);
                //console.log(currentModel.objet);

                if (ctrl.contenuFormulaireCtrl.instanceCKEDITOR.instanceReady) {
                  ctrl.contenuFormulaireCtrl.instanceCKEDITOR.setData(currentModel.objet);
                }
              }
              else {
                // Si il y un model choisi avant de changer
                if (angular.isObject(prevTemplateMsg)) {
                  // Si ce model avait un objet prédéfini
                  if (prevTemplateMsg.objet !== '') {
                    if (prevObjetMsg) {
                      // Supprime l'objet dans le WYSIWYG
                      ctrl.contenuFormulaireCtrl.instanceCKEDITOR.setData(prevObjetMsg, {
                        callback: function () {
                          ctrl.tmpObjet = '';
                        }
                      });
                    }
                    else {
                      // Supprime l'objet dans le WYSIWYG
                      ctrl.contenuFormulaireCtrl.instanceCKEDITOR.setData('', {
                        callback: function () {
                          ctrl.tmpObjet = '';
                        }
                      });
                    }

                  }
                }
              }
              break;
            }
          }
        }
      }
      else {

        // Si il y a un contenu save
        if (ctrl.objContenuSave) {

          // Set l'objet client dans une variable temporaire du controller
          if (ctrl.objContenuSave.objet !== '' && angular.isDefined(ctrl.objContenuSave.objet)) {
            ctrl.tmpObjet = ctrl.objContenuSave.objet;
            ctrl.contenuFormulaireCtrl.instanceCKEDITOR.setData(angular.copy(ctrl.objContenuSave.objet));
          }
        }
        else {

          // Siil y avait un model choisi avant de changer
          if (angular.isObject(prevTemplateMsg)) {

            if (prevTemplateMsg.objet !== '') {
              if (prevObjetMsg) {
                // Supprime l'objet dans le WYSIWYG
                ctrl.contenuFormulaireCtrl.instanceCKEDITOR.setData(prevObjetMsg, {
                  callback: function () {
                    ctrl.tmpObjet = '';
                  }
                });
              }
              else {
                // Supprime l'objet dans le WYSIWYG
                ctrl.contenuFormulaireCtrl.instanceCKEDITOR.setData('', {
                  callback: function () {
                    ctrl.tmpObjet = '';
                  }
                });
              }
            }
          }
        }

      }
      prevTemplateMsg = angular.copy(ctrl.currentTemplateMessage);
    }

    var prevTemplateSms = false, prevObjetSms;

    /**
     * Lors de la selection du model dans le champ select des template SMS
     */
    function onChangeTemplateSms(index) {


      if (!prevTemplateSms) {
        prevObjetSms = angular.copy(ctrl.messageSms);
      } else {
        if (prevTemplateSms.objet == '') {
          prevObjetSms = angular.copy(ctrl.messageSms);
        }
      }


      if (angular.isNumber(index)) {
        if (ctrl.templatesSms.length <= (index + 1)) {
          ctrl.currentTemplateMessage = ctrl.templatesSms[index]; // Preselectione le 1er modèle
        }
      }
      else if (angular.isObject(index)) {
        if (index.hasOwnProperty('idModuleValeurParams')) {
          // Si il y a des model event
          if (ctrl.templatesSms.length) {
            // Pour chaque model
            for (var i = 0; i < ctrl.templatesSms.length; i++) {
              // Si le model de la boucle en cours est égale à l'id du model demandé
              if (ctrl.templatesSms[i].idModuleValeurParams === index.idModuleValeurParams) {
                ctrl.currentTemplateMessage = ctrl.templatesSms[i];
                break;
              }
            }
          }
        }
      }

      // Si un model est selectionné
      if (angular.isObject(ctrl.currentTemplateSms)) {
        // Si il y a des model event
        if (ctrl.templatesSms.length) {
          // Pour chaque model
          for (var m = 0; m < ctrl.templatesSms.length; m++) {
            var currentModel = ctrl.templatesSms[m];


            // Si le model de la boucle en cours est égale au model choisi
            if (ctrl.currentTemplateSms.idModuleValeurParams === currentModel.idModuleValeurParams) {

              // Si il y a un objet
              if (currentModel.objet !== '') {
                ctrl.messageSms = angular.copy(currentModel.objet);
              }
              else {
                // Si il y un model choisi avant de changer
                if (angular.isObject(prevTemplateSms)) {
                  // Si ce model avait un objet prédéfini
                  if (prevTemplateSms.objet !== '') {
                    if (prevObjetSms) {
                      ctrl.messageSms = prevObjetSms;
                    }
                    else {
                      ctrl.messageSms = '';
                    }
                  }
                }
              }
              break;
            }
          }
        }
      }
      else {

        // Si il y a un contenu save
        if (ctrl.objContenuSave) {

          // Set l'objet client dans une variable temporaire du controller
          if (ctrl.objContenuSave.objet !== '' && angular.isDefined(ctrl.objContenuSave.objet)) {
            ctrl.messageSms = ctrl.objContenuSave.objet;
          }
        }
        else {

          // Siil y avait un model choisi avant de changer
          if (angular.isObject(prevTemplateSms)) {

            if (prevTemplateSms.objet !== '') {
              if (prevObjetSms) {
                ctrl.messageSms = prevObjetSms;
              }
              else {
                ctrl.messageSms = '';
              }
            }
          }
        }

      }
      prevTemplateSms = angular.copy(ctrl.currentTemplateSms);
    }

    /**
     * Parcour les documents du topic pour cocher ou pas
     * @param currentDocument
     */
    function parcourDocumentsTopic(currentDocument) {

      if (!ctrl.contenuFormulaireCtrl.isNewTopic) {
        var listeDocuments = TopicManagerService.listDocumentTopicByCat;
        if (listeDocuments && listeDocuments.nb) {
          for (var c = 0; c < listeDocuments.categorie.length; c++) {
            var categorie = listeDocuments.categorie[c];
            if (categorie.documents.length) {
              for (var dc = 0; dc < categorie.documents.length; dc++) {
                if (categorie.documents[dc].guid === currentDocument.guid) {
                  categorie.documents[dc].checked = currentDocument.checked;
                }
              }
            }
            if (categorie.hasOwnProperty('groupements')) {
              if (categorie.groupements.length) {
                for (var gr = 0; gr < categorie.groupements.length; gr++) {
                  var currentGroupement = categorie.groupements[gr];
                  for (var gc = 0; gc < currentGroupement.documents.length; gc++) {
                    if (currentGroupement.documents[gc].guid === currentDocument.guid) {
                      currentGroupement.documents[gc].checked = currentDocument.checked;
                    }
                  }
                }
              }
            }
          }
        }
      }

    }

    /************** CKEDITOR *******************/
    function ckeditorOnReady(item) {

      var idContenu = 0;
      if (angular.isObject(item)) {
        idContenu = item.idContenu;
      }
      ctrl.contenuFormulaireCtrl.ckeditorOnReady(idContenu);

      var message = ctrl.message;
      if (angular.isObject(item)) {
        // Si contenu en cours de modificaiton
        if (item.editer) {
          // Si vm.message est undefined, recupère le message du contenu
          if (angular.isUndefined(ctrl.message)) {
            message = item.message;
          }
        }
      }

      // Si il y a des params par defaut et qu'il y a un message par defaut
      if (angular.isObject(ctrl.contenuFormulaireCtrl.defaultParams) && ctrl.contenuFormulaireCtrl.defaultParams.message) {
        message = ctrl.contenuFormulaireCtrl.defaultParams.message;
      }

      if (message) {
        ctrl.contenuFormulaireCtrl.instanceCKEDITOR.setData(message);
        ctrl.contenuFormulaireCtrl.ckeditorOptions.autoGrow_onStartup = true;
        ctrl.contenuFormulaireCtrl.ckeditorOptions.enterMode = CKEDITOR.ENTER_BR;
      }
      // Si il faut focus le champ
      if (UtilsService.notMobile() && angular.isObject(ctrl.contenuFormulaireCtrl.defaultParams) && ctrl.contenuFormulaireCtrl.defaultParams.focus) {
        UtilsService.focusEndEditor(ctrl.contenuFormulaireCtrl.instanceCKEDITOR);
      }

    }

    /************** Fin CKEDITOR *******************/
    /************** Pieces jointes à migrer dans un component *******************/

    /**
     * Supprime une pièce jointe de la listre est de la BDD
     * @param {Object} fileItem - Item du fichier
     * @returns {void}
     */
    ctrl.removePiecesJointes = function (fileItem) {

      // Si item venant de la liste d'upload
      if (angular.isDefined(fileItem._file)) {

        // Si object document existe
        if (angular.isObject(fileItem._file.document)) {

          var docObjet = fileItem._file.document;
          DocumentService.deleteDocumentPerso(docObjet).then(function () {

            // Supprime la pièce jointe de la liste
            ctrl.contenuFormulaireCtrl.uploader.removeFromQueue(fileItem);

            // Supprime le document des pièces jointes
            removeDocumentFromContenu(docObjet);

            //$rootScope.$broadcast('refreshListeDocuments');

          }, function (msg) {
            console.log('Erreur formulaireContenu.removeFromQueue()');
            console.log(msg);
          });

          // Sinon supprime seulement le fichier de la liste d'upload
        } else {
          ctrl.contenuFormulaireCtrl.uploader.removeFromQueue(fileItem);
        }

        // Sinon si item venant de la GED perso
      } else if (fileItem.dateUpload) {
        removeDocumentFromContenu(fileItem);
      }
    };

    // Lors du drop d'un fichier GED

    ctrl.onDropDocumentFromGedPerso = function () {
      //$scope.$emit('dropDocumentFromGedPerso',$scope.itemPieceJointeDrop);

      var document = ctrl.itemPieceJointeDrop;

      addDocumentInContenu(document);
    };

    function addDocumentInContenu(document) {
      if (angular.isObject(document)) {

        // Recupère un tableau avec le fichier si il existe
        //var existFile = $filter('where')($scope.documentsGed,{guid:document.getGuid()});
        var existFile = $filter('where')(ctrl.piecesJointesContenu, {guid: document.guid});

        // Si le fichier n'existe pas déjà on l'ajoute
        if (!existFile.length) {
          ctrl.piecesJointesContenu.push(document);
          document.checked = true;
          calcSommePiecesJointes();
          parcourDocumentsTopic(document);

          //ctrl.piecesJointesExclus.push(document);

          //$scope.documentsGed.push(document);
        }
      }
    }

    function removeDocumentFromContenu(document) {
      var guidFile = document.guid;
      // Supprime le document des pièces jointes
      ctrl.piecesJointesContenu = UtilsService.removeWith(ctrl.piecesJointesContenu, {guid: guidFile});
      if (document.checked) {
        document.checked = false;
      }
      parcourDocumentsTopic(document);
      calcSommePiecesJointes();
    }

    function isOverMaxSize() {
      if (ctrl.sommePiecesJointes >= ctrl.maxSommePiecesJointes) {
        return true;
      }
      return false;
    }

    /**
     * Permet de calculer le poid total des pièces jointes
     */
    function calcSommePiecesJointes() {

      var sizeTotal = 0;
      if (ctrl.piecesJointesContenu.length) {
        for (var i = 0; i < ctrl.piecesJointesContenu.length; i++) {
          sizeTotal = sizeTotal + ctrl.piecesJointesContenu[i].size;
        }
      }
      if (sizeTotal === 0) {
        sizeTotal = false;
      }
      ctrl.sommePiecesJointes = sizeTotal;
    }

    function convertSize(size) {
      return UtilsService.bytes(size);
    }

    function onCheckDocumentPartage(document) {

      if (document.checked) {
        addDocumentInContenu(document);
      }
      else {
        removeDocumentFromContenu(document);
      }
    }

    /************** Fin Pieces jointes à migrer dans un component *******************/

    /**
     * Fonction d'édition du dernier contenu
     * Sauvegarde le message modifié
     */
    ctrl.updateContenuMessage = function () {

      var message = ctrl.contenuFormulaireCtrl.instanceCKEDITOR.getData();

      // Si le message obligatoire n'est pas rempli
      if (angular.isUndefined(message) || message === '') {
        sweet.show('Oops...', 'Veuillez renseigner un message', 'error');
        return;
      }
      var instanceProxy = new ProxyContenuMessage(ctrl.contenuFormulaireCtrl.parentContenu);
      instanceProxy.setMessage(message);
      //console.log(instanceProxy);
      ctrl.contenuFormulaireCtrl.sendContenu(instanceProxy).then(function (retourObj) {

        if (retourObj.action === 'updateContenu') {

          //var idTopic = newMessage.getIdTopic();

          if ($state.includes('topics.detail')) {
            $state.go('topics.detail', {topicId: ctrl.contenuFormulaireCtrl.idTopic}, {reload: true});
          } else {
            $state.reload();
          }
        }
      }, function (error) {
        // Si erreur 403 alors le dernier message ne peux plus être modifié
        if (error.status === 403) {
          sweet.show({
            title: 'Oops...',
            text: 'Un autre message à été posté entre temps...',
            type: 'error',
            showCancelButton: false,
            confirmButtonText: 'Ok...',
            closeOnConfirm: true
          }, function () {
            // Reload la page pour afficher les nouveau contenu
            $state.go('topics.detail', {topicId: ctrl.contenuFormulaireCtrl.idTopic}, {reload: true});
          });
        }
      });

    };

    /**
     * Fonction permettant de verifier si le destinatire est à omit de la liste ou pas
     * @param elm
     * @returns {boolean}
     */
    function omitDestinataire(elm) {

      // Recupère un tableau de destinataire
      var destIsDelete = UtilsService.where(ctrl.deletedDestinataires, {libelle: elm.libelle, dieze: elm.dieze});

      // Si le destinataire est dans le tableau alors on renvoi true car il faut l'omit
      if (destIsDelete.length) {
        return true;

        // Sinon false on peu le laisser
      } else {
        return false;
      }
    }

    /**
     * Permet de recupérer un éventuel contenu sauvegardé
     */
    function getContenuSave() {

      var objContenuSave;

      if (angular.isDefined(ctrl.contenuFormulaireCtrl.parentContenu)) {

        // Si premier message d'un topic avec evenement
        if (ctrl.contenuFormulaireCtrl.parentContenu.isEvenement) {
          //console.log('1');
          //console.log($scope.itemContenuEvenement);
          objContenuSave = AutoSaveService.get(ctrl.contenuFormulaireCtrl.parentContenu.idContenu);
          if (angular.isObject(objContenuSave)) {
            if (objContenuSave.hasOwnProperty('reponse')) {
              // Test pour la nouvelle version du JSON sauvegardé
              if (objContenuSave.reponse.hasOwnProperty('obj')) {
                objContenuSave = objContenuSave.reponse.obj;
              } else {
                objContenuSave = objContenuSave.reponse;
              }
            }

          }

          // Si réponse d'un message
        } else {
          //console.log('2');
          objContenuSave = AutoSaveService.get(ctrl.contenuFormulaireCtrl.parentContenu.idContenu);

          if (angular.isObject(objContenuSave)) {
            //console.log('2.1');
            if (ctrl.contenuFormulaireCtrl.parentContenu.nouveau) {
              //console.log('2.2');
              if (objContenuSave.hasOwnProperty('reponse')) {
                //console.log('2.2.1');
                // Test pour la nouvelle version du JSON sauvegardé
                if (objContenuSave.reponse.hasOwnProperty('obj')) {
                  //console.log('2.2.1.1');
                  objContenuSave = objContenuSave.reponse.obj;
                } else {
                  //console.log('2.2.1.2');
                  objContenuSave = objContenuSave.reponse;
                }
              }


            } else {
              //console.log('2.3');
              if (objContenuSave.hasOwnProperty('modification')) {
                //console.log('2.3.1');
                // Test pour la nouvelle version du JSON sauvegardé
                if (objContenuSave.modification.hasOwnProperty('obj')) {
                  //console.log('2.3.1.1');
                  objContenuSave = objContenuSave.modification.obj;
                } else {
                  //console.log('2.3.1.2');
                  objContenuSave = objContenuSave.modification;
                }
              } else {
                objContenuSave = false;
              }


            }
          }
        }
        // Si nouveau message
      } else {
        //console.log('3');
        objContenuSave = AutoSaveService.get('newMessage');

        if (angular.isObject(objContenuSave)) {
          if (objContenuSave.hasOwnProperty('nouveau')) {
            // Test pour la nouvelle version du JSON sauvegardé
            if (objContenuSave.nouveau.hasOwnProperty('obj')) {
              objContenuSave = objContenuSave.nouveau.obj;
            }
            // Retro compatibilité
          } else if (objContenuSave.hasOwnProperty('reponse')) {
            // Test pour la nouvelle version du JSON sauvegardé
            if (objContenuSave.reponse.hasOwnProperty('obj')) {
              objContenuSave = objContenuSave.reponse.obj;
            } else {
              objContenuSave = objContenuSave.reponse;
            }
          }
        }
      }
      return objContenuSave;
    }

    function refreshDestinataires(newVal) {

      var objContenuSave = getContenuSave();

      // Si il y a une sauvegarde pour ce contenu
      if (objContenuSave) {

        if (objContenuSave.nouveauxDestinataires) {
          // Remet les destinataire dans le tableau des nouveaux destinataires du contenu
          ctrl.nouveauxDestinataires = objContenuSave.nouveauxDestinataires;
        }

        if (objContenuSave.deletedDestinataires) {
          // Remet les destinataire supprimé dans le tableau des destinataires à supprimé du contenu
          ctrl.deletedDestinataires = objContenuSave.deletedDestinataires;
        }
      }


      if (angular.isUndefined(newVal) || !angular.isArray(newVal)) {
        newVal = [];
      }

      // Variable qui permet de remettre les destinataire de base lors du reset
      defaultReponseDestinataires = angular.copy(newVal);

      // Si il y a des destinataires à supprimer de la liste
      if (ctrl.deletedDestinataires.length) {
        newVal = $filter('omit')(newVal, omitDestinataire);
      }

      // Si il y a des destinataires à ajouter à la liste
      if (ctrl.nouveauxDestinataires.length) {
        // Concatène les destinataires de la réponse avec les nouveaux dans la sauvegarde
        newVal = newVal.concat(ctrl.nouveauxDestinataires);
      }
      // Initialise la liste des contacts
      //vm.contacts = AnnuaireService.updateListeContact(newVal);

      // Set la liste des destinataires
      ctrl.currentItemContenu.setDestinataires(newVal);
      checkIfCanShowSendOneMailWithAllDest(ctrl.currentItemContenu.getDestinataires());
    }

    /**
     * Lors de l'ajout d'un destinataire
     * @param dest
     */
    function onAddDestinataire(dest) {
      console.log('onAddDestinataire', dest);
      angular.forEach(dest, function (groupe) {
        ctrl.nouveauxDestinataires.push(groupe);
      });
      ctrl.contenuFormulaireCtrl.focusTitle(dest);
    }

    /**
     * Lors de la supression d'un destinataire
     * @param dest
     */
    function onDeleteDestinataire(dest) {

      // Ajoute le destinataire au tableau des destinataire à supprimer de la liste
      ctrl.deletedDestinataires.push(dest);

      // Recréé un tableau nouveauDestinataires sans le destinataire supprimé
      ctrl.nouveauxDestinataires = UtilsService.removeWith(ctrl.nouveauxDestinataires, {
        libelle: dest.libelle,
        dieze: dest.dieze
      });
    }

    /**
     * Lors du submit du formulaire du contenu
     */
    function onSubmitContenu(cacher) {

      if (ctrl.currentItemContenu.sms) {
        ctrl.messageSms = ctrl.objEditorSms.getData();
        /*
        if (ctrl.messageSms !== '' && !_.isNil(ctrl.messageSms)) {
          ctrl.messageSms = ctrl.objEditorSms.getData();

          // if (angular.isObject(ctrl.contenuFormulaireCtrl.defaultParams) && ctrl.contenuFormulaireCtrl.defaultParams.prefixSms) {
          //   msg += ctrl.contenuFormulaireCtrl.defaultParams.prefixSms;
          // }
          // else {
          //   if (UserService.getGroupe().getDieze().length <= 18)  msg += UserService.getGroupe().getDieze();
          //   else msg += UtilsService.truncate(UserService.getGroupe().getDieze(), 15, '...');
          // }
          // msg += ': ' + ctrl.messageSms;
        }*/
      }

      var canContinue = ctrl.contenuFormulaireCtrl.onSubmit({onSubmit: {contenu: ctrl.currentItemContenu}});
      if (canContinue === false) return;

      // Sauvegarde au cas où il y aurai un problème
      ctrl.saveReponseContenu(function () {
        // Submit le contenu
        $timeout(function () {
          submitContenu(cacher);
        });
      });
    }

    function hideLoadingAndNotif(notifWait, callBackIfParentContenu) {
      if (ctrl.contenuFormulaireCtrl.parentContenu) {
        ctrl.contenuFormulaireCtrl.parentContenu.loadingMessage = false;
        if (callBackIfParentContenu) {
          callBackIfParentContenu();
        }
      }//else{
      ctrl.submitProgress = false;
      //}

      if (notifWait) {
        NotificationsToastServices.clear(notifWait.id);
      }
    }

    /**
     * @name submitContenu
     * @desc Lors du submit du contenu
     * @param {Object} formRepondre - Formulaire
     * @returns {void}
     */
    function submitContenu(cacher) {

      if (ctrl.contenuFormulaireCtrl.parentContenu) {
        ctrl.contenuFormulaireCtrl.parentContenu.loadingMessage = COLLAB_CONF.MIDDLE_LOADING;
      }//else{
      ctrl.submitProgress = true;
      //}

      var notifWait = false;
      NotificationsToastServices.wait('Patientez', 'Envoi du message en cours...', function (notif) {
        notifWait = notif;
      });

      try {
        //if (ctrl.contenuFormulaireCtrl.idTopic){
        var listeProxyDest = [];
        var ProxyDestinataire = $injector.get('ProxyDestinataire');
        if(_.isArray(ctrl.currentItemContenu.destinataires) && ctrl.currentItemContenu.destinataires.length) {
          ctrl.currentItemContenu.destinataires.map(function(groupe){
            var proxyDest = new ProxyDestinataire(groupe);

            /*
            // si c'est un groupe et pas une perso ics (copro/loc/prop)
            if(_.isObject(groupe) && !groupe.isPersoIcs()){
              // Si on demande à envoyer par mail et que c'est un user
              proxyDest.setAllowSendEmail(ctrl.currentItemContenu.sendByMail && groupe.isUser());
            }
            // Si groupe n'est pas un objet, donc un email ?
            else proxyDest.setAllowSendEmail(ctrl.currentItemContenu.sendByMail);
            */


            // Si pas groupe MCS,Prop,Loca etc... et pas groupe user
            if(_.isObject(groupe) && groupe.isAutre() && !groupe.isUser() && groupe.isMySociete())
              proxyDest.setAllowSendEmail(false);

            if(_.isObject(groupe) && groupe.getDieze() === "" && UtilsService.isMail(groupe.getLibelle()))
              proxyDest.setEmail(groupe.getLibelle());

            else if(_.isString(groupe) && UtilsService.isMail(groupe))
              proxyDest.setEmail(groupe);

            listeProxyDest = _.unionWith(listeProxyDest, [proxyDest], _.isEqual);
          });
        }

        if(_.isArray(ctrl.currentItemContenu.listeDestinataires) && ctrl.currentItemContenu.listeDestinataires.length) {
          ctrl.currentItemContenu.listeDestinataires.map(function(groupe){
            var proxyDest = new ProxyDestinataire(groupe);
            if(groupe.model === "Groupe") {

              /*
              // si c'est un groupe et pas une perso ics (copro/loc/prop)
              if(_.isObject(groupe) && !groupe.isPersoIcs()){
                // Si on demande à envoyer par mail et que c'est un user
                proxyDest.setAllowSendEmail(ctrl.currentItemContenu.sendByMail && groupe.isUser());
              }
              // Si groupe n'est pas un objet, donc un email ?
              else proxyDest.setAllowSendEmail(ctrl.currentItemContenu.sendByMail);*/


              // Si pas groupe MCS,Prop,Loca etc... et pas groupe user
              if(_.isObject(groupe) && groupe.isAutre() && !groupe.isUser() && groupe.isMySociete())
                proxyDest.setAllowSendEmail(false);

              if((_.isObject(groupe) && groupe.getDieze() === "" && UtilsService.isMail(groupe.getLibelle())))
                proxyDest.setEmail(groupe.getLibelle());

              else if(_.isString(groupe) && UtilsService.isMail(groupe))
                proxyDest.setEmail(groupe);
            }
            listeProxyDest = _.unionWith(listeProxyDest, [proxyDest], _.isEqual);
          });
        }
        ProxyDestinataire = null;
        //}

        //
        if(ctrl.important) ctrl.currentItemContenu.addTagType('Important', 'Important');


        var instanceProxy = new ProxyContenuMessage(ctrl.currentItemContenu);
        instanceProxy.setDestinataires([]);
        //instanceProxy.setListeDestinataires(_.unionWith(instanceProxy.listeDestinataires, listeProxyDest, _.isEqual));
        instanceProxy.setListeDestinataires(listeProxyDest);

        // Si formulaire ouvert depuis le bloc communication
        if (ctrl.openFromCommunication) {

          var erreurDestinataire = false;

          //instanceProxy.setDestinataires([]); // Remise à 0 de la propriétée "destinataires" lorsque c'est ouvert depuis la communication

          // Si la liste des destinataires est différente de 0
          if (instanceProxy.listeDestinataires.length !== 0) {
            // Si il y a des params par défaut
            if (angular.isObject(ctrl.contenuFormulaireCtrl.defaultParams)) {
              if (angular.isArray(ctrl.contenuFormulaireCtrl.defaultParams.destinataires)) {
                // Si il y a des destinataires cachés
                if (angular.isArray(ctrl.contenuFormulaireCtrl.defaultParams.destinatairesHide)) {

                  // Si le nombre de destinataires du contenu est égale au nombre de destinataire caché venant la communication
                  if (instanceProxy.listeDestinataires.length === ctrl.contenuFormulaireCtrl.defaultParams.destinatairesHide.length) {
                    erreurDestinataire = true;
                  }
                }
              }
            }
          }
          else {
            erreurDestinataire = true;
          }

          // Si erreur de destinataire
          if (erreurDestinataire) {
            sweet.show('Oops...', 'Veuillez renseigner au moins un destinataire', 'error');
            hideLoadingAndNotif(notifWait);
            ctrl.contenuFormulaireCtrl.onError({onError: true});
            return;
          }
        }

        var msg = '';
        if (ctrl.currentItemContenu.sms && ctrl.messageSms !== '' && !_.isNil(ctrl.messageSms))
          msg = ctrl.messageSms;
        else
          msg = ctrl.message = ctrl.contenuFormulaireCtrl.instanceCKEDITOR.getData();

        //console.log('ctrl.currentItemContenu',ctrl.currentItemContenu);
        //return;

        if (angular.isUndefined(msg) || msg === '' || _.isNil(msg)) {
          sweet.show('Oops...', 'Veuillez renseigner un message', 'error');
          hideLoadingAndNotif(notifWait);
          ctrl.contenuFormulaireCtrl.onError({onError: true});
          return;
        }

        instanceProxy.setMessage(msg);

        if (ctrl.contenuFormulaireCtrl.idTopic) instanceProxy.setIdTopic(ctrl.contenuFormulaireCtrl.idTopic);


        if (!ctrl.currentItemContenu.sms) {
          //---------------  Documents ------------------
          // Si envoie le message par email
          if (ctrl.currentItemContenu.sendByMail) {
            // Si trop de documents
            if (ctrl.isOverMaxSize()) {
              sweet.show('Oops...', 'Le poid maximum pour les pièces jointes envoyés par email est de ' + ctrl.convertSize(ctrl.maxSommePiecesJointes) + ' !', 'error');
              hideLoadingAndNotif(notifWait);
              ctrl.contenuFormulaireCtrl.onError({onError: true});
              return;
            }
          }
          else ctrl.currentItemContenu.sendOneMailWithAllDest = null;

          var guids = [];
          // Si il y a des fichiers à joindre
          if (angular.isArray(ctrl.piecesJointesContenu)) {
            if (ctrl.piecesJointesContenu.length) {
              angular.forEach(ctrl.piecesJointesContenu, function (itemFile) {
                guids.push(itemFile.guid);
              });
            }
          }
          instanceProxy.setGuids(guids);

          //---------------  Fin Documents ------------------
        }
        // Si SMS
        else {
          var listeDests = instanceProxy.getListeDestinataires();
          var onlyExp = true;
          if(_.isArray(listeDests) && listeDests.length) {

            for(var d = 0; d < listeDests.length; d++){
              if(listeDests[d].libelle !== UtilsService.getCurrentUser().getLibelle()) {
                onlyExp = false;
                break;
              }
            }
          }
          if(onlyExp){
            sweet.show('Oops...', 'Veuillez renseigner un destinataire', 'error');
            hideLoadingAndNotif(notifWait);
            ctrl.contenuFormulaireCtrl.onError({onError: true});
            return;
          }
        }

        if(!instanceProxy.sms && instanceProxy.sendByMail && angular.isObject(ctrl.signatureSelected) && ctrl.signatureSelected.hasOwnProperty('value')) instanceProxy.setSignature(ctrl.signatureSelected.value);

        /*
        console.log(ctrl.currentItemContenu);
        console.log(instanceProxy);
        hideLoadingAndNotif(notifWait);
        return;
        */

        ctrl.contenuFormulaireCtrl.sendContenu(instanceProxy, ctrl.formTopicTitre, cacher).then(function (retourObj) {

          // Supprime la sauvegarde du localstorage
          AutoSaveService.delete(getObjContenu(), ctrl.currentItemContenu.getIdContenu(), ctrl.contenuFormulaireCtrl.parentContenu);

          //var message = retourObj.obj;
          //var idTopic = message.getIdTopic();

          // Destroy le CKEDITOR si il  existe
          //$rootScope.destroyInstandeCKEDITOR(retourObj.obj);

          if (retourObj.action === 'insertTopic') {
            $state.go('topics.list');
          } else {
            // Si pas la page détail
            if (!$state.includes('topics.detail')) {
              $state.reload();
            }

            // Si page détail
            //if($state.is('topics.detail')){

            /*
                      // Insert le nouveau contenu dans la liste (ecouté par TopicDetailCtrl)
                      $scope.$emit('onAddContenu',message,function(newContenu){

                          if(ctrl.contenuFormulaireCtrl.layoutTopicDetailCtrl){
                              // Close le formulaire de réponse
                              ctrl.contenuFormulaireCtrl.layoutTopicDetailCtrl.openContenu(ctrl.contenuFormulaireCtrl.parentContenu);
                          }
                          UtilsService.scrollToContenu(newContenu);
                      });*/
            /*
                       // Si fonctionne avec les websockets
                       if($rootScope.withWebsockets){
                       // Insert le nouveau contenu dans la liste (ecouté par TopicDetailCtrl)
                       $scope.$emit('onAddContenu',message,function(newContenu){

                       if(parentContenu){
                       // Close le formulaire de réponse
                       $scope.topicsdetailctrl.openContenu(parentContenu);
                       }


                       $scope.scrollToContenu(newContenu);
                       });

                       }else{
                       $state.go('topics.detail',{'topicId': idTopic},{'reload': true});
                       }*/

            /*}else{
                        $state.reload();
                    }*/
          }

        }, function (error) {

          if (error === 'titreVide') {
            sweet.show('Oops...', 'Veuillez renseigner le "Titre"', 'error');
          } else {
            sweet.show({
              title: 'Il y a eu une erreur...',
              text: error.statusText + ' (' + error.status + ')',
              type: 'error'
            });
          }

        }).finally(function () {

          hideLoadingAndNotif(notifWait, function () {
            UtilsService.fermeFormulaireContenu(ctrl.contenuFormulaireCtrl.parentContenu);
          });
          /*
                if(ctrl.contenuFormulaireCtrl.parentContenu) {
                    ctrl.contenuFormulaireCtrl.parentContenu.loadingMessage = false;
                    UtilsService.fermeFormulaireContenu(ctrl.contenuFormulaireCtrl.parentContenu);
                }else{
                    ctrl.submitProgress = false;
                }

                if(notifWait){
                    NotificationsToastServices.clear(notifWait.id);
                }*/

          ctrl.currentItemContenu.sms = false;
        });
      }
      catch(e){
        hideLoadingAndNotif(notifWait);
        ctrl.contenuFormulaireCtrl.onError({onError: true});
      }
    }


    /**
     * Sauvegarde le contenu dans le LocalStorage
     */
    function saveReponseContenu(callback) {



      /*
          var notifWait = false;

          NotificationsToastServices.wait('Sauvegarde','',function(notif){
              notifWait = notif;
          });*/

      AutoSaveService.save(getObjContenu(), ctrl.currentItemContenu.getIdContenu(), ctrl.contenuFormulaireCtrl.parentContenu);
      NotificationsToastServices.success('', '<strong>Sauvegardé temporairement !</strong>');

      /*
          if(notifWait){
              $timeout(function(){
                  NotificationsToastServices.clear(notifWait.id);
              },1000);

          }*/
      if (callback) {
        callback();
      }
    }

    /**
     * Clean le contenu du LocalStorage
     */
    function cleanReponseContenu() {

      sweet.show({
        title: 'Remettre à zero la réponse ?',
        text: 'Vous êtes sûr ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Oui !',
        closeOnConfirm: true
      }, function () {

        if (ctrl.contenuFormulaireCtrl.isNewTopic) {
          ctrl.formTopicTitre = '';
        }
        ctrl.currentItemContenu.destinataires = angular.copy(defaultReponseDestinataires);

        // Si pas de "parentContenu" (nouveau Topic) OU  si nouveau contenu (réponse)
        if (angular.isUndefined(ctrl.contenuFormulaireCtrl.parentContenu) || !ctrl.contenuFormulaireCtrl.parentContenu || ctrl.contenuFormulaireCtrl.parentContenu.nouveau) {
          ctrl.message = '';
          ctrl.messageSms = '';

          // Si modification
        } else {
          ctrl.message = ctrl.contenuFormulaireCtrl.parentContenu.getMessage();
          ctrl.messageSms = ctrl.contenuFormulaireCtrl.parentContenu.getMessageSms();
        }

        // Replace le message dans le WYSIWYG
        if (ctrl.contenuFormulaireCtrl.instanceCKEDITOR) {
          ctrl.contenuFormulaireCtrl.instanceCKEDITOR.setData(ctrl.message);
        }

        if (ctrl.contenuFormulaireCtrl.uploader.queue.length) {
          angular.forEach(ctrl.contenuFormulaireCtrl.uploader.queue, function (doc) {
            ctrl.removePiecesJointes(doc);
          });
        }

        //ctrl.allTags = [];
        ctrl.currentItemContenu.tags = [];
        ctrl.piecesJointesContenu = [];
        calcSommePiecesJointes();
        //ctrl.piecesJointesExclus = [];

        // Remise à 0 des nouveaux destinataires
        ctrl.nouveauxDestinataires = [];

        // Remise à 0 des destinataires à supprimer de la liste
        ctrl.deletedDestinataires = [];

        ctrl.currentTemplateMessage = false;
        ctrl.currentTemplateSms = false;

        // Supprime la sauvegarde du localstorage
        AutoSaveService.delete(getObjContenu(), ctrl.currentItemContenu.getIdContenu(), ctrl.contenuFormulaireCtrl.parentContenu);

        $scope.$digest();
      });
    }

    /**
     * Permet de recupérer un objet du contenu actuellement en cours de modification ou de création pour le stocker dans le local storage
     * @returns {*}
     */
    function getObjContenu() {
      // Est utilisé pour la sauvegarde automatique dans le local storage
      var copyForSave = angular.copy(ctrl.currentItemContenu);

      copyForSave.setMessageSms(ctrl.messageSms);
      var message = '';
      if (ctrl.contenuFormulaireCtrl.instanceCKEDITOR) {
        message = ctrl.contenuFormulaireCtrl.instanceCKEDITOR.getData();
      }
      copyForSave.setMessage(message);


      copyForSave.setDestinataires(angular.copy(ctrl.currentItemContenu.getDestinataires()));
      copyForSave.nouveauxDestinataires = ctrl.nouveauxDestinataires;
      copyForSave.deletedDestinataires = ctrl.deletedDestinataires;
      //copyForSave.setTags(ctrl.allTags);
      copyForSave.setTags(ctrl.currentItemContenu.tags);
      copyForSave.setListeDocument(ctrl.piecesJointesContenu);
      if (ctrl.contenuFormulaireCtrl.isNewTopic) {
        copyForSave.formTopicTitre = ctrl.formTopicTitre;
      }
      return copyForSave;
    }

    /**
     * Executé lors de l'update des tags du contenu message
     * @param objUpdate
     */
    function onUpdateTag(objUpdate) {

      try {

        // Ajoute un tag
        if (objUpdate.hasOwnProperty('addTag')) {
          ctrl.currentItemContenu.pushTag(objUpdate.addTag);
        }

        // Supprime un tag
        if (objUpdate.hasOwnProperty('deleteTagFromContenu')) {
          ctrl.currentItemContenu.removeTag(objUpdate.deleteTagFromContenu);
        }

        if (ctrl.contenuFormulaireCtrl.onUpdate) {
          ctrl.contenuFormulaireCtrl.onUpdate({'objUpdate': objUpdate});
        }
      }
      catch (err) {
        console.log('[ContenuMessageFormulaire.onUpdateTag] Erreur : ' + err.message);
      }

    }

    function onChangeDestinataires(destinataires) {
      //ctrl.listeDestinataire = destinataires;
      ctrl.currentItemContenu.setDestinataires(destinataires);
      checkIfCanShowSendOneMailWithAllDest(ctrl.currentItemContenu.getDestinataires());
    }

    /**
     * Lors du choix d'une signature
     * @param obj
     */
    function onEmitSelectSignatureContenu(obj){
      try {
        if (!obj) throw new Error('Pas d\'obj !');
        if (angular.isObject(obj) && obj.hasOwnProperty('action') && obj.hasOwnProperty('signature')) {
          if(obj.action === 'changeSignature') ctrl.signatureSelected = obj.signature;
        }
      }
      catch (err) {
        console.log('[ContenuMessageFormulaireController.onEmitSelectSignatureContenu] Erreur : ' + err.message);
      }
    }

    /**
     * Lorsque l'editeur de SMS est prêt
     * @param obj
     */
    function onEditorSmsReady(obj){
      ctrl.objEditorSms = obj;
    }

    $scope.$watchCollection('contenumessageformulairectrl.currentItemContenu.destinataires', function (newVal) {
      if (angular.isArray(newVal)) {
        //console.log(newVal);
        ctrl.listeDestinataire = UtilsService.removeWith(newVal, {'idGroupe': UtilsService.getCurrentUser().getIdGroupe()});
      }
    });

    ////-------------------------- LIMIT A TESTER

  }

})();
