'use strict';

/**
 * @ngdoc function
 * @name collaboreApp.controller:ResponsiveCtrl
 * @description Permet de controller le responsive design si ce n'est pas possible de le gérer par CSS
 * # ResponsiveCtrl
 * Controller of the collaboreApp
 */
var ResponsiveCtrl = function(){

	var vm = this;

	// Permet d'afficher ou de cacher la side bar pour les mobiles et tablet
	vm.toggleSidebar = function(){

		angular.element('#btnToggleSidebar').toggleClass('visible-xs-and-sm text-center');
		angular.element('#btnToggleSidebar').find('i').toggleClass('glyphicon-chevron-right glyphicon-chevron-left');
		angular.element('.row-offcanvas').toggleClass('active');
		angular.element('.row-offcanvas').find('#sidebar').toggleClass('active');
		angular.element('#lg-menu').toggleClass('hidden-xs-and-sm').toggleClass('visible-xs-and-sm');
		angular.element('#xs-menu').toggleClass('visible-xs-and-sm').toggleClass('hidden-xs-and-sm');
		angular.element('#maskOpenSidebar').toggleClass('active');

	};

	vm.checkWindowWidth = function() {
		if(window.innerWidth>1200){
			if(angular.element('.row-offcanvas').hasClass('active')){
				vm.toggleSidebar();
			}
		}
	};

	vm.hideSidebar = function(){
		vm.toggleSidebar();
	};

	//first call of checkWindowWidth when the dom is loaded
	document.addEventListener('DOMContentLoaded', this.checkWindowWidth, false);

	//calling checkWindowWidth on resize event
	window.onresize = this.checkWindowWidth;

	/*
	var minifiedMainMenu = false;
	angular.element(window).scroll(function(){
		var posY = angular.element(window).scrollTop();

		var $mainMenu = null;
		if(posY>=10 && !minifiedMainMenu){
			$mainMenu = angular.element('#mainMenu');
			$mainMenu.addClass('minify');
			$mainMenu.find('#mainMenuNav').addClass('minify');
			$mainMenu.find('.navbar-form').addClass('minify');
			angular.element('#mainContent').find('.bg-toolbar-top').addClass('minify');

			minifiedMainMenu = true;
		}else if(posY<10 && minifiedMainMenu){
			$mainMenu = angular.element('#mainMenu');
			$mainMenu.removeClass('minify');
			$mainMenu.find('#mainMenuNav').removeClass('minify');
			$mainMenu.find('.navbar-form').removeClass('minify');
			angular.element('#mainContent').find('.bg-toolbar-top').removeClass('minify');

			minifiedMainMenu = false;
		}
	});
	*/
};

angular
	.module('collaboreApp')
	.controller('ResponsiveCtrl',[
		ResponsiveCtrl
	]);
