(function () {
    'use strict';

    angular
        .module('collaboreApp')
        .component('collabEditor', {
            templateUrl: 'app/divers/collab-editor/collab-editor.component.html',
            controller: CollabEditorController,
            controllerAs: 'collabeditorctrl',
            bindings: {
                texte: '<',
                toolbar: '<',
                miniToolbar: '<',
                showTemplates: '<',
                templates: '<',
                currentTemplate: '<',
                contenuSave: '<',
                onReady: '&'
            }
        });

    /** @ngInject */
    function CollabEditorController(COLLAB_CONF, $rootScope, UtilsService, uuid) {
        var ctrl = this;
        ctrl.notMobile = UtilsService.notMobile;

        ctrl.onFocusTextareaOnMobile = onFocusTextareaOnMobile;
        ctrl.onBlurTextareaOnMobile = onBlurTextareaOnMobile;
        ctrl.ckeditorOnReady 	= ckeditorOnReady;	        // Fonction onReady de CKEDITOR
        ctrl.onChangeTemplateMsg 	= onChangeTemplateMsg;	// Lors du changement de template

        ctrl.$onInit = function () {
            if(UtilsService.onMobile()){
                ctrl.instanceCKEDITOR = {
                    data: '',
                    instanceReady: true,
                    getData: function(){
                        return this.data;
                    },
                    setData: function(val){
                        this.data = val;
                    }
                };
                ckeditorOnReady(ctrl.instanceCKEDITOR);
            }
            else {
                var ckeditor = UtilsService.ckeditorBase();
                ckeditor.editorOptions.height = 200;
                ctrl.instanceCKEDITOR = ckeditor.editor;
                ctrl.ckeditorOptions 	= ckeditor.editorOptions;	  // Options CKEDITOR

                if(ctrl.toolbar) ctrl.ckeditorOptions.toolbar = ctrl.toolbar;
                else if(ctrl.miniToolbar) ctrl.ckeditorOptions.toolbar = COLLAB_CONF.TOOLBAR_CKEDITOR_MINI;
            }

            ctrl.idEditor = uuid.new();

            // Si on veux afficher les templates mais pas de templates passé en param
            if(ctrl.showTemplates && !ctrl.templates) ctrl.templates = UtilsService.getModulesTemplatesObjet('template_message');
        };

        ctrl.$onChanges = function(changes) {
            if(changes.currentTemplate) {
                ctrl.currentTemplate = changes.currentTemplate.currentValue;
            }
            if(changes.texte && ctrl.instanceCKEDITOR) {
                ctrl.texte = changes.texte.currentValue;
                ctrl.instanceCKEDITOR.setData(changes.texte.currentValue);
            }
        };

        ctrl.$onDestroy = function () {
            if(!UtilsService.onMobile())
                destroyCKEDITOR();
        };

        /**
         * Permet de cacher le header lorsqu'on focus le textarea
         */
        function onFocusTextareaOnMobile(){
            $rootScope.hideHeaderLayout = true;
        }

        /**
         * Permet de réafficher le header lorsqu'on quitte le textarea
         */
        function onBlurTextareaOnMobile(){
            $rootScope.hideHeaderLayout = false;
        }

        /**
         * Appelé quand le wysiwyg est prêt
         * @param d
         */
        function ckeditorOnReady(d) {
            if(ctrl.texte) d.setData(ctrl.texte);
            ctrl.onReady({
                onReady: {
                    instance: ctrl.instanceCKEDITOR,
                    getData: function(){
                        return ctrl.instanceCKEDITOR.getData();
                    },
                    setData: function(val){
                        ctrl.instanceCKEDITOR.setData(val);
                    }
                }
            });
        }

        /**
         * Destroy une éventuelle instance de CKEDITOR
         */
        function destroyCKEDITOR(){
            if(ctrl.instanceCKEDITOR){
                ctrl.instanceCKEDITOR.removeAllListeners();
                ctrl.instanceCKEDITOR.destroy();
            }
        }

        var prevTemplateMsg = false, prevObjetMsg;

        /**
         * Lors de la selection du model dans le champ select des template message
         */
        function onChangeTemplateMsg(index) {

            if (!prevTemplateMsg)  prevObjetMsg = angular.copy(ctrl.instanceCKEDITOR.getData());
            else if(prevTemplateMsg.objet == '') prevObjetMsg = angular.copy(ctrl.instanceCKEDITOR.getData());

            if (angular.isNumber(index) && ctrl.templates.length <= (index + 1)) ctrl.currentTemplate = ctrl.templates[index]; // Preselectione le 1er modèle
            // Si il y a des model event
            else if (angular.isObject(index) && index.hasOwnProperty('idModuleValeurParams') && ctrl.templates.length) {
                // Pour chaque model
                for (var i = 0; i < ctrl.templates.length; i++) {
                    // Si le model de la boucle en cours est égale à l'id du model demandé
                    if (ctrl.templates[i].idModuleValeurParams === index.idModuleValeurParams) {
                        ctrl.currentTemplate = ctrl.templates[i];
                        break;
                    }
                }
            }

            // Si un model est selectionné
            if (angular.isObject(ctrl.currentTemplate)) {
                // Si il y a des model event
                if (ctrl.templates.length) {
                    // Pour chaque model
                    for (var m = 0; m < ctrl.templates.length; m++) {
                        var currentModel = ctrl.templates[m];


                        // Si le model de la boucle en cours est égale au model choisi
                        if (ctrl.currentTemplate.idModuleValeurParams === currentModel.idModuleValeurParams) {

                            // Si il y a un objet
                            if (currentModel.objet !== '') {
                                ctrl.tmpObjet = angular.copy(currentModel.objet);

                                if (ctrl.instanceCKEDITOR.instanceReady) {
                                    ctrl.instanceCKEDITOR.setData(currentModel.objet);
                                }
                            }
                            else {
                                // Si il y un model choisi avant de changer
                                if (angular.isObject(prevTemplateMsg)) {
                                    // Si ce model avait un objet prédéfini
                                    if (prevTemplateMsg.objet !== '') {
                                        if (prevObjetMsg) {
                                            // Supprime l'objet dans le WYSIWYG
                                            ctrl.instanceCKEDITOR.setData(prevObjetMsg, {
                                                callback: function () {
                                                    ctrl.tmpObjet = '';
                                                }
                                            });
                                        }
                                        else {
                                            // Supprime l'objet dans le WYSIWYG
                                            ctrl.instanceCKEDITOR.setData('', {
                                                callback: function () {
                                                    ctrl.tmpObjet = '';
                                                }
                                            });
                                        }

                                    }
                                }
                            }
                            break;
                        }
                    }
                }
            }
            else {

                // Si il y a un contenu save
                if (ctrl.contenuSave) {

                    // Set l'objet client dans une variable temporaire du controller
                    if (ctrl.contenuSave.objet !== '' && angular.isDefined(ctrl.contenuSave.objet)) {
                        ctrl.tmpObjet = ctrl.contenuSave.objet;
                        ctrl.instanceCKEDITOR.setData(angular.copy(ctrl.contenuSave.objet));
                    }
                }
                else {

                    // Siil y avait un model choisi avant de changer
                    if (angular.isObject(prevTemplateMsg)) {

                        if (prevTemplateMsg.objet !== '') {
                            if (prevObjetMsg) {
                                // Supprime l'objet dans le WYSIWYG
                                ctrl.instanceCKEDITOR.setData(prevObjetMsg, {
                                    callback: function () {
                                        ctrl.tmpObjet = '';
                                    }
                                });
                            }
                            else {
                                // Supprime l'objet dans le WYSIWYG
                                ctrl.instanceCKEDITOR.setData('', {
                                    callback: function () {
                                        ctrl.tmpObjet = '';
                                    }
                                });
                            }
                        }
                    }
                }

            }
            prevTemplateMsg = angular.copy(ctrl.currentTemplate);
        }
    }
})();

