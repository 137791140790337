(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .component('modalDeclarerSinistre', {
      templateUrl: 'app/topics/evenement/sinistre/modal-declarer-sinistre.component.html',
      controller: ModalDeclarerSinistreController,
      controllerAs: 'modaldeclarersinistrectrl',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      }
    });

  /** @ngInject */
  function ModalDeclarerSinistreController($timeout, $scope, SinistreService, DemandeOptions, ContenuSinistre, UtilsService, ModalsService) {
    var ctrl = this;


    /********** Global **********/
    ctrl.loading = false;
    //ctrl.currentStep = 1;
    ctrl.libelleStep = {
      1: {libelle: 'Déclaration'},
      2: {libelle: 'Assureur'},
      3: {libelle: 'Expert'}/*,
            4: {libelle: 'Mail'}*/
    };
    ctrl.nbTotalStep = 3;
    ctrl.onClickChangeEtape = onClickChangeEtape;
    ctrl.onClickPreviousStep = onClickPreviousStep;     // Lors du clique sur l'étape précédente
    ctrl.onClickNextStep = onClickNextStep;             // Lors du clique sur l'étape suivante
    ctrl.onClickFinish = onClickFinish;                 // Lors du clique sur le bouton terminé
    ctrl.onClickSave = onClickSave;                     // Lors du clique sur le bouton terminé
    ctrl.onClickClose = onClickClose;                   // Lors du clique sur le bouton Fermer ou la croix


    /********** Etape 1 **********/
    ctrl.datePickerOptions = {
      startingDay: 1,
      showWeeks: true
    };
    ctrl.natureSinistre = {
      loading: false,
      list: [],
      selected: null,
      custom: {
        enable: false,
        metier: null,
        libelle: null
      }
    };
    ctrl.dateSinistre = null;
    ctrl.numRar = null;
    ctrl.onChangeNatureSinistre = onChangeNatureSinistre;
    ctrl.onClickAddNewNatureSinistre = onClickAddNewNatureSinistre;
    ctrl.onClickOpenDateSinistre = onClickOpenDateSinistre;
    ctrl.onEmitSelectSignatureContenu = onEmitSelectSignatureContenu;
    ctrl.onEmitFromTemplateTextareaComponent = onEmitFromTemplateTextareaComponent;

    /********** Etape 2 **********/
    ctrl.onUpdateContrat = onUpdateContrat;

    /********** Etape 3 **********/
    ctrl.onUpdateExpert = onUpdateExpert;
    ctrl.objExpertTmp = null;
    ctrl.tmpExpert = null;

    /********** Etape 4 **********/
    //ctrl.onUpdateMail = onUpdateMail;
    //ctrl.infosMail = null;


    /********** Global **********/
    ctrl.$onInit = function () {

      ctrl.sinistre = angular.copy(getParam('objetSinistre', new ContenuSinistre()));

      var infosSinistre = ctrl.sinistre.getInfosSinistre();
      if(infosSinistre.getDateSinistre()) ctrl.dateSinistre = infosSinistre.getDateSinistreFormat(true);
      ctrl.numRar = infosSinistre.getNumRar();
      ctrl.commentaire = infosSinistre.getCommentaire();
      ctrl.infosEvenement = getParam('infosEvenement');
      ctrl.currentStep = getParam('step', 1);

      if(_.isObject(ctrl.infosEvenement)){
        ctrl.displayDemandeOptions = (!_.isNil(ctrl.infosEvenement.noPersoProprietaire));
        if(ctrl.displayDemandeOptions){
          ctrl.checkboxHideProprietaireInDocument = UtilsService.getParametrage('hideProprietaireInDocument');
        }
      }

      ctrl.natureSinistre.loading = true;
      SinistreService
        .getListNatureSinistre()
        .then(function(listNatureSinistre) {
          if(listNatureSinistre.length && infosSinistre.getNatureSinistre()) {
            var natureSinistre = infosSinistre.getNatureSinistre();
            for(var i = 0; i < listNatureSinistre.length; i++) {
              var currentNatureSinistre = listNatureSinistre[i];
              if(currentNatureSinistre.hasOwnProperty('idNatureSinistre') && natureSinistre.hasOwnProperty('idNatureSinistre')) {
                if(currentNatureSinistre.idNatureSinistre === natureSinistre.idNatureSinistre) {
                  ctrl.sinistre.infosSinistre.natureSinistre = currentNatureSinistre;
                }
              }
              else if(currentNatureSinistre.hasOwnProperty('idNatureSinistreGlobal') && natureSinistre.hasOwnProperty('idNatureSinistreGlobal')) {
                if(currentNatureSinistre.idNatureSinistreGlobal === natureSinistre.idNatureSinistreGlobal) {
                  ctrl.sinistre.infosSinistre.natureSinistre = currentNatureSinistre;
                }
              }
            }
          }
          ctrl.natureSinistre.list = listNatureSinistre;
        })
        .catch(function(msg) {
          ModalsService.alertErreur(msg);
        })
        .finally(function() {
          ctrl.natureSinistre.loading = false;
        });
    };

    ctrl.$onDestroy = function () {
      ctrl.sinistre = null;
    };

    function getParam(param, defaultVal){
      if(angular.isObject(ctrl.resolve) && ctrl.resolve.hasOwnProperty('params') && angular.isObject(ctrl.resolve.params)) {
        if(ctrl.resolve.params.hasOwnProperty(param) && !angular.isUndefined(ctrl.resolve.params[param]) && ctrl.resolve.params[param] !== null){
          return ctrl.resolve.params[param];
        }
      }
      if(defaultVal) return defaultVal;
      return null;
    }

    function onClickChangeEtape(num){
      ctrl.currentStep = num;
    }

    /**
     * Lors du clique sur l'étape précédente
     */
    function onClickPreviousStep(){
      if(ctrl.currentStep > 1) {
        ctrl.currentStep = ctrl.currentStep - 1;
      }
    }

    /**
     * Lors du clique sur l'étape suivante
     */
    function onClickNextStep(){
      if(ctrl.currentStep < ctrl.nbTotalStep) {
        ctrl.currentStep = ctrl.currentStep + 1;
      }
    }

    /**
     * Lors du clique sur le bouton terminé
     */
    function onClickFinish(){
      mergeAndClose();
    }

    function onClickSave(){
      mergeAndClose();
    }

    function onClickClose(){
      ctrl.dismiss({$value: 'cancel'});
    }

    /**
     * Traite les donnée pour les renvoyer au composant qui appel celui si
     */
    function mergeAndClose(){
      ctrl.sinistre.getInfosSinistre().setDateSinistre(ctrl.dateSinistre);
      ctrl.sinistre.getInfosSinistre().setNumRar(ctrl.numRar);
      ctrl.sinistre.getInfosSinistre().setCommentaire(ctrl.commentaire);
      if(ctrl.natureSinistre.custom.enable){
        ctrl.sinistre.getInfosSinistre().setNatureSinistre({
          metier: ctrl.natureSinistre.custom.metier,
          libelle: ctrl.natureSinistre.custom.libelle
        });
      }

      if(!_.isNil(ctrl.checkboxHideProprietaireInDocument)){
        if(_.isNil(ctrl.sinistre.demandeOptions)) ctrl.sinistre.demandeOptions = new DemandeOptions();
        ctrl.sinistre.demandeOptions.setHideProprietaireInDocument(ctrl.checkboxHideProprietaireInDocument);
      }

      var codeMetier = null;
      // Si il y a des params pour le composant contrat et c'est un nouvel assureur
      if(_.isObject(ctrl.paramsContrat) && ctrl.paramsContrat.new && !_.isNil(ctrl.sinistre.getInfosSinistre().getNatureSinistre())) {
        // Recupère le code métier
        codeMetier = ctrl.sinistre.getInfosSinistre().getNatureSinistre().getMetier();
      }
      if(ctrl.currentStep === 2) $scope.$broadcast('updateAssureur', codeMetier);

      // Si il y a un expert dans l'objet temporaire et que l'on est pas sur la page de l'expert
      if(ctrl.objExpertTmp && ctrl.currentStep !== 3) ctrl.sinistre.getInfosSinistre().setExpert(ctrl.objExpertTmp);
      else $scope.$broadcast('updateExpert');

      //if(ctrl.currentStep === 4) $scope.$broadcast('updateMail');

      if(ctrl.signatureSelected && ctrl.signatureSelected.hasOwnProperty('value')) ctrl.sinistre.setSignature(ctrl.signatureSelected.value);
      else ctrl.sinistre.setSignature(null);

      $timeout(function() {
        if(_.isObject(ctrl.sinistre)) {
          var canClose = true;
          /*
          if(_.isObject(ctrl.sinistre.getInfosSinistre().getAssureur()) && _.isObject(ctrl.paramsContrat) && !ctrl.paramsContrat.custom && !ctrl.paramsContrat.edit && !ctrl.paramsContrat.new) {
            ctrl.sinistre.getInfosSinistre().setAssureur(null);
          }*/
          if(_.isObject(ctrl.sinistre.getInfosSinistre().getAssureur())) {
            canClose = checkNomFournisseur(ctrl.sinistre.getInfosSinistre().getAssureur());
          }
          if(canClose){
            ctrl.close({$value: ctrl.sinistre});
          }
          else {
            ctrl.currentStep = 2;
          }
        }
      });
    }

    /********** Etape 1 **********/

    /**
     * Lors du changement d'une nature de sinistre
     */
    function onChangeNatureSinistre(){
      ctrl.sinistre.getInfosSinistre().setNatureSinistre(ctrl.natureSinistre.selected);
    }

    /**
     * Lors du clique sur le bouton pour ajouter un nouveau nature sinistre
     */
    function onClickAddNewNatureSinistre(){
      ctrl.natureSinistre.custom.metier = null;
      ctrl.natureSinistre.custom.libelle = null;
      ctrl.natureSinistre.custom.enable = !ctrl.natureSinistre.custom.enable;
    }

    /**
     * Lors du clique sur le bouton pour ouvrir le datipicker de la date de sinistre
     */
    function onClickOpenDateSinistre(){
      ctrl.dateSinistreOpened = true;
    }

    /**
     * Lors du choix d'une signature
     * @param obj
     */
    function onEmitSelectSignatureContenu(obj){
      try {
        if (!obj) throw new Error('Pas d\'obj !');
        if (angular.isObject(obj) && obj.hasOwnProperty('action') && obj.hasOwnProperty('signature')) {
          if(obj.action === 'changeSignature') ctrl.signatureSelected = obj.signature;
        }
      }
      catch (err) {
        console.log('[modalDeclarerSinistre.onEmitSelectSignatureContenu] Erreur : ' + err.message);
      }
    }

    function onEmitFromTemplateTextareaComponent(obj){
      try {
        if (!obj) throw new Error('Pas d\'obj !');
        if (angular.isObject(obj) && !_.isNil(obj.text)) ctrl.commentaire = obj.text;
      }
      catch (err) {
        console.log('[modalDeclarerSinistre.onEmitFromTemplateTextareaComponent] Erreur : ' + err.message);
      }
    }

    /**
     * Ouvre l'annuaire
     * @param objParams
     * @returns {Promise}
     */
    /*
    function openAnnuaire(objParams){
        var deferred = $q.defer();

        var params = {
            stringTo: 'tag',
            searchWhenOpen: true,
            allCollaborateur: true,
            limitDestinataire: 1,
            infosSiniste: ctrl.sinistre
        };
        if(objParams) {
            params = angular.merge(params, objParams);
        }

        if(ctrl.infosEvenement) {
            var addInfosEvent = false;
            if(objParams.infosEvent) {
                addInfosEvent = objParams.infosEvent;
            }
            params.infosEvent = ctrl.infosEvenement;
            if(addInfosEvent) {
                params.infosEvent = angular.merge(params.infosEvent, addInfosEvent);
            }
        }

        var options = {
            size: 'full',
            windowClass: 'modal-collabore modal-collabore-full',
            backdrop: 'static'
        };
        ModalsService
            .modalComponent('modalAnnuaire', params, options)
            .then(function(obj) {
                deferred.resolve(obj);
            });

        return deferred.promise;
    }*/

    /********** Etape 2 **********/

    function checkNomFournisseur(contrat){
      var hasNom = false;
      if(!_.isNil(contrat) && !_.isNil(contrat.getFournisseur())){
        if(contrat.getFournisseur().hasInformations()) {
          for(var i = 0; i < contrat.getFournisseur().getInformations().length; i++) {
            var renseignement = contrat.getFournisseur().getInformations()[i];
            if(renseignement.getReference() && renseignement.getReference().isNom() && !_.isNil(renseignement.getDonnee()) && !_.isEmpty(renseignement.getDonnee())) {
              hasNom = true;
              break;
            }
          }
        }
        if(!hasNom) {
          ModalsService.alertErreur('Attention le nom du fournisseur est obligatoire !');
        }
      }
      return hasNom;
    }

    function onUpdateContrat(obj) {
      if(_.isObject(ctrl.sinistre)) {
        if(obj.hasOwnProperty('contrat')) {
          // checkNomFournisseur(obj.contrat);
          ctrl.sinistre.getInfosSinistre().setAssureur(obj.contrat);
        }
        if(obj.hasOwnProperty('params')) {
          ctrl.paramsContrat = obj.params;
        }
      }
    }


    /********** Etape 3 **********/
    function onUpdateExpert(obj) {
      if(obj.hasOwnProperty('tmp')) {
        ctrl.tmpExpert = obj.tmp;
        if(obj.hasOwnProperty('expert')) {
          ctrl.objExpertTmp = obj.expert;
        }
      }
      else if(obj.hasOwnProperty('expert')) {
        ctrl.sinistre.getInfosSinistre().setExpert(obj.expert);
      }
    }


    /********** Etape 4 **********/
    /*
    function onUpdateMail(obj) {
        console.log(obj);
        if(obj.hasOwnProperty('mail')) ctrl.sinistre.getInfosSinistre().setMailInfos(obj.mail);
    }*/
  }
})();

