(function() {

    'use strict';

    /**
     * @desc Composant de la liste des fournisseurs ICS de ICSCloud
     * @example <liste-fournisseur-ics></liste-fournisseur-ics>
     */
    angular
        .module('collaboreApp')
        .component('listeFournisseurIcs',{
            templateUrl: 'app/components/recherche-ics/liste-fournisseur-ics/liste-fournisseur-ics.component.html',
            controllerAs: 'listefournisseuricsctrl',
            bindings: {
                search: '<',    // Champ de recherche
                synchrone: '<',
                listIdPortefeuille: '<',
                onEmit: '&'
            },
            controller: ListeFournisseurIcsCtrl
        });

    /** @ngInject */
    function ListeFournisseurIcsCtrl(COLLAB_CONF, $rootScope, $state, $q, $scope, AnnuaireService, TagsService, UtilsService){
        var ctrl = this;
        ctrl.loading = false;
        ctrl.listFournisseur = [];

        ctrl.onClickFiltreTopic = onClickFiltreTopic;   // Lors du clique sur le bouton pour filtrer les topics

        ctrl.$onInit = function() {
            if(!ctrl.synchrone) {
                init();
            }
            else {
                ctrl.loading = true;
                var listener = $scope.$on('initListeFournisseurIcs', function(){
                    init();
                    listener();
                });
            }
        };

        ctrl.$onChanges = function(changes){
            if(!changes.listIdPortefeuille.isFirstChange() && !ctrl.search.isFirstChange()) {
                ctrl.listIdPortefeuille = changes.listIdPortefeuille.currentValue;
                ctrl.search = changes.search.currentValue;
                init();
            }
        };

        /**
         * Permet d'init le composant
         */
        function init(){
            if(_.isNil(ctrl.listIdPortefeuille)) {
                var listePortefeuilles = UtilsService.getCurrentUser().getListePortefeuille();
                if (listePortefeuilles.length) {
                    ctrl.listIdPortefeuille = listePortefeuilles.map(function(port){
                        return port.idPortefeuille;
                    });
                }
            }

            ctrl.loading = true;
            callFournisseur()
                .then(function(listFournisseur){
                    ctrl.listFournisseur = listFournisseur;
                })
                .finally(function(){
                    ctrl.loading = false;
                });
        }

        /**
         * Permet d'appeler ICSCloud pour récupérer les fournisseurs
         * @returns {*}
         */
        function callFournisseur(){
            var deferred = $q.defer();

            var paramsBase = {
                avecemail: 0,
                type: 'F',
                usersCollab: 0,
                groupesCollab: 0,
                recherche: ctrl.search,
                idPortefeuilles: ctrl.listIdPortefeuille,
                commencePar: false
            };

            AnnuaireService
                .searchCollaborateur(paramsBase)
                .then(function (objectCollaborateur) {

                    var listPersonnalite = [];

                    // Si il y a des perso ou fournisseur
                    if (_.isObject(objectCollaborateur) && _.isArray(objectCollaborateur.listePortefeuilles) && objectCollaborateur.listePortefeuilles.length) {

                        for(var p = 0; p < objectCollaborateur.listePortefeuilles.length; p++){
                            var portefeuille = objectCollaborateur.listePortefeuilles[p];

                            var portefeuilleCopy = angular.copy(portefeuille);
                            delete portefeuilleCopy.listePersonnalite;

                            if (portefeuille.getListePersonnalite().length) {
                                for(var j = 0; j < portefeuille.getListePersonnalite().length; j++) {

                                    listPersonnalite.push({
                                        personnalite: portefeuille.getListePersonnalite()[j],
                                        portefeuille: portefeuilleCopy
                                    });
                                }
                            }
                        }
                    }
                    deferred.resolve(listPersonnalite);
                })
                .catch(function(err) {
                    deferred.reject(err);
                });

            return deferred.promise;
        }

        /**
         * Lors du clique sur le bouton pour filtrer les topics
         * @param objPerso
         * @param objImmeuble
         */
        function onClickFiltreTopic(objPerso){
            var objetsTags = setTagsForOrigine(objPerso);
            $scope.$emit('rechercheByTags', objetsTags);
        }

        /**
         * Permet de générer et setter la variable "tagsForOrigine" pour l'ajout des tags lors de la création d'événément
         * @param objPerso
         * @param objImmeuble
         */
        function setTagsForOrigine(objPerso){

            var obj = {
                origine: objPerso.personnalite,
                portefeuille: objPerso.portefeuille,
                type: 'ICS'
            };

            obj.origine.portefeuille = {
                idPortefeuille: objPerso.portefeuille.getIdPortefeuille(),
                nom: objPerso.portefeuille.getNom()
            };

            $rootScope.current.tmpNewEvenement.tagsForOrigine = TagsService.genereTagsAndFiches(obj);
            return $rootScope.current.tmpNewEvenement.tagsForOrigine;
        }

        function emit(objet){
            ctrl.onEmit({
                obj: objet
            });
        }
    }
})();
