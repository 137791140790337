(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.MainService
   * @description
   * # MainService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('MainService',
      MainServiceController
    );

  /** @ngInject */
  function MainServiceController($injector, ErreurCollabService) {
    var ctrl = this;

    ctrl.$injector = $injector;
    ctrl.convertJsonToModel = convertJsonToModel;   // Permet de convertir une liste dans un JSON en liste d'instance du Model
    ctrl.isSuccess = isSuccess;                     // Permet de savoir si le json retourne success
    ctrl.initContenuByType = initContenuByType;     // Permet d'instancier un contenu par rapport à son typeContenu
    ctrl.getOrResolveNbSuccess = getOrResolveNbSuccess; // Permet de retourner la valeur de la propriété "nb" du json dans le cas d'un success via resolve ou en retour de la fonction

    /**
     * Permet de convertir une liste dans un JSON en liste d'instance du Model
     * @param json
     * @param nameResult
     * @param Model
     * @returns {Array}
     */
    function convertJsonToModel(json, nameResultOrArray, Model, deferred) {

      var retour = [];
      try {
        if ((json.hasOwnProperty('nb') && json.nb !== '0') || json.success) {

          if(_.isNull(nameResultOrArray) && _.isNull(Model)) return true;

          // Le model n'existe pas et que le nom de l'attribut dans le json est différent de 'contenu'
          if (!angular.isFunction(Model) && !angular.isString(Model) && nameResultOrArray !== 'contenu') {
            throw new Error('Le model "' + Model + '" n\'éxiste pas !');
          }

          if(angular.isString(Model)) Model = $injector.get(Model);

          if(Model === null && nameResultOrArray === 'contenu'){
            nameResultOrArray = ['contenu', 'Contenus', 'result'];
          }

          var nameResult = null;
          // Si le nameResult est un string
          if(angular.isString(nameResultOrArray)) nameResult = nameResultOrArray;
          // SI c'est un tableau
          else if(angular.isArray(nameResultOrArray)) {
            nameResult = getFirstPropertyOfArrayInJSON(nameResultOrArray, json);
          }

          // La propriété n'existe pas dans le JSON
          if (!nameResult || !json.hasOwnProperty(nameResult)) {
            // Si nameResult est contenu est qu'il y a "Contenus" dans le JSON et que c'est bien un array
            if(nameResult === 'contenu' && json.hasOwnProperty('Contenus') && angular.isArray(json.Contenus) && json.Contenus.length === 1){
              nameResult = 'Contenus';
            }
            // Si il y a Resultats dans le JSON alors c'est lui qu'il faut aller voir
            else if (json.hasOwnProperty('Resultats') && angular.isArray(json.Resultats) && json.Resultats.length === 1) {

              var resultat = json.Resultats[0];
              if (resultat.success) {
                // Si nameResult est toujours null est que "nameResultOrArray" est un array
                if(!nameResult && angular.isArray(nameResultOrArray)) nameResult = getFirstPropertyOfArrayInJSON(nameResultOrArray, resultat);

                if (resultat.hasOwnProperty(nameResult)) json = resultat;
                else throw new Error('La propriété "' + nameResult + '" n\'éxiste pas dans "Resultats" !');
              }
              else {
                var msg = 'Resultats success false !';
                if (resultat.hasOwnProperty('message')) {
                  msg = resultat.message;
                }
                throw new Error(msg);
              }
            }
            else {
              throw new Error('La propriété "' + nameResult + '" n\'éxiste pas dans le JSON !');
            }
          }

          // Si la propriété n'est pas un tableau
          /*
           if(!angular.isArray(json[nameResult])) {
           throw new Error('La propriété "' + nameResult + '" n\'est pas un array !');
           }*/

          if (angular.isArray(json[nameResult])) {
            for (var i = 0; i < json[nameResult].length; i++) {
              if(Model === null) retour.push(this.initContenuByType(json[nameResult][i]));
              else {
                if(json[nameResult][i].hasOwnProperty('class')) {
                  var nModel = $injector.get(json[nameResult][i].class);
                  retour.push(new nModel(json[nameResult][i]));
                  nModel = null;
                }
                else retour.push(new Model(json[nameResult][i]));
              }
            }
          }
          else if (angular.isObject(json[nameResult])) {
            if(Model === null) retour = this.initContenuByType(json[nameResult]);
            else {
              if(json[nameResult].hasOwnProperty('class')) {
                var nModel = $injector.get(json[nameResult].class);
                retour.push(new nModel(json[nameResult]));
                nModel = null;
              }
              else retour = new Model(json[nameResult]);
            }
          }
          else {
            throw new Error('La propriété "' + nameResult + '" n\'est ni un Array, ni un Object !', json);
          }
        }
        else {
          if(!json.success && json.hasOwnProperty('message'))  throw new Error(json.message, json);
          else if(!json.success)  throw new Error('Il y a eu une erreur', json);
          else ErreurCollabService.logInfo('Pas de résultat ! nameResult : ' + nameResult + '; JSON : ', json);
        }
      }
      catch (err) {
        ErreurCollabService.logErreur(err.message);
        if (deferred) {
          deferred.reject(err.message);
        }
      }

      return retour;
    }

    function getFirstPropertyOfArrayInJSON(nameResultOrArray, json){
      var nameResult = null;
      for(var i = 0; i < nameResultOrArray.length; i++) {
        // La propriété n'existe pas dans le JSON
        if (json.hasOwnProperty(nameResultOrArray[i])) {
          nameResult = nameResultOrArray[i];
          break;
        }
      }
      return nameResult;
    }

    /**
     * Permet de retourner la valeur de la propriété "nb" du json dans le cas d'un success via resolve ou en retour de la fonction
     * @param json
     * @param deferred
     * @returns {boolean}
     */
    function getOrResolveNbSuccess(json, deferred) {
      var retour = false;
      try {
        if(!json.success && json.hasOwnProperty('message'))  throw new Error(json.message, json);
        else if(!json.success)  throw new Error('Il y a eu une erreur', json);
        else {
          if (parseInt(json.nb) === 0) throw new Error('Aucun résultat', json);
          retour = json.nb;
          if (deferred) deferred.resolve(json.nb);
        }
      }
      catch (err) {
        ErreurCollabService.logErreur(err.message);
        if (deferred) deferred.reject(err.message);
      }
      return retour;
    }

    function isSuccess(json, deferred) {
      var retour = false;
      try {
        if (json.nb === '0') throw new Error('JSON Nb 0', json);
        if (!json.hasOwnProperty('Resultats')) throw new Error('JSON pas de Resultat', json);
        if (json.Resultats[0].success) retour = true;
        else throw new Error(json.message);
        if (deferred) deferred.resolve();
      }
      catch (err) {
        ErreurCollabService.logErreur(err.message);
        if (deferred) deferred.reject(err.message);
      }
      return retour;
    }

    /**
     * Instancie un Contenu par rapport à son typeContenu
     * @param contenu
     */
    function initContenuByType(contenu) {
      var retour = null;
      try {
        if (!angular.isObject(contenu)) throw new Error('Le paramètre n\'est pas un objet !', contenu);
        if (!contenu.hasOwnProperty('typeContenu')) throw new Error('Pas de typeContenu trouvé', contenu);

        var Model = null;
        switch(contenu.typeContenu){
          case 1:
            Model = $injector.get('ContenuMessage');
            break;
          case 2:
            Model = $injector.get('ContenuEvenement');
            break;
          case 3:
            Model = $injector.get('ContenuEvenementDevis');
            break;
          case 4:
            Model = $injector.get('ContenuEvenementIntervention');
            break;
          case 5:
            Model = $injector.get('ContenuEvenementFacture');
            break;
          case 6:
            Model = $injector.get('ContenuSinistre');
            break;
          case 7:
            Model = $injector.get('ContenuEvenementContrat');
            break;
          default:
            Model = $injector.get('ContenuMessage');
        }

        if(Model === null || !angular.isFunction(Model)) throw new Error('Le model "' + Model + '" n\'éxiste pas !');

        retour = new Model(contenu);
      }
      catch (err) {
        ErreurCollabService.logErreur(err.message);
      }
      return retour;
    }
  }
})();
