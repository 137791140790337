'use strict';


var DestinatairesComponent = {
	templateUrl : 'app/scripts/topics/directives/destinataires.html',
	bindings: {
		libelle: '@',							// Libelle devant le bouton d'ajout
		//positionLibelle: '@',					// Libelle avant ou après l'icon
		itemContenu: '=',						// Objet contenu
		itemEvenement: '<',
		listeDestinataires: '=',				// Si liste de destinataire différent du contenu
		beforeAdd: '&',							// Callback avant l'ajout d'un destinataire
		afterAdd: '&',							// Callback apres l'ajout d'un destinataire
		onError: '&',							// Callback lors d'une erreur

		onAdd: '&',								// Callback lors de l'ajout d'un destinataire
		onDelete: '&',							// Callback lors de la suppression d'un destinataire
		to: '=?',								// Attribut des destinataire de l'object contenu
		limit: '=',								// Limit de destinataire à ajouter
		nbDisplayDestinataires: '=nbVisible',	// Limit de destinataire à afficher
		hideSeparator: '<',						// Cache le tiret qui sépare les icones
		allowAdd: '=?',							// Permet l'affichage du boutton d'ajout de dest
		allowDelete: '=',						// Permet l'affichage du bouton de suppression du dest
		mode: '@',								// Mode annuaire ou non
		eStyle: '@',							// Style à appliquer au champ input
		classBtn: '@',							// Class des bouton ok et annuler de l'ajout du dest
		classIcon: '@',							// Icon pour l'ouverture du popup d'jaout de dest
		popupPlacement: '@',					// Placement de la popup d'ajout de dest
		popupListeDestinatairesPlacement: '@',	// Placement de la popup des autres dest
		save: '=',								// Permet de save en live ou non
		msgEmpty: '=',							// Message à afficher en cas liste vide
		disallowUser: '=',						// Est-ce l'user actuel connecté peut etre un destinataire ?
		showBtnPopover: '=?',					// Permet d'afficher ou non le bouton pour ouvrire le popover
		showBtnAnnuaire: '=?',					// Permet d'afficher ou non le bouton rapide de l'annuaire
		currentModelEvenement: '=',				// Objet du model actuellement selectionné
		defautTypeAnnuaire: '@',				// Le type par défaut de l'annuaire
		onlyFournisseur: '@',
		allCollaborateur: '@'
	},
	controllerAs: 'destinatairesctrl',
	controller: ['$rootScope','$scope','$timeout','$attrs','$state','$q', '$uibModal', 'Groupe','UtilsService','UserService','AnnuaireService','ProxyDestinataire',
		function($rootScope, $scope,$timeout,$attrs,$state,$q,$uibModal,Groupe,UtilsService,UserService,AnnuaireService,ProxyDestinataire) {

			var ctrl = this;

			ctrl.numMandat = false;
			ctrl.numImmeuble = false;
			ctrl.portefeuille = false;
			ctrl.onDeleteDestinataire = onDeleteDestinataire;

			ctrl.$onInit = function() {


				if('onAdd' in $attrs){
					ctrl.fctOnAdd = ctrl.onAdd;
				}

				if('onDelete' in $attrs){
					ctrl.fctOnDelete = ctrl.onDelete;
				}


				if(angular.isUndefined($attrs.showBtnPopover)){
					ctrl.showBtnPopover = true;
				}


				if(ctrl.showBtnPopover && $state.is('nouveau.evenement')){

					$scope.$watchCollection('destinatairesctrl.currentModelEvenement',function(newVal){

						if(angular.isObject(newVal)){
							if(newVal.isModeleHotline){
								ctrl.showBtnPopover=false;
							}else{
								ctrl.showBtnPopover=true;
							}
						}else{
							ctrl.showBtnPopover=true;
						}
					});
				}



				//if('allowAdd' in attrs){
				if(angular.isUndefined($attrs.allowAdd)){
					ctrl.allowAdd = true;
				}
				//}

				if(ctrl.itemEvenement){
					if(ctrl.itemEvenement.isContenu){
						var tagMandat = ctrl.itemEvenement.getTagForType('Mandat');
						if(tagMandat){
							ctrl.numMandat = tagMandat.getValeurIcs();
						}

						var tagImmeuble = ctrl.itemEvenement.getTagForType('Immeuble');
						if(tagImmeuble){
							ctrl.numImmeuble = tagImmeuble.getValeurIcs();
						}

						var tagPortefeuille = ctrl.itemEvenement.getTagForType('Portefeuille');
						if(tagPortefeuille){
							ctrl.portefeuille = {
								'nom': tagPortefeuille.getLibelle(),
								'idPortefeuille': tagPortefeuille.getValeurIcs()
							};
						}
					}
				}


			};


			var vm = this;
			vm.popoverDestinataireIsOpen = false;
			vm.newDest = {};

			if(!ctrl.classIcon){
				ctrl.classIcon = 'fa fa-user-plus fa-lg';
			}

			// Recupère la liste des destinataires qui ne sont pas déjà présent
			/*
			 if(!vm.contacts){
			 vm.contacts = AnnuaireService.updateListeContact(ctrl.itemContenu[ctrl.to]);
			 }*/

			var getAutreDestinataires = function(destinataires){
				if(ctrl.to!=='groupesConcernes') {
					ctrl.destinataires = UtilsService.removeWith(destinataires, {'idGroupe': UserService.getGroupe().getIdGroupe()});
				}else{
					/*
					 var tmp = [];
					 for(var i = 0 ; i < destinataires.length ; i++){
					 tmp.push(createObjDestinataire(destinataires[i]));
					 }
					 ctrl.destinataires = tmp;*/
					ctrl.destinataires = destinataires;
				}

				ctrl.autresDestinataires = [];
				if(angular.isArray(ctrl.destinataires) && (ctrl.nbDisplayDestinataires || ctrl.nbDisplayDestinataires===0)){
					// Pour chaque destinataire du contenu
					angular.forEach(ctrl.destinataires, function(destinataire, key) {

						// Si l'index du la boucle est plus grand que le nombre de destinataire display
						if(key>=ctrl.nbDisplayDestinataires){

							ctrl.autresDestinataires.push(destinataire);
						}
					});
				}
			};

			var setDestinataires = function(newVal,update){

				getAutreDestinataires(newVal);

				if(ctrl.autresDestinataires.length && (ctrl.nbDisplayDestinataires || ctrl.nbDisplayDestinataires === 0)){
					if(newVal.length && update){
						vm.openned = true;
					}
				}
			};

			// Tableau des destinataire qui sera affiché
			ctrl.destinataires = [];


			// service user
			ctrl.user = UserService;

			if(!ctrl.save){ctrl.saveLive = false;} // Si save à false alors on sauvegarde pas en live
			else{ctrl.saveLive = true;} // Si save à true alors on sauvegarde en live

			// Si le contenu est un devis est que c'est une modification alors on save le destinataire à la volée
			if(ctrl.itemContenu && (ctrl.itemContenu.isDevis || ctrl.itemContenu.isIntervention) && ctrl.itemContenu.getIdContenu() && ctrl.to!=='groupesConcernes'){
				ctrl.saveLive = true;
			}

			// Propriété de l'object 'itemContenu' à manipuler
			if(!ctrl.to){ctrl.to = 'destinataires';}

			// Placement de la popup d'ajout de destinataire
			if(!ctrl.popupPlacement){ctrl.popupPlacement = 'top';}

			// Palcement de la popup des autres destinataires
			if(!ctrl.popupListeDestinatairesPlacement){ctrl.popupListeDestinatairesPlacement = 'bottom';}

			// Icon pour ouvrir la popup d'ajout de destinataire
			//if(!ctrl.iconAdd){ctrl.iconAdd = 'fa fa-user-plus';}

			// icon pour ouvrir l'annuaire
			//if(!ctrl.iconAnnuaire){ctrl.iconAnnuaire = 'fa fa-book';}

			//console.log(ctrl.listeDestinataires);
			//console.log(ctrl.itemContenu.destinataires);
			// Si listeDestinataires est un tableau on passe une liste de destinataire autre de celle du itemContenu
			if(angular.isArray(ctrl.listeDestinataires)){


				// Ecoute les changement de 'listeDestinataires' car  l'hote qui appel la directive peut mettre du temps à créer me tableau
				$scope.$watchCollection('destinatairesctrl.listeDestinataires',function(newVal){

					// Recupère la liste des destinataires qui ne sont pas déjà présent
					//vm.contacts = AnnuaireService.updateListeContact(newVal);
					setDestinataires(newVal);
				});


			}else{
				$scope.$watchCollection('destinatairesctrl.itemContenu.'+ctrl.to,function(newVal){

					// Suppression d'un destinataire, si il y a des destinataire temporaire
					/*
					 if($rootScope.current.tmpNewEvenement.destinataires.length && angular.isArray(newVal) && angular.isArray(oldVal) && oldVal.length>newVal.length) {
					 var destDelete = [];
					 // Parcour l'ancien tableau des destinataires
					 for(var j = 0; j < oldVal.length; j++){
					 var oldDest = oldVal[j];
					 if(oldDest.hasOwnProperty('destinataire')) {
					 var exist = false;
					 // Parcour le nouveau tableau des destinataires
					 for(var i = 0; i < newVal.length; i++){
					 var newDest = newVal[i];
					 if(newDest.hasOwnProperty('destinataire') && newDest.destinataire === oldDest.destinataire){
					 exist = true;
					 }
					 }
					 if(!exist) {
					 destDelete.push(oldDest);
					 }
					 }
					 }
					 // Si il y a des destinataire delete
					 if(destDelete.length){
					 // Parcour les destinataires delete
					 for(var k = 0; k < destDelete.length; k++){
					 // Parcour les destinataires temporaires
					 for(var l = 0; l < $rootScope.current.tmpNewEvenement.destinataires.length; l++){
					 // Si le destinataire delete est dans le tableau temporaire
					 if(destDelete[k].destinataire === $rootScope.current.tmpNewEvenement.destinataires[l].destinataire){
					 $rootScope.current.tmpNewEvenement.destinataires.splice(l,1);
					 }
					 }
					 }
					 }
					 }*/


					/*
					 console.log(ctrl.to);
					 console.log(newVal);
					 */

					if(angular.isArray(newVal)){
						/*
						 for(var j = 0; j < newVal.length; j++){
						 if(!newVal[j].isModel){
						 newVal[j] = new Groupe(newVal[j]);
						 }
						 }*/
						// Recupère la liste des destinataires qui ne sont pas déjà présent
						//vm.contacts = AnnuaireService.updateListeContact(newVal);
						setDestinataires(newVal);
					}
				});
			}

			//console.log(ctrl.itemContenu);

			/**
			 * Fonciton qui permet de determiner si l'on peux ajouter des destinataires
			 * @returns {boolean}
			 */
			vm.canAdd= function(){
				var retour = true;
				if(ctrl.limit===ctrl.itemContenu[ctrl.to].length){
					retour = false;
				}else{
					if(ctrl.allowAdd==='true' || ctrl.allowAdd===true){
						retour = true;
					}else{
						retour = false;
					}
				}
				return retour;
			};

			/**
			 * Fonction qui permet de determiner si l'on peux supprimer des destinataires
			 * @returns {boolean}
			 */
			ctrl.canDelete = function(){
				var retour = false;

				// Si c'est un contenu
				if(ctrl.itemContenu.isContenu){
					// Si pas d'idContenu
					if(!ctrl.itemContenu.idContenu){
						retour = true;
					}
				}

				// Si allow-delete forcé à true
				if(ctrl.allowDelete === true){
					retour = true;
				}

				return retour;
			};


			var createObjDestinataire = function(dest){
				var obj = {
					libelle: dest.libelle,
					dieze: dest.dieze,
					isMail: dest.boolMail,
					nouveau: true
				};
				// Type "collab" ou "perso"
				if(dest.type){
					obj.type = dest.type;
				}

				// Groupe Collab
				if(dest.idGroupe){
					obj.idGroupe = dest.idGroupe;
				}
				if(dest.user){
					obj.user = dest.user;
				}
				if(dest.fiche){
					obj.fiche = dest.fiche;
				}

				// Perso ICS
				if(dest.portefeuille){
					obj.portefeuille = dest.portefeuille;
				}
				if(dest.noperso){
					obj.noperso = dest.noperso;
				}
				if(dest.perso){
					obj.perso = dest.perso;
				}
				if(dest.tagsGenerate){
					obj.tagsGenerate = dest.tagsGenerate;
				}

				if(dest.type === 'collab'){
					obj = new Groupe(obj);
				}

				if (ctrl.onlyFournisseur === 'true') {
					var persoIcs = false;
					if(dest.hasOwnProperty('perso')) {
						persoIcs = {};
						persoIcs.codemetier = dest.perso.metier;
						persoIcs.type = 'F';
						persoIcs.numero = dest.perso.numero;
						persoIcs.libelle = dest.perso.getNom();
						persoIcs.noperso = dest.perso.noperso;
						if(dest.hasOwnProperty('portefeuille')){
							persoIcs.portefeuille = dest.portefeuille;
						}
					}

					obj = new ProxyDestinataire(dest);
					if(dest.boolMail && !_.isNil(dest.libelle) && _.isNil(dest.email))
						obj.setEmail(dest.libelle);

					if(persoIcs)
						obj.setPersoICS(persoIcs);
				}
				//console.log(obj);
				return obj;
			};

			var addDestinataires = function(arrayDest){

				ctrl.loadingDestinataire = true;
				//console.log(arrayDest);

				var deferred = $q.defer();

				var arrayActuelDestinataires = ctrl.itemContenu[ctrl.to];


				//console.log('arrayActuelDestinataires',arrayActuelDestinataires);

				var exist;
				var arrayNewDestinataires = [];	// array des nouveaux destinataires
				//var arrayDestinatairesDejaPresent = [];	// array des destinataires voulant être ajouté mais étant déjà présent

				// Si c'est un Array
				if(angular.isArray(arrayDest)){
					for(var i = 0; i < arrayDest.length; i++){
						var dest = arrayDest[i];

						//exist = UtilsService.where(arrayActuelDestinataires,{'libelle':dest.libelle,'dieze':dest.dieze});
						//if(!exist.length){
						// Si sauvegarde sur le serveur
						if (ctrl.save) {
							arrayNewDestinataires.push(dest.destinataire);
						}
						else {
							arrayNewDestinataires.push(createObjDestinataire(dest));
						}
						/*
						 }/*else{
						 // Si sauvegarde sur le serveur
						 if (ctrl.save) {
						 arrayDestinatairesDejaPresent.push(dest.destinataire);
						 }else{
						 arrayDestinatairesDejaPresent.push(createObjDestinataire(dest));
						 }
						 }*/
					}

					//console.log('arrayNewDestinataires',arrayNewDestinataires);

				}else{
					exist = UtilsService.where(arrayActuelDestinataires,{'libelle':arrayDest.libelle,'dieze':arrayDest.dieze});
					if(!exist.length){
						// Si sauvegarde sur le serveur
						if (ctrl.save) {
							arrayNewDestinataires.push(arrayDest.destinataire);
						}else{

							arrayNewDestinataires.push(createObjDestinataire(arrayDest));
						}
					}/*else{
					 // Si sauvegarde sur le serveur
					 if (ctrl.save) {
					 arrayDestinatairesDejaPresent.push(arrayDest.destinataire);
					 }else{
					 arrayDestinatairesDejaPresent.push(createObjDestinataire(arrayDest));
					 }
					 }*/

				}



				if(arrayNewDestinataires.length){
					// Si sauvegarde sur le serveur
					if (ctrl.save) {

						if(ctrl.to === 'destinataires'){
							ctrl.itemContenu.addDestinataires(arrayNewDestinataires).then(function (groupes) {

								angular.forEach(groupes,function(groupe){
									arrayActuelDestinataires.push(groupe);
								});

								//vm.contacts = AnnuaireService.updateListeContact(arrayActuelDestinataires);

								ctrl.itemContenu.setDestinataires(arrayActuelDestinataires);


								deferred.resolve(groupes,arrayActuelDestinataires);

							}, function (error) {
								deferred.reject('Erreur lors de l\'ajout (' + error + ')');
							}).finally(function(){
								ctrl.loadingDestinataire = false;
							});
						}

					}else {

						if (angular.isArray(arrayActuelDestinataires)) {
							var n = 1;
							angular.forEach(arrayActuelDestinataires, function (destinataireObj,indexDest) {

								if(n===ctrl.limit){

									arrayActuelDestinataires.splice(indexDest, 1);

								}
								n++;
							});
						}

						angular.forEach(arrayNewDestinataires,function(dest){
							arrayActuelDestinataires.push(dest);
						});


						if(ctrl.to === 'destinataires') {
							ctrl.itemContenu.setDestinataires(arrayActuelDestinataires);

							// Initialise la liste des contacts
							//vm.contacts = AnnuaireService.updateListeContact(ctrl.itemContenu.getDestinataires());

						}
						else if(ctrl.to==='groupesConcernes'){
							/*
							 console.log(ctrl.itemContenu.groupesConcernes);
							 ctrl.itemContenu.setGroupesConcernes(arrayActuelDestinataires);
							 console.log(ctrl.itemContenu.groupesConcernes);*/
						}

						ctrl.loadingDestinataire = false;
						deferred.resolve(arrayNewDestinataires,arrayActuelDestinataires);
					}
				}else{
					ctrl.loadingDestinataire = false;
					//deferred.resolve(arrayDestinatairesDejaPresent,arrayActuelDestinataires);
					deferred.resolve([],arrayActuelDestinataires);
				}



				return deferred.promise;
			};

			/*
			 var addGroupesConcernes = function(arrayGroupe){

			 var deferred = $q.defer();

			 var arrayActuelGroupesConcernes = ctrl.itemContenu.getGroupesConcernes();

			 var arrayNewGroupesConcernes = [];

			 // Si c'est un Array
			 if(angular.isArray(arrayGroupe)){
			 angular.forEach(arrayGroupe,function(dest){
			 arrayNewGroupesConcernes.push(createObjDestinataire(dest));
			 });
			 }else{
			 arrayNewGroupesConcernes.push(createObjDestinataire(arrayDest));
			 }

			 if (angular.isArray(arrayActuelGroupesConcernes)) {
			 var n = 1;
			 angular.forEach(arrayActuelGroupesConcernes, function (destinataireObj,indexDest) {
			 if(n===ctrl.limit){
			 arrayActuelGroupesConcernes.splice(indexDest, 1);
			 }
			 n++;
			 });
			 }

			 angular.forEach(arrayNewGroupesConcernes,function(dest){
			 arrayActuelGroupesConcernes.push(dest);
			 });

			 ctrl.itemContenu.setGroupesConcernes(arrayActuelGroupesConcernes);

			 deferred.resolve(arrayNewGroupesConcernes,arrayActuelGroupesConcernes);

			 return deferred.promise;
			 };*/

			/**
			 * Fonction intermédiaire pour ajouter un destinataire, utilisé avec le popover pas avec l'annuaire
			 * @param scope (c'est le scope du template de la popup, permet d'acceder au fonction pour fermer la poppup)
			 */
			vm.add = function(form){


				//ctrl.loadingAddDestinataire = true;

				//console.log(form);

				if(ctrl.to==='destinataires'){
					ctrl.addDestinataire(form);
				}

				if(ctrl.to==='assigneA'){
					ctrl.assigneA();
				}

				if(ctrl.to==='assignePar'){
					ctrl.assignePar();
				}
			};


			/*
			 ctrl.assigneA = function() {
			 vm.error = false;

			 var newObjDestinataire = {
			 libelle: vm.newDest.libelle,
			 dieze: vm.newDest.dieze
			 };
			 // Set la variable "assigneA" avec l'objet complet du destinataire
			 ctrl.itemContenu.setAssigneA(newObjDestinataire);

			 vm.nouveauDestinataire = '';

			 // Si il y a une fonction en callback
			 if(ctrl.callback){
			 ctrl.callback();
			 }
			 //ctrl.loadingAddDestinataire = false;
			 };

			 ctrl.assignePar = function() {

			 vm.error = false;

			 var newObjDestinataire = {
			 libelle: vm.newDest.libelle,
			 dieze: vm.newDest.dieze
			 };
			 // Set la variable "assignePar" avec l'objet complet du destinataire
			 ctrl.itemContenu.setAssignePar(newObjDestinataire);

			 vm.nouveauDestinataire = '';

			 // Si il y a une fonction en callback
			 if(ctrl.callback){
			 ctrl.callback();
			 }

			 //ctrl.loadingAddDestinataire = false;

			 };
			 */

			ctrl.addDestinataire = function(form) {

				vm.nouveauDestinataire = '';
				vm.error = false;
				vm.errorDestinataire = '';

				addDestinataires(vm.newDest).then(function(newGroupes,allGroupes){


					if(form){
						// Ferme la popup
						$scope.$broadcast('togglePopoverDestinataire',false,form);
					}

					//ctrl.loadingAddDestinataire = false;

					vm.onSuccessAddDestinataire(newGroupes,allGroupes);

					/*
					 if(ctrl.fctOnSuccessAdd){
					 ctrl.fctOnSuccessAdd()(newGroupes,allGroupes);
					 }*/

				},function(msg){

					form.$show();
					vm.errorDestinataire = msg;
					//ctrl.$broadcast('errorDestinataire',form,msg);
					//ctrl.loadingAddDestinataire = false;
				}).finally(function(){
					vm.loadingPopupDestinataire = false;
				});
			};


			vm.openRechercheAvance = function(){

				var obj = {
					stringTo: ctrl.to,
					currentModelEvenement: ctrl.currentModelEvenement,
					limitDestinataire: ctrl.limit,
					defautTypeAnnuaire: ctrl.defautTypeAnnuaire,
					onlyFournisseur: ctrl.onlyFournisseur,
					allCollaborateur: ctrl.allCollaborateur,
					infosEvent: {
						displayContratsLinks: true,
						numMandat: ctrl.numMandat,
						numImmeuble: ctrl.numImmeuble,
						portefeuille: ctrl.portefeuille
					}

				};

				AnnuaireService.openAnnuaire(obj).then(function(objRetour){

					ctrl.beforeAdd({obj: objRetour});

					if(objRetour.arrayContacts.length){
						//if(ctrl.to==='destinataires'){
						addDestinataires(objRetour.arrayContacts)
							.then(function(newGroupes,allGroupes){
								vm.onSuccessAddDestinataire(newGroupes,allGroupes,objRetour.othersObj);
							})
							.catch(function(msg){
								ctrl.onError({msg: msg});
							});
						/*}
						 else if(ctrl.to==='groupesConcernes'){
						 addGroupesConcernes(arrayDestinataires).then(function(newGroupes,allGroupes){
						 vm.onSuccessAddDestinataire(newGroupes,allGroupes);
						 });
						 }*/

					}
				});
				/*
				 var modalInstance = $uibModal.open({
				 animation: true,

				 templateUrl: '/src/annuaire/modal-annuaire.html',
				 controller: 'ModalAnnuaireCtrl',
				 controllerAs: 'modalannuairectrl',
				 size: 'full',
				 windowClass: 'modal-collabore modal-collabore-full modal-annuaire-collab',
				 backdrop: 'static',
				 resolve: {
				 limitDestinataire: function() {
				 return ctrl.limit;
				 },
				 stringTo: function(){
				 return ctrl.to;
				 },
				 currentModelEvenement: function(){
				 return ctrl.currentModelEvenement;
				 },
				 defautTypeAnnuaire: function(){
				 return ctrl.defautTypeAnnuaire;
				 }
				 }
				 });

				 modalInstance.opened.then(function() {
				 //vm.popoverDestinataireIsOpen = false;
				 $scope.$broadcast('closePopoverDestinataire');
				 });

				 modalInstance.result.then(function(objRetour) {

				 if(objRetour.arrayContacts.length){
				 addDestinataires(objRetour.arrayContacts).then(function(newGroupes,allGroupes){
				 vm.onSuccessAddDestinataire(newGroupes,allGroupes,objRetour.othersObj);
				 });

				 }
				 }, function () {
				 //console.log('Modal dismissed at: ' + new Date());
				 });*/
			};
			/**
			 * Supprime un destinataire de la liste des destinataires
			 * @param {Integer} index - L'index du destinaitre à supprimer du tableau
			 * @returns {void}
			 */
			/*
			 ctrl.deleteDestinataire = function(index){


			 var destinataires = ctrl.itemContenu.getDestinataires();
			 destinataires.splice(index, 1);
			 ctrl.itemContenu.setDestinataires(destinataires);
			 // Initialise la liste des contacts
			 //ctrl.updateListeContact();
			 vm.contacts = AnnuaireService.updateListeContact(destinataires);
			 console.log('?');
			 };*/



			/**
			 * Lors de l'ajout d'un ou plusieur destinataires
			 * @param destinataires
			 */
			vm.onSuccessAddDestinataire = function(newDestinataires,allGroupes,othersObj){

				if(ctrl.to === 'destinataires') {
					var array = [];
					if(angular.isArray(newDestinataires)){
						array = newDestinataires;
					}else{
						array.push(newDestinataires);
					}

					angular.forEach(array,function(destinataire){
						/*
						 var exist = [];
						 if(angular.isString(destinataire)){

						 }else if(angular.isObject(destinataire)){
						 var searchRemove = {'libelle':destinataire.libelle, 'dieze': destinataire.dieze};
						 if(destinataire.hasOwnProperty('idGroupe')){
						 searchRemove = {'idGroupe':destinataire.idGroupe};
						 }
						 exist  = UtilsService.where(ctrl.itemContenu[ctrl.to],searchRemove);
						 }


						 if(exist.length===0){
						 ctrl.destinataires.push(new Groupe(destinataire));
						 }*/

						ctrl.destinataires.push(new Groupe(destinataire));
					});


					// Initialise la liste des contacts
					//vm.contacts = AnnuaireService.updateListeContact(ctrl.destinataires);
				}


				ctrl.afterAdd({
					newDestinataires: newDestinataires,
					othersObj: othersObj
				});

				if(ctrl.fctOnAdd){
					ctrl.fctOnAdd()(newDestinataires,othersObj);
				}
				//console.log(ctrl.destinataires);

				setDestinataires(ctrl.destinataires,true);
			};

            function onDeleteDestinataire(obj) {
                var destinataire = null;
                if(angular.isObject(obj)) {
                    if (obj.hasOwnProperty('listeUsers')) {
                        ctrl.itemContenu[ctrl.to] = obj.listeUsers;
                    }
                    if(obj.hasOwnProperty('groupe')) {
                        destinataire = obj.groupe;
                    }
                }


                if(angular.isObject(destinataire)){
                    var searchRemove = {'libelle':destinataire.libelle, 'dieze': destinataire.dieze};
                    if(destinataire.hasOwnProperty('idGroupe'))
                    	searchRemove = {'idGroupe':destinataire.idGroupe};

                    else if(destinataire.hasOwnProperty('email'))
                        searchRemove = {'email':destinataire.email};

                    ctrl.destinataires  = UtilsService.removeWith(ctrl.destinataires,searchRemove);
                }

                setDestinataires(ctrl.destinataires,true);

                if(ctrl.fctOnDelete){
                    ctrl.fctOnDelete({destinataire: destinataire});
               	}
            }

			ctrl.close = function(){
				ctrl.openned = false;
			};

			/*
			 ctrl.$watchCollection('itemContenu.destinataires',function(newVal,oldVal){
			 if(angular.isDefined(newVal)){
			 ctrl.destinataires  = UtilsService.removeWith(newVal,{'idGroupe':UserService.getGroupe().getIdGroupe()});
			 getAutreDestinataires(newVal);

			 if(ctrl.autresDestinataires.length && (ctrl.nbDisplayDestinataires || ctrl.nbDisplayDestinataires === 0)){
			 if(newVal.length>oldVal.length){
			 vm.openned = true;
			 }
			 }
			 }
			 });*/

			/*
			 ctrl.toggle = function(){
			 vm.openned = !vm.openned;
			 };

			 */
		}]
};

angular.module('collaboreApp').component('destinataires',DestinatairesComponent);
