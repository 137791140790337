'use strict';

/**
 * @ngdoc directive
 * @name collaboreApp.directive:showWhenConnected
 * @description
 * # showWhenConnected
 */

var showWhenConnected = function (UserService) {
	return {
		restrict: 'A',
		link: function (scope, element) {
			var showIfConnected = function() {
				if(UserService.isConnected()) {
					angular.element(element).removeClass('hidden');
				} else {
					angular.element(element).addClass('hidden');
				}
			};

			showIfConnected();
			scope.$on('connectionStateChanged', showIfConnected);
		}
	};
};

angular
	.module('collaboreApp')
	.directive('showWhenConnected',[
		'UserService',
		showWhenConnected
	]);
