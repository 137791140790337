(function() {

  'use strict';

  /**
   * @desc Composant permetant d'aider l'utilisateur à cloturer des anciens dossier
   * @example <wizard-cloture></wizard-cloture>
   */
  angular
    .module('collaboreApp')
    .component('wizardCloture',{
      templateUrl: 'app/components/wizard-cloture/wizard-cloture.component.html',
      bindings : {
        onEmit: '&',            // Permet de notifier le composant parent

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<'
      },
      controllerAs: 'wizardcloturectrl',
      controller: WizardClotureController
    });

  /*@ngInject*/
  function WizardClotureController(COLLAB_CONF, $timeout, TopicStatesService, TopicService, ModalsService, UtilsService){

    var _this = this;
    _this.loading = false;

    _this.currentStep = 1;

    _this.topicStates = {
      list: [],
      nb: 0,
      total: 0,
      limit: 10,
      page: 1,
      nbSelected: 0,
      allSelected: false,
      allSelectedInView: false,
      filtre: {
        search: null,
        searchTmp: null,
        actif: false,
        filteredListView: [],     // Liste filtré par le orderBy et limitTo
        filteredList: [],         // Liste filtré par le filter
        nbSelectedInFilteredList: 0,  // Nb dossier sélectionné dans la liste filtré
        inputSearchFocus: false,
        sens: false,
        order: 'topic.dateCreation',

        /**
         * Lors de la frappe dans le filtre libre
         * @param event
         * @param objetAssocFromListPortefeuille
         */
        onKeyUpFiltreLibre: function(event) {
          // Si tape sur "Entrée"
          if (event.keyCode === 13) {
            // Stop le formulaire
            event.preventDefault();
            this.execFiltre();
          }
        },

        onClickFiltre: function(){
          this.execFiltre();
        },

        execFiltre: function(){

          this.actif = (this.searchTmp !== null && this.searchTmp !== "");
          if(this.searchTmp !== null && this.searchTmp !== "") this.search = angular.copy(this.searchTmp);

          initCheckboxSelectedAllInListView();
          countNbSelectedInFilteredList();
        },

        onClickClearFiltre: function(){
          this.search = null;
          this.search = null;
          this.actif = false;
        },

        changeTri: function(tri){
          if (this.order !== tri) {
            this.order = tri;
            this.sens = true;
          }
          else this.sens = !(this.sens);
        },
      },
    };

    _this.channels = [];
    _this.filtre = {
      nbJourSansActivite: 365,
      withEvent: 'true',
      whithoutRelanceAutoEnabled: true,
      idChannel: null
    };

    _this.onChangeWithEvent = onChangeWithEvent;
    _this.onClickSearch = onClickSearch;

    _this.onClickChangeStep = onClickChangeStep;
    _this.onClickSelectedAll = onClickSelectedAll;
    _this.onChangeCheckboxTopicAllSelectedInView = onChangeCheckboxTopicAllSelectedInView;
    _this.onChangeCheckboxTopicSelected = onChangeCheckboxTopicSelected;
    _this.onChangePage = onChangePage;

    _this.onClickExecClotureArchive = onClickExecClotureArchive;
    _this.onClickClose = onClickClose;

    _this.$onInit = function() {
      if(_this.resolve && _this.modalInstance) {
        // _this.listGroupe = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'listGroupe'));
      }
      init();
    };

    /**
     * Lors de changements depuis le parent
     * @param changes
     */
    _this.$onChanges = function (changes) {
      /*
      if(angular.isObject(changes)) {
        if(angular.isObject(changes.listeEmail) && !changes.listeEmail.isFirstChange()) {
        }
      }*/
    };

    _this.$onDestroy = function(){
    };

    /**
     * Initialisation
     */
    function init(){
      var groupe = UtilsService.getCurrentUser();
      _this.channels = groupe.getListeChannel();
      if(_this.channels.length){
        for(var c = 0; c < _this.channels.length; c++){
          if(_this.channels[c].isAccueil()){
            _this.filtre.idChannel = _this.channels[c].getIdChannel();
            break;
          }
        }
      }
    }

    /**
     * Lors du clique sur le bouton pour changer d'étape
     * @param num
     */
    function onClickChangeStep(num){
      _this.currentStep = num;
    }

    //--------------------
    // STEP 1 (Recherche)
    //--------------------

    var oldValues = {
      whithoutRelanceAutoEnabled: angular.copy(_this.filtre.whithoutRelanceAutoEnabled)
    };
    function onChangeWithEvent(){
      if(_this.filtre.withEvent === 'false') {
        _this.filtre.whithoutRelanceAutoEnabled = false;
      }
      else {
        _this.filtre.whithoutRelanceAutoEnabled = oldValues.whithoutRelanceAutoEnabled;
      }
    }

    /**
     * Lors du clique sur bouton pour rechercher les dossiers
     */
    function onClickSearch(){
      _this.loading = true;
      _this.topicStates.list = [];
      var params = {
        etat: 3,          // Tous les dossiers (archivés, coup de coeur etc...)
        cloture: false,
        returnOnlyTopic: true,
        returnOnlyClosable: true,
        limit: 0
      };
      params = _.merge(params, _this.filtre);

      if(params.withEvent === "all") delete params.withEvent;
      TopicStatesService
        .searchListTopicStates(params)
        .then(function(retourListTopicStates){

          if(!retourListTopicStates.success) throw new Error(retourListTopicStates.message);

          if(!_.isArray(retourListTopicStates.TopicStates)) throw new Error("La liste des topicStates n'est pas un Array");

          retourListTopicStates.TopicStates.map(function(topicState) {
            if(_.isObject(topicState.getTopic().getTopicInformations())) {
              if(!topicState.getTopic().getTopicInformations().getAllowCloture()) topicState.disabled = true;
            }
            if(!topicState.disabled) topicState.selected = true;
            return topicState;
          });

          _this.topicStates.list = retourListTopicStates.TopicStates;
          _this.topicStates.nb = retourListTopicStates.nb;
          _this.topicStates.total = retourListTopicStates.total;
        })
        .catch(function(err){
          ModalsService.alertErreur(err);
        })
        .finally(function(){
          _this.loading = false;
          _this.topicStates.page = 1;
          _this.currentStep = 2;
          initListResultats();
        });
    }

    //--------------------
    // STEP 2 (Résultats)
    //--------------------

    function initListResultats(){
      initCount();
      initCheckboxSelectedAllInListView();
    }

    function initCount(){
      countNbSelectedAll();
      countNbSelectedInFilteredList();
    }

    function initCheckboxSelectedAllInListView(){
      $timeout(function() {
        _this.topicStates.allSelectedInView = (countNbSelectedInView() === _this.topicStates.filtre.filteredListView.length);
      });
    }

    /**
     * Permet de calculer le nombre de dossiers selectionner parmis tous les dossiers
     */
    function countNbSelectedAll(){
      _this.topicStates.nbSelected = _this.topicStates.list.reduce(function (nbSelected, topicState) {
        if (topicState.selected) nbSelected++;
        return nbSelected;
      }, 0);
    }

    /**
     * Permet de calculer le nombre de dossiers selectionner parmis les dossiers filtrés
     */
    function countNbSelectedInFilteredList(){
      $timeout(function() {
        _this.topicStates.filtre.nbSelectedInFilteredList = _this.topicStates.filtre.filteredList.reduce(function (nbSelected, topicState) {
          if (topicState.selected) nbSelected++;
          return nbSelected;
        }, 0);
      });
    }

    /**
     * Permet de calculer le nombre de dossiers selectionner parmis les dossiers visibles
     */
    function countNbSelectedInView(){
      return _this.topicStates.filtre.filteredListView.reduce(function (nbSelected, topicState) {
        if (topicState.selected) nbSelected++;
        return nbSelected;
      }, 0);
    }

    /**
     * Permet de sélectionner/désélectionner tous les dossiers
     * @param val
     */
    function onClickSelectedAll(list, val){
      if(_.isArray(list)) {
        list.map(function(topicstate){
          topicstate.selected = angular.copy(val);
          return topicstate;
        });
        initListResultats();
      }
    }

    /**
     * Permet de cocher/décocher les item dans la vue actuelle
     */
    function onChangeCheckboxTopicAllSelectedInView(){
      _this.topicStates.filtre.filteredListView.map(function(topicstate){
        topicstate.selected = angular.copy(_this.topicStates.allSelectedInView);
        return topicstate;
      });
      initCount();
    }

    /**
     * Lors du clique sur la checkbox d'un dossier
     */
    function onChangeCheckboxTopicSelected(){
      initListResultats();
    }

    /**
     * Lors d'un changement de page
     */
    function onChangePage(){
      initCheckboxSelectedAllInListView();
    }

    /**
     * Permet de récupérer la liste des idTopic sélectionnés
     * @returns {*}
     */
    function getListIdTopicSelected(){
      return _this.topicStates.list.reduce(function (list, topicState) {
        if (topicState.selected) list.push(topicState.getTopic());
        return list;
      }, []);
    }

    /**
     * Lors du clique sur le bouton pour envoyer le message
     */
    function onClickExecClotureArchive(){
      var listIdTopic = getListIdTopicSelected();
      var textDossiers = "dossier sélectionné";
      if(listIdTopic.length > 1) textDossiers = "les dossiers sélectionnés";
      ModalsService
        .confirm("Clôturer/Archiver " + listIdTopic.length + " dossier(s)", "Voulez-vous vraiment clôturer/archiver <strong>"+listIdTopic.length+"</strong> " +textDossiers+" ?<br/>"+COLLAB_CONF.MSG_CONFIRMATION_CLOTURE_TOPIC_BY_CREATEUR, {type: 'warning'})
        .then(function (modal) {
          var commentaire = angular.element('#commentaireCloture').val();
          _this.loading = true;
          _this.nbTopicCloture = 0;
          modal.close();
          TopicService
            .clotureTopic(listIdTopic, commentaire)
            .then(function(topicstates){
              _this.nbTopicCloture = (_.isArray(topicstates)) ? topicstates.length : 0;
              _this.currentStep = 3;
            })
            .finally(function(){
              _this.loading = false;

            });
        });
      //emit();
    }

    /**
     * Si ouvert en mode Modal
     */
    function onClickClose(){
      _this.popoverIsOpen = false;
      if(_this.modalInstance) _this.dismiss({$value: 'cancel'});
      else emit({action: 'closeChooseGroupeParent'});
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      if(_this.modalInstance) _this.close({$value: objRetour});
      else _this.onEmit({obj: objRetour})
    }

  }
})();
