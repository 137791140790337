(function () {
  'use strict';

  /**
   * @desc Gestion des associations
   * @example <gestion-associations></gestion-associations>
   */

  angular
    .module('collaboreApp')
    .component('gestionAssociations', {
      templateUrl: 'app/parametrage/gestion-associations/gestion-associations.component.html',
      bindings: {
        adminVersion: '<',
        onEmit: '&',

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<'
      },
      controllerAs: 'gestionassocctrl',
      controller: GestionAssociationsController
    });

  /*@ngInject*/
  function GestionAssociationsController(NotificationsToastServices, UtilsService, ErreurCollabService) {

    var _this = this;
    _this.myGroupe = UtilsService.getCurrentUser();

    _this.$onInit = function() {


      if(_this.resolve && _this.modalInstance) {
        _this.adminVersion = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'adminVersion'));
      }

      if(_this.adminVersion){
        if(!_this.myGroupe.isAdmin()) _this.errorComponent = "Vous ne pouvez pas accéder à cette fonctionnalitée";
        else {
          _this.loadingPortefeuille = true;
          var GroupesAdminService = UtilsService.$injector.get('GroupesAdminService');
          GroupesAdminService.getAllPortefeuilles()
            .then(function(portefeuilles){
              _this.listPortefeuille = portefeuilles;
              init();
            })
            .finally(function(){
              _this.loadingPortefeuille = false;
            });
        }
      }
      else {
        _this.listPortefeuille = _this.myGroupe.getListePortefeuille();
        init();
      }
    };

    _this.$onChanges = function(changes) {
    };

    _this.$onDestroy = function(){
    };

    function init(){
      if(_.isArray(_this.listPortefeuille) && _this.listPortefeuille.length === 1){
        _this.currentSelectedPortefeuille = _this.listPortefeuille[0];
        _this.selectedTab = 0;
      }
    }

    function onSelectTab(indexTab){
      _this.selectedTab = indexTab;
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      if(_this.modalInstance) _this.close({$value: objRetour});
      else _this.onEmit({obj: objRetour});
    }

    /**
     * Si ouvert en mode Modal
     */
    function onClickClose(){
      if(_this.modalInstance) _this.dismiss({$value: 'cancel'});
      else emit({action: 'closeReply', contenu: _this.contenuParent});
    }
  }
})();
