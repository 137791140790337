(function () {
  'use strict';

  /**
   * @desc Composant d'avancement d'un contenu
   * @example <avancement-contenu></avancement-contenu>
   */

  angular
    .module('collaboreApp')
    .component('avancementContenu', {
      templateUrl: 'app/topics/components/avancement-contenu/avancement-contenu.component.html',
      bindings: {
        topicIsBlocked: '<',
        contenu: '<',
        mode: '<',
        displayType: '<',
        displayDraggableDoc: '<',
        hideBtn: '<',
        viewMode: '@',
        onEmit: '&'
      },
      controllerAs: 'avancementcontenuctrl',
      controller: AvancementContenuController
    });

  /*@ngInject*/
  function AvancementContenuController($q, $rootScope, TopicManagerService, ModalsService, ContenuService, UtilsService, DocumentManagerService) {

    var _this = this;

    _this.addDocumentsOpened = false;
    _this.getAvancement = getAvancement;
    _this.onClickRetry = onClickRetry;
    //_this.onClickSelectDemande = onClickSelectDemande;
    _this.onClickOpenDocument = onClickOpenDocument;
    _this.onClickDownloadDocument = onClickDownloadDocument;
    _this.onClickDeleteDocumentDevis = onClickDeleteDocumentDevis;
    //_this.onClickOpenTraiteDemande = onClickOpenTraiteDemande;
    //_this.onClickOpenSigne = onClickOpenSigne;
    _this.onClickOpenEditDemande = onClickOpenEditDemande;

    _this.onClickAddDevis = onClickAddDevis;
    _this.onClickAddFacture = onClickAddFacture;
    _this.onClickAddContrat = onClickAddContrat;
    _this.onClickDeleteDocumentFacture = onClickDeleteDocumentFacture;


    _this.$onInit = function () {
      init(_this.contenu);

      _this.hasTopicsRef = TopicManagerService.hasListTopicRefFromCurrentTopic();
      if(_.isNil(_this.topicIsBlocked)) _this.topicIsBlocked = TopicManagerService.isBlocked;

      _this.target = '_blank';
      if(UtilsService.isTopicDetail()) {
        _this.target = '_self';
      }
    };

    _this.$onChanges = function(changes){
      if(_.isObject(changes.viewMode)){
        _this.viewMode = changes.viewMode.currentValue;
        if(_this.viewMode !== 'fournisseur' && _this.viewMode !== 'extranet-fournisseur') _this.viewMode = false;
      }

      if(_.isObject(changes.contenu) && !changes.contenu.isFirstChange()){
        _this.contenu = changes.contenu.currentValue;
        init(changes.contenu.currentValue);
      }
      if(_.isObject(changes.mode)){
        //console.log(changes.mode);
        _this.mode = changes.mode.currentValue;
        initMode();
      }
    }

    /**
     * Initialisation
     * @param contenu
     */
    function init(contenu){
      _this.sendToComfactEnable = UtilsService.getParametrageComfact('allowSendToComfactfacture');
      _this.validDevisWhenSignIt = UtilsService.getParametrage('validDevisWhenSignIt');

      if(_.isObject(contenu)) {

        // Si contenu pas cloturé
        if(!_this.contenu.isCloture()){

          // Si demande de devis
          if(_this.contenu.isContenuDevis()) {

            /*
            // Si la demande a un devis en attente d'acceptation
            if(_this.contenu.hasDevis() && _this.contenu.isWaiting()) {
                // Si on doit valider le devis lors de sa signature on passe l'étape de la validation
                if(_this.validDevisWhenSignIt) {

                }
            }
            */
          }

          // Si demande de contrat
          if(_this.contenu.isContenuContrat()) {
            // Si il y a des contrat fournisseur signe mais la demande est toujours en attente (pas accepté par refusé par l'agence)
            if(_this.contenu.hasContratFournisseurSigne() && _this.contenu.isWaiting()){
              _this.nextDocumentWaitingFromListDocumentContratFournisseurSigne = _this.contenu.getNextDocumentWaitingFromListDocumentContratFournisseurSigne()
            }

            // Si la demande est acceptée et que tous les documents accépté ne sont pas encore signé par l'agence
            if(_this.contenu.isAccepte() && !_this.contenu.isSignedByAgence()){
              _this.nextDocumentAcceptedFromListDocumentContratFournisseurSigne = _this.contenu.getNextDocumentAcceptedFromListDocumentContratFournisseurSigne()
            }
          }
        }
      }
    }

    function initMode(){
      if(_.isNil(_this.mode)) {
        _this.mode = 'full';
      }
    }

    /**
     * Lors du clique sur le bouton pour réessayer la sauvegarde de la demande
     */
    function onClickRetry() {
      if(_this.contenu.isError()) {
        _this.contenu.loadingRetry = true;
        _this.contenu.retryDevis()
          .then(function(){
            _this.contenu.setEtat(0);
            emit({action: 'updateEtat', etat: 0})
          })
          .catch(function(msg){
            ModalsService.alertErreur(msg);
          })
          .finally(function(){
            _this.contenu.loadingRetry = false;
          })
      }
    }

    /**
     * Permet d'ouvrir une demande
     * @param doc
     */
    /*
    function onClickSelectDemande(event) {
        if(UtilsService.isTopicDetail()) {
            event.preventDefault();
            emit({action: 'selectDemande'});
        }
    }*/

    /**
     * Permet d'ouvrir un document
     * @param doc
     */
    function onClickOpenDocument(doc, event) {

      if(UtilsService.isTopicDetail()) {
        event.preventDefault();
        emit({action: 'openDocument', document: doc});
        /*
        if(_this.vueFournisseur) {
          var params = {
            documents: doc
          };

          ModalsService
            .modalComponent('documentsViewer', params, {size: 'lg'})
            .then(function onSuccessDocumentsViewer(obj){
              console.log(obj);
            });
        }
        else {
          emit({action: 'openDocument', document: doc});
        }*/
      }
    }

    /**
     * Permet de télécharger un document
     * @param doc
     */
    function onClickDownloadDocument(doc) {
      if(_.isObject(doc) && doc.guid) {
        DocumentManagerService.downloadDocument(doc.guid, _this.contenu.idContenu);
      }
    }

    /**
     * Permet de supprimer un document devis
     * @param doc
     */
    function onClickDeleteDocumentDevis(doc) {
      ModalsService.confirm('Suppression du devis', 'Voulez-vous vraiment supprimer le devis "'+doc.getNomFichier()+'" ?').then(function (modal) {

        _this.contenu
          .deleteDocumentDevis()
          .then(function(){
            $rootScope.$broadcast('refreshDocuments');
            emit({action: 'addOrUpdateDemande'});
          })
          .catch(function (msg) {
            ModalsService.alertErreur(msg);
          })
          .finally(function(){
            modal.close();
          });
      });
    }

    /**
     * Permet de supprimer un document facture
     * @param doc
     */
    function onClickDeleteDocumentFacture(doc) {
      ModalsService.confirm('Suppression de la facture', 'Voulez-vous vraiment supprimer la facture "'+doc.getNomFichier()+'" ?')
        .then(function (modal) {

        _this.contenu
          .deleteDocumentFromFacture(doc.getGuid())
          .then(function(){
            $rootScope.$broadcast('refreshDocuments');
            emit({action: 'addOrUpdateDemande'});
          })
          .catch(function (msg) {
            ModalsService.alertErreur(msg);
          })
          .finally(function(){
            modal.close();
          });
      });
    }

    /**
     * Lors du clique sur le bouton pour ouvrir la demande sur la fenetre de validation/refus
     */
    /*function onClickOpenTraiteDemande(event){
        if(UtilsService.isTopicDetail()) {
            event.preventDefault();
            emit({action: 'openTraiteDemande'});
        }
    }*/

    /**
     * Lors du clique sur le bouton pour ouvrir la demande sur la fenetre de signature
     */
    /*
    function onClickOpenSigne(event){
        if(UtilsService.isTopicDetail()) {
            event.preventDefault();
            emit({action: 'openSigne'});
        }
    }*/

    /**
     * Lors du clique sur le bouton pour modifier la demande
     */
    function onClickOpenEditDemande(event){
      if(UtilsService.isTopicDetail()) {
        event.preventDefault();
        emit({action: 'openEditDemande'});
      }
    }

    function onClickAddDevis(event){
      emit({action: "toggleDisplayAddDocumentsDevis"});
      /*
      var params = {
        contenu: _this.contenu,
        action: 'addDevis'
      };

      ModalsService
        .modalComponent('addDocuments', params, {size: 'lg', backdrop: 'static'})
        .then(function onSuccess(obj){
          if(_.isObject(obj)) emit({action: obj.action, contenu: obj.contenu});
        });*/
    }

    function onClickAddFacture(event){
      emit({action: "toggleDisplayAddDocumentFacture"});
    }

    function onClickAddContrat(event){
      emit({action: "toggleDisplayAddDocumentContrat"});
    }

    /**
     * Permet de récupérer l'état d'avancement de la demande
     * @param champ
     * @returns {*}
     */
    function getAvancement(champ){
      var type = 'primary';
      var value = 0;

      // Si pas encore de devis
      if(!_this.contenu.hasDevis()) value = 0;

      // Si il y a un devis
      else if(_this.contenu.hasDevis()) {

        // Si devis en attente d'acceptation ou refus
        if (_this.contenu.isWaiting()) {
          value = 25;

          // Si il y a une facture ajouté par l'agence
          if(_this.contenu.hasFacture()) {
            type = 'warning';
            value = 75;
          }
        }
        else {

          // Si devis accepte
          if(_this.contenu.isAccepte()) {

            type = 'success';

            // Si devis signé
            if(_this.contenu.isSigne()) {

              // Si il y a une facture
              if(_this.contenu.hasFacture()) value = 100;

              // Si pas de facture
              else value = 75;
            }
            // Si devis pas signé
            else value = 50;
            // TODO :  si le devis n'est pas signé et qu'il y a une facture il y a un problème
          }
          else if(_this.contenu.isRefuse()) {
            type = 'danger';
            value = 100;
          }
        }
      }
      if(champ === 'type') return type;

      return value;
    }

    /**
     * Permet d'emit une information vers le parent
     * @param valObj
     */
    function emit(valObj) {
      var object = {obj: {}};
      object.obj = _.merge(object.obj, valObj);
      // Si pas de "contenu" dans obj
      if(!object.obj.hasOwnProperty('contenu')) {
        object.obj.contenu = angular.copy(_this.contenu)
      }
      _this.onEmit(object);
    }
  }
})();
