(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.MainService
   * @description
   * # MainService
   * Service in the collaboreApp.
   */

  angular
      .module('collaboreApp')
      .service('ErreurCollabService',
          ErreurCollabServiceController
      );

  /** @ngInject */
  function ErreurCollabServiceController($rootScope, $q, $log, sweet) {
    var ctrl = this;

    ctrl.onResponseError = onResponseError;
    ctrl.alert = alert;
    ctrl.erreurServlet = erreurServlet;
    ctrl.ifResult = ifResult;

    ctrl.log = log;
    ctrl.logInfo = ctrl.info = logInfo;
    ctrl.logWarning = ctrl.warning = logWarning;
    ctrl.logErreur = ctrl.erreur = ctrl.error = logErreur;
    ctrl.logDebug = ctrl.debug = logDebug;

    /**
     * Alerte pour les response error
     * @param rejection
     */
    function onResponseError(rejection){

      if(_.isObject(rejection)){
        var servlet = null;
        var statusText = rejection.statusText;
        var title = rejection.title;
        var msg = null;
        var infos = [];

        var data = null;
        if(_.isObject(rejection.data))          data = rejection.data;
        else if(_.isObject(rejection.response)) data = rejection.response;
        else if(_.isObject(rejection.config))   data = rejection.config.data;

        if(_.isObject(data)){

          if(_.isNil(data.success)) return;

          var config = null;
          if(_.isObject(rejection.config))        config = rejection.config;
          else if(_.isObject(rejection.fileItem)) config = rejection.fileItem;

          if(_.isObject(config)){

            if(_.isNil(statusText) && _.isObject(config._xhr)) statusText = config._xhr.statusText;

            if(!_.isNil(config.method)) infos.push("<tr><th style='width:80px'>Method</th><td>" + config.method + "</td></tr>");
            if(!_.isNil(statusText)) infos.push("<tr><th style='width:80px'>StatusText</th><td>" + statusText + "</td></tr>");

            if(!_.isNil(config.url)){
              infos.push("<tr><th style='width:80px'>Url</th><td>" + config.url + "</td></tr>");
              var tabUri = config.url.split("/");
              servlet = tabUri[tabUri.length-1];
            }

            if(_.isObject(config.data))       infos.push("<tr><th style='width:80px'>Data</th><td>" + angular.toJson(config.data) + "</td></tr>");
            if(_.isObject(config.formData))   infos.push("<tr><th style='width:80px'>FormData</th><td>" + angular.toJson(config.formData) + "</td></tr>");
            if(_.isObject(config.params))     infos.push("<tr><th style='width:80px'>Params</th><td>" + angular.toJson(config.params) + "</td></tr>");
          }

          if(_.isNil(title)) title = statusText;

          if(!_.isNil(data.message)) {
            msg = data.message;
            if(_.isNil(title)) title = data.message;
          }

          if(!infos.length && !_.isNil(servlet)) msg = "<b>" + servlet + "</b><br />" + msg;

          if(infos.length || !_.isNil(data.stackTrace)){
            msg += "<br /><div class='well well-sm text-left h5' style='max-height:300px;overflow: auto'>";
            if(infos.length) msg += "<table><tbody>" + infos.join("") + "</tbody></table>";
            if(!_.isNil(data.stackTrace)) msg += "<br /><div style='color:#f00'>StackTrace:<br/>" + data.stackTrace + "</div>";
            msg += "</div>";
          }
        }

        if(_.isNil(title)) title = (!_.isNil(statusText)) ? statusText : "Erreur";

        alert(title, msg, 'collabore-alerte-response');
      }
      else alert();

      $log.error(rejection);
    }

    /**
     * Alerte avec une popup d'une erreur
     * 0 argument alors message prédéfinis
     * 1 argument = message
     * 2 arguments = titre,message
     */
    function alert(){

      var titre = 'Oops...', message = 'Erreur !', classCss = null;
      if(arguments.length){
        switch(arguments.length){
            // Seulement le message
          case 1:
            message = arguments[0];
            break;
            // Titre et message
          case 2:
            titre = arguments[0];
            message = arguments[1];
            break;
          case 3:
            titre = arguments[0];
            message = arguments[1];
            classCss = arguments[2];
            break;
          default:
        }
      }
      var params = {
        title: titre,
        text: message,
        type: 'error',
        html: true,
        showCancelButton: false,
        closeOnConfirm: true
      };
      if(!_.isNil(classCss)) params.customClass = 'collabore-alerte '+classCss;

      sweet.show(params);
    }

    /**
     * Log dans la console l'erreur lors de l'appel du servlet en ajax
     * @param error (header http)
     * @param msg (String)
     * @param deferred (Promise) Facultatif
     */
    function erreurServlet(error,msg,deferred){
      if(!deferred){
        deferred = $q.defer();
      }
      var msgErreur = 'Erreur (' + error.status + ') : ' + error.statusText;
      deferred.reject(msg+' :<br /><strong>' + msgErreur+'</strong>');
      $rootScope.$broadcast('dislayError', msgErreur);
      $log.error(error);
      $log.error(msg);
      $log.error(msgErreur);
    }


    function ifResult(json){
      var retour = false;
      if(json.nb!=='0'){
        if(json.hasOwnProperty('Resultats')){
          if(json.Resultats[0].success){
            retour = true;
          }
        }else{
          retour = true;
        }
      }
      return retour;
    }

    function log(){
      if(arguments.length == 1){
        $log.log(arguments[0]);
      }
      else if(arguments.length == 2){
        $log.log(arguments[0], arguments[1]);
      }
      else {
        $log.log('Log');
      }
    }
    function logInfo(){
      if(arguments.length == 1){
        $log.info(arguments[0]);
      }
      else if(arguments.length == 2){
        $log.info(arguments[0], arguments[1]);
      }
      else {
        $log.info('Info');
      }
    }
    function logWarning(){
      if(arguments.length == 1){
        $log.warn('Attention : ' + arguments[0]);
      }
      else if(arguments.length == 2){
        $log.warn(arguments[0], arguments[1]);
      }
      else {
        $log.warn('Attention');
      }
    }
    function logErreur(){
      if(arguments.length == 1){
        $log.error('Erreur : ' + arguments[0]);
      }
      else if(arguments.length == 2){
        $log.error(arguments[0], arguments[1]);
      }
      else {
        $log.error('Erreur');
      }
    }
    function logDebug(){
      if(arguments.length == 1){
        $log.debug('Debug : ' + arguments[0]);
      }
      else if(arguments.length == 2){
        $log.debug(arguments[0], arguments[1]);
      }
      else {
        $log.debug('Debug');
      }
    }

  }

})();
