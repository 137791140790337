(function () {

    'use strict';

    /**
     * @desc Composant notification
     * @example <notification></notification>
     */
    angular
        .module('collaboreApp')
        .component('notification', {
            templateUrl: 'app/notification/notification.component.html',
            controllerAs: 'notificationctrl',
            bindings: {
                idTopic: '<',
                eventModifiable: '<',
                topicInformations: '<',
                mode: '@',                // 'inline' ou 'popover'
                popoverBtnClass: '@',
                popoverIconClass: '@',
                popoverTooltip: '@',
                popoverPlacement: '@',
                popoverClass: '@',
                popoverAppendToBody: '<'
            },
            controller: NotificationCtrl
        });

    /** @ngInject */
    function NotificationCtrl($scope, $state, NotificationService, UtilsService) {
        var ctrl = this;

        ctrl.loading = false;
        ctrl.listNotificationNonLu = [];
        ctrl.listNotificationLu = [];
        ctrl.onClickNotification = onClickNotification;
        ctrl.onMouseEnterNotification = onMouseEnterNotification;
        ctrl.onMouseLeaveNotification = onMouseLeaveNotification;

        ctrl.$onInit = function () {
            if(angular.isUndefined(ctrl.mode)) ctrl.mode = 'popover';
            if(angular.isUndefined(ctrl.popoverTooltip)) ctrl.popoverTooltip = 'Notifications';
            if(angular.isUndefined(ctrl.popoverPlacement)) ctrl.popoverPlacement = 'auto';
            if(angular.isUndefined(ctrl.popoverClass)) ctrl.popoverClass = 'popover-collab popover-lg';
            if(angular.isUndefined(ctrl.popoverAppendToBody)) ctrl.popoverAppendToBody = false;

            if(ctrl.mode === 'popover') {
                $scope.$watch('notificationctrl.popoverIsOpen', function(newVal){
                    if(newVal) init();
                });
            }
            else init();
        };

        function init() {
            if (ctrl.idTopic && ((ctrl.mode === 'popover' && ctrl.popoverIsOpen) || ctrl.mode === 'inline')) {
                ctrl.notifications = [];
                ctrl.loading = true;
                if(!angular.isObject(ctrl.topicInformations)) ctrl.topicInformations = {nbNotificationsNonLus: 0, nbNotificationsLus:0};
                NotificationService
                    .getNotificationsForIdtopic(ctrl.idTopic, 10, 10)
                    .then(function (notifications) {
                        ctrl.listNotificationNonLu = notifications.listNotificationNonLu;
                        ctrl.listNotificationLu = notifications.listNotificationLu;
                        ctrl.topicInformations.nbNotificationsLus += angular.copy(ctrl.topicInformations.nbNotificationsNonLus);
                        ctrl.topicInformations.nbNotificationsNonLus = 0;
                    })
                    .finally(function () {
                        ctrl.loading = false;
                    });
            }
        }

        function onClickNotification(notification) {
            var tab = {
                currentTab: 'fournisseurs',
                obj: {
                    action: 'selectDemande',
                    contenu: {
                        idContenu: notification.idContenu
                    }
                }
            };
            if(!$state.includes('topics.detail')) {
                $state.go('topics.detail',
                    {
                        topicId: notification.getIdTopic(),
                        objTab: tab
                    },
                    {
                        location: true,
                        reload: true,
                        inherit: false
                    }
                );
            }
            else {
                ctrl.popoverIsOpen = false;
                $scope.$emit('layoutTopicDetailOpenTab', tab);
                /*
                UtilsService.scrollToDevis(notification.getIdContenu());
                ctrl.popoverIsOpen = false;
                angular.element('#devis_' + notification.idContenu).removeClass('warning');
                */
            }
        }

        function onMouseEnterNotification(notification) {
            angular.element('#devis_' + notification.idContenu).addClass('warning');
        }

        function onMouseLeaveNotification(notification) {
            angular.element('#devis_' + notification.idContenu).removeClass('warning');
        }
    }
})();
