(function() {
  'use strict';

  /**
   * @desc Liste des demande de devis
   * @example <liste-demande-devis></liste-demande-devis>
   */

  angular
    .module('collaboreApp')
    .component('listeLigneDemandeFournisseur',{
      templateUrl: 'app/topics/evenement/liste-ligne-demande-fournisseur/liste-ligne-demande-fournisseur.component.html',
      bindings: {
        idTopic: '<',
        topicIsBlocked: '<',
        contenuEvenement: '<',
        noModalCommunication: '<',
        titreTopicRef: '<',
        condensedDisplay: '<',	// Permet de savoir si on doit faire un affichage plus condensé
        onEmit:'&'
      },
      controllerAs: 'listelignedemandefournisseur',
      controller: ListeLigneDemandeFournisseurController
    });

  /*@ngInject*/
  function ListeLigneDemandeFournisseurController(COLLAB_CONF, $log, $state, $rootScope, $timeout, UtilsService, TopicService, ModalsService, TagService, sweet, ContenuService, TopicManagerService){

    var ctrl = this;
    ctrl.notMobile = UtilsService.notMobile;
    ctrl.loading = true;
    ctrl.listDemandes = [];
    ctrl.addDocumentsOpened = {};
    ctrl.createEventOpened = {};
    ctrl.createEventWithDemandeOpened = {};
    ctrl.documentViewer = {};
    ctrl.isVu = isVu;
    ctrl.currentGroupeUserIsAgenceICS = currentGroupeUserIsAgenceICS; // Le groupe actuel est une agence ICS ?
    ctrl.openViewFacture = openViewFacture;
    ctrl.onClickOpenCommunication = onClickOpenCommunication;
    ctrl.onClickAjouterDemande = onClickAjouterDemande;
    ctrl.onClickVoirDemande = onClickVoirDemande;
    ctrl.onEmitFromMenuDemande = onEmitFromMenuDemande;

    ctrl.onClickSendTo = onClickSendTo;                                      // Lors du clique sur le bouton pour envoyer aux copro/CS
    ctrl.onClickSendSMS = onClickSendSMS;                                      // Lors du clique sur le bouton pour envoyer un SMS
    ctrl.onClickSendEmail = onClickSendEmail;                                  // Lors du clique sur le bouton pour envoyer un EMAIL
    ctrl.onClickRelancerDemande = onClickRelancerDemande;                                  // Lors du clique sur le bouton pour envoyer un EMAIL

    ctrl.onClickCreateEvent = onClickCreateEvent;                       // Lors du clique sur le bouton pour créer un événement depuis une demande (entre agences)
    ctrl.onClickRetry = onClickRetry;
    ctrl.onDropDocument = onDropDocument;

    ctrl.onEmitFromAvancementComponent = onEmitFromAvancementComponent;
    ctrl.onEmitFromAddDocumentsComponent = onEmitFromAddDocumentsComponent;
    ctrl.onEmitFromDocumentsViewerComponent = onEmitFromDocumentsViewerComponent;

    ctrl.$onInit = function(){
      if(_.isNil(ctrl.topicIsBlocked)) ctrl.topicIsBlocked = TopicManagerService.isBlocked;

      if(ctrl.contenuEvenement) {
        init(ctrl.contenuEvenement);
        ctrl.loading = false;
      }
      else if(ctrl.idTopic) {
        TopicService
          .getEvenementForIdTopic(ctrl.idTopic)
          .then(function(contenuEvenement){
            init(contenuEvenement);
          })
          .catch(function(msg){
            ModalsService.alertErreur(msg);
          })
          .finally(function() {
            ctrl.loading = false;
          });
      }
    };

    ctrl.$onChanges = function(changes) {
      if(_.isObject(changes.contenuEvenement) && !changes.contenuEvenement.isFirstChange()) {
        ctrl.contenuEvenement = changes.contenuEvenement.currentValue;
        init(ctrl.contenuEvenement);
      }
    };

    function init(contenuEvenement){
      if(!ctrl.contenuEvenement) ctrl.contenuEvenement = contenuEvenement;

      ctrl.infosEvenement = UtilsService.getInfosEvenement(ctrl.contenuEvenement);
      if(_.isObject(ctrl.infosEvenement) && _.isObject(ctrl.infosEvenement.portefeuille)) {
        ctrl.portefeuille = ctrl.infosEvenement.portefeuille;
      }
      /*
      var tagImmeuble = TagService.getTagForType(ctrl.tagsType, COLLAB_CONF.TAG_TYPE_IMMEUBLE);
      if(tagImmeuble) ctrl.retourModal.immeuble = tagImmeuble.getValeurIcs();

      var tagMandat = TagService.getTagForType(ctrl.tagsType, COLLAB_CONF.TAG_TYPE_MANDAT);
      if(tagMandat) ctrl.retourModal.mandat = tagMandat.getValeurIcs();

      var tagLot = TagService.getTagForType(ctrl.tagsType, COLLAB_CONF.TAG_TYPE_LOT);
      if(tagLot) ctrl.retourModal.lot = tagLot.getValeurIcs().substr(-4);

      var tagPortefeuille = TagService.getTagForType(ctrl.tagsType, COLLAB_CONF.TAG_TYPE_PORTEFEUILLE);
      if(tagPortefeuille && tagPortefeuille.getPortefeuille()) ctrl.idPortefeuille = tagPortefeuille.getPortefeuille().getIdPortefeuille()
      */

      ctrl.listDemandes = _.union(contenuEvenement.listeDevis, contenuEvenement.listeContrats);
      if(!ctrl.idTopic) ctrl.idTopic = contenuEvenement.idTopic;

      // Si il y a un tag "Propriétaire" alors c'est un événement de gestion
      if(_.isObject(ctrl.infosEvenement) && !_.isNil(ctrl.infosEvenement.isGestion)) ctrl.isGestion = ctrl.infosEvenement.isGestion;
      else if(ctrl.contenuEvenement && TagService.hasTagTypeProprietaire(ctrl.contenuEvenement.getTagsType())) ctrl.isGestion = true;

    }

    /**
     * Check si l'item est vu ou non
     * @param item
     * @returns {string}
     */
    function isVu(item) {
      if (item.getVuParFournisseur()) {
        return '<span class="label label-success"><i class="fa fa-eye"></i></span>';
      } else {
        return '<span class="label label-danger"><i class="fa fa-eye-slash"></i></span>';
      }
    }

    /**
     * Permet de savoir si le groupe actuel est une agence ICS
     * @returns {*}
     */
    function currentGroupeUserIsAgenceICS(){
      return UtilsService.getParametrage('agenceICS');
    }

    function openViewFacture(devis) {

      var facture = devis.getFacture();

      var options = {
        contenu: facture,
        documents: facture.getListeDocument(),
        allowDelete: false
      };
      ModalsService.modalViewDocument(options);
    }

    /**
     * Ouvre la box de communication et place sur le bon fournisseur
     * @param event
     * @param devis
     */
    function onClickOpenCommunication(idTopic, devis) {
      if(ctrl.noModalCommunication) emit({action: 'onClickCommunication', idTopic: idTopic, devis: devis})
      else {
        ModalsService.modalCommunication(idTopic, function(){
          $timeout(function(){
            $rootScope.$broadcast('openMessageForDevis',devis);
          });
        });
      }
    }

    function onClickAjouterDemande(devis){
      emit({action: 'onClickAjouterDemande'})
    }

    function onClickVoirDemande(devis){
      if(devis.isContenuDevisInter()) {
        emit({action: 'onClickVoirDemande', demande: devis})
      }
      else if(devis.isContenuContrat()) {
        emit({action: 'onClickAjouterDemandeContrat'})
      }
    }

    /**
     * Lors du clique sur le bouton pour envoyer le devis au propriétaire ou CS
     * @param devis
     */
    function onClickSendTo(devis) {
      var options = {
        size: 'lg',
        windowClass: 'modal-collabore',
        backdrop: 'static'
      };

      var params = {
        devis: devis,
        infosEvenement: ctrl.infosEvenement,
        evenement: ctrl.contenuEvenement,
        isGestion: ctrl.isGestion,
        documents: [devis.getDocumentDevis()]
      };

      ModalsService
        .modalComponent('sendToGravitant', params, options)
        .then(function(obj) {
          emit(obj);
        });
    }

    /**
     * Ouvre la modal pour envoyer un SMS
     */
    function onClickSendSMS(devis) {

      var obj = {
        contenu: devis,
        defaultParams: {
          //destinatairesHide: UtilsService.getDestinatairesHideFromEvenement(ctrl.contenuEvenement),
          destinatairesHide: (_.isObject(ctrl.infosEvenement) && _.isArray(ctrl.infosEvenement.listDestinatairesHide)) ? ctrl.infosEvenement.listDestinatairesHide : null,
          idTopic: ctrl.idTopic
        }
      };

      if(_.isObject(ctrl.portefeuille) &&  _.isObject(ctrl.portefeuille.portefeuilleInfos)) {
        obj.defaultParams.prefixSms = ctrl.portefeuille.portefeuilleInfos.prefixSms;
      }
      ModalsService.modalSendSMS(obj, false, function (contenuMessage) {
        $rootScope.$broadcast('addMessage', contenuMessage);
      });
    }

    /**
     * Lors du clique sur le bouton pour ouvrir la modal d'envoi de mail
     */
    function onClickSendEmail(devis){
      var options = {
        backdrop: 'static'
      };
      //var copyDestinataire = angular.copy(_this.demande.getDestinataire());
      //copyDestinataire.typeDestinataire = 'fournisseur';
      var params = {
        contenuParent: devis,
        typeFormulaire: 'email',
        chooseContact: false,
        useMiniToolbar: true,
        disallowSelectType: true,
        disallowChooseDestinataires: false,
        destinataires: [],
        destinatairesHide: (_.isObject(ctrl.infosEvenement) && _.isArray(ctrl.infosEvenement.listDestinatairesHide)) ? ctrl.infosEvenement.listDestinatairesHide : null
      };

      ModalsService
        .modalComponent('formulaireMessage', params, options)
        .then(function (objet) {
          $rootScope.$broadcast('addMessage', objet.contenu);
        })
        .catch(function (msg) {
          if (msg !== 'cancel') ModalsService.alertErreur(msg);
        });
    }

    /**
     * Permet de relancer la demande
     * @param devis
     */
    function onClickRelancerDemande(devis) {
      if (_.isObject(devis)) {

        ModalsService
          .modalRelanceDemande(devis)
          .then(function(retourDevis){
            devis.setData(retourDevis);
            emit({action: 'addOrUpdateDemande', contenu: retourDevis});
          });

        /*
        sweet.show({
          title: 'Relance',
          text: 'Relancer ' + devis.getDestinataire().getDefaultLibelle() + ' ?',
          type: 'info',
          showCancelButton: true,
          //cancelButtonText: 'Annuler',
          confirmButtonText: 'Relancer !',
          closeOnConfirm: false,
          showLoaderOnConfirm: true
        }, function () {
          devis.relancer()
            .then(function () {
              ModalsService.alert('Relance', 'Relance effectuée !');
            })
            .catch(function (msg) {
              ModalsService.alertErreur(msg);
            })
            .finally(function () {
              //sweet.close();
            });
          //saveDevis();
        });*/
      }
    }

    function onClickCreateEvent(devis, withDemande){
      var nameAction = 'CreateNewEvenement';
      if(withDemande) nameAction += 'WithDemand';

      var objCheck = ctrl.createEventOpened;
      if(withDemande) objCheck = ctrl.createEventWithDemandeOpened;

      var close = (objCheck[devis.idContenu]);

      var keys = Object.keys(objCheck);
      if(keys.length) {
        for(var i = 0; i < keys.length; i++){
          delete objCheck[keys[i]];
        }
      }

      if(close){
        emit({action: 'closeCreateNewEvenement', emitToParent: true});
        return;
      }

      var ContenuEvenement = UtilsService.$injector.get('ContenuEvenement');
      var newEvenement = new ContenuEvenement();
      newEvenement.setObjet(UtilsService.nl2br(devis.descriptionDevis));
      newEvenement.idContenuRefInterAgence = devis.getIdContenu();

      objCheck[devis.idContenu] = true;

      if(withDemande) ctrl.createEventOpened = {};
      else ctrl.createEventWithDemandeOpened = {};

      emit({action: 'open' + nameAction, emitToParent: true, newEvenement: newEvenement});
      //ModalsService.modalComponent('formulaireEvenement', {contenuEvenement: newEvenement}, {size: 'full', backdrop: 'static'});
    }

    function onClickRetry(demande) {
      if(_.isObject(demande) && demande.isError()) {
        demande.loadingRetry = true;
        demande.retryDevis()
          .then(function(){
            demande.setEtat(0);
          })
          .catch(function(msg){
            ModalsService.alertErreur(msg);
          })
          .finally(function(){
            demande.loadingRetry = false;
          })
      }
    }

    /**
     * Lors du drop d'un item draggable
     */
    function onDropDocument(event) {
      try {
        if (!_.isObject(ctrl.itemDrop) || ctrl.itemDrop.model !== "Document") {
          $log.error(ctrl.itemDrop);
          throw new Error("Le document n'est pas bon !");
        }

        var demande = null;
        if(ctrl.listDemandes.length){
          for(var i = 0; i < ctrl.listDemandes.length; i++){
            if(ctrl.listDemandes[i].getIdContenu() === parseInt(event.target.dataset.idcontenu)){
              demande = ctrl.listDemandes[i];
              break;
            }
          }
        }
        if(_.isNil(demande)) {
          $log.error('listDemandes', ctrl.listDemandes);
          $log.error('event', event);
          $log.error('event.target.dataset.idcontenu', event.target.dataset.idcontenu);
          throw new Error("La demande n'a pas été trouvée !");
        }

        var action = null;
        // Si le document est de type devis et qu'on peut upload un devis
        if(ctrl.itemDrop.isDocumentDevis() && demande.canAddDevis()) action = 'addDevis';
        // Si le doc est de type facture et qu'on peut upload une facture
        else if(ctrl.itemDrop.isFacture() && demande.canAddFacture())  action = 'addFacture'

        // Si pas d'action
        if(_.isNil(action)){
          // Si la demande n'a pas de devis
          if(demande.canAddDevis()) action = 'addDevis';
          // Si elle a un devis
          else action = 'addFacture';
        }

        var msgErreur = null;
        if(action === 'addFacture' || action === 'addDevis') {
          if (!_.isNil(ctrl.itemDrop.typeDocument) && !UtilsService.contains(COLLAB_CONF.TYPE_DOC_ENUMS_ALLOWED_FOR_DEVIS_FACTURE, ctrl.itemDrop.typeDocument)) msgErreur = "Impossible d'ajouter un document de type \"" + ctrl.itemDrop.getLibelleTypeDocument() + "\"";
        }

        if (!_.isNil(msgErreur)) {
          if(action === 'addFacture') msgErreur += " en tant que facture";
          else if(action === 'addDevis') msgErreur += " en tant que devis";
          throw new Error(msgErreur);
        }

        /*
        if(_.isNil(action)) {
          $log.error(demande);
          throw new Error("L'action est null !");
        }*/

        var titre = null,
          desc = "Voulez-vous ajouter le document <strong>"+ctrl.itemDrop.getNomFichier()+"</strong> à cette demande ?";
        if(action === "addDevis")         titre = "Ajouter le devis";
        else if(action === "addFacture")  titre = "Ajouter la facture";
        else {
          $log.error('demande', demande);
          $log.error('action', action);
          throw new Error("L'action n'est pas reconnue !");
        }

        ModalsService
          .confirm(titre, desc)
          .then(function (modal) {

            var params = {
              action: action,
              idContenu: demande.getIdContenu(),
              guids: [ctrl.itemDrop.getGuid()]
            };
            ContenuService
              .addDocuments(params)
              .then(function onSuccess(listContenu){

                if(!_.isNil(listContenu)) {
                  UtilsService.addOrUpdateListContenuOrContenuInListContenu(listContenu, ctrl.listDemandes);
                  emit({action: 'addOrUpdateDemande', contenu: listContenu});
                }
                else {
                  ModalsService.alertErreur("Pas de contenu en retour !");
                }
                modal.close();
              })
              .catch(function onError(msg){
                ModalsService.alertErreur(msg);
              });
          });
      }
      catch(err) {
        ModalsService.alertErreur(err.message);
      }
    }

    /**
     * Lorsque l'on reçoit une action du composant menu-demande
     * @param obj
     */
    function onEmitFromMenuDemande(obj){
      emit(obj);
    }

    /**
     * Lorsque le composant avancement-contenu remonte une information
     * @param demande
     * @param obj
     */
    function onEmitFromAvancementComponent(demande, obj){
      //console.log(obj);
      try {
        if (!obj) throw new Error('Il manque l\'objet "obj" !');
        if (!obj.contenu && !obj.listContenu) throw new Error('Il faut au moins l\'objet "obj.contenu" OU "obj.listContenu" !');

        var openDetail = false;
        var stateParam = {
          topicId: ctrl.idTopic,
          objTab: {
            currentTab: 'fournisseurs',
            obj: {}
          }
        };

        var optionsParam = {
          location: true,
          reload: true,
          inherit: false
        };

        if(obj.action === 'updateEtat') {
          obj.contenu.setEtat(obj.etat);
        }

        if(obj.action === 'openEditDemande') {
          stateParam.objTab.obj = {
            action: 'editDemande',
            contenu: obj.contenu
          };
          openDetail = true;
        }

        if(obj.action === 'openDocument'){
          if (_.isNil(obj.document)) throw new Error('Il manque l\'objet "obj.document" !');
          // Vue agence
          if(ctrl.contenuEvenement.isModifiable()){
            stateParam.objTab.obj = {
              action: 'openDocument',
              contenu: obj.contenu,
              document: obj.document
            };
            openDetail = true;
          }
          // Vue fournisseur ou agence pas dans evenement
          else {

            if(!_.isNil(ctrl.documentViewer[demande.getIdContenu()])) {

              var currentOpenedDoc = angular.copy(ctrl.documentViewer[demande.getIdContenu()]);
              delete ctrl.documentViewer[demande.getIdContenu()];
              $timeout(function(){
                if(obj.document.isOpen) {
                  // Si le document ouvert n'est pas le meme que le document à ouvrir
                  if(currentOpenedDoc.guid !== obj.document.guid){
                    ctrl.documentViewer[demande.getIdContenu()] = obj.document;
                  }
                  // Si le document ouvert est le meme que le document à ouvrir
                  else {
                    delete ctrl.documentViewer[demande.getIdContenu()];
                    delete obj.document.isOpen;
                  }
                }
                else {
                  obj.document.isOpen = true;
                  ctrl.documentViewer[demande.getIdContenu()] = obj.document;
                }
              });
            }
            else {
              obj.document.isOpen = true;
              ctrl.documentViewer[demande.getIdContenu()] = obj.document;
            }
          }
        }

        if(obj.action === 'addOrUpdateDemande') {
          delete ctrl.addDocumentsOpened[demande.idContenu];
          UtilsService.addOrUpdateListContenuOrContenuInListContenu(obj.contenu, ctrl.listDemandes);
          emit(obj);
        }

        if(obj.action === "toggleDisplayAddDocumentsDevis") {
          if(_.isNil(ctrl.addDocumentsOpened[demande.idContenu]) || ctrl.addDocumentsOpened[demande.idContenu] !== 'addDevis')
            ctrl.addDocumentsOpened[demande.idContenu] = 'addDevis';
          else
            delete ctrl.addDocumentsOpened[demande.idContenu];
        }
        if(obj.action === "toggleDisplayAddDocumentFacture") {
          if(_.isNil(ctrl.addDocumentsOpened[demande.idContenu]) || ctrl.addDocumentsOpened[demande.idContenu] !== 'addFacture')
            ctrl.addDocumentsOpened[demande.idContenu] = 'addFacture';
          else
            delete ctrl.addDocumentsOpened[demande.idContenu];
        }
        if(obj.action === "closeAddDocuments") delete ctrl.addDocumentsOpened[demande.idContenu];

        /*
        if(obj.action === 'openTraiteDemande' || obj.action === 'openSigne') {
            if(obj.contenu.isContenuDevis()) {
                stateParam.objTab.obj = {
                    action: 'openDocument',
                    contenu: obj.contenu,
                    document: obj.contenu.getDocumentDevis()
                };
            }
            else if(obj.contenu.isContenuContrat()) {

                if(obj.action === 'openTraiteDemande') {
                    // Ouvre le prochain document fournisseur signé qui reste à accepter par l'agence
                    stateParam.objTab.obj = {
                        action: 'openDocument',
                        contenu: obj.contenu,
                        document: obj.contenu.getNextDocumentWaitingFromListDocumentContratFournisseurSigne()
                    };
                }
                else if(obj.action === 'openSigne'){
                    // ouvre le prochain document fournisseur signé accépté par l'agence mais qui reste encre à signer par l'agence
                    stateParam.objTab.obj = {
                        action: 'openDocument',
                        contenu: obj.contenu,
                        document: obj.contenu.getNextDocumentAcceptedFromListDocumentContratFournisseurSigne()
                    };
                }
            }
            openDetail = true;
        }
        if(obj.action === 'selectDemande') {
            stateParam.objTab.obj = {
                action: 'selectDemande',
                contenu: obj.contenu
            };
            openDetail = true;
        }*/

        if(openDetail) {
          if(!UtilsService.isTopicDetail()) $state.go('topics.detail', stateParam, optionsParam);
          else $rootScope.$broadcast('layoutTopicDetailOpenTab', stateParam.objTab);
        }
      }
      catch (err) {
        console.log('[ListeLigneDemandeFournisseurController.onEmitFromAvancementComponent] Erreur : ' + err.message);
      }
    }

    /**
     * Lorsque le composant add-documents remonte une information
     * @param obj
     */
    function onEmitFromAddDocumentsComponent(obj){
      //console.log(obj);
      try {
        if (!obj) throw new Error('Il manque l\'objet "obj" !');
        if (!obj.contenu) throw new Error('Il manque l\'objet "obj.contenu" !');

        if(obj.action === "closeAddDocuments") delete ctrl.addDocumentsOpened[obj.contenu.idContenu];

        if(obj.action === 'addOrUpdateDemande') {
          if(_.isArray(obj.contenu) && obj.contenu.length) {
            for(var c = 0; c < obj.contenu.length; c++){
              delete ctrl.addDocumentsOpened[obj.contenu[c].idContenu];
            }
          }
          UtilsService.addOrUpdateListContenuOrContenuInListContenu(obj.contenu, ctrl.listDemandes);
          emit(obj);
        }
      }
      catch (err) {
        console.log('[ListeLigneDemandeFournisseurController.onEmitFromAddDocumentsComponent] Erreur : ' + err.message);
      }
    }

    /**
     * Lorsque le composant documents-viewer remonte une information
     * @param obj
     */
    function onEmitFromDocumentsViewerComponent(obj, doc){
      try {
        if (!obj) throw new Error('Il manque l\'objet "obj" !');

        if(obj.action === "close") delete doc.isOpen;
      }
      catch (err) {
        console.log('[ListeLigneDemandeFournisseurController.onEmitFromDocumentsViewerComponent] Erreur : ' + err.message);
      }
    }

    function emit(obj){
      ctrl.onEmit({obj: obj});
    }
  }
})();
