(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.GedService
   * @description
   * # GedService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('GedService', GedService);

  /*@ngInject*/
  function GedService(MainService, $injector, $q, GedAjax, ErreurCollabService, ModalsService) {

    var ctrl = this;

    ctrl.sendToGed = sendToGed;
    ctrl.exportInGed = exportInGed;
    ctrl.searchGedHierarchie = searchGedHierarchie;

    ctrl.sendToGedV2 = sendToGedV2;
    ctrl.exportTopicInGedV2 = exportTopicInGedV2;
    ctrl.searchCheminGedV2 = searchCheminGedV2;

    /**
     * Permet d'envoyer un document dans la GedV1
     * @param idPortefeuille
     * @param cheminGed
     * @param guids
     * @returns {*}
     */
    function sendToGed(idPortefeuille, cheminGed, guids) {
      var deferred = $q.defer();
      try{
        if(_.isNil(idPortefeuille) || !idPortefeuille) throw new Error('L\'idPortefeuille est vide !');
        if(_.isNil(cheminGed) || !cheminGed) throw new Error('Le cheminGed est vide !');
        if(_.isNil(guids) || !guids) throw new Error('Le guids est vide !');
        if(!_.isArray(guids)) guids = [guids];

        var sendParams = {
          action: 'sendToGed',
          idPortefeuille: idPortefeuille,
          cheminGed: cheminGed,
          guids: guids.map(function(guidOrDoc){
            var guid = guidOrDoc;
            if(_.isObject(guid) && !_.isNil(guid.guid)) guid = guid.guid;
            return guid;
          })
        };
        GedAjax.post(sendParams, function(json) {
          deferred.resolve(MainService.convertJsonToModel(json, 'result', 'Document', deferred));
        },function(){
          deferred.reject();
        });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Permet d'export le dossier dans la Ged V1
     * @param objet
     * @returns {*}
     */
    function exportInGed(objet) {
      var deferred = $q.defer();
      try{
        if(!objet) throw new Error('L\'objet est vide !');
        var sendParams = {
          action: 'exportInGed',
          pdfParams: angular.toJson(objet)
        };
        GedAjax.post(sendParams, function(json) {
          deferred.resolve(MainService.convertJsonToModel(json, 'topic', 'Topic', deferred));
        },function(){
          deferred.reject();
        });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Permet de récupérer la hieréchie de la GedV1
     * @param rechercheImmeuble
     * @param rechercheMandat
     * @param idPortefeuille
     * @param idEvenement
     * @param typeGed
     * @returns {*}
     */
    function searchGedHierarchie(rechercheImmeuble, rechercheMandat, idPortefeuille, idEvenement, typeGed) {
      var deferred = $q.defer();
      try{
        if(!idPortefeuille) throw new Error('idPortefeuille est vide !');
        if(!rechercheImmeuble && !rechercheMandat) throw new Error('rechercheImmeuble ou rechercheMandat est vide !');
        if(!idEvenement) throw new Error('idEvenement est vide !');
        var sendParams = {
          action: 'searchGedHierarchie',
          rechercheImmeuble: rechercheImmeuble,
          rechercheMandat: rechercheMandat,
          idEvenement: idEvenement,
          idPortefeuille: idPortefeuille,
          typeGed: typeGed
        };
        GedAjax.post(sendParams, function(json) {
          deferred.resolve(json);
        },function(){
          deferred.reject();
        });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Permet d'envoyer un document dans la GedV2
     * @param idTopic
     * @param guids
     * @param gedFolder
     * @returns {*}
     */
    function sendToGedV2(idTopic, guids, gedFolder) {
      var deferred = $q.defer();
      try{
        if(_.isNil(idTopic) || !idTopic) throw new Error('L\'idTopic est vide !');
        if(_.isNil(guids) || !guids) throw new Error('Le guids est vide !');
        if(!_.isArray(guids)) guids = [guids];

        var sendParams = {
          action: 'sendToGedV2',
          idTopic: idTopic,
          gedFolder: gedFolder,
        };

        if(_.isArray(guids) && guids.length){
          var listGuid = [];
          var listDocs = [];
          for(var g = 0; g < guids.length; g++){
            if(_.isObject(guids[g])){
              var doc = guids[g];
              listDocs.push(angular.toJson({
                guid: doc.getGuid(),
                tmpIdContenu: doc.getTmpIdContenu(),
                tmpIdTopic: doc.getTmpIdTopic(),
                typeDocument: doc.getTypeDocument(),
              }));
              // TODO : ajouter le folderinged dans le document ?
            }
            else if(_.isString(guids[g]))listGuid.push(guids[g]);
          }
          if(listDocs.length) sendParams.docs = listDocs;
          if(listGuid.length) sendParams.guids = listGuid;
        }
        GedAjax.post(sendParams, function(json) {
          deferred.resolve(MainService.convertJsonToModel(json, 'result', 'Document', deferred));
        },function(){
          deferred.reject();
        });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Permet d'export le dossier dans la Ged V2
     * @param idTopic
     * @param nomPdf
     * @returns {*}
     */
    function exportTopicInGedV2(idTopic, nomPdf, paramsPdf) {
      var deferred = $q.defer();
      try{
        if(!idTopic) throw new Error('L\'idTopic est vide !');
        var sendParams = {
          action: 'exportTopicInGedV2',
          idTopic: idTopic,
          nomPdf: nomPdf,
          pdfParams: angular.toJson(paramsPdf)
        };
        GedAjax.post(sendParams, function(json) {
          deferred.resolve({
            documents: MainService.convertJsonToModel(json, 'result', 'Document', deferred),
            topic: MainService.convertJsonToModel(json, 'topic', 'Topic', deferred)
          });
        },function(){
          deferred.reject();
        });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Permet de récupérer le chemin en GedV2 pour un document
     * @param idEvenement
     * @param gedFolder
     * @param guid
     * @returns {*}
     */
    function searchCheminGedV2(idTopic, gedFolder, guids){
      var deferred = $q.defer();
      try{
        if(_.isNil(idTopic) || !idTopic) throw new Error('idTopic est vide !');
        if(!_.isNil(guids) && !_.isArray(guids)) guids = [guids];

        var sendParams = {
          action: 'searchCheminGedV2',
          idTopic: idTopic,
          gedFolder: gedFolder
        };

        if(_.isArray(guids) && guids.length){
          var listGuid = [];
          var listDocs = [];
          for(var g = 0; g < guids.length; g++){
            if(_.isObject(guids[g])){
              var doc = guids[g];
              listDocs.push(angular.toJson({
                guid: doc.getGuid(),
                tmpIdContenu: doc.getTmpIdContenu(),
                tmpIdTopic: doc.getTmpIdTopic(),
                typeDocument: doc.getTypeDocument(),
              }));
              // TODO : ajouter le folderinged dans le document ?
            }
            else if(_.isString(guids[g]))listGuid.push(guids[g]);
          }
          if(listDocs.length) sendParams.docs = listDocs;
          if(listGuid.length) sendParams.guids = listGuid;
        }

        GedAjax.post(sendParams, function(json) {
            if(_.isObject(json) && json.success){
              var results = json.result;
              if(_.isArray(results)) {
                var Document = $injector.get('Document');
                results.map(function(result){
                  if(!_.isNil(result.doc)) result.doc = new Document(result.doc);
                  return result;
                });
              }
              deferred.resolve(results);
            }
            else {
              ErreurCollabService.logErreur("Problème avec retour \"searchCheminGedV2\"", json);
              deferred.reject();
            }
          },
          function(){
            deferred.reject();
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }
  }
})();
