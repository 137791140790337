(function () {
  'use strict';

  /**
   * @desc Gestion des associations
   * @example <gestion-associations-ligne></gestion-associations-ligne>
   */

  angular
    .module('collaboreApp')
    .component('gestionAssociationsLigne', {
      templateUrl: 'app/parametrage/gestion-associations/gestion-associations-ligne.component.html',
      bindings: {
        groupe: '<',
        hideCheckbox: '<',
        adminVersion: '<',
        hideActions: '<',
        listGroupeParent: '<',
        portefeuille: '<',
        onEmit: '&',
      },
      controllerAs: 'assoclignectrl',
      controller: GestionAssociationsLigneController
    });

  /*@ngInject*/
  function GestionAssociationsLigneController(UtilsService, ErreurCollabService) {

    var _this = this;
    _this.myIdGroupe = UtilsService.getCurrentUser().getIdGroupe();
    _this.currentGroupeUserHasAssociation = false;  // Permet de savoir si le groupeUser connecté a déjà une association avec ce groupe
    _this.groupeCollaborateur = null;

    _this.onChangeCheckbox = onChangeCheckbox;

    _this.onClickDisplayGroupe = onClickDisplayGroupe;
    _this.onClickAddAssociation = onClickAddAssociation;
    _this.onClickRemoveAssociation = onClickRemoveAssociation;
    _this.onClickDeleteAssociation = onClickDeleteAssociation;
    _this.onEmitFromChooseGroupeParent = onEmitFromChooseGroupeParent;

    _this.$onInit = function() {
      //console.log(_this.groupe);
      init(_this.groupe);
    };

    _this.$onChanges = function(changes) {
      //console.log(changes);
      if(_.isObject(changes.groupe) && !changes.groupe.isFirstChange()){
        init(changes.groupe.currentValue);
      }
    };

    _this.$onDestroy = function(){
    };

    function init(groupe){
      _this.currentGroupeUserHasAssociation = false;
      if(_.isObject(groupe) && _.isObject(groupe.getGroupeAssociation()) &&
        _.isArray(_this.listGroupeParent) && _this.listGroupeParent.length){
        _this.listGroupeParent = _this.listGroupeParent.reduce(function(list, currentGroupe){
          if(!groupe.getGroupeAssociation().collaborateurIsInListGroupeAssociationGroupe(currentGroupe)){
            list.push(currentGroupe);
          }
          if(groupe.getGroupeAssociation().collaborateurIsInListGroupeAssociationGroupe(_this.myIdGroupe)){
            _this.currentGroupeUserHasAssociation = true;
          }
          return list;
        }, []);
      }
    }

    function onChangeCheckbox(){
      emit({
        action:'checkUncheckAssoc',
        groupe: _this.groupe
      });
    }

    function onClickDisplayGroupe(){
      _this.groupe.getGroupeAssociation().displayGroupeAssociationGroupe = !_this.groupe.getGroupeAssociation().displayGroupeAssociationGroupe;
    }

    /*
    function onClickAddAssociation(){
      emit({
        action:'addAssoc',
        groupe: _this.groupe
      });
    }
    */
    function onClickAddAssociation(){
      saveAssociation();
    }

    function saveAssociation(groupeCollaborateur){
      var params = {
        action:'addAssoc',
        groupe: _this.groupe
      };
      if(!_.isNil(groupeCollaborateur)) params.groupeCollaborateur = angular.copy(groupeCollaborateur);
      emit(params);
    }

    /**
     * Si on clique sur le bouton pour supprimer l'association pour un groupe
     */
    function onClickRemoveAssociation(groupeAssocationGroupe){
      var objetEmit = {
        action:'removeAssoc',
        groupe: _this.groupe
      };

      if(_.isObject(groupeAssocationGroupe)) {
        groupeAssocationGroupe.loading = true;
        if(groupeAssocationGroupe.isGroupeAssociationGroupe) {
          objetEmit.groupeAssocationGroupe = groupeAssocationGroupe;
        }
        else if(groupeAssocationGroupe.isGroupeAssociation) {
          objetEmit.groupeAssocationGroupe = groupeAssocationGroupe.getListGroupeAssociationGroupe();
        }
      }

      emit(objetEmit);
    }

    /**
     * Si on clique sur le bouton pour supprimer complétement l'association pour tous le monde
     */
    function onClickDeleteAssociation(){
      emit({
        action:'deleteAssoc',
        groupe: _this.groupe
      });
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      _this.onEmit({obj: objRetour});
    }

    function onEmitFromChooseGroupeParent(obj){
      try {

        if (!_.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');

        if(obj.action === 'validSelection')
          saveAssociation(obj.listGroupeCollaborateurChecked);

      }
      catch (err) {
        ErreurCollabService.logErreur('[GestionAssociationsLigneController.onEmitFromChooseGroupeParent] Erreur : ',  err.message);
      }
    }
  }
})();
