(function () {

  'use strict';

  /**
   * @desc Composant AdminCollab
   * @example <admin-collab-capfun></admin-collab-capfun>
   */
  angular
    .module('collaboreApp')
    .component('adminCollabCapfun', {
      templateUrl: 'app/admin/capfun/admin-collab-capfun.component.html',
      controllerAs: 'admincollabcapfunctrl',
      controller: AdminCollabCapfunCtrl
    });

  /** @ngInject */
  function AdminCollabCapfunCtrl(COLLAB_CONF, AdminCapfunService) {
    var _this = this;
    _this.listCamping = [];
    _this.copyModules = {
      source: null,
      destination: null
    };

    _this.onChangeModuleCopyCampingSource = onChangeModuleCopyCampingSource;
    _this.onChangeModuleCopyCampingDestination = onChangeModuleCopyCampingDestination;
    _this.onSubmitCopyModules = onSubmitCopyModules;

    _this.$onInit = function () {
      AdminCapfunService
        .getCampings()
        .then(function(groupe){
          if(_.isObject(groupe) && groupe.model === "Groupe") {
            _this.groupeCampingsCapfun = groupe;

            if(groupe.getGroupesFils().length) {
              for(var i = 0; i < groupe.getGroupesFils().length; i++) {
                if(!groupe.getGroupesFils()[i].isUser()) {
                  _this.listCamping.push(groupe.getGroupesFils()[i]);
                }
              }
            }
          }
      });

    };

    function onChangeModuleCopyCampingSource(){
      if(_.isNil(_this.copyModules.source)) _this.copyModules.destination = null;
    }

    function onChangeModuleCopyCampingDestination(){
    }

    function onSubmitCopyModules(formCopieModule){
      if (!formCopieModule.$valid) {
        return false;
      }
      if(_this.copyModules.source.idGroupe === _this.copyModules.destination.idGroupe) {
        return false;
      }
      console.log(_this.copyModules);
      console.log(formCopieModule);
    }



  }
})();
