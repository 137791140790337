'use strict';

/**
 * @ngdoc function
 * @name collaboreApp.controller:InscriptionCtrl
 * @description
 * # InscriptionCtrl
 * Controller of the collaboreApp
 */

var InscriptionCtrl = function($rootScope, COLLAB_CONF,$scope) {
	var vm = this;

	vm.loadingInscription = false;
	$scope.$watch('formInscription.$valid', function(etatForm){
		// Si le formulaire est rempli
		if(etatForm) {
			vm.displayMsgInscription = false;
			vm.erreurInscription = false;
			vm.msgInscription = 'Merci !';
		}
	});

	// Valide le formulaire de l'inscription lors du submit
	vm.submitFormInscription = function(form){

		vm.displayMsgInscription = true;
		vm.erreurInscription = false;
		vm.msgInscription = '';

		// Si l'erreur required est à true alors tous les champs ne sont pas remplis
		if(form.$error.required){
			vm.erreurInscription = true;
			vm.msgInscription = 'Veuillez renseigner tous les champs !';
			return;
		}

		// Si l'erreur pattern est à true alors login avec caractère non authorisés
		if(form.utilisateur.$error.pattern){
			vm.erreurInscription = true;
			vm.msgInscription = 'Le login contient des caractères non authorisés ou est trop petit !';
			return;
		}

		// Si l'erreur minlength est à true alors mdp trop petit
		if(form.mdp.$error.minlength || form.confmdp.$error.minlength){
			vm.erreurInscription = true;
			vm.msgInscription = 'Mot de passe trop petit !';
			return;
		}

		if(form.motdepasse!==form.confirmationmotdepasse){
			vm.erreurInscription = true;
			vm.msgInscription = 'Le mot de passe ne correspond pas à la confirmation';
			return;
		}

		

		vm.msgInscription = 'Super !';

		/*
		vm.loadingInscription = COLLAB_CONF.LOADING;
		User.inscription(form.login,form.motdepasse).then(function(objUser){ // Si ok

			vm.displayMsgInscription = false;
			//this.msgInscription = "Bienvenue ! Veuillez patientez...";
			vm.loadingInscription = false;

			// Go accueil
			$location.url('/');

			// Affichage des notifications si il y en a
			$rootScope.serviceNotifications.displayNotifications();

		},
		// Si erreur
		function(msg){
			vm.erreurInscription = true;
			vm.msgInscription = msg;
			vm.loadingInscription = false;
		});*/

	};
};

angular
	.module('collaboreApp')
	.controller('InscriptionCtrl', [
		'$rootScope',
		'COLLAB_CONF',
		'$scope',
		InscriptionCtrl
	]);
