(function() {
    'use strict';

    angular.module('collaboreApp').controller('ModalDocumentsCtrl', ModalDocumentsCtrl);

    /** @ngInject */
    function ModalDocumentsCtrl($uibModalInstance, params){
        var ctrl = this;
        ctrl.contenu = false;
        ctrl.documents = false;
        ctrl.allowDelete = false;
        ctrl.saveAuto = false;
        ctrl.isDevis = false;
        ctrl.onUpdate = false;
        ctrl.onSelect = false;

        if(params){
            if(params.hasOwnProperty('contenu')) {
                ctrl.contenu = params.contenu;
            }
            if(params.hasOwnProperty('documents')) {
                ctrl.documents = params.documents;
            }
            if(params.hasOwnProperty('onUpdate')) {
                ctrl.onUpdate = params.onUpdate;
            }
            if(params.hasOwnProperty('onSelect')) {
                ctrl.onSelect = params.onSelect;
            }
            if(params.hasOwnProperty('allowDelete')) {
                ctrl.allowDelete = params.allowDelete;
            }
            if(params.hasOwnProperty('saveAuto')) {
                ctrl.saveAuto = params.saveAuto;
            }
            if(params.hasOwnProperty('isDevis')) {
                ctrl.isDevis = params.isDevis;
            }
        }

        /**
         * Ferme le modal
         */
        ctrl.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        //$uibModalInstance.close(objAnnuaire);

        $uibModalInstance.rendered.then(function(){
        });
    }

})();