(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.InfosDashboard
   * @description
   * # InfosDashboard
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('InfosDashboard', InfosDashboardModel);

  /** @ngInject */
  function InfosDashboardModel(Main) {

    function InfosDashboard(data) {
      Main.apply(this, arguments);
      this.hydrate(data);
    }

    InfosDashboard.prototype = {

      model: 'InfosDashboard',
      isInfosDashboard: true,

      // nbMessagesNonLu
      getNbMessagesNonLu: function(){
        return parseInt(this.nbMessagesNonLu);
      },
      setNbMessagesNonLu: function(nb){
        this.nbMessagesNonLu = nb;
      },

      // nbTopicAvecMessagesNonLu
      getNbTopicAvecMessagesNonLu: function(){
        return parseInt(this.nbTopicAvecMessagesNonLu);
      },
      setNbTopicAvecMessagesNonLu: function(nb){
        this.nbTopicAvecMessagesNonLu = nb;
      },

      // nbDemandeInterventionEnCours
      getNbDemandeInterventionEnCours: function(){
        return parseInt(this.nbDemandeInterventionEnCours);
      },
      setNbDemandeInterventionEnCours: function(nb){
        this.nbDemandeInterventionEnCours = nb;
      },

      // nbDemandeInterventionEnCoursPlusDe10Jours
      getNbDemandeInterventionEnCoursPlusDe10Jours: function(){
        return parseInt(this.nbDemandeInterventionEnCoursPlusDe10Jours);
      },
      setNbDemandeInterventionEnCoursPlusDe10Jours: function(nb){
        this.nbDemandeInterventionEnCoursPlusDe10Jours = nb;
      },

      // nbDemandeDevisEnCours
      getNbDemandeDevisEnCours: function(){
        return parseInt(this.nbDemandeDevisEnCours);
      },
      setNbDemandeDevisEnCours: function(nb){
        this.nbDemandeDevisEnCours = nb;
      },

      // nbDemandeDevisAvecDevisNonValide
      getNbDemandeDevisAvecDevisNonValide: function(){
        return parseInt(this.nbDemandeDevisAvecDevisNonValide);
      },
      setNbDemandeDevisAvecDevisNonValide: function(nb){
        this.nbDemandeDevisAvecDevisNonValide = nb;
      },

      // nbDemandeInterventionEnCours
      getNbTopicNonClotureAvecDateEcheanceDepassee: function(){
        return parseInt(this.nbTopicNonClotureAvecDateEcheanceDepassee);
      },
      setNbTopicNonClotureAvecDateEcheanceDepassee: function(nb){
        this.nbTopicNonClotureAvecDateEcheanceDepassee = nb;
      },

      // nbTopicNonClotureAvecDateEcheanceDepasseeMoinsDe7Jours
      getNbTopicNonClotureAvecDateEcheanceDepasseeMoinsDe7Jours: function(){
        return parseInt(this.nbTopicNonClotureAvecDateEcheanceDepasseeMoinsDe7Jours);
      },
      setNbTopicNonClotureAvecDateEcheanceDepasseeMoinsDe7Jours: function(nb){
        this.nbTopicNonClotureAvecDateEcheanceDepasseeMoinsDe7Jours = nb;
      },

      // nbTopicNonClotureAvecDateEcheanceDepasseePlusDe7Jours
      getNbTopicNonClotureAvecDateEcheanceDepasseePlusDe7Jours: function(){
        return parseInt(this.nbTopicNonClotureAvecDateEcheanceDepasseePlusDe7Jours);
      },
      setNbTopicNonClotureAvecDateEcheanceDepasseePlusDe7Jours: function(nb){
        this.nbTopicNonClotureAvecDateEcheanceDepasseePlusDe7Jours = nb;
      },

      // nbTopicNonClotureAvecDateEcheanceDepasseePlusDe14Jours
      getNbTopicNonClotureAvecDateEcheanceDepasseePlusDe14Jours: function(){
        return parseInt(this.nbTopicNonClotureAvecDateEcheanceDepasseePlusDe14Jours);
      },
      setNbTopicNonClotureAvecDateEcheanceDepasseePlusDe14Jours: function(nb){
        this.nbTopicNonClotureAvecDateEcheanceDepasseePlusDe14Jours = nb;
      },

      // nbTopicNonClotureSansDateEcheanceCreePlusDe7Jours
      getNbTopicNonClotureSansDateEcheanceCreePlusDe7Jours: function(){
        return parseInt(this.nbTopicNonClotureSansDateEcheanceCreePlusDe7Jours);
      },
      setNbTopicNonClotureSansDateEcheanceCreePlusDe7Jours: function(nb){
        this.nbTopicNonClotureSansDateEcheanceCreePlusDe7Jours = nb;
      },

      // nbTopicNonClotureSansDateEcheanceCreePlusDe30Jours
      getNbTopicNonClotureSansDateEcheanceCreePlusDe30Jours: function(){
        return parseInt(this.nbTopicNonClotureSansDateEcheanceCreePlusDe30Jours);
      },
      setNbTopicNonClotureSansDateEcheanceCreePlusDe30Jours: function(nb){
        this.nbTopicNonClotureSansDateEcheanceCreePlusDe30Jours = nb;
      },

      // nbFactureNonTraitee
      getNbFactureNonTraitee: function(){
        return parseInt(this.nbFactureNonTraitee);
      },
      setNbFactureNonTraitee: function(nb){
        this.nbFactureNonTraitee = nb;
      },

      // nbFactureNonTraiteeArchives
      getNbFactureNonTraiteeArchives: function(){
        return parseInt(this.nbFactureNonTraiteeArchives);
      },
      setNbFactureNonTraiteeArchives: function(nb){
        this.nbFactureNonTraiteeArchives = nb;
      },

      // nbTopicSansEventAvecTagToctoctoc
      getNbTopicSansEventAvecTagToctoctoc: function(){
        return parseInt(this.nbTopicSansEventAvecTagToctoctoc);
      },
      setNbTopicSansEventAvecTagToctoctoc: function(nb){
        this.nbTopicSansEventAvecTagToctoctoc = nb;
      },

      // nbTopicSansEventAvecTagExtranet
      getNbTopicSansEventAvecTagExtranet: function(){
        return parseInt(this.nbTopicSansEventAvecTagExtranet);
      },
      setNbTopicSansEventAvecTagExtranet: function(nb){
        this.nbTopicSansEventAvecTagExtranet = nb;
      },

      // nbTopicAvecTagRapportVisite
      getNbTopicAvecTagRapportVisite: function(){
        return parseInt(this.nbTopicAvecTagRapportVisite);
      },
      setNbTopicAvecTagRapportVisite: function(nb){
        this.nbTopicAvecTagRapportVisite = nb;
      }
    };

    angular.extend(InfosDashboard.prototype, Main.prototype);

    return InfosDashboard;

  }
})();
