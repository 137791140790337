'use strict';

var iconNbDocuments = function () {
    var directiveDefinitionObject = {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: { nb:'@' },
        template : '<div class="iconDocuments">'+
        '<span class="nbDocuments">{{nb}}</span>'+
        '<i class="fa fa-file-o"></i>'+
        '</div>',

        link: function (scope, element,attrs) {
            var nb = attrs.nb;
            if(!nb || nb===0 || nb==='0') {
                element.addClass('iconDocuments-noDocuments');
            }
        }
    };
    return directiveDefinitionObject;
};

angular.module('collaboreApp').directive('iconNbDocuments',[
    '$state',
    iconNbDocuments
]);