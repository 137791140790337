(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.FichesService
   * @description
   * # FichesService
   * Service in the collaboreApp.
   */
  angular
    .module('collaboreApp')
    .service('FichesService',
      FichesService
    );


  /** @ngInject */
  function FichesService(MainService, COLLAB_CONF, $rootScope, $q, ErreurCollabService, FicheAjax, State, Fiche, Renseignement, Reference) {

    var ctrl = this;

    ctrl.addRenseignement = addRenseignement;
    ctrl.updateRenseignement = updateRenseignement;
    ctrl.deleteRenseignement = deleteRenseignement;

    /**
     * Liste des références
     * @returns {{idReference: number, typeReference: string, categorie: string}[]}
     */
    ctrl.getListeReferences = function () {
      return [
        {
          'idReference': 1,
          'type': 'Portable',
          'categorie': 'telephone'
        },
        {
          'idReference': 2,
          'type': 'Email',
          'categorie': 'email'
        },
        {
          'idReference': 3,
          'type': 'Commentaire',
          'categorie': 'commentaire'
        },
        {
          'idReference': 4,
          'type': 'Prénom',
          'categorie': 'prenom'
        },
        {
          'idReference': 5,
          'type': 'Nom',
          'categorie': 'nom'
        },
        {
          'idReference': 6,
          'type': 'Téléphone',
          'categorie': 'telephone'
        },
        {
          'idReference': 7,
          'type': 'Adresse',
          'categorie': 'adresse'
        },
        {
          'idReference': 8,
          'type': 'Fax',
          'categorie': 'fax'
        },
        {
          'idReference': 9,
          'type': 'Code Postal',
          'categorie': 'codePostal'
        },
        {
          'idReference': 10,
          'type': 'Ville',
          'categorie': 'ville'
        },
        {
          'idReference': 11,
          'type': 'Portable Info',
          'categorie': 'telephone'
        }
      ];
    };

    /**
     * Recupère la liste des références par rapport à une categorie
     * @param categorie
     * @returns {Array}
     */
    ctrl.getListeReferencesByCategorie = function (categorie) {

      var references = [];
      angular.forEach(ctrl.getListeReferences(), function (reference) {
        if (reference.categorie === categorie) {
          references.push(new Reference(reference));
        }
      });
      return references;
    };


    /*
     ctrl.newRenseignement = function(type,value){
     return Renseignement({
     'reference': new Reference(type),
     'state': new State('prive'),
     'donnee': value
     });
     };*/


    /**
     * Ajoute une fiche
     * @param obj
     * @returns {promise|*|Promise|capability.promise|a.fn.promise}
     */
    ctrl.addFiche = function (obj) {

      var deferred = $q.defer();

      var params = {
        'idFiche': obj.idFiche,
        'idState': obj.idState,
        'idReference': obj.idReference,
        'donnee': obj.donnee
      };
      FicheAjax.addFiche(params, function (json) {

        if (json.nb !== '0') {
          deferred.resolve(new Fiche(json.Fiches[0]));
        } else {
          deferred.reject('Pas de résultats...');
        }

      }, function (error) {
        ErreurCollabService.erreurServlet(error, 'Problème avec retour servlet FichesService.addFiche()', deferred);
      });

      return deferred.promise;
    };

    /**
     * Update une fiche
     * @param obj
     * @returns {promise|*|Promise|capability.promise|a.fn.promise}
     */
    ctrl.updateFiche = function (obj) {

      var deferred = $q.defer();

      var params = {
        idFiche: obj.idFiche,
        idState: obj.idState,
        idReference: obj.idReference,
        idRenseignement: obj.idRenseignement,
        donnee: obj.donnee
      };
      FicheAjax.updateFiche(params, function (json) {

        if (json.nb !== '0') {
          deferred.resolve(new Fiche(json.Fiches[0]));
        } else {
          deferred.resolve(false);
        }

      }, function (error) {
        ErreurCollabService.erreurServlet(error, 'Problème avec retour servlet FichesService.updateFiche()', deferred);
      });

      return deferred.promise;
    };

    /**
     * Supprime un renseignement
     * @param obj
     * @returns {promise|*|Promise|capability.promise|a.fn.promise}
     */
    /*
    ctrl.deleteRenseignement = function (obj) {

      var deferred = $q.defer();

      var params = {
        'idFiche': obj.idFiche,
        'idRenseignement': obj.idRenseignement
      };

      FicheAjax.deleteRenseignement(params, function (json) {

        if (json.nb !== '0') {
          deferred.resolve(new Fiche(json.Fiches[0]));
        } else {
          deferred.resolve(false);
        }

      }, function (error) {
        ErreurCollabService.erreurServlet(error, 'Problème avec retour servlet FichesService.deleteRenseignement()', deferred);
      });

      return deferred.promise;
    };*/


    //---------------- New ------------------------//

    /**
     * Ajoute un renseignement
     * @param obj
     * @returns {promise|*|Promise|capability.promise|a.fn.promise}
     */
    function addRenseignement(obj) {

      var deferred = $q.defer();

      var params = {
        action: 'addRenseignement',
        idFiche: obj.idFiche,
        idState: obj.idState,
        idReference: obj.idReference,
        donnee: obj.donnee
      };
      FicheAjax.postToFicheServlet(params, function (json) {

        if (json.success) deferred.resolve(MainService.convertJsonToModel(json, 'fiche', Fiche, deferred));
        else deferred.reject(json.message);

      }, function (error) {
        ErreurCollabService.erreurServlet(error, 'Problème avec retour servlet FichesService.addRenseignement()', deferred);
      });

      return deferred.promise;
    }

    /**
     * Update un renseignement
     * @param obj
     * @returns {promise|*|Promise|capability.promise|a.fn.promise}
     */
    function updateRenseignement(obj) {

      var deferred = $q.defer();

      var params = {
        action: 'updateRenseignement',
        idFiche: obj.idFiche,
        idState: obj.idState,
        idReference: obj.idReference,
        idRenseignement: obj.idRenseignement,
        donnee: obj.donnee
      };
      FicheAjax.postToFicheServlet(params, function (json) {

        if (json.success) deferred.resolve(MainService.convertJsonToModel(json, 'fiche', Fiche, deferred));
        else deferred.reject(json.message);

      }, function (error) {
        ErreurCollabService.erreurServlet(error, 'Problème avec retour servlet FichesService.updateRenseignement()', deferred);
      });

      return deferred.promise;
    }

    /**
     * Supprime un renseignement
     * @param obj
     * @returns {promise|*|Promise|capability.promise|a.fn.promise}
     */
    function deleteRenseignement(obj) {

      var deferred = $q.defer();

      var params = {
        action: 'deleteRenseignement',
        idFiche: obj.idFiche,
        idRenseignement: obj.idRenseignement
      };

      FicheAjax.postToFicheServlet(params, function (json) {

        if (json.success) deferred.resolve(MainService.convertJsonToModel(json, 'fiche', Fiche, deferred));
        else deferred.reject(json.message);

      }, function (error) {
        ErreurCollabService.erreurServlet(error, 'Problème avec retour servlet FichesService.deleteRenseignement()', deferred);
      });

      return deferred.promise;
    }

  }
})();
