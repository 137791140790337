(function() {
  'use strict';

  /**
   * @desc Affiche un TopicStates
   * @example <topic-states-ligne [topic-states="" OU topic=""] topic-detail="true" on-update="ctrl.la_fonction(topicStates)"></topic-states-ligne>
   */

  angular
    .module('collaboreApp')
    .component('topicStatesLigne', {
      templateUrl: 'app/topics/topic-states-ligne.component.html',
      require: {
        listTopicStateCtrl: '?^listTopicState',	// Composant appelé depuis un composant listTopicStates
        listTopicStatesCtrl: '?^listTopicStates',	// Composant appelé depuis un composant listTopicStates
        detailCtrl: '?^topicsdetailctrl'			// Composant appelé dans le controller de topicsDetail
      },
      bindings: {
        topicStates: '<',		// <Object> (One-way binding) Objet du TopicStates
        //OU
        topic: '<',				// <Object> (One-way binding) Objet du Topic, sert à créer un TopicStates si pas de TopicStates lors de l'appel du composant
        index: '<',
        //-----------------------------------------------------------------------------
        customPreference: '<',	// <Object> (One-way binding) Objet des préférences d'affichage entres autres
        topicDetail: '@',		// <String> (NoBinding) Permet de savoir si le topc doit etre afficher en mode page detail
        condensedDisplay: '<',	// Permet de savoir si on doit faire un affichage plus condensé
        onUpdate: '&',			// <Function> Executé lors de la modification d'un TopicStates
        onEmit: '&'
      },
      controllerAs: 'topicstateslignectrl',
      controller: TopicStatesLigneController
    });

  /*@ngInject*/
  function TopicStatesLigneController(COLLAB_CONF, TagService, ModalsService, FiltreService, TopicService, $rootScope, UtilsService, TopicStatesService, ContenuService, sweet, ErreurCollabService) {

    var ctrl = this;

    ctrl.evenement = null;
    ctrl.withDemandes = false;

    ctrl.notMobile = UtilsService.notMobile;
    //ctrl.parseMessage = parseMessage;					// Recupère la prévisualisation du message
    ctrl.getLastDate = UtilsService.getLastDate;		// Recupère la date la plus récente du contenu entre création et modification
    ctrl.parseLastContenuDate = parseLastContenuDate;	// Info sur la date du dernier contenu
    ctrl.getTooltipEvenement = getTooltipEvenement;		// Texte tooltip du label événement
    ctrl.getTooltipLuNonLu = getTooltipLuNonLu;			// Texte du tooltip du bouton marquer lu/non lu
    ctrl.onClickLuNonLu = onClickLuNonLu;				// Lors du clique sur le bouton permetant de lire ou remettre en non lu un topic
    ctrl.onClickCloture = onClickCloture;				// Lors du clique sur le bouton pour cloturer
    ctrl.onClickTag = onClickTag;				        // Lors du clique sur un tag
    ctrl.closeListeDestinataire = closeListeDestinataire;
    ctrl.voirPlus = voirPlus;
    ctrl.onAfterAddDestinataire = onAfterAddDestinataire;
    ctrl.onBeforeAddDestinataire = onBeforeAddDestinataire;
    ctrl.onErrorAddDestinataire = onErrorAddDestinataire;

    ctrl.getIconTag = getIconTag;
    ctrl.getClassBadge = getClassBadge;
    ctrl.displayLibelle = displayLibelle;

    ctrl.$onInit = function () {
      if(!_.isNil(ctrl.listTopicStatesCtrl))      ctrl.displayDetailsInTopicList = ctrl.listTopicStatesCtrl.displayDetailsInTopicList;
      else if(!_.isNil(ctrl.listTopicStateCtrl))  ctrl.displayDetailsInTopicList = ctrl.listTopicStateCtrl.displayDetailsInTopicList;

      initPreference();

      // Si le TopicStates n'est pas définie
      if (angular.isUndefined(ctrl.topicStates)) {
        // Si Topic est définie
        if (angular.isDefined(ctrl.topic)) {
          // Crée un nouveau TopicStates
          var topicStates = TopicStatesService.newTopicStates();
          // Set le topic dans le TopicStates tout neuf
          topicStates.setTopic(ctrl.topic);

          ctrl.topicStates = topicStates;	// Attribut le nouveau topicStates à la variable topicStates du composant
          ctrl.topic = null;				// Vide la variable topic qui ne sert plus
        }
      }
      //console.log(ctrl.topicStates);


      // Si on demande le topic en version pas detail
      if (ctrl.topicDetail === 'true') {
        ctrl.isTopicDetail = true;
        initTagsType();
      }
      else {
        ctrl.isTopicDetail = false;
        initEvent();
      }
    };

    ctrl.$onChanges = function(changes){
      if(_.isObject(changes.customPreference) && !changes.customPreference.isFirstChange() && changes.customPreference.currentValue){
        ctrl.customPreference = changes.customPreference.currentValue;
        initPreference();
      }
      if(_.isObject(changes.topicStates) && !changes.topicStates.isFirstChange() && changes.topicStates.currentValue){
        ctrl.topicStates = changes.topicStates.currentValue;
        initEvent();
        initTagsType();
      }
    };

    function initEvent(){
      if(!_.isNil(ctrl.topicStates.getTopic().getEvenement())){
        ctrl.evenement = ctrl.topicStates.getTopic().getEvenement();
        //ctrl.destMenage = ctrl.evenement.getDestinataireMenage();
        //ctrl.destTechnique =ctrl.evenement.getDestinataireTechnique();

        if(ctrl.topicStates.getTopic().getTopicInformations().getNbDevis() != 0 ||
          ctrl.topicStates.getTopic().getTopicInformations().getNbInterventions() != 0 ||
          ctrl.topicStates.getTopic().getTopicInformations().getNbContrats() != 0)
          ctrl.withDemandes = true;
        else ctrl.withDemandes = false;
      }

      if(_.isArray(ctrl.topicStates.getTopic().tagsEvenement) && ctrl.topicStates.getTopic().tagsEvenement.length){
        ctrl.topicStates.getTopic().tagsEvenement.map(function(t){
          t.selected = TagService.tagExistIn($rootScope.current.filtreTopics.tags, t);
          return t;
        });
      }
      initTopicStates(ctrl.topicStates);
    }

    function initTopicStates(topicState){
      /*
      if(_.isArray(topicStates) && topicStates.length) {
        topicStates = topicStates.map(function(topicState){
          if(_.isObject(topicState) && _.isObject(topicState.getTopic()) && _.isArray(topicState.getTopic().getContenus()) && topicState.getTopic().getContenus().length) {
            var contenu = topicState.getTopic().getContenus()[0];
            var msg = null;
            if(contenu.isContenuMessage()) msg = contenu.message;
            else if(contenu.isContenuEvenement()) msg = contenu.objet;
            else if(contenu.isContenuDevisInter()) msg = contenu.descriptionDevis;
            if(_.isNil(msg)){
              topicState.getTopic().getContenus()[0].previsualisationMsg = UtilsService.parseObjetMessage(msg);
            }
          }
          return topicState;
        });
      }*/

      if(_.isObject(topicState) && _.isObject(topicState.getTopic()) && _.isArray(topicState.getTopic().getContenus()) && topicState.getTopic().getContenus().length) {
        var contenu = topicState.getTopic().getContenus()[0];
        var msg = null;
        if(contenu.isContenuMessage()) msg = contenu.message;
        else if(contenu.isContenuEvenement()) msg = contenu.objet;
        else if(contenu.isContenuDevisInter()) msg = contenu.descriptionDevis;

        // Si pas de message alors prend l'objet de l'événement si existe
        if(_.isNil(msg) && !_.isNil(topicState.getTopic().getEvenement()))  {
          var objetSplit = topicState.getTopic().getEvenement().splitObjet();
          if(!_.isNil(objetSplit.texteObjet)) msg = UtilsService.stripTags(objetSplit.texteObjet);
        }

        if(!_.isNil(msg)) topicState.getTopic().getContenus()[0].previsualisationMsg = UtilsService.parseObjetMessage(msg);
      }
    }

    function initPreference(){
      ctrl.displayTagsInTopicsList = true;
      ctrl.displayDemandesInTopicsList = false;
      if (_.isObject(ctrl.customPreference)) {
        if (ctrl.customPreference.hasOwnProperty('displayTagsInTopicsList')) {
          ctrl.displayTagsInTopicsList = ctrl.customPreference.displayTagsInTopicsList;
        }
        if (ctrl.customPreference.hasOwnProperty('displayDemandesInTopicsList')) {
          ctrl.displayDemandesInTopicsList = ctrl.customPreference.displayDemandesInTopicsList;
        }
      }
    }

    function initTagsType(){
      ctrl.raisonSociale = null;
      ctrl.tagTypePortefeuille = null;
      ctrl.tagTypeImmeuble = null;
      ctrl.tagTypeLot = null;
      ctrl.tagTypeLocataire = null;
      ctrl.tagTypeProprietaire = null;
      ctrl.tagTypeCoproprietaire = null;
      ctrl.tagTypeLotMulticamp = null;
      ctrl.tagTypeLieuCommun = null;
      ctrl.tagTypeStatut = null;
      ctrl.tagTypePresence = null;
      if(_.isObject(ctrl.topicStates) && _.isObject(ctrl.topicStates.getTopic()) && ctrl.topicStates.getTopic().hasTagsType()){
        var tagsType = ctrl.topicStates.getTopic().getTagsType();
        for(var i = 0; i < tagsType.length; i++){
          var tagType = tagsType[i];
          tagType.selected = TagService.tagExistIn($rootScope.current.filtreTopics.tags, tagType);
          if(tagType.isTagImmeuble()) ctrl.tagTypeImmeuble = tagType;
          if(tagType.isTagLot()) ctrl.tagTypeLot = tagType;
          if(tagType.isTagLocataire()) ctrl.tagTypeLocataire = tagType;
          if(tagType.isTagProprietaire()) ctrl.tagTypeProprietaire = tagType;
          if(tagType.isTagCoproprietaire()) ctrl.tagTypeCoproprietaire = tagType;
          if(tagType.isTagStatut()) ctrl.tagTypeStatut = tagType;
          if(tagType.isTagPresence()) ctrl.tagTypePresence = tagType;
          if(tagType.isTagPortefeuille()) ctrl.tagTypePortefeuille = tagType;
          if(tagType.isTagTypeTechnicien()) ctrl.tagTypeTechnicien = tagType;
          if(tagType.isTagTypeMenage()) ctrl.tagTypeMenage = tagType;
          if(tagType.isTagLotMulticamp()) ctrl.tagTypeLotMulticamp = tagType;
          if(tagType.isTagLieuCommun()) ctrl.tagTypeLieuCommun = tagType;

          if(_.isArray(ctrl.topicStates.getTopic().tagsEvenement) && ctrl.topicStates.getTopic().tagsEvenement.length){
            ctrl.topicStates.getTopic().tagsEvenement = UtilsService.removeWith(ctrl.topicStates.getTopic().tagsEvenement, {'libelle': tagType.libelle});
          }
        }

        if(!_.isNil(ctrl.tagTypePortefeuille) &&
          !_.isNil(ctrl.tagTypePortefeuille.getPortefeuille()) &&
          !_.isNil(ctrl.tagTypePortefeuille.getPortefeuille().getPortefeuilleInfos()) &&
          !_.isNil(ctrl.tagTypePortefeuille.getPortefeuille().getPortefeuilleInfos().getRaisonSociale())) {
          ctrl.raisonSociale = ctrl.tagTypePortefeuille.getPortefeuille().getPortefeuilleInfos().getRaisonSociale();
        }
      }
    }

    function getIconTag(tag){
      var libelle = '';
      if(tag.hasOwnProperty('typeTag')) libelle = tag.typeTag.libelle;
      var classCss = TagService.getIconForTagType(libelle);
      if(_.isString(classCss)) {
        classCss += ' fa-fw';
        if(tag.selected) {
          //classCss += ' warning';
        }
      }

      return classCss;
    }

    function getClassBadge(tag){
      return TagService.getClassBadge(tag);
    }

    function displayLibelle(tag){

      if(tag.hasOwnProperty('libelle')) {
        if(UtilsService.startsWith(tag.libelle, '#')) {
          return angular.copy(tag.libelle.slice(1));
        }
        return tag.libelle;
      }
      return '?';
    }

    /**
     * Recupère la prévisualisation du message
     * @param topicState
     * @returns {*}
     */
    /*
    function parseMessage(topicState) {
      var msg = UtilsService.getPrevisualisationMessage(topicState);
      return UtilsService.parseObjetMessage(UtilsService.replaceAll('img-responsive', '', msg));
    }*/

    /**************** Textes tooltip ***************/

    /**
     * Texte tooltip du label événement
     * @param topicState
     * @returns {string}
     */
    function getTooltipEvenement(topicState) {
      var tooltip = 'Topic avec événement';
      if (topicState.getTopic().isUrgent()) {
        tooltip += ' urgent !';
      }
      return tooltip;
    }

    /**
     * Texte du tooltip du bouton marquer lu/non lu
     * @param topicState
     * @returns {string}
     */
    function getTooltipLuNonLu(topicState) {
      var tooltip = 'Marquer comme lu';
      if (topicState.getTopic().lu) {
        tooltip = 'Marquer comme non lu';
      }
      return tooltip;
    }

    /**************** Fin Textes tooltip ***************/

    function parseLastContenuDate(date){
      return UtilsService.dateMomentParser(date);
    }

    /**
     * Lors du clique sur le bouton permetant de lire ou remettre en non lu un topic
     * @param topicStates
     */
    function onClickLuNonLu(topicStates) {

      var callBack = function () {
        topicStates.loadingChangeState = false;
        if (topicStates.getTopic().lu && $rootScope.current.nonLuEnable) {
          if(ctrl.listTopicStatesCtrl) {
            ctrl.listTopicStatesCtrl.listTopicStates = UtilsService.removeWith(ctrl.listTopicStatesCtrl.listTopicStates, {'idTopicState': topicStates.idTopicState});
          }
          if(ctrl.listTopicStateCtrl) {
            ctrl.listTopicStateCtrl.listTopicStates = UtilsService.removeWith(ctrl.listTopicStateCtrl.listTopicStates, {'idTopicState': topicStates.idTopicState});
          }
        }
      };

      topicStates.loadingChangeState = COLLAB_CONF.MINI_LOADING;

      if (topicStates.getTopic().lu) {
        topicStates.getTopic().addToNonLu().then(callBack);
      } else {
        topicStates.getTopic().lire().then(callBack);
      }
    }

    function onClickCloture(){
      TopicService.clotureOrReouvre(ctrl.topicStates.getTopic()).then(function(topicState){
        if(_.isObject(topicState) && topicState.isModel) {
          ctrl.topicStates.setState(topicState.getState());
          ctrl.topicStates.getTopic().setDateCloture(topicState.getTopic().getDateCloture());
        }
      });
    }

    function onClickTag(tag){
      if(_.isObject(tag) && !$rootScope.openInVisicop && !$rootScope.inIframe) {
        if(_.isObject(tag.clickTag)) tag = tag.clickTag;
        if(tag.selected) TagService.deleteTagFromTags($rootScope.current.filtreTopics.tags, tag);
        else $rootScope.current.filtreTopics.tags = _.unionWith( $rootScope.current.filtreTopics.tags, [tag], _.isEqual);

        //if(_.isObject($rootScope.current.tmpNewEvenement.tagsForOrigine))
        //  $rootScope.current.tmpNewEvenement.tagsForOrigine.disableEventCreation = true;

        ctrl.onEmit({obj: {action: 'onFiltreByTag', filtre: $rootScope.current.filtreTopics, tag: tag}});
        $rootScope.$broadcast('filtreTopicList', {
          objFiltre: $rootScope.current.filtreTopics,
          //gotToState: 'topics.tous',
          //channel: -1,
          reload: false,
          notifyAndReload: true
        });
      }
    }

    function closeListeDestinataire() {
      ctrl.listeDestinataireOpenned = false;
    }

    function voirPlus() {

      ctrl.displayDemandesInTopicsList = !ctrl.displayDemandesInTopicsList;
      if(ctrl.displayDemandesInTopicsList) {
        ctrl.loadingEvent = true;
        TopicService
          .getEvenementForIdTopic(ctrl.topicStates.getTopic().getIdTopic())
          .then(function(contenuEvenement){
            ctrl.evenement = contenuEvenement;
          })
          .catch(function(msg){
            ModalsService.alertErreur(msg);
          })
          .finally(function() {
            ctrl.loadingEvent = false;
            delete ctrl.topicStates.pending;
          });
      }
    }

    function onAfterAddDestinataire() {
      sweet.close();
    }

    function onBeforeAddDestinataire(obj) {
      if (obj.hasOwnProperty('arrayContacts')) {
        if (angular.isArray(obj.arrayContacts) && obj.arrayContacts.length) {
          var text = '<strong>Ajout des destinataires suivants :</strong><br />';
          for (var i = 0; i < obj.arrayContacts.length; i++) {
            var dest = obj.arrayContacts[i];
            if(dest.type === 'collab') {
              if(dest.hasOwnProperty('user') && dest.user.isModel) {
                text += '<div>'+dest.user.getFichePerso().getNomPrenom()+'</div>';
              }
            }
            else {
              text += '<div>'+dest.destinataire+'</div>';
            }
          }

          text += '<div class="loadingMiddle center-block"></div>';

          sweet.show({
            title: 'Veuillez patienter...',
            text: text,
            type: 'info',
            html: true,
            showConfirmButton: false,
            showCancelButton: false
          });
        }
      }
    }

    function onErrorAddDestinataire(obj){
      sweet.close();
      if (obj.hasOwnProperty('msg')) {
        ErreurCollabService.alert('Problème...', obj.msg);
      }
    }

  }
})();
