'use strict';

/**
 * @ngdoc service
 * @name collaboreApp.DocumentsTopicService
 * @description
 * # DocumentsTopicService
 * Service in the collaboreApp.
 */

angular.module('collaboreApp').service('DocumentsTopicService', ['COLLAB_CONF','COLLAB_VALUES','$rootScope','$q','$window', '$filter', 'Document','DocumentsAjax','UtilsService', function(COLLAB_CONF,COLLAB_VALUES,$rootScope,$q,$window, $filter, Document, DocumentsAjax,UtilsService) {

  var scope = this;
  scope._pool = null;

  var init = function(){
    scope._pool = {
      categorie: [
        {
          type: 'topic',
          libelle: 'Dossier',
          documents:[]
        },
        {
          type: 'evenement',
          libelle: 'Evenement',
          documents:[]
        },
        {
          type: 'factures',
          libelle: 'Factures',
          groupements: [],
          documents:[]
        },
        {
          type: 'devis',
          libelle: 'Devis',
          groupements: [],
          documents:[]
        },
        {
          type: 'sinistre',
          libelle: 'Sinistre',
          groupements: [],
          documents:[]
        },
        {
          type: 'courrier',
          libelle: 'Courriers',
          groupements: [],
          documents:[]
        },
        {
          type: 'autres',
          libelle: 'Autres',
          groupements: [],
          documents:[]
        },
        {
          type: 'contrats',
          libelle: 'Contrats',
          groupements: [],
          documents:[]
        },
        {
          type: 'fournisseur',
          libelle: 'Docs Fournisseur',
          groupements: [],
          documents:[]
        },
        {
          type: 'comptable',
          libelle: 'Docs Comptable',
          groupements: [],
          documents:[]
        },
        {
          type: 'divers',
          libelle: 'Divers',
          documents:[]
        }
      ],
      nb: 0
    };
    return scope._pool;
  };
  init();

  scope.init = function(sourceTopic){
    if(sourceTopic.isModel){

      var topic = angular.copy(sourceTopic),
        hasDocument = false;

      scope._pool.nb = 0;

      // Si il y a un événement
      if(topic.eventIsPresent()){

        var event = topic.getEvenement();

        // Si le topic a un document
        if(topic.getDocument()) {
          hasDocument = true;
          cleanDocumentsCategorieWidget('topic');
          addDocumentIfNoExist('topic', topic.getDocument());
          scope._pool.nb++;
        }

        // Nombre de documents dans liste documents de l'event
        var nbDocumentsEvenement = event.getListeDocument().length;

        // Si le contenu événement contient des documents
        if(nbDocumentsEvenement){
          hasDocument = true;
          // Pour chaque document du contenu
          angular.forEach(event.getListeDocument(),function(document){
            addDocumentIfNoExist('evenement',document);
          });
        }

        var nbDocumentsEvenementWidget = scope.getNbDocumentInCategorie('evenement');

        // Si le nombre de doc de l'event est plus grand que le nombre de doc event déjà dans le widget
        if((nbDocumentsEvenementWidget>nbDocumentsEvenement) || nbDocumentsEvenement===0){
          nbDocumentsEvenementWidget = cleanDocumentsWidget('evenement',event.getListeDocument());
        }

        scope._pool.nb += nbDocumentsEvenementWidget;

        //--------------- Courrier -------------------
        // Nombre de documents dans les courrier de l'événement
        var docsCourriers = [];

        if(event.getListeCourrier().length) {
          for(var i = 0; i < event.getListeCourrier().length; i++) {
            if(event.getListeCourrier()[i].getDocument()) {
              addDocumentIfNoExist('courrier', event.getListeCourrier()[i].getDocument());
              docsCourriers.push(event.getListeCourrier()[i].getDocument());
            }
          }
        }

        var nbDocumentsCourrierWidget = scope.getNbDocumentInCategorie('courrier');

        // Si le nombre de doc de l'event est plus grand que le nombre de doc event déjà dans le widget
        if((nbDocumentsCourrierWidget > docsCourriers.length) || docsCourriers.length===0){
          nbDocumentsCourrierWidget = cleanDocumentsWidget('courrier', docsCourriers);
        }

        scope._pool.nb += nbDocumentsCourrierWidget;
        //--------------- Fin Courrier -------------------

        //--------------- Sinistre -----------------------
        if(event.getSinistre() && event.getSinistre().getInfosSinistre() && event.getSinistre().getInfosSinistre().getDocumentDeclarationSinistre()) {
          addDocumentIfNoExist('sinistre', event.getSinistre().getInfosSinistre().getDocumentDeclarationSinistre());
          hasDocument = true;
          scope._pool.nb += cleanDocumentsWidget('sinistre', [event.getSinistre().getInfosSinistre().getDocumentDeclarationSinistre()]);
        }
        else cleanDocumentsCategorieWidget('sinistre');
        //--------------- Fin Sinistre -------------------



        // Nombre de documents de type devis
        var nbDocumentsDevisAutresByGroupementWidget = 0,
          nbDocumentsDevisFournisseurByGroupementWidget = 0,
          nbDocumentsDevisComptableByGroupementWidget = 0,
          nbDocumentsFactureByGroupementWidget = 0,
          listeGroupements = {};

        var listeDocumentsDevis = [];
        var listeDocumentsFacture = [];
        var listeDocumentsDevisAutres = [];
        var listeDocumentsFournisseur = [];
        var listeDocumentsComptable = [];
        var listeDocumentsContrats = [];

        // Si le contenu événement contient une liste de devis
        if(event.getListeDevis().length || event.getListeContrats().length){

          // Pour chaque devis du contenu événement
          angular.forEach(event.getListeDevis(),function(devis){

            listeGroupements[devis.groupement] = devis.groupement;

            // Si il y a un devis
            if(devis.documentDevis){

              devis.documentDevis.isDevis = true;
              devis.documentDevis.accepte = false;
              devis.documentDevis.refuse = false;
              devis.documentDevis.attente = false;

              // Si devis accepte
              if(devis.isAccepte()){
                devis.documentDevis.accepte = true;
              }
              // Si devis en attente
              else if(devis.isWaiting()){
                devis.documentDevis.attente = true;
              }
              // Si devis refusé
              else if(devis.isRefuse()){
                devis.documentDevis.refuse = true;
              }

              hasDocument = true;
              listeDocumentsDevis.push(devis.documentDevis);
              addDocumentIfNoExist('devis',devis.documentDevis,devis.groupement);
            }

            // Si il y a un devis signé
            if(devis.documentDevisSigne){

              devis.documentDevisSigne.isDevis = true;
              devis.documentDevisSigne.signe = true;

              hasDocument = true;
              listeDocumentsDevis.push(devis.documentDevisSigne);
              addDocumentIfNoExist('devis',devis.documentDevisSigne,devis.groupement);
            }

            // Si il y a une facture
            if(devis.getFacture() && devis.getFacture().getListeDocument().length){
              for(var fd = 0; fd < devis.getFacture().getListeDocument().length; fd++){
                var facture = devis.getFacture().getListeDocument()[fd];
                listeDocumentsFacture.push(facture);
                addDocumentIfNoExist('factures',facture,devis.groupement);
              }
              hasDocument = true;
            }

            // Pour chaque devis parcour la liste des autres documents
            angular.forEach(devis.getListeDocument(),function(autreDocument){
              //listeAutresDocumentsGroupements[devis.groupement] = devis.groupement;
              hasDocument = true;
              listeDocumentsDevisAutres.push(autreDocument);
              addDocumentIfNoExist('autres',autreDocument,devis.groupement);
            });

            // Si il y a des documents envoyé par le fournisseur
            if(devis.listeDocumentFournisseur.length){
              // Pour chaque devis parcour la liste des autres documents fournisseur
              angular.forEach(devis.getListeDocumentFournisseur(),function(autreDocumentFournisseur){
                hasDocument = true;
                listeDocumentsFournisseur.push(autreDocumentFournisseur);
                addDocumentIfNoExist('fournisseur',autreDocumentFournisseur,devis.groupement);
              });
            }

            // Si il y a des documents comptable
            if(devis.listeDocumentComptable.length){
              // Pour chaque devis parcour la liste des autres documents comptable
              angular.forEach(devis.getListeDocumentComptable(),function(autreDocumentComptable){
                hasDocument = true;
                listeDocumentsComptable.push(autreDocumentComptable);
                addDocumentIfNoExist('comptable',autreDocumentComptable,devis.groupement);
              });
            }
          });

          // Pour chaque contrat du contenu événement
          for(var c = 0; c < event.getListeContrats().length; c++) {
            var contrat = event.getListeContrats()[c];
            listeGroupements[contrat.groupement] = contrat.groupement;

            var listContratsFournisseurSigne = contrat.getListDocumentContratFournisseurSigne();

            // Si il y des contrats signé pas le fournisseur
            if(listContratsFournisseurSigne.length){

              for(var d = 0; d < listContratsFournisseurSigne.length; d++) {
                var doc = listContratsFournisseurSigne[d];

                doc.accepte = false;
                doc.refuse = false;
                doc.attente = false;

                if(doc.isContratFournisseurAccepte()){
                  doc.accepte = true;
                }
                else if(doc.isContratFournisseurRefuse()){
                  doc.refuse = true;
                }
                else if(doc.isContratFournisseur()){
                  doc.attente = true;
                }
              }
              hasDocument = true;
              listeDocumentsContrats.push(doc);
              addDocumentIfNoExist('contrats', doc, contrat.groupement);
            }

            var listContratsAgenceSigne = contrat.getListDocumentContratAgenceSigne();

            // Si il y a des contrats signé par l'agence
            if(listContratsAgenceSigne.length){

              for(var e = 0; e < listContratsAgenceSigne.length; e++) {
                var docAgenceSigne = listContratsAgenceSigne[e];

                docAgenceSigne.signe = true;
                hasDocument = true;
                listeDocumentsContrats.push(docAgenceSigne);
                addDocumentIfNoExist('contrats', docAgenceSigne, contrat.groupement);
              }
            }

            // TODO : Voir pour les contrats avec le type signé_accepté ou signé_refusé
            // Parcour la liste des documents fournisseur sans prendre les contrats agence signé
            if(contrat.getListeDocument().length) {
              for(var j = 0; j < contrat.getListeDocument().length; j++) {
                var docAutre = contrat.getListeDocument()[j];
                if(!docAutre.isContratAgence()) {
                  hasDocument = true;
                  listeDocumentsDevisAutres.push(docAutre);
                  addDocumentIfNoExist('autres', docAutre, contrat.groupement);
                }
              }
            }

            // Parcour la liste des documents fournisseur sans prendre les contrats signé par le fournisseur
            if(contrat.getListeDocumentFournisseur().length) {
              for(var f = 0; f < contrat.getListeDocumentFournisseur().length; f++) {
                var docFournisseur = contrat.getListeDocumentFournisseur()[f];
                if(!docFournisseur.isContratFournisseur()) {
                  hasDocument = true;
                  listeDocumentsDevisAutres.push(docFournisseur);
                  addDocumentIfNoExist('fournisseur', docFournisseur, contrat.groupement);
                }
              }
            }

            // Si il y a des documents comptable
            if(contrat.getListeDocumentComptable().length){
              // Pour chaque devis parcour la liste des autres documents comptable
              for(var g = 0; g < contrat.getListeDocumentComptable().length; g++) {
                var docComptable = contrat.getListeDocumentComptable()[g];
                hasDocument = true;
                listeDocumentsComptable.push(docComptable);
                addDocumentIfNoExist('comptable', docComptable, contrat.groupement);
              }
            }
          }

          // Recupère le nombre de devis dans le widget
          var nbDocumentsDevisWidget = scope.getNbDocumentInCategorie('devis');

          // Recupère le nombre de contrats dans le widget
          var nbDocumentsContratsWidget = scope.getNbDocumentInCategorie('contrats');

          //console.log('nbDocumentsDevisWidget',nbDocumentsDevisWidget);

          // Si le nombre de doc des devis est plus grand que le nombre de doc devis déjà dans le widget
          if((nbDocumentsDevisWidget>listeDocumentsDevis.length) || listeDocumentsDevis.length===0){
            nbDocumentsDevisWidget = cleanDocumentsWidget('devis',listeDocumentsDevis);
          }

          // Si le nombre de doc des contrats est plus grand que le nombre de doc contrat déjà dans le widget
          if((nbDocumentsContratsWidget>listeDocumentsContrats.length) || listeDocumentsContrats.length===0){
            nbDocumentsContratsWidget = cleanDocumentsWidget('contrats',listeDocumentsContrats);
          }
          nbDocumentsDevisWidget += nbDocumentsContratsWidget;

          // Parcour la liste des groupements trouvé dans les devis qui ont des autres documents
          angular.forEach(listeGroupements,function(groupement){
            nbDocumentsFactureByGroupementWidget += scope.getNbDocumentInCategorie('factures',groupement);
            nbDocumentsDevisAutresByGroupementWidget += scope.getNbDocumentInCategorie('autres',groupement);
            nbDocumentsDevisFournisseurByGroupementWidget += scope.getNbDocumentInCategorie('fournisseur',groupement);
            nbDocumentsDevisComptableByGroupementWidget += scope.getNbDocumentInCategorie('comptable',groupement);
          });

          // Documents facture
          if((nbDocumentsFactureByGroupementWidget>listeDocumentsFacture.length) || listeDocumentsFacture.length===0){
            nbDocumentsFactureByGroupementWidget = cleanDocumentsWidget('factures',listeDocumentsFacture);
          }
          nbDocumentsDevisWidget += nbDocumentsFactureByGroupementWidget;

          // Documents de la demande de devis
          if((nbDocumentsDevisAutresByGroupementWidget>listeDocumentsDevisAutres.length) || listeDocumentsDevisAutres.length===0){
            nbDocumentsDevisAutresByGroupementWidget = cleanDocumentsWidget('autres',listeDocumentsDevisAutres);
          }
          nbDocumentsDevisWidget += nbDocumentsDevisAutresByGroupementWidget;

          // Documents fournisseur
          if((nbDocumentsDevisFournisseurByGroupementWidget>listeDocumentsFournisseur.length) || listeDocumentsFournisseur.length===0){
            nbDocumentsDevisFournisseurByGroupementWidget = cleanDocumentsWidget('fournisseur',listeDocumentsFournisseur);
          }
          nbDocumentsDevisWidget += nbDocumentsDevisFournisseurByGroupementWidget;

          // Documents Comptable
          if((nbDocumentsDevisComptableByGroupementWidget>listeDocumentsComptable.length) || listeDocumentsComptable.length===0){
            nbDocumentsDevisComptableByGroupementWidget = cleanDocumentsWidget('comptable',listeDocumentsComptable);
          }
          nbDocumentsDevisWidget += nbDocumentsDevisComptableByGroupementWidget;

          scope._pool.nb += nbDocumentsDevisWidget;
        }
      }

      var nbDocumentDiversTotalWidget = 0;

      // Si il y a des contenus de type message
      if(topic.getContenusMessage().length){
        var listeDocumentsDivers = [];
        // Pour chaque Contenu de type Message
        angular.forEach(topic.getContenusMessage(),function(contenuMessage){

          // Si le contenu à des documents
          if(contenuMessage.getListeDocument().length){
            hasDocument = true;
            // Pour chaque document du contenu
            angular.forEach(contenuMessage.getListeDocument(),function(document){
              listeDocumentsDivers.push(document);
              addDocumentIfNoExist('divers',document);
            });
          }
        });


        var nbDocumentsDiversWidget = scope.getNbDocumentInCategorie('divers');

        //console.log('nbDocumentsDiversWidget',nbDocumentsDiversWidget);
        //console.log('listeDocumentsDivers.length',listeDocumentsDivers.length);

        // Si le nombre de doc des devis est plus grand que le nombre de doc devis déjà dans le widget
        if((nbDocumentsDiversWidget>listeDocumentsDivers.length) || listeDocumentsDivers.length===0){
          nbDocumentsDiversWidget = cleanDocumentsWidget('divers',listeDocumentsDivers);
        }
        nbDocumentDiversTotalWidget += nbDocumentsDiversWidget;
      }

      scope._pool.nb += nbDocumentDiversTotalWidget;
      if(!hasDocument){
        //documents = false;
      }
    }else{
      init();
    }
    return scope.getListDocument();
  };

  /**
   * Recupère l'instances d'un document parle biais de son guid
   * @returns {Array} Tableau des objets document
   * @param guid
   */
  scope.getDocument = function(guid) {
    var retour = false;
    // Pour chaque liste de documents
    angular.forEach(scope._pool.categorie,function(obj,type){
      // Si retour est encore à false
      if(!retour){
        for(var i = 0;i < obj.documents.length;i++){

          if(obj.documents[i].getGuid() === guid){
            retour = obj.documents[i];
            retour.type = type;
            break;
          }
        }
        if(!retour && _.isArray(obj.groupements) && obj.groupements.length) {
          for(var g = 0; g < obj.groupements.length; g++){

            for(var d = 0; d < obj.groupements[g].documents.length; d++) {

              if (obj.groupements[g].documents[d].getGuid() === guid) {
                retour = obj.groupements[g].documents[d];
                retour.type = type;
                break;
              }
            }
          }
        }
      }
    });
    return retour;
  };

  /**
   * Récupère la l'objet avec les différente liste de document
   * @returns {{factures: Array, devis: Array, divers: Array, nb: number}|*}
   */
  scope.getListDocument = function() {
    return scope._pool;
  };

  /**
   * Recupère le nombre de document pour une categorie
   * @param type
   * @returns {number}
   */
  scope.getNbDocumentInCategorie = function(type,groupement){
    var nb = 0;

    // Parcour les categories pour voir si il existe
    for(var n = 0 ;n < scope._pool.categorie.length ; n++){
      if(scope._pool.categorie[n].type === type){
        if(!groupement) {
          nb = scope._pool.categorie[n].documents.length;
        }
        // Si le type à des groupements
        if(scope._pool.categorie[n].hasOwnProperty('groupements')){

          // Parcour les groupements
          for(var i = 0 ;i < scope._pool.categorie[n].groupements.length ; i++){
            if(groupement){
              if(scope._pool.categorie[n].groupements[i].libelle === groupement){
                nb += scope._pool.categorie[n].groupements[i].documents.length;
                break;
              }
            }else{
              nb += scope._pool.categorie[n].groupements[i].documents.length;
            }
          }
        }
        break;
      }
    }
    return nb;
  };

  /**
   * Permet de supprimer les document du widget qui ne sont plus dans le contenu
   * @param type
   * @param listeDocuments
   * @returns {number}
   */
   function cleanDocumentsWidget(type,listeDocuments){

    var nb = 0;
    // Parcour les categories
    for(var i = 0 ;i < scope._pool.categorie.length ; i++){
      // Si type trouvé
      if(scope._pool.categorie[i].type === type){

        // Parcour les documents de la categorie du widget
        for(var j = 0 ;j < scope._pool.categorie[i].documents.length ; j++){
          var exist = false;
          // Parcour les documents du type venant du contenu modifié
          for(var k = 0 ;k < listeDocuments.length ; k++){
            // Si le doc est bien dans les deux liste
            if(listeDocuments[k].guid === scope._pool.categorie[i].documents[j].guid){
              exist = true;
              break;
            }
          }
          // Si le doc n'existe plus dans la liste des documents du contenu modifié, on le supprime du widget
          if(!exist){
            scope._pool.categorie[i].documents.splice(j,1);
          }
        }
        // Recupère le nombre de document dans cette categorie apres la suppression
        nb = scope._pool.categorie[i].documents.length;

        // Si le type à des groupements
        if(scope._pool.categorie[i].hasOwnProperty('groupements')){
          // Parcour les groupements
          for(var l = 0 ;l < scope._pool.categorie[i].groupements.length ; l++){

            // Parcour les documents de chaque groupement de la categorie du widget
            for(var m = 0 ;m < scope._pool.categorie[i].groupements[l].documents.length ; m++){
              var exist2 = false;
              // Parcour les documents du type venant du contenu modifié
              for(var n = 0 ;n < listeDocuments.length ; n++){
                // Si le doc est bien dans les deux liste
                if(listeDocuments[n].guid === scope._pool.categorie[i].groupements[l].documents[m].guid){
                  exist2 = true;
                  nb++;
                  break;
                }
              }
              // Si le doc n'existe plus dans la liste des documents du contenu modifié, on le supprime du widget
              if(!exist2){
                scope._pool.categorie[i].groupements[l].documents.splice(m,1);
              }
            }

          }
        }

        break;

      }
    }
    return nb;
  }

  function cleanDocumentsCategorieWidget(type){

    var nb = 0;
    // Parcour les categories
    for(var i = 0 ;i < scope._pool.categorie.length ; i++){
      // Si type trouvé
      if(scope._pool.categorie[i].type === type){
        // Supprime tout les doc de la categorie
        scope._pool.categorie[i].documents = [];

        // Si le type à des groupements
        if(scope._pool.categorie[i].hasOwnProperty('groupements')){
          // Parcour les groupements
          for(var l = 0 ;l < scope._pool.categorie[i].groupements.length ; l++){
            scope._pool.categorie[i].groupements[l].documents = [];
          }
        }
        break;
      }
    }
    return nb;
  }

  /**
   * Ajoute un document à l'objet des documents si il n'existe pas déjà
   * @param type
   * @param document
   * @returns {*}
   */
  function addDocumentIfNoExist(type,document,groupement) {
    //console.log(type,groupement,document);
    var etat = {
      add: false,
      nbInWidget: 0
    };
    if(!document.isModel){
      document = new Document(document);
    }
    var typeExist = false;
    // Parcour la liste des categorie pour voir si la categorie à ajouter existe
    for(var i = 0 ;i < scope._pool.categorie.length ; i++){
      if(scope._pool.categorie[i].type === type){
        typeExist = true;
        break;
      }
    }
    // Si la categorie n'existe pas on met divers
    if(!typeExist){ type = 'divers';}

    var documentNoExist = true;
    // Parcour les categories pour voir si il existe
    for(var n = 0 ;n < scope._pool.categorie.length ; n++){
      if(scope._pool.categorie[n].type === type){

        //etat.nbInWidget = scope._pool.categorie[n].documents.length;

        var groupementExist = false,indexGroupement=null;
        if(groupement){
          // Parcour le tableau des groupements
          for(var g = 0 ;g < scope._pool.categorie[n].groupements.length ; g++){
            if(scope._pool.categorie[n].groupements[g].libelle === groupement){
              groupementExist = true;
              indexGroupement = g;
              break;
            }
          }
          if(!groupementExist){
            scope._pool.categorie[n].groupements.push({
              libelle: groupement,
              documents: []
            });
            indexGroupement = scope._pool.categorie[n].groupements.length-1;
          }
        }


        // Si il y a un groupement
        if(groupement){

          // Parcour les documents du bon groupement et du bon type pour voir le document existe
          for(var h = 0 ;h < scope._pool.categorie[n].groupements[indexGroupement].documents.length ; h++){
            // Si le document est trouvé
            if(document.getGuid()===scope._pool.categorie[n].groupements[indexGroupement].documents[h].guid){
              // Remplace le doc
              scope._pool.categorie[n].groupements[indexGroupement].documents[h] = document;
              documentNoExist = false;
              break;
            }
          }
          // Si le doc n'a pas été trouvbé on l'ajoute
          if(documentNoExist){
            scope._pool.categorie[n].groupements[indexGroupement].documents.push(document);
            etat.add = true;
          }
          break;

        }else{
          // Parcour les document du type pour voir le document existe
          for(var m = 0 ;m < scope._pool.categorie[n].documents.length ; m++){
            // Si le document est trouvé
            if(document.getGuid()===scope._pool.categorie[n].documents[m].guid){
              scope._pool.categorie[n].documents[m] = document;
              documentNoExist = false;
              break;
            }
          }
          // Si le doc n'a pas été trouvbé on l'ajoute
          if(documentNoExist){
            scope._pool.categorie[n].documents.push(document);
            etat.add = true;
          }
          break;
        }
      }
    }

    return etat;
  }


  scope.destroy = function(){
    init();
  };


}]);
