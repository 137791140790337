(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.AdminParametrageService
   * @description
   * # AdminParametrageService
   * Service in the collaboreApp.
   */
  angular
    .module('collaboreApp')
    .service('AdminParametrageService',
      AdminParametrageService
    );

  /** @ngInject */
  function AdminParametrageService(MainService, $q, ModalsService, ParametrageSuperAdminResource) {

    var _this = this;
    _this.updateParametre = updateParametre;

    /**
     * Permet de modifier un paramétrage
     * @param nomParam
     * @param valeurParam
     * @param diese
     * @returns {angular.IPromise<T>}
     */
    function updateParametre(nomParam, valeurParam, diese){
      var deferred = $q.defer();
      try {
        if(_.isNil(nomParam))     throw new Error("Il manque le nom du paramètre");
        if(_.isNil(valeurParam))  throw new Error("Il manque la valeur du paramètre");
        if(_.isNil(diese))        throw new Error("Il manque le diese de la société");
        var params = {
          action: 'updateParametre',
          nomParametre: nomParam,
          valeurParametre: valeurParam,
          diese: diese
        };
        ParametrageSuperAdminResource
          .post(params)
          .$promise
          .then(function onSuccess(json){
            deferred.resolve(MainService.convertJsonToModel(json, 'result', 'Parametrage', deferred));
          })
          .catch(function onError(err) {
            deferred.reject(err);
          });
      }
      catch (e) {
        deferred.reject(e.message);
      }
      return deferred.promise;
    }
  }
})();
