(function () {
  'use strict';

  /**
   * @desc Dashboard Pour capfun
   */

  angular
    .module('collaboreApp')
    .component('capfunDashboard', {
      templateUrl:  'app/capfun/dashboard/capfun-dashboard.component.html',
      controllerAs: 'capfundashboardctrl',
      controller:   CapfunDashboardController
    });

  /*@ngInject*/
  function CapfunDashboardController($rootScope, $state) {

    var _this = this;

    _this.$onInit = function(){
      if(!$rootScope.isCapfun) $rootScope.$broadcast('goAccueil');
      if($state.current.name === 'capfunDashboard') $state.transitionTo('capfunDashboard.recap');
      if(_.isObject($state.current.data)) _this.pageName = $state.current.data.namePage;
    };


    /**
     * TODO: Stocker ici la selection des portefeuilles (campings) pour éviter de rechoisir dans chaque composant enfant
     */
  }
})();
