(function() {

  'use strict';

  /**
   * @desc Composant recapMail
   * @example <recherche></recherche>
   */
  angular
    .module('collaboreApp')
    .component('listTopicElasticsearch',{
      templateUrl: 'app/topics/list-topic-elasticsearch/list-topic-elasticsearch.component.html',
      controllerAs: 'listtopicelasticsearchctrl',
      bindings: {
        query: '<',
        type: '<',
        page: '<',
        nbpp: '<'
      },
      controller: ListTopicElasticsearchCtrl
    });

  /** @ngInject */
  function ListTopicElasticsearchCtrl($rootScope, COLLAB_CONF, RechercheService, TopicService, UtilsService, ModalsService, $state){
    var ctrl = this;

    ctrl.searchByElasticSearchEnabled = false;
    ctrl.onChangeTri = onChangeTri;
    ctrl.onChangePagination = onChangePagination;

    ctrl.$onInit = function() {
      ctrl.searchByElasticSearchEnabled = UtilsService.getParametrage('searchByElasticSearchEnabled');

      ctrl.page = (ctrl.page) ? ctrl.page : COLLAB_CONF.DEFAULT_RECHERCHE_COLLAB_PAGE;
      ctrl.nbpp = (ctrl.nbpp) ? ctrl.nbpp : COLLAB_CONF.DEFAULT_RECHERCHE_COLLAB_NBPP;
      ctrl.listTri = (ctrl.searchByElasticSearchEnabled) ? COLLAB_CONF.ARRAY_TRI_RECHERCHE_LIBRE_COLLAB : COLLAB_CONF.RECHERCHE_COLLAB_ARRAY_TRI;
      ctrl.selectedTri = (ctrl.searchByElasticSearchEnabled) ? COLLAB_CONF.DEFAULT_TRI_RECHERCHE_LIBRE_COLLAB : COLLAB_CONF.DEFAULT_TRI_RECHERCHE_COLLAB;

      ctrl.loadingSearch = false;

      ctrl.infosTopics = {
        list: [],
        nbTotal: 0,
      };
      ctrl.maxSizePagination = 5;
      //if(!$rootScope.isCitya) search();
      search();
    };

    function search() {
      ctrl.erreur = null;
      ctrl.loadingSearch = true;

      var objParams = {
        q: ctrl.query,
        tri: ctrl.selectedTri,
        type: ctrl.type,
        page: ctrl.page,
        nbpp: ctrl.nbpp
      };

      getService()
        .searchCollab(objParams)
        .then(function(obj){
          if(obj.error) ctrl.erreur = obj.message;
          else {
            ctrl.infosTopics.list = parseSearchResult(ctrl.query, ctrl.type, obj);
            ctrl.infosTopics.nbTotal = obj.total;
            // Change l'url
            $state.go($state.current.name, objParams, {'location': true, 'reload': false, 'notify': false});
          }
        })
        .finally(function(){
          ctrl.loadingSearch = false;
        });
    }

    function getService(){
      if(ctrl.searchByElasticSearchEnabled) {
        return TopicService;
      }
      return RechercheService;
    }

    function parseSearchResult(query, type, objRetour){
      var listTopic = [];
      if(_.isObject(objRetour)) {
        var regEx = new RegExp(query, 'ig');

        var list = (_.isArray(objRetour.topics)) ? objRetour.topics : (_.isArray(objRetour.listTopicStates)) ? objRetour.listTopicStates : [];
        listTopic = list.map(function(topicStateOrTopic){
          if(_.isObject(topicStateOrTopic)) {
            var topic = null;
            // Si c'est un topicState
            if(topicStateOrTopic.isTopicStates) topic = topicStateOrTopic.topic;
            // Si c'est un topic
            else if(topicStateOrTopic.isTopic) topic =  topicStateOrTopic;


            if(!_.isNil(topic)) {
              return parseTopic(regEx, topic, type);
            }
          }
        });
      }
      return listTopic;
    }

    function parseTopic(regEx, topic, type){
      if(_.isObject(topic) && _.isArray(topic.contenus) && topic.contenus.length) {
        var contenu = topic.contenus[0];

        if(!_.isNil(contenu.previsualisationMsg))
          contenu.previsualisationMsg = UtilsService.truncate(contenu.previsualisationMsg, 50, '...');

        if(!_.isNil(regEx) && !_.isNil(type)) {

          if (type.charAt(0) == '1') {
            topic.titre = topic.titre.replace(regEx, function(match) {
              return '<mark>' + match + '</mark>';
            });
          }

          if (!_.isNil(contenu.previsualisationMsg) && type.charAt(2) == '1') {
            contenu.previsualisationMsg = contenu.previsualisationMsg.replace(regEx, function(match) {
              return '<mark>' + match + '</mark>';
            });
          }
        }
      }
      return topic;
    }

    function onChangeTri(){
      search();
    }

    function onChangePagination(){
      search();
    }

  }
})();
