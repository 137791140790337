'use strict';

/**
 * @ngdoc service
 * @name collaboreApp.TopicManager
 * @description
 * # HistoriqueAvancementService
 * Service in the collaboreApp.
 */

var HistoriqueAvancementService = function(COLLAB_CONF,$q,HistoriqueAvancementAjax) {

  var scope = this;

  /**
   * Ajoute une progression
   * @param objVal
   * @returns {a.fn.promise|*|promise.promise}
   */
  scope.add = function(objVal){

    var deferred = $q.defer();
    HistoriqueAvancementAjax.save(objVal,function(json){

      if(json.nb!=='0'){
        deferred.resolve(json.Historiques[0]);
      }else{
        deferred.reject('Pas de résultat');
      }

    },function(error){
      var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
      deferred.reject('Problème avec HistoriqueAvancementService.add(), '+msgErreur);
    });

    return deferred.promise;
  };

};
angular.module('collaboreApp').service('HistoriqueAvancementService', [
  'COLLAB_CONF',
  '$q',
  'HistoriqueAvancementAjax',
  HistoriqueAvancementService]);
