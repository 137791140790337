(function() {

  'use strict';

  /**
   * @desc Composant de la Mailbox
   * @example <mailbox-list></mailbox-list>
   */
  angular
    .module('collaboreApp')
    .component('mailboxDetail',{
      templateUrl: 'app/mailbox/mailbox-detail/mailbox-detail.component.html',
      controllerAs: 'mailboxdetailctrl',
      controller: MailboxDetailCtrl,
      bindings: {
        mailbox: '<',
        nbChecked: '<',
        superAdmin: '<',
        onOpenSearchTopic: '&',
        onAccept: '&',
        onClose: '&',
        onDelete: '&',
        onDeletedAttachement: '&'
      }
    });

  /** @ngInject */
  function MailboxDetailCtrl(ModalsService, MailboxService, UtilsService){
    var _this = this;

    _this.loading = false;
    _this.hasUnsafeSrc = false;
    _this.selectedMailbox = null;
    _this.onClickSafeImg = onClickSafeImg;
    _this.onClickOpenSearchTopic = onClickOpenSearchTopic;
    _this.onClickAccept = onClickAccept;
    _this.onClickDelete = onClickDelete;
    _this.onClickCloseDetail = onClickCloseDetail;
    _this.onEmitFromDocumentsComponent = onEmitFromDocumentsComponent;

    _this.$onInit = function() {
      if(_.isNil(_this.mailbox)) ModalsService.alertErreur('Il manque l\'email');
    };

    _this.$onChanges = function(changes){
      if(_.isObject(changes.mailbox) && _.isObject(changes.mailbox.currentValue)) {
        _this.hasUnsafeSrc = changes.mailbox.currentValue.hasUnsafeImg();
      }
    };

    function onClickSafeImg(){
      _this.hasUnsafeSrc = false;
      _this.mailbox.message = UtilsService.replaceAll('unsafe-src=', 'src=', _this.mailbox.message);
    }

    function onClickCloseDetail(){
      _this.selectedMailbox = null;
      _this.onClose();
    }

    function onClickOpenSearchTopic(){
      _this.onOpenSearchTopic({mailbox: _this.mailbox});
    }

    function onClickAccept(){
      _this.onAccept({mailbox: _this.mailbox});
    }

    function onClickDelete(){
      _this.onDelete({mailbox: _this.mailbox});
    }

    function onEmitFromDocumentsComponent(obj) {
      try {
        if (_.isNil(obj) || !_.isObject(obj)) throw new Error('Il manque l\'objet "obj" !');

        if(_.isObject(obj.document)){
          if(obj.action === 'delete'){
            var listGuid = [obj.document.guid];
            MailboxService
              .deleteAttachementMailbox(listGuid, _this.mailbox.idMailbox)
              .then(function(retour){

                if(_.isObject(retour.mailbox)) {
                  _this.mailbox.attachements = retour.mailbox.attachements;
                  _this.onDeletedAttachement({
                    mailbox: _this.mailbox,
                    document: obj.document
                  });
                }

                if(retour.errorDocuments.length){
                  console.error(retour);
                }

                /*
                if(retour.nbDoc === retour.nbDelete){
                  if(retour.errorDocuments.length){
                    ModalsService.alertErreur('Il y a eu un problème avec <strong>' + retour.errorDocuments.length + '</strong> document(s)');
                    console.error(retour);
                  }
                }
                else{
                  ModalsService.alertErreur('Il y a eu un problème lors de la suppression du document');
                  console.error('Problème lors de la suppression du document', retour);
                }*/
              })
              .catch(function(msg){
                ModalsService.alertErreur(msg);
              })
              .finally(function(){
                delete obj.document.loading;
              });
          }
        }
      }
      catch (err) {
        console.log('[MailboxDetailCtrl.onEmitFromDocumentsComponent] Erreur : ' + err.message);
      }
    }
  }
})();
