(function() {
	'use strict';

	/**
	 * @desc Composant pour tamponner un devis lors de la signature
	 * @example <signature-tampon></signature-tampon>
	 */

	angular
		.module('collaboreApp')
		.component('signatureTampon', {
			templateUrl: 'app/topics/components/signature-tampon.component.html',
			bindings : {
				onSelect: '&'
			},
			controllerAs: 'signaturetamponctrl',
			controller: SignatureTamponController
		});

	/*@ngInject*/
	function SignatureTamponController($q, UtilsService, TamponService, sweet){

		var ctrl = this;
		ctrl.editTampon = false;
		ctrl.tampon = null;
		ctrl.selectedTampon = false;
		ctrl.messageFlash = false;

		ctrl.showEditTampon = showEditTampon;		// Affiche le textarea pour editer un tampon
		ctrl.hideEditTampon = hideEditTampon;		// Cache le textarea pour editer un tampon
		ctrl.onSelectTampon = onSelectTampon;		// Lors de la selection d'un tampon
		ctrl.onDeselectTampon = onDeselectTampon;	// Lors de la deselection d'un tampon
		ctrl.saveNewTampon = saveNewTampon;			// Sauvegarde un nouveau tampon
		ctrl.onDeleteTampon = onDeleteTampon;			// Delete un tampon

		ctrl.$onInit = function() {
			getTampons();
		};

		function initNewTampon(tampon){
			if(tampon) {
				ctrl.tampon = tampon;
			}
			else {
				ctrl.tampon = {
					texte: '',
					texteNl2br: ''
				};
			}
		}

		/**
		 * Recupère la liste des tampons
		 */
		function getTampons(){
			ctrl.loading = true;
			ctrl.listTampons = [];
			TamponService.getTampons().then(function(tampons){

				for(var i = 0; i < tampons.length; i++){
					tampons[i].texteNl2br = UtilsService.nl2br(tampons[i].texte);
					ctrl.listTampons.push(tampons[i]);
				}

			},function(msg){
				ctrl.messageFlash = msg;
			}).finally(function(){
				ctrl.loading = false;
			});
		}


		/**
		 * Affiche le textarea pour editer un tampon
		 * @param tampon
		 */
		function showEditTampon(tampon){
			ctrl.editTampon = true;
			if(tampon) {
				ctrl.tampon = tampon;
			}
			else {
				initNewTampon();
			}
		}


		/**
		 * Cache le textarea tampon
		 */
		function hideEditTampon(){
			ctrl.editTampon = false;
			if(!ctrl.tampon.hasOwnProperty('idTampon')) {
				ctrl.tampon = null;
			}
		}

		/**
		 * Lors de la selection d'un tampon
		 * @param item
		 */
		function onSelectTampon(item) {
			ctrl.onSelect({objTampon: item});
		}

		/**
		 * Deselect le tampon
		 */
		function onDeselectTampon(){
			ctrl.tampon = null;
			ctrl.editTampon = false;
		}

		/**
		 * Sauvegarde un nouveau tampon
		 */
		function saveNewTampon(){

			ctrl.loading = true;

			if(ctrl.tampon.hasOwnProperty('idTampon')) {
				var tamponTmp = angular.copy(ctrl.tampon);

				deleteTampon(ctrl.tampon).then(function(){

					delete tamponTmp.idTampon;

					addTampon(tamponTmp).then(function(tampon){
						initNewTampon(tampon);
						onSelectTampon(tampon);
						getTampons();
					}).finally(function(){
						ctrl.editTampon = false;
						ctrl.loading = false;
					});
				});
			}
			else{
				addTampon(ctrl.tampon).then(function(tampon){
					initNewTampon(tampon);
					onSelectTampon(tampon);
					getTampons();
				}).finally(function(){
					ctrl.editTampon = false;
					ctrl.loading = false;
				});
			}
		}

		/**
		 * Permet de supprimer un tampon
		 * @param tampon
		 */
		function onDeleteTampon(tampon){
			sweet.show({
				title: 'Voulez-vous vraiment supprimer :',
				text: tampon.texte,
				type: 'info',
				showCancelButton: true,
				confirmButtonText: 'Oui !',
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {

				deleteTampon(tampon).then(function(){
					ctrl.tampon = null;
					ctrl.editTampon = false;
					getTampons();
					sweet.close();
				});

			});
		}

		function addTampon(tampon){
			var deferred = $q.defer();
			TamponService.addTampon(tampon.texte).then(function(tampon){
				tampon.texteNl2br = UtilsService.nl2br(tampon.texte);
				ctrl.listTampons.push(tampon);

				deferred.resolve(tampon);
			});
			return deferred.promise;
		}

		function deleteTampon(tampon){
			var deferred = $q.defer();
			TamponService.deleteTampon(tampon).finally(function(){
				deferred.resolve();
			});
			return deferred.promise;
		}
	}
})();
