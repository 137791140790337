(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .component('capfunDashboardReporting', {
      templateUrl: 'app/capfun/dashboard/reporting/capfun-dashboard-reporting.component.html',
      bindings: {
        search: '<',
        onEmit: '&'
      },
      controllerAs: 'capfundashboardreportingctrl',
      controller: CapfunDashboardReportingController
    });

  /*@ngInject*/
  function CapfunDashboardReportingController($locale, COLLAB_CONF, Tag, AnnuaireService, UtilsService, DashboardService, TagsService, TopicStatesService, ErreurCollabService) {

    var _this = this;
    var defaultValuesDatePicker = {
      startDate: moment().startOf('month'),
      endDate: moment().add(1, 'month').endOf('month'),
      label: 'Toute la période'
    };

    _this.readyForSearch = false;
    _this.getLigneStatus = getLigneStatus;
    _this.onSubmitSearch = onSubmitSearch;
    _this.onClickDownloadExcel = onClickDownloadExcel;
    _this.onChangeCloture = onChangeCloture;
    _this.clearDatesCreation = clearDatesCreation;
    _this.clearDatesCloture = clearDatesCloture;
    _this.onInitFromFiltreCapfunComponent = onInitFromFiltreCapfunComponent;
    _this.openAnnuaire = openAnnuaire;
    _this.removeTagFromFiltre = removeTagFromFiltre;
    _this.onEmitFromFiltreCapfunComponent = onEmitFromFiltreCapfunComponent;


    _this.filtreTags = [];
    _this.listIntervenant = [];
    _this.listStatus = [];
    _this.listeSelectedPortefeuille = [];
    _this.filtre = {
      show: true,
      classe: '2',                                  // Topic avec événement seulement
      cloturer: '0',                             // Cloturé (0: Tous, 1: Cloturé, 2: Non cloturé)
      dateCreation: {                         // Dates de création
        datepicker: 'dateCreation',
        showDates: false,
        isDefault: true,
        startDate: angular.copy(defaultValuesDatePicker.startDate),
        endDate: angular.copy(defaultValuesDatePicker.endDate),
        label: angular.copy(defaultValuesDatePicker.label)
      },
      dateCloture: {                         // Dates de cloture
        datepicker: 'dateCloture',
        showDates: false,
        isDefault: true,
        startDate: angular.copy(defaultValuesDatePicker.startDate),
        endDate: angular.copy(defaultValuesDatePicker.endDate),
        label: angular.copy(defaultValuesDatePicker.label)
      },
      idPortefeuilles: [],
      typesIntervenant: [],
      tagsStatut: [],
      tags: []
    }

    _this.order = 'nbJour';
    _this.sens = 'DESC';
    _this.limit = 20;

    // Tableau pour le choix du nombre de lignes
    _this.arrayNbResult = [
      10,
      15,
      20,
      50,
      100,
      200,
      500
    ];

    _this.reporting = {
      list: [],
      searchSubmited: false
    };

    _this.optionsDatepicker = {
      showDropdowns: true,
      autoApply: false,
      clearLabel: 'Effacer',
      linkedCalendars: false,
      locale: {
        format: 'DD/MM/YYYY',
        separator: ' au ',
        applyLabel: 'Ok',
        fromLabel: 'Du',
        toLabel: 'au',
        cancelLabel: 'Annuler',
        customRangeLabel: 'Personnaliser',
        daysOfWeek: $locale.DATETIME_FORMATS.SHORTDAY,
        firstDay: 1,
        monthNames: $locale.DATETIME_FORMATS.STANDALONEMONTH
      },
      eventHandlers: {
        'show.daterangepicker': function (ev) {
          if (ev.model.datepicker === 'dateCreation') _this.datePickerDateCreationShow = true;
          if (ev.model.datepicker === 'dateCloture') _this.datePickerDateClotureShow = true;
        },
        'hide.daterangepicker': function (ev) {
          if (ev.model.datepicker === 'dateCreation') _this.datePickerDateCreationShow = false;
          if (ev.model.datepicker === 'dateCloture') _this.datePickerDateClotureShow = false;
        },
        'apply.daterangepicker': function (ev) {
          UtilsService.parseDisplayDates(ev.model);
        }
      },
      ranges: {

        'Aujourd\'hui': [moment(), moment()],
        'Hier': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Cette semaine (depuis Lundi)': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
        'Les 7 derniers jours': [moment().subtract(7, 'days'), moment()],
        'Semaine dernière (Lun - Dim)': [moment().subtract(1, 'week').startOf('isoWeek'), moment().subtract(1, 'week').endOf('isoWeek')],
        'Les 14 derniers jours': [moment().subtract(14, 'days'), moment()],
        'Ce mois-ci': [moment().startOf('month'), moment().endOf('month')],
        'Les 30 derniers jours': [moment().subtract(30, 'days'), moment()],
        'Le mois dernier': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Toute la période': [angular.copy(defaultValuesDatePicker.startDate), angular.copy(defaultValuesDatePicker.endDate)]
        //'Toute la période': [moment().year(2000).dayOfYear(1).startOf('hour'), moment()]
      },
      opens: 'right'
    };

    _this.pagination = {
      nbTotal: 0,
      currentPage: 1,
      maxSize: 5,
      pageChanged: function () {
        onSubmitSearch();
      }
    };

    _this.$onInit = function () {
      if (!_.isNil(_this.search)) {
        var search = UtilsService.decodeSearch(_this.search);
        if(_.isObject(search)){
          if(!_.isNil(search.tags)){

            var tags = angular.fromJson(search.tags);
            /*
            if(_.isArray(tags) && !_.isEmpty(tags)){
              _this.listStatus = tags.reduce(function(listTagsStatut, tag){
                var instanceTag = new Tag(tag);
                if(instanceTag.isTagStatut()) listTagsStatut.push(instanceTag);
                return listTagsStatut;
              }, []);
            }*/
            console.log(tags);
          }

          if(!_.isNil(search.intervenants)){
            _this.listIntervenant = angular.fromJson(search.intervenants);
            console.log(_this.listIntervenant);
          }
        }
        //console.log(search);
        /*
        if(_this.filtre.dateCreation) {
          if(_.isObject(_this.filtre.dateCreation.startDate) && _this.filtre.dateCreation.startDate._isAMomentObject && _.isString(_this.filtre.dateCreation.startDate._d)) {
            _this.filtre.dateCreation.startDate = moment(_this.filtre.dateCreation.startDate._d);
          }
          if(_.isObject(_this.filtre.dateCreation.endDate) && _this.filtre.dateCreation.endDate._isAMomentObject && _.isString(_this.filtre.dateCreation.endDate._d)) {
            _this.filtre.dateCreation.endDate = moment(_this.filtre.dateCreation.endDate._d);
          }
          UtilsService.parseDisplayDates(_this.filtre.dateCreation);
        }*/
      }
    };


    function initSearchParams(goPageOne){
      var filtre = angular.copy(_this.filtre);

      // Si la date choisi est celle par default alors on supprime les dates
      if (filtre.dateCreation.isDefault) {
        filtre.dateCreation.startDate = null;
        filtre.dateCreation.endDate = null;
      }
      if (filtre.dateCloture.isDefault) {
        filtre.dateCloture.startDate = null;
        filtre.dateCloture.endDate = null;
      }

      if(goPageOne) _this.pagination.currentPage = 1;

      if(_.isArray(_this.filtreTags) && _this.filtreTags.length){
        _this.filtreTags.map(function(tag){
          filtre.tags.push(tag);
        });
        filtre.tags = _.uniqWith(filtre.tags, _.isEqual);
      }

      var params = {
        order: _this.order,
        sens: _this.sens,
        limit: _this.limit,
        page: _this.pagination.currentPage,
        filtre: filtre
      };

      //ConfigService.setFiltresTableauDevisDashboard(params);

      return params;
    }

    function getLigneStatus(topic) {
      var classRetour = '';
      if (topic.isCloture()) classRetour = 'cloture';

      var event = topic.getEvenement();
      if(_.isObject(event)){
        if(event.isEnAttente()) classRetour = 'bg-warning';
        else if(event.isProbleme()) classRetour = 'bg-danger';
        else if(event.isTermine()) classRetour = 'bg-success';
      }
      return classRetour;
    }

    /**
     * Lors de la validation de la recherche
     */
    function onSubmitSearch(goPageOne){
      var params = initSearchParams(goPageOne);
      _this.loading = true;
      _this.reporting.searchSubmited = true;
      DashboardService.searchReportingCapfun(params)
        .then(function (result) {
          _this.pagination.nbTotal = result.total;
          _this.reporting.list = result.topics;
          _this.infos = result.infos;
          _this.resultElasticSearch = result.resultElasticSearch;
        })
        .catch(function() {
          _this.pagination.nbTotal = 0;
          _this.reporting.list = [];
        })
        .finally(function(){
          _this.loading = false;
        });
    }

    function onClickDownloadExcel(){
      var params = initSearchParams();
      params.exportEXCEL = true;
      _this.loading = true;
      DashboardService.downloadExcelReportingCapfun(params)
        .then(function(data){
          var headers = data.headers;
          var windowUrl = (window.URL || window.webkitURL || window.mozURL);
          var downloadableBlob = windowUrl.createObjectURL(data.response);
          var fileNamePattern = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

          var a = document.createElement('a');
          a.href = downloadableBlob;
          a.download = fileNamePattern.exec(headers['content-disposition'])[1];
          a.target = '_blank';

          document.body.appendChild(a);

          a.click();

          $timeout(function(){
            document.body.removeChild(a);
            windowUrl.revokeObjectURL(downloadableBlob);
          }, 100);

        })
        .finally(function () {
          _this.loading = false;
        });
    }

    function onChangeCloture(){
      if(_this.filtre.cloturer != 1) clearDatesCloture();
    }

    function clearDatesCreation() {
      _this.filtre.dateCreation.startDate = angular.copy(defaultValuesDatePicker.startDate);
      _this.filtre.dateCreation.endDate = angular.copy(defaultValuesDatePicker.endDate);
      _this.filtre.dateCreation.label = angular.copy(defaultValuesDatePicker.label);
      _this.filtre.dateCreation.showDates = false;
      _this.filtre.dateCreation.isDefault = true;
    }

    function clearDatesCloture() {
      _this.filtre.dateCloture.startDate = angular.copy(defaultValuesDatePicker.startDate);
      _this.filtre.dateCloture.endDate = angular.copy(defaultValuesDatePicker.endDate);
      _this.filtre.dateCloture.label = angular.copy(defaultValuesDatePicker.label);
      _this.filtre.dateCloture.showDates = false;
      _this.filtre.dateCloture.isDefault = true;
    }

    function onInitFromFiltreCapfunComponent(obj){
      onEmitFromFiltreCapfunComponent(obj);
    }

    function openAnnuaire() {
      var obj = {
        'openFrom': 'dashboard',
        'stringTo': 'tag',
        //'limitDestinataire': 1,
        'defautTypeAnnuaire': 'perso',
        listPortefeuille: _this.listeSelectedPortefeuille
      };

      AnnuaireService.openAnnuaire(obj)
        .then(function (objRetour) {
          if (objRetour.isTag) _this.filtreTags = [objRetour.tag];
          else {
            // var objetsTags = TagService.genereTagsForType(objRetour);
            var objetsTags = TagsService.genereTagsAndFiches(objRetour)

            if (objetsTags.hasOwnProperty('addTag')) {
              var listeTagsSearch = [];
              if (objetsTags.addTag.hasOwnProperty('tagsType')) {

                // Cherche dans les tagType
                var listeTagsTypes = TopicStatesService.getTagsTypeAllowForSearchTopicCapfun(objetsTags);
                if (listeTagsTypes.length) angular.merge(listeTagsSearch, listeTagsTypes);

                //TagsService.getTagTypeLotMulticamp(objetsTags.addTag.tagsType)
              }

              // Si toujours pas de callTopicList à true, tante la recherche avec tag origin
              /*
              if (!callTopicList) {

                var tagOrig = TopicStatesService.getParamsSearchTopicByTagOrigin(objetsTags);
                if (tagOrig) listeTagsSearch.push(tagOrig);
              }*/

              // Si liste de tag trouvé alors recherche les topic avec
              if (listeTagsSearch.length) _this.filtreTags = listeTagsSearch;
            }
          }
        });
    }

    function removeTagFromFiltre(objUpdate) {
      console.log(objUpdate);
      if (objUpdate.hasOwnProperty('deleteTagFromContenu')) {
        var tagDel = objUpdate.deleteTagFromContenu;
        if (_this.filtreTags.length) _this.filtreTags = TagsService.deleteTagFromTags(_this.filtreTags, tagDel);
      }
    }

    function onEmitFromFiltreCapfunComponent(obj){
      try {
        if (!obj) throw new Error('Il manque le paramètre "obj"');
        if (_.isObject(obj)) {
          _this.readyForSearch = obj.isReady;

          _this.listeSelectedPortefeuille = [];
          _this.filtre.idPortefeuilles = [];
          _this.filtre.typesIntervenant = [];
          _this.filtre.tags = [];

          if(_.isArray(obj.listPortefeuilleSelected)) {
            _this.listeSelectedPortefeuille = obj.listPortefeuilleSelected;
            _this.filtre.idPortefeuilles = obj.listPortefeuilleSelected.map(function(portefeuille){
              return portefeuille.idPortefeuille;
            });
          }
          if(_.isArray(obj.listIntervenantSelected)) {
            _this.filtre.typesIntervenant = obj.listIntervenantSelected.map(function(intervenant){
              return intervenant.id;
            });
          }
          if(_.isArray(obj.listStatutSelected)) {
            _this.filtre.tagsStatut = obj.listStatutSelected.map(function(statut){
              return TagsService.genereTagTypeStatut(statut.libelle);
            });
          }
          if(!_.isNil(obj.presence)) {
            var presence = (obj.presence) ? COLLAB_CONF.TAG_TYPE_PRESENCE_OBLIGATOIRE : COLLAB_CONF.TAG_TYPE_PRESENCE_FACULTATIVE;
            _this.filtre.tags.push(TagsService.genereTagTypePresence(presence));
          }
        }
      }
      catch (err) {
        ErreurCollabService.error('[CapfunDashboardReportingController.onEmitFromFiltreCapfunComponent] Erreur : ' + err.message, err);
      }
    }
  }
})();
