(function() {

  'use strict';

  /**
   * @desc Composant de stockage temporaire de fichier
   * @example <doc-box></doc-box>
   */
  angular
    .module('collaboreApp')
    .component('docBox',{
      templateUrl: 'app/components/doc-box/doc-box.component.html',
      controllerAs: 'docboxctrl',
      bindings: {
        sidebar: '<',
        onEmit: '&',

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<'
      },
      controller: DocBoxCtrl
    });

  /** @ngInject */
  function DocBoxCtrl(COLLAB_CONF, COLLAB_VALUES, $timeout, $q, $scope, SharedDocument, TopicManagerService, DocumentManagerService, DocBoxService, ModalsService, UtilsService, FileUploader){
    var _this = this;

    _this.moment = moment;
    _this.loading = false;
    _this.options = {
      showTypeDoc: true,
      showListByDate: false,
      orderBy: 'nom'
    };
    _this.open = true;
    _this.listSharedDocument = [];
    _this.listPortefeuilleGedV2 = [];
    _this.canSendToGed = TopicManagerService.currentTopicHasGed;
    _this.eventModifiable = TopicManagerService.currentTopicIsModifiableByAgence;

    _this.onClickRefreshDocBox = onClickRefreshDocBox;
    _this.startDrag = startDrag;
    _this.onBeforeDrop = onBeforeDrop;
    _this.onDragOverFromFile = onDragOverFromFile;
    _this.onDragOverFromComponent = onDragOverFromComponent;
    _this.onDropDocumentDevisFromWidgetDocuments = onDropDocumentDevisFromWidgetDocuments;
    _this.onChangeDocumentCheckbox = onChangeDocumentCheckbox;
    _this.onClickShareWith = onClickShareWith;
    _this.onClickOpenDocument = onClickOpenDocument;
    _this.onClickDownloadDocument = onClickDownloadDocument;
    _this.onClickRenameDocument = onClickRenameDocument;
    _this.onClickSendDocumentToGed = onClickSendDocumentToGed;
    _this.onClickDeleteSharedDocument = onClickDeleteSharedDocument;
    _this.onClickClose = onClickClose;

    _this.$onInit = function() {
      _this.sendToComfactEnable = UtilsService.getParametrageComfact('allowSendToComfactfacture');
      $scope.$on('refreshDocBox', function(){
        getListDocBox();
      });
      init();
    };

    _this.$onChanges = function(changes) {
    };

    _this.$onDestroy = function() {
    };

    function init(){
      // Liste des documents selectionné avec la checkbox
      _this.listSharedDocumentSelected = [];

      if(_this.sidebar){
        _this.configScrollbar = {
          autoHideScrollbar: false,
          theme: 'dark-thin',
          advanced: {
            updateOnContentResize: true
          },
          scrollButtons: {
            scrollAmount: 'auto', // scroll amount when button pressed
            enable: false // enable scrolling buttons by default
          },
          axis: 'y', // enable 2 axis scrollbars by default
          setHeight: 150,
          scrollInertia: 100
        };
      }
      initUpload();
      getListDocBox();
    }

    function getListDocBox(){
      var deferred = $q.defer();
      _this.loading = true;

      iniListPortefeuilleGedV2();
      DocBoxService
        .getListSharedDocument()
        .then(function(listSharedDocument){
          iniListSharedDocument(listSharedDocument);
          deferred.resolve(listSharedDocument);
        })
        .catch(function(msg){
          deferred.reject(msg);
        })
        .finally(function(){
          _this.loading = false;

        });
      return deferred.promise;
    }

    function iniListPortefeuilleGedV2(){
      var portefeuilles = UtilsService.getCurrentUser().getListePortefeuille();
      _this.listPortefeuilleGedV2 = [];
      if(_.isArray(portefeuilles) && portefeuilles.length){
        _this.listPortefeuilleGedV2 = portefeuilles.map(function(portefeuille){
          if(portefeuille.gedV2IsEnabled()) return portefeuille;
        });
      }
    }

    function iniListSharedDocument(listSharedDocument){
      if(_.isArray(listSharedDocument)) {

        if( listSharedDocument.length) {
          for(var i = 0; i < listSharedDocument.length; i++){
            if(!listSharedDocument[i].isModel) listSharedDocument[i] = new SharedDocument(listSharedDocument[i]);

            /*
            if(_.isArray(shareDoc.getListSharedDocumentGroupe() && shareDoc.getListSharedDocumentGroupe().length){
              for(var j = 0; j < shareDoc.getListSharedDocumentGroupe().length; j++){
                var shareDocGroupe = shareDoc.getListSharedDocumentGroupe()[j];
              }
            }*/
          }
        }


        _this.listSharedDocument = listSharedDocument;

        if(_.isArray(_this.listSharedDocumentSelected) && _this.listSharedDocumentSelected.length && _this.listSharedDocument.length) {

          for(var d = 0; d < _this.listSharedDocument.length; d++){
            var sharedDoc = _this.listSharedDocument[d];
            for(var s = 0; s < _this.listSharedDocumentSelected.length; s++){
              var tempBoxSelected = _this.listSharedDocumentSelected[s];
              if(sharedDoc.idSharedDocument === tempBoxSelected.idSharedDocument){
                sharedDoc.selected = true;
                sharedDoc.$$hashKey = tempBoxSelected.$$hashKey;
                break;
              }
            }
          }
        }
      }
    }

    /**
     * Permet d'init les uploader des document devis de chaque demande
     * @param demande
     */
    function initUpload() {
      var modalConfirm = null;
      _this.uploader = new FileUploader({
        autoUpload: true,
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_UPLOAD_DOCUMENT,
        alias: 'fileName',
        removeAfterUpload: true,
        filters: [
          {
            name: 'sizeLimit',
            fn: function (item) {

              if (item.size <= COLLAB_CONF.SIZE_LIMIT_UPLOAD) {
                return true;
              } else {
                ModalsService.alertErreur('<span class="text-danger">' + item.name + '</span> trop gros !', '50 Mo Maximum');
              }
            }
          }/*,
          {
            name: 'allowExtension',
            fn: function (item) {
              var extensionsAllowed = ['pdf','jpg','png','jpeg','bmp','gif'];
              var type = item.type.slice(item.type.lastIndexOf('/') + 1);
              if (!UtilsService.contains(extensionsAllowed, type)) {
                ModalsService.alertErreur('<span class="text-danger">' + item.name + '</span> doit être un PDF ou une image !', 'PDF ou Image obligatoire');
              }
              else return true;
            }
          }*/
        ]
      });

      /**
       * Après l'ajout d'un fichier en pièce jointe
       * @param fileItem
       */
      _this.uploader.onAfterAddingFile = function afterAddingFile(fileItem) {
        _this.loading = true;
        fileItem.formData.push({action: 'uploadSharedDocument'});
        fileItem.formData.push({token: UtilsService.getCurrentUser().getToken()});
      };

      _this.uploader.onAfterAddingAll = function(addedItems){
        /*
        ModalsService
          .confirm("Partager avec l'agence ?", null, null, {btnConfirmText: 'OUI', btnCancelText: 'NON'})
          .then(function onSuccess(modal){
            modalConfirm = modal;
            if(_.isArray(addedItems) && !addedItems.length) {
              addedItems.map(function(fileItem){
                fileItem.formData.push({shared: true});
              });
            }
            _this.uploader.uploadAll();
          })
          .catch(function onReject(){

          });*/
      };

      /**
       * Lorsqu'un fichier est bien upload
       * @param fileItem
       * @param response
       */
      _this.uploader.onSuccessItem = function (fileItem, response) {
        if(response.success) {
          if(_.isArray(response.result)) iniListSharedDocument(response.result);
          else ModalsService.alertErreur('Erreur avec le retour lors de l\'envoie du document');
        }
        else ModalsService.alertErreur(response.message);
      };

      /**
       * Lorsque l'upload de toute la liste est terminée
       */
      _this.uploader.onCompleteAll = function () {
        _this.loading = false;
        /*
        if(!_.isNil(modalConfirm)) {
          modalConfirm.close();
        }*/
      };

      /**
       * Si erreur sur un item à upload
       */
      _this.uploader.onErrorItem = function (item, response, status, headers) {
      };

      _this.uploader.onError = function(response, status, headers){
        _this.loading = false;
      };
    }

    function onClickRefreshDocBox(){
      getListDocBox();
      _this.open = true;
    }

    /**
     * Lors du start d'un drag and drop
     * @param event
     * @param ui
     */
    function startDrag(event, ui) {
      angular.element(ui.helper).addClass('ui-draggable-helper');
    }

    function onBeforeDrop(event, ui, sharedDoc){
      if(_.isObject(event) && _.isObject(event.target)) {
        if(event.target.id !== 'docBoxDropZone'){
          /*
          console.log(event);
          console.log(ui);
          console.log(sharedDoc);
          */
        }
      }
    }

    function onDragOverFromFile(event) {
      _this.open = true;
    }

    function onDragOverFromComponent(){
      $timeout(function(){
        _this.open = true;
      });
    }

    /**
     * Permet d'ajouter à la tempBox un document déjà éxistant
     */
    function onDropDocumentDevisFromWidgetDocuments(){
      if(_.isObject(_this.documentDevisDrop)) {

        var exist = false;
        if(_.isArray(_this.listSharedDocument) && _this.listSharedDocument.length) {
          for(var i = 0; i < _this.listSharedDocument.length; i++){
            if(_this.listSharedDocument[i].document.guid === _this.documentDevisDrop.guid){
              exist = true;
              break;
            }
          }
        }

        if(!exist) {
          _this.loading = true;
          DocBoxService
            .addDocument(_this.documentDevisDrop.guid)
            .then(function(listSharedDocument){
              iniListSharedDocument(listSharedDocument);
            })
            .finally(function(){
              _this.loading = false;
            });
          /*
          var modalConfirm = null;
          ModalsService
            .confirm("Partager ?", null, null, {btnConfirmText: 'OUI', btnCancelText: 'NON'})
            .then(function onSuccess(modal){
              modalConfirm = modal;
            })
            .finally(function onFinally(){
              _this.loading = true;
              DocBoxService
                .addDocument(_this.documentDevisDrop.guid)
                .then(function(listSharedDocument){
                  iniListSharedDocument(listSharedDocument);
                })
                .finally(function(){
                  modalConfirm.close();
                  _this.loading = false;
                });
            });*/
        }
      }
    }

    /**
     * Lors du changement d'état de la checkbox du document
     * @param doc
     */
    function onChangeDocumentCheckbox(sharedDocument) {
      if (!sharedDocument.selected) {
        sharedDocument.selected = true;
        _this.listSharedDocumentSelected.push(sharedDocument);
      }
      else {
        sharedDocument.selected = false;
        //scope.documentsSelected = scope.documentsSelected.splice(index+1,1);
        _this.listSharedDocumentSelected = UtilsService.removeWith(_this.listSharedDocumentSelected, {$$hashKey: sharedDocument.$$hashKey});
      }
    }

    function onClickShareWith(sharedDocuments){

      var listIdShareDocument = sharedDocuments;
      if(_.isArray(sharedDocuments)) {
        listIdShareDocument = sharedDocuments.reduce(function(listIdShareDocument, sharedDoc) {
          if(sharedDoc.getCanShare()) listIdShareDocument.push(sharedDoc.idSharedDocument);
          return listIdShareDocument;
        }, []);
      }
      else if(_.isObject(sharedDocuments)) {
        if(!sharedDocuments.getCanShare()) {
          ModalsService.alertErreur("Vous n'avez pas les droits pour partager ce document");
          return;
        }
        listIdShareDocument = sharedDocuments.idSharedDocument;
      }


      if(_.isNil(listIdShareDocument) || !listIdShareDocument || (_.isArray(listIdShareDocument) && !listIdShareDocument.length)) {
        ModalsService.alertErreur("Aucun document à partager ou vous n'avez pas les droits sur les documents sélectionnés");
        return;
      }

      var options = {
        backdrop: 'static'
      };

      var params = {
        iconTitleModal: 'fa-share',
        textTitleModal: 'Attribuer à'
      };

      ModalsService
        .modalComponent('chooseCollaborateur', params, options)
        .then(function (objRetour) {
          if(_.isObject(objRetour) && _.isArray(objRetour.collaborateurs) && objRetour.collaborateurs.length) {
            var listeIdGroupe = objRetour.collaborateurs.map(function(groupe) {
              return groupe.idGroupe;
            });

            _this.loading = true;
            DocBoxService
              .shareWith(listIdShareDocument, listeIdGroupe)
              .then(function(listSharedDocument){
                iniListSharedDocument(listSharedDocument);
              })
              .catch(function(msg){
                ModalsService.alertErreur(msg);
              })
              .finally(function(){
                _this.loading = false;
              });

            console.log(listeIdGroupe);
          }
        })
        .catch(function (msg) {
          if (msg !== 'cancel') {
            ModalsService.alertErreur(msg);
          }
        });

    }

    /**
     * Lors du clique sur le bouton pour ouvrir un document
     * @param document
     */
    function onClickOpenDocument(sharedDocument) {
      DocumentManagerService.openDocument(sharedDocument.document);
    }

    /**
     * Telecharge un ou plusieur fichiers select ou non
     * @param documents
     */
    function onClickDownloadDocument(documents) {
      var guids = documents;
      if(_.isArray(documents)) {
        guids = [];
        guids = documents.map(function(sharedDocument) {
          return sharedDocument.document.guid;
        });
      }
      DocumentManagerService.loadingDownload('loadingWidgetTempDocBox');
      DocumentManagerService.downloadDocument(guids);
      _this.listSharedDocumentSelected = [];
    }

    /**
     * Permet de renommer un document
     * @param document
     */
    function onClickRenameDocument(sharedDocument){
      DocumentManagerService.renameDocument(sharedDocument.document, TopicManagerService.currentTopicStates.getTopic());
    }

    /**
     * Permet d'envoyer un ou plusieur document dans la GED
     * @param tempDoc
     */
    function onClickSendDocumentToGed(sharedDocuments){
      var documents = sharedDocuments;
      if(_.isArray(sharedDocuments)) {
        documents = [];
        documents = sharedDocuments.map(function(sharedDoc) {
          if(_.isArray(sharedDoc.usedInContenus) && sharedDoc.usedInContenus.length){
            if(sharedDoc.usedInContenus.length == 1) {
              sharedDoc.document.tmpIdContenu = sharedDoc.usedInContenus[0].idContenu;
              sharedDoc.document.tmpIdTopic = sharedDoc.usedInContenus[0].idTopic;
            }
          }
          return sharedDoc.document;
        });
      }
      else if(_.isObject(sharedDocuments)) {
        documents = sharedDocuments.document;
        if(_.isArray(sharedDocuments.usedInContenus) && sharedDocuments.usedInContenus.length){
          if(sharedDocuments.usedInContenus.length == 1) {
            documents.tmpIdContenu = sharedDocuments.usedInContenus[0].idContenu;
            documents.tmpIdTopic = sharedDocuments.usedInContenus[0].idTopic;
          }
        }
      }

      DocumentManagerService
        .sendDocumentToGed(documents, TopicManagerService.currentTopicStates.getTopic(), COLLAB_CONF.GED_FOLDER_ENUMS.DOC_BOX)
        .then(function(){
          _this.listSharedDocumentSelected = [];
        });
    }

    /**
     * Permet de supprimer un ou plusieur document de la tempBox
     * @param tempDoc
     */
    function onClickDeleteSharedDocument(sharedDocuments) {
      var listIdSharedDocument = sharedDocuments;
      var title = 'Supprimer le document de la liste ?';
      if(_.isArray(sharedDocuments)) {
        listIdSharedDocument = sharedDocuments.reduce(function(listIdSharedDocument, sharedDoc) {
          if(sharedDoc.getCanDelete()) listIdSharedDocument.push(sharedDoc.idSharedDocument);
            return listIdSharedDocument;
        }, []);
        title = 'Supprimer les '+listIdSharedDocument.length+' document(s) de la liste ?';
      }
      else if(_.isObject(sharedDocuments)) {
        if(!sharedDocuments.getCanDelete()) {
          ModalsService.alertErreur("Vous n'avez pas les droits pour supprimer ce document");
          return;
        }
        listIdSharedDocument = sharedDocuments.idSharedDocument;
        title = 'Supprimer "'+sharedDocuments.document.getNomFichier()+'" de la liste ?';
      }
      if(_.isNil(listIdSharedDocument) || !listIdSharedDocument || (_.isArray(listIdSharedDocument) && !listIdSharedDocument.length)) {
        ModalsService.alertErreur("Aucun document à supprimer ou vous n'avez pas les droits sur les documents sélectionnés");
        return;
      }

      ModalsService
        .confirm(title)
        .then(function onSuccess(modal){
          _this.loading = true;
          DocBoxService
            .deleteDocument(listIdSharedDocument)
            .then(function(listSharedDocument){
              iniListSharedDocument(listSharedDocument);
            })
            .finally(function(){
              modal.close();
              _this.loading = false;
              _this.listSharedDocumentSelected = [];
            });
        });
    }

    function onClickClose(){
      close();
    }

    /**
     * Si ouvert en mode Modal
     */
    function close(){
      if (_this.modalInstance) _this.dismiss({$value: 'cancel'});
      else emit({action: 'close'});
    }

    function emit(objet) {
      _this.onEmit({
        obj: objet
      });
    }
  }
})();
