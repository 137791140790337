/* eslint-disable lodash/prefer-includes */
(function() {

  'use strict';

  /**
   * @desc Composant de la Mailbox
   * @example <mailbox-list></mailbox-list>
   */
  angular
    .module('collaboreApp')
    .component('mailboxList',{
      templateUrl: 'app/mailbox/mailbox-list/mailbox-list.component.html',
      controllerAs: 'mailboxlistctrl',
      controller: MailboxListCtrl,
      bindings: {
        superAdmin: '<',
        onChooseMailbox: '&',
        onCheckMailbox: '&'
      }
    });

  /** @ngInject */
  function MailboxListCtrl($q, NotificationService, $scope, $rootScope, MailboxService, ModalsService){
    var _this = this;

    _this.popoverChangeTopicIsOpen = false;
    _this.allChecked = false;
    _this.currentView = null;

    _this.currentPage = 1;
    _this.limit = 15;
    _this.nbTotal = 0;
    _this.maxSize = 10;
    _this.arrayNbResult = [
      5,
      10,
      15,
      20,
      50
    ];
    _this.loading = false;
    _this.selectedMailbox = null;

    _this.listMailbox = [];
    _this.listCheckedMailbox = [];
    _this.listCheckedIdMailboxAllowedToProcessed = [];
    _this.listCheckedIdMailboxAllowedToChangeIdTopic = [];

    _this.onClickRefresh = onClickRefresh;
    _this.onAllChecked = onAllChecked;
    _this.onClickChangePage = onClickChangePage;
    _this.onMailboxChecked = onMailboxChecked;
    _this.onClickMailbox = onClickMailbox;
    //_this.onEmitFromPopoverSearchTopic = onEmitFromPopoverSearchTopic;
    _this.onClickAccepte = onClickAccepte;
    _this.onClickAccepteAllChecked = onClickAccepteAllChecked;
    _this.onClickDelete = onClickDelete;
    _this.onClickDeleteAllChecked = onClickDeleteAllChecked;
    _this.onClickOpenSearchTopic = onClickOpenSearchTopic;

    var actionListener = null;
    _this.$onInit = function() {
      searchMailbox();
      actionListener = $scope.$on('actionToMailbox', function (event, action, mailbox, currentView) {
        if(action === 'refresh'){
          _this.currentView = currentView;
          _this.selectedMailbox = null;
          searchMailbox();
        }
        else if(!_.isNil(mailbox)) {
          if(action === 'delete') onClickDelete(mailbox);
          else if(action === 'accept') onClickAccepte(mailbox);
          else if(action === 'openSearchTopic') onClickOpenSearchTopic(mailbox);
          else if(action === 'attachementDeleted') {
            for(var i = 0; i < _this.listMailbox.length; i++){
              if(_this.listMailbox[i].idMailbox === mailbox.idMailbox){
                _this.listMailbox[i] = mailbox;
                break;
              }
            }
            for(var j = 0; j < _this.listCheckedMailbox.length; j++){
              if(_this.listCheckedMailbox[j].idMailbox === mailbox.idMailbox){
                _this.listCheckedMailbox[j] = mailbox;
                break;
              }
            }
          }
        }
      });
    };

    _this.$onDestroy = function(){
      if(!_.isNil(actionListener)) actionListener();
    };

    function mailboxIsChecked(mailbox){
      var indexFound = -1;
      if(_.isArray(_this.listCheckedMailbox) && _this.listCheckedMailbox.length){
        indexFound = _this.listCheckedMailbox.findIndex(function(currentMailbox){
          return mailbox.idMailbox === currentMailbox.idMailbox;
        });
      }
      return indexFound !== -1;
    }

    /**
     * Coche/Décoche un email
     * @param {*} mailbox
     */
    function checkOrUncheckMailbox(mailbox){
      var idMailbox = mailbox.idMailbox;
      if(!mailbox.checked){
        // Retire le mailbox décoché
        _this.listCheckedMailbox = _this.listCheckedMailbox.filter(function(currentMailbox){
          return currentMailbox.idMailbox !== idMailbox;
        });

        if(mailbox.allowedToProcessed()){
          uncheckIdMailboxAllowedToProcessed(idMailbox);
        }
        if(mailbox.allowedToChangeIdTopic()){
          uncheckIdMailboxAllowedToChangeIdTopic(idMailbox);
        }
      }
      // Ajoute le mailbox coché
      else {
        var indexFound = _this.listCheckedMailbox.findIndex(function(currentMailbox){
          return currentMailbox.idMailbox === idMailbox;
        });
        if(indexFound === -1){
          _this.listCheckedMailbox.push(mailbox);
        }
        if(mailbox.allowedToProcessed() && _this.listCheckedIdMailboxAllowedToProcessed.indexOf(idMailbox) === -1){
          _this.listCheckedIdMailboxAllowedToProcessed.push(idMailbox);
        }
        if(mailbox.allowedToChangeIdTopic() && _this.listCheckedIdMailboxAllowedToChangeIdTopic.indexOf(idMailbox) === -1){
          _this.listCheckedIdMailboxAllowedToChangeIdTopic.push(idMailbox);
        }
      }
    }

    function uncheck(mailbox) {

      uncheckIdMailboxAllowedToProcessed(mailbox);
      uncheckIdMailboxAllowedToChangeIdTopic(mailbox);

      var idMailbox = null;
      var indexFound = -1;
      if(_.isArray(mailbox)) {
        for(var i = 0; i < mailbox.length; i++){
          idMailbox = (_.isObject(mailbox[i])) ? mailbox[i].idMailbox : mailbox[i];
          if(!_.isNil(idMailbox)) {
            indexFound = _this.listCheckedMailbox.findIndex(function(currentMailbox) {
              return currentMailbox.idMailbox === idMailbox;
            });
            if(indexFound !== -1){
              delete _this.listCheckedMailbox[indexFound].cheched;
              _this.listCheckedMailbox.splice(indexFound, 1);
            }
            if(!_.isNil(_this.selectedMailbox) && _this.selectedMailbox.idMailbox === idMailbox){
              chooseMailbox(null);
            }
          }
        }
      }
      else {
        idMailbox = (_.isObject(mailbox)) ? mailbox.idMailbox : mailbox;
        if(!_.isNil(idMailbox)) {
          indexFound = _this.listCheckedMailbox.findIndex(function(currentMailbox) {
            return currentMailbox.idMailbox === idMailbox;
          });
          if(indexFound !== -1){
            delete _this.listCheckedMailbox[indexFound].cheched;
            _this.listCheckedMailbox.splice(indexFound, 1);
          }
          if(!_.isNil(_this.selectedMailbox) && _this.selectedMailbox.idMailbox === idMailbox){
            chooseMailbox(null);
          }
        }
      }
      _this.allChecked = false;
      _this.onCheckMailbox({listChecked: _this.listCheckedMailbox});
    }

    function uncheckIdMailboxAllowedToProcessed(mailbox){
      var indexFound = -1;
      if(_.isArray(mailbox)) {
        for(var i = 0; i < mailbox.length; i++){
          indexFound = _this.listCheckedIdMailboxAllowedToProcessed.indexOf((_.isObject(mailbox[i])) ? mailbox[i].idMailbox : mailbox[i]);
          if(indexFound !== -1){
            _this.listCheckedIdMailboxAllowedToProcessed.splice(indexFound, 1);
          }
        }
      }
      else {
        indexFound = _this.listCheckedIdMailboxAllowedToProcessed.indexOf((_.isObject(mailbox)) ? mailbox.idMailbox : mailbox);
        if(indexFound !== -1){
          _this.listCheckedIdMailboxAllowedToProcessed.splice(indexFound, 1);
        }
      }
    }

    function uncheckIdMailboxAllowedToChangeIdTopic(mailbox){
      var indexFound = -1;
      if(_.isArray(mailbox)) {
        for(var i = 0; i < mailbox.length; i++){
          indexFound = _this.listCheckedIdMailboxAllowedToChangeIdTopic.indexOf((_.isObject(mailbox[i])) ? mailbox[i].idMailbox : mailbox[i]);
          if(indexFound !== -1){
            _this.listCheckedIdMailboxAllowedToChangeIdTopic.splice(indexFound, 1);
          }
        }
      }
      else {
        indexFound = _this.listCheckedIdMailboxAllowedToChangeIdTopic.indexOf((_.isObject(mailbox)) ? mailbox.idMailbox : mailbox);
        if(indexFound !== -1){
          _this.listCheckedIdMailboxAllowedToChangeIdTopic.splice(indexFound, 1);
        }
      }
    }

    /**
     * Permet de rechercher les emails
     */
    function searchMailbox() {
      _this.loading = true;
      _this.listMailbox = [];
      NotificationService.cleanListNotificationMail();
      MailboxService.searchListMailboxSociete(_this.currentPage, _this.limit, _this.superAdmin, (_this.currentView == 'trashed'))
        .then(function (retour) {
          if(_this.currentPage > 1 && retour.nb === 0 && retour.total > 0) {
            _this.currentPage = _this.currentPage - 1;
            searchMailbox();
          }
          else{
            _this.listMailbox = retour.listMailbox;
            _this.nbTotal = retour.total;
            $rootScope.mailboxInfos.nbMail = retour.nbMailbox;
            $rootScope.mailboxInfos.nbTrashedMail = retour.nbMailboxTrashed;
            $rootScope.mailboxInfos.nbNewMail = retour.nbMailboxNonLu;

            if(_.isArray(_this.listCheckedMailbox) && _this.listCheckedMailbox.length) {
              if(_.isArray(_this.listMailbox) && _this.listMailbox.length) {
                var nbTotalChecked = 0;
                for(var i = 0; i < _this.listMailbox.length; i++){
                  _this.listMailbox[i].checked = mailboxIsChecked(_this.listMailbox[i]);
                  if(_this.listMailbox[i].checked) nbTotalChecked += 1;
                }
                _this.allChecked = (nbTotalChecked === _this.listMailbox.length);
              }
            }
          }
        })
        .finally(function () {
          _this.loading = false;
        });
    }

    function onClickRefresh(){
      searchMailbox();
    }

    function onClickChangePage(){
      searchMailbox();
    }

    /**
     * Lorsqu'on clique sur la case à cocher toutes les lignes
     */
    function onAllChecked(){
      for(var i = 0; i < _this.listMailbox.length; i++){
        _this.listMailbox[i].checked = _this.allChecked;
        checkOrUncheckMailbox(_this.listMailbox[i]);
      }
      _this.onCheckMailbox({listChecked: _this.listCheckedMailbox});
    }

    /**
     * Lors du check d'une ligne
     * @param {*} mailbox
     */
    function onMailboxChecked(mailbox){
      checkOrUncheckMailbox(mailbox);
      _this.onCheckMailbox({listChecked: _this.listCheckedMailbox});
      // Recup le nombre de ligne coché actuellement visible
      var nbChecked = _this.listMailbox.reduce(function(nb, item){
        if(item.checked) nb += 1;
        return nb;
      }, 0);
      // Si elle sont toute coché
      _this.allChecked = (nbChecked === _this.listMailbox.length);
    }

    /**
     * Lorsqu'on choisi un email pour le visualiser en détail
     * @param {*} mailbox
     */
    function chooseMailbox(mailbox){
      _this.selectedMailbox = mailbox;
      _this.onChooseMailbox({mailbox: mailbox});
    }

    /**
     * Lorsqu'on clique sur un email pour le visualiser en détail
     * @param {*} mailbox
     * @returns
     */
    function onClickMailbox(mailbox) {
      if(!_.isNil(_this.selectedMailbox) && _this.selectedMailbox.idMailbox === mailbox.idMailbox){
        chooseMailbox(null);
        return;
      }

      chooseMailbox(mailbox);
      if(mailbox.dateLecture) return;

      MailboxService.readMailbox([mailbox.idMailbox], _this.superAdmin)
        .then(function(retour) {
          $rootScope.mailboxInfos.nbNewMail = retour.nbMailboxNonLu;
          $rootScope.mailboxInfos.nbTrashedMail = retour.nbMailboxTrashed;
          var foundIndex = retour.listMailbox.findIndex(function (currentMailbox){
            return currentMailbox.idMailbox === mailbox.idMailbox;
          });
          if(foundIndex !== -1) {
            mailbox.setDateLecture(retour.listMailbox[foundIndex].dateLecture);
          }
        });
    }

    function onClickOpenSearchTopic(mailbox){
      var options = {
        backdrop: 'static'
      };
      var params = {
        btnValidIconClass: 'fa-check',
        btnValidText: 'Insérer dans collab'
      };

      ModalsService
        .modalComponent('searchTopics', params, options)
        .then(function (retour) {
          if(retour.action === 'selectTopicState' && _.isObject(retour.topicState)){
            var idTopic = retour.topicState.topic.idTopic;
            if(!_.isNil(idTopic)){
              var modalLoading = ModalsService.loading('Patientez...');
              traiteMailbox(mailbox, idTopic).finally(function(){
                modalLoading.close();
                uncheck(mailbox);
              });
            }
            else{
              console.error("Aucun idTopic trouvé", retour);
            }
          }
          else console.error("Erreur du retour ?", retour);
        })
        .catch(function (msg) {
          console.log(msg);
          if (msg !== 'cancel') ModalsService.alertErreur(msg);
        });
    }

    /*
    function onEmitFromPopoverSearchTopic(obj, mailbox){
      if(_.isObject(obj) && _.isObject(mailbox)){
        if(obj.action === 'selectTopicState' && _.isObject(obj.topicState)){
          mailbox.idTopicFound = obj.topicState.idTopic;
        }
      }
    }*/

    /**
     * Permet de traiter un ou plusieur email
     * @param {*} mailbox
     * @param {*} idTopic
     * @returns
     */
    function traiteMailbox(mailbox, idTopic){
      var deferred = $q.defer();

      if(_.isNil(mailbox)) {
        deferred.reject('Aucun mail à traiter');
        return;
      }

      var listId = [];
      if(_.isArray(mailbox)){
        listId = mailbox.map(function(m){
          return (_.isObject(m)) ? m.idMailbox : m;
        });
      }
      else {
        listId = [(_.isObject(mailbox)) ? mailbox.idMailbox : mailbox];
      }

      MailboxService.traiteMailbox(listId, idTopic, null, _this.superAdmin)
        .then(function(retour) {
          $rootScope.mailboxInfos.nbNewMail = retour.nbMailboxNonLu;
          $rootScope.mailboxInfos.nbTrashedMail = retour.nbMailboxTrashed;
          // Si pas la 1er page
          // Si pas d'erreur
          // Si le nombre de suppression est égale au nombre dans la liste actuelle
          if(_this.currentPage > 1 && retour.nbErreur === 0 && _this.listMailbox.length === listId.length) {
            _this.currentPage = _this.currentPage - 1;
          }
          deferred.resolve();
          uncheck(listId);
          searchMailbox();
        })
        .catch(function(msg) {
          ModalsService.alertErreur(msg);
          deferred.reject(msg);
        })
        .finally(function () {
        });

      return deferred.promise;
    }

    function onClickAccepte(mailbox) {
      if(!mailbox) return;

      ModalsService.confirm(
        'Ajout dans Collab',
        'Voulez-vous vraiment ajouter dans Collab ?'
        )
        .then(function onConfirm(modal) {
          traiteMailbox(mailbox).then(function(){
            modal.close();
          });
        })
        .catch(function onReject() {});
    }

    function onClickAccepteAllChecked(){
      if(!_this.listCheckedIdMailboxAllowedToProcessed.length) return;
      ModalsService.confirm(
        'Ajout dans Collab',
        'Voulez-vous vraiment ajouter <strong>' + _this.listCheckedIdMailboxAllowedToProcessed.length + '</strong> email(s) dans Collab ?'
        )
        .then(function onConfirm(modal) {
          traiteMailbox(_this.listCheckedIdMailboxAllowedToProcessed)
          .finally(function(){
            modal.close();
          });
        })
        .catch(function onReject() {});
    }

    /**
     * Permet de mettre à la poubelle un ou plusieur email
     * @param {*} listeMailbox
     * @returns
     */
    function trashMailbox(listeMailbox){
      var deferred = $q.defer();

      if(!_.isArray(listeMailbox) || !listeMailbox.length) {
        deferred.reject('Aucun mail à traiter');
        return;
      }

      var listId = listeMailbox.map(function(m){
        return m.idMailbox;
      });

      if(listeMailbox.length === 1) listeMailbox[0].loading = true;
      MailboxService.trashMailbox(listId, _this.superAdmin)
        .then(function(retour) {
          $rootScope.mailboxInfos.nbNewMail = retour.nbMailboxNonLu;
          $rootScope.mailboxInfos.nbTrashedMail = retour.nbMailboxTrashed;
          // Si pas la 1er page
          // Si le nombre de suppression est égale au nombre dans la liste actuelle
          if(_this.currentPage > 1 && _this.listMailbox.length === retour.nb) {
            _this.currentPage = _this.currentPage - 1;
          }
          uncheck(listId);
          searchMailbox();
          deferred.resolve();
        })
        .catch(function(msg) {
          ModalsService.alertErreur(msg);
          deferred.reject(msg);
        })
        .finally(function () {
          if(listeMailbox.length === 1) listeMailbox[0].loading = false;
        });
        return deferred.promise;
    }

    function onClickDelete(mailbox) {
      if(!mailbox) return;
      var perso = (mailbox.nameExpediteur) ? mailbox.nameExpediteur + '<' + mailbox.emailExpediteur + '>' : mailbox.emailExpediteur;
      ModalsService.confirm(
        'Suppression de l\'email',
        'Voulez-vous vraiment supprimer l\'email de <strong>' + perso + '</strong> ?'
        )
        .then(function onConfirm(modal) {
          trashMailbox([mailbox]).finally(function(){
            modal.close();
          });
        })
        .catch(function onReject() {

        });
    }

    function onClickDeleteAllChecked() {
      if(!_this.listCheckedMailbox.length) return;
      ModalsService.confirm(
        'Suppression des emails',
        'Voulez-vous vraiment supprimer les <strong>' + _this.listCheckedMailbox.length + '</strong> email(s) sélectionné(s) ?'
        )
        .then(function onConfirm(modal) {
          trashMailbox(_this.listCheckedMailbox)
          .finally(function(){
            modal.close();
          });
        })
        .catch(function onReject() {

        });
    }
  }
})();
