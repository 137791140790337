(function () {
  'use strict';

  /**
   * @desc Permet de rechercher et choisir un compte (modele ics)
   * @example <choose-compte></choose-immeuble>
   */

  angular
    .module('collaboreApp')
    .component('chooseCompte', {
      templateUrl: 'app/components/choose-compte/choose-compte.component.html',
      bindings: {
        search: '<',
        numImmeuble: '<',
        libelleImmeuble: '<',
        fournisseur: '<',
        contrat: '<',
        portefeuille: '<',
        listSelectedCompte: '<',
        typeMetier: '<',
        onEmit: '&'
      },
      controllerAs: 'choosecomptectrl',
      controller: ChooseCompteController
    });

  /*@ngInject*/
  function ChooseCompteController($scope, $q, $timeout, ICSCloudService, UtilsService, ModalsService) {

    var _this = this;
    var prevTypeMetier = null;
    var defaultNumImm = null;

    _this.loading = false;
    _this.defaultSearch = "";
    _this.lastSearch = "";
    _this.currentModele = null;

    var listAllCompte = [];
    _this.listCompte = [];

    _this.searchCompte = searchCompte;
    _this.groupByImmeuble = groupByImmeuble;
    _this.onSelect = onSelect;
    _this.onKeyDown = onKeyDown;

    _this.$onInit = function() {
      init();
      $scope.$on('focusCompte', function () {
        $timeout(function(){
          _this.focusClear = true;
          $scope.$broadcast('focusSelectCompte');
        });
      });
    };

    _this.$onChanges = function(changes) {
      //console.log("chooseCompte", changes);
      if(_.isObject(changes)) {

        if(_.isObject(changes.listSelectedCompte) && !changes.listSelectedCompte.isFirstChange()) {
          var listSelectedCCCCCC = _this.listSelectedCompte.map(function(compte){
            return {
              CCCCCC: compte.getCCCCCC(),
              BB: compte.getBB()
            };
          });
          updateListCompte(listSelectedCCCCCC, true);
        }
        if(_.isObject(changes.portefeuille) && !changes.portefeuille.isFirstChange()) {
          init();
        }
        if(_.isObject(changes.search) && !changes.search.isFirstChange()) {
          init();
        }
        if(_.isObject(changes.numImmeuble) && !changes.numImmeuble.isFirstChange()) {
          _this.listCompte = [];
          _this.lastSearch = '';
          if(_.isNil(_this.numImmeuble)) _this.numImmeuble = '0000';
          else defaultNumImm = _this.numImmeuble;
        }
        if(_.isObject(changes.typeMetier) && !changes.typeMetier.isFirstChange()) {
          _this.listCompte = [];
          _this.lastSearch = '';
          _this.filtreCompte = '';
          if (changes.typeMetier.currentValue == 1) _this.numImmeuble = '0000';
          else _this.numImmeuble = defaultNumImm;
        }
      }
      /*
      if(_.isObject(changes.contrat)){
        searchCompte(null, true, _this.contrat);
      }*/
    };

    _this.$onDestroy = function(){
    };

    /**
     * Permet d'init le composant
     */
    function init(){

      if(_.isNil(_this.numImmeuble)) _this.numImmeuble = '0000';
      else defaultNumImm = _this.numImmeuble;

      if(!_.isNil(_this.typeMetier)) {
        prevTypeMetier = _this.typeMetier;
        // Si gestion on appel les comptes commun
        if(_this.typeMetier == 1) _this.numImmeuble = '0000';
      }

      if(_.isNil(_this.portefeuille)){
        var listPortefeuille = UtilsService.getCurrentUser().getListePortefeuille();
        if(_.isArray(listPortefeuille)) _this.portefeuille = listPortefeuille[0];
      }

      if(_.isNil(_this.portefeuille)) {
        ModalsService.alertErreur('Pas de portefeuille');
        return;
      }
    }

    /**
     * Permet d'appeler ICSCloud pour récupérer le plan modele
     * @returns {*}
     */
    function callModeleICSCloud(search, contrat){
      var deferred = $q.defer();

      var paramsSearch = {
        numImmeuble: _this.numImmeuble,
        portefeuille: _this.portefeuille,
        global: _this.searchCompte
      };

      if(_.isString(search)) paramsSearch.global = search;

      if(_.isObject(contrat)) {
        paramsSearch.refContrat = contrat.value;
      }

      ICSCloudService
        .getPlanModele(paramsSearch)
        .then(function(planModele){
          var listeCompte = [];
          if(_.isObject(planModele)){
            if(planModele.hasListBatiment()){
              planModele.getListBatiment().map(function(batiment){
                parseListCompte(listeCompte, batiment);
                if(batiment.hasListRegroupement()){
                  batiment.getListRegroupement().map(function(regroupement){
                    parseListCompte(listeCompte, batiment, regroupement);
                    if(regroupement.hasListRegroupement()){
                      regroupement.getListRegroupement().map(function(sousRegroupement){
                        parseListCompte(listeCompte, batiment, regroupement, sousRegroupement);
                      });
                    }
                  });
                }
              });
            }
          }
          deferred.resolve(listeCompte);
        })
        .finally(function(){
          _this.loading = false;
        });
      return deferred.promise;
    }

    function parseListCompte(
      listeCompte,
      batiment,
      regroupement,
      sousRegroupement
    ) {
      if (_.isObject(batiment)) {
        // Si seulement le batiment
        if (_.isNil(regroupement)) {
          if (batiment.hasListCompte()) {
            batiment.getListCompte().map(function (compte) {
              var objCompte = angular.copy(compte);
              objCompte.libelleBatiment = batiment.getLib();
              objCompte.batiment = angular.copy(batiment);
              delete objCompte.batiment.listRegroupement;
              delete objCompte.batiment.listCompte;
              listeCompte.push(objCompte);
            });
          }
        } else {
          if (_.isNil(sousRegroupement)) {
            if (regroupement.hasListCompte()) {
              regroupement.getListCompte().map(function (compte) {
                var objCompte = angular.copy(compte);
                objCompte.libelleRegroupement = regroupement.getLib();
                objCompte.libelleBatiment = batiment.getLib();
                objCompte.setClerep(regroupement.getClerep());
                objCompte.regroupement = angular.copy(regroupement);
                objCompte.batiment = batiment;
                delete objCompte.regroupement.listRegroupement;
                delete objCompte.regroupement.listCompte;
                delete objCompte.batiment.listRegroupement;
                delete objCompte.batiment.listCompte;
                listeCompte.push(objCompte);
              });
            }
          } else {
            if (sousRegroupement.hasListCompte()) {
              sousRegroupement.getListCompte().map(function (compte) {
                var objCompte = angular.copy(compte);
                objCompte.libelleSousRegroupement = sousRegroupement.getLib();
                objCompte.libelleRegroupement = regroupement.getLib();
                objCompte.libelleBatiment = batiment.getLib();
                objCompte.setClerep(regroupement.getClerep());
                objCompte.sousRegroupement = angular.copy(sousRegroupement);
                objCompte.regroupement = angular.copy(regroupement);
                objCompte.batiment = angular.copy(batiment);
                delete objCompte.sousRegroupement.listCompte;
                delete objCompte.regroupement.listRegroupement;
                delete objCompte.regroupement.listCompte;
                delete objCompte.batiment.listRegroupement;
                delete objCompte.batiment.listCompte;
                listeCompte.push(objCompte);
              });
            }
          }
        }
      }
      return listeCompte;
    }

    function groupByImmeuble(item){
      if(_this.numImmeuble == "0000") return;
      return item.libelleBatiment + ' - ' + _this.libelleImmeuble;
    }

    /**
     * Recherche les fournisseur dans ICSCloud et OCR-Front
     * @param search
     * @param firstSearch
     * @returns {*}
     */
    function searchCompte(search, firstSearch, contrat){

      if (_.isNil(search)) search = _this.lastSearch;
      if (
          (_.isNil(search) || _.isEmpty(search) ||  (_this.lastSearch === search && !firstSearch))
          &&
          _.isNil(contrat)
      )
        return $q.reject();

      _this.listCompte = [];
      _this.loadingSearch = true;
      if(_.isString(search)) _this.lastSearch = angular.copy(search);

      var promises = [];
      var searchByContrat = false;

      if (_.isObject(contrat) && (_.isNil(search) || _.isEmpty(search))) {
        _this.loading = true;
        _this.lastSearch = "";
        _this.filtreCompte = '';
        searchByContrat = true;
        promises.push(callModeleICSCloud(search, contrat));
      }
      else if(!_.isNil(search) && !_.isEmpty(search)) {
        if (firstSearch) _this.loading = true;
        promises.push(callModeleICSCloud(search));
      }

      return $q.all(promises)
        .then(function(list){
          var listICS = list[0];

          if(searchByContrat && _.isArray(listICS) && listICS.length){
            listICS = listICS.map(function(compte){
              compte.groupe = 'contrat';
              return compte;
            });
          }

          _this.listCompte = listICS;
          listAllCompte = angular.copy(listICS);
          if(searchByContrat){
            selecteComptes(_this.listCompte);
          }
        })
        .finally(function(){
          _this.loadingSearch = false;
          _this.loading = false;
          $timeout(function() {
            var uiSelect = angular.element("[name=chooseCompte]").controller('uiSelect');
            if(!_.isNil(uiSelect)){
              uiSelect.items = _this.listCompte;
              uiSelect.refreshing = true;
            }
          });
        });
    }

    /**
     * Lors de la selection d'un immeuble
     * @param item
     */
    function onSelect(item){
      selecteCompte(item);
    }

    function selecteCompte(obj){
      _this.focusClear = true;
      _this.filtreCompte = null;
      emit({
        action: 'selectCompte',
        compte: obj
      });
      updateListCompte([{
        CCCCCC: obj.getCCCCCC(),
        BB: obj.getBB()
      }]);
    }

    function selecteComptes(list){
      _this.focusClear = true;
      _this.filtreCompte = null;
      emit({
        action: 'selectListCompte',
        comptes: list
      });
      updateListCompte(list.map(function(compte){
        return {
          CCCCCC: compte.getCCCCCC(),
          BB: compte.getBB()
        };
      }));
    }

    function updateListCompte(listCCCCCC, completeList){
      if(!_.isArray(listCCCCCC)) return;
      // Si la liste en paramètre et la liste de tous les elements selectionné, on va les retirer le la liste complète
      if(completeList){
        _this.listCompte = _.filter(listAllCompte, function(compte){
          return (_.findIndex(listCCCCCC, {
            CCCCCC: compte.getCCCCCC(),
            BB: compte.getBB()
          }) === -1);
        });
      }
      // Sinon on retire juste les compte en paramètre de la liste des comptes
      else{
        _.remove(_this.listCompte, function(compte){
          return (_.findIndex(listCCCCCC, {
            CCCCCC: compte.getCCCCCC(),
            BB: compte.getBB()
          }) !== -1);
        });
      }
    }

    function onKeyDown(event){
      if(event.keyCode === 9){
        if(event.shiftKey) emit({action: 'previousField'});
        else emit({action: 'nextField'});
        _this.focusClear = false;
      }
    }

    function emit(obj){
      _this.onEmit({obj: obj});
    }
  }
})();
