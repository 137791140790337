(function() {
    'use strict';

    angular.module('collaboreApp').controller('ModalCommunicationCtrl', ModalCommunicationCtrl);

    /** @ngInject */
    function ModalCommunicationCtrl($q, $uibModalInstance,TopicStatesService,idTopic){
        var ctrl = this;
        ctrl.loading = true;
        ctrl.idTopic = idTopic;
        ctrl.topicState = false;
        ctrl.contenu = false;
        ctrl.paramsCommunication = {
            'show': true
        };

        /**
         * Ferme le modal
         */
        ctrl.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        //$uibModalInstance.close(objAnnuaire);

        $uibModalInstance.rendered.then(function(){
            var deferred = $q.defer();
            ctrl.loading = true;
            TopicStatesService.getTopicStateByIdTopic(idTopic).then(function(topicState){
                //console.log(topicState);
                ctrl.topicState = topicState;
                ctrl.contenu = topicState.getTopic().getEvenement();

                $uibModalInstance.finish();
            }).finally(function(){
                ctrl.loading = false;
                deferred.resolve();
            });

            return deferred.promise;
        });
    }

})();
