(function () {
    'use strict';

    /**
     * @desc Modal pour la gestion des groupes d'un memebre
     * @example <admin-collab-modal-groupe></admin-collab-modal-groupe>
     */

    angular
        .module('collaboreApp')
        .component('adminCollabModalGroupe',{
            templateUrl: 'app/admin/groupe/admin-collab-modal-groupe.component.html',
            controllerAs: 'admincollabmodalgroupe',
            controller: AdminCollabModalGroupeCtrl,
            bindings: {
                modalInstance: '<',
                resolve: '<',
                close: '&',
                dismiss: '&'
            }
        });

    /*@ngInject*/
    function AdminCollabModalGroupeCtrl(UtilsService) {

        var ctrl = this;

        ctrl.onClickClose = onClickClose;

        ctrl.$onInit = function() {

            ctrl.compte = UtilsService.getParamModalComponent(ctrl.resolve, 'compte');

            // console.log(ctrl.compte);
            if(ctrl.onOpened && angular.isFunction(ctrl.onOpened)) {
                ctrl.onOpened();
            }
        };

        function onClickClose(){
            ctrl.dismiss({$value: 'cancel'});
        }
    }
})();
