(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .factory('ContratIcsCloudResource', ContratIcsCloudResource);

  /** @ngInject */
  function ContratIcsCloudResource(COLLAB_CONF, COLLAB_VALUES, $resource) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_CONTRATS, null, {

      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      },

      getContrats: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_CONTRATS,
        params: {
          action: 'searchListContrat',
          mandat: '@mandat',
          immeuble: '@immeuble',
          ventilationComptable: '@ventilationComptable',
          seulementAvecComptes: '@seulementAvecComptes',
          gerance: '@gerance',
          codeMetier: '@codeMetier',
          numeroFournisseur: '@numeroFournisseur',
          refContrat: '@refContrat',
          idPortefeuille: '@idPortefeuille'
        }
      }
    });
  }
})();
