(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .factory('DocumentResource', DocumentResource);

  /** @ngInject */
  function DocumentResource(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DOCUMENT, null, {

      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      },

      // Stream un document
      stream: {
        method: 'GET',
        responseType: 'blob',
        params: {
          action: 'stream',
          guid: '@guid',
          idContenu: '@idContenu',
          idTopic: '@idTopic'
        },
        transformResponse: function (data) {
          // Stores the ArrayBuffer object in a property called "data"
          return {data: data};
        }
      }

    });
  }
})();
