(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name collaboreApp.CourrierService
     * @description
     * # CourrierService
     * Service in the collaboreApp.
     */

    angular
        .module('collaboreApp')
        .service('CourrierService', CourrierService);

  /*@ngInject*/
    function CourrierService(MainService, $q, Courrier, CourrierAjax, ModalsService) {

        var ctrl = this;

        ctrl.createCourrierSimple = createCourrierSimple;
        ctrl.updateCourrierSimple = updateCourrierSimple;
        ctrl.deleteCourrier = deleteCourrier;

      /* Public Methods */

        /**
         * Permet de créer un courrier simple
         * @param obj
         * @returns {IPromise<T>}
         */
        function createCourrierSimple(obj){
            var deferred = $q.defer();
            try{
                if(!angular.isObject(obj)) throw new Error('Le paramètre n\'est pas un objet !');
                obj.action = 'createCourrierSimple';
                CourrierAjax.post(obj, function(json){
                    deferred.resolve(MainService.convertJsonToModel(json, 'courrier', Courrier, deferred));
                },function(msg){
                    deferred.reject(msg);
                });
            }
            catch(e) {
                ModalsService.alertErreur(e.message);
                deferred.reject(e.message);
            }
            return deferred.promise;
        }

        /**
         * Permet de modifier un courrier simple
         * @param obj
         * @returns {IPromise<T>}
         */
        function updateCourrierSimple(obj){
            var deferred = $q.defer();
            try{
                if(!angular.isObject(obj)) throw new Error('Le paramètre n\'est pas un objet !');
                obj.action = 'updateCourrierSimple';
                CourrierAjax.post(obj, function(json){
                    deferred.resolve(MainService.convertJsonToModel(json, 'courrier', Courrier, deferred));
                },function(msg){
                    deferred.reject(msg);
                });
            }
            catch(e) {
                ModalsService.alertErreur(e.message);
                deferred.reject(e.message);
            }
            return deferred.promise;
        }

        /**
         * Permet de supprimer un courrier
         * @param idCourrier
         * @returns {IPromise<T>}
         */
        function deleteCourrier(idCourrier){
            var deferred = $q.defer();
            try{
                if(!idCourrier) throw new Error('Le paramètre idCourrier est obligatoire !');
                var obj = {
                    idCourrier: idCourrier,
                    action: 'deleteCourrier'
                };
                CourrierAjax.post(obj, function(json){
                    MainService.isSuccess(json, deferred);
                },function(msg){
                    deferred.reject(msg);
                });
            }
            catch(e) {
                ModalsService.alertErreur(e.message);
                deferred.reject(e.message);
            }
            return deferred.promise;
        }
    }

})();