(function () {
  'use strict';

  /**
   * @desc Formulaire de l'événement
   * @example <formulaire-evenement></formulaire-evenement>
   */

  angular
    .module('collaboreApp')
    .component('formulaireEvenement', {
      templateUrl: 'app/topics/formulaire-evenement/formulaire-evenement.component.html',
      bindings: {
        idTopic: '<',
        devis: '<',
        contrat: '<',
        contenuEvenement: '<',
        tags: '<',
        tagsType: '<',
        categorieTopic: ' <',
        topicInformations: '<',
        onUpdate: '&',

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<'
      },
      controllerAs: 'formulaireevenementctrl',
      controller: FormulaireEvenementController
    });

  /*@ngInject*/
  function FormulaireEvenementController($injector, $rootScope, $log, $timeout, COLLAB_CONF, COLLAB_VALUES, $state, $scope, Groupe, $q, $sce, $compile, $templateRequest, sweet, ContenuService, EvenementService, DocumentManagerService, AutoSaveService, NotificationsToastServices, UtilsService, UserService, AnnuaireService, ContenuEvenement, Personnalite, $stateParams, TagService,TagsService, ProxyContenuEvenement, ProxyContenuMessage, ICSCloudService, GroupeAssociationService, ModalsService) {

    var ctrl = this;
    ctrl.notMobile = UtilsService.notMobile;

    ctrl.uploaderAttachment = {
      action: 'uploadTypedDocument',
      typeDocument: COLLAB_CONF.TYPE_DOC_ENUMS.ATTACHMENT,
      sizeLimit: COLLAB_CONF.SIZE_LIMIT_ATTACHMENTS
    };

    //********************** New *********************************

    var idMainContent = 'eventMainContent';			// ID du block main content
    var idFichesContent = 'eventFichesContent';		// ID du block fiches content
    var isCti = false;
    var isInitByJSON = false;

    ctrl.showEtape2 = true;							  // Lors de l'enregistrement, montrer la fenetre de communication
    ctrl.documents = [];
    ctrl.listeGroupements = [];						// Liste des groupements
    ctrl.listeTagsType = [];
    ctrl.listeGroupeAddByGroupeAssociation = [];
    ctrl.editMsgObjet = false;
    ctrl.datePickerOptions = {
      minDate: new Date(),
      startingDay: 1,
      showWeeks: true
    };
    ctrl.showBtnPopoverConcerne = false;
    ctrl.currentModelEvent = null;
    ctrl.modelsEvenements = UtilsService.getModulesModelesEvenements();
    ctrl.parseStringHTML = UtilsService.parseStringHTML;	// Permet de garder les attributs style du HTML, sinon il sont supprim�
    ctrl.nl2br = UtilsService.nl2br;
    ctrl.hasPortefeuille = $rootScope.hasPortefeuille;
    ctrl.addEvenementTopic = ContenuService.addEvenementTopic;
    ctrl.focusEndEditor = UtilsService.focusEndEditor;	// Permet de focus le WYSIWYG
    ctrl.instanceCKEDITOR = false;			// Instance CKEDITOR pour l'objet
    ctrl.instanceFichesCKEDITOR = false;	// Instance CKEDITOR pour les fiches
    ctrl.ckeditorOptions = {
      on: {
        instanceReady: function () {
          this.dataProcessor.htmlFilter.addRules({
            elements: {
              img: function (el) {
                var isBase64 = UtilsService.test(el.attributes.src, 'base64', 'i');
                if (isBase64) {
                  el.addClass('image-max-editor');
                }
              }
            }
          });
        },
        focus: function () {
          // Ferme le popover des tags si ouvert
          $rootScope.$broadcast('closePopoverTag');
          $rootScope.$broadcast('closeTooltipDestinataire');
          $rootScope.$broadcast('closePopoverCategorieTopic');
        }
      }
    };
    ctrl.isImage = UtilsService.isImage;

    ctrl.initNotifWaitUpload = initNotifWaitUpload;

    // Destinataire
    //ctrl.groupeAll = null;      // Groupe All temporaire avec sa hiérarchie pour éviter de recupérer à chaque fois tant que le composant n'est pas destroy
    ctrl.onChangeDestinataires = onChangeDestinataires; // Lors de changement dans les destinataire
    //ctrl.onEndGetGroupeAllFromDestinataireComponent = onEndGetGroupeAllFromDestinataireComponent;
    ctrl.onClickAddDestinatairesFromGroupeAssociation = onClickAddDestinatairesFromGroupeAssociation;

    ctrl.ckeditorOnReady = ckeditorOnReady;	                  // Appelé lorsque le WYSIWYG est bien ready
    ctrl.focusTitle = focusTitle;						                  // Permet de focus le champ title
    ctrl.hasDefaultDestinataire = hasDefaultDestinataire;		  // Permet de savoir si des destinataire temporaire doivent être ajoutés
    ctrl.addDefaultDestinataires = addDefaultDestinataires;		// Permet d'ajouter des destinataires
    ctrl.notifySuccessSave = notifySuccessSave;			          // Permet d'afficher une notification de success

    ctrl.onClickCleanModificationEvenement = onClickCleanModificationEvenement;	// Permet de reset le fomulaire d'événement
    ctrl.onClickUrgent = onClickUrgent;								        // Lors du clique sur le bouton urgent
    ctrl.onClickEditMsg = onClickEditMsg;											// Permet de passer le formulaire de l'événement en mode édition
    ctrl.onChangeModelEvent = onChangeModelEvent;             // Lors du changement de model événement
    ctrl.onClickDateEcheance = onClickDateEcheance;						// Lors du clique sur le bouton pour ouvrir le datepicker de la date d'echeance
    ctrl.onClickDateIntervention = onClickDateIntervention;		// Lors du clique sur le bouton pour ouvrir le datepicker de la date d'intervention
    ctrl.onClickCommunication = onClickCommunication;         // Lors du clique sur le bouton "communication"
    ctrl.onClickSaveTmp = onClickSaveTmp;					            // Permet de sauvegarder le fomulaire d'événement

    ctrl.onClickPrendreInterventionEvent = onClickPrendreInterventionEvent;
    ctrl.onClickTraiterEvent = onClickTraiterEvent;
    ctrl.onClickProblemeEvent = onClickProblemeEvent;
    ctrl.onClickMettreEnAttenteEvent = onClickMettreEnAttenteEvent;

    ctrl.ckeditorFichesOnReady = ckeditorFichesOnReady;				// Fonction executé lorsque le WYSIWYG pour les fiches est ready

    ctrl.onEmitFromSelectCategorieTopic = onEmitFromSelectCategorieTopic;	// Lors d'une modification de categorie de topic
    //ctrl.onUpdatePiecesJointes = onUpdatePiecesJointes;				// Lors d'une modification de pièces jointes
    ctrl.onEmitFromDocuments = onEmitFromDocuments;				    // Lors d'une modification de pièces jointes
    ctrl.onUpdateTag = onUpdateTag;									          // Lors d'un tag (va remplacer les autres au fur et à mesure)

    ctrl.onEmitFromManagerDemandeDevisIntervention = onEmitFromManagerDemandeDevisIntervention;					// Lors d'une remonté d'infos du composant "manage-devis"
    ctrl.onEmitFromManagerDemandeContrat = onEmitFromManagerDemandeContrat;					// Lors d'une remonté d'infos du composant "manage-devis"
    ctrl.onEmitFromCourrier = onEmitFromCourrier;					// Lors d'une remonté d'infos du composant "courrier"
    ctrl.onEmitFromUploadCollab = onEmitFromUploadCollab;   // Lors d'une remonté d'infos du composant "upload-collab"
    ctrl.onEmitFromListDocument = onEmitFromListDocument;   // Lors d'une remonté d'infos du composant "list-document"

    ctrl.onEmitFromCheckAssociationsComponent = onEmitFromCheckAssociationsComponent;

    // Sinistre
    ctrl.onClickDeclarerSinistre = onClickDeclarerSinistre;		// Lors du clique sur le bouton pour déclarer un sinistre
    ctrl.onEmitFromSinistre = onEmitFromSinistre;

    ctrl.onSubmitContenu = onSubmitContenu;                     // New : Sauvegarde de l'évenement
    ctrl.allowEditEvenement = allowEditEvenement;					     // Permet de savoir si l'événement est éditable par l'utilisateur
    ctrl.getObjContenu = getObjContenu;	      // Permet de recupérer un objet du contenu actuellement en cours de modification ou de création pour le stocker dans le local storage
    ctrl.getFicheObj = getFicheObj;		        // Fonction qui genere le HTML pour une fiche de groupe collab
    ctrl.getInfosPerso = getInfosPerso;	      // Fonction qui genere le HTML pour une perso ICS

    ctrl.onClickClose = onClickClose;

    ctrl.$onInit = function() {
      if(ctrl.resolve && ctrl.modalInstance) {
        ctrl.contenuEvenement = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'contenuEvenement'));
        ctrl.topicInformations = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'topicInformations'));
        ctrl.tags = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'tags'));
        ctrl.tagsType = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'tagsType'));
      }

      ctrl.evenement = angular.copy(ctrl.contenuEvenement);
      initNotifWaitUpload();

      $scope.$on('showLoadingContenuEvenementFormulaire', function () {
        ctrl.submitProgress = true;
      });
      $scope.$on('hideLoadingContenuEvenementFormulaire', function () {
        ctrl.submitProgress = false;
      });

      init();
    };

    ctrl.$onChanges = function(changes) {
      if(_.isObject(changes.contenuEvenement) && !changes.contenuEvenement.isFirstChange()){
        //console.log('change old contenuEvenement', ctrl.contenuEvenement);
        //console.log('change new contenuEvenement', changes.contenuEvenement.currentValue);
        //console.log("changes.contenuEvenement", changes.contenuEvenement);
        ctrl.evenement = changes.contenuEvenement.currentValue;
        init();
      }

      if(_.isObject(changes.categorieTopic) && !changes.categorieTopic.isFirstChange()) {
        ctrl.categorieTopic = changes.categorieTopic.currentValue;
      }
    };

    ctrl.$onDestroy = function(){
      var GroupeService = $injector.get('GroupeService');
      GroupeService.destroy();
    };

    function init(){
      //initInfosFromTagsType();
      //console.log('init', ctrl.evenement);
      if($stateParams.action === 'addEventFromVisicop') {
        $rootScope.openInVisicop = true;
      }
      ctrl.isNewTopic = false;
      if(!ctrl.idTopic) ctrl.isNewTopic = true;

      if (angular.isObject(ctrl.defaultParams) && ctrl.defaultParams.hauteurEditor) {
        // Options des WYSIWYG
        ctrl.ckeditorOptions.autoGrow_minHeight = ctrl.defaultParams.hauteurEditor;	// Hauteur
      }

      // Si pas de contenu evenement alors création d'un objet ContenuEvenement vide
      if (angular.isUndefined(ctrl.evenement)) {
        ctrl.evenement = new ContenuEvenement();
        if(ctrl.idTopic) ctrl.evenement.setIdTopic(ctrl.idTopic);
        if(angular.isArray(ctrl.tagsType)) ctrl.evenement.setTagsType(angular.copy(ctrl.tagsType));
        if(angular.isArray(ctrl.tags)) ctrl.evenement.setTags(angular.copy(ctrl.tags));
      }

      /************** CKEDITOR *******************/
      // Options des WYSIWYG
      ctrl.ckeditorOptions.height = 300;						// Hauteur
      ctrl.ckeditorOptions.removePlugins = 'image,forms,floating-tools,fixed,maximize,resize';
      ctrl.ckeditorOptions.extraPlugins = 'dragresize,blockquoteGroupeUser,sharedspace';
      ctrl.ckeditorOptions.sharedSpaces = {
        top: 'topCkeditor',
        bottom: 'bottomCkeditor'
      };

      if($rootScope.simplifyViewForCamping) ctrl.ckeditorOptions.toolbar = COLLAB_CONF.TOOLBAR_CKEDITOR_MINI;

      ctrl.ckeditorFichesOptions = angular.copy(ctrl.ckeditorOptions);
      //ctrl.ckeditorFichesOptions.enterMode = CKEDITOR.ENTER_BR;
      //ctrl.ckeditorFichesOptions.fillEmptyBlocks = false;
      //ctrl.ckeditorFichesOptions.tabSpaces = 0;
      //ctrl.ckeditorFichesOptions.forcePasteAsPlainText = true;
      ctrl.ckeditorFichesOptions.entities = false;
      ctrl.ckeditorFichesOptions.basicEntities = false;

      // Avant chaque execution d'une command ckeditor
      ctrl.ckeditorFichesOptions.on.beforeCommandExec = function (evt) {
        // Si chargement du plugin des fiches
        if (evt.data.name === 'blockquoteGroupeUser') {
          //evt.editor.focus();
          ctrl.focusEndEditor(evt.editor);
        }
      };

      // Après chaque execution d'une command ckeditor
      ctrl.ckeditorFichesOptions.on.afterCommandExec = function (evt) {
        // Si chargement du plugin des fiches
        if (evt.data.name === 'blockquoteGroupeUser') {

          focusAfterAddOrigine(ctrl.instanceFichesCKEDITOR).then(function(){

            if (evt.data.hasOwnProperty('commandData')) {
              // Si il n'y a as d'attribut indiquant qu'il ne faut pas save dans local storage
              //if(!evt.data.commandData.hasOwnProperty('noSaveInLocalStorage') || !evt.data.commandData.noSaveInLocalStorage) {
              //	saveLocalStorage();
              //}
              if (evt.data.commandData.hasOwnProperty('callBack') || angular.isFunction(evt.data.commandData.callBack)) {
                evt.data.commandData.callBack();

              }
            }
          });
        }
      };

      /*
      ctrl.ckeditorFichesOptions.on.setData = function(evt) {
        evt.data.dataValue = evt.data.dataValue.replace(new RegExp("\n","gm"),"");
        console.log(evt)
      };
       */
      /*
       ctrl.contenuFormulaireCtrl.ckeditorFichesOptions.on.afterSetData = function(evt){
       console.log('AfterSetData');
       console.log(evt);


       };
       ctrl.contenuFormulaireCtrl.ckeditorFichesOptions.on.dataReady = function(evt){
       console.log('DataReady');
       console.log(evt);


       };*/
      /************** Fin CKEDITOR *******************/

      /************ Pièce jointes ****************/
      /*
      if(_.isNil(ctrl.uploader)){

        //ctrl.whenAddingAttachement = [{idGroupe: UtilsService.getCurrentUser().getIdGroupe()}];
        //if(!isNull(ctrl.idTopic)) ctrl.whenAddingAttachement.push({idTopic: ctrl.idTopic});

        ctrl.uploader = new FileUploader({
          autoUpload: true,
          url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_DOCUMENTS_IN_GROUPE,
          alias: 'fileName',
          removeAfterUpload: false,

          filters: [{
            name: 'sizeLimit',
            fn: function (item) {
              //COLLAB_CONF.SIZE_LIMIT_UPLOAD
              if (item.size <= COLLAB_CONF.SIZE_LIMIT_UPLOAD) return true;
              sweet.show({
                title: '<span class="text-danger">' + item.name + '</span> trop gros !',
                text: '50 Mo Maximum',
                type: 'error',
                html: true
              });
            }
          }]
        });

        // Après l'ajout d'un fichier en pièce jointe
        ctrl.uploader.onAfterAddingFile = function (fileItem) {
          fileItem.formData.push({idTopic: ctrl.idTopic});
        };
      }*/


      /*
      // Après l'ajout d'un fichier en pièce jointe
      ctrl.uploader.onAfterAddingFile = function (fileItem) {
        fileItem.formData.push({idGroupe: UserService.getGroupe().getIdGroupe()});
        fileItem.formData.push({token: UserService.getGroupe().getToken()});
      };

      // Lorsqu'un fichier est bien upload
      ctrl.uploader.onSuccessItem = function (fileItem, response) {
        if (response.nb !== '0') {
          ctrl.documents = ctrl.evenement.getListeDocument();
          angular.forEach(response.Documents, function (document) {
            // Ajoute le document dans le document manager
            var instanceDocument = DocumentService.setDocument(document);
            fileItem._file.document = instanceDocument;

            // Ajoute le document dans le tableau des pièces jointes à joindre au contenu
            ctrl.documents.push(instanceDocument);
          });
        }
      };

      ctrl.uploader.onProgressAll = function (progress) {
        ctrl.notifWaitUpload.progress = progress;
        if (!ctrl.notifWaitUpload.show) {
          ctrl.notifWaitUpload.show = true;
          NotificationsToastServices.wait('Patientez', 'Upload des documents en cours...', function (notif) {
            ctrl.notifWaitUpload.obj = notif;
          });
        }
      };

      // Après upload de tout les fichiers
      ctrl.uploader.onCompleteAll = function () {

        // Set les nouveaux documents
        ctrl.evenement.setListeDocument(ctrl.documents);

        // Supprime la liste des fichier upload
        ctrl.uploader.clearQueue();

        if (ctrl.notifWaitUpload.show) {
          NotificationsToastServices.clear(ctrl.notifWaitUpload.obj.id);
          initNotifWaitUpload();
        }

        ctrl.documents = [];
        ctrl.submitProgress = true;
        ctrl.evenement
          .updateDocuments()
          .then(function () {
            emitEvenement();
            // Mise à jour du widget des documents
            //$scope.$emit('refreshDocuments');
          })
          .catch(function (msg) {
            ModalsService.alertErreur(msg);
          })
          .finally(function () {
            ctrl.submitProgress = false;
          });
      };

      // Lors du drop d'un fichier GED
      ctrl.onDropDocumentFromGedPerso = function () {
        var doc = ctrl.itemPieceJointeDrop;
        if (angular.isObject(doc)) {

          var documents = ctrl.evenement.getListeDocument();
          // Recupère un tableau avec le fichier si il existe
          var existFile = UtilsService.where(documents, {guid: doc.getGuid()});

          // Si le fichier n'existe pas déjà on l'ajoute
          if (!existFile.length) {
            documents.push(doc);
            ctrl.evenement.setListeDocument(documents);
            ctrl.submitProgress = true;
            ctrl.evenement
              .updateDocuments()
              .then(function () {
                $rootScope.$broadcast('refreshListContenus');
              })
              .catch(function (msg) {
                ModalsService.alertErreur(msg);
              })
              .finally(function () {
                ctrl.submitProgress = false;
              });
          }
        }
      };
      */

      /************ Fin Pièce jointes ****************/

      // Recupère les contenu sauvegardé
      ctrl.objContenuSave = getContenuSave();
      ctrl.tmpReferenceClient = ctrl.evenement.getReferenceClient();
      ctrl.tmpObjet = ctrl.evenement.getObjet();
      if (ctrl.evenement.getDateEcheance()) ctrl.tmpDateEcheance = ctrl.evenement.getDateEcheance(true);
      if (ctrl.evenement.getDateIntervention()) ctrl.tmpDateIntervention = ctrl.evenement.getDateIntervention(true);

      // Si il y a des documents
      if (ctrl.evenement.getListeDocument().length) ctrl.openPiecesJointes = true;
      // Si pas de documents
      else ctrl.openPiecesJointes = false;

      if ($stateParams.hasOwnProperty('action')) {
        if ($stateParams.action === 'cti') {
          isCti = true;
          ctrl.submitProgress = true;
        }
        else if($stateParams.action === 'addEventFromVisicop') {
          isInitByJSON = true;
          ctrl.submitProgress = true;
        }
      }

      if (!isCti) {
        // Si il y a un contenu sauvegardé dans le LocalStorage
        if (ctrl.objContenuSave) {

          // Initialise la liste des contacts
          ctrl.contacts = AnnuaireService.updateListeContact(ctrl.objContenuSave.destinataires);

          // Si c'est un nouvel evenement
          if (ctrl.isNewTopic || ctrl.addEvenementTopic) {
            // Si il y a un titre
            if (ctrl.objContenuSave.formTopicTitre) ctrl.formTopicTitre = ctrl.objContenuSave.formTopicTitre;

            ctrl.evenement.setDestinataires(ctrl.objContenuSave.destinataires);
            ctrl.evenement.setDestinatairesHide(ctrl.objContenuSave.destinatairesHide);
            ctrl.evenement.setAssigneA(ctrl.objContenuSave.assigneA);
            ctrl.evenement.setAssignePar(ctrl.objContenuSave.assignePar);
            ctrl.evenement.setTags(ctrl.objContenuSave.tags);
            ctrl.evenement.setTagsType(ctrl.objContenuSave.tagsType);
            ctrl.evenement.setUrgent(ctrl.objContenuSave.urgent);

            if (ctrl.objContenuSave.currentModelEvent && angular.isDefined(ctrl.objContenuSave.currentModelEvent)) ctrl.currentModelEvent = ctrl.objContenuSave.currentModelEvent;

            //updateDestinatairesFromGroupeAssociation(ctrl.objContenuSave.tagsType);
          }

          // L'objet et la réfénce client sont les deux seul champs qui peuvent être sauvegardés si l'événement existe déjà.
          // Set la référence client dans une variable temporaire du controller
          ctrl.tmpReferenceClient = ctrl.objContenuSave.referenceClient;
          if (ctrl.objContenuSave.dateEcheance) ctrl.tmpDateEcheance = new Date(ctrl.objContenuSave.dateEcheance);
          if (ctrl.objContenuSave.dateIntervention) ctrl.tmpDateIntervention = new Date(ctrl.objContenuSave.dateIntervention);

          // Set l'objet client dans une variable temporaire du controller
          if (ctrl.objContenuSave.objet !== '' && angular.isDefined(ctrl.objContenuSave.objet)) ctrl.tmpObjet = ctrl.objContenuSave.objet;
        }
        // Si c'est un nouveau topic ou l'ajout d'un événement depuis un topic existant mais une modification d'un evenement
        else initDefaultModelAndDestinataires();
      }

      // Si nouveau topic ou ajout d'un événement dans un topic existant sans evenement
      if (ctrl.isNewTopic || ctrl.addEvenementTopic) ctrl.editMsgObjet = true;

      // Recup les paramétrages de l'utilisateur ou de sa societe
      var defaultParametrage = $rootScope.current.userGroupe.getDefaultParametrage();
      // Si il y a des params
      if (defaultParametrage) {
        // Si il faut pas passer par l'étape 2 de la création d'event (communication)
        if (defaultParametrage.hasOwnProperty('noCommunicationAfterCreateEvent') && defaultParametrage.getNoCommunicationAfterCreateEvent() === true) {
          ctrl.showEtape2 = false;
        }
      }

      ctrl.infosEvenement = UtilsService.getInfosEvenement(getEvenement());

      ctrl.isEventIntervention = false;
      if(UtilsService.isCapfun() && ctrl.evenement.getIdContenu()){
        var tagTypeType = TagService.getTagForType(ctrl.evenement.getTagsType(), COLLAB_CONF.TAG_TYPE_TYPE);
        if(tagTypeType && tagTypeType.isTagTypeIntervention()) ctrl.isEventIntervention = true;
      }
      initObjetAndFiches();
    }

    /*
    function initInfosFromTagsType(tags){

      ctrl.infosFromTagsType = {
        portefeuille: null,
        tagMandat: null,
        tagImmeuble: null,
        tagLot: null,
        tagProprietaire: null
      };

      if(_.isArray(tags) && tags.length){
        var tagTypePortefeuille = TagsService.getTagTypePortefeuille(tags);
        var tagTypeImmeuble = TagsService.getTagTypeImmeuble(tags);
        var tagTypeProprietaire = TagsService.getTagTypeProprietaire(tags);
        if(tagTypePortefeuille) ctrl.infosFromTagsType.portefeuille = tagTypePortefeuille.getPortefeuille();
        if(tagTypeImmeuble) ctrl.infosFromTagsType.tagImmeuble = tagTypeImmeuble;
        if(tagTypeProprietaire) ctrl.infosFromTagsType.tagProprietaire = tagTypeImmeuble;
      }
    }*/

    /*
    function updateDestinatairesFromGroupeAssociation(tags){

      if(_.isArray(tags)) initInfosFromTagsType(tags);

      if((_.isNil(tags) || _.isArray(tags)) &&_.isObject(ctrl.infosFromTagsType.portefeuille) && (_.isObject(ctrl.infosFromTagsType.tagImmeuble) || _.isObject(ctrl.infosFromTagsType.tagProprietaire))) {
        var typeAssociation = 'immeuble';
        var valAssociation = ctrl.infosFromTagsType.tagImmeuble.getValeurIcs();
        if(_.isObject(ctrl.infosFromTagsType.proprietaire)) {
          typeAssociation = 'proprietaire';
          valAssociation = ctrl.infosFromTagsType.tagProprietaire.getValeurIcs();
        }

        ctrl.loadingDestinataires = true;
        GroupeAssociationService.getListGroupeFromAssociation(ctrl.infosFromTagsType.portefeuille.idPortefeuille, typeAssociation, valAssociation)
          .then(function(listGroupe){
            if(listGroupe.length) {
              listGroupe = _.uniqWith(listGroupe, _.isEqual);
              ctrl.listeGroupeAddByGroupeAssociation = listGroupe;
              ctrl.evenement.addDestinataire(listGroupe);
            }
          })
          .finally(function(){
            ctrl.loadingDestinataires = false;
          });
      }
      else if(ctrl.listeGroupeAddByGroupeAssociation.length && !ctrl.evenement.idContenu){
        // Si il y a des destinataires venant d'associations à ajouter
        ctrl.evenement.addDestinataire(ctrl.listeGroupeAddByGroupeAssociation);
      }
    }*/

    function updateDestinatairesFromGroupeAssociation(listeGroupeAddByGroupeAssociation, typeAction){

      // Si des destinataires ont été ajoutés vie un GroupeAssociation, on les retire car on va tous les remettre après
      if(ctrl.listeGroupeAddByGroupeAssociation.length){
        getEvenement().removeDestinataires(ctrl.listeGroupeAddByGroupeAssociation);

        // Si il y a des destinataires d'un model alors on les remets
        if(_.isArray(ctrl.destinataireFromModel)) getEvenement().addDestinataire(ctrl.destinataireFromModel);
      }

      // Si le paramètre est null on vide la liste des collaborateurs venant des associations
      if(_.isNil(listeGroupeAddByGroupeAssociation)) {
        ctrl.listeGroupeAddByGroupeAssociation = [];
        ctrl.loadingDestinataires = false;
      }
      // Si le paramètre est un boolean on remet les destinataires des groupe associé dans les destinataire de l'événement
      else if(!_.isNil(listeGroupeAddByGroupeAssociation) && _.isBoolean(listeGroupeAddByGroupeAssociation) && ctrl.listeGroupeAddByGroupeAssociation.length) {
        // Si il y a des destinataires venant d'associations à ajouter
        getEvenement().addDestinataire(ctrl.listeGroupeAddByGroupeAssociation);
      }
      else if(_.isArray(listeGroupeAddByGroupeAssociation)){

        // Si l'événement existe déjà on enregistre les ajout de destinataire
        if(getEvenement().idContenu && typeAction === "add"){

          // Liste des destinataires actuel de l'évenement
          var listeDestinataireActuel = getEvenement().getDestinataires(true);

          // Retire de la liste des groupes choisi en association, la liste des destinataire déjà présent
          listeGroupeAddByGroupeAssociation = _.differenceBy(listeGroupeAddByGroupeAssociation, listeDestinataireActuel, "idGroupe");

          // Si il reste des groupes alors on les ajoute à l'événement
          if(listeGroupeAddByGroupeAssociation.length){

            var listLibelleDiese = listeGroupeAddByGroupeAssociation.map(function(g){
              return g.getLibelleDieze();
            });
            ctrl.loadingDestinataires = true;
            var ContenuService = $injector.get('ContenuService');
            ContenuService
              .addDestinatairesToContenu(getEvenement().idContenu, listLibelleDiese)
              .then(function(groupes){
                getEvenement().addDestinataire(groupes);
              })
              .finally(function(){
                ctrl.loadingDestinataires = false;
              });
          }

        }
        // Si l'événement n'existe pas encore
        else if(!getEvenement().idContenu){
          if(listeGroupeAddByGroupeAssociation.length){
            listeGroupeAddByGroupeAssociation.map(function(groupe){
              groupe.fromGroupeAssociation = true;
              return groupe;
            });
          }
          ctrl.listeGroupeAddByGroupeAssociation = listeGroupeAddByGroupeAssociation;
          // Si il y a des destinataires venant d'associations à ajouter
          getEvenement().addDestinataire(ctrl.listeGroupeAddByGroupeAssociation);
          ctrl.loadingDestinataires = false;
        }
      }
      ctrl.displayBtnAddGroupeFromAssociations = false;
    }

    function initByAction(){
      if (isCti) initC3T();
      else if(isInitByJSON) initByJSON();
    }

    /**
     * Permet de récupérer l'événement
     * @returns {string|*|ContenuEvenement}
     */
    function getEvenement() {
      return ctrl.evenement;
    }
    /**
     * Permet de setter l'evenement
     * @param evenement
     */
    function setEvenement(evenement) {
      var event = null;
      if (angular.isDefined(evenement)) {
        if (angular.isObject(evenement)) {
          event = evenement;
          if (!event.isModel) event = new ContenuEvenement(evenement);
        }
        else ModalsService.alertErreur('Evenement n\'est pas un objet');
      }
      else ModalsService.alertErreur('Evenement indéfini');
      ctrl.evenement = event;
    }

    function getTypeIntervenant(){
      var typeIntervenant = null;
      if($rootScope.isInGroupeTechnique) typeIntervenant = "technicien";
      else if($rootScope.isInGroupeMenage) typeIntervenant = "menage";
      return typeIntervenant;
    }

    /**
     * Recupère un objet avec 2 attributs :
     * contentHtml : Contient la versio final de texte de l'editeur avec les wrapper
     * contentText : Contient une version du texte de l'editeur sans les wrapper pour tester si l'editeur est rempli
     * @returns {{contentHtml: string, contentText: *}}
     */
    function getCkeditorData() {
      var objet = {};
      if (ctrl.instanceCKEDITOR && ctrl.instanceFichesCKEDITOR) {
        var contentMain = ctrl.instanceCKEDITOR.getData();
        var contentFiches = ctrl.instanceFichesCKEDITOR.getData();
        var wrapperMainContent = wrappeMainCkeditor(contentMain);
        var wrapperFichesContent = wrappeFichesCkeditor();
        objet = {
          mainContent: wrapperMainContent,
          fichesContent: wrapperFichesContent,
          contentHtml: wrapperMainContent + wrapperFichesContent,
          contentText: contentMain + contentFiches
        };
      }
      else {
        objet = {
          contentHtml: ctrl.evenement.getObjet()
        };
      }
      return objet;
    }

    /**
     * Permet de recupérer un objet du contenu actuellement en cours de modification ou de création pour le stocker dans le local storage
     * @returns {*}
     */
    function getObjContenu(addObj) {
      var content = getCkeditorData();

      // Est utilisé pour la sauvegarde automatique dans le local storage
      var copyForSave = {
        objet: content.contentHtml,
        typeContenu: angular.copy(ctrl.evenement.getTypeContenu()),
        referenceClient: ctrl.tmpReferenceClient,
        urgent: ctrl.evenement.getUrgent()
      };

      if (ctrl.tmpDateEcheance) copyForSave.dateEcheance = new Date(ctrl.tmpDateEcheance);
      if (ctrl.tmpDateIntervention) copyForSave.dateIntervention = new Date(ctrl.tmpDateIntervention);

      // Si c'est un nouvel evenement
      if (!ctrl.evenement.getIdContenu()) {

        // Si ne viens pas du bouton pour load l'origine lors de larrivé
        if (!$state.params.loadOrigine) {
          copyForSave.tags = ctrl.evenement.getTags();
          copyForSave.tagsType = ctrl.evenement.getTagsType();
        }

        copyForSave.destinataires = angular.copy(ctrl.evenement.getDestinataires());
        copyForSave.destinatairesHide = angular.copy(ctrl.evenement.getDestinatairesHide());
        copyForSave.assigneA = ctrl.evenement.getAssigneA();
        copyForSave.assignePar = ctrl.evenement.getAssignePar();
        if (ctrl.isNewTopic) copyForSave.formTopicTitre = ctrl.formTopicTitre;
        if (ctrl.isNewTopic || ctrl.addEvenementTopic) copyForSave.currentModelEvent = ctrl.currentModelEvent;
      }

      // Si il y a un objet à ajouter
      if (addObj) {
        // Parcour cet objet à ajouter
        angular.forEach(addObj, function (valObj, indexObj) {
          copyForSave[indexObj] = valObj;
        });
      }

      ctrl.objContenuSave = copyForSave;
      return copyForSave;
    }

    /**
     * Appelé lorsque le WYSIWYG est bien ready
     * @param idContenu
     * @param isFiches
     */
    function whenCkeditorIsReady(idContenu, isFiches) {
      if (!idContenu) idContenu = 0;
      var instance = ctrl.instanceCKEDITOR;
      if (isFiches) {
        instance = ctrl.instanceFichesCKEDITOR;
        idContenu += '_Fiches';
      }
      // Ajout l'instance qui vien d'être créé dans le pool d'instance de CKEDITOR
      $rootScope._poolInstancesCKEDITOR[idContenu] = instance;
    }

    /**
     * Lorsque l'instance du WYSIWYG des fiches est ready
     * @param item
     */
    function ckeditorFichesOnReady(item) {
      var content = splitContent(ctrl.tmpObjet);
      ctrl.instanceFichesCKEDITOR.setData(content.fichesContent, {
        callback: function () {
          var _this = this;
          _this.resize('100%', 300, true, true);
          whenCkeditorIsReady(item.idContenu, true);
          initByAction();
          if ($state.params.loadOrigine) {
            // Si nouveau topic ou ajout d'un événement dans un topic existant sans evenement
            if (ctrl.isNewTopic || ctrl.addEvenementTopic) {
              // Si il y a un tag origine à ajouter lors de l'arrivée sur le nouvel événement
              if ($rootScope.current.tmpNewEvenement.tagsForOrigine) {
                if ($rootScope.current.tmpNewEvenement.tagsForOrigine.hasOwnProperty('addTag')) {
                  deleteOrigine(true).then(function () {
                    addOrigine($rootScope.current.tmpNewEvenement.tagsForOrigine.addTag, true);
                  });
                }
              }
            }
          }
          else if(_.isArray(ctrl.tagsType) && ctrl.tagsType.length) {
            generateFichesFromTags(ctrl.tagsType, ctrl.tags);
          }
        }
      });
    }

    /**
     * Permet de regénerer les fiches à partir des tags type
     * @param tagsType
     * @param tags
     */
    /*
    function generateFichesFromTags(tagsType, tags){
      //resetForm();
      var promises = [];
      var objParams = {
        fiches: {},
        tags: (angular.isArray(tags)) ? tags : [],
        tagsType: tagsType
      };

      var GroupeService = $injector.get('GroupeService');

      var tagOrigine = null;
      var tagLocataire = null;
      angular.forEach(tagsType, function(currentTag) {
        if(currentTag.getPortefeuille()){

          var params = {
            avecemail: 0,
            idPortefeuilles: [currentTag.getPortefeuille().getIdPortefeuille()],
            recherche: ''
          };
          var isImmeuble = false;
          var callAnnuaire = false;

          // Recup le tagOrigine
          if(currentTag.getTypeTag().getLibelle() === COLLAB_CONF.TAG_TYPE_ORIGINE_ICS) tagOrigine = currentTag;


          if(currentTag.getTypeTag().getLibelle() === COLLAB_CONF.TAG_TYPE_PROPRIETAIRE ||
            currentTag.getTypeTag().getLibelle() === COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE ||
            currentTag.getTypeTag().getLibelle() === COLLAB_CONF.TAG_TYPE_LOCATAIRE) {

            angular.merge(params, {
              type: 'U',
              noperso: currentTag.getValeurIcs(),
              groupesCollab: 0,
              usersCollab: 0
            });
            callAnnuaire = true;

            if(currentTag.getTypeTag().getLibelle() === COLLAB_CONF.TAG_TYPE_LOCATAIRE) tagLocataire = currentTag;
          }
          else if(currentTag.isTagType(COLLAB_CONF.TAG_TYPE_IMMEUBLE)) {
            angular.merge(params, {
              recherche: currentTag.getValeurIcs(),
              type: 'I'
            });
            isImmeuble = true;
            callAnnuaire = true;
          }

          if(callAnnuaire) {
            var deferred = $q.defer();
            AnnuaireService
              .searchCollaborateur(params)
              .then(function (objectCollaborateur) {

                if(objectCollaborateur.hasOwnProperty('listePortefeuilles') &&
                  angular.isArray(objectCollaborateur.listePortefeuilles) &&
                  objectCollaborateur.listePortefeuilles.length) {

                  var portefeuilleResult = objectCollaborateur.listePortefeuilles[0];
                  if(angular.isObject(portefeuilleResult)) {
                    if(isImmeuble) {
                      if(angular.isArray(portefeuilleResult.listeImmeuble) && portefeuilleResult.listeImmeuble.length) {
                        var Immeuble = $injector.get('Immeuble');
                        objParams.fiches.immeuble = new Immeuble(portefeuilleResult.listeImmeuble[0]);
                        Immeuble = null;
                      }
                    }
                    else {
                      if(angular.isArray(portefeuilleResult.listePersonnalite) && portefeuilleResult.listePersonnalite.length) {
                        if(currentTag.getTypeTag().getLibelle() === COLLAB_CONF.TAG_TYPE_PROPRIETAIRE) objParams.fiches.proprietaire = portefeuilleResult.listePersonnalite[0];
                        if(currentTag.getTypeTag().getLibelle() === COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE) objParams.fiches.coproprietaire = portefeuilleResult.listePersonnalite[0];
                        if(currentTag.getTypeTag().getLibelle() === COLLAB_CONF.TAG_TYPE_LOCATAIRE) objParams.fiches.locataire = portefeuilleResult.listePersonnalite[0];
                      }
                    }
                  }
                }
              })
              .finally(function() {
                deferred.resolve();
              });

            promises.push(deferred.promise);
          }
        }
      });

      if(promises.length) ctrl.submitProgress = true;

      $q.all(promises).then(function(){
        // Si on a un tag origine et un locataire
        if(tagOrigine && tagLocataire) {
          // Si l'origine et le locataire sont les meme, il faut supprimer la fiche propriétaire eventuel
          if(tagLocataire.getValeurIcs() === tagOrigine.getValeurIcs()) {
            if(objParams.fiches.hasOwnProperty('proprietaire')) delete objParams.fiches.proprietaire;
          }
        }
        if(Object.keys(objParams.fiches).length) {
          deleteOrigine(true).then(function () {
            addOrigine(objParams, true);
            ctrl.submitProgress = false;
          });
        }
      });
      GroupeService = null;
    }*/

    function generateFichesFromTags(tagsType, tags){
      //resetForm();

      var objParams = {
        tagOrigine: null,
        tagLocataire: null,
        fiches: {},
        tags: (_.isArray(tags)) ? tags : [],
        tagsType: tagsType
      };

      ctrl.fichesProgresse = true;

      ctrl.submitProgress = true;
      //var chain = $q.when();
      synchGenereFiches(tagsType, objParams).then(function onSuccessSynchCall(retourObjParams){

        // Si on a un tag origine et un locataire
        if(retourObjParams.tagOrigine && retourObjParams.tagLocataire) {
          // Si l'origine et le locataire sont les meme, il faut supprimer la fiche propriétaire eventuel
          if(retourObjParams.tagLocataire.getValeurIcs() === retourObjParams.tagOrigine.getValeurIcs()) {
            if(retourObjParams.fiches.hasOwnProperty('proprietaire')) delete retourObjParams.fiches.proprietaire;
          }
        }
        if(Object.keys(retourObjParams.fiches).length) {
          deleteOrigine(true).then(function () {
            addOrigine(retourObjParams, true);
            ctrl.fichesProgresse = false;
          });
        }
      });
    }

    /**
     * Permet de synchroniser les appels à la génération des fiches par rapport à un tag type pour un array de tags type
     * objParams est l'accumulateur et donc la première promesse grace à la methode when de $q qui transforme une valeur en promesse
     * @param tagsType
     * @param objParams
     * @returns {*}
     */
    function synchGenereFiches(tagsType, objParams){
      return tagsType.reduce(function (promise, currentTag) {
        return promise.then(function(retourObjParams) {
          return genereFicheForTagType(retourObjParams, currentTag);
        });
      }, $q.when(objParams));
    }

    /**
     * Permet de générer un objet avec les fiches (entre autres) d'un tag type
     * @param objParams
     * @param currentTag
     * @returns {*}
     */
    function genereFicheForTagType(objParams, currentTag){
      var deferred = $q.defer();
      if(_.isNil(objParams)) {
        objParams = {
          tagOrigine: null,
          tagLocataire: null,
          fiches: {},
          tags: (angular.isArray(tags)) ? tags : [],
          tagsType: tagsType
        };
      }
      var resolve = false;
      if(currentTag.getPortefeuille()){

        var params = {
          avecemail: 0,
          idPortefeuilles: [currentTag.getPortefeuille().getIdPortefeuille()],
          recherche: ''
        };
        var isImmeuble = false;
        var callAnnuaire = false;

        // Recup le tagOrigine
        if(currentTag.getTypeTag().getLibelle() === COLLAB_CONF.TAG_TYPE_ORIGINE_ICS) objParams.tagOrigine = currentTag;

        if(currentTag.getTypeTag().getLibelle() === COLLAB_CONF.TAG_TYPE_PROPRIETAIRE ||
          currentTag.getTypeTag().getLibelle() === COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE ||
          currentTag.getTypeTag().getLibelle() === COLLAB_CONF.TAG_TYPE_LOCATAIRE) {

          angular.merge(params, {
            type: 'U',
            noperso: currentTag.getValeurIcs(),
            groupesCollab: 0,
            usersCollab: 0
          });
          callAnnuaire = true;

          if(currentTag.getTypeTag().getLibelle() === COLLAB_CONF.TAG_TYPE_LOCATAIRE) objParams.tagLocataire = currentTag;
        }
        else if(currentTag.isTagType(COLLAB_CONF.TAG_TYPE_IMMEUBLE)) {
          angular.merge(params, {
            recherche: currentTag.getValeurIcs(),
            type: 'I'
          });
          isImmeuble = true;
          callAnnuaire = true;
        }

        if(callAnnuaire) {
          AnnuaireService
            .searchCollaborateur(params)
            .then(function (objectCollaborateur) {

              if(objectCollaborateur.hasOwnProperty('listePortefeuilles') &&
                angular.isArray(objectCollaborateur.listePortefeuilles) &&
                objectCollaborateur.listePortefeuilles.length) {

                var portefeuilleResult = objectCollaborateur.listePortefeuilles[0];
                if(angular.isObject(portefeuilleResult)) {
                  if(isImmeuble) {
                    if(angular.isArray(portefeuilleResult.listeImmeuble) && portefeuilleResult.listeImmeuble.length) {
                      var Immeuble = $injector.get('Immeuble');
                      objParams.fiches.immeuble = new Immeuble(portefeuilleResult.listeImmeuble[0]);
                      Immeuble = null;
                    }
                  }
                  else {
                    if(angular.isArray(portefeuilleResult.listePersonnalite) && portefeuilleResult.listePersonnalite.length) {
                      if(currentTag.getTypeTag().getLibelle() === COLLAB_CONF.TAG_TYPE_PROPRIETAIRE) objParams.fiches.proprietaire = portefeuilleResult.listePersonnalite[0];
                      if(currentTag.getTypeTag().getLibelle() === COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE) objParams.fiches.coproprietaire = portefeuilleResult.listePersonnalite[0];
                      if(currentTag.getTypeTag().getLibelle() === COLLAB_CONF.TAG_TYPE_LOCATAIRE) objParams.fiches.locataire = portefeuilleResult.listePersonnalite[0];
                    }
                  }
                }
              }
            })
            .finally(function() {
              deferred.resolve(objParams);
            });
        }
        else resolve = true;
      }
      else resolve = true;

      if(resolve) deferred.resolve(objParams);

      return deferred.promise;
    }

    /**
     * Lorsque l'instance du WYSIWYG de l'objet est ready
     * @param item
     */
    function ckeditorOnReady(item) {
      var content = splitContent(ctrl.tmpObjet);
      ctrl.instanceCKEDITOR.setData(content.mainContent, {
        callback: function () {
          var _this = this;
          _this.resize('100%', 300, true, true);
          whenCkeditorIsReady(item.idContenu);
        }
      });
    }

    /**
     * Permet de focus le champ title
     * @param val
     */
    function focusTitle(val) {
      if (val) ctrl.titleFocus = true;
      else ctrl.titleFocus = false;
    }

    /**
     * Permet de savoir si des destinataires par défaut doivent être ajoutés
     * @returns {boolean}
     */
    function hasDefaultDestinataire() {
      if ($rootScope.current.tmpNewEvenement.destinataires.length) return true;
      return false;
    }

    /**
     * Permet d'ajouter les destinataires par défaut
     * @param destinataires
     */
    function addDefaultDestinataires(destinataires) {

      $timeout(function () {
        // Si il y a des destinataires à ajouter en arrivant sur la page
        if ($rootScope.current.tmpNewEvenement.destinataires.length) {
          for (var g = 0; g < $rootScope.current.tmpNewEvenement.destinataires.length; g++) {
            var exist = false;
            if (destinataires.length) {
              for (var d = 0; d < destinataires.length; d++) {
                if (destinataires[d].hasOwnProperty('destinataire') && $rootScope.current.tmpNewEvenement.destinataires[g].hasOwnProperty('destinataire')) {
                  if (destinataires[d].destinataire === $rootScope.current.tmpNewEvenement.destinataires[g].destinataire) {
                    exist = true;
                    break;
                  }
                }
              }
            }
            if (!exist) destinataires.push($rootScope.current.tmpNewEvenement.destinataires[g]);
          }
          //$rootScope.current.tmpNewEvenement.destinataires = [];
        }
        // Ajoute les destinataires venant des associations si il y en a
        updateDestinatairesFromGroupeAssociation(true);
      });
    }

    /**
     * Permet de faire une notification de success
     */
    function notifySuccessSave() {
      NotificationsToastServices.success('', '<strong>Sauvegardé !</strong>');
    }

    /**
     * Lors du clique sur le bouton "Communication"
     */
    function onClickCommunication() {
      $scope.$emit('openCommunication');
    }

    /**
     * Permet de focus le WYSIWYG après l'ajout d'un origine
     * @param editor
     */
    function focusAfterAddOrigine(editor) {

      var deferred = $q.defer();
      ctrl.titleFocus = false;
      $timeout(function () {
        if (!ctrl.formTopicTitre || ctrl.formTopicTitre === '') {
          ctrl.titleFocus = true;
          deferred.resolve();
        }
        else {
          if (!editor) editor = ctrl.instanceFichesCKEDITOR;

          ctrl.focusEndEditor(editor)
            .then(function(){
            })
            .finally(function(){
              deferred.resolve();
            });
        }
      });
      return deferred.promise;
    }

    /**
     * Permet d'init un objet pour la notification d'upload
     */
    function initNotifWaitUpload() {
      ctrl.notifWaitUpload = {
        progress: 0,
        obj: false,
        show: false
      };
    }

    /**
     * Permet de recupérer un éventuel contenu sauvegardé
     */
    function getContenuSave() {
      var objContenuSave = false;
      if(!ctrl.addEvenementTopic) {
        // Si modification d'un evenement
        if (angular.isDefined(ctrl.evenement) && ctrl.evenement.idContenu) {
          objContenuSave = AutoSaveService.get(ctrl.evenement.idContenu);

          if (angular.isObject(objContenuSave) && objContenuSave.hasOwnProperty('modification')) {
            // Test pour la nouvelle version du JSON sauvegardé
            if (objContenuSave.modification.hasOwnProperty('obj')) objContenuSave = objContenuSave.modification.obj;
            else objContenuSave = objContenuSave.modification;
          }
        }

        // Si nouveau
        if (!objContenuSave && !ctrl.evenement.idContenu) {
          objContenuSave = AutoSaveService.get('newEvenement');

          if (angular.isObject(objContenuSave)) {
            // Test pour la nouvelle version du JSON sauvegardé
            if (objContenuSave.hasOwnProperty('nouveau') && objContenuSave.nouveau.hasOwnProperty('obj')) objContenuSave = objContenuSave.nouveau.obj;
            // Retro compatibilité
            else if (objContenuSave.hasOwnProperty('reponse')) {
              // Test pour la nouvelle version du JSON sauvegardé
              if (objContenuSave.reponse.hasOwnProperty('obj')) objContenuSave = objContenuSave.reponse.obj;
              else objContenuSave = objContenuSave.reponse;
            }
          }
        }
      }
      return objContenuSave;
    }

    /**
     * Permet de charger le modèle par défault ou ajoute une liste de destinataire si il y en a une dans le rootscope
     */
    function initDefaultModelAndDestinataires(model) {
      // Si c'est un nouveau topic ou l'ajout d'un événement depuis un topic existant mais une modification d'un evenement
      if (ctrl.isNewTopic || ctrl.addEvenementTopic) {
        if (ctrl.modelsEvenements.length) {
          if (!model) {

            var selectModelWithIndex = false;
            // Si ce n'est pas le siège (car la selection de model pose problème pour Franceloc)
            if (!UtilsService.isCapfun()) {
              // Si c'est ICS
              if (UtilsService.isICS()) selectModelWithIndex = 0;
              // Si ce n'est pas ICS
              else {
                // Si seulement 1 modèle
                if (ctrl.modelsEvenements.length === 1 && ctrl.modelsEvenements[0].isModeleAgence) selectModelWithIndex = 0;
                // Si plusieur modèle
                else {
                  var defaultExit = false;
                  // Parcour les models event pour voir si il y en a un par défaut
                  for (var i = 0; i < ctrl.modelsEvenements.length; i++) {
                    // Si ce modèle est à séléctionner par défaut
                    if (ctrl.modelsEvenements[i].isModeleDefault) {
                      defaultExit = true;
                      break;
                    }
                  }

                  // Parcour les models event pour voir si il y en a un par défaut à selectionenr
                  for (var j = 0; j < ctrl.modelsEvenements.length; j++) {
                    // Si il faut prendre le modèle coché par défaut et si ce modèle est à séléctionner par défaut
                    if (defaultExit && ctrl.modelsEvenements[j].isModeleDefault) {
                      selectModelWithIndex = j;
                      break;
                    }
                    // Si pas de modèle par défaut coché alors on prends le 1er avec "isInFirstGroupeParent" à true
                    else if(ctrl.modelsEvenements[j].isInFirstGroupeParent) {
                      selectModelWithIndex = j;
                      break;
                    }
                  }
                }
              }
            }
            // Si c'est Capfun
            else {
              // Parcour les models event pour voir si il y en a un par défaut
              for (var d = 0; d < ctrl.modelsEvenements.length; d++) {
                // Si ce modèle est à séléctionner par défaut
                if (ctrl.modelsEvenements[d].isModeleDefault) {
                  selectModelWithIndex = d;
                  break;
                }
              }
            }

            // Si doit selectionner le 1er modèle
            if (selectModelWithIndex !== false) {
              $timeout(function () {
                onChangeModelEvent(selectModelWithIndex);
              });
            }
            else {
              addDefaultDestinataires(ctrl.evenement.getDestinataires());
            }
          } else {
            $timeout(function () {
              onChangeModelEvent(model);
            });
          }
        }
        else addDefaultDestinataires(ctrl.evenement.getDestinataires());
      }
    }

    var prevModel = false,
      prevTitre,
      prevObjet,
      tagsDelete = [];

    // TODO : Pourquoi pas save les model dans un objet pour en cas de modification du texte et changement du model gardé en mémoire les modif
    /**
     * Lors de la selection du model dans le champ select des model event
     */
    function onChangeModelEvent(index) {

      var contentSlip;
      if (!prevModel) {
        prevTitre = angular.copy(ctrl.formTopicTitre);
        prevObjet = angular.copy(ctrl.instanceCKEDITOR.getData());
      }
      else {
        if (prevModel.titre === '') prevTitre = angular.copy(ctrl.formTopicTitre);
        if (prevModel.objet === '') prevObjet = angular.copy(ctrl.instanceCKEDITOR.getData());
      }

      // Si l'index est numérique preselectione le 1er modèle
      if (angular.isNumber(index) && (index + 1) <= ctrl.modelsEvenements.length) ctrl.currentModelEvent = ctrl.modelsEvenements[index];
      // Si l'index est un objet
      else if (angular.isObject(index) && index.hasOwnProperty('idModule')) {
        // Si il y a des model event
        if (ctrl.modelsEvenements.length) {
          // Pour chaque model
          for (var i = 0; i < ctrl.modelsEvenements.length; i++) {
            // Si le model de la boucle en cours est égale à l'id du model demandé
            if (ctrl.modelsEvenements[i].idModule === index.idModule) {
              ctrl.currentModelEvent = ctrl.modelsEvenements[i];
              break;
            }
          }
        }
      }

      //console.log(ctrl.currentModelEvent);
      // Si un model est selectionné
      if (_.isObject(ctrl.currentModelEvent)) {

        var currentModel = ctrl.currentModelEvent;
        // Si il y a des model event
        //if (ctrl.modelsEvenements.length) {
        // Pour chaque model
        //for (var m = 0; m < ctrl.modelsEvenements.length; m++) {
        //var currentModel = ctrl.modelsEvenements[m];

        // Si le model de la boucle en cours est égale au model choisi
        //if (ctrl.currentModelEvent.idModule === currentModel.idModule) {

        if(!ctrl.evenement.idContenu) {
          // Si il y a des destinataires
          if (currentModel.destinataires.length) ctrl.evenement.setDestinataires(currentModel.destinataires);
          // Si il y un model choisi avant de changer
          else if (_.isObject(prevModel)) {
            // Si ce model avait des destinataires prédéfinis
            if (prevModel.destinataires.length) {
              ctrl.evenement.setDestinataires([]);		// Supprime les destinataires
            }
          }

          // Si il y a des destinataires
          if (currentModel.destinatairesHide.length) ctrl.evenement.setDestinatairesHide(currentModel.destinatairesHide);
          else ctrl.evenement.setDestinatairesHide([]);		// Supprime les destinataires caché

          // Si il y a des tags
          if (currentModel.tags.length) {
            var tagOrigine = false;
            // Si ily a des tags type
            if (ctrl.evenement.tagsType.length) {
              tagOrigine = TagService.getTagForType(ctrl.evenement.tagsType, COLLAB_CONF.TAG_TYPE_ORIGINE);
            }

            // Crée une copie des tag prédéfini du model
            var modelTagsTmp = angular.copy(currentModel.tags);
            // Pour chaque tag supprime le diese
            modelTagsTmp = modelTagsTmp.map(function (libelle) {
              return UtilsService.ltrim(libelle, '#');
            });

            var tagsTmp = angular.copy(ctrl.evenement.getTags());

            if (tagsTmp.length) {

              // Pour chaque tag de l'evenement
              angular.forEach(tagsTmp, function (tag) {

                var existInModel = false;
                var tagParse = UtilsService.ltrim(tag.libelle, '#');
                // Si le tag est dans la liste des tag autorisés par le model ?
                if (UtilsService.contains(modelTagsTmp, tagParse) || tagParse === COLLAB_CONF.TAG_TOCTOCTOC) existInModel = true;

                // Si pas trouvé dans le modele alors on le supprime de la liste des tags car pas autorisé
                if (!existInModel) {
                  for (var i = 0; i < ctrl.evenement.tags.length; i++) {
                    if (UtilsService.ltrim(ctrl.evenement.tags[i].libelle, '#') === tagParse) {

                      var canDelete = true;
                      // Si il y a un tag origine qui est le meme que le tag bleu alors on ne le supprime pas !
                      if (tagOrigine) {
                        if (UtilsService.ltrim(tagOrigine.libelle, '#') === tagParse) {
                          canDelete = false;
                        }
                      }
                      if (canDelete) {
                        tagsDelete.push(ctrl.evenement.tags[i]);
                        ctrl.evenement.tags.splice(i, 1);
                      }


                    }
                  }

                }
              });
            }
          }
          // Si il y un model choisi avant de changer
          else if (_.isObject(prevModel)) {
            // Si ce model avait des tags prédéfinis
            if (prevModel.tags.length) {
              if (tagsDelete.length) {
                for (var t = 0; t < tagsDelete.length; t++) {
                  ctrl.evenement.tags.push(tagsDelete[t]);
                }
                tagsDelete = [];
              }
            }
          }

          // Si il y a un titre
          if (currentModel.titre !== '') ctrl.formTopicTitre = currentModel.titre;
          // Si il y un model choisi avant de changer
          else if (_.isObject(prevModel)) {
            // Si ce model avait un titre prédéfini
            if (prevModel.titre !== '') {
              // Si il y avait un titre renseigné avant de changer
              if (prevTitre) {
                ctrl.formTopicTitre = angular.copy(prevTitre);
              }
              else {
                ctrl.formTopicTitre = '';							// Supprime le titre
              }
            }
          }
        }

        // Si il y a un objet
        if (currentModel.objet !== '') {

          ctrl.tmpObjet = wrappeMainCkeditor(currentModel.objet) + wrappeFichesCkeditor('');

          if (ctrl.instanceCKEDITOR.instanceReady) {
            ctrl.instanceCKEDITOR.setData(currentModel.objet);
          }
        }
        // Si il y un model choisi avant de changer
        else if (_.isObject(prevModel)) {
          // Si ce model avait un objet prédéfini
          if (prevModel.objet !== '') {
            if (prevObjet) {
              // Supprime l'objet dans le WYSIWYG
              ctrl.instanceCKEDITOR.setData(prevObjet, {
                callback: function () {
                  ctrl.tmpObjet = wrappeMainCkeditor() + wrappeFichesCkeditor();
                }
              });
            }
          }
        }
        // break;
        //}
        //}
        //}
      }
      // Si aucun model de renseignement selectionné
      else {

        // Si il y a un contenu save
        if (ctrl.objContenuSave) {

          if(!ctrl.evenement.idContenu) {
            ctrl.evenement.setDestinataires(ctrl.objContenuSave.destinataires);		 // Supprime les destinataires
            ctrl.evenement.setDestinatairesHide(ctrl.objContenuSave.destinatairesHide); // Supprime les destinataires caché

            // Si il y a un titre
            if (ctrl.objContenuSave.formTopicTitre) ctrl.formTopicTitre = ctrl.objContenuSave.formTopicTitre;

            if (ctrl.objContenuSave.tags.length) ctrl.evenement.setTags(ctrl.objContenuSave.tags);

            // Si ce model avait des tags prédéfinis
            if (angular.isArray(prevModel.tags) && prevModel.tags.length) {
              if (tagsDelete.length) {
                for (var j = 0; j < tagsDelete.length; j++) {
                  ctrl.evenement.tags.push(tagsDelete[j]);
                }
                tagsDelete = [];
              }
            }
          }

          // Set l'objet client dans une variable temporaire du controller
          if (ctrl.objContenuSave.objet !== '' && angular.isDefined(ctrl.objContenuSave.objet)) {
            ctrl.tmpObjet = ctrl.objContenuSave.objet;
            contentSlip = splitContent(ctrl.objContenuSave.objet);

            ctrl.instanceCKEDITOR.setData(contentSlip.mainContent);
            ctrl.instanceFichesCKEDITOR.setData(contentSlip.fichesContent);
          }
        }
        // Si aucun contenu save
        else {

          // Si il y avait un model choisi avant de changer
          if (_.isObject(prevModel)) {
            if(!ctrl.evenement.idContenu) {
              // Si ce model avait des destinataires prédéfinis
              if (prevModel.destinataires.length) ctrl.evenement.setDestinataires([]);		// Supprime les destinataires

              if (prevModel.titre !== '') {
                if (prevTitre) ctrl.formTopicTitre = angular.copy(prevTitre);
                else ctrl.formTopicTitre = '';							// Supprime le titre
              }

              // Si ce model avait des tags prédéfinis
              if (prevModel.tags.length && tagsDelete.length) {
                for (var k = 0; k < tagsDelete.length; k++) {
                  ctrl.evenement.tags.push(tagsDelete[k]);
                }
                tagsDelete = [];
              }
            }

            if (prevModel.objet !== '' && prevObjet) {
              // Supprime l'objet dans le WYSIWYG
              ctrl.instanceCKEDITOR.setData(prevObjet, {
                callback: function () {
                  ctrl.tmpObjet = wrappeMainCkeditor() + wrappeFichesCkeditor();
                }
              });
            }
          }
          if(!ctrl.evenement.idContenu) ctrl.evenement.setDestinatairesHide([]);
          //ctrl.instanceFichesCKEDITOR.setData('');	// Supprime l'objet dans le WYSIWYG
          //vm.tmpObjet = '';									// Supprime l'objet dans la variable Angular
        }
      }
      if(!ctrl.evenement.idContenu) addDefaultDestinataires(ctrl.evenement.destinataires);

      prevModel = angular.copy(ctrl.currentModelEvent);
      ctrl.destinataireFromModel = angular.copy(ctrl.evenement.destinataires);
    }
    /*
    function onChangeModelEvent(index) {

      var contentSlip;
      if (!prevModel) {
        prevTitre = angular.copy(ctrl.formTopicTitre);
        prevObjet = angular.copy(ctrl.instanceCKEDITOR.getData());
      }
      else {
        if (prevModel.titre === '') prevTitre = angular.copy(ctrl.formTopicTitre);
        if (prevModel.objet === '') prevObjet = angular.copy(ctrl.instanceCKEDITOR.getData());
      }

      // Si l'index est numérique preselectione le 1er modèle
      if (angular.isNumber(index) && (index + 1) <= ctrl.modelsEvenements.length) ctrl.currentModelEvent = ctrl.modelsEvenements[index];
      // Si l'index est un objet
      else if (angular.isObject(index) && index.hasOwnProperty('idModule')) {
        // Si il y a des model event
        if (ctrl.modelsEvenements.length) {
          // Pour chaque model
          for (var i = 0; i < ctrl.modelsEvenements.length; i++) {
            // Si le model de la boucle en cours est égale à l'id du model demandé
            if (ctrl.modelsEvenements[i].idModule === index.idModule) {
              ctrl.currentModelEvent = ctrl.modelsEvenements[i];
              break;
            }
          }
        }
      }

      console.log(ctrl.currentModelEvent);
      // Si un model est selectionné
      if (angular.isObject(ctrl.currentModelEvent)) {
        // Si il y a des model event
        if (ctrl.modelsEvenements.length) {
          // Pour chaque model
          for (var m = 0; m < ctrl.modelsEvenements.length; m++) {
            var currentModel = ctrl.modelsEvenements[m];

            // Si le model de la boucle en cours est égale au model choisi
            if (ctrl.currentModelEvent.idModule === currentModel.idModule) {

              if(!ctrl.evenement.idContenu) {
                // Si il y a des destinataires
                if (currentModel.destinataires.length) ctrl.evenement.setDestinataires(currentModel.destinataires);
                else {
                  // Si il y un model choisi avant de changer
                  if (angular.isObject(prevModel)) {
                    // Si ce model avait des destinataires prédéfinis
                    if (prevModel.destinataires.length) {
                      ctrl.evenement.setDestinataires([]);		// Supprime les destinataires
                    }
                  }
                }

                // Si il y a des destinataires
                if (currentModel.destinatairesHide.length) ctrl.evenement.setDestinatairesHide(currentModel.destinatairesHide);
                else ctrl.evenement.setDestinatairesHide([]);		// Supprime les destinataires caché

                // Si il y a des tags
                if (currentModel.tags.length) {
                  var tagOrigine = false;
                  // Si ily a des tags type
                  if (ctrl.evenement.tagsType.length) {
                    tagOrigine = TagService.getTagForType(ctrl.evenement.tagsType, COLLAB_CONF.TAG_TYPE_ORIGINE);
                  }

                  // Crée une copie des tag prédéfini du model
                  var modelTagsTmp = angular.copy(currentModel.tags);
                  // Pour chaque tag supprime le diese
                  modelTagsTmp = modelTagsTmp.map(function (libelle) {
                    return UtilsService.ltrim(libelle, '#');
                  });

                  var tagsTmp = angular.copy(ctrl.evenement.getTags());

                  if (tagsTmp.length) {

                    // Pour chaque tag de l'evenement
                    angular.forEach(tagsTmp, function (tag) {

                      var existInModel = false;
                      var tagParse = UtilsService.ltrim(tag.libelle, '#');
                      // Si le tag est dans la liste des tag autorisés par le model ?
                      if (UtilsService.contains(modelTagsTmp, tagParse) || tagParse === COLLAB_CONF.TAG_TOCTOCTOC) existInModel = true;

                      // Si pas trouvé dans le modele alors on le supprime de la liste des tags car pas autorisé
                      if (!existInModel) {
                        for (var i = 0; i < ctrl.evenement.tags.length; i++) {
                          if (UtilsService.ltrim(ctrl.evenement.tags[i].libelle, '#') === tagParse) {

                            var canDelete = true;
                            // Si il y a un tag origine qui est le meme que le tag bleu alors on ne le supprime pas !
                            if (tagOrigine) {
                              if (UtilsService.ltrim(tagOrigine.libelle, '#') === tagParse) {
                                canDelete = false;
                              }
                            }
                            if (canDelete) {
                              tagsDelete.push(ctrl.evenement.tags[i]);
                              ctrl.evenement.tags.splice(i, 1);
                            }


                          }
                        }

                      }
                    });
                  }
                }
                else {
                  // Si il y un model choisi avant de changer
                  if (angular.isObject(prevModel)) {
                    // Si ce model avait des tags prédéfinis
                    if (prevModel.tags.length) {
                      if (tagsDelete.length) {
                        for (var t = 0; t < tagsDelete.length; t++) {
                          ctrl.evenement.tags.push(tagsDelete[t]);
                        }
                        tagsDelete = [];
                      }
                    }
                  }
                }

                // Si il y a un titre
                if (currentModel.titre !== '') ctrl.formTopicTitre = currentModel.titre;
                else {
                  // Si il y un model choisi avant de changer
                  if (angular.isObject(prevModel)) {
                    // Si ce model avait un titre prédéfini
                    if (prevModel.titre !== '') {
                      // Si il y avait un titre renseigné avant de changer
                      if (prevTitre) {
                        ctrl.formTopicTitre = angular.copy(prevTitre);
                      }
                      else {
                        ctrl.formTopicTitre = '';							// Supprime le titre
                      }

                    }
                  }
                }
              }


              // Si il y a un objet
              if (currentModel.objet !== '') {

                ctrl.tmpObjet = wrappeMainCkeditor(currentModel.objet) + wrappeFichesCkeditor('');

                if (ctrl.instanceCKEDITOR.instanceReady) {
                  ctrl.instanceCKEDITOR.setData(currentModel.objet);
                }
              }
              else {
                // Si il y un model choisi avant de changer
                if (angular.isObject(prevModel)) {
                  // Si ce model avait un objet prédéfini
                  if (prevModel.objet !== '') {
                    if (prevObjet) {
                      // Supprime l'objet dans le WYSIWYG
                      ctrl.instanceCKEDITOR.setData(prevObjet, {
                        callback: function () {
                          ctrl.tmpObjet = wrappeMainCkeditor() + wrappeFichesCkeditor();
                        }
                      });
                    }
                  }
                }
              }
              break;
            }
          }
        }
      }
      // Si aucun model de renseignement selectionné
      else {

        // Si il y a un contenu save
        if (ctrl.objContenuSave) {

          if(!ctrl.evenement.idContenu) {
            ctrl.evenement.setDestinataires(ctrl.objContenuSave.destinataires);		 // Supprime les destinataires
            ctrl.evenement.setDestinatairesHide(ctrl.objContenuSave.destinatairesHide); // Supprime les destinataires caché

            // Si il y a un titre
            if (ctrl.objContenuSave.formTopicTitre) ctrl.formTopicTitre = ctrl.objContenuSave.formTopicTitre;

            if (ctrl.objContenuSave.tags.length) ctrl.evenement.setTags(ctrl.objContenuSave.tags);

            // Si ce model avait des tags prédéfinis
            if (angular.isArray(prevModel.tags) && prevModel.tags.length) {
              if (tagsDelete.length) {
                for (var j = 0; j < tagsDelete.length; j++) {
                  ctrl.evenement.tags.push(tagsDelete[j]);
                }
                tagsDelete = [];
              }
            }
          }


          // Set l'objet client dans une variable temporaire du controller
          if (ctrl.objContenuSave.objet !== '' && angular.isDefined(ctrl.objContenuSave.objet)) {
            ctrl.tmpObjet = ctrl.objContenuSave.objet;
            contentSlip = splitContent(ctrl.objContenuSave.objet);

            ctrl.instanceCKEDITOR.setData(contentSlip.mainContent);
            ctrl.instanceFichesCKEDITOR.setData(contentSlip.fichesContent);
          }
        }
        // Si aucun contenu save
        else {

          // Si il y avait un model choisi avant de changer
          if (angular.isObject(prevModel)) {
            if(!ctrl.evenement.idContenu) {
              // Si ce model avait des destinataires prédéfinis
              if (prevModel.destinataires.length) ctrl.evenement.setDestinataires([]);		// Supprime les destinataires

              if (prevModel.titre !== '') {
                if (prevTitre) ctrl.formTopicTitre = angular.copy(prevTitre);
                else ctrl.formTopicTitre = '';							// Supprime le titre
              }

              // Si ce model avait des tags prédéfinis
              if (prevModel.tags.length && tagsDelete.length) {
                for (var k = 0; k < tagsDelete.length; k++) {
                  ctrl.evenement.tags.push(tagsDelete[k]);
                }
                tagsDelete = [];
              }
            }

            if (prevModel.objet !== '' && prevObjet) {
              // Supprime l'objet dans le WYSIWYG
              ctrl.instanceCKEDITOR.setData(prevObjet, {
                callback: function () {
                  ctrl.tmpObjet = wrappeMainCkeditor() + wrappeFichesCkeditor();
                }
              });
            }
          }
          if(!ctrl.evenement.idContenu) ctrl.evenement.setDestinatairesHide([]);
          //ctrl.instanceFichesCKEDITOR.setData('');	// Supprime l'objet dans le WYSIWYG
          //vm.tmpObjet = '';									// Supprime l'objet dans la variable Angular
        }
      }
      if(!ctrl.evenement.idContenu) addDefaultDestinataires(ctrl.evenement.destinataires);

      prevModel = angular.copy(ctrl.currentModelEvent);
    }*/

    /**
     * Scinde le contenu text de l'objet en deux (contenu du bloc main et contenu du bloc fiches)
     * @param content
     * @returns {{mainContent: IAugmentedJQuery, fichesContent: IAugmentedJQuery}}
     */
    function splitContent(content) {
      if (!content) content = '';
      var $element = angular.element('<div>' + content + '</div>');
      var $mainContent = $element.find('#' + idMainContent);
      var mainContent = $mainContent.html();
      var $fichesContent = $element.find('#' + idFichesContent);
      var fichesContent = $fichesContent.html();

      if (angular.isUndefined(mainContent)) mainContent = '';
      if (angular.isUndefined(fichesContent)) fichesContent = '';

      var obj = {
        mainContent: mainContent,
        fichesContent: fichesContent
      };

      // Si pas d'element fiches
      if (!$fichesContent.length) {
        // Recupère les éventuels fiches dans le texte
        var groupes = $element.find('.blockquoteGroupeUser');

        // Si il y en a
        if (groupes.length) {
          var fiches = '';
          for (var g = 0; g < groupes.length; g++) {
            var fiche = angular.element(groupes[g]);
            fiches += fiche[0].outerHTML + '<br />';
            // Supprime l'element de l'objet
            fiche.remove();
          }
          obj.fichesContent = fiches;
        }
      }

      // Si pas d'element mainContent
      if (!$mainContent.length) obj.mainContent = $element.html();

      return obj;
    }

    /**
     * Permet de recupérer l'objet de l'event, utiliser la fonction pour séparer les fiches de l'objet et setter les variables
     */
    function initObjetAndFiches() {
      var contentObjet = splitContent(ctrl.evenement.objet);
      ctrl.objetEvenement = contentObjet.mainContent;
      if(!_.isEmpty(contentObjet.fichesContent)){
        if(_.isString(contentObjet.fichesContent)) {
          contentObjet.fichesContent = UtilsService.replaceAll('&nbsp;', '', contentObjet.fichesContent);
          contentObjet.fichesContent = UtilsService.replaceAll('<div class="blockquoteGroupeUser-content"></div>', '', contentObjet.fichesContent);
        }
        ctrl.fichesEvenement = contentObjet.fichesContent;
      }
      // Si pas de fiche, événement venant de collab V2 ?
      else{
        ctrl.fichesEvenement = null;
      }
    }

    function initC3T() {
      if ($stateParams.hasOwnProperty('action') && $stateParams.action === 'cti') {

        // Si c'est un nouveau topic ou l'ajout d'un événement depuis un topic existant mais une modification d'un evenement
        initDefaultModelAndDestinataires();

        //var Cle_Id = UtilsService.getParam('Cle_Id');
        var Num_Perso = UtilsService.getParam('Num_Perso');
        var id_c3T = UtilsService.getParam('id_c3T');
        var phone_number = UtilsService.getParam('phone_number');

        ctrl.submitProgress = true;

        //var objRetour = {};
        var params = {
          type: 'U',
          noperso: (_.isNil(Num_Perso)) ? false : Num_Perso,
          idPortefeuilles: [],
          avecemail: 0,
          groupesCollab: 0,
          usersCollab: 0,
          recherche: ''
        };

        // Récupération de la liste des portefeuilles de l'user
        if (ctrl.hasPortefeuille) {
          UserService.getPortefeuilles(true).then(function (portefeuilles) {
            // Si il y a un tag "normal" à ajouter
            if (Num_Perso === '0' && id_c3T) {
              //var tagC3T = TagService.newTag(UtilsService.parseTag(id_c3T));
              var tagC3T = TagService.genereTag(id_c3T);
              if (tagC3T) {
                var portefeuille = UtilsService.getPortefeuilleICS(portefeuilles);
                if (portefeuille) {
                  // Génère les tags "Projet" et "Portefeuille"
                  var objetsTags = TagService.createTags({
                    portefeuille: portefeuille
                  });

                  // Création de l'objet qui va permettre d'ajouter les tags
                  var retour = {
                    tag: false,						// Pas d'origine
                    tagsType: objetsTags.tagsType,	// Tags type à ajouter à l'événement
                    tags: [tagC3T]					// Tags "normal" à ajouter à lévénement
                  };

                  var tagTelephone = false;
                  if (phone_number) {
                    tagTelephone = TagService.genereTag(phone_number);
                    if (tagTelephone) retour.tags.push(tagTelephone);
                  }
                  $timeout(function () {
                    deleteOrigine().then(function () {
                      addOrigine(retour);
                      ctrl.submitProgress = false;
                    });
                  });
                }
              }
            }
            else {
              angular.forEach(portefeuilles, function (portefeuille) {
                // Ajotue à la fin du tableau
                params.idPortefeuilles.push(portefeuille.idPortefeuille);
              });

              if (params.idPortefeuilles.length) {
                AnnuaireService.searchCollaborateur(params).then(function (objectCollaborateur) {

                  if (objectCollaborateur) {

                    // Si il y a des perso ou fournisseur
                    if (objectCollaborateur.listePortefeuilles.length) {

                      // Recup le seul portefeuille qui à une perso
                      var portefeuille;
                      for (var i = 0; i < objectCollaborateur.listePortefeuilles.length; i++) {
                        if (objectCollaborateur.listePortefeuilles[i].listePersonnalite.length) {
                          portefeuille = objectCollaborateur.listePortefeuilles[i];
                        }
                      }

                      /*if(portefeuille) {
                       // Recup la seul perso trouvé
                       var perso = portefeuille.getListePersonnalite()[0];

                       var numero;
                       if(perso.isPersoFournisseur){
                       numero = perso.getNumero();
                       }
                       if(angular.isUndefined(numero) || numero==='' || !numero){
                       numero = perso.getNoperso();
                       }

                       objRetour.collaborateur = {
                       'isPerso': true,
                       'isFournisseur': false,
                       'type': 'perso',
                       'noperso': perso.getNoperso(),
                       'perso': perso,
                       'nom': perso.getNom(true),
                       'prenom': numero,
                       'source': portefeuille.getNom(true),
                       'portefeuille': {
                       'idPortefeuille': portefeuille.getIdPortefeuille(),
                       'nom': portefeuille.getNom(true)
                       }
                       };

                       if(perso.isPersoFournisseur) {
                       if (perso.getMetier()) {
                       objRetour.collaborateur.codemetier = perso.getMetier();
                       objRetour.collaborateur.isFournisseur = true;
                       }
                       }

                       // Génère les tags "Projet" et "Portefeuille"
                       var objetsTags = TagService.genereTags(objRetour);

                       // Supprime la liste des perso du portefeuille car pas besoin
                       delete portefeuille.listePersonnalite;

                       // Création de l'objet qui va permettre d'ajouter les tags et les fiches
                       var retour = {
                       fiches: {
                       client: perso		// Créé une fiche client
                       },
                       tag: TagService.genereTag(perso,COLLAB_CONF.TAG_TYPE_ORIGINE_COMPTA,perso.noperso,portefeuille),	// Création du tag Origine ICS
                       //groupes: [perso],	// Ajoute la perso dans l'array des groupes, utilisé pour créer les fiches
                       tagsType: objetsTags.tagsType,	// Tags type à ajouter à l'événement
                       tags: objetsTags.tags				// Tags "normal" à ajouter à lévénement
                       };

                       // Si il y a un tag "normal" à ajouter
                       if(id_c3T){
                       var tagC3T = TagService.newTag(UtilsService.parseTag(id_c3T));
                       if(tagC3T){
                       retour.tags.push(tagC3T);
                       }
                       }

                       $timeout(function(){
                       addOrigine(retour);
                       });
                       }*/
                      if (portefeuille) {
                        // Recup la seul perso trouvé
                        var perso = portefeuille.getListePersonnalite()[0];

                        /*
                         var numero;
                         if(perso.isPersoFournisseur){
                         numero = perso.getNumero();
                         }
                         if(angular.isUndefined(numero) || numero==='' || !numero){
                         numero = perso.getNoperso();
                         }*/


                        if (perso) {
                          // Génère les tags "Projet" et "Portefeuille"
                          var objetsTags = TagService.createTags({
                            portefeuille: portefeuille,
                            client: perso,
                            projet: 'client'
                          });

                          // Supprime la liste des perso du portefeuille car pas besoin
                          delete portefeuille.listePersonnalite;

                          // Création de l'objet qui va permettre d'ajouter les tags et les fiches
                          var retour = {
                            fiches: {
                              client: perso		// Créé une fiche client
                            },
                            tag: TagService.genereTag(perso, COLLAB_CONF.TAG_TYPE_ORIGINE_COMPTA, perso.noperso, portefeuille),	// Création du tag Origine ICS
                            //groupes: [perso],	// Ajoute la perso dans l'array des groupes, utilisé pour créer les fiches
                            tagsType: objetsTags.tagsType,	// Tags type à ajouter à l'événement
                            tags: objetsTags.tags				// Tags "normal" à ajouter à lévénement
                          };

                          // Si il y a un tag "normal" à ajouter
                          if (id_c3T) {
                            var tagC3T = TagService.genereTag(id_c3T);
                            if (tagC3T) {
                              retour.tags.push(tagC3T);
                            }
                          }

                          var tagTelephone = false;
                          if (phone_number) {
                            tagTelephone = TagService.genereTag(phone_number);
                            if (tagTelephone) {
                              retour.tags.push(tagTelephone);
                            }
                          }

                          var tagPerso = TagService.genereTag(perso);
                          if (tagPerso) {
                            retour.tags.push(tagPerso);
                          }


                          $timeout(function () {
                            deleteOrigine().then(function () {
                              addOrigine(retour);
                            });

                          });
                        }

                      }
                    }
                  }

                }, function () {
                }).finally(function () {
                  ctrl.submitProgress = false;
                });
              }
              else {
                ctrl.submitProgress = false;
              }

            }
          });
        }
      }

    }

    function initByJSON() {
      var json = UtilsService.getParam('json');
      try {
        if(_.isNil(json)) throw new Error("Il manque le paramètre \"json\"");

        var jsonVisicop =   angular.fromJson($stateParams.json),
          token =           (_.isNil(jsonVisicop.token)) ? null : jsonVisicop.token,
          docs =           (_.isNil(jsonVisicop.docs)) ? null : jsonVisicop.docs,
          idPortefeuille =  (_.isNil(jsonVisicop.idPortefeuille)) ? null : jsonVisicop.idPortefeuille,
          numImm =          (_.isNil(jsonVisicop.numImm)) ? null : jsonVisicop.numImm,
          equipement =      (_.isNil(jsonVisicop.equipement)) ? null : jsonVisicop.equipement,
          controle =        (_.isNil(jsonVisicop.controle)) ? null : jsonVisicop.controle,
          constat =        (_.isNil(jsonVisicop.probleme)) ? null : jsonVisicop.probleme,
          action =          (_.isNil(jsonVisicop.action)) ? null : jsonVisicop.action,
          text =            (_.isNil(jsonVisicop.text)) ? null : jsonVisicop.text;

        ctrl.listDocs = docs;
        if(_.isNil(token))          throw new Error("Il manque le paramètre \"token\" dans le JSON !");
        if(_.isNil(idPortefeuille)) throw new Error("Il manque le paramètre \"idPortefeuille\" dans le JSON !");
        if(_.isNil(numImm))         throw new Error("Il manque le paramètre \"numImm\" dans le JSON !");

        // Récupération de la liste des portefeuilles de l'user
        if (ctrl.hasPortefeuille) {

          ctrl.submitProgress = true;

          UtilsService.getPortefeuille(idPortefeuille)
            .then(function(portefeuille){

              AnnuaireService
                .searchImmeubleByNum(numImm, portefeuille.getCle())
                .then(function (ret) {
                  if (ret.immeuble) {
                    var objetCollaborateur = AnnuaireService.genereCollaborateur(ret.immeuble, ret.portefeuille, null, null, false);
                    var objAnnuaire = UtilsService.genereObjetAnnuaire({
                      origine: objetCollaborateur.collaborateur.immeuble,
                      collaborateur: objetCollaborateur.collaborateur
                    });

                    var objetsTags = TagsService.genereTagsAndFiches(objAnnuaire.objAnnuaire);

                    if($stateParams.action === 'addEventFromVisicop') {

                      var objVisicop = {};
                      if(!_.isNil(equipement))  objVisicop.equipement = equipement;
                      if(!_.isNil(controle))    objVisicop.controle = controle;
                      if(!_.isNil(constat))     objVisicop.constat = constat;
                      if(!_.isNil(action))      objVisicop.action = action;
                      if(!_.isNil(text))        objVisicop.text = text;


                      // Génère un tag non typé #RapportVisite
                      var tagRapportVisite = TagsService.genereTagRapportVisite();
                      if(!_.isNil(tagRapportVisite)) objetsTags.addTag.tags.push(tagRapportVisite);

                      // Génère un tag typé VisicopAction avec comme libelle #RapportVisite et valeurICS l'idA (id de l'action dans visicop)
                      var tagTypeVisicop = null;
                      if(_.isObject(action) && !_.isNil(action.idA)) tagTypeVisicop = TagsService.genereTagTypeVisicopRefLiaisonECCA(null, action.idA, ret.portefeuille);
                      else tagTypeVisicop = TagsService.genereTagTypeVisicopRefLiaisonECCA(null, null, ret.portefeuille);

                      if(!_.isNil(tagTypeVisicop)) objetsTags.addTag.tagsType.push(tagTypeVisicop);


                      if(_.isObject(objetsTags.addTag) && _.isObject(objetsTags.addTag.fiches) && _.isObject(objetsTags.addTag.fiches.immeuble)) {
                        //objetsTags.addTag.fiches.immeuble.infosVisicop = objVisicop;
                        objetsTags.addTag.fiches.visicop = objVisicop;
                      }

                      /*
                      var tagVisicop = null;
                      if(!_.isNil(idA)) tagVisicop = TagsService.genereTagVisicop(idA, ret.portefeuille);
                      else tagVisicop = TagsService.genereTagVisicop();

                      if(!_.isNil(tagVisicop)) objetsTags.addTag.tags.push(tagVisicop);
                      */

                      /*
                      if(!_.isNil(idA)) {
                        var tagIdA = TagsService.genereTag(idA);
                        if(!_.isNil(tagIdA)) objetsTags.addTag.tags.push(tagIdA);
                      }*/
                    }
                    //console.log(objetsTags);



                    addOrigine(objetsTags.addTag, true);

                    /*
                    if(!_.isNil(text)) {
                      ctrl.instanceCKEDITOR.setData(text, {
                        callback: function () {
                          ctrl.tmpObjet = wrappeMainCkeditor(text) + wrappeFichesCkeditor();
                        }
                      });
                    }*/
                  }
                })
                .catch(function (msg) {
                  console.error(msg);
                  ctrl.submitProgress = false;
                });

            })
            .catch(function(msg){
              ModalsService.alertErreur(msg + " id: " + idPortefeuille);
              ctrl.submitProgress = false;
            });
        }
      }
      catch(err) {
        ModalsService.alertErreur(err.message);
      }
    }

    /**
     * Recupère la valeur du WYSIWYG du main content avec une balise main autour
     * @param val
     * @returns {string}
     */
    function wrappeMainCkeditor(val, append) {
      if (!val) val = ctrl.instanceCKEDITOR.getData();
      if(append) val += append;
      return '<div id="' + idMainContent + '">' + val + '</div>';
    }

    /**
     * Recupère la valeur du WYSIWYG des fiches avec une balise fiches autour
     * @param val
     * @returns {string}
     */
    function wrappeFichesCkeditor(val) {
      if (!val) val = ctrl.instanceFichesCKEDITOR.getData();
      // Si ouvert en chargeant un origine à l'arrivé, ne pas sauvegarder les fiches
      if ($state.params.loadOrigine) val = '';
      return '<div id="' + idFichesContent + '">' + val + '</div>';
    }

    /**
     * Permer de save dans le local storage le form actuel
     */
    function saveLocalStorage() {
      AutoSaveService.save(getObjContenu(), ctrl.evenement.getIdContenu());	// Save le contenu
    }



    //----------- Origine -------------------
    function addOrigine(objParams, noSaveInLocalStorage) {
      ctrl.submitProgress = true;
      if (objParams.hasOwnProperty('tag') && objParams.tag) ctrl.evenement.pushTag(objParams.tag, objParams.tag.typeTag.libelle, 1);

      // Ajoute les fiches, save dans le local storage dans le listener "afterExecCommand"
      asyncAddFiches(objParams).then(function () {
        ctrl.submitProgress = false;
        if (!noSaveInLocalStorage) saveLocalStorage();

        ctrl.instanceCKEDITOR.focus();
        var range = ctrl.instanceCKEDITOR.createRange();
        range.moveToElementEditEnd( range.root );
        ctrl.instanceCKEDITOR.getSelection().selectRanges( [ range ] );
      });
    }

    /**
     * Fonction de suppression de groupes dans le WYSIWYG executée par la directive "destinataires" lors de l'ajout d'un groupe concerne
     * @param noSaveInLocalStorage
     */
    function deleteOrigine(noSaveInLocalStorage) {

      var deferred = $q.defer();
      var last = false;
      var editor = ctrl.instanceFichesCKEDITOR;

      //initInfosFromTagsType();

      // Recupération des fiches
      var objBlockquoteGroupes = editor.document.find('.blockquoteGroupeUser');
      //var widgets = editor.widgets.checkWidgets();

      if (objBlockquoteGroupes.count()) {
        try {
          // Si il y a juste un element et que c'est surement lui qu'on supprime alors c'est le dernier
          if (objBlockquoteGroupes.count() === 1) last = true;

          // Pour chaque blockquote
          for (var i2 = 0; i2 < objBlockquoteGroupes.count(); i2++) {
            var blockquote2 = objBlockquoteGroupes.getItem(i2);
            var widget2 = editor.widgets.getByElement(blockquote2);
            if (widget2) {
              // Destroy le widget
              widget2.destroy();
              // Supprime le blockquote (la fiche) du groupe
              blockquote2.remove();
            }
          }
        }
        catch (err) {
          $log.log('[FormulaireEvenement.deleteOrigine] Erreur : ' + err.message);
        }
      }

      updateDestinatairesFromGroupeAssociation();

      // Recup le tag origine
      var tagOrigine = ctrl.evenement.getTagForType('Origine');
      // Si il y a bien un tag origine et si il y a des tags
      if (tagOrigine && ctrl.evenement.tags.length) {
        // Parcour ses tags
        for (var t = 0; t < ctrl.evenement.tags.length; t++) {
          // Si le libelle du tag de la boucle et exactement égale au libelle tu tag origine alors on le supprime
          if (ctrl.evenement.tags[t].libelle === tagOrigine.libelle) ctrl.evenement.tags.splice(t, 1);
        }
      }

      ctrl.evenement.tagsType = [];
      if (!noSaveInLocalStorage) saveLocalStorage();

      var body = editor.document.getBody();	// Recupère le body du document
      var p = body.getElementsByTag('p');		// Recupère les tag "p"
      var nbP = p.count();					// Nb de "p" total
      var reste = nbP;						// Nb de "p" restant

      var arrayForDelete = [];				// Array d'element à delete
      if (nbP) {
        // Pour chaque element "p"
        for (var l = 0; l < nbP; l++) {
          // Recup l'élement
          var elemP = p.getItem(l);
          // Si le contenu est un <br>
          if (elemP.$.innerHTML === '<br>') {
            // Si l'element n'est pas le dernier (on garde un <p>&nbsp;</p> à la fin)
            if (l !== (nbP - 1)) {
              // Ajout dans le tableay à delete
              arrayForDelete.push(elemP);
            }
          }
        }
      }

      // Si il y a des element à delete
      if (arrayForDelete.length) {
        // Parcour ce tableau
        for (var k = 0; k < arrayForDelete.length; k++) {
          // Supprime l'element
          arrayForDelete[k].remove();
          // Decremente le reste de "p"
          reste--;
        }
      }
      arrayForDelete = null;

      // Si on a supprimé le dernier blockquote et qu'il reste une balie <p>&nbsp;</p>
      if (reste === 1 && last) {
        //console.log('clean !');
        ctrl.instanceFichesCKEDITOR.setData('', {
          callback: function () {
            ctrl.tmpObjet = wrappeMainCkeditor() + wrappeFichesCkeditor();
            deferred.resolve();
          }
        });
      }
      else deferred.resolve();

      return deferred.promise;
    }

    /**
     * Fonction qui genere le HTML pour une fiche de groupe collab
     * @param groupe
     * @returns {{idGroupe: boolean, title: string, content: string}}
     */
    function getFicheObj(groupe) {
      if (!groupe.isModel) groupe = new Groupe(groupe);

      var retour = false,
        ficheCollab;

      if (groupe.isUser()) ficheCollab = groupe.getUser().getFichePerso();

      if (ficheCollab) {

        retour = {
          idGroupe: false,
          title: '',
          content: ''
        };

        retour.idGroupe = groupe.idGroupe;
        retour.title = '<span class="fiche-libelle"><i class="' + groupe.getIcon() + '"></i> </span>';

        if (groupe.isUser()) retour.title += '<span class="fiche-infos-nom">'+ficheCollab.getNomPrenom()+'</span>';
        else retour.title += '<span class="fiche-infos-nom">'+ficheCollab.getDefaultNom()+'</span>';

        retour.content += '<table class="table table-condensed">';
        retour.content += '<tr>';
        retour.content += '<th colspan="2">' + groupe.getDefaultNom() + '</th>';
        retour.content += '</tr>';

        if (ficheCollab.isFiche) {
          var informations = ficheCollab.getInformations();
          if (informations.length) {
            for (var j = 0; j < informations.length; j++) {
              var renseignement = informations[j];
              retour.content += '<tr>';
              retour.content += '<th>' + renseignement.getReference().getType() + '</th>';
              retour.content += '<td>' + renseignement.getDonnee() + '</td>';
              retour.content += '</tr>';
            }
          }
        }
        retour.content += '</table>';
        retour.fiche = 'groupe';
        retour.ordreFiche = COLLAB_CONF.ORDRE_FICHE_GROUPE;
      }
      return retour;
    }

    /**
     * Fonction qui genere le HTML pour une perso ICS
     * @param originPerso
     */
    /*
    function getInfosPerso(originPerso, typeFiche) {

        var deferred = $q.defer();
        var perso = originPerso;

        if (typeFiche === 'bail') perso = originPerso.getPersonnalite();
        if (!perso.isModel) perso = new Personnalite(perso.perso);
        var retour = {
            title: '',
            content: ''
        };

        var libelleTypePerso = '',
            ordreFiche = false;
        switch (typeFiche) {
            case 'salarie':

                libelleTypePerso = 'Salarie : ';
                if (COLLAB_CONF.LIBELLE_SALARIE_BY_NIVEAU.hasOwnProperty(originPerso.niveau)) {
                    libelleTypePerso = COLLAB_CONF.LIBELLE_SALARIE_BY_NIVEAU[originPerso.niveau] + ' : ';
                }
                ordreFiche = COLLAB_CONF.ORDRE_FICHE_BAIL;
                break;
            case 'bail':
                libelleTypePerso = 'Locataire : ';
                ordreFiche = COLLAB_CONF.ORDRE_FICHE_BAIL;
                break;
            case 'locataire':
                libelleTypePerso = 'Locataire : ';
                ordreFiche = COLLAB_CONF.ORDRE_FICHE_LOCATAIRE;
                break;
            case 'colocataire':
                libelleTypePerso = 'Colocataire : ';
                ordreFiche = COLLAB_CONF.ORDRE_FICHE_LOCATAIRE;
                break;
            case 'coproprietaire':
                libelleTypePerso = 'Copropriétaire : ';
                ordreFiche = COLLAB_CONF.ORDRE_FICHE_COPROPRIETAIRE;
                break;
            case 'proprietaire':
                libelleTypePerso = 'Propriétaire : ';
                ordreFiche = COLLAB_CONF.ORDRE_FICHE_PROPRIETAIRE;
                break;
            case 'fournisseur':
                libelleTypePerso = 'Fournisseur : ';
                ordreFiche = COLLAB_CONF.ORDRE_FICHE_FOURNISSEUR;
                break;

            default:
                libelleTypePerso = '';
                ordreFiche = COLLAB_CONF.ORDRE_FICHE_LOCATAIRE;
        }

        retour.title = '<i class="fa fa-user"></i> ' + libelleTypePerso + perso.getNom();

        var templatepath = 'app/ics/personnalite-collab.template.html';
        if (UtilsService.isICS()) templatepath = 'app/ics/personnalite.template.html';

        // Recupère le template pour les infos perso
        var templateUrl = $sce.getTrustedResourceUrl(templatepath);
        $templateRequest(templateUrl).then(function (template) {

            var modalScope = $scope.$new(false);

            // Création de la variable "templateModel" dans le $scope pour le template qui l'utilise
            modalScope.templateModel = perso;

            var templateCompiled = $compile(template)(modalScope);	// Compile le template avec le $scope

            // Permet de s'assurer que les variables ont été remplacé par les valeur
            $timeout(function () {
                var txt = UtilsService.cleanHtml(templateCompiled[0].outerHTML);
                retour.content = txt;
                retour.fiche = typeFiche;
                retour.ordreFiche = ordreFiche;
                deferred.resolve(retour);
            });
        });

        return deferred.promise;
    }*/
    function getInfosPerso(originPerso, typeFiche) {

      var deferred = $q.defer();
      var perso = originPerso;

      if (typeFiche === 'bail') perso = originPerso.getPersonnalite();
      if (!perso.isModel) perso = new Personnalite(perso.perso);
      var retour = {
        title: '',
        content: ''
      };

      var libelleTypePerso = '',
        ordreFiche = false;
      switch (typeFiche) {
        case 'salarie':

          libelleTypePerso = 'Salarie :';
          if (COLLAB_CONF.LIBELLE_SALARIE_BY_NIVEAU.hasOwnProperty(originPerso.niveau)) {
            libelleTypePerso = COLLAB_CONF.LIBELLE_SALARIE_BY_NIVEAU[originPerso.niveau] + ' : ';
          }
          ordreFiche = COLLAB_CONF.ORDRE_FICHE_BAIL;
          break;
        case 'bail':
          libelleTypePerso = 'Locataire :';
          ordreFiche = COLLAB_CONF.ORDRE_FICHE_BAIL;
          break;
        case 'locataire':
          libelleTypePerso = 'Locataire :';
          ordreFiche = COLLAB_CONF.ORDRE_FICHE_LOCATAIRE;
          break;
        case 'colocataire':
          libelleTypePerso = 'Colocataire :';
          ordreFiche = COLLAB_CONF.ORDRE_FICHE_LOCATAIRE;
          break;
        case 'coproprietaire':
          libelleTypePerso = 'Copropriétaire :';
          ordreFiche = COLLAB_CONF.ORDRE_FICHE_COPROPRIETAIRE;
          break;
        case 'proprietaire':
          libelleTypePerso = 'Propriétaire :';
          ordreFiche = COLLAB_CONF.ORDRE_FICHE_PROPRIETAIRE;
          break;
        case 'fournisseur':
          libelleTypePerso = 'Fournisseur :';
          ordreFiche = COLLAB_CONF.ORDRE_FICHE_FOURNISSEUR;
          break;

        default:
          libelleTypePerso = '';
          ordreFiche = COLLAB_CONF.ORDRE_FICHE_LOCATAIRE;
      }

      var ficheType = angular.copy(typeFiche);
      if(_.isEmpty(ficheType)) ficheType = 'default';

      retour.title = '<span class="fiche-libelle"><i class="fa fa-user"></i> ' + libelleTypePerso + '</span> <span class="fiche-infos-nom">' +perso.getNom() + '</span>';

      if (UtilsService.isICS()) {
        var templatepath = 'app/ics/personnalite.template.html';

        // Recupère le template pour les infos perso
        var templateUrl = $sce.getTrustedResourceUrl(templatepath);
        $templateRequest(templateUrl).then(function (template) {

          var modalScope = $scope.$new(false);

          // Création de la variable "templateModel" dans le $scope pour le template qui l'utilise
          modalScope.templateModel = perso;

          var templateCompiled = $compile(template)(modalScope);	// Compile le template avec le $scope

          // Permet de s'assurer que les variables ont été remplacé par les valeur
          $timeout(function () {
            var txt = UtilsService.cleanHtml(templateCompiled[0].outerHTML);
            retour.content = txt;
            retour.fiche = typeFiche;
            retour.ordreFiche = ordreFiche;
            deferred.resolve(retour);
          });
        });
      }
      else {
        var infosComplementaires = [];

        if(_.isArray(perso.getTelephones()) && perso.getTelephones().length) {
          for(var t = 0; t < perso.getTelephones().length; t++){
            var tel = perso.getTelephones()[t];
            infosComplementaires.push('<a href="tel:' + UtilsService.cleanPhoneNumber(tel) + '">' + tel + '</a>');
          }
        }

        if(_.isArray(perso.getTelephonesFixe()) && perso.getTelephonesFixe().length) {
          for(var tFixe = 0; tFixe < perso.getTelephonesFixe().length; tFixe++){
            var tel = perso.getTelephonesFixe()[tFixe];
            infosComplementaires.push('<a href="tel:' + UtilsService.cleanPhoneNumber(tel) + '">' + tel + '</a>');
          }
        }

        if(_.isArray(perso.getTelephonesMobile()) && perso.getTelephonesMobile().length) {
          for(var tMob = 0; tMob < perso.getTelephonesMobile().length; tMob++){
            var tel = perso.getTelephonesMobile()[tMob];
            infosComplementaires.push('<a href="tel:' + UtilsService.cleanPhoneNumber(tel) + '">' + tel + '</a>');
          }
        }

        if(_.isArray(perso.getMails()) && perso.getMails().length) {
          for(var m = 0; m < perso.getMails().length; m++){
            var mail = perso.getMails()[m];
            infosComplementaires.push('<a href="mailto:' + mail + '">' + mail + '</a>');
          }
        }

        if(infosComplementaires.length) {
          retour.title += ' <span class="fiche-infos-complementaires">(' + infosComplementaires.join(", ") + ')</span>';
        }
        retour.content = null;
        retour.fiche = typeFiche;
        retour.ordreFiche = ordreFiche;
        deferred.resolve(retour);
      }

      return deferred.promise;
    }

    /**
     * Fonction qui genere le HTML pour un immeuble ICS
     * @param immeuble
     */
    function getFichesImmeuble(immeuble) {
      var deferred = $q.defer();
      var retour = {
        title: '',
        content: ''
      };

      var libelleLieuIntervention = 'Lieu Intervention :';
      if (UtilsService.isICS()) libelleLieuIntervention = 'Immeuble :';

      retour.title = '<span class="fiche-libelle"><i class="fa fa-building"></i> '+libelleLieuIntervention+' </span> <span class="fiche-infos-nom">' + immeuble.getNom()+'</span>';

      if (UtilsService.isICS()) {

        // Recupère le template pour les infos perso
        var templateUrl = $sce.getTrustedResourceUrl('app/ics/immeuble-ics.template.html');
        $templateRequest(templateUrl).then(function (template) {

          var modalScope = $scope.$new(false);

          // Création de la variable "templateModel" dans le $scope pour le template qui l'utilise
          modalScope.templateModel = immeuble;

          var templateCompiled = $compile(template)(modalScope);	// Compile le template avec le $scope

          // Permet de s'assurer que les variables ont été remplacé par les valeur
          $timeout(function () {
            var txt = UtilsService.cleanHtml(templateCompiled[0].outerHTML);
            retour.content = txt;
            retour.fiche = 'immeuble';
            retour.ordreFiche = COLLAB_CONF.ORDRE_FICHE_IMMEUBLE;
            deferred.resolve(retour);
          });
        });
      }
      else {
        var txt = '';
        var content = null;
        var adresse = immeuble.getAdresse();
        if(!_.isEmpty(adresse)) {
          txt += '(<span class="fiche-infos-adresse"><a href="' + COLLAB_CONF.URL_LOCATE_ADRESSE + adresse + '" target="_blank">' + adresse + '</a></span>)';
        }

        var digicodes = immeuble.getDigicodes();
        if(_.isArray(digicodes) && digicodes.length) {
          txt += '<span class="fiche-infos-digicode">';
          for(var d = 0; d < digicodes.length; d++) {
            var digicode = digicodes[d];
            if(!_.isEmpty(digicode)) {
              txt += '<div class="ligne_digicode">Digicode: ' + digicode.getDigicode() + '; Bat: ' + digicode.getNumBatiment() + '; Esc: ' + digicode.getNumEscalier() + ' - ' + digicode.getAd1() + ' ' + digicode.getAd2() + '</div>';
            }
          }
          txt += '</span>';
        }

        if(!_.isEmpty(txt)) {
          //retour.title += txt.slice(0, -2);
          retour.title += ' <span class="fiche-infos-complementaires">'+txt+'</span>';
        }


        /*
        if(_.isObject(immeuble.infosVisicop)){
          var contentVisicop = '';
          var infosVisicop = immeuble.infosVisicop;
          if(_.isObject(infosVisicop.equipement)){
            var equipement = infosVisicop.equipement;
            if(!_.isNil(equipement.libelle) && !_.isEmpty(equipement.libelle)) {
              if(!_.isNil(equipement.idE)) contentVisicop += '<span data-idequipement="'+equipement.idE+'">';
              else contentVisicop += '<span>';
              contentVisicop += equipement.libelle + '</span>';
            }
          }
          if(_.isObject(infosVisicop.controle)){
            if(!_.isEmpty(contentVisicop)) contentVisicop += ' <i class="fa fa-arrow-right"></i> ';
            var controle = infosVisicop.controle;
            if(!_.isNil(controle.libelle) && !_.isEmpty(controle.libelle)) {
              if(!_.isNil(controle.idC)) contentVisicop += '<span data-idcontrole="'+controle.idC+'">';
              else contentVisicop += '<span>';
              contentVisicop += controle.libelle + '</span>';
            }
          }
          if(_.isObject(infosVisicop.constat)){
            if(!_.isEmpty(contentVisicop)) contentVisicop += ' <i class="fa fa-arrow-right"></i> ';
            var constat = infosVisicop.constat;
            if(!_.isNil(constat.libelle) && !_.isEmpty(constat.libelle)) {
              if(!_.isNil(constat.idP)) contentVisicop += '<span data-idprobleme="'+constat.idP+'">';
              else contentVisicop += '<span>';
              contentVisicop += constat.libelle + '</span>';
            }
          }
          if(_.isObject(infosVisicop.action)){
            if(!_.isEmpty(contentVisicop)) contentVisicop += ' <i class="fa fa-arrow-right"></i> ';
            var action = infosVisicop.action;
            if(!_.isNil(action.libelle) && !_.isEmpty(action.libelle)) {
              if(!_.isNil(action.idA)) contentVisicop += '<span data-idaction="'+action.idA+'">';
              else contentVisicop += '<span>';
              contentVisicop += action.libelle;
              if(!_.isNil(action.txt) && !_.isEmpty(action.txt)) contentVisicop += ' - ' + action.txt;
              contentVisicop += '</span>';
            }
          }

          if(!_.isNil(infosVisicop.text) && !_.isEmpty(infosVisicop.text)) contentVisicop += "<div>"+UtilsService.nl2br(infosVisicop.text)+"</div>";

          if(!_.isEmpty(contentVisicop)) content = contentVisicop;

        }*/

        retour.content = content;
        retour.fiche = 'immeuble';
        retour.ordreFiche = COLLAB_CONF.ORDRE_FICHE_IMMEUBLE;
        deferred.resolve(retour);
      }

      return deferred.promise;
    }

    function getInfosClientCapfun(originPerso, typeFiche){

      var deferred = $q.defer();
      var retour = {
        title: '',
        content: ''
      };

      retour.title = '<span class="fiche-libelle"><i class="fa fa-user-circle-o"></i> Client :</span> <span class="fiche-infos-nom">' + originPerso.nom + '</span>';

      var coordonnees = [];

      if(_.isArray(originPerso.telephones) && originPerso.telephones.length) {
        for(var t = 0; t < originPerso.telephones.length; t++){
          var tel = originPerso.telephones[t];
          coordonnees.push('<a href="tel:' + UtilsService.cleanPhoneNumber(tel) + '">' + tel + '</a>');
        }
      }

      if(coordonnees.length) retour.title += ' <span class="fiche-infos-complementaires">(' + coordonnees.join(", ") + ')</span>';

      retour.content = null;
      retour.fiche = typeFiche;
      retour.ordreFiche = 1;
      deferred.resolve(retour);

      return deferred.promise;
    }

    function getInfosLotMulticamp(originPerso, typeFiche) {
      var deferred = $q.defer();
      var retour = {
        title: '',
        content: ''
      };

      var lotMulticamp = originPerso.lotMulticamp;
      if(!lotMulticamp.isModel) {
        var LotMulticamp = $injector.get('LotMulticamp');
        lotMulticamp = new LotMulticamp(lotMulticamp);
        LotMulticamp = null;
      }

      retour.title = '<span class="fiche-libelle"><i class="fa fa-home"></i> Lot :</span> <span class="fiche-infos-nom">' + lotMulticamp.getPlace_name() + '</span>';

      // Recupère le template pour les infos perso
      var templateUrl = $sce.getTrustedResourceUrl('app/capfun/lot-multicamp.template.html');
      $templateRequest(templateUrl).then(function (template) {

        var modalScope = $scope.$new(false);

        // Création de la variable "templateModel" dans le $scope pour le template qui l'utilise
        modalScope.templateModel = lotMulticamp;

        var templateCompiled = $compile(template)(modalScope);	// Compile le template avec le $scope

        // Permet de s'assurer que les variables ont été remplacé par les valeur
        $timeout(function () {
          var txt = UtilsService.cleanHtml(templateCompiled[0].outerHTML);
          retour.content = txt;
          retour.fiche = typeFiche;
          retour.ordreFiche = 2;
          deferred.resolve(retour);
        });
      });
      /*
      var txt = '';
      if(!_.isEmpty(txt)) retour.title += ' (' + txt.slice(0, -2) + ')';

      retour.content = null;
      retour.fiche = typeFiche;
      retour.ordreFiche = 1;
      deferred.resolve(retour);
      */

      return deferred.promise;
    }

    function getInfosLieuCommunCapfun(originPerso, typeFiche) {
      var deferred = $q.defer();
      var retour = {
        title: '',
        content: ''
      };

      var lieuCommunCapfun = originPerso.lieuCommunCapfun;
      if(!lieuCommunCapfun.isModel) {
        var LieuCommunCapfun = $injector.get('LieuCommunCapfun');
        lieuCommunCapfun = new LieuCommunCapfun(lieuCommunCapfun);
        LieuCommunCapfun = null;
      }

      retour.title = '<span class="fiche-libelle"><i class="fa fa-home"></i> Lieu :</span> <span class="fiche-infos-nom">' + lieuCommunCapfun.getLieu() + '</span>';

      // Recupère le template pour les infos perso
      var templateUrl = $sce.getTrustedResourceUrl('app/capfun/lieu-commun-capfun.template.html');
      $templateRequest(templateUrl).then(function (template) {

        var modalScope = $scope.$new(false);

        // Création de la variable "templateModel" dans le $scope pour le template qui l'utilise
        modalScope.templateModel = lieuCommunCapfun;

        var templateCompiled = $compile(template)(modalScope);	// Compile le template avec le $scope

        // Permet de s'assurer que les variables ont été remplacé par les valeur
        $timeout(function () {
          var txt = UtilsService.cleanHtml(templateCompiled[0].outerHTML);
          retour.content = txt;
          retour.fiche = typeFiche;
          retour.ordreFiche = 2;
          deferred.resolve(retour);
        });
      });
      /*
      var txt = '';
      if(!_.isEmpty(txt)) retour.title += ' (' + txt.slice(0, -2) + ')';

      retour.content = null;
      retour.fiche = typeFiche;
      retour.ordreFiche = 1;
      deferred.resolve(retour);
      */

      return deferred.promise;
    }

    /**
     * Fonction qui genere le HTML pour une fiche Visicop
     * @param immeuble
     */
    function getFicheVisicop(infosVisicop) {
      var deferred = $q.defer();
      var retour = {
        title: null,
        content: ''
      };

      var content = null;

      if(_.isObject(infosVisicop)){
        var contentVisicop = '';
        if(_.isObject(infosVisicop.equipement)){
          var equipement = infosVisicop.equipement;
          if(!_.isNil(equipement.libelle) && !_.isEmpty(equipement.libelle)) {
            if(!_.isNil(equipement.idE)) contentVisicop += '<span class="fiche-visicop-infos-equipement" data-idequipement="'+equipement.idE+'">';
            else contentVisicop += '<span class="fiche-visicop-infos-equipement">';
            contentVisicop += equipement.libelle + '</span>';
          }
        }
        if(_.isObject(infosVisicop.controle)){
          if(!_.isEmpty(contentVisicop)) contentVisicop += ' <i class="fa fa-arrow-right"></i> ';
          var controle = infosVisicop.controle;
          if(!_.isNil(controle.libelle) && !_.isEmpty(controle.libelle)) {
            if(!_.isNil(controle.idC)) contentVisicop += '<span class="fiche-visicop-infos-controle" data-idcontrole="'+controle.idC+'">';
            else contentVisicop += '<span class="fiche-visicop-infos-controle">';
            contentVisicop += controle.libelle + '</span>';
          }
        }
        if(_.isObject(infosVisicop.constat)){
          if(!_.isEmpty(contentVisicop)) contentVisicop += ' <i class="fa fa-arrow-right"></i> ';
          var constat = infosVisicop.constat;
          if(!_.isNil(constat.libelle) && !_.isEmpty(constat.libelle)) {
            if(!_.isNil(constat.idP)) contentVisicop += '<span class="fiche-visicop-infos-probleme" data-idprobleme="'+constat.idP+'">';
            else contentVisicop += '<span class="fiche-visicop-infos-probleme">';
            contentVisicop += constat.libelle + '</span>';
          }
        }
        if(_.isObject(infosVisicop.action)){
          if(!_.isEmpty(contentVisicop)) contentVisicop += ' <i class="fa fa-arrow-right"></i> ';
          var action = infosVisicop.action;
          if(!_.isNil(action.libelle) && !_.isEmpty(action.libelle)) {
            if(!_.isNil(action.idA)) contentVisicop += '<span class="fiche-visicop-infos-action" data-idaction="'+action.idA+'">';
            else contentVisicop += '<span class="fiche-visicop-infos-action">';
            contentVisicop += action.libelle;
            if(!_.isNil(action.txt) && !_.isEmpty(action.txt)) contentVisicop += ' - ' + action.txt;
            contentVisicop += '</span>';
          }
        }

        if(!_.isNil(infosVisicop.text) && !_.isEmpty(infosVisicop.text)) contentVisicop += "<div class='fiche-visicop-infos-text'>"+UtilsService.nl2br(infosVisicop.text)+"</div>";

        if(!_.isEmpty(contentVisicop)) content = contentVisicop;
      }

      retour.content = content;
      retour.fiche = 'visicop';
      retour.ordreFiche = COLLAB_CONF.ORDRE_FICHE_VISICOP;
      deferred.resolve(retour);

      return deferred.promise;
    }

    /*
    function getFichesImmeuble(immeuble) {

        var deferred = $q.defer();
        var retour = {
            title: '<i class="fa fa-building"></i> Lieu Intervention: ' + immeuble.getNom(),
            content: ''
        };

        var templatepath = 'app/ics/immeuble-collab.template.html';

        if (UtilsService.isICS()) {
            retour.title = '<i class="fa fa-building"></i> Immeuble : ' + immeuble.getNom();
            templatepath = 'app/ics/immeuble-ics.template.html';
        }


        // Recupère le template pour les infos perso
        var templateUrl = $sce.getTrustedResourceUrl(templatepath);
        $templateRequest(templateUrl).then(function (template) {

            var modalScope = $scope.$new(false);

            // Création de la variable "templateModel" dans le $scope pour le template qui l'utilise
            modalScope.templateModel = immeuble;

            var templateCompiled = $compile(template)(modalScope);	// Compile le template avec le $scope

            // Permet de s'assurer que les variables ont été remplacé par les valeur
            $timeout(function () {
                var txt = UtilsService.cleanHtml(templateCompiled[0].outerHTML);
                retour.content = txt;
                retour.fiche = 'immeuble';
                retour.ordreFiche = COLLAB_CONF.ORDRE_FICHE_IMMEUBLE;
                deferred.resolve(retour);
            });
        });


        return deferred.promise;
    }*/

    /**
     * Permet d'ajouter les fiches
     * @param obj
     * @returns {Promise}
     */
    function asyncAddFiches(obj) {

      var globalDeferred = $q.defer();
      var idPortefeuille = null;
      var editor = ctrl.instanceFichesCKEDITOR;

      if (obj.hasOwnProperty('tagsType')) {
        for (var t = 0; t < obj.tagsType.length; t++) {
          ctrl.evenement.pushTagsType(obj.tagsType[t]);
        }
        var tagPortefeuille = TagsService.getTagTypePortefeuille(obj.tagsType);
        if (tagPortefeuille) idPortefeuille = tagPortefeuille.getValeurIcs();
        //updateDestinatairesFromGroupeAssociation(obj.tagsType);
      }

      // Ajout des tags si il y en a ajouter
      if (obj.hasOwnProperty('tags')) {
        for (var u = 0; u < obj.tags.length; u++) {
          if(!ctrl.evenement.hasTag(obj.tags[u].libelle)) {

            // Si le tag qui doit etre ajouté est extranet
            if(obj.tags[u].libelle === '#'+COLLAB_CONF.TAG_EXTRANET) {
              // Si il y a déjà le tag toctoctoc dans l'événement on le retire
              if(ctrl.evenement.hasTag('#'+COLLAB_CONF.TAG_TOCTOCTOC)) ctrl.evenement.removeTag('#'+COLLAB_CONF.TAG_TOCTOCTOC);
              if(ctrl.evenement.hasTag('#'+COLLAB_CONF.TAG_RAPPORT_VISITE)) ctrl.evenement.removeTag('#'+COLLAB_CONF.TAG_RAPPORT_VISITE);
            }
            // Si ajout du tag toctoctoc
            else if(obj.tags[u].libelle === '#'+COLLAB_CONF.TAG_TOCTOCTOC) {
              if(ctrl.evenement.hasTag('#'+COLLAB_CONF.TAG_EXTRANET)) ctrl.evenement.removeTag('#'+COLLAB_CONF.TAG_EXTRANET);
              if(ctrl.evenement.hasTag('#'+COLLAB_CONF.TAG_RAPPORT_VISITE)) ctrl.evenement.removeTag('#'+COLLAB_CONF.TAG_RAPPORT_VISITE);
            }
            // Si ajout du tag visicop
            else if(obj.tags[u].libelle === '#'+COLLAB_CONF.TAG_RAPPORT_VISITE) {
              if(ctrl.evenement.hasTag('#'+COLLAB_CONF.TAG_TOCTOCTOC)) ctrl.evenement.removeTag('#'+COLLAB_CONF.TAG_TOCTOCTOC);
              if(ctrl.evenement.hasTag('#'+COLLAB_CONF.TAG_EXTRANET)) ctrl.evenement.removeTag('#'+COLLAB_CONF.TAG_EXTRANET);
            }
            ctrl.evenement.pushTags(obj.tags[u]);
          }

          //var exist = UtilsService.where(ctrl.evenement.getTags(), {libelle: obj.tags[u].libelle});
          //if (!exist.length) ctrl.evenement.pushTags(obj.tags[u]);
        }
      }

      var objRetourHTML,
        fichesExist = {};

      // Recupération des fiches pour tester l'existance de celle que l'on veux ajouter. (inutile d'en avoir deux identique)
      var objBlockquoteGroupes = editor.document.find('.blockquoteGroupeUser');

      // Si il y a déjà des fiches
      // Si il y a déjà des fiches
      if (objBlockquoteGroupes.count()) {
        // Pour chaque fiche
        for (var i = 0; i < objBlockquoteGroupes.count(); i++) {
          var blockquote = objBlockquoteGroupes.getItem(i);
          angular.forEach(obj.fiches, function (objFiche, typeFiche) {
            // Si la fiche que l'on veux ajouter existe déjà
            if (blockquote.data('fiche') === typeFiche) fichesExist[typeFiche] = true;
          });
        }
      }

      /*
      var tasks = []; // Tache à executer en série avant le retour de la fonction
      angular.forEach(obj.fiches, function (objFiche, typeFiche) {
        if (!fichesExist.hasOwnProperty(typeFiche)) {
          var task = function () {
            var deferred = $q.defer();
            if (typeFiche === 'groupe') {
              objRetourHTML = getFicheObj(objFiche);
              if (objRetourHTML) {
                editor.execCommand('blockquoteGroupeUser', {
                  startupData: objRetourHTML,
                  callBack: function () {
                    deferred.resolve();
                  }
                });
              }
              else deferred.resolve();
            }
            else if (typeFiche === 'locataire' || typeFiche === 'colocataire' || typeFiche === 'proprietaire' || typeFiche === 'coproprietaire' || typeFiche === 'bail' || typeFiche === 'client' || typeFiche === 'salarie' || typeFiche === 'fournisseur') {
              getInfosPerso(objFiche, typeFiche).then(function (objRetourHTML) {
                if (objRetourHTML) {
                  editor.execCommand('blockquoteGroupeUser', {
                    startupData: objRetourHTML,
                    callBack: function () {
                      deferred.resolve();
                    }
                  });
                }
                else deferred.resolve();
              });
            }
            else if (typeFiche === 'immeuble') {
              ICSCloudService.getDigicode(idPortefeuille, objFiche.getNumImmeuble()).then(function (digicodes) {
                if (digicodes.length) objFiche.setDigicodes(digicodes);
                getFichesImmeuble(objFiche).then(function (objRetourHTML) {
                  if (objRetourHTML) {
                    editor.execCommand('blockquoteGroupeUser', {
                      startupData: objRetourHTML,
                      callBack: function () {
                        deferred.resolve();
                      }
                    });
                  }
                  else deferred.resolve();
                });
              });
            }
            // else if (typeFiche === 'clientCapfun') {
            //   getInfosClientCapfun(objFiche, typeFiche).then(function (objRetourHTML) {
            //     if (objRetourHTML) {
            //       editor.execCommand('blockquoteGroupeUser', {
            //         startupData: objRetourHTML,
            //         callBack: function () {
            //           deferred.resolve();
            //         }
            //       });
            //     }
            //     else deferred.resolve();
            //   });
            // }
            else if (typeFiche === 'lotMulticamp') {
              getInfosLotMulticamp(objFiche, typeFiche).then(function (objRetourHTML) {
                if (objRetourHTML) {
                  editor.execCommand('blockquoteGroupeUser', {
                    startupData: objRetourHTML,
                    callBack: function () {
                      deferred.resolve();
                    }
                  });
                }
                else deferred.resolve();
              });
            }
            else if (typeFiche === 'lieuCommunCapfun') {
              getInfosLieuCommunCapfun(objFiche, typeFiche).then(function (objRetourHTML) {
                if (objRetourHTML) {
                  editor.execCommand('blockquoteGroupeUser', {
                    startupData: objRetourHTML,
                    callBack: function () {
                      deferred.resolve();
                    }
                  });
                }
                else deferred.resolve();
              });
            }
            else if (typeFiche === 'visicop') {
              getFicheVisicop(objFiche).then(function (objRetourHTML) {
                if (objRetourHTML) {
                  editor.execCommand('blockquoteGroupeUser', {
                    startupData: objRetourHTML,
                    callBack: function () {
                      deferred.resolve();
                    }
                  });
                }
                else deferred.resolve();
              });
            }
            return deferred.promise;
          };
          tasks.push(task);
        }
      });
      // TODO : Ordonner les task avant de les executer
      $q.serial(tasks).then(function () {
        globalDeferred.resolve(editor);
      });
      */

      var tasks = []; // Tache à executer en série avant le retour de la fonction
      // TODO : Récupérer le noPerso du porprietaire et le numero d'immeuble pour recup les associations ?
      var numeroImmeuble = null;
      var noPersoProprietaire = null;
      // Recup les deux et prendre toutes les assoc ? ou soit l'un soit l'autre ?
      angular.forEach(obj.fiches, function (objFiche, typeFiche) {
        if (!fichesExist.hasOwnProperty(typeFiche)) {
          var deferred = $q.defer();
          if (typeFiche === 'groupe') {
            objRetourHTML = getFicheObj(objFiche);
            deferred.resolve(objRetourHTML);
          }
          else if (typeFiche === 'locataire' || typeFiche === 'colocataire' || typeFiche === 'proprietaire' || typeFiche === 'coproprietaire' || typeFiche === 'bail' || typeFiche === 'client' || typeFiche === 'salarie' || typeFiche === 'fournisseur') {
            getInfosPerso(objFiche, typeFiche).then(function (objRetourHTML) {
              deferred.resolve(objRetourHTML);
            });
          }
          else if (typeFiche === 'immeuble') {
            ICSCloudService.getDigicode(idPortefeuille, objFiche.getNumImmeuble()).then(function (digicodes) {
              if (digicodes.length) objFiche.setDigicodes(digicodes);
              getFichesImmeuble(objFiche).then(function (objRetourHTML) {
                deferred.resolve(objRetourHTML);
              });
            });
          }
            // else if (typeFiche === 'clientCapfun') {
            //   getInfosClientCapfun(objFiche, typeFiche).then(function (objRetourHTML) {
            //     deferred.resolve(objRetourHTML);
            //   });
          // }
          else if (typeFiche === 'lotMulticamp') {
            getInfosLotMulticamp(objFiche, typeFiche).then(function (objRetourHTML) {
              deferred.resolve(objRetourHTML);
            });
          }
          else if (typeFiche === 'lieuCommunCapfun') {
            getInfosLieuCommunCapfun(objFiche, typeFiche).then(function (objRetourHTML) {
              deferred.resolve(objRetourHTML);
            });
          }
          else if (typeFiche === 'visicop') {
            getFicheVisicop(objFiche).then(function (objRetourHTML) {
              deferred.resolve(objRetourHTML);
            });
          }
          tasks.push(deferred.promise);
        }
      });

      // Quand toutes les taches sont ok
      $q.all(tasks).then(function (taches) {
        if(_.isArray(taches) && taches.length) {
          // Trie les taches par ordre d'affichage de fiche
          taches = _.sortBy(taches, ['ordreFiche']);
          var execs = []
          // Parcour les taches à executer
          angular.forEach(taches, function (tache) {
            execs.push(function () {
              var deferred = $q.defer();
              if (tache) {
                editor.execCommand('blockquoteGroupeUser', {
                  startupData: tache,
                  callBack: function () {
                    deferred.resolve();
                  }
                });
              }
              else deferred.resolve();
              return deferred.promise;
            });
          });
          if(execs.length) {
            // Quand toutes les taches sont executé en séries
            $q.serial(execs).then(function () {
              globalDeferred.resolve(editor);
            });
          }
          else globalDeferred.resolve(editor);
        }
        else globalDeferred.resolve(editor);
      });
      return globalDeferred.promise;
    }
    //----------- Fin Origine ---------------

    /**
     * Lors du clique sur le bouton pour ajouter les destinataires venant des associations
     */
    function onClickAddDestinatairesFromGroupeAssociation(){
      ctrl.evenement.addDestinataire(ctrl.listeGroupeAddByGroupeAssociation);
      ctrl.displayBtnAddGroupeFromAssociations = false;
    }

    /**
     * Permet de savoir si l'événement est éditable par l'utilisateur
     * @returns {boolean}
     */
    function allowEditEvenement() {
      var retour = false;
      if ((ctrl.evenement.isModifiable() && !ctrl.isNewTopic) || ctrl.isNewTopic || ctrl.addEvenementTopic) {
        retour = true;
      }
      return retour;
    }

    /**
     * Lors du clique sur le bouton pour ouvrir le datepicker de la date d'échéance
     */
    function onClickDateEcheance() {
      ctrl.dateEcheanceOpened = true;
    }

    /**
     * Lors du clique sur le bouton pour ouvrir le datepicker de la date d'intervention
     */
    function onClickDateIntervention() {
      ctrl.dateInterventionOpened = true;
    }


    /**
     * Lors du click sur le bouton urgent
     */
    function onClickUrgent() {
      if (ctrl.evenement.getIdContenu()) {
        if (ctrl.evenement.urgent) ctrl.evenement.urgent = false;
        else ctrl.evenement.urgent = true;

        // Submit l'événements
        submitContenu();
      }
    }

    /**
     * Lors du changement d'un destinataire
     * @param destinataires
     */
    function onChangeDestinataires(destinataires) {
      ctrl.evenement.setDestinataires(destinataires);
      if(ctrl.listeGroupeAddByGroupeAssociation.length){
        var nbFound = 0;
        var nbDestAssoc = ctrl.listeGroupeAddByGroupeAssociation.length;
        if(destinataires.length){
          for(var i = 0; i < nbDestAssoc; i++){
            for(var j = 0; j < destinataires.length; j++){
              if(UtilsService.destinataireIsEqual(ctrl.listeGroupeAddByGroupeAssociation[i], destinataires[j])){
                nbFound++;
                break;
              }
            }
          }
        }
      }
      ctrl.displayBtnAddGroupeFromAssociations = (nbFound<nbDestAssoc);
    }

    /*
    function onEndGetGroupeAllFromDestinataireComponent(groupeAll) {
      ctrl.groupeAll = groupeAll;
    }*/

    //----------- Sinistre ---------------

    /**
     * Lors du clique sur le bouton pour afficher la fenetre de déclaration du sinistre
     */
    function onClickDeclarerSinistre() {
      $scope.$emit('openDeclarationSinitre');
    }
    //----------- Fin Sinistre ---------------

    /**
     * Permet de passer le formulaire de l'événement en mode édition
     */
    function onClickEditMsg() {
      ctrl.editMsgObjet = !ctrl.editMsgObjet;
      // Lors de l'ouvertue du formulaire
      if (ctrl.editMsgObjet && ctrl.evenement.dateEcheance) {
        ctrl.evenement.dateEcheance = new Date(ctrl.evenement.dateEcheance);
      }
      // Lorsqu'on ferme le formulaire sans enregistrer
      else {
        ctrl.currentModelEvent = null;
        $rootScope.destroyInstandeCKEDITOR(ctrl.evenement);

        // Si il y a un objet dans le local storage
        if (ctrl.objContenuSave.objet !== '' && angular.isDefined(ctrl.objContenuSave.objet)) ctrl.tmpObjet = ctrl.objContenuSave.objet;
        else ctrl.tmpObjet = ctrl.evenement.getObjet();
      }
    }

    /**
     * Permet de resete le fomulaire d'événement
     */
    function onClickCleanModificationEvenement() {
      sweet.show({
        title: 'Annuler les modification de l\'objet ?',
        text: 'Vous êtes sûr ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Oui !',
        closeOnConfirm: true
      }, function () {
        resetForm();
      });
    }

    /**
     * Permet de reste le formulaire
     */
    function resetForm(){
      if (ctrl.isNewTopic) ctrl.formTopicTitre = '';

      // Si pas d'id Contenu alors c'est un nouvel evenement
      if (!ctrl.evenement.getIdContenu()) {
        ctrl.evenement.setDestinataires([]);
        ctrl.evenement.setAssigneA('');
        ctrl.evenement.setAssignePar('');
        ctrl.evenement.setUrgent(false);
        ctrl.evenement.setTags([]);
        ctrl.evenement.setTagsType([]);
      }

      // Si réponse à un contenu ou si nouveau topic
      if (ctrl.isNewTopic) {
        ctrl.tmpObjet = '';
        ctrl.tmpReferenceClient = '';
        ctrl.tmpDateEcheance = '';
        ctrl.tmpDateIntervention = '';

        // Si un modèle est choisi on
        if (ctrl.currentModelEvent) initDefaultModelAndDestinataires(ctrl.currentModelEvent);
      }
      // Si modification
      else {
        ctrl.tmpObjet = ctrl.evenement.getObjet();
        ctrl.tmpReferenceClient = ctrl.evenement.getReferenceClient();
        if (ctrl.evenement.getDateEcheance()) ctrl.tmpDateEcheance = ctrl.evenement.getDateEcheance(true);
        if (ctrl.evenement.getDateIntervention()) ctrl.tmpDateIntervention = ctrl.evenement.getDateIntervention(true);
      }

      var content = splitContent(ctrl.tmpObjet);
      ctrl.instanceCKEDITOR.setData(content.mainContent);
      ctrl.instanceFichesCKEDITOR.setData(content.fichesContent);

      // Supprime la sauvegarde du localstorage
      AutoSaveService.delete(getObjContenu(), ctrl.evenement.getIdContenu());

      $scope.$digest();
    }

    /**
     * Permet de sauvegarder le fomulaire d'événement
     * @param callback
     */
    function onClickSaveTmp(callback) {
      saveTmpContenu(callback);
    }


    function onClickPrendreInterventionEvent(){
      ModalsService.confirm("Prendre Intervention", "Voulez-vous intervenir sur cet événement ?").then(function () {
        var modalLoading = ModalsService.loading('Patientez...');

        EvenementService
          .changeToEnCours(getEvenement().getIdContenu(), getTypeIntervenant())
          .then(function (retour) {
            setEvenement(retour.evenement);
            emitEvenement();
            if(retour.message){
              if (ctrl.onUpdate) ctrl.onUpdate({objUpdate: {message: retour.message}});
            }
            modalLoading.close();
          })
          .catch(function (msg) {
            ModalsService.alertErreur(msg);
          });
      });
    }

    function onClickTraiterEvent(){
      ModalsService.confirm("Traiter", "Vous avez terminé l'intervention ?").then(function () {
        var modalLoading = ModalsService.loading('Patientez...');

        EvenementService
          .changeToTermine(getEvenement().getIdContenu())
          .then(function (retour) {
            setEvenement(retour.evenement);
            emitEvenement();
            if(retour.message){
              if (ctrl.onUpdate) ctrl.onUpdate({objUpdate: {message: retour.message}});
            }
            modalLoading.close();
          })
          .catch(function (msg) {
            ModalsService.alertErreur(msg);
          });
      });
    }

    function onClickProblemeEvent(){
      var texte = '<div class="text-left"><strong>Commentaire :</strong></div><div class="row"><div class="col-xs-12 padding-left-0"><textarea rows="3" id="commentaireProbleme" class="form-control"></textarea></div></div>';
      ModalsService
        .confirm("Vous avez un problème ?", texte, {type: 'warning'})
        .then(function (modal) {
          var commentaire = angular.element('#commentaireProbleme').val();
          var modalLoading = ModalsService.loading('Patientez...');

          EvenementService
            .changeToProbleme(getEvenement().getIdContenu(), commentaire, getTypeIntervenant())
            .then(function (retour) {
              setEvenement(retour.evenement);
              emitEvenement();
              if(retour.message){
                if (ctrl.onUpdate) ctrl.onUpdate({objUpdate: {message: retour.message}});
              }
              modalLoading.close();
            })
            .catch(function (msg) {
              throw new Error(msg);
            });
        })
        .catch(function(modal){
          ModalsService.alertErreur(msg);
        });
    }

    function onClickMettreEnAttenteEvent(){
      ModalsService.confirm("En Attente", "Mettre en attente ?").then(function () {
        var modalLoading = ModalsService.loading('Patientez...');

        EvenementService
          .changeToEnAttente(getEvenement().getIdContenu(), getTypeIntervenant())
          .then(function (retour) {
            setEvenement(retour.evenement);
            emitEvenement();
            if(retour.message){
              if (ctrl.onUpdate) ctrl.onUpdate({objUpdate: {message: retour.message}});
            }
            modalLoading.close();
          })
          .catch(function (msg) {
            ModalsService.alertErreur(msg);
          });
      });
    }



    /**
     * Lors d'une remonté d'info depuis le composant de selection de catégorie topic
     * @param obj
     */
    function onEmitFromSelectCategorieTopic(obj){
      try {
        if (!obj) throw new Error('Pas d\'objet');
        if (_.isObject(obj)) {
          if(obj.action === 'selectCategorieTopic') {
            ctrl.categorieTopic = obj.categorieTopic;
          }
        }
      }
      catch (err) {
        console.log('[FormulaireEvenementComponent.onEmitFromSelectCategorieTopic] Erreur : ' + err.message);
      }
    }

    /**
     * Lors de la modification d'une pièces jointe
     * @param objUpdate
     * @returns {IPromise<T>}
     */
    /*
    function onUpdatePiecesJointes(objUpdate) {
      var saveAuto = false;
      var deferred = false;
      try {
        if (!objUpdate) throw new Error('Il manque l\'objet "objUpdate" !');
        if (objUpdate.hasOwnProperty('saveAuto')) saveAuto = objUpdate.saveAuto;

        // Si sauvegarde auto alors doit etre une promesse
        if (saveAuto) {
          deferred = $q.defer();
          ctrl.evenement = objUpdate.contenu;
          // Submit l'événements
          saveContenu().then(function (contenuEvenement) {
            deferred.resolve(contenuEvenement);
          });
        }

        // Si action delete
        if (objUpdate.hasOwnProperty('action') && objUpdate.action === 'delete' && !saveAuto) {

          ctrl.submitProgress = true;
          ctrl.evenement
            .deleteDocumentFromListeDocument(objUpdate.document.getGuid())
            .then(function () {
              emitEvenement();
              // Mise à jour du widget des documents
              //$scope.$emit('refreshDocuments');
            })
            .catch(function (msg) {
              ModalsService.alertErreur(msg);
            })
            .finally(function () {
              ctrl.submitProgress = false;
            });
        }
      }
      catch (err) {
        $log.log('[FormulaireEvenement.onUpdatePiecesJointes] Erreur : ' + err.message);
        if (saveAuto) deferred.reject();
      }

      if (saveAuto) {
        return deferred.promise;
      }
    }*/

    /**
     * Lors de la modification d'une pièces jointe
     * @param objUpdate
     * @returns {IPromise<T>}
     */
    function onEmitFromDocuments(objUpdate) {
      try {
        if (!objUpdate) throw new Error('Il manque l\'objet "objUpdate" !');

        // Si action delete
        if (objUpdate.hasOwnProperty('action') && objUpdate.action === 'delete') {

          if(!_.isNil(ctrl.evenement.getIdContenu())) {
            ctrl.submitProgress = true;
            ctrl.evenement
              .deleteDocumentFromListeDocument(objUpdate.document.getGuid())
              .then(function () {
                emitEvenement();
                // Mise à jour du widget des documents
                //$scope.$emit('refreshDocuments');
              })
              .catch(function (msg) {
                ModalsService.alertErreur(msg);
              })
              .finally(function () {
                ctrl.submitProgress = false;
              });
          }
          else {
            ctrl.evenement.removeDocument(objUpdate.document);
            // Obligé de resetter la liste pour que les composant enfant detect le changement
            ctrl.evenement.setListeDocument(ctrl.evenement.getListeDocument());
            emitEvenement();
          }
        }
      }
      catch (err) {
        $log.log('[FormulaireEvenement.onEmitFromDocuments] Erreur : ' + err.message);
      }
    }

    /**
     * Permet de sauvegarder temporairement le formulaire du contenu
     */
    function saveTmpContenu(callback) {
      $state.params.loadOrigine = false;
      saveLocalStorage();	// Save le contenu
      ctrl.objContenuSave = getContenuSave();
      NotificationsToastServices.success('', '<strong>Sauvegardé temporairement !</strong>');
      if(callback) callback();
    }

    /**
     * Permet d'ajouter la demande de devis/intervention ou contrat si besoin
     */
    function addObjetDemande(){
      var deferred = $q.defer();
      if(_.isObject(ctrl.devis) && (ctrl.isNewTopic || !ctrl.evenement.getIdContenu())) {
        ctrl.evenement.setListeDevis(false);
        $scope.$broadcast('getObjetDemande',
          function(objetDemande){
            if(objetDemande && _.isObject(objetDemande)) {

              var demande = angular.copy(objetDemande);
              // Si il y a des destinataires dans l'objet devis, on va les changer de d'attribut car "destinataires" doit disparaitre si possible
              if (demande.destinataires.length) demande.listeDestinataires = angular.copy(demande.destinataires);
              demande.destinataires = [];

              // Si pas de destinataires dans la liste des autres destinataire de la demande
              if (!demande.listeDestinatairesMessageGravitants.length) demande.messageGravitants = '';

              ctrl.evenement.addDevis(demande);
            }
            deferred.resolve();
          },
          function(msg){
            deferred.reject(msg);
          });
      }
      else if(_.isObject(ctrl.contrat) && (ctrl.isNewTopic || !ctrl.evenement.getIdContenu())) {
        ctrl.evenement.setListeContrats(false);
        $scope.$broadcast('getObjetDemande',
          function(objetDemande){
            if(objetDemande && _.isObject(objetDemande)) {

              var demande = angular.copy(objetDemande);

              // Si il y a des destinataires dans l'objet contrat, on va les changer de d'attribut car "destinataires" doit disparaitre si possible
              if (demande.destinataires.length) demande.listeDestinataires = angular.copy(demande.destinataires);
              demande.destinataires = [];

              ctrl.evenement.addContrat(demande);
            }
            deferred.resolve();
          },
          function(msg){
            deferred.reject(msg);
          });
      }
      else deferred.resolve();

      return deferred.promise;
    }

    /**
     * Sauvegarde le contenu en ligne
     * @returns {Promise}
     */
    function saveContenu(cacher) {
      ctrl.submitProgress = true;
      var objetError = null;
      var deferred = $q.defer();
      // Notification d'enregistrement en cours
      var notifWait = false;

      try {
        NotificationsToastServices.wait('Patientez', 'Enregistrement en cours...', function (notif) {
          notifWait = notif;
        });

        if(ctrl.tmpDateEcheance !== '') ctrl.evenement.setDateEcheance(ctrl.tmpDateEcheance);
        if(ctrl.tmpDateIntervention !== '') ctrl.evenement.setDateIntervention(ctrl.tmpDateIntervention);

        //-------------- Origine --------------------
        var hasTagOrigine = false;
        if (ctrl.evenement.hasTagType(COLLAB_CONF.TAG_TYPE_ORIGINE)) hasTagOrigine = true;

        // Si pas de tag origine et (que la societe actuelle n'est pas franceloc ou capfun OU C'est capfun mais on restreint les fonctionnalités car c'est un camping)
        if (!hasTagOrigine && (!$rootScope.isCapfun || $rootScope.simplifyViewForCamping)) {
          // Si un model est selectionné
          if (angular.isObject(ctrl.currentModelEvent)) {

            var msgErrorOrigine = 'Veuillez renseigner une Origine !';
            if (UtilsService.isICS()) msgErrorOrigine = 'Veuillez renseigner un client !';
            objetError = {titre: 'Origine Obligatoire !', text: msgErrorOrigine};
            throw new Error();
          }
        }
        //-------------- Fin Origine --------------------

        //-------------- Objet --------------------
        // Si objet en cours d'éxécution on prends les variables temporaires
        if (ctrl.editMsgObjet) {
          var content = getCkeditorData();
          ctrl.tmpObjet = content.contentHtml;

          if (angular.isUndefined(content.contentText) || content.contentText === '') {
            objetError = {titre: 'Objet Obligatoire !', text: 'Veuillez renseigner un "Objet"'};
            throw new Error();
          }

          ctrl.evenement.setObjet(ctrl.tmpObjet);
          // Référence client
          ctrl.evenement.setReferenceClient(ctrl.tmpReferenceClient);
        }
        //-------------- Fin Objet --------------------

        //---------------  Tags ------------------
        if (!ctrl.evenement.getTags().length) {

          // Si un model est selectionné
          // ET
          // Si c'est un modèle Hotline
          if (angular.isObject(ctrl.currentModelEvent) && ctrl.currentModelEvent.isModeleHotline) {
            objetError = {titre: 'Tag Obligatoire !', text: 'Veuillez renseigner au moins un tag !'};
            throw new Error();
          }
        }
        //---------------  Fin Tags ------------------

        // Ajoute l'objet devis ou contrat demande dans l'enfant si besoin
        addObjetDemande()
          .then(function(){

            ctrl.evenement
              .save(ctrl.formTopicTitre, cacher, ctrl.categorieTopic)
              .then(function (retourObj) {

                ctrl.editMsgObjet = false;
                ContenuService.addEvenementTopic = false;
                ctrl.addEvenementTopic = false;

                // Destroy le CKEDITOR si il  existe
                $rootScope.destroyInstandeCKEDITOR(ctrl.evenement);

                // Supprime la sauvegarde du localstorage
                AutoSaveService.delete(getObjContenu(), ctrl.evenement.getIdContenu());

                // Si modification de l'événement
                if (retourObj.action === 'updateEvenement') {
                  // Actualise les info du contenu courrant
                  setEvenement(retourObj.obj);

                  if($stateParams.action === 'addEventFromVisicop') {
                    parent.window.postMessage({'evenement': retourObj.obj}, '*');
                  }

                  initObjetAndFiches();
                }
                // Si insertion d'un événement
                else if (retourObj.action === 'insertEvenement') {
                  if($stateParams.action === 'addEventFromVisicop') {
                    parent.window.postMessage({'evenement': retourObj.obj}, '*');
                  }
                  if ($state.includes('topics.detail')) {
                    // Reload la page detail en scrollant vers l'événement
                    $state.go('topics.detail', {
                      topicId: retourObj.obj.idTopic,
                      hash: 'Evenement'
                    }, {reload: true});
                  }
                }
                // Si création de topic
                else if (retourObj.action === 'insertTopic') {

                  if($stateParams.action === 'addEventFromVisicop') {
                    parent.window.postMessage({'topic': retourObj.obj}, '*');
                  }

                  var showCommunication = false;

                  // Si nouveau événement ET a un portefeuille ET (des destinataires OU des tags typé) ET qu'on demande à voir l'étape 2
                  if (!ctrl.evenement.idContenu && ctrl.hasPortefeuille &&
                    (ctrl.evenement.getDestinataires().length || ctrl.evenement.getTagsType().length) && ctrl.showEtape2) showCommunication = true;

                  // Redirige vers la page détail du topic fraichement créé en scrollant vers l'événement
                  $state.go('topics.detail', {
                    topicId: retourObj.obj.idTopic,
                    communication: {
                      show: showCommunication,
                      sms: true,
                      message: 'Bonjour, nouvel événement créé'
                    },
                    hash: 'Evenement'
                  }, {reload: true});
                }

                notifySuccessSave();
                ctrl.currentModelEvent = false; // Remet le select des model sur aucun choix

                deferred.resolve(retourObj);

                if(ctrl.modalInstance) ctrl.close({$value: {action: 'addEvent', retourObj: retourObj}});
              })
              .catch(function(err){
                ctrl.submitProgress = false;
                if (notifWait) NotificationsToastServices.clear(notifWait.id);
                deferred.reject();
                ModalsService.alertErreur(err);
              })
              .finally(function () {
                if (notifWait) NotificationsToastServices.clear(notifWait.id);
                ctrl.submitProgress = false;
              });
          })
          .catch(function(err) {
            ctrl.submitProgress = false;
            if (notifWait) NotificationsToastServices.clear(notifWait.id);
            deferred.reject();
            ModalsService.alertErreur(err);
          });
      }
      catch(err) {
        ctrl.submitProgress = false;
        if (notifWait) NotificationsToastServices.clear(notifWait.id);
        deferred.reject();
        if(angular.isObject(objetError)) ModalsService.alertErreur(objetError.titre, objetError.text);
        else ModalsService.alertErreur(err.message);
      }
      return deferred.promise;
    }

    /**
     * Lors du submit du bouton "Valider" ou "Continuer"
     */
    function onSubmitContenu(cacher) {

      // Sauvegarde au cas où il y aurai un problème
      saveTmpContenu();

      // Sauvegarde le contenu en ligne
      saveContenu(cacher);
      /*
      if(!ctrl.modalInstance) saveContenu(cacher);
      else {
        saveContenu(cacher).then(function(retourObj){
          ctrl.close({$value: {action: 'addEvent', retourObj: retourObj}});
        });
      }*/
    }

    /**
     * Executé lors de l'update
     * @param objUpdate
     */
    function onUpdateTag(objUpdate) {
      try {

        var limitTag = false;
        if (objUpdate.hasOwnProperty('limit')) limitTag = objUpdate.limit;

        var typeTag = false;
        if (objUpdate.hasOwnProperty('typeTag')) typeTag = objUpdate.typeTag;

        // Ajoute un tag
        if (objUpdate.hasOwnProperty('addTag')) {
          var canAdd = true;
          var tag;
          if (objUpdate.addTag.hasOwnProperty('tag')) {

            if (typeTag === COLLAB_CONF.TAG_TYPE_ORIGINE) {
              canAdd = false;

              $state.params.loadOrigine = false;

              deleteOrigine().then(function () {
                addOrigine(objUpdate.addTag);
              });
            }
            else tag = objUpdate.addTag.tag;
          }
          else tag = objUpdate.addTag;

          if (canAdd) ctrl.evenement.pushTag(tag, typeTag, limitTag);
        }

        // Supprime un tag
        if (objUpdate.hasOwnProperty('deleteTagFromContenu')) {

          if (typeTag === COLLAB_CONF.TAG_TYPE_ORIGINE) {
            deleteOrigine(objUpdate.deleteTagFromContenu);
            ctrl.evenement.tagsType = [];
          }
          else ctrl.evenement.removeTag(objUpdate.deleteTagFromContenu, typeTag);
        }

        // Supprime plusieur tags
        if (objUpdate.hasOwnProperty('deleteTagsFromContenu')) ctrl.evenement.removeTags(objUpdate.deleteTagsFromContenu, typeTag);

        // Si update evenement
        if (objUpdate.hasOwnProperty('evenement')) {
          setEvenement(objUpdate.evenement);
          emitEvenement();
        }

        if (ctrl.onUpdate) ctrl.onUpdate({objUpdate: objUpdate});
      }
      catch (err) {
        $log.log('[FormulaireEvenement.onUpdateTag] Erreur : ' + err.message);
      }
    }

    /**
     * Lorsque le composant enfant evenement emit quelque chose
     * @param obj
     */
    function onEmitFromManagerDemandeDevisIntervention(obj) {
      try {
        if (angular.isObject(obj)) {
          if (obj.hasOwnProperty('objetDemande') && angular.isObject(obj.objetDemande)) {
            ctrl.evenement.addDevis(obj.objetDemande);
          }
        }
      }
      catch (err) {
        $log.log('[FormulaireEvenement.onEmitFromManagerDemandeDevisIntervention] Erreur : ' + err.message);
      }
    }

    /**
     * Lorsque le composant enfant evenement emit quelque chose
     * @param obj
     */
    function onEmitFromManagerDemandeContrat(obj) {
      try {
        if (angular.isObject(obj)) {
          if (obj.hasOwnProperty('objetDemande') && angular.isObject(obj.objetDemande)) {
            ctrl.evenement.addContrat(obj.objetDemande);
          }
        }
      }
      catch (err) {
        $log.log('[FormulaireEvenement.onEmitFromManagerDemandeContrat] Erreur : ' + err.message);
      }
    }

    /**
     * Lorsque le composant enfant courrier emit quelque chose
     * @param obj
     */
    function onEmitFromCourrier(obj) {
      try {
        //emitEvenement();

        if (!obj) throw new Error('Pas de courrier');
        if (angular.isObject(obj) && obj.hasOwnProperty('action') && obj.hasOwnProperty('courrier') && obj.courrier) {
          if(obj.action === 'add') {
            var exist = false;
            if (ctrl.evenement.getListeCourrier().length) {
              for (var i = 0; i < ctrl.evenement.getListeCourrier().length; i++) {
                if (ctrl.evenement.getListeCourrier()[i].getIdCourrier() === obj.courrier.getIdCourrier()) {
                  exist = true;
                  break;
                }
              }
            }
            if(!exist) ctrl.evenement.getListeCourrier().push(obj.courrier);
          }
          else if(obj.action === 'update') {
            if (ctrl.evenement.getListeCourrier().length) {
              for (var i = 0; i < ctrl.evenement.getListeCourrier().length; i++) {
                if (ctrl.evenement.getListeCourrier()[i].getIdCourrier() === obj.courrier.getIdCourrier())
                  ctrl.evenement.getListeCourrier()[i] = obj.courrier;
              }
            }
          }
          else if(obj.action === 'delete') {
            if (ctrl.evenement.getListeCourrier().length) {
              for (var i = 0; i < ctrl.evenement.getListeCourrier().length; i++) {
                if (ctrl.evenement.getListeCourrier()[i].getIdCourrier() === obj.courrier.getIdCourrier())
                  ctrl.evenement.getListeCourrier().splice(i, 1);
              }
            }
          }
        }
        emitEvenement();
      }
      catch (err) {
        console.log('[FormulaireEvenementComponent.onUpdateCourrier] Erreur : ' + err.message);
      }
    }

    /**
     * Lorsque le composant enfant upload-collab emit quelque chose
     * @param obj
     */
    function onEmitFromUploadCollab(obj){
      try {
        if (!obj) throw new Error('Pas de "obj"');

        if(obj.action === 'updateDroppedDoc' && _.isArray(obj.docs) && obj.docs.length) {

          var nbDocs = angular.copy(ctrl.evenement.getListeDocument().length);
          var documents = _.unionBy(ctrl.evenement.getListeDocument(), angular.copy(obj.docs), 'guid');
          var updateEvent = (nbDocs != documents.length);

          /*
          var documents = ctrl.evenement.getListeDocument();
          var updateEvent = false;
          for(var d = 0; d < obj.docs.length; d++){
            var doc = obj.docs[d];
            // Recupère un tableau avec le fichier si il existe
            if(!UtilsService.where(documents, {guid: doc.getGuid()}).length) {
              documents.push(doc);
              updateEvent = true;
            }
          }*/
          if(updateEvent){
            ctrl.evenement.setListeDocument(documents);
            ctrl.submitProgress = true;
            ctrl.evenement
              .updateDocuments()
              .then(function () {
                $rootScope.$broadcast('refreshListContenus');
                //$scope.$broadcast('resetDocumentsDroppedFromDocs');
              })
              .catch(function (msg) {
                ModalsService.alertErreur(msg);
              })
              .finally(function () {
                ctrl.submitProgress = false;
              });
          }
          //else $scope.$broadcast('resetDocumentsDroppedFromDocs');
        }

        /*
        if(obj.action === 'onDrop' && _.isObject(obj.item)) {
          var documents = ctrl.evenement.getListeDocument();
          // Recupère un tableau avec le fichier si il existe
          var existFile = UtilsService.where(documents, {guid: obj.item.getGuid()});

          // Si le fichier n'existe pas déjà on l'ajoute
          if (!existFile.length) {
            documents.push(obj.item);
            ctrl.evenement.setListeDocument(documents);
            ctrl.submitProgress = true;
            ctrl.evenement
              .updateDocuments()
              .then(function () {
                $rootScope.$broadcast('refreshListContenus');
              })
              .catch(function (msg) {
                ModalsService.alertErreur(msg);
              })
              .finally(function () {
                ctrl.submitProgress = false;
              });
          }
        }*/

        if(obj.action === 'onSuccessItem' && _.isObject(obj.fileItem) && _.isObject(obj.response)){
          var listDoc = DocumentManagerService.getListDocFromRetourUpload(obj);

          if(_.isArray(listDoc)){
            ctrl.documents = ctrl.evenement.getListeDocument();
            _.forEach(listDoc, function (document) {
              obj.fileItem._file.document = document;
              // Ajoute le document dans le tableau des pièces jointes à joindre au contenu
              ctrl.documents.push(document);
            });
          }
        }

        if(obj.action === 'onCompleteAll'){
          // Set les nouveaux documents
          ctrl.evenement.setListeDocument(ctrl.documents);
          ctrl.documents = [];
          ctrl.submitProgress = true;
          ctrl.evenement
            .updateDocuments()
            .then(function () {
              emitEvenement();
              // Mise à jour du widget des documents
              //$scope.$emit('refreshDocuments');
            })
            .catch(function (msg) {
              ModalsService.alertErreur(msg);
            })
            .finally(function () {
              ctrl.submitProgress = false;
            });
        }
      }
      catch (err) {
        console.log('[FormulaireEvenementComponent.onEmitFromUploadCollab] Erreur : ' + err.message);
      }
    }

    /**
     * Lorsque le composant enfant list-document emit quelque chose
     * @param obj
     */
    function onEmitFromListDocument(obj){
      //console.log(obj);
      try {
        if (!obj) throw new Error('Pas de "obj"');

        if(_.isObject(obj.doc)) {

          if(obj.action === 'addDocumentToFactureDemande') {
            if (!ctrl.devis.facture) ctrl.devis.facture = ContenuService.initContenuEvenementFacture();
            ctrl.devis.facture.addDocument(obj.doc);
            ctrl.devis.facture.setUpdate(true);
          }
        }
        else if(_.isArray(obj.docs) && obj.docs.length) {
          if(obj.action === 'addDocumentsToEvent') {
            for(var d = 0; d < obj.docs.length; d++) {
              ctrl.evenement.addDocument(obj.docs[d]);
            }
            ctrl.evenement.setListeDocument(ctrl.evenement.getListeDocument());
            emitEvenement();
          }
          else if(obj.action === 'addDocumentsToDevisDemande') {
            ctrl.devis.setDemandeDevisActive(true);

            for(var d = 0; d < obj.docs.length; d++) {
              ctrl.devis.addDocumentDevis(obj.docs[d]);
            }
            ctrl.devis.setListDocumentDevis(ctrl.devis.getListDocumentDevis());
          }
          else if(obj.action === 'addDocumentsToOtherDocDemande') {
            for(var d = 0; d < obj.docs.length; d++) {
              ctrl.devis.addDocument(obj.docs[d]);
            }
            ctrl.devis.setListeDocument(ctrl.devis.getListeDocument());
          }
        }
      }
      catch (err) {
        console.log('[FormulaireEvenementComponent.onEmitFromListDocument] Erreur : ' + err.message);
      }
    }

    function onEmitFromCheckAssociationsComponent(obj){
      try {
        if (!obj) throw new Error('Pas de "obj"');

        // Si l'event n'existe pas
        if(obj.action === 'loadingDestinataires' && !getEvenement().idContenu) ctrl.loadingDestinataires = obj.valeur;

        if(obj.action === 'updateDestinatairesFromGroupeAssociation' && _.isArray(obj.listGroupe)) {
          updateDestinatairesFromGroupeAssociation(obj.listGroupe, obj.typeAction);
        }
      }
      catch (err) {
        console.log('[FormulaireEvenementComponent.onEmitFromCheckAssociationsComponent] Erreur : ' + err.message);
      }
    }

    /**
     * Lorsque le composant enfant sinistre emit quelque chose
     * @param obj
     */
    function onEmitFromSinistre(obj){
      try {
        if (!obj) throw new Error('Pas de sinistre');
        if (_.isObject(obj) && _.isObject(obj.sinistre)) {
          if(obj.action === 'update') ctrl.evenement.setSinistre(obj.sinistre);
          else if(obj.action === 'delete') ctrl.evenement.setSinistre(null);
          else if(obj.action === 'addMessage' && _.isObject(obj.contenu)) {
            ctrl.onUpdate({objUpdate: {contenu: ctrl.evenement}});
            ctrl.evenement.setSinistre(null);
          }
        }
        emitEvenement();
      }
      catch (err) {
        console.log('[FormulaireEvenementComponent.onEmitFromSinistre] Erreur : ' + err.message);
      }
    }

    /**
     * Permet de faire remonter l'événement vers le parent
     */
    function emitEvenement(){
      if (ctrl.onUpdate) ctrl.onUpdate({objUpdate: {contenu: ctrl.evenement}});
    }

    /**
     * Fermeture du composant si en mode modal
     */
    function onClickClose(){
      if(ctrl.modalInstance) ctrl.dismiss({$value: 'cancel'});
    }
  }


})();
