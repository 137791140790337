(function() {
	'use strict';

	angular.module('collaboreApp').factory('Channel', ChannelModel);

    /** @ngInject */
	function ChannelModel(COLLAB_CONF,TypeChannel){

		function Channel(objetData){
			this.setData(objetData);
		}

		Channel.prototype = {

			isModel: true,

			setData: function(objetData) {
				angular.extend(this, objetData);

				if(objetData.hasOwnProperty('groupeChannel')){
					this.setGroupeChannel(objetData.groupeChannel);
				}
			},

			getIdChannel: function(){
				return this.idChannel;
			},
			setIdChannel: function(id){
				this.idChannel = id;
			},

			getNbNotif: function(){
				return this.nbNotif;
			},
			setNbNotif: function(nb){
				this.nbNotif = nb;
			},

			getNom: function(){
				return this.nom;
			},
			setNom: function(nom){
				this.nom = nom;
			},

			getDateCreation: function(){
				return this.dateCreation;
			},
			setDateCreation: function(date){
				this.dateCreation = date;
			},

			/*
			getUserCreateur: function(){
				return this.userCreateur;
			},
			setUserCreateur: function(user){
				this.userCreateur = user;
			},*/

			getType: function(){
				//console.log(this);
				if(!this.type.isModel){
					this.type = new TypeChannel(this.type);
				}
				return this.type;
			},
			setType: function(obj){
				if(!obj.isModel){
					obj = new TypeChannel(obj);
				}
				this.type = obj;
			},

			getGroupeChannel: function(){
				if(!this.hasOwnProperty('groupeChannel')){
					this.groupeChannel = [];
				}
				if(!angular.isArray(this.groupeChannel)){
					this.groupeChannel = [];
				}
				return this.groupeChannel;
			},
			setGroupeChannel: function(listeGroupes){
				if(!angular.isArray(listeGroupes)){
					listeGroupes = [];
				}/*else{
					if(listeGroupes.length){
						for(var i = 0 ; i < listeGroupes.length ; i++){
							if(!listeGroupes[i].isModel){
								listeGroupes[i] = new Groupe(listeGroupes[i]);
							}
						}
					}
				}*/
				this.groupeChannel = listeGroupes;
			},

			hasGroupeChannel: function(){
				var retour = false;
				if(this.getGroupeChannel().length){
					retour = true;
				}
				return retour;
			},

			hasAccueilForGroupe: function(idGroupe){
				return this.hasGroupeForType(idGroupe,COLLAB_CONF.CHANNEL_TYPE_ACCUEIL);
			},

			hasChannelForGroupe: function(idGroupe){
				return this.hasGroupeForType(idGroupe,COLLAB_CONF.CHANNEL_TYPE_CHANNEL);
			},


			hasGroupeForType: function(idGroupe,type){
				var retour = false;
				// Si le type du channel est le type demandé
				if(this.getType().getIdTypeChannel() === type){
					// Recup les groupes du channel
					var groupes = this.getGroupeChannel();
					if(groupes.length){
						// Parcour les groupes du channel
						for(var i = 0; i < groupes.length ; i++){
							// Si le groupe est trouvé
							if(groupes[i].idGroupe===idGroupe){
								retour = true;
								break;
							}
						}
					}
				}

				return retour;
			},

      isAccueil: function(){
        if(_.isObject(this.getType()) && this.getType().getIdTypeChannel() === COLLAB_CONF.CHANNEL_TYPE_ACCUEIL){
          return true;
        }
        return false;
      }
		};


		return Channel;
	}

})();
