(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.Portefeuille
   * @description
   * # Portefeuille
   * Service in the collaboreApp.
   */
  angular
    .module('collaboreApp')
    .factory('Portefeuille', PortefeuilleModel);

  /** @ngInject */
  function PortefeuilleModel(Main, $q,Personnalite,Fournisseur,Immeuble,LotMulticamp,PortefeuilleInfos,ParamsAjax) {

    function Portefeuille(objetData) {
      if(objetData) this.setData(objetData);
      this.libelle = (_.isObject(this.getPortefeuilleInfos()) && this.getPortefeuilleInfos().getRaisonSociale()) ? this.getPortefeuilleInfos().getRaisonSociale() : this.getNom();
      /*
      Main.apply(this, arguments);
      this.hydrate(objetData);
      */
    }

    Portefeuille.prototype = {

      isModel: true,
      isPortefeuille: true,

      setData: function(objetData) {
        angular.extend(this, objetData);

        if(objetData.hasOwnProperty('listePersonnalite')){
          this.setListePersonnalite(objetData.listePersonnalite);
        }

        if(objetData.hasOwnProperty('portefeuilleInfos')){
          this.setPortefeuilleInfos(objetData.portefeuilleInfos);
        }

        if(objetData.hasOwnProperty('listeImmeuble')){
          this.setListeImmeuble(objetData.listeImmeuble);
        }
        if(objetData.hasOwnProperty('listeLotMulticamp')){
          this.setListeLotMulticamp(objetData.listeLotMulticamp);
        }
        if (objetData.hasOwnProperty('loginSpool')) {
          this.setLoginSpool(objetData.loginSpool);
        }
        if (objetData.hasOwnProperty('serveurSpool')) {
          this.setServeurSpool(objetData.serveurSpool);
        }
      },

      updateData: function(portefeuille){
        this.setIdPortefeuille(portefeuille.idPortefeuille);
        this.setNom(portefeuille.nom);
        this.setCle(portefeuille.cle);
        this.setLoginId(portefeuille.loginId);
        this.setMdpId(portefeuille.mdpId);
        this.setLoginSpool(portefeuille.loginSpool);
        this.setServeurSpool(portefeuille.serveurSpool);
      },

      getGedV1Enabled: function(){
        return this.gedV1Enabled;
      },
      setGedV1Enabled: function(val){
        this.gedV1Enabled = val;
      },
      gedV1IsEnabled: function(){
        return (_.isNil(this.gedV1Enabled) || !this.gedV1Enabled) ? false : this.gedV1Enabled;
      },

      getGedV2Enabled: function(){
        return this.gedV2Enabled;
      },
      setGedV2Enabled: function(val){
        this.gedV2Enabled = val;
      },
      gedV2IsEnabled: function(){
        return (_.isNil(this.gedV2Enabled) || !this.gedV2Enabled) ? false : this.gedV2Enabled;
      },

      getCanSendToGed: function(){
        if(_.isNil(this.canSendToGed) || !this.canSendToGed) return (this.getGedV1Enabled() || this.getGedV2Enabled());
        return this.canSendToGed;
      },
      setCanSendToGed: function(val){
        this.canSendToGed = val;
      },

      getVersionGed: function(){
        return (this.getGedV2Enabled()) ? 2 : (this.getGedV1Enabled()) ? 1 : null;
      },

      getIdPortefeuille: function(){
        return this.idPortefeuille;
      },
      setIdPortefeuille: function(val){
        this.idPortefeuille = val;
      },

      // Nom
      getNom: function(){
        return this.nom;
      },
      setNom: function(valNom){
        this.nom = valNom;
      },

      // Cle
      getCle: function(){
        return this.cle;
      },
      setCle: function(lib){
        this.cle = lib;
      },

      // LoginID
      getLoginId: function(){
        return this.loginId;
      },
      setLoginId: function(valLogin){
        this.loginId = valLogin;
      },

      // MdpID
      getMdpId: function(){
        return this.mdpId;
      },
      setMdpId: function(valMdp){
        this.mdpId = valMdp;
      },


      // LoginSpool
      getLoginSpool: function(){
        return this.loginSpool;
      },
      setLoginSpool: function(valLogin){
        this.loginSpool = valLogin;
      },

      // ServeurSpool
      getServeurSpool: function(){
        return this.serveurSpool;
      },
      setServeurSpool: function(valLogin){
        this.serveurSpool = valLogin;
      },

      // ListePersonnalite
      getListePersonnalite: function(){
        if(!this.listePersonnalite){
          this.listePersonnalite = [];
        }
        return this.listePersonnalite;
      },
      setListePersonnalite: function(valListePersonnalite){
        var liste = [];
        if(angular.isArray(valListePersonnalite)){
          angular.forEach(valListePersonnalite,function(personnalite){
            if(!personnalite.isModel){
              if(personnalite.hasOwnProperty('numero')){
                personnalite = new Fournisseur(personnalite);
              }else{
                personnalite = new Personnalite(personnalite);
              }
            }
            liste.push(personnalite);
          });
        }

        this.listePersonnalite = liste;
      },

      // ListeImmeuble
      getListeImmeuble: function(){
        if(!this.listeImmeuble){
          this.listeImmeuble = [];
        }
        return this.listeImmeuble;
      },
      setListeImmeuble: function(valListeImmeuble){
        var liste = [];
        if(angular.isArray(valListeImmeuble)){
          angular.forEach(valListeImmeuble,function(immeuble){
            if(!immeuble.isModel){
              immeuble = new Immeuble(immeuble);
            }
            liste.push(immeuble);
          });
        }

        this.listeImmeuble = liste;
      },

      // listeLotMulticamp
      getListeLotMulticamp: function(){
        if(!this.listeLotMulticamp) this.listeLotMulticamp = [];
        return this.listeLotMulticamp;
      },
      setListeLotMulticamp: function(valListeLotMulticamp){
        var liste = [];
        if(_.isArray(valListeLotMulticamp)){
          angular.forEach(valListeLotMulticamp,function(lot){
            if(!lot.isModel) lot = new LotMulticamp(lot);
            liste.push(lot);
          });
        }
        this.listeLotMulticamp = liste;
      },

      // Portefeuille Infos
      getPortefeuilleInfos: function(){
        return this.portefeuilleInfos;
      },
      setPortefeuilleInfos: function(infos){
        if(!infos.isModel){
          infos = new PortefeuilleInfos(infos);
        }
        this.portefeuilleInfos = infos;
      },

      getLibelle: function(){
        return this.libelle;
      },

      getIsBlocked: function(){
        return (_.isObject(this.getPortefeuilleInfos())) ? this.getPortefeuilleInfos().getIsBlocked() : false;
      },

      hasPersonnalite: function(){
        var retour = false;
        if(this.getListePersonnalite().length){
          retour = true;
        }
        return retour;
      },

      deleteEntete: function(idGroupe){
        var defered = $q.defer();
        var _this = this;
        var params = {delete: true, idPortefeuille: _this.getIdPortefeuille()};
        if(idGroupe) params.idGroupe = idGroupe;

        ParamsAjax.deleteEntete(params, function(retour){
          if(retour.Resultats[0].success){
            _this.getPortefeuilleInfos().setImageEntete(false);
            defered.resolve(_this);
          }
          else {
            defered.reject();
          }
        });


        return defered.promise;
      },


    };

    return Portefeuille;
  }
})();
