(function() {
	'use strict';

	/**
	 * @desc TagAnnuaire
	 * @example <tag-annuaire></tag-annuaire>
	 */

	angular
		.module('collaboreApp')
		.component('tagAnnuaire',{
			templateUrl: 'app/tags/tag-annuaire.component.html',
			require: {
				tagCtrl: '^tag'
			},
			controllerAs: 'tagannuairectrl',
			controller: TagAnnuaireController
		});

	/*@ngInject*/
	function TagAnnuaireController(AnnuaireService,TagService,  TagsService){

		var ctrl = this;
		ctrl.openAnnuaire = openAnnuaire;	// Ouvre l'annuaire
		ctrl.onClickTag = onClickTag;		// Lors du click sur un tag
		ctrl.onDeleteTag = onDeleteTag;		// Lors de la suppression d'un tag

		ctrl.$onInit = function() {
		};

		function openAnnuaire(){

			var typeTag = false;
			if(ctrl.tagCtrl.typeTag){
				typeTag = ctrl.tagCtrl.typeTag;
			}

			var obj = {
				stringTo: 'tag',
				createEvent: ctrl.tagCtrl.createEvent,
				currentModelEvenement: ctrl.tagCtrl.currentModelEvenement,
				limitDestinataire: ctrl.tagCtrl.limit,
				defautTypeAnnuaire: 'perso'

			};

			AnnuaireService.openAnnuaire(obj).then(function(objRetour){
				//console.log('objRetour',objRetour);

				//var retourUpdate = TagService.genereTagsForType(objRetour, typeTag);
        var retourUpdate = TagsService.genereTagsAndFiches(objRetour, typeTag);

        //console.log('retourUpdate',retourUpdate);

				if(_.isObject(retourUpdate) && _.isObject(retourUpdate.tagCtrl) && _.isArray(retourUpdate.tagCtrl.tags) && retourUpdate.tagCtrl.tags.length) {
					for(var i = 0; i < retourUpdate.tagCtrl.tags.length; i++){
						ctrl.tagCtrl.tags.push(retourUpdate.tagCtrl.tags[i]);
					}
				}

				ctrl.tagCtrl.update(retourUpdate);

				/*
				 if(objRetour.hasOwnProperty('collaborateurs')) {
				 if(!objRetour.collaborateurs.length){
				 throw new Error('Le retour de "objRetour.collaborateurs" est vide, il doit y avoir seulement 1 collaborateur pour "Origine" !');
				 }else{
				 objRetour.origine  = objRetour.collaborateurs[0];
				 delete objRetour.collaborateurs;
				 }
				 }

				 var tag, retourUpdate = {};
				 // Si ajout d'un tag origine
				 if(typeTag === COLLAB_CONF.TAG_TYPE_ORIGINE){

				 try{

				 var objetsTags = TagService.createTags(objRetour);

				 //console.log('objetsTags',objetsTags);

				 var retour = {
				 'tag': {},
				 'groupes': [],
				 'fiches': (angular.isObject(objRetour.fiches)) ? objRetour.fiches : {},
				 'tagsType': objetsTags.tagsType,
				 'tags': objetsTags.tags
				 };


				 var objPortefeuille = false;

				 if(objRetour.hasOwnProperty('portefeuille')) {
				 objPortefeuille = objRetour.portefeuille;
				 }

				 var valeurIcs = false;

				 if(objRetour.type === 'ICS'){

				 typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_ICS;
				 if(objPortefeuille){
				 // Si le portefeuille est celui d'ICS on change le type de tag pour un tag origin compta
				 if(objPortefeuille.nom === COLLAB_CONF.NOM_PORTEFEUILLE_ICS){
				 typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_COMPTA;
				 }
				 }

				 if(objRetour.hasOwnProperty('origine')) {
				 valeurIcs = objRetour.origine.noperso;

				 if(objRetour.origine.isProprietaire){
				 retour.fiches.proprietaire = objRetour.origine;

				 }else if(objRetour.origine.isLocataire){
				 retour.fiches.locataire = objRetour.origine;

				 }else if(objRetour.origine.isCoproprietaire){
				 retour.fiches.coproprietaire = objRetour.origine;
				 }

				 if(UtilsService.isICS()){
				 if(objRetour.origine.isCollaborateur){
				 retour.fiches.client = objRetour.origine;
				 }
				 }
				 }
				 }
				 else if(objRetour.type === 'Collab'){
				 valeurIcs = objRetour.origine.idGroupe;
				 typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_COLLAB;
				 retour.fiches.groupe = objRetour.origine.groupe;
				 }
				 else if(objRetour.type === 'Immeuble'){
				 if(objRetour.origine.isImmeuble) {
				 valeurIcs = objRetour.origine.noimme;
				 typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_IMMEUBLE;
				 }
				 else if(objRetour.origine.hasOwnProperty('noperso')) {
				 valeurIcs = objRetour.origine.noperso;
				 // Si salarié comme origine
				 if(objRetour.origine.isSalarie) {
				 typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_GARDIEN;
				 if(valeurIcs !== '000000') {
				 retour.fiches.salarie = objRetour.origine;
				 }
				 }
				 // Si copropriétaire comme origine
				 else if(objRetour.origine.isCoproprietaire) {
				 typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_ICS;
				 }
				 }

				 if(objRetour.coproprietaire) {
				 retour.fiches.coproprietaire = objRetour.coproprietaire;
				 }
				 }
				 else{
				 if(objRetour.origine.isPerso){
				 retour.fiches.client = objRetour.origine.perso;
				 }
				 else if(objRetour.origine.isCollab){
				 retour.fiches.groupe = objRetour.origine.groupe;
				 }
				 }

				 // Création du tag actuel
				 retour.tag = TagService.genereTag(objRetour.origine,typeTag,valeurIcs,objPortefeuille);
				 retour.tags.push(TagService.genereTag(objRetour.origine));	// Ajoute le tag à la liste du composant
				 retour.tags = UtilsService.unique(retour.tags);



				 if(objRetour.immeuble) {
				 retour.fiches.immeuble = objRetour.immeuble;
				 }
				 if(objRetour.bail) {
				 retour.fiches.bail = objRetour.bail;
				 }

				 //retour.tagsGenerate = TagService.genereTagsGenerate(retour);

				 retourUpdate.addTag = retour;
				 }
				 catch(err){
				 console.log('[TagAnnaireComponent.openAnnuaire] Erreur : '+err.message);
				 }

				 }else{

				 if(objRetour.hasOwnProperty('origine')){
				 tag = TagService.genereTag(objRetour.origine,typeTag);
				 ctrl.tagCtrl.tags.push(tag);

				 retourUpdate.addTag = tag;

				 }
				 // else if(objRetour.hasOwnProperty('collaborateurs')){
				 //
				 // 	retourUpdate.addTags = [];
				 // 	for(var i = 0 ; i < objRetour.collaborateurs.length ; i++){
				 // 		tag = TagService.genereTag(objRetour.collaborateurs[i],typeTag);
				 // 		ctrl.tagCtrl.tags.push(tag);
				 // 		retourUpdate.addTags.push(tag);
				 // 		// TODO : "addTags" n'est pas pris en compte par l'hote, à faire quand on ajoutera plusieur tags avec l'annuaire (càd tag sans limit)
				 // 	}
				 // }

				 }

				 //console.log(retourUpdate);
				 ctrl.tagCtrl.update(retourUpdate);*/
			});

		}

		/**
		 * Lors du clique sur un tag
		 */
		function onClickTag(){
		}

		/**
		 * Lors de la suppression d'un tag
		 * @param tag
		 */
		function onDeleteTag(objUpdate){
			ctrl.tagCtrl.update(objUpdate);
		}
	}
})();
