(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.ResultDocumentAutonome
   * @description
   * # ResultDocumentAutonome
   * Service in the collaboreApp.
   */

  angular.module('collaboreApp').factory('ResultDocumentAutonome', ResultDocumentAutonomeModel);

  /** @ngInject */
  function ResultDocumentAutonomeModel(Main, Document) {

    var ResultDocumentAutonome = function(objetData) {
      Main.call(this, objetData);
    };

    ResultDocumentAutonome.prototype = {

      isResultDocumentAutonome: true,
      model: 'ResultDocumentAutonome',

      // nbTotalDocument
      getNbTotalDocument: function(){
        return this.nbTotalDocument;
      },
      setNbTotalDocument: function(nb){
        this.nbTotalDocument = nb;
      },

      // nbPrevious
      getNbPrevious: function(){
        return this.nbPrevious;
      },
      setNbPrevious: function(nb){
        this.nbPrevious = nb;
      },

      // nbNext
      getNbNext: function(){
        return this.nbNext;
      },
      setNbNext: function(nb){
        this.nbNext = nb;
      },

      // previousDoc
      getPreviousDoc: function(){
        return this.previousDoc;
      },
      setPreviousDoc: function(infosPrevDoc){
        if(_.isObject(infosPrevDoc) && !infosPrevDoc.isModel) infosPrevDoc = new Document(infosPrevDoc);
        this.previousDoc = infosPrevDoc;
      },

      // currentDoc
      getCurrentDoc: function(){
        return this.currentDoc;
      },
      setCurrentDoc: function(infosCurrentDoc){
        if(_.isObject(infosCurrentDoc) && !infosCurrentDoc.isModel) infosCurrentDoc = new Document(infosCurrentDoc);
        this.currentDoc = infosCurrentDoc;
      },

      // nextDoc
      getNextDoc: function(){
        return this.nextDoc;
      },
      setNextDoc: function(infosNextDoc){
        if(_.isObject(infosNextDoc) && !infosNextDoc.isModel) infosNextDoc = new Document(infosNextDoc);
        this.nextDoc = infosNextDoc;
      },

      // listDocument
      getListDocument: function(){
        if(!_.isArray(this.listDocument)) this.listDocument = [];
        return this.listDocument;
      },
      setListDocument: function(val){
        var list = [];
        if(_.isArray(val) && val.length) {
          list = val.map(function(doc){
            return (!doc.isModel) ? new Document(doc) : doc;
          });
        }
        this.listDocument = list;
      }
    };

    angular.extend(ResultDocumentAutonome.prototype, Main.prototype);

    return ResultDocumentAutonome;
  }
})();
