(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.MatchedKeyword
   * @description
   * # MatchedKeyword
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('MatchedKeyword', MatchedKeywordModel);

  /** @ngInject */
  function MatchedKeywordModel(Main) {

    function MatchedKeyword(data) {
      Main.apply(this, arguments);
      this.hydrate(data);
    }

    MatchedKeyword.prototype = {

      model: 'MatchedKeyword',
      isMatchedKeyword: true,

      // count
      getCount: function(){
        return this.count;
      },
      setCount: function(val){
        this.count = val;
      },

      // id
      getId: function(){
        return this.id;
      },
      setId: function(list){
        this.id = list;
      },

      // matches
      getMatches: function(){
        return this.matches;
      },
      setMatches: function(list){
        this.matches = list;
      }

    };

    angular.extend(MatchedKeyword.prototype, Main.prototype);

    return MatchedKeyword;
  }
})();
