(function() {
    'use strict';

    /**
     * @desc Composant stats du topic
     * @example <stats-topic></stats-topic>
     */

    angular
        .module('collaboreApp')
        .component('statsTopic',{
            templateUrl: 'app/topics/stats/stats-topic.component.html',
            bindings: {
                infosEvenement: '<'
            },
            controllerAs: 'statstopicctrl',
            controller: StatsTopicCtrl
        });

    /*@ngInject*/
    function StatsTopicCtrl(StatsTopicService, NgMap, GeoCoder, $q, GroupeService, COLLAB_CONF, TopicService, ICSCloudService, UtilsService){
        var _this = this;

        _this.loadingInfos =  false;
        //_this.loadingMap = false;
        _this.loadingEtatDepense = false;
        _this.infoImmeuble = null;

        _this.parseDate = UtilsService.parseDate;
        //_this.onMapReady = onMapReady;

        _this.$onInit = function(){
            init(_this.infosEvenement.numImmeuble, _this.infosEvenement.portefeuille.idPortefeuille);
        };

        /**
         * Initialisation du component
         * @param numImmeuble
         * @param idPortefeuille
         */
        function init(numImmeuble, idPortefeuille) {

            if(!_.isNil(numImmeuble)) {
                _this.loadingInfos =  true;
              ICSCloudService
                    .getDonneesImmeuble(numImmeuble, idPortefeuille)
                    .then(function onSuccess(infoImmeuble){
                        _this.infoImmeuble = infoImmeuble;
                        //initImmeubleOnMap(_this.infoImmeuble.getAdresse());
                    })
                    .catch(function onError(msg){
                        console.log(msg);
                    })
                    .finally(function onFinally(){
                        _this.loadingInfos =  false;
                    });
            }

            if(!_.isNil(idPortefeuille)) {
                _this.loadingEtatDepense =  true;
                StatsTopicService
                    .getEtatDepenseImmeuble(numImmeuble, idPortefeuille)
                    .then(function onSuccess(rows){
                        _this.tab = rows.html;
                    })
                    .catch(function onError(msg){
                        console.log(msg);
                    })
                    .finally(function onFinally(){
                        _this.loadingEtatDepense = false;
                    });
            }
        }

        /**
         * Permet de récupérer et de setter les coordonnées de latitude et longitude de l'immeuble par rapport à son adresse
         * @param adresse
         */
        function initImmeubleOnMap(adresse){
            /*_this.loadingMap = true;
            GeoCoder
                .geocode({address: adresse})
                .then(function(result) {
                    _this.lat = result[0].geometry.location.lat();
                    _this.lng = result[0].geometry.location.lng();
                })
                .finally(function onFinally(){
                    _this.loadingMap = false;
                });*/

            /*
            getGroupeImmeuble(numImmeuble,  idPortefeuille)
                .then(function onSuccess(groupe){
                    GeoCoder
                        .geocode({address: groupe.getFiche().getAdresse()})
                        .then(function(result) {
                            _this.lat = result[0].geometry.location.lat();
                            _this.lng = result[0].geometry.location.lng();

                        })
                        .finally(function onFinally(){
                            _this.loadingMap = false;
                        });
                })
                .catch(function onError(){
                    _this.loadingMap = false;
                });*/
        }

        /**
         * Lorsque la map est instanciée pas utilisée pour le moment
         * @param map
         */
        /*function onMapReady(map){
            _this.map = map;
        }*/

        /**
         * Permet de récupérer le groupe de l'immeuble par rapport à sont numéro de idPortefeuille
         * @param numImmeuble
         * @param idPortefeuille
         * @returns {*}
         */
        /*
        function getGroupeImmeuble(numImmeuble, idPortefeuille){
            var deferred = $q.defer();
            var type = COLLAB_CONF.TAG_TYPE_IMMEUBLE;
            var groupe = (TopicService.mapTagTypeToObject.hasOwnProperty(type) && !_.isEmpty(TopicService.mapTagTypeToObject[type])) ? TopicService.mapTagTypeToObject[type] : null;

            if(_.isNil(groupe)){
                GroupeService
                    .searchGroupeByLibelleAndPortefeuilleInMySociete(idPortefeuille, numImmeuble)
                    .then(function (groupe){
                        TopicService.mapTagTypeToObject[type] = groupe;
                        deferred.resolve(groupe);
                    })
                    .catch(function (msg){
                        deferred.reject(msg);
                    })
                    .finally(function(){
                    });
            }
            else{
                deferred.resolve(groupe);
            }
            return deferred.promise;
        }*/
    }
})();
