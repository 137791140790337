(function() {

    'use strict';

    /**
     * @desc Composant recapMail
     * @example <recap-mail></recap-mail>
     */
    angular
        .module('collaboreApp')
        .component('recapMail',{
            templateUrl: 'app/recap-mail/recap-mail.component.html',
            controllerAs: 'recapmailctrl',
            controller: RecapMailCtrl
        });

    /** @ngInject */
    function RecapMailCtrl($locale, RecapMailService, UtilsService, ErreurCollabService, sweet){
        var ctrl = this;

        ctrl.getRowEtat = getRowEtat;                               // Permet de retourner la class qu'il faut pour la ligne
        ctrl.clearDatesRecapMails = clearDatesRecapMails;           // Permet de remetre à 0 les dates
        ctrl.onClickRefresh = onClickRefresh;                       // Permet de refresh
        ctrl.onClickResendAll = onClickResendAll;                   // Permet de renvoyer tous le mails
        ctrl.onClickResend = onClickResend;                         // Permet de renvoyer le mail
        ctrl.canResend = canResend;                                 // Permet de savoir si le recapMail peut être renvoyer
        ctrl.isResend = isResend;                                   // Permet de savoir si le recapMail à déjà été renvoyé

        ctrl.nbRecapMailResend = 0;
        ctrl.loadingListeRecapMails = false;
        ctrl.listeRecapMails = [];
        ctrl.listeRecapMailsCanBeResend = [];
        ctrl.listeRecapMailsAlreadyResend = [];
        ctrl.moment = moment;

        var defaultValuesDatePicker = {
            startDate: moment().startOf('isoWeek'),
            endDate:  moment().endOf('isoWeek'),
            label: 'Cette semaine (depuis Lundi)'
        };

        ctrl.filtre = {
            show: true,
            dateRecapMail: {                        // Dates des recap mails
                showDates: false,
                isDefault: true,
                startDate: angular.copy(defaultValuesDatePicker.startDate),
                endDate: angular.copy(defaultValuesDatePicker.endDate),
                label: angular.copy(defaultValuesDatePicker.label)
            }
        };

        ctrl.optionsDatepicker = {
            showDropdowns: true,
            autoApply: false,
            clearLabel: 'Effacer',
            linkedCalendars: false,
            locale: {
                format: 'DD/MM/YYYY',
                separator: ' au ',
                applyLabel: 'Ok',
                fromLabel: 'Du',
                toLabel: 'au',
                cancelLabel: 'Annuler',
                customRangeLabel: 'Personnaliser',
                daysOfWeek: $locale.DATETIME_FORMATS.SHORTDAY,
                firstDay: 1,
                monthNames: $locale.DATETIME_FORMATS.STANDALONEMONTH
            },
            eventHandlers: {
                'show.daterangepicker': function(){
                    ctrl.datePickerDateShow = true;
                },
                'hide.daterangepicker': function(){
                    ctrl.datePickerDateShow = false;
                },
                'apply.daterangepicker': function() {
                    //parseDisplayDates(ev.model);
                    searchRecapMail();
                }
            },
            ranges: {
                'Aujourd\'hui': [moment(), moment()],
                'Hier': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Cette semaine (depuis Lundi)': [angular.copy(defaultValuesDatePicker.startDate), angular.copy(defaultValuesDatePicker.endDate)],
                'Les 7 derniers jours': [moment().subtract(7, 'days'), moment()],
                'Semaine dernière (Lun - Dim)': [moment().subtract(1, 'week').startOf('isoWeek'), moment().subtract(1, 'week').endOf('isoWeek')],
                'Les 14 derniers jours': [moment().subtract(14, 'days'), moment()],
                'Ce mois-ci': [moment().startOf('month'), moment().endOf('month')],
                'Les 30 derniers jours': [moment().subtract(30, 'days'), moment()],
                'Le mois dernier': [moment().subtract(1,'month').startOf('month'), moment().subtract(1,'month').endOf('month')],
                'Toute la période': [moment().startOf('month'), moment().add(1,'month').endOf('month')]
                //'Toute la période': [moment().year(2000).dayOfYear(1).startOf('hour'), moment()]
            },
            opens: 'right'
        };



        ctrl.$onInit = function() {
            searchRecapMail();
        };


        /******* Privée *********/

        function searchRecapMail(){
            ctrl.listeRecapMailsAlreadyResend = [];
            ctrl.loadingListeRecapMails = true;

            var paramsSearch = {};

            if(ctrl.filtre.dateRecapMail.startDate!==null){
                if(ctrl.filtre.dateRecapMail.startDate._isAMomentObject){
                    paramsSearch.dateDebut = ctrl.filtre.dateRecapMail.startDate.format('YYYY-MM-DD');
                }
                else {
                    paramsSearch.dateDebut = UtilsService.dateFormat(ctrl.filtre.dateRecapMail.startDate,'yyyy-MM-dd');
                }

            }
            if(ctrl.filtre.dateRecapMail.endDate!==null){
                if(ctrl.filtre.dateRecapMail.endDate._isAMomentObject){
                    paramsSearch.dateFin = ctrl.filtre.dateRecapMail.endDate.format('YYYY-MM-DD');
                }
                else {
                    paramsSearch.dateFin = UtilsService.dateFormat(ctrl.filtre.dateRecapMail.endDate,'yyyy-MM-dd');
                }

            }

            parseDisplayDates(ctrl.filtre.dateRecapMail);

            RecapMailService
                .getRecapMail(paramsSearch)
                .then(function(recapMails){

                    ctrl.listeRecapMailsCanBeResend = [];
                    if(recapMails.length) {
                        var liste = [];
                        for(var i = 0; i < recapMails.length; i++){
                            if(recapMails[i].canResend()) {
                                liste.push(recapMails[i]);
                            }
                        }
                        ctrl.listeRecapMailsCanBeResend = liste;
                    }


                    ctrl.listeRecapMails = recapMails;
                })
                .finally(function(){
                    ctrl.loadingListeRecapMails = false;
                });
        }

        // Permet de parser les dates qui seront affichés pour les dates pickers
        function parseDisplayDates(obj){
            switch(obj.label) {
                case 'Aujourd\'hui':
                    obj.showDates = obj.startDate.format('dddd DD MMMM YYYY').ucfirst();
                    break;
                case 'Hier':
                    obj.showDates = obj.startDate.format('dddd DD MMMM YYYY').ucfirst();
                    break;
                case 'Ce mois-ci':
                    obj.showDates = obj.startDate.format('MMMM YYYY').ucfirst();
                    break;
                case 'Le mois dernier':
                    obj.showDates = obj.startDate.format('MMMM YYYY').ucfirst();
                    break;
                case 'Toute la période':
                    obj.showDates = false;
                    obj.startDate = null;
                    obj.endDate = null;
                    obj.isDefault = true;
                    break;
                default:
                    obj.showDates = 'Du '+obj.startDate.format('DD MMM YYYY')+' au '+obj.endDate.format('DD MMM YYYY');
                    obj.isDefault = false;
            }
        }


        /********* Public **********/

        function getRowEtat(recapMail){

            var classRow = '';
            if(!ctrl.isResend(recapMail)) {
                switch(recapMail.getEtat()) {
                    case -1:
                        classRow = 'danger';
                        break;
                    case -2:
                        classRow = 'warning';
                        break;
                    case 1:
                        classRow = 'success';
                        break;
                    default:
                        classRow = '';
                }
            }
            else{
                classRow = 'info';
            }

            return classRow;
        }

        function onClickResendAll(){

            if(ctrl.listeRecapMailsCanBeResend.length){

                sweet.show({
                    title: 'Confirmation',
                    text: 'Voulez-vous vraiment demander le renvoi de <strong>'+ctrl.listeRecapMailsCanBeResend.length+'</strong> mail(s) ?',
                    type: 'warning',
                    showCancelButton: true,
                    //confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Renvoyer !',
                    closeOnConfirm: false,
                    showLoaderOnConfirm: true,
                    html: true
                }, function(isConfirm) {
                    if(isConfirm) {

                        RecapMailService
                            .resendMails(ctrl.listeRecapMailsCanBeResend)
                            .then(function(liste){
                                sweet.show('Demande de renvoi effectué !');

                                if(liste.length){
                                    for(var i = 0; i < liste.length; i++){

                                        if(!RecapMailService.checkIfExistInListe(liste[i],ctrl.listeRecapMailsAlreadyResend)) {
                                            ctrl.listeRecapMailsAlreadyResend.push(liste[i]);
                                        }


                                        // Parcour les liste des recapMails affichés
                                        for(var j = 0; j < ctrl.listeRecapMails.length; j++){
                                            if(ctrl.listeRecapMails[j].idRecapMail === liste[i].idRecapMail){
                                                ctrl.listeRecapMails[j] = liste[i];
                                                break;
                                            }
                                        }
                                    }
                                }

                                ctrl.nbRecapMailResend = angular.copy(liste.length);
                                ctrl.listeRecapMailsCanBeResend = [];
                            })
                            .catch(function(msg){
                                ErreurCollabService.alert(msg);
                            });
                    }
                });
            }
        }

        function onClickRefresh(){
            ctrl.nbRecapMailResend = 0;
            searchRecapMail();
        }

        function onClickResend(recapMail){
            recapMail.loading = true;
            RecapMailService
                .resendMail(recapMail)
                .then(function(recapMails){
                    if(recapMails.length === 1) {

                        ctrl.nbRecapMailResend++;

                        if(!RecapMailService.checkIfExistInListe(recapMails[0],ctrl.listeRecapMailsAlreadyResend)) {
                            ctrl.listeRecapMailsAlreadyResend.push(recapMails[0]);
                        }

                        if(ctrl.listeRecapMailsCanBeResend.length){
                            // Parcour la liste des recapMail qui peuvent être renvoyer pour le retiré de la liste
                            for(var j = 0; j < ctrl.listeRecapMailsCanBeResend.length; j++){
                                if(ctrl.listeRecapMailsCanBeResend[j].idRecapMail === recapMails[0].idRecapMail) {
                                    ctrl.listeRecapMailsCanBeResend.splice(j,1);
                                    break;
                                }
                            }
                        }

                        // Si il y a une liste de recapMails affichées
                        if(ctrl.listeRecapMails.length){
                            // Parcour la liste des recapMails affichés
                            for(var i = 0; i < ctrl.listeRecapMails.length; i++){

                                if(ctrl.listeRecapMails[i].idRecapMail === recapMails[0].idRecapMail) {
                                    ctrl.listeRecapMails[i] = recapMails[0];
                                    break;
                                }
                            }
                        }
                    }
                })
                .finally(function(){
                    recapMail.loading = false;
                });
        }

        function canResend(recapMail){
            return RecapMailService.checkIfExistInListe(recapMail,ctrl.listeRecapMailsCanBeResend);
        }

        function isResend(recapMail){
            return RecapMailService.checkIfExistInListe(recapMail,ctrl.listeRecapMailsAlreadyResend);
        }
        /*

         if(ctrl.listeRecapMails.length && recapMails.length){
         // Parcour
         for(var i = 0; i < ctrl.listeRecapMails.length; i++){
         for(var j = 0; j < recapMails.length; j++){

         }
         }
         }
         */

        function clearDatesRecapMails(){
            ctrl.filtre.dateRecapMail.startDate = angular.copy(defaultValuesDatePicker.startDate);
            ctrl.filtre.dateRecapMail.endDate = angular.copy(defaultValuesDatePicker.endDate);
            ctrl.filtre.dateRecapMail.label = angular.copy(defaultValuesDatePicker.label);

            ctrl.filtre.dateRecapMail.showDates = false;
            ctrl.filtre.dateRecapMail.isDefault = true;
        }
    }
})();
