(function () {
  'use strict';

  angular.module('infinite-scroll').value('THROTTLE_MILLISECONDS', 250);
  angular
    .module('collaboreApp')
    .value('COLLAB_VALUES', {
      ASSIGN_TOPIC_DEFAULT: true,
      ASSIGN_TOPIC_CLOTURE_DEFAULT: false,
      TEXTE_SEND_ONE_MAIL_FOR_ALL_DEST: "Envoyer un seul email avec tous les destinataires en copie",
      CONF_URL: {
        FULL_PATH_TOMCAT: '/* @echo FULL_PATH_TOMCAT */' + '/* @echo APP_COLLAB */' + '/',
        PATH_TOMCAT: '/* @echo PATH_TOMCAT */' + '/* @echo APP_COLLAB */' + '/',
        URL_AVATARS: '/* @echo PATH_TOMCAT */' + 'Avatars/',
        URL_ENTETES: '/* @echo PATH_TOMCAT */' + 'Entetes/',
        URL_WEBSOCKET_JAVA: '/* @echo URL_WS_TOMCAT */' + 'Collabore/CollaboreWebsocket/',
        URL_COLLAB_NODEJS: '/* @echo URL_NODEJS */',
        NAMESPACE_COLLAB_NODEJS: '/* @echo NAMESPACE_NODEJS */',
        URL_OCR_FRONT: '/* @echo URL_OCR_FRONT */' + '/* @echo APP_OCR_FRONT */' + '/',
      }
    });
})();
