/* eslint-disable lodash/prefer-some */
(function() {

  'use strict';

  /**
   * @desc Permet de rechercher des topics
   * @example <search-topics></-search-topic>
   */
  angular
      .module('collaboreApp')
      .component('searchTopics',{
          templateUrl: 'app/topics/search-topics/search-topics.component.html',
          controllerAs: 'searchtopicsctrl',
          bindings: {

              btnValidIconClass: '@',
              btnValidText: '@',

              multiCheck: '<',
              onEmit: '&',

              // Si Modal
              resolve: '<',
              close: '&',
              dismiss: '&',
              modalInstance: '<'
          },
          controller: SearchTopicCtrl
      });

  /** @ngInject */
  function SearchTopicCtrl(ElasticSearchService, UtilsService){
    var _this = this;
    var listenerClose = null;

    _this.limit = 10;
    _this.currentPage = 1;
    _this.nbTotal = 0;

    _this.searchTopic = null;
    _this.searchTopicSubmited = null;
    _this.listTopicStates = [];
    _this.listTopicStatesChecked = [];     // Liste privée des topic cochés

    _this.onSubmitSearchTopic = onSubmitSearchTopic;
    _this.onClickChangePage = onClickChangePage;
    _this.onClickClearSearchTopic = onClickClearSearchTopic;
    _this.onCheckTopic = onCheckTopic;
    _this.onClickValid = onClickValid;
    _this.onClickClose = onClickClose;

    _this.$onInit = function() {
      if(_this.resolve && _this.modalInstance) {
        _this.multiCheck = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'multiCheck'));
        _this.btnValidIconClass = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'btnValidIconClass'));
        _this.btnValidText = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'btnValidText'));
      }
      init();
    };

    _this.$onChanges = function(changes){
        if(_.isObject(changes.multiCheck) && !changes.multiCheck.isFirstChange()) {
            _this.multiCheck = changes.multiCheck.currentValue;
        }
    };

    _this.$onDestroy = function(){
        if(!_.isNil(listenerClose)) listenerClose();
    };

    /**
     * Permet d'init le composant
     */
    function init(){
      _this.searchTopic = null;
      _this.searchTopicSubmited = null;
      _this.currentPage = 1;
      _this.listTopicStates = [];
      _this.listTopicStatesChecked = [];
    }

    function onSubmitSearchTopic(){
      _this.searchTopicSubmited = true;
      _this.currentPage = 1;
      callSearchTopic();
    }

    function onClickClearSearchTopic(){
      _this.searchTopic = null;
      _this.searchTopicSubmited = null;
      _this.currentPage = 1;
    }

    function onClickChangePage(){
      callSearchTopic();
    }

    /**
     * Permet d'init le composant
     */
    function callSearchTopic(){
      if(_.isNil(_this.searchTopic)) return;
      _this.listTopicStates = [];
      // Recupération de la liste des topics
      _this.loadingTopic = true;
      ElasticSearchService
        .searchTopic(_this.searchTopic, _this.currentPage, _this.limit)
        .then(function onSuccess(retourTopicsStates){
          _this.listTopicStates = retourTopicsStates.listTopicState.map(function(topicState){
            topicState.checked = _this.listTopicStatesChecked.findIndex(function(topicStateChecked) {
              return topicStateChecked.topic.idTopic === topicState.topic.idTopic;
            }) !== -1;
            return topicState;
          });
          _this.nbTotal = retourTopicsStates.nbTotal;
        })
        .finally(function onFinally(){
            _this.loadingTopic = false;
        });
    }

    /**
     * Lors du clique sur une checkbox d'un dossier
     * @param currentTopic
     */
    function onCheckTopic(currentTopic){

        // Si pas de multi selection alors décoche les cases sauf celle selectionné
        if(_.isNil(_this.multiCheck) || !_this.multiCheck){
          _this.listTopicStatesChecked = [];
          resetCheckedTopic(_this.listTopicStates, currentTopic);
        }
        _this.listTopicStatesChecked = _this.listTopicStates.reduce(function (listChecked, topicState) {
          if(topicState.checked) {
            // Si le topic n'est pas déjà dans la liste
            if(listChecked.findIndex(function(topicChecked) {
              return topicChecked.topic.idTopic === topicState.topic.idTopic;
            }) === -1){
              listChecked.push(topicState);
            }
          }
          return listChecked;
        }, _this.listTopicStatesChecked);
    }

    /**
     * Permet de décocher les cases sauf celle actuellement selectionnée
     * @param listTopicStates
     * @param currentTopicChecked
     */
    function resetCheckedTopic(listTopicStates, currentTopicChecked){
        if(_.isArray(listTopicStates) && listTopicStates.length){
            for(var i = 0; i < listTopicStates.length; i++){
                if(_.isObject(currentTopicChecked) && listTopicStates[i].topic.idTopic !== currentTopicChecked.topic.idTopic) {
                  delete listTopicStates[i].checked;
                }
            }
        }
    }

    function onClickValid(){
      emitTopic();
    }

    function onClickClose(){
      if(_this.modalInstance) _this.dismiss({$value: 'cancel'});
    }

    function emitTopic(){
        if(_this.multiCheck) {
            emit({
                action: 'selectListTopicStates',
                listTopicStates: _this.listTopicStatesChecked
            });
        }
        else {
            emit({
                action: 'selectTopicState',
                topicState: (_this.listTopicStatesChecked.length) ? _this.listTopicStatesChecked[0] : null
            });
        }
    }

    /**
     * Permet d'emit des infos vers le composant parent
     * @param objet
     */
    function emit(objet){
      if(_this.modalInstance) _this.close({$value: objet});
      else _this.onEmit({obj: objet});
    }
  }
})();
