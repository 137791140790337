(function() {
    'use strict';

    /**
     * @ngdoc factory
     * @name collaboreApp.ProxyContenuFacture
     * @description
     * # ProxyContenuFacture
     */

    angular.module('collaboreApp').factory('ProxyContenuFacture', ProxyContenuFactureModel);

    /** @ngInject */
    function ProxyContenuFactureModel(ProxyContenu) {

        function ProxyContenuFacture(objetData) {

            ProxyContenu.apply(this, arguments);

            this.hydrate(objetData);

            this.typeContenu = 5;


        }

        ProxyContenuFacture.prototype = {

            cleanProxy: function(){
                delete this.listeDestinataires;
                delete this.destinataires;
            },

            // idEvenement
            getIdEvenement: function(){
                return this.idEvenement;
            },
            setIdEvenement: function(val){
                this.idEvenement = val;
            },

            // descriptionFacture
            getDescriptionFacture: function(){
                return this.descriptionFacture;
            },
            setDescriptionFacture: function(val){
                this.descriptionFacture = val;
            },

            // idDevisPere
            getIdDevisPere: function(){
                return this.idDevisPere;
            },
            setIdDevisPere: function(val){
                this.idDevisPere = val;
            },

            // idInterventionPere
            getIdInterventionPere: function(){
                return this.idInterventionPere;
            },
            setIdInterventionPere: function(val){
                this.idInterventionPere = val;
            },

            // montant
            getMontant: function(){
                return this.montant;
            },
            setMontant: function(val){
                this.montant = val;
            },

            // groupement
            getGroupement: function(){
                return this.groupement;
            },
            setGroupement: function(val){
                this.groupement = val;
            },

            // dateEnvoi
            getDateEnvoi: function(){
                return this.dateEnvoi;
            },
            setDateEnvoi: function(val){
                this.dateEnvoi = val;
            },

            // dateFacture
            getDateFacture: function(){
                return this.dateFacture;
            },
            setDateFacture: function(val){
                this.dateFacture = val;
            }

            /*
            // acceptedByCS
            getAcceptedByCS: function(){
                return this.acceptedByCS;
            },
            setAcceptedByCS: function(val){
                this.acceptedByCS = val;
            },

            // acceptedBySyndic
            getAcceptedBySyndic: function(){
                return this.acceptedBySyndic;
            },
            setAcceptedBySyndic: function(val){
                this.acceptedBySyndic = val;
            },

            // refusedByCS
            getRefusedByCS: function(){
                return this.refusedByCS;
            },
            setRefusedByCS: function(val){
                this.refusedByCS = val;
            },

            // refusedBySyndic
            getRefusedBySyndic: function(){
                return this.refusedBySyndic;
            },
            setRefusedBySyndic: function(val){
                this.refusedBySyndic = val;
            },

            // groupeValidationCS
            getGroupeValidationCS: function(){
                return this.groupeValidationCS;
            },
            setGroupeValidationCS: function(val){
                this.groupeValidationCS = val;
            },

            // dateValidationCS
            getDateValidationCS: function(){
                return this.dateValidationCS;
            },
            setDateValidationCS: function(val){
                this.dateValidationCS = val;
            },

            // groupeValidationSyndic
            getGroupeValidationSyndic: function(){
                return this.groupeValidationSyndic;
            },
            setGroupeValidationSyndic: function(val){
                this.groupeValidationSyndic = val;
            },

            // dateValidationSyndic
            getDateValidationSyndic: function(){
                return this.dateValidationSyndic;
            },
            setDateValidationSyndic: function(val){
                this.dateValidationSyndic = val;
            }*/
        };

        angular.extend(ProxyContenuFacture.prototype, ProxyContenu.prototype);

        return ProxyContenuFacture;
    }
})();