(function() {
  'use strict';

  /**
   * @desc Toolbar des contenu message
   * @example <contenu-message-toolbar contenu="" id-topic=""></contenu-message-toolbar>
   */
  angular
    .module('collaboreApp')
    .component('contenuMessageToolbar',{
      templateUrl: 'app/topics/contenu-message-toolbar.component.html',
      require: {
        contenuMessageComponentCtrl: '^contenuMessage'
      },
      bindings: {
        eventInTopic: '<',
        idGroupeExpediteur: '<',
        isLast: '<',
        isSms: '<',
        onEmit: '&'
      },
      controllerAs: 'contenumessagetoolbarctrl',
      controller: ContenuMessageToolbarController
    });

  /** @ngInject */
  function ContenuMessageToolbarController($rootScope,$timeout,UtilsService){
    var ctrl = this;

    ctrl.myIdGroupe = UtilsService.getCurrentUser().idGroupe;

    ctrl.hasPortefeuille = $rootScope.hasPortefeuille;

    ctrl.$onInit = function() {
    };

    ctrl.editerMessage = editerMessage;			// Permet d'éditer le message
    ctrl.repondreTous = repondreTous;			// Ouvre le formulaire de réponse avec la liste de tous les destinataires
    ctrl.onClickOpenCommunication = onClickOpenCommunication;	// Demande l'ouverture de la box communication
    ctrl.goDetailTopic = UtilsService.goDetailTopic;

    /**
     * Permet d'éditer le message
     * @param itemContenu
     */
    function editerMessage(){
      emit({action: 'editMessage'});
    }

    /**
     * Ouvre le formulaire de réponse avec la liste de tous les destinataires
     * @param {Object} itemContenu - objet contenu
     * @returns {void}
     */
    function repondreTous(){
      emit({action: 'replyAll'});
    }

    function onClickOpenCommunication(){
      emit({action: 'openCommunication'});
    }

    function emit(objet){
      ctrl.onEmit({obj: objet});
    }
  }

})();
