(function () {
  'use strict';

  /**
   * @desc Gestion du contrat
   * @example <manage-contrat></manage-contrat>
   */

  angular
    .module('collaboreApp')
    .component('manageContrat', {
      bindings: {
        objetContrat: '<',
        newTopic: '<',
        allowEditEvenement: '<',
        evenement: '<',
        onEmit: '&'
      },
      templateUrl: 'app/topics/evenement/contrat/manage-contrat.component.html',
      controllerAs: 'managecontratctrl',
      controller: manageContrat
    });

  /*@ngInject*/
  function manageContrat(COLLAB_CONF, COLLAB_VALUES, $rootScope, $scope, $q, DocumentManagerService, FileUploader, TagsService, GroupeService, UserService, UtilsService, sweet, ContenuService, ModalsService, DemandeOptions) {

    var ctrl = this;
    var deferredDevisAutreDocument = false;

    ctrl.tagOrigine = false;         // Tag origine du contenu
    ctrl.tagProprietaire = false;

    ctrl.onChangeDest = onChangeDest;

    //ctrl.onDropAutreDocumentFromWidgetDocuments = onDropAutreDocumentFromWidgetDocuments;
    ctrl.removeAutreDocument = removeAutreDocument;

    //ctrl.onUpdatePiecesJointes = onUpdatePiecesJointes;					// Lors d'une modification de pièces jointes
    //ctrl.saveAddDevis = saveAddDevis;					// Sauvegarde le devis
    ctrl.saveContrat = saveContrat;					// Sauvegarde le devis
    //ctrl.onAddDestinataire = onAddDestinataire;		// Lors de l'ajout d'un destinataire
    ctrl.closeManagerContrat = closeManagerContrat;		// Ferme le devis

    ctrl.showCheckboxSendDocByMail = true; // Permet d'afficher ou cacher la checkbox pour envoyer les docs par email au destinataire

    var oldDestinataires = null;
    ctrl.onEmitSelectSignatureContenu = onEmitSelectSignatureContenu;   // Lors du rmeonté d'infos du composant "select-signature-contenu"

    ctrl.onEmitFromDocuments = onEmitFromDocuments;
    ctrl.onEmitFromUploadCollab = onEmitFromUploadCollab;

    var noSendDocumentParam = null;

    ctrl.$onInit = function () {

      ctrl.autreDocumentsDropTmp = [];        // Liste des autres documents temporaire venant du widget des documents

      // Upload autres documents
      ctrl.uploaderAutresDocuments = new FileUploader({
        autoUpload: false,
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_DOCUMENTS_IN_GROUPE,
        alias: 'fileName',
        removeAfterUpload: false,
        filters: [{
          name: 'sizeLimit',
          fn: function (item) {
            //COLLAB_CONF.SIZE_LIMIT_UPLOAD
            if (item.size <= COLLAB_CONF.SIZE_LIMIT_UPLOAD) return true;
            sweet.show({
              title: '<span class="text-danger">' + item.name + '</span> trop gros !',
              text: '50 Mo Maximum',
              type: 'error',
              html: true
            });
          }
        }]
      });

      // Après l'ajout d'un fichier
      ctrl.uploaderAutresDocuments.onAfterAddingFile = function (fileItem) {
        fileItem.formData.push({idTopic: ctrl.evenement.idTopic});
      };

      // Si c'est un contenu avec idContenu
      if (ctrl.objetContrat.idContenu) {
        oldDestinataires = angular.copy(ctrl.objetContrat.getDestinataires());
        // Si pas de destinataire dans le contenu déjà enregistré, on permet du coup de supprimer un potentiel destinataire ajouté
        if(!ctrl.objetContrat.getDestinataires().length) ctrl.allowDelete = true;
      }
      else{
        if(_.isNil(ctrl.objetContrat.demandeOptions)) ctrl.objetContrat.demandeOptions = new DemandeOptions();
        ctrl.objetContrat.demandeOptions.hideProprietaireInDocument = UtilsService.getParametrage('hideProprietaireInDocument');
        ctrl.tagProprietaire = TagsService.getTagTypeProprietaire(ctrl.evenement.getTagsType());
      }

      noSendDocumentParam = UtilsService.getParametrage('createContratNoSendDocumentsByMail');
      if (noSendDocumentParam !== null) ctrl.objetContrat.sendDocumentDemandeWhenCreateContrat = !noSendDocumentParam;

      checkDisplayOptions();

      // Si appelé depuis la création d'un évenement et d'une demande en même temps
      if(ctrl.newTopic) {
        ctrl.allowEditEvenement = true;

        // Ecoute les chanegment de tag type pour changer les gravitants
        $scope.$watch('managecontratctrl.evenement.tagsType', function(newVal, oldVal) {
          ctrl.tagOrigine = TagsService.getTagTypeOrigine(newVal);
          ctrl.tagProprietaire = TagsService.getTagTypeProprietaire(newVal);
        });

        $scope.$on('getObjetDemande', function(event, successCallback, errorCallback){
          if(successCallback) {
            saveContrat()
              .then(function(objetDemande){
                successCallback(objetDemande);
              })
              .catch(function(msg){
                errorCallback(msg);
              });
          }
        });
      }
    };

    ctrl.$onChanges = function (changes) {
      if(_.isObject(changes)) {
        if(_.isObject(changes.evenement) && !changes.evenement.isFirstChange()) {
          ctrl.evenement = changes.evenement.currentValue;
        }
      }
    };

    /********** Uploader *************/




    /*
    // Lorsqu'un fichier est bien upload
    ctrl.uploaderAutresDocuments.onSuccessItem = function (fileItem, response) {
      if (response.nb !== '0' && ctrl.objetContrat) {
        ctrl.objetContrat.addDocument(response.Documents[0]);
      }
    };

    ctrl.uploaderAutresDocuments.onCompleteAll = function () {
      deferredDevisAutreDocument.resolve();
    };

    // Après l'ajout d'un fichier en pièce jointe
    ctrl.uploaderAutresDocuments.onAfterAddingFile = function(fileItem) {
      fileItem.formData.push({idGroupe: UserService.getGroupe().getIdGroupe()});
      fileItem.formData.push({token: UserService.getGroupe().getToken()});
    };*/


    /**
     * Ajoute un document venant du widget des document à la liste des autres documents
     */
    /*
    function onDropAutreDocumentFromWidgetDocuments(){
      var exist = false;
      if(ctrl.autreDocumentsDropTmp.length){
        for(var i = 0; i < ctrl.autreDocumentsDropTmp.length; i++) {
          if(ctrl.autreDocumentDrop.getGuid() === ctrl.autreDocumentsDropTmp[i].getGuid()) {
            exist = true;
            break;
          }
        }
      }
      if(!exist) ctrl.autreDocumentsDropTmp.push(ctrl.autreDocumentDrop);
      ctrl.autreDocumentDrop = null;
    }*/

    /**
     * Retire un document de la liste des autre document temporaire venant du widget des document
     * @param doc
     */
    function removeAutreDocument(doc){
      if(ctrl.autreDocumentsDropTmp.length){
        for(var i = 0; i < ctrl.autreDocumentsDropTmp.length; i++) {
          if(doc.getGuid() === ctrl.autreDocumentsDropTmp[i].getGuid()) {
            ctrl.autreDocumentsDropTmp.splice(i, 1);
          }
        }
      }
    }

    function onChangeDest(){
      checkDisplayOptions();
    }

    function checkFormulaire() {
      // Si pas de destinataire
      if (!ctrl.objetContrat.destinataires.length)
        throw new Error('Il manque le/les destinataire(s) de la demande !');

      // Un document obligatoire
      /*
      if(!ctrl.autreDocumentsDropTmp.length && !ctrl.uploaderAutresDocuments.getNotUploadedItems().length)
          throw new Error('Un document est obligatoire !');*/
    }

    function saveContrat(closeManager) {
      if(!ctrl.newTopic) ctrl.penddingSave = true;
      var globalDeferred = $q.defer();
      try {
        checkFormulaire();

        var promises = [];

        // Si il y a des autres documents du devis à upload
        if (ctrl.uploaderAutresDocuments.getNotUploadedItems().length) {
          deferredDevisAutreDocument = $q.defer();
          ctrl.uploaderAutresDocuments.uploadAll();
          promises.push(deferredDevisAutreDocument.promise);
        }

        // Si il y a des documents ajouté depuis le widget des documents
        if(ctrl.autreDocumentsDropTmp.length){
          // Parcour les autres documents temporaire déposé depuis le widget
          for(var i = 0; i < ctrl.autreDocumentsDropTmp.length; i++){
            ctrl.objetContrat.addDocument(angular.copy(ctrl.autreDocumentsDropTmp[i]));
          }
        }

        $q.all(promises)
          .then(function () {

            if(angular.isObject(ctrl.signatureSelected) && ctrl.signatureSelected.hasOwnProperty('value')) ctrl.objetContrat.signature = ctrl.signatureSelected.value;

            // Si nouveau contrat
            if (_.isNil(ctrl.objetContrat.idContenu) || !ctrl.objetContrat.idContenu) {

              if(ctrl.newTopic) globalDeferred.resolve(ctrl.objetContrat);
              else {
                ctrl.loading = true;
                // $rootScope.$broadcast('showLoadingContenuEvenementFormulaire');
                ctrl.evenement
                  .createContrat(ctrl.objetContrat)
                  .then(function () {
                    $rootScope.$broadcast('refreshDocuments');
                    //emitDevis();
                    // Ferme le manager devis
                    if (closeManager) closeManagerContrat();
                    globalDeferred.resolve();
                  })
                  .catch(function (msg) {
                    //$rootScope.$broadcast('hideLoadingContenuEvenementFormulaire');
                    ModalsService.alertErreur(msg);
                    globalDeferred.reject(msg);
                  })
                  .finally(function () {
                    ctrl.penddingSave = false;
                    ctrl.loading = false;
                  });
              }
            }
            // Sinon update
            else {
              ctrl.loading = true;
              //$rootScope.$broadcast('showLoadingContenuEvenementFormulaire');
              ctrl.objetContrat
                .updateContratWithProxyContrat(ctrl.evenement)
                .then(function (listContrat) {
                  if(angular.isArray(listContrat) && listContrat.length) {
                    $rootScope.$broadcast('refreshDocuments');
                  }
                  // Ferme le manager devis
                  //emitDevis();
                  if (closeManager) closeManagerContrat();
                  globalDeferred.resolve();
                })
                .catch(function (msg) {
                  //$rootScope.$broadcast('hideLoadingContenuEvenementFormulaire');
                  ModalsService.alertErreur(msg);
                  globalDeferred.reject(msg);
                })
                .finally(function () {
                  ctrl.penddingSave = false;
                  ctrl.loading = false;
                });
            }
          })
          .finally(function(){
            ctrl.autreDocumentsDropTmp = [];
          });
      }
      catch(err) {
        ctrl.penddingSave = false;
        if(!ctrl.newTopic) ModalsService.alertErreur(err.message);
        globalDeferred.reject(err.message);
      }
      return globalDeferred.promise;
    }

    function closeManagerContrat(withoutEvenement) {
      ctrl.objetContrat = false;
      var obj = {
        action: 'closeManagerContrat'
      };

      if(!withoutEvenement) {
        obj.evenement = ctrl.evenement
      }
      ctrl.onEmit({
        obj: obj
      });
    }

    function checkDisplayOptions(){
      // Si le contenu existe déjà, du coup c'est un update
      if(ctrl.objetContrat.idContenu) {
        ctrl.showOptions = false;

        // Cache la case à cocher pour envoyer les doc par email
        ctrl.showCheckboxSendDocByMail = false;

        // Décoche la case pour envoyer les doc par email
        if(ctrl.objetContrat.sendDocumentDemandeWhenCreateContrat && noSendDocumentParam) ctrl.objetContrat.sendDocumentDemandeWhenCreateContrat = false;

        // Si les destinataires on changés par rapport à avant (càd qu'il n'y en avait pas avant mais maintenant oui)
        if(!oldDestinataires.length && ctrl.objetContrat.destinataires.length) {
          ctrl.showCheckboxSendDocByMail = true;
        }
        else ctrl.showCheckboxSendDocByMail = false;

      }
      else {
        ctrl.showOptions = true;

        // Si il y a des destinataires
        if(ctrl.objetContrat.destinataires.length) {
          // Affiche la case à cocher pour envoyer les doc par email car pas de destinataire encore
          ctrl.showCheckboxSendDocByMail = true;
        }
        // Cache la case à cocher pour envoyer les doc par email car pas de destinataire encore
        else {
          ctrl.showCheckboxSendDocByMail = false;
          // Décoche la case pour envoyer les doc par email
          if(ctrl.objetContrat.sendDocumentDemandeWhenCreateContrat && noSendDocumentParam) ctrl.objetContrat.sendDocumentDemandeWhenCreateContrat = false;
        }
      }

      if(ctrl.showCheckboxSendDocByMail) ctrl.showOptions = true;
    }

    /**
     * Lors de la modification
     * @param objUpdate
     * @returns {IPromise<T>}
     */
    /*
    function onUpdatePiecesJointes(objUpdate) {

      //console.log(objUpdate);
      //  var objetUpdate = {'objUpdate':objUpdate};
      try {
        if (!objUpdate) throw new Error('Il manque l\'objet "objUpdate" !');

        if (objUpdate.saveAuto) {
          saveContrat();
        }

      }
      catch (err) {
        console.log('[ManageDevis.onUpdatePiecesJointes] Erreur : ' + err.message);
      }
    }*/

    function onEmitSelectSignatureContenu(obj){
      try {
        if (!obj) throw new Error('Pas d\'obj !');
        if (angular.isObject(obj) && obj.hasOwnProperty('action') && obj.hasOwnProperty('signature')) {
          if(obj.action === 'changeSignature') ctrl.signatureSelected = obj.signature;
        }
      }
      catch (err) {
        console.error('[manageContrat.onEmitSelectSignatureContenu] Erreur : ' + err.message);
      }
    }

    function onEmitFromDocuments(obj, listDocsOrDoc){
      try {
        if (_.isNil(obj) || !_.isObject(obj)) throw new Error('Il manque l\'objet "obj" !');

        if(_.isObject(obj.document)){
          if(obj.action === 'delete') {
            // Si listDocsOrDoc est un array
            if(_.isArray(listDocsOrDoc) && listDocsOrDoc.length) {

              var indexFound = null;
              for(var i = 0; i < listDocsOrDoc.length; i++){
                if(_.isObject(listDocsOrDoc[i]) && listDocsOrDoc[i].guid === obj.document.guid) {
                  indexFound = i;
                  break;
                }
              }
              if(!_.isNil(indexFound)) {
                listDocsOrDoc.splice(indexFound, 1);
              }
            }
          }
        }
        //console.log(listDocsOrDoc);

      }
      catch (err) {
        console.error('[ManageContrat.onEmitFromDocuments] Erreur : ' + err.message);
      }
    }

    /**
     * Lorsque le composant enfant upload-collab emit quelque chose
     * @param obj
     */
    function onEmitFromUploadCollab(obj){
      try {
        if (!obj) throw new Error('Pas de "obj"');

        if(obj.action === 'updateDroppedDoc' && _.isArray(obj.docs)) {
          var msgErreur = null;
          for (var d = 0; d < obj.docs.length; d++) {
            if (!_.isNil(obj.docs[d].typeDocument) && !UtilsService.contains(COLLAB_CONF.TYPE_DOC_ENUMS_ALLOWED_FOR_DEVIS_FACTURE, obj.docs[d].typeDocument)) {
              msgErreur = "Impossible d'ajouter un document de type \"" + obj.docs[d].getLibelleTypeDocument() + "\" en tant que contrat";
              break;
            }
          }
          if (!_.isNil(msgErreur)) ModalsService.alertErreur(msgErreur);
          else ctrl.autreDocumentsDropTmp = _.unionBy(ctrl.autreDocumentsDropTmp, angular.copy(obj.docs), 'guid');
        }

        /*
        if(obj.action === 'onDrop' && _.isObject(obj.item)) {
          var exist = false;
          if(ctrl.autreDocumentsDropTmp.length){
            for(var i = 0; i < ctrl.autreDocumentsDropTmp.length; i++) {
              if(obj.item.getGuid() === ctrl.autreDocumentsDropTmp[i].getGuid()) {
                exist = true;
                break;
              }
            }
          }
          if(!exist) ctrl.autreDocumentsDropTmp.push(obj.item);
        }
        */

        // Lorsqu'un fichier est bien upload
        if(obj.action === 'onSuccessItem' && _.isObject(obj.fileItem) && _.isObject(obj.response)){

          // Set le nouveau document devis
          var listDoc = DocumentManagerService.getListDocFromRetourUpload(obj);
          if(_.isArray(listDoc) && listDoc.length) ctrl.objetContrat.addDocument(listDoc[0]);
        }
        /*
        if(obj.action === 'onSuccessItem' && _.isObject(obj.fileItem) && _.isObject(obj.response)){
          if (obj.response.nb !== '0' && ctrl.objetContrat) {
            ctrl.objetContrat.addDocument(obj.response.Documents[0]);
          }
        }*/
        if(obj.action === 'onCompleteAll'){
          deferredDevisAutreDocument.resolve();
        }
      }
      catch (err) {
        console.error('[manageContrat.onEmitFromUploadCollab] Erreur : ' + err.message);
      }
    }

  }
})();
