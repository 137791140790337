(function () {

  'use strict';

  /**
   * @desc Composant notification de message
   * @example <notification-message></notification-message>
   */
  angular
    .module('collaboreApp')
    .component('notificationMessage', {
      templateUrl: 'app/notification/notification-message/notification-message.component.html',
      controllerAs: 'notificationmessagectrl',
      bindings: {
        mode: '@',                // 'inline' ou 'popover'
        popoverBtnClass: '@',
        popoverIconClass: '@',
        popoverTooltip: '@',
        popoverPlacement: '@',
        popoverClass: '@',
        popoverAppendToBody: '<'
      },
      controller: NotificationMessageCtrl
    });

  /** @ngInject */
  function NotificationMessageCtrl($scope, $rootScope, $state, NotificationService) {
    var ctrl = this;

    ctrl.loading = false;
    ctrl.listNotification = [];
    ctrl.onClickMonAccueil = onClickMonAccueil;
    ctrl.onClickNotification = onClickNotification;

    var listenerNotif = null;

    ctrl.$onInit = function () {
      if(angular.isUndefined(ctrl.mode)) ctrl.mode = 'popover';
      if(angular.isUndefined(ctrl.popoverTooltip)) ctrl.popoverTooltip = 'Notifications';
      if(angular.isUndefined(ctrl.popoverPlacement)) ctrl.popoverPlacement = 'auto';
      if(angular.isUndefined(ctrl.popoverClass)) ctrl.popoverClass = 'popover-collab popover-lg';
      if(angular.isUndefined(ctrl.popoverAppendToBody)) ctrl.popoverAppendToBody = false;

      if(ctrl.mode === 'popover') {
        $scope.$watch('notificationmessagectrl.popoverIsOpen', function(newVal){
          if(newVal) init();
        });
      }
      else init();

      listenerNotif = $scope.$on('refreshNotificationMessage', function(){
        init();
      });
    };

    ctrl.$onDestroy = function () {
      if(!_.isNil(listenerNotif)) listenerNotif();
    };

    function init() {
      ctrl.listNotification = NotificationService.getListNotificationMessage();
    }

    function onClickMonAccueil() {
      ctrl.popoverIsOpen = false;
      $state.go('topics.list', {topicPage: $rootScope.current.pageTopicAccueil, idChannel: null}, {location: true, reload: true, inherit: false});
    }

    function onClickNotification(notification) {
      ctrl.popoverIsOpen = false;
      $state.go('topics.detail', {topicId: notification.getIdTopic(), '#': 'contenu_' + notification.getIdContenu()}, {location: true, reload: true, inherit: false});
      NotificationService.removeFromListNotificationMessageForTopic(notification.getIdTopic());
    }

  }
})();
