(function() {
  'use strict';

  /**
   * @desc Tag pour les groupes collab
   * @example <groupe-collab></groupe-collab>
   */

  angular
    .module('collaboreApp')
    .component('groupeCollab', {
      templateUrl: 'app/topics/components/groupe-collab/groupe-collab.component.html',
      bindings : {
        obj: '<',                // Objet du groupe ou email
        listeUsers: '<',                // Liste des destinataire à mettre à jour si besoin
        allowAddGroupe: '<',    		// Permet d'avoir le bouton pour ajouter au destinataire groupe
        allowAddUser: '<',    			// Permet d'avoir le bouton pour ajouter au destinataire user
        allowInfos: '<',          		// Permet d'avoir le bouton pour voir la fiche
        allowGroupesParents: '<',   	// Permet de voir les groupes parents au survole du badge
        hideSearchInfos: '<',           // Permet de cacher la loupe
        allowDelete: '<',               // Permet d'avoir le bouton pour supprimer l'user
        immeuble: '<',                  // Permet de renseignber un imeuble pour le groupe
        contenuPersoIcs: '<',           // Eventuel PersoICS d'un contenu
        truncateLength: '<',            // Permet de limiter le nombre de caractère à afficher


        appendToBodyPopoverGroupesParents: '<',
        placementPopoverGroupesParents: '@',


        onDelete: '&',
        onBeforeDelete: '&',            // Execute une fonction avant de delete le groupe
        onClickVoirInfos: '&',          // Execute une fonction lors du clique sur la loupe
        onClickAddToDestinataire: '&'   // Execute une fonction lors du clique sur l'ajout en destinataire
      },
      controllerAs: 'groupecollabctrl',
      controller: GroupeCollabController
    });

  /*@ngInject*/
  function GroupeCollabController($injector, $rootScope, $attrs, COLLAB_CONF, COLLAB_VALUES, ModalsService, GroupesService, UtilsService){

    var ctrl = this;

    ctrl.onClickAddUser = onClickAddUser;
    // ctrl.onClickVoirGroupesParents = onClickVoirGroupesParents;
    ctrl.onClickOpenInfos = onClickOpenInfos;
    ctrl.onClickDelete = onClickDelete;
    ctrl.classDestinataire = classDestinataire;

    ctrl.$onInit = function() {

      if(!_.isNil(ctrl.obj)){
        if(angular.isUndefined($attrs.allowDelete)) {
          ctrl.allowDelete = false;
        }
        if(angular.isUndefined($attrs.allowInfos)) {
          ctrl.allowInfos = true;
        }
        if(angular.isUndefined($attrs.allowGroupesParents)) {
          ctrl.allowGroupesParents = true;
        }

        if(angular.isUndefined($attrs.placementPopoverGroupesParents)) {
          ctrl.placementPopoverGroupesParents = 'bottom-left';
        }

        if(angular.isUndefined($attrs.appendToBodyPopoverGroupesParents)) {
          ctrl.appendToBodyPopoverGroupesParents = true;
        }

        init(ctrl.obj);
        ctrl.groupes = false;
      }
      else {
        ctrl.libelle = "Inconnu";
      }
    };

    ctrl.$onChanges = function(changes){
      if(_.isObject(changes.obj) && !changes.obj.isFirstChange() && changes.obj.currentValue){
        ctrl.obj = changes.obj.currentValue;
        init(ctrl.obj);
      }
    };

    function init(userCollab) {
      //console.log(userCollab);
      ctrl.urlAvatar = COLLAB_VALUES.CONF_URL.URL_AVATARS;
      ctrl.loadingAvatar = COLLAB_CONF.MIDDLE_LOADING_SRC;

      ctrl.isEmail = false;
      ctrl.isGroupe = false;
      ctrl.isMulticamp = false;
      ctrl.isCurrentGroupe = false;
      ctrl.sameSociete = false;
      ctrl.fromGroupeAssociation = false;
      ctrl.infosgroupectrl = {}; // Pour tree-recursif-hierarchie-groupe.html

      //scope.fiches = AnnuaireService.getListeFiches();

      var libelle;
      var diezeUser = $rootScope.current.userGroupe.getDieze();

      if(angular.isDefined(userCollab)) {
        // Si object user
        if(angular.isObject(userCollab)) {



          // Si c'est un email
          if((userCollab.email || userCollab.isMail) && !userCollab.hasOwnProperty('idGroupe')) {
            ctrl.isEmail = true;
            libelle = (!userCollab.hasOwnProperty('libelle') && userCollab.hasOwnProperty('email')) ? angular.copy(userCollab.email) : angular.copy(userCollab.libelle);
          }

          // L'objet à la propriété "libelle"
          else if(userCollab.hasOwnProperty('libelle')) {

            ctrl.fromGroupeAssociation = userCollab.fromGroupeAssociation;

            // Si Model "Groupe"
            if(userCollab.isModel && userCollab.model === 'Groupe'){

              libelle = userCollab.getDefaultNom(ctrl.contenuPersoIcs);

              // Si groupeUser
              if(userCollab.isUser()){

                if(userCollab.getUser().hasAvatar()) {
                  ctrl.avatar = userCollab.getUser().getNomAvatar();
                }

                //ctrl.infosgroupectrl.isUser = true; // Pour tree-recursif-hierarchie-groupe.html
                /*
                var ficheCollab = userCollab.getUser().getFichePerso();

                // Si la fiche existe
                if (ficheCollab) {
                    if (ficheCollab.getNomPrenom()) {
                        hasFiche = true;
                    }
                    if (ficheCollab.hasUser()) {
                        if (ficheCollab.getUser().hasAvatar()) {
                            ctrl.avatar = ficheCollab.getUser().getNomAvatar();
                        }
                    }
                }*/

                if(ctrl.allowAddUser){ctrl.allowAdd = true;}
              }
              // Si pas d'user alors c'est un groupe groupe
              else {
                ctrl.isGroupe = true;
                //ctrl.infosgroupectrl.isUser = false; // Pour tree-recursif-hierarchie-groupe.html
                ctrl.allowInfos = true;

                if(ctrl.allowAddGroupe){ctrl.allowAdd = true;}

                // Recupère l'éventuel fiche du groupe
                var fiche = userCollab.getFiche();
                if(fiche){
                  libelle = fiche.getNomPrenom();
                }
              }
            }
            else{
              libelle = userCollab.getDefaultLibelle();
            }

            // Si meme groupe
            if(UtilsService.inSameGroup(userCollab)) ctrl.sameSociete = true;
            // Si pas le meme groupe et pas de fiche, ajoute le dieze au libelle
            //else if(!hasFiche){ libelle += userCollab.dieze;}
          }

          // L'objet à la propriété "nom"
          else if(userCollab.hasOwnProperty('nom')){

            libelle = userCollab.nom;

            // Si meme groupe
            if(UtilsService.inSameGroupString(userCollab.nom)){
              ctrl.sameSociete = true;
              // Supprime la fin du libelle si elle correspond à la société de l'user
              libelle = UtilsService.rtrim(libelle, diezeUser);
            }
          }

          // L'objet a la propriété "idPerson" (Multicamp)
          else if(userCollab.hasOwnProperty('idPerson')){
            if(!userCollab.isModel){
              var LotMulticamp =  $injector.get('LotMulticamp');
              userCollab = new LotMulticamp(userCollab);
              LotMulticamp = null;
            }
            libelle = userCollab.getNom();
            ctrl.sameSociete = true;
          }

          // Si l'attribut 'immeuble' de la directive est rempli
          if(ctrl.immeuble) {
            // Si l'objet actuel n'est pas l'immeuble
            if(!userCollab.isImmeuble()) {
              var ficheImmeuble = ctrl.immeuble.getFiche();
              if(ficheImmeuble){
                libelle += ' '+ficheImmeuble.getNomPrenom();
              }else{
                libelle += ' '+ctrl.immeuble.getLibelle();
              }
            }
          }

          // TODO: Afficher petit à petit les info de la hiérarchi pour les portefeuilles
          // Si c'est un portefeuille, all ou un nouveau cache le bouton des infos
          //if(userCollab.isAll() || userCollab.isPortefeuille() || userCollab.nouveau){
          if(userCollab.isModel && userCollab.isGroupe) {

            if(userCollab.isAll() || userCollab.isPortefeuille()) {
              ctrl.allowInfos = false;
            }
          }
        }
        // Si string
        else if(angular.isString(userCollab)) {
          libelle = userCollab;

          // Si meme groupe par rapport au string
          if(UtilsService.inSameGroupString(userCollab)) ctrl.sameSociete = true;
        }

        if(ctrl.sameSociete) libelle = UtilsService.rtrim(libelle, diezeUser);

        if(ctrl.truncateLength)
          ctrl.libelle = UtilsService.truncate(libelle, ctrl.truncateLength, '...');
        else
          ctrl.libelle = libelle;



        // Si l'attribut "hideSearchInfos" de la directive est à true alors on n'affiche pas la loupe
        if(ctrl.hideSearchInfos || !userCollab.hasOwnProperty('idGroupe')) {
          ctrl.allowInfos = false;
        }
      }
    }

    function classDestinataire() {
      var retourClass = '';

      if(!ctrl.isCurrentGroupe && !ctrl.isEmail) {
        if(ctrl.isGroupe) {
          if(ctrl.sameSociete) retourClass += 'badge-destinataire-groupe-same-societe ';
          else retourClass += 'badge-destinataire-groupe-other-societe ';
        }
        else {
          if(ctrl.sameSociete) retourClass += 'badge-destinataire-user-same-societe ';
          else retourClass += 'badge-destinataire-user-other-societe ';
        }

        if(ctrl.sameSociete) {
          // Si c'est un groupe venant d'une association
          if(ctrl.fromGroupeAssociation){
            retourClass += 'badge-bordered badge-default ';
          }
          else {
            retourClass += 'badge-bordered badge-vert-collabore ';
          }
        }
        else retourClass += 'badge-bordered badge-default-collabore ';
      }

      /*
      if(!ctrl.isGroupe && ctrl.sameSociete && !ctrl.isEmail && !ctrl.isCurrentGroupe) {
        retourClass += 'badge-destinataire-user-same-societe ';
        retourClass += 'badge-bordered badge-vert-collabore ';
      }
      if(ctrl.isGroupe && ctrl.sameSociete && !ctrl.isEmail && !ctrl.isCurrentGroupe) {
        retourClass += 'badge-destinataire-groupe-same-societe ';
        // Si c'est un groupe venant d'une association
        if(ctrl.fromGroupeAssociation){
          retourClass += 'badge-vert-collabore ';
        }
        else {
          retourClass += 'badge-bordered badge-vert-collabore ';
        }
      }
      if(!ctrl.isGroupe && !ctrl.sameSociete && !ctrl.isEmail && !ctrl.isCurrentGroupe) {
        retourClass += 'badge-destinataire-user-other-societe ';
        retourClass += 'badge-bordered badge-default-collabore ';
      }
      if(ctrl.isGroupe && !ctrl.sameSociete && !ctrl.isEmail && !ctrl.isCurrentGroupe) {
        retourClass += 'badge-destinataire-groupe-other-societe ';
        retourClass += 'badge-bordered badge-default-collabore ';
      }*/
      if(ctrl.isEmail) {
        retourClass += 'badge-destinataire-email ';
      }
      if(ctrl.isCurrentGroupe) {
        retourClass += 'badge-destinataire-current-groupe ';
      }

      if(retourClass.length) {
        retourClass = retourClass.slice(0, -1);
      }

      return retourClass;
    }

    function onClickAddUser(){
      if(angular.isDefined($attrs.onClickAddToDestinataire)){
        ctrl.onClickAddToDestinataire({
          obj: ctrl.obj
        });
      }
    }

    /*
    function onClickVoirGroupesParents(){
        if(ctrl.allowGroupesParents){
            ctrl.popupIsOpen = true;
            ctrl.loadingGroupesParentsPopup = COLLAB_CONF.MIDDLE_LOADING;

            GroupesService
                .getGroupesParent(ctrl.obj.idGroupe)
                .then(function(groupesParents) {
                    // Hiérarchie du groupe
                    ctrl.groupes = [groupesParents];

                })
                .finally(function(){
                    ctrl.loadingGroupesParentsPopup = false;
                });
        }
    }*/

    function onClickOpenInfos() {

      var params = {
        groupe: ctrl.obj,
        allowAddGroupeToDestinataire: ctrl.allowAddGroupe,
        allowAddUserToDestinataire: ctrl.allowAddUser
      };
      ModalsService
        .modalFiche(params,  function(){
          // Ferme le popup si ouvert
          onMouseLeavePopup();

          if(angular.isDefined($attrs.onClickVoirInfos)){
            ctrl.onClickVoirInfos();
          }
        });
    }

    function onMouseLeavePopup() {
      ctrl.popupIsOpen = false;
    }

    function updateListeUsers(user) {
      angular.forEach(ctrl.listeUsers, function(currentUser, indexUser) {
        var lib;

        // Si 2 objet
        if(angular.isObject(user) && angular.isObject(currentUser)) {

          if(user.hasOwnProperty('idGroupe')) {
            if(currentUser.idGroupe === user.idGroupe) {
              ctrl.listeUsers.splice(indexUser, 1);
            }
          }
          else {
            if(!_.isNil(currentUser.libelle) && !_.isNil(user.libelle) && currentUser.libelle === user.libelle && !_.isNil(currentUser.dieze) && !_.isNil(user.dieze) && currentUser.dieze === user.dieze) {
              ctrl.listeUsers.splice(indexUser, 1);
            }
            else if(!_.isNil(currentUser.email) && !_.isNil(user.email) && currentUser.email === user.email) {
              ctrl.listeUsers.splice(indexUser, 1);
            }
          }
        }
        // Si 2 string
        else if(angular.isString(user) && angular.isString(currentUser)) {
          if(currentUser === user){
            ctrl.listeUsers.splice(indexUser, 1);
          }
        }
        // Si user est un objet et currentuser et un string
        else if(angular.isObject(user) && angular.isString(currentUser)) {
          if(user.hasOwnProperty('libelle')) {
            lib = user.libelle;
          }
          else if(user.hasOwnProperty('nom')) {
            lib = user.nom;
          }
          if(lib === currentUser){
            ctrl.listeUsers.splice(indexUser, 1);
          }
        }
        // Si user est un string et currentuser et un objet
        else if(angular.isString(user) && angular.isObject(currentUser)) {
          if(currentUser.hasOwnProperty('libelle')) {
            lib = currentUser.libelle;
          }
          else if(currentUser.hasOwnProperty('nom')) {
            lib = currentUser.nom;
          }
          if(lib === user) {
            ctrl.listeUsers.splice(indexUser, 1);
          }
        }
      });
    }

    /**
     * Supprime l'user
     */
    function onClickDelete(){
      // Si fonction à executer avant la suppression
      if(angular.isDefined($attrs.onBeforeDelete)){
        ctrl.onBeforeDelete({
          obj: {
            groupe: ctrl.obj
          }
        }).then(function(){
          //deleteGroupe();
        });
      }
      else if(angular.isDefined($attrs.onDelete)){
        deleteGroupe();
      }
    }

    function deleteGroupe() {
      var objUpdate = {
        obj: {
          groupe: ctrl.obj
        }
      };
      if(ctrl.listeUsers) {
        updateListeUsers(ctrl.obj);
        objUpdate.obj.listeUsers = ctrl.listeUsers;
      }

      ctrl.onDelete(objUpdate);
    }
  }
})();
