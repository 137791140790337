(function() {

  'use strict';

  /**
   * @desc Composant gerer les parametrage d'un groupe
   * @example <groupe-parametrage></groupe-parametrage>
   */
  angular
    .module('collaboreApp')
    .component('groupeParametrage',{
      templateUrl: 'app/parametrage/groupe-parametrage/groupe-parametrage.html',
      controllerAs: 'groupeparametragectrl',
      bindings: {
        groupeAll: '<'
      },
      controller: GroupeParametrageCtrl
    });


  /*@ngInject*/
  function GroupeParametrageCtrl($q, ConfigService, COLLAB_CONF, COLLAB_VALUES, $rootScope, ModalsService, NotificationsToastServices, ParametrageService, AnnuaireService, UtilsService, $scope, hotkeys){

    var ctrl = this;
    ctrl.parametrage = false;
    ctrl.parametrageComfact = false;
    ctrl.paramComfactDefault = $rootScope.current.userGroupe.getDefaultParametrageComfact();
    ctrl.paramComfactNotInParamPerso = {};

    ctrl.custom = ConfigService.getPreferencesCustom();
    ctrl.parseObjetMessage = UtilsService.parseObjetMessage;	// Permet de garder les attributs style du HTML, sinon il sont supprim�
    ctrl.paramDefault = $rootScope.current.userGroupe.getDefaultParametrage();
    ctrl.paramNotInParamPerso = {};
    ctrl.onSelectTab = onSelectTab;

    ctrl.updateAllParametrage = updateAllParametrage;
    ctrl.onClickSaveParametre = onClickSaveParametre;

    ctrl.onClickChangeLogo = onClickChangeLogo;
    ctrl.onClickDeleteLogo = onClickDeleteLogo;
    ctrl.onEmitFromUploadLogoCollab = onEmitFromUploadLogoCollab;

    ctrl.onClickResetParametre = onClickResetParametre;
    ctrl.onChangeParamLocalStorage = onChangeParamLocalStorage;
    ctrl.onEmitFromGroupeParametreCheckbox = onEmitFromGroupeParametreCheckbox;
    ctrl.onEmitFromGroupeParametreComfactCheckbox = onEmitFromGroupeParametreComfactCheckbox;
    ctrl.txtSendOneMailWithAllDest = COLLAB_VALUES.TEXTE_SEND_ONE_MAIL_FOR_ALL_DEST;
    ctrl.isNil = _.isNil;


    ctrl.loading = false;
    ctrl.loadingParam = {};
    ctrl.showSuperAdminParameters = false;
    ctrl.showBetaParameters = false;

    ctrl.$onInit = function(){

      hotkeys.bindTo($scope)
        .add({
          combo: COLLAB_CONF.HOTKEYS_SHOW_FEATURES_ICS.combo,
          description: COLLAB_CONF.HOTKEYS_SHOW_FEATURES_ICS.description,
          callback: function() {
            ctrl.showBetaParameters = !ctrl.showBetaParameters;
          }
        })
        .add({
          combo: COLLAB_CONF.HOTKEYS_SHOW_FEATURES_SUPER_ADMIN.combo,
          description: COLLAB_CONF.HOTKEYS_SHOW_FEATURES_SUPER_ADMIN.description,
          callback: function() {
            ctrl.showSuperAdminParameters = !ctrl.showSuperAdminParameters;
            ctrl.showBetaParameters = angular.copy(ctrl.showSuperAdminParameters);
          }
        });

      getParametrage();

      ctrl.urlUploaderLogo = COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_LOGO_COLLAB_ADMIN_SERVLET;
      ctrl.queueLimitLogo = 1;
      ctrl.textUploadLogo = "Ajouter votre logo";
      ctrl.onlyExtensions = ["jpg","png","jpeg","gif"];
      ctrl.whenAddingLogo = [
        {
          action: 'updateLogoCollab',
          editGroupeAll: true
        }
      ];

      // Si "custom" n'existe pas encore
      if(!_.isObject(ctrl.custom)){
        ctrl.custom = {
          'displayTagsInTopicsList': true		// Affiche ou cache les tags dans la liste des topics
        };
      }

      /**
       * Initialisation et configuration de l'uploader
       */

      /*
      ctrl.uploaderEntete = new FileUploader({
        autoUpload: false,
        queueLimit:1,
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_ENTETE_PORTEFEUILLE_INFOS_ADMIN,
        alias: 'fileName',
        removeAfterUpload : false,
        filters: [
          {
            name: 'sizeLimit',
            fn: function(item) {
              if(item.size<=COLLAB_CONF.SIZE_LIMIT_UPLOAD_DEFAUT){
                return true;
              }else{
                // Si il y a un fichier dans la liste d'upload
                if(ctrl.uploaderEntete.queue.length===1){
                  sweet.show({
                    title: '<span class="text-danger">'+item.name+'</span> trop gros !',
                    text: '2 Mo Maximum',
                    type: 'error',
                    html: true
                  });
                }
              }
            }
          },
          {
            name: 'imageFilter',
            // A user-defined filter
            fn: function(item) {
              var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
              //return '|jpg|png|jpeg|gif|'.indexOf(type) !== -1;
              if(('|jpg|png|jpeg|gif|'.indexOf(type) !== -1)===false){
                sweet.show({
                  title: '<span class="text-danger">' + item.name + '</span> doit être une image !',
                  text: 'Fichier Image obligatoire !',
                  type: 'error',
                  html: true
                });
              }else{
                return true;
              }
            }
          }]
      });

      // Après l'ajout d'une image
      ctrl.uploaderEntete.onAfterAddingFile = function(fileItem) {
        fileItem.formData.push({idPortefeuille: ctrl.currentPortefeuilleEdit.idPortefeuille});
        fileItem.formData.push({token: $rootScope.current.userGroupe.getToken()});
        if(ctrl.idGroupeAll) fileItem.formData.push({idGroupe: ctrl.idGroupeAll});
      };

      ctrl.uploaderEntete.onCompleteItem  = function(item, response) {
        if (response.nb === '1') {
          if (response.Portefeuilles.length) {
            ctrl.currentPortefeuilleEdit = new Portefeuille(response.Portefeuilles[0]);
            hideUploaderEntete();
          }
        }
      };
      */
    };

    ctrl.$onDestroy = function() {
      destroyCKEDITOR();
    };

    //--------------- CKEDITOR ---------------------------
    ctrl.signatureCKEDITOR = false;
    ctrl.ckeditorOnReady 	= ckeditorOnReady;	// Fonction onReady de CKEDITOR
    ctrl.ckeditorOptions 	= {
      height: 300,
      toolbar: COLLAB_CONF.TOOLBAR_CKEDITOR_MINI,
      on: {
        instanceReady: function(obj) {
          ctrl.signatureCKEDITOR = obj.editor;
          /*
          this.dataProcessor.htmlFilter.addRules({
              elements: {
                  img: function( el ) {
                      var isBase64 = UtilsService.test(el.attributes.src,'base64','i');
                      if(isBase64){
                          el.addClass( 'image-max-editor' );
                      }
                  }
              }
          });*/
        }
      }
    };

    // Called when the editor is completely ready.
    function ckeditorOnReady() {
      // Ajout l'instance qui vien d'être créé dans le pool d'instance de CKEDITOR
      $rootScope._poolInstancesCKEDITOR[0] = ctrl.signatureCKEDITOR;

      if(ctrl.parametrage.signature) ctrl.signatureCKEDITOR.setData(ctrl.parametrage.signature);
    }

    /**
     * Destroy une éventuelle instance de CKEDITOR
     */
    function destroyCKEDITOR(){
      if(ctrl.signatureCKEDITOR){
        ctrl.signatureCKEDITOR.removeAllListeners();
        ctrl.signatureCKEDITOR.destroy();
      }
    }
    //--------------- FIN CKEDITOR ---------------------------

    function onSelectTab(tab){
      if(tab === 'paramsGlobaux') {
        if(_.isEmpty(ctrl.listeMetiersFournisseur)) getListMetiersFournisseur();
      }
      else if(tab === 'comfact'){
        getParametrageComfact();
      }
    }

    function getParametrage(){
      ctrl.loading = true;
      if(ctrl.groupeAll) {
        ParametrageService
          .getParametrageAdmin()
          .then(function(parametrage) {
            ctrl.parametrage = parametrage;
          })
          .finally(function(){
            ctrl.loading = false;
          });
      }
      else {
        ParametrageService
          .getParametrage()
          .then(function(parametrage) {
            ctrl.parametrage = parametrage;
            mergeParamPersoFromParamDefault();
          })
          .finally(function(){
            ctrl.loading = false;
          });
      }
    }

    function getParametrageComfact(){
      ctrl.loadingComfact = true;
      ParametrageService
        .getParametrageComfact(ctrl.groupeAll)
        .then(function(parametrage) {
          ctrl.parametrageComfact = parametrage;
          if(_.isNil(ctrl.groupeAll)) mergeParamComfactPersoFromParamDefault();
        })
        .finally(function(){
          ctrl.loadingComfact = false;
        });
    }

    function mergeParamPersoFromParamDefault(){
      ctrl.paramNotInParamPerso = {};
      var keysParamsDefault = Object.keys(ctrl.paramDefault);
      if(keysParamsDefault.length){
        // Parcours les parametres par default
        for(var i = 0; i < keysParamsDefault.length; i++){

          // Si le paramètre n'est pas dans la liste des param perso
          // OU
          // Si le paramètre est dans la liste des param perso mais qu'il est null
          if(!ctrl.parametrage.hasOwnProperty(keysParamsDefault[i]) || (ctrl.parametrage.hasOwnProperty(keysParamsDefault[i]) && ctrl.parametrage[keysParamsDefault[i]] === null)){
            // Si la methode getXXXGroupeAll existe dans l'instance du parametrage par default
            // Permet de setter la valeur du groupeAll dans la valeur du perso pour une visualisation plus cohérente
            // Car en cas de param perso null, c'est le groupeAll qui prend la priorité
            if(angular.isFunction(ctrl.paramDefault['get'+keysParamsDefault[i].ucfirst()+'GroupeAll'])){
              if(keysParamsDefault[i] !== 'signature') {
                ctrl.parametrage[keysParamsDefault[i]] = angular.copy(ctrl.paramDefault['get'+keysParamsDefault[i].ucfirst()+'GroupeAll']());
                ctrl.paramNotInParamPerso[keysParamsDefault[i]] = true;
              }
            }
          }
        }
      }
    }

    function mergeParamComfactPersoFromParamDefault(){
      ctrl.paramComfactNotInParamPerso = {};
      var keysParamsDefault = Object.keys(ctrl.paramComfactDefault);
      if(keysParamsDefault.length){
        // Parcours les parametres par default
        for(var i = 0; i < keysParamsDefault.length; i++){

          // Si le paramètre n'est pas dans la liste des param perso
          // OU
          // Si le paramètre est dans la liste des param perso mais qu'il est null
          if(!ctrl.parametrageComfact.hasOwnProperty(keysParamsDefault[i]) || (ctrl.parametrageComfact.hasOwnProperty(keysParamsDefault[i]) && ctrl.parametrageComfact[keysParamsDefault[i]] === null)){
            // Si la methode getXXXGroupeAll existe dans l'instance du parametrage par default
            // Permet de setter la valeur du groupeAll dans la valeur du perso pour une visualisation plus cohérente
            // Car en cas de param perso null, c'est le groupeAll qui prend la priorité
            if(angular.isFunction(ctrl.paramComfactDefault['get'+keysParamsDefault[i].ucfirst()+'GroupeAll'])){
              if(keysParamsDefault[i] !== 'signature') {
                ctrl.parametrageComfact[keysParamsDefault[i]] = angular.copy(ctrl.paramComfactDefault['get'+keysParamsDefault[i].ucfirst()+'GroupeAll']());
                ctrl.paramComfactNotInParamPerso[keysParamsDefault[i]] = true;
              }
            }
          }
        }
      }
    }

    /**
     * Permet de sauvegarder tous les paramètres
     */
    function updateAllParametrage(){
      ctrl.saveProcessing = true;
      var notifWait = null;
      NotificationsToastServices.wait('Patientez', 'Envoi du message en cours...', function (notif) {
        notifWait = notif;
      });
      ctrl.parametrage.signature = ctrl.signatureCKEDITOR.getData();
      updateParametre(ctrl.parametrage, true)
        .then(function(){
          if(_.isObject(notifWait)) NotificationsToastServices.clear(notifWait.id);
          ctrl.saveProcessing = false;
        });
    }

    /**
     * Permet de sauvegarder un ou plusieur paramètre
     * @param objet
     * @returns {angular.IPromise<T>}
     */
    function updateParametre(objet, updateAll){
      var deferred = $q.defer();
      if(ctrl.groupeAll) {
        ParametrageService.updateParametrageAdmin(objet)
          .then(function(parametrage) {
            ctrl.parametrage = parametrage;
            $rootScope.current.userGroupe.updateParametragesParent(parametrage);
            $rootScope.$broadcast('changeGroupeParams');
            NotificationsToastServices.success('', '<strong>Paramètres société sauvegardés !</strong>');
          })
          .finally(function(){
            ctrl.loading = false;
            deferred.resolve();
          });
      }
      else {
        if(updateAll && _.size(ctrl.paramNotInParamPerso) !== 0){
          var newObjet = {};
          // Parcour les propriétés de "objet" (paramètres à update)
          _.forEach(objet, function(currentValue, currentKey) {
            // Si la propriété n'est pas dans l'objet "paramNotInParamPerso" donc c'est bien un paramètre perso, on le garde pour l'update
            // Car le problème c'est que dans "ctrl.parametrage" il y a des propriété de agence, donc ca va update les param perso
            // Ce qu'on ne veux pas car ca doit rester null pour hériter des params de l'agence, c'est plus cohérent
            if(!ctrl.paramNotInParamPerso.hasOwnProperty(currentKey)) {
              newObjet[currentKey] = currentValue;
            }
          });
          objet = newObjet;
        }

        ParametrageService.updateParametrage(objet)
          .then(function(parametrage) {
            ctrl.parametrage = parametrage;
            $rootScope.current.userGroupe.setParametrage(parametrage);
            $rootScope.$broadcast('changeGroupeParams');
            mergeParamPersoFromParamDefault();
            NotificationsToastServices.success('', '<strong>Paramètres perso sauvegardés !</strong>');
            if(!ctrl.parametrage.hasOwnProperty('signature')) {
              ctrl.signatureCKEDITOR.setData('');
            }
          })
          .catch(function(err){
            console.log(err);
          })
          .finally(function(){
            deferred.resolve();
          });
      }
      return deferred.promise;
    }

    /**
     * Permet de sauvegarder un ou plusieur paramètre comfact
     * @param objet
     * @returns {angular.IPromise<T>}
     */
    function updateParametreComfact(property, value, updateAll){
      var deferred = $q.defer();
      if(ctrl.groupeAll) {
        ParametrageService.updateParametreComfact(property, value, true)
          .then(function(parametrage) {
            ctrl.parametrageComfact = parametrage;
            $rootScope.current.userGroupe.updateParametragesComfactParent(parametrage);
            NotificationsToastServices.success('', '<strong>Paramètres comfact société sauvegardés !</strong>');
          })
          .finally(function(){
            ctrl.loading = false;
            deferred.resolve();
          });
      }
      else {
        if(updateAll && _.size(ctrl.paramNotInParamPerso) !== 0){
          var newObjet = {};
          // Parcour les propriétés de "objet" (paramètres à update)
          _.forEach(objet, function(currentValue, currentKey) {
            // Si la propriété n'est pas dans l'objet "paramNotInParamPerso" donc c'est bien un paramètre perso, on le garde pour l'update
            // Car le problème c'est que dans "ctrl.parametrage" il y a des propriété de agence, donc ca va update les param perso
            // Ce qu'on ne veux pas car ca doit rester null pour hériter des params de l'agence, c'est plus cohérent
            if(!ctrl.paramComfactNotInParamPerso.hasOwnProperty(currentKey)) {
              newObjet[currentKey] = currentValue;
            }
          });
          objet = newObjet;
        }

        ParametrageService.updateParametreComfact(property, value)
          .then(function(parametrage) {
            ctrl.parametrageComfact = parametrage;
            $rootScope.current.userGroupe.setParametrageComfact(parametrage);
            mergeParamComfactPersoFromParamDefault();
            NotificationsToastServices.success('', '<strong>Paramètres comfact sauvegardés !</strong>');
          })
          .catch(function(err){
            console.log(err);
          })
          .finally(function(){
            deferred.resolve();
          });
      }
      return deferred.promise;
    }

    /**
     * Permet d'update seulement un paramètre
     * @param propetry
     * @param value
     */
    function updateOneParametre(property, value){
      var deferred = $q.defer();
      var objet = {};
      objet[property] = angular.copy(value);
      ctrl.loadingParam[property] = true;
      updateParametre(objet).then(function() {
        if(!ctrl.groupeAll) delete ctrl.loadingParam[property];
        else if(value === 'reset') ctrl.paramNotInParamPerso[obj.property] = true;
        deferred.resolve();
      });
      return deferred.promise;
    }

    function updateOneParametreComfact(property, value){
      var deferred = $q.defer();
      ctrl.loadingParam[property] = true;
      updateParametreComfact(property, value).then(function() {
        if(!ctrl.groupeAll) delete ctrl.loadingParam[property];
        else if(value === 'reset') ctrl.paramComfactNotInParamPerso[obj.property] = true;
        deferred.resolve();
      });
      return deferred.promise;
    }

    /**
     * Lors du clique sur un bouton pour sauvegarder un paramètre
     * @param property
     * @param value
     */
    function onClickSaveParametre(property, value){
      if(property === 'signature') value = ctrl.signatureCKEDITOR.getData();
      else if(_.isNil(value) && ctrl.parametrage.hasOwnProperty(property)) value = ctrl.parametrage[property];

      if(_.isNil(value)) {
        ModalsService.alertErreur('Il y a un problème !');
        console.error('property', property);
        console.error('value', value);
        return;
      }
      updateOneParametre(property, value);
    }

    /**
     * Lors du changement d'état d'une case à cocher où la valeur est save en localstorage
     */
    function onChangeParamLocalStorage(){
      ConfigService.setPreferencesCustom(ctrl.custom);
    }

    /**
     * Lors du clique sur le bouton pour reset un paramètre
     * @param param
     */
    function onClickResetParametre(property){
      resetParametre(property);
    }

    /**
     * Permet de reset un paramètre pour récup la valeur par du param société
     * @param param
     */
    function resetParametre(property){
      var deferred = $q.defer();
      updateOneParametre(property, 'reset').then(function(){
        ctrl.paramNotInParamPerso[property] = true;
        deferred.resolve();
      });
      return deferred.promise;
    }

    function resetParametreComfact(property){
      var deferred = $q.defer();
      updateOneParametreComfact(property, 'reset').then(function(){
        ctrl.paramComfactNotInParamPerso[property] = true;
        deferred.resolve();
      });
      return deferred.promise;
    }

    /**
     * Recupère la liste des métiers fournisseur
     */
    function getListMetiersFournisseur(){

      // TODO : Bug quand societe non ICS, peut être faire cette fonction que si Agence ICS ?
      ctrl.loadingSearchMetiersFournisseur = true;

      AnnuaireService.getListMetiersFournisseur('')
        .then(function(liste){
          /*
          ctrl.listeMetiersFournisseur = [];

          if(liste.length) {
              for(var i = 0; i < liste.length; i++) {
                  ctrl.listeMetiersFournisseur.push(liste[i].codemetier);
              }
          }*/
          ctrl.listeMetiersFournisseur = liste;
        })
        .finally(function(){
          ctrl.loadingSearchMetiersFournisseur = false;
        });
    }

    function onClickChangeLogo(){
      if(!ctrl.showUploader) showUploaderLogo();
      else hideUploaderEntete();
    }

    function onClickDeleteLogo(){

      ModalsService.confirm('Suppression du logo', 'Voulez-vous vraiment supprimer le logo ?', {type: 'info'}).then(function (modal) {
        ctrl.loadingLogo = true;

        ParametrageService
          .deleteLogoCollab()
          .then(function(param){
            ctrl.parametrage = param;
            $rootScope.current.userGroupe.getParametrage().setLogoCollab(ctrl.parametrage.getLogoCollab());
            $rootScope.$broadcast("refreshLogoCollab");
            showUploaderLogo();
            modal.close();
          })
          .catch(function(msg){
            ModalsService.alertErreur('Erreur lors de la supression: ' + msg);
          })
          .finally(function(){
            ctrl.loadingLogo = false;
          });
      });

      /*
      sweet.show({
        title: 'Suppression entête',
        text: 'Voulez-vous vraiment supprimer l\'entête ?',
        type: 'info',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        closeOnConfirm: true
      },function() {
        ctrl.loadingEntete = true;
        ctrl.currentPortefeuilleEdit
          .deleteEntete(ctrl.idGroupeAll)
          .finally(function(){
            ctrl.loadingEntete = false;
            showUploaderLogo();
          });
      });*/
    }

    function showUploaderLogo(){
      ctrl.showUploader = true;
    }

    function hideUploaderEntete(){
      ctrl.showUploader = false;
      angular.element('#fileLogo').prop('value', null);
    }

    /**
     * Lorsque le composant enfant upload-collab emit quelque chose
     * @param obj
     */
    function onEmitFromUploadLogoCollab(obj){
      //console.log(obj);

      try {
        if (!obj) throw new Error('Pas de "obj"');
        //if (_.isNil(obj.uploader)) throw new Error('Pas de "obj"');

        if(_.isObject(obj.fileItem) && _.isObject(obj.response)) {
          if(obj.action === 'onErrorItem') {
            ModalsService.alertErreur('Erreur lors de l\'upload');
            console.log('fileItem',obj.fileItem);
            console.log('response',obj.response);
          }
          if(obj.action === 'onSuccessItem'){
            if(_.isObject(obj.response) && obj.response.success) {
              hideUploaderEntete();
              ctrl.parametrage = new ParametrageService.Parametrage(obj.response.result);
              $rootScope.current.userGroupe.getParametrage().setLogoCollab(ctrl.parametrage.getLogoCollab());
              $rootScope.$broadcast("refreshLogoCollab");
            }
            else {
              ModalsService.alertErreur('Erreur du retour de l\'upload');
              console.log('fileItem',obj.fileItem);
              console.log('response',obj.response);
            }
          }
        }

        // Notifie que tous les upload sont terminés
        //if(obj.action === 'onCompleteAll') _this.currentUploader.onCompleteAll();

      }
      catch (err) {
        console.log('[AddDocumentsController.onEmitFromUploadAutresCollab] Erreur : ' + err.message);
      }
    }

    /**
     * Lors d'une remontée d'info des composant groupe-parametre-checkbox
     * @param obj
     */
    function onEmitFromGroupeParametreCheckbox(obj){
      var deferred = $q.defer();
      try {
        if (!obj || !_.isObject(obj)) throw new Error('Pas d\'obj ou pas un object !');
        if (_.isNil(obj.action)) throw new Error('Pas d\'action dans le paramètre "obj" !');
        if (_.isNil(obj.property)) throw new Error('Pas de "property" dans le paramètre "obj" !');

        if(obj.action === 'changeValue' && !_.isNil(obj.value)){
          updateOneParametre(obj.property, obj.value)
            .finally(function(){
              deferred.resolve();
            });
        }
        else if(obj.action === 'reset') {
          resetParametre(obj.property)
            .finally(function(){
              deferred.resolve();
            });
        }
        else deferred.resolve();
      }
      catch (err) {
        ModalsService.alertErreur(err.message);
        deferred.reject(err.message);
      }
      return deferred.promise;
    }

    /**
     * Lors d'une remontée d'info des composant groupe-parametre-checkbox
     * @param obj
     */
    function onEmitFromGroupeParametreComfactCheckbox(obj){
      var deferred = $q.defer();
      try {
        if (!obj || !_.isObject(obj)) throw new Error('Pas d\'obj ou pas un object !');
        if (_.isNil(obj.action)) throw new Error('Pas d\'action dans le paramètre "obj" !');
        if (_.isNil(obj.property)) throw new Error('Pas de "property" dans le paramètre "obj" !');

        if(obj.action === 'changeValue' && !_.isNil(obj.value)){
          updateOneParametreComfact(obj.property, obj.value)
            .finally(function(){
              deferred.resolve();
            });
        }
        else if(obj.action === 'reset') {
          resetParametreComfact(obj.property)
            .finally(function(){
              deferred.resolve();
            });
        }
        else deferred.resolve();
      }
      catch (err) {
        ModalsService.alertErreur(err.message);
        deferred.reject(err.message);
      }
      return deferred.promise;
    }
  }
})();
