(function () {
  "use strict";

  /**
   * @desc Composant permetant d'affecter des montants sur des comptes
   * @example <affectation-comptes></affectation-comptes>
   */
  angular.module("collaboreApp").component("affectationComptes", {
    templateUrl:
      "app/components/affectation-comptes/affectation-comptes.component.html",
    bindings: {
      montant: "<",
      portefeuille: "<",
      contrat: "<",
      numImmeuble: "<",
      libelleImmeuble: "<",
      portefeuilleComfact: "<",
      typeMetier: "<",
      fournisseur: "<",
      onEmit: "&", // Permet de notifier le composant parent
    },
    controllerAs: "affectationcomptesctrl",
    controller: AffectationComptesController,
  });

  /*@ngInject*/
  function AffectationComptesController(
    $q,
    $scope,
    $timeout,
    CompteService,
    ICSCloudService,
    ModalsService,
    ErreurCollabService
  ) {
    var _this = this;
    var saisieContratReady = false;
    var saisieAutreReady = false;
    _this.reste = 0;
    _this.loadingContratModeles = false;
    _this.loadingCalcReste = false;
    _this.listCompteByBatiment = [];
    _this.listSelectedCompte = [];
    _this.onClickOpenPlanModel = onClickOpenPlanModel;
    _this.onClickRefreshContrat = onClickRefreshContrat;
    _this.onClickVentilContrat = onClickVentilContrat;
    _this.onClickResetMontantContrat = onClickResetMontantContrat;
    _this.onChangeContratMontantField = onChangeContratMontantField;
    _this.onEmitFromChooseCompte = onEmitFromChooseCompte;
    _this.onEmitFromSaisieCompte = onEmitFromSaisieCompte;

    var listernerGetListModele = $scope.$on(
      "getListCompteByRegroupement",
      function (event, promise) {
        promise.resolve({
          listSelectedCompte: listCompteByRegroupementToListSelectedCompte(),
          reste: _this.reste,
        });
      }
    );
    var listernerClearSelectedModeles = $scope.$on(
      "clearSelectedContratModeles",
      function () {
        if (_.isObject(_this.contrat)) {
          _this.contrat.listBatiment = [];
        }

        $timeout(function(){
          execCalcul();
        });
      }
    );

    _this.$onInit = function () {
      init();
      emit({ action: "onReady", ready: true });
    };

    /**
     * Lors de changements depuis le parent
     * @param changes
     */
    _this.$onChanges = function (changes) {
      //console.log("affectationComptes", changes);
      if (_.isObject(changes)) {
        init();
        // Si changement d'immeuble
        if (
          _.isObject(changes.numImmeuble) &&
          !changes.numImmeuble.isFirstChange()
        ) {
          var oldImm = changes.numImmeuble.previousValue;
          // Si on change d'immeuible alors on vire les comptes de l'ancien immeuble
          if (_this.numImmeuble !== oldImm) {
            if (
              _.isArray(_this.listSelectedCompte) &&
              _this.listSelectedCompte.length
            ) {
              _.remove(_this.listSelectedCompte, function (compte) {
                return compte.getIIII() == oldImm;
              });
              listSelectedCompteToListCompteByRegroupement();
            }
          }
        }

        // Si changement du montant alors on recalcul le reste
        if (_.isObject(changes.montant) && !changes.montant.isFirstChange()) {
          $timeout(function(){
            execCalcul(true);
          });
        }

        if (_.isObject(changes.contrat) && !_.isNil(_this.contrat)) {
          searchModelesForContrat();
        }
      }
    };

    _this.$onDestroy = function () {
      if (!_.isNil(listernerGetListModele)) listernerGetListModele();
      if (!_.isNil(listernerClearSelectedModeles))
        listernerClearSelectedModeles();
    };

    function init() {
      if (_.isNil(_this.portefeuille)) {
        ModalsService.alertErreur("Pas de portefeuille");
        return;
      }
      if (_.isNil(_this.numImmeuble)) _this.numImmeuble = "0000";
    }

    /**
     * Permet de calculer le reste de l'argent à saisir
     */
    function calculReste() {
      if (_.isObject(_this.contrat)) {
        _this.contrat.reste = CompteService.getReste(
          _this.montant,
          _this.contrat.listBatiment
        );

        _this.reste = CompteService.getReste(
          _this.contrat.reste,
          _this.listCompteByBatiment
        );
      } else {
        _this.reste = CompteService.getReste(
          _this.montant,
          _this.listCompteByBatiment
        );
      }
      listCompteByRegroupementToListSelectedCompte();
    }

    function execCalcul(force) {
      if (
        _this.listCompteByBatiment.length ||
        (_.isObject(_this.contrat) &&
          _.isArray(_this.contrat.listBatiment) &&
          _this.contrat.listBatiment.length)
      ) {

        var execs = [];
        if (
          _.isObject(_this.contrat) &&
          _.isArray(_this.contrat.listBatiment) &&
          _this.contrat.listBatiment.length
        ) {
          // Si la base de repartition est à 0 on pass en ventil classique
          if(!_this.contrat.source.hasBasRep()) {
            execs.push(function() {
              var deferred = $q.defer();
              $scope.$broadcast("ventil", deferred, null);
              return deferred.promise;
            });
          }
          else{
            execs.push(function() {
              var deferred = $q.defer();
              $scope.$broadcast("ventilContrat", deferred, force);
              return deferred.promise;
            });
          }

        }
        if(_this.listCompteByBatiment.length) {
          execs.push(function() {
            var deferred = $q.defer();
            $scope.$broadcast("ventil", deferred, null);
            return deferred.promise;
          });
        }

        if(execs.length){
          _this.loadingCalcReste = true;

          if (!_.isNil(_this.montant) && _.isObject(_this.contrat)) {
            _this.contrat.cumulMontant = CompteService.getMontant(
              _this.contrat.listBatiment
            );

            if(force) {
              _this.contrat.montant = _this.montant;
              _this.contrat.cumulMontant = 0
            }
            else{

              // Contrat pas modifié à la main
              if (!_this.contrat.edited) {
                // Cumule du montant des autres comptes que ceux du contrat
                var cummulMontantComptesHormisContrat = CompteService.getMontant(
                  _this.listCompteByBatiment
                );
                // Calcul du nouveau montant du contrat (montant - le cumul des autre comptes)
                _this.contrat.montant = math.subtract(
                  _this.montant,
                  cummulMontantComptesHormisContrat
                );
              }

            }

          }

          $q.serial(execs)
          .then(function () {
            calculReste();
            emit({ action: "resteMontant", reste: _this.reste });
          })
          .catch(function (msg) {
            console.error(msg);
          })
          .finally(function(){
            _this.loadingCalcReste = false;
          });
        }
      }
      else{
        calculReste();
      }
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour) {
      _this.onEmit({ obj: objRetour });
    }

    function onClickOpenPlanModel() {
      var options_modal = {
        size: "full",
        windowClass: "modal-collabore modal-collabore-full",
        backdrop: "static",
      };

      var params = {
        portefeuille: _this.portefeuille,
        numImmeuble: _this.numImmeuble,
        libelleImmeuble: _this.libelleImmeuble,
        listSelectedCompte: listCompteByRegroupementToListSelectedCompte(),
        portefeuilleComfact: _this.portefeuilleComfact,
        typeMetier: _this.typeMetier,
        fournisseur: _this.fournisseur,
        contrat: _this.contrat,
      };

      ModalsService.modalComponent("chooseModeleModal", params, options_modal)
        .then(function (obj) {
          if (_.isArray(obj.selectedComptes) && obj.selectedComptes.length) {
            var comptesOfContrat = [];
            if (_.isObject(_this.contrat)) {
              comptesOfContrat = CompteService.extractComptes(
                _this.contrat.listBatiment,
                comptesOfContrat
              );
            }

            // On reset la liste car il doit y avoir toute la selection dans le retour de la modal
            _this.listSelectedCompte = comptesOfContrat.length
              ? angular.copy(comptesOfContrat)
              : [];

            //console.log(obj.selectedComptes);
            obj.selectedComptes.map(function (compte) {
              addCompte(compte);
            });
          }
          listSelectedCompteToListCompteByRegroupement();
        })
        .catch(function (msg) {
          console.log(msg);
        });
    }

    function onClickRefreshContrat() {
      if (_.isObject(_this.contrat)) {
        searchModelesForContrat();
      }
    }

    function onClickVentilContrat() {
      if(_.isObject(_this.contrat)) {
        delete _this.contrat.edit;
        $timeout(function(){
          execCalcul(true);
        });
      }
    }

    /**
     * Permet de réattribuer le montant TTC au contrat
     */
    function onClickResetMontantContrat() {
      if(!_.isObject(_this.contrat))
      CompteService.execOnEachCompte(
        _this.listCompteByBatiment,
        function (compte) {
          delete compte.montant;
          delete compte.montantCorrection;
          delete compte.edited;
        }
      );
      delete _this.contrat.edited;
      $timeout(function(){
        execCalcul(true);
      });
    }

    function onChangeContratMontantField() {
      if (_this.contrat.montant > _this.montant)
        _this.contrat.montant = angular.copy(_this.montant);
      if (math.isNegative(_this.contrat.montant)) _this.contrat.montant = 0;
      _this.contrat.edited = true;
      $timeout(function(){
        execCalcul();
      });
    }

    /**
     * Permet de récupérer les modèles qui concernent le contrat séléctionné
     * @param {*} contrat
     */
    function searchModelesForContrat() {
      var paramsSearch = {
        //numImmeuble: _this.numImmeuble,
        numImmeuble: _this.contrat.numImm,
        portefeuille: _this.portefeuille,
        refContrat: _this.contrat.value
      };

      _this.loadingContratModeles = true;
      ICSCloudService.getPlanModele(paramsSearch)
        .then(function (planModele) {
          if (
            _.isObject(_this.contrat) &&
            _.isObject(planModele) &&
            _.isArray(planModele.listBatiment)
          ) {
            _this.contrat.montant = _this.montant;
            _this.contrat.listBatiment = CompteService.initContrattab(
              planModele.listBatiment,
              _this.contrat.value
            );
          }
        })
        .finally(function () {
          _this.loadingContratModeles = false;
        });
    }

    /**
     * Permet d'ajouter un compte à la liste des comptes selectionné
     * @param {*} compte
     */
    function addCompte(compte) {
      if (!_.isArray(_this.listSelectedCompte)) _this.listSelectedCompte = [];
      if (
        _.findIndex(_this.listSelectedCompte, {
          CCCCCC: compte.getCCCCCC(),
          BB: compte.getBB(),
        }) === -1
      )
        _this.listSelectedCompte.push(compte);
    }

    /**
     * Créé une hiérarchie à partir de la liste de compte "_this.listSelectedCompte"
     */
    function listSelectedCompteToListCompteByRegroupement() {
      _this.listCompteByBatiment =
        CompteService.listCompteToListCompteByRegroupement(
          _this.listSelectedCompte
        );
    }

    /**
     * Converti la hierarchie par batiment en simple liste de compte
     * @returns
     */
    function listCompteByRegroupementToListSelectedCompte() {
      var selectedCompte = [];
      if (_.isObject(_this.contrat)) {
        selectedCompte =
          CompteService.listCompteByRegroupementToListSelectedCompte(
            selectedCompte,
            _this.contrat.listBatiment
          );
      }
      selectedCompte =
        CompteService.listCompteByRegroupementToListSelectedCompte(
          selectedCompte,
          _this.listCompteByBatiment
        );

      _this.listSelectedCompte = selectedCompte;

      return _this.listSelectedCompte;
    }

    function onEmitFromChooseCompte(obj) {
      //console.log(obj);
      try {
        if (!_.isObject(obj))
          throw new Error('Le paramètre "obj" doit être un objet');

        if (obj.action === "selectCompte" && _.isObject(obj.compte)) {
          addCompte(obj.compte);
          listSelectedCompteToListCompteByRegroupement();
        } else if (
          obj.action === "selectListCompte" &&
          _.isArray(obj.comptes) &&
          obj.comptes.length
        ) {
          for (var c = 0; c < obj.comptes.length; c++) {
            addCompte(obj.comptes[c]);
          }

          listSelectedCompteToListCompteByRegroupement();
        }

        if(saisieAutreReady) {
          $timeout(function(){
            execCalcul();
          });
        }
      }
      catch (err) {
        ErreurCollabService.logErreur(
          "[AffectationComptesController.onEmitFromChooseCompte] - " +
            err.message
        );
      }
    }

    function onEmitFromSaisieCompte(obj) {
      //console.log(obj);
      try {
        if (!_.isObject(obj))
          throw new Error('Le paramètre "obj" doit être un objet');

        if(obj.ready){
          if(obj.mode === 'contrat') saisieContratReady = true;
          else saisieAutreReady = true;
        }
        else if(obj.destroy){
          if(obj.mode === 'contrat') saisieContratReady = false;
          else saisieAutreReady = false;
        }

        if(obj.action === 'removeCompte' && _.isObject(_this.contrat) && _.isArray(_this.contrat.listBatiment) && !_this.contrat.listBatiment.length){
          searchModelesForContrat();
          return;
        }

        execCalcul((obj.action === 'onReady' && obj.mode === 'contrat'));
        //execCalcul(obj.mode === 'contrat');
      }
      catch (err) {
        ErreurCollabService.logErreur(
          "[AffectationComptesController.onEmitFromSaisieCompte] - " +
            err.message
        );
      }
    }
  }
})();
