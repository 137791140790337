(function () {
  'use strict';

  /**
   * @ngdoc function
   * @name collaboreApp.controller:ParamsCtrl
   * @description
   * # ParamsCtrl
   * Controller of the collaboreApp
   */

  angular
    .module('collaboreApp')
    .controller('ParamsCtrl', ParamsCtrl);

  /*@ngInject*/
  function ParamsCtrl($state, UtilsService, GroupesAdminService){

    var vm = this;

    vm.modeSignature = 'viewer';

    /*
         vm.listTabIndex = {
         'mon_compte': 0,
         'gestion_groupes': 1,
         'modules': 2,
         'autres': 3
         };

         vm.activeTab = vm.listTabIndex.mon_compte;*/

    //vm.tabActive = 0;
    vm.tabMonCompteActive = false;
    vm.tabGestionMesGroupesActive = false;
    vm.tabGestionGroupeActive = false;
    vm.tabGestionPortefeuillesActive = false;
    vm.tabGestionModuleActive = false;
    vm.tabAutresActive = false;
    vm.tabGestionGroupeAvanceActive = false;
    vm.tabParametrageSociete = false;

    switch($state.current.name) {
      case 'params.mes_groupes':
        vm.tabGestionMesGroupesActive = true;
        vm.tabActive = 1;
        break;
      case 'params.gestion_groupes':
        vm.tabGestionGroupeActive = true;
        vm.tabActive = 2;
        break;
      case 'params.modules':
        vm.tabGestionModuleActive = true;
        vm.tabActive = 3;
        break;

      case 'params.gestion_portefeuilles':
        vm.tabGestionPortefeuillesActive = true;
        vm.tabActive = 4;
        break;

      case 'params.autres':
        vm.tabAutresActive = true;
        vm.tabActive = 5;
        break;
      case 'params.gestion_groupes_avance':
        vm.tabGestionGroupeActive = true;
        vm.tabActive = 6;
        break;
      case 'params.parametrageSociete':
        vm.tabParametrageSociete = true;
        vm.tabActive = 7;
        break;
      default:
        vm.tabActive = 0;
        vm.tabMonCompteActive = true;
    }

    vm.selectTab = function(nom){
      /*
             if(vm.listTabIndex.hasOwnProperty(nom)){
             var indexTmp = vm.listTabIndex[nom];
             if(indexTmp!==vm.activeTab){
             vm.activeTab = indexTmp;
             if(nom==='gestion_groupes'){
             $timeout(function(){
             $scope.$broadcast('getGroupes');
             });
             }
             }

             }else{
             vm.activeTab = 0;
             }*/

      vm.tabMonCompteActive = false;
      vm.tabGestionGroupeActive = false;
      vm.tabGestionModuleActive = false;
      vm.tabGestionPortefeuillesActive = false;
      vm.tabGestionMesGroupesActive = false;
      vm.tabAutresActive = false;
      vm.tabGestionGroupeAvanceActive = false;
      vm.tabParametrageSociete = false;

      switch(nom){
        case 'mon_compte':
          vm.tabMonCompteActive = true;
          break;
        case 'gestion_groupes':
          vm.tabGestionGroupeActive = true;
          break;
        case 'gestion_portefeuilles':
          vm.tabGestionPortefeuillesActive = true;
          break;
        case 'modules':
          vm.tabGestionModuleActive = true;
          break;
        case 'mes_groupes':
          vm.tabGestionMesGroupesActive = true;
          break;
        case 'autres':
          vm.tabAutresActive = true;
          break;
        case 'gestion_groupes_avance':
          vm.tabGestionGroupeAvanceActive = true;
          break;
        case 'societe':
          vm.tabParametrageSociete = true;
          break;

      }
    };

    vm.isAdmin = UtilsService.getCurrentUser().isAdmin();
    vm.isSuperAdmin = UtilsService.getCurrentUser().isSuperAdmin();

    if(vm.isAdmin || vm.isSuperAdmin){
      vm.groupesAdminService = GroupesAdminService;
    }
  }

})();
