(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.DevisService
   * @description
   * # DevisService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('DevisService', DevisService);

  /*@ngInject*/
  function DevisService(MainService, $q, DevisAjax, RunJobResource, ModalsService) {

    var ctrl = this;

    ctrl.updateDevisWithProxyDevis = updateDevisWithProxyDevis;

    ctrl.updateFieldByField = updateFieldByField;

    ctrl.updateDocumentDevis = updateDocumentDevis;
    ctrl.deleteDocumentDevis = deleteDocumentDevis;

    ctrl.saveRelance = saveRelance;
    ctrl.doRelance = doRelance;
    ctrl.duplicateDevis = duplicateDevis;
    ctrl.regenerateDocument = regenerateDocument;
    ctrl.regenerateDocumentAttestationTvaSimple = regenerateDocumentAttestationTvaSimple;

    ctrl.accepteDevisBySyndic = accepteDevisBySyndic;
    ctrl.refuseDevisBySyndic = refuseDevisBySyndic;

    ctrl.deleteDocumentByGuid = deleteDocumentByGuid;
    ctrl.deleteDocumentFromFacture = deleteDocumentFromFacture;
    ctrl.retryDevis = retryDevis;


    function postServlet(action, obj) {
      var deferred = $q.defer();
      try {
        if (!action) throw new Error('Le paramètre "action" n\'existe pas !');

        if (!angular.isObject(obj) && !angular.isNumber(obj)) throw new Error('Le paramètre "obj" doit être un objet un un nombre !');
        if (angular.isObject(obj) && !obj.hasOwnProperty('idDevis') && !obj.hasOwnProperty('idContenu')) throw new Error('Le paramètre "obj" doit avoir un idDevis ou idContenu !');

        var sendParams = angular.merge({}, obj);
        sendParams.action = action;
        sendParams.idDevis = (angular.isNumber(obj)) ? obj : ((obj.hasOwnProperty('idDevis')) ? obj.idDevis : obj.idContenu);
        if(sendParams.hasOwnProperty('idContenu')) delete sendParams.idContenu;

        DevisAjax
          .post(sendParams, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, ['devis', 'listDevis'], 'ContenuEvenementDevis', deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /* Public Methods */

    /**
     * Permet de créer un devis dans un événement
     * @param obj
     * @returns {IPromise<T>}
     */
    /*
    function createDevisInEvenement(obj) {
        var deferred = $q.defer();
        try {
            if (!angular.isObject(obj)) throw new Error('Le paramètre n\'est pas un objet !');
            DevisAjax
                .createDevisInEvenement(obj, function (json) {
                    deferred.resolve(MainService.convertJsonToModel(json, 'listDevis', ContenuEvenementDevis, deferred));
                }, function (msg) {
                    deferred.reject(msg);
                });
        }
        catch (e) {
            ModalsService.alertErreur(e.message);
            deferred.reject(e.message);
        }
        return deferred.promise;
    }
    */
    /**
     * Permet de modifier un devis avec proxyDevis
     * @param obj
     * @returns {IPromise<T>}
     */
    function updateDevisWithProxyDevis(obj) {
      var deferred = $q.defer();
      try {
        if (!angular.isObject(obj)) throw new Error('Le paramètre n\'est pas un objet !');
        DevisAjax
          .updateDevisWithProxyDevis(obj, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, ['listDevis','devis'], 'ContenuEvenementDevis', deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    // --- NEW ---

    /**
     * Permet de modifier un devis champ par champ
     * @param obj
     * @returns {IPromise<T>}
     */
    function updateFieldByField(obj) {
      var deferred = $q.defer();
      postServlet('update', obj)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet d'accepter un devis
     * @param obj
     * @returns {IPromise<T>}
     */
    function accepteDevisBySyndic(obj) {
      var deferred = $q.defer();
      postServlet('accepteDevisBySyndic', obj)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de refuser un devis
     * @param obj
     * @returns {IPromise<T>}
     */
    function refuseDevisBySyndic(obj) {
      var deferred = $q.defer();
      postServlet('refuseDevisBySyndic', obj)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de sauvegarder une relance
     * @param idDevis
     * @param idPeriode
     * @param enabled
     * @returns {angular.IPromise<T>}
     */
    function saveRelance(idDevis, idPeriode, enabled, relanceFromToday, doRelance){
      var deferred = $q.defer();
      var obj = {
        idDevis: idDevis,
        idPeriode: idPeriode,
        relanceAutoEnabled: (_.isNil(enabled)) ? false : enabled,
        relanceFromToday: (_.isNil(relanceFromToday)) ? false : relanceFromToday,
        doRelance: doRelance
      };
      postServlet('saveRelance', obj)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de faire une relance d'un devis
     * @param obj
     * @returns {IPromise<T>}
     */
    function doRelance(idDevis) {
      var deferred = $q.defer();
      postServlet('doRelance', idDevis)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de dupliquer un devis avec une description ou non
     * @param idDevis
     * @param description
     */
    function duplicateDevis(idDevis, obj) {
      var deferred = $q.defer();
      var params = {
        idDevis: idDevis
      };

      params.descriptionDevis = null;
      params.sendMailWhenCreateDemande = false;
      if(_.isString(obj)) params.descriptionDevis = obj;
      else if(_.isObject(obj)) {
        if(obj.hasOwnProperty('description')) params.descriptionDevis = obj.description;
        if(obj.hasOwnProperty('sendMailWhenCreateDemande')) params.sendMailWhenCreateDemande = obj.sendMailWhenCreateDemande;
        if(obj.hasOwnProperty('hideProprietaireInDocument')) params.hideProprietaireInDocument = obj.hideProprietaireInDocument;
      }

      postServlet('duplicateDevis', params)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de regénérer le document demande
     * @param obj
     * @returns {IPromise<T>}
     */
    function regenerateDocument(idDevis, demandeOptions) {
      var deferred = $q.defer();
      var params = {
        idDevis: idDevis
      };
      if(demandeOptions != null) {
        params.hideProprietaireInDocument = demandeOptions.hideProprietaireInDocument;
      }
      postServlet('regenerateDocument', params)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de regénérer le document tva simplifié
     * @param obj
     * @returns {IPromise<T>}
     */
    function regenerateDocumentAttestationTvaSimple(idDevis) {
      var deferred = $q.defer();
      postServlet('regenerateDocumentAttestationTvaSimple', idDevis)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de change de documentdevis
     * @param obj
     * @returns {IPromise<T>}
     */
    function updateDocumentDevis(obj) {

      var deferred = $q.defer();
      postServlet('changeDocumentDevis', obj)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de supprimer le documentdevis
     * @param obj
     * @returns {IPromise<T>}
     */
    function deleteDocumentDevis(idDevis) {
      var deferred = $q.defer();
      postServlet('deleteDocumentDevis', idDevis)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de supprimer un document du devis
     * @param obj
     * @returns {IPromise<T>}
     */
    function deleteDocumentByGuid(obj) {
      var deferred = $q.defer();
      postServlet('deleteDocumentByGuid', obj)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de supprimer un document de la facture du devis
     * @param idDevis
     * @returns {Promise}
     */
    function deleteDocumentFromFacture(idDevis) {
      var deferred = $q.defer();
      postServlet('deleteDocumentFacture', idDevis)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de relancer la créaiton ou la modification d'un devis si échoué
     * @param id
     */
    function retryDevis(id) {
      var deferred = $q.defer();
      try {
        if (!angular.isNumber(id)) throw new Error('Le paramètre n\'est pas numérique !');
        var param = {
          idDevis: id
        };
        RunJobResource
          .runJobDevis(param, function (json) {
            if(json.success) deferred.resolve(true);
            else deferred.reject('Il y a eu un problème lors de la relance de création ou modification du devis N° ' + id + '<br />' + json.message);
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }


  }

})();
