(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.Reference
   * @description
   * # Reference
   * Service in the collaboreApp.
   */

  /*
     Si ajout de référence ne pas oublier de l'ajouter également dans "FichesService"
     Et dans "Fiche"
     */
  angular.module('collaboreApp').factory('Reference', ReferenceModel);

  /** @ngInject */
  function ReferenceModel(Main) {

    function Reference(objetData) {


      if(angular.isString(objetData)){
        switch(objetData) {
          case 'nom':
            objetData = Reference.NOM;
            break;
          case 'adresse':
            objetData = Reference.ADRESSE;
            break;
          case 'codePostal':
            objetData = Reference.CODE_POSTAL;
            break;
          case 'ville':
            objetData = Reference.VILLE;
            break;
          case 'telephone':
            objetData = Reference.TELEPHONE;
            break;
          case 'portable':
            objetData = Reference.PORTABLE;
            break;
          case 'email':
            objetData = Reference.EMAIL;
            break;
          case 'fax':
            objetData = Reference.FAX;
            break;
          case 'commentaire':
            objetData = Reference.COMMENTAIRE;
            break;
          case 'code_postal':
            objetData = Reference.CODE_POSTAL;
            break;
          case 'ville':
            objetData = Reference.VILLE;
            break;
          case 'portable_info':
            objetData = Reference.PORTABLE_INFO;
            break;
          default:
        }
        /*
        if(objetData==='email'){
            objetData = {
                'idReference': 2,
                'type': 'Email',
                'isReference': true
            };
        }*/
      }
      Main.apply(this, arguments);
      this.hydrate(objetData);
    }

    Reference.prototype = {

      model: 'Reference',
      isReference: true,

      setData: function(objetData) {
        angular.extend(this, objetData);
      },

      // Id référence
      getIdReference: function(){
        return this.idReference;
      },
      setIdReference: function(id){
        this.idReference = id;
      },

      // Type de référence
      getType: function(){
        return this.type;
      },
      setType: function(valTypeReference){
        this.type = valTypeReference;
      },



      isPortable: function(){
        var retour = false;
        if(this.getIdReference()===1){
          retour= true;
        }
        return retour;
      },

      isPortableInfo: function(){
        var retour = false;
        if(this.getIdReference()===11){
          retour= true;
        }
        return retour;
      },

      isEmail: function(){
        var retour = false;
        if(this.getIdReference()===2){
          retour= true;
        }
        return retour;
      },

      isCommentaire: function(){
        var retour = false;
        if(this.getIdReference()===3){
          retour= true;
        }
        return retour;
      },

      isPrenom: function(){
        var retour = false;
        if(this.getIdReference()===4){
          retour= true;
        }
        return retour;
      },

      isNom: function(){
        var retour = false;
        if(this.getIdReference()===5){
          retour= true;
        }
        return retour;
      },

      isTelephone: function(){
        var retour = false;
        if(this.getIdReference()===6){
          retour= true;
        }
        return retour;
      },

      isAdresse: function(){
        var retour = false;
        if(this.getIdReference()===7){
          retour= true;
        }
        return retour;
      },

      isFax: function(){
        var retour = false;
        if(this.getIdReference()===8){
          retour= true;
        }
        return retour;
      },

      isCodePostal: function(){
        var retour = false;
        if(this.getIdReference()===9){
          retour= true;
        }
        return retour;
      },

      isVille: function(){
        var retour = false;
        if(this.getIdReference()===10){
          retour= true;
        }
        return retour;
      }
    };

    Reference.PORTABLE = {
      idReference: 1,
      type: 'Portable',
      categorie: 'telephone'
    };

    Reference.EMAIL = {
      idReference: 2,
      type: 'Email',
      categorie: 'email'
    };

    Reference.COMMENTAIRE = {
      idReference: 3,
      type: 'Commentaire',
      categorie: 'commentaire'
    };

    Reference.PRENOM = {
      idReference: 4,
      type: 'Prénom',
      categorie: 'prenom'
    };

    Reference.NOM = {
      idReference: 5,
      type: 'Nom',
      categorie: 'nom'
    };

    Reference.TELEPHONE = {
      idReference: 6,
      type: 'Téléphone',
      categorie: 'telephone'
    };

    Reference.ADRESSE = {
      idReference: 7,
      type: 'Adresse',
      categorie: 'adresse'
    };

    Reference.FAX = {
      idReference: 8,
      type: 'Fax',
      categorie: 'fax'
    };

    Reference.CODE_POSTAL = {
      idReference: 9,
      type: 'Code Postal',
      categorie: 'codePostal'
    };

    Reference.VILLE = {
      idReference: 10,
      type: 'Ville',
      categorie: 'ville'
    };

    Reference.PORTABLE_INFO = {
      idReference: 11,
      type: 'Portable Info',
      categorie: 'telephone'
    };

    Reference.listReference = [
      Reference.PORTABLE,
      Reference.EMAIL,
      Reference.COMMENTAIRE,
      Reference.PRENOM,
      Reference.NOM,
      Reference.TELEPHONE,
      Reference.ADRESSE,
      Reference.FAX,
      Reference.CODE_POSTAL,
      Reference.VILLE,
      Reference.PORTABLE_INFO
    ];
    angular.extend(Reference.prototype, Main.prototype);

    return Reference;
  }
})();
