(function() {

  'use strict';

  /**
   * @desc Composant de template pour les textarea
   * @example <template-textarea></template-textarea>
   */
  angular
    .module('collaboreApp')
    .component('templateTextarea',{
      templateUrl: 'app/components/template-textarea/template-textarea.component.html',
      controllerAs: 'templatetextareactrl',
      bindings: {
        typeTemplate: '@',
        currentTemplate: '<',
        onEmit: '&'
      },
      controller: TemplateTextareaCtrl
    });

  /** @ngInject */
  function TemplateTextareaCtrl(UtilsService, ModalsService){
    var _this = this;
    _this.templates = [];
    _this.onChangeTemplate = onChangeTemplate;

    _this.$onInit = function() {
      if(!_this.typeTemplate)  ModalsService.alertErreur('Il manque le type de template pour le composant "templateTextarea" !');
      _this.templates = UtilsService.getModulesTemplatesObjet(_this.typeTemplate);
    };

    var prevTemplate = null,
      prevMessage = null;
    /**
     * Lors de la selection du model dans le champ select des templates
     */
    function onChangeTemplate() {

      var message = _this.raisonCloture;

      if (!prevTemplate) prevMessage = angular.copy(message);
      else if (prevTemplate.objet === '') prevMessage = angular.copy(message);

      var found = false;

      // Si un model est selectionné et qu'il y a des templates
      if (_.isObject(_this.currentTemplate) && _.isArray(_this.templates) && _this.templates.length) {
        // Pour chaque model
        for (var m = 0; m < _this.templates.length; m++) {
          var currentModel = _this.templates[m];

          // Si le model de la boucle en cours est égale au model choisi
          if (_this.currentTemplate.idModuleValeurParams === currentModel.idModuleValeurParams) {
            // Si il y a un objet
            if (currentModel.objet !== '') {
              message = angular.copy(currentModel.objet);
              found = true;
            }
            break;
          }
        }
      }

      // Si pas de model trouvé lors du changement et qu'il y avait un model choisi avant de changer
      if(!found && _.isObject(prevTemplate) && !_.isNil(prevTemplate.objet) && prevTemplate.objet !== '') {
        message = '';
        if (!_.isNil(prevMessage)) {
          message = prevMessage;
        }
      }
      prevTemplate = angular.copy(_this.currentTemplate);
      _this.onEmit({obj: {text: message}});
    }

  }
})();
