(function() {

  'use strict';

  /**
   * @desc Composant de la Mailbox
   * @example <mailbox></mailbox>
   */
  angular
    .module('collaboreApp')
    .component('mailbox',{
      templateUrl: 'app/mailbox/mailbox.component.html',
      controllerAs: 'mailboxctrl',
      controller: MailboxCtrl,
      bindings: {
        idMailbox: '<',
        superAdmin: '<'
      }
    });

  /** @ngInject */
  function MailboxCtrl($scope, NotificationService){
    var _this = this;

    _this.selectedMailbox = null;
    _this.nbChecked = 0;
    _this.currentView = 'inbox';
    _this.onClickInboxMail = onClickInboxMail;
    _this.onClickTrashedMail = onClickTrashedMail;
    _this.onClickRefresh = onClickRefresh;
    _this.onChooseMailboxFromList = onChooseMailboxFromList;
    _this.onCheckMailboxFromList = onCheckMailboxFromList;
    _this.onCloseDetailFromDetail = onCloseDetailFromDetail;
    _this.onOpenSearchTopicFromDetail = onOpenSearchTopicFromDetail;
    _this.onAcceptFromDetail = onAcceptFromDetail;
    _this.onDeleteFromDetail = onDeleteFromDetail;
    _this.onDeletedAttachementFromDetail = onDeletedAttachementFromDetail;

    _this.$onInit = function() {
      NotificationService.cleanListNotificationMail();
    };

    function refresh(){
      _this.selectedMailbox = null;
      $scope.$broadcast('actionToMailbox', 'refresh', null, _this.currentView);
    }

    function onClickInboxMail(){
      if(_this.currentView == 'inbox') return;
      _this.currentView = 'inbox';
      refresh();
    }

    function onClickTrashedMail(){
      if(_this.currentView == 'trashed') return;
      _this.currentView = 'trashed';
      refresh();
    }

    function onClickRefresh(){
      refresh();
    }

    /**
     * Lorsqu'on ferme le détail depuis le componsant de détail
     */
    function onCloseDetailFromDetail(){
      _this.selectedMailbox = null;
    }

    /**
     * Lorsqu'on choisi un email depuis le composant de list
     * @param {*} mailbox
     */
    function onChooseMailboxFromList(mailbox){
      _this.selectedMailbox = mailbox;
    }

    function onCheckMailboxFromList(listChecked){
      _this.nbChecked = listChecked.length;
    }

    function onOpenSearchTopicFromDetail(mailbox){
      $scope.$broadcast('actionToMailbox', 'openSearchTopic', mailbox);
    }

    function onAcceptFromDetail(mailbox){
      $scope.$broadcast('actionToMailbox', 'accept', mailbox);
    }

    function onDeleteFromDetail(mailbox){
      $scope.$broadcast('actionToMailbox', 'delete', mailbox);
    }

    function onDeletedAttachementFromDetail(mailbox, document){
      $scope.$broadcast('actionToMailbox', 'attachementDeleted', mailbox, document);
    }
  }
})();
