(function () {
    'use strict';

    /**
     * @desc Composant d'affiche d'infos d'un contenu en popover
     * @example <popup-infos-contenu</popup-infos-contenu>
     */

    angular
        .module('collaboreApp')
        .component('popupInfosContenu', {
            templateUrl: 'app/topics/components/popup-infos-contenu/popup-infos-contenu.component.html',
            bindings: {
                contenu: '<',
                shortLibelle: '<',
                noLibelle: '<',
                noLabelBordered: '<',
                noLabel: '<',
                onEmit: '&'
            },
            controllerAs: 'popupinfoscontenuctrl',
            controller: PopupInfosContenuController
        });

    /*@ngInject*/
    function PopupInfosContenuController($q, UtilsService) {

        var _this = this;
        _this.popoverOpen = false;
        _this.onClickVoirInfos = onClickVoirInfos;

        _this.$onInit = function () {
        };

        _this.$onChanges = function(changes){
            if(_.isObject(changes.contenu) && !changes.contenu.isFirstChange()){
                _this.contenu = changes.contenu.currentValue;
            }
        }

        /**
         * Lors du clique sur le bouton pour voir la fiche d'un groupe
         */
        function onClickVoirInfos(){
            _this.popoverOpen = false;
        }

        /**
         * Permet d'emit une information vers le parent
         * @param valObj
         */
        function emit(valObj) {
            var object = {obj: {}};
            object.obj = _.merge(object.obj, valObj);
            _this.onEmit(object);
        }
    }
})();
