(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name collaboreApp.ModalSendSmsCtrl
     * @description
     * # ModalSendSmsCtrl
     * Service in the collaboreApp.
     */

    angular
        .module('collaboreApp')
        .controller('ModalSendSmsCtrl', ModalSendSmsCtrl);

    /*@ngInject*/
    function ModalSendSmsCtrl($uibModalInstance, contenu, defaultParams){
        var ctrl = this;
        ctrl.loading = false;
        ctrl.contenu = contenu;

        defaultParams.communication = true;
        defaultParams.hauteurEditor = 100;
        defaultParams.destinataires = contenu.getDestinataires();
        defaultParams.destinatairesHide = (_.isObject(defaultParams) && _.isArray(defaultParams.destinatairesHide)) ? defaultParams.destinatairesHide : [];
        defaultParams.destinatairesExclus = (_.isObject(defaultParams) && _.isArray(defaultParams.destinatairesExclus)) ? defaultParams.destinatairesExclus : [];
        defaultParams.smsAdvance = true;
        defaultParams.sms = true;
        defaultParams.message = '';
        defaultParams.focus = true;

        ctrl.defaultParams = defaultParams;

        ctrl.onClickClose = onClickClose;
        ctrl.onSubmit = onSubmit;
        ctrl.onInsertContenuMessage = onInsertContenuMessage;

        /**
         * Ferme le modal
         */
        function onClickClose() {
            $uibModalInstance.dismiss('cancel');
        }

        function onSubmit() {
            ctrl.loading = true;
        }

        function onInsertContenuMessage(onInsert){
            ctrl.loading = false;
            $uibModalInstance.close((_.isObject(onInsert.insertContenu)) ? onInsert.insertContenu : onInsert);
        }
    }
})();
