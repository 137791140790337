'use strict';

var popoverTag = function () {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: 'app/scripts/tags/directives/popoverTag.html',
		scope: {
			from: '@',
			obj: '=',
			initTags: '=',
			typeTag: '@',
			currentModelEvenement: '=',
			allowAddTag: '@',
			onUpdate: '&'
			//showTags: '@',
			//showTagsInPop: '@'
		},
		link: function(scope,element,attrs){

			//scope.from = attrs.from || 'popoverTag';
			scope.libelle = attrs.libelle || false;
			scope.popoverPlacement = attrs.popoverPlacement || 'top';
			scope.popoverTitle = attrs.popoverTitle || attrs.libelle || 'Tag';

			scope.icon = attrs.icon || 'fa fa-tag';
			scope.from = scope.from || 'formulaireContenu';
			scope.showTags = (attrs.showTags==='false') ? false : true;
			scope.showTagsInPop = (attrs.showTagsInPop==='true') ? true : false;
			//scope.currentModelEvenement = (attrs.currentModelEvenement) ? attrs.currentModelEvenement : false;


			if(angular.isUndefined(scope.allowAddTag)){
				scope.allowAddTag = true;
			}else{
				if(scope.allowAddTag==='true') scope.allowAddTag = true;
				else scope.allowAddTag = false;
			}




			// Si limit tag
			scope.limitTag = parseInt(attrs.limitTag);


			var classPopover = 'popover-collabore';
			if(scope.typeTag){
				classPopover += ' popover-tag-type';
			}else{
				classPopover += ' popover-tag';
			}
			scope.classPopover = classPopover;
			/*

			scope.toggle = function() {
				$timeout(function() {
					element.triggerHandler(scope.openned ? 'close' : 'open');
					scope.openned = !scope.openned;
				});
			};

			return element.on('click', scope.toggle);
			*/
		},
		controller: ['$rootScope','$scope','$timeout','TagService','UtilsService','FiltreService', function($rootScope,$scope,$timeout,TagService,UtilsService,FiltreService){

			//console.log('yo');
			var vm = this;

			vm.suffixPopoverTitle = '';		// Suffix du titre du popover
			vm.modeleWithTags = false;		// Permet de savoir si un eventuel modèle d'événement utilise une liste de tags autorisées

			vm.paramsFiltreByTagType = false; // Paramètres pour le ui-sref du tag type

			if(angular.isObject($scope.currentModelEvenement)){
				if($scope.currentModelEvenement.tags.length){
					vm.modeleWithTags = true;
				}
			}

			$scope.popoverTags = {
				'isOpen': false,
				'toggle': function toggle(event){

					$scope.popoverTags.isOpen = !$scope.popoverTags.isOpen;

					if(event){
						event.stopPropagation();
					}
				},
				'close': function close(){
					$scope.popoverTags.isOpen = false;
				},
				'submit': function submit(){
					$scope.$broadcast('submitPopoverTags');
				}
			};
			//vm.popoverTags = $scope.popoverTags;

			/**
			 * Récupère dans "currentTags" les tags typé avec le string demandé
			 * @param type
			 * @returns {Array}


			 $scope.getTagsForType = function(type){
				var retour = [];
				var tagType = $scope.currentTags;

				// Si tags est un array
				if(angular.isArray(tagType)){
					// Si cet array est différent de 0
					if(tagType.length){

						// Pour chaque tagsType
						angular.forEach(tagType,function(tag){

							var TypeTag = tag.getTypeTag();

							if(angular.isObject(TypeTag)){
								var lib = TypeTag.getLibelle();

								if(lib===type){

									retour.push(tag);
								}
							}
						});
					}
				}
				return retour;
			};*/

			/**
			 * Recupère dans "currentTags" les tags différent du type demandé
			 * @param type
			 * @returns {Array}

			 $scope.getTagsWithoutType = function(type){
				var retour = [];
				var tagType = $scope.currentTags;

				// Si tags est un array
				if(angular.isArray(tagType)){
					// Si cet array est différent de 0
					if(tagType.length){

						// Pour chaque tagsType
						angular.forEach(tagType,function(tag){

							var TypeTag = tag.getTypeTag();

							if(angular.isObject(TypeTag)){
								var lib = TypeTag.getLibelle();

								if(lib!==type){

									retour.push(tag);
								}
							}
						});
					}
				}
				return retour;
			};*/


			var tags = [];
			if(!$scope.initTags){
				$scope.initTags = tags;
			}else{

				if($scope.typeTag){
					tags = TagService.getTagsForType($scope.initTags,$scope.typeTag);
				}else{
					tags = $scope.initTags;
				}
			}
			$scope.tmpTags = tags;


			/*
			$scope.$watch('currentTags',function(newVal,oldVal){
				$scope.tmpTags = newVal;
			},true);*/




			/*

			 // Init la liste des tags du formulaire de tags
			 // Si on demande un tag typé
			 if($scope.typeTag){
			 console.log('1');
			 console.log($scope.typeTag);
			 $scope.currentTags = $scope.obj.getTagsForType($scope.typeTag);
			 // Sinon tags normaux
			 }else{
			 console.log('2');
			 console.log($scope.typeTag);
			 $scope.currentTags = $scope.obj.tags;
			 }*/



			// Si on demande un tag typé
			/*
			 if(angular.isObject($scope.obj)){
			 if($scope.typeTag){
			 $scope.currentTags = $scope.obj.getTagsForType($scope.typeTag);
			 }else{
			 $scope.currentTags = $scope.obj.getTags();
			 }
			 }*/

			// Supprime un tag de la liste des tags
			/*
			$scope.deleteTag = function(tag){

				if($scope.from!=='formulaireContenu'){
					$scope.loading = true;

					//console.log(objTag);
					$scope.obj.deleteTag(tag,$scope.typeTag).then(function(){
						$scope.tmpTags = UtilsService.removeWith($scope.tmpTags,{idTag:tag.idTag});
						$scope.loading = false;

						// Emit l'événement 'onDelTag'
						$scope.$emit('onDelTag',tag);
					});
				}else{
					$scope.tmpTags = UtilsService.removeWith($scope.tmpTags,{libelle:tag.libelle});
				}

			};*/

			var listenInitTags = true;
			var listenTmpTags = true;

			/**
			 * Si tags mis à jours depuis l'hote qui appel la directive
			 */
			$scope.$watchCollection('initTags',function(newVal){

				//console.log('2');
				// Si "listenInitTags" est à true alors on change la valeur de "tmpTags"
				if(listenInitTags){


					/*console.log('-- initTags --');
					console.log('old',oldVal);
					console.log('newval',newVal);
					console.log($scope.typeTag);
					console.log('------------');*/

					listenTmpTags = false;

					if($scope.typeTag) {
						if (newVal.length) {
							var tagTypeExist = false;
							if (newVal[0].hasOwnProperty('idTag')) {
								vm.paramsFiltreByTagType = FiltreService.getObjFiltreByTagType(newVal[0]);
								tagTypeExist = true;
							}

							// Si la tag n'existe pas ou n'a pas d'idTag on ne peut pas filtrer dessus
							if (!tagTypeExist) {
								vm.paramsFiltreByTagType = false;
							}
						}
					}




					$scope.tmpTags = angular.copy(newVal);

					vm.closePopup(newVal);

					// Sinon on ne change pas la valeur de "tmpTags"
				}else{

					// Passe "listenInitTags" à true tant qu'il n'y a pas de changement sur "tmpTags"
					listenInitTags = true;
				}
			});

			/**
			 * Si tags mis à jours par "formulaireTags"
			 */
			$scope.$watchCollection('tmpTags',function(newVal) {

				//console.log('Tags modifé !');

				if(listenTmpTags){

					/*
					console.log('-- tmpTags --');
					console.log('old',oldVal);
					console.log('newval',newVal);
					console.log($scope.typeTag);
					console.log('------------');*/

					// Passe "listenInitTags" à false pour que lors de l'affectation de "initTags"
					// le listeners ne reste pas "tmpTags" ce qui provoquerai une boucle
					listenInitTags = false;
					$scope.initTags = angular.copy(newVal);

					vm.closePopup(newVal);
				}else{

					listenTmpTags = true;
				}

			});


			/**
			 * Permet de mettre à jour "initTags" lors de la création d'un evenement
			 * Car ne fonctionne pas comme le reste...
			 */
			/*
			$scope.$on('initTagType',function(event,tag){

				console.log(tag);
				var tagTypeExist = false;
				if(tag){
					if(tag.hasOwnProperty('idTag')){
						vm.paramsFiltreByTagType = FiltreService.getObjFiltreByTagType(tag);
						tagTypeExist = true;
					}
				}

				// Si la tag n'existe pas ou n'a pas d'idTag on ne peut pas filtrer dessus
				if(!tagTypeExist){
					vm.paramsFiltreByTagType = false;
				}

				$scope.initTags = angular.copy([tag]);
			});*/


			vm.closePopup = function(newVal){
				// Si il y a une limite de tag
				if($scope.limitTag){
					// Si la limit de tag est atteinte alors on ferme le popover
					if(newVal.length>=$scope.limitTag){


						 //var popoverElement = $element.find('.popover-collabore-tag');
						 //$timeout(function() {
						 //popoverElement.triggerHandler('close');
						 //});


						//$scope.$broadcast('closePopover');
						$scope.popoverTags.close();
					}
				}
			};

			vm.onUpdateTags = function(objUpdate){
				if($scope.onUpdate){
					$scope.onUpdate({'objUpdate':objUpdate});
				}
			};

			/**
			 * Appelé entre autres lors du focus sur le wysiwyg
			 */
			var listenerClosePopoverTag = $scope.$on('closePopoverTag',function(){
				$timeout(function(){
					$scope.popoverTags.close();
				});
			});

			$scope.$on('$destroy', function(){
                listenerClosePopoverTag();
			});
			/*
            $scope.$onDestroy = function(){
            	console.log('close');
                listenerClosePopoverTag();
			};*/

			/*

			if(angular.isString($scope.typeTag)){

				// Lors d'un changement dans le tableau "tmpTags"
				// Permet de mettre à jour "currentTags" selon le cas
				// Pour repercuter les changement dans le scope qui appel la directive "popoverTag"
				$scope.$watch('tmpTags',function(newVal,oldVal){

					console.log('-- tmpTags --');
					console.log(oldVal);
					console.log(newVal);
					console.log($scope.typeTag);
					console.log('------------');



					// Si tag typé
					if($scope.typeTag){
						var otherTagsType = TagService.getTagsWithoutType($scope.currentTags,$scope.typeTag);
						var tagsType = newVal;
						tagsType.concat(otherTagsType);
						$scope.currentTags = tagsType;
					}else{
						$scope.currentTags = newVal;
					}
				},true);

			}*/


			/************* Listerners ***************/

			/**
			 * Ecoute la sauvegarde d'un tag type
			 * Les tags typé n'utilise pas "currentTags"
			 */

			/*
			$scope.$on('onAddTagFromPopover',function(event,tags,type){
				console.log('onAddTagFromPopover');

				// Si le type du tag sauvegardé est bien le même que le type actuel de la directive
				if(type===$scope.typeTag){

					// Met à jour les tags de ce type
					$scope.tmpTags = tags;

					// Si il y a une limite de tag
					if($scope.limitTag){
						// Si la limit de tag est atteinte alors on ferme le popover
						if(tags.length>=$scope.limitTag){
							$scope.$broadcast('closePopover');
						}
					}
				}
			});
*/

		}],
		controllerAs: 'popovertagctrl'
	};
};

angular.module('collaboreApp').directive('popoverTag',[
	popoverTag
]);