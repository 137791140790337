(function () {
  'use strict';

  /**
   * @desc Permet d'envoyer un document dans la ged v2
   * @example <send-ged-v2></send-ged-v2>
   */

  angular
    .module('collaboreApp')
    .component('sendGedV2', {
      templateUrl: 'app/topics/send-ged/v2/send-ged-v2.component.html',
      bindings: {

        // Concerne l'envoie de doc
        documents: '<',
        folderGed: '<',

        // Concernen l'export du topic
        documentTopic: '<',
        hasMessage: '<',
        hasSinistre: '<',
        hasCourriers: '<',
        hasPieceJointe: '<',
        hasDevisOuIntervention: '<',
        hasDemandesContrats: '<',

        // Global
        exportTopic: '<',
        idTopic: '<',
        onEmit: '&',

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<'
      },
      controllerAs: 'sendgedv2ctrl',
      controller: SendGedV2Controller
    });

  /*@ngInject*/
  function SendGedV2Controller(COLLAB_CONF, $log, $rootScope, $q, GedLink, UtilsService, GedService, ModalsService) {

    var _this = this;
    _this.folderGed = null;
    _this.listDocument = null;
    _this.nbCheckedDocument = 0;
    _this.sendProcessed = false;
    _this.onCheckDocument = onCheckDocument;
    _this.onClickSend = onClickSend;
    _this.onClickCancel = onClickCancel;


    _this.$onInit = function() {

      if(_this.resolve && _this.modalInstance) {
        _this.exportTopic = getParamFromResolve('exportTopic');
        _this.documents = getParamFromResolve('documents');
        _this.folderGed = getParamFromResolve('folderGed');
        _this.documentTopic = getParamFromResolve('documentTopic');
        _this.hasMessage = getParamFromResolve('hasMessage');
        _this.hasSinistre = getParamFromResolve('hasSinistre');
        _this.hasCourriers = getParamFromResolve('hasCourriers');
        _this.hasPieceJointe = getParamFromResolve('hasPieceJointe');
        _this.hasDevisOuIntervention = getParamFromResolve('hasDevisOuIntervention');
        _this.hasDemandesContrats = getParamFromResolve('hasDemandesContrats');
        _this.exportTopic = getParamFromResolve('exportTopic');
        _this.idTopic = getParamFromResolve('idTopic');
        _this.onEmit = getParamFromResolve('onEmit');
      }

      if(_.isNil(_this.idTopic)) ModalsService.alertErreur('Il manque le numéro de dossier');

      if(_this.exportTopic) {
        _this.nomPdf = _this.idTopic;
        if(_.isObject(_this.documentTopic)){
          _this.nomPdf =_this.documentTopic.getNom();
          _this.documentTopic.lastGedLink = _this.documentTopic.getLastGedLinks();
        }
        _this.displayOptions = (_this.hasMessage || _this.hasSinistre || _this.hasCourriers || _this.hasPieceJointe || _this.hasDevisOuIntervention || _this.hasDemandesContrats);

        _this.paramsPdf = {
          pdfMessage: false,
          pdfSinistre: false,
          pdfCourriers: false,
          pdfPieceJointe: false,
          pdfDevisOuIntervention: false,
          pdfContrats: false
        };
      }
      else {

        if(_.isNil(_this.idTopic)) {
          ModalsService.alertErreur('Il manque l\'id du dossier');
          return;
        }
        if(!_.isArray(_this.documents) || !_this.documents.length) {
          ModalsService.alertErreur('Aucun document à envoyer');
          return;
        }

        searchCheminGedV2();
        // ctrl.onEmit({obj: {folderGed: ctrl.folderGed}});
      }

    };

    _this.$onChanges = function(changes) {
    };

    _this.$onDestroy = function(){
    };


    function getParamFromResolve(param, defaultVal){
      return angular.copy(UtilsService.getParamModalComponent(_this.resolve, param, defaultVal));
    }

    function searchCheminGedV2(){
      _this.loading = true;
      GedService
        .searchCheminGedV2(_this.idTopic, _this.folderGed, _this.documents)
        .then(function(results) {
          if (_.isArray(results) && results.length) {
            _this.documents.map(function(doc){
              for(var c = 0; c < results.length; c++){
                var currentDoc = results[c].doc;
                if(currentDoc.guid == doc.guid){

                  doc.setData(currentDoc);

                  // Coché si pas en ged et pas d'erreur
                  // Décoché si en ged ou si il y a une erreur
                  doc.checked = (_.isNil(doc.getErreur()));
                  doc.isSent = results[c].isSent;

                  if(doc.isSent){
                    doc.checked = false;
                    // Reset l'erreur car le document n'existe plus dans le FS collab si déjà en GedV2
                    doc.erreur = null;
                  }

                  doc.cheminCompletGedV2 = GedLink.shortenPathGed(results[c].chemin);

                  doc.disabled = (doc.isSent || doc.getErreur());
                  break;
                }
              }
              return doc;
            });
            calcNbDocumentChecked();
          }
        })
        .finally(function() {
          _this.loading = false;
        });
    }

    function onCheckDocument(){
      calcNbDocumentChecked();
    }

    function calcNbDocumentChecked(){
      _this.nbCheckedDocument = 0;
      if(_.isArray(_this.documents) && _this.documents.length){
        for(var i = 0; i < _this.documents.length; i++) {
          if(_this.documents[i].checked) _this.nbCheckedDocument++;
        }
      }
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      _this.onEmit({obj: objRetour});
    }

    /**
     * Lors du clique sur le bouton envoyer
     */
    function onClickSend(){

      // Si exporte le dossier dans la Ged
      if(_this.exportTopic){

        _this.loading = true;
        GedService
          .exportTopicInGedV2(_this.idTopic, _this.nomPdf, _this.paramsPdf)
          .then(function(obj){
            if(_.isObject(obj)){
              if (_.isArray(obj.documents)) _this.listDocument = obj.documents;
              _this.sendProcessed = true;
              emit({'docsAndTopic': obj});
            }

            // $rootScope.$broadcast('refreshDocuments');
          })
          .finally(function(){
            _this.loading = false;
          });
      }
      // Sinon si on envoie simplement des documents
      else{
        var docs = [];
        if(_.isArray(_this.documents)) {
          for(var i = 0; i < _this.documents.length; i++){
            if(_this.documents[i].checked) docs = _.union(docs, [_this.documents[i]]);
          }
        }
        else if(_.isObject(_this.documents) && _this.documents.checked) docs.push(_this.documents);

        _this.loading = true;
        GedService
          .sendToGedV2(_this.idTopic, docs, _this.folderGed)
          .then(function(documents){
            if (_.isArray(documents)) _this.documents = documents;
            _this.sendProcessed = true;
            emit({'documents': documents});
          })
          .finally(function(){
          _this.loading = false;
        });
      }
    }

    /**
     * Lors du clique sur le bouton pour fermer la modal
     */
    function onClickCancel(){
      cancel();
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    /*
    function emitAndClose(objRetour){
      if(_this.modalInstance) _this.close({$value: objRetour});
    }*/

    /**
     * Permet de fermer le composant, soit la modal soit emit un message pour fermer le composant depuis un parent pas exemple
     */
    function cancel(){
      if(_this.modalInstance) _this.dismiss({$value: 'cancel'});
      else emit({action: 'closeSendGedV2'});
    }

  }
})();
