(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .factory('IcsCloudResource', IcsCloudResource);

  /** @ngInject */
  function IcsCloudResource(COLLAB_CONF, COLLAB_VALUES, $resource) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_ICSCLOUD, null, {

      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      },

      getDigicode: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_DIGICODES,
        params: {
          idPortefeuille: '@idPortefeuille',
          iiii: '@iiii'
        }
      }
    });
  }
})();
