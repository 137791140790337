(function () {
  'use strict';

  /**
   * @desc Modal pour la modification d'un groupe user
   * @example <admin-collab-modal-edit-groupe-user></admin-collab-modal-edit-groupe-user>
   */

  angular
    .module('collaboreApp')
    .component('adminCollabModalEditGroupeUser',{
      templateUrl: 'app/admin/groupe/admin-collab-modal-edit-groupe-user.component.html',
      controllerAs: 'admincollabmodaleditgroupeuser',
      controller: AdminCollabModalEditGroupeUserCtrl,
      bindings: {
        //modalInstance: '<',
        resolve: '<',
        close: '&',
        dismiss: '&'
      }
    });

  /*@ngInject*/
  function AdminCollabModalEditGroupeUserCtrl(UtilsService) {

    var ctrl = this;

    ctrl.onSuccess = onSuccess;
    ctrl.onClickClose = onClickClose;
    ctrl.onProgress = onProgress;
    ctrl.progress = false;

    ctrl.$onInit = function() {
      ctrl.compte = UtilsService.getParamModalComponent(ctrl.resolve, 'compte');
      console.log(ctrl.compte);

    };

    function onSuccess(obj){
      if(_.isObject(obj) && obj.hasOwnProperty('groupe')) {
        ctrl.compte = obj.groupe;
        ctrl.close({$value: obj.groupe});
      }
    }

    function onClickClose(){
      ctrl.dismiss({$value: 'cancel'});
      //ctrl.close({$value: ctrl.sinistre});
    }

    function onProgress(obj){
      ctrl.progress = obj;
    }
  }
})();
