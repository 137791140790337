(function() {
  'use strict';

  /**
   * @ngdoc factory
   * @name collaboreApp.ProxyContenu
   * @description
   * # ProxyContenu
   */

  angular.module('collaboreApp').factory('ProxyContenu', ProxyContenuModel);

  /** @ngInject */
  function ProxyContenuModel(Main, UtilsService, ProxyDestinataire, ProxyTag, ProxyDestinataireExclu, DemandeOptions) {

    // Permet de convertir un setter par une autre
    var convertSetter = {
      listeDocument: 'guids'
    };

    function ProxyContenu(objetData) {


      var scope = this,
        attributMinimum = [
          {attribut:'destinataires',  defaultValue:[]},
          {attribut:'guids',          defaultValue:[]},
          {attribut:'tags',           defaultValue:[]},
          {attribut:'tagsType',       defaultValue:[]}
        ];

      /*
      this.hydrate(objetData);

      // Parcour les attributs minimum

      for(var i = 0 ; i < attributMinimum.length ; i++){
          // Si l'attribut n'est pas dans l'objet on le met avec sa valeur par defaut
          if(!scope.hasOwnProperty(attributMinimum[i].nom)){
              scope[attributMinimum[i].nom] = attributMinimum[i].valDefault;
          }
      }*/

      Main.call(this, objetData, attributMinimum, convertSetter);

      if(scope.getDestinataires().length===0 && scope.getListeDestinataires().length===0){
        scope.addListeDestinataires(UtilsService.getCurrentUser());
      }
    }

    ProxyContenu.prototype = {

      //isModel: true,
      isProxy: true,
      /*
      hydrate: function (objetData) {
          var scope = this;
          // Si c'est un objet
          if (angular.isObject(objetData)) {
              // Hydratation
              angular.forEach(objetData,function(val,key){
                  if (angular.isDefined(key)) {
                      var setter = 'set' + key.ucfirst();

                      // Si la propriété est dans le tableau des conversion
                      // Converti le setter en un autre
                      if(convertSetter.hasOwnProperty(setter)) {
                          // Remplace la méthode par celle de la conversion
                          // Exemple listeDocument => guids
                          setter = convertSetter[setter];
                      }

                      if(angular.isFunction(scope[setter])) {
                          scope[setter](val);
                      }
                  }
              });
          }
      },*/


      // Permet de convertir l'objet en JSON
      /*
      toJson: function(){
          return angular.toJson(this);
      },*/

      // IdContenu
      getIdContenu: function(){
        return this.idContenu;
      },
      setIdContenu: function(val){
        this.idContenu = val;
      },

      // idTopic
      getIdTopic: function(){
        return this.idTopic;
      },
      setIdTopic: function(val){
        this.idTopic = val;
      },

      // Guids
      getGuids: function(){
        return this.guids;
      },
      setGuids: function(val){
        var guids = [];
        if(_.isArray(val)){
          val.map(function(doc) {
            if(_.isObject(doc))
              guids = _.union(guids, [doc.guid]);
            else if(_.isString(doc))
              guids = _.union(guids, [doc]);
          });
        }
        else if(_.isObject(val))
          guids = _.union(guids, [val.guid]);
        else if(_.isString(val))
          guids = _.union(guids, [val]);

        this.guids = guids;
      },
      addGuids: function(val){
        var guids = this.getGuids() || [];
        if(_.isArray(val)){
          val.map(function(doc) {
            if(_.isObject(doc))
              guids = _.union(guids, [doc.guid]);
            else if(_.isString(doc))
              guids = _.union(guids, [doc]);
          });
        }
        else if(_.isObject(val))
          guids = _.union(guids, [val.guid]);
        else if(_.isString(val))
          guids = _.union(guids, [val]);

        this.guids = guids;
      },

      // listeDocumentFournisseur
      getListeDocumentFournisseur: function(){
        return this.listeDocumentFournisseur;
      },
      setListeDocumentFournisseur: function(val){
        var guids = [];
        if(angular.isArray(val)){
          for(var i = 0 ; i < val.length ; i++){
            if(angular.isObject(val[i])){
              guids.push(val[i].guid);
            }
            else if(angular.isString(val[i])){
              guids.push(val[i]);
            }
          }
        }
        this.listeDocumentFournisseur = guids;
      },

      // listeDocumentComptable
      getListeDocumentComptable: function(){
        return this.listeDocumentComptable;
      },
      setListeDocumentComptable: function(val){
        var guids = [];
        if(angular.isArray(val)){
          for(var i = 0 ; i < val.length ; i++){
            if(angular.isObject(val[i])){
              guids.push(val[i].guid);
            }
            else if(angular.isString(val[i])){
              guids.push(val[i]);
            }
          }
        }
        this.listeDocumentComptable = guids;
      },

      // Destinataires
      getDestinataires: function(){
        if(!this.hasOwnProperty('destinataires')){
          this.destinataires = [];
        }
        else if(!angular.isArray(this.destinataires)){
          this.destinataires = [];
        }
        return this.destinataires;
      },
      setDestinataires: function(val){
        var retourArrayDestinataires = [];
        if(angular.isArray(val)){
          for(var i = 0; i < val.length; i++){

            var dest = val[i];
            if(angular.isObject(dest)){
              if(dest.hasOwnProperty('libelle') && dest.hasOwnProperty('dieze')) retourArrayDestinataires.push(dest.libelle + dest.dieze);
              if(dest.hasOwnProperty('libelle') && !dest.hasOwnProperty('dieze')) retourArrayDestinataires.push(dest.libelle);
              else if(dest.hasOwnProperty('email')) retourArrayDestinataires.push(dest.email);
            }
            else if(angular.isString(dest)) retourArrayDestinataires.push(dest);
          }
        }
        this.destinataires = retourArrayDestinataires;
      },

      setDestinatairesHide: function(val){
        var dest = this.getDestinataires();
        if(angular.isArray(val) && val.length){
          // Parcour les destinataires caché
          for(var d = 0; d < val.length; d++) {
            var newDest = val[d];
            // Si le destinataire caché est un objet on recup son libelle et dieze
            if (angular.isObject(val[d])) newDest = val[d].libelle + val[d].dieze;
            var exist = false;

            // Si il y a déjà des destinataires
            if (dest.length) {
              // Parcour les destinataires existante pourchacker que l'on ne pas va pas fair un doublon
              for (var i = 0; i < dest.length; i++) {
                if (dest[i] === newDest) {
                  exist = true;
                  break;
                }
              }
            }
            if (!exist) dest.push(newDest);
          }
        }

        this.setDestinataires(dest);
      },

      // ListeDestinataires (Idem à "destinataires" mais permet d'envoyer des objet au serveur)
      getListeDestinataires: function(){
        if(!this.hasOwnProperty('listeDestinataires')){
          this.listeDestinataires = [];
        }
        else if(!angular.isArray(this.listeDestinataires)){
          this.listeDestinataires = [];
        }
        return this.listeDestinataires;
      },
      setListeDestinataires: function(val){
        var retourArrayListeDestinataire = [];
        if(angular.isArray(val)){
          for(var i = 0 ; i < val.length ; i++){

            retourArrayListeDestinataire.push(new ProxyDestinataire(val[i]));
          }
        }
        this.listeDestinataires = retourArrayListeDestinataire;
      },
      addListeDestinataires: function(val){
        var retourArrayListeDestinataire = this.getListeDestinataires();
        retourArrayListeDestinataire.push(new ProxyDestinataire(val));
        this.listeDestinataires = retourArrayListeDestinataire;
      },

      // DestinatairesExclus
      getDestinatairesExclus: function(){
        if(!this.hasOwnProperty('destinatairesExclus')){
          this.destinatairesExclus = [];
        }
        else if(!angular.isArray(this.destinatairesExclus)){
          this.destinatairesExclus = [];
        }
        return this.destinatairesExclus;
      },
      setDestinatairesExclus: function(val){
        var retourArrayDestinatairesExclus = [];
        if(angular.isArray(val)){
          for(var i = 0 ; i < val.length ; i++){
            retourArrayDestinatairesExclus.push(new ProxyDestinataireExclu(val[i]));
          }
        }
        this.destinatairesExclus = retourArrayDestinatairesExclus;
      },

      // Tags
      getTags: function(){
        return this.tags;
      },
      setTags: function(val){
        var tags = [];
        if(angular.isArray(val)){
          for(var t = 0 ; t < val.length ; t++){
            tags.push(new ProxyTag(val[t]));
          }
        }
        this.tags = tags;
      },

      // TagsType
      getTagsType: function(){
        return this.tagsType;
      },
      setTagsType: function(val){
        var tags = [];
        if(angular.isArray(val)){
          for(var t = 0 ; t < val.length ; t++){
            tags.push(new ProxyTag(val[t]));
          }
        }
        this.tagsType = tags;
      },

      // TypeContenu
      getTypeContenu: function(){
        return this.typeContenu;
      },
      setTypeContenu: function(val){
        this.typeContenu = val;
      },

      // sendByMail
      getSendByMail: function(){
        return this.sendByMail;
      },
      setSendByMail: function(val){
        this.sendByMail = val;
      },

      // SendOneMailWithAllDest
      getSendOneMailWithAllDest: function(){
        return this.sendOneMailWithAllDest;
      },
      setSendOneMailWithAllDest: function(bool){
        this.sendOneMailWithAllDest = bool;
      },

      // Update
      getUpdate: function(){
        return this.update;
      },
      setUpdate: function(val){
        this.update = val;
      },

      // idContenuParent
      getIdContenuParent: function(){
        return this.idContenuParent;
      },
      setIdContenuParent: function(val){
        this.idContenuParent = val;
      },

      // idContenuRefInterAgence
      getIdContenuRefInterAgence: function(){
        return this.idContenuRefInterAgence;
      },
      setIdContenuRefInterAgence: function(val){
        this.idContenuRefInterAgence = val;
      },

      // signature
      getSignature: function(){
        return this.signature;
      },
      setSignature: function(val){
        this.signature = val;
      },

      // proxyMail
      getProxyMail: function(){
        return this.proxyMail;
      },
      setProxyMail: function(val){
        var objProxyMail = val;
        if(angular.isObject(objProxyMail) && !objProxyMail.model){
          var ProxyMail = Main.$injector.get('ProxyMail');
          objProxyMail = new ProxyMail(objProxyMail);
          ProxyMail = null;
        }
        this.proxyMail = objProxyMail;
      },

      // dateCloture
      getDateCloture: function(){
        return this.dateCloture;
      },
      setDateCloture: function(val){
        this.dateCloture = val;
      },

      // raisonCloture
      getRaisonCloture: function(){
        return this.raisonCloture;
      },
      setRaisonCloture: function(val){
        this.raisonCloture = val;
      },

      // demandeOptions
      getDemandeOptions: function(){
        return this.demandeOptions;
      },
      setDemandeOptions: function(val){
        if(_.isObject(val)) {
          if(!val.isModel) {
            var DemandeOptions = new Main.$injector.get('DemandeOptions');
            val = new DemandeOptions(val);
          }
          this.demandeOptions = val;
        }
      },
    };

    ProxyContenu.ProxyDestinataire = ProxyDestinataire;

    angular.extend(ProxyContenu.prototype, Main.prototype);

    return ProxyContenu;
  }
})();
