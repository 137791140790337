(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.CommunicationService
   * @description
   * # CommunicationService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('CommunicationService', CommunicationService);

  /*@ngInject*/
  function CommunicationService($q, $injector, CommunicationAjax, ModalsService) {

    var ctrl = this;

    ctrl.sendSMSDiffusionToctoctoc = sendSMSDiffusionToctoctoc;
    //ctrl.sendEmailWithProxyMail = sendEmailWithProxyMail;

    /*
    function postServlet(action, obj) {
      var deferred = $q.defer();
      try {
        if (!action) throw new Error('Le paramètre "action" n\'existe pas !');

        if (!angular.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet !');

        var sendParams = angular.merge({}, obj);
        sendParams.action = action;

        CommunicationAjax
          .post(sendParams, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, ['devis', 'listDevis'], ContenuEvenementDevis, deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }*/

    function sendSMSDiffusionToctoctoc(listIdGroupeByPortefeuille, sujet, message){
      var deferred = $q.defer();
      try {
        if (!angular.isArray(listIdGroupeByPortefeuille)) throw new Error('Le paramètre "listIdGroupeByPortefeuille" doit être un Array !');
        if (!angular.isString(message)) throw new Error('Le paramètre "message" doit être un String !');

        var listJson = [];
        if(listIdGroupeByPortefeuille.length) {
          for(var i = 0; i < listIdGroupeByPortefeuille.length; i++) {
            listJson.push(angular.toJson(listIdGroupeByPortefeuille[i]));
          }
        }

        var param = {
          action: 'sendSMSDiffusionToctoctoc',
          sujet: (sujet) ? sujet : 'SMS Collab',
          message: message,
          listIdGroupeByPortefeuille: listJson
        };
        CommunicationAjax
          .post(param, function (json) {
            if(json.success) deferred.resolve(true);
            else deferred.reject('Il y a eu un problème lors de l\'envoie du SMS à la liste d\'idGroupe<br />' + json.message);
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /*
      function sendEmailWithProxyMail(obj){
          var deferred = $q.defer();
          try {
              if (!angular.isObject(obj)) throw new Error('Le paramètre "obj" doit être un Object !');

              var ProxyMail = $injector.get('ProxyMail');
              var param = {
                  action: 'sendEmailWithProxyMail',
                  proxyMail: (new ProxyMail(obj)).toJson()
              };
              ProxyMail = null;
              CommunicationAjax
                  .post(param, function (json) {
                      if(json.success) deferred.resolve(true);
                      else deferred.reject('Il y a eu un problème lors de l\'envoie du mail avec ProxyMail<br />' + json.message);
                  }, function (msg) {
                      deferred.reject(msg);
                  });
          }
          catch (e) {
              ModalsService.alertErreur(e.message);
              deferred.reject(e.message);
          }
          return deferred.promise;
      }*/

  }

})();
