(function() {
    'use strict';
    /**
     * @ngdoc factory
     * @name collaboreApp.ProxyRenseignement
     * @description
     * # ProxyRenseignement
     */

    angular.module('collaboreApp').factory('ProxyRenseignement', ProxyRenseignementModel);

    /** @ngInject */
    function ProxyRenseignementModel(Main) {

        function ProxyRenseignement(objetData) {
            Main.apply(this, arguments);
            this.hydrate(objetData);
        }

        ProxyRenseignement.prototype = {

            model: 'ProxyRenseignement',
            isProxy: true,

            getIdRenseignement: function(){
                return this.idRenseignement;
            },
            setIdRenseignement: function(val){
                this.idRenseignement = val;
            },

            getDonnee: function(){
                return this.donnee;
            },
            setDonnee: function(val){
                this.donnee = val;
            },

            getIdReference: function(){
                return this.idReference;
            },
            setIdReference: function(val){
                this.idReference = val;
            },

            setReference: function(val){
                if(angular.isObject(val) && val.hasOwnProperty('idReference')) {
                    this.idReference = val.idReference;
                }
            }

        };

        angular.extend(ProxyRenseignement.prototype, Main.prototype);

        return ProxyRenseignement;
    }
})();