(function () {

    'use strict';

    /**
     * @desc Liste des demandes de devis/intervention, contrat
     * @example <list-demande-fournisseur></list-demande-fournisseur>
     */

    angular
        .module('collaboreApp')
        .component('listDemandeFournisseur', {
            bindings: {
                idTopic: '<',           // <Integer> Id du topic
                allowEditEvenement: '<',// <Boolean> Permet de savoir si l'événement est modifiable
                evenement: '<',         // <ContenuEvenement> événement (Obligatoire à cause du composant "manage-devis")
                infosEvenement: '<',    // <Object> Infos de l'événement
                listDevis: '<',	        // Array<ContenuEvenementDevis> Liste des devis/inter
                listContrats: '<',	        // Array<ContenuEvenementContrat> Liste des contrats
                onInit: '<',            // <Object> Objet avec des actions à effectuer lors de l'initialisation du component
                onEmit: '&'             // <Function> Utilisé pour passer des informations au parents
            },
            templateUrl: 'app/topics/fournisseurs/list-demande-fournisseur/list-demande-fournisseur.component.html',
            controllerAs: 'listdemandefournisseurctrl',
            controller: ListDemandeFournisseurCtrl
        });

    /*@ngInject*/
    function ListDemandeFournisseurCtrl($rootScope, $timeout, $scope, $log, COLLAB_CONF, $injector, $location, $anchorScroll, UtilsService, ContenuEvenementDevis, ContenuEvenementContrat, TopicManagerService) {

        var _this = this;
        var listenerOpenPdfAndSelectDemande = null;

        _this.viewPDF = false;
        _this.currentDemande = null;      // Demande actuellement en cours de création/modification

        _this.selectedDemande = null;   // Demande actuellement selectionnée (objet avec la demande, le document,le type de categorie et le titre)

        _this.openManagerDemande = openManagerDemande;
        _this.closeDetailPDF = closeDetailPDF;
        _this.onEmitFromDemandeFournisseur = onEmitFromDemandeFournisseur;
        _this.onEmitFromManageDemande = onEmitFromManageDemande;
        _this.onEmitFromDetailPdf = onEmitFromDetailPdf;

        _this.widthListDemandes = null;
        _this.onChangeWithOfListDemandes = onChangeWithOfListDemandes;
        _this.getColClassDemande = getColClassDemande;

        _this.$onInit = function () {

            _this.validDevisWhenSignIt = UtilsService.getParametrage('validDevisWhenSignIt');
            _this.topicIsBlocked = TopicManagerService.isBlocked;

            if(!_.isNil(_this.idTopic)) {
                _this.idTopic = parseInt(_this.idTopic);
            }

            if(!_.isArray(_this.listDevis)) {
                _this.listDevis = [];
            }
            if(!_.isArray(_this.listContrats)) {
                _this.listContrats = [];
            }

            initListDemandes();

            // Si il y a des choses à faire venant du parent lors de l'initialisation de ce composant
            if(_.isObject(_this.onInit)) {
                if((_this.onInit.action === 'openDocument' || _this.onInit.action === 'selectDemande') && _.isObject(_this.onInit.contenu)) {
                    if(_.isObject(_this.onInit.document)) {
                        openPdfAndSelectDemande(_this.onInit.contenu, _this.onInit.document);
                    }
                    else {
                        openPdfAndSelectDemande(_this.onInit.contenu);
                    }
                    $anchorScroll();
                    //$location.hash('devis_'+_this.onInit.contenu.idContenu);
                    //$anchorScroll();
                }
                else if(_this.onInit.action === 'newDemande') openManagerDemande();
                else if(_this.onInit.action === 'newDemandeContrat') openManagerDemande(null,null,true);
                else if(_this.onInit.action === 'editDemande' && _.isObject(_this.onInit.contenu)) {
                    openManagerDemande(_this.onInit.contenu);
                }
                //else if(_this.onInit.action === 'openDocumentDevis') openViewDevis(_this.onInit.contenu, 1);
                //else if(_this.onInit.action === 'openSignatureDevis') openViewDevis(_this.onInit.contenu, 2);
                //else if(_this.onInit.action === 'openDocumentDevisSigne') openViewDevis(_this.onInit.contenu, 3);
            }

            listenerOpenPdfAndSelectDemande = $scope.$on('openPdfAndSelectDemande', function(event, contenu){
                openPdfAndSelectDemande(contenu);
            });
        };

        _this.$onChanges = function (changes) {
            if(_.isObject(changes)) {
                if(_.isObject(changes.listDevis) && !changes.listDevis.isFirstChange()) {
                    _this.listDevis = changes.listDevis.currentValue;
                    initListDemandes();
                }
                if(_.isObject(changes.listContrats) && !changes.listContrats.isFirstChange()) {
                    _this.listContrats = changes.listContrats.currentValue;
                    initListDemandes();
                }
                if(_.isObject(changes.evenement) && !changes.evenement.isFirstChange()) {
                    _this.evenement = changes.evenement.currentValue;
                }
            }
        };

        _this.$onDestroy = function () {
            // Lors du destroy du composant on supprime l'objet "selected" de la demande actuellement selectionné
            // pour update la liste du composant parent qui lui a le selected
            // Si la prochaine route est la page de detail du topic
            if(_.isObject(_this.selectedDemande) &&
              _.isObject(_this.selectedDemande.demande) &&
              !_.isNil(_this.selectedDemande.demande.typeContenu) &&
              UtilsService.startsWith($rootScope.toState.name, 'topics.detail')) {

                delete _this.selectedDemande.demande.selected;
                emit({
                    action: 'addOrUpdateDemande',
                    demande: _this.selectedDemande.demande
                });
            }
            if(!_.isNil(listenerOpenPdfAndSelectDemande)) listenerOpenPdfAndSelectDemande();
        };

        /**
         * Méthodes Private
         */

        /**
         * Initialise la liste des variables
         */
        function initVarsList(){

            _this.demandesDevisEnAttenteDeReception = {
                titre: ContenuEvenementDevis.ETATS.ATTENTE_DEVIS.LIBELLE,
                type: ContenuEvenementDevis.ETATS.ATTENTE_DEVIS.TYPE,
                list: [] // Liste des demandes de devis qui sont en attentes de reception d'un devis
            };

            _this.demandesDevisEnAttenteDeValidationOuRefuse = {
                titre: ContenuEvenementDevis.ETATS.ATTENTE_VALIDATION_DEVIS.LIBELLE,
                type: ContenuEvenementDevis.ETATS.ATTENTE_VALIDATION_DEVIS.TYPE,
                list: [] // Liste des demandes de devis qui sont en attentes de validation ou refus du devis reçu
            };
            if(_this.validDevisWhenSignIt) {
                _this.demandesDevisEnAttenteDeValidationOuRefuse = {
                    titre: ContenuEvenementDevis.ETATS.ATTENTE_SIGNATURE_REFUS_DEVIS.LIBELLE,
                    type: ContenuEvenementDevis.ETATS.ATTENTE_SIGNATURE_REFUS_DEVIS.TYPE,
                    list: [] // Liste des demandes de devis qui sont en attentes de signature ou refus du devis reçu
                };
            }

            _this.demandesDevisValideEnAttenteDeSignature = {
                titre: ContenuEvenementDevis.ETATS.ATTENTE_SIGNATURE_DEVIS.LIBELLE,
                type: ContenuEvenementDevis.ETATS.ATTENTE_SIGNATURE_DEVIS.TYPE,
                list: [] // Liste des demandes de devis validés qui sont en attentes de signature
            };

            _this.demandesInterventionEnCours = {
                titre: ContenuEvenementDevis.ETATS.EN_COURS.LIBELLE,
                type: ContenuEvenementDevis.ETATS.EN_COURS.TYPE,
                list: [] // Liste des demandes qui sont en cours (Pour les devis ils sont validés)
            };

            _this.demandesAvecFactureAttenteTraitement = {
                titre: ContenuEvenementDevis.ETATS.FACTURE_RECU_NON_TRAITE.LIBELLE,
                type: ContenuEvenementDevis.ETATS.FACTURE_RECU_NON_TRAITE.TYPE,
                list: [] // Liste des demandes d'intervention ou devis avec une facture qui n'est pas encore traitée
            };

            _this.demandesCloture = {
                titre: ContenuEvenementDevis.ETATS.CLOTURE.LIBELLE,
                type: ContenuEvenementDevis.ETATS.CLOTURE.TYPE,
                list: [] // Liste des demandes cloturées
            };

            _this.demandesContratEnAttenteDeSignatureParLeFournisseur = {
                titre: ContenuEvenementContrat.ETATS.ATTENTE_CONTRAT.LIBELLE,
                type: ContenuEvenementContrat.ETATS.ATTENTE_CONTRAT.TYPE,
                list: [] // Liste des demandes de contrat qui sont en attentes de signature par le fournisseur
            };

            _this.demandesContratEnAttenteDeValidationOuRefuse = {
                titre: ContenuEvenementContrat.ETATS.ATTENTE_VALIDATION_CONTRAT.LIBELLE,
                type: ContenuEvenementContrat.ETATS.ATTENTE_VALIDATION_CONTRAT.TYPE,
                list: [] // Liste des demandes de contrat qui ont un contrat signé par le fournisseur mais qui sont en attente de traitement de l'agence
            };

            _this.demandesContratValideEnAttenteDeSignature = {
                titre: ContenuEvenementContrat.ETATS.ATTENTE_SIGNATURE_CONTRAT.LIBELLE,
                type: ContenuEvenementContrat.ETATS.ATTENTE_SIGNATURE_CONTRAT.TYPE,
                list: [] // Liste des demandes de contrat qui sont validé et en attente de signature de l'agence
            };
        }

        /**
         * Permet d'initialiser la liste des demandes
         */
        function initListDemandes(){

            var listDevis = _.union(_this.listDevis, _this.listContrats);

            // Initialisation des variables list
            initVarsList();

            // Si pas de demande de devis ou intervention alor souvre le formulaire
            if(!_.isArray(listDevis) || _.isEmpty(listDevis)) {

                openManagerDemande();
            }
            // Si il y a des demandes de devis ou interventions
            else if(_.isArray(listDevis) && !_.isEmpty(listDevis)) {

                for(var d = 0; d < listDevis.length; d++) {
                    var demande = listDevis[d];


                    // Si il y a une demande selectionnée alors on doit la mettre à jour avec l'objet de la liste des devis qui lui correspond
                    if(!_.isNil(_this.selectedDemande) && _.isObject(_this.selectedDemande.demande)) {
                        if(demande.getIdContenu() === _this.selectedDemande.demande.getIdContenu()) {
                            _this.selectedDemande.demande = demande;
                        }
                    }
                    /*
                    if(_.isNil(demande.idTopic) && !_.isNil(_this.idTopic)) {
                        demande.idTopic = _this.idTopic;
                    }*/

                    switch(demande.getTypeEtatDemande()) {
                        case _this.demandesDevisEnAttenteDeReception.type:
                            _this.demandesDevisEnAttenteDeReception.list.push(demande);
                            break;
                        case _this.demandesDevisEnAttenteDeValidationOuRefuse.type:
                            _this.demandesDevisEnAttenteDeValidationOuRefuse.list.push(demande);
                            break;
                        case _this.demandesDevisValideEnAttenteDeSignature.type:
                            _this.demandesDevisValideEnAttenteDeSignature.list.push(demande);
                            break;
                        case _this.demandesInterventionEnCours.type:
                            _this.demandesInterventionEnCours.list.push(demande);
                            break;
                        case _this.demandesAvecFactureAttenteTraitement.type:
                            _this.demandesAvecFactureAttenteTraitement.list.push(demande);
                            break;
                        case _this.demandesCloture.type:
                            _this.demandesCloture.list.push(demande);
                            break;
                        case _this.demandesContratEnAttenteDeSignatureParLeFournisseur.type:
                            _this.demandesContratEnAttenteDeSignatureParLeFournisseur.list.push(demande);
                            break;
                        case _this.demandesContratEnAttenteDeValidationOuRefuse.type:
                            _this.demandesContratEnAttenteDeValidationOuRefuse.list.push(demande);
                            break;
                        case _this.demandesContratValideEnAttenteDeSignature.type:
                            _this.demandesContratValideEnAttenteDeSignature.list.push(demande);
                            break;
                        default:
                    }
                    /*
                    // Si la demande n'est pas cloturé
                    if(!demande.isCloture()) {

                        // Si demande de devis
                        if(demande.getDemandeDevisActive()) {

                            // Si la demande est refusée
                            if(demande.isRefuse()){
                                _this.demandesDevisEnAttenteDeValidationOuRefuse.list.push(demande);
                            }
                            // Si demande en attente (Pas validé/refusé)
                            else if(demande.isWaiting() || demande.isWaitingFromWebsocket()) {

                                // Si la demande a un document devis alors elle est en attente de validation/refus
                                if(demande.hasDevis()) {
                                    _this.demandesDevisEnAttenteDeValidationOuRefuse.list.push(demande);
                                }
                                // Si la demande n'a pas de document devis
                                else {
                                    _this.demandesDevisEnAttenteDeReception.list.push(demande);
                                }
                            }
                            // Si la demande n'est pas en attente et signée
                            else if(demande.isSigne()){

                                // Si la demande de devis est acceptée
                                if(demande.isAccepte()) {

                                    // Si le demande de devis a une facture alors elle est en attente de traitement
                                    if(demande.hasFacture()) {
                                        _this.demandesAvecFactureAttenteTraitement.list.push(demande);
                                    }
                                    // Si la demande de devis n'a pas de facture
                                    else {
                                        _this.demandesInterventionEnCours.list.push(demande);
                                    }
                                }
                            }
                            // Si la demande n'est pas en attente et pas signée alors en attente de signature
                            else if(!demande.isSigne()) {
                                _this.demandesDevisValideEnAttenteDeSignature.list.push(demande);
                            }
                        }
                        // Si demande d'intervention
                        else {
                            // Si la demande a une facture alors elle est en attente de traitement
                            if(demande.hasFacture()) {
                                _this.demandesAvecFactureAttenteTraitement.list.push(demande);
                            }
                            // Si la demande d'intervention n'a pas de facture alors est en cours
                            else {
                                _this.demandesInterventionEnCours.list.push(demande);
                            }
                        }
                    }
                    else {
                        _this.demandesCloture.list.push(demande);
                    }*/
                }
            }

            // Permet d'ouvrir le dernier devis ou dernière facture reçu
            // TODO: Il faut detecter si l'user est sur une demande pour ne pas afficher n'importe qu'elle demande à la suite de son action
            // openLastDocumentReceived(listDevis);
        }

        /**
         * Permet d'ouvrir le dernier devis ou dernière facture reçu
         * @param listeDevis
         */
        function openLastDocumentReceived(listeDevis){
            var demandes = [];
            for(var d = 0; d < listeDevis.length; d++) {
                var demande = listeDevis[d];
                var obj = null;
                // Si demande de type devis/inter
                if(demande.isContenuDevisInter()) {
                    // Si devis
                    if(demande.isContenuDevis()) {

                        // Si demande encore en attente
                        if(demande.isWaiting()) {

                            // Si il y a un devis
                            if(demande.hasDevis()) {
                                // Devis en attente d'acceptation
                                obj = {
                                    date: demande.getDateReceptionDevis(),
                                    contenu: demande,
                                    document: demande.getDocumentDevis()
                                };
                            }
                        }
                        // Si devis accepte
                        else if(demande.isAccepte()){
                            // Si devis pas signé
                            if(!demande.isSigne()) {
                                // Devis en attente de signature
                                obj = {
                                    date: demande.getDateAcceptation(),
                                    contenu: demande,
                                    document: demande.getDocumentDevis()
                                }
                            }
                        }
                    }

                    // Si il y a une facture
                    if(demande.hasFacture()) {
                        // Si facture non traitée
                        if(!demande.getFacture().isTraite() && !demande.hasFactureTraite()) {
                            // Facture en attente de traitement
                            obj = {
                                date: demande.getFacture().getDateCreation(),
                                contenu: demande,
                                document: demande.getDocumentFacture()
                            }
                        }
                    }
                }
                if(!_.isNil(obj)) {
                    demandes.push(obj);
                }
            }

            if(demandes.length) {
                // Ordre de date décroissante
                demandes.sort(function(a,b){return new Date(b.date).getTime() - new Date(a.date).getTime()});
                openPdfAndSelectDemande(demandes[0].contenu, demandes[0].document)
            }
        }

        /**
         * Permet d'ajouter ou d'update un devis dans la liste des devis ou contrats
         * @param devis
         */
        function addOrUpdateDemande(demande){

            if(_.isObject(demande) && demande.isModel){
                if(demande.isContenuDevisInter()){
                    var existInDevis = false;
                    if(_.isArray(_this.listDevis) && !_.isEmpty(_this.listDevis)) {
                        for(var i = 0; i < _this.listDevis.length; i++) {
                            if(_this.listDevis[i].getIdContenu === demande.getIdContenu()) {
                                existInDevis = true;
                                _this.listDevis[i].setData(demande);
                                break;
                            }
                        }
                    }
                    if(!existInDevis) {
                        if(!_.isArray(_this.listDevis)){
                            _this.listDevis = [];
                        }
                        _this.listDevis.push(demande);
                    }
                }
                else if(demande.isContenuContrat()){
                    var existInContrats = false;
                    if(_.isArray(_this.listContrats) && !_.isEmpty(_this.listContrats)) {
                        for(var i = 0; i < _this.listContrats.length; i++) {
                            if(_this.listContrats[i].getIdContenu === demande.getIdContenu()) {
                                existInContrats = true;
                                _this.listContrats[i].setData(demande);
                                break;
                            }
                        }
                    }
                    if(!existInContrats) {
                        if(!_.isArray(_this.listContrats)){
                            _this.listContrats = [];
                        }
                        _this.listContrats.push(demande);
                    }
                }
            }
        }

        /**
         * Méthodes Public
         */

        /**
         * Permet d'ouvrir la création/modification d'une demande de contrat
         * @param devis
         */
        function openManagerDemande(demande, isDevis, isContrat) {

            if (_.isObject(demande)) {
                _this.currentDemande = angular.copy(demande);
            }
            else {

                if(isContrat) {
                    _this.currentDemande = new ContenuEvenementContrat();
                }
                else {
                    _this.currentDemande = new ContenuEvenementDevis();
                    if(isDevis) {
                        _this.currentDemande.setDemandeDevisActive(true);
                    }

                    if (UtilsService.isCapfun()) {
                        _this.currentDemande.setDescriptionDevis(COLLAB_CONF.DEFAULT_DESCRIPTION_DEMANDE_DEVIS);
                        _this.currentDemande.setMessageGravitants(COLLAB_CONF.DEFAULT_DESCRIPTION_DEMANDE_DEVIS);
                    }
                }
                _this.currentDemande.setGroupement('Défaut');
            }
            UtilsService.scrollToTop();
            closeDetailPDF();
        }

      /**
       * Permet de choisir une demande et d'ouvrir un document
       * @param contenu
       * @param varDocument
       * @param fromContenu
       */
        function openPdfAndSelectDemande(contenu, varDocument, fromContenu){
            var findContenu = null;
            if(_.isObject(contenu) && _.isNil(contenu.model) && !_.isNil(contenu.idContenu)) {
                findContenu = parseInt(contenu.idContenu);
            }
            else if(_.isString(contenu) || _.isNumber(contenu)) {
                findContenu = parseInt(contenu);
            }

            if(!_.isNil(findContenu)) {
                _this.listDevis.map(function(item){
                    if(parseInt(item.getIdContenu()) === findContenu){
                        contenu = item;
                    }
                });

                if(_.isNil(contenu.model)) {
                    _this.listContrats.map(function(item){
                        if(parseInt(item.getIdContenu()) === findContenu){
                            contenu = item;
                        }
                    });
                }
            }

            if(_.isNil(contenu.model))  throw new Error('Le "contenu" n\'est pas un model');

            if(!_.isObject(varDocument)) {

                if(contenu.isContenuDevisInter()) {
                    if(contenu.getDocumentFacture()) {
                        varDocument = contenu.getDocumentFacture();
                    }
                    else if(contenu.getDocumentDevisSigne()) {
                        varDocument = contenu.getDocumentDevisSigne();
                    }
                    else if(contenu.getDocumentDevis()) {
                        varDocument = contenu.getDocumentDevis();
                    }
                    else if(contenu.getDocumentDemande()) {
                        varDocument = contenu.getDocumentDemande();
                    }
                }
                else if(contenu.isContenuContrat()) {
                    if(contenu.getListDocumentContratAgenceSigne().length === 1){
                        varDocument = contenu.getListDocumentContratAgenceSigne()[0];
                    }
                    else if(contenu.getListDocumentContratFournisseurSigne().length === 1){
                        varDocument = contenu.getListDocumentContratFournisseurSigne()[0];
                    }
                    else if(contenu.getDocumentDemande()) {
                        varDocument = contenu.getDocumentDemande();
                    }
                }
            }

            if(!_.isObject(varDocument)) {
                throw new Error('Il manque le "varDocument" pour la fonction "openPdfAndSelectDemande"');
            }

            _this.listDevis.map(function(item){
                if(item.getIdContenu() === contenu.getIdContenu()){
                    item.selected = varDocument;
                }
                else {
                    delete item.selected;
                }
                return item;
            });

            _this.listContrats.map(function(item){
                if(item.getIdContenu() === contenu.getIdContenu()){
                    item.selected = varDocument;
                }
                else {
                    delete item.selected;
                }
                return item;
            });

            contenu.selected = varDocument;
            _this.selectedDemande = {
                demande: contenu,
                document: varDocument,
                fromContenu: fromContenu
            };
        }

        /**
         * Permet de fermer le detail PDF
         */
        function closeDetailPDF(){
            _this.selectedDemande = null;
            _this.listDevis.map(function(item){
                delete item.selected;
            });
            _this.listContrats.map(function(item){
                delete item.selected;
            });
            $scope.$broadcast('getWidthFromListenWidth');
        }

        /**
         * Lorsque le composant demande-fournisseur remonte une information
         * @param obj
         */
        function onEmitFromDemandeFournisseur(obj){
            try {
                if(!_.isObject(obj)) {
                    throw new Error('Le paramètre "obj" doit être un objet');
                }

                if(!obj.hasOwnProperty('action')) {

                    // Si "actions" avec propriété "addMessages" on remonte l'info (Infos venant de send-to-gravitant)
                    if(_.isObject(obj.actions) && obj.actions.hasOwnProperty('addMessages') && _.isArray(obj.actions.addMessages) && obj.actions.addMessages.length) {
                        emit(obj);
                    }
                    else {
                        throw new Error('Pas de propriété "action" dans le paramètre obj');
                    }
                }

                if(obj.action === 'closeDetailPDF') {
                    closeDetailPDF();
                }

                if(_.isObject(obj.contenu)) {
                    if(obj.action === 'openEditDemande') {
                        openManagerDemande(obj.contenu);
                    }
                    if(obj.action === 'openCommunication') {
                        emit({
                            action: obj.action,
                            type: obj.type,
                            contenu: obj.contenu
                        });
                    }

                    // Ajout d'un message depuis le composant enfant "demande-fournisseur"
                    if(obj.action === 'addMessage') {
                        emit({
                            action: obj.action,
                            message: obj.message
                        });
                    }

                    // Ajout d'une demande depuis le composant enfant "demande-fournisseur"
                    if(obj.action === 'addOrUpdateDemande') {
                        //addOrUpdateDemande(obj.contenu);
                        emit({
                            action: obj.action,
                            demande: obj.contenu
                        });
                    }

                    // Affiche un PDF "demande-fournisseur"
                    if(obj.action === 'openPDF') {
                        openPdfAndSelectDemande(obj.contenu, obj.document, obj.fromContenu);

                        /*
                        _this.listDevis.map(function(item){
                            if(item.getIdContenu() === obj.contenu.getIdContenu()){
                                item.selected = obj.document;
                            }
                            else {
                                delete item.selected;
                            }
                        })

                        obj.contenu.selected = obj.document;
                        _this.selectedDemande = {
                            demande: obj.contenu,
                            document: obj.document
                        };*/
                    }

                }
            }
            catch(err) {
                var ErreurCollabService = $injector.get('ErreurCollabService');
                ErreurCollabService.logErreur('[ListDemandeDevisInterCtrl.onEmitFromDemandeFournisseur] - ' + err.message);
                ErreurCollabService = null;
            }
        }

        /**
         * Lorsque le composant manage-devis ou manage-contrat remonte une information
         * @param obj
         */
        function onEmitFromManageDemande(obj) {
            try {
                if (!obj) {
                    throw new Error('Il manque l\'objet "obj" !');
                }

                if(obj.action === 'closeManagerDevis' || obj.action === 'closeManagerContrat') {
                    //if(obj.contenu) openPdfAndSelectDemande(obj.contenu);
                    _this.hideOtherElement = false;
                    _this.currentDemande = null;
                }

                emit(obj);
                /*

                if(obj.devis) {
                    emit({
                        action: 'addOrUpdateDemande',
                        demande: obj.devis
                    });
                }
                else {
                    emit(obj);
                }*/
            }
            catch (err) {
                console.log('[ListDemandeDevisInterCtrl.onEmitFromManageDemande] Erreur : ' + err.message);
            }
        }

        /**
         * Lorsque le composant detail-pdf remonte une information
         * @param obj
         */
        function onEmitFromDetailPdf(obj){
            try {
                if (!obj) {
                    throw new Error('Il manque l\'objet "obj" !');
                }

                /*
                if(obj.action === 'onEmitFromDemandeFournisseur' && _.isObject(obj.obj)) {
                    onEmitFromDemandeFournisseur(obj.obj);
                }*/

                if(obj.action === 'closeDetailPDF') {
                    closeDetailPDF();
                }

                if(_.isObject(obj.contenu)) {
                    if (obj.action === 'addOrUpdateDemande') {
                        //addOrUpdateDemande(obj.contenu);
                        emit({
                            action: obj.action,
                            demande: obj.contenu
                        });
                    }
                }
            }
            catch (err) {
                console.log('[ListDemandeDevisInterCtrl.onEmitFromDetailPdf] Erreur : ' + err.message);
            }
        }

        /**
         * Lorsque la largeur de la div "listDemandes" change
         * @param width
         */
        function onChangeWithOfListDemandes(width){
            _this.widthListDemandes = width;
        }

        /**
         * Permet de récuperer les class pour les demandes par rapport à la largeur de la liste
         * @returns {string}
         */
        function getColClassDemande(){
            var arrayClassCss = [];
            // Si il y a une demande selectionnée
            if(!_.isNil(_this.selectedDemande)) {
                arrayClassCss.push('col-xs-12');
                /*
                if(!_.isNil(demande) && demandeIsSelected(demande)) {
                    arrayClassCss.push('demande-selected');
                }*/

                /*
                if(_this.widthListDemandes <= 600) {
                    arrayClassCss.push('col-xs-12');
                }
                else if(_this.widthListDemandes <= 1000) {
                    arrayClassCss.push('col-xs-6');
                }
                else if(_this.widthListDemandes <= 1200) {
                    arrayClassCss.push('col-xs-4');
                }*/
            }

            //if(_.isNil(_this.selectedDemande) || _this.widthListDemandes > 1200) {
            // Si pas de demande selectionnée
            if(_.isNil(_this.selectedDemande)) {
                //arrayClassCss.push('col-xs-12 col-sm-6 col-lg-4');
                arrayClassCss.push('col-xs-12');

                if(_this.widthListDemandes >= 1000) {
                    arrayClassCss.push('col-sm-6');
                }

                if(_this.widthListDemandes >= 1450) {
                    arrayClassCss.push('col-lg-4');
                }
            }

            return (arrayClassCss.length) ? _.join(arrayClassCss, ' ') : '';
        }

        /**
         * Permet d'emit vers le parent
         * @param valObj
         */
        function emit(valObj){
            var object = {
                obj: valObj
            };
            _this.onEmit(object);
        }

    }
})();
