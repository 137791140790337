(function() {
	'use strict';

	/**
	 * @desc Block de salarie communication
	 * @example <salarie-communication></salarie-communication>
	 */

	angular
		.module('collaboreApp')
		.component('salarieCommunication',{
			templateUrl: 'app/communication/salarie-communication.component.html',
			controllerAs: 'salariecommunicationcomponentctrl',
			bindings: {
				immeuble: '<',
				destinatairesEvenement: '<',
				portefeuille: '<',
				destinataires: '<',
				onChange: '&'
			},
			controller: SalarieCommunicationController
		});

	/*@ngInject*/
	function SalarieCommunicationController($rootScope, AnnuaireService, UtilsService) {

		var ctrl = this;
		var listeMetiersRecherche = [];
		ctrl.withPaieIcs = false;
		ctrl.listeMailsDestinataires = [];		// Liste des destinataires
		ctrl.listeContratsByMetiers = [];		// Liste des contarts par immeuble
		ctrl.listeSalaries = [];				// Liste des salariés

		// Paramétrages de l'app
		var defaultParametrage = $rootScope.current.userGroupe.getDefaultParametrage();
		if(defaultParametrage){
			if(defaultParametrage.hasOwnProperty('listeMetiersRechercheContrats')){
				listeMetiersRecherche = defaultParametrage.getListeMetiersRechercheContrats(true);
			}

			if(defaultParametrage.hasOwnProperty('withPaieIcs')){
				ctrl.withPaieIcs = defaultParametrage.getWithPaieIcs();
			}
		}

		ctrl.onChangeCheckboxMailSalarie = onChangeCheckboxMailSalarie;
		ctrl.onClickOpen = onClickOpen;
		ctrl.onClickClearDestinataires = onClickClearDestinataires;

		ctrl.$onInit = function() {

			if (ctrl.destinataires) {
				if (ctrl.destinataires.length) {
					for (var i = 0; i < ctrl.destinataires.length; i++){
						if (angular.isString(ctrl.destinataires[i])) {
							if (UtilsService.isMail(ctrl.destinataires[i])) {
								ctrl.listeMailsDestinataires.push(ctrl.destinataires[i]);
							}
						}
					}
				}
			}
		};

		function onChangeCheckboxMailSalarie(objMail){
			var index = ctrl.listeMailsDestinataires.indexOf(objMail.mail);
			if(objMail.checked) {
				if(index===-1) {
					ctrl.listeMailsDestinataires.push(objMail.mail);
				}
			}
			else {
				if(index!==-1) {
					ctrl.listeMailsDestinataires.splice(index,1);
				}
			}
			//console.log(ctrl.listeMailsDestinataires);
			onChangeComponent();
		}

		function onChangeComponent() {
			if (ctrl.onChange) {
				var obj = {
					obj: {
						salarieDestinataire: ctrl.listeMailsDestinataires
					}
				};
				ctrl.onChange(obj);
			}
		}

		function genereObjMails(mails){
			var objMails = [];
			if(mails.length !== 0) {
				for(var j = 0; j < mails.length; j++) {

					var index = ctrl.listeMailsDestinataires.indexOf(mails[j]);
					var obj = {
						checked: (index === -1) ? false : true,
						mail: mails[j]
					};
					objMails.push(obj);
				}
			}
			return objMails;
		}


		function onClickOpen(){
			ctrl.show = !ctrl.show;
			if (ctrl.show) {
				var numImmeuble = ctrl.immeuble.getNumImmeuble();
				var idPortefeuille;
				if (ctrl.portefeuille) {
					idPortefeuille = ctrl.portefeuille.getIdPortefeuille();
				}

				ctrl.loadingContrats = true;



				if(ctrl.withPaieIcs) {
					AnnuaireService
						.searchCoproprietaires(numImmeuble,idPortefeuille)
						.then(function(objRetour){

							var salaries = [];
							if(objRetour.listeSalaries.length) {

								for(var s = 0; s < objRetour.listeSalaries.length; s++){
									var salarie = objRetour.listeSalaries[s];
									salarie.checked = false;
									salarie.objMails = genereObjMails(salarie.getMails());
								}
								salaries = objRetour.listeSalaries;
							}

							ctrl.listeSalaries = salaries;

						})
						.finally(function(){
							ctrl.loadingContrats = false;
						});
				}
				else {
					AnnuaireService
						.searchContrats(false,numImmeuble,idPortefeuille,listeMetiersRecherche)
						.then(function(immeubles){
							var metiers = [];
							if (immeubles.length) {
								if (immeubles[0].metiers.length) {

									for(var m = 0; m < immeubles[0].metiers.length; m++){
										var metier = immeubles[0].metiers[m];

										if (metier.contrats.length) {
											for(var k = 0; k < metier.contrats.length; k++){
												var contrat = metier.contrats[k];
												contrat.checked = false;
												contrat.objMails = genereObjMails(contrat.getFournisseur().getMails());
											}
										}
										metiers.push(metier);
									}
								}
							}
							ctrl.listeContratsByMetiers = metiers;
						})
						.finally(function(){
							ctrl.loadingContrats = false;
						});
				}


			}
		}

		function onClickClearDestinataires(){
			ctrl.listeMailsDestinataires = [];
			if(ctrl.listeSalaries.length){
				for(var i = 0; i < ctrl.listeSalaries.length; i++) {
					ctrl.listeSalaries[i].objMails.checked = false;
					for(var m = 0; m < ctrl.listeSalaries[i].objMails.length ; m++){
						ctrl.listeSalaries[i].objMails[m].checked = false;
					}
				}
			}

			if(ctrl.listeContratsByMetiers.length){
				for(var j = 0; j < ctrl.listeContratsByMetiers.length; j++) {
					for(var k = 0; k < ctrl.listeContratsByMetiers[j].contrats.length; k++){
						for(var l = 0; l < ctrl.listeContratsByMetiers[j].contrats[k].objMails.length; l++){
							ctrl.listeContratsByMetiers[j].contrats[k].objMails[l].checked = false;
						}
					}
				}
			}
		}
	}
})();
