'use strict';


/**
 * Permet de setter "showGroupes" � true ou false selon si le collapse est ouvert ou pas
 */
angular.module('collaboreApp').directive('collapseIsOpen', function($parse) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {

      var model = $parse(attrs.collapseIsOpen);
      var val = model(scope);
      var modelSetter = model.assign;
      //console.log('-------------');
      //console.log(val);
      //console.log(element);

      //showGroupes
      element.on('show.bs.collapse', function (e) {


        //console.log('--- show ---');
        //console.log(val);
        //console.log(element);
        //console.log(e);

        if(element[0].id === e.target.id){
          val = true;
          scope.$apply(function(){
            modelSetter(scope, val);
          });
        }
        e.stopPropagation();
      });

      element.on('hide.bs.collapse', function (e) {

        //console.log('--- hide ---');
        //console.log(val);
        //console.log(element);
        //console.log(e);

        if(element[0].id === e.target.id) {

          val = false;
          scope.$apply(function () {
            modelSetter(scope, val);
          });
        }
        e.stopPropagation();
      });
    }
  };
});

angular.module('collaboreApp').directive('collapseOnClick', function() {
  return {
    restrict: 'A',
    scope: {
      collapseOnClick: '&'
    },
    link: function(scope, element) {

      element.on('show.bs.collapse', function (e) {

        if(element[0].id === e.target.id){
          scope.collapseOnClick({'action':{'open':true}});
        }
        e.stopPropagation();
      });

      element.on('hide.bs.collapse', function (e) {

        if(element[0].id === e.target.id) {
          scope.collapseOnClick({'action':{'close':true}});
        }
        e.stopPropagation();
      });
    }
  };
});

/*'use strict';


var checkPwd = function () {
  var directiveDefinitionObject =  {
    restrict: 'A',
    scope: false,
    controller: ['$scope','$element', function($scope,$element) {

      var check = function(){
        console.log($scope.curPwd);
        console.log($scope.newPwd);
      };

      $scope.$watch('confirmPwd',function(){
        check();
      });

      $scope.$watch('newPwd',function(newVal,oldVal){
        console.log(newVal);
        console.log(oldVal);
        check();
      });

    }]
  };
  return directiveDefinitionObject;
};

angular.module('collaboreApp').directive('checkPwd',[
  checkPwd
]);
*/
/*
angular.module('collaboreApp').directive('signatureCollab', [
    '$timeout',
  function ($timeout) {

    var signaturePad, canvasTemoin,ctxTemoin, canvasSignature, element, EMPTY_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=';
    return {
      restrict: 'EA',
      replace: true,
      template: '<div class="signature" ng-style="{height: height + \'px\', width: width + \'px\',\'margin-left\':\'auto\',\'margin-right\':\'auto\'}"><canvas id="canvasSignature" style="position:absolute" height="{{ height }}" width="{{ width }}" ng-style="padStyle"></canvas><canvas id="canvasTemoin"  height="{{ height }}" width="{{ width }}"></canvas></div>',
      scope: {
        accept: '=',
        clear: '=',
        height: '@',
        width: '@',
        signature: '=',
        optionsSignaturePad: '=',
        onBegin: '&',
        onEnd: '&',
        padStyle: '=',
        loadSignature: '&'
      },
      controller: [
        '$scope',
        function ($scope) {
          $scope.accept = function () {
            var signature = {};

            if (!signaturePad.isEmpty()) {
              signature.dataUrl = signaturePad.toDataURL();
              signature.isEmpty = false;
            } else {
              signature.dataUrl = EMPTY_IMAGE;
              signature.isEmpty = true;

            }
            signature.width = $scope.width;
            signature.height = $scope.height;

            return signature;
          };

          $scope.clear = function () {
            signaturePad.clear();
          };
        }
      ],
      link: function ($scope, $element) {

        $timeout(function(){
          var diffWidth = Math.ceil($scope.width*0.1);
          var diffHeight = Math.ceil($scope.height*0.1);
          var widthTemoin = $scope.width-diffWidth;
          var heightTemoin = $scope.height-diffHeight;
          var marginTopTemoin = Math.ceil(diffHeight/2);
          var marginLeftTemoin = Math.ceil(diffWidth/2);

          canvasTemoin = $element.find('#canvasTemoin').get(0);

          ctxTemoin = canvasTemoin.getContext('2d');
          ctxTemoin.beginPath();
          ctxTemoin.lineWidth='1';
          ctxTemoin.lineJoin='miter';
          ctxTemoin.strokeStyle='#CCCCCC';
          ctxTemoin.setLineDash([5,2]);
          ctxTemoin.rect(marginLeftTemoin,marginTopTemoin,widthTemoin,heightTemoin);
          ctxTemoin.stroke();
        });

        if(!angular.isObject($scope.optionsSignaturePad)){
          $scope.optionsSignaturePad = {
            'minWidth': 0.5,
            'maxWidth': 2.5,
            'penColor': 'black',
            'velocityFilterWeight': 0.7
          };
        }

        if($scope.onBegin){
          $scope.optionsSignaturePad.onBegin = $scope.onBegin;
        }
        if($scope.onEnd){
          $scope.optionsSignaturePad.onEnd = $scope.onEnd;
        }


        canvasSignature = $element.find('canvas#canvasSignature');
        element = $element;
        signaturePad = new SignaturePad(canvasSignature.get(0),$scope.optionsSignaturePad);




        if (!$scope.height) {
          $scope.height = 220;
        }
        if (!$scope.width) {
          $scope.width = 568;
        }


        if($scope.loadSignature){
          $scope.loadSignature().then(function(sign){
            signaturePad.fromDataURL(sign.dataUrl);
          });
        }

        if ($scope.signature && !$scope.signature.$isEmpty && $scope.signature.dataUrl) {
          signaturePad.fromDataURL($scope.signature.dataUrl);
        }
      }
    };
  }
]);*/