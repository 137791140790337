'use strict';

/**
 * @desc Icon pour passer/enlever un topic en caché
 * @example <icon-cache topic-state="" icon-size="small"></icon-cache>
 */

angular
	.module('collaboreApp')
	.component('iconCache',{
		templateUrl: 'app/topics/components/icon-cache.component.html',
		bindings : {
			idTopicState: '<',		// <Integer> Id du topicState
			state: '<',				// <Object> State du topicState
			iconSize: '@',			// <String> Taille de l'icone
			showLibelle: '<'
		},
		controllerAs: 'iconcachectrl',
		controller: ['$rootScope','$state','$scope', IconCacheController]
	});

function IconCacheController($rootScope,$state,$scope){

	var ctrl = this;

	ctrl.updateState = updateState;			// Update l'état du state du topic

    var listenerOnUpdateState = $scope.$on('updateState',function(event,idTopicState){
        if(ctrl.idTopicState === idTopicState){
            setLibelle(ctrl.state);
        }
    });

	ctrl.$onInit = function() {

		ctrl.loading = false;
		ctrl.libelleTooltip = '';


		switch(ctrl.iconSize){
			case 'small':
				ctrl.isSmall = true;
				break;
			default:
				ctrl.isSmall = false;
		}
		setLibelle(ctrl.state);
	};

	ctrl.$onDestroy = function(){
        listenerOnUpdateState();
	};

	/**
	 * Set le libelle du tooltip
	 * @param state
	 */
	function setLibelle(state){
		if(state.isCache()){
			ctrl.libelleTooltip = 'Ne plus cacher';
		}else{
			ctrl.libelleTooltip = 'Cacher jusqu\'à la prochaine réponse';
		}
	}

	/**
	 * Permet d'update le state
	 */
	function updateState(){

		try{

			if(angular.isUndefined(ctrl.idTopicState)){
				throw new Error('[iconCache] Il manque l\'idTopicState !');
			}

			ctrl.loading = true;
			ctrl.state.toggleAddRemoveToCache(ctrl.idTopicState).then(function(state){

				setLibelle(state);

				// Supprimé de la liste si
				// Page list ou favoris et etat caché ou archive
				// Page archives et etat différent de caché et archive
				if((($state.is('topics.list') || $state.is('topics.favoris')) && (state.isCache() || state.isArchive())) ||
					($state.is('topics.archives') && (!state.isCache() && !state.isArchive()))){
					$scope.$emit('removeTopic', ctrl.idTopicState);
				}

				$rootScope.$broadcast('updateState',ctrl.idTopicState);

			},function(msg){
				throw new Error('Erreur : '+msg);
			}).finally(function(){
				ctrl.loading = false;
			});
		}
		catch(err){
			console.log(err.message);
		}
	}



}


