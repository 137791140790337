(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .factory('ParametrageSuperAdminResource', ParametrageSuperAdminResource);



  /** @ngInject */
  function ParametrageSuperAdminResource(COLLAB_CONF, COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {


      return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_SUPER_ADMIN_PARAMETRAGE, null, {

        post: {
          method: 'POST',
          transformRequest: function (data) {
            return $httpParamSerializerJQLike(data);
          },
          headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }
      });

  }



})();
