(function() {
  'use strict';

  angular
    .module('collaboreApp')
    .factory('ComfactCompte', ComfactCompteModel);

  /** @ngInject */
  function ComfactCompteModel(Main, UtilsService) {

    function ComfactCompte() {
      /*
      // Si l'arguement est un modele ics
      if(arguments.length && _.isObject(arguments[0]) && arguments[0].isModele){
        this.hydrate({
          CCCCCC: arguments[0].getCCCCCC(),
          montant: arguments[0].getMontant()
        });
        return;
      }*/
      Main.apply(this, arguments);
    }

    ComfactCompte.prototype = {

      model: 'ComfactCompte',
      isComfactCompte: true,

      // idcompte
      getIdcompte: function () {
        return this.idcompte;
      },
      setIdcompte: function (val) {
        this.idcompte = val;
      },

      // idfacture
      getIdfacture: function () {
        return this.idfacture;
      },
      setIdfacture: function (val) {
        this.idfacture = val;
      },

      // CCCCCC
      getCCCCCC: function () {
        return this.CCCCCC;
      },
      setCCCCCC: function (val) {
        this.CCCCCC = val;
      },

      // BB
      getBB: function () {
        return this.BB;
      },
      setBB: function (val) {
        this.BB = val;
      },

      // libelle
      getLibelle: function () {
        return this.libelle;
      },
      setLibelle: function (val) {
        this.libelle = val;
      },

      // montant
      getMontant: function () {
        return this.montant;
      },
      setMontant: function (val) {
        this.montant = val;
      },

      // date
      getDate: function (format) {
        if(!_.isNil(format)) {
          if(!_.isString(format) && format) format = null;
          return UtilsService.parseDate(this.date, format);
        }
        return this.date;
      },
      setDate: function (val) {
        this.date = val;
      }
    };

    angular.extend(ComfactCompte.prototype, Main.prototype);

    return ComfactCompte;
  }
})();
