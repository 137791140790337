'use strict';

angular.module('collaboreApp').directive('sliderProgression',[function() {
    return {
        restrict: 'E',
        replace: false,
        //templateUrl: 'app/layout/slider-progression.directive.html',
        template: '<input click-prevent type="text" data-slider-handle="custom" id="sliderAvancementDetailTopic" data-slider-id="sliderDetail"/>',
        scope: {
            onReady: '&'
        },

        controller:['$scope','$element','$window', function($scope,$element,$window){

             var $slide = angular.element($element[0]).find('input');

            var instance = new Slider($slide[0], {
                step: 1,
                min: 1,
                max: 10,
                value: 0,
                ticks:[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                ticks_labels: ['0', '1', '2', '3', '4','5','6','7','8','9','10'],
                tooltip: 'hide'
            });

            /**
             * Resize le slider
             */
            function refreshSlider(){
                instance.refresh();
            }


            var w = angular.element($window);
            w.bind('resize', refreshSlider);

            $scope.onReady({'instance':instance});

            /*
            var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
            var eventer = window[eventMethod];
            var resizeEvent = eventMethod === 'attachEvent' ? 'onresize' : 'resize';
            eventer(resizeEvent,refreshSlider);

            console.log('instance',instance);*/
        }]
    };
}]);