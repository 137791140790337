(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name collaboreApp.DevisService
     * @description
     * # DevisService
     * Service in the collaboreApp.
     */

    angular
        .module('collaboreApp')
        .service('CalculatriceService', CalculatriceService);

    /*@ngInject*/
    function CalculatriceService(MainService, $q,  ModalsService, CalculatriceResource, CalculatriceAjax) {
        var ctrl = this;
        ctrl.rechercheLignesCalculatrice = rechercheLignesCalculatrice;
        ctrl.ajoutLigneCalculatrice = ajoutLigneCalculatrice;
        ctrl.supprimeLigneCalculatrice = supprimeLigneCalculatrice;
        ctrl.downloadExcel = downloadExcel;



        /**
         * Permet de chercher les lignes du tableau dans la bdd
         * @param newLigne
         * @returns {*}
         */
        function rechercheLignesCalculatrice(newLigne){
            var deferred = $q.defer();

            var params = {
                action: 'rechercheLignesCalculatrice',
                idTopic: newLigne.getIdTopic()
            };
            try {
                CalculatriceResource
                    .post(params)
                    .$promise
                    .then(function onSuccess(json){
                        deferred.resolve(MainService.convertJsonToModel(json, 'result', 'LigneCalculatrice', deferred));
                    })
                    .catch(function onError(err) {
                        deferred.reject(err);
                    });
            }
            catch (e) {
                ModalsService.alertErreur(e.message);
                deferred.reject(e.message);
            }
            return deferred.promise;
        }

        /**
         * Permet de rajouter une ligne dans la bdd
         * @param newLigne
         * @returns {*}
         */
        function ajoutLigneCalculatrice(newLigne){
            var deferred = $q.defer();

            var params = {
                action: 'ajoutLigneCalculatrice',
                jsonCalculatrice: newLigne.toJson()
            };
            try {
                CalculatriceResource
                    .post(params)
                    .$promise
                    .then(function onSuccess(json){
                        deferred.resolve(MainService.convertJsonToModel(json, 'result', 'LigneCalculatrice', deferred));
                    })
                    .catch(function onError(err) {
                        deferred.reject(err);
                    });
            }
            catch (e) {
                ModalsService.alertErreur(e.message);
                deferred.reject(e.message);
            }
            return deferred.promise;
        }

        /**
         * Permet de supprimer une ligne dans la bdd
         * @param index
         * @returns {*}
         */
        function supprimeLigneCalculatrice(index){
            var deferred = $q.defer();

            var params = {
                action: 'supprimeLigneCalculatrice',
                jsonCalculatrice: index.toJson()
            };
            try {
                CalculatriceResource
                    .post(params)
                    .$promise
                    .then(function onSuccess(json){
                        deferred.resolve(MainService.convertJsonToModel(json, 'result', 'LigneCalculatrice', deferred));
                    })
                    .catch(function onError(err) {
                        deferred.reject(err);
                    });
            }
            catch (e) {
                ModalsService.alertErreur(e.message);
                deferred.reject(e.message);
            }
            return deferred.promise;
        }

        /**
         * Permet de demander le fichier à la bdd
         * @param idTopic
         * @returns {*}
         */
        function downloadExcel(idTopic) {
            var deferred = $q.defer();

            var params = {
                action: 'exportExcel',
                idTopic: idTopic
            };

            CalculatriceAjax.downloadExcel(
                params,function(data) {
                    deferred.resolve(data);
                }, function(error) {
                    deferred.reject('Problème avec CalculatriceService.downloadExcelReporting(), Erreur (' + error.status + ') : ' + error.statusText);
                }
            );
            return deferred.promise;
        }
    }
})();
