(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.DestinataireEmail
   * @description
   * # DestinataireEmail
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('DestinataireEmail',
      DestinataireEmailModel
    );
  /** @ngInject */
  function DestinataireEmailModel(Main) {

    function DestinataireEmail(objetData) {
      Main.call(this, objetData);
    }

    DestinataireEmail.prototype = {
      model: 'DestinataireEmail',
      isDestinataireEmail: true,

      // idDestinataireEmail
      getIdDestinataireEmail: function(){
        return this.idDestinataireEmail;
      },
      setIdDestinataireEmail: function(id){
        this.idDestinataireEmail = id;
      },

      // email
      getEmail: function(){
        return this.email;
      },
      setEmail: function(val){
        this.email = val;
      },

      // dateCreation
      getDateCreation: function(){
        return this.dateCreation;
      },
      setDateCreation: function(val){
        this.dateCreation = val;
      },

      getIdGroupe: function(){
        return null;
      },
      getDefaultLibelle: function(){
        return this.getEmail();
      },
      getNom: function(){
        return this.getDefaultLibelle();
      },
      isUser: function(){
        return true;
      },
      getLibelle: function(){
        return this.getDefaultLibelle()
      }
    };

    angular.extend(DestinataireEmail.prototype, Main.prototype);

    return DestinataireEmail;
  }
})();
