(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.TopicManagerService
   * @description
   * # TopicService
   * Service in the collaboreApp.
   */
  angular
    .module('collaboreApp')
    .service('TopicManagerService',
      TopicManagerService
    );

  /** @ngInject */
  function TopicManagerService(COLLAB_CONF, UtilsService, TagsService) {

    var _this = this;
    _this.isBlocked = null;
    init();
    _this.getCurrentTopicStates = getCurrentTopicStates;
    _this.setCurrentTopicStates = setCurrentTopicStates;

    _this.getCurrentTopic = getCurrentTopic;
    _this.currentTopicHasEvenement = currentTopicHasEvenement;
    _this.currentTopicHasGed = currentTopicHasGed;
    _this.currentTopicHasGedV2Enabled = currentTopicHasGedV2Enabled;
    _this.currentTopicIsModifiableByAgence = currentTopicIsModifiableByAgence;
    _this.getEvenementFromCurrentTopic = getEvenementFromCurrentTopic;
    _this.getTypeOfCurrentEvent = getTypeOfCurrentEvent;
    _this.currentEventIsIntervention = currentEventIsIntervention;
    _this.eventHasPortefeuilleBlocked = eventHasPortefeuilleBlocked;
    _this.topicIsBlocked = topicIsBlocked;

    _this.hasListTopicRefFromCurrentTopic = hasListTopicRefFromCurrentTopic;
    _this.getListTopicRefFromCurrentTopic = getListTopicRefFromCurrentTopic;

    _this.recurseUpdateDocumentInTopic = recurseUpdateDocumentInTopic;
    _this.destroy = destroy;

    function init(){
      _this.listDocumentTopicByCat = null;
      _this.currentTopicStates = null;
      _this.isBlocked = null;
    }

    function getCurrentTopicStates(){
      return _this.currentTopicStates;
    }
    function setCurrentTopicStates(val){
      _this.currentTopicStates = angular.copy(val);
      _this.isBlocked = _this.topicIsBlocked();
    }

    function getCurrentTopic(){
      if(!_.isObject(_this.currentTopicStates) || !_.isObject(_this.currentTopicStates.getTopic()))
        return null;
      return _this.currentTopicStates.getTopic();
    }

    /**
     * Permet de savoir si le currentTopicStates a un événement
     * @returns {boolean}
     */
    function currentTopicHasEvenement(){
      if(!_.isObject(_this.currentTopicStates)) return false;
      var topic = _this.currentTopicStates.getTopic();
      var evenement = topic.getEvenement();
      if(_.isObject(evenement)) return true;
      return false;
    }

    /**
     * Permet de savoir si le currentTopicStates à un topic avec un portefeuille qui a la ged
     * @returns {boolean}
     */
    function currentTopicHasGed(){
      if(!_.isObject(_this.currentTopicStates)) return false;
      return UtilsService.canSendToGed(_this.currentTopicStates.getTopic());
    }

    /**
     * Permet de savoir si le currentTopicStates à un topic avec un portefeuille qui a la ged v2 activée
     * @returns {boolean}
     */
    function currentTopicHasGedV2Enabled(){
      if(!_.isObject(_this.currentTopicStates)) return false;
      return UtilsService.canSendToGed(_this.currentTopicStates.getTopic(), 2);
    }

    /**
     * Permet de savoir si le currentTopicStates à un topic modifiable
     * @returns {*|boolean|boolean}
     */
    function currentTopicIsModifiableByAgence(){
      if(!_.isObject(_this.currentTopicStates)) return false;
      return (UtilsService.getParametrage('agenceICS') && _this.currentTopicStates.getTopic().hasEventModifiable());
    }

    /**
     * Permet de récupèrer l'événement dans le topic actuel
     * @returns {boolean}
     */
    function getEvenementFromCurrentTopic(){
      if(!_.isObject(_this.currentTopicStates)) return false;
      var topic = _this.currentTopicStates.getTopic();
      var evenement = topic.getEvenement();
      if(_.isObject(evenement)) return evenement;
      return null;
    }

    function getTypeOfCurrentEvent(){
      var type = 'syndic';
      if(!_.isObject(_this.currentTopicStates)) return type;
      var topic = _this.currentTopicStates.getTopic();
      var evenement = topic.getEvenement();
      if(_.isObject(evenement)) {
        var infosEvent = UtilsService.getInfosEvenement(evenement);
        if(_.isObject(infosEvent) && infosEvent.isGestion) type = 'gerance';
      }
      return type;
    }

    function currentEventIsIntervention(){
      var event = getEvenementFromCurrentTopic();
      if(!_.isNil(event)){
        var tagTypeType = TagsService.getTagForType(event.getTagsType(), COLLAB_CONF.TAG_TYPE_TYPE);
        if(tagTypeType && tagTypeType.isTagTypeIntervention()) return true
      }
      return false;
    }

    function eventHasPortefeuilleBlocked(){
      var event = _this.getEvenementFromCurrentTopic();
      if(_.isObject(event) && event.portefeuilleIsBlocked()) return true;
      return false;
    }

    function topicIsBlocked(){
      var topic = this.getCurrentTopic();
      if(!_.isObject(topic)) return null;
      return topic.isBlocked;
    }

    /**
     * Permet de savoir si le topic a des topicsRef sans les récupérer
     * @returns {boolean}
     */
    function hasListTopicRefFromCurrentTopic(){
      if(!_.isObject(_this.currentTopicStates) || !_.isObject(_this.currentTopicStates.getTopic()))
        return false;
      return (_this.currentTopicStates.getTopic().getTopicsRef().length) ? true : false;
    }

    /**
     * Recupère le topicsRef du topic actuel
     * @returns {*}
     */
    function getListTopicRefFromCurrentTopic(){
      if(!_.isObject(_this.currentTopicStates) || !_.isObject(_this.currentTopicStates.getTopic()))
        return null;
      return _this.currentTopicStates.getTopic().getTopicsRef();
    }

    /**
     * Permet de mettre à jour des documents dans un objet
     * @param objDocument
     * @param obj
     * @param parentObj
     * @param currentProperty
     * @returns {boolean}
     */
    function recurseUpdateDocumentInTopic(objDocument, obj, parentObj, currentProperty){
      var isDoc = false;
      if(_.isObject(obj)) {
        if(_.isArray(obj)) {
          obj.map(function (item){
            isDoc = recurseUpdateDocumentInTopic(objDocument, item);
          });

          // Si le tableau actuel contient des docs on le copy pour que les composant de document prenent en compte les modif
          if(isDoc && !_.isNil(parentObj) && !_.isNil(currentProperty)) {
            parentObj[currentProperty] = angular.copy(obj);
          }
        }
        else {
          if(_.isObject(obj) && obj.isModel && obj.model === 'Document') {
            if(_.isArray(objDocument) && objDocument.length){
              objDocument.map(function(doc){
                if(_.isObject(doc) && doc.isModel && doc.model === 'Document') {
                  if(obj.getGuid() === doc.getGuid()) {
                    obj.setData(doc);
                    isDoc = true;
                  }
                }
              });
            }
            else if(_.isObject(objDocument) && objDocument.isModel && objDocument.model === 'Document'){
              if(obj.getGuid() === objDocument.getGuid()) {
                obj.setData(objDocument);
                isDoc = true;
              }
            }
          }
          else {
            Object.keys(obj).map(function (property){
              recurseUpdateDocumentInTopic(objDocument, obj[property], obj, property);
            });
          }
        }
      }
      return isDoc;
    }

    function destroy() {
      init();
    }
  }
})();
