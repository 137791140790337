(function () {
    'use strict';

    angular
        .module('collaboreApp')
        .component('generateDocument', {
            templateUrl: 'app/topics/fournisseurs/generate-document/generate-document.component.html',
            controller: GenerateDocumentController,
            controllerAs: 'generatedocumentctrl',
            bindings: {
                demandeOptions:'<',
                onSubmit: '&',
                infosEvenement: '<',    // <Object> Infos de l'événement

                // Si Modal
                resolve: '<',
                close: '&',
                dismiss: '&'
            }
        });

    // TODO: Pour le moment ce composant ne sert que pour le paramètre "hideProprietaireInDocument"
    /** @ngInject */
    function GenerateDocumentController(UtilsService, DemandeOptions) {
        var ctrl = this;

        ctrl.demandeOptions = new DemandeOptions();

        ctrl.onClickSave = onClickSave;
        ctrl.onClickClose = onClickClose;

        ctrl.$onInit = function () {

            if(ctrl.resolve) {
                ctrl.isModal = true;
                ctrl.demandeOptions = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'demandeOptions'));
                ctrl.infosEvenement = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'infosEvenement'));
            }
            if(_.isNil(ctrl.demandeOptions)) {
              ctrl.demandeOptions = new DemandeOptions();
              ctrl.demandeOptions.hideProprietaireInDocument = UtilsService.getParametrage('hideProprietaireInDocument');
            }

            if(_.isObject(ctrl.infosEvenement)){
              ctrl.displayDemandeOptions = (!_.isNil(ctrl.infosEvenement.noPersoProprietaire));
            }
        };

        ctrl.$onDestroy = function () {
        };

        /**
         * Lors du clique sur le bouton sauvegarder
         */
        function onClickSave(){
            emit({action: 'generateDocument', demandeOptions: ctrl.demandeOptions});
        }

        /**
         * Permet d'emit vers composant parent
         * @param objRetour
         */
        function emit(objRetour){
            if(ctrl.isModal) ctrl.close({$value: objRetour});
            else ctrl.onSubmit({obj: objRetour})
        }

        function onClickClose(){
            ctrl.dismiss({$value: 'cancel'});
        }
    }
})();

