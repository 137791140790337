(function() {
  'use strict';

  angular
    .module('collaboreApp')
    .factory('GroupeAssociationGroupe', GroupeAssociationGroupeModel);

  /** @ngInject */
  function GroupeAssociationGroupeModel(Main, UtilsService) {

    function GroupeAssociationGroupe(contenuData) {
      Main.apply(this, arguments);
    }

    GroupeAssociationGroupe.prototype = {

      model: 'GroupeAssociationGroupe',
      isGroupeAssociationGroupe: true,

      // groupe
      getGroupe: function(){
        return this.groupe;
      },
      setGroupe: function(g){
        if(_.isObject(g) && !g.isModel){
          var Groupe = Main.$injector.get('Groupe');
          g = new Groupe(g);
          Groupe = null;
        }
        this.groupe = g;
      },

      // dateCreation
      getDateCreation: function (format) {
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateCreation, format);
        }
        return this.dateCreation;
      },
      setDateCreation: function (date) {
        this.dateCreation = date;
      }
    };

    angular.extend(GroupeAssociationGroupe.prototype, Main.prototype);

    return GroupeAssociationGroupe;
  }
})();
