(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.ReceiptBody
   * @description
   * # ReceiptBody
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('ReceiptBody', ReceiptBodyModel);

  /** @ngInject */
  function ReceiptBodyModel(Main, Receipt) {

    function ReceiptBody(data) {
      Main.apply(this, arguments);
      this.hydrate(data);
    }

    ReceiptBody.prototype = {

      model: 'ReceiptBody',
      isReceiptBody: true,

      // receiptData
      getReceiptData: function(){
        return this.receiptData;
      },
      setReceiptData: function(val){
        if(_.isObject(val) && _.isNil(val.isModel)) val = new Receipt(val);
        this.receiptData = val;
      },

      // requestId
      getRequestId: function(){
        return this.requestId;
      },
      setRequestId: function(val){
        this.requestId = val;
      },

      // result
      getResult: function(){
        return this.result;
      },
      setResult: function(val){
        this.result = val;
      },

      /*
      // errors
      getErrors: function(){
        return this.errors;
      },
      setErrors: function(val){
        this.errors = val;
      },
      */
      isError: function(){
        //return (this.getResult() !== "success" || (_.isArray(this.getErrors()) && !_.isEmpty(this.getErrors())));
        return (this.getResult() !== "success");
      },

      hasCustomerDatas: function(){
        if(_.isObject(this.getReceiptData()))
          return this.getReceiptData().hasCustomerDatas();

        return false;
      },

      hasMerchantDatas: function(){
        if(_.isObject(this.getReceiptData()))
          return this.getReceiptData().hasMerchantDatas();

        return false;
      }
    };

    angular.extend(ReceiptBody.prototype, Main.prototype);

    return ReceiptBody;
  }
})();
