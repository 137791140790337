(function() {
  'use strict';
  /**
   * @ngdoc factory
   * @name collaboreApp.ProxyRelanceAutoConfig
   * @description
   * # ProxyRelanceAutoConfig
   */

  angular.module('collaboreApp').factory('ProxyRelanceAutoConfig', ProxyRelanceAutoConfigModel);

  /** @ngInject */
  function ProxyRelanceAutoConfigModel(Main) {

    function ProxyRelanceAutoConfig(objetData) {
      Main.apply(this, arguments);
      this.hydrate(objetData);
    }

    ProxyRelanceAutoConfig.prototype = {

      isProxy: true,
      model: 'ProxyRelanceAutoConfig',

      getId: function(value){
        return this.id;
      },
      setId: function(val){
        this.id = val;
      },

      // Periode
      getPeriode: function(){
        return {id: this.idPeriode};
      },
      setPeriode: function(val){
        if(_.isObject(val)) val = val.id;
        this.idPeriode = val;
      },

      // enabled
      getEnabled: function(){
        return this.enabled;
      },
      setEnabled: function(bool){
        this.enabled = bool;
      },

      // heureRelance
      getHeureRelance: function(){
        return this.period;
      },
      setHeureRelance: function(val){
        this.heureRelance = val;
      }

    };

    angular.extend(ProxyRelanceAutoConfig.prototype, Main.prototype);

    return ProxyRelanceAutoConfig;
  }
})();
