(function () {
    'use strict';

    /**
     * @desc Composant pour ajouter/modifier le commentaire d'un contenu
     * @example <commentaire-contenu></commentaire-contenu>
     */

    angular
        .module('collaboreApp')
        .component('commentaireContenu', {
            templateUrl: 'app/topics/components/commentaire-contenu/commentaire-contenu.component.html',
            bindings: {
                contenu: '<',
                onEmit: '&'
            },
            controllerAs: 'commentairecontenuctrl',
            controller: CommentaireContenuController
        });

    /*@ngInject*/
    function CommentaireContenuController($q, ModalsService, ContenuService, UtilsService) {

        var _this = this;
        var currentCommentaire = null;
        _this.loading = false;
        _this.editMode = false;
        _this.onClickEditcommentaire = onClickEditcommentaire;
        _this.onSubmit = onSubmit;
        _this.onClickCancel = onClickCancel;

        _this.$onInit = function () {
            init(_this.contenu);
        };

        _this.$onChanges = function(changes){
            if(_.isObject(changes.contenu) && !changes.contenu.isFirstChange()){
                _this.contenu = changes.contenu.currentValue;
                init(changes.contenu.currentValue);
            }
        }

        /**
         * Initialisation
         * @param contenu
         */
        function init(contenu){
          if(_.isObject(contenu)) {
              currentCommentaire = angular.copy(contenu.getCommentaire());
          }
        }

        function onClickEditcommentaire(){
            _this.editMode = true;
        }

        /**
         * Lors du  submit du formulaire du commentaire
         * @param form
         */
        function onSubmit(form){
            save(_this.contenu.commentaire);
        }

        /**
         * Lors du clique sur le bouton pour annuler le commentaire
         */
        function onClickCancel(){
            _this.editMode = false;
            _this.contenu.setCommentaire(currentCommentaire);
            //emit({action: 'closeCommentaire'});
        }

        /**
         * Permet de sauverager le commentaire
         * @param commentaire
         */
        function save(commentaire){
            _this.loading = true;
            ContenuService
                .updateCommentaire(_this.contenu.getIdContenu(), commentaire)
                .then(function(contenu){
                    emit({action: 'updateCommentaire', commentaire: contenu.getCommentaire()});
                    _this.editMode = false;
                    currentCommentaire = angular.copy(contenu.getCommentaire());
                })
                .finally(function(){
                    _this.loading = false;
                });
        }

        /**
         * Permet d'emit une information vers le parent
         * @param valObj
         */
        function emit(valObj) {
            var object = {obj: {}};
            object.obj = _.merge(object.obj, valObj);
            _this.onEmit(object);
        }
    }
})();
