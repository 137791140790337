(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.CampingInformations
   * @description
   * # CampingInformations
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('CampingInformations', CampingInformationsModel);

  /** @ngInject */
  function CampingInformationsModel(Main) {

    function CampingInformations(contenuData) {
      Main.apply(this, arguments);
      this.hydrate(contenuData);
    }

    CampingInformations.prototype = {

      model: 'CampingInformations',
      isCampingInformations: true,

      // nom
      getNom: function(){
        return this.nom;
      },
      setNom: function(val){
        this.nom = val;
      },

      // typeCompte
      getTypeCompte: function(){
        return this.typeCompte;
      },
      setTypeCompte: function(val){
        this.typeCompte = val;
      },
      isTypeCompteTechnique: function(){
        return (this.getTypeCompte() === "technique") ? true : false;
      },
      isTypeCompteMenage: function(){
        return (this.getTypeCompte() === "menage") ? true : false;
      }
    };

    angular.extend(CampingInformations.prototype, Main.prototype);

    return CampingInformations;
  }
})();
