(function() {
  'use strict';

  angular
    .module('collaboreApp')
    .factory('GroupeAssociation', GroupeAssociationModel);

  /** @ngInject */
  function GroupeAssociationModel(Main, UtilsService) {

    function GroupeAssociation(contenuData) {
      Main.apply(this, arguments);
    }

    GroupeAssociation.prototype = {

      model: 'GroupeAssociation',
      isGroupeAssociation: true,

      // class
      getClass: function(){
        return this.class;
      },
      setClass: function(val){
        this.class = val;
      },

      // idGroupeAssociation
      getIdGroupeAssociation: function(){
        return this.idGroupeAssociation;
      },
      setIdGroupeAssociation: function(id){
        this.idGroupeAssociation = id;
      },

      // listGroupeAssociationGroupe
      getListGroupeAssociationGroupe: function(){
        return this.listGroupeAssociationGroupe;
      },
      setListGroupeAssociationGroupe: function(val){
        if(_.isArray(val) && val.length){
          var GroupeAssociationGroupe = Main.$injector.get('GroupeAssociationGroupe');
          val = val.map(function(item) {
            if(!item.isModel) item = new GroupeAssociationGroupe(item);
            return item;
          });
          GroupeAssociationGroupe = null;
        }
        this.listGroupeAssociationGroupe = val;
      },

      // groupeAssocie
      getGroupeAssocie: function(){
        return this.groupeAssocie;
      },
      setGroupeAssocie: function(dest){
        var instanceGroupe = dest;
        if(_.isObject(instanceGroupe) && !instanceGroupe.isModel){
          var Groupe = Main.$injector.get('Groupe');
          instanceGroupe = new Groupe(instanceGroupe);
          Groupe = null;
        }
        this.groupeAssocie = instanceGroupe;
      },

      // dateCreation
      getDateCreation: function (format) {
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateCreation, format);
        }
        return this.dateCreation;
      },
      setDateCreation: function (date) {
        this.dateCreation = date;
      },

      // dateFin
      getDateFin: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateFin, format);
        }
        return this.dateFin;
      },
      setDateFin: function(val){
        this.dateFin = val;
      },

      // typeGroupeAssocie
      getTypeGroupeAssocie: function(){
        return this.typeGroupeAssocie;
      },
      setTypeGroupeAssocie: function(id){
        this.typeGroupeAssocie = id;
      },

      collaborateurIsInListGroupeAssociationGroupe: function(collaborateur){
        var isInList = false;
        if(_.isArray(this.getListGroupeAssociationGroupe()) && this.getListGroupeAssociationGroupe().length){
          for(var i = 0; i < this.getListGroupeAssociationGroupe().length; i++){
            if(_.isObject(this.getListGroupeAssociationGroupe()[i].getGroupe())){
              if(_.isObject(collaborateur) && collaborateur.isGroupe && this.getListGroupeAssociationGroupe()[i].getGroupe().getIdGroupe() === collaborateur.getIdGroupe()){
                isInList = true;
                break;
              }
              else if(_.isNumber(collaborateur) && this.getListGroupeAssociationGroupe()[i].getGroupe().getIdGroupe() === collaborateur){
                isInList = true;
                break;
              }
            }
          }
        }
        return isInList;
      }

    };

    GroupeAssociation.mapNameModelByTypeGroupeAssocie = {
      immeuble: 'GroupeAssociationImmeuble',
      proprietaire: 'GroupeAssociationProprietaire'
    };

    angular.extend(GroupeAssociation.prototype, Main.prototype);

    return GroupeAssociation;
  }
})();
