(function () {
  'use strict';

  /**
   * @desc Page detail d'un topic
   * @example <layout-topic-detail></layout-topic-detail>
   */


  angular
    .module('collaboreApp')
    .component('layoutTopicDetail', {
      bindings: {
        currentTab: '<'
      },
      templateUrl: 'app/layout/layout-topic-detail.component.html',
      controllerAs: 'layouttopicdetailctrl',
      controller: LayoutTopicDetailController
    });

  /** @ngInject */
  function LayoutTopicDetailController(COLLAB_CONF, $log, $injector, $rootScope, $scope, SocketNotificationService, TopicManagerService, DocumentManagerService, DocumentsTopicService, $location, $state, $stateParams, $filter, $timeout, $q, TopicService, TopicStatesService, ContenuService, DocumentService, HistoriqueAvancementService, UtilsService, sweet, ModalsService, WebsocketServices, NotificationService, SinistreService) {

    // Id du topic récupéré des paramètres de l'URL
    var topicId = parseInt($stateParams.topicId);

    var ctrl = this;
    //var listenerOnSocketConnected = null;
    ctrl.notMobile = UtilsService.notMobile;
    ctrl.collapseMenu = false;
    if(!ctrl.notMobile())
      ctrl.collapseMenu = true;
    //var filterTags = [];

    //ctrl.showCommunication = $stateParams.communication.show;

    ctrl.paramsCommunication = {
      idTopic: null,
      sms: $stateParams.communication.sms,
      message: $stateParams.communication.message
    };

    ctrl.listeDocuments = {};
    ctrl.listeContenuImportant = [];
    ctrl.topicId = topicId;
    ctrl.onClickTab = onClickTab;

    ctrl.getPrevisualisation = getPrevisualisation;				// Recupère la prévisualisation du contenu
    ctrl.addContenuMessage = addContenuMessage;					// Ajoute un contenu à la volée
    ctrl.openContenu = openContenu;								// Ouvre le contenu
    ctrl.parseObjetMessage = UtilsService.parseObjetMessage;	// Permet de garder les attributs style du HTML, sinon il sont supprimé
    ctrl.scrollToContenu = UtilsService.scrollToContenu;
    ctrl.goToContenuImportant = goToContenuImportant;
    // ctrl.showOldContenus = showOldContenus;						// Permet d'afficher les anciens contenu déjà lu
    ctrl.isOpen = isOpen;										// Est-ce que l'item est open
    ctrl.onInsertContenuEvenement = onInsertContenuEvenement;	// Executé lors de l'ajoute de l'événement à un topic existant
    ctrl.onUpdateContenuEvenement = onUpdateContenuEvenement;	// Executé lors de la modification de l'événement à un topic existant
    //ctrl.onUpdateContenuMessage = onUpdateContenuMessage;		// Executé lors de la modification d'un message à un topic existant
    ctrl.onEmitContenuEvenement = onEmitContenuEvenement;		// Lors d'une remonté d'info du composant contenu-evenement

    ctrl.refreshTopicDetail = refreshTopicDetail;				// Refresh la page detail

    ctrl.addEvenement = addEvenement;							// Affiche le formulaire d'événement si pas topic sans event
    ctrl.cancelAddEvenement = cancelAddEvenement;				// Cache le formulaire d'événement si pas topic sans event

    ctrl.onClickTag = onClickTag;								// Lors du clique sur un tag du topic
    ctrl.onDeleteTag = onDeleteTag;								// Lors de la suppression d'un tag du topic
    ctrl.onClickCommunicationFromContenuEvenement = onClickCommunicationFromContenuEvenement;					// Lorsque le composant contenu-evenement emet un clique d'ouverture de communication
    ctrl.openCommunication = openCommunication;					// Permet d'ouvrir la fenetre de communication
    ctrl.closeCommunication = closeCommunication;				// Permet de fermer la fenetre de communication
    ctrl.onClickUrgent = onClickUrgent;							// Permet de gérer l'état "Urgent" de l'item evenement
    ctrl.onClickCloture = onClickCloture;						// Permet de gérer l'état "Cloturé" de l'item evenement
    ctrl.onClickSendGed = onClickSendGed;						// Permet de créer un pdf dans la ged
    ctrl.oncClickManageSinistre = oncClickManageSinistre;		// Permet de gérer le sinistre


    ctrl.allowEditEvenement = allowEditEvenement;

    //--------- Slider ----------
    //ctrl.slideSaveAvancement = slideSaveAvancement;				// Sauvegarde de l'avencement
    //ctrl.slideCancelAvancement = slideCancelAvancement;			// Cancel le chanegement de l'avancement
    //ctrl.onReadySlider = onReadySlider;							// Executer lorsque l'instance du slider est ok
    //--------- Fin Slider ---------

    ctrl.paramsFiltreByChannelAndPageCurrent = paramsFiltreByChannelAndPageCurrent;
    ctrl.onEmitFromContenuMessage = onEmitFromContenuMessage;

    //------------------------ Discussions -----------------//
    ctrl.onEmitFromDiscussionsCollab = onEmitFromDiscussionsCollab;		// Lors d'une remonté d'info du composant "discussions-collab"

    //------------------------ Bloc Notes -----------------//
    ctrl.onEmitTopicBlocNotes = onEmitTopicBlocNotes;
    ctrl.onEmitFromListDemandeDevisInterComponent = onEmitFromListDemandeDevisInterComponent;

    //------------------------ Courriers -----------------//
    ctrl.onEmitFromCourrier = onEmitFromCourrier;

    //------------------------ SendMail -----------------//
    /*
    ctrl.openSendMail = false;
    ctrl.onClickOpenSendMail = onClickOpenSendMail;
    ctrl.onEmitFromFormulaireMessageEmailComponent = onEmitFromFormulaireMessageEmailComponent;
    */

    //----------- Icon Contenu Important ---------------
    ctrl.onEmitFromIconContenuImportant = onEmitFromIconContenuImportant;

    ctrl.topicManagerService = TopicManagerService;
    ctrl.loadingLayoutTopicDetail = {show: true};

    ctrl.$onInit = function () {

      $scope.$watch('layouttopicdetailctrl.topicManagerService.currentTopicStates',function(newVal, oldVal){
        ctrl.currentTopicStates = newVal;
        ctrl.canSendToGed = TopicManagerService.currentTopicHasGed;
        //console.log('newVal', newVal);
        //console.log('oldVal', oldVal);
      }, true);

      SocketNotificationService.on('listeContenu', onUpdateListContenuFromWebsocket);

      ctrl.currentTab = 'topic';
      ctrl.filtreContenu = false;
      ctrl.infosEvenement = UtilsService.getInfosEvenement();

      if($stateParams.communication.show) showTabCommuniquer();
      else if($stateParams.objTab) showTab($stateParams.objTab);
      initCallContenus();
    };

    function initFromStateParams(){
      if(!_.isNil($stateParams.currentTab)){
        if(!ctrl.itemContenuEvenement.modifiable) {
          $state.go('topics.detail', {topicId: ctrl.itemContenuEvenement.idTopic}, {location: true, reload: true, inherit: false});
          return;
        }
        var promises = [];

        var objTab = {
          currentTab: $stateParams.currentTab,
          obj: {}
        };
        if(!_.isNil($stateParams.action)) {
          objTab.obj.action = $stateParams.action;
        }

        if(!_.isNil($stateParams.contenu)) {
          objTab.obj.contenu = $stateParams.contenu;
        }
        else if(!_.isNil($stateParams.idContenu)) {

          if(ctrl.itemContenuEvenement.getListeDevis().length) {
            for(var d = 0; d < ctrl.itemContenuEvenement.getListeDevis().length; d++){
              if(parseInt($stateParams.idContenu) === parseInt(ctrl.itemContenuEvenement.getListeDevis()[d].getIdContenu())){
                objTab.obj.contenu = ctrl.itemContenuEvenement.getListeDevis()[d];
                break;
              }
            }
          }

          if(_.isNil(objTab.obj.contenu) && ctrl.itemContenuEvenement.getListeContrats().length) {
            for(var c = 0; c < ctrl.itemContenuEvenement.getListeContrats().length; c++){
              if(parseInt($stateParams.idContenu) === parseInt(ctrl.itemContenuEvenement.getListeContrats()[c].getIdContenu())){
                objTab.obj.contenu = ctrl.itemContenuEvenement.getListeContrats()[c];
                break;
              }
            }
          }

          /*
          var defferedContenu = $q.defer();
          ContenuService
              .getContenu($stateParams.idContenu)
              .then(function onSuccess(contenu){
                  objTab.obj.contenu = contenu;
                  defferedContenu.resolve(contenu);

              });
          promises.push(defferedContenu.promise);*/
        }

        // Recoi un document en paramètre
        if(!_.isNil($stateParams.document)) {
          objTab.obj.document = $stateParams.document;
        }
        // Recoi un guid de document en paramètre
        else if(!_.isNil($stateParams.guid)) {
          objTab.obj.document = DocumentsTopicService.getDocument($stateParams.guid);
        }

        if(_.isNil(objTab.obj.action)) {
          if(!_.isNil($stateParams.idContenu) && !_.isNil($stateParams.guid)) {
            objTab.obj.action = 'openDocument';
          }
          else if(!_.isNil($stateParams.idContenu) && _.isNil($stateParams.guid)) {
            objTab.obj.action = 'selectDemande';
          }
        }
        else {
          if(objTab.obj.action === 'edit-demande') {
            objTab.obj.action = 'editDemande';
          }
        }

        if(promises.length){
          $q.all(promises).then(function(){
            showTab(objTab);
          });
        }
        else {
          showTab(objTab);
        }
      }
    }

    /*
    ctrl.$onChanges = function (changes) {
        console.log(changes);
    }*/

    function onUpdateListContenuFromWebsocket(listeContenu){
      updateListDemande(listeContenu);
    }

    $scope.$on('layoutTopicDetailOpenTab', function (ev, item) {
      onClickTab(item);
    });

    $scope.$on('openCommunication', function (ev, type, devis) {
      openCommunication(devis, type);
      /*
      $timeout(function () {
          if (type === 'message') {
              $scope.$broadcast('openMessageForDevis', devis);
          }
          if (type === 'sms') {
              $scope.$broadcast('openSmsForDevis', devis);
          }
      });*/
    });

    $scope.$on('refreshTopicDetail', function () {
      refreshTopicDetail();
    });

    $scope.$on('refreshListContenus', function (ev, noRefreshWidget) {
      initContenus(null, false, noRefreshWidget);
    });

    $scope.$on('refreshDocuments', function () {
      updateListeDocumentTopic();
    });

    $scope.$on('openDeclarationSinitre', function () {
      openDeclarationSinitre();
    });

    $scope.$on('addMessage', function (event, item) {
      addContenuMessage(item);
    });

    // Lorsque le controller est detruit
    ctrl.$onDestroy = function () {
      //console.log(ctrl.currentTopicStates.topic.categorieTopic);
      //console.log(TopicManagerService.currentTopicStates.topic.categorieTopic);
      ContenuService.addEvenementTopic = false;
      ctrl.devis = false;

      SocketNotificationService.removeListener('listeContenu');

      DocumentService.destroy();

      DocumentsTopicService.destroy();

      TopicService.destroy();
      TopicManagerService.destroy();
      //DocumentManagerService.destroy();

      //$window.off('resize',onResizeWindow);
      //delete ctrl.currentTopicStates;
    };

    // Objet d'édition du titre du topic
    ctrl.EditTitreTopic = {
      'loadingUpdateTitre': false,	// Loading de l'update du titre
      'modeEditTitre': false,			// Etat d'affichaque du champ d'édition du titre
      'valNewTitre': false,			// Valeur du nouveau titre

      /**
       * Permet de savoir si le titre peut etre modifié
       * @returns {boolean}
       */
      'canEditTitre': function () {
        var retour = false;
        var evenement = ctrl.currentTopicStates.getTopic().getEvenement();
        if(_.isObject(evenement) && evenement.getModifiable()) retour = true;
        else if(!evenement && UtilsService.getCurrentUser().isSameSocieteOfGroupe(ctrl.currentTopicStates.getTopic().getGroupeCreateur())) retour = true;

        /*
        var idGroupeCreateur = ctrl.currentTopicStates.getTopic().getGroupeCreateur().getIdGroupe();
        var idGroupeUser = UtilsService.getCurrentUser().getIdGroupe();

        if (idGroupeCreateur === idGroupeUser) {
            if (ctrl.currentTopicStates.getTopic().getNbContenu() === 1) {
                retour = true;
            }
        }*/
        return retour;
      },

      /**
       * Lors du clique sur le bouton pour editer le titre
       */
      'onClicEditTitreTopic': function () {
        var _this = this;
        if (!_this.modeEditTitre) {
          _this.modeEditTitre = true;

          _this.valNewTitre = ctrl.currentTopicStates.getTopic().getTitre();


          $timeout(function () {
            angular.element('#inputEditTitre').focus();
          });
        }
      },

      /**
       * Lors du clique sur le bouton annuler l'édition
       */
      'onClickCancelEditTitreTopic': function () {
        this.modeEditTitre = false;
      },

      /**
       * Lors du submit di nouveau titre
       */
      'onSubmitEditTitreTopic': function () {
        var _this = this;
        this.loadingUpdateTitre = COLLAB_CONF.MINI_LOADING;


        /*
         $rootScope.current.TopicState.getTopic().setTitre(_this.valNewTitre);
         _this.modeEditTitre = false;
         _this.loadingUpdateTitre = false;*/
        //$timeout(function(){

        TopicService.updateTitre(ctrl.currentTopicStates.getTopic(), _this.valNewTitre).then(function () {
          // Set le titre de page
          $rootScope.setTitlePage(COLLAB_CONF.TITLE_DEFAULT + ' - ' + ctrl.currentTopicStates.getTopic().getTitre());
        }, function (error) {
          if (error.status === 403) {
            sweet.show({
              title: 'Oops...',
              text: 'Vous ne pouvez plus modifier le titre de ce topic...<br />Il y certainement eu une réponse entre temps...',
              type: 'error',
              html: true,
              showCancelButton: false,
              confirmButtonText: 'Ok...',
              closeOnConfirm: true
            }, function () {

            });
          }
        }).finally(function () {
          _this.modeEditTitre = false;
          _this.loadingUpdateTitre = false;
        });
        //},5000);

      }
    };

    //************ Onglets ****************************

    /**
     * Lors du clique sur un onglet
     * @param tab
     */
    function onClickTab(tab){
      showTab(tab);
    }

    function showTab(param){
      ctrl.onInitOpenDevis = null;
      ctrl.onInitEvenementListDemandeFournisseur = null;
      if(!ctrl.notMobile())
        ctrl.collapseMenu = true;

      if(_.isString(param)) {
        ctrl.currentTab = param;
      }
      else if(_.isObject(param) && param.currentTab){
        if(param.currentTab === 'fournisseurs') {
          showTabFournisseurs(param.obj);
        }
      }
    }

    /**
     * Permet d'afficher le topic
     */
    function showTabTopic(){
      ctrl.currentTab = 'topic';
    }

    /**
     * Permet d'afficher les discussions
     */
    function showTabDiscussions(){
      ctrl.currentTab = 'discussions';
    }

    /**
     * Permet d'afficher le bloc notes
     */
    function showTabBlocNotes(){
      ctrl.currentTab = 'bloc_notes';
    }

    /**
     * Permet d'afficher les demandes de devis et interventions
     */
    function showTabFournisseurs(obj){
      //console.log(obj);
      ctrl.currentTab = 'fournisseurs';
      if(_.isObject(obj)) {
        if(_.isObject(obj.contenu)) {
          ctrl.onInitEvenementListDemandeFournisseur = {
            action: obj.action,
            contenu: obj.contenu,
            document: obj.document
          };
        }
        else ctrl.onInitEvenementListDemandeFournisseur = {action: obj.action};
      }
    }

    /**
     * Permet d'afficher la communication
     * @param devis
     */
    function showTabCommuniquer(devis, type){
      ctrl.currentTab = 'communiquer';
      if(_.isObject(devis) &&_.isString(type)) {
        ctrl.onInitOpenDevis = {
          action: (type === 'sms') ? 'openSMSForDevis' : 'openMessageForDevis',
          devis: devis
        };
      }
    }
    //************ Fin Onglets ****************************

    function currentGroupeUserIsAgenceICS(){
      return UtilsService.getParametrage('agenceICS');
    }

    function addEvenement(withDevis) {
      if(withDevis) {
        var ContenuEvenementDevis = $injector.get('ContenuEvenementDevis');
        var newDevis = new ContenuEvenementDevis();

        if (UtilsService.isCapfun()) {
          newDevis.setDescriptionDevis(COLLAB_CONF.DEFAULT_DESCRIPTION_DEMANDE_DEVIS);
          newDevis.setMessageGravitants(COLLAB_CONF.DEFAULT_DESCRIPTION_DEMANDE_DEVIS);
        }

        newDevis.setGroupement('Défaut');

        // Nouveau devis
        ctrl.devis = newDevis;
        /*
        ctrl.manageMyDevis = {
            nouveau: true,
            id: false,
            obj: newDevis,
            index: null,
            fromNewTopic: true
        };*/
        ContenuEvenementDevis = null;
      }
      ContenuService.addEvenementTopic = true;
      ctrl.addEvenementTopic = true; // Bientot obsolète
    }

    function cancelAddEvenement() {
      ctrl.devis = false;
      if (ctrl.addEvenementTopic) {
        ctrl.itemContenuEvenement = false;
        ctrl.addEvenementTopic = false; // Bientot obsolète
        ContenuService.addEvenementTopic = false;
      }
    }

    /**
     * Recupère un contenu avec son id
     * @param itemContenu
     */
    /*
    function getContenu(itemContenu, deferred) {

      if (!deferred) {
        deferred = $q.defer();
      }

      var idContenu = itemContenu.idContenu;

      if (idContenu) {

        itemContenu.loading = COLLAB_CONF.MIDDLE_LOADING;

        ContenuService.getContenu(idContenu).then(function (contenu) {

          // Si contenu message
          if (contenu.typeContenu === 1) {

            itemContenu.message = contenu.message;

            // Si contenu evenement
          } else if (contenu.typeContenu === 2) {

            //console.log(contenu);

            itemContenu.setData(contenu);

            itemContenu.groupesConcernes = UtilsService.getGroupesConcernesInObjet(contenu.objet);
            //console.log(itemContenu.groupesConcernes);
            itemContenu.objet = contenu.objet;
          }

          deferred.resolve(contenu);
        }).finally(function () {
          // Passe loading à false pour afficher le contenu
          itemContenu.loading = false;
        });
      } else {
        deferred.reject('Pas d\'id contenu');
      }

      return deferred.promise;
    }*/


    /**
     * Parcour les contenus en async
     * @param index
     */
    function getContenuOpenAsync(index) {

      var deferred = $q.defer();

      var idContenu = ctrl.contenus[index].idContenu;
      var next = parseInt(index) + 1;

      if (idContenu) {
        if (ctrl.contenus[index].open) {
          ContenuService.getContenu(idContenu).then(function (contenu) {

            // Si contenu message
            if (contenu.typeContenu === 1) {
              ctrl.contenus[index].message = contenu.message;

              // Passe loading à false pour afficher le contenu
              ctrl.contenus[index].loading = false;

              // Si contenu evenement
            } else if (contenu.typeContenu === 2) {
              ctrl.contenus[index].objet = contenu.objet;
            }

            if (ctrl.contenus[next]) {

              deferred.resolve(next);
            } else {
              deferred.reject();
            }
          });
        } else {
          if (ctrl.contenus[next]) {

            deferred.resolve(next);
          } else {
            deferred.reject();
          }
        }
      } else {
        if (ctrl.contenus[next]) {

          deferred.resolve(next);
        } else {
          deferred.reject();
        }
      }

      deferred.promise.then(getContenuOpenAsync);
    }

    /**
     * Parcour les contenus en async
     * @param index
     */
    function callContenuOpenAsync(contenu) {

      var deferred = $q.defer();

      try {
        if(_.isNil(contenu) || _.isNil(contenu.idContenu)) throw new Error('Contenu invalide');
        contenu.loading = true;
        ContenuService
          .getContenu(contenu.idContenu)
          .then(function onSuccess(contenuRetour) {

            // Si contenu message
            if (contenu.isContenuMessage()) {
              contenu.setMessage(contenuRetour.message);
              //contenu.setDestinataires(contenuRetour.destinataires);
              //contenu.setDestinatairesEmail(contenuRetour.destinatairesEmail);
              //contenu.setDestinatairesSms(contenuRetour.destinatairesSms);
            }
            // Si contenu evenement
            else if (contenu.isContenuEvenement()) contenu.setObjet(contenuRetour.objet);

            deferred.resolve(contenu);
          })
          .catch(function onError(msg){
            deferred.reject(err.message);
          })
          .finally(function onFinally(){
            delete contenu.loading;
          });
      }
      catch(err){
        deferred.reject(err.message);
        console.error(err.message);
      }
      return deferred.promise;
    }

    /**
     * Permet de savoir si l'événement est éditable par l'utilisateur
     * @returns {boolean}
     */
    function allowEditEvenement() {
      var retour = false;
      if ((ctrl.itemContenuEvenement && ctrl.itemContenuEvenement.isModifiable()) || ContenuService.addEvenementTopic) retour = true;
      return retour;
    }

    /**
     * Recupère la liste des contenus du topic
     * @param {void}
     * @returns {Promesse} Initialise les variable du controller sinon msg erreur
     */
    function initCallContenus() {

      // Connecte la websocket
      /*WebsocketServices.connectWebsocket();*/
      //filterTags = [];

      cancelAddEvenement();

      // Mise à vide de la liste des nouveaux contenus temporaire à lister lors du clique sur la notif
      $rootScope.arrayNewContenusTmp = [];

      NotificationService.removeFromListNotificationMessageForTopic(topicId);

      // Récupération des infos du topic
      TopicStatesService.getTopicStateByIdTopic(topicId)
        .then(function onSuccess(topicState) {
          init(topicState);
        })
        // Si pas trouvé de topic, retour à l'accueil
        .catch(function onError() {
          $scope.$emit('goAccueil');

        })
        .finally(function onFinally() {
          ctrl.loadingLayoutTopicDetail.show = false;
          initFromStateParams();
        });
    }

    function init(topicState){
      ctrl.topicManagerService.setCurrentTopicStates(angular.copy(topicState));
      ctrl.currentTopicStates = ctrl.topicManagerService.currentTopicStates;
      ctrl.paramsCommunication.idTopic = ctrl.currentTopicStates.getTopic().getIdTopic();

      if(_.isObject(ctrl.currentTopicStates.getTopic().getTopicInformations())){
        var groupeImmeuble = ctrl.currentTopicStates.getTopic().getTopicInformations().getGroupeImmeuble();
        var groupeCoproprietaire = ctrl.currentTopicStates.getTopic().getTopicInformations().getGroupeCoproprietaire();
        var groupeProprietaire = ctrl.currentTopicStates.getTopic().getTopicInformations().getGroupeProprietaire();
        var groupeLocataire = ctrl.currentTopicStates.getTopic().getTopicInformations().getGroupeLocataire();
        if(_.isObject(groupeImmeuble))        ctrl.paramsCommunication.groupeImmeuble = groupeImmeuble;
        if(_.isObject(groupeCoproprietaire))  ctrl.paramsCommunication.groupeCoproprietaire = groupeCoproprietaire;
        if(_.isObject(groupeProprietaire))    ctrl.paramsCommunication.groupeProprietaire = groupeProprietaire;
        if(_.isObject(groupeLocataire))       ctrl.paramsCommunication.groupeLocataire = groupeLocataire;
      }

      ctrl.currentTopicStates.topic.dateCreationFormated = UtilsService.dateParser(ctrl.currentTopicStates.topic.dateCreation);

      // Set le titre de page
      $rootScope.setTitlePage(COLLAB_CONF.TITLE_DEFAULT + ' - ' + ctrl.currentTopicStates.getTopic().getTitre());

      // Initialise l'événement
      ctrl.itemContenuEvenement = ctrl.currentTopicStates.getTopic().getEvenement();

      initContenus(ctrl.currentTopicStates, true);

      /*
      var arrayContenus = [];
      var arrayGroupContenus = [];

      var nbContenusLu = 0;
      var nGroup = 0;

      var contenusMessage = topicState.getTopic().getContenusMessage();
      var contenuEvenement = topicState.getTopic().getEvenement();

      ctrl.nbMessage = 0;
      ctrl.nbMessageNonLu = 0;
      ctrl.listeContenuImportant = [];
      if(_.isArray(contenusMessage) && contenusMessage.length) {
        ctrl.nbMessage = contenusMessage.length;
        for(var i = 0; i < ctrl.nbMessage; i++) {
          if(contenusMessage[i].lu) ctrl.nbMessageNonLu++;
        }
      }

      // TODO : Debut à commenter quand timeline ok
      // Pour chaque contenus de type message
      angular.forEach(contenusMessage, function (contenu, index) {

        // Copie l'objet contenu dans un nouvel objet
        var objContenu = contenu;
        if(objContenu.isImportant()) {
          objContenu.open = true;
          ctrl.listeContenuImportant.push(objContenu);
        }
        else objContenu.open = false;

        // Si contenu non lu
        if (!contenu.lu) {
          // Déplie le contenu
          objContenu.open = true;
          objContenu.loading = COLLAB_CONF.MIDDLE_LOADING;
        }

        // Ajoute de l'attribut du numero de group
        objContenu.nGroup = nGroup;

        // Si l'index est différent du 1er et dernier contenu
        if (index !== 0 && index !== (contenusMessage.length - 1)) {


          // Si le contenu est lu
          if (contenu.lu) {

            // Si le nombre de contenu lu est à 0
            if (!nbContenusLu) {
              arrayGroupContenus[nGroup] = {};
              // C'est le 1er contenu déjà lu du groupe
              objContenu.firstLu = true;
            } else {
              objContenu.firstLu = false;
            }

            // Ce groupe de contenu doit etre caché
            arrayGroupContenus[nGroup].display = false;
            nbContenusLu++;
            arrayGroupContenus[nGroup].nbLu = nbContenusLu;

            // Si le contenu n'est pas lu
          } else {

            nbContenusLu = 0;

            // Incrémente le groupe pour les prochain contenu lu
            nGroup++;

            arrayGroupContenus[nGroup] = {};
            // Ce groupe de contenu doit être affiché car non lu
            arrayGroupContenus[nGroup].display = true;
            arrayGroupContenus[nGroup].nbLu = 0;

          }
          // Si premier ou dernier contenu
        } else {

          // Si dernier
          if (index === (contenusMessage.length - 1)) {
            // Contenu deplié
            objContenu.open = true;
            objContenu.last = true;
            objContenu.loading = COLLAB_CONF.MIDDLE_LOADING;
          }
        }

        arrayContenus.push(objContenu);
      });


       // Tableau des contenus
      ctrl.contenus = arrayContenus;
      if (ctrl.contenus.length) {
        // Recupère de façon asynchrone les messages open
        getContenuOpenAsync(0);
      }*/

      // Tableau des group de contenu
      // ctrl.groupContenus = angular.copy(arrayGroupContenus);
      // TODO : Fin à commenter quand timeline ok

      //ctrl.currentTopicStates.getTopic().lire();

      // Initialise l'événement
      if(ctrl.itemContenuEvenement) {
        ctrl.infosEvenement = UtilsService.getInfosEvenement(ctrl.itemContenuEvenement);
        ctrl.tagPortefeuille = ctrl.infosEvenement.tagPortefeuille;
      }

      checkHash();
    }

    function initContenus(topicState, firstInit, noRefreshWidget){
      if(_.isNil(topicState)) topicState = ctrl.topicManagerService.currentTopicStates;

      ctrl.listeContenuImportant = [];

      //ctrl.itemContenuEvenement.listeDocument = angular.copy(ctrl.currentTopicStates.getTopic().getEvenement().getListeDocument());
      var contenusMessage = angular.copy(topicState.getTopic().getContenusMessage());

      if(_.isArray(contenusMessage)){
        if(firstInit) {
          // Pour chaque contenus de type message
          contenusMessage.map(function(contenu, index){
            // Si dernier contenu, non lu ou important
            if (!contenu.lu || index === (contenusMessage.length - 1) || contenu.isImportant()) {
              contenu.open = true; // Contenu deplié
              if(index === (contenusMessage.length - 1)) contenu.last = true;
              callContenuOpenAsync(contenu);
              if(contenu.isImportant()) ctrl.listeContenuImportant.push(contenu);
            }
          });
        }
        else if (ctrl.contenus.length){
          ctrl.contenus.map(function(contenu, index){
            if(contenu.open) contenusMessage[index].open = true
          });
        }
      }
      // Tableau des contenus
      ctrl.contenus = contenusMessage;

      updateListeDocumentTopic(topicState, noRefreshWidget);
    }

    /**
     * Permet d'update la liste des contenu importants
     */
    function updateListeContenuImportant(open){
      ctrl.listeContenuImportant = [];
      if(ctrl.contenus.length){
        for(var i = 0; i < ctrl.contenus.length; i++){
          if(ctrl.contenus[i].isImportant()) {
            if(open) ctrl.contenus[i].open = open;
            ctrl.listeContenuImportant.push(ctrl.contenus[i]);
          }
        }
      }
    }

    /**
     * Gère les scroll de la page selon les hash
     */
    function checkHash() {

      var hash = $location.hash();

      // Si il y a une ancre dans l'url
      if (hash) {
        var hasUnderscore = UtilsService.test(hash, '_');

        if (hasUnderscore) {

          var tabHash = hash.split('_');
          var idContenu = parseInt(tabHash[1]);

          if(tabHash[0] === 'devis') {
            UtilsService.scrollToDevis(idContenu);
          }
          else {
            // pour chaque contenu
            angular.forEach(ctrl.contenus, function (contenu, indexContenu) {
              // Si contenu est égale au contenu du hash dans l'url
              if (contenu.idContenu === idContenu) {
                // Déplie de contenu
                ctrl.contenus[indexContenu].open = true;
                UtilsService.scrollToContenu(ctrl.contenus[indexContenu]);
              }
            });
          }
        }
        else {
          if (hash === 'Evenement') {
            if (ctrl.itemContenuEvenement) {
              ctrl.itemContenuEvenement.open = true;
              UtilsService.scrollToEvenement();
            }
          } else {
            if (ctrl.itemContenuEvenement.open) {
              UtilsService.scrollToEvenement();
            } else {
              UtilsService.scrollToId(hash);
            }
          }
          //$scope.scrollToId($scope.hash);
        }
      }

    }

    function refreshTopicDetail() {
      ctrl.loadingLayoutTopicDetail.show = true;
      initCallContenus();
    }

    //------------------------ Liste des Messages -----------------//

    function getPrevisualisation(itemContenu) {
      var msg = UtilsService.getPrevisualisationMessage(itemContenu);
      //console.log('msg',msg);
      var retour = UtilsService.replaceAll('img-responsive', '', msg);
      return retour;
    }

    /**
     * Ouvre le contenu
     * @param {Object} itemContenu - objet contenu
     * @returns {void}
     */
    function openContenu(itemContenu) {

      if (itemContenu.open) {

        itemContenu.open = false;
        /*
        if (itemContenu.isMessage) {
          itemContenu.repondreOpen = false;
          itemContenu.repondreAllOpen = false;
        }*/

        if (itemContenu.isEvenement) {
          $location.hash('');
        }


        itemContenu.nouveauContenu = false;
        itemContenu.loading = false;

        $rootScope.destroyInstandeCKEDITOR(itemContenu);

      }
      else {

        itemContenu.open = true;
        callContenuOpenAsync(itemContenu);

        /*
        itemContenu.open = true;
        itemContenu.loading = COLLAB_CONF.MIDDLE_LOADING;

        // Si le contenu est un message on recupère le contenu par la servlet
        // Si c'est un evenement alors le contenu est recupéré par la fonction "finishLoadFormulaireEvenement"
        //if(itemContenu.isMessage){
        getContenu(itemContenu);
        //}
        */

        /*
         $scope.$on('$includeContentLoaded', function(event,templateName) {
         console.log('Hey');
         console.log(event);
         console.log(templateName);
         });*/
      }
    }

    /**
     * Permet d'ajouter un contenu à la volé dans la liste et modifié les données du topic
     * @param contenu
     */
    function addContenuMessage(contenu, onlyPushInListe) {

      if (contenu.isMessage) {
        contenu.last = true;
        contenu.open = true;

        var msg = UtilsService.stripTags(contenu.message);
        contenu.previsualisationMsg = UtilsService.truncate(msg, 20, '...');

        var exist = false;
        if(!_.isNil(contenu.idContenu)) {
          for(var c = 0; c < ctrl.contenus.length; c++) {
            ctrl.contenus[c].last = false;
            if(ctrl.contenus[c].idContenu === contenu.getIdContenu()){
              ctrl.contenus[c].setData(contenu);
              exist = true;
              break;
            }
          }
        }

        if(!exist){
          if (!onlyPushInListe) ctrl.currentTopicStates.getTopic().addContenu(contenu);
          ctrl.contenus.push(contenu);
        }
        else ctrl.currentTopicStates.getTopic().updateContenu(contenu);
        updateTopicStates(ctrl.currentTopicStates);
        updateListeDocumentTopic(ctrl.currentTopicStates);
        updateListeContenuImportant(true);
      }
    }

    /**
     * Est-ce que l'item est open
     * @param itemContenu
     * @returns {boolean}
     */
    function isOpen(itemContenu) {
      var open = false;
      if (itemContenu.open && !itemContenu.loading) {
        open = true;
      }
      return open;
    }

    /**
     * Update le topicState manager
     * @param topicState
     */
    function updateTopicStates(topicState){
      ctrl.topicManagerService.getCurrentTopicStates(topicState);
      ctrl.currentTopicStates = ctrl.topicManagerService.currentTopicStates;
    }

    /**
     * Permet d'init ou d'update le topic du topicState de la page détail
     * @param topic
     */
    function updateTopic(topic){
      if(topic) {
        ctrl.currentTopicStates.setTopic(topic);
        updateListeDocumentTopic(ctrl.currentTopicStates);
      }
    }

    /**
     * Met à jour la liste des documents du topic manager pour le composant de message etc... pas le widget des documents
     * @param topic
     */
    function updateListeDocumentTopic(topicState, noRefreshWidget){
      if(!topicState) topicState = ctrl.currentTopicStates;
      //if(!topicState) topicState = ctrl.topicManagerService.currentTopicStates;
      ctrl.topicManagerService.listDocumentTopicByCat = DocumentsTopicService.init(topicState.getTopic());
      if(!noRefreshWidget) $rootScope.$broadcast('refreshDocumentsWidget');
    }

    function onInsertContenuEvenement(onInsert) {
      if (onInsert.hasOwnProperty('insertContenu')) {
        ctrl.itemContenuEvenement = onInsert.insertContenu;
      }
      //console.log('onInsertContenuEvenement',onInsert);
    }

    function updateEvenement(event, noRefreshWidget){
      // Commenté car sans angular.copy sur les deux variables de l'event, le sinistre ne s'affiche pas
      //ctrl.currentTopicStates.getTopic().setEvenement(event);
      //ctrl.itemContenuEvenement = ctrl.currentTopicStates.getTopic().getEvenement();
      ctrl.currentTopicStates.getTopic().setEvenement(angular.copy(event));
      ctrl.itemContenuEvenement = angular.copy(ctrl.currentTopicStates.getTopic().getEvenement());
      if(!noRefreshWidget) updateListeDocumentTopic(ctrl.currentTopicStates);
    }

    /**
     * Permet d'update une demande dans les listes
     * @param devis
     */
    function updateDemande(demande, noRefreshWidgetDocument){
      if(_.isObject(demande) && demande.idTopic === ctrl.itemContenuEvenement.idTopic) {

        var listContenu = null;
        if(demande.isContenuDevisInter())   listContenu = ctrl.itemContenuEvenement.listeDevis;
        else if(demande.isContenuContrat()) listContenu = ctrl.itemContenuEvenement.listeContrats;
        if(!_.isArray(listContenu)){
          if(demande.isContenuDevisInter()) {
            if(!_.isArray(ctrl.itemContenuEvenement.listeDevis)) ctrl.itemContenuEvenement.listeDevis = [];
            ctrl.itemContenuEvenement.listeDevis.push(angular.copy(demande));
          }
          if(demande.isContenuContrat()) {
            if(!_.isArray(ctrl.itemContenuEvenement.listeContrats)) ctrl.itemContenuEvenement.listeContrats = [];
            ctrl.itemContenuEvenement.listeContrats.push(angular.copy(demande));
          }
        }
        else {
          var exist = false;
          if(listContenu.length) {
            for(var i = 0; i < listContenu.length; i++) {
              if (demande.getIdContenu() === listContenu[i].getIdContenu()) {
                exist = true;
                listContenu[i].setData(angular.copy(demande));
                break;
              }
            }
          }
          if(!exist) listContenu.push(demande);
        }
        updateEvenement(ctrl.itemContenuEvenement, noRefreshWidgetDocument);
      }
    }
    /*

    function updateDemande(demande, noRefreshWidgetDocument){
      if(_.isObject(demande) && demande.idTopic === ctrl.currentTopicStates.getTopic().getIdTopic()) {

        if(demande.isContenuDevisInter()) {
          var listDevis = angular.copy(ctrl.itemContenuEvenement.listeDevis);
          var exist = false;
          if(_.isArray(listDevis)) {
            if(listDevis.length) {
              for(var i = 0; i < listDevis.length; i++) {
                if(demande.getIdContenu() === listDevis[i].getIdContenu()) {
                  exist = true;
                  listDevis[i] = demande;
                  break;
                }
              }
            }/
          }
          else listDevis = [];

          if(!exist) listDevis.push(demande);
          ctrl.itemContenuEvenement.listeDevis = listDevis;
        }
        else if(demande.isContenuContrat()) {
          var listContrats = angular.copy(ctrl.itemContenuEvenement.listeContrats);
          var exist = false;
          if(_.isArray(listContrats)) {

            if(listContrats.length) {
              for(var j = 0; j < listContrats.length; j++) {
                if(demande.getIdContenu() === listContrats[j].getIdContenu()) {
                  exist = true;
                  listContrats[j] = demande;
                  break;
                }
              }
            }
          }
          else {
            listContrats = [];
          }
          if(!exist) listContrats.push(demande);
          ctrl.itemContenuEvenement.listeContrats = listContrats;
        }

        if(!noRefreshWidgetDocument) updateListeDocumentTopic();
      }
    }*.

    /**
     * Update les listes de demandes
     */
    function updateListDemande(listDemandes, noOpenPdf) {
      var refreshList = (!listDemandes) ? true : false;

      if(_.isArray(listDemandes) && listDemandes.length) {
        var ContenuEvenementDevis = $injector.get('ContenuEvenementDevis');
        var ContenuEvenementContrat = $injector.get('ContenuEvenementContrat');
        for(var j = 0; j < listDemandes.length; j++) {

          if(!listDemandes[j].isModel) {
            if(listDemandes[j].typeContenu === COLLAB_CONF.TYPE_CONTENU_DEVIS_INTER) {
              listDemandes[j] = new ContenuEvenementDevis(listDemandes[j]);
            }
            else if(listDemandes[j].typeContenu === COLLAB_CONF.TYPE_CONTENU_CONTRAT) {
              listDemandes[j] = new ContenuEvenementContrat(listDemandes[j]);
            }
          }

          if(listDemandes[j].idTopic === ctrl.topicId) {
            updateDemande(listDemandes[j], true);
            refreshList = true;

            // Si il y a une seul demande à upatde alors on la selectionne
            if((_.isNil(noOpenPdf) || !noOpenPdf) && listDemandes.length === 1) {
              $scope.$broadcast('openPdfAndSelectDemande', listDemandes[j]);
            }
          }
        }
        if(refreshList) updateListeDocumentTopic();
        ContenuEvenementDevis = null;
        ContenuEvenementContrat = null;
      }
    }

    /**
     * Fonction executé lors de l'update de l'événement
     * @param objUpdate
     */
    function onUpdateContenuEvenement(objUpdate) {

      var typeTag = false;
      if (objUpdate.hasOwnProperty('typeTag')) typeTag = objUpdate.typeTag;

      //console.log(objUpdate);
      if (objUpdate.hasOwnProperty('contenu')) updateEvenement(objUpdate.contenu);

      if (objUpdate.hasOwnProperty('addTag')) {
        if (typeTag !== COLLAB_CONF.TAG_TYPE_PROJET) {
          ctrl.currentTopicStates.getTopic().addTag(objUpdate.addTag);
        }
      }

      if (objUpdate.hasOwnProperty('deleteTagFromContenu')) {
        ctrl.currentTopicStates.getTopic().removeTag(objUpdate.deleteTagFromContenu);
      }
      if (objUpdate.hasOwnProperty('deleteTagsFromContenu')) {
        ctrl.currentTopicStates.getTopic().removeTags(objUpdate.deleteTagsFromContenu);
      }

      if (objUpdate.hasOwnProperty('addGroupes')) {
        if (angular.isArray(objUpdate.addGroupes)) {
          //console.log('objUpdate.addGroupes', objUpdate.addGroupes);
          //var destinatairesEvent = angular.copy(ctrl.itemContenuEvenement.getDestinataires());
          var destinatairesEvent = ctrl.itemContenuEvenement.getDestinataires();

          for (var g = 0; g < objUpdate.addGroupes.length; g++) {
            destinatairesEvent.push(objUpdate.addGroupes[g]);
          }
          //ctrl.itemContenuEvenement.setDestinataires(destinatairesEvent);
        }

      }
    }

    /**
     * Fonction executé lors d'un update d'un contenu message
     * @param objUpdate
     */
    /*
    function onUpdateContenuMessage(objUpdate) {

      var typeTag = false;
      if (objUpdate.hasOwnProperty('typeTag')) {
        typeTag = objUpdate.typeTag;
      }

      if (objUpdate.hasOwnProperty('addTag')) {
        ctrl.currentTopicStates.getTopic().addTag(objUpdate.addTag);
      }

      if (objUpdate.hasOwnProperty('deleteTagFromContenu')) {
        ctrl.currentTopicStates.getTopic().removeTag(objUpdate.deleteTagFromContenu);
      }
    }*/

    /**
     * Fonction executé lors d'un emit du composant contenu-evenement
     * @param obj
     */
    function onEmitContenuEvenement(obj) {
      try {
        if(!_.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');
        if(!obj.hasOwnProperty('action') && !obj.hasOwnProperty('actions')) throw new Error('Pas de propriété "action" ou "actions" dans le paramètre obj');

        if(_.isObject(obj.actions)) {
          if(obj.actions.hasOwnProperty('addMessages') && _.isArray(obj.actions.addMessages) && obj.actions.addMessages.length) {
            for(var i = 0; i < obj.actions.addMessages.length; i++)
              addContenuMessage(obj.actions.addMessages[i]);
          }
        }
        else {
          if(obj.action === 'onClickVoirDemande') showTabFournisseurs({contenu: obj.demande, action: 'selectDemande'});
          else if(obj.action === 'onClickAjouterDemande') showTabFournisseurs({action: 'newDemande'});
          else if(obj.action === 'onClickAjouterDemandeContrat') showTabFournisseurs({action: 'newDemandeContrat'});

          else if(obj.action === 'addOrUpdateDemande' && _.isObject(obj.contenu)) {
            if(_.isArray(obj.contenu)) updateListDemande(obj.contenu);
            else if(_.isObject(obj.contenu)) updateDemande(obj.contenu);
          }
          else if(obj.action === 'addMessage' && (_.isObject(obj.contenu) || _.isObject(obj.message))) {

            var message = (_.isObject(obj.contenu)) ? obj.contenu : obj.message;
            // Ajoute le message à la page détail
            addContenuMessage(message);
            UtilsService.scrollToContenu(message);
          }
          // Si demande d'ajout de messages
          else if(obj.action === 'addMessages' && _.isArray(obj.messages) && obj.messages.length) {
            for(var m = 0; m < obj.messages.length; m++)
              addContenuMessage(obj.messages[m]);
          }
          else if(obj.action === 'openCreateNewEvenement') {
            ctrl.openFormulaireEvent = true;
            ctrl.openFormulaireEventWithDemande = false;
            ctrl.newEvenement = obj.newEvenement;
          }
          else if(obj.action === 'openCreateNewEvenementWithDemand') {
            ctrl.openFormulaireEvent = false;
            ctrl.openFormulaireEventWithDemande = true;
            ctrl.newEvenement = obj.newEvenement;
          }
          else if(obj.action === 'closeCreateNewEvenement') {
            ctrl.openFormulaireEvent = false;
            ctrl.openFormulaireEventWithDemande = false;
          }
        }
      }
      catch(err) {
        var ErreurCollabService = $injector.get('ErreurCollabService');
        ErreurCollabService.logErreur('[LayoutTopicDetail.onEmitContenuEvenement] - ' + err.message);
        ErreurCollabService = null;
      }
    }

    /**
     * Executé lors du clique sur un tag des la liste global du topic
     */
    function onClickTag() {
    }

    /**
     * Fonction executé par le composant "tags-collab" qui liste tous les tags du topic
     * @param objUpdate
     */
    function onDeleteTag(objUpdate) {
      try {

        //console.log('objUpdate',objUpdate);
        /*
         var limitTag = false;
         if(objUpdate.hasOwnProperty('limit')){
         limitTag = objUpdate.limit;
         }
         */
        var typeTag = false;
        if (objUpdate.hasOwnProperty('typeTag')) {
          typeTag = objUpdate.typeTag;
        }

        // Supprime un tag d'un topic
        if (objUpdate.hasOwnProperty('deleteTagFromTopic')) {

          ctrl.currentTopicStates.getTopic().removeTag(objUpdate.deleteTagFromTopic, typeTag, true);
          //console.log('deleteTagFromTopic : ',objUpdate.deleteTagFromTopic);
        }

      }
      catch (err) {
        console.log('[LayoutTopicDetail.onDeleteTag] Erreur : ' + err.message);
      }

    }

    /**
     * Lorsque le composant contenu-evenement emet un clique sur un bouton de communication
     * @param obj
     */
    function onClickCommunicationFromContenuEvenement(obj){
      try {
        if(!angular.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');
        if(!obj.hasOwnProperty('devis')) throw new Error('Pas de propriété "devis" dans le paramètre obj');
        openCommunication(obj.devis);
      }
      catch(err) {
        var ErreurCollabService = $injector.get('ErreurCollabService');
        ErreurCollabService.logErreur('[LayoutTopicDetail.onClickCommunicationFromContenuEvenement] - ' + err.message);
        ErreurCollabService = null;
      }
    }

    function openCommunication(devis, type) {
      showTabCommuniquer(devis, type);
    }

    function closeCommunication() {
      showTabTopic();
      //ctrl.showCommunication = false;
    }

    /**
     * Lors du click sur le bouton urgent
     */
    function onClickUrgent() {
      if (ctrl.itemContenuEvenement.getIdContenu()) {
        var isUrgent = null;
        if (ctrl.itemContenuEvenement.urgent) isUrgent = false;
        else isUrgent = true;

        $rootScope.$broadcast('showLoadingContenuEvenementFormulaire');
        ctrl.itemContenuEvenement
          .updateUrgent(isUrgent)
          .catch(function(msg) {
            ModalsService.alertErreur(msg);
          })
          .finally(function () {
            $rootScope.$broadcast('hideLoadingContenuEvenementFormulaire');
          });
        /*
        if (ctrl.itemContenuEvenement.urgent) {
            ctrl.itemContenuEvenement.urgent = false;
        } else {
            ctrl.itemContenuEvenement.urgent = true;
        }

        //ctrl.itemContenuEvenement.setUpdate(true);

        $scope.$broadcast('submitEvenement');*/
        // Submit l'événements
        //ctrl.submitContenu();
      }
    }

    /**
     * Lors du click sur le bouton cloturer
     */
    function onClickCloture() {

      TopicService
        .clotureOrReouvre(ctrl.currentTopicStates.getTopic())
        .then(function (topic) {
          if (_.isObject(topic) && topic.isModel) {
            refreshTopicDetail()
          }
        });
      /*
      ContenuService.clotureOrReouvreEvenement(ctrl.currentTopicStates.getTopic()).then(function (contenu) {
        if (angular.isObject(contenu) && contenu.isModel) {
          refreshTopicDetail();
        }
      });*/

      //$scope.$broadcast('clotureEvenement');
    }

    function onClickSendGed(){
      openModalSendGed();
    }

    function anyDevisSignedHere(listeDeDevis) {
      for (var i = 0; i < listeDeDevis.length; i++) {
        var devis = listeDeDevis[i];
        if (devis.hasDevisSigne()) {
          return (true);
        }
      }
      return (false);
    }

    function openModalSendGed() {
      try {
        DocumentManagerService
          .exportTopicToGed(ctrl.currentTopicStates.getTopic())
          .then(function(topic){
            console.log("then exportTopicToGed", topic);
            // Si GedV1
            if(_.isObject(topic) && !ctrl.currentTopicStates.getTopic().gedV2IsEnabled()){
              ctrl.currentTopicStates.setTopic(topic);
              updateTopicStates(ctrl.currentTopicStates);
              updateListeDocumentTopic();
            }
          })
          .finally(function () {
          }, function (obj) { // Notify
          console.log("notity exportTopicToGed", obj);
          if (_.isObject(obj) && _.isObject(obj.topic)) {
            ctrl.currentTopicStates.setTopic(obj.topic);
            updateTopicStates(ctrl.currentTopicStates);
            updateListeDocumentTopic();
          }
        });

        /*
        // Si GedV2
        if(tagPortefeuille.gedV2IsEnabled()){
          DocumentManagerService
            .exportTopicToGed(ctrl.currentTopicStates.getTopic())
            .then(function(topic){
              if (_.isObject(topic)) {
                ctrl.currentTopicStates.setTopic(topic);
                updateTopicStates(ctrl.currentTopicStates);
                updateListeDocumentTopic();
              }
            });
        }
        // Si GedV1
        else{
          var tagImmeuble = evenement.getTagForType('Immeuble');
          var tagMandat = evenement.getTagForType('Mandat');


          var params = {
            idTopic: topicId,
            documentTopic: ctrl.currentTopicStates.getTopic().getDocument(),
            idEvenement: evenement.getIdContenu(),
            hasMessage: (ctrl.currentTopicStates.getTopic().getContenusMessage().length > 0) ? true : false,
            hasSinistre: ctrl.currentTopicStates.getTopic().hasSinistre(),
            hasCourriers: (evenement.getListeCourrier().length > 0) ? true : false,
            hasDevisSigne: anyDevisSignedHere(evenement.getListeDevis()),
            hasPieceJointe: (evenement.getListeDocument().length > 0) ? true : false,
            hasDevisOuIntervention: (evenement.getListeDevis().length > 0) ? true : false,
            tagImmeuble: tagImmeuble,
            tagMandat: tagMandat,
            tagPortefeuille: tagPortefeuille
          };

          ModalsService
            .modalComponent('modalSendGed', params, {backdrop: 'static'})
            .then(function (objet) {
              creationDuPdf(objet);
            })
            .catch(function (msg) {
              if (msg !== 'cancel') {
                ModalsService.alertErreur(msg);
              }
            });
        }*/
      }
      catch(e){
        ModalsService.alertErreur(e.message);
      }
    }

    function oncClickManageSinistre() {
      $scope.$broadcast('openDeclarationSinitre');
    }

    /*
    function creationDuPdf(objet) {
      var pdfSendGed = null;
      if (objet.infos) {
        pdfSendGed = {
          pdfGedChemin: objet.gedChemin,
          pdfNom: objet.nomPdf, // Mettre le nom du topic
          pdfIdTopic: objet.id,
          pdfMessage: (objet.infos.topicMessages) ? true : false,
          pdfSinistre: (objet.infos.topicSinistre) ? true : false,
          pdfCourriers: (objet.infos.topicCourriers) ? true : false,
          pdfDevisSigne: (objet.infos.topicDevisSigne && !objet.infos.topicDevisOuIntervention) ? true : false,
          pdfPieceJointe: (objet.infos.topicPiecesJointes) ? true : false,
          pdfDevisOuIntervention: (objet.infos.topicDevisOuIntervention) ? true : false
        };
      }
      else {
        pdfSendGed = {
          pdfGedChemin: objet.gedChemin,
          pdfIdTopic: objet.id,
          pdfNom: objet.nomPdf, // Mettre le nom du topic
          pdfMessage: false,
          pdfSinistre: false,
          pdfCourriers: false,
          pdfDevisSigne: false,
          pdfPieceJointe: false,
          pdfDevisOuIntervention: false
        };
      }
      var GedService = $injector.get('GedService');
      var modalLoading = ModalsService.loading();
      GedService
        .exportInGed(pdfSendGed)
        .then(function(topic){
          if (_.isObject(topic)) {
            ctrl.currentTopicStates.setTopic(topic);
            updateTopicStates(ctrl.currentTopicStates);
          }

          ModalsService.success('Le pdf a bien été envoyé');
          updateListeDocumentTopic();
          //modalLoading.close();
        })
        .catch(function(msg) {
          ModalsService.alertErreur(msg);
        });
      GedService = null;
    }*:

    /**
     * Permet de retourner un objet qui est utilisé en paramètre du state de ui-router dans le ui-sref pour le retour à la liste des topic en gardant les filtres, channels etc....
     * @returns {{topicPage, idChannel, search}}
     */
    function paramsFiltreByChannelAndPageCurrent(){
      var FiltreService = $injector.get('FiltreService');
      var obj = FiltreService.getObjFiltreByChannelAndPageCurrent();
      FiltreService = null;
      return obj
    }

    /**
     * Lors d'une remontée d'info depuis contenu-message-
     * @param obj
     */
    function onEmitFromContenuMessage(obj){
      try {
        if (!_.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');
        if (!obj.hasOwnProperty('action'))throw new Error('La propriété "action" n\'existe pas');

        if(obj.action === 'openCommunication') openCommunication();
        if(obj.action === 'addMessage' && _.isObject(obj.message)) {
          // Close le formulaire de réponse
          //openContenu(obj.message);

          // Ajoute le message à la page détail
          addContenuMessage(obj.message);
          UtilsService.scrollToContenu(obj.message);
        }

        if(obj.action === 'objUpdate' && _.isObject(obj.update)) {
          var typeTag = false;

          if (obj.update.hasOwnProperty('typeTag'))
            typeTag = obj.update.typeTag;

          if (obj.update.hasOwnProperty('addTag'))
            ctrl.currentTopicStates.getTopic().addTag(obj.update.addTag);

          if (obj.update.hasOwnProperty('deleteTagFromContenu'))
            ctrl.currentTopicStates.getTopic().removeTag(obj.update.deleteTagFromContenu);
        }

      }
      catch (err) {
        $log.log('[LayoutTopicDetailController.onEmitFromContenuMessage] Erreur : ' + err.message);
      }
    }

    //------------------------ Discussions -----------------//
    function onEmitFromDiscussionsCollab(obj){
      try {

        if(!angular.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');
        if(!obj.hasOwnProperty('action') && !obj.hasOwnProperty('actions')) throw new Error('Pas de propriété "action" ou "actions" dans le paramètre obj');

        if(angular.isObject(obj.actions)) {
          //if(obj.actions.hasOwnProperty('updateEvenement')) updateEvenement(obj.actions.updateEvenement);
          if(obj.actions.hasOwnProperty('addMessages') && angular.isArray(obj.actions.addMessages) && obj.actions.addMessages.length) {
            for(var i = 0; i < obj.actions.addMessages.length; i++)
              addContenuMessage(obj.actions.addMessages[i]);
          }
        }
        else {
          if(_.isObject(obj.devis)) {
            if(obj.action === 'onClickVoirDemande') {
              showTabFournisseurs({contenu: obj.devis, document: obj.devis.getDocumentDemande(), action: 'openDocument'});
            }
            else if(obj.action === 'onClickVoirDocumentDevis') {
              showTabFournisseurs({contenu: obj.devis, document: obj.devis.getDocumentDevis(), action: 'openDocument'});
            }
            else if(obj.action === 'onClickVoirDocumentDevisSigne') {
              showTabFournisseurs({contenu: obj.devis, document: obj.devis.getDocumentDevisSigne(), action: 'openDocument'});
            }
            else if(obj.action === 'onClickVoirDocumentFacture') {
              showTabFournisseurs({contenu: obj.devis, document: obj.devis.getDocumentFacture(), action: 'openDocument'});
            }
            /*
            else if(obj.action === 'onClickSignerDocument') {
                showTabFournisseurs({contenu: obj.devis, document: obj.devis.getDocumentDevis(), action: 'openDocument'});
            }*/

            else if(obj.action === 'updateDevis') updateDemande(obj.devis);
          }
          else if(_.isObject(obj.demande)) {
            if(obj.action === 'onClickVoirDocument') {
              showTabFournisseurs({contenu: obj.demande, document: obj.document, action: 'openDocument'});
            }
          }
          else if (_.isArray(obj.messages) && obj.messages.length){
            for(var m = 0; m < obj.messages.length; m++)
              addContenuMessage(obj.messages[m]);
          }
          else if(obj.action === 'addMessage' && _.isObject(obj.message)) {
            addContenuMessage(obj.message);
          }
          else if(obj.action === 'updateMessage' && _.isObject(obj.message)) {
            addContenuMessage(obj.message);
          }
        }
      }
      catch(err) {
        var ErreurCollabService = $injector.get('ErreurCollabService');
        ErreurCollabService.logErreur('[LayoutTopicDetail.onEmitFromDiscussionsCollab] - ' + err.message);
        ErreurCollabService = null;
      }
    }

    //------------------------ Fournisseurs -----------------//

    function onEmitTopicBlocNotes(obj){
      try {
        if (angular.isObject(obj)) {
          if (obj.hasOwnProperty('action') && obj.action) {
            if(obj.action === 'updateTopic' && obj.hasOwnProperty('topic')) updateTopic(obj.topic);
          }
        }
      }
      catch (err) {
        $log.log('[LayoutTopicDetail.onEmitTopicBlocNotes] Erreur : ' + err.message);
      }
    }

    /**
     * Lors de la remontée d'informations depuis le composant <list-demande-fournisseur>
     * @param obj
     */
    function onEmitFromListDemandeDevisInterComponent(obj){
      try {
        if(!_.isObject(obj)) {
          throw new Error('Le paramètre "obj" doit être un objet');
        }

        if(!obj.hasOwnProperty('action') && !obj.hasOwnProperty('actions')) {
          throw new Error('Pas de propriété "actions"  ou "actions" dans le paramètre obj');
        }

        if(_.isObject(obj.evenement)) {
          updateEvenement(obj.evenement, true);
        }

        if(_.isObject(obj.contenu)) {

          if(obj.action === 'openCommunication') {
            openCommunication(obj.contenu, obj.type);
          }

          if(obj.action === 'addOrUpdateDemande'){
            if(_.isArray(obj.contenu)){
              updateListDemande(obj.contenu);
            }
            else if(_.isObject(obj.contenu)){
              updateDemande(obj.contenu);
            }
          }
        }

        if(obj.action === 'addMessage') {
          if(!_.isObject(obj.message)) {
            throw new Error('Il manque la propriété "message" dans "obj"');
          }
          addContenuMessage(obj.message);
        }
        else if(_.isObject(obj.actions)) {
          if(obj.actions.hasOwnProperty('addMessages') && _.isArray(obj.actions.addMessages) && obj.actions.addMessages.length) {
            for(var i = 0; i < obj.actions.addMessages.length; i++)
              addContenuMessage(obj.actions.addMessages[i]);
          }
        }

        // Ajout d'une demande depuis le composant enfant "list-demande-fournisseur"
        if(obj.action === 'addOrUpdateDemande') {
          if(!_.isObject(obj.demande) && !_.isObject(obj.demandes)) {
            throw new Error('Il doit y avoir la propriété "demande" ou "demandes" dans "obj"');
          }

          if(_.isArray(obj.demandes)){
            updateListDemande(obj.demandes);
          }
          else if(_.isObject(obj.demande)){
            updateDemande(obj.demande);
          }

        }
      }
      catch (err) {
        $log.log('[LayoutTopicDetail.onEmitFromListDemandeDevisInterComponent] Erreur : ' + err.message);
      }
    }

    //------------------------ Courriers -----------------//

    /**
     * Lorsque le composant enfant courrier emit quelque chose
     * @param obj
     */
    function onEmitFromCourrier(obj) {
      try {
        //emitEvenement();

        if (!obj) throw new Error('Pas de courrier');
        if (angular.isObject(obj) && obj.hasOwnProperty('action') && obj.hasOwnProperty('courrier') && obj.courrier) {
          if(obj.action === 'add') {
            var exist = false;
            if (ctrl.itemContenuEvenement.getListeCourrier().length) {
              for (var i = 0; i < ctrl.itemContenuEvenement.getListeCourrier().length; i++) {
                if (ctrl.itemContenuEvenement.getListeCourrier()[i].getIdCourrier() === obj.courrier.getIdCourrier()) {
                  exist = true;
                  break;
                }
              }
            }
            if(!exist) ctrl.itemContenuEvenement.getListeCourrier().push(obj.courrier);
          }
          else if(obj.action === 'update') {
            if (ctrl.itemContenuEvenement.getListeCourrier().length) {
              for (var i = 0; i < ctrl.itemContenuEvenement.getListeCourrier().length; i++) {
                if (ctrl.itemContenuEvenement.getListeCourrier()[i].getIdCourrier() === obj.courrier.getIdCourrier())
                  ctrl.itemContenuEvenement.getListeCourrier()[i] = obj.courrier;
              }
            }
          }
          else if(obj.action === 'delete') {
            if (ctrl.itemContenuEvenement.getListeCourrier().length) {
              for (var i = 0; i < ctrl.itemContenuEvenement.getListeCourrier().length; i++) {
                if (ctrl.itemContenuEvenement.getListeCourrier()[i].getIdCourrier() === obj.courrier.getIdCourrier())
                  ctrl.itemContenuEvenement.getListeCourrier().splice(i, 1);
              }
            }
          }
        }

        //ctrl.currentTopicStates.getTopic().setEvenement(objUpdate.contenu);
        //ctrl.itemContenuEvenement = ctrl.currentTopicStates.getTopic().getEvenement();
        updateListeDocumentTopic();
        //ctrl.onUpdate({objUpdate: {contenu: ctrl.itemContenuEvenement}});
      }
      catch (err) {
        console.log('[LayoutTopicDetailController.onUpdateCourrier] Erreur : ' + err.message);
      }
    }


    //----------- Sinistre ---------------
    /**
     * Ouvre le modal de déclaration de sinistre
     * @param paramsSup
     */
    function openDeclarationSinitre(paramsSup) {
      SinistreService
        .openDeclarationSinitre(ctrl.itemContenuEvenement, ctrl.infosEvenement)
        .then(function(event){
          updateEvenement(event)
        });
    }

    /**
     * Lors du clique sur le bouton pour afficher la fenetre de déclaration du sinistre
     */
    function onClickDeclarerSinistre() {
      openDeclarationSinitre();
    }
    //----------- Fin Sinistre ---------------

    //----------- SendMail ---------------

    /**
     * Lorsqu'on ouvre le formulair epour envoyer un email
     */
    /*
    function onClickOpenSendMail(){
        ctrl.openSendMail = !ctrl.openSendMail;
    }*/

    /**
     * Lorsque le composant "formulaire-message" emit quelque chose
     * @param obj
     */
    /*
    function onEmitFromFormulaireMessageEmailComponent(obj){
        try {
            if (!obj)
                throw new Error('Pas de "obj"');

            if(obj.action === 'closeReply')
                ctrl.openSendMail = false;

            if(obj.action === 'addMessage' && _.isObject(obj.contenu))
                addContenuMessage(obj.contenu);

        }
        catch (err) {
            console.log('[LayoutTopicDetailController.onEmitFromFormulaireMessageComponent] Erreur : ' + err.message);
        }
    }*/
    //----------- Fin SendMail ---------------

    //----------- Icon Contenu Important ---------------

    function goToContenuImportant(contenu){
      showTabTopic();
      UtilsService.scrollToContenu(contenu);
    }

    function onEmitFromIconContenuImportant(contenu, obj){
      try {
        //emitEvenement();

        if (!obj) throw new Error('Il manque le paramètre "obj"');
        if (!contenu) throw new Error('Il manque le paramètre "contenu"');
        if (_.isObject(obj) && _.isString(obj.action)) {
          if(obj.action === 'updateContenu' && _.isObject(obj.contenu)) {
            contenu.setTagsType(obj.contenu.getTagsType())
            ctrl.currentTopicStates.getTopic().updateContenu(contenu);
            /*
            for(var c = 0; c < ctrl.contenus.length; c++){
                if(ctrl.contenus[c].idContenu === obj.contenu.idContenu) {
                    ctrl.contenus[c].setTagsType(obj.contenu.getTagsType());
                    break;
                }
            }*/

            var indexExist = null;
            if(ctrl.listeContenuImportant.length) {
              for(var i = 0; i < ctrl.listeContenuImportant.length; i++){
                if(ctrl.listeContenuImportant[i].idContenu === contenu.idContenu){
                  indexExist = i;
                  break;
                }
              }
            }
            // Si le contenu est dans la liste mais qu'il n'est pas important on le retire
            if(!_.isNil(indexExist) && !contenu.isImportant()) {
              ctrl.listeContenuImportant.splice(indexExist, 1);
            }
            // Si le contenu n'est pas dans la liste mais qu'il est important on l'ajoute
            else if(_.isNil(indexExist) && contenu.isImportant()) {
              ctrl.listeContenuImportant.push(contenu);
            }


          }
        }
      }
      catch (err) {
        console.log('[ContenuEvenementFormulaire.onEmitFromIconContenuImportant] Erreur : ' + err.message);
      }
    }
    //----------- Fin Icon Contenu Important ---------------
  }
})();
