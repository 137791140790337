(function() {

  'use strict';

  /**
   * @desc Composant recapMail
   * @example <popup-infos-devis></popup-infos-devis>
   */
  angular
    .module('collaboreApp')
    .directive('onDragOver', OnDragOverCtrl);

  /** @ngInject */
  function OnDragOverCtrl(){
    return {
      restrict: 'A',
      scope: {
        onDragOver: '&',
        onDragLeave: '&',
        onDragOverClass: '@'
      },
      link: function(scope, element, attrs) {
        // again we need the native object
        var el = element[0];
        el.addEventListener("dragover", onDragOver, false);
        el.addEventListener("dragleave", onDragLeave, false);
        el.addEventListener("drop", onDragLeave, false);

        function onDragOver(event) {
          if(!_.isNil(attrs.onDragOver)) scope.onDragOver();
          if (event.preventDefault) event.preventDefault();
          if (event.stopPropagation) event.stopPropagation();
          if (!_.isNil(attrs.onDragOverClass) && !element.hasClass(attrs.onDragOverClass))
            element.addClass(attrs.onDragOverClass);
        }

        function onDragLeave(event) {
          if(!_.isNil(attrs.onDragLeave)) scope.onDragLeave();
          if (event.preventDefault) event.preventDefault();
          if (event.stopPropagation) event.stopPropagation();
          if (!_.isNil(attrs.onDragOverClass) && element.hasClass(attrs.onDragOverClass))
            element.removeClass(attrs.onDragOverClass);
        }

        scope.$on('$destroy',function(){
          el.removeEventListener("dragover", onDragOver, false);
          el.removeEventListener("dragleave", onDragLeave, false);
          el.removeEventListener("drop", onDragLeave, false);
        });
      }
    };
  }
})();
