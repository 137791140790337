'use strict';

angular.module('collaboreApp').factory('Inverse',[
	'Immeuble',
	'Lot',
	'Typelot',
	'Personnalite',
	'Bail',
	function(Immeuble, Lot, Typelot, Personnalite, Bail) {

		function Inverse(objetData) {


			if (objetData) {
				this.setData(objetData);
				this.isInverse = true;
			}
		}

		Inverse.prototype = {

			isModel: true,

			setData: function(objetData) {
				angular.extend(this, objetData);
				if(angular.isObject(objetData)){
					if(objetData.hasOwnProperty('immeuble')){
						if(objetData.immeuble.hasOwnProperty('nomimme')){
							this.setImmeuble(objetData.immeuble);
						}else{
							this.setImmeuble(false);
						}
					}

					if(objetData.hasOwnProperty('lot')){
						if(objetData.lot.hasOwnProperty('llll')){
							this.setLot(objetData.lot);
						}else{
							this.setLot(false);
						}
					}

					if(objetData.hasOwnProperty('typelot')){
						if(objetData.typelot.hasOwnProperty('libelleType')){
							this.setTypelot(objetData.typelot);
						}else{
							this.setTypelot(false);
						}
					}

					if(objetData.hasOwnProperty('personnalite')){
						if(objetData.personnalite.hasOwnProperty('nomper')){
							this.setPersonnalite(objetData.personnalite);
						}else{
							this.setPersonnalite(false);
						}
					}

					if(objetData.hasOwnProperty('bail')){
						if(objetData.bail.hasOwnProperty('dateproch')){
							this.setBail(objetData.bail);
						}else{
							this.setBail(false);
						}
					}
				}
			},

			// Num�ro de mandat
			getNumMandat: function(){
				return this.mmmm;
			},
			setNumMandat: function(val){
				this.mmmm = val;
			},

			// Num�ro d'immeuble
			getNumImmeuble: function(){
				return this.iiii;
			},
			setNumImmeuble: function(val){
				this.iiii = val;
			},

			// Num�ro de lot
			getNumLot: function(){
				return this.llll;
			},
			setNumLot: function(val){
				this.llll = val;
			},

			// Num�ro de copropri�taire
			getNumCoproprietaire: function(){
				return this.xxxx;
			},
			setNumCoproprietaire: function(val){
				this.xxxx = val;
			},

			// Num�ro de autre
			getNumAutre: function(){
				return this.autre;
			},
			setNumAutre: function(val){
				this.autre = val;
			},

			// Type
			getType: function(){
				return this._type;
			},
			setType: function(val){
				this._type = val;
			},

			// Num�ro de personnalit�
			getNumPerso: function(){
				return this.ino;
			},
			setNumPerso: function(val){
				this.ino = val;
			},

			// Mails
			getMails: function(){
				if(!angular.isArray(this.mails)){
					this.mails = [];
				}
				return this.mails;
			},
			setMails: function(arrayMails){
				this.mails = arrayMails;
			},

			// inom
			// icle4
			// mand
			// ex
			// ep
			// er
			// dr
			// nr
			// sh
			// cs
			// qPindi
			// manind
			// compges
			// prl
			// echApp
			// perNu
			// cdeNu
			// perCo
			// ext
			// vLoc
			// vTva
			// echJJ

			getImmeuble: function(){
				return this.immeuble;
			},
			setImmeuble: function(val){

				// Si "val" est un string
				if(angular.isString(val)){
					val = new Immeuble({'nomimme':val});
				}else{
					// Si val est un objet mais qu'il n'est pas un model
					if(!val.isModel){
						// Si val a l'attribut "nomimme"
						if(val.hasOwnProperty('nomimme')){
							val = new Immeuble(val);
						}else{
							val = false;
						}
					}
				}

				this.immeuble = val;
			},

			getLot: function(){
				return this.lot;
			},
			setLot: function(val){

				// Si "val" est un string
				if(angular.isString(val)){
					val = new Lot({'llll':val});
				}else{
					if(!val){
						// Si val a l'attribut "llll"
						if(angular.isDefined(this.getNumLot())){
							val = new Lot({'llll':this.getNumLot()});
						}
					}
					// Si val est un objet mais qu'il n'est pas un model
					else if(!val.isModel){
						// Si val a l'attribut "llll"
						if(val.hasOwnProperty('llll')){
							val = new Lot(val);
						}else{
							val = false;
						}
					}

				}

				this.lot = val;
			},

			getTypelot: function(){
				return this.typelot;
			},
			setTypelot: function(val){

				// Si "val" est un string
				if(angular.isString(val)){
					val = new Typelot({'libelleType':val});
				}else{
					// Si val est un objet mais qu'il n'est pas un model
					if(!val.isModel){
						// Si val a l'attribut "libelleType"
						if(val.hasOwnProperty('libelleType')){
							val = new Typelot(val);
						}else{
							val = false;
						}
					}
				}

				this.typelot = val;
			},

			getPersonnalite: function(){
				return this.personnalite;
			},
			setPersonnalite: function(val){

				// Si "val" est un string
				if(angular.isString(val)){
					val = new Personnalite({'nomper':val});
				}else{
					// Si val est un objet mais qu'il n'est pas un model
					if(!val.isModel){
						// Si val a l'attribut "nomper"
						if(val.hasOwnProperty('nomper')){
							val = new Personnalite(val);
						}else{
							val = false;
						}
					}
				}

				this.personnalite = val;
			},

			getBail: function(){
				return this.bail;
			},
			setBail: function(val){

				// Si "val" est un string
				if(angular.isObject(val)){
					// Si val est un objet mais qu'il n'est pas un model
					if(!val.isModel){
						// Si val a l'attribut "dateproch"
						if(val.hasOwnProperty('dateproch')){
							val = new Bail(val);
						}else{
							val = false;
						}
					}
				}

				this.bail = val;
			},


			isLocataire: function(){
				if(this.getType()==='Locataire'){
					return true;
				}
				return false;
			},

			isColocataire: function(){
				if(this.getType()==='Colocataire'){
					return true;
				}
				return false;
			},

			isCoproprietaire: function(){
				if(this.getType()==='Coproprietaire'){
					return true;
				}
				return false;
			},
			isProprietaire: function(){
				if(this.getType()==='Proprietaire'){
					return true;
				}
				return false;
			}
/*
				if(this._type!==''){
			if(this._type.equals('F')){
				this._type= 'Fournisseur';
			}
			else if(this._type.equals('C')){
				this._type= 'Coproprietaire';
			}
			else if(this._type.equals('L')){
				this._type= 'Locataire';
			}
			else if(this._type.equals('P')){
				this._type= 'Proprietaire';
			}else if(this._type.equals('I')){
				this._type= 'Indivision';
			}else if(this._type.equals('c')){
				this._type= 'Colocataire';
			}else if(this._type.equals('a')){
				this._type= 'Assureur';
			}else if(this._type.equals('q')){
				this._type= 'Caution';
			}else if(this._type.equals('r')){
				this._type= 'Caution';
			}else if(this._type.equals('p')){
				this._type= 'Payeur';
			}else if(this._type.equals('M')){
				this._type= 'Proprietaire Meuble';
			}else if(this._type.equals('E')){
				this._type= 'Employeur';
			}else if(this._type.equals('S')){
				this._type= 'Salarie';
			}else if(this._type.equals('X')){
				this._type= 'Client';
			}else if(this._type.equals('Y')){
				this._type= 'ICS';
			}else if(this._type.equals('T')){
				this._type= 'ICS';
			}
		}*/

		};

		return Inverse;
	}
]);