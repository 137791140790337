(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name collaboreApp.ContratIcs
     * @description
     * # ContratIcs
     * Service in the collaboreApp.
     */

    angular
        .module('collaboreApp')
        .factory('ContratIcs', ContratIcsModel);

    /** @ngInject */
    function ContratIcsModel(Main, Portefeuille, PersoIcs) {

        function ContratIcs(contenuData) {
            Main.apply(this, arguments);
            this.hydrate(contenuData);
        }

        ContratIcs.prototype = {

            model: 'ContratIcs',
            isContratIcs: true,

            // Id Perso ICS
            getIdContratICS: function(){
                return this.idContratICS;
            },
            setIdContratICS: function(id){
                this.idContratICS = id;
            },

            // refContrat
            getRefContrat: function(value){
                if (arguments.length) {
                    this.setRefContrat(value);
                } else {
                    return this.refContrat;
                }
            },
            setRefContrat: function(val){
                this.refContrat = val;
            },

            // refAssureur
            getRefAssureur: function(value){
                if (arguments.length) {
                    this.setRefAssureur(value);
                } else {
                    return this.refAssureur;
                }
            },
            setRefAssureur: function(val){
                this.refAssureur = val;
            },

            // refFournisseur
            getRefFournisseur: function(){
                return this.refFournisseur;
            },
            setRefFournisseur: function(val){
                this.refFournisseur = val;
            },

            // objet
            getObjet: function(value){
                if (arguments.length) {
                    this.setObjet(value);
                } else {
                    return this.objet;
                }
            },
            setObjet: function(val){
                this.objet = val;
            },

            // policeNo
            getPoliceNo: function(value){
                if (arguments.length) {
                    this.setPoliceNo(value);
                } else {
                    return this.policeNo;
                }
            },
            setPoliceNo: function(val){
                this.policeNo = val;
            },

            // dateDerniereReconduction
            getDateDerniereReconduction: function(){
                return this.dateDerniereReconduction;
            },
            setDateDerniereReconduction: function(val){
                this.dateDerniereReconduction = val;
            },

            // dateDebutContrat
            getDateDebutContrat: function(value){
                if (arguments.length) {
                    this.setDateDebutContrat(value);
                } else {
                    return this.dateDebutContrat;
                }
            },
            // dateDebutContrat
            getDateDebutContratFormat: function(toDate){
                var retour = this.dateDebutContrat;
                if(!toDate) {
                    toDate = 'DD/MM/YYYY';
                }
                if(retour) {
                    if(angular.isString(toDate)) {
                        retour = moment(retour).format(toDate);
                    }
                    else {
                        retour = new Date(retour);
                    }
                }
                return retour;
            },
            setDateDebutContrat: function(val){
                this.dateDebutContrat = val;
            },

            // dateFinContrat
            getDateFinContrat: function(){
                return this.dateFinContrat;
            },
            setDateFinContrat: function(val){
                this.dateFinContrat = val;
            },

            // dateRevision
            getDateRevision: function(){
                return this.dateRevision;
            },
            setDateRevision: function(val){
                this.dateRevision = val;
            },

            // dateResiliation
            getDateResiliation: function(){
                return this.dateResiliation;
            },
            setDateResiliation: function(val){
                this.dateResiliation = val;
            },

            // dateDerniereNego
            getDateDerniereNego: function(){
                return this.dateDerniereNego;
            },
            setDateDerniereNego: function(val){
                this.dateDerniereNego = val;
            },

            // dateProchaineNego
            getDateProchaineNego: function(){
                return this.dateProchaineNego;
            },
            setDateProchaineNego: function(val){
                this.dateProchaineNego = val;
            },

            // Portefeuille
            getPortefeuille: function(){
                return this.portefeuille;
            },
            setPortefeuille: function(portefeuille){
                // Si val est un objet mais qu'il n'est pas un model
                if(!portefeuille.isModel){
                    portefeuille = new Portefeuille(portefeuille);
                }
                this.portefeuille = portefeuille;
            },

            // Compagnie (persoC)
            getCompagnie: function(){
                return this.compagnie;
            },
            setCompagnie: function(compagnie){
                // Si val est un objet mais qu'il n'est pas un model
                if(!compagnie.isModel){
                    compagnie = new PersoIcs(compagnie);
                }
                this.compagnie = compagnie;
            },

            // Fournisseur
            getFournisseur: function(){
                return this.fournisseur;
            },
            setFournisseur: function(fournisseur){
                // Si val est un objet mais qu'il n'est pas un model
                if(!fournisseur.isModel){
                    fournisseur = new PersoIcs(fournisseur);
                }
                this.fournisseur = fournisseur;
            }

        };

        angular.extend(ContratIcs.prototype, Main.prototype);

        return ContratIcs;
    }
})();