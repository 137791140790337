(function () {
  'use strict';

  /**
   * @desc Page de liste de topic avec recherche
   * @example <layout-topic-recherche></layout-topic-recherche>
   */

  angular
    .module('collaboreApp')
    .component('layoutTopicRecherche', {
      templateUrl: 'app/layout/layout-topic-recherche/layout-topic-recherche.component.html',
      controllerAs: 'layouttopicrecherchectrl',
      controller: LayoutTopicRechercheController
    });

  /*@ngInject*/
  function LayoutTopicRechercheController($stateParams, $state, $rootScope, $scope, FiltreService, TopicStatesService, UtilsService, $timeout) {

    var ctrl = this;
    ctrl.search = false;
    ctrl.showListTopic = false;
    ctrl.showListTopicElasticsearch = false;
    ctrl.notMobile = UtilsService.notMobile;

    ctrl.onEmitFromListTopic = onEmitFromListTopic;

    ctrl.$onInit = function () {

      var useListTopicWithTagsFiltre = false;

      // Recupération d'un éventuel filtre dans l'url
      var filtre = FiltreService.parseFiltreFromUrl($stateParams, true);
      if(_.isObject(filtre)) {
        if(!_.isNil(filtre.rechercheElasticsearch)){
          ctrl.rechercheElasticsearch = filtre.rechercheElasticsearch;
        }

        if(_.isObject(filtre.evenement)) {
          if((_.isArray(filtre.evenement.tags) && filtre.evenement.tags.length) ||
            (_.isArray(filtre.evenement.listeFournisseurMail) && filtre.evenement.listeFournisseurMail.length) ||
            (_.isArray(filtre.evenement.listeFournisseurPersoICS) && filtre.evenement.listeFournisseurPersoICS.length)) {
            useListTopicWithTagsFiltre = true;
          }
        }
      }

      // Si il y a une query alors on set la variable de recherche pour le champ
      if(_.isObject(ctrl.rechercheElasticsearch) && !_.isNil(ctrl.rechercheElasticsearch.query)){
        ctrl.search = ctrl.rechercheElasticsearch.query;   // Remplit le champ de recherche
      }

      // Si on ne filtre pas par tags
      // OU
      // Si il y a une rechercheElasticsearch dans le filtre mais que le isActive est à true
      if(!useListTopicWithTagsFiltre || (_.isObject(ctrl.rechercheElasticsearch) && ctrl.rechercheElasticsearch.isActive)){
        useSearchByElasticsearch();
      }
      // Si la recherche n'est pas par elastic search OU qu'on demande à utiliser la liste des topics filtré par tags
      else if(!ctrl.showListTopicElasticsearch || useListTopicWithTagsFiltre) {
        useNormalSearch();
      }

      $scope.$on('rechercheByTags', function (event, objetTags) {
        $rootScope.current.filtreTopics.idChannel = -1;
        //objetTags.idChannel = -1;
        useNormalSearch(objetTags);
      });
    };

    ctrl.$onDestroy = function () {
    };

    /**
     * Permet d'utiliser la recherche par elasticsearch
     */
    function useSearchByElasticsearch(search){
      // Remplit le champ de recherche
      if(!_.isNil(search)) {
        ctrl.search = search;
      }

      ctrl.showListTopicElasticsearch = true;
      ctrl.showListTopic = false;
      if(_.isObject($rootScope.current.filtreTopics.rechercheElasticsearch))
        $rootScope.current.filtreTopics.rechercheElasticsearch.isActive = true;
    }

    /**
     * Permet d'utiliser la recherche normal
     */
    function useNormalSearch(objetTags){
      FiltreService.cleanFiltre();
      $rootScope.current.filtreTopics.isActive = true;
      if(_.isObject(objetTags)) {
        ctrl.objetTags = angular.copy(objetTags);
      }
      if(_.isObject($rootScope.current.filtreTopics.rechercheElasticsearch)) {
        $rootScope.current.filtreTopics.rechercheElasticsearch.isActive = false;
      }
      $timeout(function(){
        ctrl.showListTopicElasticsearch = false;
        ctrl.showListTopic = true;
      });

    }

    /**
     * Lorsque le composant list-topic emit une info
     * @param obj
     */
    function onEmitFromListTopic(obj){
      try{
        if (!obj || !_.isObject(obj)) {
          throw new Error('Il manque l\'objet "obj" !');
        }
        if(obj.action === 'cleanFiltre') {
          useSearchByElasticsearch();
        }
      }
      catch(err){
        console.log('[LayoutTopicRecherche.LayoutTopicRechercheController] Erreur : '+err.message);
      }
    }
  }

})();
