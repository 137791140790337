(function() {
  'use strict';

  angular
    .module('collaboreApp')
    .factory('OcrFrontFeedback', OcrFrontFeedbackModel);

  /** @ngInject */
  function OcrFrontFeedbackModel(Main) {

    function OcrFrontFeedback(contenuData) {
      Main.apply(this, arguments);
    }

    OcrFrontFeedback.prototype = {

      model: 'OcrFrontFeedback',
      isOcrFrontFeedback: true,

      // id
      getId: function(){
        return this.id;
      },
      setId: function(val){
        this.id = val;
      },

      getCodeMetier: function(){
        return this.codeMetier;
      },
      setCodeMetier: function(val){
        this.codeMetier = val;
      },

      getNumFournisseur: function(){
        return this.numFournisseur;
      },
      setNumFournisseur: function(val){
        this.numFournisseur = val;
      },

      // societe
      getSociete: function(){
        return this.societe;
      },
      setSociete: function(val){
        this.societe = val;
      },

      // compteFournisseur
      getCompteFournisseur: function(){
        return this.compteFournisseur;
      },
      setCompteFournisseur: function(val){
        if(_.isString(val) && val.length === 7){
          this.setCodeMetier(val.substring(0, 3));
          this.setNumFournisseur(val.substring(3));
        }
        this.compteFournisseur = val;
      },

      // nomFournisseur
      getNomFournisseur: function(){
        return this.nomFournisseur;
      },
      setNomFournisseur: function(val){
        this.nomFournisseur = val;
      },

      // keyFournisseur
      getKeyFournisseur: function(){
        return this.keyFournisseur;
      },
      setKeyFournisseur: function(val){
        this.keyFournisseur = val;
      },

      // keyDateFacture
      getKeyDateFacture: function(){
        return this.keyDateFacture;
      },
      setKeyDateFacture: function(val){
        this.keyDateFacture = val;
      },

      // keyDateEcheance
      getKeyDateEcheance: function(){
        return this.keyDateEcheance;
      },
      setKeyDateEcheance: function(val){
        this.keyDateEcheance = val;
      },

      // keyNoFacture
      getKeyNoFacture: function(){
        return this.keyNoFacture;
      },
      setKeyNoFacture: function(val){
        this.keyNoFacture = val;
      },

      // keyMontantHT
      getKeyMontantHT: function(){
        return this.keyMontantHT;
      },
      setKeyMontantHT: function(val){
        this.keyMontantHT = val;
      },

      // keyMontantTTC
      getKeyMontantTTC: function(){
        return this.keyMontantTTC;
      },
      setKeyMontantTTC: function(val){
        this.keyMontantTTC = val;
      },

      // keyMontantTVA
      getKeyMontantTVA: function(){
        return this.keyMontantTVA;
      },
      setKeyMontantTVA: function(val){
        this.keyMontantTVA = val;
      },

      // idFacture
      getIdFacture: function(){
        return this.idFacture;
      },
      setIdFacture: function(val){
        this.idFacture = val;
      }

    };

    angular.extend(OcrFrontFeedback.prototype, Main.prototype);

    return OcrFrontFeedback;
  }
})();
