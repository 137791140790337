(function () {
    'use strict';

    angular
        .module('collaboreApp')
        .component('raisonClotureContenu', {
            templateUrl: 'app/topics/components/raison-cloture-contenu/raison-cloture-contenu.component.html',
            controller: RaisonClotureContenuController,
            controllerAs: 'raisoncloturecontenuctrl',
            bindings: {
                idContenu: '<',             // id du contenu à cloturer
                hideTemplates: '<',         // Cache les templates
                hideLinkFournisseur: '<',   // Cache la case à coché pour envoyer sur l'extranet fournisseur
                currentTemplate: '<',       // Template actuel
                titleModal: '@',            // Titre du composant en mode modal
                raisonCloture: '<',         // Raison de la cloture
                onEmit: '&',

                // Si Modal
                resolve: '<',
                close: '&',
                dismiss: '&',
                modalInstance: '<'
            }
        });

    /** @ngInject */
    function RaisonClotureContenuController($rootScope, $scope, ContenuService, UtilsService, ErreurCollabService, ModalsService) {
        var ctrl = this;

        ctrl.templates = [];
        ctrl.onChangeTemplate = onChangeTemplate;
        ctrl.onClickSave = onClickSave;
        ctrl.onClickClose = onClickClose;

        ctrl.$onInit = function () {
            if(ctrl.resolve && ctrl.modalInstance) {
                ctrl.idContenu = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'idContenu'));
                ctrl.titleModal = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'titleModal'));
                ctrl.raisonCloture = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'raisonCloture'));
                ctrl.hideTemplates = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'hideTemplates'));
                ctrl.hideLinkFournisseur = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'hideLinkFournisseur'));
                ctrl.currentTemplate = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'currentTemplate'));
            }
            if(!ctrl.idContenu)  ModalsService.alertErreur('Pas d\'idContenu !');
            if(!ctrl.titleModal) ctrl.titleModal = 'Cloturer';

            ctrl.templates = UtilsService.getModulesTemplatesObjet('template_cloture');
        };

        ctrl.$onChanges = function(changes){
        };

        ctrl.$onDestroy = function(){
        };

        /**
         * Lors du clique sur le bouton pour sauvegarder
         */
        function onClickSave(){
            try {
                var params = {
                    idContenu: ctrl.idContenu,
                    raisonCloture: ctrl.raisonCloture,
                    raisonClotureVisibleFournisseur: (ctrl.raisonClotureVisibleFournisseur) ? true : null
                };
                ctrl.loading = true;
                ContenuService
                    .cloturerContenu(params)
                    .then(function onSuccess(ret) {
                        emit({
                            action: 'updateContenu',
                            contenu: ret
                        });
                    })
                    .catch(function onError(msg){
                        ModalsService.alertErreur(msg);
                    })
                    .finally(function onFinally(){
                        ctrl.loading = false;
                    });
            }
            catch(err) {
                ModalsService.alertErreur(err.message);
                ctrl.loading = false;
            }
        }

        var prevTemplate = null,
            prevMessage = null;
        /**
         * Lors de la selection du model dans le champ select des templates
         */
        function onChangeTemplate() {

            var message = ctrl.raisonCloture;

            if (!prevTemplate) prevMessage = angular.copy(message);
            else if (prevTemplate.objet === '') prevMessage = angular.copy(message);

            var found = false;

            // Si un model est selectionné et qu'il y a des templates
            if (_.isObject(ctrl.currentTemplate) && _.isArray(ctrl.templates) && ctrl.templates.length) {
                // Pour chaque model
                for (var m = 0; m < ctrl.templates.length; m++) {
                    var currentModel = ctrl.templates[m];

                    // Si le model de la boucle en cours est égale au model choisi
                    if (ctrl.currentTemplate.idModuleValeurParams === currentModel.idModuleValeurParams) {
                        // Si il y a un objet
                        if (currentModel.objet !== '') {
                            message = angular.copy(currentModel.objet);
                            found = true;
                        }
                        break;
                    }
                }
            }

            // Si pas de model trouvé lors du changement et qu'il y avait un model choisi avant de changer
            if(!found && _.isObject(prevTemplate) && !_.isNil(prevTemplate.objet) && prevTemplate.objet !== '') {
                message = '';
                if (!_.isNil(prevMessage)) {
                    message = prevMessage;
                }
            }
            prevTemplate = angular.copy(ctrl.currentTemplate);
            ctrl.raisonCloture = message;
        }

        /**
         * Fermeture du composant si en mode modal
         */
        function onClickClose(){
            ctrl.dismiss({$value: 'cancel'});
        }

        /**
         * Traite les donnée pour les renvoyer au composant qui appel celui si
         */
        function emit(obj){
            if(!ctrl.modalInstance) {
                ctrl.onEmit({obj: obj});
            }
            else {
                ctrl.close({$value: obj});
            }
        }
    }
})();
