(function() {
  'use strict';

  angular.module('collaboreApp').service('GroupesAdminService', GroupesAdminServiceCtrl);

  /** @ngInject */
  function GroupesAdminServiceCtrl(MainService, $q,ErreurCollabService,Groupe,Portefeuille,AdminAjax,Module, ModalsService){

    var ctrl = this;

    ctrl.getGroupesOnly = getGroupesOnly;                     // Permet de récupérer seulement les groupes non user sans les users,portefeuilles etc...
    ctrl.getDetailGroupe = getDetailGroupe;                   // Permet de récupérer les infos d'un groupe et les groupe fils non user sur 1 profondeur sans details
    ctrl.getAllUsers = getAllUsers;                           // Permet de récupérer tout les users par le biais de la servlet
    ctrl.addGroupe = addGroupe;                               // Permet d'ajouter un groupe dans un groupe
    ctrl.deleteGroupeFromGroupe = deleteGroupeFromGroupe;     // Permet de supprimer un groupe d'un groupe
    ctrl.moveGroupeToGroupe = moveGroupeToGroupe;             // Permet de déplacer un groupe dans un groupe

    ctrl.createGroupeNoUserInGroupe = createGroupeNoUserInGroupe; // Permet de créer un groupe non user
    ctrl.assignTopicCloture = assignTopicCloture; // Permet de créer un groupe non user
    //ctrl.updateListUserInGroupe = updateListUserInGroupe;     // Permet d'ajouter et retirer des user avec une liste d'iGroupe
    ctrl.addListGroupeInGroupe = addListGroupeInGroupe;           // Permet d'ajouter des groupes avec une liste d'iGroupe
    ctrl.removeListGroupeFromGroupe = removeListGroupeFromGroupe;     // Permet de retirer des groupes avec une liste d'iGroupe

    ctrl.getAllPortefeuilles = getAllPortefeuilles;                     // Permet de récupérer tous les portefeuilles
    ctrl.addPortefeuille = addPortefeuille;                             // Ajout d'un portefeuille dans la société
    ctrl.updatePortefeuille = updatePortefeuille;                       // Update les infos d'un portefeuille de la société
    ctrl.deletePortefeuille = deletePortefeuille;                       // Supprime un portefeuille de la société
    ctrl.addPortefeuilleInGroupe = addPortefeuilleInGroupe;             // Ajoute un portefeuille à un groupe
    ctrl.deletePortefeuilleFromGroupe = deletePortefeuilleFromGroupe;   // Supprime un portefeuille d'un groupe

    ctrl.addModuleInGroupe = addModuleInGroupe;                         // Ajout d'un module dans un groupe
    ctrl.deleteModuleInGroupe = deleteModuleInGroupe;                   // Supprimme un module d'un groupe

    /**
     * Recupère un groupe sans les users, sans les listes avec une profondeur de groupes fils sans detail
     * @param nom
     * @returns {*}
     */
    function getGroupesOnly(nom){
      return _callHierarchieGroupe({
        'nom': nom
      });
    }

    /**
     * Recupère un groupe avec ses détails et une profondeur de groupes fils sans détail
     * @param nom
     * @returns {*}
     */
    function getDetailGroupe(nom){
      return _callHierarchieGroupe({
        'type': 1,
        'nom': nom
      });
    }

    /**
     * Permet d'appeler la servlet de hierarchie groupe
     * @param objParam
     * @returns {IPromise<T>}
     * @private
     */
    function _callHierarchieGroupe(objParam){
      var deferred = $q.defer();

      AdminAjax.getHierachie(objParam,function (json) {

        if (json.nb === '1') {
          deferred.resolve(new Groupe(json.Groupes[0]));
        }else{
          deferred.reject('Pas de Groupes en retour de GroupesAdminService._callHierarchieGroupe()');
        }

      },function (error) {
        ErreurCollabService.erreurServlet(error,'Problème avec GroupesAdminService._callHierarchieGroupe()',deferred);
      });

      return deferred.promise;
    }

    /************** Utilisateurs ***************/

    function checkErrorManageGroupe(error){
      if(error.status === 400 && _.isObject(error.data) && !_.isNil(error.data.message)){
        return error.data.message;
      }
      return false;
    }

    /**
     * Permet de récupérer tous les users
     * @returns {IPromise<T>}
     */
    function getAllUsers(){
      var deferred = $q.defer();

      AdminAjax.getAllUsers(function(json){
        if(json.nb!=='0'){
          var groupes = [];
          for(var i = 0 ; i < json.Groupes.length ; i++){
            groupes.push(new Groupe(json.Groupes[i]));
          }
          deferred.resolve(groupes);
        }else{
          deferred.reject('Aucun groupes');
        }

      },function(error){
        ErreurCollabService.erreurServlet(error,'Problème avec GroupesAdminService.getAllUsers()',deferred);
      });

      return deferred.promise;
    }

    /**
     * Permet d'ajouter un groupe dans un groupe
     * @param obj
     * @returns {IPromise<T>}
       */
    function addGroupe(obj) {

      var deferred = $q.defer();

      var libelleGroupeChild = '',idGroupeParent;

      if(angular.isObject(obj.groupeChild)){
        libelleGroupeChild = obj.groupeChild.getLibelle();
      }else if(angular.isString(obj.groupeChild)){
        libelleGroupeChild = obj.groupeChild;
      }

      if(angular.isObject(obj.groupeParent)){
        idGroupeParent = obj.groupeParent.getIdGroupe();
      }else if(angular.isNumber(obj.groupeParent)){
        idGroupeParent = obj.groupeParent;
      }

      var params = {
        groupe: libelleGroupeChild,
        groupePapa: idGroupeParent
      };

      if(!_.isNil(obj.dontAssignTopic)) params.dontAssignTopic = obj.dontAssignTopic;
      if(!_.isNil(obj.assignTopicCloture)) params.assignTopicCloture = obj.assignTopicCloture;

      AdminAjax.addGroupe(params,function (json) {

        if (json.nb === '1') {
          deferred.resolve(new Groupe(json.Groupes[0]));
        }
        else {
          deferred.reject('Pas de Groupes en retour de GroupesAdminService.addGroupe()');
        }

      }, function (error) {
        var err = checkErrorManageGroupe(error);
        if(err) deferred.reject(err);
        else {
          if(error.status === 403){
            deferred.reject('Ce nom groupe existe déjà !');
          }else{
            ErreurCollabService.erreurServlet(error,'Problème avec GroupesAdminService.addGroupe()',deferred);
          }
        }
      });

      return deferred.promise;
    }

    /**
     * Suppression d'un groupe qui est dans un autres groupe
     * @param obj
     * @returns {promise|*|Promise|capability.promise|a.fn.promise}
     */
    function deleteGroupeFromGroupe(obj) {
      var deferred = $q.defer();

      var params = {
        'groupe': obj.idGroupeChild,
        'groupePapa': obj.idGroupeParent
      };

      AdminAjax.deleteGroupeFromGroupe(params,function (json) {
        /*if (json.nb === '1') {
          deferred.resolve(new Groupe(json.Groupes[0]));
        }*/
        if (json.Resultats[0].success) {
          deferred.resolve(true);
        }
        else {
          deferred.reject('Erreur retour GroupesAdminService.deleteGroupeFromGroupe()');
        }

      }, function (error) {
        var err = checkErrorManageGroupe(error);
        if(err) deferred.reject(err);
        else ErreurCollabService.erreurServlet(error,'Problème avec GroupesAdminService.deleteGroupeFromGroupe()',deferred);
      });

      return deferred.promise;
    }

    /**
     * Déplace un groupe dans un groupe (et donc un user dans un groupe)
     * @param obj
     * @returns {promise|*|Promise|capability.promise|a.fn.promise}
     */
    function moveGroupeToGroupe(obj) {

      var deferred = $q.defer();

      var params = {
        'groupe': obj.idGroupeChild,
        'groupeOldPapa': obj.idGroupeOldParent,
        'groupeNewPapa': obj.idGroupeNewParent
      };

      AdminAjax.moveGroupeToGroupe(params,function (json) {

        /*if (json.nb === '1') {
          deferred.resolve(new Groupe(json.Groupes[0]));
        }*/
        if (json.Resultats[0].success) {
          deferred.resolve(true);
        }
        else {
          deferred.reject('Erreur retour GroupesAdminService.moveGroupeToGroupe()');
        }

      }, function (error) {
        var err = checkErrorManageGroupe(error);
        if(err) deferred.reject(err);
        else ErreurCollabService.erreurServlet(error,'Problème avec GroupesAdminService.moveGroupeToGroupe()',deferred);
      });

      return deferred.promise;
    }

    function createGroupeNoUserInGroupe(idGroupeParent, nomGroupe, listIdGroupeUser, objetEvenement, dontAssignTopic, assignTopicCloture){
      var deferred = $q.defer();
      try {
        if (!_.isNumber(idGroupeParent)) throw new Error('Le paramètre "idGroupeParent" doit être un nombre !');
        if (!_.isNil(listIdGroupeUser) && !_.isArray(listIdGroupeUser)) throw new Error('Le paramètre "listIdGroupeUser" doit être un array d\'integer !');

        var params = {
          action: 'createGroupeNoUserInGroupe',
          groupeCible: idGroupeParent
        };
        if(!_.isNil(nomGroupe)) params.nomGroupe = nomGroupe;
        if(_.isArray(listIdGroupeUser) && listIdGroupeUser.length) params.listIdGroupe = listIdGroupeUser;
        if(!_.isNil(objetEvenement)) params.objetEvenement = objetEvenement;

        if(!_.isNil(dontAssignTopic)) params.dontAssignTopic = dontAssignTopic;
        if(!_.isNil(assignTopicCloture)) params.assignTopicCloture = assignTopicCloture;

        AdminAjax.post(params, function(json){
          deferred.resolve(MainService.convertJsonToModel(json, 'groupe', 'Groupe', deferred));
        },function(error){
          var err = checkErrorManageGroupe(error);
          if(err) deferred.reject(err);
          else ErreurCollabService.erreurServlet(error,'Problème avec GroupesAdminService.createGroupeNoUserInGroupe()',deferred);
        });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Permet d'assigner les topic cloturés d'un groupe (idGroupeParent) et ses parents à un ou plusieur user (listIdGroupe)
     * @param {*} idGroupeParent
     * @param {*} listIdGroupe
     * @returns
     */
    function assignTopicCloture(idGroupeParent, listIdGroupe) {
      var deferred = $q.defer();
      try {
        if (!angular.isNumber(idGroupeParent)) throw new Error('Le paramètre "idGroupeParent" doit être un nombre !');
        if (!angular.isArray(listIdGroupe)) throw new Error('Le paramètre "listIdGroupe" doit être un array d\'integer !');

        var params = {
          action: 'assignTopicCloture',
          groupeCible: idGroupeParent,
          listIdGroupe: listIdGroupe
        };
        AdminAjax.post(params, function(json){
          deferred.resolve(MainService.convertJsonToModel(json, 'groupe', 'Groupe', deferred));
        },function(error){
          var err = checkErrorManageGroupe(error);
          if(err) deferred.reject(err);
          else ErreurCollabService.erreurServlet(error,'Problème avec GroupesAdminService.assignTopicCloture()',deferred);
        });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Permet d'ajouter/retirer des groupe user d'un groupe
     * @param groupeParent
     * @param listIdGroupeUser
     */
    /*
    function updateListUserInGroupe(idGroupeParent, listIdGroupeUser){
      var deferred = $q.defer();
      try {
        if (!angular.isNumber(idGroupeParent)) throw new Error('Le paramètre "idGroupeParent" doit être un nombre !');
        if (!angular.isArray(listIdGroupeUser)) throw new Error('Le paramètre "listIdGroupeUser" doit être un array d\'integer !');

        var params = {
          action: 'updateListUserInGroupe',
          groupeCible: idGroupeParent,
          listIdGroupe: listIdGroupeUser
        };
        AdminAjax.post(params, function(json){
          deferred.resolve(MainService.convertJsonToModel(json, 'groupe', 'Groupe', deferred));
        },function(error){
          var err = checkErrorManageGroupe(error);
          if(err) deferred.reject(err);
          else ErreurCollabService.erreurServlet(error,'Problème avec GroupesAdminService.updateListUserInGroupe()',deferred);
        });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }*/

    function addListGroupeInGroupe(idGroupeParent, listIdGroupe, dontAssignTopic, assignTopicCloture) {
      var deferred = $q.defer();
      try {
        if (!angular.isNumber(idGroupeParent)) throw new Error('Le paramètre "idGroupeParent" doit être un nombre !');
        if (!angular.isArray(listIdGroupe)) throw new Error('Le paramètre "listIdGroupe" doit être un array d\'integer !');

        var params = {
          action: 'addListGroupeInGroupe',
          groupeCible: idGroupeParent,
          listIdGroupe: listIdGroupe
        };
        if(!_.isNil(dontAssignTopic)) params.dontAssignTopic = dontAssignTopic;
        if(!_.isNil(assignTopicCloture)) params.assignTopicCloture = assignTopicCloture;

        AdminAjax.post(params, function(json){
          deferred.resolve(MainService.convertJsonToModel(json, 'groupe', 'Groupe', deferred));
        },function(error){
          var err = checkErrorManageGroupe(error);
          if(err) deferred.reject(err);
          else ErreurCollabService.erreurServlet(error,'Problème avec GroupesAdminService.addListUserInGroupe()',deferred);
        });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    function removeListGroupeFromGroupe(idGroupeParent, listIdGroupe) {
      var deferred = $q.defer();
      try {
        if (!angular.isNumber(idGroupeParent)) throw new Error('Le paramètre "idGroupeParent" doit être un nombre !');
        if (!angular.isArray(listIdGroupe)) throw new Error('Le paramètre "listIdGroupe" doit être un array d\'integer !');

        var params = {
          action: 'removeListGroupeFromGroupe',
          groupeCible: idGroupeParent,
          listIdGroupe: listIdGroupe
        };
        AdminAjax.post(params, function(json){
          deferred.resolve(MainService.convertJsonToModel(json, 'groupe', 'Groupe', deferred));
        },function(error){
          var err = checkErrorManageGroupe(error);
          if(err) deferred.reject(err);
          else ErreurCollabService.erreurServlet(error,'Problème avec GroupesAdminService.removeListUserFromGroupe()',deferred);
        });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /************** Portefeuille ***************/

    /**
     * Permet de récupérer tous les portefeuilles
     * @returns {IPromise<T>}
     */
    function getAllPortefeuilles(idGroupe){
      var deferred = $q.defer();

      var params = null;
      if(idGroupe) params = {idGroupe: idGroupe};


      AdminAjax.getAllPortefeuilles(params, function(json){
        if(json.nb!=='0'){
          var portefeuilles = [];
          for(var i = 0 ; i < json.Portefeuilles.length ; i++){
            portefeuilles.push(new Portefeuille(json.Portefeuilles[i]));
          }
          deferred.resolve(portefeuilles);
        }else{
          deferred.reject('Aucun portefeuille');
        }

      },function(error){
        ErreurCollabService.erreurServlet(error,'Problème avec GroupesAdminService.getAllPortefeuilles()',deferred);
      });

      return deferred.promise;
    }

    /**
     * Ajoute un portefeuille
     * @param obj
     * @returns {promise|*|Promise|capability.promise|a.fn.promise}
     */
    function addPortefeuille(obj, idGroupe) {

      var deferred = $q.defer();

      var params = {
        'nom': obj.nom,
        'cle': obj.cle,
        'loginId': obj.loginId,
        'mdpId': obj.mdpId
      };

      if(idGroupe) params.idGroupe = idGroupe;

      AdminAjax.addPortefeuille(params,function (json, idGroupe) {

        if(json.nb!=='0'){
          var portefeuilles = [];
          for(var i = 0 ; i < json.Portefeuilles.length ; i++){
            portefeuilles.push(new Portefeuille(json.Portefeuilles[i]));
          }
          deferred.resolve(portefeuilles);
        }else{
          deferred.reject('Aucun portefeuille');
        }

      }, function (error) {
        if(error.status===403){
          deferred.reject('Problème lors de la vérification de la clé portefeuille avec les login/pass');
        }else {
          ErreurCollabService.erreurServlet(error, 'Problème avec GroupesAdminService.addPortefeuille()', deferred);
        }
      });
      return deferred.promise;
    }

    /**
     * Update un portefeuille
     * @param obj
     * @returns {promise|*|Promise|capability.promise|a.fn.promise}
     */
    function updatePortefeuille(obj, idGroupe) {

      var deferred = $q.defer();

      var params = {
        'portefeuille': obj.idPortefeuille,
        'nom': obj.nom,
        'cle': obj.cle,
        'loginId': obj.loginId,
        'mdpId': obj.mdpId
      };

      if(idGroupe) params.idGroupe = idGroupe;

      if(obj.hasOwnProperty('portefeuilleInfos')){
        params.raisonSociale = obj.portefeuilleInfos.raisonSociale;
        params.adresse = obj.portefeuilleInfos.adresse;
        params.codePostal = obj.portefeuilleInfos.codePostal;
        params.ville = obj.portefeuilleInfos.ville;
        params.villeEdition = obj.portefeuilleInfos.villeEdition;
        params.telephone = obj.portefeuilleInfos.telephone;
        params.fax = obj.portefeuilleInfos.fax;
        params.email = obj.portefeuilleInfos.email;
        params.siteInternet = obj.portefeuilleInfos.siteInternet;
        params.auCapitalDe = obj.portefeuilleInfos.auCapitalDe;
        params.siret = obj.portefeuilleInfos.siret;
        params.ape = obj.portefeuilleInfos.ape;
        params.numCartePro = obj.portefeuilleInfos.numCartePro;
        params.numCarteProDelivreePar = obj.portefeuilleInfos.numCarteProDelivreePar;
        params.prefixSms = obj.portefeuilleInfos.prefixSms;
      }

      AdminAjax.updatePortefeuille(params,function (json) {

        if (json.nb === '1') {
          var instancePortefeuille = new Portefeuille(json.Portefeuilles[0]);
          deferred.resolve(instancePortefeuille);
        }
        else {
          deferred.reject('Pas de Portefeuille en retour de GroupesAdminService.updatePortefeuille()');
        }

      }, function (error) {
        if(error.status===403){
          deferred.reject('Problème lors de la vérification de la clé portefeuille avec les login/pass');
        }else{
          ErreurCollabService.erreurServlet(error,'Problème avec GroupesAdminService.updatePortefeuille()',deferred);
        }

      });


      return deferred.promise;
    }

    /**
     * Supression d'un portefeuille
     * @param obj
     * @returns {promise|*|Promise|capability.promise|a.fn.promise}
     */
    function deletePortefeuille(obj, idGroupe) {
      var deferred = $q.defer();

      var params = {
        'portefeuille': obj.idPortefeuille
      };
      if(idGroupe) params.idGroupe = idGroupe;

      AdminAjax.deletePortefeuille(params,function (json) {
        if (json.Resultats[0].success) {
          deferred.resolve(true);
        }
        else {
          deferred.reject('Erreur retour de GroupesAdminService.deletePortefeuille()');
        }

      }, function (error) {
        ErreurCollabService.erreurServlet(error,'Problème avec GroupesAdminService.deletePortefeuille()',deferred);
      });

      return deferred.promise;
    }

    /**
     * Ajoute un portefeuille dans un groupe
     * @param obj
     * @returns {promise|*|Promise|capability.promise|a.fn.promise}
     */
    function addPortefeuilleInGroupe(obj) {

      var deferred = $q.defer();

      var params = {
        'portefeuille': obj.idPortefeuille,
        'groupe': obj.idGroupe
      };

      AdminAjax.addPortefeuilleInGroupe(params, function (json) {

        if (json.nb === '1') {
          deferred.resolve(new Portefeuille(json.Portefeuilles[0]));
        }
        else {
          deferred.reject('Pas de Groupes en retour de GroupesAdminService.addPortefeuilleInGroupe()');
        }

      }, function (error) {
        if (error.status === 403) {
          deferred.reject('Problème lors de la vérification de la clé portefeuille avec les login/pass');
        } else {
          ErreurCollabService.erreurServlet(error, 'Problème avec GroupesAdminService.addPortefeuilleInGroupe()', deferred);
        }
      });


      return deferred.promise;
    }

    /**
     * Suppression d'un portefeuille d'un groupe
     * @param obj
     * @returns {promise|*|Promise|capability.promise|a.fn.promise}
     */
    function deletePortefeuilleFromGroupe(obj) {
      var deferred = $q.defer();

      var params = {
        'portefeuille': obj.idPortefeuille,
        'groupe': obj.idGroupe
      };

      AdminAjax.deletePortefeuilleFromGroupe(params,function (json) {
        if (json.Resultats[0].success) {
          deferred.resolve(true);
        }
        else {
          deferred.reject('Erreur retour de GroupesAdminService.deletePortefeuilleFromGroupe()');
        }

      }, function (error) {
        ErreurCollabService.erreurServlet(error,'Problème avec GroupesAdminService.deletePortefeuilleFromGroupe()',deferred);
      });

      return deferred.promise;
    }

    /************** Modules ***************/

    /**
     * Ajoute un module dans un groupe
     * @param obj
     * @returns {promise|*|Promise|capability.promise|a.fn.promise}
     */
    function addModuleInGroupe(obj) {

      var deferred = $q.defer();

      var params = {
        'idModule': obj.idModule,
        'idGroupe': obj.idGroupe
      };

      AdminAjax.addModuleInGroupe(params,function (json) {

        //console.log(json);
        if (json.nb === '1') {
          var module = new Module(json.ModuleGroupes[0].module);
          // module.idModuleGroupe = json.ModuleGroupes[0].idModuleGroupe;

          deferred.resolve(module);
        }
        else {
          deferred.reject('Mauvais retour de GroupesAdminService.addModuleInGroupe()');
        }
      }, function (error) {
        if(error.status===403){
          deferred.reject('Problème lors de l\'ajout du module');
        }else {
          ErreurCollabService.erreurServlet(error, 'Problème avec GroupesAdminService.addModuleInGroupe()', deferred);
        }
      });


      return deferred.promise;
    }

    /**
     * Supprime un module d'un groupe
     * @param obj
     * @returns {promise|*|Promise|capability.promise|a.fn.promise}
     */
    function deleteModuleInGroupe(obj) {

      var deferred = $q.defer();

      var params = {
        'idModule': obj.idModule,
        'idGroupe': obj.idGroupe,
        'aSupprimer': true
      };

      AdminAjax.addModuleInGroupe(params,function (json) {

        if (json.Resultats[0].success) {
          deferred.resolve(true);
        }
        else {
          deferred.reject('Erreur retour de GroupesAdminService.deleteModuleInGroupe()');
        }
      }, function (error) {
        ErreurCollabService.erreurServlet(error, 'Problème avec GroupesAdminService.deleteModuleInGroupe()', deferred);
      });


      return deferred.promise;
    }

    //**************************** Fin ok ************************

    /*
    var parcourGroupes = function(groupes,newGroupes){

      var nbGroupeFils = 0;
      // Si groupe all
      if (groupes.isAll()) {
        var tmpNewGroupes = {
          'idGroupe': groupes.idGroupe,
          'libelle': groupes.libelle,
          'dieze': groupes.dieze,
          'palier': groupes.palier,
          'showGroupes': true,
          'groupes': [],
          'users': [],
          'listePortefeuille': groupes.listePortefeuille
        };

        newGroupes = new Groupe(tmpNewGroupes);
      }else{
        if(newGroupes.hasOwnProperty('groupesFils')){
          nbGroupeFils = newGroupes.groupesFils.length;
          delete newGroupes.groupesFils;
        }


      }

      if(groupes.hasOwnProperty('groupesFils')){
        if(groupes.groupesFils.length) {

          angular.forEach(groupes.groupesFils, function (groupeJson) {

            var GroupeInstance = new Groupe(groupeJson);

            if(Groupe.palier===3){
              GroupeInstance.showGroupes = true;
            }else{
              GroupeInstance.showGroupes = false;
            }

            // Si pas groupe all
            if (!GroupeInstance.isAll()) {

              GroupeInstance.setGroupeParent(newGroupes);
              GroupeInstance.getGroupeParent().nbGroupes = nbGroupeFils;

              if (!newGroupes.hasOwnProperty('users')) {    newGroupes.users = [];}
              if (!newGroupes.hasOwnProperty('groupes')) {  newGroupes.groupes = [];}

              // Si c'est un user
              if (GroupeInstance.isUser()) {

                delete GroupeInstance.groupesFils;
                //delete GroupeInstance.user.fichePerso;

                // Si l'user n'existe pas encore dans la liste complete des users
                if(!ctrl.listeObjUsers.hasOwnProperty(GroupeInstance.getIdGroupe())){
                  ctrl.listeObjUsers[GroupeInstance.getIdGroupe()] = GroupeInstance;
                }

                newGroupes.users.push(GroupeInstance);

                // Sinon c'est un autre groupe
              } else {

                GroupeInstance.groupes = [];
                GroupeInstance.users = [];

                var newGroupe = angular.copy(GroupeInstance);
                newGroupe = parcourGroupes(GroupeInstance,newGroupe);
                newGroupes.groupes.push(newGroupe);
              }
            }
          });
        }
      }



      return newGroupes;
    };*/

    /**
     * Permet d'update les donnée du tableau existant sans changer les références Angular
     * @param newHierarchieGroupe
     */
    /*
    ctrl.updateHierarchieGroupe = function(newHierarchieGroupe,deferred){
      //console.log(newHierarchieGroupe);
      //console.log(ctrl.hierarchieGroupe);
      if(!ctrl.hierarchieGroupe){
        ctrl.hierarchieGroupe = newHierarchieGroupe;
      }else{
        ctrl.hierarchieGroupe.updateData(newHierarchieGroupe);
      }
      //console.log(ctrl.hierarchieGroupe);

      ctrl.setListeUsers(ctrl.listeObjUsers);
      ctrl.setListePortefeuilles(ctrl.hierarchieGroupe.listePortefeuille);

      if(deferred){
        deferred.resolve(ctrl.hierarchieGroupe);
      }
    };*/

    /*
    ctrl.deleteUserFromOldGroupe = function(groupes,dragFromGroupe,user,newGroupes){

      if(!newGroupes){
        newGroupes = {};
      }

      // Parcour la liste complète des groupes
      angular.forEach(groupes,function(groupeBoucle){
        // SI ce n'est pas all et pas un user
        if(!groupeBoucle.isAll() && !groupeBoucle.isUser()){
          // Si le groupe actuel de la boucle est celui du parent où il faut supprimer l'user
          if(groupeBoucle.getIdGroupe()===dragFromGroupe.getIdGroupe()){
            var usersGroupeBoucle = groupeBoucle.getUsers();
            // Recrée un array d'users du groupe sans l'user dedans car c'est l'ancien groupe d'où on a deplacé l'user
            var newUsers = $filter('removeWith')(usersGroupeBoucle,{'idGroupe':user.idGroupe});
            groupeBoucle.setUsers(newUsers);
          }else{
            // Si il y a encore des groupes
            if(groupeBoucle.getGroupes().length){
              ctrl.deleteUserFromOldGroupe(groupeBoucle.getGroupes(),dragFromGroupe,user);
            }
          }
        }
      });

      return newGroupes;
    };

    ctrl.deleteGroupeFromOldGroupe = function(groupes,dragFromGroupe,objectDropped){

      // Parcour la liste complète des groupes
      angular.forEach(groupes,function(groupeBoucle){
        // SI ce n'est pas all et pas un user
        if(!groupeBoucle.isAll() && !groupeBoucle.isUser()){
          // Si le groupe actuel de la boucle est celui du parent où il faut supprimer l'user
          if(groupeBoucle.getIdGroupe()===dragFromGroupe.getIdGroupe()){

            // Si c'est un user
            if(objectDropped.isUser()){
              var usersGroupeBoucle = groupeBoucle.getUsers();
              // Recrée un array d'users du groupe sans l'user dedans car c'est l'ancien groupe d'où on a deplacé l'user
              var newUsers = $filter('removeWith')(usersGroupeBoucle,{'idGroupe':objectDropped.idGroupe});
              groupeBoucle.setUsers(newUsers);

              // Si c'est un groupe
            }else{
              var groupesGroupeBoucle = groupeBoucle.getGroupes();
              // Recrée un array d'users du groupe sans l'user dedans car c'est l'ancien groupe d'où on a deplacé l'user
              var newGroupes = $filter('removeWith')(groupesGroupeBoucle,{'idGroupe':objectDropped.idGroupe});
              groupeBoucle.setGroupes(newGroupes);
            }

          }else{
            // Si il y a encore des groupes
            if(groupeBoucle.getGroupes().length){
              ctrl.deleteUserFromOldGroupe(groupeBoucle.getGroupes(),dragFromGroupe,objectDropped);
            }
          }
        }
      });
    };*/

    /*
    ctrl.setListeUsers = function(listeObjUsers){
      var users = [];
      angular.forEach(listeObjUsers,function(obj){

        delete obj.groupeParent;
        if(!obj.isModel){
          obj = new User(obj);
        }
        users.push(obj);

        obj = null;

      });
      ctrl.listeUsers = users;
    };


    ctrl.setListePortefeuilles = function(valListe){
      var liste = angular.copy(valListe);
      angular.forEach(liste,function(obj){
        delete obj.groupeParent;
      });

      ctrl.listePortefeuilles = liste;
      liste = null;
    };*/

    /************** Portefeuille ***************/

    /*
    ctrl.destroy = function(){
      initGroupesAdminService();
    };*/
  }

})();
