(function() {
    'use strict';

    angular.module('collaboreApp').controller('ModalSendFaxCtrl', ModalSendFaxCtrl);

    /** @ngInject */
    function ModalSendFaxCtrl($uibModalInstance,sweet,contenu,UtilsService,DocumentManagerService,GroupeService){
        var ctrl = this;

        // TODO : Modifier ce composant pour afficher les peroICS avec leur numéros dessous, prendre exemple surt form-send-sms. Sortir le composant du modal aussi si possible
        var destinataire = false;
        ctrl.loading = {
            'show': true,
            'message': 'Chargement...'
        };
        ctrl.numerosFax = [];       // Numéros de fax
        ctrl.numeroCustom = '';
        ctrl.errorNumeroCustom = {
            'etat': false,
            'message': ''
        };
        /**
         * Ferme le modal
         */
        ctrl.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        ctrl.submit = function(){

            var close = true;
            // Si il y a des numéros
            if(ctrl.numerosFax.length || ctrl.numeroCustom!==''){

                var numeros = [];
                // Parcour les numéro pour garder ceux cochés
                for(var i = 0 ; i < ctrl.numerosFax.length ; i++){
                    var objNumero = ctrl.numerosFax[i];
                    // Si coché
                    if(objNumero.checked){
                        var numeroParse = UtilsService.cleanPhoneNumber(objNumero.numero);
                        if(numeroParse!=='' && numeroParse.length==10){
                            numeros.push(numeroParse);
                        }
                    }
                }

                if(ctrl.numeroCustom!==''){
                    ctrl.numeroCustom = UtilsService.cleanPhoneNumber(ctrl.numeroCustom);
                    if(ctrl.numeroCustom!=='' && ctrl.numeroCustom.length==10){

                        var fichePerso = destinataire.getUser().getFichePerso();
                        fichePerso.saveFax(ctrl.numeroCustom);

                        numeros.push(ctrl.numeroCustom);
                    }else{
                        ctrl.errorNumeroCustom.etat = true;
                        if(ctrl.numeroCustom===''){
                            ctrl.errorNumeroCustom.message = 'Numéro vide...';
                            close = false;
                        }
                        if(ctrl.numeroCustom.length!==10){
                            close = false;
                            ctrl.errorNumeroCustom.message = 'Le numéro doit avoir 10 chiffres';
                        }
                    }
                }

                // Si il rest des numéros
                if(numeros.length){

                    close = false;
                    ctrl.loading.show = true;
                    ctrl.loading.message = 'Envoi en cours...';

                    /*

                    console.log('Send : ',numeros);

                    sweet.show({
                        title: 'Terminé',
                        text: 'Fax envoyé !',
                        type: 'success',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonText: 'OK'
                    });
*/


                  DocumentManagerService.sendDemandeDevisParFax(contenu.getIdContenu(),numeros).then(function(json){
                        if(json.Resultats[0].success){
                            sweet.show({
                                title: 'Terminé',
                                text: 'Fax envoyé !',
                                type: 'success',
                                showCancelButton: true,
                                showConfirmButton: false,
                                cancelButtonText: 'OK'
                            });
                        }else{
                            sweet.show({
                                title: 'Problème...',
                                text: 'Retour Servlet pas ok...',
                                type: 'error',
                                showCancelButton: true,
                                showConfirmButton: false,
                                cancelButtonText: 'OK'
                            });
                        }

                    },function(msg){
                         sweet.show({
                             title: 'Problème...',
                             text: msg,
                             html:true,
                             type: 'error',
                             showCancelButton: true,
                             showConfirmButton: false,
                             cancelButtonText: 'OK'
                         });
                    }).finally(function(){
                        $uibModalInstance.close();
                    });
                }

            }

            if(close){
                $uibModalInstance.close();
            }
        };

        $uibModalInstance.rendered.then(function(){
            ctrl.loading.show = true;
            ctrl.loading.message = 'Chargement des numéros...';

            if(contenu.isDevis){
                if(contenu.getDestinataires().length){
                    destinataire = contenu.getDestinataires()[0];
                }
            }

            GroupeService
                .getInfosGroupe(destinataire.getIdGroupe())
                .then(function(groupe) {
                    if(destinataire){
                        destinataire = groupe;

                        var numerosFax = [];

                        var fichePerso = groupe.getUser().getFichePerso();

                        // Numéro des surfiches
                        var faxSurfiche = fichePerso.getFax();
                        if(faxSurfiche.length){
                            for(var f = 0 ; f < faxSurfiche.length ; f++){
                                numerosFax.push({
                                    'checked': true,
                                    'numero': faxSurfiche[f].getDonnee()
                                });
                            }
                        }

                        // Si il y a une fiche père
                        if(fichePerso.hasFichePere()){
                            // Recupère les fax de la fichePere et parcour les resultats
                            var arrayFichePereFax = fichePerso.getFichePere().getFax();
                            if(arrayFichePereFax.length){
                                for(var f2 = 0 ; f2 < arrayFichePereFax.length ; f2++){
                                    numerosFax.push({
                                        'checked': true,
                                        'numero': arrayFichePereFax[f2].getDonnee()
                                    });
                                }
                            }
                        }

                        // Recupère les infos perso des portefeuilles
                        GroupeService.getInfosPersonnalite(groupe,true).then(function(arrayPortefeuille){
                            //console.log(arrayPortefeuille);

                            if(arrayPortefeuille.length){
                                for(var i = 0 ; i < arrayPortefeuille.length ; i++){
                                    var portefeuille = arrayPortefeuille[i];
                                    if(portefeuille.listePersonnalite.length){
                                        for(var j = 0 ; j < portefeuille.listePersonnalite.length ; j++){
                                            var personnalite = portefeuille.listePersonnalite[j];
                                            if(personnalite.getNofax()){
                                                numerosFax.push({
                                                    'checked': true,
                                                    'numero': personnalite.getNofax()
                                                });
                                            }
                                            if(personnalite.isPersoFournisseur){
                                                if(personnalite.getNofaxs()){
                                                    numerosFax.push({
                                                        'checked': true,
                                                        'numero': personnalite.getNofaxs()
                                                    });
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            if(numerosFax.length){
                                ctrl.numerosFax = numerosFax;
                            }
                        },function(msg){
                            console.log(msg);
                        }).finally(function(){
                            ctrl.loading.show = false;
                        });
                    }
                });
        });
    }

})();
