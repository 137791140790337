'use strict';

/**
 * @ngdoc service
 * @name collaboreApp.Personnalite
 * @description
 * # Personnalite
 * Service in the collaboreApp.
 */

angular.module('collaboreApp').factory('Salarie',[
		'UtilsService',
	function(UtilsService) {

		function Salarie(objetData) {
			if (objetData) {
				this.hydrate(objetData);
			}
		}

		Salarie.prototype = {

			isModel: true,
			isSalarie: true,
			model: 'Salarie',

			hydrate: function(contenuData){
				var scope = this;
				// Hydratation
				angular.forEach(contenuData,function(val,key){
					var setter = 'set' + key.ucfirst();
					if(angular.isFunction(scope[setter])) {
						scope[setter](val);
					}
				});
			},

			setData: function(objetData) {
				angular.extend(this, objetData);
			},

			// Nom
			getNom: function(isPersoICS){
				var nom = '';

				if(this.getNomper()!=='' && this.getNomper()!==false && angular.isDefined(this.getNomper())){
					if(this.getCodelib()!=='' && this.getCodelib()!==false && angular.isDefined(this.getCodelib()) && !isPersoICS){
						nom = this.getCodelib()+' ';
					}
					nom += this.getNomper();
				}

				return nom;
			},

			// NoPerso
			getNoperso: function(){
				return this.noperso;
			},
			setNoperso: function(valNoperso){
				this.noperso = valNoperso;
			},

			// Codelib
			getCodelib: function(){
				return this.codelib;
			},
			setCodelib: function(valCodelib){
				this.codelib = valCodelib;
			},

			// Nomper
			getNomper: function(){
				return this.nomper;
			},
			setNomper: function(valNomper){
				this.nomper = valNomper;
			},

			// datenai
			getDatenai: function(enableFormatDate){
				return UtilsService.parseDateForProperty('datenai',this,enableFormatDate);
			},
			setDatenai: function(val){
				this.datenai = val;
			},

			// lieunai
			getLieunai: function(){
				return this.lieunai;
			},
			setLieunai: function(val){
				this.lieunai = val;
			},

			// Notel1
			getNotel1: function(){
				return this.notel1;
			},
			setNotel1: function(valNotel1){
				this.notel1 = valNotel1;
			},
			// Notel2
			getNotel2: function(){
				return this.notel2;
			},
			setNotel2: function(valNotel2){
				this.notel2 = valNotel2;
			},

			// Nofax
			getNofax: function(){
				return this.nofax;
			},
			setNofax: function(valNofax){
				this.nofax = valNofax;
			},

			// Telephones
			getTelephones: function(){

				var telephones = [];
				if(angular.isDefined(this.getNotel1()) && this.getNotel1()!==''){	telephones.push(this.getNotel1());}
				if(angular.isDefined(this.getNotel2()) && this.getNotel2()!==''){	telephones.push(this.getNotel2());}

				return telephones;
			},

			// Mails
			getMails: function(){
				if(!angular.isArray(this.mails)){
					this.mails = [];
				}
				return this.mails;
			},
			setMails: function(arrayMails){
				this.mails = arrayMails;
			},

			// Ad1
			getAd1: function(){
				return this.Ad1;
			},
			setAd1: function(valAd1){
				this.Ad1 = valAd1;
			},

			// Ad2
			getAd2: function(){
				return this.Ad2;
			},
			setAd2: function(valAd2){
				this.Ad2 = valAd2;
			},

			// Ad3
			getAd3: function(){
				return this.Ad3;
			},
			setAd3: function(valAd3){
				this.Ad3 = valAd3;
			},

			// Ad4
			getAd4: function(){
				return this.Ad4;
			},
			setAd4: function(valAd4){
				this.Ad4 = valAd4;
			},


			// profess
			getProfess: function(){
				return this.profess;
			},
			setProfess: function(val){
				this.profess = val;
			},

			// niveau
			getNiveau: function(){
				return this.niveau;
			},
			setNiveau: function(val){
				this.niveau = val;
			},

			// No
			getNo: function(){
				return this.No;
			},
			setNo: function(val){
				this.No = val;
			},


			getVille: function(){
				var retour = '';
				if(this.getAd4()){
					retour = this.getAd4();
				}
				return retour;
			},

			// Adresse
			getAdresse: function(){
				var adresse = '';

				if(angular.isDefined(this.getAd1()) && this.getAd1()!==''){	adresse += '<div>'+this.getAd1()+'</div>';}
				if(angular.isDefined(this.getAd2()) && this.getAd2()!==''){	adresse += '<div>'+this.getAd2()+'</div>';}
				if(angular.isDefined(this.getAd3()) && this.getAd3()!==''){	adresse += '<div>'+this.getAd3()+'</div>';}
				if(angular.isDefined(this.getAd4()) && this.getAd4()!==''){	adresse += '<div>'+this.getAd4()+'</div>';}


				return adresse;
			}

		};

		return Salarie;

	}
]);