(function() {

  'use strict';

  /**
   * @desc Componsant pour choisir un destinataire
   * @example <choose-collaborateur></choose-collaborateur>
   */

  angular
    .module('collaboreApp')
    .component('chooseCollaborateur',{
      templateUrl: 'app/topics/components/choose-collaborateur/choose-collaborateur.component.html',
      bindings : {
        idContenu: '<',                   // IdContenu
        hideCollaborateurPresent: '<',    // Permet de cacher les collaborateur qui sont present dans le groupe passé en param
        onlyCollaborateurs: '<',          // Affiche seulement les collaborateurs
        addMe: '<',                       // Si je doit etre dans la liste
        //onEndGetGroupeAll: '&',          // Lorsque le composant a fini de récupérer la hiérarchie
        collaborateurs: '<',              // La liste des collaborateurs du contenu
        onChangeCollaborateur: '&',       // Lorsque la liste des collaborateur change, notifie le composant parent

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<',
        iconTitleModal: '@',
        textTitleModal: '@'
      },
      controllerAs: 'choosecollaborateurctrl',
      controller: ChooseCollaborateurController
    });

  /*@ngInject*/
  function ChooseCollaborateurController($scope, $injector, $timeout, GroupeService, UtilsService, AnnuaireService){

    var _this = this;
    _this.searchGroupe = '';
    _this.searchEnabled = false;

    _this.listGroupeUser = [];
    _this.listGroupeEquipe = [];
    _this.itsMe = UtilsService.itsMe;
    _this.onSubmitSearchDestinataire = onSubmitSearchDestinataire;
    _this.onClickClearSearchDestinataire = onClickClearSearchDestinataire;
    _this.onSubmitLibreDestinataire = onSubmitLibreDestinataire;
    _this.onCheckGroupe = onCheckGroupe;
    _this.onCheckAllGroupeUser = onCheckAllGroupeUser;
    _this.onClickClose = onClickClose;
    _this.onClickSave = onClickSave;

    _this.$onInit = function() {

      if(_this.resolve && _this.modalInstance) {
        _this.idContenu = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'idContenu'));
        _this.hideCollaborateurPresent = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'hideCollaborateurPresent'));
        _this.onlyCollaborateurs = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'onlyCollaborateurs'));
        _this.addMe = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'addMe'));
        _this.collaborateurs = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'collaborateurs'));
        _this.iconTitleModal = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'iconTitleModal'));
        _this.textTitleModal = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'textTitleModal'));

        if(_.isNil(_this.iconTitleModal) || !_this.iconTitleModal) _this.iconTitleModal = 'fa-users';
        if(_.isNil(_this.textTitleModal) || !_this.textTitleModal) _this.textTitleModal = 'Mes collaborateurs';
      }

      //console.log(_this.collaborateurs);
      //_this.selectedList = [];  // Liste des collaborateurs actuel du composant

      initSelectedList(_this.collaborateurs);
      /*
      if(_this.collaborateurs) {
        var dest = [];
        if(_this.collaborateurs.length) {
          for(var i = 0; i < _this.collaborateurs.length; i++){
            if(_this.addMe || !_this.itsMe(_this.collaborateurs[i])) dest.push(angular.copy(_this.collaborateurs[i]));
          }
        }
        _this.selectedList = dest;
      }*/
      _this.newCollaborateurs = []; // Liste des destinatiares pour les nouveaux destinataire si contenu existe déjà

      $scope.$on('addCollaborateur', function(event, val){
        //console.log('addCollaborateur', val);
        addCollaborateur(val);
        emitCollaborateur();
      });
      /*
      $scope.$on('removeCollaborateur', function(event, val){
        console.log('removeCollaborateur', val);
        removeCollaborateur(val);
        changeStatusCheckedForAllFromDestinataires(); // Change l'état de l'attribut checked dans les liste de groupes
        emitCollaborateur();
      });*/

      init();
      /*
      $scope.$on('clearListNewCollaborateurs', function(){
        _this.newCollaborateurs = [];
      });
      */
    };

    /**
     * Lors de changements depuis le parent
     * @param changes
     */
    _this.$onChanges = function (changes) {
      //console.log(changes);

      if(_.isObject(changes.collaborateurs) && !changes.collaborateurs.isFirstChange() && changes.collaborateurs.currentValue.length){
        initSelectedList(changes.collaborateurs.currentValue);
        changeStatusCheckedForAllFromDestinataires();
      }
      /*
      if(changes.collaborateurs) {
        var dest = [];
        if(_.isArray(changes.collaborateurs.currentValue) && changes.collaborateurs.currentValue.length) {
          for(var i = 0; i < changes.collaborateurs.currentValue.length; i++){
            if(_this.addMe || !_this.itsMe(changes.collaborateurs.currentValue[i])) dest.push(angular.copy(changes.collaborateurs.currentValue[i]));
          }
        }
        _this.selectedList = dest;
        changeStatusCheckedForAllFromDestinataires();
      }*/
    };

    _this.$onDestroy = function(){
      //GroupeService.setGroupeAllWithHierarchie(null);
    };


    /**
     * Initlisation
     */
    function init(){

      _this.configScrollbar = {
        autoHideScrollbar: false,
        theme: 'dark-3',
        advanced: {
          updateOnContentResize: true
        },
        scrollButtons: {
          scrollAmount: 'auto', // scroll amount when button pressed
          enable: false // enable scrolling buttons by default
        },
        axis: 'y', // enable 2 axis scrollbars by default
        //setHeight: 200,
        scrollInertia: 100
      };

      _this.groupeAll = GroupeService.getGroupeAllWithHierarchie();

      if(!_this.groupeAll) {
        _this.loading = true;
        GroupeService
          .getHierarchieAgenceWithoutPortefeuille()
          .then(function (groupe) {
            _this.groupeAll = groupe;
            //_this.groupeAllFiltered = angular.copy(_this.groupeAll);
            //_this.onEndGetGroupeAll({groupeAll: _this.groupeAll});
            initDestinataires();
            /*
            if(_this.collaborateurs.length && _this.groupeAllFiltered){
              initDestinataires();
            }*/
          })
          .finally(function(){
            _this.loading = false;
          });
      }
      else {
        //_this.groupeAllFiltered = angular.copy(_this.groupeAll);
        initDestinataires();
      }
    }

    function initSelectedList(collaborateurs){
      var dest = [];
      if(_.isArray(collaborateurs) && collaborateurs.length) {
        for(var i = 0; i < collaborateurs.length; i++){
          if(_this.addMe || !_this.itsMe(collaborateurs[i])) dest.push(angular.copy(collaborateurs[i]));
        }
      }
      _this.selectedList = dest;
    }

    /**
     * Permet d'initialiser les liste utiliser dans le composant tout en cochant les cases par rapport au destinataires
     * @param groupe
     */
    function initDestinataires(groupe){
      if(!groupe) groupe = _this.groupeAll;
      if(groupe){
        // Si groupe all alors on ne test que ses enfants
        if(groupe.isAll()){
          if(groupe.getGroupesFils().length) {
            for(var j = 0; j < groupe.getGroupesFils().length; j++){
              initDestinataires(groupe.getGroupesFils()[j]);
            }
          }
        }
        // Si pas groupe All
        else {
          var exist = false;
          // Si user
          if(groupe.isUser()) {
            if(_this.addMe || !_this.itsMe(groupe)){
              // Si il y a déjà des groupe user il faut eviter les doublons
              if(_this.listGroupeUser.length) {
                for(var i = 0; i < _this.listGroupeUser.length; i++){
                  if(_this.listGroupeUser[i].idGroupe === groupe.idGroupe) {
                    exist = true;
                    break;
                  }
                }
              }
              // Si existe pas on l'ajoute
              if(!exist) _this.listGroupeUser.push(groupe);

            }
          }
          // Si pas user
          else {
            // Si il y a déjà des groupe equipe il faut eviter les doublons
            if(_this.listGroupeEquipe.length) {
              for(var k = 0; k < _this.listGroupeEquipe.length; k++){
                if(_this.listGroupeEquipe[k].idGroupe === groupe.idGroupe) {
                  exist = true;
                  break;
                }
              }
            }
            // Si existe pas on l'ajoute
            if(!exist) {
              var copyGroupe = angular.copy(groupe);
              var groupesFils = [];
              // Si il y a des groupes fils
              if(groupe.getGroupesFils().length) {
                // Parcours les fils
                for(var l = 0; l < groupe.getGroupesFils().length; l++){
                  initDestinataires(groupe.getGroupesFils()[l]);
                  // Si le fils n'est pas un user on le vire des enfants car on garde seulement les fils user
                  if(groupe.getGroupesFils()[l].isUser()) groupesFils.push(groupe.getGroupesFils()[l]);
                }
              }
              copyGroupe.setGroupesFils(groupesFils);
              _this.listGroupeEquipe.push(copyGroupe);
            }
          }
        }
      }
      if(groupe.isAll()) {
        _this.listGroupeUserFiltered = angular.copy(_this.listGroupeUser);
        _this.listGroupeEquipeFiltered = angular.copy(_this.listGroupeEquipe);
        changeStatusCheckedForAllFromDestinataires();
        GroupeService.setGroupeAllWithHierarchie(groupe);
      }
    }

    /**
     * Change l'état de l'attribut "checked" des groupes de la liste "listGroupeUser" et "listGroupeEquipe" par rapport aux groupes de la liste "destinataires"
     */
    function changeStatusCheckedForAllFromDestinataires(){
      changeStatusCheckedGroupesUserFromDestinataires();
      changeStatusCheckedGroupesEquipeFromDestinataires();
      //onSubmitSearchDestinataire();
    }

    /**
     * Change l'état de l'attribut "checked" des groupes de la liste "listGroupeUser" par rapport aux groupes de la liste "destinataires"
     */
    function changeStatusCheckedGroupesUserFromDestinataires(){
      /*
      _this.listGroupeUserFiltered = angular.copy(_this.listGroupeUser);
      _this.listGroupeUserFiltered = changeStatusCheckedFromDestinataires(_this.listGroupeUserFiltered);
      */
      _this.listGroupeUserFiltered = changeStatusCheckedFromDestinataires(_this.listGroupeUserFiltered);
    }

    /**
     * Change l'état de l'attribut "checked" des groupes de la liste "listGroupeEquipe" par rapport aux groupes de la liste "destinataires"
     */
    function changeStatusCheckedGroupesEquipeFromDestinataires(){
      /*
      _this.listGroupeEquipeFiltered = angular.copy(_this.listGroupeEquipe);
      _this.listGroupeEquipeFiltered = changeStatusCheckedFromDestinataires(_this.listGroupeEquipeFiltered);
      */
      _this.listGroupeEquipeFiltered = changeStatusCheckedFromDestinataires(_this.listGroupeEquipeFiltered);
    }

    /**
     * Change l'état de l'attribut "checked" des groupes de la liste passé en paramètre par rapport aux groupes de la liste "destinataires"
     * @param listeGroupe
     */
    function changeStatusCheckedFromDestinataires(listeGroupe){
      if(_.isArray(listeGroupe) && listeGroupe.length) {
        for(var n = 0; n < listeGroupe.length; n++){
          listeGroupe[n].checked = false;

          if(_this.selectedList.length) {
            for(var d = 0; d < _this.selectedList.length; d++) {
              if (GroupeService.isSameGroupe(_this.selectedList[d], listeGroupe[n])) {
                listeGroupe[n].checked = true;
                // Si il y a un idContenu alors c'est un contenu enregistré on doit donc rendre impossible de decocher le groupe qui est déjà destinataire
                if(_this.idContenu || _this.selectedList[d].disabledCheckbox) listeGroupe[n].disabledCheckbox = true; // Disable la case à cocher
              }
            }
          }

          if(_this.idContenu && _this.newCollaborateurs.length) {
            for(var f = 0; f < _this.newCollaborateurs.length; f++){
              if(GroupeService.isSameGroupe(_this.newCollaborateurs[f], listeGroupe[n])) {
                listeGroupe[n].checked = true;
              }
            }
          }
        }
      }
      return listeGroupe;
    }

    /**
     * Lors du submit du formulaire de recherche
     */
    function onSubmitSearchDestinataire(){

      _this.listGroupeUserFiltered = angular.copy(_this.listGroupeUser);
      _this.listGroupeEquipeFiltered = angular.copy(_this.listGroupeEquipe);
      if(_this.searchGroupe !== '') {
        _this.searchEnabled = true;
        if(_this.listGroupeUserFiltered.length) {
          var copyListGroupeUser = [];
          for(var gu = 0; gu < _this.listGroupeUserFiltered.length; gu++) {
            if(searchInGroupe(_this.searchGroupe, _this.listGroupeUserFiltered[gu])) copyListGroupeUser.push(_this.listGroupeUserFiltered[gu]);
          }
          _this.listGroupeUserFiltered = copyListGroupeUser;
        }

        if(_this.listGroupeEquipeFiltered.length) {
          var copyListGroupeEquipe = [];
          for(var ge = 0; ge < _this.listGroupeEquipeFiltered.length; ge++) {
            if(searchInGroupe(_this.searchGroupe, _this.listGroupeEquipeFiltered[ge])) copyListGroupeEquipe.push(_this.listGroupeEquipeFiltered[ge]);
          }
          _this.listGroupeEquipeFiltered = copyListGroupeEquipe;
        }
      }
      else _this.searchEnabled = false;
      changeStatusCheckedForAllFromDestinataires();
    }

    function onClickClearSearchDestinataire(){
      _this.searchEnabled = false;
      _this.searchGroupe = '';
      onSubmitSearchDestinataire();
    }

    /**
     * Permet de recherche un groupe par rapport à un string
     * @param search
     * @param groupe
     * @returns {boolean}
     */
    function searchInGroupe(search, groupe){
      var find = false;
      var regEx = new RegExp(search.toLowerCase(), 'ig');
      if(UtilsService.test((groupe.getDieze()) ? groupe.getDieze().toLowerCase() : '', search, 'ig')) {
        find = true;
        //groupe.open = true;
        groupe.dieze = groupe.dieze.replace(regEx, function(match) {
          return '<strong style="color:#5ab728">' + match + '</strong>';
        });
      }

      if(UtilsService.test((groupe.getLibelle()) ? groupe.getLibelle().toLowerCase() : '', search, 'ig')) {
        find = true;
        //groupe.open = true;
        groupe.libelle = groupe.libelle.replace(regEx, function(match) {
          return '<strong style="color:#5ab728">' + match + '</strong>';
        });
      }

      if(UtilsService.test((groupe.getDefaultLibelle()) ? groupe.getDefaultLibelle().toLowerCase() : '', search, 'ig')) {
        find = true;
        //groupe.open = true;
        groupe.defaultLibelle = groupe.defaultLibelle.replace(regEx, function (match) {
          return '<strong style="color:#5ab728">' + match + '</strong>';
        });
      }
      return find;
    }

    /**
     * Lors du submit du formulaire de recherche
     *
     function onSubmitSearchDestinataire(){
      _this.groupeAllFiltered = angular.copy(_this.groupeAll);
      if(_this.searchGroupe !== '') recursiveSearch(_this.searchGroupe, _this.groupeAllFiltered);
    }*/

    /**
     * Lors du submit du formulaire d'ajout de destinataire libre
     */
    function onSubmitLibreDestinataire(){
      _this.messageErreurDestinataireLibre = '';
      if(_this.libreDestinataire !== '') {
        var obj = null;

        // Si email
        if(UtilsService.isMail(_this.libreDestinataire)) obj = {isMail: true, libelle: _this.libreDestinataire, dieze: ''};
        // Si groupe collab
        else if(UtilsService.isStringGroupeCollab(_this.libreDestinataire)) obj = UtilsService.convertStringToGroupeCollab(_this.libreDestinataire);
        // Erreur
        else _this.messageErreurDestinataireLibre = 'Veuillez saisir : exemple#Societe OU un email';

        if(obj) {
          addCollaborateur(obj);
          _this.libreDestinataire = '';
        }
      }
    }

    /**
     * Recherche un string dans la hiérarchie du groupeAll
     * @param search
     * @param groupe
     * @param groupeParent
     * @returns {boolean}
     *
     function recursiveSearch(search, groupe, groupeParent){

      if(!groupe.isAll()) {
        var find = false;

        var regEx = new RegExp(search, 'ig');

        if(UtilsService.test(groupe.getDieze(), search, 'ig')) {
          find = true;
          groupe.dieze = groupe.dieze.replace(regEx, function(match) {
            return '<strong style="color:#5ab728">' + match + '</strong>';
          });
        }

        if(UtilsService.test(groupe.getLibelle(), search, 'ig')) {
          find = true;
          groupe.libelle = groupe.libelle.replace(regEx, function(match) {
            return '<strong style="color:#5ab728">' + match + '</strong>';
          });
        }

        if(UtilsService.test(groupe.getDefaultLibelle(), search, 'ig')) {
          find = true;
          groupe.defaultLibelle = groupe.defaultLibelle.replace(regEx, function (match) {
            return '<strong style="color:#5ab728">' + match + '</strong>';
          });
        }

        if(find) groupe.keep = true;
        else groupe.keep = false;
        groupe.open = true;
      }
      // Si c'est le groupe all on le garde
      else groupe.keep = true;

      // Si le groupe a des enfants
      if(groupe.getGroupesFils().length) {
        for(var i = 0; i < groupe.getGroupesFils().length; i++) {
          if(recursiveSearch(search, groupe.getGroupesFils()[i], groupe)) i--;
        }
      }
      return spliceGroupeNotKeepFromParent(groupe, groupeParent);
    }*/

    /**
     * Supprime le groupe qui n'est pas gardé de son groupe parent
     * @param groupe
     * @param groupeParent
     * @returns {boolean}
     *
     function spliceGroupeNotKeepFromParent(groupe, groupeParent){
      var retour = false;
      // Si le groupe n'a pas d'enfants et Si il ne doit pas étre gardé alors on le vire du groupe parent
      if(!groupe.getGroupesFils().length && !groupe.keep) {
        for(var g = 0; g < groupeParent.getGroupesFils().length; g++){
          if(groupeParent.getGroupesFils()[g].idGroupe === groupe.idGroupe) {
            groupeParent.getGroupesFils().splice(g, 1);
            retour = true;
          }
        }
      }
      return retour;
    }*/

    /**
     * Permet d'ajouter un destinataire à la liste des destinataires
     * @param groupe | Array
     */
    function addCollaborateur(destinataire) {
      _this.selectedList = GroupeService.addGroupeToList(destinataire, _this.selectedList);
      if(_this.idContenu) _this.newCollaborateurs = GroupeService.addGroupeToList(destinataire, _this.newCollaborateurs);
    }

    /**
     * Permet de retirer un collaborateur de la selectedList
     * @param groupe
     */
    function removeCollaborateur(groupe) {
      _this.selectedList = GroupeService.removeGroupeFromList(groupe, _this.selectedList);
      if(_this.idContenu) _this.newCollaborateurs = GroupeService.removeGroupeFromList(groupe, _this.newCollaborateurs);
    }

    /**
     * Permet d'ajouter un destinataire à la liste des destinataires
     * @param groupe | Array
     */
    /*
    function addCollaborateur(destinataire) {

      var Groupe = $injector.get('Groupe');
      // Si le nouveau destinataire est une liste de destinataire
      if(angular.isArray(destinataire)) {
        // Si elle n'est pas vide
        if(destinataire.length) {
          // Parcour tout les nouveaux destinataire
          for(var d = 0; d < destinataire.length; d++){
            if(!collaborateurExistInSelectList(destinataire[d])) {
              if(!destinataire[d].isMail) {
                if(destinataire[d].hasOwnProperty('libelle')) destinataire[d].libelle = UtilsService.stripTags(destinataire[d].libelle);
                if(destinataire[d].hasOwnProperty('dieze')) destinataire[d].dieze = UtilsService.stripTags(destinataire[d].dieze);
                if(destinataire[d].hasOwnProperty('defaultLibelle')) destinataire[d].defaultLibelle = UtilsService.stripTags(destinataire[d].defaultLibelle);
                destinataire[d] = new Groupe(destinataire[d]);
              }

              // Si pas nouveau contenu, on permet également de retirer les groupes grace à l'attribut "newInCurrentDestinataires" ajouté lors de l'ajout du groupe dans la liste currentDestinataires
              //if(_this.idContenu) destinataire[d].newInCurrentDestinataires = true;
              // Si nouveau contenu on permet de retirer le groupe des destinataires
              //else destinataire[d].allowRemoveFromDestinataires = true;

              if(_this.idContenu) _this.newCollaborateurs.push(destinataire[d]);
              else _this.selectedList.push(destinataire[d]);

            }
          }
        }
      }
      else if(angular.isObject(destinataire)) {
        if(!destinataire.isMail) {
          if(destinataire.hasOwnProperty('libelle')) destinataire.libelle = UtilsService.stripTags(destinataire.libelle);
          if(destinataire.hasOwnProperty('dieze')) destinataire.dieze = UtilsService.stripTags(destinataire.dieze);
          if(destinataire.hasOwnProperty('defaultLibelle')) destinataire.defaultLibelle = UtilsService.stripTags(destinataire.defaultLibelle);
          destinataire = new Groupe(destinataire);
        }
        // Si pas nouveau contenu, on permet également de retirer les groupes grace à l'attribut "newInCurrentDestinataires" ajouté lors de l'ajout du groupe dans la liste currentDestinataires
        //if(_this.idContenu) destinataire.newInCurrentDestinataires = true;
        // Si nouveau contenu on permet de retirer le groupe des destinataires
        //else destinataire.allowRemoveFromDestinataires = true;

        if(_this.idContenu) {
          if(!_this.newCollaborateurs.length) _this.newCollaborateurs.push(destinataire);
          else if(!collaborateurExistInSelectList(destinataire)) _this.newCollaborateurs.push(destinataire);
        }
        else {
          // Si liste des destinataire vid eon l'ajoute direct
          if(!_this.selectedList.length) _this.selectedList.push(destinataire);
          // Sinon on test si il est pas déjà dedans
          else if(!collaborateurExistInSelectList(destinataire)) _this.selectedList.push(destinataire);
        }
      }
      emitCollaborateur();
    }*/


    /**
     * Permet de vérifier si le nouveau collaborateur n'est pas déjà dans la selectedList
     * @param destinataire
     * @returns {boolean}
     */
    /*
    function collaborateurExistInSelectList(destinataire){
      var exist = false;
      for(var i = 0; i < _this.selectedList.length; i++){
        if(isSameGroupe(_this.selectedList[i], destinataire)) {
          exist = true;
          break;
        }
      }
      if(!exist && _this.idContenu && _this.newCollaborateurs.length){
        for(var j = 0; j < _this.newCollaborateurs.length; j++){
          if(isSameGroupe(_this.newCollaborateurs[j], destinataire)) {
            exist = true;
            break;
          }
        }
      }
      return exist;
    }*/

    /**
     * Permet de retirer un collaborateur de la selectedList
     * @param groupe
     */
    /*
    function removeCollaborateur(groupe){
      if(_this.selectedList.length) {
        for(var i = 0; i < _this.selectedList.length; i++){
          if(isSameGroupe(_this.selectedList[i], groupe)) _this.selectedList.splice(i, 1);
        }
      }
      if(_this.idContenu && _this.newCollaborateurs.length) {
        for(var j = 0; j < _this.newCollaborateurs.length; j++){
          if(isSameGroupe(_this.newCollaborateurs[j], groupe)) _this.newCollaborateurs.splice(j, 1);
        }
      }
      emitCollaborateur();
    }*/

    /**
     * Test si le groupeA et le meme que le groupeB
     * @param groupeA
     * @param groupeB
     * @returns {boolean}
     */
    /*
    function isSameGroupe(groupeA, groupeB) {

      var groupeACopy = angular.copy(groupeA);
      var groupeBCopy = angular.copy(groupeB);
      if(groupeACopy.hasOwnProperty('libelle')) groupeACopy.libelle = UtilsService.stripTags(groupeACopy.libelle);
      if(groupeBCopy.hasOwnProperty('libelle')) groupeBCopy.libelle = UtilsService.stripTags(groupeBCopy.libelle);

      if(groupeACopy.hasOwnProperty('dieze')) groupeACopy.dieze = UtilsService.stripTags(groupeACopy.dieze);
      if(groupeBCopy.hasOwnProperty('dieze')) groupeBCopy.dieze = UtilsService.stripTags(groupeBCopy.dieze);

      if(groupeACopy.hasOwnProperty('defaultLibelle')) groupeACopy.defaultLibelle = UtilsService.stripTags(groupeACopy.defaultLibelle);
      if(groupeBCopy.hasOwnProperty('defaultLibelle')) groupeBCopy.defaultLibelle = UtilsService.stripTags(groupeBCopy.defaultLibelle);

      if((groupeACopy.hasOwnProperty('idGroupe') && groupeACopy.idGroupe === groupeBCopy.idGroupe) ||
        (angular.isString(groupeACopy.libelle) && angular.isString(groupeBCopy.libelle) && angular.isString(groupeACopy.dieze) && angular.isString(groupeBCopy.dieze) && groupeACopy.libelle.toLowerCase() === groupeBCopy.libelle.toLowerCase() && groupeACopy.dieze.toLowerCase() === groupeBCopy.dieze.toLowerCase()) ||
        (groupeACopy.isMail && angular.isString(groupeACopy.libelle) && groupeBCopy.isMail && angular.isString(groupeBCopy.libelle) && groupeACopy.libelle.toLowerCase() === groupeBCopy.libelle.toLowerCase())) {
        return true;
      }
      return false;
    }*/

    /**
     * Permet de retirer de la liste des destinataires tous les groupes enfant du groupe en paramètre
     * @param groupe
     *
     function removeGroupesFilsFromDestinataire(groupe){
      if(_this.selectedList.length) {
        var nbFils = groupe.getGroupesFils().length;
        if(nbFils) {
          for(var i = 0; i < nbFils; i++){
            removeCollaborateur(groupe.getGroupesFils()[i]);
            removeGroupesFilsFromDestinataire(groupe.getGroupesFils()[i]);
          }
        }
      }
    }*/

    /**
     * Permet de checker un groupe et ses enfants
     * @param groupe
     *
     function checkGroupeAndFils(groupe){
      groupe.checked = true;
      var nbFils = groupe.getGroupesFils().length;
      if(nbFils) {
        for(var i = 0; i < nbFils; i++){
          var fils = groupe.getGroupesFils()[i];
          fils.checked = true;
          checkGroupeAndFils(fils);
        }
      }
    }*/

    /**
     * Lorsqu'un fils du groupeAll est coché ou décoché
     * @param groupe
     */
    /*
    function onCheckGroupe(groupe){
      if(!groupe) groupe = _this.groupeAllFiltered;
      var nbFils = groupe.getGroupesFils().length;
      if(nbFils) {
        for(var i = 0; i < nbFils; i++){
          var fils = groupe.getGroupesFils()[i];

          if(fils.checked) {
            addCollaborateur(fils);                    // Ajoute ce groupe en destinataire
            removeGroupesFilsFromDestinataire(fils); // Retire les enfants de ce groupe des destinataires car ils sont inutiles si le parent y est déjà
          }
          else if(!fils.checked) {
            removeCollaborateur(fils); // Retire le groupe des destinataires
            onCheckGroupe(fils);      // Recommence la fonction pour les éventuels enfants de ce groupe
          }
        }
      }
      if(groupe.isAll()) _this.onChangeCollaborateur({destinataires: _this.selectedList});
    }*/

    /**
     * Lorsqu'un groupe est coché/décoché
     * @param groupe
     */
    function onCheckGroupe(groupe){
      if(groupe.checked) addCollaborateur(groupe);             // Ajoute ce groupe en destinataire
      else if(!groupe.checked) removeCollaborateur(groupe);    // Retire le groupe des destinataires
      emitCollaborateur();
    }

    /**
     * Permet de Cocher/Décocher tous les groupe user d'un coup
     */
    function onCheckAllGroupeUser(){
      var checked = _this.allGroupeUserChecked;
      if(_this.listGroupeUserFiltered.length) {
        for(var i = 0; i < _this.listGroupeUserFiltered.length; i++){
          if(!_this.listGroupeUserFiltered.disabledChecked) {
            _this.listGroupeUserFiltered[i].checked = checked;
            if(checked) addCollaborateur(_this.listGroupeUserFiltered[i]);
            else removeCollaborateur(_this.listGroupeUserFiltered[i]);
          }
        }
        emitCollaborateur();
      }
    }

    function emitCollaborateur(forceEmit){
      if(!_this.modalInstance || forceEmit) {
        emit({
          collaborateurs: _this.selectedList,
          newCollaborateurs: _this.newCollaborateurs
        });
      }

      /*
      _this.onChangeCollaborateur({
        obj: {
          collaborateurs: _this.selectedList,
          newCollaborateurs: _this.newCollaborateurs
        }
      });*/
    }

    /**
     * Si le composant est ouvert dans une modal et que l'on clique sur le bouton pour fermer la modal
     */
    function onClickClose(){
      close();
    }

    /**
     * Si le composant est ouvert dans une modal et que l'on clique sur le bouton pour sauvegarder
     */
    function onClickSave(){
      emitCollaborateur(true);
    }

    /**
     * Si ouvert en mode Modal
     */
    function close(){
      if(_this.modalInstance) _this.dismiss({$value: 'cancel'});
      else emit({action: 'closeChooseCollaborateur'});
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      if(_this.modalInstance) _this.close({$value: objRetour});
      else {
        _this.onChangeCollaborateur({
          obj: objRetour
        });
      }
    }
  }
})();
