(function() {

    'use strict';

    /**
     * @desc Composant pour afficher une ligne de categorie topic dans la gestion des categorie topic
     * @example <admin-ligne-categorie-topic></admin-ligne-categorie-topic>
     */
    angular
        .module('collaboreApp')
        .component('adminLigneCategorieTopic',{
            require: {
                adminGestionCategoriesTopicsCtrl: '^adminGestionCategoriesTopics'
            },
            bindings: {
                categorieTopic: '<',
                onEmit: '&'
            },
            templateUrl: 'app/parametrage/admin/admin-gestion-categories-topics/admin-ligne-categorie-topic/admin-ligne-categorie-topic.component.html',
            controllerAs: 'adminlignecategorietopicctrl',
            controller: AdminLigneCategorieTopicCtrl
        });

    /** @ngInject */
    function AdminLigneCategorieTopicCtrl(AdminTopicService, ModalsService){
        var _this = this;

        _this.onClickEditCategorieTopic = onClickEditCategorieTopic;
        _this.onClickDeleteCategorieTopic = onClickDeleteCategorieTopic;
        _this.onEmitFromLigneCategorieTopicEnfant = onEmitFromLigneCategorieTopicEnfant;

        _this.$onInit = function(){
            init();
        };

        function init(groupe){
        }

        /**
         * Lors du clique sur le bouton pour ajouter une nouvelle categorie Topic
         */
        function onClickEditCategorieTopic(){
            emit({
                action: 'editCategorieTopic',
                categorieTopic: _this.categorieTopic
            });
        }

        /**
         * Lors du clique sur le bouton pour supprimer une categorie de topic
         */
        function onClickDeleteCategorieTopic(){
            deleteCategorieTopic();
        }

        /**
         * Sauvegarde de la catégorie Topic
         */
        function deleteCategorieTopic(){

            var titre = 'Supprimer catégorie de topic';
            var desc = 'Voulez-vous vraiment supprimer la catégorie de topic:<br /><br />' +
                '<strong style="color:'+_this.categorieTopic.getTextColor()+'">'+_this.categorieTopic.getLibelle()+'</strong>' +
                '<br /><br />' +
                '<span style="color: #FF0000">Attention toutes les catégories enfants seront également supprimées et tous les topics utilisant ces catégories, n\'en n\auront plus !</span>';

            ModalsService
                .confirm(titre, desc)
                .then(function (modal) {
                    emit({action: 'showLoadingList'});
                    _this.loadingList = true;
                    AdminTopicService
                        .deleteCategorieTopic(_this.categorieTopic.getIdCategorieTopic())
                        .then(function onSuccess(){
                            emit({action: 'refreshList'});
                            modal.close();
                        })
                        .catch(function onError(msg){
                            ModalsService.alertErreur(msg);
                        })
                        .finally(function onFinally(){
                            emit({action: 'hideLoadingList'});
                        });
                });

        }

        function onEmitFromLigneCategorieTopicEnfant(obj){
            emit(obj);
        }

        /**
         * Permet de remonter une infos au composant parent
         * @param obj
         */
        function emit(obj){
            _this.onEmit({
                obj: obj
            });
        }

    }
})();
