(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name collaboreApp.ContenuSinistre
     * @description
     * # ContenuEvenement
     * Service in the collaboreApp.
     */

    angular.module('collaboreApp').factory('ContenuSinistre', ContenuSinistreModel);

    /** @ngInject */
    function ContenuSinistreModel(COLLAB_CONF, $injector, $q, Contenu, InfosSinistre) {

        var ContenuSinistre = function() {

            var scope = this;

            Contenu.apply(scope, arguments);

            var attributMinimum = [
                {attribut:'infosSinistre',defaultValue: new InfosSinistre()}
            ];

            this.setAttributesRequired(scope, attributMinimum);

            this.typeContenu = COLLAB_CONF.TYPE_CONTENU_SINISTRE;
        };

        ContenuSinistre.prototype = {

            model: 'ContenuSinistre',
            isSinistre: true,
            isEvenement: false,
            isDevis: false,
            isIntervention: false,
            isFacture: false,

            getInfosSinistre: function(){
                return this.infosSinistre;
            },
            setInfosSinistre: function(val){
                if(angular.isObject(val)) {
                    if(!val.isModel) this.infosSinistre = new InfosSinistre(val);
                    else this.infosSinistre = val;
                }
                else this.infosSinistre = new InfosSinistre();
            },

            /**
             * Permet de modifier un sinistre
             * @returns {Promise}
             */
            updateSinistre: function(objetSinistre){

                var _this = this;
                var deferred = $q.defer();

                var SinistreService = $injector.get('SinistreService');
                SinistreService.updateFieldByField(objetSinistre)
                    .then(function(sinistre){
                        _this.setData(sinistre);
                        deferred.resolve(_this);
                    })
                    .catch(function(msg){
                        deferred.reject(msg);
                    });

                return deferred.promise;
            },

            generateDeclarationSinistre: function(){
                var _this = this;
                var deferred = $q.defer();

                var SinistreService = $injector.get('SinistreService');
                SinistreService.genereDeclarationSinistre(_this.getIdContenu())
                    .then(function(sinistre){
                        _this.setData(sinistre);
                        deferred.resolve(_this);
                    })
                    .catch(function(msg){
                        deferred.reject(msg);
                    });
                return deferred.promise;
            }

        };

        // Copie le prototype de Contenu dans le prototype de ContenuSinistre
        // ContenuSinistre hérite donc des methodes de Contenu
        angular.extend(ContenuSinistre.prototype, Contenu.prototype);

        return ContenuSinistre;
    }
})();