(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .factory('TopicResource', TopicResource);

  /** @ngInject */
  function TopicResource(COLLAB_CONF, COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_TOPIC, null, {
      post: {
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_TOPIC,
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      },
      get: {
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_TOPIC,
        params: {
          topic: '@topic',
          action: 'searchDetailTopic'
        }
      },
      /*
      getWithLastContenu: {
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_CONTENUS,
        params: {
          topic: '@topic',
          type: 1
        }
      },*/
      getTopicWithFactureNoSentToComfact: {
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_TOPIC,
        params: {
          page: '@page',
          idFacture: '@idFacture',
          'listIdGroupeExpediteur[]': '@listIdGroupeExpediteur',
          'listIdGroupeDestinataire[]': '@listIdGroupeDestinataire',
          idChannel: '@idChannel',
          topicId: '@topicId',
          action: 'searchTopicsFacturesNoSentToComfact'
        }
      },
      lire: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_LECTURE_CONTENU,
        params: {
          'topic': '@topic'
        }
      },
      list: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_TOPIC,

        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        /*
         params: {
         classe:'@classe',
         cloturer: '@cloturer',
         urgent: '@urgent',
         etat: '@etat',
         idTagType: '@idTagType',
         'tags[]':'@tags',
         //'libelleTypeTags[]':'@libelleTypeTags',
         page: '@page',
         limit: '@limit',
         nonlu: '@nonlu',
         idChannel: '@idChannel'
         }*/
      },
      getEvenement: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_EVENEMENT_BY_TOPICS,
        params: {
          topic: '@topic',
          getAllInfos: '@getAllInfos'
        }
      },
      addDestinataires: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_DESTINATAIRE_TOPIC,
        params: {
          'topic[]': '@topic',
          'destinataires[]': '@destinataires'
        }
      },
      deleteTag: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DELETE_TAGS_IN_TOPIC,
        params: {
          topic: '@topic',  // idContenu
          tag: '@tag'     // #exemple
        }
      },
      /*
      saveOld: {
        method: 'POST',

        //headers: {'Content-Type': 'multipart/mixed;boundary=gc0p4Jq0M2Yt08jU534c0p;charset=ISO-8859-1'},
        headers: {'Content-Type': undefined},
        transformRequest: angular.identity,
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_TOPIC,
        params: {
          titre: '@titre',
          message: '@message',
          'destinataires[]': '@destinataires',
          'tags[]': '@tags',
          'guids[]': '@guids'
           //fileName: '@fileName'
        }
      },*/
      /**
       * titre: '@titre',
       * objJSON:'@objJSON'
       */
      save: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_TOPIC
      },
      updateState: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_UPDATE_TOPIC_STATE,
        params: {
          topicState: '@idTopicState',
          idNom: '@idNom'
        }
      },

      updateTitre: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_UPDATE_TITRE_TOPIC
      }
    });
  }
})();
