(function() {

    'use strict';

    /**
     * @desc Composant recapMail
     * @example <popup-infos-devis></popup-infos-devis>
     */
    angular
        .module('collaboreApp')
        .directive('popupInfosDevis', PopupInfosDevisCtrl);

    /** @ngInject */
    function PopupInfosDevisCtrl(){
        return {
            restrict: 'AE',
            replace: true,
            templateUrl: 'app/topics/directives/popup-infos-devis.directive.html',
            scope: {
                devis: '=',
                shortLibelle: '<',
                noLibelle: '<',
                noLabelBordered: '<'
            }
        };
    }
})();
