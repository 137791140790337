(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .factory('GroupeResource', GroupeResource);

  /** @ngInject */
  function GroupeResource(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_GROUPE, null, {

      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      },

      /**
       * Permet de recupérer la hiérarchie de mon agence sans le portefeuille (OLD)
       */
      getHierarchieAgenceWithoutPortefeuille: {
        method: 'GET',
        params: {
          action: 'getHierarchieAgenceWithoutPortefeuille'
        }
      },

      /**
       * Permet de recupérer la liste des groupes collaborateurs user qui sont expediteur d'un contenu que je peux voir
       */
      searchCollaborateursSender: {
        method: 'GET',
        params: {
          action: 'searchCollaborateursSender',
        }
      },

      /**
       * Permet de recupérer la liste des groupes user sans les perso ics
       */
      searchCollaborateurs: {
        method: 'GET',
        params: {
          action: 'searchCollaborateurs',
          idPortefeuille: '@idPortefeuille',
          search: '@search',
          offset: '@offset',
          page: '@page',
          limit: '@limit',
          order: '@order',
          sens: '@sens',
        }
      },

      /**
       * Permet de recupérer la liste des groupes non user avec des groupe user dedans
       */
      searchGroupeOfCollaborateurs: {
        method: 'GET',
        params: {
          action: 'searchGroupeOfCollaborateurs',
          idPortefeuille: '@idPortefeuille',
          withGroupesFils: '@withGroupesFils',
          search: '@search',
          offset: '@offset',
          page: '@page',
          limit: '@limit',
          order: '@order',
          sens: '@sens',
        }
      },



      /**
       * Permet de recupérer la liste des groupes parent
       */
      searchListGroupeParent: {
        method: 'GET',
        params: {
          action: 'searchListGroupeParent',
          idGroupeSearch: '@idGroupeSearch',
          idPortefeuille: '@idPortefeuille',
          search: '@search',
          offset: '@offset',
          limit: '@limit',
          order: '@order',
          sens: '@sens',
        }
      },

      /**
       * Permet de recupérer deux liste de groupe, une user et une non user
       */
      getGroupesAgenceWithoutPortefeuille: {
        method: 'GET',
        params: {
          action: 'getGroupesAgenceWithoutPortefeuille'
        }
      },

      searchGroupeByLibelleAndPortefeuilleInMySociete: {
        method: 'POST',
        params: {
          action: 'searchGroupeByLibelleAndPortefeuilleInMySociete',
          idPortefeuille: '@idPortefeuille',
          rechercheLibre: '@rechercheLibre',
          rechercheIdContenu: '@rechercheIdContenu'
        }
      },

      // Old utiliser "searchListGroupeByGroupePortefeuille"
      searchListGroupeImmeubleByGroupePortefeuille: {
        method: 'POST',
        params: {
          action: 'searchListGroupeImmeubleByGroupePortefeuille',
          withAllCollaborateur: '@withAllCollaborateur',
          idPortefeuille: '@idPortefeuille',
          search: '@search',
          offset: '@offset',
          limit: '@limit',
          order: '@order',
          sens: '@sens',
          withGroupeAssociation: '@withGroupeAssociation',
          onlyWithGroupeAssociation: '@onlyWithGroupeAssociation'
        }
      },

      searchListGroupeForTypeByGroupePortefeuille: {
        method: 'POST',
        params: {
          action: 'searchListGroupeForTypeByGroupePortefeuille',
          typeGroupe: '@typeGroupe', // immeuble, coproprietaire, proprietaire,
          withAllCollaborateur: '@withAllCollaborateur',
          idPortefeuille: '@idPortefeuille',
          search: '@search',
          searchNumeroImmeuble: '@searchNumeroImmeuble', // Pour limiter sur un immeuble lors d'une recherche de perso
          offset: '@offset',
          limit: '@limit',
          order: '@order',
          sens: '@sens',
          withGroupeAssociation: '@withGroupeAssociation',
          onlyWithGroupeAssociation: '@onlyWithGroupeAssociation'
        }
      },

      searchGroupe: {
        method: 'POST',
        params: {
          action: 'searchGroupe',
          typeGroupe: '@typeGroupe', // immeuble, coproprietaire, proprietaire,
          withAllCollaborateur: '@withAllCollaborateur',
          idPortefeuille: '@idPortefeuille',
          search: '@search',
          withGroupeAssociation: '@withGroupeAssociation',
        }
      }
    });
  }
})();
