(function () {
    'use strict';

    /**
     * @desc Permet d'accepter ou refuser une demande
     * @example <accept-refus-demande></accept-refus-demande>
     */

    angular
        .module('collaboreApp')
        .component('acceptRefusDemande', {
            templateUrl: 'app/topics/components/accept-refus-demande/accept-refus-demande.component.html',
            bindings: {
                type: '@',              // "accepter" ou "refuser"
                contenu: '<',           // Contenu de la demande
                document: '<',          // Document à accepter
                onEmit: '&',

                // Si Modal
                resolve: '<',
                close: '&',
                dismiss: '&',
                modalInstance: '<'
            },
            controllerAs: 'acceptrefusdemandectrl',
            controller: AcceptRefusDemandeController
        });

    /*@ngInject*/
    function AcceptRefusDemandeController($injector, ModalsService, UtilsService) {

        var _this = this;
        _this.listeDestinataires = [];
        _this.onClickCancel = onClickCancel;
        _this.onClickConfirm = onClickConfirm;
        _this.onChangeDestinataires = onChangeDestinataires;

        _this.$onInit = function() {
            if(_this.resolve && _this.modalInstance) {
                _this.type = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'type'));
                _this.contenu = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'contenu'));
                _this.document = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'document'));
            }

            if(!_this.contenu) ModalsService.alertErreur('Pas de demande');
        };

        _this.$onChanges = function(changes) {
            if(_.isObject(changes)) {
                if(_.isObject(changes.contenu) && !changes.contenu.isFirstChange()) {
                    _this.contenu = changes.contenu.currentValue;
                }
                if(_.isObject(changes.document) && !changes.document.isFirstChange()) {
                    _this.document = changes.document.currentValue;
                }
            }
        };

        _this.$onDestroy = function(){
        };

        /**
         * Lors du changement dans les destinataires
         * @param destinataires
         */
        function onChangeDestinataires(destinataires) {
            _this.listeDestinataires = destinataires;
        }

        /**
         * Lors du clique sur le bouton pour annuler
         */
        function onClickCancel(){
            cancel();
        }

        /**
         * Lors du clique sur le bouton de confirmation
         */
        function onClickConfirm(){
            confirm();
        }

        /**
         * Permet de confirmer
         */
        function confirm(){
            try {

                _this.loading= true;

                if(_this.type === "accepter") {
                    _this.loading= true;
                    var listeDestinataires = [];
                    if(_.isArray(_this.listeDestinataires) && _this.listeDestinataires.length) {
                        var ProxyDestinataire = $injector.get('ProxyDestinataire');
                        for(var d = 0; d < _this.listeDestinataires.length; d++) {
                            listeDestinataires.push(new ProxyDestinataire(_this.listeDestinataires[d]));
                        }
                        ProxyDestinataire = null;
                    }

                    if(_this.contenu.isContenuDevis()) {

                        _this.contenu
                            .accepterDevisBySyndic(_this.contenu.sendMailWhenAcceptOrDecline)
                            .then(function () {
                                emit({action: 'accepteDemande'});
                            })
                            .finally(function(){
                                _this.loading = false;
                            });
                    }
                    else if(_this.contenu.isContenuContrat()) {
                        _this.contenu
                            .accepterContratBySyndic(_this.contenu.sendMailWhenAcceptOrDecline, _this.document, listeDestinataires)
                            .then(function () {
                                emit({action: 'accepteDemande', document: _this.document});
                            })
                            .finally(function(){
                                _this.loading = false;
                            });
                    }
                }
                else if(_this.type === "refuser"){
                    _this.loading= true;
                    if(_this.contenu.isContenuDevis()) {
                        _this.contenu
                            .refuserDevisBySyndic(_this.contenu.sendMailWhenAcceptOrDecline)
                            .then(function () {
                                emit({action: 'refuseDemande'});
                            })
                            .finally(function(){
                                _this.loading = false;
                            });
                    }
                    else if(_this.contenu.isContenuContrat()) {
                        _this.contenu
                            .refuserContratBySyndic(_this.contenu.sendMailWhenAcceptOrDecline, _this.document)
                            .then(function () {
                                emit({action: 'refuseDemande', document: _this.document});
                            })
                            .finally(function(){
                                _this.loading = false;
                            });
                    }
                }
            }
            catch(e) {
                ModalsService.alertErreur(e.message);
                _this.loading = false;
            }
        }

        /**
         * Permet d'emit vers composant parent
         * @param objRetour
         */
        function emit(objRetour){
            if(!_.isObject(objRetour)){
                objRetour = {};
            }
            if(_.isObject(objRetour) && !_.isArray(objRetour)) {
                objRetour.contenu = _this.contenu;
            }
            if(_this.modalInstance) _this.close({$value: objRetour});
            else _this.onEmit({obj: objRetour});
        }

        /**
         * Si ouvert en mode Modal
         */
        function cancel(){
            if(_this.modalInstance) _this.dismiss({$value: 'cancel'});
            else emit({action: 'close', contenu: _this.contenuParent});
        }

        /**
         * Lors du choix d'une signature
         * @param obj
         */
        /*
        function onEmitSelectSignatureContenu(obj){
            try {
                if (!obj) throw new Error('Pas d\'obj !');
                if (_.isObject(obj) && obj.hasOwnProperty('action') && obj.hasOwnProperty('signature')) {
                    if(obj.action === 'changeSignature') _this.signatureSelected = obj.signature;
                }
            }
            catch (err) {
                ModalsService.alertErreur(err.message);
            }
        }*/
    }
})();
