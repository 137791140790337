'use strict';

/**
 * @ngdoc service
 * @name collaboreApp.InformationsServices
 * @description
 * # InformationsServices
 * Service in the collaboreApp.
 */
angular.module('collaboreApp').service('InformationsServices', ['COLLAB_CONF','COLLAB_VALUES','$q','$rootScope','$timeout','State','TopicStatesService','$http', function(COLLAB_CONF,COLLAB_VALUES,$q,$rootScope,$timeout,State,TopicStatesService,$http) {

  var scope = this;
  var requestForInformations = null;
  var checkInfos;
  scope.informations = false;

  scope.ajaxInformationsPending = false;

  $rootScope.nbTotalTopic = 0;
  $rootScope.nbTotalContenu = 0;

  $rootScope.nbTopicNormal = 0;
  $rootScope.nbTopicCache = 0;
  $rootScope.nbTopicCoupCoeur = 0;
  $rootScope.nbTopicArchive = 0;

  //$rootScope.nbMessageNonLu = 0;
  //$rootScope.nbMessageNonLuEvenement = 0;
  //$rootScope.nbMessageNonLuMessage = 0;

  $rootScope.nbTopicArchiveEtCache = 0;
  $rootScope.nbTopicNormalEtCoupCoeur = 0;

  scope.getInformations = function(refresh){

    var deferredAbort  = $q.defer();
    var promise;

    if(refresh || !scope.informations) {

      scope.ajaxInformationsPending = true;

      var request = $http({
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INFORMATIONS_COLLABORE,
        timeout: deferredAbort.promise
      });

      promise = request.then(function (response) {

        var json = response.data;

        if (json.nb === '1') {
          scope.informations = json.Informations[0];

          scope.initInformations(json.Informations[0]);
          return (json.Informations[0]);

        } else {
          return ($q.reject('Something went wrong'));
        }
      }, function () {
        return ($q.reject('Something went wrong'));
      });

      promise.abort = function() {
        deferredAbort.resolve();
      };

      promise.finally(
          function() {
            scope.ajaxInformationsPending = false;
            promise.abort = angular.noop;
            deferredAbort  = request = promise = null;
          }
      );

    }else{
      deferredAbort.resolve(scope.informations);
    }

    return(promise);
  };


  /*
  scope.getInformations = function(refresh){

    var deferred = $q.defer();

    if(refresh || !scope.informations) {

      promiseInfos = $http.get({
        method: 'get',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_INFORMATIONS_COLLABORE,
        timeout: deferred.promise
    }).success(function(json) {
        if (json.nb === '1') {
          scope.informations = json.Informations[0];

          scope.initInformations(json.Informations[0]);
          deferred.resolve(json.Informations[0]);

        } else {
          deferred.reject();
        }
      }).error(function(data, status, headers, config) {
        var msgErreur = 'Erreur (' + status + ') : ' + error.statusText;
        deferred.reject('Problème avec InformationsServices.getInformations(), ' + msgErreur);
      });
    }else{
      deferred.resolve(scope.informations);
    }

    return deferred.promise;
  };*/

  scope.getNbContenu = function(refresh){
    var deferred = $q.defer();

    scope.getInformations(refresh).then(function(informations){
      deferred.resolve(informations.nbContenu);
    },function(msg){
      deferred.reject(msg);
    });

    return deferred.promise;
  };


  scope.getNbTopic = function(refresh){
    var deferred = $q.defer();

    scope.getInformations(refresh).then(function(informations){
      deferred.resolve(informations.nbTopic);
    },function(msg){
      deferred.reject(msg);
    });

    return deferred.promise;
  };

  scope.getNbArchive = function(refresh){
    var deferred = $q.defer();

    scope.getInformations(refresh).then(function(informations){
      deferred.resolve(informations.nbArchive);
    },function(msg){
      deferred.reject(msg);
    });

    return deferred.promise;
  };

  scope.getNbCoupCoeur = function(refresh){
    var deferred = $q.defer();

    scope.getInformations(refresh).then(function(informations){
      deferred.resolve(informations.nbCoupCoeur);
    },function(msg){
      deferred.reject(msg);
    });

    return deferred.promise;
  };

  /**
   * Initialisation des informations qui proviennent  des appel ajax
   * @param data
   */
  scope.initInformations = function(data){

    $rootScope.nbTotalTopic = data.nbTopic;
    $rootScope.nbTotalContenu = data.nbContenu;

    //$rootScope.nbMessageNonLu = data.nbMessageNonLu;
    //$rootScope.nbMessageNonLuEvenement = data.nbMessageNonLuEvenement;
    //$rootScope.nbMessageNonLuMessage = data.nbMessageNonLuMessage;
/*
    if(angular.isArray(data.mapTopic)){
      angular.forEach(data.mapTopic,function(objMapTopic){
        var state = new State(objMapTopic[0]);
        var val = objMapTopic[1];

        if(state.isNormal()){
          $rootScope.nbTopicNormal = val;
        }

        if(state.isCoupCoeur()){
          $rootScope.nbTopicCoupCoeur = val;
        }

        if(state.isArchive()) {
          $rootScope.nbTopicArchive = val;
        }

        if(state.isCache()){
          $rootScope.nbTopicCache = val;
        }

      });
    }
    */
    if(data.hasOwnProperty('nbTopicNormal')){
      $rootScope.nbTopicNormal = data.nbTopicNormal;
    }
    if(data.hasOwnProperty('nbTopicCoupCoeur')){
      $rootScope.nbTopicCoupCoeur = data.nbTopicCoupCoeur;
    }
    if(data.hasOwnProperty('nbTopicArchive')){
      $rootScope.nbTopicArchive = data.nbTopicArchive;
    }
    if(data.hasOwnProperty('nbTopicCache')){
      $rootScope.nbTopicCache = data.nbTopicCache;
    }

    $rootScope.nbTopicNormalEtCoupCoeur = $rootScope.nbTopicNormal+$rootScope.nbTopicCoupCoeur;

    $rootScope.nbTopicArchiveEtCache = $rootScope.nbTopicCache+$rootScope.nbTopicArchive;

  };


  /*
  scope.updateTopicNonLu = function(topic){

    var topicState = false;
    TopicStatesService.getListTopicStates().then(function(topicStates){
      if(topicStates.getTopic().getIdTopic()===topic.getIdTopic()){
        topicState = topicStates;
      }
    });

    if(topicState){
      var state = topicState.getState();

      if(state.isNormal()){
        if($rootScope.nbTopicNormal){
          $rootScope.nbTopicNormal--;
        }
      }

      if(state.isCoupCoeur()){
        if($rootScope.nbTopicCoupCoeur){
          $rootScope.nbTopicCoupCoeur--;
        }
      }

      if(state.isArchive() || state.isCache()){
        if(state.isArchive()){
          if($rootScope.nbTopicArchive){
            $rootScope.nbTopicArchive--;
          }
        }

        if(state.isCache()){
          if($rootScope.nbTopicCache){
            $rootScope.nbTopicCache--;
          }
        }
        $rootScope.nbTopicArchiveEtCache = $rootScope.nbTopicCache+$rootScope.nbTopicArchive;
      }
    }
  };*/

  /**
   * Initialisation des informations qui proviennent des websockets
   * @param data
   */
  scope.initInformationsWebsocket = function(data){

    $rootScope.nbTotalTopic = data.nbTopic;
    $rootScope.nbTotalContenu = data.nbContenu;

    $rootScope.nbTopicNormal = data.mapTopic['State [idState=1, type=1, nom=Normal, idNom=5]'];
    $rootScope.nbTopicCache = data.mapTopic['State [idState=3, type=1, nom=Caché, idNom=3]'];
    $rootScope.nbTopicCoupCoeur = data.mapTopic['State [idState=5, type=1, nom=Coup de coeur, idNom=1]'];
    $rootScope.nbTopicArchive = data.mapTopic['State [idState=8, type=1, nom=Archivé, idNom=2]'];

    //$rootScope.nbMessageNonLu = data.nbMessageNonLu;
    //$rootScope.nbMessageNonLuEvenement = data.nbMessageNonLuEvenement;
    //$rootScope.nbMessageNonLuMessage = data.nbMessageNonLuMessage;


    $rootScope.nbTopicNormalEtCoupCoeur = $rootScope.nbTopicNormal+$rootScope.nbTopicCoupCoeur;
    $rootScope.nbTopicArchiveEtCache = $rootScope.nbTopicCache+$rootScope.nbTopicArchive;


    scope.informations = data;

  };

  scope.abortInformations = function() {
    return( requestForInformations && requestForInformations.abort() );
  };

  scope.goCheckInformations = function(){

    if($rootScope.token){
      if(COLLAB_CONF.ENABLE_CHECK_INFORMATIONS && !scope.ajaxInformationsPending){

        if(checkInfos){
          $timeout.cancel(checkInfos);
        }
        // Recup les infos, lors du retour de la servlet lance un timoute avant de rappeler la fonction
        (requestForInformations = scope.getInformations(true)).then(function(){
          checkInfos = $timeout(function(){
            scope.goCheckInformations();
          },(COLLAB_CONF.NB_SECONDES_CHECK_INFORMATIONS*1000));
          $rootScope._poolIntervalServlet.checkInfos = checkInfos;
        });


      }
    }
  };

  scope.destroy = function(){
    scope.informations = false;
    $rootScope.nbTotalTopic = 0;
    $rootScope.nbTotalContenu = 0;

    $rootScope.nbTopicNormal = 0;
    $rootScope.nbTopicCache = 0;
    $rootScope.nbTopicCoupCoeur = 0;
    $rootScope.nbTopicArchive = 0;

    //$rootScope.nbMessageNonLu = 0;
    //$rootScope.nbMessageNonLuEvenement = 0;
    //$rootScope.nbMessageNonLuMessage = 0;

    $rootScope.nbTopicArchiveEtCache = 0;
    $rootScope.nbTopicNormalEtCoupCoeur = 0;
  };

}]);
