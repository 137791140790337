(function () {
  'use strict';

  /**
   * @desc Filtre pour capfun
   */
  angular
    .module('collaboreApp')
    .component('filtreCapfun', {
      templateUrl:  'app/capfun/filtre-capfun/filtre-capfun.component.html',
      bindings: {
        inline: '<',
        intervenants: '<',
        status: '<',
        onInit: '&',
        onEmit: '&'
      },
      controllerAs: 'filtrecapfunctrl',
      controller:   FiltreCapfunController
    });

  /*@ngInject*/
  function FiltreCapfunController(COLLAB_CONF, $rootScope, $q, UserService, TagsService, PortefeuilleService, UtilsService) {

    var _this = this;

    var tagsStatut = {
      tagAttente: TagsService.genereTagTypeStatut(COLLAB_CONF.TAG_TYPE_STATUT_EN_ATTENTE, null, null),
      tagEnCours: TagsService.genereTagTypeStatut(COLLAB_CONF.TAG_TYPE_STATUT_EN_COURS, null, null),
      tagProbleme: TagsService.genereTagTypeStatut(COLLAB_CONF.TAG_TYPE_STATUT_PROBLEME, null, null),
      tagTermine: TagsService.genereTagTypeStatut(COLLAB_CONF.TAG_TYPE_STATUT_TERMINE, null, null),
    };

    /*
    var tagsPresence = {
      facultative: TagsService.genereTagTypePresence(COLLAB_CONF.TAG_TYPE_PRESENCE_FACULTATIVE, null, null),
      obligatoire: TagsService.genereTagTypePresence(COLLAB_CONF.TAG_TYPE_PRESENCE_OBLIGATOIRE, null, null),
    };*/

    _this.loadingListPortefeuille = false;

    _this.listPortefeuille = [];
    _this.filtrePortefeuille = {
      loading: false,
      list: [],
      selected: [],
      events: {
        //onItemSelect: function(property) {},
        //onItemDeselect: function(property) {},
        onSelectionChanged: function() {
          emit();
        }
      },
      settings: {
        extraSettings: {
          enableSearch: true,
          styleActive: true,
          smartButtonMaxItems: 10
        },
        translationTexts: {
          buttonDefaultText: "Liste des campings",
          checkAll: "Tous",
          uncheckAll: "Aucun",
          dynamicButtonTextSuffix: "Camping(s) coché(s)"
        }
      }
    };

    _this.filtreIntervenant = {
      loading: false,
      list: [
        {
          id: 'menage',
          label: 'Ménage'
        },
        {
          id: 'technique',
          label: 'Technique'
        }
      ],
      events: {
        onSelectionChanged: function() {
          emit();
        }
      },
      selected: [],
      settings: {
        extraSettings: {
          enableSearch: false,
          styleActive: true,
          smartButtonMaxItems: 2
        },
        translationTexts: {
          buttonDefaultText: "Liste des types",
          checkAll: "Tous",
          uncheckAll: "Aucun",
          dynamicButtonTextSuffix: "Type(s) coché(s)"
        }
      }
    };

    _this.filtreStatuts = [];
    _this.filtrePresence = null;

    //_this.filtrePresence = [];

    /*
    // Génére le tagType LotMulticamp
    if(_.isObject(obj.lotMulticamp)){
      retour.tagsType.push(genereTagTypeLotMulticamp(obj.lotMulticamp.getPlace_name(), obj.lotMulticamp.getPlace_id(), objPortefeuille));
      if(oldTag) retour.tagsType.push(genereTagTypeLotMulticamp(obj.lotMulticamp.getPlace_name(), obj.lotMulticamp.getPlace_id(), objPortefeuille, oldTag));

      // Génère tag typé "ClientCapfun"
      if(_.isObject(obj.clientCapfun)) retour.tagsType.push(genereTagTypeClientCapfun(obj.clientCapfun.nom, obj.clientCapfun.idPerson, objPortefeuille));

      // Génère tag typé "Type"
      retour.tagsType.push(genereTagTypeType(COLLAB_CONF.TAG_TYPE_TYPE_INTERVENTION, null, null));

      // Génère tag typé "Presence"
      retour.tagsType.push(genereTagTypePresence(COLLAB_CONF.TAG_TYPE_PRESENCE_FACULTATIVE, null, objPortefeuille));

      // Génère tag typé "Statut"
      retour.tagsType.push(genereTagTypeStatut(COLLAB_CONF.TAG_TYPE_STATUT_EN_ATTENTE, null, null));
    }

    // Génére le tagType LieuCommunCapfun
    if(_.isObject(obj.lieuCommunCapfun)){
      retour.tagsType.push(genereTagTypeLieuCommunCapfun(obj.lieuCommunCapfun.getLieu(), null, objPortefeuille));
      if(oldTag) retour.tagsType.push(genereTagTypeLieuCommunCapfun(obj.lieuCommunCapfun.getLieu(), null, objPortefeuille, oldTag));

      // Génère tag typé "Type"
      retour.tagsType.push(genereTagTypeType(COLLAB_CONF.TAG_TYPE_TYPE_INTERVENTION, null, null));

      // Génère tag typé "Statut"
      retour.tagsType.push(genereTagTypeStatut(COLLAB_CONF.TAG_TYPE_STATUT_EN_ATTENTE, null, null));
    }*/

    _this.onChangeStatut = onChangeStatut;
    _this.onChangePresence = onChangePresence;

    _this.$onInit = function(){

      initIntervenants();
      initStatus();
      _.forEach(tagsStatut, function(value, key) {
        _this.filtreStatuts.push({
          type: key,
          libelle: TagsService.getLibelle(value),
          classCss: TagsService.getClassBadge(value),
          checked: true
        });
      });

      /*
      _.forEach(tagsPresence, function(value, key) {
        _this.filtrePresence.push({
          type: key,
          libelle: TagsService.getLibelle(value),
          classCss: TagsService.getClassBadge(value),
          value: true
        });
      });*/

      _this.filtreIntervenant.selected = angular.copy([_this.filtreIntervenant.list[1]]);

      /*
      if($rootScope.simplifyViewForCamping){
        if(!$rootScope.isInGroupeTechnique || !$rootScope.isInGroupeMenage) {
          _this.filtreIntervenant.list = [];
          _this.filtreIntervenant.selected = [];
        }
      }*/

      callListPortefeuille().then(function(){
        _this.onInit({obj: getInfos()});
      });
    };

    _this.$onChanges = function(changes){
      if(_.isObject(changes)) {
        if (_.isObject(changes.intervenants) && !changes.intervenants.isFirstChange()) {
          initIntervenants();
        }
        if (_.isObject(changes.status) && !changes.status.isFirstChange()) {
          initStatus();
        }
      }
    }

    function initIntervenants(){
      if(_.isArray(_this.intervenants) && _this.intervenants.length){
        _this.intervenants = _this.intervenants.map(function(intervenant){
          return (_.isObject(intervenant)) ? intervenant.id : intervenant;
        });

        _this.filtreIntervenant.selected = _this.filtreIntervenant.list.reduce(function(selectedList, intervenant){
          if(_this.intervenants.indexOf(intervenant.id) !== -1) selectedList.push(intervenant);
          return selectedList;
        },[]);
      }
    }

    function initStatus(){
      if(_.isArray(_this.status) && _this.status.length){
        _this.filtreStatuts.map(function(tag){
          if(_.findIndex(_this.status, {typeTag: {libelle: tag.typeTag.libelle}}) === -1){
            tag.checked = false;
          }
        });
      }
    }

    function callListPortefeuille(){
      var deferred = $q.defer();
      _this.filtrePortefeuille.loading = true;
      _this.listPortefeuille = [];

      if($rootScope.isSiegeCapfunOrCapfunAdmin){
        PortefeuilleService
          .searchListAllPortefeuille()
          .then( function onSuccess(list){
            initListPortefeuille(list);
            _this.filtrePortefeuille.loading = false;
            deferred.resolve();
          })
          .catch(function onError(msg){
            _this.filtrePortefeuille.loading = false;
            deferred.reject(msg)
          });
      }
      else{
        UserService
          .getPortefeuilles()
          .then(function (portefeuilles) {
            initListPortefeuille(portefeuilles);
            _this.filtrePortefeuille.loading = false;
            deferred.resolve();
          })
          .catch(function onError(msg){
            _this.filtrePortefeuille.loading = false;
            deferred.reject(msg)
          });
      }

      return deferred.promise;
    }

    function initListPortefeuille(list){
      if(_.isArray(list)) {
        _this.listPortefeuille = list;

        _this.filtrePortefeuille.selected = [];

        _this.filtrePortefeuille.list = list.map(function(item){

          var data = {id: item.getIdPortefeuille(), label: item.getNom()};

          if(!$rootScope.simplifyViewForCamping || UtilsService.getCurrentUser().hasPortefeuille(item)) {
            _this.filtrePortefeuille.selected.push(data);
          }
          return data;
        });

        _this.filtrePortefeuille.list.sort(function(a,b){
          return a.label.localeCompare(b.label);
        });
      }
    }

    function onChangeStatut(){
      emit();
    }

    function onChangePresence(){
      emit();
    }

    function getInfos(){

      return {
        isReady: (!_this.filtrePortefeuille.loading && !_this.filtreIntervenant.loading),
        listStatutSelected: _this.filtreStatuts.reduce(function(list, statut){
          if(statut.checked) list.push(angular.copy(statut));
          return list;
        }, []),
        listPortefeuilleSelected: _this.filtrePortefeuille.selected.reduce(function(list, portefeuille){
          for(var p = 0; p < _this.listPortefeuille.length; p++){
            if(_this.listPortefeuille[p].idPortefeuille === portefeuille.id){
              list.push(_this.listPortefeuille[p]);
              break;
            }
          }
          return list;
        }, []),
        listIntervenantSelected: angular.copy(_this.filtreIntervenant.selected),
        presence: angular.copy(_this.filtrePresence)
      };
    }

    function emit(otherObj){
      var objEmit = getInfos();
      if(_.isObject(otherObj)) objEmit = _.merge(objEmit, otherObj);
      _this.onEmit({obj: objEmit});
    }
  }
})();
