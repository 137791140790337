(function () {
  'use strict';

  /**
   * @desc Dashboard Collab - Page de statistiques et d'informations utiles
   */

  angular
    .module('collaboreApp')
    .component('dashboard', {
      templateUrl:  'app/dashboard/dashboard.component.html',
      bindings: {
        search: '<'
      },
      controllerAs: 'dashboardctrl',
      controller:   DashboardController
    });

  /*@ngInject*/
  function DashboardController(COLLAB_CONF, $rootScope, $stateParams, $state, $scope, UtilsService, FiltreService) {

    var ctrl = this;
    //ctrl.namePage = '';
    ctrl.paramsFiltreByChannelAndPageCurrent = paramsFiltreByChannelAndPageCurrent;
    ctrl.onEmitDirectChildComponent = onEmitDirectChildComponent;

    ctrl.$onInit = function(){
      if(!$stateParams.search) genereNomChannel();
      //if(_.isObject($state.current) && _.isObject($state.current.data) && $state.current.data.namePage) ctrl.namePage = $state.current.data.namePage;
    };


    function decodeSearch(search) {
      var parsedWordArray = CryptoJS.enc.Base64.parse(search);
      var parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
      return angular.fromJson(parsedStr);
    }

    /**
     * Permet de retourner un objet qui est utilisé en paramètre du state de ui-router dans le ui-sref pour le retour à la liste des topic en gardant les filtres, channels etc....
     * @returns {{topicPage, idChannel, search}}
     */
    function paramsFiltreByChannelAndPageCurrent(){
      var obj = FiltreService.getObjFiltreByChannelAndPageCurrent();
      obj.nonlu = false;
      $rootScope.current.filtreTopics.others.nonLuEnable = false;
      return obj;
    }

    /**
     * Inialisation du channel
     * @param idChannel
     */
    /*
    function initChannel(idChannel) {
        var nomChannel = COLLAB_CONF.LIBELLE_CHANNEL_ALL;
        var channel = null;
        ctrl.currentIdChannel = null;

        if (!_.isNil(idChannel)) {
            UtilsService.getCurrentFiltreTopics().idChannel = idChannel; // Set l'idChannel dans le filtre
            ctrl.currentIdChannel = idChannel;
        }
        // Si il y a "search" dans l'url
        else if(_.isString($stateParams.search)) {
            // Recup l'objet de recherche par rapport au string de "search"
            var objFiltre = FiltreService.parseFiltreFromUrl($stateParams);
            // Si l'objet recup est bien un object avec idChannel dedans et différent de null ou undefined
            if(_.isObject(objFiltre) && !_.isNil(objFiltre.idChannel)) {
                // Recup l'idChannel de l'url
                ctrl.currentIdChannel =  objFiltre.idChannel;
            }
        }

        // Si pas de channel dans "search" alors on va chercher celui dans le filtre principal
        if(_.isNil(ctrl.currentIdChannel)) {
            ctrl.currentIdChannel = FiltreService.filtreIsChannel();
        }

        channel = UtilsService.getCurrentUser().getChannel(ctrl.currentIdChannel);
        if (!_.isNil(channel)) {
            nomChannel = channel.nom;
        }

        ctrl.nomChannel = nomChannel;
    }*/

    function genereNomChannel(idChannel) {
      if(_.isObject($state.current) && $state.current.name === "dashboard.reportingCamping") ctrl.nomChannel = null;
      else {
        var nomChannel = null;
        if (angular.isDefined(idChannel)) {
          UtilsService.getCurrentFiltreTopics().idChannel = idChannel; // Set l'idChannel dans le filtre
          ctrl.currentIdChannel = idChannel;
          var channel = UtilsService.getCurrentUser().getChannel(idChannel);
          if (channel) nomChannel = channel.nom;
        }
        // Si pas de channel dans "search" alors on va chercher celui dans le filtre principal
        if(!nomChannel) {
          nomChannel = COLLAB_CONF.LIBELLE_CHANNEL_ALL;
          ctrl.currentIdChannel = FiltreService.filtreIsChannel();
          var channel = UtilsService.getCurrentUser().getChannel(ctrl.currentIdChannel);
          if (channel) nomChannel = channel.nom;
        }
        if(idChannel === -1) UtilsService.getCurrentFiltreTopics().idChannel = 0;

        ctrl.nomChannel =  ' - ' + nomChannel;
      }

    }

    /**
     * Infos provenant dues composant enfant
     * @param obj
     */
    function onEmitDirectChildComponent(obj) {
      if(angular.isObject(obj)) {
        if(obj.hasOwnProperty('idChannel')) genereNomChannel(obj.idChannel);
      }
    }


  }
})();
