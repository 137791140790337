(function () {
  'use strict';

  /**
   * @desc Page de liste de topic
   * @example <main-collabore></main-collabore>
   */

  angular
    .module('collaboreApp')
    .component('layoutMainCollabore', {
      templateUrl: 'app/layout/layout-main-collabore.component.html',
      controllerAs: 'layoutmaincollaborectrl',
      controller: LayoutMainCollaboreController
    });

  /*@ngInject*/
  function LayoutMainCollaboreController(COLLAB_CONF, uuid, $timeout, $window, $document, $rootScope, $scope, $state, AnnuaireService, TagsService, UserService, bowser, ConfigService, UtilsService, FiltreService, TopicStatesService, WebsocketServices) {

    $rootScope.moment = moment;
    $rootScope.$state = $state;
    $rootScope.client = COLLAB_CONF.CLIENT;
    $rootScope.notMobile = UtilsService.notMobile;
    $rootScope.onMobile = UtilsService.onMobile;

    $rootScope.paramsCollab = {};

    $rootScope.isCitya = (COLLAB_CONF.CLIENT === 'citya');
    $rootScope.isAuthKerberos = COLLAB_CONF.AUTH_KERBEROS;
    $rootScope.isAuthOnelogin = COLLAB_CONF.AUTH_ONELOGIN;

    /*
    $rootScope.isCitya = function(){
      return (COLLAB_CONF.CLIENT === 'citya');
    };

    $rootScope.isAuthKerberos = function(){
      return COLLAB_CONF.AUTH_KERBEROS;
    };

    $rootScope.isAuthOnelogin = function(){
      return COLLAB_CONF.AUTH_ONELOGIN;
    };*/


    $rootScope.smsEnabled = true;
    /*
    if($rootScope.client === 'citya') {
      $rootScope.smsEnabled = false;
    }*/

    var ctrl = this;

    ctrl.logoCollab = null;
    ctrl.versionCollab = COLLAB_CONF.VERSION_COLLAB;
    ctrl.openAnnuaire = openAnnuaire;
    ctrl.onDragOver = onDragOver;
    // ctrl.notMobile = UtilsService.notMobile;

    //ctrl.paramsFiltreByChannelAndPageCurrent = paramsFiltreByChannelAndPageCurrent;
    //$rootScope.current.nonLuEnable = false;

    /*
     ctrl.configScrollbarSidebar = {
     autoHideScrollbar: false,
     theme: 'dark-thin',
     advanced:{
     updateOnContentResize: true
     },
     scrollButtons: {
     scrollAmount: 'auto', // scroll amount when button pressed
     enable: false // enable scrolling buttons by default
     },
     axis: 'y', // enable 2 axis scrollbars by default
     setHeight: 500,
     scrollInertia: 100
     };


     $scope.$on('onResizeBody',function(evt,height){
     ctrl.configScrollbarSidebar.setHeight = height;
     });*/

    ctrl.$onInit = function () {
      if (bowser.msie) {
        //console.log(bowser);
        var classIE = 'ie';

        switch (bowser.version) {
          case '9.0':
            classIE += ' ie9';
            break;
          case '10.0':
            classIE += ' ie10';
            break;
          case '11.0':
            classIE += ' ie11';
            break;
          default:
            classIE += '';
        }
        angular.element('html').addClass(classIE);
      }
    };

    // Lorsque le controller est detruit
    ctrl.$onDestroy = function () {
    };

    ctrl.user = UserService;

    function onDragOver(event) {
      maximizeLeftSidebar();
    }

    // Filtre perso actuellement actif
    //$rootScope.filtreTopic =
    //$scope.filtre = {};
    //$scope.filtre.filtrePersoActif = false;

    //console.log("Main");
    ctrl.classPage = function () {

      var varClass = '';

      //console.log("Main.classPage()");

      if ($state.includes('topics')) {
        varClass = 'page-topics';
      }

      return varClass;
    };


    //-------------- Notifications ---------------

    //-------------- Fin Notifications ---------------

    function openAnnuaire(infos, configAnnuaire) {

      var obj = {
        stringTo: 'tag',
        limitDestinataire: 1,
        defautTypeAnnuaire: 'perso'
      };

      if(configAnnuaire) obj = angular.merge(obj, configAnnuaire);

      if (infos) obj.searchParams = infos;

      //ModalsService.modalComponent('modalAnnuaire', obj, options).then(function(objRetour){


      AnnuaireService.openAnnuaire(obj).then(function (objRetour) {

        //console.log('objRetour TagsService', TagsService.genereTagsAndFiches(objRetour));
        //console.log('objRetour TagService', TagService.genereTagsForType(objRetour));

        //console.log('objRetour',objRetour);
        //var objetsTags = TagService.genereTagsForType(objRetour);
        var objetsTags = TagsService.genereTagsAndFiches(objRetour);
        //console.log('objetsTags',objetsTags);
        //var objetsTags = TagsService.genereTagsAndFichesForSearch(objRetour);

        //console.log('objetsTags',objetsTags);
        // var objetsTags = TagService.createTags(objRetour);
        filtreByTags(objetsTags, objRetour);
      });
    }

    function filtreByTags(objetsTags, objRetour) {
      if (objetsTags.hasOwnProperty('addTag')) {

        var listeTagsSearch = [];
        var callTopicList = false;
        $rootScope.current.filtreTopics.classe = '0';
        $rootScope.current.filtreTopics.tags = [];
        $rootScope.current.filtreTopics.listeFournisseurMail = [];
        $rootScope.current.filtreTopics.listeFournisseurPersoICS = [];

        if (objetsTags.addTag.hasOwnProperty('tagsType')) {

          var listeTagsTypes = [];
          // Cherche dans les tagType
          if($rootScope.camping) listeTagsTypes = TopicStatesService.getTagsTypeAllowForSearchTopicCapfun(objetsTags);
          else listeTagsTypes = TopicStatesService.getTagsTypeAllowForSearchTopic(objetsTags);

          if (listeTagsTypes.length) angular.merge(listeTagsSearch, listeTagsTypes);

          // Cherche dans le "tag" qui est normalement un tag origine si ICS le demande ou si pas encore de résultat dans les tag type
          if (UtilsService.isICS()) {
            var tagOrigin = TopicStatesService.getParamsSearchTopicByTagOrigin(objetsTags);
            if (tagOrigin) {
              listeTagsSearch.push(tagOrigin);
            }
          }

          // Si liste de tag trouvé alors recherche les topic avec
          if (listeTagsSearch.length) {
            //$rootScope.current.filtreTopics.tags = listeTagsSearch;
            callTopicList = true;
          }
        }

        // Si toujours pas de callTopicList à true passe par les fiches pour essayer de trouver des infos
        if (!callTopicList) {
          var objParamTopicByFiche = TopicStatesService.getParamsSearchTopicByFiche(objetsTags);
          if (objParamTopicByFiche.success === true) {
            $rootScope.current.filtreTopics.listeFournisseurMail = objParamTopicByFiche.listeFournisseurMail;
            $rootScope.current.filtreTopics.listeFournisseurPersoICS = objParamTopicByFiche.listeFournisseurPersoICS;
            callTopicList = true;
          }
        }

        // Si toujours pas de callTopicList à true, tante la recherche avec tag origin
        if (!callTopicList) {
          var tagOrig = TopicStatesService.getParamsSearchTopicByTagOrigin(objetsTags);
          if (tagOrig) {
            listeTagsSearch.push(tagOrig);
            callTopicList = true;
          }
        }

        // Si liste de tag trouvé alors recherche les topic avec
        if (listeTagsSearch.length) {
          $rootScope.current.filtreTopics.tags = listeTagsSearch;
        }

        if (callTopicList) {

          $rootScope.current.tmpNewEvenement.tagsForOrigine = objetsTags;

          if(_.isObject(objRetour) && _.isObject(objRetour.origine) && _.isObject(objRetour.origine.groupe) && objRetour.origine.groupe.isOld())
            $rootScope.current.tmpNewEvenement.tagsForOrigine = false;

          if (UtilsService.isTopicList() && !$state.includes('topics.recherche')) {

            $scope.$broadcast('filtreTopicList', {
              objFiltre: $rootScope.current.filtreTopics,
              gotToState: 'topics.tous',
              channel: -1,
              reload: false,
              notifyAndReload: true
            });
          }
          else {
            FiltreService.saveFiltre($rootScope.current.filtreTopics);
            var stateParams = FiltreService.getObjFiltreByChannel(-1);

            // Change l'url
            $state.go('topics.tous', stateParams, {'location': true, 'reload': true, 'notify': true});
          }
        }

      }
    }

    function initGroupeParams() {
      //$rootScope.isCapfun = UtilsService.isCapfun();
      UserService.initParamsCollab();
      ctrl.stateHomeCollab = UtilsService.getStateHomeCollab();
      ctrl.stateHomeCollabParams = FiltreService.getObjFiltreByChannel(UtilsService.getStateHomeCollabParams());
      initLogoCollab();
    }

    function initLogoCollab(){
      ctrl.logoCollab = UtilsService.getLogoCollab()+"&cache="+uuid.new();
    }

    function maximizeLeftSidebar(){
      $timeout(function(){
        angular.element('.main-wrapper').removeClass('sidebar-mini').addClass('sidebar-opened');
        $rootScope.sidebarMini = false;
        $rootScope.sidebarOpen = true;
      });
    }

    $scope.$on('openAnnuaireSearchTopics', function (event, infos, configAnnuaire) {
      openAnnuaire(infos, configAnnuaire);
    });

    $scope.$on('filtreByTags', function (event, objetTags) {
      filtreByTags(objetTags);
      //UtilsService.resetStateParamsAction();
    });


    /**
     * Permet de changer le titre de la page
     * @param text
     */
    $rootScope.setTitlePage = function (valText) {

      /* Commenté car plus de notification pour le moment
       var nbNotif = '';
       if(angular.isDefined(valText)){

       // Est-ce qu'il y a le nombre de notification dans le titre de page ?
       var hasNbNotification = UtilsService.test(valText,'^\\([0-9]*\\).*','g');
       // Si il y a bien le nombre de notification
       if(hasNbNotification){
       // Recupère le string du genre "(5) "
       var tabTitlePage = UtilsService.match(valText,'^\\([0-9]*\\)\\s+');
       // Supprime le nombre de notification du title de la page
       valText = UtilsService.replaceAll(tabTitlePage[0],'',valText);
       }
       if($rootScope.nbNotifications!==0){
       nbNotif = '('+$rootScope.nbNotifications+') ';
       }
       }

       $document[0].title = nbNotif+valText;*/
      $document[0].title = valText;


      /*
       console.log($document);
       $rootScope.title = nbNotif+valText;*/

      /*
       if(angular.isDefined($rootScope.nbNotifications) && $rootScope.nbNotifications!==0){
       text = '('+$rootScope.nbNotifications+') '+text;
       }

       $rootScope.title = text;*/
    };

    ctrl.toggleLeft = function () {
      var $mainWrapper = angular.element('.main-wrapper');
      if ($window.innerWidth > 767) {
        $mainWrapper.toggleClass('sidebar-mini');
        if ($mainWrapper.hasClass('sidebar-mini') && $mainWrapper.hasClass('sidebar-opened')) {
          $mainWrapper.removeClass('sidebar-opened')
        }
      } else {
        $mainWrapper.toggleClass('sidebar-opened');
        if ($mainWrapper.hasClass('sidebar-opened') && $mainWrapper.hasClass('sidebar-mini')) {
          $mainWrapper.removeClass('sidebar-mini')
        }
      }


      if ($mainWrapper.hasClass('sidebar-mini')) {
        $rootScope.sidebarMini = true;
      } else {
        $rootScope.sidebarMini = false;
      }

      if ($mainWrapper.hasClass('sidebar-opened')) {
        $rootScope.sidebarOpen = true;
      } else {
        $rootScope.sidebarOpen = false;
      }

      ConfigService.setPreferencesModeDisplayMiniSideBar($rootScope.sidebarMini);
    };

    ctrl.showDemoExtranet = false;
    ctrl.onCloseDemoExtranet = function () {
      ctrl.showDemoExtranet = false;
    };
    $scope.$on('openDemoExtranet', function () {
      ctrl.showDemoExtranet = true;
    });

    /*
     ctrl.checkWindowWidth = function() {

     // Si version tablette ou petit dekstop
     if($window.innerWidth>767 && $window.innerWidth<1200){
     angular.element('.main-wrapper').addClass('sidebar-mini');
     $rootScope.sidebarMini = true;

     // Si version mobile la sidebar est normal mais caché
     }else if($window.innerWidth<=767){
     angular.element('.main-wrapper').removeClass('sidebar-mini');
     $rootScope.sidebarMini = false;
     }
     };

     //first call of checkWindowWidth when the dom is loaded
     document.addEventListener('DOMContentLoaded', this.checkWindowWidth, false);

     //calling checkWindowWidth on resize event
     window.onresize = this.checkWindowWidth;*/

    // Permet de retourner à l'accueil
    $scope.$on('goAccueil', function () {
      $state.go('topics.list');
    });

    // Deconnexion
    $scope.$on('logOut', function (ev, logoutOnelogin) {
      // Déconnexion de l'utilisateur
      UserService.deconnexion(logoutOnelogin);

      // Redirige sur le login
      $state.go('login', {fromLogOut: true});
    });


    $scope.$on('changeGroupeParams', function () {
      initGroupeParams();
    });

    $scope.$on('refreshLogoCollab', function () {
      initLogoCollab();
    });

    $scope.$on('connectionStateChanged', function () {
      if (UserService.isConnected()) {

        $rootScope.isConnected = true;

        ctrl.hasPortefeuille = $rootScope.hasPortefeuille;

        ctrl.groupeUser = UserService.getGroupe();

        // Paramétrages de l'app
        initGroupeParams();

        // Recupération du mode d'affichage de la sidebar
        var miniSideBar = ConfigService.getPreferencesModeDisplayMiniSideBar();
        if (miniSideBar) {
          $rootScope.sidebarMini = true;
          $rootScope.sidebarOpen = false;
          $timeout(function () {
            angular.element('.main-wrapper').addClass('sidebar-mini');
          });
        }
      }
      else {
        $rootScope.isConnected = false;
        $timeout(function () {
          angular.element('body').removeClass('modal-open');
          angular.element('body').removeClass('stop-scrolling');
        });

        // Deconnecte la websocket
        /*WebsocketServices.disconnect(true);*/
      }

    });

    // Permet de minimiser la left sidebar
    $scope.$on('minimizeLeftSidebar', function () {
      $timeout(function(){
        angular.element('.main-wrapper').removeClass('sidebar-opened').addClass('sidebar-mini');
        $rootScope.sidebarMini = true;
        $rootScope.sidebarOpen = false;
      });
    });


    // Permet de maximiser la left sidebar
    $scope.$on('maximizeLeftSidebar', function () {
      maximizeLeftSidebar();
    });

    // Permet de maximiser la left sidebar
    $scope.$watch('$root.current', function (newVal) {
      ctrl.rootscopeCurrent = angular.copy(newVal);
    }, true);

  }


})();
