'use strict';

/**
 * @ngdoc service
 * @name collaboreApp.TagType
 * @description
 * # TagType
 * Service in the collaboreApp.
 */

angular.module('collaboreApp').factory('TagType',[
	function () {

		function TagType(tagData) {
			if (tagData) {
				this.setData(tagData);
			}
		}

		TagType.prototype = {
			
			setData: function(tagData) {
				angular.extend(this, tagData);
			},

			getIdTypeTag: function(){
				return parseInt(this.idTypeTag);
			},

			// Libelle
			getLibelle: function(){
				return this.libelle;
			},
			setLibelle: function(libelle){
				this.libelle = libelle;
			}
			
		};
		return TagType;

	}
]);
