(function() {

  'use strict';

  /**
   * @desc Composant annuaire
   * @example <annuaire></annuaire>
   */
  angular
    .module('collaboreApp')
    .component('fiche',{
      bindings: {
        groupeCollab: '<',
        myFiche: '<',
        onUpdate: '&'
      },
      templateUrl: 'app/fiche/fiche.component.html',
      controllerAs: 'fichectrl',
      controller: FicheCtrl
    });

  /*@ngInject*/
  function FicheCtrl(uuid, COLLAB_CONF, $rootScope, UtilsService, ModalsService,GroupesService,GroupeService,ErreurCollabService,StatesService,FichesService){
    var ctrl = this;

    ctrl.hasPortefeuille = $rootScope.hasPortefeuille;
    ctrl.editingMode = false;
    ctrl.isGroupePersoIcsFromMySociete = false;

    ctrl.informationsPrives = [];
    ctrl.groupeIsSameSociete = false;

    ctrl.onClickModifierFiche = onClickModifierFiche;
    ctrl.onClickGetGroupesParents = onClickGetGroupesParents;
    ctrl.addToDestinataire = addToDestinataire;
    ctrl.addToNewContenu = addToNewContenu;
    ctrl.cancelFiche = cancelFiche;
    ctrl.onUpdateLigneFiche = onUpdateLigneFiche;

    ctrl.$onInit = function() {

      ctrl.displayGroupesParents = false;
      ctrl.objSearch = {
        searchUser: '',
        searchGroupeParent: '',
        searchGroupe: ''
      };

      ctrl.groupeParents = {
        nom: '',
        loading: false,
        immeuble: false,
        hierarchieGroupe: []
      };

      ctrl.groupe = {
        nom: '',
        loading: false,
        immeuble: false,
        hierarchieGroupe: [],
        users: []
      };

      // Portefeuille
      ctrl.objPortefeuille = {
        show : false,
        liste: [],
        getInfosPortefeuilles: function(groupe){
          // Si c'est un utilisateurs et qu'il y a une liste de portefeuille
          if(groupe.hasOwnProperty('idGroupe') && $rootScope.hasPortefeuille && !ctrl.objPortefeuille.liste.length && groupe.hasOwnProperty('user')){

            ctrl.objPortefeuille.loading = COLLAB_CONF.MIDDLE_LOADING;

            // Recupère les infos perso des portefeuilles
            GroupeService.getInfosPersonnalite(groupe,true,true).then(function(arrayPortefeuille){
              //console.log(arrayPortefeuille);

              ctrl.objPortefeuille.show = true;

              ctrl.objPortefeuille.liste = arrayPortefeuille;

            },function(msg){

            }).finally(function(){
              ctrl.objPortefeuille.loading = false;
            });
          }
        }
      };

      //  TODO : Ne pas appeler la servlet siouvert dpuis mon compte
      if(ctrl.groupeCollab.hasOwnProperty('idGroupe')) {
        ctrl.loadingGroupe = true;
        GroupeService
          .getInfosGroupe(ctrl.groupeCollab.getIdGroupe())
          .then(function(groupe) {

            ctrl.groupeCollab = groupe;
            ctrl.objPortefeuille.getInfosPortefeuilles(groupe);
            ctrl.groupeIsSameSociete = UtilsService.isSameDiese(groupe.dieze);

            if(groupe.hasOwnProperty('libelle') && groupe.hasOwnProperty('dieze')) ctrl.identifiant_collabore = groupe.libelle + groupe.dieze;
            else if(groupe.hasOwnProperty('nom')) ctrl.identifiant_collabore = groupe.nom;

            if(!groupe.isUser()) getGroupesParents(groupe);
            initInfosFiche(groupe);

            if(ctrl.myFiche) {
              ctrl.isMyFiche = true;
              ctrl.editingMode = true;
              editerFiche();
            }
          })
          .catch(function(msg) {
            ModalsService.alertErreur(msg);
          })
          .finally(function() {
            ctrl.loadingGroupe = false;
          });
      }

    };


    /******* Privée *********/

    function initInfosFiche(groupeCollab){
      ctrl.informations = {};

      var groupeFiche,idGroupe;

      // Si c'est mon utilisateur
      if(ctrl.isMyFiche){
        groupeFiche = groupeCollab.getUser().getFichePerso();
        ctrl.idGroupe = idGroupe = UtilsService.getCurrentUser().getIdGroupe();

        if(groupeFiche) groupeFiche.idGroupe = idGroupe;

      }
      // Sinon l'utilisateur de quelqu'un d'autre
      else{
        // Si c'est un utilisateur
        if(ctrl.groupeCollab.isUser()) groupeFiche = groupeCollab.getUser().getFichePerso();
        // Si c'est un groupe non user
        else {
          // Crée une instance de 'Groupe'
          var groupeInstance = GroupesService.newGroupe(groupeCollab);
          // Recupère la fiche éventuel du groupe
          groupeFiche = groupeInstance.getFiche();
          groupeInstance = null;
        }

      }

      ctrl.prenom_nom = groupeCollab.getDefaultNom();

      if(groupeFiche){

        // Si l'user à modifié est le meme que celui qui modifie la fiche
        if(groupeCollab.idGroupe === UtilsService.getCurrentUser().getIdGroupe()) ctrl.isMyFiche = true;
        // Si le groupe modifié est un groupe Perso ICS de la société du groupe qui modifie la fiche
        else if(groupeCollab && groupeCollab.getGroupeInformations()) ctrl.isGroupePersoIcsFromMySociete = groupeCollab.getGroupeInformations().getIsGroupePersoIcsFromMySociete();

        ctrl.idGroupe = groupeCollab.idGroupe;
        ctrl.idFiche = groupeFiche.getIdFiche();
        ctrl.idFichePere = (groupeFiche.hasFichePere()) ? groupeFiche.getFichePere().getIdFiche() : false;

        var user = groupeCollab.getUser();
        ctrl.user = user;
        ctrl.informations = groupeFiche.getInformationsByCategorie(); // Infos de la fiche perso
        ctrl.informationsPrives = groupeFiche.getInformationsPrive(); // Infos de la surfiche
      }
      else{

        ctrl.idFiche = groupeCollab.idFiche;
        ctrl.idFichePere = (groupeCollab.model === 'Fiche' && groupeCollab.hasFichePere()) ? groupeCollab.getFichePere().getIdFiche() : false;
        ctrl.idGroupe = null;
        ctrl.user = null;
        if(groupeCollab.hasOwnProperty('user')) ctrl.user = groupeCollab.user;

        ctrl.informations = {
          nom: {
            default: false,
            partage: false,
            prive: false
          },
          prenom: {
            default: false,
            partage: false,
            prive: false
          },
          adresse: {
            default: false,
            partage: false,
            prive: false
          },
          /*
          adresses: {
              all: [],
              partages: [],
              prives: []
          },*/
          telephones: {
            all: [],
            partages: [],
            prives: []
          },
          fax: {
            all: [],
            partages: [],
            prives: []
          },
          emails: {
            all: [],
            partages: [],
            prives: []
          },
          commentaires: {
            all: [],
            partages: [],
            prives: []
          }
        };
      }


      // Si c'est un groupe
      if(!ctrl.groupeCollab.isUser()){

        // Set le nom par défault
        var nomGroupe = groupeCollab.getDefaultNom();

        // Si le groupe est un immeuble
        if(groupeCollab.isImmeuble()){

          if(ctrl.informations.nom.default){
            nomGroupe = ctrl.informations.nom.default.getDonnee();
          }

          // Si le groupe n'est pas un immeuble
        }else{
          if(groupeCollab.immeuble){
            // Si l'objet "objImmeuble" est un immeuble
            if(groupeCollab.immeuble.isModel){
              ctrl.groupe.immeuble = {
                'obj':groupeCollab.immeuble,
                'nom': groupeCollab.immeuble.getLibelleDieze()
              };

              // Recupère l'éventuel fiche de l'immeuble
              var ficheImmeuble = groupeCollab.immeuble.getFiche();
              if(ficheImmeuble){
                // Stocke le nom de l'immeuble
                ctrl.groupe.immeuble.nom = ficheImmeuble.getNomPrenom();
              }
            }
          }
        }
        ctrl.groupe.nom = nomGroupe;
      }
    }



    function addChamp() {

      var retour,
        categorie,
        renseignement = false;

      if(arguments.length) {

        var valueReference = 0,
          valueState = 0,
          valueRenseignement = '',
          isMyEmailCollab = false,
          canDelete = true;

        angular.forEach(arguments, function (argument) {
          // Si c'est un objet
          if (angular.isObject(argument)) {

            if (argument.isRenseignement) renseignement = argument;
            else if (argument.isMyEmailCollab) {
              valueRenseignement = argument.value;
              valueState = argument.state;
              valueReference = argument.reference;
              isMyEmailCollab = true;
            }

            if(argument.hasOwnProperty('canDelete')) canDelete = argument.canDelete;
          }
          // Si string alors categorie
          else if (angular.isString(argument)) categorie = argument;
        });



        // Si il y a une reference en argument alors on réupère l'id comme valeur
        if (renseignement) {


          valueReference = renseignement.getReference().getIdReference();
          valueRenseignement = renseignement.getDonnee();

          // Si email temporaire OU Si email avec state public
          // Alors on ne supprime pas
          if(renseignement.isEmailTmp() || (renseignement.isEmail() && renseignement.getState().isPublic())) canDelete = false;
          //if(renseignement.isEmailTmp()) canDelete = false;

          // Si il y a un typePersoMail venant de ICS
          if(renseignement.getTypePersoMail()) {
            // Si email alors on peux pas delete
            if(renseignement.getTypePersoMail() === 3) canDelete = false;
            // Si email-info alors on peux delete
            else if(renseignement.getTypePersoMail() === 4) canDelete = true;
          }

          // Si c'est ma fiche
          if(ctrl.isMyFiche && renseignement.hasState()) valueState = renseignement.getState().getIdState();
          else valueState = 2;
        }

        if(categorie){
          var placeholder = '';
          switch (categorie) {
            case 'nom':
              placeholder = 'Ajouter un nom';
              break;
            case 'prenom':
              placeholder = 'Ajouter un prénom';
              break;
            case 'telephone':
              placeholder = 'Ajouter un numéro de téléphone';
              break;
            /*
        case 'adresses':
            placeholder = 'Ajouter une adresse';
            break;*/
            case 'fax':
              placeholder = 'Ajouter un numéro de fax';
              break;
            case 'commentaire':
              placeholder = 'Ajouter un commentaire';
              break;
            case 'email':
              placeholder = 'Ajouter un email';
              break;
          }

          var displayReference = false,
            displayState = false;
          if(categorie === 'nom' || categorie === 'prenom' || valueRenseignement){
            displayReference = true;
            displayState = true;
          }

          retour = {
            placeholder: placeholder,
            renseignement: renseignement,
            isMyEmailCollab: isMyEmailCollab,
            canDelete: canDelete,
            reference: {
              categorie: categorie,
              typeChamp: (getListeReference(categorie).length > 1) ? 'select' : 'text',
              liste: getListeReference(categorie),
              value: valueReference,
              show: displayReference
            },
            state: {
              type: 3,
              liste: getListeState(3),
              value: valueState,
              show: displayState
            },
            valueType: 'textarea',
            displayClean: false,
            disabled: (isMyEmailCollab) ? true : false,
            name: uuid.new(),
            tmpValue: valueRenseignement,
            value: valueRenseignement
          };
        }
      }
      return retour;

    }

    function joinGroupesParentsEnfants(groupeOrigin,groupes){
      if(groupes.length){
        for(var n = 0 ; n < groupes.length ; n++){
          if(groupes[n].idGroupe===groupeOrigin.idGroupe){

            groupes[n].currentGroupe = true;
            groupes[n].users = groupeOrigin.users;
            groupes[n].groupes = groupeOrigin.groupes;
            break;
          }else{
            joinGroupesParentsEnfants(groupeOrigin,groupes[n].groupes);
          }
        }
      }
    }

    function getListeReference(categorie){
      var liste = false;
      if(categorie){
        liste = FichesService.getListeReferencesByCategorie(categorie);
      }else{
        liste = FichesService.getListeReferences();
      }
      return liste;
    }

    function getListeState(type){
      var liste = false;
      if(type){
        liste = StatesService.getListeStatesByType(type);
      }else{
        liste = StatesService.getListeStates();
      }
      return liste;
    }

    function editerFiche(){

      ctrl.editingMode = true;
      ctrl.editingData = {
        nom: {
          libelle: false,
          value: [],
          unique: true
        },
        prenom: {
          libelle: false,
          value: [],
          unique: true
        },
        /*
        adresses: {
            libelle: 'Adresse',
            value: [],
            unique: false
        },*/
        adresse: {
          libelle: false,
          value: [],
          unique: false
        },

        codePostal: {
          libelle: false,
          value: [],
          unique: true
        },
        ville: {
          libelle: false,
          value: [],
          unique: true
        },

        telephone: {
          libelle: 'Téléphones',
          value: [],
          unique: false
        },
        fax: {
          libelle: 'Fax',
          value: [],
          unique: false
        },
        email: {
          libelle: 'Emails',
          value: [],
          unique: false
        },
        commentaire: {
          libelle: 'Commentaires',
          value: [],
          unique: false
        }
      };


      var canDelete = null;
      if(ctrl.isGroupePersoIcsFromMySociete) canDelete = {canDelete: true};

      ctrl.editingData.nom.value.push(addChamp(ctrl.informations.nom.default,'nom',canDelete));
      ctrl.editingData.prenom.value.push(addChamp(ctrl.informations.prenom.default,'prenom',canDelete));

      // Si il y a déjà des téléphones
      angular.forEach(ctrl.informations.telephones.all,function(telephone){
        ctrl.editingData.telephone.value.push(addChamp(telephone,'telephone',canDelete));
      });
      ctrl.editingData.telephone.value.push(addChamp('telephone',canDelete));

      // Si il y a déjà des fax
      angular.forEach(ctrl.informations.fax.all,function(fax){
        ctrl.editingData.fax.value.push(addChamp(fax,'fax',canDelete));
      });
      ctrl.editingData.fax.value.push(addChamp('fax',canDelete));


      var myEmail = false,myEmailExist = false;
      if(ctrl.isMyFiche) myEmail = UtilsService.getCurrentUser().getEmail();


      // Si il y a déjà des emails
      angular.forEach(ctrl.informations.emails.all,function(email){
        ctrl.editingData.email.value.push(addChamp(email,'email',canDelete));
        if(email.getDonnee()===myEmail) myEmailExist = true;
      });
      if(!myEmailExist && myEmail){
        ctrl.editingData.email.value.push(addChamp('email',{isMyEmailCollab: true,value:myEmail,state:2,reference:2}));
      }

      ctrl.editingData.email.value.push(addChamp('email'));



      // Si il y a déjà des commentaires
      angular.forEach(ctrl.informations.commentaires.all,function(commentaire){
        ctrl.editingData.commentaire.value.push(addChamp(commentaire,'commentaire',canDelete));
      });
      ctrl.editingData.commentaire.value.push(addChamp('commentaire',canDelete));

      ctrl.disableSaveFiche = true;
    }

    /********* Public **********/

    function onClickGetGroupesParents() {

      getGroupesParents(ctrl.groupeCollab);
    }

    function getGroupesParents(groupe) {

      // Si il a l'attribut "idGroupe"
      if(groupe.hasOwnProperty('idGroupe') && !ctrl.myFiche){
        ctrl.displayGroupesParents = true;
        // Si c'est un group avec objet 'user'
        if(groupe.isUser()){

          ctrl.groupe.loading = true;
          GroupesService.getGroupesParent(groupe.idGroupe).then(function(groupesParents){

            // Hiérarchie du groupe
            ctrl.groupe.hierarchieGroupe = [groupesParents];

          }).finally(function(){
            ctrl.groupe.loading = false;
          });

          // Si c'est un group sans objet 'user'
        }else{
          ctrl.groupe.loading = true;

          // Recupération des groupes parents
          GroupesService.getGroupesParent(groupe.idGroupe).then(function(groupesParents){

            // Hiérarchie du groupe
            ctrl.groupe.hierarchieGroupe = [groupesParents];

            if(ctrl.groupeIsSameSociete){
              // Recupère les groupes enfants
              GroupesService.getGroupes(groupe.libelle+groupe.dieze).then(function(groupesEnfants){

                // Join le groupe enfant au groupe parent
                joinGroupesParentsEnfants(groupesEnfants,ctrl.groupe.hierarchieGroupe[0].groupes);

                // Tous les users du groupe
                ctrl.groupe.users = groupesEnfants.allUsers;

              }).finally(function(){
                ctrl.groupe.loading = false;
              });
            }else{
              ctrl.groupe.loading = false;
            }

          },function(){
            ctrl.groupe.loading = false;
          });
        }
      }
    }


    /**
     * Fonction qui permet d'ajouter un user ou un groupe au destinataires du formulaire actuel
     * @param destinataire
     */
    function addToDestinataire(destinataire){
      $rootScope.$broadcast('addToDestinataire',destinataire);
      ModalsService.closeAllModalFiche();
    }

    /**
     * Fonction qui permet d'ajouter un user ou un groupe dans un tableau de destinataires temporaire qui permet d'enrichir le formulaire d'un nouveau contenu
     */
    function addToNewContenu(){
      $rootScope.current.tmpNewEvenement.destinataires.push(ctrl.groupeCollab);
      ModalsService.closeAllModalFiche();
    }

    function cancelFiche(){
      ctrl.editingMode = false;
    }


    function onClickModifierFiche(){
      editerFiche();
    }

    function onUpdateLigneFiche(ligneFiche) {
      if(ligneFiche.hasOwnProperty('groupe')) {
        ctrl.groupeCollab = ligneFiche.groupe;
        //ctrl.onUpdate({groupe: ligneFiche.groupe});
        initInfosFiche(ligneFiche.groupe);
        editerFiche();
      }
    }
  }
})();
