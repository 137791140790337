(function () {
    'use strict';

    /**
     * @desc Page qui permet la diffusion par SMS de l'application TocTocToc
     * @example <diffusion-toctoctoc></diffusion-toctoctoc>
     */

    angular
        .module('collaboreApp')
        .component('diffusionToctoctoc', {
            templateUrl: 'app/diffusion-toctoctoc/diffusion-toctoctoc.component.html',
            controllerAs: 'diffusiontoctoctocctrl',
            controller: DiffusionTocTocTocController
        });

    /*@ngInject*/
    function DiffusionTocTocTocController($q, $timeout,  UtilsService, GroupeService, CommunicationService, ModalsService) {

        var ctrl = this;
        ctrl.onClickRemoveAllGroupeChecked = onClickRemoveAllGroupeChecked;
        ctrl.onClickRemoveGroupeChecked = onClickRemoveGroupeChecked;
        ctrl.onClickEnvoyer = onClickEnvoyer;
        ctrl.onChangePortefeuille = onChangePortefeuille;
        ctrl.onEditorSmsReady = onEditorSmsReady;
        ctrl.onEmitFromChooseGroupe = onEmitFromChooseGroupe;

        ctrl.onSuccessCopy = onSuccessCopy;
        ctrl.onErrorCopy = onErrorCopy;

        ctrl.nbCoproprieteChecked = 0;
        ctrl.lienToctoctoc = '/* @echo URL_TOCTOCTOC */';

        ctrl.loadingSlider = true;
        ctrl.slickConfig = {
            enabled: true,
            autoplay: true,
            draggable: true,
            autoplaySpeed: 5000,
            arrows:  false,
            method: {},
            event: {
                beforeChange: function (event, slick, currentSlide, nextSlide) {
                },
                afterChange: function (event, slick, currentSlide, nextSlide) {
                }
            }
        };

        ctrl.$onInit = function () {
            ctrl.listGroupeChecked = [];  // Liste des groupes checked (Objet avec portefeuille et liste idGroupe)

            ctrl.objEditorSms = null;
            ctrl.messageSms = "Plus proche de vous. Un pb dans votre Immeuble, postez le en 3 clics dans l'app TOCTOCTOC!\n"+ctrl.lienToctoctoc;

            ctrl.listPortefeuilles = UtilsService.getCurrentUser().getListePortefeuille();
            if(!angular.isArray(ctrl.listPortefeuilles)) ctrl.listPortefeuilles = [];

            ctrl.currentPortefeuille = null;
            // Si il y a seulement un portefeuille dans la liste alors on le présélectionne
            if(ctrl.listPortefeuilles.length === 1) ctrl.currentPortefeuille = ctrl.listPortefeuilles[0];

            $timeout(function(){
                ctrl.loadingSlider = false;
            }, 1000);
        };

        ctrl.$onDestroy = function(){
        };

        /**
         * Lors du clique sur le bouton pour remove tout les groupes checked
         */
        function onClickRemoveAllGroupeChecked(){
            ctrl.listGroupeChecked = [];
            updateNbCoproprieteChecked();
        }

        /**
         * Lors du clique sur le bouton pour remove un groupe checked
         * @param groupe
         */
        function onClickRemoveGroupeChecked(groupe) {
            removeGroupeChecked(groupe);
        }

        /**
         * Permet de retirer un groupe de la liste des groupe checked
         * @param groupe
         */
        function removeGroupeChecked(groupe) {
            if(ctrl.listGroupeChecked.length) {
                for(var i = 0; i < ctrl.listGroupeChecked.length; i++) {
                    var canBreak = false;
                    if(ctrl.listGroupeChecked[i].getGroupesFils().length) {
                        for(var g = 0; g < ctrl.listGroupeChecked[i].getGroupesFils().length; g++) {
                            if(ctrl.listGroupeChecked[i].getGroupesFils()[g].idGroupe === groupe.idGroupe) {
                                ctrl.listGroupeChecked[i].getGroupesFils()[g].splice(i, 1);
                                canBreak = true;
                                break;
                            }
                        }
                    }

                    // Si plus de groupe fils dans le groupe portefeuille alors retire le groupe portefeuille de la liste des checked
                    if(!ctrl.listGroupeChecked[i].getGroupesFils().length) ctrl.listGroupeChecked.splice(i, 1);
                    if(canBreak) break;
                }
            }
            updateNbCoproprieteChecked();
        }

        /**
         * Permet d'update le nombre de copropriété checked
         * @returns {number}
         */
        function updateNbCoproprieteChecked(){
            ctrl.nbCoproprieteChecked = 0;
            if(ctrl.listGroupeChecked.length) {
                for(var i = 0; i < ctrl.listGroupeChecked.length; i++){
                    ctrl.nbCoproprieteChecked += ctrl.listGroupeChecked[i].getGroupesFils().length;
                }
            }
        }

        /**
         * Lors du clique sur le bouton pour envoyer le SMS
         */
        function onClickEnvoyer(){
            var messageConfirm = '<div class="text-left">' +
                '<strong>Récapitulatif:</strong><br />' +
                'Nombre de portefeuille: ' + ctrl.listGroupeChecked.length + '<br />' +
                'Nombre de copropriété: ' + ctrl.nbCoproprieteChecked + '<br />' +
                'Message: ' + ctrl.objEditorSms.getData() +
                '</div>';

            ModalsService
                .confirm('Envoyer le SMS ?', messageConfirm, {type: 'info'})
                .then(function (modal) {
                    sendSMS()
                        .then(function(){
                            console.log('envoye');
                            //modal.close();
                            ModalsService.success('SMS en cours d\'envoi !');
                        })
                        .catch(function (msg) {
                            console.log('error', msg);
                        })
                        .finally(function(){
                            console.log('finally');
                        });
                });

        }

        /**
         * Permet d'envoyer les SMS
         */
        function sendSMS(){
            var defer = $q.defer();
            var reject = function(msg){
                ModalsService.alertErreur(msg);
                defer.reject(msg);
            };

            try {
                if(!ctrl.listGroupeChecked.length) throw new Error('Pas de groupe coché !');
                if(ctrl.objEditorSms.getData() === '') throw new Error('Le message est vide !');

                /*
                var listIdGroupe = ctrl.listGroupeChecked.map(function(groupe) {
                  return groupe.getIdGroupe();
                });*/
                var listIdGroupeByPortefeuille = [];
                for(var p = 0; p < ctrl.listGroupeChecked.length; p++) {
                    listIdGroupeByPortefeuille.push({
                        listIdGroupe: ctrl.listGroupeChecked[p].getGroupesFils().map(function(groupe){
                            return groupe.getIdGroupe();
                        }),
                        idPortefeuille: (angular.isObject(ctrl.listGroupeChecked[p].portefeuille)) ? ctrl.listGroupeChecked[p].portefeuille.idPortefeuille : null
                    });
                }

                CommunicationService
                    .sendSMSDiffusionToctoctoc(listIdGroupeByPortefeuille, UtilsService.getCurrentUser().getDieze() + ' Diffusion TocTocToc', ctrl.objEditorSms.getData())
                    .then(function() {
                        defer.resolve(true);
                    })
                    .catch(function(e){
                        reject(e);
                    });
            }
            catch(e) {
                reject(e.message);
            }
            return defer.promise;
        }

        /**
         * Lors du changement de portefeuille
         */
        function onChangePortefeuille(){
            //console.log(ctrl.selected.portefeuille);
        }

        /**
         * Lorsque l'editeur de SMS est prêt
         * @param obj
         */
        function onEditorSmsReady(obj){
            ctrl.objEditorSms = obj;
        }

        /**
         * Lorsque le composant "choose-groupe" remonte une information
         * @param obj
         */
        function onEmitFromChooseGroupe(obj) {
            if(angular.isObject(obj)) {
                //if(obj.listGroupeChecked && angular.isArray(obj.listGroupeChecked)) ctrl.listGroupeChecked = obj.listGroupeChecked;
                if(obj.listGroupePortefeuilleChecked && angular.isArray(obj.listGroupePortefeuilleChecked)) {
                    ctrl.listGroupeChecked = obj.listGroupePortefeuilleChecked;
                    updateNbCoproprieteChecked();
                }
            }
        }

        /**
         * Executée lors de la copie avec success du lien dans le press papier
         * @param e
         */
        function onSuccessCopy(e) {
            //e.clearSelection();
            ctrl.copyLienApp = {
                open: true,
                txt: 'Lien copié !'
            };
            $timeout(function(){
                ctrl.copyLienApp.open = false;
            }, 2000);
        }

        /**
         * Executée lors de la copie avec erreur du lien dans le press papier
         * @param e
         */
        function onErrorCopy(e) {
            ctrl.copyLienApp = {
                open: true,
                txt: 'Erreur...'
            };
            $timeout(function(){
                ctrl.copyLienApp.open = false;
            }, 2000);
        }
    }
})();
