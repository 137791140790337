(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.GroupeInformations
   * @description
   * # GroupeInformations
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('GroupeInformations', GroupeInformationsModel);

  /** @ngInject */
  function GroupeInformationsModel(Main, CampingInformations) {

    function GroupeInformations(contenuData) {
      Main.apply(this, arguments);
      this.hydrate(contenuData);
    }

    GroupeInformations.prototype = {

      model: 'GroupeInformations',
      isGroupeInformations: true,

      // hasNumeroPortablePrive
      getHasNumeroPortablePrive: function(){
        return this.hasNumeroPortablePrive;
      },
      setHasNumeroPortablePrive: function(val){
        this.hasNumeroPortablePrive = val;
      },

      // isGroupePersoIcs
      getIsGroupePersoIcs: function(){
        return this.isGroupePersoIcs;
      },
      setIsGroupePersoIcs: function(val){
        this.isGroupePersoIcs = val;
      },

      // isGroupePersoIcsFromMySociete
      getIsGroupePersoIcsFromMySociete: function(){
        return this.isGroupePersoIcsFromMySociete;
      },
      setIsGroupePersoIcsFromMySociete: function(val){
        this.isGroupePersoIcsFromMySociete = val;
      },

      // isGroupePortefeuille
      getIsGroupePortefeuille: function(){
        return this.isGroupePortefeuille;
      },
      setIsGroupePortefeuille: function(val){
        this.isGroupePortefeuille = val;
      },

      // nbChannels
      getNbChannels: function(){
        return this.nbChannels;
      },
      setNbChannels: function(val){
        this.nbChannels = val;
      },

      // nbGroupesNonUser
      getNbGroupesNonUser: function(){
        return this.nbGroupesNonUser;
      },
      setNbGroupesNonUser: function(val){
        this.nbGroupesNonUser = val;
      },

      // nbGroupesUser
      getNbGroupesUser: function(){
        return this.nbGroupesUser;
      },
      setNbGroupesUser: function(val){
        this.nbGroupesUser = val;
      },

      // nbModules
      getNbModulese: function(){
        return this.nbModules;
      },
      setNbModules: function(val){
        this.nbModules = val;
      },

      // nbPortefeuilles
      getNbPortefeuilles: function(){
        return this.nbPortefeuilles;
      },
      setNbPortefeuilles: function(val){
        this.nbPortefeuilles = val;
      },

      // email
      getEmail: function(){
        return this.email;
      },
      setEmail: function(val){
        this.email = val;
      },

      // hasEmailTemporaire
      getHasEmailTemporaire: function(){
        return this.hasEmailTemporaire;
      },
      setHasEmailTemporaire: function(val){
        this.hasEmailTemporaire = val;
      },

      // listCampingInformations
      getListCampingInformations: function(){
        return this.listCampingInformations;
      },
      setListCampingInformations: function(val){
        if(_.isArray(val) && val.length){
          val = val.map(function(item) {
            if(!item.isModel) item = new CampingInformations(item);
            return item;
          });
          this.listCampingInformations = val;
        }
      },

      // onlyInGroupeCamping
      getOnlyInGroupeCamping: function(){
        return this.onlyInGroupeCamping;
      },
      setOnlyInGroupeCamping: function(val){
        this.onlyInGroupeCamping = val;
      },

      // idGroupe
      getIdGroupe: function(){
        return this.idGroupe;
      },
      setIdGroupe: function(val){
        this.idGroupe = val;
      },

      // libelle
      getLibelle: function(){
        return this.libelle;
      },
      setLibelle: function(val){
        this.libelle = val;
      },

      // nom
      getNom: function(){
        return this.nom;
      },
      setNom: function(val){
        this.nom = val;
      },

      // prenom
      getPrenom: function(){
        return this.prenom;
      },
      setPrenom: function(val){
        this.prenom = val;
      },

      // adresse
      getAdresse: function(){
        return this.adresse;
      },
      setAdresse: function(val){
        this.adresse = val;
      },

      // listGroupeImmeuble
      getListGroupeImmeuble: function(toHtml){
        var retour = this.listGroupeImmeuble;
        if(toHtml && _.isArray(this.listGroupeImmeuble) && this.listGroupeImmeuble.length){
          retour = this.listGroupeImmeuble.reduce(function(accum, item){
            accum += "["+item.getNumero()+"] "+item.getNom();
            if(item.getAdresse() !== "") accum += " - "+item.getAdresse();
            accum += "<br/>";
            return accum;
          }, '');
          retour = _.trimEnd(retour, '<br/>');
        }
        return retour;
      },
      setListGroupeImmeuble: function(val){
        if(_.isArray(val)){
          val = val.map(function(item) {
            if(!item.isModel) item = new GroupeInformations(item);
            return item;
          });
          this.listGroupeImmeuble = val;
        }
      },

      // listGroupeTypePerso
      getListGroupeTypePerso: function(){
        return this.listGroupeTypePerso;
      },
      setListGroupeTypePerso: function(val){
        if(_.isArray(val)){
          val = val.map(function(item) {
            if(!item.isModel) item = new GroupeInformations(item);
            return item;
          });
          this.listGroupeTypePerso = val;
        }
      },

      // numero
      getNumero: function(){
        return this.numero;
      },
      setNumero: function(val){
        this.numero = val;
      },

      // nbGroupeFilsTotal
      getNbGroupeFilsTotal: function(){
        return this.nbGroupeFilsTotal;
      },
      setNbGroupeFilsTotal: function(val){
        this.nbGroupeFilsTotal = val;
      },

      // nbGroupeFils
      getNbGroupeFils: function(){
        return this.nbGroupeFils;
      },
      setNbGroupeFils: function(val){
        this.nbGroupeFils = val;
      },

      // nbMailNonLu
      getNbMailNonLu: function(){
        return this.nbMailNonLu;
      },
      setNbMailNonLu: function(val){
        this.nbMailNonLu = val;
      },

      // personnaliteICS
      getPersonnaliteICS: function () {
        return this.personnaliteICS;
      },
      setPersonnaliteICS: function (perso) {
        if(_.isObject(perso)) {
          if(!perso.isModel) {
            var Personnalite = Main.$injector.get('Personnalite');
            perso = new Personnalite(perso);
            Personnalite = null;
          }
        }
        this.personnaliteICS = perso;
      },

      // digicode
      getDigicode: function () {
        return this.digicode;
      },
      setDigicode: function (val) {
        if(_.isObject(val)) {
          if(!val.isModel) {
            var Digicode = Main.$injector.get('Digicode');
            val = new Digicode(val);
            Digicode = null;
          }
        }
        this.digicode = val;
      },
    };

    angular.extend(GroupeInformations.prototype, Main.prototype);

    return GroupeInformations;
  }
})();
