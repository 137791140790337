(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.TopicService
   * @description
   * # TopicService
   * Service in the collaboreApp.
   */
  angular
    .module('collaboreApp')
    .service('TopicService',
      TopicService
    );

  /** @ngInject */
  function TopicService(COLLAB_CONF, $stateParams, MainService, $q, ModalsService, TopicResource, TopicStatesService, ErreurCollabService, UtilsService) {


    var ctrl = this;
    ctrl.mapTagTypeToObject = {};
    ctrl.createEvenement = createEvenement;
    ctrl.save = save;
    ctrl.updateTitre = updateTitre;
    ctrl.saveNote = saveNote;
    ctrl.getPoolTopicStates = getPoolTopicStates;
    //ctrl.getEvenementByIdTopic = getEvenementByIdTopic;
    ctrl.getEvenementForIdTopic = getEvenementForIdTopic;
    ctrl.getTopicWithFactureNoSentToComfact = getTopicWithFactureNoSentToComfact;

    ctrl.searchListAgenceWorkWithMe = searchListAgenceWorkWithMe;

    // Catégorie de topic
    ctrl.getListCategorieTopic = getListCategorieTopic;
    ctrl.updateCategorieTopic = updateCategorieTopic;

    ctrl.clotureOrReouvre = clotureOrReouvre;
    ctrl.getDateClotureTopicForListId = getDateClotureTopicForListId;

    ctrl.clotureTopic = clotureTopic;
    ctrl.archiveTopic = archiveTopic;
    ctrl.cacheTopic = cacheTopic;
    ctrl.normalizeTopic = normalizeTopic;

    ctrl.searchCollab = searchCollab;

    ctrl.destroy = destroy;

    function createEvenement(objetTopic, objetEvenement){
      var deferred = $q.defer();

      var ProxyContenuEvenement = MainService.$injector.get('ProxyContenuEvenement');
      var params = {
        action: 'create',
        json: new ProxyContenuEvenement(objetEvenement).toJson()
      };
      if(objetTopic.hasOwnProperty('idTopic') && objetTopic.idTopic) params.idTopic = objetTopic.idTopic;
      if(objetTopic.hasOwnProperty('titreTopic') && objetTopic.titreTopic) params.titre = objetTopic.titreTopic;
      if(objetTopic.hasOwnProperty('cacher') && objetTopic.cacher) params.cacher = objetTopic.cacher;
      if(objetTopic.hasOwnProperty('idCategorieTopic') && objetTopic.idCategorieTopic) params.idCategorieTopic = objetTopic.idCategorieTopic;

      ProxyContenuEvenement = null;

      TopicResource
        .post(params, function (json) {
            var Topic = MainService.$injector.get('Topic');
            deferred.resolve(MainService.convertJsonToModel(json, 'topic', Topic, deferred));
            Topic = null;
          },
          function (msg) {
            deferred.reject(msg);
          });
      return deferred.promise;
    }

    /**
     * save
     * @desc Ajoute un topic
     * @param {Object} objParams - Objet des paramètres à envoyer en post
     * @returns {Promesse}
     */
    function save(param){

      var deferred = $q.defer();

      TopicResource.save(param,function(json){
        //console.log(json);

        var retour = false;
        if(json.nb!==0){
          if(json.Topics[0]) {
            retour = json.Topics[0];
          }
        }
        deferred.resolve(retour);
      },function(msg){
        deferred.reject(msg);
      });

      return deferred.promise;
    }

    function updateTitre(topic,newTitre){

      var deferred = $q.defer();
      var params = {
        'idTopic': topic.getIdTopic(),
        'donnee': newTitre
      };

      TopicResource.updateTitre(params,function(json){

        if(json.nb!=='0'){
          if(json.Resultats[0].success){

            deferred.resolve();
            topic.setTitre(newTitre);

          }else{
            deferred.reject();
          }
        }else{
          deferred.reject();
        }

      },function(error){
        if(error.status===403){
          deferred.reject(error);
        }else{
          ErreurCollabService.erreurServlet(error,'Problème avec retour servlet TopicService.updateTitre()',deferred);
        }

      });
      return deferred.promise;
    }

    function saveNote(idTopic, note){

      var deferred = $q.defer();
      var params = {
        action: 'saveNote',
        idTopic: idTopic,
        note: note
      };

      TopicResource.post(params, function(json){
        var Topic = MainService.$injector.get('Topic');
        deferred.resolve(MainService.convertJsonToModel(json, 'topic', Topic, deferred));
        Topic = null;
      },function(error){
        if(error.status===403) deferred.reject(error);
        else ErreurCollabService.erreurServlet(error,'Problème avec retour servlet TopicService.updateTitre()',deferred);
      });
      return deferred.promise;
    }

    function getPoolTopicStates(){
      return TopicStatesService._poolTopicStates;
    }

    /**[OBSOLETE] utiliser "getEvenementForIdTopic"
     * Permet de recupérer l'événement au complet pour un idTopic
     * @param idTopic
     * @returns {Promise}
     */
    /*
    function getEvenementByIdTopic(idTopic) {
      var deferred = $q.defer();
      var params = {
        topic: idTopic,
        getAllInfos: true
      };

      TopicResource.getEvenement(params,function(json){

        if(json.nb!=='0'){
          if(json.Contenus.length){
            var ContenuEvenement = MainService.$injector.get('ContenuEvenement');
            deferred.resolve(new ContenuEvenement(json.Contenus[0]));
          }else{
            deferred.reject();
          }
        }else{
          deferred.reject();
        }

      },function(error){
        if(error.status===403){
          deferred.reject(error);
        }else{
          ErreurCollabService.erreurServlet(error,'Problème avec retour servlet TopicService.getEvenement()',deferred);
        }

      });
      return deferred.promise;
    }*/

    /**
     * Permet de recupérer l'événement au complet pour un idTopic
     * @param idTopic
     * @returns {Promise}
     */
    function getEvenementForIdTopic(idTopic) {
      var deferred = $q.defer();
      var params = {
        idTopic: idTopic,
        action: 'getEvenementFromTopic'
      };

      TopicResource.post(params, function(json) {
        var ContenuEvenement = MainService.$injector.get('ContenuEvenement');
        deferred.resolve(MainService.convertJsonToModel(json, 'evenement', ContenuEvenement, deferred));

      },function(error) {
        if(error.status===403) {
          deferred.reject(error);
        }
        else {
          ErreurCollabService.erreurServlet(error,'Problème avec retour servlet TopicService.getEvenementForIdTopic()',deferred);
        }

      });
      return deferred.promise;
    }

    /**
     * Permet de récupérer un topicState qui à des facture non envoyé dans Comfact
     * @param nPage
     */
    function getTopicWithFactureNoSentToComfact(obj) {
      if(!obj) obj = {page:1};
      else obj = angular.copy(obj);
      var deferred = $q.defer();

      delete obj.paramsComfactfacture;

      TopicResource.getTopicWithFactureNoSentToComfact(obj, function(json){

        if(json.success) {
          var TopicStateFacture = MainService.$injector.get('TopicStateFacture');
          deferred.resolve(new TopicStateFacture(json));
        }
        else deferred.reject(json.message);
      },function(error){
        if(error.status===403) deferred.reject(error);
        else ErreurCollabService.erreurServlet(error,'Problème avec retour servlet TopicService.getTopicWithFactureNoSentToComfact()',deferred);
      });
      return deferred.promise;
    }

    /**
     * Permet de récupérer la liste des categories de topic
     * @returns {*}
     */
    function getListCategorieTopic(){
      var deferred = $q.defer();
      try {
        var params= {
          action:'searchListCategorieTopic'
        };
        TopicResource
          .post(params)
          .$promise
          .then(function onSuccess(json){
            deferred.resolve(MainService.convertJsonToModel(json, 'result', 'CategorieTopic', deferred));
          })
          .catch(function onError(err) {
            deferred.reject(err);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Permet d'update la catégorie de topic d'un topic
     * @param idTopic
     * @param idCategorieTopic
     * @returns {*}
     */
    function updateCategorieTopic(idTopic, idCategorieTopic){
      var deferred = $q.defer();
      try {
        var params= {
          action:'updateCategorieTopic',
          idTopic: idTopic,
          idCategorieTopic: idCategorieTopic
        };
        TopicResource
          .post(params)
          .$promise
          .then(function onSuccess(json){
            deferred.resolve(MainService.convertJsonToModel(json, 'topic', 'Topic', deferred));
          })
          .catch(function onError(err) {
            deferred.reject(err);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    function clotureOrReouvre() {
      var deferred = $q.defer();

      var topicIsBlocked = false;
      try {
        if (!arguments.length) throw new Error('Aucun paramètres !', arguments);

        var obj, idTopic, idContenu, type = 'topic', clotureOuReouverture = 'cloture', keepOpen = false;
        switch (arguments.length) {

          // Objet Topic ou Contenu
          case 1:
            obj = arguments[0];
            break;

          // Objet Topic ou Contenu et type (topic ou contenu)
          case 2:

            if (arguments[1] === 'topic' || arguments[1] === 'contenu') type = arguments[1];
            else throw new Error('2ème paramètre doit être "topic" ou "contenu" !', arguments);

            if (type === 'topic') idTopic = arguments[0];
            else if (type === 'contenu') idContenu = arguments[0];

            break;

          // Objet Topic ou Contenu et type (topic ou contenu)
          case 3:

            if (arguments[1] === 'topic' || arguments[1] === 'contenu') type = arguments[1];
            else throw new Error('2ème paramètre doit être "topic" ou "contenu" !', arguments);

            if (type === 'topic') idTopic = arguments[0];
            else if (type === 'contenu') idContenu = arguments[0];

            if (arguments[2] === 'cloture' || arguments[2] === 'reouverture') clotureOuReouverture = arguments[2];
            else throw new Error('3ème paramètre doit être "cloture" ou "reouverture" !', arguments);

            break;

          // Objet Topic ou Contenu et type (topic ou contenu) et keepOpen
          case 4:

            if (arguments[1] === 'topic' || arguments[1] === 'contenu') type = arguments[1];
            else throw new Error('2ème paramètre doit être "topic" ou "contenu" !', arguments);

            if (type === 'topic') idTopic = arguments[0];
            else if (type === 'contenu') idContenu = arguments[0];

            if (arguments[2] === 'cloture' || arguments[2] === 'reouverture') clotureOuReouverture = arguments[2];
            else throw new Error('3ème paramètre doit être "cloture" ou "reouverture" !', arguments);

            keepOpen = (!_.isNil(arguments[3]) && arguments[3]) ? true : false;

            break;

          default:
        }

        if (_.isObject(obj)) {
          if (obj.isModel) {
            if (obj.model === 'Reporting' || obj.model === 'Topic') idTopic = obj.getIdTopic();
            else if (obj.model === 'ContenuEvenement' || obj.model === 'Contenu') idContenu = obj.getIdContenu();
            else throw new Error('Objet Model inconnu !');
            topicIsBlocked = obj.isBlocked;
          }
          else if (obj.hasOwnProperty('idTopic')) {
            idTopic = obj.idTopic;
            if (obj.hasOwnProperty('topicIsBlocked')) topicIsBlocked = obj.topicIsBlocked;
          }
          else if (obj.hasOwnProperty('idContenu')) {
            idContenu = obj.idContenu;
            if (obj.hasOwnProperty('topicIsBlocked')) topicIsBlocked = obj.topicIsBlocked;
          }
          else if (obj.hasOwnProperty('idEvenement')) {
            idContenu = obj.idEvenement;
            if (obj.hasOwnProperty('topicIsBlocked')) topicIsBlocked = obj.topicIsBlocked;
          }
          else {
            throw new Error('Objet inconnu !');
          }
        }


        if (idTopic || idContenu) {

          var id = idTopic;
          if (idContenu) {
            type = 'contenu';
            id = idContenu;
          }

          var commentaire;
          var options = {
            type: 'warning'
          };

          ModalsService.loading('Chargement...');

          // Si array alors propose la cloture
          if (_.isArray(id)) {

            var textModal = COLLAB_CONF.MSG_CONFIRMATION_CLOTURE_TOPIC_BY_CREATEUR;

            if (clotureOuReouverture === 'cloture') {
              ModalsService
                .confirm('Cloturer/Demande de cloture ' + idTopic.length + ' dossier(s)', textModal, options)
                .then(function (modal) {

                  commentaire = angular.element('#commentaireCloture').val();

                  // Cloture l'événement
                  cloturerTopicWithListId(id, commentaire, type)
                    .then(function (retourTopic) {
                      deferred.resolve(retourTopic);
                    })
                    .catch(function (msg) {
                      throw new Error(msg);
                    })
                    .finally(function () {
                      if(!keepOpen) modal.close();
                    });
                })
                .catch(function(modal){
                  deferred.reject(modal);
                });
            }
            else if (clotureOuReouverture === 'reouverture') {
              ModalsService
                .confirm('Rouvrir/Demande de réouverture ' + id.length + ' dossier(s)', textModal, options)
                .then(function (modal) {

                  commentaire = angular.element('#commentaireCloture').val();

                  // Cloture l'événement
                  rouvrirTopicWithListId(id, commentaire, type)
                    .then(function (retourTopic) {
                      deferred.resolve(retourTopic);
                    })
                    .catch(function (msg) {
                      throw new Error(msg);
                    })
                    .finally(function () {
                      if(!keepOpen) modal.close();
                    });
                })
                .catch(function(modal){
                  deferred.reject(modal);
                });
            }
          }
          else if (_.isNumber(id)) {
            getDateClotureTopicForListId(id, type)
              .then(function (topic) {

                var isCreateur = UtilsService.userIsCreateur(topic);
                var isCloture = topic.isCloture();

                var titleModal = COLLAB_CONF.TITLE_MSG_CONFIRMATION_CLOTURE_TOPIC_BY_CREATEUR;
                var msgModal = '';
                var btnConfModal = COLLAB_CONF.BTN_CONFIRMATION_CLOTURE_TOPIC_BY_CREATEUR;

                if (!isCreateur && !topic.getTopicInformations().getAllowCloture()) {
                  titleModal = COLLAB_CONF.TITLE_MSG_CONFIRMATION_CLOTURE_TOPIC_BY_NO_CREATEUR;
                  msgModal = COLLAB_CONF.MSG_CONFIRMATION_CLOTURE_TOPIC_BY_NO_CREATEUR;
                  btnConfModal = COLLAB_CONF.BTN_CONFIRMATION_CLOTURE_TOPIC_BY_NO_CREATEUR;
                }

                if(!topic.topicIsBlocked()) msgModal += COLLAB_CONF.MSG_CONFIRMATION_CLOTURE_TOPIC_BY_CREATEUR;

                options.btnConfirmText = btnConfModal;

                // Si pas cloture
                if (!isCloture) {
                  //$timeout(function(){

                  ModalsService
                    .confirm(titleModal, msgModal, options)
                    .then(function (modal) {
                      commentaire = angular.element('#commentaireCloture').val();

                      // Cloture l'événement
                      cloturerTopicWithListId(id, commentaire, type)
                        .then(function (retourTopic) {
                          deferred.resolve(retourTopic);
                        })
                        .catch(function (msg) {
                          throw new Error(msg);
                        })
                        .finally(function () {
                          if(!keepOpen) modal.close();
                        });
                    })
                    .catch(function(modal){
                      deferred.reject(modal);
                    });

                }
                // Si réouverture
                else {
                  titleModal = COLLAB_CONF.TITLE_MSG_CONFIRMATION_REOUVRIR_TOPIC_BY_CREATEUR;
                  if (!isCreateur && !topic.getTopicInformations().getAllowCloture()) {
                    titleModal = COLLAB_CONF.TITLE_MSG_CONFIRMATION_REOUVRIR_TOPIC_BY_NO_CREATEUR;
                    msgModal = COLLAB_CONF.MSG_CONFIRMATION_REOUVRIR_TOPIC_BY_NO_CREATEUR;
                    btnConfModal = COLLAB_CONF.BTN_CONFIRMATION_REOUVRIR_TOPIC_BY_NO_CREATEUR;
                  }

                  options.btnConfirmText = btnConfModal;

                  ModalsService
                    .confirm(titleModal, msgModal, options)
                    .then(function (modal) {
                      commentaire = angular.element('#commentaireCloture').val();

                      // Réouvre l'événement
                      rouvrirTopicWithListId(id, commentaire, type)
                        .then(function (contenu) {
                          deferred.resolve(contenu);
                        })
                        .catch(function (msg) {
                          throw new Error(msg);
                        })
                        .finally(function () {
                          if(!keepOpen) modal.close();
                        });
                    })

                    .catch(function(modal){
                      deferred.reject(modal);
                    });
                }
              })
              .catch(function (msg) {
                throw new Error(msg);
              });
          }
          else throw new Error('Problème avec "id" : ' + id);
        }
        else throw new Error('Problème !! Pas d\'idContenu ni d\'idTopic !');
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        //ErreurCollabService.logErreur(e.message);
        deferred.reject(e.message);
      }

      return deferred.promise;
    }

    function getObjectWithListIdByType(listeId, type){
      var params = {};
      if (type === 'topic') {
        if (_.isArray(listeId)) {
          params.idTopic = listeId.map(function (topic) {
            if (_.isNumber(topic)) return topic;
            else if (_.isObject(topic) && topic.hasOwnProperty('idTopic')) return topic.idTopic;
          });
        }
        else if (_.isNumber(listeId)) params.idTopic = [listeId];
        else if (_.isObject(listeId) && listeId.hasOwnProperty('idTopic')) params.idTopic = [listeId.idTopic];
      }
      else if (type === 'contenu') {
        if (_.isArray(listeId)) {
          params.idContenu = listeId.map(function (topic) {
            if (_.isNumber(topic)) return topic;
            else if (_.isObject(topic) && topic.hasOwnProperty('idEvenement')) return topic.idEvenement;
            else if (_.isObject(topic) && topic.hasOwnProperty('idContenu')) return topic.idContenu;
          });
        }
        else if (_.isNumber(listeId)) params.idContenu = [listeId];
        else if (_.isObject(listeId) && listeId.hasOwnProperty('idEvenement')) params.idContenu = [listeId.idEvenement];
        else if (_.isObject(listeId) && listeId.hasOwnProperty('idContenu')) params.idContenu = [listeId.idContenu];
      }
      return params;
    }

    function resolveOrRejectTopicState(deferred, json, returnArray){
      var retour = null;
      if (returnArray) retour = [];

      if (json.nb !== '0' && json.TopicStates.length) {
        /*
        json.TopicStates.map(function(ts){
          delete ts.topic;
          return ts;
        });*/
        var TopicStates = MainService.$injector.get('TopicStates');
        if (returnArray) {
          for (var c = 0; c < json.TopicStates.length; c++) {
            retour.push(new TopicStates(json.TopicStates[c]));
          }
          deferred.resolve(retour);
        }
        else {
          deferred.resolve(new TopicStates(json.TopicStates[0]));
        }
      }
      else deferred.resolve(retour);
    }

    function resolveOrRejectTopic(deferred, json, returnArray){
      var retour = null;
      if (returnArray) retour = [];

      if (json.nb !== '0' && json.listTopic.length) {
        var Topic = MainService.$injector.get('Topic');
        if (returnArray) {
          for (var c = 0; c < json.listTopic.length; c++) {
            retour.push(new Topic(json.listTopic[c]));
          }
          deferred.resolve(retour);
        }
        else deferred.resolve(new Topic(json.listTopic[0]));
      }
      else deferred.resolve(retour);
    }

    /**
     * Permet de recupérer une liste de portefeuille qui ont créé des topics où le gorupeUser est dans un des contenus
     * @returns {Promise}
     */
    function searchListAgenceWorkWithMe(avecDemande) {
      var deferred = $q.defer();
      var params = {
        action: 'searchListAgenceWorkWithMe',
        avecDemande: avecDemande
      };

      TopicResource.post(params, function(json) {
        deferred.resolve(MainService.convertJsonToModel(json, 'result', 'Portefeuille', deferred));
      },function(error) {
        if(error.status===403) {
          deferred.reject(error);
        }
        else {
          ErreurCollabService.erreurServlet(error,'Problème avec retour servlet TopicService.searchListAgenceWorkWithMe()',deferred);
        }

      });
      return deferred.promise;
    }

    /**
     * Permet de recupérer une date de cloture pour un topic ou une liste de topic
     * @param listeId
     * @param type
     * @returns {Promise}
     */
    function getDateClotureTopicForListId(listeId, type) {
      var deferred = $q.defer();
      var params = {action: 'dateCloture'};

      params = _.merge(params, getObjectWithListIdByType(listeId, type));

      TopicResource.post(params, function (json) {
        resolveOrRejectTopic(deferred, json, _.isArray(listeId));
      }, function (error) {
        var msgErreur = 'Erreur (' + error.status + ') : ' + error.statusText;
        deferred.reject('Problème avec TopicService.getDateClotureTopicForListId(), ' + msgErreur);
      });
      return deferred.promise;
    }

    /**
     * Permet de cloturer des topics pas partir d'une liste de topic ou contenu
     * @param listeId
     * @param message
     * @param type
     * @returns {Promise}
     */
    function cloturerTopicWithListId(listeId, message, type) {

      var deferred = $q.defer();
      var params = {
        action: 'cloturerTopics'
      };

      if (!type) type = 'topic';

      params = _.merge(params, getObjectWithListIdByType(listeId, type));

      if (message) params.message = message;

      TopicResource.post(params, function (json) {
        resolveOrRejectTopicState(deferred, json, _.isArray(listeId));
      }, function (error) {
        var msgErreur = 'Erreur (' + error.status + ') : ' + error.statusText;
        deferred.reject('Problème avec TopicService.cloturerTopicWithListId(), ' + msgErreur);
      });
      return deferred.promise;
    }

    /**
     * Permet de réouvrir les topics d'une liste d'id ou idTopic
     * @param listeId
     * @param message
     * @param type
     * @returns {angular.IPromise<T>}
     */
    function rouvrirTopicWithListId(listeId, message, type) {

      var deferred = $q.defer();
      var params = {action: 'reouvrirTopics'};
      if (!type) type = 'topic';
      params = _.merge(params, getObjectWithListIdByType(listeId, type));

      if (message) params.message = message;

      TopicResource.post(params, function (json) {
        resolveOrRejectTopicState(deferred, json, _.isArray(listeId));
      }, function (error) {
        var msgErreur = 'Erreur (' + error.status + ') : ' + error.statusText;
        deferred.reject('Problème avec TopicService.rouvrirTopicWithListId(), ' + msgErreur);
      });
      return deferred.promise;
    }

    /**
     * Permet de cloturer et archiver une liste d'idTopic
     * @param listeId
     * @param message
     * @returns {Promise}
     */
    function clotureTopic(listeId, message){
      return cloturerTopicWithListId(listeId, message, 'topic');
    }

    function updateTopicState(listeId, action, type) {
      var deferred = $q.defer();
      var params = {action: action};
      if (!type) type = 'topic';
      params = _.merge(params, getObjectWithListIdByType(listeId, type));
      TopicResource.post(params, function (json) {
        resolveOrRejectTopicState(deferred, json, _.isArray(listeId));
      }, function (error) {
        var msgErreur = 'Erreur (' + error.status + ') : ' + error.statusText;
        deferred.reject('Problème avec TopicService.updateTopicState(), action: ' + action + ', ' + msgErreur);
      });
      return deferred.promise;
    }

    function archiveTopic(listeId, type) {
      return updateTopicState(listeId, 'archiveTopic', type);
    }

    function cacheTopic(listeId, type) {
      return updateTopicState(listeId, 'cacheTopic', type);
    }

    function normalizeTopic(listeId, type) {
      return updateTopicState(listeId, 'normalizeTopic', type);
    }

    function destroy() {
      ctrl.mapTagTypeToObject = {};
    }

    /**
     * Permet de faire une recherche sur collab par chaine de caractère
     * @param obj
     * @returns {angular.IPromise<T>}
     */
    function searchCollab(obj){
      var deferred = $q.defer();
      try {
        if(!_.isObject(obj)) throw new Error("Le paramètre doit être un objet");
        var searchQuery = (!_.isNil(obj.q)) ? obj.q : ((!_.isNil(obj.searchQuery)) ? obj.searchQuery : null);
        if(_.isNil(searchQuery)) throw new Error("Le paramètre doit contenir la propriété \"searchQuery\" ou \"q\"");

        var limit = (!_.isNil(obj.nbpp) && obj.nbpp != 0) ? obj.nbpp : ((!_.isNil(obj.limit) && obj.limit != 0) ? obj.limit : COLLAB_CONF.LIST_TOPIC_LIMIT_PAR_PAGE);
        var searchType = (!_.isNil(obj.type)) ? obj.type : ((!_.isNil(obj.searchType)) ? obj.searchType : COLLAB_CONF.TYPE_RECHERCHE_LIBRE_COLLAB_TITRE);
        var searchOrder = (!_.isNil(obj.tri)) ? obj.tri : ((!_.isNil(obj.searchOrder)) ? obj.searchOrder : COLLAB_CONF.DEFAULT_TRI_RECHERCHE_LIBRE_COLLAB);

        var params = {
          action: 'searchTopics',
          page: (!_.isNil(obj.page) && obj.page != 0) ? obj.page : COLLAB_CONF.LIST_TOPIC_PAGE_NUM,
          limit: limit,
          searchQuery: searchQuery,
          searchType: searchType,
          searchOrder: searchOrder,
        };

        if(!_.isNil($stateParams.idChannel))  params.idChannel = $stateParams.idChannel;
        if(!_.isNil($stateParams.etat))       params.etat = $stateParams.etat;

        TopicResource.post(params, function (json) {
          var obj = {};
          // Si il y a la propriété success alors nouveau service
          if(json.hasOwnProperty("success")){
            if(json.success) {
              obj = {
                nb: parseInt(json.nb),
                total: parseInt(json.total),
                listTopicStates: MainService.convertJsonToModel(json, 'TopicStates', 'TopicStates')
              };
            }
            else {
              obj = {
                success: false,
                error: true,
                message: json.message
              }
            }
          }
          // Sinon alors c'est soit une erreur soit l'ancien service ES dans le cas d'une recherche libre
          else {
            obj = {error: true};
            if(json.hasOwnProperty("topics") && json.hasOwnProperty("stats")){
              obj = {
                nb: parseInt(json.nb),
                total: parseInt(json.total),
                totalTime: parseInt(json.stats.totalTime),
                topics: MainService.convertJsonToModel(json, 'topics', 'Topic')
              };
            }
          }

          deferred.resolve(obj);
        }, function (error) {
          var msgErreur = 'Erreur (' + error.status + ') : ' + error.statusText;
          throw new Error('Problème avec TopicService.searchCollab(), ' + msgErreur);
        });
      }
      catch(e) {
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    angular.extend(TopicService.prototype, TopicStatesService);
  }
})();
