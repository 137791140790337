(function() {
	'use strict';

	/**
	 * @desc Bloc qui affiche les tags type ajouté au contenu evenement
	 * @example <evenement-bloc-tags-type></evenement-bloc-tags-type>
	 */

	angular
		.module('collaboreApp')
		.component('evenementBlocTagsType', {
			/*
			 require: {
			 contenuEvenementFormulaireCtrl: '^contenuEvenementFormulaire'
			 },*/
			templateUrl: 'app/topics/evenement/evenement-bloc-tags-type.component.html',
			bindings: {
				idContenu: '<',
				tags: '<',				// Liste Tags normal
				tagsType: '<',			// Liste Tags type
				currentModelEvent: '<',
				hideLibelle: '<',
				hideIcon: '<',
				useShortLibelle: '<',
        hideBtnPlusTags: '<',
        readOnly: '<',
				onUpdate: '&'		// <Function> Lors de la modification d'un ContenuMessage
			},
			controllerAs: 'evenementbloctagstypectrl',
			controller: EvenementBlocTagsTypeCtrl

		});
	/*@ngInject*/
	function EvenementBlocTagsTypeCtrl(COLLAB_CONF, $scope, TagService, UtilsService) {

		var ctrl = this;

		ctrl.viewAllTags = false;
		ctrl.showImmeuble = showImmeuble;
		ctrl.showLocataire = showLocataire;
		ctrl.showProprietaire = showProprietaire;
		ctrl.showCoproprietaire = showCoproprietaire;
		ctrl.showTagsAndProjet = showTagsAndProjet;
		ctrl.showBtnPlusTag = showBtnPlusTag;
		ctrl.onClickTogglePlusInfo = onClickTogglePlusInfo;
		ctrl.onUpdateTag = onUpdateTag;

		var types = [
      COLLAB_CONF.TAG_TYPE_PORTEFEUILLE,
      COLLAB_CONF.TAG_TYPE_MANDAT,
      COLLAB_CONF.TAG_TYPE_IMMEUBLE,
      COLLAB_CONF.TAG_TYPE_LOT,
      COLLAB_CONF.TAG_TYPE_PROPRIETAIRE,
      COLLAB_CONF.TAG_TYPE_LOCATAIRE,
      COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE,
      COLLAB_CONF.TAG_TYPE_PROJET,
      COLLAB_CONF.TAG_TYPE_CLIENT_CAPFUN,
      COLLAB_CONF.TAG_TYPE_LOT_MULTICAMP,
      COLLAB_CONF.TAG_TYPE_LIEU_COMMUN_CAPFUN,
      COLLAB_CONF.TAG_TYPE_PRESENCE,
      COLLAB_CONF.TAG_TYPE_TYPE,
      COLLAB_CONF.TAG_TYPE_STATUT,
      COLLAB_CONF.TAG_TYPE_TECHNICIEN,
      COLLAB_CONF.TAG_TYPE_MENAGE
    ];

		for (var t = 0; t < types.length; t++) {
			ctrl['tag' + types[t]] = false;
		}

		ctrl.$onInit = function () {

			$scope.$watchCollection('evenementbloctagstypectrl.tagsType', function (tags) {
				var tagParType = TagService.getTagForListType(tags, types);
				var nb = 0;
				for (var t = 0; t < types.length; t++) {
					ctrl['tag' + types[t]] = false;
				}
				if (tagParType) {
					angular.forEach(tagParType, function (tag, type) {
						ctrl['tag' + type] = tag;
						nb++;
					});
				}

				if((!ctrl.idContenu && nb) || ctrl.hideBtnPlusTags) {
					ctrl.viewAllTags = true;
				}

			});


			// Ecoute le changement de modèle
			$scope.$watchCollection('evenementbloctagstypectrl.currentModelEvent', function(newVal){
				ctrl.currentModelEvent = newVal;
			});

			/*
			 if(!ctrl.idContenu){
			 ctrl.viewAllTags = true;
			 }*/
		};

		function showImmeuble() {
			if((UtilsService.isICS() || UtilsService.isCapfun()) && !ctrl.idContenu) {
				return false;
			}
			if (ctrl.tagImmeuble) {
				return true;
			}
			else {
				return false;
			}
		}

		function showLocataire() {
			if((UtilsService.isICS() || UtilsService.isCapfun()) && !ctrl.idContenu) {
				return false;
			}
			//if (ctrl.tagLocataire || (!ctrl.idContenu && !ctrl.tagLocataire)) {
			if (ctrl.tagLocataire) {
				return true;
			}
			else {
				return false;
			}
		}

		function showProprietaire() {
			if((UtilsService.isICS() || UtilsService.isCapfun()) && !ctrl.idContenu) {
				return false;
			}
			// Affiché sir il y a un tag propriétaire OU si il y a pas de contenu et pas de tab propriétaire et pas de tag copro
			//if (ctrl.tagProprietaire || (!ctrl.idContenu && !ctrl.tagProprietaire && !ctrl.tagCoproprietaire)) {
			if (ctrl.tagProprietaire) {
				return true;
			}
			// Si le tag coproprietaire est rempli et pas le proprietaire alors on cache le copro
			if (ctrl.tagCoproprietaire && !ctrl.tagProprietaire) {
				return false;
			}
		}

		function showCoproprietaire() {
			if((UtilsService.isICS() || UtilsService.isCapfun()) && !ctrl.idContenu) {
				return false;
			}

			//if (ctrl.tagCoproprietaire || (!ctrl.idContenu && !ctrl.tagCoproprietaire && !ctrl.tagProprietaire)) {
			if (ctrl.tagCoproprietaire) {
				return true;
			}
			// Si le tag proprietaire est rempli et pas le copro alors on cache le copro
			if (ctrl.tagProprietaire && !ctrl.tagCoproprietaire) {
				return false;
			}
		}

		function showTagsAndProjet(){
			if(!ctrl.idContenu || UtilsService.isICS() || UtilsService.isCapfun() || ctrl.viewAllTags) {
				return true;
			}
			return false;
		}

		function showBtnPlusTag() {
			if(((UtilsService.isICS() || UtilsService.isCapfun()) && (!ctrl.idContenu || !ctrl.tagsType.length)) || ctrl.hideBtnPlusTags) {
				return false;
			}
			return (ctrl.tagsType.length);
		}

		function onClickTogglePlusInfo() {
			ctrl.viewAllTags = !ctrl.viewAllTags;
		}

		function onUpdateTag(objUpdate) {
			if (ctrl.onUpdate) {
				ctrl.onUpdate({'objUpdate': objUpdate});
			}
		}


	}
})();


