(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.GroupeAssociationService
   * @description
   * # GroupeAssociationService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('GroupeAssociationService',
      GroupeAssociationServiceController
    );

  /** @ngInject */
  function GroupeAssociationServiceController($q, $injector, MainService, GroupeAssociationResource, ModalsService) {
    var _this = this;

    _this.getListIdGroupe = getListIdGroupe;
    _this.getListIdGroupeAssociation = getListIdGroupeAssociation;
    _this.associeListIdGroupeWithListIdGroupeAssocie = associeListIdGroupeWithListIdGroupeAssocie;
    _this.searchListGroupeAssociation = searchListGroupeAssociation;
    _this.deleteGroupeAssociation = deleteGroupeAssociation;
    _this.removeListGroupeFromListAssociation = removeListGroupeFromListAssociation;

    _this.extractListCollaborateurFromListGroupeAssociation = extractListCollaborateurFromListGroupeAssociation;
    _this.getListGroupeFromAssociation = getListGroupeFromAssociation;

    /**
     * Permet de récupérer une liste d'idGroupe
     * @param listObject - Array<Groupe> | Groupe | idGroupe | listIdGroupe | Array<GroupeAssociationGroupe> | GroupeAssociationGroupe - On recup les idGroupe
     * @returns {[]}
     */
    function getListIdGroupe(listObject){
      var listIdGroupe = [];
      if(_.isArray(listObject)) {
        listIdGroupe = listObject.reduce(function(accum, item){
          var id = item;
          if(_.isObject(id)) {
            if(id.isGroupe) id = id.getIdGroupe();
            else if(id.isGroupeAssociationGroupe) id = id.getGroupe().getIdGroupe();
          }
          if(!_.isNil(id)) accum.push(id);
          return accum;
        }, []);
      }
      else if(_.isObject(listObject)) {
        if(listObject.isGroupe) listIdGroupe.push(listObject.getIdGroupe());
        else if(listObject.isGroupeAssociationGroupe) listIdGroupe.push(listObject.getGroupe().getIdGroupe());
      }
      else if(_.isNumber(listObject)) listIdGroupe.push(listObject);

      return listIdGroupe;
    }

    /**
     * Permet de récupérer les idGroupeAssociation
     * @param listObject - Array<Groupe> | Groupe | idGroupeAssociation | listIdGroupeAssociation | Array<GroupeAssociation> | GroupeAssociation - On recup les idGroupeAssociation
     * @returns {*}
     */
    function getListIdGroupeAssociation(listObject){
      var listIdGroupeAssociation = [];
      if(_.isArray(listObject)){
        listIdGroupeAssociation = listObject.reduce(function(accum, item){
          var id = item;
          if(_.isObject(id)){
            if(id.isGroupe) id = id.getGroupeAssociation().getIdGroupeAssociation();
            else if(id.isGroupeAssociation) id = id.getIdGroupeAssociation();
          }
          if(!_.isNil(id)) accum.push(id);
          return accum;
        }, []);
      }
      else if(_.isObject(listObject)) {
        if(listObject.isGroupe) listIdGroupeAssociation.push(listObject.getGroupeAssociation().getIdGroupeAssociation());
        else if(listObject.isGroupeAssociation) listIdGroupeAssociation.push(listObject.getIdGroupeAssociation());
      }
      else if(_.isNumber(listObject)) listIdGroupeAssociation.push(listObject);

      return listIdGroupeAssociation;
    }

    function postServlet(action, obj, model) {
      var deferred = $q.defer();
      try {
        if (!action) throw new Error('Le paramètre "action" n\'existe pas !');

        if (!_.isObject(obj))
          throw new Error('Le paramètre "obj" doit être un objet  !');

        if(_.isNil(model)) model = 'GroupeAssociation';

        /*
        if (_.isObject(obj) && !obj.hasOwnProperty('listObjJSON') && !obj.hasOwnProperty('objJSON') && !obj.hasOwnProperty('idTopic'))
          throw new Error('Le paramètre "obj" doit avoir un objJSON OU listObjJSON OU idTopic !');

        if(obj.hasOwnProperty('listObjJSON')) obj.listObjJSON = angular.toJson(obj.listObjJSON);
        if(obj.hasOwnProperty('objJSON')) obj.objJSON = angular.toJson(obj.objJSON);*/

        var sendParams = _.merge({}, obj);
        sendParams.action = action;
        GroupeAssociationResource
          .post(sendParams, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, ['result', 'groupes', 'nb'], model, deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Permet d'associer une liste d'idGroupe (collaborateur) à une liste d'idGroupe associé (immeuble ou prop)
     * @param idPortefeuille
     * @param listIdGroupe = Groupe collaborateur - Array<Groupe> | Groupe | idGroupe | listIdGroupe - On recup les idGroupe
     * @param listeIdGroupeAssocie = Groupe associé (immeuble et prop) - Array<Groupe> | Groupe | idGroupe | listIdGroupe - On recup les idGroupe
     * @returns {angular.IPromise<T>}
     */
    function associeListIdGroupeWithListIdGroupeAssocie(idPortefeuille, typeGroupe, listIdGroupe, listeIdGroupeAssocie, withAllCollaborateur) {
      var deferred = $q.defer();
      try {
        if(_.isNil(typeGroupe)) throw new Error('typeGroupe obligatoire !');
        var obj = {
          searchTypeAssociation: typeGroupe,
          listIdGroupe: getListIdGroupe(listIdGroupe),
          listIdGroupeAssocie: getListIdGroupe(listeIdGroupeAssocie),
          idPortefeuille: idPortefeuille,
          withAllCollaborateur: withAllCollaborateur
        };

        if(!obj.listIdGroupe.length) throw new Error('Aucun idGroupe trouvé !');
        if(!obj.listIdGroupeAssocie.length) throw new Error('Aucun idGroupeAssocie trouvé !');

        obj.listIdGroupe = angular.toJson(obj.listIdGroupe);
        obj.listIdGroupeAssocie = angular.toJson(obj.listIdGroupeAssocie);

        postServlet('associeListIdGroupeWithListIdGroupeAssocie', obj, 'Groupe')
          .then(function (ret) {
            deferred.resolve(ret);
          })
          .catch(function (reason) {
            ModalsService.alertErreur(reason.message);
            deferred.reject(reason.message);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Recupère une liste de GroupeAssociation pour un idPortfeuille, le groupe identifié par le token et un type
     */
    /*function searchListGroupeAssociation(idPortefeuille, type) {
      var deferred = $q.defer();
      try {
        var obj = {
          idPortefeuille: idPortefeuille,
          versionAdmin: _this.versionAdmin
        };
        if(type === 'immeuble') {
          GroupeAssociationResource
            .searchListGroupeAssociationImmeuble(obj, function (json) {
              deferred.resolve(MainService.convertJsonToModel(json, 'result', 'GroupeAssociationImmeuble', deferred));
            }, function (msg) {
              deferred.reject(msg);
            });
        }
        else if(type === 'proprietaire') {
          GroupeAssociationResource
            .searchListGroupeAssociationProprietaire(obj, function (json) {
              deferred.resolve(MainService.convertJsonToModel(json, 'result', 'GroupeAssociationProprietaire', deferred));
            }, function (msg) {
              deferred.reject(msg);
            });
        }
        else deferred.reject("Le type n'existe pas");
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }*/
    function searchListGroupeAssociation(idPortefeuille, type, val, withAllCollaborateur) {
      var deferred = $q.defer();
      try {
        var obj = {
          idPortefeuille: idPortefeuille,
          searchTypeAssociation: type,
          searchValGroupeAssocie: val,
          withAllCollaborateur: withAllCollaborateur
        };
        GroupeAssociationResource
          .searchListGroupeAssociation(obj, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, 'result', 'GroupeAssociation', deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Permet de supprimer une liste de GroupeAssocation par leur id
     * @param listIdGroupeAssociation - Array<Groupe> | Groupe | Array<GroupeAssociation> | GroupeAssociation | listIdGroupeassociation | idGroupeAssociation
     * @returns {angular.IPromise<any>}
     */
    function deleteGroupeAssociation(typeGroupe, listOrIdGroupeAssociation){
      var deferred = $q.defer();
      try {
        var obj = {
          action: 'deleteGroupeAssociation',
          searchTypeAssociation: typeGroupe,
          listIdGroupeAssociation: getListIdGroupeAssociation(listOrIdGroupeAssociation)
        };
        if(!obj.listIdGroupeAssociation.length) throw new Error('Aucun idGroupeAssociation trouvé !');
        if(_.isNil(typeGroupe)) throw new Error('typeGroupe obligatoire !');

        obj.listIdGroupeAssociation = angular.toJson(obj.listIdGroupeAssociation);
        GroupeAssociationResource
          .post(obj, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, 'groupes', 'Groupe', deferred));
            //MainService.getOrResolveNbSuccess(json, deferred);
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }

      return deferred.promise;
    }

    /**
     * Permet de retirer des association à un groupe
     * @param listIdGroupe - Groupe | GroupeAssociationGroupe | idGroupe | Array<Groupe> | Array<GroupeAssociationGroupe> - Ce sont les idGroupe qui sont récup
     * @param listOrIdGroupeAssociation - Array<Groupe> | Groupe | Array<GroupeAssociation> | GroupeAssociation | listIdGroupeassociation | idGroupeAssociation - Ce sont les idGroupeAssociation qui sont récup
     * @returns {angular.IPromise<T>}
     */
    function removeListGroupeFromListAssociation(typeGroupe, listIdGroupe, listOrIdGroupeAssociation, withAllCollaborateur){
      var deferred = $q.defer();
      try {
        if(_.isNil(listIdGroupe)) throw new Error('Le paramètre "listIdGroupe" est null  !');
        if(_.isNil(typeGroupe)) throw new Error('typeGroupe obligatoire !');
        var obj = {
          action: 'removeListGroupeFromListAssociation',
          searchTypeAssociation: typeGroupe,
          withAllCollaborateur: withAllCollaborateur,
          listIdGroupe: getListIdGroupe(listIdGroupe),
          listIdGroupeAssociation: getListIdGroupeAssociation(listOrIdGroupeAssociation)
        };

        if(!obj.listIdGroupe.length) throw new Error('Aucun idGroupe trouvé !');
        if(!obj.listIdGroupeAssociation.length) throw new Error('Aucun idGroupeAssociation trouvé !');

        obj.listIdGroupe = angular.toJson(obj.listIdGroupe);
        obj.listIdGroupeAssociation = angular.toJson(obj.listIdGroupeAssociation);
        GroupeAssociationResource
          .post(obj, function (json) {
            // MainService.getOrResolveNbSuccess(json, deferred);
            deferred.resolve(MainService.convertJsonToModel(json, 'groupes', 'Groupe', deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }

      return deferred.promise;
    }

    /**
     * Permet de récupérer une liste de Groupe (collaborateurs) venant d'une liste de groupe association
     * @param associations
     * @returns {[]}
     */
    function extractListCollaborateurFromListGroupeAssociation(associations){
      var listGroupe = [];
      if(_.isArray(associations) && associations.length){
        listGroupe = associations.reduce(function(listGroupe, groupeAssocation) {
          var listGroupeAssocGroupe = groupeAssocation.getListGroupeAssociationGroupe();
          if(_.isArray(listGroupeAssocGroupe) && listGroupeAssocGroupe.length){
            listGroupe = listGroupeAssocGroupe.reduce(function(accum, groupeAssocationGroupe) {
              accum.push(groupeAssocationGroupe.getGroupe());
              return accum;
            }, listGroupe);
          }
          return listGroupe;
        }, []);
      }
      return listGroupe;
    }

    /**
     * Permet de récupérer la liste des groupes liés à une association trouvé par rapport à la valeur du groupe associé (num immeuble ou num pers)
     * @param idPortefeuille
     * @param typeAssociation
     * @param valAssociation
     * @returns {angular.IPromise<T>}
     */
    function getListGroupeFromAssociation(idPortefeuille, typeAssociation, valAssociation){
      var deferred = $q.defer();
      try {
        if(_.isNil(idPortefeuille)) throw new Error('idPortefeuille obligatoire !');
        if(_.isNil(typeAssociation)) throw new Error('typeAssociation obligatoire !');
        if(_.isNil(valAssociation)) throw new Error('valAssociation obligatoire !');

        searchListGroupeAssociation(idPortefeuille, typeAssociation, valAssociation)
          .then(function(associations){
            deferred.resolve(extractListCollaborateurFromListGroupeAssociation(associations));
          })
          .catch(function(err){
            deferred.reject(err);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }

      return deferred.promise;
    }
  }
})();
