(function() {

    'use strict';

    /**
     * @desc Permet de selectionner une catégorie de topic pour un topic
     * @example <select-categorie-topic></select-categorie-topic>
     */
    angular
        .module('collaboreApp')
        .component('selectCategorieTopic',{
            templateUrl: 'app/topics/select-categorie-topic/select-categorie-topic.component.html',
            controllerAs: 'selectcategorietopicctrl',
            bindings: {
                mode: '@',                // 'inline' ou 'popover'
                popoverBtnClass: '@',
                popoverIconClass: '@',
                popoverTooltip: '@',
                popoverPlacement: '@',
                popoverClass: '@',
                popoverAppendToBody: '<',

                multiCheck: '<',
                editCategorieTopic: '<',
                idTopic: '<',
                categorieTopic: '<',
                onEmit: '&'
            },
            controller: SelectCategorieTopicCtrl
        });

    /** @ngInject */
    function SelectCategorieTopicCtrl($scope, TopicService, TopicManagerService){
        var _this = this;
        var listenerClose = null;

        _this.listCategorieTopicChecked = [];     // Liste privée des catégories topic cochées

        _this.categorieTopicIsDisabled = categorieTopicIsDisabled;
        _this.onChooseCategorie = onChooseCategorie;
        _this.onClickSave = onClickSave;
        _this.onClickClose = onClickClose;

        _this.$onInit = function() {
            if(_.isNil(_this.mode))                 _this.mode = 'popover';
            if(_.isNil(_this.popoverTooltip))       _this.popoverTooltip = 'Catégories topic';
            if(_.isNil(_this.popoverPlacement))     _this.popoverPlacement = 'auto';
            if(_.isNil(_this.popoverClass))         _this.popoverClass = 'popover-collabore popover-lg';
            if(_.isNil(_this.popoverAppendToBody))  _this.popoverAppendToBody = true;
            if(_.isNil(_this.usedForEditCategorie)) _this.usedForEditCategorie = false;

            if(_this.mode === 'popover') {
                $scope.$watch('selectcategorietopicctrl.popoverIsOpen', function(newVal){
                    if(newVal) init();
                });
            }
            else init();
        };

        _this.$onChanges = function(changes){
            if(_.isObject(changes.idTopic) && !changes.idTopic.isFirstChange()) {
                _this.idTopic = changes.idTopic.currentValue;
            }

            if(_.isObject(changes.multiCheck) && !changes.multiCheck.isFirstChange()) {
                _this.multiCheck = changes.multiCheck.currentValue;
            }

            if(_.isObject(changes.usedForEditCategorie) && !changes.usedForEditCategorie.isFirstChange()) {
                _this.usedForEditCategorie = changes.usedForEditCategorie.currentValue;
            }

            if(_.isObject(changes.categorieTopic) && !changes.categorieTopic.isFirstChange()) {
                _this.categorieTopic = changes.categorieTopic.currentValue;
                initListCategorieTopicChecked(_this.categorieTopic);
            }

            if(_.isObject(changes.editCategorieTopic) && !changes.editCategorieTopic.isFirstChange()) {
                _this.editCategorieTopic = changes.editCategorieTopic.currentValue;
            }
        };

        _this.$onDestroy = function(){
            if(!_.isNil(listenerClose)) listenerClose();
        };

        /**
         * Permet d'init le composant
         */
        function init(){

            _this.listCategorieTopic = [];
            if ((_this.mode === 'popover' && _this.popoverIsOpen) || _this.mode === 'inline') {
                // Recupération de la liste des categorie de topic
                _this.loadingCategorieTopic = true;
                TopicService
                    .getListCategorieTopic()
                    .then(function onSuccess(list){
                        _this.listCategorieTopic = list;

                        if(_.isObject(_this.categorieTopic)){
                            initListCategorieTopicChecked(_this.categorieTopic);
                        }
                        // Si edition d'une categorie avec une categorie parent, il faut checker la categorie parent
                        else  if(_.isObject(_this.editCategorieTopic) && !_.isNil(_this.editCategorieTopic.idCategorieTopicParent)){
                            initListCategorieTopicChecked(_this.editCategorieTopic.idCategorieTopicParent);
                        }
                    })
                    .finally(function onFinally(){
                        _this.loadingCategorieTopic = false;
                    });

                listenerClose = $scope.$on('closePopoverCategorieTopic', function(){
                    close();
                });
            }
        }

        /**
         * Permet de recréer la liste d'objet catégorie topic checked par rapport à une liste d'idCategorieTopic ou d'objet categorieTopic passée en paramètre
         * @param list
         */
        function initListCategorieTopicChecked(list){
            var listIdCategorieChecked = [];

            if(_.isArray(list) && list.length) {
                // On va construire une liste d'idCategorieTopic par rapport à la list passé en paramètre
                for(var i = 0; i < list.length; i++){
                    if(_.isString(list[i]) || _.isNumber(list[i])) {
                        listIdCategorieChecked.push(parseInt(list[i]));
                    }
                    else if(_.isObject(list[i]) && !_.isNil(list[i].idCategorieTopic)) {
                        listIdCategorieChecked.push(list[i]);
                    }
                }
            }
            else if(_.isObject(list)) {
                if(_.isString(list) || _.isNumber(list)) {
                    listIdCategorieChecked.push(parseInt(list));
                }
                else if(_.isObject(list) && !_.isNil(list.idCategorieTopic)) {
                    listIdCategorieChecked.push(list);
                }
            }
            else if(_.isNumber(list)) {
                listIdCategorieChecked.push(parseInt(list));
            }

            // Si il y a des idCategorieTopic et des categorieTopic dans la liste retourné par la servlet
            // il faut reconstruire une liste d'objet categorieTopic selectionnée par rapport à la liste d'idCategorieTopic
            if(listIdCategorieChecked.length && _.isArray(_this.listCategorieTopic) && _this.listCategorieTopic.length) {
                // Supprime tous les categorie topic checked
                resetCheckedCategorieTopic(_this.listCategorieTopic);
                _this.listCategorieTopicChecked = [];
                for(var j = 0; j < listIdCategorieChecked.length; j++) {
                    recurseInitListCategorieTopicChecked(_this.listCategorieTopic, listIdCategorieChecked[j]);
                }
            }
            // checkUncheckCategorieAndSoon
        }

        /**
         * Permet de reconstruire la liste des categories de topic checked
         * @param listeCategorieTopic
         * @param idCategorieTopic
         */
        function recurseInitListCategorieTopicChecked(listeCategorieTopic, idCategorieTopic) {

            var tmp = [];
            if (_.isArray(listeCategorieTopic) && listeCategorieTopic.length) {

                for (var l = 0; l < listeCategorieTopic.length; l++) {
                    if (!_.isNil(idCategorieTopic)) {
                        var found = false;
                        if((_.isString(idCategorieTopic) || _.isNumber(idCategorieTopic)) && parseInt(listeCategorieTopic[l].idCategorieTopic) === parseInt(idCategorieTopic)) {
                            found = true;
                        }
                        else if(_.isObject(idCategorieTopic) && parseInt(listeCategorieTopic[l].idCategorieTopic) === parseInt(idCategorieTopic.idCategorieTopic)) {
                            found = idCategorieTopic;
                        }

                        if(found) {
                            if(_.isObject(found)) {
                                _this.listCategorieTopicChecked.push(found);
                                listeCategorieTopic[l] = _.merge(listeCategorieTopic[l], found);
                                //if(found.checkedOrange) listeCategorieTopic[l].checkedOrange = found.checkedOrange;
                                //if(found.checkedBlue) listeCategorieTopic[l].checkedBlue = found.checkedBlue;
                            }
                            else {
                                _this.listCategorieTopicChecked.push(angular.copy(listeCategorieTopic[l]));
                            }
                            listeCategorieTopic[l].checked = true;
                            break;
                        }

                    } else if (_.isNil(idCategorieTopic) && listeCategorieTopic[l].checked) {
                        tmp.push(angular.copy(listeCategorieTopic[l]));
                    }

                    if (_.isArray(listeCategorieTopic[l].getListEnfantsCategorieTopic()) && listeCategorieTopic[l].getListEnfantsCategorieTopic().length) {
                        tmp = _.concat(tmp, recurseInitListCategorieTopicChecked(listeCategorieTopic[l].getListEnfantsCategorieTopic(), idCategorieTopic));
                    }
                }
            }

            return tmp;
        }


        /**
         * Lors du clique sur une checkbox de categorie
         * @param cat
         */
        function onChooseCategorie(currentCategorie){

            //console.log(currentCategorie.checked);
            //console.log('orange', currentCategorie.checkedOrange);
            //console.log('blue', currentCategorie.checkedBlue);
            // Si pas de multi selection alors décoche les cases sauf celle selectionné
            if(_.isNil(_this.multiCheck) || !_this.multiCheck){
                resetCheckedCategorieTopic(_this.listCategorieTopic, currentCategorie);
            }
            else {
                checkUncheckCategorieAndSoon(currentCategorie);
            }

            _this.listCategorieTopicChecked = recurseInitListCategorieTopicChecked(_this.listCategorieTopic);

            if(_.isNil(_this.idTopic)){
                emitCategorieTopic();
            }
        }

        function checkUncheckCategorieAndSoon(currentCategorie){
            /*
            console.log('checked', currentCategorie.checked);
            console.log('checkedBlue', currentCategorie.checkedBlue);
            console.log('checkedOrange', currentCategorie.checkedOrange);
            */
            // Si on vien de cocher et que la case n'était pas coché
            if(currentCategorie.checked && !currentCategorie.checkedBlue){
                checkCategorieAndSoon(currentCategorie);
            }
            // Si la case était coché en orange, alors on décoche tous les enfants et on coche en bleu cette case
            else if (currentCategorie.checkedOrange){
                uncheckCategorieAndSoon(currentCategorie);
                currentCategorie.checked = true;
                currentCategorie.checkedBlue = true;
            }
            // Si la case est coché en bleu alors on décoche
            else if(currentCategorie.checkedBlue){
                uncheckCategorieAndSoon(currentCategorie);
            }
        }

        /**
         * Permet de décocher les cases sauf celle actuellement selectionnée
         * @param listCategorieTopic
         * @param currentCategorieChecked
         */
        function resetCheckedCategorieTopic(listCategorieTopic, currentCategorieChecked){
            if(_.isArray(listCategorieTopic) && listCategorieTopic.length){
                for(var i = 0; i < listCategorieTopic.length; i++){
                    if(_.isObject(currentCategorieChecked) && listCategorieTopic[i].idCategorieTopic !== currentCategorieChecked.idCategorieTopic) {
                        delete listCategorieTopic[i].checked;
                    }
                    if(_.isArray(listCategorieTopic[i].getListEnfantsCategorieTopic()) && listCategorieTopic[i].getListEnfantsCategorieTopic().length){
                        resetCheckedCategorieTopic(listCategorieTopic[i].getListEnfantsCategorieTopic(), currentCategorieChecked);
                    }
                }
            }
        }



        /**
         * Permet d'ajouter/retirer une categorie de la liste par rapport à son état
         * @param currentCat
         */
        /*
        function addOrRemoveCategorie(currentCat){
            if(!_.isObject(currentCat)) throw new Error('currentCat doit être un objet !');

            // Si coché alors on ajoute dans la liste
            if(currentCat.checked) addCategorieInListChecked(currentCat);
            // Sinon on retire de la liste
            else removeCategorieFromListChecked(currentCat);
        }*/

        /**
         * Permet d'ajouter une catégorie dans la liste si n'existe pas déjà
         * @param currentCat
         */
        /*
        function addCategorieInListChecked(currentCat){

            if(!_.isObject(currentCat)) throw new Error('currentCat doit être un objet !');
            if(!_.isArray(_this.listCategorieTopicChecked) || !_this.multiCheck || _.isNil(_this.multiCheck)) _this.listCategorieTopicChecked = [];

            var exist = false;
            // Si il y a déjà des entrées dans la liste
            if(_this.multiCheck && _this.listCategorieTopicChecked.length) {
                for (var i = 0; i < _this.listCategorieTopicChecked.length; i++) {
                    if (_this.listCategorieTopicChecked[i].idCategorieTopic === currentCat.idCategorieTopic) {
                        exist = true;
                        break;
                    }
                }
            }
            if(!exist) {
                var catcopy = angular.copy(currentCat);
                delete catcopy.checked;
                _this.listCategorieTopicChecked.push(catcopy);
            }
        }*/

        /**
         * Permet de supprimer une catégorie de la liste si existe
         * @param currentCat
         */
        /*
        function removeCategorieFromListChecked(currentCat) {
            if(_.isNil(_this.multiCheck) || !_this.multiCheck) {
                _this.listCategorieTopicChecked = [];
                return;
            }
            if (!_.isObject(currentCat)) throw new Error('currentCat doit être un objet !');

            if (_this.listCategorieTopicChecked.length) {
                var listTmp = [];
                for (var i = 0; i < _this.listCategorieTopicChecked.length; i++) {
                    if (_this.listCategorieTopicChecked[i].idCategorieTopic !== currentCat.idCategorieTopic) {
                        listTmp.push(_this.listCategorieTopicChecked[i]);
                    }
                }
                _this.listCategorieTopicChecked = listTmp;
                listTmp = null;
            }
        }
        */

        /**
         * Permet de cocher une case ainsi que ses enfants
         * @param list
         */
        function checkCategorieAndSoon(list){
            if (_.isArray(list) && list.length) {
                for (var i = 0; i < list.length; i++) {
                    list[i].checked = true;
                    list[i].checkedOrange = true;
                    checkCategorieAndSoon(list[i].getListEnfantsCategorieTopic());
                }
            }
            else if(_.isObject(list)) {
                list.checked = true;
                list.checkedOrange = true;
                checkCategorieAndSoon(list.getListEnfantsCategorieTopic());
            }
        }

        /**
         * Permet de décocher une case ainsi que ses enfants
         * @param list
         */
        function uncheckCategorieAndSoon(list){
            if (_.isArray(list) && list.length) {
                for (var i = 0; i < list.length; i++) {
                    delete list[i].checked;
                    delete list[i].checkedOrange;
                    delete list[i].checkedBlue;
                    uncheckCategorieAndSoon(list[i].getListEnfantsCategorieTopic());
                }
            }
            else if(_.isObject(list)) {
                delete list.checked;
                delete list.checkedOrange;
                delete list.checkedBlue;
                uncheckCategorieAndSoon(list.getListEnfantsCategorieTopic());
            }
        }

        /**
         * Lors du clique sur le bouton pour sauvegarder les changements. utilisé dans le cas ou le topic existe déjà
         */
        function onClickSave(){
            if(!_this.usedForEditCategorie) {
                _this.loadingCategorieTopic = true;
                var idCategorie = null;
                if(_.isArray(_this.listCategorieTopicChecked) && _this.listCategorieTopicChecked.length) {
                  idCategorie = _this.listCategorieTopicChecked[0].idCategorieTopic;
                }
                TopicService
                    .updateCategorieTopic(_this.idTopic, idCategorie)
                    .then(function onSuccess(topic){
                        TopicManagerService.currentTopicStates.topic = topic;
                        emitCategorieTopic();
                        close();
                    })
                    .finally(function onFinally(){
                        _this.loadingCategorieTopic = false;
                    });
            }
        }

        function onClickClose(){
            close();
        }


        function close(){
            _this.popoverIsOpen = false;
        }

        function categorieTopicIsDisabled(cat){
            // Si pas utilisé par l'édition de catégorie topic de l'admin
            if(!_.isObject(_this.editCategorieTopic)) return;

            // Si la categorie de topic actuellement en cours de modification et la même que celle passé en paramètre
            if(_this.editCategorieTopic.idCategorieTopic === cat.idCategorieTopic) {
                return true;
            }

            // Si la categorie qui est modifié actuellement à des enfants on ne peux pas choisir un de ses enfants en parent
            return recurseCategorieTopicIsDisabled(cat, _this.editCategorieTopic);
        }

        function recurseCategorieTopicIsDisabled(cat, currentCategorieTopic){
            var disabled = false;
            if(_.isObject(currentCategorieTopic) && currentCategorieTopic.listEnfantsCategorieTopic && currentCategorieTopic.listEnfantsCategorieTopic.length) {
                for(var c = 0; c < currentCategorieTopic.listEnfantsCategorieTopic.length; c++) {
                    if(cat.idCategorieTopic === currentCategorieTopic.listEnfantsCategorieTopic[c].idCategorieTopic) {
                        disabled = true;
                    }
                    else {
                        disabled = recurseCategorieTopicIsDisabled(cat, currentCategorieTopic.listEnfantsCategorieTopic[c]);
                    }
                    if(disabled) break;
                }
            }
            return disabled;
        }


        function emitCategorieTopic(){
            if(_this.multiCheck) {
                emit({
                    action: 'selectListCategorieTopic',
                    listCategorieTopicChecked: _this.listCategorieTopicChecked
                });
            }
            else {
                emit({
                    action: 'selectCategorieTopic',
                    categorieTopic: (_this.listCategorieTopicChecked.length) ? _this.listCategorieTopicChecked[0] : null
                });
            }

        }

        /**
         * Permet d'emit des infos vers le composant parent
         * @param objet
         */
        function emit(objet){
            _this.onEmit({
                obj: objet
            });
        }
        /*
        Object.defineProperty(DestinataireController.prototype,
            'popoverIsOpen', {
                get: function () {
                    return this._popoverIsOpen;
                },
                set: function (newValue) {
                    this._popoverIsOpen = newValue;

                    //Call method on update
                    this.onPopoverIsOpenChange(this._popoverIsOpen);
                },
                enumerable: true,
                configurable: true
            });*/
    }
})();
