'use strict';

/**
 * Permet de recupérer la taille de la fenetre
 */
angular.module('collaboreApp').factory('dimensionService', ['$window', '$document',function ($window, $document) {
	var DimensionService = {};

	DimensionService.getWindowDimension = function() {
		var w = $window,
			d = $document[0],
			e = d.documentElement,
			g = d.getElementsByTagName('body')[0],
			x = w.innerWidth || e.clientWidth || g.clientWidth,
			y = w.innerHeight|| e.clientHeight|| g.clientHeight;

		return {'innerWidth' : x, 'innerHeight' : y};
	};

	return DimensionService;
}]);

/**
 * Permet de générer des id aléatoir et unique
 */
angular.module('collaboreApp').factory('uuid', function() {
	var svc = {
		new: function() {
			function _p8(s) {
				var p = (Math.random().toString(16)+'000000000').substr(2,8);
				return s ? '-' + p.substr(0,4) + '-' + p.substr(4,4) : p ;
			}
			return _p8() + _p8(true) + _p8(true) + _p8();
		},

		empty: function() {
			return '00000000-0000-0000-0000-000000000000';
		}
	};

	return svc;
});

/**
 * Permet de charger des fichier css et js à la volée
 */
angular.module('collaboreApp').service('angularLoad', ['$document', '$q', '$timeout', function ($document, $q, $timeout) {
	var document = $document[0];

	function loader(createElement) {
		var promises = {};

		return function(url) {
			if (typeof promises[url] === 'undefined') {
				var deferred = $q.defer();
				var element = createElement(url);

				element.onload = element.onreadystatechange = function (e) {
					$timeout(function () {
						deferred.resolve(e);
					});
				};
				element.onerror = function (e) {
					$timeout(function () {
						deferred.reject(e);
					});
				};

				promises[url] = deferred.promise;
			}

			return promises[url];
		};
	}

	/**
	 * Dynamically loads the given script
	 * @param src The url of the script to load dynamically
	 * @returns {*} Promise that will be resolved once the script has been loaded.
	 */
	this.loadScript = loader(function (src) {
		var script = document.createElement('script');

		script.src = src;

		document.body.appendChild(script);
		return script;
	});

	/**
	 * Dynamically loads the given CSS file
	 * @param href The url of the CSS to load dynamically
	 * @returns {*} Promise that will be resolved once the CSS file has been loaded.
	 */
	this.loadCSS = loader(function (href) {
		var style = document.createElement('link');

		style.rel = 'stylesheet';
		style.type = 'text/css';
		style.href = href;

		document.head.appendChild(style);
		return style;
	});
}]);
/*
angular.module('collaboreApp').factory('LiveTchatInitializer', function(COLLAB_CONF,$window, $q){

	var defer = $q.defer();

	var asyncUrl = COLLAB_CONF.URL_COLLAB_NODEJS+'/js/scriptLiveTchat.js';

	// async loader
	var loadScript = function(asyncUrl) {
		var script = document.createElement('script');
		//script.type = 'text/javascript';
		script.src = asyncUrl;
		document.body.appendChild(script);
	};

	// loading google maps
	loadScript(asyncUrl);

	return defer.promise;
});*/
