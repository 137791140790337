(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.Parametrage
   * @description
   * # User
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('Parametrage',
      ParametrageModel
    );

  /** @ngInject */
  function ParametrageModel(Main, Periode) {

    function Parametrage(objetData) {
      if (angular.isObject(objetData)) {
        Main.apply(this, arguments);
        this.hydrate(objetData);


        if(objetData.hasOwnProperty('listeMetiersRechercheContrats')) {

          var liste = '';
          if(angular.isObject(objetData.listeMetiersRechercheContrats) && !angular.isArray(objetData.listeMetiersRechercheContrats) && objetData.listeMetiersRechercheContrats.hasOwnProperty('valDefault')) {
            liste = objetData.listeMetiersRechercheContrats.valDefault;
          }
          else {
            liste = objetData.listeMetiersRechercheContrats;
          }
          if(liste!==''){
            liste = angular.fromJson(liste);
          }
          this.setListeMetiersRechercheContrats(liste);

        }
      }
    }

    Parametrage.prototype = {

      isModel: true,
      model: 'Parametrage',
      // Id Parametrage
      getIdParametrage: function(){
        return this.getDefaultValue('idParametrage');
      },
      setIdParametrage: function(id){
        this.setDefaultValue('idParametrage', id);
      },

      // priority
      getPriority: function(){
        return this.getDefaultValue('priority');
      },
      setPriority: function(val){
        this.setDefaultValue('priority', val);
      },

      // hideBtnMessage
      getHideBtnMessage: function(){
        return this.getDefaultValue('hideBtnMessage');
      },
      getHideBtnMessageGroupeAll: function(){
        return this.getGroupeAllValue('hideBtnMessage');
      },
      setHideBtnMessage: function(val){
        this.setDefaultValue('hideBtnMessage', val);
      },

      // displayDetailsInTopicList
      getDisplayDetailsInTopicList: function(){
        return this.getDefaultValue('displayDetailsInTopicList');
      },
      getDisplayDetailsInTopicListGroupeAll: function(){
        return this.getGroupeAllValue('displayDetailsInTopicList');
      },
      setDisplayDetailsInTopicList: function(val){
        this.setDefaultValue('displayDetailsInTopicList', val);
      },

      // displayWidgetTempDocBox
      getDisplayWidgetTempDocBox: function(){
        return this.getDefaultValue('displayWidgetTempDocBox');
      },
      getDisplayWidgetTempDocBoxGroupeAll: function(){
        return this.getGroupeAllValue('displayWidgetTempDocBox');
      },
      setDisplayWidgetTempDocBox: function(val){
        this.setDefaultValue('displayWidgetTempDocBox', val);
      },

      // createDevisNoSendDocumentsByMail
      getCreateDevisNoSendDocumentsByMail: function(){
        return this.getDefaultValue('createDevisNoSendDocumentsByMail');
      },
      getCreateDevisNoSendDocumentsByMailGroupeAll: function(){
        return this.getGroupeAllValue('createDevisNoSendDocumentsByMail');
      },
      setCreateDevisNoSendDocumentsByMail: function(val){
        this.setDefaultValue('createDevisNoSendDocumentsByMail', val);
      },

      // sendNotifMailActionFromFournisseur
      getSendNotifMailActionFromFournisseur: function(){
        return this.getDefaultValue('sendNotifMailActionFromFournisseur');
      },
      getSendNotifMailActionFromFournisseurGroupeAll: function(){
        return this.getGroupeAllValue('sendNotifMailActionFromFournisseur');
      },
      setSendNotifMailActionFromFournisseur: function(val){
        this.setDefaultValue('sendNotifMailActionFromFournisseur', val);
      },

      // sendNotifMailActionFromFournisseurOnlyExpediteur
      getSendNotifMailActionFromFournisseurOnlyExpediteur: function(){
        return this.getDefaultValue('sendNotifMailActionFromFournisseurOnlyExpediteur');
      },
      getSendNotifMailActionFromFournisseurOnlyExpediteurGroupeAll: function(){
        return this.getGroupeAllValue('sendNotifMailActionFromFournisseurOnlyExpediteur');
      },
      setSendNotifMailActionFromFournisseurOnlyExpediteur: function(val){
        this.setDefaultValue('sendNotifMailActionFromFournisseurOnlyExpediteur', val);
      },

      // noSendMailWhenAcceptOrDeclineDevis
      getNoSendMailWhenAcceptOrDeclineDevis: function(){
        return this.getDefaultValue('noSendMailWhenAcceptOrDeclineDevis');
      },
      getNoSendMailWhenAcceptOrDeclineDevisGroupeAll: function(){
        return this.getGroupeAllValue('noSendMailWhenAcceptOrDeclineDevis');
      },
      setNoSendMailWhenAcceptOrDeclineDevis: function(val){
        this.setDefaultValue('noSendMailWhenAcceptOrDeclineDevis', val);
      },

      // noSendMailWhenSigneDevis
      getNoSendMailWhenSigneDevis: function(){
        return this.getDefaultValue('noSendMailWhenSigneDevis');
      },
      getNoSendMailWhenSigneDevisGroupeAll: function(){
        return this.getGroupeAllValue('noSendMailWhenSigneDevis');
      },
      setNoSendMailWhenSigneDevis: function(val){
        this.setDefaultValue('noSendMailWhenSigneDevis', val);
      },

      // noNotifWhenCreateDevisInterSameSociete
      getNoNotifWhenCreateDevisInterSameSociete: function(){
        return this.getDefaultValue('noNotifWhenCreateDevisInterSameSociete');
      },
      getNoNotifWhenCreateDevisInterSameSocieteGroupeAll: function(){
        return this.getGroupeAllValue('noNotifWhenCreateDevisInterSameSociete');
      },
      setNoNotifWhenCreateDevisInterSameSociete: function(val){
        this.setDefaultValue('noNotifWhenCreateDevisInterSameSociete', val);
      },

      // onClickReplyToAllSendByMailEnabled
      getOnClickReplyToAllSendByMailEnabled: function(){
        return this.getDefaultValue('onClickReplyToAllSendByMailEnabled');
      },
      getOnClickReplyToAllSendByMailEnabledGroupeAll: function(){
        return this.getGroupeAllValue('onClickReplyToAllSendByMailEnabled');
      },
      setOnClickReplyToAllSendByMailEnabled: function(val){
        this.setDefaultValue('onClickReplyToAllSendByMailEnabled', val);
      },

      // associationEnabled
      getAssociationEnabled: function(){
        return this.getDefaultValue('associationEnabled');
      },
      getAssociationEnabledGroupeAll: function(){
        return this.getGroupeAllValue('associationEnabled');
      },
      setAssociationEnabled: function(val){
        this.setDefaultValue('associationEnabled', val);
      },


      // createContratNoSendDocumentsByMail
      getCreateContratNoSendDocumentsByMail: function(){
        return this.getDefaultValue('createContratNoSendDocumentsByMail');
      },
      getCreateContratNoSendDocumentsByMailGroupeAll: function(){
        return this.getGroupeAllValue('createContratNoSendDocumentsByMail');
      },
      setCreateContratNoSendDocumentsByMail: function(val){
        this.setDefaultValue('createContratNoSendDocumentsByMail', val);
      },

      // sendOneMailForAllDest
      getSendOneMailForAllDest: function(){
        return this.getDefaultValue('sendOneMailForAllDest');
      },
      getSendOneMailForAllDestGroupeAll: function(){
        return this.getGroupeAllValue('sendOneMailForAllDest');
      },
      setSendOneMailForAllDest: function(val){
        this.setDefaultValue('sendOneMailForAllDest', val);
      },

      // sendNotifMailActionContratFromFournisseur
      getSendNotifMailActionContratFromFournisseur: function(){
        return this.getDefaultValue('sendNotifMailActionContratFromFournisseur');
      },
      getSendNotifMailActionContratFromFournisseurGroupeAll: function(){
        return this.getGroupeAllValue('sendNotifMailActionContratFromFournisseur');
      },
      setSendNotifMailActionContratFromFournisseur: function(val){
        this.setDefaultValue('sendNotifMailActionContratFromFournisseur', val);
      },

      // sendNotifMailActionContratFromFournisseurOnlyExpediteur
      getSendNotifMailActionContratFromFournisseurOnlyExpediteur: function(){
        return this.getDefaultValue('sendNotifMailActionContratFromFournisseurOnlyExpediteur');
      },
      getSendNotifMailActionContratFromFournisseurOnlyExpediteurGroupeAll: function(){
        return this.getGroupeAllValue('sendNotifMailActionContratFromFournisseurOnlyExpediteur');
      },
      setSendNotifMailActionContratFromFournisseurOnlyExpediteur: function(val){
        this.setDefaultValue('sendNotifMailActionContratFromFournisseurOnlyExpediteur', val);
      },

      // noSendMailWhenAcceptOrDeclineContrat
      getNoSendMailWhenAcceptOrDeclineContrat: function(){
        return this.getDefaultValue('noSendMailWhenAcceptOrDeclineContrat');
      },
      getNoSendMailWhenAcceptOrDeclineContratGroupeAll: function(){
        return this.getGroupeAllValue('noSendMailWhenAcceptOrDeclineContrat');
      },
      setNoSendMailWhenAcceptOrDeclineContrat: function(val){
        this.setDefaultValue('noSendMailWhenAcceptOrDeclineContrat', val);
      },

      // noSendMailWhenSigneContrat
      getNoSendMailWhenSigneContrat: function(){
        return this.getDefaultValue('noSendMailWhenSigneContrat');
      },
      getNoSendMailWhenSigneContratGroupeAll: function(){
        return this.getGroupeAllValue('noSendMailWhenSigneContrat');
      },
      setNoSendMailWhenSigneContrat: function(val){
        this.setDefaultValue('noSendMailWhenSigneContrat', val);
      },

      // noNotifWhenCreateContratSameSociete
      getNoNotifWhenCreateContratSameSociete: function(){
        return this.getDefaultValue('noNotifWhenCreateContratSameSociete');
      },
      getNoNotifWhenCreateContratSameSocieteGroupeAll: function(){
        return this.getGroupeAllValue('noNotifWhenCreateContratSameSociete');
      },
      setNoNotifWhenCreateContratSameSociete: function(val){
        this.setDefaultValue('noNotifWhenCreateContratSameSociete', val);
      },

      // signature
      getSignature: function(){
        return this.getDefaultValue('signature');
      },
      getSignatureGroupeAll: function(){
        return this.getGroupeAllValue('signature');
      },
      setSignature: function(id){
        this.setDefaultValue('signature', id);
      },

      // onlyValidContract
      getOnlyValidContract: function(){
        return this.getDefaultValue('onlyValidContract');
      },
      getOnlyValidContractGroupeAll: function(){
        return this.getGroupeAllValue('onlyValidContract');
      },
      setOnlyValidContract: function(val){
        this.setDefaultValue('onlyValidContract', val);
      },

      // validDevisWhenSignIt
      getValidDevisWhenSignIt: function(){
        return this.getDefaultValue('validDevisWhenSignIt');
      },
      getValidDevisWhenSignItGroupeAll: function(){
        return this.getGroupeAllValue('validDevisWhenSignIt');
      },
      setValidDevisWhenSignIt: function(val){
        this.setDefaultValue('validDevisWhenSignIt', val);
      },

      // stateHomeCollab
      getStateHomeCollab: function(){
        return this.getDefaultValue('stateHomeCollab');
      },
      getStateHomeCollabGroupeAll: function(){
        return this.getGroupeAllValue('stateHomeCollab');
      },
      setStateHomeCollab: function(val){
        this.setDefaultValue('stateHomeCollab', val);
      },

      // collabizNotAllowedAddMessageToClosedTopic
      getCollabizNotAllowedAddMessageToClosedTopic: function(){
        return this.getDefaultValue('collabizNotAllowedAddMessageToClosedTopic');
      },
      getCollabizNotAllowedAddMessageToClosedTopicGroupeAll: function(){
        return this.getGroupeAllValue('collabizNotAllowedAddMessageToClosedTopic');
      },
      setCollabizNotAllowedAddMessageToClosedTopic: function(val){
        this.setDefaultValue('collabizNotAllowedAddMessageToClosedTopic', val);
      },

      // collabizNotifCreatorByMailIfMessageNotAddAboutClosedTopic
      getCollabizNotifCreatorByMailIfMessageNotAddAboutClosedTopic: function(){
        return this.getDefaultValue('collabizNotifCreatorByMailIfMessageNotAddAboutClosedTopic');
      },
      getCollabizNotifCreatorByMailIfMessageNotAddAboutClosedTopicGroupeAll: function(){
        return this.getGroupeAllValue('collabizNotifCreatorByMailIfMessageNotAddAboutClosedTopic');
      },
      setCollabizNotifCreatorByMailIfMessageNotAddAboutClosedTopic: function(val){
        this.setDefaultValue('collabizNotifCreatorByMailIfMessageNotAddAboutClosedTopic', val);
      },

      // searchByElasticSearchEnabled
      getSearchByElasticSearchEnabled: function(){
        return this.getDefaultValue('searchByElasticSearchEnabled');
      },
      getSearchByElasticSearchEnabledGroupeAll: function(){
        return this.getGroupeAllValue('searchByElasticSearchEnabled');
      },
      setSearchByElasticSearchEnabled: function(val){
        this.setDefaultValue('searchByElasticSearchEnabled', val);
      },

      // exportElasticSearchInRealTimeEnabled
      getExportElasticSearchInRealTimeEnabled: function(){
        return this.getDefaultValue('exportElasticSearchInRealTimeEnabled');
      },
      getExportElasticSearchInRealTimeEnabledGroupeAll: function(){
        return this.getGroupeAllValue('exportElasticSearchInRealTimeEnabled');
      },
      setExportElasticSearchInRealTimeEnabled: function(val){
        this.setDefaultValue('exportElasticSearchInRealTimeEnabled', val);
      },

      // urlExtranet
      getUrlExtranet: function(){
        return this.getDefaultValue('urlExtranet');
      },
      setUrlExtranet: function(id){
        this.setDefaultValue('urlExtranet', id);
      },

      // logoCollab
      getLogoCollab: function(){
        return this.getDefaultValue('logoCollab');
      },
      setLogoCollab: function(id){
        this.setDefaultValue('logoCollab', id);
      },

      // noMailCreationTopicForSociete
      getNoMailCreationTopicForSociete: function(){
        return this.getDefaultValue('noMailCreationTopicForSociete');
      },
      setNoMailCreationTopicForSociete: function(val){
        this.setDefaultValue('noMailCreationTopicForSociete', val);
      },

      // agenceICS
      getAgenceICS: function(){
        return this.getDefaultValue('agenceICS');
      },
      setAgenceICS: function(val){
        this.setDefaultValue('agenceICS', val);
      },

      // noCommunicationAfterCreateEvent
      getNoCommunicationAfterCreateEvent: function(){
        return this.getDefaultValue('noCommunicationAfterCreateEvent');
      },
      getNoCommunicationAfterCreateEventGroupeAll: function(){
        return this.getGroupeAllValue('noCommunicationAfterCreateEvent');
      },
      setNoCommunicationAfterCreateEvent: function(val){
        this.setDefaultValue('noCommunicationAfterCreateEvent', val);
      },

      // disallowDeleteDevisForFournisseur
      getDisallowDeleteDevisForFournisseur: function(){
        return this.getDefaultValue('disallowDeleteDevisForFournisseur');
      },
      setDisallowDeleteDevisForFournisseur: function(val){
        this.setDefaultValue('disallowDeleteDevisForFournisseur', val);
      },

      // clotureEventByMembreAgence
      getClotureEventByMembreAgence: function(){
        return this.getDefaultValue('clotureEventByMembreAgence');
      },
      setClotureEventByMembreAgence: function(val){
        this.setDefaultValue('clotureEventByMembreAgence', val);
      },

      // withPaieIcs
      getWithPaieIcs: function(){
        return this.getDefaultValue('withPaieIcs');
      },
      setWithPaieIcs: function(id){
        this.setDefaultValue('withPaieIcs', id);
      },

      // allowSendToEdi
      getAllowSendToEdi: function(){
        return this.getDefaultValue('allowSendToEdi');
      },
      setAllowSendToEdi: function(id){
        this.setDefaultValue('allowSendToEdi', id);
      },

      // allowSendToComfactfacture
      getAllowSendToComfactfacture: function(){
        return this.getDefaultValue('allowSendToComfactfacture');
      },
      setAllowSendToComfactfacture: function(id){
        this.setDefaultValue('allowSendToComfactfacture', id);
      },

      // fastSearchEnabled
      getFastSearchEnabled: function(){
        return this.getDefaultValue('fastSearchEnabled');
      },
      setFastSearchEnabled: function(id){
        this.setDefaultValue('fastSearchEnabled', id);
      },

      // shootingMailEnabled
      getShootingMailEnabled: function(){
        return this.getDefaultValue('shootingMailEnabled');
      },
      setShootingMailEnabled: function(id){
        this.setDefaultValue('shootingMailEnabled', id);
      },

      // collabizEnabled
      getCollabizEnabled: function(){
        return this.getDefaultValue('collabizEnabled');
      },
      setCollabizEnabled: function(val){
        this.setDefaultValue('collabizEnabled', val);
      },

      // updateLogicielIcsEnabled
      getUpdateLogicielIcsEnabled: function(){
        return this.getDefaultValue('updateLogicielIcsEnabled');
      },
      setUpdateLogicielIcsEnabled: function(val){
        this.setDefaultValue('updateLogicielIcsEnabled', val);
      },

      // contratEnabled
      getContratEnabled: function(){
        return this.getDefaultValue('contratEnabled');
      },
      setContratEnabled: function(val){
        this.setDefaultValue('contratEnabled', val);
      },

      // facturesAutonomesEnabled
      getFacturesAutonomesEnabled: function(){
        return this.getDefaultValue('facturesAutonomesEnabled');
      },
      setFacturesAutonomesEnabled: function(val){
        this.setDefaultValue('facturesAutonomesEnabled', val);
      },

      // ocrEnabled
      getOcrEnabled: function(){
        return this.getDefaultValue('ocrEnabled');
      },
      setOcrEnabled: function(val){
        this.setDefaultValue('ocrEnabled', val);
      },

      // saisieComptesSCOEnabled
      getSaisieComptesSCOEnabled: function(){
        return this.getDefaultValue('saisieComptesSCOEnabled');
      },
      setSaisieComptesSCOEnabled: function(val){
        this.setDefaultValue('saisieComptesSCOEnabled', val);
      },

      // hideProprietaireInDocument
      getHideProprietaireInDocument: function(){
        return this.getDefaultValue('hideProprietaireInDocument');
      },
      getHideProprietaireInDocumentGroupeAll: function(){
        return this.getGroupeAllValue('hideProprietaireInDocument');
      },
      setHideProprietaireInDocument: function(val){
        this.setDefaultValue('hideProprietaireInDocument', val);
      },

      // listeMetiersRechercheContrats
      getListeMetiersRechercheContrats: function(convertObj){
        var liste = this.getDefaultValue('listeMetiersRechercheContrats');

        if(liste && convertObj) {
          liste = angular.fromJson(liste);
        }
        else {
          liste = [];

          if(!convertObj) {
            liste = angular.toJson(liste);
          }

        }
        return liste;
      },
      setListeMetiersRechercheContrats: function(val){
        this.setDefaultValue('listeMetiersRechercheContrats', val);
      },
      addListeMetiersRechercheContrats: function(val){
        var liste = this.getListeMetiersRechercheContrats(true);
        var exist = false;
        if(liste.length) {
          for(var i = 0; i < liste.length; i++) {
            if(val === liste[i]) {
              exist = true;
              break;
            }
          }
        }
        if(!exist) {
          liste.push(val);
        }
        this.setListeMetiersRechercheContrats(liste);
      },
      /********* Proxy ************/

      toJson: function(){
        var self = angular.copy(this);
        if(angular.isObject(self.getListeMetiersRechercheContrats())) {
          self.listeMetiersRechercheContrats = angular.toJson(self.getListeMetiersRechercheContrats());
        }
        return angular.toJson(self);
      },
      getGroupeAllValue: function(key){
        var retour = this[key];
        if(angular.isObject(this[key]) && !angular.isArray(this[key]) && this[key].hasOwnProperty('valGroupeAll')) {
          retour = this[key].valGroupeAll;
        }
        return retour;
      },

      getDefaultValue: function(key){
        var retour = this[key];
        if(angular.isObject(this[key]) && !angular.isArray(this[key]) && this[key].hasOwnProperty('valDefault')) {
          retour = this[key].valDefault;
        }
        return retour;
      },
      setDefaultValue: function(key, val){
        this[key] = val;
        /*
                if(angular.isObject(this[key]) && !angular.isArray(this[key]) && this[key].hasOwnProperty('valDefault')) {
                    this[key].valDefault = val;
                }
                else{
                    this[key] = val;
                }*/
      },

      getListPeriode: function(){
        return this.listPeriode;
      },
      setListPeriode: function (val){
        if(_.isArray(val) && val.length) {
          val = val.reduce(function(accum, item, index) {
            if(_.isObject(item)) {
              if(!item.isModel) item = new Periode(item);
              accum.push(item);
            }
            return accum;
          }, []);
        }
        this.listPeriode = val;
      }
    };

    angular.extend(Parametrage.prototype, Main.prototype);

    return Parametrage;
  }
})();
