(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name collaboreApp.Topic
     * @description
     * # Topic
     * Service in the collaboreApp.
     */

    angular
        .module('collaboreApp')
        .factory('CategorieTopic', CategorieTopicModel);

    /** @ngInject */
    function CategorieTopicModel(Main, UtilsService) {

        var CategorieTopic = function(objet) {
            Main.call(this, objet);
        };

        CategorieTopic.prototype = {

            model: 'CategorieTopic',
            isCategorieTopic: true,

            // idCategorieTopic
            getIdCategorieTopic: function(){
                return this.idCategorieTopic;
            },
            setIdCategorieTopic: function(id){
                this.idCategorieTopic = id;
            },

            // libelle
            getLibelle: function(){
                return this.libelle;
            },
            setLibelle: function(id){
                this.libelle = id;
            },

            // textColor
            getTextColor: function(){
                return this.textColor;
            },
            setTextColor: function(color){
                this.textColor = color;
            },

            // description
            getDescription: function(){
                return this.description;
            },
            setDescription: function(id){
                this.description = id;
            },

            // dateCreation
            getDateCreation: function (format) {
                if(!_.isNil(format)) {
                    if(!_.isString(format) && format){
                        format = null;
                    }
                    return UtilsService.parseDate(this.dateCreation, format);
                }
                return this.dateCreation;
            },
            setDateCreation: function (date) {
                this.dateCreation = date;
            },

            // categorieTopicParent
            getCategorieTopicParent: function(){
                return this.categorieTopicParent;
            },
            setCategorieTopicParent: function(categorieParent){
                if(_.isObject(categorieParent)){
                    if (!categorieParent.isModel) {
                        categorieParent = new CategorieTopic(categorieParent);
                    }
                    this.categorieTopicParent = categorieParent;
                }
            },

            // listEnfantsCategorieTopic
            getListEnfantsCategorieTopic: function(){
                return this.listEnfantsCategorieTopic;
            },
            setListEnfantsCategorieTopic: function(listCategorieEnfant){
                if(_.isArray(listCategorieEnfant) && listCategorieEnfant.length){
                    for(var i = 0; i < listCategorieEnfant.length; i++){
                        if (!listCategorieEnfant[i].isModel) {
                            listCategorieEnfant[i] = new CategorieTopic(listCategorieEnfant[i]);
                        }
                    }
                    this.listEnfantsCategorieTopic = listCategorieEnfant;
                }
            },

            // Groupe
            getGroupe: function(){
                return this.groupe;
            },
            setGroupe: function(groupe){
                if(_.isObject(groupe)){
                    if (!groupe.isModel) {
                        var Groupe = Main.$injector.get('Groupe');
                        groupe = new Groupe(groupe);
                        Groupe = null;
                    }
                    this.groupe = groupe;
                }
            }
        };

        angular.extend(CategorieTopic.prototype, Main.prototype);

        return CategorieTopic;
    }
})();
