(function () {
  "use strict";

  /**
   * @desc Composant permetant d'affecter des montants sur des comptes
   * @example <affectation-comptes></affectation-comptes>
   */
  angular.module("collaboreApp").component("saisieCompte", {
    templateUrl:
      "app/components/affectation-comptes/saisie-compte/saisie-compte.component.html",
    bindings: {
      mode: '@',
      contrat: "<",
      montant: "<",
      loadingCalculReste: '<',
      listCompteByBatiment: "<",
      onEmit: "&", // Permet de notifier le composant parent
    },
    controllerAs: "saisiecomptectrl",
    controller: SaisieCompteController,
  });

  /*@ngInject*/
  function SaisieCompteController($scope, CompteService, ModalsService) {
    var _this = this;
    var noAttributReste = null;
    var listenerVentile = null;
    _this.hasBaseRep = false;
    _this.nbCompte = 0;
    _this.onClickDeletModele = onClickDeletModele;
    _this.onClickAddReste = onClickAddReste;
    _this.onClickAddCorrection = onClickAddCorrection;
    _this.onClickClearField = onClickClearField;
    _this.onChangeMontantField = onChangeMontantField;
    _this.onBlurMontantField = onBlurMontantField;

    _this.$onInit = function () {
      _this.hasBaseRep = (_.isObject(_this.contrat) && _.isObject(_this.contrat.source)) ? _this.contrat.source.hasBasRep() : false;
      var eventListener = (_this.mode == 'contrat' && _this.hasBaseRep) ? 'ventilContrat' : 'ventil';
      listenerVentile = $scope.$on(
        eventListener,
        function (e, promise, force) {
          //console.log('exec', eventListener);
          try{
            if(eventListener == 'ventilContrat'){
              CompteService.ventileContrat(
                _this.montant,
                 _this.listCompteByBatiment,
                 _this.contrat,
                 force
               );
               if(!_.isNil(_this.montant)) _this.contrat.reste = math.subtract(_this.montant, _this.contrat.cumulMontant);
             }
             else{
              _this.reste = CompteService.ventile(
                _this.montant,
                _this.listCompteByBatiment,
                _this.contrat,
                noAttributReste
               );
               noAttributReste = null;
             }

             promise.resolve({listener:eventListener, reste: _this.reste});
          }
          catch(e){
            promise.reject(e);
          }
        }
      );
      emit({ action: "onReady", ready: true, mode: _this.mode });
    };

    /**
     * Lors de changements depuis le parent
     * @param changes
     */
    _this.$onChanges = function (changes) {
      //console.log("saisieCompte", changes);
      if (_.isObject(changes)) {
         // Si composant en mode ventilation (principalement pour les contrats)
         if(_this.mode == 'contrat') {
          if (_.isObject(changes.contrat) && _.isObject(_this.contrat)) {
            _this.listCompteByBatiment = _this.contrat.listBatiment;
          }
        }
        _this.hasBaseRep = (_.isObject(_this.contrat) && _.isObject(_this.contrat.source)) ? _this.contrat.source.hasBasRep() : false;
      }

      if(_.isArray(_this.listCompteByBatiment)){
        _this.nbCompte = CompteService.getNbCompte(_this.listCompteByBatiment);
      }

    };

    _this.$onDestroy = function () {
      if(!_.isNil(listenerVentile)) listenerVentile();
      emit({ action: "onDestroy", destroy: true, mode: _this.mode });
    };

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour) {
      _this.onEmit({ obj: objRetour });
    }

    function emitCalculReste() {
      emit({ action: "calculReste", mode: _this.mode });
    }

    function onClickDeletModele(modele) {
      if(_.isObject(modele.contrattab)){
        ModalsService
        .confirm("Suppression d'un compte de ventilation","Attention vous allez supprimer un compte de ventilation lié à ce contrat, êtes-vous sûr de vouloir continuer ?")
        .then(function (modal) {
          removeModele(modele);
          modal.close();
      });
      }
      else removeModele(modele);
    }

    function onClickAddReste(modele) {
      modele.montant = _this.reste;
      delete modele.montantCorrection;
      delete modele.edited;
      emitCalculReste();
    }

    function onClickAddCorrection(modele) {
      modele.montant = modele.montantCorrection;
      delete modele.montantCorrection;
      delete modele.edited;
      emitCalculReste();
    }

    function onClickClearField(modele) {
      delete modele.montant;
      delete modele.montantCorrection;
      delete modele.edited;
      noAttributReste = true;
      emitCalculReste();
    }

    function onChangeMontantField(modele) {
      if (_.isNil(modele)) return;
      if (_.isString(modele.montant))
        modele.montant = modele.montant.replace(/\,/gi, ".");
      emitCalculReste();
    }

    function onBlurMontantField(modele) {
      return;
      /*
      if (_.isNil(modele)) return;
      if (_.isString(modele.montant))
        modele.montant = modele.montant.replace(/\,/gi, ".");
      emitCalculReste();*/
    }

    function removeModele(modele) {
      if (!_.isObject(modele)) return;
      _.remove(_this.listCompteByBatiment, function (batiment) {
        _.remove(batiment.listCompte, function (compte) {
          return (
            compte.getCCCCCC() == modele.getCCCCCC() &&
            compte.getBB() == modele.getBB()
          );
        });
        _.remove(batiment.listRegroupement, function (regroupement) {
          _.remove(regroupement.listCompte, function (compte) {
            return (
              compte.getCCCCCC() == modele.getCCCCCC() &&
              compte.getBB() == modele.getBB()
            );
          });
          _.remove(regroupement.listRegroupement, function (sousRegroupement) {
            _.remove(sousRegroupement.listCompte, function (sousCompte) {
              return (
                sousCompte.getCCCCCC() == modele.getCCCCCC() &&
                sousCompte.getBB() == modele.getBB()
              );
            });
            // Supprime le sous regroupement du regroupement du batiment si plus de compte
            return (
              _.isNil(sousRegroupement.listCompte) ||
              (_.isArray(sousRegroupement.listCompte) &&
                !sousRegroupement.listCompte.length)
            );
          });
          // Supprime le regroupement du batiment si plus de compte ou plus de sous regroupement
          return (
            (_.isNil(regroupement.listCompte) ||
              (_.isArray(regroupement.listCompte) &&
                !regroupement.listCompte.length)) &&
            (_.isNil(regroupement.listRegroupement) ||
              (_.isArray(regroupement.listRegroupement) &&
                !regroupement.listRegroupement.length))
          );
        });
        //Supprime le batiment de la liste si plus de compte et plus de regroupement
        return (
          (_.isNil(batiment.listCompte) ||
            (_.isArray(batiment.listCompte) && !batiment.listCompte.length)) &&
          (_.isNil(batiment.listRegroupement) ||
            (_.isArray(batiment.listRegroupement) &&
              !batiment.listRegroupement.length))
        );
      });
      emit({ action: "removeCompte" });
      if(_.isArray(_this.listCompteByBatiment)){
        _this.nbCompte = CompteService.getNbCompte(_this.listCompteByBatiment);
      }
    }
  }
})();
