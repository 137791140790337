(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.Document
   * @description
   * # Contenus
   * Service in the collaboreApp.
   */
  angular.module('collaboreApp').factory('Document', DocumentModel);

  /** @ngInject */
  function DocumentModel(Main, MainService, COLLAB_CONF, COLLAB_VALUES, $q, $window, $rootScope, DocumentsAjax, Tag, GedLink, DocumentOCR, UtilsService) {

    function Document(documentData) {
      Main.call(this, documentData);
    }

    /*
     dateUpload: "2015-03-30T00:00:00"
     extension: ".jpg"
     guid: "A0E1F108-DBAC-46C4-8E8B-BBB5B6DB89B5"
     nom: "ics"
     repertoire: "../1"
     size: 138035
     tags: []
     */
    Document.prototype = {

      model: 'Document',
      isDocument: true,

      getNomFichier: function (){
        return this.getNom()+this.getExtension();
      },

      /*
      setData: function (documentData) {
          //angular.extend(this, documentData);

          this.hydrate(documentData);

          //--------- Début Tags -------------

          // Si tags est un array
          if (angular.isArray(documentData.tags)) {

              // Si cet array est différent de 0
              if (documentData.tags.length) {
                  var tags = [];
                  // Pour chaque tags
                  angular.forEach(documentData.tags, function (tag) {
                      // Crée une instance de tag
                      var instanceTag = new Tag(tag);
                      // Ajoute cette instance à un tableau de tags
                      tags.push(instanceTag);
                  });
                  // Set "tags" avec l'array d'instance de Tag
                  this.setTags(tags);
              }
          }

          //--------- Fin Tags -------------
      },

      hydrate: function (documentData) {
          var scope = this;
          var reg = /^set[A-Za-z]+/;
          if (angular.isObject(documentData)) {

              angular.forEach(documentData, function (val, key) {
                  var setter = 'set' + $filter('ucfirst')(key);
                  //console.log(setter);

                  if (typeof(scope[setter]) === 'function') {

                      scope[setter](documentData[key]);
                  }
              });
          } else {

              var methodes = Object.getPrototypeOf(this);
              angular.forEach(methodes, function (val, key) {
                  if (reg.test(key) && key !== 'setData') {
                      scope[key]();
                  }
              });

          }
      },
         */
      // Date upload
      getDateUpload: function (format) {
        if(!_.isNil(format)) {
          if (!_.isString(format) && format) {
            format = null;
          }
          return UtilsService.parseDate(this.dateUpload, format);
        }
        return this.dateUpload;
      },
      setDateUpload: function (date) {
        this.dateUpload = date;
      },
      getDateUploadAgo: function(){
        var retour = this.dateUpload;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },

      // Extension
      getExtension: function () {
        return this.extension;
      },
      setExtension: function (extension) {
        this.extension = extension;
      },

      // groupeCreateur
      getGroupeCreateur: function () {
        return this.groupeCreateur;
      },
      setGroupeCreateur: function (groupe) {
        if(_.isObject(groupe)) {
          if(!groupe.isModel) {
            var Groupe = Main.$injector.get('Groupe');
            groupe = new Groupe(groupe);
            Groupe = null;
          }
        }
        this.groupeCreateur = groupe;
      },

      // Guid
      getGuid: function () {
        return this.guid;
      },
      setGuid: function (guid) {
        this.guid = guid;
      },

      /**
       * Si paramètres renseignés alors recherche par rapport aux paramètres
       * @param idContenu
       * @param idTopic
       * @returns {*[]|*|[]}
       */
      getGedLinks: function(idContenu, idTopic, withoutRealPath){
        if(_.isNil(idContenu) && _.isNil(idTopic) && (_.isNil(withoutRealPath) || !withoutRealPath)) return (_.isNil(this.gedLinks)) ? [] : this.gedLinks;
        var listGedLinks = [];
        if(_.isArray(this.gedLinks) && this.gedLinks.length && (!_.isNil(idContenu) || !_.isNil(idTopic) || withoutRealPath)){
          for(var i = 0; i < this.gedLinks.length; i++){
            var currentGedLink = this.gedLinks[i];
            // Si on veut les gedLinks sans celui du document réél et que c'est le document réel alors on passe à l'itération suivante
            if(!_.isNil(withoutRealPath) && withoutRealPath && currentGedLink.getIsRealDoc())
              continue;

            if(_.isNil(idContenu) && _.isNil(idTopic)) {
              listGedLinks.push(currentGedLink);
              continue;
            }

            if(!_.isNil(idContenu)){
              if(currentGedLink.getIdContenu() === idContenu){
                listGedLinks.push(currentGedLink);
              }
            }
            // Si pas d'idContenu à chercher mais un idTopic seulement
            else {
              // Si l'idContenu du gedLink est null et que l'idTopic est le même que celui recherché
              if(!_.isNil(idTopic) && _.isNil(currentGedLink.getIdContenu()) && currentGedLink.getIdTopic() === idTopic){
                listGedLinks.push(currentGedLink);
              }
            }
          }
        }
        return _.sortBy(listGedLinks,['dateSentToGed']);
      },
      getLastGedLinks: function(idContenu, idTopic){
        var gedLinks = this.getGedLinks(idContenu, idTopic);
        return (_.isArray(gedLinks)) ? _.last(gedLinks) : null;
      },
      getPublicGedLinks: function(idContenu, idTopic){
        return this.getGedLinks(idContenu, idTopic, true);
      },
      setGedLinks: function(array){
        if(_.isArray(array) && array.length){
          for(var i = 0; i < array.length; i++){
            if(!array[i].isModel) array[i] = new GedLink(array[i]);
          }
          this.gedLinks = array;
        }
      },
      hasGedLinks: function(){
        return (this.getGedLinks().length);
      },

      // Nom
      getNom: function () {
        return this.nom;
      },
      setNom: function (nom) {
        this.nom = nom;
      },


      // Erreur
      getErreur: function () {
        return UtilsService.nl2br(this.erreur);
      },
      setErreur: function (erreur) {
        this.erreur = erreur;
      },

      // pathGed
      getPathGed: function () {
        return this.pathGed;
      },
      setPathGed: function (path) {
        this.pathGed = path;
      },

      // Repertoire
      getRepertoire: function () {
        return this.repertoire;
      },
      setRepertoire: function (repertoire) {
        this.repertoire = repertoire;
      },

      // Poids
      getSize: function () {
        return this.size;
      },
      setSize: function (size) {
        this.size = size;
      },

      //Description
      getDescription: function () {
        return this.description;
      },
      setDescription: function (description) {
        this.description = description;
      },

      isSentToEdi: function () {
        if (this.getDateSentToEdi()) {
          return true;
        }
        return false;
      },

      // dateSentToEdi
      getDateSentToEdi: function () {
        return this.dateSentToEdi;
      },
      setDateSentToEdi: function (date) {
        this.dateSentToEdi = date;
      },
      getDateSentToEdiAgo: function(){
        var retour = this.dateSentToEdi;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },

      // dateSentToComfactfacture
      getDateSentToComfactfacture: function (format) {
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateSentToComfactfacture, format);
        }
        return this.dateSentToComfactfacture;
      },
      setDateSentToComfactfacture: function (date) {
        this.dateSentToComfactfacture = date;
      },
      getDateSentToComfactfactureAgo: function(){
        var retour = this.dateSentToComfactfacture;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },
      isSentToComfactfacture: function () {
        if (this.getDateSentToComfactfacture()) {
          return true;
        }
        return false;
      },

      // idComfactfacture
      getIdComfactfacture: function () {
        return this.idComfactfacture;
      },
      setIdComfactfacture: function (val) {
        this.idComfactfacture = val;
      },

      isSentToGed: function () {
        return (this.isSentToGedV1() || this.isSentToGedV2());
      },

      // Date sentToGed
      getDateSentToGed: function (format) {
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateSentToGed, format);
        }
        return this.dateSentToGed;
      },
      setDateSentToGed: function (date) {
        this.dateSentToGed = date;
      },
      isSentToGedV1: function () {
        return (this.getDateSentToGed());
      },

      isSentToGedV2: function (idContenu, idTopic) {
        // Si pas de gedLink alors pas en ged
        if(!this.hasGedLinks()) return false;
        // si gedLink et pas d'idContenu demandé alors en ged
        if(_.isNil(idContenu) && _.isNil(idTopic)) return true;
        // Si idContenu demandé on va chercher dans les gedLinks
        return (this.getGedLinks(idContenu, idTopic).length);
      },
      getLastDateSentToGedV2: function (format) {
        if(!this.hasGedLinks()) return null;
        return _.last(this.getGedLinks()).getDateSentToGed(format);
      },

      getDisplayDocument: function () {
        return this.displayDocument;
      },
      setDisplayDocument: function (displayDocument) {
        this.displayDocument = displayDocument;
      },

      // dateAcceptation
      getDateAcceptation: function (format) {
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateAcceptation, format);
        }
        return this.dateAcceptation;
      },
      setDateAcceptation: function (date) {
        this.dateAcceptation = date;
      },
      getDateAcceptationAgo: function(){
        var retour = this.dateAcceptation;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },
      isAccepted: function(){
        if(this.getDateAcceptation() != null) {
          return true;
        }
        return false;
      },

      // dateRefus
      getDateRefus: function (format) {
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateRefus, format);
        }
        return this.dateRefus;
      },
      setDateRefus: function (date) {
        this.dateRefus = date;
      },
      getDateRefusAgo: function(){
        var retour = this.dateRefus;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },
      isRefused: function(){
        if(this.getDateRefus() != null) {
          return true;
        }
        return false;
      },

      // dateSignature
      getDateSignature: function (format) {
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateSignature, format);
        }
        return this.dateSignature;
      },
      setDateSignature: function (date) {
        this.dateSignature = date;
      },
      getDateSignatureAgo: function(){
        var retour = this.dateSignature;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },
      isSigned: function(){
        if(this.getDateSignature() != null) {
          return true;
        }
        return false;
      },

      isWaiting: function(){
        if(this.isAccepted() || this.isRefused() || this.isSigned() || this.getDocumentSource()) {
          return false;
        }
        return true;
      },

      // Liste de tags
      getTags: function () {
        return this.tags;
      },
      setTags: function (tags) {
        this.tags = tags;
      },

      // Stream
      getStream: function () {
        return this.stream;
      },
      setStream: function (stream) {
        this.stream = stream;
      },

      // Type
      getType: function () {
        return this.type;
      },
      setType: function (type) {
        if (!type) {
          type = [];
        }
        this.type = type;
      },

      // TypeDocument
      getTypeDocument: function () {
        return this.typeDocument;
      },
      setTypeDocument: function (typeDocument) {
        this.typeDocument = typeDocument;
      },

      // defaultTypeDocument
      getDefaultTypeDocument: function () {
        return this.defaultTypeDocument;
      },
      setDefaultTypeDocument: function (typeDocument) {
        this.defaultTypeDocument = typeDocument;
      },

      /**
       * @name view
       * @desc Permet d'avoir le stream du document
       * @param {String} guid - guid du fichier
       * @returns {void}
       */
      view: function () {
        $window.open(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DOCUMENTS_DOWNLOAD + '?type=1&guid=' + this.getGuid() + '&token=' + $rootScope.token);
      },

      /**
       * Permet de télécharger un document
       * @param {String} guid - guid du fichier
       * @returns {void}
       */
      download: function () {
        $window.location.href = COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DOCUMENTS_DOWNLOAD + '?type=0&guid=' + this.getGuid() + '&token=' + $rootScope.token;
      },

      /**
       * Ajoute un tag au document
       * @param {Array} arrayTags - Tableau de tags à ajouter
       * @returns {Promesse}
       */
      addTags: function (arrayTags, isType) {
        var deferred = $q.defer();
        var error = {etat: false};
        var scope = this;

        if (this.getGuid()) {
          var documentsAjax = new DocumentsAjax();
          documentsAjax.guid = this.getGuid();
          documentsAjax.tags = arrayTags;
          if (isType) {
            documentsAjax.type = isType;
          }
          documentsAjax.$addTags(function (json) {
            if (json.nb !== '0') {
              // Recupère les tags de l'objet actuel
              var currentTags = scope.getTags();
              // Parcour les nouveaux tags ajoutés
              angular.forEach(json.Tags, function (tag) {
                var instanceNewTag = new Tag(tag);
                // Ajoute l'instance du tag au tableau des tags de l'objet
                currentTags.push(instanceNewTag);

              });
              // Set les nouveau tags de l'objet actuel
              scope.setTags(currentTags);

              deferred.resolve(json.Tags);
            } else {
              error.etat = true;
              error.msg = 'Tag inexistant [Document.addTags()]';
            }
          }, function () {
            error.etat = true;
            error.msg = 'Problème Ajax [Document.addTags()]';
          });
        } else {
          error.etat = true;
          error.msg = 'Pas de Guid [Document.addTags()]';
        }

        if (error.etat) {
          deferred.reject(error);
        }
        return deferred.promise;
      },

      /**
       * Supprime un tag au document
       * @param {Object} tag - Tags à supprimer
       * @returns {Promesse}
       */
      deleteTag: function (tag) {

        var deferred = $q.defer();
        var error = {etat: false};
        var scope = this;

        if (this.getGuid()) {

          var documentsAjax = new DocumentsAjax();
          documentsAjax.guid = this.getGuid();
          documentsAjax.tag = tag.idTag;

          documentsAjax.$deleteTag(function (json) {
            if (json.Resultats[0].success) {

              // Recupère les tags de l'objet actuel
              var currentTags = scope.getTags();

              // Parcour les nouveaux tags ajoutés
              angular.forEach(currentTags, function (objTagDocument, indexTagDocument) {
                // Si le tag actuellement parcouru
                if (objTagDocument.idTag === tag.idTag) {
                  currentTags.splice(indexTagDocument, 1);
                }
              });
              // Set les nouveau tags de l'objet actuel
              scope.setTags(currentTags);

              deferred.resolve();
            } else {
              error.etat = true;
              error.msg = 'Tag inexistant [Document.deleteTag()]';
            }
          }, function () {
            error.etat = true;
            error.msg = 'Problème Ajax [Document.deleteTag()]';
          });
        } else {
          error.etat = true;
          error.msg = 'Pas de Guid [Document.deleteTag()]';
        }

        if (error.etat) {
          deferred.reject(error);
        }
        return deferred.promise;
      },

      /**
       * Supprime un document
       * @param {Int} idGroupe*
       * @returns {Promesse}
       */
      changeSharing: function (idGroupe) {
        var deferred = $q.defer();

        /*
        var form = new FormData();
        form.append('guids[]',this.getGuid());
        form.append('idGroupe',idGroupe);
        form.append('token',$rootScope.token);
        */
        var documentAjax = new DocumentsAjax();
        documentAjax.idGroupe = idGroupe;
        documentAjax.guids = this.getGuid();
        documentAjax.$changeSharing(function (json) {
          //DocumentsAjax.changeSharing(params,function(json){
          if (json.nb === '1') {
            deferred.resolve(json.Documents[0]);
          }
          else {
            deferred.reject();
          }
        }, function (error) {
          var msgErreur = 'Erreur (' + error.status + ') : ' + error.statusText;
          deferred.reject('Problème avec Document.changeSharing(), ' + msgErreur);
        });
        return deferred.promise;
      },

      deleteDocument: function (idGroupe) {
        var deferred = $q.defer();
        var error = {etat: false};


        if (idGroupe) {

          if (this.getGuid()) {

            var documentAjax = new DocumentsAjax();
            documentAjax.idGroupe = idGroupe;
            documentAjax.guid = this.getGuid();
            documentAjax.$deleteFilePerso(function (json) {

              if (json.Resultats[0].success) {

                deferred.resolve();
              } else {

                error.etat = true;
                //error.msg = 'Document inexistant [Document.deleteDocument()]';
                error.msg = 'Impossible de supprimer ce document';
                deferred.reject(error);
              }
            }, function () {
              error.etat = true;
              error.msg = 'Problème Delete [Document.deleteDocument()]';
            });
          } else {
            error.etat = true;
            error.msg = 'Pas de Guid [Document.deleteDocument()]';
          }
        } else {
          error.etat = true;
          error.msg = 'Pas de d\'idGroupe [Document.deleteDocument()]';
        }

        if (error.etat) {

          deferred.reject(error);
        }

        return deferred.promise;
      },

      isDemandeDevis: function () {
        if (this.getTypeDocument() === COLLAB_CONF.TYPE_DOC_ENUMS.DEMANDE_DEVIS) {
          return true;
        }
        return false;
      },

      isDemandeIntervention: function () {
        if (this.getTypeDocument() === COLLAB_CONF.TYPE_DOC_ENUMS.DEMANDE_INTERVENTION) {
          return true;
        }
        return false;
      },

      isDemandeContrat: function () {
        if (this.getTypeDocument() === COLLAB_CONF.TYPE_DOC_ENUMS.DEMANDE_CONTRAT) {
          return true;
        }
        return false;
      },

      isDemande: function () {
        if (this.isDemandeDevis() || this.isDemandeIntervention() || this.isDemandeContrat()) {
          return true;
        }
        return false;
      },

      isAttestationTvaSimple: function () {
        if (this.getTypeDocument() === COLLAB_CONF.TYPE_DOC_ENUMS.ATTESTATION_TVA_SIMPLE) {
          return true;
        }
        return false;
      },

      isDocumentComptable: function () {
        if (this.getTypeDocument() === COLLAB_CONF.TYPE_DOC_ENUMS.DEMANDE_FACTURE ||
          this.getTypeDocument() === COLLAB_CONF.TYPE_DOC_ENUMS.FACTURE_DEMANDE_DEVIS_SIGNE ||
          this.getTypeDocument() === COLLAB_CONF.TYPE_DOC_ENUMS.FACTURE_DEVIS_SIGNE ||
          this.getIdComfactfacture()) {
          return true;
        }
        return false;
      },

      isFacture: function () {
        if (this.getTypeDocument() === COLLAB_CONF.TYPE_DOC_ENUMS.FACTURE ||
          this.isDocumentComptable()) {
          return true;
        }
        return false;
      },

      isDocumentDevis: function () {
        if (this.getTypeDocument() === COLLAB_CONF.TYPE_DOC_ENUMS.DEVIS) {
          return true;
        }
        return false;
      },

      isDocumentDevisSigne: function () {
        if (this.getTypeDocument() === COLLAB_CONF.TYPE_DOC_ENUMS.DEVIS_SIGNE) {
          return true;
        }
        return false;
      },

      isDevis: function () {
        if (this.getTypeDocument() === COLLAB_CONF.TYPE_DOC_ENUMS.DEVIS ||
          this.getTypeDocument() === COLLAB_CONF.TYPE_DOC_ENUMS.DEVIS_SIGNE) {
          return true;
        }
        return false;
      },

      isContratFournisseur: function () {
        if (this.getTypeDocument() === COLLAB_CONF.TYPE_DOC_ENUMS.DOC_CONTRAT_FOURNISSEUR_SIGNE) {
          return true;
        }
        return false;
      },
      isContratFournisseurAccepte: function () {
        if (this.isContratFournisseur() && !_.isNil(this.getDateAcceptation()) && _.isNil(this.getDateRefus())) {
          return true;
        }
        return false;
      },
      isContratFournisseurRefuse: function () {
        if (this.isContratFournisseur() && _.isNil(this.getDateAcceptation()) && !_.isNil(this.getDateRefus())) {
          return true;
        }
        return false;
      },

      isContratAgence: function () {
        if (this.getTypeDocument() === COLLAB_CONF.TYPE_DOC_ENUMS.DOC_CONTRAT_AGENCE_SIGNE) {
          return true;
        }
        return false;
      },

      isContrat: function () {
        if (this.isContratFournisseur() || this.isContratAgence()) {
          return true;
        }
        return false;
      },

      /**
       * Recupère un libelle par rapport au type de document
       * @returns {string}
       */
      getLibelleTypeDocument: function (smallVersion) {
        var libelle = '';
        switch(this.getTypeDocument()) {
          case COLLAB_CONF.TYPE_DOC_ENUMS.DEVIS:
            libelle = 'Devis';
            if(smallVersion) libelle = 'Reçu';
            break;
          case COLLAB_CONF.TYPE_DOC_ENUMS.DEVIS_SIGNE:
            libelle = 'Devis signé';
            if(smallVersion) libelle = 'Signé';
            break;
          case COLLAB_CONF.TYPE_DOC_ENUMS.DEMANDE_DEVIS:
            libelle = 'Demande de devis';
            if(smallVersion) libelle = 'Demande';
            break;
          case COLLAB_CONF.TYPE_DOC_ENUMS.DEMANDE_INTERVENTION:
            libelle = 'Demande d\'intervention';
            if(smallVersion) libelle = 'Demande';
            break;
          case COLLAB_CONF.TYPE_DOC_ENUMS.DEMANDE_CONTRAT:
            libelle = 'Demande de contrat';
            if(smallVersion) libelle = 'Demande';
            break;
          case COLLAB_CONF.TYPE_DOC_ENUMS.CONTRAT:
            libelle = 'Contrat';
            if(smallVersion) libelle = 'Contrat';
            break;
          case COLLAB_CONF.TYPE_DOC_ENUMS.DOC_CONTRAT_FOURNISSEUR_SIGNE:
            // Si accepté
            if(this.isContratFournisseurAccepte()) {
              libelle = 'Contrat signé par le fournisseur et accepté par l\'agence';
              if(smallVersion) libelle = 'Accepté';
            }
            // Si refusé
            else if(this.isContratFournisseurRefuse()) {
              libelle = 'Contrat signé par le fournisseur et refusé par l\'agence';
              if(smallVersion) libelle = 'Refusé';
            }
            // Si en attente
            else{
              libelle = 'Contrat signé par le fournisseur';
              if(smallVersion) libelle = 'Signé';
            }
            break;
          case COLLAB_CONF.TYPE_DOC_ENUMS.DOC_CONTRAT_AGENCE_SIGNE:
            libelle = 'Contrat signé par l\'agence';
            if(smallVersion) libelle = 'Agence';
            break;
          case COLLAB_CONF.TYPE_DOC_ENUMS.ATTESTATION_TVA_SIMPLE:
            libelle = 'Attestation TVA simplifiée';
            if(smallVersion) libelle = 'TVA';
            break;
          case COLLAB_CONF.TYPE_DOC_ENUMS.DECLARATION_SINISTRE:
            libelle = 'Déclaration de sinistre';
            if(smallVersion) libelle = 'Sinistre';
            break;
          case COLLAB_CONF.TYPE_DOC_ENUMS.DOC_TOPIC:
            libelle = 'Document du dossier';
            if(smallVersion) libelle = 'Dossier';
            break;
          case COLLAB_CONF.TYPE_DOC_ENUMS.DOC_COMFACT:
            libelle = 'Document Comfact';
            break;
          case COLLAB_CONF.TYPE_DOC_ENUMS.FACTURE:
            libelle = 'Facture';
            if(smallVersion) libelle = 'Facture';
            break;
          case COLLAB_CONF.TYPE_DOC_ENUMS.DEMANDE_FACTURE:
            libelle = 'Facture + Demande';
            if(smallVersion) libelle = 'Custom';
            break;
          case COLLAB_CONF.TYPE_DOC_ENUMS.FACTURE_DEVIS_SIGNE:
            libelle = 'Facture + Devis signé';
            if(smallVersion) libelle = 'Custom';
            break;
          case COLLAB_CONF.TYPE_DOC_ENUMS.FACTURE_DEMANDE_DEVIS_SIGNE:
            libelle = 'Facture + Demande + Devis signé';
            if(smallVersion) libelle = 'Custom';
            break;
          case COLLAB_CONF.TYPE_DOC_ENUMS.COURRIER_SIMPLE:
            libelle = 'Courrier simple';
            if(smallVersion) libelle = 'Courrier';
            break;
          case COLLAB_CONF.TYPE_DOC_ENUMS.DOC_FOURNISSEUR:
            libelle = 'Document fournisseur';
            if(smallVersion) libelle = 'Document';
            break;
          case COLLAB_CONF.TYPE_DOC_ENUMS.ATTACHMENT:
            libelle = 'Pièce jointe';
            if(smallVersion) libelle = 'PJ';
            break;

          default:
            libelle = this.getNomFichier();
            if(smallVersion) libelle = null;
        }
        return libelle;
      },

      saveDescription: function () {
        var deferred = $q.defer();

        var documentAjax = new DocumentsAjax();
        documentAjax.action = 'saveDescription';
        documentAjax.guid = this.getGuid();
        documentAjax.description = this.getDescription();
        documentAjax.$post(function (json) {
          if (json.success) {
            deferred.resolve(MainService.convertJsonToModel(json, 'document', 'Document', deferred));
          }
          else deferred.reject();
        }, function (error) {
          var msgErreur = 'Erreur (' + error.status + ') : ' + error.statusText;
          deferred.reject('Problème avec Document.saveDescription(), ' + msgErreur);
        });
        return deferred.promise;
      },

      rename: function (nom) {
        var deferred = $q.defer();

        var _this = this;
        var documentAjax = new DocumentsAjax();
        documentAjax.action = 'rename';
        documentAjax.guid = this.getGuid();
        documentAjax.nom = nom;
        documentAjax.$post(function (json) {
          if (json.success) {
            var docRetour = MainService.convertJsonToModel(json, 'document', 'Document', deferred);
            _this.setNom(docRetour.getNom());
            deferred.resolve(_this);
          }
          else deferred.reject();
        }, function (error) {
          var msgErreur = 'Erreur (' + error.status + ') : ' + error.statusText;
          deferred.reject('Problème avec Document.rename(), ' + msgErreur);
        });
        return deferred.promise;
      },

      // Document Source
      getDocumentSource: function () {
        return this.documentSource;
      },
      setDocumentSource: function (document) {
        // Si c'est l'objet document on recupèrte le guid
        if (_.isObject(document) && !document.hasOwnProperty('isModel')) {
          document = new Document(document);
        }
        this.documentSource = document;
      },

      // DocumentOCR
      getDocumentOCR: function () {
        return this.documentOCR;
      },
      setDocumentOCR: function (document) {
        if (_.isObject(document) && _.isNil(document.isModel))
          document = new DocumentOCR(document);

        this.documentOCR = document;
      },
      hasDocumentOCR: function(){
        return (_.isObject(this.getDocumentOCR()));
      },
      documentOcrIsOk: function(){
        if(!this.hasDocumentOCR()) return false;
        if(this.getDocumentOCR().isError()) return false;
        return true;
      },
      getErreurDocumentOCR: function () {
        if(!this.hasDocumentOCR()) return "Le document n'a pas été analysé";
        if(this.getDocumentOCR().isError()) return this.getDocumentOCR().getErreurMessage();
        return "Erreur inconnue";
      },

      // ocrExecuted
      getOcrExecuted: function(){
        return this.ocrExecuted;
      },
      setOcrExecuted: function(nb){
        this.ocrExecuted = nb;
      },

      getTmpIdContenu: function(){
        return this.tmpIdContenu;
      },
      setTmpIdContenu: function(val){
        this.tmpIdContenu = val;
      },

      getTmpIdTopic: function(){
        return this.tmpIdTopic;
      },
      setTmpIdTopic: function(val){
        this.tmpIdTopic = val;
      },
    };



    angular.extend(Document.prototype, Main.prototype);

    return Document;
  }

})();
