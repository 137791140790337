(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.Digicode
   * @description
   * Service pour faire appel au servlets du ICSCloud par le biais de Collabore. Migrer les méthodes qui appel ICSCloud dans ce service si possible
   */

  angular
      .module('collaboreApp')
      .service('ContratIcsCloudService',
      ContratIcsCloudServiceController
      );

  /** @ngInject */
  function ContratIcsCloudServiceController(MainService, $q, ContratIcsCloudResource, ModalsService) {
    var ctrl = this;

    ctrl.searchListContrat = searchListContrat;
    ctrl.searchListContratByImmAndFournisseur = searchListContratByImmAndFournisseur;
    ctrl.searchListContratByRefContratForImmeuble = searchListContratByRefContratForImmeuble;
    ctrl.searchListCompteFournisseurForImmeuble = searchListCompteFournisseurForImmeuble;
    ctrl.searchListImmeuble = searchListImmeuble;
    ctrl.searchListFournisseur = searchListFournisseur;

    function searchListContrat(params){
      var deferred = $q.defer();

      try {

        if(!_.isObject(params)) throw new Error("Le paramètre doit être un objet");
        if(_.isNil(params.idPortefeuille)) throw new Error("Il manque l'id du portefeuille");
        if(_.isNil(params.immeuble) && _.isNil(params.mandat) && _.isNil(params.refContrat) && _.isNil(params.codeMetier) && _.isNil(params.numeroFournisseur))
          throw new Error("Il manque un paramètre obligatoire");

        var paramsSearch = {
          action: 'searchListContrat',
          idPortefeuille: (_.isObject(params.idPortefeuille)) ? params.idPortefeuille.idPortefeuille : params.idPortefeuille
        };

        if(!_.isNil(params.avecEmail))  paramsSearch.avecEmail = params.avecEmail;
        if(!_.isNil(params.immeuble))  paramsSearch.immeuble = params.immeuble;
        if(!_.isNil(params.mandat))    paramsSearch.mandat = params.mandat;
        if(!_.isNil(params.refContrat))    paramsSearch.refContrat = params.refContrat;
        if(!_.isNil(params.codeMetier))    paramsSearch.codeMetier = params.codeMetier;
        if(!_.isNil(params.numeroFournisseur))    paramsSearch.numeroFournisseur = params.numeroFournisseur;
        if(!_.isNil(params.ventilationComptable))    paramsSearch.ventilationComptable = params.ventilationComptable;
        if(!_.isNil(params.seulementAvecComptes))    paramsSearch.seulementAvecComptes = params.seulementAvecComptes;
        if(!_.isNil(params.gerance))    paramsSearch.gerance = params.gerance;

        ContratIcsCloudResource
        .getContrats(paramsSearch)
          .$promise
          .then(function onSuccess(json){
            deferred.resolve(MainService.convertJsonToModel(json, 'contrats', 'Contrat', deferred));
          })
          .catch(function onError(err) {
            deferred.reject(err);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    function searchListContratByRefContratForImmeuble(ref, numImmeuble, idPortefeuille){
      var deferred = $q.defer();

      searchListContrat({
        avecEmail: false,
        immeuble: numImmeuble,
        refContrat: ref,
        ventilationComptable: true,
        seulementAvecComptes: false,
        idPortefeuille: idPortefeuille
      }).then(function(contrats){
        deferred.resolve(contrats);
      }).catch(function onError(err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }

    function searchListContratByImmAndFournisseur(numImmeuble, codeMetier, numeroFournisseur, idPortefeuille, gerance){
      var deferred = $q.defer();

      searchListContrat({
        avecEmail: false,
        immeuble: numImmeuble,
        codeMetier: codeMetier,
        numeroFournisseur: numeroFournisseur,
        ventilationComptable: true,
        seulementAvecComptes: false,
        idPortefeuille: idPortefeuille,
        gerance: gerance
      }).then(function(contrats){
        deferred.resolve(contrats);
      }).catch(function onError(err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }

    function searchListCompteFournisseurForImmeuble(numImmeuble, codeMetier, numeroFournisseur, idPortefeuille){
      var deferred = $q.defer();

      searchListContrat({
        avecEmail: false,
        immeuble: numImmeuble,
        codeMetier: codeMetier,
        numeroFournisseur: numeroFournisseur,
        ventilationComptable: true,
        seulementAvecComptes: false,
        idPortefeuille: idPortefeuille
      }).then(function(contrats){
        deferred.resolve(_.sortBy(contrats, 'datFin'));
      }).catch(function onError(err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }

    function searchListImmeuble(codeMetier, numeroFournisseur, idPortefeuille){
      var deferred = $q.defer();

      searchListContrat({
        avecEmail: false,
        codeMetier: codeMetier,
        numeroFournisseur: numeroFournisseur,
        ventilationComptable: true,
        seulementAvecComptes: false,
        idPortefeuille: idPortefeuille
      }).then(function(contrats){
        var immeubles = [];
        if(_.isArray(contrats) && contrats.length){
          var imms = {};
          for(var c = 0; c < contrats.length; c++){
            var contrat = contrats[c];
            if(!_.isObject(contrat.immeuble)) continue;

            var numImm = contrat.immeuble.noimme;
            if(_.isNil(imms[numImm])) imms[numImm] = contrat.immeuble;

            imms[numImm].addContrat(contrat);
          }
          immeubles = _.sortBy(_.values(imms), 'noimme');
        }
        deferred.resolve(immeubles);
      }).catch(function onError(err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }

    function searchListFournisseur(numImmeuble, idPortefeuille, gerance){
      var deferred = $q.defer();

      searchListContrat({
        avecEmail: false,
        immeuble: numImmeuble,
        ventilationComptable: true,
        seulementAvecComptes: false,
        idPortefeuille: idPortefeuille,
        gerance: gerance
      }).then(function(contrats){
        var fournisseurs = [];
        if(_.isArray(contrats) && contrats.length){
          var fourni = {};
          for(var c = 0; c < contrats.length; c++){
            var contrat = contrats[c];
            if(!_.isObject(contrat.persofournisseur)) continue;

          }

        }
        deferred.resolve(fournisseurs);
      }).catch(function onError(err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }
  }
})();
