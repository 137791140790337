(function () {
    'use strict';

    /**
     * @desc View des autres documents
     * @example <view-documents></view-documents>
     */

    angular
        .module('collaboreApp')
        .component('viewDocuments', {
            bindings: {
                contenu: '<',
                isDevis: '<',
                documents: '<',
                allowDelete: '<',
                saveAuto: '<',
                onUpdate: '&',
                onSelect: '&'
            },
            templateUrl: 'app/topics/evenement/view-documents.component.html',
            controllerAs: 'viewdocumentsctrl',
            controller: viewDocuments

        });

    /*@ngInject*/
    function viewDocuments($scope, $attrs, $sce, bowser, DocumentManagerService, ModalsService) {

        var ctrl = this;
        ctrl.currentPdfView = {
            document: false,
            pdfUrl: false
        };
        ctrl.onUpdatePiecesJointes = onUpdatePiecesJointes;	// Executé par le composant des pièces jointes
        ctrl.onOpenDocument = onOpenDocument;	            // Executé par le composant des pièces jointes lors de l'ouverture d'un doc


        ctrl.$onInit = function () {
        };

        ctrl.$onDestroy = function () {
        };

        /**
         * Lors de la modification
         * @param objUpdate
         * @returns {IPromise<T>}
         */

        /*
        function onUpdatePiecesJointes(objUpdate) {

            var objetUpdate = {'objUpdate': objUpdate};
            try {
                if (!objUpdate) {
                    throw new Error('Il manque l\'objet "objUpdate" !');
                }
                // Update le contenu venant des pièces jointes
                ctrl.contenu = objUpdate.contenu;

                if (objUpdate.saveAuto) {

                    if (objUpdate.document) {
                        if (objUpdate.document.guid === ctrl.currentPdfView.document.guid) {
                            ctrl.currentPdfView.document = false;
                            ctrl.currentPdfView.pdfUrl = false;
                        }

                        if (ctrl.documents.length) {
                            for (var i = 0; i < ctrl.documents.length; i++) {
                                if (objUpdate.document.guid === ctrl.documents[i].guid) {
                                    ctrl.documents.splice(i, 1);
                                }
                            }
                        }
                    }
                    ctrl.onUpdate(objetUpdate);
                }
            }
            catch (err) {
                console.log('[viewDocuments.onUpdatePiecesJointes] Erreur : ' + err.message);
            }
        }*/

        function onUpdatePiecesJointes(objUpdate) {
            var objetUpdate = {'objUpdate': objUpdate};
            try {
                if (!objUpdate) throw new Error('Il manque l\'objet "objUpdate" !');

                // Update le contenu venant des pièces jointes
                ctrl.contenu = objUpdate.contenu;

                ctrl.loadingSaveEvenement = true;
                ctrl.onUpdate(objetUpdate)
                    .then(function(docs) {
                        ctrl.documents = docs;
                    })
                    .catch(function(msg) {
                        console.log(msg);
                    })
                    .finally(function () {
                        ctrl.loadingSaveEvenement = false;
                    });
            }
            catch (err) {
                console.log('[viewDocuments.onUpdatePiecesJointes] Erreur : ' + err.message);
            }
        }

        function onOpenDocument(open) {
            if (open.document) {
                var guid = open.document.getGuid();

                ctrl.currentPdfView.document = open.document;

                if ($attrs.onSelect) {
                    ctrl.onSelect({
                        select: {
                            document: open.document
                        }
                    });
                }

                for (var i = 0; i < ctrl.documents.length; i++) {
                    if (ctrl.documents[i].guid === open.document.guid) {
                        ctrl.documents[i].openView = true;
                    }
                    else {
                        ctrl.documents[i].openView = false;
                    }
                }

                if (bowser.msie) {
                    var urlPdf = DocumentManagerService.getUrlStream(guid, ctrl.contenu.getIdContenu());
                    ctrl.currentPdfView.pdfUrl = $sce.trustAsResourceUrl(encodeURIComponent(urlPdf));
                } else {
                    // Recupère le PDF
                    ctrl.loadingSaveEvenement = true;
                  DocumentManagerService.getStream(guid, ctrl.contenu.getIdContenu()).then(function (url) {
                        ctrl.currentPdfView.pdfUrl = encodeURIComponent(url);
                        ctrl.loadingSaveEvenement = false;
                    }, function (msg) {
                        if (msg.hasOwnProperty('statusText')) {
                            ModalsService.alertErreur(msg.statusText);
                        }

                        ctrl.loadingSaveEvenement = false;
                        ctrl.currentPdfView.document = false;
                        ctrl.currentPdfView.pdfUrl = false;
                    });
                }
            }
        }
    }
})();
