(function() {
	'use strict';

	/**
	 * @desc TagFomulaire
	 * @example <tag-formulaire></tag-formulaire>
	 */

	angular
		.module('collaboreApp')
		.component('tagFormulaire', {
			templateUrl: 'app/tags/tag-formulaire.component.html',
			require: {
				tagCtrl: '?^tag'
			},
			bindings: {
				parentCtrl: '<'		// Controller parent dans le cas de la popover car le composant est appelé dans un template qui l'isole
			},
			controllerAs: 'tagformulairectrl',
			controller: TagFormulaireController
		});

	/*@ngInject*/
	function TagFormulaireController(COLLAB_CONF, $scope, $timeout, TagService, UtilsService, ProxyTag) {

		var ctrl = this;
		ctrl.inPopover = false;
		ctrl.showDieze = true;


		var promiseTag, promiseTimeout;
		var nbMaxTagsSuggeres = COLLAB_CONF.NB_MAX_TAG_SUGGERES_FOR_CONTENU;
		ctrl.tagSuggestions = [];

		ctrl.loadingSuggestion = {
			'show': false,
			'content': COLLAB_CONF.MINI_LOADING
		};

		ctrl.loading = false;

		ctrl.onAddTag = onAddTag;				// Lors de l'ajout d'un tag
		ctrl.onClickTag = onClickTag;		// Lors du click sur un tag
		ctrl.onDeleteTag = onDeleteTag;		// Lors de la suppression d'un tag

		ctrl.$onInit = function () {

			if (ctrl.tagCtrl) {

				ctrl.popoverCtrl = {
					'tagsFermes': false,
					'allowSearchSuggestion': true
				};
				ctrl.showDieze = false;

			} else if (ctrl.parentCtrl) {
				ctrl.inPopover = true;
				ctrl.popoverCtrl = ctrl.parentCtrl;
				ctrl.tagCtrl = ctrl.popoverCtrl.tagCtrl;
			}


			// TODO : Grouper les suggestions par portefeuille ?

			// Ecoute la variable du champ tag
			$scope.$watch('tagformulairectrl.nouveauTag', function (newValue) {

				// Si newValue est defini
				if (angular.isDefined(newValue)) {
					if (ctrl.popoverCtrl.allowSearchSuggestion) {
						ctrl.loadingSuggestion.show = false;


						if (promiseTimeout) {
							$timeout.cancel(promiseTimeout);
						}
						UtilsService.cancelPromise(promiseTag);

						// Si il y a 2 caractères ou plus
						if (newValue.length >= COLLAB_CONF.NB_CARACTERES_AVANT_APPEL_RECHERCHE_TAG_SERVLET) {

							ctrl.tagSuggestions = [];


							ctrl.loadingSuggestion.show = true;

							promiseTimeout = $timeout(function () {
								// Appel des tags avec le string en like
								promiseTag = TagService.get(newValue, nbMaxTagsSuggeres, ctrl.tagCtrl.typeTag).then(function (tags) {

									var n = 0;
									var tagsSuggere = [];
									// Pour chaque tags retourné par la servlet
									angular.forEach(tags, function (tag) {
										delete tag.idTag;
										if (tag.hasOwnProperty('typeTag')) {
											delete tag.typeTag;
										}

										tag.libelle = UtilsService.parseTag(tag.libelle);
										// Si on a pas atteind le nombre max de tags suggérés
										if (n < nbMaxTagsSuggeres) {

											var tagsWhere = [];
											if (angular.isArray(ctrl.tagCtrl.tags)) {
												// Si il y a des tags déjà choisis
												if (ctrl.tagCtrl.tags.length) {
													tagsWhere = UtilsService.where(ctrl.tagCtrl.tags, {libelle: tag.libelle});
												}
											}


											// Si le tag suggere n'est pas dans le tableau des tags
											if (!tagsWhere.length) {
												//if(!tabTmp.length){
												tagsSuggere.push(tag);
												n++;
											}
										}
									});

									ctrl.tagSuggestions = tagsSuggere;
								}, function () {
								}).finally(function () {
									ctrl.loadingSuggestion.show = false;
								});
							}, 800, false);
						}
					}
				}
			});
		};


		function saveTag(newTag, isType) {

			var libelleTag;
			if (angular.isString(newTag)) {
				libelleTag = newTag;
			} else if (angular.isObject(newTag)) {
				libelleTag = newTag.libelle;
			}

			if (!UtilsService.firstDieze(libelleTag)) {
				// Ajoute le diese devant le tag et retire les accents
				libelleTag = UtilsService.parseTag(libelleTag);
			}


			var tag = {
				libelle: libelleTag
			};

			if (isType) {
				tag.typeTag = {
					libelle: isType
				};
			}

			var objTag = new ProxyTag(tag);

			ctrl.nouveauTag = '';

			var tagsWhere = TagService.tagExistIn(ctrl.tagCtrl.tags, objTag);

			// Si le tag n'est pas dans le tableau des tags
			if (!tagsWhere) {

				try {

					if (ctrl.tagCtrl.idContenu) {

						ctrl.loading = true;
						TagService.addTag(ctrl.tagCtrl.idContenu, objTag, ctrl.tagCtrl.tags, isType, ctrl.tagCtrl.limit).then(function (Tags) {

							if (Tags.length) {

								// Supprime le tag de la liste des suggestions
								if (ctrl.tagSuggestions.length) {
									ctrl.tagSuggestions = UtilsService.removeWith(ctrl.tagSuggestions, {'libelle': Tags[0].libelle});
								}

								// Supprime le tag de la liste ferme si elle est affichée
								if (ctrl.popoverCtrl.tagsFermes) {
									if (ctrl.popoverCtrl.tagsFermes.show) {
										if (ctrl.popoverCtrl.tagsFermes.liste.length) {
											ctrl.popoverCtrl.tagsFermes.liste = UtilsService.removeWith(ctrl.popoverCtrl.tagsFermes.liste, Tags[0]);
										}
									}
								}

								ctrl.tagCtrl.update({
									'addTag': Tags[0]
								});
							}
						}).finally(function () {
							ctrl.loading = false;
						});

					} else {
						var objTagTmp;

						if (!ctrl.tagCtrl.typeTag) {

							// Recrée un objet pour eviter les idTag
							objTagTmp = {
								libelle: libelleTag
							};

							// Supprime le tag de la liste des suggestions
							if (ctrl.tagSuggestions.length) {
								ctrl.tagSuggestions = UtilsService.removeWith(ctrl.tagSuggestions, objTagTmp);
							}

							// Supprime le tag de la liste ferme si elle est affichée
							if (ctrl.popoverCtrl.tagsFermes) {
								if (ctrl.popoverCtrl.tagsFermes.show) {
									if (ctrl.popoverCtrl.tagsFermes.liste.length) {
										ctrl.popoverCtrl.tagsFermes.liste = UtilsService.removeWith(ctrl.popoverCtrl.tagsFermes.liste, objTagTmp);
									}
								}
							}

							// Si plus de suggestion autorisé par le modèle
							if (ctrl.tagSuggestions.length === 0) {
								// Si formulaire en mode popover
								if (ctrl.inPopover) {
									// Ferme de popup
									ctrl.popoverCtrl.popoverTags.close();
								}
							}

							if (ctrl.popoverCtrl.tagsFermes) {
								if (ctrl.popoverCtrl.tagsFermes.show) {
									if (ctrl.popoverCtrl.tagsFermes.liste.length === 0) {
										// Si formulaire en mode popup
										if (ctrl.inPopover) {
											// Ferme de popup
											ctrl.popoverCtrl.popoverTags.close();
										}
									}
								}
							}

							//console.log('ctrl.tagSuggestions',ctrl.tagSuggestions);

						} else {

							// Recrée un objet pour eviter les idTag
							objTagTmp = {
								libelle: libelleTag,
								typeTag: {
									libelle: ctrl.tagCtrl.typeTag
								}
							};

							// Si la tag ajouté est un objet et qu'il à comme attribut "typeTag"
							if (angular.isObject(newTag) && newTag.hasOwnProperty('typeTag')) {
								// Ajoute l'idTag à l'objet créé
								objTagTmp.idTag = newTag.idTag;
								// Si l'objet de l'attribut "typeTag" à comme attribut "idTypeTag"
								if (newTag.typeTag.hasOwnProperty('idTypeTag')) {
									//Ajoute cette idTypeTag à l'objet créé
									objTagTmp.typeTag.idTypeTag = newTag.typeTag.idTypeTag;
								}
							}

							// Ajoute le tag à la liste des tag du formulaire de contenu
							//ctrl.tagCtrl.tags = angular.copy([objTagTmp]);
						}

						ctrl.tagCtrl.update({'addTag': objTagTmp});
					}
				}
				catch (err) {

				}
			}
		}

		/**
		 * Lors de l'ajout d'un tag
		 * @param tag
		 */
		function onAddTag(tag) {

			if (!tag) {
				return;
			}

			var allowAdd = true;
			if (ctrl.tagCtrl.idContenu) {
				// Si tag typé
				if (ctrl.tagCtrl.typeTag === 'Projet') {
					allowAdd = false;

					// Supprime tous les tags avant d'ajouter le nouveau
					TagService.deleteAllTags(ctrl.tagCtrl.idContenu, ctrl.tagCtrl.tags, ctrl.tagCtrl.typeTag).then(function (tags) {
						ctrl.tagCtrl.update({'deleteTagsFromContenu': ctrl.tagCtrl.tags});
						ctrl.tagCtrl.tags = tags;
						saveTag(tag, ctrl.tagCtrl.typeTag);
					});

				}
				/*else{
				 TagService.deleteLastTags(ctrl.tagCtrl.idContenu,ctrl.tagCtrl.tags,ctrl.tagCtrl.typeTag,ctrl.tagCtrl.limit).then(function(){
				 saveTag(tag,ctrl.tagCtrl.typeTag);
				 });
				 }*/
			}

			if (allowAdd) {
				saveTag(tag, ctrl.tagCtrl.typeTag);
			}
		}

		/**
		 * Lors du clique sur un tag
		 */
		function onClickTag() {
		}

		/**
		 * Lors de la suppression d'un tag
		 * @param tag
		 */
		function onDeleteTag(objUpdate) {
			ctrl.tagCtrl.update(objUpdate);
		}
	}


})();