(function() {
    'use strict';


    /**
     * @ngdoc factory
     * @name collaboreApp.ProxyTag
     * @description
     * # ProxyTag
     */

    angular.module('collaboreApp').factory('ProxyTag', ProxyTagModel);

    /** @ngInject */
    function ProxyTagModel() {

        function ProxyTag(objetData) {

            var scope = this;

            // Si c'est un objet
            if (angular.isObject(objetData)) {

                if(objetData.hasOwnProperty('libelle')){
                    scope.setLibelleTag(objetData.libelle);
                }
                if(objetData.hasOwnProperty('valeurIcs')){
                    scope.setValeurIcs(objetData.valeurIcs);
                }

                if(objetData.hasOwnProperty('typeTag')){
                    scope.setLibelleTypeTag(objetData.typeTag.libelle);
                }

                if(objetData.hasOwnProperty('portefeuille')){
                    if(angular.isObject(objetData.portefeuille)){
                        scope.setIdPortefeuille(objetData.portefeuille.idPortefeuille);
                    }else if(angular.isNumber(objetData.portefeuille)){
                        scope.setIdPortefeuille(objetData.portefeuille);
                    }
                }

            }
        }

        ProxyTag.prototype = {

            isModel: true,
            isProxy: true,

            // libelleTag
            getLibelleTag: function(){
                return this.libelleTag;
            },
            setLibelleTag: function(val){
                this.libelleTag = val;
            },

            // libelleTypeTag
            getLibelleTypeTag: function(){
                return this.libelleTypeTag;
            },
            setLibelleTypeTag: function(val){
                this.libelleTypeTag = val;
            },

            // valeurIcs
            getValeurIcs: function(){
                return this.valeurIcs;
            },
            setValeurIcs: function(val){
                this.valeurIcs = val;
            },

            // idPortefeuille
            getIdPortefeuille: function(){
                return this.idPortefeuille;
            },
            setIdPortefeuille: function(val){
                this.idPortefeuille = val;
            }

        };

        return ProxyTag;
    }
})();