(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.AdminCapfunService
   * @description
   * # AdminCapfunService
   * Service in the collaboreApp.
   */
  angular
    .module('collaboreApp')
    .service('AdminCapfunService',
      AdminCapfunService
    );

  /** @ngInject */
  function AdminCapfunService(MainService, $q, ModalsService, CapfunResource) {

    var _this = this;
    _this.getCampings = getCampings;

    /**
     * Permet de sauvegarder une categorie de topic
     * @returns {*}
     */
    function getCampings(){
      var deferred = $q.defer();
      try {
        CapfunResource
          .post({action: 'getCampings'})
          .$promise
          .then(function onSuccess(json){
            deferred.resolve(MainService.convertJsonToModel(json, 'groupe', 'Groupe', deferred));
          })
          .catch(function onError(err) {
            deferred.reject(err);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }
  }
})();
