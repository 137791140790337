'use strict';

/**
 * @ngdoc directive
 * @name collaboreApp.directive:hideWhenConnected
 * @description
 * # hideWhenConnected
 */

var hideWhenConnected = function (UserService) {
	return {
		restrict: 'A',
		link: function (scope, element) {
			var hideIfConnected = function() {
				if(UserService.isConnected()) {
					angular.element(element).addClass('hidden');
				} else {
					angular.element(element).removeClass('hidden');
				}
			};
			 
			hideIfConnected();
			scope.$on('connectionStateChanged', hideIfConnected);
		}
	};
};

angular
	.module('collaboreApp')
	.directive('hideWhenConnected',[
		'UserService',
		hideWhenConnected
	]);
