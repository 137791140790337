(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .component('sendToGravitant', {
      templateUrl: 'app/topics/evenement/devis/send-to-gravitant/send-to-gravitant.component.html',
      controller: SendToGravitantController,
      controllerAs: 'sendtogravitantctrl',
      bindings: {
        //destinataires: '<',
        infosEvenement: '<',
        evenement: '<',
        devis: '<',
        //tagsType: '<',
        //textMessage: '<',
        //textSMS: '<',
        isGestion: '<',
        documents: '<',
        onSubmit: '&',

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&'
      }
    });

  /** @ngInject */
  function SendToGravitantController($rootScope, COLLAB_CONF, COLLAB_VALUES, UtilsService, MessageService, ModalsService, TagsService, ProxyDestinataire, ProxyContenuMessage, TopicService) {
    var ctrl = this;

    ctrl.destinatairesHide = null;
    ctrl.objEditorMessage = null;
    ctrl.objEditorSms = null;
    ctrl.onCheckDocument = onCheckDocument;
    ctrl.onClickEditSms = onClickEditSms;
    ctrl.onClickSave = onClickSave;
    ctrl.onClickClose = onClickClose;
    ctrl.onEditorReady = onEditorReady;
    ctrl.onEditorSmsReady = onEditorSmsReady;
    ctrl.onEmitFromChooseGravitants = onEmitFromChooseGravitants;
    ctrl.onInitFromChooseGravitants = onInitFromChooseGravitants;
    ctrl.limitSizeAttachments = COLLAB_CONF.SIZE_LIMIT_ATTACHMENTS;
    ctrl.bytes = UtilsService.bytes;

    ctrl.txtSendOneMailWithAllDest = COLLAB_VALUES.TEXTE_SEND_ONE_MAIL_FOR_ALL_DEST;

    var evenementObjet = null;
    var evenementDestinataires = null;
    var idTopic = null;
    var prefixSMS = null;
    ctrl.tagsType = [];
    ctrl.listGravitants = [];

    ctrl.$onInit = function () {

      ctrl.destinataires = [];
      ctrl.sendSms = angular.copy($rootScope.smsEnabled);
      ctrl.isAlreadySharedOnExtranet = false;

      if(ctrl.resolve) {
        ctrl.isModal = true;
        ctrl.devis = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'devis'));
        ctrl.infosEvenement = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'infosEvenement'));
        ctrl.evenement = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'evenement'));

        //ctrl.destinataires = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'destinataires'));
        //ctrl.tagsType = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'tagsType'));
        //ctrl.textMessage = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'textMessage'));
        //ctrl.textSMS = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'textSMS'));
        ctrl.isGestion = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'isGestion'));
        ctrl.documents = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'documents'));
        //ctrl.partageExtranet = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'partageExtranet'));
      }
      if(!ctrl.evenement && !ctrl.infosEvenement) ModalsService.alertErreur('Pas d\'événement');

      var destinatairesHide = [];
      if(_.isObject(ctrl.infosEvenement)) {
        if(ctrl.isGestion === null) {
          ctrl.isGestion = ctrl.infosEvenement.isGestion;
        }
        if(_.isObject(ctrl.infosEvenement.infosObjet)) {
          idTopic = ctrl.infosEvenement.infosObjet.idTopic;
          evenementObjet = ctrl.infosEvenement.infosObjet.objet;
          evenementDestinataires = ctrl.infosEvenement.infosObjet.destinataires;
          ctrl.tagsType = ctrl.infosEvenement.infosObjet.tagsType;
        }

        destinatairesHide = ctrl.infosEvenement.listDestinatairesHide;
      }
      else if(_.isObject(ctrl.evenement)) {

        idTopic = ctrl.evenement.getIdTopic();
        ctrl.tagsType = ctrl.evenement.getTagsType();

        if(ctrl.isGestion === null) {
          // Si il y a un tag "Propriétaire" alors c'est un événement de gestion
          if (TagsService.hasTagTypeProprietaire(ctrl.tagsType)) ctrl.isGestion = true;
          else ctrl.isGestion = false;
        }

        evenementObjet = ctrl.evenement.getObjet();
        evenementDestinataires = ctrl.evenement.getDestinataires();


        // Si il y a un événement on recupère les groupes qui ne soint pas immeuble, copro etc...
        // Pour les ajouter aux destinataires cachés
        if(_.isNil(ctrl.destinatairesHide)) {
          destinatairesHide = UtilsService.getDestinatairesHideFromEvenement(ctrl.evenement);
        }
      }

      if(_.isArray(ctrl.tagsType)){
        var tagPortefeuille = TagsService.getTagTypePortefeuille(ctrl.tagsType);
        if(tagPortefeuille && tagPortefeuille.getPortefeuille() && tagPortefeuille.getPortefeuille().getPortefeuilleInfos()) prefixSMS = tagPortefeuille.getPortefeuille().getPortefeuilleInfos().getPrefixSms();
      }

      if(_.isNil(prefixSMS)) prefixSMS = UtilsService.getPrefixSmsFromPortefeuilleOrUser();

      if(destinatairesHide.length){
        ctrl.destinatairesHide = [];
        destinatairesHide.map(function(dest){
          ctrl.destinatairesHide.push({
            checked: true,
            groupe: dest
          });
        });
        initTxtMessage(evenementObjet);
      }
      else if(!_.isNil(idTopic)) {
        ctrl.destinatairesHide = [];
        ctrl.loading = true;
        TopicService.getEvenementForIdTopic(idTopic)
          .then(function(event){
            if(!_.isNil(event)){
              if(_.isArray(event.destinataires) && event.destinataires.length){
                event.destinataires.map(function(dest){
                  ctrl.destinatairesHide.push({
                    checked: true,
                    groupe: dest
                  });
                });
              }
              initTxtMessage(event.getObjet());
            }
          })
          .finally(function(){
            ctrl.loading = false;
          });
      }

      ctrl.selectGravitant = ['CS'];
      if(ctrl.isGestion) {
        ctrl.selectGravitant = [COLLAB_CONF.TAG_TYPE_PROPRIETAIRE];
      }

      ctrl.textSMS = 'Cher propriétaire. Nous venons de vous envoyer un devis par mail. Dans l\'attente de votre accord';

      if(!ctrl.documents && ctrl.devis) ctrl.documents = angular.copy(ctrl.devis.getDocumentDevis());
      else if(_.isArray(ctrl.documents)) {
        ctrl.documents = ctrl.documents.map(function(doc){
          doc.checked = true;
          return doc;
        });
        calcTotalSizeDocs();
      }
    };

    ctrl.$onDestroy = function () {
    };

    function initTxtMessage(evenementObjet){
      var retourObjetEvenement = UtilsService.getObjetEvenement(evenementObjet);
      var objetEvenement = retourObjetEvenement.texteObjet;
      var texte = 'Chers membres du conseil Syndical.<br /><br />' +
        'Suite à votre demande ci dessous.<br /><br />' +
        '<blockquote><cite>Evénement</cite><p class="citation">' + objetEvenement + '</p></blockquote>' +
        '<br />' +
        'Nous venons de recevoir le devis en pièce jointe.<br />' +
        'Nous vous remercions de nous faire savoir votre position';

      if(ctrl.isGestion) {
        texte = 'Madame, Monsieur,<br /><br />' +
          'Suite à votre demande ci dessous.<br /><br />' +
          '<blockquote><cite>Evénement</cite><p class="citation">' + objetEvenement + '</p></blockquote>' +
          '<br />' +
          'Nous venons de recevoir le devis en pièce jointe.<br />' +
          'Nous vous remercions de nous faire savoir votre position';
      }
      ctrl.textMessage = texte;
    }

    function initShareOnExtranet(){
      ctrl.isAlreadySharedOnExtranet = false;

      if(_.isArray(ctrl.destinataires) && _.isArray(evenementDestinataires) && evenementDestinataires.length) {

        var nbFound = 0;
        for(var d = 0; d < evenementDestinataires.length; d++) {
          for (var a = 0; a < ctrl.destinataires.length; a++) {
            if (evenementDestinataires[d].getIdGroupe() === ctrl.destinataires[a].groupeCollab.getIdGroupe()) {
              nbFound++;
              break;
            }
          }
        }
        if (nbFound === ctrl.destinataires.length) ctrl.isAlreadySharedOnExtranet = true;

        /*
        for(var d = 0; d < ctrl.evenement.getDestinataires().length; d++) {

          if(!destinataires) {
            if(!ctrl.isGestion && ctrl.evenement.getDestinataires()[d].isMCS()) {
              ctrl.isAlreadySharedOnExtranet = true;
              break;
            }
          }
          else if(angular.isArray(destinataires)) {
            var nbFound = 0;
            for(var a = 0; a < destinataires.length; a++) {
              if(ctrl.evenement.getDestinataires().getIdGroupe() === destinataires[a].groupeCollab.getIdGroupe()) {
                nbFound++;

              }
            }

            if(nbFound === destinataires.length) ctrl.isAlreadySharedOnExtranet = true;
          }
        }*/
      }
    }

    /**
     * Lorsque l'editeur de message est prêt
     * @param obj
     */
    function onEditorReady(obj){
      ctrl.objEditorMessage = obj;
    }

    /**
     * Lorsque l'editeur de SMS est prêt
     * @param obj
     */
    function onEditorSmsReady(obj){
      ctrl.objEditorSms = obj;
    }

    function onCheckDocument(doc){
      calcTotalSizeDocs();
    }

    /**
     * Lors
     */
    function onClickEditSms(){
      ctrl.openSms = !ctrl.openSms;
      /*
      if(!ctrl.openSms) {
        ctrl.textSMS = ctrl.objEditorSms.getData();
      }*/
    }

    /**
     * Permet de savoir si la taille des docs selectionner ne depasse pas la limit par email
     * @returns {boolean}
     */
    function isOverMaxSize() {
      if (ctrl.totalSizeDocs >= ctrl.limitSizeAttachments) return true;
      return false;
    }

    /**
     * Permet de calculer le poid total des pièces jointes
     */
    function calcTotalSizeDocs() {
      var sizeTotal = 0;
      if (ctrl.documents.length) {
        for (var i = 0; i < ctrl.documents.length; i++) {
          if(ctrl.documents[i].checked) sizeTotal = sizeTotal + ctrl.documents[i].size;
        }
      }
      if (sizeTotal === 0) sizeTotal = false;
      ctrl.totalSizeDocs = sizeTotal;
    }

    function checkIfCanShowSendOneMailWithAllDest(listeDest){
      ctrl.showSendOneMailWithAllDest = UtilsService.checkIfCanShowSendOneMailWithAllDest(listeDest);
      if(!ctrl.showSendOneMailWithAllDest) ctrl.sendOneMailWithAllDest = false;
    }

    /**
     * Lors du clique sur le bouton sauvegarder
     */
    function onClickSave(){
      try {
        if(!_.isArray(ctrl.destinataires) || !ctrl.destinataires.length) throw new Error('Aucun destinataire sélectionné !');

        var obj = {
          idTopic: idTopic,
          listObjJSON: []
        };

        // Destinataires Gravitants
        var destinataires = ctrl.destinataires.map(function(dest){
          return new ProxyDestinataire(dest.groupeCollab);
        });

        // Si pas déjà paratagé sur l'extranet
        if(!ctrl.isAlreadySharedOnExtranet) {
          obj.partageExtranet = (ctrl.partageExtranet === "true" || ctrl.partageExtranet === true) ? true : false;
          if(obj.partageExtranet) {
            obj.listProxyDestinataire = angular.copy(destinataires);
            /*
            obj.listProxyDestinataire = destinataires.map(function(dest) {
              return new ProxyDestinataire(dest);
            });*/
          }
        }

        // Destinataires Agences
        var listDestHide = [];
        if(_.isArray(ctrl.destinatairesHide) && ctrl.destinatairesHide.length) {
          ctrl.destinatairesHide.map(function(dest){
            if(dest.checked){
              var proxyDest = new ProxyDestinataire(dest.groupe);

              var sendEmail = false;
              // Si il y a des gravitants
              if(ctrl.listGravitants.length){
                // Parcour la liste des gravitants
                for(var g = 0; g < ctrl.listGravitants.length; g++){
                  // Si le destinataire Agence coché est un des gravitant alors il doit recevoir le mail
                  if(dest.groupe.idGroupe === ctrl.listGravitants[g].idGroupe){
                    sendEmail = true;
                    break;
                  }
                }
              }

              proxyDest.setAllowSendEmail(sendEmail);
              listDestHide = _.unionWith(listDestHide, [proxyDest], _.isEqual);
            }
          });
        }

        destinataires = _.unionWith(destinataires, listDestHide, _.isEqual);

        var proxyMessage = new ProxyContenuMessage();
        proxyMessage.setSendByMail(true);
        if(ctrl.sendOneMailWithAllDest) proxyMessage.setSendOneMailWithAllDest(true);
        //proxyMessage.setIdTopic(this.evenement.getIdTopic());
        proxyMessage.setListeDestinataires(destinataires);
        proxyMessage.setMessage(ctrl.objEditorMessage.getData());

        if(angular.isArray(ctrl.documents) && ctrl.documents.length) {
          var guids = [];
          for(var p = 0; p < ctrl.documents.length; p++) {
            if(ctrl.documents[p].checked) guids.push(ctrl.documents[p].guid);
          }
          proxyMessage.setGuids(guids);
        }

        obj.listObjJSON.push(proxyMessage);

        if(ctrl.sendSms) {
          var proxyMessageSms = new ProxyContenuMessage();
          //proxyMessageSms.setIdTopic(this.evenement.getIdTopic());
          proxyMessageSms.setListeDestinataires(destinataires);

          if(ctrl.openSms && _.isObject(ctrl.objEditorSms)) {
            if(_.isEmpty(ctrl.objEditorSms.getData())) throw new Error('Le SMS ne peut pas être vide !');
            proxyMessageSms.setMessage(ctrl.objEditorSms.getData());
          }
          else proxyMessageSms.setMessage(prefixSMS + COLLAB_CONF.LIAISON_PREFIX_MESSAGE + ctrl.textSMS);

          proxyMessageSms.setSms(true);
          obj.listObjJSON.push(proxyMessageSms);
        }

        /*
        console.log(obj.listObjJSON);
        ctrl.loading = false;
        return;
        */

        ctrl.loading = true;
        MessageService
          .addMessageInTopic(obj)
          .then(function(obj){
            var actions = {addMessages: obj.listMessage};
            if(angular.isObject(obj.evenement)) actions.updateEvenement = obj.evenement;
            emit({actions: actions});
          })
          .catch(function(error){
            ModalsService.alertErreur(error);
          })
          .finally(function(){
            ctrl.loading = false;
          });
      }
      catch(e) {
        ModalsService.alertErreur(e.message);
      }

      //emit({action: 'onSave', obj: obj});
      //ModalsService.alertErreur('Pas de collaborateur sélectionné !');
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      if(ctrl.isModal) ctrl.close({$value: objRetour});
      else ctrl.onSubmit({obj: objRetour})
    }


    function onClickClose(){
      ctrl.dismiss({$value: 'cancel'});
    }

    function onInitFromChooseGravitants(obj){
      if(_.isObject(obj)) {
        if(_.isArray(obj.listGroupes) && obj.listGroupes.length) {
          ctrl.listGravitants = obj.listGroupes;
        }
      }
    }

    /**
     * Lors que le composant enfant "choose-gravitants" emit une info
     * @param obj
     */
    function onEmitFromChooseGravitants(obj) {
      if(_.isObject(obj) && obj.hasOwnProperty('destinataires') && _.isArray(obj.destinataires)) {
        ctrl.destinataires = obj.destinataires;
        initShareOnExtranet();
        checkIfCanShowSendOneMailWithAllDest(ctrl.destinataires);
      }
    }
  }
})();

