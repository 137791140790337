(function () {
  'use strict';

  /**
   * @desc Dashboard Pour Vagues Océanes
   */

  angular
    .module('collaboreApp')
    .component('vaguesOceanesDashboard', {
      templateUrl:  'app/capfun/dashboard/vagues-oceanes-dashboard.component.html',
      controllerAs: 'vaguesoceanesdashboardctrl',
      controller:   VaguesOceanesDashboardController
    });

  /*@ngInject*/
  function VaguesOceanesDashboardController($rootScope, $state) {

    var _this = this;

    _this.$onInit = function(){
      if(!$rootScope.isCapfun) $rootScope.$broadcast('goAccueil');
      if($state.current.name === 'vaguesOceanesDashboard') $state.transitionTo('vaguesOceanesDashboard.recap');
      if(_.isObject($state.current.data)) _this.pageName = $state.current.data.namePage;
    };


    /**
     * TODO: Stocker ici la selection des portefeuilles (campings) pour éviter de rechoisir dans chaque composant enfant
     */
  }
})();
