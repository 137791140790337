(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.OcrFrontService
   * @description
   * # OcrFrontService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('OcrFrontService',
      OcrFrontServiceController
    );

  /** @ngInject */
  function OcrFrontServiceController($q, $injector, MainService, ModalsService, OcrFrontResource, OcrFrontFeedback) {
    var _this = this;

    _this.getListFounisseur = getListFounisseur;
    _this.canCallOcrFront = canCallOcrFront;

    function getListFounisseur(searchObject) {
      var deferred = $q.defer();
      try {
        if(!_.isObject(searchObject)) throw new Error('Le paramètre doit être un objet !');
        if(_.isObject(searchObject.portefeuille)) searchObject.portefeuille = searchObject.portefeuille.cle;
        if(!searchObject.portefeuille || _.isNil(searchObject.portefeuille)) throw new Error('Il manque la clé Portefeuille !');

        OcrFrontResource
          .getListFounisseur(searchObject, function (json) {
            var list = [];
            if(_.isArray(json)){
              list = json.map(function(item){
                return new OcrFrontFeedback(item);
              });
            }
            deferred.resolve(list);
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        //ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }
  }

  function canCallOcrFront(search){
    return (!_.isNil(search.vatNumber) || !_.isNil(search.phone) || !_.isNil(search.email) || !_.isNil(search.cocNumber) || !_.isNil(search.customerBankAccountNumber));
  }
})();
