(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name collaboreApp.ContratService
     * @description
     * # ContratService
     * Service in the collaboreApp.
     */

    angular
        .module('collaboreApp')
        .service('ContratService', ContratService);

    /*@ngInject*/
    function ContratService(MainService, $q, ContratResource, RunJobResource, ModalsService) {

        var ctrl = this;

        ctrl.updateContratWithProxyContrat = updateContratWithProxyContrat;
        ctrl.updateFieldByField = updateFieldByField;

        ctrl.addDocumentsContratFournisseurSigne = addDocumentsContratFournisseurSigne;
        //ctrl.updateDocumentContratFournisseurSigne = updateDocumentContratFournisseurSigne;
        //ctrl.deleteDocumentContratFournisseurSigne = deleteDocumentContratFournisseurSigne;

        ctrl.doRelance = doRelance;
        ctrl.duplicateContrat = duplicateContrat;
        ctrl.regenerateDocument = regenerateDocument;

        ctrl.accepteContratBySyndic = accepteContratBySyndic;
        ctrl.refuseContratBySyndic = refuseContratBySyndic;

        ctrl.deleteDocumentByGuid = deleteDocumentByGuid;
        ctrl.retryContrat = retryContrat;

        ctrl.signeContrat = signeContrat;


        function postServlet(action, obj) {
            var deferred = $q.defer();
            try {
                if (!action) throw new Error('Le paramètre "action" n\'existe pas !');

                if (!angular.isObject(obj) && !angular.isNumber(obj)) throw new Error('Le paramètre "obj" doit être un objet un un nombre !');
                if (angular.isObject(obj) && !obj.hasOwnProperty('idContrat') && !obj.hasOwnProperty('idContenu')) throw new Error('Le paramètre "obj" doit avoir un idContrat ou idContenu !');

                var sendParams = angular.merge({}, obj);
                sendParams.action = action;
                sendParams.idContrat = (angular.isNumber(obj)) ? obj : ((obj.hasOwnProperty('idContrat')) ? obj.idContrat : obj.idContenu);
                if(sendParams.hasOwnProperty('idContenu')) delete sendParams.idContenu;

                ContratResource
                    .post(sendParams, function (json) {
                        deferred.resolve(MainService.convertJsonToModel(json, ['contrat', 'listContrats'], 'ContenuEvenementContrat', deferred));
                    }, function (msg) {
                        deferred.reject(msg);
                    });
            }
            catch (e) {
                ModalsService.alertErreur(e.message);
                deferred.reject(e.message);
            }
            return deferred.promise;
        }

        /* Public Methods */

        /**
         * Permet de modifier un contrat avec proxyContrat
         * @param obj
         * @returns {IPromise<T>}
         */
        function updateContratWithProxyContrat(obj) {
            var deferred = $q.defer();
            postServlet('updateContrat', obj)
                .then(function (ret) {
                    deferred.resolve(ret);
                })
                .catch(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        /**
         * Permet de modifier un devis champ par champ
         * @param obj
         * @returns {IPromise<T>}
         */
        function updateFieldByField(obj) {
            var deferred = $q.defer();
            postServlet('update', obj)
                .then(function (ret) {
                    deferred.resolve(ret);
                })
                .catch(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        /**
         * Permet d'accepter un contrat
         * @param obj
         * @returns {IPromise<T>}
         */
        function accepteContratBySyndic(obj) {
            var deferred = $q.defer();
            postServlet('accepteContratBySyndic', obj)
                .then(function (ret) {
                    deferred.resolve(ret);
                })
                .catch(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        /**
         * Permet de refuser un contrat
         * @param obj
         * @returns {IPromise<T>}
         */
        function refuseContratBySyndic(obj) {
            var deferred = $q.defer();
            postServlet('refuseContratBySyndic', obj)
                .then(function (ret) {
                    deferred.resolve(ret);
                })
                .catch(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        /**
         * Permet de faire une relance d'un devis
         * @param obj
         * @returns {IPromise<T>}
         */
        function doRelance(idDevis) {
            var deferred = $q.defer();
            postServlet('doRelance', idDevis)
                .then(function (ret) {
                    deferred.resolve(ret);
                })
                .catch(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        /**
         * Permet de dupliquer un contrat
         * @param idContrat
         * @param description
         */
        function duplicateContrat(idContrat, obj) {
            var deferred = $q.defer();
            var params = {
                idContrat: idContrat
            };

            if(_.isObject(obj)) {
              if(obj.hasOwnProperty('sendMailWhenCreateDemande')) params.sendMailWhenCreateDemande = obj.sendMailWhenCreateDemande;
              if(obj.hasOwnProperty('hideProprietaireInDocument')) params.hideProprietaireInDocument = obj.hideProprietaireInDocument;
            }

            postServlet('duplicateContrat', params)
                .then(function (ret) {
                    deferred.resolve(ret);
                })
                .catch(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        /**
         * Permet de regénérer le document demande
         * @param obj
         * @returns {IPromise<T>}
         */
        function regenerateDocument(idContrat, demandeOptions) {
            var deferred = $q.defer();
            var params = {
              idContrat: idContrat
            };
            if(demandeOptions != null) {
              params.hideProprietaireInDocument = demandeOptions.hideProprietaireInDocument;
            }
            postServlet('regenerateDocument', params)
                .then(function (ret) {
                    deferred.resolve(ret);
                })
                .catch(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        /**
         * Permet de change de contrat fournisseur signé
         * @param obj
         * @returns {IPromise<T>}
         */
        function addDocumentsContratFournisseurSigne(obj) {

            var deferred = $q.defer();
            postServlet('addDocumentsContratFournisseurSigne', obj)
                .then(function (ret) {
                    deferred.resolve(ret);
                })
                .catch(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        /**
         * Permet de change de contrat fournisseur signé
         * @param obj
         * @returns {IPromise<T>}
         */
        /*
        function updateDocumentContratFournisseurSigne(obj) {

            var deferred = $q.defer();
            postServlet('changeDocumentContratFournisseurSigne', obj)
                .then(function (ret) {
                    deferred.resolve(ret);
                })
                .catch(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }*/

        /**
         * Permet de supprimer le contrat fournisseur signé
         * @param obj
         * @returns {IPromise<T>}
         */
        /*
        function deleteDocumentContratFournisseurSigne(idDevis) {
            var deferred = $q.defer();
            postServlet('deleteDocumentContratFournisseurSigne', idDevis)
                .then(function (ret) {
                    deferred.resolve(ret);
                })
                .catch(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }*/

        /**
         * Permet de supprimer un document du contrat
         * @param obj
         * @returns {IPromise<T>}
         */
        function deleteDocumentByGuid(obj) {
            var deferred = $q.defer();
            postServlet('deleteDocumentByGuid', obj)
                .then(function (ret) {
                    deferred.resolve(ret);
                })
                .catch(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        /**
         * Permet de relancer la création ou la modification d'un contrat si échoué
         * @param id
         */
        function retryContrat(id) {
            var deferred = $q.defer();
            try {
                if (!angular.isNumber(id)) throw new Error('Le paramètre n\'est pas numérique !');
                var param = {
                    idContrat: id
                };
                RunJobResource
                    .runJobDevis(param, function (json) {
                        if(json.success) deferred.resolve(true);
                        else deferred.reject('Il y a eu un problème lors de la relance de création ou modification du contrat N° ' + id + '<br />' + json.message);
                    }, function (msg) {
                        deferred.reject(msg);
                    });
            }
            catch (e) {
                ModalsService.alertErreur(e.message);
                deferred.reject(e.message);
            }
            return deferred.promise;
        }

        /**
         * Permet de signer un contrat
         * @param objParams
         * @returns {*}
         */
        function signeContrat(objParams){
            if(_.isObject(objParams)) {

                var deferred = $q.defer();
                var params = {};

                if(_.isObject(objParams.contenu) && !_.isNil(objParams.contenu.idContenu)) {
                    params.idContenu = objParams.contenu.idContenu;
                }
                else if(_.isString(objParams.contenu)) {
                    params.idContenu = objParams.contenu;
                }

                if(!_.isNil(objParams.bpa)) params.bpa = objParams.bpa;
                if(!_.isNil(objParams.date)) params.date = objParams.date;
                if(_.isObject(objParams.tampon)) params.idTampon = objParams.tampon.idTampon;
                if(!_.isNil(objParams.page)) params.page = objParams.page;
                if(!_.isNil(objParams.coordonnees)) params.coordonnees = objParams.coordonnees;
                if(!_.isNil(objParams.sendMail)) params.sendMail = objParams.sendMail;
                if(!_.isNil(objParams.guidDocumentContratFournisseurSigne)) params.guidDocumentContratFournisseurSigne = objParams.guidDocumentContratFournisseurSigne;
                if(!_.isNil(objParams.paraphe)) params.paraphe = objParams.paraphe;

                postServlet('signeContrat', params)
                    .then(function (ret) {
                        deferred.resolve(ret);
                    })
                    .catch(function (reason) {
                        deferred.reject(reason);
                    });
                return deferred.promise;
            }
        }
    }

})();
