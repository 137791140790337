'use strict';

/**
 * @ngdoc provider
 * @name collaboreApp.NotificationsToastProvider
 * @description
 * # NotificationsToastProvider
 * Provider in the collaboreApp.
 */

angular.module('collaboreApp').provider('NotificationsToast', [
	function() {

		var notification = false;

		this.getPermissionNotificationDesktop = function () {

			// https://developer.mozilla.org/fr/docs/Web/API/notification
			// Voyons si le navigateur supporte les notifications
			if (('Notification' in window)) {
				notification = window.Notification || window.mozNotification || window.webkitNotification;

				// Si les notifications ne sont pas accepté on demande l'authorisation
				if (notification.permission !== 'denied') {

					notification.requestPermission(function (permission) {

						// Quelque soit la réponse de l'utilisateur, nous nous assurons de stocker cette information
						if (!('permission' in notification)) {
							notification.permission = permission;
						}
					});
				}
			}
		};

		this.$get = [ function() {

			return {

				NotificationHTML5: function(){
					return notification;
				}
			};
		}];
	}
]);
