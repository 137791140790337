(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.InfosSinistre
   * @description
   * # InfosSinistre
   * Service in the collaboreApp.
   */

  angular.module('collaboreApp').factory('InfosSinistre', InfosSinistreModel);

  /** @ngInject */
  function InfosSinistreModel(Main, UtilsService, PersoIcs, ContratIcs, NatureSinistre, Document) {

    var InfosSinistre = function(objetData) {
      Main.apply(this, arguments);
      this.hydrate(objetData);
    };

    InfosSinistre.prototype = {

      getNatureSinistre: function(value){
        if (arguments.length) {
          this.setNatureSinistre(value);
        } else {
          return this.natureSinistre;
        }
      },
      setNatureSinistre: function(objet){
        var natureSinistre = objet;
        if(angular.isObject(natureSinistre) && !natureSinistre.isModel){
          natureSinistre = new NatureSinistre(natureSinistre);
        }
        this.natureSinistre = natureSinistre;
      },

      getDateSinistre: function(value){
        if (arguments.length) {
          this.setDateSinistre(value);
        } else {
          return this.dateSinistre;
        }
      },
      getDateSinistreFormat: function(toDate){
        return UtilsService.parseDate(this.dateSinistre, toDate);
      },
      setDateSinistre: function(val){
        this.dateSinistre = val;
      },

      getExpert: function(){
        return this.expert;
      },
      setExpert: function(objet){
        var expert = objet;
        if(angular.isObject(expert) && !expert.isModel){
          expert = new PersoIcs(expert);
        }
        this.expert = expert;
      },

      getAssureur: function(){
        return this.assureur;
      },
      setAssureur: function(objet){
        var assureur = objet;
        if(angular.isObject(assureur) && !assureur.isModel){
          assureur = new ContratIcs(assureur);
        }
        this.assureur = assureur;
      },

      // Document DeclarationSinistre
      getDocumentDeclarationSinistre: function(){
        return this.documentDeclarationSinistre;
      },
      setDocumentDeclarationSinistre: function(valDocumentdocument){

        var instanceDocument = valDocumentdocument;
        // Si c'est l'objet document on recupèrte le guid
        if(angular.isObject(valDocumentdocument) && !valDocumentdocument.hasOwnProperty('isModel')){
          instanceDocument = new Document(valDocumentdocument);
          valDocumentdocument = instanceDocument;
        }

        this.documentDeclarationSinistre = valDocumentdocument;
      },
      hasDeclarationSinistre: function(){
        if(this.getDocumentDeclarationSinistre()){
          return true;
        }
        return false;
      },

      getCommentaire: function(value){
        if (arguments.length) this.setCommentaire(value);
        else return this.commentaire;
      },
      setCommentaire: function(val){
        this.commentaire = val;
      },

      getNumRar: function(value){
        if (arguments.length) this.setNumRar(value);
        else return this.numRar;
      },
      setNumRar: function(val){
        this.numRar = val;
      }
    };

    angular.extend(InfosSinistre.prototype, Main.prototype);

    InfosSinistre.prototype.model = 'InfosSinistre';

    return InfosSinistre;
  }
})();
