'use strict';

/**
 * @ngdoc service
 * @name collaboreApp.Personnalite
 * @description
 * # Personnalite
 * Service in the collaboreApp.
 */

angular.module('collaboreApp').factory('Personnalite',[
  '$injector',
  'UtilsService',
  function($injector, UtilsService) {

    function Personnalite(objetData) {
      if (objetData) {
        this.setData(objetData);
      }
    }

    Personnalite.prototype = {

      isModel: true,
      isPersonnalite: true,
      model: 'Personnalite',

      setData: function(objetData) {
        angular.extend(this, objetData);
      },

      // Nom
      getNom: function(isPersoICS){
        var nom = '';

        if(this.getNompr()!=='' && this.getNompr()!==false && angular.isDefined(this.getNompr())){
          if(this.getCodelib()!=='' && this.getCodelib()!==false && angular.isDefined(this.getCodelib()) && !isPersoICS){
            nom = this.getCodelib()+' ';
          }
          nom += this.getNompr();
        }else if(this.getNomper()!=='' && this.getNomper()!==false && angular.isDefined(this.getNomper())){
          if(this.getCodelib()!=='' && this.getCodelib()!==false && angular.isDefined(this.getCodelib()) && !isPersoICS){
            nom = this.getCodelib()+' ';
          }
          nom += this.getNomper();
        }

        if(this.getLibelle()!=='' && this.getLibelle()!==false && angular.isDefined(this.getLibelle())){
          nom = (_.isEmpty(nom)) ? this.getLibelle() : nom + ' - '+this.getLibelle();
        }

        return nom;
      },

      // NoPerso
      getNoperso: function(){
        return this.noperso;
      },
      setNoperso: function(valNoperso){
        this.noperso = valNoperso;
      },

      // Codelib
      getCodelib: function(){
        return this.codelib;
      },
      setCodelib: function(valCodelib){
        this.codelib = valCodelib;
      },

      // Nomper
      getNomper: function(){
        return this.nomper;
      },
      setNomper: function(valNomper){
        this.nomper = valNomper;
      },

      // Nompr
      getNompr: function(){
        return this.nompr;
      },
      setNompr: function(valNompr){
        this.nompr = valNompr;
      },

      // NomRepresentant
      getNomRepresentant: function(){
        return this.norep;
      },
      setNomRepresentant: function(val){
        this.norep = val;
      },

      // Metier
      getMetier: function(){
        return this.metier;
      },
      setMetier: function(val){
        this.metier = val;
      },

      // Numero
      getNumero: function(){
        return this.numero;
      },
      setNumero: function(val){
        this.numero = val;
      },

      // datenai
      getDatenai: function(enableFormatDate){
        return UtilsService.parseDateForProperty('datenai',this,enableFormatDate);
      },
      setDatenai: function(val){
        this.datenai = val;
      },

      // lieunai
      getLieunai: function(){
        return this.lieunai;
      },
      setLieunai: function(val){
        this.lieunai = val;
      },

      // NoPersoMail
      getNopersoMail: function(){
        return this.nopersoMail;
      },
      setNopersoMail: function(valNopersoMail){
        this.nopersoMail = valNopersoMail;
      },

      // Mail
      getMail: function(){
        return this.mail;
      },
      setMail: function(valMail){
        this.mail = valMail;
      },

      // Mails
      getMails: function(){
        if(!_.isArray(this.mails)){
          this.mails = [];
        }
        return this.mails;
      },
      setMails: function(arrayMails){
        this.mails = arrayMails;
      },

      // TelephonesFixe
      getTelephonesFixe: function(){
        if(!_.isArray(this.telephonesFixe)){
          this.telephonesFixe = [];
        }
        return this.telephonesFixe;
      },
      setTelephonesFixe: function(arraytelephonesFixe){
        this.telephonesFixe = arraytelephonesFixe;
      },

      // TelephonesMobile
      getTelephonesMobile: function(){
        if(!_.isArray(this.telephonesMobile)){
          this.telephonesMobile = [];
        }
        return this.telephonesMobile;
      },
      setTelephonesMobile: function(arraytelephonesMobile){
        this.telephonesMobile = arraytelephonesMobile;
      },

      // Notel1
      getNotel1: function(){
        return this.notel1;
      },
      setNotel1: function(valNotel1){
        this.notel1 = valNotel1;
      },
      // Notel2
      getNotel2: function(){
        return this.notel2;
      },
      setNotel2: function(valNotel2){
        this.notel2 = valNotel2;
      },

      getPortables: function(){
        var tel1 = this.getNotel1();
        var tel2 = this.getNotel2();
        var telPortable = [];
        if (tel1 !== '' && tel1 && UtilsService.isTelMobile(tel1)) {
          telPortable.push(tel1);
        }
        if (tel2 !== '' && tel2 && UtilsService.isTelMobile(tel2)) {
          telPortable.push(tel2);
        }

        var telsMobiles = this.getTelephonesMobile();
        if (_.isArray(telsMobiles) && telsMobiles.length) {
          for(var t = 0; t < telsMobiles.length; t++){
            if (UtilsService.isTelMobile(telsMobiles[t])) {
              telPortable.push(telsMobiles[t]);
            }
          }
        }

        if(this.type === 'Fournisseur') {
          var tel1s = this.getNotel1s();
          if (tel1s !== '' && tel1s && UtilsService.isTelMobile(tel1s)) {
            telPortable.push(tel1s);
          }
          var tel2s = this.getNotel2s();
          if (tel2s !== '' && tel2s && UtilsService.isTelMobile(tel2s)) {
            telPortable.push(tel2s);
          }
        }

        return telPortable;
      },

      // Nofax
      getNofax: function(){
        return this.nofax;
      },
      setNofax: function(valNofax){
        this.nofax = valNofax;
      },

      // Telephones
      getTelephones: function(){

        var telephones = [];

        if(!_.isNil(this.getNotel1()) && !_.isEmpty(this.getNotel1()))
          telephones.push(this.getNotel1());

        if(!_.isNil(this.getNotel2()) && !_.isEmpty(this.getNotel2()))
          telephones.push(this.getNotel2());

        return telephones;
      },

      // Libelle
      getLibelle: function(){
        var retour = false;
        if(this.hasOwnProperty('libelle')){
          retour = this.libelle;
        }
        return retour;
      },
      setLibelle: function(valLibelle){
        this.libelle = valLibelle;
      },

      // Ad1
      getAd1: function(){
        return this.ad1;
      },
      setAd1: function(valAd1){
        this.ad1 = valAd1;
      },

      // Ad2
      getAd2: function(){
        return this.ad2;
      },
      setAd2: function(valAd2){
        this.ad2 = valAd2;
      },

      // Ad3
      getAd3: function(){
        return this.ad3;
      },
      setAd3: function(valAd3){
        this.ad3 = valAd3;
      },

      // Ad4
      getAd4: function(){
        return this.ad4;
      },
      setAd4: function(valAd4){
        this.ad4 = valAd4;
      },

      // Ad5
      getAd5: function(){
        return this.ad5;
      },
      setAd5: function(valAd5){
        this.ad5 = valAd5;
      },

      // Ad1s
      getAd1s: function(){
        return this.ad1s;
      },
      setAd1s: function(valAd1s){
        this.ad1s = valAd1s;
      },

      // Ad2s
      getAd2s: function(){
        return this.ad2s;
      },
      setAd2s: function(valAd2s){
        this.ad2s = valAd2s;
      },

      // Ad3s
      getAd3s: function(){
        return this.ad3s;
      },
      setAd3s: function(valAd3s){
        this.ad3s = valAd3s;
      },

      // Ad4s
      getAd4s: function(){
        return this.ad4s;
      },
      setAd4s: function(valAd4s){
        this.ad4s = valAd4s;
      },

      // Ad5s
      getAd5s: function(){
        return this.ad5s;
      },
      setAd5s: function(valAd5s){
        this.ad5s = valAd5s;
      },

      // nomban
      getNomban: function(){
        return this.nomban;
      },
      setNomban: function(val){
        this.nomban = val;
      },

      // noetab
      getNoetab: function(){
        return this.noetab;
      },
      setNoetab: function(val){
        this.noetab = val;
      },

      // noguic
      getNoguic: function(){
        return this.noguic;
      },
      setNoguic: function(val){
        this.noguic = val;
      },

      // nocpte
      getNocpte: function(){
        return this.nocpte;
      },
      setNocpte: function(val){
        this.nocpte = val;
      },

      // codeetr
      getCodeetr: function(){
        return this.codeetr;
      },
      setCodeetr: function(val){
        this.codeetr = val;
      },

      // profess
      getProfess: function(){
        return this.profess;
      },
      setProfess: function(val){
        this.profess = val;
      },

      // datdu
      getDatdu: function(enableFormatDate){
        return UtilsService.parseDateForProperty('datdu',this,enableFormatDate);
      },
      setDatdu: function(val){
        this.datdu = val;
      },

      // datau
      getDatau: function(enableFormatDate){
        return UtilsService.parseDateForProperty('datau',this,enableFormatDate);
      },
      setDatau: function(val){
        this.datau = val;
      },

      getRue: function(inline){
        var adresse = '';
        if(angular.isDefined(this.getAd1()) && this.getAd1()!=='') adresse += this.getAd1()+'\n';
        if(angular.isDefined(this.getAd2()) && this.getAd2()!=='') adresse += this.getAd2()+'\n';
        if(inline && adresse !== ''){
          if(inline === true) {adresse = adresse.slice(0,-1);}
          else {adresse = adresse.slice(0,-(inline.length));}
        }
        return adresse;
      },
      getCodePostal: function(){
        var retour = '';
        if(this.getAd3()) retour = this.getAd3();
        return retour;
      },
      getVille: function(){
        var retour = '';
        if(this.getAd5()) retour = this.getAd5();
        if(retour==='' && this.getAd4()) retour = this.getAd4();
        return retour;
      },

      getRueBis: function(inline){
        var adresse = '';
        if(angular.isDefined(this.getAd1s()) && this.getAd1s()!=='') adresse += this.getAd1s()+'\n';
        if(angular.isDefined(this.getAd2s()) && this.getAd2s()!=='') adresse += this.getAd2s()+'\n';
        if(inline && adresse !== ''){
          if(inline === true) {adresse = adresse.slice(0,-1);}
          else {adresse = adresse.slice(0,-(inline.length));}
        }
        return adresse;
      },
      getCodePostalBis: function(){
        var retour = '';
        if(this.getAd3s()) retour = this.getAd3s();
        return retour;
      },
      getVilleBis: function(){
        var retour = '';
        if(this.getAd5s()) retour = this.getAd5s();
        if(retour==='' && this.getAd4s()) retour = this.getAd4s();
        return retour;
      },

      // Adresse
      getAdresse: function(inline){
        var adresse = '';

        if(angular.isDefined(this.getAd1()) && this.getAd1()!==''){
          if(inline === true) {adresse += this.getAd1()+' ';}
          else {
            if(inline) {adresse += this.getAd1()+inline;}
            else {adresse += '<div>'+this.getAd1()+'</div>';}
          }
        }
        if(angular.isDefined(this.getAd2()) && this.getAd2()!==''){
          if(inline === true) {adresse += this.getAd2()+' ';}
          else {
            if(inline) {adresse += this.getAd2()+inline;}
            else {adresse += '<div>'+this.getAd2()+'</div>';}
          }
        }
        if(angular.isDefined(this.getAd3()) && this.getAd3()!==''){
          if(inline === true) {adresse += this.getAd3()+' ';}
          else {
            if(inline) {adresse += this.getAd3()+inline;}
            else {adresse += '<div>'+this.getAd3()+'</div>';}
          }
        }
        if(angular.isDefined(this.getAd4()) && this.getAd4()!==''){
          if(inline === true) {adresse += this.getAd4()+' ';}
          else {
            if(inline) {adresse += this.getAd4()+inline;}
            else {adresse += '<div>'+this.getAd4()+'</div>';}
          }
        }
        if(angular.isDefined(this.getAd5()) && this.getAd5()!==''){
          if(inline === true) {adresse += this.getAd5()+' ';}
          else {
            if(inline) {adresse += this.getAd5()+inline;}
            else {adresse += '<div>'+this.getAd5()+'</div>';}
          }
        }

        if(inline && adresse !== ''){
          if(inline === true) {adresse = adresse.slice(0,-1);}
          else {adresse = adresse.slice(0,-(inline.length));}

        }
        return adresse;
      },

      // Adresse Bis
      getAdresseBis: function(inline){
        var adresse = '';

        if(angular.isDefined(this.getAd1s()) && this.getAd1s()!==''){
          if(inline === true) {adresse += this.getAd1s()+' ';}
          else {
            if(inline) {adresse += this.getAd1s()+inline;}
            else {adresse += '<div>'+this.getAd1s()+'</div>';}
          }
        }
        if(angular.isDefined(this.getAd2s()) && this.getAd2s()!==''){
          if(inline === true) {adresse += this.getAd2s()+' ';}
          else {
            if(inline) {adresse += this.getAd2s()+inline;}
            else {adresse += '<div>'+this.getAd2s()+'</div>';}
          }
        }
        if(angular.isDefined(this.getAd3s()) && this.getAd3s()!==''){
          if(inline === true) {adresse += this.getAd3s()+' ';}
          else {
            if(inline) {adresse += this.getAd3s()+inline;}
            else {adresse += '<div>'+this.getAd3s()+'</div>';}
          }
        }
        if(angular.isDefined(this.getAd4s()) && this.getAd4s()!==''){
          if(inline === true) {adresse += this.getAd4s()+' ';}
          else {
            if(inline) {adresse += this.getAd4s()+inline;}
            else {adresse += '<div>'+this.getAd4s()+'</div>';}
          }
        }
        if(angular.isDefined(this.getAd5s()) && this.getAd5s()!==''){
          if(inline === true) {adresse += this.getAd5s()+' ';}
          else {
            if(inline) {adresse += this.getAd5s()+inline;}
            else {adresse += '<div>'+this.getAd5s()+'</div>';}
          }
        }

        if(inline && adresse !== ''){
          if(inline === true) {adresse = adresse.slice(0,-1);}
          else {adresse = adresse.slice(0,-(inline.length));}

        }
        return adresse;
      },

      getAdresses: function(withoutTMP){
        var adresses = [];
        if(this.getRue() !== '') {
          adresses.push({
            nomPrenom: this.getNom(),
            rue: this.getRue(),
            codePostal: this.getCodePostal(),
            ville: this.getVille()
          });
        }
        if(this.getRueBis() !== '') {
          adresses.push({
            nomPrenom: this.getNom(),
            rue: this.getRueBis(),
            codePostal: this.getCodePostalBis(),
            ville: this.getVilleBis()
          });
        }
        if(!withoutTMP && _.isArray(this.adressesTMP) && this.adressesTMP.length) {
          for(var i = 0; i < this.adressesTMP.length; i++)
            adresses.push(this.adressesTMP[i]);
        }
        return adresses;
      },

      addAdresseTMP: function(obj){
        if(_.isObject(obj) && obj.custom) {
          if(!_.isArray(this.adressesTMP)) this.adressesTMP = [];
          var find = false;
          var adresses = this.getAdresses(true);
          if(adresses.length) {
            for(var i = 0; i < adresses.length; i++){
              if(adresses[i].nomPrenom === obj.nomPrenom && adresses[i].rue === obj.rue && adresses[i].codePostal === obj.codePostal && adresses[i].ville === obj.ville) {
                find = true;
                break;
              }
            }
          }
          if(this.adressesTMP.length) {
            for(var j = 0; j < this.adressesTMP.length; j++){
              if(this.adressesTMP[j].nomPrenom === obj.nomPrenom && this.adressesTMP[j].rue === obj.rue && this.adressesTMP[j].codePostal === obj.codePostal && this.adressesTMP[j].ville === obj.ville) {
                find = true;
                break;
              }
            }
          }

          if(!find) this.adressesTMP.push(angular.copy(obj));
        }
      },

      setAdressesTMP: function(array){
        if(_.isArray(array)) {
          this.adressesTMP = array;
        }
      },

      // codsitfa
      getCodsitfa: function(){
        return this.codsitfa;
      },
      setCodsitfa: function(val){
        this.codsitfa = val;
      },

      // codperso
      getCodperso: function(){
        return this.codperso;
      },
      setCodperso: function(val){
        this.codperso = val;
      },

      // chemise
      getChemise: function(){
        return this.chemise;
      },
      setChemise: function(val){
        this.chemise = val;
      },

      // expcour
      getExpcour: function(){
        return this.expcour;
      },
      setExpcour: function(val){
        this.expcour = val;
      },

      // stat1
      getStat1: function(){
        return this.stat1;
      },
      setStat1: function(val){
        this.stat1 = val;
      },

      // stat2
      getStat2: function(){
        return this.stat2;
      },
      setStat2: function(val){
        this.stat2 = val;
      },

      // Stat3
      getBloque: function(){
        return this.stat3;
      },
      setBloque: function(valBloque){
        this.stat3 = valBloque;
      },
      isBloque: function(){
        if(this.getBloque()==='true'){
          return true;
        }else{
          return false;
        }
      },
      hasEtatCompta:function(){
        if(this.hasOwnProperty('stat3')){
          if(this.getBloque()!==''){
            return true;
          }
        }
        return false;
      },

      // Type
      getType: function(){
        return this._Type;
      },
      setType: function(valType){
        this._Type = valType;
      },

      // Types
      getTypes: function(){
        return this.types;
      },
      setTypes: function(valType){
        this.types = valType;
      },

      isType: function(type){
        var retour = false;
        if(angular.isDefined(this.getTypes())) {
          var tabTypes = this.getTypes().split(',');
          if(tabTypes.length){
            for(var i = 0 ; i < tabTypes.length ; i++){
              if(tabTypes[i] === type){
                retour = true;
                break;
              }
            }
          }
        }
        return retour;
      },

      isOnlyType: function(type){
        var retour = false;
        if(angular.isDefined(this.getTypes())) {
          var tabTypes = this.getTypes().split(',');
          if(tabTypes.length === 1 && tabTypes[0] === type) retour = true;
        }
        return retour;
      },


      isProprietaire: function(){
        if(this.isType('P')){
          return true;
        }
        else{
          if(this.getType() === 'Proprietaire'){
            return true;
          }
        }
        return false;
      },
      isCoproprietaire: function(){
        if(this.isType('C')){
          return true;
        }
        else{
          if(this.getType() === 'Coproprietaire'){
            return true;
          }
        }
        return false;
      },
      isLocataire: function(){
        if(this.isType('L')){
          return true;
        }
        else{
          if(this.getType() === 'Locataire'){
            return true;
          }
        }
        return false;
      },
      isColocataire: function(){
        if(this.isType('c')){
          return true;
        }
        else{
          if(this.getType() === 'Colocataire'){
            return true;
          }
        }
        return false;
      },
      isIndivision: function(){
        if(this.isType('I')){
          return true;
        }
        else{
          if(this.getType() === 'Indivision'){
            return true;
          }
        }
        return false;
      },
      isFournisseur: function(){
        if(this.isPersoFournisseur || this.isType('F')){
          return true;
        }
        else{
          if(this.getType() === 'Fournisseur'){
            return true;
          }
        }
        return false;
      },
      isOnlyFournisseur: function(){
        if(this.isPersoFournisseur || this.isOnlyType('F')){
          return true;
        }
        else{
          if(this.getType() === 'Fournisseur'){
            return true;
          }
        }
        return false;
      },
      isSalarie: function(){
        if(this.isType('S')){
          return true;
        }
        else{
          if(this.getType() === 'Salarie'){
            return true;
          }
        }
        return false;
      },

      isCaution: function(){
        if(this.isType('q') || this.isType('r')){
          return true;
        }
        else{
          if(this.getType() === 'Caution'){
            return true;
          }
        }
        return false;
      },

      isAssureur: function(){
        if(this.isType('A')){
          return true;
        }
        else{
          if(this.getType() === 'Assureur'){
            return true;
          }
        }
        return false;
      },

      isProprietaireMeuble: function(){
        if(this.isType('M')){
          return true;
        }
        else{
          if(this.getType() === 'Proprietaire Meuble'){
            return true;
          }
        }
        return false;
      },

      isEmployeur: function(){
        if(this.isType('E')){
          return true;
        }
        else{
          if(this.getType() === 'Employeur'){
            return true;
          }
        }
        return false;
      },

      isPayeur: function(){
        if(this.isType('p')){
          return true;
        }
        else{
          if(this.getType() === 'Payeur'){
            return true;
          }
        }
        return false;
      },

      isClient: function(){
        if(this.isType('X')){
          return true;
        }
        else{
          if(this.getType() === 'Client'){
            return true;
          }
        }
        return false;
      },

      isICS: function(){
        if(this.isType('Y') || this.isType('T')){
          return true;
        }
        else{
          if(this.getType() === 'ICS'){
            return true;
          }
        }
        return false;
      },

      // Com
      getCom: function(){
        if(angular.isUndefined(this.com)){
          this.setCom('');
        }
        return UtilsService.nl2br(this.com);
      },
      setCom: function(val){
        this.com = val;
      },

      // cdregl
      getCdregl: function(){
        return this.cdregl;
      },
      setCdregl: function(val){
        this.cdregl = val;
      },

      // regroupe
      getRegroupe: function(){
        return this.regroupe;
      },
      setRegroupe: function(val){
        this.regroupe = val;
      },

      // delpai
      getDelpai: function(){
        return this.delpai;
      },
      setDelpai: function(val){
        this.delpai = val;
      },

      // naf
      getNaf: function(){
        return this.naf;
      },
      setNaf: function(val){
        this.naf = val;
      },

      // noepou
      getNoepou: function(){
        return this.noepou;
      },
      setNoepouf: function(val){
        this.noepou = val;
      },

      // datema
      getDatema: function(enableFormatDate){
        return UtilsService.parseDateForProperty('datema',this,enableFormatDate);
      },
      setDatema: function(val){
        this.datema = val;
      },

      // datedi
      getDatedi: function(enableFormatDate){
        return UtilsService.parseDateForProperty('datedi',this,enableFormatDate);
      },
      setDatedi: function(val){
        this.datedi = val;
      },

      // datede
      getDatede: function(enableFormatDate){
        return UtilsService.parseDateForProperty('datede',this,enableFormatDate);
      },
      setDatede: function(val){
        this.datede = val;
      },

      // nomjf
      getNomjf: function(){
        return this.nomjf;
      },
      setNomjf: function(val){
        this.nomjf = val;
      },

      // rcs
      getRcs: function(){
        return this.rcs;
      },
      setRcs: function(val){
        this.rcs = val;
      },

      // nosecu
      getNosecu: function(){
        return this.nosecu;
      },
      setNosecu: function(val){
        this.nosecu = val;
      },

      // cartesej
      getCartesej: function(){
        return this.cartesej;
      },
      setCartesej: function(val){
        this.cartesej = val;
      },

      // ca
      getCa: function(){
        return this.ca;
      },
      setCa: function(val){
        this.ca = val;
      },

      // nomdir
      getNomdir: function(){
        return this.nomdir;
      },
      setNomdir: function(val){
        this.nomdir = val;
      },

      // siret
      getSiret: function(){
        return this.siret;
      },
      setSiret: function(val){
        this.siret = val;
      },

      // datecartesej
      getDatecartesej: function(enableFormatDate){
        return UtilsService.parseDateForProperty('datecartesej',this,enableFormatDate);
      },
      setDatecartesej: function(val){
        this.datecartesej = val;
      },

      // telep
      getTelep: function(){
        return this.telep;
      },
      setTelep: function(val){
        this.telep = val;
      },

      // nopubcour
      getNopubcour: function(){
        return this.nopubcour;
      },
      setNopubcour: function(val){
        this.nopubcour = val;
      },

      // nopubmail
      getNopubmail: function(){
        return this.nopubmail;
      },
      setNopubmail: function(val){
        this.nopubmail = val;
      },

      // inverses
      getInverses: function(){
        return this.inverses;
      },
      setInverses: function(val){
        if(_.isArray(val) && val.length) {
          var Inverse = $injector.get('Inverse');
          for(var i = 0; i < val.length; i++){
            if(_.isObject(val[i]) && !val[i].isModel) {
              val[i] = new Inverse(val[i]);
            }
          }
          Inverse = null;
        }
        this.inverses = val;
      },

      getCompteFournisseur: function(){
        return this.metier+this.numero;
      },

    };

    return Personnalite;

  }
]);
