(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .run(runBlock);

  /** @ngInject */
  function runBlock($rootScope, $location, amMoment, COLLAB_CONF, $timeout, editableOptions, editableThemes, $state, $anchorScroll, UserService, ConfigService, NotificationsToastServices, UtilsService) {

    /*
    FileUploader.FileDrop.prototype.onDragLeave = function onDragLeave(event) {
      if (event.currentTarget !== this.element[0]) {return;} // some fix code here
      this._preventAndStop(event);
      angular.forEach(this.uploader._directives.over, this._removeOverClass, this);
    };*/

    /*
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'versionCollab': COLLAB_CONF.VERSION_COLLAB
    });
    */
    var infosExtranetFournisseur = null, openByVisicop = false;

    moment.locale('fr'); // OLD
    amMoment.changeLocale('fr'); // Service qui imbrique MomentJS

    UserService.initRootscopeVars();

    $rootScope.displayAlert = false;
    $rootScope.displayInfo = false;

    $rootScope.withWebsockets = false;
    // Si le navigateur supporte les Websockets ET websocket java est activé dans la config de collabore
    if (Modernizr.websockets && COLLAB_CONF.ENABLE_WEBSOCKET_JAVA) $rootScope.withWebsockets = true;

    //var supportsWebSockets = 'WebSocket' in $window || 'MozWebSocket' in $window;

    /*
     // Instances $interval pour les servlet qui check toutes les n secondes
     $rootScope._poolIntervalServlet = {};

     // Instance $interval pour l'auto save
     $rootScope._poolIntervalAutoSaveContenu = {};

     // Instances de CKEDITOR
     $rootScope._poolInstancesCKEDITOR = {};*/

    if (!$rootScope.current.filtreTopics) $rootScope.current.filtreTopics = ConfigService.getPreferencesFiltreTopics();

    /**
     * Permet de supprime une instance de CKEDITOR si elle existe
     * @param itemContenu
     */
    $rootScope.destroyInstandeCKEDITOR = function (itemContenu) {
      if (itemContenu) {
        var id = itemContenu.idContenu;
        if (angular.isUndefined(id) || id === false) id = 0;
        //console.log('id',id);
        // Si une instance de CKEDITOR est en route pour ce contenu alors on la destroy
        if ($rootScope._poolInstancesCKEDITOR.hasOwnProperty(id)) {
          //console.log($rootScope._poolInstancesCKEDITOR[id]);
          $rootScope._poolInstancesCKEDITOR[id].removeAllListeners();
          $rootScope._poolInstancesCKEDITOR[id].destroy();
          //CKEDITOR.remove($rootScope._poolInstancesCKEDITOR[id]);
          delete $rootScope._poolInstancesCKEDITOR[id];
        }

        // Si une instance de CKEDITOR pour les fiches est en route pour ce contenu alors on la destroy
        if ($rootScope._poolInstancesCKEDITOR.hasOwnProperty(id + '_Fiches')) {
          $rootScope._poolInstancesCKEDITOR[id + '_Fiches'].removeAllListeners();
          $rootScope._poolInstancesCKEDITOR[id + '_Fiches'].destroy();
          delete $rootScope._poolInstancesCKEDITOR[id + '_Fiches'];
        }
      }
      else {
        $rootScope._poolInstancesCKEDITOR = {};
      }
    };

    var currentHash = false;
    //var checkInfos;
    //var checkNotifications;
    // Décalage par rapport au heut de page lors d'un scroll vers une ancre

    $rootScope.anchorScroll = {
      init: function(){
        $anchorScroll.yOffset = 140;
      },
      update: function(val){
        $anchorScroll.yOffset = val;
      }
    };
    $rootScope.anchorScroll.init();

    $rootScope.prevToStateBeforeLogout = null;
    $rootScope.prevToParamsBeforeLogout = null;

    // Lors de chaque changement de page
    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {

      $rootScope.toState = toState;
      $rootScope.toParams = toParams;

      //console.log(CKEDITOR.instances);
      $rootScope.$broadcast('hideLoading');

      //$rootScope.currentTopicState = null;

        /*
       console.log('');
       console.log('**********************');
       console.log('onStateChangeStart');
       console.log('**********************');
       console.log('fromState',fromState);
       console.log('fromParams',fromParams);
       console.log('toState',toState);
       console.log('toParams',toParams);
         */


      // Permet de savoir si les pages doivent être ouverte dans la vue habituel de collab ou non
      $rootScope.notInCollab = false;
      if (toState.notInCollab) $rootScope.notInCollab = toState.notInCollab;

      // Si param "visicop"
      if(!_.isNil(toParams.visicop) && toParams.visicop) {
        openByVisicop = true;
        $rootScope.openInVisicop = true;
      }

      // Si param "inIframe"
      if(!_.isNil(toParams.inIframe) && toParams.inIframe) {
        $rootScope.inIframe = true;
      }

      // Si param "noScroll"
      if(!_.isNil(toParams.noScroll) && toParams.noScroll) {
        $rootScope.noScroll = true;
      }

      /*
      // Si arrivé sur Collab par le login
      if(toState.name === "login"){
        if(!_.isNil(toParams.visicop) && toParams.visicop) {
          openByVisicop = true;
          $rootScope.openInVisicop = true;
        }
      }
      // Si il n'y avait pas le paramètre "visicop" lors de l'authentification Collab
      else if(!openByVisicop) {
        $rootScope.openInVisicop = false;
        // Permet quand même d'afficher collab en version Visicop temporairement jsuqu'au changement de page
        if(!_.isNil(toParams.visicop) && toParams.visicop) $rootScope.openInVisicop = true;
      }*/

      $rootScope.enableCallListTopic = true;

      if (toParams.hash) currentHash = toParams.hash;

      // -------- CKEDITOR -----
      // Destroy toutes les instance de CKEDITOR qui on été créé
      angular.forEach($rootScope._poolInstancesCKEDITOR, function (instanceCK) {
        instanceCK.removeAllListeners();
        instanceCK.destroy();
      });
      $rootScope._poolInstancesCKEDITOR = {};
      // -------- FIN CKEDITOR -----

      // Stop l'autosaveservice
      //AutoSaveService.stop();

      NotificationsToastServices.clearAll();

      // Si viens d'une page topic detail et qu'on l'on ne va pas vers une autre page topic detail on supprime les documents
      //if (UtilsService.startsWith(fromState.name, 'topics.detail') && !UtilsService.startsWith(toState.name, 'topics.detail')) DocumentsTopicService.destroy();

      // Si demande de la page des topics
      if (toState.name === 'topics' || toState.name === 'topics.list' || toState.name === 'topics.favoris' || toState.name === 'topics.archives' || toState.name === 'topics.tous') {

        $rootScope.current.pageTopic = parseInt(toParams.topicPage);
        $rootScope.lastTypeListTopic = toState.name;

        // Variables pour la dernière page visité par type de liste topic
        $rootScope.current.pageTopicAccueil = 1;
        $rootScope.current.pageTopicFavoris = 1;
        $rootScope.current.pageTopicArchives = 1;

        // Si page de la liste des favoris
        if (toState.name === 'topics.favoris')  $rootScope.current.pageTopicFavoris = $rootScope.current.pageTopic;

        // Si page de la liste des archives
        else if (toState.name === 'topics.archives') $rootScope.current.pageTopicArchives = $rootScope.current.pageTopic;

        // Si liste normal
        else $rootScope.current.pageTopicAccueil = $rootScope.current.pageTopic;

      }
      // Sinon si page différente de la page detail
      else if (!UtilsService.startsWith(toState.name, 'topics.detail')) {

        $rootScope.current.pageTopic = 1;
        $rootScope.lastTypeListTopic = false;
        $rootScope.current.pageTopicAccueil = 1;
        $rootScope.current.pageTopicFavoris = 1;
        $rootScope.current.pageTopicArchives = 1;

      }
      else if (UtilsService.startsWith(toState.name, 'topics.detail')) {
        if (!$rootScope.lastTypeListTopic) $rootScope.lastTypeListTopic = 'topics.list';
      }

      // Si pas connecté
      if (!UserService.isConnected() && !toParams.fromLogOut && !toParams.cancel && !toParams.erreur) {

        // Recupère le token dans le localstorage
        var valToken = ConfigService.getUserToken();

        if(!_.isNil(toParams.token)) valToken = toParams.token;
        if(!_.isNil(toParams.json)) {
          var json = angular.fromJson(toParams.json);
          valToken = (_.isNil(json.token)) ? null : json.token;
        }

        // Si token dans le local storage
        if ((valToken !== 'false' && valToken && !_.isNil(valToken)) || (toState.name === 'mondevis' || toState.name === 'monEvenement' || toState.authByHash)) {

          var params = {token: valToken};
          if (toState.name === 'mondevis' || toState.name === 'monEvenement' || toState.authByHash) {
            params = {
              login: toParams.login,
              diese: toParams.diese,
              hash: toParams.hash,
              extranet: true,
              authByHash: toState.authByHash
            };
            infosExtranetFournisseur = angular.copy(params);
            infosExtranetFournisseur.idTopic = toParams.idT;
            infosExtranetFournisseur.idContenu = toParams.idC;
            infosExtranetFournisseur.token = toParams.token;
            infosExtranetFournisseur.url = $location.absUrl();

            params = angular.copy(infosExtranetFournisseur);
          }
          connexion(toState, toParams, params);
        }
        else if (toState.authorized) {
          $rootScope.prevToStateBeforeLogout = toState;
          $rootScope.prevToParamsBeforeLogout = toParams;

          if(COLLAB_CONF.AUTH_KERBEROS) connexion(toState, toParams, {});
          else {
            event.preventDefault();
            $state.go('login', {infosExtranetFournisseur: infosExtranetFournisseur}, {reload: true, notify: true, location: true});
          }
        }
        // Si page login ?
        else if(COLLAB_CONF.AUTH_KERBEROS && !toParams.forceManuelLogin) {
          connexion(toState, toParams, {});
        }
      }
    });

    function connexion(toState, toParams, params) {

      $rootScope.mainLoadingCollab = true;
      $rootScope.mainLoadingCollabText = 'Connexion Collab';

      UserService
        .connexion(params)
        .then(function (groupeObj) {
          UtilsService.goRouteAskedOrLogin(toState, toParams);
        })
        .catch(function(error){
          if(_.isObject(error) && !_.isNil(error.code)){
            if(error.code === 401){
              // Déconnexion de l'utilisateur
              UserService.deconnexion();
            }
          }
          // Redirige sur le login
          $state.go('login', {infosExtranetFournisseur: infosExtranetFournisseur, erreur: error.msg, cancel: true});

          //var paramsRoute = {};
          //if(msg === 'cancel') paramsRoute.cancel = true;
          //$state.go('login', paramsRoute, {reload: true, notify: true, location: true});
        })
        .finally(function(){
          $rootScope.mainLoadingCollab = false;
        });
    }

    // Listener sur l'événement logOut
    $rootScope.isTopicList = UtilsService.isTopicList;
    $rootScope.isTopicDetail = UtilsService.isTopicDetail;

    // Si erreur 401 déconnecte et renvoi au login avec un message
    $rootScope.$on('erreur401', function (event, data) {

      // Déconnexion de l'utilisateur
      UserService.deconnexion();

      var msg = COLLAB_CONF.NON_AUTORISE;
      if (data) msg = data;

      // Redirige sur le login
      $state.go('login', {infosExtranetFournisseur: infosExtranetFournisseur, erreur: msg});

      /*
      // Message "non autorisé"
      $timeout(function () {
        $rootScope.$broadcast('msgErreur', msg);
      }, 500);*/
    });

    // Si erreur 500
    /*
    $rootScope.$on('erreur500', function (event, data) {

      // Redirige sur le login
      //console.log('---500---');
      //$state.go('login');

      var msg = COLLAB_CONF.ERREUR_500;
      if (data) msg = data;

      // Message "Problème de servlet"
      $timeout(function () {
        $rootScope.$broadcast('msgErreur', msg);
      }, 500);
    });*/

    // Si erreur 502
    $rootScope.$on('erreur502', function (event, data) {

      var msg = COLLAB_CONF.ERREUR_502;
      if (data) msg = data;

      // Redirige sur le login
      //console.log('---502---');
      $state.go('login', {infosExtranetFournisseur: infosExtranetFournisseur, erreur: msg});

      /*
      // Message "Problème de servlet"
      $timeout(function () {
        $rootScope.$broadcast('msgErreur', msg);
      }, 500);*/
    });

    // Si erreur 503
    $rootScope.$on('erreur503', function (event, data) {

      var msg = COLLAB_CONF.ERREUR_503;
      if (data) msg = data;

      // Redirige sur le login
      //console.log('---503---');
      $state.go('login', {infosExtranetFournisseur: infosExtranetFournisseur, erreur: msg});

      /*
      // Message "Problème de servlet"
      $timeout(function () {
        $rootScope.$broadcast('msgErreur', msg);
      }, 500);*/
    });

    $rootScope.onSocketConnectedFunctions = [];
    $rootScope.$on('socketConnected', function(){
      //console.log('socketConnected', $rootScope.onSocketConnectedFunctions);
      if(_.isArray($rootScope.onSocketConnectedFunctions) && $rootScope.onSocketConnectedFunctions.length) {
        for(var i = 0; i < $rootScope.onSocketConnectedFunctions.length; i++){
          //console.log($rootScope.onSocketConnectedFunctions[i].toString());
          $rootScope.onSocketConnectedFunctions[i]();
        }
        $rootScope.onSocketConnectedFunctions = [];
      }
    });


    //------- Config xeditable -------
    editableOptions.theme = 'bs3'; // bootstrap3 theme. Can be also 'bs2', 'default'
    editableThemes.bs3.inputClass = 'input-sm';
    editableThemes.bs3.buttonsClass = 'btn-xs';

    swal.setDefaults({
      cancelButtonText: 'Annuler',
      confirmButtonColor: '#5AB728',
      customClass: 'collabore-alerte'
    });
  }
})();
