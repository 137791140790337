'use strict';

/**
 * @ngdoc service
 * @name collaboreApp.PortefeuilleInfos
 * @description
 * # PortefeuilleInfos
 * Service in the collaboreApp.
 */

angular.module('collaboreApp').factory('PortefeuilleInfos',[
  function() {

    function PortefeuilleInfos(objetData) {
      if (objetData) {
        this.setData(objetData);
      }
    }

    PortefeuilleInfos.prototype = {

      isModel: true,

      setData: function(objetData) {
        angular.extend(this, objetData);
      },

      getIdPortefeuilleInfos: function(){
        return this.idPortefeuilleInfos;
      },
      setIdPortefeuilleInfos: function(val){
        this.idPortefeuilleInfos = val;
      },

      // Nom Agence
      getNomAgence: function(){
        return this.nomAgence;
      },
      setNomAgence: function(valNomAgence){
        this.nomAgence = valNomAgence;
      },

      // ape
      getApe: function(){
        return this.ape;
      },
      setApe: function(val){
        this.ape = val;
      },

      // numCartePro
      getNumCartePro: function(){
        return this.numCartePro;
      },
      setNumCartePro: function(numCartePro){
        this.numCartePro = numCartePro;
      },

      // numCarteProDelivreePar
      getNumCarteProDelivreePar: function(){
        return this.numCarteProDelivreePar;
      },
      setNumCarteProDelivreePar: function(numCartePro){
        this.numCarteProDelivreePar = numCartePro;
      },

      // prefixSms
      getPrefixSms: function(){
        return this.prefixSms;
      },
      setPrefixSms: function(valPrefixSms){
        this.prefixSms = valPrefixSms;
      },

      // auCapitalDe
      getAuCapitalDe: function(){
        return this.auCapitalDe;
      },
      setAuCapitalDe: function(auCapitalDe){
        this.auCapitalDe = auCapitalDe;
      },

      // raisonSociale
      getRaisonSociale: function(){
        return this.raisonSociale;
      },
      setRaisonSociale: function(valraisonSociale){
        this.raisonSociale = valraisonSociale;
      },

      // Adresse
      getAdresse: function(){
        return this.adresse;
      },
      setAdresse: function(adr){
        this.adresse = adr;
      },

      // Code Postal
      getCodePostal: function(){
        return this.codePostal;
      },
      setCodePostal: function(code){
        this.codePostal = code;
      },

      // Ville
      getVille: function(){
        return this.ville;
      },
      setVille: function(val){
        this.ville = val;
      },
      // VilleEdition
      getVilleEdition: function(){
        return this.villeEdition;
      },
      setVilleEdition: function(val){
        this.villeEdition = val;
      },

      // Telephone
      getTelephone: function(){
        return this.telephone;
      },
      setTelephone: function(val){
        this.telephone = val;
      },

      // Fax
      getFax: function(){
        return this.fax;
      },
      setFax: function(val){
        this.fax = val;
      },

      // Email
      getEmail: function(){
        return this.email;
      },
      setEmail: function(val){
        this.email = val;
      },

      // Siret
      getSiret: function(){
        return this.siret;
      },
      setSiret: function(val){
        this.siret = val;
      },

      // SiteInternet
      getSiteInternet: function(){
        return this.siteInternet;
      },
      setSiteInternet: function(val){
        this.siteInternet = val;
      },

      // imageEntete
      getImageEntete: function(){
        return this.imageEntete;
      },
      setImageEntete: function(val){
        this.imageEntete = val;
      },

      // isBlocked
      getIsBlocked: function(){
        return this.isBlocked;
      },
      setIsBlocked: function(val){
        this.isBlocked = val;
      },

      getAdresseComplete: function(inline){
        var adresse = '';
        if(this.getAdresse()!==''){
          adresse += this.getAdresse()+ ((inline) ? ' - ' : '<br />');
        }
        if(this.getCodePostal()!=='' || this.getVille()!==''){
          if(this.getCodePostal()!=='') adresse += this.getCodePostal()+' ';
          if(this.getVille()!==''){adresse += this.getVille()+' ';}
          if(adresse.length){adresse = adresse.slice(0,-1);}
        }

        return adresse;
      }
    };

    return PortefeuilleInfos;
  }
]);
