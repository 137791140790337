(function() {

  'use strict';

  /**
   * @desc Composant permetant de lister le plan modele
   * @example <choose-modele-modal></choose-modele-modal>
   */
  angular
    .module('collaboreApp')
    .component('chooseModeleModal',{
      templateUrl: 'app/components/choose-modele/choose-modele-modal.component.html',
      bindings : {
        portefeuille: '<',
        numImmeuble: '<',
        libelleImmeuble: '<',
        listSelectedCompte: '<',
        portefeuilleComfact: '<',
        typeMetier: '<',
        fournisseur: '<',
        contrat: '<',
        onEmit: '&',            // Permet de notifier le composant parent

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controllerAs: 'choosemodelemodalctrl',
      controller: ChooseModeleModalController
    });

  /*@ngInject*/
  function ChooseModeleModalController($rootScope, UtilsService, ModalsService, ICSCloudService, ErreurCollabService){

    var _this = this;
    _this.loading = false;
    _this.error = null;
    _this.selectedContrat = null;
    _this.selectedImmeuble = {
      num: '0000',
      libelle: 'Commun'
    };
    _this.currentModele = null;
    _this.searchCompte = null;
    _this.hideCommunBtn = null;
    _this.hideImmeubleBtn = null;
    _this.selectedComptes = [];
    _this.onChooseContrat = onChooseContrat;
    _this.onChooseImmeuble = onChooseImmeuble;
    _this.onCheckCompte = onCheckCompte;
    _this.onSubmitFilterCompte = onSubmitFilterCompte;
    _this.onClickValidate = onClickValidate;
    _this.onClickClose = onClickClose;
    _this.onEmitFromModeleLigneComponent = onEmitFromModeleLigneComponent;

    _this.$onInit = function() {
      if(_this.resolve) {
        _this.portefeuille = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'portefeuille'));
        _this.numImmeuble = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'numImmeuble'));
        _this.libelleImmeuble = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'libelleImmeuble'));
        _this.listSelectedCompte = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'listSelectedCompte'));
        _this.portefeuilleComfact = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'portefeuilleComfact'));
        _this.typeMetier = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'typeMetier'));
        _this.fournisseur = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'fournisseur'));
        _this.contrat = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'contrat'));
      }
      if(_.isNil(_this.portefeuille)) ModalsService.alertErreur('Pas de portefeuille');
      if(!_.isNil(_this.numImmeuble) && _this.numImmeuble !== "0000") {
        _this.selectedImmeuble.num = angular.copy(_this.numImmeuble);
        _this.selectedImmeuble.libelle = (!_.isNil(_this.libelleImmeuble)) ? angular.copy(_this.libelleImmeuble) : null;
      }
      /*
      if(!_.isNil(_this.portefeuilleComfact)) {
        // Si syndic on cache le bouton commun
        if(_this.portefeuilleComfact.isSyndic()) _this.hideCommunBtn = true;
        else if(_this.portefeuilleComfact.isGestion()) _this.hideImmeubleBtn = true;
      }*/
      if(!_.isNil(_this.typeMetier)) {
        // Si syndic on cache le bouton commun
        if(_this.typeMetier == 2) {
          _this.hideCommunBtn = true;
        }
        // Si gestion on cache le bouton immeuble
        else if(_this.typeMetier == 1) {
          _this.selectedImmeuble.num = '0000';
        }
      }
      callModele(_this.selectedImmeuble.num);
    };

    /**
     * Lors de changements depuis le parent
     * @param changes
     */
    _this.$onChanges = function (changes) {
    };

    _this.$onDestroy = function(){
    };

    function callModele(numImmeuble, contrat){
      if(_.isNil(numImmeuble)) numImmeuble = _this.selectedImmeuble.num;
      _this.loading = true;

      var search = {
        numImmeuble: numImmeuble,
        portefeuille: _this.portefeuille,
        global: _this.searchCompte
      };

      if(_.isObject(contrat)){
        search.refContrat = contrat.value;
      }

      ICSCloudService
        .getPlanModele(search)
        .then(function(planModele){
          _this.currentModele = planModele;
          checkOrUnckCompte();
        })
        .finally(function(){
          _this.loading = false;
          console.log(_this);
        });
    }

    function checkOrUnckCompte(){
      if(_.isObject(_this.currentModele)){
        var compteCheckedByNumAndBB = [];

        if(_this.currentModele.hasListBatiment()){

          _this.currentModele.getListBatiment().map(function(batiment){
            if(batiment.hasListRegroupement()){

              if(batiment.getListRegroupement().length === 1){
                batiment.getListRegroupement()[0].expanded = true;
                batiment.expanded = true;

                batiment.getListRegroupement().map(function(regroupement){
                  if(regroupement.hasListRegroupement()){
                    if(regroupement.getListRegroupement().length === 1){
                      regroupement.getListRegroupement()[0].expanded = true;
                      regroupement.expanded = true;

                      regroupement.getListRegroupement().map(function(sousRegroupement){
                        if(sousRegroupement.hasListRegroupement()){
                          if(sousRegroupement.getListRegroupement().length === 1){
                            sousRegroupement.getListRegroupement()[0].expanded = true;
                            sousRegroupement.expanded = true;

                          }
                        }
                      });
                    }
                  }
                });
              }
            }
          });
        }

        // Liste des comptes selectionné
        if(_.isArray(_this.listSelectedCompte) && _this.listSelectedCompte.length){
          compteCheckedByNumAndBB =_this.listSelectedCompte.reduce(function(accum, modele){

            if(!accum.hasOwnProperty(modele.getCCCCCC())) accum[modele.getCCCCCC()] = {};

            if(!accum[modele.getCCCCCC()].hasOwnProperty(modele.getBB()))
              accum[modele.getCCCCCC()][modele.getBB()] = {};

            accum[modele.getCCCCCC()][modele.getBB()] = modele;

            return accum;
          }, {});
        }

        if(_.size(compteCheckedByNumAndBB) !== 0 && _this.currentModele.hasListBatiment()){

          _this.currentModele.getListBatiment().map(function(batiment){
            if(batiment.hasListCompte()){
              batiment.getListCompte().map(function(compte){
                if(compteCheckedByNumAndBB.hasOwnProperty(compte.getCCCCCC()) && compteCheckedByNumAndBB[compte.getCCCCCC()].hasOwnProperty(compte.getBB())){
                  compte.setData(compteCheckedByNumAndBB[compte.getCCCCCC()][compte.getBB()]);
                  compte.checked = true;
                  selectCompte(compte, batiment);
                  batiment.expanded = true;
                }
              });
            }
            if(batiment.hasListRegroupement()){
              batiment.getListRegroupement().map(function(regroupement){
                if(regroupement.hasListCompte()){

                  regroupement.getListCompte().map(function(compte){
                    if(compteCheckedByNumAndBB.hasOwnProperty(compte.getCCCCCC()) && compteCheckedByNumAndBB[compte.getCCCCCC()].hasOwnProperty(compte.getBB())){
                      compte.setData(compteCheckedByNumAndBB[compte.getCCCCCC()][compte.getBB()]);
                      compte.checked = true;
                      selectCompte(compte, batiment, regroupement);
                      batiment.expanded = true;
                      regroupement.expanded = true;
                    }
                  });
                }

                if(regroupement.hasListRegroupement()){
                  regroupement.getListRegroupement().map(function(sousRegroupement){
                    if(sousRegroupement.hasListCompte()){

                      sousRegroupement.getListCompte().map(function(compte){
                        if(compteCheckedByNumAndBB.hasOwnProperty(compte.getCCCCCC()) && compteCheckedByNumAndBB[compte.getCCCCCC()].hasOwnProperty(compte.getBB())){
                          compte.setData(compteCheckedByNumAndBB[compte.getCCCCCC()][compte.getBB()]);
                          compte.checked = true;
                          selectCompte(compte, batiment, regroupement, sousRegroupement);
                          batiment.expanded = true;
                          regroupement.expanded = true;
                          sousRegroupement.expanded = true;
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      }
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      if(_this.resolve) _this.close({$value: objRetour});
      else _this.onEmit({obj: objRetour})
    }

    function close(){
      _this.dismiss({$value: 'cancel'});
    }

    function onChooseContrat(){
      if(_.isNil(_this.contrat)) return;
      _this.selectedContrat = true;
      callModele(_this.numImmeuble, _this.contrat);
    }

    /**
     * Lors du choix de trie par l'immeuble
     */
    function onChooseImmeuble(val){
      if(_this.selectedImmeuble.num === val && !_this.selectedContrat) return;

      _this.selectedImmeuble.num = angular.copy(val);
      _this.selectedContrat = undefined;
      callModele(val);
    }

    function onSubmitFilterCompte(form){
      console.log(form);
      if (!form.$valid) {
        return false;
      }
      callModele();

      /*
       var modulesFiltered = UtilsService.fuzzyBy(ctrl.modules,'libelle',ctrl.search);
            ctrl.listeModules = UtilsService.groupBy(modulesFiltered,'typeModule.libelle');
       */
    }

    function onCheckCompte(modele, batiment, regroupement, sousRegroupement){
      selectCompte(modele, batiment, regroupement, sousRegroupement);
    }

    function selectCompte(modele, batiment, regroupement, sousRegroupement){
      if(!_.isObject(modele) || !_.isObject(batiment)) return;

      var batimentCopy = angular.copy(batiment);
      delete batimentCopy.listRegroupement;
      delete batimentCopy.listCompte;

      var modeleCopy = angular.copy(modele);
      modeleCopy.batiment = batimentCopy;

      var regroupementCopy = angular.copy(regroupement);
      if(_.isObject(regroupementCopy)){

        delete regroupementCopy.listRegroupement;
        delete regroupementCopy.listCompte;

        modeleCopy.regroupement = regroupementCopy;
      }

      var sousRegroupementCopy = angular.copy(sousRegroupement);
      if(_.isObject(sousRegroupementCopy)){

        delete sousRegroupementCopy.listRegroupement;
        delete sousRegroupementCopy.listCompte;

        modeleCopy.sousRegroupement = sousRegroupementCopy;
      }

      var numCompte = modeleCopy.getCCCCCC();
      var numBatiment = modeleCopy.getBB();
      if(modeleCopy.checked) {
        if(_.findIndex(_this.selectedComptes, {CCCCCC: numCompte, BB: numBatiment}) === -1)
          _this.selectedComptes.push(modeleCopy);
      }
      else{
        // Retire le compte de la liste
        _.remove(_this.selectedComptes, function(item){
          return (item.getCCCCCC() == numCompte && item.getBB() == numBatiment);
        });
      }
    }

    /**
     * Lors du clique sur le bouton valider
     */
    function onClickValidate(){
      _this.close({$value: {selectedComptes: _this.selectedComptes}});
    }

    /**
     * Fermeture du composant si en mode modal
     */
    function onClickClose(){
      close();
    }

    function onEmitFromModeleLigneComponent(obj){
      console.log(obj);
      try {
        if(!_.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');

      }
      catch(err) {
        ErreurCollabService.logErreur('[ChooseModeleModalController.onEmitFromModeleLigneComponent] - ' + err.message);
      }
    }
  }
})();
