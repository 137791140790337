(function() {

    'use strict';

    /**
     * @desc Componsant pour choisir un ou plusieur groupe
     * @example <choose-groupe-immeuble></choose-groupe-immeuble>
     */
    angular
        .module('collaboreApp')
        .component('chooseGroupeImmeuble',{
            templateUrl: 'app/components/choose-groupe-immeuble/choose-groupe-immeuble.component.html',
            bindings : {
                title: '@',                 // Titre tu popover
                portefeuille: '<',          // Portefeuille dans lequel il faut chercher
                listGroupeChecked: '<',     // Liste des groupes portefeuille avec des immeubles déjà selectionnés
                onEmit: '&',                // Permet de notifier le composant parent
                onSubmit: '&'               // Permet de notifier le composant parent lors du submit
            },
            controllerAs: 'choosegroupeimmeublectrl',
            controller: ChooseGroupeImmeubleController
        });

    /*@ngInject*/
    function ChooseGroupeImmeubleController($injector, $q, $timeout, GroupeService){

        var _this = this;
        var portefeuilleForSearch = null;


        _this.triggerPopover = 'outsideClick';
        _this.triggerPopoverEnabled = true;

        _this.displayTitle = displayTitle;
        _this.onSubmitSearch = onSubmitSearch;
        _this.onClickClearSearch = onClickClearSearch;
        _this.onPopoverIsOpenChange = onPopoverIsOpenChange;
        _this.onCheckGroupe = onCheckGroupe;
        _this.onCheckAllGroupe = onCheckAllGroupe;

        _this.onClickCancel = onClickCancel;
        _this.onClickSave = onClickSave;

        _this.$onInit = function() {
        };

        /**
         * Lors de changements depuis le parent
         * @param changes
         */
        _this.$onChanges = function (changes) {
            if(changes.portefeuille) _this.portefeuille = changes.portefeuille.currentValue;
            if(changes.listGroupeChecked) _this.listGroupeCheckedTmp = angular.copy(changes.listGroupeChecked.currentValue);
        };

        _this.$onDestroy = function(){
        };

        /**
         * Initlisation
         */
        function init(){

            _this.configScrollbar = {
                autoHideScrollbar: false,
                theme: 'dark-3',
                advanced: {
                    updateOnContentResize: true
                },
                scrollButtons: {
                    scrollAmount: 'auto', // scroll amount when button pressed
                    enable: false // enable scrolling buttons by default
                },
                axis: 'y', // enable 2 axis scrollbars by default
                //setHeight: 200,
                scrollInertia: 100
            };

            _this.searchGroupe = '';
            _this.searchEnabled = false;

            // Liste des groupes
            if(angular.isUndefined(_this.listGroupe) || !angular.isArray(_this.listGroupe)) _this.listGroupe = [];

            // Liste temporaire des groupe checked
            if(angular.isUndefined(_this.listGroupeCheckedTmp) || !angular.isArray(_this.listGroupeCheckedTmp)) _this.listGroupeCheckedTmp = [];

            // Liste des groupes checked
            if(angular.isUndefined(_this.listGroupeChecked) || !angular.isArray(_this.listGroupeChecked)) _this.listGroupeChecked = [];
            else _this.listGroupeCheckedTmp = angular.copy(_this.listGroupeChecked);

            // Si pas de groupe à afficher on va les chercher ou si le portefeuille qui à servie pour la recherche est différent du portefeuille choisi
            if(!_this.listGroupe.length || portefeuilleForSearch === null || portefeuilleForSearch.idPortefeuille !== _this.portefeuille.idPortefeuille) {
                initListGroupe();
            }
            // Si la liste existe déjà alors on recoche les checked
            else {
                _this.loading = true;
                $timeout(function(){
                    _this.listGroupeFiltered = angular.copy(_this.listGroupe);
                    updateStatusChecked();
                }, 100);

            }

            $timeout(function(){
                var $window = $injector.get('$window');
                var element = $window.document.getElementById('inputSearchGroupe');
                if(element) element.focus();
            });
        }

        /**
         * Permet d'afficher le titre du popover
         * @returns {string}
         */
        function displayTitle(){
            return (angular.isDefined(_this.title) && angular.isString(_this.title)) ? _this.title : 'Groupes';
        }

        /**
         * Permet d'init la liste des groupes
         */
        function initListGroupe(){
            _this.loading = true;
            callListGroupePortefeuilleWithGroupeImmeuble()
                .then(function(groupes){
                    _this.listGroupe = groupes;
                    _this.listGroupeFiltered = angular.copy(_this.listGroupe);
                })
                .catch(function(msg) {

                })
                .finally(function(){
                    _this.loading = false;
                    updateStatusChecked();
                });
        }

        /**
         * Permet d'avoir une liste de groupe poirtefeuille contenant une liste de groupe immeuble
         */
        function callListGroupePortefeuilleWithGroupeImmeuble(){
            var defer = $q.defer();
            if(angular.isObject(_this.portefeuille)) {
                portefeuilleForSearch = _this.portefeuille;
                GroupeService
                    .searchListGroupeImmeubleByGroupePortefeuille(_this.portefeuille.getIdPortefeuille())
                    .then(function(listImmeuble){
                        defer.resolve(listImmeuble);
                    })
                    .catch(function(err) {
                        defer.resolve([]);
                    });
            }
            else defer.reject("Veuillez choisir un portefeuille !");
            return defer.promise;
        }

        /**
         * Permet de récupérer le groupe portefeuille par rapport à l'idGroupePortefeuille du groupe passé en paramètre
         * @param groupeChecked
         * @returns {*}
         */
        function getCurrentGroupePortefeuilleChecked(groupeChecked){
            var currentGroupePortefeuille = null;
            if(_this.listGroupeCheckedTmp.length) {
                for(var i = 0; i < _this.listGroupeCheckedTmp.length; i++) {
                    if(_this.listGroupeCheckedTmp[i].getIdGroupe() === groupeChecked.idGroupePortefeuille) {
                        currentGroupePortefeuille = _this.listGroupeCheckedTmp[i];
                        break;
                    }
                }
            }
            // Si pas de groupe portefeuille trouvé dans les checked alors on recupère dans la liste normal
            if(!currentGroupePortefeuille) {
                var groupePortefeuille = angular.copy(getCurrentGroupePortefeuille(groupeChecked.idGroupePortefeuille));
                if(angular.isObject(groupePortefeuille)) {
                    groupePortefeuille.setGroupesFils([]);
                    _this.listGroupeCheckedTmp.push(groupePortefeuille);
                    currentGroupePortefeuille = groupePortefeuille;
                }
            }
            return currentGroupePortefeuille;
        }

        /**
         * Permet de récupérer le groupe portefeuille par rapport à un id groupe portefeuille
         * @param idGroupePortefeuille
         * @returns {*}
         */
        function getCurrentGroupePortefeuille(idGroupePortefeuille){
            var currentGroupePortefeuille = null;
            if(_this.listGroupe.length) {
                for(var i = 0; i < _this.listGroupe.length; i++) {
                    if(_this.listGroupe[i].getIdGroupe() === idGroupePortefeuille) {
                        currentGroupePortefeuille = _this.listGroupe[i];
                        break;
                    }
                }
            }
            return currentGroupePortefeuille;
        }

        /**
         * Permet d'ajouter un destinataire à la liste des destinataires
         * @param groupe | Array
         */
        function addGroupe(groupeChecked) {
            var currentGroupePortefeuille = getCurrentGroupePortefeuilleChecked(groupeChecked);
            currentGroupePortefeuille.groupesFils = GroupeService.addGroupeToList(groupeChecked.groupe, currentGroupePortefeuille.groupesFils);
        }

        /**
         * Permet de retirer un collaborateur de la selectedList
         * @param groupe
         */
        function removeGroupe(groupeChecked) {
            var currentGroupePortefeuille = getCurrentGroupePortefeuilleChecked(groupeChecked);
            currentGroupePortefeuille.groupesFils = GroupeService.removeGroupeFromList(groupeChecked.groupe, currentGroupePortefeuille.groupesFils);
        }

        /**
         * Ferme la popup
         */
        function close(){
            _this.popoverIsOpen = false;
            _this.listGroupeFiltered = [];
        }

        /**
         * Lors du clique sur le bouton Fermer
         */
        function onClickCancel(){
            close();
        }

        /**
         * Lors du clique sur le bouton Ajouter
         */
        function onClickSave(){
            emitListGroupe();
            close();
        }

        /**
         * Lors du submit du formulaire de recherche
         */
        function onSubmitSearch(){

            if(_this.searchGroupe !== '') {
                _this.listGroupeFiltered = [];
                _this.searchEnabled = true;
                if(_this.listGroupe.length) {

                    var listByPortefeuille = [];

                    _this.listGroupe.map(function(groupePortefeuille) {
                        // Si il y a des fils
                        if(groupePortefeuille.getGroupesFils().length) {

                            var groupePortefeuilleCopy = angular.copy(groupePortefeuille);
                            groupePortefeuilleCopy.setGroupesFils([]);

                            var copyListGroupe = [];
                            // Parcour la liste des groupe fils du groupe portefeuille
                            groupePortefeuille.getGroupesFils().map(function(groupeFils){
                                var fils = angular.copy(groupeFils);
                                if(GroupeService.searchInGroupe(_this.searchGroupe, fils)) copyListGroupe.push(fils);
                            });

                            if(copyListGroupe.length) {
                                groupePortefeuilleCopy.setGroupesFils(copyListGroupe);
                                listByPortefeuille.push(groupePortefeuilleCopy);
                            }
                        }
                    });

                    _this.listGroupeFiltered = listByPortefeuille;
                }
            }
            else {
                _this.searchEnabled = false;
                _this.listGroupeFiltered = angular.copy(_this.listGroupe);
            }
            updateStatusChecked();
        }

        /**
         * Lors du clique sur le bouton pour clear le champ de recherche
         */
        function onClickClearSearch(){
            _this.searchGroupe = '';
            onSubmitSearch();
        }

        /**
         * Lorsqu'un groupe est coché/décoché
         * @param groupe
         */
        function onCheckGroupe(groupeChecked){
            if(angular.isObject(groupeChecked.groupe)) {
                if(groupeChecked.groupe.checked) addGroupe(groupeChecked);             // Ajoute ce groupe
                else if(!groupeChecked.groupe.checked) removeGroupe(groupeChecked);    // Retire le groupe
            }
        }

        /**
         * Permet de Cocher/Décocher tous les groupe user d'un coup
         */
        function onCheckAllGroupe(groupePortefeuille){
            if(angular.isObject(groupePortefeuille)) {
                var groupeFils = groupePortefeuille.getGroupesFils();
                if(groupeFils.length) groupePortefeuille.loadingCheckedAll = true;
                $timeout(function(){
                    if(groupeFils.length) {
                        var checked = groupePortefeuille.checked;
                        groupePortefeuille.getGroupesFils().map(function(groupe){
                            groupe.checked = checked
                            if(checked) addGroupe(groupe);
                            else removeGroupe(groupe);
                        });

                        /*
                        for(var i = 0; i < groupeFils.length; i++) {
                          groupeFils[i].checked = checked
                          if(checked) addGroupe(groupeFils[i]);
                          else removeGroupe(groupeFils[i]);
                        }*/
                        groupePortefeuille.loadingCheckedAll = false;
                    }
                });
            }
        }

        /**
         * Change l'état de l'attribut "checked" des groupes de la liste "listGroupeFiltered" par rapport aux groupes de la liste "listGroupeChecked"
         */
        function updateStatusChecked(){
            var listeGroupePortefeuilleFiltered = _this.listGroupeFiltered;
            if(_this.listGroupeCheckedTmp.length && angular.isArray(listeGroupePortefeuilleFiltered) && listeGroupePortefeuilleFiltered.length) {

                // Recupère le nombre de groupe fils checked
                var nbGroupeFilsCheck = 0;
                for(var i = 0; i < _this.listGroupeCheckedTmp.length; i++) {
                    nbGroupeFilsCheck += _this.listGroupeCheckedTmp[i].groupesFils.length;
                }

                var nbGroupeFilsCheckFound = 0; // Nombre de fils checked trouvé

                // Parcour la liste des groupes portefeuille checked
                for(var c = 0; c < _this.listGroupeCheckedTmp.length; c++) {
                    var groupePortefeuilleChecked = _this.listGroupeCheckedTmp[c];
                    // Si il y a des fils
                    if(groupePortefeuilleChecked.getGroupesFils().length) {
                        // Parcour la liste des groupes fils checked du portefeuille
                        for(var p = 0; p < groupePortefeuilleChecked.getGroupesFils().length; p++) {
                            var groupeFilsFound = false;
                            var groupeFilsChecked = groupePortefeuilleChecked.getGroupesFils()[p];

                            // Parcour la liste des groupe portefeuille filtré
                            for(var l = 0; l < listeGroupePortefeuilleFiltered.length; l++) {
                                var groupePortefeuille = listeGroupePortefeuilleFiltered[l];
                                // Si le groupe portefeuille checked et le meme que le non checked
                                if(groupePortefeuille.idGroupe === groupePortefeuilleChecked.idGroupe) {
                                    // Si il y a des fils
                                    if(groupePortefeuille.getGroupesFils().length) {
                                        // Parcour la liste des groupe fils du groupe portefeuille
                                        for (var f = 0; f < groupePortefeuille.getGroupesFils().length; f++) {

                                            var groupeFils = groupePortefeuille.getGroupesFils()[f];

                                            // Si le groupe checked est le meme que le groupe fils on coche le groupe fils
                                            if (groupeFilsChecked.idGroupe === groupeFils.idGroupe) {
                                                groupeFils.checked = true;
                                                groupeFilsFound = true;
                                                nbGroupeFilsCheckFound++;
                                                break;
                                            }
                                        }
                                    }
                                }
                                if(groupeFilsFound) break;
                            }
                        }
                    }
                    // Si le nombre de groupes fils checked est égale au nombre de groupe fils checked trouvé on break car plus de check à traiter
                    if(nbGroupeFilsCheckFound === nbGroupeFilsCheck) break;
                }




                /*
                var canBreak = false;
                // Parcour la liste des groupe portefeuille filtré
                for(var l = 0; l < listeGroupePortefeuilleFiltered.length; l++) {
                  var groupePortefeuille = listeGroupePortefeuilleFiltered[l];
                  // Si il y a des fils
                  if(groupePortefeuille.getGroupesFils().length) {
                    // Parcour la liste des groupe fils du groupe portefeuille
                    for(var f = 0; f < groupePortefeuille.getGroupesFils().length; f++) {
                      var groupeFilsFound = false;
                      var groupeFils = groupePortefeuille.getGroupesFils()[f];
                      groupeFils.checked = false;

                      // Parcour la liste des groupes portefeuille checked
                      for(var c = 0; c < _this.listGroupeCheckedTmp.length; c++) {
                        var groupePortefeuilleChecked = _this.listGroupeCheckedTmp[c];
                        // Si il y a des fils
                        if(groupePortefeuilleChecked.getGroupesFils().length) {
                          // Parcour la liste des groupes fils checked du portefeuille
                          for(var p = 0; p < groupePortefeuilleChecked.getGroupesFils().length; p++) {
                            // Si le groupe checked est le meme que le groupe fils on coche le groupe fils
                            if (groupePortefeuilleChecked.getGroupesFils()[p].idGroupe === groupeFils.idGroupe) {
                              groupeFils.checked = true;
                              groupeFilsFound = true;
                              nbGroupeFilsCheckFound++;
                              break;
                            }
                          }
                        }
                        if(groupeFilsFound) break;
                      }
                      // Si le nombre de groupes fils checked est égale au nombre de groupe fils checked trouvé on break car plus de check à traiter
                      if(nbGroupeFilsCheckFound === nbGroupeFilsCheck) {
                        canBreak = true;
                        break;
                      }
                    }
                  }

                  if(canBreak) break;
                }*/

                /*
                // Parcour la liste des groupe portefeuille filtré
                listeGroupePortefeuilleFiltered.map(function(groupePortefeuille){
                  // Si il y a des fils
                  if(groupePortefeuille.getGroupesFils().length) {
                    // Parcour la liste des groupe fils du groupe portefeuille
                    groupePortefeuille.getGroupesFils().map(function(groupeFils){
                      groupeFils.checked = false;

                      // Parcour la liste des groupes portefeuille checked
                      _this.listGroupeCheckedTmp.map(function(groupePortefeuilleChecked){
                        // Si il y a des fils
                        if(groupePortefeuilleChecked.getGroupesFils().length) {
                          // Parcour la liste des groupe fils du groupe portefeuille checked
                          groupePortefeuilleChecked.getGroupesFils().map(function(groupeFilsChecked){
                            // Si le groupe checked est le meme que le groupe fils on coche le groupe fils
                            if (GroupeService.isSameGroupe(groupeFilsChecked, groupeFils)) groupeFils.checked = true;
                          });
                        }

                      });
                    });
                  }
                });*/
            }
            _this.loading = false;
        }

        /**
         * Executé lorsque la variable "popoverIsOpen" change
         * @param newVal
         */
        function onPopoverIsOpenChange(newVal){
            if(newVal) init();
        }

        /**
         * Permet d'emit les infos vers le composant parent
         */
        function emitListGroupe(){
            if(_this.listGroupeCheckedTmp.length) {
                for(var i = 0; i < _this.listGroupeCheckedTmp.length; i++) {
                    _this.listGroupeCheckedTmp[i].portefeuille = _this.portefeuille;
                }
            }
            _this.onEmit({
                obj: {
                    listGroupePortefeuilleChecked: _this.listGroupeCheckedTmp
                }
            });
        }

    }

    Object.defineProperty(ChooseGroupeImmeubleController.prototype,
        'popoverIsOpen', {
            get: function () {
                return this._popoverIsOpen;
            },
            set: function (newValue) {
                this._popoverIsOpen = newValue;

                //Call method on update
                this.onPopoverIsOpenChange(this._popoverIsOpen);
            },
            enumerable: true,
            configurable: true
        });
})();
