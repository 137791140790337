(function () {
  'use strict';

  /**
   * @desc Formulaire d'événement et de demandes de devis ou intervention ou contrat réunis
   * @example <formulaire-evenement-demande></formulaire-evenement-demande>
   */

  angular
    .module('collaboreApp')
    .component('formulaireEvenementDemande', {
      templateUrl: 'app/topics/formulaire-evenement-demande/formulaire-evenement-demande.component.html',
      bindings: {
        typeDemande: '@',
        contenuEvenement: '<',

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<'
      },
      controllerAs: 'formulaireevenementdemandectrl',
      controller: FormulaireEvenementDemandeController
    });

  /*@ngInject*/
  function FormulaireEvenementDemandeController(COLLAB_CONF, UtilsService, $injector) {

    var ctrl = this;
    ctrl.onDismiss = onDismiss;
    ctrl.onClose = onClose;

    ctrl.$onInit = function() {
      if(ctrl.resolve && ctrl.modalInstance) {
        ctrl.typeDemande = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'typeDemande'));
      }
      var model = null;
      if(ctrl.typeDemande === 'contrat') {
        model = $injector.get('ContenuEvenementContrat');
      }
      else {
        ctrl.typeDemande = 'devis';
        model = $injector.get('ContenuEvenementDevis');
      }
      ctrl.obj = new model();

      if (UtilsService.isCapfun()) {
        ctrl.obj.setDescriptionDevis(COLLAB_CONF.DEFAULT_DESCRIPTION_DEMANDE_DEVIS);
        ctrl.obj.setMessageGravitants(COLLAB_CONF.DEFAULT_DESCRIPTION_DEMANDE_DEVIS);
      }
      ctrl.obj.setGroupement('Défaut');
      model = null;
    };

    function onDismiss(value){
      if(ctrl.modalInstance) ctrl.dismiss({$value: value});
    }

    function onClose(value){
      if(ctrl.modalInstance) ctrl.close({$value: value});
    }

  }
})();
