(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.Fiche
   * @description
   * # Fiche
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('Fiche',
      FicheModel
    );

  /** @ngInject */
  function FicheModel(Renseignement,UtilsService,$q,FicheAjax,ErreurCollabService) {

    function Fiche(objetData) {
      if (objetData) {
        this.setData(objetData);
        this.isFiche = true;
      }
    }

    var parcourFiche = function(fiche,infos){

      var isPublic = false;
      var isPrive = false;
      var canDelete = true;

      // Si cette fiche à une fiche pere alors ses données sont privé
      if(fiche.hasFichePere()){
        isPrive = true;

        // Si cette fiche n'a pas de fiche pere alors c'est une fiche normal ou pere donc ses données sont public
        // Mais pas modifiable
      }else{
        isPublic = true;
        canDelete = false;
      }

      angular.forEach(fiche.getInformations(),function(info){

        info.idFiche = fiche.getIdFiche();
        if(isPublic){
          infos.partages.push(info);
          info.isPublic = true;

        }else if(isPrive){
          infos.prives.push(info);
          info.isPrive = true;
        }
        info.canDelete = canDelete;

        if(info.getReference()){

          if(info.getReference().isNom()){
            // Si Partagé
            if(isPublic){
              infos.nom.partage = info;
            }else if(isPrive){
              infos.nom.prive = info;
              infos.nom.default = info;
            }

            if(!infos.nom.default){
              infos.nom.default = info;
            }
          }
          if(info.getReference().isPrenom()){
            // Si Partagé
            if(isPublic){
              infos.prenom.partage = info;
            }else if(isPrive){
              infos.prenom.prive = info;
              infos.prenom.default = info;
            }

            if(!infos.prenom.default){
              infos.prenom.default = info;
            }
          }

          if(info.getReference().isPortable() || info.getReference().isPortableInfo() || info.getReference().isTelephone()){
            if(isPublic){
              infos.telephones.partages.push(info);
            }else if(isPrive){
              infos.telephones.prives.push(info);
            }
            infos.telephones.all.push(info);
          }

          if(info.getReference().isFax()){
            if(isPublic){
              infos.fax.partages.push(info);
            }else if(isPrive){
              infos.fax.prives.push(info);
            }
            infos.fax.all.push(info);
          }

          if(info.getReference().isEmail()){
            if(isPublic){
              infos.emails.partages.push(info);
            }else if(isPrive){
              infos.emails.prives.push(info);
            }

            infos.emails.all.push(info);
          }

          if(info.getReference().isCommentaire()){
            if(isPublic){
              infos.commentaires.partages.push(info);
            }else if(isPrive){
              infos.commentaires.prives.push(info);
            }

            infos.commentaires.all.push(info);
          }

          if(info.getReference().isAdresse()){
            // Si Partagé
            if(isPublic){
              infos.adresse.partages.push(info);
              infos.adresse.partage = info;
            }else if(isPrive){
              infos.adresse.prive = info;
              infos.adresse.default = info;
              infos.adresse.prives.push(info);
            }

            if(!infos.adresse.default){
              infos.adresse.default = info;
            }
            infos.adresse.all.push(info);
          }
        }

      });
      return infos;
    };

    Fiche.prototype = {

      isModel: true,
      model: 'Fiche',

      setData: function(objetData) {
        var _this = this;
        angular.extend(_this, objetData);

        if(objetData.hasOwnProperty('informations')){
          _this.setInformations(objetData.informations);
        }

        if(objetData.hasOwnProperty('fichePere') && objetData.fichePere !== null){
          _this.setFichePere(objetData.fichePere);
        }

        var infos = {
          partages: [],
          prives: [],
          nom: {
            default: false,
            partage: false,
            prive: false
          },
          prenom: {
            default: false,
            partage: false,
            prive: false
          },
          adresse: {
            default: false,
            partage: false,
            prive: false,
            all: [],
            partages: [],
            prives: []
          },
          telephones: {
            all: [],
            partages: [],
            prives: []
          },
          fax: {
            all: [],
            partages: [],
            prives: []
          },
          emails: {
            all: [],
            partages: [],
            prives: []
          },
          commentaires: {
            all: [],
            partages: [],
            prives: []
          }
        };


        if(_this.hasFichePere()){
          infos = parcourFiche(_this.getFichePere(), infos);
        }
        infos = parcourFiche(_this, infos);

        _this.setAdresses(infos.adresse.all);
        _this.setTelephones(infos.telephones.all);
        _this.setFax(infos.fax.all);
        _this.setEmails(infos.emails.all);

        var prenom_nom = false;
        // Si pas de nom et prenom par default
        if(!infos.nom.default && !infos.prenom.default){
          _this.hasNomPrenom = false;
          if(_this.libelle !== '' && _this.libelle !== undefined){
            prenom_nom = _this.libelle;
          }else if(_this.nom !== '' && _this.nom !== undefined){
            prenom_nom = _this.nom;
            _this.setDefaultNom(_this.nom);
          }
        }else{
          _this.hasNomPrenom = true;
          prenom_nom = '';

          if(infos.nom.default){
            _this.setDefaultNom(infos.nom.default.getDonnee());
            prenom_nom += infos.nom.default.getDonnee()+' ';
          }

          if(infos.prenom.default){
            _this.setDefaultPrenom(infos.prenom.default.getDonnee());
            prenom_nom += infos.prenom.default.getDonnee()+' ';
          }

          if(prenom_nom !== '') {
            prenom_nom = prenom_nom.substr(0,prenom_nom.length-1);
          }
        }
        _this.setNomPrenom(prenom_nom);

        if(infos.adresse.default){
          _this.setAdresse(infos.adresse.default.getDonnee());
        }

        _this.setInformationsPublic(infos.partages);
        _this.setInformationsPrive(infos.prives);

        _this.setInformationsByCategorie(infos);

        /*
                 if(_this.getInformations().length){

                 var nom = {
                 'default': false,
                 'partage': false,
                 'prive': false
                 };

                 var prenom = {
                 'default': false,
                 'partage': false,
                 'prive': false
                 };

                 var telephones = {
                 'all': [],
                 'partages': [],
                 'prives': []
                 };

                 var emails = {
                 'all': [],
                 'partages': [],
                 'prives': []
                 };

                 angular.forEach(_this.getInformations(),function(info){

                 var isPartage = false;
                 if(info.hasState()){
                 if(info.getState().isPublic() || info.getState().isProtected()){
                 isPartage = true;
                 }
                 }

                 if(info.getReference().isNom()){
                 // Si Partagé
                 if(isPartage){
                 nom.partage = info;
                 }else{
                 nom.prive = info;
                 nom.default = info;
                 }

                 if(!nom.default){
                 nom.default = info;
                 }
                 }
                 if(info.getReference().isPrenom()){
                 // Si Partagé
                 if(isPartage){
                 prenom.partage = info;
                 }else{
                 prenom.prive = info;
                 prenom.default = info;
                 }

                 if(!prenom.default){
                 prenom.default = info;
                 }
                 }

                 if(info.getReference().isPortable() || info.getReference().isTelephone()){
                 if(isPartage){
                 telephones.partages.push(info);
                 }else{
                 telephones.prives.push(info);
                 }
                 telephones.all.push(info);
                 }

                 if(info.getReference().isEmail()){
                 if(isPartage){
                 emails.partages.push(info);
                 }else{
                 emails.prives.push(info);
                 }

                 emails.all.push(info);
                 }
                 });
                 _this.setTelephones(telephones.all);
                 _this.setEmails(emails.all);

                 var prenom_nom = false;
                 // Si pas de nom et prenom par default
                 if(!nom.default && !prenom.default){
                 if(this.libelle !== '' && this.libelle !== undefined){
                 prenom_nom = this.libelle;
                 }else if(this.nom !== '' && this.nom !== undefined){
                 prenom_nom = this.nom;
                 }
                 }else{
                 prenom_nom = '';
                 if(prenom.default){
                 prenom_nom += prenom.default.getDonnee()+' ';
                 }

                 if(nom.default){
                 prenom_nom += nom.default.getDonnee();
                 }
                 }
                 _this.setNomPrenom(prenom_nom);
                 }else{
                 _this.setTelephones([]);
                 _this.setEmails([]);
                 _this.setNomPrenom(false);
                 }*/
      },

      // Id Fiche
      getIdFiche: function(){
        return this.idFiche;
      },
      setIdFiche: function(id){
        this.idFiche = id;
      },

      // Fiche pere
      getFichePere: function(){
        return this.fichePere;
      },
      setFichePere: function(fiche){
        if(angular.isObject(fiche)){
          if(!fiche.isModel){
            fiche = new Fiche(fiche);
          }
        }
        this.fichePere = fiche;
      },
      hasFichePere: function(){
        var retour = false;
        if(angular.isObject(this.getFichePere())){

          retour = true;
        }
        return retour;
      },

      // User
      // Impossible d'utiliser le model "User" car sinon il y a une circular
      getUser: function(){
        return this.user;
      },
      setUser: function(user){
        this.user = user;
      },
      hasUser: function(){
        var retour = false;
        if(angular.isObject(this.getUser())){
          retour = true;
        }
        return retour;
      },

      hasAvatar: function(){
        var retour = false;
        if(this.hasUser()){
          if(angular.isString(this.getUser().nomAvatar) && this.getUser().nomAvatar!==''){
            retour = true;
          }
        }
        return retour;
      },

      // Id Groupe (selon les cas)
      getIdGroupe: function(){
        return this.idGroupe;
      },
      setIdGroupe: function(id){
        this.idGroupe = id;
      },

      // Nom
      getNom: function(){
        return this.nom;
      },
      getDefaultNom: function(){
        return this.defaultNom;
      },
      setDefaultNom: function(valNom){
        this.defaultNom = valNom;
      },

      // Prenom
      getDefaultPrenom: function(){
        return this.defaultPrenom;
      },
      setDefaultPrenom: function(valPrenom){
        this.defaultPrenom = valPrenom;
      },

      // Adresse
      getAdresse: function(){
        return this.adresse;
      },
      setAdresse: function(valAdresse){
        this.adresse = valAdresse;
      },

      // Informations
      getInformations: function(){
        return this.informations;
      },
      setInformations: function(valInformations){
        var informations = [];
        if(angular.isArray(valInformations)){
          angular.forEach(valInformations,function(information){
            if(!information.isModel){
              information = new Renseignement(information);
            }
            informations.push(information);
          });
        }
        this.informations = informations;
      },

      getInformationsPublic: function(){
        return this.informationsPublic;
      },
      setInformationsPublic: function(valInfos){
        this.informationsPublic = valInfos;
      },

      getInformationsPrive: function(){
        return this.informationsPrive;
      },
      setInformationsPrive: function(valInfos){
        this.informationsPrive = valInfos;
      },

      getInformationsByCategorie: function(){
        delete this.informationsByCategorie.prives;
        delete this.informationsByCategorie.partages;
        return this.informationsByCategorie;
      },
      setInformationsByCategorie: function(valInfos){
        this.informationsByCategorie = valInfos;
      },

      isPublic: function(){
        var retour = false;
        if(this.getFichePere() === null){
          retour = true;
        }
        return retour;
      },

      /**
       * C'est une fiche perso si il y a une fichePere
       * @returns {boolean}
       */
      isPerso: function(){
        var retour = false;
        if(this.hasFichePere()){
          retour = true;
        }
        return retour;
      },

      isNote: function(){
        var retour = false;
        if(this.getIdGroupe() === null){
          retour = true;
        }
        return retour;
      },

      /**
       * Recupère la liste des renseignements public
       * @returns {Array}
       */
      getInfosPublic: function(){
        var infos = this.getInformations(),
          retourInfos = [];
        if(infos.length){
          angular.forEach(infos,function(renseignement){
            if(renseignement.getState().isPublic()){
              retourInfos.push(renseignement);
            }
          });
        }
        return retourInfos;
      },

      /**
       * Recupère la liste des renseignements protected
       * @returns {Array}
       */
      getInfosProtected: function(){
        var infos = this.getInformations(),
          retourInfos = [];
        if(infos.length){
          angular.forEach(infos,function(renseignement){
            if(renseignement.getState().isProtected()){
              retourInfos.push(renseignement);
            }
          });
        }
        return retourInfos;
      },

      /**
       * Recupère la liste des renseignements privées
       * @returns {Array}
       */
      getInfosPrive: function(){
        var infos = this.getInformations(),
          retourInfos = [];
        if(infos.length){
          angular.forEach(infos,function(renseignement){
            if(renseignement.getState().isPrive()){
              retourInfos.push(renseignement);
            }
          });
        }
        return retourInfos;
      },

      getNomPrenom: function(){
        return this.nomPrenom;
      },
      setNomPrenom: function(valNomPrenom){
        this.nomPrenom = valNomPrenom;
      },
      getHasNomPrenom: function(){
        return this.hasNomPrenom;
      },

      getTelephones: function(){
        return this.telephones;
      },
      setTelephones: function(valTelephones){
        this.telephones = valTelephones;
      },

      getDefaultTelephone: function(){
        var telephone = false;
        if(this.getTelephones().length){
          telephone = this.getTelephones()[0];
        }
        return telephone;
      },

      getTelephonesPortable: function(){
        var tels = [];
        if(angular.isArray(this.telephones) && this.telephones.length) {
          for(var i = 0; i < this.telephones.length; i++){
            if(angular.isObject(this.telephones[i])){
              if(this.telephones[i].getReference().isPortable()) {
                tels.push(this.telephones[i]);
              }
              else if(UtilsService.isTelMobile(this.telephones[i].getDonnee())){
                tels.push(this.telephones[i]);
              }
            }
          }
        }
        return tels;
      },

      getEmails: function(){
        return this.emails;
      },
      setEmails: function(valEmails){
        this.emails = valEmails;
      },
      getDefaultEmail: function(){
        var email = false;
        if(this.getEmails().length){
          email = this.getEmails()[0];
        }
        return email;
      },

      getFax: function(){
        return this.fax;
      },
      setFax: function(valFax){
        this.fax = valFax;
      },
      getDefaultFax: function(){
        var fax = false;
        if(this.getFax().length){
          fax = this.getFax()[0];
        }
        return fax;
      },


      getAdresses: function(){
        return this.adresses;
      },
      setAdresses: function(valAdresses){
        this.adresses = valAdresses;
      },
      hasAdresses: function(){
        return (this.getAdresses().length!==0);
      },

      /**
       * Permet de sauvegarder un numéro de fax sur la fiche
       * @param num
       * @returns {Promise}
       */
      saveFax: function(num){

        var numExist = false,
          deferred = $q.defer(),
          _this = this;

        num = UtilsService.cleanPhoneNumber(num);


        // Recupère les fax de la fiche et parcour les resultats
        var arrayFax = this.getFax();
        if(arrayFax.length){
          for(var f = 0 ; f < arrayFax.length ; f++){
            if(arrayFax[f].getDonnee() === num){
              numExist = true;
              break;
            }
          }
        }

        // Si il y a une fiche père
        if(this.hasFichePere()){
          // Si le numéro pas encore trouvé
          if(!numExist){
            // Recupère les fax de la fichePere et parcour les resultats
            var arrayFichePereFax = this.getFichePere().getFax();
            if(arrayFichePereFax.length){
              for(var f2 = 0 ; f2 < arrayFichePereFax.length ; f2++){
                if(arrayFichePereFax[f2].getDonnee() === num){
                  numExist = true;
                  break;
                }
              }
            }
          }
        }

        if(!numExist){
          var params = {
            'idFiche': this.getIdFiche(),
            'idState': Renseignement.STATE.PUBLIC.idState,
            'idReference': Renseignement.REFERENCE.FAX.idReference,
            'donnee': num
          };

          FicheAjax.addFiche(params,function(json){

            if(json.nb!=='0'){
              _this.setData(json.Fiches[0]);
              deferred.resolve(_this);
            }else{
              deferred.resolve(false);
            }

          },function(error){
            ErreurCollabService.erreurServlet(error,'Problème avec retour servlet Fiche.saveFax()',deferred);
          });
        }else{
          deferred.resolve();
        }

        return deferred.promise;
      },

      /**
       * Permet de sauvegarder un numéro de téléphone portable sur la fiche
       * @param num
       * @returns {Promise}
       */
      saveTelMobile: function(num, groupe){

        var numExist = false,
          deferred = $q.defer(),
          _this = this,
          addInFichePerso = (groupe && groupe.getGroupeInformations() && groupe.getGroupeInformations().getIsGroupePersoIcsFromMySociete()) ? true : false,
        idFiche = this.getIdFiche();

        num = UtilsService.cleanPhoneNumber(num);


        // Recupère les tel portables de la fiche et parcour les resultats
        var arrayPortables = this.getTelephonesPortable();
        if(arrayPortables.length){
          for(var f = 0 ; f < arrayPortables.length ; f++){
            if(arrayPortables[f].getDonnee() === num){
              numExist = true;
              break;
            }
          }
        }

        // Si il y a une fiche père
        if(this.hasFichePere()){
          // Si le numéro pas encore trouvé
          if(!numExist){
            // Recupère les tel portables de la fichePere et parcour les resultats
            var arrayFichePerePortables = this.getFichePere().getTelephonesPortable();
            if(arrayFichePerePortables.length){
              for(var f2 = 0 ; f2 < arrayFichePerePortables.length ; f2++){
                if(arrayFichePerePortables[f2].getDonnee() === num){
                  numExist = true;
                  break;
                }
              }
            }

            // Si le num existe pas et qu'il faut l'ajouter dans la fiche perso
            if(!numExist && addInFichePerso) idFiche = this.getFichePere().getIdFiche();
          }
        }

        if(!numExist){
          var params = {
            idFiche: idFiche,
            idState: Renseignement.STATE.PUBLIC.idState,
            idReference: Renseignement.REFERENCE.PORTABLE.idReference,
            donnee: num
          };

          FicheAjax.addFiche(params,function(json){

            if(json.nb!=='0'){
              _this.setData(json.Fiches[0]);
              deferred.resolve(_this);
            }else{
              deferred.resolve(false);
            }

          },function(error){
            ErreurCollabService.erreurServlet(error,'Problème avec retour servlet Fiche.saveTelMobile()',deferred);
          });
        }else{
          deferred.resolve();
        }

        return deferred.promise;
      },

      getRenseignementsWithReference: function (nomRef) {
        var renseignements = [];
        if(angular.isArray(this.getInformations()) && this.getInformations().length) {

          var idRefSearch = null;
          // Si "nomRef" est un id alors on a déjà l'id du type de référence
          if(angular.isNumber(nomRef)) idRefSearch = nomRef;
          // Si c'est un string alors on regarde dans la liste des référence le type et la categorie on trouve on récupère l'id
          else if(angular.isString(nomRef)) {
            idRefSearch = [];
            for(var r = 0; r < Renseignement.REFERENCE.listReference.length; r++){
              if(Renseignement.REFERENCE.listReference[r].categorie === nomRef || Renseignement.REFERENCE.listReference[r].type === nomRef) {
                idRefSearch.push(Renseignement.REFERENCE.listReference[r].idReference);
              }
            }
          }

          if(idRefSearch) {
            // Parcour les renseignements pour tester les références et récupèrer la bonne
            for(var i = 0; i < this.getInformations().length; i++) {
              if(angular.isNumber(idRefSearch)) {
                if(angular.isObject(this.getInformations()[i].getReference()) && this.getInformations()[i].getReference().getIdReference() === idRefSearch){
                  renseignements.push(this.getInformations()[i]);
                }
              }
              else if(angular.isArray(idRefSearch)) {
                if(angular.isObject(this.getInformations()[i].getReference()) && idRefSearch.indexOf(this.getInformations()[i].getReference().getIdReference()) !== -1){
                  renseignements.push(this.getInformations()[i]);
                }
              }
            }
          }
        }
        return renseignements;
      }

    };
    return Fiche;
  }
})();
