(function () {
  'use strict';
  /**
   * @ngdoc factory
   * @name collaboreApp.ProxyContenuDevis
   * @description
   * # ProxyContenuDevis
   */

  angular.module('collaboreApp').factory('ProxyContenuDevis', ProxyContenuDevisModel);

  /** @ngInject */
  function ProxyContenuDevisModel(ProxyContenu, ProxyPersoIcs, ProxyContenuFacture, ProxyDestinataire, ProxyRelanceAutoConfig) {

    function ProxyContenuDevis(objetData) {
      ProxyContenu.apply(this, arguments);
      this.hydrate(objetData);
      this.typeContenu = 3;
    }

    ProxyContenuDevis.prototype = {

      // idEvenement
      getIdEvenement: function () {
        return this.idEvenement;
      },
      setIdEvenement: function (val) {
        this.idEvenement = val;
      },

      // tagFournisseur
      getTagFournisseur: function () {
        return this.tagFournisseur;
      },
      setTagFournisseur: function (val) {
        this.tagFournisseur = val;
      },

      // montant
      getMontant: function () {
        return this.montant;
      },
      setMontant: function (val) {
        this.montant = val;
      },

      // groupement
      getGroupement: function () {
        return this.groupement;
      },
      setGroupement: function (val) {
        this.groupement = val;
      },

      // descriptionDevis
      getDescriptionDevis: function () {
        return this.descriptionDevis;
      },
      setDescriptionDevis: function (val) {
        this.descriptionDevis = val;
      },

      // dateEnvoi
      /*
       getDateEnvoi: function(){
       return this.dateEnvoi;
       },
       setDateEnvoi: function(val){
       this.dateEnvoi = val;
       },*/

      // dateReception
      /*
       getDateReception: function(){
       return this.dateReception;
       },
       setDateReception: function(val){
       this.dateReception = val;
       },*/


      // TODO : Dois les garder pour le moment pour l'ancien enregistrement
      /*
      // dateRelances
      getDateRelances: function () {
        return this.dateRelances;
      },
      setDateRelances: function (val) {
        this.dateRelances = val;
      },*/

      /*
       // acceptedByCS
       getAcceptedByCS: function(){
       return this.acceptedByCS;
       },
       setAcceptedByCS: function(val){
       this.acceptedByCS = val;
       },

       // acceptedBySyndic
       getAcceptedBySyndic: function(){
       return this.acceptedBySyndic;
       },
       setAcceptedBySyndic: function(val){
       this.acceptedBySyndic = val;
       },

       // refusedByCS
       getRefusedByCS: function(){
       return this.refusedByCS;
       },
       setRefusedByCS: function(val){
       this.refusedByCS = val;
       },

       // refusedBySyndic
       getRefusedBySyndic: function(){
       return this.refusedBySyndic;
       },
       setRefusedBySyndic: function(val){
       this.refusedBySyndic = val;
       },

       // Pas utilisé pour le moment
       // proxyIntervention
       getProxyIntervention: function(){
       return this.proxyIntervention;
       },
       setProxyIntervention: function(val){
       this.proxyIntervention = val;
       },
       */

      // proxyFacture
      getProxyFacture: function () {
        return this.proxyFacture;
      },
      setProxyFacture: function (val) {
        if (angular.isObject(val)) {
          this.proxyFacture = new ProxyContenuFacture(val);
        }
      },
      setFacture: function (val) {
        this.setProxyFacture(val);
      },

      /*
       // groupeValidationCS
       getGroupeValidationCS: function(){
       return this.groupeValidationCS;
       },
       setGroupeValidationCS: function(val){
       this.groupeValidationCS = val;
       },

       // dateValidationCS
       getDateValidationCS: function(){
       return this.dateValidationCS;
       },
       setDateValidationCS: function(val){
       this.dateValidationCS = val;
       },
       */

      /*
       // groupeValidationSyndic
       getGroupeValidationSyndic: function(){
       return this.groupeValidationSyndic;
       },
       setGroupeValidationSyndic: function(val){
       this.groupeValidationSyndic = val;
       },

       /*
       // dateValidationSyndic
       getDateValidationSyndic: function(){
       return this.dateValidationSyndic;
       },
       setDateValidationSyndic: function(val){
       this.dateValidationSyndic = val;
       },*/

      // documentDevis
      getDocumentDevis: function () {
        return this.documentDevis;
      },
      setDocumentDevis: function (val) {
        var document = false;
        if (_.isObject(val)) {
          document = val.guid;
          // Sinon si c'est déjà le guid on le récupère
        } else if (_.isString(val)) {
          document = val;
        }
        if (document) {
          this.documentDevis = document;
        }
      },

      // listDocumentDevis
      getListDocumentDevis: function () {
        return this.listDocumentDevis;
      },
      setListDocumentDevis: function (val) {
        var listDocument = [];
        if(_.isArray(val)) {
          if(val.length) {
            for(var i = 0; i < val.length; i++) {
              if(_.isObject(val[i])) listDocument.push(val[i].guid);
              else if(_.isString(val[i])) listDocument.push(val[i]);
            }
          }
        }
        else if(_.isObject(val)) listDocument.push(val.guid);
        // Sinon si c'est déjà le guid on le récupère
        else if(_.isString(val)) listDocument.push(val);

        this.listDocumentDevis = listDocument;
      },

      // documentDevisSigne
      getDocumentDevisSigne: function () {
        return this.documentDevisSigne;
      },
      setDocumentDevisSigne: function (val) {
        var document = false;
        if (angular.isObject(val)) {
          document = val.guid;
          // Sinon si c'est déjà le guid on le récupère
        } else if (angular.isString(val)) {
          document = val;
        }
        if (document) {
          this.documentDevisSigne = document;
        }
      },

      // persoICS
      getPersoICS: function () {
        return this.persoICS;
      },
      setPersoICS: function (val) {
        if (angular.isObject(val)) {
          this.persoICS = new ProxyPersoIcs(val);
        }
      },

      // demandeDevisActive
      getDemandeDevisActive: function () {
        return this.demandeDevisActive;
      },
      setDemandeDevisActive: function (val) {
        this.demandeDevisActive = val;
      },

      // genereTvaSimplifie
      getGenereTvaSimplifie: function () {
        return this.genereTvaSimplifie;
      },
      setGenereTvaSimplifie: function (val) {
        this.genereTvaSimplifie = val;
      },

      // sendAttachementsWhenCreateDevis
      getSendAttachementsWhenCreateDevis: function () {
        return this.sendAttachementsWhenCreateDevis;
      },
      setSendAttachementsWhenCreateDevis: function (val) {
        this.sendAttachementsWhenCreateDevis = val;
      },

      // sendMailWhenAcceptOrDeclineDevis
      getSendMailWhenAcceptOrDeclineDevis: function () {
        return this.sendMailWhenAcceptOrDeclineDevis;
      },
      setSendMailWhenAcceptOrDeclineDevis: function (val) {
        this.sendMailWhenAcceptOrDeclineDevis = val;
      },

      // listeDestinatairesMessageGravitants (destinataires de la copie de la demande de devis)
      getListeDestinatairesMessageGravitants: function () {
        if (!this.hasOwnProperty('autresDestinatairesDemande')) {
          this.listeDestinatairesMessageGravitants = [];
        }
        else if (!angular.isArray(this.listeDestinatairesMessageGravitants)) {
          this.listeDestinatairesMessageGravitants = [];
        }
        return this.listeDestinatairesMessageGravitants;
      },
      setListeDestinatairesMessageGravitants: function (val) {
        var retourArrayListeDestinatairesMessageGravitants = [];
        if (angular.isArray(val)) {
          for (var i = 0; i < val.length; i++) {
            retourArrayListeDestinatairesMessageGravitants.push(new ProxyDestinataire(val[i]));
          }
        }
        this.listeDestinatairesMessageGravitants = retourArrayListeDestinatairesMessageGravitants;
      },
      addListeDestinatairesMessageGravitants: function (val) {
        var retourArrayListeDestinatairesMessageGravitants = this.getListeDestinatairesMessageGravitants();
        retourArrayListeDestinatairesMessageGravitants.push(new ProxyDestinataire(val));
        this.listeDestinatairesMessageGravitants = retourArrayListeDestinatairesMessageGravitants;
      },

      // messageGravitants
      getMessageGravitants: function () {
        return this.messageGravitants;
      },
      setMessageGravitants: function (val) {
        this.messageGravitants = val;
      },

      // SendOneMailWithAllGravitants
      getSendOneMailWithAllGravitants: function(){
        return this.sendOneMailWithAllGravitants;
      },
      setSendOneMailWithAllGravitants: function(bool){
        this.sendOneMailWithAllGravitants = bool;
      },

      // persoICS
      getRelanceAutoConfig: function () {
        return this.relanceAutoConfig;
      },
      setRelanceAutoConfig: function (val) {
        if (_.isObject(val)) {
          this.relanceAutoConfig = new ProxyRelanceAutoConfig(val);
        }
      }

    };

    angular.extend(ProxyContenuDevis.prototype, ProxyContenu.prototype);

    return ProxyContenuDevis;
  }
})();
