(function () {

  'use strict';

  /**
   * @desc Composant login
   * @example <layout-login></layout-login>
   */
  angular
    .module('collaboreApp')
    .component('layoutLogin', {
      templateUrl: 'app/layout/layout-login/layout-login.component.html',
      controllerAs: 'layoutloginctrl',
      controller: LayoutLoginCtrl
    });

  /** @ngInject */
  function LayoutLoginCtrl(COLLAB_CONF, COLLAB_VALUES, $window, $rootScope, $scope, $filter, $state, $stateParams, UserService, ConfigService, UtilsService, FiltreService) {
    var ctrl = this;

    ctrl.versionCollab = COLLAB_CONF.VERSION_COLLAB;

    ctrl.logWithKerberos = COLLAB_CONF.AUTH_KERBEROS;
    ctrl.logWithOnelogin = COLLAB_CONF.AUTH_ONELOGIN;

    ctrl.logAuto = (ctrl.logWithKerberos || ctrl.logWithOnelogin);
    ctrl.loading = false;

    ctrl.identifiants = {
      login: null,
      mdp: null,
      diese: null,
      dieseInLS: null,
      seSouvenir: false
    };

    ctrl.onSubmitFormLogin = onSubmitFormLogin;
    ctrl.onClickConnexion = onClickConnexion;

    ctrl.$onInit = function () {

      $scope.$on('msgErreur', function (event, msg) {
        ctrl.loading = false;
        ctrl.displayMsgLogin = true;
        ctrl.erreurLogin = true;
        ctrl.msgLogin = msg;
      });

      var login = ConfigService.getUserLogin();
      if(!_.isEmpty(login)){
        ctrl.identifiants.login = angular.copy(login);
        ctrl.identifiants.seSouvenir = true;
      }

      var motdepasse = ConfigService.getUserPass();
      if(!_.isEmpty(motdepasse)){
        ctrl.identifiants.mdp = angular.copy(motdepasse);
        ctrl.identifiants.seSouvenir = true;
      }

      var diese = ConfigService.getUserDiese();
      if(!_.isEmpty(diese)){
        ctrl.identifiants.diese = angular.copy(diese);
        ctrl.identifiants.dieseInLS = angular.copy(diese);
        ctrl.identifiants.seSouvenir = true;
      }

      if($stateParams.infosExtranetFournisseur) ctrl.infosExtranetFournisseur = $stateParams.infosExtranetFournisseur;
      if($stateParams.forceManuelLogin)         ctrl.forceManuelLogin = $stateParams.forceManuelLogin;

      /*
	if(!_.isNil($stateParams.visicop) && $stateParams.visicop){
    $rootScope.openInVisicop = true;
  }*/

      if(!_.isNil($stateParams.token)){
        UserService
          .connexion({token: $stateParams.token})
          .then(function(){
            ctrl.displayMsgLogin = false;
            ctrl.loading = false;

            // Go accueil
            $state.go(UtilsService.getStateHomeCollab(), FiltreService.getObjFiltreByChannel(UtilsService.getStateHomeCollabParams()));
          })

          .catch(function(error){
            if(_.isObject(error) && !_.isNil(error.msg)){
              ctrl.erreurLogin = true;
              ctrl.msgLogin = error.msg;
            }
          })
          .finally(function(){
            ctrl.loading = false;
          });
      }
      if(!_.isNil($stateParams.erreur)){
        ctrl.erreurLogin = true;
        ctrl.msgLogin = $stateParams.erreur;
      }
    };

    function onSubmitFormLogin(form){
      submitLogin(form);
    }

    function onClickConnexion(){
      console.log(COLLAB_VALUES.CONF_URL.FULL_PATH_TOMCAT + COLLAB_CONF.SERVLET_AUTHENTIFICATION_ONELOGIN);
      if(ctrl.logWithOnelogin) $window.location = COLLAB_VALUES.CONF_URL.FULL_PATH_TOMCAT + COLLAB_CONF.SERVLET_AUTHENTIFICATION_ONELOGIN;
      //if(ctrl.logWithOnelogin) $window.location = "https://collab.citya.com/tomcat/CollaborePreprod/"+ COLLAB_CONF.SERVLET_AUTHENTIFICATION_ONELOGIN;
      else submitLogin();
    }

    function submitLogin(form){
      ctrl.erreurLogin = false;
      ctrl.msgLogin = null;

      // Si formulaire n'est pas rempli, message d'erreur et return;
      if((!ctrl.logWithKerberos || ctrl.forceManuelLogin) && !_.isNil(form) && !form.$valid){
        ctrl.erreurLogin = true;
        ctrl.msgLogin = 'Veuillez renseigner vos identifiants !';
        return;
      }

      var params = {};
      if(!_.isEmpty(ctrl.identifiants.login) && !_.isEmpty(ctrl.identifiants.diese)){
        params.login = '@'+_.trimStart(angular.copy(ctrl.identifiants.login), '@');
        params.dieze = '#'+_.trimStart(angular.copy(ctrl.identifiants.diese), '@');
        params.mdp = ctrl.identifiants.mdp;
      }

      ctrl.loading = true;
      UserService
        .connexion(params, ctrl.forceManuelLogin)
        .then(function(){
          if(ctrl.identifiants.seSouvenir){
            ConfigService.setUserLogin(_.trimStart(angular.copy(params.login), '@'));
            ConfigService.setUserPass(params.mdp);
            // Ajoute le diese dans le local storage
            if(!_.isNil(params.dieze)) ConfigService.setUserDiese(_.trimStart(params.dieze, '#'));
          }
          else{
            ConfigService.setUserLogin(false);
            ConfigService.setUserPass(false);
            ConfigService.setUserDiese('');
          }
          UtilsService.goRouteAskedOrLogin(UtilsService.getStateHomeCollab(), FiltreService.getObjFiltreByChannel(UtilsService.getStateHomeCollabParams()));
          // Go accueil
          // $state.go(UtilsService.getStateHomeCollab(), FiltreService.getObjFiltreByChannel(UtilsService.getStateHomeCollabParams()));
        })
        .catch(function(error){
          if(_.isObject(error) && !_.isNil(error.msg)){
            ctrl.erreurLogin = true;
            ctrl.msgLogin = error.msg;
          }
        })
        .finally(function(){
          ctrl.loading = false;
        });
    }
  }
})();
