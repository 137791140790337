(function() {

  'use strict';

  /**
   * @desc Composant recapMail
   * @example <facture-manager></facture-manager>
   */
  angular
    .module('collaboreApp')
    .component('factureManager',{
      templateUrl: 'app/facture-manager/facture-manager.component.html',
      controllerAs: 'facturemanagerctrl',
      bindings: {
        idTopic: '<',
        idDevis: '<',
        idFacture: '<',
        guid: '<',
        typeFacture: '@'
      },
      controller: FactureManagerCtrl
    });

  /** @ngInject */
  function FactureManagerCtrl(COLLAB_CONF, COLLAB_VALUES, $window, $state, NotificationsToastServices, FileUploader, $scope, $stateParams, $q, $sce, bowser, DocumentManagerService, UtilsService, ModalsService, FiltreService, FactureService, ErreurCollabService){
    var _this = this;
    var queueLimit = false;

    _this.oldStates = {
      facturesCollab: {
        stateName: ($state.current.name === 'facturesAutonomes') ? 'factures' : $state.current.name,
        stateParams: null,
      },
      facturesAutonomes: {
        stateParams: null,
      }
    };

    _this.facturesAutonomesEnabled = UtilsService.getParametrageComfactSociete('facturesAutonomesEnabled');
    _this.ocrEnabled = UtilsService.getParametrageComfactSociete('ocrEnabled');

    _this.loadingUpload = false;
    _this.extensionsAllowed = ['pdf', 'jpg', 'png', 'jpeg', 'gif'];
    _this.sizeLimit = COLLAB_CONF.SIZE_LIMIT_UPLOAD;

    _this.indexTabs = {
      facturesCollab: 0,
      facturesAutonomes: 1,
    };
    _this.nl2br = UtilsService.nl2br;

    _this.onSelectTab = onSelectTab;
    _this.removeFile = removeFile;
    _this.removeAllFile = removeAllFile;
    _this.onClickUploadAll = onClickUploadAll;
    _this.onClickModifierCommentaireFacture = onClickModifierCommentaireFacture;
    _this.onClickClotureDemande = onClickClotureDemande;
    _this.onClickDeleteFacture = onClickDeleteFacture;
    _this.onEmitFromFactureCollab = onEmitFromFactureCollab;
    _this.onEmitFromFactureAutonome = onEmitFromFactureAutonome;

    var listenerHeight;
    _this.$onInit = function() {

      _this.mainDiv = angular.element('#main-content');
      listenerHeight = $scope.$on('calcHeight', function(){
        postToParent();
      });

      if(_.isNil(_this.typeFacture)) _this.typeFacture = "collab";
      _this.selectedTab = (_this.typeFacture === "autonome") ? _this.indexTabs.facturesAutonomes : _this.indexTabs.facturesCollab;

      if(!_this.facturesAutonomesEnabled && ($state.current.name === 'facturesAutonomes' || _this.selectedTab === 'autonome')) {
        $state.go('factures', null, {reload: true, notify: true, location: true});
      }

      //console.log('oldStateName', _this.oldStateName);
      //console.log('oldStateParams', _this.oldStateParams);

      //console.log('idTopic', _this.idTopic);
      //console.log('idFacture', _this.idFacture);

      initInfos();
      initDocument();
      initUpload();
    };

    _this.$onDestroy = function(){
      if(!_.isNil(listenerHeight)) listenerHeight();
    };

    function postToParent() {
      parent.window.postMessage({'factureManagerHeight': _this.mainDiv.height()}, '*');
    }

    function onSelectTab(tab){
      //selectTab(tab, null);
    }

    function selectTab(tab, document){
      if(_this.selectedTab != tab) {
        _this.selectedTab = tab;
        initDocument(document);
      }
    }

    /**
     * Initialisation des variables
     */
    function init(obj){

      _this.idDevis = null;
      _this.idFacture = null;
      _this.idTopic = null;

      initInfos(obj);
      initDocument(obj);
    }

    function initInfos(obj){
      if(_.isObject(obj) && _.isObject(obj.infos)){
        if(!_.isNil(obj.infos.idDevis))    _this.idDevis = obj.infos.idDevis;
        if(!_.isNil(obj.infos.idFacture))  _this.idFacture = obj.infos.idFacture;
        if(!_.isNil(obj.infos.idTopic))    _this.idTopic = obj.infos.idTopic;
      }
    }


    function initDocument(obj){
      //_this.idDevis = null;               // Devis
      _this.documentFacture = null;       // Objet Document de la Facture actuel

      // PDF Facture
      _this.urlPdfFacture = null;        // Url du PDF facture pour ouvrir en lecture
      _this.loadingPdfFacture = false;   // Loading du PDF facture

      if(_.isObject(obj) && _.isObject(obj.document)) _this.documentFacture = angular.copy(obj.document);
    }

    /**
     * Permet d'init un objet pour la notification d'upload
     */
    function initNotifUpload() {
      _this.notifUpload = {
        progress: 0,
        current: 0,
        total: 0,
        name: "",
        uploadedSuccessDocs: [],
        uploadedErrorItemFile: []
      };

      if(!_.isNil(_this.uploader)){
        _this.uploader.clearQueue();
      }
    }

    function extensionIsAllow(nom, extension){
      if(!_this.extensionsAllowed.contains(_.trimStart(extension, '.'))){
        var message = 'un ' + _this.extensionsAllowed[0].toUpperCase();
        if(_this.extensionsAllowed.length) message = 'une de ces extensions: ' + _this.extensionsAllowed.join(', ');
        ModalsService.alertErreur('Erreur type de fichier', '<span class="text-danger">' + nom + '</span> doit être ' + message);
        return false;
      }
      return true;
    }

    function removeFile(fileItem){
      _this.uploader.removeFromQueue(fileItem);

      if(!_this.uploader.queue.length) onUploadSuccess();
      else if(!_.isEmpty(_this.notifUpload.uploadedErrorItemFile)){
        _.remove(_this.notifUpload.uploadedErrorItemFile, function(item){
          return (fileItem.file.name == item.file.name && fileItem.file.size == item.file.size && fileItem.file.lastModifiedDate == item.file.lastModifiedDate);
        });
      }
    }

    function removeAllFile(){
      onUploadSuccess();
    }

    /**
     * Permet d'init les uploader des document devis de chaque demande
     * @param demande
     */
    function initUpload() {
      initNotifUpload();

      var paramsUploader = {
        autoUpload: false,
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_UPLOAD_DOCUMENT,
        alias: 'fileName',
        removeAfterUpload: false,
        //withCredentials: true,
        filters: [
          {
            name: 'sizeLimit',
            fn: function (item) {
              if (item.size <= _this.sizeLimit) return true;
              else ModalsService.alertErreur('<span class="text-danger">' + item.name + '</span> trop gros !', UtilsService.bytes(_this.sizeLimit) + ' Maximum');
            }
          },
          {
            name: 'onlyExtensions',
            fn: function (item) {
              var type = item.type.slice(item.type.lastIndexOf('/') + 1);
              if(extensionIsAllow(item.name, type)) return true;
            }
          },
          {
            name: 'doublon',
            fn: function (item) {
              var add = true;
              for(var i = 0; i < _this.uploader.queue.length; i++){
                if(item.name == _this.uploader.queue[i].file.name && item.size == _this.uploader.queue[i].file.size) {
                  add = false;
                  break;
                }
              }
              return add;
            }
          }
        ]
      };

      if(_.isNumber(queueLimit)) {
        paramsUploader.queueLimit = queueLimit;
      }

      _this.uploader = new FileUploader(paramsUploader);

      /**
       * Après l'ajout d'un fichier en pièce jointe
       * @param fileItem
       */
      _this.uploader.onAfterAddingFile = function afterAddingFile(fileItem) {
        //console.log("onAfterAddingFile", fileItem);
        if(_.isNumber(queueLimit)){
          var nbInQueue = _this.uploader.queue.length;
          if (nbInQueue > queueLimit) {
            fileItem.remove();
            ModalsService.alertErreur('Limite atteinte', '<span class="text-danger">Vous ne pouvez pas mettre plus de '+queueLimit+' document(s)</span>');
            return;
          }
        }
        fileItem.formData.push({action: 'uploadTypedDocument'});
        fileItem.formData.push({typeDocument: COLLAB_CONF.TYPE_DOC_ENUMS.FACTURE_AUTONOME});
        if(_this.ocrEnabled) fileItem.formData.push({submitToOCR: true});
        fileItem.formData.push({convertToPdf: true});
        fileItem.formData.push({token: UtilsService.getCurrentUser().getToken()});
        _this.notifUpload.uploadedErrorItemFile = [];
      };


      _this.uploader.onAfterAddingAll = function(addedItems) {
        //console.log("onAfterAddingAll", addedItems);
      };

      _this.uploader.onBeforeUploadItem = function(item){
        _this.notifUpload.current = _this.notifUpload.current + 1;
        _this.notifUpload.name = item.file.name;
      };

      /**
       * Lorsqu'un fichier est bien upload
       * @param fileItem
       * @param response
       */
      _this.uploader.onSuccessItem = function (fileItem, response) {
        if(response.success) {
          if(_.isArray(response.result)) {

            var documents = response.result.map(function(doc){
              return DocumentManagerService.instanceDoc(doc);
            });

            _this.notifUpload.uploadedSuccessDocs = _.concat(_this.notifUpload.uploadedSuccessDocs, documents);
          }
          else ModalsService.alertErreur('Erreur avec le retour lors de l\'envoie du document');
        }
        else ModalsService.alertErreur(response.message);
      };

      /**
       * Si erreur sur un item à upload
       */
      _this.uploader.onErrorItem = function (item, response, status, headers) {
        //console.error("onErrorItem item", item);
        //console.error("onErrorItem response", response);
        //console.error("onErrorItem status", status);

        var msg = "Problème avec le fichier";
        if(!_.isNil(status)) msg += " (Code: "+status+")";
        if(_.isObject(response)) {
          if(!_.isNil(response.message)) msg = response.message;
        }

        item.erreur = msg;

        _this.notifUpload.uploadedErrorItemFile.push({
          message: msg,
          file: item
        });
      };

      _this.uploader.onProgressAll = function (progress) {
        _this.notifUpload.progress = progress;
      };

      /**
       * Lorsque l'upload de toute la liste est terminée
       */
      _this.uploader.onCompleteAll = function () {

        // Si pas d'erreur on reset l'objet de notification et affiche le 1er document envoyé
        if(_.isEmpty(_this.notifUpload.uploadedErrorItemFile)) {
          onUploadSuccess();
        }

        _this.notifUpload.current = 0;
        _this.notifUpload.progress = 0;
        _this.notifUpload.name = "";
      };
    }

    function onClickUploadAll(){
      _this.notifUpload.total = _this.uploader.queue.length;
      _this.uploader.uploadAll();
    }

    function onUploadSuccess(){
      if(!_.isEmpty(_this.notifUpload.uploadedSuccessDocs)){
        var lastDoc = _this.notifUpload.uploadedSuccessDocs[_this.notifUpload.uploadedSuccessDocs.length - 1];
        if(!_.isNil(lastDoc)){
          _this.guid = lastDoc.guid;
          if(_this.selectedTab !== _this.indexTabs.facturesAutonomes) selectTab(_this.indexTabs.facturesAutonomes, lastDoc);
          else initDocument(lastDoc);
        }
      }

      initNotifUpload();
    }

    /**
     * Permet de charger l'url d'un PDF
     * @param guid
     */
    function loadPdf(obj){
      initDocument(obj);
      var guid = null;
      if(_.isObject(_this.documentFacture)) guid = _this.documentFacture.getGuid();
      if(_.isNil(guid) && _.isObject(obj) && !_.isNil(obj.guid)) guid = obj.guid;

      if(!_.isNil(guid)){
        _this.loadingPdfFacture = true;
        getUrlPdf(guid)
          .then(function(urlFacture){
            _this.urlPdfFacture = urlFacture;
          })
          .catch(function(msg){
            _this.urlPdfFacture = null;
            //ModalsService.alertErreur('Facture ' + msg);
          })
          .finally(function(){
            _this.loadingPdfFacture = false;
            postToParent();
          });
      }
    }

    /**
     * Permet de récupérer l'url d'un PDF avec son Guid
     * @param guid
     * @returns {*}
     */
    function getUrlPdf(guid){
      var deferred = $q.defer();
      if (bowser.msie) deferred.resolve($sce.trustAsResourceUrl(encodeURIComponent(DocumentManagerService.getUrlStream(guid, _this.idFacture, _this.idTopic))));
      else {
        // Recupère le PDF
        DocumentManagerService
          .getStream(guid, _this.idFacture, _this.idTopic)
          .then(function (url) {
            deferred.resolve(encodeURIComponent(url));
          })
          .catch(function(msg){
            deferred.reject(msg.statusText);
          });
      }
      return deferred.promise;
    }

    /**
     * Permet de passer à une autre facture suivante ou précédente selon si il en reste après ou pas
     */
    function goToOtherFacture(){
      $scope.$broadcast('goToOtherFacture');
    }

    /**
     * Lors du clique sur le bouton pour modifier le commentaire de la facture
     */
    function onClickModifierCommentaireFacture(){
      if(_this.documentFacture) {
        var description = '';
        if(_this.documentFacture.getDescription()) description = _this.documentFacture.getDescription();

        var textModal = '<div class="text-left"><strong>Commentaire :</strong></div><textarea style="width:100%" rows="3" id="commentaireFacture" class="form-control">'+description+'</textarea>';
        ModalsService.confirm('Commentaire de la facture', textModal, {type: 'info'}).then(function (modal) {
          _this.loadingPdfFacture = true;
          var commentaireFacture = angular.element('#commentaireFacture').val();
          _this.documentFacture.setDescription(commentaireFacture);
          _this.documentFacture
            .saveDescription()
            .then(function(){
              modal.close();
            })
            .catch(function (msg) {
              ModalsService.alertErreur(msg);
            })
            .finally(function(){
              _this.loadingPdfFacture = false;

            });
        });
      }
    }

    /**
     * Lors du clique sur le bouton pour cloturer la demande
     */
    function onClickClotureDemande(){
      if(_this.idDevis) {

        var params = {
          idContenu: _this.idDevis,
          hideLinkFournisseur: true,
          titleModal: 'Cloturer la demande'
        };

        ModalsService
          .modalComponent('raisonClotureContenu', params, {size: 'lg'})
          .then(function onSuccess(obj){
            goToOtherFacture();
          });
      }
    }

    function onClickDeleteFacture(){
      if(_this.selectedTab === _this.indexTabs.facturesAutonomes){
        ModalsService
          .confirm('Suppression facture autonome', 'Voulez-vous vraiment supprimer cette facture ?', {type: 'info'})
          .then(function (modal) {
            _this.loadingPdfFacture = true;
            FactureService
              .deleteFactureAutonome(_this.documentFacture.getGuid())
              .then(function onSuccess(ret){
                console.log(ret);
                goToOtherFacture();
                modal.close();
              })
              .catch(function (msg) {
                ModalsService.alertErreur(msg);
              })
              .finally(function onFinally(){
                _this.loadingPdfFacture = false;
              });
          });
      }
    }

    /**
     * Lorsque le composant enfant facture-collab emit des informations
     * @param obj
     */
    function onEmitFromFactureCollab(obj){
      //console.log(obj);
      try {
        if(!_.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');
        if(_.isNil(obj.action)) throw new Error('Le paramètre "obj" doit avoir une action');

        if(obj.action === "initAndLoadPdf") {
          init(obj);
          loadPdf(obj);
        }
        if(obj.action === "cleanInit") {
          initInfos();
          initDocument();
        }
        if(obj.action === "loadPdf") loadPdf(obj);

        if(_.isObject(obj.states)) _this.oldStates.facturesCollab = angular.copy(obj.states);
        //console.log(_this.oldStates);
        postToParent();
      }
      catch(err) {
        ErreurCollabService.logErreur('[FactureManagerCtrl.onEmitFromFactureCollab] - ' + err.message);
      }
    }

    /**
     * Lorsque le composant enfant facture-autonome emit des informations
     * @param obj
     */
    function onEmitFromFactureAutonome(obj){
      //console.log(obj);
      try {
        if(!_.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');
        if(obj.action === "init") init(obj)
        if(obj.action === "loadPdf") {
          init(obj)
          loadPdf(obj)
        }
        if(_.isObject(obj.states)) _this.oldStates.facturesAutonomes = angular.copy(obj.states);
        //console.log(_this.oldStates);
        postToParent();
      }
      catch(err) {
        ErreurCollabService.logErreur('[FactureManagerCtrl.onEmitFromFactureAutonome] - ' + err.message);
      }
    }
  }
})();
