(function () {

    'use strict';

    /**
     * @desc Composant groupe ligne
     * @example <admin-collab-groupe-ligne></admin-collab-groupe-ligne>
     */
    angular
        .module('collaboreApp')
        .component('adminCollabGroupeLigne', {
            bindings: {
                groupeUser: '<', // Groupe user
                groupe: '<',     // Object<Groupe>
                assignTopic: '<',
                assignTopicCloture: '<',
                onProgress: '&'
            },
            templateUrl: 'app/admin/groupe/admin-collab-groupe-ligne.component.html',
            controllerAs: 'admincollabgroupelignectrl',
            controller: AdminCollabGroupeLigneCtrl
        });


    /** @ngInject */
    function AdminCollabGroupeLigneCtrl($scope, $timeout, AdminCollabService, sweet, ErreurCollabService, ModalsService) {
        var ctrl = this;

        ctrl.onLigneProgress = onLigneProgress;
        ctrl.onChangePresence = onChangePresence;
        ctrl.onClickAssignTopicCloture = onClickAssignTopicCloture;
        ctrl.onClickAssignTopicNonCloture = onClickAssignTopicNonCloture;

        ctrl.$onInit = function () {

            /*
             if (ctrl.groupe.isAll()) {
             ctrl.groupe.allUsers[0].accueil = ChannelService.listeChannelHasAccueilForGroupe($rootScope.current.userGroupe.getListeChannel(), ctrl.groupe.allUsers[0]);
             ctrl.groupe.allUsers[0].channel = ChannelService.listeChannelHasChannelForGroupe($rootScope.current.userGroupe.getListeChannel(), ctrl.groupe.allUsers[0]);
             }
             ctrl.groupe.accueil = ChannelService.listeChannelHasAccueilForGroupe($rootScope.current.userGroupe.getListeChannel(), ctrl.groupe);
             ctrl.groupe.channel = ChannelService.listeChannelHasChannelForGroupe($rootScope.current.userGroupe.getListeChannel(), ctrl.groupe);
             */
        };


        function onLigneProgress(progress) {
            ctrl.onProgress({'progress': progress});
        }

        function onChangePresence(groupe) {

            if(!ctrl.groupeUser) {
                ErreurCollabService.logErreur('Pas de "groupeUser"');
                groupe.userIsPresent = !groupe.userIsPresent;
                return;
            }

            groupe.loading = true;

            // Si coche
            if (groupe.userIsPresent) {

                sweet.show({
                    title: 'Ajout dans un groupe',
                    text: 'Ajouter  "' + ctrl.groupeUser.getDefaultLibelle() + '" dans le groupe "' + groupe.getDefaultLibelle() + '" ?',
                    type: 'warning',
                    html:true,
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Oui !',
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    showLoaderOnConfirm: false
                },function(isConfirm){
                    if (isConfirm) {
                        //ctrl.onProgress({'progress': true});
                        AdminCollabService
                            .addGroupeInGroupe(ctrl.groupeUser.getIdGroupe(), groupe.getIdGroupe(), !ctrl.assignTopic, ctrl.assignTopicCloture)
                            .then(function () {
                                groupe.userIsPresent = true;
                                sweet.close();
                            })
                            .catch(function (msg) {
                                groupe.userIsPresent = false;
                                $timeout(function(){
                                  sweet.show('Problème...', msg, 'error');
                                },500);
                            })
                            .finally(function () {
                                groupe.loading = false;
                                //ctrl.onProgress({'progress': false});
                            });
                    }
                    else {
                        $timeout(function(){
                            groupe.loading = false;
                            groupe.userIsPresent = false;
                        });
                    }
                });
            }
            // Si décoche
            else {

                sweet.show({
                    title: 'Supprimer d\'un groupe',
                    text: 'Supprimer  "' + ctrl.groupeUser.getDefaultLibelle() + '" du groupe "' + groupe.getDefaultLibelle() + '" ?',
                    type: 'warning',
                    html:true,
                    showCancelButton: true,
                    confirmButtonColor: '#5cb85c',
                    confirmButtonText: 'Oui !',
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    showLoaderOnConfirm: false
                },function(isConfirm){
                    if (isConfirm) {
                        //ctrl.onProgress({'progress': true});
                        AdminCollabService
                            .removeGroupeFromGroupe(ctrl.groupeUser.getIdGroupe(), groupe.getIdGroupe())
                            .then(function () {
                                groupe.userIsPresent = false;
                                sweet.close();
                            })
                            .catch(function (msg) {
                                groupe.userIsPresent = true;
                                $timeout(function(){
                                  sweet.show('Problème...', msg, 'error');
                                },500);
                            })
                            .finally(function () {
                                groupe.loading = false;
                                //ctrl.onProgress({'progress': false});
                            });
                    }
                    else {
                        $timeout(function(){
                            groupe.loading = false;
                            groupe.userIsPresent = true;
                        });
                    }

                });
            }
        }

        function onClickAssignTopicCloture(groupe){
          try {
            if(!groupe) throw new Error('Le groupe parent n\'éxiste pas !');
            if (!groupe.userIsPresent) throw new Error('Impossible d\'effectuer cette action !');

            groupe.loading = true;

            sweet.show({
                title: 'Assigner les dossiers clôturés',
                text: 'Assigner les dossiers clôturés de "<strong>' + ctrl.groupe.libelle + '</strong> (et ses parents) à "<strong>' + ctrl.groupeUser.libelle + '</strong>" ?<br/>Ils peuvent déjà être assigner.',
                type: 'warning',
                html:true,
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Oui !',
                closeOnConfirm: true,
                closeOnCancel: true,
                showLoaderOnConfirm: false
            },function(isConfirm){
                if (isConfirm) {
                  AdminCollabService
                    .assignTopicCloture(ctrl.groupeUser.getIdGroupe(), groupe.getIdGroupe())
                    .then(function(){
                      sweet.close();
                    })
                    .catch(function (msg) {
                      $timeout(function(){
                        ModalsService.alertErreur(msg);
                      },500);
                    })
                    .finally(function () {
                      groupe.loading = false;
                  });
                }
                else {
                    $timeout(function(){
                        groupe.loading = false;
                    });
                }
            });
          }
          catch (e) {
            ModalsService.alertErreur(e.message);
          }
        }

        function onClickAssignTopicNonCloture(groupe){
          try {
            if(!groupe) throw new Error('Le groupe parent n\'éxiste pas !');
            if (!groupe.userIsPresent) throw new Error('Impossible d\'effectuer cette action !');

            groupe.loading = true;

            sweet.show({
                title: 'Assigner les dossiers non clôturés',
                text: 'Assigner les dossiers non clôturés de "<strong>' + ctrl.groupe.libelle + '</strong> (et ses parents) à "<strong>' + ctrl.groupeUser.libelle + '</strong>" ?<br/>Ils peuvent déjà être assigner.',
                type: 'warning',
                html:true,
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Oui !',
                closeOnConfirm: true,
                closeOnCancel: true,
                showLoaderOnConfirm: false
            },function(isConfirm){
                if (isConfirm) {
                  AdminCollabService
                    .assignTopicNonCloture(ctrl.groupeUser.getIdGroupe(), groupe.getIdGroupe())
                    .then(function(){
                      sweet.close();
                    })
                    .catch(function (msg) {
                      $timeout(function(){
                        ModalsService.alertErreur(msg);
                      },500);
                    })
                    .finally(function () {
                      groupe.loading = false;
                  });
                }
                else {
                    $timeout(function(){
                        groupe.loading = false;
                    });
                }
            });
          }
          catch (e) {
            ModalsService.alertErreur(e.message);
          }
        }

    }
})();
