(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.WebsocketServices
   * @description
   * # WebsocketServices
   * Service in the collaboreApp.
   */
  angular
    .module('collaboreApp')
    .service('WebsocketServices',WebsocketServices);

  /*@ngInject*/
  function WebsocketServices($log, COLLAB_CONF,COLLAB_VALUES,UtilsService,$injector,$rootScope,$timeout,$window,$stateParams,$websocket,$filter,$interval,TopicStatesService,AnnuaireService,NotificationsToastServices) {

    var scope = this;
    var forceDisconnect = false;
    var urlWS,urlToken,dataStream,intervalReconnectWS, intervalConnect;

    scope.connectWebsocket = connectWebsocket;
    scope.connect = connect;
    scope.startRetry = startRetry;
    scope.disconnect = disconnect;
    scope.initDevis = initDevis;
    scope.isConnected = false;

    $rootScope.arrayNewTopicStateTmp = [];
    $rootScope.arrayNewContenusTmp = [];


    /*
    scope.test = function(){
      console.log('emit');
      SocketNotification.emit('init', 'mm');

    };

    SocketNotification.on('test', function(d) {
      console.log('test',d);
    });*/

    function connectWebsocket(urlWebsocket, token){
      if(scope.isConnected || !$rootScope.withWebsockets) return;

      // Si il n'y a pas de tentative de connection en cours
      if(!scope.tryToConnect) scope.connect(urlWebsocket, token);
      // Si il y a une tentative de connexion déjà en cours et pas d'interval alors création d'une interval toutes les 5 secondes
      //else if(!intervalConnect) intervalConnect = $interval(scope.connect(urlWebsocket, token), 5000);
    }

    /**
     * Connection à la websocket java
     * @param urlWebsocket
     */
    function connect(urlWebsocket, token){

      // Si il y a déjà une tentative de connexion en cours on sort
      if(scope.tryToConnect) return;

      urlWS = urlWebsocket || COLLAB_VALUES.CONF_URL.URL_WEBSOCKET_JAVA;
      urlToken = token || $rootScope.token;

      if(urlToken){
        scope.tryToConnect = true;
        // Open a WebSocket connection
        dataStream = $websocket(urlWS + urlToken);

        dataStream.onOpen(function(){
          cancelInterval();
          scope.isConnected = true;
          scope.tryToConnect = false;
        });

        dataStream.onClose(function(){
          scope.isConnected = false;
          scope.tryToConnect = false;
          scope.startRetry();
          //if(intervalConnect) $interval.cancel(intervalConnect);
          $log.log('closeWS');
        });


        /*
        dataStream.onError(function(event){
          if(intervalConnect) $interval.cancel(intervalConnect);
          scope.startRetry();
          $log.log('errorWS', event);
        });*/

        dataStream.onMessage(function(message) {
          cancelInterval();
          // console.log('onMessage', message);
          init(angular.fromJson(message.data));
        });
      }
      else {
        cancelInterval();
        $log.error('Erreur Websocket : Pas de Token !');
      }
    }

    function init(obj){
      if(angular.isArray(obj) && obj.length) {
        var listeContenuDevis = []; // Liste de contenuDevis à init

        // Boucle sur l'array pour voir les différent objet à traiter, il sera possible mettre des type différent
        for(var i = 0; i < obj.length; i++) {
          if(obj[i].hasOwnProperty('idContenu') && obj[i].hasOwnProperty('typeContenu')) {
            // Si contenu Devis
            if(obj[i].typeContenu === 3) listeContenuDevis.push(obj[i]);
          }
        }

        if(listeContenuDevis.length) initDevis(listeContenuDevis);
        listeContenuDevis = null;
      }
      else if(angular.isObject(obj)) {
        if(obj.hasOwnProperty('idContenu') && obj.hasOwnProperty('typeContenu')) {
          // Si contenu Devis
          if(obj.typeContenu === 3) initDevis(obj);
        }
        else if(obj.hasOwnProperty('typeNotification')) {
          var NotificationService = $injector.get('NotificationService');
          NotificationService.websocketNotification(obj);
          NotificationService = null;
        }
      }

      /*
      if(obj.alias === 'Information'){
        //InformationsServices.initInformationsWebsocket(obj);
      }

      // if(obj.alias==='Notification'){
      // TODO : [Notification] Ajouter une notification
      // }

      if(obj.alias==='ListeContact'){
        AnnuaireService.setAnnuaire(obj);
      }

      if(obj.alias==='TopicState'){

        $rootScope.arrayNewTopicStateTmp.push(obj);

        // Notification du nouveaux contenu
        NotificationsToastServices.newTopic();
      }

      if(obj.alias==='Contenu'){

        if($rootScope.isTopicDetail() || $rootScope.isTopicList()) {

          // Recupère le topic si l'user est actuellement sur la liste où il se trouve ou page détail du topic du nouveau contenu
          TopicStatesService.getByIdTopic(obj.idTopic).then(function(topicState){

            var msg;
            var contenuModel = topicState.getTopic().initContenu(obj);

            // Si l'user est sur la page détail lors de la reception d'un nouveau contenu
            if ($rootScope.isTopicDetail()) {

              // Si l'user et sur la page topic du qui correspond au nouveau contenu recu
              if (obj.idTopic === parseInt($stateParams.topicId)) {

                // Si nouveau contenu de type message
                if(contenuModel.isMessage) {


                  // Si date modification undefined ou null alors c'est un nouveau contenu
                  //if(angular.isUndefined(contenuModel.dateModification) || contenuModel.dateModification === null) {
                    // Incremente le nombre de contenu
                  //  topicState.getTopic().setNbContenu(topicState.getTopic().getNbContenu() + 1);
                  //}

                  // Supprime les balise html du message pour créer la previsualisaton
                  msg = $filter('stripTags')(contenuModel.message);
                  contenuModel.previsualisationMsg = $filter('truncate')(msg, 20, '...');

                  $rootScope.arrayNewContenusTmp.push(contenuModel);
                }
                // Si nouveau contenu de type événement
                else if(contenuModel.isEvenement){

                }

                // Notification du nouveaux contenu
                NotificationsToastServices.newContenu(contenuModel, topicState);
              }

              // Si page liste Topic où se trouve le topic du nouveau contenu
            }
            else if ($rootScope.isTopicList()) {

              var updateLastContenu = false;
              var isLu = topicState.getTopic().getLu();
              // Si le topic est lu
              if (isLu) {
                updateLastContenu = true;
              }else{
                var lastContenu = topicState.getTopic().getContenus()[0];
                // Si l'idContenu du dernier contenu est le même que le contenu qui vient d'être reçu
                // et que la date de modification est différente de undefined et null
                if((lastContenu.getIdContenu() === contenuModel.idContenu) &&
                  (!angular.isUndefined(contenuModel.dateModification) && contenuModel.dateModification !== null)) {
                  updateLastContenu = true;
                }
              }

              if(updateLastContenu){
                // Passe en non lu
                topicState.getTopic().setLu(false);

                // Supprime les balise html du message pour créer la previsualisaton
                msg = $filter('stripTags')(contenuModel.message);
                contenuModel.message = $filter('truncate')(msg, 30, '...');

                //Remplace le dernier contenu
                topicState.getTopic().setContenus([contenuModel]);
              }

              // Recupère la liste des destinataires du contenu
              var listeDestinatairesContenu = contenuModel.getDestinataires();
              // Recupère la liste des destinataires du topic
              var listeDestinatairesTopic = topicState.getTopic().getDestinataires();
              // Chaque destinataire du contenu
              angular.forEach(listeDestinatairesContenu, function (destinataire) {
                // Recupère un array vide si le destinataire n'existe pas dans les destinataires du topic
                var arrayDest = $filter('where')(listeDestinatairesTopic, {idGroupe: destinataire.id});
                // Donc le destinataire n'est pas dans la liste du topic
                if (!arrayDest.length) {
                  var tmpDestinataire = {
                    'dieze': destinataire.dieze,
                    'idGroupe': destinataire.id,
                    'libelle': destinataire.libelle,
                    'listeFiltres': []
                  };
                  // Ajoute le destinataire au topic
                  listeDestinatairesTopic.push(tmpDestinataire);
                }
              });
              // Set la nouvelle liste de destinataire
              topicState.getTopic().setDestinataires(listeDestinatairesTopic);

              if(contenuModel.isEvenement){
                // Modifi l'urgent du topic par la valeur de l'urgent du contenu
                topicState.getTopic().setUrgent(contenuModel.getUrgent());

                if(contenuModel.getDateCloture()){
                  topicState.getTopic().setEventCloturer(true);
                }

                //$scope.itemContenuEvenement = contenu;
                $rootScope.$broadcast('updateCurrentItemContenu', contenuModel, true);
              }

              // Si date modification undefined ou null alors c'est un nouveau contenu
              if(angular.isUndefined(contenuModel.dateModification) || contenuModel.dateModification === null) {

                // Recupère la liste des documents du contenu
                var listeDocuments = contenuModel.getListeDocument();
                // Si il y a des documents
                if (listeDocuments.length) {
                  // Recupère le nombre de documents du topic
                  var nbDocument = topicState.getTopic().getNbDocument();
                  // Ajoute le nombre de doc du contenu au nombre de doc du topic et set le nouveau nombre dans le topic
                  topicState.getTopic().setNbDocument(nbDocument + listeDocuments.length);
                }

                // Incremente le nombre de contenu
                topicState.getTopic().setNbContenu(topicState.getTopic().getNbContenu() + 1);

                // Incremente le nombre de contenu non lu
                topicState.getTopic().setNbContenuNonLu(topicState.getTopic().getNbContenuNonLu() + 1);
              }
            }

          },function(){
            // Notification du nouveaux contenu
            NotificationsToastServices.newContenu(obj);
          });
        }else{
          // Notification du nouveaux contenu
          NotificationsToastServices.newContenu(obj);
        }
      }*/
    }

    function startRetry(){
      if(!forceDisconnect && !scope.isConnected && !scope.tryToConnect && !intervalReconnectWS && !intervalConnect){
        intervalReconnectWS = $interval(function(){
          scope.connectWebsocket(urlWS, urlToken);
          $log.log('Retry Connect WS');
        },(COLLAB_CONF.NB_SECONDES_CHECK_WS * 1000));
      }
    }

    /**
     * Ferme la socket
     * @param force
     */
    function disconnect(force){
      forceDisconnect = true;
      scope.isConnected = false;
      scope.tryToConnect = false;
      if(intervalConnect) $interval.cancel(intervalConnect);
      if(dataStream) dataStream.close(force);
    }

    function cancelInterval(){
      if(intervalReconnectWS) $interval.cancel(intervalReconnectWS);
      if(intervalConnect) $interval.cancel(intervalConnect);
    }

    function initDevis(objet){
      var ContenuDevis = $injector.get('ContenuEvenementDevis');
      if(angular.isArray(objet) && objet.length) {
        var listDevis = [];
        for(var i = 0; i < objet.length; i++){
          listDevis.push(new ContenuDevis(objet[i]));
        }
        if($rootScope.isTopicDetail() || UtilsService.isExtranetFournisseur()) $rootScope.$broadcast('WebsocketUpdateListDevis', listDevis);
      }
      else if(angular.isObject(objet)) {
        var devis = new ContenuDevis(objet);
        if($rootScope.isTopicDetail() || UtilsService.isExtranetFournisseur()) $rootScope.$broadcast('WebsocketUpdateDevis', devis);
      }
    }

    /**
     * Ferme la socket lorsque avant de quitter la page
     */
    //$window.onbeforeunload = function() {
    //console.log('OnBeforeUnload');
    //scope.disconnect(true);
    //};
  }
})();
