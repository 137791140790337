'use strict';

/**
 * @ngdoc service
 * @name collaboreApp.Tags
 * @description
 * # Tags
 * Service in the collaboreApp.
 */

angular.module('collaboreApp').service('TagService', ['COLLAB_CONF','ErreurCollabService','$rootScope','$q','$timeout', 'Tag', 'TagAjax','TopicResource','ContenuAjax','UtilsService', function(COLLAB_CONF,ErreurCollabService,$rootScope,$q,$timeout, Tag, TagAjax,TopicResource,ContenuAjax,UtilsService) {

  var nbMaxTagsSuggeres = COLLAB_CONF.NB_MAX_TAG_SUGGERES_FOR_DOCUMENT;
  var promiseTimeout, promiseTag;
  var scope = this;

  scope.newTag = newTag;                                // Créé une instance de Tag

  // Recupération de tags
  scope.get = get;                                      // Recupère les tags
  scope.getWithType = getWithType;                      // Recupère les tags type
  scope.getTagsTypeMoreUsed = getTagsTypeMoreUsed;      // Recupère les tags type le splus utilisés
  scope.getPlusUtilise = getPlusUtilise;                // Recupère les tags les plus utilisé
  scope.getTagsForType = getTagsForType;                // Récupère dans "tags" les tags typé avec le string demandé
  scope.hasTagType = hasTagType;                        // Permet de savoir si dans "tags" il y a un tab type demandé
  scope.hasTagTypeProprietaire = hasTagTypeProprietaire; // Permet de savoir si dans "tags" il y a le tag type propriétaire
  scope.hasTagTypeInProxy = hasTagTypeInProxy;          // Permet de savoir si dans "tags" il y a un tab type demandé pour un objet ProxyTag

  scope.getTagForType = getTagForType;                  // Récupère dans "tags" le tag typé avec le string demandé
  scope.getTagTypeImmeuble = getTagTypeImmeuble;        // Récupère dans "tags" le tag typé immeuble
  scope.getTagForListType = getTagForListType;          // Récupère dans "tags" le tag typé pour chaque type à partir de la liste demandé

  scope.searchByTag = searchByTag;                      // Permet de chercher des tags pour des suggestions

  // Ajout de tags
  scope.addTag = addTag;                                // Permet d'ajouter un tag à un contenu

  // Suppresion de tags
  scope.deleteAllTags = deleteAllTags;                  // Permet de supprimer plusieur tags d'un contenu
  scope.deleteLastTags = deleteLastTags;                // Permet de supprimer le dernier tag d'un contenu
  scope.deleteTagFromTags = deleteTagFromTags;          // Permet de supprimer un tag d'une liste de tags
  scope.deleteTagFromContenu = deleteTagFromContenu;    // Permet de delete un tag d'un contenu
  scope.deleteTagFromTopic = deleteTagFromTopic;        // Permet de delete un tag d'un topic

  scope.tagExistIn = tagExistIn;                        // Permet de checker si un tag exist

  // Génération de tags
  scope.genereTag = genereTag;                          // Permet de générer un tag
  scope.genereTagTypeOrigineCollab = genereTagTypeOrigineCollab;    // Permet de générer un tag de type origine collab
  scope.genereTagTypeOrigineICS = genereTagTypeOrigineICS;    // Permet de générer un tag de type origine ICS
  scope.genereTagTypeOrigineCompta = genereTagTypeOrigineCompta;    // Permet de générer un tag de type origine ICS
  scope.getTagProjetMandat = getTagProjetMandat;
  scope.getTagProjetImmeuble = getTagProjetImmeuble;
  scope.getTagProjetLot = getTagProjetLot;
  scope.genereTags = genereTags;                        // Permet de générer tout les tags type etc...
  scope.createTags = createTags;                        // Permet de générer tout les tags type etc...
  scope.genereTagsGenerate = genereTagsGenerate;        // Permet de générer un tableau d'objet de tag généré qui va être utilisé lors de la suppression de l'origine
  scope.getIconForTagType = getIconForTagType;          // Permet de récupérer l'icon du tag type
  scope.getClassBadge = getClassBadge;          // Permet de récupérer l'icon du tag type

  scope.genereTagsForType = genereTagsForType;    // Permet de générer tous ce qu'il faut pour ajouter un origine à un événement

  /**
   * Créé une instance de Tag
   * @param data
   * @returns {Tag|*}
   */
  function newTag(data,libelleTypeTag,valeurIcs,portefeuille,proxy){

    var objData = {};

    // Si data est in string alors c'est un libelle normal et un tag non typé
    if(angular.isString(data)){
      data = UtilsService.ltrim(angular.copy(data),'#');
      objData = {
        'libelle': '#'+data
      };

      if(valeurIcs) {
        objData.valeurIcs = angular.copy(valeurIcs);
      }

      if(portefeuille){
        objData.portefeuille = angular.copy(portefeuille);
      }

      if(libelleTypeTag){
        objData.typeTag = {'libelle':angular.copy(libelleTypeTag)};
      }

    }else{
      objData = angular.copy(data);
    }

    return new Tag(objData,proxy);
  }

  /**
   * Recupère les tags
   * @param {String} String pour recherche de tag
   * @returns {Array} Promesse avec array des tags
   */
  function get(string,nbTags,libelletype) {

    var deferred = $q.defer();

    if(!nbTags){
      nbTags = COLLAB_CONF.NB_MAX_TAG_SUGGERES_FOR_CONTENU;
    }


    string = UtilsService.ltrim(string,'#');

    var tagAjax = new TagAjax();
    tagAjax.tag = '#'+string;
    tagAjax.nb = nbTags;

    if(libelletype){
      tagAjax.type = 1;
      tagAjax.libelleType = libelletype;
    }

    tagAjax.$get(function(json){

      var tags = [];

      // Si topic OK
      if(json.nb!=='0') {
        for(var i = 0 ; i < json.Tags.length ; i++){
          tags.push(new Tag(json.Tags[i]));
        }
        //tags = json.Tags;
      }

      deferred.resolve(tags);

    },function(error){

      var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
      deferred.reject('Problème avec Tags.get(), '+msgErreur);
      $rootScope.$broadcast('dislayError',msgErreur);

    });

    return deferred.promise;
  }

  /**
   * Recupère les tags type
   * @param {String} String pour recherche de tag
   * @returns {Array} Promesse avec array des tags
   */
  function getWithType(libelletype,string,nbTags, withoutTag) {

    var deferred = $q.defer();

    if(!nbTags){
      nbTags = COLLAB_CONF.NB_MAX_TAG_SUGGERES_FOR_CONTENU;
    }

    string = UtilsService.ltrim(string,'#');

    var tagAjax = new TagAjax();

    if(withoutTag) {tagAjax.tag = string;}
    else {tagAjax.tag = '#'+string;}

    tagAjax.nb = nbTags;
    tagAjax.type = 1;
    tagAjax.libelleType = libelletype;
    tagAjax.$get(function(json){

      var tags = [];

      // Si topic OK
      if(json.nb!=='0') {
        for(var i = 0 ; i < json.Tags.length ; i++){
          tags.push(new Tag(json.Tags[i]));
        }
        //tags = json.Tags;
      }

      deferred.resolve(tags);

    },function(error){

      var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
      deferred.reject('Problème avec Tags.getWithType(), '+msgErreur);
      $rootScope.$broadcast('dislayError',msgErreur);

    });

    return deferred.promise;
  }

  function getTagsTypeMoreUsed(arrayLibelleTags,nbTags) {

    var deferred = $q.defer();

    if(!nbTags){
      nbTags = COLLAB_CONF.NB_MAX_TAG_TYPE_MORE_USED;
    }

    var params = {
      libelleType: arrayLibelleTags,
      type: 2,
      nb: nbTags
    };

    TagAjax.get(params, function(json){
      var tags = [];
      // Si topic OK
      if(json.nb!=='0') {
        for(var i = 0 ; i < json.TagUseds.length ; i++){
          tags.push({
            nbUsed: json.TagUseds[i].nbUsed,
            tag: new Tag(json.TagUseds[i].tag)
          });
        }
      }
      deferred.resolve(tags);

    },function(error){

      var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
      deferred.reject('Problème avec Tags.getTagsTypeMoreUsed(), '+msgErreur);
      $rootScope.$broadcast('dislayError',msgErreur);

    });

    return deferred.promise;
  }

  /**
   * Recupère les tags les plus utilisé
   * @param {void}
   * @returns {Array} Promesse avec array des tags
   */
  function getPlusUtilise() {

    var deferred = $q.defer();

    var tagAjax = new TagAjax();
    tagAjax.type = 1;
    tagAjax.$get(function(json){

      var tags = [];

      // Si topic OK
      if(json.nb!=='0') {tags = json.Tags;}

      deferred.resolve(tags);

    },function(error){

      var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
      deferred.reject('Problème avec Tags.getPlusUtilise(), '+msgErreur);
      $rootScope.$broadcast('dislayError',msgErreur);

    });

    return deferred.promise;
  }

  /**
   * Permet juste de savoir si un tag d'un certain type existe dans "tags"
   * @param tags
   * @param type
   * @returns {boolean}
     */
  function hasTagType(tags,type){
    var retour = false;
    var tagType = tags;

    // Si tags est un array
    if(angular.isArray(tagType)){
      // Si cet array est différent de 0
      if(tagType.length){

        for(var i = 0 ; i < tagType.length ; i++){
          if(tagType[i].hasOwnProperty('typeTag')) {
            var TypeTag = tagType[i].typeTag;
            if (angular.isObject(TypeTag)) {
              var lib = TypeTag.libelle;
              if(type===COLLAB_CONF.TAG_TYPE_ORIGINE){
                if(UtilsService.contains(COLLAB_CONF.ARRAY_TAGS_ORIGINE,lib)){
                  retour = true;
                  break;
                }
              }else{
                if (lib === type) {
                  retour = true;
                  break;
                }
              }

            }
          }
        }
      }
    }
    return retour;
  }

  /**
   * Permet de savoir si dans la liste des tags il y a un tag propriétaire
   * @param tags
   * @returns {boolean|*}
   */
  function hasTagTypeProprietaire(tags){
    return this.hasTagType(tags, COLLAB_CONF.TAG_TYPE_PROPRIETAIRE);
  }

  /**
   * Permet juste de savoir si un tag d'un certain type existe dans "tags" pour un objet de type ProxyTag
   * @param tags
   * @param type
   * @returns {boolean}
   */
  function hasTagTypeInProxy(tags,type){
    var retour = false;
    var tagType = tags;

    // Si tags est un array
    if(angular.isArray(tagType)){
      // Si cet array est différent de 0
      if(tagType.length){

        for(var i = 0 ; i < tagType.length ; i++){

            var TypeTag = tagType[i];
            if (angular.isObject(TypeTag)) {
              var lib = TypeTag.libelleTypeTag;
              if(type===COLLAB_CONF.TAG_TYPE_ORIGINE){
                if(UtilsService.contains(COLLAB_CONF.ARRAY_TAGS_ORIGINE,lib)){
                  retour = true;
                  break;
                }
              }else{
                if (lib === type) {
                  retour = true;
                  break;
                }
              }

            }

        }
      }
    }
    return retour;
  }

  /**
   * Recupère un tag avec un certain type
   * @param tags
   * @param type
   * @returns {boolean}
     */
  function getTagForType(tags,type){
    var retour = false;
    var tagType = tags;

    // Si tags est un array
    if(angular.isArray(tagType)){

      // Si cet array est différent de 0
      if(tagType.length){
        for(var i = 0 ; i < tagType.length ; i++){
          if(tagType[i].hasOwnProperty('typeTag')) {
            var TypeTag = tagType[i].typeTag;
            if (angular.isObject(TypeTag)) {
              var lib = TypeTag.libelle;
              if(type===COLLAB_CONF.TAG_TYPE_ORIGINE){
                if(UtilsService.contains(COLLAB_CONF.ARRAY_TAGS_ORIGINE,lib)){
                  retour = tagType[i];
                  break;
                }
              }else{
                if (lib === type) {
                  retour = tagType[i];
                  break;
                }
              }

            }
          }
        }
      }
      return retour;
    }
  }

  /**
   * Recupère le tag de type immeuble dans la liste des tags
   * @param tags
   * @returns {boolean|*}
   */
  function getTagTypeImmeuble(tags){
    return this.getTagForType(tags, COLLAB_CONF.TAG_TYPE_IMMEUBLE);
  }

  /**
   * Recupère un tag à partir d'une liste de type
   * @param tags
   * @param type
   * @returns {Object}
   */
  function getTagForListType(tags,listType){
    var retour = false;
    var tagType = tags;

    // Si tags est un array et la liste de type aussi
    if(angular.isArray(tagType) && angular.isArray(listType)){

      // Si cet array est différent de 0
      if(tagType.length){
        for(var i = 0 ; i < tagType.length ; i++){
          if(tagType[i].hasOwnProperty('typeTag')) {
            var TypeTag = tagType[i].typeTag;
            if (angular.isObject(TypeTag)) {
              var lib = TypeTag.libelle;

              for(var t = 0 ; t < listType.length ; t++){
                var type = listType[t];
                if(type===COLLAB_CONF.TAG_TYPE_ORIGINE){
                  if(UtilsService.contains(COLLAB_CONF.ARRAY_TAGS_ORIGINE,lib)){
                    if(!angular.isObject(retour)){
                      retour = {};
                    }
                    retour[type] = tagType[i];
                    break;
                  }
                }else{
                  if (lib === type) {
                    if(!angular.isObject(retour)){
                      retour = {};
                    }
                    retour[type] = tagType[i];
                    break;
                  }
                }
              }

            }
          }
        }
      }
      return retour;
    }
  }

  /**
   * Récupère dans "tags" les tags typé avec le string demandé
   * @param type
   * @returns {Array}
   */
  function getTagsForType(tags,type){
    var retour = [];
    var tagType = tags;

    // Si tags est un array
    if(angular.isArray(tagType)){
      // Si cet array est différent de 0
      if(tagType.length){

        // Pour chaque tagsType
        angular.forEach(tagType,function(tag){

          if(tag.hasOwnProperty('typeTag')) {
            var TypeTag = tag.typeTag;

            if (angular.isObject(TypeTag)) {
              var lib = TypeTag.libelle;

              if(type===COLLAB_CONF.TAG_TYPE_ORIGINE){
                if(UtilsService.contains(COLLAB_CONF.ARRAY_TAGS_ORIGINE,lib)){
                  retour.push(tag);
                }
              }else{
                if (lib === type) {

                  retour.push(tag);
                }
              }
            }
          }
        });
      }
    }
    return retour;
  }

  /**
   * Permet de chercher des tags pour des suggestions
   * @param newValue
   * @param tagFilters
   * @param nbTagSug
   * @returns {IPromise<T>}
   */
  function searchByTag(newValue,tagFilters,nbTagSug){

    var deferred = $q.defer();

    if(!nbTagSug){
      nbTagSug = nbMaxTagsSuggeres;
    }

    $timeout(function(){
      deferred.notify({'loading':true});
    });

    if(promiseTimeout) {$timeout.cancel(promiseTimeout);}
    UtilsService.cancelPromise(promiseTag);

    // Si newValue est defini
    if(angular.isDefined(newValue) && newValue!==''){
      // Si il y a 2 caractères ou plus
      if(newValue.length>=COLLAB_CONF.NB_CARACTERES_AVANT_APPEL_RECHERCHE_TAG_SERVLET){

        promiseTimeout = $timeout(function(){

          // Appel des tags avec le string en like
          promiseTag = scope.get(newValue,nbTagSug).then(function(tags){

            var n = 0;
            var tagsSuggere = [];
            // Pour chaque tags retourné par la servlet
            angular.forEach(tags,function(tag){
              // Si on a pas atteind le nombre max de tags suggérés
              if(n<nbTagSug){

                var tagsWhere = [];
                if(angular.isArray(tagFilters)){
                  // Si il y a des tags déjà choisis
                  if(tagFilters.length){
                    tagsWhere = UtilsService.where(tagFilters,{libelle:tag.libelle});
                  }
                }


                // Si le tag suggere n'est pas dans le tableau des tags
                if(!tagsWhere.length){
                  var tagInstance = new Tag(tag);
                  //if(!tabTmp.length){
                  tagsSuggere.push(tagInstance);
                  n++;
                }
              }
            });

            //tagSuggestions = tagsSuggere;

            deferred.resolve(tagsSuggere);

          },function(msg){
            deferred.reject(msg);
          });
        }, 800, false);
      }else{
        deferred.resolve(true);
      }
    }else{
      deferred.resolve(false);
    }

    return deferred.promise;
  }



  /**
   * Permet de générer un tag
   * @param obj
   * @param libelleTypeTag
   * @param valeurIcs
   * @returns {boolean}
   */
  function genereTag(obj,libelleTypeTag,valeurIcs,portefeuille){
    var tag = false;
    //console.log('obj',obj);

    /*
    if(!libelleTypeTag){
      console.error('[Erreur] TagService.genereTag : Il manque le deuxième parametre qui doit être le string du type de tag');
      return;
    }*/

    // Si objet
    if(_.isObject(obj)){

      // Si c'est bien une instance
      if(obj.isModel) {
        // Si c'est une instance de Tag
        if (obj.isTag) tag = obj;
      }
      else {
        if (obj.isPerso) obj = obj.perso;
        else if (obj.isCollab) obj = obj.groupe;
        else if (obj.isImmeuble) obj = obj.immeuble;
      }

      if(!tag && obj){
        // Génère le libelle du tag
        var libelle = Tag.genereLibelleTag(obj);
        if(libelle){
          tag = {
            'libelle': libelle
          };
        }
      }
    }
    // Si string
    else if(_.isString(obj)) tag = {libelle: UtilsService.parseTag(obj)};
    else{
      ErreurCollabService.alert('[Erreur] TagService.genereTag : Le 1er parametre doit être un objet ou un string : ' + obj);
      //console.error('[Erreur] TagService.genereTag : Le 1er parametre doit être un objet ou un string : ' + obj);
      return;
    }

    // Si tag est bien un objet
    if(_.isObject(tag)){

      // Si il y a bien un libelle de type de tag
      if(libelleTypeTag) tag.typeTag = {libelle: libelleTypeTag};
      if(valeurIcs) tag.valeurIcs = valeurIcs;
      if(portefeuille) tag.portefeuille = angular.copy(portefeuille);

      // Si tag n'est pas une instance de Tag
      if(!tag.isTag) tag = new Tag(tag);
    }
    return tag;
  }

  /**
   * Génère un objet avec des tags et tags type par rapport à la perso donnée
   * @param collaborateur
   * @param item
   * @param immeuble
   * @returns {{perso: *, tagsType: Array, tags: *[]}}
   */
  function genereTags(objParams){


    try{
      //console.log(objParams);

      if(!angular.isObject(objParams.collaborateur)){
        throw new Error('Le 1er paramètre n\'est pas un objet !');
      }


      var objPortefeuille = false,nomImmeuble;

      if(objParams.collaborateur.hasOwnProperty('portefeuille')){
        objPortefeuille = objParams.collaborateur.portefeuille;

        /*
        if(objParams.collaborateur.portefeuille.hasOwnProperty('idPortefeuille')){

          idPortefeuille = objParams.collaborateur.portefeuille.idPortefeuille;

          if(objParams.collaborateur.isPerso){
            valeurIcs.type = 'noperso';
            valeurIcs.valeur = objParams.collaborateur.perso.noperso;
          }
          else if(objParams.collaborateur.isCollab){
            valeurIcs.type = 'idgroupe';
            valeurIcs.valeur = objParams.collaborateur.groupe.idGroupe;
          }
        }*/
      }




      var nom = UtilsService.getNom(objParams.collaborateur,false),noperso = UtilsService.getNoPerso(objParams.collaborateur);

      var obj = {
        'tagsType': [],
        'tags': []/*,
        'searchByTags':[]*/
      };

      if(objParams.immeuble){
        obj.immeuble = objParams.immeuble;
      }

      if(objParams.bail){
        obj.bail = objParams.bail;
      }

      // Si mandat
      if(angular.isString(objParams.item)){
        // Tag Projet Mandat
        obj.tagsType.push(getTagProjetMandat(objParams.collaborateur,objParams.item,objPortefeuille));

        var tagMandat = genereTagTypeMandat(objParams.item,objParams.item,objPortefeuille);

        // Génére le tagType Mandat
        obj.tagsType.push(tagMandat);

        // Génére le tagType Proprietaire
        obj.tagsType.push(genereTagTypeProprietaire(nom,noperso,objPortefeuille));


        //obj.searchByTags.push(tagMandat);

      }
      // Si item est un objet
      else if (angular.isObject(objParams.item)){


        // Si personnalite
        if(objParams.item.isPersonnalite){
          if(objParams.item.isCoproprietaire()){
            // Génére le tagType coproprietaire
            obj.tagsType.push(genereTagTypeCoproprietaire(objParams.item.getNomper(),objParams.item.getNoperso(),objPortefeuille));
          }

        }
        // Si lot
        else if(objParams.item.isLot){

          // #L[NumImmeuble][NoLot]
          obj.tagsType.push(getTagProjetLot(objParams.item,objPortefeuille));

          // Génére le tagType Lot
          obj.tagsType.push(genereTagTypeLot(objParams.item.getNumLot(),objParams.item.getNumImmeuble()+objParams.item.getNumLot(),objPortefeuille));

          if(objParams.item.getType()==='Locataire'){

            /*
             obj.tags.push(
             // #[NumLot]-[NumLocataire]
             {
             'libelle': '#'+objParams.item.getNumLot()+'-'+noperso
             }
             );*/
            // Génére le tagType Mandat
            obj.tagsType.push(genereTagTypeMandat(objParams.item.getNumMandat(),objParams.item.getNumMandat(),objPortefeuille));

            // Génére le tagType Proprietaire
            obj.tagsType.push(genereTagTypeProprietaire(objParams.item.getProprietaire().getNomper(),objParams.item.getProprietaire().getNoperso(),objPortefeuille));

            // Génére le tagType Locataire
            obj.tagsType.push(genereTagTypeLocataire(nom,noperso,objPortefeuille));


            //obj.searchByTags.push(tagLocataire);

            /*
            obj.tags.push(
                // #[NomProprietaire]
                {
                  'libelle': '#'+objParams.item.getNomProprietaire()
                }
            );*/
          }
          else if(objParams.item.getType()==='Proprietaire'){

            // Génére le tagType Mandat
            obj.tagsType.push(genereTagTypeMandat(objParams.item.getNumMandat(),objParams.item.getNumMandat(),objPortefeuille));


            // Génére le tagType Proprietaire
            obj.tagsType.push(genereTagTypeProprietaire(nom,noperso,objPortefeuille));

            /*
            obj.tags.push(
                // #[NumLot]
                {
                  'libelle': '#'+objParams.item.getNumLot()
                }
            );*/

            //obj.searchByTags.push(tagProprietaire);

            if(angular.isObject(objParams.bail)){
              if(objParams.bail.isBail){
                if(objParams.bail.getPersonnalite()){
                  // Génére le tagType Locataire
                  obj.tagsType.push(genereTagTypeLocataire(objParams.bail.getPersonnalite().getNomper(),objParams.bail.getPersonnalite().getNoperso(),objPortefeuille));
                }
              }
            }
          }
        }
        // Si immeuble
        else if(objParams.item.isImmeuble){

          if(objParams.hasOwnProperty('inverse')){
            if(angular.isObject(objParams.inverse)){
              if(objParams.inverse.isCoproprietaire()){


                // Génére le tagType coproprietaire
                obj.tagsType.push(genereTagTypeCoproprietaire(nom,noperso,objPortefeuille));

                //obj.searchByTags.push(tagCopro);

                /*
                 // #[NoCoproprietaire (xxxx)]
                 obj.tags.push({
                 'libelle': '#'+objParams.item.inverse.getNumCoproprietaire()+'-'+noperso
                 });*/
              }
            }

          }

          // #I[NumImmeuble][NomImmeuble]
          obj.tagsType.push(getTagProjetImmeuble(objParams.item,objPortefeuille));


          nomImmeuble = objParams.item.getNom();
          if(angular.isDefined(nomImmeuble) && nomImmeuble !== ''){
            // Génére le tagType immeuble
            obj.tagsType.push(genereTagTypeImmeuble(nomImmeuble,objParams.item.getNumImmeuble(),objPortefeuille));
          }


          //obj.searchByTags.push(tagImmeuble);

          // #[NomImmeuble]
          /*
          obj.tags.push({
            'libelle': '#'+ UtilsService.parseTag(objParams.item.getNom()).slice(1)
          });*/
        }
        else if(objParams.item.isBail){

        }
      }
      else {
        if(objParams.collaborateur.hasOwnProperty('tagProjet')){
          obj.tagsType.push(objParams.collaborateur.tagProjet);
        }else{
          obj.tagsType.push(genereTag(objParams.collaborateur,'Projet'));
        }
      }

      if(objParams.immeuble){
        nomImmeuble = objParams.immeuble.getNom();
        if(angular.isDefined(nomImmeuble) && nomImmeuble !== ''){
          // Génére le tagType immeuble
          obj.tagsType.push(genereTagTypeImmeuble(objParams.immeuble.getNom(),objParams.immeuble.getNumImmeuble(),objPortefeuille));
        }
      }

      if(objParams.collaborateur.hasOwnProperty('portefeuille')){
        if(objParams.collaborateur.portefeuille.hasOwnProperty('idPortefeuille')){

          // Génére le tagType Portefeuille
          obj.tagsType.push(genereTagTypePortefeuille(objParams.collaborateur.portefeuille.nom,objParams.collaborateur.portefeuille.idPortefeuille,objPortefeuille));

        }
      }
      return obj;
    }
    catch(err){
      console.log('[TagService.genereTags] Erreur : '+err.message);
      return false;
    }

  }

  function createTags(objParams){

    try{
      //console.log(objParams);
      var obj = {
        tagsType: [],
        tags: []
      };

      var objPortefeuille = false;
      if(objParams.portefeuille) objPortefeuille = objParams.portefeuille;
      else if(objParams.origine.hasOwnProperty('portefeuille')) objPortefeuille = objParams.origine.portefeuille;

      // Génére le tagType Portefeuille
      if(objPortefeuille)           obj.tagsType.push(genereTagTypePortefeuille(objPortefeuille.nom,objPortefeuille.idPortefeuille,objPortefeuille));

      // Génére le tagType immeuble
      if(objParams.immeuble)        obj.tagsType.push(genereTagTypeImmeuble(objParams.immeuble.getNom(),objParams.immeuble.getNumImmeuble(),objPortefeuille));
      if(objParams.bail)            obj.bail = objParams.bail;
      if(objParams.mandat)          obj.tagsType.push(genereTagTypeMandat(objParams.mandat,objParams.mandat,objPortefeuille));
      if(objParams.coproprietaire)  obj.tagsType.push(genereTagTypeCoproprietaire(objParams.coproprietaire.getNomper(),objParams.coproprietaire.getNoperso(),objPortefeuille));
      if(objParams.proprietaire)    obj.tagsType.push(genereTagTypeProprietaire(objParams.proprietaire.getNomper(),objParams.proprietaire.getNoperso(),objPortefeuille));
      if(objParams.locataire)       obj.tagsType.push(genereTagTypeLocataire(objParams.locataire.getNomper(),objParams.locataire.getNoperso(),objPortefeuille));

      if(objParams.salarie){
        var profess = objParams.salarie.getProfess();
        if (profess !== '') obj.tags.push(genereTag(profess));
      }

      // [VIDE]
      if(objParams.fournisseur){
      }

      // Génére le tagType Lot
      if(objParams.lot) obj.tagsType.push(genereTagTypeLot(objParams.lot.getNumLot(),objParams.lot.getNumImmeuble()+objParams.lot.getNumLot(),objPortefeuille));

      if(objParams.projet){
        if(_.isObject(objParams.projet))  obj.tagsType.push(objParams.origine.tagProjet);
        else if(_.isString(objParams.projet)){
          // #L[NumImmeuble][NoLot]
          if(objParams.projet === 'lot')                  obj.tagsType.push(getTagProjetLot(objParams.lot,objPortefeuille));
          // #I[NumImmeuble][NomImmeuble]
          else if(objParams.projet === 'immeuble')        obj.tagsType.push(getTagProjetImmeuble(objParams.immeuble,objPortefeuille));
          // Tag Projet Mandat
          else if(objParams.projet === 'mandat')          obj.tagsType.push(getTagProjetMandat(objParams.origine,objParams.mandat,objPortefeuille));
          else if(objParams.projet === 'proprietaire')    obj.tagsType.push(genereTag(objParams.proprietaire,'Projet'));
          else if(objParams.projet === 'coproprietaire')  obj.tagsType.push(genereTag(objParams.coproprietaire,'Projet'));
          else if(objParams.projet === 'locataire')       obj.tagsType.push(genereTag(objParams.locataire,'Projet'));
          else if(objParams.projet === 'fournisseur')     obj.tagsType.push(genereTag(objParams.fournisseur,'Projet'));
          else if(objParams.projet === 'client')          obj.tagsType.push(genereTag(objParams.client,'Projet'));
        }
      }
      else{
        if(objParams.hasOwnProperty('origine')) {
          obj.tagsType.push(genereTag(objParams.origine,'Projet'));
        }
      }

      return obj;
    }
    catch(err){
      console.error('[TagService.createTags] Erreur : '+err.message);
      console.log(err);
      return {
        'tagsType': [],
        'tags': []
      };
    }

  }

  /**
   * Permet d'ajouter un tag à un contenu
   * @param idContenu
   * @param tag
   * @param typeTag
   * @param limit
   * @returns {IPromise<T>}
     */
  function addTag(idContenu,tag,tags,typeTag,limit){

    var deferred = $q.defer();
    var msgErreur = false;

    if(!tag.isModel){
      tag = new Tag(tag);
    }

    var canAdd = true;


    if(limit){
      if (typeTag) {
        if(this.getTagsForType(tags,typeTag).length>=limit){
          canAdd = false;
        }
      }else{
        if(tags.length>=limit){
          canAdd = false;
        }
      }
    }

    // Si la limit n'est pas atteinte
    if(canAdd){

      // Si il y a bien un idContenu
      if(idContenu){

        var params = {
          'contenu':idContenu,
          'tags':[tag]
        };

        if(typeTag){
          params.type = 1;
        }
        ContenuAjax.addTags(params,function(json){

          if(json.nb!=='0'){

            var instancesNewTag = [];

            // Parcour les nouveaux tags ajoutés pour en créé une instance de Tag
            for(var i = 0 ; i < json.Tags.length ; i ++){

              var instanceNewTag = new Tag(json.Tags[i]);

              instanceNewTag.cleanTag(typeTag);

              // Ajoute l'instance du tag au tableau des nouveau tag qui seront retournés
              instancesNewTag.push(instanceNewTag);
            }

            // Retour la listre des instances des nouveaus tags
            deferred.resolve(instancesNewTag);
          }else{
            msgErreur = 'Tag inexistant [TagService.addTag()]';
          }
        },function(){
          msgErreur = 'Problème Ajax [TagService.addTag()]';
        });
      }
    }else{
      msgErreur = 'Limit de tag atteinte ! [TagService.addTag()]';
    }

    if(msgErreur){
      deferred.reject(msgErreur);
    }

    return deferred.promise;
  }

  /**
   * Supprime un tag d'un contenu
   * @param idContenu
   * @param tag
   * @param typeTag
   * @returns {IPromise<T>}
   */
  function deleteTagFromContenu(idContenu,tag,typeTag){

    var msgErreur = false;
    var deferred = $q.defer();

    if(!tag.isModel){
      tag = new Tag(tag);
    }

    if(idContenu){

      var idTagDel = tag.getIdTag();
      var params = {
        'contenu': idContenu,
        'tag': idTagDel
      };

      if(typeTag){
        params.type = 1;
      }

      ContenuAjax.deleteTag(params,function(json){
        if(json.Resultats[0].success){
          deferred.resolve();
        }else{
          msgErreur = 'Tag inexistant [TagService.deleteTagFromContenu()]';
        }
      },function(){
        msgErreur = 'Problème Ajax [TagService.deleteTagFromContenu()]';
      });
    }else{
      msgErreur = 'Pas de Guid [TagService.deleteTagFromContenu()]';
    }

    if(msgErreur){
      deferred.reject(msgErreur);
    }
    return deferred.promise;
  }

  /**
   * Supprime un tag d'un topic
   * @param idTopic
   * @param tag
   * @param typeTag
   * @returns {IPromise<T>}
   */
  function deleteTagFromTopic(idTopic,tag,typeTag){

    var msgErreur = false;
    var deferred = $q.defer();

    if(!tag.isModel){
      tag = new Tag(tag);
    }

    if(idTopic){

      var idTagDel = tag.getIdTag();
      var params = {
        'topic': idTopic,
        'tag': idTagDel
      };

      if(typeTag){
        params.type = 1;
      }

      TopicResource.deleteTag(params,function(json){
        if(json.Resultats[0].success){
          deferred.resolve();
        }else{
          msgErreur = 'Tag inexistant [TagService.deleteTagFromTopic()]';
        }
      },function(){
        msgErreur = 'Problème Ajax [TagService.deleteTagFromTopic()]';
      });
    }else{
      msgErreur = 'Pas de Guid [TagService.deleteTagFromTopic()]';
    }

    if(msgErreur){
      deferred.reject(msgErreur);
    }
    return deferred.promise;
  }

  /**
   * Supprime une liste de tags
   * @param idContenu
   * @param tags
   * @param typeTag
   * @returns {IPromise<T>}
   */
  function deleteAllTags(idContenu,tags,typeTag){

    var scope = this;
    var globalDeferred = $q.defer();
    var promises = [];
    var deferred;

    // Si currentTags est bien un tableau
    if(angular.isArray(tags)){

      if(tags.length){
        // Parcour les tag pour les supprimer
        for(var i = 0 ; i < tags.length ; i ++){

          deferred = $q.defer();

          // Supprime tout les tag typé
          scope.deleteTagFromContenu(idContenu,tags[i],typeTag).then(function(){
            deferred.resolve();
          },function(){
            deferred.resolve();
          });
          promises.push(deferred.promise);
        }

      }else{
        deferred = $q.defer();
        deferred.resolve();
        promises.push(deferred.promise);
      }
    }else{
      deferred = $q.defer();
      deferred.resolve();
      promises.push(deferred.promise);
    }

    $q.all(promises).then(function(){
      globalDeferred.resolve([]);
    });

    return globalDeferred.promise;
  }

  /**
   * Permet de supprimer le dernier tag par rapport à la limite imposé
   * @param contenu
   * @param tags
   * @param isType
   * @param limit
   * @returns {IPromise<T>}
   */
  function deleteLastTags(idContenu,tags,typeTag,limit){

    var scope = this;
    var deferred = $q.defer();

    // Si currentTags est bien un tableau
    if(angular.isArray(tags)){

      // Nombre de tags actuellement
      var nbTag = tags.length;

      // si il y a une limit de tags
      if(limit){

        // Si le nombre de tags actuel est plus grand ou égale à la limit
        if(nbTag>=limit){

          // Recup le denrier tag du tableau
          var lastTag = tags[nbTag-1];

          // Supprime ce tag
          scope.deleteTagFromContenu(idContenu,lastTag,typeTag).then(function(){
            deferred.resolve();
          },function(){
            deferred.resolve();
          });

        }else {
          deferred.resolve();
        }
      }else {
        deferred.resolve();
      }

    }else {
      deferred.resolve();
    }

    return deferred.promise;
  }

  /**
   * Permet de supprimer un tag d'une liste de tags si il existe
   * @param tags
   * @param tag
   * @returns {Array}
     */
  function deleteTagFromTags(tags,tag){
    try{
      return this.tagExistIn(tags,tag,true);
    }
    catch(err){
      console.log('[TagService.deleteTagFromTags] Erreur : '+err.message);
      return [];
    }
  }

  /**
   * Permet de tester l'existence d'un tag dans une liste de tags
   * @param tags
   * @param tag
   * @param deleteTag (Permet de savoir si il faut delete le tag si trouvé, (utilisé avec "deleteTagFromTags" evite de dupliquer cette méthode))
 * @returns {*}
   */
  function tagExistIn(tags,tag,deleteTag){
    var retour = false;
    try{
      if(!angular.isArray(tags)){
        throw new Error('Le 1er paramètre n\'est pas un array !');
      }

      if(tags.length){
        for(var i = 0 ; i < tags.length ; i++){

          if(angular.isObject(tag)){
            // Test du libelle tu tag
            if(tags[i].libelle === tag.libelle){

              // Si ce sont des tag type
              if(tags[i].hasOwnProperty('typeTag') && tag.hasOwnProperty('typeTag')){

                // Si les libelle des typeTag sont les memes
                if(tags[i].typeTag.libelle === tag.typeTag.libelle){

                  // Si il on tous les deux un attribut "valeurIcs"
                  if(tags[i].typeTag.hasOwnProperty('valeurIcs') && tag.typeTag.hasOwnProperty('valeurIcs')){

                    // Si les "valeursIcs" des deux sont des objet
                    if(angular.isObject(tags[i].typeTag.valeurIcs) && angular.isObject(tag.typeTag.valeurIcs)){

                      // Si "valeurIcs" à un attribut "type" et "valeur"
                      if(tags[i].typeTag.valeurIcs.hasOwnProperty('type') && tag.typeTag.valeurIcs.hasOwnProperty('type') &&
                          tags[i].typeTag.valeurIcs.hasOwnProperty('valeur') && tag.typeTag.valeurIcs.hasOwnProperty('valeur')){

                        // Si les attribut "type" et "valeur" sont identiques aux attributs "type et "valeur" du tag à supprimer
                        if((tags[i].typeTag.valeurIcs.type === tag.typeTag.valeurIcs.type) && (tags[i].typeTag.valeurIcs.valeur === tag.typeTag.valeurIcs.valeur)){

                          // Si il on tous les deux un attribut "idPortefeuille"
                          if(tags[i].typeTag.valeurIcs.hasOwnProperty('idPortefeuille') && tag.typeTag.valeurIcs.hasOwnProperty('idPortefeuille')){

                            // Si cet attribut "idPortefeuille" sont de même valeur
                            if(tags[i].typeTag.valeurIcs.idPortefeuille === tag.typeTag.valeurIcs.idPortefeuille){

                              retour = true;
                            }
                            // Si pas d'idPortefeuille dans les deux et que "valeur" et "type" sont quand même identique on supprime
                          }
                          else if(!tags[i].typeTag.valeurIcs.hasOwnProperty('idPortefeuille') && !tag.typeTag.valeurIcs.hasOwnProperty('idPortefeuille')){
                            retour = true;
                          }
                        }
                        // Si les attributs "type" et "valeur" n'existe dans aucun des deux et que "valeur" et "type" sont quand même identique on supprime
                      }
                      else if(!tags[i].typeTag.valeurIcs.hasOwnProperty('type') && !tag.typeTag.valeurIcs.hasOwnProperty('type') &&
                          !tags[i].typeTag.valeurIcs.hasOwnProperty('valeur') && !tag.typeTag.valeurIcs.hasOwnProperty('valeur')){
                        retour = true;
                      }


                    }
                    else{
                      // Si les valeur sont identiques
                      if(tags[i].typeTag.valeurIcs === tag.typeTag.valeurIcs){
                        retour = true;
                      }
                    }
                    // Si aucun des deux n'a d'attributs "valeurIcs" on supprime
                  }
                  else if(!tags[i].typeTag.hasOwnProperty('valeurIcs') && !tag.typeTag.hasOwnProperty('valeurIcs')){
                    retour = true;
                  }
                }
                // Aucun des deux n'a de typeTag
              }
              else if(!tags[i].hasOwnProperty('typeTag') && !tag.hasOwnProperty('typeTag')){
                retour = true;
              }
            }
          }
          else if(angular.isString(tag)){
            // Test du libelle tu tag
            if(angular.isObject(tags[i]) && tags[i].hasOwnProperty('libelle') && tags[i].libelle === tag) {
              retour = true;
            }
            else if(angular.isString(tags[i]) && tags[i] === tag) {
              retour = true;
            }

          }
          else{
            throw new Error('Le 2ème paramètre doit être un objet ou un string !');
          }

          if(retour){
            if(deleteTag){
              tags.splice(i,1);
              retour = tags;
            }

            break;
          }
        }
      }

      if(!retour && deleteTag){
        retour = tags;
      }

      return retour;

    }
    catch(err){
      console.log('[TagService.tagExistIn] Erreur : '+err.message);
      return retour;
    }
  }


  /********* Méthodes de génération de tag type ************/

  function genereTagTypeImmeuble(libelle,valeurIcs,portefeuille){return newTag(UtilsService.parseTag(libelle),COLLAB_CONF.TAG_TYPE_IMMEUBLE,valeurIcs,portefeuille);}
  function genereTagTypeLot(libelle,valeurIcs,portefeuille){return newTag(UtilsService.parseTag(libelle),COLLAB_CONF.TAG_TYPE_LOT,valeurIcs,portefeuille);}
  function genereTagTypeProprietaire(libelle,valeurIcs,portefeuille){return newTag(UtilsService.parseTag(libelle),COLLAB_CONF.TAG_TYPE_PROPRIETAIRE,valeurIcs,portefeuille);}
  function genereTagTypeCoproprietaire(libelle,valeurIcs,portefeuille){return newTag(UtilsService.parseTag(libelle),COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE,valeurIcs,portefeuille);}
  function genereTagTypeLocataire(libelle,valeurIcs,portefeuille){return newTag(UtilsService.parseTag(libelle),COLLAB_CONF.TAG_TYPE_LOCATAIRE,valeurIcs,portefeuille);}
  function genereTagTypeMandat(libelle,valeurIcs,portefeuille){return newTag(UtilsService.parseTag(libelle),COLLAB_CONF.TAG_TYPE_MANDAT,valeurIcs,portefeuille);}
  function genereTagTypeOrigineCollab(libelle,valeurIcs,portefeuille){return newTag(UtilsService.parseTag(libelle),COLLAB_CONF.TAG_TYPE_ORIGINE_COLLAB,valeurIcs,portefeuille);}
  function genereTagTypeOrigineICS(libelle,valeurIcs,portefeuille){return newTag(UtilsService.parseTag(libelle),COLLAB_CONF.TAG_TYPE_ORIGINE_ICS,valeurIcs,portefeuille);}
  function genereTagTypeOrigineCompta(libelle,valeurIcs,portefeuille){return newTag(UtilsService.parseTag(libelle),COLLAB_CONF.TAG_TYPE_ORIGINE_COMPTA,valeurIcs,portefeuille);}
  function genereTagTypePortefeuille(libelle,valeurIcs,portefeuille){return newTag(UtilsService.parseTag(libelle),COLLAB_CONF.TAG_TYPE_PORTEFEUILLE,valeurIcs,portefeuille);}
  function genereTagTypeImportant(libelle,valeurIcs,portefeuille){return newTag(UtilsService.parseTag(libelle),COLLAB_CONF.TAG_TYPE_IMPORTANT,valeurIcs,portefeuille);}

  /**
   * Permet de génrer l'objet d'un tag Projet pour un mandat
   * @param collaborateur
   * @param numMandat
   * @returns {{libelle: string, typeTag: {libelle: string}}}
   */
  function getTagProjetMandat(collaborateur,numMandat,portefeuille){

    var nom = UtilsService.getNom(collaborateur,true);
    return newTag('#MAN-'+numMandat+'-'+nom,'Projet',false,portefeuille);
    /*
    return {
      'libelle': '#MAN-'+numMandat+'-'+nom,
      'typeTag':{
        'libelle': 'Projet'
      }
    };*/
  }

  /**
   * Permet de génrer l'objet d'un tag Projet pour un immeuble
   * @param item
   * @returns {{}}
   */
  function getTagProjetImmeuble(item,portefeuille){
    var tag = {};
    if(angular.isObject(item)) {
      if (item.isImmeuble) {
        tag = newTag('#IMM-' + item.getNumImmeuble() + '-' + UtilsService.parseTag(item.getNom()).slice(1),'Projet',false,portefeuille);
        /*
        tag = {
          'libelle': '#IMM-' + item.getNumImmeuble() + '-' + UtilsService.parseTag(item.getNom()).slice(1),
          'typeTag': {
            'libelle': 'Projet'
          }
        };*/
      }
    }
    return tag;
  }

  /**
   * Permet de génrer l'objet d'un tag Projet pour un lot
   * @param item
   * @returns {{}}
   */
  function getTagProjetLot(item,portefeuille){

    var tag = {};
    if(angular.isObject(item)){
      // Si lot
      if(item.isLot) {
        tag = newTag('#LOT-' + item.getNumImmeuble() + '-' + item.getNumLot(),'Projet',false,portefeuille);
        /*
        tag = {
          'libelle': '#LOT-' + item.getNumImmeuble() + '-' + item.getNumLot(),
          'typeTag': {
            'libelle': 'Projet'
          }
        };*/
      }
    }

    return tag;
  }

  /**
   * Permet de générer un tableau d'objet de tag généré qui va être utilisé lors de la suppression de l'origine
   * @returns {*}
   * @param obj
   */
  function genereTagsGenerate(obj){

    var tagsGenerate = [];

    if(obj.hasOwnProperty('tag')){
      if(angular.isObject(obj.tag)){
        tagsGenerate.push(obj.tag);
      }
    }

    // Si il y a des tagsType ou tags on les ajoute dans un tableau de tags généré qui est dans la perso ou le groupe
    if(obj.hasOwnProperty('tagsType')){
      if(obj.tagsType.length){
        for(var k = 0 ; k < obj.tagsType.length ; k++){
          tagsGenerate.push(obj.tagsType[k]);
        }
      }
    }

    if(obj.hasOwnProperty('tags')){
      if(obj.tags.length){
        for(var l = 0 ; l < obj.tags.length ; l++){
          tagsGenerate.push(obj.tags[l]);
        }
      }
    }


    return tagsGenerate;
  }

  function getIconForTagType(typeTag,limit) {
    var classIcon = false;
    switch (typeTag) {
      case COLLAB_CONF.TAG_TYPE_PROJET:
        classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_PROJET];
        break;
      case COLLAB_CONF.TAG_TYPE_LOT:
        classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_LOT];
        break;
      case COLLAB_CONF.TAG_TYPE_PROPRIETAIRE:
        classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_PROPRIETAIRE];
        break;
      case COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE:
        classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE];
        break;
      case COLLAB_CONF.TAG_TYPE_LOCATAIRE:
        classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_LOCATAIRE];
        break;
      case COLLAB_CONF.TAG_TYPE_ORIGINE:
        classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_ORIGINE];
        break;
      case COLLAB_CONF.TAG_TYPE_IMMEUBLE:
        classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_IMMEUBLE];
        break;
      case COLLAB_CONF.TAG_TYPE_MANDAT:
        classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_MANDAT];
        break;
      case COLLAB_CONF.TAG_TYPE_PORTEFEUILLE:
        classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_PORTEFEUILLE];
        break;
      case COLLAB_CONF.TAG_NORMAL:
        classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_NORMAL];
        break;
      case COLLAB_CONF.TAG_TYPE_IMPORTANT:
        classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_IMPORTANT];
        break;
      default:
        if(UtilsService.contains(COLLAB_CONF.ARRAY_TAGS_ORIGINE, typeTag)){
          classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_ORIGINE];
        }
        else if(COLLAB_CONF.ICON_TAG.hasOwnProperty(typeTag)){
          classIcon = COLLAB_CONF.ICON_TAG[typeTag];
        }
        else {
          classIcon = (limit && limit > 1) ? COLLAB_CONF.ICON_TAG.Normaux : (!limit) ? COLLAB_CONF.ICON_TAG.Normaux : COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_NORMAL];
        }

    }

    return classIcon;
  }

  function getClassBadge(tag, typeTag, classForced, xs){

    var type = typeTag;
    if(!typeTag || _.isNil(typeTag)){
      if(tag.hasOwnProperty('typeTag')) type = tag.typeTag.libelle;
      else type = COLLAB_CONF.TAG_NORMAL;
    }

    var badgeBorderedClass = 'badge-bordered';
    var badgeClass = '';
    switch(type){
      case COLLAB_CONF.TAG_TYPE_PROJET:
        badgeClass = 'badge-rouge-collabore ';
        break;
      //case COLLAB_CONF.TAG_TYPE_ORIGINE:
      //	badgeClass = 'alert-noir-collabore ';
      //	break;
      case COLLAB_CONF.TAG_NORMAL:
        badgeClass = 'badge-bleu-collabore ';
        break;
      case COLLAB_CONF.TAG_TYPE_STATUT:
        if(tag.isTagStatutTermine()) badgeClass = 'badge-success ';
        else if(tag.isTagStatutEnAttente()) badgeClass = 'badge-warning ';
        else if(tag.isTagStatutProbleme()) badgeClass = 'badge-danger ';
        else badgeClass = 'badge-default-collabore ';
        badgeBorderedClass = '';
        break;
      /*
            case 'Immeuble':
                badgeClass = 'alert-orange-collabore ';
                break;*/
      default:
        if(UtilsService.contains(COLLAB_CONF.ARRAY_TAGS_ORIGINE, type)){
          badgeClass = 'badge-noir-collabore ';
        }
        else {
          badgeClass = 'badge-default-collabore ';
        }
    }

    if(xs){badgeClass += 'badge-tag-xs ';}
    if(classForced) badgeClass = classForced;

    return badgeClass+badgeBorderedClass;
  }

  function genereTagsForType(objRetour, typeTag){

    var tag, retourUpdate = { tagCtrl: {tags : []}};

    if(!typeTag) typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE;

    if(objRetour.hasOwnProperty('collaborateurs')) {
      if(!objRetour.collaborateurs.length){
        throw new Error('Le retour de "objRetour.collaborateurs" est vide, il doit y avoir seulement 1 collaborateur pour "Origine" !');
      }
      else{
        objRetour.origine  = objRetour.collaborateurs[0];
        delete objRetour.collaborateurs;
      }
    }

    // Si ajout d'un tag origine
    if(typeTag === COLLAB_CONF.TAG_TYPE_ORIGINE){
      try{

        var objetsTags = createTags(objRetour);

        //console.log('objetsTags',objetsTags);
        //console.log('objRetour',objRetour);

        var retour = {
          tag: {},
          groupes: [],
          fiches: (angular.isObject(objRetour.fiches)) ? objRetour.fiches : {},
          tagsType: objetsTags.tagsType,
          tags: objetsTags.tags
        };

        var objPortefeuille = false;
        if(objRetour.hasOwnProperty('portefeuille')) objPortefeuille = objRetour.portefeuille;

        var valeurIcs = false;

        if(objRetour.type === 'ICS'){

          typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_ICS;
          if(objPortefeuille){
            // Si le portefeuille est celui d'ICS on change le type de tag pour un tag origin compta
            if(objPortefeuille.nom === COLLAB_CONF.NOM_PORTEFEUILLE_ICS){
              typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_COMPTA;
            }
          }

          if(objRetour.hasOwnProperty('origine')) {
            valeurIcs = objRetour.origine.noperso;

            // Si Copropriétaire
            if(objRetour.coproprietaire){
              if(objRetour.origine.isCopro){
                retour.fiches.coproprietaire = objRetour.origine;
              }
            }
            // Si Propriétaire
            else if(objRetour.proprietaire){
              if(objRetour.origine.isProprietaire){
                retour.fiches.proprietaire = objRetour.origine;
              }
            }
            // Si Fournisseur
            else if(objRetour.fournisseur){
              if(objRetour.origine.isFournisseur){
                retour.fiches.fournisseur = objRetour.origine;
              }
            }

            if(objRetour.origine.isColocataire){
              retour.fiches.colocataire = objRetour.origine;
            }
            else if(objRetour.origine.isLocataire){
              retour.fiches.locataire = objRetour.origine;
            }

            /*
            // Si un lot est choisi, c'est le propriétaire qui doit etre pris en compte
            if(angular.isDefined(objRetour.bail) && objRetour.bail) {
              if(objRetour.origine.isProprietaire){
                retour.fiches.proprietaire = objRetour.origine;
              }
              else if(objRetour.origine.isCopro){
                retour.fiches.coproprietaire = objRetour.origine;
              }
              else if(objRetour.origine.isColocataire){
                retour.fiches.colocataire = objRetour.origine;
              }
              else if(objRetour.origine.isLocataire){
                retour.fiches.locataire = objRetour.origine;
              }

            }
            // Sinon c'est le copro
            else {
              if(objRetour.origine.isCopro){
                retour.fiches.coproprietaire = objRetour.origine;
              }
              else if(objRetour.origine.isProprietaire){
                retour.fiches.proprietaire = objRetour.origine;
              }
              else if(objRetour.origine.isColocataire){
                retour.fiches.colocataire = objRetour.origine;
              }
              else if(objRetour.origine.isLocataire){
                retour.fiches.locataire = objRetour.origine;
              }

            }*/

            if(UtilsService.isICS()){
              if(objRetour.origine.isCollaborateur){
                retour.fiches.client = objRetour.origine;
              }
            }
          }
        }
        else if(objRetour.type === 'Collab'){
          valeurIcs = objRetour.origine.idGroupe;
          typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_COLLAB;
          retour.fiches.groupe = objRetour.origine.groupe;
        }
        else if(objRetour.type === 'Immeuble'){
          if(objRetour.origine.isImmeuble) {
            valeurIcs = objRetour.origine.noimme;
            typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_IMMEUBLE;
          }
          else if(objRetour.origine.hasOwnProperty('noperso')) {
            valeurIcs = objRetour.origine.noperso;

            // Si model personnalite
            if(objRetour.origine.isPersonnalite) {
              // Si copropriétaire comme origine
              if(objRetour.origine.isCoproprietaire()) {
                typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_ICS;
              }
            }
            // Si salarié comme origine
            else if(objRetour.origine.isSalarie) {
              typeTag = COLLAB_CONF.TAG_TYPE_ORIGINE_GARDIEN;
              if(valeurIcs !== '000000') {
                retour.fiches.salarie = objRetour.origine;
              }
            }

          }

          if(objRetour.coproprietaire) {
            retour.fiches.coproprietaire = objRetour.coproprietaire;
          }
        }
        else{
          if(objRetour.origine.isPerso){
            retour.fiches.client = objRetour.origine.perso;
          }
          else if(objRetour.origine.isCollab){
            retour.fiches.groupe = objRetour.origine.groupe;
          }
        }

        // Création du tag actuel
        retour.tag = genereTag(objRetour.origine, typeTag, valeurIcs, objPortefeuille);
        retour.tags.push(genereTag(objRetour.origine));	// Ajoute le tag à la liste du composant
        retour.tags = UtilsService.unique(retour.tags);


        if(objRetour.immeuble) retour.fiches.immeuble = objRetour.immeuble;
        if(objRetour.bail) retour.fiches.bail = objRetour.bail;
        retourUpdate.addTag = retour;
      }
      catch(err){
        console.log('[TagService.genereTagsForOrigine] Erreur : '+err.message);
      }
    }
    else {
      if(objRetour.hasOwnProperty('origine')){
        tag = genereTag(objRetour.origine,typeTag);
        retourUpdate.tagCtrl.tags.push(tag);

        retourUpdate.addTag = tag;

      }
      // else if(objRetour.hasOwnProperty('collaborateurs')){
      //
      // 	retourUpdate.addTags = [];
      // 	for(var i = 0 ; i < objRetour.collaborateurs.length ; i++){
      // 		tag = TagService.genereTag(objRetour.collaborateurs[i],typeTag);
      // 		ctrl.tagCtrl.tags.push(tag);
      // 		retourUpdate.addTags.push(tag);
      // 		// TODO : "addTags" n'est pas pris en compte par l'hote, à faire quand on ajoutera plusieur tags avec l'annuaire (càd tag sans limit)
      // 	}
      // }
    }

    //console.log(retourUpdate);
    return retourUpdate;
  }
}]);
