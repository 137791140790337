'use strict';

/**
 * @desc Label pour les topic cloture
 * @example <evenement-cloture></evenement-cloture>
 */

angular
	.module('collaboreApp')
	.component('evenementCloture',{
		templateUrl: 'app/topics/components/evenement-cloture.component.html',
		bindings : {
			cloture: '<',
			allowFiltre: '<'
		},
		controllerAs: 'evenementcloturectrl',
		controller: ['$state','FiltreService',EvenementClotureController]
	});

function EvenementClotureController($state,FiltreService){

	var ctrl = this;

	ctrl.$onInit = function() {
		ctrl.currentState = $state.current.name;
		ctrl.paramsFiltreByEvenementCloture = {};
		if(ctrl.allowFiltre){
			ctrl.paramsFiltreByEvenementCloture = FiltreService.getObjFiltreByEvenementCloture();
		}
	};
}


