'use strict';

var deprecated_sharedWith = function () {
	return {
		restrict: 'E',
		templateUrl : 'app/scripts/documents/directives/sharedWith.html',
		scope: {
			document: '='
		},
		controller: ['COLLAB_CONF','DocumentService','$rootScope','$scope','UserService', '$q', 'sweet', function(COLLAB_CONF,DocumentService,$rootScope,$scope, UserService, $q, sweet) {
			$scope.user = UserService;
			$scope.COLLAB_CONF = COLLAB_CONF;
			$scope.token = $rootScope.token;

			$scope.changeSharing = function(document) {

				console.log(document);


				sweet.show({
					title: 'Partager ?',
					text: 'Partager ce document avec votre société',
					type: 'info',
					showCancelButton: true,
					confirmButtonColor: '#DD6B55',
					confirmButtonText: 'Partager !',
					closeOnConfirm: true
				},function() {

					document.changeSharing(0).then(function(newDocument){

						newDocument.type = 1;
						$scope.document = DocumentService._retrieveInstance(newDocument.guid,newDocument);
						console.log($scope.document);

					}, function(msg){
						console.log(msg);
					});
				});

			};
/*
			var partager = function(document) {
				var deffered = $q.defer();
				document.changeSharing(0).then(function(json){
					deffered.resolve();
				}, function(){
					deffered.reject();
				});
				return deffered.promise;
			};
*/
			$scope.submitPartage = function(form,event){
				event.preventDefault();
				console.log(form);
			};
		}]
	};
};

angular
	.module('collaboreApp')
	.directive('sharedWith',[
		deprecated_sharedWith
	]);
