(function () {
  'use strict';

  /**
   * OBSOLETE utiliser "collabFournisseurDetail"
   * @deprecated
   * @desc Extranet fournisseur
   * @example <extranet-fournisseur></extranet-fournisseur>
   */

  angular
    .module('collaboreApp')
    .component('extranetFournisseur', {
      templateUrl: 'app/extranet-fournisseur/extranet-fournisseur.component.html',
      controllerAs: 'extranetfournisseurctrl',
      controller: ExtranetFournisseurController
    });

  /*@ngInject*/
  function ExtranetFournisseurController(COLLAB_CONF, COLLAB_VALUES, $q, $sce, $state, $scope, $rootScope, $stateParams, $filter, UtilsService, ProxyContenuMessage, ProxyContenuEvenement, ContenuService, UserService, TopicStatesService, DocumentService, FileUploader, GroupeService, ModalsService, sweet) {

    var ctrl = this;
    var nbStep = 4;								// Nombre d'étapes de base
    var nbStepContrat = 3;						// Nombre d'étapes de base pour une demande de contrat

    ctrl.limitObjetEvenement = 50;              // Limit de caractères pour l'objet événement
    ctrl.onMobile = UtilsService.onMobile;
    ctrl.truncate = UtilsService.truncate;
    //ctrl.stripTags = UtilsService.stripTags;

    ctrl.nl2br = UtilsService.nl2br;
    ctrl.parseStringHTML = UtilsService.parseStringHTML;

    // ------------ Global -------------/
    ctrl.isConnected = $rootScope.isConnected;
    ctrl.urlEntete = COLLAB_VALUES.CONF_URL.URL_ENTETES;

    ctrl.nbDemandeDevisInter = 0;
    ctrl.nbDemandeContrat = 0;



    ctrl.topicState = false;                  // TopicState récupéré
    ctrl.loadingPage = false;
    ctrl.loadingCommentaire = false;
    ctrl.listDemandes = [];                   // Liste des demandes

    // Evenement
    ctrl.evenementPendingCloture = false;			// Demande de cloture d'evenement
    ctrl.evenement = false;                   // Evénement
    ctrl.isOpenFromCollab = false;		        // Si celui qui affiche la page est destinataire du devis et l'ouvre depuis collab
    ctrl.evenementOwner = false;		          // Si celui qui affiche la page est destinataire de l'événement
    ctrl.messageEvenement = '';               // Objet de l'événement
    ctrl.fichesEvenement = '';                // Fiches de l'événement
    ctrl.infosPortefeuille = false;           // Les infos du portefeuille
    ctrl.persoProprietaire = false;           // La perso propriétaire

    // Méthodes
    ctrl.eventCanBeCloture = eventCanBeCloture;                   // Est-ce que l'événement peut être cloturé ?
    ctrl.onClickCollapseDemande = onClickCollapseDemande;
    ctrl.getClassIconEtat = getClassIconEtat;                     // Permet de récupérer le bon icon d'état de la demande
    ctrl.getClassCurrentState = getClassCurrentState;					    // Permet de recupérer la class pour la progressbar de l'état d'avancement de la demande
    ctrl.getCurrentState = getCurrentState;							          // Permet de recupérer la phrase de l'état d'avancement de la demande
    ctrl.onUpdatePiecesJointes = onUpdatePiecesJointes;				    // Lors de l'upload du devis
    ctrl.cloturerEvenement = cloturerEvenement;						        // Lors de la demande de cloture
    ctrl.onClickRefreshDemande = onClickRefreshDemande;						// Lors du clique sur le bouton pour rafraichi la demande

    // ------------ Documents fournisseur -------------/
    ctrl.uploadersDocFournisseur = {};                            // Objet qui rassemble tous les uploads des documents fournisseurs

    // Méthodes
    ctrl.onSaveDocumentsFournisseur = onSaveDocumentsFournisseur;	// Lors de la sauvegarde des docs fournisseur du devis

    // ------------ Devis -------------/
    ctrl.uploadersDevis = {};                                     // Objet qui rassemble tous les uploads de devis (un par demande)

    // Méthodes
    ctrl.onSaveDevis = onSaveDevis;									              // Lors de la sauvegarde du devis
    ctrl.onUpdateDocumentDevis = onUpdateDocumentDevis;				    // Lors de l'upload du document devis

    // ------------ Interventions -------------/

    // Méthodes
    ctrl.onClickShowUploadDevis = onClickShowUploadDevis;         // Lorsque l'on clique sur le bouton pour afficher l'upload du devis

    // ------------ Facture -------------/
    ctrl.uploadersFacture = {};                                     // Objet qui rassemble tous les uploads de devis (un par demande)

    // Méthodes
    ctrl.onSaveFacture = onSaveFacture;								            // Lors de la sauvegarde de la facture

    // ------------ Contrat -------------/
    ctrl.uploadersContrat = {};                                     // Objet qui rassemble tous les uploads de Contrat (un par demande)

    // Méthodes
    ctrl.onSaveContrat = onSaveContrat;								// Lors de la sauvegarde du Contrat
    ctrl.onUpdateDocumentContrat = onUpdateDocumentContrat;			// Lors de l'upload du document Contrat
    ctrl.onClickShowUploadContrat = onClickShowUploadContrat;       // Lorsque l'on clique sur le bouton pour afficher l'upload du contrat

    // ------------ Commentaire -------------/
    ctrl.messageEnvoye = false;

    // Méthodes
    ctrl.onClickOpenSendMessage = onClickOpenSendMessage;
    ctrl.sendMessage = sendMessage;									              // Lors de la sauvegarde du message


    //ctrl.onEmitFromDocumentsComponents = onEmitFromDocumentsComponents;

    ctrl.$onInit = function () {

      //WebsocketServices.test();

      /*
      if(SocketNotificationService.isConnected()) {
          SocketNotificationService.getInstanceSocketIO().on('listeDevis', function(listeDevis){
              updateListDevis(listeDevis);
          });
      }

      $scope.$on('WebsocketUpdateListDevis', function (event, listDevis) {
          initDemandes(listDevis);
      });*/

      ctrl.loadingPage = true;
      TopicStatesService
        .getTopicStateByIdTopic($stateParams.idT)
        .then(function (topicState) {

          ctrl.topicState = topicState;

          ctrl.evenement = topicState.getTopic().getEvenement();
          ctrl.topicIsBlocked = topicState.getTopic().isBlocked;

          if (ctrl.evenement) {

            // Si ouvert par un destinataire de l'événement
            if (ctrl.evenement.isModifiable()) {
              ctrl.evenementOwner = true;
            }
            else {
              //topicState.getTopic().lire();

              // Si la page est la page ouverte depuis collab
              if ($state.is('evenementFournisseur')) ctrl.isOpenFromCollab = true;

              //WebsocketServices.connectWebsocket();
            }

            ctrl.expediteurEvenement = ctrl.evenement.getExpediteur().getDefaultNom();

            //ctrl.dateEvenement = $filter('date')(ctrl.evenement.getDateCreation(), 'dd/MM/yyyy à HH:mm');
            //ctrl.dateEvenement = UtilsService.parseDate(ctrl.evenement.getDateCreation(), 'DD/MM - HH:mm');
            ctrl.dateEvenement = UtilsService.dateMomentParser(ctrl.evenement.getDateCreation(), 'DD/MM');
            ctrl.referenceInterne = topicState.getTopic().getIdTopic();

            var retourObjetEvenement = UtilsService.getObjetEvenement(ctrl.evenement.getObjet());
            ctrl.messageEvenement = retourObjetEvenement.texteObjet;
            ctrl.fichesEvenement = retourObjetEvenement.texteFiches;
            /*
            var arrayContents = angular.element(ctrl.evenement.getObjet());
            if (arrayContents.length === 2) {
              ctrl.messageEvenement = $sce.trustAsHtml(arrayContents[0].innerHTML);
              ctrl.fichesEvenement = $sce.trustAsHtml(arrayContents[1].innerHTML);
            }
            else ctrl.messageEvenement = $sce.trustAsHtml(ctrl.evenement.getObjet());
            */

            var tagTypePortefeuille = ctrl.evenement.getTagForType(COLLAB_CONF.TAG_TYPE_PORTEFEUILLE);
            // Si il y aun tag type portefeuille
            if (tagTypePortefeuille && tagTypePortefeuille.hasOwnProperty('portefeuille') && tagTypePortefeuille.portefeuille.hasOwnProperty('portefeuilleInfos')) {
              ctrl.infosPortefeuille = tagTypePortefeuille.portefeuille.portefeuilleInfos;
            }

            var tagTypeProprietaire = ctrl.evenement.getTagForType(COLLAB_CONF.TAG_TYPE_PROPRIETAIRE);
            if (tagTypeProprietaire) {
              ctrl.loadingProprietaire = true;
              var idPortefeuilleProprietaire = tagTypeProprietaire.getPortefeuille().getIdPortefeuille();

              GroupeService
                .searchGroupeByLibelleAndPortefeuilleInMySociete(idPortefeuilleProprietaire, tagTypeProprietaire.getValeurIcs(), ctrl.evenement.getIdContenu())
                .then(function(groupe){
                  ctrl.persoProprietaire = groupe;
                })
                .finally(function(){
                  ctrl.loadingProprietaire = false;
                });
            }

            initListeDemande(ctrl.evenement);

          }
        })
        .finally(function () {
          ctrl.loadingPage = false;
        });


      if(!_.isNil($stateParams.idC)) {
        ctrl.idCurrentContenu = parseInt($stateParams.idC);
      }
    };

    ctrl.$onDestroy = function(){
      // Deconnecte la websocket
      // WebsocketServices.disconnect(true);
    };

    // ------------ Global -------------/

    /**
     * Permet de savoir si on peux cloturer l'événement
     * @returns {boolean}
     */
    function eventCanBeCloture(){
      var nbDemandeFinished = 0;
      if(ctrl.evenement.getListeDevis().length) {
        for(var e = 0; e < ctrl.evenement.getListeDevis().length; e++){
          if(ctrl.evenement.getListeDevis()[e].isDemandeIntervention() && ctrl.evenement.getListeDevis()[e].currentStep === 1) nbDemandeFinished++;
          else if(!ctrl.evenement.getListeDevis()[e].isDemandeIntervention() && (ctrl.evenement.getListeDevis()[e].isRefuse() || ctrl.evenement.getListeDevis()[e].currentStep === 4)) nbDemandeFinished++;
        }
      }
      return (nbDemandeFinished === ctrl.evenement.getListeDevis().length) ? true : false;;
    }

    /**
     * Permet d'init la liste des demandes
     * @param listeDemande
     */
    /*
    function initListeDemande(listeDemande){

        var demandeFind = null;
        ctrl.listDemandes = [];
        var newListDemande = [];
        var listDemandeEnfants = [];

        // Si il y a une liste de devis
        if(listeDemande.length) {

            // Parcour la liste des demandes de devis
            for(var i = 0; i < listeDemande.length; i++){
                var demande = listeDemande[i];

                if(!ctrl.evenementOwner || (ctrl.evenementOwner && $stateParams.idF && parseInt($stateParams.idF) === demande.getDestinataires()[0].getIdGroupe())) {
                    // Si pas d'idContenuRef ajoute à la nouvelle liste principale
                    if(!demande.getIdContenuRef()) ctrl.listDemandes.push(demande);
                    // Si il y a un idContenuRef alors c'est enfant on l'ajoute à une liste enfant
                    else listDemandeEnfants.push(demande);

                    initDemande(demande);
                    if(parseInt($stateParams.idC) === demande.getIdContenu()) demandeFind = demande;
                    newListDemande.push(demande);
                }
            }

            ctrl.evenement.listeDevis = newListDemande;


            // Si il y a bien des demandes dans la nouvelle liste et qu'il y a bien des enfant dans la liste enfant on doit les attribuer à leur parent
            if(ctrl.listDemandes.length && listDemandeEnfants.length){

                // Parcour la liste des demandes de devis
                for(var e = 0; e < listDemandeEnfants.length; e++){
                    var demandeEnfant = angular.copy(listDemandeEnfants[e]);
                    // Si il y a un idContenuRef
                    if(demandeEnfant.getIdContenuRef()) {

                        // Parcour la liste des demandes
                        for(var p = 0; p <  ctrl.listDemandes.length; p++){
                            var demandeParent = ctrl.listDemandes[p];
                            // Si l'idContenuRef de l'enfant est le même que l'idContenu du parent
                            if(demandeParent.getIdContenu() === demandeEnfant.getIdContenuRef()) demandeParent.addDevis(demandeEnfant);
                        }
                    }
                }
            }
        }
        listDemandeEnfants = null;
        if(demandeFind) {
            demandeFind.open = true;
            //UtilsService.scrollToDevis(demandeFind.getIdContenu());
            demandeFind = null;
        }
        else if(listeDemande.length === 1) {
            listeDemande[0].open = true;
        }
    }*/

    /**
     * Permet d'init la liste des demandes
     * @param listeDemande
     */
    function initListeDemande(evenement){
      ctrl.nbDemandeDevisInter = 0;
      ctrl.nbDemandeContrat = 0;
      ctrl.listDemandes = [];
      var listeDemandes = _.union(evenement.getListeDevis(), evenement.getListeContrats());

      // Si il y a une liste de demande
      if(listeDemandes.length) {
        // Parcour la liste des demandes
        for(var i = 0; i < listeDemandes.length; i++){
          var demande = listeDemandes[i];

          if(!ctrl.evenementOwner || (ctrl.evenementOwner && $stateParams.idF && parseInt($stateParams.idF) === demande.getDestinataire().getIdGroupe())) {
            initDemande(demande);
            if(parseInt($stateParams.idC) === demande.getIdContenu()) {
              demande.open = true;
            }
            ctrl.listDemandes.push(demande);
          }
        }
      }

      if(ctrl.listDemandes.length === 1) {
        ctrl.listDemandes[0].open = true;
      }
    }

    /**
     * Permet d'init chaque demande
     * @param currentDemande
     */
    function initDemande(currentDemande, open){

      currentDemande.currentStep = 0;     // Num étape actuel de la demande

      if(currentDemande.isContenuDevisInter()) {

        ctrl.nbDemandeDevisInter++;
        currentDemande.nbStep = angular.copy(nbStep);     // Nombre d'étape de la demande

        // Recupère les paramétrages
        var defaultParametrage = currentDemande.getExpediteur().getDefaultParametrage();
        if (defaultParametrage) {
          // Recupère le parametrage pour savoir si le fournisseur peux supprimer le devis
          currentDemande.allowDeleteDevisForFournisseur = (defaultParametrage.getDisallowDeleteDevisForFournisseur()) ? false : true;
        }
        // Si la page est ouverte par un destinataire de l'event direct alors rend possible la suppression
        if (ctrl.evenementOwner) currentDemande.allowDeleteDevisForFournisseur = true;

        initUploadDevis(currentDemande);
        initUploadDocumentsFournisseur(currentDemande);
        initUploadFacture(currentDemande);

        // Si demande de devis
        if(currentDemande.getDemandeDevisActive()) {
          // Si devis en attente
          if (currentDemande.isWaiting()) {
            currentDemande.currentStep = 0;

            // Si il y a un devis
            if(currentDemande.hasDevis()) currentDemande.currentStep = 1;
          }

          // Si devis accepté
          else if (currentDemande.isAccepte()) {
            currentDemande.currentStep = 2;

            // Si le devis est signé
            if (currentDemande.isSigne()) currentDemande.currentStep = 3;

            // Si le devis est signé et qu'il a une facture
            if (currentDemande.isSigne() && currentDemande.hasFacture()) currentDemande.currentStep = 4;
          }

          // Si devis refusé
          else if (currentDemande.isRefuse())  {
            currentDemande.currentStep = 0;
            currentDemande.nbStep = 0;
          }
        }
        // Si demande d'intervention
        else {
          currentDemande.currentStep = 0;

          // Si il y a un devis
          if(currentDemande.getFacture()) currentDemande.currentStep = 1;
        }

      }
      else if(currentDemande.isContenuContrat()) {
        ctrl.nbDemandeContrat++;
        currentDemande.nbStep = angular.copy(nbStepContrat);     // Nombre d'étape de la demande
        //currentDemande.allowDeleteContratForFournisseur = true;

        initUploadContrat(currentDemande);
        initUploadDocumentsFournisseur(currentDemande);

        // Si demande en attente du contrat fournisseur signé
        if (currentDemande.isWaiting()) {
          currentDemande.currentStep = 0;

          // Si il y a un contrat signé par le fournisseur
          if(currentDemande.isSignedByFournisseur()) currentDemande.currentStep = 1;
        }

        // Si contrat accepté
        else if (currentDemande.isAccepte()) {
          currentDemande.currentStep = 2;

          // Si le contrat est signé par l'agence
          if (currentDemande.isSignedByAgence()) currentDemande.currentStep = 3;
        }

        // Si contrat refusé
        else if (currentDemande.isRefuse())  {
          currentDemande.currentStep = 0;
          currentDemande.nbStep = 0;
        }
      }
      if(angular.isDefined(open)) currentDemande.open = open;
    }

    /**
     * Permet d'init les demandes par rapport à une liste, ajoute ou modifie si existe
     * @param listDemandes
     */
    function initDemandes(listDemandes){
      if(_.isArray(listDemandes) && listDemandes.length) {

        var listeDemandeEvent = _.union(ctrl.evenement.getListeDevis(), ctrl.evenement.getListeContrats());
        // Parcour la liste des nouvelles demandes
        for(var p = 0; p <  listDemandes.length; p++){
          var newDemande = listDemandes[p];
          if(newDemande.idTopic === parseInt($stateParams.idT)) {
            var exist = false;

            if(_.isArray(listeDemandeEvent) && listeDemandeEvent.length) {
              // Parcour la liste des demandes
              for(var i = 0; i < listeDemandeEvent.length; i++){
                var demande = listeDemandeEvent[i];

                // Si l'idContenu de la nouvelle demande est le même que l'idContenu d'une demande dans la liste existante
                if(newDemande.getIdContenu() === demande.getIdContenu()) {
                  var open = (demande.open) ? true : false;
                  demande.setData(newDemande);
                  demande.open = open;
                  exist = true;
                  initDemande(demande);
                  break;
                }
              }
            }

            if(!exist) {
              if(newDemande.isContenuDevisInter()) {
                ctrl.evenement.addDevis(newDemande);
              }
              else if(newDemande.isContenuContrat()){
                ctrl.evenement.addContrat(newDemande);
              }

              initDemande(newDemande, true);
              ctrl.listDemandes.push(newDemande);
            }
          }
        }
      }
    }

    /**
     * Lors du clique sur le bouton pour ouvrir la demande
     * @param demande
     */
    function onClickCollapseDemande(demande){
      demande.open = !demande.open;
    }

    /**
     * Permet de retourner la bonne icon d'état de la demande
     * @param demande
     * @returns {*}
     */
    function getClassIconEtat(demande){

      if(ctrl.topicIsBlocked) return 'fa-ban';

      // Si cloturé
      if(demande.isCloture()) {
        return 'fa-window-close';
      }

      if(demande.isContenuDevisInter()) {
        // Si demande devis
        if(demande.getDemandeDevisActive()) {
          // Si demande accepté
          if(demande.isAccepte()) {

            // Si devis signé et qu'il y a une facture
            if(demande.hasDevisSigne() && demande.hasFacture()) return 'fa-thumbs-o-up';

            // Si devis signé
            if(demande.hasDevisSigne()) return 'fa-pencil';
            else return 'fa-check';
          }
          // Si refusé
          else if(demande.isRefuse()) return 'fa-times';
          // Si demande en attente
          else if(demande.isWaiting()) return 'fa-clock-o';
        }
        else {
          // Si l'intervention a une facture
          if(demande.hasFacture()) return 'fa-thumbs-o-up';
        }
      }
      else if(demande.isContenuContrat()) {
        // Si demande accepté
        if(demande.isAccepte()) {

          // Si le contrat est signé par le fournisseur
          if(demande.hasContratFournisseurSigne()) return 'fa-thumbs-o-up';

          // Si contrat signé par l'agence
          if(demande.hasContratAgenceSigne()) return 'fa-pencil';
          else return 'fa-check';
        }
        // Si refusé
        else if(demande.isRefuse()) return 'fa-times';
        // Si demande en attente
        else if(demande.isWaiting()) return 'fa-clock-o';
      }
      return 'fa-clock-o';
    }

    /**
     * Permet de retourner la phrase de l'état d'avancement de la demande
     * @param demande
     * @returns {string}
     */
    function getCurrentState(demande) {
      var textRetour = '';

      if(ctrl.topicIsBlocked) return '<strong class="text-warning">Dossier bloqué</strong>';

      if(demande.isContenuDevisInter()) {
        // Si demande devis
        if(demande.getDemandeDevisActive()) {
          textRetour = '<strong class="text-muted">Attente du devis...</strong>';
          switch (demande.currentStep) {
            case 1:
              textRetour = '<strong class="text-muted">En traitement...</strong>';
              break;
            case 2:
              textRetour = '<strong class="text-success">Accepté</strong>';
              break;
            case 3:
              textRetour = '<strong class="text-success">Accepté/signé !</strong>';
              break;
            case 4:
              textRetour = '<strong class="text-success">Facture reçu !</strong>';
              break;
            case 5:
              textRetour = '<strong class="text-muted">Cloturé</strong>';
              break;
            default:
              if(demande.nbStep === 0) textRetour = '<strong class="text-danger">Refusé</strong>';
          }
        }
        else {
          textRetour = '<strong class="text-muted">Attente facture...</strong>';
          switch (demande.currentStep) {
            case 1:
              textRetour = '<strong class="text-success">Facture reçu !</strong>';
              break;
            case 4:
              textRetour = '<strong class="text-muted">Cloturé</strong>';
              break;
            default:
          }
        }
      }
      else if(demande.isContenuContrat()) {
        textRetour = '<strong class="text-muted">Attente du contrat signé...</strong>';
        switch (demande.currentStep) {
          case 1:
            textRetour = '<strong class="text-muted">En traitement...</strong>';
            break;
          case 2:
            textRetour = '<strong class="text-success">Accepté</strong>';
            break;
          case 3:
            textRetour = '<strong class="text-success">Accepté/signé !</strong>';
            break;
          case 4:
            textRetour = '<strong class="text-muted">Cloturé</strong>';
            break;

          default:
            if(demande.nbStep === 0) textRetour = '<strong class="text-danger">Refusé</strong>';
        }
      }

      return textRetour;
    }

    /**
     * Permet de retourner la class css à utiliser pour le type d'état d'avancement de la demande
     * @param demande
     * @returns {string}
     */
    function getClassCurrentState(demande) {
      // Si cloturé
      if(demande.isCloture()) {
        return 'danger';
      }
      var classRetour = 'primary';

      if(demande.isContenuDevisInter()) {
        if(demande.isContenuDevis()) {
          switch (demande.currentStep) {
            // En traitement
            case 1:
              classRetour = 'muted';
              break;
            // Devis accepte
            case 2:
              classRetour = 'success';
              break;
            // Devis accepte et signe
            case 3:
              classRetour = 'success';
              break;
            // Facture recu
            case 4:
              classRetour = 'success';
              break;
            // Cloturé
            case 5:
              classRetour = 'muted';
              break;
            default:
              if (demande.nbStep === 0) classRetour = 'danger';
          }
        }
        else {
          switch (demande.currentStep) {
            case 0:
              classRetour = 'muted';
              break;
            // Facture recu
            case 1:
              classRetour = 'success';
              break;
            default:
              if (demande.nbStep === 0) classRetour = 'danger';
          }
        }
      }
      else if(demande.isContenuContrat()) {
        switch (demande.currentStep) {
          // Contrat fournisseur signé reçu
          case 1:
            classRetour = 'muted';
            break;
          // Contrat accepte
          case 2:
            classRetour = 'success';
            break;
          // Contrat signé par l'agence
          case 3:
            classRetour = 'success';
            break;
          // Cloturé
          case 4:
            classRetour = 'muted';
            break;
          default:
            if (demande.nbStep === 0) classRetour = 'danger';
        }

      }

      return classRetour;
    }

    /**
     * Fonction utilisée après l'ajout d'un fichier dans la liste des fichier à upload
     * @param fileItem
     */
    function afterAddingFile(fileItem) {
      fileItem.formData.push({idGroupe: UserService.getGroupe().getIdGroupe()});
      fileItem.formData.push({token: UserService.getGroupe().getToken()});
    }

    /**
     * Fonction utilisée après l'ajout d'un fichier dans la liste des fichier à upload pour les devis
     * @param fileItem
     */
    function afterAddingFileDevis(fileItem) {
      afterAddingFile(fileItem);
      fileItem.formData.push({convertToPdf: true});
    }

    /**
     * Filtre taille limit de fichier pour l'upload
     * @param item
     * @returns {[null]}
     */
    function sizeLimitFilterUpload(){
      return {
        name: 'sizeLimit',
        fn: function (item) {

          if (item.size <= COLLAB_CONF.SIZE_LIMIT_UPLOAD) {
            return true;
          } else {
            sweet.show({
              title: '<span class="text-danger">' + item.name + '</span> trop gros !',
              text: '50 Mo Maximum',
              type: 'error',
              html: true
            });
          }
        }
      };
    }

    /**
     * Filtre le type de fichier autorisés pour l'upload
     * @returns {{name: string, fn: fn}}
     */
    function allowExtensionFilterUpload(){
      return {
        name: 'allowExtension',
        fn: function (item) {
          var extensionsAllowed = ['pdf','jpg','png','jpeg','bmp','gif'];
          var type = item.type.slice(item.type.lastIndexOf('/') + 1);
          if (!UtilsService.contains(extensionsAllowed, type)) {
            sweet.show({
              title: '<span class="text-danger">' + item.name + '</span> doit être un PDF ou une image !',
              text: 'PDF ou Image obligatoire',
              type: 'error',
              html: true
            });
          }
          else return true;
        }
      };
    }

    /**
     * Permet de sauvegarder la demande
     */
    /*
    function save() {
      var deferred = $q.defer();
      var instanceProxy = new ProxyContenuEvenement(ctrl.evenement);
      sendContenu(instanceProxy)
        .then(function (demande) {
          console.log(demande);
          //initDemande(demande);
          deferred.resolve();
        })
        .catch(function (msg) {
          deferred.reject(msg);
        });
      return deferred.promise;
    }*/

    /**
     * Ajoute un contenu au topic actuel
     * @param {Object} formRepondre - Formulaire
     * @returns {void}
     */
    function sendContenu(proxyObj) {
      var deferred = $q.defer();

      // Ajout d'un contenu
      if (!ctrl.isNewTopic) {

        // Ajoute l'idTopic
        proxyObj.idTopic = ctrl.topicState.getTopic().getIdTopic();

        ContenuService.sendContenu(proxyObj).then(function(objRetour){
          if(objRetour.action === 'updateContenu') ctrl.topicState.getTopic().updateContenu(objRetour.obj);
          else if(objRetour.action === 'insertContenu') ctrl.topicState.getTopic().addContenu(objRetour.obj);
          deferred.resolve(objRetour);
        });
      }
      return deferred.promise;
    }

    /**
     * Lors de la modification des pièces jointes
     * @param objUpdate
     */
    function onUpdatePiecesJointes(demande, objUpdate) {
      var saveAuto = false;
      try {
        if (!objUpdate) throw new Error('Il manque l\'objet "objUpdate" !');
        /*
        if (objUpdate.hasOwnProperty('saveAuto')) saveAuto = objUpdate.saveAuto;

        // Si sauvegarde auto alors doit etre une promesse
        if (saveAuto) {
          // Update le contenu venant des pièces jointes
          demande = objUpdate.contenu;

          // Certainement une suppression du devis donc repasse le devisConfirmed en false
          //if (objUpdate.hasOwnProperty('contenu') && objUpdate.contenu.documentDevis === '') demande.documentDevisConfirmed = false;

          // Submit l'événements
          save();
        }*/

        // Si action delete
        if (objUpdate.hasOwnProperty('action') && objUpdate.action === 'delete' && !saveAuto) {

          if (objUpdate.hasOwnProperty('property') && angular.isString(objUpdate.property)) {
            if(objUpdate.property === 'listeDocumentFournisseur') {
              ctrl.uploadersDocFournisseur[demande.getIdContenu()].loadingDelete = true;
              demande
                .deleteDocumentFromListeDocumentFournisseur(objUpdate.document.getGuid())
                .then(function () {
                  initDemande(demande, true);
                })
                .catch(function (msg) {
                  ModalsService.alertErreur(msg);
                })
                .finally(function(){
                  ctrl.uploadersDocFournisseur[demande.getIdContenu()].loadingDelete = false;
                });
            }
            if(objUpdate.property === 'listeDocument') {
              ctrl.uploadersDocFournisseur[demande.getIdContenu()].loadingDelete = true;
              demande
                .deleteDocumentFromListeDocument(objUpdate.document.getGuid())
                .then(function () {
                  initDemande(demande, true);
                })
                .catch(function (msg) {
                  ModalsService.alertErreur(msg);
                })
                .finally(function(){
                  ctrl.uploadersDocFournisseur[demande.getIdContenu()].loadingDelete = false;
                });
            }
          }
        }
      }
      catch (err) {
        console.log('[ExtranetFournisseurController.onUpdatePiecesJointes] Erreur : ' + err.message);
      }
    }

    /**
     * Lors de la demande de cloture de l'événement
     */
    function cloturerEvenement() {
      // Si cloture
      if (ctrl.evenement.getDateCloture() === '' || angular.isUndefined(ctrl.evenement.getDateCloture())) {
        ContenuService.clotureOrReouvreEvenement(ctrl.evenement).then(function (contenu) {
          if (_.isObject(contenu) && contenu.isModel) {
            ctrl.evenementPendingCloture = true;
          }
        });
      }
    }

    /**
     * Lors du clique sur le bouton pour rafraichir la demande
     * @param demande
     */
    function onClickRefreshDemande(demande){
      demande.loading = true;
      ContenuService
        .getContenu(demande.getIdContenu())
        .then(function(contenu){
          demande.setData(contenu);
          initDemande(demande, true);
        })
        .finally(function(){
          demande.loading = false;
        })
    }

    // ------------ Documents fournisseur -------------/

    /**
     * Permet d'init les uploader des document devis de chaque demande
     * @param demande
     */
    function initUploadDocumentsFournisseur(demande) {
      // Si pas d'uploader document fournisseur pour cette demande
      if(!ctrl.uploadersDocFournisseur.hasOwnProperty(demande.getIdContenu())) {
        var currentUploadDocFournisseur = {
          loading: false,
          uploader: new FileUploader({
            autoUpload: false,
            url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_DOCUMENTS_IN_GROUPE,
            alias: 'fileName',
            removeAfterUpload: true
          })
        };
        currentUploadDocFournisseur.uploader.filters.push(sizeLimitFilterUpload());

        /**
         * Après l'ajout d'un fichier en pièce jointe
         * @param fileItem
         */
        currentUploadDocFournisseur.uploader.onAfterAddingFile = afterAddingFile;

        /**
         * Lorsqu'un fichier est bien upload
         * @param fileItem
         * @param response
         */
        currentUploadDocFournisseur.uploader.onSuccessItem = function (fileItem, response) {
          if (response.nb !== '0' && demande) {
            // Set le nouveau document devis
            demande.addDocumentFournisseur(response.Documents[0]);
            // demande.update = true;
          }
        };

        /**
         * Lorsque l'upload de toute la liste est terminée
         */
        currentUploadDocFournisseur.uploader.onCompleteAll = function () {

          currentUploadDocFournisseur.loading = true;
          demande
            .updateDocumentsFournisseur()
            .then(function () {
              //sweet.show('Terminé', 'Documents envoyés !', 'success');
              initDemande(demande, true);
            })
            .catch(function (msg) {
              ModalsService.alertErreur(msg);
            })
            .finally(function () {
              currentUploadDocFournisseur.loading = false;
            });
        };

        /**
         * Si erreur sur un item à upload
         */
        currentUploadDocFournisseur.uploader.onErrorItem = function () {
          currentUploadDocFournisseur.loading = false;
        };

        // Upload de devis
        ctrl.uploadersDocFournisseur[demande.getIdContenu()] = currentUploadDocFournisseur;
      }
    }

    /**
     * Sauvegarde les documents du fournisseur
     */
    function onSaveDocumentsFournisseur(demande) {
      ctrl.uploadersDocFournisseur[demande.getIdContenu()].uploader.uploadAll();
    }

    // -------------- Devis ---------------/

    /**
     * Permet de créer une instance d'uploader par demande
     * @param demande
     */
    function initUploadDevis(demande) {

      // Si pas d'uploader devis pour cette demande
      if(!ctrl.uploadersDevis.hasOwnProperty(demande.getIdContenu())) {

        var currentUploadDevis = {
          loading: false,
          listGuids: [],
          uploader: new FileUploader({
            autoUpload: false,
            url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_DOCUMENTS_IN_GROUPE,
            alias: 'fileName',
            removeAfterUpload: true
            //queueLimit: 1
          })
        };
        currentUploadDevis.uploader.filters.push(sizeLimitFilterUpload());
        //currentUploadDevis.uploader.filters.push(allowExtensionFilterUpload());

        /**
         * Après l'ajout d'un fichier en pièce jointe
         * @param fileItem
         */
        currentUploadDevis.uploader.onAfterAddingFile = afterAddingFileDevis;

        /**
         * Lorsqu'un fichier est bien upload
         * @param fileItem
         * @param response
         */
        currentUploadDevis.uploader.onSuccessItem = function (fileItem, response) {
          if (response.nb !== '0' && demande) {
            var doc = DocumentService.newDocument(response.Documents[0]);
            currentUploadDevis.listGuids.push(doc.getGuid());
            doc = null;
            // Set le nouveau document devis
            //demande.setDocumentDevis(angular.copy(DocumentService.newDocument(response.Documents[0])));
          }
        };

        /**
         * Lorsque l'upload de toute la liste est terminée
         */
        currentUploadDevis.uploader.onCompleteAll = function () {

          currentUploadDevis.loading = true;

          demande
            .changeDocumentDevis(currentUploadDevis.listGuids)
            .then(function (retour) {


              //demande.documentDevisConfirmed = true;
              if(_.isArray(retour)) {
                if(retour.length) {
                  // Cherche la demande actuel dans la liste, si elle est trouvé alors on ne la ferme pas
                  var demandeFound = false;
                  for(var i = 0; i < retour.length; i++){
                    if(retour[i].idContenu === demande.idContenu) {
                      demandeFound = true;
                      break;
                    }
                  }
                  if(demandeFound) demande.open = true;
                  else demande.open = false;
                }
                initDemandes(retour);
              }
              else if(_.isObject(retour)) {
                // Si le retour est la demande initial alors on ne la ferme pas
                if(retour.idContenu === demande.idContenu) demande.open = true;
                else demande.open = false;
                initDemande(retour);
              }

            })
            .catch(function (msg) {
              ModalsService.alertErreur(msg);
            })
            .finally(function () {
              currentUploadDevis.loading = false;
              currentUploadDevis.listGuids = [];
            });//
          /*
          demande
            .changeDocumentDevis(demande.getDocumentDevis().getGuid())
            .then(function () {
              demande.documentDevisConfirmed = true;
              initDemande(demande);
            })
            .catch(function (msg) {
              ModalsService.alertErreur(msg);
            })
            .finally(function () {
              currentUploadDevis.loading = false;
            });*/
        };

        /**
         * Si erreur sur un item à upload
         */
        currentUploadDevis.uploader.onErrorItem = function () {
          currentUploadDevis.loading = false;
        };

        // Upload de devis
        ctrl.uploadersDevis[demande.getIdContenu()] = currentUploadDevis;
      }
    }

    /**
     * Sauvegarde du devis
     */
    function onSaveDevis(demande) {
      ctrl.uploadersDevis[demande.getIdContenu()].uploader.uploadAll();
    }

    /**
     * Lors de la modification
     * @param objUpdate
     */
    function onUpdateDocumentDevis(demande, objUpdate) {
      try {
        if (!objUpdate) throw new Error('Il manque l\'objet "objUpdate" !');

        // Si action delete
        if (objUpdate.hasOwnProperty('action') && objUpdate.action === 'delete') {

          // Update le contenu venant des pièces jointes
          //if (objUpdate.hasOwnProperty('contenu')) {

          // Certainement une suppression du devis donc repasse le devisConfirmed en false
          //if (demande.documentDevis === '' && objUpdate.contenu.documentDevis === '') {

          ctrl.uploadersDevis[demande.getIdContenu()].loadingDelete = true;

          // Delete le document devis
          demande
            .deleteDocumentDevis()
            .then(function () {
              initDemande(demande, true);
            })
            .catch(function (msg) {
              ModalsService.alertErreur(msg);
            })
            .finally(function () {
              ctrl.uploadersDevis[demande.getIdContenu()].loadingDelete = false;
            });
          //}
          // Si pas de document devis dans le devis  alors c'est une suppression de pièces jointe sans l'avoir ajouté au devis
          /*else if (demande.documentDevis === '') {
            DocumentService.deleteDocumentPerso(objUpdate.document);
            initDemande(demande);
          }*/
          //}
        }
      }
      catch (err) {
        console.log('[ExtranetFournisseurController.onUpdateDocumentDevis] Erreur : ' + err.message);
      }

    }

    // -------------- Interventions ---------------/

    /**
     * Lorsque l'on clique sur le bouton pour envoyer un devis pour une demande d'intervention
     */
    function onClickShowUploadDevis(demande) {
      demande.showUploadDevis = true;
    }

    // -------------- Factures -----------------/

    /**
     * Permet de créer une instance d'uploader de facture par demande
     * @param demande
     */
    function initUploadFacture(demande) {

      // Si pas d'uploader devis pour cette demande
      if(!ctrl.uploadersFacture.hasOwnProperty(demande.getIdContenu())) {

        var currentUploadFacture = {
          loading: false,
          uploader: new FileUploader({
            autoUpload: false,
            url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_DOCUMENTS_IN_GROUPE,
            alias: 'fileName',
            removeAfterUpload: true,
            queueLimit: 1
          })
        };
        currentUploadFacture.uploader.filters.push(sizeLimitFilterUpload());
        //currentUploadFacture.uploader.filters.push(allowExtensionFilterUpload());

        /**
         * Après l'ajout d'un fichier en pièce jointe
         * @param fileItem
         */
        currentUploadFacture.uploader.onAfterAddingFile = afterAddingFileDevis;

        /**
         * Lorsqu'un fichier est bien upload
         * @param fileItem
         * @param response
         */
        currentUploadFacture.uploader.onSuccessItem = function (fileItem, response) {
          if (!demande.getFacture()) demande.setFacture(ContenuService.initContenuEvenementFacture());
          // Set le nouveau document devis
          if (response.nb !== '0' && demande.getFacture()) demande.getFacture().addDocument(response.Documents[0]);
        };

        /**
         * Lorsque l'upload de toute la liste est terminée
         */
        currentUploadFacture.uploader.onCompleteAll = function () {

          currentUploadFacture.loading = true;

          demande.getFacture()
            .createFactureInDevis(demande.getIdContenu())
            .then(function (retour) {
              initDemande(demande, true);
            })
            .catch(function (msg) {
              ModalsService.alertErreur(msg);
            })
            .finally(function () {
              currentUploadFacture.loading = false;
            });
        };

        /**
         * Si erreur sur un item à upload
         */
        currentUploadFacture.uploader.onErrorItem = function () {
          currentUploadFacture.loading = false;
        };

        // Upload de devis
        ctrl.uploadersFacture[demande.getIdContenu()] = currentUploadFacture;
      }
    }

    /**
     * Sauvegarde de la facture
     */
    function onSaveFacture(demande) {
      ctrl.uploadersFacture[demande.getIdContenu()].uploader.uploadAll();
    }

    // -------------- Contrat ---------------/

    /**
     * Permet de créer une instance d'uploader par demande
     * @param demande
     */
    function initUploadContrat(demande) {

      // Si pas d'uploader devis pour cette demande
      if(!ctrl.uploadersContrat.hasOwnProperty(demande.getIdContenu())) {

        var currentUploadContrat = {
          loading: false,
          listGuids: [],
          uploader: new FileUploader({
            autoUpload: false,
            url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_DOCUMENTS_IN_GROUPE,
            alias: 'fileName',
            removeAfterUpload: true,
            filters: [
              {
                name: 'imageFilter',
                // A user-defined filter
                fn: function(item) {
                  var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                  //return '|jpg|png|jpeg|gif|'.indexOf(type) !== -1;
                  if(('|jpg|png|jpeg|gif|pdf|'.indexOf(type) !== -1)===false){
                    sweet.show({
                      title: '<span class="text-danger">' + item.name + '</span> doit être une image ou un PDF !',
                      text: 'Fichier Image ou PDF obligatoire !',
                      type: 'error',
                      html: true
                    });
                  }else{
                    return true;
                  }
                }
              }]
            //queueLimit: 1
          })
        };
        currentUploadContrat.uploader.filters.push(sizeLimitFilterUpload());

        /**
         * Après l'ajout d'un fichier en pièce jointe
         * @param fileItem
         */
        currentUploadContrat.uploader.onAfterAddingFile = afterAddingFileDevis;

        /**
         * Lorsqu'un fichier est bien upload
         * @param fileItem
         * @param response
         */
        currentUploadContrat.uploader.onSuccessItem = function (fileItem, response) {
          if (response.nb !== '0' && demande) {
            var doc = DocumentService.newDocument(response.Documents[0]);
            currentUploadContrat.listGuids.push(doc.getGuid());
            doc = null;
          }
        };

        /**
         * Lorsque l'upload de toute la liste est terminée
         */
        currentUploadContrat.uploader.onCompleteAll = function () {

          currentUploadContrat.loading = true;

          demande
            .saveDocumentsContratFournisseurSigne(currentUploadContrat.listGuids)
            .then(function (retour) {


              //demande.documentDevisConfirmed = true;
              if(_.isArray(retour)) {
                if(retour.length) {
                  // Cherche la demande actuel dans la liste, si elle est trouvé alors on ne la ferme pas
                  var demandeFound = false;
                  for(var i = 0; i < retour.length; i++){
                    if(retour[i].idContenu === demande.idContenu) {
                      demandeFound = true;
                      break;
                    }
                  }
                  if(demandeFound) demande.open = true;
                  else demande.open = false;
                }
                initDemandes(retour);
              }
              else if(_.isObject(retour)) {
                // Si le retour est la demande initial alors on ne la ferme pas
                if(retour.idContenu === demande.idContenu) demande.open = true;
                else demande.open = false;
                initDemande(retour);
              }
            })
            .catch(function (msg) {
              ModalsService.alertErreur(msg);
            })
            .finally(function () {
              currentUploadContrat.loading = false;
              currentUploadContrat.listGuids = [];
            });//
          /*
          demande
            .changeDocumentDevis(demande.getDocumentDevis().getGuid())
            .then(function () {
              demande.documentDevisConfirmed = true;
              initDemande(demande);
            })
            .catch(function (msg) {
              ModalsService.alertErreur(msg);
            })
            .finally(function () {
              currentUploadDevis.loading = false;
            });*/
        };

        /**
         * Si erreur sur un item à upload
         */
        currentUploadContrat.uploader.onErrorItem = function () {
          currentUploadContrat.loading = false;
        };

        // Upload de devis
        ctrl.uploadersContrat[demande.getIdContenu()] = currentUploadContrat;
      }
    }

    /**
     * Sauvegarde du devis
     */
    function onSaveContrat(demande) {
      ctrl.uploadersContrat[demande.getIdContenu()].uploader.uploadAll();
    }

    /**
     * Lors de la modification
     * @param objUpdate
     */
    function onUpdateDocumentContrat(demande, objUpdate) {
      try {
        if (!objUpdate) throw new Error('Il manque l\'objet "objUpdate" !');

        // Si action delete
        if (objUpdate.hasOwnProperty('action') && objUpdate.action === 'delete') {

          if (!_.isObject(objUpdate.document)) throw new Error('Il manque l\'objet "document" dans "objUpdate" !');

          ctrl.uploadersContrat[demande.getIdContenu()].loadingDelete = true;

          // Delete le document devis
          demande
            .deleteDocumentFromListDocumentContratFournisseurSigne(objUpdate.document.getGuid())
            .then(function () {
              initDemande(demande, true);
            })
            .catch(function (msg) {
              ModalsService.alertErreur(msg);
            })
            .finally(function () {
              ctrl.uploadersContrat[demande.getIdContenu()].loadingDelete = false;
            });
        }
      }
      catch (err) {
        console.log('[ExtranetFournisseurController.onUpdateDocumentContrat] Erreur : ' + err.message);
      }

    }

    /**
     * Lorsque l'on clique sur le bouton pour envoyer un devis pour une demande d'intervention
     */
    function onClickShowUploadContrat(demande) {
      demande.showUploadContrat = true;
    }

    // -------------- Commentaire -----------------/

    function onClickOpenSendMessage(){
      sweet.show({
        title: 'Vous avez une question ?',
        text: '<textarea style="width:100%" rows="3" id="commentaireMessage" class="form-control"></textarea>',
        type: 'info',
        html: true,
        showCancelButton: true,
        //confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Envoyer',
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      }, function () {
        var message = angular.element('#commentaireMessage').val();
        sendMessage(message)
          .finally(function(){
            sweet.close();
          });

      });
    }

    /**
     * Permet de poster un message
     */
    function sendMessage(message) {

      var deferred = $q.defer();
      var messageProxy = new ProxyContenuMessage();

      if (angular.isUndefined(message) || message === '') {
        sweet.show('Oops...', 'Veuillez renseigner un message', 'error');
        return;
      }

      messageProxy.setMessage(message);
      messageProxy.setDestinataires(ctrl.evenement.destinataires);

      ctrl.loadingCommentaire = true;
      sendContenu(messageProxy)
        .then(function () {
          ctrl.messageEnvoye = true;
          ctrl.loadingCommentaire = false;
          deferred.resolve();
        });

      return deferred.promise;
    }


    // ----------- Divers --------------------/

    /*
    function onEmitFromDocumentsComponents(obj){
        console.log(obj);
    }*/

  }
})();
