(function() {
	'use strict';

	/**
	 * @desc Block de groupe communication
	 * @example <groupe-communication></groupe-communication>
	 */

	angular
		.module('collaboreApp')
		.component('groupeCommunication',{
			templateUrl: 'app/communication/groupe-communication.component.html',
			controllerAs: 'groupecommunicationcomponentctrl',
			bindings: {
				groupe: '<',
				destinatairesEvenement: '<',
				type: '@',
				onChange: '&'
			},
			controller: GroupeCommunicationController
		});

	/*@ngInject*/
	function GroupeCommunicationController($scope, sweet, GroupesService, UtilsService) {

		var ctrl = this;
		var noUpdate = false;
		var firstPage = 1;
		var maxSize = 5;
		var limit = 15;
		var checkedParDefault = false;

		ctrl.onMobile = UtilsService.onMobile;

		ctrl.pagination = {
			totalItem: 0,
			currentPage: firstPage,
			maxSize: maxSize,
			limit: limit
		};

		ctrl.paginationPCS = {
			totalItem: 0,
			currentPage: firstPage,
			maxSize: maxSize,
			limit: limit
		};

		ctrl.paginationMCS = {
			totalItem: 0,
			currentPage: firstPage,
			maxSize: maxSize,
			limit: limit
		};

		ctrl.loadingGroupeFils = false;
		ctrl.loadingGroupeFilsPCS = false;
		ctrl.loadingGroupeFilsMCS = false;
		ctrl.allGroupeChecked = angular.copy(checkedParDefault);			// Checkbox du groupe parent
		ctrl.allGroupePCSChecked = angular.copy(checkedParDefault);		// Checkbox du groupe parent PCS
		ctrl.allGroupeMCSChecked = angular.copy(checkedParDefault);		// Checkbox du groupe parent MCS
		ctrl.groupeFils = [];
		ctrl.groupeFilsPCS = [];
		ctrl.groupeFilsMCS = [];

		ctrl.groupesUserExclus = [];
		ctrl.groupesUserCoches = [];

		ctrl.groupesUserPCSExclus = [];
		ctrl.groupesUserPCSCoches = [];

		ctrl.groupesUserMCSExclus = [];
		ctrl.groupesUserMCSCoches = [];

		ctrl.onChangeCheckboxGroupe = onChangeCheckboxGroupe;				// Executé lors du changement du checkbox d'un groupe
		ctrl.onChangeCheckboxGroupeUser = onChangeCheckboxGroupeUser;		// Executé lors du changement du checkbox d'un groupe user
		ctrl.onChangeCheckboxGroupeUserPCS = onChangeCheckboxGroupeUserPCS;	// Executé lors du changement du checkbox d'un groupe user du CS
		//ctrl.countGroupeUserChecked = countGroupeUserChecked;				// Permet de compter le nombre de groupeFils user checked dans un groupe
		//ctrl.countGroupeUserTotal = countGroupeUserTotal;					// Permet de compter le nombre de groupeFils user au total dans un groupe
		ctrl.onChangeCheckboxGroupeUserMCS = onChangeCheckboxGroupeUserMCS;	// Executé lors du changement du checkbox d'un groupe user du CS
		//ctrl.countGroupeUserChecked = countGroupeUserChecked;				// Permet de compter le nombre de groupeFils user checked dans un groupe
		//ctrl.countGroupeUserTotal = countGroupeUserTotal;					// Permet de compter le nombre de groupeFils user au total dans un groupe
		ctrl.onOpenGroupe = onOpenGroupe;									// Permet d'ouvrir le groupe
		ctrl.onChangePage = onChangePage;									// Lors du changement de page
		ctrl.onCliqueExtranet = onCliqueExtranet;							// Permet de partager l'event pour le groupe en param

        var listenerOnToggleDestinatairesImmeubleCommunication = $scope.$on('toggleDestinatairesImmeubleCommunication',function(ev,val,noEmit){
            ctrl.groupe.checked = val;
            noUpdate = noEmit;
            onChangeCheckboxGroupe(ctrl.groupe);
        });

		ctrl.$onInit = function() {
			if (ctrl.groupe) {
				ctrl.groupe.checked = angular.copy(checkedParDefault);
				//recursiveChangeCheckboxGroupe(ctrl.groupe,true);
				if (ctrl.groupe.isCS()){
					ctrl.groupePCS = ctrl.groupe.getGroupePresidentConseilSyndical();
					ctrl.groupeMCS = ctrl.groupe.getGroupeMembreConseilSyndical();

					ctrl.groupePCS.checked = angular.copy(checkedParDefault);
					ctrl.groupeMCS.checked = angular.copy(checkedParDefault);

					var nb = 0;
					var nbUsersPCS = angular.copy(ctrl.groupePCS.getGroupeInformations().nbGroupesUser);
					var nbUsersMCS = angular.copy(ctrl.groupeMCS.getGroupeInformations().nbGroupesUser);

					ctrl.groupePCS.nbUserTotal = nbUsersPCS;
					ctrl.groupePCS.nbUserChecked = (checkedParDefault) ? nbUsersPCS : 0;

					ctrl.groupeMCS.nbUserTotal = nbUsersMCS;
					ctrl.groupeMCS.nbUserChecked = (checkedParDefault) ? nbUsersMCS : 0;

					nb += nbUsersPCS;
					nb += nbUsersMCS;


					ctrl.groupe.nbUserTotal = nb;
					ctrl.groupe.nbUserChecked = (checkedParDefault) ? nb : 0;

				}
				else {
					ctrl.groupe.nbUserTotal = ctrl.groupe.getGroupeInformations().nbGroupesUser;
					ctrl.groupe.nbUserChecked = (checkedParDefault) ? ctrl.groupe.getGroupeInformations().nbGroupesUser : 0;
				}

				/*
				 ctrl.groupe.nbUserTotal = recursiveCountGroupeFils(ctrl.groupe,0,false);
				 ctrl.groupe.nbUserChecked = recursiveCountGroupeFils(ctrl.groupe,0,true);
				 */

				if (ctrl.destinatairesEvenement.length) {
					for(var i = 0 ; i < ctrl.destinatairesEvenement.length ; i++) {
						if (ctrl.destinatairesEvenement[i].idGroupe === ctrl.groupe.getIdGroupe()){
							ctrl.isInEvent = true;
							break;
						}
					}
				}
			}

		};

		ctrl.$onDestroy = function(){
            listenerOnToggleDestinatairesImmeubleCommunication();
		};

		/**
		 * Recupère l'index d'un groupe dans une liste. Car indexOf ne fonctionne pas à cause des hashkey ajoutés par Angular
		 * @param liste
		 * @param groupe
		 * @returns {number}
		 */
		function getIndexFromListeForGroupe(liste, groupe) {
			var index = -1;
			if (liste.length) {
				for (var i = 0; i < liste.length; i++) {
					if (groupe.idGroupe === liste[i].idGroupe) {
						index = i;
						break;
					}
				}
			}
			return index;
		}

		function addUserInDestinataire(groupe) {
			ctrl.groupesUserExclus = [];

			var indexGroupeCoche = getIndexFromListeForGroupe(ctrl.groupesUserCoches,groupe);
			if (groupe.checked) {
				if (indexGroupeCoche===-1) {
					ctrl.groupesUserCoches.push(groupe);
				}
			}
			else {
				if (indexGroupeCoche!==-1) {
					ctrl.groupesUserCoches.splice(indexGroupeCoche,1);
				}
			}
			ctrl.groupe.nbUserChecked = ctrl.groupesUserCoches.length;
		}

		function addUserPCSInDestinataire(groupe) {
			ctrl.groupesUserPCSExclus = [];

			var indexGroupeCoche = getIndexFromListeForGroupe(ctrl.groupesUserPCSCoches,groupe);
			if (groupe.checked) {
				if (indexGroupeCoche===-1) {
					ctrl.groupesUserPCSCoches.push(groupe);
				}
			}
			else {
				if (indexGroupeCoche!==-1) {
					ctrl.groupesUserPCSCoches.splice(indexGroupeCoche,1);
				}
			}
			ctrl.groupePCS.nbUserChecked = ctrl.groupesUserPCSCoches.length;
		}


		function addUserMCSInDestinataire(groupe) {
			ctrl.groupesUserMCSExclus = [];

			var indexGroupeCoche = getIndexFromListeForGroupe(ctrl.groupesUserMCSCoches,groupe);
			if (groupe.checked) {
				if (indexGroupeCoche===-1) {
					ctrl.groupesUserMCSCoches.push(groupe);
				}
			}
			else {
				if (indexGroupeCoche!==-1) {
					ctrl.groupesUserMCSCoches.splice(indexGroupeCoche,1);
				}
			}
			ctrl.groupeMCS.nbUserChecked = ctrl.groupesUserMCSCoches.length;
		}

		/**
		 * Lorsque l'on coche/decoche la case d'un groupe non user qui contient des user
		 * @param groupe
		 */
		function onChangeCheckboxGroupe(groupeMain) {

			delete ctrl.groupe.groupesUserExclus;

			ctrl.groupesUserExclus = [];
			ctrl.groupesUserCoches = [];

			ctrl.groupesUserPCSExclus = [];
			ctrl.groupesUserPCSCoches = [];

			ctrl.groupesUserMCSExclus = [];
			ctrl.groupesUserMCSCoches = [];

			if (!groupeMain.checked) {
				groupeMain.nbUserChecked = 0;

				// Si décoche le groupe CS
				if (groupeMain.isCS()) {
					ctrl.allGroupePCSChecked = false;
					ctrl.allGroupeChecked = false;

					ctrl.groupePCS.checked = false;		// Décoche le groupe PCS
					ctrl.groupeMCS.checked = false;		// Décoche le groupe MCS
					ctrl.groupePCS.nbUserChecked = 0;	// Passe à 0 le nombre d'user cochés du groupe PCS
					ctrl.groupeMCS.nbUserChecked = 0;	// Passe à 0 le nombre d'user cochés du groupe MCS
				}
				// Si décoche le groupe PCS
				else if (groupeMain.isPCS()) {
					ctrl.allGroupePCSChecked = false;
					ctrl.groupePCS.nbUserChecked = 0;

					// Si le groupe MCS n'a pas d'user coché
					if (ctrl.groupeMCS.nbUserChecked === 0) {
						ctrl.groupe.checked = false;	// Décoche le groupe CS
						ctrl.groupe.nbUserChecked = 0;	// Passe à 0 le nombre d'users du groupe CS
						ctrl.allGroupeChecked = false;
					}
					// Si le groupe MCS a des users cochés
					else {
						// Set le nombre d'user coché du groupe CS avec le nombre d'users cochés du groupe MCS
						ctrl.groupe.nbUserChecked = angular.copy(ctrl.groupeMCS.nbUserChecked);
					}
				}
				// Si décoche le groupe MCS
				else if (groupeMain.isMCS()) {
					ctrl.allGroupeMCSChecked = false;
					ctrl.groupeMCS.nbUserChecked = 0;

					// Si le groupe PCS n'a pas d'user coché
					if (ctrl.groupePCS.nbUserChecked === 0) {
						ctrl.groupe.checked = false;	// Décoche le groupe CS
						ctrl.groupe.nbUserChecked = 0;	// Passe à 0 le nombre d'users du groupe CS
						ctrl.allGroupeChecked = false;
					}
					// Si le groupe PCS a des users cochés
					else {
						// Set le nombre d'user coché du groupe CS avec le nombre d'users cochés du groupe PCS
						ctrl.groupe.nbUserChecked = angular.copy(ctrl.groupePCS.nbUserChecked);
					}
				}
				//ctrl.allGroupeChecked = false;
			}
			else {

				groupeMain.nbUserChecked = angular.copy(groupeMain.nbUserTotal);

				if (groupeMain.isCS()) {
					ctrl.allGroupeChecked = true;
					ctrl.allGroupePCSChecked = true;
					ctrl.allGroupeMCSChecked = true;
					ctrl.groupePCS.checked = true;		// Coche le groupe PCS
					ctrl.groupeMCS.checked = true;		// Coche le groupe MCS
					ctrl.groupePCS.nbUserChecked = angular.copy(ctrl.groupePCS.nbUserTotal);
					ctrl.groupeMCS.nbUserChecked = angular.copy(ctrl.groupeMCS.nbUserTotal);
				}
				else if (groupeMain.isPCS()) {
					ctrl.groupe.checked = true;			// Coche le groupe CS
					ctrl.allGroupePCSChecked = true;
					ctrl.groupe.nbUserChecked = groupeMain.nbUserTotal + ctrl.groupeMCS.nbUserChecked;

					// Si la somme des users cochés du groupe PCS et MCS est égale à la somme total des users du CS
					//if((groupeMain.nbUserChecked + ctrl.groupeMCS.nbUserChecked) === ctrl.groupe.nbUserTotal) {
					ctrl.allGroupeChecked = true;
					//}
				}
				else if (groupeMain.isMCS()) {
					ctrl.groupe.checked = true;			// Coche le groupe CS
					ctrl.allGroupeMCSChecked = true;
					ctrl.groupe.nbUserChecked = ctrl.groupePCS.nbUserChecked + groupeMain.nbUserTotal;

					// Si la somme des users cochés du groupe PCS et MCS est égale à la somme total des users du CS
					//if((ctrl.groupePCS.nbUserChecked + groupeMain.nbUserChecked) === ctrl.groupe.nbUserTotal) {
					ctrl.allGroupeChecked = true;
					//}
				}
				else {
					ctrl.groupe.checked = true;			// Coche le groupe
					ctrl.allGroupeChecked = true;
				}
			}

			if (groupeMain.isCS()) {
				// Coche/Decoche les groupes fils
				ctrl.groupeFilsPCS.map(function(groupe) {
					groupe.checked = angular.copy(groupeMain.checked);
					return groupe;
				});

				// Coche/Decoche les groupes fils
				ctrl.groupeFilsMCS.map(function(groupe) {
					groupe.checked = angular.copy(groupeMain.checked);
					return groupe;
				});


			}
			else if (groupeMain.isPCS()) {
				// Coche/Decoche les groupes fils
				ctrl.groupeFilsPCS.map(function(groupe) {
					groupe.checked = angular.copy(groupeMain.checked);
					return groupe;
				});

			}
			else if (groupeMain.isMCS()) {
				// Coche/Decoche les groupes fils
				ctrl.groupeFilsMCS.map(function(groupe) {
					groupe.checked = angular.copy(groupeMain.checked);
					return groupe;
				});

			}
			else{
				// Coche/Decoche les groupes fils
				ctrl.groupeFils.map(function(groupe) {
					groupe.checked = angular.copy(groupeMain.checked);
					return groupe;
				});
			}

			//recursiveChangeCheckboxGroupe(groupeMain,groupeMain.checked);
			//ctrl.groupe.nbUserChecked = recursiveCountGroupeFils(ctrl.groupe,0,true);
			onChangeComponent();
		}

		/**
		 * Lorsque l'on coche/decoche la case d'un groupe user
		 */
		function onChangeCheckboxGroupeUser(groupe) {

			// Si le groupe parent est coché alors quand on clique sur un user on gère des users exclus
			if (ctrl.groupe.checked) {

				if (ctrl.allGroupeChecked) {
					var indexGroupeExclu = getIndexFromListeForGroupe(ctrl.groupesUserExclus,groupe);
					if (!groupe.checked) {
						if (indexGroupeExclu===-1) {
							ctrl.groupesUserExclus.push(groupe);
						}
					}
					else {
						if (indexGroupeExclu!==-1) {
							ctrl.groupesUserExclus.splice(indexGroupeExclu,1);
						}
					}
					ctrl.groupe.nbUserChecked = ctrl.groupe.nbUserTotal - ctrl.groupesUserExclus.length;
				}
				else {
					addUserInDestinataire(groupe);
				}


			}
			else {
				ctrl.groupe.checked = true;
				addUserInDestinataire(groupe);
				ctrl.allGroupeChecked = false;
			}

			// Si tous les users sont cochés alors passe le allGroupeChecked à true
			if (ctrl.groupe.nbUserChecked === ctrl.groupe.nbUserTotal) {
				ctrl.allGroupeChecked = true;
			}
			else if (ctrl.groupe.nbUserChecked === 0) {
				ctrl.allGroupeChecked = false;
				ctrl.groupe.checked = false;
				ctrl.groupesUserExclus = [];
				ctrl.groupesUserCoches = [];
			}

			//ctrl.groupe.nbUserChecked = recursiveCountGroupeFils(ctrl.groupe,0,true);
			onChangeComponent();
			//console.log('groupesUserCoches',ctrl.groupesUserCoches);
			//console.log('groupesUserExclus',ctrl.groupesUserExclus);
		}

		/**
		 * Lorsque l'on coche/decoche la case d'un groupe user
		 */
		function onChangeCheckboxGroupeUserPCS(groupe) {

			// Si le groupe parent est coché alors quand on clique sur un user on gère des users exclus
			if (ctrl.groupePCS.checked) {

				if (ctrl.allGroupePCSChecked) {
					var indexGroupeExclu = getIndexFromListeForGroupe(ctrl.groupesUserPCSExclus,groupe);
					if (!groupe.checked) {
						if (indexGroupeExclu===-1) {
							ctrl.groupesUserPCSExclus.push(groupe);
						}
					}
					else {
						if (indexGroupeExclu!==-1) {
							ctrl.groupesUserPCSExclus.splice(indexGroupeExclu,1);
						}
					}
					ctrl.groupePCS.nbUserChecked = ctrl.groupePCS.nbUserTotal - ctrl.groupesUserPCSExclus.length;
				}
				else {
					addUserPCSInDestinataire(groupe);
				}


			}
			else {
				ctrl.groupe.checked = true;
				ctrl.groupePCS.checked = true;
				addUserPCSInDestinataire(groupe);

				// Si pas de MCS cochés
				if (ctrl.groupeMCS.nbUserChecked === 0) {
					ctrl.allGroupeChecked = false;
				}

				ctrl.allGroupePCSChecked = false;
			}

			ctrl.groupe.nbUserChecked = ctrl.groupePCS.nbUserChecked + ctrl.groupeMCS.nbUserChecked;

			// Si tous les users sont cochés alors passe le allGroupeChecked à true
			if (ctrl.groupePCS.nbUserChecked === ctrl.groupePCS.nbUserTotal) {
				ctrl.allGroupePCSChecked = true;

				// Si tous les PCS cochés
				if (ctrl.groupeMCS.nbUserChecked === ctrl.groupeMCS.nbUserTotal) {
					ctrl.allGroupeChecked = true;
				}

			}
			else if (ctrl.groupeMCS.nbUserChecked === 0) {
				ctrl.allGroupePCSChecked = false;
				ctrl.groupePCS.checked = false;
				ctrl.groupesUserPCSExclus = [];
				ctrl.groupesUserPCSCoches = [];

				// Si pas de MCS cochés
				if (ctrl.groupeMCS.nbUserChecked === 0) {
					ctrl.allGroupeChecked = false;
					ctrl.groupe.checked = false;
				}
			}

			//ctrl.groupe.nbUserChecked = recursiveCountGroupeFils(ctrl.groupe,0,true);
			onChangeComponent();
			// console.log('groupesUserPCSCoches',ctrl.groupesUserPCSCoches);
			// console.log('groupesUserPCSExclus',ctrl.groupesUserPCSExclus);
		}

		/**
		 * Lorsque l'on coche/decoche la case d'un groupe user
		 */
		function onChangeCheckboxGroupeUserMCS(groupe) {

			// Si le groupe parent est coché alors quand on clique sur un user on gère des users exclus
			if (ctrl.groupeMCS.checked) {

				if (ctrl.allGroupeMCSChecked) {
					var indexGroupeExclu = getIndexFromListeForGroupe(ctrl.groupesUserMCSExclus,groupe);
					if (!groupe.checked) {
						if (indexGroupeExclu===-1) {
							ctrl.groupesUserMCSExclus.push(groupe);
						}
					}
					else {
						if (indexGroupeExclu!==-1) {
							ctrl.groupesUserMCSExclus.splice(indexGroupeExclu,1);
						}
					}

					ctrl.groupeMCS.nbUserChecked = ctrl.groupeMCS.nbUserTotal - ctrl.groupesUserMCSExclus.length;
				}
				else {
					addUserMCSInDestinataire(groupe);

				}

			}
			else {
				ctrl.groupe.checked = true;
				ctrl.groupeMCS.checked = true;
				addUserMCSInDestinataire(groupe);

				// Si pas de PCS cochés
				if (ctrl.groupePCS.nbUserChecked === 0) {
					ctrl.allGroupeChecked = false;
				}

				ctrl.allGroupeMCSChecked = false;
			}

			ctrl.groupe.nbUserChecked = ctrl.groupePCS.nbUserChecked + ctrl.groupeMCS.nbUserChecked;

			// Si tous les users sont cochés alors passe le allGroupeChecked à true
			if (ctrl.groupeMCS.nbUserChecked === ctrl.groupeMCS.nbUserTotal) {
				ctrl.allGroupeMCSChecked = true;

				// Si tous les PCS cochés
				if (ctrl.groupePCS.nbUserChecked === ctrl.groupePCS.nbUserTotal) {
					ctrl.allGroupeChecked = true;
				}

			}
			else if (ctrl.groupeMCS.nbUserChecked === 0) {
				ctrl.allGroupeMCSChecked = false;
				ctrl.groupeMCS.checked = false;
				ctrl.groupesUserMCSExclus = [];
				ctrl.groupesUserMCSCoches = [];

				// Si pas de PCS cochés
				if (ctrl.groupePCS.nbUserChecked === 0) {
					ctrl.allGroupeChecked = false;
					ctrl.groupe.checked = false;
				}
			}

			//ctrl.groupe.nbUserChecked = recursiveCountGroupeFils(ctrl.groupe,0,true);
			onChangeComponent();
			// console.log('groupesUserMCSCoches',ctrl.groupesUserMCSCoches);
			// console.log('groupesUserMCSExclus',ctrl.groupesUserMCSExclus);
		}

		function onChangeComponent() {
			if(!noUpdate) {
				if (ctrl.onChange) {
					var obj = {
						obj: {
							groupesDestinataire: getDestinataires(),
							type: ctrl.type
						}
					};
					ctrl.onChange(obj);
				}
			}
			noUpdate = false;
		}

		function getDestinataires() {

			var destinataires = [];
			var indexDestinataire;

			// Si le groupe n'est pas le groupe CS
			if (!ctrl.groupe.isCS()){
				// Si le groupe est coché
				if (ctrl.groupe.checked) {
					// Si allGroupeChecked à true alors c'est le groupe qu'on ajoute et on exclu des users
					if (ctrl.allGroupeChecked) {

						if (ctrl.groupesUserExclus.length) {
							ctrl.groupe.groupesUserExclus = ctrl.groupesUserExclus;
						}

						destinataires.push(ctrl.groupe);
					}
					// Si allGroupeChecked à false alors on ajoute des users seulement et pas le groupe
					else {

						ctrl.groupesUserExclus = [];
						// Si il y a des users cochés
						if (ctrl.groupesUserCoches.length) {
							for (var i = 0; i < ctrl.groupesUserCoches.length; i++) {
								indexDestinataire = getIndexFromListeForGroupe(destinataires, ctrl.groupesUserCoches[i]);
								if (indexDestinataire === -1) {
									destinataires.push(ctrl.groupesUserCoches[i]);
								}
							}
						}
					}
				}
				else {
					ctrl.groupesUserExclus = [];
					ctrl.groupesUserCoches = [];
				}
			}
			else {
				// Si le groupe est coché
				if (ctrl.groupe.checked) {

					/*
					 var allPCS = false;
					 var allMCS = false;

					 // Si tous les preseident sont cochés
					 if (ctrl.groupePCS.nbUserChecked === ctrl.groupePCS.nbUserTotal) {
					 allPCS = true;
					 }

					 if (ctrl.groupeMCS.nbUserChecked === ctrl.groupeMCS.nbUserTotal) {
					 allMCS = true;
					 }*/

					// Si allGroupeChecked à true alors c'est le groupe qu'on ajoute et on exclu des users
					if (ctrl.allGroupeChecked) {

						// Si tout coché on prend le groupe CS
						if (ctrl.groupePCS.nbUserChecked === ctrl.groupePCS.nbUserTotal && ctrl.groupeMCS.nbUserChecked === ctrl.groupeMCS.nbUserTotal) {

							destinataires.push(ctrl.groupe);
						}
						// Sinon
						else {
							// Si tous le groupe PCS est coché, prend le groupe PCS, il y aura peut être des exclus
							if (ctrl.allGroupePCSChecked) {
								if (ctrl.groupesUserPCSExclus.length) {
									ctrl.groupePCS.groupesUserPCSExclus = ctrl.groupesUserPCSExclus;
								}

								destinataires.push(ctrl.groupePCS);
							}
							// Si allGroupePCSChecked à false alors on prends seulement les groupes users cochés
							else {
								for (var l = 0; l < ctrl.groupesUserPCSCoches.length; l++) {
									indexDestinataire = getIndexFromListeForGroupe(destinataires, ctrl.groupesUserPCSCoches[l]);
									if (indexDestinataire === -1) {
										destinataires.push(ctrl.groupesUserPCSCoches[l]);
									}
								}
							}

							// Si tous le groupe MCS est coché, prend le groupe MCS il y aura peut être des exclus
							if (ctrl.allGroupeMCSChecked) {
								if (ctrl.groupesUserMCSExclus.length) {
									ctrl.groupeMCS.groupesUserMCSExclus = ctrl.groupesUserMCSExclus;
								}

								destinataires.push(ctrl.groupeMCS);
							}
							// Si allGroupeMCSChecked à false alors on prends seulement les groupes users cochés
							else {
								for (var m = 0; m < ctrl.groupesUserMCSCoches.length; m++) {
									indexDestinataire = getIndexFromListeForGroupe(destinataires, ctrl.groupesUserMCSCoches[m]);
									if (indexDestinataire === -1) {
										destinataires.push(ctrl.groupesUserMCSCoches[m]);
									}
								}
							}
						}
					}
					// Si allGroupeChecked à false alors on ajoute des users seulement et pas le groupe
					else {

						ctrl.groupesUserPCSExclus = [];
						ctrl.groupesUserMCSExclus = [];
						for (var k = 0; k < ctrl.groupesUserPCSCoches.length; k++) {
							indexDestinataire = getIndexFromListeForGroupe(destinataires, ctrl.groupesUserPCSCoches[k]);
							if (indexDestinataire === -1) {
								destinataires.push(ctrl.groupesUserPCSCoches[k]);
							}
						}

						for (var j = 0; j < ctrl.groupesUserMCSCoches.length; j++) {
							indexDestinataire = getIndexFromListeForGroupe(destinataires, ctrl.groupesUserMCSCoches[j]);
							if (indexDestinataire === -1) {
								destinataires.push(ctrl.groupesUserMCSCoches[j]);
							}
						}
					}
				}
				else {
					ctrl.groupesUserPCSCoches = [];
					ctrl.groupesUserMCSCoches = [];
				}
			}

			/*
			 // Si le groupe est le groupe CS
			 if (ctrl.groupe.isCS()){
			 // Si le groupe PCS est coché
			 if (ctrl.groupePCS.checked) {

			 }
			 }*/

			//console.log('destinataires',destinataires);

			return destinataires;
		}

		/*
		 function getDestinataires() {

		 console.log(ctrl.groupe);
		 console.log(ctrl.groupeFils);
		 var destinataires = [];
		 // Si il y a des users checked
		 if (ctrl.groupe.nbUserChecked!=0) {
		 // Si le nombre d'user checked est égale au nombre d'user total
		 if (ctrl.groupe.nbUserChecked == ctrl.groupe.nbUserTotal) {
		 destinataires.push(ctrl.groupe);
		 }
		 else{
		 if (!ctrl.groupe.isCS()){
		 var groupeFils = ctrl.groupe.getGroupesFils();
		 if (groupeFils.length) {
		 for (var i = 0; i < groupeFils.length; i++) {
		 if (groupeFils[i].checked) {
		 destinataires.push(groupeFils[i]);
		 }
		 }
		 }
		 }
		 else{
		 var groupeFilsPCS = ctrl.groupePCS.getGroupesFils(),
		 groupeFilsMCS = ctrl.groupeMCS.getGroupesFils(),
		 groupesUserPCSChecked = [],
		 groupesUserMCSChecked = [],
		 allPCSChecked = false,
		 allMCSChecked = false;

		 if (groupeFilsPCS.length) {
		 for (var p = 0; p < groupeFilsPCS.length; p++) {
		 if (groupeFilsPCS[p].checked) {
		 groupesUserPCSChecked.push(groupeFilsPCS[p]);
		 }
		 }
		 }

		 if (groupeFilsMCS.length) {
		 for (var q = 0; q < groupeFilsMCS.length; q++) {
		 if (groupeFilsMCS[q].checked) {
		 groupesUserMCSChecked.push(groupeFilsMCS[q]);
		 }
		 }
		 }

		 if (groupesUserPCSChecked.length!=0) {
		 // Si le nombre total de PCS est égale au nombre de PCS coché
		 if (groupeFilsPCS.length == groupesUserPCSChecked.length) {allPCSChecked = true;}
		 // Sinon alors il faut recup les groupes destinataires
		 else{
		 for(var r = 0 ; r < groupesUserPCSChecked.length ; r++) {
		 destinataires.push(groupesUserPCSChecked[r]);
		 }
		 }
		 }

		 if (groupesUserMCSChecked.length!=0) {
		 // Si le nombre total de MCS est égale au nombre de MCS coché
		 if (groupeFilsMCS.length == groupesUserMCSChecked.length) {allMCSChecked = true;}
		 // Sinon alors il faut recup les groupes destinataires
		 else{
		 for(var s = 0 ; s < groupesUserMCSChecked.length ; s++) {
		 destinataires.push(groupesUserMCSChecked[s]);
		 }
		 }
		 }

		 // Si tout les PCS et MCS coché alors on prend le groupe CS entier
		 if (allPCSChecked && allMCSChecked) {
		 destinataires.push(ctrl.groupe);
		 }
		 else{
		 // Si tous les users du groupe PCS sont cochés alors prend le groupe PCS
		 if (allPCSChecked) {destinataires.push(ctrl.groupePCS);}
		 // Si tous les users du groupe MCS sont cochés alors prend le groupe MCS
		 if (allMCSChecked) {destinataires.push(ctrl.groupeMCS);}
		 }
		 }
		 }
		 }
		 console.log(destinataires);

		 return destinataires;
		 }*/
		/*
		 function countUserChecked(groupe) {
		 if (!groupe) {
		 groupe = ctrl.groupe;
		 }
		 return groupe.getGroupeInformations().nbGroupesUser - ctrl.groupesUserExclus.length;
		 }*/

		/**
		 * Permet de changer l'etat de la checkbox recursivement
		 * @param groupe
		 * @param etat
		 */
		/*
		 function recursiveChangeCheckboxGroupe(groupe,etat) {
		 var groupeFils = groupe.getGroupesFils();
		 if (groupeFils.length) {
		 for (var i = 0; i < groupeFils.length; i++) {
		 groupeFils[i].checked = etat;
		 if (groupeFils[i].getGroupesFils().length) {
		 recursiveChangeCheckboxGroupe(groupeFils[i],etat);
		 }
		 }
		 }
		 }*/

		/**
		 * Permet de compter recursivement les groupes user
		 * @param groupe
		 * @param nb
		 * @param withChecked
		 * @returns {*}
		 */
		/*
		 function recursiveCountGroupeFils(groupe,nb,withChecked) {

		 if (groupe.isUser()){
		 if (withChecked) {
		 if (groupe.checked) {
		 nb++;
		 }
		 }
		 else{
		 nb++;
		 }
		 }else{
		 if (!groupe.isUser()) {
		 var groupeFils = groupe.getGroupesFils();

		 if (groupeFils.length) {
		 for (var i = 0; i < groupeFils.length; i++) {
		 if (groupeFils[i].isUser()) {
		 if (withChecked) {
		 if (groupeFils[i].checked) {
		 nb++;
		 }
		 }
		 else {
		 nb++;
		 }
		 }
		 else {
		 if (groupeFils[i].getGroupesFils().length) {
		 nb = recursiveCountGroupeFils(groupeFils[i], nb, withChecked);
		 }
		 }
		 }
		 }
		 }
		 }
		 return nb;
		 }*/

		/**
		 * Permet de compter le nombre de foupe user checked dans un groupe
		 * @param groupe
		 * @returns {number}
		 */
		/*
		 function countGroupeUserChecked(groupe) {
		 return recursiveCountGroupeFils(groupe,0,true);
		 }*/

		/**
		 * Permet de compte le nombre groupe user au total dans un groupe
		 * @param groupe
		 * @returns {number}
		 */
		/*
		 function countGroupeUserTotal(groupe) {
		 return recursiveCountGroupeFils(groupe,0,false);
		 }
		 */

		function getGroupeFils(idGroupe) {


			if (ctrl.groupe.isCS()){

				ctrl.groupeFilsPCS = [];
				ctrl.groupeFilsMCS = [];
				ctrl.loadingGroupeFilsPCS = true;
				ctrl.loadingGroupeFilsMCS = true;

				GroupesService
					.getGroupesFils(ctrl.groupePCS.idGroupe,ctrl.paginationPCS.currentPage,ctrl.paginationPCS.limit)
					.then(function(objRetour) {
						ctrl.paginationPCS.totalItem = objRetour.total;

						objRetour.groupes.map(function(groupe) {

							var check = false;

							// Si groupe parent coché gère les exclus
							if (ctrl.groupe.checked) {

								if (ctrl.allGroupePCSChecked) {
									// Cherche si le groupe est exclu
									var indexGroupeExclu = getIndexFromListeForGroupe(ctrl.groupesUserPCSExclus,groupe);
									// Si pas exclu on le coche
									if (indexGroupeExclu === -1) {
										check = true;
									}
								}
								else {
									// Cherche si le groupe est coche
									var indexGroupeCoche = getIndexFromListeForGroupe(ctrl.groupesUserPCSCoches,groupe);
									// Si pas coche on le coche
									if (indexGroupeCoche !== -1) {
										check = true;
									}
								}

							}

							groupe.checked = check;

							return groupe;
						});
						ctrl.groupeFilsPCS = objRetour.groupes;
					})
					.finally(function() {
						ctrl.loadingGroupeFilsPCS = false;
					});


				GroupesService
					.getGroupesFils(ctrl.groupeMCS.idGroupe,ctrl.paginationMCS.currentPage,ctrl.paginationMCS.limit)
					.then(function(objRetour) {
						ctrl.paginationMCS.totalItem = objRetour.total;

						objRetour.groupes.map(function(groupe) {

							var check = false;

							// Si groupe parent coché gère les exclus
							if (ctrl.groupe.checked) {

								if (ctrl.allGroupeMCSChecked) {
									// Cherche si le groupe est exclu
									var indexGroupeExclu = getIndexFromListeForGroupe(ctrl.groupesUserMCSExclus,groupe);
									// Si pas exclu on le coche
									if (indexGroupeExclu === -1) {
										check = true;
									}
								}
								else {
									// Cherche si le groupe est coche
									var indexGroupeCoche = getIndexFromListeForGroupe(ctrl.groupesUserMCSCoches,groupe);
									// Si pas coche on le coche
									if (indexGroupeCoche !== -1) {
										check = true;
									}
								}

							}

							groupe.checked = check;

							return groupe;
						});
						ctrl.groupeFilsMCS = objRetour.groupes;
					})
					.finally(function() {
						ctrl.loadingGroupeFilsMCS = false;
					});

			}
			else {

				ctrl.groupeFils = [];
				ctrl.loadingGroupeFils = true;

				GroupesService
					.getGroupesFils(idGroupe,ctrl.pagination.currentPage,ctrl.pagination.limit)
					.then(function(objRetour) {
						ctrl.pagination.totalItem = objRetour.total;
						objRetour.groupes.map(function(groupe) {

							var check = false;

							// Si groupe parent coché gère les exclus
							if (ctrl.groupe.checked) {

								if (ctrl.allGroupeChecked) {
									// Cherche si le groupe est exclu
									var indexGroupeExclu = getIndexFromListeForGroupe(ctrl.groupesUserExclus,groupe);
									// Si pas exclu on le coche
									if (indexGroupeExclu === -1) {
										check = true;
									}
								}
								else {
									// Cherche si le groupe est coche
									var indexGroupeCoche = getIndexFromListeForGroupe(ctrl.groupesUserCoches,groupe);
									// Si pas coche on le coche
									if (indexGroupeCoche !== -1) {
										check = true;
									}
								}

							}

							groupe.checked = check;

							return groupe;
						});
						ctrl.groupeFils = objRetour.groupes;
					})
					.finally(function() {
						ctrl.loadingGroupeFils = false;
					});
			}


		}

		function onOpenGroupe() {
			ctrl.show = !ctrl.show;
			if (ctrl.show) {
				getGroupeFils(ctrl.groupe.getIdGroupe());
			}
		}

		function onChangePage() {
			getGroupeFils(ctrl.groupe.getIdGroupe());
		}

		function onCliqueExtranet(groupe) {

			ctrl.loadingPartageExtranet = true;
			sweet.show({
				title: 'Attention !',
				text: '<h4 class="text-danger">Vous allez partager cet événement avec <strong>TOUS</strong> les <strong>'+groupe.getDefaultNom()+'</strong> de l\'immeuble !</h4><br />Attention cette action est irréversible !',
				type: 'warning',
				html: true,
				showCancelButton: true,
				//confirmButtonColor: '#DD6B55',
				confirmButtonText: 'Confimer',
				closeOnConfirm: true,
				showLoaderOnConfirm: true
			},function(isConfirm) {

				if (isConfirm) {
					if (ctrl.onChange) {
						ctrl.onChange({'obj': { 'partageExtranet' : [ctrl.groupe]}}).then(function() {
							ctrl.isInEvent = true;
						},function() {
							ctrl.isInEvent = false;
						}).finally(function() {
							ctrl.loadingPartageExtranet = false;
						});
					}
				}else{
					$scope.$apply(function() {
						ctrl.isInEvent = false;
						ctrl.loadingPartageExtranet = false;
					});
				}
			});
		}
	}



})();
