(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.DashboardService
   * @description
   * # DashboardService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('DashboardService',
      DashboardServiceController
    );

  /** @ngInject */
  function DashboardServiceController($rootScope,MainService, $q, DashboardAjax, ModalsService, UtilsService, ProxyTag, Reporting, Topic) {
    var ctrl = this;
    ctrl.searchInfosDashboard = searchInfosDashboard;
    ctrl.searchReportingDashboard = searchReportingDashboard;
    ctrl.downloadExcelReporting = downloadExcelReporting;
    ctrl.searchReportingCampingDashboard = searchReportingCampingDashboard;

    ctrl.searchReportingCapfun = searchReportingCapfun;
    ctrl.searchStatsCampingDashboard = searchStatsCampingDashboard;
    ctrl.downloadExcelReportingCapfun = downloadExcelReportingCapfun;

    function setParamsSearchReporting(params){
      var paramsSearch = {
        order: params.order,
        sens:  params.sens,
        limit: params.limit,
        page:  params.page,
        tags: [],
        listeFournisseurMail: [],
        listeFournisseurPersoICS: []
      };

      if(params.tag){
        paramsSearch.idTag = params.tag.idTag;
      }
      else if(_.isArray(params.tags) && params.tags.length){
        for(var i = 0; i < params.tags.length; i++){
          paramsSearch.tags.push(angular.toJson(new ProxyTag(params.tags[i])));
        }
      }

      if(_.isArray(params.listeFournisseurMail) && params.listeFournisseurMail.length){
        for(var m = 0; m < params.listeFournisseurMail.length; m++){
          paramsSearch.listeFournisseurMail.push(params.listeFournisseurMail[m]);
        }
      }

      if(_.isArray(params.listeFournisseurPersoICS) && params.listeFournisseurPersoICS.length){
        var ProxyPersoIcs = MainService.$injector.get('ProxyPersoIcs');
        for(var p = 0; p < params.listeFournisseurPersoICS.length; p++){
          paramsSearch.listeFournisseurPersoICS.push(angular.toJson(new ProxyPersoIcs(params.listeFournisseurPersoICS[p])));
        }
        ProxyPersoIcs = null;
      }

      if(_.isArray(params.idPortefeuilles)) paramsSearch.idPortefeuilles = params.idPortefeuilles;
      if(_.isArray(params.typesIntervenant)) paramsSearch.typesIntervenant = params.typesIntervenant;

      if(_.isObject(params.filtre)){
        if(params.filtre.idChannel) paramsSearch.idChannel = params.filtre.idChannel;
        if(params.filtre.topicSansEcheance) paramsSearch.topicSansEcheance = true;
        if(!_.isNil(params.filtre.etat)) paramsSearch.etat = params.filtre.etat;
        if(!_.isNil(params.filtre.classe)) paramsSearch.classe = params.filtre.classe;
        if(params.filtre.demandeDevis) paramsSearch.demandeDevis = true;
        if(params.filtre.demandeDevisNonValide) paramsSearch.demandeDevisNonValide = true;
        if(params.filtre.demandeIntervention) paramsSearch.demandeIntervention = true;
        if(params.filtre.demandeContrat) paramsSearch.demandeContrat = true;
        if(params.filtre.contratNonRecu) paramsSearch.contratNonRecu = true;
        if(params.filtre.evenementAvecSinistre) paramsSearch.evenementAvecSinistre = true;
        if(params.filtre.evenementSansSinistre) paramsSearch.evenementSansSinistre = true;
        if(params.filtre.demandeDevisAvecFacture) paramsSearch.demandeDevisAvecFacture = true;
        if(params.filtre.demandeDevisAvecFactureNonTraitee) paramsSearch.demandeDevisAvecFactureNonTraitee = true;
        if(params.filtre.avecContenuNonLu) paramsSearch.avecContenuNonLu = true;
        if(params.filtre.cloture) paramsSearch.cloture = true;
        if(params.filtre.nonCloture) paramsSearch.nonCloture = true;
        if(!_.isNil(params.filtre.cloturer)) paramsSearch.cloturer = params.filtre.cloturer;
        //if(params.filtre.nonArchive) paramsSearch.nonArchive = true;
        if(_.isArray(params.filtre.listCategorieTopic)) {
          paramsSearch.listIdCategorieTopic = params.filtre.listCategorieTopic.map(function(cat){
            return cat.idCategorieTopic;
          });
        }

        if(_.isArray(params.filtre.tags) && params.filtre.tags.length){
          for(var i = 0; i < params.filtre.tags.length; i++){
            paramsSearch.tags.push(angular.toJson(new ProxyTag(params.filtre.tags[i])));
          }
        }

        if(_.isArray(params.filtre.tagsStatut) && params.filtre.tagsStatut.length){
          paramsSearch.tagsStatut = [];
          params.filtre.tagsStatut.map(function(tag){
            paramsSearch.tagsStatut.push(angular.toJson(new ProxyTag(tag)));
          });
        }

        if(_.isArray(params.filtre.idPortefeuilles)) paramsSearch.idPortefeuilles = params.filtre.idPortefeuilles;
        if(_.isArray(params.filtre.typesIntervenant)) paramsSearch.typesIntervenant = params.filtre.typesIntervenant;

        if(params.filtre.dureePeriode) paramsSearch.dureePeriode = params.filtre.dureePeriode;
        else{

          if(_.isObject(params.filtre.dateCreation.startDate) && params.filtre.dateCreation.startDate._isAMomentObject) paramsSearch.debutCreation = UtilsService.dateFormat(params.filtre.dateCreation.startDate.format(),'yyyy-MM-dd');
          else if(_.isString(params.filtre.dateCreation.startDate)) paramsSearch.debutCreation = UtilsService.dateFormat(params.filtre.dateCreation.startDate,'yyyy-MM-dd');

          if(_.isObject(params.filtre.dateCreation.endDate) && params.filtre.dateCreation.endDate._isAMomentObject) paramsSearch.finCreation = UtilsService.dateFormat(params.filtre.dateCreation.endDate.format(),'yyyy-MM-dd');
          else if(_.isString(params.filtre.dateCreation.endDate)) paramsSearch.finCreation = UtilsService.dateFormat(params.filtre.dateCreation.endDate,'yyyy-MM-dd');


          //if(params.filtre.dateCreation.startDate!==null){
          //  if(params.filtre.dateCreation.startDate._isAMomentObject) params.filtre.dateCreation.startDate = params.filtre.dateCreation.startDate.format();
          //  paramsSearch.debutCreation = UtilsService.dateFormat(params.filtre.dateCreation.startDate,'yyyy-MM-dd');
          //}
          //if(params.filtre.dateCreation.endDate!==null){
          //  if(params.filtre.dateCreation.endDate._isAMomentObject) params.filtre.dateCreation.endDate = params.filtre.dateCreation.endDate.format();
          //  paramsSearch.finCreation = UtilsService.dateFormat(params.filtre.dateCreation.endDate,'yyyy-MM-dd');
          //}
        }

        //if(_.isObject(params.filtre.dateEcheance.startDate)){
        //  if(params.filtre.dateEcheance.startDate._isAMomentObject) params.filtre.dateEcheance.startDate = params.filtre.dateEcheance.startDate.format();
        //  paramsSearch.debutEcheance = UtilsService.dateFormat(params.filtre.dateEcheance.startDate,'yyyy-MM-dd');
        //}

        if(_.isObject(params.filtre.dateCloture)){
          if(_.isObject(params.filtre.dateCloture.startDate) && params.filtre.dateCloture.startDate._isAMomentObject) paramsSearch.dateClotureDebut = UtilsService.dateFormat(params.filtre.dateCloture.startDate.format(),'yyyy-MM-dd');
          else if(_.isString(params.filtre.dateCloture.startDate)) paramsSearch.dateClotureDebut = UtilsService.dateFormat(params.filtre.dateCloture.startDate,'yyyy-MM-dd');

          if(_.isObject(params.filtre.dateCloture.endDate) && params.filtre.dateCloture.endDate._isAMomentObject) paramsSearch.dateClotureFin = UtilsService.dateFormat(params.filtre.dateCloture.endDate.format(),'yyyy-MM-dd');
          else if(_.isString(params.filtre.dateCloture.endDate)) paramsSearch.dateClotureFin = UtilsService.dateFormat(params.filtre.dateCloture.endDate,'yyyy-MM-dd');
        }

        if(_.isObject(params.filtre.dateEcheance)){
          if(_.isObject(params.filtre.dateEcheance.startDate) && params.filtre.dateEcheance.startDate._isAMomentObject) paramsSearch.debutEcheance = UtilsService.dateFormat(params.filtre.dateEcheance.startDate.format(),'yyyy-MM-dd');
          else if(_.isString(params.filtre.dateEcheance.startDate)) paramsSearch.debutEcheance = UtilsService.dateFormat(params.filtre.dateEcheance.startDate,'yyyy-MM-dd');

          if(_.isObject(params.filtre.dateEcheance.endDate) && params.filtre.dateEcheance.endDate._isAMomentObject) paramsSearch.finEcheance = UtilsService.dateFormat(params.filtre.dateEcheance.endDate.format(),'yyyy-MM-dd');
          else if(_.isString(params.filtre.dateEcheance.endDate)) paramsSearch.finEcheance = UtilsService.dateFormat(params.filtre.dateEcheance.endDate,'yyyy-MM-dd');
        }


        if(params.filtre.hasOwnProperty('filtreExpediteurs') && _.isArray(params.filtre.filtreExpediteurs) && params.filtre.filtreExpediteurs.length) {

          paramsSearch.idGroupeExpediteurs = params.filtre.filtreExpediteurs.map(function(obj){
            return obj.idGroupe;
          });
        }

      }

      return paramsSearch;
    }

    /**
     * Recherche accueil dashboard
     */
    function searchInfosDashboard(filtre){
      var deferred = $q.defer();
      try {

        var param = {
          action: 'searchInfosDashboard'
        };
        if(filtre) param = angular.merge(param, filtre);
        DashboardAjax
          .post(param, function (json) {
            var objRetour = {
              infosDashboard: MainService.convertJsonToModel(json, 'infosDashboard', 'InfosDashboard', deferred),
              resultElasticSearch: (!_.isNil(json.resultElasticSearch)) ? json.resultElasticSearch : false,
              asynchrone: (!_.isNil(json.asynchrone)) ? json.asynchrone : false
            };

            $rootScope.current.resultElasticSearch = objRetour.resultElasticSearch;

            deferred.resolve(objRetour);
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Recherche reporting
     * @param params
     */
    function searchReportingDashboard(params){
      var deferred = $q.defer();

      var paramsSearch = setParamsSearchReporting(params);
      paramsSearch.action = 'searchReportingDashboard';
      DashboardAjax
        .post(paramsSearch, function (json) {
          if(json.success) {
            var retour = {
              nb:           parseInt(json.nb),
              total:        parseInt(json.total),
              liste_events: json.reportingDashboard.liste.map(function(item){
                              return new Reporting(item);
                            }),
              infos: json.reportingDashboard.infos,
              resultElasticSearch: (!_.isNil(json.resultElasticSearch)) ? json.resultElasticSearch : false
            };
            $rootScope.current.resultElasticSearch = retour.resultElasticSearch;
            deferred.resolve(retour);
          }
          else {
            ModalsService.alertErreur(json.message);
            deferred.reject(json);
          }

        }, function (msg) {
          deferred.reject(msg);
        });

      return deferred.promise;
    }

    /**
     * Permet de télécharger un fichier excel pour le reporting
     * @param params
     * @returns {Promise}
     */
    function downloadExcelReporting(params) {
      var deferred = $q.defer();

      var paramsSearch = setParamsSearchReporting(params);
      paramsSearch.action = 'downloadReportingExportEXCEL';
      paramsSearch.exportEXCEL = true;

      DashboardAjax.downloadExcelReporting(
        paramsSearch, function(data) {
          deferred.resolve(data);
        }, function(error) {
          deferred.reject('Problème avec DashboardService.downloadExcelReporting(), Erreur (' + error.status + ') : ' + error.statusText);
        }
      );
      return deferred.promise;
    }

    /**
     * Permet de télécharger un fichier excel pour le reporting
     * @param params
     * @returns {Promise}
     */
    function downloadExcelReportingCapfun(params) {
      var deferred = $q.defer();

      var paramsSearch = setParamsSearchReporting(params);
      paramsSearch.action = 'downloadReportingCapfunExportEXCEL';
      paramsSearch.exportEXCEL = true;

      DashboardAjax.downloadExcelReporting(
        paramsSearch, function(data) {
          deferred.resolve(data);
        }, function(error) {
          deferred.reject('Problème avec DashboardService.downloadExcelReportingCapfun(), Erreur (' + error.status + ') : ' + error.statusText);
        }
      );
      return deferred.promise;
    }

    /**
     * Recherche reporting
     * @param params
     */
    function searchReportingCampingDashboard(params){
      var deferred = $q.defer();

      var paramsSearch = setParamsSearchReporting(params);
      paramsSearch.action = 'searchReportingCampingDashboard';

      DashboardAjax
        .post(paramsSearch, function (json) {
          deferred.resolve(MainService.convertJsonToModel(json, 'result', 'InfosReportingCampingDashboard', deferred));
        }, function (msg) {
          deferred.reject(msg);
        });

      return deferred.promise;
    }

    /**
     * Recherche reporting capfun
     * @param params
     */
    function searchReportingCapfun(params){
      var deferred = $q.defer();

      var paramsSearch = setParamsSearchReporting(params);
      paramsSearch.action = 'searchReportingCapfun';
      DashboardAjax
        .post(paramsSearch, function (json) {
          if(json.success) {
            var retour = {
              nb:           parseInt(json.nb),
              total:        parseInt(json.reportingDashboard.nbTotal),
              topics: json.reportingDashboard.listTopic.map(function(topic){
                var topicInstance = new Topic(topic);
                topicInstance.groupeDestinataireMenage = topicInstance.getDestinataireMenage();
                topicInstance.groupeDestinataireTechnique = topicInstance.getDestinataireTechnique();
                return topicInstance;
              }),
              infos: json.reportingDashboard.infos,
              resultElasticSearch: (!_.isNil(json.resultElasticSearch)) ? json.resultElasticSearch : false
            };
            $rootScope.current.resultElasticSearch = retour.resultElasticSearch;
            deferred.resolve(retour);
          }
          else ModalsService.alertErreur(json.message);
        }, function (msg) {
          deferred.reject(msg);
        });
      return deferred.promise;
    }


    function searchStatsCampingDashboard(params){
      var deferred = $q.defer();

      var paramsSearch = setParamsSearchReporting(params);
      paramsSearch.action = 'searchStatsCampingDashboard';

      DashboardAjax
        .post(paramsSearch, function (json) {
          deferred.resolve(MainService.convertJsonToModel(json, 'result', 'InfosReportingCampingDashboard', deferred));
        }, function (msg) {
          deferred.reject(msg);
        });

      return deferred.promise;
    }


  }

})();
