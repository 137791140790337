(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.MatchedLineItemsReceipt
   * @description
   * # MatchedLineItemsReceipt
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('MatchedLineItemsReceipt', MatchedLineItemsReceiptModel);

  /** @ngInject */
  function MatchedLineItemsReceiptModel(Main, ReceiptLineItemItem) {

    function MatchedLineItemsReceipt(data) {
      Main.apply(this, arguments);
      this.hydrate(data);
    }

    MatchedLineItemsReceipt.prototype = {

      model: 'MatchedLineItemsReceipt',
      isMatchedLineItemsReceipt: true,

      // id
      getId: function(){
        return this.id;
      },
      setId: function(val){
        this.id = val;
      },

      // lineitems
      getLineitems: function(){
        return this.lineitems;
      },
      setLineitems: function(list){
        if(_.isArray(list)){
          list = list.map(function(item) {
            if(!item.isModel) item = new ReceiptLineItemItem(item);
            return item;
          });
        }
        this.lineitems = list;
      }

    };

    angular.extend(MatchedLineItemsReceipt.prototype, Main.prototype);

    return MatchedLineItemsReceipt;
  }
})();
