'use strict';

angular.module('collaboreApp').factory('Lot',[
	'Immeuble',
	'Typelot',
	'Personnalite',
	'Bail',
	function(Immeuble, Typelot, Personnalite, Bail) {

		function Lot(objetData) {


			if (objetData) {
				this.setData(objetData);
				this.isLot = true;
			}
		}

		Lot.prototype = {


			isModel: true,

			setData: function(objetData) {
				angular.extend(this, objetData);
				if(_.isObject(objetData)){
					if(objetData.hasOwnProperty('immeuble')){
						if(objetData.immeuble.hasOwnProperty('nomimme')){
							this.setImmeuble(objetData.immeuble);
						}else{
							this.setImmeuble(false);
						}
					}
				}
			},

			// Type
			getType: function(){
				return this._type;
			},
			setType: function(val){
				this._type = val;
			},

			// Numéro d'immeuble
			getNumImmeuble: function(){
				return this.iiii;
			},
			setNumImmeuble: function(val){
				this.iiii = val;
			},

			// Numéro de lot
			getNumLot: function(){
				return this.llll;
			},
			setNumLot: function(val){
				this.llll = val;
			},

			// Numéro de mandat
			getNumMandat: function(){
				return this.mmmm;
			},
			setNumMandat: function(val){
				this.mmmm = val;
			},

			// Numéro de batiment
			getNumBatiment: function(){
				return this.nobat;
			},
			setNumBatiment: function(val){
				this.nobat = val;
			},

			// Escalier
			getEscalier: function(){
				return this.escal;
			},
			setEscalier: function(val){
				this.escal = val;
			},

			// Etage
			getEtage: function(){
				return this.etage;
			},
			setEtage: function(val){
				this.etage = val;
			},

			// Porte
			getPorte: function(){
				return this.porte;
			},
			setPorte: function(val){
				this.porte = val;
			},

			// nopla
			// codty

			// Nombre de pièces
			getNbPiece: function(){
				if(!_.isNil(this.nbpie) && this.nbpie!==''){
					return parseInt(this.nbpie);
				}else{
					return this.nbpie;
				}

			},
			setNbPiece: function(val){
				this.nbpie = val;
			},

			// Surface
			getSurface: function(){
				if(!_.isNil(this.surfa) && this.surfa!==''){
					if(_.isString(this.surfa)){
						this.setSurface(parseInt(this.surfa));
					}
				}
				return this.surfa;
			},
			setSurface: function(val){
				this.surfa = val;
			},

			// codcat
			// loprin
			// fdroul
			// codloi
			// pouloi

			// Numéro de perso du propriétaire
			getNumPersoProprietaire: function(){
				return this.noperpro;
			},
			setNumPersoProprietaire: function(val){
				this.noperpro = val;
			},

			// Numéro de perso du locataire
			getNumPersoLocataire: function(){
				return this.noperloc;
			},
			setNumPersoLocataire: function(val){
				this.noperloc = val;
			},

			// codeassur
			// pronumbai
			// refcad
			// revcad

			// Annee
			getAnnee: function(){
				return this.annee;
			},
			setAnnee: function(val){
				this.annee = val;
			},

			// fdres
			// codevl
			// surfar

			// Sinistre
			getSinistre: function(){
				return this.sinistre;
			},
			setSinistre: function(val){
				this.sinistre = val;
			},

			// Numéro de lot
			getNoLot: function(){
				return this.nolot;
			},
			setNoLot: function(val){
				this.nolot = val;
			},


			getImmeuble: function(){
				return this.immeuble;
			},
			setImmeuble: function(val){
				// Si "val" est un string
				if(_.isString(val)){
					val = new Immeuble({'nomimme':val});
				}else{
					// Si val est un objet mais qu'il n'est pas un model
					if(!val.isModel){
						// Si val a l'attribut "nomimme"
						if(val.hasOwnProperty('nomimme')){
							val = new Immeuble(val);
						}else{
							val = false;
						}
					}
				}


				this.immeuble = val;
			},

			getTypelot: function(){
				return this.typelot;
			},
			setTypelot: function(val){

				// Si "val" est un string
				if(_.isString(val)){
					val = new Typelot({'libelleType':val});
				}else{
					// Si val est un objet mais qu'il n'est pas un model
					if(!val.isModel){
						// Si val a l'attribut "nomimme"
						if(val.hasOwnProperty('libelleType')){
							val = new Typelot(val);
						}else{
							val = false;
						}
					}
				}

				this.typelot = val;
			},

			getLibelleTypeLot: function(){
				if(this.getTypelot()){
					return this.getTypelot().getLibelleType();
				}else{
					return '';
				}
			},

			// Propriétaire
			getProprietaire: function(){
				return this.proprietaire;
			},
			setProprietaire: function(val){
				// Si "val" est un string
				if(_.isString(val)){
					val = new Personnalite({'nomper':val});
				}else{
					// Si val est un objet mais qu'il n'est pas un model
					if(!val.isModel){
						// Si val a l'attribut "nomper"
						if(val.hasOwnProperty('nomper')){
							val = new Personnalite(val);
						}else{
							val = false;
						}
					}
				}

				this.proprietaire = val;
			},


			// Nom du propriétaire
			getNomProprietaire: function(){
				return this.getProprietaire().getNomper();
			},

			getBail: function(){
				return this.bail;
			},
			setBail: function(val){

				// Si "val" est un string
				if(_.isObject(val)){
					// Si val est un objet mais qu'il n'est pas un model
					if(!val.isModel){
						// Si val a l'attribut "dateproch"
						if(val.hasOwnProperty('dateproch')){
							val = new Bail(val);
						}else{
							val = false;
						}
					}
				}

				this.bail = val;
			}

		};

		return Lot;
	}
]);
