(function() {

  'use strict';

  /**
   * @desc Composant gerer mes portefeuilles
   * @example <admin-gestion-des-portefeuilles></admin-gestion-des-portefeuilles>
   */
  angular
    .module('collaboreApp')
    .component('adminGestionDesPortefeuilles',{
      templateUrl: 'app/parametrage/admin/gestion-des-portefeuilles/admin-gestion-des-portefeuilles.component.html',
      controllerAs: 'admingestiondesportefeuillesctrl',
      controller: AdminGestionDesPortefeuillesCtrl,
      bindings: {
        societe: '<'
      }
    });


  /*@ngInject*/
  function AdminGestionDesPortefeuillesCtrl(COLLAB_CONF,COLLAB_VALUES,$rootScope,sweet,ErreurCollabService,GroupesAdminService,FileUploader,Portefeuille){
    var ctrl = this;
    ctrl.uploaderEntete = false;
    ctrl.urlEntete = COLLAB_VALUES.CONF_URL.URL_ENTETES;
    ctrl.idGroupeAll = null;


    ctrl.onSubmitPortefeuille = onSubmitPortefeuille;
    ctrl.onClickAddPortefeuille = onClickAddPortefeuille;
    ctrl.onClickCancelPortefeuille = onClickCancelPortefeuille;
    ctrl.onClickChangeEntete = onClickChangeEntete;
    ctrl.onClickDeleteEntete = onClickDeleteEntete;
    ctrl.onClickEditPortefeuille = onClickEditPortefeuille;
    ctrl.onClickDeletePortefeuille = onClickDeletePortefeuille;
    ctrl.loading = {
      'show': false,
      'content': COLLAB_CONF.MIDDLE_LOADING
    };

    ctrl.$onInit = function(){
      initVarCurrentPortefeuille();

      var idGroupe = null;
      if(angular.isObject(ctrl.societe) && ctrl.societe.hasOwnProperty('idGroupe')) ctrl.idGroupeAll = ctrl.societe.idGroupe;

      if(angular.isUndefined(ctrl.portefeuilles)){
        ctrl.loading.show = true;
        GroupesAdminService.getAllPortefeuilles(ctrl.idGroupeAll).then(function(portefeuilles){
          ctrl.portefeuilles = portefeuilles;
        }).finally(function(){
          ctrl.loading.show = false;
        });
      }

      /**
       * Initialisation et configuration de l'uploader
       */

      ctrl.uploaderEntete = new FileUploader({
        autoUpload: false,
        queueLimit:1,
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_ENTETE_PORTEFEUILLE_INFOS_ADMIN,
        alias: 'fileName',
        removeAfterUpload : false,
        filters: [
          {
            name: 'sizeLimit',
            fn: function(item) {
              if(item.size<=COLLAB_CONF.SIZE_LIMIT_UPLOAD_DEFAUT){
                return true;
              }else{
                // Si il y a un fichier dans la liste d'upload
                if(ctrl.uploaderEntete.queue.length===1){
                  sweet.show({
                    title: '<span class="text-danger">'+item.name+'</span> trop gros !',
                    text: '2 Mo Maximum',
                    type: 'error',
                    html: true
                  });
                }
              }
            }
          },
          {
            name: 'imageFilter',
            // A user-defined filter
            fn: function(item) {
              var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
              //return '|jpg|png|jpeg|gif|'.indexOf(type) !== -1;
              if(('|jpg|png|jpeg|gif|'.indexOf(type) !== -1)===false){
                sweet.show({
                  title: '<span class="text-danger">' + item.name + '</span> doit être une image !',
                  text: 'Fichier Image obligatoire !',
                  type: 'error',
                  html: true
                });
              }else{
                return true;
              }
            }
          }]
      });

      // Après l'ajout d'une image
      ctrl.uploaderEntete.onAfterAddingFile = function(fileItem) {
        fileItem.formData.push({idPortefeuille: ctrl.currentPortefeuilleEdit.idPortefeuille});
        fileItem.formData.push({token: $rootScope.current.userGroupe.getToken()});
        if(ctrl.idGroupeAll) fileItem.formData.push({idGroupe: ctrl.idGroupeAll});
      };

      ctrl.uploaderEntete.onCompleteItem  = function(item, response) {
        if (response.nb === '1') {
          if (response.Portefeuilles.length) {
            ctrl.currentPortefeuilleEdit = new Portefeuille(response.Portefeuilles[0]);
            hideUploaderEntete();
          }
        }
      };

    };





    function initVarCurrentPortefeuille(portefeuille){

      if(portefeuille){
        ctrl.currentPortefeuilleEdit = angular.copy(portefeuille);
        if(!ctrl.currentPortefeuilleEdit.hasOwnProperty('portefeuilleInfos')){
          ctrl.currentPortefeuilleEdit.portefeuilleInfos = {};
        }
      }else{
        ctrl.currentPortefeuilleEdit = {
          'portefeuilleInfos': {}
        };
      }
    }

    /**
     * Affiche le formulaire de modification/ajout d'un portefeuille et de ses infos
     * @param reset
     */
    function showFormPortefeuille(reset){
      if(reset){
        initVarCurrentPortefeuille();
      }
      ctrl.showFormPortefeuille = true;
    }

    /**
     * Cache le formulaire de modification/ajout d'un portefeuille et de ses infos
     */
    function hideFormPortefeuille(){
      ctrl.showFormPortefeuille = false;
      initVarCurrentPortefeuille();
    }

    /**
     * Lors de la soummission du formulaire d'ajout/update de portefeuille
     */
    function onSubmitPortefeuille(){


      // Si update
      if(ctrl.currentPortefeuilleEdit.idPortefeuille){

        ctrl.loading.show = true;
        GroupesAdminService.updatePortefeuille(ctrl.currentPortefeuilleEdit, ctrl.idGroupeAll).then(function(portefeuille){
          for(var i = 0 ; i < ctrl.portefeuilles.length ; i++){
            if(ctrl.portefeuilles[i].getIdPortefeuille()===portefeuille.getIdPortefeuille()){
              ctrl.portefeuilles[i] = portefeuille;
            }
          }
          hideFormPortefeuille();

        },function(msg){
          ErreurCollabService.alert(msg);
        }).finally(function(){
          ctrl.loading.show = false;
        });
        // Si ajout
      }else{
        ctrl.loading.show = true;
        GroupesAdminService.addPortefeuille(ctrl.currentPortefeuilleEdit, ctrl.idGroupeAll).then(function(portefeuilles){
          ctrl.portefeuilles = portefeuilles;
          hideFormPortefeuille();
        },function(msg){
          ErreurCollabService.alert(msg);
        }).finally(function(){
          ctrl.loading.show = false;
        });
      }


    }

    /**
     * Lors du clique sur le bouton "Ajouter"
     */
    function onClickAddPortefeuille(){
      showFormPortefeuille(true);
    }

    /**
     * Lors du clique sur le bouton "Annuler"
     */
    function onClickCancelPortefeuille(){
      hideFormPortefeuille();
    }

    function onClickChangeEntete(){
      if(!ctrl.showUploader) {
        showUploaderEntete();
      }
      else {
        hideUploaderEntete();
      }
    }

    function onClickDeleteEntete(){
      sweet.show({
        title: 'Suppression entête',
        text: 'Voulez-vous vraiment supprimer l\'entête ?',
        type: 'info',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        closeOnConfirm: true
      },function() {
        ctrl.loadingEntete = true;
        ctrl.currentPortefeuilleEdit
          .deleteEntete(ctrl.idGroupeAll)
          .finally(function(){
            ctrl.loadingEntete = false;
            showUploaderEntete();
          });
      });
    }

    function showUploaderEntete(){
      ctrl.showUploader = true;
    }

    function hideUploaderEntete(){
      ctrl.showUploader = false;
      ctrl.uploaderEntete.clearQueue();
      angular.element('#fileEntete').prop('value', null);
    }

    /**
     * Lors du clique sur le bouton "Editer" du portefeuille
     * @param portefeuille
     */
    function onClickEditPortefeuille(portefeuille){
      initVarCurrentPortefeuille(portefeuille);

      showFormPortefeuille();
    }

    /**
     * Lors du clique sur le bouton suppression d'un portefeuille
     * @param portefeuille
     */
    function onClickDeletePortefeuille(portefeuille){
      var texte = 'Voulez-vous vraiment supprimer le portefeuille :<br /><strong>'+portefeuille.nom+'</strong><br />'+portefeuille.cle;
      sweet.show({
        title: 'Supprimer un portefeuille',
        text: texte,
        type: 'warning',
        html:true,
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Oui !',
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      },function(isConfirm){
        if(isConfirm){

          GroupesAdminService.deletePortefeuille(portefeuille, ctrl.idGroupeAll).then(function(){
            for(var i = 0 ; i < ctrl.portefeuilles.length ; i++){
              if(ctrl.portefeuilles[i].getIdPortefeuille()===portefeuille.getIdPortefeuille()){
                ctrl.portefeuilles.splice(i,1);
              }
            }
            sweet.close();
          });
        }
        else sweet.close();
      });
    }

  }
})();
