'use strict';

/**
 * @ngdoc service
 * @name collaboreApp.AutoSaveService
 * @description
 * # AutoSaveService
 * Service in the collaboreApp.
 */
angular.module('collaboreApp').service('AutoSaveService', ['COLLAB_CONF','$rootScope','ConfigService', function(COLLAB_CONF,$rootScope,ConfigService) {

  var scope = this;

  scope._keyNewContenu = function(objContenu,objetId,parentContenu){

    var idContenu = false;



    // Si il y a un id contenu, donc une modification d'un contenu
    if(objetId){
      idContenu = objetId;
    }else{

      switch(objContenu.typeContenu){
        case 2:
          idContenu = 'newEvenement';
          break;
        default:
          idContenu = 'newMessage';
      }

      // Si il y a un parent contenu, donc peut etre une r�ponse � un contenu message
      if(parentContenu){
        idContenu = (_.isObject(parentContenu)) ? parentContenu.getIdContenu() : parentContenu;
      }
    }

    return idContenu;
  };

  /*
  scope._keyNewContenu = function(itemContenu){
    var idContenu;
    if(angular.isObject(itemContenu)){
      switch(itemContenu.typeContenu){
        case 2:
          idContenu = 'newEvenement';
          break;
        default:
          idContenu = 'newMessage';
      }
    }else{
      idContenu = false;
    }

    return idContenu;
  };*/

  /*
  var getIdContenu = function(formScope){
    var itemContenu = formScope.itemContenu;
    //console.log(formScope);
    if('contenumessagecomponentctrl' in formScope){
      itemContenu = formScope.contenumessagecomponentctrl.contenu;
    }



    //console.log(itemContenu);
    if(angular.isUndefined(itemContenu)){
      itemContenu = formScope.itemContenuEvenement;
    }
    var currentItemContenu = formScope.currentItemContenu;
    var idContenu = scope._keyNewContenu(currentItemContenu);

    if(formScope.currentItemContenu.typeContenu===1){
      if(itemContenu.idContenu){
        idContenu = itemContenu.idContenu;
      }
    }else if(formScope.currentItemContenu.typeContenu===2){
      if(currentItemContenu.idContenu){
        idContenu = currentItemContenu.idContenu;
      }
    }

    return idContenu;
  };*/


  /*
  scope.start = function(formScope){

    var idContenu = getIdContenu(formScope);

    var intervalAutosave = $interval(function(){

      scope.save(formScope,idContenu);

    },(COLLAB_CONF.INTERVAL_AUTOSAVE_REPONSE*1000));

    $rootScope._poolIntervalAutoSaveContenu[idContenu]= intervalAutosave;
  };*/

  /*
  scope.stop = function(itemContenu) {

    if(angular.isObject(itemContenu)){
      var idContenu = scope._keyNewContenu(itemContenu);

      // Si itemContenu est un objet
      if(angular.isObject(itemContenu)){

        // Si ily a un id contenu
        if(itemContenu.idContenu){
          idContenu = itemContenu.idContenu;
        }
      }

      //Si il y a une interval dans le pool des interval autosave de type message du rootscope
      if($rootScope._poolIntervalAutoSaveContenu[idContenu]){
        //Cancel l'interval
        $interval.cancel($rootScope._poolIntervalAutoSaveContenu[idContenu]);
      }

      // Supprime l'interval du rootscope
      delete $rootScope._poolIntervalAutoSaveContenu[idContenu];
    }else{

      // Cancel toutes les intervales dans le rootscope._poolIntervalAutoSaveContenu
      angular.forEach($rootScope._poolIntervalAutoSaveContenu,function(interval){
        $interval.cancel(interval);
      });

      $rootScope._poolIntervalAutoSaveContenu = {};
    }
  };*/

  scope.save = function(objContenu,objetId,parentContenu){

    var contenu = {};


    /*
    console.log('objContenu',objContenu);
    console.log('objetId',objetId);
    console.log('parentContenu',parentContenu);
*/

    // Si l'objetId est un numero et est diff�rent de 0
    if(angular.isNumber(objetId) && objetId!==0){
      contenu = {
        'modification':{
          'obj': objContenu,
          'date': new Date()
        }
      };
    }else{



      // Si il y a un contenu parent
      if(parentContenu){

        objetId = (_.isObject(parentContenu)) ? parentContenu.idContenu : parentContenu;
        // Recup�re l'idContenu du parent
        //objetId = parentContenu.idContenu;
        contenu = {
          'reponse': {
            'obj': objContenu,
            'date': new Date()
          }
        };

      }
      // Si pas de contenu parent
      else{

        // Si message
        if(objContenu.typeContenu===1){
          objetId = 'newMessage';
        }

        // Si evenement
        if(objContenu.typeContenu===2){
          objetId = 'newEvenement';
        }

        contenu = {
          'nouveau':{
            'obj': objContenu,
            'date': new Date()
          }
        };
      }
    }


    // Ajoute/Modifie la sauvegarde dans le localstorage
    ConfigService.updateListeContenuSave(objetId,contenu);
  };


  scope.get = function(objetId) {
    var instance = ConfigService.getListeContenuSave(objetId);
    if(instance){
      instance = angular.fromJson(instance);
    }
    return instance;
  };

  scope.delete = function(objContenu,objetId,parentContenu) {
    var isNouveau = true;
    var idContenu = scope._keyNewContenu(objContenu,objetId,parentContenu);
    ConfigService.deleteListeContenuSave(idContenu,isNouveau);
  };

}]);
