'use strict';

/**
 * @ngdoc service
 * @name collaboreApp.SignatureService
 * @description
 * # SignatureService
 * Service in the collaboreApp.
 */
angular.module('collaboreApp').service('SignatureService', ['COLLAB_CONF','$q','SignatureAjax', function(COLLAB_CONF,$q,SignatureAjax) {

  var scope = this;

  scope.getSignature = function(){
    var deferred = $q.defer();
    SignatureAjax.getSignature(function(json){
      if(json.nb!=='0'){
        deferred.resolve(json.Signatures[0]);
      }else{
        deferred.reject('Pas de signature');
      }
    },function(error){
      var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
      deferred.reject('Problème avec ParamsService.getSignature(), '+msgErreur);
    });
    return deferred.promise;
  };

  scope.addSignature = function(signatureBase64){

    var deferred = $q.defer();

    var params = {
      'signature' : signatureBase64
    };

    SignatureAjax.addSignature(params,function(json){

      if(json.Resultats[0].success){
        deferred.resolve(true);
      }else{
        deferred.reject(false);
      }
    },function(error){
      var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
      deferred.reject('Problème avec ParamsService.addSignature(), '+msgErreur);
    });

    return deferred.promise;
  };

}]);
