(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.ReceiptLineItemItem
   * @description
   * # ReceiptLineItemItem
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('ReceiptLineItemItem', ReceiptLineItemItemModel);

  /** @ngInject */
  function ReceiptLineItemItemModel(Main) {

    function ReceiptLineItemItem(data) {
      Main.apply(this, arguments);
      this.hydrate(data);
    }

    ReceiptLineItemItem.prototype = {

      model: 'ReceiptLineItemItem',
      isReceiptLineItemItem: true,

      // amount
      getAmount: function(){
        return this.amount;
      },
      setAmount: function(val){
        this.amount = val;
      },

      // amountEach
      getAmountEach: function(){
        return this.amountEach;
      },
      setAmountEach: function(val){
        this.amountEach = val;
      },

      // amountExVat
      getAmountExVat: function(){
        return this.amountExVat;
      },
      setAmountExVat: function(val){
        this.amountExVat = val;
      },

      // description
      getDescription: function(){
        return this.description;
      },
      setDescription: function(val){
        this.description = val;
      },

      // quantity
      getQuantity: function(){
        return this.quantity;
      },
      setQuantity: function(val){
        this.quantity = val;
      },

      // sku
      getSku: function(){
        return this.sku;
      },
      setSku: function(val){
        this.sku = val;
      },

      // title
      getTitle: function(){
        return this.title;
      },
      setTitle: function(val){
        this.title = val;
      },

      // unitOfMeasurement
      getUnitOfMeasurement: function(){
        return this.unitOfMeasurement;
      },
      setUnitOfMeasurement: function(val){
        this.unitOfMeasurement = val;
      },

      // vatAmount
      getVatAmount: function(){
        return this.vatAmount;
      },
      setVatAmount: function(val){
        this.vatAmount = val;
      },

      // vatCode
      getVatCode: function(){
        return this.vatCode;
      },
      setVatCode: function(val){
        this.vatCode = val;
      },

      // vatPercentage
      getVatPercentage: function(){
        return this.vatPercentage;
      },
      setVatPercentage: function(val){
        this.vatPercentage = val;
      },

    };

    angular.extend(ReceiptLineItemItem.prototype, Main.prototype);

    return ReceiptLineItemItem;
  }
})();
