(function() {

  'use strict';
  /**
   * @desc Componsant pour choisir un destinataire
   * @example <discussions-collab</discussions-collab>
   */
  angular
    .module('collaboreApp')
    .component('discussionsCollab',{
      templateUrl: 'app/topics/discussions-collab/discussions-collab.component.html',
      bindings : {
        topic: '<', // Objet Topic
        onEmit:'&'
      },
      controllerAs: 'discussionscollabctrl',
      controller: DiscussionsCollabController
    });

  /*@ngInject*/
  function DiscussionsCollabController($q, $rootScope, $scope, COLLAB_CONF, COLLAB_VALUES, $log, UtilsService, ModalsService, ErreurCollabService, TopicManagerService){

    var ctrl = this;

    ctrl.moment = moment;

    // Loading de l'avatar si chargement
    ctrl.loadingAvatar = COLLAB_CONF.MIDDLE_LOADING_SRC;

    // Url l'image si pas d'avatar
    ctrl.srcNoPhoto = COLLAB_CONF.SRC_NO_PHOTO;

    // Url du dossier des avatars
    ctrl.urlAvatar = COLLAB_VALUES.CONF_URL.URL_AVATARS;

    ctrl.myGroupe = UtilsService.getCurrentUser();

    ctrl.dateCreationTopicFormated = null;
    ctrl.params = {
      tagPortefeuille: null
    };
    ctrl.evenement = null;
    ctrl.listContenus = [];

    ctrl.getClassTimelineCollabItem = getClassTimelineCollabItem;
    ctrl.onClickAddNewMessage = onClickAddNewMessage;
    ctrl.onClickReply = onClickReply;
    ctrl.onClickReplyAll = onClickReplyAll;
    ctrl.onClickEditerMessage = onClickEditerMessage;                   // Lorsqu'on clique sur le bouton pour editer le message
    ctrl.onClickVoirDemande = onClickVoirDemande;                       // Lorsqu'on clique sur le bouton pour voir la demande
    ctrl.onClickVoirDocument = onClickVoirDocument;                     // Lorsqu'on clique sur le bouton pour voir un des documents de la demande
    ctrl.onClickVoirDocumentDevis = onClickVoirDocumentDevis;           // Lorsqu'on clique sur le bouton pour voir le devis
    ctrl.onClickVoirDocumentDevisSigne = onClickVoirDocumentDevisSigne; // Lorsqu'on clique sur le bouton pour voir le devis signé
    ctrl.onClickVoirDocumentFacture = onClickVoirDocumentFacture;       // Lorsqu'on clique sur le bouton pour voir la facture
    //ctrl.onClickSignerDocument = onClickSignerDocument;                 // Lorsqu'on clique sur le bouton pour aller signer le devis
    ctrl.onEmitFromFormulaireMessage = onEmitFromFormulaireMessage;
    ctrl.onEmitFromIconContenuImportant = onEmitFromIconContenuImportant;
    ctrl.onEmitFromMenuDemande = onEmitFromMenuDemande;
    ctrl.onUpdateDevis = onUpdateDevis;
    ctrl.dateParser = dateParser;


    ctrl.$onInit = function() {
      init(ctrl.topic);
      $scope.$on('addMessage', function (event, item) {
        addContenu(item);
      });
    };

    /**
     * Lors de changements depuis le parent
     * @param changes
     */
    ctrl.$onChanges = function (changes) {
      if(_.isObject(changes.topic) && !changes.topic.isFirstChange()) {
        ctrl.topic = changes.topic.currentValue;
        init(ctrl.topic);
      }
      /*
      console.log(changes);

      if(angular.isObject(changes) && angular.isObject(changes.topic.currentValue)) {
        console.log(changes.topic);
        console.log(changes.topic.currentValue);

        init(changes.topic.currentValue);
      }*/
    };

    ctrl.$onDestroy = function(){
    };

    /**
     * Initialisaiton du composant
     * @param topic
     */
    function init(topic){
      if($rootScope.isCapfun) ctrl.isEventIntervention = TopicManagerService.currentEventIsIntervention();
      ctrl.listContenus = [];
      var dateCreationTopicMoment = moment(topic.getDateCreation());
      ctrl.dateCreationTopicFormated = dateCreationTopicMoment.format('dddd').ucfirst() + ' ' + dateCreationTopicMoment.format('DD') + ' ' + dateCreationTopicMoment.format('MMMM').ucfirst() + ' ' + dateCreationTopicMoment.format('YYYY')
      var tmpListContenus = {};

      ctrl.nbDemandeIntervention = 0;
      ctrl.nbDemandeDevis = 0;
      ctrl.nbMessage = 0;
      ctrl.nbSms = 0;
      ctrl.nbDemandeContrat = 0;
      if(_.isObject(topic)) {
        // Si il y a des contenus
        if(_.isArray(topic.getContenus()) && topic.getContenus().length) {

          // Parcour tous les contenus
          for(var c = 0; c < topic.getContenus().length; c++){
            var contenu = topic.getContenus()[c];

            // Si contenu de type évenement
            if(contenu.isContenuEvenement()) {
              ctrl.evenement = contenu;
              ctrl.params.tagPortefeuille = contenu.getTagForType('Portefeuille');

              /*
              if(ctrl.evenement.getDateEcheance()) {
                tmpListContenus = addContenuToListTmp(tmpListContenus, generateObjFromContenu(ctrl.evenement));
              }*/

              // Si il y a des demandes de devis ou intervention
              if(ctrl.evenement.getListeDevis().length) {
                for(var d = 0; d < ctrl.evenement.getListeDevis().length; d++){
                  var demande = ctrl.evenement.getListeDevis()[d];

                  if(demande.isDemandeIntervention()) ctrl.nbDemandeIntervention++;
                  else ctrl.nbDemandeDevis++;

                  tmpListContenus = addContenuToListTmp(tmpListContenus, generateObjFromContenu(demande));
                }
              }

              // Si il y a des demandes de contrats
              if(ctrl.evenement.getListeContrats().length) {
                for(var e = 0; e < ctrl.evenement.getListeContrats().length; e++){
                  ctrl.nbDemandeContrat++;
                  tmpListContenus = addContenuToListTmp(tmpListContenus, generateObjFromContenu(ctrl.evenement.getListeContrats()[e]));
                }
              }
            }

            // Si contenu message
            if(contenu.isContenuMessage()) {

              if(contenu.sms) ctrl.nbSms++;
              else ctrl.nbMessage++;

              tmpListContenus = addContenuToListTmp(tmpListContenus, generateObjFromContenu(contenu));
            }
          }

          if(Object.keys(tmpListContenus).length) {

            angular.forEach(tmpListContenus, function(tab, date){
              addListContenuForDate(date, tab);
            })

            if(ctrl.evenement.getDateEcheance() && moment().isSameOrAfter(ctrl.evenement.getDateEcheance(), 'day')){

              var echeanceAdd = false;
              if(ctrl.listContenus.length) {
                for(var i = 0; i < ctrl.listContenus.length; i++) {
                  if(moment(ctrl.listContenus[i].date).isSame(ctrl.evenement.getDateEcheance())) {
                    ctrl.listContenus[i].evenementEcheance = true;
                    echeanceAdd = true;
                    break;
                  }
                }
              }

              if(!echeanceAdd) {
                var dateMoment = moment(ctrl.evenement.getDateEcheance());
                ctrl.listContenus.push({
                  evenementEcheance: true,
                  date: ctrl.evenement.getDateEcheance(),
                  dateFormated: dateMoment.format('dddd').ucfirst() + ' ' + dateMoment.format('DD') + ' ' + dateMoment.format('MMMM').ucfirst() + ' ' + dateMoment.format('YYYY')
                });
                ctrl.listContenus.sort(function(a,b){return new Date(a.date).getTime() - new Date(b.date).getTime()});
              }
            }
          }
        }
      }
    }

    /**
     * Permet de générer l'objet qui est insérer dans la liste des contenus par date
     * @param contenu
     * @returns {*}
     */
    function generateObjFromContenu(contenu){

      var objets = [];
      // Si contenu message
      if(contenu.isContenuMessage()) {
        var dateContenu = (contenu.getDateModification()) ? contenu.getDateModification() : contenu.getDateCreation();
        objets.push({
          type: 'creationMessage',
          isCopy: false,
          position: (_.isNil(contenu.getExpediteur()) || contenu.getExpediteur().isDestinataireEmail || contenu.getExpediteur().getIdGroupe() !== ctrl.myGroupe.getIdGroupe()) ? 'right' : 'left',
          date: dateContenu,
          dateDay: moment(dateContenu).format('YYYY-MM-DD'),
          contenu: contenu
        });
      }

      // Si contenu de type évenement
      if(contenu.isContenuEvenement()) {

        /*
        // Si il y a une date d'échéance
        if(contenu.getDateEcheance()) {
          // Ajoute la demande pour sa création
          objets.push({
            type: 'dateEcheanceEvenement',
            isCopy: true,
            position: 'left',
            date: contenu.getDateEcheance(),
            dateDay: moment(contenu.getDateEcheance()).format('YYYY-MM-DD'),
            contenu: null
          });
        }*/
      }

      // Si demande de devis/intervention ou contrat
      if(contenu.isContenuDevisInter() || contenu.isContenuContrat()){
        // Ajoute la demande pour sa création
        objets.push({
          type: 'creationDemande',
          isCopy: false,
          position: 'left',
          date: contenu.getDateCreation(),
          dateDay: moment(contenu.getDateCreation()).format('YYYY-MM-DD'),
          contenu: contenu
        });

        // Si demande de type devis/inter
        if(contenu.isContenuDevisInter()) {
          // Si il y a une date de reception du devis
          if(contenu.getDateReceptionDevis()) {
            objets.push({
              type: 'receptionDevis',
              isCopy: true,
              position: 'right',
              date: contenu.getDateReceptionDevis(),
              dateDay: moment(contenu.getDateReceptionDevis()).format('YYYY-MM-DD'),
              contenu: contenu
            });
          }

          // Si il y a une facture
          if(contenu.hasFacture()){
            objets.push({
              type: 'receptionFacture',
              isCopy: true,
              position: 'right',
              date: contenu.getFacture().getDateCreation(),
              dateDay: moment(contenu.getFacture().getDateCreation()).format('YYYY-MM-DD'),
              contenu: contenu
            });

            if(contenu.getFacture() && contenu.getFacture().getListeDocument().length){
              for (var i = 0; i < contenu.getFacture().getListeDocument().length; i++) {
                if (contenu.getFacture().getListeDocument()[i].isSentToComfactfacture()) {

                  objets.push({
                    type: 'factureEnvoyeDansComfact',
                    isCopy: true,
                    position: 'left',
                    date: contenu.getFacture().getListeDocument()[i].getDateSentToComfactfacture(),
                    dateDay: moment(contenu.getFacture().getListeDocument()[i].getDateSentToComfactfacture()).format('YYYY-MM-DD'),
                    contenu: contenu,
                    document: contenu.getFacture().getListeDocument()[i]
                  });
                }
              }
            }
          }

          // Si demande acceptée
          if(contenu.isAccepte()) {

            // Si il y a une date d'acceptation de la demande
            if(contenu.getDateAcceptation()) {
              objets.push({
                type: 'acceptationDemande',
                isCopy: true,
                position: 'left',
                date: contenu.getDateAcceptation(),
                dateDay: moment(contenu.getDateAcceptation()).format('YYYY-MM-DD'),
                contenu: contenu,
                document: contenu.getDocumentDevis()
              });
            }

            // Si il y a une date de signature de la demande
            if(contenu.getDateDevisSigne()) {
              objets.push({
                type: 'signatureDevis',
                isCopy: true,
                position: 'left',
                date: contenu.getDateDevisSigne(),
                dateDay: moment(contenu.getDateDevisSigne()).format('YYYY-MM-DD'),
                contenu: contenu,
                document: contenu.getDocumentDevisSigne()
              });
            }
          }

          // Si il y a une date de refus du devis alors demande refusée
          else if(contenu.getDateRefus()){
            objets.push({
              type: 'refusDemande',
              isCopy: true,
              position: 'left',
              date: contenu.getDateRefus(),
              dateDay: moment(contenu.getDateRefus()).format('YYYY-MM-DD'),
              contenu: contenu,
              document: contenu.getDocumentDevis()
            });
          }
        }
        // Si demande de type contrat
        else if(contenu.isContenuContrat()) {

          // Si il y a des contrats envoyé par le fournisseur
          if(contenu.hasContratFournisseurSigne()) {
            for(var c = 0; c < contenu.getListDocumentContratFournisseurSigne().length; c++){
              var contratSigne = contenu.getListDocumentContratFournisseurSigne()[c];

              // Reception du contrat signé par le fournisseur
              objets.push({
                type: 'receptionContratFournisseurSigne',
                isCopy: true,
                position: 'right',
                date: contratSigne.getDateUpload(),
                dateDay: contratSigne.getDateUpload('YYYY-MM-DD'),
                contenu: contenu,
                document: contratSigne
              });

              // Si le contrat est accepté
              if(contratSigne.isAccepted()) {
                // Acceptation du contrat signé par le fournisseur
                objets.push({
                  type: 'acceptationContratFournisseurSigne',
                  isCopy: true,
                  position: 'left',
                  date: contratSigne.getDateAcceptation(),
                  dateDay: contratSigne.getDateAcceptation('YYYY-MM-DD'),
                  contenu: contenu,
                  document: contratSigne
                });

                /*
                // Si le contrat est signé par l'agence
                if(contratSigne.isSigned()) {
                    // Acceptation du contrat signé par le fournisseur
                    objets.push({
                        type: 'signatureContratFournisseurSigne',
                        isCopy: true,
                        position: 'left',
                        date: contratSigne.getDateSignature(),
                        dateDay: contratSigne.getDateSignature('YYYY-MM-DD'),
                        contenu: contenu,
                        document: contratSigne
                    });
                }*/
              }
              // Si le contrat est refusé
              else if(contratSigne.getDateRefus()) {
                // Acceptation du contrat signé par le fournisseur
                objets.push({
                  type: 'refusContratFournisseurSigne',
                  isCopy: true,
                  position: 'left',
                  date: contratSigne.getDateRefus(),
                  dateDay: contratSigne.getDateRefus('YYYY-MM-DD'),
                  contenu: contenu,
                  document: contratSigne
                });
              }
            }
          }

          // Si il y a des contrats fournisseur signé par l'agence
          if(contenu.hasContratAgenceSigne()) {
            for(var ca = 0; ca < contenu.getListDocumentContratAgenceSigne().length; ca++) {
              var contratAgenceSigne = contenu.getListDocumentContratAgenceSigne()[ca];

              // Signature par l'agence du contrat fournisseur
              objets.push({
                type: 'signatureContratFournisseurSigne',
                isCopy: true,
                position: 'left',
                date: contratAgenceSigne.getDateSignature(),
                dateDay: contratAgenceSigne.getDateSignature('YYYY-MM-DD'),
                contenu: contenu,
                document: contratAgenceSigne
              });
            }
          }

        }


      }
      return objets;
    }

    /**
     * Permet d'ajouter un contenu à la liste temporaire
     * @param tmpListContenus
     * @param dateDay
     * @param obj
     * @returns {*}
     */
    /*
    function addContenuToListTmp(tmpListContenus, dateDay, obj){
      if(!tmpListContenus.hasOwnProperty(dateDay)) tmpListContenus[dateDay] = [];
      tmpListContenus[dateDay].push(obj);

      return tmpListContenus;
    }*/

    /**
     * Permet d'ajouter un contenu à la liste temporaire
     * @param tmpListContenus
     * @param dateDay
     * @param obj
     * @returns {*}
     */
    function addContenuToListTmp(tmpListContenus, objets){
      if(_.isArray(objets) && objets.length) {
        for(var o = 0; o < objets.length; o++){
          if(!tmpListContenus.hasOwnProperty(objets[o].dateDay)) tmpListContenus[objets[o].dateDay] = [];
          tmpListContenus[objets[o].dateDay].push(objets[o]);
        }
      }
      else if(_.isObject(objets)) {
        if(!tmpListContenus.hasOwnProperty(objets.dateDay)) tmpListContenus[objets.dateDay] = [];
        tmpListContenus[objets.dateDay].push(objets);
      }
      return tmpListContenus;
    }

    /**
     * Permet d'ajouter un groupe de contenu pour une date dans la discussion
     * @param date
     * @param tab
     */
    function addListContenuForDate(date, tab, closed){
      var isClosed = (closed) ? true : false;
      if(!angular.isArray(tab)) tab = [];

      var nbContenuByType = {
        nbMessage: 0,
        nbSms: 0,
        nbDemandeDevis: 0,
        nbDemandeIntervention: 0,
        nbDevisRecu: 0,
        nbDevisAccepte: 0,
        nbDevisRefuse: 0,
        nbDevisSigne: 0,
        nbFactureRecu: 0,
        nbFactureComfacte: 0
      };

      if(tab.length) {

        tab.sort(function(a,b){return new Date(a.date).getTime() - new Date(b.date).getTime()});

        var nbContenu = 0;
        var nbContenuLu = 0;
        for(var c = 0; c < tab.length; c++) {
          if(angular.isObject(tab[c].contenu)) {
            var currentContenu = tab[c].contenu;
            if(!tab[c].isCopy) {
              nbContenu++;
              if(currentContenu.getLu()) nbContenuLu++;
            }

            // Si contenu message
            if(currentContenu.isContenuMessage()) {
              if(currentContenu.sms) nbContenuByType.nbSms++;
              else nbContenuByType.nbMessage++;
            }

            // Si demande de devis ou intervention
            if(currentContenu.isContenuDevisInter()){
              if(tab[c].type === 'creationDemande') {
                if(currentContenu.isDemandeIntervention()) nbContenuByType.nbDemandeIntervention++;
                else nbContenuByType.nbDemandeDevis++;
              }

              if(tab[c].type === 'receptionDevis') nbContenuByType.nbDevisRecu++;
              if(tab[c].type === 'acceptationDemande') nbContenuByType.nbDevisAccepte++;
              if(tab[c].type === 'refusDemande') nbContenuByType.nbDevisRefuse++;
              if(tab[c].type === 'signatureDevis') nbContenuByType.nbDevisSigne++;
              if(tab[c].type === 'receptionFacture') nbContenuByType.nbFactureRecu++;
              if(tab[c].type === 'factureEnvoyeDansComfact') nbContenuByType.nbFactureComfacte++;
            }
          }
        }
        if(angular.isUndefined(closed) && nbContenu === nbContenuLu) isClosed = true;
      }

      var dateMoment = moment(date);
      ctrl.listContenus.push({
        isClosed: isClosed,
        nbContenuByType: nbContenuByType,
        date: date,
        dateFormated: dateMoment.format('dddd').ucfirst() + ' ' + dateMoment.format('DD') + ' ' + dateMoment.format('MMMM').ucfirst() + ' ' + dateMoment.format('YYYY'),
        listContenuByDate: tab
      });

      ctrl.listContenus.sort(function(a,b){return new Date(a.date).getTime() - new Date(b.date).getTime()});
      ctrl.listContenus[ctrl.listContenus.length-1].isClosed = false;

    }

    /**
     * Permet d'ajoute run contenu à la liste des contenu par date
     * @param date
     * @param obj
     */
    function addContenuGenerate(obj){
      var date = obj.dateDay;
      // Si pas encore de discussion alors ajoute la première
      if(!ctrl.listContenus.length) {
        addListContenuForDate(date, [obj], false);
        return;
      }
      // Si il y a déjà des discussion il faut aller voir si le groupement par date existe déjà
      for(var i = 0; i < ctrl.listContenus.length; i++){
        if(ctrl.listContenus[i].date === date) {
          var exist = false;
          // Si l'objet pour cette date exist est qu'il y a une liste de contenu par date
          if(angular.isArray(ctrl.listContenus[i].listContenuByDate)) {
            // Parcour les contenu pour vérifier que celui qu'on va ajouter n'y ai pas déjà
            for(var j = 0; j < ctrl.listContenus[i].listContenuByDate.length; j++){
              // Si il est trouvé
              if(ctrl.listContenus[i].listContenuByDate[j].contenu.idContenu === obj.contenu.idContenu  && ctrl.listContenus[i].listContenuByDate[j].type === obj.type && ctrl.listContenus[i].listContenuByDate[j].date === obj.date) {
                exist = true;
                break;
              }
            }
          }
          // Si il n'éxiste pas alors on l'ajoute
          if(!exist) {
            ctrl.listContenus[i].isClosed = false;
            ctrl.listContenus[i].listContenuByDate.push(obj);
          }
          break;
        }
      }
    }

    /**
     * Permet d'ajouter un contenu ou une duplication d'état de devis à la liste de discussion
     * @param contenu
     */
    function addContenu(contenu){
      if(angular.isObject(contenu)){
        var objets = generateObjFromContenu(contenu);
        if(angular.isArray(objets) && objets.length) {
          for(var i = 0; i < objets.length; i++)
            addContenuGenerate(objets[i]);
        }
      }
      if(angular.isArray(ctrl.listContenus) && ctrl.listContenus.length) ctrl.listContenus.sort(function(a,b){return new Date(a.date).getTime() - new Date(b.date).getTime()});
    }

    /**
     * Permet d'update un contenu dans la discussion, ajouté également les duplication de devis pour les état si n'existe pas
     * @param contenu
     */
    function updateContenu(obj){
      if(angular.isObject(obj) && ctrl.listContenus.length){

        var objets = generateObjFromContenu(obj);
        if(angular.isArray(objets) && objets.length) {
          var objetsCopy = angular.copy(objets);

          for(var o = 0; o < objets.length; o++){

            // Parcour les discussions
            for(var i = 0; i < ctrl.listContenus.length; i++){
              // Si il y a une liste de contenu par date
              if(angular.isArray(ctrl.listContenus[i].listContenuByDate)) {
                // Parcour les contenu pour vérifier que celui qu'on va ajouter n'y ai pas déjà
                for(var j = 0; j < ctrl.listContenus[i].listContenuByDate.length; j++){
                  // Si le contenu est trouvé par son idContenu
                  if(ctrl.listContenus[i].listContenuByDate[j].contenu.idContenu === objets[o].contenu.idContenu) {

                    ctrl.listContenus[i].isClosed = false;
                    ctrl.listContenus[i].listContenuByDate[j].contenu = objets[o].contenu;

                    // Si meme type et meme date donc c'est un contenu dupliqué
                    if(ctrl.listContenus[i].listContenuByDate[j].type === objets[o].type && ctrl.listContenus[i].listContenuByDate[j].contenu.dateCreation === objets[o].contenu.dateCreation) {
                      ctrl.listContenus[i].listContenuByDate[j] = objets[o];

                      // il faut retirer l'objet de la liste des objets dupliqué, ceux qui resterons seoront à ajouter
                      if(angular.isArray(objetsCopy) && objetsCopy.length) {
                        for(var n = 0; n < objetsCopy.length; n++){
                          if(objets[o].contenu.idContenu === objetsCopy[n].contenu.idContenu && objets[o].type === objetsCopy[n].type && objets[o].contenu.dateCreation === objetsCopy[n].contenu.dateCreation) {
                            objetsCopy.splice(n, 1);
                            break;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          // Si il reste des objets ce sont des duplications pour chaque état d'un devis il faut les ajouter
          if(angular.isArray(objetsCopy) && objetsCopy.length) {
            for(var m = 0; m < objetsCopy.length; m++)
              addContenuGenerate(objetsCopy[m]);
          }
        }
        ctrl.listContenus.sort(function(a,b){return new Date(a.date).getTime() - new Date(b.date).getTime()});
      }
    }

    /**
     * Permet de récupèrer la class CSS à utiliser pour le block par rapport au contenu
     * @param obj
     * @returns {string}
     */
    function getClassTimelineCollabItem(obj){
      var classRetour = '';

      if(angular.isObject(obj.contenu)) {

        // Si la position est "left" alors on met à gauche de la timeline
        if(obj.position === 'left') classRetour += 'timeline-collab-odd-item ';
        // Sinon à droite
        else classRetour += 'timeline-collab-even-item ';

        // Si contenu de type message
        if(obj.contenu.isContenuMessage()){

          /*
          // Si je ne suis pas l'expediteur donc à droite de la timeline
          if(obj.contenu.getExpediteur().getIdGroupe() !== ctrl.myGroupe.getIdGroupe()) classRetour += 'timeline-collab-even-item ';
          // Si je suis l'expediteur donc à gauche de la timeline
          else classRetour += 'timeline-collab-odd-item ';*/

          // Si SMS
          if(obj.contenu.sms) classRetour += 'timeline-collab-block-sms ';
          // Si pas SMS
          else classRetour += 'timeline-collab-block-message ';
        }
        /*
        else {
          // Si la position est "left" alors on met à gauche de la timeline
          if(obj.position === 'left') classRetour += 'timeline-collab-odd-item ';
          // Sinon à droite
          else classRetour += 'timeline-collab-even-item ';
        }*/

        // Si demande de devis ou intervention
        if(obj.contenu.isContenuDevisInter()){

          classRetour += 'timeline-collab-block-demande ';

          // Si demande intervention
          if(obj.contenu.isDemandeIntervention()) classRetour += 'timeline-collab-block-demande-intervention ';
          // Si demande devis
          else classRetour += 'timeline-collab-block-demande-devis ';
        }

        // Si demande de contrat
        if(obj.contenu.isContenuContrat()){
          classRetour += 'timeline-collab-block-demande timeline-collab-block-demande-contrat ';
        }
      }
      else classRetour += 'timeline-collab-label-block ';

      return classRetour;
    }

    /**
     * Permet d'ouvrir le formulaire
     * @param contenu
     */
    function openForm(contenu){
      contenu.openReply = true;
    }

    /**
     * Permet de fermet le reply
     * @param contenu
     */
    function closeForm(contenu){
      if(angular.isObject(contenu)) contenu.openReply = false;
      if(ctrl.openFormNewMessage) ctrl.openFormNewMessage = false;
    }

    function onClickAddNewMessage(){
      ctrl.openFormNewMessage = true;
      if(ctrl.evenement) ctrl.destinatairesNewContenu = angular.copy(ctrl.evenement.getDestinataires());
    }

    /**
     * Lors du clique sur le bouton pour répondre seulement à l'expediteur du message
     * @param contenu
     */
    function onClickReply(contenu){
      openForm(contenu);
      contenu.destinatairesNewContenu = [angular.copy(contenu.getExpediteur())];
    }

    /**
     * Lors du clique sur le bouton pour répondre à tous les destinataires du message
     * @param contenu
     */
    function onClickReplyAll(contenu){
      openForm(contenu);
      var dests = angular.copy(contenu.getDestinataires(true));
      if(contenu.getExpediteur().isDestinataireEmail) dests.push(angular.copy(contenu.getExpediteur()));
      contenu.destinatairesNewContenu = dests;
    }

    /**
     * Lorsqu'on clique sur le bouton pour editer le message
     * @param contenu
     */
    function onClickEditerMessage(contenu){
      contenu.openEdit = true;

      // Scroll la page vers le fomulaire de réponse
      UtilsService.scrollToFormReponse(contenu);
    }

    /**
     * Lorsqu'on clique sur le bouton pour voir la demande
     * @param devis
     */
    function onClickVoirDemande(devis){
      ctrl.onEmit({obj:{action: 'onClickVoirDemande', devis: devis}});
    }

    /**
     * Lorsqu'on clique sur le bouton pour voir un des documents de la demande
     * @param devis
     */
    function onClickVoirDocument(demande, document){
      ctrl.onEmit({obj:{action: 'onClickVoirDocument', demande: demande, document: document}});
    }

    /**
     * Lorsqu'on clique sur le bouton pour voir le document devis
     * @param devis
     */
    function onClickVoirDocumentDevis(devis){
      ctrl.onEmit({obj:{action: 'onClickVoirDocumentDevis', devis: devis}});
    }

    /**
     * Lorsqu'on clique sur le bouton pour voir le document devis signe
     * @param devis
     */
    function onClickVoirDocumentDevisSigne(devis){
      ctrl.onEmit({obj:{action: 'onClickVoirDocumentDevisSigne', devis: devis}});
    }

    /**
     * Lorsqu'on clique sur le bouton pour voir le document devis signe
     * @param devis
     */
    function onClickVoirDocumentFacture(devis){
      ctrl.onEmit({obj:{action: 'onClickVoirDocumentFacture', devis: devis}});
    }

    /**
     * Lorsqu'on clique sur le bouton pour signer le devis
     * @param devis
     */
    /*
    function onClickSignerDocument(devis){
        ctrl.onEmit({obj:{action: 'onClickSignerDocument', devis: devis}});
    }*/

    /**
     * Lorsque le formulaire de message remonte une information
     * @param obj
     */
    function onEmitFromFormulaireMessage(obj) {
      try {
        if (!angular.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');
        if (!obj.hasOwnProperty('action'))throw new Error('La propriété "action" n\'existe pas');

        if(obj.action === 'closeReply') closeForm(obj.contenu);
        if(obj.action === 'addMessage' && angular.isObject(obj.contenu)) {
          addContenu(obj.contenu);
          ctrl.onEmit({obj: {action: obj.action, message: obj.contenu}});
        }
        // TODO: dans le layout l'update de message n'est pas prise en compte
        if(obj.action === 'updateMessage' && angular.isObject(obj.contenu)) {
          updateContenu(obj.contenu);
          ctrl.onEmit({obj: {action: obj.action, message: obj.contenu}});
        }
      }
      catch (err) {
        $log.log('[DiscussionsCollabController.onEmitFromFormulaireMessage] Erreur : ' + err.message);
      }
    }

    /**
     * Lors de la remontée d'infos depuis le composant "icon-contenu-important"
     * @param obj
     */
    function onEmitFromIconContenuImportant(obj) {
      try {
        if (!_.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');
        if (!obj.hasOwnProperty('action'))throw new Error('La propriété "action" n\'existe pas');

        if(obj.action === 'updateContenu' && _.isObject(obj.contenu)) {
          updateContenu(obj.contenu);
          ctrl.onEmit({obj: {action: 'updateMessage', message: obj.contenu}});
        }
      }
      catch (err) {
        $log.log('[DiscussionsCollabController.onEmitFromIconContenuImportant] Erreur : ' + err.message);
      }
    }

    /**
     * Lorsque l'on reçoit une action du composant menu-demande
     * @param obj
     */
    function onEmitFromMenuDemande(obj){
      ctrl.onEmit({obj: obj});

      try {
        if(!angular.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');
        if(!obj.hasOwnProperty('action') && !obj.hasOwnProperty('actions')) throw new Error('Pas de propriété "action" ou "actions" dans le paramètre obj');

        if(angular.isObject(obj.actions)) {
          //if(obj.actions.hasOwnProperty('updateEvenement')) updateEvenement(obj.actions.updateEvenement);
          if(obj.actions.hasOwnProperty('addMessages') && angular.isArray(obj.actions.addMessages) && obj.actions.addMessages.length) {
            for(var i = 0; i < obj.actions.addMessages.length; i++)
              addContenu(obj.actions.addMessages[i]);
          }
        }
        else if(obj.action === 'updateDevis') {
          onUpdateDevis(null, obj);
        }
      }
      catch(err) {
        ErreurCollabService.logErreur('[DiscussionsCollabController.onEmitFromMenuDemande] - ' + err.message);
      }
    }

    function onUpdateDevis(devis, objUpdate) {
      var deffered = $q.defer();
      try {
        if (!objUpdate) {
          ErreurCollabService.logErreur(objUpdate);
          throw new Error('Il manque l\'objet "objUpdate" !');
        }

        // Si action delete
        if (objUpdate.hasOwnProperty('action')) {
          if(objUpdate.action === 'delete') {
            ctrl.loading = true;
            //$scope.$emit('showLoadingContenuEvenementFormulaire');

            devis
              .deleteDocumentFromFacture(objUpdate.document.getGuid())
              .then(function(contenu){
                $rootScope.$broadcast('refreshDocuments');
                deffered.resolve(contenu);
              })
              .catch(function (msg) {
                ModalsService.alertErreur(msg);
                deffered.reject();
              })
              .finally(function () {
                //$scope.$emit('hideLoadingContenuEvenementFormulaire');
                ctrl.loading = false;
              });
          }
          // Nouveau pour eviter de repercuter les update vers les parents
          else if(objUpdate.action === 'updateDevis') {
            if(objUpdate.hasOwnProperty('refreshTopic')){
              //ctrl.contenuEvenement.setData(objUpdate.update.evenement);
              $scope.$emit('refreshTopicDetail');
              deffered.resolve();
            }
            //emitUpdate = false;
            else if (objUpdate.hasOwnProperty('contenu')) {
              if (objUpdate.contenu.isFacture) {
                for (var i = 0; i < ctrl.listeDevis.length; i++) {
                  if (ctrl.listeDevis[i].idContenu === objUpdate.contenu.idDevisPere) {
                    ctrl.listeDevis[i].setFacture(objUpdate.contenu);
                  }
                }
                objUpdate.listeDevis = ctrl.listeDevis;
                $rootScope.$broadcast('refreshDocuments');
                deffered.resolve();
              }
              else if (objUpdate.contenu.isDevis) {
                ctrl.onEmit({obj:{action: 'updateDevis', devis: objUpdate.contenu}});
                updateContenu(objUpdate.contenu);
                deffered.resolve();
              }
            }
          }
        }
      }
      catch (err) {
        ErreurCollabService.logErreur('[DiscussionsCollabController.onUpdateDevis] Erreur : ' + err.message);
        deffered.reject();
      }
      return deffered.promise;
    }

    /**
     * Permet de formaté une date
     * @param date
     * @returns {string}
     */
    function dateParser(date) {
      return UtilsService.dateMomentParser(date);
    }
  }
})();
