(function() {

  'use strict';

  /**
   * @desc Componsant pour choisir un destinataire
   * @example <destinataire-ligne></destinataire-ligne>
   */

  angular
    .module('collaboreApp')
    .component('destinataireLigne',{
      templateUrl: 'app/topics/components/destinataire/destinataire-ligne.component.html',
      bindings : {
        groupe: '<',
        hideCollaborateurPresent: '<',    // Permet de cacher les collaborateur qui sont present dans le groupe passé en param
        onCheck: '&'
      },
      controllerAs: 'destinatairelignectrl',
      controller: DestinataireLigneController
    });

  /*@ngInject*/
  function DestinataireLigneController($sce, uuid){

    var _this = this;
    _this.trustAsHtml = $sce.trustAsHtml;
    _this.onCheckGroupe = onCheckGroupe;          // Lorsqu'on coche ou décoche le groupe
    _this.onClickBtnGroupe = onClickBtnGroupe;

    _this.$onInit = function() {
      _this.uuid = uuid.new();  // Identifiant unique pour les id des label et input
      //console.log(_this.groupe);
    };

    function onClickBtnGroupe(){
      _this.groupe.checked = !_this.groupe.checked;
      onCheckGroupe();
    }

    /**
     * Permet de notifié au composant parent l'état du groupe actuel
     */
    function emit(){
      _this.onCheck({groupeChecked: _this.groupe});
    }

    /**
     * Lorsqu'on coche ou décoche le groupe. Repercute le changement sur les enfants
     */
    function onCheckGroupe(){
      emit();
    }
  }
})();
