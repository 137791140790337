(function() {

  'use strict';

  /**
   * @desc Composant recapEnvois
   * @example <recap-envois></recap-envois>
   */
  angular
    .module('collaboreApp')
    .component('recapEnvois',{
      templateUrl: 'app/recap-envois/recap-envois.component.html',
      controllerAs: 'recapenvoisctrl',
      controller: RecapEnvoisCtrl
    });

  /** @ngInject */
  function RecapEnvoisCtrl($state, $timeout, $stateParams){
    var _this = this;
    _this.onSelectTab = onSelectTab;

    _this.$onInit = function() {
      onSelectTab($state.current.name);
    };

    function initTab(stateParams){

      if(!stateParams) stateParams = $stateParams;
      //console.log(stateParams);
      if(_.isObject(stateParams) && _.isObject(stateParams.currentTab)){
        if(stateParams.currentTab.hasOwnProperty('value')) {
          _this.currentTab = stateParams.currentTab.value;
          _this.currentTabIndex = stateParams.currentTabIndex.value;
          _this.currentTitle = stateParams.currentTitle.value;
        }
        else {
          _this.currentTab = stateParams.currentTab;
          _this.currentTabIndex = stateParams.currentTabIndex;
          _this.currentTitle = stateParams.currentTitle;
        }
      }
      else {
        _this.currentTab = 'recap_shootingmail';
        _this.currentTabIndex = 0;
        _this.currentTitle = 'Liste des envois - Shooting Mail';
      }
      /*
      switch($state.current.name) {
        case 'recapEnvois.recapSms':
          _this.currentTab = 'recap_sms';
          _this.currentTabIndex = 1;
          _this.currentTitle = 'Liste des envois - Sms';
          break;
        default:
          _this.currentTab = 'recap_shootingmail';
          _this.currentTabIndex = 0;
          _this.currentTitle = 'Liste des envois - Shooting Mail';
      }*/
    }

    function onSelectTab(nameTab){
      var stateConfig = $state.get(nameTab);
      initTab(stateConfig.params);
      /*
      _this.currentTab = nameTab;
      switch(nameTab) {
        case 'recapEnvois.recapSms':
          _this.currentTab = 'recap_sms';
          _this.currentTabIndex = 1;
          _this.currentTitle = 'Liste des envois - Sms';
          break;
        default:
          _this.currentTab = 'recap_shootingmail';
          _this.currentTabIndex = 0;
          _this.currentTitle = 'Liste des envois - Shooting Mail';
      }
      /*
      $timeout(function(){
        initTab();
      });*/
    }
  }
})();
