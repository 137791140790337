(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.Courrier
   * @description
   * # Courrier
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('Courrier', CourrierModel);

  /** @ngInject */
  function CourrierModel(Main, Groupe, Document, $injector) {

    var ContenuService = null, Portefeuille = null;

    function Courrier(contenuData) {

      if(angular.isObject(contenuData)){
        if(contenuData.hasOwnProperty('contenuReference')) ContenuService = $injector.get('ContenuService');
        if(contenuData.hasOwnProperty('portefeuille')) Portefeuille = $injector.get('Portefeuille');
      }

      Main.apply(this, arguments);
      this.hydrate(contenuData);
    }

    Courrier.prototype = {

      model: 'Courrier',
      isCourrier: true,

      // Id Courrier
      getIdCourrier: function(){
        return this.idCourrier;
      },
      setIdCourrier: function(id){
        this.idCourrier = id;
      },

      // groupeDestinataire
      getGroupeDestinataire: function(){
        return this.groupeDestinataire;
      },
      setGroupeDestinataire: function(dest){
        var instanceGroupe = dest;
        // Si c'est l'objet document on recupèrte le guid
        if(angular.isObject(dest) && !dest.hasOwnProperty('isModel')){
          instanceGroupe = new Groupe(dest);
        }
        this.groupeDestinataire = instanceGroupe;
      },

      // nomPrenom
      getNomPrenom: function(value){
        if (arguments.length) {
          this.setNomPrenom(value);
        } else {
          return this.nomPrenom;
        }
      },
      setNomPrenom: function(val){
        this.nomPrenom = val;
      },

      // rue
      getRue: function(value){
        if (arguments.length) {
          this.setRue(value);
        } else {
          return this.rue;
        }
      },
      setRue: function(val){
        this.rue = val;
      },

      // codePostal
      getCodePostal: function(value){
        if (arguments.length) {
          this.setCodePostal(value);
        } else {
          return this.codePostal;
        }
      },
      setCodePostal: function(val){
        this.codePostal = val;
      },

      // ville
      getVille: function(value){
        if (arguments.length) {
          this.setVille(value);
        } else {
          return this.ville;
        }
      },
      setVille: function(val){
        this.ville = val;
      },

      // numRar
      getNumRar: function(value){
        if (arguments.length) {
          this.setNumRar(value);
        } else {
          return this.numRar;
        }
      },
      setNumRar: function(val){
        this.numRar = val;
      },

      // numPerso
      getNumPerso: function(value){
        if (arguments.length) {
          this.setNumPerso(value);
        } else {
          return this.numPerso;
        }
      },
      setNumPerso: function(val){
        this.numPerso = val;
      },

      // libelle
      getLibelle: function(value){
        if (arguments.length) {
          this.setObjet(value);
        } else {
          return this.libelle;
        }
      },
      setLibelle: function(val){
        this.libelle = val;
      },

      // objet
      getObjet: function(value){
        if (arguments.length) {
          this.setObjet(value);
        } else {
          return this.objet;
        }
      },
      setObjet: function(val){
        this.objet = val;
      },

      // corp
      getCorp: function(value){
        if (arguments.length) {
          this.setCorp(value);
        } else {
          return this.corp;
        }
      },
      setCorp: function(val){
        this.corp = val;
      },

      // dateCreation
      getDateCreation: function(value){
        if (arguments.length) {
          this.setDateCreation(value);
        } else {
          return this.dateCreation;
        }
      },
      getDateCreationFormat: function(toDate){
        var retour = this.dateCreation;
        if(!toDate) {
          toDate = 'DD/MM/YYYY HH:mm';
        }
        if(retour) {
          if(angular.isString(toDate)) {
            retour = moment(retour).format(toDate);
          }
          else {
            retour = new Date(retour);
          }
        }
        return retour;
      },
      setDateCreation: function(val){
        this.dateCreation = val;
      },

      // dateModification
      getDateModification: function(value){
        if (arguments.length) {
          this.setDateModification(value);
        } else {
          return this.dateModification;
        }
      },
      getDateModificationFormat: function(toDate){
        var retour = this.dateModification;
        if(!toDate) {
          toDate = 'DD/MM/YYYY HH:mm';
        }
        if(retour) {
          if(angular.isString(toDate)) {
            retour = moment(retour).format(toDate);
          }
          else {
            retour = new Date(retour);
          }
        }
        return retour;
      },
      setDateModification: function(val){
        this.dateModification = val;
      },

      // contenuEvenement
      getContenuEvenement: function(){
        return this.contenuEvenement;
      },
      setContenuEvenement: function(contenu){
        var instanceContenuEvenement = contenu;
        if(angular.isObject(contenu) && !contenu.hasOwnProperty('isModel')){
          instanceContenuEvenement = ContenuService.instanceContenuByType(contenu);
        }
        this.contenuEvenement = instanceContenuEvenement;
      },

      // contenuReference
      getContenuReference: function(){
        return this.contenuReference;
      },
      setContenuReference: function(contenu){
        var instanceContenuReference = contenu;
        if(angular.isObject(contenu) && !contenu.hasOwnProperty('isModel')){
          instanceContenuReference = ContenuService.instanceContenuByType(contenu);
        }
        this.contenuReference = instanceContenuReference;
      },


      // Document
      getDocument: function(){
        return this.document;
      },
      setDocument: function(document){
        var instanceDocument = document;
        // Si c'est l'objet document on recupèrte le guid
        if(angular.isObject(document) && !document.hasOwnProperty('isModel')){
          instanceDocument = new Document(document);
        }
        this.document = instanceDocument;
      },

      // Portefeuille
      getPortefeuille: function(){
        return this.portefeuille;
      },
      setPortefeuille: function(val){
        if(angular.isObject(val) && !val.hasOwnProperty('isModel')){
          val = new Portefeuille(val);
        }
        this.portefeuille = val;
      },

      // signature
      getSignature: function(value){
        if (arguments.length) {
          this.setSignature(value);
        } else {
          return this.signature;
        }
      },
      setSignature: function(val){
        this.signature = val;
      },

    };

    angular.extend(Courrier.prototype, Main.prototype);

    return Courrier;
  }
})();
