(function () {
  'use strict';

  angular
    .module('collaboreApp',
      [

        //'ngCookies',
        'ngResource',
        'ngSanitize',
        'ngTouch',
        'ui.bootstrap',                           // Bootstrap pour AngularJS : https://angular-ui.github.io/bootstrap/
        'ui.router',                              // Gestion des routes : http://angular-ui.github.io/ui-router/site/
        'ui.select',                              // Champ Select : https://github.com/angular-ui/ui-select
        'LocalStorageModule',                     // Gestion du Local Storage : https://github.com/grevory/angular-local-storage
        'jlareau.bowser',                         // Detection des browser : https://github.com/jacqueslareau/angular-bowser
        'angular-toasty',                         // Notification Browser: https://github.com/invertase/angular-toasty
        'angular.filter',                         // Filtre AngularJS avanc� : https://github.com/a8m/angular-filter
        'angularFileUpload',                      // Upload AngularJS : https://github.com/nervgh/angular-file-upload
        'federicot.ng-message-center',            // (Utilis� ?) Gestion des messages : https://github.com/federicot/ng-message-center
        'xeditable',                              // Xeditable pour AngularJS : http://vitalets.github.io/angular-xeditable/
        'ngDragDrop',                             // Drag and Drop pour AngularJS : http://codef0rmer.github.io/angular-dragdrop/
        'cfp.hotkeys',                            // Gestion des touches claviers : https://github.com/chieffancypants/angular-hotkeys
        //'angularUtils.directives.dirPagination',  // A SUPPRIMER : Utilis� dans les document, le remplacer par pagination de ui-bootstrap
        //'modal.collabore',                        // Modal pour pi�ce jointe � d�veloper
        'vAccordion',                             // Accordion : http://lukaszwatroba.github.io/v-accordion/
        'ngAnimate',
        'ngWebSocket',                            // A activer pour les websocket : https://github.com/AngularClass/angular-websocket
        //'ckeditor',

        'ui.bootstrap-slider',                    // Slider: https://github.com/seiyria/angular-bootstrap-slider
        //'angularAwesomeSlider',
        'luegg.directives',                       // Scroll Glue (colle le scroll en bas de page) : https://github.com/Luegg/angularjs-scroll-glue
        'ngImgCrop',                              // Crop image : https://github.com/alexk111/ngImgCrop
        'ngDialog',                               // Modal : https://github.com/likeastore/ngDialog
        'hSweetAlert',                            // Modal (confirm,alert..) : https://github.com/leftstick/angular-sweetalert
        'signature',                              // Module de signature : https://github.com/szimek/signature_pad/,
        'ngScrollbars',                           // Scrollbar design : https://github.com/iominh/ng-scrollbars
        'toggle-switch',                          // Boutton Switch toggle : http://jumplink.github.io/angular-toggle-switch/
        'ngMask',                                 // Mask : https://github.com/candreoliveira/ngMask
        'ui.mask',                                // https://github.com/angular-ui/ui-mask
        'sly',                                    // Angular Optimizer : https://github.com/scalyr/angular
        'highcharts-ng',                          // HightCharts-ng : Adaptation de HighCharts pour AngularJS : https://github.com/pablojim/highcharts-ng
        'bm.uiTour',                              // WebSite Tour : http://benmarch.github.io/angular-ui-tour/
        'daterangepicker',                        // Range picker : https://github.com/fragaria/angular-daterangepicker
        'fsm',                                    // Stocky Header : https://github.com/FutureStateMobile/sticky-header
        'angularMoment',                          // MomentJS : https://github.com/urish/angular-moment
        'btford.socket-io',                       // Socket IO pour AngularJS : https://github.com/btford/angular-socket-io
        'slickCarousel',                          // Carousel: https://github.com/devmark/angular-slick-carousel
        'ngclipboard',                            // Clipboard: http://sachinchoolur.github.io/ngclipboard/
        'ngMap',                                  // Google Map https://github.com/allenhwkim/angularjs-google-maps
        'minicolors',                             // Color Picker: https://github.com/kaihenzler/angular-minicolors
        'angular-autogrow',                       // ATTENTION ! Fichier dans assets/lib
        'angularjs-dropdown-multiselect',         // Select : https://github.com/dotansimha/angularjs-dropdown-multiselect
        'infinite-scroll'                         // http://sroze.github.io/ngInfiniteScroll/
      ]
    );

  angular.element(document).ready(function () {
    angular.bootstrap(document, ['collaboreApp']);
  });

})();

