'use strict';

/*
 * Directive pour changer le titre de page par rapport à la route
 */
angular.module('collaboreApp').directive('title', [
  'COLLAB_CONF',
  'COLLAB_VALUES',
  '$rootScope',
  '$timeout',
  function (COLLAB_CONF,COLLAB_VALUES,$rootScope, $timeout) {
    var directiveDefinitionObject = {
      restrict: 'E',
      link: function(scope,element) {

        $rootScope.defaultTitle = '';

        var listener = function(event, toState) {

          $timeout(function() {

            var title = COLLAB_CONF.TITLE_DEFAULT;
            if(toState.data && toState.data.pageTitle){
              title += ' - ' + toState.data.pageTitle;
            }
            $rootScope.defaultTitle = title;
            if(_.isNumber($rootScope.nbNotifications) && $rootScope.nbNotifications !== 0){
              title = '(' + $rootScope.nbNotifications + ') ' + title;
            }
            //$rootScope.title = title;
            element[0].innerHTML = title;
            //$document[0].title = title;
            //$rootScope.title = title;
            //$rootScope.title = (toState.data && toState.data.pageTitle) ? toState.data.pageTitle : COLLAB_CONF.TITLE_DEFAULT;
          });
        };

        $rootScope.$on('$stateChangeSuccess', listener);
      }
    };
    return directiveDefinitionObject;
  }
]);

angular.module('collaboreApp').directive('sticky', [
  '$window',
  'UtilsService',
  function ($window, UtilsService) {
    var directiveDefinitionObject = {
      restrict: 'A',
      link: function(scope,element) {

        /*
         var positionTopElement = element[0].offsetTop,
         hauteurElement = element[0].offsetHeight,
         positionTopSticky = positionTopElement+hauteurElement;*/

        function onScroll(){
          angular.element(element[0]).css('top',$window.pageYOffset + 'px');
        }

        if(!UtilsService.onMobile())
          angular.element($window).off('scroll', onScroll).on('scroll', onScroll);
      }
    };
    return directiveDefinitionObject;
  }
]);


angular.module('collaboreApp').directive('capitalize', function() {
  return {
    require: 'ngModel',
    link: function(scope, element, attrs, modelCtrl) {
      var capitalize = function(inputValue) {
        if(angular.isUndefined(inputValue)) {
          inputValue = '';
        }
        var capitalized = inputValue.toUpperCase();
        if(capitalized !== inputValue) {
          modelCtrl.$setViewValue(capitalized);
          modelCtrl.$render();
        }
        return capitalized;
      };
      modelCtrl.$parsers.push(capitalize);
      capitalize(scope[attrs.ngModel]);  // capitalize initial value
    }
  };
});

angular.module('collaboreApp').directive('latinize', ['$filter',function($filter) {
  return {
    require: 'ngModel',
    link: function(scope, element, attrs, modelCtrl) {

      var latinize = function(inputValue) {
        if(angular.isUndefined(inputValue)) {
          inputValue = '';
        }

        // Supprime les éventuel accent
        var latinized = $filter('latinize')(inputValue);

        // Si la chain est alpha numérique
        if($filter('test')(latinized,'^[a-z0-9]+$','ig')){

          // Si différente de jusqte avant (pour eviter les boucles)
          if(latinized !== inputValue) {
            modelCtrl.$setViewValue(latinized);
            modelCtrl.$render();
          }
          // Si autres caractère que alpha numérique
        }else{
          // Supprime le dernier caractère
          latinized = latinized.slice(0,-1);
          modelCtrl.$setViewValue(latinized);
          modelCtrl.$render();
        }
        return latinized;
      };
      modelCtrl.$parsers.push(latinize);
      latinize(scope[attrs.ngModel]);  // latinize initial value
    }
  };
}]);

/**
 * Permet de stop l'event du click sur les liens
 */
angular.module('collaboreApp').directive('clickPrevent', function() {
  return {
    restrict: 'A',
    link: function(scope, elem) {
      function preventClick(e){
        e.preventDefault();
        e.stopPropagation();
      }
      elem.on('click', preventClick);

      scope.$on('$destroy', function () {
        elem.off('click', preventClick);
      });
    }
  };
});

/**
 * Permet de focus le champ qui a la directive "focusWhen" et quand sa valeur est à true
 */
angular.module('collaboreApp').directive('focusWhen', function() {
  return {
    restrict: 'A',
    scope: {
      focusWhen: '='
    },
    link: function(scope, elem) {
      scope.$watch('focusWhen',function(newVal){
        if(newVal){
          elem[0].focus();
        }
      });
    }
  };
});


angular.module('collaboreApp').directive('showFocus', ['$timeout',function($timeout) {
  return function(scope, element, attrs) {
    scope.$watch(attrs.showFocus,
      function (newValue) {
        $timeout(function() {
          if(newValue && element.focus()){
            return true;
          }
        });
      },true);
  };
}]);

/**
 * Permet de mettre un event resize sur un element
 * Cette directive utilise le service qui calcul la taille de la fenetre
 */
/*
angular.module('collaboreApp').directive('resize', ['$window', 'dimensionService',function($window, dimensionService) {
  return {
    link: function(scope) {
      angular.element($window).on('resize', function() {
        scope.$broadcast('resize::resize', dimensionService.getWindowDimension());
      });
    }
  };
}]);*/

/**
 * Permet d'emit un evenement quand le ng-repeat est terminé d'affiché
 */
angular.module('collaboreApp').directive('onFinishRender',['$timeout', function ($timeout) {
  return {
    restrict: 'A',
    link: function (scope, element, attr) {
      if (scope.$last === true) {

        $timeout(function () {
          scope.$emit(attr.onFinishRender);
        });
      }
    }
  };
}]);

/**
 * Permet d'emit un evenement quand le ng-repeat est terminé d'affiché
 */
angular.module('collaboreApp').directive('onElemReady',['$interval', function ($interval) {
  return {
    restrict: 'A',
    link: function (scope, element, attr) {
      var event;
      event = $interval(function(){
        scope.$emit(attr.onElemReady);
        $interval.cancel(event);
      },0,1,false);

    }
  };
}]);

/**
 * The ng-thumb directive
 * @author: nerv
 * @version: 0.1.2, 2014-01-09
 */
angular.module('collaboreApp').directive('ngThumb', ['$window', function($window) {
  var helper = {
    support: Boolean($window.FileReader && $window.CanvasRenderingContext2D),
    isFile: function(item) {
      return angular.isObject(item) && item instanceof $window.File;
    },
    isImage: function(file) {
      var type = '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
      return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
    }
  };

  return {
    restrict: 'A',
    template: '<canvas/>',
    link: function(scope, element, attributes) {
      if (!helper.support) {return;}

      var params = scope.$eval(attributes.ngThumb);

      if (!helper.isFile(params.file)) {return;}
      if (!helper.isImage(params.file)) {return;}

      var canvas = element.find('canvas');
      var reader = new FileReader();

      var onLoadFile = function(event) {
        var img = new Image();
        img.onload = onLoadImage;
        img.src = event.target.result;
      };

      var onLoadImage = function() {
        var width = params.width || this.width / this.height * params.height;
        var height = params.height || this.height / this.width * params.width;
        canvas.attr({width: width, height: height});
        canvas[0].getContext('2d').drawImage(this, 0, 0, width, height);
      };

      reader.onload = onLoadFile;
      reader.readAsDataURL(params.file);
    }
  };
}]);

angular.module('collaboreApp').directive('compile',[
  '$compile',
  '$parse',
  function ($compile,$parse) {
    var directiveDefinitionObject = {
      restrict: 'A',
      link: function(scope, element, attr) {
        scope.$watch(attr.compile, function() {
          element.html($parse(attr.compile)(scope));
          $compile(element.contents())(scope);
        }, true);
      }
    };
    return directiveDefinitionObject;
  }
]);

/**
 * Permet d'excuter une fonction quand on click en dehors d'un element
 * Utilisation : <a click-anywhere-but-here="clickedSomewhereElse()"
 ng-click="clickedHere()">Don't Click Me!</a>
 * http://stackoverflow.com/questions/12931369/click-everywhere-but-here-event
 */
angular.module('collaboreApp').directive('clickAnywhereButHere', ['$document', 'clickAnywhereButHereService',function($document, clickAnywhereButHereService){
  return {
    restrict: 'A',
    link: function(scope, elem, attr) {
      var handler = function(e) {
        e.stopPropagation();
      };
      elem.on('click', handler);

      scope.$on('$destroy', function(){
        elem.off('click', handler);
      });

      clickAnywhereButHereService(scope, attr.clickAnywhereButHere);
    }
  };
}]);

/**
 * Permet de mettre une image d'erreur dans un element img si erreur 404
 * <img ng-src="smiley.png" err-src="http://google.com/favicon.ico" />
 */
angular.module('collaboreApp').directive('errSrc', function() {
  return {
    link: function(scope, element, attrs) {
      //console.log(attrs.ngSrc);
      element.bind('error', function() {
        if (attrs.src !== attrs.errSrc) {
          attrs.$set('src', attrs.errSrc);
        }
      });
      attrs.$observe('ngSrc', function(value) {
        if (!value && attrs.errSrc) {
          attrs.$set('src', attrs.errSrc);
        }
      });
    }
  };
});

/**
 * Permet de creer une image de loading sous l'img qui appel la directive
 * <img ng-src="images/i.jpg" loading-src="loading.gif">
 */
angular.module('collaboreApp').directive('loadingSrcAvatar', [function() {
  return {
    link: function(scope, element,attrs) {

      var img = new Image();
      img.src = attrs.loadingSrcAvatar;
      img.height = 18;
      img.width = 18;

      element.after(img);

      function onLoad() {
        angular.element(img).remove();
      }

      function onError() {
        angular.element(img).remove();
        element.remove();
        //element[0].src = COLLAB_CONF.SRC_NO_PHOTO;
      }

      element.on('load', onLoad);
      element.on('error', onError);

      scope.$on('$destroy', function(){
        element.off('load', onLoad);
        element.off('error', onError);
      });
    }
  };
}]);

angular.module('collaboreApp').directive('loadingSrc', ['COLLAB_CONF',function(COLLAB_CONF) {
  return {
    link: function(scope, element,attrs) {

      var img = new Image();
      img.src = attrs.loadingSrc;

      var divImg = angular.element('<div class="margin-top-20"></div>');
      divImg.append(img);

      element.after(divImg);

      function onLoad() {
        divImg.remove();
      }

      function onError() {
        divImg.remove();
        element[0].src = COLLAB_CONF.SRC_NO_PHOTO;
      }

      element.on('load', onLoad);
      element.on('error', onError);

      scope.$on('$destroy', function(){
        element.off('load', onLoad);
        element.off('error', onError);
      });
    }
  };
}]);

/**
 * Bootstrap-toggle Directive
 * @link https://github.com/minhur/bootstrap-toggle
 */
angular.module('collaboreApp').directive('toggleCheckbox', function() {

  /**
   * Directive
   */
  return {
    restrict: 'A',
    transclude: true,
    replace: false,
    require: 'ngModel',
    link: function ($scope, $element, $attr, require) {

      var ngModel = require;

      // update model from Element
      var updateModelFromElement = function() {
        // If modified
        var checked = $element.prop('checked');

        if (checked !== ngModel.$viewValue) {
          // Update ngModel
          ngModel.$setViewValue(checked);
          $scope.$apply();
        }
        /*
         if(checked){
         if($attr.disabledOnTrue==='true'){
         $timeout(function(){
         $element.bootstrapToggle('disable');
         });

         }
         }*/
      };

      // Update input from Model
      var updateElementFromModel = function() {
        // Update button state to match model
        $element.trigger('change');
      };

      // Observe: Element changes affect Model
      $element.on('change', function() {
        updateModelFromElement();
      });

      // Observe: ngModel for changes
      $scope.$watch(function() {
        return ngModel.$viewValue;
      }, function() {

        //$element.data('bs.toggle').off(false);
        updateElementFromModel();
      });


      // Initialise BootstrapToggle
      $element.bootstrapToggle();
    }
  };
});

/**
 * Signature
 */
angular.module('collaboreApp').directive('signatureCollab', [
  '$timeout',
  'SignatureService',
  function ($timeout,SignatureService) {

    var signaturePad, canvasTemoin,ctxTemoin, canvasSignature, element,
      EMPTY_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=';
    return {
      restrict: 'EA',
      replace: true,
      template: '<div><div ng-show="loadingSignature" class="mask-loading"></div>' +

      '<div ng-hide="loadingSignature" >' +

      '<h4 ng-bind-html="title"></h4>' +
      '<div class="signature" ng-style="{position: \'relative\',height: height + \'px\', width: width + \'px\',\'margin-left\':\'auto\',\'margin-right\':\'auto\'}"><div ng-if="mode===\'viewer\'" class="mask-disabled"></div><canvas id="canvasSignature" style="position:absolute" height="{{ height }}" width="{{ width }}" ng-style="signaturecollabctrl.padStyle"></canvas><canvas id="canvasTemoin"  height="{{ height }}" width="{{ width }}"></canvas></div>' +

      '<div class="row margin-top-10 margin-bottom-10" ng-if="mode!==\'viewer\'">' +
      '<div class="col-md-12 text-center">' +
      '<button button="button" class="btn btn-danger margin-right-5" ng-click="onClickClearSignature()" ng-bind-html="libelleDelete"></button>' +
      '<button button="button" class="btn btn-success" ng-click="onClickSaveSignature()" ng-bind-html="libelleAccept"></button>' +
      '</div>' +
      '</div>' +

      '<div class="row margin-top-10 margin-bottom-10" ng-if="mode===\'viewer\'">' +
      '<div class="col-md-12 text-center">' +
      '<button button="button" class="btn btn-default" ng-click="onClickEditSignature()">Modifier</button>' +
      '</div>' +
      '</div>' +

      '</div>' +
      '</div>',
      scope: {
        title:'@',
        libelleAccept: '@',
        libelleDelete: '@',
        accept: '=',
        clear: '=',
        height: '@',
        width: '@',
        signature: '=?',
        //optionsSignaturePad: '=',
        onBegin: '&',
        onEnd: '&',
        //padStyle: '=',
        mode: '='
      },
      controllerAs: 'signaturecollabctrl',
      controller: [
        '$scope',
        '$q',
        'sweet',
        function ($scope,$q,sweet) {

          //var vm = this;

          var getSign = function () {
            var signature = {};

            if (!signaturePad.isEmpty()) {
              signature.dataUrl = signaturePad.toDataURL();
              signature.$isEmpty = false;
            } else {
              signature.dataUrl = EMPTY_IMAGE;
              signature.$isEmpty = true;

            }
            signature.width = $scope.width;
            signature.height = $scope.height;

            return signature;
          };

          /*
           $scope.getSignature = function(){
           var deferred= $q.defer();
           $scope.loadingSignature = true;
           ParamsService.getSignature().then(function(json){
           $scope.loadingSignature = false;
           var sign = {
           'dataUrl': 'data:image/png;base64,'+json.docString,
           '$isEmpty': false
           };
           deferred.resolve(sign);
           },function(){
           $scope.loadingSignature = false;
           deferred.reject();
           });
           return deferred.promise;
           };*/

          /**
           * Sauvegarde de la signature
           * @param signature
           */
          $scope.onClickSaveSignature = function(){

            //console.log($scope.sign);

            var signature = getSign();

            if(!signature.$isEmpty){

              $scope.loadingSignature = true;
              //console.log(signature);


              var tabSign = signature.dataUrl.split(',');

              SignatureService.addSignature(tabSign[1]).then(function(){

                sweet.show({
                  title: 'Signature',
                  text: 'Signature sauvegardée !',
                  type: 'success',
                  html: false
                });

              },function(msg){

                sweet.show({
                  title: 'Signature',
                  text: msg,
                  type: 'error',
                  html: false
                });
              }).finally(function(){
                $scope.loadingSignature = false;
              });


              /*
               var tabSign;

               var canvas = document.createElement('canvas'),
               ctx = canvas.getContext('2d');

               var image = new Image(),
               ratio = 3,
               width = Math.ceil(signature.width / ratio),
               height = Math.ceil(signature.height / ratio),
               newBase64 = false;

               image.src = signature.dataUrl;

               canvas.width = width;
               canvas.height = height;
               console.log(canvas);

               image.onload = function () {
               ctx.drawImage(image, 0, 0, width, height);

               newBase64 = canvas.toDataURL();
               tabSign = newBase64.split(',');

               SignatureService.addSignature(tabSign[1]).then(function(){
               $scope.loadingSignature = false;
               sweet.show({
               title: 'Signature',
               text: 'Signature sauvegardée !',
               type: 'success',
               html: false
               });

               },function(msg){
               $scope.loadingSignature = false;
               sweet.show({
               title: 'Signature',
               text: msg,
               type: 'error',
               html: false
               });
               });
               };*/
            }
          };



          $scope.onClickClearSignature = function () {
            sweet.show({
              title: 'Effacer la signature ?',
              text: 'Vous êtes sûr ?',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'Oui !',
              closeOnConfirm: true
            },function() {
              signaturePad.clear();
            });
          };


          $scope.onClickEditSignature = function(){
            $scope.mode = 'edit';
          };
        }
      ],
      link: function ($scope, $element, $attrs) {

        var optionsSignaturePad = {
          minWidth: 0.5,
          maxWidth: 2.5,
          penColor: 'black',
          velocityFilterWeight: 0.7
        };

        $scope.title = ($scope.title) ? $scope.title : 'Signature';
        $scope.libelleAccept = ($scope.libelleAccept) ? $scope.libelleAccept : 'Sauvegarder';
        $scope.libelleDelete = ($scope.libelleDelete) ? $scope.libelleDelete : 'Effacer';
        $scope.signaturecollabctrl.padStyle = ($scope.signaturecollabctrl.padStyle) ? $scope.signaturecollabctrl.padStyle : {border:'1px dotted'};
        $scope.mode = ($scope.mode) ? $scope.mode : 'edit';

        $timeout(function(){
          var diffWidth = Math.ceil($scope.width * 0.1);
          var diffHeight = Math.ceil($scope.height * 0.1);
          var widthTemoin = $scope.width - diffWidth;
          var heightTemoin = $scope.height - diffHeight;
          var marginTopTemoin = Math.ceil(diffHeight / 2);
          var marginLeftTemoin = Math.ceil(diffWidth / 2);

          canvasTemoin = $element.find('#canvasTemoin').get(0);

          ctxTemoin = canvasTemoin.getContext('2d');
          ctxTemoin.beginPath();
          ctxTemoin.lineWidth = '1';
          ctxTemoin.lineJoin = 'miter';
          ctxTemoin.strokeStyle = '#CCCCCC';
          ctxTemoin.setLineDash([5,2]);
          ctxTemoin.rect(marginLeftTemoin,marginTopTemoin,widthTemoin,heightTemoin);
          ctxTemoin.stroke();
        });

        /*
         if(!angular.isObject(optionsSignaturePad)){
         $scope.optionsSignaturePad = {
         'minWidth': 0.5,
         'maxWidth': 2.5,
         'penColor': 'black',
         'velocityFilterWeight': 0.7
         };
         }*/

        if('onBegin' in $attrs){
          optionsSignaturePad.onBegin = $scope.onBegin;
        }

        // Lors de la fin de la signature
        optionsSignaturePad.onEnd = function(event){

          if('onEnd' in $attrs) {
            $scope.onEnd()(event);
          }

          if (!signaturePad.isEmpty()) {
            var signTmp = {
              dataUrl: signaturePad.toDataURL(),
              $isEmpty: false
            };
            $scope.signature = signTmp;
          }
        };


        canvasSignature = $element.find('canvas#canvasSignature');
        element = $element;
        signaturePad = new SignaturePad(canvasSignature.get(0),optionsSignaturePad);

        if (!$scope.height) {
          $scope.height = 220;
        }
        if (!$scope.width) {
          $scope.width = 568;
        }



        $scope.loadingSignature = true;
        SignatureService.getSignature().then(function(json){
          $scope.loadingSignature = false;
          var sign = {
            dataUrl: 'data:image/png;base64,' + json.docString,
            $isEmpty: false
          };
          $scope.signature = sign;
          signaturePad.fromDataURL(sign.dataUrl);
        },function(){
          $scope.loadingSignature = false;
        });


        if ($scope.signature && !$scope.signature.$isEmpty && $scope.signature.dataUrl) {
          signaturePad.fromDataURL($scope.signature.dataUrl);
        }





        /*
         $scope.temoin = {
         'position': 'absolute',
         'border': '1px dashed #f00',
         'width': widthTemoin+'px',
         'height': heightTemoin+'px',
         'margin-top': marginTopTemoin+'px',
         'margin-left': marginLeftTemoin+'px'
         };*/
        /*
         width: 450px;
         height: 282px;
         position: absolute;
         border: 1px dashed #f00;
         margin-top: 25px;
         margin-left: 25px;*/
      }
    };
  }
]);

/**
 * Tampon
 */
angular.module('collaboreApp').directive('tamponCollab', [
  '$timeout',
  '$parse',
  'UtilsService',
  '$filter',
  'TamponService',
  function ($timeout,$parse,UtilsService,$filter,TamponService) {
    return {
      restrict: 'EA',
      replace: true,
      scope: {
        tampon: '='
      },
      templateUrl: 'app/scripts/divers/directives/templates/tampon-collabore.html',
      link: function(scope){

        //var attrModel = $parse(attrs.tamponCollab);

        //scope.tampon = {};

        if(!angular.isObject(scope.tampon)){
          scope.tampon = {};
          //var tamp = $parse(scope.tam);
          //tamp.assign(scope, scope.tampon);
          //attrs.$set('tampon',scope.tampon);
        }

        scope.tampon.addNew = {
          show: false,
          val: '',
          msg: false,
          loading: false
        };
        scope.tampon.liste = {
          search: '',
          tampons: [],
          tamponsFiltered: [],
          msg: false,
          loading: false
        };
        scope.tampon.selected = false;

        /*
         scope.$watchCollection('tampon',function(val){
         attrModel.assign(scope, val);
         });*/
      },
      controller: function($scope,COLLAB_CONF,sweet){

        $scope.onSubmitFilterModules = function(){
          $scope.tampon.liste.tamponsFiltered = angular.copy($filter('filter')($scope.tampon.liste.tampons,$scope.tampon.liste.search));
        };

        /**
         * Affiche le textarea pour ajouter un tampon
         */
        $scope.showAddNewTampon = function(){
          $scope.tampon.addNew.show = true;
          $scope.tampon.addNew.val = '';
        };

        /**
         * Cache le textarea du nouveau tampon
         */
        $scope.hideAddNewTampon = function(){
          $scope.tampon.addNew.show = false;
          $scope.tampon.addNew.val = '';
        };

        /**
         * Recupère la liste des tampons
         */
        $scope.getTampons = function(){

          $scope.tampon.liste.loading = COLLAB_CONF.MIDDLE_LOADING;
          $scope.tampon.liste.msg = false;
          $scope.tampon.liste.tampons = [];
          TamponService.getTampons().then(function(tampons){
            angular.forEach(tampons,function(tampon){
              tampon.texteNl2br = UtilsService.nl2br(tampon.texte);
              $scope.tampon.liste.tampons.push(tampon);
            });

            //$scope.tampon.liste.tampons = tampons;
          },function(msg){
            $scope.tampon.liste.msg = msg;
          }).finally(function(){
            $scope.tampon.liste.loading = false;
          });
        };

        /**
         * Sauvegarde un nouveau tampon
         */
        $scope.saveNewTampon = function(){

          $scope.tampon.addNew.loading = COLLAB_CONF.MIDDLE_LOADING;
          TamponService.addTampon($scope.tampon.addNew.val).then(function(tampon){
            //console.log(tampon);

            tampon.texteNl2br = UtilsService.nl2br(tampon.texte);
            $scope.tampon.liste.tampons.push(tampon);

            $scope.tampon.addNew.show = false;
            $scope.tampon.addNew.val = '';
            $scope.getTampons();
          },null).finally(function(){
            $scope.tampon.addNew.loading = false;
          });
        };

        /**
         * Select un tampon
         * @param tampon
         */
        $scope.selectTampon = function(tampon){
          tampon.selected = !tampon.selected;
          if(tampon.selected){
            $scope.tampon.selected = tampon;
          }else{
            $scope.tampon.selected = false;
          }
        };

        $scope.deleteTampon = function(tampon){
          sweet.show({
            title: 'Voulez-vous vraiment supprimer :',
            text: tampon.texte,
            type: 'info',
            showCancelButton: true,
            confirmButtonText: 'Oui !',
            closeOnConfirm: false,
            showLoaderOnConfirm: true
          }, function () {
            TamponService.deleteTampon(tampon).finally(function(){
              $scope.getTampons();
              sweet.close();
            });
          });
        };

        $scope.$watchCollection('tampon.liste.tampons',function(newVal,oldVal){
          if(newVal !== oldVal){
            $scope.tampon.liste.tamponsFiltered = UtilsService.fuzzyBy(newVal,'texte',$scope.tampon.liste.search);
          }
        });

        $timeout(function(){
          $scope.getTampons();
        });


      }
    };
  }
]);


angular.module('collaboreApp').directive('modalChangeAvatar', ['$uibModal',function ($uibModal) {
  return {
    restrict: 'EA',
    link: function (scope,element) {

      scope.cropper = {
        myImage: '',
        myCroppedImage: ''
      };

      element.bind('click',function(){

        var modalInstance = $uibModal.open({
          animation: true,
          size: 'lg',
          windowClass: 'modal-collabore modal-annuaire-collab',
          backdrop: 'static',
          templateUrl: 'app/scripts/groupes/directives/templates/modal-change-avatar.html',
          controller: ['COLLAB_VALUES', 'COLLAB_CONF', '$rootScope','$scope','$uibModalInstance', 'FileUploader', 'UserService','sweet', function(COLLAB_VALUES, COLLAB_CONF, $rootScope,$scope,$uibModalInstance, FileUploader, UserService, sweet){

            /*
             $scope.isIE9 = false;
             if (bowser.msie && bowser.version <= 9) {
             $scope.isIE9 = true;
             }*/
            $scope.cropper = {
              myImage: '',
              myCroppedImage: ''
            };

            // Permet de vider le champ après avoir selectionné une image
            /*
             FileUploader.FileSelect.prototype.isEmptyAfterSelection = function() {
             return true; // true|false
             };*/

            /**
             * Initialisation et configuration de l'uploader
             */
            var uploader = $scope.uploader = new FileUploader({
              autoUpload: false,
              queueLimit:1,
              url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_AVATAR,
              alias: 'fileName',
              removeAfterUpload : false,
              filters: [
                {
                  name: 'sizeLimit',
                  fn: function(item) {
                    if(item.size <= COLLAB_CONF.SIZE_LIMIT_UPLOAD_AVATAR){
                      return true;
                    }
                    // Si il y a un fichier dans la liste d'upload
                    if(uploader.queue.length === 1){
                      sweet.show({
                        title: '<span class="text-danger">' + item.name + '</span> trop gros !',
                        text: '2 Mo Maximum',
                        type: 'error',
                        html: true
                      });
                    }

                  }
                },
                {
                  name: 'imageFilter',
                  // A user-defined filter
                  fn: function(item) {
                    var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                    //return '|jpg|png|jpeg|gif|'.indexOf(type) !== -1;
                    if(('|jpg|png|jpeg|gif|'.indexOf(type) !== -1) === false){
                      sweet.show({
                        title: '<span class="text-danger">' + item.name + '</span> doit être une image !',
                        text: 'Fichier Image obligatoire !',
                        type: 'error',
                        html: true
                      });
                    }else{
                      return true;
                    }
                  }
                }]
            });

            /**
             * Après avoir ajouté une image affiche l'image retaillée
             */
            uploader.onAfterAddingFile = function(item) {
              if(uploader.isHTML5) {
                var reader = new FileReader();
                reader.onload = function (event) {
                  $scope.$apply(function () {
                    $scope.cropper.myImage = event.target.result;
                  });
                };
                reader.readAsDataURL(item._file);

                item.formData.push({token: UserService.getGroupe().getToken()});
              }else{
                console.log(item);
                item.url += '?token=' + UserService.getGroupe().getToken();
              }
            };

            /**
             * Upload Blob (cropped image) instead of file.
             * @see
             *   https://developer.mozilla.org/en-US/docs/Web/API/FormData
             *   https://github.com/nervgh/angular-file-upload/issues/208
             */
            uploader.onBeforeUploadItem = function(item) {
              $scope.showProgressBar = true;
              if(uploader.isHTML5){
                var blob = dataURItoBlob($scope.cropper.myCroppedImage);
                item._file = blob;
              }
            };

            /**
             * Après chaque succes d'upload
             */
            uploader.onSuccessItem = function(fileItem, response) {
              /*
               console.log(response);
               console.log(status);
               console.log(headers);*/
              if(response){
                // Si la reponse de la servlet est ok
                if(response.nb !== '0'){
                  var avatar = response.Users[0].nomAvatar;
                  UserService.getGroupe().getUser().setNomAvatar(avatar);
                  $rootScope.$broadcast('updateAvatar',avatar);
                  $scope.close();
                }
              }else{
                /*
                 if(!uploader.isHTML5){

                 }else{

                 }*/

                sweet.show({
                  title: 'Erreur d\'upload !',
                  text: 'Pas de réponse...',
                  type: 'error',
                  html: true
                });

              }
            };




            /**
             * Converts data uri to Blob. Necessary for uploading.
             * @see
             *   http://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
             * @param  {String} dataURI
             * @return {Blob}
             */
            var dataURItoBlob = function(dataURI) {
              // convert base64/URLEncoded data component to raw binary data held in a string
              var byteString;
              if (dataURI.split(',')[0].indexOf('base64') >= 0) {
                byteString = atob(dataURI.split(',')[1]);
              } else {
                byteString = decodeURI(dataURI.split(',')[1]);
              }
              var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
              var array = [];
              for(var i = 0; i < byteString.length; i++) {
                array.push(byteString.charCodeAt(i));
              }
              return new Blob([new Uint8Array(array)], {type: mimeString});
            };



            // Après chaque erreur d'upload
            uploader.onErrorItem = function(fileItem, response, status) {
              sweet.show({
                title: 'Erreur d\'upload !',
                text: 'Regarder la console pour l\'erreur',
                type: 'error',
                html: true
              });
              console.log('fileItem',fileItem);
              console.log('response',response);
              console.log('status',status);
            };

            /**
             * Upload
             */
            $scope.sauvegarder = function () {
              uploader.uploadAll();
            };

            $scope.deleteImage = function(){

              // Vide le champ input file
              var input = angular.element('#fileAvatar');
              input.val('');

              // Réinit les images
              $scope.cropper = {
                myImage: '',
                myCroppedImage: ''
              };

              // Efface la liste des upload
              uploader.clearQueue();
            };


            $scope.close = function () {
              uploader.destroy();
              $uibModalInstance.close();
              //$modalInstance.dismiss('cancel');
            };
          }],
          resolve: {
            cropper: function () {
              return scope.cropper;
            }
          }
        });

        /*
         modalInstance.rendered.then(function () {

         fileInput  = angular.element('.input-file-container').find('.input-file');
         console.log(fileInput);
         });*/

        modalInstance.result.then(function () {
          console.log('Modal  ' + new Date());
        }, function () {
          console.log('Modal dismissed at: ' + new Date());
        });
      });
    }
  };
}]);

angular.module('collaboreApp').directive('avatar', ['COLLAB_CONF','COLLAB_VALUES','$rootScope','UserService',function (COLLAB_CONF,COLLAB_VALUES,$rootScope,UserService) {
  return {
    restrict: 'EA',
    replace: true,
    scope: {
      user: '=',
      avatarStyle: '='
    },
    templateUrl: 'app/scripts/groupes/directives/templates/avatar.html',
    link: function (scope, elem) {

      // Loading de l'avatar si chargement
      scope.loadingAvatar = COLLAB_CONF.MIDDLE_LOADING_SRC;

      // Url l'image si pas d'avatar
      scope.srcNoPhoto = COLLAB_CONF.SRC_NO_PHOTO;

      // Url du dossier des avatars
      scope.urlAvatar = COLLAB_VALUES.CONF_URL.URL_AVATARS;

      //console.log(scope.user);

      if(scope.user) {


        if (angular.isDefined(scope.user.nomAvatar) && angular.isString(scope.user.nomAvatar)) {
          scope.avatar = scope.user.nomAvatar;
        }else{
          scope.avatar = false;
        }



      }else{
        // Avatar 'nom.ext'
        scope.avatar = UserService.getGroupe().getUser().getNomAvatar();

        var listenerUpdatAvatar = $rootScope.$on('updateAvatar',function(event,avatar){
          if(avatar){
            scope.avatar = avatar;
          }else{
            scope.avatar = UserService.getGroupe().getUser().getNomAvatar();
          }
        });

        elem.on('$destroy', function(){
          if(listenerUpdatAvatar) listenerUpdatAvatar();
        });
      }
    }
  };
}]);

angular.module('collaboreApp').directive('fixedHeader', ['$timeout',function($timeout){
  return {
    restrict: 'A',
    link: function ($scope, $elem, $attrs) {
      var elem = $elem[0];

      var tableDataLoaded = function() {
        // first cell in the tbody exists when data is loaded but doesn't have a width
        // until after the table is transformed
        var firstCell = elem.querySelector('tbody tr:first-child td:first-child');
        return firstCell && !firstCell.style.width;
      };


      var transformTable = function() {
        // reset display styles so column widths are correct when measured below
        angular.element(elem.querySelectorAll('thead, tbody, tfoot')).css('display', '');

        // wrap in $timeout to give table a chance to finish rendering
        $timeout(function () {
          // set widths of columns
          angular.forEach(elem.querySelectorAll('tr:first-child th'), function (thElem, i) {

            var tdElems = elem.querySelector('tbody tr:first-child td:nth-child(' + (i + 1) + ')');
            var tfElems = elem.querySelector('tfoot tr:first-child td:nth-child(' + (i + 1) + ')');

            var columnWidth = tdElems ? tdElems.offsetWidth : thElem.offsetWidth;
            if (tdElems) {
              tdElems.style.width = columnWidth + 'px';
            }
            if (thElem) {
              thElem.style.width = columnWidth + 'px';
            }
            if (tfElems) {
              tfElems.style.width = columnWidth + 'px';
            }
          });

          // set css styles on thead and tbody
          angular.element(elem.querySelectorAll('thead, tfoot')).css('display', 'block');

          angular.element(elem.querySelectorAll('tbody')).css({
            display: 'block',
            height: $attrs.tableHeight || 'inherit',
            overflow: 'auto'
          });

          // reduce width of last column by width of scrollbar
          var tbody = elem.querySelector('tbody');
          var scrollBarWidth = tbody.offsetWidth - tbody.clientWidth;
          if (scrollBarWidth > 0) {
            // for some reason trimming the width by 2px lines everything up better
            scrollBarWidth -= 2;
            var lastColumn = elem.querySelector('tbody tr:first-child td:last-child');
            lastColumn.style.width = (lastColumn.offsetWidth - scrollBarWidth) + 'px';
          }
        });
      };

      // wait for data to load and then transform the table
      $scope.$watch(tableDataLoaded, function (isTableDataLoaded) {
        if (isTableDataLoaded) {
          transformTable();
        }
      });
    }
  };
}]);

angular.module('collaboreApp').directive('popoverModel', function () {
  return {
    restrict: 'AE',
    replace: false,
    transclude: true,
    scope: {
      //templateModel: '=',
      popoverModel: '=',
      popoverAppendToBody: '=',
      popoverModelTemplate: '=',
      popoverModelTitle: '@',
      popoverModelPlacement: '@',
      popoverModelTrigger: '@',
      onEmit: '&'
    },
    template: '<div class="inline" uib-popover-template="popoverModelTemplate" popover-placement="{{placement}}" popover-title="{{popoverModelTitle}}" popover-trigger="\'{{trigger}}\'" popover-is-open="popupIsOpen" popover-class="popover-collab popover-lg" popover-append-to-body="{{toBody}}" ng-click="onClick()"><ng-transclude></ng-transclude></div>',
    link: function(scope){
      scope.placement = scope.popoverModelPlacement || 'top';
      scope.templateModel = scope.popoverModel;
      scope.toBody = scope.popoverAppendToBody;
      scope.trigger = scope.popoverModelTrigger || 'outsideClick';
    },
    controller: ['$scope', function($scope){
      $scope.popupIsOpen = false;

      $scope.onClick = function(){
        $scope.popupIsOpen = !$scope.popupIsOpen;
      };

      $scope.$watch('popoverModel', function(newVal) {
        $scope.templateModel = newVal;
      });
    }]
  };
});

var setImmediate = window && window.setImmediate ? window.setImmediate : function (fn) {
  setTimeout(fn, 0);
};
/*
 angular.module('collaboreApp').directive('ckEditor', ['$timeout','$parse','$q',function ($timeout,$parse,$q) {
 return {
 require: '?ngModel',
 scope: {
 ckEditorFocus: '=',
 ckEditorModelIsUpdate: '='
 //ckEditorOnBlur: '&'
 //ckEditorDebounce: '=',
 //ready: '&'
 },
 link: function (scope, elm, attrs,ngModel) {

 if (!ngModel) {
 return;
 }

 console.log('attrs.ckEditor',$parse(attrs.ckEditor)(scope));
 var config = $parse(attrs.ckEditor)(scope) || {};
 var instance;
 var readyDeferred = $q.defer(); // a deferred to be resolved when the editor is ready
 var debounce = parseInt(attrs.ckEditorDebounce) || 0;

 //var focus = $parse(attrs.ckEditorFocus)(scope);

 var editorElement = elm[0];

 // Create editor instance.
 if (editorElement.hasAttribute('contenteditable') &&
 editorElement.getAttribute('contenteditable').toLowerCase() === true) {
 instance = CKEDITOR.inline(editorElement, config);
 }
 else {
 instance = CKEDITOR.replace(editorElement, config);
 }

 instance.on('instanceReady', function() {
 instance.setData(ngModel.$viewValue);
 setImmediate(function () {
 $parse(attrs.ready)(scope);
 });
 });

 var timeout = false;

 function onUpdateModel(event) {


 //if(scope.ckEditorModelIsUpdate){
 //  scope.$digest();
 //}

 //console.log('onUpdateModel',event.name);




 //console.log(scope.ckEditorModelIsUpdate);

 var tmpDebounce = debounce;
 if(event.name==='blur'){
 tmpDebounce = 0;
 }

 // Si il y a un timeout càd que la frappe est rapide
 if(timeout){
 if(scope.ckEditorModelIsUpdate===true){
 $timeout(function() {
 // Emit l'update du modelCkeditor et digest
 scope.$apply(function () {
 scope.ckEditorModelIsUpdate = false;
 //scope.$emit('updateModelCkeditor',false);
 });
 });
 }

 // Canel le précédent timeout
 $timeout.cancel(timeout);
 }



 timeout = $timeout(function(){
 updateModel();
 },tmpDebounce);
 }

 function updateModel(){
 //console.log('updateModel');

 var data = instance.getData();

 //console.log('apply');
 scope.$apply(function () {

 scope.ckEditorModelIsUpdate = true;
 if (data !== ngModel.$viewValue) {

 ngModel.$setViewValue(instance.getData());
 }
 //scope.$emit('updateModelCkeditor',true);

 });

 }

 function onBlur(){
 scope.ckEditorFocus = false;

 updateModel();

 //if(scope.ckEditorOnBlur){
 //  scope.ckEditorOnBlur()();
 //}
 }
 function onFocus(){
 scope.ckEditorFocus = true;
 }

 instance.on('pasteState', onUpdateModel);
 //instance.on('change', onUpdateModel);
 //instance.on('key', onUpdateModel);
 instance.on('dataReady', onUpdateModel);
 instance.on('saveSnapshot', onUpdateModel);

 instance.on('blur', onBlur);
 instance.on('focus', onFocus);


 // Destroy editor when the scope is destroyed.
 scope.$on('$destroy', function onDestroy() {
 readyDeferred.promise.then(function() {
 instance.destroy(false);
 });
 });

 ngModel.$render = function () {
 instance.setData(ngModel.$modelValue);
 };
 }
 };
 }]);
 */

angular.module('collaboreApp').directive('ckeditor', [function () {
  return {
    require: ['ckeditor'],
    scope: {
      ckeditor: '=',
      ckeditorOptions: '=',
      ckeditorReady: '&'
    },
    link: function (scope, elm, attrs,ctrls) {

      var controller = ctrls[0]; // our own, see below

      // Initialize the editor content when it is ready.
      controller.ready().then(function initialize() {
        controller.instance.setReadOnly(false);
        setImmediate(function () {
          if('ckeditorReady' in attrs){scope.ckeditorReady({instance: controller.instance});}
        });
      });
    },
    controller: ['$scope', '$element', '$attrs','$parse','$q',function($scope, $element, $attrs,$parse,$q){
      var config = $scope.ckeditorOptions || {};
      var editorElement = $element[0];
      var instance;
      var readyDeferred = $q.defer(); // a deferred to be resolved when the editor is ready

      // Create editor instance.
      if (editorElement.hasAttribute('contenteditable') &&
        editorElement.getAttribute('contenteditable').toLowerCase() === 'true') {
        instance = $scope.ckeditor = this.instance = CKEDITOR.inline(editorElement, config);
      }
      else {
        instance = $scope.ckeditor = this.instance = CKEDITOR.replace(editorElement, config);
      }

      instance.on('instanceReady', function() {
        readyDeferred.resolve(true);
      });

      this.ready = function ready() {
        return readyDeferred.promise;
      };

      // Destroy editor when the scope is destroyed.
      $scope.$on('$destroy', function onDestroy() {
        if(typeof (instance) !== 'undefined' && instance !== null){
          instance.destroy(true);
        }
      });
    }]
  };
}]);


//angular.module('collaboreApp').directive('ckEditor', ['$timeout',function ($timeout) {
//  return {
//    require: ['ckEditor', 'ngModel'],
//    scope: {
//      ckEditorFocus: '=',
//      ckEditorModelIsUpdate: '=',
//      ckEditorOptions: '=',
//      //ckEditorOnBlur: '&'
//      //ckEditorDebounce: '=',
//      ready: '&'
//    },
//    link: function (scope, elm, attrs,ctrls) {
//
//      if('ready' in attrs){
//        scope.fctOnReady = scope.ready;
//      }
//
//      var debounce = parseInt(attrs.ckEditorDebounce) || 0;
//
//      var focus = false;
//      var timeout = false;
//      var controller = ctrls[0]; // our own, see below
//      var ngModelController = ctrls[1];
//
//      function onUpdateModel(event) {
//
//        // Si il y a un timeout càd que la frappe est rapide
//        if(timeout){
//          if(scope.ckEditorModelIsUpdate===true){
//            $timeout(function() {
//              // Emit l'update du modelCkeditor et digest
//              scope.$apply(function () {
//                scope.ckEditorModelIsUpdate = false;
//                //scope.$emit('updateModelCkeditor',false);
//              });
//            });
//          }
//          // Canel le précédent timeout
//          $timeout.cancel(timeout);
//        }
//
//        if(focus){
//          var tmpDebounce = debounce;
//          if(event.name==='blur'){
//            tmpDebounce = 0;
//          }
//
//          timeout = $timeout(function(){
//            //console.log(event.name);
//            updateModel();
//          },tmpDebounce);
//        }
//      }
//
//      function updateModel() {
//        //console.log('updateModel');
//        var data = controller.instance.getData();
//        scope.$apply(function () {
//          scope.ckEditorModelIsUpdate = true;
//          if (data !== ngModelController.$viewValue) {
//            //console.log(controller.instance.getData());
//            ngModelController.$setViewValue(controller.instance.getData());
//          }
//        });
//      }
//
//      function onBlur(){
//        focus = false;
//        scope.ckEditorFocus = false;
//        updateModel();
//      }
//
//      function onFocus(){
//        focus = true;
//        scope.ckEditorFocus = true;
//      }
//
//
//      // Initialize the editor content when it is ready.
//      controller.ready().then(function initialize() {
//
//        /*
//        // Sync view on specific events.
//        ['pasteState', 'dataReady', 'saveSnapshot'].forEach(function (event) {
//          controller.onCKEvent(event,onUpdateModel);
//        });
//
//        controller.onCKEvent('blur', onBlur);
//        controller.onCKEvent('focus', onFocus);*/
//
//        controller.instance.on('pasteState', onUpdateModel);
//        controller.instance.on('dataReady', onUpdateModel);
//        controller.instance.on('saveSnapshot', onUpdateModel);
//        controller.instance.on('blur', onBlur);
//        controller.instance.on('focus', onFocus);
//
//        controller.instance.setReadOnly(false);
//
//        setImmediate(function () {
//          if(scope.fctOnReady){
//            scope.fctOnReady();
//          }
//        });
//      });
//
//      // Set editor data when view data change.
//      ngModelController.$render = function syncEditor() {
//        controller.ready().then(function () {
//
//
//          //var data = CKEDITOR.dom.element.createFromHtml('<div>'+ngModelController.$viewValue+'</div>');
//          //controller.instance.insertElement(data);
//          /*
//          var $elementData = angular.element(ngModelController.$viewValue);
//          if($elementData.length){
//            for(var j = 0; j < $elementData.length ; j++){
//              var element = new CKEDITOR.dom.element($elementData[j]);
//              controller.instance.insertElement(element);
//              if(element.hasClass('blockquoteGroupeUser')){
//                controller.instance.widgets.initOn(element,'blockquoteGroupeUser');
//              }
//            }
//
//            var widgets = controller.instance.widgets.checkWidgets();
//            console.log('widgets',widgets);
//          }
//          */
//          controller.instance.setData(ngModelController.$viewValue || '');
//        });
//      };
//
//    },
//    controller: ['$scope', '$element', '$attrs','$parse','$q',function($scope, $element, $attrs,$parse,$q){
//      var config = $scope.ckEditorOptions || {};
//      var editorElement = $element[0];
//      var instance;
//      var readyDeferred = $q.defer(); // a deferred to be resolved when the editor is ready
//
//      // Create editor instance.
//      if (editorElement.hasAttribute('contenteditable') &&
//          editorElement.getAttribute('contenteditable').toLowerCase() === 'true') {
//        instance = this.instance = CKEDITOR.inline(editorElement, config);
//      }
//      else {
//        instance = this.instance = CKEDITOR.replace(editorElement, config);
//      }
//      /*
//
//      this.onCKEvent = function (event, listener) {
//
//        instance.on(event, asyncListener);
//
//        function asyncListener() {
//          var args = arguments;
//          setImmediate(function () {
//            applyListener.apply(null, args);
//          });
//        }
//
//        function applyListener() {
//          var args = arguments;
//          $scope.$apply(function () {
//            listener.apply(null, args);
//          });
//        }
//
//        // Return the deregistration function
//        return function $off() {
//          instance.removeListener(event, applyListener);
//        };
//
//      };
//
//      this.onCKEvent('instanceReady', function() {
//        readyDeferred.resolve(true);
//      });*/
//
//
//      instance.on('instanceReady', function() {
//        readyDeferred.resolve(true);
//      });
//
//      this.ready = function ready() {
//        return readyDeferred.promise;
//      };
//
//      // Destroy editor when the scope is destroyed.
//      $scope.$on('$destroy', function onDestroy() {
//        if(typeof(instance) !== 'undefined' && instance !== null){
//          instance.destroy(true);
//        }
//        /*
//        readyDeferred.promise.then(function() {
//
//        });*/
//      });
//    }]
//  };
//}]);

/**
 * Directive qui permet de limiter le nombre de caractère dans un champ
 */
angular.module('collaboreApp').directive('limitText', ['COLLAB_CONF','$rootScope',function (COLLAB_CONF,$rootScope) {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      nbReste: '=?'
    },
    link: function (scope, elm, attrs, ngModel) {
      attrs.$set('ngTrim', 'false');

      var nbCaracteresForSms = COLLAB_CONF.OLD_NB_MAX_CARACTERES_SMS,
        nbCaracteresPrefixMsg = ($rootScope.current.userGroupe.getDieze().length + COLLAB_CONF.NB_CARACTERES_AFTER_SOCIETE),
        limitChar = (attrs.limitText) ? parseInt(attrs.limitText,10) : (nbCaracteresForSms - nbCaracteresPrefixMsg);

      ngModel.$formatters.push(function(value){
        if(value){
          scope.nbReste = limitChar - value.length;
        }
        return value;
      });

      ngModel.$parsers.push(function (value) {
        scope.nbReste = limitChar - value.length;
        if (value.length > limitChar) {
          value = value.substr(0, limitChar);
          ngModel.$setViewValue(value);
          ngModel.$render();
        }
        return value;
      });
    }
  };
}]);

/**
 * Directive qui remplace ng-include pour eviter la création d'un scope inutile du ng-include
 */
angular.module('collaboreApp').directive('staticInclude', ['$http', '$templateCache', '$compile',function($http, $templateCache, $compile) {
  return function(scope, element, attrs) {
    var templatePath = attrs.staticInclude;
    $http.get(templatePath, {cache: $templateCache}).then(function(response) {
      var contents = element.html(response.data).contents();
      $compile(contents)(scope);
    });
  };
}]);

// Use the ui-select-on-open-focus directive on the ui-select element
/*
 angular.module('collaboreApp').directive('uiSelectOnOpenFocus', ['$timeout', function($timeout){
 return {
 require: 'uiSelect',
 restrict: 'A',
 link: function($scope, el, attrs, uiSelect) {
 var closing = false;

 //console.log(uiSelect);
 //console.log(uiSelect.focusser);

 angular.element(uiSelect.focusser).on('focus', function() {
 if(!closing) {
 uiSelect.activate();
 }
 });

 // Because ui-select immediately focuses the focusser after closing
 // we need to not re-activate after closing
 $scope.$on('uis:close', function() {
 closing = true;
 $timeout(function() { // I'm so sorry
 closing = false;
 });
 });
 }
 };
 }]);*/

angular.module('collaboreApp').directive('collabTooltip', ['$parse', 'UtilsService', function($parse, UtilsService){
  return {
    restrict: 'A',
    replace: true,
    link: function (scope, element, attrs) {

      if(attrs.collabTooltip !== '') {
        var enabled = $parse(attrs.enableCollabTooltip)(scope);

        if(angular.isUndefined(enabled) || enabled === true){
          //var options = (attrs.collabTooltipOptions) ? attrs.collabTooltipOptions : {'animation':false};
          var options = (attrs.collabTooltipOptions) ? $parse(attrs.collabTooltipOptions)(scope) : {};
          options.animation = false;
          options.title = attrs.collabTooltip;
          //options.placement = (attrs.collabTooltipPlacement) ? attrs.collabTooltipPlacement : 'top';
          //options.viewport = {'selector':'body','padding':0};
          options.container = 'body';
          options.trigger = 'hover';
          options.template = '<div class="tooltip tooltip-collab" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>';


          var $element = $(element);
          var parent = $element.parent();
          if(parent.length) {
            var elementsForContainerBody = [
              'TR'
            ];
            if(UtilsService.contains(elementsForContainerBody,parent[0].tagName)){

              options.container = 'body';
            }
          }

          $element.tooltip(options);

          var onClique = function(){
            $element.tooltip('hide');
          };

          $element.on('click',onClique);

          $element.on('$destroy', function () {
            $element.off('click',onClique);
          });

          /*
           $element.bind('mouseover',function(){
           // on mouseenter
           $element.tooltip('show');
           });

           $element.bind('mouseout',function(){
           // on mouseenter
           $element.tooltip('hide');
           });*/

          scope.$on('$destroy', function() {
            $element.tooltip('destroy');
          });
        }
      }


    }
  };
}]);


angular.module('collaboreApp').directive('iframeCollabore', ['$sce','$window','$parse',function($sce,$window,$parse){
  return {
    restrict: 'AE',
    replace: true,
    template: '<div><div ng-show="showLoading" class="loadingBig center-block"></div><iframe ng-hide="showLoading" frameborder="0" scrolling="no" width="100%" ng-src="{{url}}"></iframe></div>',
    link: function(scope, element,attrs){

      scope.url = $sce.trustAsResourceUrl(attrs.url);
      scope.showLoading = true;

      scope.height = (attrs.height) ? parseInt(attrs.height) : false;
      scope.maxHeight = (attrs.maxHeight) ? parseInt(attrs.maxHeight) : false;

      // On essaie d'être compatible avec le max de navigateurs
      var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
      var eventer = window[eventMethod];
      var resizeEvent = eventMethod === 'attachEvent' ? 'onresize' : 'resize';
      var scrollEvent = eventMethod === 'attachEvent' ? 'onscroll' : 'scroll';

      var iframe = angular.element(element).find('iframe');

      // Ecoute l'event "resize" venant de l'iframe
      eventer(resizeEvent,function() {
        resize();
      },false);

      // Ecoute l'event "scroll" venant de l'iframe
      eventer(scrollEvent,function() {
        resize();
      },false);

      // A la fin du chargement cache le loading et affiche l'iframe
      iframe.on('load', function(){
        scope.$apply(function(){
          scope.showLoading = false;
          resize();
          if(attrs.onReady){
            var onReady = $parse(attrs.onReady)(scope);
            console.log(onReady);
            //onReady();
          }
        });
      });

      function resize(){
        if(scope.height){
          iframe[0].height = scope.height + 'px';
        }else{
          var hauteur = $window.innerHeight + $window.scrollY;
          if(scope.maxHeight){
            if(hauteur <= scope.maxHeight){
              iframe[0].height = hauteur + 'px';
            }
          }else{
            iframe[0].height = hauteur + 'px';
          }
        }

      }
    }
  };
}]);

angular.module('collaboreApp').directive('iframeWidget', ['$sce','$window',function($sce,$window){
  return {
    restrict: 'A',
    replace: true,
    template: '<div><div ng-show="showLoading" class="loadingBig center-block"></div><iframe ng-hide="showLoading" frameborder="0" scrolling="no" width="100%" ng-src="{{url}}"></iframe></div>',
    link: function(scope, element,attrs){

      scope.url = $sce.trustAsResourceUrl(attrs.iframeWidget);
      scope.showLoading = true;

      // On essaie d'être compatible avec le max de navigateurs
      var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
      var eventer = window[eventMethod];
      var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

      var iframe = angular.element(element).find('iframe');

      // Ecoute l'event "message" venant de l'iframe
      eventer(messageEvent,function(e) {

        if(e.data.hasOwnProperty('extranet')) {
          // Maj de la hauteur de l'iFrame
          if(e.data.extranet.height){
            iframe[0].height = e.data.extranet.height + 'px';
          }

          // Scroll vers MMC
          if(e.data.extranet.goScroll){
            scope.$apply(function(){
              $window.scrollTo(0, e.data.extranet.goScroll);
              //$('html,body').animate({scrollTop:e.data.extranet.goScroll},800);
            });
          }
        }

        if(e.data.hasOwnProperty('hauteurIframe')) {
          // Maj de la hauteur de l'iFrame
          if(e.data.hauteurIframe.height){
            iframe[0].height = e.data.hauteurIframe.height + 'px';
          }
        }


      },false);

      // A la fin du chargement cache le loading et affiche l'iframe
      iframe.on('load', function(){
        scope.$apply(function(){
          scope.showLoading = false;
        });
      });
    }
  };
}]);
