(function() {

  'use strict';

  /**
   * @desc Composant d'upload
   * @example <upload-collab></upload-collab>
   */
  angular
    .module('collaboreApp')
    .component('uploadCollab',{
      templateUrl: 'app/components/upload-collab/upload-collab.component.html',
      controllerAs: 'uploadcollabctrl',
      bindings: {
        uploader: '<',
        eventMode: '<', // Si à true alors le bouton d'upload est caché et le choix des doc est déclenché par événement
        //openChooseDoc:'<',
        dropzone:'<',
        customClassBtnParcourir: '@',
        whenAddingFile: '<',
        textUpload: '@',
        showQueue: '<',
        showDocsTopic: '<',
        contenuParent: '<',
        addDocuments: '<',
        disabled: '<',
        hideNotifUpload: '<',
        action: '<',
        idTopic: '<',
        idContenu: '<',
        sourceUpload: '<',
        typeDocument: '<',
        disableModalError: '<',
        dropOnlyWithoutTypeDocument: '<',

        url: '<',
        alias: '@',
        autoUpload: '<',
        removeAfterUpload: '<',
        queueLimit: '<',
        sizeLimit: '<',
        onlyExtensions: '<',
        convertToPdf: '<',

        onEmit: '&',

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<'
      },
      controller: UploadCollabCtrl
    });

  /** @ngInject */
  function UploadCollabCtrl($scope, $log, COLLAB_VALUES, COLLAB_CONF, DocumentManagerService, DocumentsTopicService, ModalsService, UtilsService, FileUploader, NotificationsToastServices, ErreurCollabService){
    var _this = this, uploaderCreateHere = false;
    var defaultAction = 'uploadDocument';
    //var listDocsTopicChecked = [];  // Liste des documents déjà présent dans le topic qui sont ajouter en pièces jointes
    _this.bytes = UtilsService.bytes;
    _this.uuid = UtilsService.uuid.new();
    _this.isImage = UtilsService.isImage;
    _this.documentsDroppedFromDocs = [];

    // Tableau des pièces jointes à envoyer
    _this.documentDemande = null;		            // Document demande du devis actuel quand ouvert la communication
    _this.documentDevis = null;			            // Document devis du devis actuel quand ouvert la communication
    _this.documentDevisSigne = null;	                // Document devis du devis signe actuel quand ouvert la communication
    _this.documentAttestationTvaSimple = null;	    // Document attestation tva simplifiée quand ouvert par la communication
    _this.documentFacture = null;	                // Document facture
    _this.listDocumentContratFournisseurSigne = null;	// Document contrat fournisseur signe
    _this.listDocumentContratAgenceSigne = null;	        // Document contrat agence signe

    _this.onCheckDocumentPartage = onCheckDocumentPartage;
    _this.onDropDocument = onDropDocument;
    _this.removeDocument = removeDocument;
    _this.onClickClose = onClickClose;
    _this.onEmitFromUploadCollabQueue = onEmitFromUploadCollabQueue;

    _this.$onInit = function() {
      if(_this.resolve) {
        _this.uploader = UtilsService.getParamModalComponent(_this.resolve, 'uploader');
        _this.dropzone = UtilsService.getParamModalComponent(_this.resolve, 'dropzone');
        _this.eventMode = UtilsService.getParamModalComponent(_this.resolve, 'eventMode');
        //_this.openChooseDoc = UtilsService.getParamModalComponent(_this.resolve, 'openChooseDoc');
        _this.customClassBtnParcourir = UtilsService.getParamModalComponent(_this.resolve, 'customClassBtnParcourir');
        _this.whenAddingFile = UtilsService.getParamModalComponent(_this.resolve, 'whenAddingFile');
        _this.textUpload = UtilsService.getParamModalComponent(_this.resolve, 'textUpload');
        _this.showQueue = UtilsService.getParamModalComponent(_this.resolve, 'showQueue');
        _this.showDocsTopic = UtilsService.getParamModalComponent(_this.resolve, 'showDocsTopic');
        _this.contenuParent = UtilsService.getParamModalComponent(_this.resolve, 'contenuParent');
        _this.addDocuments = UtilsService.getParamModalComponent(_this.resolve, 'addDocuments');
        _this.disabled = UtilsService.getParamModalComponent(_this.resolve, 'disabled');
        _this.hideNotifUpload = UtilsService.getParamModalComponent(_this.resolve, 'hideNotifUpload');
        _this.action = UtilsService.getParamModalComponent(_this.resolve, 'action');
        _this.idTopic = UtilsService.getParamModalComponent(_this.resolve, 'idTopic');
        _this.idContenu = UtilsService.getParamModalComponent(_this.resolve, idContenu);
        _this.typeDocument = UtilsService.getParamModalComponent(_this.resolve, 'typeDocument');
        _this.sourceUpload = UtilsService.getParamModalComponent(_this.resolve, 'sourceUpload');
        _this.dropOnlyWithoutTypeDocument = UtilsService.getParamModalComponent(_this.resolve, 'dropOnlyWithoutTypeDocument');

        _this.url = UtilsService.getParamModalComponent(_this.resolve, 'url');
        _this.alias = UtilsService.getParamModalComponent(_this.resolve, 'alias');
        _this.autoUpload = UtilsService.getParamModalComponent(_this.resolve, 'autoUpload');
        _this.removeAfterUpload = UtilsService.getParamModalComponent(_this.resolve, 'removeAfterUpload');
        _this.queueLimit = UtilsService.getParamModalComponent(_this.resolve, 'queueLimit');
        _this.sizeLimit = UtilsService.getParamModalComponent(_this.resolve, 'sizeLimit');
        _this.onlyExtensions = UtilsService.getParamModalComponent(_this.resolve, 'onlyExtensions');
        _this.convertToPdf = UtilsService.getParamModalComponent(_this.resolve, 'convertToPdf');
        _this.disableModalError = UtilsService.getParamModalComponent(_this.resolve, 'disableModalError');
      }

      if(!_this.uploader && !_this.url) {
        _this.url = COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_UPLOAD_DOCUMENT;
        if(!_.isArray(_this.whenAddingFile)) _this.whenAddingFile = [];
        var actionFound = false;
        if(_this.whenAddingFile.length){
          for(var i = 0; i < _this.whenAddingFile.length; i++){
            if(_.isObject(_this.whenAddingFile[i]) && _this.whenAddingFile[i].action){
              actionFound = true;
              break;
            }
          }
        }
        if(!actionFound) _this.whenAddingFile.push({action: defaultAction});
        // ModalsService.alertErreur('Pas d\'uploader ou d\'url');
      }

      if(_.isNil(_this.dropzone)) _this.dropzone = true;

      if(_this.eventMode){
        $scope.$on('openUpload', function(ev, uuid){
          console.log(uuid);
          if(uuid === _this.uuid) {
            angular.element("#upload_"+_this.uuid).trigger("click");
          }
        });
      }

      $scope.$on('resetDocumentsDroppedFromDocs', function (){
        _this.documentsDroppedFromDocs = [];
      });

      if(!_this.textUpload) _this.textUpload = "Déposez vos fichiers ici ou cliquez pour sélectionner";
      init();
      emit({'action': 'uploaderReady', uuid: _this.uuid});
    };

    _this.$onChanges = function(changes) {
      if(_.isObject(changes.uploader) && !changes.uploader.isFirstChange()) _this.uploader = changes.uploader.currentValue;
      if(_.isObject(changes.dropzone) && !changes.dropzone.isFirstChange()) _this.dropzone = changes.dropzone.currentValue;
      if(_.isObject(changes.eventMode) && !changes.eventMode.isFirstChange()) _this.eventMode = changes.eventMode.currentValue;
      /*
      if(_.isObject(changes.openChooseDoc) && !changes.openChooseDoc.isFirstChange()) {
        console.log(changes.openChooseDoc);
        angular.element("#upload_"+_this.uuid).trigger("click");
      }*/
      if(_.isObject(changes.customClassBtnParcourir) && !changes.customClassBtnParcourir.isFirstChange()) _this.customClassBtnParcourir = changes.customClassBtnParcourir.currentValue;
      if(_.isObject(changes.whenAddingFile) && !changes.whenAddingFile.isFirstChange()) _this.whenAddingFile = changes.whenAddingFile.currentValue;
      if(_.isObject(changes.textUpload) && !changes.textUpload.isFirstChange()) _this.textUpload = changes.textUpload.currentValue;
      if(_.isObject(changes.showQueue) && !changes.showQueue.isFirstChange()) _this.showQueue = changes.showQueue.currentValue;
      if(_.isObject(changes.url) && !changes.url.isFirstChange()) _this.url = changes.url.currentValue;
      if(_.isObject(changes.alias) && !changes.alias.isFirstChange()) _this.alias = changes.alias.currentValue;
      if(_.isObject(changes.autoUpload) && !changes.autoUpload.isFirstChange()) _this.autoUpload = changes.autoUpload.currentValue;
      if(_.isObject(changes.removeAfterUpload) && !changes.removeAfterUpload.isFirstChange()) _this.removeAfterUpload = changes.removeAfterUpload.currentValue;
      if(_.isObject(changes.queueLimit) && !changes.queueLimit.isFirstChange()) _this.queueLimit = changes.queueLimit.currentValue;
      if(_.isObject(changes.sizeLimit) && !changes.sizeLimit.isFirstChange()) _this.sizeLimit = changes.sizeLimit.currentValue;
      if(_.isObject(changes.onlyExtensions) && !changes.onlyExtensions.isFirstChange()) _this.onlyExtensions = changes.onlyExtensions.currentValue;
      if(_.isObject(changes.showDocsTopic) && !changes.showDocsTopic.isFirstChange()) _this.showDocsTopic = changes.showDocsTopic.currentValue;
      if(_.isObject(changes.contenuParent) && !changes.contenuParent.isFirstChange()) _this.contenuParent = changes.contenuParent.currentValue;
      if(_.isObject(changes.addDocuments) && !changes.addDocuments.isFirstChange()) _this.addDocuments = changes.addDocuments.currentValue;
      if(_.isObject(changes.disabled) && !changes.disabled.isFirstChange()) _this.disabled = changes.disabled.currentValue;
      if(_.isObject(changes.hideNotifUpload) && !changes.hideNotifUpload.isFirstChange()) _this.hideNotifUpload = changes.hideNotifUpload.currentValue;
      if(_.isObject(changes.action) && !changes.action.isFirstChange()) _this.action = changes.action.currentValue;
      if(_.isObject(changes.idTopic) && !changes.idTopic.isFirstChange()) _this.idTopic = changes.idTopic.currentValue;
      if(_.isObject(changes.idContenu) && !changes.idContenu.isFirstChange()) _this.idContenu = changes.idContenu.currentValue;
      if(_.isObject(changes.typeDocument) && !changes.typeDocument.isFirstChange()) _this.typeDocument = changes.typeDocument.currentValue;
      if(_.isObject(changes.sourceUpload) && !changes.sourceUpload.isFirstChange()) _this.sourceUpload = changes.sourceUpload.currentValue;
      if(_.isObject(changes.convertToPdf) && !changes.convertToPdf.isFirstChange()) _this.convertToPdf = changes.convertToPdf.currentValue;
      if(_.isObject(changes.disableModalError) && !changes.disableModalError.isFirstChange()) _this.disableModalError = changes.disableModalError.currentValue;
    };
    _this.$onDestroy = function() {};

    /**
     * Permet d'init un objet pour la notification d'upload
     */
    function initNotifWaitUpload() {
      _this.notifWaitUpload = {
        progress: 0,
        obj: false,
        show: false
      };
    }

    function init(){
      if(_this.showDocsTopic) {
        // Liste des document du topic
        // TODO: A remplacer par DocumentManagerService ?
        _this.listeDocuments = angular.copy(DocumentsTopicService.getListDocument());
        _this.countPiecesJointesInCategorie = DocumentsTopicService.getNbDocumentInCategorie;
      }

      if(_.isNil(_this.uploader)) {
        var objetUploader = {
          autoUpload: (!_.isNil(_this.autoUpload)) ? _this.autoUpload : false,
          url: _this.url,
          alias: (!_.isNil(_this.alias)) ? _this.alias : 'fileName',
          removeAfterUpload: (!_.isNil(_this.removeAfterUpload)) ? _this.removeAfterUpload : true,
          filters: []
        };
        if(!_.isNil(_this.queueLimit)) objetUploader.queueLimit = _this.queueLimit;

        // Si pas de taille limit défini alors on prend la limite par défaut
        if(_.isNil(_this.sizeLimit)) _this.sizeLimit = COLLAB_CONF.SIZE_LIMIT_UPLOAD;

        // Si type de document attachement alors limit la taille
        if(!_.isNil(_this.typeDocument) && _this.typeDocument === COLLAB_CONF.TYPE_DOC_ENUMS.ATTACHMENT) _this.sizeLimit = COLLAB_CONF.SIZE_LIMIT_ATTACHMENTS;


        objetUploader.filters.push({
          name: 'sizeLimit',
          fn: function (item) {

            if (item.size <= _this.sizeLimit) return true;
            else ModalsService.alertErreur('<span class="text-danger">' + item.name + '</span> trop gros !', UtilsService.bytes(_this.sizeLimit) + ' Maximum');
          }
        });

        if(_.isArray(_this.onlyExtensions)) {
          objetUploader.filters.push({
            name: 'onlyExtensions',
            fn: function (item) {
              var type = item.type.slice(item.type.lastIndexOf('/') + 1);
              if(extensionIsAllow(item.name, type)) return true;
            }
          });
        }

        // Si upload pas automatiquement les fichiers et que le paramètre "show-queue" est null
        // alors on affiche la liste des fichier en attentes d'upload
        if(!objetUploader.autoUpload && _.isNil(_this.showQueue)) _this.showQueue = true;
        else if(objetUploader.autoUpload) _this.showQueue = false;

        _this.uploader = new FileUploader(objetUploader);
        uploaderCreateHere = true;
      }

      initNotifWaitUpload();

      if(!_.isNil(_this.uploader)) {

        if(_.isArray(_this.whenAddingFile)){
          // Si il y a un type de document à ajouter à l'appel
          var addAction = !_.isNil(_this.action);
          var addConvertToPdf = !_.isNil(_this.convertToPdf);
          var addSourceUpload = !_.isNil(_this.sourceUpload);
          var addTypeDoc = !_.isNil(_this.typeDocument);
          var addIdTopic = !_.isNil(_this.idTopic);
          var addIdContenu = !_.isNil(_this.idContenu);

          for(var n = 0; n < _this.whenAddingFile.length; n++){
            var itemAdd = _this.whenAddingFile[n];
            if(_.isObject(itemAdd)) {
              if (addAction && itemAdd.action) {
                itemAdd.action = _this.action;
                addAction = false;
              }
              if (addConvertToPdf && itemAdd.convertToPdf) {
                itemAdd.convertToPdf = _this.convertToPdf;
                addConvertToPdf = false;
              }
              if (addTypeDoc && itemAdd.typeDocument) {
                itemAdd.typeDocument = _this.typeDocument;
                addTypeDoc = false;
              }
              if (addIdTopic && itemAdd.idTopic) {
                itemAdd.idTopic = _this.idTopic;
                addIdTopic = false;
              }
              if (addIdContenu && itemAdd.idContenu) {
                itemAdd.idContenu = _this.idContenu;
                addIdContenu = false;
              }
              if (addSourceUpload && itemAdd.sourceUpload) {
                itemAdd.sourceUpload = _this.sourceUpload;
                addSourceUpload = false;
              }
            }
          }
          if(addAction) _this.whenAddingFile.push({action: _this.action});
          if(addConvertToPdf) _this.whenAddingFile.push({convertToPdf: _this.convertToPdf});
          if(addTypeDoc) _this.whenAddingFile.push({typeDocument: _this.typeDocument});
          if(addIdTopic) _this.whenAddingFile.push({idTopic: _this.idTopic});
          if(addIdContenu) _this.whenAddingFile.push({idContenu: _this.idContenu});
          if(addSourceUpload) _this.whenAddingFile.push({sourceUpload: _this.sourceUpload});
        }

        // Récupère la fonction de base du onAfterAddingFile
        var baseOnAfterAddingFile = angular.copy(_this.uploader.onAfterAddingFile);

        /**
         * Après l'ajout d'un fichier en pièce jointe
         * @param fileItem
         */
        _this.uploader.onAfterAddingFile = function (fileItem) {
          if(_this.queueLimit) {
            var nbInQueue = _this.uploader.queue.length + _this.documentsDroppedFromDocs.length;
            if (nbInQueue > _this.queueLimit) {
              fileItem.remove();
              ModalsService.alertErreur('Limite atteinte', '<span class="text-danger">Vous ne pouvez pas mettre plus de '+_this.queueLimit+' document(s)</span>');
            }
          }

          baseOnAfterAddingFile.apply(null, arguments); // Possible aussi
          //baseOnAfterAddingFile(...arguments);
          if(_.isArray(_this.whenAddingFile) && _this.whenAddingFile.length) {
            for(var j = 0; j < _this.whenAddingFile.length; j++){
              if(_.isObject(_this.whenAddingFile[j])){
                fileItem.formData.push(angular.copy(_this.whenAddingFile[j]));
              }
            }
          }
          else {
            fileItem.formData.push({idGroupe: UtilsService.getCurrentUser().getIdGroupe()});
          }
          fileItem.formData.push({token: UtilsService.getCurrentUser().getToken()});
          emit({'action': 'onAfterAddingFile', fileItem: fileItem});
          if(_this.showDocsTopic) calcSommePiecesJointes();
        };

        var baseOnBeforeUploadItem = angular.copy(_this.uploader.onBeforeUploadItem);

        /**
         * Fires before uploading an item.
         * @param progress
         */
        _this.uploader.onBeforeUploadItem = function (fileItem) {
          baseOnBeforeUploadItem.apply(null, arguments);
          emit({action: 'onBeforeUploadItem', fileItem: fileItem});
        };

        // Récupère la fonction de base du onSuccessItem
        var baseOnSuccessItem = angular.copy(_this.uploader.onSuccessItem);

        /**
         * Lorsqu'un fichier est bien upload
         * @param fileItem
         * @param response
         */
        _this.uploader.onSuccessItem = function (fileItem, response) {
          //baseOnSuccessItem(...arguments);
          baseOnSuccessItem.apply(null, arguments);
          emit({'action': 'onSuccessItem', fileItem: fileItem, response: response});
        };

        var baseOnErrorItem = angular.copy(_this.uploader.onErrorItem);

        /**
         * Si erreur sur un item à upload
         * @param fileItem
         * @param response
         */
        _this.uploader.onErrorItem = function (fileItem, response) {
          baseOnErrorItem.apply(null, arguments);
          //baseOnErrorItem(...arguments);

          // Si le modal erreur n'est pas désactivé, fait une alert si erreur d'upload du fichier
          if(_.isNil(_this.disableModalError) || !_this.disableModalError)
            ErreurCollabService.onResponseError({fileItem: fileItem, response: response});

          emit({'action': 'onErrorItem', fileItem: fileItem, response: response});
        };

        var baseOnProgressAll = angular.copy(_this.uploader.onProgressAll);

        /**
         * On upload queue progress
         * @param progress
         */
        _this.uploader.onProgressAll = function (progress) {
          baseOnProgressAll.apply(null, arguments);
          //baseOnProgressAll(...arguments);
          emit({'action': 'onProgressAll', progress: progress});
          if(!_this.hideNotifUpload) {
            _this.notifWaitUpload.progress = progress;
            if (!_this.notifWaitUpload.show) {
              _this.notifWaitUpload.show = true;
              NotificationsToastServices.wait('Patientez', 'Upload des documents en cours...', function (notif) {
                _this.notifWaitUpload.obj = notif;
              });
            }
          }
        };


        var baseOnCompleteAll = angular.copy(_this.uploader.onCompleteAll);
        /**
         * Après upload de tout les fichiers
         */
        _this.uploader.onCompleteAll = function () {
          baseOnCompleteAll.apply(null, arguments);
          //baseOnCompleteAll(...arguments);
          // Supprime la liste des fichier upload
          _this.uploader.clearQueue();
          if (_this.notifWaitUpload.show) {
            NotificationsToastServices.clear(_this.notifWaitUpload.obj.id);
            initNotifWaitUpload();
          }
          emit({'action': 'onCompleteAll'});
        };

      }

      if(_.isObject(_this.contenuParent) && _this.contenuParent.model === 'Contenu' && (_this.contenuParent.isContenuDevisInter() || _this.contenuParent.isContenuContrat())) {

        _this.documentDemande = angular.copy(_this.contenuParent.getDocumentDemande());
        if(_this.contenuParent.isContenuDevisInter()) {
          _this.documentDevis = angular.copy(_this.contenuParent.getDocumentDevis());
          _this.documentDevisSigne = angular.copy(_this.contenuParent.getDocumentDevisSigne());
          _this.documentAttestationTvaSimple = angular.copy(_this.contenuParent.getDocumentAttestationTvaSimple());
          _this.documentFacture = angular.copy(_this.contenuParent.getDocumentFacture());
        }
        else if(_this.contenuParent.isContenuContrat()) {
          _this.listDocumentContratFournisseurSigne = angular.copy(_this.contenuParent.getListDocumentContratFournisseurSigne());
          _this.listDocumentContratAgenceSigne = angular.copy(_this.contenuParent.getListDocumentContratAgenceSigne());
        }
      }

      if(_.isObject(_this.addDocuments)) {
        if(_.isArray(_this.addDocuments)) {
          for(var i = 0; i < _this.addDocuments.length; i++) {
            if(!_this.addDocuments[i].isModel) _this.addDocuments[i] = DocumentManagerService.instanceDoc(_this.addDocuments[i]);
            addDocument(_this.addDocuments[i]);
          }
        }
        else {
          if(!_this.addDocuments.isModel) _this.addDocuments = DocumentManagerService.instanceDoc(_this.addDocuments);
          addDocument(_this.addDocuments);
        }
      }
    }

    function extensionIsAllow(nom, extension){
      if(!_this.onlyExtensions.contains(_.trimStart(extension, '.'))){
        var message = 'un ' + _this.onlyExtensions[0].toUpperCase();
        if(_this.onlyExtensions.length) message = 'une de ces extensions: ' + _this.onlyExtensions.join(', ');
        ModalsService.alertErreur('Erreur type de fichier', '<span class="text-danger">' + nom + '</span> doit être ' + message);
        return false;
      }
      return true;
    }

    /**
     * Lors du drop d'un item draggable
     */
    function onDropDocument() {
      addDocument(_this.itemDrop);
    }

    /**
     * Lorsqu'on check un document
     * @param document
     */
    function onCheckDocumentPartage(document) {
      if (document.checked) addDocument(document);
      else removeDocument(document);
    }

    /**
     * Ajoute un document déjà existant à la liste des doc (doc en raccourci ou dropé depuis les widget)
     * @param document
     */
    function addDocument(document){
      if (_.isObject(document)) {
        if(_.isArray(_this.onlyExtensions) && !extensionIsAllow(document.getNomFichier(), document.getExtension())) return;

        if(_this.queueLimit){
          var nbInQueue = _this.uploader.queue.length + _this.documentsDroppedFromDocs.length;
          if(nbInQueue == _this.queueLimit) {
            ModalsService.alertErreur('Limite atteinte', '<span class="text-danger">Vous ne pouvez pas mettre plus de '+_this.queueLimit+' document(s)</span>');
            return;
          }
        }

        var exist = false;
        if(_this.documentsDroppedFromDocs.length){
          for(var i = 0; i < _this.documentsDroppedFromDocs.length; i++) {
            if(document.getGuid() === _this.documentsDroppedFromDocs[i].getGuid()) {
              exist = true;
              break;
            }
          }
        }
        if(!exist) _this.documentsDroppedFromDocs.push(document);

        emit({action: 'updateDroppedDoc', docs: angular.copy(_this.documentsDroppedFromDocs)});


        // Si le composant est paramétré pour ne pas afficher la liste des documents
        // on remet à 0 liste des document droped après avoir envoyé l'info en emit
        if(!_.isNil(_this.showQueue) && !_this.showQueue)
          _this.documentsDroppedFromDocs = [];
        else {
          if(_this.dropOnlyWithoutTypeDocument) {
            _.remove(_this.documentsDroppedFromDocs, function(doc){
              return !_.isNil(doc.typeDocument);
            });
          }
        }

        if(_this.contenuParent) checkOrUncheckShortcutDocs(document, true);

        if(_this.showDocsTopic) {
          parcourDocumentsTopic(document, true);
          calcSommePiecesJointes();
        }
      }
    }

    /**
     * Retire un document de la liste des docs
     * @param document
     */
    function removeDocument(document, fromQueue){
      if (_.isObject(document)) {
        if(_.isObject(document.file)){
          //document.remove();
          if(_this.showDocsTopic) calcSommePiecesJointes();
        }
        else {
          if(!fromQueue){
            _.remove(_this.documentsDroppedFromDocs, function(doc){
              return (document.getGuid() === doc.getGuid());
            });
          }

          emit({action: 'updateDroppedDoc', docs: _this.documentsDroppedFromDocs});

          if(_this.contenuParent) checkOrUncheckShortcutDocs(document, false);

          if(_this.showDocsTopic) {
            parcourDocumentsTopic(document, false);
            calcSommePiecesJointes();
          }
        }
      }
    }

    /**
     * Permet de checker ou uncheck un document qui est en racourci
     * @param document
     * @param checked
     */
    function checkOrUncheckShortcutDocs(document, checked){
      if(!_.isNil(_this.documentDemande) && _this.documentDemande.guid === document.guid) {
        _this.documentDemande.checked = checked;
      }
      if(!_.isNil(_this.documentDevis) && _this.documentDevis.guid === document.guid) {
        _this.documentDevis.checked = checked;
      }
      if(!_.isNil(_this.documentDevisSigne) && _this.documentDevisSigne.guid === document.guid) {
        _this.documentDevisSigne.checked = checked;
      }
      if(!_.isNil(_this.documentAttestationTvaSimple) && _this.documentAttestationTvaSimple.guid === document.guid) {
        _this.documentAttestationTvaSimple.checked = checked;
      }
      if(!_.isNil(_this.documentFacture) && _this.documentFacture.guid === document.guid) {
        _this.documentFacture.checked = checked;
      }
    }

    /**
     * Parcour les documents du topic pour cocher ou pas
     * @param currentDocument
     */
    function parcourDocumentsTopic(currentDoc, check) {
      if (_this.showQueue) {
        if (_this.listeDocuments && _this.listeDocuments.nb) {

          var docs = [];
          if(_.isArray(currentDoc)) docs = currentDoc;
          else if(_.isObject(currentDoc)) docs.push(currentDoc);

          if(docs.length) {
            docs.map(function(currentDocument){
              _this.listeDocuments.categorie.map(function(categorie){
                if (categorie.documents.length) {
                  categorie.documents.map(function(doc){
                    if (doc.guid === currentDocument.guid)
                      doc.checked = (check) ? true : false;
                  });
                }
                if (categorie.hasOwnProperty('groupements') && _.isArray(categorie.groupements)) {
                  categorie.groupements.map(function(groupement){
                    groupement.documents.map(function(doc){
                      if (doc.guid === currentDocument.guid)
                        doc.checked = (check) ? true : false;
                    });
                  });
                }
              });
            });
          }
        }
      }
    }

    /**
     * Permet de calculer le poid total des pièces jointes
     */
    function calcSommePiecesJointes() {

      var sizeTotal = 0;
      if (_this.documentsDroppedFromDocs.length) {
        for (var i = 0; i < _this.documentsDroppedFromDocs.length; i++) {
          sizeTotal = sizeTotal + _this.documentsDroppedFromDocs[i].size;
        }
      }
      if (_this.uploader.queue.length) {
        for (var j = 0; j < _this.uploader.queue.length; j++) {
          sizeTotal = sizeTotal + _this.uploader.queue[j].file.size;
        }
      }
      if (sizeTotal === 0) sizeTotal = false;
      emit({action: 'sommePiecesJointes', sommePiecesJointes: sizeTotal});
    }

    function onClickClose(){
      close();
    }

    /**
     * Si ouvert en mode Modal
     */
    function close(){
      if (_this.modalInstance) _this.dismiss({$value: 'cancel'});
      else emit({action: 'close'});
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      if(_.isObject(objRetour)) objRetour = _.merge(objRetour, {uploader: _this.uploader});
      _this.onEmit({obj: objRetour});
      /*
      if(_this.modalInstance) _this.close({$value: objRetour});
      else _this.onEmit({obj: objRetour});
      */
    }

    /**
     * Lorsque le composant collab-upload-queue remonte une information
     * @param obj
     */
    function onEmitFromUploadCollabQueue(obj) {
      //console.log("upload-collab", obj);
      try {
        if (!obj) throw new Error('Il manque l\'objet "obj" !');
        if (obj.action === "remove") {
          if (_.isArray(obj.docs)) _this.documentsDroppedFromDocs = _.unionBy(_this.documentsDroppedFromDocs, angular.copy(obj.docs), 'guid');
          removeDocument(obj.document, true);
        }
      } catch (err) {
        console.log('[UploadCollabCtrl.onEmitFromUploadCollabQueue] Erreur : ' + err.message);
      }
    }
  }
})();
