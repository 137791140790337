(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.InformationsDossier
   * @description
   * # InformationsDossier
   * Service in the collaboreApp.
   */

  angular.module('collaboreApp').factory('InformationsDossier', InformationsDossierModel);

  /** @ngInject */
  function InformationsDossierModel(Main) {

    var InformationsDossier = function(objetData) {
      Main.call(this, objetData);
      //this.hydrate(objetData);
    };

    InformationsDossier.prototype = {

      isInformationsDossier: true,

      // groupeImmeuble
      getGroupeImmeuble: function () {
        return this.groupeImmeuble;
      },
      setGroupeImmeuble: function (groupe) {
        if(_.isObject(groupe)) {
          if(!groupe.isModel) {
            var Groupe = Main.$injector.get('Groupe');
            groupe = new Groupe(groupe);
            Groupe = null;
          }
        }
        this.groupeImmeuble = groupe;
      },

      // groupeCoproprietaire
      getGroupeCoproprietaire: function () {
        return this.groupeCoproprietaire;
      },
      setGroupeCoproprietaire: function (groupe) {
        if(_.isObject(groupe)) {
          if(!groupe.isModel) {
            var Groupe = Main.$injector.get('Groupe');
            groupe = new Groupe(groupe);
            Groupe = null;
          }
        }
        this.groupeCoproprietaire = groupe;
      },

      // groupeProprietaire
      getGroupeProprietaire: function () {
        return this.groupeProprietaire;
      },
      setGroupeProprietaire: function (groupe) {
        if(_.isObject(groupe)) {
          if(!groupe.isModel) {
            var Groupe = Main.$injector.get('Groupe');
            groupe = new Groupe(groupe);
            Groupe = null;
          }
        }
        this.groupeProprietaire = groupe;
      },

      // groupeLocataire
      getGroupeLocataire: function () {
        return this.groupeLocataire;
      },
      setGroupeLocataire: function (groupe) {
        if(_.isObject(groupe)) {
          if(!groupe.isModel) {
            var Groupe = Main.$injector.get('Groupe');
            groupe = new Groupe(groupe);
            Groupe = null;
          }
        }
        this.groupeLocataire = groupe;
      },
    };

    angular.extend(InformationsDossier.prototype, Main.prototype);

    InformationsDossier.prototype.model = 'InformationsDossier';

    return InformationsDossier;
  }
})();
