'use strict';

angular.module('collaboreApp').service('ResetPwdService', [
	'COLLAB_CONF',
	'$q',
	'ParamsAjax',
	'ErreurCollabService',
	function(COLLAB_CONF,$q,ParamsAjax,ErreurCollabService){

		this.resetPwd = function(mail){
			var deferred = $q.defer();

			ParamsAjax.resetPassword({'email':mail},function(mailUser){
				if (mailUser.Resultats[0].success){	deferred.resolve(mailUser);}
				else{								deferred.reject(mailUser);}
			},function(error){
				ErreurCollabService.erreurServlet(error,'Erreur avec ResetPwdService.ResetPwdPwd()',deferred);
			});
			return deferred.promise;
		};
	}]);