(function() {

  'use strict';

  /**
   * @desc Composant gerer mes groupes
   * @example <admin-gestion-des-groupes></admin-gestion-des-groupes>
   */
  angular
    .module('collaboreApp')
    .component('adminGestionDesGroupes',{
      templateUrl: 'app/parametrage/admin/gestion-des-groupes/admin-gestion-des-groupes.html',
      controllerAs: 'admingestiondesgroupesctrl',
      controller: AdminGestionDesGroupesCtrl
    });

  /** @ngInject */
  function AdminGestionDesGroupesCtrl(COLLAB_CONF, $scope, $rootScope,$q,UtilsService,ErreurCollabService,sweet,GroupesAdminService, hotkeys){
    var ctrl = this;

    ctrl.groupe = {};
    ctrl.objectDropped = {};
    ctrl.searchInAllGroupe = '';
    ctrl.filterInAllGroupe = '';


    ctrl.enableActionbOnGroupePortefeuille = false;
    ctrl.showGroupeOldPortefeuille = false;

    ctrl.loading = false;

    ctrl.actionOnGroupeEnabled = actionOnGroupeEnabled;

    ctrl.genereObjGroupeParent = genereObjGroupeParent;
    ctrl.onSubmitFilterGroupe = onSubmitFilterGroupe;
    ctrl.onClickCreateNewGroupe = onClickCreateNewGroupe;

    ctrl.startDragGroupe = startDragGroupe;
    ctrl.onDropInGroupe = onDropInGroupe;
    ctrl.onUpdateGroupe = onUpdateGroupe;


    ctrl.fctOnUpdateGroupe = fctOnUpdateGroupe;

    ctrl.$onInit = function(){

      hotkeys.bindTo($scope)
        .add({
          combo: COLLAB_CONF.HOTKEYS_ENABLE_ACTION_ON_GROUPE_PORTEFEUILLE.combo,
          description: COLLAB_CONF.HOTKEYS_ENABLE_ACTION_ON_GROUPE_PORTEFEUILLE.description,
          callback: function() {
            ctrl.enableActionbOnGroupePortefeuille = !ctrl.enableActionbOnGroupePortefeuille;
          }
        })
        .add({
          combo: COLLAB_CONF.HOTKEYS_SHOW_OLD_GROUPE_PORTEFEUILLE.combo,
          description: COLLAB_CONF.HOTKEYS_SHOW_OLD_GROUPE_PORTEFEUILLE.description,
          callback: function() {
            ctrl.showGroupeOldPortefeuille = !ctrl.showGroupeOldPortefeuille;
          }
        });

      ctrl.loading = true;
      GroupesAdminService.getGroupesOnly().then(function(groupe){
        //console.log(groupe);
        ctrl.groupe = groupe;
      }).finally(function(){
        ctrl.loading = false;
      });


    };

    function onSubmitFilterGroupe(){
      ctrl.filterInAllGroupe = angular.copy(ctrl.searchInAllGroupe);
    }

    function actionOnGroupeEnabled(groupe) {
      return (!groupe.isPortefeuille() || ctrl.enableActionbOnGroupePortefeuille);
    }

    /**
     * Créer un nouveau groupe dans un groupe
     * @param groupeParent
     */
    function onClickCreateNewGroupe(groupeParent){
      sweet.show({
        title: 'Création d\'un nouveau groupe dans '+groupeParent.getLibelle(),
        text: 'Veuillez renseigner le nom du groupe :',
        type: 'input',
        html: true,
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Oui !',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        inputPlaceholder: 'Nom du groupe'
      },function(inputValue) {

        if (inputValue === false){
          return false;
        }
        if (inputValue === '') {
          sweet.showInputError('Le nom ne peux pas être vide');
          return false;
        }

        inputValue = UtilsService.cleanNomGroupe(inputValue);
        inputValue = '@'+inputValue;

        GroupesAdminService
          .createGroupeNoUserInGroupe(groupeParent.getIdGroupe(), inputValue, null, null)
          .then(function(groupe){
            console.log(groupe);
            if(_.isObject(groupe)){
              groupeParent.groupesFils.push(groupe);
              groupeParent.getGroupeInformations().nbGroupesNonUser++;
            }
            /*
            if(_.isObject(groupe) && _.isArray(groupe.groupesFils) && groupe.groupesFils.length) {
              for(var g = 0; g < groupe.groupesFils.length; g++) {
                if(groupe.groupesFils[g].libelle === inputValue){
                  groupeParent.groupesFils.push(groupe.groupesFils[g]);
                  groupeParent.getGroupeInformations().nbGroupesNonUser++;
                  break;
                }
              }
            }*/
            sweet.close();
          })
          .catch(function (msg) {
            ErreurCollabService.alert(msg);
          });


        /*
        var objParam = {
          'groupeChild': inputValue,
          'groupeParent': groupeParent
        };

        // Ajoute le groupe dans le groupe
        GroupesAdminService.addGroupe(objParam).then(function(newGroupe){

          groupeParent.groupesFils.push(newGroupe);
          groupeParent.getGroupeInformations().nbGroupesNonUser++;

          //if(callBackOnsuccess){
          //    callBackOnsuccess(groupe);
          //}else{
          //    ctrl.groupe = groupe;
          //}
          sweet.close();
        },function(msg){
          ErreurCollabService.alert(msg);
        });*/
      });
    }

    /**
     * Lors du start d'un drag and drop d'un utilisateur
     */
    function startDragGroupe(event, ui) {
      angular.element(ui.helper).addClass('ui-draggable-badge-helper');
    }

    /**
     * Lors du drop d'un groupe (user ou non) vers un autre groupe
     * @param event
     * @param ui
     * @param groupesHierarchie (liste des toute la hierarchie)
     * @param groupeDestinataire (nouveau groupe )
     * @returns {promise|*|Promise|capability.promise|a.fn.promise}
     */
    function onDropInGroupe(event,ui,groupeDestinataire) {
      var objectDropped = angular.copy(ctrl.objectDropped),isPresent,deferred;

      // console.log('objectDropped',objectDropped);
      // console.log('groupeDestinataire',groupeDestinataire);

      // Si c'est un portefeuille
      if(objectDropped.obj.isPortefeuille === true) {

        // Si on dépose un portefeuille dans All
        if(groupeDestinataire.isAll()) return;

        var nomPortefeuille = objectDropped.obj.getNom();

        isPresent = !!UtilsService.where(groupeDestinataire.getListePortefeuille(), {'idPortefeuille': objectDropped.obj.getIdPortefeuille()})[0];
        if (isPresent) {
          ErreurCollabService.alert('Erreur lors de l\'ajout d\'un portefeuille', '"<strong>' + nomPortefeuille + '</strong> est déjà présent dans le groupe "<strong>' + groupeDestinataire.getLibelle() + '</strong>" !');
        } else {
          //deferred = $q.defer();

          sweet.show({
            title: 'Ajouter un portefeuille',
            text: 'Ajouter "<strong>' + nomPortefeuille + '</strong> au groupe "<strong>' + groupeDestinataire.getLibelle() + '</strong>" ?',
            type: 'info',
            html: true,
            showCancelButton: true,
            confirmButtonText: 'Oui !',
            closeOnConfirm: false,
            showLoaderOnConfirm: true
          }, function (isConfirm) {
            if (isConfirm) {

              var objParam = {
                'idPortefeuille': objectDropped.obj.getIdPortefeuille(),
                'idGroupe': groupeDestinataire.getIdGroupe()
              };

              GroupesAdminService.addPortefeuilleInGroupe(objParam).then(function (portefeuille) {

                if(!groupeDestinataire.hasOwnProperty('listePortefeuille')){
                  groupeDestinataire.listePortefeuille = [];
                }else{
                  if(!angular.isArray(groupeDestinataire.listePortefeuille)){
                    groupeDestinataire.listePortefeuille = [];
                  }
                }
                groupeDestinataire.listePortefeuille.push(portefeuille);
                groupeDestinataire.getGroupeInformations().nbPortefeuilles++;
                /*
                if(callBackOnsuccess){
                    // Ajoute le portefeuille à la liste
                    callBackOnsuccess(portefeuille);
                }else{
                    ctrl.groupe.listePortefeuille.push(portefeuille);
                }*/

                /*
                if(callBackOnsuccess){
                    // Met à jour le nouveau groupe parent
                    callBackOnsuccess(newGroupePapa);
                }else{
                    ctrl.groupe = newGroupePapa;
                }*/
                sweet.close();
              }, function (msg) {
                ErreurCollabService.alert(msg);
                //deferred.reject(msg);
              }).finally(function () {
                ctrl.objectDropped = {};
              });
            } else {
              //deferred.reject('Non');
              ctrl.objectDropped = {};
              sweet.close();
            }
          });
        }

      }
      // Si c'est un module
      else if(objectDropped.obj.isModule===true){

        if(groupeDestinataire.isAll()) return;

        sweet.show({
          title: 'Ajouter un module',
          text: 'Ajouter "<strong>' + objectDropped.obj.getLibelle() + '</strong> au groupe "<strong>' + groupeDestinataire.getLibelle() + '</strong>" ?',
          type: 'info',
          html: true,
          showCancelButton: true,
          confirmButtonText: 'Oui !',
          closeOnConfirm: false,
          showLoaderOnConfirm: true
        }, function (isConfirm) {
          if (isConfirm) {
            var objParam = {
              'idModule': objectDropped.obj.getIdModule(),
              'idGroupe': groupeDestinataire.getIdGroupe()
            };

            GroupesAdminService.addModuleInGroupe(objParam).then(function(module){

              //console.log(groupeDestinataire);

              if(!groupeDestinataire.hasOwnProperty('listeModule')){
                groupeDestinataire.listeModule = [];
              }else{
                if(!angular.isArray(groupeDestinataire.listeModule)){
                  groupeDestinataire.listeModule = [];
                }
              }
              groupeDestinataire.listeModule.push(module);
              groupeDestinataire.getGroupeInformations().nbModules++;

              sweet.close();
            }, function (msg) {
              ErreurCollabService.alert(msg);
              //deferred.reject(msg);
            }).finally(function () {
              ctrl.objectDropped = {};
            });
          } else {
            //deferred.reject('Non');
            ctrl.objectDropped = {};
            sweet.close();
          }
        });

      }
      // Si c'est un groupe
      else{
        var libelleNewGroupeAdd = objectDropped.obj.getLibelle();

        var canDrop = false;

        // Groupe du groupe avant déplacement
        if (objectDropped.groupeParent) {

          // Si le groupe précédent de l'user est différent du nouveau groupe
          if (objectDropped.groupeParent.idGroupe !== groupeDestinataire.getIdGroupe()) {
            canDrop = true;
          }

          // Si on déplace un portefeuille vers un autre groupe portefeuille
          if(objectDropped.obj.isPortefeuille() && groupeDestinataire.isPortefeuille()){
            canDrop = false
          }
        }else{
          // Si le groupe destination n'est pas all OU  que ce n'est pas un user
          // C'est à dire on peux mettre dans all que les groupe non user
          if(!groupeDestinataire.isAll() || !objectDropped.obj.isUser()){
            canDrop = true;
          }
        }

        // Si l'object peut etre dropped
        if (canDrop) {

          if(objectDropped.obj.isUser()){
            if(objectDropped.obj.getUser().getFichePerso()){
              libelleNewGroupeAdd = objectDropped.obj.getUser().getFichePerso().getNomPrenom();
            }
          }

          isPresent = !!UtilsService.where(groupeDestinataire.getGroupesUser(), {'idGroupe': objectDropped.obj.getIdGroupe()})[0];
          if (isPresent) {

            var titreErreur = 'Erreur lors de l\'ajout d\'un utilisateur',
              texteErreur = '<span class="badge badge-destinataire destinataire-text badge-destinataire-samegroupe">' + libelleNewGroupeAdd + '</span> est déjà présent dans le groupe "<strong>' + groupeDestinataire.getLibelle() + '</strong>" !';
            // Si c'est un groupe
            if(!objectDropped.obj.isUser()){
              titreErreur = 'Erreur lors de l\'ajout d\'un groupe';
              texteErreur = '"<strong>' + libelleNewGroupeAdd + '</strong> est déjà présent dans le groupe "<strong>' + groupeDestinataire.getLibelle() + '</strong>" !';
            }
            ErreurCollabService.alert(titreErreur,texteErreur);
          } else {
            deferred = $q.defer();

            var title = 'Ajouter un utilisateur',
              texte = 'Ajouter <span class="badge badge-destinataire destinataire-text badge-destinataire-samegroupe">' + libelleNewGroupeAdd + '</span> au groupe "<strong>' + groupeDestinataire.getLibelle() + '</strong>" ?';

            // Si c'est un user

            if(objectDropped.obj.isUser()){
              // Si l'user est déplacé
              if (objectDropped.groupeParent) {
                title = 'Déplacer un utilisateur';
                texte = 'Déplacer <span class="badge badge-destinataire destinataire-text badge-destinataire-samegroupe">' + libelleNewGroupeAdd + '</span> du groupe "<strong>' + objectDropped.groupeParent.libelle + '</strong>" vers le groupe "<strong>' + groupeDestinataire.getLibelle() + '</strong>" ?';
              }

              // Si c'est un groupe
            }else{

              title = 'Ajouter un groupe';
              texte = 'Ajouter "<strong>' + libelleNewGroupeAdd + '</strong>" au groupe "<strong>' + groupeDestinataire.getLibelle() + '</strong>" ?';

              // Si le gorupe est déplacé
              if (objectDropped.groupeParent) {
                title = 'Déplacer un groupe';
                texte = 'Déplacer "<strong>' + libelleNewGroupeAdd + '</strong>" du groupe "<strong>' + objectDropped.groupeParent.libelle + '</strong>" vers le groupe "<strong>' + groupeDestinataire.getLibelle() + '</strong>" ?';
              }
            }


            sweet.show({
              title: title,
              text: texte,
              type: 'info',
              html: true,
              showCancelButton: true,
              confirmButtonText: 'Oui !',
              closeOnConfirm: false,
              showLoaderOnConfirm: true
            }, function (isConfirm) {
              if (isConfirm) {

                // Si le groupe a un groupeParent alors il c'est qu'il est déplacé
                if (objectDropped.groupeParent) {

                  var objParamMove = {
                    idGroupeChild: objectDropped.obj.getIdGroupe(),
                    idGroupeOldParent: objectDropped.groupeParent.idGroupe,
                    idGroupeNewParent: groupeDestinataire.getIdGroupe()
                  };

                  // Déplace le groupe dans le groupe
                  GroupesAdminService.moveGroupeToGroupe(objParamMove).then(function(){
                    /*
                    if(callBackOnsuccess){
                        // Supprime le groupe de l'ancien groupe parent
                        $rootScope.$broadcast('removeGroupeFils',objectDropped.groupeParent.idGroupe,objectDropped.obj.getIdGroupe());

                        // Met à jour le nouveau groupe parent
                        callBackOnsuccess(newGroupePapa);
                    }else{
                        ctrl.groupe = newGroupePapa;
                    }
                    */

                    if(objectDropped.obj.isUser()){
                      // Supprime le groupe de l'ancien groupe parent
                      $rootScope.$broadcast('removeGroupeUser',objectDropped.groupeParent.idGroupe,objectDropped.obj.getIdGroupe());

                      // Ajoute le groupe au groupe user
                      groupeDestinataire.groupesUser.push(objectDropped.obj);
                      groupeDestinataire.getGroupeInformations().nbGroupesUser++;
                    }else{
                      // Supprime le groupe de l'ancien groupe parent
                      $rootScope.$broadcast('removeGroupeFils',objectDropped.groupeParent.idGroupe,objectDropped.obj.getIdGroupe());

                      // Ajoute le groupe au groupe fils
                      groupeDestinataire.groupesFils.push(objectDropped.obj);
                      groupeDestinataire.getGroupeInformations().nbGroupesNonUser++;
                    }


                    deferred.resolve();
                    sweet.close();
                    ctrl.objectDropped = {};
                  })
                  .catch(function(msg){
                    ErreurCollabService.alert(msg);
                  });


                  // Ajout d'un user
                } else {

                  var objParam = {
                    groupeChild: objectDropped.obj,
                    groupeParent: groupeDestinataire,
                    dontAssignTopic: !objectDropped.assignTopic,
                    assignTopicCloture: objectDropped.assignTopicCloture
                  };


                  // Ajoute le groupe dans le groupe
                  GroupesAdminService.addGroupe(objParam).then(function(groupeUser){
                    groupeDestinataire.groupesUser.push(groupeUser);
                    groupeDestinataire.getGroupeInformations().nbGroupesUser++;
                    deferred.resolve();
                    sweet.close();
                  },function(msg){
                    ErreurCollabService.alert(msg);
                    deferred.reject(msg);
                  }).finally(function(){
                    ctrl.objectDropped = {};
                  });
                }


              } else {
                deferred.reject('Non');
                ctrl.objectDropped = {};
                sweet.close();
              }
            });

            return deferred.promise;
          }
        }
      }
    }

    function genereObjGroupeParent(groupe){
      return {
        'idGroupe': groupe.idGroupe,
        'libelle': groupe.libelle,
        'dieze': groupe.dieze
      };
    }

    function fctOnUpdateGroupe(groupe,action){

      // Si update du groupe actuel
      if(action.updateGroupe){
        // Si le groupe actuel est le groupe qu'il faut mettre à jour
        if(groupe.idGroupe===action.updateGroupe.idGroupe){
          groupe = action.updateGroupe;
        }

      }

      // Si suppréssion d'un groupe fils
      if(action.removeGroupeFils){
        // Si le groupe actuel est le groupe où il faut y supprimer un fils
        if(groupe.idGroupe===action.fromGroupe){
          // Si il a bien des fils
          if(groupe.groupesFils.length){
            // Parcour les fils
            for(var i = 0 ; i < groupe.groupesFils.length ; i++){
              // Si le fils actuel est celui à delete
              if(groupe.groupesFils[i].idGroupe === action.removeGroupeFils){
                groupe.groupesFils.splice(i,1);
                groupe.getGroupeInformations().nbGroupesNonUser--;
              }
            }
          }
        }
      }

      //console.log('groupe',groupe);
      //console.log('action',action);
      // Si suppréssion d'un groupe user
      if(action.removeGroupeUser){
        // Si le groupe actuel est le groupe où il faut y supprimer un user
        if(groupe.idGroupe===action.fromGroupe){
          // Si il a bien des users
          if(groupe.groupesUser.length){
            // Parcour les users
            for(var u = 0 ; u < groupe.groupesUser.length ; u++){
              // Si l'user actuel est celui à delete
              if(groupe.groupesUser[u].idGroupe === action.removeGroupeUser){
                groupe.groupesUser.splice(u,1);
                groupe.getGroupeInformations().nbGroupesUser--;
              }
            }
          }
        }
      }
    }

    /**
     * Lors de la modification d'un composant "adminGestionGroupe" enfant de ce composant
     * @param action
     */
    function onUpdateGroupe(action){
      fctOnUpdateGroupe(ctrl.groupe,action);
    }
  }
})();
