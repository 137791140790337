(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name collaboreApp.TopicStateFacture
     * @description
     * # TopicStateFacture
     * Service in the collaboreApp.
     */

    angular.module('collaboreApp').factory('TopicStateFacture', TopicStateFactureModel);

    /** @ngInject */
    function TopicStateFactureModel(Main) {

        var TopicStateFacture = function(objetData) {
            Main.call(this, objetData);
        };

        TopicStateFacture.prototype = {

            isTopicStateFacture: true,
            model: 'TopicStateFacture',

            // nbTotalTopicState
            getNbTotalTopicState: function(){
                return this.nbTotalTopicState;
            },
            setNbTotalTopicState: function(nb){
                this.nbTotalTopicState = nb;
            },

            // nbTotalFacture
            getNbTotalFacture: function(){
                return this.nbTotalFacture;
            },
            setNbTotalFacture: function(nb){
                this.nbTotalFacture = nb;
            },

            // nbPreviousFacture
            getNbPreviousFacture: function(){
                return this.nbPreviousFacture;
            },
            setNbPreviousFacture: function(nb){
                this.nbPreviousFacture = nb;
            },

            // nbNextFacture
            getNbNextFacture: function(){
                return this.nbNextFacture;
            },
            setNbNextFacture: function(nb){
                this.nbNextFacture = nb;
            },

            // previousContenu
            getPreviousContenu: function(){
                return this.previousContenu;
            },
            setPreviousContenu: function(infosPrevContenu){
                if(_.isObject(infosPrevContenu)) {
                    if(!infosPrevContenu.isModel) {
                        var ContenuInformations = new Main.$injector.get('ContenuInformations');
                        infosPrevContenu = new ContenuInformations(infosPrevContenu);
                    }
                    this.previousContenu = infosPrevContenu;
                }
            },

            // currentContenu
            getCurrentContenu: function(){
                return this.currentContenu;
            },
            setCurrentContenu: function(infosCurrentContenu){
                if(_.isObject(infosCurrentContenu)) {

                    if(!infosCurrentContenu.isModel) {
                        var ContenuInformations = new Main.$injector.get('ContenuInformations');
                        infosCurrentContenu = new ContenuInformations(infosCurrentContenu);
                    }
                    this.currentContenu = infosCurrentContenu;
                }
            },

            // nextContenu
            getNextContenu: function(){
                return this.nextContenu;
            },
            setNextContenu: function(infosNextContenu){
                if(_.isObject(infosNextContenu)) {

                    if(!infosNextContenu.isModel) {
                        var ContenuInformations = new Main.$injector.get('ContenuInformations');
                        infosNextContenu = new ContenuInformations(infosNextContenu);
                    }
                    this.nextContenu = infosNextContenu;
                }
            },

            // listFactureNoSentToComfact
            getListFactureNoSentToComfact: function(){
                if(!_.isArray(this.listFactureNoSentToComfact)) this.listFactureNoSentToComfact = [];
                return this.listFactureNoSentToComfact;
            },
            setListFactureNoSentToComfact: function(val){
                var list = [];
                if(_.isArray(val) && val.length) {
                    var ContenuInformations = new Main.$injector.get('ContenuInformations');
                    for(var i = 0; i < val.length; i++) {
                        if(!val[i].isModel) list.push(new ContenuInformations(val[i]));
                        else list.push(val[i]);
                    }
                }
                this.listFactureNoSentToComfact = list;
            },

            // listGroupeDestinataire
            getListGroupeDestinataire: function(){
                if(!angular.isArray(this.listGroupeDestinataire)) this.listGroupeDestinataire = [];
                return this.listGroupeDestinataire;
            },
            setListGroupeDestinataire: function(val){
                var list = [];
                if(_.isArray(val) && val.length) {
                    var Groupe = new Main.$injector.get('Groupe');
                    for(var i = 0; i < val.length; i++) {
                        if(!val[i].isModel) list.push(new Groupe(val[i]));
                        else list.push(val[i]);
                    }
                    Groupe = null;
                }
                this.listGroupeDestinataire = list;
            }

        };

        angular.extend(TopicStateFacture.prototype, Main.prototype);

        return TopicStateFacture;
    }
})();
