(function () {
  'use strict';

  /**
   * @desc Block de communication
   * @example <communication></communication>
   */

  angular
    .module('collaboreApp')
    .component('communication',{
      templateUrl: 'app/topics/communication.component.html',
      controllerAs: 'communicationcomponentctrl',
      require: {
        layoutTopicDetailCtrl: '?^layoutTopicDetail'
      },
      bindings: {
        contenu: '<',
        topicState: '<',
        params: '<',
        openInModal: '<',
        onClose: '&',
        onReady: '&',
        onAddGroupesInEvenement: '&',
        onInitOpen: '<'
      },
      controller: CommunicationController
    });

  /*@ngInject*/
  function CommunicationController(COLLAB_CONF, $injector, $timeout,$location,$anchorScroll,$rootScope,$scope,$sce,$q,GroupeService,UtilsService,TagsService,ContenuService,TopicService,sweet, TopicManagerService) {

    var ctrl = this,
      groupeCommunicationDestinataire = {},
      listeSalarieDestinataire = [],
      globalDestinatairesHide = [],
      immeubleCheckedByDefault = false;

    ctrl.notMobile = UtilsService.notMobile;

    ctrl.enabledDemo = false;

    ctrl.idGroupeByTypeTag = {};
    ctrl.idGroupeByTypeTag[COLLAB_CONF.TAG_TYPE_IMMEUBLE] = null;
    ctrl.idGroupeByTypeTag[COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE] = null;
    ctrl.idGroupeByTypeTag[COLLAB_CONF.TAG_TYPE_PROPRIETAIRE] = null;
    ctrl.idGroupeByTypeTag[COLLAB_CONF.TAG_TYPE_LOCATAIRE] = null;


    ctrl.getIconTag = getIconTag;							// Permet de recupérer l'icon du type de tag
    ctrl.nouveauMessage = nouveauMessage;					// Permet de réafficher le formulaire pour envoyer un autre message
    ctrl.closeCommunication = closeCommunication;			// Permet de fermer la fenetre de communication
    ctrl.openMessage = openMessage;							// Ouvre le formulaire en mode Message
    ctrl.openSMS = openSMS;									// Ouvre le formulaire en mode SMS
    ctrl.onCliqueExtranet = onCliqueExtranet;				// Lors du clique du partage extranet
    ctrl.onSubmitMessage = onSubmitMessage;					// Lors du submit du message
    ctrl.onInsertContenuMessage = onInsertContenuMessage;	// Lors de l'ajout d'un contenu
    ctrl.onErrorMessage = onErrorMessage;					// Lors d'une erreur apreès un submit du message
    ctrl.openDemo = openDemo;								// Ouverture de la demo de l'extranet
    ctrl.getObjetEvenement = getObjetEvenement;				// Recupère l'objet de l'event

    // Fournisseur
    ctrl.onClickOpenFournisseur = onClickOpenFournisseur;	// Lors du clique sur la bouton pour ouvrir le bloc fournisseur
    ctrl.getNomFournisseur = getNomFournisseur;				// Permet de recupérer le nom du fournisseur

    ctrl.selectResult = selectResult;						// Choisi un resultats si il y en a plusieur
    ctrl.resetResult = resetResult;							// Reset le résultat choisi pour réafficher la liste
    ctrl.onChangeGroupeCommunication = onChangeGroupeCommunication;	// Lorsque le composant "groupe-communication" emet un changement
    ctrl.onChangeSalarieDestinataire = onChangeSalarieDestinataire;	// Lorsque le composant "salarie-communication" emet un changement
    ctrl.onChangeCheckboxImmeuble = onChangeCheckboxImmeuble;	// Lorsque l'on clique sur la checkbox de l'immeuble
    ctrl.onClickOpenImmeuble = onClickOpenImmeuble;	// Lorsque l'on clique sur la box immeuble

    //------------------------ SendMail and SMS -----------------//
    ctrl.openSendMail = false;
    ctrl.openSendSms = false;
    ctrl.onClickOpenSendMail = onClickOpenSendMail;
    ctrl.onClickOpenSendSms = onClickOpenSendSms;
    ctrl.onEmitFromFormulaireMessageComponent = onEmitFromFormulaireMessageComponent;
    //------------------------ SendSms -----------------//

    var tagsType = [],
      infosParBlock,
      defaultFormulaire = {
        communication: true,
        hauteurEditor: 100,
        destinataires: [],
        destinatairesHide: [],
        destinatairesExclus: [],
        smsAdvance: true,
        sms: true,
        message: '',
        focus: true
      };
    var destinataires, destinatairesHide;
    var tagTypeGroupe = [COLLAB_CONF.TAG_TYPE_IMMEUBLE];

    ctrl.$onInit = function() {

      if(angular.isObject(ctrl.contenu)) ctrl.contenu.nouveau = true;

      ctrl.topicIsBlocked = TopicManagerService.isBlocked;

      if(ctrl.params){
        defaultFormulaire.sms = ctrl.params.sms;
        if(ctrl.params.message!=='') defaultFormulaire.message = ctrl.params.message;
        if(ctrl.params.idTopic!=='') ctrl.idTopic = defaultFormulaire.idTopic = ctrl.params.idTopic;

        if(_.isObject(ctrl.params.groupeImmeuble) && !_.isNil(ctrl.params.groupeImmeuble.idGroupe))              ctrl.idGroupeByTypeTag[COLLAB_CONF.TAG_TYPE_IMMEUBLE] = ctrl.params.groupeImmeuble.idGroupe;
        if(_.isObject(ctrl.params.groupeCoproprietaire) && !_.isNil(ctrl.params.groupeCoproprietaire.idGroupe))  ctrl.idGroupeByTypeTag[COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE] = ctrl.params.groupeCoproprietaire.idGroupe;
        if(_.isObject(ctrl.params.groupeProprietaire) && !_.isNil(ctrl.params.groupeProprietaire.idGroupe))      ctrl.idGroupeByTypeTag[COLLAB_CONF.TAG_TYPE_PROPRIETAIRE] = ctrl.params.groupeProprietaire.idGroupe;
        if(_.isObject(ctrl.params.groupeLocataire) && !_.isNil(ctrl.params.groupeLocataire.idGroupe))            ctrl.idGroupeByTypeTag[COLLAB_CONF.TAG_TYPE_LOCATAIRE] = ctrl.params.groupeLocataire.idGroupe;
      }


      if(ctrl.contenu) {
        var tagPortefeuille = ctrl.contenu.getTagForType('Portefeuille');
        if(_.isObject(tagPortefeuille)){
          if(tagPortefeuille.getPortefeuille() && tagPortefeuille.getPortefeuille().getPortefeuilleInfos()) defaultFormulaire.prefixSms = tagPortefeuille.getPortefeuille().getPortefeuilleInfos().getPrefixSms();
          if(_.isNil(ctrl.params)) ctrl.params = {};
          ctrl.params.tagPortefeuille = tagPortefeuille;
        }
      }

      infosParBlock = {
        selectedResult: false,
        results: [],
        destinataires: [],
        defaultFormulaire: angular.copy(defaultFormulaire),
        loading: false,			// Loading
        loadingSubmit: false,	// Loading lors du submit
        display: false,			// Block dispo
        enabled: false,			// Groupe fini de chargé il peux etre utilisé
        submit: false,			// Formulaire soumit
        show: false,			// Formulaire affiché
        tag: false,				// Tag
        groupeCollab: false,	// Objet du groupe collab
        isGroupeNonUser: false,
        //'libelle': '',
        isInEvent: false,
        onlySms: false
      };

      ctrl.blocs = {};
      ctrl.blocs[COLLAB_CONF.TAG_TYPE_PROPRIETAIRE] = {
        'nom': 'Propriétaire',
        'infos': angular.copy(infosParBlock)
      };
      ctrl.blocs[COLLAB_CONF.TAG_TYPE_LOCATAIRE] = {
        'nom': 'Locataire',
        'infos': angular.copy(infosParBlock)
      };
      ctrl.blocs[COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE] = {
        'nom': 'Copropriétaire',
        'infos': angular.copy(infosParBlock)
      };
      ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE] = {
        'nom': 'Immeuble',
        'infos': angular.copy(infosParBlock)
      };
      ctrl.blocs['Fournisseur'] = {
        'nom': 'Fournisseurs',
        'noTagType': true,
        'infos': angular.copy(infosParBlock)
      };

      if(COLLAB_CONF.COMMUNICATION_CLIENT_CAPFUN_ENABLED) {
        ctrl.blocs[COLLAB_CONF.TAG_TYPE_CLIENT_CAPFUN] = {
          nom: 'Client',
          infos: angular.copy(infosParBlock),
          multicamp: true
        };
      }

      destinataires = ctrl.contenu.getDestinataires();
      destinatairesHide = getDestinatairesForContenu(ctrl.contenu);
      init(ctrl.contenu);
      if($rootScope.isCapfun) ctrl.isEventIntervention = TopicManagerService.currentEventIsIntervention();
    };

    var listenerOnOpenMessageForDevis = $scope.$on('openMessageForDevis',function(ev,devis){
      $timeout(function() {
        openMessageOrSmsDevis('message', devis);
      });
    });
    var listenerOnOpenSMSForDevis = $scope.$on('openSMSForDevis',function(ev,devis){
      $timeout(function(){
        openMessageOrSmsDevis('sms',devis);
      });

    });

    ctrl.$onDestroy = function(){
      listenerOnOpenMessageForDevis();
      listenerOnOpenSMSForDevis();
      //if(ctrl.openInModal) TopicService.destroy();
      TopicService.destroy();
    };

    function init(contenuEvenement){

      if(destinatairesHide.length){
        destinatairesHide = destinatairesHide.map(function(dest){
          dest.allowSendEmail = false;

          // si c'est pas une perso ics (copro/loc/prop)
          if(dest.isUser() && !dest.isPersoIcs()){
            // Si on demande à envoyer par mail et que c'est un user
            dest.allowSendEmail = true;
          }

          //dest.allowSendEmail = false;
          return dest;
        });
        globalDestinatairesHide = angular.copy(destinatairesHide);
      }

      // Recup tout les tag
      tagsType = [];
      angular.forEach(ctrl.blocs,function(obj,type) {
        if(obj.noTagType) {

        }
        else {
          var tag = contenuEvenement.getTagForType(type);
          if(tag){
            tagsType.push(tag);
          }
        }
      });

      // Parcour les tags typ où il faut trouver le groupe collab
      angular.forEach(tagsType,function(tag) {

        var type = tag.getTypeTag().getLibelle();

        if(ctrl.blocs.hasOwnProperty(type)){

          var blocInfos = ctrl.blocs[type].infos;
          blocInfos.tag = tag;
          blocInfos.loading = true;
          blocInfos.display = true;


          var idPortefeuille, portefeuille;
          if(angular.isDefined(blocInfos.tag.getPortefeuille())){
            portefeuille = blocInfos.tag.getPortefeuille();
            idPortefeuille = portefeuille.getIdPortefeuille();
          }
          else if($rootScope.current.userGroupe.hasListePortefeuille()){
            var portefeuilles = $rootScope.current.userGroupe.getListePortefeuille();
            portefeuille = portefeuilles[0];
            idPortefeuille = portefeuilles[0].getIdPortefeuille();
          }
          if(portefeuille) blocInfos.portefeuille = portefeuille;

          var isGroupeNonUser = false;

          // Si le type est un type de tag considéré comme un groupe qui n'est pas un user
          if(UtilsService.contains(tagTypeGroupe,type)) isGroupeNonUser = true;

          // Si tag pour recherche multicamp
          if(ctrl.blocs[type].multicamp) {
            GroupeService
              .getInfosPersoMulticamp(idPortefeuille, blocInfos.tag.getValeurIcs())
              .then(function(result){
                blocInfos = initBlocMulticamp(result, blocInfos);
              })
              .catch(function(){
                blocInfos.display = false;
              })
              .finally(function(){
                blocInfos.loading = false;
              });
          }
          // Si collab normal
          else {
            GroupeService
              .searchGroupeByLibelleAndPortefeuilleInMySociete(idPortefeuille, blocInfos.tag.getValeurIcs())
              .then(function(groupe){
                // Si il y a un idGroupe stocké pour ce type (venant de topicInformation) et que cet idGroupe est le même que celui trouvé par le service de recherche
                // OU
                // Pas d'idGroupe pour ce type stocké
                if((!_.isNil(ctrl.idGroupeByTypeTag[type]) && groupe.idGroupe === ctrl.idGroupeByTypeTag[type]) || _.isNil(ctrl.idGroupeByTypeTag[type])){
                  blocInfos.isGroupeNonUser = isGroupeNonUser;
                  blocInfos = initBloc(groupe, blocInfos);
                }
                else blocInfos.display = false;
              })
              .catch(function(){
                blocInfos.display = false;
              })
              .finally(function(){
                blocInfos.loading = false;
              });
          }


          /*
          if(!TopicService.mapTagTypeToObject.hasOwnProperty(type)) {
            // Si tag pour recherche multicamp
            if(ctrl.blocs[type].multicamp) {
              GroupeService
                .getInfosPersoMulticamp(idPortefeuille, blocInfos.tag.getValeurIcs())
                .then(function(result){
                  TopicService.mapTagTypeToObject[type] = result;
                  blocInfos = initBlocMulticamp(result, blocInfos);
                })
                .catch(function(){
                  blocInfos.display = false;
                })
                .finally(function(){
                  blocInfos.loading = false;
                });
            }
            // Si collab normal
            else {
              GroupeService
                .searchGroupeByLibelleAndPortefeuilleInMySociete(idPortefeuille, blocInfos.tag.getValeurIcs())
                .then(function(groupe){
                  blocInfos.isGroupeNonUser = isGroupeNonUser;
                  TopicService.mapTagTypeToObject[type] = groupe;
                  blocInfos = initBloc(groupe, blocInfos);
                })
                .catch(function(){
                  blocInfos.display = false;
                })
                .finally(function(){
                  blocInfos.loading = false;
                });
            }
          }
          else {
            if(TopicService.mapTagTypeToObject[type]) {
              blocInfos.isGroupeNonUser = isGroupeNonUser;
              // Si tag pour recherche multicamp
              if(ctrl.blocs[type].multicamp) blocInfos = initBlocMulticamp(TopicService.mapTagTypeToObject[type], blocInfos);
              else blocInfos = initBloc(TopicService.mapTagTypeToObject[type], blocInfos);
              blocInfos.loading = false;
            }
            else blocInfos.display = false;
          }*/
        }
      });

      ctrl.blocs['MonGroupe'] = {
        'nom': 'Mon Groupe',
        'infos': angular.copy(infosParBlock)
      };



      initMonGroupe(contenuEvenement);
      initFournisseur(contenuEvenement);

      if($rootScope.isCapfun) {

        if(contenuEvenement.hasTagTechnicien()) {
          ctrl.blocsTechniciens = {
            nom: 'Technicien',
            infos: angular.copy(infosParBlock)
          };

          initIntervenants(contenuEvenement, ctrl.blocsTechniciens, "technique");
        }

        if(contenuEvenement.hasTagMenage()) {
          ctrl.blocsMenages = {
            nom: 'FTM',
            infos: angular.copy(infosParBlock)
          };

          initIntervenants(contenuEvenement, ctrl.blocsMenages, "menage");
        }
      }
    }

    function initBloc(groupe, blocInfos){
      blocInfos.groupeCollab = groupe;

      if(!groupe.isImmeuble()){
        blocInfos.defaultFormulaire.destinataires.push(groupe);
      }


      if(blocInfos.isGroupeNonUser){
        blocInfos.defaultFormulaire.smsAdvance = false;
        if(groupe.getFiche()) {
          blocInfos.libelle = groupe.getFiche().getNomPrenom();
        }


        //blocInfos.destinataires.push(groupe);


        if(groupe.isImmeuble()){
          blocInfos.destinataires.push(groupe);
          genereDestinataireImmeuble();
        }
      }
      else{
        blocInfos.destinataires.push(groupe);
        blocInfos.libelle = groupe.getDefaultNom();
        /*
                if(groupe.isImmeuble()){
                    blocInfos.libelle = groupe.getFiche().getNomPrenom();
                }
                else {
                    blocInfos.libelle = groupe.getUser().getFichePerso().getNomPrenom();
                }*/

      }


      if(destinatairesHide.length){
        // Parcour les destinataires caché
        for(var d = 0 ; d < destinatairesHide.length ; d++){
          // Parcour l'ensemble des tags type trouve (copro, locataire, proprietaire...)
          for(var e = 0 ; e < tagsType.length ; e++){
            // Si le destinataire caché est le meme que la valeur ics du tags type actuel
            if(UtilsService.startsWith(destinatairesHide[d].libelle,'@'+tagsType[e].getValeurIcs())){
              // on le supprime de la liste des destinataires cachés
              destinatairesHide.splice(d, 1);
              break;
            }
          }
        }
      }
      blocInfos.defaultFormulaire.destinatairesHide = destinatairesHide;


      if(destinataires.length){
        for(var i = 0 ; i < destinataires.length ; i++){
          if(destinataires[i].idGroupe === groupe.getIdGroupe()){
            blocInfos.isInEvent = true;
            break;
          }
        }
      }

      blocInfos.enabled = true;
      return blocInfos;
    }

    function initBlocMulticamp(result, blocInfos){
      blocInfos.onlySms = true;
      blocInfos.defaultFormulaire.sms = true;
      blocInfos.defaultFormulaire.smsAdvance = true;
      blocInfos.defaultFormulaire.disallowReplyWithUrlToSMS = true;

      blocInfos.multicamp = result;
      blocInfos.libelle = 'Client';

      blocInfos.destinataires.push(result); // Pour affichage des bloc

      /*
      var ProxyDestinataire = $injector.get("ProxyDestinataire");
      var proxyDest = new ProxyDestinataire();
      proxyDest.setTypeDestinataire('destinataireSms');
      proxyDest.setEmail(UtilsService.genereYopmailClientCapfun(result));
      proxyDest.setAllowSendEmail(false);
      proxyDest.setLibelle(UtilsService.genereLibelleClientCapfun(result));
      proxyDest.setDieze(COLLAB_CONF.SOCIETE_CAPFUN);
      ProxyDestinataire = null;
      blocInfos.defaultFormulaire.destinataires.push(proxyDest); // Pour formulaire du message
      */
      if(destinatairesHide.length){
        // Parcour les destinataires caché
        for(var d = 0 ; d < destinatairesHide.length ; d++){
          // Parcour l'ensemble des tags type trouve (copro, locataire, proprietaire...)
          for(var e = 0 ; e < tagsType.length ; e++){
            // Si le destinataire caché est le meme que la valeur ics du tags type actuel
            if(UtilsService.startsWith(destinatairesHide[d].libelle,'@'+tagsType[e].getValeurIcs())){
              // on le supprime de la liste des destinataires cachés
              destinatairesHide.splice(d, 1);
              break;
            }
          }
        }
      }
      blocInfos.defaultFormulaire.destinatairesHide = destinatairesHide;

      /*

      if(destinataires.length){
        for(var i = 0 ; i < destinataires.length ; i++){
          if(destinataires[i].idGroupe === groupe.getIdGroupe()){
            blocInfos.isInEvent = true;
            break;
          }
        }
      }*/

      blocInfos.enabled = true;
      return blocInfos;
    }

    function initMonGroupe(contenuEvenement){

      if(contenuEvenement.getDestinataires().length){

        var dest = getDestinatairesForContenu(contenuEvenement);

        ctrl.blocs['MonGroupe'].infos.defaultFormulaire.smsAdvance = false;
        ctrl.blocs['MonGroupe'].infos.defaultFormulaire.destinataires = dest;
        ctrl.blocs['MonGroupe'].infos.destinataires = dest;
        ctrl.blocs['MonGroupe'].infos.enabled = true;
        ctrl.blocs['MonGroupe'].infos.isInEvent = true;

        /*if(destinataires.length){

                 // var libelleMonGroupe = '';
                 // for(var j = 0 ; j < destinataires.length ; j++){
                 // 	if(destinataires[j].hasUser()){
                 // 		libelleMonGroupe += destinataires[j].getUser().getFichePerso().getNomPrenom()+', ';
                 // 	}else{
                 // 		libelleMonGroupe += destinataires[j].getLibelleDieze()+', ';
                 // 	}
                 // }
                 ctrl.blocs['MonGroupe'].infos.libelle = libelleMonGroupe.slice(0,-2);
                 ctrl.blocs['MonGroupe'].infos.display = true;
                 ctrl.blocs['MonGroupe'].infos.enabled = true;
                 ctrl.blocs['MonGroupe'].infos.isInEvent = true;*!/

                 }*/
      }

      if(ctrl.onReady){
        ctrl.onReady();
      }
    }

    function initFournisseur(contenuEvenement){

      // Recupère la liste des devis
      var listeDevis = contenuEvenement.listeDevis;
      var newListeDevis = [];

      // Parcour chaque devis
      for(var d = 0; d < listeDevis.length; d++){

        var newDevis = false;
        // Si le devis à des destinataires
        if(listeDevis[d].getDestinataires().length){
          newDevis = angular.copy(listeDevis[d]);
          newDevis.infos = angular.copy(infosParBlock);
          newDevis.infos.defaultFormulaire.devis = angular.copy(listeDevis[d]);

          for(var e = 0; e < newDevis.getDestinataires().length; e++){
            newDevis.getDestinataires()[e].typeDestinataire = 'fournisseur';
          }
          newDevis.infos.defaultFormulaire.destinataires = newDevis.getDestinataires();
          newDevis.infos.defaultFormulaire.destinatairesHide = globalDestinatairesHide;
        }
        // Si le devis est ok
        if(newDevis) {
          // Ajoute simplement le devis
          newListeDevis.push(newDevis);
        }
      }

      ctrl.listeDevis = newListeDevis;
      if(listeDevis.length) {
        ctrl.blocs['Fournisseur'].infos.enabled = true;
        ctrl.blocs['Fournisseur'].infos.display = true;
      }

      // Si il y a des choses à faire venant du parent lors de l'initialisation de ce composant
      if(_.isObject(ctrl.onInitOpen)) {
        if(ctrl.onInitOpen.hasOwnProperty('action') && ctrl.onInitOpen.hasOwnProperty('devis')) {
          if(ctrl.onInitOpen.action === 'openMessageForDevis') {
            openMessageOrSmsDevis('message', ctrl.onInitOpen.devis);
          }
          if(ctrl.onInitOpen.action === 'openSMSForDevis') {
            openMessageOrSmsDevis('sms', ctrl.onInitOpen.devis);
          }
        }
      }
    }

    /*
    function initTechniciens(contenuEvenement, obj){
      var tagPortefeuille = contenuEvenement.getTagPortefeuille();

      obj.infos.display = true;
      obj.infos.enabled = true;
      obj.infos.onlySms = true;
      obj.infos.defaultFormulaire.sms = true;
      obj.infos.defaultFormulaire.smsAdvance = true;


      if(tagPortefeuille && !_.isNil(tagPortefeuille.getPortefeuille())) {
        obj.infos.loading = true;
        if(!TopicService.mapTagTypeToObject.hasOwnProperty(obj.type)) {
          GroupeService
            .searchGroupeByLibelleAndPortefeuilleInMySociete(tagPortefeuille.getPortefeuille().getIdPortefeuille(), "technique")
            .then(function(groupe){
              var list = [];
              if(_.isObject(groupe) && _.isArray(groupe.getGroupesFils()) && groupe.getGroupesFils().length) {
                for(var g = 0; g < groupe.getGroupesFils().length; g++){
                  if(groupe.getGroupesFils()[g].isUser()) {
                    var objetInfos = angular.copy(infosParBlock);

                    objetInfos.groupeCollab = groupe.getGroupesFils()[g];
                    objetInfos.enabled = true;
                    objetInfos.onlySms = angular.copy(obj.infos.onlySms);
                    objetInfos.defaultFormulaire.sms = angular.copy(obj.infos.defaultFormulaire.sms);
                    objetInfos.defaultFormulaire.smsAdvance = angular.copy(obj.infos.defaultFormulaire.smsAdvance);
                    objetInfos.defaultFormulaire.destinataires.push(groupe.getGroupesFils()[g]);
                    objetInfos.destinataires.push(groupe.getGroupesFils()[g]);

                    list.push({
                      type: "tech",
                      infos: objetInfos
                    });
                  }
                }
              }

              obj.infos.results = list;
              TopicService.mapTagTypeToObject[obj.type] = list;
            })
            .catch(function(){
              obj.infos.display = false;
            })
            .finally(function(){
              obj.infos.loading = false;
            });
        }
        else {
          if(TopicService.mapTagTypeToObject[obj.type]) {
            obj.infos.results = TopicService.mapTagTypeToObject[obj.type];
            obj.infos.loading = false;
          }
          else obj.infos.display = false;
        }
      }
    }*/

    function initIntervenants(contenuEvenement, obj, type){
      var tagPortefeuille = contenuEvenement.getTagPortefeuille();

      obj.infos.display = true;
      obj.infos.enabled = true;
      obj.infos.onlySms = true;
      obj.infos.defaultFormulaire.sms = true;
      obj.infos.defaultFormulaire.smsAdvance = true;


      if(tagPortefeuille && !_.isNil(tagPortefeuille.getPortefeuille())) {
        obj.infos.loading = true;
        GroupeService
          .searchGroupeByLibelleAndPortefeuilleInMySociete(tagPortefeuille.getPortefeuille().getIdPortefeuille(), type)
          .then(function(groupe){
            var list = [];
            if(_.isObject(groupe) && _.isArray(groupe.getGroupesFils()) && groupe.getGroupesFils().length) {
              for(var g = 0; g < groupe.getGroupesFils().length; g++){
                if(groupe.getGroupesFils()[g].isUser()) {
                  var objetInfos = angular.copy(infosParBlock);

                  objetInfos.groupeCollab = groupe.getGroupesFils()[g];
                  objetInfos.enabled = true;
                  objetInfos.onlySms = angular.copy(obj.infos.onlySms);
                  objetInfos.defaultFormulaire.sms = angular.copy(obj.infos.defaultFormulaire.sms);
                  objetInfos.defaultFormulaire.smsAdvance = angular.copy(obj.infos.defaultFormulaire.smsAdvance);
                  objetInfos.defaultFormulaire.destinataires.push(groupe.getGroupesFils()[g]);
                  objetInfos.destinataires.push(groupe.getGroupesFils()[g]);

                  list.push({
                    type: type,
                    infos: objetInfos
                  });
                }
              }
            }

            obj.infos.results = list;
          })
          .catch(function(){
            obj.infos.display = false;
          })
          .finally(function(){
            obj.infos.loading = false;
          });

        /*
        if(!TopicService.mapTagTypeToObject.hasOwnProperty(obj.type)) {
          GroupeService
            .searchGroupeByLibelleAndPortefeuilleInMySociete(tagPortefeuille.getPortefeuille().getIdPortefeuille(), type)
            .then(function(groupe){
              var list = [];
              if(_.isObject(groupe) && _.isArray(groupe.getGroupesFils()) && groupe.getGroupesFils().length) {
                for(var g = 0; g < groupe.getGroupesFils().length; g++){
                  if(groupe.getGroupesFils()[g].isUser()) {
                    var objetInfos = angular.copy(infosParBlock);

                    objetInfos.groupeCollab = groupe.getGroupesFils()[g];
                    objetInfos.enabled = true;
                    objetInfos.onlySms = angular.copy(obj.infos.onlySms);
                    objetInfos.defaultFormulaire.sms = angular.copy(obj.infos.defaultFormulaire.sms);
                    objetInfos.defaultFormulaire.smsAdvance = angular.copy(obj.infos.defaultFormulaire.smsAdvance);
                    objetInfos.defaultFormulaire.destinataires.push(groupe.getGroupesFils()[g]);
                    objetInfos.destinataires.push(groupe.getGroupesFils()[g]);

                    list.push({
                      type: type,
                      infos: objetInfos
                    });
                  }
                }
              }

              obj.infos.results = list;
              TopicService.mapTagTypeToObject[obj.type] = list;
            })
            .catch(function(){
              obj.infos.display = false;
            })
            .finally(function(){
              obj.infos.loading = false;
            });
        }
        else {
          if(TopicService.mapTagTypeToObject[obj.type]) {
            obj.infos.results = TopicService.mapTagTypeToObject[obj.type];
            obj.infos.loading = false;
          }
          else obj.infos.display = false;
        }*/
      }
    }

    function selectResult(item, blocInfos){
      blocInfos.selectedResult = item;
      blocInfos = initBloc(item, blocInfos);
    }

    function resetResult(blocInfos){
      blocInfos.selectedResult = false;
      blocInfos.groupeCollab = false;
      blocInfos.isGroupeNonUser = false;
      blocInfos.isInEvent = false;
      blocInfos.defaultFormulaire = angular.copy(defaultFormulaire);
      blocInfos.destinataires = [];
      delete blocInfos.libelle;
      ctrl.allImmeuble = false;
      ctrl.allImmeubleChecked = false;
    }

    /**
     * Permet de récupérer les destinataires du contenu sans prendre moi meme
     * @param contenuEvenement
     */
    function getDestinatairesForContenu(contenuEvenement){
      return UtilsService.getDestinatairesHideFromEvenement(contenuEvenement);
      /*
      var array = [];
      if(contenuEvenement.getDestinataires().length) {
        array = contenuEvenement.getDestinataires();
        for (var k = 0; k < array.length; k++) {
          if (array[k].getIdGroupe() === UtilsService.getCurrentUser().getIdGroupe()) {
            array.splice(k, 1);
          }
        }
      }
      return array;*/
    }
    /*
         $scope.$watch('communicationcomponentctrl.blocs.MonGroupe.infos.defaultFormulaire.destinataires',function(newVal,oldVal){
         console.log('oldVal',oldVal);
         console.log('newVal',newVal);
         },true);

         $scope.$watchCollection('communicationcomponentctrl.contenu',function(newVal,oldVal){
         console.log('contenu oldVal',oldVal);
         console.log('contenu newVal',newVal);
         });*/

    function getIconTag(tag){
      var libelle = '';
      if(tag.hasOwnProperty('typeTag')){
        libelle = tag.typeTag.libelle;
      }
      return TagsService.getIconForTagType(libelle);
    }

    /**
     * Permet de réafficher le formulaire
     * @param type
     */
    function nouveauMessage(type){
      if (angular.isObject(type)) {
        type.infos.submit = false;
      }
      else {
        ctrl.blocs[type].infos.submit = false;
      }
    }

    /**
     * Permet de fermer la fenetre de communication
     */
    function closeCommunication(){
      if(ctrl.onClose){
        ctrl.onClose();
      }
    }

    /**
     * Ouvre le formulaire en mode Message
     * @param current
     */
    function openMessage(current){
      if(current.infos.show){

        if(!current.infos.defaultFormulaire.sms){
          current.infos.show = false;
          current.infos.submit = false;
        }
      }
      else{
        current.infos.show = true;
      }
      current.infos.defaultFormulaire.sms = false;
    }

    /**
     * Ouvre le formulaire en mode SMS
     * @param current
     */
    function openSMS(current){

      if(current.infos.show){
        if(current.infos.defaultFormulaire.sms){
          current.infos.show = false;
        }
      }
      else{
        current.infos.show = true;
      }
      current.infos.defaultFormulaire.sms = true;
      listeSalarieDestinataire = [];
      //if(!current.infos.multicamp && current.type !=="tech") genereDestinataireImmeuble();
      if(_.isObject(current.infos.groupeCollab) && current.infos.groupeCollab.isImmeuble()) genereDestinataireImmeuble();
    }

    function onCliqueExtranet(currentType){

      //console.log(currentType);
      //console.log(currentType.infos.defaultFormulaire.destinataires);

      currentType.infos.loadingPartageExtranet = true;
      sweet.show({
        title: 'Confirmation',
        text: 'Attention cette action est irréversible !',
        type: 'warning',
        html: true,
        showCancelButton: true,
        //confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confimer',
        closeOnConfirm: true,
        showLoaderOnConfirm: true
      },function(isConfirm) {

        if (isConfirm) {

          partageExtranet(currentType.infos.defaultFormulaire.destinataires).then(function(){
            currentType.infos.isInEvent = true;
          },function(){
            currentType.infos.isInEvent = false;
          }).finally(function(){
            currentType.infos.loadingPartageExtranet = false;
          });

          /*
                     var destinataires = [];
                     if(currentType.infos.defaultFormulaire.destinataires.length){
                     for(var d = 0 ; d < currentType.infos.defaultFormulaire.destinataires.length ; d++){
                     destinataires.push(currentType.infos.defaultFormulaire.destinataires[d].getLibelleDieze());
                     }
                     }

                     ctrl.contenu.addDestinataires(destinataires).then(function(groupes){
                     currentType.infos.isInEvent = true;
                     ctrl.onAddGroupesInEvenement({'objUpdate':{'addGroupes':groupes}});
                     },function(){
                     currentType.infos.isInEvent = false;
                     }).finally(function(){
                     currentType.infos.loadingPartageExtranet = false;
                     });*/

        }else{
          $scope.$apply(function(){
            currentType.infos.isInEvent = false;
            currentType.infos.loadingPartageExtranet = false;
          });

          //$scope.$digest();
        }


        //$scope.$digest();
      });
    }

    function partageExtranet(sourceDestinataires){
      var deferred = $q.defer();

      var dest = [];
      if(sourceDestinataires.length){
        for(var d = 0 ; d < sourceDestinataires.length ; d++){
          dest.push(sourceDestinataires[d].getLibelleDieze());
        }
      }


      ctrl.contenu.addDestinataires(dest).then(function(groupes){
        ctrl.onAddGroupesInEvenement({'objUpdate':{'addGroupes':groupes}});
        deferred.resolve(groupes);
      },function(){
        deferred.reject();
      });

      return deferred.promise;
    }

    function getObjetEvenement(){
      var objet = $sce.trustAsHtml(ctrl.contenu.getObjet());
      return objet;
    }

    /**
     * Lors du submit du message
     * @param type
     * @returns {boolean}
     */
    function onSubmitMessage(type){
      type.infos.loadingSubmit = true;
      return true;
    }

    /**
     * Appelé lors de l'ajout d'un contenu
     * @param onInsert
     * @param type
     */
    function onInsertContenuMessage(onInsert,type){

      var contenu = (_.isObject(onInsert) && _.isObject(onInsert.insertContenu)) ? onInsert.insertContenu : onInsert;
      if(_.isObject(contenu)){
        if (_.isObject(type)) {
          type.infos.submit = true;
          type.infos.loadingSubmit = false;
        }
        else {
          ctrl.blocs[type].infos.submit = true;
          ctrl.blocs[type].infos.loadingSubmit = false;
        }


        if(ctrl.layoutTopicDetailCtrl){
          listeSalarieDestinataire = [];
          // Ajoute le message à la page détail
          ctrl.layoutTopicDetailCtrl.addContenuMessage(contenu, true);
        }
      }
    }

    /**
     * Appelé si il y a une erreur
     * @param obj
     */
    function onErrorMessage(type){
      type.infos.loadingSubmit = false;
    }

    function openDemo(){
      $rootScope.$broadcast('openDemoExtranet');
    }

    function genereDestinataireImmeuble(){
      ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires = [];
      ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinatairesExclus = [];

      var hasCopro = false;
      if (ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.groupeCollab.getGroupeCoproprietaire().getGroupeInformations().nbGroupesUser) hasCopro = true;

      var hasProp = false;
      if (ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.groupeCollab.getGroupeProprietaire().getGroupeInformations().nbGroupesUser) hasProp = true;

      var hasLoc = false;
      if (ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.groupeCollab.getGroupeLocataire().getGroupeInformations().nbGroupesUser) hasLoc = true;

      var conseilSyndical = ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.groupeCollab.getGroupeConseilSyndical();
      var hasCS = false;
      //var hasPCS = false;
      //var hasMCS = false;
      if(_.isObject(conseilSyndical) && conseilSyndical.getGroupeInformations().nbGroupesUser) hasCS = true;
      if(_.isObject(conseilSyndical) && conseilSyndical.getGroupePresidentConseilSyndical().getGroupeInformations().nbGroupesUser) hasCS = true;
      if(_.isObject(conseilSyndical) && conseilSyndical.getGroupeMembreConseilSyndical().getGroupeInformations().nbGroupesUser) hasCS = true;

      var groupeCoproAll = false,
        groupeCoproExclus = false,
        groupePropAll = false,
        groupePropExclus = false,
        groupeLocAll = false,
        groupeLocExclus = false,
        groupeCSAll = false,
        groupePCSAll = false,
        groupePCSExclus = false,
        groupeMCSAll = false,
        groupeMCSExclus = false;

      // Si il y a le type copro
      if(groupeCommunicationDestinataire.hasOwnProperty('copro')){
        if(groupeCommunicationDestinataire.copro.length !== 0){
          // Si il y a un groupe
          if(groupeCommunicationDestinataire.copro.length === 1){
            // Si il y a des user dans ce groupe
            if(hasCopro) {
              if(groupeCommunicationDestinataire.copro[0].isCoproprietaire()){
                // Alors c'est le groupe copro
                groupeCoproAll = true;
                if(groupeCommunicationDestinataire.copro[0].hasOwnProperty('groupesUserExclus')) {
                  groupeCoproExclus = true;
                  ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinatairesExclus.push(groupeCommunicationDestinataire.copro[0]);
                }
              }
              // Sinon c'est un user
              else {
                ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.push(groupeCommunicationDestinataire.copro[0]);
              }
            }
          }
          // Si plus de un groupe dans le tableau alors ce sont des users
          else {
            for(var c = 0 ; c < groupeCommunicationDestinataire.copro.length ; c++){
              ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.push(groupeCommunicationDestinataire.copro[c]);
            }
          }
        }
      }
      // Si pas encore "copro" dans "groupeCommunicationDestinataire"
      else {
        // Si pourtant il y a bien des users dans le groupe copro et que l'immeuble est checked par défaut alors on prend le groupe entier
        if(hasCopro && immeubleCheckedByDefault) {
          groupeCoproAll = true;
        }
      }


      // Si il y a le type prop
      if(groupeCommunicationDestinataire.hasOwnProperty('prop')){
        if(groupeCommunicationDestinataire.prop.length !== 0){
          // Si il y a un groupe
          if(groupeCommunicationDestinataire.prop.length === 1){
            // Si il y a des user dans ce groupe
            if(hasProp) {
              if(groupeCommunicationDestinataire.prop[0].isProprietaire()){
                // Alors c'est le groupe prop
                groupePropAll = true;
                if(groupeCommunicationDestinataire.prop[0].hasOwnProperty('groupesUserExclus')) {
                  groupePropExclus = true;
                  ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinatairesExclus.push(groupeCommunicationDestinataire.prop[0]);
                }
              }
              // Sinon c'est un user
              else {
                ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.push(groupeCommunicationDestinataire.prop[0]);
              }
            }
          }
          // Si plus de un groupe dans le tableau alors ce sont des users
          else {
            for(var p = 0 ; p < groupeCommunicationDestinataire.prop.length ; p++){
              ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.push(groupeCommunicationDestinataire.prop[p]);
            }
          }
        }
      }
      // Si pas encore "prop" dans "groupeCommunicationDestinataire"
      else {
        // Si pourtant il y a bien des users dans le groupe prop alors on prend le groupe entier
        if(hasProp && immeubleCheckedByDefault) {
          groupePropAll = true;
        }
      }


      // Si il y a le type loc
      if(groupeCommunicationDestinataire.hasOwnProperty('loc')){
        if(groupeCommunicationDestinataire.loc.length !== 0){
          // Si il y a un groupe
          if(groupeCommunicationDestinataire.loc.length === 1){
            // Si il y a des user dans ce groupe
            if(hasLoc) {
              // Si c'est le gorupe locataire
              if(groupeCommunicationDestinataire.loc[0].isLocataire()){
                // Alors c'est le groupe loc
                groupeLocAll = true;
                if(groupeCommunicationDestinataire.loc[0].hasOwnProperty('groupesUserExclus')) {
                  groupeLocExclus = true;
                  ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinatairesExclus.push(groupeCommunicationDestinataire.loc[0]);
                }
              }
              // Sinon c'est un user
              else {
                ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.push(groupeCommunicationDestinataire.loc[0]);
              }
            }
          }
          // Si plus de un groupe dans le tableau alors ce sont des users
          else {
            for(var l = 0 ; l < groupeCommunicationDestinataire.loc.length ; l++){
              ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.push(groupeCommunicationDestinataire.loc[l]);
            }
          }
        }
      }
      // Si pas encore "loc" dans "groupeCommunicationDestinataire"
      else {
        // Si pourtant il y a bien des users dans le groupe loc alors on prend le groupe entier
        if(hasLoc && immeubleCheckedByDefault) {
          groupeLocAll = true;
        }
      }


      // Si il y a le type cs
      if(groupeCommunicationDestinataire.hasOwnProperty('cs')){
        if(groupeCommunicationDestinataire.cs.length !== 0){
          // Si il y a un groupe
          if(groupeCommunicationDestinataire.cs.length === 1){

            var destGroupeCS = groupeCommunicationDestinataire.cs[0];
            // Si il y a des user dans ce groupe
            if(destGroupeCS.nbUserTotal !== 0) {

              if(destGroupeCS.isCS()){

                // Alors c'est le groupe cs
                groupeCSAll = true;
                if(destGroupeCS.hasOwnProperty('groupesUserExclus')) {
                  ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinatairesExclus.push(destGroupeCS);
                }
                /*

                                var McsWithUsers = false;
                                var PcsWithUsers = false;
                                // Si le groupe CS à bien les groupes MCS et PCS
                                if(destGroupeCS.groupesFils.length) {
                                    // Parcour ces groupes
                                    for(var g = 0; g < destGroupeCS.groupesFils.length; g++){
                                        // Si il y a bien des user dans ces groupes
                                        if(destGroupeCS.groupesFils[g].nbUserTotal !== 0){
                                            if(destGroupeCS.groupesFils[g].isPCS()) {
                                                PcsWithUsers = destGroupeCS.groupesFils[g];
                                            }
                                            else if(destGroupeCS.groupesFils[g].isMCS()) {
                                                McsWithUsers = destGroupeCS.groupesFils[g];
                                            }
                                        }
                                    }
                                }
                                // Si les groupes enfant de CS (donc MCS et PCS) n'ont pas d'users alors on prends le groupe CS entier
                                if(!PcsWithUsers && !McsWithUsers){
                                    // Alors c'est le groupe cs
                                    groupeCSAll = true;
                                    if(destGroupeCS.hasOwnProperty('groupesUserExclus')) {
                                        ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinatairesExclus.push(destGroupeCS);
                                    }
                                }
                                // Si un des groupes enfant ou les deux de CS n'ont pas d'user
                                else {
                                    // Si groupe PCS a des users
                                    if(PcsWithUsers) {
                                        groupePCSAll = true;
                                        if(PcsWithUsers.hasOwnProperty('groupesUserExclus')) {
                                            groupePCSExclus = true;
                                            ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinatairesExclus.push(PcsWithUsers);
                                        }
                                    }
                                    // Si groupe MCS a des users
                                    else if(McsWithUsers) {
                                        if(McsWithUsers.hasOwnProperty('groupesUserExclus')) {
                                            groupeMCSExclus = true;
                                            ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinatairesExclus.push(McsWithUsers);
                                        }
                                        groupeMCSAll = true;
                                    }
                                }*/
              }
              else if(destGroupeCS.isPCS()){
                // Alors c'est le groupe pcs
                groupePCSAll = true;
                if(destGroupeCS.hasOwnProperty('groupesUserExclus')) {
                  groupePCSExclus = true;
                  ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinatairesExclus.push(destGroupeCS);
                }
              }
              else if(destGroupeCS.isMCS()){
                // Alors c'est le groupe mcs
                groupeMCSAll = true;
                if(destGroupeCS.hasOwnProperty('groupesUserExclus')) {
                  groupeMCSExclus = true;
                  ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinatairesExclus.push(destGroupeCS);
                }
              }
              // C'est un user ...
              else{
                ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.push(destGroupeCS);
              }
            }
          }

          // Si groupePropAll toujours à false alors ce sont des users
          if(!groupeCSAll) {
            for(var cs = 0 ; cs < groupeCommunicationDestinataire.cs.length ; cs++){
              //if(groupeCommunicationDestinataire.cs[cs].nbUserTotal !== 0) {
              ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.push(groupeCommunicationDestinataire.cs[cs]);
              //}
            }
          }
        }
      }
      // Si pas encore "cs" dans "groupeCommunicationDestinataire"
      else {
        // Si pourtant il y a bien des users dans le groupe cs alors on prend le groupe entier ou PCS ou MCS
        if(hasCS && immeubleCheckedByDefault) {
          groupeCSAll = true;
        }
        /*
                 if(hasPCS) {
                 groupePCSAll = true;
                 }
                 if(hasMCS) {
                 groupeMCSAll = true;
                 }*/
      }


      // Si pour tout les type de groupe tous les users sont cochés alors on prends l'immeuble en destinataire
      if(groupeCoproAll && !groupeCoproExclus && groupePropAll && !groupePropExclus && groupeLocAll && !groupeLocExclus && groupeCSAll && !groupePCSExclus && !groupeMCSExclus){
        var groupeImmeuble = ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.groupeCollab;
        ctrl.allImmeuble = true;

        ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.push(groupeImmeuble);
      }
      else {
        ctrl.allImmeuble = false;
        // Si il y a tous les user du groupe copro mais pas touts les users des autres groupes alors prends le groupe copro, les autres users sont fait avant
        if(groupeCoproAll){
          if(groupeCommunicationDestinataire.hasOwnProperty('copro')){
            ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.push(groupeCommunicationDestinataire.copro[0]);
          }
          else{
            ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.push(ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.groupeCollab.getGroupeCoproprietaire());
          }
        }



        // Si il y a tous les user du groupe prop mais pas touts les users des autres groupes alors prends le groupe prop, les autres users sont fait avant
        if(groupePropAll){
          if(groupeCommunicationDestinataire.hasOwnProperty('prop')){
            ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.push(groupeCommunicationDestinataire.prop[0]);
          }
          else{
            ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.push(ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.groupeCollab.getGroupeProprietaire());
          }
        }
        // Si il y a tous les user du groupe loc mais pas touts les users des autres groupes alors prends le groupe loc, les autres users sont fait avant
        if(groupeLocAll){
          if(groupeCommunicationDestinataire.hasOwnProperty('loc')){
            ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.push(groupeCommunicationDestinataire.loc[0]);
          }
          else{
            ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.push(ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.groupeCollab.getGroupeLocataire());
          }
        }
        // Si il y a tous les user du groupe cs (ou du groupe pcs ou mcs) mais pas touts les users des autres groupes alors prends le groupe cs (ou pcs ou mcs), les autres users sont fait avant
        // Le seul groupe qui est dans "cs" est soit CS, PCS ou MCS
        if(groupeCSAll || groupePCSAll || groupeMCSAll){
          if(groupeCommunicationDestinataire.hasOwnProperty('cs')){
            ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.push(groupeCommunicationDestinataire.cs[0]);
          }
          else{
            //if(groupeCSAll) {
            ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.push(ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.groupeCollab.getGroupeConseilSyndical());
            //}
            /*
                         if(groupePCSAll) {
                         ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.push(ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.groupeCollab.getGroupePresidentConseilSyndical());
                         }
                         if(groupeMCSAll) {
                         ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.push(ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.groupeCollab.getGroupeMembreConseilSyndical());
                         }*/

          }
        }
      }

      if(ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinatairesHide.length){
        var groupesCopros = null;
        var groupesProps = null;
        var groupesLocs = null;
        var groupesMCS = null;
        var groupesPCS = null;
        if(hasCopro) groupesCopros = ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.groupeCollab.getGroupeCoproprietaire();
        if(hasProp) groupesProps = ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.groupeCollab.getGroupeProprietaire();
        if(hasLoc) groupesLocs = ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.groupeCollab.getGroupeLocataire();
        if(hasCS) {
          groupesMCS = conseilSyndical.getGroupeMembreConseilSyndical();
          groupesPCS = conseilSyndical.getGroupePresidentConseilSyndical();
        }


        var destExclus = ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinatairesExclus;
        // Reduce sur les destinatairehide pour retirer les groupe Copro, loc, prop etc... ou les user qui sont dans un de ses groupes pour qu'il receoivent l'email
        ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinatairesHide = ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinatairesHide.reduce(function(accum, groupe){
          if(_.isObject(groupe) && groupe.isMySociete()) {
            var addGroupe = true;
            // Si user
            if(groupe.isUser()) {
              // Si c'est une pero ics
              if(groupe.isPersoIcs()) {
                // Si le groupeCopro est coché
                if(addGroupe && groupeCoproAll && _.isObject(groupesCopros) && _.isArray(groupesCopros.groupesFils) && groupesCopros.groupesFils.length){
                  // Parcour les groupes du groupe copros pour vérifier que "groupe" n'en fait pas parti
                  for(var c = 0; c < groupesCopros.groupesFils.length; c++){
                    // Si "groupe" qui est actuellement dans "destinataireHide" est trouvé dans le groupe copro coché alors on le retire de "destinatairesHide"
                    if(groupesCopros.groupesFils[c].idGroupe === groupe.idGroupe){
                      addGroupe = false;
                      break;
                    }
                  }
                }
                // Idem que Copro ci-dessous
                if(addGroupe && groupePropAll && _.isObject(groupesProps) && _.isArray(groupesProps.groupesFils) && groupesProps.groupesFils.length){
                  for(var p = 0; p < groupesProps.groupesFils.length; p++){
                    if(groupesProps.groupesFils[p].idGroupe === groupe.idGroupe){
                      addGroupe = false;
                      break;
                    }
                  }
                }
                // Idem que Copro ci-dessous
                if(addGroupe && groupeLocAll && _.isObject(groupesLocs) && _.isArray(groupesLocs.groupesFils) && groupesLocs.groupesFils.length){
                  for(var l = 0; l < groupesLocs.groupesFils.length; l++){
                    if(groupesLocs.groupesFils[l].idGroupe === groupe.idGroupe){
                      addGroupe = false;
                      break;
                    }
                  }
                }
                // Idem que Copro ci-dessous
                if(addGroupe && (groupeCSAll || groupeMCSAll) && _.isObject(groupesMCS) && _.isArray(groupesMCS.groupesFils) && groupesMCS.groupesFils.length){
                  for(var mcs = 0; mcs < groupesMCS.groupesFils.length; mcs++){
                    if(groupesMCS.groupesFils[mcs].idGroupe === groupe.idGroupe){
                      addGroupe = false;
                      break;
                    }
                  }
                }

                // Idem que Copro ci-dessous
                if(addGroupe && (groupeCSAll || groupePCSAll) && _.isObject(groupesPCS) && _.isArray(groupesPCS.groupesFils) && groupesPCS.groupesFils.length){
                  for(var pcs = 0; pcs < groupesPCS.groupesFils.length; pcs++){
                    if(groupesPCS.groupesFils[pcs].idGroupe === groupe.idGroupe){
                      addGroupe = false;
                      break;
                    }
                  }
                }

                // Si il y a des destinataires exclus
                if(!addGroupe && destExclus.length){
                  // Parcour les destinataires excluse pour ne pas retirer des destinataires hide
                  for(var d = 0; d < destExclus.length; d++){
                    if(destExclus[d].idGroupe === groupe.idGroupe) {
                      addGroupe = true;
                      break;
                    }
                  }
                }
              }
            }
            // Si c'est un groupe Immeuble, Loca, copro, Prop, CS, MCS ou PCS alors on ne l'ajoute pas dans les destinataires hides
            else if(groupe.isImmeuble() || groupe.isCoproprietaire() || groupe.isLocataire() || groupe.isProprietaire() || groupe.isCS() || groupe.isPCS() || groupe.isMCS()) {
              addGroupe = false;
            }
            if(addGroupe) accum.push(groupe);
            return accum;
          }
        }, []);
      }

      ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires = UtilsService.unique(ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires,'idGroupe');

      ctrl.allImmeubleChecked = angular.copy(immeubleCheckedByDefault);

      if(ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.length === 0) {
        ctrl.allImmeubleChecked = false;
      }
      else if(ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.length){
        ctrl.allImmeubleChecked = true;
      }


      getDestinataireSalarie();




      //console.log(ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires);
      // console.log(ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire);
    }

    function getDestinataireSalarie(){
      for(var i = 0; i < listeSalarieDestinataire.length; i++) {
        ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.push(listeSalarieDestinataire[i]);
      }
    }

    /**
     * Lors du clique sur le bouton pour ouvrir le bloc fournisseur
     * @param bloc
     */
    function onClickOpenFournisseur(bloc){
      bloc.infos.show = !bloc.infos.show;
    }

    function getNomFournisseur(devis) {
      var retour = 'NC';
      if(devis.getDestinataires().length){
        var groupeDest = devis.getDestinataires()[0];
        retour = groupeDest.getDefaultNom();
        /*
                retour = groupeDest.getLibelleDieze();
                if(groupeDest.hasUser()){
                    retour = groupeDest.getUser().getFichePerso().getNomPrenom();
                }*/
      }
      return retour;
    }

    function openMessageOrSmsDevis(type, devis){
      var nbDevis = ctrl.listeDevis.length;
      if (nbDevis) {

        for(var i = 0; i < ctrl.listeDevis.length; i++){
          if(ctrl.listeDevis[i].idContenu === devis.idContenu){
            ctrl.blocs.Fournisseur.infos.show = true;
            if(type === 'message'){
              openMessage(ctrl.listeDevis[i]);
              $location.hash('devis_'+devis.idContenu);
              $anchorScroll();
            }
            if(type === 'sms'){
              openSMS(ctrl.listeDevis[i]);
            }
          }
        }
      }
    }

    function onChangeCheckboxImmeuble(){

      /*
            console.log('ctrl.allImmeubleChecked',ctrl.allImmeubleChecked);
            if(ctrl.allImmeubleChecked) {
                var groupeImmeuble = ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.groupeCollab;
                ctrl.allImmeuble = true;
                ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires.push(groupeImmeuble);
            }
            else {
                ctrl.allImmeuble = false;
                // groupeCommunicationDestinataire.copro = [];
                // groupeCommunicationDestinataire.prop = [];
                // groupeCommunicationDestinataire.loc = [];
                // groupeCommunicationDestinataire.cs = [];
                genereDestinataireImmeuble();
            }
*/

      $rootScope.$broadcast('toggleDestinatairesImmeubleCommunication',ctrl.allImmeubleChecked);
      //console.log(ctrl.blocs[COLLAB_CONF.TAG_TYPE_IMMEUBLE].infos.defaultFormulaire.destinataires);
    }

    function onClickOpenImmeuble(currentType){
      currentType.infos.open = !currentType.infos.open;
    }

    function onChangeGroupeCommunication(obj){

      //console.log('obj',obj);

      if(obj.hasOwnProperty('partageExtranet')){
        var deferred = $q.defer();

        partageExtranet(obj.partageExtranet).then(function(groupes){
          deferred.resolve(groupes);
        },function(){
          deferred.reject();
        });

        return deferred.promise;
      }

      if(obj.hasOwnProperty('groupesDestinataire')){

        if(obj.hasOwnProperty('type')){

          if(groupeCommunicationDestinataire.hasOwnProperty(obj.type)){
            groupeCommunicationDestinataire[obj.type] = [];
          }

          if(angular.isArray(obj.groupesDestinataire)){
            //console.log('groupesDestinataire',obj.groupesDestinataire);
            groupeCommunicationDestinataire[obj.type] = obj.groupesDestinataire;
          }
          genereDestinataireImmeuble();

        }
      }
    }

    function onChangeSalarieDestinataire(obj) {
      if(obj.hasOwnProperty('salarieDestinataire')){
        listeSalarieDestinataire = angular.copy(obj.salarieDestinataire);
        genereDestinataireImmeuble();
      }
    }

    //----------- SendMail and SMS ---------------

    /**
     * Lorsqu'on ouvre le formulair epour envoyer un email
     */
    function onClickOpenSendMail(){
      ctrl.openSendMail = !ctrl.openSendMail;
    }

    /**
     * Lorsqu'on ouvre le formulair epour envoyer un sms
     */
    function onClickOpenSendSms(){
      ctrl.openSendSms = !ctrl.openSendSms;
    }

    /**
     * Lorsque le composant "formulaire-message" emit quelque chose
     * @param obj
     */
    function onEmitFromFormulaireMessageComponent(obj){
      try {
        if (!obj)
          throw new Error('Pas de "obj"');

        if(obj.action === 'closeReply'){
          ctrl.openSendMail = false;
          ctrl.openSendSms = false;
        }

        if(obj.action === 'addMessage' && _.isObject(obj.contenu)){
          if(ctrl.layoutTopicDetailCtrl){
            $scope.$emit('layoutTopicDetailOpenTab', 'topic');

            // Ajoute le message à la page détail
            ctrl.layoutTopicDetailCtrl.addContenuMessage(obj.contenu);

            UtilsService.scrollToContenu(obj.contenu);
          }
        }
      }
      catch (err) {
        console.log('[CommunicationController.onEmitFromFormulaireMessageComponent] Erreur : ' + err.message);
      }
    }
    //----------- Fin SendMail and SMS ---------------
  }
})();
