(function () {

  'use strict';

  /**
   * @desc Composant annuaire
   * @example <annuaire></annuaire>
   */
  angular
    .module('collaboreApp')
    .component('annuaire', {
      templateUrl: 'app/annuaire/annuaire.component.html',
      controllerAs: 'annuairectrl',
      controller: AnnuaireCtrl,
      bindings: {
        params: '<',                    // Les params passé dans un objet
        searchWhenOpen: '<',            // Fais la recherche lorsque l'annuaire est ouvert
        limitDestinataire: '<',
        stringTo: '<',
        currentModelEvenement: '<',
        focusRecherche: '<',            // <Boolean> Permet de focus le champ de recherche
        isModal: '<',
        allCollaborateur: '<',          // <Boolean> Permet d'appeler la servlet avec l'option pour recupérer toutes les perso meme ceux sans mails
        defautTypeAnnuaire: '<',        // <String> Type de l'annuaire par defaut
        onlyFournisseur: '<',
        defaultMetier: '<',
        infosEvent: '<',                // Divers infos de l'event
        infosSiniste: '<',              // Divers infos du sinistre
        openFrom: '<',

        onSubmit: '&',
        close: '&'
      }
    });

  /*@ngInject*/
  function AnnuaireCtrl(COLLAB_CONF, COLLAB_VALUES, $uibModal, $q, $timeout, $rootScope, $scope, $filter, UserService, AnnuaireService, UtilsService, TagService, ModalsService, ConfigService, Salarie, $state, sweet) {
    var ctrl = this;

    ctrl.getTagProjetMandat = TagService.getTagProjetMandat;
    ctrl.getTagProjetImmeuble = TagService.getTagProjetImmeuble;
    ctrl.getTagProjetLot = TagService.getTagProjetLot;

    ctrl.submitSearch = submitSearch;

    // Permet de voir les différent Template
    ctrl.showTemplateICS = showTemplateICS;
    ctrl.showTemplatePerso = showTemplatePerso;
    ctrl.showTemplateCollab = showTemplateCollab;
    ctrl.showTemplateContrats = showTemplateContrats;
    ctrl.showTemplateImmeuble = showTemplateImmeuble;
    ctrl.showTemplateOrigine = showTemplateOrigine;
    ctrl.showTemplateLotMulticamp = showTemplateLotMulticamp;

    ctrl.displayColonnePrenom = displayColonnePrenom;
    ctrl.displayColonneImmeuble = displayColonneImmeuble;
    //ctrl.displayColonneLots = displayColonneLots;
    ctrl.displayColonneDiese = displayColonneDiese;
    ctrl.displayColonneType = displayColonneType;
    ctrl.displayColonneBaux = displayColonneBaux;


    ctrl.getContrats = getContrats;
    ctrl.displayChoixOrigine = displayChoixOrigine;

    ctrl.isSearchPerso = isSearchPerso;
    ctrl.isSearchImmeuble = isSearchImmeuble;
    ctrl.isSearchFournisseur = isSearchFournisseur;
    ctrl.isSearchCollab = isSearchCollab;

    ctrl.onUpdateFromComponentListImmeuble = onUpdateFromComponentListImmeuble;
    ctrl.onInitBtnLot = onInitBtnLot;

    ctrl.hasPortefeuille = $rootScope.hasPortefeuille;
    ctrl.urlAvatar = COLLAB_VALUES.CONF_URL.URL_AVATARS;
    ctrl.loadingImg = COLLAB_CONF.MIDDLE_LOADING;
    ctrl.loadingAvatar = COLLAB_CONF.MIDDLE_LOADING_SRC;
    ctrl.miniLoading = COLLAB_CONF.MINI_LOADING;


    ctrl.hotlineICS = {
      actif: false,
      portefeuille: false
    };
    ctrl.modelAgence = {
      actif: false,
      portefeuille: false
    };

    ctrl.formSubmitted = false;
    ctrl.lastTypeRecherche = false;
    ctrl.modalIsOpen = false;
    ctrl.portefeuilles = [];                // Liste des portfeuilles
    ctrl.listLieuxCommunsCapfun = [];
    ctrl.collaborateurs = {};               // Objet des collaborateurs
    ctrl.filtreCollabore = {
      user: {
        display: true,
        nb: 0
      },
      groupe: {
        display: true,
        nb: 0
      },
      immeuble: {
        display: true,
        nb: 0
      },
      display: true,
      nb: 0
    };
    ctrl.filtreIcs = {
      proprietaire: {
        display: true,
        nb: 0
      },
      coproprietaire: {
        display: true,
        nb: 0
      },
      locataire: {
        display: true,
        nb: 0
      },
      colocataire: {
        display: true,
        nb: 0
      },
      fournisseur: {
        display: true,
        nb: 0
      },
      salarie: {
        display: true,
        nb: 0
      },
      caution: {
        display: true,
        nb: 0
      },
      assureur: {
        display: true,
        nb: 0
      },
      payeur: {
        display: true,
        nb: 0
      },
      employeur: {
        display: true,
        nb: 0
      },
      ics_: {
        display: true,
        nb: 0
      },
      client: {
        display: true,
        nb: 0
      },
      proprietairemeuble: {
        display: true,
        nb: 0
      },
      indivision: {
        display: true,
        nb: 0
      },
      display: true,
      nb: 0
    };
    ctrl.filtrePortefeuilles = {};        // Objet contenant entre autres le nombre de resultats par portefeuille

    /**
     * Objet de filtre du tableau
     * @type {{sortType: string, sortReverse: boolean, searchFilter: string}}
     */
    ctrl.filtre = {
      sortType: 'nom',
      sortReverse: false,
      tmpSearchFilter: '',
      searchFilter: ''
    };

    ctrl.displayContratsLinks = false;
    ctrl.currentEvent = {
      numMandat: false,
      numImmeuble: false,
      portefeuille: false
    };

    var prefSourcesChecked, listenerOnCloseModalAnnuaire, listenerOnAddToDestinataire;

    ctrl.listeMetiersFournisseur = [];

    // Objet de recherche de personnalités ICS
    ctrl.paramsSearch = {
      search: false,
      type: 'G',
      typePerso: 'all',
      //'sourceCollab': true,
      source: {},
      options: {
        commencePar: false
      },
      filtreAllow: {},
      metier: undefined,
      recherche: '',
      numFournisseur: null,
      noperso: null,
      immeuble: null
    };

    ctrl.resultByPortefeuilleSearch = [];
    ctrl.destinatairesSelected = [];
    ctrl.destinatairesByFournisseurSelected = {};
    ctrl.nbContactSelected = 0;

    ctrl.$onInit = function () {

      ctrl.searchWhenOpen = getParam('searchWhenOpen');
      ctrl.searchContratsImmeubleWhenOpen = getParam('searchContratsImmeubleWhenOpen');
      ctrl.limitDestinataire = getParam('limitDestinataire');
      ctrl.stringTo = getParam('stringTo');
      ctrl.currentModelEvenement = getParam('currentModelEvenement');
      ctrl.focusRecherche = getParam('focusRecherche');
      ctrl.isModal = getParam('isModal');
      ctrl.allCollaborateur = getParam('allCollaborateur');
      ctrl.allowNoMail = getParam('allowNoMail');
      ctrl.defautTypeAnnuaire = getParam('defautTypeAnnuaire');
      ctrl.onlyFournisseur = getParam('onlyFournisseur');
      ctrl.onlyContrats = getParam('onlyContrats');
      ctrl.defaultMetier = getParam('defaultMetier');
      ctrl.infosEvent = getParam('infosEvent');
      ctrl.infosSiniste = getParam('infosSiniste');
      ctrl.openFrom = getParam('openFrom');
      ctrl.paramsExterieur = getParam('searchParams');
      ctrl.createEvent = getParam('createEvent');
      ctrl.listPortefeuille = getParam('listPortefeuille');

      if(_.isNil(ctrl.focusRecherche)){
        $timeout(function(){
          ctrl.focusRecherche = true;
        }, 1000);
      }

      prefSourcesChecked = ConfigService.getPreferencesAnnuaireSourcesChecked();

      ctrl.isICS = UtilsService.isICS();

      if (ctrl.isModal) {
        listenerOnCloseModalAnnuaire = $scope.$on('closeModalAnnuaire', function () {
          ctrl.close();
        });
      }

      listenerOnAddToDestinataire = $scope.$on('addToDestinataire', function (event, destinataire) {
        // Test la validité du contact
        var objDest = UtilsService.checkValidDestinataire(destinataire.getLibelleDieze());
        objDest.idGroupe = destinataire.getIdGroupe();
        if (destinataire.hasOwnProperty('user')) {
          objDest.user = destinataire.getUser();
        }
        var retour = {
          arrayContacts: [objDest],
          othersObj: {}
        };

        if (ctrl.isModal) {
          ctrl.onSubmit({objAnnuaire: retour});
        } else {
          $rootScope.current.tmpNewEvenement.destinataires.push(objDest);
          $state.go('nouveau.evenement');
        }
      });

      // Si un model d'événement est choisi
      if (angular.isObject(ctrl.currentModelEvenement)) {
        if (ctrl.currentModelEvenement.isModeleHotline) ctrl.hotlineICS.actif = true;
        if (ctrl.currentModelEvenement.isModeleAgence) ctrl.modelAgence.actif = true;
      }

      // Si il y a des infos d'un événement à prendre en compte pour la recherche
      if (angular.isObject(ctrl.infosEvent)) {
        if (ctrl.infosEvent.displayContratsLinks) ctrl.displayContratsLinks = ctrl.infosEvent.displayContratsLinks;
        if (ctrl.infosEvent.numMandat) ctrl.currentEvent.numMandat = ctrl.infosEvent.numMandat;
        if (ctrl.infosEvent.numImmeuble) ctrl.currentEvent.numImmeuble = ctrl.infosEvent.numImmeuble;
        if (ctrl.infosEvent.portefeuille) ctrl.currentEvent.portefeuille = ctrl.infosEvent.portefeuille;
      }
      // Sinon si il y a des paramètres de recherche à prendre en compte
      else if (ctrl.paramsExterieur) {

        // Si numéro de perso
        if (ctrl.paramsExterieur.noperso) ctrl.paramsSearch.noperso = ctrl.paramsExterieur.noperso;

        // Si un numéro fournisseur avec son code metier
        if (ctrl.paramsExterieur.nofourni) {
          // Set le type de recherche en 'fournisseur'
          ctrl.defautTypeAnnuaire = 'fournisseur';
          // Set le métier par defaut
          ctrl.defaultMetier = ctrl.paramsExterieur.nofourni.substring(0, 3);
          ctrl.paramsSearch.numFournisseur = ctrl.paramsExterieur.nofourni.substring(3);
        }
        // Si noperso, immeuble et le mandat sont renseigné
        else if (ctrl.paramsExterieur.noperso && ctrl.paramsExterieur.immeuble && ctrl.paramsExterieur.mandat) {

          ctrl.paramsSearch.noperso = ctrl.paramsExterieur.noperso;
          /*
          AnnuaireService.getPerso(ctrl.paramsExterieur.noperso, ctrl.paramsExterieur.portefeuille)
              .then(function (retPerso) {
                  ctrl.paramsSearch.recherche = retPerso.perso.getNomper();
              });*/
        }
        // Si numéro d'immeuble
        else if (ctrl.paramsExterieur.immeuble) {
          // Set le type de recherche en 'immeuble'
          ctrl.defautTypeAnnuaire = 'immeuble';
          ctrl.paramsSearch.numImmeuble = ctrl.paramsExterieur.immeuble;
        }

        // Si il y a un portefeuille
        if (ctrl.paramsExterieur.portefeuille) ctrl.paramsSearch.clePortefeuille = ctrl.paramsExterieur.portefeuille;

        // Si type perso à cocher de base
        if (ctrl.paramsExterieur.typePerso) ctrl.paramsSearch.typePerso = ctrl.paramsExterieur.typePerso;
        ctrl.searchWhenOpen = true;

        //ctrl.loadingModal = true;
      }

      if(_.isArray(ctrl.listPortefeuille) && !_.isEmpty(ctrl.listPortefeuille)){
        ctrl.hasPortefeuille = true;
        initPortefeuilles(ctrl.listPortefeuille);
      }
      else if ($rootScope.hasPortefeuille) {

        ctrl.loadingSearchFormulaire = true;
        if (ctrl.searchWhenOpen || ctrl.searchContratsImmeubleWhenOpen) {
          ctrl.loadingModal = true;
        }
        UserService
          .getPortefeuilles()
          .then(function (portefeuilles) {
            initPortefeuilles(portefeuilles);
          })
          .finally(function () {
            ctrl.loadingSearchFormulaire = false;
          });
      }
    };

    ctrl.$onDestroy = function () {
      listenerOnAddToDestinataire();
      if (ctrl.isModal) {
        listenerOnCloseModalAnnuaire();
      }

      var numMandat;
      for (numMandat in poolListenersAccordion) {
        destroyListenerForMandat(numMandat);
      }
      poolListenersAccordion = {};
    };

    function initPortefeuilles(portefeuilles){
      var portefeuillesTmp = [];
      angular.forEach(portefeuilles, function (portefeuille) {

        // Si le portefeuille est bloqué, passe au suivant
        if(!portefeuille.getIsBlocked()) {

          var idPortefeuille = portefeuille.idPortefeuille;

          var insertInArray = true, isICS = false;

          // Si le composant n'est pas dans un modal OU que stringTo et égale à 'groupesConcernes'
          if (!ctrl.isModal || ctrl.stringTo === 'tag') {
            // Si le nom du portefeuille est ICS
            if (portefeuille.nom === COLLAB_CONF.NOM_PORTEFEUILLE_ICS) {
              isICS = true;
            }

            // Si c'est dans la modal et différent de 'groupesConcernes' on n'affiche pas le portefeuille ICS
          } else {
            // Si le nom du portefeuille est ICS
            if (portefeuille.nom === COLLAB_CONF.NOM_PORTEFEUILLE_ICS) {
              //insertInArray = false;
              isICS = true;
            }
          }

          /*
           // Si l'annuaire est appelé pour un autre attribut que "groupesConcernes" on n'affiche pas le portefeuille ICS
           if(ctrl.stringTo !== 'groupesConcernes'){
           // Si le nom du portefeuille est ICS
           if(portefeuille.nom === COLLAB_CONF.NOM_PORTEFEUILLE_ICS){
           insertInArray = false;
           isICS = true;
           }
           }else{
           // Si le nom du portefeuille est ICS
           if(portefeuille.nom === COLLAB_CONF.NOM_PORTEFEUILLE_ICS){
           isICS = true;
           }
           }*/

          if (!ctrl.filtrePortefeuilles.hasOwnProperty(idPortefeuille)) {
            ctrl.filtrePortefeuilles[idPortefeuille] = {
              idPortefeuille: idPortefeuille,
              nom: portefeuille.nom,
              display: true,
              nb: 0
            };
          }

          // Si la variable "insertInArray" est à true alors on peux l'ajouter
          if (insertInArray) {
            // Si le portefeuille est celui d'ics
            if (isICS) {
              // Ajoute au début du tableau
              portefeuillesTmp.unshift(portefeuille);

              // Si modèle de type hotline
              if (ctrl.hotlineICS.actif) {
                ctrl.paramsSearch.source[portefeuille.idPortefeuille] = true;
              }

              ctrl.hotlineICS.portefeuille = portefeuille;


            } else {
              // Ajotue à la fin du tableau
              portefeuillesTmp.push(portefeuille);
            }

          }
        }

      });

      ctrl.portefeuilles = portefeuillesTmp;


      // Si il n'y à qu'un portefeuille on le coche
      if (ctrl.portefeuilles.length === 1) {
        ctrl.paramsSearch.source[ctrl.portefeuilles[0].idPortefeuille] = true;
        ctrl.onChangeSourceRecherche(ctrl.portefeuilles[0].idPortefeuille);
      }





      // Si on a renseigné un type par defaut pour l'annuaire
      if (ctrl.defautTypeAnnuaire) {
        // Si type fournisseur
        if (ctrl.defautTypeAnnuaire === 'fournisseur') {
          ctrl.paramsSearch.type = 'F';
          // Recupération de la liste des métiers fournisseurs
          ctrl.getListMetiersFournisseur()
            .then(function () {
              if (ctrl.searchWhenOpen) search();
              if (ctrl.searchContratsImmeubleWhenOpen && ctrl.currentEvent.numImmeuble) {
                getContrats(null, ctrl.currentEvent.numImmeuble);
              }
            });
        }
        else {
          if (ctrl.defautTypeAnnuaire === 'perso') {
            ctrl.paramsSearch.type = 'U';
            if($rootScope.isCapfun) ctrl.paramsSearch.type = 'M';
          }
          else if (ctrl.defautTypeAnnuaire === 'collabore') ctrl.paramsSearch.type = 'G';
          else if (ctrl.defautTypeAnnuaire === 'immeuble') {
            ctrl.paramsSearch.type = 'I';
            if($rootScope.isCapfun) ctrl.paramsSearch.type = 'M';
          }

          if (ctrl.searchWhenOpen) search();
        }
      }
      // Si pas de type renseigné
      else {
        // Si l'annuaire est appelé pour les tags
        if (ctrl.stringTo === 'tag') {
          ctrl.paramsSearch.type = 'U';
          if($rootScope.isCapfun) ctrl.paramsSearch.type = 'M';
        }
        /*else{
         if(ctrl.onlyFournisseur){
         if(!UtilsService.isICS()){

         ctrl.limitDestinataireByFournisseur = 1;
         ctrl.hideTypeRecherche = true;

         ctrl.paramsSearch.type = 'F';
         // Recupération de la liste des métiers fournisseurs
         ctrl.getListMetiersFournisseur();
         }
         }
         }*/
        // Si affiche seulement les fournisseur
        if (ctrl.onlyFournisseur) {
          if (!UtilsService.isICS()) {

            ctrl.limitDestinataireByFournisseur = 1;
            ctrl.hideTypeRecherche = true;

            ctrl.paramsSearch.type = 'F';

            // Recupération de la liste des métiers fournisseurs
            ctrl.getListMetiersFournisseur()
              .then(function () {
                if (ctrl.searchWhenOpen) search();
              });
          }
        }
        else if (ctrl.onlyContrats) {
          ctrl.hideTypeRecherche = true;
          ctrl.hideChampRecherche = true;
          if (ctrl.searchWhenOpen) search();
          if (ctrl.searchContratsImmeubleWhenOpen && ctrl.currentEvent.numImmeuble) {
            getContrats(null, ctrl.currentEvent.numImmeuble);
          }
        }
      }

      setPrefCheckedSource();
    }

    /**
     * Permet de recupérer la valeur d'une vairbale dans l'objet "ctrl.params"
     * @param param
     * @returns {*}
     */
    function getParam(param, defaultVal) {
      var valeur = null;
      if (ctrl.hasOwnProperty(param)) {
        valeur = ctrl[param];
      }
      if (ctrl.hasOwnProperty('params') && angular.isObject(ctrl.params)) {
        if (ctrl.params.hasOwnProperty(param) && !angular.isUndefined(ctrl.params[param])) {
          valeur = ctrl.params[param];
        }
      }
      if (valeur == null && defaultVal) return defaultVal;
      return valeur;
    }

    var poolListenersAccordion = {};

    /**
     * Permet d'init un objet qui va garder en mémoire les listener pour les accordion d'un mandat
     * @param numMandat
     */
    function initPoolListenerForMandat(numMandat) {
      if (!poolListenersAccordion.hasOwnProperty(numMandat)) {
        poolListenersAccordion[numMandat] = {
          onready: null
        };
      }
    }

    /**
     * Permet de destroy les listener d'un mandat
     * @param numMandat
     */
    function destroyListenerForMandat(numMandat) {
      if (poolListenersAccordion.hasOwnProperty(numMandat) && poolListenersAccordion[numMandat].hasOwnProperty('onready') && poolListenersAccordion[numMandat].onready) {
        poolListenersAccordion[numMandat].onready();
        delete poolListenersAccordion[numMandat];
      }
    }

    /********** Tableau *************/

    /**
     * Permet de trier les colonnes
     * @param type
     */
    ctrl.sortCollaborateur = function (type) {
      ctrl.filtre.sortType = type;
      ctrl.filtre.sortReverse = !ctrl.filtre.sortReverse;
    };

    /***** Si composant appelé par Modal *****/

    /**
     * Permet d'enrichir l'objet retourné par l a fonction checkValidDestinataire()
     * @param objCustom
     * @param objOrigin
     * @returns {*}
     */
    function customObjet(objCustom, objOrigin) {
      //console.log('objOrigin',objOrigin);
      // Si groupe collab
      if (objOrigin.isCollab) {
        objCustom.type = 'collab';
        objCustom.idGroupe = objOrigin.idGroupe;
        if (objOrigin.hasOwnProperty('user')) {
          objCustom.user = objOrigin.user;
        }
        if (objOrigin.hasOwnProperty('obj')) {
          if (objOrigin.obj.hasOwnProperty('groupe')) {
            if (objOrigin.obj.groupe.hasOwnProperty('fiche')) {
              objCustom.fiche = objOrigin.obj.groupe.fiche;
            }

            if (objOrigin.obj.groupe.hasOwnProperty('tagsGenerate')) {
              objCustom.tagsGenerate = objOrigin.obj.groupe.tagsGenerate;
            }
          }
        }
      }

      // Si perso ICS
      if (objOrigin.isPerso) {
        objCustom.type = 'perso';

        if (objOrigin.hasOwnProperty('obj')) {

          if (objOrigin.obj.hasOwnProperty('noperso')) {
            objCustom.noperso = objOrigin.obj.noperso;
          }

          if (objOrigin.obj.hasOwnProperty('perso')) {
            objCustom.perso = objOrigin.obj.perso;

            if (objOrigin.obj.perso.hasOwnProperty('tagsGenerate')) {
              objCustom.perso.tagsGenerate = objOrigin.obj.perso.tagsGenerate;
            }
          } else {
            objCustom.perso = objOrigin.obj;
          }

          if (objOrigin.obj.hasOwnProperty('portefeuille')) {
            objCustom.portefeuille = objOrigin.obj.portefeuille;
          } else {
            if (ctrl.currentEvent.portefeuille) {
              objCustom.portefeuille = ctrl.currentEvent.portefeuille;
            }
          }

        }
      }

      return objCustom;
    }

    /**
     * Transmet les destinataires et ferme le Modal
     */
    ctrl.saveDestinataires = function () {


      if (!ctrl.destinatairesSelected.length) {
        return;
      }

      ctrl.loadingModal = true;

      var hasErreur = false;
      var retour = {};

      if (ctrl.stringTo === 'tag') {
        retour = {
          'collaborateurs': []
        };
      } else {
        retour = {
          'arrayContacts': [],
          'othersObj': {}
        };
      }

      // Parcour les destinataires
      angular.forEach(ctrl.destinatairesSelected, function (dest) {
        //console.log(dest);

        dest.erreur = false;

        var erreur = false;

        // Si annuaire appelé pour les tags
        if (ctrl.stringTo === 'tag') {
          /*
           var libelle = dest.obj.nom;
           if(angular.isUndefined(libelle)){ libelle = dest.obj.prenom; }
           if(angular.isUndefined(libelle)){ libelle = dest.obj.libelle; }

           var objCustom = {
           libelle: libelle,
           dieze: '',
           boolMail: false
           };

           retour.othersObj.tagsType = dest.tagsType;
           retour.othersObj.tags = dest.tags;
           retour.othersObj.fiches = dest.fiches;

           objCustom = customObjet(objCustom,dest);
           retour.arrayContacts.push(objCustom);*/

          retour.collaborateurs.push(dest.obj);

        } else {
          if (dest.contacts.length) {
            // Pour chaque contacts du destinataire (mails)
            angular.forEach(dest.contacts, function (objContact) {

              objContact.erreur = false;

              // Si le mails est coché
              if (objContact.actif) {
                // Test la validité du contact
                var objDest = UtilsService.checkValidDestinataire(objContact.libelle);

                // Si erreur dans le contact et on autorise pas les contact sans mail
                if (!objDest.success && !ctrl.allowNoMail) {
                  erreur = true;
                  objContact.erreur = true;
                } else {
                  objDest = customObjet(objDest, dest);
                  retour.arrayContacts.push(objDest);
                }
              }
            });

          }
        }

        // Si au moin une erreur dans le contact
        if (erreur) {
          hasErreur = true;
          dest.erreur = true;
        }
      });

      if (hasErreur) {
        ctrl.loadingModal = false;
      } else {
        ctrl.loadingModal = false;

        ctrl.onSubmit({'objAnnuaire': retour});
      }
    };

    /***** Fin gestion Modal *****/

    /********** Gestion de la recherche *************/

    function setPrefCheckedSource() {

      prefSourcesChecked = ConfigService.getPreferencesAnnuaireSourcesChecked();

      // Si "prefSourcesChecked" est un objet
      if (angular.isObject(prefSourcesChecked)) {
        if (prefSourcesChecked.hasOwnProperty(ctrl.stringTo)) {
          angular.forEach(ctrl.portefeuilles, function (portefeuille) {


            // Si cet objet a "F" ou "U" en attribut
            if (prefSourcesChecked[ctrl.stringTo].hasOwnProperty(ctrl.paramsSearch.type)) {
              // Si il y a l'idPortefeuille en attribut
              if (prefSourcesChecked[ctrl.stringTo][ctrl.paramsSearch.type].hasOwnProperty(portefeuille.idPortefeuille)) {
                ctrl.paramsSearch.source[portefeuille.idPortefeuille] = prefSourcesChecked[ctrl.stringTo][ctrl.paramsSearch.type][portefeuille.idPortefeuille];
              }
            }
          });

          // Si recherche Collabore
          if (ctrl.paramsSearch.type === 'G') {

            // Si cet objet a "G" en attribut
            if (prefSourcesChecked[ctrl.stringTo].hasOwnProperty('G')) {
              // Si cet objet a "usersCollabore" en attribut
              if (prefSourcesChecked[ctrl.stringTo]['G'].hasOwnProperty('usersCollabore')) {
                ctrl.paramsSearch.sourceUtilisateursCollabore = prefSourcesChecked[ctrl.stringTo]['G'].usersCollabore;
              }
              // Si cet objet a "usersCollabore" en attribut
              if (prefSourcesChecked[ctrl.stringTo]['G'].hasOwnProperty('groupesCollabore')) {
                ctrl.paramsSearch.sourceGroupesCollabore = prefSourcesChecked[ctrl.stringTo]['G'].groupesCollabore;
              }
            }
          }

          /*
           if(ctrl.paramsSearch.type === 'U'){

           // Si cet objet a "U" en attribut
           if(prefSourcesChecked[ctrl.stringTo].hasOwnProperty('U')){
           // Si cet objet a "collabore" en attribut
           if(prefSourcesChecked[ctrl.stringTo]['U'].hasOwnProperty('collabore')){
           ctrl.paramsSearch.sourceCollab = prefSourcesChecked[ctrl.stringTo]['U'].collabore;
           }
           }
           }*/
        }
      }
    }

    /**
     * Lors du changement de type de recherche (perso, fournisseurs)
     */
    ctrl.onChangeTypeRecherche = function (formRecherche) {

      // Remet l'état du formulaire en non soumit
      formRecherche.$submitted = false;

      ctrl.focusRecherche = false;

      prefSourcesChecked = ConfigService.getPreferencesAnnuaireSourcesChecked();

      if (ctrl.paramsSearch.type === 'F') {

        // Décoche la source "Collabore"
        //ctrl.paramsSearch.sourceCollab = false;


        // Recupération de la liste des métiers fournisseurs
        ctrl.getListMetiersFournisseur();

        // Coche tous les portefeuilles
        angular.forEach(ctrl.portefeuilles, function (portefeuille) {
          ctrl.paramsSearch.source[portefeuille.idPortefeuille] = true;
        });

      } else {
        if (ctrl.paramsSearch.type === 'G') {
          ctrl.paramsSearch.sourceUtilisateursCollabore = true;
          ctrl.paramsSearch.sourceGroupesCollabore = true;
        }

        if (ctrl.paramsSearch.type === 'CAPFUN_COMMUNS') {
          genereLieuxCommunsCapfun();
        }
        else {
          ctrl.hideChampRecherche = false;
          $timeout(function () {
            ctrl.focusRecherche = true;
          });
        }
      }
      /*else if(ctrl.paramsSearch.type === 'U'){
                   // Coche la source "Collabore"
                   ctrl.paramsSearch.sourceCollab = true;
                   ctrl.focusRecherche = true;

                   }else if(ctrl.paramsSearch.type === 'G'){
                   // Coche la source "Collabore"
                   ctrl.paramsSearch.sourceCollab = true;
                   ctrl.focusRecherche = true;
                   }*/

      setPrefCheckedSource();
    };

    ctrl.onChangeTypePersoRecherche = function (formRecherche) {
      // Remet l'état du formulaire en non soumit
      formRecherche.$submitted = false;

    };

    function checkIfOnlyICS() {
      //------ Permet de savoir si il y a seulement ICS coché comme source -------

      if (angular.isObject(ctrl.hotlineICS.portefeuille)) {

        // Si il y a des sources cochées
        if (!angular.equals(ctrl.paramsSearch.source, {})) {

          var sources = [];

          // Pour chaque source
          angular.forEach(ctrl.paramsSearch.source, function (bool, idPortefeuille) {
            if (bool) {
              sources.push(idPortefeuille);
            }
          });

          var isOnlyICS = false;

          // Si collab n'est pas coché
          // if(!ctrl.paramsSearch.sourceCollab){
          // Si recherche une perso et un portefeuille
          if (ctrl.paramsSearch.type === 'U' && sources.length === 1) {
            // Si le portefeuille est celui de ICS
            if (ctrl.hotlineICS.portefeuille.idPortefeuille === parseInt(sources[0])) {
              isOnlyICS = true;
            }
          }
          //}

          ctrl.isPortefeuilleICS = isOnlyICS;
        }
      }
      //------------ Fin de la vérification ICS ------------
    }

    /**
     * Lors du changement de la source de recherche (collabore et portefeuilles)
     * @param id
     */
    ctrl.onChangeSourceRecherche = function (id) {

      var isCollabore = false;
      var isPortefeuille = false;

      if (id === 'collabore' || id === 'utilisateursCollabore' || id === 'groupesCollabore') {
        isCollabore = true;
      } else {
        isPortefeuille = true;
      }


      // Si l'id est un attribut de l'objet source
      if (ctrl.paramsSearch.source.hasOwnProperty(id)) {
        var sourceExist = false;
        // Parcour les lignes du tableau
        angular.forEach(ctrl.collaborateurs.rows, function (ligne) {

          // Si la case coché est la case collabore
          if (isCollabore) {
            // Si la ligne est de type collabore
            if (ligne.isCollab) {
              sourceExist = true;
            }

            // Si la case coché est la case d'un portefeuille
          } else if (isPortefeuille) {
            // Si la ligne est de type perso
            if (ligne.isPerso) {
              sourceExist = true;
            }
          }
        });
        if (!sourceExist) {
          if (isCollabore) {
            ctrl.filtreCollabore.nb = 0;
          } else if (isPortefeuille) {
            ctrl.filtrePortefeuilles[id].nb = 0;
          }
        }
      }

      var objPref = angular.copy(prefSourcesChecked);
      if (!objPref) {
        objPref = {};
        objPref[ctrl.stringTo] = {};
      }
      if (!objPref.hasOwnProperty(ctrl.stringTo)) {
        objPref[ctrl.stringTo] = {};
      }
      objPref[ctrl.stringTo][ctrl.paramsSearch.type] = angular.copy(ctrl.paramsSearch.source);

      /*
       if(ctrl.paramsSearch.type === 'U'){
       objPref[ctrl.stringTo]['U'].collabore = angular.copy(ctrl.paramsSearch.sourceCollab);
       }*/

      if (ctrl.paramsSearch.type === 'G') {
        objPref[ctrl.stringTo]['G'].usersCollabore = angular.copy(ctrl.paramsSearch.sourceUtilisateursCollabore);
        objPref[ctrl.stringTo]['G'].groupesCollabore = angular.copy(ctrl.paramsSearch.sourceGroupesCollabore);
      }

      // Attributs autorisé
      var attributAllow = ['destinataires', 'groupesConcernes', 'annuaire', 'tag'];

      // Parcour tous les attributs de l'objet de sources checked
      angular.forEach(objPref, function (objAttribut, attribut) {
        var allow = false;
        // Parcour le tableau des attribut autorisés
        for (var i = 0; i < attributAllow.length; i++) {
          // Si l'attribut en cours est autorisé
          if (attribut === attributAllow[i]) {
            allow = true;
            break;
          }
        }
        // Si l'attribut n'est pas autorisé
        if (!allow) {
          delete objPref[attribut];
        }
      });
      /*
       if(objPref.hasOwnProperty('U')){            delete objPref['U'];}
       if(objPref.hasOwnProperty('F')){            delete objPref['F'];}
       if(objPref.hasOwnProperty('G')){            delete objPref['G'];}
       if(objPref.hasOwnProperty('undefined')){    delete objPref['undefined'];}
       if(objPref.hasOwnProperty('modalannuairectrl.stringTo')){    delete objPref['modalannuairectrl.stringTo'];}*/

      ConfigService.setPreferencesAnnuaireSourcesChecked(objPref);
    };

    /**
     * Recupère la liste des métiers fournisseur
     */
    ctrl.getListMetiersFournisseur = function () {

      var deferred = $q.defer();
      ctrl.disableForm = true;
      ctrl.loadingSearchMetiersFournisseur = true;

      AnnuaireService.getListMetiersFournisseur('').then(function (liste) {
        ctrl.listeMetiersFournisseur = liste;

        if (angular.isString(ctrl.defaultMetier) && liste.length) {
          for (var i = 0; i < liste.length; i++) {
            if (liste[i].codemetier === ctrl.defaultMetier) {
              ctrl.paramsSearch.metier = liste[i];
              break;
            }
          }
        }

        deferred.resolve();
      }).catch(function (msg) {
        deferred.reject(msg);
      }).finally(function () {
        ctrl.disableForm = false;
        ctrl.loadingSearchMetiersFournisseur = false;

      });
      return deferred.promise;
    };

    /**
     * Permet de mettre tru ou false à l'attribut ng-required des champs checkbox source
     * @returns {boolean}
     */
    ctrl.requiredSource = function (isPortefeuille) {
      var retour = true, portefeuilleChoisi = false;

      // Parcour les sources portefeuille choisi
      /*
       angular.forEach(ctrl.paramsSearch.source,function(source){
       if(source){
       retour = false;
       }
       });*/

      /*
       // Si source collabore choisi
       if(ctrl.paramsSearch.sourceCollab){
       retour = false;
       }*/

      if (ctrl.paramsSearch.type === 'G') {
        // Si source usersCollabore ou groupesCollabore choisi
        if (ctrl.paramsSearch.sourceUtilisateursCollabore || ctrl.paramsSearch.sourceGroupesCollabore) {
          retour = false;
        }
      }


      // Si il y au moins une source portefeuille coché
      angular.forEach(ctrl.paramsSearch.source, function (source) {
        if (source) {
          portefeuilleChoisi = true;
          retour = false;
        }
      });

      // Si c'est un portefeuille est qu'aucun n'est coché
      if (isPortefeuille && !portefeuilleChoisi) {
        // Si on cherche des fournisseurs alors
        if (ctrl.paramsSearch.type === 'F') {
          retour = true;
        }
      }

      return retour;
    };

    /**
     * Permet de récupérer les contrats auprès de la servlet
     * @param numMandat
     * @param numImmeuble
     */
    function getContrats(numMandat, numImmeuble) {
      if ((numMandat || numImmeuble) && ctrl.currentEvent.portefeuille) {
        ctrl.searchContrats = true;
        ctrl.loadingModal = true;
        AnnuaireService
          .searchContrats(numMandat, numImmeuble, ctrl.currentEvent.portefeuille.idPortefeuille)
          .then(function (contrats) {
            /*
             var retour = contrats;
             if(numImmeuble && contrats.length){
             retour = contrats[0];
             }*/


            /*
             if(contrats.length){
             for(var i = 0; i < contrats.length ; i++){

             for(var j = 0; j < contrats[i].metiers.length ; j++){

             for(var k = 0; k < contrats[i].metiers[j].contrats.length ; k++){


             var perso = contrats[i].metiers[j].contrats[k].getFournisseur();


             var numero = perso.getNumero();

             if(angular.isUndefined(numero) || numero==='' || !numero){
             numero = perso.getNoperso();
             }

             var collaborateur = {
             'isPerso': true,
             'isFournisseur': true,
             'isAllowForDestinataire': false,
             'type': 'perso',
             'noperso': perso.getNoperso(),
             'perso': perso,
             'nom': perso.getNom(false),
             'prenom': numero,
             'ville': perso.getVille(),
             'libelle': '-',
             'telephone': '-',
             'portefeuille': {
             'idPortefeuille': ctrl.currentEvent.portefeuille.idPortefeuille,
             'nom': ctrl.currentEvent.portefeuille.nom
             },

             'popover': {
             'title': perso.getNom(false),
             'template': 'app/popovers/popover-personnalite.html'
             },
             'arrayMails': [],
             'arrayTelephones': []
             };

             var mails = perso.getMails();
             if(mails.length){
             collaborateur.libelle = mails[0];
             collaborateur.arrayMails = mails;

             if(UtilsService.isMail(mails[0])){
             collaborateur.isAllowForDestinataire = true;
             }

             }

             var telephones = perso.getTelephones();
             if(telephones.length){
             collaborateur.telephone = telephones[0];
             collaborateur.arrayTelephones = telephones;
             }


             contrats[i].metiers[j].contrats[k].persofournisseur = collaborateur;

             }
             }

             }
             }*/
            ctrl.collaborateurs.rows = contrats;
            //console.log(contrats);
          })
          .finally(function () {
            ctrl.loadingModal = false;
          });
      }

    }

    /**
     * Recherche une personnalitée
     */
    var search = function () {

      ctrl.paramsSearch.filtreAllow = {};
      ctrl.searchLotMulticamp = false;
      ctrl.searchImmeuble = false;
      ctrl.searchContrats = false;
      ctrl.searchOrigine = false;

      var params = {};
      params.usersCollab = 0;
      params.groupesCollab = 0;
      //params.collab = 0;

      // Si pas de portefeuille
      if (!ctrl.hasPortefeuille) {
        params.usersCollab = 1;
        params.groupesCollab = 1;

        //params.collab = 1;
        ctrl.paramsSearch.type = 'U';
        ctrl.paramsSearch.filtreAllow.collabore = true;
        /*
         ctrl.paramsSearch.type = 'U';
         ctrl.paramsSearch.sourceCollab = true;*/
      }

      // Si recherche fournisseur
      if (ctrl.paramsSearch.type === 'F') {

        params.recherche = '';

        // Si la champ de recherche libre est remplie
        if (angular.isDefined(ctrl.paramsSearch.recherche)) params.recherche = ctrl.paramsSearch.recherche;

        // Si un metier est choisi
        if (angular.isObject(ctrl.paramsSearch.metier)) params.codemetier = ctrl.paramsSearch.metier.codemetier;

        // Si la recherche doit se faire le numéro fournisseur
        if (ctrl.paramsSearch.numFournisseur) {
          params.numFournisseur = ctrl.paramsSearch.numFournisseur;
          delete params.recherche;
        }
      }
      // Si recherche un utilisateur
      else if (ctrl.paramsSearch.type === 'U' || ctrl.paramsSearch.type === 'G') {
        params.recherche = ctrl.paramsSearch.recherche;

        if (ctrl.paramsSearch.type === 'G') {
          ctrl.paramsSearch.filtreAllow.collabore = true;

          // Si recherche Collabore
          if (ctrl.paramsSearch.type === 'G') {

            // Si recherche users
            if (ctrl.paramsSearch.sourceUtilisateursCollabore) {
              params.usersCollab = 1;
            }

            // Si recherche groupes
            if (ctrl.paramsSearch.sourceGroupesCollabore) {
              params.groupesCollab = 1;
            }
          }

        }
        else if (ctrl.paramsSearch.type === 'U') {
          ctrl.paramsSearch.filtreAllow.ics = true;
          if (ctrl.paramsSearch.typePerso && ctrl.paramsSearch.typePerso !== 'all') params.fortype = ctrl.paramsSearch.typePerso;
          // Si recherche par noperso recu en paramètre de l'exterieur
          if (ctrl.paramsSearch.noperso) {
            params.noperso = ctrl.paramsSearch.noperso;
            delete params.recherche;
          }
        }

      }
      else if (ctrl.paramsSearch.type === 'I') {
        params = {};

        ctrl.searchImmeuble = true;
        // Si la champ de recherche libre est remplie
        if (angular.isDefined(ctrl.paramsSearch.recherche)) params.recherche = ctrl.paramsSearch.recherche;

        // Si recherche avec un numéro d'immeuble
        if (ctrl.paramsSearch.numImmeuble) params.numImmeuble = ctrl.paramsSearch.numImmeuble;
      }
      else if (ctrl.paramsSearch.type === 'O') ctrl.searchOrigine = true;
      else if (ctrl.paramsSearch.type === 'M') {
        params = {};
        ctrl.searchLotMulticamp = true;
        // Si la champ de recherche libre est remplie
        if (!_.isNil(ctrl.paramsSearch.recherche)) params.recherche = ctrl.paramsSearch.recherche;
      }

      // Si recherche fournisseur ou perso alors on peut avoir l'option "commencePar"
      if (ctrl.paramsSearch.type === 'F' || ctrl.paramsSearch.type === 'U') params.commencePar = ctrl.paramsSearch.options.commencePar;

      // Si il faut rechercher avec une cle portefeuille
      if (ctrl.paramsSearch.clePortefeuille) params.clePortefeuille = ctrl.paramsSearch.clePortefeuille;

      params.type = ctrl.paramsSearch.type;
      ctrl.lastTypeRecherche = angular.copy(params.type);

      // Si différent de objet vide
      if (!angular.equals(ctrl.paramsSearch.source, {})) {

        var sources = [];
        angular.forEach(ctrl.paramsSearch.source, function (bool, idPortefeuille) {
          if (bool) {
            sources.push(idPortefeuille);

            // Affiche le filtre
            ctrl.paramsSearch.filtreAllow[idPortefeuille] = true;
          }
        });

        if (sources.length) {
          params.idPortefeuilles = sources;
        }
      }

      if (ctrl.paramsSearch.type === 'G') delete params.idPortefeuilles;

      // Si annuaire pour les groupes concernes OU option "allCollaborateur" à true
      if ((ctrl.stringTo === 'tag' || ctrl.allCollaborateur) && ctrl.paramsSearch.type !== 'M') {
        params.avecemail = 0;   // Pour recuperer tous les résultats sur ICSCloud meme ceux sans email. (Si il y a un mail alors il sera dans la perso)
      }


      // Permet de savoir si il y a seulement ICS coché comme source
      checkIfOnlyICS();


      //console.log(params);

      ctrl.loadingModal = true;

      if (ctrl.paramsSearch.type !== 'O') {
        AnnuaireService.searchCollaborateur(params).then(function (objectCollaborateur) {

          var arrayCollaborateur = [];

          // Met à 0 les nombre de résultats du filtre collabore
          ctrl.filtreCollabore.nb = 0;
          ctrl.filtreCollabore.user.nb = 0;
          ctrl.filtreCollabore.groupe.nb = 0;
          ctrl.filtreCollabore.immeuble.nb = 0;

          ctrl.filtreIcs.nb = 0;
          ctrl.filtreIcs.proprietaire.nb = 0;
          ctrl.filtreIcs.coproprietaire.nb = 0;
          ctrl.filtreIcs.locataire.nb = 0;
          ctrl.filtreIcs.colocataire.nb = 0;
          ctrl.filtreIcs.fournisseur.nb = 0;
          ctrl.filtreIcs.salarie.nb = 0;
          ctrl.filtreIcs.caution.nb = 0;
          ctrl.filtreIcs.assureur.nb = 0;
          ctrl.filtreIcs.payeur.nb = 0;
          ctrl.filtreIcs.employeur.nb = 0;
          ctrl.filtreIcs.ics_.nb = 0;
          ctrl.filtreIcs.client.nb = 0;
          ctrl.filtreIcs.proprietairemeuble.nb = 0;
          ctrl.filtreIcs.indivision.nb = 0;

          // Met à 0 le nombre de résultats des filtre portefeuille
          angular.forEach(ctrl.filtrePortefeuilles, function (portefeuille) {
            portefeuille.nb = 0;
          });

          if (objectCollaborateur) {

            // Si il y a des perso ou fournisseur
            if (objectCollaborateur.listePortefeuilles.length) {
              angular.forEach(objectCollaborateur.listePortefeuilles, function (portefeuille) {

                var idPortefeuille = portefeuille.getIdPortefeuille();

                if (portefeuille.getListeImmeuble().length) {
                  for (var i = 0; i < portefeuille.getListeImmeuble().length; i++) {
                    var currentImmeuble = portefeuille.getListeImmeuble()[i];
                    var objetCollaborateur = AnnuaireService.genereCollaborateur(currentImmeuble, portefeuille, ctrl.filtreCollabore, ctrl.filtreIcs, ctrl.hotlineICS.actif);
                    ctrl.filtrePortefeuilles[idPortefeuille].nb++;
                    arrayCollaborateur.push(objetCollaborateur.collaborateur);
                  }
                }

                if (portefeuille.getListePersonnalite().length) {
                  angular.forEach(portefeuille.getListePersonnalite(), function (perso) {

                    // Si paramètres de recherche venant de l'exterieur alors rempli le champ libre de recherche avec la perso trouvé
                    if (angular.isObject(ctrl.paramsExterieur) && ctrl.paramsExterieur.noperso) {
                      perso.setTypes(ctrl.paramsSearch.typePerso);
                      ctrl.paramsSearch.recherche = perso.getNomper();
                      delete ctrl.paramsSearch.noperso; // Supprime le noperso des param de recherche pour que le client est la main sur la recherche
                      /**
                       * Utilise la methode "onInitBtnLot" pour ouvrir les lots lorsque le bouton est bien chargé
                       */
                    }

                    var objetCollaborateur = AnnuaireService.genereCollaborateur(perso, portefeuille, ctrl.filtreCollabore, ctrl.filtreIcs, ctrl.hotlineICS.actif);
                    arrayCollaborateur.push(objetCollaborateur.collaborateur);
                    ctrl.filtreIcs = angular.merge(ctrl.filtreIcs, objetCollaborateur.filtreIcs);
                    ctrl.filtrePortefeuilles[idPortefeuille].nb++;
                  });
                }
                if (portefeuille.getListeLotMulticamp().length) {
                  for (var l = 0; l < portefeuille.getListeLotMulticamp().length; l++) {
                    var currentLotMulticamp = portefeuille.getListeLotMulticamp()[l];
                    var objetCollaborateur = AnnuaireService.genereCollaborateur(currentLotMulticamp, portefeuille, ctrl.filtreCollabore, ctrl.filtreIcs, ctrl.hotlineICS.actif);

                    ctrl.filtrePortefeuilles[idPortefeuille].nb++;
                    arrayCollaborateur.push(objetCollaborateur.collaborateur);
                  }
                }
              });
            }

            // Si il y a des groupes
            if (objectCollaborateur.listeGroupes.length) {
              angular.forEach(objectCollaborateur.listeGroupes, function (groupe) {
                if(!groupe.isOld() || !ctrl.createEvent){
                  var objetCollaborateur = AnnuaireService.genereCollaborateur(groupe, null, ctrl.filtreCollabore, ctrl.filtreIcs, ctrl.hotlineICS.actif);

                  arrayCollaborateur.push(objetCollaborateur.collaborateur);
                  ctrl.filtreCollabore = angular.merge(ctrl.filtreCollabore, objetCollaborateur.filtreCollabore);
                }
              });
            }
          }

          if (ctrl.filtreCollabore.groupe.nb) {
            ctrl.paramsSearch.filtreAllow.collaboreGroupe = true;
          }
          if (ctrl.filtreCollabore.user.nb) {
            ctrl.paramsSearch.filtreAllow.collaboreUser = true;
          }
          if (ctrl.filtreCollabore.immeuble.nb) {
            ctrl.paramsSearch.filtreAllow.collaboreImmeuble = true;
          }

          if (ctrl.filtreIcs.proprietaire.nb) {
            ctrl.paramsSearch.filtreAllow.proprietaire = true;
          }
          if (ctrl.filtreIcs.coproprietaire.nb) {
            ctrl.paramsSearch.filtreAllow.coproprietaire = true;
          }
          if (ctrl.filtreIcs.locataire.nb) {
            ctrl.paramsSearch.filtreAllow.locataire = true;
          }
          if (ctrl.filtreIcs.colocataire.nb) {
            ctrl.paramsSearch.filtreAllow.colocataire = true;
          }
          if (ctrl.filtreIcs.fournisseur.nb) {
            ctrl.paramsSearch.filtreAllow.fournisseur = true;
          }
          if (ctrl.filtreIcs.salarie.nb) {
            ctrl.paramsSearch.filtreAllow.salarie = true;
          }
          if (ctrl.filtreIcs.caution.nb) {
            ctrl.paramsSearch.filtreAllow.caution = true;
          }

          if (ctrl.filtreIcs.assureur.nb) {
            ctrl.paramsSearch.filtreAllow.assureur = true;
          }
          if (ctrl.filtreIcs.payeur.nb) {
            ctrl.paramsSearch.filtreAllow.payeur = true;
          }
          if (ctrl.filtreIcs.employeur.nb) {
            ctrl.paramsSearch.filtreAllow.employeur = true;
          }
          if (ctrl.filtreIcs.ics_.nb) {
            ctrl.paramsSearch.filtreAllow.ics_ = true;
          }
          if (ctrl.filtreIcs.client.nb) {
            ctrl.paramsSearch.filtreAllow.client = true;
          }
          if (ctrl.filtreIcs.proprietairemeuble.nb) {
            ctrl.paramsSearch.filtreAllow.proprietairemeuble = true;
          }
          if (ctrl.filtreIcs.indivision.nb) {
            ctrl.paramsSearch.filtreAllow.indivision = true;
          }

          ctrl.collaborateurs.rows = angular.copy(arrayCollaborateur);

          arrayCollaborateur = null;

          // La recherche est effectué
          ctrl.paramsSearch.search = true;

        }, function () {
          ctrl.collaborateurs.rows = [];
        }).finally(function () {
          ctrl.loadingModal = false;
          ctrl.formSubmitted = true;

        });
      }
      else {
        var typeTags = [
          COLLAB_CONF.TAG_TYPE_ORIGINE,
          COLLAB_CONF.TAG_TYPE_ORIGINE_COLLAB,
          COLLAB_CONF.TAG_TYPE_ORIGINE_ICS,
          COLLAB_CONF.TAG_TYPE_ORIGINE_IMMEUBLE,
          COLLAB_CONF.TAG_TYPE_ORIGINE_GARDIEN
        ];

        TagService.getWithType(typeTags, ctrl.paramsSearch.recherche, 500, true).then(function (tags) {

          // Met à 0 le nombre de résultats des filtre portefeuille
          angular.forEach(ctrl.filtrePortefeuilles, function (portefeuille) {
            portefeuille.nb = 0;
          });

          var arrayTags = [];
          if (tags.length) {
            for (var i = 0; i < tags.length; i++) {
              var newTag = {
                isTag: true,
                tag: tags[i]
              };

              if (tags[i].hasOwnProperty('portefeuille')) {

                if(_.isObject(ctrl.filtrePortefeuilles[tags[i].portefeuille.idPortefeuille]))
                  ctrl.filtrePortefeuilles[tags[i].portefeuille.idPortefeuille].nb++;

                newTag.source = tags[i].portefeuille.nom;
              }
              arrayTags.push(newTag);
            }
          }
          ctrl.collaborateurs.rows = arrayTags;
        }, function () {
          ctrl.collaborateurs.rows = [];
        }).finally(function () {
          ctrl.loadingModal = false;
          ctrl.formSubmitted = true;
        });
      }
    };

    /**
     * Lors du clique sur le bouton de recherche
     * @param formRecherche
     */
    function submitSearch(formRecherche) {
      if (!formRecherche.$valid) {
        return false;
      }
      search();
    }

    function genereLieuxCommunsCapfun(){
      //ctrl.paramsSearch.search = false; // Pour eviter de réafficher les communs si clique sur "Lot" après une recherche
      ctrl.hideChampRecherche = true;

      var arrayCollaborateur = [];
      var LieuCommunCapfunModel = UtilsService.$injector.get('LieuCommunCapfun');

      for(var p = 0; p < ctrl.portefeuilles.length; p++) {
        for(var i = 0; i < COLLAB_CONF.LIEUX_COMMUNS_CAPFUN.length; i++){
          var currentLieuCommunCapfun = new LieuCommunCapfunModel({
            camping: ctrl.portefeuilles[p].getNom(),
            lieu: COLLAB_CONF.LIEUX_COMMUNS_CAPFUN[i]
          });
          var objetCollaborateur = AnnuaireService.genereCollaborateur(currentLieuCommunCapfun, ctrl.portefeuilles[p], ctrl.filtreCollabore, ctrl.filtreIcs, ctrl.hotlineICS.actif);
          arrayCollaborateur.push(objetCollaborateur.collaborateur);
          ctrl.listLieuxCommunsCapfun = angular.copy(arrayCollaborateur);
        }
        ctrl.filtrePortefeuilles[ctrl.portefeuilles[p].idPortefeuille].nb++;
      }
      arrayCollaborateur = null;
    }

    /**
     * Lors de la frappe dans le champ libre de recherche
     * @param event
     * @param formRecherche
     */
    ctrl.onKeyUpChampLibre = function (event, formRecherche) {

      // Si tape sur "Entrée"
      if (event.keyCode === 13) {
        // Stop le formulaire
        event.preventDefault();

        // Si la recherche est à false alors lance la recherche
        if (!ctrl.paramsSearch.search) {
          // Lance la recherche
          submitSearch(formRecherche);
        }
        // Si non il faut récupérer le résultat le plus pertinent voir le seul résultat et l'ajouter
        else {

          // Retire un éventuel @ du string de la recherche
          var currentRecherche = UtilsService.ltrim(ctrl.paramsSearch.recherche, '@');
          var userFind = false;
          // Si il y a des résultats
          if (ctrl.collaborateurs.rows.length) {
            // Pour chaque résultat de la recherche
            for (var i = 0; i < ctrl.collaborateurs.rows.length; i++) {

              // Retire les @ des champs où il faut rechercher
              var nom = UtilsService.ltrim(ctrl.collaborateurs.rows[i].nom, '@');
              var prenom = UtilsService.ltrim(ctrl.collaborateurs.rows[i].prenom, '@');
              var libelle = UtilsService.ltrim(ctrl.collaborateurs.rows[i].libelle, '@');
              if ((ctrl.collaborateurs.rows.length > 1 && (nom === currentRecherche || prenom === currentRecherche || libelle === currentRecherche)) || (ctrl.collaborateurs.rows.length === 1)) {
                userFind = ctrl.collaborateurs.rows[i];
                break;
              }
            }
          }

          // Si un user est trouvé
          if (userFind) {
            //console.log('userFind : ',userFind);
            // Ajoute le destinataire et ferme la modal

            // Si annuaire pour les tags
            if (ctrl.stringTo === 'tag') {
              // Si le bouton lots est affiché
              if (ctrl.displayBtnLots(userFind)) {
                // ouvre les lots
                ctrl.onClickOpenLots(userFind);
              }
              else {
                var origin = angular.copy(userFind);
                if(origin.isImm) origin = userFind.immeuble;
                // Choisi le resulat
                ctrl.chooseAndClose(origin, userFind);
              }
            }
            // Si annuaire pour les contacts
            else {
              ctrl.selectDestinataireAndClose(userFind);
            }
          }
        }
      }
      // Si autres touches
      else {
        // Repasse la recherche en non effectuée
        ctrl.paramsSearch.search = false;
      }
    };

    /********** Colonne résultats de recherche *************/

    var arrayAllowFilter = [
      'nom',
      'prenom',
      'libelle',
      'telephone',
      'source',
      'codemetier',
      'ville',
      'noimme',
      'adresse1',
      'adresse2',
      'codePostal',
      'numlot',
      'categorie',
      'client',
      'camping',
      'begin',
      'end'
    ];


    /*
     function displayColonneLots(){
     var retour = false;
     if(!ctrl.isModal && ctrl.isSearchPerso() && $rootScope.hasPortefeuille && !UtilsService.isICS()){
     retour = true;
     }
     return retour;
     }*/

    function displayColonnePrenom() {
      if (ctrl.isSearchCollab()) {
        return true;
      }
      return false;
    }

    function displayColonneImmeuble() {
      if (ctrl.isSearchPerso() && ctrl.hasPortefeuille) {
        return true;
      }
      return false;
    }

    function displayColonneDiese() {
      if (ctrl.isSearchPerso() && ctrl.showFiltreSource()) {
        return true;
      }
      return false;
    }

    function displayColonneType() {
      if (ctrl.isSearchPerso()) {
        return true;
      }
      return false;
    }

    /**
     * Permet d'afficher la colonne des baux
     * @returns {boolean}
     */
    function displayColonneBaux() {
      var retour = false;
      if (ctrl.isSearchPerso() && ctrl.hasPortefeuille && !UtilsService.isICS()) {
        retour = true;
      }
      return retour;
    }

    function displayChoixOrigine() {
      var retour = false;
      if (ctrl.openFrom === 'dashboard') {
        retour = true;
      }
      return retour;
    }

    /**
     * Permet de savoir si il faut afficher ou cacher le bouton d'appel des lots
     * @param collaborateur
     * @returns {boolean}
     */
    ctrl.displayBtnLots = function (collaborateur) {
      /*
       if(collaborateur.isCollab || collaborateur.isGroupe || (collaborateur.isFournisseur && !collaborateur.isCopro && !collaborateur.isProprietaire) || ctrl.stringTo==='destinataires' || UtilsService.isICS()){
       return false;
       }
       return true;*/
      if (collaborateur.isPerso && (collaborateur.isCopro || collaborateur.isProprietaire || collaborateur.isLocataire || collaborateur.isColocataire) && ctrl.stringTo !== 'destinataires' && !UtilsService.isICS()) {
        return true;
      }
      return false;
    };

    ctrl.displayBtnBaux = function () {
      return true;
    };

    /**
     * Permet de savoir si il faut afficher les boutons pour ajouter le collaborateur
     * @param collaborateur
     * @returns {boolean}
     */
    ctrl.displayBtnAdd = function (collaborateur) {
      if (!ctrl.displayBtnLots(collaborateur)) {
        if (ctrl.stringTo === 'tag') {
          if (collaborateur.isGroupe) {
            return false;
          }
        }
        return true;
      }
      // Si perso sans types
      if (collaborateur.isPerso) {
        if (!collaborateur.perso.getTypes()) {
          return true;
        }
      }
      return false;
    };


    /**
     * Permet de savoir si "LotMulticamp" est coché
     * @returns {boolean}
     */
    function isSearchLotMulticamp() {
      if (ctrl.paramsSearch.type === 'M' && ctrl.lastTypeRecherche === 'M') {
        return true;
      }
      return false;
    }

    /**
     * Permet de savoir si "Perso" est coché
     * @returns {boolean}
     */
    function isSearchPerso() {
      if (ctrl.paramsSearch.type === 'U' && ctrl.lastTypeRecherche === 'U') {
        return true;
      }
      return false;
    }

    /**
     * Permet de savoir si "Immeuble" est coché
     * @returns {boolean}
     */
    function isSearchImmeuble() {
      if (ctrl.paramsSearch.type === 'I' && ctrl.lastTypeRecherche === 'I') {
        return true;
      }
      return false;
    }

    /**
     * Permet de savoir si "Fournisseurs" est coché
     * @returns {boolean}
     */
    function isSearchFournisseur() {
      if (ctrl.paramsSearch.type === 'F' && ctrl.lastTypeRecherche === 'F') {
        return true;
      }
      return false;
    }

    /**
     * Permet de savoir si "Collab" est coché
     * @returns {boolean}
     */
    function isSearchCollab() {
      if (ctrl.paramsSearch.type === 'G' && ctrl.lastTypeRecherche === 'G') {
        return true;
      }
      return false;
    }

    /**
     * Fonction appliqué au filtre "search" tu tableau des collaborateurs
     * @param value
     * @returns {boolean}
     */
    ctrl.filterCollaborateur = function (value) {

      var retour = true;

      // Si le filtre collabore est decoché
      if (!ctrl.filtreCollabore.display) {
        if (value.isCollab) {
          retour = false;
        }
      }
      else {
        // Si le filtre user collabore est décoché
        if (!ctrl.filtreCollabore.user.display) {
          if (value.isUser) {
            retour = false;
          }
        }

        // Si le filtre groupe collabore est décoché
        if (!ctrl.filtreCollabore.groupe.display && retour) {
          if (value.isGroupe) {
            if (!value.groupe.isImmeuble()) {
              retour = false;
            }
          }
        }

        // Si le filtre immeuble collabore est décoché
        if (!ctrl.filtreCollabore.immeuble.display && retour) {
          if (value.isGroupe) {
            if (value.groupe.isImmeuble()) {
              retour = false;
            }
          }
        }
      }


      // Si le filtre ics est decoché
      if (!ctrl.filtreIcs.display) {
        if (value.isPerso) {
          retour = false;
        }
      }
      else {

        if (value.isPerso) {

          if (value.perso.getTypes()) {

            var p = false,
              c = false,
              l = false,
              f = false,
              s = false,
              q = false,
              a = false,
              pa = false,
              e = false,
              ics = false,
              cl = false,
              pm = false,
              i = false;

            // Si le filtre proprietaire ics est coché
            if (ctrl.filtreIcs.proprietaire.display) {
              if (value.isProprietaire) {
                p = true;
              }
            }

            // Si le filtre coproprietaire ics est coché
            if (ctrl.filtreIcs.coproprietaire.display) {
              if (value.isCopro) {
                c = true;
              }
            }

            // Si le filtre locataire ics est coché
            if (ctrl.filtreIcs.locataire.display) {
              if (value.isLocataire) {
                l = true;
              }
            }

            // Si le filtre colocataire ics est coché
            if (ctrl.filtreIcs.colocataire.display) {
              if (value.isColocataire) {
                l = true;
              }
            }

            // Si le filtre fournisseur ics est coché
            if (ctrl.filtreIcs.fournisseur.display) {
              if (value.isFournisseur) {
                f = true;
              }
            }

            // Si le filtre salarie ics est coché
            if (ctrl.filtreIcs.salarie.display) {
              if (value.isSala) {
                s = true;
              }
            }

            // Si le filtre cautionnaire ics est coché
            if (ctrl.filtreIcs.caution.display) {
              if (value.isCaution) {
                q = true;
              }
            }

            // Si le filtre assureur ics est coché
            if (ctrl.filtreIcs.assureur.display) {
              if (value.isAssureur) {
                a = true;
              }
            }
            // Si le filtre payeur ics est coché
            if (ctrl.filtreIcs.payeur.display) {
              if (value.isPayeur) {
                pa = true;
              }
            }
            // Si le filtre employeur ics est coché
            if (ctrl.filtreIcs.employeur.display) {
              if (value.isEmployeur) {
                e = true;
              }
            }
            // Si le filtre ics ics est coché
            if (ctrl.filtreIcs.ics_.display) {
              if (value.isICS) {
                ics = true;
              }
            }
            // Si le filtre client ics est coché
            if (ctrl.filtreIcs.client.display) {
              if (value.isClient) {
                cl = true;
              }
            }
            // Si le filtre proprietairemeuble ics est coché
            if (ctrl.filtreIcs.proprietairemeuble.display) {
              if (value.isProprietaireMeuble) {
                pm = true;
              }
            }
            // Si le filtre indivision ics est coché
            if (ctrl.filtreIcs.indivision.display) {
              if (value.isIndivision) {
                i = true;
              }
            }

            if (p || c || l || f || s || q || a || pa || e || ics || cl || pm || i) {
              retour = true;
            }
            else {
              retour = false;
            }
          }
        }
      }

      if (retour) {
        // Parcour les filtre portefeuille
        angular.forEach(ctrl.filtrePortefeuilles, function (filtrePortefeuille) {
          // Si le filtre du portefeuille est decoché
          if (!filtrePortefeuille.display) {
            if (value.source === filtrePortefeuille.nom) {
              retour = false;
            }
          }
        });
      }

      if (retour && ctrl.filtre.searchFilter !== '') {
        retour = false;
        // Parcour le tableau des attribut à tester pour le filtre
        for (var j = 0; j < arrayAllowFilter.length; j++) {
          var reg = new RegExp(ctrl.filtre.searchFilter, 'ig');
          // Si le text tapé dans le champ de recherche est trouvé dans la valeur de l'attribut
          if (reg.test(value[arrayAllowFilter[j]])) {
            retour = true;
            break;
          }
        }
      }
      return retour;
    };

    /**
     * Lors du changement d'un filtre portefeuille
     */
    ctrl.onChangeFiltrePortefeuille = function () {


    };

    /**
     * Affiche/Cache la ligne filtre source
     * @returns {boolean|Number|number}
     */
    ctrl.showFiltreSource = function () {
      return (!UtilsService.isEmpty(ctrl.paramsSearch.filtreAllow) && ctrl.hasPortefeuille && ctrl.portefeuilles.length > 1 && !ctrl.loadingModal && ctrl.collaborateurs.rows.length);
    };

    /**
     * Affiche/Cache la ligne filtre collabore
     * @returns {*|boolean|Number|number}
     */
    ctrl.showFiltreCollabore = function () {
      return (ctrl.isSearchCollab() && ctrl.paramsSearch.filtreAllow.collabore && ctrl.filtreCollabore.display && !ctrl.loadingModal && ctrl.collaborateurs.rows.length && ctrl.filtreCollabore.nb);
    };

    /**
     * Affiche/Cache la ligne filtre ICS
     * @returns {*|boolean|Number|number}
     */
    ctrl.showFiltrePerso = function () {
      return (ctrl.isSearchPerso() && ctrl.paramsSearch.filtreAllow.ics && ctrl.filtreIcs.display && !ctrl.loadingModal && ctrl.collaborateurs.rows.length && ctrl.filtreIcs.nb);
    };


    /**
     * Permet d'afficher le template ICS
     * @returns {boolean}
     */
    function showTemplateICS() {
      return (ctrl.isPortefeuilleICS);
    }

    /**
     * Permet d'afficher le template PErso
     * @returns {boolean}
     */
    function showTemplatePerso() {
      return ((isSearchPerso() || isSearchFournisseur()) && ctrl.hasPortefeuille && !ctrl.isPortefeuilleICS && !ctrl.searchContrats && !ctrl.searchImmeuble && !ctrl.searchOrigine);
    }

    /**
     * Permet d'afficher le template Collab
     * @returns {boolean}
     */
    function showTemplateCollab() {
      return ((isSearchCollab() || !ctrl.hasPortefeuille) && !ctrl.isPortefeuilleICS && !ctrl.searchContrats && !ctrl.searchImmeuble && !ctrl.searchOrigine);
    }

    /**
     * Permet d'afficher le template Contrat
     * @returns {boolean|*}
     */
    function showTemplateContrats() {
      return (!ctrl.isPortefeuilleICS && ctrl.searchContrats);
    }

    /**
     * Permet d'afficher le template immeuble
     * @returns {boolean}
     */
    function showTemplateImmeuble() {
      return (isSearchImmeuble() && !ctrl.isPortefeuilleICS && ctrl.searchImmeuble);
    }

    /**
     * Permet d'afficher le template lot multicamp
     * @returns {boolean}
     */
    function showTemplateLotMulticamp() {
      return (isSearchLotMulticamp() && !ctrl.isPortefeuilleICS && ctrl.searchLotMulticamp);
    }

    /**
     * Permet d'afficher le template Origine
     * @returns {boolean}
     */
    function showTemplateOrigine() {
      return (!ctrl.isPortefeuilleICS && ctrl.searchOrigine);
    }

    /**
     * Lors de la frappe dans le filtre libre
     * @param event
     * @param formRecherche
     */
    ctrl.onKeyUpFiltreLibre = function (event) {

      // Si tape sur "Entrée"
      if (event.keyCode === 13) {
        // Stop le formulaire
        event.preventDefault();
        ctrl.filtre.searchFilter = angular.copy(ctrl.filtre.tmpSearchFilter);
      }
    };

    /**
     * Lors de la frappe dans le filtre libre
     * @param event
     * @param formRecherche
     */
    ctrl.onKeyUpFiltre = function (event, tmpSearch, nameFiltre) {

      // Si tape sur "Entrée"
      if (event.keyCode === 13) {
        // Stop le formulaire
        event.preventDefault();
        ctrl.filtre[nameFiltre] = angular.copy(tmpSearch);
      }
    };

    ctrl.onClickOpenFiche = function (collaborateur) {
      var allowAddGroupeToDestinataire = true, allowAddUserToDestinataire = true;
      /*
       if(ctrl.stringTo==='destinataires'){
       allowAddGroupeToDestinataire = false;
       allowAddUserToDestinataire = true;
       }*/

      var params = {
        groupe: collaborateur.groupe,
        allowAddGroupeToDestinataire: allowAddGroupeToDestinataire,
        allowAddUserToDestinataire: allowAddUserToDestinataire
      };
      ModalsService.modalFiche(params);
    };

    ctrl.onClickOpenCopros = function (collaborateur) {


      if (collaborateur.isImm) {
        if (!collaborateur.coprosIsOpen) {

          collaborateur.loadingCopros = true;
          collaborateur.coprosIsOpen = true;

          AnnuaireService
            .searchCoproprietaires(collaborateur.noimme, collaborateur.portefeuille.idPortefeuille)
            .then(function (objRetour) {

              var telephones, mails, tag;

              for (var i = 0; i < objRetour.listeCoproprietaires.length; i++) {
                var coproprietaire = objRetour.listeCoproprietaires[i];
                telephones = coproprietaire.getTelephones();
                if (telephones.length) {
                  coproprietaire.telephone = telephones[0];
                  coproprietaire.arrayTelephones = telephones;
                }

                mails = coproprietaire.getMails();
                if (mails.length) {
                  coproprietaire.email = mails[0];
                  coproprietaire.arrayMails = mails;

                  if (UtilsService.isMail(mails[0])) {
                    coproprietaire.isAllowForDestinataire = true;
                  }

                }

                tag = TagService.genereTag(coproprietaire, 'Projet');
                coproprietaire.tagProjet = tag;
              }

              collaborateur.listeCoproprietaires = objRetour.listeCoproprietaires;


              if (objRetour.listeSalaries.length) {

                for (var s = 0; s < objRetour.listeSalaries.length; s++) {
                  var salarie = objRetour.listeSalaries[s];
                  telephones = salarie.getTelephones();
                  if (telephones.length) {
                    salarie.telephone = telephones[0];
                    salarie.arrayTelephones = telephones;
                  }

                  mails = salarie.getMails();
                  if (mails.length) {
                    salarie.email = mails[0];
                    salarie.arrayMails = mails;

                    if (UtilsService.isMail(mails[0])) {
                      salarie.isAllowForDestinataire = true;
                    }

                  }

                  tag = TagService.genereTag(salarie, 'Projet');
                  salarie.tagProjet = tag;
                }

                collaborateur.listeSalaries = objRetour.listeSalaries;
              }
              else {
                var gardien = new Salarie({
                  profess: 'Gardien',
                  niveau: '02',
                  noperso: '000000'
                });
                collaborateur.listeSalaries = [gardien];
              }

              //console.log(collaborateur.listeCoproprietaires);

            })
            .finally(function () {
              collaborateur.loadingCopros = false;
            });
        } else {
          collaborateur.coprosIsOpen = false;
        }
      }
    };

    /**
     * Lors du clique sur le bouton pour ouvrir les lots d'une perso
     * @param collaborateur
     * @returns {IPromise<T>}
     */
    ctrl.onClickOpenLots = function (collaborateur) {

      var defered = $q.defer();
      // Si perso ICS
      if (collaborateur.isPerso) {
        // Si lots pas ouvert
        if (!collaborateur.lotsIsOpen) {

          collaborateur.lotsIsOpen = true;

          // Si pas d'objetTypePerso en mémoire
          if (!collaborateur.objTypePerso) {
            collaborateur.loadingLots = true;
            collaborateur.disabledBtnImmeubles = true;
            AnnuaireService
              .searchImmeubles(collaborateur.noperso, collaborateur.portefeuille.idPortefeuille)
              .then(function (obj) {
                collaborateur.objTypePerso = obj.objTypePerso;
                collaborateur.listImmeuble = obj.immeubles;
                defered.resolve(collaborateur);
              })
              .catch(function (msg) {
                defered.reject(msg);
              })
              .finally(function () {
                collaborateur.loadingLots = false;
                delete collaborateur.disabledBtnImmeubles;
              });
          }
        }
        // Si déjà ouvert
        else {
          collaborateur.lotsIsOpen = false;
          if (collaborateur.hasOwnProperty('objTypePerso') && collaborateur.objTypePerso) {
            if (collaborateur.objTypePerso.hasOwnProperty('Proprietaire') && collaborateur.objTypePerso.Proprietaire) {
              if (collaborateur.objTypePerso.Proprietaire.hasOwnProperty('listeMandats') && angular.isArray(collaborateur.objTypePerso.Proprietaire.listeMandats) && collaborateur.objTypePerso.Proprietaire.listeMandats.length) {
                for (var m = 0; m < collaborateur.objTypePerso.Proprietaire.listeMandats.length; m++) {
                  destroyListenerForMandat(collaborateur.objTypePerso.Proprietaire.listeMandats[m].num);
                }
              }
            }
          }
          defered.resolve(collaborateur);
        }
      }
      return defered.promise;
    };

    /**
     * Appelé lorsque le bouton pour ouvrir les lots d'une perso est affiché
     * @param collaborateur
     */
    function onInitBtnLot(collaborateur) {
      // Si paramètres de recherche venant de l'exterieur alors rempli le champ libre de recherche avec la perso trouvé
      if (angular.isObject(ctrl.paramsExterieur) && ctrl.paramsExterieur.immeuble && ctrl.paramsExterieur.noperso && collaborateur.noperso === ctrl.paramsExterieur.noperso) {
        ctrl.onClickOpenLots(collaborateur).then(function (retourCollaborateur) {
          if (retourCollaborateur.hasOwnProperty('objTypePerso') && angular.isObject(retourCollaborateur.objTypePerso)) {
            if (retourCollaborateur.objTypePerso.hasOwnProperty('Proprietaire') && angular.isObject(retourCollaborateur.objTypePerso.Proprietaire)) {
              if (retourCollaborateur.objTypePerso.Proprietaire.hasOwnProperty('listeMandats') && angular.isArray(retourCollaborateur.objTypePerso.Proprietaire.listeMandats) && retourCollaborateur.objTypePerso.Proprietaire.listeMandats.length) {
                for (var m = 0; m < retourCollaborateur.objTypePerso.Proprietaire.listeMandats.length; m++) {
                  var numMandat = retourCollaborateur.objTypePerso.Proprietaire.listeMandats[m].num;
                  initPoolListenerForMandat(numMandat);
                  poolListenersAccordion[numMandat].onready = $scope.$on('accordionLots' + numMandat + ':onReady', function (event) {
                    var iPane = 'paneMandat' + numMandat + 'Imm' + ctrl.paramsExterieur.immeuble;
                    event.targetScope.internalControl.expand(iPane);    // ouvre l'accordion
                    UtilsService.scrollToId(iPane);     // Scroll vers l'accordion
                    delete ctrl.paramsExterieur;
                    //UtilsService.resetStateParamsAction();
                  });
                }
              }
            }
          }
        });
      }
    }

    /**
     * Lorsque le composant de la liste d'immeuble fais remonter des infos
     * @param objUpdate
     * @param collaborateur
     */
    function onUpdateFromComponentListImmeuble(objUpdate, collaborateur) {

      // Met à jours les immeubles
      if (objUpdate.hasOwnProperty('refreshList')) {
        collaborateur.objTypePerso = objUpdate.refreshList.objTypePerso;
        collaborateur.listImmeuble = objUpdate.refreshList.immeubles;
      }
      // Desactive le bouton choisir pendant le chargement des immeubles
      if (objUpdate.hasOwnProperty('disabledBtnChoisir')) {
        collaborateur.disabledBtnChoisir = objUpdate.disabledBtnChoisir;
      }
    }

    ctrl.onClickOpenBaux = function (collaborateur, lot) {

      lot.baux = false;
      if (!lot.bauxIsOpen) {

        lot.loadingBaux = true;
        lot.bauxIsOpen = true;

        AnnuaireService.searchBaux(lot.getNumMandat(), lot.getNumImmeuble(), lot.getNumLot(), collaborateur.portefeuille.idPortefeuille).then(function (baux) {
          lot.baux = baux;
        }).finally(function () {
          lot.loadingBaux = false;
        });
      } else {
        lot.bauxIsOpen = false;
      }
    };

    /********** Gestion des destinataires *************/

    /**
     * Fonction qui permet d'ajouter un user ou un groupe dans un tableau de destinataires temporaire qui permet d'enrichir le formulaire d'un nouveau contenu
     */
    ctrl.addToNewContenu = function (collaborateur) {

      if (collaborateur.isPerso) {
        // TODO : Utiliser la gestion des destinataires habituel pour pouvoir modifier les email en cas de mail faux
        var obj = UtilsService.checkValidDestinataire(collaborateur.libelle);
        if (obj.success) {
          $rootScope.current.tmpNewEvenement.destinataires.push(UtilsService.checkValidDestinataire(collaborateur.libelle));
        }

      } else if (collaborateur.isCollab) {
        collaborateur = collaborateur.groupe;
        $rootScope.current.tmpNewEvenement.destinataires.push(collaborateur);
      }

    };

    /**
     * Compte le nombre de contact actif dans les destinataires
     * @returns {number}
     */
    ctrl.nbContactActif = function () {
      var nb = 0;

      if (ctrl.limitDestinataireByFournisseur) {
        angular.forEach(ctrl.destinatairesByFournisseurSelected, function (fournisseur) {
          for (var i = 0; i < fournisseur.destinataires; i++) {
            var alreadyAdd = false;
            for (var j = 0; j < fournisseur.destinataires[i].contacts.length; j++) {
              var contact = fournisseur.destinataires[i].contacts[j];
              if (contact.actif) {
                alreadyAdd = true;
                nb++;
              }
            }
            // Si on utilise l'annuaire pour ajouter des tags et que le contact na pas encore été comptabilisé
            if (ctrl.stringTo === 'tag' && !alreadyAdd) {
              nb++;
            }
          }
        });
      }
      else {
        for (var i = 0; i < ctrl.destinatairesSelected.length; i++) {
          var alreadyAdd = false;
          for (var j = 0; j < ctrl.destinatairesSelected[i].contacts.length; j++) {
            if (ctrl.destinatairesSelected[i].contacts[j].actif) {
              alreadyAdd = true;
              nb++;
            }
          }

          // Si on utilise l'annuaire pour ajouter des tags et que le contact na pas encore été comptabilisé
          if (ctrl.stringTo === 'tag' && !alreadyAdd) {
            nb++;
          }
        }
      }
      /*
       angular.forEach(ctrl.destinatairesSelected,function(dest){
       var alreadyAdd = false;
       angular.forEach(dest.contacts,function(contact){
       if(contact.actif){
       alreadyAdd = true;
       nb++;
       }
       });
       // Si on utilise l'annuaire pour ajouter des tags et que le contact na pas encore été comptabilisé
       if(ctrl.stringTo === 'tag' && !alreadyAdd){
       nb++;
       }
       });*/


      return nb;
    };

    /**
     * Lors du cochage/décochage d'un email dans un destinataire
     * @param dest
     */
    ctrl.onChangeContactInDestinataire = function (perso, objMail) {


      if (ctrl.limitDestinataire && objMail.actif) {
        var nbActif = ctrl.nbContactActif();
        if (ctrl.limitDestinataire < nbActif) {
          objMail.actif = false;
        }
      }
      else {
        // Si coche
        if (objMail.actif) {

          // Parcour les Perso selected
          for (var i = 0; i < ctrl.destinatairesSelected.length; i++) {
            var dest = ctrl.destinatairesSelected[i];
            // Si contact dispo dans cette perso
            if (dest.contacts.length) {
              // Parcour les contacts dispo dans cette perso
              for (var c = 0; c < dest.contacts.length; c++) {
                // Si le contact est actif et qu'il est différent du contact actuel
                if (dest.contacts[c].actif && objMail.libelle !== dest.contacts[c].libelle && dest.noperso === perso.noperso && ctrl.limitDestinataireByFournisseur) {
                  dest.contacts[c].actif = false; // Décoche le contact
                }
                else if (objMail.libelle === dest.contacts[c].libelle) {
                  dest.contacts[c].actif = false; // Décoche le contact
                  objMail.actif = true;
                }
              }
            }
          }
        }
      }
    };

    /**
     * Ajoute un destinataire et ferme la modal
     * @param perso
     */
    ctrl.selectDestinataireAndClose = function (perso) {
      // ajoute le destinataire
      ctrl.addDestinataire({'perso': perso}, true).then(function () {
        // Sauvegarde les destinataires et ferme la modal
        ctrl.saveDestinataires();
      });
    };

    /**
     * Permet de transmettre la selection à l'hote et ferme le modal
     * @param collaborateur
     * @param item
     * @param immeuble
     */
    ctrl.selectAndClose = function (collaborateur, item, immeuble, inverse, bail) {

      var retour = {
        'objAnnuaire': {
          'collaborateur': collaborateur,
          'item': item,
          'immeuble': immeuble,
          'inverse': inverse,
          'bail': bail
        }
      };

      ctrl.onSubmit(retour);
    };

    ctrl.chooseTagAndClose = function (origine) {
      ctrl.onSubmit({objAnnuaire: origine});
    };

    /**
     * Permet de transmettre la selection à l'hote et ferme le modal
     * @param action :
      * addMandat = Origine Proprietaire
     * addImmeuble = Origine Immeuble
     * addCoproprietaire = Origine Coproprietaire
     * @param collaborateur
     * @param item
     * @param immeuble
     * @param inverse
     * @param bail
     */
    ctrl.chooseAndClose = function (origine, collaborateur, item, immeuble, lot, bail, mandat, typePerso) {

      var obj = {
          origine: origine,
          collaborateur: collaborateur,
          current: item,
          immeuble: immeuble,
          lot: lot,
          bail: bail,
          mandat: mandat,
          typePerso: typePerso,
          portefeuille: ctrl.currentEvent.portefeuille
      };
      //console.log(obj);
      //console.log(UtilsService.genereObjetAnnuaire(obj));
      //return;
      ctrl.onSubmit(UtilsService.genereObjetAnnuaire(obj));


      //ctrl.onSubmit(UtilsService.genereObjetFromAnnuaire(origine, collaborateur, item, immeuble, lot, bail, ctrl.currentEvent.portefeuille));
      /*
       console.log(origine);
       console.log(collaborateur);
       console.log(item);
       */
      /*
      var selected,objOrigine = false;


      if(origine.hasOwnProperty('isCollaborateur')) {
          if(origine.isCollaborateur) {
              collaborateur = angular.copy(origine);
              if(origine.isPerso){
                  objOrigine = angular.copy(origine.perso);
              }
              else if(origine.isCollab){
                  objOrigine = angular.copy(origine.groupe);
              }
          }
      }

      // Si pas d'obj origine ET que l'origine est un Model
      if(!objOrigine && origine.isModel) {
          objOrigine = angular.copy(origine);
          selected = angular.copy(origine);
      }
      // si l'origine est un String
      else if(angular.isString(origine)) {
          objOrigine = angular.copy(origine);
      }

      var retour = {
          objAnnuaire: {
              origine: origine,
              projet: false,
              mandat: false,
              immeuble: false,
              portefeuille: false,
              lot: false,
              coproprietaire: false,
              proprietaire: false,
              locataire: false,
              fournisseur: false,
              salarie: false,
              bail: bail,
              contrat: false,
              type: false
          }
      };

      if(item){
          if(angular.isObject(item)) {
              if(item.isMandat) {
                  selected = item.num;
              }
              else if(item.isModel) {
                  selected = item;
              }
          }
      }

      if(collaborateur) {
          if(collaborateur.hasOwnProperty('portefeuille')){
              retour.objAnnuaire.portefeuille = collaborateur.portefeuille;
          }
          if(collaborateur.hasOwnProperty('immeuble')){
              delete collaborateur.immeuble.collapsed;
              delete collaborateur.immeuble.metiers;
              retour.objAnnuaire.immeuble = collaborateur.immeuble;
          }

          if(collaborateur.isPerso){
              retour.objAnnuaire.type = 'ICS';
          }
          else if(collaborateur.isCollab){
              retour.objAnnuaire.type = 'Collab';
          }
          else if(collaborateur.isImm){
              retour.objAnnuaire.type = 'Immeuble';
          }
      }
      else {
          if(ctrl.currentEvent.portefeuille) {
              retour.objAnnuaire.portefeuille = ctrl.currentEvent.portefeuille;
          }
      }


      if(lot) {
          retour.objAnnuaire.lot = lot;
      }
      if(immeuble) {
          retour.objAnnuaire.immeuble = immeuble;
      }

      if(angular.isString(objOrigine)) {

      }
      else if(angular.isObject(objOrigine)) {
          // Si mandat
          if(angular.isString(selected)) {
              retour.objAnnuaire.projet = 'mandat';
              retour.objAnnuaire.mandat = selected;
              retour.objAnnuaire.proprietaire = objOrigine;
          }
          else if(angular.isObject(selected)) {

              if(selected.isLot){
                  retour.objAnnuaire.projet = 'lot';
                  retour.objAnnuaire.lot = selected;
                  if(selected.getType()==='Locataire' || selected.getType()==='Colocataire'){
                      retour.objAnnuaire.mandat = selected.getNumMandat();
                      retour.objAnnuaire.proprietaire = selected.getProprietaire();
                      retour.objAnnuaire.locataire = objOrigine;
                  }
                  else if(selected.getType()==='Proprietaire'){
                      retour.objAnnuaire.mandat = selected.getNumMandat();
                      retour.objAnnuaire.proprietaire = objOrigine;
                      if(angular.isObject(bail)) {
                          if(bail.isBail) {
                              retour.objAnnuaire.locataire = bail.getPersonnalite();
                          }
                      }
                  }
              }
              else if(selected.isImmeuble){
                  retour.objAnnuaire.projet = 'immeuble';
                  retour.objAnnuaire.immeuble = selected;
                  if(angular.isObject(objOrigine)){
                      if(objOrigine.model !== 'Immeuble') {
                          if(objOrigine.isCoproprietaire()){
                              retour.objAnnuaire.coproprietaire = objOrigine;
                          }
                      }
                  }
              }
              else if(selected.isPersonnalite){
                  if(selected.isProprietaire()){
                      retour.objAnnuaire.projet = 'proprietaire';
                      retour.objAnnuaire.proprietaire = selected;
                  }
                  else if(selected.isCoproprietaire()){
                      retour.objAnnuaire.projet = 'immeuble';
                      retour.objAnnuaire.coproprietaire = selected;
                  }
                  else if(selected.isLocataire() || selected.isColocataire()){
                      retour.objAnnuaire.projet = 'locataire';
                      retour.objAnnuaire.locataire = selected;
                  }
                  else if(selected.isFournisseur()){
                      delete objOrigine.metiers;
                      retour.objAnnuaire.projet = 'fournisseur';
                      retour.objAnnuaire.fournisseur = selected;
                  }
              }
              else if(selected.isSalarie){
                  retour.objAnnuaire.projet = 'immeuble';
                  retour.objAnnuaire.salarie = selected;
              }
              else if(selected.isContrat){
                  retour.objAnnuaire.projet = 'contrat';
                  retour.objAnnuaire.contrat = selected;
              }
          }
          else{
              if(collaborateur && collaborateur.hasOwnProperty('tagProjet')){
                  retour.objAnnuaire.projet = collaborateur.tagProjet;
              }
          }
      }

      if(angular.isObject(origine)){
          if(!retour.objAnnuaire.proprietaire && !retour.objAnnuaire.coproprietaire && !retour.objAnnuaire.locataire) {

              // Si bail indefini ou à false
              if(angular.isUndefined(retour.objAnnuaire.bail) || !retour.objAnnuaire.bail) {

                  // Si l'origine est à la fois propriétaire et coproproprietaire
                  if(origine.isProprietaire && origine.isCopro){
                      // Alors c'est un proprietaire
                      if(origine.isProprietaire && !retour.objAnnuaire.proprietaire) {
                          retour.objAnnuaire.proprietaire = objOrigine;
                      }
                  }
                  // Si l'origine est un coproproprietaire
                  else if(origine.isCopro && !retour.objAnnuaire.coproprietaire) {
                      retour.objAnnuaire.coproprietaire = objOrigine;
                  }
                  // Si l'origine est un proprietaire
                  else if(origine.isProprietaire && !retour.objAnnuaire.proprietaire) {
                      retour.objAnnuaire.proprietaire = objOrigine;
                  }
                  // Si l'origine est un fournisseur
                  else if(origine.isFournisseur && !retour.objAnnuaire.fournisseur) {
                      retour.objAnnuaire.fournisseur = objOrigine;
                  }
              }
              // Si bail définit alors c'est un propriétaire
              else {
                  if(origine.isProprietaire && !retour.objAnnuaire.proprietaire) {
                      retour.objAnnuaire.proprietaire = objOrigine;
                  }
              }

               // if(origine.isProprietaire && !retour.objAnnuaire.proprietaire) {
               // retour.objAnnuaire.proprietaire = objOrigine;
               // }
               // if(origine.isCopro && !retour.objAnnuaire.coproprietaire) {
               // retour.objAnnuaire.coproprietaire = objOrigine;
               // }
               // if(origine.isLocataire && !retour.objAnnuaire.locataire) {
               // retour.objAnnuaire.locataire = objOrigine;
               // }
          }
      }

      //console.log(retour);
      ctrl.onSubmit(retour);
      */
    };

    /**
     * Ajoute/Retire un destinataire
     * @param perso
     */
    ctrl.toggleSelectDestinataire = function (perso) {

      if (perso.selected) {
        ctrl.removeDestinataire(perso);
      } else {
        ctrl.addDestinataire({'perso': perso});
      }
    };

    /**
     * Ajoute un destinataire
     * @param perso
     */
    ctrl.addDestinataire = function (obj, andClose) {

      var deferred = $q.defer();

      var collaborateur = obj.perso,
        reste;

      ctrl.nbContactSelected = ctrl.nbContactActif();

      if (ctrl.limitDestinataire) {
        if (ctrl.nbContactSelected === parseInt(ctrl.limitDestinataire)) {
          return;
        } else {
          // Calcule le reste des contact possible à ajouter
          // Limit - le nombre actuel deja présent
          reste = ctrl.limitDestinataire - ctrl.nbContactSelected;
        }
      }

      var exist = false;
      if (ctrl.destinatairesSelected.length) {
        for (var i = 0; i < ctrl.destinatairesSelected.length; i++) {
          var persoInSelected = ctrl.destinatairesSelected[i];
          if ((collaborateur.isPerso || collaborateur.isPersonnalite) && persoInSelected.isPerso) {
            if (collaborateur.noperso === persoInSelected.noperso) {
              exist = true;

            }
          }

          if (collaborateur.isCollab && persoInSelected.isCollab) {
            if (collaborateur.idGroupe === persoInSelected.idGroupe) {
              exist = true;
            }
          }
        }
      }


      if (!exist) {
        var allowAddContact = true;
        // Si perso ICS
        if ((collaborateur.isPerso || collaborateur.isPersonnalite) && !ctrl.allowNoMail) {
          var mails;
          if (collaborateur.isPersonnalite) {
            mails = collaborateur.getMails();
          }
          else {
            mails = collaborateur.perso.getMails();
          }

          // Si pas de mail et annuaire ne sert pas à ajouter des tags
          if (mails.length === 0 && ctrl.stringTo !== 'tag') {
            allowAddContact = false;


            var text = '';
            // Si fournisseur selectionné
            if (ctrl.paramsSearch.type === 'F') {

              var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/annuaire/modal-email.html',
                controller: 'ModalEmailCtrl',
                controllerAs: 'modalemailctrl',
                size: 'md',
                windowClass: 'modal-collabore',
                backdrop: 'static',
                resolve: {
                  titre: function () {
                    return 'Veuillez renseigner un email';
                  },
                  collaborateur: function () {
                    return collaborateur;
                  }
                }
              });

              modalInstance.result.then(function (obj) {
                if (obj.email) {
                  if (collaborateur.hasOwnProperty('perso')) {
                    collaborateur.perso.setMails([obj.email]);
                  }
                  else {
                    collaborateur.setMails([obj.email]);
                  }

                  addContact(collaborateur, reste, andClose);
                  deferred.resolve();
                }

              }, function () {
                deferred.reject();
              });
            }
            else {
              sweet.show({
                title: 'Veuillez renseigner un email',
                text: text,
                type: 'input',
                showCancelButton: true,
                closeOnConfirm: false,
                html: true,
                animation: 'slide-from-top',
                inputPlaceholder: 'exemple@exemple.com'
              }, function (inputValue) {
                var error = false;
                if (inputValue === false) {
                  error = true;
                }
                if (inputValue === '') {
                  sweet.showInputError('Le champ est vide !');
                  error = true;
                }

                if (!UtilsService.isMail(inputValue)) {
                  sweet.showInputError('L\'email est incorrect !');
                  error = true;
                }

                if (error) {
                  return false;
                }

                collaborateur.perso.setMails([inputValue]);
                addContact(collaborateur, reste, andClose);
                sweet.close();
                $scope.$digest();
                deferred.resolve();

              });
            }
          }
        }

        if (allowAddContact) {
          addContact(collaborateur, reste, andClose);
          deferred.resolve();
        }

      }
      return deferred.promise;
    };

    ctrl.test = function () {
      console.log('yo');
    };

    function addContact(collaborateur, reste, andClose) {
      collaborateur.selected = true;

      var contactActifs = [];
      for (var d = 0; d < ctrl.destinatairesSelected.length; d++) {

        var destSelected = ctrl.destinatairesSelected[d];
        // Si contact dispo dans cette perso
        if (destSelected.contacts.length) {
          // Parcour les contacts dispo dans cette perso
          for (var c = 0; c < destSelected.contacts.length; c++) {
            // Si le contact est actif et qu'il est différent du contact actuel
            if (destSelected.contacts[c].actif) {

              contactActifs.push(destSelected.contacts[c].libelle);
            }
          }
        }
      }


      var persoSelect = {
        'erreur': false,
        'selected': true,
        'obj': collaborateur,
        //'fiches': {},
        //'tagsType': (obj.hasOwnProperty('tagsType')) ? obj.tagsType : [],
        //'tags': (obj.hasOwnProperty('tags')) ? obj.tags : [],
        'contacts': []
      };

      /*
       if(obj.immeuble){
       persoSelect.fiches.immeuble =  obj.immeuble;
       }*/

      // Si perso ICS
      if (collaborateur.isPerso || collaborateur.isPersonnalite) {
        persoSelect.isPerso = true;
        persoSelect.noperso = collaborateur.noperso;
        var mails;
        if (collaborateur.isPersonnalite) {
          mails = collaborateur.getMails();
        }
        else {
          mails = collaborateur.perso.getMails();
        }
        var mailsActif = angular.copy(mails);
        if (mails.length) {

          if (ctrl.limitDestinataire) {
            mailsActif = [];
            for (var i = 0; i < reste; i++) {
              mailsActif.push(mails[i]);
            }
          }
          else if (ctrl.limitDestinataireByFournisseur) {
            mailsActif = [mails[0]];
          }

          for (var index = 0; index < mails.length; index++) {
            var isActif = false;
            // Si le mail actuel est dans le tableau des mails à cocher
            if (UtilsService.contains(mailsActif, mails[index])) {
              isActif = (andClose || index == 0) ? true : false;
            }
            // Si il y a des contact déjà coché, toutes perso confondues
            if (contactActifs.length) {
              // Si le mail actuel est déjà dans ce tableau alors on ne le coche pas pour la perso
              // Car il peux y avoir deux fois le meme mail pour deux perso différente donc si déjà coché par besoin de recocher
              if (UtilsService.contains(contactActifs, mails[index])) {
                isActif = false;
              }
            }
            persoSelect.contacts.push({
              'erreur': false,
              'libelle': mails[index],
              //'actif': (angular.isDefined(mailsActif[index]))
              'actif': isActif
            });
          }
        }
        // Si autorisé à ajouter un contatc sans mail
        else if (ctrl.allowNoMail) {
          var contactTMP = {
            erreur: false,
            libelle: persoSelect.noperso,
            actif: true
          };
          if (collaborateur.isPersonnalite) contactTMP.libelle = collaborateur.getNom();
          else contactTMP.libelle = collaborateur.perso.getNom();
          persoSelect.contacts.push(contactTMP);
        }
      }

      // Si groupe/user Collab
      if (collaborateur.isCollab) {
        persoSelect.isCollab = true;
        persoSelect.idGroupe = collaborateur.idGroupe;

        // Si groupe user
        if (collaborateur.isUser) {
          persoSelect.user = collaborateur.groupe.user;
        }

        var actif = true;
        if (ctrl.limitDestinataire) {
          if (reste < 1) {
            actif = false;
          }
        }
        persoSelect.contacts.push({
          'erreur': false,
          'libelle': collaborateur.groupe.getLibelleDieze(),
          'actif': actif
        });

      }
      /*


       // Si pas de tagtype créé manuelement
       if(!persoSelect.tagsType.length){
       //persoSelect.tagsType.push(ctrl.genereTagsTypeProjet(collaborateur));
       var tagsType = ctrl.genereTagsTypeProjet(collaborateur);
       if(tagsType){
       persoSelect.tagsType.push(tagsType);
       }
       }

       // Si il y a des tagsType ou tags on les ajoute dans un tableau de tags généré qui est dans la perso ou le groupe
       persoSelect = ctrl.genereTagsGenerate(collaborateur,persoSelect);
       */


      ctrl.destinatairesSelected.push(persoSelect);
    }

    /**
     * Permet de générer un tag type Projet
     * @param collaborateur
     * @returns {Array}
     */
    /*
     ctrl.genereTagsTypeProjet = function(collaborateur){

     var tagsType = false, objModel;

     if(collaborateur.isCollab){
     objModel = collaborateur.groupe;
     if(collaborateur.groupe.isImmeuble()){
     var numImm = collaborateur.groupe.getNumImmeuble();
     var nomImm = collaborateur.nom;
     tagsType = [{
     'libelle': '#I'+numImm+nomImm,
     'typeTag':{
     'libelle': 'Projet'
     }
     }];
     }

     }else if(collaborateur.isPerso){
     objModel = collaborateur.perso;
     }

     if(!tagsType){
     // Génère un tag type projet avec l'objet perso ou groupe
     var tagTypeProjet = TagService.genereTag(objModel,'Projet');
     if(tagTypeProjet){
     tagsType = tagTypeProjet;
     }
     }


     return tagsType;
     };*/


    /**
     * Retire un destinataire
     * @param perso
     */
    ctrl.removeDestinataire = function (perso) {
      //console.log(ctrl.destinatairesSelected);
      if (perso.isPerso || perso.isPersonnalite) {
        ctrl.destinatairesSelected = $filter('removeWith')(ctrl.destinatairesSelected, {'noperso': perso.noperso});
      }

      if (perso.isCollab) {
        ctrl.destinatairesSelected = $filter('removeWith')(ctrl.destinatairesSelected, {'idGroupe': perso.idGroupe});
      }

      for (var l = 0; l < ctrl.collaborateurs.rows.length; l++) {
        var ligne = ctrl.collaborateurs.rows[l];

        if (ligne.isPerso && perso.isPerso) {
          if (ligne.noperso === perso.noperso) {
            ligne.selected = false;
          }
        }
        if (ligne.isCollab && perso.isCollab) {
          if (ligne.idGroupe === perso.idGroupe) {
            ligne.selected = false;
          }
        }

        if (ligne.isImm) {
          for (var m = 0; m < ligne.metiers.length; m++) {
            var currentMetier = ligne.metiers[m];
            for (var c = 0; c < currentMetier.contrats.length; c++) {
              var currentFournisseur = currentMetier.contrats[c].getFournisseur();
              if (currentFournisseur.noperso === perso.noperso) {
                currentFournisseur.selected = false;
              }
            }
          }
        }
      }

    };
  }

})();
