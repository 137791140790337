(function () {
  "use strict";

  /**
   * @ngdoc service
   * @name collaboreApp.Immeuble
   * @description
   * # Immeuble
   * Service in the collaboreApp.
   */

  angular.module("collaboreApp").factory("Immeuble", ImmeubleModel);

  /** @ngInject */
  function ImmeubleModel(Main, Digicode) {
    function Immeuble(objetData) {
      // Change l'attribut "noimme" dans le JSON et "numImme" dans le Model Immeuble
      var objectConversion = {
        nomimme: "nom",
        noimme: "numImmeuble",
      };
      Main.call(this, objetData, null, objectConversion);
      //Main.apply(this, arguments);
      //this.hydrate(objetData, null, objectConversion);
    }

    Immeuble.prototype = {
      model: "Immeuble",
      isImmeuble: true,

      getNomimme: function () {
        return this.nomimme;
      },
      setNomimme: function (val) {
        this.nomimme = val;
      },

      getNom: function () {
        var nomImm = this.nomimme;
        if (angular.isUndefined(this.nomimme) || _.trim(this.nomimme) === "") {
          nomImm = this.getAd1();
          if (angular.isUndefined(nomImm) || _.trim(nomImm) === "") {
            nomImm = this.getAd2();
            if (angular.isUndefined(nomImm) || _.trim(nomImm) === "") {
              nomImm = this.getNumImmeuble();
            }
          }
        }
        return nomImm;
      },
      setNom: function (val) {
        this.nomimme = val;
      },

      getNoimme: function () {
        return this.noimme;
      },
      setNoimme: function (val) {
        this.noimme = val;
      },

      getNumImmeuble: function () {
        return this.noimme;
      },
      setNumImmeuble: function (val) {
        this.noimme = val;
      },

      getAd1: function () {
        return this.ad1;
      },
      setAd1: function (val) {
        this.ad1 = val;
      },

      getAd2: function () {
        return this.ad2;
      },
      setAd2: function (val) {
        this.ad2 = val;
      },

      getAd3: function () {
        return this.ad3;
      },
      setAd3: function (val) {
        this.ad3 = val;
      },

      getAd4: function () {
        return this.ad4;
      },
      setAd4: function (val) {
        this.ad4 = val;
      },

      getAdresse: function (lengthTruncate) {
        var retour = "";
        if (this.getAd1() !== "") {
          retour += this.getAd1() + " - ";
        }
        if (this.getAd2() !== "") {
          retour += this.getAd2() + " - ";
        }
        if (this.getAd3() !== "") {
          retour += this.getAd3() + " - ";
        }
        if (this.getAd4() !== "") {
          retour += this.getAd4() + " - ";
        }
        retour = retour.slice(0, -3);
        if (!_.isNil(lengthTruncate) && retour.length > lengthTruncate) {
          retour = _.truncate(retour, {
            length: lengthTruncate,
            separator: "...",
          });
        }
        return retour;
      },

      getDigicodes: function () {
        return this.digicodes;
      },
      setDigicodes: function (arrayDigicodes) {
        var arrayListeDigicodes = [];
        if (angular.isArray(arrayDigicodes)) {
          for (var i = 0; i < arrayDigicodes.length; i++) {
            if (!arrayDigicodes[i].isModel) {
              arrayDigicodes[i] = new Digicode(arrayDigicodes[i]);
            }
            arrayListeDigicodes.push(arrayDigicodes[i]);
          }
          arrayListeDigicodes.sort(function (a, b) {
            return a.getNumBatiment() - b.getNumBatiment();
          });
        }
        this.digicodes = arrayListeDigicodes;
      },

      //Nom du Gestionnaire
      getResponsable: function () {
        return this.responsable;
      },
      setResponsable: function (val) {
        this.responsable = val;
      },

      //Nom du comptable
      getComptable: function () {
        return this.comptable;
      },
      setComptable: function (val) {
        this.comptable = val;
      },

      //Arrété comptable
      getFinExercice: function () {
        return this.finExercice;
      },
      setFinExercice: function (val) {
        this.finExercice = val;
      },

      //Fin du mandat
      getDatfin: function () {
        return this.datfin;
      },
      setDatfin: function (val) {
        this.datfin = val;
      },

      //Date assemblée générale
      getRea_ag_fixee: function () {
        return this.Rea_ag_fixee;
      },
      setRea_ag_fixee: function (val) {
        this.Rea_ag_fixee = val;
      },

      //Visites prevues
      getNbvip: function () {
        return this.nbvip;
      },
      setNbvip: function (val) {
        this.nbvip = val;
      },

      //Visites realisees
      getNbvir: function () {
        return this.nbvir;
      },
      setNbvir: function (val) {
        this.nbvir = val;
      },

      //Nombre lots
      getNbrlotp: function () {
        return this.nbrlotp;
      },
      setNbrlotp: function (val) {
        this.nbrlotp = val;
      },

      //Contrats
      getContrats: function () {
        return this.contrats;
      },
      setContrats: function (val) {
        var list = [];
        if (_.isArray(val)) {
          var Contrat = Main.$injector.get("Contrat");
          for (var i = 0; i < val.length; i++) {
            var currentContrat = val[i];
            if (_.isObject(val[i]) && !val[i].isModel) {
              currentContrat = new Contrat(val[i]);
            }
            list.push(currentContrat);
          }
          Contrat = null;
        }
        this.contrats = list;
      },
      addContrat: function (contrat) {
        if (
          !_.isObject(contrat) ||
          _.findIndex(this.contrats, { refCon: contrat.refCon }) !== -1
        )
          return;

        var currentContrat = angular.copy(contrat);
        delete currentContrat.immeuble;

        if (!currentContrat.isModel) {
          var Contrat = Main.$injector.get("Contrat");
          currentContrat = new Contrat(currentContrat);
        }
        if(!_.isArray(this.contrats)) this.contrats = [];
        this.contrats.push(currentContrat);
      }
    };

    angular.extend(Immeuble.prototype, Main.prototype);

    return Immeuble;
  }
})();
