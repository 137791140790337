(function () {
  "use strict";
  angular.module("collaboreApp").factory("Contrat", ContratModel);
  /** @ngInject */
  function ContratModel(
    Main,
    Fournisseur,
    Immeuble,
    Metierfournisseur,
    UtilsService
  ) {
    function Contrat(objetData) {
      if (objetData) {
        this.setData(objetData);
        this.isContrat = true;
      }
    }

    Contrat.prototype = {
      isModel: true,

      setData: function (objetData) {
        angular.extend(this, objetData);
        if (angular.isObject(objetData)) {
          if (objetData.hasOwnProperty("persofournisseur")) {
            this.setFournisseur(objetData.persofournisseur);
          } else {
            this.setFournisseur(false);
          }

          if (objetData.hasOwnProperty("immeuble")) {
            this.setImmeuble(objetData.immeuble);
          } else {
            this.setImmeuble(false);
          }

          if (objetData.hasOwnProperty("metierfournisseur")) {
            this.setMetierfournisseur(objetData.metierfournisseur);
          } else {
            this.setMetierfournisseur(false);
          }

          if (objetData.hasOwnProperty("contrattabs")) {
            this.setContrattabs(objetData.contrattabs);
          } else {
            this.setContrattabs([]);
          }
        }
      },

      // refCon
      getRefCon: function () {
        return this.refCon;
      },
      setRefCon: function (val) {
        this.refCon = val;
      },

      // refFou
      getRefFou: function () {
        return this.refFou;
      },
      setRefFou: function (val) {
        this.refFou = val;
      },

      // metierFourni
      getMetierFourni: function () {
        return this.metierFourni;
      },
      setMetierFourni: function (val) {
        this.metierFourni = val;
      },

      // numeroFourni
      getNumeroFourni: function () {
        return this.numeroFourni;
      },
      setNumeroFourni: function (val) {
        this.numeroFourni = val;
      },

      // iiii
      getIiii: function () {
        return this.iiii;
      },
      setIiii: function (val) {
        this.iiii = val;
      },

      // objet1
      getObjet1: function () {
        return this.objet1;
      },
      setObjet1: function (val) {
        this.objet1 = val;
      },

      // objet2
      getObjet2: function () {
        return this.objet2;
      },
      setObjet2: function (val) {
        this.objet2 = val;
      },

      // objet3
      getObjet3: function () {
        return this.objet3;
      },
      setObjet3: function (val) {
        this.objet3 = val;
      },

      getObjet: function (inline) {
        var objet = "";
        if (angular.isDefined(this.getObjet1()) && this.getObjet1() !== "") {
          if (inline === true) {
            objet += this.getObjet1() + " ";
          } else {
            if (inline) {
              objet += this.getObjet1() + inline;
            } else {
              objet += this.getObjet1() + "<br/>";
            }
          }
        }
        if (angular.isDefined(this.getObjet2()) && this.getObjet2() !== "") {
          if (inline === true) {
            objet += this.getObjet2() + " ";
          } else {
            if (inline) {
              objet += this.getObjet2() + inline;
            } else {
              objet += this.getObjet2() + "<br/>";
            }
          }
        }
        if (angular.isDefined(this.getObjet3()) && this.getObjet3() !== "") {
          if (inline === true) {
            objet += this.getObjet3() + " ";
          } else {
            if (inline) {
              objet += this.getObjet3() + inline;
            } else {
              objet += this.getObjet3() + "<br/>";
            }
          }
        }

        if (inline && objet !== "") {
          if (inline === true) {
            objet = objet.slice(0, -5);
          } else {
            objet = objet.slice(0, -inline.length);
          }
        }

        return objet;
      },

      // duree
      getDuree: function () {
        return this.duree;
      },
      setDuree: function (val) {
        this.duree = val;
      },

      // preRes
      getPreRes: function () {
        return this.preRes;
      },
      setPreRes: function (val) {
        this.preRes = val;
      },

      // perRev
      getPerRev: function () {
        return this.perRev;
      },
      setPerRev: function (val) {
        this.perRev = val;
      },

      // monCon
      getMonCon: function () {
        return this.monCon;
      },
      setMonCon: function (val) {
        this.monCon = val;
      },

      // derMon
      getDerMon: function () {
        return this.derMon;
      },
      setDerMon: function (val) {
        this.derMon = val;
      },

      // derDat
      getDerDat: function (value) {
        if (arguments.length) {
          this.setDerDat(value);
        } else {
          return this.derDat;
        }
      },
      getDerDatFormat: function (formatDate) {
        return UtilsService.parseDate(this.derDat, formatDate);
      },
      setDerDat: function (val) {
        this.derDat = val;
      },

      // lib1
      getLib1: function () {
        return this.lib1;
      },
      setLib1: function (val) {
        this.lib1 = val;
      },

      // lib2
      getLib2: function () {
        return this.lib2;
      },
      setLib2: function (val) {
        this.lib2 = val;
      },

      getLibelle: function () {
        var objet = "";
        if (this.getLib1() != "") {
          objet += this.getLib1() + "<br/>";
        }
        if (this.getLib2() != "") {
          objet += this.getLib2() + "<br/>";
        }
        return objet.slice(0, -5);
      },

      // ordre
      getOrdre: function () {
        return this.ordre;
      },
      setOrdre: function (val) {
        this.ordre = val;
      },

      // tresor
      getTresor: function () {
        return this.tresor;
      },
      setTresor: function (val) {
        this.tresor = val;
      },

      // basRep
      getBasRep: function () {
        return this.basRep;
      },
      setBasRep: function (val) {
        this.basRep = val;
      },
      hasBasRep: function () {
        return math.numeric(this.basRep,'number') !== 0;
      },

      // proReg
      getProReg: function () {
        return this.proReg;
      },
      setProReg: function (val) {
        this.proReg = val;
      },

      // monReg
      getMonReg: function () {
        return this.monReg;
      },
      setMonReg: function (val) {
        this.monReg = val;
      },

      // manDat
      getManDat: function () {
        return this.manDat;
      },
      setManDat: function (val) {
        this.manDat = val;
      },

      // modReg
      getModReg: function () {
        return this.modReg;
      },
      setModReg: function (val) {
        this.modReg = val;
      },

      // manAtt
      getManAtt: function () {
        return this.manAtt;
      },
      setManAtt: function (val) {
        this.manAtt = val;
      },

      // genAtt
      getGenAtt: function () {
        return this.genAtt;
      },
      setGenAtt: function (val) {
        this.genAtt = val;
      },

      // persoC
      getPersoC: function () {
        return this.persoC;
      },
      setPersoC: function (val) {
        this.persoC = val;
      },

      // _type
      get_type: function () {
        return this._type;
      },
      set_type: function (val) {
        this._type = val;
      },

      // bat
      getBat: function () {
        return this.bat;
      },
      setBat: function (val) {
        this.bat = val;
      },

      // tacite
      getTacite: function () {
        return this.tacite;
      },
      setTacite: function (val) {
        this.tacite = val;
      },

      // carnet
      getCarnet: function () {
        return this.carnet;
      },
      setCarnet: function (val) {
        this.carnet = val;
      },

      // typeEquip
      getTypeEquip: function () {
        return this.typeEquip;
      },
      setTypeEquip: function (val) {
        this.typeEquip = val;
      },

      // datFin
      getDatFin: function (value) {
        if (arguments.length) {
          this.setDatFin(value);
        } else {
          return this.datFin;
        }
      },
      getDatFinFormat: function (formatDate) {
        return UtilsService.parseDate(this.datFin, formatDate);
      },
      setDatFin: function (val) {
        this.datFin = val;
      },

      // rDatFin
      getRDatFin: function (value) {
        if (arguments.length) {
          this.setRDatFin(value);
        } else {
          return this.rDatFin;
        }
      },
      getRDatFinFormat: function (formatDate) {
        return UtilsService.parseDate(this.rDatFin, formatDate);
      },
      setRDatFin: function (val) {
        this.rDatFin = val;
      },

      // datRes
      getDatRes: function (value) {
        if (arguments.length) {
          this.setDatRes(value);
        } else {
          return this.datRes;
        }
      },
      getDatResFormat: function (formatDate) {
        return UtilsService.parseDate(this.datRes, formatDate);
      },
      setDatRes: function (val) {
        this.datRes = val;
      },

      // rDatRes
      getRDatRes: function (value) {
        if (arguments.length) {
          this.setRDatRes(value);
        } else {
          return this.rDatRes;
        }
      },
      getRDatResFormat: function (formatDate) {
        return UtilsService.parseDate(this.rDatRes, formatDate);
      },
      setRDatRes: function (val) {
        this.rDatRes = val;
      },

      // datRev
      getDatRev: function (value) {
        if (arguments.length) {
          this.setDatRev(value);
        } else {
          return this.datRev;
        }
      },
      getDatRevFormat: function (formatDate) {
        return UtilsService.parseDate(this.datRev, formatDate);
      },
      setDatRev: function (val) {
        this.datRev = val;
      },

      // rDatRev
      getRDatRev: function (value) {
        if (arguments.length) {
          this.setRDatRev(value);
        } else {
          return this.rDatRev;
        }
      },
      getRDatRevFormat: function (formatDate) {
        return UtilsService.parseDate(this.rDatRev, formatDate);
      },
      setRDatRev: function (val) {
        this.rDatRev = val;
      },

      // libType
      getLibType: function () {
        return this.libType;
      },
      setLibType: function (val) {
        this.libType = val;
      },

      // libTypeEquip
      getLibTypeEquip: function () {
        return this.libTypeEquip;
      },
      setLibTypeEquip: function (val) {
        this.libTypeEquip = val;
      },

      // datSou
      getDatSou: function (value) {
        if (arguments.length) {
          this.setDatSou(value);
        } else {
          return this.datSou;
        }
      },
      getDatSouFormat: function (formatDate) {
        return UtilsService.parseDate(this.datSou, formatDate);
      },
      setDatSou: function (val) {
        this.datSou = val;
      },

      // dat1Sou
      getDat1Sou: function (value) {
        if (arguments.length) {
          this.setDat1Sou(value);
        } else {
          return this.dat1Sou;
        }
      },
      getDat1SouFormat: function (formatDate) {
        return UtilsService.parseDate(this.dat1Sou, formatDate);
      },
      setDat1Sou: function (val) {
        this.dat1Sou = val;
      },

      // perPai
      getPerPai: function () {
        return this.perPai;
      },
      setPerPai: function (val) {
        this.perPai = val;
      },

      // monOri
      getMonOri: function () {
        return this.monOri;
      },
      setMonOri: function (val) {
        this.monOri = val;
      },

      // lastDatNego
      getLastDatNego: function (value) {
        if (arguments.length) {
          this.setLastDatNego(value);
        } else {
          return this.lastDatNego;
        }
      },
      getLastDatNegoFormat: function (formatDate) {
        return UtilsService.parseDate(this.lastDatNego, formatDate);
      },
      setLastDatNego: function (val) {
        this.lastDatNego = val;
      },

      // dureeNego
      getDureeNego: function () {
        return this.dureeNego;
      },
      setDureeNego: function (val) {
        this.dureeNego = val;
      },

      // nextDatNego
      getNextDatNego: function (value) {
        if (arguments.length) {
          this.setNextDatNego(value);
        } else {
          return this.nextDatNego;
        }
      },
      getNextDatNegoFormat: function (formatDate) {
        return UtilsService.parseDate(this.nextDatNego, formatDate);
      },
      setNextDatNego: function (val) {
        this.nextDatNego = val;
      },

      // rNextDatNego
      getRNextDatNego: function (value) {
        if (arguments.length) {
          this.setRNextDatNego(value);
        } else {
          return this.rNextDatNego;
        }
      },
      getRNextDatNegoFormat: function (formatDate) {
        return UtilsService.parseDate(this.rNextDatNego, formatDate);
      },
      setRNextDatNego: function (val) {
        this.rNextDatNego = val;
      },

      // datDebC
      getDatDebC: function (value) {
        if (arguments.length) {
          this.setDatDebC(value);
        } else {
          return this.datDebC;
        }
      },
      getDatDebCFormat: function (formatDate) {
        return UtilsService.parseDate(this.datDebC, formatDate);
      },
      setDatDebC: function (val) {
        this.datDebC = val;
      },

      // datFinC
      getDatFinC: function (value) {
        if (arguments.length) {
          this.setDatFinC(value);
        } else {
          return this.datFinC;
        }
      },

      getDatFinCFormat: function (formatDate) {
        return UtilsService.parseDate(this.datFinC, formatDate);
      },
      setDatFinC: function (val) {
        this.datFinC = val;
      },

      isValid: function () {
        if (angular.isDefined(this.datFinC) && this.datFinC !== "") {
          if (moment().isSameOrAfter(this.datFinC)) {
            return false;
          }
        }
        return true;
      },

      // Fournisseur
      getFournisseur: function () {
        return this.persofournisseur;
      },
      setFournisseur: function (val) {
        // Si "val" est un string
        if (angular.isString(val)) {
          val = new Fournisseur({ nomper: val });
        } else {
          // Si val est un objet mais qu'il n'est pas un model
          if (!val.isModel) {
            // Si val a l'attribut "nomper"
            if (val.hasOwnProperty("nomper")) {
              val = new Fournisseur(val);
            } else {
              val = false;
            }
          }
        }

        this.persofournisseur = val;
      },

      // Nom du fournisseur
      getNomFournisseur: function () {
        var retour = "";
        if (this.getFournisseur()) {
          if (this.getFournisseur().getCodelib()) {
            retour += this.getFournisseur().getCodelib() + " ";
          }
          retour += this.getFournisseur().getNomper();
        }
        return retour;
      },

      getInfosFournisseur: function () {
        var retour = "";
        if (this.getPersonnalite()) {
          var noperso = this.getFournisseur().getNoperso();
          var nom = this.getNomFournisseur();
          if (nom !== "") {
            retour += nom;
            if (noperso !== "") {
              retour += " [" + noperso + "]";
            }
            retour += "<br/>";
          } else {
            if (noperso !== "") {
              retour += "NoPerso: " + noperso + "<br/>";
            }
          }

          var tel1 = this.getFournisseur().getNotel1();
          if (tel1 != "") {
            retour += '<i class="fa fa-phone"></i> ' + tel1 + "<br/>";
          }

          var tel2 = this.getFournisseur().getNotel2();
          if (tel2 != "") {
            retour += '<i class="fa fa-phone"></i> ' + tel2 + "<br/>";
          }

          var fax = this.getFournisseur().getNofax();
          if (fax != "") {
            retour += '<i class="fa fa-fax"></i> ' + fax + "<br/>";
          }
        }

        retour = retour.slice(0, -5);
        return retour;
      },

      getImmeuble: function () {
        return this.immeuble;
      },
      setImmeuble: function (val) {
        // Si "val" est un string
        if (angular.isString(val)) {
          val = new Immeuble({ nomimme: val });
        } else {
          // Si val est un objet mais qu'il n'est pas un model
          if (!val.isModel) {
            // Si val a l'attribut "nomimme"
            if (val.hasOwnProperty("nomimme")) {
              val = new Immeuble(val);
            } else {
              val = false;
            }
          }
        }

        this.immeuble = val;
      },

      getMetierfournisseur: function () {
        return this.metierfournisseur;
      },
      setMetierfournisseur: function (val) {
        // Si "val" est un string
        if (angular.isString(val)) {
          val = new Metierfournisseur({ libellemetier: val });
        } else {
          // Si val est un objet mais qu'il n'est pas un model
          if (!val.isModel) {
            // Si val a l'attribut "nomimme"
            if (val.hasOwnProperty("libellemetier")) {
              val = new Metierfournisseur(val);
            } else {
              val = false;
            }
          }
        }
        this.metierfournisseur = val;
      },

      getContrattab: function (cccccc) {
        var contratab = null;
        if(_.isArray(this.contrattabs) && this.contrattabs.length){
          for(var i = 0; i < this.contrattabs.length; i++){
            if(this.contrattabs[i].getTabImput() == cccccc){
              contratab = this.contrattabs[i];
              break;
            }
          }
        }
        return contratab;
      },

      // Contratabs
      getContrattabs: function () {
        return this.contrattabs;
      },
      setContrattabs: function (val) {
        var list = [];
        if(_.isArray(val)){

          var Contrattab = Main.$injector.get('Contrattab');
          for(var i = 0; i < val.length; i++){
            var currentContratab = val[i];
            if(_.isObject(val[i]) && !val[i].isModel){
              currentContratab = new Contrattab(val[i]);
              currentContratab.calcPercentQP(this.basRep);
            }
            list.push(currentContratab);
          }
          Contrattab = null;
        }
        this.contrattabs = list;
      }
    };
    return Contrat;
  }
})();
