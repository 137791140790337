(function () {
  'use strict';

  /**
   * @desc Affiche un bloc de filtre par "channels" (destinataires de topic)
   * @example <channels></channels>
   */

  angular
    .module('collaboreApp')
    .component('channels',{
      templateUrl: 'app/channel/channels.component.html',
      controllerAs: 'channelsctrl',
      bindings: {
        modeSelect: '<',     // PErmet de passer le composant en mode balise <select>
        classSelect: '@',     // Class pour le select
        rootscopeCurrent: '<'
      },
      controller: ChannelsController
    });

  /** @ngInject */
  function ChannelsController(COLLAB_CONF, $rootScope, $state, FiltreService, UtilsService){

    var ctrl = this, listenerChangeStart;

    ctrl.open = true;
    ctrl.channels = [];
    ctrl.libelleChannelAll = COLLAB_CONF.LIBELLE_CHANNEL_ALL;
    ctrl.isTopicDetail = UtilsService.isTopicDetail;
    //ctrl.goSearch = goSearch;

    ctrl.hasChannelActif = hasChannelActif;
    ctrl.getChannelFiltreActif = getChannelFiltreActif;
    //ctrl.paramsFiltreByChannel = paramsFiltreByChannel;

    ctrl.configScrollbar = {
      autoHideScrollbar: false,
      theme: 'dark-thin',
      advanced:{
        updateOnContentResize: true
      },
      scrollButtons: {
        scrollAmount: 'auto', // scroll amount when button pressed
        enable: false // enable scrolling buttons by default
      },
      axis: 'y', // enable 2 axis scrollbars by default
      setHeight: 150,
      scrollInertia: 100
    };

    // -------- Composant en mode <select> ----------
    ctrl.currentSelectEquipe = null;
    ctrl.listSelectEquipes = [];
    ctrl.onChangeSelectEquipe = onChangeSelectEquipe;



    ctrl.$onInit = function() {
      initChannelStateName($state.current);
      ctrl.channels = angular.copy($rootScope.current.userGroupe.getListeChannel());

      // Si mode <select>
      if(ctrl.modeSelect) {
        ctrl.listSelectEquipes.push({
          idChannel: 0,
          nom: ctrl.libelleChannelAll,
          type: {
            idTypeChannel: 0
          }
        });

        if(ctrl.channels.length)
          ctrl.listSelectEquipes = _.union(ctrl.listSelectEquipes, ctrl.channels);


        if(ctrl.listSelectEquipes.length){
          for(var i = 0; i < ctrl.listSelectEquipes.length; i++){
            var chan = ctrl.listSelectEquipes[i];
            if(($state.current.name === ctrl.channelStateName && getChannelFiltreActif(chan)) || (($state.includes('topics.*') || $state.includes('dashboard.*')) && getChannelFiltreActif(chan))) {
              ctrl.currentSelectEquipe = chan;
              break;
            }
          }
        }
      }
      /*
              if($rootScope.current.userGroupe.getDieze() === '#ICS' || $rootScope.current.userGroupe.getDieze() === '#FRANCELOC') {

                  ctrl.enabled = true;

                  ctrl.channels = [];

                  ctrl.loading = {
                      'show': true,
                      'img': COLLAB_CONF.MIDDLE_LOADING
                  };

                  var idGroupe = $rootScope.current.userGroupe.getIdGroupe();

                  GroupesService.getGroupesFlatten(idGroupe).then(function (groupesFlatten) {
                      ctrl.channels = groupesFlatten;
                  }).finally(function () {
                      ctrl.loading.show = false;
                  });
              }*/

      // Lors du changement d'une route
      listenerChangeStart = $rootScope.$on('$stateChangeStart', function(event, toState, toParams){

        initChannelStateName(toState);
        if(_.isObject($rootScope.current.userGroupe)) {
          ctrl.channels = angular.copy($rootScope.current.userGroupe.getListeChannel());
        }


        if(ctrl.channels.length) {
          for(var i = 0; i < ctrl.channels.length; i++){
            if(ctrl.channels[i].idChannel !== toParams.idChannel) {
              ctrl.channels[i].open = false;
              ctrl.channels[i].active = false;
            }
          }
        }

        if(ctrl.modeSelect && ctrl.listSelectEquipes.length) {
          ctrl.listSelectEquipes.map(function(chan){
            chan.open = false;
            chan.active = false;
          });
        }
        genereParamsFiltre();
      });

      genereParamsFiltre();
    };

    ctrl.$onChanges = function(changes) {
      //console.log(changes);
      if(!changes.rootscopeCurrent.isFirstChange() && changes.rootscopeCurrent.currentValue) {
        genereParamsFiltre();
      }
    };

    ctrl.$onDestroy = function(){
      if(listenerChangeStart) listenerChangeStart();
    };

    function genereParamsFiltre(){
      var retour = FiltreService.parseFiltreToUrl($rootScope.current.filtreTopics);

      // Si page de recherche libre alors on prends tous les topics cloturé ou non
      if($state.is('topics.recherche')){
        ctrl.paramsFiltreList = FiltreService.getObjFiltre(retour, -1, null);
        ctrl.paramsFiltreFavoris = FiltreService.getObjFiltre(retour, -1, null);
        ctrl.paramsFiltreArchives = FiltreService.getObjFiltre(retour, -1, null);
        ctrl.paramsFiltreTous = FiltreService.getObjFiltre(retour, -1, null);
      }
      else {
        ctrl.paramsFiltreList = FiltreService.getObjFiltre(retour, -1, retour.obj.cloturer);
        ctrl.paramsFiltreFavoris = FiltreService.getObjFiltre(retour, -1, retour.obj.cloturer);
        ctrl.paramsFiltreArchives = FiltreService.getObjFiltre(retour, -1, null);
        ctrl.paramsFiltreTous = FiltreService.getObjFiltre(retour, -1, null);
      }

      //ctrl.paramsFiltreTous = FiltreService.getObjFiltre(retour, -1);
      //ctrl.paramsFiltreTousWithCloture = FiltreService.getObjFiltre(retour, -1, null);

      if(ctrl.channels.length) {
        for(var c = 0; c < ctrl.channels.length; c++){
          if($state.is('topics.recherche')){
            ctrl.channels[c].paramsFiltreList = FiltreService.getObjFiltre(retour, ctrl.channels[c].idChannel, null);
            ctrl.channels[c].paramsFiltreFavoris = FiltreService.getObjFiltre(retour, ctrl.channels[c].idChannel, null);
            ctrl.channels[c].paramsFiltreArchives = FiltreService.getObjFiltre(retour, ctrl.channels[c].idChannel, null);
            ctrl.channels[c].paramsFiltreTous = FiltreService.getObjFiltre(retour, ctrl.channels[c].idChannel, null);
          }
          else{
            ctrl.channels[c].paramsFiltreList = FiltreService.getObjFiltre(retour, ctrl.channels[c].idChannel, retour.obj.cloturer);
            ctrl.channels[c].paramsFiltreFavoris = FiltreService.getObjFiltre(retour, ctrl.channels[c].idChannel, retour.obj.cloturer);
            ctrl.channels[c].paramsFiltreArchives = FiltreService.getObjFiltre(retour, ctrl.channels[c].idChannel, null);
            ctrl.channels[c].paramsFiltreTous = FiltreService.getObjFiltre(retour, ctrl.channels[c].idChannel, null);
          }

          // ctrl.channels[c].paramsFiltre = FiltreService.getObjFiltreByChannel(ctrl.channels[c].idChannel);
        }
      }
    }

    function initChannelStateName(toState){
      ctrl.channelStateName = 'topics.list';
      ctrl.channelStatePreffixName = 'topics';
      if(toState) {
        if(toState.name === 'dashboard.accueil') ctrl.channelStateName = 'dashboard.accueil';
        else if(toState.name === 'dashboard.reporting') ctrl.channelStateName = 'dashboard.reporting';
        else if(toState.name === 'factures') ctrl.channelStateName = 'factures';
        else if(UtilsService.startsWith(toState.name, 'topics.recherche.')) {
          ctrl.channelStateName = 'topics.recherche.list';
          ctrl.channelStatePreffixName = 'topics.recherche';
        }
      }
    }

    function hasChannelActif(){
      var retour = false;
      if(angular.isDefined($rootScope.current.filtreTopics.idChannel)){
        if($rootScope.current.filtreTopics.idChannel!==0 && $rootScope.current.filtreTopics.idChannel!==-1){
          var channel = $rootScope.current.userGroupe.getChannel($rootScope.current.filtreTopics.idChannel);
          if(channel){
            if(!channel.getType().isAccueil()){
              retour = true;
            }
          }
        }
      }

      return retour;
    }

    function getChannelFiltreActif(channel){
      return (parseInt(channel.idChannel) === parseInt($rootScope.current.filtreTopics.idChannel));
    }


    function paramsFiltreByChannel(channel){
      return FiltreService.getObjFiltreByChannel(channel.idChannel);
    }


    /**
     * Lorsqu'on selectionne un channel en mode <select>
     * @param chan
     */
    function onChangeSelectEquipe(chan){
      var params = paramsFiltreByChannel(ctrl.currentSelectEquipe);
      $state.go(ctrl.channelStateName, params, {location: true, reload: true, inherit: false});
    }

    /*
        function goSearch(search){
            var objRecherche = {
                'newRecherche': true,
                'paramRechercheGenerale': {
                    'search': search,
                    'option': 2,
                    'currentPage': 1,
                    'currentLimit': 10
                }
            };

            $rootScope.current.rechercheGenerale.paramRechercheGenerale = objRecherche.paramRechercheGenerale;

            $state.go('recherche',objRecherche,{'location':true,'reload':false,'inherit':false});
        }*/
  }
})();
