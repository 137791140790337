(function () {
  'use strict';

  /**
   * @desc Formulaire de l'événement
   * @example <nouveau-topic></nouveau-topic>
   */

  angular
    .module('collaboreApp')
    .component('nouveauTopic', {
      templateUrl: 'app/topics/nouveau-topic/nouveau-topic.component.html',
      controllerAs: 'nouveautopicctrl',
      controller: NouveauTopicController
    });

  /*@ngInject*/
  function NouveauTopicController($state) {
    this.$state = $state;
  }
})();
