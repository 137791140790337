'use strict';

/**
 * @ngdoc service
 * @name collaboreApp.UtilsService
 * @description
 * # UtilsService
 * Service in the collaboreApp.
 */
/* eslint-disable */
/**
 * @description
 * Get an object, and return an array composed of it's properties names(nested too).
 * @param obj {Object}
 * @param stack {Array}
 * @param parent {String}
 * @returns {Array}
 * @example
 * parseKeys({ a:1, b: { c:2, d: { e: 3 } } }) ==> ["a", "b.c", "b.d.e"]
 */
function deepKeys(obj, stack, parent) {
  stack = stack || [];
  var keys = Object.keys(obj);

  keys.forEach(function(el) {
    //if it's a nested object
    if(isObject(obj[el]) && !isArray(obj[el])) {
      //concatenate the new parent if exist
      var p = parent ? parent + '.' + el : parent;
      deepKeys(obj[el], stack, p || el);
    } else {
      //create and save the key
      var key = parent ? parent + '.' + el : el;
      stack.push(key);
    }
  });
  return stack;
}

/**
 * @description
 * Test if given object is a Scope instance
 * @param obj
 * @returns {Boolean}
 */
function isScope(obj) {
  return obj && obj.$evalAsync && obj.$watch;
}
angular.module('collaboreApp').provider('filterWatcher', function() {

  this.$get = ['$window', '$rootScope', function($window, $rootScope) {

    /**
     * Cache storing
     * @type {Object}
     */
    var $$cache = {};

    /**
     * Scope listeners container
     * scope.$destroy => remove all cache keys
     * bind to current scope.
     * @type {Object}
     */
    var $$listeners = {};

    /**
     * $timeout without triggering the digest cycle
     * @type {function}
     */
    var $$timeout = $window.setTimeout;

    /**
     * @description
     * get `HashKey` string based on the given arguments.
     * @param fName
     * @param args
     * @returns {string}
     */
    function getHashKey(fName, args) {
      function replacerFactory() {
        var cache = [];
        return function(key, val) {
          if(isObject(val) && !isNull(val)) {
            if (~cache.indexOf(val)) return '[Circular]';
            cache.push(val)
          }
          if($window == val) return '$WINDOW';
          if($window.document == val) return '$DOCUMENT';
          if(isScope(val)) return '$SCOPE';
          return val;
        }
      }
      return [fName, JSON.stringify(args, replacerFactory())]
        .join('#')
        .replace(/"/g,'');
    }

    /**
     * @description
     * fir on $scope.$destroy,
     * remove cache based scope from `$$cache`,
     * and remove itself from `$$listeners`
     * @param event
     */
    function removeCache(event) {
      var id = event.targetScope.$id;
      forEach($$listeners[id], function(key) {
        delete $$cache[key];
      });
      delete $$listeners[id];
    }

    /**
     * @description
     * for angular version that greater than v.1.3.0
     * it clear cache when the digest cycle is end.
     */
    function cleanStateless() {
      $$timeout(function() {
        if(!$rootScope.$$phase)
          $$cache = {};
      }, 2000);
    }

    /**
     * @description
     * Store hashKeys in $$listeners container
     * on scope.$destroy, remove them all(bind an event).
     * @param scope
     * @param hashKey
     * @returns {*}
     */
    function addListener(scope, hashKey) {
      var id = scope.$id;
      if(isUndefined($$listeners[id])) {
        scope.$on('$destroy', removeCache);
        $$listeners[id] = [];
      }
      return $$listeners[id].push(hashKey);
    }

    /**
     * @description
     * return the `cacheKey` or undefined.
     * @param filterName
     * @param args
     * @returns {*}
     */
    function $$isMemoized(filterName, args) {
      var hashKey = getHashKey(filterName, args);
      return $$cache[hashKey];
    }

    /**
     * @description
     * store `result` in `$$cache` container, based on the hashKey.
     * add $destroy listener and return result
     * @param filterName
     * @param args
     * @param scope
     * @param result
     * @returns {*}
     */
    function $$memoize(filterName, args, scope, result) {
      var hashKey = getHashKey(filterName, args);
      //store result in `$$cache` container
      $$cache[hashKey] = result;
      // for angular versions that less than 1.3
      // add to `$destroy` listener, a cleaner callback
      if(isScope(scope)) {
        addListener(scope, hashKey);
      } else {
        cleanStateless();
      }
      return result;
    }

    return {
      isMemoized: $$isMemoized,
      memoize: $$memoize
    }
  }];
});

(function() {

  'use strict';

  angular
    .module('collaboreApp')
    .service('UtilsService', UtilsServiceCtrl);

  function UtilsServiceCtrl(COLLAB_VALUES, COLLAB_CONF, $log, $stateParams, $q, $rootScope, $uibModal, $state, $filter, $parse, $sce, $anchorScroll, $location, $timeout, filterWatcher, uuid, UserResource, $injector, $window) {
    var scope = this;

    scope.portefeuilles = {}; // Liste des portefeuille avec comme clé l'idPortefeuille
    scope.$injector = $injector;
    scope.uuid = uuid;

    var isDefined = angular.isDefined,
      isUndefined = angular.isUndefined,
      isFunction = angular.isFunction,
      isString = angular.isString,
      isNumber = angular.isNumber,
      isObject = angular.isObject,
      isArray = angular.isArray,
      forEach = angular.forEach,
      extend = angular.extend,
      copy = angular.copy,
      equals = angular.equals;

    var defaultDiacriticsRemovalap = [
      {'base':'A', 'letters':'\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F'},
      {'base':'AA','letters':'\uA732'},
      {'base':'AE','letters':'\u00C6\u01FC\u01E2'},
      {'base':'AO','letters':'\uA734'},
      {'base':'AU','letters':'\uA736'},
      {'base':'AV','letters':'\uA738\uA73A'},
      {'base':'AY','letters':'\uA73C'},
      {'base':'B', 'letters':'\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181'},
      {'base':'C', 'letters':'\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E'},
      {'base':'D', 'letters':'\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779\u00D0'},
      {'base':'DZ','letters':'\u01F1\u01C4'},
      {'base':'Dz','letters':'\u01F2\u01C5'},
      {'base':'E', 'letters':'\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E'},
      {'base':'F', 'letters':'\u0046\u24BB\uFF26\u1E1E\u0191\uA77B'},
      {'base':'G', 'letters':'\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E'},
      {'base':'H', 'letters':'\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D'},
      {'base':'I', 'letters':'\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197'},
      {'base':'J', 'letters':'\u004A\u24BF\uFF2A\u0134\u0248'},
      {'base':'K', 'letters':'\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2'},
      {'base':'L', 'letters':'\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780'},
      {'base':'LJ','letters':'\u01C7'},
      {'base':'Lj','letters':'\u01C8'},
      {'base':'M', 'letters':'\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C'},
      {'base':'N', 'letters':'\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4'},
      {'base':'NJ','letters':'\u01CA'},
      {'base':'Nj','letters':'\u01CB'},
      {'base':'O', 'letters':'\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C'},
      {'base':'OI','letters':'\u01A2'},
      {'base':'OO','letters':'\uA74E'},
      {'base':'OU','letters':'\u0222'},
      {'base':'OE','letters':'\u008C\u0152'},
      {'base':'oe','letters':'\u009C\u0153'},
      {'base':'P', 'letters':'\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754'},
      {'base':'Q', 'letters':'\u0051\u24C6\uFF31\uA756\uA758\u024A'},
      {'base':'R', 'letters':'\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782'},
      {'base':'S', 'letters':'\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784'},
      {'base':'T', 'letters':'\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786'},
      {'base':'TZ','letters':'\uA728'},
      {'base':'U', 'letters':'\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244'},
      {'base':'V', 'letters':'\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245'},
      {'base':'VY','letters':'\uA760'},
      {'base':'W', 'letters':'\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72'},
      {'base':'X', 'letters':'\u0058\u24CD\uFF38\u1E8A\u1E8C'},
      {'base':'Y', 'letters':'\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE'},
      {'base':'Z', 'letters':'\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762'},
      {'base':'a', 'letters':'\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250'},
      {'base':'aa','letters':'\uA733'},
      {'base':'ae','letters':'\u00E6\u01FD\u01E3'},
      {'base':'ao','letters':'\uA735'},
      {'base':'au','letters':'\uA737'},
      {'base':'av','letters':'\uA739\uA73B'},
      {'base':'ay','letters':'\uA73D'},
      {'base':'b', 'letters':'\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253'},
      {'base':'c', 'letters':'\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184'},
      {'base':'d', 'letters':'\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A'},
      {'base':'dz','letters':'\u01F3\u01C6'},
      {'base':'e', 'letters':'\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD'},
      {'base':'f', 'letters':'\u0066\u24D5\uFF46\u1E1F\u0192\uA77C'},
      {'base':'g', 'letters':'\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F'},
      {'base':'h', 'letters':'\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265'},
      {'base':'hv','letters':'\u0195'},
      {'base':'i', 'letters':'\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131'},
      {'base':'j', 'letters':'\u006A\u24D9\uFF4A\u0135\u01F0\u0249'},
      {'base':'k', 'letters':'\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3'},
      {'base':'l', 'letters':'\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747'},
      {'base':'lj','letters':'\u01C9'},
      {'base':'m', 'letters':'\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F'},
      {'base':'n', 'letters':'\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5'},
      {'base':'nj','letters':'\u01CC'},
      {'base':'o', 'letters':'\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275\u00F0'},
      {'base':'oi','letters':'\u01A3'},
      {'base':'ou','letters':'\u0223'},
      {'base':'oo','letters':'\uA74F'},
      {'base':'p','letters':'\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755'},
      {'base':'q','letters':'\u0071\u24E0\uFF51\u024B\uA757\uA759'},
      {'base':'r','letters':'\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783'},
      {'base':'s','letters':'\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B'},
      {'base':'t','letters':'\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787'},
      {'base':'tz','letters':'\uA729'},
      {'base':'u','letters': '\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289'},
      {'base':'v','letters':'\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C'},
      {'base':'vy','letters':'\uA761'},
      {'base':'w','letters':'\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73'},
      {'base':'x','letters':'\u0078\u24E7\uFF58\u1E8B\u1E8D'},
      {'base':'y','letters':'\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF'},
      {'base':'z','letters':'\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763'}
    ];

    function escapeRegExp(string) {
      return string.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
    }

    /**
     * @description
     * get an object and return array of values
     * @param object
     * @returns {Array}
     */
    function toArray(object) {
      return isArray(object) ? object : Object.keys(object).map(function(key) {
        return object[key];
      });
    }

    /**
     * @param value
     * @returns {boolean}
     */
    function isNull(value) {
      return value === null;
    }

    /**
     * @description
     * return if object contains partial object
     * @param partial{object}
     * @param object{object}
     * @returns {boolean}
     */
    function objectContains(partial, object) {
      var keys = Object.keys(partial);

      return keys.map(function(el) {
        return (object[el] !== undefined) && (object[el] == partial[el]);
      }).indexOf(false) === -1;

    }

    /**
     * @description
     * search for approximate pattern in string
     * @param word
     * @param pattern
     * @returns {*}
     */
    function hasApproxPattern(word, pattern) {
      if(pattern === ''){
        return word;
      }


      var index = word.indexOf(pattern.charAt(0));

      if(index === -1){
        return false;
      }


      return hasApproxPattern(word.substr(index+1), pattern.substr(1))
    }

    /**
     * @description
     * return the first n element of an array,
     * if expression provided, is returns as long the expression return truthy
     * @param array
     * @param n {number}
     * @param expression {$parse}
     * @return array or single object
     */
    function getFirstMatches(array, n, expression) {
      var count = 0;

      return array.filter(function(elm) {
        var rest = isDefined(expression) ? (count < n && expression(elm)) : count < n;
        count = rest ? count+1 : count;

        return rest;
      });
    }
    /**
     * Polyfill to ECMA6 String.prototype.contains
     */
    if (!String.prototype.contains) {
      String.prototype.contains = function() {
        return String.prototype.indexOf.apply(this, arguments) !== -1;
      };
    }

    /**
     * @param num {Number}
     * @param decimal {Number}
     * @param $math
     * @returns {Number}
     */
    function convertToDecimal(num, decimal, $math){
      return $math.round(num * $math.pow(10,decimal)) / ($math.pow(10,decimal));
    }

    var diacriticsMap = {};
    for (var i = 0; i < defaultDiacriticsRemovalap.length; i++) {
      var letters = defaultDiacriticsRemovalap[i].letters.split("");
      for (var j = 0; j < letters.length ; j++){
        diacriticsMap[letters[j]] = defaultDiacriticsRemovalap[i].base;
      }
    }

    // "what?" version ... http://jsperf.com/diacritics/12
    function removeDiacritics (str) {
      return str.replace(/[^\u0000-\u007E]/g, function(a){
        return diacriticsMap[a] || a;
      });
    }

    scope.isInDiacriticsMap = function(char){
      return (!_.isNil(diacriticsMap[char]))
    };


    /***********************************************/
    /*                    String                   */
    /***********************************************/
    scope.test = function (input, pattern, flag) {
      var reg = new RegExp(pattern, flag);
      return isString(input) ? reg.test(input) : input;
    };

    scope.match = function (input, pattern, flag) {
      var reg = new RegExp(pattern, flag);
      return isString(input) ? input.match(reg) : null;
    };

    scope.hasArobase = function (val) {
      var patt = new RegExp('@');
      return patt.test(val);
    };

    scope.hasDieze = function (val) {
      var patt = new RegExp('#');
      return patt.test(val);
    };

    scope.isStringGroupeCollab = function (val) {
      var patt = new RegExp('[a-zA-Z]+\\#[a-zA-Z]+');
      return patt.test(val);
    };

    scope.getLibelle = function (val) {
      var retour = false;
      if(angular.isString(val)){
        if(scope.startsWith(val,'@')){
          if(scope.hasDieze(val)){
            var tab = val.split('#');
            retour = tab[0];
          }else{
            retour = val;
          }
        }
      }
      return retour;
    };

    scope.getDieze = function (val) {
      var retour = false;
      if(angular.isString(val)){
        if(scope.hasDieze(val)){
          var tab = val.split('#');
          retour = '#'+tab[1];
        }
      }
      return retour;
    };

    scope.getUserDieze = function(){
      return $rootScope.current.userGroupe.getDieze();
    };

    scope.isMail = function (email) {
      var patt = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return patt.test(email);
    };



    /**
     * Permet de savoir si le string est un numéro de téléphone valide
     * @param num
     * @returns {boolean}
     */
    scope.isTelMobile = function(num){
      var retour = false;

      num = scope.cleanPhoneNumber(num);
      var nb = num.length;
      var withPlus = scope.startsWith(num, '+');
      if(
        (
          !withPlus &&
          (
            nb == 10 &&
            (
              (scope.isBE() && scope.startsWithFromList(num, COLLAB_CONF.PREFIX_MOBILE_BELGE))
              ||
              (!scope.isBE() && scope.startsWithFromList(num, COLLAB_CONF.PREFIX_MOBILE_FRANCAIS))
            )
          ) ||
          (
            nb == 13 && (scope.startsWith(num, '0032') || scope.startsWith(num, '0033'))
          )
        )
        ||
        (
          withPlus && nb == 12 && (scope.startsWith(num, '+32') || scope.startsWith(num, '+33'))
        )
      ) {
        retour = true;
      }
      return retour;
    };

    scope.startsWithFromList = function(lib, list){
      var retour = false;
      if(_.isArray(list) && list.length) {
        for(var i = 0; i < list.length; i++) {
          if(scope.startsWith(lib, list[i])) {
            retour = true;
            break;
          }
        }
      }
      return retour;
    };

    scope.isEmpty = function(collection) {
      return isObject(collection)
        ? !toArray(collection).length
        : !collection.length;
    };

    scope.ltrim = function(input, chars) {
      var trim = chars || '\\s';
      return isString(input) ? input.replace(new RegExp('^' + trim + '+'), '') : input;
    };

    scope.rtrim = function(input, chars) {
      var trim = chars || '\\s';
      return isString(input) ? input.replace(new RegExp(trim + '+$'), '') : input;
    };

    scope.trim = function(input, chars) {
      var trim = chars || '\\s';
      return isString(input) ? input.replace(new RegExp('^' + trim + '+|' + trim + '+$', 'g'), '') : input;
    };

    scope.startsWith = function (input, start, csensitive) {

      var sensitive = csensitive || false;

      if(!isString(input) || isUndefined(start)) {
        return input;
      }

      input = (sensitive) ? input : input.toLowerCase();

      return !input.indexOf((sensitive) ? start : start.toLowerCase());
    };

    scope.endsWith = function (input, ends, csensitive) {

      var sensitive = csensitive || false,
        position;

      if(!isString(input) || isUndefined(ends)) {
        return input;
      }

      input = (sensitive) ? input : input.toLowerCase();
      position = input.length - ends.length;

      return input.indexOf((sensitive) ? ends : ends.toLowerCase(), position) !== -1;
    };

    scope.convertStringToGroupeCollab = function (string) {

      var retour = null;

      // Supprime le @ du début si il y en a un
      var newString = scope.ltrim(string,'@');

      // Si il y a un dieze
      if(scope.hasDieze(newString)){
        var Groupe = $injector.get('Groupe');
        // Split le destinataire à partir du dieze
        var tabNewCollaborateur = newString.split('#');

        var obj = {
          libelle: '@'+tabNewCollaborateur[0],
          dieze: '#'+tabNewCollaborateur[1]
        };
        retour = new Groupe(obj);
      }
      return retour;
    };

    scope.checkValidDestinataire = function (destinataire) {

      var objRetour = {};
      objRetour.success = true;

      // Supprime le @ du début si il y en a un
      var newDestinataire = scope.ltrim(destinataire,'@');

      var hasDieze = scope.hasDieze(newDestinataire);
      var isMail = scope.isMail(newDestinataire);

      objRetour.boolMail = false;

      // Si il y a un dieze
      if(hasDieze){
        // Split le destinataire à partir du dieze
        var tabNewCollaborateur = newDestinataire.split('#');


        objRetour.destinataire = '@'+newDestinataire;
        objRetour.libelle = '@'+tabNewCollaborateur[0];
        objRetour.dieze = '#'+tabNewCollaborateur[1];
        // Sinon si c'est un email
      }else if(isMail){
        objRetour.destinataire = newDestinataire;
        objRetour.libelle = newDestinataire;
        objRetour.dieze = '';
        objRetour.boolMail = true;
      }else{
        objRetour.success = false;
        objRetour.libelle = false;
        objRetour.dieze = false;
        objRetour.destinataire = false;
        objRetour.message = 'Veuillez saisir : exemple#Societe OU un email';
      }


      return objRetour;
    };

    scope.inSameGroupString = function (string) {
      if(string){
        return scope.endsWith(string,$rootScope.current.userGroupe.getDieze(),true);
      }
    };

    scope.isSameDiese = function (string) {
      if(string) return scope.endsWith(string, $rootScope.current.userGroupe.getDieze(), true);
    };

    scope.userIsCreateur = function (itemContenu) {
      if(_.isObject(itemContenu)) {
        if(_.isObject(itemContenu.expediteur)) return $rootScope.current.userGroupe.getIdGroupe() === itemContenu.expediteur.idGroupe;
        else if(_.isObject(itemContenu.groupeCreateur)) return $rootScope.current.userGroupe.getIdGroupe() === itemContenu.groupeCreateur.idGroupe;
      }
      return false;
    };

    scope.stripTags = function(input) {
      return isString(input) ? input.replace(/<\S[^><]*>/g, '') : input;
    };

    scope.dateParser = function (paramDate) {

      var todayMoment = moment();
      var paramDateMoment = moment(paramDate);

      if(paramDateMoment.isValid()) {
        // Si aujourd'hui retourne juste l'heure
        if(todayMoment.format('MDYYYY') === paramDateMoment.format('MDYYYY')) {
          return paramDateMoment.format('HH:mm');

        }
        // Sinon retourne le jour et l'heure
        else {
          var date = null;
          // Si la même année
          if(todayMoment.format('YYYY') === paramDateMoment.format('YYYY')) date = paramDateMoment.format('D MMM');
          else date = paramDateMoment.format('D MMM YYYY');

          return date+' <small class="text-muted">'+paramDateMoment.format('HH:mm')+'</small>';
        }
      }
      else return paramDate;
    };
    /*
     scope.dateParser = function (paramDate) {

     // Année et date de aujourd'hui
     var anneeDuJour = $filter('date')(new Date(),'yyyy');
     var dateDuJour = $filter('date')(new Date(),'yyyy-MM-dd');


     var dateTmp = $filter('date')(paramDate,'yyyy-MM-dd');

     // Si aujourd'hui retourne juste l'heure
     if(dateDuJour === dateTmp) {
     return $filter('date')(paramDate,'HH:mm');

     // Sinon retourne le jour et l'heure
     }else {

     // Année de la date donne au filtre
     var annee = $filter('date')(paramDate,'yyyy');
     var date = null;

     // Si pas la même année
     if(angular.equals(annee,anneeDuJour)){
     date = $filter('date')(paramDate,'d MMM');
     }else{
     date = $filter('date')(paramDate,'d MMM yyyy');
     }
     var heure = $filter('date')(paramDate,'HH:mm');

     return date+' <small class="text-muted">'+heure+'</small>';


     //return $filter('date')(paramDate,'dd/MM/yyyy à HH:mm');
     }
     };*/

    scope.dateFormat = function (date,format) {
      if(!format) {
        format = 'dd/MM/yyyy';
      }
      return angular.copy($filter('date')(date,format));
    };

    scope.truncate = function(input, length, suffix, preserve) {

      length = isUndefined(length) ? input.length : length;
      preserve = preserve || false;
      suffix = suffix || '';

      if(!isString(input) || (input.length <= length)) return input;

      return input.substring(0, (preserve)
        ? ((input.indexOf(' ', length) === -1) ? input.length : input.indexOf(' ', length))
        : length) + suffix;
    };

    scope.firstDieze = function (val) {
      var patt = new RegExp('^#');
      return patt.test(val);
    };


    scope.cleanHtml = function(val){
      //return val.replace(/(\/\*[\w\'\s\r\n\*]*\*\/)|(\/\/[\w\s\']*)|(\<![\-\-\s\w\>\/]*\>)/gm, '');
      //return val.replace(/<!--(.*?)-->/gm, '');
      return val.replace(/<!--[^>]*-->/gi, '')    // Suppresison des com html
        .replace(/ng-(\w+|\-\w+)+=".*?"/g, '')  // Suppression des attributs ng- et leur valeur
        .replace(/ng-.+?\b/g, '')               // Suppression des ng- restant
        .replace(/class="\s*"/g, '')            // Suppression des class vide
        //.replace(/\"/g, "\\\"")                 // Ajoute des "/" devant les "
        .replace(/\n/g, '')                     // Suppression des retour chariot
        .replace(/\s+/g, ' ');                  // Suppression des caractères vide successif
    };

    scope.nl2br = function(str, is_xhtml) {
      //  discuss at: http://phpjs.org/functions/nl2br/
      // original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
      // improved by: Philip Peterson
      // improved by: Onno Marsman
      // improved by: Atli Þór
      // improved by: Brett Zamir (http://brett-zamir.me)
      // improved by: Maximusya
      // bugfixed by: Onno Marsman
      // bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
      //    input by: Brett Zamir (http://brett-zamir.me)
      //   example 1: nl2br('Kevin\nvan\nZonneveld');
      //   returns 1: 'Kevin<br />\nvan<br />\nZonneveld'
      //   example 2: nl2br("\nOne\nTwo\n\nThree\n", false);
      //   returns 2: '<br>\nOne<br>\nTwo<br>\n<br>\nThree<br>\n'
      //   example 3: nl2br("\nOne\nTwo\n\nThree\n", true);
      //   returns 3: '<br />\nOne<br />\nTwo<br />\n<br />\nThree<br />\n'
      if(!_.isString(str)) return str;
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display

      return (str + '')
        .replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    };

    scope.bytes = function(bytes, precision) {
      if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
        return '-';
      }
      if (typeof precision === 'undefined') {
        precision = 1;
      }
      var units = ['bytes', 'ko', 'Mo', 'Go', 'To', 'Po'],
        number = Math.floor(Math.log(bytes) / Math.log(1024));
      return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +  ' ' + units[number];
    };

    scope.replaceAll = function(find, replace, str) {
      if(angular.isDefined(str)){
        return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
      }
    };

    /*
     function replaceTag(libelle,regex,replace){
     var p = new RegExp(regex,'g');
     libelle.replace(p, function(match) {
     if(replace!==null) libelle.replace(match, replace);
     else libelle.replace(match, match.toUpperCase());
     });
     return libelle;
     }*/

    /*
    scope.parseNumeroTel = function(numero){
        numero = numero.replace(/[^0-9]/g, ''); // Garde que les charactère numérique, les autres sont remplacé par rien
        return numero.trim();
    };*/

    // TODO: A tester
    /*
    scope.enleverCaracteresSpeciaux = function(text) {
        var utf8 = [
            {
                pattern: /[áàâãªä]/gu,
                replacement: 'a'
            },
            {
                pattern: /[ÁÀÂÃÄ]/gu,
                replacement: 'A'
            },
            {
                pattern: /[ÍÌÎÏ]/gu,
                replacement:'I'
            },
            {
                pattern: /[íìîï]/gu,
                replacement:'i'
            },
            {
                pattern: /[éèêë]/gu,
                replacement:'e'
            },
            {
                pattern: /[ÉÈÊË]/gu,
                replacement:'E'
            },
            {
                pattern: /[óòôõºö]/gu,
                replacement:'o'
            },
            {
                pattern: /[ÓÒÔÕÖ]/gu,
                replacement:'O'
            },
            {
                pattern: /[úùûü]/gu,
                replacement:'u'
            },
            {
                pattern: /[ÚÙÛÜ]/gu,
                replacement:'U'
            },
            {
                pattern: /ç/g,
                replacement:'c'
            },
            {
                pattern: /Ç/g,
                replacement:'C'
            },
            {
                pattern: /ñ/g,
                replacement:'n'
            },
            {
                pattern: /Ñ/g,
                replacement:'N'
            },
            {
                pattern: /[']/gu,
                replacement:' '// guillemet simple
            },
            {
                pattern: /["]/gu,
                replacement:' '// guillemet double
            },
            {
                pattern: / /g,
                replacement:' '// espace insécable (équiv. à 0x160)
            }
        ];

        for(var i = 0; i < utf8.length; i++){
            text.replace(utf8[i].pattern, utf8[i].replacement);
        }
        return text;
    }*/

    scope.oldParseTag = function(tag){
      tag = scope.ltrim(tag,'#'); // Supprime un éventuel # au début
      tag = tag.toLowerCase();    // Passe tout en minuscule
      tag = scope.camelize(tag);  // Passe la chaine en CamelCase
      tag = scope.ucfirst(tag);   // 1er lettre en majuscule
      tag = tag.replace(/[^a-zA-Z0-9]/g, ''); // Garde que les charactère alpha numérique, les autres sont remplacé par rien
      return '#'+tag.trim();
      /*
       tag = replaceTag(tag,'[^a-zA-Z0-9,\'][a-zA-Z0-9]{1}',null);
       tag = replaceTag(tag,'[^a-zA-Z0-9]','');
       tag = '#'+tag;

       return tag;*/
    };

    scope.parseLibelleTag = function(tag){
      tag = scope.ltrim(tag,'#'); // Supprime un éventuel # au début
      //tag = scope.slugify(tag); // Transform text into a URL slug, Replaces whitespaces, with dash("-"), or given argument
      tag = scope.latinize(tag); // Remove accents/diacritics from a string
      tag = tag.toLowerCase();    // Passe tout en minuscule
      tag = tag.replace(/[^a-zA-Z0-9-_\s]/g, ''); // Garde que les charactère alpha numérique, tiret, underscore et espace, les autres sont remplacé par rien
      tag = scope.camelize(tag);  // Passe la chaine en CamelCase
      tag = scope.ucfirst(tag);   // 1er lettre en majuscule


      return tag.trim();
    };

    scope.parseTag = function(tag){
      return '#' + scope.parseLibelleTag(tag);
    };

    scope.camelize = function camelize(str) {
      // garde les tirets
      return str.split(/[^a-zA-Z0-9]+/g)
        .reduce(function(memo, s, i) {
          return i === 0 ? s : memo + scope.capitalize(s)
        }, '');
    };

    // Met en majuscule le premier caractère de la chaîne.
    scope.capitalize = function capitalize(str) {
      return str.toLowerCase().replace(/^[a-z]/, function(m) {
        return m.toUpperCase();
      })
    };

    /*
    // Vrai fonction avec lowercase et retrait des accents
    scope.camelize = function camelize(str) {
      return scope.noDiacritics(str)
        .toLowerCase()
        .split(/[^a-z]+/g)
        .reduce(function(memo, s, i) {
          return i === 0 ? s : memo + scope.capitalize(s)
        }, '');
    };

    // Remplace tout les caractères accentués par leur version non-accentué.
    scope.noDiacritics = function noDiacritics(str) {
      var DACRITICS_MAP = {
        A: /[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g,
        E: /[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g,
        I: /[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g,
        O: /[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g,
        U: /[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g,
        a: /[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g,
        ae: /[\u00E6\u01FD\u01E3]/g,
        c: /[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g,
        e: /[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g,
        i: /[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g,
        o: /[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g,
        u: /[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g
      }

      return Object.keys(DACRITICS_MAP).reduce(function (str, k) {
        var v = DACRITICS_MAP[k]
        return str.replace(v, k)
      }, str)
    };

    */



    /*
    scope.camelize = function (input, first) {
      var CAMELIZE_REGEX = /(?:^|[-_ ])(\w)/g;

      var isString = typeof input === 'string',
        firstLetter = typeof first === 'undefined' ? false : !!first;

      if(typeof input === 'undefined' ||
        input === null ||
        (!isString && isNaN(input)) ) {
        return '';
      }

      if(!isString){
        return '' + input;
      }

      return input.trim() // remove trailing spaces
        .replace(/ +(?= )/g,'') // remove multiple WS
        .replace(CAMELIZE_REGEX, function (_, character, pos) { // actual conversion
          if (character && (firstLetter || pos > 0)) {
            return character.toUpperCase();
          } else {
            return character;
          }
        });
    };*/

    /*
     scope.camelize = function(str) {
     return scope.ucfirst(str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
     if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
     return index == 0 ? match.toLowerCase() : match.toUpperCase();
     }));
     };*/

    scope.ucfirst = function(input) {
      return isString(input)
        ? input
          .split(' ')
          .map(function (ch) {
            return ch.charAt(0).toUpperCase() + ch.substring(1);
          })
          .join(' ')
        : input;
    };

    scope.decodeHTMLEntities = function(text) {
      return angular.element("<textarea/>")
        .html(text)
        .text();
    };

    scope.encodeHTMLEntities = function(text) {
      return angular.element("<textarea/>")
        .text(text)
        .html();
    }


    /***********************************************/
    /*                    Collection               */
    /***********************************************/

    scope.removeWith = function (collection, object) {
      if(isUndefined(object)) {
        return collection;
      }
      collection = isObject(collection) ? toArray(collection) : collection;
      return collection.filter(function (elm) {
        return !objectContains(object, elm);
      });
    };

    scope.where = function (collection, object) {
      if(isUndefined(object)) {
        return collection;
      }
      collection = isObject(collection) ? toArray(collection) : collection;

      return collection.filter(function (elm) {
        return objectContains(object, elm);
      });
    };

    scope.isUser = function (libelleDieze) {
      var retour = false;
      if(_.isString(libelleDieze)){
        retour = _.eq($rootScope.current.userGroupe.getLibelleDieze(),libelleDieze);
      }else if(_.isObject(libelleDieze)){
        retour = _.eq($rootScope.current.userGroupe.getLibelleDieze(),libelleDieze.libelle+libelleDieze.dieze);
      }
      return retour;
    };

    scope.inSameGroup = function (itemUser) {
      if(itemUser){
        return _.eq($rootScope.current.userGroupe.getDieze(),itemUser.dieze);
      }
    };

    scope.parseStringCreateur = function (itemUser) {
      var string = '';
      var classBox = '';
      var libelle;

      // Si object user
      if(angular.isObject(itemUser)){
        libelle = itemUser.libelle;
        //libelle = $filter('ltrim')(itemUser.libelle,'@');
        // Si meme groupe
        if(scope.inSameGroup(itemUser)){
          classBox = ' badge-destinataire-samegroupe';
          libelle = libelle;
        }else{
          libelle = libelle+itemUser.dieze;
        }

        // Si string user
      }else if(angular.isString(itemUser)){

        //libelle = $filter('ltrim')(itemUser,'@');
        libelle = itemUser;
        // Si meme groupe par rapport au string
        if(scope.inSameGroupString(itemUser)){
          classBox = ' badge-destinataire-samegroupe';
          libelle = scope.rtrim(libelle,$rootScope.current.userGroupe.getDieze());
        }
      }

      string += '<span class="badge badge-destinataire margin-right-5 destinataire-text'+classBox+'">'+libelle+'</span>';

      return string;
    };

    scope.unique = function (collection, property) {

      collection = isObject(collection) ? toArray(collection) : collection;

      if (!isArray(collection)) {
        return collection;
      }

      //store all unique identifiers
      var uniqueItems = [],
        get = $parse(property);

      return (isUndefined(property))
        //if it's kind of primitive array
        ? collection.filter(function (elm, pos, self) {
          return self.indexOf(elm) === pos;
        })
        //else compare with equals
        : collection.filter(function (elm) {
          var prop = get(elm);
          if(some(uniqueItems, prop)) {
            return false;
          }
          uniqueItems.push(prop);
          return true;
        });

      //checked if the unique identifier is already exist
      function some(array, member) {
        if(isUndefined(member)) {
          return false;
        }
        return array.some(function(el) {
          return equals(el, member);
        });
      }
    };

    scope.toArray = function (collection, addKey) {

      if(!isObject(collection)) {
        return collection;
      }

      return !addKey
        ? toArray(collection)
        : Object.keys(collection).map(function (key) {
          return extend(collection[key], { $key: key });
        });
    };

    scope.groupBy = function (collection, property) {

      if(!isObject(collection) || isUndefined(property)) {
        return collection;
      }

      return filterWatcher.isMemoized('groupBy', arguments) ||
        filterWatcher.memoize('groupBy', arguments, this,
          _groupBy(collection, $parse(property)));

      /**
       * groupBy function
       * @param collection
       * @param getter
       * @returns {{}}
       */
      function _groupBy(collection, getter) {
        var result = {};
        var prop;

        forEach( collection, function( elm ) {
          prop = getter(elm);

          if(!result[prop]) {
            result[prop] = [];
          }
          result[prop].push(elm);
        });
        return result;
      }
    };

    scope.fuzzy = function (collection, search, csensitive) {
      var sensitive = csensitive || false;
      collection = isObject(collection) ? toArray(collection) : collection;

      if(!isArray(collection) || isUndefined(search)) {
        return collection;
      }

      search = (sensitive) ? search : search.toLowerCase();

      return collection.filter(function(elm) {
        if(isString(elm)) {
          elm = (sensitive) ? elm : elm.toLowerCase();
          return hasApproxPattern(elm, search) !== false
        }
        return (isObject(elm)) ? _hasApproximateKey(elm, search) : false;
      });

      /**
       * checks if object has key{string} that match
       * to fuzzy search pattern
       * @param object
       * @param search
       * @returns {boolean}
       * @private
       */
      function _hasApproximateKey(object, search) {
        var properties = Object.keys(object),
          prop, flag;
        return 0 < properties.filter(function (elm) {
          prop = object[elm];

          //avoid iteration if we found some key that equal[performance]
          if(flag) return true;

          if (isString(prop)) {
            prop = (sensitive) ? prop : prop.toLowerCase();
            return flag = (hasApproxPattern(prop, search) !== false);
          }

          return false;

        }).length;
      }
    };

    scope.fuzzyBy = function (collection, property, search, csensitive) {

      var sensitive = csensitive || false,
        prop, getter;

      collection = isObject(collection) ? toArray(collection) : collection;

      if(!isArray(collection) || isUndefined(property)
        || isUndefined(search)) {
        return collection;
      }

      getter = $parse(property);

      return collection.filter(function(elm) {

        prop = getter(elm);
        if(!isString(prop)) {
          return false;
        }

        prop = (sensitive) ? prop : prop.toLowerCase();
        search = (sensitive) ? search : search.toLowerCase();

        return hasApproxPattern(prop, search) !== false
      })
    };

    scope.contains = function (collection, expression) {

      collection = isObject(collection) ? toArray(collection) : collection;

      if(!isArray(collection) || isUndefined(expression)) {
        return false;
      }

      return collection.some(function(elm) {
        return ((isString(expression) && isObject(elm)) || isFunction(expression))
          ? $parse(expression)(elm)
          : elm === expression;
      });

    };

    scope.filterBy = function(collection, properties, search, strict) {
      var comparator;

      search = (isString(search) || isNumber(search)) ?
        String(search).toLowerCase() : undefined;

      collection = isObject(collection) ? toArray(collection) : collection;

      if(!isArray(collection) || isUndefined(search)) {
        return collection;
      }

      return collection.filter(function(elm) {
        return properties.some(function(prop) {

          /**
           * check if there is concatenate properties
           * example:
           * object: { first: 'foo', last:'bar' }
           * filterBy: ['first + last'] => search by full name(i.e 'foo bar')
           */
          if(!~prop.indexOf('+')) {
            comparator = $parse(prop)(elm)
          } else {
            var propList = prop.replace(/\s+/g, '').split('+');
            comparator = propList
              .map(function(prop) { return $parse(prop)(elm); })
              .join(' ');
          }

          if (!isString(comparator) && !isNumber(comparator)) {
            return false;
          }

          comparator = String(comparator).toLowerCase();

          return strict ? comparator === search : comparator.contains(search);
        });
      });
    };

    scope.slugify = function (input, sub) {

      var replace = (isUndefined(sub)) ? '-' : sub;

      return isString(input)
        ? input.toLowerCase().replace(/\s+/g, replace)
        : input;
    };

    scope.latinize = function (input) {

      return isString(input)
        ? removeDiacritics(input)
        : input;
    };

    scope.cleanSpecialChar = function(txt){
      txt = txt.replace(/[^\w\s]/gi, '');
      return txt.trim();
    };

    scope.cleanPhoneNumber = function(txt){
      if(_.isString(txt)) {

        var withPlus = scope.startsWith(txt, '+');

        // Recupère tout les "+" autre que cleui en première position
        //txt = txt.replace(/(?<!^)\+/gi, '');

        // Garde que les charactère numérique, les autres sont remplacé par rien
        txt = txt.replace(/[^\d]/gi, '');

        if(withPlus) txt = '+' + txt;

        return txt.trim();
      }
      return txt;
    };

    /***********************************************/
    /*                    Autres                   */
    /***********************************************/

    scope.cancelPromise  = function(promise){
      if (
        promise &&
        promise._httpTimeout &&
        promise._httpTimeout.resolve
      ) {
        promise._httpTimeout.resolve();
      }
    };

    scope.addEvent = function(context, type, handler) {

      if(context.addEventListener){

        context.addEventListener(type, handler.__wrapper = function(e)
        {
          return handler.call(context, e);

        }, false);

        return { silence : function()
          {
            context.removeEventListener(type, handler.__wrapper, false);
          }};

      }else if(context.attachEvent){

        context.attachEvent('on' + type, handler.__wrapper = function(e)
        {
          return handler.call(context, e);
        });

        return { silence : function()
          {
            context.detachEvent('on' + type, handler.__wrapper);
          }};
      }
    };

    /**
     * Permet de récupérer
     * @returns {Array}
     */
    scope.getModulesModelesEvenements = function(){

      function genereModule(module, campingInfo){
        var modelEvenement = {
          idModule: module.getIdModule(),
          libelle: module.getLibelle(),
          destinataires : [],
          destinatairesHide : [],
          tags : [],
          titre: '',
          objet: '',
          isModeleHotline: false,
          isModeleAgence: false,
          isModeleDefault: false,
          isInFirstGroupeParent: module.getIsInFirstGroupeParent()
        };

        var libelleBase = angular.copy(modelEvenement.libelle);

        var parametres = module.getListeParametres();
        if(parametres.length){
          for(var p = 0 ; p < parametres.length ; p++){
            var param = parametres[p];

            if(param.getReferenceParams().getReference()==='destinataire'){
              var val = param.getValeur().val;
              /*
              // Si le module est "model_event_camping_capfun" et que l'user actuel est bien dans des campings
              if(module.getTypeModule().getTypeObjet() === 'model_event_camping_capfun' && !_.isNil(campingInfo)) {
                if(scope.isCapfunDiese()) val += "-" + campingInfo.getNom() + COLLAB_CONF.SOCIETE_CAPFUN;
                else if(scope.isVaguesOceanesDiese()) val += "-" + campingInfo.getNom() + COLLAB_CONF.SOCIETE_VAGUES_OCEANES;
                else if(scope.isClicochicDiese()) val += "-" + campingInfo.getNom() + COLLAB_CONF.SOCIETE_CLICOCHIC;
                modelEvenement.libelle = libelleBase + " ("+campingInfo.getNom().ucfirst()+")";
              }*/

              if(!_.isNil(campingInfo)) {
                var foundEvent = false;
                // Si le module est "model_event_camping_capfun" et que l'user actuel est bien dans des campings
                if(module.getTypeModule().getTypeObjet() === 'model_event_camping_capfun' && scope.isCapfunDiese()){
                  foundEvent = true;
                  val += "-" + campingInfo.getNom() + COLLAB_CONF.SOCIETE_CAPFUN;
                }
                else if(module.getTypeModule().getTypeObjet() === 'model_event_camping_vo' && scope.isVaguesOceanesDiese()){
                  foundEvent = true;
                  val += "-" + campingInfo.getNom() + COLLAB_CONF.SOCIETE_VAGUES_OCEANES;
                }
                else if(module.getTypeModule().getTypeObjet() === 'model_event_camping_clico' && scope.isClicochicDiese()){
                  foundEvent = true;
                  val += "-" + campingInfo.getNom() + COLLAB_CONF.SOCIETE_CLICOCHIC;
                }
                if(foundEvent) modelEvenement.libelle = libelleBase + " ("+campingInfo.getNom().ucfirst()+")";
              }

              if(param.getValeur().hide) modelEvenement.destinatairesHide.push(val);
              else modelEvenement.destinataires.push(val);
            }
            if(param.getReferenceParams().getReference()==='tags'){
              modelEvenement.tags.push(param.getValeur().val);
            }
            if(param.getReferenceParams().getReference()==='titre'){
              modelEvenement.titre = param.getValeur();
            }
            if(param.getReferenceParams().getReference()==='objet'){
              modelEvenement.objet = param.getValeur();
            }
            if(param.getReferenceParams().getReference()==='modele_hotline'){
              modelEvenement.isModeleHotline = (param.getValeur()==='true') ? true : false;
            }
            if(param.getReferenceParams().getReference()==='modele_agence'){
              modelEvenement.isModeleAgence = (param.getValeur()==='true') ? true : false;
            }
            if(param.getReferenceParams().getReference()==='modele_default'){
              modelEvenement.isModeleDefault = (param.getValeur()==='true') ? true : false;
            }
          }
        }
        return modelEvenement;
      }

      var modelEvent = [];
      if($rootScope.current.myModules.length){
        var nbCamping = (_.isObject(scope.getCurrentUser().getGroupeInformations()) && _.isArray(scope.getCurrentUser().getGroupeInformations().getListCampingInformations()) && scope.getCurrentUser().getGroupeInformations().getListCampingInformations().length) ? scope.getCurrentUser().getGroupeInformations().getListCampingInformations().length : 0;
        for(var e = 0 ; e < $rootScope.current.myModules.length ; e++){
          var module = $rootScope.current.myModules[e];
          if(module.getTypeModule().getTypeObjet() === 'model_event'){
            modelEvent.push(genereModule(module));
          }
          else if(nbCamping !== 0){
            if(scope.isCapfun() && module.getTypeModule().getTypeObjet() === 'model_event_camping_capfun') {
              for(var c = 0; c < nbCamping; c++) {
                modelEvent.push(genereModule(module, scope.getCurrentUser().getGroupeInformations().getListCampingInformations()[c]));
              }
            }
            else if(scope.isVaguesOceanesDiese() && module.getTypeModule().getTypeObjet() === 'model_event_camping_vo') {
              for(var c = 0; c < nbCamping; c++) {
                modelEvent.push(genereModule(module, scope.getCurrentUser().getGroupeInformations().getListCampingInformations()[c]));
              }
            }
            else if(scope.isClicochicDiese() && module.getTypeModule().getTypeObjet() === 'model_event_camping_clico') {
              for(var c = 0; c < nbCamping; c++) {
                modelEvent.push(genereModule(module, scope.getCurrentUser().getGroupeInformations().getListCampingInformations()[c]));
              }
            }
          }
        }
      }
      return modelEvent;
    };

    scope.getModulesTemplatesObjet = function(type){
      var objets = [];
      if($rootScope.current.myModules.length){

        for(var e = 0 ; e < $rootScope.current.myModules.length ; e++){
          var module = $rootScope.current.myModules[e];
          if(module.getTypeModule().getTypeObjet() === type){

            var parametres = module.getListeParametres();
            if(parametres.length){
              for(var p = 0 ; p < parametres.length ; p++){
                var param = parametres[p];
                if(param.getReferenceParams().getReference()==='objet' || param.getReferenceParams().getReference()==='courriers' || param.getReferenceParams().getReference()==='text'){
                  var valeur = angular.fromJson(param.getValeur());

                  var cat = module.getLibelle();
                  if(cat==='') {
                    cat = 'Défaut';
                  }
                  var objet = {
                    idModuleValeurParams: param.getIdModuleValeurParams(),
                    categorie: cat,
                    libelle: valeur.titre,
                    objet: valeur.val
                  };
                  objets.push(objet);
                }
              }
            }
          }
        }
      }
      return objets;
    };

    scope.getParametreForTypeModule = function(typeObjet, nameRefParam){
      var valeur = null;
      if($rootScope.current.myModules.length){

        for(var e = 0 ; e < $rootScope.current.myModules.length ; e++){
          var module = $rootScope.current.myModules[e];
          if(module.getTypeModule().getTypeObjet() === typeObjet){

            var parametres = module.getListeParametres();
            if(parametres.length){
              for(var p = 0 ; p < parametres.length ; p++){
                var param = parametres[p];
                if(param.getReferenceParams().getReference() === nameRefParam){
                  valeur = param.getValeur();
                  break;
                }
              }
            }
          }
          if(angular.isDefined(valeur)) {
            break;
          }
        }
      }
      return valeur;
    };

    scope.getParametreForModule = function(module, nameRefParam){
      var valeur = null;
      if(module){
        var parametres = module.getListeParametres();
        if(parametres.length){
          for(var p = 0 ; p < parametres.length ; p++){
            var param = parametres[p];
            if(!_.isNil(param.getReferenceParams())) {
              if(param.getReferenceParams().getReference() === nameRefParam){
                valeur = param.getValeur();
                break;
              }
            }
            else {
              $log.error('Il manque "referenceParams" dans le param');
              $log.error(param);
            }
          }
        }
      }
      return valeur;
    };

    /**
     * Permet de récupérer un objet contenant l'objet de l'événement et les fiches
     */
    scope.getObjetEvenement = function(objet){
      var retour = {
        texteObjet: null,
        texteFiches: null
      };
      var arrayContents = angular.element(objet);
      if (arrayContents.length === 2) {
        retour.texteObjet = $sce.trustAsHtml(arrayContents[0].innerHTML);

        retour.texteFiches = $sce.trustAsHtml(scope.replaceAll('&nbsp;', '', arrayContents[1].innerHTML));
      }
      else retour.texteObjet = $sce.trustAsHtml(objet);
      return retour;
    };

    /**
     * Recupère la prévisualisation du message si elle existe sinon la créée
     * @param topicState
     * @returns {*}
     */
    scope.getPrevisualisationMessage = function(item){
      var msg, contenu = item;

      // Si l'item est un TopicStates
      if(item.isTopicStates){
        contenu = item.getTopic().getContenus()[0];
      }

      if(item.hasOwnProperty('previsualisationMsg')){
        msg = item.previsualisationMsg;
      }else{
        if(contenu.isContenuMessage()){
          msg = contenu.message;
        }
        else if(contenu.isContenuEvenement()){
          msg = contenu.objet;
        }
        else if(contenu.isContenuDevisInter()){
          msg = contenu.descriptionDevis;
        }
      }
      return msg;
    };

    /**
     * Permet de garder les attributs style du HTML, sinon il sont supprimé
     * @param message
     * @returns {any}
     */
    scope.parseObjetMessage = function(message){
      return $sce.trustAsHtml(message);
    };

    /**
     * Permet de garder les attributs style du HTML, sinon il sont supprimé
     * @param message
     * @returns {any}
     */
    scope.parseStringHTML = function(message){
      return $sce.trustAsHtml(message);
    };

    /**
     * Recupère un array des groupes concernés dans un texte d'événement
     * @param objet
     * @returns {Array}
     */
    scope.getGroupesConcernesInObjet = function(objet){

      var $element = angular.element('<div>'+objet+'</div>');
      var groupes = $element.find('.blockquoteGroupeUser');
      //console.log(groupes);
      var objGroupesConcernes = [];
      if(groupes.length){
        for(var i = 0 ; i < groupes.length ; i++){
          var objGroupes = angular.element(groupes[i]).find('.objGroupesConcernes');
          //console.log(objGroupes);
          if(objGroupes.length){
            var json = angular.element(objGroupes[0]).data('objetgroupe');
            var objGroupe = angular.fromJson(json);
            objGroupesConcernes.push(objGroupe);
          }
        }
      }
      return objGroupesConcernes;
    };

    /**
     * Scroll la page tout en haut
     * @param {Object} itemContenu - objet contenu
     * @returns {void}
     */
    scope.scrollToTop = function(){
      $anchorScroll();
    };

    /**
     * Scroll la page vers le contenu
     * @param {Object} itemContenu - objet contenu
     * @returns {void}
     */
    scope.scrollToContenu = function(itemContenu){
      $timeout(function(){
        //$location.hash('contenu_'+itemContenu.idContenu);
        $anchorScroll('contenu_'+itemContenu.idContenu);
      }, 10);
    };

    /**
     * Scroll la page vers le contenu
     * @param {Object} itemContenu - objet contenu
     * @returns {void}
     */
    scope.scrollToDevis = function(idContenu){
      $timeout(function(){
        $location.hash('devis_'+idContenu);
        $anchorScroll();
      }, 10);
    };

    /**
     * Scroll la page vers l'id
     * @param {Object} id - objet contenu
     * @returns {void}
     */
    scope.scrollToId = function(id){
      $timeout(function(){
        $location.hash(id);
        $anchorScroll();
      }, 10);
    };

    /**
     * Scroll la page vers l'événement
     * @param {Object} id - objet contenu
     * @returns {void}
     */
    scope.scrollToEvenement = function(){
      $timeout(function(){
        $location.hash('Evenement');
        $anchorScroll();
      }, 10);
    };

    /**
     * Scroll la page vers le formulaire de réponse
     * @param {Object} itemContenu - objet contenu
     * @returns {void}
     */
    scope.scrollToFormReponse = function(itemContenu){
      $timeout(function(){
        $location.hash('repondre_'+itemContenu.idContenu);
        $anchorScroll();
      }, 10);
    };

    /**
     * Permet d'aller à la page detail du topic
     * @param itemTopic - item du topic
     * @returns {void}
     */
    scope.goDetailTopic = function(itemTopic,notification){

      var idTopic = false;
      if(angular.isObject(itemTopic)){
        // Si topic archivé
        if(itemTopic.state.idNom===2){

        }
        idTopic = itemTopic.topic.idTopic;
        //$state.go('topics.detail',{'topicId':itemTopic.topic.idTopic},{reload:true});
        itemTopic.topic.lu=true;
      }else if(angular.isNumber(itemTopic)){
        idTopic = itemTopic;

        //$scope.closeNotifications();
      }

      if(angular.isNumber(idTopic)){
        var paramsUrl = {'topicId':idTopic};


        if(angular.isObject(notification)){

          // Si notification de type message
          if(notification.getTypeNotification()===1){
            paramsUrl.hash = 'contenu_'+notification.getIdContenu();

            // Si notification de type événement
          }else if(notification.getTypeNotification()===2){
            paramsUrl.hash = 'Evenement';
          }
        }
        /*

         paramsUrl.evenement = undefined;
         paramsUrl.cloturer = undefined;
         paramsUrl.urgent = undefined;
         paramsUrl.tagsType = undefined;*/

        //$state.params.evenement = undefined;
        //$state.go('topics.detail',paramsUrl,{'location':true,'reload':true});
        $state.go('topics.detail',paramsUrl,{'location':true,'reload':false,'inherit':false});

        if($rootScope.current.openPopoverNotifications){
          $rootScope.current.openPopoverNotifications = false;
        }
        //$state.transitionTo('topics.detail',paramsUrl);
      }
    };

    /**
     * Recupère la date la plus récente du contenu entre création et modification
     * @param contenu
     * @returns {string}
     */
    scope.getLastDate = function(contenu){
      var date = contenu.getDateCreation();
      var libelle = 'Réponse: ';

      if(contenu.getDateModification()){
        date = contenu.getDateModification();
        libelle = 'Modif: ';
      }
      return libelle+scope.dateParser(date);
    };

    /**
     * Permet de savoir si la page actuel est une page de list de topics
     * @returns {boolean}
     */
    scope.isTopicList = function(){
      return ($state.is('topics.list') || $state.is('topics.archives') || $state.is('topics.favoris') || $state.is('topics.tous') || $state.includes('topics.recherche.*'));
    };

    scope.isExtranetFournisseur = function(){
      return ($state.is('mondevis') || $state.is('monEvenement') || $state.is('devisfournisseur') || $state.is('evenementFournisseur') || $state.includes('collabFournisseur.*'));
    };

    scope.isTopicDetail = function(){
      return $state.includes('topics.detail');
    };

    scope.focusEndEditor = function(editor){
      var deferred = $q.defer();
      $timeout(function(){
        editor.focus();


        // var range = editor.createRange();
        // //range.moveToElementEditEnd( range.root );
        // range.moveToPosition( range.root, CKEDITOR.POSITION_BEFORE_END );
        // editor.getSelection().selectRanges( [ range ] );


        var s = editor.getSelection(); // getting selection
        var selected_ranges = s.getRanges(); // getting ranges
        if(selected_ranges.length){
          var node = selected_ranges[0].startContainer; // selecting the starting node
          var parents = node.getParents(true);

          node = parents[parents.length - 2].getFirst();

          if(node){
            while (true) {
              var x = node.getNext();
              if (x == null) {
                break;
              }
              node = x;
            }

            s.selectElement(node);
            selected_ranges = s.getRanges();
            //if(selected_ranges.length){
            selected_ranges[0].collapse(false);  //  false collapses the range to the end of the selected node, true before the node.
            //}
            s.selectRanges(selected_ranges);  // putting the current selection there
          }
        }
        deferred.resolve();
      });
      return deferred.promise;
    };

    /**
     * Objet pour avec options de base de ckeditor
     * @returns {{editorInstance: *, editorOptions: {height: number, on: {instanceReady: instanceReady}}}}
     */
    scope.ckeditorBase = function(){
      var editor = null;
      var options = {
        height: 300,
        autoGrow_onStartup: true,
        enterMode: CKEDITOR.ENTER_BR,
        on: {
          instanceReady: function(obj) {
            editor = obj.editor;
            this.dataProcessor.htmlFilter.addRules({
              elements: {
                img: function(el) {
                  if(scope.test(el.attributes.src,'base64','i')){
                    el.addClass('image-max-editor');
                    //el.attributes.style = 'max-width:100%';
                  }
                }
              }
            });
          },
          focus: function () {
            // Ferme le popover des tags si ouvert
            $rootScope.$broadcast('closePopoverTag');
            $rootScope.$broadcast('closeTooltipDestinataire');
            $rootScope.$broadcast('closePopoverCategorieTopic');
          }
        }
      }
      return {
        editorInstance: editor,
        editorOptions: options
      };
    };


    /**
     * Permet de fermer un formulaire de contenu (utilisé dans contenu-evenement.component et contenu-message.component)
     * @param item
     */
    scope.fermeFormulaireContenu = function(item){
      item.editer = false;
      item.messageEdite = false;
      item.repondreOpen = false;
      item.repondreAllOpen = false;
      item.editer = false;

      $rootScope.destroyInstandeCKEDITOR(item);
    };

    scope.getEtat = function(type,item) {
      var retour = {};
      retour.rowEtat = '';
      retour.iconEtat = '<span class="glyphicon glyphicon-time"></span>';

      if(type==='devis'){
        // Si accepté
        if (item.isAccepte()) {

          // Si signé
          if (item.isSigne()) {
            retour.rowEtat = 'success';
            retour.iconEtat = '<span class="glyphicon glyphicon-ok text-success"></span>';

            // Si pas encore signé
          }else{
            retour.rowEtat = 'success';
            retour.iconEtat = '<span class="glyphicon glyphicon-time text-success"></span>';
          }


        }
        // Si refusé
        if (item.isRefuse()) {
          retour.rowEtat = 'danger';
          retour.iconEtat = '<span class="glyphicon glyphicon-remove text-danger"></span>';

        }

        // Si selectionne
        if (item.selected) {
          retour.rowEtat = 'warning';
        }

      }else if(type==='interventions'){
        // Si effectué
        if (item.interventionEffectue) {
          retour.rowEtat = 'success';
          retour.iconEtat = '<span class="glyphicon glyphicon-ok text-success"></span>';
        }
      }
      return retour;
    };

    /**
     * Permet de récupérer le nom du collaborateur par rapport à son type
     * @param collaborateur
     * @param parse
     * @returns {*}
     */
    scope.getNom = function(collaborateur,parse){
      var nom = '';
      if(angular.isObject(collaborateur)){
        if(collaborateur.isPerso){
          nom = collaborateur.perso.getNomper();
        }
        else if(collaborateur.isCollab){
          nom = collaborateur.nom;
        }
        else if(collaborateur.isImmeuble){
          nom = collaborateur.immeuble.getNom();
        }
        else if(collaborateur.isPersonnalite){
          nom = collaborateur.getNomper();
        }
        if(parse && nom!==''){
          nom = scope.parseTag(nom).slice(1);
        }
      }

      return nom;
    };

    /**
     * Permet de récupérer le noPerso du collaborateur par rapport à son type
     * @param collaborateur
     * @returns {*}
     */
    scope.getNoPerso = function(collaborateur){
      var noperso = false;
      if(angular.isObject(collaborateur)) {
        if (collaborateur.isPerso || collaborateur.isPersonnalite) {
          noperso = collaborateur.noperso;
        }
        else if (collaborateur.isCollab) {
          noperso = collaborateur.groupe.getNumPerso();
        }
        else if (collaborateur.isImmeuble) {
          noperso = collaborateur.immeuble.getNumImmeuble();
        }
      }

      return noperso;
    };

    scope.isBE = function(){
      return ($rootScope.current.userGroupe.getDieze()==='#TREVI');
    };

    scope.isIcsOrCapfun = function(){
      return (scope.isICS() || scope.isCapfun());
    };

    scope.isICS = function(){
      return ($rootScope.current.userGroupe.getDieze()===COLLAB_CONF.SOCIETE_ICS);
    };

    scope.isAndyOrAurelie = function(){
      return ($rootScope.current.userGroupe.getDieze()===COLLAB_CONF.SOCIETE_ICS && ($rootScope.current.userGroupe.getLibelle()==='@andy' || $rootScope.current.userGroupe.getLibelle()==='@amagrans'));
    };

    scope.isSuperAdmin = function(){
      return ($rootScope.current.userGroupe.isSuperAdmin());
    };

    /**
     * Permet de savoir si le diese est un diese du groupe de société de camping capfun (sans ICS)
     * @returns {*|boolean}
     */
    scope.isCapfun = function(){
      return (scope.isCapfunDiese() || scope.isVaguesOceanesDiese() || scope.isClicochicDiese());
    };

    scope.isCapfunDiese = function(){
      return ($rootScope.current.userGroupe.getDieze()==='#FRANCELOC' || $rootScope.current.userGroupe.getDieze()===COLLAB_CONF.SOCIETE_CAPFUN);
    };
    scope.isVaguesOceanesDiese = function(){
      return $rootScope.current.userGroupe.getDieze()===COLLAB_CONF.SOCIETE_VAGUES_OCEANES;
    };
    scope.isClicochicDiese = function(){
      return $rootScope.current.userGroupe.getDieze()===COLLAB_CONF.SOCIETE_CLICOCHIC;
    };

    scope.itsMe = function(groupe){
      if(_.isObject(groupe)) {
        if($rootScope.current.userGroupe.getIdGroupe() === groupe.idGroupe) return true;
        if($rootScope.current.userGroupe.getLibelle() === groupe.libelle && $rootScope.current.userGroupe.getDieze() === groupe.dieze) return true;
      }
      return false;
    };

    scope.destinataireIsEqual = function(destinataire, otherDestinataire){
      var equal = false;
      if(_.isObject(destinataire) && _.isObject(otherDestinataire)){
        // Si le nouveau destinataires n'est pas un email
        if(!destinataire.isMail) {
          // Si le current destinataire a un idGroupe et que le new aussi
          if(_.isNumber(otherDestinataire.idGroupe) && _.isNumber(destinataire.idGroupe) && otherDestinataire.idGroupe === destinataire.idGroupe) {
            equal = true;
          }
          // Sinon teste le libelle et le dieze
          else if(_.isString(otherDestinataire.libelle) && _.isString(otherDestinataire.dieze) && otherDestinataire.libelle === destinataire.libelle && otherDestinataire.dieze === destinataire.dieze) {
            equal = true;
          }
          // Si le current destinataire a un destinataireEmail
          else if(_.isNumber(otherDestinataire.idDestinataireEmail) && _.isNumber(destinataire.idDestinataireEmail) && otherDestinataire.idDestinataireEmail === destinataire.idDestinataireEmail) {
            equal = true;
          }
        }
        // Si le nouveau destinataire est un email
        else if(destinataire.isMail && _.isString(otherDestinataire.libelle) && _.isString(destinataire.libelle) && otherDestinataire.libelle === destinataire.libelle) {
          equal = true;
        }
      }
      return equal;
    };


    /**
     * Permet de récupérer le portefeuille ICS si il existe dans la liste des portefeuilles
     * @param listePortefeuilles
     * @returns {boolean}
     */
    scope.getPortefeuilleICS = function(listePortefeuilles){
      var portefeuilleRetour = false;
      if(scope.isICS()) {
        if(!listePortefeuilles) {
          listePortefeuilles = $rootScope.current.userGroupe.getListePortefeuille();
        }
        else if(!angular.isArray(listePortefeuilles)) {
          return portefeuilleRetour;
        }
        for(var i = 0; i < listePortefeuilles.length; i++){
          var portefeuille = listePortefeuilles[i];
          // Si le nom du portefeuille est ICS
          if (portefeuille.nom === COLLAB_CONF.NOM_PORTEFEUILLE_ICS) {
            portefeuilleRetour = portefeuille;
            break;
          }
        }
      }
      return portefeuilleRetour;
    };

    scope.getPortefeuilles = function(){
      var deferred = $q.defer();
      UserResource.getPortefeuilles(function(json){
        if(json.nb!=='0'){
          $rootScope.hasPortefeuille = true;
          deferred.resolve(json.Portefeuilles);
        }
        else{
          $rootScope.hasPortefeuille = false;
          deferred.reject(json);
        }
      });
      return deferred.promise;
    };

    scope.getPortefeuille = function(idPortefeuille){
      idPortefeuille = parseInt(idPortefeuille);
      var deferred = $q.defer();
      var portefeuille = null;
      var PortefeuilleModel = null;
      if (idPortefeuille) {
        if (!_.isObject(idPortefeuille)) {

          if(scope.portefeuilles.hasOwnProperty(idPortefeuille)) deferred.resolve(scope.portefeuilles[idPortefeuille]);
          else if(_.isArray(scope.getCurrentUser().getListePortefeuille()) && scope.getCurrentUser().getListePortefeuille().length) {
            for(var i = 0; i < scope.getCurrentUser().getListePortefeuille().length; i++){
              if(scope.getCurrentUser().getListePortefeuille()[i].idPortefeuille === idPortefeuille) {
                portefeuille = scope.getCurrentUser().getListePortefeuille()[i];
                break;
              }
            }
          }

          if(!_.isNil(portefeuille)) deferred.resolve(portefeuille);
          else {
            scope.getPortefeuilles()
              .then(function(portefeuilles) {

                if(portefeuilles.length) {
                  for(var p = 0; p < portefeuilles.length; p++) {
                    if(portefeuilles[p].idPortefeuille === idPortefeuille) {
                      PortefeuilleModel = $injector.get('Portefeuille');
                      portefeuille = new PortefeuilleModel(portefeuilles[p]);
                      scope.portefeuilles[idPortefeuille] = portefeuille;
                      break;
                    }
                  }
                  PortefeuilleModel = null;
                }

                if(!_.isNil(portefeuille)) deferred.resolve(portefeuille);
                else deferred.reject('Pas de portefeuille');
              })
              .catch(function(msg) {
                deferred.reject(msg);
              });
          }
        }
        else deferred.resolve(idPortefeuille);
      }
      else deferred.reject('Pas de portefeuille');

      return deferred.promise;
    };

    scope.getCurrentUser = function(){
      return $rootScope.current.userGroupe;
    };

    scope.getCurrentFiltreTopics = function(){
      return $rootScope.current.filtreTopics;
    };

    scope.getStateHomeCollab = function(){
      var stateHomeCollab = scope.getParametrage(COLLAB_CONF.NAME_PARAM_HOME_COLLAB);
      if ($rootScope.openInVisicop || _.isNil(stateHomeCollab) || !scope.contains(COLLAB_CONF.ARRAY_NAME_PARAM_HOME_COLLAB_ALLOWED, stateHomeCollab)) {
        stateHomeCollab = COLLAB_CONF.HOME_STATE;
      }
      return stateHomeCollab;
    };

    scope.getStateHomeCollabParams = function(){
      var channels = $rootScope.current.userGroupe.getListeChannel();
      var idAccueil = null;
      if(_.isArray(channels) && channels.length) {
        for(var i = 0; i < channels.length; i++){
          if(channels[i].getType().isAccueil()){
            idAccueil = channels[i].getIdChannel();
            break;
          }
        }
        if(!_.isNil(idAccueil)) return idAccueil;
      }
      return null;
    };

    scope.getParametrageComfact = function(name){
      if(scope.getCurrentUser()) {
        var defaultParametrage = scope.getCurrentUser().getDefaultParametrageComfact();
        if(defaultParametrage){
          if(defaultParametrage.hasOwnProperty(name)){
            var methode = 'get'+name.ucfirst();
            if(angular.isFunction(defaultParametrage[methode])){
              return defaultParametrage[methode]();
            }
          }
        }
      }
      return null;
    };

    scope.getParametrageComfactPerso = function(name){
      var defaultParametrage = scope.getCurrentUser().getParametrageComfact();
      if(defaultParametrage){
        if(defaultParametrage.hasOwnProperty(name)){
          var methode = 'get'+name.ucfirst();
          if(angular.isFunction(defaultParametrage[methode])){
            return defaultParametrage[methode]();
          }
        }
      }
      return null;
    };

    scope.getParametrageComfactSociete = function(name){
      var defaultParametrage = scope.getCurrentUser().getParametrageComfactSociete();
      if(defaultParametrage){
        if(defaultParametrage.hasOwnProperty(name)){
          var methode = 'get'+name.ucfirst();
          if(angular.isFunction(defaultParametrage[methode])){
            return defaultParametrage[methode]();
          }
        }
      }
      return null;
    };

    scope.getParametrage = function(name){
      if(scope.getCurrentUser()) {
        var defaultParametrage = scope.getCurrentUser().getDefaultParametrage();
        if(defaultParametrage){
          if(defaultParametrage.hasOwnProperty(name)){
            var methode = 'get'+name.ucfirst();
            if(angular.isFunction(defaultParametrage[methode])){
              return defaultParametrage[methode]();
            }
          }
        }
      }
      return null;
    };

    scope.getParametragePerso = function(name){
      var defaultParametrage = scope.getCurrentUser().getParametrage();
      if(defaultParametrage){
        if(defaultParametrage.hasOwnProperty(name)){
          var methode = 'get'+name.ucfirst();
          if(angular.isFunction(defaultParametrage[methode])){
            return defaultParametrage[methode]();
          }
        }
      }
      return null;
    };

    scope.getParametrageSociete = function(name){
      var defaultParametrage = scope.getCurrentUser().getParametrageSociete();
      if(defaultParametrage){
        if(defaultParametrage.hasOwnProperty(name)){
          var methode = 'get'+name.ucfirst();
          if(angular.isFunction(defaultParametrage[methode])){
            return defaultParametrage[methode]();
          }
        }
      }
      return null;
    };

    scope.parseDateForProperty = function(property, obj, enableFormatDate){
      var date = null;
      if(obj.hasOwnProperty(property)){
        date = angular.copy(obj[property]);
      }

      if(date !== null && enableFormatDate){
        var dates = date.split(' ');
        date = moment(dates[0]).format('DD/MM/YYYY');
      }
      return date;
    };

    scope.parseDate = function(date, format){
      if(date){
        if(format === true) {
          date = new Date(date);
        }
        else {
          var paramDateMoment = date;
          if(!moment.isMoment(paramDateMoment)) paramDateMoment = moment(paramDateMoment);

          if(format === false || angular.isUndefined(format) || format == null) {
            format = 'DD/MM/YYYY';
          }
          // Si il y a l'année dans le format
          else if(scope.test(format, ' YYYY', 'g')) {
            // Si l'année actuel est la meme que la date qui va etre retourné on va retourner sans l'année
            if(moment().format('YYYY') === paramDateMoment.format('YYYY')) {
              format = scope.replaceAll('YYYY','', format);
            }
          }
          date = paramDateMoment.format(format);
        }
      }
      return date;
    };

    scope.parseDateHeure = function(date, format){
      if(date){
        if(format == true) {
          date = new Date(date);
        }
        else {
          if(format === false || angular.isUndefined(format) || format == null) {
            format = 'DD/MM/YYYY HH:mm';
          }
          date = moment(date).format(format);
        }
      }
      return date;
    };

    scope.dateMomentParser = function (paramDate, format) {

      var todayMoment = moment();
      var paramDateMoment = moment(paramDate);

      if(paramDateMoment.isValid()) {
        // Si aujourd'hui retourne juste l'heure
        if(todayMoment.format('MDYYYY') === paramDateMoment.format('MDYYYY')) return 'Aujourd\'hui à ' + paramDateMoment.format('HH:mm');

        // Sinon retourne le jour et l'heure
        else {
          var date = null;

          var formatSameYear = 'D MMM';
          var formatPreviousYear = 'D MMM YYYY';
          if(format) {
            formatSameYear = format;
            formatPreviousYear = format + ' YYYY';
            date = '';
          }
          else {
            date = paramDateMoment.format('ddd').ucfirst() + ' ';
          }

          // Si la même année
          if(todayMoment.format('YYYY') === paramDateMoment.format('YYYY')) date += paramDateMoment.format(formatSameYear);
          else date += paramDateMoment.format(formatPreviousYear);

          return date + ' ' + paramDateMoment.format('HH:mm');
        }
      }
      else return paramDate;
    };

    scope.openCommunication = function(event){
      var deferred = $q.defer();

      if(angular.isObject(event)){


        var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: 'app/topics/modal/modal-communication.html',
          controller: 'ModalCommunicationCtrl',
          controllerAs: 'modalcommunicationctrl',
          size: 'full',
          windowClass: 'modal-collabore modal-annuaire-collab',
          backdrop: 'static',
          resolve: {
            idTopic: function() {
              return event.idTopic;
            }
          }
        });

        //modalInstance.opened.then(function() {
        //});

        modalInstance.result.then(function(objRetour) {
          deferred.resolve(objRetour);
        }, function () {
          deferred.reject();
        });
      }else{
        deferred.reject('Le paramètre doit être un objet');
      }

      return deferred.promise;
    };


    scope.ifResultatJSONSuccess = function(json){
      var retour = false;
      if(json.nb!=='0'){
        if(json.hasOwnProperty('Resultats')){
          if(json.Resultats[0].success){
            retour = true;
          }
        }else{
          retour = true;
        }
      }
      return retour;
    };

    scope.getMessageFromResultatsJSON = function(json){
      if(json.nb!=='0' && _.isArray(json.Resultats) && !_.isNil(json.Resultats[0])){
        if(!json.Resultats[0].success && !_.isNil(json.Resultats[0].message)){
          return json.Resultats[0].message;
        }
      }
      return 'Il y a eu un problème...';
    };

    scope.initChamp = function(objDest, objSource, property) {
      var variable = null;
      if (objSource.hasOwnProperty(property)) {   variable = objSource[property];}
      objDest[property] = variable;
    };

    scope.setChamp = function(objDest, objSource, property) {
      var variable = null;
      if (objSource.hasOwnProperty(property)) {   variable = objSource[property];}
      objDest['set' + property.ucfirst()](variable);
    };

    scope.propertyValidForObject = function(obj, property) {
      var retour = false;
      if(obj.hasOwnProperty(property) && obj[property] != '' && obj[property] != undefined) {
        retour = true;
      }
      return retour;
    };

    scope.genereLibelleClientCapfun = function(obj){
      var libelle = null;
      if(_.isObject(obj) && obj.model === "LotMulticamp") {
        if(obj.model === "LotMulticamp") libelle = "client_capfun_" + obj.getIdPerson();
        else libelle = "client_capfun_" + obj.idPerson;
      }
      return "@" + libelle;
    };

    scope.genereYopmailClientCapfun = function(obj){
      var email = scope.genereLibelleClientCapfun(obj);
      if(_.isNil(email)) email = uuid.new();
      else email = scope.ltrim(email, "@");
      return email + "@yopmail.com";
    };

    scope.genereEmailJetable = function(obj){
      var email = '';
      if(angular.isObject(obj)){
        var perso = obj;
        var isFournisseur = false;
        if(obj.isPerso || obj.isPersonnalite) {
          if(obj.isFournisseur) {
            isFournisseur = true;
          }
        }

        if(obj.hasOwnProperty('perso')){
          perso = obj.perso;
        }

        if(perso.hasOwnProperty('noperso')){
          email += perso.noperso+'.';
        }

        // Si ce n'est pas un fournisseur
        if(!isFournisseur){
          if(perso.hasOwnProperty('nomper') && perso.nomper != '') {
            email += scope.cleanSpecialChar(perso.nomper)+'.';
          }
          else if(perso.hasOwnProperty('nompr') && perso.nompr != '') {
            email += scope.cleanSpecialChar(perso.nompr)+'.';
          }
        }
        // Si c'est un fournisseur
        else {
          if(perso.hasOwnProperty('numero')){
            email += perso.numero+'.';
          }
          if(perso.hasOwnProperty('metier')){
            email += perso.metier+'.';
          }
        }


        if(email.length){
          email = email.slice(0,-1);
        }
      }
      if(!email.length){
        email = uuid.new();
      }
      return email+'@courriel.fr.nf';
    };


    /**
     * Permet de recup un param dans le stateparam de la vue
     * @param key
     * @returns {boolean}
     */
    scope.getParam = function(key){
      var retour = null;
      if(!$stateParams.hasOwnProperty(key)) return retour;

      if(_.isArray($stateParams[key]) && $stateParams[key].length) retour = $stateParams[key][0];
      else retour = $stateParams[key];

      return retour;
    };

    scope.genereObjetAnnuaire = function(obj){

      var selected = null,
        objOrigine = null,
        retour = {
          objAnnuaire: {
            origine: null,
            projet: false,
            mandat: false,
            immeuble: false,
            portefeuille: false,
            lot: false,
            coproprietaire: false,
            proprietaire: false,
            locataire: false,
            fournisseur: false,
            salarie: false,
            bail: null,
            contrat: false,
            type: false,
            lotMulticamp: false,
            lieuCommunCapfun: false
          }
        };

      try {

        if(!_.isObject(obj)) throw new Error('Le paramètre doit être un Object !');

        // Si l'origine est un object
        if(_.isObject(obj.origine)) {
          if(obj.origine.isCollaborateur) {
            obj.collaborateur = angular.copy(obj.origine);
            if(obj.origine.isPerso) objOrigine = selected = angular.copy(obj.origine.perso);
            else if(obj.origine.isCollab) objOrigine = angular.copy(obj.origine.groupe);
            else if(obj.origine.isLotMulticamp) objOrigine = selected = angular.copy(obj.origine.lotMulticamp);
            else if(obj.origine.isLieuCommunCapfun) objOrigine = selected = angular.copy(obj.origine.lieuCommunCapfun);
          }
          else if(_.isObject(obj.origine.personnalite)){
            objOrigine = selected = angular.copy(obj.origine.personnalite);
          }

          // Si pas d'obj origine ET que l'origine est un Model
          if((_.isNil(objOrigine) || !objOrigine) && obj.origine.isModel) {
            objOrigine = angular.copy(obj.origine);
            selected = angular.copy(obj.origine);
          }
        }
        // si l'origine est un String
        else if(_.isString(obj.origine)) objOrigine = angular.copy(obj.origine);

        retour.objAnnuaire.origine = obj.origine;
        retour.objAnnuaire.bail = obj.bail;

        // Si le current est un object
        if(_.isObject(obj.current)){
          if(obj.current.isMandat)
            selected = obj.current.num;

          else if(obj.current.isModel)
            selected = obj.current;
        }

        if(_.isObject(obj.collaborateur)) {

          if(obj.collaborateur.hasOwnProperty('portefeuille'))
            retour.objAnnuaire.portefeuille = obj.collaborateur.portefeuille;

          if(_.isObject(obj.collaborateur.immeuble)){
            delete obj.collaborateur.immeuble.collapsed;
            delete obj.collaborateur.immeuble.metiers;
            retour.objAnnuaire.immeuble = obj.collaborateur.immeuble;
          }

          if(obj.collaborateur.isPerso)
            retour.objAnnuaire.type = 'ICS';

          else if(obj.collaborateur.isCollab)
            retour.objAnnuaire.type = 'Collab';

          else if(obj.collaborateur.isImm)
            retour.objAnnuaire.type = 'Immeuble';

          else if(obj.collaborateur.isLotMulticamp || obj.collaborateur.isLieuCommunCapfun)
            retour.objAnnuaire.type = 'Capfun';
        }
        else {
          if(obj.portefeuille)
            retour.objAnnuaire.portefeuille = obj.portefeuille;
        }

        if(obj.lot)
          retour.objAnnuaire.lot = obj.lot;

        if(obj.immeuble)
          retour.objAnnuaire.immeuble = obj.immeuble;

        if(_.isObject(obj.mandat) && _.isString(obj.mandat.num))
          retour.objAnnuaire.mandat = obj.mandat.num;

        if(_.isObject(objOrigine)) {
          // Si mandat
          if(_.isString(selected)) {
            retour.objAnnuaire.projet = 'mandat';
            retour.objAnnuaire.mandat = selected;
            retour.objAnnuaire.proprietaire = objOrigine;
            retour.objAnnuaire.typePerso = 'Proprietaire';

          }
          else if(_.isObject(selected)) {

            if(selected.isLot){
              retour.objAnnuaire.projet = 'lot';
              retour.objAnnuaire.lot = selected;
              if(selected.getType()==='Locataire' || selected.getType()==='Colocataire'){
                retour.objAnnuaire.mandat = selected.getNumMandat();
                retour.objAnnuaire.proprietaire = selected.getProprietaire();
                retour.objAnnuaire.locataire = objOrigine;
                retour.objAnnuaire.typePerso = 'Locataire';
              }
              else if(selected.getType()==='Proprietaire'){
                retour.objAnnuaire.mandat = selected.getNumMandat();
                retour.objAnnuaire.proprietaire = objOrigine;
                retour.objAnnuaire.typePerso = 'Proprietaire';
                if(_.isObject(obj.bail) && obj.bail.isBail)
                  retour.objAnnuaire.locataire = obj.bail.getPersonnalite();
              }
            }
            else if(selected.isImmeuble){
              retour.objAnnuaire.projet = 'immeuble';
              retour.objAnnuaire.immeuble = selected;
              retour.objAnnuaire.typePerso = 'Coproprietaire';
              if(_.isObject(objOrigine) && objOrigine.model !== 'Immeuble') {
                // Si il y a un typePerso indiqué alors on le prend
                if(_.isString(obj.typePerso) && obj.typePerso === 'proprietaire' && objOrigine.isProprietaire()) {
                  retour.objAnnuaire.proprietaire = objOrigine;
                  retour.objAnnuaire.typePerso = 'Proprietaire';
                }
                // Sinon si l'origine n'est pas l'immeuble, que c'est un copro
                else if(objOrigine.isCoproprietaire()) retour.objAnnuaire.coproprietaire = objOrigine;
              }
            }
            else if(selected.isPersonnalite){
              if(selected.isProprietaire()){
                retour.objAnnuaire.projet = 'proprietaire';
                retour.objAnnuaire.proprietaire = selected;
                retour.objAnnuaire.typePerso = 'Proprietaire';
              }
              else if(selected.isCoproprietaire()){
                if(_.isObject(obj.collaborateur) && obj.collaborateur.isImm){
                  retour.objAnnuaire.projet = 'immeuble';
                }
                else {
                  retour.objAnnuaire.projet = 'coproprietaire';
                }
                //retour.objAnnuaire.projet = 'immeuble';
                retour.objAnnuaire.coproprietaire = selected;
                retour.objAnnuaire.typePerso = 'Coproprietaire';
              }
              else if(selected.isLocataire() || selected.isColocataire()){
                retour.objAnnuaire.projet = 'locataire';
                retour.objAnnuaire.locataire = selected;
                retour.objAnnuaire.typePerso = 'Locataire';
              }
              else if(selected.isFournisseur()){
                delete objOrigine.metiers;
                retour.objAnnuaire.projet = 'fournisseur';
                retour.objAnnuaire.fournisseur = selected;
              }
            }
            else if(selected.isSalarie){
              retour.objAnnuaire.projet = 'immeuble';
              retour.objAnnuaire.salarie = selected;
            }
            else if(selected.isContrat){
              retour.objAnnuaire.projet = 'contrat';
              retour.objAnnuaire.contrat = selected;
            }
            else if(selected.isLotMulticamp){
              retour.objAnnuaire.projet = 'mh';
              retour.objAnnuaire.lotMulticamp = selected;
              if(selected.getIdPerson()) {
                retour.objAnnuaire.clientCapfun = {
                  idPerson: selected.getIdPerson(),
                  nom: selected.getNom(),
                  telephones: selected.getTelephones()
                };
              }
            }
            else if(selected.isLieuCommunCapfun){
              retour.objAnnuaire.projet = 'lieu';
              retour.objAnnuaire.lieuCommunCapfun = selected;
            }
          }
          else {
            if(_.isObject(obj.collaborateur) && obj.collaborateur.hasOwnProperty('tagProjet'))
              retour.objAnnuaire.projet = obj.collaborateur.tagProjet;
          }
        }

        if(_.isObject(obj.origine)){
          if(!retour.objAnnuaire.proprietaire && !retour.objAnnuaire.coproprietaire && !retour.objAnnuaire.locataire) {

            // Si bail indefini ou à false
            if(_.isNil(retour.objAnnuaire.bail) || !retour.objAnnuaire.bail) {

              // Si l'origine est à la fois propriétaire et coproproprietaire
              if (obj.origine.isProprietaire && obj.origine.isCopro) {
                // Alors c'est un proprietaire
                if (obj.origine.isProprietaire && !retour.objAnnuaire.proprietaire) {
                  retour.objAnnuaire.proprietaire = objOrigine;
                  retour.objAnnuaire.typePerso = 'Proprietaire';
                }
              }
              // Si l'origine est un coproproprietaire
              else if (obj.origine.isCopro && !retour.objAnnuaire.coproprietaire) {

                retour.objAnnuaire.coproprietaire = objOrigine;
                retour.objAnnuaire.typePerso = 'Coproprietaire';

                // Si l'origine est un proprietaire
              } else if (obj.origine.isProprietaire && !retour.objAnnuaire.proprietaire){
                retour.objAnnuaire.proprietaire = objOrigine;
                retour.objAnnuaire.typePerso = 'Proprietaire';

              }
              // Si l'origine est un fournisseur
              else if(obj.origine.isFournisseur && !retour.objAnnuaire.fournisseur)
                retour.objAnnuaire.fournisseur = objOrigine;

            }
            // Si bail définit alors c'est un propriétaire
            else {
              if(obj.origine.isProprietaire && !retour.objAnnuaire.proprietaire){
                retour.objAnnuaire.proprietaire = objOrigine;
                retour.objAnnuaire.typePerso = 'Proprietaire';
              }
            }
          }
        }
      }
      catch(e){
        $log.error('Erreur [UtilsService.genereObjetFromAnnuaire] : ' + e.message);
      }
      return retour;
    }

    /*
    scope.genereObjetFromAnnuaire = function(origine,collaborateur,item,immeuble,lot,bail, portefeuille){

        var selected,objOrigine = false;

        if(origine.hasOwnProperty('isCollaborateur')) {
            if(origine.isCollaborateur) {
                collaborateur = angular.copy(origine);
                if(origine.isPerso) objOrigine = angular.copy(origine.perso);
                else if(origine.isCollab)objOrigine = angular.copy(origine.groupe);
            }
        }

        // Si pas d'obj origine ET que l'origine est un Model
        if(!objOrigine && origine.isModel) {
            objOrigine = angular.copy(origine);
            selected = angular.copy(origine);
        }
        // si l'origine est un String
        else if(angular.isString(origine)) {
            objOrigine = angular.copy(origine);
        }

        var retour = {
            objAnnuaire: {
                origine: origine,
                projet: false,
                mandat: false,
                immeuble: false,
                portefeuille: false,
                lot: false,
                coproprietaire: false,
                proprietaire: false,
                locataire: false,
                fournisseur: false,
                salarie: false,
                bail: bail,
                contrat: false,
                type: false
            }
        };

        if(item){
            if(angular.isObject(item)) {
                if(item.isMandat) {
                    selected = item.num;
                }
                else if(item.isModel) {
                    selected = item;
                }
            }
        }

        if(collaborateur) {
            if(collaborateur.hasOwnProperty('portefeuille')){
                retour.objAnnuaire.portefeuille = collaborateur.portefeuille;
            }
            if(collaborateur.hasOwnProperty('immeuble')){
                delete collaborateur.immeuble.collapsed;
                delete collaborateur.immeuble.metiers;
                retour.objAnnuaire.immeuble = collaborateur.immeuble;
            }
            if(collaborateur.isPerso)       retour.objAnnuaire.type = 'ICS';
            else if(collaborateur.isCollab) retour.objAnnuaire.type = 'Collab';
            else if(collaborateur.isImm)    retour.objAnnuaire.type = 'Immeuble';
        }
        else {
            if(portefeuille) retour.objAnnuaire.portefeuille = portefeuille;
        }

        if(lot)         retour.objAnnuaire.lot = lot;
        if(immeuble)    retour.objAnnuaire.immeuble = immeuble;

        if(angular.isObject(objOrigine)) {
            // Si mandat
            if(angular.isString(selected)) {
                retour.objAnnuaire.projet = 'mandat';
                retour.objAnnuaire.mandat = selected;
                retour.objAnnuaire.proprietaire = objOrigine;
            }
            else if(angular.isObject(selected)) {

                if(selected.isLot){
                    retour.objAnnuaire.projet = 'lot';
                    retour.objAnnuaire.lot = selected;
                    if(selected.getType()==='Locataire' || selected.getType()==='Colocataire'){
                        retour.objAnnuaire.mandat = selected.getNumMandat();
                        retour.objAnnuaire.proprietaire = selected.getProprietaire();
                        retour.objAnnuaire.locataire = objOrigine;
                    }
                    else if(selected.getType()==='Proprietaire'){
                        retour.objAnnuaire.mandat = selected.getNumMandat();
                        retour.objAnnuaire.proprietaire = objOrigine;
                        if(angular.isObject(bail)) {
                            if(bail.isBail) {
                                retour.objAnnuaire.locataire = bail.getPersonnalite();
                            }
                        }
                    }
                }
                else if(selected.isImmeuble){
                    retour.objAnnuaire.projet = 'immeuble';
                    retour.objAnnuaire.immeuble = selected;
                    if(angular.isObject(objOrigine)){
                        if(objOrigine.model !== 'Immeuble') {
                            if(objOrigine.isCoproprietaire()){
                                retour.objAnnuaire.coproprietaire = objOrigine;
                            }
                        }
                    }
                }
                else if(selected.isPersonnalite){
                    if(selected.isProprietaire()){
                        retour.objAnnuaire.projet = 'proprietaire';
                        retour.objAnnuaire.proprietaire = selected;
                    }
                    else if(selected.isCoproprietaire()){
                        retour.objAnnuaire.projet = 'immeuble';
                        retour.objAnnuaire.coproprietaire = selected;
                    }
                    else if(selected.isLocataire() || selected.isColocataire()){
                        retour.objAnnuaire.projet = 'locataire';
                        retour.objAnnuaire.locataire = selected;
                    }
                    else if(selected.isFournisseur()){
                        delete objOrigine.metiers;
                        retour.objAnnuaire.projet = 'fournisseur';
                        retour.objAnnuaire.fournisseur = selected;
                    }
                }
                else if(selected.isSalarie){
                    retour.objAnnuaire.projet = 'immeuble';
                    retour.objAnnuaire.salarie = selected;
                }
                else if(selected.isContrat){
                    retour.objAnnuaire.projet = 'contrat';
                    retour.objAnnuaire.contrat = selected;
                }
            }
            else{
                if(collaborateur && collaborateur.hasOwnProperty('tagProjet')){
                    retour.objAnnuaire.projet = collaborateur.tagProjet;
                }
            }
        }

        if(angular.isObject(origine)){
            if(!retour.objAnnuaire.proprietaire && !retour.objAnnuaire.coproprietaire && !retour.objAnnuaire.locataire) {

                // Si bail indefini ou à false
                if(angular.isUndefined(retour.objAnnuaire.bail) || !retour.objAnnuaire.bail) {

                    // Si l'origine est à la fois propriétaire et coproproprietaire
                    if(origine.isProprietaire && origine.isCopro){
                        // Alors c'est un proprietaire
                        if(origine.isProprietaire && !retour.objAnnuaire.proprietaire) {
                            retour.objAnnuaire.proprietaire = objOrigine;
                        }
                    }
                    // Si l'origine est un coproproprietaire
                    else if(origine.isCopro && !retour.objAnnuaire.coproprietaire) {
                        retour.objAnnuaire.coproprietaire = objOrigine;
                    }
                    // Si l'origine est un proprietaire
                    else if(origine.isProprietaire && !retour.objAnnuaire.proprietaire) {
                        retour.objAnnuaire.proprietaire = objOrigine;
                    }
                    // Si l'origine est un fournisseur
                    else if(origine.isFournisseur && !retour.objAnnuaire.fournisseur) {
                        retour.objAnnuaire.fournisseur = objOrigine;
                    }
                }
                // Si bail définit alors c'est un propriétaire
                else {
                    if(origine.isProprietaire && !retour.objAnnuaire.proprietaire) retour.objAnnuaire.proprietaire = objOrigine;
                }
            }
        }
        return retour;
    };
    */

    scope.resetStateParamsAction = function(){
      $stateParams.action = null;
      $stateParams.nofourni = null;
      $stateParams.noperso = null;
      $stateParams.immeuble = null;
      $stateParams.mandat = null;
      $stateParams.lot = null;
      $stateParams.locataire = null;
      $stateParams.origine = null;
      $stateParams.inv = null;
      $stateParams.portefeuille = null;
      $stateParams.idPortefeuille = null;
    };

    /**
     * Parmet de recupérer une data dans un objet "params" pour les Modal avec composant (ModalsService.modalComponent)
     * @param resolve
     * @param param
     * @param defaultVal
     * @returns {*}
     */
    scope.getParamModalComponent = function(resolve, param, defaultVal){
      var valeur = null;
      if(_.isObject(resolve) && resolve.hasOwnProperty('params') && _.isObject(resolve.params)) {
        if(resolve.params.hasOwnProperty(param) && !_.isNil(resolve.params[param])){
          valeur = resolve.params[param];
        }
      }
      if(valeur == null && defaultVal) return defaultVal;
      return valeur;
    };

    /**
     * Permet de tester si l'item est une image
     * @param item
     * @returns {boolean}
     */
    scope.isImage = function(item) {
      var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
      return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
    };

    scope.extensionIsImage = function (item) {
      var retour = false;
      if(item){
        var dotIndex = item.lastIndexOf('.');
        var ext = item.substring(dotIndex);
        retour = (/\.(gif|jpg|jpeg|tiff|png)$/i).test(ext);
      }
      return retour;
    };

    scope.getInfosEvenement = function(evenement){
      var infosEvenement = {
        numMandat: null,
        numImmeuble: null,
        noPersoCopro: null,
        portefeuille: null,
        tagPortefeuille: null,
        isGestion: false,
        listDestinatairesHide: [],
        infosObjet : null,
        noPersoProprietaire: null
      };

      if(_.isObject(evenement)) {

        infosEvenement.infosObjet = {
          idTopic: evenement.getIdTopic(),
          idContenu: evenement.getIdContenu(),
          destinataires: evenement.getDestinataires(),
          objet: evenement.getObjet(),
          tagsType: evenement.getTagsType(),
        };

        var tagMandat = evenement.getTagForType(COLLAB_CONF.TAG_TYPE_MANDAT);
        var tagImmeuble = evenement.getTagForType(COLLAB_CONF.TAG_TYPE_IMMEUBLE);
        var tagPortefeuille = evenement.getTagForType(COLLAB_CONF.TAG_TYPE_PORTEFEUILLE);
        var tagCopro = evenement.getTagForType(COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE);
        var tagProp = evenement.getTagForType(COLLAB_CONF.TAG_TYPE_PROPRIETAIRE);

        if (tagMandat || tagImmeuble || tagPortefeuille) {
          if (tagMandat) infosEvenement.numMandat = tagMandat.getValeurIcs();
          if (tagImmeuble) infosEvenement.numImmeuble = tagImmeuble.getValeurIcs();
          if (tagCopro) infosEvenement.noPersoCopro = tagCopro.getValeurIcs();
          if (tagProp) infosEvenement.noPersoProprietaire = tagProp.getValeurIcs();
          if (tagPortefeuille) {
            infosEvenement.tagPortefeuille = tagPortefeuille;
            infosEvenement.portefeuille = tagPortefeuille.portefeuille
            if(!_.isObject(infosEvenement.portefeuille)){
              infosEvenement.portefeuille = {
                nom: tagPortefeuille.getLibelle(),
                idPortefeuille: tagPortefeuille.getValeurIcs(),
              };
            }
          }
          infosEvenement.listDestinatairesHide = scope.getDestinatairesHideFromEvenement(evenement);
        }

        // Si il y a un tag "Propriétaire" alors c'est un événement de gestion
        if(evenement.getTagForType(COLLAB_CONF.TAG_TYPE_PROPRIETAIRE)) infosEvenement.isGestion = true;
      }


      return infosEvenement;
    };

    /**
     * Permet de récupérer des destinataires qui seront caché à partir de l'événément
     * @param listDest
     * @returns {*}
     */
    scope.getDestinatairesHideFromEvenement = function(event){
      var listDestinatairesHide = [];
      if(_.isObject(event) && _.isArray(event.getDestinataires()) && event.getDestinataires().length) {
        event.getDestinataires().map(function(groupe) {
          // Si pas groupe MCS,Prop,Loca etc... et pas gorupe user on prend
          //if(groupe.isAutre() && !groupe.isUser() && groupe.isMySociete())
          if(!groupe.itsMe())
            listDestinatairesHide = _.union(listDestinatairesHide, [groupe]);
        });
      }
      return listDestinatairesHide;
    };

    /**
     * Permet d'afficher l'élément si ce n'est pas en version mobile ou si on le force par paramètre
     * @returns {boolean}
     */
    scope.notMobile = function(){
      return (!$rootScope.versionMobile || $rootScope.forceViewMobileOld);
    };

    /**
     * Permet d'afficher l'élément si ce n'est pas en version mobile ou si on le force par paramètre
     * @returns {boolean}
     */
    scope.onMobile = function(){
      return ($rootScope.versionMobile && !$rootScope.forceViewMobileOld);
    };

    scope.goRouteAskedOrLogin = function(toState, toParams, reload){
      //console.log(toState);
      //console.log(toParams);
      var goHome = true;
      var params = null;
      var listRouteNotAllowedCamping = ['recapEnvois', 'factures', 'dashboard.reportingCamping'];

      if(_.isObject($rootScope.prevToStateBeforeLogout) && _.isObject($rootScope.prevToParamsBeforeLogout)) {
        toState = $rootScope.prevToStateBeforeLogout;
        toParams = $rootScope.prevToParamsBeforeLogout;
        reload = true;
      }



      // Si url demandée est login ou / alors redirige sur la liste de stopics
      if (_.isObject(toState) && toState.name !== 'login' && toState.url !== '^') goHome = false;

      // Si c'est un camping et qu'il veut accéder à la liste des envoies
      if($rootScope.camping && listRouteNotAllowedCamping.indexOf(toState.name) !== -1) goHome = true;

      if(goHome) {
        var FiltreService = $injector.get('FiltreService');
        params = FiltreService.getObjFiltreByChannel(scope.getStateHomeCollabParams());
        if(_.isObject(toParams) && toParams.action === 'rechercheAnnuaire') {
          _.merge(params, toParams);
        }
        //if(!_.isNil(toParams)) toParams.idPortefeuille
        $state.go(scope.getStateHomeCollab(), params, {reload: true, notify: true, location: true});
        FiltreService = null;
      }
      else if(reload) {

        if(_.isNil(toParams)) toParams = toState.params;
        if(_.isObject(toParams)) {
          params = {};
          angular.forEach(toParams, function(value, key) {
            if(_.isObject(value) && _.isFunction(value.$$fn)) {
              params[key] = value.$$fn();
            }
            else params[key] = value;
          });
        }
        $state.go(toState.name, params, {reload: true, notify: true, location: true});
      }

      $rootScope.prevToStateBeforeLogout = null;
      $rootScope.prevToParamsBeforeLogout = null;
    };

    /**
     * Permet de modifier/ajouter des contenu d'une liste à partir d'une liste de contenu ou d'un contenu
     * @param contenuOrListContenu : Nouveaux contenus ou contenu(s) modifié
     * @param listContenu : Liste des contenu à checker
     */
    scope.addOrUpdateListContenuOrContenuInListContenu = function(contenuOrListContenu, listContenu){
      if(_.isArray(listContenu) && listContenu.length) {
        var found, d;
        if(_.isArray(contenuOrListContenu)) {
          // Parcour la liste des nouveaux contenu ou contenu modifiés
          for(var c = 0; c < contenuOrListContenu.length; c++) {
            found = false;
            // Parcour la liste des contenus à modifier
            for(d = 0; d < listContenu.length; d++) {
              // Si contenu trouvé
              if(contenuOrListContenu[c].idContenu === listContenu[d].idContenu) {
                listContenu[d] = contenuOrListContenu[c];
                found = true;
                break;
              }
            }
            // Si contenu pas trouvé, alors ajout dans la liste
            if(!found) listContenu.push(contenuOrListContenu[c]);
          }
        }
        else if(_.isObject(contenuOrListContenu)) {
          found = false;
          // Parcour la liste des contenus à modifier
          for(d = 0; d < listContenu.length; d++) {
            // Si contenu trouvé
            if(contenuOrListContenu.idContenu === listContenu[d].idContenu) {
              listContenu[d] = contenuOrListContenu;
              found = true;
              break;
            }
          }
          // Si contenu pas trouvé, alors ajout dans la liste
          if(!found) listContenu.push(contenuOrListContenu);
        }
      }
    };

    scope.decodeSearch = function(search){
      var parsedWordArray = CryptoJS.enc.Base64.parse(search);
      var parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
      return angular.fromJson(parsedStr);
    };

    scope.contenuHasTagTypeOldPortefeuille = function(contenu) {
      var contenuHasTagTypeOldPortefeuille = false;
      if(_.isObject(contenu) && _.isArray(contenu.tagsType) && contenu.tagsType.length){
        for(var t = 0; t < contenu.tagsType.length; t++){
          var tagType = contenu.tagsType[t];
          if(_.isObject(tagType) && _.isObject(tagType.typeTag) && tagType.typeTag.libelle === COLLAB_CONF.TAG_TYPE_PORTEFEUILLE){
            if(_.isObject(tagType.portefeuille) && _.isObject(tagType.portefeuille.portefeuilleInfos) && tagType.portefeuille.portefeuilleInfos.isBlocked) {
              contenuHasTagTypeOldPortefeuille = true;
            }
            break;
          }
        }
      }
      return contenuHasTagTypeOldPortefeuille;
    };

    scope.calculDateProchaineRelance = function(dateDepart, periode, format, instanceContenu){
      if(_.isObject(periode)){
        var today = moment().startOf('day');

        if(_.isNil(dateDepart) || dateDepart === false) dateDepart = new Date();

        var dateNextRelance = moment(dateDepart).add(periode.getValuePeriodCalendar(), periode.getTypePeriodMoment()).startOf('day');

        /**
         * Si la date de prhciane relance est égale ou inférieur à aujourd'hui alors la date de prochaine relanse sera calculée
         * pas rapport à aujourd'hui
         */
        if(dateNextRelance.isSameOrBefore(today)) {
          if(!_.isNil(instanceContenu)) instanceContenu.relanceFromTodayForced = true;
          dateNextRelance = moment(today).add(periode.getValuePeriodCalendar(), periode.getTypePeriodMoment()).startOf('day');
        }

        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return scope.parseDate(dateNextRelance, format);
        }
        return dateNextRelance;
      }
      return null;
    };

    /**
     * Permet de récupérer le logo collab
     * @returns {string}
     */
    scope.getLogoCollab = function(){
      return COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_LOGO_COLLAB_SERVLET+'?action=streamLogoCollab&token='+scope.getCurrentUser().getToken();
    };

    scope.convertToDataURLviaCanvas = function(url, callbackOnload, callbackError, outputFormat) {
      var img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        var canvas = document.createElement('CANVAS');
        var ctx = canvas.getContext('2d');
        var dataURL;
        canvas.height = this.height;
        canvas.width = this.width;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callbackOnload(dataURL);
        canvas = null;
      };
      img.onerror = function () {
        if (callbackError) {
          callbackError();
        }
      };
      img.src = url;
    };

    /**
     * Permet de générer le label par rapport à une date de debut et de fin
     * @param defaultValuesDatePicker
     * @returns {*}
     */
    scope.datepickerGetLabelFromDates = function(defaultValuesDatePicker, defaultLabel){
      if(_.isNil(defaultLabel)) defaultLabel = 'Toute la période';

      var today = moment().startOf('day');
      var hier = moment().subtract(1, 'days').startOf('day');
      var jMoins7 = moment().subtract(7, 'days').startOf('day');
      var jMoins14 = moment().subtract(14, 'days').startOf('day');
      var jMoins30 = moment().subtract(30, 'days').startOf('day');

      var debutSemaineDerniere = moment().subtract(1, 'week').startOf('isoWeek');
      var finSemaineDerniere = moment().subtract(1, 'week').endOf('isoWeek');

      var debutSemaine = moment().startOf('isoWeek');
      var finSemaine = moment().endOf('isoWeek');

      var debutMois = moment().startOf('month').startOf('day');
      var finMois = moment().endOf('month').startOf('day');

      var debutMoisDernier = moment().subtract(1, 'month').startOf('month');
      var finMoisDernier = moment().subtract(1, 'month').endOf('month')

      if(defaultValuesDatePicker.searchAll) defaultValuesDatePicker.label = defaultLabel;
      else {
        // Si il y a une date de début et une date de fin
        if(!_.isNil(defaultValuesDatePicker.startDate) && !_.isNil(defaultValuesDatePicker.endDate)) {
          // Si la date de début et la date de fin sont à aujourdhui
          if(defaultValuesDatePicker.startDate.isSame(today, 'day') && defaultValuesDatePicker.endDate.isSame(today, 'day')){
            defaultValuesDatePicker.label = 'Aujourd\'hui';
          }
          // Si la date de début et la date de fin sont à hier
          else if(defaultValuesDatePicker.startDate.isSame(hier, 'day') && defaultValuesDatePicker.endDate.isSame(hier, 'day')){
            defaultValuesDatePicker.label = 'Hier';
          }
          // Si la date de début est au début de la semaine et la date de fin à la fin de la semaine
          else if(defaultValuesDatePicker.startDate.isSame(debutSemaine, 'day') && defaultValuesDatePicker.endDate.isSame(finSemaine, 'day')){
            defaultValuesDatePicker.label = 'Cette semaine (depuis Lundi)';
          }
          // Si la date de début est à J-7 et la date de fin à aujourd'hui
          else if(defaultValuesDatePicker.startDate.isSame(jMoins7, 'day') && defaultValuesDatePicker.endDate.isSame(today, 'day')){
            defaultValuesDatePicker.label = 'Les 7 derniers jours';
          }
          // Si la date de début est à la date de début de la semaine dernière et la date de fin à la date de fin de la semaine dernière
          else if(defaultValuesDatePicker.startDate.isSame(debutSemaineDerniere, 'day') && defaultValuesDatePicker.endDate.isSame(finSemaineDerniere, 'day')){
            defaultValuesDatePicker.label = 'Semaine dernière (Lun - Dim)';
          }
          // Si la date de début est à J-14 et la date de fin à aujourd'hui
          else if(defaultValuesDatePicker.startDate.isSame(jMoins14, 'day') && defaultValuesDatePicker.endDate.isSame(today, 'day')){
            defaultValuesDatePicker.label = 'Les 14 derniers jours';
          }
          // Si la date de début est au début du mois et la date de fin à la fin du mois
          else if(defaultValuesDatePicker.startDate.isSame(debutMois, 'day') && defaultValuesDatePicker.endDate.isSame(finMois, 'day')){
            defaultValuesDatePicker.label = 'Ce mois-ci';
          }
          // Si la date de début est à J-30 et la date de fin à aujourd'hui
          else if(defaultValuesDatePicker.startDate.isSame(jMoins30, 'day') && defaultValuesDatePicker.endDate.isSame(today, 'day')){
            defaultValuesDatePicker.label = 'Les 30 derniers jours';
          }
          // Si la date de début est au début du mois dernier et la date de fin à la fin du mois dernier
          else if(defaultValuesDatePicker.startDate.isSame(debutMoisDernier, 'day') && defaultValuesDatePicker.endDate.isSame(finMoisDernier, 'day')){
            defaultValuesDatePicker.label = 'Le mois dernier';
          }
          else
            defaultValuesDatePicker.label = 'Personnaliser';
        }
        // Si pas de date début et pas de date de fin
        else if(_.isNil(defaultValuesDatePicker.startDate) && _.isNil(defaultValuesDatePicker.endDate))
          defaultValuesDatePicker.label = defaultLabel;
        else
          defaultValuesDatePicker.label = 'Personnaliser';

        defaultValuesDatePicker = scope.parseDisplayDates(defaultValuesDatePicker);
      }

      return defaultValuesDatePicker;
    };

    /**
     * Permet de parser les dates qui seront affichés pour les dates pickers
     * @param obj
     * @returns {*}
     */
    scope.parseDisplayDates = function(obj) {

      if(_.isString(obj.startDate)) obj.startDate = moment(obj.startDate);
      if(_.isString(obj.endDate)) obj.endDate = moment(obj.endDate);

      if(_.isNil(obj.searchAll) || !obj.searchAll) {
        obj.isDefault = false;
        switch (obj.label) {
          case 'Aujourd\'hui':
            obj.showDates = obj.startDate.format('dddd DD MMMM YYYY').ucfirst();
            break;
          case 'Hier':
            obj.showDates = obj.startDate.format('dddd DD MMMM YYYY').ucfirst();
            break;
          case 'Ce mois-ci':
            obj.showDates = obj.startDate.format('MMMM YYYY').ucfirst();
            break;
          case 'Le mois dernier':
            obj.showDates = obj.startDate.format('MMMM YYYY').ucfirst();
            break;
          case 'Toute la période':
            obj.showDates = false;
            obj.startDate = null;
            obj.endDate = null;
            obj.isDefault = true;
            obj.searchAll = true;
            break;
          case 'Tous':
            obj.showDates = false;
            obj.startDate = null;
            obj.endDate = null;
            obj.isDefault = true;
            obj.searchAll = true;
            break;
          default:
            if(obj.startDate && obj.endDate) {
              if(obj.startDate.isSame(obj.endDate, 'day')) obj.showDates = obj.startDate.format('dddd DD MMMM YYYY').ucfirst();
              else obj.showDates = 'Du ' + obj.startDate.format('DD MMM YYYY') + ' au ' + obj.endDate.format('DD MMM YYYY');
            }
            else if(obj.startDate && !obj.endDate) obj.showDates = 'Après le ' + obj.startDate.format('DD MMM YYYY');
            else if(!obj.startDate && obj.endDate) obj.showDates = 'Avant le ' + obj.endDate.format('DD MMM YYYY');
        }
      }
      return obj;
    };

    scope.checkIfCanShowSendOneMailWithAllDest = function(listeDest){
      var showSendOneMailWithAllDest = false;
      if(_.isArray(listeDest)) {
        if(listeDest.length > 1) showSendOneMailWithAllDest = true;
        else {
          showSendOneMailWithAllDest = false;
          var groupe = listeDest[0];
          if(_.isObject(groupe)) {
            if(groupe.hasOwnProperty('groupeCollab')) groupe = groupe.groupeCollab;
            if(_.isObject(groupe) && groupe.model === 'Groupe' && groupe.isGroupeWithMultipleUsers()) showSendOneMailWithAllDest = true;
          }
        }
      }
      return showSendOneMailWithAllDest;
    };

    scope.cleanNomGroupe = function(nom){
      nom = scope.ltrim(nom,'@'); // Supprime un éventuel @ au début
      nom = scope.slugify(nom); // Transform text into a URL slug, Replaces whitespaces, with dash("-"), or given argument
      nom = scope.latinize(nom); // Remove accents/diacritics from a string
      nom = nom.replace(/[^a-zA-Z0-9-_]/g, ''); // Garde que les charactère alpha numérique, tiret et underscore les autres sont remplacé par rien
      return nom.trim();
    };

    scope.isGSMAlphabet = function(text) {
      var regexp = new RegExp("^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$");
      return regexp.test(text);
    };

    scope.getObjectCleanStringForGsm = function(text, limit){
      var retour = {
        nb: 0,
        nbNotAllowed: 0,
        nbNotAllowedButConverted: 0,
        nbReste: (_.isNumber(limit)) ? limit : null,
        value: ""
      };
      if(_.isString(text)){
        for(var i = 0; i < text.length; i++){
          if(_.isNumber(limit) && retour.nb >= limit) break;
          if(_.isNumber(retour.nbReste) && retour.nbReste <= 0) break;

          var character = text.charAt(i);
          if(scope.isGSMAlphabet(character)){
            retour.nb++;
            if(_.isNumber(retour.nbReste)) retour.nbReste--;
            if(COLLAB_CONF.REGEX_CHARACTER_COUNT_DOUBLE.test(character) || COLLAB_CONF.LIST_CHARACTER_COUNT_DOUBLE.indexOf(character) != -1){
              if(_.isNumber(retour.nbReste)) {
                retour.nbReste--;
                // Si il reste plus de caractère dispo alors on sort maintenant pour éviter d'incrémenter le nb et d'ajouter le caractère qui compte double
                if(retour.nbReste < 0) break;
              }
              retour.nb++;
            }
            if(_.isNil(limit) || (_.isNumber(limit) && retour.nb <= limit)) {
              retour.value += character;
            }
            continue;
          }
          // Si c'est un caractère qui peut être remplacé
          else if(scope.isInDiacriticsMap(character)){
            var convertCharacter = scope.latinize(character);
            if(_.isString(convertCharacter)) {
              retour.nb++;
              retour.nbNotAllowedButConverted++;
              if(_.isNumber(retour.nbReste)) retour.nbReste--;
              if(_.isNil(limit) || (_.isNumber(limit) && retour.nb <= limit)) {
                retour.value += convertCharacter;
              }
              continue;
            }
          }
          retour.nbNotAllowed++;
        }
      }
      return retour;
    };

    scope.getPrefixSmsFromPortefeuilleOrUser = function(){
      var prefixSMS = null;
      var portefeuilles = scope.getCurrentUser().getListePortefeuille();
      if(portefeuilles.length === 1 && _.isObject(portefeuilles[0].getPortefeuilleInfos())) {
        prefixSMS = portefeuilles[0].getPortefeuilleInfos().getPrefixSms();
      }
      // Si toujours pas de préfix alors prend le diese
      if(!prefixSMS) prefixSMS = scope.getCurrentUser().getDieze();

      return prefixSMS;
    };

    scope.canSendToGed = function(topic, versionGed){
      var agenceIcs = scope.getParametrage('agenceICS');
      if(!agenceIcs || _.isNil(agenceIcs)) return false;

      if(_.isObject(topic)){
        var portefeuille = topic.getPortefeuille();
        if(_.isObject(portefeuille)) {
          if(_.isNil(versionGed)) return portefeuille.getCanSendToGed();
          if(portefeuille.getCanSendToGed()){
            if(versionGed === 2) return portefeuille.gedV2IsEnabled();
            if(versionGed === 1) return portefeuille.gedV1IsEnabled();
          }
        }
      }
      return false;
    };

    scope.orderBy = function(array, property, order){
      if(order === 'ASC') array.sort(function(a,b){return new Date(a[property]).getTime() - new Date(b[property]).getTime()});
      else array.sort(function(a,b){return new Date(b[property]).getTime() - new Date(a[property]).getTime()});
      return array;
    };

    scope.isMicrosoftEdgeBrowser = function(userAgent) {
      if(_.isNil(userAgent)) userAgent = $window.navigator.userAgent.toLowerCase();
      if ((userAgent.indexOf('chrome') !== -1) && (userAgent.indexOf('edg') !== -1)) {
        return true;
      }
      return false;
    };

    scope.getMicrosoftEdgeBrowserVersion = function() {
        var version = null;
        var userAgent = $window.navigator.userAgent.toLowerCase();
        if (scope.isMicrosoftEdgeBrowser(userAgent)) {
            var matches = userAgent.match(/edg\/([0-9]+\.[0-9]+\.[0-9]+\.[0-9]+)/);
            if (matches) {
                version = matches[1];
            }
        }
        return version;
    };
  }
})();
/* eslint-enable */
