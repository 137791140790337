(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .config(routerConfig);

  /** @ngInject */
  function routerConfig(COLLAB_CONF, $locationProvider, $stateProvider, $urlRouterProvider, $httpProvider){

    var msie = document.documentMode;
    var isIE9 = false;
    if (msie && msie === 9) isIE9 = true;

    if(!isIE9) {
      $locationProvider.html5Mode({
        enabled: true,
        requireBase: false,
        rewriteLinks: true
      }).hashPrefix('!');
    }

    /*
     $locationProvider.html5Mode({
     enabled: false,
     requireBase: false
     });*/
    //$httpProvider.defaults.withCredentials = true;

    $httpProvider.defaults.useXDomain = true;
    if(COLLAB_CONF.AUTH_KERBEROS) $httpProvider.defaults.withCredentials = true;
    delete $httpProvider.defaults.headers.common['X-Requested-With'];


    // Configuration des routes
    $urlRouterProvider
    //.when('/','/Topics/list','/Topics/list')
    //.when('','/Topics/list')
    //.when('/','/Topics/list')
      .when('/topics','/topics/list')
      .when('/topics/','/topics/list')
      .when('/topics/nouveau','/topics/nouveau/message')
      //.otherwise('/login');
      .otherwise('/login');
    //.deferIntercept();


    $stateProvider

      // Dashboard capfun
      .state('capfunDashboard', {
        url: '/capfun-dashboard',
        template: '<capfun-dashboard></capfun-dashboard>',
        authorized: true
      })

      // Reporting
      .state('capfunDashboard.reporting', {
        url: '/reporting?search',
        views: {
          viewCapfunDashboard: {
            template: '<capfun-dashboard-reporting search="$resolve.recherche"></capfun-dashboard-reporting>',
            resolve: {
              recherche: ['$stateParams',function($stateParams){
                return $stateParams.search;
              }]
            }
          }
        },
        data: {
          pageTitle: 'Dashboard Capfun - Reporting',
          namePage: 'Reporting'
        },
        authorized: true
      })

      // Reporting
      .state('capfunDashboard.recap', {
        url: '/recap',
        views: {
          viewCapfunDashboard: {
            template: '<capfun-dashboard-recap ></capfun-dashboard-recap>'
          }
        },
        data: {
          pageTitle: 'Dashboard Capfun - Récap',
          namePage: 'Récap'
        },
        authorized: true
      })

      // Dashboard Vagues Océanes
      .state('vaguesOceanesDashboard', {
        url: '/vagues-oceanes-dashboard',
        template: '<vagues-oceanes-dashboard></vagues-oceanes-dashboard>',
        authorized: true
      })

      // Reporting
      .state('vaguesOceanesDashboard.reporting', {
        url: '/reporting?search',
        views: {
          viewVaguesOceanesDashboard: {
            template: '<capfun-dashboard-reporting search="$resolve.recherche"></capfun-dashboard-reporting>',
            resolve: {
              recherche: ['$stateParams',function($stateParams){
                return $stateParams.search;
              }]
            }
          }
        },
        data: {
          pageTitle: 'Dashboard Vagues Océanes - Reporting',
          namePage: 'Reporting'
        },
        authorized: true
      })

      // Reporting
      .state('vaguesOceanesDashboard.recap', {
        url: '/recap',
        views: {
          viewVaguesOceanesDashboard: {
            template: '<capfun-dashboard-recap ></capfun-dashboard-recap>'
          }
        },
        data: {
          pageTitle: 'Dashboard Vagues Océanes - Récap',
          namePage: 'Récap'
        },
        authorized: true
      })

    // Dashboard Collab
      .state('dashboard', {
        url: '/dashboard',
        template: '<dashboard></dashboard>',
        authorized: true
      })

      .state('dashboard.accueil', {
        url: '/accueil?search',
        views: {
          viewDashboard: {
            template: '<dashboard-accueil search="$resolve.recherche" on-emit="dashboardctrl.onEmitDirectChildComponent(obj)" id-channel="dashboardctrl.currentIdChannel"></dashboard-accueil>',
            resolve: {
              recherche: ['$stateParams',function($stateParams){
                return $stateParams.search;
              }]
            }
          }
        },
        data: {
          pageTitle: 'Dashboard - Accueil',
          namePage: ''
        },
        authorized: true
      })

      // Reporting
      .state('dashboard.reporting', {
        url: '/reporting?search',
        views: {
          viewDashboard: {
            template: '<dashboard-reporting search="$resolve.recherche" on-emit="dashboardctrl.onEmitDirectChildComponent(obj)" id-channel="dashboardctrl.currentIdChannel"></dashboard-reporting>',
            resolve: {
              recherche: ['$stateParams',function($stateParams){
                return $stateParams.search;
              }]
            }
          }
        },
        data: {
          pageTitle: 'Dashboard - Reporting',
          namePage: 'Reporting'
        },
        authorized: true
      })


      // Reporting
      /*
      .state('dashboard.reportingCamping', {
        url: '/reporting-camping?search',
        views: {
          viewDashboard: {
            template: '<dashboard-reporting-camping search="$resolve.recherche" on-emit="dashboardctrl.onEmitDirectChildComponent(obj)" id-channel="dashboardctrl.currentIdChannel"></dashboard-reporting-camping>',
            resolve: {
              recherche: ['$stateParams',function($stateParams){
                return $stateParams.search;
              }]
            }
          }
        },
        data: {
          pageTitle: 'Dashboard - Reporting Camping',
          namePage: 'Reporting Camping'
        },
        authorized: true
      })*/



      // Nouveau topic
      .state('nouveau', {
        url: '/nouveau?visicop',
        template: '<nouveau-topic></nouveau-topic>',
        data: {
          pageTitle: 'Nouveau topic'
        },
        authorized: true
      })

      // Nouveau topic message
      .state('nouveau.message', {
        url: '/message',
        views: {
          viewTopicsNouveau: {
            template: '<contenu-formulaire><contenu-message-formulaire></contenu-message-formulaire></contenu-formulaire>'
            /*
            templateUrl: 'app/views/topics/contenu/formulaireContenuMessage.html',
            controller: 'ContenuCtrl',
            controllerAs: 'contenuctrl'
            */
          }
        },
        data: {
          pageTitle: 'Nouveau message'
        },
        authorized: true
      })

      // Nouveau topic événement
      .state('nouveau.evenement', {
        url: '/evenement/?action&Cle_Id&Num_Perso&id_c3T&phone_number&json',
        views: {
          viewTopicsNouveau: {
            template: '<formulaire-evenement></formulaire-evenement>'
            /*
            templateUrl: 'app/views/topics/contenu/formulaireContenuEvenement.html',
            controller: 'ContenuCtrl',
            controllerAs: 'contenuctrl'*/
          }
        },
        params: {
          loadOrigine: false
        },
        data: {
          pageTitle: 'Nouvel événement'
        },
        authorized: true
      })

      // Nouveau topic événement avec demande de devis ou d'intervention
      .state('nouveau.evenementAvecDevisOuIntervention', {
        url: '/demande-devis-intervention/?action&Cle_Id&Num_Perso&id_c3T&phone_number&json',
        views: {
          viewTopicsNouveau: {
            template: '<formulaire-evenement-demande type-demande="devis"></formulaire-evenement-demande>'
          }
        },
        params: {
          loadOrigine: false
        },
        data: {
          pageTitle: 'Nouvel événement avec demande de devis ou intervention'
        },
        authorized: true
      })

      // Nouveau topic événement avec demande de devis ou d'intervention
      .state('nouveau.evenementAvecContrat', {
        url: '/demande-contrat/?action&Cle_Id&Num_Perso&id_c3T&phone_number',
        views: {
          viewTopicsNouveau: {
            template: '<formulaire-evenement-demande type-demande="contrat"></formulaire-evenement-demande>'
          }
        },
        params: {
          loadOrigine: false
        },
        data: {
          pageTitle: 'Nouvel événement avec demande de contrat'
        },
        authorized: true
      })

      // Topics
      .state('topics', {
        url: '/topics?visicop',
        template: '<layout-topic-main></layout-topic-main>',
        authorized: true
      })
      // Liste des topics
      .state('topics.list', {
        url: '/list/{topicPage:[0-9]*}?search&idPortefeuille&action&immeuble',
        //url: '/list/{topicPage:[0-9]*}?evenement&cloturer&urgent&tagsType',
        params: {
          etat: {value:0},
          topicPage: {value: '1'},
          noRefresh: null,
          idChannel: null/*,
                     evenement: {value: undefined},
                     cloturer: {value: undefined},
                     urgent: {value: undefined},
                     tagsType: {value: undefined}*/
        },
        views: {
          //"viewTopicsList": {
          /*
           'viewTopics': {
           templateUrl: 'app/views/topics/topicsList.html',
           controller: 'TopicsListCtrl',
           controllerAs: 'topicslistctrl'
           }*/
          viewTopics: {
            template: '<layout-topic-list></layout-topic-list>'
          }
        },
        data: {
          pageTitle: 'Liste des topics'
        },
        authorized: true
      })
      .state('topics.archives', {
        url: '/archives/{topicPage:[0-9]*}?search&idPortefeuille&action&immeuble',
        params: {
          etat: {value:2},
          topicPage: {value: '1'},
          noRefresh: null,
          idChannel: null
        },
        views: {
          viewTopics: {
            template: '<layout-topic-list></layout-topic-list>'
          }
        },
        data: {
          pageTitle: 'Liste des topics archivés'
        },
        authorized: true
      })
      .state('topics.favoris', {
        url: '/favoris/{topicPage:[0-9]*}?search&idPortefeuille&action&immeuble',
        params: {
          etat: {value:1},
          topicPage: {value: '1'},
          noRefresh: null,
          idChannel: null
        },
        views: {
          viewTopics: {
            template: '<layout-topic-list></layout-topic-list>'
          }
        },
        data: {
          pageTitle: 'Liste des topics favoris'
        },
        authorized: true
      })
      .state('topics.tous', {
        url: '/tous/{topicPage:[0-9]*}?search&idPortefeuille&action&immeuble&noperso&mandat&lot&portefeuille&nofourni&locataire&origine&inv',
        params: {
          etat: {value:3},
          topicPage: {value: '1'},
          noRefresh: null,
          idChannel: null,
          action: {
            value: null,
            inherit: false
          },
          immeuble: {
            value: null,
            inherit: false
          },
          noperso: {
            value: null,
            inherit: false
          },
          nofourni: {
            value: null,
            inherit: false
          },
          locataire: {
            value: null,
            inherit: false
          },
          mandat: {
            value: null,
            inherit: false
          },
          lot: {
            value: null,
            inherit: false
          },
          portefeuille: {
            value: null,
            inherit: false
          },
          origine: {
            value: null,
            inherit: false
          },
          inv: {
            value: null,
            inherit: false
          }
        },
        views: {
          viewTopics: {
            template: '<layout-topic-list></layout-topic-list>'
          }
        },
        data: {
          pageTitle: 'Liste de tous les topics'
        },
        authorized: true
      })
      .state('topics.recherche', {
        //url: '/recherche/{topicPage:[0-9]*}?search',
        url: '/recherche?search&idPortefeuille&action&immeuble',
        params: {
          topicPage: {value: '1'},
          noRefresh: null,
          idChannel: null
        },
        views: {
          viewTopics: {
            template: '<layout-topic-recherche></layout-topic-recherche>'
          }
        },
        data: {
          pageTitle: 'Liste de tous les topics'
        },
        authorized: true
      })
      .state('topics.recherche.list', {
        url: '/list/{topicPage:[0-9]*}',
        params: {
          etat: {value:0}
        },
        data: {
          pageTitle: 'Recherche des topics'
        },
        authorized: true
      })
      .state('topics.recherche.favoris', {
        url: '/favoris/{topicPage:[0-9]*}',
        params: {
          etat: {value:1}
        },
        data: {
          pageTitle: 'Recherche des topics favoris'
        },
        authorized: true
      })
      .state('topics.recherche.archives', {
        //url: '/recherche/{topicPage:[0-9]*}?search&q&tri&type&page&nbpp',
        url: '/archives/{topicPage:[0-9]*}',
        params: {
          etat: {value:2}
        },
        data: {
          pageTitle: 'Recherche des topics archives'
        },
        authorized: true
      })
      .state('topics.recherche.tous', {
        url: '/tous/{topicPage:[0-9]*}',
        params: {
          etat: {value:3}
        },
        data: {
          pageTitle: 'Recherche de tous les topics'
        },
        authorized: true
      })

      /*
                  .state('topics.test', {
                      url: '/test/{topicPage:[0-9]*}?search&action&immeuble&noperso&mandat&lot&portefeuille&nofourni&locataire&origine&inv',
                      params: {
                          etat: {value:3},
                          topicPage: {value: '1'},
                          noRefresh: null,
                          idChannel: null,
                          action: {
                              value: null,
                              inherit: false
                          },
                          immeuble: {
                              value: null,
                              inherit: false
                          },
                          noperso: {
                              value: null,
                              inherit: false
                          },
                          nofourni: {
                              value: null,
                              inherit: false
                          },
                          locataire: {
                              value: null,
                              inherit: false
                          },
                          mandat: {
                              value: null,
                              inherit: false
                          },
                          lot: {
                              value: null,
                              inherit: false
                          },
                          portefeuille: {
                              value: null,
                              inherit: false
                          },
                          origine: {
                              value: null,
                              inherit: false
                          },
                          inv: {
                              value: null,
                              inherit: false
                          }
                      },
                      views: {
                          'viewTopics': {
                              template: '<layout-topic-list></layout-topic-list>'
                          }
                      },
                      data: {
                          pageTitle: 'Liste de tous les topics'
                      },
                      authorized: true
                  })
      */

      // Détail d'un topic
      .state('topics.detail', {
        //url: '/{topicId:[0-9]*}/{tab:[a-zA-Z]*?}',
        url: '/{topicId:[0-9]*}',
        views: {
          viewTopics: {
            template: '<layout-topic-detail current-tab="$resolve.currentTab"></layout-topic-detail>'
          }
        },

        resolve: {
          currentTab: ['$stateParams',function($stateParams){
            return $stateParams.tab;
          }]
        },
        params: {
          hash: null,
          communication: {
            show: false,
            sms: false,
            message: ''
          },
          tab:{
            value: null,
            squash: true
          },
          objTab: null
        },
        data: {
          pageTitle: 'Détail d\'un topic'
        },
        authorized: true
      })

      // Onglet discussions de la page detail
      .state('topics.detail.discussions', {
        url: '/discussions',
        params: {
          currentTab: {
            value: 'discussions'
          }
        },
        data: {
          pageTitle: 'Discussions'
        },
        authorized: true
      })

      // Onglet discussions de la page detail
      .state('topics.detail.bloc_notes', {
        url: '/bloc_notes',
        params: {
          currentTab: {
            value: 'bloc_notes'
          }
        },
        data: {
          pageTitle: 'Bloc Notes'
        },
        authorized: true
      })

      // Onglet fournisseur de la page detail
      .state('topics.detail.fournisseurs', {
        url: '/fournisseurs/{action:[a-zA-Z-]*?}/{idContenu:[0-9]*?}/{guid:[0-9a-zA-Z\-]*?}',
        params: {
          currentTab: {
            value: 'fournisseurs'
          },
          idContenu: {
            value:null,
            squash: true
          },
          guid: {
            value:null,
            squash: true
          },
          action: {
            value:null,
            squash: true
          },
          contenu: {
            value:null
          },
          document: {
            value:null
          }
        },
        data: {
          pageTitle: 'Fournisseurs'
        },
        authorized: true
      })

      // Onglet communiquer de la page detail
      .state('topics.detail.communiquer', {
        url: '/communiquer',
        params: {
          currentTab: {
            value: 'communiquer'
          }
        },
        data: {
          pageTitle: 'Communiquer'
        },
        authorized: true
      })

      // Onglet courriers de la page detail
      .state('topics.detail.courriers', {
        url: '/courriers',
        params: {
          currentTab: {
            value: 'courriers'
          }
        },
        data: {
          pageTitle: 'Courriers'
        },
        authorized: true
      })

      // Onglet stats de la page detail
      .state('topics.detail.stats', {
        url: '/stats',
        params: {
          currentTab: {
            value: 'stats'
          }
        },
        data: {
          pageTitle: 'Stats'
        },
        authorized: true
      })

      // Diffusion TocTocToc
      .state('diffusionTocTocToc', {
        url: '/diffusion-toctoctoc',
        template: '<diffusion-toctoctoc></diffusion-toctoctoc>',
        authorized: true
      })

      .state('annuaire', {
        url: '/annuaire',
        templateUrl: 'app/annuaire/annuaire.html',
        authorized: true,
        data: {
          pageTitle: 'Annuaire'
        }
      })
      .state('documents', {
        url: '/documents',
        templateUrl: 'app/views/documents/documentsMain.html',
        controller: 'DocumentsCtrl',
        controllerAs : 'documentsctrl',
        authorized: true,
        data: {
          pageTitle: 'Documents'
        }
      })
      .state('params', {
        url: '/params',
        templateUrl: 'app/views/parametrages/params.html',
        controller: 'ParamsCtrl',
        controllerAs : 'paramsctrl',
        authorized: true,
        data: {
          pageTitle: 'Paramétrage'
        }
      })
      .state('params.mon_compte', {
        url: '/mon-compte',
        authorized: true,
        data: {
          pageTitle: 'Paramétrage - Mon Compte'
        }
      })

      .state('params.mes_groupes', {
        url: '/mes-groupes',
        authorized: true,
        data: {
          pageTitle: 'Paramétrage - Mes Groupes'
        }
      })
      .state('params.gestion_groupes', {
        url: '/gestion-des-groupes',
        //controller: 'ParamsAdminGestionGroupesCtrl',
        //controllerAs : 'gestiongroupesctrl',
        authorized: true,
        data: {
          pageTitle: 'Paramétrage - Gestion des groupes et portefeuilles'
        }
      })

      .state('params.gestion_groupes_avance', {
        url: '/gestion-des-groupes-avance',
        //controller: 'ParamsAdminGestionGroupesCtrl',
        //controllerAs : 'gestiongroupesctrl',
        authorized: true,
        data: {
          pageTitle: 'Paramétrage - Gestion avancée des groupes et portefeuilles'
        }
      })

      .state('params.modules', {
        url: '/modules',
        authorized: true,
        data: {
          pageTitle: 'Paramétrage - Modules'
        }
      })

      .state('params.gestion_portefeuilles', {
        url: '/gestion-des-portefeuilles',
        authorized: true,
        data: {
          pageTitle: 'Paramétrage - Gestion des portefeuilles'
        }
      })


      .state('params.autres', {
        url: '/autres',
        authorized: true,
        data: {
          pageTitle: 'Paramétrage - Autres'
        }
      })
      .state('params.parametrageSociete', {
        url: '/parametrage-societe',
        authorized: true,
        data: {
          pageTitle: 'Paramétrage - Paramétrage de la société'
        }
      })
      .state('login', {
        url: '/login?token&visicop&idPortefeuille&action&immeuble',
        views: {
          viewLogin: {
            template: '<layout-login></layout-login>',
          }
        },
        params: {
          infosExtranetFournisseur: null,
          cancel: null,
          fromLogOut: null,
          forceManuelLogin: null,
          erreur: null
        },
        data: {
          pageTitle: 'Authentification'
        }
      })
      /*
      .state('login', {
        url: '/login?token&visicop&idPortefeuille&action&immeuble',
        views: {
          viewLogin: {
            templateUrl: 'app/views/login.html',
            controller: 'LoginCtrl',
            controllerAs: 'loginctrl'
          }
        },
        params: {
          infosExtranetFournisseur: null,
          cancel: null,
          fromLogOut: null,
          forceManuelLogin: null
        },
        data: {
          pageTitle: 'Authentification'
        }
      })*/
      // Modules
      .state('module', {
        url: '/module/{idModule:[0-9]*}',
        params: {
          idModule: {value: '0'}
        },
        templateUrl: 'app/views/module/module.html',
        controller: 'ModuleCtrl',
        controllerAs: 'modulectrl',
        data:{
          pageTitle: 'Module'
        },
        authorized: true
      })
      /*
      .state('rechercheCollab', {
          url: '/search?q&tri&type&page&nbpp',
          template: '<list-topic-elasticsearch query="$resolve.query" params="$resolve.paramRechercheGenerale" tri="$resolve.tri" type="$resolve.type" page="$resolve.page" nbpp="$resolve.nbpp"></list-topic-elasticsearch>',
          resolve: {
              paramRechercheGenerale: ['$stateParams',function($stateParams){
                  return $stateParams.paramRechercheGenerale;
              }],
              query: ['$stateParams',function($stateParams){
                  return $stateParams.q;
              }],
              tri: ['$stateParams',function($stateParams){
                  return $stateParams.tri;
              }],
              type: ['$stateParams',function($stateParams){
                  return $stateParams.type;
              }],
              page: ['$stateParams',function($stateParams){
                  return $stateParams.page;
              }],
              nbpp: ['$stateParams',function($stateParams){
                  return $stateParams.nbpp;
              }]
          },
          params: {
              page: {value: (COLLAB_CONF.ENABLE_PAGINATION_RECHERCHE_COLLAB) ? COLLAB_CONF.DEFAULT_RECHERCHE_COLLAB_PAGE : null},
              nbpp: {value: (COLLAB_CONF.ENABLE_PAGINATION_RECHERCHE_COLLAB) ? COLLAB_CONF.DEFAULT_RECHERCHE_COLLAB_NBPP : null},
              paramRechercheGenerale: {}
          },
          authorized: true,
          data: {
              pageTitle: 'Recherche'
          }
      })*/
      .state('recherche', {
        url: '/recherche',
        templateUrl: 'app/views/recherche/rechercheMain.html',
        controller: 'RerchercheCtrl',
        controllerAs : 'recherchectrl',
        authorized: true,
        params: {
          newRecherche: false,
          paramRechercheGenerale: {}
        },
        data: {
          pageTitle: 'Recherche'
        }
      })

      .state('collabFournisseur', {
        abstract: true,
        url: '/collab-fournisseur',
        template: '<collab-fournisseur></collab-fournisseur>',
        authorized: true,
        notInCollab: true,
        authByHash: true
      })
      .state('collabFournisseur.dossiers', {
        url: '/dossiers/{login}/{diese}/{hash}',
        views: {
          viewFournisseur:{
            template: '<collab-fournisseur-dossiers login="$resolve.login" diese="$resolve.diese" hash="$resolve.hash"></collab-fournisseur-dossiers>'
          }
        },
        resolve: {
          login: ['$stateParams',function($stateParams){
            return $stateParams.login;
          }],
          diese: ['$stateParams',function($stateParams){
            return $stateParams.diese;
          }],
          hash: ['$stateParams',function($stateParams){
            return $stateParams.hash;
          }]
        },
        data: {
          pageTitle: 'Mes dossiers'
        },
        authorized: true,
        notInCollab: true,
        authByHash: true
      })
      .state('collabFournisseur.detailwithauth', {
        url: '/detail/{login}/{diese}/{hash}/{idT:int}/{idC:int}/{idF:int}',
        views: {
          viewFournisseur:{
            template: '<collab-fournisseur-detail login="$resolve.login" diese="$resolve.diese" hash="$resolve.hash" id-topic="$resolve.idTopic" id-contenu="$resolve.idContenu" id-fournisseur="$resolve.idFournisseur"></collab-fournisseur-detail>'
          }
        },
        params: {
          idT: {value: null, squash: false},
          idC: {value: null, squash: true, isOptional:true},
          idF: {value: null, squash: true, isOptional:true},
        },
        resolve: {
          login: ['$stateParams',function($stateParams){
            return $stateParams.login;
          }],
          diese: ['$stateParams',function($stateParams){
            return $stateParams.diese;
          }],
          hash: ['$stateParams',function($stateParams){
            return $stateParams.hash;
          }],
          idTopic: ['$stateParams',function($stateParams){
            return $stateParams.idT;
          }],
          idContenu: ['$stateParams',function($stateParams){
            return $stateParams.idC;
          }],
          idFournisseur: ['$stateParams',function($stateParams){
            return $stateParams.idF;
          }]
        },
        data: {
          pageTitle: 'Mes dossiers'
        },
        authorized: true,
        notInCollab: true,
        authByHash: true
      })
      .state('collabFournisseur.detail', {
        url: '/detail/{idT:int}/{idC:int}/{idF:int}?inIframe&noScroll&token',
        views: {
          viewFournisseur:{
            template: '<collab-fournisseur-detail id-topic="$resolve.idTopic" id-contenu="$resolve.idContenu" id-fournisseur="$resolve.idFournisseur"></collab-fournisseur-detail>'
          }
        },
        params: {
          idT: {value: null, squash: false},
          idC: {value: null, squash: true, isOptional:true},
          idF: {value: null, squash: true, isOptional:true},
        },
        resolve: {
          idTopic: ['$stateParams',function($stateParams){
            return $stateParams.idT;
          }],
          idContenu: ['$stateParams',function($stateParams){
            return $stateParams.idC;
          }],
          idFournisseur: ['$stateParams',function($stateParams){
            return $stateParams.idF;
          }]
        },
        data: {
          pageTitle: 'Mes dossiers'
        },
        authorized: true,
        notInCollab: true,
        authByHash: true
      })

      /* OLD EXTRANET FOURNISSEUR
      .state('mondevis', {
        url: '/mon-devis/{login}/{diese}/{hash}/{idT:[0-9]*}/{idC:[0-9]*}',
        template: '<layout-upload-document-fournisseur type="devis"></layout-upload-document-fournisseur>',
        authorized: true,
        notInCollab: true,
        data: {
          pageTitle: 'Mon Devis'
        }
      })

      .state('monEvenement', {
        url: '/mon-evenement/{login}/{diese}/{hash}/{idT:int}/{idE:int}/{idC:int}',
        template: '<extranet-fournisseur></extranet-fournisseur>',
        params: {
          idT: {value: null, squash: false},
          idE: {value: null, squash: true, isOptional:true},
          idC: {value: null, squash: true, isOptional:true},
        },
        authorized: true,
        notInCollab: true,
        data: {
          pageTitle: 'Mon événement'
        }
      })

      .state('devisfournisseur', {
        url: '/devis/{idT:[0-9]*}/{idC:[0-9]*}',
        template: '<layout-upload-document-fournisseur type="devis"></layout-upload-document-fournisseur>',
        authorized: true,
        notInCollab: true,
        data: {
          pageTitle: 'Devis Fournisseur'
        }
      })

      .state('evenementFournisseur', {
        url: '/evenement/{idT:[0-9]*}/{idE:[0-9]*}/{idF:[0-9]*}',
        template: '<extranet-fournisseur></extranet-fournisseur>',
        authorized: true,
        notInCollab: true,
        data: {
          pageTitle: 'Evénement Fournisseur'
        }
      })*/
      /*
       .state('extranet', {
       url: '/extranet',
       templateUrl: 'app/views/extranetTmp/extranet.html',
       authorized: true
       })*/
      .state('resetMotDePasse', {
        url: '/mot-de-passe-oublie',
        views: {
          viewLogin: {
            templateUrl: 'app/views/retrievePassword.html',
            controller: 'RetrievePwdCtrl',
            controllerAs: 'retrievepwdctrl'
          }
        },
        data: {
          pageTitle: 'Mot de passe perdu'
        }
      })
      .state('inscription', {
        url: '/inscription',
        views: {
          viewLogin: {
            templateUrl: 'app/views/inscription.html',
            controller: 'InscriptionCtrl',
            controllerAs: 'inscriptionctrl'
          }
        },
        data: {
          pageTitle: 'Inscription'
        }
      })
      .state('mailbox', {
        url: '/mailbox/{idMailbox:[0-9]*}',
        params: {
          idMailbox: {
            value: null,
            squash: true
         }
        },
        template: '<layout-mailbox id-mailbox="$resolve.idMailbox"></layout-mailbox>',
        resolve: {
          idMailbox: ['$stateParams',function($stateParams){
            return $stateParams.idMailbox;
          }]
        },
        authorized: true
      })
      .state('recapMail', {
        url: '/recap-mail',
        template: '<recap-mail></recap-mail>',
        authorized: true
      })
      .state('factures', {
        url: '/factures/{idFacture:[0-9]*}?search&inIframe&noScroll&token',
        template: '<facture-manager id-facture="$resolve.idFacture" type-facture="collab"></facture-manager>',
        resolve: {
          idFacture: ['$stateParams',function($stateParams){
            return $stateParams.idFacture;
          }]
        },
        params: {
          idFacture: null,
          guid: null,
          page: null,
          paramsComfactfacture: null,
          listGroupe: null
        },
        authorized: true
      })
      .state('facturesAutonomes', {
        url: '/factures-autonomes/{guid:[a-zA-Z0-9\-]*}?inIframe&noScroll&token',
        template: '<facture-manager guid="$resolve.guid" type-facture="autonome"></facture-manager>',
        resolve: {
          guid: ['$stateParams',function($stateParams){
            return $stateParams.guid;
          }]
        },
        params: {
          idFacture: null,
          guid: null,
          page: null,
          paramsComfactfacture: null,
          listGroupe: null
        },
        authorized: true
      })
      .state('facturesTopic', {
        url: '/factures-topic/{idTopic:[0-9]*}/{idFacture:[0-9]*?}?search&inIframe&noScroll&token',
        template: '<facture-manager id-topic="$resolve.idTopic" id-facture="$resolve.idFacture"></facture-manager>',
        resolve: {
          idTopic: ['$stateParams',function($stateParams){
            return $stateParams.idTopic;
          }],
          idFacture: ['$stateParams',function($stateParams){
            return $stateParams.idFacture;
          }]
        },
        params: {
          page: null,
          paramsComfactfacture: null,
          listGroupe: null
        },
        authorized: true
      })
      .state('recapEnvois', {
        url: '/recap-envois',
        template: '<recap-envois></recap-envois>',
        authorized: true,
        data: {
          pageTitle: 'Liste des envois'
        }
      })
      .state('recapEnvois.recapShootingMail', {
        url: '/shooting-mail',
        authorized: true,
        params: {
          currentTabIndex: {
            value: 0
          },
          currentTab: {
            value: 'recap_shootingmail'
          },
          currentTitle: {
            value: 'Liste des envois Shooting Mail'
          }
        },
        data: {
          pageTitle: 'Liste des envois - Shooting Mail'
        }
      })
      .state('recapEnvois.recapSms', {
        url: '/sms',
        authorized: true,
        params: {
          currentTabIndex: {
            value: 1
          },
          currentTab: {
            value: 'recap_sms'
          },
          currentTitle: {
            value: 'Liste des envois Sms'
          }
        },
        data: {
          pageTitle: 'Liste des envois - Sms'
        }
      })
      .state('recapShootingMail', {
        url: '/recap-shooting-mail',
        template: '<recap-shooting-mail></recap-shooting-mail>',
        authorized: true
      })

      .state('parametrageCollab', {
        url: '/parametrage-collab',
        template: '<parametrage-collab></parametrage-collab>',
        authorized: true,
        data: {
          pageTitle: 'Paramétrage'
        }
      })
      .state('parametrageCollab.mon_compte', {
        url: '/mon-compte',
        authorized: true,
        params: {
          currentTab: {
            value: 'mon_compte'
          },
          currentTitle: {
            value: 'Mon Compte'
          }
        },
        data: {
          pageTitle: 'Paramétrage - Mon Compte'
        }
      })
      .state('parametrageCollab.mes_groupes', {
        url: '/mes-groupes',
        authorized: true,
        params: {
          currentTab: {
            value: 'mes_groupes'
          },
          currentTitle: {
            value: 'Mes Groupes'
          }
        },
        data: {
          pageTitle: 'Paramétrage - Mes Groupes'
        }
      })
      .state('parametrageCollab.mes_associations', {
        url: '/mes-associations',
        authorized: true,
        params: {
          currentTab: {
            value: 'mes_associations'
          },
          currentTitle: {
            value: 'Mes associations'
          }
        },
        data: {
          pageTitle: 'Paramétrage - Mes associations'
        }
      })
      .state('parametrageCollab.gestion_groupes', {
        url: '/gestion-des-groupes',
        authorized: true,
        params: {
          currentTab: {
            value: 'gestion_groupes'
          },
          currentTitle: {
            value: 'Gestion des groupes et portefeuilles'
          }
        },
        data: {
          pageTitle: 'Paramétrage - Gestion des groupes et portefeuilles'
        }
      })
      .state('parametrageCollab.gestion_associations', {
        url: '/gestion-des-associations',
        authorized: true,
        params: {
          currentTab: {
            value: 'gestion_associations'
          },
          currentTitle: {
            value: 'Gestion des associations'
          }
        },
        data: {
          pageTitle: 'Paramétrage - Gestion des associations'
        }
      })
      .state('parametrageCollab.categories_topic', {
        url: '/categories-topic',
        authorized: true,
        params: {
          currentTab: {
            value: 'categories_topic'
          },
          currentTitle: {
            value: 'Gestion des catégories de dossier'
          }
        },
        data: {
          pageTitle: 'Paramétrage - Gestion des catégories de dossier'
        }
      })
      .state('parametrageCollab.gestion_groupes_avance', {
        url: '/gestion-des-groupes-avance',
        authorized: true,
        params: {
          currentTab: {
            value: 'gestion_groupes_avance'
          },
          currentTitle: {
            value: 'Gestion avancée des groupes et portefeuilles'
          }
        },
        data: {
          pageTitle: 'Paramétrage - Gestion avancée des groupes et portefeuilles'
        }
      })
      .state('parametrageCollab.modules', {
        url: '/modules',
        authorized: true,
        params: {
          currentTab: {
            value: 'modules'
          },
          currentTitle: {
            value: 'Modules'
          }
        },
        data: {
          pageTitle: 'Paramétrage - Modules'
        }
      })
      .state('parametrageCollab.gestion_portefeuilles', {
        url: '/gestion-des-portefeuilles',
        params: {
          currentTab: {
            value: 'gestion_portefeuilles'
          },
          currentTitle: {
            value: 'Gestion des portefeuilles'
          }
        },
        authorized: true,
        data: {
          pageTitle: 'Paramétrage - Gestion des portefeuilles'
        }
      })
      .state('parametrageCollab.autres', {
        url: '/autres',
        params: {
          currentTab: {
            value: 'autres'
          },
          currentTitle: {
            value: 'Autres'
          }
        },
        authorized: true,
        data: {
          pageTitle: 'Paramétrage - Autres'
        }
      })
      .state('parametrageCollab.societe', {
        url: '/parametrage-societe',
        params: {
          currentTab: {
            value: 'societe'
          },
          currentTitle: {
            value: 'Paramétrage de la société'
          }
        },
        authorized: true,
        data: {
          pageTitle: 'Paramétrage - Paramétrage de la société'
        }
      })

      .state('admin', {
        url: '/admin',
        template: '<layout-admin-collab></layout-admin-collab>',
        authorized: true,
        onlySuperAdmin: true,
        data: {
          pageTitle: 'Administration'
        }
      })
      .state('admin.comptes', {
        url: '/comptes',
        authorized: true,
        onlySuperAdmin: true,
        data: {
          pageTitle: 'Administration - Comptes'
        }
      })
      .state('admin.mails', {
        url: '/mails',
        authorized: true,
        onlySuperAdmin: true,
        data: {
          pageTitle: 'Administration - Mails'
        }
      })
      .state('admin.stats', {
        url: '/stats',
        authorized: true,
        onlySuperAdmin: true,
        data: {
          pageTitle: 'Administration - Stats'
        }
      })
      .state('admin.mailbox', {
        url: '/mailbox',
        authorized: true,
        onlySuperAdmin: true,
        data: {
          pageTitle: 'Administration - Mailbox'
        }
      })/*
      .state('admin.capfun', {
        url: '/capfun',
        authorized: true,
        onlySuperAdmin: true,
        data: {
          pageTitle: 'Administration - Capfun'
        }
      })*/;

  }

})();
