(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name collaboreApp.ModalEmailCtrl
     * @description
     * # ModalEmailCtrl
     * Service in the collaboreApp.
     */

    angular
        .module('collaboreApp')
        .controller('ModalEmailCtrl', ModalEmailCtrl);

    /*@ngInject*/
    function ModalEmailCtrl($uibModalInstance, titre, collaborateur, UtilsService){
        var ctrl = this;

        ctrl.onClose = onClose;
        ctrl.onSubmit = onSubmit;
        ctrl.onCheckEmail = onCheckEmail;

        ctrl.titre = titre;
        ctrl.collaborateur = collaborateur;
        ctrl.email = '';

        var oldEmail = '';

        ctrl.$onInit = function() {
            var email = UtilsService.genereEmailJetable(ctrl.collaborateur);

            ctrl.emailJetable = {
                'email' : email,
                'checked': false
            };
        };


        /**
         * Ferme le modal
         */
        function onClose() {
            $uibModalInstance.dismiss('cancel');
        }

        /**
         * Valid le modal
         */
        function onSubmit(){
            if(ctrl.email!=''){
                $uibModalInstance.close({'email':ctrl.email});
            }
        }


        function onCheckEmail(){
            if(ctrl.emailJetable.checked){
                oldEmail = angular.copy(ctrl.email);
                ctrl.email = angular.copy(ctrl.emailJetable.email);
            }
            else{
                ctrl.email = angular.copy(oldEmail);
            }
        }

        /**
         * Lorsque le modal est affiché
         */
        $uibModalInstance.rendered.then(function(){

        });
    }

})();