(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.DestinataireSms
   * @description
   * # DestinataireSms
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('DestinataireSms',
      DestinataireSmsModel
    );
  /** @ngInject */
  function DestinataireSmsModel(Main) {

    function DestinataireSms(objetData) {
      Main.call(this, objetData);
    }

    DestinataireSms.prototype = {
      model: 'DestinataireSms',
      isDestinataireSms: true,

      // idDestinataireSms
      getIdDestinataireSms: function(){
        return this.idDestinataireSms;
      },
      setIdDestinataireSms: function(id){
        this.idDestinataireSms = id;
      },

      // libelle
      getLibelle: function(){
        return this.libelle;
      },
      setLibelle: function(val){
        this.libelle = val;
      },

      // sms
      getSms: function(){
        return this.sms;
      },
      setSms: function(val){
        this.sms = val;
      },

      // dateCreation
      getDateCreation: function(){
        /*
        var retour = this.dateCreation;
        if(!toDate) {
            toDate = 'DD/MM/YYYY HH:mm';
        }
        if(retour) {
            if(angular.isString(toDate)) {
                retour = moment(retour).format(toDate);
            }
            else {
                retour = new Date(retour);
            }
        }*/
        return this.dateCreation;
      },
      setDateCreation: function(val){
        this.dateCreation = val;
      }
    };

    angular.extend(DestinataireSms.prototype, Main.prototype);

    return DestinataireSms;
  }
})();
