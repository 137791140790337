(function() {

  'use strict';

  /**
   * @desc Composant recapMail
   * @example <popup-infos-devis></popup-infos-devis>
   */
  angular
    .module('collaboreApp')
    .directive('panHammer', PanHammerCtrl);

  /** @ngInject */
  function PanHammerCtrl(){
    return {
      restrict: 'A',
      scope: {
        panHammer: '&'
      },
      link: function(scope, element, attrs) {
        // again we need the native object
        var el = element[0];

        var hm = new Hammer(el);

        // add a "PAN" recognizer to it (all directions)
        hm.add( new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 0 }) );

        hm.on('pan', handleDrag);

        var lastPosX = 0;
        var lastPosY = 0;
        var isDragging = false;

        function handleDrag(ev) {

          var elem = ev.target;
          console.log(event);

          if ( ! isDragging ) {
            isDragging = true;
            lastPosX = elem.offsetX;
            lastPosY = elem.offsetY;
          }

          var posX = ev.x + lastPosX;
          var posY = ev.y + lastPosY;
          elem.style.left = posX + "px";
          elem.style.top = posY + "px";

          if (ev.isFinal) {
            isDragging = false;
          }

          /*


          // for convience, let's get a reference to our object
          var elem = ev.target;

          // DRAG STARTED
          // here, let's snag the current position
          // and keep track of the fact that we're dragging
          if ( ! isDragging ) {
            isDragging = true;
            lastPosX = elem.offsetLeft;
            lastPosY = elem.offsetTop;
          }

          // we simply need to determine where the x,y of this
          // object is relative to where it's "last" known position is
          // NOTE:
          //    deltaX and deltaY are cumulative
          // Thus we need to always calculate 'real x and y' relative
          // to the "lastPosX/Y"
          var posX = ev.deltaX + lastPosX;
          var posY = ev.deltaY + lastPosY;

          // move our element to that position
          elem.style.left = posX + "px";
          elem.style.top = posY + "px";
          console.log(elem.style);

          // DRAG ENDED
          // this is where we simply forget we are dragging
          if (ev.isFinal) {
            isDragging = false;
          }*/
        }

        scope.$on('$destroy',function(){
          hm.destroy();
        });
      }
    };
  }
})();
