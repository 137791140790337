'use strict';

/**
 * @ngdoc function
 * @name collaboreApp.controller:LoginCtrl
 * @description
 * # LoginCtrl
 * Controller of the collaboreApp
 */

var LoginCtrl = function (COLLAB_CONF, COLLAB_VALUES, $window, $rootScope, $scope, $filter, $state, $stateParams, UserService, ConfigService, UtilsService, FiltreService) {


	var vm = this;
  vm.isKerberos = COLLAB_CONF.AUTH_KERBEROS;
  vm.logWithOnelogin = COLLAB_CONF.AUTH_ONELOGIN;

	vm.versionCollab = COLLAB_CONF.VERSION_COLLAB;
	vm.onSubmitFormLogin = onSubmitFormLogin;
	vm.onClickConnexion = onClickConnexion;

	vm.loadingLogin = false;
	vm.seSouvenir = false;

	vm.login = '';
	var login = ConfigService.getUserLogin();
	if(login!==false && login!==''){
		vm.login = login;
		vm.seSouvenir = true;
	}

	vm.motdepasse = '';
	var motdepasse = ConfigService.getUserPass();
	if(motdepasse!==false && motdepasse!==''){
		vm.motdepasse = motdepasse;
		vm.seSouvenir = true;
	}

	vm.diese = '';
	vm.dieseInLS = '';
	var diese = ConfigService.getUserDiese();
	if(diese!==false && diese!==''){
		vm.diese = diese;
		vm.dieseInLS = diese;
		vm.seSouvenir = true;
	}

	if($stateParams.infosExtranetFournisseur) vm.infosExtranetFournisseur = $stateParams.infosExtranetFournisseur;
	if($stateParams.forceManuelLogin) vm.forceManuelLogin = $stateParams.forceManuelLogin;

	/*
	if(!_.isNil($stateParams.visicop) && $stateParams.visicop){
    $rootScope.openInVisicop = true;
  }*/

	if(!_.isNil($stateParams.token)){
    UserService.connexion({token: $stateParams.token}).then(function(){
      vm.displayMsgLogin = false;
      vm.loadingLogin = false;
      // Go accueil
      $state.go(UtilsService.getStateHomeCollab(), FiltreService.getObjFiltreByChannel(UtilsService.getStateHomeCollabParams()));
    });
  }

  //vm.dieseInLS = ConfigService.getUserDiese();

	// Valide le formulaire de login lors du sbimit
	function submitFormLogin(form){
		vm.displayMsgLogin = true;
		vm.erreurLogin = false;
		vm.msgLogin = '';

		// Si formulaire n'est pas rempli, message d'erreur et return;
		if(!vm.isKerberos && !vm.logWithOnelogin && !form.$valid){
			vm.erreurLogin = true;
			vm.msgLogin = 'Veuillez renseigner vos identifiants !';
			return;
		}

		var login = vm.login;
		var diese = vm.diese;

		// Supprime le @ du début
		var loginSansArobase = $filter('ltrim')(login,'@');
		// Supprime le # du début
		var dieseSansArobase = $filter('ltrim')(diese,'#');

		var params = {
			login: '@'+loginSansArobase,
			dieze: '#'+dieseSansArobase,
			mdp: vm.motdepasse
		};

		vm.loadingLogin = COLLAB_CONF.LOADING;
		UserService.connexion(params, vm.forceManuelLogin).then(function(){

			vm.displayMsgLogin = false;
			vm.loadingLogin = false;

			if(vm.seSouvenir){
				ConfigService.setUserLogin(vm.login);
				ConfigService.setUserPass(vm.motdepasse);
				// Ajoute le diese dans le local storage
				ConfigService.setUserDiese(dieseSansArobase);
			}
			else{
				ConfigService.setUserLogin(false);
				ConfigService.setUserPass(false);
				ConfigService.setUserDiese('');
			}
      UtilsService.goRouteAskedOrLogin(UtilsService.getStateHomeCollab(), FiltreService.getObjFiltreByChannel(UtilsService.getStateHomeCollabParams()));
			// Go accueil
      // $state.go(UtilsService.getStateHomeCollab(), FiltreService.getObjFiltreByChannel(UtilsService.getStateHomeCollabParams()));
		});
	}

  function onSubmitFormLogin(form){
    submitFormLogin(form);
  }

	function onClickConnexion(){
	  if(vm.logWithOnelogin) $window.location = COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_AUTHENTIFICATION_KERBEROS;
	  else submitFormLogin();
  }

	$scope.$on('msgErreur', function (event, msg) {
		//console.log(msg);
		vm.loadingLogin = false;
		vm.displayMsgLogin = true;
		vm.erreurLogin = true;
		vm.msgLogin = msg;
	});

	/*
	if(vm.isKerberos && _.isNil($stateParams.erreurKerberos)) {
    submitFormLogin();
  }*/
};

angular.module('collaboreApp').controller('LoginCtrl',[
	'COLLAB_CONF',
	'COLLAB_VALUES',
	'$window',
	'$rootScope',
	'$scope',
	'$filter',
	'$state',
	'$stateParams',
	'UserService',
	'ConfigService',
	'UtilsService',
	'FiltreService',
	LoginCtrl
]);
