(function() {
    'use strict';
    /**
     * @ngdoc factory
     * @name collaboreApp.ProxyContratIcs
     * @description
     * # ProxyContratIcs
     */

    angular.module('collaboreApp').factory('ProxyContratIcs', ProxyContratIcsModel);

    /** @ngInject */
    function ProxyContratIcsModel(Main, UtilsService, ProxyPersoIcs) {

        function ProxyContratIcs(objetData) {
            Main.apply(this, arguments);
            this.hydrate(objetData);
        }

        ProxyContratIcs.prototype = {

            isProxy: true,

            // idContratICS
            getIdContratICS: function(){
                return this.idContratICS;
            },
            setIdContratICS: function(val){
                this.idContratICS = val;
            },

            // idPortefeuille
            getIdPortefeuille: function(){
                return this.idPortefeuille;
            },
            setIdPortefeuille: function(val){
                this.idPortefeuille = val;
            },
            setPortefeuille: function(val){
                if(angular.isObject(val)){
                    this.setIdPortefeuille(val.idPortefeuille);
                }
            },

            // refContrat
            getRefContrat: function(){
                return this.refContrat;
            },
            setRefContrat: function(val){
                this.refContrat = val;
            },

            // policeNo
            getPoliceNo: function(){
                return this.policeNo;
            },
            setPoliceNo: function(val){
                this.policeNo = val;
            },

            // refFournisseur
            getRefFournisseur: function(){
                return this.refFournisseur;
            },
            setRefFournisseur: function(val){
                this.refFournisseur = val;
            },

            // refAssureur
            getRefAssureur: function(){
                return this.refAssureur;
            },
            setRefAssureur: function(val){
                this.refAssureur = val;
            },

            // objet
            getObjet: function(){
                return this.objet;
            },
            setObjet: function(val){
                this.objet = val;
            },

            getDateDebutContrat: function(value){
                if (arguments.length) {
                    this.setDateDebutContrat(value);
                } else {
                    return this.dateDebutContrat;
                }
            },
            getDateDebutContratFormat: function(toDate){
                return UtilsService.parseDate(this.dateDebutContrat, toDate);
            },
            setDateDebutContrat: function(val){
                this.dateDebutContrat = val;
            },

            getDateFinContrat: function(value){
                if (arguments.length) {
                    this.setDateFinContrat(value);
                } else {
                    return this.dateFinContrat;
                }
            },
            getDateFinContratFormat: function(toDate){
                return UtilsService.parseDate(this.dateFinContrat, toDate);
            },
            setDateFinContrat: function(val){
                this.dateFinContrat = val;
            },

            getDateDerniereReconduction: function(value){
                if (arguments.length) {
                    this.setDateDerniereReconduction(value);
                } else {
                    return this.dateDerniereReconduction;
                }
            },
            getDateDerniereReconductionFormat: function(toDate){
                return UtilsService.parseDate(this.dateDerniereReconduction, toDate);
            },
            setDateDerniereReconduction: function(val){
                this.dateDerniereReconduction = val;
            },

            getDateRevision: function(value){
                if (arguments.length) {
                    this.setDateRevision(value);
                } else {
                    return this.dateRevision;
                }
            },
            getDateRevisionFormat: function(toDate){
                return UtilsService.parseDate(this.dateRevision, toDate);
            },
            setDateRevision: function(val){
                this.dateRevision = val;
            },

            getDateResiliation: function(value){
                if (arguments.length) {
                    this.setDateResiliation(value);
                } else {
                    return this.dateResiliation;
                }
            },
            getDateResiliationFormat: function(toDate){
                return UtilsService.parseDate(this.dateResiliation, toDate);
            },
            setDateResiliation: function(val){
                this.dateResiliation = val;
            },

            getDateDerniereNego: function(value){
                if (arguments.length) {
                    this.setDateDerniereNego(value);
                } else {
                    return this.dateDerniereNego;
                }
            },
            getDateDerniereNegoFormat: function(toDate){
                return UtilsService.parseDate(this.dateDerniereNego, toDate);
            },
            setDateDerniereNego: function(val){
                this.dateDerniereNego = val;
            },

            getDateProchaineNego: function(value){
                if (arguments.length) {
                    this.setDateProchaineNego(value);
                } else {
                    return this.dateProchaineNego;
                }
            },
            getDateProchaineNegoFormat: function(toDate){
                return UtilsService.parseDate(this.dateProchaineNego, toDate);
            },
            setDateProchaineNego: function(val){
                this.dateProchaineNego = val;
            },

            // compagnie
            getCompagnie: function(){
                return this.compagnie;
            },
            setCompagnie: function(val){
                if(angular.isObject(val)){
                    if(!val.isModel || !val.isProxy) {
                        val = new ProxyPersoIcs(val);
                    }
                    this.compagnie = val;
                }
            },

            // persoC
            getFournisseur: function(){
                return this.fournisseur;
            },
            setFournisseur: function(val){
                if(angular.isObject(val)){
                    if(!val.isModel || !val.isProxy) {
                        val = new ProxyPersoIcs(val);
                    }
                    this.fournisseur = val;
                }
            }

        };

        angular.extend(ProxyContratIcs.prototype, Main.prototype);

        ProxyContratIcs.prototype.model = 'ProxyContratIcs';

        return ProxyContratIcs;
    }
})();