(function() {
  'use strict';

  /**
   * @ngdoc factory
   * @name collaboreApp.State
   * @description
   * # ProxyDestinataire
   */

  angular.module('collaboreApp').factory('ProxyDestinataire', ProxyDestinataireModel);

  /** @ngInject */
  function ProxyDestinataireModel(Main, $rootScope, UtilsService, ProxyPersoIcs) {

    function ProxyDestinataire(objetData) {

      var scope = this;

      this.setAllowSendEmail(true);

      // Si c'est un objet
      if (_.isObject(objetData)) {

        /*
        // Si il y a un libelle différent de vide et que le dieze lui est vide alors dans le libelle il y a un meila surement
        if(objetData.libelle !== "" && objetData.hasOwnProperty('dieze') && objetData.dieze === "" && UtilsService.isMail(objetData.libelle)){
            objetData.email = objetData.libelle;
            delete objetData.libelle;
            delete objetData.dieze;
        }*/

        // Hydratation
        /*
        angular.forEach(objetData,function(val,key){
          var setter = 'set' + key.ucfirst();
          if (angular.isFunction(scope[setter])) {
            scope[setter](val);
          }
        });*/
        Main.apply(this, arguments);
        this.hydrate(objetData);

        if(objetData.isDestinataireEmail) {
          this.setTypeDestinataire("destinataireEmail");
        }
      }
      // Si c'est un string (email ou groupe du style @toto#YOPMAIL
      else if (_.isString(objetData)) {
        if (UtilsService.isMail(objetData)) {
          scope.setEmail(objetData);
        }
        else if (UtilsService.hasDieze(objetData)) {
          var libelle = UtilsService.getLibelle(objetData);
          var diese = UtilsService.getDieze(objetData);
          if (libelle && diese) {
            scope.setLibelle(libelle);
            scope.setDieze(diese);
          }
        }
      }
      // Si c'est un nombre alors certainement un idGroupe
      else if (_.isNumber(objetData)) {
        scope.setIdGroupe(objetData);
      }
    }

    ProxyDestinataire.prototype = {

      model: 'ProxyDestinataire',
      isModel: true,
      isProxy: true,

      /*
      setData: function(objetData) {
        angular.extend(this, objetData);
      },
      */

      // IdGroupe
      getIdGroupe: function(){
        return this.idGroupe;
      },
      setIdGroupe: function(id){
        this.idGroupe = id;
      },

      // idDestinataireEmail
      getIdDestinataireEmail: function(){
        return this.idDestinataireEmail;
      },
      setIdDestinataireEmail: function(id){
        this.idDestinataireEmail = id;
      },

      // nom
      getNom: function(){
        return this.nom;
      },
      setNom: function(val){
        this.nom = val;
      },

      // prenom
      getPrenom: function(){
        return this.prenom;
      },
      setPrenom: function(val){
        this.prenom = val;
      },

      // Libelle
      getLibelle: function(){
        return this.libelle;
      },
      setLibelle: function(val){
        this.libelle = val;
      },

      // libelleDestinataireSms
      getLibelleDestinataireSms: function(){
        return this.libelleDestinataireSms;
      },
      setLibelleDestinataireSms: function(id){
        this.libelleDestinataireSms = id;
      },

      // Dieze
      getDieze: function(){
        return this.dieze;
      },
      setDieze: function(id){
        this.dieze = id;
      },

      // Email
      getEmail: function(){
        return this.email;
      },
      setEmail: function(id){
        this.email = id;
      },

      // AllowSendEmail
      getAllowSendEmail: function(){
        return this.allowSendEmail;
      },
      setAllowSendEmail: function(val){
        this.allowSendEmail = val;
      },

      // TypeDestinataire
      getTypeDestinataire: function(){
        return this.typeDestinataire;
      },
      setTypeDestinataire: function(val){
        this.typeDestinataire = val;
      },

      // persoICS
      getPersoICS: function(){
        return this.persoICS;
      },
      setPersoICS: function(val){
        if(angular.isObject(val)){
          this.persoICS = new ProxyPersoIcs(val);
        }
      },

      // listeEmail
      getListeEmail: function(){
        return this.listeEmail;
      },
      setListeEmail: function(val){
        this.listeEmail = val;
      },
      addInListeEmail: function(val){
        if(_.isString(val)) {
          var add = false;
          if(!_.isArray(this.listeEmail)) {
            this.listeEmail = []
          }
          this.listeEmail = _.union(this.listeEmail, [val]);
        }
      },

      // listeNumeroTelephone
      getListeNumeroTelephone: function(){
        return this.listeNumeroTelephone;
      },
      setListeNumeroTelephone: function(val){
        this.listeNumeroTelephone = val;
      },

      getDefaultLibelle: function(){
        var libelle = this.getLibelle()+this.getDieze();
        // Si même société on n'affiche pas le diese
        if(this.getDieze() === $rootScope.current.userGroupe.getDieze()) libelle = this.getLibelle();

        return libelle;
      }


    };

    angular.extend(ProxyDestinataire.prototype, Main.prototype);

    return ProxyDestinataire;
  }
})();
