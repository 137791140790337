(function() {
  'use strict';

  angular
    .module('collaboreApp')
    .service('GroupesService', GroupesServiceCtrl);

  /*@ngInject*/
  function GroupesServiceCtrl(MainService, $q,UtilsService,ErreurCollabService,Groupe,Portefeuille,GroupeAjax,PersonnaliteAjax) {


    var scope = this, firstGroupe;

    scope.getInfosGroupe = getInfosGroupe;

    /**
     * Parcour la hiérarchie pour créé un objet plus pratique à l'utilisation
     * @param groupes
     * @param newGroupes
     * @returns {*}
     */
    var parcourGroupes = function(groupes,newGroupes,immeuble){


      //var nbGroupeFils = 0;
      //var idForTrack;
      // Si 1er groupe
      if (newGroupes===true) {
        //idForTrack = 1;
        var tmpNewGroupes = {
          //'idForTrack': idForTrack,
          idGroupe: groupes.idGroupe,
          libelle: groupes.libelle,
          dieze: groupes.dieze,
          palier: groupes.palier,
          groupes: [],
          users: [],
          listePortefeuille: groupes.listePortefeuille,
          immeuble: false
        };

        if(groupes.hasOwnProperty('fiche')){
          tmpNewGroupes.fiche = groupes.fiche;
        }

        newGroupes = new Groupe(tmpNewGroupes);

        newGroupes.isFirst = true;
        newGroupes.allUsers = {};

        if(newGroupes.isImmeuble()){
          immeuble = new Groupe(tmpNewGroupes);
          delete immeuble.groupesFils;
          newGroupes.immeuble = immeuble;
        }

        firstGroupe = newGroupes;
      }else{
        //nbGroupeFils = newGroupes.groupesFils.length;
        delete newGroupes.groupesFils;
      }

      if(groupes.groupesFils.length) {

        for(var i = 0; i < groupes.groupesFils.length; i ++){
          var groupeJson = groupes.groupesFils[i];

          var GroupeInstance = new Groupe(groupeJson);

          // Si pas groupe all
          if (newGroupes!==true) {

            if(GroupeInstance.isImmeuble()){
              immeuble = new Groupe(groupeJson);
              delete immeuble.groupesFils;
            }

            if(immeuble){
              GroupeInstance.immeuble = immeuble;
            }

            if (!newGroupes.hasOwnProperty('users')) {    newGroupes.users = [];}
            if (!newGroupes.hasOwnProperty('groupes')) {  newGroupes.groupes = [];}

            // Si c'est un user
            if (GroupeInstance.isUser()) {

              delete GroupeInstance.groupesFils;
              //delete GroupeInstance.user.fichePerso;

              // Si l'user n'existe pas encore dans la liste complete des users
              if(firstGroupe.hasOwnProperty('allUsers') && !firstGroupe.allUsers.hasOwnProperty(GroupeInstance.getIdGroupe())){
                firstGroupe.allUsers[GroupeInstance.getIdGroupe()] = GroupeInstance;
              }

              newGroupes.users.push(GroupeInstance);

              // Sinon c'est un autre groupe
            } else {

              GroupeInstance.groupes = [];
              GroupeInstance.users = [];

              var newGroupe = angular.copy(GroupeInstance);
              newGroupe = parcourGroupes(GroupeInstance,newGroupe,immeuble);
              newGroupes.groupes.push(newGroupe);
            }
          }
        }

      }


      return newGroupes;
    };

    function flattenGroupes(groupe,groupesFlatten){

      if(!groupesFlatten){
        groupesFlatten = [];
      }

      var objGroupe = {
        'idGroupe' : groupe.idGroupe,
        'libelle' : groupe.libelle,
        'dieze' : groupe.dieze,
        'isUser': groupe.hasUser()
      };
      groupesFlatten.push(objGroupe);

      var groupesFils = groupe.getGroupesFils();
      if(groupesFils.length){
        for(var i = 0 ; i < groupesFils.length ; i++){
          flattenGroupes(new Groupe(groupesFils[i]),groupesFlatten);
        }
      }

      var groupesFlattenDedoublonne = [];

      if(groupesFlatten.length){

        // Parcour les groupes flatten
        for(var j = 0 ; j < groupesFlatten.length ; j++){


          var thisIdGroupe = groupesFlatten[j].idGroupe;

          var exist = false;

          // Parcour les groupes flatten dédoublonnés pour savoir si le "thisIdGroupe" existe déjà
          for(var k = 0 ; k < groupesFlattenDedoublonne.length ; k++) {
            if (thisIdGroupe === groupesFlattenDedoublonne[k].idGroupe) {
              exist = true;
              break;
            }
          }

          if(!exist){
            groupesFlattenDedoublonne.push(groupesFlatten[j]);
          }
        }
      }
      groupesFlatten = null;
      return groupesFlattenDedoublonne;
    }

    /**
     * Recupère la hiérachie d'un groupe
     * @param nom
     * @returns {IPromise<T>}
     */
    scope.getGroupes = function(nom) {

      var deferred = $q.defer();

      GroupeAjax.getHierachie({'nom':nom},function (json) {

        if (json.nb === '1') {
          var instance = new Groupe(json.Groupes[0]);
          var groupes = parcourGroupes(instance,true);
          groupes.allUsers = UtilsService.toArray(groupes.allUsers);
          deferred.resolve(groupes);
        }
        else {
          deferred.reject('Pas de Groupes en retour de GroupesService.getGroupes()');
        }

      }, function (error) {
        ErreurCollabService.erreurServlet(error,'Problème avec GroupesService.getGroupes()',deferred);
      });


      return deferred.promise;
    };

    /**
     * Recupère les groupes expediteurs
     * @returns {IPromise<T>}
     */
    scope.getListGroupeExpediteur = function(onlyMySociete) {

      var deferred = $q.defer();

      GroupeAjax.getListGroupeExpediteur({'onlyMyDiese': onlyMySociete},function (json) {

        var groupes = [];
        if (json.nb !== '0') {
          if(json.hasOwnProperty('Groupes')) {
            if(json.Groupes.length) {
              for(var i = 0; i < json.Groupes.length; i++){
                groupes.push(new Groupe(json.Groupes[i]));
              }
            }
          }
        }
        deferred.resolve(groupes);

      }, function (error) {
        ErreurCollabService.erreurServlet(error,'Problème avec GroupesService.getListGroupeExpediteur()',deferred);
      });


      return deferred.promise;
    };

    /**
     * Permet de recuperer un groupe pas son idGroupe si le groupe qui demande peut le voir
     * @param idGroupe
     * @returns {Promise}
     */
    scope.getGroupe = function(idGroupe) {

      var deferred = $q.defer();

      GroupeAjax.getGroupe({idGroupe: idGroupe}, function (json) {

        var groupe = false;
        if (json.nb === '1') {
          if(json.hasOwnProperty('Groupes')) {
            if(angular.isArray(json.Groupes) && json.Groupes.length === 1) {
              groupe = new Groupe(json.Groupes[0]);
            }
          }
        }
        deferred.resolve(groupe);

      }, function (error) {
        ErreurCollabService.erreurServlet(error,'Problème avec GroupesService.getGroupe()',deferred);
      });


      return deferred.promise;
    };

    /**
     * Recupère la hiérachie complète pour un groupe mais uniquement ce groupe
     * @param id
     * @returns {IPromise<T>}
     */
    scope.getGroupesParent = function(id, withoutEnfantImmeuble){

      var deferred = $q.defer();

      var params = {
        idGroupe:id
      };
      if(withoutEnfantImmeuble) params.withoutEnfantImmeuble = true;
      GroupeAjax.getGroupesParent(params,function(json){
        if (json.nb === '1') {

          var instance = new Groupe(json.Groupes[0]);
          var groupes = parcourGroupes(instance,true);
          groupes.allUsers = UtilsService.toArray(groupes.allUsers);
          deferred.resolve(groupes);

        }else{
          deferred.reject('Pas de résultats...');
        }
      },function(error){
        ErreurCollabService.erreurServlet(error,'Problème avec retour servlet GroupesService.getGroupesParent()',deferred);
      }) ;


      return deferred.promise;

    };

    /**
     * Recupère la liste des groupes fils d'un groupe avec pagination
     * @param id
     * @returns {IPromise<T>}
     */
    scope.getGroupesFils = function(id, page, limit){

      var deferred = $q.defer();

      if (!page) {
        page = 1;
      }
      if (!limit) {
        limit = 10;
      }

      var params = {
        idGroupe: id,
        page: page,
        limit: limit
      };

      GroupeAjax.getGroupesFils(params, function (json) {

        var obj = {
          total: parseInt(json.total),
          groupes: []
        };


        if (json.nb !== '0') {
          if(json.Groupes.length) {
            for (var i = 0;i < json.Groupes.length; i++) {
              obj.groupes.push(new Groupe(json.Groupes[i]));
            }
          }
        }


        deferred.resolve(obj);

      }, function (error) {
        ErreurCollabService.erreurServlet(error,'Problème avec retour servlet GroupesService.getGroupesFils()',deferred);
      });


      return deferred.promise;

    };

    /**
     * Recupère la hiérachie complète pour un groupe mais uniquement ce groupe et aplati le résultat
     * @param id
     * @returns {IPromise<T>}
     */
    scope.getGroupesFlatten = function(id){

      var deferred = $q.defer();

      GroupeAjax.getGroupesParent({'idGroupe':id},function(json){

        if (json.nb === '1') {

          var instance = new Groupe(json.Groupes[0]);
          var groupes = flattenGroupes(instance);
          deferred.resolve(groupes);

        }else{
          deferred.reject('Pas de résultats...');
        }
      },function(error){
        ErreurCollabService.erreurServlet(error,'Problème avec retour servlet GroupesService.getGroupesFlatten()',deferred);
      }) ;

      return deferred.promise;
    };

    /**
     * Créé une instance de 'Groupe'
     * @param groupeObj
     * @returns {Groupe|*}
     */
    scope.newGroupe = function(groupeObj){
      return new Groupe(groupeObj);
    };

    /**
     * Recupère les informations d'une personnalité
     * @param user
     * @returns {IPromise<T>}
     */
    scope.getInfosPersonnalite = function(user,infosPersoICS,groupByMetier){

      var retour = false;
      var deferred = $q.defer();

      var idGroupe = false;
      if(angular.isNumber(user)) idGroupe = user;
      else if (angular.isObject(user)) idGroupe = user.idGroupe;

      if(idGroupe){
        var params = {
          groupe: idGroupe
        };

        if(infosPersoICS) params.type = 'persoICS';
        PersonnaliteAjax.getPersonnalite(params,function(json){

          if(json.nb!=='0'){
            retour = [];

            if(!groupByMetier){
              for(var i = 0 ; i < json.Portefeuilles.length ; i++){
                retour.push(new Portefeuille(json.Portefeuilles[i]));
              }

              deferred.resolve(retour);
            }
            else{

              var byMetier = {};
              for(var j = 0 ; j < json.Portefeuilles.length ; j++){
                var portefeuille = new Portefeuille(json.Portefeuilles[j]);

                if(!byMetier.hasOwnProperty(portefeuille.getIdPortefeuille())){
                  var copyPortefeuille = angular.copy(portefeuille);
                  delete copyPortefeuille.listePersonnalite;
                  copyPortefeuille.metiers = {};
                  byMetier[portefeuille.getIdPortefeuille()] = copyPortefeuille;
                }

                if(portefeuille.hasOwnProperty('listePersonnalite')) {
                  for(var k = 0 ; k < portefeuille.listePersonnalite.length ; k++){
                    var perso = portefeuille.listePersonnalite[k];
                    if(!byMetier[portefeuille.getIdPortefeuille()].metiers.hasOwnProperty(perso.metier)){
                      byMetier[portefeuille.getIdPortefeuille()].metiers[perso.metier] = {
                        metier: perso.metier,
                        personnalites: {}
                      };
                    }

                    if(!byMetier[portefeuille.getIdPortefeuille()].metiers[perso.metier].personnalites.hasOwnProperty(perso.numero)){
                      byMetier[portefeuille.getIdPortefeuille()].metiers[perso.metier].personnalites[perso.numero] = perso;
                    }

                  }
                }

              }


              angular.forEach(byMetier,function(portefeuille){
                var copyPort = angular.copy(portefeuille);
                delete copyPort.metiers;

                copyPort.listeMetiers = [];

                angular.forEach(portefeuille.metiers,function(metier){

                  var copyMetier = angular.copy(metier);
                  delete copyMetier.personnalites;

                  copyMetier.listePersonnalites = [];

                  angular.forEach(metier.personnalites,function(personnalite){
                    copyMetier.listePersonnalites.push(personnalite);
                  });

                  copyPort.listeMetiers.push(copyMetier);

                });

                retour.push(copyPort);

              });
              deferred.resolve(retour);


              /*
               for(var j = 0 ; j < json.Portefeuilles.length ; j++){
               var portefeuilleExist   = false,
               metierExist         = false;

               var portefeuille = new Portefeuille(json.Portefeuilles[j]);

               for(var k = 0 ; k < retour.length ; k++){

               if(retour[k].idPortefeuille === portefeuille.getIdPortefeuille()){
               portefeuilleExist = true;

               for(var l = 0 ; l < retour[k].listeMetiers.length ; l++){

               if(retour[k].listeMetiers[l].metier === ){

               }
               }

               }
               }

               if(!portefeuilleExist){
               delete portefeuille.listePersonnalite;
               portefeuille.listeMetiers = [{
               'metier': perso.metier,
               'listePersonnalite': []
               }];
               retour.push(portefeuille);
               }

               }
               */

            }

            //deferred.resolve(retour);

          }else{
            deferred.reject('Pas de résultats...');
          }

        },function(error){
          ErreurCollabService.erreurServlet(error,'Problème avec retour servlet GroupesService.getInfosPersonnalite()',deferred);
        }) ;
      }

      return deferred.promise;

    };

    /**
     * Permet d'executer une fonction pour chaque groupes
     * @param groupe
     * @param fct
     * @returns {*}
     */
    scope.recurseGroupes = function(groupe,fct){
      if(angular.isObject(groupe)){

        if(groupe.hasOwnProperty(('groupes'))){

          if(groupe.groupes.length){
            for(var i = 0 ; i < groupe.groupes.length ; i++){
              scope.recurseGroupes(groupe.groupes[i],fct);
            }
          }

          if(fct){
            return fct(groupe);
          }
        }

      }
    };


    //------------------ New -----------------//
    function getInfosGroupe(idGroupe) {

      var deferred = $q.defer();

      var params = {
        action: 'getInfosGroupe',
        idGroupeSearch: idGroupe
      };

      GroupeAjax.postToGroupeServlet(params, function (json) {

        if (json.success) deferred.resolve(MainService.convertJsonToModel(json, 'groupe', Groupe, deferred));
        else deferred.reject(json.message);

      }, function (error) {
        ErreurCollabService.erreurServlet(error, 'Problème avec retour servlet GroupesService.getInfosGroupe()', deferred);
      });

      return deferred.promise;
    }
  }
})();
