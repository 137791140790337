(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .component('assureurSinistre', {
      templateUrl: 'app/topics/evenement/sinistre/assureur-sinistre.component.html',
      controller: AssureurSinistreController,
      controllerAs: 'assureursinistrectrl',
      bindings: {
        contrat: '<',
        params: '<',
        infosEvenement: '<',
        disableButtons: '<',
        enableSubmit: '<',
        onUpdate: '&'
      }
    });

  /** @ngInject */
  function AssureurSinistreController($scope, Contrat, PersoIcs, ContratIcs, Renseignement, ModalsService, AnnuaireService, uuid) {
    var ctrl = this;


    ctrl.loading = false;

    ctrl.datePickerOptions = {
      startingDay: 1,
      showWeeks: true
    };

    ctrl.onClickAddLigneInfosFournisseur = onClickAddLigneInfosFournisseur;
    ctrl.onClickDeleteLigneInfosFournisseur = onClickDeleteLigneInfosFournisseur;
    ctrl.onClickOpenDatePremiereSouscription = onClickOpenDatePremiereSouscription;
    ctrl.onClickNouveau = onClickNouveau;
    ctrl.onClickSearch = onClickSearch;
    ctrl.onClickEditCustom = onClickEditCustom;
    ctrl.onClickCancelEditCustom = onClickCancelEditCustom;
    ctrl.onClickSave = onClickSave;

    var listernerOnUpdateAssureur = $scope.$on('updateAssureur', function(ev, codeMetier){
      emitUpdate(codeMetier);
    });

    /********** Global **********/
    ctrl.$onInit = function () {
      ctrl.uniqId = uuid.new();
      if(_.isNil(ctrl.params)){
        initParams();
      }
    };

    ctrl.$onChanges = function(changes) {
      if(_.isObject(changes.params) && _.isObject(changes.params.currentValue)) {
        ctrl.params = angular.copy(changes.params.currentValue);
      }
      if(_.isObject(changes.contrat)) {
        ctrl.contrat = angular.copy(changes.contrat.currentValue);
        init(ctrl.contrat);
      }
    };

    ctrl.$onDestroy = function(){
      emitUpdate();
      if(listernerOnUpdateAssureur) listernerOnUpdateAssureur();
    };

    function initParams(){
      ctrl.params = {
        edit: false,
        new:false,
        custom: false,
        savePrevContrat: null
      };
    }

    /**
     * Traite les donnée pour les renvoyer au composant qui appel celui si
     */
    function emitUpdate(codeMetier){

      var contratIcs = ctrl.contrat.contratICS;

      var persoIcsFournisseur = contratIcs.fournisseur;

      // Si pas de contratIcs ou nouveau contrat
      if(ctrl.params.new) {

        persoIcsFournisseur = new PersoIcs();
        persoIcsFournisseur.setCodemetier((!_.isNil(codeMetier) ? codeMetier : 'ASS'));
        persoIcsFournisseur.setType('A');

        if(ctrl.infosEvenement && ctrl.infosEvenement.hasOwnProperty('portefeuille') && angular.isObject(ctrl.infosEvenement.portefeuille) && ctrl.infosEvenement.portefeuille.idPortefeuille){
          contratIcs.setPortefeuille(ctrl.infosEvenement.portefeuille);
          persoIcsFournisseur.portefeuille = ctrl.infosEvenement.portefeuille;
        }
      }

      if(contratIcs) {
        if(ctrl.dateDebutContrat) contratIcs.setDateDebutContrat(ctrl.dateDebutContrat);
      }

      if(_.isObject(persoIcsFournisseur)) {
        // Si custom
        if(!persoIcsFournisseur.getNumero() && !persoIcsFournisseur.getNoperso()) {
          persoIcsFournisseur.setLibelle(ctrl.contrat.tmpFournisseur.nom.getDonnee());
        }

        var informations = [];
        informations.push(ctrl.contrat.tmpFournisseur.nom);
        informations.push(ctrl.contrat.tmpFournisseur.adresse);
        informations = checkInformations(informations, ctrl.contrat.tmpFournisseur.telephones);
        informations = checkInformations(informations, ctrl.contrat.tmpFournisseur.portables);
        informations = checkInformations(informations, ctrl.contrat.tmpFournisseur.faxs);
        informations = checkInformations(informations, ctrl.contrat.tmpFournisseur.mails);

        persoIcsFournisseur.informations = informations;
        ctrl.contrat.contratICS.fournisseur = persoIcsFournisseur;
      }
      else ctrl.contrat.contratICS = null;

      var objUpdate = {
        obj: {
          contrat: ctrl.contrat.contratICS,
          params: ctrl.params
        }
      };
      ctrl.onUpdate(objUpdate);
    }

    function defaultObj(contratIcs) {
      return {
        contratICS: (!_.isNil(contratIcs)) ? contratIcs: new ContratIcs(),
        tmpFournisseur: {
          nom: new Renseignement('nom'),
          adresse: new Renseignement('adresse'),
          telephones: [],
          portables: [],
          faxs: [],
          mails: []
        }
      };
    }

    /**
     * Initialise
     * @param persoICS
     */
    function init(contratIcs) {
      ctrl.contrat = angular.copy(defaultObj(contratIcs));
      var contratICS = ctrl.contrat.contratICS;

      if(!_.isNil(contratICS.getDateDebutContrat())) ctrl.dateDebutContrat = contratICS.getDateDebutContratFormat(true);

      if(!_.isNil(contratICS.getFournisseur())){
        var hasNom = false;
        if(contratICS.getFournisseur().hasInformations()) {
          for(var i = 0; i < contratICS.getFournisseur().getInformations().length; i++) {
            var renseignement = contratICS.getFournisseur().getInformations()[i];
            if(renseignement.getReference()) {
              if(renseignement.getReference().isNom()) {
                ctrl.contrat.tmpFournisseur.nom = renseignement;
                hasNom = true;
              }
              if(renseignement.getReference().isAdresse()) {
                ctrl.contrat.tmpFournisseur.adresse = renseignement;
              }
              if(renseignement.getReference().isTelephone()) {
                ctrl.contrat.tmpFournisseur.telephones.push(renseignement);
              }
              if(renseignement.getReference().isPortable()) {
                ctrl.contrat.tmpFournisseur.portables.push(renseignement);
              }
              if(renseignement.getReference().isFax()) {
                ctrl.contrat.tmpFournisseur.faxs.push(renseignement);
              }
              if(renseignement.getReference().isEmail()) {
                ctrl.contrat.tmpFournisseur.mails.push(renseignement);
              }
            }
          }
        }
        if(!hasNom) ctrl.contrat.tmpFournisseur.nom.setDonnee(contratICS.getFournisseur().getLibelle());
      }

      initTmp(ctrl.contrat);
    }

    function initTmp(objContrat) {
      var objTmp = {};
      if(!objContrat) {
        objContrat = angular.copy(defaultObj());
        ctrl.contrat = objContrat;
      }

      if(_.isNil(ctrl.params)) initParams();

      if(_.isObject(objContrat.contratICS.fournisseur) && !objContrat.contratICS.fournisseur.getNumero() && !objContrat.contratICS.fournisseur.getNoperso()) {
        ctrl.params.custom = true;
      }
    }


    function checkInformations(informations, array){
      if(array.length) {
        for(var i = 0; i < array.length; i++) {
          informations.push(array[i]);
        }
      }
      return informations;
    }

    /**
     * Lors du clique sur le bouton de supression d'une ligne de renseignement
     * @param array
     * @param index
     */
    function onClickDeleteLigneInfosFournisseur(array, index) {
      if(ctrl.contrat.tmpFournisseur.hasOwnProperty(array) && angular.isArray(ctrl.contrat.tmpFournisseur[array]) && ctrl.contrat.tmpFournisseur[array].length) {
        ctrl.contrat.tmpFournisseur[array].splice(index, 1);
      }
    }

    /**
     * Lors du clique sur le bouton d'ajout d'une ligne de renseignement
     * @param type
     */
    function onClickAddLigneInfosFournisseur(type) {
      if(type === 'telephone') { ctrl.contrat.tmpFournisseur.telephones.push(new Renseignement('telephone'));}
      else if(type === 'portable') { ctrl.contrat.tmpFournisseur.portables.push(new Renseignement('portable'));}
      else if(type === 'fax') { ctrl.contrat.tmpFournisseur.faxs.push(new Renseignement('fax'));}
      else if(type === 'email') { ctrl.contrat.tmpFournisseur.mails.push(new Renseignement('email'));}
    }

    /**
     * Lors du clique sur le bouton pour ouvrir le datipicker de la date de sinistre
     */
    function onClickOpenDatePremiereSouscription(){
      ctrl.datePremiereSouscriptionOpened = true;
    }

    /**
     * Lors du clique sur le bouton pour editer le contrat
     */
    function onClickEditCustom() {
      ctrl.params.savePrevContrat = angular.copy(ctrl.contrat);
      ctrl.params.edit = true;
    }

    /**
     * Lors du clique sur le bouton pour annuler l'édition de la perso
     */
    function onClickCancelEditCustom() {
      if(_.isObject(ctrl.params.savePrevContrat)) ctrl.contrat = angular.copy(ctrl.params.savePrevContrat);
      initParams();
    }

    /**
     * Lors du clique sur le bouton pour ajouter un nouveau contrat
     */
    function onClickNouveau() {
      ctrl.params.savePrevContrat = angular.copy(ctrl.contrat);
      initTmp();
      ctrl.dateDebutContrat = null;
      ctrl.params.edit = true;
      ctrl.params.new = true;
    }

    /**
     * Ouvre l'annuaire pour rechercher dans l'annuaire
     */
    function onClickSearch() {

      var params = {
        stringTo: 'tag',
        limitDestinataire: 1,
        onlyFournisseur: true,
        //onlyContrats: true,
        searchContratsImmeubleWhenOpen: false,
        infosEvent: {}
      };

      if(ctrl.infosEvenement) params.infosEvent = ctrl.infosEvenement;

      params.infosEvent.displayContratsLinks = true;

      var options = {
        size: 'full',
        windowClass: 'modal-collabore modal-collabore-full',
        backdrop: 'static'
      };

      ModalsService
        .modalComponent('modalAnnuaire', params, options)
        .then(function(obj) {
          var contratIcs = null;

          // Si choix d'un contrat
          if(angular.isObject(obj) && angular.isObject(obj.contrat)) {
            var contrat = new Contrat(obj.contrat);
            contratIcs = new ContratIcs();
            contratIcs.setRefContrat((contrat.getRefCon() !== '') ? contrat.getRefCon() : null);
            contratIcs.setRefAssureur(null);
            contratIcs.setRefFournisseur((contrat.getRefFou() !== '') ? contrat.getRefFou() : null);
            contratIcs.setObjet((contrat.getObjet(true) !== '') ? contrat.getObjet(true) : null);
            contratIcs.setPoliceNo((contrat.getRefCon() !== '') ? contrat.getRefCon() : null);
            contratIcs.setDateDebutContrat((contrat.getDat1Sou() !== '') ? contrat.getDat1Sou() : null);
            contratIcs.setDateFinContrat((contrat.getDatFinC() !== '') ? contrat.getDatFinC() : null);

            if(contrat.getFournisseur()){
              var persoIcs = new PersoIcs();
              persoIcs.setPersoIcs(contrat.getFournisseur());
              persoIcs.setType('F');
              if(obj.hasOwnProperty('portefeuille')) persoIcs.setPortefeuille(obj.portefeuille);

              contratIcs.setFournisseur(persoIcs);
            }

            if(angular.isObject(obj.portefeuille)) {
              contratIcs.setPortefeuille(obj.portefeuille);

              if(contrat.getPersoC() && contrat.getPersoC() !== '') {
                ctrl.loading = true;

                AnnuaireService
                  .getPerso(contrat.getPersoC(), contratIcs.getPortefeuille())
                  .then(function (retourPerso) {
                    if(angular.isObject(retourPerso) && angular.isObject(retourPerso.perso)) {
                      var persoIcsCompagnie = new PersoIcs();
                      persoIcsCompagnie.setPersoIcs(retourPerso.perso);
                      persoIcsCompagnie.setType('F');
                      if(angular.isObject(retourPerso.portefeuille)) persoIcsCompagnie.setPortefeuille(retourPerso.portefeuille);
                      contratIcs.setCompagnie(persoIcsCompagnie);
                    }
                  })
                  .finally(function() {
                    ctrl.loading = false;

                    init(contratIcs);
                    emitUpdate();
                  });
              }
              else {
                init(contratIcs);
                emitUpdate();
              }
            }
            else {
              init(contratIcs);
              emitUpdate();
            }
          }
          // Si choix d'un fournisseur
          else if(angular.isObject(obj) && angular.isObject(obj.fournisseur)) {
            contratIcs = new ContratIcs();
            contratIcs.isFournisseur = true;

            var persoIcs = new PersoIcs();
            persoIcs.setPersoIcs(obj.fournisseur);
            persoIcs.setType('F');
            if(angular.isObject(obj.portefeuille)) {
              contratIcs.setPortefeuille(obj.portefeuille);
              persoIcs.setPortefeuille(obj.portefeuille);
            }

            contratIcs.setFournisseur(persoIcs);

            init(contratIcs);
            emitUpdate();
          }
        });
    }

    /**
     * Lors du clique sur le bouton pour sauvegarder l'expert du sinistre
     */
    function onClickSave() {
      emitUpdate();
    }
  }
})();

