(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.Renseignement
   * @description
   * # Renseignement
   * Service in the collaboreApp.
   */

  angular.module('collaboreApp').factory('Renseignement', RenseignementModel);

  /** @ngInject */
  function RenseignementModel(Main, Reference, State) {

    function Renseignement(objetData) {
      if(angular.isString(objetData)) {
        objetData = {reference: new Reference(objetData)};
      }
      Main.apply(this, arguments);
      this.hydrate(objetData);
    }

    Renseignement.prototype = {

      model: 'Renseignement',
      isRenseignement: true,

      getIdRenseignement: function(){
        return this.idRenseignement;
      },
      setIdRenseignement: function(id){
        this.idRenseignement = id;
      },

      getDonnee: function(value){
        if (arguments.length) {
          this.setDonnee(value);
        } else {
          return this.donnee;
        }
      },
      /*getDonnee: function(){
          return this.donnee;
      },*/
      setDonnee: function(valDonnee){
        this.donnee = valDonnee;
      },

      // Reference
      getReference: function(){
        return this.reference;
      },
      setReference: function(valRef){
        if(valRef && (angular.isString(valRef) || (angular.isObject(valRef) && !valRef.isModel))) {
          valRef = new Reference(valRef);
        }
        this.reference = valRef;
      },

      // State
      getState: function(){
        return this.state;
      },
      setState: function(valState){
        if(valState){
          if(!valState.isModel){
            valState = new State(valState);
          }
        }
        this.state = valState;
      },

      hasState: function(){
        var retour =  false;
        if(this.getState()!==null && this.getState()!==undefined){
          retour  = true;
        }
        return retour;
      },

      getChampICS: function(){
        return this.champICS;
      },
      setChampICS: function(valChamp){
        this.champICS = valChamp;
      },

      getNopersoMail: function(){
        return this.nopersoMail;
      },
      setNopersoMail: function(valNoperso){
        this.nopersoMail = valNoperso;
      },

      getTypePersoMail: function(){
        return this.typePersoMail;
      },
      setTypePersoMail: function(valTypePersoMail){
        this.typePersoMail = valTypePersoMail;
      },

      getLibelle: function(){
        return this.libelle;
      },
      setLibelle: function(valLibelle){
        this.libelle = valLibelle;
      },

      isEmail: function(){
        if(this.getReference() && this.getReference().isEmail()) return true;
        return false;
      },

      isEmailTmp: function(){
        if(this.getChampICS() && this.getChampICS() === "yopmail") return true;
        return false;
      },

      isPortable: function(){
        if(this.getReference() && this.getReference().isPortable()) return true;
        return false;
      },

      isPortableInfo: function(){
        if(this.getReference() && this.getReference().isPortableInfo()) return true;
        return false;
      },

      isCommentaire: function(){
        if(this.getReference() && this.getReference().isCommentaire()) return true;
        return false;
      },

      isPrenom: function(){
        if(this.getReference() && this.getReference().isPrenom()) return true;
        return false;
      },

      isNom: function(){
        if(this.getReference() && this.getReference().isNom()) return true;
        return false;
      },

      isTelephone: function(){
        if(this.getReference() && this.getReference().isTelephone()) return true;
        return false;
      },

      isAdresse: function(){
        if(this.getReference() && this.getReference().isAdresse()) return true;
        return false;
      },

      isFax: function(){
        if(this.getReference() && this.getReference().isFax()) return true;
        return false;
      },

      isCodePostal: function(){
        if(this.getReference() && this.getReference().isCodePostal()) return true;
        return false;
      },

      isVille: function(){
        if(this.getReference() && this.getReference().isVille()) return true;
        return false;
      }
    };


    Renseignement.REFERENCE = Reference;
    Renseignement.STATE = State;

    angular.extend(Renseignement.prototype, Main.prototype);

    return Renseignement;
  }
})();
