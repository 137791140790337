(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name collaboreApp.ContenuEvenementFacture
   * @description
   * # ContenuEvenementFacture
   * Service in the collaboreApp.
   */

  angular.module('collaboreApp').factory('ContenuEvenementFacture', ContenuEvenementFactureModel);

  /** @ngInject */
  function ContenuEvenementFactureModel(COLLAB_CONF, $q, $injector, Contenu) {

    var ContenuEvenementFacture = function() {
      Contenu.apply(this, arguments);
      this.typeContenu = COLLAB_CONF.TYPE_CONTENU_FACTURE;
    };

    ContenuEvenementFacture.prototype = {

      model: 'ContenuEvenementFacture',
      isDevis: false,
      isIntervention: false,
      isFacture: true,

      getDestinataire: function(){
        if(_.isArray(this.getDestinataires()) && !_.isEmpty(this.getDestinataires())) {
          return this.getDestinataires()[0];
        }
        return null;
      },

      getNomFournisseur: function(){
        if(this.getPersoICS()) {
          return this.getPersoICS().getNom();
        }
        if(this.getDestinataire()) {
          return this.getDestinataire().getDefaultLibelle();
        }
        return 'NC';
      },

      // DescriptionFacture
      getDescriptionFacture: function(){
        return this.descriptionFacture;
      },
      setDescriptionFacture: function(string){
        this.descriptionFacture = string;
      },

      // IdDevisPere
      getIdDevisPere: function(){
        return this.idDevisPere;
      },
      setIdDevisPere: function(int){
        this.idDevisPere = int;
      },

      // IdInterventionPere
      getIdInterventionPere: function(){
        return this.idInterventionPere;
      },
      setIdInterventionPere: function(int){
        this.idInterventionPere = int;
      },

      // DateEnvoi
      /*
       getDateEnvoi: function(){
       return this.dateEnvoi;
       },
       setDateEnvoi: function(date){
       this.dateEnvoi = date;
       },*/

      // DateFacture
      /*
       getDateFacture: function(){
       return this.dateReception;
       },
       setDateFacture: function(date){
       this.dateFacture = date;
       },*/

      // Groupement
      getGroupement: function(){
        return this.groupement;
      },
      setGroupement: function(string){
        this.groupement = string;
      },

      //******** Conseil Syndical **************/

      // AcceptedByCS
      getAcceptedByCS: function(){
        return this.acceptedByCS;
      },
      setAcceptedByCS: function(bool){
        this.acceptedByCS = bool;
      },

      // RefusedByCS
      getRefusedByCS: function(){
        return this.refusedByCS;
      },
      setRefusedByCS: function(bool){
        this.refusedByCS = bool;
      },

      // DateRefusedByCS
      /*
       getDateRefusedByCS: function(){
       return this.dateRefusedByCS;
       },
       setDateRefusedByCS: function(date){
       this.dateRefusedByCS = date;
       },*/

      // GroupeValidationCS
      getGroupeValidationCS: function(){
        return this.groupeValidationCS;
      },
      setGroupeValidationCS: function(val){
        this.groupeValidationCS = val;
      },

      // DateValidationCS
      /*
       getDateValidationCS: function(){
       return this.dateValidationCS;
       },
       setDateValidationCS: function(date){
       this.dateValidationCS = date;
       },*/


      //******** Syndic **************/

      // AcceptedBySyndic
      getAcceptedBySyndic: function(){
        return this.acceptedBySyndic;
      },
      setAcceptedBySyndic: function(bool){
        this.acceptedBySyndic = bool;
      },

      // RefusedBySyndic
      getRefusedBySyndic: function(){
        return this.refusedBySyndic;
      },
      setRefusedBySyndic: function(bool){
        this.refusedBySyndic = bool;
      },

      // DateRefusedBySyndic
      /*
       getDateRefusedBySyndic: function(){
       return this.dateRefusedBySyndic;
       },
       setDateRefusedBySyndic: function(date){
       this.dateRefusedBySyndic = date;
       },*/

      // GroupeValidationSyndic
      getGroupeValidationSyndic: function(){
        return this.groupeValidationSyndic;
      },
      setGroupeValidationSyndic: function(val){
        this.groupeValidationSyndic = val;
      },

      // DateValidationSyndic
      /*
       getDateValidationSyndic: function(){
       return this.dateValidationSyndic;
       },
       setDateValidationSyndic: function(date){
       this.dateValidationSyndic = date;
       },*/

      /**
       * Si la facture est traité (envoyé dans comfact)
       * @returns {boolean}
       */
      isTraite: function(){
        if(this.getListeDocument().length && !_.isNil(this.getListeDocument()[0].getDateSentToComfactfacture())){
          return true;
        }
        return false;
      },

      getDateSentToComfact: function(format){
        if(this.isTraite()){
          return this.getListeDocument()[0].getDateSentToComfactfacture(format);
        }
        return false;
      },

      isWaiting: function(){
        if(!this.getAcceptedBySyndic() && !this.getRefusedBySyndic() && !this.isCloture()){
          return true;
        }
        return false;
      },

      isAccepte: function(){
        if(this.getAcceptedBySyndic()){
          return true;
        }
        return false;
      },

      isRefuse: function(){
        if(this.getRefusedBySyndic()){
          return true;
        }
        return false;
      },

      /**
       * Recupère le document facture qui est pour le moment le prermier document de la liste des documents
       * @returns {*}
       */
      getDocumentFacture: function(){
        if(this.hasDocuments()){
          return this.getListeDocument()[0];
        }
        return false;
      },

      /**
       * Permet de créer la facture actuel dans un devis
       * @param facture
       * @returns {IPromise<T>}
       */
      createFactureInDevis: function (idDevis) {

        var FactureService = $injector.get('FactureService');
        var ProxyContenuFacture = $injector.get('ProxyContenuFacture');
        var _this = this;
        var deferred = $q.defer();
        var params = {
          idDevis: idDevis,
          json: new ProxyContenuFacture(_this).toJson()
        };
        FactureService.createFactureInDevis(params)
          .then(function (facture) {
            _this.setData(facture);
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      }
    };

    // Copie le prototype de Contenu dans le prototype de ContenuEvenementFacture
    // ContenuEvenementFacture hérite donc des methodes de Contenu
    angular.extend(ContenuEvenementFacture.prototype, Contenu.prototype);

    return ContenuEvenementFacture;
  }
})();
