'use strict';

var tagCollab = function () {
	return {
		restrict: 'EA',
		replace: true,
		scope: {
			tags: '=',					// <Array> Liste des tags
			isTypeTag: '=?',			// <Boolean> Permet de dire que les tags doivent être considéré comme tag type
			tagCollabHideLibelle: '=',	// <Boolean> Permet de cacher le libelle pour avoir un tag plus petit
			typeTag: '=',				// <String> Type de tag
			itemContenu: '=',			// <Object> Contenu
			nbVisible: '@',				// <String> Nombre de tags à afficher
			onClickDeleteTag: '&',		// <Function> Fonction à executer lors du clique sur le bouton delete du tag
			onClickTag: '&',			// <Function> Fonction à exectuer lors du clique sur le libelle tu tag
			allowDeleteTag: '=',		// <Boolean> Affiche/Cache le bouton delete des tags
			allowDeleteEachTag: '&',	// <Function> Fonction à exécuter pour chaque tag. Permet d'afficher ou cacher le bouton delete
			onUpdate: '&'
		},
		templateUrl: 'app/scripts/tags/directives/tagCollab.html',
		link: function(scope,element,attrs){

			scope.libelleTagCliquable = false;


			// Si l'attribut "onClickTag" est défini, c'est à dire qu'il y a une fonction à executer lors du clique sur le libelle du tag
			if(angular.isDefined(attrs.onClickTag)){
				scope.libelleTagCliquable = true;
				scope.fctOnClickTag = scope.onClickTag;
			}

			// Si l'attribut "onClickDeleteTag" est défini, c'est à dire qu'il y a une fonction à executer lors du clique sur le bouton delete du tag
			if(angular.isDefined(attrs.onClickDeleteTag)){
				scope.fctOnClickDeleteTag = scope.onClickDeleteTag;
			}

			// Si l'attribut "allowDeleteEachTag" est défini, c'est à dire qu'il y a une fonction à executer pour chaque tag afin de savoir si il est supprimable
			if(angular.isDefined(attrs.allowDeleteEachTag)){
				scope.fctAllowDeleteEachTag = scope.allowDeleteEachTag;
			}
		},
		controllerAs: 'tagcollabctrl',
		controller: ['$scope','$window','$state','TagService','FiltreService','UtilsService','NotificationsToastServices', function($scope,$window,$state,TagService,FiltreService,UtilsService,NotificationsToastServices) {

			var vm = this;

			vm.onClickTag 				= onClickTag;				// Click sur le libelle si il y a une fonction à executer
			vm.onClickFiltreByTypeTag 	= onClickFiltreByTypeTag;	// Filtre les topic sur un type tag
			vm.allowDeleteTag 			= allowDeleteTag;			// Execute a chaque tag pour savoir il peut etre delete
			vm.onDeleteTag 				= onDeleteTag;				// Delete un tag


			/**
			 * Lors du clique sur un libelle de tag
			 * @param tag
             */
			function onClickTag(tag){
				if($scope.libelleTagCliquable && $scope.fctOnClickTag){
					$scope.fctOnClickTag()(tag);
				}
			}

			function openWindowFiltreByTypeTag(tag){
				var parametres = FiltreService.getObjFiltreByTagType(tag);
				var url = $state.href('topics.tous', parametres);
				$window.open(url,'_blank');
			}

			/**
			 * Lors du clique sur le bouton filtre d'un type tag
			 * @param tag
             */
			function onClickFiltreByTypeTag(tag){

				if (angular.isObject(tag)) {

					// Si le tag à déjà un idTag alors génération du lien de la recherche et on l'execute dans un nouvel onglet
					if (tag.hasOwnProperty('idTag')) {
						openWindowFiltreByTypeTag(tag);
					}else{
						vm.filtreLoading = true;
						var libelleCurrentTag = tag.libelle;
						var exist = false;

						var typeTag = $scope.typeTag;
						if(!$scope.typeTag){
							if(tag.hasOwnProperty('typeTag')){
								typeTag = tag.typeTag.libelle;
							}
						}

						// Recupère les tag suggéré pour ce tag
						TagService.getWithType(typeTag,libelleCurrentTag,0).then(function(tags){
							// Si il y a des réponses
							if(tags.length){
								// Pour chaque réponse
								for(var j = 0 ; j < tags.length ; j++){
									var tagSuggere = tags[j];
									// Si le libelle du tag correspond exactement au tag suggéré
									if(tagSuggere.getLibelle() === libelleCurrentTag){
										exist = tagSuggere;
										break;
									}
								}
							}
							// Si le tag exist alors on filtre
							if(exist){
								openWindowFiltreByTypeTag(exist);
							// Sinon on affiche un message
							}else{
								NotificationsToastServices.error('Aucun résultats !',libelleCurrentTag);
							}
						}).finally(function(){
							vm.filtreLoading = false;
						});
					}
				}
			}

			/**
			 * Fonction executé à chaque tag pour savoir il peut etre supprimé
			 * @param tag
             * @returns {boolean}
             */
			function allowDeleteTag(tag){
				var retour = true;
				// Si il y a une fonction à executer à chaque tag pour savoir si il peut etre delete
				if($scope.fctAllowDeleteEachTag){
					retour = $scope.fctAllowDeleteEachTag()(tag);
				// Si pas de fonction
				}else{
					// Si "allowDeleteTag" est défini
					if(angular.isDefined($scope.allowDeleteTag)){
						// Si "allowDeleteTag" a false
						if(!$scope.allowDeleteTag){
							retour = false;
						}
					}
				}
				return retour;
			}

			/**
			 * Permet de delete un tag. Si l'itemContenu à un idContenu alors il doit le supprimer de la BDD par le biais de la servlet
			 * @param tag <Tag>
             */
			function onDeleteTag(tag){

				// Si il y a une fonction à exectuer lors du clique sur le bouton delete du tag, elle est prioritaire
				if($scope.fctOnClickDeleteTag){
					$scope.fctOnClickDeleteTag()(tag);
				}else{
					// Si c'est un contenu existant
					if($scope.itemContenu.idContenu){

						vm.loading = true;

						var typeTag = $scope.typeTag;
						if(!$scope.typeTag){
							if(tag.hasOwnProperty('typeTag')){
								typeTag = tag.typeTag.libelle;
							}
						}

						$scope.itemContenu.deleteTag(tag,typeTag).then(function(){

							$scope.tags = UtilsService.removeWith($scope.tags,{idTag:tag.idTag});

							if($scope.onUpdate){
								$scope.onUpdate({'objUpdate':{'deleteTag':tag}});
							}

							// Emit l'événement 'onDelTag'
							//$scope.$emit('onDelTag',tag);

						}).finally(function(){
							vm.loading = false;
						});

					// Si c'est une création de contenu
					}else{
						$scope.tags = UtilsService.removeWith($scope.tags,{libelle:tag.libelle});
					}
				}


			}

		}]

	};
};

angular.module('collaboreApp').directive('tagCollab',[
	tagCollab
]);