'use strict';

/**
 * @desc Block d'un contenu message
 * @example <contenu-message contenu="" id-topic=""></contenu-message>
 */

angular
	.module('collaboreApp')
	.component('contenuMessage',{
		require: {
			layoutTopicDetailCtrl: '?^layoutTopicDetail'
		},
		templateUrl: 'app/topics/contenu-message.component.html',
		bindings : {
      isBlocked: '<',
			contenu: '<',		// <Object> Objet contenu
			eventInTopic: '<',
			idTopic: '@',		// <Number> Id topic du contenu
			onUpdate: '&',
			onEmit: '&'
		},
		controllerAs: 'contenumessagecomponentctrl',
		controller: ContenuMessageController
	});

/** @ngInject */
function ContenuMessageController($rootScope, $log, $timeout ,UtilsService, DocumentManagerService){

	var ctrl = this;
    ctrl.notMobile = UtilsService.notMobile;
	ctrl.showRepondre = true;
	ctrl.editMessage = false;
	ctrl.repondreOpen = false;
	ctrl.parseObjetMessage = UtilsService.parseObjetMessage;// Permet de garder les attributs style du HTML, sinon il sont supprimé
	//ctrl.onUpdateContenuMessage = onUpdateContenuMessage;	// Exécuté par les composant enfant qui modifie contenu
	//ctrl.onInsertContenuMessage = onInsertContenuMessage;	// Exécuté par les composant enfant qui ajoute un contenu
	ctrl.onUpdateTag = onUpdateTag;							// Exécuté par le composant tag
  ctrl.downloadAllForContenu = downloadAllForContenu;
  ctrl.onEmitFromFormulaireMessage = onEmitFromFormulaireMessage;
  ctrl.onEmitFromContenuMessageToolbar = onEmitFromContenuMessageToolbar;

	ctrl.$onInit = function() {
		ctrl.loadingDocuments = DocumentManagerService.loadingDocuments;

    if(ctrl.layoutTopicDetailCtrl.itemContenuEvenement && !ctrl.layoutTopicDetailCtrl.itemContenuEvenement.isModifiable()) ctrl.showRepondre = false;

		if(angular.isDefined(ctrl.idTopic)){
			ctrl.idTopic = parseInt(ctrl.idTopic);
		}
	};

  ctrl.$onChanges = function (changes) {
    if(changes.idTopic) ctrl.idTopic = parseInt(ctrl.idTopic);
  }

	/**
	 * Ferme le formulaire de réponse
	 * @param item
	 */
	function closeReply(){
    ctrl.repondreOpen = false;
    ctrl.editMessage = false;
    $rootScope.destroyInstandeCKEDITOR(ctrl.contenu);
	}

  /**
   * Lorsque le formulaire de message remonte une information
   * @param obj
   */
  function onEmitFromFormulaireMessage(obj) {
    try {
      if (!_.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');
      if (!obj.hasOwnProperty('action'))throw new Error('La propriété "action" n\'existe pas');

      if(obj.action === 'closeReply') closeReply();
      if(obj.action === 'addMessage' && _.isObject(obj.contenu)) {
        /*
        // Si composant appelé depuis la page detail
        if(ctrl.layoutTopicDetailCtrl){

          // Close le formulaire de réponse
          ctrl.layoutTopicDetailCtrl.openContenu(obj.contenu);

          // Ajoute le message à la page détail
          ctrl.layoutTopicDetailCtrl.addContenuMessage(obj.contenu, true);

        }
        $timeout(function(){
          UtilsService.scrollToContenu(obj.contenu);
        });
        */
        emit({action: obj.action, message: obj.contenu});
      }
      // TODO: dans le layout l'update de message n'est pas prise en compte
      if(obj.action === 'updateMessage' && _.isObject(obj.contenu)) {
        ctrl.contenu.setData(obj.contenu);
        ctrl.contenu.open = true;
      }
    }
    catch (err) {
      $log.log('[ContenuMessageCtrl.onEmitFromFormulaireMessage] Erreur : ' + err.message);
    }
  }


	/**
	 * Lors de l'update d'un contenu message
	 * @param updateContenu
     */
	/*
	function onUpdateContenuMessage(objUpdate){
		if(objUpdate.updateContenu){
			ctrl.contenu.setData(objUpdate.updateContenu);
		}

	}*/

	/**
	 * Lors de l'insertion d'un nouveau contenu message
	 * @param onInsert
     */
	/*
	function onInsertContenuMessage(onInsert){
		if(onInsert.insertContenu){

			// Si composant appelé depuis la page detail
			if(ctrl.layoutTopicDetailCtrl){

				// Close le formulaire de réponse
				ctrl.layoutTopicDetailCtrl.openContenu(onInsert.insertContenu);

				// Ajoute le message à la page détail
				ctrl.layoutTopicDetailCtrl.addContenuMessage(onInsert.insertContenu, true);

			}
			$timeout(function(){
				UtilsService.scrollToContenu(onInsert.insertContenu);
			});

		}
	}*/

	/**
	 * Executé lors de l'update des tags du contenu message
	 * @param objUpdate
     */
	function onUpdateTag(objUpdate){

		try{
			// Ajoute un tag
			if(objUpdate.hasOwnProperty('addTag')) ctrl.contenu.pushTag(objUpdate.addTag);

			// Supprime un tag
			if(objUpdate.hasOwnProperty('deleteTagFromContenu')) ctrl.contenu.removeTag(objUpdate.deleteTagFromContenu);

			if(ctrl.onUpdate) ctrl.onEmit({action: 'objUpdate', update: objUpdate});
		}
		catch(err){
			console.log('[ContenuMessage.onUpdateTag] Erreur : '+err.message);
		}
	}

  function downloadAllForContenu() {
    DocumentManagerService.loadingDownload('loadingDocDownload' + ctrl.contenu.getIdContenu());
    DocumentManagerService.downloadAllForContenu(ctrl.contenu, ctrl.property, ctrl.contenu.getIdContenu(), ctrl.idTopic);
  }

  /**
   * Lors d'une remontée d'info depuis contenu-message-toolbar
   * @param obj
   */
  function onEmitFromContenuMessageToolbar(obj){
    try {
      if (!_.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');
      if (!obj.hasOwnProperty('action'))throw new Error('La propriété "action" n\'existe pas');

      if(obj.action === 'openCommunication') emit({action: 'openCommunication'});
      if(obj.action === 'editMessage') {
        if(!ctrl.editMessage){
          ctrl.repondreOpen = false;
          $timeout(function(){
            ctrl.editMessage = true;
            // Scroll la page vers le fomulaire de réponse
            UtilsService.scrollToFormReponse(ctrl.contenu);
          });
        }
        else closeReply()
      }
      if(obj.action === 'replyAll') {

        if(!ctrl.repondreOpen){
          ctrl.repondreOpen = true;
          $timeout(function(){
            ctrl.editMessage = false;
            // Scroll la page vers le fomulaire de réponse
            UtilsService.scrollToFormReponse(ctrl.contenu);
          });
        }
        else closeReply()
      }
    }
    catch (err) {
      $log.log('[ContenuMessageController.onEmitFromContenuMessageToolbar] Erreur : ' + err.message);
    }
  }

  function emit(objet){
    ctrl.onEmit({obj: objet});
  }

}



