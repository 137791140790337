(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.ParametrageService
   * @description
   * # ParametrageService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('MailboxService',
    MailboxServiceController
    );

  /** @ngInject */
  function MailboxServiceController($log, MainService, ModalsService, $q, Mailbox, MailboxResource) {

    var _this = this;

    _this.searchListMailboxSociete = searchListMailboxSociete;
    _this.readMailbox = readMailbox;
    _this.traiteMailbox = traiteMailbox;
    _this.trashMailbox = trashMailbox;
    _this.deleteAttachementMailbox = deleteAttachementMailbox;

    function searchListMailboxSociete(page, limit, superAdmin, trashed) {
      var deferred = $q.defer();
      if(_.isNil(page)) page = 1;
      if(_.isNil(limit)) limit = 10;
      var params = {
        action: 'searchListMailboxSociete',
        trashed: false,
        page: page,
        limit: limit
      };

      if(superAdmin) params.fromSuperAdmin = true;
      if(!_.isNil(trashed)) params.trashed = trashed;

      var retour = {
        nb: 0,
        total: 0,
        nbMailbox: 0,
        nbMailboxNonLu: 0,
        nbMailboxTrashed: 0,
        listMailbox: []
      };

      MailboxResource.get(
        params,
        function (json) {
          if(_.isObject(json)){
            if(json.success){
              retour.nb = json.nb;
              retour.total = json.total;
              retour.nbMailbox = json.nbMailbox;
              retour.nbMailboxNonLu = json.nbMailboxNonLu;
              retour.nbMailboxTrashed = json.nbMailboxTrashed;
              if(_.isArray(json.listMailbox)) {
                retour.listMailbox = json.listMailbox.map(function(mailbox) {
                  return new Mailbox(mailbox);
                });
              }
            }
          }
          deferred.resolve(retour);
        },
        function (msg) {
          deferred.reject(msg);
        }
      );
      return deferred.promise;
    }

    function readMailbox(listIdMailbox, superAdmin){
      if (!listIdMailbox || !_.isArray(listIdMailbox) || !listIdMailbox.length)
        throw new Error('Aucun idMailbox trouvé !');

      var deferred = $q.defer();
      var params = {
        action: 'readMailbox',
        listIdMailbox: listIdMailbox
      };

      if(superAdmin) params.fromSuperAdmin = true;

      var retour = {
        nb: 0,
        nbMailboxNonLu: 0,
        nbMailboxTrashed: 0,
        listMailbox: []
      };

      MailboxResource.post(
        params,
        function (json) {
          if(_.isObject(json)){
            if(json.success){
              retour.nb = json.nb;
              retour.nbMailboxNonLu = json.nbMailboxNonLu;
              retour.nbMailboxTrashed = json.nbMailboxTrashed;
              if(_.isArray(json.listMailbox)) {
                retour.listMailbox = json.listMailbox.map(function(mailbox) {
                  return new Mailbox(mailbox);
                });
              }
            }
          }
          deferred.resolve(retour);
        },
        function (msg) {
          deferred.reject(msg);
        }
      );
      return deferred.promise;
    }

    function traiteMailbox(listIdMailbox, idTopic, idContenu, superAdmin){
      if (!listIdMailbox || !_.isArray(listIdMailbox) || !listIdMailbox.length)
        throw new Error('Aucun idMailbox trouvé !');

      var deferred = $q.defer();
      var params = {
        action: 'traiteMailbox',
        listIdMailbox: listIdMailbox
      };
      if(!_.isNil(idTopic)) params.insertInIdTopic = idTopic;
      if(!_.isNil(idContenu)) params.idContenuReference = idContenu;
      if(superAdmin) params.fromSuperAdmin = true;

      var retour = {
        nbErreur: 0,
        nbMailboxNonLu: 0,
        nbMailboxTrashed: 0,
        listMailboxErreur: []
      };

      MailboxResource.post(
        params,
        function (json) {
          if(_.isObject(json)){
            if(json.success){
              retour.nbErreur = json.nb;
              retour.nbMailboxNonLu = json.nbMailboxNonLu;
              retour.nbMailboxTrashed = json.nbMailboxTrashed;
              if(_.isArray(json.listMailboxErreur)) {
                retour.listMailboxErreur = json.listMailboxErreur.map(function(mailbox) {
                  return new Mailbox(mailbox);
                });
              }
            }
          }
          deferred.resolve(retour);
        },
        function (msg) {
          deferred.reject(msg);
        }
      );
      return deferred.promise;
    }

    function trashMailbox(listIdMailbox, superAdmin){
      if (!listIdMailbox || !_.isArray(listIdMailbox) || !listIdMailbox.length)
        throw new Error('Aucun idMailbox trouvé !');

      var deferred = $q.defer();
      var params = {
        action: 'trashMailbox',
        listIdMailbox: listIdMailbox
      };
      var retour = {
        nb: 0,
        nbMailboxNonLu: 0,
        nbMailboxTrashed: 0
      };

      if(superAdmin) params.fromSuperAdmin = true;
      MailboxResource.post(
        params,
        function (json) {
          if(_.isObject(json)){
            if(json.success){
              retour.nb = json.nb;
              retour.nbMailboxNonLu = json.nbMailboxNonLu;
              retour.nbMailboxTrashed = json.nbMailboxTrashed;
            }
          }
          deferred.resolve(retour);
        },
        function (msg) {
          deferred.reject(msg);
        }
      );
      return deferred.promise;
    }

    function deleteAttachementMailbox(listGuid, idMailbox, superAdmin){
      if (!listGuid || !_.isArray(listGuid) || !listGuid.length)
        throw new Error('Aucun document trouvé !');

      var deferred = $q.defer();
      var params = {
        action: 'deleteAttachementMailbox',
        listGuid: listGuid,
        idMailbox: idMailbox
      };
      var retour = {
        nbDelete: 0,
        nbDoc: listGuid.length,
        errorDocuments: [],
        deletedDocuments: [],
        mailbox: null
      };

      if(superAdmin) params.fromSuperAdmin = true;
      MailboxResource.post(
        params,
        function (json) {
          if(_.isObject(json)){
            if(json.success){
              retour.nbDelete = json.nb;
              if(_.isObject(json.mailbox)) {
                retour.mailbox = new Mailbox(json.mailbox);
              }
              if(_.isArray(json.listErrorDocument)){
                retour.errorDocuments = json.listErrorDocument;
              }
              if(_.isArray(json.listDeletedDocument)){
                retour.deletedDocuments = json.listDeletedDocument;
              }
            }
            else deferred.reject((!_.isNil(json.message)) ? json.message : 'Il y a eu une erreur');
          }
          deferred.resolve(retour);
        },
        function (msg) {
          deferred.reject(msg);
        }
      );
      return deferred.promise;
    }
  }
})();
