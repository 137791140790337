(function () {

  'use strict';

  /**
   * @desc Composant groupe ligne
   * @example <admin-collab-edit-groupe-user></admin-collab-edit-groupe-user>
   */
  angular
    .module('collaboreApp')
    .component('adminCollabEditGroupeUser', {
      bindings: {
        groupe: '<',        // Groupe
        onEmit: '&',     // Permet de remonté l'info en cas de success

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<'
      },
      templateUrl: 'app/admin/groupe/admin-collab-edit-groupe-user.component.html',
      controllerAs: 'admincollabeditgroupeuserctrl',
      controller: AdminCollabEditGroupeUserCtrl
    });


  /** @ngInject */
  function AdminCollabEditGroupeUserCtrl($injector, UtilsService, AdminCollabService, ModalsService, SocketNotificationService) {
    var ctrl = this;
    ctrl.groupeUser = {
      idGroupe: null,
      libelle: {
        editDisabled: true,
        value: null,
        defaultValue: null
      },
      email: {
        editDisabled: true,
        value: null,
        defaultValue: null
      },
      mdp: {
        editDisabled: true,
        value: null,
        defaultValue: null
      },
      renseignements: []
    };
    ctrl.onSubmitEditGroupeUser = onSubmitEditGroupeUser;
    ctrl.onClickClose = onClickClose;
    ctrl.onClickEditInput = onClickEditInput;
    ctrl.onClickDeleteRenseignement = onClickDeleteRenseignement;
    ctrl.isNil = _.isNil;

    ctrl.$onInit = function () {
      if(ctrl.resolve && ctrl.modalInstance) {
        ctrl.groupe = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'groupe'));
      }
      init();
    };

    ctrl.$onDestroy = function(){
      SocketNotificationService.removeListener('updateGroupeUser');
    };

    function init(){
      if(!ctrl.groupe) ModalsService.alertErreur('Pas de "groupe"');
      else {
        ctrl.groupeUser.idGroupe = angular.copy(ctrl.groupe.idGroupe);
        ctrl.groupeUser.libelle = {
          editDisabled: true,
          value: angular.copy(ctrl.groupe.libelle),
          defaultValue: angular.copy(ctrl.groupe.libelle)
        };
        ctrl.groupeUser.email = {
          editDisabled: true,
          value: angular.copy(ctrl.groupe.user.email),
          defaultValue: angular.copy(ctrl.groupe.user.email)
        };
        setRenseignement('nom');
        setRenseignement('prenom');
        setRenseignement('email');
        setRenseignement('telephone');
      }

      initStep();
    }

    function initStep(){
      ctrl.formSubmit = false;
      ctrl.typeProgress = 'info';
      ctrl.step = 0;
      ctrl.nbStep = 0;
    }

    function setRenseignement(type) {
      var renseignements = angular.copy(ctrl.groupe.getMainRenseignementsWithReference(type));
      if(renseignements.length) {
        for(var r = 0; r < renseignements.length; r++) {
          ctrl.groupeUser.renseignements.push({
            editDisabled: true,
            value: angular.copy(renseignements[r]),
            defaultValue: angular.copy(renseignements[r])
          });
        }
      }
    }

    function onSubmitEditGroupeUser(){

      initStep();
      if(_.isObject(ctrl.groupeUser)) {

        var groupeSent = angular.copy(ctrl.groupeUser);

        ctrl.showLogin = false;
        if(!groupeSent.libelle.editDisabled) {
          groupeSent.libelle = groupeSent.libelle.value;
          ctrl.nbStep = ctrl.nbStep + 3;
          ctrl.showLogin = true;
        }
        else delete groupeSent.libelle;

        ctrl.showEmail = false;
        if(!groupeSent.email.editDisabled) {
          groupeSent.email = groupeSent.email.value;
          ctrl.nbStep = ctrl.nbStep + 2;
          ctrl.showEmail = true;
        }
        else delete groupeSent.email;

        ctrl.showMdp = false;
        if(!groupeSent.mdp.editDisabled) {
          groupeSent.mdp = groupeSent.mdp.value;
          ctrl.nbStep = ctrl.nbStep + 2;
          ctrl.showMdp = true;
        }
        else delete groupeSent.mdp;

        ctrl.showRenseignement = false;
        if(groupeSent.renseignements.length) {

          var listRenseignement = [];
          //var listRenseignementDelete = [];
          var ProxyRenseignement = $injector.get('ProxyRenseignement');
          var n = 0;
          for(var r = 0; r < groupeSent.renseignements.length; r++){
            if(!groupeSent.renseignements[r].editDisabled) {
              if(n===0){
                ctrl.nbStep++;
                ctrl.showRenseignement = true;
                n++;
              }
              listRenseignement.push(new ProxyRenseignement(groupeSent.renseignements[r].value));
            }
            /*
            if(groupeSent.renseignements[r].delete) listRenseignementDelete.push(new ProxyRenseignement(groupeSent.renseignements[r].value));
            else if(!groupeSent.renseignements[r].editDisabled) listRenseignement.push(new ProxyRenseignement(groupeSent.renseignements[r].value));
            */
          }
          /*
          groupeSent.renseignements = angular.toJson({
            listRenseignements: listRenseignement,
            listRenseignementsDelete: listRenseignementDelete
          });*/
          groupeSent.renseignements = angular.toJson({
            listRenseignements: listRenseignement
          });
        }

        //var modalLoading = ModalsService.loading();
        ctrl.formSubmit = true;

        ctrl.changeLoginCollabAuth = null;
        ctrl.changeLoginCollabore = null;
        ctrl.nbFicheUpdate = null;
        //ctrl.nbContenuUpdate = null;
        //ctrl.nbTopicUpdate = null;
        ctrl.changePasswordCollabAuth = null;
        ctrl.changePasswordCollabore = null;
        ctrl.changeEmailCollabAuth = null;
        ctrl.changeEmailCollabore = null;
        ctrl.updateRenseignements = null;

        SocketNotificationService.on('updateGroupeUser', function(json){
          if(_.isObject(json) && json.success) {
            ctrl.step++;
            if(!_.isNil(json.changeLoginCollabAuth))   ctrl.changeLoginCollabAuth = json.changeLoginCollabAuth;
            if(!_.isNil(json.changeLoginCollabore))  ctrl.changeLoginCollabore = json.changeLoginCollabore;

            if(!_.isNil(json.nbFicheUpdate))    ctrl.nbFicheUpdate = json.nbFicheUpdate;
            //if(!_.isNil(json.nbContenuUpdate))  ctrl.nbContenuUpdate = json.nbContenuUpdate;
            //if(!_.isNil(json.nbTopicUpdate))    ctrl.nbTopicUpdate = json.nbTopicUpdate;

            if(!_.isNil(json.changePasswordCollabAuth)) ctrl.changePasswordCollabAuth = json.changePasswordCollabAuth;
            if(!_.isNil(json.changePasswordCollabore)) ctrl.changePasswordCollabore = json.changePasswordCollabore;

            if(!_.isNil(json.changeEmailCollabAuth)) ctrl.changeEmailCollabAuth = json.changeEmailCollabAuth;
            if(!_.isNil(json.changeEmailCollabore)) ctrl.changeEmailCollabore = json.changeEmailCollabore;

            if(!_.isNil(json.updateRenseignements)) ctrl.updateRenseignements = json.updateRenseignements;

          }
        });
        AdminCollabService
          .updateGroupeUser(groupeSent)
          .then(function (groupe) {
            ctrl.typeProgress = 'success';
            ctrl.groupe = groupe;
          })
          .catch(function (msg) {
            ModalsService.alertErreur(msg);
            initStep();
          });
      }
    }

    /**
     * Fermeture du composant si en mode modal
     */
    function onClickClose(){
      if(ctrl.formSubmit) ctrl.close({$value: {action: 'updateGroupe', groupe: ctrl.groupe}});
      else ctrl.dismiss({$value: 'cancel'});
    }

    function onClickEditInput(obj){
      if(!obj.editDisabled) obj.value = angular.copy(obj.defaultValue);
      obj.editDisabled = !obj.editDisabled;
    }

    function onClickDeleteRenseignement(obj){
      obj.delete = !obj.delete;
      if(!obj.delete) obj.value = angular.copy(obj.defaultValue);
    }

    /**
     * Permet d'envoyer des infos au composant parent
     */
    function emit(objet){
      if(!ctrl.modalInstance) {
        ctrl.onEmit({obj: objet});
      }
    }
  }
})();
