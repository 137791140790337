(function() {
	'use strict';

	/**
	 * @ngdoc service
	 * @name collaboreApp.ChannelService
	 * @description
	 * # AnnuaireService
	 * Service in the collaboreApp.
	 */

	angular.module('collaboreApp').service('ChannelService', ChannelServiceCtrl);


    /** @ngInject */
	function ChannelServiceCtrl(COLLAB_CONF,$q,ErreurCollabService,Channel,ChannelAjax){
		var ctrl = this;

		ctrl.getChannels = getChannels;												// Permet de récupérer la liste des channels

		ctrl.addChannel = addChannel;												// Permet d'ajouter un groupe à un channel ou de creer le channel "Defaut"
		ctrl.addAccueil = addAccueil;												// Permet d'ajouter un groupe à un channel ou de creer le channel "Accueil"

		ctrl.deleteGroupeForChannel = deleteGroupeForChannel;						// Supprime un groupe d'un channel
		ctrl.deleteChannel = deleteChannel;											// Supprime un channel

		ctrl.listeChannelHasChannelForGroupe = listeChannelHasChannelForGroupe;		// Permet de savoir si la liste de channel à le groupe en channel
		ctrl.listeChannelHasAccueilForGroupe = listeChannelHasAccueilForGroupe;		// Permet de savoir si la liste de channel à le groupe en accueil
		ctrl.getChannelForGroupe = getChannelForGroupe;								// Permet de récupérer le channel où il y a le groupe demandé dedans pour le type channel
		ctrl.getAccueilForGroupe = getAccueilForGroupe;								// Permet de récupérer le channel où il y a le groupe demandé dedans pour le type accueil
		ctrl.channelExist = channelExist;											// Permet de verifier que le channel exist et de le recupérer


		/**
		 * Permet de récupérer les channel
		 * @returns {IPromise.<T>}
         */
		function getChannels(){
			return _callRechercheChannel(COLLAB_CONF.CHANNEL_TYPE_CHANNEL);
		}

		/**
		 * Permet de faire appel à la servlet de recherche des channel
		 * @param type
		 * @returns {IPromise<T>}
         * @private
         */
		function _callRechercheChannel(type){

			var deferred = $q.defer();

			if(!type){
				type = 2;
			}

			ChannelAjax.getChannels({'type':type},function(json){
				deferred.resolve(json);
			},function(error){
				ErreurCollabService.erreurServlet(error,'Erreur avec ChannelService._callRechercheChannel()',deferred);
			});
			return deferred.promise;
		}

		/**
		 * Permet d'ajouter un groupe au channel "Defaut (Channel)" ou de le créer
		 * @param groupe
         * @returns {*}
         */
		function addChannel(groupe,channel){
			return _callInsertionChannel({
				'type':COLLAB_CONF.CHANNEL_TYPE_CHANNEL,
				'groupe': groupe,
				'channel': channel
			});
		}

		/**
		 * Permet d'ajouter un groupe au channel "Accueil" ou de le créer
		 * @param groupe
		 * @param channel
         * @returns {*}
         */
		function addAccueil(groupe,channel){
			return _callInsertionChannel({
				'type':COLLAB_CONF.CHANNEL_TYPE_ACCUEIL,
				'groupe': groupe,
				'channel': channel
			});
		}

		function _callInsertionChannel(obj){

			var deferred = $q.defer();

			try{
				if(!obj.hasOwnProperty('type')){
					throw new Error('Il manque l\'attribut "type" !');
				}

				if(!obj.hasOwnProperty('groupe')){
					throw new Error('Il manque l\'attribut "groupe" !');
				}

				if(!angular.isNumber(obj.groupe) && !angular.isObject(obj.groupe)){
					throw new Error('Le groupe doit être un id ou un objet ! : '+obj.groupe);
				}

				var idGroupe = obj.groupe;
				if(angular.isObject(obj.groupe)){
					idGroupe = obj.groupe.idGroupe;
				}

				var idChannel = null;
				if(obj.channel){
					if(!angular.isNumber(obj.channel) && !angular.isObject(obj.channel)){
						throw new Error('Le channel doit être un id ou un objet ! : '+obj.channel);
					}

					idChannel = obj.channel;
					if(angular.isObject(obj.channel)){
						idChannel = obj.channel.idChannel;
					}
				}

				var param = {
					'type': obj.type,
					'idGroupe': idGroupe,
					'idChannel': idChannel
				};

				ChannelAjax.addChannel(param,function(json){

					if(json.nb!=='0'){
						deferred.resolve(new Channel(json.channels[0]));
					}else{
						deferred.reject();
					}

				},function(error){
					ErreurCollabService.erreurServlet(error,'Erreur avec ChannelService._callInsertionChannel()',deferred);
				});

			}
			catch(err){
				console.log('[ChannelService._callInsertionChannel] Erreur : '+err.message);
				deferred.reject();
			}


			return deferred.promise;
		}

		/**
		 * Permet de supprimer un groupe d'un channel
		 * @param groupe
		 * @param channel
         * @returns {*}
         */
		function deleteGroupeForChannel(groupe,channel){
			try{
				if(!angular.isNumber(groupe) && !angular.isObject(groupe)){
					throw new Error('Le groupe doit être un id ou un objet !');
				}

				if(!angular.isNumber(channel) && !angular.isObject(channel)){
					throw new Error('Le channel doit être un id ou un objet !');
				}

				var idGroupe = groupe;
				if(angular.isObject(groupe)){
					idGroupe = groupe.idGroupe;
				}

				var idChannel = channel;
				if(angular.isObject(channel)){
					idChannel = channel.idChannel;
				}

				return _callDeleteChannel({
					'idGroupe': idGroupe,
					'idChannel': idChannel
				});
			}
			catch(err){
				console.log('[ChannelService.deleteGroupeForChannel] Erreur : '+err.message);
				var deferred = $q.defer();
				deferred.reject();
				return deferred.promise;
			}
		}

		/**
		 * Permet de supprimer un channel
		 * @param channel
         * @returns {*}
         */
		function deleteChannel(channel){
			try{

				if(!angular.isNumber(channel) && !angular.isObject(channel)){
					throw new Error('Le channel doit être un id ou un objet !');
				}

				var idChannel = channel;
				if(angular.isObject(channel)){
					idChannel = channel.idChannel;
				}

				return _callDeleteChannel({
					'idChannel': idChannel
				});
			}
			catch(err){
				console.log('[ChannelService.deleteChannel] Erreur : '+err.message);
				var deferred = $q.defer();
				deferred.reject();
				return deferred.promise;
			}
		}

		/**
		 * Permet de faire appel à la sevrlet de suppression des channel
		 * @param obj
		 * @returns {IPromise<T>}
         * @private
         */
		function _callDeleteChannel(obj){
			var deferred = $q.defer();

			try{

				if(!obj.hasOwnProperty('idGroupe') && !obj.hasOwnProperty('idChannel')){
					throw new Error('Il faut au moins un "idGroupe" ou "idChannel" !');
				}

				var param = {
					'type': obj.type
				};

				if(obj.hasOwnProperty('idGroupe')){
					param.idGroupe = obj.idGroupe;
				}

				if(obj.hasOwnProperty('idChannel')){
					param.idChannel = obj.idChannel;
				}

				ChannelAjax.deleteChannel(param,function(json){

					if(json.nb==='1'){
						if(json.Resultats[0].success){
							deferred.resolve(true);
						}
					}

					deferred.reject();

				},function(error){
					ErreurCollabService.erreurServlet(error,'Erreur avec ChannelService._callDeleteChannel()',deferred);
				});

			}
			catch(err){
				console.log('[ChannelService._callDeleteChannel] Erreur : '+err.message);
				deferred.reject();
			}


			return deferred.promise;
		}

		/**
		 * Renvoie true si le groupe se trouve dans la liste des groupes du type channel
		 * @param liste
		 * @param groupe
         * @returns {boolean}
         */
		function listeChannelHasChannelForGroupe(liste,groupe){
			return (_listeChannelHasTypeForGroupe(liste,groupe,COLLAB_CONF.CHANNEL_TYPE_CHANNEL)) ? true : false;
		}

		/**
		 * Renvoie true si le groupe se trouve dans la liste des groupes du type accueil
		 * @param liste
		 * @param groupe
         * @returns {boolean}
         */
		function listeChannelHasAccueilForGroupe(liste,groupe){
			return (_listeChannelHasTypeForGroupe(liste,groupe,COLLAB_CONF.CHANNEL_TYPE_ACCUEIL)) ? true : false;
		}

		/**
		 * Permet de récupérer le channel pour le groupe demandé et le type channel
		 * @param liste
		 * @param groupe
		 * @returns {Channel|false}
         */
		function getChannelForGroupe(liste,groupe){
			return _listeChannelHasTypeForGroupe(liste,groupe,COLLAB_CONF.CHANNEL_TYPE_CHANNEL);
		}

		/**
		 * Permet de récupérer le channel pour le groupe demandé et le type accueil
		 * @param liste
		 * @param groupe
		 * @returns {Channel|false}
		 */
		function getAccueilForGroupe(liste,groupe){
			return _listeChannelHasTypeForGroupe(liste,groupe,COLLAB_CONF.CHANNEL_TYPE_ACCUEIL);
		}

		/**
		 * Renvoie le channel si le groupe est bien dans le channel du type demandé
		 * @param liste
		 * @param groupe
		 * @param type
         * @returns {Channel|false}
         * @private
         */
		function _listeChannelHasTypeForGroupe(liste,groupe,type){
			var retour = false;
			try{
				if(!angular.isArray(liste)){
					throw new Error('Le 1er paramètre doit être un Array<Channel> !');
				}
				if(!angular.isObject(groupe)){
					throw new Error('Le 2ème paramètre doit être un Object<Groupe> !');
				}

				if(!type){
					type = COLLAB_CONF.CHANNEL_TYPE_CHANNEL;
				}
				if(liste.length){
					for(var i = 0 ; i < liste.length ; i++){
						if(liste[i].hasGroupeForType(groupe.idGroupe,type)){
							retour = liste[i];
							break;
						}
					}
				}
				return retour;
			}
			catch(err){
				console.log('[ChannelService._listeChannelHasTypeForGroupe] Erreur : '+err.message);
				return retour;
			}

		}

		function channelExist(liste,idChannel){
			var retour = false;
			try{
				if(!angular.isArray(liste)){
					throw new Error('Le 1er paramètre doit être un Array<Channel> !');
				}
				if(!idChannel){
					throw new Error('Le 2ème paramètre doit être un idChannel !');
				}

				if(liste.length){
					for(var i = 0 ; i < liste.length ; i++){
						if(liste[i].getIdChannel()===idChannel){
							retour = liste[i];
							break;
						}
					}
				}
				return retour;
			}
			catch(err){
				console.log('[ChannelService.channelExist] Erreur : '+err.message);
				return retour;
			}

		}

	}


})();