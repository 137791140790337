(function() {

	'use strict';

	/**
	 * @desc Label pour les topic urgent
	 * @example <evenement-urgent urgent="" allow-filtre=""></evenement-urgent>
	 */

	angular
		.module('collaboreApp')
		.component('evenementUrgent',{
			templateUrl: 'app/topics/components/evenement-urgent.component.html',
			bindings : {
				urgent: '<',
				allowFiltre: '<'
			},
			controllerAs: 'evenementurgentctrl',
			controller: EvenementUrgentController
		});

	/*@ngInject*/
	function EvenementUrgentController($state,FiltreService){

		var ctrl = this;

		ctrl.$onInit = function() {
			if(ctrl.urgent) {
                ctrl.currentState = $state.current.name;
                ctrl.paramsFiltreByEvenementUrgent = {};
                if(ctrl.allowFiltre){
                    ctrl.paramsFiltreByEvenementUrgent = FiltreService.getObjFiltreByEvenementUrgent();
                }
			}
		};
	}


})();