(function() {
  'use strict';

  angular.module('collaboreApp').service('RecapShootingMailService', RecapShootingMailServiceCtrl);

  /*@ngInject*/
  function RecapShootingMailServiceCtrl(MainService, $q, $log, ErreurCollabService, RecapShootingMail, ShootingMailAjax, UtilsService){
    var ctrl = this;

    ctrl.checkIfExistInListe 	= checkIfExistInListe;	// Check si le recapMail exist dans une liste
    ctrl.getRecapShootingMail 			= getRecapShootingMail;			// Permet de récupérer la liste des recapMail
    ctrl.resendShootingMail 			= resendShootingMail;			// Permet de renvoyer un RecapMail
    ctrl.resendShootingMails 			= resendShootingMails;			// Permet de renvoyer une liste de RecapMail
    ctrl.sendAllMail 			= sendAllMail;			// Permet de renvoyer tous les RecapMail en attente ou erreur de ma société

    function checkIfExistInListe(obj,liste){
      var retour = false;
      if(angular.isArray(liste) && angular.isObject(obj) && obj.hasOwnProperty('id')) {
        if(liste.length){
          for(var i = 0; i < liste.length; i++) {
            if(liste[i].id === obj.id) {
              retour = true;
              break;
            }
          }
        }
      }
      return retour;
    }

    /**
     * Recupère une liste de RecapMail
     * @param obj
     * @returns {Promise}
     */
    function getRecapShootingMail(obj){
      var deferred = $q.defer();
      try{

        var param = {
          dateFin: moment().format('YYYY-MM-DD')
        };

        if(_.isObject(obj)) {
          if(!_.isNil(obj.dateDebut)) param.dateDebut = obj.dateDebut;
          if(!_.isNil(obj.dateFin)) param.dateFin = obj.dateFin;
          if(!_.isNil(obj.searchSujet)) param.searchSujet = obj.searchSujet;
          if(!_.isNil(obj.searchCorps)) param.searchCorps = obj.searchCorps;
          if(!_.isNil(obj.searchDestinataire)) param.searchDestinataire = obj.searchDestinataire;
          if(!_.isNil(obj.offset)) param.offset = obj.offset;
          if(!_.isNil(obj.limit)) param.limit = obj.limit;
        }

        ShootingMailAjax.getRecapShootingMail(param,function(json){
          deferred.resolve({
            emails: MainService.convertJsonToModel(json, 'emails', RecapShootingMail, deferred),
            message: (json.message) ? json.message : null,
            nb: json.nb,
            nbTotal: json.nbTotal
          });
        },function(error){
          ErreurCollabService.erreurServlet(error,'Erreur avec RecapShootingMailService.getRecapShootingMail()',deferred);
        });

      }
      catch(err){
        $log.error('[RecapShootingMailService.getRecapShootingMail] Erreur : '+err.message);
        deferred.reject();
      }

      return deferred.promise;
    }

    /**
     * Permet de renvoyer des RecapMail à partir d'une liste de RecapMail ou d'idRecapMail
     * @param array
     * @returns {Promise}
     */
    function resendShootingMails(array){
      var deferred = $q.defer();
      try{
        var listeId = [];
        if(angular.isArray(array)) {
          for(var a = 0; a < array.length; a++) {
            if(angular.isNumber(array[a])) listeId.push(array[a]);
            else if(angular.isObject(array[a])){
              if(array[a].isModel && array[a].model === 'RecapShootingMail') listeId.push(array[a].getId());
              else if(array[a].hasOwnProperty('id'))listeId.push(array[a].id);
            }
          }
        }
        sendShootingMail(listeId).then(function(liste){
          deferred.resolve(liste);
        }).catch(function(msg) {
          throw new Error(msg);
        });

      }
      catch(err){
        $log.error('[RecapShootingMailService.resendShootingMails] Erreur : '+err.message);
        deferred.reject();
      }

      return deferred.promise;
    }

    /**
     * Permet de renvoyer un RecapMail à partir d'un objet RecapMail ou avec propriété idRecapMail
     * @param obj
     * @returns {Promise}
     */
    function resendShootingMail(obj){
      var deferred = $q.defer();
      try{

        var listeId = [];
        if(angular.isObject(obj)){
          if(obj.isModel && obj.model === 'RecapShootingMail') listeId.push(obj.getId());
          else if(obj.hasOwnProperty('id')) listeId.push(obj.id);
        }

        sendShootingMail(listeId).then(function(liste){
          deferred.resolve(liste);
        }).catch(function(msg) {
          throw new Error(msg);
        });
      }
      catch(err){
        $log.error('[RecapShootingMailService.resendShootingMail] Erreur : '+err.message);
        deferred.reject();
      }
      return deferred.promise;
    }

    /**
     * Permet de renvoyer des RecapMail
     * @param array
     * @returns {Promise}
     */
    function sendShootingMail(array){
      var deferred = $q.defer();
      try {
        ShootingMailAjax.resendShootingMail({id: array},function(json){
          deferred.resolve({
            emails: MainService.convertJsonToModel(json, 'emails', RecapShootingMail, deferred),
            message: (json.message) ? json.message : null
          });
        },function(error){
          ErreurCollabService.erreurServlet(error,'Erreur avec RecapShootingMailService.sendMail()',deferred);
        });
      }
      catch(err){
        $log.error('[RecapShootingMailService.sendMail] Erreur : '+err.message);
        deferred.reject();
      }
      return deferred.promise;
    }

    /**
     * Permet de renvoyer tous les RecapMail en attente ou en erreur
     * @returns {Promise}
     */
    function sendAllMail(){
      var deferred = $q.defer();
      try {
        ShootingMailAjax.resendAllShootingMail(function(json){
          if(UtilsService.ifResult(json)){
            deferred.resolve();
          }
        },function(error){
          ErreurCollabService.erreurServlet(error,'Erreur avec RecapShootingMailService.resendAllShootingMail()',deferred);
        });
      }
      catch(err){
        $log.error('[RecapShootingMailService.resendAllShootingMail] Erreur : ' + err.message);
        deferred.reject();
      }
      return deferred.promise;
    }
  }
})();
