(function() {

    'use strict';

    /**
     * @desc Composant de la liste des personnalite ICS de ICSCloud
     * @example <liste-personnalite-ics></liste-personnalite-ics>
     */
    angular
        .module('collaboreApp')
        .component('listePersonnaliteIcs',{
            templateUrl: 'app/components/recherche-ics/liste-personnalite-ics/liste-personnalite-ics.component.html',
            controllerAs: 'listepersonnaliteicsctrl',
            bindings: {
                search: '<',    // Champ de recherche
                listIdPortefeuille: '<',
                onEmit: '&'
            },
            controller: ListePersonnaliteIcsCtrl
        });

    /** @ngInject */
    function ListePersonnaliteIcsCtrl(COLLAB_CONF, $rootScope, $state, $q, $scope, AnnuaireService, TagsService, UtilsService){
        var ctrl = this;
        ctrl.loading = false;
        ctrl.listAllPersonnalite = [];
        ctrl.listPersonnalite = [];

        /**
         * Objet de filtre du tableau
         * @type {{sortType: string, sortReverse: boolean, searchFilter: string}}
         */
        /*
        ctrl.filtre = {
            sortType: 'nom',
            sortReverse: false,
            tmpSearchFilter: '',
            searchFilter: ''
        };*/

        //ctrl.onClickOpenPersonnalite = onClickOpenPersonnalite;     // Lors du clique sur le bouton pour ouvrir ou fermer la personnalitée
        ctrl.onClickFiltreTopic = onClickFiltreTopic;               // Lors du clique sur le bouton pour filtrer les topics
        ctrl.onClickCreateEvenement = onClickCreateEvenement;       // Lors du clique sur le bouton pour créer un événement
        ctrl.onClickCreateDevisInter = onClickCreateDevisInter;     // Lors du clique sur le bouton pour créer un devis ou une intervention
        ctrl.onClickOpenBaux = onClickOpenBaux;                     // Lors du clique sur le bouton pour ouvrir les baux d'un lot

        ctrl.persosExpandCallback = persosExpandCallback;
        ctrl.persosCollapseCallback = persosCollapseCallback;

        ctrl.$onInit = function() {
            init();
        };

        ctrl.$onChanges = function(changes){
            if(!changes.listIdPortefeuille.isFirstChange() && !ctrl.search.isFirstChange()) {
                ctrl.listIdPortefeuille = changes.listIdPortefeuille.currentValue;
                ctrl.search = changes.search.currentValue;
                init();
            }
        };

        /**
         * Permet d'init le composant
         */
        function init(){
            if(_.isNil(ctrl.listIdPortefeuille)) {
                var listePortefeuilles = UtilsService.getCurrentUser().getListePortefeuille();
                if (listePortefeuilles.length) {
                    ctrl.listIdPortefeuille = listePortefeuilles.map(function(port){
                        return port.idPortefeuille;
                    });
                }
            }


            ctrl.loading = true;
            callPersonnalite()
                .then(function(listePerso){
                    //var promises = [];
                    ctrl.listAllPersonnalite = listePerso;
                    if(_.isArray(ctrl.listAllPersonnalite) && ctrl.listAllPersonnalite.length) {

                        // Perso avec inverse
                        for(var i = 0; i < ctrl.listAllPersonnalite.length; i++){
                            var immeubles = AnnuaireService.triInverses(ctrl.listAllPersonnalite[i].personnalite.inverses);
                            if(immeubles.length) {
                                ctrl.listAllPersonnalite[i].immeubles = immeubles;
                                ctrl.listAllPersonnalite[i].isExpanded = true;
                                ctrl.listPersonnalite.push(ctrl.listAllPersonnalite[i]);
                            }
                        }


                        // angular.forEach(ctrl.listAllPersonnalite, function(objPerso){
                        //     promises.push(searchImmeuble(objPerso).then(function(perso){
                        //         if(perso.immeubles.length) {
                        //             perso.isExpanded = true;
                        //             ctrl.listPersonnalite.push(perso);
                        //         }
                        //     }));
                        // });
                    }

                    //$q.all(promises).finally(function(){
                    //    ctrl.loading = false;
                    //});
                })
                //.catch(function(){
                //    ctrl.loading = false;
                //})
                .finally(function(){
                    ctrl.loading = false;
                    emit({action: 'finishedCall'});
                });
        }

        /**
         * Permet d'appeler ICSCloud pour récupérer les personnalités
         * @returns {*}
         */
        function callPersonnalite(){
            var deferred = $q.defer();

            var paramsBase = {
                avecemail: 0,
                type: 'U',
                usersCollab: 0,
                groupesCollab: 0,
                recherche: ctrl.search,
                idPortefeuilles: ctrl.listIdPortefeuille,
                commencePar: false,
                avecinverses: true
            };

            //ctrl.paramsSearch.filtreAllow = {
            //    ics: true
            //};

            //if (ctrl.paramsSearch.typePerso && ctrl.paramsSearch.typePerso !== 'all')
            //    params.fortype = ctrl.paramsSearch.typePerso;

            //ctrl.loading = true;
            AnnuaireService
                .searchCollaborateur(paramsBase)
                .then(function (objectCollaborateur) {

                    var listPersonnalite = [];

                    // Si il y a des perso ou fournisseur
                    if (_.isObject(objectCollaborateur) && _.isArray(objectCollaborateur.listePortefeuilles) && objectCollaborateur.listePortefeuilles.length) {

                        for(var p = 0; p < objectCollaborateur.listePortefeuilles.length; p++){
                            var portefeuille = objectCollaborateur.listePortefeuilles[p];

                            var portefeuilleCopy = angular.copy(portefeuille);
                            delete portefeuilleCopy.listePersonnalite;

                            if (portefeuille.getListePersonnalite().length) {
                                for(var j = 0; j < portefeuille.getListePersonnalite().length; j++) {

                                    // Si c'est un fournisseur alors passe à la perso suivante
                                    if(portefeuille.getListePersonnalite()[j].isOnlyFournisseur())
                                        continue;



                                    listPersonnalite.push({
                                        personnalite: portefeuille.getListePersonnalite()[j],
                                        portefeuille: portefeuilleCopy,
                                        personnaliteLoading: false,
                                        personnaliteIsOpen: false,
                                        immeubles: null
                                    });
                                }
                            }
                        }
                    }
                    deferred.resolve(listPersonnalite);
                })
                .catch(function(err) {
                    deferred.reject(err);
                });

            return deferred.promise;
        }

        /**
         * Permet de récupérer la liste d'immeuble d'une personnalité
         * @param objPerso
         * @returns {*}
         */
        function searchImmeuble(objPerso){
            var defered = $q.defer();
            var resolve = false;

            // Si perso ICS
            if (objPerso.personnalite.isPersonnalite) {
                // Si lots pas ouvert
                if (!objPerso.personnaliteIsOpen) {

                    objPerso.personnaliteIsOpen = true;

                    // Si pas d'objetTypePerso en mémoire
                    if (!_.isArray(objPerso.immeubles)) {

                        AnnuaireService
                            .getImmeubles(objPerso.personnalite.getNoperso(), objPerso.portefeuille.getIdPortefeuille())
                            .then(function (obj) {
                                objPerso.immeubles = obj;
                                defered.resolve(objPerso);
                            })
                            .catch(function (msg) {
                                defered.reject(msg);
                            });
                    }
                    else
                        resolve = true;
                }
                // Si déjà ouvert
                else {

                    objPerso.personnaliteIsOpen = false;
                    /*
                    if (collaborateur.hasOwnProperty('objTypePerso') && collaborateur.objTypePerso) {
                        if (collaborateur.objTypePerso.hasOwnProperty('Proprietaire') && collaborateur.objTypePerso.Proprietaire) {
                            if (collaborateur.objTypePerso.Proprietaire.hasOwnProperty('listeMandats') && angular.isArray(collaborateur.objTypePerso.Proprietaire.listeMandats) && collaborateur.objTypePerso.Proprietaire.listeMandats.length) {
                                for (var m = 0; m < collaborateur.objTypePerso.Proprietaire.listeMandats.length; m++) {
                                    destroyListenerForMandat(collaborateur.objTypePerso.Proprietaire.listeMandats[m].num);
                                }
                            }
                        }
                    }*/
                    resolve = true;
                }
            }
            else
                resolve = true;

            if(resolve) {
                defered.resolve(objPerso);
            }
            return defered.promise;
        }

        /**
         * Lors du clique sur le bouton pour ouvrir ou fermer la personnalitée
         * @param objPerso
         */
        /*
        function onClickOpenPersonnalite(objPerso){
            objPerso.personnaliteLoading = true;
            searchImmeuble(objPerso)
                .finally(function(){
                    objPerso.personnaliteLoading = false;
                });
        }*/

        /**
         * Lors du clique sur le bouton pour filtrer les topics
         * @param objPerso
         * @param objImmeuble
         * @param lot
         */
        function onClickFiltreTopic(objPerso, objImmeuble, lot, bail){
            var objetsTags = setTagsForOrigine('disableEventCreation', objPerso, objImmeuble, lot, bail);
            $scope.$emit('rechercheByTags', objetsTags);
        }

        /**
         * Lors du clique sur le bouton pour créer un événement
         * @param objPerso
         * @param objImmeuble
         */
        function onClickCreateEvenement(projet, objPerso, objImmeuble, lot, bail){
            setTagsForOrigine(projet, objPerso, objImmeuble, lot, bail);
            $state.go('nouveau.evenement', {loadOrigine: true});
        }

        /**
         * Lors du clique sur le bouton pour créer un devis ou une intervention
         * @param objPerso
         * @param objImmeuble
         */
        function onClickCreateDevisInter(projet, objPerso, objImmeuble, lot, bail){
            setTagsForOrigine(projet, objPerso, objImmeuble, lot, bail);
            $state.go('nouveau.evenementAvecDevisOuIntervention', {loadOrigine: true});
        }

        /**
         * Permet de générer et setter la variable "tagsForOrigine" pour l'ajout des tags lors de la création d'événément
         * @param objPerso
         * @param objImmeuble
         */
        function setTagsForOrigine(projet, objPerso, objImmeuble, lot, bail){

            var obj = {
                origine: objPerso.personnalite,
                immeuble: (!_.isNil(objImmeuble.immeuble)) ? objImmeuble.immeuble : null,
                projet: (!_.isNil(projet)) ? projet: null,
                lot: (!_.isNil(lot)) ? lot : null,
                bail: (!_.isNil(bail)) ? bail : null,
                portefeuille: objPerso.portefeuille,
                typePerso: (!_.isNil(objImmeuble.typePerso)) ? objImmeuble.typePerso : null,
                type: 'ICS'
            };

            // Si le tag projet doit etre le lot et qu'on a bien un immeuble et qu'il une perso dedans alors c'est un propriétaire
            if(projet === 'lot' && !_.isNil(objImmeuble.personnalite)) {
                obj.proprietaire = objImmeuble.personnalite;
            }

            $rootScope.current.tmpNewEvenement.tagsForOrigine = TagsService.genereTagsAndFiches(obj);
            //$rootScope.current.tmpNewEvenement.tagsForOrigine = TagsService.genereTagsAndFichesForSearch(obj);
            return $rootScope.current.tmpNewEvenement.tagsForOrigine;
        }

        /**
         * Lors du clique sur le bouton pour ouvrir les baux d'un lot
         * @param objPerso
         * @param objLot
         */
        function onClickOpenBaux(objPerso, objLot){
            objLot.baux = [];
            if (!objLot.bauxIsOpen) {

                objLot.loadingBaux = true;
                objLot.bauxIsOpen = true;

                AnnuaireService
                    .searchBaux(objLot.getNumMandat(), objLot.getNumImmeuble(), objLot.getNumLot(), objPerso.portefeuille.getIdPortefeuille())
                    .then(function (baux) {
                        objLot.baux = baux;
                    })
                    .finally(function () {
                        objLot.loadingBaux = false;
                    });
            }
            else {
                objLot.bauxIsOpen = false;
            }
        }

        /*
        function resetFiltre(){
            // Met à 0 les nombre de résultats du filtre ics
            ctrl.filtreIcs.nb = 0;
            ctrl.filtreIcs.proprietaire.nb = 0;
            ctrl.filtreIcs.coproprietaire.nb = 0;
            ctrl.filtreIcs.locataire.nb = 0;
            ctrl.filtreIcs.colocataire.nb = 0;
            ctrl.filtreIcs.fournisseur.nb = 0;
            ctrl.filtreIcs.salarie.nb = 0;
            ctrl.filtreIcs.caution.nb = 0;
            ctrl.filtreIcs.assureur.nb = 0;
            ctrl.filtreIcs.payeur.nb = 0;
            ctrl.filtreIcs.employeur.nb = 0;
            ctrl.filtreIcs.ics_.nb = 0;
            ctrl.filtreIcs.client.nb = 0;
            ctrl.filtreIcs.proprietairemeuble.nb = 0;
            ctrl.filtreIcs.indivision.nb = 0;
        }

        function initFiltre() {
            if (ctrl.filtreIcs.proprietaire.nb) {
                ctrl.paramsSearch.filtreAllow.proprietaire = true;
            }
            if (ctrl.filtreIcs.coproprietaire.nb) {
                ctrl.paramsSearch.filtreAllow.coproprietaire = true;
            }
            if (ctrl.filtreIcs.locataire.nb) {
                ctrl.paramsSearch.filtreAllow.locataire = true;
            }
            if (ctrl.filtreIcs.colocataire.nb) {
                ctrl.paramsSearch.filtreAllow.colocataire = true;
            }
            if (ctrl.filtreIcs.fournisseur.nb) {
                ctrl.paramsSearch.filtreAllow.fournisseur = true;
            }
            if (ctrl.filtreIcs.salarie.nb) {
                ctrl.paramsSearch.filtreAllow.salarie = true;
            }
            if (ctrl.filtreIcs.caution.nb) {
                ctrl.paramsSearch.filtreAllow.caution = true;
            }
            if (ctrl.filtreIcs.assureur.nb) {
                ctrl.paramsSearch.filtreAllow.assureur = true;
            }
            if (ctrl.filtreIcs.payeur.nb) {
                ctrl.paramsSearch.filtreAllow.payeur = true;
            }
            if (ctrl.filtreIcs.employeur.nb) {
                ctrl.paramsSearch.filtreAllow.employeur = true;
            }
            if (ctrl.filtreIcs.ics_.nb) {
                ctrl.paramsSearch.filtreAllow.ics_ = true;
            }
            if (ctrl.filtreIcs.client.nb) {
                ctrl.paramsSearch.filtreAllow.client = true;
            }
            if (ctrl.filtreIcs.proprietairemeuble.nb) {
                ctrl.paramsSearch.filtreAllow.proprietairemeuble = true;
            }
            if (ctrl.filtreIcs.indivision.nb) {
                ctrl.paramsSearch.filtreAllow.indivision = true;
            }
        }*/

        /**
         * Lorsque qu'une pane de l'accordion est ouverte
         * @param index
         */
        function persosExpandCallback(index, id) {
        }

        /**
         * Lorsque qu'une pane de l'accordion est fermée
         * @param index
         */
        function persosCollapseCallback(index, id) {
        }

        function emit(objet){
            ctrl.onEmit({
                obj: objet
            });
        }
    }
})();
