(function() {
  'use strict';

  /**
   * @ngdoc factory
   * @name collaboreApp.ProxyContenuEvenement
   * @description
   * # ProxyContenuEvenement
   */

  angular.module('collaboreApp').factory('ProxyContenuEvenement', ProxyContenuEvenementModel);

  /** @ngInject */
  function ProxyContenuEvenementModel(ProxyContenu, ProxyContenuDevis, ProxyContenuContrat, ProxyContenuFacture) {

    function ProxyContenuEvenement(objetData) {
      ProxyContenu.apply(this, arguments);
      this.hydrate(objetData);
      this.typeContenu = 2;
    }

    ProxyContenuEvenement.prototype = {

      // dateEcheance
      getDateEcheance: function(){
        return this.dateEcheance;
      },
      setDateEcheance: function(val){
        this.dateEcheance = val;
      },

      // dateIntervention
      getDateIntervention: function(){
        return this.dateIntervention;
      },
      setDateIntervention: function(val){
        this.dateIntervention = val;
      },

      // referenceAutoByICS
      getReferenceAutoByICS: function(){
        return this.referenceAutoByICS;
      },
      setReferenceAutoByICS: function(val){
        this.referenceAutoByICS = val;
      },

      // referenceClient
      getReferenceClient: function(){
        return this.referenceClient;
      },
      setReferenceClient: function(val){
        this.referenceClient = val;
      },

      // postits (PAS UTILISE)
      getPostits: function(){
        return this.postits;
      },
      setPostits: function(val){
        this.postits = val;
      },

      // urgent
      getUrgent: function(){
        return this.urgent;
      },
      setUrgent: function(val){
        this.urgent = val;
      },

      // listeDevis
      getListeDevis: function(){
        return this.listeDevis;
      },
      setListeDevis: function(val){
        var arrayListeDevis = [];
        if(angular.isArray(val)){
          for(var i = 0 ; i < val.length ;  i++){
            var valeur = val[i];
            if(!valeur.isProxy) {
              valeur = new ProxyContenuDevis(valeur);
            }
            arrayListeDevis.push(valeur);
          }
        }
        this.listeDevis = arrayListeDevis;
      },

      // listeInterventions (PAS UTILISE)
      getListeInterventions: function(){
        return this.listeInterventions;
      },
      setListeInterventions: function(val){
        this.listeInterventions = val;
      },

      // listeFactures (PAS UTILISE)
      getListeFactures: function(){
        return this.listeFactures;
      },
      setListeFactures: function(val){
        var arrayListeFacture = [];
        if(angular.isArray(val)){
          for(var i = 0 ; i < val.length ;  i++){
            var valeur = val[i];
            if(!valeur.isProxy) {
              valeur = new ProxyContenuFacture(valeur);
            }
            arrayListeFacture.push(valeur);
          }
        }
        this.listeFactures = arrayListeFacture;
      },

      // listeContrats
      getListeContrats: function(){
        return this.listeContrats;
      },
      setListeContrats: function(val){
        var arrayListeContrats = [];
        if(angular.isArray(val)){
          for(var i = 0 ; i < val.length ;  i++){
            var valeur = val[i];
            if(!valeur.isProxy) {
              valeur = new ProxyContenuContrat(valeur);
            }
            arrayListeContrats.push(valeur);
          }
        }
        this.listeContrats = arrayListeContrats;
      },

      // objet
      getObjet: function(){
        return this.objet;
      },
      setObjet: function(val){
        this.objet = val;
      },

      // assigneA (PAS UTILISE)
      getAssigneA: function(){
        return this.assigneA;
      },
      setAssigneA: function(val){
        this.assigneA = val;
      },

      // assignePar (PAS UTILISE)
      getAssignePar: function(){
        return this.assignePar;
      },
      setAssignePar: function(val){
        this.assignePar = val;
      }

    };

    angular.extend(ProxyContenuEvenement.prototype, ProxyContenu.prototype);

    return ProxyContenuEvenement;
  }
})();
