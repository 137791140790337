(function() {

    'use strict';

    /**
     * @desc Composant gerer mes groupes
     * @example <admin-gestion-des-groupes-user></admin-gestion-des-groupes-user>
     */
    angular
        .module('collaboreApp')
        .component('adminGestionDesGroupesUser',{
            bindings: {
                users: '<'
            },
            templateUrl: 'app/parametrage/admin/gestion-des-groupes/admin-gestion-des-groupes-user.html',
            controllerAs: 'admingestiondesgroupesuserctrl',
            controller: AdminGestionDesGroupesUserCtrl
        });


    /** @ngInject */
    function AdminGestionDesGroupesUserCtrl(COLLAB_CONF,COLLAB_VALUES,UtilsService,GroupesAdminService){
        var ctrl = this;

        //ctrl.usersDrag = [];
        ctrl.filtered = false;
        ctrl.listeUsersFiltered = [];
        ctrl.search = '';
        ctrl.assignTopic = COLLAB_VALUES.ASSIGN_TOPIC_DEFAULT;
        ctrl.assignTopicCloture = COLLAB_VALUES.ASSIGN_TOPIC_CLOTURE_DEFAULT;

        ctrl.onCheckAssignTopic = onCheckAssignTopic;
        ctrl.startDragGroupe = startDragGroupe;
        ctrl.onSubmitFilterUtilisateurs = onSubmitFilterUtilisateurs;

        ctrl.loading = {
            'show': false,
            'content': COLLAB_CONF.MIDDLE_LOADING
        };

        ctrl.$onInit = function(){


            if(angular.isUndefined(ctrl.users)){
                ctrl.loading.show = true;
                GroupesAdminService.getAllUsers().then(function(groupes){
                    ctrl.users = groupes;
                }).finally(function(){
                    ctrl.loading.show = false;
                });
            }
        };

        function onCheckAssignTopic(){
          for(var i = 0; i < ctrl.listeUsersFiltered.length; i++) {
            if(_.isNil(ctrl.listeUsersFiltered[i])) continue;
            ctrl.listeUsersFiltered[i].assignTopic = ctrl.assignTopic;
          }

          if(_.isNil(ctrl.assignTopic) || !ctrl.assignTopic){
            ctrl.assignTopicCloture = false;
          }
        }

        function filtreGroupes(searchVal,groupes) {
            var filtered = [];

            for(var i = 0; i < groupes.length; i++){
                var currentGroupe = groupes[i];
                var testText = currentGroupe.getDefaultNom();
                if(currentGroupe.getFiche()){
                    testText += ' ' + currentGroupe.getFiche().getDefaultNom();
                }

                if(UtilsService.test(testText,searchVal,'ig')){
                    filtered.push({
                      groupeParent:false,
                      obj:currentGroupe,
                      assignTopic: ctrl.assignTopic,
                      assignTopicCloture: ctrl.assignTopicCloture
                    });
                }
            }

            return filtered;
        }

        /**
         * Lors du start d'un drag and drop d'un utilisateur
         */
        function startDragGroupe(event, ui) {
            angular.element(ui.helper).addClass('ui-draggable-badge-helper');
        }


        function onSubmitFilterUtilisateurs(){

            //console.log(ctrl.search);
            if(!ctrl.search || ctrl.search === ''){
                ctrl.listeUsersFiltered = [];
                ctrl.filtered = false;
            }else{
                ctrl.listeUsersFiltered = filtreGroupes(ctrl.search,ctrl.users);
                //ctrl.listeUsersFiltered = UtilsService.filterBy(ctrl.users,['libelle'],ctrl.search);
                ctrl.filtered = true;
            }
            //console.log(ctrl.listeUsersFiltered);
        }

    }
})();
