(function() {
  'use strict';

  angular.module('collaboreApp').factory('RecapShootingMail', RecapShootingMailModel);

  /*@ngInject*/
  function RecapShootingMailModel(Main, Topic, Groupe, UtilsService){

    function RecapShootingMail(objetData){
      Main.apply(this, arguments);
      this.hydrate(objetData);
    }

    RecapShootingMail.prototype = {

      model: 'RecapShootingMail',
      isRecapShootingMail: true,

      getId: function(){
        return this.id;
      },
      setId: function(id){
        this.id = id;
      },

      getDateCreation: function(){
        return this.dateCreation;
      },
      setDateCreation: function(date){
        this.dateCreation = date;
        this.setDateCreationFormatUS(date);
        this.setDateCreationFormatFR(date);
      },

      getDateCreationFormatUS: function(){
        return this.dateCreationFormatedUS;
      },
      setDateCreationFormatUS: function(date){
        this.dateCreationFormatedUS = moment(date).format('YYYY-MM-DD');
      },

      getDateCreationFormatFR: function(){
        return this.dateCreationFormatedFR;
      },
      setDateCreationFormatFR: function(date){
        this.dateCreationFormatedFR = moment(date).format('DD/MM/YYYY HH:mm:ss');
      },

      getDateEnvoie: function(){
        return this.dateEnvoie;
      },
      setDateEnvoie: function(date){
        this.dateEnvoie = date;
        this.setDateEnvoieFormatFR(date);
      },

      getDateEnvoieFormatFR: function(){
        return this.dateEnvoieFormatedFR;
      },
      setDateEnvoieFormatFR: function(date){
        this.dateEnvoieFormatedFR = moment(date).format('DD/MM/YYYY HH:mm:ss');
      },

      getEtat: function(){
        return this.etat;
      },
      setEtat: function(etat){
        this.etat = etat;
      },

      getStatut: function(){
        return this.statut;
      },
      setStatut: function(statut){
        this.statut = statut;
      },

      getType: function(){

        if(this.getSmtpTypeAuth() === 'SMS') return 'SMS';
        else {
          var societe = UtilsService.ltrim(UtilsService.getUserDieze(), '#');
          if(this.getMessageMail() === '' && this.getSujetMail() === societe) return 'Fax';
          else if(UtilsService.test(this.getSujetMail(), '\\[' + societe + '\\]\\s{1}Devis\\s{1}OK\\s{1}','gi')) return 'Devis Accepté';
          else if(UtilsService.test(this.getSujetMail(), '\\[' + societe + '\\]\\s{1}Devis\\s{1}Refusé\\s{1}','gi')) return 'Devis Refusé';
          else if(UtilsService.test(this.getSujetMail(), '\\[' + societe + '\\]\\s{1}Nouveau\\s{1}Message\\s{1}','gi')) return 'Message';
          else if(UtilsService.test(this.getSujetMail(), '\\[' + societe + '\\]\\s{1}Demande\\s{1}de\\s{1}devis\\s{1}','gi')) return 'Demande de Devis';
          else if(UtilsService.test(this.getSujetMail(), '\\[' + societe + '\\]\\s{1}Devis\\s{1}Signé\\s{1}','gi')) return 'Devis signé';
          else if(UtilsService.test(this.getSujetMail(), '\\[' + societe + '\\]\\s{1}Relance\\s{1}','gi')) return 'Relance';
          else if(UtilsService.test(this.getSujetMail(), '\\[' + societe + '\\]\\s{1}Demande\\s{1}d\'intervention\\s{1}','gi')) return 'Demande d\'Intervention';
        }

        return '';
      },
      setType: function(type){
        this.smtpTypeAuth = type;
      },

      getSmtpTypeAuth: function(){
        return this.smtpTypeAuth;
      },
      setSmtpTypeAuth: function(type){
        this.smtpTypeAuth = type;
      },

      getTopic: function(){
        return this.topic;
      },
      setTopic: function(topic){
        if(angular.isObject(topic)) {
          if(!topic.isModel) {
            topic = new Topic(topic);
          }
          this.topic = topic;
        }
      },

      getExpediteur: function(){
        var expediteur = this.expediteur;
        if(_.isNil(expediteur) && !_.isNil(this.getEmailExpediteur())){
          expediteur = this.getEmailExpediteur();
        }
        return expediteur;
      },
      setExpediteur: function(expediteur){
        if(angular.isObject(expediteur)) {
          if(!expediteur.isModel) {
            expediteur = new Groupe(expediteur);
          }
          this.expediteur = expediteur;
        }
      },

      getEmailExpediteur: function(){
        return this.emailExpediteur;
      },
      setEmailExpediteur: function(expediteur){
        this.emailExpediteur = expediteur;
      },

      getIdGroupeAll: function(){
        return this.idGroupeAll;
      },
      setIdGroupeAll: function(id){
        this.idGroupeAll = id;
      },

      getDestinataires: function(){
        return this.destinataires;
      },
      setDestinataires: function(liste){
        this.destinataires = liste;
      },

      getSujetMail: function(length){
        var sujet = this.sujetMail;
        if(length && angular.isNumber(length) && sujet.length > length) {
          sujet = sujet.substring(0,length) + '...';
        }
        return sujet;
      },
      setSujetMail: function(val){
        this.sujetMail = val;
      },

      getMessageMail: function(length){
        var message = this.messageMail;
        if(length && angular.isNumber(length) && message.length > length) {
          message = message.substring(0,length) + '...';
        }
        return message;
      },
      setMessageMail: function(val){
        this.messageMail = val;
      },

      hasMessageErreur: function(){
        return (this.hasOwnProperty('messageErreur') && this.messageErreur !== '');
      },
      getMessageErreur: function(){
        return this.messageErreur;
      },
      setMessageErreur: function(val){
        this.messageErreur = val;
      },

      getPiecesJointes: function(){
        return this.piecesJointes;
      },
      setPiecesJointes: function(liste){
        this.piecesJointes = liste;
      },

      isTempEmail: function(){
        var isTemp = false;
        if(this.getDestinataires().length) {
          for(var i = 0; i < this.getDestinataires().length; i++) {
            if(UtilsService.endsWith(this.getDestinataires()[0], '@yopmail.com') || UtilsService.endsWith(this.getDestinataires()[0], '@courriel.fr.nf')){
              isTemp = true;
              break;
            }
          }
        }
        return isTemp;
      },

      isSms: function(){
        if(this.getType() === 'SMS') return true;
        return false;
      },

      canResend: function(){
        return (this.getEtat() === '0' || this.getEtat() === '-5');
      }
    };

    angular.extend(RecapShootingMail.prototype, Main.prototype);

    return RecapShootingMail;
  }

})();
