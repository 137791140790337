(function () {
  'use strict';

  /**
   * @desc Composant d'ajout de document
   * @example <add-documents></add-documents>
   */
  angular
    .module('collaboreApp')
    .component('addDocuments', {
      templateUrl: 'app/topics/components/add-documents/add-documents.component.html',
      bindings: {
        contenu: '<',
        action: '<',
        limitDocument:'<',
        eventMode: '<', // Si à true alors le bouton d'upload est caché et le choix des doc est déclenché par événement
        openChooseDoc:'<',
        dropzone:'<',
        onEmit: '&',

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<'
      },
      controllerAs: 'adddocumentsctrl',
      controller: AddDocumentsController
    });

  /*@ngInject*/
  function AddDocumentsController(COLLAB_CONF, COLLAB_VALUES, $rootScope, $sce, $log, $q, bowser, sweet, DocumentManagerService, ModalsService, ContenuService, UtilsService) {

    var _this = this;
    _this.getTitreModal = getTitreModal;
    _this.onClickCloseDocument = onClickCloseDocument;
    _this.onClickSave = onClickSave;
    _this.onClickClose = onClickClose;
    _this.onEmitFromDocumentsComponent = onEmitFromDocumentsComponent;
    _this.onEmitFromUploadCollab = onEmitFromUploadCollab;

    _this.$onInit = function () {
      if(_this.resolve && _this.modalInstance) {
        _this.contenu = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'contenu'));
        _this.action = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'action'));
        _this.limitDocument = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'limitDocument'));
        _this.eventMode = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'eventMode'));
        _this.openChooseDoc = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'openChooseDoc'));
        _this.dropzone = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'dropzone'));
      }
      init(_this.contenu);
    };

    _this.$onChanges = function(changes){
      if(_.isObject(changes.contenu) && !changes.contenu.isFirstChange()){
        _this.contenu = changes.contenu.currentValue;
        init(changes.contenu.currentValue);
      }
      if(_.isObject(changes.action) && !changes.action.isFirstChange()){
        _this.action = changes.action.currentValue;
        init();
      }
      if(_.isObject(changes.limitDocument) && !changes.limitDocument.isFirstChange()) _this.limitDocument = changes.limitDocument.currentValue;
    }

    /**
     * Initialisation
     * @param contenu
     */
    function init(contenu){

      _this.displayUploader = true;
      _this.notMobile = UtilsService.notMobile;

      _this.listObjectWhenAddingFile = [];

      _this.currentPdfViewer = {
        document: null,
        pdfUrl: null
      };

      _this.currentUploader = {
        uploader: null,
        uploadDeferred: null,
        listContenu: [],
        itemsDropedFromDocs: [],
        upload: function(){
          var promises = [];
          var globalDeferred = $q.defer();

          try {

            var _thisCurrentUploader = this;
            var tasks = [];
            if(this.itemsDropedFromDocs.length) {
              tasks.push(function(){
                var itemDroppedDeferred = $q.defer();
                var params = {
                  action: _this.action,
                  idContenu: _this.contenu.getIdContenu(),
                  guids: _thisCurrentUploader.itemsDropedFromDocs.map(function(doc) {
                    return doc.guid;
                  })
                };
                ContenuService
                  .addDocuments(params)
                  .then(function onSuccess(listContenu){
                    //console.log(listContenu);
                    //emit({action: 'addOrUpdateDemande', contenu: listContenu});
                    itemDroppedDeferred.resolve(listContenu);
                  })
                  .catch(function onError(msg){
                    itemDroppedDeferred.reject(msg);
                  })
                  .finally(function onFinally(){
                  });
                return itemDroppedDeferred.promise;
              });
            }
            if(this.uploader.getNotUploadedItems().length){
              this.testUploaderExist();
              tasks.push(function(){
                _thisCurrentUploader.uploadDeferred = $q.defer();
                _thisCurrentUploader.uploader.uploadAll();
                return _thisCurrentUploader.uploadDeferred.promise;
              });
            }
            /*
            $q.all(promises)
              .then(function(ret){

                var lisContenu = ret.reduce(function(accum, tab) {
                  accum.push(...tab);
                  return accum;
                }, []);

                console.log(lisContenu);
                globalDeferred.resolve(lisContenu);
              })
              .catch(function(err){
                globalDeferred.reject(err);
              });*/

            if(tasks.length) {
              $q.serial(tasks)
                .then(function (listContenu) {
                  globalDeferred.resolve(listContenu);
                })
                .catch(function(err){
                  globalDeferred.reject(err);
                });
            }
            else globalDeferred.reject("Pas de taches à exécuter");
          }
          catch(err){
            globalDeferred.reject(err.message);
          }
          return globalDeferred.promise;
        },
        testUploaderExist: function(){
          if(_.isNil(this.uploader)) {
            ModalsService.alertErreur("Uploader null !");
            throw new Error("Uploader null !");
          }
        },
        onSuccessItem: function(fileItem, response){
          if(response.success){
            if(_.isArray(response.result)) {
              //_this.currentUploader.listContenu.push(...response.result.map(function(c){return ContenuService.instanceContenuByType(c);}));
              response.result.map(function(c){
                _this.currentUploader.listContenu.push(ContenuService.instanceContenuByType(c));
              });
            }
            else if(_.isObject(response.result)) _this.currentUploader.listContenu.push(ContenuService.instanceContenuByType(response.result));
          }
        },
        onCompleteAll: function(){
          _this.currentUploader.uploadDeferred.resolve(_this.currentUploader.listContenu);
        },
        onErrorItem: function(fileItem, response){
          _this.currentUploader.uploadDeferred.reject(response);
        }
      };

      if(_.isNil(contenu)) contenu = _this.contenu;
      try{
        if(!_this.action) throw new Error('Pas d\'action');
        if(!contenu || !_.isObject(contenu)) throw new Error('Pas de contenu');

        if(!_this.limitDocument) _this.limitDocument = 0;

        displayUploader();

        _this.listObjectWhenAddingFile = [
          {idContenu: contenu.getIdContenu()},
          {action: _this.action}
        ];

        _this.urlUploader = COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_CONTENU;
        _this.queueLimit = null;
        _this.textUpload = null;

        if(_this.action === 'addFacture') {
          _this.textUpload = "Glissez/Déposez votre facture ou cliquez pour sélectionner";
          _this.queueLimit = 1;
          if(!_this.contenu.getDocumentFacture()) {
            //initUploadFacture(contenu);
          }
        }
        if(_this.action === 'addDevis') {
          _this.textUpload = "Glissez/Déposez vos devis ou cliquez pour en sélectionner";
          _this.listObjectWhenAddingFile.push({convertToPdf: true});
          //initUploadDevis(contenu);
        }

      }
      catch(err) {
        ModalsService.alertErreur(err.message);
        _this.loading = false;
      }
    }

    function displayUploader(){
      _this.displayUploader = true;
      if(_this.action === 'addFacture') {
        if(_this.contenu.getDocumentFacture()) {
          _this.displayUploader = false;
        }
      }
    }

    /**
     * Permet de créer une instance d'uploader de facture par demande
     * @param demande
     */
    /*
    function initUploadFacture(demande) {

      var currentUploadFacture = {
        loading: false,
        deferred: null,
        contenu: demande,
        uploader: new FileUploader({
          autoUpload: false,
          url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_CONTENU,
          alias: 'fileName',
          removeAfterUpload: true,
          queueLimit: 1
        }),
        getNbNotUploadedDoc: function(){
          return (this.uploader.getNotUploadedItems().length);
        },
        upload: function(){
          this.deferred = $q.defer();
          this.uploader.uploadAll();
          return this.deferred.promise;
        }
      };
      currentUploadFacture.uploader.filters.push(sizeLimitFilterUpload());

      // Après l'ajout d'un fichier en pièce jointe
      currentUploadFacture.uploader.onAfterAddingFile = function (fileItem) {
        afterAddingFile(fileItem);
        fileItem.formData.push({idContenu: demande.getIdContenu()});
        fileItem.formData.push({action: 'addFacture'});
      };

      // Lorsqu'un fichier est bien upload
      currentUploadFacture.uploader.onSuccessItem = function (fileItem, response) {
        if(response.success){
          currentUploadFacture.contenu = ContenuService.instanceContenuByType(response.contenu);
        }
      };

      // Lorsque l'upload de toute la liste est terminée
      currentUploadFacture.uploader.onCompleteAll = function () {
        currentUploadFacture.deferred.resolve(currentUploadFacture.contenu);
      };

      // Si erreur sur un item à upload
      currentUploadFacture.uploader.onErrorItem = function (fileItem, response) {
        $log.error(fileItem);
        currentUploadFacture.deferred.reject(response);
      };

      // Upload de facture
      _this.currentUploader = currentUploadFacture;
    }*/

    /**
     * Permet de créer une instance d'uploader de devis par demande
     * @param demande
     */
    /*
    function initUploadDevis(demande) {

      var currentUploadDevis = {
        loading: false,
        deferred: null,
        listGuids: [],
        listDevis: [],
        uploader: new FileUploader({
          autoUpload: false,
          url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_CONTENU,
          alias: 'fileName',
          removeAfterUpload: true
        }),
        getNbNotUploadedDoc: function(){
          return (this.uploader.getNotUploadedItems().length);
        },
        upload: function(){
          this.deferred = $q.defer();
          this.uploader.uploadAll();
          return this.deferred.promise;
        }
      };

      currentUploadDevis.uploader.onAfterAddingFile = function (fileItem) {
        console.log('addFile');
        console.log('fileItem', fileItem);
      };

      _this.listObjectWhenAddingFile.push({convertToPdf: true});

      _this.currentUploader = currentUploadDevis;
    }*/

    /**
     * Permet de générer le titre de la modal
     */
    function getTitreModal(){
      var titre = 'Ajouter des documents';
      if(_this.action === 'addFacture') {
        titre = 'Ajouter une facture';
      }
      else if(_this.action === 'addDevis'){
        titre = 'Ajouter un devis';
      }
      else if(_this.action === 'addDocuments'){
        titre = 'Ajouter des documents';
      }
      if(_.isString(_this.titleModal)) {
        titre = _this.titleModal;
      }
      return titre;
    }


    function onClickCloseDocument(){
      _this.currentPdfViewer = {
        document: null,
        pdfUrl: null
      };
    }

    /**
     * Lors du clique sur le bouton pour sauvegarder
     */
    function onClickSave(){
      try {

        //_this.loading = true;

        if(_.isNil(_this.currentUploader.uploader)) throw new Error("Pas d'uploader");

        // Si il n'y a pas de document à upload
        if (!_this.currentUploader.uploader.getNotUploadedItems().length && !_this.currentUploader.itemsDropedFromDocs.length)
          throw new Error('Pas de document à envoyer');

        _this.currentUploader
          .upload()
          .then(function onSuccessUpload(listContenu) {

            if(!_this.modalInstance && _.isArray(listContenu) && listContenu.length) {
              for(var c = 0; c < listContenu.length; c++) {
                if(listContenu[c].idContenu === _this.contenu.idContenu){
                  _this.contenu = listContenu[c];
                  break;
                }
              }
            }

            emit({
              action: 'addOrUpdateDemande',
              contenu: listContenu
            });
            $rootScope.$broadcast('refreshDocuments');
          })
          .catch(function onErrorUpload(msg){
            ModalsService.alertErreur(msg);
          })
          .finally(function onFinallyUpload(){
            //_this.loading = false;
            displayUploader();
          });
      }
      catch(err) {
        ModalsService.alertErreur(err.message);
        //_this.loading = false;
      }
    }

    /**
     * Fermeture du composant si en mode modal
     */
    function onClickClose(){
      if(!_this.modalInstance) _this.onEmit({obj: {action: "closeAddDocuments", contenu: _this.contenu}});
      else _this.dismiss({$value: 'cancel'});
    }

    /**
     * Permet d'ouvrir un document dans le viewer
     * @param document
     */
    function openViewerDocument(document){
      if (_.isObject(document)) {
        var guid = document.getGuid();

        _this.currentPdfViewer.document = document;

        if (bowser.msie) {
          var urlPdf = DocumentManagerService.getUrlStream(guid, _this.contenu.idContenu);
          _this.currentPdfViewer.pdfUrl = $sce.trustAsResourceUrl(encodeURIComponent(urlPdf));
        } else {
          // Recupère le PDF
          _this.loadingViewerDoc= true;
          DocumentManagerService.getStream(guid, _this.contenu.idContenu)
            .then(function (url) {
              _this.currentPdfViewer.pdfUrl = encodeURIComponent(url);
            })
            .catch(function () {
              _this.currentPdfViewer.document = false;
              _this.currentPdfViewer.pdfUrl = false;
            })
            .finally(function () {
              _this.loadingViewerDoc = false;
            });
        }
      }
    }

    /**
     * Lorsque le composant "documents" remonte une information
     * @param obj
     */
    function onEmitFromDocumentsComponent(obj){
      try {
        if (!obj) {
          throw new Error('Il manque l\'objet "obj" !');
        }
        if(!_.isObject(obj.document)) {
          throw new Error('Il manque l\'objet "obj.document" !');
        }

        // obj.document
        if(obj.action === 'open') {
          // Si version desktop
          if(_this.notMobile) {
            openViewerDocument(obj.document);
          }
          // Si version mobile
          else {
            DocumentManagerService.openDocument(obj.document.getGuid(), _this.contenu.idContenu);
          }

        }
        else if(obj.action === 'delete') {
          _this.loading = true;
          if(_this.action === 'addFacture') {
            _this.contenu
              .deleteDocumentFromFacture(obj.document.getGuid())
              .then(function(contenu){
                emit({
                  action: 'addOrUpdateDemande',
                  contenu: contenu
                });
                $rootScope.$broadcast('refreshDocuments');
              })
              .catch(function (msg) {
                ModalsService.alertErreur(msg);
              })
              .finally(function () {
                _this.loading = false;
                displayUploader();
              });
          }
          else if(_this.action === 'addDevis') {
            _this.contenu
              .deleteDocumentDevis()
              .then(function (contenu) {
                emit({
                  action: 'addOrUpdateDemande',
                  contenu: contenu
                });
                $rootScope.$broadcast('refreshDocuments');
              })
              .catch(function (msg) {
                ModalsService.alertErreur(msg);
              })
              .finally(function () {
                _this.loading = false;
                displayUploader();
              });
          }
        }
      }
      catch (err) {
        console.log('[AddDocumentsController.onEmitFromDocumentsComponent] Erreur : ' + err.message);
      }
    }

    /**
     * Lorsque le composant enfant upload-collab emit quelque chose
     * @param obj
     */
    function onEmitFromUploadCollab(obj){
      // console.log(obj);
      try {
        if (!obj) throw new Error('Pas de "obj"');
        if (_.isNil(obj.uploader)) throw new Error('Pas de "obj"');

        _this.currentUploader.uploader = obj.uploader;

        if(_.isObject(obj.fileItem) && _.isObject(obj.response)) {
          if(obj.action === 'onErrorItem') {
            _this.currentUploader.onErrorItem(obj.fileItem, obj.response);
          }
          if(obj.action === 'onSuccessItem'){
            _this.currentUploader.onSuccessItem(obj.fileItem, obj.response);
          }
        }

        // Notifie que tous les upload sont terminés
        if(obj.action === 'onCompleteAll') _this.currentUploader.onCompleteAll();

        if(obj.action === 'updateDroppedDoc' && _.isArray(obj.docs)) {
          var msgErreur = null;
          if(_this.action === 'addFacture' || _this.action === 'addDevis') {
            for (var d = 0; d < obj.docs.length; d++) {
              if (!_.isNil(obj.docs[d].typeDocument) && !UtilsService.contains(COLLAB_CONF.TYPE_DOC_ENUMS_ALLOWED_FOR_DEVIS_FACTURE, obj.docs[d].typeDocument)) {
                msgErreur = "Impossible d'ajouter un document de type \"" + obj.docs[d].getLibelleTypeDocument() + "\"";
                break;
              }
            }
          }

          if (!_.isNil(msgErreur)) {
            if(_this.action === 'addFacture') msgErreur += " en tant que facture";
            else if(_this.action === 'addDevis') msgErreur += " en tant que devis";
            ModalsService.alertErreur(msgErreur);
          }
          else _this.currentUploader.itemsDropedFromDocs = _.unionBy(_this.currentUploader.itemsDropedFromDocs, angular.copy(obj.docs), 'guid');
        }
      }
      catch (err) {
        console.log('[AddDocumentsController.onEmitFromUploadAutresCollab] Erreur : ' + err.message);
      }
    }

    /**
     * Permet d'emit une information vers le parent
     * @param obj
     */
    function emit(valObj){
      if(!_this.modalInstance) _this.onEmit({obj: valObj});
      else _this.close({$value: valObj});
    }
  }
})();
