(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.ParametrageService
   * @description
   * # ParametrageService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('ParametrageService',
      ParametrageServiceController
    );

  /** @ngInject */
  function ParametrageServiceController(MainService, ParamsAjax, ErreurCollabService, $q, Parametrage, ParametrageComfact, ParametrageResource) {


    var ctrl = this;

    ctrl.Parametrage = Parametrage;
    ctrl.ParametrageComfact = ParametrageComfact;
    ctrl.getParametrage = getParametrage;                  // Recupère le parametrage pour le groupe user
    ctrl.updateParametrage = updateParametrage;            // Insert/Update le parametrage pour le groupe user

    ctrl.getParametrageAdmin = getParametrageAdmin;        // Recupère le parametrage pour le groupe All
    ctrl.updateParametrageAdmin = updateParametrageAdmin;  // Insert/Update le parametrage pour le groupe All

    ctrl.getLogoCollab = getLogoCollab;                    // Recupération du logo collab
    ctrl.updateLogoCollab = updateLogoCollab;              // Modification du logo collab
    ctrl.deleteLogoCollab = deleteLogoCollab;              // Supression du logo collab

    ctrl.getParametrageComfact = getParametrageComfact;                  // Recupère le parametrage pour le groupe user
    ctrl.updateParametreComfact = updateParametreComfact;            // Insert/Update le parametrage pour le groupe user


    /**
     * Recupère le parametrage pour le groupe user
     * @returns {IPromise<T>}
     */
    function getParametrage() {
      var deferred = $q.defer();

      ParamsAjax.getParametrage(null, function (parametrage) {

        // Si pas de parametrage
        if (parametrage.nb === '0') {

          // deferred.resolve($rootctrl.current.userGroupe.getDefaultParametrage());
          deferred.resolve(new Parametrage());
        }
        // Si parametrage trouvé
        else if (parametrage.nb === '1') {
          deferred.resolve(new Parametrage(parametrage.parametrages[0]));
        }
        else {
          deferred.reject('Pas de Parametrage trouvé');
        }

      }, function (error) {
        ErreurCollabService.erreurServlet(error, 'Erreur avec ParametrageService::getParametrageAdmin()', deferred);
      });
      return deferred.promise;
    }

    /**
     * Insert/Update le parametrage pour le groupe user
     * @param obj
     * @returns {Promise}
     */
    /*
    function updateParametrage(obj) {
      var deferred = $q.defer();

      // Doit clone les param pour éviter que de modifier les params de l'agence
      // Est-ce utile car il faut les froits admin et le param "editGroupeAll" à true pour modifier les params agence
      var clone = {};

      // Affichage
      clone.stateHomeCollab = angular.copy(obj.stateHomeCollab);
      clone.hideBtnMessage = angular.copy(obj.hideBtnMessage);
      clone.displayDetailsInTopicList = angular.copy(obj.displayDetailsInTopicList);
      clone.displayWidgetTempDocBox = angular.copy(obj.displayWidgetTempDocBox);

      // Collab
      clone.noCommunicationAfterCreateEvent = angular.copy(obj.noCommunicationAfterCreateEvent);
      clone.onlyValidContract = angular.copy(obj.onlyValidContract);
      clone.onClickReplyToAllSendByMailEnabled = angular.copy(obj.onClickReplyToAllSendByMailEnabled);

      // Documents
      clone.createDevisNoSendDocumentsByMail = angular.copy(obj.createDevisNoSendDocumentsByMail);
      clone.createContratNoSendDocumentsByMail = angular.copy(obj.createContratNoSendDocumentsByMail);

      // Signature
      clone.signature = angular.copy(obj.signature);

      // Demandes
      // - Devis
      clone.validDevisWhenSignIt = angular.copy(obj.validDevisWhenSignIt);

      // Mes Notifications
      // - Devis Inter
      clone.sendNotifMailActionFromFournisseur = angular.copy(obj.sendNotifMailActionFromFournisseur);
      clone.sendNotifMailActionFromFournisseurOnlyExpediteur = angular.copy(obj.sendNotifMailActionFromFournisseurOnlyExpediteur);
      clone.noNotifWhenCreateDevisInterSameSociete = angular.copy(obj.noNotifWhenCreateDevisInterSameSociete);

      // - Contrats
      clone.sendNotifMailActionContratFromFournisseur = angular.copy(obj.sendNotifMailActionContratFromFournisseur);
      clone.sendNotifMailActionContratFromFournisseurOnlyExpediteur = angular.copy(obj.sendNotifMailActionContratFromFournisseurOnlyExpediteur);
      clone.noNotifWhenCreateContratSameSociete = angular.copy(obj.noNotifWhenCreateContratSameSociete);

      // Notifications Fournisseur
      clone.noSendMailWhenAcceptOrDeclineDevis = angular.copy(obj.noSendMailWhenAcceptOrDeclineDevis);
      clone.noSendMailWhenSigneDevis = angular.copy(obj.noSendMailWhenSigneDevis);
      clone.noSendMailWhenAcceptOrDeclineContrat = angular.copy(obj.noSendMailWhenAcceptOrDeclineContrat);
      clone.noSendMailWhenSigneContrat = angular.copy(obj.noSendMailWhenSigneContrat);

      ParamsAjax.updateParametrage(clone, function (parametrage) {

        // Si parametrage trouvé
        if (parametrage.nb === '1') {
          deferred.resolve(new Parametrage(parametrage.parametrages[0]));
        }
        else {
          deferred.reject('Pas de Parametrage trouvé');
        }

      }, function (error) {
        ErreurCollabService.erreurServlet(error, 'Erreur avec ParametrageService::updateParametrage()', deferred);
      });
      return deferred.promise;
    }*/

    /**
     * Insert/Update le parametrage pour le groupe user
     * @param obj
     * @returns {Promise}
     */
    function updateParametrage(obj) {
      var deferred = $q.defer();

      ParamsAjax.updateParametrage(angular.copy(obj), function (parametrage) {

        // Si parametrage trouvé
        if (parametrage.nb === '1') {
          deferred.resolve(new Parametrage(parametrage.parametrages[0]));
        }
        else {
          deferred.reject('Pas de Parametrage trouvé');
        }

      }, function (error) {
        ErreurCollabService.erreurServlet(error, 'Erreur avec ParametrageService::updateParametrage()', deferred);
      });
      return deferred.promise;
    }

    /**
     * Recupère le parametrage pour le groupe All
     * @returns {IPromise<T>}
     */
    function getParametrageAdmin() {
      var deferred = $q.defer();

      ParamsAjax.getParametrage({groupeAll: true}, function (parametrage) {

        // Si pas de parametrage
        if (parametrage.nb === '0') {
          deferred.resolve(new Parametrage());
        }
        // Si parametrage trouvé
        else if (parametrage.nb === '1') {
          deferred.resolve(new Parametrage(parametrage.parametrages[0]));
        }
        else {
          deferred.reject('Pas de Parametrage trouvé');
        }

      }, function (error) {
        ErreurCollabService.erreurServlet(error, 'Erreur avec ParametrageService::getParametrageAdmin()', deferred);
      });
      return deferred.promise;
    }

    /**
     * Insert/Update le parametrage pour le groupe All
     * @param obj
     * @returns {Promise}
     */
    function updateParametrageAdmin(obj) {
      var deferred = $q.defer();

      var clone = angular.copy(obj);
      clone.editGroupeAll = true;
      if (clone.hasOwnProperty('idParametrage')) delete clone.idParametrage;
      if (clone.hasOwnProperty('priority')) delete clone.priority;

      if (clone.hasOwnProperty('listeMetiersRechercheContrats')) {
        var liste = '';
        if (clone.listeMetiersRechercheContrats !== '') {
          if (clone.listeMetiersRechercheContrats.length) {
            liste = angular.toJson(clone.listeMetiersRechercheContrats);
          }
        }
        clone.listeMetiersRechercheContrats = liste;
      }

      ParamsAjax.updateParametrage(clone, function (parametrage) {

        // Si parametrage trouvé
        if (parametrage.nb === '1') {
          deferred.resolve(new Parametrage(parametrage.parametrages[0]));
        }
        else {
          deferred.reject('Pas de Parametrage trouvé');
        }

      }, function (error) {
        ErreurCollabService.erreurServlet(error, 'Erreur avec ParametrageService::updateParametrageAdmin()', deferred);
      });
      return deferred.promise;
    }

    function getLogoCollab(){
    }

    function updateLogoCollab(){
    }


    /**
     * Permet de supprimer un logo d'un idGroupe ou du groupe all
     * @param idGroupe
     * @returns {angular.IPromise<T>}
     */
    function deleteLogoCollab(idGroupe){
      var defered = $q.defer();
      var params = {
        action: 'deleteLogoCollab'
      };
      if(idGroupe) params.idGroupe = idGroupe;
      else params.editGroupeAll = true;

      ParamsAjax.deleteLogoCollab(params, function(retour){
        if(retour.success && _.isObject(retour.result)) defered.resolve(new Parametrage(retour.result));
        else defered.reject(retour.message);
        console.log(retour);
      });

      return defered.promise;
    }


    /**
     * Permet de modifier un paramétrage
     * @param nomParam
     * @param valeurParam
     * @param diese
     * @returns {angular.IPromise<T>}
     */
    function updateParametreComfact(nomParam, valeurParam, editGroupeAll){
      var deferred = $q.defer();
      try {
        if(_.isNil(nomParam))     throw new Error("Il manque le nom du paramètre");
        if(_.isNil(valeurParam))  throw new Error("Il manque la valeur du paramètre");
        var params = {
          action: 'updateParametrageComfact',
          nomParametre: nomParam,
          valeurParametre: valeurParam
        };
        if(editGroupeAll) params.editGroupeAll = true;
        ParametrageResource
          .post(params)
          .$promise
          .then(function onSuccess(json){
            deferred.resolve(MainService.convertJsonToModel(json, 'result', 'ParametrageComfact', deferred));
          })
          .catch(function onError(err) {
            deferred.reject(err);
          });
      }
      catch (e) {
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Recupère le parametrage pour le groupe user
     * @returns {IPromise<T>}
     */
    function getParametrageComfact(getParamsGroupeAll) {
      var deferred = $q.defer();
      try {
        var params = {
          action: 'getParametrageComfact'
        };

        if(getParamsGroupeAll) params.editGroupeAll = true;

        ParametrageResource
          .get(params)
          .$promise
          .then(function onSuccess(json){
            deferred.resolve(MainService.convertJsonToModel(json, 'result', 'ParametrageComfact', deferred));
          })
          .catch(function onError(err) {
            deferred.reject(err);
          });
      }
      catch (e) {
        deferred.reject(e.message);
      }
      return deferred.promise;
    }
  }
})();
