(function() {
	'use strict';

    angular.module('collaboreApp').service('ModuleService', ModuleService);

    /** @ngInject */
	function ModuleService($q, AdminAjax, Module, ModuleType) {

		var vm = this;
		vm.newModule = newModule;
		vm.getListeTypeModule = getListeTypeModule;		// Appel la liste des type de module
		vm.getListeModule = getListeModule;				// Appel la liste des modules
		vm.saveModule = saveModule;						// Ajoute/Update un module dans la BDD
		vm.deleteModule = deleteModule;					// Delete un module de la BDD

		function newModule(data) {
			return new Module(data);
		}

		function getListeTypeModule() {

			var deferred = $q.defer();

			var typeModules = [];
			AdminAjax.getListeTypeModule(function(json){
				if(json.nb !== '0'){

					for(var i = 0 ; i < json.ModuleTypes.length ; i++){
						typeModules.push(new ModuleType(json.ModuleTypes[i]));
					}
					deferred.resolve(typeModules);


				}else{
					deferred.reject('Pas de type de modules');
				}
			},function(error){
				var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
				deferred.reject('Problème avec ModuleService.getListeTypeModule(), '+msgErreur);
			}).$promise.finally(function(){
				typeModules = [];
			});

			return deferred.promise;
		}

		function getListeModule() {

			var deferred = $q.defer();

			var modules = [];
			AdminAjax.getListeModule(function(json){

				if(json.nb !== '0'){

					for(var i = 0 ; i < json.Modules.length ; i++){
						modules.push(new Module(json.Modules[i]));
					}
					deferred.resolve(modules);


				}else{
					deferred.reject('Pas de modules');
				}
			},function(error){
				var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
				deferred.reject('Problème avec ModuleService.getListeModule(), '+msgErreur);
			}).$promise.finally(function(){
				modules = [];
			});

			return deferred.promise;
		}

		function saveModule(moduleJSON) {
			var deferred = $q.defer();

			var params = {
				'objJSON': moduleJSON
			};

			AdminAjax.saveModuleAdmin(params,function(json){

				if(json.nb !== '0'){
					deferred.resolve(new Module(json.Modules[0]));
				}else{
					deferred.reject('Pas de modules');
				}
			},function(error){
				var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
				deferred.reject('Problème avec ModuleService.saveModule(), '+msgErreur);
			});

			return deferred.promise;
		}

		function deleteModule(id) {
			var deferred = $q.defer();

			var params = {
				'idModule': id
			};

			AdminAjax.deleteModuleAdmin(params,function(json){

				if(json.nb !== '0'){
					if(json.Resultats[0].success === 1){
						deferred.resolve();
					}else{
						deferred.reject('Impossible de supprimer le module');
					}
				}else{
					deferred.reject('Retour pas bon');
				}

			},function(error){
				var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
				deferred.reject('Problème avec ModuleService.deleteModule(), '+msgErreur);
			});

			return deferred.promise;
		}
	}

})();