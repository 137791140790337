(function() {
  'use strict';

  angular.module('collaboreApp').factory('RecapSms', RecapSmsModel);

  /*@ngInject*/
  function RecapSmsModel(Main, ContenuMessage, Groupe, TopicInformations, UtilsService){

    function RecapSms(objetData){
      Main.apply(this, arguments);
      this.hydrate(objetData);
    }

    RecapSms.prototype = {

      model: 'RecapSms',
      isRecapSms: true,

      getIdRecapSms: function(){
        return this.idRecapSms;
      },
      setIdRecapSms: function(id){
        this.idRecapSms = id;
      },

      getDateCreation: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateCreation, format);
        }
        return this.dateCreation;
      },
      setDateCreation: function(date){
        this.dateCreation = date;
        this.setDateCreationFormatUS(date);
      },

      getDateCreationFormatUS: function(){
        return this.dateCreationFormatedUS;
      },
      setDateCreationFormatUS: function(date){
        this.dateCreationFormatedUS = moment(date).format('YYYY-MM-DD');
      },

      getDateEnvoie: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateEnvoie, format);
        }
        return this.dateEnvoie;
      },
      setDateEnvoie: function(date){
        this.dateEnvoie = date;
      },

      getDateReception: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateReception, format);
        }
        return this.dateReception;
      },
      setDateReception: function(date){
        this.dateReception = date;
      },

      getStatus: function(){
        return this.status;
      },
      setStatus: function(status){
        this.status = status;
      },
      getEtat: function(){
        var etat = null;
        switch (this.getStatus()) {
          case "SUBMITTED":
            etat = "En cours";
            break;
          case "SENT":
            etat = "Envoyé";
            break;
          case "DELIVERED":
            etat = "Reçu";
            break;
          case "EXPIRED":
            etat = "Le message n'a pas pu être remis dans la période de validité";
            break;
          case "FAILED":
            etat = "Le message n'a pas pu être remis";
            break;
          case "FAILEDAUTHORISATION":
            etat = "Le message Premium n'a pas pu être autorisé pour ce destinataire";
            break;
          case "ACKNOWLEDGED":
            etat = "Le message vocal a été reconnu par le destinataire.";
            break;
          case "CONNECTING":
            etat = "Un message vocal est actuellement remis au client";
            break;
          case "ERROR":
            etat = "Erreur lors de l'envoi";
            break;
          default:
            etat = "Inconnu ("+this.getStatus()+")";
        }
        return etat;
      },

      // idTopic
      getIdTopic: function(){
        return this.idTopic;
      },
      setIdTopic: function(id){
        this.idTopic = id;
      },

      getContenu: function(){
        return this.contenu;
      },
      setContenu: function(contenu){
        if(_.isObject(contenu)) {
          if(!contenu.isModel) {
            contenu = new ContenuMessage(contenu);
          }
          this.contenu = contenu;
        }
      },

      // TopicInformations
      getTopicInformations: function(){
        return this.topicInformations;
      },
      setTopicInformations: function(infos){
        if(_.isObject(infos)){
          if(!infos.isModel) {
            infos = new TopicInformations(infos);
          }
          this.topicInformations = infos;
        }
      },

      getExpediteur: function(){
        return this.expediteur;
      },
      setExpediteur: function(expediteur){
        if(_.isObject(expediteur)) {
          if(!expediteur.isModel) {
            expediteur = new Groupe(expediteur);
          }
          this.expediteur = expediteur;
        }
      },

      getIdGroupeAll: function(){
        return this.idGroupeAll;
      },
      setIdGroupeAll: function(id){
        this.idGroupeAll = id;
      },

      getGroupeDestinataire: function(){
        return this.groupeDestinataire;
      },
      setGroupeDestinataire: function(dest){
        if(_.isObject(dest)) {
          if(!dest.isModel) {
            dest = new Groupe(dest);
          }
          this.groupeDestinataire = dest;
        }
      },

      getNumeroDestinataire: function(){
        return this.numeroDestinataire;
      },
      setNumeroDestinataire: function(num){
        this.numeroDestinataire = num;
      },

      getSujetSms: function(length){
        var sujet = this.sujetSms;
        if(length && _.isNumber(length) && sujet.length > length) {
          sujet = sujet.substring(0,length) + '...';
        }
        return sujet;
      },
      setSujetSms: function(val){
        this.sujetSms = val;
      },

      getMessageSms: function(length){
        var message = this.messageSms;
        if(length && _.isNumber(length) && message.length > length) {
          message = message.substring(0,length) + '...';
        }
        return message;
      },
      setMessageSms: function(val){
        this.messageSms = UtilsService.nl2br(val);
      },

      hasMessageErreur: function(){
        return (this.hasOwnProperty('messageErreur') && this.messageErreur !== '');
      },
      getMessageErreur: function(){
        return this.messageErreur;
      },
      setMessageErreur: function(val){
        this.messageErreur = val;
      },

      getCodeErreur: function(){
        return this.codeErreur;
      },
      setCodeErreur: function(val){
        this.codeErreur = val;
      },

      getIdMessageBatchEsendex: function(){
        return this.idMessageBatchEsendex;
      },
      setIdMessageBatchEsendex: function(val){
        this.idMessageBatchEsendex = val;
      },

      getIdMessageHeaderEsendex: function(){
        return this.idMessageHeaderEsendex;
      },
      setIdMessageHeaderEsendex: function(val){
        this.idMessageHeaderEsendex = val;
      }
    };

    angular.extend(RecapSms.prototype, Main.prototype);

    return RecapSms;
  }
})();
