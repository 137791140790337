
(function() {
  'use strict';

  angular
    .module('collaboreApp')
    .service('AdminCollabService',
      AdminCollabServiceCtrl
    );

  /** @ngInject */
  function AdminCollabServiceCtrl($q, MainService, AdminCollabAjax, GroupeForAdmin, UtilsService, RecapMail, ErreurCollabService){
    var ctrl = this;
    ctrl.getGroupesAll = getGroupesAll; 					    // Recupère toutes les société
    ctrl.searchGroupesAll = searchGroupesAll; 					// Recupère les société par rapport à une recherche
    ctrl.searchGroupesPortefeuille = searchGroupesPortefeuille; 					// Recupère les groupe qui sont normalement des portefeuilles pour un idGroupe
    ctrl.getGroupesPortefeuille = getGroupesPortefeuille; 	    // Recupère tout les portefeuilles
    ctrl.getHierarchieGroupesNonUserForSociete = getHierarchieGroupesNonUserForSociete; // Recupère la hiérarchie des gorupes non user pour une societe
    ctrl.getGroupesUser = getGroupesUser; 	                    // Recupère les groupes user
    ctrl.changePrivilegeForGroupe = changePrivilegeForGroupe;   // Change le privilège d'un groupe
    ctrl.changeEtatForGroupe = changeEtatForGroupe;             // Change l'état d'un groupe
    ctrl.updateGroupeUser = updateGroupeUser;                   // Update les infor d'un groupe
    ctrl.addUtilisateurs = addUtilisateurs; 	                // Ajout de comptes
    ctrl.sendEmailTest = sendEmailTest; 	                    // Envoi un email de test
    ctrl.changeSmtpForGroupe = changeSmtpForGroupe; 	        // Changement d'un SMTP
    ctrl.resetSmtpForGroupe = resetSmtpForGroupe; 	            // Reste un SMTP pour un groupe

    ctrl.getStatsRecapMailParSociete = getStatsRecapMailParSociete; 	            // Recupère le nombre de recap mail en attente par societé
    ctrl.getListRecapMailForGroupe = getListRecapMailForGroupe; 	                // Recupère une liste de recapMail par rapport à l'etat pour une société
    ctrl.resendMailForSociete = resendMailForSociete; 	                            // Renvoi les mails pour une societe
    ctrl.resendMailForIdRecapMail = resendMailForIdRecapMail; 	                    // Renvoi les mails pour une liste d'idRecapMail

    ctrl.getStatsNbTopicByDiese = getStatsNbTopicByDiese; 	                // Recupère le nombre de topic par diese

    ctrl.addGroupeInGroupe = addGroupeInGroupe;                     // Ajoute un Groupe dans un Groupe
    ctrl.assignTopicCloture = assignTopicCloture;                     // Assigne les dossiers cloturé
    ctrl.assignTopicNonCloture = assignTopicNonCloture;                     // Assigne les dossiers non cloturé
    ctrl.removeGroupeFromGroupe = removeGroupeFromGroupe;           // Retire un Groupe d'un Groupe

    ctrl.changeNomSociete = changeNomSociete;                     // Renomme une société
    ctrl.changeNomPortefeuilles = changeNomPortefeuilles;           // Renomme des portefeuilles
    ctrl.deleteSociete = deleteSociete;                           // Supprime une société

    function checkErrorManageGroupe(error){
      if(error.status === 400 && _.isObject(error.data) && !_.isNil(error.data.message)){
        return error.data.message;
      }
      return false;
    }

    function getGroupesAll() {
      var deferred = $q.defer();
      AdminCollabAjax
        .getGroupesAll(function (json) {
          var retour = [];
          if (json.hasOwnProperty('Groupes')) {
            if (json.Groupes.length) {
              for (var g = 0; g < json.Groupes.length; g++) {
                var groupe = new GroupeForAdmin(json.Groupes[g]);
                retour.push(groupe);
              }
            }
          }
          deferred.resolve(retour);
        }, function () {
          deferred.reject('Problème AdminCollabService.getAllSociete()');
        });
      return deferred.promise;
    }

    function searchGroupesAll(val) {
      var deferred = $q.defer();
      AdminCollabAjax
        .searchGroupesAll({search: val}, function (json) {
          var retour = [];
          if (json.hasOwnProperty('Groupes')) {
            if (json.Groupes.length) {
              for (var g = 0; g < json.Groupes.length; g++) {
                var groupe = new GroupeForAdmin(json.Groupes[g]);
                retour.push(groupe);
              }
            }
          }
          deferred.resolve(retour);
        }, function () {
          deferred.reject('Problème AdminCollabService.searchGroupesAll()');
        });
      return deferred.promise;
    }

    function searchGroupesPortefeuille(val) {
      var deferred = $q.defer();
      AdminCollabAjax
        .searchGroupesPortefeuille({idGroupe: val}, function (json) {
          var retour = [];
          if (json.hasOwnProperty('Groupes')) {
            if (json.Groupes.length) {
              for (var g = 0; g < json.Groupes.length; g++) {
                var groupe = new GroupeForAdmin(json.Groupes[g]);
                retour.push(groupe);
              }
            }
          }
          deferred.resolve(retour);
        }, function () {
          deferred.reject('Problème AdminCollabService.searchGroupesPortefeuille()');
        });
      return deferred.promise;
    }


    function getGroupesPortefeuille(dieze) {
      var deferred = $q.defer();
      AdminCollabAjax
        .getGroupesPortefeuille({diese: dieze}, function (json) {
          var retour = [];

          if (json.hasOwnProperty('Groupes')) {
            if (json.Groupes.length) {
              for (var g = 0; g < json.Groupes.length; g++) {
                var groupe = new GroupeForAdmin(json.Groupes[g]);
                retour.push(groupe);
              }
            }
          }
          deferred.resolve(retour);

        }, function () {
          deferred.reject('Problème AdminCollabService.getGroupesPortefeuille()');
        });
      return deferred.promise;
    }

    function getHierarchieGroupesNonUserForSociete(groupe) {
      var deferred = $q.defer();
      AdminCollabAjax
        .getHierarchieGroupesNonUserForSociete({idGroupe: groupe.getIdGroupe(), diese: groupe.getDieze()}, function (json) {
          var retour = [];

          if (json.hasOwnProperty('Groupes')) {
            if (json.Groupes.length) {
              for (var g = 0; g < json.Groupes.length; g++) {
                retour.push(new GroupeForAdmin(json.Groupes[g]));
              }
            }
          }
          deferred.resolve(retour);

        }, function () {
          deferred.reject('Problème AdminCollabService.getHierarchieGroupesNonUserForSociete()');
        });
      return deferred.promise;
    }

    function getGroupesUser(dieze) {
      var deferred = $q.defer();
      AdminCollabAjax
        .getGroupesUser({diese: dieze}, function (json) {
          var retour = [];

          if (json.hasOwnProperty('Groupes')) {
            if (json.Groupes.length) {
              for (var g = 0; g < json.Groupes.length; g++) {
                var groupe = new GroupeForAdmin(json.Groupes[g]);
                retour.push(groupe);
              }
            }
          }
          deferred.resolve(retour);

        }, function () {
          deferred.reject('Problème AdminCollabService.getGroupesUser()');
        });
      return deferred.promise;
    }

    function changePrivilegeForGroupe(idGroupe, newPrivilege) {
      var deferred = $q.defer();
      AdminCollabAjax
        .changePrivilegeForGroupe({idGroupe: idGroupe, newPrivilege: newPrivilege}, function (json) {
          var groupe = false;
          if (json.hasOwnProperty('Groupes')) {
            if (json.Groupes.length === 1) {
              groupe = new GroupeForAdmin(json.Groupes[0]);
            }
          }
          deferred.resolve(groupe);

        }, function () {
          deferred.reject('Problème AdminCollabService.changePrivilegeForGroupe()');
        });
      return deferred.promise;
    }

    function changeEtatForGroupe(idGroupe, newEtat) {
      var deferred = $q.defer();
      AdminCollabAjax
        .changeEtatForGroupe({idGroupe: idGroupe, newEtat: newEtat}, function (json) {
          var groupe = false;
          if (json.hasOwnProperty('Groupes')) {
            if (json.Groupes.length === 1) {
              groupe = new GroupeForAdmin(json.Groupes[0]);
            }
          }
          deferred.resolve(groupe);

        }, function () {
          deferred.reject('Problème AdminCollabService.changeEtatForGroupe()');
        });
      return deferred.promise;
    }

    function updateGroupeUser(objGroupe) {
      var deferred = $q.defer();
      objGroupe.action = 'updateGroupeUser';
      AdminCollabAjax
        .updateGroupeUser(objGroupe, function (json) {
          if (json.hasOwnProperty('Groupes')) {
            var groupe = false;
            if (json.Groupes.length === 1) groupe = new GroupeForAdmin(json.Groupes[0]);
            deferred.resolve(groupe);
          }
          else if (json.hasOwnProperty('Resultats')) {
            if (json.Resultats.length === 1 && json.Resultats[0].success === 0) deferred.reject(json.Resultats[0].message);
          }
        }, function () {
          deferred.reject('Problème AdminCollabService.updateGroupeUser()');
        });
      return deferred.promise;
    }

    function addUtilisateurs(objJSON) {
      var deferred = $q.defer();
      AdminCollabAjax
        .addUtilisateurs({objJSON: angular.toJson(objJSON)}, function (json) {
          if(json.success) deferred.resolve(json.nb + '/' + json.total);
          else deferred.reject((json.message) ? json.message : 'Il y a eu un problème...');
        }, function (error) {
          var err = checkErrorManageGroupe(error);
          if(err) deferred.reject(err);
          else {
            ErreurCollabService.erreurServlet(error,'Erreur avec AdminCollabService.addUtilisateurs()',deferred);
          }
        });
      return deferred.promise;
    }

    function sendEmailTest(obj, emailTest) {
      var deferred = $q.defer();

      var params = {
        idGroupe: obj.idGroupe,
        host: obj.host,
        port: obj.port,
        auth: obj.auth,
        tls: obj.tls,
        userSmtp: obj.userSmtp,
        userSmtpIsExpediteur: obj.userSmtpIsExpediteur,
        mdp: obj.mdp,
        destinataire: emailTest
      };

      AdminCollabAjax
        .sendEmailTest(params, function (json) {
          if (UtilsService.ifResultatJSONSuccess(json)) {
            deferred.resolve();
          }
          else {
            deferred.reject('Il y a eu un problème...');
          }
        }, function (error) {
          var msgErreur = 'Problème avec AdminCollabService.sendEmailTest(),  Erreur ('+error.status+') : '+error.statusText;
          if(error.status===400){
            msgErreur = 'Connexion impossible, veuillez vérifier vos paramètres';
          }
          deferred.reject(msgErreur);
        });
      return deferred.promise;
    }

    function changeSmtpForGroupe(obj) {
      var deferred = $q.defer();

      var params = {
        idGroupe: obj.idGroupe,
        host: obj.host,
        port: obj.port,
        auth: obj.auth,
        tls: obj.tls,
        userSmtp: obj.userSmtp,
        userSmtpIsExpediteur: obj.userSmtpIsExpediteur,
        mdp: obj.mdp
      };

      AdminCollabAjax
        .changeSmtpForGroupe(params, function (json) {
          var groupe = false;
          if (json.hasOwnProperty('Groupes')) {
            if (json.Groupes.length === 1) {
              groupe = new GroupeForAdmin(json.Groupes[0]);
            }
          }
          deferred.resolve(groupe);
        }, function (error) {
          var msgErreur = 'Problème avec AdminCollabService.changeSmtp(),  Erreur ('+error.status+') : '+error.statusText;
          if(error.status===400){
            msgErreur = 'Connexion impossible, veuillez vérifier vos paramètres';
          }
          deferred.reject(msgErreur);
        });
      return deferred.promise;
    }

    function resetSmtpForGroupe(idGroupe) {
      var deferred = $q.defer();

      AdminCollabAjax
        .resetSmtpForGroupe({idGroupe: idGroupe}, function (json) {
          var groupe = false;
          if (json.hasOwnProperty('Groupes')) {
            if (json.Groupes.length === 1) {
              groupe = new GroupeForAdmin(json.Groupes[0]);
            }
          }
          deferred.resolve(groupe);
        }, function () {
          deferred.reject('Problème AdminCollabService.resetSmtpForGroupe()');
        });
      return deferred.promise;
    }

    function getStatsRecapMailParSociete() {
      var deferred = $q.defer();
      AdminCollabAjax
        .getStatsRecapMailParSociete(function (json) {
          var retour = [];
          if(json.nb !== '0'){
            retour = json.StatsRecapMails;
          }
          deferred.resolve(retour);
        }, function () {
          deferred.reject('Problème AdminCollabService.getStatsRecapMailParSociete()');
        });
      return deferred.promise;
    }

    function getListRecapMailForGroupe(idGroupe, etat) {
      var deferred = $q.defer();
      AdminCollabAjax
        .getListRecapMailForGroupe({idGroupeAll: [idGroupe], etat: etat},function (json) {
          var retour = [];
          if(json.nb !== '0'){
            if(angular.isArray(json.RecapMails)) {
              for(var i = 0; i < json.RecapMails.length; i++) {
                retour.push(new RecapMail(json.RecapMails[i]));
              }
            }
          }
          deferred.resolve(retour);
        }, function () {
          deferred.reject('Problème AdminCollabService.getListRecapMailForGroupe()');
        });
      return deferred.promise;
    }

    function resendMailForSociete(idGroupe) {
      var deferred = $q.defer();
      AdminCollabAjax
        .resendMailForSociete({idGroupeAll: [idGroupe]},function (json) {
          if (UtilsService.ifResultatJSONSuccess(json)) {
            deferred.resolve();
          }
          else {
            deferred.reject('Il y a eu un problème...');
          }
        }, function () {
          deferred.reject('Problème AdminCollabService.getStatsRecapMailParSociete()');
        });
      return deferred.promise;
    }

    /**
     * Permet de renvoyer des RecapMail
     * @param array
     * @returns {Promise}
     */
    function resendMailForIdRecapMail(array){
      var deferred = $q.defer();
      try {
        AdminCollabAjax.resendMailForIdRecapMail({idRecapMail: array},function(json){
          var liste = [];
          if(json.nb !== '0'){
            if(angular.isArray(json.RecapMails)) {
              for(var i = 0; i < json.RecapMails.length; i++) {
                liste.push(new RecapMail(json.RecapMails[i]));
              }
            }
          }
          deferred.resolve(liste);
        },function(error){
          ErreurCollabService.erreurServlet(error,'Erreur avec AdminCollabAjax.resendMailForIdRecapMail()',deferred);
        });
      }
      catch(err){
        console.error('[AdminCollabService.resendMailForIdRecapMail] Erreur : '+err.message);
        deferred.reject();
      }
      return deferred.promise;
    }

    function getStatsNbTopicByDiese(filtre) {
      var deferred = $q.defer();

      var paramsSearch = {
        dateDu: null,
        dateAu:  null
      };

      if(angular.isObject(filtre)){

        if(filtre.dateCreation.startDate!==null){
          if(filtre.dateCreation.startDate._isAMomentObject){
            filtre.dateCreation.startDate = filtre.dateCreation.startDate.format();
          }
          paramsSearch.dateDu = UtilsService.dateFormat(filtre.dateCreation.startDate,'yyyy-MM-dd');
        }
        if(filtre.dateCreation.endDate!==null){
          if(filtre.dateCreation.endDate._isAMomentObject){
            filtre.dateCreation.endDate = filtre.dateCreation.endDate.format();
          }
          paramsSearch.dateAu = UtilsService.dateFormat(filtre.dateCreation.endDate,'yyyy-MM-dd');
        }
      }

      AdminCollabAjax
        .getNbTopicByDiese(paramsSearch ,function (json) {
          if(json.success)    deferred.resolve(json.nbTopicByDiese);
          else                deferred.reject(json.message);

        }, function () {
          deferred.reject('Problème AdminCollabService.getStatsNbTopic()');
        });
      return deferred.promise;
    }

    function addGroupeInGroupe(idGroupe, idGroupeParent, dontAssignTopic, assignTopicCloture) {
      var deferred = $q.defer();

      try{
        if(!idGroupe) throw new Error('Il manque l\'attribut "idGroupe" !');
        if(!idGroupeParent) throw new Error('Il manque l\'attribut "idGroupeParent" !');

        var param = {
          idGroupe: idGroupe,
          idGroupeParent: idGroupeParent
        };
        if(!_.isNil(dontAssignTopic)) param.dontAssignTopic = dontAssignTopic;
        if(!_.isNil(assignTopicCloture)) param.assignTopicCloture = assignTopicCloture;

        AdminCollabAjax.addGroupeInGroupe(param, function(json){
          if (UtilsService.ifResultatJSONSuccess(json)) deferred.resolve();
          else deferred.reject(UtilsService.getMessageFromResultatsJSON(json));
        },function(error){
          var err = checkErrorManageGroupe(error);
          if(err) deferred.reject(err);
          else {
            ErreurCollabService.erreurServlet(error,'Erreur avec AdminCollabService.addGroupeInGroupe()',deferred);
          }
        });
      }
      catch(error){
        console.log('[AdminCollabService.addGroupeInGroupe] Erreur : '+error.message);
        deferred.reject();
      }
      return deferred.promise;
    }

    function assignTopicCloture(idGroupe, idGroupeParent) {
      var deferred = $q.defer();

      try{
        if(!idGroupe) throw new Error('Il manque l\'attribut "idGroupe" !');
        if(!idGroupeParent) throw new Error('Il manque l\'attribut "idGroupeParent" !');

        var param = {
          idGroupe: idGroupe,
          idGroupeParent: idGroupeParent
        };

        AdminCollabAjax.assignTopicCloture(param, function(json){
          if (UtilsService.ifResultatJSONSuccess(json)) deferred.resolve();
          else deferred.reject(UtilsService.getMessageFromResultatsJSON(json));
        },function(error){
          var err = checkErrorManageGroupe(error);
          if(err) deferred.reject(err);
          else {
            ErreurCollabService.erreurServlet(error,'Erreur avec AdminCollabService.assignTopicCloture()',deferred);
          }
        });
      }
      catch(error){
        console.log('[AdminCollabService.assignTopicCloture] Erreur : ' + error.message);
        deferred.reject();
      }
      return deferred.promise;
    }

    function assignTopicNonCloture(idGroupe, idGroupeParent) {
      var deferred = $q.defer();

      try{
        if(!idGroupe) throw new Error('Il manque l\'attribut "idGroupe" !');
        if(!idGroupeParent) throw new Error('Il manque l\'attribut "idGroupeParent" !');

        var param = {
          idGroupe: idGroupe,
          idGroupeParent: idGroupeParent
        };

        AdminCollabAjax.assignTopicNonCloture(param, function(json){
          if (UtilsService.ifResultatJSONSuccess(json)) deferred.resolve();
          else deferred.reject(UtilsService.getMessageFromResultatsJSON(json));
        },function(error){
          var err = checkErrorManageGroupe(error);
          if(err) deferred.reject(err);
          else {
            ErreurCollabService.erreurServlet(error,'Erreur avec AdminCollabService.assignTopicCloture()',deferred);
          }
        });
      }
      catch(error){
        console.log('[AdminCollabService.assignTopicCloture] Erreur : ' + error.message);
        deferred.reject();
      }
      return deferred.promise;
    }

    function removeGroupeFromGroupe(idGroupe, idGroupeParent) {
      var deferred = $q.defer();

      try{
        if(!idGroupe) throw new Error('Il manque l\'attribut "idGroupe" !');
        if(!idGroupeParent) throw new Error('Il manque l\'attribut "idGroupeParent" !');

        var param = {
          idGroupe: idGroupe,
          idGroupeParent: idGroupeParent
        };

        AdminCollabAjax.removeGroupeFromGroupe(param, function(json){
          if (UtilsService.ifResultatJSONSuccess(json)) deferred.resolve();
          else deferred.reject(UtilsService.getMessageFromResultatsJSON(json));
        },function(error){
          var err = checkErrorManageGroupe(error);
          if(err) deferred.reject(err);
          else {
            ErreurCollabService.erreurServlet(error,'Erreur avec AdminCollabService.removeGroupeFromGroupe()',deferred);
          }
        });
      }
      catch(err){
        console.log('[AdminCollabService.removeGroupeFromGroupe] Erreur : '+err.message);
        deferred.reject();
      }
      return deferred.promise;
    }

    function changeNomSociete(idGroupe, diese, groupesPortefeuille) {
      var deferred = $q.defer();

      try{
        if(!idGroupe) throw new Error('Il manque l\'attribut "idGroupe" !');
        if(!diese) throw new Error('Il manque l\'attribut "diese" !');

        var param = {
          action: 'changeNomSociete',
          idGroupe: idGroupe,
          diese: diese
        };

        if(!_.isNil(groupesPortefeuille)) {
          if(_.isArray(groupesPortefeuille) && groupesPortefeuille.length) param.groupesPortefeuille = angular.toJson(groupesPortefeuille);
          else if(_.isString(groupesPortefeuille)) param.groupesPortefeuille = groupesPortefeuille;
        }

        AdminCollabAjax.postGroupeSuperAdmin(param, function(json){
          console.log(json);
          deferred.resolve(MainService.convertJsonToModel(json, 'Groupes', 'Groupe', deferred));
        },function(error){
          console.log(error);
          var err = checkErrorManageGroupe(error);
          if(err) deferred.reject(err);
          else {
            ErreurCollabService.erreurServlet(error,'Erreur avec AdminCollabService.changeNomSociete()', deferred);
          }
        });
      }
      catch(error){
        console.log('[AdminCollabService.changeNomSociete] Erreur : '+error.message);
        deferred.reject();
      }
      return deferred.promise;
    }

    function changeNomPortefeuilles(groupesPortefeuille) {
      var deferred = $q.defer();

      try{
        var param = {
          action: 'changeNomPortefeuilles',
          groupesPortefeuille: null
        };

        if(!_.isNil(groupesPortefeuille)) {
          if(_.isArray(groupesPortefeuille) && groupesPortefeuille.length) param.groupesPortefeuille = angular.toJson(groupesPortefeuille);
          else if(_.isString(groupesPortefeuille)) param.groupesPortefeuille = groupesPortefeuille;
        }

        if(_.isNil(param.groupesPortefeuille)) throw new Error('Il manque les portefeuilles !');

        AdminCollabAjax.postGroupeSuperAdmin(param, function(json){
          console.log(json);
          deferred.resolve(MainService.convertJsonToModel(json, 'Groupes', 'Groupe', deferred));
        },function(error){
          console.log(error);
          var err = checkErrorManageGroupe(error);
          if(err) deferred.reject(err);
          else {
            ErreurCollabService.erreurServlet(error,'Erreur avec AdminCollabService.changeNomPortefeuille()', deferred);
          }
        });
      }
      catch(err){
        console.log('[AdminCollabService.changeNomPortefeuilles] Erreur : '+err.message);
        deferred.reject();
      }
      return deferred.promise;
    }

    function deleteSociete(idGroupe) {
      var deferred = $q.defer();

      try{
        if(!idGroupe) throw new Error('Il manque l\'attribut "idGroupe" !');

        var param = {
          action: 'deleteSociete',
          idGroupe: idGroupe
        };

        AdminCollabAjax.postGroupeSuperAdmin(param, function(json){
          if (UtilsService.ifResultatJSONSuccess(json)) deferred.resolve();
          else deferred.reject(json.Resultats[0].message);
        },function(error){
          ErreurCollabService.erreurServlet(error,'Erreur avec AdminCollabService.deleteSociete()', deferred);
        });
      }
      catch(err){
        console.log('[AdminCollabService.deleteSociete] Erreur : '+err.message);
        deferred.reject();
      }
      return deferred.promise;
    }
  }
})();
