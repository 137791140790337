(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.DocumentInformations
   * @description
   * # DocumentInformations
   * Service in the collaboreApp.
   */

  angular.module('collaboreApp').factory('DocumentInformations', DocumentInformationsModel);

  /** @ngInject */
  function DocumentInformationsModel(Main, UtilsService) {

    var DocumentInformations = function(objetData) {
      Main.call(this, objetData);
    };

    DocumentInformations.prototype = {

      isDocumentInformations: true,
      model: 'DocumentInformations',

      // guid
      getGuid: function(){
        return this.guid;
      },
      setGuid: function(nb){
        this.guid = nb;
      },

      // dateUpload
      getDateUpload: function (format) {
        if(!_.isNil(format)) {
          if (!_.isString(format) && format) format = null;
          return UtilsService.parseDate(this.dateUpload, format);
        }
        return this.dateUpload;
      },
      setDateUpload: function (date) {
        this.dateUpload = date;
      },
      getDateUploadAgo: function(){
        var retour = this.dateUpload;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },

      // Extension
      getExtension: function () {
        return this.extension;
      },
      setExtension: function (extension) {
        this.extension = extension;
      },
      // Poids
      getSize: function () {
        return this.size;
      },
      setSize: function (size) {
        this.size = size;
      },

      //Description
      getDescription: function () {
        return this.description;
      },
      setDescription: function (description) {
        this.description = description;
      },

    };

    angular.extend(DocumentInformations.prototype, Main.prototype);

    return DocumentInformations;
  }
})();
