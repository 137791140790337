(function () {
  'use strict';

  /**
   * @desc Collab fournisseur liste des dossiers
   * @example <collab-fournisseur-dossiers></collab-fournisseur-dossiers>
   */

  angular
    .module('collaboreApp')
    .component('collabFournisseurDossiers', {
      templateUrl: 'app/collab-fournisseur/collab-fournisseur-dossiers.component.html',
      controllerAs: 'collabfournisseurdossiersctrl',
      bindings: {
        login: '<',
        diese: '<',
        hash: '<'
      },
      controller: CollabFournisseurDossiersController
    });

  /*@ngInject*/
  function CollabFournisseurDossiersController($rootScope, COLLAB_CONF, COLLAB_VALUES, UtilsService, TopicService, TopicStatesService, RechercheService, FiltreService, ConfigService) {

    var _this = this;

    _this.disabled = false;
    _this.loadingComponent = false;
    _this.loadingTopic = false;

    _this.agences = {
      selected: null,
      loading: false,
      list: []
    };

    _this.topicStates = {
      nb: 0,
      nbPage: 0,
      limit: 10,
      page: 1,
      list: []
    };

    _this.optionsEtat = [
      {
        valeur: '0',
        libelle: "Non archivés seulement"
      },
      {
        valeur: '2',
        libelle: "Archivés seulement"
      },
      {
        valeur: '3',
        libelle: "Tous"
      }
    ];

    _this.searchIdTopic = null;
    _this.filtre = FiltreService.getBaseFiltre();

    _this.onCallNextTopic = onCallNextTopic;
    _this.onClickSearch = onClickSearch;
    _this.onClickSearchById = onClickSearchById;
    _this.onEmitFromAvancementComponent = onEmitFromAvancementComponent;

    _this.$onInit = function () {

      if(!_.isNil(_this.diese)){
        var diese = ConfigService.getUserDiese();
        if(!_.isNil(diese) && !_.isEmpty(diese)){
          var dieseFromUrl = angular.copy(_this.diese);
          if(!diese.startsWith("#")) diese = "#" + diese;
          if(!dieseFromUrl.startsWith("#")) dieseFromUrl = "#" + dieseFromUrl;
          if(diese != dieseFromUrl){
            _this.disabled = true;
          }
        }
      }

      if(!_this.disabled){
        _this.filtre.desync = false;    // Avec toute les infos sans websocket
        _this.filtre.etat = '0';
        _this.filtre.classe = 2;
        _this.filtre.cloturer = 0;
        _this.filtre.idChannel = 0;
        _this.filtre.topicAvecDemande = true;
        _this.filtre.lightJson = false;

        init();
      }
    };

    _this.$onChanges = function () {
    };

    _this.$onDestroy = function(){
    };

    function init(){
      callListeAgence();
      callTopicStates();
    }

    /**
     * Recherche les topicStates
     * @param idTopic
     */
    function callTopicStates(idTopic){

      _this.filtre.idPortefeuille = null;
      if(_.isObject(_this.agences.selected)){
        _this.filtre.idPortefeuille = _this.agences.selected.getIdPortefeuille();
      }

      if(_this.topicStates.page === 1) _this.loadingTopic = true;
      else _this.loadingAddTopic = true;

      if(_.isNil(idTopic)) {
        var searchParams = {
          page: _this.topicStates.page,
          limit: _this.topicStates.limit,
          filtre: _this.filtre
        };

        TopicStatesService
          .getListTopicStates(searchParams)
          .then(function (resultats) {
            initResult(resultats);
          })
          .finally(function () {
            _this.loadingTopic = false;
            _this.loadingAddTopic = false;
          });
      }
      else {
        RechercheService
          .searchCollabByIdTopic(idTopic)
          .then(function(resultats){
            initResult(resultats);
          })
          .finally(function () {
            _this.loadingTopic = false;
            _this.loadingAddTopic = false;
          });
      }
    }

    /**
     * Permet de parser les résultats de recherche
     * @param obj
     */
    function initResult(obj){
      //console.log(obj);


      if(_.isObject(obj)) {

        var listTopicState = [];
        if(_.isArray(obj)) {
          _this.topicStates.nb = obj.length;
          listTopicState = obj;

        }
        else {
          _this.topicStates.nb = obj.total;
          listTopicState = obj.listTopicStates;
        }

        if(listTopicState.length){
          for(var i = 0; i < listTopicState.length; i++){
            parseObjetFromEvenement(listTopicState[i].getTopic().getEvenement());
          }
        }
        if(_this.topicStates.page === 1) _this.topicStates.list = listTopicState;
        else _this.topicStates.list = _.concat(_this.topicStates.list, listTopicState);

        /*
        _this.topicStates.nbPage = Math.ceil(obj.total / _this.topicStates.limit);

        // Si différent de la page 1 et qu'il n'y à pas de resultat alors renvoi sur la dernière pages
        if (_this.topicStates.page !== 1 && !listTopicState.length) {

        }*/
      }

    }

    /**
     * Permet d'avoir la liste des agences
     */
    function callListeAgence(){
      _this.agences.loading = true;
      TopicService
        .searchListAgenceWorkWithMe(true)
        .then(function(listPortefeuille){
          _this.agences.list = listPortefeuille;
        })
        .finally(function(){
          _this.agences.loading = false;
        });
    }

    /**
     * Permet d'extraire des infos à partir de l'objet de l'événement
     * @param evenement
     */
    function parseObjetFromEvenement(evenement){
      if(_.isObject(evenement) && evenement.isEvenement){
        evenement.lieuIntervention = null;
        var objetSplit = evenement.splitObjet();
        if(!_.isNil(objetSplit.domFiches)){
          evenement.lieuIntervention = evenement.extractLieuInterventionFromFiche(objetSplit.domFiches);
        }
      }
    }

    function onCallNextTopic(){
      if(_this.topicStates.list.length < _this.topicStates.nb) {
        _this.topicStates.page = _this.topicStates.page+1;
        callTopicStates();
      }
    }

    /**
     * Lors du clique sur le bouton de recherche par agence
     */
    function onClickSearch(){
      _this.topicStates.page = 1;
      _this.searchIdTopic = null;
      callTopicStates();
    }

    /**
     * Lors du clique sur le bouton de rechercher par idTopic
     */
    function onClickSearchById(){
      _this.topicStates.page = 1;
      callTopicStates(_this.searchIdTopic);
    }

    /**
     * Lorsque le composant collab-fournisseur-btn-avancement remonte une information
     * @param obj
     */
    function onEmitFromAvancementComponent(obj, demande){
      //console.log(obj);
      /*
      try {
        if (!obj) throw new Error('Il manque l\'objet "obj" !');
        if (!_.isObject(demande)) throw new Error('Il manque le paramètre "demande"');

        if(obj.action === "toggleDisplayAddDocumentsDevis") openChooseDocumentDevis(demande)
        if(obj.action === "toggleDisplayAddDocumentFacture") openChooseDocumentFacture(demande)
        if(obj.action === "toggleDisplayAddDocumentContrat") openChooseDocumentContrat(demande)
      }
      catch (err) {
        console.log('[CollabFournisseurDossiersController.onEmitFromAvancementComponent] Erreur : ' + err.message);
      }*/
    }
  }
})();
