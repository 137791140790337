(function() {

    'use strict';

    angular
        .module('collaboreApp')
        .controller('ModalAdminSMTPCtrl',
            ModalAdminSMTPCtrl
        );

    /** @ngInject */
    function ModalAdminSMTPCtrl($uibModalInstance, groupeUser){
        var ctrl = this;
        ctrl.groupeUser = groupeUser;

        /**
         * Ferme le modal
         */
        ctrl.close = function () {
            $uibModalInstance.dismiss('cancel');
            // $uibModalInstance.close(obj);
        };

        //$uibModalInstance.rendered.then(function(){
        //});
    }
})();
