(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.DocumentOCR
   * @description
   * # DocumentOCR
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('DocumentOCR', DocumentOCRModel);

  /** @ngInject */
  function DocumentOCRModel(Main, ReceiptBody, Portefeuille, UtilsService) {

    function DocumentOCR(data) {
      Main.apply(this, arguments);
      this.hydrate(data);
    }

    DocumentOCR.prototype = {

      model: 'DocumentOCR',
      isDocumentOCR: true,

      // guid
      getGuid: function(){
        return this.guid;
      },
      setGuid: function(val){
        this.guid = val;
      },

      // code
      getCode: function(){
        return this.code;
      },
      setCode: function(val){
        this.code = val;
      },

      // erreur
      getErreur: function(){
        return this.erreur;
      },
      setErreur: function(val){
        this.erreur = val;
      },

      // dateOCR
      getDateOCR: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format) format = null;
          return UtilsService.parseDate(this.dateOCR, format);
        }
        return this.dateOCR;
      },
      getDateOCRAgo: function(){
        var retour = this.dateOCR;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },
      setDateOCR: function(date){
        this.dateOCR = date;
      },

      // json
      getJson: function(){
        return this.json;
      },
      setJson: function(val){
        this.json = val;
      },

      // groupeCreateur
      getGroupeCreateur: function(){
        return this.groupeCreateur;
      },
      setGroupeCreateur: function(createur){
        if(_.isObject(createur) && !createur.isModel){
          var Groupe = Main.$injector.get('Groupe');
          createur = new Groupe(createur);
          Groupe = null;
        }
        this.groupeCreateur = createur;
      },

      // receiptBody
      getReceiptBody: function(){
        return this.receiptBody;
      },
      setReceiptBody: function(val){
        if(_.isObject(val) && _.isNil(val.isModel)) val = new ReceiptBody(val);
        this.receiptBody = val;
      },

      isError: function(){
        return (!_.isNil(this.getCode()) || _.isNil(this.getReceiptBody()) || (_.isObject(this.getReceiptBody() && this.getReceiptBody().isError())));
      },
      getErreurMessage: function(){
        var message = "Il y a eu un problème lors de l'analyse du document";
        if(!_.isNil(this.getErreur())) message += " : " + this.getErreur();
        if(!_.isNil(this.getCode()) && this.getCode() != 0) message += " (" + this.getCode() + ")";
        return message;
      },

      hasCustomerDatas: function(){
        if(_.isObject(this.getReceiptBody()))
          return this.getReceiptBody().hasCustomerDatas();

        return false;
      },

      hasMerchantDatas: function(){
        if(_.isObject(this.getReceiptBody()))
          return this.getReceiptBody().hasMerchantDatas();
      },

      // dateFacture
      getDateFacture: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format) format = null;
          return UtilsService.parseDate(this.dateFacture, format);
        }
        return this.dateFacture;
      },
      getDateFactureAgo: function(){
        var retour = this.dateFacture;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },
      setDateFacture: function(date){
        this.dateFacture = date;
      },

      // numeroFacture
      getNumeroFacture: function(){
        return this.numeroFacture;
      },
      setNumeroFacture: function(val){
        this.numeroFacture = val;
      },

      // montantHT
      getMontantHT: function () {
        return this.montantHT;
      },
      setMontantHT: function (string) {
        if (_.isString(string) && !_.isEmpty(string)) string = string.replace(',', '.');
        this.montantHT = string;
      },

      // montantTTC
      getMontantTTC: function () {
        return this.montantTTC;
      },
      setMontantTTC: function (string) {
        if (_.isString(string) && !_.isEmpty(string)) string = string.replace(',', '.');
        this.montantTTC = string;
      },

      // montantTVA
      getMontantTVA: function () {
        return this.montantTVA;
      },
      setMontantTVA: function (string) {
        if (_.isString(string) && !_.isEmpty(string)) string = string.replace(',', '.');
        this.montantTVA = string;
      },

      // nomFournisseur
      getNomFournisseur: function(){
        return this.nomFournisseur;
      },
      setNomFournisseur: function(val){
        this.nomFournisseur = val;
      },

      // phoneFournisseur
      getPhoneFournisseur: function(){
        return this.phoneFournisseur;
      },
      setPhoneFournisseur: function(val){
        this.phoneFournisseur = val;
      },

      // emailFournisseur
      getEmailFournisseur: function(){
        return this.emailFournisseur;
      },
      setEmailFournisseur: function(val){
        this.emailFournisseur = val;
      },

      // compteFournisseur
      getCompteFournisseur: function(){
        return this.compteFournisseur;
      },
      setCompteFournisseur: function(val){
        this.compteFournisseur = val;
      },

      // cocNumber
      getCocNumber: function(){
        return this.cocNumber;
      },
      setCocNumber: function(val){
        this.cocNumber = val;
      },

      // vatNumber
      getVatNumber: function(){
        return this.vatNumber;
      },
      setVatNumber: function(val){
        this.vatNumber = val;
      },

      // idComfact
      getIdComfact: function(){
        return this.idComfact;
      },
      setIdComfact: function(val){
        this.idComfact = val;
      },

      // Portefeuille
      getPortefeuille: function(){
        return this.portefeuille;
      },
      setPortefeuille: function(portefeuille){
        // Si val est un objet mais qu'il n'est pas un model
        if(_.isObject(portefeuille) && !portefeuille.isModel) {
          //var Portefeuille = Main.$injector.get('Portefeuille');
          portefeuille = new Portefeuille(portefeuille);
          //Portefeuille = null;

        }
        this.portefeuille = portefeuille;
      },
    };

    angular.extend(DocumentOCR.prototype, Main.prototype);

    return DocumentOCR;
  }
})();
