(function () {

    'use strict';

    /**
     * @desc Composant groupe ligne
     * @example <admin-collab-groupe></admin-collab-groupe>
     */
    angular
        .module('collaboreApp')
        .component('adminCollabGroupe', {
            bindings: {
                onProgress: '&',    // Permet de remonté l'info d'un loading par exemple
                groupe: '<',        // Groupe
                isModal: '<'        // Permet de savoir si composant ouver par un modal
            },
            templateUrl: 'app/admin/groupe/admin-collab-groupe.component.html',
            controllerAs: 'admincollabgroupectrl',
            controller: AdminCollabGroupeCtrl
        });


    /** @ngInject */
    function AdminCollabGroupeCtrl(AdminCollabService, ErreurCollabService, COLLAB_VALUES) {
        var ctrl = this;
        ctrl.assignTopic = COLLAB_VALUES.ASSIGN_TOPIC_DEFAULT;
        ctrl.assignTopicCloture = COLLAB_VALUES.ASSIGN_TOPIC_CLOTURE_DEFAULT;
        ctrl.groupeUser = false;
        ctrl.progress = false;
        ctrl.listGroupes = [];
        ctrl.onProgress = onProgress;
        ctrl.onChangeAssignTopic = onChangeAssignTopic;

        ctrl.$onInit = function () {
            init();
        };

        function init(){
            if(ctrl.groupe) {
                ctrl.groupeUser = ctrl.groupe;
                ctrl.loading = true;
                AdminCollabService
                    .getHierarchieGroupesNonUserForSociete(ctrl.groupe)
                    .then(function (groupes) {
                        ctrl.listGroupes = groupes;
                    })
                    .finally(function () {
                        ctrl.loading = false;
                    });
            }
            else ErreurCollabService.logErreur('Pas de "groupe"');
        }

        function onProgress(progress) {
            ctrl.progress = progress;
        }

        function onChangeAssignTopic(){
          if(_.isNil(ctrl.assignTopic) || !ctrl.assignTopic){
            ctrl.assignTopicCloture = false;
          }
        }
    }
})();
