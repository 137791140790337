(function () {
  'use strict';

  /**
   * @desc Composant pour choisir une signature d'un contenu
   * @example <select-signature-contenu></select-signature-contenu>
   */

  angular
    .module('collaboreApp')
    .component('selectSignatureContenu', {
      templateUrl: 'app/topics/components/select-signature-contenu/select-signature-contenu.component.html',
      bindings: {
        showLibelle: '<',
        libelle: '<',
        signature: '<',
        onEmit: '&'
      },
      controllerAs: 'selectsignaturecontenuctrl',
      controller: SelectSignatureContenuController
    });

  /*@ngInject*/
  function SelectSignatureContenuController(ContenuService, UtilsService) {

    var ctrl = this;
    ctrl.parseStringHTML = UtilsService.parseStringHTML;
    ctrl.onChangeSignature = onChangeSignature; // Lors du changement de signature

    ctrl.$onInit = function () {
      if(angular.isUndefined(ctrl.showLibelle)) ctrl.showLibelle = true;
      if(angular.isUndefined(ctrl.libelle)) ctrl.libelle = 'Signature';
      initSignatures();
    };

    /**
     * Initialisation des signatures
     */
    function initSignatures(){
      var signatures = ContenuService.getSignatures(ctrl.signature);
      ctrl.signaturePerso = signatures.signaturePerso;
      ctrl.signatureSociete = signatures.signatureSociete;
      ctrl.signatures = signatures.signatures;
      ctrl.signatureSelected = signatures.signatureSelected;
      emitSignature();
    }

    /**
     * Lors du changement de signature
     */
    function onChangeSignature(){
      emitSignature();
    }

    function emitSignature(){
      ctrl.onEmit(
        {
          obj: {
            action: 'changeSignature',
            signature: ctrl.signatureSelected
          }
        }
      );
    }
  }
})();
