(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.ElasticSearchService
   * @description
   * # ElasticSearchService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('ElasticSearchService',
    ElasticSearchServiceController
    );

  /** @ngInject */
  function ElasticSearchServiceController(TopicStates, ElasticSearchResource, $q, ModalsService) {
    var ctrl = this;
    ctrl.searchTopic = searchTopic;

    function searchTopic(search, page, limit){
      var deferred = $q.defer();
      try {
        if(_.isNil(page)) page = 1;
        if(_.isNil(limit)) limit = 10;
        var params = {
          search: search,
          page: page,
          limit: limit
        };
        var retourTopicsStates = {
          listTopicState: [],
          nbTotal: 0
        };
        ElasticSearchResource
          .searchTopic(params)
          .$promise
          .then(function onSuccess(json){

            if(json.success && _.isObject(json.result) && _.isObject(json.result.topic) && _.isArray(json.result.topic.listTopicState)){
              retourTopicsStates.listTopicState = json.result.topic.listTopicState.map(function(t){
                return new TopicStates(t);
              });

              retourTopicsStates.nbTotal = json.result.topic.nbTotal;

              deferred.resolve(retourTopicsStates);
            }
            else{
              deferred.reject((!_.isNil(json.message)) ? json.message : 'Erreur lors de la recherche');
            }
          })
          .catch(function onError(err) {
            deferred.reject(err);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

  }

})();
