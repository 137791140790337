(function () {

  'use strict';

  /**
   * @desc Detail d'un PDF
   * @example <detail-pdf></detail-pdf>
   */

  angular
    .module('collaboreApp')
    .component('detailPdf', {
      /*
      require: {
          listDemandeDevisInterCtrl: '?^listDemandeDevisInter'
      },*/
      bindings: {
        allowEditEvenement: '<',// <Boolean> Permet de savoir si l'événement est modifiable
        demande: '<',	        // <ContenuEvenementDevis> Liste des devis/inter
        document: '<',	        // <Document> document à ouvrir
        fromContenu: '<',       // document venant du contenu
        infosEvenement: '<',    // <Object> Infos de l'événement
        onEmit: '&'             // <Function> Utilisé pour passer des informations au parents
      },
      templateUrl: 'app/topics/fournisseurs/detail-pdf/detail-pdf.component.html',
      controllerAs: 'detailpdfctrl',
      controller: DetailPdfCtrl
    });

  /*@ngInject*/
  function DetailPdfCtrl(COLLAB_CONF, $q, $scope, $rootScope, $sce, $timeout, sweet, bowser, UtilsService, ModalsService, DocumentManagerService, ContenuEvenementDevis, SignatureService, ContratService, ContenuService, TopicManagerService) {
    var pdfjsLib = window['pdfjs-dist/build/pdf'];
    var _this = this;
    _this.signature = null;
    _this.pdfUrl = null;    // Url du PDF à afficher
    _this.erreurDocument = null;
    _this.signeAndAcceptDevis = null;        // Permet de savoir si il faut accepter la demande en la signant
    _this.forceSignature = null;
    _this.documentContratAgenceSigne = null;

    _this.listTypeDocAllowDisplayNomFichier = [
      COLLAB_CONF.TYPE_DOC_ENUMS.DEVIS,
      COLLAB_CONF.TYPE_DOC_ENUMS.DEVIS_SIGNE,
      COLLAB_CONF.TYPE_DOC_ENUMS.FACTURE,
      COLLAB_CONF.TYPE_DOC_ENUMS.DOC_CONTRAT_FOURNISSEUR_SIGNE,
      COLLAB_CONF.TYPE_DOC_ENUMS.DOC_CONTRAT_AGENCE_SIGNE,
      null
    ];
    _this.displayNomFichier = false;

    _this.displayToolBarAcceptationRefus = displayToolBarAcceptationRefus;
    _this.displayViewerIframe = displayViewerIframe;
    _this.displayCanvasSignature = displayCanvasSignature;
    //_this.getTitreRecap = getTitreRecap;                        // Permet de récupérer le titre du recap par rapport à l'état et au type de demande
    //_this.onClickCloseDetailPdf = onClickCloseDetailPdf;        // Lors du clique sur le bouton pour close le detailPDF
    _this.onClickAccepter = onClickAccepter;          // Lors du clique sur le bouton pour refuser le devis
    _this.onClickRefuser = onClickRefuser;            // Lors du clique sur le bouton pour refuser le devis
    _this.onClickValiderSignature = onClickValiderSignature;    // Lors du clique sur le bouton pour valider la signature
    _this.onClickResigner = onClickResigner;    // Lors du clique sur le bouton pour signer le devis à nouveau
    _this.onSelectTampon = onSelectTampon;                      // Lorsqu'un tampon est choisi dans le composant des tampons

    //_this.onEmitFromDemandeDevisInter = onEmitFromDemandeDevisInter;    // Lorsque le composant demande-fournisseur remonte une information
    //_this.onEmitFromMontantContenuComponent = onEmitFromMontantContenuComponent;    // Lorsque le composant montant-contenu remonte une information

    _this.$onInit = function () {

      $rootScope.$broadcast('minimizeLeftSidebar');


      if(_.isObject(_this.infosEvenement)) {

        if(!_.isNil(_this.infosEvenement.numImmeuble)) {
          _this.eventHasImmeuble = true;
        }

        if(_.isObject(_this.infosEvenement.portefeuille)) {
          UtilsService
            .getPortefeuille(_this.infosEvenement.portefeuille.idPortefeuille)
            .then(function onSuccess(port){
              _this.portefeuille = port;
            });
        }
      }

      initIdContenuForDocument();
      init();
    };

    _this.$onChanges = function (changes) {
      if(_.isObject(changes)) {

        if(_.isObject(changes.fromContenu) && !changes.fromContenu.isFirstChange())   _this.fromContenu = changes.fromContenu.currentValue;
        if(_.isObject(changes.demande) && !changes.demande.isFirstChange()) {

          _this.demande = changes.demande.currentValue;

          // Pour gérer un bug de selection de document. Car appelé deux fois mais je ne sais pas d'où...
          if(_.isObject(changes.demande.currentValue) && _.isNil(changes.demande.currentValue.selected) &&
            _.isObject(changes.demande.previousValue) && _.isObject(changes.demande.previousValue.selected)){
            _this.demande.selected = angular.copy(changes.demande.previousValue.selected);
          }
        }
        initIdContenuForDocument();

        if((_.isObject(changes.demande) && !changes.demande.isFirstChange()) || (_.isObject(changes.document) && !changes.document.isFirstChange())) {
          if(_.isObject(changes.document) && !changes.document.isFirstChange()) _this.document = changes.document.currentValue;
          init();
        }
      }
    };

    _this.$onDestroy = function () {
      _this.SignatureWYSIWYG.destroy();
    };

    /**
     * Permet de set l'objet etat de la demande avec le type et le libelle dedans
     */
    function init(){



      _this.topicIsBlocked = TopicManagerService.isBlocked;
      _this.forceSignature = false;
      _this.signeAndAcceptDevis = false;
      if(_this.SignatureWYSIWYG.isActive) {
        _this.SignatureWYSIWYG.destroy();
      }
      if(_.isObject(_this.demande)) {

        _this.etat = _this.demande.getEtatDemande();

        // Si demande en atttente de validation
        if(_this.demande.isWaiting()) {
          _this.demande.sendMailWhenAcceptOrDecline = true;
          if(_this.demande.isContenuDevisInter()) {
            var noSendMailWhenAcceptOrDeclineDevis = UtilsService.getParametrage('noSendMailWhenAcceptOrDeclineDevis');
            if (!_.isNil(noSendMailWhenAcceptOrDeclineDevis)) {
              _this.demande.sendMailWhenAcceptOrDecline = !noSendMailWhenAcceptOrDeclineDevis;
            }

            var validDevisWhenSignIt = UtilsService.getParametrage('validDevisWhenSignIt');
            // Si on veux valider le devis en le signant
            if(!_.isNil(validDevisWhenSignIt)) {
              _this.signeAndAcceptDevis = validDevisWhenSignIt;
            }
          }
          else if(_this.demande.isContenuContrat()){
            var noSendMailWhenAcceptOrDeclineContrat = UtilsService.getParametrage('noSendMailWhenAcceptOrDeclineContrat');
            if (noSendMailWhenAcceptOrDeclineContrat !== null) {
              _this.demande.sendMailWhenAcceptOrDecline = !noSendMailWhenAcceptOrDeclineContrat;
            }
          }
          if(_this.topicIsBlocked) _this.demande.sendMailWhenAcceptOrDecline = false;
        }

        // Si la demandes est accépté mais pas signé
        if((_this.demande.isAccepte() || _this.signeAndAcceptDevis) &&
          (
            (_this.demande.isContenuDevis() && !_this.demande.isSigne() && _this.document.isDocumentDevis()) ||
            (_this.demande.isContenuContrat() && _this.document.isAccepted() && (_.isNil(_this.documentContratAgenceSigne) || !_this.documentContratAgenceSigne.isSigned()) && _this.document.isContratFournisseur())
          )
        ) {

          _this.sendMailWhenSigne = true;

          var noSendMailWhenSigne = null;
          // Si la demande est un devis/inter le document chargé est un devis
          if(_this.demande.isContenuDevis() && _this.document.isDocumentDevis()) {
            noSendMailWhenSigne = UtilsService.getParametrage('noSendMailWhenSigneDevis');
          }
          // Si la demande est un contrat le document chargé est un contrat signé par le fournisseur
          else if(_this.demande.isContenuContrat() && _this.document.isContratFournisseur()) {
            noSendMailWhenSigne = UtilsService.getParametrage('noSendMailWhenSigneContrat');
          }

          if (noSendMailWhenSigne !== null) {
            _this.sendMailWhenSigne = !noSendMailWhenSigne;
          }
          if(_this.topicIsBlocked) _this.sendMailWhenSigne = false;
          enableSignature();
        }
        else initDocument();
      }
      else {
        initDocument();
        _this.etat = null;
        ModalsService.alertErreur('Pas de demande !');
      }
    }

    function initDocument(){
      _this.forceSignature = false;
      _this.signeAndAcceptDevis = false;
      _this.documentContratAgenceSigne = null;
      if(!_.isNil(_this.document)) {
        setUrlPDF(_this.document);

        // Si il y a une demande est que c'est une demande de contrat
        if(!_.isNil(_this.demande) && _this.demande.isContenuContrat()) {
          // Recupère le contrat signé par l'agence par rapport au contrat signé par le fournisseur
          _this.documentContratAgenceSigne = _this.demande.getDocumentFromListContratAgenceSigneForGuidDocumentSource(_this.document.getGuid())
        }
      }
    }

    function initIdContenuForDocument(){
      _this.idContenu = (_.isObject(_this.fromContenu)) ? _this.fromContenu.idContenu : (_.isObject(_this.demande)) ? _this.demande.idContenu : null;
    }

    /**
     * Active le module de signature
     */
    function enableSignature(){
      _this.tampon = {};

      _this.infosSignature = {
        dateSignature: new Date(),
        dateSignatureChecked: false,

        textBonPourAccord: 'Bon pour Accord',
        bonPourAccordChecked: false,

        parapheChecked: false,

        textSigneOnFirstPage: 'Signer la 1er page',
        signeOnFirstPageChecked: false,

        textSigneOnLastPageChecked: 'Signer la dernière page',
        signeOnLastPageChecked: false
      };
      //_this.modeSignature = 'viewer';
      _this.SignatureWYSIWYG.init();
      UtilsService.scrollToTop();
    }

    /**
     * Permet de récupérer l'url du PDF pour l'afficher dans le viewer
     * @param document
     */
    function setUrlPDF(document){
      if(allowGetUrlDocumentForIFrame()) {
        _this.pdfUrl = null;
        _this.erreurDocument = null;

        _this.displayNomFichier = false;
        // Si le type du document est trouvé dans la liste des type
        if(_.indexOf(_this.listTypeDocAllowDisplayNomFichier, document.getTypeDocument()) !== -1) {
          _this.displayNomFichier = true;
        }


        if (bowser.msie) {
          var urlDevisPdf = DocumentManagerService.getUrlStream(document.getGuid(), _this.idContenu);
          _this.pdfUrl = $sce.trustAsResourceUrl(encodeURIComponent(urlDevisPdf));
        } else {
          // Recupère le PDF
          _this.loadingDocument = true;
          DocumentManagerService
            .getStream(document.getGuid(), _this.idContenu)
            .then(function (url) {
              _this.pdfUrl = encodeURIComponent(url);
              UtilsService.scrollToTop();
            })
            .catch(function(err){
              if(_.isString(err)) {
                _this.erreurDocument = err;
              }
              else {
                console.log(err);
              }
            })
            .finally(function(){
              _this.loadingDocument = false;
            });
        }
      }
    }

    /**
     * Permet de récupérer le titre du recap
     * @returns {*}
     */
    /*
    function getTitreRecap(){
        var libelle = null;

        if(_.isObject(_this.etat)) {
            switch(_this.etat.TYPE){
                case ContenuEvenementDevis.TYPE_ETATS.ATTENTE_VALIDATION_DEVIS:
                    libelle = 'Devis en attente de validation';
                    break;
                case ContenuEvenementDevis.TYPE_ETATS.ATTENTE_SIGNATURE_DEVIS:
                    libelle = 'Devis en attente de signature';
                    break;
                case ContenuEvenementDevis.TYPE_ETATS.EN_COURS:
                    libelle = 'Intervention en attente';
                    break;
                case ContenuEvenementDevis.TYPE_ETATS.FACTURE_RECU_NON_TRAITE:
                    libelle = 'Facture reçu en attente de traitement';
                    break;
                case ContenuEvenementDevis.TYPE_ETATS.CLOTURE:
                    libelle = 'Demande cloturé';
                    break;
                default:
                    libelle = 'Demande';
                    if(_this.demande.isDemandeIntervention()) {
                        libelle += 'd\'intervention ';
                    }
                    else {
                        libelle += 'de devis ';
                    }
            }
        }

        libelle += ': ' + _this.demande.getNomFournisseur();

        return libelle;
    }*/

    /**
     * Lors du clique sur le bouton valider le devis
     */
    function onClickAccepter(){

      var params = {
        type: 'accepter',
        contenu: _this.demande,
        document: _this.document
      };

      ModalsService
        .modalComponent('acceptRefusDemande', params, {size: 'md', backdrop: 'static'})
        .then(function onSuccess(obj){
          if(_.isObject(obj)) {
            if(obj.action === 'accepteDemande' && _.isObject(obj.contenu)) {
              _this.demande = obj.contenu;
              emit({action: 'accepteDemande', document: obj.document});
            }
          }
        });
      /*
      var title = 'Accepter le Devis';
      var desc = 'Vous acceptez ce devis ?';
      if(_this.demande.isContenuContrat()) {
          title = 'Accepter le document';
          desc = 'Vous acceptez ce contrat ?';
      }
      sweet.show({
          title: title,
          text: desc,
          type: 'info',
          showCancelButton: true,
          confirmButtonText: 'Oui !',
          closeOnConfirm: false,
          showLoaderOnConfirm: true
      }, function () {
          if(_this.demande.isContenuDevis()) {
              _this.demande
                  .accepterDevisBySyndic(_this.demande.sendMailWhenAcceptOrDecline)
                  .then(function () {
                      emit({action: 'accepteDemande'});
                  })
                  .finally(function () {
                      sweet.close();
                  });
          }
          else if(_this.demande.isContenuContrat()) {
              _this.demande
                  .accepterContratBySyndic(_this.demande.sendMailWhenAcceptOrDecline, _this.document)
                  .then(function () {
                      emit({action: 'accepteDemande'});
                  })
                  .finally(function () {
                      sweet.close();
                  });
          }

      });*/
    }

    /**
     * Lors du clique sur le bouton pour refuser le devis
     */
    function onClickRefuser() {
      var params = {
        type: 'refuser',
        contenu: _this.demande,
        document: _this.document
      };

      ModalsService
        .modalComponent('acceptRefusDemande', params, {size: 'md', backdrop: 'static'})
        .then(function onSuccess(obj){
          if(_.isObject(obj)) {
            if(obj.action === 'refuseDemande' && _.isObject(obj.contenu)) {
              _this.demande = obj.contenu;

              emit({action: 'refuseDemande', document: obj.document});
            }
          }
        });
      /*
      var title = 'Refuser le Devis';
      var desc = 'Vous refusez ce devis ?';
      if(_this.demande.isContenuContrat()) {
          title = 'Refuser le Contrat';
          desc = 'Vous refusez ce contrat ?';      }
      sweet.show({
          title: title,
          text: desc,
          type: 'info',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          closeOnConfirm: false,
          showLoaderOnConfirm: true
      }, function () {
          _this.demande
              .refuserDevisBySyndic(_this.demande.sendMailWhenAcceptOrDecline)
              .then(function(){
                  emit({action: 'refuseDemande'});
              })
              .finally(function () {
                  sweet.close();
              });
      });*/
    }

    /**
     * Lors du clique sur le bouton pour valider la signature
     */
    function onClickValiderSignature(){
      // Si le paramètre de validation en meme temps que la signature n'est pas activé ou si la case activer la signature est cochée
      // Alors signature du devis
      if(!_this.signeAndAcceptDevis || _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.active) {
        var tampon = _this.tampon.selected;

        var libelleTypeContenu = 'devis';
        if(_this.demande.isContenuContrat()) {libelleTypeContenu = 'contrat';}

        var titrePopup = 'Signature du ' + libelleTypeContenu;
        var textPopup = 'Signer et tamponner le ' + libelleTypeContenu + ' ?<br /><br />';

        // Si pas de tampon
        if (!_.isObject(tampon)) {
          textPopup = '<strong class="text-danger">Pas de tampon sélectionné</strong>, voulez-vous quand même signer le ' + libelleTypeContenu + ' ?<br /><br />';
        }


        // Si accepte le devis directement en le signant
        if(_this.signeAndAcceptDevis) {

          titrePopup = 'ACCEPTER et SIGNER le devis';

          /*
          var checkedSendEmailWhenAcceptOrDecline = ' checked="false"';
          if(_this.demande.sendMailWhenAcceptOrDecline) {
              checkedSendEmailWhenAcceptOrDecline = ' checked="true"';
          }

          var textLabelCheckboxAcceptDecline = 'Envoyer un email lors de l\'acceptation ou du refus';
          if(_this.demande.isContenuContrat()){
              textLabelCheckboxAcceptDecline = 'Envoyer un email lors du refus'
          }
          textPopup += '<div class="checkbox checkbox-success">\n' +
              '<input type="checkbox" id="checkboxDocSendByEmailWhenAcceptOrDecline"'+checkedSendEmailWhenAcceptOrDecline+'>\n' +
              '<label for="checkboxDocSendByEmailWhenAcceptOrDecline" style="text-transform: none;">'+textLabelCheckboxAcceptDecline+'</label>\n' +
              '</div>';*/
        }

        var checkedSendEmailWhenSigne = ' checked="false"';
        if(_this.sendMailWhenSigne) {
          checkedSendEmailWhenSigne = ' checked="true"';
        }
        if(!_this.topicIsBlocked) textPopup += '<div class="checkbox checkbox-success">\n' +
          '<input type="checkbox" id="checkboxDocSendByEmailWhenSigne"'+checkedSendEmailWhenSigne+'>\n' +
          '<label for="checkboxDocSendByEmailWhenSigne" style="text-transform: none;">Envoyer un email lors de la signature du ' + libelleTypeContenu + '</label>\n' +
          '</div>';


        sweet.show({
          title: titrePopup,
          text: textPopup,
          type: 'info',
          html: true,
          showCancelButton: true,
          confirmButtonText: 'Oui !',
          closeOnConfirm: false,
          showLoaderOnConfirm: true
        }, function () {
          _this.sendMailWhenSigne = angular.element('#checkboxDocSendByEmailWhenSigne').is(":checked");
          if(_this.topicIsBlocked) _this.sendMailWhenSigne = false;
          /*
          if(_this.signeAndAcceptDevis) {
              _this.demande.sendMailWhenAcceptOrDecline = angular.element('#checkboxDocSendByEmailWhenSigne').is(":checked");
          }*/
          signeDocument();
          sweet.close();
          /*
          if (_this.modeSignature === 'edit') {
              saveSignature()
                  .then(function () {
                      signeDocument();
                      sweet.close();
                  })
                  .catch(function (msg) {
                      sweet.show({
                          title: 'Erreur',
                          text: msg,
                          type: 'error',
                          html: true,
                          showCancelButton: false,
                          confirmButtonText: 'Fermer',
                          closeOnConfirm: true
                      });
                  });
          } else {
              signeDocument();
              sweet.close();
          }*/
        });
      }
        // Si le paramètre de validation en meme temps que la signature est activé et que la case est décochée
      // Refus du devis
      else if(_this.signeAndAcceptDevis && !_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.active){
        onClickRefuser();
      }
    }

    /**
     * Lors du clique sur le bouton pour signer le devis à nouveau
     */
    function onClickResigner(){
      _this.forceSignature = true;
      enableSignature();
    }


    /**
     * Permet d'afficher ou non la toolbar pour accepter refuser une demande/document
     * @returns {boolean}
     */
    function displayToolBarAcceptationRefus(){
      if(_.isObject(_this.demande) && _this.allowEditEvenement && allowGetUrlDocumentForIFrame()) {
        // Si demande de devis avec le document devis selectionné actuellement
        if (_this.demande.isContenuDevis() && _this.document.isDocumentDevis() && _this.demande.isWaiting() && !_this.signeAndAcceptDevis) {
          return true
        }
        // Si demande de contrat avec un contrat signé par le fournisseur selectionné
        if (_this.demande.isContenuContrat() && _this.document.isContratFournisseur() && _this.demande.isWaiting() && _this.document.isWaiting()) {
          return true
        }
      }
      return false
    }

    /**
     * Permet de savoir si on doit récupérer l'url du document pour l'afficher dans l'IFrame
     * @returns {boolean}
     */
    function allowGetUrlDocumentForIFrame(){
      // ?
      if(!_.isObject(_this.demande)) return true;

      if(!_this.forceSignature){

        if (_this.demande.isContenuIntervention()) return true;
        else if (_this.demande.isContenuDevis()) {

          // Si pas un devis ou qu'il est signé on affiche l'iframe
          if(!_this.document.isDocumentDevis() || _this.demande.isSigne()) return true;

          // Si l'acceptation ne se fait pas automatiquement en signant Et que la demande n'est pas accéptée
          if(!_this.signeAndAcceptDevis && !_this.demande.isAccepte()) return true;

          /*
          // Si l'acceptation se fait en signant alors on affiche pas l'iframe dans le cas où c'est un devis pas signé
          if(_this.signeAndAcceptDevis){
            // Si le document n'est pas un devis OU que c'est un devis mais qu'il est déjà signé
            if(!_this.document.isDocumentDevis() || _this.demande.isSigne()) return true;

          }
          // Si l'acceptation ne se fait pas automatiquement en signant
          else{
            // Si la demande n'est pas accepté OU qu'elle est signée
            if(!_this.demande.isAccepte() || _this.demande.isSigne())  return true;
          }*/
        }
        else if(_this.demande.isContenuContrat()) {

          // Si le document est signé ou refusé
          if((!_.isNil(_this.documentContratAgenceSigne) && _this.documentContratAgenceSigne.isSigned()) || !_this.document.isAccepted()) return true;
        }
      }
      return false;

      /*
      if(!_this.forceSignature) {

        // si demande de devis pas accepté
        if(!_this.demande.isAccepte()) return true;

        // Si demande de devis déjà signé
        if(_this.demande.isSigne()) return true;

        if (_this.demande.isContenuIntervention()) return true;
        else if (_this.demande.isContenuDevis()) {

          // Si le document n'est pas un devis
          if(!_this.document.isDocumentDevis()) return true;
        }
        else if(_this.demande.isContenuContrat()) {

          // Si le document est signé ou refusé
          if((!_.isNil(_this.documentContratAgenceSigne) && _this.documentContratAgenceSigne.isSigned()) || !_this.document.isAccepted()) return true;
        }
      }*/
      return false;
    }

    /**
     * Permet de savoir si le viewer doit être affiché
     * @returns {boolean|*}
     */
    function displayViewerIframe(){
      return (!_this.SignatureWYSIWYG.isActive && !_this.loadingDocument && !_.isNil(_this.pdfUrl) && !_.isNil(_this.document));
      //return (((_this.demande.isAccepte() && _this.demande.isSigne() && _this.document.getTypeDocument() === 'devis') || !_this.demande.isAccepte()) && !_this.loadingDocument && _this.pdfUrl)
    }

    /**
     * Permet de savoir si le Canvas doit petre affiché
     * @returns {boolean}
     */
    function displayCanvasSignature(){
      // Si signature activé, pas de loading, devis accepté et que le document est le devis et qu'il est signé ou qu'on force la resignature
      return (_this.SignatureWYSIWYG.isActive && !_this.loadingDocument && _this.SignatureWYSIWYG.vars.objectForSignature.pdfDoc && !_.isNil(_this.document) && (_this.document.isDocumentDevis() || _this.document.isContratFournisseur()))
    }

    /**
     * Signe et tamponne un devis
     * @param tampon
     * @param contenu
     */
    function signeDocument() {

      _this.loading = true;

      var textAccord, date;
      if (_this.infosSignature.bonPourAccordChecked) {
        textAccord = _this.infosSignature.textBonPourAccord;
      }
      if (_this.infosSignature.dateSignatureChecked) {
        date = moment(_this.infosSignature.dateSignature).format('DD/MM/YYYY');
      }

      var pages = [];
      var coordonnnees = [];
      if (_.isObject(_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne)) {
        _.forEach(_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne, function (coordonnees, numPage) {
          if (!_.isNil(coordonnees.xReel) && !_.isNil(coordonnees.yReel)) {
            pages.push(numPage);
            coordonnnees.push(coordonnees.xReel + ',' + coordonnees.yReel);
          }
        });
      }

      var objParams = {
        contenu: _this.demande,
        tampon: _this.tampon.selected,
        bpa: textAccord,
        date: date,
        page: pages,
        coordonnees: coordonnnees,
        sendMail: (_this.topicIsBlocked) ? false :_this.sendMailWhenSigne
      };

      // Si on accepte le devis en le signant
      if(_this.signeAndAcceptDevis && _this.demande.sendMailWhenAcceptOrDecline) {
        objParams.sendMailWhenAcceptOrDeclineDevis = _this.demande.sendMailWhenAcceptOrDecline;
        if(_this.topicIsBlocked) objParams.sendMailWhenAcceptOrDeclineDevis = false;
      }

      if(_this.demande.isContenuDevis()) {
        DocumentManagerService
          .signeDevis(objParams)
          .then(function (document) {

            _this.demande.setDocumentDevisSigne(document);
            _this.document = _this.demande.getDocumentDevisSigne();
            setUrlPDF(_this.document);

            ContenuService
              .getContenu(_this.demande.idContenu)
              .then(function(contenu){
                _this.demande = contenu;
              })
              .finally(function(){
                emit({action: 'signeDevis', document: _this.document});
              });
          })
          .finally(function () {
            _this.loading = false;
          });
      }
      else if(_this.demande.isContenuContrat()){

        /*
        // Si il y a seulement une signature sur une page, ajoute les coordonnées de la signature du PDF sur toutes les pages
        if(pages.length === 1 && coordonnnees.length === 1){
            var coords = coordonnnees[0];
            pages = [];
            coordonnnees = [];
            for(var p = 1; p <= _this.SignatureWYSIWYG.vars.objectForSignature.pdfDoc.numPages; p++){
                pages.push(p);
                coordonnnees.push(coords);
            }
            objParams.page = pages;
            objParams.coordonnees = coordonnnees;
        }*/

        var guidDocFournisseurSigne = _this.document.getGuid();
        objParams.guidDocumentContratFournisseurSigne = guidDocFournisseurSigne;
        objParams.paraphe = _this.infosSignature.parapheChecked;
        ContratService
          .signeContrat(objParams)
          .then(function (contrat) {
            _this.demande.setData(contrat);

            setUrlPDF(_this.document);
            emit({action: 'signeContrat', guidDocFournisseurSigne: guidDocFournisseurSigne});
          })
          .finally(function () {
            _this.loading = false;
          });
      }


    }

    /**
     * Lorsqu'un tampon est choisi dans le composant des tampons
     * @param objTampon
     */
    function onSelectTampon(objTampon) {
      _this.tampon.selected = objTampon;
    }

    /**
     * Lorsque le composant montant-contenu remonte une information
     */
    /*
    function onEmitFromMontantContenuComponent(obj, contenu){
        try {
            if (!obj) {
                throw new Error('Il manque l\'objet "obj" !');
            }

            if(obj.action === 'changeMontant') {
                contenu.setMontant(obj.montant);
                emit({action: 'addOrUpdateDemande'});
            }
        }
        catch (err) {
            console.log('[DetailPdfCtrl.onEmitFromMontantContenuComponent] Erreur : ' + err.message);
        }
    }
    */

    /**
     * Lorsque le composant demande-fournisseur remonte une information
     * @param obj
     */
    /*
    function onEmitFromDemandeDevisInter(obj){
        emit({action: 'onEmitFromDemandeDevisInter', obj: obj});
        // try {
        //     if(_.isNil(_this.listDemandeDevisInterCtrl)) {
        //         throw new Error('Ce composant est dépendant du composant listDemandeDevisInter');
        //     }
        //     _this.listDemandeDevisInterCtrl.onEmitFromDemandeDevisInter(obj);
        // }
        // catch(err) {
        //     var ErreurCollabService = $injector.get('ErreurCollabService');
        //     ErreurCollabService.logErreur('[DetailPdfCtrl.onEmitFromDemandeDevisInter] - ' + err.message);
        //     ErreurCollabService = null;
        // }
    }*/

    /**
     * Permet d'emit vers le parent
     * @param valObj
     */
    function emit(valObj){
      var object = {
        obj: {}
      };
      object.obj = _.merge(object.obj, valObj);
      // Si pas de "contenu" dans obj
      if(!object.obj.hasOwnProperty('contenu')) {
        object.obj.contenu = angular.copy(_this.demande)
      }
      // Si "contenu" dans obj
      else if(object.obj.action === 'addOrUpdateDemande'){
        _this.demande = object.obj.contenu;
      }
      _this.onEmit(object);
    }

    /**
     * Permet de réupérer la signature en lign ou dans la mémoire du composant
     * @returns {*}
     */
    function getSignature(){
      var deferred = $q.defer();
      if(_.isNil(_this.signature)) {
        // Recupère la signature
        SignatureService
          .getSignature()
          .then(function onSuccess(json){
            _this.signature = {
              dataUrl: 'data:image/png;base64,' + json.docString,
              $isEmpty: false
            };
            deferred.resolve(_this.signature);
          })
          .catch(function onError(msg){
            deferred.reject(msg);
          });
      }
      else {
        deferred.resolve(_this.signature);
      }
      return deferred.promise;
    }

    // New, holds the 8 tiny boxes that will be our selection handles
    // the selection handles will be in this order:
    // 0  1  2
    // 3     4
    // 5  6  7
    _this.selectionHandles = [];

    _this.SignatureWYSIWYG = {
      isActive: false,
      vars: {
        objectForSignature: {
          pdfDoc: false,									// Objet du PDF
          currentViewport: false,							// Viewport actuel de la page actuel
          desiredWidth: 596,								// Largeur désiré du PDF (A4 en 72dpi = 596px x 842px)
          objScale: {'scale': 1},
          pageNum: 1,										// Numéro de la page actuel du PDF
          pageRendering: false,
          pageNumPending: null,
          canvasPDF: false,
          contextCanvasPDF: false,
          scaleSignature: 50,
          widthBase: 0,
          heightBase: 0,
          widthScale: 0,
          heightScale: 0
        },
        objectPdfWYSIWYG: {
          url: false,
          canvasWYSIWYG: false,							// Canvas du WYSIWYG
          contextCanvasWYSIWYG: false,	// Context du canvas du WYSIWYG
          defaultBoxMouse: {
            width: 200,
            height: 100,
            left: (200 / 2),			// Position x de la souris dans le rectangle
            top: (100 / 2),		    // Position y de la souris dans le rectangle
            rotation: 0
          },
          customBoxMouse: false,
          widthMouse: 200,								// Largeur du rectangle autour de la souris
          heightMouse: 100,								// Hauteur du rectangle autour de la souris
          pageSigne: null, 								// Objet des pages signée
          active: false
        }
      },
      destroy: function () {
        //_this.signature = {};
        _this.SignatureWYSIWYG.isActive = false;
        _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.active = false;
        _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne = null;
        _this.SignatureWYSIWYG.vars.objectForSignature.pageNum = 1;
        var url = window.URL || window.webkitURL || window.mozURL;
        url.revokeObjectURL(_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.url);
      },
      init: function () {

        _this.loadingDocument = true;
        _this.erreurDocument = null;
        _this.SignatureWYSIWYG.isActive = true;

        // Recupère la signature
        getSignature()
          .then(function onSuccess(signature){

            // Recupère le PDF
            DocumentManagerService
              .getStream(_this.document.getGuid(), _this.idContenu)
              .then(function onSuccess(url) {

                _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.url = url;

                // Initialisation du PDF
                pdfjsLib
                  .getDocument(url)
                  .promise
                  .then(function (pdfDoc_) {

                    _this.SignatureWYSIWYG.vars.objectForSignature.pdfDoc = pdfDoc_;

                    _this.SignatureWYSIWYG.vars.objectForSignature.canvasPDF = angular.element('#canvasPDF')[0];
                    _this.SignatureWYSIWYG.vars.objectForSignature.contextCanvasPDF = _this.SignatureWYSIWYG.vars.objectForSignature.canvasPDF.getContext('2d');

                    _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG = angular.element('#canvasWYSIWYG')[0];
                    _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.contextCanvasWYSIWYG = _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.getContext('2d');


                    _this.SignatureWYSIWYG.pdf
                      .renderPage(_this.SignatureWYSIWYG.vars.objectForSignature.pageNum)
                      .then(function () {
                        _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.height = _this.SignatureWYSIWYG.vars.objectForSignature.canvasPDF.height;
                        _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.width = _this.SignatureWYSIWYG.vars.objectForSignature.canvasPDF.width;
                        _this.SignatureWYSIWYG.initSignature(signature);
                        if(!_this.signeAndAcceptDevis) _this.loadingDocument = false;
                      })
                      .catch(function(){
                        _this.loadingDocument = false;
                      });
                  })
                  .catch(function(){
                    _this.loadingDocument = false;
                  });
              })
              .catch(function(err){
                _this.SignatureWYSIWYG.isActive = true;
                if(_.isString(err)) {
                  _this.erreurDocument = err;
                }
                else {
                  console.log(err);
                }
                _this.loadingDocument = false;
              });
          })
          .catch(function onError(msg) {
            _this.loadingDocument = false;
            _this.erreurDocument = msg;
          });
      },
      initSignature: function (val) {
        if (val.hasOwnProperty('$isEmpty')) {
          //vm.objectPdfWYSIWYG.contextCanvasWYSIWYG.scale(vm.objectForSignature.objScale.scale,vm.objectForSignature.objScale.scale);
          if (!val.$isEmpty) {

            var image = new Image();
            image.src = val.dataUrl;

            image.onerror = function(){
              _this.loadingDocument = false;
            };

            image.onload = function () {

              var scale = _this.SignatureWYSIWYG.vars.objectForSignature.scaleSignature;
              var rotation = _this.SignatureWYSIWYG.vars.objectForSignature.objScale.rotation;

              //console.log('_this.SignatureWYSIWYG.vars.objectForSignature.objScale',_this.SignatureWYSIWYG.vars.objectForSignature.objScale);

              // Taille réel du PDF
              var widthBaseDoc = _this.SignatureWYSIWYG.vars.objectForSignature.widthBase = Math.ceil(_this.SignatureWYSIWYG.vars.objectForSignature.objScale.viewBox[2]);
              var heightBaseDoc = _this.SignatureWYSIWYG.vars.objectForSignature.heightBase = Math.ceil(_this.SignatureWYSIWYG.vars.objectForSignature.objScale.viewBox[3]);

              // Taille du PDF calculé par PDF.js par rapport à la largeur demandée
              var widthDoc = _this.SignatureWYSIWYG.vars.objectForSignature.widthScale = Math.ceil(_this.SignatureWYSIWYG.vars.objectForSignature.objScale.width);
              var heightDoc = _this.SignatureWYSIWYG.vars.objectForSignature.heightScale = Math.ceil(_this.SignatureWYSIWYG.vars.objectForSignature.objScale.height);

              if (rotation === 90 || rotation === -90 || rotation === 270) {
                widthDoc = _this.SignatureWYSIWYG.vars.objectForSignature.widthScale = Math.ceil(_this.SignatureWYSIWYG.vars.objectForSignature.objScale.height);
                heightDoc = _this.SignatureWYSIWYG.vars.objectForSignature.heightScale = Math.ceil(_this.SignatureWYSIWYG.vars.objectForSignature.objScale.width);
              }

              // Pourcentage entre la taille reel et la taille calculé
              var pourcentageHeight = Math.ceil((heightDoc * 100) / heightBaseDoc);
              var pourcentageWidth = Math.ceil((widthDoc * 100) / widthBaseDoc);

              /*
               if(rotation === 90 || rotation === -90 || rotation === 270) {
               pourcentageHeight = Math.ceil((heightDoc*100)/widthBaseDoc);
               pourcentageWidth = Math.ceil((widthDoc*100)/heightBaseDoc);
               }*/

              /*

               // Largeur du doc divisé par trois
               var widthDocTmp = Math.ceil(widthDoc/3.5);

               // Calcul de la nouvelle taille de l'image par rapport à la largeur du doc divisé par 3
               var widthImage = widthDocTmp;
               var heightImage = (widthDocTmp*image.height)/image.width;
               */

              // Calcule de la nouvelle taille de la signature par rapport au pourcentage de redimensionnement du PDF
              var widthImage = Math.ceil((pourcentageWidth * image.width) / 100);
              var heightImage = Math.ceil((pourcentageHeight * image.height) / 100);


              /*
               console.log('_this.SignatureWYSIWYG.vars.objectForSignature.objScale',_this.SignatureWYSIWYG.vars.objectForSignature.objScale);
               console.log('widthBaseDoc',widthBaseDoc);
               console.log('heightBaseDoc',heightBaseDoc);

               console.log('widthDoc',widthDoc);
               console.log('heightDoc',heightDoc);

               console.log('pourcentageWidth',pourcentageWidth);
               console.log('pourcentageHeight',pourcentageHeight);

               console.log('widthImage',widthImage);
               console.log('heightImage',heightImage);
               */

              // Réduction de la signature de 'scale' %
              widthImage = Math.ceil((scale * widthImage) / 100);
              heightImage = Math.ceil((scale * heightImage) / 100);


              /*
               if(_this.SignatureWYSIWYG.vars.objectForSignature.objScale.scale>1){

               widthImage = Math.ceil(image.width / _this.SignatureWYSIWYG.vars.objectForSignature.objScale.scale);
               heightImage = Math.ceil(image.height / _this.SignatureWYSIWYG.vars.objectForSignature.objScale.scale);
               }else{

               widthImage = Math.ceil(image.width * _this.SignatureWYSIWYG.vars.objectForSignature.objScale.scale);
               heightImage = Math.ceil(image.height * _this.SignatureWYSIWYG.vars.objectForSignature.objScale.scale);
               }*/

              _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.customBoxMouse = {
                width: widthImage,
                height: heightImage,
                top: Math.ceil(heightImage / 2),
                left: Math.ceil(widthImage / 2),
                image: image,
                rotation: rotation
              };

              // Si on accepte le devis en le signant alors on précoche la case d'activation de la signature
              if(_this.signeAndAcceptDevis) {
                $timeout(function(){
                  // Si on accepte le devis en le signant alors on précoche la case d'activation de la signature
                  _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.active = true;
                  _this.SignatureWYSIWYG.wysiwyg.enableSignatureWYSIWYG();
                  _this.loadingDocument = false;
                });
              }
            };
          }
        }
      },
      pdf: {

        queueRenderPage: function (num) {
          if (_this.SignatureWYSIWYG.vars.objectForSignature.pageRendering) {
            _this.SignatureWYSIWYG.vars.objectForSignature.pageNumPending = num;
          } else {
            this.renderPage(num);
          }
        },
        renderPage: function (nPage) {
          var deferred = $q.defer();

          _this.SignatureWYSIWYG.vars.objectForSignature.pageRendering = true;
          _this.SignatureWYSIWYG.vars.objectForSignature.pdfDoc.getPage(nPage).then(function (page) {

            _this.SignatureWYSIWYG.vars.objectForSignature.currentViewport = page.getViewport({scale: 1});
            var scale = _this.SignatureWYSIWYG.vars.objectForSignature.desiredWidth / _this.SignatureWYSIWYG.vars.objectForSignature.currentViewport.width;
            _this.SignatureWYSIWYG.vars.objectForSignature.objScale = page.getViewport({scale: scale});

            //console.log(_this.SignatureWYSIWYG.vars.objectForSignature.objScale);
            //console.log(scale);


            //var scale = 1.5;
            //var viewport = page.getViewport(scale);
            _this.SignatureWYSIWYG.vars.objectForSignature.canvasPDF.height = _this.SignatureWYSIWYG.vars.objectForSignature.objScale.height;
            _this.SignatureWYSIWYG.vars.objectForSignature.canvasPDF.width = _this.SignatureWYSIWYG.vars.objectForSignature.objScale.width;

            var renderContext = {
              canvasContext: _this.SignatureWYSIWYG.vars.objectForSignature.contextCanvasPDF,
              viewport: _this.SignatureWYSIWYG.vars.objectForSignature.objScale
            };

            var renderTask = page.render(renderContext);

            // Wait for rendering to finish
            renderTask.promise.then(function () {
              _this.SignatureWYSIWYG.vars.objectForSignature.pageRendering = false;
              if (_this.SignatureWYSIWYG.vars.objectForSignature.pageNumPending !== null) {
                // New page rendering is pending
                _this.SignatureWYSIWYG.pdf.renderPage(_this.SignatureWYSIWYG.vars.objectForSignature.pageNumPending);
                _this.SignatureWYSIWYG.vars.objectForSignature.pageNumPending = null;
              }
              if (_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.active) {
                _this.SignatureWYSIWYG.wysiwyg.drawBoxInWYSIWYGForPageNum(nPage);
              }
              deferred.resolve();
            });
          });
          return deferred.promise;
        },
        previousPage: function () {
          if (_this.SignatureWYSIWYG.vars.objectForSignature.pageNum <= 1) {
            return;
          }
          _this.SignatureWYSIWYG.vars.objectForSignature.pageNum--;
          this.queueRenderPage(_this.SignatureWYSIWYG.vars.objectForSignature.pageNum);
        },
        nextPage: function () {
          if (_this.SignatureWYSIWYG.vars.objectForSignature.pageNum >= _this.SignatureWYSIWYG.vars.objectForSignature.pdfDoc.numPages) {
            return;
          }
          _this.SignatureWYSIWYG.vars.objectForSignature.pageNum++;
          this.queueRenderPage(_this.SignatureWYSIWYG.vars.objectForSignature.pageNum);
        }
      },
      wysiwyg: {
        cleanCanvas: function () {
          // Efface tout
          _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.contextCanvasWYSIWYG.clearRect(0, 0, _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.width, _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.height);

          // Création du fond noir avec transparence
          //_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.contextCanvasWYSIWYG.fillStyle='rgba(0,0,0,0.3)';
          //_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.contextCanvasWYSIWYG.fillRect(0,0,_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.width,_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.height);
        },
        toggleSigne: function () {

          //_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.active = !_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.active;
          if (_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.active) {
            this.enableSignatureWYSIWYG();
          } else {
            this.disableSignatureWYSIWYG();
          }
        },
        enableSignatureWYSIWYG: function (numPage) {

          if (!numPage) {
            numPage = _this.SignatureWYSIWYG.vars.objectForSignature.pageNum;
          }

          // Si page signe alors draw la signature comme elle était,
          var pageSigne = this.drawBoxInWYSIWYGForPageNum(numPage);

          // Si la page n'est pas signé on draw la box dans le coin en haut à gauche
          if (!pageSigne) {
            /*
             var e = {
             'clientX':0,
             'clientY':0,
             'offsetX':0,
             'offsetY':0
             };
             this.drawBoxInWYSIWYGOnMove(e);*/
          }
        },
        disableSignatureWYSIWYG: function () {
          _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.contextCanvasWYSIWYG.clearRect(0, 0, _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.width, _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.height);
        },
        getLeftAndTopPositionOfSignature: function (e) {

          var objBox = this.getBoxMouse();
          var xPositionSourisInRectangle = objBox.left;
          var yPositionSourisInRectangle = objBox.top;

          this.cleanCanvas();

          var canvasRect = _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.getBoundingClientRect();   // Recupère la position du canvas
          var xPosition = Math.ceil(e.clientX - canvasRect.left);       // Ajuste la position du cursor
          var yPosition = Math.ceil(e.clientY - canvasRect.top);

          // Nombre pixel du bord gauche du canvas jusqu'au bord gauche du rectangle de la souris
          var offsetLeft = Math.ceil(e.offsetX - xPositionSourisInRectangle);
          // Nombre pixel du bord gauche du canvas jusqu'au bord droit du rectangle de la souris
          var offsetRight = Math.ceil(e.offsetX + xPositionSourisInRectangle);
          // Nombre pixel du bord haut du canvas jusqu'au bord haut du rectangle de la souris
          var offsetTop = Math.ceil(e.offsetY - yPositionSourisInRectangle);
          // Nombre pixel du bord haut du canvas jusqu'au bord haut du rectangle de la souris
          var offsetBottom = Math.ceil(e.offsetY + yPositionSourisInRectangle);

          var obj = {
            left: Math.ceil(xPosition - xPositionSourisInRectangle), // Position X du rectangle de la souris calculé automatiquement par rapport à la souris
            top: Math.ceil(yPosition - yPositionSourisInRectangle),	// Position Y du rectangle de la souris calculé automatiquement par rapport à la souris
            width: objBox.width,
            height: objBox.height,
            image: objBox.image
          };

          /*
           if(objBox.rotation === 90 || objBox.rotation === -90 || objBox.rotation === 270) {
           obj.width = objBox.height;
           obj.height = objBox.width;
           }
           */

          // Si le bord gauche du rectangle de la souris et plus petit que 0 (sort du canvas par la gauche)
          if (offsetLeft < 0) {
            obj.left = 0;
          }
          // Si le bord haut du rectangle de la souris et plus petit que 0 (sort du canvas par le haut)
          if (offsetTop < 0) {
            obj.top = 0;
          }

          // Si la position du bord droit du rectangle de la souris et plus grand ou égale à la taille
          // du canvas (sort du canvas par la doitre)
          if (offsetRight >= _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.width) {
            obj.left = Math.ceil(_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.width - obj.width);
          }

          // Si la position du bord bas du rectangle de la souris et plus grand ou égale à la taille
          // du canvas (sort du canvas par le bas)
          if (offsetBottom >= _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.height) {
            obj.top = Math.ceil(_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.height - obj.height);
          }

          return obj;
        },
        drawBoxInWYSIWYGOnMove: function (e) {
          var objPosition = this.getLeftAndTopPositionOfSignature(e);
          this.renderBoxInWYSIWYG(objPosition);
        },
        drawBoxInWYSIWYGForPageNum: function (numPage) {

          this.cleanCanvas();

          var retour = false;
          var pagesSignes = _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne;
          if (_.isObject(pagesSignes) && pagesSignes.hasOwnProperty(numPage)) {

            if (pagesSignes[numPage].signe) {

              var objPosition = {
                left: pagesSignes[numPage].offsetLeft,
                top: pagesSignes[numPage].offsetTop,
                width: pagesSignes[numPage].box.width,
                height: pagesSignes[numPage].box.height,
                image: pagesSignes[numPage].box.image
              };

              this.renderBoxInWYSIWYG(objPosition);
              retour = true;
            }
          }
          return retour;
        },
        renderBoxInWYSIWYG: function (objPosition) {

          var lefPosition = objPosition.left;
          var topPosition = objPosition.top;
          var widthMouse = objPosition.width;
          var heightMouse = objPosition.height;
          var image = objPosition.image;

          // Efface un rectangle autour de la souris
          _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.contextCanvasWYSIWYG.clearRect(lefPosition, topPosition, widthMouse, heightMouse);

          if (image) {
            // Dessine la signature dans le canvas
            _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.contextCanvasWYSIWYG.drawImage(image, lefPosition, topPosition, widthMouse, heightMouse);
          }

          // Dessine un autre rectangle sans fond autour de la souris
          _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.contextCanvasWYSIWYG.strokeStyle = '#000000';
          _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.contextCanvasWYSIWYG.lineWidth = 0.5;
          _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.contextCanvasWYSIWYG.lineJoin = 'round';
          _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.contextCanvasWYSIWYG.strokeRect(lefPosition, topPosition, widthMouse, heightMouse);
        },
        getBoxMouse: function () {
          // Box par défaut
          var objBox = _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.defaultBoxMouse;
          // Si il y a une box custom c'est elle quon prend
          if (_.isObject(_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.customBoxMouse)) {
            objBox = _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.customBoxMouse;
          }
          return objBox;
        },
        onMouseMoveInCanvas: function (e) {

          // Si la page actuelle n'est pas signée et que la signature est activé
          if (!this.currentPageIsSigne() && _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.active) {
            this.drawBoxInWYSIWYGOnMove(e);
          }
        },
        onMouseOutOfCanvas: function () {
          if (!this.currentPageIsSigne() && _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.active) {
            this.cleanCanvas();
          }
        },
        onMouseClickInCanvas: function (e) {

          if (_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.active) {
            var numPage = _this.SignatureWYSIWYG.vars.objectForSignature.pageNum;
            var offsetX = e.offsetX;
            var offsetY = e.offsetY;

            // Box par défaut
            var objBox = this.getBoxMouse();

            var xPositionSourisInRectangle = objBox.left;
            var yPositionSourisInRectangle = objBox.top;
            /*
             // Nombre pixel du bord gauche du canvas jusqu'au bord gauche du rectangle de la souris
             var offsetX = Math.ceil(e.offsetX-xPositionSourisInRectangle);
             // Nombre pixel du bord haut du canvas jusqu'au bord haut du rectangle de la souris
             var offsetY = Math.ceil(e.offsetY-yPositionSourisInRectangle);

             var posX, posY;
             // Si le bord gauche du rectangle de la souris et plus petit que 0 (sort du canvas par la gauche)
             if(offsetX<=0){
             offsetX = posX = 0;
             }
             else{
             posX = Math.ceil(offsetX-objBox.left);
             }

             // Si le bord haut du rectangle de la souris et plus petit que 0 (sort du canvas par le haut)
             if(offsetY<=0){ offsetY = 0; }
             else{
             posY = Math.ceil((_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.height-offsetY)-yPositionSourisInRectangle);
             }
             */

            var originPosX, originPosY, posX, posY;

            // Nombre pixel du bord gauche du canvas jusqu'au bord gauche du rectangle de la souris
            var offsetLeft = Math.ceil(e.offsetX - xPositionSourisInRectangle);
            // Nombre pixel du bord gauche du canvas jusqu'au bord droit du rectangle de la souris
            var offsetRight = Math.ceil(e.offsetX + xPositionSourisInRectangle);
            // Nombre pixel du bord haut du canvas jusqu'au bord haut du rectangle de la souris
            var offsetTop = Math.ceil(e.offsetY - yPositionSourisInRectangle);
            // Nombre pixel du bord haut du canvas jusqu'au bord bas du rectangle de la souris
            var offsetBottom = Math.ceil(e.offsetY + yPositionSourisInRectangle);


            // Si le bord gauche du rectangle de la souris et plus petit que 0 (sort du canvas par la gauche)
            if (offsetLeft < 0) {
              posX = 0;
              originPosX = 0;
            }
            else {
              // Si la position du bord droit du rectangle de la souris et plus grand ou égale à la taille
              // du canvas (sort du canvas par la doitre)
              if (offsetRight >= _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.width) {
                posX = Math.ceil(_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.width - objBox.width);
                originPosX = posX;
              }
              else {
                posX = offsetLeft;
                originPosX = Math.ceil(offsetX - objBox.left);
              }
            }

            // Si le bord haut du rectangle de la souris et plus petit que 0 (sort du canvas par le haut)
            if (offsetTop < 0) {
              posY = Math.ceil(_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.height - objBox.height);
              originPosY = 0;
            }
            else {

              // Si la position du bord bas du rectangle de la souris et plus grand ou égale à la taille
              // du canvas (sort du canvas par le bas)
              if (offsetBottom >= _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.height) {
                posY = 0;
                originPosY = Math.ceil(_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.height - objBox.height);
              }
              else {
                posY = Math.ceil(_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.height - offsetBottom);
                originPosY = Math.ceil(offsetY - objBox.top);
              }
            }


            //var posX = Math.ceil(offsetX-objBox.left);
            //var posY = Math.ceil((_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.canvasWYSIWYG.height-offsetY)-objBox.top);

            if(!_.isObject(_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne)) {
              _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne = {};
            }

            if (!_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne.hasOwnProperty(numPage)) {
              _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne[numPage] = {'signe': false};
            }

            if (!_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne[numPage].signe) {

              _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne[numPage].signe = true;
              //_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne[numPage].offsetTop = Math.ceil(offsetY-objBox.top);
              //_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne[numPage].offsetLeft = Math.ceil(offsetX-objBox.left);

              _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne[numPage].offsetTop = originPosY;
              _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne[numPage].offsetLeft = originPosX;

              _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne[numPage].x = posX;
              _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne[numPage].y = posY;


              // Calcule de la position réel de la signature par rapport à la taille réel du PDF
              var xReel = (posX * _this.SignatureWYSIWYG.vars.objectForSignature.widthBase) / _this.SignatureWYSIWYG.vars.objectForSignature.widthScale;
              var yReel = (posY * _this.SignatureWYSIWYG.vars.objectForSignature.heightBase) / _this.SignatureWYSIWYG.vars.objectForSignature.heightScale;
              _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne[numPage].xReel = Math.ceil(xReel);
              _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne[numPage].yReel = Math.ceil(yReel);


              _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne[numPage].box = objBox;
              //console.log(_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne[numPage]);
            } else {
              _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne[numPage] = {'signe': false};
            }
          }

          //console.log(_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne);
        },
        currentPageIsSigne: function () {
          var retour = false;
          if (_.isObject(_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG) && _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.active && _.isObject(_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne)) {
            var numPage = _this.SignatureWYSIWYG.vars.objectForSignature.pageNum;
            if (_.isObject(_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne[numPage]) && _this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne[numPage].signe) {
              retour = true;
            }
          }

          return retour;
        },
        isSigne: function() {
          if(!_.isObject(_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne)) return false;
          var retour = false;
          _.forEach(_this.SignatureWYSIWYG.vars.objectPdfWYSIWYG.pageSigne, function (properties, numPage) {
            if (properties.signe) {
              retour = true;
            }
          });
          return retour;
        }
      }

    };
  }
})();
