'use strict';

/**
 * @ngdoc service
 * @name collaboreApp.ProxyService
 * @description
 * # ProxyService
 * Service in the collaboreApp.
 */
/*
angular.module('collaboreApp').service('ProxyService',['ProxyMessage','ProxyEvenement', function(ProxyMessage,ProxyEvenement) {

	/**
   * @name init
   * @desc Crée une instance de contenu par rapport à son type
   * @param {Object} objJSON - Objet JSON du formulaire
   * @returns {Object} Objet du proxy selon le type
   */
   /*
	this.init = function(objJSON){
		var retour;
		switch(objJSON.typeContenu){
		case '1':
			retour = new ProxyMessage(objJSON);
			break;
		case '2':
			retour = new ProxyEvenement(objJSON);
			break;
		default:
			retour = new ProxyMessage(objJSON);
		}
		return retour;
	};

}]);*/

angular.module('collaboreApp').service('ProxyService',['Tag','ContenuMessage','ContenuEvenement','ContenuEvenementDevis','ContenuEvenementIntervention','ContenuEvenementFacture', function(Tag,ContenuMessage,ContenuEvenement,ContenuEvenementDevis,ContenuEvenementIntervention,ContenuEvenementFacture) {

	/**
   * @name init
   * @desc Crée une instance de contenu par rapport à son type
   * @param {Object} objJSON - Objet JSON du formulaire
   * @returns {Object} Objet du proxy selon le type
   */

	this.init = function(objJSON){
		var retour;
		switch(objJSON.typeContenu){
		case '1':
			retour = new ContenuMessage(objJSON);
			break;
		case '2':
			retour = new ContenuEvenement(objJSON);
			break;
		case '3':
			retour = new ContenuEvenementDevis(objJSON);
			break;
		case '4':
			retour = new ContenuEvenementIntervention(objJSON);
			break;
		case '5':
			retour = new ContenuEvenementFacture(objJSON);
			break;
		default:
			retour = new ContenuMessage(objJSON);
		}
		return retour;
	};

	this.new = function(typeContenu,isProxy){
		var retour;
		switch(typeContenu){
			case '1':
				retour = new ContenuMessage(false,isProxy);
				break;
			case '2':
				retour = new ContenuEvenement(false,isProxy);
				break;
			case '3':
				retour = new ContenuEvenementDevis(false,isProxy);
				break;
			case '4':
				retour = new ContenuEvenementIntervention(false,isProxy);
				break;
			case '5':
				retour = new ContenuEvenementFacture(false,isProxy);
				break;
			default:
				retour = new ContenuMessage(false,isProxy);
		}
		return retour;
	};

	this.newMessage = function(data){
		return new ContenuMessage(data,true);
	};

	this.newEvenement = function(data){
		return new ContenuEvenement(data,true);
	};


	this.newDevis = function(data,bool){
		return new ContenuEvenementDevis(data,bool);
	};

	this.newIntervention = function(data,bool){
		return new ContenuEvenementIntervention(data,bool);
	};

	this.newFacture = function(data){
		return new ContenuEvenementFacture(data,true);
	};

	this.newTag = function(data,libelleTypeTag,valeurIcs,portefeuille){
		var objData = {};
		// Si data est in string alors c'est un libelle normal et un tag non typé
		if(angular.isString(data)){
			objData = {
				//'libelleTag': encodeURIComponent(data)
				'libelleTag': data
			};

			if(libelleTypeTag){
				objData.libelleTypeTag = libelleTypeTag;
			}
		}else{
			objData = data;
			/*
			// Si data a "typeTag" et n'a pas "libelleTypeTag"
			if(data.hasOwnProperty('typeTag') && !data.hasOwnProperty('libelleTypeTag')){
				objData.libelleTypeTag = data.typeTag.libelle;
			}*/
		}


		if(valeurIcs){
			/*
			if(!objData.hasOwnProperty('valeurIcs')){
				if(angular.isObject(valeurIcs)){
					valeurIcs = angular.toJson(valeurIcs);
				}
			}else{
				if(angular.isObject(objData.valeurIcs)){
					valeurIcs = angular.toJson(objData.valeurIcs);
				}
			}*/

			objData.valeurIcs = valeurIcs;
		}

		if(portefeuille){
			if(angular.isObject(portefeuille)){
				objData.idPortefeuille = portefeuille.idPortefeuille;
			}else if(angular.isNumber(portefeuille)){
				objData.idPortefeuille = portefeuille;
			}
		}



		return new Tag(objData,true);
	};

}]);