(function() {

  'use strict';

  /**
   * @desc Composant gerer les parametrage d'un groupe
   * @example <groupe-parametre-checkbox></groupe-parametre-checkbox>
   */
  angular
    .module('collaboreApp')
    .component('groupeParametreCheckbox',{
      templateUrl: 'app/parametrage/groupe-parametre-checkbox/groupe-parametre-checkbox.html',
      controllerAs: 'groupeparametrecheckboxctrl',
      bindings: {
        groupeAll: '<',
        property: '@',
        value: '<',
        defaultValue: '<',
        presentInParamPerso: '<',
        label: '@',
        labelGroupeAll: '@',
        customStyle: '<',
        tooltip: '@',
        onEmit: '&'
      },
      controller: GroupeParametreCheckboxCtrl
    });

  /*@ngInject*/
  function GroupeParametreCheckboxCtrl($q){
    var ctrl = this;
    ctrl.loadingParam = false;
    ctrl.onChangeCheckbox = onChangeCheckbox;
    ctrl.onClickResetParametre = onClickResetParametre;
    ctrl.getIconBoolean = getIconBoolean;
    ctrl.isNil = _.isNil;

    ctrl.$onInit = function(){
      /*
      console.log('-------' + ctrl.property + '----------');
      console.log('value',ctrl.value);
      console.log('defaultValue',ctrl.defaultValue);
      console.log('groupeAll',ctrl.groupeAll);
      console.log('presentInParamPerso',ctrl.presentInParamPerso);
      */
    };

    ctrl.$onDestroy = function() {
    };

    /**
     * Lorque l'on clique sur une case à cocher
     * @param property
     * @param value
     */
    function onChangeCheckbox(){
      emit({action: 'changeValue', property: ctrl.property, value: ctrl.value}).then(function(){
        ctrl.presentInParamPerso = true;
      });
    }

    /**
     * Permet de reset un paramètre pour récup la valeur par du param société
     * @param param
     */
    function onClickResetParametre(){
      emit({action: 'reset', property: ctrl.property}).then(function(){
        ctrl.presentInParamPerso = true;
      });
    }

    function emit(objet){
      var deferred = $q.defer();
      ctrl.onEmit({obj: objet}).finally(function(){
        deferred.resolve();
      });
      return deferred.promise;
    }

    function getIconBoolean(val) {
      var retour = '?';
      if(val===true) retour = '<i class="text-primary fa fa-check-square-o"></i>';
      else retour = '<i class="text-primary fa fa-square-o"></i>';
      return retour;
    }

  }
})();
