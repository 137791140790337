(function() {

  /**
   * TODO: Créer un composant similaire à choose-collaborateur mais qui fait appel à deux servlet
   * Une pour recup les users et l'autres les groupes pour pouvoir faire de la pagination sur chaque webservice
   */
  'use strict';

  /**
   * @desc Componsant pour choisir un groupe collaborateur (user et non user sauf immeuble etc..)
   * @example <choose-groupe-collaborateur></choose-groupe-collaborateur>
   */
  angular
    .module('collaboreApp')
    .component('chooseGroupeCollaborateur',{
      templateUrl: 'app/components/choose-groupe-collaborateur/choose-groupe-collaborateur.component.html',
      bindings : {
        idContenu: '<',                   // IdContenu
        hideCollaborateurPresent: '<',    // Permet de cacher les collaborateur qui sont present dans le groupe passé en param
        onlyCollaborateurs: '<',          // Affiche seulement les collaborateurs
        addMe: '<',                       // Si je doit etre dans la liste
        collaborateurs: '<',              // La liste des collaborateurs du contenu
        onChangeCollaborateur: '&',       // Lorsque la liste des collaborateur change, notifie le composant parent

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<',
        iconTitleModal: '@',
        textTitleModal: '@'
      },
      controllerAs: 'choosegroupecollaborateurctrl',
      controller: ChooseGroupeCollaborateurController
    });

  /*@ngInject*/
  function ChooseGroupeCollaborateurController($scope, $injector, $timeout, GroupeService, UtilsService){

    var _this = this;
    _this.searchGroupe = null;

    _this.users = {
      list: [],
      loading: false,
      loadingAdd: false,
      page: 1,
      limit: 10,
      nbTotal: 0,
      order: 'libelle',
      sens: 'ASC'
    };

    _this.groupes = {
      list: [],
      loading: false,
      loadingAdd: false,
      page: 1,
      limit: 10,
      nbTotal: 0,
      order: null,
      sens: null
    };

    _this.itsMe = UtilsService.itsMe;
    _this.onSubmitSearchDestinataire = onSubmitSearchDestinataire;
    _this.onClickClearSearchDestinataire = onClickClearSearchDestinataire;
    _this.onSubmitLibreDestinataire = onSubmitLibreDestinataire;
    _this.onCheckGroupe = onCheckGroupe;
    _this.onCheckAllGroupeUser = onCheckAllGroupeUser;
    _this.onClickClose = onClickClose;
    _this.onClickSave = onClickSave;

    _this.$onInit = function() {

      if(_this.resolve && _this.modalInstance) {
        _this.idContenu = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'idContenu'));
        _this.hideCollaborateurPresent = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'hideCollaborateurPresent'));
        _this.onlyCollaborateurs = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'onlyCollaborateurs'));
        _this.addMe = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'addMe'));
        _this.collaborateurs = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'collaborateurs'));
        _this.iconTitleModal = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'iconTitleModal'));
        _this.textTitleModal = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'textTitleModal'));

        if(_.isNil(_this.iconTitleModal) || !_this.iconTitleModal) _this.iconTitleModal = 'fa-users';
        if(_.isNil(_this.textTitleModal) || !_this.textTitleModal) _this.textTitleModal = 'Mes collaborateurs';
      }

      initSelectedList(_this.collaborateurs);

      _this.newCollaborateurs = []; // Liste des destinatiares pour les nouveaux destinataire si contenu existe déjà

      $scope.$on('addCollaborateur', function(event, val){
        addCollaborateur(val);
        emitCollaborateur();
      });
      init();
    };

    /**
     * Lors de changements depuis le parent
     * @param changes
     */
    _this.$onChanges = function (changes) {
      //console.log(changes);

      if(_.isObject(changes.collaborateurs) && !changes.collaborateurs.isFirstChange() && changes.collaborateurs.currentValue.length){
        initSelectedList(changes.collaborateurs.currentValue);
        changeStatusCheckedForAllFromDestinataires();
      }
    };

    _this.$onDestroy = function(){
    };

    /**
     * Initlisation
     */
    function init(){

      var configScrollbar = {
        autoHideScrollbar: false,
        theme: 'dark-3',
        advanced: {
          updateOnContentResize: true
        },
        scrollButtons: {
          scrollAmount: 'auto', // scroll amount when button pressed
          enable: false // enable scrolling buttons by default
        },
        axis: 'y', // enable 2 axis scrollbars by default
        //setHeight: 200,
        scrollInertia: 100
      };

      _this.configScrollbarUsers = angular.copy(configScrollbar);
      _this.configScrollbarGroupes = angular.copy(configScrollbar);

      _this.configScrollbarUsers.callbacks = {
        onTotalScrollOffset: 50,
        onTotalScroll: function(){
          console.log("end users");
          callNextUsers();
        }
      };

      _this.configScrollbarGroupes.callbacks = {
        onTotalScrollOffset: 50,
        onTotalScroll: function(){
          callNextGroupes();
          console.log("end groupes");
        }
      };

      callUsers();
      callGroupes();
    }


    function callNextUsers(){
      if(_this.users.list.length < _this.users.nbTotal) {
        _this.users.page = _this.users.page+1;
        callUsers(true);
      }
    }

    function callUsers(append){
      if(append) _this.users.loadingAdd = true;
      else _this.users.loading = true;

      GroupeService.searchCollaborateurs(_this.searchGroupe, _this.users.page, _this.users.limit, _this.users.order, _this.users.sens)
        .then(function(objRetour){
          _this.users.nbTotal = objRetour.total;

          var groupes = objRetour.groupes;
          if(append) _this.users.list = _.concat(_this.users.list, groupes);
          else _this.users.list = groupes;

          if(!_.isNil(_this.searchGroupe)){
            _this.users.list.map(function(groupe){
              searchInGroupe(_this.searchGroupe, groupe)
            });
          }

          changeStatusCheckedGroupesUserFromDestinataires();
        })
        .finally(function(){
          if(append) _this.users.loadingAdd = false;
          else _this.users.loading = false;
        });
    }

    function callNextGroupes(){
      if(_this.groupes.list.length < _this.groupes.nbTotal) {
        _this.groupes.page = _this.groupes.page+1;
        callGroupes(true);
      }
    }

    function callGroupes(append){
      if(append) _this.groupes.loadingAdd = true;
      else _this.groupes.loading = true;
      GroupeService.searchGroupeOfCollaborateurs(_this.searchGroupe, _this.groupes.page, _this.groupes.limit, true, _this.groupes.order, _this.groupes.sens)
        .then(function(objRetour){

          _this.groupes.nbTotal = objRetour.total;

          var groupes = objRetour.groupes;
          if(append) _this.groupes.list = _.concat(_this.groupes.list, groupes);
          else _this.groupes.list = groupes;

          if(!_.isNil(_this.searchGroupe)){
            _this.groupes.list.map(function(groupe){
              searchInGroupe(_this.searchGroupe, groupe)
            });
          }

          changeStatusCheckedGroupesEquipeFromDestinataires();
        })
        .finally(function(){
          if(append) _this.groupes.loadingAdd = false;
          else _this.groupes.loading = false;
        });
    }

    function initSelectedList(collaborateurs){
      var dest = [];
      if(_.isArray(collaborateurs) && collaborateurs.length) {
        for(var i = 0; i < collaborateurs.length; i++){
          if(_this.addMe || !_this.itsMe(collaborateurs[i])) dest.push(angular.copy(collaborateurs[i]));
        }
      }
      _this.selectedList = dest;
    }

    /**
     * Change l'état de l'attribut "checked" des groupes de la liste "users.list" et "groupes.list" par rapport aux groupes de la liste "destinataires"
     */
    function changeStatusCheckedForAllFromDestinataires(){
      changeStatusCheckedGroupesUserFromDestinataires();
      changeStatusCheckedGroupesEquipeFromDestinataires();
    }

    /**
     * Change l'état de l'attribut "checked" des groupes de la liste "users.list" par rapport aux groupes de la liste "destinataires"
     */
    function changeStatusCheckedGroupesUserFromDestinataires(){
      _this.users.list = changeStatusCheckedFromDestinataires(_this.users.list);
    }

    /**
     * Change l'état de l'attribut "checked" des groupes de la liste "groupes.list" par rapport aux groupes de la liste "destinataires"
     */
    function changeStatusCheckedGroupesEquipeFromDestinataires(){
      _this.groupes.list = changeStatusCheckedFromDestinataires(_this.groupes.list);
    }

    /**
     * Change l'état de l'attribut "checked" des groupes de la liste passé en paramètre par rapport aux groupes de la liste "destinataires"
     * @param listeGroupe
     */
    function changeStatusCheckedFromDestinataires(listeGroupe){
      if(_.isArray(listeGroupe) && listeGroupe.length) {
        for(var n = 0; n < listeGroupe.length; n++){
          listeGroupe[n].checked = false;

          if(_this.selectedList.length) {
            for(var d = 0; d < _this.selectedList.length; d++) {
              if (GroupeService.isSameGroupe(_this.selectedList[d], listeGroupe[n])) {
                listeGroupe[n].checked = true;
                // Si il y a un idContenu alors c'est un contenu enregistré on doit donc rendre impossible de decocher le groupe qui est déjà destinataire
                if(_this.idContenu || _this.selectedList[d].disabledCheckbox) listeGroupe[n].disabledCheckbox = true; // Disable la case à cocher
              }
            }
          }

          if(_this.idContenu && _this.newCollaborateurs.length) {
            for(var f = 0; f < _this.newCollaborateurs.length; f++){
              if(GroupeService.isSameGroupe(_this.newCollaborateurs[f], listeGroupe[n])) {
                listeGroupe[n].checked = true;
              }
            }
          }
        }
      }
      return listeGroupe;
    }

    /**
     * Lors du submit du formulaire de recherche
     */
    function onSubmitSearchDestinataire(){

      if(_.isEmpty(_this.searchGroupe)) _this.searchGroupe = null;

      _this.users.page = 1;
      _this.groupes.page = 1;
      callUsers();
      callGroupes();
    }

    function onClickClearSearchDestinataire(){
      _this.searchGroupe = null;
      onSubmitSearchDestinataire();
    }

    /**
     * Permet de recherche un groupe par rapport à un string
     * @param search
     * @param groupe
     * @returns {boolean}
     */
    function searchInGroupe(search, groupe){
      var find = false;
      var regEx = new RegExp(search.toLowerCase(), 'ig');
      if(UtilsService.test((groupe.getDieze()) ? groupe.getDieze().toLowerCase() : '', search, 'ig')) {
        find = true;
        //groupe.open = true;
        groupe.dieze = groupe.dieze.replace(regEx, function(match) {
          return '<strong style="color:#5ab728">' + match + '</strong>';
        });
      }

      if(UtilsService.test((groupe.getLibelle()) ? groupe.getLibelle().toLowerCase() : '', search, 'ig')) {
        find = true;
        //groupe.open = true;
        groupe.libelle = groupe.libelle.replace(regEx, function(match) {
          return '<strong style="color:#5ab728">' + match + '</strong>';
        });
      }

      if(UtilsService.test((groupe.getDefaultLibelle()) ? groupe.getDefaultLibelle().toLowerCase() : '', search, 'ig')) {
        find = true;
        //groupe.open = true;
        groupe.defaultLibelle = groupe.defaultLibelle.replace(regEx, function (match) {
          return '<strong style="color:#5ab728">' + match + '</strong>';
        });
      }
      return find;
    }

    /**
     * Lors du submit du formulaire de recherche
     *
     function onSubmitSearchDestinataire(){
      _this.groupeAllFiltered = angular.copy(_this.groupeAll);
      if(_this.searchGroupe !== '') recursiveSearch(_this.searchGroupe, _this.groupeAllFiltered);
    }*/

    /**
     * Lors du submit du formulaire d'ajout de destinataire libre
     */
    function onSubmitLibreDestinataire(){
      _this.messageErreurDestinataireLibre = '';
      if(_this.libreDestinataire !== '') {
        var obj = null;

        // Si email
        if(UtilsService.isMail(_this.libreDestinataire)) obj = {isMail: true, libelle: _this.libreDestinataire, dieze: ''};
        // Si groupe collab
        else if(UtilsService.isStringGroupeCollab(_this.libreDestinataire)) obj = UtilsService.convertStringToGroupeCollab(_this.libreDestinataire);
        // Erreur
        else _this.messageErreurDestinataireLibre = 'Veuillez saisir : exemple#Societe OU un email';

        if(obj) {
          addCollaborateur(obj);
          _this.libreDestinataire = '';
        }
      }
    }

    /**
     * Permet d'ajouter un destinataire à la liste des destinataires
     * @param groupe | Array
     */
    function addCollaborateur(destinataire) {
      _this.selectedList = GroupeService.addGroupeToList(destinataire, _this.selectedList);
      if(_this.idContenu) _this.newCollaborateurs = GroupeService.addGroupeToList(destinataire, _this.newCollaborateurs);
    }

    /**
     * Permet de retirer un collaborateur de la selectedList
     * @param groupe
     */
    function removeCollaborateur(groupe) {
      _this.selectedList = GroupeService.removeGroupeFromList(groupe, _this.selectedList);
      if(_this.idContenu) _this.newCollaborateurs = GroupeService.removeGroupeFromList(groupe, _this.newCollaborateurs);
    }

    /**
     * Lorsqu'un groupe est coché/décoché
     * @param groupe
     */
    function onCheckGroupe(groupe){
      if(groupe.checked) addCollaborateur(groupe);             // Ajoute ce groupe en destinataire
      else if(!groupe.checked) removeCollaborateur(groupe);    // Retire le groupe des destinataires
      emitCollaborateur();
    }

    /**
     * Permet de Cocher/Décocher tous les groupe user d'un coup
     */
    function onCheckAllGroupeUser(){
      var checked = _this.allGroupeUserChecked;
      if(_this.users.list.length) {
        for(var i = 0; i < _this.users.list.length; i++){
          if(!_this.users.list.disabledChecked) {
            _this.users.list[i].checked = checked;
            if(checked) addCollaborateur(_this.users.list[i]);
            else removeCollaborateur(_this.users.list[i]);
          }
        }
        emitCollaborateur();
      }
    }

    function emitCollaborateur(forceEmit){
      if(!_this.modalInstance || forceEmit) {
        emit({
          collaborateurs: _this.selectedList,
          newCollaborateurs: _this.newCollaborateurs
        });
      }

      /*
      _this.onChangeCollaborateur({
        obj: {
          collaborateurs: _this.selectedList,
          newCollaborateurs: _this.newCollaborateurs
        }
      });*/
    }

    /**
     * Si le composant est ouvert dans une modal et que l'on clique sur le bouton pour fermer la modal
     */
    function onClickClose(){
      close();
    }

    /**
     * Si le composant est ouvert dans une modal et que l'on clique sur le bouton pour sauvegarder
     */
    function onClickSave(){
      emitCollaborateur(true);
    }

    /**
     * Si ouvert en mode Modal
     */
    function close(){
      if(_this.modalInstance) _this.dismiss({$value: 'cancel'});
      else emit({action: 'closeChooseCollaborateur'});
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      if(_this.modalInstance) _this.close({$value: objRetour});
      else {
        _this.onChangeCollaborateur({
          obj: objRetour
        });
      }
    }
  }
})();
