'use strict';

/**
 * @desc Icon pour voir un contenu important et le changer
 * @example <icon-contenu-important id-topic="" icon-size="small"></icon-contenu-important>
 */

angular
	.module('collaboreApp')
	.component('iconContenuImportant',{
		templateUrl: 'app/topics/components/icon-contenu-important/icon-contenu-important.component.html',
		bindings : {
			idTopic: '<',		// <Integer> Id du topic
			idContenu: '<',		// <Integer> Id du contenu
			isImportant: '<',	// <Object> Etat du contenu
			faIconSize: '@',		// <String> Taille de l'icone
			pending: '<',
			onEmit: '&'
		},
		controllerAs: 'iconcontenuimportantctrl',
		controller: IconContenuImportantController
	});

/*@ngInject*/
function IconContenuImportantController(ContenuService, ModalsService, $timeout){

	var _this = this;

	_this.onClickIcon = onClickIcon;		// Lors du clique sur l'icon

	_this.$onInit = function() {

		if(_.isNil(_this.idTopic) && _.isNil(_this.idContenu))
			ModalsService.alertErreur('Pas d\'idTopic ou d\'idContenu !');

		if(_.isNil(_this.faIconSize)) {
			_this.faIconSize = 'fa-lg';
		}
		setLibelle();
	};

	_this.$onChanges = function(changes) {
		if(_.isObject(changes.isImportant) && changes.isImportant.isFirstChange()) {
			_this.isImportant = changes.isImportant.currentValue;
		}
		if(_.isObject(changes.pending)) {
			_this.pending = changes.pending.currentValue;
		}
		setLibelle();
	};

	_this.$onDestroy = function(){
	};

	/**
	 * Set le libelle du tooltip
	 * @param state
	 */
	function setLibelle(){
		if(!_.isNil(_this.idContenu)) {
			if(_this.isImportant)_this.libelleTooltip = 'Ne plus rendre Important';
			else _this.libelleTooltip = 'Rendre Important';
		}
		else if(!_.isNil(_this.idTopic)) {
			if(_this.isImportant)_this.libelleTooltip = 'Important';
			else _this.libelleTooltip = 'Pas Important';
		}
	}

	/**
	 * Permet d'update le state
	 */
	function onClickIcon(){

		if(!_.isNil(_this.idContenu)) {
			_this.loading = true;
			ContenuService
				.toggleImportant(_this.idContenu)
				.then(function(contenu){
					emit({action: 'updateContenu', contenu: contenu});
				})
				.finally(function(){
					_this.loading = false;
				});
		}
	}

	/**
	 * Permet d'emit des infos vers le composant parent
	 * @param objet
	 */
	function emit(objet){
		_this.onEmit({
			obj: objet
		});
	}
}