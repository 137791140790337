(function() {
  'use strict';

  angular
    .module('collaboreApp')
    .factory('Tantcolonne', TantcolonneModel);

  /** @ngInject */
  function TantcolonneModel(Main) {

    function Tantcolonne(contenuData) {
      Main.apply(this, arguments);
    }

    Tantcolonne.prototype = {

      model: 'Tantcolonne',
      isTantcolonne: true,

      // IIII
      getIIII: function () {
        return this.IIII;
      },
      setIIII: function (val) {
        this.IIII = val;
      },

      // BBBB
      getBBBB: function () {
        return this.BBBB;
      },
      setBBBB: function (val) {
        this.BBBB = val;
      },

      // IdColonne
      getIdColonne: function () {
        return this.IdColonne;
      },
      setIdColonne: function (val) {
        this.IdColonne = val;
      },

      // TLibtant
      getTLibtant: function () {
        return this.TLibtant;
      },
      setTLibtant: function (val) {
        this.TLibtant = val;
      },

      // TLibtant1
      getTLibtant1: function () {
        return this.TLibtant1;
      },
      setTLibtant1: function (val) {
        this.TLibtant1 = val;
      },

      // Ttant
      getTtant: function () {
        return this.Ttant;
      },
      setTtant: function (val) {
        this.Ttant = val;
      },

      // Ttant
      getTtant1: function () {
        return this.Ttant1;
      },
      setTtant1: function (val) {
        this.Ttant1 = val;
      }

    };

    angular.extend(Tantcolonne.prototype, Main.prototype);

    return Tantcolonne;
  }
})();
