(function() {

  'use strict';

  /**
   * @desc Composant gerer mes groupes plus simplmement
   * @example <admin-gestion-groupes></admin-gestion-groupes>
   */
  angular
    .module('collaboreApp')
    .component('adminGestionGroupes',{
      templateUrl: 'app/parametrage/admin/admin-gestion-groupes/admin-gestion-groupes.html',
      controllerAs: 'admingestiongroupesctrl',
      controller: AdminGestionGroupesCtrl
    });

  /** @ngInject */
  function AdminGestionGroupesCtrl(GroupeService, ModalsService){
    var ctrl = this;
    ctrl.groupe = null;
    ctrl.listGroupes = [];

    ctrl.onClickAddEquipe = onClickAddEquipe;
    ctrl.onEmitFromLigne = onEmitFromLigne;
    ctrl.onProgress = onProgress;

    ctrl.$onInit = function(){
      ctrl.loading = true;

      GroupeService
        .getHierarchieAgenceWithoutPortefeuille()
        .then(function (groupe) {
          init(groupe);
        })
        .finally(function(){
          ctrl.loading = false;
        });
      /*
      GroupesAdminService.getGroupesOnly().then(function(groupe){
        console.log(groupe);
        ctrl.listGroupes = groupe;
      }).finally(function(){
        ctrl.loading = false;
      });*/
    };

    function init(groupe){
      ctrl.groupe = groupe;
      if(groupe.getGroupesFils().length){
        ctrl.listGroupes = [];
        for(var i = 0; i < groupe.getGroupesFils().length; i++) {
          if(!groupe.getGroupesFils()[i].isUser()) ctrl.listGroupes.push(groupe.getGroupesFils()[i]);
        }
      }
      else ctrl.listGroupes = groupe.getGroupesFils();
    }

    function onClickAddEquipe(){
      var options = {
        size: 'lg',
        windowClass: 'modal-collabore',
        backdrop: 'static'
      };

      var params = {
        groupe: ctrl.groupe,
        createGroupeNoUser: true
      };

      ModalsService
        .modalComponent('adminGestionAjoutGroupe', params, options)
        .then(function(obj) {
          //console.log(obj);
          if(angular.isObject(obj) && obj.action === 'refreshGroupe' && angular.isObject(obj.groupe))
            init(obj.groupe);
        });
    }

    /**
     * Si emit venant d'une ligne
     * @param obj
     */
    function onEmitFromLigne(obj){
      if(angular.isObject(obj) && obj.action === 'refreshGroupe' && angular.isObject(obj.groupe)) {
        init(obj.groupe);
      }
    }

    function onProgress(progress){
      ctrl.progress = progress;
    }
  }
})();
