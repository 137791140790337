(function () {
  'use strict';

  angular.module('collaboreApp').factory('InformationsAjax', InformationsAjax);
  angular.module('collaboreApp').factory('NotificationsAjax', NotificationsAjax);
  angular.module('collaboreApp').factory('AnnuaireAjax', AnnuaireAjax);
  angular.module('collaboreApp').factory('TagAjax', TagAjax);
  angular.module('collaboreApp').factory('GedAjax', GedAjax);
  angular.module('collaboreApp').factory('ComfactAjax', ComfactAjax);
  angular.module('collaboreApp').factory('ContenuAjax', ContenuAjax);
  angular.module('collaboreApp').factory('MessageAjax', MessageAjax);
  angular.module('collaboreApp').factory('DemandeAjax', DemandeAjax);
  angular.module('collaboreApp').factory('DevisAjax', DevisAjax);
  angular.module('collaboreApp').factory('CourrierAjax', CourrierAjax);
  angular.module('collaboreApp').factory('EvenementAjax', EvenementAjax);
  angular.module('collaboreApp').factory('SinistreAjax', SinistreAjax);
  angular.module('collaboreApp').factory('FactureAjax', FactureAjax);
  angular.module('collaboreApp').factory('DocumentsAjax', DocumentsAjax);
  angular.module('collaboreApp').factory('TemporaryDocBoxAjax', TemporaryDocBoxAjax);
  angular.module('collaboreApp').factory('ParamsAjax', ParamsAjax);
  angular.module('collaboreApp').factory('RecapMailAjax', RecapMailAjax);
  angular.module('collaboreApp').factory('ShootingMailAjax', ShootingMailAjax);
  angular.module('collaboreApp').factory('DashboardAjax', DashboardAjax);
  angular.module('collaboreApp').factory('CalculatriceAjax',CalculatriceAjax);
  angular.module('collaboreApp').factory('RechercheAjax', RechercheAjax);
  angular.module('collaboreApp').factory('HistoriqueAvancementAjax', HistoriqueAvancementAjax);
  angular.module('collaboreApp').factory('FiltreTopicsAjax', FiltreTopicsAjax);
  angular.module('collaboreApp').factory('SignatureAjax', SignatureAjax);
  angular.module('collaboreApp').factory('TamponAjax', TamponAjax);
  angular.module('collaboreApp').factory('GroupeAjax', GroupeAjax);
  angular.module('collaboreApp').factory('PersonnaliteAjax', PersonnaliteAjax);
  angular.module('collaboreApp').factory('AdminAjax', AdminAjax);
  angular.module('collaboreApp').factory('FicheAjax', FicheAjax);
  angular.module('collaboreApp').factory('ChannelAjax', ChannelAjax);
  angular.module('collaboreApp').factory('CommunicationAjax', CommunicationAjax);
  angular.module('collaboreApp').factory('AdminCollabAjax', AdminCollabAjax);
  angular.module('collaboreApp').factory('RunJobResource', RunJobResource);
  angular.module('collaboreApp').factory('AdminTopicAjax', AdminTopicAjax);

  /** @ngInject */
  function InformationsAjax(COLLAB_CONF,COLLAB_VALUES, $resource) {
    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INFORMATIONS_COLLABORE);
  }

  /** @ngInject */
  function NotificationsAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {
    /**
     * type : 0 = Pas Lu
     * type : 1 = Lu
     * type : 2 = Toutes les notifications
     */
    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_NOTIFICATIONS, {type: '@type'}, {
      lectureNotification: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      }
    });
  }

  /** @ngInject */
  function AnnuaireAjax(COLLAB_CONF,COLLAB_VALUES, $resource) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ANNUAIRE_PERSO, null, {
      getAnnuairePerso: {
        method: 'POST',
        //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        params: {
          type: '@type',
          page: '@page',
          limit: '@limit'
        }
      },

      getMetiersFournisseur: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_METIER_FOURNISSEUR,
        params: {
          global: '@global'
        }
      }
    });
  }

  /** @ngInject */
  function TagAjax(COLLAB_CONF,COLLAB_VALUES, $resource) {
    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_TAGS, null, {
      get: {
        params: {
          tag: '@tag',
          type: '@type',
          libelleType: '@libelleType',
          nb: '@nb'
        }
      },
      addInContenu: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_TAGS,
        params: {
          contenu: '@contenu',  // idContenu
          type: '@type',
          tags: '@tags'     // #exemple
        }
      },
      addInDocument: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_TAGS_IN_DOCUMENT,
        params: {
          guid: '@guid',  // guid
          type: '@type',
          tags: '@tags'     // #exemple
        }
      },
      deleteTagInContenu: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DELETE_TAGS_IN_CONTENU,
        params: {
          contenu: '@contenu',  // idContenu
          type: '@type',
          tag: '@tag'     // #exemple
        }
      },
      deleteTagInTopic: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DELETE_TAGS_IN_TOPIC,
        params: {
          topic: '@topic',  // idContenu
          type: '@type',
          tag: '@tag'     // #exemple
        }
      },
      deleteTagInDocument: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DELETE_TAGS_IN_DOCUMENT,
        params: {
          guid: '@guid',  // idContenu
          type: '@type',
          tag: '@tag'     // #exemple
        }
      }

    });
  }
  /* GedAjax */
  function GedAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {
    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_GED, null, {
      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      },
      get: {
        params: {
          numImmeuble: '@numImmeuble'
        }
      }
    });
  }

  /*
   var ContenuAjax = function (COLLAB_CONF,COLLAB_VALUES,ResourceFactory) {
   return ResourceFactory.createResource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_CONTENUS, {topic:'@topic'},{
   get: {
   url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_RECHERCHE_CONTENU,
   params: {
   contenu: '@contenu'
   }
   },

   //topic:'@topic',
   //objJSON: '@objJSON'
   save: {
   method:'POST',
   transformRequest: function(data){
   return $httpParamSerializerJQLike(data);
   },
   headers: {'Content-Type': 'application/x-www-form-urlencoded'},
   url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_INSERTION_CONTENU
   },
   update: {
   method:'POST',
   transformRequest: function(data){
   return $httpParamSerializerJQLike(data);
   },
   headers: {'Content-Type': 'application/x-www-form-urlencoded'},
   url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_UPDATE_CONTENU
   },
   lire: {
   method:'POST',
   url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_LECTURE_CONTENU,
   params: {
   'contenu[]':'@contenu'
   }
   },
   addToNonLu: {
   method:'POST',
   url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_INSERTION_LECTURE_CONTENU,
   params: {
   'contenu[]':'@contenu'
   }
   },
   addDestinataires: {
   method:'POST',
   url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_INSERTION_DESTINATAIRE_CONTENU,
   params: {
   'contenu[]': '@contenu',
   'destinataires[]':'@destinataires'
   }
   },
   addTags: {
   method:'POST',
   //transformRequest: function(data){
   //return $httpParamSerializerJQLike(data);
   //},
   //headers: {'Content-Type': 'application/x-www-form-urlencoded'},

   params: {
   contenu: '@contenu',  // idContenu
   type: '@type',
   tags: '@tags'     // #exemple
   },
   url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_INSERTION_TAGS
   },
   deleteTag: {
   method:'POST',
   url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_DELETE_TAGS_IN_CONTENU,
   params: {
   contenu: '@contenu',  // idContenu
   type: '@type',
   tag: '@tag'     // #exemple
   }
   }
   });
   };
   angular.module('collaboreApp')
   .factory('ContenuAjax', [
   'COLLAB_CONF',
   'ResourceFactory',
   ContenuAjax
   ]);*/

  /** @ngInject */
  function ComfactAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {
    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_COMFACT, null, {
      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      }
    });
  }

  /** @ngInject */
  function ContenuAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {
    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_CONTENU, {topic: '@topic'}, {

      post: {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        }
      },

      get: {
        params: {
          idContenu: '@idContenu',
          action: 'searchDetailContenu',
        }
      },

      updateContenu: {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        transformRequest: function (data) {
          data.action = 'update';
          return $httpParamSerializerJQLike(data);
        }
      },

      addDestinatairesToContenu: {
        method: 'POST',
        params: {
          action : 'addDestinatairesToContenu',
          idContenu: '@idContenu',
          'destinataires[]': '@destinataires'
        }
      },

      deleteDocumentByGuid: {
        method: 'POST',
        params: {
          action: 'deleteDocumentByGuid',
          idContenu: '@idContenu',
          guidDocument: '@guidDocument',
          guidDocumentFournisseur: '@guidDocumentFournisseur',
          guidDocumentComptable: '@guidDocumentComptable'
        }
      },
      /*
                  searchContenu: {
                      method: 'POST',
                      url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_SEARCH_CONTENU,
                      params: {
                          action: '@action',
                          'idTopic[]': '@idTopic',
                          'idContenu[]': '@idContenu'
                      }
                  },*/

      //topic:'@topic',
      //objJSON: '@objJSON'
      save: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_CONTENU
      },
      /*
      update: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_UPDATE_CONTENU
      },*/

      /*
      generateDemande: {
          method: 'POST',
          params: {
              regenerateDocument: true,
              idContenu: '@idContenu'
          },
          url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_UPDATE_CONTENU

      },
      generateAttestationTvaSimple: {
          method: 'POST',
          params: {
              regenerateDocumentAttestationTvaSimple: true,
              idContenu: '@idContenu'
          },
          url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_UPDATE_CONTENU

      },

      generateDeclarationSinistre: {
          method: 'POST',
          params: {
              regenerateDeclarationSinistre: true,
              idContenu: '@idContenu'
          },
          url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_UPDATE_CONTENU

      },*/

      lire: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_LECTURE_CONTENU,
        params: {
          'contenu[]': '@contenu'
        }
      },
      addToNonLu: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_LECTURE_CONTENU,
        params: {
          'contenu[]': '@contenu'
        }
      },
      addDestinataires: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_DESTINATAIRE_CONTENU,
        params: {
          'contenu[]': '@contenu',
          'destinataires[]': '@destinataires'
        }
      },
      addTags: {
        method: 'POST',
        //transformRequest: function(data){
        //  return $httpParamSerializerJQLike(data);
        //},
        //headers: {'Content-Type': 'application/x-www-form-urlencoded'},

        params: {
          contenu: '@contenu',  // idContenu
          type: '@type',
          tags: '@tags'     // #exemple
        },
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_TAGS
      },
      deleteTag: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DELETE_TAGS_IN_CONTENU,
        params: {
          contenu: '@contenu',  // idContenu
          type: '@type',
          tag: '@tag'     // #exemple
        }
      },
      updateEvenement: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_EVENEMENT,
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      }
    });
  }

  /** @ngInject */
  function MessageAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {
    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_MESSAGE, null, {
      // -- NEW --
      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      }
    });
  }

  /** @ngInject */
  function DemandeAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {
    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DEMANDE, null, {
      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      }
    });
  }

  /** @ngInject */
  function DevisAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {
    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DEVIS, null, {

      // --- NEW ---
      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      },

      // --- OLD ---
      /*
      createDevisInEvenement: {
          method: 'POST',
          headers: {'Content-Type': 'application/x-www-form-urlencoded'},
          transformRequest: function (data) {
              data.action = 'createDevisInEvenement';
              // idEvenement
              // json
              return $httpParamSerializerJQLike(data);
          }
      },*/
      updateDevisWithProxyDevis: {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        transformRequest: function (data) {
          data.action = 'updateDevis';
          // idDevis
          // json
          return $httpParamSerializerJQLike(data);
        }
      }
    });
  }

  /** @ngInject */
  function CourrierAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {
    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_COURRIER, null, {
      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      }
    });
  }

  /** @ngInject */
  function EvenementAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_EVENEMENT, null, {

      // -- NEW --
      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      },
      update: {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        transformRequest: function (data) {
          data.action = 'update';
          return $httpParamSerializerJQLike(data);
        }
      },
      /*
      createDevis: {
          method: 'POST',
          transformRequest: function (data) {
              return $httpParamSerializerJQLike(data);
          },
          headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      },*/

      // -- OLD --
      updateEvenement: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      }
    });
  }

  /** @ngInject */
  function SinistreAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {
    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_SINISTRE, null, {
      // -- NEW --
      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      }
    });
  }

  /** @ngInject */
  function FactureAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {
    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_FACTURE, null, {
      // -- NEW --
      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      }
    });
  }

  /** @ngInject */
  function DocumentsAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_DOCUMENTS_PERSO, null, {

      post: {
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DOCUMENT,
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      },

      getDocSociete: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_DOCUMENTS_PERSO,
        params: {
          type: 1
        }
      },

      // Force le download d'un document
      download: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DOCUMENTS_DOWNLOAD,
        params: {
          'guids[]': '@guids',
          type: 0
        }
      },

      // Stream un document
      stream: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DOCUMENTS_DOWNLOAD,
        params: {
          'guids[]': '@guids',
          type: 1
        }
      },

      // G�n�re un PDF de l'�v�nement du contenu
      /*streamPdfEvenement: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_TELECHARGEMENT_EVENEMENT_PDF,
        params: {
          idContenu: '@idContenu'
        }
      },*/
      rechercheByTags: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_DOCUMENTS_PERSO,
        params: {
          'tags[]': '@tags',     // #exemple
          type: '@type'
        }
      },

      addTags: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_TAGS_IN_DOCUMENT,
        params: {
          guid: '@guid',  // guid
          type: '@type',
          tags: '@tags'     // #exemple
        }
      },
      deleteTag: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DELETE_TAGS_IN_DOCUMENT,
        params: {
          guid: '@guid',  // idContenu
          tag: '@tag'     // #exemple
        }
      },
      /*
       sendFile: {
       method: 'POST',
       url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_INSERTION_DOCUMENTS_IN_GROUPE,
       params:{
       fileName: '@fileName',
       idGroupe: '@idGroupe'
       }
       },*/
      updateNewDescription: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_UPDATE_DESCRIPTION_DOCUMENT,
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        /*
         params:{
         guid:'@guid',
         description: '@description'
         }*/
      },
      changeSharing: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_DOCUMENTS_IN_SOCIETE,
        params: {
          'guids[]': '@guids',
          idGroupe: '@idGroupe'
        }
      },
      deleteFilePerso: {
        method: 'POST',
        //headers: {'Content-Type': undefined},
        //transformRequest: angular.identity,
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DELETE_DOCUMENTS_IN_GROUPE,
        params: {
          guid: '@guid',
          idGroupe: '@idGroupe'
        }
      },

      deleteFileContenu: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DELETE_DOCUMENTS_IN_CONTENU,
        params: {
          guid: '@guid',
          idContenu: '@idContenu'
        }
      },


      getPDF: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DOCUMENTS_DOWNLOAD,
        //responseType: 'arraybuffer',
        responseType: 'blob',
        params: {
          'guids[]': '@guids',
          type: 1
        },
        transformResponse: function (data) {
          // Stores the ArrayBuffer object in a property called "data"
          return {data: data};
        }
      },

      signeDevis: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_SIGN_DOCUMENT
      }
    });
  }

  /** @ngInject */
  function TemporaryDocBoxAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_TEMP_DOC_BOX, null, {

      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      }
    });
  }


  /** @ngInject */
  function ParamsAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike, transformRequestAsFormPost) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_PORTEFEUILLE, null, {
      get: {
        method: 'GET',
        params: {
          //token: '@token',
          cle: '@cle'
        }
      },
      resetPassword: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RESET_PASSWORD,
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      },
      updateNewPassword: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_MODIFY_PASSWORD,
        params: {
          oldPassword: '@oldPassword',
          newPassword: '@newPassword'
        }
      },
      addNewPortefeuille: {
        method: 'POST',
        params: {
          //token: '@token',
          name: '@name',
          key: '@key'
        }
      },
      deleteThisPortefeuille: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DELETE_PORTEFEUILLE,
        params: {
          //token: '@token',
          id: '@id'
        }
      },

      addSignature: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_SIGNATURE
      },

      getSignature: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_SIGNATURE
      },

      addSmtp: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_SMTP
      },

      deleteEntete: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ENTETE_PORTEFEUILLE_INFOS_ADMIN
      },

      deleteLogoCollab: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_LOGO_COLLAB_ADMIN_SERVLET
      },

      getParametrage: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_PARAMETRAGE
      },
      updateParametrage: {
        method: 'POST',
        transformRequest: transformRequestAsFormPost, // Remplace $.param() et $httpParamSerializerJQLike permet de plus utiliser jQuery
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_UPDATE_PARAMETRAGE
      },

      deleteLogo: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_LOGO_COLLAB_ADMIN_SERVLET
      }
    });
  }

  /** @ngInject */
  function RecapMailAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {
    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECAP_MAIL, null, {
      getRecapMail: {
        method: 'GET',
        params: {
          dateDebut: '@dateDebut',
          dateFin: '@dateFin'
        },
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECAP_MAIL
      },

      resendMail: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_COMMUNICATION,
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        params: {
          type: 'resendMail'
        }
        /*
        params: {
          type: 'resendMail',
          'idRecapMail[]': '@idRecapMail'
        }*/
      },

      resendAllMail: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_COMMUNICATION,
        params: {
          type: 'resendAllMail'
        }
      }
    });
  }

  /** @ngInject */
  function ShootingMailAjax(COLLAB_CONF,COLLAB_VALUES, $resource) {
    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_SHOOTING_MAIL, null, {
      getRecapShootingMail: {
        method: 'GET',
        params: {
          action: 'listEmail',
          dateDebut: '@dateDebut',
          dateFin: '@dateFin',
          searchSujet: '@searchSujet',
          searchObjet: '@searchObjet',
          searchDestinataire: '@searchDestinataire',
          offset: '@offset',
          limit: '@limit',
        }
      },

      resendShootingMail: {
        method: 'POST',
        params: {
          action: 'resendEmail',
          'id[]': '@id'
        }
      },

      resendAllShootingMail: {
        method: 'GET',
        params: {
          action: 'resendAllEmail'
        }
      }
    });
  }

  /** @ngInject */
  function DashboardAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {
    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DASHBOARD, null, {
      post: {
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DASHBOARD,
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      },
      /**
       * Télécharge un fichier excel
       */
      downloadExcelReporting: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        transformResponse: function (data, headers) {
          return {
            response: new Blob([data], {
              type: COLLAB_CONF.CONTENT_TYPE_XLSX
            }),
            headers: headers()
          };
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded', accept: COLLAB_CONF.CONTENT_TYPE_XLSX},
        responseType: 'arraybuffer',
        cache: false,
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DASHBOARD
      },

      /*
      getStatistique: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_STATISTIQUE
      },*/
      getEventStatistique: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_EVENT_STATISTIQUE
      },

      getEventTagsType: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_EVENT_TAGS_TYPE
      }
    });
  }

  /** @ngInject */
  function CalculatriceAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {
    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_CALCULATRICE, null, {
      post: {
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_CALCULATRICE,
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      },
      /**
       * Télécharge un fichier excel
       */
      downloadExcel: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        transformResponse: function (data, headers) {
          return {
            response: new Blob([data], {
              type: COLLAB_CONF.CONTENT_TYPE_XLSX
            }),
            headers: headers()
          };
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded', accept: COLLAB_CONF.CONTENT_TYPE_XLSX},
        responseType: 'arraybuffer',
        cache: false,
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_CALCULATRICE
      },

      /*
      getStatistique: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_STATISTIQUE
      },*/
      getEventStatistique: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_EVENT_STATISTIQUE
      },

      getEventTagsType: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_EVENT_TAGS_TYPE
      }
    });
  }

  /** @ngInject */
  function RechercheAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {
    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_GENERAL, null, {
      get: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_COLLAB
      },
      rechercheSearchCollab: {
        method: 'GET',
        params: {
          q: '@q',
          tri: '@tri',
          type: '@type',
          page: '@page',
          nbpp: '@nbpp'
        },
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_COLLAB
      },
      rechercheSearchCollabByIdTopic: {
        method: 'GET',
        params: {
          idTopic: '@idTopic'
        },
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_COLLAB
      }
    });
  }

  /** @ngInject */
  function HistoriqueAvancementAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {
    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_HISTORIQUE_AVANCEMENT, null, {
      save: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_HISTORIQUE_AVANCEMENT
      }
    });
  }

  /** @ngInject */
  function FiltreTopicsAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {
    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_FILTRE_TOPICS, null, {
      addFiltre: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_FILTRE_TOPICS
      },
      deleteFiltre: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DELETE_FILTRE_TOPICS
      },
      updateFiltre: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_UPDATE_FILTRE_TOPICS
      }
    });
  }

  /** @ngInject */
  function SignatureAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_SIGNATURE, null, {

      addSignature: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_SIGNATURE
      },

      getSignature: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_SIGNATURE
      }
    });
  }

  /** @ngInject */
  function TamponAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_TAMPON, null, {

      addTampon: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_TAMPON
      },

      getTampons: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_TAMPON
      },

      deleteTampon: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DELETE_TAMPON
      }
    });
  }

  /** @ngInject */
  function GroupeAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_HIERARCHIE, null, {

      postToGroupeServlet: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_GROUPE
      },

      /**
       * nom=@1306_Tichadou%23TICHADOU
       */
      getHierachie: {
        method: 'GET',
        params: {
          nom: '@nom'
        }
      },

      /**
       * Recup�re la perso grace � un idGroupe
       */
      getPersonnalite: {
        method: 'GET',
        params: {
          groupe: '@groupe'
        },
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_PERSONNALITE
      },

      /**
       * Recup�re la liste des groupes parent d'un groupe/user
       */
      getGroupesParent: {
        method: 'GET',
        params: {
          idGroupe: '@idGroupe',
          withoutEnfantImmeuble: '@withoutEnfantImmeuble'
        },
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_GROUPE_PERE
      },

      /**
       * Recupère la lsite des groupes fils d'un groupe avec pagination
       */
      getGroupesFils: {
        method: 'GET',
        params: {
          idGroupe: '@idGroupe',
          page: '@page',
          limit: '@limit'
        },
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_GROUPE_FILS
      },

      /**
       * Recupère la liste des groupes expediteur
       */
      getListGroupeExpediteur: {
        method: 'GET',
        params: {
          action: 'getListGroupeExpediteur'
        },
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_GROUPE
      },

      /**
       * Permet de recupérer un groupe avec son idGroupe si le groupe qui demande peux le voir
       */
      getGroupe: {
        method: 'GET',
        params: {
          action: 'getInfosGroupe',
          idGroupe: 'idGroupe'
        },
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_GROUPE
      }
    });
  }

  /*
   angular.module('collaboreApp').factory('GroupeAjax', [
   'COLLAB_CONF',
   '$resource',
   function (COLLAB_CONF,COLLAB_VALUES,$resource) {

   return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_RECHERCHE_HIERARCHIE, null, {
   //nom=@1306_Tichadou%23TICHADOU
   getHierachie: {
   method:'GET',
   url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_RECHERCHE_HIERARCHIE
   }
   });
   }
   ]);
   */

  /** @ngInject */
  function PersonnaliteAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_PERSONNALITE, null, {

      /**
       * Recup�re la perso par idGroupe ou un string
       */
      getPersonnalite: {
        method: 'GET',
        params: {
          groupe: '@groupe',
          recherche: '@recherche',
          type: '@type',
          avecemail: '@avecemail'
        }
      },

      /**
       * Recup�re les fournisseur par un string
       */
      getPersonnaliteFournisseur: {
        method: 'GET',
        params: {
          codemetier: '@codemetier',
          recherche: '@recherche',
          type: 'F',
          avecemail: '@avecemail'
        }
      },

      /**
       * Recup�re les perso SRV1 et collab
       */
      getCollaborateur: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_COLLABORATEUR
        /*
         params: {
         type: '@type',

         recherche: '@recherche',
         numFournisseur: '@numFournisseur',
         numImmeuble: '@numImmeuble',
         noperso: '@noperso',

         codemetier: '@codemetier',
         //collab: '@collab',
         usersCollab: '@usersCollab',
         groupesCollab: '@groupesCollab',
         avecemail: '@avecemail',
         avecGroupesFils: '@avecGroupesFils',
         'idPortefeuilles[]': '@idPortefeuilles',
         clePortefeuille: '@clePortefeuille',

         fortype: '@fortype',
         filtreParPortefeuille: '@filtreParPortefeuille'
         }*/
      },

      /**
       * Recupère les lots d'une perso propriétaire
       */
      getLots: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_LOTS,
        params: {
          noperpro: '@noperpro'
        }
      },

      /**
       * Recupère les inverse d'une perso
       */
      getInverses: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_INVERSE,
        params: {
          noperso: '@noperso',
          iiii: '@iiii',
          typeRecherche: '@typeRecherche',
          idPortefeuille: '@idPortefeuille'
        }
      },

      getBaux: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_BAILS,
        params: {
          mmmm: '@mmmm',
          iiii: '@iiii',
          llll: '@llll',
          idPortefeuille: '@idPortefeuille'
        }
      }
    });
  }

  /** @ngInject */
  function AdminAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_TAMPON, null, {

      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMIN_GROUPE
      },

      /**
       * Ajoute un groupe dans un groupe
       */
      addGroupe: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_GROUPE_IN_GROUPE_ADMIN
      },

      /**
       * Supprime un groupe d'un groupe
       */
      deleteGroupeFromGroupe: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DELETE_GROUPE_FROM_GROUPE_ADMIN
      },

      /**
       * D�place un groupe d'un groupe
       */
      moveGroupeToGroupe: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_UPDATE_GROUPE_FROM_GROUPE_ADMIN
      },

      /**
       * Permet de recupérer tous les portefeuilles
       */
      getAllPortefeuilles: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_PORTEFEUILLE_ADMIN
      },

      /**
       * Ajoute un portefeuille
       */
      addPortefeuille: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_PORTEFEUILLE_ADMIN
      },

      /**
       * Ajoute un portefeuille dans un groupe
       */
      addPortefeuilleInGroupe: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_PORTEFEUILLE_FROM_GROUPE_ADMIN
      },

      /**
       * Modifie un portefeuille
       */
      updatePortefeuille: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_UPDATE_PORTEFEUILLE_ADMIN
      },

      /**
       * Supprime un portefeuille
       */
      deletePortefeuille: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DELETE_PORTEFEUILLE_ADMIN
      },

      /**
       * Supprime un portefeuille d'un groupe
       */
      deletePortefeuilleFromGroupe: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DELETE_PORTEFEUILLE_FROM_GROUPE_ADMIN
      },

      /**
       * Recup�re la liste des groupes/portefeuilles
       */
      getHierachie: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_HIERACHIE_ADMIN/*,
                 params:{
                 type: '1'
                 }*/
      },

      /**
       * Recup�re la liste des type de modules dispo
       */
      getListeTypeModule: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_LIST_TYPE_MODULE_ADMIN
      },

      /**
       * Recup�re la liste des modules d�j� cr��
       */
      getListeModule: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_LIST_MODULE_ADMIN
      },

      saveModuleAdmin: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_MODULE_ADMIN
      },

      deleteModuleAdmin: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DELETE_MODULE_ADMIN
      },

      addModuleInGroupe: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_MODULE_IN_GROUPE_ADMIN
      },

      /**
       * Permet de recupérer tous les users
       */
      getAllUsers: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_USER_ADMIN
      },

      getParametrage: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_PARAMETRAGE_ADMIN
      }
      /*
       addParametrage: {
       method:'GET',
       url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT+COLLAB_CONF.SERVLET_INSERTION_PARAMETRAGE_ADMIN
       },

       updateParametrage: {
       method: 'GET',
       url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_UPDATE_PARAMETRAGE_ADMIN
       }*/
    });
  }

  /** @ngInject */
  function FicheAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ANNUAIRE_PERSO, null, {

      addFiche: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_FICHE
      },

      updateFiche: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_UPDATE_FICHE
      },

      deleteFiche: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DELETE_FICHE
      },

      deleteRenseignement: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DELETE_RENSEIGNEMENT_FROM_FICHE
      },

      postToFicheServlet: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_FICHE
      }

    });
  }

  /** @ngInject */
  function ChannelAjax(COLLAB_CONF,COLLAB_VALUES, $resource) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_CHANNEL, null, {

      /**
       * Recupère les channels
       */
      getChannels: {
        method: 'GET',
        params: {
          type: '@type'
        },
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RECHERCHE_CHANNEL
      },

      /**
       * Ajoute un channel ou un groupe à un channel
       */
      addChannel: {
        method: 'GET',
        params: {
          type: '@type',
          idGroupe: '@idGroupe',
          idChannel: '@idChannel'
        },
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_CHANNEL
      },

      /**
       * Supprime un groupe d'un channel ou le channel complet avec tous ses groupes
       */
      deleteChannel: {
        method: 'GET',
        params: {
          idGroupe: '@idGroupe',
          idChannel: '@idChannel'
        },
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DELETE_CHANNEL
      }

    });
  }

  /** @ngInject */
  function CommunicationAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_COMMUNICATION, null, {

      // --- NEW ---
      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_COMMUNICATION_COLLAB
      },

      // --- OLD ---
      sendFax: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_COMMUNICATION,
        params: {
          type: 'fax',
          idContenu: '@idContenu',
          'numeros[]': '@numeros'
        }
      },

      sendSms: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_COMMUNICATION,
        params: {
          type: 'sms',
          idContenu: '@idContenu',
          'numeros[]': '@numeros'
        }
      }
    });
  }

  /** @ngInject */
  function AdminCollabAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {

    return $resource(null, null, {

      postGroupeSuperAdmin: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_GROUPE
      },

      getGroupesAll: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_GROUPE,
        params: {
          action: 'getGroupesAll'
        }
      },
      searchGroupesAll: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_GROUPE,
        params: {
          action: 'searchGroupesAll',
          search: '@search'
        }
      },
      searchGroupesPortefeuille: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_GROUPE,
        params: {
          action: 'searchGroupesPortefeuille',
          idGroupe: '@idGroupe'
        }
      },
      getGroupesPortefeuille: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_GROUPE,
        params: {
          action: 'getGroupesPortefeuille',
          diese: '@diese'
        }
      },
      getGroupesUser: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_GROUPE,
        params: {
          action: 'getGroupesUser',
          diese: '@diese'
        }
      },
      getHierarchieGroupesNonUserForSociete: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_GROUPE,
        params: {
          action: 'getHierarchieGroupesNonUserForSociete',
          idGroupe: '@idGroupe',
          diese: '@diese'
        }
      },


      changePrivilegeForGroupe: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_GROUPE,
        params: {
          action: 'changePrivilegeForGroupe',
          idGroupe: '@idGroupe',
          newPrivilege: '@newPrivilege'
        }
      },

      changeEtatForGroupe: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_GROUPE,
        params: {
          action: 'changeEtatForGroupe',
          idGroupe: '@idGroupe',
          newEtat: '@newEtat'
        }
      },

      changeSmtpForGroupe: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_GROUPE,
        params: {
          action: 'changeSmtpForGroupe'
        }
      },

      updateGroupeUser: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_GROUPE,
        timeout: 1800000 // 30min
      },

      sendEmailTest: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_GROUPE,
        params: {
          action: 'sendEmailTest'
        }
      },

      resetSmtpForGroupe: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_GROUPE,
        params: {
          action: 'resetSmtpForGroupe',
          idGroupe: '@idGroupe'
        }
      },

      addUtilisateurs: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_CREATION_UTILISATEUR,
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      },

      /**
       * Ajoute un groupe dans un gorupe
       */
      addGroupeInGroupe: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_GROUPE,
        params: {
          action: 'addGroupeInGroupe',
          idGroupe: '@idGroupe',
          idGroupeParent: '@idGroupeParent',
          dontAssignTopic: '@dontAssignTopic',
          assignTopicCloture: '@assignTopicCloture'
        }
      },

      /**
       * Ajoute les dossiers cloturé
       */
      assignTopicCloture: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_GROUPE,
        params: {
          action: 'assignTopicCloture',
          idGroupe: '@idGroupe',
          idGroupeParent: '@idGroupeParent'
        }
      },

      /**
       * Ajoute les dossiers non cloturé
       */
      assignTopicNonCloture: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_GROUPE,
        params: {
          action: 'assignTopicNonCloture',
          idGroupe: '@idGroupe',
          idGroupeParent: '@idGroupeParent'
        }
      },

      /**
       * Supprime un groupe d'un groupe
       */
      removeGroupeFromGroupe: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_GROUPE,
        params: {
          action: 'removeGroupeFromGroupe',
          idGroupe: '@idGroupe',
          idGroupeParent: '@idGroupeParent'
        }
      },

      /**
       * Recupère une liste de nombre de recap mail en attente par société
       */
      getStatsRecapMailParSociete: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_RECAPMAIL,
        params: {
          action: 'getStatsRecapMailParSociete'
        }
      },

      /**
       * Recupère une liste de recap mail pour une societe
       * etat : 0 = attente
       * 1 = envoyé
       * 2 = Erreur EmailTemporaire
       * -1 = Erreur
       * -2 = Erreur email yopmail trop long
       */
      getListRecapMailForGroupe: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_RECAPMAIL,
        params: {
          action: 'getListRecapMailForGroupe',
          etat: '@etat',
          'idGroupeAll[]': '@idGroupeAll'
        }
      },

      resendMailForSociete: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_RECAPMAIL,
        params: {
          action: 'resendMailForSociete',
          'idGroupeAll[]': '@idGroupeAll'
        }
      },

      resendMailForIdRecapMail: {
        method: 'POST',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMINCOLLAB_RECAPMAIL,
        params: {
          action: 'resendMailForIdRecapMail',
          'idRecapMail[]': '@idRecapMail'
        }
      },

      /**
       * Recupère le nombre de topic par diese
       */
      getNbTopicByDiese: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_STATS_COLLAB,
        params: {
          dateDu: '@dateDu',
          dateAu: '@dateAu'
        }
      }
    });
  }

  /** @ngInject */
  function RunJobResource(COLLAB_CONF,COLLAB_VALUES, $resource) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_RUN_JOB, null, {

      /**
       * Permet de relancer un Job pour la création ou modification d'un devis
       */
      runJobDevis: {
        method: 'GET',
        params: {
          action: 'runJobDevis',
          idDevis: '@idDevis',
          idContrat: '@idContrat'
        }
      }
    });
  }

  /** @ngInject */
  function AdminTopicAjax(COLLAB_CONF,COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMIN_TOPIC, null, {
      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ADMIN_TOPIC
      },
    });
  }


})();
