(function() {
	'use strict';

	angular.module('collaboreApp').factory('TypeChannel',TypeChannelModel);

    /** @ngInject */
	function TypeChannelModel(COLLAB_CONF){

		function TypeChannel(objetData){
			this.setData(objetData);
		}

		TypeChannel.prototype = {

			isModel: true,

			setData: function(objetData) {
				angular.extend(this, objetData);
			},

			getIdTypeChannel: function(){
				return this.idTypeChannel;
			},
			setIdTypeChannel: function(id){
				this.idTypeChannel = id;
			},

			getLibelle: function(){
				return this.libelle;
			},
			setLibelle: function(nb){
				this.libelle = nb;
			},

			isAccueil: function(){
				if(this.getIdTypeChannel() === COLLAB_CONF.CHANNEL_TYPE_ACCUEIL){
					return true;
				}
				return false;
			},

			isChannel: function(){
				if(this.getIdTypeChannel() === COLLAB_CONF.CHANNEL_TYPE_CHANNEL){
					return true;
				}
				return false;
			}
		};


		return TypeChannel;
	}

})();