(function () {
	'use strict';

	angular
		.module('collaboreApp')
		.factory('GroupeForAdmin',
			GroupeForAdminModel
		);
	/** @ngInject */
	function GroupeForAdminModel(Main, Groupe) {

		function GroupeForAdmin(objetData) {
			Main.apply(this, arguments);
			Groupe.apply(this, arguments);
			this.hydrate(objetData);
		}

		GroupeForAdmin.prototype = {

			isGroupeForAdmin: true,
			model: 'GroupeForAdmin',

			// MdpClaire
			getMdpClaire: function () {
				return this.mdpClaire;
			},
			setMdpClaire: function (valMdp) {
				this.mdpClaire = valMdp;
			},

			getNewEtat: function() {
				return this.newEtat;
			},
			setNewEtat: function(val) {
				this.newEtat = val;
			},
			setNewEtatActif: function() {
				this.newEtat = Groupe.User.refEtats.actif.structure;
			},
			setNewEtatInvite: function() {
				this.newEtat = Groupe.User.refEtats.invite.structure;
			},
			setNewEtatSupprime: function() {
				this.newEtat = Groupe.User.refEtats.supprime.structure;
			},
			isNewEtatActif: function () {
				return (this.getNewEtat() === Groupe.User.refEtats.actif.structure);
			},
			isNewEtatInvite: function () {
				return (this.getNewEtat() === Groupe.User.refEtats.invite.structure);
			},
			isNewEtatSupprime: function () {
				return (this.getNewEtat() === Groupe.User.refEtats.supprime.structure);
			},

			getNewPrivilege: function() {
				return this.newPrivilege;
			},

			setNewPrivilege: function(val) {
				this.newPrivilege = val;
			},
			setNewPrivilegeMembre: function() {
				this.newPrivilege = Groupe.User.refPrivileges.$membre.structure;
			},
			setNewPrivilegeAdmin: function() {
				this.newPrivilege = Groupe.User.refPrivileges.$admin.structure;
			},
			setNewPrivilegeSuperAdmin: function() {
				this.newPrivilege = Groupe.User.refPrivileges.$superadmin.structure;
			},
      setNewPrivilegeDirectionCamping: function() {
        this.newPrivilege = Groupe.User.refPrivileges.$direction_camping.structure;
      },
      setNewPrivilegeAccueilCamping: function() {
        this.newPrivilege = Groupe.User.refPrivileges.$accueil_camping.structure;
      },
      setNewPrivilegeDirectionCapfun: function() {
        this.newPrivilege = Groupe.User.refPrivileges.$direction_capfun.structure;
      },
      setNewPrivilegeSiegeCapfun: function() {
        this.newPrivilege = Groupe.User.refPrivileges.$siege_capfun.structure;
      },

			isNewPrivilegeMembre: function () {
				return (this.getNewPrivilege() === Groupe.User.refPrivileges.$membre.structure);
			},
			isNewPrivilegeAdmin: function (checkSuperAdmin) {

				if (this.getNewPrivilege() === Groupe.User.refPrivileges.$admin.structure) {
					return true;
				}
				if (checkSuperAdmin && this.getNewPrivilege() === Groupe.User.refPrivileges.$superadmin.structure) {
					return true;
				}
				return false;
			},
			isNewPrivilegeSuperAdmin: function () {
				return (this.getNewPrivilege() === Groupe.User.refPrivileges.$superadmin.structure);
			},
      isNewPrivilegeAccueilCamping: function () {
        return (this.getNewPrivilege() === Groupe.User.refPrivileges.$accueil_camping.structure);
      },
      isNewPrivilegeDirectionCamping: function () {
        return (this.getNewPrivilege() === Groupe.User.refPrivileges.$direction_camping.structure);
      },
      isNewPrivilegeDirectionCapfun: function () {
        return (this.getNewPrivilege() === Groupe.User.refPrivileges.$direction_capfun.structure);
      },
      isNewPrivilegeSiegeCapfun: function () {
        return (this.getNewPrivilege() === Groupe.User.refPrivileges.$siege_capfun.structure);
      }

		};

		angular.extend(GroupeForAdmin.prototype, Main.prototype);
		angular.extend(GroupeForAdmin.prototype, Groupe.prototype);

		return GroupeForAdmin;
	}
})();
