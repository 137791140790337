(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .factory('GroupeAssociationResource', GroupeAssociationResource);

  /** @ngInject */
  function GroupeAssociationResource(COLLAB_CONF, COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_GROUPE_ASSOCIATION, null, {

      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      },

      /**
       * Recupère une liste de GroupeAssociationImmeuble pour un idPortfeuille et le gorupe identifié par le token
       */
      /*
      searchListGroupeAssociationImmeuble: {
        method: 'GET',
        params: {
          action: 'searchListGroupeAssociationImmeuble',
          idPortefeuille: '@idPortefeuille',
        }
      },*/

      /**
       * Recupère une liste de GroupeAssociationProprietaire pour un idPortfeuille et le gorupe identifié par le token
       */
      /*
      searchListGroupeAssociationProprietaire: {
        method: 'GET',
        params: {
          action: 'searchListGroupeAssociationProprietaire',
          idPortefeuille: '@idPortefeuille',
        }
      },*/

      /**
       * Permet de rechercher une litse de groupe association
       */
      searchListGroupeAssociation: {
        method: 'GET',
        params: {
          action: 'searchListGroupeAssociation',
          idPortefeuille: '@idPortefeuille',
          searchTypeAssociation: '@searchTypeAssociation',
          searchValGroupeAssocie: '@searchValGroupeAssocie',
        }
      },
    });
  }
})();
