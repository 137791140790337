(function () {

  'use strict';

  /**
   * @desc Composant AdminCollab
   * @example <admin-collab-mailbox></admin-collab-mailbox>
   */
  angular
    .module('collaboreApp')
    .component('adminCollabMailbox', {
      templateUrl: 'app/admin/mailbox/admin-collab-mailbox.component.html',
      controllerAs: 'admincollabmailboxctrl',
      controller: AdminCollabMailboxCtrl
    });

  /** @ngInject */
  function AdminCollabMailboxCtrl() {
    var ctrl = this;

    ctrl.$onInit = function () {

    };
  }
})();
