(function () {
  "use strict";

  angular.module("collaboreApp").component("comfactFacture", {
    templateUrl: "app/topics/comfact/comfact-facture.component.html",
    controller: ComfactFactureController,
    controllerAs: "comfactfacturectrl",
    bindings: {
      idTopic: "<",
      topicInformations: "<",
      idDevis: "<",
      facture: "<",
      persoIcsDevis: "<",
      destinataireDevis: "<",
      tagsType: "<",
      isIntervention: "<",
      objHasDocuments: "<",
      showCloture: "<",
      isCloture: "<",
      paramsComfactfacture: "<",
      documentFacture: "<",
      typeComfact: "@",
      onSubmit: "&",
      onEmit: "&",

      // Si Modal
      resolve: "<",
      close: "&",
      dismiss: "&",
    },
  });

  /** @ngInject */
  function ComfactFactureController(
    COLLAB_CONF,
    $q,
    $scope,
    $timeout,
    ContratIcsCloudService,
    OcrFrontService,
    TagService,
    ContenuService,
    UtilsService,
    ModalsService,
    ComfactService,
    ErreurCollabService
  ) {
    var ctrl = this;
    /*
    var orderTab = {
      1: 'portefeuilleCollab',
      2: 'portefeuilleComfact',
      3: 'typeComfactFacture',
      4: 'comptable',
      5: 'fournisseur',
      6: 'dateFacture',
      7: 'numFacture',
      8: 'immeuble',
      9: 'numMandat',
      10: 'numLot',
      11: 'montantHT',
      12: 'montantTVA',
      13: 'montantTTC',
      14: 'commentaire',
      15: 'radioPostit',
      16: 'radioOnlyFacture',
      17: 'checkboxBap',
      18: 'nomPdf'
    };*/

    ctrl.ocrEnabled = UtilsService.getParametrageComfactSociete("ocrEnabled");
    ctrl.saisieComptesSCOEnabled = UtilsService.getParametrageComfactSociete(
      "saisieComptesSCOEnabled"
    );
    ctrl.choiceAccountantRequired = UtilsService.getParametrageComfactSociete(
      "choiceAccountantRequired"
    );

    ctrl.affectationComptesComponentReady = false;

    ctrl.datePickerOptions = {
      maxDate: new Date(),
      startingDay: 1,
      showWeeks: true,
    };

    ctrl.isReady = false;
    ctrl.portefeuilleCollab = null;
    ctrl.objFournisseur = null;
    ctrl.objImmeuble = null;
    ctrl.objContrat = null;
    ctrl.showNomPdf = false;
    ctrl.nomPdfTouched = false;
    ctrl.erreurComfact = null;

    ctrl.loadingImmeuble = false;
    ctrl.allowToEditImmeuble = true;
    ctrl.suggestionsImmeuble = [];

    ctrl.fournisseurFromOCR = null;
    ctrl.fournisseurFromDestinataire = null;
    ctrl.loadingFournisseur = false;
    ctrl.baseSuggestionsFournisseur = [];
    ctrl.suggestionsFournisseur = [];

    ctrl.loadingContrat = false;
    ctrl.suggestionsContrat = [];

    ctrl.allowToEditMandat = true;
    ctrl.allowToEditLot = true;

    ctrl.onKeyDown = onKeyDown;
    ctrl.onFocusField = onFocusField;
    ctrl.onChangePortefeuilleCollab = onChangePortefeuilleCollab;
    ctrl.onChangeType = onChangeType;
    ctrl.onClickSendToComfact = onClickSendToComfact;
    ctrl.onClickSendAndCloture = onClickSendAndCloture;
    ctrl.onClickCloturer = onClickCloturer;
    ctrl.onChangeNomPdf = onChangeNomPdf;
    ctrl.onChangeDocuments = onChangeDocuments;
    ctrl.onClickClose = onClickClose;
    ctrl.onClickSwitchMontantTTC = onClickSwitchMontantTTC;
    ctrl.onEmitFromChooseFournisseur = onEmitFromChooseFournisseur;
    ctrl.onEmitFromChooseContrat = onEmitFromChooseContrat;
    ctrl.onEmitFromChooseImmeuble = onEmitFromChooseImmeuble;
    ctrl.onEmitFromDocumentOcrManager = onEmitFromDocumentOcrManager;
    ctrl.onEmitFromAffectationComptesComponent =
      onEmitFromAffectationComptesComponent;

    ctrl.$onInit = function () {
      if (ctrl.resolve) {
        ctrl.isModal = true;
        ctrl.idTopic = angular.copy(
          UtilsService.getParamModalComponent(ctrl.resolve, "idTopic")
        );
        ctrl.topicInformations = angular.copy(
          UtilsService.getParamModalComponent(ctrl.resolve, "topicInformations")
        );
        ctrl.idDevis = angular.copy(
          UtilsService.getParamModalComponent(ctrl.resolve, "idDevis")
        );
        ctrl.facture = angular.copy(
          UtilsService.getParamModalComponent(ctrl.resolve, "facture")
        );
        ctrl.persoIcsDevis = angular.copy(
          UtilsService.getParamModalComponent(ctrl.resolve, "persoIcsDevis")
        );
        ctrl.destinataireDevis = angular.copy(
          UtilsService.getParamModalComponent(ctrl.resolve, "destinataireDevis")
        );
        ctrl.tagsType = angular.copy(
          UtilsService.getParamModalComponent(ctrl.resolve, "tagsType")
        );
        ctrl.isIntervention = angular.copy(
          UtilsService.getParamModalComponent(ctrl.resolve, "isIntervention")
        );
        ctrl.objHasDocuments = angular.copy(
          UtilsService.getParamModalComponent(ctrl.resolve, "objHasDocuments")
        );
        ctrl.showCloture = angular.copy(
          UtilsService.getParamModalComponent(ctrl.resolve, "showCloture")
        );
        ctrl.isCloture = angular.copy(
          UtilsService.getParamModalComponent(ctrl.resolve, "isCloture")
        );
        ctrl.documentFacture = angular.copy(
          UtilsService.getParamModalComponent(ctrl.resolve, "documentFacture")
        );
      }

      if (!_.isObject(ctrl.documentFacture) && !_.isObject(ctrl.facture))
        ModalsService.alertErreur("Il manque la facture");


      ctrl.searchImmeuble = null;
      ctrl.searchCompte = null;

      ctrl.listPortefeuilleCollab = null;
      ctrl.portefeuille = null;
      ctrl.configs = [];
      ctrl.comptable = null;
      ctrl.comptables = [];

      ctrl.retourModal = {
        type: "2",
        portefeuille: null,
        nomPdf: "",
        comptable: null,
        commentaire: null,
        typeDocument: "onlyFacture",
        bap: false,
        typeCommentaire: "postit",
        fournisseur: null,
        contrat: null,
        comptes: [],
      };

      ctrl.groupeImmeuble = null;
      if (_.isObject(ctrl.topicInformations)) {
        if (_.isObject(ctrl.topicInformations.getGroupeImmeuble())) {
          ctrl.groupeImmeuble = ctrl.topicInformations.getGroupeImmeuble();
          ctrl.searchImmeuble = angular.copy(ctrl.groupeImmeuble);
          if(_.isObject(ctrl.groupeImmeuble.groupeInformations)){
            setImmeuble({
              immeuble: ctrl.groupeImmeuble.groupeInformations.numero,
              libelleImmeuble: ctrl.groupeImmeuble.groupeInformations.nom
            });
          }
        }
      }
      if (_.isNil(ctrl.searchImmeuble) && !_.isNil(ctrl.retourModal.immeuble)) {
        ctrl.searchImmeuble = angular.copy(ctrl.retourModal.immeuble);
        setImmeuble({
          immeuble: ctrl.retourModal.immeuble
        });
      }

      ctrl.retourModal.nomPdf = genereNomPdf();

      var listPort = UtilsService.getCurrentUser().getListePortefeuille();
      var callConfigs = true;
      var callUsers = true;
      var paramsInitPortefeuilleAndComptablesComfact = null;

      if (_.isObject(ctrl.facture)) {
        ctrl.documentFacture = ctrl.facture.getDocumentFacture();
        ctrl.retourModal.idDevis = ctrl.idDevis;

        var tagImmeuble = TagService.getTagForType(
          ctrl.tagsType,
          COLLAB_CONF.TAG_TYPE_IMMEUBLE
        );
        if (tagImmeuble) {
          ctrl.retourModal.immeuble = tagImmeuble.getValeurIcs();
          if(ctrl.typeComfact == 'collab') ctrl.allowToEditImmeuble = false;
        }

        var tagMandat = TagService.getTagForType(
          ctrl.tagsType,
          COLLAB_CONF.TAG_TYPE_MANDAT
        );
        if (tagMandat) {
          ctrl.retourModal.mandat = tagMandat.getValeurIcs();
          if (
            _.isString(ctrl.retourModal.mandat) &&
            ctrl.retourModal.mandat.length > 4
          ) {
            ctrl.retourModal.mandat = _.trimStart(tagMandat.getLibelle(), "#");
          }
        }

        var tagLot = TagService.getTagForType(
          ctrl.tagsType,
          COLLAB_CONF.TAG_TYPE_LOT
        );
        if (tagLot) {
          ctrl.retourModal.lot = tagLot.getValeurIcs().substr(-4);
        }

        var tagPortefeuille = TagService.getTagForType(
          ctrl.tagsType,
          COLLAB_CONF.TAG_TYPE_PORTEFEUILLE
        );
        if (tagPortefeuille && tagPortefeuille.getPortefeuille()) {
          ctrl.portefeuilleCollab = tagPortefeuille.getPortefeuille();
          if (
            _.isObject(ctrl.portefeuilleCollab) &&
            _.isNil(ctrl.portefeuilleCollab.cle) &&
            _.isArray(listPort)
          ) {
            for (var p = 0; p < listPort.length; p++) {
              if (
                listPort[p].idPortefeuille ===
                ctrl.portefeuilleCollab.idPortefeuille
              ) {
                ctrl.portefeuilleCollab = listPort[p];
                break;
              }
            }
          }
        }

        // Si il y a un tag "Propriétaire" alors on préselectionne "Gestion"
        if (
          TagService.hasTagType(
            ctrl.tagsType,
            COLLAB_CONF.TAG_TYPE_PROPRIETAIRE
          )
        ) {
          ctrl.retourModal.type = "1";
          if(ctrl.typeComfact == 'collab'){
            if(!_.isNil(ctrl.retourModal.mandat)) ctrl.allowToEditMandat = false;
            if(!_.isNil(ctrl.retourModal.lot)) ctrl.allowToEditLot = false;
          }
        }

        if (
          _.isObject(ctrl.persoIcsDevis) ||
          (_.isObject(ctrl.destinataireDevis) &&
            ctrl.destinataireDevis.isGroupe)
        ) {
          var persoICS = ctrl.persoIcsDevis;
          if (_.isNil(persoICS))
            persoICS = ctrl.destinataireDevis.getLastPersoICS();
          if (
            _.isObject(persoICS) &&
            !_.isNil(persoICS.getCodemetier()) &&
            !_.isNil(persoICS.getNumero())
          ) {
            ctrl.retourModal.fournisseur = persoICS.getCodemetier() + persoICS.getNumero();
            ctrl.fournisseurFromDestinataire = angular.copy(persoICS);
          }
        }

        if (
          _.isObject(ctrl.paramsComfactfacture) &&
          _.isObject(ctrl.portefeuilleCollab) &&
          ctrl.paramsComfactfacture.hasOwnProperty(
            ctrl.portefeuilleCollab.idPortefeuille
          )
        ) {
          var idPort = ctrl.portefeuilleCollab.idPortefeuille;
          if (ctrl.paramsComfactfacture[idPort].hasOwnProperty("configs")) {
            setPortefeuilles(ctrl.paramsComfactfacture[idPort].configs);
            callConfigs = false;
          }
          if (ctrl.paramsComfactfacture[idPort].hasOwnProperty("users")) {
            setComptables(ctrl.paramsComfactfacture[idPort].users);
            callUsers = false;
          }
        }

        paramsInitPortefeuilleAndComptablesComfact = {
          idDevis: ctrl.idDevis,
        };
      }
      else {
        ctrl.listPortefeuilleCollab = UtilsService.getCurrentUser().getListePortefeuille();
        if (
          _.isArray(ctrl.listPortefeuilleCollab) &&
          ctrl.listPortefeuilleCollab.length === 1
        ) {
          ctrl.portefeuilleCollab = angular.copy(
            ctrl.listPortefeuilleCollab[0]
          );

          paramsInitPortefeuilleAndComptablesComfact = {
            idPortefeuille: ctrl.portefeuilleCollab.idPortefeuille,
          };
        }
      }

      initComfactInfos();
      if(_.isObject(paramsInitPortefeuilleAndComptablesComfact)) {
        initPortefeuilleAndComptablesComfact(callConfigs, callUsers, paramsInitPortefeuilleAndComptablesComfact);
      }
      else ctrl.isReady = true;
    };

    ctrl.$onDestroy = function () {};

    function initPortefeuilleAndComptablesComfact(callConfigs, callUsers, obj) {
      ctrl.disabledSubmit = true;
      ctrl.isReady = false;
      var chain = $q.when();
      if (callConfigs)
        chain = chain.then(function () {
          return initPortefeuilles(obj);
        });
      if (callUsers)
        chain = chain.then(function () {
          return initComptables(obj);
        });
      chain = chain.then(function () {
        return initSuggestions();
      });

      chain.finally(function () {
        ctrl.isReady = true;
        ctrl.disabledSubmit = false;
      });
    }

    /**
     * Permet de récupèrer les configs (portefeuille)
     * @param idFacture
     */
    function initPortefeuilles(obj) {
      var deferred = $q.defer();

      ctrl.erreurComfact = null;
      if (
        !_.isObject(obj) ||
        ((_.isNil(obj.idDevis) || !obj.idDevis) &&
          (_.isNil(obj.idPortefeuille) || !obj.idPortefeuille))
      ) {
        setPortefeuilles([]);
        deferred.resolve();
      } else {
        ctrl.loadingPortefeuilleComfact = true;

        ComfactService.searchComfactConfigs(obj)
          .then(function (configs) {
            /**
             *  En fonction du retour, si il y en a plusieur, regarder le "type" de retourModal est à 2 (Syndic) alors prendre une config avec type à 2 (syndic pour la conf)
             *  si "type" de retourModal est à 1 (Gerance) alors prendre une conf avec type à 1 (gerance pour la conf)
             *  Si il y a qu'une seul config la prendre
             *  Config type 0 c'est gerance et syndic
             */
            setPortefeuilles(configs);
          })
          .catch(function (msg) {
            setPortefeuilles([]);
            ctrl.erreurComfact = msg;
          })
          .finally(function () {
            deferred.resolve();
            ctrl.loadingPortefeuilleComfact = false;
          });
      }

      return deferred.promise;
    }

    function setPortefeuilles(configs) {
      // Si il y a des config (portefeuilles)
      if (configs.length) {
        // Si il n'y a qu'une seule config on la récup
        if (configs.length === 1) ctrl.portefeuille = configs[0];
        // Si il y en a plusieurs on récupère celle qu'il faut selon le type (Gestion, Syndic ou les deux)
        else {
          for (var i = 0; i < configs.length; i++) {
            var config = configs[i];
            if (ctrl.retourModal.type === "2" && config.isSyndic())
              ctrl.portefeuille = config;
            else if (ctrl.retourModal.type === "1" && config.isGestion())
              ctrl.portefeuille = config;
            else if (
              ctrl.retourModal.type === "3" &&
              config.isSyndicAndGestion()
            )
              ctrl.portefeuille = config;
            if (ctrl.portefeuille !== null) break;
          }
        }
      } else ctrl.portefeuille = null;

      ctrl.configs = configs;

      ctrl.onEmit({
        obj: {
          idPortefeuille: _.isObject(ctrl.portefeuilleCollab)
            ? ctrl.portefeuilleCollab.idPortefeuille
            : null,
          configs: configs,
        },
      });
    }

    /**
     * Permet de récupèrer les users pour y récupèrer les comptables
     * @param idFacture
     * @param onlyComptable
     */
    function initComptables(obj) {
      var deferred = $q.defer();
      ctrl.comptablesErreur = null;
      if (
        !_.isObject(obj) ||
        ((_.isNil(obj.idDevis) || !obj.idDevis) &&
          (_.isNil(obj.idPortefeuille) || !obj.idPortefeuille))
      ) {
        setComptables([]);
        deferred.resolve();
      } else {
        ctrl.loadingComptablesComfact = true;
        ComfactService.searchComfactUsers(obj, true)
          .then(function (comptables) {
            setComptables(comptables);
          })
          .catch(function (msg) {
            setComptables([]);
            ctrl.comptablesErreur = msg;
          })
          .finally(function () {
            deferred.resolve();
            ctrl.loadingComptablesComfact = false;
          });
      }
      return deferred.promise;
    }

    function setComptables(comptables) {
      if (comptables.length === 1) ctrl.comptable = comptables[0];
      else ctrl.comptable = null;
      ctrl.comptables = comptables;
      ctrl.onEmit({
        obj: {
          idPortefeuille: _.isObject(ctrl.portefeuilleCollab)
            ? ctrl.portefeuilleCollab.idPortefeuille
            : null,
          users: comptables,
        },
      });
    }

    function initComfactInfos() {
      ctrl.retourModal.montantTTC = null;
      ctrl.retourModal.montantHT = null;
      ctrl.retourModal.montantTVA = null;
      ctrl.retourModal.dateFacture = null;
      ctrl.retourModal.numFacture = null;

      ctrl.montantTTC = {
        ocr: {
          libelle: "OCR",
          value: null,
        },
        facture: {
          libelle: "Montant Collab",
          value: null,
        },
        selected: {
          type: null,
          value: null,
        },
      };

      if (_.isObject(ctrl.facture)) {
        ctrl.montantTTC.facture.value = angular.copy(ctrl.facture.getMontant());
        if (!_.isNil(ctrl.facture.getMontant())) {
          ctrl.montantTTC.selected.type = "facture";
          ctrl.montantTTC.selected.value = angular.copy(
            ctrl.facture.getMontant()
          );
        }
      }

      if (_.isObject(ctrl.documentFacture)) {
        if (ctrl.documentFacture.documentOcrIsOk()) {
          var docOCR = ctrl.documentFacture.getDocumentOCR();
          if (!_.isNil(docOCR.getMontantTTC())) {
            ctrl.montantTTC.ocr.value = docOCR.getMontantTTC();
            ctrl.montantTTC.selected.type = "ocr";
            ctrl.montantTTC.selected.value = angular.copy(
              ctrl.montantTTC.ocr.value
            );
          }
          ctrl.retourModal.montantHT = docOCR.getMontantHT();
          ctrl.retourModal.montantTVA = docOCR.getMontantTVA();
          //if(!_.isNil(docOCR.getDateFacture())) ctrl.retourModal.dateFacture = new Date(docOCR.getDateFacture());
          if (!_.isNil(docOCR.getDateFacture())) {
            ctrl.retourModal.dateFacture = UtilsService.parseDate(
              docOCR.getDateFacture(),
              false
            );
          }
          ctrl.retourModal.numFacture = docOCR.getNumeroFacture();
          //if (_.isNil(ctrl.retourModal.fournisseur)) {
            var customerBankAccountNumber = null;
            if (
              _.isObject(docOCR.getReceiptBody()) &&
              _.isObject(docOCR.getReceiptBody().getReceiptData())
            ) {
              customerBankAccountNumber = !_.isEmpty(
                docOCR
                  .getReceiptBody()
                  .getReceiptData()
                  .getCustomerBankAccountNumber()
              )
                ? docOCR
                    .getReceiptBody()
                    .getReceiptData()
                    .getCustomerBankAccountNumber()
                : null;
            }
            if (
              !_.isNil(docOCR.getEmailFournisseur()) ||
              !_.isNil(docOCR.getPhoneFournisseur()) ||
              !_.isNil(docOCR.getCocNumber()) ||
              !_.isNil(docOCR.getVatNumber()) ||
              !_.isNil(docOCR.getNomFournisseur()) ||
              !_.isNil(customerBankAccountNumber)
            ) {
              ctrl.fournisseurFromOCR = {
                isOcr: true,
                email: docOCR.getEmailFournisseur(),
                phone: docOCR.getPhoneFournisseur(),
                cocNumber: docOCR.getCocNumber(),
                vatNumber: docOCR.getVatNumber(),
                name: docOCR.getNomFournisseur(),
                customerBankAccountNumber: customerBankAccountNumber
              };
            }
          //}
        }
      }
    }

    function initSuggestions() {
      var deferred = $q.defer();
      ctrl.suggestionsImmeuble = [];
      ctrl.baseSuggestionsFournisseur = [];
      ctrl.suggestionsFournisseur = [];
      ctrl.suggestionsContrat = [];

      var chain = $q.when();

      if (_.isObject(ctrl.fournisseurFromOCR) || _.isObject(ctrl.fournisseurFromDestinataire)){

        // Si il y a un fournisseur destinataire de la demande, oin l'ajout aux suggestions
        if(_.isObject(ctrl.fournisseurFromDestinataire)){
          ctrl.baseSuggestionsFournisseur = [ctrl.fournisseurFromDestinataire];
          setFournisseur(ctrl.fournisseurFromDestinataire);
        }

        if (_.isObject(ctrl.fournisseurFromOCR)) {
          chain = chain.then(function () {
            var deferredFournisseurOCR = $q.defer();
            ctrl.loadingFournisseur = true;
            callFournisseurOCRFront(ctrl.fournisseurFromOCR)
            .then(function (listPersonnalite) {
              if(listPersonnalite.length){
                ctrl.baseSuggestionsFournisseur = _.concat(ctrl.baseSuggestionsFournisseur, listPersonnalite);
                if(listPersonnalite.length === 1) setFournisseur(listPersonnalite[0]);
              }
            })
            .catch(function () {
              deferredFournisseurOCR.reject();
            })
            .finally(function () {
              ctrl.loadingFournisseur = false;
              deferredFournisseurOCR.resolve();
            });
            return deferredFournisseurOCR.promise;
          });
        }
      }

      chain = chain.then(function () {
        if(ctrl.baseSuggestionsFournisseur.length === 0) setFournisseur(null);
        else ctrl.suggestionsFournisseur = angular.copy(ctrl.baseSuggestionsFournisseur);
        return callContratICSCloud();
      });

      chain.finally(function () {

        deferred.resolve();
      });

      return deferred.promise;
    }

    function onChangePortefeuilleCollab() {
      setImmeuble(null);
      setFournisseur(null);
      setContrat(null);

      /**
       * Lors du changement de portefeuille collab, le portefeuille est modifié dans les enfant (choose-*)
       * choose-immeuble va clearImmeuble et faire remonter l'info à comfact-facture qui va clean les
       * suggestions fournisseurs et contrats
       */
      initPortefeuilleAndComptablesComfact(true, true, {
        idPortefeuille: _.isObject(ctrl.portefeuilleCollab)
          ? ctrl.portefeuilleCollab.idPortefeuille
          : null,
      });
    }

    function onChangeType() {
      $timeout(function () {
        $scope.$broadcast("clearSelectedFournisseur");
      });
    }

    function isGestion() {
      return !_.isNil(ctrl.retourModal.type) && ctrl.retourModal.type != "2";
    }

    /**
     * Lors du clique sur le bouton envoyer
     */
    function onClickSendToComfact() {
      sendToComfact()
        .then(function (devis) {
          ModalsService.success("Envoyée dans Comfact !");
          emit({ action: "sentToComfact", devis: devis });
        })
        .catch(function (msg) {
          ModalsService.alertErreur(msg);
        });
    }

    /**
     * Lors du clique sur le bouton cloturer
     */
    function onClickCloturer() {
      ContenuService.clotureOrReouvreEvenement({ idTopic: ctrl.idTopic })
        .then(function (contenu) {
          if (angular.isObject(contenu) && contenu.isModel) {
            ModalsService.success("Cloturé !");
            emit({ action: "cloture", contenu: contenu });
          }
        })
        .catch(function (msg) {
          ModalsService.alertErreur(msg);
        });
    }

    /**
     * Lors du clique sur le bouton envoyer et cloturer
     */
    function onClickSendAndCloture() {
      sendToComfact()
        .then(function (devis) {
          ContenuService.clotureOrReouvreEvenement({ idTopic: ctrl.idTopic })
            .then(function (contenu) {
              ModalsService.success("Envoyée dans Comfact et cloturé !");
              emit({
                action: "sentToComfactAndCloture",
                contenu: contenu,
                devis: devis,
              });
            })
            .catch(function () {
              ModalsService.success("Envoyée dans Comfact !");
              emit({ action: "sentToComfact", devis: devis });
            });
        })
        .catch(function (msg) {
          ModalsService.alertErreur(msg);
        });
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour) {
      if (ctrl.isModal) ctrl.close({ $value: objRetour });
      else ctrl.onSubmit({ obj: objRetour });
    }

    /**
     * Permet d'envoyer sur comfact
     */
    function sendToComfact() {
      var deferred = $q.defer();
      try {
        if (
          _.isNil(ctrl.portefeuilleCollab) ||
          _.isNil(ctrl.portefeuilleCollab.idPortefeuille) ||
          !ctrl.portefeuilleCollab.idPortefeuille
        ) {
          if (
            _.isArray(ctrl.listPortefeuilleCollab) &&
            !_.isEmpty(ctrl.listPortefeuilleCollab)
          )
            throw new Error('Veuillez choisir un "Portfeuille Collab"');
          else throw new Error("Pas de portefeuille Collab défini");
        }

        if (!_.isObject(ctrl.portefeuille))
          throw new Error('Veuillez choisir un "Portfeuille Comfact"');
        if (ctrl.choiceAccountantRequired && _.isEmpty(ctrl.comptable))
          throw new Error('Veuillez choisir un "Comptable"');
        if (ctrl.ocrEnabled && _.isNil(ctrl.retourModal.fournisseur))
          throw new Error('Veuillez renseigner le champ "Fournisseur"');
        if (ctrl.ocrEnabled && _.isNil(ctrl.retourModal.immeuble))
          throw new Error('Veuillez renseigner le champ "Immeuble"');
        //if(!_.isObject(ctrl.comptable))     throw new Error("Veuillez choisir un \"Comptable\"");

        ctrl.retourModal.portefeuille = ctrl.portefeuille.portefeuille;
        if (_.isObject(ctrl.comptable))
          ctrl.retourModal.comptable = ctrl.comptable.initiales;

        if (ctrl.retourModal.typeDocument === "onlyFacture")
          ctrl.retourModal.nomPdf = null;

        if (
          _.isObject(ctrl.montantTTC) &&
          _.isObject(ctrl.montantTTC.selected) &&
          !_.isNil(ctrl.montantTTC.selected.value)
        )
          ctrl.retourModal.montantTTC = ctrl.montantTTC.selected.value;

        if (!_.isObject(ctrl.facture) && !_.isObject(ctrl.documentFacture))
          throw new Error("Pas de document ou de facture");

        if (
          _.isString(ctrl.retourModal.dateFacture) &&
          ctrl.retourModal.dateFacture.length === 8
        ) {
          ctrl.retourModal.dateFacture =
            ctrl.retourModal.dateFacture.substring(0, 2) +
            "/" +
            ctrl.retourModal.dateFacture.substring(2, 4) +
            "/" +
            ctrl.retourModal.dateFacture.substring(4, 8);
        }

        setComptesFromAffectationComptesComponent().then(function () {
          $timeout(function () {

            /*
              console.log('facture',ctrl.facture);
              console.log('retourModal',ctrl.retourModal);
              deferred.reject("mok");
              return;
            */

            ModalsService.loading("Envoi vers Comfact en cours...");

            if (_.isObject(ctrl.facture)) {
              ComfactService.sendToComfact(ctrl.retourModal)
                .then(function (devis) {
                  deferred.resolve(devis);
                })
                .catch(function (msg) {
                  ModalsService.alertErreur(msg);
                  deferred.reject(msg);
                });
            } else if (_.isObject(ctrl.documentFacture)) {
              ctrl.retourModal.idPortefeuille =
                ctrl.portefeuilleCollab.idPortefeuille;
              ctrl.retourModal.guid = ctrl.documentFacture.getGuid();
              ComfactService.sendDocumentToComfact(ctrl.retourModal)
                .then(function (devis) {
                  deferred.resolve(devis);
                })
                .catch(function (msg) {
                  ModalsService.alertErreur(msg);
                  deferred.reject(msg);
                });
            } else {
              var msg = "Pas de facture, ni de documentFacture";
              ModalsService.alertErreur(msg);
              deferred.reject(msg);
            }
          }, 500);
        });
      } catch (e) {
        deferred.reject(e.message);
      }

      return deferred.promise;
    }

    function onKeyDown(event, from, silent) {
      if (event.keyCode === 9) {
        if (!event.shiftKey) {
          /*
          if(from === 'previousFournisseur' && !silent) focusBroadcast(event,'focusFournisseur');
          else */
          if (from === "previousImmeuble")
            focusBroadcast(event, "focusImmeuble");
        }

        // Si retour en arrière
        if (event.shiftKey) {
          /*
          if(from === 'afterImmeuble')  focusBroadcast(event,'focusImmeuble');
          else if(from === 'afterFournisseur')       focusBroadcast(event,'focusFournisseur');
          else */
          if (from === "afterContrat") focusBroadcast(event, "focusContrat");
        }
      }
    }

    function focusBroadcast(event, name) {
      if (!_.isNil(event)) {
        //console.log(event);
        event.preventDefault();
        event.stopPropagation();
      }
      $scope.$broadcast(name);
    }

    function onFocusField(event) {
      /*
      event.preventDefault();
      event.stopPropagation();
      if(_.isObject(event.target)){
        console.log(event.target.name);
      }*/
    }

    /**
     * Lors que changement du nom du PDF
     */
    function onChangeNomPdf() {
      if (!ctrl.nomPdfTouched) ctrl.nomPdfTouched = true;
    }

    /**
     * Lors du changement de type de document à créer pour comfact
     */
    function onChangeDocuments() {
      if (
        !ctrl.nomPdfTouched &&
        ctrl.retourModal.typeDocument !== "onlyFacture"
      )
        ctrl.retourModal.nomPdf = genereNomPdf();
      else if (ctrl.retourModal.typeDocument === "onlyFacture") {
        ctrl.showNomPdf = false;
      } else if (
        !ctrl.showNomPdf &&
        ctrl.retourModal.typeDocument !== "onlyFacture"
      ) {
        ctrl.showNomPdf = true;
      }
    }

    /**
     * Permet de générer un nom pour le PDF
     * @returns {*}
     */
    function genereNomPdf() {
      var nomPdf = null;
      if (ctrl.retourModal.typeDocument !== "onlyFacture") {
        ctrl.showNomPdf = true;
        if (_.isObject(ctrl.facture)) {
          switch (ctrl.retourModal.typeDocument) {
            case "factureDemande":
              nomPdf = "facture_et_demande_" + ctrl.facture.getIdContenu();
              break;
            case "factureDemandeDevisSigne":
              nomPdf =
                "facture_demande_et_devisSigne_" + ctrl.facture.getIdContenu();
              break;
            case "factureDevisSigne":
              nomPdf = "facture_et_devisSigne_" + ctrl.facture.getIdContenu();
              break;
            default:
              nomPdf = "facture_comfact_" + ctrl.facture.getIdContenu();
          }
        } else nomPdf = "facture_comfact";
      }
      return nomPdf;
    }

    function onClickClose() {
      ctrl.dismiss({ $value: "cancel" });
    }

    function onClickSwitchMontantTTC() {
      ctrl.montantTTC.selected.type =
        ctrl.montantTTC.selected.type === "ocr" ? "facture" : "ocr";
      ctrl.montantTTC.selected.value = angular.copy(
        ctrl.montantTTC[ctrl.montantTTC.selected.type].value
      );
    }

    /*
    function isSameAsSelectedOcrCantSearch(immeuble, fournisseur){
      var isSame = false;

      // Si contrat choisi et fournisseur ou immeuble choisi
      if(!_.isNil(ctrl.selectedContrat) && (!_.isNil(fournisseur) || !_.isNil(immeuble))){
        if(!_.isNil(fournisseur)){
          // Si le contrat selectionné à un codemétier et numéro identique au fournisseur choisi
          isSame = fournisseur.codeMetier == _this.selectedContrat.codeMetier &&
                    fournisseur.numFournisseur == _this.selectedContrat.numFournisseur &&
                    fournisseur.numImm == _this.selectedContrat.numImm;

          // Si le contrat choisi a les même infos que le fournisseur choisi et que le contrat n'est pas un contrat commun
          if(isSame && !_.isNil(immeuble) && _this.selectedContrat.numImm !== '0000'){
            isSame = immeuble.num == _this.selectedContrat.numImm;
          }
        }
        else{
          isSame = immeuble.num == _this.selectedContrat.numImm;
        }
      }
      return isSame;
    }*/

    /**
     * Permet d'appeler OCR-Front pour récupérer les comptes fournisseur
     * @param search
     * @returns {*}
     */
    function callFournisseurOCRFront(defaultSearchOCR) {
      var deferred = $q.defer();

      if (
        _.isObject(defaultSearchOCR) &&
        defaultSearchOCR.isOcr &&
        _.isObject(ctrl.portefeuilleCollab) &&
        OcrFrontService.canCallOcrFront(defaultSearchOCR)
      ) {
        var searchOcr = {
          portefeuille: ctrl.portefeuilleCollab,
          vatNumber: defaultSearchOCR.vatNumber,
          phone: defaultSearchOCR.phone,
          mail: defaultSearchOCR.email,
          cocnumber: defaultSearchOCR.cocNumber,
          customerBankAccountNumber: defaultSearchOCR.customerBankAccountNumber
        };

        OcrFrontService.getListFounisseur(searchOcr)
          .then(function (list) {
            var listPersonnalite = [];
            if (_.isArray(list) && !_.isEmpty(list)) {
              listPersonnalite = list.map(function (obj) {
                return ComfactService.initObjetFournisseur(obj);
              });

              listPersonnalite = _.uniqBy(listPersonnalite, "value");
            }

            deferred.resolve(listPersonnalite);
          })
          .catch(function (err) {
            deferred.reject(err);
          });
      } else deferred.reject();
      return deferred.promise;
    }

    function callContratICSCloud() {
      var deferred = $q.defer();

      // groupeInformations.numero

      // Recherche par fournisseur (numImm, codeMetier, numFournisseur) ou par immeuble
      /*
      if (isSameAsSelectedOcrCantSearch(ctrl.objImmeuble, ctrl.objFournisseur)) {
        deferred.resolve([]);
        return deferred.promise;
      }*/
      var numImm =
        _.isObject(ctrl.objFournisseur) && !_.isNil(ctrl.objFournisseur.numImm)
          ? ctrl.objFournisseur.numImm
          : _.isObject(ctrl.objImmeuble)
            ? ctrl.objImmeuble.num
            : undefined;

        if(_.isNil(numImm) && !_.isNil(ctrl.searchImmeuble)){
          if(_.isObject(ctrl.searchImmeuble)) {
            if(_.isObject(ctrl.searchImmeuble.groupeInformations) && !_.isNil(ctrl.searchImmeuble.groupeInformations.numero)) {
              numImm = ctrl.searchImmeuble.groupeInformations.numero;
            }
          }
        }

      var codeMetier =
        _.isObject(ctrl.objFournisseur) &&
        !_.isNil(ctrl.objFournisseur.codeMetier)
          ? ctrl.objFournisseur.codeMetier
          : undefined;
      var numFournisseur =
        _.isObject(ctrl.objFournisseur) &&
        !_.isNil(ctrl.objFournisseur.numFournisseur)
          ? ctrl.objFournisseur.numFournisseur
          : undefined;

        if(_.isNil(numImm) && _.isNil(codeMetier) && _.isNil(numFournisseur)) {
          deferred.resolve();
          ctrl.suggestionsFournisseur = angular.copy(ctrl.baseSuggestionsFournisseur);
          return deferred.promise;
        }

        if(_.isNil(codeMetier) && _.isNil(numFournisseur)) ctrl.loadingFournisseur = true;
        if(_.isNil(numImm)) ctrl.loadingImmeuble = true;
        ctrl.loadingContrat = true;

        ContratIcsCloudService.searchListContrat({
          avecEmail: false,
          immeuble: numImm,
          codeMetier: codeMetier,
          numeroFournisseur: numFournisseur,
          ventilationComptable: true,
          seulementAvecComptes: false,
          idPortefeuille: ctrl.portefeuilleCollab,
          gerance: isGestion()
        })
        .then(function (contrats) {
          var immeubles = [];
          var fournisseurs = [];
          if (_.isArray(contrats) && contrats.length) {
            var imms = {};
            var fourni = {};
            for (var c = 0; c < contrats.length; c++) {
              var contrat = contrats[c];

              if (_.isObject(contrat.immeuble) && _.isNil(numImm)) {
                var currentNumImm = contrat.immeuble.noimme;
                if (_.isNil(imms[currentNumImm]))
                  imms[currentNumImm] = angular.copy(contrat.immeuble);

                imms[currentNumImm].addContrat(contrat);
              }

              if (_.isObject(contrat.persofournisseur) && _.isNil(codeMetier) && _.isNil(numFournisseur)) {
                var ref =
                  contrat.persofournisseur.metier +
                  contrat.persofournisseur.numero +
                  contrat.iiii;
                if (_.isNil(fourni[ref])) {
                  fourni[ref] = angular.copy(contrat.persofournisseur);
                  fourni[ref].iiii = contrat.iiii;
                }

                fourni[ref].addContrat(contrat);
              }
            }
            fournisseurs = _.sortBy(_.values(fourni), ["metier", "numero"]);
            immeubles = _.sortBy(_.values(imms), "noimme");
          }

          if(_.isNil(numImm)) ctrl.suggestionsImmeuble = immeubles;
          if(_.isNil(codeMetier) && _.isNil(numFournisseur)) {
            ctrl.suggestionsFournisseur = _.concat(ctrl.baseSuggestionsFournisseur, fournisseurs);
          }
          ctrl.suggestionsContrat = contrats;

          deferred.resolve();
        })
        .catch(function (err) {
          deferred.reject(err);
        })
        .finally(function(){
          ctrl.loadingFournisseur = false;
          ctrl.loadingImmeuble = false;
          ctrl.loadingContrat = false;
        });

      return deferred.promise;
    }

    function setImmeuble(obj){
      if(!_.isObject(obj) || (obj.hasOwnProperty('immeuble') && _.isNil(obj.immeuble))){
        ctrl.objImmeuble = null;
        ctrl.retourModal.immeuble = null;
      }
      else {
        ctrl.retourModal.immeuble = obj.immeuble;
        ctrl.objImmeuble = {
          num: ctrl.retourModal.immeuble
        };
        if(!_.isNil(obj.libelleImmeuble)) ctrl.objImmeuble.libelle = obj.libelleImmeuble;
        else ctrl.objImmeuble.libelle = "Immeuble";
      }
    }

    function setFournisseur(obj){
      if(!_.isObject(obj) || (obj.hasOwnProperty('fournisseur') && _.isNil(obj.fournisseur))){
        ctrl.objFournisseur = null;
        ctrl.retourModal.fournisseur = null;
      }
      else{
        var fournisseur =  angular.copy(obj);
        if(_.isObject(obj.fournisseur)) fournisseur = angular.copy(obj.fournisseur);

        if(!fournisseur.isObjetFournisseur) {
          fournisseur = ComfactService.initObjetFournisseur(fournisseur);
        }
        ctrl.objFournisseur = fournisseur;
        ctrl.retourModal.fournisseur = ctrl.objFournisseur.value;
      }
    }

    function setContrat(obj){
      if(!_.isObject(obj) || (obj.hasOwnProperty('contrat') && _.isNil(obj.contrat))){
        ctrl.objContrat = null;
        ctrl.retourModal.contrat = null;
        $scope.$broadcast("clearSelectedContratModeles");
      }
      else {

        var contrat =  angular.copy(obj);
        if(_.isObject(obj.contrat)) contrat = angular.copy(obj.contrat);

        ctrl.objContrat = contrat;
        ctrl.retourModal.contrat = ctrl.objContrat.value;
        // Si numéro de mandat dans le contrat
        if(_.isObject(ctrl.objContrat.source) && !_.isEmpty(ctrl.objContrat.source.manDat) && ctrl.objContrat.source.manDat != "0000" && isGestion()){
          // Si le numéro de mandat n'est pas encore saisie on le rempli
          if(_.isEmpty(ctrl.retourModal.mandat)){
            ctrl.retourModal.mandat = ctrl.objContrat.source.manDat;
          }
          // si il est saisie mais différent de celui du contrat on propose de le changer
          else if(ctrl.retourModal.mandat != ctrl.objContrat.source.manDat){
            var txt = "Le numéro de mandat saisi dans le formulaire est <strong>"+ctrl.retourModal.mandat+"</strong> alors que celui du contrat est <strong>"+ctrl.objContrat.source.manDat+"</strong>.<br />" +
            "Voulez-vous remplacer <strong>"+ctrl.retourModal.mandat+"</strong> par <strong>"+ctrl.objContrat.source.manDat+"</strong> dans le formulaire ?";
            ModalsService.confirm(
              "Numéro de mandat différent",
              txt
            )
              .then(function onConfirm(modal) {
                ctrl.retourModal.mandat = ctrl.objContrat.source.manDat;
                modal.close();
              })
              .catch(function onReject() {
              });
          }
        }

        // Si pas de fournisseur choisi
        if (_.isNil(ctrl.objFournisseur) && !_.isNil(ctrl.objContrat.codeMetier) && !_.isNil(ctrl.objContrat.numFournisseur)) {
          // TODO : set ctrl.objFournisseur avec l'objet trouvé dans suggestionFourniseur ?
          $scope.$broadcast("selecteFournisseur", {
            codeMetier: ctrl.objContrat.codeMetier,
            numFournisseur: ctrl.objContrat.numFournisseur
          });
        }
      }
    }

    function onEmitFromChooseImmeuble(obj) {
      //console.log("onEmitFromChooseImmeuble", obj);
      try {
        if (!_.isObject(obj))
          throw new Error('Le paramètre "obj" doit être un objet');
        var nextField = false,
          previousField = false;
        if (obj.action === "selectedImmeuble") {
          setImmeuble(obj);

          if (_.isNil(obj.immeuble)) {
            if(_.isObject(ctrl.objFournisseur) && ctrl.objFournisseur.group === 'contrat'){
              ctrl.objImmeuble = undefined;
              $scope.$broadcast("clearSelectedFournisseur");
            }
          }
          else nextField = obj.next;

          if(!obj.onInit) callContratICSCloud();
        }
        if (obj.action === "previousField") previousField = true;
        else if (obj.action === "nextField") nextField = true;
        if (previousField) {
          $timeout(function () {
            var element = document.getElementById(
              ctrl.comptables.length > 1 ? "comptable" : "typeComfactFacture"
            );
            element.focus();
            element.select();
          });
        } else if (nextField) {
          $timeout(function () {
            focusBroadcast(null, "focusFournisseur");
          });
        }
      } catch (err) {
        ErreurCollabService.logErreur(
          "[ComfactFactureController.onEmitFromChooseImmeuble] - " + err.message
        );
      }
    }

    function onEmitFromChooseFournisseur(obj) {
      //console.log("onEmitFromChooseFournisseur", obj);
      try {
        if (!_.isObject(obj))
          throw new Error('Le paramètre "obj" doit être un objet');
        var nextField = false,
          previousField = false;
        if (obj.action === "selectedFournisseur") {
          setFournisseur(obj);

          if (_.isNil(obj.fournisseur)) {
            ctrl.objContrat = undefined;
            $scope.$broadcast("clearSelectedContrat");
          }
          else nextField = obj.next;

          if(!obj.onInit) callContratICSCloud();
        }

        if (obj.action === "previousField") previousField = true;
        else if (obj.action === "nextField") nextField = true;

        if (previousField) {
          $timeout(function () {
            focusBroadcast(null, "focusImmeuble");
          });
        } else if (nextField) {
          $timeout(function () {
            focusBroadcast(null, "focusContrat");
          });
        }
      } catch (err) {
        ErreurCollabService.logErreur(
          "[ComfactFactureController.onEmitFromChooseFournisseur] - " +
            err.message
        );
      }
    }

    function onEmitFromChooseContrat(obj) {
      //console.log("onEmitFromChooseContrat", obj);
      try {
        if (!_.isObject(obj))
          throw new Error('Le paramètre "obj" doit être un objet');
        var nextField = false,
          previousField = false;
        if (obj.action === "selectedContrat") {
          setContrat(obj);
          if (_.isObject(obj.contrat)) nextField = obj.next;
        }

        if (obj.action === "previousField") previousField = true;
        else if (obj.action === "nextField") nextField = true;

        if (previousField) {
          $timeout(function () {
            focusBroadcast(null, "focusFournisseur");
          });
        } else if (nextField) {
          $timeout(function () {
            var element = document.getElementById("dateFacture");
            element.focus();
            element.select();
          });
        }
      } catch (err) {
        ErreurCollabService.logErreur(
          "[ComfactFactureController.onEmitFromChooseContrat] - " + err.message
        );
      }
    }

    function onEmitFromDocumentOcrManager(obj) {
      //console.log(obj);
      try {
        if (!_.isObject(obj))
          throw new Error('Le paramètre "obj" doit être un objet');

        if (obj.action === "retrievedDocument" || obj.action === "resultOCR") {
          if (!_.isObject(obj.document))
            throw new Error(
              'Il doit y avoir un "document" pour l\'action "retrievedDocument" ou "resultOCR"'
            );
          ctrl.documentFacture = obj.document;
          if (
            obj.action === "resultOCR" &&
            ctrl.documentFacture.documentOcrIsOk()
          )
            ctrl.panelOcrIsCollapsed = false;
          initComfactInfos();
        }
      } catch (err) {
        ErreurCollabService.logErreur(
          "[ComfactFactureController.onEmitFromDocumentOcrManager] - " +
            err.message
        );
      }
    }

    function setComptesFromAffectationComptesComponent() {
      var deferred = $q.defer();
      if (
        ctrl.saisieComptesSCOEnabled &&
        ctrl.affectationComptesComponentReady
      ) {
        var subDeferred = $q.defer();
        $scope.$broadcast("getListCompteByRegroupement", subDeferred);
        subDeferred.promise
          .then(function (retourAffectationCompte) {
            if (_.isObject(retourAffectationCompte)) {
              ctrl.resteMontant = retourAffectationCompte.reste;

              var listCompte = retourAffectationCompte.listSelectedCompte;
              if (_.isArray(listCompte) && !_.isEmpty(listCompte)) {
                var ComfactCompte = UtilsService.$injector.get("ComfactCompte");
                var listComfactCompte = listCompte.map(function (compte) {
                  return new ComfactCompte(compte);
                });
                if (!_.isEmpty(listComfactCompte))
                  ctrl.retourModal.comptes = angular.toJson(listComfactCompte);
              }
              else ctrl.retourModal.comptes = null;
              if (!math.isZero(ctrl.resteMontant)) {
                var texte = "";
                if (math.isNegative(ctrl.resteMontant)) {
                  var montantSansMoins = math
                    .format(ctrl.resteMontant)
                    .replace(/\-/gi, "");

                  texte =
                    "La somme des montants affectés aux comptes dépasse le montant TTC.<br />" +
                    "Montant TTC: <strong>" +
                    ctrl.retourModal.montantTTC +
                    " €</strong><br/>" +
                    "Montant affecté: <strong class='text-danger'>" +
                    math.round(
                      math.add(ctrl.retourModal.montantTTC, montantSansMoins),
                      2
                    ) +
                    " €</strong>";
                } else if (math.isPositive(ctrl.resteMontant))
                  texte =
                    "Il reste <strong>" +
                    math.format(ctrl.resteMontant) +
                    " €</strong> à affecter.";

                texte +=
                  "<br /><br /><strong>Voulez-vous vraiment continuer ?</strong>";

                ModalsService.confirm(
                  "Information sur la saisie des comptes",
                  texte
                )
                  .then(function onConfirm() {
                    deferred.resolve();
                  })
                  .catch(function onReject() {
                    deferred.reject();
                  });
              } else deferred.resolve();
            } else {
              deferred.reject(
                'Le retour de "getListCompteByRegroupement" n\'est pas bon'
              );
              console.error("retourAffectationCompte", retourAffectationCompte);
            }
          })
          .catch(function onReject(msg) {
            deferred.reject(msg);
          });
      } else deferred.resolve(null);
      return deferred.promise;
    }

    function onEmitFromAffectationComptesComponent(obj) {
      //console.log(obj);
      try {
        $scope.$emit("calcHeight");
        if (!_.isObject(obj))
          throw new Error('Le paramètre "obj" doit être un objet');
        if (obj.action === "onReady")
          ctrl.affectationComptesComponentReady = obj.ready;
        if (obj.action === "resteMontant") ctrl.resteMontant = obj.reste;
      } catch (err) {
        ErreurCollabService.logErreur(
          "[ComfactFactureController.onEmitFromAffectationComptesComponent] - " +
            err.message
        );
      }
    }
  }
})();
