(function() {

  'use strict';

  /**
   * @desc Composant facture autonome
   * @example <facture-autonome></facture-autonome>
   */
  angular
    .module('collaboreApp')
    .component('factureAutonome',{
      templateUrl: 'app/facture-manager/facture-autonome/facture-autonome.component.html',
      controllerAs: 'factureautonomectrl',
      bindings: {
        guid: '<',
        onEmit: '&'
      },
      controller: FactureAutonomeCtrl
    });

  /** @ngInject */
  function FactureAutonomeCtrl($timeout, $scope, $state, $stateParams, UtilsService, DocumentManagerService){
    var _this = this;

    _this.loadingFacture = false;         // Loading facture
    _this.resultDocumentAutonome = null;
    _this.paramsComfactfacture = null;   // Objet avec comme clé l'idPortefeuille et comme valeur un objet avec configs et comptables

    _this.onChangeFacture = onChangeFacture;
    _this.onClickRefreshFacture = onClickRefreshFacture;
    _this.onClickPreviousFacture = onClickPreviousFacture;
    _this.onClickNextFacture = onClickNextFacture;
    _this.onSubmitComfactfacture = onSubmitComfactfacture;
    _this.onEmitComfactfacture = onEmitComfactfacture;

    _this.$onInit = function() {
      init();

      if($stateParams.paramsComfactfacture) _this.paramsComfactfacture = $stateParams.paramsComfactfacture;


      _this.sendToComfactEnable = UtilsService.getParametrageComfact('allowSendToComfactfacture');

      searchFacturesAutonomes(_this.guid);
      $scope.$on('goToOtherFacture', function() {
        goToOtherFacture();
      });
    };

    _this.$onChanges = function(changes){
      if(_.isObject(changes.guid) && !changes.guid.isFirstChange() && !_.isNil(changes.guid.currentValue)){
        init();
        searchFacturesAutonomes(changes.guid.currentValue);
      }
    };

    /**
     * Initialisation des variables
     */
    function init(){

      // PDF Devis
      _this.loadingPdfDevis = false;     // Loading du pdf devis
      _this.urlPdfDevis = null;          // Url du PDF devis signé ou devis pour ouvrir en lecture

      // PDF Facture
      _this.urlPdfFacture = null;        // Url du PDF facture pour ouvrir en lecture
      _this.loadingPdfFacture = false;   // Loading du PDF facture
    }

    function emit(obj){
      _this.onEmit({obj: obj});
    }

    function getObjectSearch(guidOrDoc){
      if(_.isObject(guidOrDoc) && !_.isString(guidOrDoc)) guidOrDoc = guidOrDoc.guid;
      return {
        guid: guidOrDoc,
        paramsComfactfacture: _this.paramsComfactfacture
      };
    }

    function searchFacturesAutonomes(guidOrDoc){
      init();
      _this.loadingFacture = true;

      DocumentManagerService
        .searchDocumentsAutonomes(getObjectSearch(guidOrDoc))
        .then(function(result){
          _this.resultDocumentAutonome = result;
          if(_this.resultDocumentAutonome.nbTotalDocument !== 0){
            if(_.isObject(_this.resultDocumentAutonome.currentDoc)){

              _this.panelComfactIsCollapsed = true;

              var searchObject = getObjectSearch(_this.resultDocumentAutonome.currentDoc.guid);
              $state.go($state.current.name, searchObject, {reload: false, notify: false, location: true});

              emit({
                action: 'loadPdf',
                document: _this.resultDocumentAutonome.currentDoc,
                states: {
                  stateParams: searchObject
                }
              });
            }
          }
          else{
            emit({action: 'init'});
          }

          //console.log(result);
        })
        .catch(function(msg){
          console.error(msg);
        })
        .finally(function(){
          _this.loadingFacture = false;
        });
    }

    function goToOtherFacture(){
      if(_this.resultDocumentAutonome.nextDoc) goNextFacture();
      else if(_this.resultDocumentAutonome.previousDoc) goPreviousFacture();
      else {
        _this.resultDocumentAutonome.currentDoc = null;

        _this.resultDocumentAutonome.previousDoc = null;
        _this.resultDocumentAutonome.nbPrevious = 0;

        _this.resultDocumentAutonome.nextDoc = null;
        _this.resultDocumentAutonome.nbNext = 0;

        _this.resultDocumentAutonome.nbTotalDocument = 0;
        _this.resultDocumentAutonome.listDocument = [];

        emit({action: 'init'});
      }
    }

    function onChangeFacture(){
      var obj = getObjectSearch(_this.resultDocumentAutonome.currentDoc);
      if(_.isObject(obj) && !_.isNil(obj.guid)) searchFacturesAutonomes(obj);
    }

    function onClickRefreshFacture(){
      searchFacturesAutonomes(_this.resultDocumentAutonome.currentDoc);
    }

    function onClickPreviousFacture(){
      goPreviousFacture();
    }

    function onClickNextFacture(){
      goNextFacture();
    }

    function goPreviousFacture(){
      if(_this.resultDocumentAutonome.previousDoc) searchFacturesAutonomes(_this.resultDocumentAutonome.previousDoc);
    }

    /**
     * Facture suivante
     */
    function goNextFacture(){
      if(_this.resultDocumentAutonome.nextDoc) searchFacturesAutonomes(_this.resultDocumentAutonome.nextDoc);
    }

    /**
     * Lorsque le formulaire comfact est terminé
     * @param obj
     */
    function onSubmitComfactfacture(obj) {
      //console.log(obj);
      if(_.isObject(obj) && obj.hasOwnProperty('action')) {

        // Si envoyé sur Comfact, passe à une autre facture
        if(obj.action === 'sentToComfact' || obj.action === 'sentToComfactAndCloture') {
          $timeout(function(){
            goToOtherFacture();
          });
        }

        // Surement seulement la cloture
        //else if(obj.hasOwnProperty('contenu')){}
      }
    }

    function onEmitComfactfacture(obj){
      if(_.isObject(obj) && obj.hasOwnProperty('idPortefeuille')) {
        if(!_.isObject(_this.paramsComfactfacture)) _this.paramsComfactfacture = {};
        if(!_.isObject(_this.paramsComfactfacture[obj.idPortefeuille])) _this.paramsComfactfacture[obj.idPortefeuille] = {};
        if(obj.hasOwnProperty('configs')) _this.paramsComfactfacture[obj.idPortefeuille].configs = obj.configs;
        if(obj.hasOwnProperty('users')) _this.paramsComfactfacture[obj.idPortefeuille].users = obj.users;
      }
    }
  }
})();
