(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.Tag
   * @description
   * # Tag
   * Service in the collaboreApp.
   */
  angular
    .module('collaboreApp')
    .factory('Tag', TagModel);

  /** @ngInject */
  function TagModel(COLLAB_CONF, $q, $filter,TagType,UtilsService,Portefeuille) {

    function Tag(tagData,boolProxy) {
      if (tagData) this.setData(tagData,boolProxy);
      else this.hydrate(tagData,boolProxy);

      if(boolProxy){
        if(this.hasOwnProperty('libelle')){
          this.libelleTag = this.libelle;
          delete this.libelle;
        }
        if(this.hasOwnProperty('typeTag')){
          this.libelleTypeTag = this.typeTag.libelle;
          delete this.typeTag;
        }
        if(this.hasOwnProperty('portefeuille')){
          this.idPortefeuille = this.portefeuille.idPortefeuille;
          delete this.portefeuille;
        }
      }
    }


    Tag.prototype = {

      isModel: true,
      isTag: true,
      model: 'Tag',

      // Permet de convertir l'objet en JSON
      toJson: function(){
        return angular.toJson(this);
      },

      hydrate: function(tagData,boolProxy){
        var scope = this;

        var exclu = [];

        var convert = {};

        // Si boolproxy à true
        // Exclu des champ à setter pour renvoyer le bonne obj à la servlet
        if(boolProxy){
          exclu = [
            'setTypeTag'
          ];


          // Permet de convertir une méthode par une autre
          /*
                    convert = {
                        'setLibelle': 'setLibelleTag'
                    };*/

        }
        if(angular.isObject(tagData)) {



          //var data = Object.keys(contenuData);
          //Object.keys(contenuData).forEach(function (key) {
          // key = la cle de l'objet (ex: idContenu)
          // val = la valeur actuel
          angular.forEach(tagData,function(val,key){

            var setter = 'set' + $filter('ucfirst')(key);

            // Si la propriété est dans le tableau des conversion
            // Converti le setter en un autre
            if(convert.hasOwnProperty(setter)) {

              // Remplace la méthode par celle de la conversion
              // Exemple listeDocument => guids
              // setListeDocuments => setGuids
              // La valeur reupéré pour setGuids et bien 'listeDocument'
              // Du coup setGuids(listeDocument)
              setter = convert[setter];

              // Si c'est bien une fonction
              if (angular.isFunction(scope[setter])) {
                scope[setter](val,boolProxy);
              }


              // Si le setter n'est pas dans le tableau des attribut à exclure
            }else if(!$filter('contains')(exclu,setter)){

              // Si c'est bien une fonction
              //if (typeof(scope[setter]) === 'function') {
              if (angular.isFunction(scope[setter])) {
                scope[setter](val,boolProxy);
              }
            }

          });
        }else{

          // Pattern pour trouver les méthodes qui comments par 'set'
          // donc les setter
          var reg = /^set[A-Za-z]+/;
          var methodes = Object.getPrototypeOf(this);
          // key = méthode (ex: setIdContenu)
          // val = la fonction en string : function(){....}
          // Pour chaque méthode de Contenu et c'est enfants
          angular.forEach(methodes,function(val,key){


            // Si la propriété est dans le tableau des conversion
            // Converti le setter en un autre
            if(convert.hasOwnProperty(key)){
              key = convert[key];
            }

            // Si la méthode est un setter et que ce n'est pas 'setData'
            if(reg.test(key) && key!=='setData'){

              // Si la key n'est pas dans le tableau des attribut à exclure
              if(!$filter('contains')(exclu,key)){

                scope[key](undefined,true);
              }

            }

          });

        }
      },

      setData: function(tagData,boolProxy) {
        //angular.extend(this, tagData);

        this.hydrate(tagData,boolProxy);

        if(tagData.hasOwnProperty('typeTag')){
          var instanceTagType = new TagType(tagData.typeTag);
          this.setTypeTag(instanceTagType);
        }
      },

      getIdTag: function(){
        return parseInt(this.idTag);
      },
      setIdTag: function(id){
        this.idTag = id;
      },

      // Libelle
      getLibelle: function(){
        return this.libelle;
      },
      setLibelle: function(libelle){
        this.libelle = libelle;
      },

      // Libelle Tag
      getLibelleTag: function(){
        return this.libelleTag;
      },
      setLibelleTag: function(libelle){
        this.libelleTag = libelle;
      },

      // Libelle Type Tag
      // Utilisé pour le proxy
      getLibelleTypeTag: function(){
        return this.libelleTypeTag;
      },
      setLibelleTypeTag: function(libelle){
        this.libelleTypeTag = libelle;
      },

      // idPortefeuille
      // Utilisé pour le proxy
      getIdPortefeuille: function(){
        return this.idPortefeuille;
      },
      setIdPortefeuille: function(id){
        this.idPortefeuille = id;
      },

      // TypeTag
      getTypeTag: function(){
        return this.typeTag;
      },
      setTypeTag: function(obj){
        this.typeTag = obj;
      },
      isTagType: function(key){
        if(this.hasOwnProperty('typeTag')){
          // Si il y un type de tag doit avoir un certin libelle
          if(key){
            if(key===COLLAB_CONF.TAG_TYPE_ORIGINE){
              // Test si le tag type est du type demandé
              if(UtilsService.contains(COLLAB_CONF.ARRAY_TAGS_ORIGINE,this.getTypeTag().getLibelle())){
                return this.getTypeTag().getLibelle();
              }else{
                return false;
              }
            }else{
              // Test si le tag type est du type demandé
              if(this.getTypeTag().getLibelle() === key){
                return this.getTypeTag().getLibelle();
              }else{
                return false;
              }
            }

          }else{
            return this.getTypeTag().getLibelle();
          }

        }else{
          return false;
        }
      },
      isTagNormal: function(){
        return this.isTagType(COLLAB_CONF.TAG_NORMAL);
      },
      isTagProjet: function(){
        return this.isTagType(COLLAB_CONF.TAG_TYPE_PROJET);
      },
      isTagOrigine: function(){
        return this.isTagType(COLLAB_CONF.TAG_TYPE_ORIGINE);
      },
      isTagImmeuble: function(){
        return this.isTagType(COLLAB_CONF.TAG_TYPE_IMMEUBLE);
      },
      isTagLot: function(){
        return this.isTagType(COLLAB_CONF.TAG_TYPE_LOT);
      },
      isTagLocataire: function(){
        return this.isTagType(COLLAB_CONF.TAG_TYPE_LOCATAIRE);
      },
      isTagProprietaire: function(){
        return this.isTagType(COLLAB_CONF.TAG_TYPE_PROPRIETAIRE);
      },
      isTagCoproprietaire: function(){
        return this.isTagType(COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE);
      },
      isTagMandat: function(){
        return this.isTagType(COLLAB_CONF.TAG_TYPE_MANDAT);
      },
      isTagPortefeuille: function(){
        return this.isTagType(COLLAB_CONF.TAG_TYPE_PORTEFEUILLE);
      },
      isTagImportant: function(){
        return this.isTagType(COLLAB_CONF.TAG_TYPE_IMPORTANT);
      },
      isTagTypeTechnicien: function(){
        return this.isTagType(COLLAB_CONF.TAG_TYPE_TECHNICIEN);
      },
      isTagTypeMenage: function(){
        return this.isTagType(COLLAB_CONF.TAG_TYPE_MENAGE);
      },
      isTagPresence: function(){
        return this.isTagType(COLLAB_CONF.TAG_TYPE_PRESENCE);
      },
      isTagPerso: function(){
        return (this.isTagLocataire() || this.isTagProprietaire() || this.isTagCoproprietaire());
      },
      isTagLotMulticamp: function(){
        return this.isTagType(COLLAB_CONF.TAG_TYPE_LOT_MULTICAMP);
      },
      isTagLieuCommun: function(){
        return this.isTagType(COLLAB_CONF.TAG_TYPE_LIEU_COMMUN_CAPFUN);
      },


      isTagTypeIntervention: function(){
        return (this.isTagType(COLLAB_CONF.TAG_TYPE_TYPE) && this.getLibelle() === UtilsService.parseTag(COLLAB_CONF.TAG_TYPE_TYPE_INTERVENTION)) ? true : false;
      },


      isTagStatut: function(){
        return this.isTagType(COLLAB_CONF.TAG_TYPE_STATUT);
      },
      isTagStatutEnAttente: function(){
        return (this.isTagType(COLLAB_CONF.TAG_TYPE_STATUT) && this.getLibelle() === UtilsService.parseTag(COLLAB_CONF.TAG_TYPE_STATUT_EN_ATTENTE)) ? true : false;
      },
      isTagStatutEnCours: function(){
        return (this.isTagType(COLLAB_CONF.TAG_TYPE_STATUT) && this.getLibelle() === UtilsService.parseTag(COLLAB_CONF.TAG_TYPE_STATUT_EN_COURS)) ? true : false;
      },
      isTagStatutTermine: function(){
        return (this.isTagType(COLLAB_CONF.TAG_TYPE_STATUT) && this.getLibelle() === UtilsService.parseTag(COLLAB_CONF.TAG_TYPE_STATUT_TERMINE)) ? true : false;
      },
      isTagStatutProbleme: function(){
        return (this.isTagType(COLLAB_CONF.TAG_TYPE_STATUT) && this.getLibelle() === UtilsService.parseTag(COLLAB_CONF.TAG_TYPE_STATUT_PROBLEME)) ? true : false;
      },

      // Valeur Ics
      getValeurIcs: function(){
        return this.valeurIcs;
      },
      setValeurIcs: function(val){
        this.valeurIcs = val;
      },

      // Portefeuille
      getPortefeuille: function(){
        return this.portefeuille;
      },
      setPortefeuille: function(val){
        if(!val.isModel){
          val = new Portefeuille(val);
        }
        this.portefeuille = val;
      },
      portefeuilleIsBlocked: function(){
        var portefeuille = this.getPortefeuille();
        if(_.isObject(portefeuille) && portefeuille.getIsBlocked()) return true;
        return false;
      },

      gedV2IsEnabled: function(){
        var portefeuille = this.getPortefeuille();
        if(_.isObject(portefeuille)) return portefeuille.gedV2IsEnabled();
        return false;
      },

      // tagEvenement
      getTagEvenement: function(){
        return this.tagEvenement;
      },
      setTagEvenement: function(val){
        this.tagEvenement = val;
      },

      cleanTag: function(typeTag){
        // Si pas de tag type
        if(!typeTag){
          // Si il y a un typetag sur le tag récupéré on le supprime
          if(this.hasOwnProperty('typeTag')){
            delete this.typeTag;
          }
        }
      },

      getLibelleType: function(){
        if(this.isTagType()){
          var libelle = this.getTypeTag().getLibelle();
          if(UtilsService.contains(COLLAB_CONF.ARRAY_TAGS_ORIGINE,libelle)){
            return COLLAB_CONF.TAG_TYPE_ORIGINE;
          }else{
            return this.getTypeTag().getLibelle();
          }
        }else{
          return 'Tag';
        }
      },

      getIcon: function() {
        var classIcon = false;
        if(this.isTagProjet())                classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_PROJET];
        else if(this.isTagLot())              classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_LOT];
        else if(this.isTagProprietaire())     classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_PROPRIETAIRE];
        else if(this.isTagCoproprietaire())   classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE];
        else if(this.isTagLocataire())        classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_LOCATAIRE];
        else if(this.isTagOrigine())          classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_LOCATAIRE];
        else if(this.isTagImmeuble())         classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_IMMEUBLE];
        else if(this.isTagMandat())           classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_MANDAT];
        else if(this.isTagPortefeuille())     classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_PORTEFEUILLE];
        else if(this.isTagNormal())           classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_NORMAL];
        else if(this.isTagImportant())        classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_IMPORTANT];
        else {
          var libelleType = this.getLibelleType();
          if(UtilsService.contains(COLLAB_CONF.ARRAY_TAGS_ORIGINE, libelleType)) classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_TYPE_ORIGINE];
          else if(COLLAB_CONF.ICON_TAG.hasOwnProperty(libelleType)) classIcon = COLLAB_CONF.ICON_TAG[libelleType];
          else classIcon = COLLAB_CONF.ICON_TAG[COLLAB_CONF.TAG_NORMAL];
        }
        return classIcon;
      },

      getGroupe: function (withGroupeFils) {
        var deferred = $q.defer();
        try {
          var idPortefeuille = (_.isObject(this.getPortefeuille())) ? this.getPortefeuille().getIdPortefeuille() : null;
          var valeurIcs = this.getValeurIcs();

          if(_.isNil(idPortefeuille)) throw new Error("L'idPortefeuille est null");
          if(_.isNil(valeurIcs)) throw new Error("La valeur ICS est null");

          var GroupeService = UtilsService.$injector.get('GroupeService');

          if(withGroupeFils){
            GroupeService
              .searchGroupeByLibelleAndPortefeuilleInMySociete(idPortefeuille, valeurIcs)
              .then(function(groupe){
                deferred.resolve(groupe);
              })
              .catch(function(msg){
                deferred.reject(msg);
              });
          }
          // Dans ce retour il n'y a pas les groupesFils
          else if(this.isTagImmeuble() || this.isTagPerso()){
            var params = {
              idPortefeuille: idPortefeuille,
              withGroupeAssociation: true,
              withAllCollaborateur: true,
              typeGroupe: (this.isTagImmeuble()) ? 'immeuble' : 'perso',
              search: valeurIcs+"\\_"
            };
            GroupeService
              .searchGroupe(params)
              .then(function(groupe){
                deferred.resolve(groupe);
              })
              .catch(function(msg){
                deferred.reject(msg);
              });
          }
        }
        catch (e) {
          deferred.reject(e.message);
        }
        return deferred.promise;
      }

    };

    Tag.genereLibelleTag = function(obj){
      var libelle = false;
      // Si c'est bien une instance
      if(obj.isModel) {
        // Si c'est une instance de Personnalite
        if (obj.isPersonnalite) {
          var nomper = obj.getNomper();
          if(nomper!=='' && angular.isDefined(nomper)){
            libelle = UtilsService.parseTag(nomper);
            //console.log(libelle);
            //console.info('[Info] Tag.genereLibelleTag : Le parametre est une instance de Personnalite');
            //libelle = 'Perso' + obj.getNoperso();
          }


        }
        // Si c'est une instance de Groupe
        else if (obj.isGroupe) {
          //console.info('[Info] Tag.genereLibelleTag : Le parametre est une instance de Groupe');
          var nom = obj.defaultLibelle;
          if(obj.hasOwnProperty('nom')) {
            nom = obj.nom;
          }
          if(nom!=='' && angular.isDefined(nom)){
            libelle = UtilsService.parseTag(nom);
          }


        }
        // Si c'est une instance de Immeuble
        else if (obj.isImmeuble) {
          //console.info('[Info] Tag.genereLibelleTag : Le parametre est une instance de Groupe');
          var nomimme = obj.getNom();
          if(nomimme!=='' && angular.isDefined(nomimme)){
            libelle = UtilsService.parseTag(nomimme);
          }
        }
        // Si c'est une instance de Salarie
        else if (obj.isSalarie) {
          //console.info('[Info] Tag.genereLibelleTag : Le parametre est une instance de Groupe');
          var nomSalarie = obj.getNom();
          if (nomSalarie === '') {
            nomSalarie = obj.getProfess();
          }
          if(nomSalarie!=='' && angular.isDefined(nomSalarie)){
            libelle = UtilsService.parseTag(nomSalarie);
          }
        }
        // Si c'est une instance de LotMulticamp
        else if (obj.isLotMulticamp) {
          var nomLot = obj.getPlace_name();
          if(!_.isNil(nomLot) && nomLot!=='') libelle = UtilsService.parseTag(nomLot);
        }
        // Si c'est une instance de LieuCommunCapfun
        else if (obj.isLieuCommunCapfun) {
          var nomLieu = obj.getLieu();
          if(!_.isNil(nomLieu) && nomLieu!=='') libelle = UtilsService.parseTag(nomLieu);
        }
      }else{
        console.error('[Erreur] Tag.genereLibelleTag : Le parametre n\'est pas une instance');
      }
      return libelle;
    };

    Tag.genereOldLibelleTag = function(obj){
      var libelle = false;
      // Si c'est bien une instance
      if(obj.isModel) {
        // Si c'est une instance de Personnalite
        if (obj.isPersonnalite) {
          var nomper = obj.getNomper();
          if(nomper!=='' && angular.isDefined(nomper)){
            libelle = UtilsService.oldParseTag(nomper);
            //console.log(libelle);
            //console.info('[Info] Tag.genereOldLibelleTag : Le parametre est une instance de Personnalite');
            //libelle = 'Perso' + obj.getNoperso();
          }


        }
        // Si c'est une instance de Groupe
        else if (obj.isGroupe) {
          //console.info('[Info] Tag.genereOldLibelleTag : Le parametre est une instance de Groupe');
          var nom = obj.defaultLibelle;
          if(obj.hasOwnProperty('nom')) {
            nom = obj.nom;
          }
          if(nom!=='' && angular.isDefined(nom)){
            libelle = UtilsService.oldParseTag(nom);
          }


        }
        // Si c'est une instance de Immeuble
        else if (obj.isImmeuble) {
          //console.info('[Info] Tag.genereOldLibelleTag : Le parametre est une instance de Groupe');
          var nomimme = obj.getNom();
          if(nomimme!=='' && angular.isDefined(nomimme)){
            libelle = UtilsService.oldParseTag(nomimme);
          }
        }
        // Si c'est une instance de Salarie
        else if (obj.isSalarie) {
          //console.info('[Info] Tag.genereOldLibelleTag : Le parametre est une instance de Groupe');
          var nomSalarie = obj.getNom();
          if (nomSalarie === '') {
            nomSalarie = obj.getProfess();
          }
          if(nomSalarie!=='' && angular.isDefined(nomSalarie)){
            libelle = UtilsService.oldParseTag(nomSalarie);
          }
        }
      }else{
        console.error('[Erreur] Tag.genereOldLibelleTag : Le parametre n\'est pas une instance');
      }
      return libelle;
    };

    /*
        Tag.proxyTag = function(data,libelleTypeTag,valeurIcs,portefeuille){

            var objData = {};
            // Si data est in string alors c'est un libelle normal et un tag non typé
            if(angular.isString(data)){
                objData = {
                    'libelleTag': data
                };

                if(libelleTypeTag){
                    objData.libelleTypeTag = libelleTypeTag;
                }
            }else{
                objData = data;
            }


            if(valeurIcs){
                objData.valeurIcs = valeurIcs;
            }

            if(portefeuille){
                if(angular.isObject(portefeuille)){
                    objData.idPortefeuille = portefeuille.idPortefeuille;
                }else if(angular.isNumber(portefeuille)){
                    objData.idPortefeuille = portefeuille;
                }
            }

            return new Tag(objData,true);

        };*/

    return Tag;

  }

})();
