(function () {
  'use strict';

  /**
   * @ngdoc factory
   * @name collaboreApp.addTokenHttpInterceptor
   * @description
   * # addTokenHttpInterceptor
   * Permet de verifier tout les appel ajax
   * Si erreur 401 on renvoi le visiteur sur la page login
   */

  angular
    .module('collaboreApp')
    .factory('addTokenHttpInterceptor', AddTokenHttpInterceptor);

  /*@ngInject*/
  function AddTokenHttpInterceptor(COLLAB_CONF, COLLAB_VALUES, $rootScope, $location, $q, ErreurCollabService) {

    return {
        request: function(config) {
          // Recupère le token dans le rootscope
          var tokenRootScope = $rootScope.token;

          /*
          console.log("------- Start httpProvider --------");
          console.log(config);
          console.log("------- End httpProvider --------");
          */

          // Si l'appel n'est pas une vue et qu'on a un token on l'ajoute en paramètre dans tous les appels ajax
          if(!_.endsWith(config.url,'.html')){

            if(!_.isNil(tokenRootScope) && tokenRootScope){

              // Si pas de propriété "params" dans l'objet config
              if(!_.isObject(config.params)) config.params = {};

              if(config.method === 'GET') config.params.token = tokenRootScope;
              if(config.method === 'POST'){
                /*
                console.log("** config **");
                console.log(config);
                console.log("** config **");
                */

                if(_.isString(config.data))       config.data += '&token=' + tokenRootScope;
                else if(_.isObject(config.data))  {
                  config.data.token = tokenRootScope;
                  config.params.token = tokenRootScope;
                }
              }
            }
          }
          return config;
        },
        /*
         'response': function(response) {
         console.log(response);

         return response;
         },*/
        responseError: function (rejection) {
          //ErreurCollabService.error(rejection);
          $rootScope.loadingMain = false;
          switch(rejection.status){
            case 400:
              ErreurCollabService.onResponseError(rejection);
              break;

            case 401:
              if($location.path() !== '/login') $rootScope.$broadcast('erreur401');
              break;

            case 500:
              ErreurCollabService.onResponseError(rejection);
              //$rootScope.$broadcast('erreur500');
              break;

            case 502:
              $rootScope.$broadcast('erreur502');
              break;

            case 503:
              $rootScope.$broadcast('erreur503');
              break;

            default:
              ErreurCollabService.onResponseError(rejection);
          }
          return $q.reject(rejection);
        }
      };
  }

})();
