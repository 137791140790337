(function () {
  'use strict';

  /**
   * @desc Permet de choisir un groupe parent
   * @example <choose-groupe-parent></choose-groupe-parent>
   */

  angular
    .module('collaboreApp')
    .component('chooseGroupeParent', {
      templateUrl: 'app/components/choose-groupe-parent/choose-groupe-parent.component.html',
      transclude: true,
      bindings: {
        listGroupe: '<',
        listGroupeToExclude: '<',
        btnClass: '@',
        addMe: '<',
        mode: '@',    // popover ou autre
        portefeuille: '<',  // Portefeuille ou idPortefeuille
        disabled: '<',
        onEmit: '&',

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<'
      },
      controllerAs: 'choosegroupeparentctrl',
      controller: ChooseGroupeParentController
    });

  /*@ngInject*/
  function ChooseGroupeParentController(GroupeService, UtilsService) {

    var _this = this;
    _this.loadingComponent = false;
    _this.errorComponent = null;

    _this.idPortefeuille = null;

    _this.popoverIsOpen = false;

    _this.onClickOpenPopover = onClickOpenPopover;
    _this.onClickClose = onClickClose;
    _this.onClickSave = onClickSave;

    _this.$onInit = function() {

      if(_this.resolve && _this.modalInstance) {
        _this.listGroupe = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'listGroupe'));
        _this.mode = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'mode'));
        _this.portefeuille = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'portefeuille'));
        _this.disabled = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'disabled'));
        _this.addMe = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'addMe'));
        _this.btnClass = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'btnClass'));
        _this.listGroupeToExclude = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'listGroupeToExclude'));
      }

      _this.mode = 'popover'; // Force ce mode pour le momment car les autre n'existe pas encore
      init();
    };

    _this.$onChanges = function(changes) {
      if(_.isObject(changes.listGroupe) && !changes.listGroupe.isFirstChange() && _.isArray(changes.listGroupe.currentValue)){
        _this.listGroupe = changes.listGroupe.currentValue;
        init();
      }
      if(_.isObject(changes.listGroupeToExclude) && !changes.listGroupeToExclude.isFirstChange() && _.isArray(changes.listGroupeToExclude.currentValue)){
        _this.listGroupeToExclude = changes.listGroupeToExclude.currentValue;
      }
    };

    _this.$onDestroy = function(){
    };

    function init(){
      if(_.isNumber(_this.portefeuille)) _this.idPortefeuille = _this.portefeuille;
      else if(_.isObject(_this.portefeuille)) _this.idPortefeuille = _this.portefeuille.getIdPortefeuille();

      if(_.isNil(_this.btnClass)) _this.btnClass = "btn-default";

      initListGroupeParent();
    }

    function callGroupeParent(){
      _this.listGroupeParent = [];
      _this.loading = true;
      GroupeService
        .searchListGroupeParent(_this.idPortefeuille)
        .then(function(groupes){
          _this.listGroupe = angular.copy(groupes);
          initListGroupeParent();
        })
        .finally(function(){
          _this.loading = false;
        });
    }

    function initListGroupeParent(){
      if(_.isArray(_this.listGroupe)) {
        if(_this.addMe && _.findIndex(_this.listGroupe, {'idGroupe': UtilsService.getCurrentUser().getIdGroupe()}) === -1)
          _this.listGroupe.push(UtilsService.getCurrentUser());

        _this.listGroupeParent = angular.copy(_this.listGroupe);
        if(_.isArray(_this.listGroupeToExclude) && _this.listGroupeToExclude.length) {
          _this.listGroupeParent = _.differenceBy(_this.listGroupeParent, _this.listGroupeToExclude, 'idGroupe');
        }
      }
    }

    function uncheckListGroupeParent(){
      if(_.isArray(_this.listGroupeParent) && _this.listGroupeParent.length) {
        _this.listGroupeParent = _this.listGroupeParent.map(function(groupe){
          delete groupe.checked;
          return groupe;
        });
      }
    }

    function onClickOpenPopover(){
      if(_this.mode === 'popover' && _.isNil(_this.listGroupe)){
        callGroupeParent();
      }
      else if(_.isArray(_this.listGroupeToExclude) && _this.listGroupeToExclude.length) {
        _this.listGroupeParent = angular.copy(_this.listGroupe);
        _this.listGroupeParent = _.differenceBy(_this.listGroupeParent, _this.listGroupeToExclude, 'idGroupe');
      }
      //uncheckListGroupeParent();
    }

    function onClickSave(){
      var listGroupeChecked = _this.listGroupeParent.reduce(function(list, groupe){
        if(groupe.checked) list.push(groupe);
        return list;
      }, []);
      _this.popoverIsOpen = false;
      emit({
        action: 'validSelection',
        listGroupeCollaborateurChecked: listGroupeChecked
      });
    }

    /**
     * Si ouvert en mode Modal
     */
    function onClickClose(){
      _this.popoverIsOpen = false;
      if(_this.modalInstance) _this.dismiss({$value: 'cancel'});
      else emit({action: 'closeChooseGroupeParent'});
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      if(_this.modalInstance) _this.close({$value: objRetour});
      else _this.onEmit({obj: objRetour});
    }


  }
})();
