(function() {
  'use strict';

  angular
    .module('collaboreApp')
    .factory('GroupeAssociationProprietaire', GroupeAssociationProprietaireModel);

  /** @ngInject */
  function GroupeAssociationProprietaireModel(GroupeAssociation) {

    function GroupeAssociationProprietaire(contenuData) {
      GroupeAssociation.apply(this, arguments);
    }

    GroupeAssociationProprietaire.prototype = {
      model: 'GroupeAssociationProprietaire',
      isGroupeAssociationProprietaire: true,
    };

    angular.extend(GroupeAssociationProprietaire.prototype, GroupeAssociation.prototype);

    return GroupeAssociationProprietaire;
  }
})();
