'use strict';

/**
 * @ngdoc service
 * @name collaboreApp.StatesService
 * @description
 * # StatesService
 * Service in the collaboreApp.
 */
angular.module('collaboreApp').service('StatesService', ['State',function(State) {

    var scope = this;

    scope.getListeStates = function(){
        return [
            {
                'idState': 1,
                'idNom': 5,
                'nom': 'Normal',
                'type': 1
            },
            {
                'idState': 3,
                'idNom': 3,
                'nom': 'Caché',
                'type': 1
            },

            {
                'idState': 5,
                'idNom': 1,
                'nom': 'Coup de coeur',
                'type': 1
            },
            {
                'idState': 8,
                'idNom': 2,
                'nom': 'Archivé',
                'type': 1
            },
            {
                'idState': 10,
                'idNom': 4,
                'nom': 'Spam',
                'type': 1
            },

            {
                'idState': 4,
                'idNom': 1,
                'nom': 'Publique',
                'type': 3
            },
            {
                'idState': 6,
                'idNom': 2,
                'nom': 'Agence',
                'type': 3
            },
            {
                'idState': 2,
                'idNom': 3,
                'nom': 'Privé',
                'type': 3
            },


            {
                'idState': 7,
                'idNom': 1,
                'nom': 'Pas lu',
                'type': 2
            },

            {
                'idState': 9,
                'idNom': 2,
                'nom': 'Lu',
                'type': 2
            }
        ];
    };


    scope.getListeStatesByType = function(type){
        var states = [];
        angular.forEach(scope.getListeStates(),function(state){
            if(type===state.type){
                states.push(new State(state));
            }
        });
        return states;
    };

}]);
