(function () {
    'use strict';

    angular
        .module('collaboreApp')
        .component('dupliquerDevis', {
            templateUrl: 'app/topics/fournisseurs/dupliquer-devis/dupliquer-devis.component.html',
            controller: DupliquerDevisController,
            controllerAs: 'dupliquerdevisctrl',
            bindings: {
                demande:'<',
                onSubmit: '&',
                infosEvenement: '<',    // <Object> Infos de l'événement

                // Si Modal
                resolve: '<',
                close: '&',
                dismiss: '&'
            }
        });

    /** @ngInject */
    function DupliquerDevisController(COLLAB_CONF, $q, $timeout, TagService, DevisService, UtilsService, ModalsService) {
        var ctrl = this;

        ctrl.onChangeTemplate = onChangeTemplate;
        ctrl.onClickSave = onClickSave;
        ctrl.onClickClose = onClickClose;

        ctrl.$onInit = function () {

            ctrl.descriptionDevis = null;

            if(ctrl.resolve) {
                ctrl.isModal = true;
                ctrl.demande = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'demande'));
                ctrl.infosEvenement = angular.copy(UtilsService.getParamModalComponent(ctrl.resolve, 'infosEvenement'));
            }

            if(!ctrl.demande) ModalsService.alertErreur('Pas de demande');

            ctrl.currentTemplateMessage = null;
            if(ctrl.demande.isContenuDevisInter()) {
                ctrl.templatesMessage = UtilsService.getModulesTemplatesObjet('template_desc_devis');
                ctrl.descriptionDevis = angular.copy(ctrl.demande.getDescriptionDevis());
            }

            ctrl.checkboxDuplicateDevisSendMail = false;
            if(_.isObject(ctrl.infosEvenement)){
                ctrl.displayDemandeOptions = (!_.isNil(ctrl.infosEvenement.noPersoProprietaire));
                if(ctrl.displayDemandeOptions){
                  ctrl.checkboxHideProprietaireInDocument = UtilsService.getParametrage('hideProprietaireInDocument');
                }
            }
        };

        ctrl.$onDestroy = function () {
        };


        /**
         * Lors de la selection du model dans le champ select des template
         */
        function onChangeTemplate(typeChamp) {

            if (typeChamp === 'descDevis') {

                // Si un model est selectionné
                if (angular.isObject(ctrl.currentTemplateMessage)) {
                    // Si il y a des model event
                    if (ctrl.templatesMessage.length) {
                        // Pour chaque model
                        for (var m = 0; m < ctrl.templatesMessage.length; m++) {
                            var currentModel = ctrl.templatesMessage[m];


                            // Si le model de la boucle en cours est égale au model choisi
                            if (ctrl.currentTemplateMessage.idModuleValeurParams === currentModel.idModuleValeurParams) {
                                // Si il y a un objet
                                if (currentModel.objet !== '') ctrl.descriptionDevis = angular.copy(currentModel.objet);
                                break;
                            }
                        }
                    }
                }
                else ctrl.descriptionDevis = angular.copy(ctrl.demande.getDescriptionDevis());
            }
        }

        /**
         * Lors du clique sur le bouton sauvegarder
         */
        function onClickSave(){
            ctrl.loading = true;
            var params = {
                description: ctrl.descriptionDevis,
                sendMailWhenCreateDemande: ctrl.checkboxDuplicateDevisSendMail
            };
            if(!_.isNil(ctrl.checkboxHideProprietaireInDocument)){
              params.hideProprietaireInDocument = ctrl.checkboxHideProprietaireInDocument;
            }
            ctrl.demande
                .dupliquer(params)
                .then(function(contenu) {
                    emit({action: 'duplicateDemande', demande: contenu});
                })
                .finally(function(){
                    ctrl.loading = false;
                });
        }

        /**
         * Permet d'emit vers composant parent
         * @param objRetour
         */
        function emit(objRetour){
            if(ctrl.isModal) ctrl.close({$value: objRetour});
            else ctrl.onSubmit({obj: objRetour})
        }

        function onClickClose(){
            ctrl.dismiss({$value: 'cancel'});
        }
    }
})();

