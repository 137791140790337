(function() {
  'use strict';

  /**
   * @desc Bouton de menu avec l'avatar
   * @example <header-menu-collab></header-menu-collab>
   */

  angular
    .module('collaboreApp')
    .component('headerMenuCollab',{
      templateUrl: 'app/divers/header-menu-collab/header-menu-collab.component.html',
      controllerAs: 'headermenucollabctrl',
      bindings: {
        user: '<',
        avatarStyle: '<',
        rootscopeCurrent: '<'
      },
      controller: HeaderMenuCollabController
    });

  /*@ngInject*/
  function HeaderMenuCollabController(COLLAB_CONF, COLLAB_VALUES, $scope, $rootScope, UtilsService, FiltreService, ModalsService, hotkeys) {

    var ctrl = this;
    ctrl.authByKerberos = false;
    ctrl.showFeaturesICS = false;

    // Loading de l'avatar si chargement
    ctrl.loadingAvatar = COLLAB_CONF.MIDDLE_LOADING_SRC;

    // Url l'image si pas d'avatar
    ctrl.srcNoPhoto = COLLAB_CONF.SRC_NO_PHOTO;

    // Url du dossier des avatars
    ctrl.urlAvatar = COLLAB_VALUES.CONF_URL.URL_AVATARS;

    ctrl.logOut = logOut;
    ctrl.logOutOnelogin = logOutOnelogin;
    ctrl.onClickChangeSociete = onClickChangeSociete;
    ctrl.onClickOpenWizardCloture = onClickOpenWizardCloture;

    var avatarListener = null;
    ctrl.$onInit = function(){
      init();

      hotkeys.bindTo($scope)
        .add({
          combo: COLLAB_CONF.HOTKEYS_SHOW_FEATURES_ICS.combo,
          description: COLLAB_CONF.HOTKEYS_SHOW_FEATURES_ICS.description,
          callback: function() {
            ctrl.showFeaturesICS = !ctrl.showFeaturesICS;
          }
        });

      if(ctrl.user) {
        if (_.isString(ctrl.user.nomAvatar)) ctrl.avatar = ctrl.user.nomAvatar;
        else ctrl.avatar = false;
      }
      else{
        // Avatar 'nom.ext'
        ctrl.avatar = ctrl.groupeUser.getUser().getNomAvatar();

        avatarListener = $scope.$on('updateAvatar',function(event,avatar){
          if(avatar) ctrl.avatar = avatar;
          else ctrl.avatar = ctrl.groupeUser.getUser().getNomAvatar();
        });
      }
    };

    ctrl.$onChanges = function(changes) {
      if(!changes.rootscopeCurrent.isFirstChange() && changes.rootscopeCurrent.currentValue) {
        init();
      }
    };

    ctrl.$onDestroy = function(){
      if(!_.isNil(avatarListener)) avatarListener();
    };

    function init(){
      ctrl.groupeUser = UtilsService.getCurrentUser();
      ctrl.authByKerberos = ctrl.groupeUser.getAuthByKerberos();
      ctrl.paramsUrl = FiltreService.getObjFiltreByChannel();
      ctrl.shootingMailEnabled = UtilsService.getParametrage('shootingMailEnabled');
    }

    function logOut(){
      $rootScope.$broadcast('logOut');
    }
    function logOutOnelogin(){
      $rootScope.$broadcast('logOut', true);
    }

    function onClickChangeSociete(){
      ModalsService.modalComponent('chooseSociete', {changeDiese: true});
    }

    function onClickOpenWizardCloture(){
      var options = {
        backdrop: 'static'
      };

      ModalsService
        .modalComponent('wizardCloture', null, options)
        .then(function (objRetour) {

        })
        .catch(function (msg) {
          if (msg !== 'cancel') {
            ModalsService.alertErreur(msg);
          }
        });
    }
  }
})();
