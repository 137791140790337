(function() {
    'use strict';

    /**
     * @desc View des contrats dans l'événement
     * @example <view-list-contrats></view-list-contrats>
     */

    angular
        .module('collaboreApp')
        .component('viewListContrats',{
            templateUrl: 'app/topics/evenement/view-list-contrats/view-list-contrats.component.html',
            bindings: {
                idTopic: '<',
                contenuEvenement: '<',
                noModalCommunication: '<',
                condensedDisplay: '<',	// Permet de savoir si on doit faire un affichage plus condensé
                onEmit:'&'
            },
            controllerAs: 'viewlistcontratsctrl',
            controller: ViewListContrats
        });

    /*@ngInject*/
    function ViewListContrats(COLLAB_CONF, $rootScope, $timeout, UtilsService, TopicService, ModalsService, TagService, sweet){

        var ctrl = this;
        ctrl.notMobile = UtilsService.notMobile;
        ctrl.loading = true;
        ctrl.listDemande = [];
        ctrl.isVu = isVu;
        ctrl.onClickOpenCommunication = onClickOpenCommunication;
        ctrl.onClickAjouterDemande = onClickAjouterDemande;
        ctrl.onClickVoirDemande = onClickVoirDemande;
        ctrl.onEmitFromMenuDemande = onEmitFromMenuDemande;

        ctrl.onClickSendSMS = onClickSendSMS;                                      // Lors du clique sur le bouton pour envoyer un SMS
        ctrl.onClickSendEmail = onClickSendEmail;                                  // Lors du clique sur le bouton pour envoyer un EMAIL


        ctrl.$onInit = function(){
            if(ctrl.contenuEvenement) {
                init(ctrl.contenuEvenement);
                ctrl.loading = false;
            }
            else if(ctrl.idTopic) {
                TopicService
                    .getEvenementForIdTopic(ctrl.idTopic)
                    .then(function(contenuEvenement){
                        init(contenuEvenement);
                    })
                    .catch(function(msg){
                        ModalsService.alertErreur(msg);
                    })
                    .finally(function() {
                        ctrl.loading = false;
                    });
            }
        };

        function init(contenuEvenement){
            if(!ctrl.contenuEvenement) ctrl.contenuEvenement = contenuEvenement;

            ctrl.infosEvenement = UtilsService.getInfosEvenement(ctrl.contenuEvenement);
            if(_.isObject(ctrl.infosEvenement) && _.isObject(ctrl.infosEvenement.portefeuille)) {
                ctrl.portefeuille = ctrl.infosEvenement.portefeuille;
            }

            /*
            var tagImmeuble = TagService.getTagForType(ctrl.tagsType, COLLAB_CONF.TAG_TYPE_IMMEUBLE);
            if(tagImmeuble) ctrl.retourModal.immeuble = tagImmeuble.getValeurIcs();

            var tagMandat = TagService.getTagForType(ctrl.tagsType, COLLAB_CONF.TAG_TYPE_MANDAT);
            if(tagMandat) ctrl.retourModal.mandat = tagMandat.getValeurIcs();

            var tagLot = TagService.getTagForType(ctrl.tagsType, COLLAB_CONF.TAG_TYPE_LOT);
            if(tagLot) ctrl.retourModal.lot = tagLot.getValeurIcs().substr(-4);

            var tagPortefeuille = TagService.getTagForType(ctrl.tagsType, COLLAB_CONF.TAG_TYPE_PORTEFEUILLE);
            if(tagPortefeuille && tagPortefeuille.getPortefeuille()) ctrl.idPortefeuille = tagPortefeuille.getPortefeuille().getIdPortefeuille()
            */

            ctrl.listDemande = contenuEvenement.listeContrats;
            if(!ctrl.idTopic) ctrl.idTopic = contenuEvenement.idTopic;
        }

        /**
         * Check si l'item est vu ou non
         * @param item
         * @returns {string}
         */
        function isVu(item) {
            if (item.getVuParFournisseur()) {
                return '<span class="label label-success"><i class="fa fa-eye"></i></span>';
            } else {
                return '<span class="label label-danger"><i class="fa fa-eye-slash"></i></span>';
            }
        }

        /**
         * Ouvre la box de communication et place sur le bon fournisseur
         * @param event
         * @param devis
         */
        function onClickOpenCommunication(idTopic, devis) {
            if(ctrl.noModalCommunication) ctrl.onEmit({obj:{action: 'onClickCommunication', idTopic: idTopic, devis: devis}})
            else {
                ModalsService.modalCommunication(idTopic, function(){
                    $timeout(function(){
                        $rootScope.$broadcast('openMessageForDevis',devis);
                    });
                });
            }
        }

        function onClickAjouterDemande(devis){
            ctrl.onEmit({obj:{action: 'onClickAjouterDemandeContrat'}})
        }

        function onClickVoirDemande(demande){
            ctrl.onEmit({obj:{action: 'onClickVoirDemande', demande: demande}})
        }

        /**
         * Ouvre la modal pour envoyer un SMS
         */
        function onClickSendSMS(devis) {

            var obj = {
                contenu: devis,
                defaultParams: {
                    idTopic: ctrl.idTopic
                }
            };

            if(_.isObject(ctrl.portefeuille) &&  _.isObject(ctrl.portefeuille.portefeuilleInfos)) {
                obj.defaultParams.prefixSms = ctrl.portefeuille.portefeuilleInfos.prefixSms;
            }
            ModalsService.modalSendSMS(obj, false, function (contenuMessage) {
                $rootScope.$broadcast('addMessage', contenuMessage);
            });
        }

        /**
         * Lors du clique sur le bouton pour ouvrir la modal d'envoi de mail
         */
        function onClickSendEmail(devis){
            var options = {
                backdrop: 'static'
            };
            //var copyDestinataire = angular.copy(_this.demande.getDestinataire());
            //copyDestinataire.typeDestinataire = 'fournisseur';
            var params = {
                contenuParent: devis,
                typeFormulaire: 'email',
                chooseContact: false,
                useMiniToolbar: true,
                disallowSelectType: true,
                disallowChooseDestinataires: false,
                destinataires: [],
                destinatairesHide: (_.isObject(ctrl.infosEvenement) && _.isArray(ctrl.infosEvenement.listDestinatairesHide)) ? ctrl.infosEvenement.listDestinatairesHide : null
            };

            ModalsService
                .modalComponent('formulaireMessage', params, options)
                .then(function (objet) {
                    $rootScope.$broadcast('addMessage', objet.contenu);
                })
                .catch(function (msg) {
                    if (msg !== 'cancel') ModalsService.alertErreur(msg);
                });
        }

        /**
         * Lorsque l'on reçoit une action du composant menu-demande
         * @param obj
         */
        function onEmitFromMenuDemande(obj){
            ctrl.onEmit({obj: obj});
        }
    }
})();
