(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.MessageService
   * @description
   * # MessageService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('MessageService', MessageService);

  /*@ngInject*/
  function MessageService(ContenuMessage, ProxyContenuMessage, MainService, $q, MessageAjax, ModalsService) {

    var ctrl = this;

    ctrl.addMessageInTopic = addMessageInTopic;
    ctrl.updateMessage = updateMessage;
    ctrl.ContenuMessage = ContenuMessage;
    ctrl.ProxyContenuMessage = ProxyContenuMessage;

    function postServlet(action, obj) {
      var deferred = $q.defer();
      try {
        if (!action) throw new Error('Le paramètre "action" n\'existe pas !');

        if (!angular.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet  !');
        if (angular.isObject(obj) && !obj.hasOwnProperty('listObjJSON') && !obj.hasOwnProperty('objJSON') && !obj.hasOwnProperty('idTopic')) throw new Error('Le paramètre "obj" doit avoir un objJSON OU listObjJSON OU idTopic !');
        //if (angular.isObject(obj) && !obj.hasOwnProperty('listObjJSON') && !obj.hasOwnProperty('objJSON')) throw new Error('Le paramètre "obj" doit avoir un objJSON OU listObjJSON !');

        /*
        if(obj.hasOwnProperty('listObjJSON') && angular.isArray(obj.listObjJSON) && obj.listObjJSON.length){
          for(var i = 0; i < obj.listObjJSON.length; i++) {
            obj.listObjJSON[i] = angular.toJson(obj.listObjJSON[i]);
          }
        }*/
        if(obj.hasOwnProperty('listObjJSON')) obj.listObjJSON = angular.toJson(obj.listObjJSON);
        if(obj.hasOwnProperty('objJSON')) obj.objJSON = angular.toJson(obj.objJSON);

        var sendParams = angular.merge({}, obj);
        sendParams.action = action;
        MessageAjax
          .post(sendParams, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, ['listMessage', 'contenuMessage'], 'ContenuMessage', deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Permet d'ajouter des messages dans un topic
     * @param obj
     */
    function addMessageInTopic(obj) {
      var deferred = $q.defer();
      try {
        if (!angular.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet  !');
        if (angular.isObject(obj) && !obj.hasOwnProperty('listObjJSON') && !obj.hasOwnProperty('objJSON') && !obj.hasOwnProperty('idTopic')) throw new Error('Le paramètre "obj" doit avoir un objJSON OU listObjJSON OU idTopic !');

        if(obj.hasOwnProperty('listProxyDestinataire')) obj.listProxyDestinataire = angular.toJson(obj.listProxyDestinataire);
        if(obj.hasOwnProperty('listObjJSON')) obj.listObjJSON = angular.toJson(obj.listObjJSON);
        if(obj.hasOwnProperty('objJSON')) obj.objJSON = angular.toJson(obj.objJSON);

        var sendParams = angular.merge({}, obj);
        sendParams.action = 'addMessage';

        MessageAjax
          .post(sendParams, function (json) {
            if(json.success) {
              var retour = null;
              var ContenuMessage = MainService.$injector.get('ContenuMessage');
              if(json.hasOwnProperty('contenuMessage') && angular.isObject(json.contenuMessage))  {
                if(!retour) retour = {};
                retour.message = new ContenuMessage(json.contenuMessage);
              }
              if(json.hasOwnProperty('listMessage') && angular.isArray(json.listMessage) && json.listMessage.length) {
                if(!retour) retour = {};
                retour.listMessage = json.listMessage.map(function(mess){
                  return new ContenuMessage(mess);
                });
              }
              ContenuMessage = null;

              if(json.hasOwnProperty('evenement') && angular.isObject(json.evenement))  {
                if(!retour) retour = {};
                var ContenuEvenement = MainService.$injector.get('ContenuEvenement');
                retour.evenement = new ContenuEvenement(json.evenement);
                ContenuEvenement = null;
              }

              deferred.resolve(retour);
            }
            else deferred.reject(json.message);

          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
          ModalsService.alertErreur(e.message);
          deferred.reject(e.message);
        }

      /*
      postServlet('addMessage', obj)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });*/
      return deferred.promise;
    }

    /**
     * Permet d'update un message
     * @param obj
     */
    function updateMessage(obj) {
      var deferred = $q.defer();
      try {
        if (!angular.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet  !');
        if (angular.isObject(obj) && (!obj.hasOwnProperty('idContenu') || !obj.hasOwnProperty('message'))) throw new Error('Le paramètre "obj" doit avoir un idContenu ET un message !');

        var sendParams = angular.merge({}, obj);
        sendParams.action = 'updateMessage';

        MessageAjax
          .post(sendParams, function (json) {
            if(json.success) {
              var retour = null;
              var ContenuMessage = MainService.$injector.get('ContenuMessage');
              if(json.hasOwnProperty('contenuMessage') && angular.isObject(json.contenuMessage))  {
                retour = {
                  message: new ContenuMessage(json.contenuMessage)
                };
              }
              ContenuMessage = null;
              deferred.resolve(retour);
            }
            else deferred.reject(json.message);

          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }
  }

})();
