(function() {
	'use strict';

    angular.module('collaboreApp').factory('ModuleType', ModuleType);

    /** @ngInject */
	function ModuleType(ModuleRefParam){

		var ModuleTypeConstructor = function(data) {
			this.setData(data);
		};

		ModuleTypeConstructor.prototype = {

			isModel: true,

			setData: function(data){
				angular.extend(this, data);

				if(angular.isObject(data)){
					if(data.hasOwnProperty('listeParametres')){
						this.setListeParametres(data.listeParametres);
					}
				}

			},

			// idModuleType
			setIdModuleType: function(idType){
				this.idModuleType = idType;
			},
			getIdModuleType: function(){
				return this.idModuleType;
			},

			// Libelle
			setLibelle: function(lib){
				this.libelle = lib;
			},
			getLibelle: function(){
				return this.libelle;
			},

			// TypeObjet
			setTypeObjet: function(val){
				this.typeObjet = val;
			},
			getTypeObjet: function(){
				return this.typeObjet;
			},

			/**
			 * La liste des param�tres est dans le cas o�
			 * @param arrayInstanceListeParametres
             */
			setListeParametres: function(arrayInstanceListeParametres){
				var array = [];
				angular.forEach(arrayInstanceListeParametres,function(arrayInstanceParametre){
					if(!arrayInstanceParametre.isModel){
						array.push(new ModuleRefParam(arrayInstanceParametre));
					}else{
						array.push(arrayInstanceParametre);
					}
				});
				this.listeParametres = array;
			},
			getListeParametres: function(){
				return this.listeParametres;
			}
		};

		return ModuleTypeConstructor;
	}


})();