(function () {
  "use strict";

  /**
   * @desc Permet de rechercher et choisir un ou plusieur immeubles
   * @example <choose-immeuble></choose-immeuble>
   */

  angular.module("collaboreApp").component("chooseImmeuble", {
    templateUrl:
      "app/components/choose-immeuble/choose-immeuble.component.html",
    bindings: {
      search: "<",
      portefeuille: "<",
      immeubles: "<",
      allowToEdit: '<',
      onEmit: "&",
    },
    controllerAs: "chooseimmeublectrl",
    controller: ChooseImmeubleController,
  });

  /*@ngInject*/
  function ChooseImmeubleController(
    $scope,
    $q,
    $timeout,
    AnnuaireService,
    UtilsService,
    ContratIcsCloudService,
    ComfactService
  ) {
    var _this = this;
    _this.loading = false;
    _this.defaultSearch = "";
    _this.lastSearch = "";
    _this.selectedImmeuble = null;
    _this.forceChoose = true;
    _this.listIdPortefeuille = [];
    _this.listPortefeuille = [];
    _this.listImmeuble = [];

    _this.groupeBy = groupeBy;
    _this.searchImmeuble = searchImmeuble;
    _this.onSelect = onSelect;
    _this.onKeyUp = onKeyUp;
    _this.onKeyDown = onKeyDown;
    _this.onClickClearImmeuble = onClickClearImmeuble;

    var listenerFocus = null;
    _this.$onInit = function () {
      init();

      listenerFocus = $scope.$on("focusImmeuble", function () {
        $timeout(function () {
          _this.focusClear = true;
          $scope.$broadcast("focusSelectImmeuble");
        });
      });
    };

    _this.$onChanges = function (changes) {
      //console.log(changes);
      if(_.isObject(changes.portefeuille)){
        if (_.isNil(_this.portefeuille)) {
          var listPortefeuille =
            UtilsService.getCurrentUser().getListePortefeuille();
          if (_.isArray(listPortefeuille)) {
            if (listPortefeuille.length === 1)
              _this.portefeuille = listPortefeuille[0];

            _this.listIdPortefeuille = listPortefeuille.map(function (port) {
              return port.idPortefeuille;
            });
          }
        } else _this.listIdPortefeuille = [_this.portefeuille.idPortefeuille];
      }
      if(_.isObject(changes.immeubles)){
        if(_.isArray(changes.immeubles.currentValue)){
          var listImm = [];
          if(_this.immeubles.length){
            for(var i = 0; i < _this.immeubles.length; i++){
              var imm = ComfactService.initObjetImmeuble(_this.immeubles[i]);
              imm.group = 'contrat';
              listImm.push(imm);
            }
          }
          _this.listImmeuble = listImm;

          if(_this.listImmeuble.length === 1) {
            setSelectedImmeuble(_this.listImmeuble[0], true);
            _this.selectedImmeuble =
            _.isObject(_this.listImmeuble[0]) && !_this.listImmeuble[0].isObjetImmeuble ? ComfactService.initObjetImmeuble(_this.listImmeuble[0]) : _this.listImmeuble[0];
          _this.focusClear = false;
          }
          refreshViewSelect();
        }
      }

    };

    _this.$onDestroy = function () {
      if(!_.isNil(listenerFocus)) listenerFocus();
    };

    function refreshViewSelect(){
      $timeout(function() {
        var uiSelect = angular.element("[name=chooseImm]").controller('uiSelect');
        if(!_.isNil(uiSelect)){
          uiSelect.items = _this.listImmeuble;
          uiSelect.refreshing = true;
        }
      });
    }

    /**
     * Permet d'init le composant
     */
    function init() {
      var search = null;
      if (_.isObject(_this.search)) {
        if (_this.search.isTag) search = _this.search.getValeurIcs();
        if (_this.search.isImmeuble) search = _this.search.getNumImmeuble();

        _this.forceChoose = false;
        setSelectedImmeuble(_this.search, true);
      } else if (_.isString(_this.search)) search = _this.search;



      if (!_.isNil(search)) {
        _this.defaultSearch = angular.copy(search);
        _this.lastSearch = angular.copy(search);
        if (_.isNil(_this.selectedImmeuble)) {
          searchImmeuble(search, true);
        }
      }
    }

    /**
     * Permet d'appeler ICSCloud pour récupérer les immeubles
     * @returns {*}
     */
    function callImmeubleICSCloud(search) {
      var deferred = $q.defer();

      var paramsBase = {
        avecemail: 0,
        type: "I",
        recherche: search,
        idPortefeuilles: _this.listIdPortefeuille,
        commencePar: false,
      };

      AnnuaireService.searchCollaborateur(paramsBase)
        .then(function (objectCollaborateur) {
          var listImm = [];

          // Si il y a des perso ou immeuble
          if (
            _.isObject(objectCollaborateur) &&
            _.isArray(objectCollaborateur.listePortefeuilles) &&
            objectCollaborateur.listePortefeuilles.length
          ) {
            objectCollaborateur.listePortefeuilles.map(function (portefeuille) {
              if (
                _.isArray(portefeuille.getListeImmeuble()) &&
                !_.isEmpty(portefeuille.getListeImmeuble())
              ) {
                for (
                  var i = 0;
                  i < portefeuille.getListeImmeuble().length;
                  i++
                ) {
                  var imm = angular.copy(portefeuille.getListeImmeuble()[i]);
                  listImm.push(ComfactService.initObjetImmeuble(imm));
                }
              }
            });
          }
          deferred.resolve(listImm);
        })
        .catch(function (err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function searchImmeubleFromContrat(fournisseur) {
      var deferred = $q.defer();

      if (
        !_.isNil(_this.selectedImmeuble) ||
        _.isNil(fournisseur) ||
        _.isNil(fournisseur.codeMetier) ||
        _.isNil(fournisseur.numFournisseur)
      ) {
        deferred.resolve([]);
        return deferred.promise;
      }

      ContratIcsCloudService.searchListImmeuble(
        fournisseur.codeMetier,
        fournisseur.numFournisseur,
        _this.portefeuille
      )
        .then(function (immeubles) {
          var listImm = [];
          if(_.isArray(immeubles) && immeubles.length){
            for(var i = 0; i < immeubles.length; i++){
              var imm = ComfactService.initObjetImmeuble(immeubles[i]);
              imm.group = 'contrat';
              listImm.push(imm);
            }
          }
          deferred.resolve(_.concat(_this.listImmeuble, listImm));
        })
        .catch(function (err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    /**
     * Recherche les immeubles dans ICSCloud
     * @param search
     * @param firstSearch
     * @returns {*}
     */
    function searchImmeuble(search, firstSearch, fournisseur) {
      if (_.isNil(search)) search = _this.lastSearch;
      if (
          (_.isNil(search) || _.isEmpty(search) ||  (_this.lastSearch === search && !firstSearch))
          &&
          (_.isNil(fournisseur) || !_.isNil(_this.selectedImmeuble))
      )
        return $q.reject();

      _this.listImmeuble = [];
      _this.lastSearch = angular.copy(search);

      _this.loadingSearch = true;
      var promises = [];

      if (!_.isNil(fournisseur) && _.isNil(_this.selectedImmeuble)) {
        if (firstSearch !== false) _this.loading = true;
        _this.lastSearch = "";
        promises.push(searchImmeubleFromContrat(fournisseur));
      }
      else if(!_.isNil(search) && !_.isEmpty(search)) {
        if (firstSearch) _this.loading = true;
        promises.push(callImmeubleICSCloud(search));
      }

      return $q
        .all(promises)
        .then(function (list) {
          var listImm = list[0];

          if (
            !_this.forceChoose &&
            !_.isNil(_this.defaultSearch) &&
            !_.isEmpty(_this.defaultSearch) &&
            listImm.length === 1
          )
            setSelectedImmeuble(listImm[0]);

          _this.listImmeuble = _.concat(_this.listImmeuble, listImm);
        })
        .finally(function () {
          _this.loadingSearch = false;
          _this.loading = false;
          refreshViewSelect();
        });
    }

    function groupeBy(item){
      var sugg = "Suggestion(s)";
      //var sugg = "Suggestion(s) pour les contrats du fournisseur";
      //if(item.group === 'contrat' && _.isObject(_this.fournisseur) && !_.isNil(_this.fournisseur.display())) sugg += " " + _this.fournisseur.display();
      return (item.group === 'contrat') ? sugg : "ICS";
    }

    /**
     * Lors de la selection d'un immeuble
     * @param item
     */
    function onSelect(item) {
      setSelectedImmeuble(item);
    }

    function setSelectedImmeuble(obj, onInit) {
      _this.selectedImmeuble =
        _.isObject(obj) && !obj.isObjetImmeuble ? ComfactService.initObjetImmeuble(obj) : obj;
      _this.focusClear = false;
      var objEmit = {
        action: "selectedImmeuble",
        next: !onInit,
        onInit: onInit,
        immeuble: _.isObject(_this.selectedImmeuble)
          ? _this.selectedImmeuble.value
          : null,
        libelleImmeuble: _.isObject(_this.selectedImmeuble)
          ? _this.selectedImmeuble.display()
          : null,
      };
      emit(objEmit);
    }

    function onKeyUp(search){
      if(_this.lastSearch != search && _.isEmpty(search)) searchImmeuble(null, false);
    }

    function onKeyDown(event) {
      if (event.keyCode === 9) {
        if (event.shiftKey) emit({ action: "previousField" });
        else emit({ action: "nextField" });
        _this.focusClear = false;
      }
    }

    function onClickClearImmeuble() {
      clearImmeuble();
    }

    function clearImmeuble(silent) {
      _this.listImmeuble = [];
      _this.selectedImmeuble = null;
      _this.filtreImmeuble = null;
      _this.forceChoose = true;
      _this.focusClear = false;
      _this.lastSearch = "";
      if(!silent) {
        emit({ action: "selectedImmeuble", next: false, immeuble: null });
        searchImmeuble(null, true).finally(function () {
          $timeout(function () {
            $scope.$broadcast("focusSelectImmeuble");
          });
        });
      }
    }

    function emit(obj) {
      _this.onEmit({ obj: obj });
    }
  }
})();
