(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.ContenuInformations
   * @description
   * # ContenuInformations
   * Service in the collaboreApp.
   */

  angular.module('collaboreApp').factory('ContenuInformations', ContenuInformationsModel);

  /** @ngInject */
  function ContenuInformationsModel(Main) {

    var ContenuInformations = function(objetData) {
      Main.call(this, objetData);
    };

    ContenuInformations.prototype = {

      isContenuInformations: true,
      model: 'ContenuInformations',

      // idTopic
      getIdTopic: function(){
        return this.idTopic;
      },
      setIdTopic: function(id){
        this.idTopic = id;
      },

      // titreTopic
      getTitreTopic: function(){
        return this.titreTopic;
      },
      setTitreTopic: function(id){
        this.titreTopic = id;
      },

      // idDevis
      getIdDevis: function(){
        return this.idDevis;
      },
      setIdDevis: function(id){
        this.idDevis = id;
      },

      // libelleDevis
      getLibelleDevis: function(){
        return this.libelleDevis;
      },
      setLibelleDevis: function(id){
        this.libelleDevis = id;
      },

      // idFacture
      getIdFacture: function(){
        return this.idFacture;
      },
      setIdFacture: function(id){
        this.idFacture = id;
      },

      // libelleFacture
      getLibelleFacture: function(){
        return this.libelleFacture;
      },
      setLibelleFacture: function(id){
        this.libelleFacture = id;
      },

      // nbFacture
      getNbFacture: function(){
        return this.nbFacture;
      },
      setNbFacture: function(nb){
        this.nbFacture = nb;
      },

      // nbFactureNoComfact
      getNbFactureNoComfact: function(){
        return this.nbFactureNoComfact;
      },
      setNbFactureNoComfact: function(nb){
        this.nbFactureNoComfact = nb;
      },

      // topicState
      getTopicState: function(){
        return this.topicState;
      },
      setTopicState: function(topicState){
        if(angular.isObject(topicState)) {

          if(!topicState.isModel) {
            var TopicStates = Main.$injector.get('TopicStates');
            topicState = new TopicStates(topicState);
          }
          this.topicState = topicState;
        }
      },

      // relanceAllowed
      getRelanceAllowed: function(){
        return this.relanceAllowed;
      },
      setRelanceAllowed: function(nb){
        this.relanceAllowed = nb;
      },
      relanceIsAllowed: function(){
        // Si c'est null ou true
        return (_.isNil(this.getRelanceAllowed()) || (_.isBoolean(this.getRelanceAllowed()) && this.getRelanceAllowed()));
      }
    };

    angular.extend(ContenuInformations.prototype, Main.prototype);

    return ContenuInformations;
  }
})();
