(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name collaboreApp.NatureSinistre
     * @description
     * # NatureSinistre
     * Service in the collaboreApp.
     */

    angular.module('collaboreApp').factory('NatureSinistre', NatureSinistreModel);

    /** @ngInject */
    function NatureSinistreModel(Main) {

        function NatureSinistre(objetData) {
            Main.apply(this, arguments);
            this.hydrate(objetData);
        }

        NatureSinistre.prototype = {

            model: 'NatureSinistre',
            isNatureSinistre: true,

            // Id Nature Sinistre
            getIdNatureSinistre: function(){
                return this.idNatureSinistre;
            },
            setIdNatureSinistre: function(val){
                this.idNatureSinistre = val;
            },

            // Id Nature Sinistre Global
            getIdNatureSinistreGlobal: function(){
                return this.idNatureSinistreGlobal;
            },
            setIdNatureSinistreGlobal: function(val){
                this.idNatureSinistreGlobal = val;
            },

            // metier
            getMetier: function(){
                return this.metier;
            },
            setMetier: function(val){
                this.metier = val;
            },

            // libelle
            getLibelle: function(){
                return this.libelle;
            },
            setLibelle: function(val){
                this.libelle = val;
            },
            getMetierAndLibelle: function(){
                return '['+this.metier+'] '+this.libelle;
            }

        };

        angular.extend(NatureSinistre.prototype, Main.prototype);

        return NatureSinistre;
    }
})();