(function () {
  'use strict';


  /**
   * @ngdoc service
   * @name collaboreApp.Notification
   * @description
   * # Notification
   * Service in the collaboreApp.
   */
  angular.module('collaboreApp').factory('Notification', NotificationModel);

  function NotificationModel (Main, MainService, Topic, Groupe, State, UtilsService) {

    function Notification(objet) {
      Main.call(this, objet);
    }

    Notification.prototype = {

      model: 'Notification',
      isNotifications: true,

      /*
            setData: function(notificationData) {
                angular.extend(this, notificationData);

                if(notificationData.state){
                    this.setState(notificationData.state);
                    this.setStateTopic(notificationData.stateTopic);
                    //this.setState(new State(objetData.state));
                }
            },*/

      getIdNotification: function(){
        return parseInt(this.idNotification);
      },

      // Id Topic
      getIdTopic: function(){
        return parseInt(this.idTopic);
      },
      setIdTopic: function(valInt){
        this.idTopic = valInt;
      },

      // Topic
      getTopic: function(){
        return this.topic;
      },
      setTopic: function(topic){
        var instanceTopic = topic;
        if(angular.isObject(topic) && !topic.hasOwnProperty('isModel')){
          instanceTopic = new Topic(topic);
        }
        this.topic = instanceTopic;
      },

      // TopicInformations
      getTopicInformations: function(){
        return this.topicInformations;
      },
      setTopicInformations: function(infos){
        if(angular.isObject(infos)){
          if(!infos.isModel) {
            var TopicInformations = Main.$injector.get('TopicInformations');
            infos = new TopicInformations(infos);
            TopicInformations = null;
          }
          this.topicInformations = infos;
        }
      },

      // Id Contenu
      getIdContenu: function(){
        return parseInt(this.idContenu);
      },
      setIdContenu: function(valInt){
        this.idContenu = valInt;
      },

      // contenu
      getContenu: function(){
        return this.contenu;
      },
      setContenu: function(val){
        this.contenu = MainService.initContenuByType(val);
      },

      getLibelle: function(){
        var libelle = '';
        switch(this.typeNotification) {

          // Nouvelle demande de devis
          case 'creationDevis':
            // Si current user est le même que l'expediteur de la notifications
            if(this.getGroupeEnvoyeur() && UtilsService.isSameDiese(this.getGroupeEnvoyeur().getDieze()) && this.getDestinataireDefaultNomForContenuDevis()) {
              libelle = 'Demande <span class="label label-xs label-bordered label-bleu-collabore">DEVIS</span> pour <strong>' + this.getDestinataireDefaultNomForContenuDevis() + '</strong>';
            }
            else libelle = 'Nouvelle demande <span class="label label-xs label-bordered label-bleu-collabore">DEVIS</span>';
            break;

          // Devis accepté par le Syndic
          case 'accepteDevisParSyndic':
            libelle = '<span class="text-success"><span class="label label-xs label-bordered label-bleu-collabore">DEVIS</span> accepté par <strong>' + this.getGroupeEnvoyeurDefaultNom() + '</strong></span>';
            break;

          // Devis refusé par le Syndic
          case 'refusDevisParSyndic':
            libelle = '<span class="text-danger"><span class="label label-xs label-bordered label-bleu-collabore">DEVIS</span> refusé par <strong>' + this.getGroupeEnvoyeurDefaultNom() + '</strong></span>';
            break;

          // Reception Devis
          case 'nouveauDocumentDevis':
            var nomExpediteurDocumentDevis = this.getGroupeEnvoyeurDefaultNom();
            if(this.getDestinataireDefaultNomForContenuDevis()) nomExpediteurDocumentDevis = this.getDestinataireDefaultNomForContenuDevis();
            libelle = 'Nouveau <span class="label label-xs label-bordered label-bleu-collabore">DEVIS</span> du fournisseur <strong>' + nomExpediteurDocumentDevis + '</strong>';
            break;

          // Reception d'un nouveau document d'un fournisseur
          case 'nouveauDocumentFournisseur':
            var nomExpediteurDocument = this.getGroupeEnvoyeurDefaultNom();
            if(this.getDestinataireDefaultNomForContenuDevis()) nomExpediteurDocument = this.getDestinataireDefaultNomForContenuDevis();
            libelle = 'Nouveau document du fournisseur <strong>' + nomExpediteurDocument + '</strong>';
            break;

          // Nouvelle intervention
          case 'creationIntervention':
            // Si current user est le même que l'expediteur de la notifications
            if(this.getGroupeEnvoyeur() && UtilsService.isSameDiese(this.getGroupeEnvoyeur().getDieze()) && this.getDestinataireDefaultNomForContenuDevis()) {
              libelle = 'Demande <span class="label label-xs label-bordered label-rouge-collabore">INTERVENTION</span> pour <strong>' + this.getDestinataireDefaultNomForContenuDevis() + '</strong>';
            }
            else libelle = 'Nouvelle demande <span class="label label-xs label-bordered label-rouge-collabore">INTERVENTION</span>';
            break;

          // Reception Facture
          case 'nouveauDocumentFacture':
            var nomExpediteurDocumentFacture = this.getGroupeEnvoyeurDefaultNom();
            if(this.getDestinataireDefaultNomForContenuDevis()) nomExpediteurDocumentFacture = this.getDestinataireDefaultNomForContenuDevis();
            libelle = 'Nouvelle facture du fournisseur <strong>' + nomExpediteurDocumentFacture + '</strong>';
            break;

          // Nouveau message
          case 'nouveauMessage':
            var nomExpediteurNewMessage = this.getGroupeEnvoyeurDefaultNom();
            libelle = 'Nouveau message de <strong>' + nomExpediteurNewMessage + '</strong>';
            break;

          default:
            libelle = this.getMessage();
        }
        return libelle;
      },

      // Message
      getMessage: function(){
        return this.message;
      },
      setMessage: function(message){
        this.message = message;
      },

      // nomGroupe
      getNomGroupe: function(){
        return this.nomGroupe;
      },
      setNomGroupe: function(nom){
        this.nomGroupe = nom;
      },

      // emailExpediteur
      getEmailExpediteur: function(){
        return this.emailExpediteur;
      },
      setEmailExpediteur: function(destEmail){
        var instanceDestinataireEmail = destEmail;
        if(_.isObject(destEmail) && !destEmail.hasOwnProperty('isModel')){
          var DestinataireEmail = Main.$injector.get('DestinataireEmail');
          instanceDestinataireEmail = new DestinataireEmail(destEmail);
          DestinataireEmail = null;
        }
        this.emailExpediteur = instanceDestinataireEmail;
      },

      // groupeEnvoyeur
      getGroupeEnvoyeur: function(){
        return this.groupeEnvoyeur;
      },
      setGroupeEnvoyeur: function(groupe){
        var instanceGroupe = groupe;
        if(_.isObject(groupe) && !groupe.hasOwnProperty('isModel')){
          instanceGroupe = new Groupe(groupe);
        }
        this.groupeEnvoyeur = instanceGroupe;
      },

      // getGroupeEnvoyeurDefaultNom
      getGroupeEnvoyeurDefaultNom: function(){
        var retour = 'Inconnu';
        if(!_.isString(this.groupeEnvoyeurDefaultNom)) {
          if(_.isObject(this.getGroupeEnvoyeur())) retour = this.getGroupeEnvoyeur().getDefaultLibelle();
          if(_.isObject(this.getEmailExpediteur())) retour = this.getEmailExpediteur().getEmail();
        }
        else retour = this.groupeEnvoyeurDefaultNom;
        return retour;
      },
      setGroupeEnvoyeurDefaultNom: function(valeur){
        this.groupeEnvoyeurDefaultNom = valeur;
      },

      // destinataireDefaultNomForContenuDevis
      getDestinataireDefaultNomForContenuDevis: function(){
        var retour = null;
        if(this.destinataireDefaultNomForContenuDevis) retour = this.destinataireDefaultNomForContenuDevis;
        return retour;
      },
      setDestinataireDefaultNomForContenuDevis: function(valeur){
        this.destinataireDefaultNomForContenuDevis = valeur;
      },


      // Type de Notification
      getTypeNotification: function(){
        return parseInt(this.typeNotification);
      },
      setTypeNotification: function(valInt){
        this.typeNotification = valInt;
      },

      // State
      getState: function(){
        return this.state;
      },
      setState: function(val){
        if(angular.isObject(val) && !val.isModel) val = new State(val);
        this.state = val;
      },

      // StateTopic
      getStateTopic: function(){
        return this.stateTopic;
      },
      setStateTopic: function(val){
        this.stateTopic = new State(val);
      },

      // dateCreation
      getDateCreation: function(value){
        if (arguments.length) {
          this.setDateCreation(value);
        } else {
          return this.dateCreation;
        }
      },
      getDateCreationFormat: function(toDate){
        var retour = this.dateCreation;
        if(!toDate) {
          toDate = 'DD/MM/YYYY HH:mm';
        }
        if(retour) {
          if(angular.isString(toDate)) {
            retour = moment(retour).format(toDate);
          }
          else {
            retour = new Date(retour);
          }
        }
        return retour;
      },
      getDateCreationAgo: function(){
        var retour = this.dateCreation;
        if(retour) {
          var now = moment(new Date());
          retour = moment.duration(now.diff(retour)).humanize();

        }
        return retour;
      },
      setDateCreation: function(val){
        this.dateCreation = val;
      }

    };

    angular.extend(Notification.prototype, Main.prototype);
    return Notification;

  }
})();
