(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .factory('OcrFrontResource', OcrFrontResource);

  /** @ngInject */
  function OcrFrontResource(COLLAB_CONF,COLLAB_VALUES, $resource) {

    return $resource(COLLAB_VALUES.CONF_URL.URL_OCR_FRONT + 'FeedbackServlet', null, {
      getListFounisseur: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.URL_OCR_FRONT + 'FeedbackServlet',
        isArray: true,
        params: {
          portefeuille: '@portefeuille',
          vatNumber: '@vatNumber',
          phone: '@phone',
          mail: '@mail',
          cocnumber: '@cocnumber',
        }
      }
    });
  }
})();
