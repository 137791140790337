(function () {

  'use strict';

  /**
   * @desc Bloc d'une demande pour un fournisseur
   * @example <demande-fournisseur></demande-fournisseur>
   */

  angular
    .module('collaboreApp')
    .component('demandeFournisseur', {
      bindings: {
        allowEditEvenement: '<',// <Boolean> Permet de savoir si l'événement est modifiable
        demande: '<',	        // <ContenuEvenementDevis || ontenuEvenementContrat> Devis/inter ou contrat
        document: '<',	        // <Document> document à ouvrir
        fromContenu: '<',      // document venant du contenu
        detail: '<',            // <Boolean> Demande détaillée ?
        infosEvenement: '<',    // <Object> Infos de l'événement
        onEmit: '&'             // <Function> Utilisé pour passer des informations au parents
      },
      templateUrl: 'app/topics/fournisseurs/demande-fournisseur/demande-fournisseur.component.html',
      controllerAs: 'demandefournisseurctrl',
      controller: DemandeFournisseurCtrl
    });

  /*@ngInject*/
  function DemandeFournisseurCtrl(COLLAB_CONF, COLLAB_VALUES, TagService, sweet, $rootScope, $injector, $location, $anchorScroll, UtilsService, ModalsService, ContenuService, ContenuEvenementDevis, ContenuEvenementContrat, FileUploader, TopicManagerService) {

    var _this = this;
    _this.hideDetails = false;
    _this.displayDemandeOptions = false;
    _this.etat = null;
    _this.eventHasImmeuble = false;
    _this.portefeuille = null;      // Objet portefeuille de la demande
    _this.tagProprietaire = null;

    _this.getTitreRecap = getTitreRecap;                    // Permet de récupérer le titre du recap par rapport à l'état et au type de demande
    _this.getClassEtatDemande = getClassEtatDemande;        // Permet de récupérer les class du composant par rapport au type/etat de la demande
    _this.documentIsSelected = documentIsSelected;          // Permet de savoir si le doc passé en paramètre est celui selectionné

    _this.onClickBtnDetail = onClickBtnDetail;              // Lors du clique sur le bouton "detail"

    _this.onClickOpenEditDemande = onClickOpenEditDemande;                          // Lors du clique sur le bouton pour ouvrir l'édition de la demande
    //_this.onClickOpenCommunicationMessage = onClickOpenCommunicationMessage;    // Lors du clique sur le bouton pour ouvrir l'onglet communication sur la demande
    _this.onClickSendDemandeByFax = onClickSendDemandeByFax;                    // Lors du clique sur le bouton pour envoyer la demande par FAX
    _this.onClickSendEmail = onClickSendEmail;                                  // Lors du clique sur le bouton pour envoyer un EMAIL
    _this.onClickSendSMS = onClickSendSMS;                                      // Lors du clique sur le bouton pour envoyer un SMS
    _this.onClickSendTo = onClickSendTo;                                        // Lorsqu'on clique sur le bouton pour envoyer au gravitants
    _this.onClickOpenDocumentDemande = onClickOpenDocumentDemande;              // Lors du clique sur le bouton pour ouvrir le document de la demande
    _this.onClickOpenDocument = onClickOpenDocument;                            // Lors du clique sur le bouton pour ouvrir un document passé en paramètre
    _this.onClickGenereDocumentDemande = onClickGenereDocumentDemande;          // Lors du clique sur le bouton pour générer le document de la demande
    _this.onClickOpenDocumentAttestationTvaSimple = onClickOpenDocumentAttestationTvaSimple;        // Lors du clique sur le bouton pour ouvrir le document attestation tv simplifiée de la demande
    _this.onClickGenereDocumentAttestationTvaSimple = onClickGenereDocumentAttestationTvaSimple;    // Lors du clique sur le bouton pour générer le document attestation tv simplifiée de la demande
    _this.onClickDupliquerDemande = onClickDupliquerDemande;    // Lors du clique sur le bouton pour dupliquer une demande
    //_this.onClickAccepterDevis = onClickAccepterDevis;          // Lors du clique sur le bouton pour accepter le devis
    //_this.onClickRefuserDevis = onClickRefuserDevis;            // Lors du clique sur le bouton pour refuser le devis
    _this.onClickRelancerDemande = onClickRelancerDemande;      // Lors du clique sur le bouton pour relancer le fournisseur de la demande
    _this.onClickCloturerDemande = onClickCloturerDemande;      // Lors du clique sur le bouton pour cloturer la demande
    _this.onClickReouvrirDemande = onClickReouvrirDemande;      // Lors du clique sur le bouton pour réouvrir la demande
    _this.onClickDisplayDocuments = onClickDisplayDocuments;    // Lors du clique sur le bouton pour afficher la liste des documents
    _this.onClickFakeBouton = onClickFakeBouton;                // Lors du clique sur un bouton alors que la demande est en attente de la websocket
    _this.onClickRetry = onClickRetry;                          // Lors du clique sur un bouton pour recommencer l'update ou création de demande en cas d'erreur
    _this.onClickCloseDetailPdf = onClickCloseDetailPdf;        // Lors du clique sur le bouton pour close le detailPDF
    _this.onClickVoirPlus = onClickVoirPlus;                    // Lors du clique sur le bouton pour voir plus d'informations

    _this.onEmitFromDetailPdf = onEmitFromDetailPdf;                  // Lorsque le composant "detail-pdf" remonte une information
    _this.onEmitFromDocumentsComponent = onEmitFromDocumentsComponent;                  // Lorsque le composant "documents" remonte une information
    _this.onEmitFromMontantContenuComponent = onEmitFromMontantContenuComponent;        // Permet de modifier le montant de la demande
    _this.onEmitFromCommentaireContenuComponent = onEmitFromCommentaireContenuComponent;        // Lorsque le composant "commentaire-contenu" remonte une information
    _this.onEmitFromAvancementComponent = onEmitFromAvancementComponent;        // Lorsque le composant "avancement-contenu" remonte une information

    _this.onDropDocumentFromWidgets = onDropDocumentFromWidgets;

    _this.$onInit = function () {
      _this.topicIsBlocked = TopicManagerService.isBlocked;
      if(!_.isObject(_this.demande))  {
        ModalsService.alertErreur('Pas de demande !');
      }
      init();
      if(_.isObject(_this.infosEvenement)) {

        if(!_.isNil(_this.infosEvenement.numImmeuble)) {
          _this.eventHasImmeuble = true;
        }

        if(_.isObject(_this.infosEvenement.portefeuille)) {
          UtilsService
            .getPortefeuille(_this.infosEvenement.portefeuille.idPortefeuille)
            .then(function onSuccess(port){
              _this.portefeuille = port;
            });
        }

        _this.displayDemandeOptions = (!_.isNil(_this.infosEvenement.noPersoProprietaire));
      }
    };

    _this.$onChanges = function (changes) {
      /**
       * Rappel:
       * Quand la list-demande-devis... ajoute le doc dans _this.demande.selected on ne passe pas dans le onChanges
       */
      if(_.isObject(changes)) {
        if(_.isObject(changes.fromContenu) && !changes.fromContenu.isFirstChange()) {
          _this.fromContenu = changes.fromContenu.currentValue;
        }
        if(_.isObject(changes.demande) && !changes.demande.isFirstChange()) {
          _this.demande = changes.demande.currentValue;
          init(true);
        }
        if(_.isObject(changes.document) && !changes.document.isFirstChange()) {
          _this.document = changes.document.currentValue;
        }
      }
    };

    _this.$onDestroy = function () {
    };

    /**
     * Permet de set l'objet etat de la demande avec le type et le libelle dedans
     */
    function init(update){
      if(_.isObject(_this.demande)) {
        _this.etat = _this.demande.getEtatDemande();
        if(_this.demande.selected) _this.detail = true;
        if(!_.isNil(_this.document) && _.isNil(update)) _this.hideDetails = true;
        _this.sendToComfactEnable = UtilsService.getParametrageComfact('allowSendToComfactfacture');
        initUpload();
      }
      else _this.etat = null;
    }

    /**
     * Permet d'init les uploader des document devis de chaque demande
     * @param demande
     */
    function initUpload() {
      _this.uploader = new FileUploader({
        autoUpload: true,
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_CONTENU,
        alias: 'fileName',
        removeAfterUpload: true,
        filters: [
          {
            name: 'sizeLimit',
            fn: function (item) {

              if (item.size <= COLLAB_CONF.SIZE_LIMIT_UPLOAD) {
                return true;
              } else {
                ModalsService.alertErreur('<span class="text-danger">' + item.name + '</span> trop gros !', '50 Mo Maximum');
              }
            }
          }
        ]
      });

      /**
       * Après l'ajout d'un fichier en pièce jointe
       * @param fileItem
       */
      _this.uploader.onAfterAddingFile = function afterAddingFile(fileItem) {
        _this.loading = true;
        fileItem.formData.push({action: 'addDocuments'});
        fileItem.formData.push({token: UtilsService.getCurrentUser().getToken()});
        fileItem.formData.push({idContenu: _this.demande.getIdContenu()});
      };

      /*
      _this.uploader.onAfterAddingAll = function(addedItems){
      };*/

      /**
       * Lorsqu'un fichier est bien upload
       * @param fileItem
       * @param response
       */
      _this.uploader.onSuccessItem = function (fileItem, response) {
        if(response.success) {
          if(_.isObject(response.contenu)) {
            emit({action: 'addOrUpdateDemande', contenu: ContenuService.instanceContenuByType(response.contenu)});
          }
          else ModalsService.alertErreur('Erreur avec le retour lors de l\'envoie du document');
        }
        else ModalsService.alertErreur(response.message);
      };

      /**
       * Lorsque l'upload de toute la liste est terminée
       */
      _this.uploader.onCompleteAll = function () {
        _this.loading = false;
      };

      /**
       * Si erreur sur un item à upload
       */
      /*
      _this.uploader.onErrorItem = function (item, response, status, headers) {
      };
      */
      _this.uploader.onError = function(response, status, headers){
        _this.loading = false;
      };
    }

    /**
     * Permet de récupérer le titre du recap
     * @returns {*}
     */
    function getTitreRecap(){
      var libelle = null;

      if(_.isObject(_this.etat)) {
        switch(_this.etat.TYPE){
          case ContenuEvenementDevis.TYPE_ETATS.ATTENTE_VALIDATION_DEVIS:
            libelle = 'Devis en attente de validation';
            if(_this.demande.isRefuse()) {
              libelle = 'Demande de devis refusée';
            }
            break;
          case ContenuEvenementDevis.TYPE_ETATS.ATTENTE_SIGNATURE_REFUS_DEVIS:
            libelle = 'Devis en attente de signature/refus';
            if(_this.demande.isRefuse()) {
              libelle = 'Demande de devis refusée';
            }
            break;
          case ContenuEvenementDevis.TYPE_ETATS.ATTENTE_SIGNATURE_DEVIS:
            libelle = 'Devis en attente de signature';
            break;

          case ContenuEvenementDevis.TYPE_ETATS.EN_COURS:
            libelle = 'Intervention en attente';
            break;
          case ContenuEvenementDevis.TYPE_ETATS.FACTURE_RECU_NON_TRAITE:
            libelle = 'Facture reçu en attente de traitement';
            break;
          case ContenuEvenementDevis.TYPE_ETATS.CLOTURE:
            libelle = 'Demande cloturée';
            break;

          case ContenuEvenementContrat.TYPE_ETATS.ATTENTE_VALIDATION_CONTRAT:
            libelle = 'Contrat en attente de validation';
            if(_this.demande.isRefuse()) {
              libelle = 'Contrat refusé';
            }
            break;
          case ContenuEvenementDevis.TYPE_ETATS.ATTENTE_SIGNATURE_CONTRAT:
            libelle = 'Contrat en attente de signature';
            break;
          case ContenuEvenementContrat.TYPE_ETATS.CLOTURE:
            libelle = 'Demande de contrat cloturée';
            break;
          default:
            libelle = 'Demande';
            if(_this.demande.isContenuDevisInter()){
              if(_this.demande.isDemandeIntervention()) {
                libelle += ' d\'intervention ';
              }
              else {
                libelle += ' de devis ';
              }
            }
        }
      }

      libelle += ': ' + _this.demande.getNomFournisseur();

      return libelle;
    }

    /**
     * Permet de récupérer les class css pour le composant par rapport au type/etat de la demande
     * @returns {string}
     */
    function getClassEtatDemande(){
      var arrayClassCss = ['demande-'+_this.etat.TYPE];

      if(_this.demande.selected){
        if(_.isNil(_this.document)) {
          arrayClassCss.push('demande-selected');
        }
        else {
          arrayClassCss.push('demande-with-view-pdf');
        }
      }
      else {
        _this.detail = false;
      }

      /*
      // Si pas de document
      if(_.isNil(_this.document)) {
          _this.detail = false;
      }*/

      if(_this.demande.isRefuse() || _this.demande.isCloture()) {
        arrayClassCss.push('demande-refuse');
      }


      return (arrayClassCss.length) ? _.join(arrayClassCss, ' ') : '';
    }

    /**
     * Permet de savoir si le doc est actuellement selectionné
     * @param doc
     * @returns {boolean}
     */
    function documentIsSelected(doc) {
      if(_.isNil(_this.demande.selected) || !_this.demande.selected || _.isNil(doc) || !doc) {
        return false;
      }
      if(_this.demande.selected.getGuid() === doc.getGuid()) {
        return true;
      }
    }

    /**
     * Permet d'ajouter à la tempBox un document déjà éxistant
     */
    function onDropDocumentFromWidgets(){
      if(_.isObject(_this.documentDroped)) {

        var exist = false;
        if(_.isArray(_this.demande.getListeDocument()) && _this.demande.getListeDocument().length) {
          for(var i = 0; i < _this.demande.getListeDocument().length; i++){
            if(_this.demande.getListeDocument()[i].guid === _this.documentDroped.guid){
              exist = true;
              break;
            }
          }
        }

        if(!exist) {
          var params = {
            idContenu: _this.demande.getIdContenu(),
            guids: [_this.documentDroped.guid]
          };
          _this.loading = true;
          ContenuService
            .addDocuments(params)
            .then(function onSuccess(devis){
              emit({action: 'addOrUpdateDemande', contenu: devis});
            })
            .catch(function onError(msg){
              ModalsService.alertErreur(msg);
            })
            .finally(function onFinally(){
              _this.loading = false;
            });
          /*
          _this.loading = true;
          DocBoxService
            .addDocument(_this.documentDevisDrop.guid)
            .then(function(listSharedDocument){
              iniListSharedDocument(listSharedDocument);
            })
            .finally(function(){
              _this.loading = false;
            });*/
        }
      }
    }

    /**
     * Lors du clique sur le bouton pour voir le detail
     */
    function onClickBtnDetail(){

    }

    /**
     * Lors du clique sur le bouton pour ouvrir l'édition de la demande
     */
    function onClickOpenEditDemande() {
      openEditDemande();
    }

    /**
     * Lors du clique sur le bouton pour ouvrir l'onglet communication et nouveau message pour la demande
     */
    /*
    function onClickOpenCommunicationMessage(){
        openCommunication('message');
    }*/

    /**
     * Lors du clique sur le bouton pour ouvrir la modal d'envoi de mail
     */
    function onClickSendEmail(){
      var options = {
        backdrop: 'static'
      };
      //var copyDestinataire = angular.copy(_this.demande.getDestinataire());
      //copyDestinataire.typeDestinataire = 'fournisseur';
      var params = {
        contenuParent: _this.demande,
        typeFormulaire: 'email',
        chooseContact: false,
        useMiniToolbar: true,
        disallowSelectType: true,
        disallowChooseDestinataires: false,
        destinataires: [],
        destinatairesHide: (_.isObject(_this.infosEvenement) && _.isArray(_this.infosEvenement.listDestinatairesHide)) ? _this.infosEvenement.listDestinatairesHide : null
        // evenement: ctrl.evenement,
        //documents: [doc],
        //textSujet: 'Déclaration de sinistre'
      };

      ModalsService
        .modalComponent('formulaireMessage', params, options)
        .then(function (objet) {
          emit({action: 'addMessage', message: objet.contenu});
        })
        .catch(function (msg) {
          if (msg !== 'cancel') ModalsService.alertErreur(msg);
        });
    }

    /**
     * Lorsq du clique sur le bouton pour envoyer la demande par FAX
     */
    function onClickSendDemandeByFax(){
      openModalSendDemandeByFax()
    }

    /**
     * Lors du clique sur le bouton pour envoyer un SMS
     */
    function onClickSendSMS(){
      openSendSMS();
    }

    /**
     * Lors du clique sur le bouton pour envoyer le devis au propriétaire ou CS
     * @param devis
     */
    function onClickSendTo() {
      var options = {
        size: 'lg',
        windowClass: 'modal-collabore',
        backdrop: 'static'
      };

      var params = {
        devis: _this.demande,
        infosEvenement: _this.infosEvenement,
        documents: [_this.demande.getDocumentDevis()]
      };

      ModalsService
        .modalComponent('sendToGravitant', params, options)
        .then(function(obj) {
          emit(obj);
        });
    }


    /**
     * Lors du clique sur le bouton pour ouvrir un document passé en paramètre
     */
    function onClickOpenDocument (varDocument, fromContenu){
      openDocument(varDocument, fromContenu);
    }

    /**
     * Permet d'ouvrir un document
     * @param document
     */
    function openDocument(varDocument, fromContenu){
      if (_.isObject(varDocument)) {
        emit({action: 'openPDF', document: varDocument, fromContenu: fromContenu});
      }
    }

    /**
     * Lors du clique sur le bouton pour ouvrir le document demande
     */
    function onClickOpenDocumentDemande(){
      openDocumentDemande();
    }

    /**
     * Lors du clique sur le bouton pour générer le document de la demande
     * TODO: Pour le moment on ouvre la modal seulement si il y a un propriétaire dans les tags
     */
    function onClickGenereDocumentDemande(){
      if(_this.displayDemandeOptions){
        ModalsService
          .modalComponent('generateDocument', {
            demandeOptions: _this.demande.demandeOptions,
            infosEvenement: _this.infosEvenement
          }, {size: 'lg'})
          .then(function onSuccess(obj){
            if(_.isObject(obj) && obj.action === 'generateDocument' && _.isObject(obj.demandeOptions)) {
              _this.demande.demandeOptions = obj.demandeOptions;
              genereDocumentDemande();
            }
          });
      }
      else {
        genereDocumentDemande();
      }
    }

    /**
     * Lors du clique sur le bouton pour ouvrir le document Attestation TVA simplifiée de la demande
     */
    function onClickOpenDocumentAttestationTvaSimple(){
      openDocumentAttestationTvaSimple();
    }

    /**
     * Lors du clique sur le bouton pour générer le document Attestation TVA simplifiée de la demande
     */
    function onClickGenereDocumentAttestationTvaSimple(){
      genereDocumentAttestationTvaSimple();
    }

    /**
     *  Lors du clique sur le bouton pour dupliquer une demande
     */
    function onClickDupliquerDemande(){
      ModalsService
        .modalComponent('dupliquerDevis', {
          demande: _this.demande,
          infosEvenement: _this.infosEvenement
        }, {size: 'lg'})
        .then(function onSuccess(obj){
          if(_.isObject(obj) && obj.action === 'duplicateDemande' && _.isObject(obj.demande)) {
            closeDetailPDF();
            emit({action: 'addOrUpdateDemande', contenu: obj.demande});
          }
        });
    }

    /**
     * Lors du clique sur le bouton pour accepter le devis
     */
    /*
    function onClickAccepterDevis() {
        sweet.show({
            title: 'Accepter le Devis',
            text: 'Vous acceptez ce devis ?',
            type: 'info',
            showCancelButton: true,
            confirmButtonText: 'Oui !',
            closeOnConfirm: false,
            showLoaderOnConfirm: true
        }, function () {
            _this.demande
                .accepterDevisBySyndic(_this.demande.sendMailWhenAcceptOrDecline)
                .then(function (newObjDevis) {
                    // TODO: Go to signature
                    //goToSignature();
                    emit({action: 'addOrUpdateDemande'});
                })
                .finally(function () {
                    sweet.close();
                });
        });
    }*/

    /**
     * Lors du clique sur le bouton pour refuser le devis
     */
    /*
    function onClickRefuserDevis() {
        sweet.show({
            title: 'Refuser le Devis',
            text: 'Vous refusez ce devis ?',
            type: 'info',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            closeOnConfirm: false,
            showLoaderOnConfirm: true
        }, function () {
            _this.demande
                .refuserDevisBySyndic(_this.demande.sendMailWhenAcceptOrDecline)
                .then(function(){
                    emit({action: 'addOrUpdateDemande'});
                })
                .finally(function () {
                    sweet.close();
                });
        });
    }*/

    /**
     *  Lors du clique sur le bouton pour relancer le fournisseur
     */
    function onClickRelancerDemande(){
      relancerDemande();
    }

    /**
     * Lors du clique sur le bouton pour cloturer la demande
     */
    function onClickCloturerDemande() {
      cloturerDemande();
    }

    /**
     * Lors du clique sur le bouton pour réouvrir la demande
     */
    function onClickReouvrirDemande(){
      reouvrirDemande();
    }

    /**
     * Lors du clique sur le bouton pour afficher la liste des documents
     */
    function onClickDisplayDocuments(){
      _this.openListDocuments = !_this.openListDocuments;
    }

    /**
     * Lors du clique sur le bouton pour close le composant
     */
    function onClickCloseDetailPdf(){
      closeDetailPDF();
    }

    /**
     * Permet d'afficher/cacher les informations
     */
    function onClickVoirPlus(){
      _this.hideDetails = !_this.hideDetails;
    }

    /**
     * Permet de close le composant
     */
    function closeDetailPDF(){
      emit({
        action: 'closeDetailPDF'
      });
    }

    /**
     * Ouvre la modal pour envoyer la demande par FAX
     */
    function openModalSendDemandeByFax() {
      ModalsService.modalSendDemandeByFax(_this.demande);
    }

    /**
     * Ouvre l'éditiontion de la demande
     */
    function openEditDemande() {
      emit({action: 'openEditDemande'});
    }

    /**
     * Ouvre l'onglet de communication pour le type demandé
     */
    /*
    function openCommunication(type) {
        emit({action: 'openCommunication', type: type});
    }*/

    /**
     * Ouvre la modal pour envoyer un SMS
     */
    function openSendSMS() {

      var obj = {
        contenu: _this.demande,
        defaultParams: {
          idTopic: _this.demande.getIdTopic()
        }
      };

      if(_.isObject(_this.portefeuille) &&  _.isObject(_this.portefeuille.getPortefeuilleInfos())) {
        obj.defaultParams.prefixSms = _this.portefeuille.getPortefeuilleInfos().getPrefixSms();
      }
      ModalsService.modalSendSMS(obj, false, function (contenuMessage) {
        emit({action: 'addMessage', message: contenuMessage});
      });
    }

    /**
     * Ouvre le document "demande" de la demande
     */
    function openDocumentDemande() {
      var documentDemande = _this.demande.getDocumentDemande();
      if (documentDemande) {
        emit({action: 'openPDF', document: documentDemande});
      }
      else {
        genereDocumentDemande(true);
      }
    }

    /**
     * Permet de générer le document de la demande
     * @param open
     */
    function genereDocumentDemande(open) {
      _this.loading = true;
      _this.demande
        .generateDemande()
        .then(function onSuccess() {
          $rootScope.$broadcast('refreshDocuments');
          if(open) {
            emit({action: 'openPDF', document: _this.demande.getDocumentDemande()});
          }
        })
        .catch(function onError(msg) {
          console.log(msg);
          //ModalsService.alertErreur(msg);
        })
        .finally(function onFinally() {
          _this.loading = false;
        });
    }

    /**
     * Permet de générer le document Attestation TVA simplifiée de la demande
     */
    function openDocumentAttestationTvaSimple() {
      var documentAttestation = _this.demande.getDocumentAttestationTvaSimple();
      if (documentAttestation) {
        emit({action: 'openPDF', document: documentAttestation});
      }
      else {
        genereDocumentAttestationTvaSimple(open);
      }
    }

    /**
     * Permet de générer le document Attestation TVA simplifiée de la demande
     * @param open
     */
    function genereDocumentAttestationTvaSimple(open) {
      _this.loading = true;
      _this.demande
        .generateAttestationTvaSimple()
        .then(function onSuccess() {
          $rootScope.$broadcast('refreshDocuments');
          if(open) {
            emit({action: 'openPDF', document: _this.demande.getDocumentAttestationTvaSimple()});
          }
        })
        .catch(function onError(msg) {
          ModalsService.alertErreur(msg);
        })
        .finally(function onFinally() {
          _this.loading = false;
        });
    }

    /**
     * Permet de relancer les fournisseurs de la demande
     */
    function relancerDemande() {

      ModalsService
        .modalRelanceDemande(_this.demande)
        .then(function(retourDevis){
          emit({action: 'addOrUpdateDemande', contenu: retourDevis});
        });
      /*
      sweet.show({
        title: 'Relance',
        text: 'Relancer la demande pour ' + _this.demande.getDestinataire().getDefaultLibelle() + ' ?',
        type: 'info',
        showCancelButton: true,
        //cancelButtonText: 'Annuler',
        confirmButtonText: 'Relancer !',
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      }, function () {
        _this.demande
          .relancer()
          .catch(function onSuccess(msg) {
            ModalsService.alertErreur(msg);
          })
          .finally(function onFinally() {
            sweet.close();
          });
      });*/
    }

    /**
     * Permet de cloturer la demande
     */
    function cloturerDemande() {

      var params = {
        idContenu: _this.demande.getIdContenu(),
        titleModal: 'Fermer la demande'
      };

      ModalsService
        .modalComponent('raisonClotureContenu', params, {size: 'lg'})
        .then(function onSuccess(obj){
          if(_.isObject(obj)) {
            if(obj.action === 'updateContenu' && _.isObject(obj.contenu)) {
              emit({action: 'addOrUpdateDemande', contenu: obj.contenu});
            }
          }
        });
    }

    /**
     * Permet de réouvrir la demande
     */
    function reouvrirDemande() {
      sweet.show({
        title: 'Réouvrir la demande',
        text: 'Voulez-vous réouvrir cette demande ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Oui !',
        closeOnConfirm: true
      }, function () {
        _this.loading = true;
        ContenuService
          .reouvrirContenu(_this.demande.getIdContenu())
          .then(function onSuccess(devis) {
            emit({action: 'addOrUpdateDemande', contenu: devis});
          })
          .catch(function onError(msg){
            ModalsService.alertErreur(msg);
          })
          .finally(function onFinally(){
            _this.loading = false;
          });
      });
    }

    /**
     * Lors du clique sur un bouton alors que la demande est en attente de la websocket
     */
    function onClickFakeBouton(){
      ModalsService.info('En cours de création', 'Désolé la demande est en cours de création');
    }

    function onClickRetry() {
      if(_this.demande.isError()) {
        _this.demande.loadingRetry = true;
        _this.demande.retryDevis()
          .then(function(){
            _this.demande.setEtat(0);
          })
          .catch(function(msg){
            ModalsService.alertErreur(msg);
          })
          .finally(function(){
            _this.demande.loadingRetry = false;
          })
      }
    }

    /**
     * Lorsque le composant "detail-pdf" remonte une information
     * On met à jour les info de l'objet "demande" du composant et on l'emit au parents
     * L'objet dans "obj.contenu" n'est pas la bon car il n'a pas d'idTopic, à cause de la servlet
     * @param obj
     */
    function onEmitFromDetailPdf(obj){
      try {
        if (!obj) {
          throw new Error('Il manque l\'objet "obj" !');
        }

        if(_.isObject(obj.contenu)) {

          // Si demande acceptée dans le composant "detail-pdf"
          if(obj.action === 'accepteDemande') {

            if(obj.contenu.isContenuDevisInter()) {
              _this.demande.setAcceptedBySyndic(obj.contenu.getAcceptedBySyndic());
              _this.demande.setDateValidationSyndic(obj.contenu.getDateValidationSyndic());
              _this.demande.setAcceptedByCS(obj.contenu.getAcceptedByCS());
              _this.demande.setDateValidationCS(obj.contenu.getDateValidationCS());
            }
            else if(obj.contenu.isContenuContrat()) {
              _this.demande.setData(obj.contenu);
              _this.document = _this.demande.getListDocumentContratFournisseurSigneWithGuid(obj.document);

              // Recupère le prochain document fournisseur qui est en attente d'acceptation/refus
              var nextDoc = _this.demande.getNextDocumentWaitingFromListDocumentContratFournisseurSigne();
              if(!_.isNil(nextDoc)) _this.document = nextDoc;
              // Si il n'y a pas de document en attente d'acceptation/refus alors prend le prochain doc en attente de signature
              else {
                var nextDocAccepted = _this.demande.getNextDocumentAcceptedFromListDocumentContratFournisseurSigne();
                if(!_.isNil(nextDocAccepted)) _this.document = nextDocAccepted;
              }

              _this.demande.selected = _this.document;
              nextDoc = null;
            }

            //openDocument(_this.demande.getDocumentDevis());
          }

          // Si demande refusée dans le composant "detail-pdf"
          if(obj.action === 'refuseDemande') {


            if(obj.contenu.isContenuDevisInter()) {
              _this.demande.setRefusedBySyndic(obj.contenu.getRefusedBySyndic());
              _this.demande.setDateRefusedBySyndic(obj.contenu.getDateRefusedBySyndic());
              _this.demande.setRefusedByCS(obj.contenu.getRefusedByCS());
              _this.demande.setDateRefusedByCS(obj.contenu.getDateRefusedByCS());
            }
            else if(obj.contenu.isContenuContrat()) {
              _this.demande.setData(obj.contenu);
              _this.document = _this.demande.getListDocumentContratFournisseurSigneWithGuid(obj.document);

              // Recupère le prochain document fournisseur qui est en attente d'acceptation/refus
              var nextDoc = _this.demande.getNextDocumentWaitingFromListDocumentContratFournisseurSigne();
              if(!_.isNil(nextDoc)) _this.document = nextDoc;
              // Si il n'y a pas de document en attente d'acceptation/refus alors prend le prochain doc en attente de signature
              else {
                var nextDocAccepted = _this.demande.getNextDocumentAcceptedFromListDocumentContratFournisseurSigne();
                if(!_.isNil(nextDocAccepted)) _this.document = nextDocAccepted;
              }
              _this.demande.selected = _this.document;
              nextDoc = null;
            }
          }

          // Si devis signé dans le composant "detail-pdf"
          if(obj.action === 'signeDevis') {
            if (!obj.document) {
              throw new Error('Il manque l\'objet "document" !');
            }
            _this.demande.setData(obj.contenu);
            _this.demande.selected = obj.document;
            /*
            _this.demande.setDocumentDevisSigne(obj.contenu.getDocumentDevisSigne());
            _this.demande.setDateDevisSigne(obj.contenu.getDateDevisSigne());*/
          }

          if(obj.action === 'signeContrat') {
            _this.demande.setData(obj.contenu);
            _this.document = _this.demande.getDocumentFromListContratAgenceSigneForGuidDocumentSource(obj.guidDocFournisseurSigne);
            // Recupère le prochain document fournisseur qui est en attente d'acceptation/refus
            var nextDoc = _this.demande.getNextDocumentWaitingFromListDocumentContratFournisseurSigne();
            if(!_.isNil(nextDoc)) _this.document = nextDoc;
            // Si il n'y a pas de document en attente d'acceptation/refus alors prend le prochain doc en attente de signature
            else {
              var nextDocAccepted = _this.demande.getNextDocumentAcceptedFromListDocumentContratFournisseurSigne();
              if(!_.isNil(nextDocAccepted)) _this.document = nextDocAccepted;
            }
            _this.demande.selected = _this.document;
            nextDoc = null;
          }

          _this.demande.setDateModification(obj.contenu.getDateModification());

          emit({action: 'addOrUpdateDemande'});
        }
      }
      catch (err) {
        console.log('[DemandeDevisInterCtrl.onEmitFromDetailPdf] Erreur : ' + err.message);
      }
    }

    /**
     * Lorsque le composant "documents" remonte une information
     * @param obj
     */
    function onEmitFromDocumentsComponent(obj, property){
      try {
        if (!obj) {
          throw new Error('Il manque l\'objet "obj" !');
        }

        if(obj.action === 'open') {
          openDocument(obj.document);
        }
        if(obj.action === 'delete') {

          var deleteFct = 'deleteDocument';

          if(!_.isNil(property)){
            switch (property) {
              case 'listeDocument':
                deleteFct = 'deleteDocumentFromListeDocument';
                break;
              case 'listeDocumentComptable':
                deleteFct = 'deleteDocumentFromListeDocumentComptable';
                break;
              case 'listeDocumentFournisseur':
                deleteFct = 'deleteDocumentFromListeDocumentFournisseur';
                break;
              default:
                deleteFct = 'deleteDocument';
            }
          }
          if(!_.isNil(deleteFct) && _.isFunction(_this.demande[deleteFct])){
            _this.demande[deleteFct](obj.document.getGuid())
              .then(function(){
                // $rootScope.$broadcast('refreshDocuments');
                // Documents refresh via "layoutTopicDetail.updateDemande" -> "layoutTopicDetail.updateListeDocumentTopic"
                emit({action: 'addOrUpdateDemande'});
              })
              .catch(function (msg) {
                ModalsService.alertErreur(msg);
              })
              .finally(function(){
              });
          }
        }
      }
      catch (err) {
        console.log('[DemandeDevisInterCtrl.onEmitFromDocumentsComponent] Erreur : ' + err.message);
      }
    }

    /**
     * Lorsque le composant montant-contenu remonte une information
     * Le "contenu" peut être la demande ou la facture, il est donc passé en paramètre
     */
    function onEmitFromMontantContenuComponent(obj, contenu){
      try {
        if (!obj) {
          throw new Error('Il manque l\'objet "obj" !');
        }

        // Si change le montant dans le composant "montant-contenu"
        if(obj.action === 'changeMontant') {
          contenu.setMontant(obj.montant);
          emit({action: 'addOrUpdateDemande'});
        }
      }
      catch (err) {
        console.log('[DemandeDevisInterCtrl.onEmitFromMontantContenuComponent] Erreur : ' + err.message);
      }
    }

    /**
     * Lorsque le composant commentaire-contenu remonte une information
     * @param obj
     */
    function onEmitFromCommentaireContenuComponent(obj){
      //console.log(obj);
      try {
        if (!obj) {
          throw new Error('Il manque l\'objet "obj" !');
        }

        // Si change le commentaire dans le composant "commentaire-contenu"
        if(obj.action === 'updateCommentaire') {
          _this.demande.setCommentaire(obj.commentaire);
          emit({action: 'addOrUpdateDemande'});
        }
      }
      catch (err) {
        console.log('[DemandeDevisInterCtrl.onEmitFromCommentaireContenuComponent] Erreur : ' + err.message);
      }
    }

    /**
     * Lorsque le composant avancement-contenu remonte une information
     * @param obj
     */
    function onEmitFromAvancementComponent(obj){
      try {
        if (!obj) {
          throw new Error('Il manque l\'objet "obj" !');
        }

        if(obj.action === 'updateEtat') {
          _this.demande.setEtat(obj.etat);
          emit({action: 'addOrUpdateDemande'});
        }
        if(obj.action === 'openEditDemande') {
          openEditDemande();
        }
        /*
        if(obj.action === 'openTraiteDemande' || obj.action === 'openSigne') {

            if(_this.demande.isContenuDevis()) {
                openDocument(_this.demande.getDocumentDevis());
            }
            else if(_this.demande.isContenuContrat()) {
                if(obj.action === 'openTraiteDemande') {
                    // Ouvre le prochain document fournisseur signé qui reste à accepter par l'agence
                    openDocument(_this.demande.getNextDocumentWaitingFromListDocumentContratFournisseurSigne());
                }
                else if(obj.action === 'openSigne'){
                    // ouvre le prochain document fournisseur signé accépté par l'agence mais qui reste encre à signer par l'agence
                    openDocument(_this.demande.getNextDocumentAcceptedFromListDocumentContratFournisseurSigne());
                }
            }
        }*/

        if(obj.action === 'openDocument'){
          if (_.isNil(obj.document)) {
            throw new Error('Il manque l\'objet "obj.document" !');
          }
          openDocument(obj.document, obj.contenu);
        }
      }
      catch (err) {
        console.log('[DemandeDevisInterCtrl.onEmitFromAvancementComponent] Erreur : ' + err.message);
      }
    }

    /**
     * Alerte avant la suppression d'un devis
     * @param indexDevis
     *
     function removeDevis(devis) {
            sweet.show({
                title: 'Supprimer ce devis ?',
                text: 'Vous êtes sûr ?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Oui supprimer !',
                closeOnConfirm: true
            }, function () {
                deleteDevis(devis.getIdContenu());
            });
        }*/

    /**
     * Permet d'emit vers le parent
     * @param valObj
     */
    function emit(valObj){
      var object = {
        obj: {}
      };
      object.obj = _.merge(object.obj, valObj);
      // Si pas de "contenu" dans obj
      if(!object.obj.hasOwnProperty('contenu')) {
        object.obj.contenu = angular.copy(_this.demande)
      }
      // Si "contenu" dans obj
      else if(object.obj.action === 'addOrUpdateDemande'){
        _this.demande = object.obj.contenu;
      }

      if(_.isObject(valObj)){
        if(valObj.action === 'openPDF') {
          _this.demande.selected = valObj.document;
        }
      }

      init(true);
      _this.onEmit(object);
    }

  }
})();
