(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .component('documentsViewer', {
      templateUrl: 'app/topics/components/documents-viewer/documents-viewer.component.html',
      controller: DocumentsViewerController,
      controllerAs: 'documentsviewerctrl',
      bindings: {
        idContenu: '<',
        idTopic: '<',
        documents: '<',        // Doc ou liste de doc
        showLibelleType: '<',
        onEmit: '&',

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<'
      }
    });

  /** @ngInject */
  function DocumentsViewerController($scope, $sce, bowser, UtilsService, DocumentManagerService, ErreurCollabService, ModalsService) {
    var _this = this;

    _this.docs = [];
    _this.onEmitFromDocumentsComponent = onEmitFromDocumentsComponent;
    _this.onClickClose = onClickClose;

    /********** Global **********/
    _this.$onInit = function () {
      if(_this.resolve && _this.modalInstance) {
        _this.idContenu = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'idContenu'));
        _this.idTopic = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'idTopic'));
        _this.documents = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'documents'));
      }
      initDoc();
      init();
    };

    _this.$onChanges = function(changes){
    };

    _this.$onDestroy = function(){
    };


    function init(){
      //console.log(_this.documents);
      if(!_this.documents || _.isNil(_this.documents) || _.isEmpty(_this.documents)) ModalsService.alertErreur('Pas de documents');
      else if(_.isArray(_this.documents)) _this.docs = _this.documents;
      else if(_.isObject(_this.documents)) _this.docs = [_this.documents];

      if(_this.docs.length) onOpenDocument(_this.docs[0]);
    }

    function initDoc(doc, url, msgErreur){
      _this.currentPdfView = {
        document: (doc) ? doc : null,
        pdfUrl: (url) ? url : null,
        erreur: (msgErreur) ? msgErreur : null
      };
    }

    // ----------------- Documents -----------------
    /**
     * Lors d'une remontée d'info depuis le composant des documents
     * @param obj
     */
    function onEmitFromDocumentsComponent(obj){
      //console.log(obj);
      try {
        if (!obj) throw new Error('Pas d\'obj !');
        if(_.isObject(obj) && _.isObject(obj.document)) {
          if(obj.action === "open") onOpenDocument(obj.document);
          //if(obj.action === "delete") onOpenDocument(obj.document);
        }
        /*
        if (angular.isObject(obj) && obj.hasOwnProperty('signature')) {
          if(obj.action === 'changeSignature') ctrl.signatureSelected = obj.signature;
        }*/
      }
      catch (err) {
        ModalsService.alertErreur(err.message);
      }
    }
    // --- Fin Documents

    /**
     * Permet d'ouvrir un document
     * @param doc
     */
    function onOpenDocument(doc) {
      if (!_.isObject(doc) || !doc.isModel) {
        ModalsService.alertErreur("Le document à ouvrir n'est pas correct");
        ErreurCollabService.logErreur(doc);
      }
      else {
        var guid = doc.getGuid();

        /*
        if ($attrs.onSelect) {
          _this.onSelect({
            select: {
              document: open.document
            }
          });
        }*/

        _this.docs.map(function(currentDoc){
          if (currentDoc.guid === doc.guid) currentDoc.openView = true;
          else currentDoc.openView = false;
        });

        if (bowser.msie) {
          var urlPdf = DocumentManagerService.getUrlStream(guid, _this.idContenu, _this.idTopic);
          initDoc(doc, $sce.trustAsResourceUrl(encodeURIComponent(urlPdf)));
        }
        else {
          // Recupère le PDF
          _this.loading = true;
          DocumentManagerService
            .getStream(guid, _this.idContenu, _this.idTopic)
            .then(function onSuccess(url) {
              //console.log(url);
              initDoc(doc, encodeURIComponent(url));
            })
            .catch(function onError(msg) {
              //if (msg) ModalsService.alertErreur(msg.statusText);
              //console.log(msg);
              initDoc(null, null, msg);
            })
            .finally(function(){
              _this.loading = false;
            });
        }
      }
    }

    /**
     * Fermeture du composant si en mode modal
     */
    function onClickClose(){
      if(!_this.modalInstance) emit({action: "close"});
      else _this.dismiss({$value: 'cancel'});
    }

    /**
     * Traite les donnée pour les renvoyer au composant qui appel celui si
     */
    function emit(obj){
      if(!_this.modalInstance) {
        var onEmit = {obj: obj};
        _this.onEmit(onEmit);
      }
    }
  }
})();

