(function () {

  'use strict';

  /**
   * @desc Composant groupe ligne
   * @example <admin-gestion-groupes-ligne></admin-gestion-groupes-ligne>
   */
  angular
    .module('collaboreApp')
    .component('adminGestionGroupesLigne', {
      bindings: {
        groupeParent: '<',     // Object<Groupe>
        groupe: '<',     // Object<Groupe>
        onProgress: '&',
        onEmit: '&'
      },
      templateUrl: 'app/parametrage/admin/admin-gestion-groupes/admin-gestion-groupes-ligne.component.html',
      controllerAs: 'admingestiongroupeslignectrl',
      controller: AdminGestionGroupesLigneCtrl
    });


  /** @ngInject */
  function AdminGestionGroupesLigneCtrl($rootScope, ModalsService, GroupesAdminService) {
    var ctrl = this;

    ctrl.onLigneProgress = onLigneProgress;
    ctrl.onClickAddEquipe = onClickAddEquipe;
    ctrl.onClickAddUser = onClickAddUser;
    ctrl.onEmitFromLigne = onEmitFromLigne;
    ctrl.onClickAssignTopicCloture = onClickAssignTopicCloture;
    ctrl.onClickRemoveGroupe = onClickRemoveGroupe;

    ctrl.$onInit = function () {
      ctrl.groupeUser = angular.copy($rootScope.current.userGroupe);
      if (ctrl.groupe.isAll()) {
      }
    };

    function onLigneProgress(val) {
      ctrl.onProgress({progress: val});
    }

    function onClickAddEquipe(){
      var options = {
        size: 'lg',
        windowClass: 'modal-collabore',
        backdrop: 'static'
      };

      var params = {
        groupe: ctrl.groupe,
        createGroupeNoUser: true
      };

      ModalsService
        .modalComponent('adminGestionAjoutGroupe', params, options)
        .then(function(obj) {
          if(angular.isObject(obj) && obj.action === 'refreshGroupe' && angular.isObject(obj.groupe)) {
            if(!_.isArray(ctrl.groupe.groupesFils)) ctrl.groupe.groupesFils = [];
            ctrl.groupe.groupesFils.push(obj.groupe);
          }
        });
    }

    function onClickAddUser(){
      var options = {
        size: 'lg',
        windowClass: 'modal-collabore',
        backdrop: 'static'
      };

      var params = {
        groupe: ctrl.groupe
      };

      ModalsService
        .modalComponent('adminGestionAjoutGroupe', params, options)
        .then(function(obj) {
          if(angular.isObject(obj) && obj.action === 'refreshGroupe' && angular.isObject(obj.groupe))
            ctrl.groupe = obj.groupe;
        })
        .finally(function(){
        });
    }

    function onClickAssignTopicCloture(){
      try {
        if(!ctrl.groupeParent) throw new Error('Le groupe parent n\'éxiste pas !');

        ModalsService.confirm('Assigner les dossiers clôturés', 'Assigner les dossiers clôturés de "<strong>' + ctrl.groupeParent.libelle + '</strong> (et ses parents) à "<strong>' + ctrl.groupe.libelle + '</strong>" ?').then(function (modal) {

          GroupesAdminService
            .assignTopicCloture(ctrl.groupeParent.getIdGroupe(), [ctrl.groupe.getIdGroupe()])
            .then(function(groupe){
              groupe.open = true;
              emit({action: 'refreshGroupe', groupe: groupe});
              modal.close();
            })
            .catch(function (msg) {
              ModalsService.alertErreur(msg);
            });
        });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
      }
    }

    function onClickRemoveGroupe(){
      try {
        if(!ctrl.groupeParent) throw new Error('Le groupe parent n\'éxiste pas !');

        ModalsService.confirm('Retirer le groupe', 'Retirer "<strong>' + ctrl.groupe.libelle + '</strong>" de "<strong>' + ctrl.groupeParent.libelle + '</strong>" ?').then(function (modal) {

          GroupesAdminService
            .removeListGroupeFromGroupe(ctrl.groupeParent.getIdGroupe(), [ctrl.groupe.getIdGroupe()])
            .then(function(groupe){
              groupe.open = true;
              emit({action: 'refreshGroupe', groupe: groupe});
              modal.close();
            })
            .catch(function (msg) {
              ModalsService.alertErreur(msg);
            });
        });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
      }
    }

    /**
     * Si emit venant d'une ligne
     * @param obj
     */
    function onEmitFromLigne(obj){
      if(angular.isObject(obj) && obj.action === 'refreshGroupe' && angular.isObject(obj.groupe)) {
        ctrl.groupe = obj.groupe;
      }
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      ctrl.onEmit({obj: objRetour});
    }
  }
})();
