(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.ContenuEvenement
   * @description
   * # ContenuEvenement
   * Service in the collaboreApp.
   */

  angular.module('collaboreApp').factory('ContenuEvenement', ContenuEvenementModel);

  /** @ngInject */
  function ContenuEvenementModel(COLLAB_CONF, $sce, $injector, $q, Contenu, ContenuSinistre, ContenuEvenementIntervention, ContenuEvenementFacture, UtilsService) {

    var ContenuEvenement = function (objet) {

      var scope = this;
      var attributMinimum = [
        {attribut: 'listeDevis', defaultValue: []},
        {attribut: 'listeContrats', defaultValue: []},
        {attribut: 'listeInterventions', defaultValue: []},
        {attribut: 'postits', defaultValue: []},
        {attribut: 'listeFactures', defaultValue: []}
      ];

      Contenu.call(scope, objet, attributMinimum);

      //this.setAttributesRequired(scope, attributMinimum);
      this.typeContenu = COLLAB_CONF.TYPE_CONTENU_EVENEMENT;
      this.previsualisationMsg = this.objet;

    };

    ContenuEvenement.prototype = {

      model: 'ContenuEvenement',
      isEvenement: true,
      isDevis: false,
      isIntervention: false,
      isFacture: false,

      // Date d'échéance
      getDateEcheance: function (date) {
        var retour = this.dateEcheance;
        if (date && retour) {
          retour = new Date(retour);
        }
        return retour;
      },
      setDateEcheance: function (date) {
        this.dateEcheance = date;
      },

      // Référence par ICS
      getReferenceAutoByICS: function () {
        return this.referenceAutoByICS;
      },
      setReferenceAutoByICS: function (ref) {
        this.referenceAutoByICS = ref;
      },

      // Référence Client
      getReferenceClient: function () {
        return this.referenceClient;
      },
      setReferenceClient: function (ref) {
        this.referenceClient = ref;
      },

      // Array<String> Postits
      getPostits: function () {
        return this.postits;
      },
      setPostits: function (arrayPostits) {
        if (!arrayPostits) {
          arrayPostits = [];
        }
        this.postits = arrayPostits;
      },

      // Modifiable
      getModifiable: function () {
        return this.modifiable;
      },
      setModifiable: function (bool) {
        this.modifiable = bool;
      },

      // allowCloture
      getAllowCloture: function () {
        return this.allowCloture;
      },
      setAllowCloture: function (bool) {
        this.allowCloture = bool;
      },

      // Urgent
      getUrgent: function () {
        return this.urgent;
      },
      setUrgent: function (bool) {
        this.urgent = bool;
      },
      updateUrgent: function (val) {
        var _this = this;
        var deferred = $q.defer();
        var params = {
          idEvenement: _this.getIdContenu(),
          urgentEvenement: val
        };
        var EvenementService = $injector.get('EvenementService');
        EvenementService.updateFieldByField(params)
          .then(function (evenement) {
            _this.setData(evenement);
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      /*
        getDevis: function (idContenu) {
            var retour = false;
            if (this.getListeDevis().length) {
                for (var i = 0; i < this.getListeDevis().length; i++) {
                    if (this.getListeDevis()[i].getIdContenu() == idContenu) {
                        retour = this.getListeDevis()[i];
                        break;
                    }
                }
            }

            return retour;
        },*/

      // dateIntervention
      getDateIntervention: function (format) {
        if(!_.isNil(this.dateIntervention) && !_.isNil(format)) {
          if(_.isBoolean(format)) return new Date(this.dateIntervention);
          else if(!_.isString(format) && format) format = null;
          return UtilsService.parseDate(this.dateReception, format);
        }
        return this.dateIntervention;
      },
      setDateIntervention: function (date) {
        this.dateIntervention = date;
      },

      // Array<ProxyIntervention> Liste des Interventions
      getListeInterventions: function () {
        return this.listeInterventions;
      },
      setListeInterventions: function (arrayProxyIntervention) {
        if (!arrayProxyIntervention) {
          arrayProxyIntervention = [];
        } else {
          if (angular.isArray(arrayProxyIntervention)) {
            var tabtmp = [];
            if (arrayProxyIntervention.length) {
              angular.forEach(arrayProxyIntervention, function (intervention) {

                var instanceIntervention = new ContenuEvenementIntervention(intervention);
                tabtmp.push(instanceIntervention);
              });
            }
            arrayProxyIntervention = tabtmp;
          }
        }
        this.listeInterventions = arrayProxyIntervention;
      },

      // Sinistre Sinistre de l'événement
      getSinistre: function () {
        return this.sinistre;
      },
      setSinistre: function (objet) {
        var sinistre = objet;
        if (angular.isObject(sinistre) && !sinistre.isModel) {
          sinistre = new ContenuSinistre(sinistre);
        }
        this.sinistre = sinistre;
      },
      hasSinistre: function () {
        return (angular.isDefined(this.getSinistre()) && angular.isObject(this.getSinistre())) ? true : false;
      },

      // Factures
      getListeFactures: function () {
        return this.listeFactures;
      },
      setListeFactures: function (arrayProxyFactures) {
        if (!arrayProxyFactures) {
          arrayProxyFactures = [];
        } else {
          if (angular.isArray(arrayProxyFactures)) {
            var tabtmp = [];
            if (arrayProxyFactures.length) {
              angular.forEach(arrayProxyFactures, function (facture) {
                tabtmp.push(new ContenuEvenementFacture(facture));
              });
            }
            arrayProxyFactures = tabtmp;
          }
        }
        this.listeFactures = arrayProxyFactures;
      },

      // ProxyTag: Tag immeuble
      getTagImmeuble: function () {
        return this.tagImmeuble;
      },
      setTagImmeuble: function (proxyTag) {
        this.tagImmeuble = proxyTag;
      },

      // ProxyTag: Tag Lot
      getTagLot: function () {
        return this.tagLot;
      },
      setTagLot: function (proxyTag) {
        this.tagLot = proxyTag;
      },

      // ProxyTag: Tag Perso
      getTagPerso: function () {
        return this.tagPerso;
      },
      setTagPerso: function (proxyTag) {
        this.tagPerso = proxyTag;
      },

      // Objet
      getObjet: function () {
        return this.objet;
      },
      setObjet: function (objet) {
        this.objet = objet;
      },

      // Assigné à
      getAssigneA: function () {
        return this.assigneA;
      },
      setAssigneA: function (string) {
        this.assigneA = string;
      },

      // Assigné Par
      getAssignePar: function () {
        return this.assignePar;
      },
      setAssignePar: function (string) {
        this.assignePar = string;
      },

      // idContenuRefInterAgence
      getIdContenuRefInterAgence: function(){
        return this.idContenuRefInterAgence;
      },
      setIdContenuRefInterAgence: function(val){
        this.idContenuRefInterAgence = val;
      },

      isModifiable: function () {
        return this.getModifiable();
      },

      isEnAttente: function () {
        var retour = false;
        var tagsType = this.getTagsType();
        if(_.isArray(tagsType) && tagsType.length){
          for(var i = 0; i < tagsType.length; i++){
            if(_.isObject(tagsType[i]) && tagsType[i].isTagStatutEnAttente()){
              retour = true;
              break;
            }
          }
        }
        return retour;
      },

      isEnCours: function () {
        var retour = false;
        var tagsType = this.getTagsType();
        if(_.isArray(tagsType) && tagsType.length){
          for(var i = 0; i < tagsType.length; i++){
            if(_.isObject(tagsType[i]) && tagsType[i].isTagStatutEnCours()){
              retour = true;
              break;
            }
          }
        }
        return retour;
      },

      isTermine: function () {
        var retour = false;
        var tagsType = this.getTagsType();
        if(_.isArray(tagsType) && tagsType.length){
          for(var i = 0; i < tagsType.length; i++){
            if(_.isObject(tagsType[i]) && tagsType[i].isTagStatutTermine()){
              retour = true;
              break;
            }
          }
        }
        return retour;
      },

      isProbleme: function () {
        var retour = false;
        var tagsType = this.getTagsType();
        if(_.isArray(tagsType) && tagsType.length){
          for(var i = 0; i < tagsType.length; i++){
            if(_.isObject(tagsType[i]) && tagsType[i].isTagStatutProbleme()){
              retour = true;
              break;
            }
          }
        }
        return retour;
      },

      hasTagType: function (key) {

        var tagsType = this.getTagsType(), retour = false;
        if (tagsType.length) {
          for (var i = 0; i < tagsType.length; i++) {
            if (tagsType[i].isTagType(key)) {
              retour = tagsType[i];
              break;
            }
          }
        }
        return retour;
      },

      hasTagTechnicien: function (){
        return this.hasTagType(COLLAB_CONF.TAG_TYPE_TECHNICIEN);
      },

      hasTagMenage: function (){
        return this.hasTagType(COLLAB_CONF.TAG_TYPE_MENAGE);
      },

      hasTagIntervenantCapfun: function (){
        return (this.hasTagTechnicien() || this.hasTagMenage());
      },

      /**
       * Permet de savoir si il y a au moins un devis signé dans cet événement
       * @returns {boolean}
       */
      hasAnySignedDevis: function(){
        var hasDevisSigned = false;
        if(_.isArray(this.getListeDevis()) && this.getListeDevis().length){
          for(var d = 0; d < this.getListeDevis().length; d++){
            if(this.getListeDevis()[d].hasDevisSigne()){
              hasDevisSigned = true;
              break;
            }
          }
        }
        return hasDevisSigned;
      },

      save: function (titreTopic, cacher, categorieTopic) {
        var deferred = $q.defer();
        try {
          var _this = this;

          // Si update
          if (_this.getIdContenu()) {

            var dateEcheance = '', dateIntervention = '';
            if(_this.getDateEcheance()) dateEcheance = moment(_this.getDateEcheance()).format();
            if(_this.getDateIntervention()) dateIntervention = moment(_this.getDateIntervention()).format();

            var params = {
              idEvenement: _this.getIdContenu(),
              objetEvenement: _this.getObjet(),
              dateEcheanceEvenement: dateEcheance,
              dateInterventionEvenement: dateIntervention
            };

            var EvenementService = $injector.get('EvenementService');
            EvenementService
              .updateFieldByField(params)
              .then(function (evenement) {
                _this.setData(evenement);
                deferred.resolve({
                  action: 'updateEvenement',
                  obj: _this
                });
              })
              .catch(function (msg) {
                deferred.reject(msg);
              })
              .finally(function () {
                //TopicService = null;
              });
          }
          // Si nouveau
          else {
            // Si nouveau topic et pas de titre
            if(!_this.getIdTopic() && !titreTopic) throw new Error('Veuillez renseigner le "Titre"');

            var objTopic = {
              titreTopic: (!_this.getIdTopic()) ? titreTopic : null,
              idTopic: _this.getIdTopic()
            };
            if(!_.isNil(cacher)) {
              objTopic.cacher = cacher;
            }
            if(_.isObject(categorieTopic)) {
              objTopic.idCategorieTopic = categorieTopic.idCategorieTopic;
            }
            else{
              objTopic.idCategorieTopic = null;
            }

            var TopicService = $injector.get('TopicService');
            TopicService
              .createEvenement(objTopic, _this)
              .then(function (topic) {

                if(_this.getIdTopic()) {
                  deferred.resolve({
                    action: 'insertEvenement',
                    obj: topic
                  });
                }
                else {
                  deferred.resolve({
                    action: 'insertTopic',
                    obj: topic
                  });
                }

              })
              .catch(function (msg) {
                deferred.reject(msg);
              })
              .finally(function () {
                TopicService = null;
              });

          }
        }
        catch(err){
          deferred.reject(err.message);
        }


        return deferred.promise;
      },

      /**
       * Permet de créer un sinistre dans l'événement
       * @returns {Promise}
       */
      createSinistre: function (objetSinistre) {

        var _this = this;
        var deferred = $q.defer();

        var EvenementService = $injector.get('EvenementService');
        EvenementService
          .createSinistre(_this.idContenu, objetSinistre)
          .then(function (evenement) {
            _this.hydrate(evenement);
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      /**
       * Permet de créer un sinistre dans l'événement
       * @returns {Promise}
       */
      /*
      updateSinistre: function (objetSinistre) {

          var _this = this;
          var deferred = $q.defer();

          var params = {
              idEvenement: _this.idContenu,
              action: 'updateSinistre'
          };
          params = Contenu.initParamsSinistre(params, objetSinistre);
          ContenuEvenement.updateEvenement(params)
              .then(function (evenement) {
                  _this.setData(evenement);
                  deferred.resolve(_this);
              })
              .catch(function (msg) {
                  deferred.reject(msg);
              });

          return deferred.promise;
      },*/

      /**
       * Permet de supprimer le sinistre de l'événement
       * @returns {Promise}
       */
      deleteSinistre: function () {

        var _this = this;
        var deferred = $q.defer();

        var EvenementService = $injector.get('EvenementService');
        EvenementService.deleteSinistre(_this.idContenu)
          .then(function (evenement) {
            _this.setData(evenement);
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      /**
       * Permet de créer un devis dans l'événement
       * @returns {Promise}
       */
      createDevis: function (objetDevis) {

        var _this = this;
        var deferred = $q.defer();

        var EvenementService = $injector.get('EvenementService');
        EvenementService
          .createDevis(_this.idContenu, objetDevis)
          .then(function (listDevis) {
            _this.addDevis(listDevis);
            deferred.resolve(listDevis);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      /**
       * Permet de créer un contrat dans l'événement
       * @returns {Promise}
       */
      createContrat: function (objetContrat) {

        var _this = this;
        var deferred = $q.defer();

        var EvenementService = $injector.get('EvenementService');
        EvenementService
          .createContrat(_this.idContenu, objetContrat)
          .then(function (listContrat) {
            _this.addContrat(listContrat);
            deferred.resolve(listContrat);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      /**
       * Permet de split l'objet de l'événement qui contient une div avec l'objet et une div avec les fiches ics
       * @returns {{domObjet: null, domFiches: null, texteObjet: null, texteFiches: null}}
       */
      /*
      splitObjet: function(){
        var retour = {
          texteObjet: null,
          texteFiches: null,
          domObjet: null,
          domFiches: null,
        };
        if(_.isString(this.getObjet()) && !_.isEmpty(this.getObjet())){
          var arrayContents = angular.element(this.getObjet());
          if (arrayContents.length === 2) {
            retour.domObjet = arrayContents[0];
            retour.domFiches = arrayContents[1];

            retour.texteObjet = arrayContents[0].innerHTML;
            retour.texteFiches = UtilsService.replaceAll('&nbsp;', '', retour.domFiches.innerHTML);
          }
          else retour.texteObjet = this.getObjet();
        }
        return retour;
      },
      */
      splitObjet: function(){
        var retour = {
          texteObjet: null,
          texteFiches: null,
          domObjet: null,
          domFiches: null,
        };
        if(_.isString(this.getObjet()) && !_.isEmpty(this.getObjet())){

          var ok = false;
          var domParser = new DOMParser();
          var htmlElement = domParser.parseFromString(this.getObjet(), 'text/html');
          if(htmlElement != null){
            var divMainObj = htmlElement.getElementById('eventMainContent');
            var divFichesObj = htmlElement.getElementById('eventFichesContent');

            if (_.isObject(divMainObj) && _.isObject(divFichesObj)) {
              retour.domObjet = divMainObj;
              retour.texteObjet = divMainObj.innerHTML;

              retour.domFiches = divFichesObj;
              retour.texteFiches = UtilsService.replaceAll('&nbsp;', '', divFichesObj.innerHTML);
              ok = true;
            }
          }

          if(!ok) retour.texteObjet = this.getObjet();
        }
        return retour;
      },

      /**
       * Permet de récupérer seulement le text de l'objet de l'événement
       * @returns {null}
       */
      getTextEvenement: function(){
        var objectSplit = this.splitObjet();
        return objectSplit.texteObjet;
      },

      /**
       * Permet de récupérer seulement les fiches de l'événement
       * @returns {null}
       */
      getTextFiches: function(){
        var objectSplit = this.splitObjet();
        return objectSplit.texteFiches;
      },

      /**
       * Permet de récupérer seulement les fiches de l'événement
       * @returns {null}
       */
      getDomFiches: function(){
        var objectSplit = this.splitObjet();
        return objectSplit.domFiches;
      },

      /**
       * Permet d'extraire les infos des fiches
       * @param typeFiche
       * @param libelle
       * @returns {null}
       */
      extractInfoFromFiche: function(typeFiche, libelle, domFiches){
        var retour = null;
        if(_.isNil(domFiches)) domFiches = this.getDomFiches();
        if(!_.isNil(domFiches)){

          var arrayBlockquote = angular.copy(angular.element(domFiches).find('[data-fiche="'+typeFiche+'"]'));
          if(arrayBlockquote.length === 1) {
            if(typeFiche === "immeuble"){
              var digicodes = angular.element(arrayBlockquote).find(".ligne_digicode");
              if(digicodes.length){
                for(var d = 0; d < digicodes.length; d++){
                  digicodes[d].remove();
                }
              }
            }

            var blockquoteCite = angular.element(arrayBlockquote).find(".blockquoteGroupeUser-cite");
            if(!_.isNil(blockquoteCite)){
              retour = blockquoteCite.html();
              retour = retour.replace(new RegExp('(\<i\\s+class=".*"><\/i>)', 'gi'), '');
              if(!_.isNil(libelle)) retour = retour.replace(new RegExp("("+libelle+")\\s*\:\\s*", 'gi'), '');
              retour = _.trim(retour);
              if(typeFiche === "immeuble"){
                // Récupère ce qui est entre parenthèses, qui est normalement l'adresse de l'immeuble
                // Plus tard va se trouver dans un span avec une class "fiche-infos-adresse"
                var regex = /\((.*)\)/gi, m;
                retour = retour.replace(regex, function(match, adresse) {
                  adresse = UtilsService.stripTags(adresse);
                  return '(<a href="' + COLLAB_CONF.URL_LOCATE_ADRESSE + adresse + '" target="_blank">' + adresse + '</a>)';
                });
                /*
                while ((m = regex.exec(str)) !== null) {
                  // This is necessary to avoid infinite loops with zero-width matches
                  if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                  }

                  // The result can be accessed through the `m`-variable.
                  m.forEach((match, groupIndex) => {

                    if(groupIndex === 1){
                      retour.replace(match, '<a href="' + COLLAB_CONF.URL_LOCATE_ADRESSE + match + '">' + match + '</a>');
                    }
                    console.log(`Found match, group ${groupIndex}: ${match}`);
                  });
                }*/
              }
            }
          }
        }

        return retour;
      },

      /**
       * Permet d'extraire des fiches ICS les infos de l'origine
       - Si j'ai une seul fiche immeuble alors l'origine est immeuble
       - Si j'ai deux fiches (immeuble et (locataire ou prop ou copro) alors l'origine est la personnalité (loc/prop/copro)
       - Si j'ai trois fiches (immeuble, locataire et propriétaire) alors l'origine est le propriétaire.
       * @returns {*}
       */
      extractOrigineFromFiche: function(domFiches, otherThanPlace){
        var lieu = this.extractLieuInterventionFromFiche(domFiches);
        if(!_.isNil(lieu)){
          var prop = this.extractProprietaireFromFiche(domFiches);
          if(!_.isNil(prop)) return {
            libelleType: "Propriétaire",
            type: "proprietaire",
            value: prop
          };
          else {
            var loc = this.extractLocataireFromFiche(domFiches);
            if(!_.isNil(loc)) return {
              libelleType: "Locataire",
              type: "locataire",
              value: loc
            };

            var copro = this.extractCoproprietaireFromFiche(domFiches);
            if(!_.isNil(copro)) return {
              libelleType: "Copropriétaire",
              type: "coproprietaire",
              value: copro
            };
          }
        }
        // Si on demande un origine autre que l'immeuble alors à ce stade on retourne null
        // Car si il n'y a pas eu de retour avant alors on a que l'immeuble
        if(!_.isNil(otherThanPlace)) return null;
        return {
          libelleType: "Lieu",
          type: "lieu",
          value: lieu
        };
      },

      /**
       * Permet d'extraire des fiches ICS les infos du lieu d'intervention
       * @returns {*}
       */
      extractLieuInterventionFromFiche: function(domFiches){
        // Prendre ce qui est entre parenthèse pour en faire un lieu google map
        //<a href="http://maps.google.com/?q=1200 Pennsylvania Ave SE, Washington, District of Columbia, 20003">...</a>
        return this.extractInfoFromFiche('immeuble', 'Lieu Intervention', domFiches);
      },

      /**
       * Permet d'extraire des fiches ICS les infos du lieu d'intervention
       * @returns {*}
       */
      extractDigicodesFromFiche: function(domFiches){
        var retour = [];
        if(_.isNil(domFiches)) domFiches = this.getDomFiches();
        if(!_.isNil(domFiches)){
          var arrayBlockquote = angular.element(domFiches).find('[data-fiche="immeuble"]');
          if(arrayBlockquote.length === 1) {
            var digicodes = angular.element(arrayBlockquote).find(".ligne_digicode");
            if(digicodes.length){
              for(var d = 0; d < digicodes.length; d++){
                retour.push(digicodes[d].innerHTML);
              }
            }
          }
        }

        return retour;
      },

      /**
       * Permet d'extraire des fiches ICS les infos du propriétaire
       * @returns {*}
       */
      extractProprietaireFromFiche: function(domFiches){
        return this.extractInfoFromFiche('proprietaire', 'Propriétaire', domFiches);
      },

      /**
       * Permet d'extraire des fiches ICS les infos du copropriétaire
       * @returns {*}
       */
      extractCoproprietaireFromFiche: function(domFiches){
        return this.extractInfoFromFiche('coproprietaire', 'Copropriétaire', domFiches);
      },

      /**
       * Permet d'extraire des fiches ICS les infos du salarie
       * @returns {*}
       */
      extractSalarieFromFiche: function(domFiches){
        return this.extractInfoFromFiche('salarie', 'Salarie', domFiches);
      },

      /**
       * Permet d'extraire des fiches ICS les infos du bail
       * @returns {*}
       */
      extractBailFromFiche: function(domFiches){
        return this.extractInfoFromFiche('bail', 'Locataire', domFiches);
      },

      /**
       * Permet d'extraire des fiches ICS les infos du locataire
       * @returns {*}
       */
      extractLocataireFromFiche: function(domFiches){
        return this.extractInfoFromFiche('locataire', 'Locataire', domFiches);
      },

      /**
       * Permet d'extraire des fiches ICS les infos du colocataire
       * @returns {*}
       */
      extractColocataireFromFiche: function(domFiches){
        return this.extractInfoFromFiche('colocataire', 'Colocataire', domFiches);
      },

      /**
       * Permet d'extraire des fiches ICS les infos du fournisseur
       * @returns {*}
       */
      extractFournisseurFromFiche: function(domFiches){
        return this.extractInfoFromFiche('fournisseur', 'Fournisseur', domFiches);
      },

      /**
       * Permet d'extraire des fiches ICS les infos visicop
       * @returns {*}
       */
      extractVisicopFromFiche: function(domFiches){
        return this.extractInfoFromFiche('visicop', null, domFiches);
      },

      /**
       * Permet d'extraire des fiches ICS les infos du client capfun
       * @returns {*}
       */
      extractClientCapfunFromFiche: function(domFiches){
        return this.extractInfoFromFiche('clientCapfun', 'Client', domFiches);
      },

      /**
       * Permet d'extraire des fiches ICS les infos du lot multicamp
       * @returns {*}
       */
      extractLotMulticampFromFiche: function(domFiches){
        return this.extractInfoFromFiche('lotMulticamp', 'Lot', domFiches);
      },

      /**
       * Permet d'extraire des fiches ICS les infos du lieu commun capfun
       * @returns {*}
       */
      extractLieuCommunCapfunFromFiche: function(domFiches){
        return this.extractInfoFromFiche('lieuCommunCapfun', 'Lieu', domFiches);
      },
    };

    // Copie le prototype de Contenu dans le prototype de ContenuEvenement
    // ContenuEvenement hérite donc des methodes de Contenu
    angular.extend(ContenuEvenement.prototype, Contenu.prototype);

    return ContenuEvenement;
  }
})();
