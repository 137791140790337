(function() {
  'use strict';

  angular
    .module('collaboreApp')
    .service('RecapSmsService', RecapSmsServiceCtrl);

  /*@ngInject*/
  function RecapSmsServiceCtrl(MainService, ModalsService, $q, ErreurCollabService, RecapSms, RecapSmsResource){
    var ctrl = this;

    ctrl.initRecapSms         = initRecapSms;
    ctrl.checkIfExistInListe 	= checkIfExistInListe;	// Check si le recapMail exist dans une liste
    ctrl.getListRecapSms 			= getListRecapSms;			// Permet de récupérer la liste des recapSms

    function initRecapSms(objet){
      if(!objet.model) objet = new RecapSms(objet);
      return objet;
    }

    function checkIfExistInListe(obj,liste){
      var retour = false;
      if(_.isArray(liste) && _.isObject(obj) && obj.hasOwnProperty('id')) {
        if(liste.length){
          for(var i = 0; i < liste.length; i++) {
            if(liste[i].id === obj.id) {
              retour = true;
              break;
            }
          }
        }
      }
      return retour;
    }

    /**
     * Recupère une liste de RecapSms
     * @param obj
     * @returns {Promise}
     */
    function getListRecapSms(obj){
      var deferred = $q.defer();
      try{

        var param = {
          action: 'getListRecapSms',
          dateFin: moment().format('YYYY-MM-DD')
        };

        if(_.isObject(obj)) {
          if(!_.isNil('dateDebut')){	param.dateDebut = obj.dateDebut;}
          if(!_.isNil('dateFin')){		param.dateFin = obj.dateFin;}
        }

        RecapSmsResource.post(param, function(json){
          //console.log(json);
          deferred.resolve(MainService.convertJsonToModel(json, 'result', RecapSms, deferred));
        },function(error){
          deferred.reject(error);
        });

      }
      catch(err){
        ModalsService.alertErreur(err.message);
        deferred.reject(err.message);
      }

      return deferred.promise;
    }
  }
})();
