(function () {
  'use strict';

  /**
   * @desc Composant main pour les courrier
   * @example <courrier></courrier>
   */

  angular
    .module('collaboreApp')
    .component('courrier', {
      templateUrl: 'app/topics/components/courrier/courrier.component.html',
      bindings: {
        evenement: '<',
        courriers: '<',
        infosEvenement: '<',
        onEmit: '&'
      },
      controllerAs: 'courrierctrl',
      controller: CourrierController
    });

  // TODO : FAire les onInsert et onUpdate pour mettre à jour les fichier dans le widget du doc OU pêut être faire un event !!!


  // TODO : Bug quand ajout d'un GroupeUser Collab depuis l'annuaire qui n'est pas de ma société
  // TODO : Si ajoute d'un GroupeUSer Collab qui est d'un autre portefeuille que celui de l'événement il va y avoir un problème pour recup les adresse car elle vont etre recup sur le portefeuille de l'événement et pas celui choisi. Problème c'est qu'il ny a pas de portefeuille dans le GroupeUser Collab de l'annuaire

  /*@ngInject*/
  function CourrierController(COLLAB_CONF, $q, $scope, Groupe, TopicService, AnnuaireService, CourrierService, ModalsService, UtilsService, DocumentManagerService, Courrier) {

    var ctrl = this;
    ctrl.idContenu = null;
    ctrl.listDestinataire = [];
    var labelFournisseur = '<span class="label label-default">F</span>';
    var tooltipFournisseur = 'Fournisseur';

    var labelCoproprietaire = '<span class="label label-info">C</span>';
    var tooltipCoproprietaire = 'Copropriétaire';

    var labelProprietaire = '<span class="label label-info">P</span>';
    var tooltipProprietaire = 'Propriétaire';

    var labelLocataire = '<span class="label label-default">L</span>';
    var tooltipLocataire = 'Locataire';

    /*
     ctrl.selectedNewDestinataire = true;
     ctrl.selectedDestinataire = false;
     ctrl.selectedDestinataireTMP = false;*/

    ctrl.openAnnuaire = openAnnuaire;

    ctrl.onClickDocument = onClickDocument;
    ctrl.onClickEditCourrier = onClickEditCourrier;
    ctrl.onClickDeleteCourrier = onClickDeleteCourrier;

    ctrl.onClickDestinataire = onClickDestinataire;
    ctrl.onClickNewDestinataire = onClickNewDestinataire;
    ctrl.onChangeTemplate = onChangeTemplate;

    ctrl.onClickAddCourrier = onClickAddCourrier;
    ctrl.onClickSaveCourrier = onClickSaveCourrier;
    ctrl.onClickCancelCourrier = onClickCancelCourrier;
    ctrl.onEmitSelectSignatureContenu = onEmitSelectSignatureContenu;

    //ctrl.ckeditorOnReady 	= ckeditorOnReady;	// Fonction onReady de CKEDITOR

    ctrl.templatesCourrier = UtilsService.getModulesTemplatesObjet('model_courrier');

    ctrl.$onInit = function () {

      if (ctrl.evenement) {
        //console.log(ctrl.evenement);
        ctrl.courriers = ctrl.evenement.getListeCourrier();
        ctrl.idContenu = ctrl.evenement.getIdContenu();
      }

      if (angular.isUndefined(ctrl.courriers)) {
        ctrl.courriers = [];
      }

      init();
    };

    /**
     * Permet d'ajouter un destinataire à la liste de choix des destinataires, tout en vérifiant de ne pas faire de doublon
     * @param dest
     */
    function addDestinataireToList(dest) {
      var find = false;
      // Si il y a bien des destinataires dans la liste des destinataires
      if (ctrl.listDestinataire.length) {
        // Parcour la liste des destinataire actuel
        for (var d = 0; d < ctrl.listDestinataire.length; d++) {
          // Si dest à ajouté est selected alors on deselect les autres
          if (dest.checked) ctrl.listDestinataire[d].checked = false;
          // Si le destinataire actuel de la boucle de la liste à un groupe Collab
          if (ctrl.listDestinataire[d].groupe && dest.groupe) {
            // Si l'idGroupe du groupe est le meme que celui du destinataire à ajouté alors existe déjà
            if (ctrl.listDestinataire[d].groupe.getIdGroupe() === dest.groupe.getIdGroupe()) {
              find = true;
              // Si il est check alors check celui de la liste qui correspond au meme destinataire
              if (dest.checked) ctrl.listDestinataire[d].checked = true;
              break;
            }
          }
          // Si le destinataire actuel de la boucle à une perso venant d'ICS
          else if (ctrl.listDestinataire[d].perso && dest.perso) {
            // Si le numéro perso du destinataire est le meme que celui du destinataire à ajouté alors existe déjà
            if (ctrl.listDestinataire[d].perso.getNoperso() === dest.perso.getNoperso()) {
              find = true;
              // Si il est check alors check celui de la liste qui correspond au meme destinataire
              if (dest.checked) ctrl.listDestinataire[d].checked = true;
              break;
            }
          }
          else if (dest.adresse) {
            if (ctrl.listDestinataire[d].adresse && ctrl.listDestinataire[d].adresse.nomPrenom === dest.adresse.nomPrenom && ctrl.listDestinataire[d].adresse.rue === dest.adresse.rue && ctrl.listDestinataire[d].adresse.codePostal === dest.adresse.codePostal && ctrl.listDestinataire[d].adresse.ville === dest.adresse.ville) {
              find = true;
              // Si il est check alors check celui de la liste qui correspond au meme destinataire
              if (dest.checked) ctrl.listDestinataire[d].checked = true;
              break;
            }
          }
        }
      }
      // Si le destinataire à ajouté n'est pas trouvé dans la liste actuel alors on l'ajoute
      if (!find) {
        if(dest.checked) ctrl.selectedNewDestinataire = false;
        ctrl.listDestinataire.push(dest);
      }
    }

    /**
     * Permet de passer en chedcked un destinataire
     * @param dest
     */
    function selectDestinataire(dest) {
      if (!dest) {
        ctrl.selectedDestinataire = false;
        ctrl.selectedDestinataireTMP = false;
        ctrl.selectedNewDestinataire = true;
      }
      if (ctrl.listDestinataire.length) {
        for (var d = 0; d < ctrl.listDestinataire.length; d++) {

          var currentDest = ctrl.listDestinataire[d];
          currentDest.checked = false;

          if (angular.isObject(dest)) {

            var groupeCollab = false;
            var personnalite = false;

            if ((dest.isModel && dest.isGroupe) || dest.groupe) groupeCollab = (dest.isGroupe) ? dest : dest.groupe;
            else if ((dest.isModel && dest.isPersonnalite) || dest.perso) personnalite = (dest.isPersonnalite) ? dest : dest.perso;


            if (groupeCollab) {
              // Si il y a quelques chose dans "groupe" dans de liste destinataire actuel et que l'idGroupe est le meme que "groupeCollab"
              // OU
              // Si le destinataire à une "perso" et que sont no perso à le même que celui du libelle de "groupeCollab"
              if ((currentDest.groupe && currentDest.groupe.getIdGroupe() === groupeCollab.getIdGroupe()) || (currentDest.perso && currentDest.perso.getNoperso() === groupeCollab.getNumPerso())) {
                if (!currentDest.groupe) currentDest.groupe = angular.copy(groupeCollab);
                currentDest.checked = true;
                if (ctrl.selectedDestinataireTMP) ctrl.selectedDestinataire = angular.copy(ctrl.selectedDestinataireTMP);
                else {
                  ctrl.selectedDestinataire = angular.copy(currentDest);
                  ctrl.selectedDestinataireTMP = angular.copy(currentDest);
                }
                ctrl.selectedNewDestinataire = false;

              }
            }
            else if (personnalite) {
              // Si il y a quelques chose dans "perso" dans de liste destinataire actuel et que le num perso est le meme que "personnalite"
              // OU
              // Si le destinataire à un "groupe"
              // Si sont libelle à le numero perso de "personnalite"
              // OU
              // //Si le gorupe à une personnalite et que cette personnalite à le même numéro que la perso)
              //if ((currentDest.perso && currentDest.perso.getNoperso() === personnalite.getNoperso()) || (currentDest.groupe && ((currentDest.groupe.getNumPerso() === personnalite.getNoperso()) || (currentDest.groupe.getPerso() && currentDest.groupe.getPerso().getNoperso() === personnalite.getNoperso())))) {
              if ((currentDest.perso && currentDest.perso.getNoperso() === personnalite.getNoperso()) || (currentDest.groupe && ((currentDest.groupe.getNumPerso() === personnalite.getNoperso())))) {
                if (!currentDest.perso) currentDest.perso = angular.copy(personnalite);
                currentDest.checked = true;
                if (ctrl.selectedDestinataireTMP) ctrl.selectedDestinataire = angular.copy(ctrl.selectedDestinataireTMP);
                else {
                  ctrl.selectedDestinataire = angular.copy(currentDest);
                  ctrl.selectedDestinataireTMP = angular.copy(currentDest);
                }
                ctrl.selectedNewDestinataire = false;
              }
            }
            else if (angular.isObject(dest.adresse)) {
              if (currentDest.hasOwnProperty('adresse') && angular.isObject(currentDest.adresse)) {
                if (currentDest.adresse.nomPrenom === dest.adresse.nomPrenom && currentDest.adresse.rue === dest.adresse.rue && currentDest.adresse.codePostal === dest.adresse.codePostal && currentDest.adresse.ville === dest.adresse.ville) {
                  currentDest.checked = true;
                  if (ctrl.selectedDestinataireTMP) ctrl.selectedDestinataire = angular.copy(ctrl.selectedDestinataireTMP);
                  else {
                    ctrl.selectedDestinataire = angular.copy(currentDest);
                    ctrl.selectedDestinataireTMP = angular.copy(currentDest);
                  }
                  ctrl.selectedNewDestinataire = false;
                }
              }
            }
          }
        }
      }
    }

    function getListDestinataire() {

      var defered = $q.defer();

      if (ctrl.evenement) {
        //console.log(ctrl.evenement);
        if (ctrl.evenement.getListeDevis().length) {
          for (var i = 0; i < ctrl.evenement.getListeDevis().length; i++) {
            var devis = ctrl.evenement.getListeDevis()[i];
            if (devis.getDestinataires().length) {

              var dest = {
                libelle: null,
                label: labelFournisseur,
                idContenu: null,
                groupe: devis.getDestinataires()[0],
                perso: false,
                tooltip: tooltipFournisseur,
                checked: false
              };


              // Si perso PersoICS dans le Devis, recup le libelle de la perso ICS et ajoute cette perso dans le groupe Collab pour plus tard
              if (devis.getPersoICS()) {
                dest.libelle = devis.getPersoICS().getLibelle();
                dest.groupe.addPersoICS(devis.getPersoICS());
              }
              // Si toujours pas de libelle recup celui du Groupe
              if (dest.libelle === null) dest.libelle = dest.groupe.getDefaultLibelle();

              dest.libelle = dest.libelle;
              dest.tooltip = tooltipFournisseur + ' : ' + dest.libelle;

              if (devis.getPersoICS() || (dest.groupe.getListePersoICS() && dest.groupe.getListePersoICS().length)) addDestinataireToList(dest);
            }
          }
        }

        if (ctrl.evenement.getTagsType().length) {

          var portefeuille, promises = [];
          if (TopicService.mapTagTypeToObject.hasOwnProperty(COLLAB_CONF.TAG_TYPE_PORTEFEUILLE)) portefeuille = TopicService.mapTagTypeToObject[COLLAB_CONF.TAG_TYPE_PORTEFEUILLE];
          else {
            portefeuille = ctrl.evenement.getTagForType(COLLAB_CONF.TAG_TYPE_PORTEFEUILLE);
            TopicService.mapTagTypeToObject[COLLAB_CONF.TAG_TYPE_PORTEFEUILLE] = portefeuille;
          }

          if (portefeuille) {

            var dests = [
              {
                typeTag: COLLAB_CONF.TAG_TYPE_PROPRIETAIRE,
                label: labelProprietaire,
                tooltip: tooltipProprietaire
              },
              {
                typeTag: COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE,
                label: labelCoproprietaire,
                tooltip: tooltipCoproprietaire
              },
              {
                typeTag: COLLAB_CONF.TAG_TYPE_LOCATAIRE,
                label: labelLocataire,
                tooltip: tooltipLocataire
              }
            ];

            var tasks = [];
            angular.forEach(dests, function (dest) {
              var typeTag = dest.typeTag;
              var tooltip = dest.tooltip;
              var label = dest.label;
              if (!TopicService.mapTagTypeToObject.hasOwnProperty(typeTag)) {
                var currentDest = ctrl.evenement.getTagForType(typeTag);
                if (currentDest) {
                  var task = function(){

                    var deferredTask = $q.defer();
                    AnnuaireService
                      .getGroupeCollab(currentDest.getValeurIcs(), portefeuille.getValeurIcs())
                      .then(function (ret) {
                        if (!angular.isArray(ret) && angular.isObject(ret)) {

                          var objTMP = {};

                          if(ret.hasOwnProperty('groupe') && angular.isObject(ret.groupe)) {
                            objTMP = {
                              libelle: ret.groupe.getDefaultLibelle(),
                              label: label,
                              idContenu: null,
                              groupe: ret.groupe,
                              perso: false,
                              tooltip: tooltip + ' : ' + ret.groupe.getDefaultLibelle(),
                              checked: false,
                              portefeuille: false
                            };

                            TopicService.mapTagTypeToObject[typeTag] = ret.groupe;
                          }


                          if(ret.portefeuille) {
                            if (!TopicService.mapTagTypeToObject.hasOwnProperty('portfeuille')) TopicService.mapTagTypeToObject['portfeuille'] = ret.portefeuille;
                            objTMP.portefeuille = ret.portefeuille;
                          }

                          addDestinataireToList(objTMP);
                        }
                        deferredTask.resolve();
                      })
                      .catch(function(msg){
                        deferredTask.reject(msg);
                      });
                    return deferredTask.promise;
                  };
                  tasks.push(task);
                }
              }
              else if (TopicService.mapTagTypeToObject.hasOwnProperty(typeTag)) {
                addDestinataireToList({
                  libelle: TopicService.mapTagTypeToObject[typeTag].getDefaultLibelle(),
                  label: label,
                  idContenu: null,
                  groupe: TopicService.mapTagTypeToObject[typeTag],
                  perso: false,
                  tooltip: tooltip + ' : ' + TopicService.mapTagTypeToObject[typeTag].getDefaultLibelle(),
                  checked: false,
                  portefeuille: TopicService.mapTagTypeToObject['portfeuille']
                });
              }
            });

            if(tasks.length) {
              ctrl.loadingDestinataires = true;
              $q.serial(tasks)
                .then(function () {

                  defered.resolve(true);
                })
                .catch(function (msg) {
                  defered.reject('Pas de promesses !');
                  console.log(msg);
                })
                .finally(function(){
                  ctrl.loadingDestinataires = false;
                });

              /*
              $q.all(promises).then(function () {
                ctrl.loadingDestinataires = false;
                defered.resolve(true);
              }).catch(function () {
                defered.reject('Pas de promesses !');
              });*/
            }
          }
        }
      }
      else defered.resolve(true);

      return defered.promise;
    }

    //--------------- CKEDITOR ---------------------------
    /*
     ctrl.instanceCKEDITOR = false;

     // Editor options.
     ctrl.ckeditorOptions 	= {
     height: 300,
     on: {
     instanceReady: function(obj) {
     ctrl.instanceCKEDITOR = obj.editor;
     this.dataProcessor.htmlFilter.addRules({
     elements: {
     img: function( el ) {
     var isBase64 = UtilsService.test(el.attributes.src,'base64','i');
     if(isBase64){
     el.addClass( 'image-max-editor' );
     }
     }
     }
     });
     }
     }
     };


     // Called when the editor is completely ready.
     function ckeditorOnReady() {
     // Ajout l'instance qui vien d'être créé dans le pool d'instance de CKEDITOR
     $rootScope._poolInstancesCKEDITOR[0] = ctrl.instanceCKEDITOR;

     if(ctrl.editCourrier.courrier && ctrl.editCourrier.courrier.corp !== ''){
     ctrl.instanceCKEDITOR.setData(ctrl.editCourrier.courrier.corp);
     }
     }

     /**
     * Destroy une éventuelle instance de CKEDITOR
     *
     function destroyCKEDITOR(){
     if(ctrl.instanceCKEDITOR){
     ctrl.instanceCKEDITOR.removeAllListeners();
     ctrl.instanceCKEDITOR.destroy();
     }
     }
     */

    //--------------- FIN CKEDITOR ---------------------------

    function init(initInstance) {

      ctrl.currentTemplateCourrier = null;
      ctrl.selectedNewDestinataire = false;
      ctrl.selectedDestinataire = false;
      ctrl.selectedDestinataireTMP = false;

      ctrl.editCourrier = {
        show: false,
        courrier: null
      };
      if (initInstance) {
        ctrl.editCourrier = {
          show: true,
          courrier: (initInstance === true) ? new Courrier() : angular.copy(initInstance)
        };

        var dest = ctrl.editCourrier.courrier.getGroupeDestinataire();
        getListDestinataire().then(function () {
          if (initInstance !== true && (_.isNil(dest) || !dest || _.isNil(dest.idGroupe))) {
            dest = {
              libelle: ctrl.editCourrier.courrier.getNomPrenom(),
              idContenu: null,
              groupe: false,
              perso: false,
              tooltip: ctrl.editCourrier.courrier.getNomPrenom(),
              checked: true,
              adresse: {
                nomPrenom: ctrl.editCourrier.courrier.getNomPrenom(),
                rue: ctrl.editCourrier.courrier.getRue(),
                codePostal: ctrl.editCourrier.courrier.getCodePostal(),
                ville: ctrl.editCourrier.courrier.getVille()
              }
            };
            addDestinataireToList(dest);
            initAdresse(dest, dest.adresse);
          }
          //else initAdresse(dest, ctrl.editCourrier.courrier);
          else {
            selectDestinataire(dest);
            /*
             initDestinataire({
             groupe: dest
             });*/
          }
        });
      }
    }

    /**
     * Ouvre l'annuaire pour recherche un destinataire
     */
    function openAnnuaire() {
      var params = {
        stringTo: 'destinataires',
        allCollaborateur: true,
        limitDestinataire: 1,
        allowNoMail: true,
        infosEvent: {},
        defautTypeAnnuaire: 'perso'
      };

      if (ctrl.infosEvenement) {
        params.infosEvent = ctrl.infosEvenement;
      }

      params.infosEvent.displayContratsLinks = true;

      var options = {
        size: 'full',
        windowClass: 'modal-collabore modal-collabore-full',
        backdrop: 'static'
      };

      ModalsService
        .modalComponent('modalAnnuaire', params, options)
        .then(function (obj) {
          if ((obj.hasOwnProperty('arrayContacts') && angular.isArray(obj.arrayContacts) && obj.arrayContacts.length) || (obj.hasOwnProperty('origine') && obj.origine)) {

            var objContact = false;
            if (obj.hasOwnProperty('arrayContacts')) objContact = obj.arrayContacts[0];
            else if (obj.hasOwnProperty('origine')) objContact = obj.origine;

            var objDest = false;
            var groupe = false;
            var perso = false;
            var label = '', tooltip = false;
            //var lib = '<span class="label label-info">C</span>';

            // Si obj d'un groupe Collab
            if (objContact.type === 'collab' || objContact.isGroupe) {
              if (objContact.hasOwnProperty('user') && angular.isObject(objContact.user) && objContact.user.isModel) {
                groupe = objDest = new Groupe(objContact);
                ctrl.editCourrier.courrier.setGroupeDestinataire(groupe);
              }
            }
            // Si perso ICS
            else if (objContact.type === 'perso' || objContact.isPersonnalite) {
              if (objContact.hasOwnProperty('perso') && angular.isObject(objContact.perso) && objContact.perso.isModel) perso = objDest = objContact.perso;
              else if (objContact.isPersonnalite) perso = objDest = objContact;

              if(perso.isPersoFournisseur || perso.isFournisseur()) {
                tooltip = tooltipFournisseur;
                label = labelFournisseur;
              }
              if(perso.isLocataire()) {
                tooltip = tooltipLocataire;
                label = labelLocataire;
              }
              if(perso.isCoproprietaire()) {
                tooltip = tooltipCoproprietaire;
                label = labelCoproprietaire;
              }
              if(perso.isProprietaire()) {
                tooltip = tooltipProprietaire;
                label = labelProprietaire;
              }

            }

            if (objDest) {

              var objetNewDest = {
                libelle: objDest.getNom(),
                label: label,
                idContenu: null,
                groupe: groupe,
                perso: perso,
                tooltip: objDest.getNom(),
                checked: true,
                portefeuille: false
              };

              // Si il y a un portefeuille dans le contact on le recupère
              if(angular.isObject(objContact.portefeuille)) objetNewDest.portefeuille = objContact.portefeuille;


              if(tooltip) objetNewDest.tooltip = tooltip+' : ' + objetNewDest.tooltip;

              // Ajoute l'object dans les destinataires
              addDestinataireToList(objetNewDest);

              initDestinataire(objetNewDest).then(function (personnalite) {

                var nom = personnalite.getNom();
                if (nom) {
                  objetNewDest.libelle = nom;
                  if(tooltip) objetNewDest.tooltip = tooltip+' : ' +nom;
                  else objetNewDest.tooltip = nom;
                  objetNewDest.perso = personnalite;
                }
                ctrl.selectedDestinataireTMP = objetNewDest;


              });
            }
          }
        });
    }

    /**
     * Permet de remplir l'adresse en fonction du destinataire
     * @param obj
     */
    function initDestinataire(obj) {
      var defered = $q.defer();
      try {
        if (!obj || (angular.isObject(obj) && (!obj.perso && !obj.groupe && !obj.adresse))) throw new Error('Argument invalid, "obj" obligatoire avec attribut groupe ou perso de type model Groupe ou Personnalite : ' + angular.toJson(obj));

        // Si c'est un objet d'une perso venant d'ics
        if (obj.perso && obj.perso.isPersonnalite) {
          initDestinatairePerso(obj.perso);
          defered.resolve(obj.perso);
        }
        // Si c'est un objet de type groupe Collab
        else if (obj.groupe && obj.groupe.isGroupe) {

          getInfosPerso(obj)
            .then(function (perso) {
              initDestinatairePerso(perso);
              defered.resolve(perso);
            })
            .catch(function () {
              // TODO : Si adresse utlisé dans les fiches Collab alors c'est dans ce catch qu'il faudra la chargé si pas trouvé de perso par exemple
            });

        }
        else if (obj.adresse) {
          initAdresse(obj, obj.adresse);
        }
      }
      catch (err) {
        var msg = 'CourrierController.initDestinataire Erreur : ' + err.message;
        console.log(msg);
      }
      return defered.promise;
    }

    /**
     * Init un destinataire de type Perso venant d'ICS
     * @param obj
     */
    function initDestinatairePerso(objPerso) {
      try {
        if (!objPerso || (angular.isObject(objPerso) && !objPerso.isPersonnalite)) throw new Error('Argument invalid, "objPerso" obligatoire et de type model Personnalite : ' + objPerso);

        // Recup les adresse de la perso
        var adresses = objPerso.getAdresses();

        // Si il y a des adresses
        if (adresses.length) {

          // Si il y a plus d'une adresse
          if (adresses.length > 1) {

            var params = {
              selectedAdresse: {
                nomPrenom: angular.copy(ctrl.editCourrier.courrier.getNomPrenom()),
                rue: angular.copy(ctrl.editCourrier.courrier.getRue()),
                codePostal: angular.copy(ctrl.editCourrier.courrier.getCodePostal()),
                ville: angular.copy(ctrl.editCourrier.courrier.getVille()),
                custom: false
              }
            };

            var options = {
              size: 'md',
              windowClass: 'modal-collabore',
              backdrop: 'static'
            };

            if (angular.isObject(ctrl.selectedDestinataire) && ctrl.selectedDestinataire.perso) {

              params.selectedAdresse.noPerso = ctrl.selectedDestinataire.perso.getNoperso();

              if (ctrl.selectedDestinataire.perso.getNoperso() === objPerso.getNoperso()) {
                if (angular.isDefined(ctrl.editCourrier.courrier.getNomPrenom()) || angular.isDefined(ctrl.editCourrier.courrier.getRue()) || angular.isDefined(ctrl.editCourrier.courrier.getCodePostal()) || angular.isDefined(ctrl.editCourrier.courrier.getVille())) {
                  var find = false;
                  for (var i = 0; i < adresses.length; i++) {
                    if (adresses[i].nomPrenom === ctrl.editCourrier.courrier.getNomPrenom() && adresses[i].rue === ctrl.editCourrier.courrier.getRue() && adresses[i].codePostal === ctrl.editCourrier.courrier.getCodePostal() && adresses[i].ville === ctrl.editCourrier.courrier.getVille()) {
                      find = true;
                      break;
                    }
                  }
                  if (!find) {
                    params.selectedAdresse.custom = true;
                    objPerso.setAdressesTMP([params.selectedAdresse]);
                  }
                }
              }
            }


            params.adresses = objPerso.getAdresses();

            ModalsService
              .modalComponent('choixAdresse', params, options)
              .then(function (obj) {
                if (_.isObject(obj) && !_.isNil(obj.adresse)) initAdresse(objPerso, obj.adresse);
                else ctrl.selectedDestinataireTMP = angular.copy(ctrl.selectedDestinataire);
              })
              .catch(function () {
                ctrl.selectedDestinataireTMP = angular.copy(ctrl.selectedDestinataire);
              });


          }
          else {
            // Prend par default la première adresse
            initAdresse(objPerso, adresses[0]);
          }
        }
      }
      catch (err) {
        var msg = 'CourrierController.initDestinatairePerso Erreur : ' + err.message;
        console.log(msg);
      }
    }

    /**
     * Permet d'init une adresse et select le destinataire
     * @param objDest
     * @param objAdresse
     */
    function initAdresse(objDest, objAdresse) {
      try {
        if (objDest && objAdresse) {
          if (!objDest.isPersonnalite && !objDest.isGroupe && !objDest.adresse) throw new Error('"objDest" doit être un mode de type Personnalite ou Groupe ou doit avoir un attribut "libelle"');

          //if (!objDest.isPersonnalite && !objDest.isGroupe) ctrl.editCourrier.courrier.setNomPrenom(objDest.getNom());
          //else  ctrl.editCourrier.courrier.setNomPrenom(objAdresse.nomPrenom);

          if (objDest.adresse && objDest.adresse.nomPrenom) ctrl.editCourrier.courrier.setNomPrenom(objDest.adresse.nomPrenom);
          else if (objAdresse.nomPrenom) ctrl.editCourrier.courrier.setNomPrenom(objAdresse.nomPrenom);
          else if ((objDest.isPersonnalite || objDest.isGroupe) && !angular.isDefined(objAdresse.nomPrenom)) ctrl.editCourrier.courrier.setNomPrenom(objDest.getNom());

          ctrl.editCourrier.courrier.setRue(objAdresse.rue);
          ctrl.editCourrier.courrier.setCodePostal(objAdresse.codePostal);
          ctrl.editCourrier.courrier.setVille(objAdresse.ville);

          //ctrl.selectedDestinataire = angular.copy(ctrl.selectedDestinataireTMP);
          //ctrl.selectedNewDestinataire = false;

          selectDestinataire(objDest);
        }
        else {
          ctrl.editCourrier.courrier.setNomPrenom('');
          ctrl.editCourrier.courrier.setRue('');
          ctrl.editCourrier.courrier.setCodePostal('');
          ctrl.editCourrier.courrier.setVille('');
          //ctrl.selectedDestinataire = false;
          //ctrl.selectedDestinataireTMP = false;
          //ctrl.selectedNewDestinataire = true;
          selectDestinataire(false);
        }

      }
      catch (err) {
        var msg = 'CourrierController.initAdresse Erreur : ' + err.message;
        console.log(msg);
      }
    }

    function onClickDocument(courrier) {
      DocumentManagerService.openDocument(courrier.getDocument().getGuid(), ctrl.idContenu);
    }

    function onClickEditCourrier(courrier) {
      //getListDestinataire();
      ctrl.selectedNewDestinataire = false;
      init(courrier);
    }

    function onClickDeleteCourrier(courrier) {
      ModalsService.confirm('Supprimer', 'Supprimer "<strong>' + courrier.libelle + '</strong>" ?').then(function () {
        var modalLoading = ModalsService.loading('Suppression en cours');

        CourrierService
          .deleteCourrier(courrier.getIdCourrier())
          .then(function () {
            ctrl.courriers.splice(ctrl.courriers.indexOf(courrier), 1);
            ctrl.onEmit({
              obj: {
                action: 'delete',
                courrier: courrier
              }
            });
            modalLoading.close();
          })
          .catch(function (msg) {
            ModalsService.alertErreur(msg);
          });
      });
    }

    var prevTemplate = false,
      prevObjetTemplate = {
        libelle: null,
        objet: null,
        corp: null
      };


    /**
     * Lors du clique sur un destinataire
     */
    function onClickDestinataire(dest) {
      initDestinataire(dest);

      //if (dest.perso) initDestinataire(dest.perso);
      //else if (dest.groupe) initDestinataire(dest.groupe);
    }

    function onClickNewDestinataire() {
      ctrl.selectedNewDestinataire = true;
      initAdresse();
    }

    /**
     * Permet de récupérer une perso sur ICSCloud et de la lié au groupe
     * @param groupeCollab
     */

    function getInfosPerso(objetDest) {
      var defered = $q.defer();
      try {
        if (!objetDest || (angular.isObject(objetDest) && (!objetDest.groupe || !objetDest.groupe.isGroupe))) throw new Error('Argument invalid, "obj" obligatoire et doit avoir un attribut "groupe" de type model Groupe : ' + angular.toJson(objetDest));

        var noPerso = false, portefeuille = false;

        // Recup une éventuelle perso ICS
        var persoICS = objetDest.groupe.getLastPersoICS();
        // si perso ICS alors surement un fournisseur venant d'un devis ou intervention
        if (persoICS) {
          noPerso = persoICS.getNoperso();
          portefeuille = persoICS.getPortefeuille();
        }
        // Si pas de perso ICS alors c'est un Copropriétaire, Locataire ou Propriétaire
        else {
          noPerso = objetDest.groupe.getNumPerso();
          // Recup le portefeuille de l'événement actuel qui est dans le service du topic
          if (TopicService.mapTagTypeToObject.hasOwnProperty('portfeuille')) portefeuille = TopicService.mapTagTypeToObject['portfeuille'];
        }

        // Si les infos sont pas ok
        if (!noPerso || !portefeuille)  throw new Error('"noPerso" ou "portefeuille" mauvais => noPerso : ' + noPerso + '; portefeuille : ' + portefeuille);


        //Va chercher la perso ICS sur ICSCloud parle biais de Collab
        ctrl.loadingAdresse = true;

        //AnnuaireService.getGroupeCollab(currentDest.getValeurIcs(), portefeuille.getValeurIcs())
        AnnuaireService
          .getPerso(noPerso, portefeuille.getIdPortefeuille())
          .then(function (obj) {

            if (obj && !angular.isObject(obj.perso)) throw new Error('Mauvais retour => ' + angular.toJson(obj));

            var perso = false;
            if (angular.isArray(obj.perso) && obj.perso.length) perso = obj.perso[0];
            else if (!angular.isArray(obj.perso)) perso = obj.perso;

            if (!perso) throw new Error('Pas de "perso" => ' + perso);

            objetDest.perso = perso;
            defered.resolve(perso);
          })
          .finally(function () {
            ctrl.loadingAdresse = false;
          });
      }
      catch (err) {
        var msg = 'CourrierController.getInfosPerso Erreur : ' + err.message;
        console.log(msg);
        defered.reject(msg);
      }
      return defered.promise;
    }

    /**
     * Lors du changement de tmeplate du courrier
     */
    function onChangeTemplate() {

      if (!prevTemplate || prevTemplate.objet == '') {
        prevObjetTemplate.libelle = angular.copy(ctrl.editCourrier.courrier.libelle);
        prevObjetTemplate.objet = angular.copy(ctrl.editCourrier.courrier.objet);
        prevObjetTemplate.corp = angular.copy(ctrl.editCourrier.courrier.corp);
      }

      // Si un model est selectionné
      if (angular.isObject(ctrl.currentTemplateCourrier)) {
        // Si il y a des model event
        if (ctrl.templatesCourrier.length) {
          // Pour chaque model
          for (var m = 0; m < ctrl.templatesCourrier.length; m++) {
            var currentModel = ctrl.templatesCourrier[m];


            // Si le model de la boucle en cours est égale au model choisi
            if (ctrl.currentTemplateCourrier.idModuleValeurParams === currentModel.idModuleValeurParams) {

              // Si il y a un objet
              if (currentModel.libelle !== '' || currentModel.objet !== '') {
                if (currentModel.categorie !== '') ctrl.editCourrier.courrier.libelle = angular.copy(currentModel.categorie);
                if (currentModel.libelle !== '') ctrl.editCourrier.courrier.objet = angular.copy(currentModel.libelle);
                //if(currentModel.objet !== '') ctrl.instanceCKEDITOR.setData(angular.copy(currentModel.objet));
                if (currentModel.objet !== '') ctrl.editCourrier.courrier.corp = angular.copy(currentModel.objet);
              }
              else {
                // Si il y un model choisi avant de changer
                if (angular.isObject(prevTemplate)) {
                  // Si ce model avait un objet prédéfini
                  if (prevTemplate.objet !== '') {
                    if (prevObjetTemplate) {

                      currentModel.categorie = prevObjetTemplate.libelle;
                      currentModel.objet = prevObjetTemplate.corp;
                    }
                    else {
                      ctrl.editCourrier.courrier.libelle = '';
                      ctrl.editCourrier.courrier.objet = '';
                      //ctrl.instanceCKEDITOR.setData('');
                      ctrl.editCourrier.courrier.corp = '';
                    }
                  }
                }
              }
              break;
            }
          }
        }
      }
      // Siil y avait un model choisi avant de changer
      else if (angular.isObject(prevTemplate) && prevTemplate.objet !== '') {

        if (prevObjetTemplate) {
          ctrl.editCourrier.courrier.libelle = prevObjetTemplate.categorie;
          ctrl.editCourrier.courrier.objet = prevObjetTemplate.libelle;
          //ctrl.instanceCKEDITOR.setData(prevObjetTemplate.corp);
          ctrl.editCourrier.courrier.corp = prevObjetTemplate.corp;
        }
        else {
          ctrl.editCourrier.courrier.libelle = '';
          ctrl.editCourrier.courrier.objet = '';
          //ctrl.instanceCKEDITOR.setData('');
          ctrl.editCourrier.courrier.corp = '';
        }
      }
      prevTemplate = angular.copy(ctrl.currentTemplateCourrier);


    }

    function onClickAddCourrier() {
      init(true);
    }

    function onClickSaveCourrier() {

      //ctrl.editCourrier.courrier.setCorp(ctrl.instanceCKEDITOR.getData());

      try {
        /*
         if(ctrl.listDestinataire.length) {
         for(var d = 0; d < ctrl.listDestinataire.length; d++)
         if(ctrl.listDestinataire[d].checked) ctrl.editCourrier.courrier.setGroupeDestinataire(ctrl.listDestinataire[d].groupe);
         }*/

        if ((!angular.isObject(ctrl.selectedDestinataire) && angular.isObject(ctrl.selectedDestinataire.groupe)) || (ctrl.editCourrier.courrier.getNomPrenom() === '' && ctrl.editCourrier.courrier.getRue() === '' && ctrl.editCourrier.courrier.getCodePostal() === '' && ctrl.editCourrier.courrier.getVille() === '')) throw new Error('Destinataire Invalid !');

        var idDest = false;
        if (ctrl.selectedDestinataire.groupe) idDest = ctrl.selectedDestinataire.groupe.getIdGroupe();

        var obj = {
          idContenu: ctrl.idContenu,
          idGroupeDestinataire: idDest,                           // Valeur à checker dans "selectedDestinataire" voir si le destinataire na pas changé
          nomPrenom: ctrl.editCourrier.courrier.getNomPrenom(),   // Valeur modifié en live
          rue: ctrl.editCourrier.courrier.getRue(),               // Valeur modifié en live
          codePostal: ctrl.editCourrier.courrier.getCodePostal(), // Valeur modifié en live
          ville: ctrl.editCourrier.courrier.getVille(),           // Valeur modifié en live
          libelle: ctrl.editCourrier.courrier.getLibelle(),       // Valeur modifié en live
          objet: ctrl.editCourrier.courrier.getObjet(),           // Valeur modifié en live
          corp: ctrl.editCourrier.courrier.getCorp(),             // Valeur modifié en live
          numPerso: ctrl.editCourrier.courrier.getNumPerso(),     // Valeur à checker dans "selectedDestinataire" voir si le destinataire na pas changé
          idPortefeuille: (ctrl.editCourrier.courrier.getPortefeuille()) ? ctrl.editCourrier.courrier.getPortefeuille().getIdPortefeuille() : false // Valeur à checker dans "selectedDestinataire" voir si le destinataire na pas changé
        };

        // Si il y a un portefeuille dans l'objet destinataire alors il faut recup l'idPortefeuille, ca va servir à recup les bonne infos plus tard si modification
        if (angular.isObject(ctrl.selectedDestinataire.portefeuille)) obj.idPortefeuille = ctrl.selectedDestinataire.portefeuille.idPortefeuille;

        // Si il y a un objet perso dans le destinataire alors on recup le noPerso, ca va servir à recup les bonne infos plus tard si modification
        if (angular.isObject(ctrl.selectedDestinataire.perso) && ctrl.selectedDestinataire.perso.getNoperso()) obj.numPerso = ctrl.selectedDestinataire.perso.getNoperso();


        if (angular.isObject(ctrl.editCourrier.courrier.getContenuReference())) obj.idContenu = ctrl.editCourrier.courrier.getContenuReference().getIdContenu();

        if (ctrl.editCourrier.courrier.getNumRar()) obj.numRar = ctrl.editCourrier.courrier.getNumRar();

        if(angular.isObject(ctrl.signatureSelected) && ctrl.signatureSelected.hasOwnProperty('value')) obj.signature = ctrl.signatureSelected.value;

        var modalLoading = ModalsService.loading();
        if (ctrl.editCourrier.courrier.getIdCourrier()) {
          obj.idCourrier = ctrl.editCourrier.courrier.getIdCourrier();
          CourrierService
            .updateCourrierSimple(obj)
            .then(function (courrier) {
              ctrl.editCourrier.courrier.setData(courrier);
              for (var c = 0; c < ctrl.courriers.length; c++) {
                if (ctrl.courriers[c].getIdCourrier() === courrier.getIdCourrier()) ctrl.courriers[c].setData(courrier);
              }
              init();
              modalLoading.close();

              ctrl.onEmit({
                obj: {
                  action: 'update',
                  courrier: courrier
                }
              });
            })
            .catch(function (msg) {
              ModalsService.alertErreur(msg);
            });
        }
        else {

          CourrierService
            .createCourrierSimple(obj)
            .then(function (courrier) {
              ctrl.courriers.push(courrier);
              init();
              modalLoading.close();

              ctrl.onEmit({
                obj: {
                  action: 'add',
                  courrier: courrier
                }
              });
            })
            .catch(function (msg) {
              ModalsService.alertErreur(msg);
            });
        }
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
      }
    }

    function onClickCancelCourrier() {
      init();
      //destroyCKEDITOR();
    }

    /**
     * Lors du choix d'une signature
     * @param obj
     */
    function onEmitSelectSignatureContenu(obj){
      try {
        if (!obj) throw new Error('Pas d\'obj !');
        if (angular.isObject(obj) && obj.hasOwnProperty('action') && obj.hasOwnProperty('signature')) {
          if(obj.action === 'changeSignature') ctrl.signatureSelected = obj.signature;
        }
      }
      catch (err) {
        console.log('[manageDevis.onEmitSelectSignatureContenu] Erreur : ' + err.message);
      }
    }
  }
})();
