(function() {
	'use strict';

	angular.module('collaboreApp').factory('DestinataireMail', DestinataireMailModel);

	/*@ngInject*/
	function DestinataireMailModel(Main){

		function DestinataireMail(objetData){
            Main.apply(this, arguments);
            this.hydrate(objetData);
		}

        DestinataireMail.prototype = {

		    model: 'DestinataireMail',
		    isDestinataireMail: true,

			getIdDestinataireMail: function(){
				return this.idDestinataireMail;
			},
            setIdDestinataireMail: function(id){
				this.idDestinataireMail = id;
			},

            getDateCreation: function(){
                return this.dateCreation;
            },
            setDateCreation: function(date){
                this.dateCreation = date;
            },

            getDestinataire: function(){
                return this.destinataire;
            },
            setDestinataire: function(val){
                this.destinataire = val;
            }
		};

        angular.extend(DestinataireMail.prototype, Main.prototype);

		return DestinataireMail;
	}

})();