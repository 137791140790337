(function() {

  'use strict';

  /**
   * @desc Composant recapSms
   * @example <recap-shooting-mail></recap-shooting-mail>
   */
  angular
    .module('collaboreApp')
    .component('recapSms',{
      templateUrl: 'app/recap-sms/recap-sms.component.html',
      controllerAs: 'recapsmsctrl',
      controller: RecapSmsCtrl
    });

  /** @ngInject */
  function RecapSmsCtrl($locale, RecapSmsService, UtilsService, ModalsService, SocketNotificationService){
    var _this = this;
    _this.getRowEtat = getRowEtat;                               // Permet de retourner la class qu'il faut pour la ligne
    _this.clearDatesRecapSms = clearDatesRecapSms;           // Permet de remetre à 0 les dates
    _this.onClickRefresh = onClickRefresh;                       // Permet de refresh
    _this.onClickRenvoyer = onClickRenvoyer;                       // Permet de renvoyer

    _this.loadingListeRecapSms = false;
    _this.listeRecapSms = [];
    _this.moment = moment;

    var defaultValuesDatePicker = {
      startDate: moment().startOf('isoWeek'),
      endDate:  moment().endOf('isoWeek'),
      label: 'Cette semaine (depuis Lundi)'
    };

    _this.filtre = {
      show: true,
      dateRecapSms: {                        // Dates des recap mails
        showDates: false,
        isDefault: true,
        startDate: angular.copy(defaultValuesDatePicker.startDate),
        endDate: angular.copy(defaultValuesDatePicker.endDate),
        label: angular.copy(defaultValuesDatePicker.label)
      }
    };

    _this.optionsDatepicker = {
      showDropdowns: true,
      autoApply: false,
      clearLabel: 'Effacer',
      linkedCalendars: false,
      locale: {
        format: 'DD/MM/YYYY',
        separator: ' au ',
        applyLabel: 'Ok',
        fromLabel: 'Du',
        toLabel: 'au',
        cancelLabel: 'Annuler',
        customRangeLabel: 'Personnaliser',
        daysOfWeek: $locale.DATETIME_FORMATS.SHORTDAY,
        firstDay: 1,
        monthNames: $locale.DATETIME_FORMATS.STANDALONEMONTH
      },
      eventHandlers: {
        'show.daterangepicker': function(){
          _this.datePickerDateShow = true;
        },
        'hide.daterangepicker': function(){
          _this.datePickerDateShow = false;
        },
        'apply.daterangepicker': function() {
          //parseDisplayDates(ev.model);
          searchRecapSms();
        }
      },
      ranges: {
        'Aujourd\'hui': [moment(), moment()],
        'Hier': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Cette semaine (depuis Lundi)': [angular.copy(defaultValuesDatePicker.startDate), angular.copy(defaultValuesDatePicker.endDate)],
        'Les 7 derniers jours': [moment().subtract(7, 'days'), moment()],
        'Semaine dernière (Lun - Dim)': [moment().subtract(1, 'week').startOf('isoWeek'), moment().subtract(1, 'week').endOf('isoWeek')],
        'Les 14 derniers jours': [moment().subtract(14, 'days'), moment()],
        'Ce mois-ci': [moment().startOf('month'), moment().endOf('month')],
        'Les 30 derniers jours': [moment().subtract(30, 'days'), moment()],
        'Le mois dernier': [moment().subtract(1,'month').startOf('month'), moment().subtract(1,'month').endOf('month')],
        'Toute la période': [moment().startOf('month'), moment().add(1,'month').endOf('month')]
        //'Toute la période': [moment().year(2000).dayOfYear(1).startOf('hour'), moment()]
      },
      opens: 'right'
    };



    _this.$onInit = function() {
      searchRecapSms();
      SocketNotificationService.on('update-recap-sms', onUpdateInfosRecapSms, function(){
        //searchInfosDashboard(baseFiltre);
      });
    };

    _this.$onDestroy = function () {
      SocketNotificationService.removeListener('update-recap-sms');
    };

    /******* Privée *********/

    function onUpdateInfosRecapSms(json){
      if(_.isObject(json) && json.success && _.isArray(json.result) && json.result.length) {
        if(_.isArray(_this.listeRecapSms) && _this.listeRecapSms.length) {

          for(var j = 0; j < json.result.length; j++){
            json.result[j] = RecapSmsService.initRecapSms(json.result[j]);
          }

          for(var i = 0; i < _this.listeRecapSms.length; i++){
            for(var k = 0; k < json.result.length; k++){
              if(_this.listeRecapSms[i].idRecapSms === json.result[k].idRecapSms){
                _this.listeRecapSms[i] = json.result[k];
              }
            }
          }
        }
      }
    }

    function searchRecapSms(){
      _this.loadingListeRecapSms = true;

      var paramsSearch = {};

      if(_this.filtre.dateRecapSms.startDate !== null){
        if(_this.filtre.dateRecapSms.startDate._isAMomentObject) paramsSearch.dateDebut = _this.filtre.dateRecapSms.startDate.format('YYYY-MM-DD');
        else paramsSearch.dateDebut = UtilsService.dateFormat(_this.filtre.dateRecapSms.startDate,'yyyy-MM-dd');
      }
      if(_this.filtre.dateRecapSms.endDate !== null){
        if(_this.filtre.dateRecapSms.endDate._isAMomentObject) paramsSearch.dateFin = _this.filtre.dateRecapSms.endDate.format('YYYY-MM-DD');
        else paramsSearch.dateFin = UtilsService.dateFormat(_this.filtre.dateRecapSms.endDate,'yyyy-MM-dd');
      }

      parseDisplayDates(_this.filtre.dateRecapSms);

      RecapSmsService
        .getListRecapSms(paramsSearch)
        .then(function(sms){
          _this.listeRecapSms = sms;
        })
        .catch(function(er){
          ModalsService.alertErreur(er);
        })
        .finally(function(){
          _this.loadingListeRecapSms = false;
        });
    }

    // Permet de parser les dates qui seront affichés pour les dates pickers
    function parseDisplayDates(obj){
      switch(obj.label) {
        case 'Aujourd\'hui':
          obj.showDates = obj.startDate.format('dddd DD MMMM YYYY').ucfirst();
          break;
        case 'Hier':
          obj.showDates = obj.startDate.format('dddd DD MMMM YYYY').ucfirst();
          break;
        case 'Ce mois-ci':
          obj.showDates = obj.startDate.format('MMMM YYYY').ucfirst();
          break;
        case 'Le mois dernier':
          obj.showDates = obj.startDate.format('MMMM YYYY').ucfirst();
          break;
        case 'Toute la période':
          obj.showDates = false;
          obj.startDate = null;
          obj.endDate = null;
          obj.isDefault = true;
          break;
        default:
          obj.showDates = 'Du ' + obj.startDate.format('DD MMM YYYY') + ' au ' + obj.endDate.format('DD MMM YYYY');
          obj.isDefault = false;
      }
    }


    /********* Public **********/

    function getRowEtat(recapSms){
      var classRow = '';
      if(recapSms.getStatus() === 'FAILED' || recapSms.getStatus() === 'EXPIRED' || recapSms.getStatus() === 'ERROR'){
        classRow = 'danger';
      }
      else if(recapSms.getStatus() === 'DELIVERED'){
        classRow = 'success';
      }
      return classRow;
    }

    function onClickRefresh(){
      searchRecapSms();
    }

    function onClickRenvoyer(recapSms){

    }

    function clearDatesRecapSms(){
      _this.filtre.dateRecapSms.startDate = angular.copy(defaultValuesDatePicker.startDate);
      _this.filtre.dateRecapSms.endDate = angular.copy(defaultValuesDatePicker.endDate);
      _this.filtre.dateRecapSms.label = angular.copy(defaultValuesDatePicker.label);

      _this.filtre.dateRecapSms.showDates = false;
      _this.filtre.dateRecapSms.isDefault = true;
    }
  }
})();
