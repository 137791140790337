(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name collaboreApp.NotificationService
     * @description
     * # NotificationService
     * Service in the collaboreApp.
     */

    angular
        .module('collaboreApp')
        .service('NotificationService', NotificationServiceController);

    /** @ngInject */
    function NotificationServiceController(MainService, Notification, $state, $rootScope, $q, $log, NotificationsAjax, ErreurCollabService, UtilsService, SocketNotificationService) {

        var ctrl = this;

        var listNotificationMessage = [];
        ctrl.setListNotificationMessage = setListNotificationMessage;
        ctrl.getListNotificationMessage = getListNotificationMessage;
        ctrl.addToListNotificationMessage = addToListNotificationMessage;

        ctrl.initSocketNotification = initSocketNotification;
        ctrl.removeFromListNotificationMessageForTopic = removeFromListNotificationMessageForTopic;
        ctrl.removeFromListNotificationMessage = removeFromListNotificationMessage;

        var listNotificationMail = [];
        ctrl.setListNotificationMail = setListNotificationMail;
        ctrl.getListNotificationMail = getListNotificationMail;
        ctrl.addToListNotificationMail = addToListNotificationMail;
        ctrl.cleanListNotificationMail = cleanListNotificationMail;

        ctrl.websocketNotification = websocketNotification;

        ctrl.getNotificationsForIdtopic = getNotificationsForIdtopic;

        function initSocketNotification(){
            SocketNotificationService.on('notification', function(notification){
                websocketNotification(notification);
            });
        }

        function setListNotificationMessage(val) {
            listNotificationMessage = val;
        }
        function getListNotificationMessage() {
            return listNotificationMessage;
        }

        function setListNotificationMail(val) {
          listNotificationMail = val;
        }
        function getListNotificationMail() {
            return listNotificationMail;
        }

        /**
         * Ajoute une notification dans la liste des notifications message
         * @param notif
         */
        function addToListNotificationMessage(notif){
            try {
                if(!angular.isArray(listNotificationMessage)) listNotificationMessage = [];
                if(!notif || !angular.isObject(notif)) throw new Error('Le paramètre "notif" n\'est pas un objet !');
                if(!notif.isModel) {
                    var NotificationModel = MainService.$injector.get('Notification');
                    notif = new NotificationModel(notif);
                    NotificationModel = null;
                }
                var exist = false;
                if(listNotificationMessage.length) {
                    for(var i = 0; i < listNotificationMessage.length; i++){
                        if(listNotificationMessage[i].idContenu === notif.getIdContenu()) {
                            exist = true;
                            break;
                        }
                    }
                }
                if(!exist) listNotificationMessage.push(notif);
                $rootScope.nbNotifications = listNotificationMessage.length;
                $rootScope.$broadcast('refreshNotificationMessage');
                setPageTitle();
            }
            catch(e) {
                $log.error(e.message);
            }
        }

        function addToListNotificationMail(notif){
          try {
              if(!angular.isArray(listNotificationMail)) listNotificationMail = [];
              if(!notif || !angular.isObject(notif)) throw new Error('Le paramètre "notif" n\'est pas un objet !');
              if(!notif.isModel) {
                  var MailboxModel = MainService.$injector.get('Mailbox');
                  notif = new MailboxModel(notif);
                  MailboxModel = null;
              }
              var exist = false;
              if(listNotificationMail.length) {
                  for(var i = 0; i < listNotificationMail.length; i++){
                      if(listNotificationMail[i].idMailbox === notif.getIdMailbox()) {
                          exist = true;
                          break;
                      }
                  }
              }
              if(!exist) listNotificationMail.push(notif);
              $rootScope.nbNotificationsMailbox = listNotificationMail.length;
              $rootScope.$broadcast('refreshNotificationMailbox');
              setPageTitle();
          }
          catch(e) {
              $log.error(e.message);
          }
        }

        function cleanListNotificationMail(){
          listNotificationMail = [];
          $rootScope.nbNotificationsMailbox = 0;
          $rootScope.$broadcast('refreshNotificationMailbox');
          setPageTitle();
        }

        function setPageTitle(){
          var nb = 0;
          if($rootScope.nbNotifications) nb += $rootScope.nbNotifications;
          if($rootScope.nbNotificationsMailbox) nb += $rootScope.nbNotificationsMailbox;
          var title = $rootScope.defaultTitle;
          if(nb > 0){
            title = '(' + nb + ') ' + title;
          }
          /*
          if($rootScope.nbNotifications !== 0 && $rootScope.nbNotifications > 0 && $rootScope.nbNotificationsMailbox !== 0 && $rootScope.nbNotificationsMailbox > 0){
            title = '(' + $rootScope.nbNotifications + ' message(s) - ' + $rootScope.nbNotificationsMailbox + ' emails(s)) ' + title;
          }
          else if($rootScope.nbNotifications !== 0 && $rootScope.nbNotifications > 0){
            title = '(' + $rootScope.nbNotifications + ' message(s)) ' + title;
          }
          else if($rootScope.nbNotificationsMailbox !== 0 && $rootScope.nbNotificationsMailbox > 0){
            title = '(' + $rootScope.nbNotificationsMailbox + ' emails(s)) ' + title;
          }*/
          $rootScope.setTitlePage(title);
        }

        /**
         * Retire de la liste des notifications message qui concerne l'idTopic passé en paramètre
         * @param idTopic
         */
        function removeFromListNotificationMessageForTopic(idTopic){
            removeFromListNotificationMessage(false, idTopic);
        }

        /**
         * Retire une notification de la liste des notification message par notif ou par idTopic
         * @param notif
         * @param idTopic
         */
        function removeFromListNotificationMessage(notif, idTopic){
            try {
                if((!notif || !angular.isObject(notif)) && (!idTopic || !angular.isNumber(idTopic))) throw new Error('Le paramètre "notif" n\'est pas un objet ou "idTopic" n\'est pas un nombre !');
                if(listNotificationMessage.length)  {
                    if(angular.isObject(notif) && !notif.isModel) {
                        var NotificationModel = MainService.$injector.get('Notification');
                        notif = new NotificationModel(notif);
                        NotificationModel = null;
                    }
                    if(listNotificationMessage.length) {
                        for(var i = 0; i < listNotificationMessage.length; i++){
                            if(notif) {
                                if(listNotificationMessage[i].idContenu === notif.getIdContenu()) {
                                    listNotificationMessage.splice(i, 1);
                                    break;
                                }
                            }
                            else if(idTopic) {
                                if(listNotificationMessage[i].idTopic === idTopic) listNotificationMessage.splice(i, 1);
                            }
                        }
                    }
                    $rootScope.nbNotifications = listNotificationMessage.length;
                    $rootScope.$broadcast('refreshNotificationMessage');
                    setPageTitle();
                }
            }
            catch(e) {
                $log.error(e.message);
            }
        }

        /**
         * Permet de gérer les notifications reçu par websocket
         * @param obj
         */
        function websocketNotification(obj){
            if(obj.hasOwnProperty('typeNotification')) {
              var NotificationsToastServices = null;
              var titre = 'Collab: Nouveau message';
              var message = '';
              if(obj.typeNotification === 'nouveauMessage'){
                if(!obj.isModel) {
                  var NotificationModel = MainService.$injector.get('Notification');
                  obj = new NotificationModel(obj);
                  NotificationModel = null;
                }
                ctrl.addToListNotificationMessage(obj);

                titre = 'Collab: Nouveau message';
                if(obj.getTopicInformations() && obj.getTopicInformations().getTitreTopic()) titre = obj.getTopicInformations().getTitreTopic();

                message = UtilsService.stripTags(UtilsService.decodeHTMLEntities(obj.getMessage()));
                if(obj.getContenu()) {
                    message = '';
                    if(obj.getContenu().getExpediteur()) message = obj.getContenu().getExpediteur().getDefaultLibelle() + ': ';
                    //message += UtilsService.truncate(UtilsService.stripTags(obj.getContenu().getMessage()), 130, '...');
                    message += UtilsService.stripTags(UtilsService.decodeHTMLEntities(UtilsService.getPrevisualisationMessage(obj.getContenu())));
                }

                NotificationsToastServices = MainService.$injector.get('NotificationsToastServices');
                NotificationsToastServices.desktop({
                  title: titre,
                  msg: message,
                  icon: 'images/comments.png'},
                  null,
                  function(){
                    $state.go('topics.detail', {topicId: obj.getIdTopic(), '#': 'contenu_' + obj.getIdContenu()}, {location: true, reload: true, inherit: false});
                });
                NotificationsToastServices = null;
              }
              else if(obj.typeNotification === 'nouveauMail' && !_.isNil(obj.mailbox)){

                if(!_.isNil(obj.nbMailboxNonLu)) $rootScope.mailboxInfos.nbNewMail = obj.nbMailboxNonLu;

                var MailboxModel = MainService.$injector.get('Mailbox');
                var mailbox = new MailboxModel(obj.mailbox);
                MailboxModel = null;

                ctrl.addToListNotificationMail(mailbox);

                titre = 'Nouveau mail à traiter';
                if(mailbox.libelleExpediteur) titre += ' de ' + mailbox.libelleExpediteur;
                message = mailbox.sujet;
                NotificationsToastServices = MainService.$injector.get('NotificationsToastServices');
                NotificationsToastServices.desktop({
                  title: titre,
                  msg: mailbox.sujet,
                  icon: 'images/mailbox.png'
                }, null, function(){
                    $state.go('mailbox', {idMailbox: mailbox.getIdMailbox()}, {location: true, reload: true, inherit: false});
                });
                NotificationsToastServices = null;
              }
            }
        }

        function getNotificationsForIdtopic(idTopic, limitNonLu, limitLu) {
            var deferred = $q.defer();
            var objParam = {
                action: 'checkNotifications',
                idTopic: idTopic,
                limitNonLu: limitNonLu,
                limitLu: limitLu
            };

            NotificationsAjax
                .get(objParam, function (json) {
                        deferred.resolve({
                            listNotificationNonLu: MainService.convertJsonToModel(json, 'listNotificationNonLu', Notification),
                            listNotificationLu: MainService.convertJsonToModel(json, 'listNotificationLu', Notification)
                        });
                    },
                    function (error) {
                        ErreurCollabService.erreurServlet(error, 'Erreur avec NotificationService.getNotificationsForIdtopic()', deferred);
                    });


            return deferred.promise;
        }
    }
})();
