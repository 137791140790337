'use strict';
var TooltipAddDestinataireComponent = {
    require: {
        destinatairesCtrl: '?^destinataires'
    },
    templateUrl : 'app/scripts/topics/directives/tooltipAddDestinataire.html',
    bindings:{
        itemContenu: '=',
        itemEvenement: '=',
        to: '=',
        mode: '@',
        eStyle: '@',
        classBtn: '@',
        classIcon: '@',
        disallowUser: '=',
        popupPlacement: '@',
        showLibelle: '@'
    },
    controllerAs: 'tooltipadddestinatairectrl',
    controller: ['$scope','$rootScope', 'COLLAB_CONF', 'UserService', 'UtilsService',
        function($scope,$rootScope,COLLAB_CONF,UserService,UtilsService){
            
        var formDestinataire;
            var ctrl = this;
            
        ctrl.hasPortefeuille = $rootScope.hasPortefeuille;


        ctrl.popoverDestinataire = {
            'isOpen': false,
            'toggle': function toggle(event,formAddDestinataire){

                formDestinataire = formAddDestinataire;
                if(ctrl.popoverDestinataire.isOpen){
                    ctrl.destinatairesCtrl.errorDestinataire = '';
                    formAddDestinataire.$cancel();
                }

                ctrl.popoverDestinataire.isOpen = !ctrl.popoverDestinataire.isOpen;

                if(event){
                    event.stopPropagation();
                }
            }
        };

        var listenerOnClosePopoverDestinataire = $scope.$on('closePopoverDestinataire',function(){
            ctrl.popoverDestinataire.isOpen = false;
        });
        var listenerOnTogglePopoverDestinataire = $scope.$on('togglePopoverDestinataire',function(event,bool,form){
            ctrl.popoverDestinataire.toggle(bool,form);
        });
        var listenerOnErrorDestinataire = $scope.$on('errorDestinataire',function(event,form,msg){
            console.log(form);
            form.$show();
            form.$setError('newDestinataire', msg);

            //ctrl.destinatairesCtrl.error = true;
        });

        ctrl.$onDestroy = function(){
            listenerOnClosePopoverDestinataire();
            listenerOnTogglePopoverDestinataire();
            listenerOnErrorDestinataire();
        };

        if(!ctrl.eStyle){
            ctrl.eStyle = 'width:215px';
        }

        if(!ctrl.classBtn){
            ctrl.classBtn = 'btn-sm';
        }


        if(!ctrl.classIcon){
            ctrl.classIcon = 'fa fa-user-plus fa-lg';
        }

        if(!ctrl.popupPlacement){
            ctrl.popupPlacement = 'top';
        }

            /*
        ctrl.modeInline = false;
        ctrl.modePopup = false;
        if(ctrl.mode==='inline'){
            ctrl.mode = 'inline';
            ctrl.modeInline = true;
        }else{
            ctrl.mode = 'popup';
            ctrl.modePopup = true;
        }*/

        ctrl.checkUser = function(user){
            ctrl.destinatairesCtrl.newDest = UtilsService.checkValidDestinataire(user);
            if (!ctrl.destinatairesCtrl.newDest.success) {
                ctrl.destinatairesCtrl.error = true;
                return ctrl.destinatairesCtrl.newDest.message;
            }

            var tabTmp;
            // Si l'user actuel ne peut pas etre un des destinataire on renvoi une erreur
            if(ctrl.disallowUser){
                var arrayDestinatairesEvenement = ctrl.itemEvenement.getDestinataires();
                tabTmp = UtilsService.where(arrayDestinatairesEvenement, {libelle: ctrl.destinatairesCtrl.newDest.libelle,dieze: ctrl.destinatairesCtrl.newDest.dieze});

                // Si le nouveau destinataire est présent dans la liste des destinataires de l'événement on fait une erreur
                if(tabTmp.length || ctrl.destinatairesCtrl.newDest.libelle+ctrl.destinatairesCtrl.newDest.dieze===UserService.getGroupe().getLibelleDieze()){
                    ctrl.destinatairesCtrl.error = true;
                    return 'Utilisateur invalide !';
                }

            }

            if(ctrl.to==='destinataires') {
                var arrayDestinataires = ctrl.itemContenu.getDestinataires();

                tabTmp = UtilsService.where(arrayDestinataires, {libelle: ctrl.destinatairesCtrl.newDest.libelle,dieze: ctrl.destinatairesCtrl.newDest.dieze});
                if(tabTmp.length){
                    return 'Cet utilisateur est déjà présent !';
                }
            }
        };

        ctrl.displayLoading = function(){
            ctrl.destinatairesCtrl.loadingPopupDestinataire = COLLAB_CONF.MIDDLE_LOADING;
        };
    }]
    
};

angular.module('collaboreApp').component('tooltipAddDestinataire',TooltipAddDestinataireComponent);