(function() {

    'use strict';

    /**
     * @desc Composant AdminCollab
     * @example <admin-collab-mails></admin-collab-mails>
     */
    angular
        .module('collaboreApp')
        .component('adminCollabMails',{
            templateUrl: 'app/admin/mails/admin-collab-mails.component.html',
            controllerAs: 'admincollabmailsctrl',
            controller: AdminCollabMailsCtrl
        });

    /** @ngInject */
    function AdminCollabMailsCtrl($q, ErreurCollabService, AdminCollabService, sweet){
        var ctrl = this;

        ctrl.listStatRecap = [];
        ctrl.onClickOpenAttente = onClickOpenAttente;
        ctrl.onClickOpenErreur = onClickOpenErreur;
        ctrl.onClickOpenErreurEmailTMP = onClickOpenErreurEmailTMP;
        ctrl.onClickResendAll = onClickResendAll;
        ctrl.onClickResend = onClickResend;
        ctrl.getRowEtat = getRowEtat;
        ctrl.moment = moment;

        ctrl.$onInit = function () {
            ctrl.loadingStatsRecapMail = true;
            AdminCollabService
                .getStatsRecapMailParSociete()
                .then(function(retour){
                    ctrl.listStatRecap = retour;
                })
                .finally(function(){
                    ctrl.loadingStatsRecapMail = false;
                });
        };

        function onClickResendAll(stat){

            sweet.show({
                title: 'Confirmation',
                text: 'Voulez-vous vraiment demander le renvoi de <strong>'+stat.nbMailEnAttente+'</strong> mail(s) pour <strong>'+stat.groupe.dieze+'</strong> ?',
                type: 'warning',
                showCancelButton: true,
                //confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Renvoyer !',
                closeOnConfirm: false,
                showLoaderOnConfirm: true,
                html: true
            }, function(isConfirm) {
                if(isConfirm) {

                    AdminCollabService
                        .resendMailForSociete(stat.groupe.idGroupe)
                        .then(function(){
                            sweet.show('Demande de renvoi effectué !');
                            stat.recapMails = [];
                            stat.open = false;
                            stat.nbMailEnAttente = 0;
                        })
                        .catch(function(msg){
                            ErreurCollabService.alert(msg);
                        });
                }
            });
        }


        function onClickResend(stat, recapMail){
            recapMail.loading = true;
            resendMail([recapMail.getIdRecapMail()])
                .then(function(recapMails){
                    // Parcour la liste des recapMail pour les retirer
                    for(var j = 0; j < stat.recapMails.length; j++){

                        if(stat.recapMails[j].idRecapMail === recapMails[0].idRecapMail) {
                            stat.recapMails.splice(j,1);
                            break;
                        }
                    }
                })
                .finally(function(){
                    recapMail.loading = true;
                });
        }

        function resendMail(arrayIdRecapMail){
            var deferred = $q.defer();
            AdminCollabService
                .resendMailForIdRecapMail(arrayIdRecapMail)
                .then(function(recapMails){
                    if(recapMails.length) {
                        deferred.resolve(recapMails);
                    }
                    else {
                        deferred.reject('Pas de resultat');
                    }
                })
                .catch(function(msg){
                    deferred.reject(msg);
                });
            return deferred.promise;
        }

        function onClickOpenAttente(stat){
            stat.recapMails = [];
            stat.openErreur = false;
            stat.openErreurEmailTMP = false;
            if(stat.openAttente) {
                stat.openAttente = false;
                stat.open = false;
            }
            else {
                stat.open = true;
                stat.openAttente = true;
                stat.loading = true;
                AdminCollabService
                    .getListRecapMailForGroupe(stat.groupe.idGroupe, 0)
                    .then(function(retour){
                        stat.recapMails = retour;
                    })
                    .finally(function(){
                        stat.loading = false;
                    });
            }
        }

        function onClickOpenErreur(stat){
            stat.recapMails = [];
            stat.openAttente = false;
            stat.openErreurEmailTMP = false;
            if(stat.openErreur) {
                stat.openErreur = false;
                stat.open = false;
            }
            else {
                stat.open = true;
                stat.openErreur = true;
                stat.loading = true;
                AdminCollabService
                    .getListRecapMailForGroupe(stat.groupe.idGroupe, -1)
                    .then(function(retour){
                        stat.recapMails = retour;
                    })
                    .finally(function(){
                        stat.loading = false;
                    });
            }
        }

        function onClickOpenErreurEmailTMP(stat){
            stat.recapMails = [];
            stat.openErreur = false;
            stat.openAttente = false;
            if(stat.openErreurEmailTMP) {
                stat.openErreurEmailTMP = false;
                stat.open = false;
            }
            else {
                stat.open = true;
                stat.openErreurEmailTMP = true;
                stat.loading = true;
                AdminCollabService
                    .getListRecapMailForGroupe(stat.groupe.idGroupe, 2)
                    .then(function(retour){
                        stat.recapMails = retour;
                    })
                    .finally(function(){
                        stat.loading = false;
                    });
            }
        }

        function getRowEtat(recapMail){
            var classRow = '';
            switch(recapMail.getEtat()) {
                case -1:
                    classRow = 'danger';
                    break;
                case -2:
                    classRow = 'warning';
                    break;
                case 1:
                    classRow = 'success';
                    break;
                default:
                    classRow = '';
            }
            return classRow;
        }


    }
})();