(function() {

  'use strict';

  /**
   * @desc Composant d'upload
   * @example <list-document></list-document>
   */
  angular
    .module('collaboreApp')
    .component('listDocument',{
      templateUrl: 'app/topics/components/list-document/list-document.component.html',
      controllerAs: 'listdocumentctrl',
      bindings: {
        idContenu: '<',
        docs: '<',           // Une liste de docs
        nbDocsInEvent: '<',
        nbDocsInDemande: '<',
        nbDocsInDevis: '<',
        hasFacture: '<',
        showBtnAddToDevis: '<',
        showBtnAddToDemande: '<',
        onEmit: '&'
      },
      controller: ListDocumentCtrl
    });

  /** @ngInject */
  function ListDocumentCtrl($q, $scope, COLLAB_CONF, Document, DocumentManagerService, UtilsService){
    var _this = this;

    _this.btnSelectionnerToutActif = false;
    _this.onClickSelectionnerTout = onClickSelectionnerTout;
    _this.onClickDoc = onClickDoc;
    _this.onClickAddToEvent = onClickAddToEvent;
    _this.onClickAddToDevis = onClickAddToDevis;
    _this.onClickAddToDemande = onClickAddToDemande;
    _this.onClickAddToFacture = onClickAddToFacture;
    _this.getNbSelectedDoc = getNbSelectedDoc;

    _this.$onInit = function() {
      init();
    };
    _this.$onChanges = function(changes) {
      if(_.isArray(changes.docs) && !changes.docs.isFirstChange()) _this.docs = changes.docs.currentValue;
    };
    _this.$onDestroy = function() {};

    function init(){
      _this.listDoc = [];
      if(_.isArray(_this.docs) && _this.docs.length) {
        getListDocument().then(function(list){
          //console.log(list);
          _this.listDoc = list;
        });
      }
    }

    function getListDocument(){
      var globalDeferred = $q.defer();

      var tasks = [];
      var list = [];
      if(_.isArray(_this.docs) && _this.docs.length) {
        var docs = angular.copy(_this.docs);
        // Parcour les taches à executer
        angular.forEach(docs, function (doc) {
          if(!doc.isModel) doc = new Document(doc);
          tasks.push(function () {
            var deferred = $q.defer();

            if(UtilsService.extensionIsImage(doc.extension)){
              if(_.isObject(doc) && !_.isNil(doc.guid)) {
                var url = DocumentManagerService.getUrlStream(doc.guid, _this.idContenu, null, true);

                convertToDataURLviaCanvas(url,
                  // Success
                  function (base64Img) {
                    doc.img = base64Img;
                    list.push(doc);
                    deferred.resolve(list);
                  },
                  // Erreur
                  function () {
                    doc.img = COLLAB_CONF.IMG_NO_EXIST;
                    list.push(doc);
                    deferred.resolve(list);
                  },
                  'image/jpeg');

              }
              else deferred.resolve(list);
            }
            else {
              list.push(doc);
              deferred.resolve(list);
            }

            return deferred.promise;
          });
        });
      }


      if(tasks.length) {
        $q.serial(tasks)
          .then(function (list) {
            globalDeferred.resolve(list);
          })
          .catch(function(err){
            globalDeferred.reject(err);
          });
      }
      else globalDeferred.reject("Pas de taches à exécuter");

      return globalDeferred.promise;
    }

    function convertToDataURLviaCanvas(url, callbackOnload, callbackError, outputFormat) {
      var img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        var canvas = document.createElement('CANVAS');
        var ctx = canvas.getContext('2d');
        var dataURL;
        canvas.height = this.height;
        canvas.width = this.width;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callbackOnload(dataURL);
        canvas = null;
      };
      img.onerror = function () {
        if (callbackError) {
          callbackError();
        }
      };
      img.src = url;
    }

    /**
     * Lors du clique sur le bouton pour selectionner tout les documents
     */
    function onClickSelectionnerTout(){
      if(_this.listDoc.length) {
        _this.btnSelectionnerToutActif = !_this.btnSelectionnerToutActif;
        changeSelectedAllDocs(_this.btnSelectionnerToutActif);
      }
    }

    /**
     * Lors du clique sur un document
     * @param doc
     */
    function onClickDoc(doc){
      doc.selected = !doc.selected;
    }

    /**
     * Lors du clique sur le bouton ajouter tout les document à l'événement
     */
    function onClickAddToEvent(){
      var selectedDocs = getSelectedDocs();
      if(selectedDocs.length) {
        emit({
          action: 'addDocumentsToEvent',
          docs: selectedDocs
        });
        _this.btnSelectionnerToutActif = false;
        changeSelectedAllDocs(_this.btnSelectionnerToutActif);
      }
    }

    /**
     * Lors du clique sur le bouton ajouter tout le document en devis de la demande
     */
    function onClickAddToDevis(){
      var selectedDocs = getSelectedDocs();
      if(selectedDocs.length) {
        emit({
          action: 'addDocumentsToDevisDemande',
          docs: selectedDocs
        });
        _this.btnSelectionnerToutActif = false;
        changeSelectedAllDocs(_this.btnSelectionnerToutActif);
      }
    }

    /**
     * Lors du clique sur le bouton ajouter tout les documents à la demande
     */
    function onClickAddToDemande(){
      var selectedDocs = getSelectedDocs();
      if(selectedDocs.length) {
        emit({
          action: 'addDocumentsToOtherDocDemande',
          docs: selectedDocs
        });
        _this.btnSelectionnerToutActif = false;
        changeSelectedAllDocs(_this.btnSelectionnerToutActif);
      }
    }

    /**
     * Lors du clique sur le bouton ajouter tout le document en facture de la demande
     */
    function onClickAddToFacture(){
      var selectedDocs = getSelectedDocs();
      if(selectedDocs.length == 1) {
        emit({
          action: 'addDocumentToFactureDemande',
          doc: selectedDocs[0]
        });
        _this.btnSelectionnerToutActif = false;
        changeSelectedAllDocs(_this.btnSelectionnerToutActif);
      }
    }

    /**
     * Permet de récupérer une liste de document selectionnés
     * @returns {*|A|Array|*[]}
     */
    function getSelectedDocs() {
      return _this.listDoc.reduce(function(accum, doc) {
        if(doc.selected) accum.push(doc);
        return accum;
      }, []);
    }

    /**
     * Recupère le nombre de document selectionnés
     * @returns {*}
     */
    function getNbSelectedDoc() {
      return _this.listDoc.reduce(function(accum, doc) {
        if(doc.selected) accum++;
        return accum;
      }, 0);
    }

    function changeSelectedAllDocs(val){
      if(_this.listDoc.length) {
        _this.listDoc.map(function (doc) {
          doc.selected = val;
          return doc;
        });
      }
    }


    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      _this.onEmit({obj: objRetour});
    }
  }
})();
