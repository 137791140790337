(function() {
    'use strict';

    /**
     * @ngdoc factory
     * @name collaboreApp.State
     * @description
     * # ProxyDestinataireExclu
     */

    angular.module('collaboreApp').factory('ProxyDestinataireExclu', ProxyDestinataireExcluModel);

    /** @ngInject */
    function ProxyDestinataireExcluModel(UtilsService, ProxyDestinataire) {

        function ProxyDestinataireExclu(objetData) {

            // Si c'est un objet
            if (angular.isObject(objetData)) {

                if(objetData.hasOwnProperty('idGroupe')) {
                    this.setIdGroupeParent(objetData.idGroupe);
                }
                if(objetData.hasOwnProperty('groupesUserExclus')) {
                    this.setListeDestinataires(objetData.groupesUserExclus);
                }
            }
        }

        ProxyDestinataireExclu.prototype = {

            isModel: true,
            isProxy: true,

            setData: function(objetData) {
                angular.extend(this, objetData);
            },

            // IdGroupeParent
            getIdGroupeParent: function(){
                return this.idGroupeParent;
            },
            setIdGroupeParent: function(id){
                this.idGroupeParent = id;
            },

            // ListeDestinataires (Idem à "destinataires" mais permet d'envoyer des objet au serveur)
            getListeDestinataires: function(){
                if(!this.hasOwnProperty('listeDestinataires')){
                    this.listeDestinataires = [];
                }
                else if(!angular.isArray(this.listeDestinataires)){
                    this.listeDestinataires = [];
                }
                return this.listeDestinataires;
            },
            setListeDestinataires: function(val){
                var retourArrayListeDestinataire = [];
                if(angular.isArray(val)){
                    for(var i = 0 ; i < val.length ; i++){
                        retourArrayListeDestinataire.push(new ProxyDestinataire(val[i]));
                    }
                }
                this.listeDestinataires = retourArrayListeDestinataire;
            }
        };

        return ProxyDestinataireExclu;
    }
})();