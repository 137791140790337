(function() {
	'use strict';

    angular.module('collaboreApp').factory('Module', Module);

	/*@ngInject*/
	function Module(UtilsService, ModuleType, ModuleRefParam, ModuleValParam){

		var ModuleConstructor = function(data) {
			this.setData(data);
			//ModuleType.apply(this, arguments);
		};

		ModuleConstructor.prototype = {

			isModel: true,
			isModule: true,

			setData: function(data){

				if(!data || !angular.isObject(data)){
					data = {
						libelle: '',
						//'page': null,
						typeModule: {},
						listeParametres: [],
						paramByRefParam: {}
						/*
						'typeModule': new ModuleType(),
						'moduleValParam': new ModuleValParam()
						*/
					};
				}


				angular.extend(this, data);

				if(data.hasOwnProperty('typeModule')){
					this.setTypeModule(data.typeModule);
				}

				if(data.hasOwnProperty('listeParametres')){
					this.setListeParametres(data.listeParametres);
				}

				//this.setParamByRefParam();

			},

			toJson: function(){
				var obj = angular.copy(this);
				if(obj.hasOwnProperty('isInFirstGroupeParent')) {delete obj.isInFirstGroupeParent;}

				obj.listeParametres = [];

				/*

				for(var i = 0 ; i < obj.moduleValParam.length ; i++){
					// Si il y a un moduleRefParam
					if(obj.moduleValParam[i].hasModuleRefParam()){
						// Si c'est un type parameters
						if(obj.moduleValParam[i].getModuleRefParam().isParameters()){
							// Converti la valeur obj en string json pour la valeur
							obj.moduleValParam[i].setValeur(angular.toJson(obj.moduleValParam[i].getValeur()));

							delete obj.moduleValParam[i].moduleRefParam.moduleValParam;
						}
					}
				}*/

				// Reconstruit le tableau "moduleValParam"
				// Parcour les types de paramètres
				angular.forEach(obj.paramByRefParam,function(paramRef){
					var typeParamTmp = angular.copy(paramRef);	// Copie l'objet RefParam
					delete typeParamTmp.listeParametres;			// Supprime l'objet ValParam de l'objet RefParam

					// Parcour les valeurs de chaque type de paramètres
					for(var j = 0; j < paramRef.listeParametres.length; j++){

						var objTmp = angular.copy(paramRef.listeParametres[j]);	// Copie l'objet ValParam
						if(objTmp.hasOwnProperty('editMode')) {delete objTmp.editMode;}
						if(objTmp.hasOwnProperty('nbResteCaracteresForSMS')) {delete objTmp.nbResteCaracteresForSMS;}

						objTmp.referenceParams = typeParamTmp;					// Ajoute l'objet RefParam au ValParam

						// Si c'est un type parameters
						if(typeParamTmp.isParameters()){

							var val = objTmp.getValeur().val;
							if(val !== '' && val){
								// Converti la valeur obj en string json pour la valeur
								objTmp.setValeur(angular.toJson(objTmp.getValeur()));
								obj.listeParametres.push(objTmp);
							}
						}else{
							obj.listeParametres.push(objTmp);
						}

					}
				});

				delete obj.paramByRefParam;

				var json = angular.toJson(obj);
				return json;
			},

			// idModule
			setIdModule: function(id){
				this.idModule = id;
			},
			getIdModule: function(){
				return this.idModule;
			},

			// isInFirstGroupeParent
			setIsInFirstGroupeParent: function(val){
				this.isInFirstGroupeParent = val;
			},
			getIsInFirstGroupeParent: function(){
				return this.isInFirstGroupeParent;
			},

			// Libelle
			setLibelle: function(lib){
				this.libelle = lib;
			},
			getLibelle: function(){
				return this.libelle;
			},

			// groupeModule
			setGroupeModule: function(id){
				this.groupeModule = id;
			},
			getGroupeModule: function(){
				return this.groupeModule;
			},

			// typeModule
			setTypeModule: function(instanceTypeModule){
				if(!instanceTypeModule.isModel){
					instanceTypeModule = new ModuleType(instanceTypeModule);
				}
				this.typeModule = instanceTypeModule;
			},
			getTypeModule: function(){
				return this.typeModule;
			},

			// listeParametres
			setListeParametres: function(arrayInstanceListeParametres){
				var array = [];
				angular.forEach(arrayInstanceListeParametres,function(arrayInstanceParametre){
					if(!arrayInstanceParametre.isModel){
						array.push(new ModuleValParam(arrayInstanceParametre));
					}else{
						array.push(arrayInstanceParametre);
					}
				});
				this.listeParametres = array;
			},
			getListeParametres: function(idRefParam){
				var retour = this.listeParametres;

				if(idRefParam){
					retour = [];
					for(var p = 0; p < this.listeParametres.length; p++){
						if(this.listeParametres[p].referenceParams.idModuleReferenceParams === idRefParam){
							retour.push(this.listeParametres[p]);
						}
					}
				}

				return retour;
			},

			/**
			 * Crée un objet de param groupé par type
			 * @param paramByRefParam
             */
			setParamByRefParam: function(listeParametres){
				var _this = this;

				// Si pas de valeur passé en paramètre
				if(!listeParametres) {
					console.error('[Erreur] Module.setParamByRefParam : Parametre vide !, il doit etre un array d\'instance de ModuleRefParam');
					return;
				}

				var paramByRefParam = {};
				//var typeObjet = this.getTypeModule().getTypeObjet();

				// Parcour les paramètres du ModuleType
				for(var j = 0; j < listeParametres.length; j++){

					var param = angular.copy(listeParametres[j]);

					if(!param.isModel){
						param = new ModuleRefParam(param);
					}

					var idRefParams = param.getIdModuleReferenceParams();

					paramByRefParam[idRefParams] = param;

					// Recupère la liste des paramètres de l'instance actuel
					var arrayListeParams = _this.getListeParametres(idRefParams);

					// Si il y a des paramètres c'est certainement une modification d'un module
					if(arrayListeParams.length){
						// Pour chaque paramètres du module
						for(var i = 0; i < arrayListeParams.length; i++){
							// Si pas de listeParametres dans "paramByRefParam" pour ce refParam
							if(!paramByRefParam[idRefParams].hasOwnProperty('listeParametres')){
								paramByRefParam[idRefParams].listeParametres = [];
							}
							paramByRefParam[idRefParams].listeParametres.push(angular.copy(arrayListeParams[i]));
						}

					// Si pas de paramètres pour ce refParam dans ce module
					}else if(!paramByRefParam[idRefParams].hasOwnProperty('listeParametres')){

            var conf = {valeur:''};
					  if(paramByRefParam[idRefParams].isObject()) {
              conf = {valeur:{}};
            }

							/*
							// Si champ select alors on change la valeur pour mettre null au départ
							if(paramByRefParam[idRefParams].isSelect()) {
                                conf = {'valeur':null};
							}*/
                            var moduleValParam = new ModuleValParam(conf);

							/*
							if(typeObjet === 'template_message'){
								moduleValParam = new ModuleValParam({
									'valeur': {
										'key': '',
										'val': ''
									}
								});
							}*/
							paramByRefParam[idRefParams].listeParametres = [moduleValParam];
						}
				}


				/*
				if(typeObjet === 'template_message' || typeObjet === 'template_sms') {
					angular.forEach(paramByRefParam, function(val, idRef){
						if(val.reference === 'categorie' && val.listeParametres[0].valeur === '') {
							paramByRefParam[idRef].listeParametres[0].valeur = COLLAB_CONF.ARRAY_CATEGORIES_TEMPLATE[0].libelle;
						}
					});
				}*/

				this.paramByRefParam = paramByRefParam;
			},
			getParamByRefParam: function(){
				return this.paramByRefParam;
			},


			isIframe: function(){
				if(this.getTypeModule().getTypeObjet() === 'iframe'){
					return true;
				}
				return false;
			},

            isLienExterne: function(){
                if(this.getTypeModule().getTypeObjet() === 'lien_externe'){
                    return true;
                }
                return false;
            },

			/**
			 * Permet de savoir si le module contient un WYSIWYG
			 * @returns {boolean|ModuleRefParam}
             */
			hasWYSIWYG: function(){
				var refParams,
hasWysiwyg = false;

				if(this.hasOwnProperty('paramByRefParam')){

					refParams = this.getParamByRefParam();
					angular.forEach(refParams,function(refParam){
						// Si il y a un refParam de type "wysiwyg"
						if(refParam.getType() === 'wysiwyg' || refParam.getType() === 'array_wysiwyg' || refParam.getType() === 'array_wysiwyg_and_titre'){
							hasWysiwyg = refParam;
						}
					});
				}else{
					refParams = this.getListeParametres();
					angular.forEach(refParams,function(refParam){
						// Si il y a un refParam de type "wysiwyg"
						if(refParam.getType() === 'wysiwyg' || refParam.getType() === 'array_wysiwyg' || refParam.getType() === 'array_wysiwyg_and_titre'){
							hasWysiwyg = refParam;
						}
					});
				}
				return hasWysiwyg;
			},


			getUrlForIframe: function(){
				var retour = false;

				if(this.isIframe() || this.isLienExterne()){
					var parametres = this.getListeParametres();
					var nParam = 0;
					var urlParams = '';
					if(parametres.length){
						for(var i = 0; i < parametres.length; i++){
							if(parametres[i].getReferenceParams().isUrl()){
								retour = parametres[i].getValeur();
							}
							if(parametres[i].getReferenceParams().isParameters()){
								var params = angular.fromJson(parametres[i].getValeur());

								if(params.key !== '' && params.val !== ''){
									if(nParam === 0){	urlParams += '?';}
									else{			urlParams += '&';}

									urlParams += params.key + '=' + params.val;
									nParam++;
								}
							}
							// Si un paramètre di d'ajouter le token à l'url
                            if(parametres[i].getReferenceParams().getReference() === 'add_token_collab' && parametres[i].getValeur() === 'true'){
                                if(nParam === 0){	urlParams += '?';}
                                else{			urlParams += '&';}
                                urlParams += 'token=' + UtilsService.getCurrentUser().getToken();
                            }
						}
					}

                    if(nParam === 0)	urlParams += '?';
                    else				urlParams += '&';
                    urlParams += 'emailUser=' + UtilsService.getCurrentUser().getEmail();

					retour += urlParams;
				}
				return retour;
			}
		};

		ModuleConstructor.newModuleType = function(data){
			return new ModuleType(data);
		};

		return ModuleConstructor;
	}


})();
