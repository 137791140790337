(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .factory('ElasticSearchResource', ElasticSearchResource);

  /** @ngInject */
  function ElasticSearchResource(COLLAB_CONF, COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ELASTICSEARCH, null, {
      post: {
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ELASTICSEARCH,
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      },
      searchTopic: {
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_ELASTICSEARCH,
        params: {
          action : 'searchGlobal',
          categorie: 'topic',
          search: '@search',
          limit: '@limit'
        }
      }
    });
  }
})();
