(function() {
	'use strict';

	/**
	 * @desc Tags
	 * @example <tag></tag>
	 */

	angular
		.module('collaboreApp')
		.component('tag', {
			templateUrl: 'app/tags/tag.component.html',
			bindings: {
				initTags: '<',				// Array<Object> Liste des tags lors de la création du composant
				initTag: '<',				// <Object> Tag lors de la création du composant
				//contenu: '<',				// <Object> Contenu
				idContenu: '<',				// <Integer> id du Contenu
				mode: '@',					// <String> Permet d'afficher un bouton qui ouvre soit l'annuaire soit la popover des tags (annuaire OU popover)
				createEvent: '<',			// Est-ce appelé lors de la création d'un event ?

				libelle: '@',				// <String> Libelle affiché devant le/les tags
				shortLibelle: '@',			// <String> Libelle en version courte
				hideLibelle: '<',			// <Boolean> Affiche ou cache le libelle
				useShortLibelle: '<',		// <Boolean> Utilise ou non le short libelle
				tagHideLibelle: '=',		// <Boolean> Permet de cacher le libelle pour avoir un tag plus petit
				typeTag: '@',				// <String> Type de tag
				limit: '@',					// <String> Nombre de tags limit

				allowAddTag: '<',			// <Boolean> Affiche/Cache le bouton pour ajouter des tags
				allowDeleteTag: '<',		// <Boolean> Affiche/Cache le bouton delete des tags

				currentModelEvenement: '<',	// Objet du model actuellement selectionné

				icon: '@',					// <String> Class de l'icon
				hideIcon: '<',				// <Boolean> Permet d'afficher ou cacher l'icon
				popoverTitle: '@',			// <String> Title du popover
				popoverPlacement: '@',		// <String> Placement du popover

				onUpdate: '&'
			},
			controllerAs: 'tagctrl',
			controller: TagController
		});

	/*@ngInject*/
	function TagController($scope, TagService) {

		var ctrl = this;

		ctrl.fctAllowAddTag = fctAllowAddTag;
		ctrl.fctAllowDeleteTag = fctAllowDeleteTag;
		ctrl.hasLimit = hasLimit;
		ctrl.hasTypeTag = hasTypeTag;
		ctrl.update = update;

		/*
		 // Si "allowAddTag" n'est pas défini on le met à true de base
		 if(angular.isUndefined(ctrl.allowAddTag)){
		 ctrl.allowAddTag = true;
		 }


		 // Si "allowDeleteTag" n'est pas défini on le met à true de base
		 if(angular.isUndefined(ctrl.allowDeleteTag)){
		 ctrl.allowDeleteTag = true;
		 }*/

		ctrl.$onInit = function () {

			if (_.isArray(ctrl.initTags)) {
				$scope.$watchCollection('tagctrl.initTags', function (newVal) {
					if (_.isArray(newVal)) {
						if (ctrl.typeTag) ctrl.tags = TagService.getTagsForType(newVal, ctrl.typeTag);
						else ctrl.tags = newVal;
					}
					else ctrl.tags = [];
					ctrl.isReady = true;
				});
			} else {

				$scope.$watchCollection('tagctrl.initTag', function (tag) {
					if (_.isObject(tag)) ctrl.tags = [tag];
					else ctrl.tags = [];
					ctrl.isReady = true;
				});
			}
		};

		function hasTypeTag() {
			return (ctrl.typeTag !== '' && angular.isDefined(ctrl.typeTag));
		}

		function hasLimit() {
			return (ctrl.limit !== 0 && angular.isDefined(ctrl.limit));
		}

		function fctAllowAddTag() {
			return (ctrl.allowAddTag || angular.isUndefined(ctrl.allowAddTag));
		}

		function fctAllowDeleteTag() {
			return (ctrl.allowDeleteTag || angular.isUndefined(ctrl.allowDeleteTag));
		}


		function update(obj) {
			if (ctrl.onUpdate) {
				obj.typeTag = ctrl.typeTag;
				obj.limit = ctrl.limit;
				ctrl.onUpdate({
					'objUpdate': obj
				});
			}
		}
	}
})();



