(function () {
  'use strict';

  /**
   * @desc Permet de rechercher et choisir un ou plusieur immeubles
   * @example <old-choose-immeuble></old-choose-immeuble>
   */

  angular
    .module('collaboreApp')
    .component('oldChooseImmeuble', {
      templateUrl: 'app/components/old-choose-immeuble/old-choose-immeuble.component.html',
      bindings: {
        modeSelect: '<',    // Componsant afficher sous forme d'un select
        multiple: '<',      // Permer de choisir plusieur immeuble
        portefeuille: '<',  // Portefeuille ou idPortefeuille
        onEmit: '&',

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<'
      },
      controllerAs: 'oldchooseimmeublectrl',
      controller: OldChooseImmeubleController
    });

  /*@ngInject*/
  function OldChooseImmeubleController(GroupeService, UtilsService) {

    var _this = this;
    _this.loadingComponent = false;
    _this.errorComponent = null;

    _this.aboutPortefeuille = {
      loading: false,
      selected: null,
      list: []
    };

    _this.myGroupe = UtilsService.getCurrentUser();
    _this.callImmeubles = callImmeubles;
    _this.onSubmitSearch = onSubmitSearch;
    _this.onClickClearSearch = onClickClearSearch;

    _this.$onInit = function() {

      if(_this.resolve && _this.modalInstance) {
        _this.modeSelect = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'modeSelect'));
        _this.multiple = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'multiple'));
        _this.portefeuille = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'portefeuille'));
      }

      init();
      /*
      if(_this.adminVersion){
        if(!_this.myGroupe.isAdmin()) _this.errorComponent = "Vous ne pouvez pas accéder à cette fonctionnalitée";
        else {
          _this.loadingPortefeuille = true;
          var GroupesAdminService = UtilsService.$injector.get('GroupesAdminService');
          GroupesAdminService.getAllPortefeuilles()
            .then(function(portefeuilles){
              _this.listPortefeuille = portefeuilles;
              init();
            })
            .finally(function(){
              _this.loadingPortefeuille = false;
            });
        }
      }
      else {
        _this.listPortefeuille = _this.myGroupe.getListePortefeuille();
        init();
      }*/
    };

    _this.$onChanges = function(changes) {
    };

    _this.$onDestroy = function(){
    };

    function init(){
      if(_.isNil(_this.portefeuille))         _this.aboutPortefeuille.list = _this.myGroupe.getListePortefeuille();
      else if(_.isNumber(_this.portefeuille)) _this.aboutPortefeuille.selected = _this.myGroupe.getPortefeuille(_this.portefeuille);
      else if(_.isArray(_this.portefeuille))  _this.aboutPortefeuille.list = _this.portefeuille;
      else if(_.isObject(_this.portefeuille)) _this.aboutPortefeuille.selected = _this.portefeuille;

      if(_.isNil(_this.aboutPortefeuille.selected)){
        if(!_this.aboutPortefeuille.list.length) _this.errorComponent = "Aucun portefeuille !";
        else if(_this.aboutPortefeuille.list.length === 1) _this.aboutPortefeuille.selected = _this.aboutPortefeuille.list[0];
      }

      initResultatsRecherche();
      initParamsRecherche();
    }

    function initResultatsRecherche(){
      _this.resultatsRecherche = {
        loading: false,
        nbTotal: 0, // Nombre total de résultat sans prendre en compte loa pagination
        nb: 0,      // Nombre de résultat
        list: [],
        selected: null
      };
    }

    function initParamsRecherche(){
      _this.paramsRecherche = {
        filtreActif: false,
        search: null,
        currentPage: 1,
        offset: 0,
        limit: 10,
        order: "numero",
        sens: "asc",
        withGroupeAssociation: false,
        onlyWithGroupeAssociation: false,
        idPortefeuille: _this.aboutPortefeuille.selected.idPortefeuille,
        typeGroupe: "immeuble"
      };
    }

    function callImmeubles(search){
      if(_this.aboutPortefeuille.selected) {
        _this.resultatsRecherche.loading = true;
        _this.paramsRecherche.search = search;
        _this.resultatsRecherche.list = [];
        GroupeService
          .searchListGroupeForTypeByGroupePortefeuille(_this.paramsRecherche)
          .then(function(listImmeubleByPortefeuille){
            if(listImmeubleByPortefeuille.length &&
              _.isObject(listImmeubleByPortefeuille[0]) &&
              listImmeubleByPortefeuille[0].isGroupe) {
              var groupePortefeuille = listImmeubleByPortefeuille[0];

              _this.resultatsRecherche.nb = groupePortefeuille.getGroupeInformations().getNbGroupeFils();
              _this.resultatsRecherche.nbTotal = groupePortefeuille.getGroupeInformations().getNbGroupeFilsTotal();

              if(_.isArray(groupePortefeuille.getGroupesFils()) && groupePortefeuille.getGroupesFils().length)
                _this.resultatsRecherche.list = groupePortefeuille.getGroupesFils();
              //else _this.resultatsRecherche.list = [];
            }
          })
          .finally(function(){
            _this.resultatsRecherche.loading = false;
          });
      }
    }

    function onSubmitSearch(formRechercheImmeuble){
      console.log(formRechercheImmeuble);
      if(formRechercheImmeuble.$valid){
        callImmeubles();
      }


    }

    function onClickClearSearch(){
      _this.paramsRecherche.search = null;
      _this.paramsRecherche.filtreActif = false;
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      if(_this.modalInstance) _this.close({$value: objRetour});
      else _this.onEmit({obj: objRetour});
    }

    /**
     * Si ouvert en mode Modal
     */
    function onClickClose(){
      if(_this.modalInstance) _this.dismiss({$value: 'cancel'});
      else emit({action: 'closeChooseImmeuble'});
    }
  }
})();
