(function () {
    'use strict';

    angular
        .module('collaboreApp')
        .component('expertSinistre', {
            templateUrl: 'app/topics/evenement/sinistre/expert-sinistre.component.html',
            controller: ExpertSinistreController,
            controllerAs: 'expertsinistrectrl',
            bindings: {
                persoIcs: '<',
                persoIcsTmp: '<',
                infosEvenement: '<',
                disableButtons: '<',
                enableSubmit: '<',
                onUpdate: '&'
            }
        });

    /** @ngInject */
    function ExpertSinistreController($scope, Renseignement, PersoIcs, ModalsService) {
        var ctrl = this;

        ctrl.loading = false;

        ctrl.onClickNouvelExpert = onClickNouvelExpert;
        ctrl.onClickSearchExpert = onClickSearchExpert;
        ctrl.onClickEditCustomExpert = onClickEditCustomExpert;
        ctrl.onClickCancelEditCustomExpert = onClickCancelEditCustomExpert;
        ctrl.onClickAddLigneExpert = onClickAddLigneExpert;
        ctrl.onClickDeleteLigneExpert = onClickDeleteLigneExpert;
        ctrl.onClickSaveExpert = onClickSaveExpert;

        var listernerOnUpdateExpert = $scope.$on('updateExpert', function(){
            emitUpdate();
        });

        /*
        var listenerOnGetTmp = $scope.$on('getTmp', function() {
            emitUpdate(true);
        });*/


        /********** Global **********/
        ctrl.$onInit = function () {

            $scope.$watch('expertsinistrectrl.persoIcs', function(newVal) {
                initExpert(newVal);
            });

            $scope.$watch('expertsinistrectrl.persoIcsTmp', function(newVal) {
                if(newVal) {
                    ctrl.expertTmp = newVal;
                }
            });
        };

        ctrl.$onDestroy = function(){
            emitUpdate(true);
            //ctrl.expert = null;
            //ctrl.expertTmp = null;
            if(listernerOnUpdateExpert) listernerOnUpdateExpert();
            //if(listenerOnGetTmp) listenerOnGetTmp();
        };

        /**
         * Traite les donnée pour les renvoyer au composant qui appel celui si
         */
        function emitUpdate(withTmp){

            // Si le bouton submit n'est pas activé ou force est à true alors update direct
            //if(!ctrl.enableSubmit || force) {
            //if(ctrl.expert.persoICS || ctrl.expertTmp.enable) {
            var persoIcs = ctrl.expert.persoICS;

            // Si pas de persoICS ou nouvel perso
            //if(!persoIcs || ctrl.expertTmp.new) {
            if(ctrl.expertTmp.new) {
                persoIcs = new PersoIcs();
                persoIcs.setCodemetier('EXP');
                persoIcs.setType('E');
                if(ctrl.infosEvenement && ctrl.infosEvenement.hasOwnProperty('portefeuille') && angular.isObject(ctrl.infosEvenement.portefeuille) && ctrl.infosEvenement.portefeuille.idPortefeuille){
                    persoIcs.portefeuille = ctrl.infosEvenement.portefeuille;
                }
            }

            if(angular.isObject(persoIcs)) {
                // Si custom
                if(!persoIcs.getNumero() && !persoIcs.getNoperso()) {
                    persoIcs.setLibelle(ctrl.expertTmp.nom.getDonnee());
                }

                var informations = [];
                informations.push(ctrl.expertTmp.nom);
                informations.push(ctrl.expertTmp.adresse);
                informations = checkInformations(informations, ctrl.expertTmp.telephones);
                informations = checkInformations(informations, ctrl.expertTmp.portables);
                informations = checkInformations(informations, ctrl.expertTmp.faxs);
                informations = checkInformations(informations, ctrl.expertTmp.mails);

                persoIcs.informations = informations;
                ctrl.expert.persoICS = persoIcs;
            }

            var objUpdate = {
                obj: {
                    expert: ctrl.expert.persoICS
                }
            };
            if(withTmp) objUpdate.obj.tmp = ctrl.expertTmp;
            ctrl.onUpdate(objUpdate);
        }

        function defaultExpert() {
            return {
                persoICS: null,
                detail: {
                    nom: new Renseignement('nom'),
                    adresse: new Renseignement('adresse'),
                    telephones: [],
                    portables: [],
                    faxs: [],
                    mails: []
                }
            };
        }

        /**
         * Initialise l'expert
         * @param persoICS
         */
        function initExpert(persoICS) {
            ctrl.expert = angular.copy(defaultExpert());

            if(persoICS || angular.isUndefined(persoICS)) {
                var expert = false;
                if(persoICS) {
                    expert = angular.copy(persoICS);
                }
                else if(ctrl.persoIcs) {
                    expert = angular.copy(ctrl.persoIcs);
                }

                if(angular.isObject(expert)) {

                    var hasNom = false;
                    if(expert.hasInformations()) {
                        for(var i = 0; i < expert.getInformations().length; i++) {
                            var renseignement = expert.getInformations()[i];
                            if(renseignement.getReference()) {
                                if(renseignement.getReference().isNom()) {
                                    ctrl.expert.detail.nom = renseignement;
                                    hasNom = true;
                                }
                                if(renseignement.getReference().isAdresse()) {
                                    ctrl.expert.detail.adresse = renseignement;
                                }
                                if(renseignement.getReference().isTelephone()) {
                                    ctrl.expert.detail.telephones.push(renseignement);
                                }
                                if(renseignement.getReference().isPortable()) {
                                    ctrl.expert.detail.portables.push(renseignement);
                                }
                                if(renseignement.getReference().isFax()) {
                                    ctrl.expert.detail.faxs.push(renseignement);
                                }
                                if(renseignement.getReference().isEmail()) {
                                    ctrl.expert.detail.mails.push(renseignement);
                                }
                            }
                        }
                    }

                    if(!hasNom) {
                        ctrl.expert.detail.nom.setDonnee(expert.getLibelle());
                    }

                    ctrl.expert.persoICS = expert;
                }
            }
            initTmpExpert(ctrl.expert);
        }

        function initTmpExpert(expert) {
            if(!expert) {
                expert = angular.copy(defaultExpert());
            }
            ctrl.expertTmp = angular.copy(expert.detail);
            ctrl.expertTmp.edit = false;
            ctrl.expertTmp.new = false;
            ctrl.expertTmp.custom = false;
            if(angular.isObject(expert.persoICS) && !expert.persoICS.getNumero() && !expert.persoICS.getNoperso()) {
                ctrl.expertTmp.custom = true;
            }
        }

        function checkInformations(informations, array){
            if(array.length) {
                for(var i = 0; i < array.length; i++) {
                    informations.push(array[i]);
                }
            }
            return informations;
        }

        /**
         * Annule l'expert
         */
        /*
         function onClickAnnulerExpert() {
         if(ctrl.expert.persoICS) {
         var options = {
         btnConfirmText: 'Confirmer !',
         type: 'warning'
         };
         ModalsService
         .confirm('Expert', 'Voulez-vous vraiment effacer l\'expert ?', options)
         .then(function (modal) {
         modal.close();
         initExpert(false);
         emitUpdate();
         });
         }
         else {
         initExpert(false);
         }
         }*/

        /**
         * Lors du clique sur le bouton pour editer la perso
         */
        function onClickEditCustomExpert() {
            ctrl.expertTmp.edit = true;
        }

        /**
         * Lors du clique sur le bouton pour annuler l'édition de la perso
         */
        function onClickCancelEditCustomExpert() {
            initExpert();
        }

        /**
         * Lors du clique sur le bouton pour ajouter un nouvel expert
         */
        function onClickNouvelExpert() {
            initTmpExpert();
            ctrl.expertTmp.edit = true;
            ctrl.expertTmp.new = true;
        }

        /**
         * Lors du clique sur le bouton de supression d'une ligne de renseignement
         * @param array
         * @param index
         */
        function onClickDeleteLigneExpert(array, index) {
            if(ctrl.expertTmp.hasOwnProperty(array) && angular.isArray(ctrl.expertTmp[array]) && ctrl.expertTmp[array].length) {
                ctrl.expertTmp[array].splice(index, 1);
            }
        }

        /**
         * Lors du clique sur le bouton d'ajout d'une ligne de renseignement
         * @param type
         */
        function onClickAddLigneExpert(type) {
            if(type === 'telephone') { ctrl.expertTmp.telephones.push(new Renseignement('telephone'));}
            else if(type === 'portable') { ctrl.expertTmp.portables.push(new Renseignement('portable'));}
            else if(type === 'fax') { ctrl.expertTmp.faxs.push(new Renseignement('fax'));}
            else if(type === 'email') { ctrl.expertTmp.mails.push(new Renseignement('email'));}
        }

        /**
         * Ouvre l'annuaire pour rechercher un expert
         */
        function onClickSearchExpert() {

            var params = {
                stringTo: 'tag',
                searchWhenOpen: false,
                limitDestinataire: 1,
                defaultMetier: 'EXP',
                onlyFournisseur: true
            };

            if(ctrl.infosEvenement) params.infosEvent = ctrl.infosEvenement;
            if(params.hasOwnProperty('infosEvent')) delete params.infosEvent.displayContratsLinks;

            var options = {
                size: 'full',
                windowClass: 'modal-collabore modal-collabore-full',
                backdrop: 'static'
            };

            ModalsService
                .modalComponent('modalAnnuaire', params, options)
                .then(function(obj) {
                    if(obj.type === 'ICS') {
                        if(obj.hasOwnProperty('fournisseur') && angular.isObject(obj.fournisseur)) {
                            var persoIcs = new PersoIcs();

                            persoIcs.setPersoIcs(obj.fournisseur);
                            persoIcs.setType('E');
                            if(obj.hasOwnProperty('portefeuille')){
                                persoIcs.setPortefeuille(obj.portefeuille);
                            }
                            /*
                             persoIcs.setCodemetier(obj.fournisseur.metier);

                             persoIcs.setNumero(obj.fournisseur.numero);
                             persoIcs.setLibelle(obj.fournisseur.getNom());
                             persoIcs.setNoperso(obj.fournisseur.noperso);


                             var renseignements = [];

                             if(obj.fournisseur.getNom()) {
                             renseignements.push(
                             new Renseignement({
                             donnee: obj.fournisseur.getNom(),
                             reference: 'nom'
                             })
                             );
                             }

                             if(obj.fournisseur.getAdresse()) {
                             renseignements.push(
                             new Renseignement({
                             donnee: obj.fournisseur.getAdresse(true),
                             reference: 'adresse'
                             })
                             );
                             }

                             if(obj.fournisseur.getTelephones().length) {
                             var tels = obj.fournisseur.getTelephones();
                             for(var t = 0; t < tels.length; t++) {
                             renseignements.push(
                             new Renseignement({
                             donnee: tels[t],
                             reference: 'telephone'
                             })
                             );
                             }
                             }

                             if(obj.fournisseur.getNofax()) {
                             renseignements.push(
                             new Renseignement({
                             donnee: obj.fournisseur.getNofax(),
                             reference: 'fax'
                             })
                             );
                             }

                             if(obj.fournisseur.getTelephonesFixe().length) {
                             var fixes = obj.fournisseur.getTelephonesFixe();
                             for(var t1 = 0; t1 < fixes.length; t1++) {
                             renseignements.push(
                             new Renseignement({
                             donnee: fixes[t1],
                             reference: 'telephone'
                             })
                             );
                             }
                             }

                             if(obj.fournisseur.getTelephonesMobile().length) {
                             var portables = obj.fournisseur.getTelephonesMobile();
                             for(var p = 0; p < portables.length; p++) {
                             renseignements.push(
                             new Renseignement({
                             donnee: portables[p],
                             reference: 'portable'
                             })
                             );
                             }
                             }

                             if(obj.fournisseur.getMails().length) {
                             var mails = obj.fournisseur.getMails();
                             for(var m = 0; m < mails.length; m++) {
                             renseignements.push(
                             new Renseignement({
                             donnee: mails[m],
                             reference: 'email'
                             })
                             );
                             }
                             }

                             if(renseignements.length) {
                             persoIcs.setInformations(renseignements);
                             }*/
                            initExpert(persoIcs);
                            emitUpdate();
                        }
                    }
                });
        }

        /**
         * Lors du clique sur le bouton pour sauvegarder l'expert du sinistre
         */
        function onClickSaveExpert() {
            emitUpdate();
        }
    }
})();

