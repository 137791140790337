(function() {
	'use strict';

	/**
	 * @desc Block de groupe communication
	 * @example <popover-list-immeuble></popover-list-immeuble>
	 */

	angular
		.module('collaboreApp')
		.component('popoverListImmeuble',{
			templateUrl: 'app/popovers/list-immeuble/popover-list-immeuble.component.html',
			controllerAs: 'popoverlistimmeublecomponentctrl',
			bindings: {
				nomPerso: '<',
				noPerso: '<',
				idPortefeuille: '<',
				listImmeuble: '<',
				disabledBtn: '<',
				onUpdate: '&'
			},
			controller: PopoverListImmeubleController
		});

	/*@ngInject*/
	function PopoverListImmeubleController(AnnuaireService) {

		var ctrl = this;
		ctrl.isOpen = false;
		ctrl.onClick = onClick;						// Lors du clique sur le bouton pour afficher la liste
		ctrl.onClickRefresh = onClickRefresh;		// Lors du clique sur le bouton pour rafraichir la liste

		ctrl.$onInit = function() {
			if(angular.isUndefined(ctrl.listImmeuble) || !angular.isArray(ctrl.listImmeuble)) {
				ctrl.listImmeuble = [];
			}
		};

		function callServlet(){
			if(ctrl.noPerso && ctrl.idPortefeuille) {
				ctrl.loading = true;


				ctrl.onUpdate({objUpdate: {disabledBtnChoisir: true}});
				AnnuaireService
					.searchImmeubles(ctrl.noPerso, ctrl.idPortefeuille)
					.then(function(obj){
						ctrl.listImmeuble = obj.immeubles;
						ctrl.onUpdate({objUpdate: {refreshList: obj}});
					})
					.finally(function(){
						ctrl.loading = false;
						ctrl.onUpdate({objUpdate: {disabledBtnChoisir: false}});
					});
			}

		}

		/**
		 * Lors du clique sur le bouton pour afficher la liste
		 */
		function onClick(){

			ctrl.isOpen = !ctrl.isOpen;

			if(ctrl.isOpen && !ctrl.listImmeuble.length) {
				callServlet();
			}
		}

		/**
		 * Lors du clique sur le bouton pour rafraichir la liste
		 */
		function onClickRefresh(){
			ctrl.listImmeuble = [];
			callServlet();
		}

	}

})();
