(function() {
  'use strict';

  angular.module('collaboreApp').controller('RerchercheCtrl', RerchercheCtrl);

  /** @ngInject */
  function RerchercheCtrl(COLLAB_CONF,$rootScope,$state,$stateParams,RechercheService){
    var vm = this;

    // Paramètres envoyés par le formulaire
    var paramRechercheGenerale = $stateParams.paramRechercheGenerale;

    vm.resultatsRecherche = false;

    // Si pas de paramètres envoyé par le formulaire (bouton retour du navigateur);
    if(!paramRechercheGenerale.hasOwnProperty('search')){
      // Si il y a des paramètres de recherche dans le rootscope
      if($rootScope.current.rechercheGenerale.paramRechercheGenerale.hasOwnProperty('search')){
        paramRechercheGenerale = $rootScope.current.rechercheGenerale.paramRechercheGenerale;
      }

      // Si il y a des resultats stocker dans le rootscope
      if($rootScope.current.rechercheGenerale.resultatsRechercheGenerale!==false){
        vm.resultatsRecherche = $rootScope.current.rechercheGenerale.resultatsRechercheGenerale;
      }
    }

    var newRecherche = $stateParams.newRecherche;
    var searchText = vm.searchText = paramRechercheGenerale.search;

    vm.typeRechercheSelected = 'par référence';
    vm.currentPage = paramRechercheGenerale.currentPage;
    vm.currentLimit = paramRechercheGenerale.currentLimit;

    vm.loadingSearch = {
      'show': false,
      'text': COLLAB_CONF.MIDDLE_LOADING
    };

    vm.goToTopic = goToTopic;
    vm.onClickMoreTopics = onClickMoreTopics;

    function search(page,limit){

      if(_.isNil(searchText) || searchText===''){
        vm.loadingSearch.show = false;
        return;
      }

      vm.loadingSearch.show = true;
      RechercheService.searchCollabByIdTopic(searchText)
        .then(function(resultats){
          //console.log(resultats);

          vm.resultatsRecherche = {
            listeTopicsStates: resultats,
            nbTotalTopics: resultats.length
          };

          $rootScope.current.rechercheGenerale.resultatsRechercheGenerale = vm.resultatsRecherche;

          vm.loadingSearch.show = false;
        })
        .catch(function(){

          if(page===1) {
            vm.resultatsRecherche = false;
          }

        })
        .finally(function(){
          vm.loadingSearch.show = false;
        });
    }

    /**
     * Lors du clique sur un topic
     * @param topic
     */
    function goToTopic(topic){
      $state.go('topics.detail',{'topicId':topic.idTopic},{'location':true,'reload':false,'inherit':false});
    }

    /**
     * Lors du clique sur le bouton "plus de topics"
     */
    function onClickMoreTopics(){
      vm.currentPage = vm.currentPage+1;
      $rootScope.current.rechercheGenerale.paramRechercheGenerale.currentPage = vm.currentPage;
      search(vm.currentPage,vm.currentLimit);
    }

    if(newRecherche){
      search(1);
    }
  }

})();
