(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.ComfactService
   * @description
   * # PortefeuilleService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('PortefeuilleService', PortefeuilleService);

  /*@ngInject*/
  function PortefeuilleService(MainService, $q, $injector, PortefeuilleResource, ModalsService) {

    var ctrl = this;

    ctrl.searchListAllPortefeuille = searchListAllPortefeuille;

    function postServlet(action, obj, attributJson, model) {
      var deferred = $q.defer();
      try {
        if (!action) throw new Error('Le paramètre "action" n\'existe pas !');
        if (!model) throw new Error('Le paramètre "model" n\'existe pas !');
        if (!attributJson) throw new Error('Le paramètre "attributJson" n\'existe pas !');

        if (!_.isObject(obj) && !angular.isNumber(obj)) throw new Error('Le paramètre "obj" doit être un objet un un nombre !');

        var sendParams = angular.merge({}, obj);
        sendParams.action = action;

        PortefeuilleResource
          .post(sendParams, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, attributJson, $injector.get(model), deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    function getServlet(action, obj, attributJson, model) {
      var deferred = $q.defer();
      try {
        if (!action) throw new Error('Le paramètre "action" n\'existe pas !');
        if (!model) throw new Error('Le paramètre "model" n\'existe pas !');
        if (!attributJson) throw new Error('Le paramètre "attributJson" n\'existe pas !');

        var sendParams = {};
        if (!_.isNil(obj)) sendParams = angular.merge(sendParams, obj);
        sendParams.action = action;

        PortefeuilleResource
          .get(sendParams, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, attributJson, $injector.get(model), deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Permet de récupérer la liste de tous les portefeuilles de ma société
     * @param obj
     */
    function searchListAllPortefeuille() {
      var deferred = $q.defer();
      getServlet('searchListAllPortefeuille', null, 'result', 'Portefeuille')
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }
  }

})();
