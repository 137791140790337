(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.TopicStatesService
   * @description
   * # TopicStatesService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('TopicStatesService',
      TopicStatesServiceController
    );

  /** @ngInject */
  function TopicStatesServiceController(MainService, COLLAB_CONF, UtilsService, TagService, TopicResource, TopicStates, ContenuEvenement, ErreurCollabService, $rootScope, $q, PersoIcs, ModalsService) {
    var ctrl = this;

    //------------ Fonctions utilisées, OK et Vérifiées ------------

    ctrl.newTopicStates = newTopicStates;                    // Instancie un TopicStates
    ctrl.searchListTopicStates = searchListTopicStates;       // NOUVEAU: Récupère la liste des topicStates
    ctrl.getListTopicStates = getListTopicStates;            // Recupère les TopicStates via Ajax
    //ctrl.getLastContenu = getLastContenu;                    // Recupère le dernier contenu d'un Topic
    ctrl.getTopicStateByIdTopic = getTopicStateByIdTopic;   // Recupère un TopicState par son id Topic
    ctrl.getEvenementByTopic = getEvenementByTopic;        // Recupère un Contenu Evenement par le biais de l'idTopic
    ctrl.getParamsSearchTopicByFiche = getParamsSearchTopicByFiche;                 // [OLD] Recupère des params pour la recherche de topic à l'aide de fiche perso
    ctrl.getParamsSearchTopicByFiches = getParamsSearchTopicByFiches;               // [NEW] Recupère des params pour la recherche de topic à l'aide de fiche perso
    ctrl.getTagsTypeAllowForSearchTopic = getTagsTypeAllowForSearchTopic;        // Recupère un param tag venant d'un tag origin
    ctrl.getTagsTypeAllowForSearchTopicCapfun = getTagsTypeAllowForSearchTopicCapfun;        // Recupère un param tag venant d'un tag origin
    ctrl.getParamsSearchTopicByTagOrigin = getParamsSearchTopicByTagOrigin;        // Recupère un param tag venant d'un tag origin

    /**
     * Instancie un TopicStates
     * @param objetData
     * @returns {TopicStates|*}
     */
    function newTopicStates(objetData){
      return new TopicStates(objetData);
    }

    /**
     * Permet de récupérer les TopicStates en Ajax
     * @param params
     * @param deferred
     * @private
     */
    function _loadTopicStates(params,deferred) {

      var objParams = {
        action: 'searchTopics',
        desync: true,
        lightJson: true,
        classe: 0,
        idChannel: null,
        cloturer: 2,            // Seulement les non cloturés
        urgent: 0,
        sinistre: 0,
        nonlu: 0,
        //searchInElasticSearch: UtilsService.getParametrage('searchByElasticSearchEnabled'),
        page: COLLAB_CONF.LIST_TOPIC_PAGE_NUM,
        limit: COLLAB_CONF.LIST_TOPIC_LIMIT_PAR_PAGE
      };

      if(_.isObject(params)){

        if(!_.isNil(params.etat))   objParams.etat = params.etat;
        if(!_.isNil(params.page))   objParams.page = params.page;
        if(!_.isNil(params.limit))  objParams.limit = params.limit;

        if(_.isObject(params.filtre)){

          var filtre = params.filtre;

          objParams.nonlu = (filtre.nonlu) ? 1 : 0;

          if(!_.isNil(filtre.idChannel) && filtre.idChannel != 0)        objParams.idChannel = filtre.idChannel;
          if(!_.isNil(filtre.idPortefeuille))   objParams.idPortefeuille = filtre.idPortefeuille;
          if(!_.isNil(filtre.topicAvecDemande)) objParams.topicAvecDemande = filtre.topicAvecDemande;
          if(!_.isNil(filtre.classe))           objParams.classe = filtre.classe;
          if(!_.isNil(filtre.etat))             objParams.etat = filtre.etat;
          if(!_.isNil(filtre.desync))           objParams.desync = filtre.desync;
          if(!_.isNil(filtre.lightJson))        objParams.lightJson = filtre.lightJson;
          if(!_.isNil(filtre.cloturer))         objParams.cloturer = filtre.cloturer;
          if(!_.isNil(filtre.urgent))           objParams.urgent = filtre.urgent;
          if(!_.isNil(filtre.sinistre))         objParams.sinistre = filtre.sinistre;

          // Si recherche par type tag
          if(_.isObject(filtre.tagType))        objParams.idTagType = filtre.tagType.idTag;

          //Liste de tags
          if(_.isArray(filtre.tags) && filtre.tags.length){
            objParams.tags = filtre.tags.map(function(tag){
              return angular.toJson(TagService.newTag(tag,false,false,false,true));
            });
          }

          //Liste de destinataires
          if(_.isArray(filtre.destinataires) && filtre.destinataires.length){
            var ProxyDestinataire = MainService.$injector.get('ProxyDestinataire');
            objParams.destinataires = filtre.destinataires.map(function(dest){
              return angular.toJson(new ProxyDestinataire(dest));
            });
          }

          //Liste de destinataireEvenement
          if(_.isArray(filtre.listeDestinataireEvenement) && filtre.listeDestinataireEvenement.length){
            var ProxyDestinataire = MainService.$injector.get('ProxyDestinataire');
            objParams.listeProxyDestinataireEvenement = filtre.listeDestinataireEvenement.map(function(dest){
              var proxy = new ProxyDestinataire(dest);
              delete proxy.allowSendEmail;
              return angular.toJson(proxy);
            });
          }

          //Liste de listeFournisseurMail
          if(_.isArray(filtre.listeFournisseurMail) && filtre.listeFournisseurMail.length){
            objParams.listeFournisseurMail = angular.copy(filtre.listeFournisseurMail);
            /*
            var mailsTmp = [];
            for(var e = 0 ; e < filtre.listeFournisseurMail.length ; e++){
              mailsTmp.push(filtre.listeFournisseurMail[e]);
            }
            listeFournisseurMail = mailsTmp;*/
          }

          //Liste de listeFournisseurPersoICS
          if(_.isArray(filtre.listeFournisseurPersoICS) && filtre.listeFournisseurPersoICS.length){
            var ProxyPersoIcs = MainService.$injector.get('ProxyPersoIcs');
            objParams.listeFournisseurPersoICS = filtre.listeFournisseurPersoICS.map(function(fournisseurPerso){
              return angular.toJson(new ProxyPersoIcs(fournisseurPerso));
            });
          }

          if(_.isObject(filtre.dateCreation)) {
            objParams.dateCreationDebut = filtre.dateCreation.startDate;
            objParams.dateCreationFin = filtre.dateCreation.endDate;
          }

          if(!_.isNil(filtre.dateClotureDebut)) objParams.dateClotureDebut = filtre.dateClotureDebut;
          if(!_.isNil(filtre.dateClotureFin))   objParams.dateClotureFin = filtre.dateClotureFin;
          if(!_.isNil(filtre.stillOpenAt))      objParams.stillOpenAt = filtre.stillOpenAt;
        }
      }

      /*
      // Liste des filtre où il faut désactiver la recherche elastic si il sont activés
      var arrayDisallowSearchElastic = [
        'urgent',
        'sinistre',
        'idPortefeuille',
        'topicAvecDemande',
        'idTagType',
        'tags',
        'destinataires',
        'listeFournisseurMail',
        'listeFournisseurPersoICS',
        'dateCreationDebut',
        'dateCreationFin',
        'dateClotureDebut',
        'dateClotureFin',
        'stillOpenAt',
      ];
      // Désactive la recherche rapide dans le cas où elle est activée et qu'on utlise un filtre pas encore pris en charge
      if(objParams.searchInElasticSearch){
        for(var i = 0; i < arrayDisallowSearchElastic.length; i++){
          var currentParam = objParams[arrayDisallowSearchElastic[i]];
          if(!_.isNil(currentParam) && currentParam != 0){
            objParams.searchInElasticSearch = false;
            break;
          }
        }
      }*/

      TopicResource
        .list(objParams)
        .$promise
        .then(function onSuccess(json){
          var objRetour = {
            total: (!_.isNil(json.total) && json.total !== 0) ? parseInt(json.total) : 0,
            listTopicStates: [],
            resultElasticSearch: (!_.isNil(json.resultElasticSearch)) ? json.resultElasticSearch : false,
            asynchrone: (!_.isNil(json.asynchrone)) ? json.asynchrone : false,
          };

          $rootScope.current.resultElasticSearch = objRetour.resultElasticSearch;

          if(json.success) {
            var retourTopicStates = [];

            // Instancie chaque topicStates du JSON
            for(var i = 0 ; i < json.TopicStates.length ; i++){
              var ts = newTopicStates(json.TopicStates[i]);
              // Si recherche via elastic et qu'on demande la desynchronisation de certaines data alors on met un pending sur le topicState
              if(objRetour.resultElasticSearch && objRetour.asynchrone) ts.pending = true;
              retourTopicStates.push(ts);
            }
            objRetour.listTopicStates = retourTopicStates;

            var InfosDashboard = MainService.$injector.get('InfosDashboard');
            objRetour.infosDashboard = new InfosDashboard(json.infosDashboard);
          }
          else{
            if(!_.isNil(json.message)) objRetour.erreur = json.message;
            else objRetour.erreur = json.message;
          }

          deferred.resolve(objRetour);
        })
        .catch(function onError(error){
          ErreurCollabService.erreurServlet(error,'Erreur avec TopicStatesService::_loadAllTopicStates()',deferred);
        });
    }

    /**
     * Recupère les TopicStates
     * @param params
     * @returns {IPromise<T>}
     */
    function getListTopicStates(params) {
      var deferred = $q.defer();
      _loadTopicStates(params,deferred);
      return deferred.promise;
    }

    /**
     * Recupère un TopicState par le biais de l'idTopic
     * @param idTopic
     * @param deferred
     */
    function getTopicStateByIdTopic(idTopic) {
      var deferred = $q.defer();

      TopicResource
        .get({topic: idTopic})
        .$promise
        .then(function onSuccess(topicObj){
          // Si topic OK
          if(topicObj.success) deferred.resolve(newTopicStates(topicObj.TopicStates[0]));
          else deferred.reject('Pas de topicState trouvé');
        })
        .catch(function onError(error){
          var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
          deferred.reject('Problème avec TopicStatesService._load(), '+msgErreur);
          $rootScope.$broadcast('dislayError',msgErreur);
        });
      return deferred.promise;
    }

    /**
     * Permet de récupérer un événement si il existe grace à un id de topic
     * @param topicState
     * @returns {a.fn.promise|*|promise.promise}
     */
    function getEvenementByTopic(topic){

      var deferred = $q.defer();

      var idTopic;
      if(angular.isObject(topic)){
        if(topic.isModel)
          idTopic = topic.getIdTopic();
        else
          idTopic = topic.idTopic;
      }
      else if(angular.isNumber(topic))
        idTopic = topic;

      TopicResource
        .getEvenement({topic: idTopic})
        .$promise
        .then(function onSuccess(topicObj) {
          if(topicObj.nb==='1')
            deferred.resolve(new ContenuEvenement(topicObj.Contenus[0]));
          else
            deferred.reject('Pas d\'événement trouvé');
        })
        .catch(function onError(error) {
          var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
          deferred.reject('Problème avec TopicStatesService.getEvenementByTopic(), '+msgErreur);
          //$rootScope.$broadcast('dislayError',msgErreur);
        });
      return deferred.promise;
    }

    /**
     * Permet de paramétrer le filtre de recherche de topics par le biais des fiches
     * @param objetsTags
     * @returns {boolean}
     */
    function getParamsSearchTopicByFiche(objetsTags){
      var retourParams = {
        success: false,
        listeFournisseurMail: [],
        listeFournisseurPersoICS: []
      };
      // Si il y a des fiches dans le retour
      if(objetsTags.addTag.hasOwnProperty('fiches') && angular.isObject(objetsTags.addTag.fiches)){

        var emails = [];
        var objCollaborateur = false;
        var isFournisseur = false;
        var persoICS = false;

        // Si il y a un fournisseur
        if(objetsTags.addTag.fiches.hasOwnProperty('fournisseur') && objetsTags.addTag.fiches.fournisseur.isFournisseur) {
          objCollaborateur = objetsTags.addTag.fiches.fournisseur;
        }
        // Si il y a un groupe
        else if(objetsTags.addTag.fiches.hasOwnProperty('groupe') && objetsTags.addTag.fiches.groupe.isModel && objetsTags.addTag.fiches.groupe.model === 'Groupe') {
          persoICS = objetsTags.addTag.fiches.groupe.getLastPersoICS();
        }

        if(_.isObject(objCollaborateur)) {

          if((_.isNil(objCollaborateur.portefeuille) || !objCollaborateur.portefeuille) && _.isArray(objetsTags.addTag.tagsType) && objetsTags.addTag.tagsType.length) {
            for(var t = 0; t < objetsTags.addTag.tagsType.length; t++){
              if(_.isObject(objetsTags.addTag.tagsType[t]) && objetsTags.addTag.tagsType[t].isModel && objetsTags.addTag.tagsType[t].isTagPortefeuille()){
                objCollaborateur.portefeuille = objetsTags.addTag.tagsType[t].getPortefeuille();
                break;
              }
            }
          }

          var perso = objCollaborateur;
          if(objCollaborateur.hasOwnProperty('perso')) {perso = objCollaborateur.perso;}

          if(perso.isFournisseur || perso.isPersoFournisseur) {
            isFournisseur = true;
          }

          // Si le fournisseur a des emails
          if(objCollaborateur.hasOwnProperty('arrayMails') && _.isArray(objCollaborateur.arrayMails) && objCollaborateur.arrayMails.length){
            // Parcours les mails pour voir si il n'y en a pas des mauvais
            for(var m = 0; m < objCollaborateur.arrayMails.length; m++){
              // Garde que les bon mail
              if(UtilsService.isMail(objCollaborateur.arrayMails[m])){
                emails.push(objCollaborateur.arrayMails[m]);
              }
            }
          }
          else if(perso.hasOwnProperty('mails') && _.isArray(perso.mails) && perso.mails.length) {
            perso.mails.map(function(mail) {
              // Garde que les bon mail
              if(UtilsService.isMail(mail)){
                emails.push(mail);
              }
            });
          }

          // Si pas d'email il faut en générer un
          if(!emails.length){emails.push(UtilsService.genereEmailJetable(objCollaborateur));}

          if(!persoICS) {
            //console.log(emails);
            persoICS = new PersoIcs();
            if(UtilsService.propertyValidForObject(objCollaborateur, 'nom')){
              persoICS.setLibelle(objCollaborateur.nom);
            }
            else if(UtilsService.propertyValidForObject(objCollaborateur, 'nomper')){
              persoICS.setLibelle(objCollaborateur.nomper);
            }

            if(UtilsService.propertyValidForObject(perso, 'noperso')){
              persoICS.setNoperso(perso.noperso);
            }
            if(UtilsService.propertyValidForObject(objCollaborateur, 'portefeuille')){
              persoICS.setPortefeuille(objCollaborateur.portefeuille);
            }
          }


          //console.log(persoICS);

          if(isFournisseur) {
            if(UtilsService.propertyValidForObject(perso, 'metier')){
              persoICS.setCodemetier(perso.metier);
            }
            if(UtilsService.propertyValidForObject(perso, 'numero')){
              persoICS.setNumero(perso.numero);
            }
            persoICS.setType('F');
            retourParams.listeFournisseurMail = emails;
            retourParams.success = true;
          }
        }

        if(persoICS) {
          retourParams.listeFournisseurPersoICS = [persoICS];
          retourParams.success = true;
        }
      }

      return retourParams;
    }

    /**
     * Permet de paramétrer le filtre de recherche de topics par le biais des fiches
     * @param objetsTags
     * @returns {boolean}
     */
    function getParamsSearchTopicByFiches(objetsTags){
      var retourParams = {
        success: false,
        listeFournisseurMail: [],
        listeFournisseurPersoICS: []
      };
      // Si il y a des fiches dans le retour
      if(objetsTags.addTag.hasOwnProperty('fiches') && angular.isObject(objetsTags.addTag.fiches)){

        var emails = [];
        var perso = false;
        var isFournisseur = false;
        var persoICS = false;

        // Si il y a un fournisseur
        if(objetsTags.addTag.fiches.hasOwnProperty('fournisseur') && objetsTags.addTag.fiches.fournisseur.isFournisseur) {
          perso = objetsTags.addTag.fiches.fournisseur;
        }
        // Si il y a un groupe
        else if(objetsTags.addTag.fiches.hasOwnProperty('groupe') && objetsTags.addTag.fiches.groupe.isModel && objetsTags.addTag.fiches.groupe.model === 'Groupe') {
          persoICS = objetsTags.addTag.fiches.groupe.getLastPersoICS();
        }

        if(_.isObject(perso) && perso.isModel) {

          if(perso.isFournisseur()) {
            isFournisseur = true;
          }

          // Si le fournisseur a des emails
          if(perso.hasOwnProperty('arrayMails') && _.isArray(perso.arrayMails) && perso.arrayMails.length){
            // Parcours les mails pour voir si il n'y en a pas des mauvais
            for(var m = 0; m < perso.arrayMails.length; m++){
              // Garde que les bon mail
              if(UtilsService.isMail(perso.arrayMails[m])){
                emails.push(perso.arrayMails[m]);
              }
            }
          }
          else if(perso.hasOwnProperty('mails') && _.isArray(perso.mails) && perso.mails.length) {
            perso.mails.map(function(mail) {
              // Garde que les bon mail
              if(UtilsService.isMail(mail)){
                emails.push(mail);
              }
            });
          }

          // Si pas d'email il faut en générer un
          if(!emails.length){emails.push(UtilsService.genereEmailJetable(perso));}

          if(!persoICS) {
            //console.log(emails);
            persoICS = new PersoIcs();
            if(!_.isNil(perso.getNom())){
              persoICS.setLibelle(perso.getNom());
            }
            if(!_.isNil(perso.getNoperso())){
              persoICS.setNoperso(perso.getNoperso());
            }
            if(UtilsService.propertyValidForObject(perso, 'portefeuille')){
              persoICS.setPortefeuille(perso.portefeuille);
            }
          }


          //console.log(persoICS);

          if(isFournisseur) {
            if(!_.isNil(perso.getMetier())){
              persoICS.setCodemetier(perso.getMetier());
            }
            if(!_.isNil(perso.getNumero())){
              persoICS.setNumero(perso.getNumero());
            }
            persoICS.setType('F');
            retourParams.listeFournisseurMail = emails;
            retourParams.success = true;
          }
        }

        if(persoICS) {
          retourParams.listeFournisseurPersoICS = [persoICS];
          retourParams.success = true;
        }
      }

      return retourParams;
    }

    /**
     * Recupère une liste de tag type par rapport à une liste de tag autorisés
     * @param objetsTags
     * @param tagsTypeAllowForSearch
     * @returns {Array}
     */
    function getTagsTypeAllowForSearchTopic(objetsTags, tagsTypeAllowForSearch){
      if(!tagsTypeAllowForSearch){
        tagsTypeAllowForSearch = [
          COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE,
          COLLAB_CONF.TAG_TYPE_PROPRIETAIRE,
          COLLAB_CONF.TAG_TYPE_IMMEUBLE,
          COLLAB_CONF.TAG_TYPE_LOCATAIRE,
          COLLAB_CONF.TAG_TYPE_LOT,
          COLLAB_CONF.TAG_TYPE_MANDAT
        ];
      }

      var listeTagsSearch = [];
      // Cherche dans les tagType
      if(angular.isArray(objetsTags.addTag.tagsType)){
        for(var t = 0 ; t < objetsTags.addTag.tagsType.length ; t++){
          var tag = objetsTags.addTag.tagsType[t];
          if(tag.hasOwnProperty('typeTag')){
            if(UtilsService.contains(tagsTypeAllowForSearch,tag.typeTag.libelle)){
              listeTagsSearch.push(angular.copy(tag));
            }
          }
        }
      }
      return listeTagsSearch;
    }

    /**
     * Recupère une liste de tag type par rapport à une liste de tag autorisés
     * @param objetsTags
     * @param tagsTypeAllowForSearch
     * @returns {Array}
     */
    function getTagsTypeAllowForSearchTopicCapfun(objetsTags, tagsTypeAllowForSearch){
      if(!tagsTypeAllowForSearch){
        tagsTypeAllowForSearch = [
          COLLAB_CONF.TAG_TYPE_LOT_MULTICAMP,
          COLLAB_CONF.TAG_TYPE_LIEU_COMMUN_CAPFUN,
        ];
      }

      var listeTagsSearch = [];
      // Cherche dans les tagType
      if(angular.isArray(objetsTags.addTag.tagsType)){
        for(var t = 0 ; t < objetsTags.addTag.tagsType.length ; t++){
          var tag = objetsTags.addTag.tagsType[t];
          if(tag.hasOwnProperty('typeTag')){
            if(UtilsService.contains(tagsTypeAllowForSearch,tag.typeTag.libelle)){
              listeTagsSearch.push(angular.copy(tag));
            }
          }
        }
      }
      return listeTagsSearch;
    }

    /**
     * Recupère un tag origin
     * @param objetsTags
     * @returns {boolean}
     */
    function getParamsSearchTopicByTagOrigin(objetsTags, tagsTypeAllowForSearch){
      if(!tagsTypeAllowForSearch){
        tagsTypeAllowForSearch = COLLAB_CONF.ARRAY_TAGS_ORIGINE;
      }
      var retour = false;
      // Cherche dans le "tag" qui est normalement un tag origine si ICS le demande ou si pas encore de résultat dans les tag type
      if(objetsTags.addTag.hasOwnProperty('tag') && angular.isObject(objetsTags.addTag.tag)){
        if(UtilsService.contains(tagsTypeAllowForSearch, objetsTags.addTag.tag.typeTag.libelle)){
          retour = angular.copy(objetsTags.addTag.tag);
        }
      }
      return retour;
    }

    /**
     * Liste des topicStates
     * @returns {angular.IPromise<T>}
     */
    function searchListTopicStates(objParams){
      var deferred = $q.defer();
      try {
        var params = {
          action:'searchListTopicStates'
        };
        params = _.merge(params,objParams);
        TopicResource
          .post(params)
          .$promise
          .then(function onSuccess(json){
            json.TopicStates = MainService.convertJsonToModel(json, 'TopicStates', 'TopicStates');
            deferred.resolve(json);
          })
          .catch(function onError(err) {
            deferred.reject(err);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    //------------ Fin Fonctions utilisées, OK et Vérifiées ------------

  }

})();
