'use strict';

var tooltipAddTags = function () {
	return {
		restrict: 'E',
		templateUrl : 'app/scripts/documents/directives/tooltipAddTags.html',
		scope: {
			document: '='
		},
		controller: ['$scope', function($scope) {

			$scope.openForm = function(){
				$scope.document.addTagVisible = !$scope.document.addTagVisible;
			};

		}]
	};
};

angular
	.module('collaboreApp')
	.directive('tooltipAddTags',[
		tooltipAddTags
	]);