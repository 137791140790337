(function () {
  "use strict";

  /**
   * @ngdoc service
   * @name collaboreApp.ComfactService
   * @description
   * # ComfactService
   * Service in the collaboreApp.
   */

  angular.module("collaboreApp").service("ComfactService", ComfactService);

  /*@ngInject*/
  function ComfactService(
    MainService,
    $q,
    $injector,
    ComfactAjax,
    ModalsService
  ) {
    var ctrl = this;

    ctrl.searchComfactConfigs = searchComfactConfigs;
    ctrl.searchComfactUsers = searchComfactUsers;
    ctrl.sendToComfact = sendToComfact;
    ctrl.sendDocumentToComfact = sendDocumentToComfact;

    ctrl.initObjetImmeuble = initObjetImmeuble;
    ctrl.initObjetFournisseur = initObjetFournisseur;
    ctrl.initObjetContrat = initObjetContrat;

    function postServlet(action, obj, attributJson, model) {
      var deferred = $q.defer();
      try {
        if (!action) throw new Error('Le paramètre "action" n\'existe pas !');
        if (!model) throw new Error('Le paramètre "model" n\'existe pas !');
        if (!attributJson)
          throw new Error('Le paramètre "attributJson" n\'existe pas !');

        if (!angular.isObject(obj) && !angular.isNumber(obj))
          throw new Error(
            'Le paramètre "obj" doit être un objet un un nombre !'
          );

        var sendParams = angular.merge({}, obj);
        sendParams.action = action;

        ComfactAjax.post(
          sendParams,
          function (json) {
            deferred.resolve(
              MainService.convertJsonToModel(
                json,
                attributJson,
                $injector.get(model),
                deferred
              )
            );
          },
          function (msg) {
            deferred.reject(msg);
          }
        );
      } catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    function getServlet(action, obj, attributJson, model) {
      var deferred = $q.defer();
      try {
        if (!action) throw new Error('Le paramètre "action" n\'existe pas !');
        if (!model) throw new Error('Le paramètre "model" n\'existe pas !');
        if (!attributJson)
          throw new Error('Le paramètre "attributJson" n\'existe pas !');

        if (!angular.isObject(obj) && !angular.isNumber(obj))
          throw new Error(
            'Le paramètre "obj" doit être un objet un un nombre !'
          );

        var sendParams = angular.merge({}, obj);
        sendParams.action = action;

        ComfactAjax.get(
          sendParams,
          function (json) {
            deferred.resolve(
              MainService.convertJsonToModel(
                json,
                attributJson,
                $injector.get(model),
                deferred
              )
            );
          },
          function (msg) {
            deferred.reject(msg);
          }
        );
      } catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Permet de récupérer la liste des configs comfact
     * @param obj
     */
    function searchComfactConfigs(obj) {
      var deferred = $q.defer();
      getServlet(
        "searchComfactConfigs",
        obj,
        "listComfactConfig",
        "ComfactConfig"
      )
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de récupérer la liste des user comfact
     * @param obj
     * @param onlyComptable
     */
    function searchComfactUsers(obj, onlyComptable) {
      var deferred = $q.defer();
      getServlet("searchComfactUsers", obj, "listComfactUser", "ComfactUser")
        .then(function (ret) {
          var retour = [];
          if (_.isArray(ret) && ret.length) {
            for (var i = 0; i < ret.length; i++) {
              if (ret[i].getPermission() !== 0) {
                if (onlyComptable) {
                  if (ret[i].getInitiales() !== "") retour.push(ret[i]);
                } else retour.push(ret[i]);
              }
            }
          }
          deferred.resolve(retour);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    function sendToComfact(data) {
      var deferred = $q.defer();
      var proprieteRetour = false;
      var modelRetour = false;
      if (_.isObject(data)) {
        if (data.hasOwnProperty("idDevis")) {
          proprieteRetour = "devis";
          modelRetour = "ContenuEvenementDevis";
        } else if (data.hasOwnProperty("idFacture")) {
          proprieteRetour = "facture";
          modelRetour = "ContenuEvenementFacture";
        }
      }
      postServlet("sendToComfact", data, proprieteRetour, modelRetour)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    function sendDocumentToComfact(data) {
      var deferred = $q.defer();

      var sendParams = angular.merge({}, data);
      sendParams.action = "sendDocumentToComfact";
      ComfactAjax.post(
        sendParams,
        function (json) {
          if (json.success) deferred.resolve();
          else deferred.reject(json.message);
        },
        function (msg) {
          deferred.reject(msg);
        }
      );
      return deferred.promise;
    }

    /**
     * Permet d'initialiser un objet standard pour la liste
     * @param obj
     * @returns {{display: function(): string, value: null}}
     */
    function initObjetImmeuble(obj) {
      var imm = {
        isObjetImmeuble: true,
        value: null,
        display: function () {
          return "Inconnu";
        },
      };
      if (_.isObject(obj)) {
        // Groupe
        if (obj.isGroupe) {
          imm = {
            isObjetImmeuble: true,
            group: "ics",
            numero: obj.getNumImmeuble(),
            nom: obj.getDefaultNom(),
            value: obj.getNumImmeuble(),
            adresse: obj.getAdresse(),
            display: function () {
              return obj.getNumImmeuble() + " - " + obj.getDefaultNom();
            },
          };
        }
        // Tag
        else if (obj.isTag) {
          imm = {
            isObjetImmeuble: true,
            group: "ics",
            numero: obj.getValeurIcs(),
            nom: obj.getLibelle(),
            value: obj.getValeurIcs(),
            adresse: null,
            display: function () {
              return obj.getValeurIcs() + " - " + obj.getLibelle();
            },
          };
        }
        // ICSCloud
        else if (obj.isImmeuble) {
          imm = {
            isObjetImmeuble: true,
            group: "ics",
            numero: obj.getNumImmeuble(),
            nom: obj.getNom(),
            value: obj.getNumImmeuble(),
            adresse: obj.getAdresse(),
            display: function () {
              return obj.getNumImmeuble() + " - " + obj.getNom();
            },
          };
        }
      }
      return imm;
    }

    /**
     * Permet d'initialiser un objet standard pour la liste
     * @param {*} obj
     * @returns
     */
    function initObjetFournisseur(obj) {
      var fournisseur = {
        isObjetFournisseur: true,
        value: null,
        numImm: null,
        order: 1,
        display: function () {
          return "Inconnu";
        },
      };
      if (_.isObject(obj)) {
        // ICSCloud
        if (obj.isPersonnalite) {
          fournisseur = {
            isObjetFournisseur: true,
            group: "ics",
            order: 2,
            codeMetier: obj.getMetier(),
            numFournisseur: obj.getNumero(),
            compteFournisseur: obj.getCompteFournisseur(),
            value: obj.getCompteFournisseur(),
            numImm: null,
            name: obj.getNomper(),
            adresse: obj.getAdresse(true),
            display: function () {
              return obj.getCompteFournisseur() + " - " + obj.getNom();
            },
          };
        }
        // PersoICS
        else if (obj.isPersoIcs) {
          fournisseur = {
            isObjetFournisseur: true,
            group: "ics",
            order: 2,
            codeMetier: obj.getCodemetier(),
            numFournisseur: obj.getNumero(),
            compteFournisseur: null,
            value: obj.getCodemetier() + obj.getNumero(),
            numImm: null,
            name: obj.getLibelle(),
            adresse: null,
            display: function () {
              return (
                obj.getCodemetier() + obj.getNumero() + " - " + obj.getLibelle()
              );
            },
          };
        }
        // OcrFrontFeedback
        else if (obj.isOcrFrontFeedback) {
          fournisseur = {
            isObjetFournisseur: true,
            group: "ocr",
            order: 1,
            codeMetier: obj.getCodeMetier(),
            numFournisseur: obj.getNumFournisseur(),
            compteFournisseur: obj.getCompteFournisseur(),
            value: obj.getCompteFournisseur(),
            numImm: null,
            name: obj.getNomFournisseur(),
            adresse: null,
            display: function () {
              return (
                obj.getCompteFournisseur() + " - " + obj.getNomFournisseur()
              );
            },
          };
        }

        if(!_.isEmpty(obj.contrats)) {
          fournisseur.order = 3;
          fournisseur.group = 'contrat';
        }
        if(!_.isNil(obj.iiii)){
          fournisseur.numImm = obj.iiii;
        }
      }
      return fournisseur;
    }
    /**
     * Permet d'initialiser un objet standard pour la liste
     * @param obj
     * @returns {{display: function(): string, value: null}}
     */
    function initObjetContrat(obj) {
      var contrat = {
        isObjetContrat: true,
        value: null,
        numImm: null,
        display: function (avecRefFournisseur) {
          return "Inconnu";
        },
        desc: function () {
          return "Inconnu";
        },
      };
      if (_.isObject(obj)) {
        // ICSCloud
        if (obj.isContrat) {
          contrat = {
            isObjetContrat: true,
            group: "ics",
            codeMetier: obj.getMetierFourni(),
            numFournisseur: obj.getNumeroFourni(),
            refFournisseur: obj.getMetierFourni() + obj.getNumeroFourni(),
            source: obj,
            value: obj.getRefCon(),
            numImm: obj.getIiii(),
            name: obj.getLib1(),
            display: function (avecRefFournisseur) {
              var display = obj.getRefCon() + " - " + obj.getLib1();
              if (avecRefFournisseur)
                display =
                  obj.getMetierFourni() +
                  obj.getNumeroFourni() +
                  " - " +
                  display;
              return display;
            },
            title: function () {
              return obj.getRefCon() + " - " + obj.getLib1();
            },
            desc: function () {
              var desc = obj.getObjet1() + " - " + obj.getOrdre();
              if (!_.isEmpty(obj.getManDat())) desc += " - Mandat: " + obj.getManDat();
              if (!_.isEmpty(obj.getBat())) desc += " - Bat: " + obj.getBat();
              return desc;
            },
          };
        }
        /*
      // OcrFrontFeedback
      else if(obj.isOcrFrontFeedback){
        fournisseur = {
          isObjetFournisseur: true,
          group: 'ocr',
          codeMetier: obj.getCodeMetier(),
          numFournisseur: obj.getNumFournisseur(),
          compteFournisseur: obj.getCompteFournisseur(),
          value: obj.getCompteFournisseur(),
          name: obj.getNomFournisseur(),
          adresse: null,
          display: function(){
            return obj.getCompteFournisseur() + ' - ' + obj.getNomFournisseur();
          }
        };
      }*/
      }
      return contrat;
    }
  }
})();
