(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.Relance
   * @description
   * # Relance
   * Service in the collaboreApp.
   */

  angular.module('collaboreApp').factory('Relance', RelanceModel);

  /** @ngInject */
  function RelanceModel(Main, MainService, RelanceAutoConfig, UtilsService) {

    var Relance = function(objetData) {
      Main.apply(this, arguments);
      this.hydrate(objetData);
    };

    Relance.prototype = {

      model: 'Relance',
      isRelance: true,

      // dateRelance
      getDateRelance: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateRelance, format);
        }
        return this.dateRelance;
      },
      getDateRelanceAgo: function(){
        var retour = this.dateRelance;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },
      setDateRelance: function(date){
        this.dateRelance = date;
      },

      getIsAuto: function(){
        return (_.isNil(this.isAuto)) ? false : this.isAuto;
      },
      setIsAuto: function(val){
        this.isAuto = val;
      },

      isRelanceAuto: function(){
        if(this.getIsAuto()) return true;
        return false;
      }

    };

    angular.extend(Relance.prototype, Main.prototype);

    return Relance;
  }
})();
