(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.Topic
   * @description
   * # Topic
   * Service in the collaboreApp.
   */

  angular.module('collaboreApp').factory('Topic', TopicModel);

  /** @ngInject */
  function TopicModel(COLLAB_CONF, Main, $rootScope, $q, UtilsService, Groupe, TopicResource, ContenuMessage, ContenuEvenement, ContenuEvenementDevis, ContenuEvenementIntervention, ContenuEvenementFacture, Tag, TopicInformations, Document) {
    /*
         var Topic = function(topicData) {
         if (topicData) {
         this.setData(topicData);
         }
         };
         */

    var Topic = function(objet) {
      var attributMinimum = [
        {attribut:   'tags', defaultValue:[]},
        {attribut:   'contenus', defaultValue:[]}
      ];

      Main.call(this, objet, attributMinimum);

      this.isBlocked = this.topicIsBlocked();

      /*
      if (objetData) {
          this.setData(objetData);
      }*/
      //ContenuMessage.apply(this, arguments);
      //ContenuEvenement.apply(this, arguments);
    };

    Topic.prototype = {

      //isModel: true,
      model: 'Topic',
      isTopic: true,

      // setData: function(topicData) {
      //     angular.extend(this, topicData);
      //     //var _this = this;
      //     //--------- Début Contenus -------------
      //     var contenus = [];
      //     //var contenusMessage = [];
      //     //var contenusEvenement = [];
      //     var tags = [];
      //     // Si documents est un array
      //     if(angular.isArray(topicData.contenus)){
      //         // Si cet array est différent de 0
      //         if(topicData.contenus.length){
      //
      //             // Pour chaque contenus
      //             angular.forEach(topicData.contenus,function(contenu){
      //
      //                 var instanceContenu = Topic.initContenu(contenu);
      //
      //                 // Ajoute cette instance à un tableau de contenus
      //                 contenus.push(instanceContenu);
      //
      //                 // Si il y a des tags dans le contenu
      //                 if(instanceContenu.getTags()){
      //                     if(instanceContenu.getTags().length){
      //                         // Pour chaque tags
      //                         angular.forEach(instanceContenu.getTags(), function(tag) {
      //                             tags.push(tag);
      //                         });
      //                     }
      //                 }
      //
      // 			/*
      // 			 // Si il y a des tags type dans le contenu
      // 			 if(instanceContenu.getTagsType()){
      // 			 if(instanceContenu.getTagsType().length){
      // 			 // Pour chaque tags type
      // 			 angular.forEach(instanceContenu.getTagsType(), function(tag) {
      // 			 tags.push(tag);
      // 			 });
      // 			 }
      // 			 }*/
      //             });
      //
      //             //contenusMessage = UtilsService.where(contenus,{typeContenu:1});
      //             //contenusEvenement = UtilsService.where(contenus,{typeContenu:2});
      //         }
      //     }
      //
      // /*
      //  this.setContenusMessage(contenusMessage);
      //  //this.setContenusEvenement(contenusEvenement);
      //
      //  // Si il y a un événement
      //  if(contenusEvenement.length===1){
      //  this.setContenuEvenement(contenusEvenement[0]);
      //  }else{
      //  this.setContenuEvenement(false);
      //  }*/
      //
      //
      //     // Set "contenus" avec l'array d'instance de Contenu
      //     this.setContenus(contenus);
      //
      //     // Si le topic n'a pas d'attribut "tags" ce qui est normal car le topic n'a pas de tags
      //     // Si il exist c'est que c'est un cumul des tags de tout ses contenus
      //     if(!topicData.hasOwnProperty('tags')){
      //         // Si il l'array des tags cumulés créée n'est pas vide
      //         if(tags.length){
      //             this.setTags(UtilsService.unique(tags, 'idTag'));
      //         }else{
      //             this.setTags([]);
      //         }
      //     }
      //
      //
      //
      //     var tagsType = [];
      //     if(topicData.hasOwnProperty('tagsType')){
      //         angular.forEach(topicData.tagsType,function(tagType){
      //             tagsType.push(new Tag(tagType));
      //         });
      //     }
      //     this.setTagsType(tagsType);
      //
      //
      //     var tagsEvenement = [];
      //     if(topicData.hasOwnProperty('tagsEvenement')){
      //         angular.forEach(topicData.tagsEvenement,function(tagEvenement){
      //             tagsEvenement.push(new Tag(tagEvenement));
      //         });
      //     }
      //     this.setTagsEvenement(tagsEvenement);
      //
      //
      //     if(topicData.hasOwnProperty('groupeCreateur')){
      //         this.setGroupeCreateur(topicData.groupeCreateur);
      //     }
      //
      //     if(topicData.hasOwnProperty('dateCreation')){
      //         this.setDateCreation(topicData.dateCreation);
      //     }
      //
      //     if(topicData.hasOwnProperty('historiques')){
      //         this.setHistoriques(topicData.historiques);
      //     }
      //
      //     if(topicData.hasOwnProperty('destinataires')){
      //         this.setDestinataires(topicData.destinataires);
      //     }
      //
      //     if(topicData.hasOwnProperty('topicInformations')){
      //         this.setTopicInformations(topicData.topicInformations);
      //     }
      //
      // },

      // Id Topic
      getIdTopic: function(){
        return this.idTopic;
      },
      setIdTopic: function(id){
        this.idTopic = id;
      },

      // Titre topic
      getTitre: function(){
        return this.titre;
      },
      setTitre: function(titre){
        this.titre = titre;
      },

      // Note
      getNote: function(){
        return this.note;
      },
      setNote: function(val){
        this.note = val;
      },

      // Lu
      getLu: function(){
        return this.lu;
      },
      setLu: function(bool){
        this.lu = bool;
      },

      // Nombre de contenu
      getNbContenu: function(){
        return this.nbContenu;
      },
      setNbContenu: function(nb){
        this.nbContenu = nb;
      },

      // Nombre de contenu non lu
      getNbContenuNonLu: function(){
        return this.nbContenuNonLu;
      },
      setNbContenuNonLu: function(nb){
        this.nbContenuNonLu = nb;
      },

      // Nombre de documents
      getNbDocument: function(){
        return this.nbDocument;
      },
      setNbDocument: function(nb){
        this.nbDocument = nb;
      },

      /*
      // Nom du createur
      getNomCreateur: function(){
        return this.nomCreateur;
      },
      setNomCreateur: function(createur){
        this.nomCreateur = createur;
      },*/

      // Groupe createur
      getGroupeCreateur: function(){
        return this.groupeCreateur;
      },
      setGroupeCreateur: function(createur){
        if(angular.isObject(createur)){

          if(createur.hasOwnProperty('id') && !createur.hasOwnProperty('idGroupe')){
            createur.idGroupe = createur.id;
          }

          if(!createur.isModel){
            createur = new Groupe(createur);
          }
        }
        this.groupeCreateur = createur;
      },

      // Date de création
      getDateCreation: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateCreation, format);
        }
        return this.dateCreation;
      },
      getDateCreationAgo: function(){
        var retour = this.dateCreation;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },
      setDateCreation: function(date){
        this.dateCreation = date;
        this.setDateCreationFormated(date);
      },

      // dateCreationFormated
      getDateCreationFormated: function(){
        return this.dateCreationFormated;
      },
      setDateCreationFormated: function(date){
        this.dateCreationFormated = UtilsService.dateParser(date);
      },

      // dateCloture
      getDateCloture: function(format){
        var date = this.dateCloture;
        if(_.isNil(date) && this.hasEvent()){
          var event = this.getEvenement();
          if(_.isObject(event)) date = event.getDateCloture();
        }
        if(!_.isNil(format)) {
          if(!_.isString(format) && format) format = null;
          return UtilsService.parseDate(date, format);
        }
        return date;
      },
      setDateCloture: function(date){
        this.dateCloture = date;
        this.setEventCloturer(!_.isNil(date));
        var event = this.getEvenement();
        if(this.hasEvent() && _.isObject(event)) {
          event.setDateCloture(date);
        }
      },
      isCloture: function () {
        if(_.isString(this.getDateCloture())) return true;
        var event = this.getEvenement();
        if(this.hasEvent() && _.isObject(event)) return event.isCloture();
        return false;
      },

      /**
       * Peut cloturé si :
       - Event modifiable et pas de date de cloture
       - Event modifiable avec une date de cloture mais topic pas bloqué (Cache "Réouvrir" si topic bloqué)
       - Event pas modifiable et topic pas bloqué  (Cache les boutons pour les fournisseurs si topic bloqué)
       * @returns {boolean}
       */
      canCloture: function(){
        var topicInfos = this.getTopicInformations();
        if(_.isObject(topicInfos) && topicInfos.getAllowCloture()) return true;
        var event = this.getEvenement();
        // Si il y a un événement
        if(this.hasEvent()){
          // Si l'event et modifiable, pas cloturé ou cloturé mais pas bloqué
          if(this.hasEventModifiable() && (!this.isCloture() || !this.isBlocked)) {
            return true;
          }
          // Si l'event n'est pas modifiable et qu'il n'est pas bloqué
          else if(!this.hasEventModifiable() && !this.isBlocked){
            return true;
          }
        }
        // Si pas d'événement et que le créateur du topic est de la même société que moi
        else if(this.getGroupeCreateur().isSameSocieteOfGroupe(UtilsService.getCurrentUser())){

        }
        return false;
      },
      canOpen: function(){

      },

      // Contenus
      getContenus: function(){
        return this.contenus;
      },
      setContenus: function(contenus){

        var arrayContenu = [];
        var tags = [];
        var tagsType = [];
        var tagsEvenement = [];
        if(angular.isArray(contenus) && contenus.length) {
          for(var i = 0; i < contenus.length; i++) {

            var contenu = contenus[i];

            var isTagEvenement = (contenu.typeContenu === 2) ? true : false;

            // Si il y a des tags dans le contenu
            if(contenu.tags && contenu.tags.length) {
              for(var t = 0; t < contenu.tags.length; t++) {
                var tag = contenu.tags[t];

                // Si le tag est un tag type
                if(tag.hasOwnProperty('typeTag') && angular.isObject(tag.typeTag) && tag.typeTag.hasOwnProperty('libelle')) {
                  // Si son type est différent de "Normal"
                  if(tag.typeTag.libelle !== 'Normal') tagsType.push(angular.copy(tag));
                  // Si "Normal" et que le contenu est un événement
                  else if(isTagEvenement) tagsEvenement.push(angular.copy(tag));
                }
                // Si pas un tag type et que le contenu actuel est un événement
                else if(isTagEvenement) tagsEvenement.push(angular.copy(tag));

                tags.push(tag);
              }
            }

            var instanceContenu = Topic.initContenu(contenu);
            arrayContenu.push(instanceContenu);
          }
        }

        if(tags.length) this.setTags(tags);
        if(tagsType.length) this.setTagsType(tagsType);
        if(tagsEvenement.length) this.setTagsEvenement(tagsEvenement);

        this.contenus = arrayContenu;
      },

      // Document du Topic
      getDocument: function() {
        return this.document;
      },
      setDocument: function(doc) {
        var instanceDocument = doc;
        // Si c'est l'objet document on recupèrte le guid
        if (angular.isObject(document) && !doc.hasOwnProperty('isModel')) {
          instanceDocument = new Document(doc);
          doc = instanceDocument;
        }

        this.document = doc;
      },


      /*
      // Liste des destinataires
      getDestinataires: function(){
        return this.destinataires;
      },
      setDestinataires: function(destinataires){
        var arrayDestinataires = [];
        var objDest = {};
        if(_.isArray(destinataires)){
          angular.forEach(destinataires,function(destinataire){
            if(!destinataire.isModel && destinataire.hasOwnProperty('idGroupe')){
              destinataire = new Groupe(destinataire);
            }
            objDest[destinataire.idGroupe] = destinataire;
            arrayDestinataires.push(destinataire);
          });
        }

        if(this.hasOwnProperty('historiques')){
          // Parcour les historique du contenu
          for(var h = 0 ; h < this.historiques.length ; h++){
            var idGroupeModificateur = this.historiques[h].groupeModificateur.getIdGroupe();
            if(objDest.hasOwnProperty(idGroupeModificateur)){
              this.historiques[h].groupeModificateur = objDest[idGroupeModificateur];
            }

          }
        }

        // Parcour les contenus
        for(var c = 0 ; c < this.contenus.length ; c++){
          var contenu = this.contenus[c];

          if(_.isObject(contenu.getExpediteur())) {
              var idGroupeExpediteur = contenu.getExpediteur().getIdGroupe();
              // Si l'idGroupe de l'expediteur est bien dans l'objet de tous les destinataires
              if(objDest.hasOwnProperty(idGroupeExpediteur)){
                // Remplace le l'expediteur par celui de la liste de tous les destinataires
                // car ce groupe à peut etre la surfiche
                contenu.setExpediteur(objDest[idGroupeExpediteur]);
              }
          }

          if(contenu.hasOwnProperty('destinataires')){
            // Parcour les destinataire du contenu
            for(var d = 0 ; d < contenu.destinataires.length ; d++){
              var idGroupeDestinataire = contenu.destinataires[d].getIdGroupe();
              if(objDest.hasOwnProperty(idGroupeDestinataire)){
                contenu.destinataires[d] = objDest[idGroupeDestinataire];
              }
            }
          }

        }
        this.destinataires = arrayDestinataires;
      },
      */

      // TopicInformations
      getTopicInformations: function(){
        return this.topicInformations;
      },
      setTopicInformations: function(infos){
        if(_.isObject(infos)){
          if(!infos.isModel) {
            infos = new TopicInformations(infos);
          }
          this.topicInformations = infos;
        }

      },

      getPortefeuille: function(){
        if(_.isObject(this.getTopicInformations()) && _.isObject(this.getTopicInformations().getPortefeuille())) return this.getTopicInformations().getPortefeuille();
        var event = this.getEvenement();
        if(_.isObject(event)) return event.getPortefeuille();
        return null;
      },
      portefeuilleIsBlocked: function(){
        var portefeuille = this.getPortefeuille();
        if(_.isObject(portefeuille) && portefeuille.getIsBlocked()) return true;
        return false;
      },
      /**
       * Si topic bloqué alors peux plus communiquer etc...
       * @returns {*|boolean}
       */
      topicIsBlocked: function(){
        if(_.isObject(this.getTopicInformations()) && this.getTopicInformations().getTopicIsBlocked()) return true;
        else if(this.portefeuilleIsBlocked()) return true;
        else if(this.hasTagsType()) {
          var isBlocked = false;
          for(var i = 0; i < this.getTagsType().length; i++) {
            if(this.getTagsType()[i].portefeuilleIsBlocked()){
              isBlocked = true;
              break;
            }
          }
          return isBlocked;
        }
        return null;
      },

      canSendToGed: function(){
         return (this.gedV1IsEnabled() || this.gedV2IsEnabled());
      },

      /**
       * Gedv1 est activé pour ce dossier
       * @returns {boolean}
       */
      gedV1IsEnabled: function(){
        var portefeuille = this.getPortefeuille();
        return (_.isObject(portefeuille) && portefeuille.gedV1IsEnabled());
      },

      /**
       * Gedv2 est activé pour ce dossier
       * @returns {boolean}
       */
      gedV2IsEnabled: function(){
        var portefeuille = this.getPortefeuille();
        return (_.isObject(portefeuille) && portefeuille.gedV2IsEnabled());
      },

      // categorieTopic
      getCategorieTopic: function(){
        return this.categorieTopic;
      },
      setCategorieTopic: function(categorieTopic){
        if(_.isObject(categorieTopic)){
          if (!categorieTopic.isModel) {
            var CategorieTopic = Main.$injector.get('CategorieTopic');
            categorieTopic = new CategorieTopic(categorieTopic);
            CategorieTopic = null;
          }
          this.categorieTopic = categorieTopic;
        }
      },

      // Topics
      getTopics: function(){
        return this.topics;
      },
      setTopics: function(arrayTopics){
        var topics = [];
        if(_.isArray(arrayTopics) && arrayTopics.length) {
          for(var i = 0; i < arrayTopics.length; i++) {
            if(_.isObject(arrayTopics[i])) {
              if(!arrayTopics[i].isModel) arrayTopics[i] = new Topic(arrayTopics[i]);
              topics.push(arrayTopics[i]);
            }
          }
        }
        this.topics = topics;
      },

      // TopicsRef
      getTopicsRef: function(){
        return (_.isNil(this.topicsRef)) ? [] : this.topicsRef;
      },
      setTopicsRef: function(array){
        if(_.isArray(array) && array.length){
          for(var i = 0; i < array.length; i++){
            if(!array[i].isModel) array[i] = new Topic(array[i]);
          }
          this.topicsRef = array;
        }
      },

      // Liste de tags (cummul des contenus)
      getTags: function(){
        var tags = [];
        if(angular.isArray(this.tags)){
          tags = this.tags;
        }
        return tags;
      },
      setTags: function(arrayTags){
        var tagsArray = [];
        if(angular.isArray(arrayTags) && arrayTags.length) {
          arrayTags = UtilsService.unique(arrayTags, 'idTag');
          for(var i = 0; i < arrayTags.length; i++) {
            if(angular.isObject(arrayTags[i])) {
              if(!arrayTags[i].isModel) arrayTags[i] = new Tag(arrayTags[i]);
              tagsArray.push(arrayTags[i]);
            }
          }
        }
        this.tags = tagsArray;
        this.dedoublonneTags();
      },
      addTag: function(tag){
        var exist = false;
        for(var i = 0 ; i < this.getTags().length ; i++){
          var currentTag = this.getTags()[i];
          if(tag.hasOwnProperty('idTag') && currentTag.hasOwnProperty('idTag')) {
            if(currentTag.idTag === tag.idTag) exist = true;
          }
          else {
            if(currentTag.libelle === tag.libelle) {
              var libelleTypeTag = currentTag.isTagType();
              if(tag.hasOwnProperty('typeTag') && tag.typeTag.hasOwnProperty('libelle') && libelleTypeTag){
                if(tag.typeTag.libelle === libelleTypeTag) exist = true;
              }
              else exist = true;
            }
          }

          if(exist)  break;
        }
        if(!exist){
          if(!tag.isModel) tag = new Tag(tag);
          this.tags.push(tag);
        }
      },
      /**
       * Permet de supprimer un tag d'un topic
       * @param tag
       * @param typeTag
       * @param parcourContenus (Parcour les contenus aussi ?)
       */
      removeTag: function(tag,typeTag,parcourContenus){

        var isLastTagWithLibelle = true; // Permet de savoir si le tag est le dernier dans tous le topic (contenus etc..)
        // Si il y a des contenus
        if(this.getContenus().length && !parcourContenus){

          // Parcour les contenus du topic
          for(var c = 0 ; c < this.getContenus().length ; c++){
            var tagsContenu = this.getContenus()[c].getTags();

            // Si il y a des tags dans le contenu
            if(tagsContenu.length){

              // Parcour les tags du contenu
              for(var k = 0 ; k < tagsContenu.length ; k++){
                if(typeTag){
                  if(tag.libelle === tagsContenu[k].libelle){
                    if(tagsContenu[k].hasOwnProperty('typeTag')){
                      if(typeTag === tagsContenu[k].typeTag.libelle) {
                        isLastTagWithLibelle = false;
                        break;
                      }
                    }
                  }
                }else{
                  if(tag.libelle === tagsContenu[k].libelle){
                    isLastTagWithLibelle = false;
                    break;
                  }
                }
              }
              if(!isLastTagWithLibelle){
                break;
              }
            }
          }
        }

        if(isLastTagWithLibelle){
          // Parcour les tags du topic
          for(var i = 0 ; i < this.tags.length ; i++){
            if(tag.libelle === this.tags[i].libelle){

              if(this.tags[i].hasOwnProperty('typeTag')){
                if(typeTag === this.tags[i].typeTag.libelle){
                  this.tags.splice(i,1);
                }
              }else{
                this.tags.splice(i,1);
              }
            }
          }


          // Si le topic à des tags on supprime le tag des contenus
          if(this.getContenus().length && parcourContenus){
            for(var j = 0 ; j < this.getContenus().length ; j++){
              this.getContenus()[j].removeTag(tag,typeTag);
            }
          }
        }
      },

      /**
       * Supprime une liste de tags du topic
       * @param tags
       * @param typeTag
       * @param parcourContenus
       */
      removeTags: function(tags,typeTag,parcourContenus){
        var scope = this;
        for(var i = 0 ; i < tags.length ; i++){
          scope.removeTag(tags[i],typeTag,parcourContenus);
        }
      },

      dedoublonneTags: function(){
        var arrayTags = UtilsService.unique(this.getTags(), 'idTag');
        this.tags = [];
        if(arrayTags.length) {
          for(var i = 0 ; i < arrayTags.length ; i++){
            this.addTag(arrayTags[i]);
          }
        }
      },

      // Liste de tags typé de l'evenement
      getTagsType: function(){
        return this.tagsType;
      },
      setTagsType: function(arrayTags){
        var tagsType = [];
        if(angular.isArray(arrayTags) && arrayTags.length) {
          for(var i = 0; i < arrayTags.length; i++) {
            if(angular.isObject(arrayTags[i])) {
              if(!arrayTags[i].isModel) arrayTags[i] = new Tag(arrayTags[i]);
              tagsType.push(arrayTags[i]);
            }
          }
        }
        this.tagsType = tagsType;
        this.dedoublonneTagsType();
      },
      addTagType: function(tag){
        var exist = false;
        for(var i = 0 ; i < this.getTagsType().length ; i++){
          var currentTag = this.getTagsType()[i];
          if(tag.hasOwnProperty('idTag') && currentTag.hasOwnProperty('idTag')) {
            if(currentTag.idTag === tag.idTag) exist = true;
          }
          else {
            if(currentTag.libelle === tag.libelle) {
              var libelleTypeTag = currentTag.isTagType();
              if(tag.hasOwnProperty('typeTag') && tag.typeTag.hasOwnProperty('libelle') && libelleTypeTag){
                if(tag.typeTag.libelle === libelleTypeTag) exist = true;
              }
              else exist = true;
            }
          }

          if(exist)  break;
        }
        if(!exist){
          if(!tag.isModel) tag = new Tag(tag);
          this.tagsType.push(tag);
        }
      },
      dedoublonneTagsType: function(){
        var arrayTags = UtilsService.unique(this.getTagsType(), 'idTag');
        this.tagsType = [];
        if(arrayTags.length) {
          for(var i = 0 ; i < arrayTags.length ; i++){
            this.addTagType(arrayTags[i]);
          }
        }
      },
      hasTagsType: function(){
        if(_.isArray(this.getTagsType()) && this.getTagsType().length){
          return true;
        }
        return false;
      },

      // Liste de tags (tags de l'eventuel evenement)
      getTagsEvenement: function(){
        var tags = [];
        if(angular.isArray(this.tagsEvenement)){
          tags = this.tagsEvenement;
        }
        return tags;
      },
      setTagsEvenement: function(arrayTagsEvenement){
        var tags = [];
        if(angular.isArray(arrayTagsEvenement) && arrayTagsEvenement.length) {
          for(var i = 0; i < arrayTagsEvenement.length; i++) {
            if(angular.isObject(arrayTagsEvenement[i])) {
              if(!arrayTagsEvenement[i].isModel) arrayTagsEvenement[i] = new Tag(arrayTagsEvenement[i]);
              tags.push(arrayTagsEvenement[i]);
            }
          }
        }
        this.tagsEvenement = tags;
        this.dedoublonneTagsEvenement();
      },
      addTagEvenement: function(tag){
        var exist = false;
        for(var i = 0 ; i < this.getTagsEvenement().length ; i++){
          var currentTag = this.getTagsEvenement()[i];
          if(tag.hasOwnProperty('idTag') && currentTag.hasOwnProperty('idTag')) {
            if(currentTag.idTag === tag.idTag) exist = true;
          }
          else {
            if(currentTag.libelle === tag.libelle) {
              var libelleTypeTag = currentTag.isTagType();
              if(tag.hasOwnProperty('typeTag') && tag.typeTag.hasOwnProperty('libelle') && libelleTypeTag){
                if(tag.typeTag.libelle === libelleTypeTag) exist = true;
              }
              else exist = true;
            }
          }

          if(exist)  break;
        }
        if(!exist){
          if(!tag.isModel) tag = new Tag(tag);
          this.tagsEvenement.push(tag);
        }
      },
      dedoublonneTagsEvenement: function(){
        var arrayTags = UtilsService.unique(this.getTagsEvenement(), 'idTag');
        this.tagsEvenement = [];
        if(arrayTags.length) {
          for(var i = 0 ; i < arrayTags.length ; i++){
            this.addTagEvenement(arrayTags[i]);
          }
        }
      },

      // Liste de contenus message
      getContenusMessage: function(){
        //return this.contenusMessage;
        var retour = [];
        var contenus = this.getContenus();
        for(var i = 0 ; i < contenus.length ; i++){
          if(contenus[i].typeContenu === 1){
            retour.push(contenus[i]);
          }
        }
        return retour;
      },
      setContenusMessage: function(arrayContenus){
        this.contenusMessage = arrayContenus;
      },


      /*
      // Contenu evenement
      getContenuEvenement: function(){
        var indexEvenement = false;
        var contenus = this.getContenus();
        for(var i = 0 ; i < contenus.length ; i++){
          if(contenus[i].typeContenu === 2){
            indexEvenement = i;
            break;
          }
        }
        if(indexEvenement !== false) return contenus[indexEvenement];
        else if(_.isObject(this.evenement) && !_.isNil(this.evenement.idContenu)) return this.evenement;
        return false;
      },
      setContenuEvenement: function(contenu){
        var indexEvenement = false;
        var contenus = this.getContenus();
        for(var i = 0 ; i < contenus.length ; i++){
          if(contenus[i].typeContenu === COLLAB_CONF.TYPE_CONTENU_EVENEMENT){
            indexEvenement = i;
            break;
          }
        }
        if(indexEvenement!==false){
          if(contenu && !contenu.isModel) contenu = new ContenuEvenement(contenu);
          //this.getContenus()[indexEvenement].hydrate(contenu);
          this.getContenus()[indexEvenement] = contenu;
        }
      },*/
      /*

             // Liste de contenus evenement
             getContenusEvenement: function(){
             return this.contenusEvenement;
             },
             setContenusEvenement: function(arrayContenus){
             this.contenusEvenement = arrayContenus;
             },*/

      findContenuEvenement: function(){
        var evenement = null;
        if(_.isArray(this.contenus) && this.contenus.length){
          for(var e = 0; e < this.contenus.length; e++){
            if(this.contenus[e].typeContenu === COLLAB_CONF.TYPE_CONTENU_EVENEMENT){
              evenement = this.contenus[e];
              break;
            }
          }
        }
        return evenement;
      },

      // Evement
      getEvenement: function(){
        var event = this.evenement;
        if(_.isNil(event) || !event) event = this.findContenuEvenement();
        return event;
      },
      setEvenement: function(evenement){
        //var eventContenu = this.findContenuEvenement();
        //if(_.isObject(eventContenu)) _.merge(evenement, eventContenu);
        if(_.isObject(evenement) && !evenement.isModel) evenement = new ContenuEvenement(evenement);
        this.evenement = evenement;
      },

      getDestinataireMenage: function(){
        var event = this.getEvenement();
        if(!_.isNil(event)) return event.getIntervenantDestinataire("menage");
        return false;
      },
      getDestinataireTechnique: function(){
        var event = this.getEvenement();
        if(!_.isNil(event)) return event.getIntervenantDestinataire("technique");
        return false;
      },

      // Cloture
      getEventCloturer: function(){
        return this.eventCloturer;
      },
      setEventCloturer: function(eventCloturer){
        this.eventCloturer = eventCloturer;
      },

      // Present
      getEventPresent: function(){
        return this.eventPresent;
      },
      setEventPresent: function(eventPresent){
        this.eventPresent = eventPresent;
      },

      // Urgent
      getUrgent: function(){
        return this.urgent;
      },
      setUrgent: function(urgent){
        this.urgent = urgent;
      },

      isUrgent: function(){
        return this.getUrgent();
      },

      // Historique avancement
      getHistoriques: function(){
        return this.historiques;
      },
      setHistoriques: function(historiques){
        var array = [];
        if(historiques.length){
          for(var i = 0; i < historiques.length; i++){
            var historique = historiques[i];
            if(!historique.groupeModificateur.isModel){
              historique.groupeModificateur = new Groupe(historique.groupeModificateur);
            }
            array.push(historique);
          }
        }
        this.historiques = array;
      },

      /*
      // hasSinistre
      getHasSinistre: function(){
        return this.hasSinistre;
      },
      setHasSinistre: function(hasSinistre){
        this.hasSinistre = hasSinistre;
      },*/

      /**
       * Est-ce qu'il y a un événement dans ce topic ? par forcément retourné dans l'objet topic
       * @returns {*}
       */
      hasEvent: function(){
        var retour = false;
        retour = this.getEventPresent();
        if(!retour){
          var topicInfos = this.getTopicInformations();
          retour = (_.isObject(topicInfos) && topicInfos.isModel && topicInfos.getHasEvent());
          if(!retour && _.isObject(this.getEvenement())) retour = true;
        }
        return retour;
      },

      hasEventCloture: function(){
        return this.getEventCloturer();
      },

      hasEventModifiable: function(){
        var retour = false;
        var topicInfos = this.getTopicInformations();

        if(_.isObject(topicInfos) && topicInfos.isModel && topicInfos.getEventModifiable()) {
          retour = true;
        }
        else if(_.isObject(this.evenement) && this.evenement.modifiable) {
          retour = true;
        }
        else {
          var event = this.getEvenement();
          if(event && event.hasOwnProperty('modifiable')){
            if(event.modifiable){
              retour = true;
            }
          }
        }

        return retour;
      },

      hasSinistre: function () {
        var event = this.getEvenement();
        if(event)  return event.hasSinistre();
        return false;
      },

      /**
       * Evenement présent dans l'objet du topic
       * @returns {boolean|boolean}
       */
      eventIsPresent: function(){
        return (this.hasEvent() && _.isObject(this.getEvenement()));
      },

      getLastContenuDate: function() {
        if(!_.isNil(this.getTopicInformations()) && !_.isNil(this.getTopicInformations().getLastContenuDate())) {
          return this.getTopicInformations().getLastContenuDate();
        }
        return null;
      },

      hasContenuImportant: function() {
        if(!_.isNil(this.getTopicInformations()) && !_.isNil(this.getTopicInformations().getHasContenuImportant())) {
          return this.getTopicInformations().getHasContenuImportant();
        }
        return null;
      },

      hasLastContenuDate: function() {
        if(!_.isNil(this.getLastContenuDate())) {
          return true;
        }
        return false;
      },

      /**
       * @name addDestinataires
       * @desc Ajoute un destinataire au topic
       * @param {Array} arrayDestinataires - Tableau de destinataires à ajouter
       * @returns {Promesse}
       */
      /*
             addDestinataires: function(arrayDestinataires){

             var deferred = $q.defer();

             // Si il y a bien un idContenu
             if(this.getId()){
             var objParam = {
             topic: this.getId(),
             destinataires: arrayDestinataires
             };

             TopicResource.addDestinataires(objParam,function(retour){
             deferred.resolve(retour);
             },function(){
             deferred.reject('Problème Ajax [Topic.addDestinataires()]');
             });
             }else{
             deferred.reject('Pas de topic [Topic.addDestinataires()]');
             }

             return deferred.promise;
             }*/

      /**
       * Ajoute un destinataire au topic
       * @param {Array} arrayDestinataires - Tableau de destinataires à ajouter
       * @returns {Promesse}
       */
      addDestinataires: function(arrayDestinataires){

        var deferred = $q.defer();
        //var error = {etat:false};

        // Si il y a bien un idTopic
        if(this.getIdTopic()){
          var topicAjax = new TopicResource();
          topicAjax.topic = this.getIdTopic();
          topicAjax.destinataires = arrayDestinataires;
          topicAjax.$addDestinataires(function(json){
            if(json.nb!=='0'){
              deferred.resolve(json.Groupes);
            }else{
              //console.log('Problème retour [Topic.addDestinataires()]');
              deferred.reject('L\'utilisateur n\'existe pas !');
            }
          },function(){
            deferred.reject('Problème Ajax [Topic.addDestinataires()]');
          });
        }else{
          deferred.reject('Pas de topic [Topic.addDestinataires()]');
        }
        return deferred.promise;
      },

      /**
       * Supprime le tag de tous les contenu du topic
       * @param {Object} tag - Tags à supprimer
       * @returns {Promesse}
       */
      deleteTags: function(Tag){
        var scope = this;
        var deferred = $q.defer();
        var error = {etat:false};

        if(scope.getIdTopic()){

          var idTagDel = Tag.getIdTag();
          var topicAjax = new TopicResource();
          topicAjax.topic = scope.getIdTopic();
          topicAjax.tag = idTagDel;

          topicAjax.$deleteTag(function(json){
            if(json.Resultats[0].success){

              // Recupère les tags de l'objet actuel
              var currentTags = scope.getTags();

              // Parcour les tags actuel de l'instance
              angular.forEach(currentTags,function(objTag,indexTag){
                // Si le tag actuellement parcouru
                if(objTag.getIdTag() === idTagDel){
                  // Supprime le tags de l'array
                  currentTags.splice(indexTag,1);
                }
              });
              // Set les nouveau tags de l'objet actuel
              scope.setTags(currentTags);

              // Recupère les contenus du topic
              var currentContenus = scope.getContenus();

              // Parcoure les contenus du topic
              angular.forEach(currentContenus, function(contenu) {

                var tagContenus = contenu.getTags();


                // Si il y a des tags dans le contenu
                if(tagContenus.length){
                  // supprime le tag de l'array des tags du contenu
                  //var tabTags = UtilsService.removeWith(tagContenus,{idTag:idTagDel});
                  var tabTags = UtilsService.removeWith(tagContenus,{idTag:idTagDel});
                  // Set les nouveau tag au contenu
                  // C'est à dire en enlevant le tag supprimé
                  contenu.setTags(tabTags);
                }

              });

              deferred.resolve();
            }else{
              error.etat = true;
              error.msg = 'Tag inexistant [Topic.deleteTags()]';
            }
          },function(){
            error.etat = true;
            error.msg = 'Problème Ajax [Topic.deleteTags()]';
          });
        }else{
          error.etat = true;
          error.msg = 'Pas de Topic [Topic.deleteTags()]';
        }

        if(error.etat){
          deferred.reject(error);
        }
        return deferred.promise;
      },

      /**
       * Lecture du topic
       * @returns {Object} Promesse
       */
      lire: function(){
        var _this = this;
        var deferred = $q.defer();
        var error = {etat:false};

        //console.log(this);
        var idTopic = this.getIdTopic();
        // Si il y a bien un idTopic
        if(idTopic){
          var topicAjax = new TopicResource();
          topicAjax.topic = idTopic;
          topicAjax.$lire(function(retour){
            if(retour.Resultats[0].success){

              // TODO : [Notification] Lir les notifications du topic

              if(_this.state.isNormal() || _this.state.isCoupCoeur()){

                if(_this.state.isNormal()){
                  if($rootScope.nbTopicNormal){
                    $rootScope.nbTopicNormal--;
                  }
                }

                if(_this.state.isCoupCoeur()){
                  if($rootScope.nbTopicCoupCoeur){
                    $rootScope.nbTopicCoupCoeur--;
                  }
                }

                $rootScope.nbTopicNormalEtCoupCoeur = $rootScope.nbTopicNormal+$rootScope.nbTopicCoupCoeur;
              }



              if(_this.state.isArchive() || _this.state.isCache()){
                if(_this.state.isArchive()){
                  if($rootScope.nbTopicArchive){
                    $rootScope.nbTopicArchive--;
                  }
                }

                if(_this.state.isCache()){
                  if($rootScope.nbTopicCache){
                    $rootScope.nbTopicCache--;
                  }
                }
                $rootScope.nbTopicArchiveEtCache = $rootScope.nbTopicCache+$rootScope.nbTopicArchive;
              }

              _this.setLu(true);
              deferred.resolve(error);
            }else{
              error.etat = true;
              error.msg = 'Problème de retour Ajax [Topic.lire()]';
            }

          },function(){
            error.etat = true;
            error.msg = 'Problème Ajax [Topic.lire()]';
          });
        }else{
          error.etat = true;
          error.msg = 'Pas de topic [Topic.lire()]';
        }

        if(error.etat){
          deferred.reject(error);
        }

        return deferred.promise;
      },

      addToNonLu: function(){
        var _this = this;
        var deferred = $q.defer();
        var error = {etat:false};

        var idTopic = this.getIdTopic();
        // Si il y a bien un idTopic
        if(idTopic){

          // Execute la fonction addToNonLu pour le 1er contenu du topic
          this.getContenus()[0].addToNonLu().then(function(retour){
            _this.setLu(false);
            deferred.resolve(retour);
          },function(){
            error.etat = true;
            error.msg = 'Problème Ajax [Topic.addToNonLu()]';
          });

        }else{
          error.etat = true;
          error.msg = 'Pas de topic [Topic.addToNonLu()]';
        }

        if(error.etat){
          deferred.reject(error);
        }

        return deferred.promise;
      },

      getTagsWithType: function(type){
        var retour = [];
        var tagType = this.getTagsType();

        // Si tags est un array
        if(angular.isArray(tagType)){
          // Si cet array est différent de 0
          if(tagType.length){

            // Pour chaque tagsType
            angular.forEach(tagType,function(tag){

              if(tag.hasOwnProperty('typeTag')){
                var TypeTag = tag.typeTag;

                if(angular.isObject(TypeTag)){
                  var lib = TypeTag.libelle;

                  if(lib===type){

                    retour.push(tag);
                  }
                }
              }
            });
          }
        }
        return retour;
      },



      /**
       * Permet d'ajouter un contenu dans un topic
       * @param contenu
       */
      addContenu: function(contenu){

        var topic = this;
        var contenus = topic.getContenus();
        var instanceContenu = Topic.initContenu(contenu);
        var idNewContenu = instanceContenu.idContenu;

        // Recup un tableau avec le contenu si il est trouvé
        var contenuExist = UtilsService.where(contenus,{idContenu:idNewContenu});

        if(!contenuExist.length){
          // Ajoute cette instance à un tableau de contenus
          contenus.push(instanceContenu);

          // Update le nombre de contenu
          topic.setNbContenu(contenus.length);

          // Si il y a des tags dans le contenu
          if(instanceContenu.getTags()){
            if(instanceContenu.getTags().length){

              // REcupère les tags du contenu
              var tagsContenu = instanceContenu.getTags();

              // REcupère les tags du topics
              var tagsTopic = this.getTags();

              // Pour chaque tags
              angular.forEach(tagsContenu, function(tag) {
                tagsTopic.push(tag);
              });

              // Re set les tags du topics en dédoublonnant
              this.setTags(UtilsService.unique(tagsTopic, 'idTag'));
            }
          }
        }
        return instanceContenu;
      },

      /**
       * Permet de modifier un contenu éxistant dans le topic
       **/
      updateContenu: function(contenu){

        var contenus = this.getContenus();
        var instanceContenu = Topic.initContenu(contenu);
        var idNewContenu = instanceContenu.idContenu;
        var indexContenuExistant = false;

        // Cherche l'index du contenu à modifier
        for(var j = 0 ; j < contenus.length ; j++){
          if(contenus[j].idContenu === idNewContenu){
            indexContenuExistant = j;
            break;
          }
        }

        var retour = instanceContenu;

        if(indexContenuExistant!==false){


          // Change le contenu
          contenus[indexContenuExistant] = instanceContenu;

          // Si il y a des tags dans le contenu
          if(instanceContenu.getTags()){
            if(instanceContenu.getTags().length){

              // REcupère les tags du contenu
              var tagsContenu = instanceContenu.getTags();

              // REcupère les tags du topics
              var tagsTopic = this.getTags();

              // Pour chaque tags
              angular.forEach(tagsContenu, function(tag) {
                tagsTopic.push(tag);
              });

              // Re set les tags du topics en dédoublonnant
              this.setTags(UtilsService.unique(tagsTopic, 'idTag'));
            }
          }

          retour = contenus[indexContenuExistant];
        }
        return retour;
      },
      //addContenu: function(contenu){
      //
      //	var contenus = this.getContenus();
      //	var instanceContenu = this.initContenu(contenu);
      //	var idNewContenu = instanceContenu.idContenu;
      //	/*
      //	console.log(idNewContenu);
      //	console.log(contenus);
      //	console.log(contenus.length);*/
      //	// Supprime le contenu si il y est déjà pour le remplacer
      //	var newContenus = UtilsService.removeWith(contenus,{idContenu:idNewContenu});
      //	//console.log(newContenus.length);
      //
      //
      //	// Ajoute cette instance à un tableau de contenus
      //	newContenus.push(instanceContenu);
      //
      //	// Si il y a des tags dans le contenu
      //	if(instanceContenu.getTags()){
      //		if(instanceContenu.getTags().length){
      //
      //			// REcupère les tags du contenu
      //			var tagsContenu = instanceContenu.getTags();
      //
      //			// REcupère les tags du topics
      //			var tagsTopic = this.getTags();
      //
      //			// Pour chaque tags
      //			angular.forEach(tagsContenu, function(tag) {
      //				tagsTopic.push(tag);
      //			});
      //
      //			// Re set les tags du topics en dédoublonnant
      //			this.setTags(UtilsService.unique(tagsTopic, 'idTag'));
      //		}
      //	}
      //
      //	this.setContenus(newContenus);
      //	/*
      //	// Passe en non lu
      //	this.getTopic().setLu(false);
      //
      //	// Incremente le nombre de contenu
      //	this.getTopic().setNbContenu(this.getTopic().getNbContenu()+1);
      //
      //	// Incremente le nombre de contenu non lu
      //	this.getTopic().setNbContenuNonLu(this.getTopic().getNbContenuNonLu()+1);
      //	*/
      //
      //	return newContenus;
      //},

      addHistorique: function(historique){
        var arrayHistoriques = this.getHistoriques();
        arrayHistoriques.push(historique);
        this.setHistoriques(arrayHistoriques);

      },

      updateTopic: function(contenu){
        if(contenu.typeContenu===2){
          this.setEventPresent(true);

          if(angular.isDefined(contenu.getDateCloture()) && contenu.getDateCloture()!==null){
            this.setEventCloturer(true);
          }else{
            this.setEventCloturer(false);
          }
        }else{
          this.setEventPresent(false);
        }

        // Si date modification undefined ou null alors c'est un nouveau contenu
        if(angular.isUndefined(contenu.dateModification) || contenu.dateModification === null) {
          this.setNbContenu(this.getNbContenu() + 1);
        }

        // Si il y a des tags dans le contenu
        if(contenu.getTags()){
          if(contenu.getTags().length){

            // REcupère les tags du contenu
            var tagsContenu = contenu.getTags();

            // REcupère les tags du topics
            var tagsTopic = this.getTags();

            // Pour chaque tags
            angular.forEach(tagsContenu, function(tag) {
              tagsTopic.push(tag);
            });

            // Re set les tags du topics en dédoublonnant
            this.setTags(UtilsService.unique(tagsTopic, 'idTag'));
          }
        }


      }
    };

    Topic.initContenu = function(contenu){

      var instanceContenu = contenu;

      if(contenu.constructor.name!=='model'){
        switch(contenu.typeContenu){
          case 1:
            // Crée une instance de ContenuMessage
            instanceContenu = new ContenuMessage(contenu);
            break;
          case 2:
            // Crée une instance de ContenuEvenement
            instanceContenu = new ContenuEvenement(contenu);

            break;
          case 3:
            // Crée une instance de ContenuEvenement
            instanceContenu = new ContenuEvenementDevis(contenu);
            break;
          case 4:
            // Crée une instance de ContenuEvenementIntervention
            instanceContenu = new ContenuEvenementIntervention(contenu);
            break;
          case 5:
            // Crée une instance de ContenuEvenementFacture
            instanceContenu = new ContenuEvenementFacture(contenu);
            break;
          default:
            // Crée une instance de ContenuMessage
            instanceContenu = new ContenuMessage(contenu);
        }
      }
      return instanceContenu;
    };

    angular.extend(Topic.prototype, Main.prototype);

    return Topic;

  }
})();
