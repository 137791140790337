(function () {
  'use strict';

  /**
   * @desc Permet de checker si il existe des association pour des tags typé
   * @example <check-associations></check-associations>
   */

  angular
    .module('collaboreApp')
    .component('checkAssociations', {
      templateUrl: 'app/components/check-associations/check-associations.component.html',
      bindings: {
        tagsType: '<',    // Les tags
        idEvenement: '<',
        onEmit: '&',
      },
      controllerAs: 'checkassociationsctrl',
      controller: CheckAssociationsController
    });

  /*@ngInject*/
  function CheckAssociationsController($q, $rootScope, GroupeService, GroupeAssociationService, UtilsService, TagsService, ModalsService) {

    var _this = this;
    _this.showComponent = false;

    _this.loading = false;

    _this.onClickChooseCollaborateurAssociation = onClickChooseCollaborateurAssociation;
    _this.onRemoveCollaborateurFromAssociation = onRemoveCollaborateurFromAssociation;
    _this.onEmitFromChooseGroupeParent = onEmitFromChooseGroupeParent;

    _this.$onInit = function() {

      if($rootScope.paramsCollab.associationEnabled){
        _this.showComponent = true;
        init(_this.tagsType);
      }
    };

    _this.$onChanges = function(changes) {
      if(_.isObject(changes.tagsType) && _.isArray(changes.tagsType.currentValue) && !changes.tagsType.isFirstChange()){
        _this.tagsType = changes.tagsType.currentValue;
        init(_this.tagsType);
      }
    };

    _this.$onDestroy = function(){
    };

    function init(tagsType){
      checkGroupeAssociation(tagsType);
    }

    function initVars(){
      _this.listGroupeParent = [];
      _this.listGroupeParentRemaining = [];
      _this.listCollaborateur = [];
      _this.groupesCanBeDeleted = {};
      _this.portefeuille = null;
      _this.tagMandat = null;
      _this.tagImmeuble = null;
      _this.tagLot = null;
      _this.tagProprietaire = null;
      _this.tagOrigineIsChoosen = false;
      _this.currentTag = null;
      _this.currentType = null;
      _this.currentGroupeAssocie = null;
      _this.currentListGroupeAssociation = [];
    }

    function initListGroupeParent(listGroupeParent){
      if(_.isNil(listGroupeParent)) listGroupeParent = _this.listGroupeParent;
      // Refait la liste des groupe parent à afficher dans la popup
      _this.listGroupeParentRemaining = angular.copy(listGroupeParent);
      // Retire de cette liste les groupes déjà associés
      if(_.isArray(_this.listCollaborateur) && _this.listCollaborateur.length) {
        _this.listGroupeParentRemaining = _.differenceBy(_this.listGroupeParentRemaining, _this.listCollaborateur, 'idGroupe');
      }
      if(listGroupeParent.length) {
        _this.groupesCanBeDeleted = {};
        listGroupeParent.map(function(g){
          _this.groupesCanBeDeleted[g.idGroupe] = true;
          return g;
        });
      }
    }

    function checkGroupeAssociation(tagsType){

      initVars();
      if(_.isArray(tagsType) && tagsType.length){
        var tagTypeOrigine = TagsService.getTagTypeOrigine(tagsType);
        var tagTypePortefeuille = TagsService.getTagTypePortefeuille(tagsType);
        var tagTypeImmeuble = TagsService.getTagTypeImmeuble(tagsType);
        var tagTypeProprietaire = TagsService.getTagTypeProprietaire(tagsType);
        if(tagTypePortefeuille) _this.portefeuille = tagTypePortefeuille.getPortefeuille();

        if(_.isObject(TagsService.getTagTypeOrigine(tagsType))) _this.tagOrigineIsChoosen = true;

        if(_.isObject(tagTypeImmeuble)) {
          _this.tagImmeuble = tagTypeImmeuble;
          _this.currentTag = tagTypeImmeuble;
          _this.currentType = 'immeuble';
        }

        if(_.isObject(tagTypeProprietaire)) {
          _this.tagProprietaire = tagTypeProprietaire;
          _this.currentTag = tagTypeProprietaire;
          _this.currentType = 'proprietaire';
        }
      }

      if(_.isObject(_this.portefeuille) && _.isObject(_this.currentTag)) {
        _this.showComponent = true;
        loadingDestinataires(true);

        // Recupère la liste des groupe parent de l'user actuel
        GroupeService
          .searchListGroupeParent(_this.portefeuille.idPortefeuille)
          .then(function(groupes){
            if(_.isArray(groupes)) _this.listGroupeParent = angular.copy(groupes);
            // ajoute l'user actuel à la liste
            _this.listGroupeParent.push(UtilsService.getCurrentUser());
            initListGroupeParent(_this.listGroupeParent);

            var tasks = [];
            tasks.push(GroupeAssociationService.searchListGroupeAssociation(_this.portefeuille.idPortefeuille, _this.currentType, _this.currentTag.getValeurIcs())
              .then(function(listGroupe){
                //console.log(listGroupe);
                emitListCollaborateurFromGroupeAssociation(listGroupe);
              })
              .finally(function(){
                //loadingDestinataires(false);
              }));

            // Recupère le groupe qui correspond au tag retenu
            tasks.push(_this.currentTag.getGroupe()
              .then(function(groupe){
                if(_.isObject(groupe)) {
                  _this.currentGroupeAssocie = groupe;
                }
              }));

            $q.all(tasks).finally(function(){
              loadingDestinataires(false);
            });

          })
          .catch(function(err){
            console.error(err);
            loadingDestinataires(false);
          });
      }
    }

    function addAssociations(groupeCollaborateur){

      var type = null;
      if(_.isObject(_this.portefeuille) && _.isObject(_this.currentTag)){
        if(_.isObject(_this.currentGroupeAssocie)) callAddAssociation(_this.currentGroupeAssocie, groupeCollaborateur);
        else {
          loadingDestinataires(true);
          _this.currentTag.getGroupe().then(function(groupeAssocie){
            if(_.isObject(groupeAssocie)) callAddAssociation(groupeAssocie, groupeCollaborateur);
            else loadingDestinataires(false);
          });
        }
      }
    }

    function callAddAssociation(groupeAssocie, groupeCollaborateur){
      loadingDestinataires(true);
      GroupeAssociationService
        .associeListIdGroupeWithListIdGroupeAssocie(
          _this.portefeuille.idPortefeuille,
          _this.currentType,
          groupeCollaborateur,
          groupeAssocie,
          true
        )
        .then(function(listGroupe){
          emitListCollaborateurFromGroupeAssocie(listGroupe, "add");
        })
        .finally(function(){
          loadingDestinataires(false);
        });
    }

    function onClickChooseCollaborateurAssociation(tag){
      var type = null;

      if(_.isObject(_this.portefeuille) && _.isObject(_this.currentTag)){
        var options_modal = {
          size: 'lg',
          windowClass: 'modal-collabore',
          backdrop: 'static'
        };
        var params = {
          mode: 'add',
          type: _this.currentType,
          idPortefeuille: _this.portefeuille.idPortefeuille,
          adminVersion: true,
          tags: _this.currentTag
        };
        ModalsService
          .modalComponent('recapAssociations', params, options_modal)
          .then(function(obj) {
            if(_.isObject(obj) && obj.action === "updateList"){
              emitListCollaborateurFromGroupeAssocie(obj.groupes, "add");
            }
          });
      }
    }

    /**
     * Permet d'emit la liste des collaborateurs trouvé dans les associations des groupes associé
     * Normalement il doit y en avoir un seul
     * @param listGroupe
     */
    function emitListCollaborateurFromGroupeAssocie(listGroupe, action){
      var listAssociations = [];
      if(_.isArray(listGroupe) && listGroupe.length) {
        listAssociations = listGroupe.reduce(function (listAssoc, groupe) {
          if (!_.isNil(groupe.getGroupeAssociation())) listAssoc.push(groupe.getGroupeAssociation());
          return listAssoc;
        }, []);
      }
      emitListCollaborateurFromGroupeAssociation(listAssociations, action);
    }

    /**
     * Permet d'emit la liste des collaborateurs trouvé dans les associations
     * @param listAssociations
     */
    function emitListCollaborateurFromGroupeAssociation(listAssociations, action){
      _this.currentListGroupeAssociation = listAssociations;

      var listCollaborateurs = GroupeAssociationService.extractListCollaborateurFromListGroupeAssociation(listAssociations);
      listCollaborateurs = _.uniqWith(listCollaborateurs, _.isEqual);

      _this.listCollaborateur = listCollaborateurs;

      initListGroupeParent();

      emit({
        action: 'updateDestinatairesFromGroupeAssociation',
        typeAction: action,
        listGroupe: angular.copy(listCollaborateurs)
      });
    }

    function onRemoveCollaborateurFromAssociation(obj){
      try {
        if (!obj) throw new Error('Pas de "obj"');

        if(_.isObject(obj.groupe)) {
          loadingDestinataires(true);
          GroupeAssociationService
            .removeListGroupeFromListAssociation(_this.currentType, obj.groupe, _this.currentListGroupeAssociation, true)
            .then(function(listGroupe){
              emitListCollaborateurFromGroupeAssocie(listGroupe, "remove");
            })
            .catch(function(e){
              ModalsService.alertErreur(e);
            })
            .finally(function(){
              loadingDestinataires(false);
            });
        }
      }
      catch (err) {
        console.log('[FormulaireEvenementComponent.onEmitFromCheckAssociationsComponent] Erreur : ' + err.message);
      }
    }

    function loadingDestinataires(value){
      _this.loading = value;
      emit({
        action: 'loadingDestinataires',
        valeur: value
      });
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      _this.onEmit({obj: objRetour});
    }

    function onEmitFromChooseGroupeParent(obj){
      try {

        if (!_.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');

        if(obj.action === 'validSelection'){
          if(_this.idEvenement){
            var text = "Voulez-vous vraiment associer";

            if(obj.listGroupeCollaborateurChecked.length > 1) text += " ces groupes";
            else text += " ce groupe";

            if(_this.currentType === 'immeuble') text += " à l'immeuble ";
            else if(_this.currentType === 'proprietaire') text += " au propriétaire ";
            text += "<strong>"+_this.currentGroupeAssocie.getDefaultLibelle() + "</strong> ?";

            text += "<br /><div class='alert alert-warning margin-0'><i class='fa fa-warning'></i> ";

            if(obj.listGroupeCollaborateurChecked.length > 1)
              text += "Les groupes deviendront destinataires de l'événement déjà existant et ne pourrons plus être retirés de ce dossier.";
            else
              text += "Le groupe deviendra destinataire de l'événement déjà existant et ne pourra plus être retiré de ce dossier.";

            text += "</div>";

            ModalsService
              .confirm("Associations", text, {type: "warning"})
              .then(function (modal) {
                addAssociations(obj.listGroupeCollaborateurChecked);
                modal.close();
              });
          }
          else addAssociations(obj.listGroupeCollaborateurChecked);
        }
      }
      catch (err) {
        ErreurCollabService.logErreur('[GestionAssociationsLigneController.onEmitFromChooseGroupeParent] Erreur : ',  err.message);
      }
    }

  }
})();
