(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.GroupeService
   * @description
   * # GroupeService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('GroupeService',
      GroupeServiceController
    );

  /** @ngInject */
  function GroupeServiceController($q, $injector, MainService, GroupeResource, ModalsService, UtilsService, PersonnaliteAjax) {
    var ctrl = this;

    ctrl.getInfosGroupe = getInfosGroupe;
    ctrl.getInfosPersoMulticamp = getInfosPersoMulticamp;
    ctrl.getInfosPersonnalite = getInfosPersonnalite;

    ctrl.groupeAllWithHierarchie = null;
    ctrl.getGroupeAllWithHierarchie = getGroupeAllWithHierarchie;
    ctrl.setGroupeAllWithHierarchie = setGroupeAllWithHierarchie;

    ctrl.getHierarchieAgenceWithoutPortefeuille = getHierarchieAgenceWithoutPortefeuille; // OLD
    ctrl.searchCollaborateurs = searchCollaborateurs; // NEW
    ctrl.searchGroupeOfCollaborateurs = searchGroupeOfCollaborateurs; // NEW

    ctrl.searchCollaborateursSender = searchCollaborateursSender;

    ctrl.searchListGroupeParent = searchListGroupeParent;

    ctrl.getGroupesAgenceWithoutPortefeuille = getGroupesAgenceWithoutPortefeuille;
    ctrl.searchGroupeByLibelleAndPortefeuilleInMySociete = searchGroupeByLibelleAndPortefeuilleInMySociete;

    ctrl.searchGroupe = searchGroupe;

    ctrl.searchListGroupeImmeubleByGroupePortefeuille = searchListGroupeImmeubleByGroupePortefeuille; // OLD
    ctrl.searchListGroupeForTypeByGroupePortefeuille = searchListGroupeForTypeByGroupePortefeuille;   // NEW

    ctrl.searchListGroupeWithEmailAndWithoutPortefeuille = searchListGroupeWithEmailAndWithoutPortefeuille;
    ctrl.searchListGroupeWithoutPortefeuilleForProxyPersoICS = searchListGroupeWithoutPortefeuilleForProxyPersoICS;
    ctrl.isSameGroupe = isSameGroupe;
    ctrl.searchInGroupe = searchInGroupe;
    ctrl.addGroupeToList = addGroupeToList;
    ctrl.removeGroupeFromList = removeGroupeFromList;
    ctrl.newGroupe = newGroupe;
    ctrl.destroy = destroy;

    /*
    function postServlet(action, obj) {
      var deferred = $q.defer();
      try {
        if (!action) throw new Error('Le paramètre "action" n\'existe pas !');

        if (!angular.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet  !');
        if (angular.isObject(obj) && !obj.hasOwnProperty('listObjJSON') && !obj.hasOwnProperty('objJSON') && !obj.hasOwnProperty('idTopic')) throw new Error('Le paramètre "obj" doit avoir un objJSON OU listObjJSON OU idTopic !');

        if(obj.hasOwnProperty('listObjJSON')) obj.listObjJSON = angular.toJson(obj.listObjJSON);
        if(obj.hasOwnProperty('objJSON')) obj.objJSON = angular.toJson(obj.objJSON);

        var sendParams = angular.merge({}, obj);
        sendParams.action = action;
        GroupeResource
          .post(sendParams, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, ['listMessage', 'contenuMessage'], 'Groupe', deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }*/

    function getGroupeAllWithHierarchie(){
      return ctrl.groupeAllWithHierarchie;
    }
    function setGroupeAllWithHierarchie(groupe){
      ctrl.groupeAllWithHierarchie = groupe;
    }

    /**
     * Permet de récupérer la hiérarchie de ma société sans les portefeuilles
     */
    function getHierarchieAgenceWithoutPortefeuille() {
      var deferred = $q.defer();
      try {
        GroupeResource
          .getHierarchieAgenceWithoutPortefeuille(function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, 'groupe', 'Groupe', deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Permet de récupérer une liste de collaborateurs users
     * @param search
     * @param page
     * @param limit
     * @returns {angular.IPromise<T>}
     */
    function searchCollaborateurs(search, page, limit, order, sens) {
      var deferred = $q.defer();
      try {

        var params = {
          search: search
        };
        if(!_.isNil(limit)){
          params.page = page;
          params.limit = limit;
          params.order = order;
          params.sens = sens;
        }

        GroupeResource
          .searchCollaborateurs(params, function (json) {
            var retour = json;
            retour.groupes = MainService.convertJsonToModel(json, 'groupes', 'Groupe', deferred);
            deferred.resolve(retour);
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Permet de récupérer une liste des groupe non user qui contienne des collaborateurs
     * @param search
     * @param page
     * @param limit
     * @param withGroupesFils
     * @returns {angular.IPromise<T>}
     */
    function searchGroupeOfCollaborateurs(search, page, limit, withGroupesFils, order, sens) {
      var deferred = $q.defer();
      try {
        var params = {
          search: search,
          withGroupesFils: (!_.isNil(withGroupesFils)) ? withGroupesFils : false
        };

        if(!_.isNil(limit)){
          params.page = page;
          params.limit = limit;
          params.order = order;
          params.sens = sens;
        }
        GroupeResource
          .searchGroupeOfCollaborateurs(params, function (json) {
            var retour = json;
            retour.groupes = MainService.convertJsonToModel(json, 'groupes', 'Groupe', deferred);
            deferred.resolve(retour);
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Permet de récupérer les groupes collaborateurs user qui sont expediteur d'un contenu que je peux voir
     */
    function searchCollaborateursSender() {
      var deferred = $q.defer();
      try {
        GroupeResource
          .searchCollaborateursSender(function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, 'groupes', 'Groupe', deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }


    /**
     * Permet d'avoir la liste des groupeParent
     * @returns {angular.IPromise<T>}
     */
    function searchListGroupeParent(idPortefeuille) {
      var deferred = $q.defer();
      try {
        var params = {};
        if(!_.isNil(idPortefeuille)) params.idPortefeuille = idPortefeuille;
        GroupeResource
          .searchListGroupeParent(params, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, 'groupes', 'Groupe', deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }


    /**
     * Permet de récupérer deux liste, une avec les user et l'autre juste les groupes
     */
    function getGroupesAgenceWithoutPortefeuille() {
      var deferred = $q.defer();
      try {
        GroupeResource
          .getGroupesAgenceWithoutPortefeuille(function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, 'groupe', 'Groupe', deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    function searchGroupeByLibelleAndPortefeuilleInMySociete(portefeuille, recherche, idContenu) {
      var deferred = $q.defer();
      try {
        if (portefeuille && _.isObject(portefeuille)) portefeuille = portefeuille.getIdPortefeuille();

        var params = {
          idPortefeuille: portefeuille,
          rechercheLibre: recherche
        };

        if(idContenu) params.rechercheIdContenu = idContenu;
        GroupeResource
          .searchGroupeByLibelleAndPortefeuilleInMySociete(params, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, 'groupe', 'Groupe', deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    function searchGroupe(paramsRecherche, portefeuille) {
      var deferred = $q.defer();
      try {
        if (portefeuille && _.isObject(portefeuille)) portefeuille = portefeuille.getIdPortefeuille();
        var params = {
          idPortefeuille: portefeuille
        };
        //paramsRecherche = _.merge(params, paramsRecherche);
        params = _.merge(params, paramsRecherche);
        if(_.isNil(params.typeGroupe)) throw new Error("Il manque le type de groupe à récupérer");
        if(_.isNil(params.idPortefeuille)) throw new Error("Il manque l'idPortefeuille'");

        GroupeResource
          .searchGroupe(params, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, 'groupe', 'Groupe', deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }


    /**
     * Permet de récupérer la liste des immeubles classé par groupe portefeuille pour un portefeuille
     * @param portefeuille
     */
    function searchListGroupeImmeubleByGroupePortefeuille(portefeuille, paramsRecherche) {
      var deferred = $q.defer();
      try {
        if (portefeuille && _.isObject(portefeuille)) portefeuille = portefeuille.getIdPortefeuille();

        /**
         * Params possibles :
         * search: recherche dans le libelle, le nom et l'adresse
         * offset
         * limit
         * order: nom (renseignement nom) ou numero (libelle)
         * sens: desc ou asc
         * withGroupeAssociation: true or false
         */
        var params = {
          idPortefeuille: portefeuille
        };
        paramsRecherche = _.merge(params, paramsRecherche);

        if(_.isObject(paramsRecherche)) {
          /*
          params.search = paramsRecherche.search;
          if(!_.isNil(paramsRecherche.withGroupeAssociation)) params.withGroupeAssociation = paramsRecherche.withGroupeAssociation;
          if(!_.isNil(paramsRecherche.offset)) params.offset = paramsRecherche.offset;
          if(!_.isNil(paramsRecherche.limit)) params.limit = paramsRecherche.limit;
          */
          if(!_.isNil(paramsRecherche.order)) {
            if(paramsRecherche.order !== "numero" && paramsRecherche.order !== "nom") throw new Error("L'ordre doit être \"numero\" ou \"nom\"");
            params.order = paramsRecherche.order;
          }
          if(!_.isNil(paramsRecherche.sens)) {
            if(paramsRecherche.sens !== "asc" && paramsRecherche.sens !== "desc") throw new Error("Le sens doit être \"asc\" ou \"desc\"");
            params.sens = paramsRecherche.sens;
          }
        }

        GroupeResource
          .searchListGroupeImmeubleByGroupePortefeuille(params, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, 'groupes', 'Groupe', deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Permet de récupérer une liste de groupe (immeuble ou perso) classé par groupe portefeuille pour un portefeuille
     * @param portefeuille
     * @param paramsRecherche
     * @returns {angular.IPromise<T>}
     */
    function searchListGroupeForTypeByGroupePortefeuille(paramsRecherche, portefeuille) {
      var deferred = $q.defer();
      try {
        if (portefeuille && _.isObject(portefeuille)) portefeuille = portefeuille.getIdPortefeuille();
        /**
         * Params possibles :
         * typeGroupe: immeuble,proprietaire,locataire,coproprietaire,mcs,cs,pcs,perso
         * search: recherche dans le libelle, le nom et l'adresse
         * offset
         * limit
         * order: nom (renseignement nom) ou numero (libelle)
         * sens: desc ou asc
         * withGroupeAssociation: true or false
         * onlyWithGroupeAssociation: true or false
         */
        var params = {
          idPortefeuille: portefeuille
        };
        paramsRecherche = _.merge(params, paramsRecherche);

        if(_.isObject(paramsRecherche)) {
          /*
          params.search = paramsRecherche.search;
          if(!_.isNil(paramsRecherche.withGroupeAssociation)) params.withGroupeAssociation = paramsRecherche.withGroupeAssociation;
          if(!_.isNil(paramsRecherche.offset)) params.offset = paramsRecherche.offset;
          if(!_.isNil(paramsRecherche.limit)) params.limit = paramsRecherche.limit;
          */
          if(!_.isNil(paramsRecherche.order)) {
            if(paramsRecherche.order !== "numero" && paramsRecherche.order !== "nom") throw new Error("L'ordre doit être \"numero\" ou \"nom\"");
            params.order = paramsRecherche.order;
          }
          if(!_.isNil(paramsRecherche.sens)) {
            if(paramsRecherche.sens !== "asc" && paramsRecherche.sens !== "desc") throw new Error("Le sens doit être \"asc\" ou \"desc\"");
            params.sens = paramsRecherche.sens;
          }
        }

        if(_.isNil(params.typeGroupe)) throw new Error("Il manque le type de groupe à récupérer");
        if(_.isNil(params.idPortefeuille)) throw new Error("Il manque l'idPortefeuille'");

        GroupeResource
          .searchListGroupeForTypeByGroupePortefeuille(params, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, 'groupes', 'Groupe', deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Permet de récupérer la liste des groupes sans portefeuille qui ont comme email
     * @param email
     */
    function searchListGroupeWithEmailAndWithoutPortefeuille(email) {
      var deferred = $q.defer();
      try {
        var params = {
          action: 'searchListGroupeWithEmailAndWithoutPortefeuille',
          email: email
        };

        GroupeResource
          .post(params, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, 'groupes', 'Groupe', deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /**
     * Permet de rechercher tout les groupes sans portefeuilles qui correspondent au ProxyPersoICS
     * @param persoICS
     * @returns {*}
     */
    function searchListGroupeWithoutPortefeuilleForProxyPersoICS(persoICS) {
      var deferred = $q.defer();
      try {
        if(_.isObject(persoICS))
          persoICS = angular.toJson(persoICS);

        var params = {
          action: 'searchListGroupeWithoutPortefeuilleForProxyPersoICS',
          proxyPersoICS: persoICS
        };

        GroupeResource
          .post(params, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, 'groupes', 'Groupe', deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }




    /**
     * Permet d'ajouter un destinataire à la liste des destinataires
     * @param groupe | Array
     */
    function addGroupeToList(destinataire, listGroupe) {

      var Groupe = MainService.$injector.get('Groupe');
      // Si le nouveau destinataire est une liste de destinataire
      if(angular.isArray(destinataire)) {
        // Si elle n'est pas vide
        if(destinataire.length) {
          // Parcour tout les nouveaux destinataire
          for(var d = 0; d < destinataire.length; d++){
            if(!groupeExistInList(destinataire[d], listGroupe)) {
              if(!destinataire[d].isMail) {
                if(destinataire[d].hasOwnProperty('libelle')) destinataire[d].libelle = UtilsService.stripTags(destinataire[d].libelle);
                if(destinataire[d].hasOwnProperty('dieze')) destinataire[d].dieze = UtilsService.stripTags(destinataire[d].dieze);
                if(destinataire[d].hasOwnProperty('defaultLibelle')) destinataire[d].defaultLibelle = UtilsService.stripTags(destinataire[d].defaultLibelle);
                destinataire[d] = new Groupe(destinataire[d]);
              }
              /*
              // Si pas nouveau contenu, on permet également de retirer les groupes grace à l'attribut "newInCurrentDestinataires" ajouté lors de l'ajout du groupe dans la liste currentDestinataires
              if(_this.idContenu) destinataire[d].newInCurrentDestinataires = true;
              // Si nouveau contenu on permet de retirer le groupe des destinataires
              else destinataire[d].allowRemoveFromDestinataires = true;*/

              listGroupe.push(destinataire[d]);
            }
          }
        }
      }
      else if(_.isObject(destinataire)) {
        if(!destinataire.isMail) {
          if(destinataire.hasOwnProperty('libelle')) destinataire.libelle = UtilsService.stripTags(destinataire.libelle);
          if(destinataire.hasOwnProperty('dieze')) destinataire.dieze = UtilsService.stripTags(destinataire.dieze);
          if(destinataire.hasOwnProperty('defaultLibelle')) destinataire.defaultLibelle = UtilsService.stripTags(destinataire.defaultLibelle);
          destinataire = new Groupe(destinataire);
        }
        // Si pas nouveau contenu, on permet également de retirer les groupes grace à l'attribut "newInCurrentDestinataires" ajouté lors de l'ajout du groupe dans la liste currentDestinataires
        //if(_this.idContenu) destinataire.newInCurrentDestinataires = true;
        // Si nouveau contenu on permet de retirer le groupe des destinataires
        //else destinataire.allowRemoveFromDestinataires = true;

        // Si liste des destinataire vid eon l'ajoute direct
        if(!listGroupe.length) listGroupe.push(destinataire);
        // Sinon on test si il est pas déjà dedans
        else if(!groupeExistInList(destinataire, listGroupe)) listGroupe.push(destinataire);
      }
      return angular.copy(listGroupe);
    }

    /**
     * Permet de retirer un collaborateur de la selectedList
     * @param groupe
     */
    function removeGroupeFromList(groupe, listGroupe){
      if(listGroupe.length) {
        for(var i = 0; i < listGroupe.length; i++){
          if(isSameGroupe(listGroupe[i], groupe)) listGroupe.splice(i, 1);
        }
      }
      return angular.copy(listGroupe);
    }

    /**
     * Permet de vérifier si le nouveau collaborateur n'est pas déjà dans la selectedList
     * @param destinataire
     * @returns {boolean}
     */
    function groupeExistInList(groupe, listGroupe){
      var exist = false;
      for(var i = 0; i < listGroupe.length; i++){
        if(isSameGroupe(listGroupe[i], groupe)) {
          exist = true;
          break;
        }
      }
      return exist;
    }

    /**
     * Test si le groupeA et le meme que le groupeB
     * @param groupeA
     * @param groupeB
     * @returns {boolean}
     */
    function isSameGroupe(groupeA, groupeB) {

      var groupeACopy = angular.copy(groupeA);
      var groupeBCopy = angular.copy(groupeB);
      if(groupeACopy.hasOwnProperty('libelle')) groupeACopy.libelle = UtilsService.stripTags(groupeACopy.libelle);
      if(groupeBCopy.hasOwnProperty('libelle')) groupeBCopy.libelle = UtilsService.stripTags(groupeBCopy.libelle);

      if(groupeACopy.hasOwnProperty('dieze')) groupeACopy.dieze = UtilsService.stripTags(groupeACopy.dieze);
      if(groupeBCopy.hasOwnProperty('dieze')) groupeBCopy.dieze = UtilsService.stripTags(groupeBCopy.dieze);

      if(groupeACopy.hasOwnProperty('defaultLibelle')) groupeACopy.defaultLibelle = UtilsService.stripTags(groupeACopy.defaultLibelle);
      if(groupeBCopy.hasOwnProperty('defaultLibelle')) groupeBCopy.defaultLibelle = UtilsService.stripTags(groupeBCopy.defaultLibelle);

      if((groupeACopy.hasOwnProperty('idGroupe') && groupeACopy.idGroupe === groupeBCopy.idGroupe) ||
        (_.isString(groupeACopy.libelle) && _.isString(groupeBCopy.libelle) && _.isString(groupeACopy.dieze) && _.isString(groupeBCopy.dieze) && groupeACopy.libelle.toLowerCase() === groupeBCopy.libelle.toLowerCase() && groupeACopy.dieze.toLowerCase() === groupeBCopy.dieze.toLowerCase()) ||
        (groupeACopy.isMail && _.isString(groupeACopy.libelle) && groupeBCopy.isMail && _.isString(groupeBCopy.libelle) && groupeACopy.libelle.toLowerCase() === groupeBCopy.libelle.toLowerCase())) {
        return true;
      }
      if((groupeACopy.hasOwnProperty('idDestinataireEmail') && groupeACopy.idDestinataireEmail === groupeBCopy.idDestinataireEmail) ||
        (_.isString(groupeACopy.email) && _.isString(groupeBCopy.email) && groupeACopy.email.toLowerCase() === groupeBCopy.email.toLowerCase())) {
        return true;
      }
      return false;
    }

    /**
     * Permet de recherche un groupe par rapport à un string
     * @param search
     * @param groupe
     * @returns {boolean}
     */
    function searchInGroupe(search, groupe){
      var find = false;
      var regEx = new RegExp(search.toLowerCase(), 'ig');
      if(UtilsService.test((groupe.getDieze()) ? groupe.getDieze().toLowerCase() : '', search, 'ig')) {
        find = true;
        //groupe.open = true;
        groupe.dieze = groupe.dieze.replace(regEx, function(match) {
          return '<strong style="color:#5ab728">' + match + '</strong>';
        });
      }

      if(UtilsService.test((groupe.getLibelle()) ? groupe.getLibelle().toLowerCase() : '', search, 'ig')) {
        find = true;
        //groupe.open = true;
        groupe.libelle = groupe.libelle.replace(regEx, function(match) {
          return '<strong style="color:#5ab728">' + match + '</strong>';
        });
      }

      if(UtilsService.test((groupe.getDefaultLibelle()) ? groupe.getDefaultLibelle().toLowerCase() : '', search, 'ig')) {
        find = true;
        //groupe.open = true;
        groupe.defaultLibelle = groupe.defaultLibelle.replace(regEx, function (match) {
          return '<strong style="color:#5ab728">' + match + '</strong>';
        });
      }
      return find;
    }

    /**
     * Permet de récupérer les infos d'un gorupe
     * @param idGroupe
     */
    function getInfosGroupe(idGroupe) {

      var deferred = $q.defer();

      var params = {
        action: 'getInfosGroupe',
        idGroupeSearch: idGroupe
      };

      GroupeResource.post(params, function (json) {

        if (json.success) deferred.resolve(MainService.convertJsonToModel(json, 'groupe', 'Groupe', deferred));
        else deferred.reject(json.message);

      }, function (error) {
        ModalsService.alertErreur(error.message);
        deferred.reject(error.message);
        //ErreurCollabService.erreurServlet(error, 'Problème avec retour servlet GroupeService.getInfosGroupe()', deferred);
      });

      return deferred.promise;
    }

    /**
     * Permet de récupérer les infos d'une perso multicamp
     * @param idGroupe
     */
    function getInfosPersoMulticamp(portefeuille, id) {

      var deferred = $q.defer();
      if (portefeuille && _.isObject(portefeuille)) portefeuille = portefeuille.getIdPortefeuille();

      var params = {
        action: 'getInfosPersoMulticamp',
        idPortefeuille: portefeuille,
        idPersonSearch: id
      };

      var LotMulticamp =  $injector.get('LotMulticamp');
      GroupeResource.post(params, function (json) {
        if (json.success) deferred.resolve(MainService.convertJsonToModel(json, 'result', 'LotMulticamp', deferred));
        else deferred.reject(json.message);

      }, function (error) {
        ModalsService.alertErreur(error.message);
        deferred.reject(error.message);
        //ErreurCollabService.erreurServlet(error, 'Problème avec retour servlet GroupeService.getInfosGroupe()', deferred);
      });

      return deferred.promise;
    }

    /**
     * Recupère les informations d'une personnalité
     * @param user
     * @returns {IPromise<T>}
     */
    function getInfosPersonnalite(user, infosPersoICS, groupByMetier){

      var retour = false;
      var deferred = $q.defer();

      var idGroupe = false;
      if(_.isNumber(user)) idGroupe = user;
      else if (_.isObject(user)) idGroupe = user.idGroupe;

      if(idGroupe){
        var params = {
          groupe: idGroupe
        };

        if(infosPersoICS) params.type = 'persoICS';
        PersonnaliteAjax.getPersonnalite(params,function(json){

          if(json.nb!=='0'){
            retour = [];

            var Portefeuille =  MainService.$injector.get('Portefeuille');
            if(!groupByMetier){
              for(var i = 0 ; i < json.Portefeuilles.length ; i++){
                retour.push(new Portefeuille(json.Portefeuilles[i]));
              }

              deferred.resolve(retour);
            }
            else{

              var byMetier = {};
              for(var j = 0 ; j < json.Portefeuilles.length ; j++){
                var portefeuille = new Portefeuille(json.Portefeuilles[j]);

                if(!byMetier.hasOwnProperty(portefeuille.getIdPortefeuille())){
                  var copyPortefeuille = angular.copy(portefeuille);
                  delete copyPortefeuille.listePersonnalite;
                  copyPortefeuille.metiers = {};
                  byMetier[portefeuille.getIdPortefeuille()] = copyPortefeuille;
                }

                if(portefeuille.hasOwnProperty('listePersonnalite')) {
                  for(var k = 0 ; k < portefeuille.listePersonnalite.length ; k++){
                    var perso = portefeuille.listePersonnalite[k];
                    if(!byMetier[portefeuille.getIdPortefeuille()].metiers.hasOwnProperty(perso.metier)){
                      byMetier[portefeuille.getIdPortefeuille()].metiers[perso.metier] = {
                        metier: perso.metier,
                        personnalites: {}
                      };
                    }

                    if(!byMetier[portefeuille.getIdPortefeuille()].metiers[perso.metier].personnalites.hasOwnProperty(perso.numero)){
                      byMetier[portefeuille.getIdPortefeuille()].metiers[perso.metier].personnalites[perso.numero] = perso;
                    }

                  }
                }

              }


              angular.forEach(byMetier,function(portefeuille){
                var copyPort = angular.copy(portefeuille);
                delete copyPort.metiers;

                copyPort.listeMetiers = [];

                angular.forEach(portefeuille.metiers,function(metier){

                  var copyMetier = angular.copy(metier);
                  delete copyMetier.personnalites;

                  copyMetier.listePersonnalites = [];

                  angular.forEach(metier.personnalites,function(personnalite){
                    copyMetier.listePersonnalites.push(personnalite);
                  });

                  copyPort.listeMetiers.push(copyMetier);

                });

                retour.push(copyPort);

              });
              deferred.resolve(retour);


              /*
               for(var j = 0 ; j < json.Portefeuilles.length ; j++){
               var portefeuilleExist   = false,
               metierExist         = false;

               var portefeuille = new Portefeuille(json.Portefeuilles[j]);

               for(var k = 0 ; k < retour.length ; k++){

               if(retour[k].idPortefeuille === portefeuille.getIdPortefeuille()){
               portefeuilleExist = true;

               for(var l = 0 ; l < retour[k].listeMetiers.length ; l++){

               if(retour[k].listeMetiers[l].metier === ){

               }
               }

               }
               }

               if(!portefeuilleExist){
               delete portefeuille.listePersonnalite;
               portefeuille.listeMetiers = [{
               'metier': perso.metier,
               'listePersonnalite': []
               }];
               retour.push(portefeuille);
               }

               }
               */

            }

            //deferred.resolve(retour);

            Portefeuille = null;
          }else{
            deferred.reject('Pas de résultats...');
          }

        },function(error){
          ModalsService.alertErreur(error.message);
          deferred.reject(error.message);
          //ErreurCollabService.erreurServlet(error,'Problème avec retour servlet GroupeService.getInfosPersonnalite()',deferred);
        }) ;
      }

      return deferred.promise;

    }

    function newGroupe(groupeObj){
      var Groupe = MainService.$injector.get('Groupe');
      return new Groupe(groupeObj);
    }

    function destroy(){
      setGroupeAllWithHierarchie(null);
    }
  }
})();
