(function() {
	'use strict';

    angular.module('collaboreApp').factory('ModuleValParam', ModuleValParam);

    /** @ngInject */
	function ModuleValParam(ModuleRefParam){

		var ModuleValParamConstructor = function(data) {
			this.setData(data);
			//ModuleRefParam.apply(this, arguments);
		};

		ModuleValParamConstructor.prototype = {

			isModel: true,

			setData: function(data){
				angular.extend(this, data);

				if(data.hasOwnProperty('referenceParams')){
					this.setReferenceParams(data.referenceParams);
				}

				if(this.hasReferenceParams()){
					if(this.getReferenceParams().isParameters()){
						this.setValeur(angular.fromJson(data.valeur));
					}
				}
			},

			// idModuleValeurParams
			setIdModuleValeurParams: function(id){
				this.idModuleValeurParams = id;
			},
			getIdModuleValeurParams: function(){
				return this.idModuleValeurParams;
			},

			// Valeur
			setValeur: function(val){
				this.valeur = val;
			},
			getValeur: function(){
				return this.valeur;
			},

			hasReferenceParams: function(){
				var retour = false;
				if(this.getReferenceParams()){
					retour = true;
				}
				return retour;
			},

			// referenceParams
			setReferenceParams: function(instanceReferenceParams){
				if(!instanceReferenceParams.isModel){
					instanceReferenceParams = new ModuleRefParam(instanceReferenceParams);
				}
				this.referenceParams = instanceReferenceParams;
			},
			getReferenceParams: function(){
				return this.referenceParams;
			}
		};

		return ModuleValParamConstructor;
	}


})();