(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .factory('PortefeuilleResource', PortefeuilleResource);
  
  /** @ngInject */
  function PortefeuilleResource(COLLAB_CONF, COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_PORTEFEUILLE, null, {
      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      }
    });
  }
})();
