(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.Groupe
   * @description
   * # Groupe
   * Service in the collaboreApp.
   *
   * Groupe Syndic (S) (@S_1306_Tichadou) :
   * RI : Responsable iiii (@RI_1306_Tichadou)
   * NI : Négo (@NI_1306_Tichadou)
   * AI : Assistante (@AI_1306_Tichadou)
   * CI : Comptable (@CI_1306_Tichadou)
   * EI : Employé (@EI_1306_Tichadou)
   *
   * Groupe Conseil Syndical (CS) (@CS_1306_Tichadou) :
   * PCS : Président Conseil Syndical (@PCS_1306_Tichadou)
   * MCS : Membre Conseil Syndical (@MCS_1306_Tichadou)
   *
   * Normaux (Normaux) (@Normaux_1306_Tichadou) :
   * L : Locataire (@L_1306_Tichadou)
   * C : Copropriétaire  (@C_1306_Tichadou)
   * P : Propriétaire (@P_1306_Tichadou)
   */

  angular
    .module('collaboreApp')
    .factory('Groupe',
      GroupeModel
    );
  /** @ngInject */
  function GroupeModel(Main, $rootScope, COLLAB_CONF, Portefeuille, Module, User, Fiche, UtilsService, Channel, Parametrage, ParametrageComfact, PersoIcs, Personnalite) {

    function Groupe(objetData) {

      if (_.isString(objetData)) {

        var obj = {
          libelle: '',
          dieze: '',
          isMail: false,
          nouveau: true
        };

        if (UtilsService.isMail(objetData)) {
          obj.libelle = objetData;
          obj.isMail = true;
        }
        else {
          var libelle = UtilsService.getLibelle(objetData);
          if (libelle) obj.libelle = libelle;

          if (!UtilsService.hasDieze(objetData)) obj.dieze = UtilsService.getUserDieze();
          else {
            var dieze = UtilsService.getDieze(objetData);
            if (dieze) obj.dieze = dieze;
          }
        }
        objetData = obj;
      }
      Main.apply(this, arguments);
      this.hydrate(objetData);
    }

    Groupe.prototype = {

      isModel: true,
      model: 'Groupe',
      isGroupe: true,
      /*
                  setData: function (objetData) {

                      var _this = this;
                      angular.extend(_this, objetData);

                      if (angular.isArray(objetData.listePortefeuille)) {
                          _this.setListePortefeuille(objetData.listePortefeuille);
                      }

                      if (angular.isArray(objetData.listeModule)) {
                          _this.setListeModule(objetData.listeModule);
                      }

                      if (angular.isArray(objetData.listeChannel)) {
                          _this.setListeChannel(objetData.listeChannel);
                      }

                      if (angular.isArray(objetData.listePersoICS)) {
                          _this.setListePersoICS(objetData.listePersoICS);
                      }

                      if (objetData.hasOwnProperty('parametrage')) {
                          _this.setParametrage(objetData.parametrage);
                      }
                      if (angular.isArray(objetData.listeParametragesParent)) {
                          _this.setListeParametragesParent(objetData.listeParametragesParent);
                      }

                      if (objetData.hasOwnProperty('groupesFils')) {
                          if (angular.isArray(objetData.groupesFils)) {
                              _this.setGroupesFils(objetData.groupesFils);
                          }
                      }

                      if (objetData.hasOwnProperty('groupesUser')) {
                          if (angular.isArray(objetData.groupesUser)) {
                              _this.setGroupesUser(objetData.groupesUser);
                          }
                      }

                      // Si c'est un user
                      if (objetData.hasOwnProperty('user')) {
                          if (angular.isObject(objetData.user)) {
                              _this.setUser(objetData.user);
                          }

                          // Sinon c'est un groupe
                      } else {

                          // Si il a une fiche
                          if (objetData.hasOwnProperty('fiche')) {

                              if (angular.isObject(objetData.fiche)) {
                                  _this.setFiche(objetData.fiche);
                              }


                               //if(_this.isImmeuble()){

                               //_this.setImmeuble(angular.copy(_this));
                               //}
                          }
                      }


                  },*/

      itsMe: function(){
        return UtilsService.itsMe(this);
      },

      isSameSocieteOfGroupe: function(groupe){
        if(!_.isObject(groupe)) throw new Error("Le paramètre \"groupe\" doit être un object Groupe");
        return UtilsService.isSameDiese(groupe.dieze);
      },

      recurseUpdateGroupe: function (data) {
        var _this = this;


        this.setIdGroupe(data.idGroupe);
        this.setDieze(data.dieze);
        this.setLibelle(data.libelle);
        this.setPalier(data.palier);


        if (data.hasOwnProperty('groupes')) {
          var nbNewGroupes = data.groupes.length;
          var nbOldGroupes = _this.groupes.length;

          var arrayGroupes = [];

          if (nbNewGroupes) {

            // Si le nb de nouveaux groupes et plus grand ou égale au nombre de groupes actuel
            if (nbNewGroupes >= nbOldGroupes) {
              // Pour chaque groupe du nouvel objet
              angular.forEach(data.groupes, function (newGroupe) {
                var groupeExist = false;
                angular.forEach(_this.groupes, function (oldGroupe) {
                  if (newGroupe.idGroupe === oldGroupe.idGroupe) {
                    groupeExist = true;
                    oldGroupe.recurseUpdateGroupe(newGroupe);
                    arrayGroupes.push(oldGroupe);
                  }
                });
                if (!groupeExist) {
                  arrayGroupes.push(newGroupe);
                  //_this.groupes.push(newGroupe);
                }
              });
              // Si le nombre de nouveaux groupes est plus petit que le nombre de groupe actuel
              // Alors ca veux dire suppression dans les groupes actuel
            } else {
              // Pour chaque groupe actuel
              angular.forEach(_this.groupes, function (oldGroupe) {
                //var groupeExist = false;
                angular.forEach(data.groupes, function (newGroupe) {
                  if (newGroupe.idGroupe === oldGroupe.idGroupe) {
                    //groupeExist = true;
                    oldGroupe.recurseUpdateGroupe(newGroupe);
                    arrayGroupes.push(oldGroupe);
                  }
                });
                /*
                                 if(!groupeExist){
                                 // Supprime le groupe de la liste des groupes actuel
                                 delete _this.groupes[indexOldGroupe];
                                 }*/
              });
            }

          }
          /*else{
                     if(nbOldGroupes){
                     _this.groupes = [];
                     }
                     }*/

          _this.groupes = arrayGroupes;
        }

        if (data.hasOwnProperty('users')) {
          this.setUsers(data.users);
        }


        // update la liste des Portefeuille
        this.updateListePortefeuille(data);

        // update la liste des Modules
        this.updateListeModule(data);


        if (data.isUser()) {
          this.setUser(data.user);
        }
        if (data.hasGroupeParent()) {
          this.setGroupeParent(data.groupeParent);
        }
      },

      updateData: function (data) {

        this.recurseUpdateGroupe(data);
      },

      getIdGroupe: function () {
        return this.idGroupe;
      },
      setIdGroupe: function (val) {
        this.idGroupe = val;
      },

      getIdUser: function () {
        return (this.isUser()) ? this.getUser().getIdUser() : false;
      },

      //Token
      getToken: function () {
        return (this.token) ? this.token : false;
      },
      setToken: function (valtoken) {
        this.token = valtoken;
      },

      // Liste Portefeuille
      getListePortefeuille: function () {
        if(!_.isArray(this.listePortefeuille)) this.listePortefeuille = [];
        return this.listePortefeuille;
      },
      setListePortefeuille: function (valListePortefeuilles) {
        var portefeuilles = [];
        if (angular.isArray(valListePortefeuilles)) {
          angular.forEach(valListePortefeuilles, function (portefeuille) {
            if (!portefeuille.isModel) {
              portefeuille = new Portefeuille(portefeuille);
            }
            portefeuilles.push(portefeuille);
          });
        }
        this.listePortefeuille = portefeuilles;
      },
      updateListePortefeuille: function (data) {

        this.listePortefeuille = data.listePortefeuille;

        /*
                 var _this = this;
                 var nbNewPortefeuille = data.listePortefeuille.length;
                 var nbOldPortefeuille = _this.listePortefeuille.length;

                 if(nbNewPortefeuille) {

                 // Si le nb de nouveaux Portefeuille et plus grand ou égale au nombre de Portefeuille actuel
                 if(nbNewPortefeuille>=nbOldPortefeuille){
                 angular.forEach(data.listePortefeuille, function (newPortefeuille) {
                 var portefeuilleExist = false;
                 angular.forEach(_this.listePortefeuille, function (oldPortefeuille) {
                 if(newPortefeuille.idPortefeuille===oldPortefeuille.idPortefeuille){
                 portefeuilleExist = true;
                 oldPortefeuille.updateData(newPortefeuille);
                 }
                 });

                 if(!portefeuilleExist){
                 _this.listePortefeuille.push(newPortefeuille);
                 }
                 });
                 }else{
                 angular.forEach(_this.listePortefeuille, function (oldPortefeuille,indexOldPortefeuille) {
                 var portefeuilleExist = false;
                 angular.forEach(data.listePortefeuille, function (newPortefeuille) {
                 if(newPortefeuille.idPortefeuille===oldPortefeuille.idPortefeuille){
                 portefeuilleExist = true;
                 oldPortefeuille.updateData(newPortefeuille);
                 }
                 });

                 if(!portefeuilleExist){
                 delete _this.listePortefeuille[indexOldPortefeuille];
                 }
                 });
                 }


                 }else{
                 if(nbOldPortefeuille){
                 _this.listePortefeuille = [];
                 }
                 }*/
      },
      hasListePortefeuille: function () {
        var retour = false;
        if (this.getListePortefeuille().length) {
          retour = true;
        }
        return retour;
      },
      hasPortefeuille: function (nom) {
        var retour = false;
        if (this.hasListePortefeuille()) {

          for (var i = 0; i < this.getListePortefeuille().length; i++) {
            if(_.isString(nom)) {
              if (this.getListePortefeuille()[i].nom === nom) {
                retour = this.getListePortefeuille()[i];
                break;
              }
            }
            else if(_.isObject(nom) && !_.isNil(nom.idPortefeuille)) {
              if (this.getListePortefeuille()[i].idPortefeuille === nom.idPortefeuille) {
                retour = this.getListePortefeuille()[i];
                break;
              }
            }
          }
        }
        return retour;
      },
      getPortefeuille: function (id) {
        var portefeuille = null;
        if(!_.isNil(id) && this.hasListePortefeuille()){
          id = parseInt(id);
          for (var i = 0; i < this.getListePortefeuille().length; i++) {
            if(id === this.getListePortefeuille()[i].getIdPortefeuille()){
              portefeuille = this.getListePortefeuille()[i];
              break;
            }
          }
        }
        return portefeuille;
      },

      // Liste PersoICS
      getListePersoICS: function () {
        return this.listePersoICS;
      },
      setListePersoICS: function (valListePersoICS) {
        var persos = [];
        if (angular.isArray(valListePersoICS)) {
          for(var i = 0; i < valListePersoICS.length; i++){
            if (!valListePersoICS[i].isModel) {
              valListePersoICS[i] = new PersoIcs(valListePersoICS[i]);
            }
            persos.push(valListePersoICS[i]);
          }
        }
        this.listePersoICS = persos;
      },
      getLastPersoICS: function () {

        var retour = false;
        if(angular.isArray(this.listePersoICS)){
          for(var i = 0; i < this.listePersoICS.length; i++){
            retour = this.listePersoICS[i];
          }
        }
        return retour;
      },
      addPersoICS: function(persoICS){
        try {

          if(!persoICS || !angular.isObject(persoICS)) throw new Error('Argument invalid!');
          if(!persoICS.isModel) persoICS = new PersoIcs(persoICS);

          var exist = false;
          var persos = this.getListePersoICS();
          if(!angular.isArray(persos)) persos = [];
          if(persos.length) {
            for(var i = 0; i < persos.length; i++) {
              if(angular.isObject(persos[i]) && (persoICS.getIdPersoICS() === persos[i].getIdPersoICS())) {
                exist = true;
                break;
              }
            }
          }
          if(!exist) {
            persos.push(persoICS);
            this.setListePersoICS(persos);
          }
        }
        catch(err){
          console.log('Groupe.model Erreur : '+err.message);
        }
      },

      // Perso recupéré
      getPerso: function(){
        return this.perso;
      },
      setPerso: function(perso){
        try{
          if(!perso || !angular.isObject(perso)) throw new Error('Argument invalid!');
          if(perso.isModel) perso = new Personnalite(perso);
          this.perso = perso;
        }
        catch(err){
          console.log('Groupe.model Erreur : '+err.message);
        }
      },

      // Liste Module
      getListeModule: function () {
        return this.listeModule;
      },
      setListeModule: function (valListeModules) {
        var modules = [];
        if (angular.isArray(valListeModules)) {
          angular.forEach(valListeModules, function (module) {
            if (!module.isModel) {
              module = new Module(module);
            }
            modules.push(module);
          });
        }
        this.listeModule = modules;
      },
      updateListeModule: function (data) {

        this.listeModule = data.listeModule;
      },
      hasListeModule: function () {
        var retour = false;
        if (this.getListeModule().length) {
          retour = true;
        }
        return retour;
      },

      // Liste Channel
      getListeChannel: function () {
        if (!angular.isArray(this.listeChannel)) {
          this.listeChannel = [];
        }
        /*
                 if(this.listeChannel.length){
                 var nb = angular.copy(this.listeChannel.length);
                 for(var i = 0 ; i < nb ; i++){
                 if(this.listeChannel[i].getType().isAccueil()){
                 if(!this.listeChannel[i].hasGroupeChannel()){
                 this.listeChannel.splice(i,1);
                 }
                 }
                 }
                 }*/
        return this.listeChannel;
      },
      /**
       * Set la liste de channel du groupe
       * @param valListeModules
       */
      setListeChannel: function (valListeModules) {
        var channels = [];
        if (angular.isArray(valListeModules)) {
          angular.forEach(valListeModules, function (channel) {
            if (!channel.isModel) {
              channel = new Channel(channel);
            }
            channels.push(channel);
          });
        }
        this.listeChannel = channels;
      },

      /**
       * Permet de savoir si le groupe à une liste de channel avec au moins un channel
       * @returns {boolean}
       */
      hasListeChannel: function () {
        var retour = false;
        if (this.getListeChannel().length) {
          retour = true;
        }
        return retour;
      },

      /**
       * Permet d'update la liste de channel du groupe
       * @param channel
       */
      updateChannel: function (channel) {
        var exist = false;
        if (this.hasListeChannel()) {
          for (var i = 0; i < this.getListeChannel().length; i++) {
            var currentChannel = this.getListeChannel()[i];
            if (currentChannel.idChannel === channel.idChannel) {
              exist = currentChannel;
              break;
            }
          }
        }

        if (!exist) {
          this.addChannel(channel);
        } else {
          exist.setData(channel);
        }
      },

      /**
       * Permet d'ajouter un channel à la liste de channel du groupe
       * @param channel
       */
      addChannel: function (channel) {
        if (!channel.isModel) {
          channel = new Channel(channel);
        }
        this.getListeChannel().push(channel);
      },

      /**
       * Permet de retirer un channel dela liste des channel du groupe
       * @param channel
       */
      removeChannel: function (channel) {
        if (this.hasListeChannel()) {
          for (var i = 0; i < this.getListeChannel().length; i++) {
            if (this.getListeChannel()[i].idChannel === channel.idChannel) {
              this.getListeChannel().splice(i, 1);
            }
          }
        }
      },

      /**
       * Permet de retirer un groupe de la liste de channel du groupe
       * @param groupe
       * @param channel
       */
      removeGroupeFromChannel: function (groupe, channel) {
        var deleteChannel = false;
        if (channel.hasGroupeChannel()) {
          for (var i = 0; i < channel.getGroupeChannel().length; i++) {
            if (channel.getGroupeChannel()[i].idGroupe === groupe.idGroupe) {
              channel.getGroupeChannel().splice(i, 1);
            }
          }
          if (!channel.hasGroupeChannel()) {
            deleteChannel = true;
          }
        } else {
          deleteChannel = true;
        }

        if (deleteChannel) {
          this.removeChannel(channel);
        }
      },

      /**
       * Permet de récupérer le channel de type Accueil (il doit y en avoir seulement 1)
       * @returns {boolean}
       */
      getChannelAccueil: function (onlyId) {
        var retour = false;
        if (onlyId) {
          retour = 0;
        }
        if (this.hasListeChannel()) {
          for (var i = 0; i < this.getListeChannel().length; i++) {
            if (this.getListeChannel()[i].getType().getIdTypeChannel() === COLLAB_CONF.CHANNEL_TYPE_ACCUEIL) {
              if (onlyId) {
                retour = this.getListeChannel()[i].getIdChannel();
              } else {
                retour = this.getListeChannel()[i];
              }
              break;
            }
          }
        }
        return retour;
      },

      getChannelsDefaut: function () {
        var retour = [];
        if (this.hasListeChannel()) {
          for (var i = 0; i < this.getListeChannel().length; i++) {
            if (this.getListeChannel()[i].getType().getIdTypeChannel() === COLLAB_CONF.CHANNEL_TYPE_CHANNEL) {
              retour.push(this.getListeChannel()[i]);
            }
          }
        }
        return retour;
      },

      getChannel: function (id) {
        var retour = false;
        try {
          if (!id) {
            throw new Error('Le paramètre doit être l\'id du channel à récupérer ! : ' + id);
          }

          if (this.hasListeChannel()) {
            for (var i = 0; i < this.getListeChannel().length; i++) {
              if (this.getListeChannel()[i].getIdChannel() === id) {
                retour = this.getListeChannel()[i];
                break;
              }
            }
          }
          return retour;
        }
        catch (err) {
          console.log('[Groupe.getChannel] Erreur : ' + err.message);
          return retour;
        }
      },

      getAccueilForGroupe: function (groupe) {
        var retour = false;
        try {

          if (!angular.isObject(groupe)) {
            throw new Error('Le 1er paramètre doit être un Object<Groupe> !');
          }

          if (this.hasListeChannel()) {
            for (var i = 0; i < this.getListeChannel().length; i++) {
              if (this.getListeChannel()[i].hasGroupeForType(groupe.idGroupe, COLLAB_CONF.CHANNEL_TYPE_ACCUEIL)) {
                retour = this.getListeChannel()[i];
                break;
              }
            }
          }
          return retour;
        }
        catch (err) {
          console.log('[Groupe.getAccueilForGroupe] Erreur : ' + err.message);
          return retour;
        }

      },

      getChannelForGroupe: function (groupe) {
        var retour = false;
        try {

          if (!angular.isObject(groupe)) {
            throw new Error('Le 1er paramètre doit être un Object<Groupe> !');
          }

          if (this.hasListeChannel()) {
            for (var i = 0; i < this.getListeChannel().length; i++) {
              if (this.getListeChannel()[i].hasGroupeForType(groupe.idGroupe, COLLAB_CONF.CHANNEL_TYPE_CHANNEL)) {
                retour = this.getListeChannel()[i];
                break;
              }
            }
          }
          return retour;
        }
        catch (err) {
          console.log('[Groupe.getChannelForGroupe] Erreur : ' + err.message);
          return retour;
        }

      },

      // userIsPresent
      getUserIsPresent: function () {
        return this.userIsPresent;
      },
      setUserIsPresent: function (lib) {
        this.userIsPresent = lib;
      },

      // defaultLibelle
      getDefaultLibelle: function () {
        return this.defaultLibelle;
      },
      setDefaultLibelle: function (lib) {
        this.defaultLibelle = lib;
      },

      // Libelle
      getLibelle: function () {
        return this.libelle;
      },
      setLibelle: function (lib) {
        this.libelle = lib;
      },

      //Dieze
      getDieze: function () {
        return this.dieze;
      },
      setDieze: function (diese) {
        this.dieze = diese;
      },

      // Libelle+Dieze
      getLibelleDieze: function () {
        return this.getLibelle() + this.getDieze();
      },

      //Host
      getHost: function () {
        return this.host;
      },
      setHost: function (host) {
        this.host = host;
      },
      //Port
      getPort: function () {
        return this.port;
      },
      setPort: function (port) {
        this.port = port;
      },
      //Auth
      getAuth: function () {
        return this.auth;
      },
      setAuth: function (auth) {
        this.auth = auth;
      },
      //Tls
      getTls: function () {
        return this.tls;
      },
      setTls: function (tls) {
        this.tls = tls;
      },

      // Mdp
      getMdp: function () {
        return this.mdp;
      },
      setMdp: function (valMdp) {
        this.mdp = valMdp;
      },

      // UserSmtp
      getUserSmtp: function () {
        return this.userSmtp;
      },
      setUserSmtp: function (valUserSmtp) {
        this.userSmtp = valUserSmtp;
      },

      // UserSmtpIsExpediteur
      getUserSmtpIsExpediteur: function () {
        return this.userSmtpIsExpediteur;
      },
      setUserSmtpIsExpediteur: function (valUserSmtpIsExpediteur) {
        this.userSmtpIsExpediteur = valUserSmtpIsExpediteur;
      },

      // Users
      getUsers: function () {
        return this.users;
      },
      setUsers: function (valUsers) {

        var users = [];
        if (angular.isArray(valUsers)) {
          angular.forEach(valUsers, function (user) {
            if (!user.isModel) {
              user = new User(user);
            }
            users.push(user);
          });
        }
        this.users = users;
      },

      // Fiche
      getFiche: function () {
        return this.fiche;
      },
      setFiche: function (valFiche) {
        if (!valFiche.isModel) {
          valFiche = new Fiche(valFiche);
        }
        this.fiche = valFiche;
      },

      // Groupe
      getGroupes: function () {
        return this.groupes;
      },
      setGroupes: function (valGroupes) {
        var groupes = [];
        if (angular.isArray(valGroupes)) {
          angular.forEach(valGroupes, function (groupe) {
            if (!groupe.isModel) {
              groupe = new User(groupe);
            }
            groupes.push(groupe);
          });
        }
        this.groupes = groupes;
      },

      // Groupe fils
      getGroupesFils: function () {
        if (!this.hasOwnProperty('groupesFils')) {
          this.groupesFils = [];
        }
        else if (!angular.isArray(this.groupesFils)) {
          this.groupesFils = [];
        }
        return this.groupesFils;
      },
      setGroupesFils: function (groupes) {
        var groupesFils = [];
        if (angular.isArray(groupes)) {
          angular.forEach(groupes, function (groupe) {
            if (!groupe.isModel) {
              groupe = new Groupe(groupe);
            }
            groupesFils.push(groupe);
          });
        }
        this.groupesFils = groupesFils;
      },

      // Groupe parent
      getGroupeParent: function () {
        return this.groupeParent;
      },
      setGroupeParent: function (groupe) {
        // supprimé pour éviter les circular
        /*var groupeTmp = angular.copy(groupe);
                 delete groupe.users;
                 delete groupe.groupes;
                 delete groupe.groupesFils;
                 delete groupe.groupeParent;*/
        this.groupeParent = new Groupe({
          'idGroupe': groupe.idGroupe,
          'libelle': groupe.libelle,
          'dieze': groupe.dieze,
          'palier': groupe.palier,
          'nbGroupes': 0
        });
      },
      hasGroupeParent: function () {
        var retour = false;
        if (this.hasOwnProperty('groupeParent')) {
          if (angular.isObject(this.groupeParent)) {
            retour = true;
          }
        }
        return retour;
      },

      // Groupes users
      getGroupesUser: function () {
        return this.groupesUser;
      },
      setGroupesUser: function (groupes) {
        var groupesUser = [];
        if (angular.isArray(groupes)) {
          angular.forEach(groupes, function (groupe) {
            if (!groupe.isModel) {
              groupe = new Groupe(groupe);
            }
            groupesUser.push(groupe);
          });
        }
        this.groupesUser = groupesUser;
      },

      getImmeuble: function () {
        return this.immeuble;
      },
      setImmeuble: function (valImmeuble) {
        this.immeuble = valImmeuble;
      },

      getPalier: function () {
        return this.palier;
      },
      setPalier: function (valPalier) {
        this.palier = valPalier;
      },

      getAuthByKerberos: function () {
        return this.authByKerberos;
      },
      setAuthByKerberos: function (val) {
        this.authByKerberos = val;
      },

      getUser: function () {
        return this.user;
      },
      setUser: function (objUser) {
        if (!objUser.isModel) {
          objUser = new User(objUser);
        }
        this.user = objUser;
      },
      hasUser: function () {
        var retour = false;
        if (angular.isObject(this.getUser())) {
          retour = true;
        }
        return retour;
      },

      getParametrage: function () {
        return this.parametrage;
      },
      setParametrage: function (parametrage) {
        var param = angular.copy(parametrage);
        if (!param.isModel) {
          param = new Parametrage(param);
        }
        this.parametrage = param;
      },

      getParametrageComfact: function () {
        return this.parametrageComfact;
      },
      setParametrageComfact: function (parametrage) {
        var param = angular.copy(parametrage);
        if (!param.isModel) {
          param = new ParametrageComfact(param);
        }
        this.parametrageComfact = param;
      },

      getGroupeInformations: function () {
        return this.groupeInformations;
      },
      setGroupeInformations: function (groupeInformations) {
        var param = angular.copy(groupeInformations);
        if (!param.isModel) {
          var GroupeInformations = Main.$injector.get('GroupeInformations');
          param = new GroupeInformations(param);
          GroupeInformations = null;
        }

        this.groupeInformations = param;
      },

      getListeParametragesParent: function () {
        return this.listeParametragesParent;
      },
      setListeParametragesParent: function (parametrages) {
        var listeParametrages = [];
        if (angular.isArray(parametrages)) {
          for (var i = 0; i < parametrages.length; i++) {
            var param = angular.copy(parametrages[i]);
            if (!param.isModel) {
              param = new Parametrage(param);
            }
            listeParametrages.push(param);
          }
        }
        this.listeParametragesParent = listeParametrages;
      },
      updateParametragesParent: function (parametrage) {
        if (angular.isObject(parametrage)) {
          var listeParamParent = this.getListeParametragesParent();
          if (angular.isArray(listeParamParent)) {
            if (listeParamParent.length) {
              for (var i = 0; i < listeParamParent.length; i++) {
                if (listeParamParent[i].idParametrage === parametrage.idParametrage) {
                  listeParamParent[i] = parametrage;
                  break;
                }
              }
            }
          }
        }
      },

      getListeParametragesComfactParent: function () {
        return this.listeParametragesParentComfact;
      },
      setListeParametragesComfactParent: function (parametrages) {
        var listeParametrages = [];
        if (angular.isArray(parametrages)) {
          for (var i = 0; i < parametrages.length; i++) {
            var param = angular.copy(parametrages[i]);
            if (!param.isModel) {
              param = new ParametrageComfact(param);
            }
            listeParametrages.push(param);
          }
        }
        this.listeParametragesParentComfact = listeParametrages;
      },
      updateParametragesComfactParent: function (parametrage) {
        if (angular.isObject(parametrage)) {
          var listeParamParent = this.getListeParametragesComfactParent();
          if (angular.isArray(listeParamParent)) {
            if (listeParamParent.length) {
              for (var i = 0; i < listeParamParent.length; i++) {
                if (listeParamParent[i].idGroupe === parametrage.idGroupe) {
                  listeParamParent[i] = parametrage;
                  break;
                }
              }
            }
          }
        }
      },

      getGroupeAssociation: function(){
        return this.groupeAssociation;
      },
      setGroupeAssociation: function(val){
        if(_.isObject(val)){
          var GroupeAssociation = null;
          if(!_.isNil(val.class)) GroupeAssociation = Main.$injector.get(val.class);
          else GroupeAssociation = Main.$injector.get('GroupeAssociation');
          val = new GroupeAssociation(val);
          GroupeAssociation = null;
        }
        this.groupeAssociation = val;
      },
      /*
      getListeAssociationGroupeI: function(){
        return this.listeAssociationGroupe;
      },
      setListeAssociationGroupe: function(val){
        if(_.isArray(val) && val.length){
          var GroupeAssociation = Main.$injector.get('GroupeAssociation');
          val.map(function(item){
            if(_.isObject(item) && !item.isModel) {
              if(!_.isNil(item.class)){
                var model = Main.$injector.get(item.class);
                item = new model(item);
              }
              else item = new GroupeAssociation(item);
            }
            return item;
          });
          GroupeAssociation = null;
        }
        this.listeAssociationGroupe = val;
      },

      // listeAssociationGroupeImmeuble
      getListeAssociationGroupeImmeuble: function(){
        return this.listeAssociationGroupeImmeuble;
      },
      setListeAssociationGroupeImmeuble: function(val){
        if(_.isArray(val) && val.length){
          var GroupeAssociationImmeuble = Main.$injector.get('GroupeAssociationImmeuble');
          val.map(function(item){
            if(_.isObject(item) && !item.isModel) item = new GroupeAssociationImmeuble(item);
            return item;
          });
          GroupeAssociationImmeuble = null;
        }
        this.listeAssociationGroupeImmeuble = val;
      },

      // listeAssociationGroupeProprietaire
      getListeAssociationGroupeProprietaire: function(){
        return this.listeAssociationGroupeProprietaire;
      },
      setListeAssociationGroupeProprietaire: function(val){
        if(_.isArray(val) && val.length){
          var GroupeAssociationProprietaire = Main.$injector.get('GroupeAssociationProprietaire');
          val.map(function(item){
            if(_.isObject(item) && !item.isModel) item = new GroupeAssociationProprietaire(item);
            return item;
          });
          GroupeAssociationProprietaire = null;
        }
        this.listeAssociationGroupeProprietaire = val;
      },*/

      /**
       * Permet de récupérer un objet "Parametrage" établi par ordre de priorité par rapport au paramsParent et paramGroupe
       * @returns {Parametrage|*}
       */
      /*getDefaultParametrage: function () {
                var paramsTmp = {};
                var paramGroupeAll = false;

                // Paramétrage du Groupe
                var parametrageGroupe = this.getParametrage();
                if (parametrageGroupe) {
                    // Si pas de priorité alors c'est le parametrage de groupe donc le plus important
                    if (!parametrageGroupe.hasOwnProperty('priority')) {
                        paramsTmp[0] = angular.copy(parametrageGroupe);
                    }
                }

                // Paramétrages des groupes parents
                var parametragesGroupeParent = this.getListeParametragesParent();
                if (parametragesGroupeParent.length) {
                    for (var i = 0; i < parametragesGroupeParent.length; i++) {
                        if (parametragesGroupeParent[i].hasOwnProperty('priority')) {
                            paramsTmp[parametragesGroupeParent[i].priority] = angular.copy(parametragesGroupeParent[i]);
                            // Si paramétrage priority 2 (palier 2) alors groupe All
                            if(parametragesGroupeParent[i].hasOwnProperty('priority') === 2) {
                                paramGroupeAll = angular.copy(parametragesGroupeParent[i]);
                            }
                        }
                    }
                }
                // TODO : il va y avoir un problème avec deux groupe du même palier, dans "paramsTmp" il n'y en aura qu'un


                var defaultParametrage = new Parametrage();
                var keys = Object.keys(paramsTmp);

                keys.sort(function (a, b) {
                    return a - b;
                });
                keys.reverse();

                // Parcour les clé dans l'odre de priorité de plus grand au plus petit 0 étant celle de l'user
                for (var j = 0; j < keys.length; j++) {
                    var param = paramsTmp[keys[j]];
                    var keysParam = Object.keys(param);			// Recup les clé du paramétrage

                    // Parcour les clé du paramétrage, la valeur des clé qui existe doivent être setté dans le defaultParametrage
                    // Si à l'itération suivante il y encore la meme clé, sa valeur doit overwrite celle du default
                    for (var k = 0; k < keysParam.length; k++) {
                        var method = 'set' + keysParam[k].ucfirst();

                        var valeur = param[keysParam[k]];
                        defaultParametrage[method](valeur);
                    }
                    delete defaultParametrage.idParametrage;
                }

                if(paramGroupeAll) {
                    defaultParametrage.paramsGroupeAll = paramGroupeAll;
                }

                return defaultParametrage;
            },*/

      getParametrageSociete: function(){
        var paramGroupeAll = null;
        // Paramétrages des groupes parents
        var parametragesGroupeParent = this.getListeParametragesParent();
        if (parametragesGroupeParent.length) {
          for (var i = 0; i < parametragesGroupeParent.length; i++) {
            if (parametragesGroupeParent[i].hasOwnProperty('priority')) {
              // Si paramétrage priority 2 (palier 2) alors groupe All
              if(parametragesGroupeParent[i].priority === 2) {
                paramGroupeAll = angular.copy(parametragesGroupeParent[i]);
              }
            }
          }
        }
        return paramGroupeAll;
      },

      getParametrageComfactSociete: function(){
        var paramGroupeAll = null;
        // Paramétrages des groupes parents
        var parametragesComfactGroupeParent = this.getListeParametragesComfactParent();
        if (angular.isArray(parametragesComfactGroupeParent) && parametragesComfactGroupeParent.length) {
          for (var i = 0; i < parametragesComfactGroupeParent.length; i++) {
            if (parametragesComfactGroupeParent[i].hasOwnProperty('priority')) {
              // Si paramétrage priority 2 (palier 2) alors groupe All
              if(parametragesComfactGroupeParent[i].priority === 2) {
                paramGroupeAll = angular.copy(parametragesComfactGroupeParent[i]);
              }
            }
          }
        }
        return paramGroupeAll;
      },

      getDefaultParametrage: function () {
        var paramsTmp = {};
        var paramGroupeAll = false;
        var paramMyGroupeUser = false;

        // Paramétrage du Groupe
        var parametrageGroupe = this.getParametrage();
        if (parametrageGroupe) {
          // Si pas de priorité alors c'est le parametrage de groupe donc le plus important
          if (!parametrageGroupe.hasOwnProperty('priority')) {
            paramsTmp[0] = paramMyGroupeUser = angular.copy(parametrageGroupe);
          }
        }

        // Paramétrages des groupes parents
        var parametragesGroupeParent = this.getListeParametragesParent();
        if (parametragesGroupeParent.length) {
          for (var i = 0; i < parametragesGroupeParent.length; i++) {

            if (parametragesGroupeParent[i].hasOwnProperty('priority')) {

              // Si paramétrage priority 2 (palier 2) alors groupe All
              if(parametragesGroupeParent[i].priority === 2) {
                paramGroupeAll = angular.copy(parametragesGroupeParent[i]);
              }

              paramsTmp[parametragesGroupeParent[i].priority] = angular.copy(parametragesGroupeParent[i]);

            }
          }
        }
        // TODO : il va y avoir un problème avec deux groupe du même palier, dans "paramsTmp" il n'y en aura qu'un

        //var defaultParametrage = new Parametrage();

        var defaultObjVal = {
          valDefault: null,		// Valeur utilisé
          valMyGroupe: null,		// Valeur du param pour mon propre groupeUser
          valGroupeAll: null		// Valeur du param pour le groupe All
        };
        var newParams = {};

        // Si param pour le groupeAll
        if(paramGroupeAll) {
          var keysParamGroupeAll = Object.keys(paramGroupeAll);			// Recup les clé du paramétrage groupeAll
          for (var p = 0; p < keysParamGroupeAll.length; p++) {
            var propriete = keysParamGroupeAll[p];
            newParams[propriete] = angular.copy(defaultObjVal);
            newParams[propriete].valGroupeAll = paramGroupeAll[propriete];
          }
        }

        // Si param perso de mon groupe
        if(paramMyGroupeUser){
          var keysParamsMyGroupe = Object.keys(paramMyGroupeUser);			// Recup les clé du paramétrage mon groupeUser
          for (var l = 0; l < keysParamsMyGroupe.length; l++) {
            var prop = keysParamsMyGroupe[l];

            // Si la propriete n'est pas dans newsParam on la créé
            if(!newParams.hasOwnProperty(prop)) {
              newParams[prop] = angular.copy(defaultObjVal);
            }
            // Set la valMyGroupe avec la valeur de mon parametre
            newParams[prop].valMyGroupe = paramMyGroupeUser[prop];
          }
        }

        var defaultParametrage = new Parametrage();

        // Recupération des "priority" dans un array
        var keys = Object.keys(paramsTmp);

        if(keys.length){
          // Tri les prioritées par ordre décroissant
          keys.sort(function (a, b) {
            return b - a;
          });
          //keys.reverse();

          // Parcour les clé dans l'odre de priorité de plus grand au plus petit 0 étant celle de l'user
          for (var j = 0; j < keys.length; j++) {
            var param = paramsTmp[keys[j]];
            var keysParam = Object.keys(param);			// Recup les clé du paramétrage

            // Parcour les clé du paramétrage, la valeur des clé qui existe doivent être setté dans le defaultParametrage
            // Si à l'itération suivante il y encore la meme clé, sa valeur doit overwrite celle du default
            for (var k = 0; k < keysParam.length; k++) {

              if(newParams.hasOwnProperty(keysParam[k])){
                var method = 'set' + keysParam[k].ucfirst();
                var valeur = param[keysParam[k]];

                newParams[keysParam[k]].valDefault = valeur;

                defaultParametrage[method](newParams[keysParam[k]]);
              }

            }
            delete defaultParametrage.idParametrage;
          }
        }



        return defaultParametrage;
      },

      getDefaultParametrageComfact: function () {
        var paramsTmp = {};
        var paramGroupeAll = false;
        var paramMyGroupeUser = false;

        // Paramétrage du Groupe
        var parametrageComfactGroupe = this.getParametrageComfact();
        if (!_.isNil(parametrageComfactGroupe) && parametrageComfactGroupe) {
          // Si pas de priorité alors c'est le parametrage de groupe donc le plus important
          if (!parametrageComfactGroupe.hasOwnProperty('priority')) {
            paramsTmp[0] = paramMyGroupeUser = angular.copy(parametrageComfactGroupe);
          }
        }

        // Paramétrages des groupes parents
        var parametragesGroupeParent = this.getListeParametragesComfactParent();
        if (angular.isArray(parametragesGroupeParent) && parametragesGroupeParent.length) {
          for (var i = 0; i < parametragesGroupeParent.length; i++) {

            if (parametragesGroupeParent[i].hasOwnProperty('priority')) {

              // Si paramétrage priority 2 (palier 2) alors groupe All
              if(parametragesGroupeParent[i].priority === 2) {
                paramGroupeAll = angular.copy(parametragesGroupeParent[i]);
              }

              paramsTmp[parametragesGroupeParent[i].priority] = angular.copy(parametragesGroupeParent[i]);

            }
          }
        }
        // TODO : il va y avoir un problème avec deux groupe du même palier, dans "paramsTmp" il n'y en aura qu'un

        //var defaultParametrage = new Parametrage();

        var defaultObjVal = {
          valDefault: null,		// Valeur utilisé
          valMyGroupe: null,		// Valeur du param pour mon propre groupeUser
          valGroupeAll: null		// Valeur du param pour le groupe All
        };
        var newParams = {};

        // Si param pour le groupeAll
        if(paramGroupeAll) {
          var keysParamGroupeAll = Object.keys(paramGroupeAll);			// Recup les clé du paramétrage groupeAll
          for (var p = 0; p < keysParamGroupeAll.length; p++) {
            var propriete = keysParamGroupeAll[p];
            newParams[propriete] = angular.copy(defaultObjVal);
            newParams[propriete].valGroupeAll = paramGroupeAll[propriete];
          }
        }

        // Si param perso de mon groupe
        if(paramMyGroupeUser){
          var keysParamsMyGroupe = Object.keys(paramMyGroupeUser);			// Recup les clé du paramétrage mon groupeUser
          for (var l = 0; l < keysParamsMyGroupe.length; l++) {
            var prop = keysParamsMyGroupe[l];

            // Si la propriete n'est pas dans newsParam on la créé
            if(!newParams.hasOwnProperty(prop)) {
              newParams[prop] = angular.copy(defaultObjVal);
            }
            // Set la valMyGroupe avec la valeur de mon parametre
            newParams[prop].valMyGroupe = paramMyGroupeUser[prop];
          }
        }

        var defaultParametrage = new ParametrageComfact();

        // Recupération des "priority" dans un array
        var keys = Object.keys(paramsTmp);

        if(keys.length){
          // Tri les prioritées par ordre décroissant
          keys.sort(function (a, b) {
            return b - a;
          });
          //keys.reverse();

          // Parcour les clé dans l'odre de priorité de plus grand au plus petit 0 étant celle de l'user
          for (var j = 0; j < keys.length; j++) {
            var param = paramsTmp[keys[j]];
            var keysParam = Object.keys(param);			// Recup les clé du paramétrage

            // Parcour les clé du paramétrage, la valeur des clé qui existe doivent être setté dans le defaultParametrage
            // Si à l'itération suivante il y encore la meme clé, sa valeur doit overwrite celle du default
            for (var k = 0; k < keysParam.length; k++) {

              if(newParams.hasOwnProperty(keysParam[k])){
                var method = 'set' + keysParam[k].ucfirst();
                var valeur = param[keysParam[k]];

                newParams[keysParam[k]].valDefault = valeur;

                defaultParametrage[method](newParams[keysParam[k]]);
              }

            }
            delete defaultParametrage.idGroupe;
          }
        }

        return defaultParametrage;
      },

      testIf: function (exp) {
        var retour = false;
        var libelle = this.getLibelle().substring(1);
        var reg = new RegExp(exp);
        if (reg.test(libelle)) {
          retour = true;
        }
        return retour;
      },

      getIcon: function () {
        var retour;
        if (this.isUser()) {
          retour = 'fa fa-user';
        } else {
          retour = 'fa fa-users';
          if (this.isPortefeuille()) {
            retour = 'fa fa-book';
          }
          else if (this.isImmeuble()) {
            retour = 'fa fa-building';
          }
        }
        return retour;
      },

      isOld: function () {
        return UtilsService.test(this.getLibelle(), '\_old$','gi');
      },

      isUser: function () {
        var retour = false;
        if (this.hasOwnProperty('user') && !this.hasOwnProperty('palier')) {
          retour = true;
        }
        return retour;
      },

      isActif: function () {
        if (this.isUser()) {
          if (this.getUser().isActif()) {
            return true;
          }
        }
        return false;
      },

      isInvite: function () {
        if (this.isUser()) {
          if (this.getUser().isInvite()) {
            return true;
          }
        }
        return false;
      },

      isSupprime: function () {
        if (this.isUser()) {
          if (this.getUser().isSupprime()) {
            return true;
          }
        }
        return false;
      },
      getEtat: function() {
        var retour = false;
        if (this.isUser()) {
          retour = this.getUser().getEtat();
        }
        return retour;
      },


      isMembre: function () {
        if (this.isUser()) {
          if (this.getUser().isMembre()) {
            return true;
          }
        }
        return false;
      },

      /**
       * Retourne si l'user est admin
       * @returns {boolean}
       */
      isAdmin: function (checkSuperAdmin) {
        if (this.isUser()) {
          if (this.getUser().isAdmin(checkSuperAdmin)) {
            return true;
          }
          if (checkSuperAdmin && this.getUser().isSuperAdmin()) {
            return true;
          }
        }
        return false;
      },

      /**
       * Retourne si l'user est superadmin
       * @returns {boolean}
       */
      isSuperAdmin: function () {
        return (this.isUser() && this.getUser().isSuperAdmin());
      },

      isAccueilCamping: function () {
        return (this.isUser() && this.getUser().isAccueilCamping());
      },

      isDirectionCamping: function () {
        return (this.isUser() && this.getUser().isDirectionCamping());
      },

      isDirectionCapfun: function () {
        return (this.isUser() && this.getUser().isDirectionCapfun());
      },

      isSiegeCapfun: function () {
        return (this.isUser() && this.getUser().isSiegeCapfun());
      },

      /**
       * Rassemble tous les dieses du groupe de société de camping capfun
       * @returns {*}
       */
      isCapfun: function(){
        return UtilsService.isCapfun();
      },
      isVaguesOceanesDiese: function(){
        return UtilsService.isVaguesOceanesDiese();
      },
      isClicochicDiese: function(){
        return UtilsService.isClicochicDiese();
      },
      isCapfunDiese: function(){
        return UtilsService.isCapfunDiese();
      },

      isICS: function(){
        return (this.getDieze()===COLLAB_CONF.SOCIETE_ICS);
      },

      allowToAccessExtranetCamping: function(){
        return (this.isCapfun() && !this.isMembre());
      },

      getPrivilege: function() {
        var retour = false;
        if (this.isUser()) {
          retour = this.getUser().getPrivilege();
        }
        return retour;
      },

      isAll: function () {
        if(_.isNil(this.getLibelle())) return false
        var retour = false;
        var libelle = this.getLibelle().substring(1);

        if (libelle === 'All') {
          retour = true;
        }

        return retour;
      },

      isPortefeuille: function () {
        if(_.isNil(this.getDieze())) return false
        var dieze = this.getDieze().toLowerCase().substring(1).ucfirst();
        var isPort = this.testIf('^(' + dieze + ')[0-9]*');
        if(!isPort && _.isObject(this.getGroupeInformations()) && this.getGroupeInformations().getIsGroupePortefeuille()) {
          isPort = true;
        }
        return isPort;
      },

      isImmeuble: function () {
        return this.testIf(/^[0-9]{4}_.+/);
      },

      getGroupeLocataire: function () {
        var retour = false;
        // Si ce groupe est un immeuble
        if (this.isImmeuble()) {
          var groupesFilsImmeuble = this.getGroupesFils();
          if (groupesFilsImmeuble.length) {
            // Parcour les groupesFils de l'immeuble
            for (var g = 0; g < groupesFilsImmeuble.length; g++) {
              var groupeFilsImmeuble = groupesFilsImmeuble[g];
              // Si ce groupe est le groupe "Normaux"
              if (groupeFilsImmeuble.isNormaux()) {
                var groupesFilsNormaux = groupeFilsImmeuble.getGroupesFils();
                // Parcour les groupesFils du groupe "Normaux"
                for (var g1 = 0; g1 < groupesFilsNormaux.length; g1++) {
                  var groupesFilsNormal = groupesFilsNormaux[g1];
                  // Si ce groupe est le groupe "Locataire"
                  if (groupesFilsNormal.isLocataire()) {
                    retour = groupesFilsNormal;
                    break;
                  }
                }
              }
              // Si il y a quelque chose dans retour alors on a trouvé le groupe souhaité on break
              if (retour) {
                break;
              }
            }
          }
        }
        return retour;
      },
      getGroupeCoproprietaire: function () {
        var retour = false;
        // Si ce groupe est un immeuble
        if (this.isImmeuble()) {
          var groupesFilsImmeuble = this.getGroupesFils();
          if (groupesFilsImmeuble.length) {
            // Parcour les groupesFils de l'immeuble
            for (var g = 0; g < groupesFilsImmeuble.length; g++) {
              var groupeFilsImmeuble = groupesFilsImmeuble[g];
              // Si ce groupe est le groupe "Normaux"
              if (groupeFilsImmeuble.isNormaux()) {
                var groupesFilsNormaux = groupeFilsImmeuble.getGroupesFils();
                // Parcour les groupesFils du groupe "Normaux"
                for (var g1 = 0; g1 < groupesFilsNormaux.length; g1++) {
                  var groupesFilsNormal = groupesFilsNormaux[g1];
                  // Si ce groupe est le groupe "Coproprietaire"
                  if (groupesFilsNormal.isCoproprietaire()) {
                    retour = groupesFilsNormal;
                    break;
                  }
                }
              }
              // Si il y a quelque chose dans retour alors on a trouvé le groupe souhaité on break
              if (retour) {
                break;
              }
            }
          }
        }
        return retour;
      },

      getGroupeProprietaire: function () {
        var retour = false;
        // Si ce groupe est un immeuble
        if (this.isImmeuble()) {
          var groupesFilsImmeuble = this.getGroupesFils();
          if (groupesFilsImmeuble.length) {
            // Parcour les groupesFils de l'immeuble
            for (var g = 0; g < groupesFilsImmeuble.length; g++) {
              var groupeFilsImmeuble = groupesFilsImmeuble[g];
              // Si ce groupe est le groupe "Normaux"
              if (groupeFilsImmeuble.isNormaux()) {
                var groupesFilsNormaux = groupeFilsImmeuble.getGroupesFils();
                // Parcour les groupesFils du groupe "Normaux"
                for (var g1 = 0; g1 < groupesFilsNormaux.length; g1++) {
                  var groupesFilsNormal = groupesFilsNormaux[g1];
                  // Si ce groupe est le groupe "Proprietaire"
                  if (groupesFilsNormal.isProprietaire()) {
                    retour = groupesFilsNormal;
                    break;
                  }
                }
              }
              // Si il y a quelque chose dans retour alors on a trouvé le groupe souhaité on break
              if (retour) {
                break;
              }
            }
          }
        }
        return retour;
      },

      getGroupeConseilSyndical: function () {
        var retour = false;
        // Si ce groupe est un immeuble
        if (this.isImmeuble()) {
          var groupesFilsImmeuble = this.getGroupesFils();
          if (groupesFilsImmeuble.length) {
            // Parcour les groupesFils de l'immeuble
            for (var g = 0; g < groupesFilsImmeuble.length; g++) {
              var groupeFilsImmeuble = groupesFilsImmeuble[g];
              // Si ce groupe est le groupe "ConseilSyndical"
              if (groupeFilsImmeuble.isCS()) {
                retour = groupeFilsImmeuble;
                break;
              }
              // Si il y a quelque chose dans retour alors on a trouvé le groupe souhaité on break
              if (retour) {
                break;
              }
            }
          }
        }
        return retour;
      },

      getGroupePresidentConseilSyndical: function () {
        var retour = false;
        // Si ce groupe est un immeuble
        if (this.isImmeuble()) {
          var groupesFilsImmeuble = this.getGroupesFils();
          if (groupesFilsImmeuble.length) {
            // Parcour les groupesFils de l'immeuble
            for (var g = 0; g < groupesFilsImmeuble.length; g++) {
              var groupeFilsImmeuble = groupesFilsImmeuble[g];
              // Si ce groupe est le groupe "ConseilSyndical"
              if (groupeFilsImmeuble.isCS()) {
                var groupesFilsCS = groupeFilsImmeuble.getGroupesFils();
                // Parcour les groupesFils du groupe "ConseilSyndical"
                for (var g1 = 0; g1 < groupesFilsCS.length; g1++) {
                  var groupeFilsCS = groupesFilsCS[g1];
                  // Si ce groupe est le groupe "PCS"
                  if (groupeFilsCS.isPCS()) {
                    retour = groupeFilsCS;
                    break;
                  }
                }
              }
              // Si il y a quelque chose dans retour alors on a trouvé le groupe souhaité on break
              if (retour) {
                break;
              }
            }
          }
        }
        else if (this.isCS()) {
          var groupesFils = this.getGroupesFils();
          // Parcour les groupesFils du groupe "ConseilSyndical"
          for (var c = 0; c < groupesFils.length; c++) {
            var groupeFils = groupesFils[c];
            // Si ce groupe est le groupe "PCS"
            if (groupeFils.isPCS()) {
              retour = groupeFils;
              break;
            }
          }
        }
        return retour;
      },
      getGroupeMembreConseilSyndical: function () {
        var retour = false;
        // Si ce groupe est un immeuble
        if (this.isImmeuble()) {
          var groupesFilsImmeuble = this.getGroupesFils();
          if (groupesFilsImmeuble.length) {
            // Parcour les groupesFils de l'immeuble
            for (var g = 0; g < groupesFilsImmeuble.length; g++) {
              var groupeFilsImmeuble = groupesFilsImmeuble[g];
              // Si ce groupe est le groupe "ConseilSyndical"
              if (groupeFilsImmeuble.isCS()) {
                var groupesFilsCS = groupeFilsImmeuble.getGroupesFils();
                // Parcour les groupesFils du groupe "ConseilSyndical"
                for (var g1 = 0; g1 < groupesFilsCS.length; g1++) {
                  var groupeFilsCS = groupesFilsCS[g1];
                  // Si ce groupe est le groupe "MCS"
                  if (groupeFilsCS.isMCS()) {
                    retour = groupeFilsCS;
                    break;
                  }
                }
              }
              // Si il y a quelque chose dans retour alors on a trouvé le groupe souhaité on break
              if (retour) {
                break;
              }
            }
          }
        }
        else if (this.isCS()) {
          var groupesFils = this.getGroupesFils();
          // Parcour les groupesFils du groupe "ConseilSyndical"
          for (var c = 0; c < groupesFils.length; c++) {
            var groupeFils = groupesFils[c];
            // Si ce groupe est le groupe "PCS"
            if (groupeFils.isMCS()) {
              retour = groupeFils;
              break;
            }
          }
        }
        return retour;
      },

      isPersoIcs: function () {
        return this.testIf('^[0-9]{6}_.+');
      },

      // ---------- Groupe Syndic ----------
      isSyndic: function () {
        /*
                 var retour = false;
                 var libelle = this.getLibelle().substring(1);

                 var reg = new RegExp('^S_');
                 if(reg.test(libelle)){
                 retour = true;
                 }*/

        return this.testIf('^S_.+');
      },
      // Responsable iiii
      isRI: function () {
        return this.testIf('^RI_.+');
      },
      // Négoc
      isNI: function () {
        return this.testIf('^NI_.+');
      },
      //Assistant
      isAI: function () {
        return this.testIf('^AI_.+');
      },
      // Comptable
      isCI: function () {
        return this.testIf('^CI_.+');
      },
      // Employé
      isEI: function () {
        return this.testIf('^EI_.+');
      },

      // ---------- Groupe Normaux -----------
      isNormaux: function () {
        return this.testIf('^Normaux_.+');
      },
      // Locataire
      isLocataire: function () {
        return this.testIf('^L_.+');
      },
      // Copropriétaire
      isCoproprietaire: function () {
        return this.testIf('^C_.+');
      },
      // Propriétaire
      isProprietaire: function () {
        return this.testIf('^P_.+');
      },

      // ---------- Groupe Conseil Syndical ----------
      isCS: function () {
        return this.testIf('^CS_.+');
      },
      // Président Conseil Syndical
      isPCS: function () {
        return this.testIf('^PCS_.+');
      },
      // Membre Conseil Syndical
      isMCS: function () {
        return this.testIf('^MCS_.+');
      },

      isAutre: function () {
        var retour = false;
        if (!this.isPortefeuille() && !this.isImmeuble() && !this.isSyndic() && !this.isNormaux() && !this.isLocataire() && !this.isCoproprietaire() && !this.isProprietaire() && !this.isRI() && !this.isNI() && !this.isAI() && !this.isCI() && !this.isEI() && !this.isCS() && !this.isPCS() && !this.isMCS()) {
          retour = true;
        }
        return retour;
      },

      isMySociete: function () {
        if(this.getLibelleDieze()) return UtilsService.endsWith(this.getLibelleDieze(), $rootScope.current.userGroupe.getDieze(), true);
      },

      isGroupeWithMultipleUsers: function(){
        return (!this.isUser() || this.isLocataire() || this.isCoproprietaire() || this.isProprietaire() || this.isCS() || this.isPCS() || this.isMCS())
      },

      isGroupePersoIcsFromMySociete: function(){
        return (_.isObject(this.getGroupeInformations()) && this.getGroupeInformations().getIsGroupePersoIcsFromMySociete());
      },

      getDefaultNom: function (contenuPersoIcs) {
        var retour = false;

        if (this.isSyndic()) {
          retour = 'Syndic';
        }
        else if (this.isRI()) {
          retour = 'Responsables Immeuble';
        }
        else if (this.isNI()) {
          retour = 'Négociateurs Immeuble';
        }
        else if (this.isAI()) {
          retour = 'Assistants Immeuble';
        }
        else if (this.isCI()) {
          retour = 'Comptables Immeuble';
        }
        else if (this.isEI()) {
          retour = 'Employés Immeuble';
        }
        else if (this.isNormaux()) {
          retour = 'Normaux';
        }
        else if (this.isLocataire()) {
          retour = 'Locataires';
        }
        else if (this.isCoproprietaire()) {
          retour = 'Copropriétaires';
        }
        else if (this.isProprietaire()) {
          retour = 'Propriétaires';
        }
        else if (this.isCS()) {
          retour = 'Conseil Syndical';
        }
        else if (this.isPCS()) {
          retour = 'Président Conseil Syndical';
        }
        else if (this.isMCS()) {
          retour = 'Membres Conseil Syndical';
        }

        if(!retour) {

          // Si groupeUser
          if(this.isUser()){
            // Recup la fiche perso
            var ficheCollab = this.getUser().getFichePerso();
            if(ficheCollab) {
              // Si il y a un nom et prenom
              if(ficheCollab.getHasNomPrenom()){
                retour = UtilsService.rtrim(ficheCollab.getNomPrenom(), $rootScope.current.userGroupe.getDieze());
              }
            }
          }
          else {
            // Recup la fiche
            var fiche = this.getFiche();
            if(fiche) {
              // Si il y a un nom et prenom
              if(fiche.getHasNomPrenom()){
                retour = UtilsService.rtrim(fiche.getNomPrenom(), $rootScope.current.userGroupe.getDieze());
              }
            }
          }

          // Si toujours pas de retour
          if(!retour){
            // Si il y a une PersoIcs passé en paramétre (peut être une persoIcs d'un contenu
            if(contenuPersoIcs && angular.isObject(contenuPersoIcs)) {
              if(contenuPersoIcs.model === 'PersoIcs') {
                retour = contenuPersoIcs.getLibelle();
              }
              else {
                retour = contenuPersoIcs.libelle;
              }
            }

            // Si pas de retour va check les persoICS de l'user
            if(!retour) {
              // Prend l'eventuel dernière perosICS
              var persoICS = this.getLastPersoICS();
              // Si perso ICS alors prend le libelle
              if(persoICS) {
                retour = persoICS.getLibelle();
              }
              // Si pas de perso ICS alors prend le default libelle du Groupe
              else {
                retour = this.getDefaultLibelle();
                if(!retour) {
                  retour = this.getLibelle();
                }
              }
            }


          }
        }
        return retour;
      },


      getNom: function () {
        var retour = this.getLibelle();
        if(_.isObject(this.getGroupeInformations()) && !_.isNil(this.getGroupeInformations().getNom())) retour = this.getGroupeInformations().getNom();
        else if (this.isUser()) {
          var user = this.getUser();
          if (user.getFichePerso()) {
            var nom = user.getFichePerso().getNomPrenom();
            if (nom) retour = nom;
          }
        }
        return retour;
      },

      getOnlyNom: function () {
        var retour = this.getLibelle();
        if(_.isObject(this.getGroupeInformations()) && !_.isNil(this.getGroupeInformations().getNom())) retour = this.getGroupeInformations().getNom();
        else if (this.isUser()) {
          var user = this.getUser();
          if (user.getFichePerso()) {
            var nom = user.getFichePerso().getDefaultNom();
            if (nom) retour = nom;
          }
        }
        return retour;
      },

      getPrenom: function(){
        var retour = this.getLibelle();
        if(_.isObject(this.getGroupeInformations()) && !_.isNil(this.getGroupeInformations().getPrenom())) retour = this.getGroupeInformations().getPrenom();
        else if (this.isUser()) {
          var user = this.getUser();
          if (_.isObject(user.getFichePerso())) {
            var prenom = user.getFichePerso().getDefaultPrenom();
            if (prenom) retour = prenom;
          }
        }
        return retour;
      },

      getEmail: function () {
        var email = null;
        if (this.isUser()) email = this.getUser().getEmail();
        if(_.isNil(email) && _.isObject(this.getGroupeInformations())) email = this.getGroupeInformations().getEmail();
        return email;
      },

      getEmails: function () {
        if (this.isUser()) {
          return this.getUser().getEmails();
        }
        return false;
      },

      getNumPerso: function () {
        var retour = false;
        if(_.isObject(this.getGroupeInformations()) && !_.isNil(this.getGroupeInformations().getNumero())){
          return this.getGroupeInformations().getNumero();
        }
        if (this.isUser()) {
          retour = this.getLibelle().slice(1, 7);
        }
        return retour;
      },

      getNumImmeuble: function () {
        var retour = false;
        if(_.isObject(this.getGroupeInformations()) && !_.isNil(this.getGroupeInformations().getNumero())){
          return this.getGroupeInformations().getNumero();
        }
        if (this.isImmeuble()) {
          retour = this.getLibelle().slice(1, 5);
        }
        return retour;
      },

      getNumero: function () {
        var retour = false;
        if (this.isImmeuble()) retour = this.getNumImmeuble();
        else if (this.isUser()) retour = this.getNumPerso();
        return retour;
      },

      hasAdresses: function () {

        if(this.getGroupeInformations() && this.getGroupeInformations().adresse){
          return true;
        }

        if (this.isUser()) {
          var user = this.getUser();
          if (user.getFichePerso()) {
            return user.getFichePerso().hasAdresses();
          }
        }
        else if(!_.isNil(this.getFiche())){
          return this.getFiche().hasAdresses();
        }
        return false;
      },

      getAdresse: function (){
        var adresse = null;
        if(this.getGroupeInformations() && this.getGroupeInformations().adresse){
          return this.getGroupeInformations().adresse;
        }
        if (this.isUser()) adresse = this.getFichePerso().getAdresse();
        else if(!_.isNil(this.getFiche())) adresse = this.getFiche().getAdresse();
        return adresse;
      },

      getRenseignementTelephones: function () {
        if(this.isUser() &&
          this.getUser().getFichePerso() &&
          this.getUser().getFichePerso().informationsByCategorie &&
          this.getUser().getFichePerso().informationsByCategorie.telephones &&
          _.isArray(this.getUser().getFichePerso().informationsByCategorie.telephones.partages))
          return this.getUser().getFichePerso().informationsByCategorie.telephones.partages;

        return [];
      },

      getRenseignementEmails: function () {
        if(this.isUser() &&
          this.getUser().getFichePerso() &&
          this.getUser().getFichePerso().informationsByCategorie &&
          this.getUser().getFichePerso().informationsByCategorie.emails &&
          _.isArray(this.getUser().getFichePerso().informationsByCategorie.emails.partages))
          return this.getUser().getFichePerso().informationsByCategorie.emails.partages;

        return [];
      },

      getMainRenseignementsWithReference: function (nomRef) {
        var renseignements = [];
        if(this.isUser() && this.getUser().getFichePerso()) {
          if(this.getUser().getFichePerso().hasFichePere()) renseignements = this.getUser().getFichePerso().getFichePere().getRenseignementsWithReference(nomRef);
          else renseignements = this.getUser().getFichePerso().getRenseignementsWithReference(nomRef);
        }
        return renseignements;
      },

      getRenseignementsWithReference: function (nomRef) {
        var renseignements = [];
        if(this.isUser() && this.getUser().getFichePerso()) renseignements = this.getUser().getFichePerso().getRenseignementsWithReference(nomRef);
        return renseignements;
      },

      minify: function () {
        var copy = angular.copy(this);
        copy.listeChannel = undefined;
        copy.listeModule = undefined;
        copy.listeParametragesParent = undefined;
        copy.listeParametragesParentComfact = undefined;
        copy.parametrage = undefined;
        copy.parametrageComfact = undefined;
        if(!_.isNil(copy.user)) copy.user.fichePerso = undefined;
        return copy;
      }
    };

    Groupe.User = User;

    angular.extend(Groupe.prototype, Main.prototype);

    return Groupe;
  }
})();
