'use strict';

/**
 * @desc Demo de lextranet
 * @example <demo-extranet></demo-extranet>
 */
/* eslint-disable */
angular
	.module('collaboreApp')
	.component('demoExtranet',{
		templateUrl: 'app/demo/demo-extranet.component.html',
		bindings : {
			onClose: '&'
		},
		controllerAs: 'demoextranetctrl',
		controller: ['$timeout','$templateCache',DemoExtranetController]
	});

function DemoExtranetController($timeout,$templateCache){

	var ctrl = this;

	ctrl.startTour = startTour;
	ctrl.closeDemo = closeDemo;


	$templateCache.put("tour-step-template-extranet.html",
		"<div>\n" +
		"    <div class=\"popover-content tour-step-content\" style=\"padding: 4px 8px;\" ng-bind-html=\"tourStep.trustedContent\"></div>\n" +
		"    <div class=\"popover-navigation tour-step-navigation\" style=\"padding:5px\"'>\n" +
			/*
		"    <div class=\"row\">\n" +
		"    	<div class=\"col-xs-12\">\n" +
		//"        <div class=\"btn-group\">\n" +


		//"        </div>\n" +
		"    	</div>\n" +
		"    </div>\n" +*/
		"    <div class=\"row margin-top-5\">\n" +
		"    	<div class=\"col-xs-12 text-center\">\n" +
		"            <button class=\"btn btn-sm pull-left btn-default\" ng-if=\"tourStep.isPrev\" ng-click=\"tour.prev()\"><i class=\"fa fa-angle-left\"></i> Précédent</button>\n" +
		"        	<button class=\"btn btn-sm\" data-role=\"end\" ng-click=\"tour.end()\">Quitter la visite</button>\n" +
		"            <button class=\"btn btn-sm pull-right btn-primary\" ng-if=\"tourStep.isNext\" ng-click=\"tour.next()\">Suivant <i class=\"fa fa-angle-right\"></i></button>\n" +
		"    	</div>\n" +
		"    </div>\n" +
		"    </div>\n" +

		"</div>\n" +
		"");



	ctrl.$onInit = function() {

	};


	function startTour(at){
		$timeout(function(){
			var btn = '#btnStartTour';
			if(at){
				btn = '#btnStartTour1';
			}
			angular.element(btn).triggerHandler('click');
		});

	}

	function closeDemo(){
		ctrl.onClose();
	}
}
/* eslint-enable */

