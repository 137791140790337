(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .component('relanceDemande', {
      templateUrl: 'app/topics/fournisseurs/relance-demande/relance-demande.component.html',
      controller: RelanceDemandeController,
      controllerAs: 'relancedemandectrl',
      bindings: {
        demande:'<',
        onSubmit: '&',
        onEmit: '&',

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<'
      }
    });

  /** @ngInject */
  function RelanceDemandeController(COLLAB_CONF, $q, $timeout, DevisService, DemandeService, UtilsService, ModalsService, RelanceAutoConfig) {
    var _this = this;

    _this.parseDate = UtilsService.parseDate;

    _this.configHasChanged = false;       // Flag pour savoir si la config à changé entre "relanceAutoConfig" et "prevRelanceAutoConfig" et autres
    _this.relanceAutoConfig = null;       // Objet relance auto config actuel, soit un nouveau soit une copie de celui de la demande
    _this.listPeriode = [];               // Liste des périodes récupéré dans les paramétrages lors de la connexion
    _this.dateDepartRelance = null;       // Date de départ pour calculer la date de prochaine relance

    _this.onChangeEnableRelanceAuto = onChangeEnableRelanceAuto;  // Lors de l'activation/désactivation de la relance auto
    _this.onChangeRelanceFromToday = onChangeRelanceFromToday;    // Lors de l'activation/désactivation de la relance depuis aujourd'hui
    _this.onChangeRelanceFromLastRelance = onChangeRelanceFromLastRelance;    // Lors de l'activation/désactivation de la relance depuis la date de dernière relance
    _this.displayRecapProchaineRelanceAutoCalcule = displayRecapProchaineRelanceAutoCalcule;  // Permet d'afficher/cacher le bloc avec l'info de la prochaine relance calculée
    _this.displayCheckboxRelanceFromToday = displayCheckboxRelanceFromToday;                  // Permet d'afficher/cacher la checkbox pour choisir de relancer à partir d'aujourd'hui
    _this.displayCheckboxRelanceFromLastRelance = displayCheckboxRelanceFromLastRelance;      // Permet d'afficher/cacher la checkbox pour choisir de relancer à partir de la date de dernière relance
    _this.getRelanceAutoFromToday = getRelanceAutoFromToday;                              // Est-ce qu'on demande la relance depuis aujourd'hui ?
    _this.onStopSlide = onStopSlide;                              // Lorsqu'on bouge le slider
    _this.onClickSave = onClickSave;                              // Lors du clique sur le bouton de sauvegarde
    _this.onClickSaveAndRelance = onClickSaveAndRelance;          // Lors du clique sur le bouton de sauvegarde et relance
    _this.onClickClose = onClickClose;

    _this.$onInit = function () {

      if(_this.resolve && _this.modalInstance) {
        _this.demande = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'demande'));
      }

      if(!_this.demande) ModalsService.alertErreur('Pas de demande');

      initListPeriode();
    };

    _this.$onDestroy = function () {
    };

    /**
     * Initialisation de l'objet de relance auto config
     */
    function initRelanceAutoConfig(){
      _this.relanceAutoConfig = new RelanceAutoConfig({
        enabled: false
      });

      if(_.isObject(_this.demande.getRelanceAutoConfig()))
        _this.relanceAutoConfig = angular.copy(_this.demande.getRelanceAutoConfig());

      _this.dateDepartRelance = _this.demande.getDateDepartRelance();
    }

    /**
     * Initilisation de la liste des periode pour créer le slider
     * @param demande
     */
    function initListPeriode(demande){

      initRelanceAutoConfig();

      _this.listPeriode = UtilsService.getParametragePerso('listPeriode');

      var listTicks = [],
        listTicksLabels = [];

      if(_.isArray(_this.listPeriode) && _this.listPeriode.length){
        for(var i = 0; i < _this.listPeriode.length; i++){
          listTicks.push(_this.listPeriode[i].getId());
          listTicksLabels.push(_this.listPeriode[i].getLibelle());
        }
      }
      _this.listTicks = listTicks;
      _this.listTicksLabels = listTicksLabels;
    }

    /**
     * Renseigne une variable qui permet de savoir si la relance a été modifié par rapport à avant
     */
    function setConfigHasChanged(){

      var configHasChanged = false;

      // Si il existe déjà une config dans la demande
      if(_.isObject(_this.demande.getRelanceAutoConfig())) {
        // Si l'activation/desactivation est différente avant aprés
        if(_this.relanceAutoConfig.getEnabled() !== _this.demande.getRelanceAutoConfig().getEnabled()) configHasChanged = true;
        // Si la période est différente
        else if(!periodChoosenIsSameOfDemandePeriod())
          configHasChanged = true;
      }
      // Si pas de config dans la demande et que les relances auto sont maintenant activées
      else if(_this.relanceAutoConfig.getEnabled()) configHasChanged = true;

      // Si on a coché la case pour paramétré la nouvelle date de relance auto par rapport à aujourd'hui et que le choix de periode est différent d'avant
      if(_this.relanceAutoConfig.getEnabled() && (_this.getRelanceAutoFromToday() || _this.relanceAutoConfig.relanceFromLastRelance)) configHasChanged = true;

      _this.configHasChanged = configHasChanged;
    }

    /**
     * Lors de l'activation/desactivation des relances auto
     */
    function onChangeEnableRelanceAuto(){
      // Si on desactive
      if(!_this.relanceAutoConfig.getEnabled()) _this.relanceAutoConfig.setPeriode(null);
      // Si on active
      else {
        // Si il y a une conf de relance auto dans la demande
        if(_.isObject(_this.demande.getRelanceAutoConfig())) _this.relanceAutoConfig.setPeriode(angular.copy(_this.demande.getRelanceAutoConfig().getPeriode()));
        // Si pas de conf alors on prend la 1er periode la liste
        else _this.relanceAutoConfig.setPeriode(angular.copy(_this.listPeriode[0]));

        setRelanceAutoFromToday(false);
        setRelanceFromLastRelance(false);
      }
      setConfigHasChanged();
    }

    /**************************************/
    /** Début Relance depuis aujourd'hui **/
    /**************************************/

    /**
     * Permet setter la variable "relanceFromToday" de l'objet "relanceAutoConfig" de l'instance du controller
     * @param val
     */
    function setRelanceAutoFromToday(val){
      _this.relanceAutoConfig.relanceFromToday = val;
      _this.relanceAutoConfig.relanceFromTodayForced = val;
      onChangeRelanceFromToday();
    }

    function getRelanceAutoFromToday(){
      return (_this.relanceAutoConfig.relanceFromToday || _this.relanceAutoConfig.relanceFromTodayForced);
    }

    /**
     * Lors du cochage/décochage de la checkbox pour démarrer les relance depuis aujourd'hui
     */
    function onChangeRelanceFromToday(){
      if(_this.relanceAutoConfig.relanceFromToday) {
        _this.dateDepartRelance = new Date();
        if(_this.relanceAutoConfig.relanceFromLastRelance) setRelanceFromLastRelance(false);
      }
      else _this.dateDepartRelance = _this.demande.getDateDepartRelance();
      setConfigHasChanged();
    }

    /**
     * Permet d'afficher/cacher la checkbox pour relancer depuis aujourd'hui
     * @returns {boolean}
     */
    function displayCheckboxRelanceFromToday(){

      // Si la date départ dans la BDD est la même qu'aujourd'hui on cache la case
      if(moment(_this.demande.getDateDepartRelance()).startOf('day').isSame(moment().startOf('day'))) return false;

      // Si il y a une conf de relance auto déjà enregistrée dans la demande
      if(_.isObject(_this.demande.getRelanceAutoConfig())) {

        // Calcul une date de prochaine échéance par rapport au choix de periode et aujourd'hui
        var dateNextRelanceFromToday = getMomentOfNextRelanceFromNowOrDateAndPeriodChoosen();
        if(!_.isNil(dateNextRelanceFromToday)) {
          // Si la date de prochaine relance calculée à partir d'aujourd'hui est la même que la date de prochaine relance dans la BDD alors pas besoin d'afficher la case
          if(dateNextRelanceFromToday.isSame(moment(_this.demande.getRelanceAutoConfig().getDateProchaineRelance()).startOf('day'))) return false;

          // Si la date de prochaine relance calculée par rapport à la date de depart relance est avant ou au même jour qu'aujourdhui alors cache le bouton
          if(!nextRelanceFromDateIsAfterToday(_this.demande.getDateDepartRelance())) return false;

          //var dateNextRelanceFromDateDepartChoosen = getMomentOfNextRelanceFromNowOrDateAndPeriodChoosen(_this.dateDepartRelance);
          //if(dateNextRelanceFromDateDepartChoosen.isSameOrBefore(moment().startOf('day'))) return false;

        }
      }
      // Si pas de conf dans la demande
      else {
        // Si il y a une date de départ relance
        if(!_.isNil(_this.demande.getDateDepartRelance())) {
          // Calcul une date de prochaine échéance par rapport au choix de periode et la date de depart relance
          //var dateNextRelanceFromDateDepartRelance = getMomentOfNextRelanceFromNowOrDateAndPeriodChoosen(_this.demande.getDateDepartRelance());
          // Si la date de la prochaine relance calculée par rapport à la date de depart relance moins récente ou égale à aujoud'hui
          //if(dateNextRelanceFromDateDepartRelance.isSameOrBefore(moment().startOf('day'))) return false;

          // Si la date de prochaine relance calculée par rapport à la date de depart relance est avant ou au même jour qu'aujourdhui alors cache le bouton
          if(!nextRelanceFromDateIsAfterToday(_this.demande.getDateDepartRelance())) return false;
        }
      }

      return true;
    }

    /*************** Fin Relance depuis aujourd'hui ******************/

    /******************************************************/
    /** Début Relance depuis la dernière date de relance **/
    /******************************************************/

    function setRelanceFromLastRelance(val){
      _this.relanceAutoConfig.relanceFromLastRelance = val;
      onChangeRelanceFromLastRelance();
    }

    function onChangeRelanceFromLastRelance(){
      if(_this.relanceAutoConfig.relanceFromLastRelance) {
        _this.dateDepartRelance = _this.demande.getDateDepartRelance();
        if(_this.relanceAutoConfig.relanceFromToday) setRelanceAutoFromToday(false);
      }
      //else _this.dateDepartRelance = _this.demande.getDateDepartRelance();
      setConfigHasChanged();
    }

    function displayCheckboxRelanceFromLastRelance(){

      if(!_.isNil(_this.demande.getDateDepartRelance()) && !_.isNil(_this.demande.getDateDerniereRelance())){

        var momentDateOnlyOfLastRelance = moment(_this.demande.getDateDerniereRelance()).startOf('day');

        // si la date dernière relance est la même qu'aujourd'hui on cache la case
        if(momentDateOnlyOfLastRelance.isSame(moment().startOf('day'))) return false;

        var momentDateOnlyOfDateDepart = moment(_this.demande.getDateDepartRelance()).startOf('day');

        // Calcul la date de prochaine relance par rapport à la date de dernière relance et la période choisie
        //var nextRelanceFromLastRelance = getMomentOfNextRelanceFromNowOrDateAndPeriodChoosen(momentDateOnlyOfLastRelance);
        // Si la date de la prochaine relance calculé par rapport à la date de dernière relance est la même ou avant aujourd'hui alors cache le bouton
        //if(nextRelanceFromLastRelance.isSameOrBefore(moment().startOf('day'))) return false;

        // Si la date de prochaine relance calculée par rapport à la date de dernière relance est avant ou au même jour qu'aujourdhui alors cache le bouton
        if(!nextRelanceFromDateIsAfterToday(momentDateOnlyOfLastRelance)) return false;
        else {
          // Si la periode choisie est la meme que celle déjà dans la bdd et que la date de depart est la meme que la date de dernière relance
          if(periodChoosenIsSameOfDemandePeriod() && momentDateOnlyOfDateDepart.isSame(momentDateOnlyOfLastRelance)){

            // Si pas de date de prochaine relance dans la BDD
            if(_.isNil(_this.demande.getRelanceAutoConfig().getDateProchaineRelance())) return false;

            var nextRelanceFromLastRelance = getMomentOfNextRelanceFromNowOrDateAndPeriodChoosen(momentDateOnlyOfLastRelance);
            // Si la date de prochaine relance calculée à partir d'aujourd'hui est la même que la date de prochaine relance dans la BDD alors pas besoin d'afficher la case
            if(nextRelanceFromLastRelance.isSame(moment(_this.demande.getRelanceAutoConfig().getDateProchaineRelance()).startOf('day'))) return false;
            return true;
          }
        }
      }
      return false;
    }

    /*************** Fin Relance depuis la dernière date de relance ******************/

    /**
     * Permet de récupérer l'objet moment de la prochaine date de relance par rapport à maintenant ou date en paramètre et la periode choisi
     * @returns {*}
     */
    function getMomentOfNextRelanceFromNowOrDateAndPeriodChoosen(date){
      var retour = null;
      var periode = _this.relanceAutoConfig.getPeriode();
      if(!_.isNil(periode)) {
        if(!date) date = moment();
        if(!moment.isMoment(date)) date = moment(date);
        // Calcul de la prochaine date de relance par rapport à aujourd'hui
        retour = date.clone().add(periode.getValuePeriodCalendar(), periode.getTypePeriodMoment()).startOf('day');
      }
      return retour;
    }

    /**
     * Permet de savoir si la période choisi est la meme que la periode de la demande
     * @returns {boolean}
     */
    function periodChoosenIsSameOfDemandePeriod(){
      return (_.isObject(_this.demande.getRelanceAutoConfig()) &&
        _.isObject(_this.demande.getRelanceAutoConfig().getPeriode()) &&
        _.isObject(_this.relanceAutoConfig.getPeriode()) &&
        _this.demande.getRelanceAutoConfig().getPeriode().getId() === _this.relanceAutoConfig.getPeriode().getId());
    }

    function nextRelanceFromDateIsAfterToday(date){
      var nextRelance = getMomentOfNextRelanceFromNowOrDateAndPeriodChoosen(date);
      return (!_.isNil(nextRelance) && nextRelance.isAfter(moment().startOf('day')));
    }

    /**
     * Permet d'afficher/cacher le bloc de récap de la future prochaine date de relance calculée par rapport au choix de période
     * @returns {boolean}
     */
    function displayRecapProchaineRelanceAutoCalcule(){
      // Si on demande de faire la relance à partir d'aujourd'hui
      if(_this.relanceAutoConfig.relanceFromToday || _this.relanceAutoConfig.relanceFromLastRelance) return true;
      // Si la periode de la conf est différente de celle choisi
      else if(!periodChoosenIsSameOfDemandePeriod()) return true;
      // Si relance auto activée, qu'il y a une conf dans la demande mais pas de date de prochaine relance (car desactivé à un moment)
      else if(_this.relanceAutoConfig.getEnabled() && _.isObject(_this.demande.getRelanceAutoConfig()) && !_this.demande.getRelanceAutoConfig().getDateProchaineRelance())
        return true;

      return false;
    }

    /**
     * Lors du changement de période
     * @param event
     * @param value
     */
    function onStopSlide(event, value){

      if(periodChoosenIsSameOfDemandePeriod()) {
        _this.relanceAutoConfig.setPeriode(angular.copy(_this.demande.getRelanceAutoConfig().getPeriode()));
        setRelanceAutoFromToday(false);
        setRelanceFromLastRelance(false);
      }
      else {
        //_this.sliderMoved = true;
        if(!_.isNil(value) && _.isArray(_this.listPeriode) && _this.listPeriode.length) {
          for(var i = 0; i < _this.listPeriode.length; i++){
            if(_this.listPeriode[i].id === value) {
              _this.relanceAutoConfig.setPeriode(angular.copy(_this.listPeriode[i]));
              break;
            }
          }
        }

        // Si la date de la prochaine relance calculée par rapport à la date de depart relance actuelle est bien après aujourd'hui
        if(nextRelanceFromDateIsAfterToday(_this.dateDepartRelance)){
          // Si la case "relance à partir d'aujourd'hui" n'est pas cochée mais qu'on à forcé la date de départ à aujourd'hui alors on ne force plus
          if(!_this.relanceAutoConfig.relanceFromToday && _this.relanceAutoConfig.relanceFromTodayForced) {
            setRelanceAutoFromToday(false);
          }
        }
      }
      setConfigHasChanged();
    }

    /**
     * Lors du clique sur le bouton pour sauvegarder le paramétrage de relance et de relancer
     */
    function onClickSaveAndRelance(){
      // Si la config a changé
      if(_this.configHasChanged) save(true);
      // Si la config n'a pas changé alors relance seulement
      else {
        _this.loading = true;
        _this.demande
          .relancer()
          .then(function(demande){
            emit({action: 'saveRelanceDemande', demande: demande, doRelance: true});
          })
          .catch(function onSuccess(msg) {
            ModalsService.alertErreur(msg);
          })
          .finally(function onFinally() {
            _this.loading = false;
          });
      }
    }

    /**
     * Lors du clique sur le bouton pour sauvegarder le paramétrage de relance
     */
    function onClickSave(){
      save();
    }

    /**
     * Sauvegarde la configuration de la relance
     */
    function save(doRelance){
      _this.loading = true;
      DemandeService
        .saveRelance(
          _this.demande.getIdContenu(),
          (_.isObject(_this.relanceAutoConfig.getPeriode())) ? _this.relanceAutoConfig.getPeriode().getId() : null,
          _this.relanceAutoConfig.getEnabled(),
          _this.getRelanceAutoFromToday(),
          (_.isNil(doRelance) ? false : doRelance)
        )
        .then(function(demande){
          emit({action: 'saveRelanceDemande', demande: demande, doRelance: doRelance});
        })
        .catch(function onSuccess(msg) {
          ModalsService.alertErreur(msg);
        })
        .finally(function onFinally() {
          _this.loading = false;
        });
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      if(_this.modalInstance) _this.close({$value: objRetour});
      else _this.onSubmit({obj: objRetour})
    }

    /**
     * Lors du clise de la modal
     */
    function onClickClose(){
      if(_this.modalInstance) _this.dismiss({$value: 'cancel'});
      else emit({action: 'closeRelanceDemande'});
    }
  }
})();

