(function() {

  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.ModalsService
   * @description
   * # ModalsService
   * Service in the collaboreApp.
   */
  angular
    .module('collaboreApp')
    .service('ModalsService',
      ModalsServiceCtrl
    );

  /** @ngInject */
  function ModalsServiceCtrl($q, ErreurCollabService, $uibModal, sweet) {

    var ctrl = this;
    var allInstanceModalFiche = [];

    ctrl.sweet = sweet;
    ctrl.modalFiche = modalFiche;
    ctrl.closeAllModalFiche = closeAllModalFiche;
    ctrl.modalViewDocument = modalViewDocument;
    ctrl.modalChangeSMTP = modalChangeSMTP;
    ctrl.modalSendSMS = modalSendSMS;
    ctrl.modalSendDemandeByFax = modalSendDemandeByFax;
    ctrl.modalCommunication = modalCommunication;
    ctrl.modalComponent = modalComponent;
    ctrl.modalRelanceDemande = modalRelanceDemande;
    ctrl.confirm = confirm;
    ctrl.input = input;
    ctrl.alert = alert;

    ctrl.alertErreur = alertErreur;
    ctrl.success = success;
    ctrl.info = info;
    ctrl.loading = loading;
    ctrl.closeAlert = closeAlert;

    function closeAlert(){
      sweet.close();
    }


    function modalFiche(params, fctOnOpened, fctOnResult) {

      if(!params) {
        params = {};
      }

      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'app/fiche/modal-fiche.html',
        controller: 'ModalFicheCtrl',
        controllerAs: 'modalfichectrl',
        size: 'lg',
        windowClass: 'modal-collabore',
        backdrop: 'static',
        resolve: {
          params: function () {
            return params;
          }
        }
      });

      allInstanceModalFiche.push(modalInstance);

      modalInstance.opened.then(function () {
        if (fctOnOpened) {
          fctOnOpened();
        }
      });

      modalInstance.result.then(function (objRetour) {
        if (fctOnResult) {
          fctOnResult(objRetour);
        }
      });
    }

    function closeAllModalFiche() {
      if (allInstanceModalFiche.length) {
        for (var i = 0; i < allInstanceModalFiche.length; i++) {
          allInstanceModalFiche[i].dismiss('cancel');
        }
      }
      allInstanceModalFiche = [];
    }


    function modalViewDocument(options, fctOnOpened, fctOnResult) {

      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'app/topics/modal/modal-documents.html',
        controller: 'ModalDocumentsCtrl',
        controllerAs: 'modaldocumentsctrl',
        size: 'full',
        windowClass: 'modal-collabore modal-annuaire-collab',
        backdrop: 'static',
        resolve: {
          params: function () {
            return options;
          }
        }
      });

      modalInstance.opened.then(function () {
        if (fctOnOpened) {
          fctOnOpened();
        }
      });

      modalInstance.result.then(function (objRetour) {
        if (fctOnResult) {
          fctOnResult(objRetour);
        }
      });
    }

    function modalChangeSMTP(groupeUser, fctOnOpened, fctOnResult) {



      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'app/admin/comptes/modal-admin-smtp.template.html',
        controller: 'ModalAdminSMTPCtrl',
        controllerAs: 'modaladminsmtpctrl',
        size: 'lg',
        windowClass: 'modal-collabore modal-annuaire-collab',
        backdrop: 'static',
        resolve: {
          groupeUser: function () {
            return groupeUser;
          }
        }
      });

      modalInstance.opened.then(function () {
        if (fctOnOpened) {
          fctOnOpened();
        }
      });

      modalInstance.result.then(function (objRetour) {
        if (fctOnResult) {
          fctOnResult(objRetour);
        }
      });
    }

    /**
     * Permet d'envoyer un SMS
     * @param obj
     * @param fctOnOpened
     * @param fctOnResult
     */
    function modalSendSMS(obj, fctOnOpened, fctOnResult) {

      var objModal = {
        animation: true,
        templateUrl: 'app/topics/modal/modal-send-sms.html',
        controller: 'ModalSendSmsCtrl',
        controllerAs: 'modalsendsmsctrl',
        size: 'lg',
        windowClass: 'modal-collabore modal-annuaire-collab',
        backdrop: 'static',
        resolve: {}
      };

      var keys = Object.keys(obj);
      if (keys.length) {
        for (var i = 0; i < keys.length; i++) {
          objModal.resolve[keys[i]] = obj[keys[i]];

        }
      }

      var modalInstance = $uibModal.open(objModal);

      modalInstance.opened.then(function () {
        if (fctOnOpened) {
          fctOnOpened();
        }
      });

      modalInstance.result.then(function (objRetour) {
        if (fctOnResult) {
          fctOnResult(objRetour);
        }
      });
    }


    /**
     * Permet d'envoyer une demande de devis par fax
     * @param contenu
     * @param fctOnOpened
     * @param fctOnResult
     */
    function modalSendDemandeByFax(contenu, fctOnOpened, fctOnResult){
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'app/topics/modal/modal-send-fax.html',
        controller: 'ModalSendFaxCtrl',
        controllerAs: 'modalsendfaxctrl',
        size: 'lg',
        windowClass: 'modal-collabore',
        backdrop: 'static',
        resolve: {
          contenu: function () {
            return contenu;
          }
        }
      });

      modalInstance.opened.then(function () {
        if (fctOnOpened) {
          fctOnOpened();
        }
      });

      modalInstance.result.then(function (objRetour) {
        if (fctOnResult) {
          fctOnResult(objRetour);
        }
      });
    }

    /**
     * Permet d'ouvrir la modal de communication
     * @param obj
     * @returns {Promise}
     */
    function modalCommunication(obj, fctOnFinish){
      var deferred = $q.defer();

      var idTopic = false;

      if(angular.isObject(obj) && obj.hasOwnProperty('idTopic')){
        idTopic = obj.idTopic;
      }
      else if(angular.isNumber(obj)){
        idTopic = obj;
      }

      if(idTopic){

        var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: 'app/topics/modal/modal-communication.html',
          controller: 'ModalCommunicationCtrl',
          controllerAs: 'modalcommunicationctrl',
          size: 'full',
          windowClass: 'modal-collabore modal-annuaire-collab',
          backdrop: 'static',
          resolve: {
            idTopic: function() {
              return idTopic;
            }
          }
        });

        modalInstance.finish = function(){
          if(fctOnFinish) {
            fctOnFinish();
          }
        };

        /*
        modalInstance.opened.then(function () {
        });

        modalInstance.rendered.then(function () {
        });
        */

        modalInstance.result.then(function(objRetour) {
          deferred.resolve(objRetour);
        }, function () {
          deferred.reject();
        });
      }else{
        deferred.reject('Pas d\'idTopic !');
      }

      return deferred.promise;
    }

    /**
     * Permet d'ouvrire un modal et charger un component
     * @param component
     * @param params
     * @returns {Promise}
     */
    function modalComponent(component, params, options) {
      var deferred = $q.defer();

      var modal = {
        component: component,
        size: 'lg',
        resolve: {
          params: function () {
            return params;
          }
        }
      };

      if(options && _.isObject(options)) {
        angular.forEach(options, function(val, key) {
          modal[key] = val;
        });
      }

      var modalInstance = $uibModal.open(modal);

      modalInstance
        .result
        .then(function (selectedItem) {
          deferred.resolve(selectedItem);
        }, function (msg) {
          if(!msg) msg = 'Erreur modalComponent';
          deferred.reject(msg);
        })
        .finally(
          // Callback
          function(){

          },
          // NotifyCallback
          function(b){
            console.log(b);
          });

      return deferred.promise;
    }

    /**
     * Permet d'ouvrir une modal pour relancer une demande
     * @param demande
     * @param options
     * @returns {angular.IPromise<T>}
     */
    function modalRelanceDemande(demande, options){
      var deferred = $q.defer(),
        _this = this;

      if(!_.isObject(options)) options = {
        size: 'lg'
      };

      _this
        .modalComponent('relanceDemande', {demande: demande}, options)
        .then(function(obj){
          if(_.isString(obj.action) && obj.action === 'saveRelanceDemande' && _.isObject(obj.demande)) {

            if(_.isObject(obj.demande.getRelanceAutoConfig())) {

              var textAlert = "Relance sauvegardée !";
              if(obj.doRelance) textAlert = "Relance sauvegardée et effectuée !";

              var dateProchaineRelance = obj.demande.getRelanceAutoConfig().getDateProchaineRelance('D MMMM YYYY');
              if(!_.isNil(dateProchaineRelance)) textAlert += "<br/>Prochaine relance le <strong>"+dateProchaineRelance+"</strong>";

              if(_.isObject(obj.demande.getRelanceAutoConfig().getPeriode())) textAlert += "puis <strong>"+obj.demande.getRelanceAutoConfig().getPeriode().getDescription().toLowerCase()+"</strong>";

              _this.success(textAlert);
            }
            deferred.resolve(obj.demande);
          }
          else {
            deferred.reject();
          }
        })
        .catch(function (msg) {
          if(msg !== 'cancel' && msg !== 'backdrop click') {
            _this.alertErreur(msg);
          }
          deferred.reject();
        });
      return deferred.promise;
    }

    /**
     * Dialog de confirmation
     * @returns {IPromise<T>|*|Promise}
     */
    function confirm(){

      var deferred = $q.defer(),
        opts;

      var objParams = {
        title: 'Confirmation',
        text: 'Veuillez confirmer',
        type: 'warning',
        html: true,
        showCancelButton: true,
        confirmButtonColor: '#5AB728',
        confirmButtonText: 'Confirmer',
        cancelButtonText: 'Annuler',
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      };

      if(arguments.length){
        switch(arguments.length){
          // Seulement le message
          case 1:
            objParams.text = arguments[0];
            break;
          // Titre et message
          case 2:
            objParams.title = arguments[0];
            if(_.isString(arguments[1])) {
              objParams.text = arguments[1];
            }
            else if(_.isFunction(arguments[1])) {

            }
            break;
          // Titre, message et options
          case 3:
            objParams.title = arguments[0];
            if(_.isString(arguments[1])) {
              objParams.text = arguments[1];
            }

            if(_.isObject(arguments[2])) {
              opts = arguments[2];
              if(opts.hasOwnProperty('btnConfirmText')) {
                objParams.confirmButtonText = opts.btnConfirmText;
              }
              if(opts.hasOwnProperty('type')) {
                objParams.type = opts.type;
              }
            }
            break;

          // Titre, message, type et options
          case 4:
            objParams.title = arguments[0];
            if(_.isString(arguments[1])) {
              objParams.text = arguments[1];
            }
            if(_.isString(arguments[2])) {
              objParams.type = arguments[2];
            }

            if(_.isObject(arguments[3])) {
              opts = arguments[3];
              if(opts.hasOwnProperty('btnConfirmText')) {
                objParams.confirmButtonText = opts.btnConfirmText;
              }
              if(opts.hasOwnProperty('btnCancelText')) {
                objParams.cancelButtonText = opts.btnCancelText;
              }
              if(opts.hasOwnProperty('type')) {
                objParams.type = opts.type;
                if(objParams.type === 'input') {
                  if(opts.hasOwnProperty('inputValue')) {
                    objParams.inputValue = opts.inputValue;
                  }
                }
              }
            }
            break;
          default:
        }
      }

      sweet.show(objParams, function(isConfirm){
        var obj = {
          close: function(callBack){
            sweet.close();
            if(callBack) {
              callBack();
            }
          }
        };
        if(isConfirm){
          deferred.resolve(obj);
        }else{
          deferred.reject(obj);
        }
      });
      return deferred.promise;
    }

    /**
     * Dialog d'input
     * @returns {IPromise<T>|*|Promise}
     */
    function input(){

      var deferred = $q.defer(), opts;
      var objParams = {
        title: 'Modifier',
        text: '',
        type: 'input',
        html: true,
        showCancelButton: true,
        confirmButtonColor: '#5AB728',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Annuler',
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
        inputValue:''
      };
      if(arguments.length){
        switch(arguments.length){
          // Seulement le message
          case 1:
            objParams.text = arguments[0];
            break;
          // Titre et message
          case 2:
            objParams.title = arguments[0];
            if(_.isString(arguments[1])) {
              objParams.text = arguments[1];
            }
            break;
          // Titre, message et options
          case 3:
            objParams.title = arguments[0];
            if(_.isString(arguments[1])) {
              objParams.text = arguments[1];
            }

            if(_.isObject(arguments[2])) {
              opts = arguments[2];
              if(opts.hasOwnProperty('btnConfirmText')) {
                objParams.confirmButtonText = opts.btnConfirmText;
              }
              if(opts.hasOwnProperty('type')) {
                objParams.type = opts.type;
              }
            }
            break;

          // Titre, message, type et options
          case 4:
            objParams.title = arguments[0];
            if(_.isString(arguments[1])) {
              objParams.text = arguments[1];
            }
            if(_.isString(arguments[2])) {
              objParams.type = arguments[2];
            }

            if(_.isObject(arguments[3])) {
              opts = arguments[3];
              if(opts.hasOwnProperty('btnConfirmText')) {
                objParams.confirmButtonText = opts.btnConfirmText;
              }
              if(opts.hasOwnProperty('btnCancelText')) {
                objParams.cancelButtonText = opts.btnCancelText;
              }
              if(opts.hasOwnProperty('inputValue')) {
                objParams.inputValue = opts.inputValue;
              }
            }
            break;
          default:
        }
      }

      sweet.show(objParams, function(inputValue){
        var obj = {
          sweet: sweet,
          inputValue: inputValue,
          close: function(callBack){
            sweet.close();
            if(callBack) {
              callBack();
            }
          }
        };
        deferred.resolve(obj);
      });
      return deferred.promise;
    }

    /**
     * Alerte avec une popup d'une erreur
     * 0 argument alors message prédéfinis
     * 1 argument = message
     * 2 arguments = titre, message
     * 3 arguments = titre, message, type
     */
    function alert(){

      var titre = 'Oops...',message = 'Erreur !',type = 'info';
      if(arguments.length){
        switch(arguments.length){
          // Seulement le message
          case 1:
            message = arguments[0];
            break;
          // Titre et message
          case 2:
            titre = arguments[0];
            message = arguments[1];
            break;
          // Titre, message et type
          case 3:
            titre = arguments[0];
            message = arguments[1];
            type = arguments[2];
            break;
          default:
        }
      }
      sweet.show({
        title: titre,
        text: message,
        type: type,
        html: true,
        showCancelButton: false,
        closeOnConfirm: true
      });
    }

    /**
     * Alerte avec une popup d'une erreur
     * 0 argument alors message prédéfinis
     * 1 argument = message
     * 2 arguments = titre,message
     */
    function alertErreur(){

      var titre = 'Oops...',message = 'Erreur !';
      if(arguments.length){
        switch(arguments.length){
          // Seulement le message
          case 1:
            if(_.isString(arguments[0])) message = arguments[0];
            else if(_.isObject(arguments[0])){
              if(arguments[0].name === "TypeError" && _.isString(arguments[0].message))
                message = arguments[0].message;
                ErreurCollabService.logErreur(arguments[0]);
            }
            break;
          // Titre et message
          case 2:
            titre = arguments[0];
            message = arguments[1];
            break;
          default:
        }
      }
      sweet.show({
        title: titre,
        text: message,
        type: 'error',
        html: true,
        showCancelButton: false,
        closeOnConfirm: true
      });
    }

    function success(){

      var titre = 'Terminé !', message = null;
      if(arguments.length){
        switch(arguments.length){
          // Seulement le message
          case 1:
            message = arguments[0];
            break;
          // Titre et message
          case 2:
            titre = arguments[0];
            message = arguments[1];
            break;
          default:
        }
      }
      sweet.show({
        title: titre,
        text: message,
        type: 'success',
        html: true,
        showCancelButton: false,
        confirmButtonText: 'Ok',
        closeOnConfirm: true
      });
    }

    function info(){

      var titre = 'Information',message = 'Terminé !';
      if(arguments.length){
        switch(arguments.length){
          // Seulement le message
          case 1:
            message = arguments[0];
            break;
          // Titre et message
          case 2:
            titre = arguments[0];
            message = arguments[1];
            break;
          default:
        }
      }
      sweet.show({
        title: titre,
        text: message,
        type: 'info',
        html: true,
        showCancelButton: false,
        confirmButtonText: 'Ok',
        closeOnConfirm: true
      });
    }

    function loading(suiteText){
      var text = '<div class="loadingMiddle center-block"></div>';
      if(suiteText) {
        text += '<div>'+suiteText+'</div>';
      }
      sweet.show({
        title: 'Veuillez patienter...',
        text: text,
        type: 'info',
        html: true,
        showConfirmButton: false,
        showCancelButton: false/*,
                allowOutsideClick: false*/
      });

      return sweet;
    }
  }
})();
