/**
 * Created by AndyDev on 18/09/2015.
 */
/* eslint-disable */
function process(myData) {

  //var DocumentManagerService = angular.injector(['collaboreApp']).get('DocumentManagerService');

  var retour = null;
  for(var i = 0; i < 100000; i++){
    retour = 'toto';
    i++;
  }
  return retour;
  /*
    var filtered = [];

    for(indexUser in myData.users){
        var user = myData.users[indexUser];

        var reg = new RegExp(myData.search,'ig');
        if(reg.test(user.libelle)){
            filtered.push(user);
        }
    }
    console.log(filtered);
    return filtered;*/


}/*
self.addEventListener('message', function(e) {
  var obj = JSON.parse(e.data);
  console.log(obj);
  self.postMessage(process(obj.myData));
}, false);*/
/* eslint-enable */
