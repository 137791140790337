(function() {

  'use strict';

  /**
   * @desc Composant de la Mailbox
   * @example <layout-mailbox></layout-mailbox>
   */
  angular
    .module('collaboreApp')
    .component('layoutMailbox',{
      templateUrl: 'app/mailbox/layout-mailbox.component.html',
      controllerAs: 'layoutmailboxctrl',
      controller: LayoutMailboxCtrl,
      bindings: {
        idMailbox: '<'
      }
    });

  /** @ngInject */
  function LayoutMailboxCtrl(){
    var _this = this;

    _this.$onInit = function() {
    };
  }
})();
