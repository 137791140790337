'use strict';

/**
 * @ngdoc service
 * @name collaboreApp.Fournisseur
 * @description
 * # Fournisseur
 * Service in the collaboreApp.
 */

angular.module('collaboreApp').factory('Fournisseur',[
  'Main',
	'Personnalite',
	function(Main, Personnalite) {

		function Fournisseur(objetData) {

			Personnalite.apply(this, arguments);

			if (objetData) {
				this.setData(objetData);
			}
		}

		Fournisseur.prototype = {

			isPersoFournisseur: true,

			setData: function(objetData) {
				angular.extend(this, objetData);
			},



			// Notel1s
			getNotel1s: function(){
				return this.notel1s;
			},
			setNotel1s: function(val){
				this.notel1s = val;
			},
			// Notel2s
			getNotel2s: function(){
				return this.notel2s;
			},
			setNotel2s: function(val){
				this.notel2s = val;
			},

			// Nofaxs
			getNofaxs: function(){
				return this.nofaxs;
			},
			setNofaxs: function(val){
				this.nofaxs = val;
			},

			// compte
			getCompte: function(){
				return this.compte;
			},
			setCompte: function(val){
				this.compte = val;
			},

			// compagnie
			getCompagnie: function(){
				return this.compagnie;
			},
			setCompagnie: function(val){
				this.compagnie = val;
			},

			// com2
			getCom2: function(){
				return this.com2;
			},
			setCom2: function(val){
				this.com2 = val;
			},

			// cdreglG
			getCdreglG: function(){
				return this.cdreglG;
			},
			setCdreglG: function(val){
				this.cdreglG = val;
			},

			// cdreglC
			getCdreglC: function(){
				return this.cdreglC;
			},
			setCdreglC: function(val){
				this.cdreglC = val;
			},


			// regroupeG
			getRegroupeG: function(){
				return this.regroupeG;
			},
			setRegroupeG: function(val){
				this.regroupeG = val;
			},

			// regroupeC
			getRegroupeC: function(){
				return this.regroupeC;
			},
			setRegroupeC: function(val){
				this.regroupeC = val;
			},

			// bordVir
			getBordVir: function(){
				return this.bordVir;
			},
			setBordVir: function(val){
				this.bordVir = val;
			},

			// codeAdrPerso
			getCodeAdrPerso: function(){
				return this.codeAdrPerso;
			},
			setCodeAdrPerso: function(val){
				this.codeAdrPerso = val;
			},

      //Contrats
      getContrats: function () {
        return this.contrats;
      },
      setContrats: function (val) {
        var list = [];
        if (_.isArray(val)) {
          var Contrat = Main.$injector.get("Contrat");
          for (var i = 0; i < val.length; i++) {
            var currentContrat = val[i];
            if (_.isObject(val[i]) && !val[i].isModel) {
              currentContrat = new Contrat(val[i]);
            }
            list.push(currentContrat);
          }
          Contrat = null;
        }
        this.contrats = list;
      },
      addContrat: function (contrat) {
        if (
          !_.isObject(contrat) ||
          _.findIndex(this.contrats, { refCon: contrat.refCon }) !== -1
        )
          return;

        var currentContrat = angular.copy(contrat);
        delete currentContrat.persofournisseur;

        if (!currentContrat.isModel) {
          var Contrat = Main.$injector.get("Contrat");
          currentContrat = new Contrat(currentContrat);
        }
        if(!_.isArray(this.contrats)) this.contrats = [];
        this.contrats.push(currentContrat);
      }



		};

		angular.extend(Fournisseur.prototype, Personnalite.prototype);

        Fournisseur.prototype.model = 'Fournisseur';

		return Fournisseur;

	}
]);
