(function() {

    'use strict';

    /**
     * @desc Componsant permetant de récupérer des infos tel que email ou numéro de tel
     * @example <choose-infos-contact></choose-infos-contact>
     */
    angular
        .module('collaboreApp')
        .component('chooseInfosContact',{
            templateUrl: 'app/components/choose-infos-contact/choose-infos-contact.component.html',
            bindings : {
                defaultPersoExpanded: '<', // Permet d'ouvrir ou fermer l'accordion des personnalitées ICS
                searchPersoForGroupeCollab: '<', // Permet de chercher les perso pour les groupe collab destinataire
                type: '<',              // Type 'sms' ou 'email'
                listeEmail: '<',        // Liste de mail mise à jour depuis le parent, elle permet de cocher/decocher les mails du composant
                allowCustom: '<',       // Permet d'ajouter des email libre
                destinataire: '<',      // Destinataire, peut être un Groupe, PersoICS, Perso ou String email ou une liste
                portefeuille: '<',      // Portefeuille, sera utilisé pour chercher des infos
                onEmit: '&',            // Permet de notifier le composant parent

                // Si Modal
                resolve: '<',
                close: '&',
                dismiss: '&'
            },
            controllerAs: 'chooseinfoscontactctrl',
            controller: ChooseInfosContactController
        });

    /*@ngInject*/
    function ChooseInfosContactController($injector, $q, GroupeService, AnnuaireService, ProxyPersoIcs, UtilsService, ModalsService, uuid){

        var _this = this,
            promisesSearch = [];

        _this.perso = [];            // Liste des perso ICS

        _this.mailCustom = null;

        var listCheched = [];           // Liste des email coché
        _this.listeMailCustom = [];     // Liste des email custom
        _this.listeAllMail = [];        // Liste de tous les emails (coché et custom)


        _this.currentGroupeCollab = null;   // Objet pour la recherche d'un gorupe collab


        _this.onMailChecked = onMailChecked;
        _this.onSubmitCustomMail = onSubmitCustomMail;
        _this.onSubmitAddCustomMailToPerso = onSubmitAddCustomMailToPerso;
        _this.onClickRemoveCustomMailFromPerso = onClickRemoveCustomMailFromPerso;
        _this.onClickRemoveCustom = onClickRemoveCustom;
        _this.onClickSave = onClickSave;
        _this.onClickClose = onClickClose;

        /**
         * TODO: Faire les set des variables par rapport au différent model
         */
        _this.$onInit = function() {
            if(_this.resolve) {
                _this.isModal = true;
                _this.defaultPersoExpanded = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'defaultPersoExpanded'));
                _this.searchPersoForGroupeCollab = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'searchPersoForGroupeCollab'));
                _this.type = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'type'));
                _this.listeEmail = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'listeEmail'));
                _this.allowCustom = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'allowCustom'));
                _this.destinataire = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'destinataire'));
                _this.portefeuille = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'portefeuille'));
            }
            if(_.isNil(_this.destinataire)) {
                ModalsService.alertErreur('Pas de destinataire');
            }
            /*
            var noperso = null,
                portefeuille = null;*/

            if(_.isNil(_this.defaultPersoExpanded)) {
                _this.persoExpanded = true;
            }
            else {
                _this.persoExpanded = angular.copy(_this.defaultPersoExpanded);
            }

            if(!_this.type)
                _this.type = 'email';

            // Init le destinataire
            initDestinataire(_this.destinataire);

            // Init depuis une liste de mail, c'est elle qui est mise à jour depuis le parent
            initFromListeEmail(_this.listeEmail);

            //emitListeEmail();
            // Si pas de groupecollab noperso et portefeuille ok alors on recherche le groupe Collab
            //if(!_this.groupeCollab && noperso && portefeuille) findGroupeCollab(noperso, portefeuille);
        };

        /**
         * Lors de changements depuis le parent
         * @param changes
         */
        _this.$onChanges = function (changes) {
            if(angular.isObject(changes)) {
                if(angular.isObject(changes.listeEmail) && !changes.listeEmail.isFirstChange()) {
                    // Init depuis une liste de mail, c'est elle qui est mise à jour depuis le parent
                    initFromListeEmail(changes.listeEmail.currentValue);
                }
            }
        };

        _this.$onDestroy = function(){
        };

        /**
         * Init le destinataire par rapport à sont type
         */
        function initDestinataire(destinataire){
            // Si destinataire est un array
            if(_.isArray(destinataire)) initArray(destinataire);

            // Si c'est un objet, tester les différent model
            else if(_.isObject(destinataire)) initModel(destinataire);

            // Si c'est un string (pourquoi faire ? libelleDiese peut être ? ou mail et tel mais vraiment utile...)
            else if(_.isString(destinataire)) initString(destinataire);
        }

        /**
         * Si le destinataire est un Array alors teste chaque itération pour savoir si c'est un Object ouString
         * @param arr
         */
        function initArray(arr){
            if(!arr.length) ModalsService.alertErreur('Pas de destinataire dans la liste');
            for(var i = 0; i < _this.destinataire.length; i++){
                if(_.isObject(_this.destinataire[i])) initModel(_this.destinataire[i]);
                else if(_.isString(_this.destinataire[i])) initString(_this.destinataire[i]);
            }
        }

        /**
         * Si le destinataire est un Object
         * @param dest
         */
        function initModel(dest){
            if(!dest.isModel) {
                ModalsService.alertErreur('Le destinataire doit être un model');
            }
            if(dest.model === 'Groupe' && dest.idGroupe && !dest.itsMe()) {
                var deferred = $q.defer();


                _this.loadingInfosCollab = true;


                GroupeService
                    .getInfosGroupe(dest.idGroupe)
                    .then(function (groupeFind) {

                        //console.log(groupeFind.getEmails());
                        _this.currentGroupeCollab = {
                            idGroupe: groupeFind.getIdGroupe(),
                            libelle: groupeFind.getLibelle(),
                            diese: groupeFind.getDieze(),
                            nom: groupeFind.getDefaultLibelle(),
                            libelleDiese: groupeFind.getLibelleDieze(),
                            groupe: groupeFind,
                            emails: groupeFind.getEmails().map(function(renseignementEmail){
                                var valid = (UtilsService.isMail(renseignementEmail.getDonnee()));
                                if(valid) {
                                    listCheched.add(renseignementEmail.getDonnee());
                                    _this.groupeCollabExpanded = true;
                                }
                                return {
                                    // On check si le mail est valide est qu'il n'y a qu'un email. Ou Si il y en a plusieur on check celui qu'on ne peux pas delete qui doit être celui de la fiche perso
                                    checked: (valid && (groupeFind.getEmails().length === 1 || (groupeFind.getEmails().length > 1 && !renseignementEmail.canDelete))),
                                    valid: valid,
                                    mail: renseignementEmail.getDonnee(),
                                    isCustom: false,
                                    groupes: [groupeFind],
                                    loading: false
                                };
                            })
                        };

                        deferred.resolve(groupeFind);

                        // Si l'option de recherche des perso pour un groupe collabe est à true
                        if(_this.searchPersoForGroupeCollab) {
                            searchPersoForGroupeCollab(groupeFind);
                        }

                    })
                    .catch(function (msg) {
                        deferred.reject(msg);
                    })
                    .finally(function(){
                        _this.loadingInfosCollab = false;
                    });

                promisesSearch.push(deferred.promise);
            }
            else if(dest.model === 'PersoIcs') {

                var objetPersoICS       = getDefaultPersoICS();
                objetPersoICS.persoICS  = dest;
                objetPersoICS.noperso   = dest.getNoperso();
                objetPersoICS.nom       = dest.getLibelle();
                objetPersoICS.metier    = dest.getCodemetier();
                objetPersoICS.numero    = dest.getNumero();
                objetPersoICS.adresse   = dest.getAdresse();
                objetPersoICS.open      = true;

                setListEmailForPersoICS(dest, objetPersoICS);
                /*
                if(_this.type === 'email') {
                    _this.loadingPerso = true;
                    objetPersoICS.emails    = [];

                    if(dest.getEmails().length) {
                        objetPersoICS.emails    = dest.getEmails().map(function(mail) {
                            var valid = true;

                            // Si le mail n'est pas un email valide
                            if(!UtilsService.isMail(mail)) valid = false;

                            var retour = {
                                checked: false,
                                valid: valid,
                                mail: mail,
                                isCustom: false,
                                groupes: [],
                                loading: true
                            };

                            var deferred = $q.defer();
                            GroupeService
                            //.searchListGroupeWithoutPortefeuilleForProxyPersoICS(angular.toJson(new ProxyPersoIcs(dest)))
                                .searchListGroupeWithEmailAndWithoutPortefeuille(mail)
                                .then(function(groupes){
                                    if(groupes.length) {

                                        // Pour chaque groupes recup
                                        groupes.map(function(groupe){
                                            // Si l'user du gorupe a un email et qu'il est le même que l'email de la persoICS actuel alors on ajoute le groupe à sa liste de groupe
                                            if(_.isObject(groupe.getUser()) && groupe.getUser().getEmail() && groupe.getUser().getEmail() === retour.mail)
                                                retour.groupes.push(angular.copy(groupe));
                                        });
                                    }
                                })
                                .finally(function(){
                                    retour.loading = false;
                                    deferred.resolve();
                                });

                            promisesSearch.push(deferred.promise);

                            return retour;
                        });
                    }
                }
                else if(_this.type === 'sms')
                    objetPersoICS.telephonesPortable    = dest.getTelephonesPortable();*/


                // Si type sms et qu'il y a des promesses
                if(_this.type === 'sms' && promisesSearch.length)
                    _this.loading = true;




                _this.perso.push(objetPersoICS);

                // Recup le portefeuille
                //portefeuille = dest.getPortefeuille();
            }

            $q.all(promisesSearch).finally(function(){
                if(_this.type === 'sms') {
                    _this.loading = false;
                }
                else if(_this.type === 'email') {
                    if(dest.model === 'Groupe' && dest.idGroupe && !dest.itsMe()) {
                        if(_this.defaultPersoExpanded && !_this.perso.length) {
                            _this.persoExpanded = false;
                        }
                        emitListeEmail();
                        /*
                        console.log(_this.persoAccordion);
                        if(!_this.perso.length) {
                            _this.persoAccordion.collapse('personnalitesICS');
                        }*/
                    }
                    else if(dest.model === 'PersoIcs') {
                        GroupeService
                            .searchListGroupeWithoutPortefeuilleForProxyPersoICS(angular.toJson(new ProxyPersoIcs(dest)))
                            .then(function(groupes){
                                if(groupes.length && _this.perso.length) {

                                    // Pour chaque groupes recupéré par rapport à la perso ICS
                                    groupes.map(function(groupe){
                                        // Si l'user du gorupe a un email et qu'il est le même que l'email de la persoICS actuel alors on ajoute le groupe à sa liste de groupe
                                        if(_.isObject(groupe) && groupe.getEmail()){

                                            // Si il y a des perso alors on les parcours
                                            _this.perso.map(function(perso){
                                                var emailExist = null;
                                                var groupeExist = false;
                                                // Si la perso a bien des emails
                                                if(perso.emails.length) {
                                                    // Parcours les email de la perso pour verifier que cette perso n'est pas déjà dans la liste
                                                    for(var i = 0; i < perso.emails.length; i++) {

                                                        var objMail = perso.emails[i];

                                                        // Si la perso est trouvé ont la garde dans une variable
                                                        if(objMail.mail === groupe.getEmail()) {
                                                            emailExist = objMail;
                                                            // Si elle a des groupes
                                                            if(objMail.groupes.length) {

                                                                // Parcour ses groupes pour vérifier si celui actuel exist
                                                                for(var g = 0; g < objMail.groupes.length; g++){
                                                                    // Si il existe on sort
                                                                    if(objMail.groupes[g].idGroupe === groupe.idGroupe) {
                                                                        groupeExist = true;
                                                                        break;
                                                                    }
                                                                }
                                                            }
                                                            break;
                                                        }
                                                    }
                                                }
                                                // Si l'email n'existe pas alors on ajoute un objet email
                                                if(_.isNull(emailExist)) {
                                                    var valid = true;
                                                    // Si le mail n'est pas un email valide
                                                    if(!UtilsService.isMail(groupe.getEmail())) valid = false;

                                                    var objMail = {
                                                        checked: false,
                                                        valid: valid,
                                                        mail: groupe.getEmail(),
                                                        isCustom: false,
                                                        groupes: [groupe],
                                                        loading: false
                                                    };
                                                    perso.emails = _.union(perso.emails, [objMail]);
                                                }

                                                // Si le groupe n'existe pas on l'ajoute à l'objet email trouvé
                                                if(!groupeExist && !_.isNull(emailExist)){
                                                    emailExist.groupes = _.union(emailExist.groupes, [groupe]);
                                                }
                                            });
                                        }
                                    });
                                }
                            })
                            .finally(function(){
                                _this.loadingPerso = false;
                                if(_this.defaultPersoExpanded && !_this.perso.length) {
                                    _this.persoExpanded = false;
                                }
                            });
                    }
                }
            });
        }

        function setListEmailForPersoICS(persoICS, persoICSCustom){
            if(_this.type === 'email') {
                _this.loadingPerso = true;
                persoICSCustom.emails    = [];

                if(persoICS.getEmails().length) {
                    persoICSCustom.emails    = persoICS.getEmails().map(function(mail) {
                        var valid = true;

                        // Si le mail n'est pas un email valide
                        if(!UtilsService.isMail(mail)) valid = false;

                        var retour = {
                            checked: false,
                            valid: valid,
                            mail: mail,
                            isCustom: false,
                            groupes: [],
                            loading: true
                        };

                        var deferred = $q.defer();
                        GroupeService
                        //.searchListGroupeWithoutPortefeuilleForProxyPersoICS(angular.toJson(new ProxyPersoIcs(dest)))
                            .searchListGroupeWithEmailAndWithoutPortefeuille(mail)
                            .then(function(groupes){
                                if(groupes.length) {

                                    // Pour chaque groupes recup
                                    groupes.map(function(groupe){
                                        // Si l'user du gorupe a un email et qu'il est le même que l'email de la persoICS actuel alors on ajoute le groupe à sa liste de groupe
                                        if(_.isObject(groupe) && groupe.getEmail() === retour.mail)
                                            retour.groupes.push(angular.copy(groupe));
                                    });
                                }
                            })
                            .finally(function(){
                                retour.loading = false;
                                deferred.resolve();
                            });

                        promisesSearch.push(deferred.promise);

                        return retour;
                    });
                }
            }
            else if(_this.type === 'sms')
                persoICSCustom.telephonesPortable    = persoICS.getTelephonesPortable();


        }

        /**
         * Si le destinataire est un String
         * @param dest
         */
        function initString(dest) {
            // TODO: A faire si libelleDiese
        }

        /**
         * Initisalisation depuis une liste d'email
         * @param listeEmail
         */
        function initFromListeEmail(listeEmail){
            if(listeEmail && angular.isArray(listeEmail)) {

                listCheched = [];               // Reset la liste des mail venant d'une case coché
                _this.listeMailCustom = [];     // Resete la liste des mail custom
                // Uncheck les emails venant des perso
                _this.perso.map(function(objPerso) {
                    objPerso.emails.map(function(objMail) {
                        objMail.checked = false;
                    });
                });

                _.forEach(listeEmail, function(value) {
                    checkEmails(value, true);
                });
            }
        }

        /**
         * Permet de récupérer un objet vide pour une perso ics
         * @returns {{libelle: null, nom: null, metier: null, codeLib: null, numero: null, adresse: null, emails: Array}}
         */
        function getDefaultPersoICS(){
            return {
                uuid: uuid.new(),
                libelle: null,
                nom: null,
                metier: null,
                codeLib: null,
                numero: null,
                adresse: null,
                emails: []
            };
        }

        /**
         * Permet de récupèrer une liste de perso avec des email coché
         * @returns {Array}
         */
        function getListPersoWithMailChecked(){
            var tmpListPerso = [];
            _this.perso.map(function(objPerso) {
                var mails = [];
                objPerso.emails.map(function(objMail) {
                    if(objMail.checked) mails.push(angular.copy(objMail));
                });
                if(mails.length) {
                    var tmpPerso = angular.copy(objPerso);
                    tmpPerso.emails = mails;
                    tmpListPerso.push(tmpPerso);
                }
            });

            if(_.isObject(_this.currentGroupeCollab)) {
                var mails = [];
                var tmpGroupeCollab = angular.copy(_this.currentGroupeCollab);
                _this.currentGroupeCollab.emails.map(function(objMail) {
                    if(objMail.checked) mails.push(angular.copy(objMail));
                });
                if(mails.length) {
                    tmpGroupeCollab.emails = mails;
                    tmpListPerso.push(tmpGroupeCollab);
                }
            }

            return tmpListPerso;
        }

        /**
         * Permet d'emit la liste des email
         */
        function emitListeEmail(){
            // console.log(listCheched);
            _this.listeAllMail = _.concat([], _this.listeMailCustom, listCheched);
            emit({
                listeEmail: _this.listeAllMail,
                listeMailCustom: _this.listeMailCustom,
                listePerso: getListPersoWithMailChecked()
            });
        }

        /**
         * Permet d'emit vers composant parent
         * @param objRetour
         */
        function emit(objRetour){
            if(_this.isModal) _this.close({$value: objRetour});
            else _this.onEmit({obj: objRetour})
        }

        /**
         * Lorsqu'un email est coché/décoché
         * @param objMail
         */
        function onMailChecked(objMail) {
            checkEmails(objMail.mail, objMail.checked);
            emitListeEmail();
        }

        /**
         * Permet d'ajouter un email à la liste d'email du perso
         * @param perso
         */
        function onSubmitAddCustomMailToPerso(perso) {

            if(perso.mailCustom === '' || angular.isUndefined(perso.mailCustom)) return;
            if(!UtilsService.isMail(perso.mailCustom)) {
                ModalsService.alertErreur(perso.mailCustom + ' n\'est pas un email valid !');
                return;
            }
            var exist = false;
            if(perso.emails.length) {
                for(var i =0; i < perso.emails.length; i++) {
                    if(perso.emails[i].mail === perso.mailCustom) {
                        exist = true;
                        break;
                    }
                }
            }

            if(exist){
                checkEmails(perso.mailCustom, true);
                emitListeEmail();
                perso.mailCustom = null;
                return;
            }

            var objMail = {
                checked: true,
                valid: true,
                mail: angular.copy(perso.mailCustom),
                isCustom: true,
                groupes: [],
                loading: true
            };
            perso.mailCustom = null;
            perso.emails.push(objMail);
            checkEmails(objMail.mail, true);

            // Recherche des groupes collab pour l'email
            GroupeService
                .searchListGroupeWithEmailAndWithoutPortefeuille(objMail.mail)
                .then(function(groupes){
                    objMail.groupes = angular.copy(groupes);
                })
                .finally(function(){
                    emitListeEmail();
                    objMail.loading = false;
                });

            /*
           _this.loadingAddCustomEmailForPerso = true;
            GroupeService
                .searchListGroupeWithEmailAndWithoutPortefeuille(perso.mailCustom)
                .then(function(groupes){

                    var objMail = {
                        checked: true,
                        valid: true,
                        mail: perso.mailCustom,
                        isCustom: true,
                        groupe: null
                    };

                    if(groupes.length)
                        objMail.groupe = angular.copy(groupes[0]);

                    perso.emails.push(objMail);

                })
                .finally(function(){
                    checkEmails(perso.mailCustom, true);
                    emitListeEmail();
                    perso.mailCustom = null;
                    _this.loadingAddCustomEmailForPerso = false;
                });*/
        }

        /**
         * Permet de retirer un email custom de la liste des emails custom de la perso
         * @param objMail
         * @param perso
         */
        function onClickRemoveCustomMailFromPerso(objMail, perso) {
            checkEmails(objMail.mail, false);
            emitListeEmail();
            /*
            if(_.isArray(perso.emails)) {
                perso.emails = _.remove(perso.emails, function(objetMail) {
                    return (!_.isEqual(objMail, objetMail));
                });
            }*/
        }

        /**
         * Permet de checker les différentes liste pour cocher un éventuel mail identique
         * @param mail
         * @param checked
         */
        function checkEmails(mail, checked){
            var found = false;
            var copyMail = angular.copy(mail);
            _this.perso.map(function(objPerso) {
                objPerso.emails.map(function(objMail) {
                    if(objMail.mail === copyMail) {
                        objMail.checked = checked;
                        found = true;
                    }
                });
            });

            if(_.isObject(_this.currentGroupeCollab)) {
                _this.currentGroupeCollab.emails.map(function(objMail) {
                    if(objMail.mail === copyMail) {
                        objMail.checked = checked;
                        found = true;
                    }
                });
            }

            if(found){
                if(checked) listCheched.add(copyMail);
                else listCheched.remove(copyMail);
            }
            else {
                if(checked) _this.listeMailCustom.add(copyMail);
                else _this.listeMailCustom.remove(copyMail);
            }
        }

        /**
         * Permet d'ajouter un numéro de téléphone dans la liste des checked
         * @param tel
         */
        function addPhoneNumber(tel){
            // TODO: Retirer les . et espace avant de l'ajouter, faire la méthode removePhoneNumber
            //listCheched.add(mail);
        }

        /**
         * Permet de chercher les perso ics d'un gorupe collab et les ajoute à la liste des perso
         * @param groupe
         */
        function searchPersoForGroupeCollab(groupe){
            _this.loadingPerso = true;

            // Recupère les infos perso des portefeuilles
            GroupeService
                .getInfosPersonnalite(groupe, true, false)
                .then(function(arrayPortefeuille){

                    if(_.isArray(arrayPortefeuille)) {

                        var PersoIcsModel = $injector.get('PersoIcs');
                        for(var p = 0; p < arrayPortefeuille.length; p++) {

                            var portefeuille = arrayPortefeuille[p];

                            if(_.isArray(portefeuille.listePersonnalite) && portefeuille.listePersonnalite.length) {

                                for(var p2 = 0; p2 < portefeuille.listePersonnalite.length; p2++) {

                                    var perso = portefeuille.listePersonnalite[p2];
                                    var objetPersoICS       = getDefaultPersoICS();

                                    var persoICS = new PersoIcsModel(perso);
                                    persoICS.setPersoIcs(perso);
                                    persoICS.setPortefeuille(portefeuille);

                                    objetPersoICS.persoICS  = persoICS;
                                    objetPersoICS.noperso   = persoICS.getNoperso();
                                    objetPersoICS.nom       = persoICS.getLibelle();
                                    objetPersoICS.metier    = persoICS.getCodemetier();
                                    objetPersoICS.numero    = persoICS.getNumero();
                                    objetPersoICS.adresse   = persoICS.getAdresse();
                                    setListEmailForPersoICS(persoICS, objetPersoICS);

                                    if(objetPersoICS.emails.length) {
                                        objetPersoICS.open      = true;
                                    }
                                    else {
                                        objetPersoICS.hide      = true;
                                    }
                                    _this.perso.push(objetPersoICS);
                                }

                            }
                        }
                        PersoIcsModel = null;
                    }
                })
                .finally(function(){
                    _this.loadingPerso = false;
                });
        }
        /*
        function findGroupeCollab(noperso, portefeuille){
            _this.loadingInfosCollab = true;

            AnnuaireService
                .getGroupeCollab(noperso, portefeuille)
                .then(function (retourRecherche) {
                    console.log(retourRecherche);
                })
                .finally(function() {
                    _this.loadingInfosCollab = false;
                });
        }*/

        /**
         * Lors du submit du mail custom
         */
        function onSubmitCustomMail() {
            if(!UtilsService.isMail(_this.mailCustom)) {
                ModalsService.alertErreur(_this.mailCustom + ' n\'est pas un email valid !');
                return;
            }
            checkEmails(_this.mailCustom, true);
            emitListeEmail();
            _this.mailCustom = null;
        }

        /**
         * Permet de retirer l'email custom de la liste
         * @param custom
         */
        function onClickRemoveCustom(custom) {
            checkEmails(custom, false);
            emitListeEmail();
        }

        /**
         * Lors du clique sur le bouton pour envoyer le message
         */
        function onClickSave(){
            emit();
        }

        /**
         * Fermeture du composant si en mode modal
         */
        function onClickClose(){
            _this.dismiss({$value: 'cancel'});
        }
    }
})();
