(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name collaboreApp.InfosCloture
     * @description
     * # InfosCloture
     * Service in the collaboreApp.
     */

    angular.module('collaboreApp').factory('InfosCloture', InfosClotureModel);

    /** @ngInject */
    function InfosClotureModel(Main, Groupe, UtilsService) {

        var InfosCloture = function(objetData) {
            Main.apply(this, arguments);
            this.hydrate(objetData);
        };

        InfosCloture.prototype = {

            // idInfosCloture
            getIdInfosCloture: function(value){
                if (arguments.length) this.setIdInfosCloture(value);
                else return this.idInfosCloture;
            },
            setIdInfosCloture: function(val){
                this.idInfosCloture = val;
            },

            // raison
            getRaison: function(value){
                if (arguments.length) this.setRaison(value);
                else return (!_.isNil(this.raison)) ? UtilsService.nl2br(this.raison) : null;
            },
            setRaison: function(val){
                this.raison = val;
            },

            // visibleByFournisseur
            getVisibleByFournisseur: function(value){
                if (arguments.length) this.setVisibleByFournisseur(value);
                else return this.visibleByFournisseur;
            },
            setVisibleByFournisseur: function(val){
                this.visibleByFournisseur = val;
            },

            // groupe
            getGroupe: function(value){
                if (arguments.length) this.setGroupe(value);
                else return this.groupe;
            },
            setGroupe: function(val){
                if(_.isObject(val)) {
                    if(!val.isModel) this.groupe = new Groupe(val);
                    else this.groupe = val;
                }
            },

            getDateCloture: function(format){
                if(!_.isNil(format)) {
                    if(!_.isString(format) && format){
                        format = null;
                    }
                    return UtilsService.parseDate(this.dateCloture, format);
                }
                return this.dateCloture;
            },
            setDateCloture: function(val){
                this.dateCloture = val;
            }
        };

        angular.extend(InfosCloture.prototype, Main.prototype);

        InfosCloture.prototype.model = 'InfosCloture';

        return InfosCloture;
    }
})();
