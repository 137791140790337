(function() {

  'use strict';

  /**
   * @desc Composant annuaire
   * @example <annuaire></annuaire>
   */
  angular
    .module('collaboreApp')
    .component('ligneFiche',{
      bindings: {
        nom: '<',
        obj: '<',
        groupe: '<',
        idFiche: '<',
        onUpdate: '&'
      },
      templateUrl: 'app/fiche/ligne-fiche.component.html',
      controllerAs: 'lignefichectrl',
      controller: LigneFicheCtrl
    });

  /*@ngInject*/
  function LigneFicheCtrl($rootScope, FichesService, UtilsService, ModalsService){
    var ctrl = this;
    ctrl.isMyFiche = false;
    ctrl.isGroupePersoIcsFromMySociete = false;

    ctrl.showRemove = showRemove;
    ctrl.hideRemove = hideRemove;
    ctrl.onFocusChamp = onFocusChamp;
    //ctrl.onBlurChamp = onBlurChamp;
    //ctrl.onKeyUpChamp = onKeyUpChamp;
    ctrl.onChangeSelect = onChangeSelect;
    ctrl.onSaveChamp = onSaveChamp;
    ctrl.onDeleteChamp = onDeleteChamp;

    ctrl.$onInit = function(){
      if(ctrl.groupe) {
        // Si l'user à modifié est le meme que celui qui modifie la fiche
        if(ctrl.groupe.idGroupe === UtilsService.getCurrentUser().getIdGroupe()) ctrl.isMyFiche = true;
        // Si le groupe modifié est un groupe Perso ICS de la société du groupe qui modifie la fiche
        else if(ctrl.groupe.getGroupeInformations()) ctrl.isGroupePersoIcsFromMySociete = ctrl.groupe.getGroupeInformations().getIsGroupePersoIcsFromMySociete();
      }
    };


    /**
     * Permet d'afficher ou non la croix pour supprimer le champ
     * @param champ
     */
    function showRemove(champ){
      champ.displayClean = false;
      if(champ.value !== ''){
        champ.displayClean = true;
      }
    }

    /**
     * Permet de cacher la croix d'un champ
     * @param champ
     */
    function hideRemove(champ){
      champ.displayClean = false;
    }


    /*
            function addChamp() {

                var retour,
                    categorie,
                    renseignement = false;

                if(arguments.length) {

                    var valueReference = 0, valueState = 0, valueRenseignement = '',isMyEmailCollab = false;

                    angular.forEach(arguments, function (argument) {
                        // Si c'est un objet
                        if (angular.isObject(argument)) {

                            if (argument.isRenseignement) {
                                renseignement = argument;

                            }else if (argument.isMyEmailCollab) {
                                valueRenseignement = argument.value;
                                valueState = argument.state;
                                valueReference = argument.reference;
                                isMyEmailCollab = true;
                            }

                            // Si string alors categorie
                        } else if (angular.isString(argument)) {
                            categorie = argument;
                        }
                    });



                    // Si il y a une reference en argument alors on réupère l'id comme valeur
                    if (renseignement) {


                        valueReference = renseignement.getReference().getIdReference();
                        valueRenseignement = renseignement.getDonnee();

                        // Si c'est ma fiche
                        if(ctrl.isMyFiche){
                            if(renseignement.hasState()){
                                valueState = renseignement.getState().getIdState();
                            }
                        }else{
                            valueState = 2;
                        }

                    }

                    if(categorie){
                        var placeholder = '';
                        switch (categorie) {
                            case 'nom':
                                placeholder = 'Ajouter un nom';
                                break;
                            case 'prenom':
                                placeholder = 'Ajouter un prénom';
                                break;
                            case 'telephone':
                                placeholder = 'Ajouter un numéro de téléphone';
                                break;
                            case 'fax':
                                placeholder = 'Ajouter un numéro de fax';
                                break;
                            case 'commentaire':
                                placeholder = 'Ajouter un commentaire';
                                break;
                            case 'email':
                                placeholder = 'Ajouter un email';
                                break;
                        }

                        var displayReference = false,
                            displayState = false;
                        if(categorie === 'nom' || categorie === 'prenom' || valueRenseignement){
                            displayReference = true;
                            displayState = true;
                        }

                        retour = {
                            'placeholder': placeholder,
                            'renseignement': renseignement,
                            'isMyEmailCollab': isMyEmailCollab,
                            'reference': {
                                'categorie': categorie,
                                'typeChamp': (getListeReference(categorie).length > 1) ? 'select' : 'text',
                                'liste': getListeReference(categorie),
                                'value': valueReference,
                                'show': displayReference
                            },
                            'state': {
                                'type': 3,
                                'liste': getListeState(3),
                                'value': valueState,
                                'show': displayState
                            },
                            'valueType': 'textarea',
                            'displayClean': false,
                            'disabled': false,
                            'name': uuid.new(),
                            'value': valueRenseignement
                        };
                    }
                }
                return retour;

            }
            */
    function saveChamp(champ,formInfos){
      // Si le champ à été changé
      if(formInfos && !formInfos[champ.name].$dirty)
        return;

      if(champ.tmpValue !== ''){

        var objParams,
          newRenseignement = false,
          idFiche = ctrl.idFiche,
        idState = champ.state.value;
        champ.disabled = true;

        // Si sans renseignement
        if(!champ.renseignement)
          newRenseignement = true;

        // Sinon si il y a un renseignement mais que ce n'est pas ma fiche
        // Alors on crée un nouveau renseignement en privé
        else if(!ctrl.isMyFiche){
          // Si champ avec un renseignement mais public
          // Alors on crée un nouveau renseignement en privé
          //if(champ.renseignement.isPublic){

          //}

          // si ce renseignement n'a pas l'attribut isPrive à true alors c'est un nouveau à crééer
          if(!champ.renseignement.isPrive)
            newRenseignement = true;


          // Si le groupe est de la meme société que celui qui le modifie et que le champ est un email alors ce n'est pas un new renseignement
          //if(UtilsService.isSameDiese(ctrl.groupe.getDieze()) && champ.renseignement.isEmail()) newRenseignement = false;
          if(ctrl.isGroupePersoIcsFromMySociete)  {
            newRenseignement = false;

            if(champ.renseignement) {
              if(champ.renseignement.idFiche) idFiche = champ.renseignement.idFiche;
            }
          }
          if(champ.renseignement.state) idState = champ.renseignement.state.idState;
        }

        //console.log(champ);
        //console.log('newRenseignement',newRenseignement);
        //console.log('---------------------');

        // Si le groupe modifié est un groupe persoIcs de ma société alors passe le state à "Agence"
        if(ctrl.isGroupePersoIcsFromMySociete) {
          idState= 6; // Protected
          // Si le renseignement est un nom ou prenom alors passe le state en public
          if(champ.reference && (champ.reference.value === 4 || champ.reference.value === 5)) {
            idState = 4; // Public
          }
        }

        if(newRenseignement){

          //console.log('---------add----------');

          objParams = {
            idFiche: idFiche,
            idState: idState,
            idReference: champ.reference.value,
            donnee: UtilsService.trim(champ.tmpValue)
            //donnee: champ.value
          };
          /**
           * Pour la note
           * idReference
           * donnee
           * nom (non de la fiche)
           */

          FichesService
            .addRenseignement(objParams)
            .then(function(fiche){
              ctrl.groupe.getUser().setFichePerso(fiche);
              updateGroupe();
              //champ.value = objParams.donnee;
            })
            .catch(function(msg) {
              ModalsService.alertErreur(msg);
            })
            .finally(function(){
              champ.disabled = false;
            });

          // Sinon update
        }
        else {

          //console.log('---------update----------');
          objParams = {
            idFiche: idFiche,
            idState: idState,
            idReference: champ.reference.value,
            idRenseignement: champ.renseignement.idRenseignement,
            donnee: UtilsService.trim(champ.tmpValue)
          };

          FichesService
            .updateRenseignement(objParams)
            .then(function(fiche){
              ctrl.groupe.getUser().setFichePerso(fiche);
              updateGroupe();
            })
            .catch(function(msg) {
              ModalsService.alertErreur(msg);
            })
            .finally(function(){
              champ.disabled = false;
            });
        }
      }

    }

    function deleteChamp(champ){

      if(champ.renseignement){
        champ.disabled = true;
        var objParams = {
          idFiche: ctrl.idFiche,
          idRenseignement: champ.renseignement.getIdRenseignement()
        };

        FichesService.deleteRenseignement(objParams).then(function(fiche){
          ctrl.groupe.getUser().setFichePerso(fiche);
          updateGroupe();
        },function(){

        }).finally(function(){
          champ.disabled = false;
        });
      }else{
        champ.value = '';
      }
    }

    /**
     * Lors du focus sur le champ
     * @param champ
     */
    function onFocusChamp(champ){
      if(champ.state.value === 0){
        // 1er state par défaut
        champ.state.value = champ.state.liste[0].idState;
      }
      if(champ.reference.value === 0){
        // 1er référence par défaut
        champ.reference.value = champ.reference.liste[0].idReference;
      }

      // Affiche la référence et le state
      champ.reference.show = true;
      champ.state.show = true;
    }

    /**
     * Lorsque l'on quitte le champ
     * @param champ
     */
    /*
    function onBlurChamp(champ,formInfos){

        var categorie = champ.reference.categorie;

        // Si le champ est vide alors cache les champ state et référence
        if(!angular.isDefined(champ.value) || champ.value===''){
            // Si le champ n'est pas unique
            if(!ctrl.editingData[categorie].unique) {
                champ.reference.show = false;
            }
            champ.state.show = false;
        }else{
            saveChamp(champ,formInfos);
        }
    }*/

    /*
    function onKeyUpChamp(champ,index,event){

        var categorie = champ.reference.categorie;

        var nbChampInCategorie = ctrl.editingData[categorie].value.length;

        // Si le champ n'est pas unique
        if(!ctrl.editingData[categorie].unique){

            // Si le champ est différent de vide
            if(champ.value!==''){


                // Si il y a déjà un champ suivant ou que sont index est égale au dernier des champs de la categorie (normalement le nouveau vide)
                if(index===(nbChampInCategorie-1)){
                    ctrl.editingData[categorie].value.push(addChamp(categorie));
                }

                // Petit flag sur le champ pour savoir si le champ suivant à déjà été ajouté
                // Pour pas l'ajouter à toute les frappes
                //champ.nextAlreadyAdd = true;
            }else{

                // Si c'est un nouveau champ et qu'il n'est pas le seul de la categorie
                if(!champ.renseignement && ctrl.editingData[categorie].length>1){

                    // Recupération du dernier champ de la categorie
                    var lastChamp = ctrl.editingData[categorie].value.slice(0,-1);

                    // Si le dernier champ de la categorie est un nouveau champ et qu'il est vide
                    if((!angular.isDefined(lastChamp.value) || champ.value==='') && !lastChamp.renseignement){

                        // Supprime le dernier champ
                        ctrl.editingData[categorie].value.splice(-1,1);
                        //champ.nextAlreadyAdd = false;
                    }
                }
            }
        }

        if(event.keyCode === 13){
            event.target.blur();
        }
    }*/




    /**
     * Lors du changement de valeur dans un champ reférence ou state
     * @param champ
     */
    function onChangeSelect(champ){

      if(champ.renseignement || champ.isMyEmailCollab) {
        saveChamp(champ);
      }
    }

    function onSaveChamp(champ, formInfos){
      var categorie = champ.reference.categorie;

      // Si le champ est vide alors cache les champ state et référence
      if(angular.isUndefined(champ.tmpValue) || champ.tmpValue === ''){

        // Si le champ n'est pas unique
        if(!ctrl.editingData[categorie].unique) champ.reference.show = false;
        champ.state.show = false;
      }
      else saveChamp(champ,formInfos);
    }

    function onDeleteChamp(champ){
      deleteChamp(champ);
    }

    function updateGroupe() {
      ctrl.onUpdate({
        ligneFiche: {
          groupe: ctrl.groupe
        }
      });
    }


  }
})();
