(function() {
    'use strict';

    /**
     * @desc Bouton de menu avec l'avatar
     * @example <menu-avatar></menu-avatar>
     */

    angular
        .module('collaboreApp')
        .component('menuAvatar',{
            templateUrl: 'app/divers/menu-avatar/menu-avatar.component.html',
            controllerAs: 'menuavatarctrl',
            bindings: {
                user: '<',
                avatarStyle: '<'
            },
            controller: MenuAvatarController
        });

    /*@ngInject*/
    function MenuAvatarController(COLLAB_CONF, COLLAB_VALUES, $rootScope) {

        var ctrl = this;

        ctrl.logOut = logOut;

        // Loading de l'avatar si chargement
        ctrl.loadingAvatar = COLLAB_CONF.MIDDLE_LOADING_SRC;

        // Url l'image si pas d'avatar
        ctrl.srcNoPhoto = COLLAB_CONF.SRC_NO_PHOTO;

        // Url du dossier des avatars
        ctrl.urlAvatar = COLLAB_VALUES.CONF_URL.URL_AVATARS;

        ctrl.nomUser = $rootScope.current.userGroupe.getNom();

        ctrl.$onInit = function(){
            //console.log(scope.user);

            if(ctrl.user) {


                if (angular.isDefined(ctrl.user.nomAvatar) && angular.isString(ctrl.user.nomAvatar)) {
                    ctrl.avatar = ctrl.user.nomAvatar;
                }else{
                    ctrl.avatar = false;
                }



            }else{
                // Avatar 'nom.ext'
                ctrl.avatar = $rootScope.current.userGroupe.getUser().getNomAvatar();

                $rootScope.$on('updateAvatar',function(event,avatar){
                    if(avatar){
                        ctrl.avatar = avatar;
                    }else{
                        ctrl.avatar = $rootScope.current.userGroupe.getUser().getNomAvatar();
                    }
                });

            }
        };

        function logOut(){
            $rootScope.$broadcast('logOut');
        }

    }
})();
