(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.User
   * @description
   * # User
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('User',
      UserModel
    );

  /** @ngInject */
  function UserModel(Main, Fiche) {

    function User(objetData) {
      if (objetData) {
        Main.apply(this, arguments);
        this.hydrate(objetData);
        //this.setData(objetData);
        //this.isUser = true;
      }
    }

    User.prototype = {

      isUser: true,
      model: 'User',
      /*
       setData: function(objetData) {

       var _this = this;
       angular.extend(_this, objetData);

       if(objetData.hasOwnProperty('fichePerso')){
       if(angular.isObject(objetData.fichePerso)){
       _this.setFichePerso(objetData.fichePerso);
       }
       }
       },*/

      // Id User
      getIdUser: function(){
        return this.id;
      },
      setIdUser: function(val){
        this.id = val;
      },

      // Email
      getEmail: function(){
        return this.email;
      },
      setEmail: function(valEmail){
        this.email = valEmail;
      },

      // Etat
      getEtat: function(){
        return this.etat;
      },
      setEtat: function(valEtat){
        this.etat = valEtat;
      },

      // NbSpam
      getNbSpam: function(){
        return this.nbSpam;
      },
      setNbSpam: function(valNbSpam){
        this.nbSpam = valNbSpam;
      },

      // Privilege
      getPrivilege: function(){
        return this.privilege;
      },
      setPrivilege: function(valPrivilege){
        this.privilege = valPrivilege;
      },

      // Avatar
      getNomAvatar: function(){
        return this.nomAvatar;
      },
      setNomAvatar: function(valNomAvatar){
        this.nomAvatar = valNomAvatar;
      },
      hasAvatar: function(){
        var retour = false;
        if(angular.isString(this.getNomAvatar()) && this.getNomAvatar()!==null && this.getNomAvatar()!==''){
          retour = true;
        }
        return retour;
      },

      // Fiche Perso
      getFichePerso: function(){
        return this.fichePerso;
      },
      setFichePerso: function(valFichePerso){
        if(!valFichePerso.isModel){
          valFichePerso = new Fiche(valFichePerso);
        }
        this.fichePerso = valFichePerso;
      },

      isActif: function () {
        return (this.getEtat() === User.refEtats.actif.structure);
      },

      isInvite: function () {
        return (this.getEtat() === User.refEtats.invite.structure);
      },

      isSupprime: function () {
        return (this.getEtat() === User.refEtats.supprime.structure);
      },

      isMembre: function () {
        return (this.getPrivilege() === User.refPrivileges.$membre.structure);
      },

      isAdmin: function (checkSuperAdmin) {
        if (this.getPrivilege() === User.refPrivileges.$admin.structure) return true;
        return (checkSuperAdmin && this.isSuperAdmin());
      },

      isSuperAdmin: function () {
        return (this.getPrivilege() === User.refPrivileges.$superadmin.structure);
      },

      isAccueilCamping: function () {
        return (this.getPrivilege() === User.refPrivileges.$accueil_camping.structure);
      },

      isDirectionCamping: function () {
        return (this.getPrivilege() === User.refPrivileges.$direction_camping.structure);
      },

      isDirectionCapfun: function () {
        return (this.getPrivilege() === User.refPrivileges.$direction_capfun.structure);
      },

      isSiegeCapfun: function () {
        return (this.getPrivilege() === User.refPrivileges.$siege_capfun.structure);
      },

      getLibellePrivilege: function(){
        return User.refPrivileges[this.getPrivilege()].libelle;
      },

      getEmails: function(){
        if(!_.isNil(this.getFichePerso())) {
          return this.getFichePerso().getEmails();
        }
        return [];
      }
    };

    User.refEtats = {
      actif: {
        libelle: 'Actif',
        structure: '$actif'
      },
      invite: {
        libelle: 'Invité',
        structure: '$invite'
      },
      supprime: {
        libelle: 'Supprimé',
        structure: '$supprime'
      }
    };

    User.refPrivileges = {
      $membre: {
        libelle: 'Membre',
        structure: '$membre'
      },
      $admin: {
        libelle: 'Admin',
        structure: '$admin'
      },
      $superadmin: {
        libelle: 'Super Admin',
        structure: '$superadmin'
      },
      $accueil_camping: {
        libelle: 'Accueil Camping',
        structure: '$accueil_camping'
      },
      $direction_camping: {
        libelle: 'Direction Camping',
        structure: '$direction_camping'
      },
      $direction_capfun: {
        libelle: 'Direction Capfun',
        structure: '$direction_capfun'
      },
      $siege_capfun: {
        libelle: 'Siège Capfun',
        structure: '$siege_capfun'
      }
    };

    angular.extend(User.prototype, Main.prototype);

    return User;
  }
})();
