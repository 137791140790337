'use strict';
/**
 * Created by AndyDev on 21/07/2015.
 */

/**
 * Retourne le tag type demandé dans un badge et avec un bouton de filtre ou non
 */
angular.module('collaboreApp').directive('badgeTagEvenementType',['$parse','$state', 'FiltreService', function($parse,$state,FiltreService) {
    return {
        restrict: 'A',
        replace: true,
        template : '<span ng-if="currentState && libelle"><strong class="text-gris" ng-show="titre" ng-bind-html="titre"></strong><a ui-sref="{{(currentState===\'recherche\') ? \'topics.list\' : currentState}}(paramsFiltreByTagType)" ui-sref-opts="{reload:false}" ng-click="$event.stopPropagation()" class="cursorPointer"><span class="badge badge-tag alert-rouge-collabore tag-text">{{libelle}}</span></a></span>',
        link: function (scope, element, attr){

            scope.currentState = $state.current.name;

            var type = attr.tagEvenementType || 'Projet';
            var Topic = $parse(attr.topic)(scope);
            scope.allowFiltre = $parse(attr.allowFiltre)(scope);

            scope.titre = (attr.libelle) ? attr.libelle : false;


            var tags = Topic.getTagsWithType(type);
            if(tags.length){
                //console.log(tags[0]);
                scope.libelle = tags[0].libelle;
                scope.tagTypeProjet = tags[0];

                scope.paramsFiltreByTagType = {};
                if(scope.allowFiltre) {

                    scope.paramsFiltreByTagType = FiltreService.getObjFiltreByTagType(scope.tagTypeProjet);
                    //return paramsFiltre;
                }

            }else{
                scope.libelle = false;
            }



            /*
            scope.getParams = function(){
                if(scope.allowFiltre) {

                    scope.paramsFiltre = {
                        'topicPage': 1,
                        'evenement': true,
                        'tagsType': angular.toJson(scope.tagTypeProjet)
                    };
                    //return paramsFiltre;
                }
            };*/



        }/*,
        controller: function($scope,FiltreService){

            $scope.filtreByTagType = function(tag){
                if($scope.allowFiltre) {
                    var canFiltre = true;
                    var filtreTag = FiltreService.filtreWithTagsType();
                    if(filtreTag){
                        // Si le filtre du tag actuel est celui du tag cliqué
                        if(filtreTag===tag.idTag){
                            canFiltre = false;
                        }
                    }

                    if(canFiltre){
                        FiltreService.filtreByTagType(tag);
                    }
                }
            };
        }*/
    };
}]);

/**
 * Label événement urgent permettant de filtrer
 *
angular.module('collaboreApp').directive('labelEvenementUrgent',['$parse','$state','FiltreService', function($parse,$state,FiltreService) {
    return {
        restrict: 'A',
        replace: true,
        //template: '<span class="label label-danger" ng-click="filtreByEvenementUrgent();$event.stopPropagation();" ng-show="TopicState.getTopic().isUrgent()" uib-tooltip="Evénement urgent !" tooltip-append-to-body="true"><i class="fa fa-warning"></i> Urgent <i class="fa fa-filter" ng-if="allowFiltre"></i></span>',
        template: '<a ui-sref="{{(currentState===\'recherche\') ? \'topics.list\' : currentState}}(paramsFiltreByEvenementUrgent)" class="clean" ng-click="$event.stopPropagation()" ng-if="currentState && Topic.urgent"><span class="label label-danger" uib-tooltip="Evénement urgent !" tooltip-append-to-body="true"><i class="fa fa-warning"></i></span></a>',
        link: function (scope, element, attr){

            scope.currentState = $state.current.name;
            scope.paramsFiltreByEvenementUrgent = {};
            scope.Topic = $parse(attr.topic)(scope);
            scope.allowFiltre = $parse(attr.allowFiltre)(scope);
            if(scope.allowFiltre){
                scope.paramsFiltreByEvenementUrgent = FiltreService.getObjFiltreByEvenementUrgent();
            }
        },
        // controller: function($scope,FiltreService){
        //     $scope.filtreByEvenementUrgent = function(){
        //         if(!FiltreService.filtreIsUrgent()){
        //             FiltreService.filtreByEvenementUrgent();
        //         }
        //     };
        // }
    };
}]);

/**
 * Label événement cloturé permettant de filtrer
 *
angular.module('collaboreApp').directive('labelEvenementCloture',['$parse','$state','FiltreService', function($parse,$state,FiltreService) {
    return {
        restrict: 'A',
        replace: true,
        scope: false,
        //template: '<span class="label label-success" ng-click="filtreByEvenementCloture();$event.stopPropagation();" ng-show="TopicState.getTopic().hasEventCloture()" uib-tooltip="Evénement cloturé !" tooltip-append-to-body="true"><i class="fa fa-check"></i> Cloturé <i class="fa fa-filter" ng-if="allowFiltre"></i></span>',
        template: '<a ui-sref="{{(currentState===\'recherche\') ? \'topics.list\' : currentState}}(paramsFiltreByEvenementCloture)" class="clean" ng-click="$event.stopPropagation()" ng-if="currentState && Topic.eventCloturer"><span class="label label-default" collab-tooltip="Evénement cloturé !"><i class="fa fa-check"></i></span></a>',
        link: function (scope, element, attr){
            scope.currentState = $state.current.name;
            scope.paramsFiltreByEvenementCloture = {};
            scope.Topic = $parse(attr.topic)(scope);
            scope.allowFiltre = $parse(attr.allowFiltre)(scope);
            if(scope.allowFiltre){
                scope.paramsFiltreByEvenementCloture = FiltreService.getObjFiltreByEvenementCloture();
            }
        },
        // controller: function($scope,FiltreService){
        //     $scope.filtreByEvenementCloture = function(){
        //         if(!FiltreService.filtreIsCloture()) {
        //             FiltreService.filtreByEvenementCloture();
        //         }
        //     };
        // }
    };
}]);

/**
 * Label Evenement avec popover
 *
angular.module('collaboreApp').directive('labelEvenement',['COLLAB_CONF','$timeout','$sce','UtilsService','$parse','$state','FiltreService','TopicStatesService', function(COLLAB_CONF,$timeout,$sce,UtilsService,$parse,$state,FiltreService,TopicStatesService) {
    return {
        restrict: 'EA',
        replace: true,

        // popover-isopen="scope.isInsidePopup"
        //popoverIsopenShow
        //ng-click="$event.stopPropagation()"
        //ng-mouseenter="labelevenementctrl.onMouseEnter()" ng-mouseleave="labelevenementctrl.onMouseLeave()"
        template : '<span><a ui-sref="{{(currentState===\'recherche\') ? \'topics.list\' : currentState}}(paramsFiltreByEvenement)" ng-if="currentState && Topic.hasEvent()" class="clean"><span class="label label-warning" popover-is-open="labelevenementctrl.popupIsOpen" popover-class="popover-collab popover-lg" uib-popover-template="\'scripts/topics/directives/templates/popover-html-evenement.html\'" popover-trigger="none" popover-placement="left" popover-append-to-body="true"><i class="fa fa-sitemap"></i><span ng-if="!small"> Evénement</span></span></a></span>',

        link: function (scope, element, attr){
            var isEnterLabel = false,timoutEnterPromise,timoutLeavePromise;

            //console.log($state.current);
            scope.currentState = $state.current.name;
            scope.paramsFiltreByEvenement = {};
            scope.popupIsOpen = false;
            scope.Topic = $parse(attr.topic)(scope);
            scope.allowFiltre = $parse(attr.allowFiltre)(scope);
            scope.small = $parse(attr.small)(scope);
            if(scope.allowFiltre){
                scope.paramsFiltreByEvenement = FiltreService.getObjFiltreByEvenement();
            }

            // Lors du clique
            function onClick(e){
                e.stopPropagation();
            }

            // Lorsque la souris est entrée dans le label
            function onMouseEnter(){
                isEnterLabel = true;

                if(timoutLeavePromise){
                    $timeout.cancel(timoutLeavePromise);
                }
                timoutEnterPromise = $timeout(function() {
                    if(isEnterLabel){
                        scope.labelevenementctrl.popupIsOpen = true;
                        scope.labelevenementctrl.loadingEvenementPopup = COLLAB_CONF.MIDDLE_LOADING;
                        TopicStatesService.getEvenementByTopic(scope.Topic).then(function (evenement) {
                            scope.labelevenementctrl.loadingEvenementPopup = false;

                            scope.labelevenementctrl.evenement = UtilsService.nl2br($sce.trustAsHtml(evenement.objet));
                            //vm.evenement = evenement;

                        });
                    }
                },500);
            }

            // Lorsque la souris est sortie du label
            function onMouseLeave(){
                isEnterLabel = false;

                if(timoutEnterPromise){
                    $timeout.cancel(timoutEnterPromise);
                }

                timoutLeavePromise = $timeout(function(){
                    if(!scope.labelevenementctrl.isInsidePopup){
                        scope.labelevenementctrl.popupIsOpen = false;
                    }
                },500);
            }

            $timeout(function(){
                var $element = angular.element(element[0]);

                //var elem = element[0];
                //var span = angular.element(elem).find('span')[0];
                if($element.find('a').length!==0){
                    var elem = $element.find('a')[0];
                    var eventMethod = elem.addEventListener ? 'addEventListener' : 'attachEvent';
                    var removeEventMethod = elem.removeEventListener ? 'removeEventListener' : 'detachEvent';
                    var onClickEvent = eventMethod === 'attachEvent' ? 'onclick' : 'click';
                    var onMouseEnterEvent = eventMethod === 'attachEvent' ? 'onmouseenter' : 'mouseenter';
                    var onMouseLeaveEvent = eventMethod === 'attachEvent' ? 'onmouseleave' : 'mouseleave';

                    // Passe les event en listener pour eviter le digest d'angular
                    elem[eventMethod](onClickEvent,onClick);            // Ajoute un listener sur le clique
                    elem[eventMethod](onMouseEnterEvent,onMouseEnter);  // Ajoute un listener sur l'entrée de la souris dans le label
                    elem[eventMethod](onMouseLeaveEvent,onMouseLeave);  // Ajoute un listener sur la sortie de la souris du label

                    scope.$on('$destroy',function(){
                        elem[eventMethod](onClickEvent,onClick);                    // Supprime le listener du clique
                        elem[removeEventMethod](onMouseEnterEvent, onMouseEnter);   // Supprime le listener du mouse enter
                        elem[removeEventMethod](onMouseLeaveEvent, onMouseLeave);   // Supprime le listener du mouse leave
                    });
                }

            });
        },
        controller: [function(){

            var vm = this;

            vm.onMouseEnterPopup = function(){
                vm.isInsidePopup = true;
            };
            vm.onMouseLeavePopup = function(){
                vm.isInsidePopup = false;
                vm.popupIsOpen = false;
            };
        }],
        controllerAs: 'labelevenementctrl'
    };
}]);
*/
/**
 * Popover qui utilise un template avec une variable qui passe de true à false si on survole la popup, permet de la garder ouverte quand la souris la survole
 */
angular.module('collaboreApp').directive('popoverTemplateCollabPopup', function () {
    return {
        restrict: 'EA',
        replace: true,
        scope: { title: '@', contentExp:'&', placement: '@', popupClass: '@', animation: '&', isOpen: '&', originScope: '&'},
        templateUrl: 'app/topics/directives/templates/popover-template-collab.html',
        controller: ['COLLAB_CONF','$scope',function(COLLAB_CONF,$scope){

            /**
             * Ecoute la variable "isInsidePopup" qui permet de savoir si la souris survole la popup
             * Transmet l'info à la directive qui appel la popover
             */
            $scope.$watch('isInsidePopup',function(newVal,oldVal){
                if(!angular.equals(newVal,oldVal)){
                    $scope.originScope().isInsidePopup = newVal;
                }
            });
        }]
    };
});
angular.module('collaboreApp').directive('popoverTemplateCollab', [ '$uibTooltip', function ($uibTooltip) {
    return $uibTooltip('popoverTemplateCollab', 'popover', 'mouseenter',{
        useContentExp: true
    });
}]);

angular.module('collaboreApp').directive('progressBarAvancement', [function () {
    return {
        restrict: 'A',
        replace: false,
        scope: {
            historiques: '=',
            item: '=',
            progressClass: '@',
            progressSize: '@',
            noPopover: '=',
            popupPlacement: '@'
        },
        template: '<uib-progressbar popover-enable="!noPopover" ng-mouseenter="onMouseEnter()" ng-mouseleave="onMouseLeave()" uib-popover-template="\'app/topics/directives/templates/popover-html-historiques-avancement.html\'" popover-placement="{{placement}}" popover-is-open="popupIsOpen" popover-trigger="outsideClick" popover-append-to-body="true" popover-class="popover-collab popover-lg" ng-class="[classProgressSize,classProgress]" value="lastEtat.value" type="{{lastEtat.type}}" max="10">{{lastEtat.value}}</uib-progressbar>',
        link: function(scope){
            scope.placement = scope.popupPlacement || 'auto';
        },
        controller: ['$scope','$filter','$timeout',function($scope,$filter,$timeout){

            var isEnterBtn = false,timoutEnterPromise,timoutLeavePromise;
            $scope.popupIsOpen = false;

            // Lorsque la souris est dans le popup
            $scope.onMouseEnterPopup = function(){
                $scope.isInsidePopup = true;
            };

            // Lorsque la souris quitte la popup
            $scope.onMouseLeavePopup = function(){
                $scope.isInsidePopup = false;
                $scope.popupIsOpen = false;
            };

            // Lorsque la souris survole le bouton
            $scope.onMouseEnter = function(){
                isEnterBtn = true;

                if(timoutLeavePromise){
                    $timeout.cancel(timoutLeavePromise);
                }
                timoutEnterPromise = $timeout(function() {
                    if(isEnterBtn){
                        $scope.popupIsOpen = true;
                    }
                },500);
            };

            // Lorsque la souris ne survole plus le bouton
            $scope.onMouseLeave = function(){
                isEnterBtn = false;

                if(timoutEnterPromise){
                    $timeout.cancel(timoutEnterPromise);
                }

                timoutLeavePromise = $timeout(function(){
                    if(!$scope.isInsidePopup){
                        $scope.popupIsOpen = false;
                    }
                },500);
            };

            $scope.classProgress = $scope.progressClass;
            var size = $scope.progressSize || 'sm';
            $scope.classProgressSize = 'progress-'+size;

            $scope.getTypeProgression = function(progression){
                var typeProgression = 'danger';
                if(progression===0){
                    typeProgression = 'empty';
                }
                else if(progression>=4 && progression<8){
                    typeProgression = 'warning';
                }
                else if(progression>=8 && progression<10){
                    typeProgression = 'info';
                }
                else if(progression===10){
                    typeProgression = 'success';
                }
                return typeProgression;
            };

            //scope.minVal = 0;
            //scope.maxVal = 10;
            $scope.lastEtat = {
                'value': 0,
                'type': 'empty'
            };


            //$scope.arrayHistorique = [];
            if($scope.historiques){

                $scope.$watchCollection('historiques',function(newVal){
                    //console.log(newVal);

                    var newArrayHistorique = $filter('orderBy')(newVal,'date');

                    //console.log('histo newVal',newVal);
                    //console.log('-----------------');

                    var lastEtat = false;
                    var arrayHistorique = newArrayHistorique;
                    if(angular.isArray(arrayHistorique)){
                        var nbEtat = arrayHistorique.length;
                        if(nbEtat){
                            lastEtat = arrayHistorique[nbEtat-1];
                        }
                    }

                    if(angular.isObject(lastEtat)){
                        var lastProgression = lastEtat.progression;
                        var typeProgression = $scope.getTypeProgression(lastProgression);

                        $scope.lastEtat = {
                            'value': lastProgression,
                            'type': typeProgression
                        };

                    }

                });

            }else if($scope.item){
                if(angular.isObject($scope.item)){
                    var lastProgression = $scope.item.progression;
                    var typeProgression = $scope.getTypeProgression(lastProgression);

                    $scope.lastEtat = {
                        'value': lastProgression,
                        'type': typeProgression
                    };
                }
            }
        }]
    };
}]);

/**
 * Popover avec la liste des historiques d'avancement d'un topic
 */
angular.module('collaboreApp').directive('popoverHistoriquesAvancement', [function () {
    return {
        restrict: 'A',
        replace: true,
        scope: {
            topic: '=',
            popupPlacement: '@'
        },
        template: '<div uib-popover-template="\'app/topics/directives/templates/popover-html-historiques-avancement.html\'" popover-placement="{{placement}}" popover-trigger="outsideClick" popover-is-open="popupIsOpen" popover-class="popover-collab popover-lg" ng-click="onClick()"></div>',
        link: function(scope){
            scope.placement = scope.popupPlacement || 'top';
            scope.historiques = scope.topic.getHistoriques();
        },
        controller: ['$scope',function($scope){
            $scope.popupIsOpen = false;

            $scope.onClick = function(){
                $scope.popupIsOpen = !$scope.popupIsOpen;
            };
            /*
            $scope.onClickOutside = function(){
                $scope.popupIsOpen = false;
            };*/
        }]
    };
}]);

/**
 * Boutton de la popover qui permet de changer la progression de l'événement dans la liste des topics
 */
angular.module('collaboreApp').directive('btnSliderProgression', ['COLLAB_CONF',function (COLLAB_CONF) {
    return {
        restrict: 'A',
        replace: false,
        scope: {
            topicState: '=',
            popupPlacement: '@'
        },
        template: '<div class="inline padding-5" click-prevent uib-popover-template="\'app/topics/directives/templates/popover-html-slider-avancement.html\'" popover-class="popover-collab popover-collabore" ng-click="onClickBtn()" popover-trigger="outsideClick" popover-is-open="popupIsOpen" collab-tooltip="Modifier l\'avancement" popover-placement="{{placement}}" topic-state="topicState"><i class="fa fa-sliders cursorPointer"></i></div>',
        link: function(scope){
            scope.popupIsOpen = false;
            scope.placement = scope.popupPlacement || 'auto';
        },
        controller: ['$scope','HistoriqueAvancementService',function($scope,HistoriqueAvancementService){

            $scope.values = {
                'old': 0,
                'new': 0
            };



            $scope.onClickBtn = function(){
              $scope.popupIsOpen = !$scope.popupIsOpen;
            };


            $scope.closePopover = function(){
                $scope.popupIsOpen = false;
            };


            $scope.saveAvancement = function(){

                if($scope.values.new!==$scope.values.old){
                    $scope.loadingPopoverAvancement = COLLAB_CONF.MIDDLE_LOADING;


                    var objParams = {
                        'topic': $scope.topicState.getTopic().getIdTopic(),
                        'progression': $scope.values.new
                    };

                    HistoriqueAvancementService.add(objParams).then(function(historique){
                        $scope.values.old = $scope.values.new;

                        $scope.topicState.getTopic().addHistorique(historique);

                        $scope.loadingPopoverAvancement = false;
                        // Ferme la popover
                        $scope.closePopover();
                    });


                }else{
                    // Ferme la popover
                    $scope.closePopover();
                }
            };

            $scope.closePopup = function(){
                // Ferme la popover
                $scope.closePopover();
            };

            /*
            $scope.onSlideInit = function(){
                $scope.loadingPopoverAvancement = false;
            };*/
        }]
    };
}]);

angular.module('collaboreApp').directive('initSlider', ['COLLAB_CONF','$timeout','$filter',function (COLLAB_CONF,$timeout,$filter) {
    return {
        restrict: 'A',
        replace: false,
        scope: {
            topicState: '=',
            newVal: '=',
            oldVal: '=',
            slider: '='
        },
        link: function(scope,element){

            var oldVal = false, $slider = element;

            var arrayHistoriques = scope.topicState.getTopic().getHistoriques();
            var nbHistorique = arrayHistoriques.length;
            var valAvancement = 0;
            if(nbHistorique){
                var newArrayHistorique = $filter('orderBy')(arrayHistoriques,'date');
                valAvancement = newArrayHistorique[nbHistorique-1].progression;
            }

            scope.oldVal = valAvancement;
            scope.newVal = valAvancement;

            $slider.bootstrapSlider({
                step: 1,
                min: 1,
                max: 10,
                value: valAvancement,
                ticks: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                ticks_labels: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                handle: 'custom'
            });



            $slider.on('change', function (obj) {

                if (!oldVal) {
                    oldVal = obj.value.oldValue;
                }
            });
            $slider.on('slideStop', function (obj) {

                scope.oldVal = oldVal;
                scope.newVal = obj.value;
                scope.$parent.$digest();
            });

            /*
            $slider.on('slideEnabled', function (obj) {
                scope.$parent.onSlideInit();
            });*/

            $timeout(function() {
                $slider.bootstrapSlider('refresh');
            });

            // Lors l'element est destroy
            element.on('$destroy', function(){
                $slider.bootstrapSlider('destroy');
            });
        }
    };
}]);
