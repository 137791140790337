(function () {
  'use strict';

  /**
   * @desc Page de liste de topic
   * @example <layout-topic-list [list-topic-states="" OU list-topic=""]></layout-topic-list>
   */

  angular
    .module('collaboreApp')
    .component('layoutTopicList', {
      templateUrl: 'app/layout/layout-topic-list.component.html',
      controllerAs: 'layouttopiclistctrl',
      bindings: {
        searchByTags: '<',
        onEmit: '&'
      },
      controller: LayoutTopicListController
    });

  /*@ngInject*/
  function LayoutTopicListController(COLLAB_CONF, $q, $window, $interval, $rootScope, $scope, $state, $stateParams, $timeout, $locale, FiltreService, UtilsService, TagService, TagsService, AnnuaireService, SocketNotificationService, ConfigService, InfosDashboard) {
    var ctrl = this;

    ctrl.tagExtranet = {
      name: 'extranet',
      tag: {
        libelle: UtilsService.parseTag(COLLAB_CONF.TAG_EXTRANET)
      },
      filtre: null,
      stateParams: null,
      stateOptions: {reload:false, notify:false}
    };

    ctrl.tagToctoctoc = {
      name: 'toctoctoc',
      tag: {
        libelle: UtilsService.parseTag(COLLAB_CONF.TAG_TOCTOCTOC)
      },
      filtre: null,
      stateParams: null,
      stateOptions: {reload:false, notify:false}
    };

    ctrl.tagRapportVisite = {
      name: 'rapportvisite',
      tag: {
        libelle: UtilsService.parseTag(COLLAB_CONF.TAG_RAPPORT_VISITE)
      },
      filtre: null,
      stateParams: null,
      stateOptions: {reload:false, notify:false}
    };

    ctrl.tagIntervention = {
      name: 'intervention',
      tag: {
        libelle: TagsService.genereTagTypeType(COLLAB_CONF.TAG_TYPE_TYPE_INTERVENTION, null, null)
      },
      filtre: null,
      stateParams: null,
      stateOptions: {reload:false, notify:false}
    };

    ctrl.tagTechnicien = {
      name: 'technicien',
      tag: {
        libelle: TagsService.genereTagTypeTechnicien()
      },
      filtre: null,
      stateParams: null,
      stateOptions: {reload:false, notify:false}
    };

    ctrl.tagMenage = {
      name: 'menage',
      tag: {
        libelle: TagsService.genereTagTypeMenage()
      },
      filtre: null,
      stateParams: null,
      stateOptions: {reload:false, notify:false}
    };

    ctrl.notMobile = UtilsService.notMobile;
    ctrl.$state = $state;

    ctrl.etat = $stateParams.etat;			//	Type de topics (normaux, archivés ou coup de coeur)

    //ctrl.filtreTopics = $rootScope.current.filtreTopics;

    // Paramètres de recherche par défault
    ctrl.search = {
      etat: ctrl.etat,
      page: $rootScope.current.pageTopic,
      filtre: $rootScope.current.filtreTopics
    };

    ctrl.popoverFiltre = {
      'isOpen': false,
      'toggle': function toggle() {
        this.isOpen = !this.isOpen;
      }
    };

    var defaultValuesDatePicker = {
      startDate: moment().startOf('month'),
      endDate: moment().add(1, 'month').endOf('month'),
      searchAll: true,
      showDates: false,
      label: 'Tous'
    };
    //ctrl.currentStateName = null;

    ctrl.filtreDateCreation = angular.copy(defaultValuesDatePicker);

    ctrl.optionsDatepicker = {
      showDropdowns: true,
      autoApply: false,
      clearLabel: 'Effacer',
      linkedCalendars: false,
      locale: {
        format: 'DD/MM/YYYY',
        separator: ' au ',
        applyLabel: 'Ok',
        fromLabel: 'Du',
        toLabel: 'au',
        cancelLabel: 'Annuler',
        customRangeLabel: 'Personnaliser',
        daysOfWeek: $locale.DATETIME_FORMATS.SHORTDAY,
        firstDay: 1,
        monthNames: $locale.DATETIME_FORMATS.STANDALONEMONTH
      },
      eventHandlers: {
        'show.daterangepicker': function (ev) {
          ctrl.datePickerDateCreationShow = true;
        },
        'hide.daterangepicker': function (ev) {
          ctrl.datePickerDateCreationShow = false;
        },
        'apply.daterangepicker': function (ev) {
          UtilsService.parseDisplayDates(ev.model);
          filtreByDate(ev.model);
        }
      },
      ranges: {
        'Tous': [angular.copy(defaultValuesDatePicker.startDate), angular.copy(defaultValuesDatePicker.endDate)],
        'Aujourd\'hui': [moment(), moment()],
        'Hier': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Cette semaine (depuis Lundi)': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
        'Les 7 derniers jours': [moment().subtract(7, 'days'), moment()],
        'Semaine dernière (Lun - Dim)': [moment().subtract(1, 'week').startOf('isoWeek'), moment().subtract(1, 'week').endOf('isoWeek')],
        'Les 14 derniers jours': [moment().subtract(14, 'days'), moment()],
        'Ce mois-ci': [moment().startOf('month'), moment().endOf('month')],
        'Les 30 derniers jours': [moment().subtract(30, 'days'), moment()],
        'Le mois dernier': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
      opens: 'right'
    };

    /*
     var tagsTypeAllowForSearch = [
     COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE,
     COLLAB_CONF.TAG_TYPE_PROPRIETAIRE,
     COLLAB_CONF.TAG_TYPE_IMMEUBLE,
     COLLAB_CONF.TAG_TYPE_LOCATAIRE,
     COLLAB_CONF.TAG_TYPE_LOT,
     COLLAB_CONF.TAG_TYPE_MANDAT

     ];*/

    ctrl.displayDemandesInTopicsList = true;
    ctrl.loadingTopicsList = true;											// Loading de la page topicList
    ctrl.topicStatesLimitPerPage = COLLAB_CONF.LIST_TOPIC_LIMIT_PAR_PAGE;	// Nombre de topic par page
    ctrl.refreshTopicList = refreshTopicList;								// Permet de refresh les topics
    ctrl.onClickFiltreTag = onClickFiltreTag;
    ctrl.onUpdateListTopicStates = onUpdateListTopicStates;					// Executé par le component listTopicStates pour mettre à jour des données
    ctrl.toggleDemandes = toggleDemandes;									// Affiche/Cache les demandes des topic
    ctrl.toggleCloture = toggleCloture;										// Affiche topic cloturés
    ctrl.toggleNonLu = toggleNonLu;											// Affiche topic non lu
    ctrl.cleanFiltre = cleanFiltre;											// Permet de clean le filtre
    ctrl.displayInformations = displayInformations;							// Permet d'afficher un texte d'information en haut de la liste des topics
    ctrl.openAnnuaire = openAnnuaire;										// Permet d'afficher l'annuaire
    //ctrl.removeTagFromFiltre = removeTagFromFiltre;							// Permet de retirer un tag du filtre
    //ctrl.removeEmailFromFiltre = removeEmailFromFiltre;						// Permet de retirer un email du filtre
    ctrl.removeParamFromFiltre = removeParamFromFiltre;						// Permet de retirer un paramètre du filtre
    ctrl.onDeleteFiltre = onDeleteFiltre;
    ctrl.clearDatesCreation = clearDatesCreation;
    ctrl.displayBtnEventCreation = displayBtnEventCreation;

    // Permet de filtrer la liste des topics
    var listenerOnFiltreTopicList = $scope.$on('filtreTopicList', function (evt, params) {
      evt.preventDefault();
      //console.log(params);
      filtreTopicList(params);
    });
    var listenerOnTogglePopoverFiltre = $scope.$on('togglePopoverFiltre', function () {
      ctrl.popoverFiltre.toggle();
    });
    var listenerOncleanFiltre = $scope.$on('cleanFiltre', function () {
      cleanFiltre();
    });
    var listenerChangeStart = null;


    // Permet de mettre à jour les infos dans la liste des topics
    /* Désactivé pour le moment car plus envoyé par le back
    var listenerOnUpdateCollab = $scope.$on('updateCollab', function (evt, params) {
      if(_.isObject(params.infosDashboard)){
        updateInfosDashboard(params.infosDashboard);
      }
    });*/

    //var intervalRefreshHome;


    $scope.$watch('$root.current.filtreTopics.idChannel', function(newVal, oldVal){
      initChannelShorcut();
    });

    ctrl.$onInit = function () {
      /*
      // Lors du changement d'une route pour les channel
      listenerChangeStart = $rootScope.$on('$stateChangeStart', function(event, toState, toParams){
        ctrl.currentStateName = toState.name;
      });*/
      init();
    };

    ctrl.$onDestroy = function () {
      listenerOnFiltreTopicList();
      listenerOnTogglePopoverFiltre();
      listenerOncleanFiltre();
      //if(!_.isNil(listenerChangeStart)) listenerChangeStart();
      //listenerOnUpdateCollab();
    };

    function init(){
      initPreferenceDisplayDemandes();

      if ($stateParams.action === 'rechercheAnnuaire') {

        var noPerso = null;
        var noFournisseur = null;
        var mandat = null;
        var immeuble = null;
        var lot = null;
        var locataire = null;
        var paramClePortefeuille = null;
        var origine = null;
        var inverse = null;
        var idPortefeuille = null;
        if ($stateParams.hasOwnProperty('noperso')) noPerso = $stateParams.noperso;
        if ($stateParams.hasOwnProperty('nofourni')) noFournisseur = $stateParams.nofourni;
        if ($stateParams.hasOwnProperty('mandat')) mandat = $stateParams.mandat;
        if ($stateParams.hasOwnProperty('immeuble')) immeuble = $stateParams.immeuble;
        if ($stateParams.hasOwnProperty('lot')) lot = $stateParams.lot;
        if ($stateParams.hasOwnProperty('locataire')) locataire = $stateParams.locataire;
        if ($stateParams.hasOwnProperty('portefeuille')) paramClePortefeuille = $stateParams.portefeuille;
        if ($stateParams.hasOwnProperty('origine')) origine = $stateParams.origine;
        if ($stateParams.hasOwnProperty('inv')) inverse = $stateParams.inv;
        if ($stateParams.hasOwnProperty('idPortefeuille')) idPortefeuille = $stateParams.idPortefeuille;

        var infosParams = {};
        var configAnnuaire = {};
        /*
         var infosParams = {
         noperso: false,
         nofourni: false,
         locataire: false,
         immeuble: false,
         mandat: false,
         lot: false,
         portefeuille: false
         };

         openAnnuaire(infosParams);*/

        getClePortefeuille(idPortefeuille, paramClePortefeuille)
          .then(function(clePortefeuille){

            // Selectionne le bail d'un locataire
            if (noPerso && mandat && immeuble && lot && _.isString(locataire) && locataire.length === 16) {
              var xxxx = locataire.substring(12);
              AnnuaireService
                .searchLot(immeuble, noPerso, lot, clePortefeuille, mandat, xxxx)
                .then(function (ret) {
                  //console.log(ret);
                  var objetCollaborateur = AnnuaireService.genereCollaborateur(
                    ret.locataire,
                    ret.portefeuille, null, null, false);
                  var objAnnuaire = UtilsService.genereObjetAnnuaire({
                    origine: objetCollaborateur.collaborateur,
                    current: ret.lot,
                    bail: ret.bail,
                    immeuble: ret.immeuble
                  });
                  var objetsTags = TagsService.genereTagsAndFiches(objAnnuaire.objAnnuaire);

                  //console.log('objetCollaborateur', objetCollaborateur);
                  //console.log('objAnnuaire', objAnnuaire);
                  //console.log('objetsTags', objetsTags);
                  $scope.$emit('filtreByTags', objetsTags);
                });
            }

            // Selectionne le lot d'un immeuble pour une perso (noPerso & mandat & immeuble & lot)
            else if (noPerso && mandat && immeuble && lot) {
              AnnuaireService
                .searchLot(immeuble, noPerso, lot, clePortefeuille, mandat)
                .then(function (ret) {
                  var objetCollaborateur = AnnuaireService.genereCollaborateur(ret.perso, ret.portefeuille, null, null, false);
                  //var objAnnuaire = UtilsService.genereObjetFromAnnuaire(objetCollaborateur.collaborateur, false, ret.lot, ret.immeuble);
                  var objAnnuaire = UtilsService.genereObjetAnnuaire({
                    origine: objetCollaborateur.collaborateur,
                    current: ret.lot,
                    immeuble: ret.immeuble
                    //immeuble: (_.isObject(ret.immeuble) && ret.immeuble.hasOwnProperty('immeuble')) ? ret.immeuble.immeuble : ret.immeuble
                  });
                  //var objetsTags = TagService.genereTagsForType(objAnnuaire.objAnnuaire);
                  var objetsTags = TagsService.genereTagsAndFiches(objAnnuaire.objAnnuaire);
                  $scope.$emit('filtreByTags', objetsTags);
                })
                .catch(function (msg) {
                  $scope.$emit('goAccueil');
                });
            }

            // Si il y a un mandat, un immeuble, un numéro perso mais pas de lot on ouvre l'annuaire (noPerso & mandat & immeuble)
            else if (noPerso && mandat && immeuble) {
              configAnnuaire.defautTypeAnnuaire = 'perso';
              infosParams = {
                noperso: noPerso,
                immeuble: immeuble,
                mandat: mandat,
                portefeuille: clePortefeuille,
                typePerso: 'P'
              };
              openAnnuaire(infosParams, configAnnuaire);
            }

            // Selectionne un copropriétaire d'un immeuble (noPerso & immeuble)
            else if (noPerso && immeuble) {
              AnnuaireService
                .searchCoproprietaireByNoPersoForNumImmeuble(immeuble, noPerso, clePortefeuille)
                .then(function (retImm) {
                  //console.log('retImm',retImm);
                  var objetCollaborateur = AnnuaireService.genereCollaborateur(retImm.immeuble, retImm.portefeuille, null, null, false);
                  var objAnnuaire = UtilsService.genereObjetAnnuaire({
                    origine: retImm.perso,
                    collaborateur: objetCollaborateur.collaborateur
                  });
                  //var objAnnuaire = UtilsService.genereObjetFromAnnuaire(retImm.perso, objetCollaborateur.collaborateur);
                  var objetsTags = TagsService.genereTagsAndFiches(objAnnuaire.objAnnuaire);
                  $scope.$emit('filtreByTags', objetsTags);
                })
                .catch(function (msg) {
                  console.error('searchCoproprietaireByNoPersoForNumImmeuble : ' + msg);
                  $scope.$emit('goAccueil');
                });
            }

            // Selectionne un propriétaire et son mandat
            else if (noPerso && mandat) {
              AnnuaireService
                .searchMandat(noPerso, mandat, clePortefeuille)
                .then(function (ret) {

                  var objetCollaborateur = AnnuaireService.genereCollaborateur(ret.perso, ret.portefeuille, null, null, false);
                  var objAnnuaire = UtilsService.genereObjetAnnuaire({
                    origine: objetCollaborateur.collaborateur,
                    current: ret.mandat
                  });
                  //var objAnnuaire = UtilsService.genereObjetFromAnnuaire(objetCollaborateur.collaborateur, false, ret.mandat);
                  var objetsTags = TagsService.genereTagsAndFiches(objAnnuaire.objAnnuaire);
                  $scope.$emit('filtreByTags', objetsTags);
                })
                .catch(function (msg) {
                  console.error('searchMandat : ' + msg);
                  $scope.$emit('goAccueil');
                });
            }

            // Selectionne un immeuble en origine (immeuble)
            else if (immeuble) {
              AnnuaireService
                .searchImmeubleByNum(immeuble, clePortefeuille)
                .then(function (ret) {
                  if (ret.immeuble) {
                    var objetCollaborateur = AnnuaireService.genereCollaborateur(ret.immeuble, ret.portefeuille, null, null, false);
                    var objAnnuaire = UtilsService.genereObjetAnnuaire({
                      origine: objetCollaborateur.collaborateur.immeuble,
                      collaborateur: objetCollaborateur.collaborateur
                    });

                    //var objAnnuaire = UtilsService.genereObjetFromAnnuaire(objetCollaborateur.collaborateur.immeuble, objetCollaborateur.collaborateur);
                    var objetsTags = TagsService.genereTagsAndFiches(objAnnuaire.objAnnuaire);
                    $scope.$emit('filtreByTags', objetsTags);
                  }
                })
                .catch(function (msg) {
                  console.error('searchImmeubleByNum : ' + msg);
                  $scope.$emit('goAccueil');
                });
            }

            // Si recherche fournisseur (noFournisseur)
            else if (noFournisseur) {
              //$stateParams.noRefresh = true;
              var nofourni = noFournisseur;
              var codemetier = nofourni.substring(0, 3);
              var numFournisseur = nofourni.substring(3);

              ctrl.loadingTopicsList = true;

              AnnuaireService
                .searchFournisseurByNum(numFournisseur, codemetier, clePortefeuille)
                .then(function (ret) {

                  if (ret.perso) {
                    var objetCollaborateur = AnnuaireService.genereCollaborateur(ret.perso, ret.portefeuille, null, null, false);

                    var objAnnuaire = UtilsService.genereObjetAnnuaire({
                      origine: objetCollaborateur.collaborateur,
                      collaborateur: objetCollaborateur.collaborateur
                    });
                    //var objAnnuaire = UtilsService.genereObjetFromAnnuaire(objetCollaborateur.collaborateur, objetCollaborateur.collaborateur);
                    var objetsTags = TagsService.genereTagsAndFiches(objAnnuaire.objAnnuaire);
                    $scope.$emit('filtreByTags', objetsTags);
                  }

                })
                .catch(function (msg) {
                  console.error('searchFournisseurByNum : ' + msg);
                  $scope.$emit('goAccueil');
                });

            }
            // Ouvre l'annuaire
            else openAnnuaire();
          })
          .catch(function(msg){
            console.error(msg);
          });
      }
      else {
        //console.log($stateParams);
        //console.log($stateParams.search);
        //console.log('clotureEnable', $rootScope.current.filtreTopics.others.clotureEnable);
        // ---------- Filtre ----------
        // Recupération d'un éventuel filtre dans l'url
        var isFiltre = FiltreService.parseFiltreFromUrl($stateParams, false);
        // console.log(isFiltre);
        //console.log('clotureEnable', $rootScope.current.filtreTopics.others.clotureEnable);
        //console.log($rootScope.current.filtreTopics.idChannel);

        // Si il y a un filtre
        if (isFiltre)  ctrl.search.filtre = ctrl.filtreTopics = $rootScope.current.filtreTopics;
        // ---------- Fin Filtre ----------


        ctrl.suffixTitre = '';
        ctrl.nomChannel = COLLAB_CONF.LIBELLE_CHANNEL_ALL;

        var idChannelFiltre = FiltreService.filtreIsChannel();
        //console.log('clotureEnable', $rootScope.current.filtreTopics.others.clotureEnable);
        if (idChannelFiltre) {

          var channel = $rootScope.current.userGroupe.getChannel(idChannelFiltre);
          if (channel) {
            ctrl.nomChannel = channel.nom;
            ctrl.suffixTitre = ' du channel "' + channel.nom + '"';
          }
        }

        ctrl.currentFiltreTag = null;
        if(_.isObject(ctrl.filtreTopics)) {

          if(_.isArray(ctrl.filtreTopics.listeDestinataireEvenement) && ctrl.filtreTopics.listeDestinataireEvenement.length){
            for(var d = 0; d < ctrl.filtreTopics.listeDestinataireEvenement.length; d++){
              if(_.isObject(ctrl.filtreTopics.listeDestinataireEvenement[d])){
                if(ctrl.filtreTopics.listeDestinataireEvenement[d].libelle === "technique"){
                  ctrl.currentFiltreTag = ctrl.tagTechnicien.name;
                  break;
                }
                else if(ctrl.filtreTopics.listeDestinataireEvenement[d].libelle === "menage"){
                  ctrl.currentFiltreTag = ctrl.tagMenage.name;
                  break;
                }
              }
            }
          }

          if(ctrl.currentFiltreTag == null && _.isArray(ctrl.filtreTopics.tags) && ctrl.filtreTopics.tags.length){
            for(var t = 0; t < ctrl.filtreTopics.tags.length; t++){
              if(_.isNil(ctrl.filtreTopics.tags[t].idTag)) {
                if(ctrl.filtreTopics.tags[t].libelle === UtilsService.parseTag(COLLAB_CONF.TAG_EXTRANET)){
                  ctrl.currentFiltreTag = ctrl.tagExtranet.name;
                  break;
                }
                else if(ctrl.filtreTopics.tags[t].libelle === UtilsService.parseTag(COLLAB_CONF.TAG_TOCTOCTOC)){
                  ctrl.currentFiltreTag = ctrl.tagToctoctoc.name;
                  break;
                }
                else if(ctrl.filtreTopics.tags[t].libelle === UtilsService.parseTag(COLLAB_CONF.TAG_RAPPORT_VISITE)){
                  ctrl.currentFiltreTag = ctrl.tagRapportVisite.name;
                  break;
                }
                else if(ctrl.filtreTopics.tags[t].libelle === UtilsService.parseTag(COLLAB_CONF.TAG_TYPE_TYPE_INTERVENTION)) {
                  ctrl.currentFiltreTag = ctrl.tagIntervention.name;
                  break;
                }
                else if(_.isObject(ctrl.filtreTopics.tags[t].typeTag) && ctrl.filtreTopics.tags[t].typeTag.libelle === COLLAB_CONF.TAG_TYPE_TECHNICIEN){
                  if(ctrl.filtreTopics.tags[t].typeTag.libelle === COLLAB_CONF.TAG_TYPE_TECHNICIEN){
                    ctrl.currentFiltreTag = ctrl.tagTechnicien.name;
                    break;
                  }
                  else if(ctrl.filtreTopics.tags[t].typeTag.libelle === COLLAB_CONF.TAG_TYPE_MENAGE){
                    ctrl.currentFiltreTag = ctrl.tagMenage.name;
                    break;
                  }
                }
              }
            }
          }

        }
      }

      // Si il y a un objet "dateCreation" dans le filtre
      if(_.isObject(ctrl.filtreTopics) && !_.isNil(ctrl.filtreTopics.dateCreation)){
        // Si on recherche toutes les dates
        if(ctrl.filtreTopics.dateCreation.searchAll){
          clearDatesCreation();
        }
        // Si on recherche pas toute les dates
        else if(!_.isNil(ctrl.filtreTopics.dateCreation.startDate) || !_.isNil(ctrl.filtreTopics.dateCreation.endDate)){
          if(!_.isNil(ctrl.filtreTopics.dateCreation.startDate))  ctrl.filtreDateCreation.startDate = moment(ctrl.filtreTopics.dateCreation.startDate).startOf('day');
          if(!_.isNil(ctrl.filtreTopics.dateCreation.endDate))    ctrl.filtreDateCreation.endDate = moment(ctrl.filtreTopics.dateCreation.endDate).startOf('day');
          ctrl.filtreDateCreation.searchAll = false;
        }
      }

      /*
      if(!_.isNil($rootScope.current.filtreTopics.dateCreationDebut) || !_.isNil($rootScope.current.filtreTopics.dateCreationFin)) {
        if(!_.isNil($rootScope.current.filtreTopics.dateCreationDebut)) {
          ctrl.filtreDateCreation.startDate = moment($rootScope.current.filtreTopics.dateCreationDebut).startOf('day')
        }
        if(!_.isNil($rootScope.current.filtreTopics.dateCreationFin)) {
          ctrl.filtreDateCreation.endDate = moment($rootScope.current.filtreTopics.dateCreationFin).startOf('day')
        }
      }*/
      ctrl.filtreDateCreation = UtilsService.datepickerGetLabelFromDates(ctrl.filtreDateCreation, defaultValuesDatePicker.label);

      initStatesFiltreTags();
      setFilterIsEnabled();
    }

    function initInfosDashboard(){
      ctrl.infosDashboard = {};
      //ctrl.loadingNbTopicSansEventAvecTagExtranet = true;
      //ctrl.loadingNbTopicSansEventAvecTagToctoctoc = true;
      //ctrl.loadingNbTopicAvecTagRapportVisite = true;
    }

    function initChannelShorcut(){
      var obj = angular.copy($rootScope.current.filtreTopics);
      if(obj.idChannel === 0) obj.idChannel = -1;
      var retour = FiltreService.parseFiltreToUrl(obj);
      ctrl.paramsFiltreChannel = FiltreService.getObjFiltre(retour, obj.idChannel);
      ctrl.paramsFiltreChannelWithCloture = FiltreService.getObjFiltre(retour, obj.idChannel, null);
      // ctrl.paramsFiltreChannel = FiltreService.getObjFiltreByChannel($rootScope.current.filtreTopics.idChannel);
    }

    function updateInfosDashboard(infos){
      if(infos.hasOwnProperty('nbTopicSansEventAvecTagExtranet')) ctrl.loadingNbTopicSansEventAvecTagExtranet = false;
      if(infos.hasOwnProperty('nbTopicSansEventAvecTagToctoctoc')) ctrl.loadingNbTopicSansEventAvecTagToctoctoc = false;
      if(infos.hasOwnProperty('nbTopicAvecTagRapportVisite')) ctrl.loadingNbTopicAvecTagRapportVisite = false;

      ctrl.infosDashboard = _.merge(ctrl.infosDashboard, infos);
    }

    function initStatesFiltreTags(){
      // Filtre pour tag #Extranet
      ctrl.stateTagExtranet =  FiltreService.getObjFiltreByTagWithoutEvent(-1 , {libelle: UtilsService.parseTag(COLLAB_CONF.TAG_EXTRANET)}, null, true);

      // Filtre pour tag #Toctoctoc
      ctrl.stateTagToctoctoc =  FiltreService.getObjFiltreByTagWithoutEvent(-1 , {libelle: UtilsService.parseTag(COLLAB_CONF.TAG_TOCTOCTOC)}, null, true);

      // Filtre pour tag #RapportVisite
      ctrl.stateTagRapportVisite =  FiltreService.getObjFiltreByTags(null , {libelle: UtilsService.parseTag(COLLAB_CONF.TAG_RAPPORT_VISITE)}, null, null, true);

      var tagInter = TagsService.genereTagTypeType(COLLAB_CONF.TAG_TYPE_TYPE_INTERVENTION, null, null);

      // Filtre pour tag #Intervention
      ctrl.stateTagIntervention =  FiltreService.getObjFiltreByTags(null , tagInter, null, null, true);

      //ctrl.stateTagTechnicien =  FiltreService.getObjFiltreByTags(null , TagsService.genereTagTypeTechnicien(), null, {listeDestinataireEvenement: [{libelle: "technique"}]}, true);
      ctrl.stateTagTechnicien =  FiltreService.getObjFiltreByTags(null , tagInter, null, {listeDestinataireEvenement: [{libelle: "technique"}]}, true);
      ctrl.stateTagMenage =  FiltreService.getObjFiltreByTags(null , tagInter, null, {listeDestinataireEvenement: [{libelle: "menage"}]}, true);

      initInfosDashboard();
    }


    function clearDatesCreation() {
      ctrl.filtreDateCreation = angular.copy(defaultValuesDatePicker);
      //filtreByDate(ctrl.filtreDateCreation);
    }

    function getClePortefeuille(idPortefeuille, clePortefeuille){
      var deferred = $q.defer();

      if(!_.isNil(clePortefeuille)) deferred.resolve(clePortefeuille);
      else if(!_.isNil(idPortefeuille)){
        UtilsService.getPortefeuille(idPortefeuille)
          .then(function(portefeuille){
            deferred.resolve(portefeuille.getCle());
          })
          .catch(function(msg){
            deferred.reject("Pas de portefeuille pour l'idPortefeuille: "+idPortefeuille+" !");
          });
      }
      else deferred.reject("Pas clé ou d'id portefeuille !");

      return deferred.promise;
    }

    function initPreferenceDisplayDemandes(){
      ctrl.customPreference = ConfigService.getPreferencesCustom();
      if(!_.isObject(ctrl.customPreference)) {
        ctrl.customPreference = {
          displayDemandesInTopicsList: true
        };
      }
      ctrl.displayDemandesInTopicsList = ctrl.customPreference.displayDemandesInTopicsList;
      ConfigService.setPreferencesCustom(ctrl.customPreference);
    }

    /**
     * Permet de setter les preférences d'affichage des demandes dans la liste des topics
     * @param val
     */
    function setPreferenceDisplayDemandes(val){
      ctrl.customPreference = ConfigService.getPreferencesCustom();
      if(!_.isObject(ctrl.customPreference)) ctrl.customPreference = {};
      ctrl.customPreference.displayDemandesInTopicsList = ctrl.displayDemandesInTopicsList = val;
      ConfigService.setPreferencesCustom(ctrl.customPreference);
    }

    function onClickFiltreTag(current, event){

      if(_.isObject(current) && $rootScope.current.filtreTopics.tags.length){
        var tagDel = current.tag;
        if (!_.isNil(tagDel)){
          if(ctrl.currentFiltreTag === current.name){
            event.preventDefault();

            $rootScope.current.filtreTopics.classe = 0;
            $rootScope.current.filtreTopics.tags = TagService.deleteTagFromTags($rootScope.current.filtreTopics.tags, tagDel);
            ctrl.currentFiltreTag = null;
            initChannelShorcut();
            filtreTopicList({'objFiltre': $rootScope.current.filtreTopics, notifyAndReload: true});
          }
          //else TagService.deleteTagFromTags($rootScope.current.filtreTopics.tags, tagDel);
        }
      }
      setFilterIsEnabled();
    }

    /**
     * Fonction exécuté par le component
     * @param obj
     */
    function onUpdateListTopicStates(obj) {
      if (_.isObject(obj)) {
        if (obj.hasOwnProperty('nbTotalTopicStates')) {
          ctrl.nbTotalTopicStates = obj.nbTotalTopicStates;
        }

        if (obj.hasOwnProperty('loadingListTopicStates')) {
          ctrl.loadingTopicsList = obj.loadingListTopicStates;
          ctrl.loadingFiltreListTopicStates = obj.loadingListTopicStates;
        }

        if (obj.hasOwnProperty('loadingFiltreListTopicStates')) {
          ctrl.loadingFiltreListTopicStates = obj.loadingFiltreListTopicStates;
        }

        /*
        if (obj.hasOwnProperty('infosDashboard')) {
          ctrl.infosDashboard = obj.infosDashboard;
        }*/


        /*
         if(obj.hasOwnProperty('descriptionFiltre')){
         console.log(obj.descriptionFiltre);
         ctrl.filtreTopics = $rootScope.current.filtreTopics = obj.descriptionFiltre.objFiltre;
         }*/
      }
    }

    /**
     * Affiche/Cache les demandes des topics
     */
    function toggleDemandes(){
      setPreferenceDisplayDemandes(!ctrl.displayDemandesInTopicsList);
      //$scope.$broadcast('customPreferenceChanged');
    }

    /**
     * Affiche tous les topic ou seulement les non cloturés
     */
    function toggleCloture() {
      //var tmpSearch = angular.copy(ctrl.search);
      if ($rootScope.current.filtreTopics.others.clotureEnable) {
        $rootScope.current.filtreTopics.others.clotureEnable = false;
        $rootScope.current.filtreTopics.withCloture = false;
        $rootScope.current.filtreTopics.withoutCloture = true;
        //ctrl.currentStateName = 'topics.list';
      }
      else {
        $rootScope.current.filtreTopics.others.clotureEnable = true;
        $rootScope.current.filtreTopics.withCloture = true;
        $rootScope.current.filtreTopics.withoutCloture = true;
        //ctrl.currentStateName = 'topics.tous';
      }
      searchTopics();
    }

    /**
     * Affiche les topic non lu
     */
    function toggleNonLu() {
      if ($rootScope.current.filtreTopics.others.nonLuEnable) {
        $rootScope.current.filtreTopics.others.nonLuEnable = false;
      }
      else {
        $rootScope.current.filtreTopics.others.nonLuEnable = true;
      }

      searchTopics();
    }

    /**
     * Filtre les topics par date de création
     * @param obj
     */
    function filtreByDate(obj) {
      if (obj.searchAll) {
        $rootScope.current.filtreTopics.dateCreation = {
          startDate: null,
          endDate: null,
          searchAll: true
        };
      }
      else{
        if(!_.isNil(obj.startDate)){
          if(_.isObject(obj.startDate) && obj.startDate._isAMomentObject) obj.startDate = obj.startDate.format();
          $rootScope.current.filtreTopics.dateCreation.startDate = UtilsService.dateFormat(obj.startDate,'yyyy-MM-dd');
        }
        if(!_.isNil(obj.endDate)){
          if(_.isObject(obj.endDate) && obj.endDate._isAMomentObject) obj.endDate = obj.endDate.format();
          $rootScope.current.filtreTopics.dateCreation.endDate = UtilsService.dateFormat(obj.endDate,'yyyy-MM-dd');
        }
        $rootScope.current.filtreTopics.dateCreation.searchAll = false;
      }
      searchTopics();
    }

    function searchTopics(){

      var tmpSearch = angular.copy(ctrl.search);
      var stateParams = FiltreService.parseFiltreToUrl($rootScope.current.filtreTopics);
      stateParams.topicPage = $rootScope.current.pageTopic;
      $stateParams.search = stateParams.objSearch.search;
      tmpSearch.filtre = angular.copy($rootScope.current.filtreTopics);
      ctrl.search = tmpSearch;

      initStatesFiltreTags();
      initChannelShorcut();
      setFilterIsEnabled();

      // Change l'url
      $state.go($state.current.name, stateParams, {'location': true, 'reload': false, 'notify': false});
      //$state.go(ctrl.currentStateName, stateParams, {'location': true, 'reload': false, 'notify': false});
    }

    /**
     * Rappel la liste des topicStates
     * @param params
     */
    function refreshTopicList(params) {

      // Mise à vide de la liste des nouveaux topics temporaire à lister lors du clique sur la notif
      // TODO [Websockets] A decommenter si utilisation des Websockets
      //$rootScope.arrayNewTopicStateTmp = [];

      // Supprime le hash de l'url
      //$location.hash('');
      // Supprime le hash du scope
      //$scope.hash = false;

      var stateParams = {}, reload = false, notify = false, state = $state.current.name;

      if (_.isObject(params)) {

        if (_.isObject(params.objFiltre)) {

          if (params.hasOwnProperty('channel')) stateParams = FiltreService.getObjFiltreByChannel(params.channel);
          else {
            var obj = FiltreService.parseFiltreToUrl(params.objFiltre);
            stateParams = obj.objSearch;
          }
          ctrl.search.filtre = params.objFiltre;
        }

        if (angular.isDefined(params.page)) $rootScope.current.pageTopic = parseInt(params.page);
        if (params.hasOwnProperty('gotToState')) state = params.gotToState;
        if (params.hasOwnProperty('reload')) reload = params.reload;

        stateParams.topicPage = $rootScope.current.pageTopic;
        ctrl.search.page = stateParams.topicPage;

        // Si filtre sur tous les topics
        if ($rootScope.current.filtreTopics.others.clotureEnable) ctrl.search.filtre.cloturer = 0;

        if(params.notifyAndReload) {
          reload = true;
          notify = true;
        }
      }
      // Dans le cas d'un refresh
      else reload = true;

      UtilsService.resetStateParamsAction();

      // Change l'url
      $state.go(state, stateParams, {'location': true, 'reload': reload, 'notify': notify});
    }

    /**
     * Permet d'afficher un texte d'information en haut de la liste des topics
     * @returns {string}
     */
    function displayInformations() {
      var retour = 'Informations';
      if (UtilsService.isTopicList()) {
        /*
         // TODO : [Checkbox Topic] Décommenté si utilisation des checkbox
         if(vm.nbTopicChecked===1){
         retour = vm.nbTopicChecked+' topic selectionné';
         }else{
         if(vm.nbTopicChecked>1){
         retour = vm.nbTopicChecked+' topics selectionnés';
         }else{
         */
        retour = FiltreService.genereDescription($rootScope.current.filtreTopics);
        /*
         if($rootScope.current.filtreTopics.libelle){
         retour = $rootScope.current.filtreTopics.libelle;
         }*/
        /*
         }
         }*/
      }
      return retour;
    }

    function displayBtnEventCreation(){
      // && (ctrl.currentFiltreTag != 'extranet' && ctrl.currentFiltreTag != 'toctoctoc' && ctrl.currentFiltreTag != 'rapportvisite')
      if($rootScope.current.filtreTopics.tags.length &&
        $rootScope.current.tmpNewEvenement.tagsForOrigine &&
        !$rootScope.current.tmpNewEvenement.tagsForOrigine.disableEventCreation){
        return true;
      }
      return false;
    }

    function setFilterIsEnabled(){
      var filtre = $rootScope.current.filtreTopics;
      if(filtre.classe!=='0' ||
        filtre.isActive ||
        filtre.others.nonLuEnable ||
        filtre.others.clotureEnable ||
        !ctrl.filtreDateCreation.searchAll ||
        !_.isNil(ctrl.currentFiltreTag) ||
        (!_.isNil(filtre.tags) && filtre.tags.length) ||
        (!_.isNil(filtre.destinataires) && filtre.destinataires.length) ||
        (!_.isNil(filtre.listeFournisseurMail) && filtre.listeFournisseurMail.length) ||
        (!_.isNil(filtre.listeFournisseurPersoICS) && filtre.listeFournisseurPersoICS.length)){
        ctrl.filterIsEnabled = true;
      }
      else {
        ctrl.filterIsEnabled = false;
      }
    }

    /**
     * Permet de clean le filtre
     */
    function cleanFiltre() {
      $timeout(function () {
        FiltreService.cleanFiltre();
        ctrl.currentFiltreTag = null;
        // Filtre la liste
        filtreTopicList({'objFiltre': $rootScope.current.filtreTopics, notifyAndReload: true});
      });

    }

    /**
     * Permet de filtrer la liste des topics
     * @param evt
     * @param params
     */
    function filtreTopicList(params) {
      FiltreService.saveFiltre(params.objFiltre);
      refreshTopicList(params);
    }

    function openAnnuaire(infos, configAnnuaire) {
      $scope.$emit('openAnnuaireSearchTopics', infos, configAnnuaire);
    }

    function removeParamFromFiltre(objUpdate, liste) {
      if (objUpdate.hasOwnProperty('deleteTagFromContenu')) {
        var tagDel = objUpdate.deleteTagFromContenu;
        // Si c'est une liste de tags où il faut retirer un tag
        if(_.isArray(liste) && liste.length){

          liste = TagService.deleteTagFromTags(liste, tagDel);
          $rootScope.current.filtreTopics.classe = 0;
          filtreTopicList({'objFiltre': $rootScope.current.filtreTopics, notifyAndReload: true});

        }
      }
      // Si c'est un attribut du filtre à remettre à null
      if(_.isString(liste) && $rootScope.current.filtreTopics.hasOwnProperty(liste)) {
        $rootScope.current.filtreTopics.classe = 0;
        $rootScope.current.filtreTopics[liste] = null;
        filtreTopicList({'objFiltre': $rootScope.current.filtreTopics, notifyAndReload: true});
      }
    }

    function onDeleteFiltre(property){
      $rootScope.current.filtreTopics[property] = null;
      filtreTopicList({'objFiltre': $rootScope.current.filtreTopics, notifyAndReload: true});
    }

    /*
     function removeTagFromFiltre(objUpdate){
     if(objUpdate.hasOwnProperty('deleteTagFromContenu')){
     var tagDel = objUpdate.deleteTagFromContenu;
     if($rootScope.current.filtreTopics.tags.length){
     $rootScope.current.filtreTopics.tags = TagService.deleteTagFromTags($rootScope.current.filtreTopics.tags,tagDel);
     filtreTopicList({'objFiltre':$rootScope.current.filtreTopics});
     }
     }
     }

     function removeEmailFromFiltre(objUpdate){
     if(objUpdate.hasOwnProperty('deleteTagFromContenu')){
     var tagDel = objUpdate.deleteTagFromContenu;
     if($rootScope.current.filtreTopics.mails.length){
     $rootScope.current.filtreTopics.mails = TagService.deleteTagFromTags($rootScope.current.filtreTopics.mails,tagDel);
     filtreTopicList({'objFiltre':$rootScope.current.filtreTopics});
     }
     }
     }*/


    //Ecoute l'événement 'refreshTopicList', pour rappeller la list des topics
    /*
     $scope.$on('refreshTopicList', function(event,params) {
     refreshTopicList(params);
     });*/

  }


})();
