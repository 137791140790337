(function () {
  'use strict';


  /**
   * @ngdoc service
   * @name collaboreApp.Contenu
   * @description
   * # Contenu
   * Service in the collaboreApp.
   */
  angular.module('collaboreApp').factory('Contenu', ContenuModel);

  /** @ngInject */
  function ContenuModel(COLLAB_CONF, Main, $q, UtilsService, ContenuAjax, DocumentsAjax, Document, Tag, Groupe, DestinataireEmail, TagsService, PersoIcs, ProxyPersoIcs, ProxyContratIcs, Courrier, InfosCloture, TopicInformations, RelanceAutoConfig, Relance, DemandeOptions) {


    /**
     * typeContenu
     * 1: Message/SMS
     * 2: Evenement
     * 3: Devis
     * 4: Intervention
     * 5: Facture
     * 6: Sinistre
     */
    function Contenu(contenuData) {


      var attributMinimum = [
        //{attribut:   'dateCreationFormated', exclu: true}, // Direct ajouté dans la méthode hydrate
        {attribut:   'groupesConcernes', defaultValue:[]},
        {attribut:   'listeDocument',    defaultValue:[]},
        {attribut:   'destinataires',    defaultValue:[]},
        {attribut:   'tags',             defaultValue:[]},
        {attribut:   'tagsType',         defaultValue:[]},
        {attribut:   'update',           defaultValue:false}
      ];

      Main.call(this, contenuData, attributMinimum);

      this.isBlocked = this.getIsBlocked();
    }

    Contenu.prototype = {

      model: 'Contenu',
      isContenu: true,

      setDataFromList: function(contenu){
        var _this = this;
        if(_.isArray(contenu)) {
          if(contenu.length === 1) {
            if(contenu[0].idContenu === _this.idContenu) _this.setData(contenu[0]);
          }
          else {
            for(var i = 0; i < contenu.length; i++){
              if(contenu[i].idContenu === _this.idContenu) {
                _this.setData(contenu[i]);
                break;
              }
            }
          }
        }
        else if(_.isObject(contenu) && contenu.idContenu === _this.idContenu) _this.setData(contenu);
      },

      // IdTopic
      getIdTopic: function(){
        return this.idTopic;
      },
      setIdTopic: function(idTopic){
        this.idTopic = idTopic;
      },


      // listTopicInformationsWhereContenuIsRef
      getListTopicInformationsWhereContenuIsRef: function(){
        return this.listTopicInformationsWhereContenuIsRef;
      },
      setListTopicInformationsWhereContenuIsRef: function(array){
        if(_.isArray(array) && array.length){
          for(var i = 0; i < array.length; i++){
            if(!array[i].isModel) {
              array[i] = new TopicInformations(array[i]);
            }
          }
        }
        this.listTopicInformationsWhereContenuIsRef = array;
      },

/*
      // TopicRef
      getTopicRef: function(){
        return this.topicRefInterAgence;
      },
      setTopicRef: function(infos){
        if(_.isObject(infos)){
          if(!infos.isModel) infos = new Topic(infos);
          this.topicsRef = infos;
        }
      },
*/
      // TopicInformations
      getTopicInformations: function(){
        return this.topicInformations;
      },
      setTopicInformations: function(infos){
        if(_.isObject(infos)){
          if(!infos.isModel) {
            infos = new TopicInformations(infos);
          }
          this.topicInformations = infos;
        }
      },

      // IdContenu
      getIdContenu: function(){
        return this.idContenu;
      },
      setIdContenu: function(idContenu){
        this.idContenu = idContenu;
      },

      // idEvenement
      getIdEvenement: function(){
        return this.idEvenement;
      },
      setIdEvenement: function(idEvenement){
        this.idEvenement = idEvenement;
      },

      // idContenuRef
      getIdContenuRef: function(){
        return this.idContenuRef;
      },
      setIdContenuRef: function(idContenuRef){
        this.idContenuRef = idContenuRef;
      },

      // dateCreation
      getDateCreation: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateCreation, format);
        }
        return this.dateCreation;
      },
      getDateCreationAgo: function(){
        var retour = this.dateCreation;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },
      setDateCreation: function(date){

        if(!date){
          date = new Date();
        }
        this.dateCreation = date;
        this.setDateCreationFormated(date);
      },

      // dateCreationFormated
      getDateCreationFormated: function(){
        return this.dateCreationFormated;
      },
      setDateCreationFormated: function(date){
        this.dateCreationFormated = UtilsService.dateParser(date);
      },

      // dateModification
      getDateModification: function(){
        return this.dateModification;
      },
      setDateModification: function(date){
        this.dateModification = date;
      },

      // infosCloture
      getInfosCloture: function(){
        return this.infosCloture;
      },
      setInfosCloture: function(infos){
        if(_.isObject(infos)) {
          if(!infos.isModel) this.infosCloture = new InfosCloture(infos);
          else this.infosCloture = infos;
        }
      },

      // dateCloture
      /*
      getDateCloture: function(){
          if(_.isObject(this.getInfosCloture()) && !_.isNil(this.getInfosCloture().getDateCloture())) {
              return this.getInfosCloture().getDateCloture();
          }
          return this.dateCloture;
      },*/
      getDateCloture: function(format){
        var date = null;
        if(_.isObject(this.getInfosCloture()) && !_.isNil(this.getInfosCloture().getDateCloture())) {
          return this.getInfosCloture().getDateCloture(format);
        }

        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateCloture, format);
        }
        return this.dateCloture;
      },
      setDateCloture: function(date){
        this.dateCloture = date;
      },
      isCloture: function () {
        return _.isString(this.getDateCloture());
      },
      // infosCloture.raison
      getRaisonCloture: function(){
        if(_.isObject(this.getInfosCloture()) && !_.isNil(this.getInfosCloture().getRaison())) {
          return this.getInfosCloture().getRaison();
        }
        return null;
      },
      hasRaisonCloture: function () {
        return !_.isEmpty(this.getRaisonCloture());
      },
      allowToViewRaisonCloture: function () {
        return (!_.isEmpty(this.getRaisonCloture()) && this.getInfosCloture().getVisibleByFournisseur());
      },

      setPrevisualisationMsg: function(msg){
        this.previsualisationMsg = msg;
        /*
                 if(this.isMessage){
                 this.previsualisationMsg = this.message;

                 }else if(this.isEvenement){
                 this.previsualisationMsg = this.objet;
                 }*/
      },

      // Groupe Concernés
      getGroupesConcernes: function(){
        return this.groupesConcernes;
      },
      setGroupesConcernes: function(arrayGroupesConcernes){
        var _this = this;
        this.groupesConcernes = [];
        if(_.isArray(arrayGroupesConcernes)){
          angular.forEach(arrayGroupesConcernes,function(groupeConcerne){
            if(!groupeConcerne.isModel){
              groupeConcerne = new Groupe(groupeConcerne);
            }
            _this.groupesConcernes.push(groupeConcerne);
          });
        }
      },

      // Destinataires
      getDestinataires: function(withDestEmail){
        var dests = this.destinataires;
        if(_.isNil(dests)) dests = [];
        if(withDestEmail && this.hasDestinatairesEmail()) {
          for(var d = 0; d < this.getDestinatairesEmail().length; d++){
            var currentDestEmail = this.getDestinatairesEmail()[d];
            var exist = false;
            if(dests.length){
              for(var e = 0; e < dests.length; e++){
                exist = (dests[e].isDestinataireEmail && dests[e].email === currentDestEmail.email);
                if(exist) break;
              }
            }
            if(!exist) dests.push(currentDestEmail);
          }
        }
        return dests;
      },
      setDestinataires: function(arrayDestinataires){
        var destinataires = [];
        if(_.isArray(arrayDestinataires)){
          angular.forEach(arrayDestinataires,function(destinataire){
            if(!destinataire.isModel){
              destinataire = new Groupe(destinataire);
            }
            destinataires.push(destinataire);
          });
        }
        this.destinataires = destinataires;
      },
      addDestinataire: function(newDestinataire){
        var newDest = [];
        if((_.isObject(newDestinataire) && !_.isArray(newDestinataire) && !newDestinataire.isModel) || _.isString(newDestinataire)) newDestinataire = new Groupe(newDestinataire);
        if(_.isObject(newDestinataire) && !_.isArray(newDestinataire)) {
          newDest.push(newDestinataire);
        }
        else if(_.isArray(newDestinataire) && newDestinataire.length) {
          newDestinataire.map(function(item){
            if((_.isObject(item) && !item.isModel) || _.isString(item)) item = new Groupe(item);
            return item;
          });
          newDest = _.uniqWith(newDestinataire, _.isEqual);
        }

        if(newDest.length){
          var arrayListeDestinataire = angular.copy(this.getDestinataires(true));

          // Parcour la liste des nouveau groupe à ajouter
          for(var nDest = 0; nDest < newDest.length; nDest++){
            var destinataire = newDest[nDest];
            // Ajoute pas si le destinataire et le même que le current groupe connecté à collab
            var exist = false;
            //var exist = (UtilsService.itsMe(destinataire));
            //if(!exist){
              // Parcour la liste des groupe déjà présent
              for(var i = 0; i < arrayListeDestinataire.length; i++){
                var currentOldDestinataire = arrayListeDestinataire[i];

                exist = UtilsService.destinataireIsEqual(destinataire, currentOldDestinataire);
                if(exist) break;
                /*
                // Si le nouveau destinataires n'est pas un email
                if(!destinataire.isMail) {
                  // Si le current destinataire a un idGroupe et que le new aussi
                  if(_.isNumber(currentOldDestinataire.idGroupe) && _.isNumber(destinataire.idGroupe) && currentOldDestinataire.idGroupe === destinataire.idGroupe) {
                    exist = true;
                    break;
                  }
                  // Sinon teste le libelle et le dieze
                  else if(_.isString(currentOldDestinataire.libelle) && _.isString(currentOldDestinataire.dieze) && currentOldDestinataire.libelle === destinataire.libelle && currentOldDestinataire.dieze === destinataire.dieze) {
                    exist = true;
                    break;
                  }
                  // Si le current destinataire a un destinataireEmail
                  else if(_.isNumber(currentOldDestinataire.idDestinataireEmail) && _.isNumber(destinataire.idDestinataireEmail) && currentOldDestinataire.idDestinataireEmail === destinataire.idDestinataireEmail) {
                    exist = true;
                    break;
                  }
                }
                // Si le nouveau destinataire est un email
                else if(destinataire.isMail && _.isString(currentOldDestinataire.libelle) && _.isString(destinataire.libelle) && currentOldDestinataire.libelle === destinataire.libelle) {
                  exist = true;
                  break;
                }*/
              }
            //}
            if(!exist) arrayListeDestinataire.push(destinataire);
          }
          this.destinataires = arrayListeDestinataire;
        }
      },
      removeDestinataires: function(arrayDestinataires){
        var listeDestToRemove = [];
        if(_.isArray(arrayDestinataires) && arrayDestinataires.length) {
          listeDestToRemove = arrayDestinataires;
        }
        else if(_.isObject(arrayDestinataires)){
          listeDestToRemove.push(arrayDestinataires);
        }

        if(listeDestToRemove.length){
          var _this = this;
          var arrayListeDestinataire = angular.copy(this.getDestinataires(true));
          _.remove(arrayListeDestinataire, function(groupe){
            var remove = false;
            for(var i = 0; i < listeDestToRemove.length; i++){
              var destinataire = listeDestToRemove[i];
              remove = UtilsService.destinataireIsEqual(destinataire, groupe);
              if(remove) break;
            }
            return remove;
          });
          this.destinataires = arrayListeDestinataire;
        }
      },

      // Jamais testée
      hasDestinataire: function(val){
        var listDest = this.getDestinataires();
        if(!_.isArray(listDest)) return false;
        var hasDest = false;

        var searchByString = (_.isString(val) && !_.isNumber(val)) ? val : ((_.isObject(val)) ? val.libelle+val.dieze : null);
        var searchByIdGroupe = (_.isNumber(val)) ? val : ((_.isObject(val)) ? val.idGroupe : null);

        for(var i = 0; i < listDest.length; i++){
          var dest = listDest[i];
          var byString = (_.isString(dest) && !_.isNumber(dest)) ? dest : ((_.isObject(dest)) ? dest.libelle+dest.dieze : null);
          var byIdGroupe = (_.isNumber(dest)) ? dest : ((_.isObject(dest)) ? dest.idGroupe : null);

          if(!_.isNil(searchByString) && !_.isNil(byString))          hasDest = (searchByString == byString);
          else if(!_.isNil(searchByIdGroupe) && !_.isNil(byIdGroupe)) hasDest = (searchByIdGroupe == byIdGroupe);

          if(hasDest) break;
        }
        return hasDest;
      },

      getIntervenantDestinataire: function(val){
        var listDest = this.getDestinataires();
        var hasDest = null;
        if(!_.isArray(listDest)) return hasDest;
        if(!_.startsWith(val, "@")) val = "@"+val;

        for(var i = 0; i < listDest.length; i++) {
          var dest = listDest[i];
          var byString = (_.isString(dest) && !_.isNumber(dest)) ? dest : ((_.isObject(dest)) ? dest.libelle : null);
          if(!_.isNil(byString)) {
            if(_.startsWith(byString, val+"-")) hasDest = dest;
            else if(_.startsWith(byString, val)) hasDest = dest;
          }
          if(!_.isNil(hasDest)) break;
        }
        return hasDest;
      },
      getDestinataireMenage: function(){
        return this.getIntervenantDestinataire("menage");
      },
      getDestinataireTechnique: function(){
        return this.getIntervenantDestinataire("technique");
      },

      getDestinatairesEmail: function(ifEmptyCheckInDestinataires){
        var dests = this.destinatairesEmail;
        if(ifEmptyCheckInDestinataires && (!_.isNil(dests) || _.isEmpty(dests)) && this.getDestinataires().length){
          if(!_.isArray(dests)) dests = [];
          var currentDests = this.getDestinataires();
          // Parcour les destinataires actuel
          for(var d = 0; d < currentDests.length; d++){
            var currentDest = currentDests[d];
            // Si c'est un destEmail
            if(currentDest.isDestinataireEmail) {
              var exist = false;
              // Si il y a des destinataires
              if(dests.length){
                // Parcours les destinataireEmail
                for(var e = 0; e < dests.length; e++){
                  exist = (dests[e].email === currentDest.email);
                  if(exist) break;
                }
              }
              if(!exist) dests.push(currentDest);
            }
          }
        }
        return dests;
      },
      setDestinatairesEmail: function(arrayDest){
        var destinatairesEmail = [];
        if(_.isArray(arrayDest) && arrayDest.length) {
          var DestinataireEmail = Contenu.Main.$injector.get('DestinataireEmail');
          for(var i = 0; i < arrayDest.length; i++){
            if(_.isObject(arrayDest[i]) && !arrayDest[i].isModel){
              arrayDest[i] = new DestinataireEmail(arrayDest[i]);
            }
            destinatairesEmail.push(arrayDest[i]);
          }
          DestinataireEmail = null;
        }
        this.destinatairesEmail = destinatairesEmail;
      },
      hasDestinatairesEmail: function(){
        return !_.isEmpty(this.getDestinatairesEmail());
      },

      // listeGroupeAvecEmailTemporaire
      getListeGroupeAvecEmailTemporaire: function(){
        var listeGroupe = [];
        var dests = this.getDestinataires();
        if(dests.length){
          for(var d = 0; d < dests.length; d++) {
            var currentDest = dests[d];
            if(_.isObject(currentDest) && currentDest.isGroupe &&
              _.isObject(currentDest.getGroupeInformations()) && currentDest.getGroupeInformations().getHasEmailTemporaire() &&
              currentDest.getGroupeInformations().getEmail()) {
              listeGroupe.push(currentDest);
            }
          }
        }
        return listeGroupe;
      },

      // Destinataires Caché
      getDestinatairesHide: function(){
        return this.destinatairesHide;
      },
      setDestinatairesHide: function(arrayDestinataires){
        var destinataires = [];
        if(arrayDestinataires){
          destinataires = arrayDestinataires;
        }
        this.destinatairesHide = destinataires;
      },

      // Tout le monde à lu le contenu
      getToutLeMondeALu: function(){
        return this.toutLeMondeALu;
      },
      setToutLeMondeALu: function(bool){
        this.toutLeMondeALu = bool;
      },

      // Liste des destinataires qui ont lu le contenu
      getListeDestinatairesQuiOntLu: function(){
        return this.listeDestinatairesQuiOntLu;
      },
      setListeDestinatairesQuiOntLu: function(arrayDestinataires){
        if(!arrayDestinataires){
          arrayDestinataires = [];
        }
        this.listeDestinatairesQuiOntLu = arrayDestinataires;
      },

      // Liste courrier
      getListeCourrier: function(){
        if(!_.isArray(this.listeCourrier)){
          this.listeCourrier = [];
        }
        return this.listeCourrier;
      },
      setListeCourrier: function(arrayCourriers){
        var arrayListeDocuments = [];
        if(_.isArray(arrayCourriers) && arrayCourriers.length){
          for(var i = 0 ; i < arrayCourriers.length ;  i++){
            if (!arrayCourriers[i].isModel) {
              arrayCourriers[i] = new Courrier(arrayCourriers[i]);
            }
            arrayListeDocuments.push(arrayCourriers[i]);
          }
        }
        this.listeCourrier = arrayListeDocuments;
      },
      addCourrier: function(objectCourrier){
        var arrayListeCourrier = this.getListeCourrier();
        if(_.isObject(objectCourrier)){
          if (!objectCourrier.isModel) {
            objectCourrier = new Courrier(objectCourrier);
          }
          arrayListeCourrier.push(objectCourrier);
        }
        this.listeCourrier = arrayListeCourrier;
      },

      // Liste des documents
      getListeDocument: function(){
        if(!_.isArray(this.listeDocument)){
          this.listeDocument = [];
        }
        return this.listeDocument;
      },
      setListeDocument: function(arrayDocuments){
        var arrayListeDocuments = [];
        if(_.isArray(arrayDocuments) && arrayDocuments.length){
          for(var i = 0 ; i < arrayDocuments.length ;  i++){
            if (!arrayDocuments[i].isModel && arrayDocuments[i].hasOwnProperty('guid')) {
              arrayDocuments[i] = new Document(arrayDocuments[i]);
              // Si contenu de type facture alors type les document en 'facture'
              if(this.typeContenu === 5) {
                arrayDocuments[i].typeDocument = 'facture';
              }
            }
            arrayListeDocuments.push(arrayDocuments[i]);
          }
        }
        this.listeDocument = arrayListeDocuments;
      },
      addDocument: function(objectDocument){
        var arrayListeDocument = this.getListeDocument();
        if(_.isObject(objectDocument)){
          if (!objectDocument.isModel) objectDocument = new Document(objectDocument);
          var exist = false;
          if(arrayListeDocument.length){
            for(var i = 0; i < arrayListeDocument.length; i++) {
              if(arrayListeDocument[i].guid === objectDocument.guid) {
                exist = true;
                break;
              }
            }
          }
          if(!exist)
            arrayListeDocument.push(objectDocument);
        }
        this.listeDocument = arrayListeDocument;
      },
      removeDocument: function(doc) {
        if(_.isArray(this.listeDocument) && this.listeDocument.length && _.isObject(doc)){
          var indexFound = null;
          for(var d = 0; d < this.listeDocument.length; d++) {
            if(this.listeDocument[d].guid === doc.guid) {
              indexFound = d;
              break;
            }
          }
          this.listeDocument.splice(indexFound, 1);
        }
      },
      hasDocuments: function(){
        if(_.isArray(this.getListeDocument()) && this.getListeDocument().length){
          return true;
        }
        return false;
      },
      /**
       * Permet de récupérer un document d'un certain type dans la liste de documents du contenu
       * @param type
       * @returns {*}
       */
      getDocumentWithTypeFromListeDocument: function(type){
        var doc = null;
        if(this.getListeDocument().length){
          for(var i = 0; i < this.getListeDocument().length; i++) {
            if(this.getListeDocument()[i].getTypeDocument() === type) {
              doc = this.getListeDocument()[i];
              break;
            }
          }
        }
        return doc;
      },
      /**
       * Permet de récupérer un document d'un certain type dans la liste de documents du contenu
       * @param type
       * @returns {*}
       */
      getListDocumentWithTypeFromListeDocument: function(type){
        var doc = [];
        if(this.getListeDocument().length){
          for(var i = 0; i < this.getListeDocument().length; i++) {
            if(this.getListeDocument()[i].getTypeDocument() === type) {
              doc.push(this.getListeDocument()[i]);
            }
          }
        }
        return doc;
      },

      deleteDocumentFromListeDocument: function (guid) {

        var _this = this;
        var deferred = $q.defer();

        var Service = Contenu.getServiceForTypeContenu(_this);
        var params = {
          idContenu: _this.getIdContenu(),
          guidDocument: guid
        };

        Service.deleteDocumentByGuid(params)
          .then(function (contenu) {
            _this.setData(contenu);
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      // Liste des documents fournisseur
      getListeDocumentFournisseur: function(){
        if(!_.isArray(this.listeDocumentFournisseur)){
          this.listeDocumentFournisseur = [];
        }
        return this.listeDocumentFournisseur;
      },
      setListeDocumentFournisseur: function(arrayDocuments){
        var arrayListeDocumentFournisseur = [];
        if(_.isArray(arrayDocuments)){
          for(var i = 0 ; i < arrayDocuments.length ;  i++){
            if (!arrayDocuments[i].isModel) {
              arrayDocuments[i] = new Document(arrayDocuments[i]);
            }
            arrayListeDocumentFournisseur.push(arrayDocuments[i]);
          }
        }
        this.listeDocumentFournisseur = arrayListeDocumentFournisseur;
      },
      addDocumentFournisseur: function(objectDocument){
        var arrayListeDocumentFournisseur = this.getListeDocumentFournisseur();
        if(_.isObject(objectDocument)){
          if (!objectDocument.isModel) {
            objectDocument = new Document(objectDocument);
          }
          arrayListeDocumentFournisseur.push(objectDocument);
        }
        this.listeDocumentFournisseur = arrayListeDocumentFournisseur;
      },

      deleteDocumentFromListeDocumentFournisseur: function (guid) {
        var _this = this;
        var deferred = $q.defer();

        var Service = Contenu.getServiceForTypeContenu(_this);
        var params = {
          idContenu: _this.getIdContenu(),
          guidDocumentFournisseur: guid
        };

        Service.deleteDocumentByGuid(params)
          .then(function (contenu) {
            _this.setData(contenu);
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },
      /**
       * Permet de récupérer un document d'un certain type dans la liste de documents du contenu
       * @param type
       * @returns {*}
       */
      getListDocumentWithTypeFromListeDocumentFournisseur: function(type){
        var doc = [];
        if(this.listeDocumentFournisseur.length){
          for(var i = 0; i < this.listeDocumentFournisseur.length; i++) {
            if(this.listeDocumentFournisseur[i].getTypeDocument() === type) {
              doc.push(this.listeDocumentFournisseur[i]);
            }
          }
        }
        return doc;
      },

      // Liste des documents comptable
      getListeDocumentComptable: function(){
        if(!_.isArray(this.listeDocumentComptable)){
          this.listeDocumentComptable = [];
        }
        return this.listeDocumentComptable;
      },
      setListeDocumentComptable: function(arrayDocuments){
        var arrayListeDocumentComptable = [];
        if(_.isArray(arrayDocuments)){
          for(var i = 0 ; i < arrayDocuments.length ;  i++){
            if (!arrayDocuments[i].isModel) {
              arrayDocuments[i] = new Document(arrayDocuments[i]);
            }
            arrayListeDocumentComptable.push(arrayDocuments[i]);
          }
        }
        this.listeDocumentComptable = arrayListeDocumentComptable;
      },
      addDocumentComptable: function(objectDocument){
        var arrayListeDocumentComptable = this.getListeDocumentComptable();
        if(_.isObject(objectDocument)){
          if (!objectDocument.isModel) {
            objectDocument = new Document(objectDocument);
          }
          arrayListeDocumentComptable.push(objectDocument);
        }
        this.listeDocumentComptable = arrayListeDocumentComptable;
      },

      deleteDocumentFromListeDocumentComptable: function (guid) {
        var _this = this;
        var deferred = $q.defer();

        var Service = Contenu.getServiceForTypeContenu(_this);
        var params = {
          idContenu: _this.getIdContenu(),
          guidDocumentComptable: guid
        };

        Service.deleteDocumentByGuid(params)
          .then(function (contenu) {
            _this.setData(contenu);
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      getNbAutresDocuments: function () {
        var nb = 0;
        if (_.isArray(this.getListeDocument())) {
          nb = nb + this.getListeDocument().length;
        }
        if (_.isArray(this.getListeDocumentFournisseur())) {
          nb = nb + this.getListeDocumentFournisseur().length;
        }
        if (_.isArray(this.getListeDocumentComptable())) {
          nb = nb + this.getListeDocumentComptable().length;
        }
        return nb;
      },
      hasAutresDocuments: function () {
        if (_.isArray(this.getListeDocument()) && this.getListeDocument().length) {
          return true;
        }
        if (_.isArray(this.getListeDocumentFournisseur()) && this.getListeDocumentFournisseur().length) {
          return true;
        }
        if (_.isArray(this.getListeDocumentComptable()) && this.getListeDocumentComptable().length) {
          return true;
        }
        return false;
      },

      // Liste des documents
      getGuids: function(){
        return this.listeDocument;
      },
      setGuids: function(arrayDocuments){
        if(!arrayDocuments){
          arrayDocuments = [];
        }else{
          if(_.isArray(arrayDocuments)){
            var guids = [];
            angular.forEach(arrayDocuments,function(document){
              // Si c'est l'objet document on recupèrte le guid
              if(_.isObject(document)){
                guids.push(document.guid);
                // Sinon si c'est déjà le guid on le récupère
              }else{
                guids.push(document);
              }
            });
            arrayDocuments = guids;

          }
        }
        this.guids = arrayDocuments;
      },

      // Array<ProxyDevis> Liste des Devis
      getListeDevis: function () {
        return this.listeDevis;
      },
      setListeDevis: function (arrayProxyDevis) {
        if (!arrayProxyDevis) {
          arrayProxyDevis = [];
        } else {
          if (_.isArray(arrayProxyDevis)) {
            var tabtmp = [];
            if (arrayProxyDevis.length) {
              var ContenuEvenementDevis = Main.$injector.get('ContenuEvenementDevis');
              angular.forEach(arrayProxyDevis, function (devis) {
                var instanceDevis = new ContenuEvenementDevis(devis);
                tabtmp.push(instanceDevis);
              });
              ContenuEvenementDevis = null;
            }
            arrayProxyDevis = tabtmp;
          }
        }
        this.listeDevis = arrayProxyDevis;
      },

      /**
       * Permet d'ajouterou update un devis ou si le param est une liste, d'en ajouter plusieur si ils n'éxistent pas et d'update si existe déjà
       * @param devis
       */
      addDevis: function (devis) {
        var arrayListeDevis = this.getListeDevis();
        if(angular.isUndefined(arrayListeDevis)) arrayListeDevis = [];

        if (_.isObject(devis)) {
          var listDevis = [];

          if (_.isArray(devis) && devis.length) listDevis = devis;
          else listDevis.push(devis);

          var ContenuEvenementDevis = Main.$injector.get('ContenuEvenementDevis');
          for (var i = 0; i < listDevis.length; i++) {
            if (!listDevis[i].isModel) listDevis[i] = new ContenuEvenementDevis(listDevis[i]);

            var exist = false;
            if(arrayListeDevis.length) {
              for(var j = 0; j < arrayListeDevis.length; j++) {
                if(arrayListeDevis[j].idContenu === listDevis[i].idContenu) {
                  exist = true;
                  arrayListeDevis[j].setData(listDevis[i]);
                  break;
                }
              }
            }
            if(!exist) arrayListeDevis.push(listDevis[i]);
          }
          ContenuEvenementDevis = null;
        }
        //this.listeDevis = arrayListeDevis;
      },
      getDevis: function (idContenu) {
        var devisFound = null;
        if(_.isArray(this.getListeDevis()) && this.getListeDevis().length) {
          for(var i = 0; i < this.getListeDevis().length; i++) {
            if(parseInt(idContenu) === this.getListeDevis()[i].getIdContenu()) {
              devisFound = this.getListeDevis()[i];
              break;
            }
          }
        }
        return devisFound;
      },

      // Array<ProxyContrat> Liste des Contrats
      getListeContrats: function () {
        return this.listeContrats;
      },
      setListeContrats: function (arrayProxyContrat) {
        if (!arrayProxyContrat) {
          arrayProxyContrat = [];
        } else {
          if (_.isArray(arrayProxyContrat)) {
            var tabtmp = [];
            if (arrayProxyContrat.length) {
              var ContenuEvenementContrat = Main.$injector.get('ContenuEvenementContrat');
              angular.forEach(arrayProxyContrat, function (devis) {
                var instanceContrat = new ContenuEvenementContrat(devis);
                tabtmp.push(instanceContrat);
              });
              ContenuEvenementContrat = null;
            }
            arrayProxyContrat = tabtmp;
          }
        }
        this.listeContrats = arrayProxyContrat;
      },

      /**
       * Permet d'ajouter ou update un contrat ou si le param est une liste, d'en ajouter plusieur si ils n'éxistent pas et d'update si existe déjà
       * @param devis
       */
      addContrat: function (contrat) {
        var arrayListeContrat = this.getListeContrats();
        if(angular.isUndefined(arrayListeContrat)) arrayListeContrat = [];

        if (_.isObject(contrat)) {
          var listContrat = [];

          if (_.isArray(contrat) && contrat.length) listContrat = contrat;
          else listContrat.push(contrat);

          var ContenuEvenementContrat = Main.$injector.get('ContenuEvenementContrat');
          for (var i = 0; i < listContrat.length; i++) {
            if (!listContrat[i].isModel) listContrat[i] = new ContenuEvenementContrat(listContrat[i]);

            var exist = false;
            if(arrayListeContrat.length) {
              for(var j = 0; j < arrayListeContrat.length; j++) {
                if(arrayListeContrat[j].idContenu === listContrat[i].idContenu) {
                  exist = true;
                  arrayListeContrat[j].setData(listContrat[i]);
                  break;
                }
              }
            }
            if(!exist) arrayListeContrat.push(listContrat[i]);
          }
          ContenuEvenementContrat = null;
        }
        this.listeContrats = arrayListeContrat;
      },
      getContrat: function (idContenu) {
        var contratFound = null;
        if(_.isArray(this.getListeContrats()) && this.getListeContrats().length) {
          for(var i = 0; i < this.getListeContrats().length; i++) {
            if(parseInt(idContenu) === this.getListeContrats()[i].getIdContenu()) {
              contratFound = this.getListeContrats()[i];
              break;
            }
          }
        }
        return contratFound;
      },

      /**
       * Permet d'avoir la liste des demandes (devis et contrats)
       * @returns {[]}
       */
      getListDemande: function(){
        var retour = [];
        if(_.isArray(this.getListeDevis()) && _.isArray(this.getListeContrats())){
          retour = _.concat(this.getListeDevis(), this.getListeContrats());
        }
        else if(_.isArray(this.getListeDevis()) && !_.isArray(this.getListeContrats())) retour = this.getListeDevis();
        else if(!_.isArray(this.getListeDevis()) && _.isArray(this.getListeContrats())) retour = this.getListeContrats();
        return retour;
      },

      /**
       * Permet de savoir si il a des demandes
       * @returns {arg is Array<any> | *}
       */
      hasListeDemande: function () {
        return (_.isArray(this.getListDemande()) && this.getListDemande().length);
      },

      // Etat
      getEtat: function(){
        return this.etat;
      },
      setEtat: function(valEtat){
        this.etat = valEtat;
      },

      // Signature
      getSignature: function(){
        return this.signature;
      },
      setSignature: function(valEtat){
        this.signature = valEtat;
      },

      /**
       * Si le contenu est en attente ou en cours de création
       * @returns {boolean}
       */
      isWaitingCreate: function(){
        if(this.getEtat() === 0) return true;
        return false;
      },
      /**
       * Si le contenu est en attente ou en cours d'update
       * @returns {boolean}
       */
      isWaitingUpdate: function(){
        if(this.getEtat() === 1) return true;
        return false;
      },
      /**
       * Si le contenu est en attente ou en cours de duplication
       * @returns {boolean}
       */
      isWaitingDuplicate: function(){
        if(this.getEtat() === 2) return true;
        return false;
      },

      /**
       * Si le contenu est en attente ou en cours d'attente de reponse par websocket
       * @returns {boolean}
       */
      isWaitingFromWebsocket: function(){
        if(this.isWaitingCreate() || this.isWaitingUpdate() || this.isWaitingDuplicate()) return true;
        return false;
      },

      /**
       * Si le contenu est créé
       * @returns {boolean}
       */
      isCreated: function(){
        if(this.getEtat() === null || angular.isUndefined(this.getEtat())) return true;
        return false;
      },

      /**
       * Si le contenu est désactivé
       * @returns {boolean}
       */
      isDisabled: function(){
        if(this.getEtat() === -1 || this.getEtat() === '-1') return true;
        return false;
      },

      /**
       * Si le contenu est en erreur
       * @returns {boolean}
       */
      isError: function(){
        if(this.getEtat() === -2 || this.getEtat() === '-2') return true;
        return false;
      },

      // Lu
      getLu: function(){
        return this.lu;
      },
      setLu: function(bool){
        this.lu = bool;
      },

      /*
      // Nom créateur
      getNomCreateur: function(){
        return this.nomCreateur;
      },
      setNomCreateur: function(createur){
        this.nomCreateur = createur;
      },*/

      // Expediteur
      getExpediteur: function(){
        var expediteur = this.expediteur;
        if(_.isNil(expediteur) && _.isObject(this.getEmailExpediteur())){
          expediteur = this.getEmailExpediteur();
        }
        return expediteur;
      },
      setExpediteur: function(createur){
        if(_.isObject(createur) && !createur.isModel) createur = new Groupe(createur);
        this.expediteur = createur;
      },

      // emailExpediteur
      getEmailExpediteur: function(){
        return this.emailExpediteur;
      },
      setEmailExpediteur: function(createur){
        if((_.isObject(createur) && !createur.isModel) || _.isString(createur)) createur = new DestinataireEmail(createur);
        this.emailExpediteur = createur;
      },

      // Liste de tags
      getTags: function(){
        if(!_.isArray(this.tags)){
          this.tags = [];
        }
        return this.tags;
      },
      setTags: function(arrayTags){
        if(!arrayTags){
          arrayTags = [];
        }
        if(arrayTags.length && this.allowTags()){

          var isTagEvenement = false;
          if(this.getTypeContenu() === 2){
            isTagEvenement = true;
          }

          for(var i = 0; i < arrayTags.length ; i++){
            if(arrayTags[i].hasOwnProperty('typeTag')){
              delete arrayTags[i].typeTag;
            }
            if(!arrayTags[i].isModel){
              arrayTags[i] = new Tag(arrayTags[i]);
            }
            arrayTags[i].setTagEvenement(isTagEvenement);
          }
        }
        this.tags = arrayTags;
      },
      hasTag: function(libelle){
        return TagsService.tagExistIn(this.getTags(), libelle);
      },

      // Si contenu de type Message ou Evenement on peut gérer les tags, si autre (devis, intervention etc...) pas de tags
      allowTags: function(){
        if(this.getTypeContenu() === 1 || this.getTypeContenu() === 2) {
          return true;
        }
        return false;
      },

      /**
       * Permet de savoir si le contenu peut etre relancé au non
       * @returns {boolean}
       */
      allowRelance: function(){
        // Si il y a une config de relance (créé lors de la création de la demande par exemple)
        //if(this.relanceAutoIsEnabled()) return true;

        // Si il y a un objet ContenuInformation et que la relance n'est pas autorisée
        if(_.isObject(this.getContenuInformations()) && !this.getContenuInformations().relanceIsAllowed()) {
          return false;
        }

        // Si bien créé, pas cloturé, qu'il y a un destinataire et un idContenu
        if(this.isCreated() && !this.isCloture() && !_.isNil(this.getDestinataire()) && !_.isNil(this.getIdContenu())) {

          // Si demande de devis
          if(this.isContenuDevis()) {

            // Si en attente et pas de pas de document devis
            if(this.isWaiting() && !this.hasDevis()) return true;

            // Si accepté, signé mais pas de facture
            if(this.isAccepte() && this.isSigne() && !this.hasFacture()) return true;
          }

          // Si demande intervention sans facture
          if(this.isContenuIntervention() && !this.hasFacture()) return true;

          // Si demande de contrat sans contrat signé par le fournisseur reçu
          if(this.isContenuContrat() && !this.hasContratFournisseurSigne()) return true;
        }
        return false;
      },

      // Liste de tags typé
      getTagsType: function(){
        if(!_.isArray(this.tagsType)) this.tagsType = [];
        return this.tagsType;
      },
      setTagsType: function(arrayTagsType){

        if(!arrayTagsType){
          arrayTagsType = [];
        }
        if(arrayTagsType.length && this.allowTags()){

          for(var i = 0 ; i < arrayTagsType.length ; i++){
            if(!arrayTagsType[i].isModel){
              arrayTagsType[i] = new Tag(arrayTagsType[i]);
            }
          }
        }
        this.tagsType = arrayTagsType;
      },
      pushTagsType: function(tag){
        if(_.isObject(tag)) {
          // Si tag n'est pas une instance de Tag
          if(!tag.isModel){
            tag = new Tag(tag);
          }
          this.tagsType.push(tag);
        }
      },
      addTagType: function(obj,libelleTypeTag){

        var tag = false;

        if(!libelleTypeTag){
          console.error('[Erreur] Contenu.addTagsType : Il manque le deuxième parametre qui doit être le string du type de tag');
          return;
        }

        // Si objet
        if(_.isObject(obj)){

          // Si c'est bien une instance
          if(obj.isModel) {

            // Si c'est une instance de Tag
            if (obj.isTag) {
              tag = obj;
            }else{
              // Génère le libelle du tag
              var libelle = Tag.genereLibelleTag(obj);
              if(libelle){
                tag = {
                  'libelle': libelle
                };
              }
            }
          }

          // Si string
        }else if(angular.isString(obj)){
          tag = {
            'libelle': ((!UtilsService.startsWith(obj, '#')) ? '#' : '') + obj
          };
        }else{
          console.error('[Erreur] Contenu.addTagsType : Le 1er parametre doit être un objet ou un string');
          return;
        }

        // Si tag est bien un objet
        if(_.isObject(tag)){

          // Si il y a bien un libelle de type de tag
          if(libelleTypeTag) {
            tag.typeTag = {
              'libelle': libelleTypeTag
            };
          }

          if(!_.isArray(this.tagsType)){
            this.tagsType = [];
          }

          // Si tag n'est pas une instance de Tag
          if(!tag.isTag){
            tag = new Tag(tag);
          }

          this.tagsType.push(tag);
        }
      },
      hasTagType: function(type){
        return TagsService.hasTagType(this.getTagsType(),type);
        /*
                 var retour = false;
                 var tagType = this.getTagsType();

                 // Si tags est un array
                 if(_.isArray(tagType)){
                 // Si cet array est différent de 0
                 if(tagType.length){

                 for(var i = 0 ; i < tagType.length ; i++){
                 if(tagType[i].hasOwnProperty('typeTag')) {
                 var TypeTag = tagType[i].typeTag;
                 if (_.isObject(TypeTag)) {
                 var lib = TypeTag.libelle;
                 if (lib === type) {
                 retour = true;
                 break;
                 }
                 }
                 }
                 }
                 }
                 }
                 return retour;*/
      },

      removeTag: function(tag,typeTag){
        //var idTag = false,isTagType = typeTag;
        /*
                 if(tag.hasOwnProperty('idTag')){
                 idTag = tag.idTag;
                 }*/

        /*
                 console.log('this.tagsType',this.tagsType);
                 console.log('tag',tag);
                 console.log('typeTag',typeTag);*/
        if(typeTag){
          for(var i = 0 ; i < this.tagsType.length ; i++){
            /*
                         if(idTag){
                         if(this.tagsType[i].hasOwnProperty('idTag')){
                         if(this.tagsType[i].idTag ===idTag){
                         this.tagsType.splice(i,1);
                         }
                         }
                         }else{*/
            if(this.tagsType[i].libelle === tag.libelle){
              if(this.tagsType[i].typeTag.libelle===typeTag){
                this.tagsType.splice(i,1);
              }
            }
            //}
          }
        }else{
          for(var j = 0 ; j < this.tags.length ; j++){
            /*
                         if(idTag){
                         if(this.tags[j].hasOwnProperty('idTag')){
                         if(this.tags[j].idTag ===idTag){
                         this.tags.splice(j,1);
                         }
                         }
                         }else{*/
            var lib = null;

            if(_.isObject(tag)) lib = tag.libelle;
            else if(_.isString(tag)) lib = tag;

            if(this.tags[j].libelle === lib){
              this.tags.splice(j,1);
            }
            //}
          }

        }
      },
      removeTags: function(tags,typeTag){

        var scope = this;
        try{
          if(!_.isArray(tags)){
            throw new Error('Le 1er paramètres doit être un array de Tag!');
          }

          for(var i = 0 ; i < tags.length ; i++){
            scope.removeTag(tags[i],typeTag);
          }
        }
        catch(err){
          console.log('[Contneu.removeTags] Erreur : '+err.message);
        }
      },


      removeTagType: function(obj,libelleTypeTag){

        var libelle,_this = this;
        if(_.isObject(obj)){
          if(obj.isModel){
            libelle = Tag.genereLibelleTag(obj);
          }
        }else if(angular.isString(obj)){
          libelle = obj;
        }else{
          console.error('[Erreur] Contenu.removeTagType : Le 1er parametre doit être un objet ou un string');
          return;
        }

        if(!libelleTypeTag){
          console.error('[Erreur] Contenu.removeTagType : Le 2eme parametre doit être le string du type de tag');
          return;
        }
        if(!libelle || libelle === '' || angular.isUndefined(libelle)){
          //console.error('[Erreur] Contenu.removeTagType : Libelle vide !');
          //console.log('obj',obj);
          return;
        }
        libelle = UtilsService.ltrim(libelle,'#');
        /*
                 var search = {
                 'libelle':libelle,
                 'typeTag':{
                 'libelle':libelleTypeTag
                 }
                 };
                 console.log('search',search);
                 this.tagsType = UtilsService.removeWith(this.tagsType,search);*/


        for(var j = 0 ; j < this.tagsType.length ; j++){
          var tag = _this.tagsType[j];
          var currentTaglibelleTag = UtilsService.ltrim(tag.getLibelle(),'#');


          // Si le tag est un tag type
          if(tag.isTagType()){
            var currentTagInstanceTypeTag = tag.getTypeTag();
            //console.log(currentTaglibelleTag+' === '+libelle +' && '+ libelleTypeTag +' === '+ currentTagInstanceTypeTag.getLibelle());
            // Si le libelle du tag est égale au libelle tu tag actuel
            // Si le type de tag en paramètre est égale au libelle du type de tag actuel
            if(currentTaglibelleTag===libelle && libelleTypeTag === currentTagInstanceTypeTag.getLibelle()){

              /*
                             // Si le contenu à un id
                             if(_this.getIdContenu()){

                             _this.deleteTag(tag,true).then(function(){
                             _this.tagsType.splice(j,1);
                             });
                             */
              // Si le contenu n'a pas d'id
              if(!_this.getIdContenu()){
                _this.tagsType.splice(j,1);
              }
            }
          }
        }
      },

      deleteGenerateTags: function(array){

        var _this = this;
        if(_.isArray(array)){
          for(var j = 0 ; j < array.length ; j++){
            var tag = array[j];
            // Si tag actuel est un tag type
            if(tag.hasOwnProperty('typeTag')){
              // Est-ce que le contenu actuel à des tags type
              if(_this.tagsType.length){
                // Parcour les tags type du contenu
                for(var k = 0 ; k < _this.tagsType.length ; k++){
                  // Si le libelle du tag  est le meme que les tag à supprimer ET le libelle tu type de tag ex : 'Projet'
                  if(_this.tagsType[k].libelle === tag.libelle && _this.tagsType[k].typeTag.libelle === tag.typeTag.libelle){
                    _this.tagsType.splice(k,1);
                  }
                }
              }

              // Si tag normal
            }else{
              // Est-ce que le contenu actuel à des tags
              if(_this.tags.length){
                // Parcour les tags du contenu
                for(var l = 0 ; l < _this.tags.length ; l++){
                  // Si le libelle du tag est le meme que les tag à supprimer
                  if(_this.tags[l].libelle === tag.libelle){
                    _this.tags.splice(l,1);
                  }
                }
              }
            }

          }
        }
      },

      // contenuInformations
      getContenuInformations: function(){
        return this.contenuInformations;
      },
      setContenuInformations: function(val){
        if(_.isObject(val)) {
          if(!val.isModel) {
            var ContenuInformations = new Main.$injector.get('ContenuInformations');
            val = new ContenuInformations(val);
          }
          this.contenuInformations = val;
        }
      },

      // demandeOptions
      getDemandeOptions: function(){
        return this.demandeOptions;
      },
      setDemandeOptions: function(val){
        if(_.isObject(val)) {
          if(!val.isModel) {
            var DemandeOptions = new Main.$injector.get('DemandeOptions');
            val = new DemandeOptions(val);
          }
          this.demandeOptions = val;
        }
      },

      // SendByMail
      getSendByMail: function(){
        return this.sendByMail;
      },
      setSendByMail: function(bool){
        this.sendByMail = bool;
      },

      // SendOneMailWithAllDest
      getSendOneMailWithAllDest: function(){
        return this.sendOneMailWithAllDest;
      },
      setSendOneMailWithAllDest: function(bool){
        this.sendOneMailWithAllDest = bool;
      },

      // allowReplyToSMS
      getAllowReplyToSMS: function(){
        return this.allowReplyToSMS;
      },
      setAllowReplyToSMS: function(bool){
        this.allowReplyToSMS = bool;
      },

      // Type de contenu
      getTypeContenu: function(){
        return this.typeContenu;
      },
      setTypeContenu: function(type){
        this.typeContenu = type;
      },

      // PersoICs
      getPersoICS: function(){
        return this.persoICS;
      },
      setPersoICS: function(val){
        if(_.isObject(val)){
          val = new PersoIcs(val);
        }
        this.persoICS = val;
      },

      relanceAutoIsEnabled: function(){
        if(_.isObject(this.getRelanceAutoConfig())) return this.getRelanceAutoConfig().getEnabled();
        return false;
      },

      // Array<Date> DateRelances
      /*
      getDateRelances: function () {
        return this.dateRelances;
      },
      setDateRelances: function (arrayDates) {
        if (!arrayDates) {
          arrayDates = [];
        }
        this.dateRelances = arrayDates;
      },*/

      // relanceAutoConfig
      getRelanceAutoConfig: function(){
        return this.relanceAutoConfig;
      },
      setRelanceAutoConfig: function(val){
        if(_.isObject(val) && !val.isModel) val = new RelanceAutoConfig(val);
        this.relanceAutoConfig = val;
      },
      initRelanceAutoConfig: function(val){
        if(_.isNil(this.getRelanceAutoConfig())) {
          if(_.isNil(val)) val = {
            enabled: false
          };
          this.setRelanceAutoConfig(val);
        }
      },

      /*
      getDateDepartRelance: function(){
        var today = moment().startOf('day');
        if(_.isObject(this.getRelanceAutoConfig()) && !_.isNil(this.getRelanceAutoConfig().getDateDepartRelance())){
          var dateDepartRelanceInConfig = moment(this.getRelanceAutoConfig().getDateDepartRelance()).startOf('day');

          // Si la date de départ de la relance dans la config est dépassée on retourne aujourd'hui
          if(today.isAfter(dateDepartRelanceInConfig)) {
            return today;
          }
          return this.getRelanceAutoConfig().getDateDepartRelance();
        }

        // Recup la date de dernière relance
        var dateDerniereRelance = this.getDateDerniereRelance();
        // Si pas de dernière relance alors prend la date de création de la demande
        var dateRetour = (_.isNil(dateDerniereRelance)) ? this.getDateCreation() : dateDerniereRelance;

        // Si la date d'aujourd'hui est après la date de départ pour les relance on set la date de départ à aujourd'hui
        if(moment(today).isAfter(dateRetour)) dateRetour = today;

        return dateRetour;
      },
      */

      // listeRelance
      getListeRelance: function(){
        if(!_.isArray(this.listeRelance)) this.setListeRelance([]);
        return this.listeRelance;
      },
      setListeRelance: function(val){
        if(_.isArray(val) && val.length){
          for(var i = 0; i < val.length; i++) {
            if(_.isObject(val[i]) && !val[i].isModel) val[i] = new Relance(val[i]);
          }
        }
        this.listeRelance = val;
      },

      /**
       * Recupère la dernière relance
       * @returns {*}
       */
      getDerniereRelance: function(){
        return this.derniereRelance;
      },
      setDerniereRelance: function(val){
        this.derniereRelance = (_.isObject(val) && !val.isModel) ? new Relance(val) : val;
      },

      /**
       * Recupère la dernière date de relance
       * @returns {*}
       */
      getDateDerniereRelance: function(format){
        var dateRelance = (_.isObject(this.getDerniereRelance())) ? this.getDerniereRelance().getDateRelance(format) : null;
        if(_.isNil(dateRelance) && _.isArray(this.getListeRelance()) && !_.isEmpty(this.getListeRelance())) {
          this.sortDatesRelance();
          return this.getListeRelance()[0].getDateRelance(format);
        }
        return dateRelance;
      },
      getDateDerniereRelanceAgo: function(){
        var retour = null;
        if(!_.isNil(this.getDateDerniereRelance())){
          retour = moment.duration(moment(new Date()).diff(this.getDateDerniereRelance())).humanize();
        }
        return retour;
      },

      // dateDepartRelance
      getDateDepartRelance: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateDepartRelance, format);
        }
        return this.dateDepartRelance;
      },
      getDateDepartRelanceAgo: function(){
        var retour = this.dateDepartRelance;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },
      setDateDepartRelance: function(date){
        this.dateDepartRelance = date;
      },

      // typeDateDepartRelance
      getTypeDateDepartRelance: function(){
        return this.typeDateDepartRelance;
      },
      setTypeDateDepartRelance: function(val){
        this.typeDateDepartRelance = val;
      },

      // dateProchaineRelance
      getDateProchaineRelance: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateProchaineRelance, format);
        }
        return this.dateProchaineRelance;
      },
      getDateProchaineRelanceAgo: function(){
        var retour = this.dateProchaineRelance;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },
      setDateProchaineRelance: function(date){
        this.dateProchaineRelance = date;
      },

      /**
       * Recupère la liste des dates de relance pour un devis
       * @returns {string}
       */
      getListeDateRelanceInHTML: function () {
        var retour = '';
        if (_.isArray(this.getListeRelance()) && !_.isEmpty(this.getListeRelance())) {

          this.sortDatesRelance();

          for(var i = 0; i < this.getListeRelance().length; i++) {
            if(i===0) retour += '<strong>';
            retour += this.getListeRelance()[i].getDateRelance('D MMM YYYY à HH:mm');
            if(i===0) retour += '</strong>';

            if(this.getListeRelance()[i].isRelanceAuto()) retour += '<i class="fa fa-clock-o margin-left-5"></i>';
            else retour += '<i class="fa fa-hand-paper-o margin-left-5"></i>';

            retour += '<br />';
          }
        }
        return retour;
      },

      /**
       * Permet de trier les dates de relance de la plus récente à la plus ancienne
       */
      sortDatesRelance: function(){
        if(_.isArray(this.getListeRelance()) && !_.isEmpty(this.getListeRelance()) && this.getListeRelance().length > 1) {
          this.getListeRelance().sort(function(a,b){
            return new Date(b.dateRelance) - new Date(a.dateRelance);
          });
        }
      },

      /*
             // Tag Projet
             getTagProjet: function(){
             return this.tagProjet;
             },
             setTagProjet: function(tag){
             this.tagProjet = tag;
             },*/

      // Update
      getUpdate: function(){
        return this.update;
      },
      setUpdate: function(bool){
        this.update = bool;
      },

      // idContenuParent
      getIdContenuParent: function(){
        return this.idContenuParent;
      },
      setIdContenuParent: function(val){
        this.idContenuParent = val;
      },

      /**
       * Permet de récupérer un tag de type bien special
       * @param type
       * @returns {boolean}
       */
      getTagForType: function(type){

        return TagsService.getTagForType(this.getTagsType(),type);
        /*
                 var retour = false;
                 var tagType = this.getTagsType();

                 // Si tags est un array
                 if(_.isArray(tagType)){

                 // Si cet array est différent de 0
                 if(tagType.length){
                 for(var i = 0 ; i < tagType.length ; i++){
                 if(tagType[i].hasOwnProperty('typeTag')) {
                 var TypeTag = tagType[i].typeTag;
                 if (_.isObject(TypeTag)) {
                 var lib = TypeTag.libelle;
                 if (lib === type) {
                 retour = tagType[i];
                 break;
                 }
                 }
                 }
                 }
                 }
                 return retour;
                 }*/
      },
      getTagImmeuble: function(type){
        return TagsService.getTagTypeImmeuble(this.getTagsType());
      },
      getTagPortefeuille: function(){
        return TagsService.getTagForType(this.getTagsType(), COLLAB_CONF.TAG_TYPE_PORTEFEUILLE);
      },
      portefeuilleIsBlocked: function(){
        var tagPortefeuille = this.getTagPortefeuille();
        if(_.isObject(tagPortefeuille) && tagPortefeuille.portefeuilleIsBlocked()) return true;
        return false;
      },
      getPortefeuille: function(){
        var tagPortefeuille = this.getTagPortefeuille();
        if(_.isObject(tagPortefeuille)) return tagPortefeuille.getPortefeuille();
        return null;
      },

      getTagsWithType: function(type){
        /*
                 var retour = [];
                 var tagType = this.getTagsType();
                 */

        return TagsService.getTagsForType(this.getTagsType(),type);
        /*
                 // Si tags est un array
                 if(_.isArray(tagType)){
                 // Si cet array est différent de 0
                 if(tagType.length){

                 // Pour chaque tagsType
                 angular.forEach(tagType,function(tag){

                 if(tag.hasOwnProperty('typeTag')) {
                 var TypeTag = tag.typeTag;

                 if (_.isObject(TypeTag)) {
                 var lib = TypeTag.libelle;

                 if (lib === type) {

                 retour.push(tag);
                 }
                 }
                 }
                 });
                 }
                 }
                 return retour;*/
      },

      getTagsWithoutType: function(type){
        return TagsService.getTagsWithoutType(this.getTagsType(),type);
        /*
                 var retour = [];
                 var tagType = this.getTagsType();

                 // Si tags est un array
                 if(_.isArray(tagType)){
                 // Si cet array est différent de 0
                 if(tagType.length){

                 // Pour chaque tagsType
                 angular.forEach(tagType,function(tag){

                 if(tag.hasOwnProperty('typeTag')) {

                 var TypeTag = tag.getTypeTag();

                 if (_.isObject(TypeTag)) {
                 var lib = TypeTag.getLibelle();

                 if (lib !== type) {

                 retour.push(tag);
                 }
                 }
                 }
                 });
                 }
                 }
                 return retour;*/
      },

      /**
       * Ajoute un tag au contenu
       * @param {Array} arrayTags - Tag à ajouter
       * @returns {Object} Promesse - Array avec les intances de Tag
       */
      /*
             addTag: function(tag,limit){
             var scope = this;
             var deferred = $q.defer();

             if(!tag.isModel){
             tag = new Tag(tag);
             }
             var libelleTypeTag = tag.isTagType();

             var canAdd = true;

             if(limit){
             if (libelleTypeTag) {
             if(this.getTagsWithType(libelleTypeTag).length>=limit){
             canAdd = false;
             }
             }else{
             if(this.getTags().length>=limit){
             canAdd = false;
             }
             }
             }

             // Si la limit n'est pas atteinte
             if(canAdd){
             var currentTags;

             // Si il y a bien un idContenu
             if(this.getIdContenu()){

             var contenuAjax = new ContenuAjax();
             contenuAjax.contenu = this.getIdContenu();
             contenuAjax.tags = [tag];
             if(libelleTypeTag){
             contenuAjax.type = 1;
             }
             contenuAjax.$addTags(function(json){

             if(json.nb!=='0'){


             var instancesNewTag = [];
             // Si tag typé
             if(libelleTypeTag){
             // Recupère les tags de l'objet actuel
             currentTags = scope.getTagsWithType(libelleTypeTag);
             }else{
             // Recupère les tags de l'objet actuel
             currentTags = scope.getTags();
             }


             // Parcour les nouveaux tags ajoutés pour en créé une instance de Tag
             angular.forEach(json.Tags,function(tag){

             var tagsWhere = UtilsService.where(currentTags,{'idTag':tag.idTag});
             // Si le tag n'est pas dans le tableau des tags
             if(!tagsWhere.length){

             var instanceNewTag = new Tag(tag);
             //console.log(instanceNewTag);

             // Ajoute l'instance du tag au tableau des tags de l'objet
             currentTags.push(instanceNewTag);
             // Ajoute l'instance du tag au tableau des nouveau tag qui seront retournés
             instancesNewTag.push(instanceNewTag);
             }
             });

             // Si tag typé
             if(libelleTypeTag){
             // Recup les tags différent du type
             var tagsWithoutType = scope.getTagsWithoutType(libelleTypeTag);

             // Concatène le tableau des tags du type demandé avec le tableau des autres type tag
             currentTags.concat(tagsWithoutType);

             // Set les nouveau tagsType de l'objet actuel
             scope.setTagsType(currentTags);
             }else{
             // Set les nouveau tags de l'objet actuel
             scope.setTags(currentTags);
             }


             // Retour la listre des instances des nouveaus tags
             deferred.resolve(instancesNewTag);
             }else{
             deferred.reject('Tag inexistant [Contenu.addTag()]');
             }
             },function(){
             deferred.reject('Problème Ajax [Contenu.addTag()]');
             });
             }else {

             var tagsWhere = [];



             if (libelleTypeTag) {
             tagsWhere = UtilsService.where(this.getTagsType(), {
             'libelle': tag.libelle,
             'typeTag': {'libelle': libelleTypeTag}
             });
             } else {
             tagsWhere = UtilsService.where(this.getTags(), {'libelle': tag.libelle});
             }

             // Si le tag n'est pas dans le tableau des tags
             if (!tagsWhere.length) {

             if (!tag.isModel) {
             tag = new Tag(tag);
             }

             if(libelleTypeTag){
             this.pushTagsType(tag);
             }else{
             // Ajoute l'instance du tag au tableau des tags de l'objet
             this.pushTags(tag);
             }

             }

             if(libelleTypeTag){
             // Retour la listre des instances des nouveaus tags
             deferred.resolve(this.getTagsWithType(libelleTypeTag));
             }else{
             // Retour la listre des instances des nouveaus tags
             deferred.resolve(this.getTags());
             }
             }
             }else{
             deferred.reject('Limit de tag atteinte !');
             }



             return deferred.promise;
             },*/

      /**
       * Ajoute un tag au contenu
       * @param {Array} arrayTags - Tableau de tags à ajouter
       * @returns {Object} Promesse - Array avec les intances de Tag
       */
      addTags: function(arrayTags,isType){
        var scope = this;
        var deferred = $q.defer();
        var error = {etat:false};


        var currentTags;

        // Si il y a bien un idContenu
        if(this.getIdContenu()){

          /*
                     var objParams = {
                     'contenu': this.getIdContenu(),
                     'tags': arrayTags
                     };
                     if(isType){
                     objParams.type = 1;
                     }

                     console.log(objParams);*/

          var contenuAjax = new ContenuAjax();
          contenuAjax.contenu = this.getIdContenu();
          contenuAjax.tags = arrayTags;
          if(isType){
            contenuAjax.type = 1;
          }

          contenuAjax.$addTags(function(json){
            //ContenuAjax.addTags(objParams,function(json){
            //console.log(json);
            if(json.nb!=='0'){


              var instancesNewTag = [];
              // Si tag typé
              if(isType){
                // Recupère les tags de l'objet actuel
                currentTags = scope.getTagsWithType(isType);
              }else{
                // Recupère les tags de l'objet actuel
                currentTags = scope.getTags();
              }


              // Parcour les nouveaux tags ajoutés pour en créé une instance de Tag
              angular.forEach(json.Tags,function(tag){

                var tagsWhere = UtilsService.where(currentTags,{'idTag':tag.idTag});
                // Si le tag n'est pas dans le tableau des tags
                if(!tagsWhere.length){

                  var instanceNewTag = new Tag(tag);

                  instanceNewTag.cleanTag(isType);

                  // Ajoute l'instance du tag au tableau des tags de l'objet
                  currentTags.push(instanceNewTag);
                  // Ajoute l'instance du tag au tableau des nouveau tag qui seront retournés
                  instancesNewTag.push(instanceNewTag);

                }
              });

              // Si tag typé
              if(isType){
                // Recup les tags différent du type
                var tagsWithoutType = scope.getTagsWithoutType(isType);

                // Concatène le tableau des tags du type demandé avec le tableau des autres type tag
                currentTags.concat(tagsWithoutType);

                // Set les nouveau tagsType de l'objet actuel
                scope.setTagsType(currentTags);
              }else{
                // Set les nouveau tags de l'objet actuel
                scope.setTags(currentTags);
              }


              // Retour la listre des instances des nouveaus tags
              deferred.resolve(instancesNewTag);
            }else{
              error.etat = true;
              error.msg = 'Tag inexistant [Contenu.addTags()]';
            }
          },function(){
            error.etat = true;
            error.msg = 'Problème Ajax [Contenu.addTags()]';
          });
        }else {
          /*
                     error.etat = true;
                     error.msg = 'Pas de contenu [Contenu.addTags()]';*/

          // Si tag typé
          if (isType) {
            // Recupère les tags de l'objet actuel
            currentTags = scope.getTagsWithType(isType);
          } else {
            // Recupère les tags de l'objet actuel
            currentTags = scope.getTags();
          }

          // Parcour les nouveaux tags
          angular.forEach(arrayTags, function (tag) {

            var tagsWhere = [];

            if (isType) {
              tagsWhere = UtilsService.where(currentTags, {
                'libelle': tag.libelle,
                'typeTag': {'libelle': isType}
              });
            } else {
              tagsWhere = UtilsService.where(currentTags, {'libelle': tag.libelle});
            }

            // Si le tag n'est pas dans le tableau des tags
            if (!tagsWhere.length) {

              if (!tag.isModel) {
                tag = new Tag(tag);
              }

              tag.cleanTag(isType);

              // Ajoute l'instance du tag au tableau des tags de l'objet
              currentTags.push(tag);
            }
          });
          // Retour la listre des instances des nouveaus tags
          deferred.resolve(currentTags);
        }

        if(error.etat){
          deferred.reject(error);
        }
        return deferred.promise;
      },


      /**
       * Ajoute un tag au contenu sans appel ajax, quelque soit le type de tag
       * @returns {Object} Promesse - Array avec les intances de Tag
       * @param tag
       * @param typeTag
       * @param limit
       */
      pushTag: function(tag,typeTag,limit){
        if(!tag.isModel){
          tag = new Tag(tag);
        }
        var retour = false;
        var libelleTypeTag = typeTag;
        var canAdd = true;

        if(limit){
          if (libelleTypeTag) {
            if(this.getTagsWithType(libelleTypeTag).length>=limit){
              canAdd = false;
            }
          }else{
            if(this.getTags().length>=limit){
              canAdd = false;
            }
          }
        }

        // Si la limit n'est pas atteinte
        if(canAdd){

          var hasTag = false;

          if (libelleTypeTag) {
            hasTag = this.hasTagType(libelleTypeTag);
            /*
                         tagsWhere = UtilsService.where(this.getTagsType(), {
                         'libelle': tag.libelle,
                         'typeTag': {'libelle': libelleTypeTag}
                         });*/
          } else {
            hasTag = TagsService.tagExistIn(this.getTags(), tag.libelle);
          }

          // Si le tag n'est pas dans le tableau des tags
          if (!hasTag) {

            if(libelleTypeTag){
              this.pushTagsType(tag);
            }else{
              // Ajoute l'instance du tag au tableau des tags de l'objet
              this.pushTags(tag);
            }

          }

          if(libelleTypeTag){
            // Retour la listre des instances des nouveaus tags
            retour = this.getTagsWithType(libelleTypeTag);
          }else{
            // Retour la listre des instances des nouveaus tags
            retour = this.getTags();
          }
        }

        return retour;
      },

      pushTags: function(tag){
        if(_.isObject(tag)) {
          // Si tag n'est pas une instance de Tag
          if(!tag.isModel){
            tag = new Tag(tag);
          }
          this.tags.push(tag);
        }
      },

      /**
       * Supprime un tag du contenu
       * @param {Object} tag - Tags à supprimer
       * @returns {Object} Promesse
       */
      deleteTag: function(tag,isType){
        var scope = this;
        var deferred = $q.defer();
        var error = {etat:false};

        if(!tag.isModel){
          tag = new Tag(tag);
        }
        var libelleTypeTag = isType;

        if(scope.getIdContenu()){

          var idTagDel = tag.getIdTag();
          var contenuAjax = new ContenuAjax();
          contenuAjax.contenu = scope.getIdContenu();
          contenuAjax.tag = idTagDel;
          if(libelleTypeTag){
            contenuAjax.type = 1;
          }

          contenuAjax.$deleteTag(function(json){
            if(json.Resultats[0].success){

              var currentTags;
              // Si tag typé
              if(libelleTypeTag){
                // Recupère les tags de l'objet actuel
                currentTags = scope.getTagsWithType(libelleTypeTag);
              }else{
                // Recupère les tags de l'objet actuel
                currentTags = scope.getTags();
              }

              // Parcour les tags actuel de l'instance
              angular.forEach(currentTags,function(objTag,indexTag){
                // Si le tag actuellement parcouru
                if(objTag.getIdTag() === idTagDel){
                  // Supprime le tags de l'array
                  currentTags.splice(indexTag,1);
                }
              });
              // Set les nouveau tags de l'objet actuel
              scope.setTags(currentTags);

              deferred.resolve();
            }else{
              error.etat = true;
              error.msg = 'Tag inexistant [Contenu.deleteTag()]';
            }
          },function(){
            error.etat = true;
            error.msg = 'Problème Ajax [Contenu.deleteTag()]';
          });
        }else{
          error.etat = true;
          error.msg = 'Pas de Guid [Contenu.deleteTag()]';
        }

        if(error.etat){
          deferred.reject(error);
        }
        return deferred.promise;
      },

      /**
       * Supprime plusieur tags du contenu
       * @param {Object} tag - Tags à supprimer
       * @returns {Object} Promesse
       */
      deleteTags: function(tags,isType){
        var scope = this;
        var globalDeferred = $q.defer();
        var promises = [];

        if(scope.getIdContenu()){

          angular.forEach(tags,function(tag){
            var deferred = $q.defer();
            var idTagDel = tag.getIdTag();
            var contenuAjax = new ContenuAjax();
            contenuAjax.contenu = scope.getIdContenu();
            contenuAjax.tag = idTagDel;
            if(isType){
              contenuAjax.type = 1;
            }

            contenuAjax.$deleteTag(function(json){
              if(json.Resultats[0].success){

                var currentTags;
                // Si tag typé
                if(isType){
                  // Recupère les tags de l'objet actuel
                  currentTags = scope.getTagsWithType(isType);
                }else{
                  // Recupère les tags de l'objet actuel
                  currentTags = scope.getTags();
                }

                // Parcour les tags actuel de l'instance
                angular.forEach(currentTags,function(objTag,indexTag){
                  // Si le tag actuellement parcouru
                  if(objTag.getIdTag() === idTagDel){
                    // Supprime le tags de l'array
                    currentTags.splice(indexTag,1);
                  }
                });
                // Set les nouveau tags de l'objet actuel
                scope.setTags(currentTags);

                deferred.resolve();
              }
            },function(){
              console.log('Problème Ajax [Contenu.deleteTags()]');
            });

            // Cumul les promesses
            promises.push(deferred.promise);
          });
        }

        $q.all(promises).then(function(){
          globalDeferred.resolve();
        });

        return globalDeferred.promise;
      },

      /**
       * @name addDestinataires
       * @desc Ajoute un destinataire au contenu
       * @param {Array} arrayDestinataires - Tableau de destinataires à ajouter
       * @returns {Object} Promesse
       */
      addDestinataires: function(arrayDestinataires){

        var deferred = $q.defer();

        // Si il y a bien un idContenu
        if(this.getIdContenu()){
          var contenuAjax = new ContenuAjax();
          contenuAjax.contenu = this.getIdContenu();
          contenuAjax.destinataires = arrayDestinataires;
          contenuAjax.$addDestinataires(function(json){
            if(json.nb!=='0'){
              var nouveauxGroupes = [];
              for(var i = 0 ; i < json.Groupes.length ; i++){
                nouveauxGroupes.push(new Groupe(json.Groupes[i]));
              }
              deferred.resolve(nouveauxGroupes);
            }else{
              //console.log('Problème retour [Contenu.addDestinataires()]');
              deferred.reject('L\'utilisateur n\'existe pas !');
            }
          },function(){
            deferred.reject('Problème Ajax [Contenu.addDestinataires()]');
          });
        }else{
          deferred.reject('Pas de contenu [Contenu.addDestinataires()]');
        }
        return deferred.promise;
      },

      /**
       * @name lire
       * @desc Lecture du contenu
       * @returns {Object} Promesse
       */
      lire: function(){
        var _this = this;
        var deferred = $q.defer();
        var error = {etat:false};

        var idContenu = this.getIdContenu();
        // Si il y a bien un idContenu
        if(idContenu){
          var contenuAjax = new ContenuAjax();
          contenuAjax.contenu = idContenu;
          contenuAjax.$lire(function(retour){
            if(retour.Resultats[0].success){
              // TODO : [Notification] Lire les Notificaitions

              _this.setLu(true);
              deferred.resolve(error);
            }else{
              error.etat = true;
              error.msg = 'Problème de retour Ajax [Contenu.lire()]';
            }

          },function(){
            error.etat = true;
            error.msg = 'Problème Ajax [Contenu.lire()]';
          });
        }else{
          error.etat = true;
          error.msg = 'Pas de contenu [Contenu.lire()]';
        }

        if(error.etat){
          deferred.reject(error);
        }

        return deferred.promise;
      },


      addToNonLu: function(){
        var _this = this;
        var deferred = $q.defer();
        var error = {etat:false};

        var idContenu = this.getIdContenu();
        // Si il y a bien un idContenu
        if(idContenu){
          var contenuAjax = new ContenuAjax();
          contenuAjax.contenu = idContenu;
          contenuAjax.$addToNonLu(function(retour){
            if(retour.Resultats[0].success){

              _this.setLu(false);
              deferred.resolve(error);
            }else{
              error.etat = true;
              error.msg = 'Problème de retour Ajax [Contenu.addNonLu()]';
            }

          },function(){
            error.etat = true;
            error.msg = 'Problème Ajax [Contenu.addNonLu()]';
          });
        }else{
          error.etat = true;
          error.msg = 'Pas de contenu [Contenu.addNonLu()]';
        }

        if(error.etat){
          deferred.reject(error);
        }

        return deferred.promise;
      },

      deleteDocument: function(guid) {
        var _this = this;
        var deferred = $q.defer();

        var Service = Contenu.getServiceForTypeContenu(_this);
        var params = {
          idContenu: _this.getIdContenu(),
          guidDocument: guid,
          guidDocumentComptable: guid,
          guidDocumentFournisseur: guid
        };

        Service.deleteDocumentByGuid(params)
          .then(function (contenu) {
            _this.setData(contenu);
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },
      /*
      deleteDocument: function(document) {
          var deferred = $q.defer();
          var error = {etat:false};


          if(document.getGuid()){

              if(this.getIdContenu()){
                  this.setUpdate(true);

                  var documentAjax = new DocumentsAjax();
                  documentAjax.idContenu = this.getIdContenu();
                  documentAjax.guid = document.getGuid();
                  documentAjax.$deleteFileContenu(function(json){

                      if(json.Resultats[0].success){

                          deferred.resolve();
                      }else{

                          error.etat = true;
                          //error.msg = 'Document inexistant [Document.deleteDocument()]';
                          error.msg = 'Impossible de supprimer ce document du contenu';
                          deferred.reject(error);
                      }
                  },function(){
                      error.etat = true;
                      error.msg = 'Problème Delete [Document.deleteDocument()]';
                  });
              }else{
                  error.etat = true;
                  error.msg = 'Pas de Guid [Document.deleteDocument()]';
              }
          }else{
              error.etat = true;
              error.msg = 'Pas de d\'idGroupe [Document.deleteDocument()]';
          }

          if(error.etat){
              deferred.reject(error);
          }

          return deferred.promise;
      },*/

      genereParamsUpdateListDocument: function () {
        var _this = this;
        if(!_this.getListeDocument().length) return false;
        var guids = Contenu.getListGuid(_this.getListeDocument());
        return {
          guidsDocument: guids
        };
      },

      genereParamsUpdateListDocumentFournisseur: function () {
        var _this = this;
        if(!_this.getListeDocumentFournisseur().length) return false;
        var guids = Contenu.getListGuid(_this.getListeDocumentFournisseur());
        return {
          guidsDocumentFournisseur: guids
        };
      },

      updateDocuments: function () {
        var deferred = $q.defer();
        var _this = this;

        var params = _this.genereParamsUpdateListDocument();
        if(_.isObject(params))  {

          var Service = Contenu.getServiceForTypeContenu(_this);
          params.idContenu = _this.getIdContenu();

          Service.updateFieldByField(params)
            .then(function (event) {
              _this.setData(event);
              deferred.resolve(_this);
            })
            .catch(function (msg) {
              deferred.reject(msg);
            });
        }
        else deferred.resolve(_this);

        return deferred.promise;
      },

      updateDocumentsFournisseur: function () {
        var deferred = $q.defer();
        var _this = this;

        var params = _this.genereParamsUpdateListDocumentFournisseur();
        if(_.isObject(params))  {

          var Service = Contenu.getServiceForTypeContenu(_this);
          params.idContenu = _this.getIdContenu();

          Service.updateFieldByField(params)
            .then(function (event) {
              _this.hydrate(event);
              deferred.resolve(_this);
            })
            .catch(function (msg) {
              deferred.reject(msg);
            });
        }
        else deferred.resolve(_this);

        return deferred.promise;
      },

      // Montant
      getMontant: function () {
        return this.montant;
      },
      setMontant: function (string) {
        if (angular.isDefined(string) && angular.isString(string)) {
          string = string.replace(',', '.');
        }
        this.montant = string;
      },

      changeMontant: function (val) {
        var _this = this;
        var deferred = $q.defer();
        var params = {
          idContenu: _this.getIdContenu(),
          montant: val
        };
        var ContenuService = Main.$injector.get('ContenuService');
        ContenuService.updateFieldByField(params)
          .then(function (contenu) {
            _this.setMontant(contenu.getMontant());
            //_this.setData(contenu);
            deferred.resolve(_this);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });

        return deferred.promise;
      },

      // commentaire
      getCommentaire: function(){
        return this.commentaire;
      },
      setCommentaire: function(val){
        this.commentaire = val;
      },

      isContenuMessage: function(){
        if(this.getTypeContenu() === COLLAB_CONF.TYPE_CONTENU_MESSAGE){
          return true;
        }
        return false;
      },

      isContenuEvenement: function(){
        if(this.getTypeContenu() === COLLAB_CONF.TYPE_CONTENU_EVENEMENT){
          return true;
        }
        return false;
      },

      isContenuDevis: function(){
        if(this.isContenuDevisInter() && this.demandeDevisActive){
          return true;
        }
        return false;
      },

      isContenuIntervention: function(){
        if(this.isContenuDevisInter() && !this.demandeDevisActive){
          return true;
        }
        return false;
      },

      isContenuDevisInter: function(){
        if(this.getTypeContenu() === COLLAB_CONF.TYPE_CONTENU_DEVIS_INTER){
          return true;
        }
        return false;
      },

      isContenuFacture: function(){
        if(this.getTypeContenu() === COLLAB_CONF.TYPE_CONTENU_FACTURE){
          return true;
        }
        return false;
      },

      isContenuSinistre: function(){
        if(this.getTypeContenu() === COLLAB_CONF.TYPE_CONTENU_SINISTRE){
          return true;
        }
        return false;
      },

      isContenuContrat: function(){
        if(this.getTypeContenu() === COLLAB_CONF.TYPE_CONTENU_CONTRAT){
          return true;
        }
        return false;
      },

      isImportant: function(){
        return TagsService.getTagTypeImportant(this.getTagsType());
      },

      getIsBlocked: function(){
        if(_.isObject(this.getTopicInformations()) && this.getTopicInformations().getTopicIsBlocked()) return true;
        else if(this.portefeuilleIsBlocked()) return true;
        return false;
      }
    };

    Contenu.getServiceForTypeContenu = function (contenu){
      var Service = null;
      if(contenu.isContenuEvenement())  Service = Main.$injector.get('EvenementService');
      else if(contenu.isContenuDevisInter()) Service = Main.$injector.get('DevisService');
      else if(contenu.isContenuSinistre()) Service = Main.$injector.get('SinistreService');
      else if(contenu.isContenuContrat()) Service = Main.$injector.get('ContratService');
      else Service = Main.$injector.get('ContenuService');
      return Service;
    };

    /*
    Contenu.initParamsSinistre = function (params, objetSinistre){

      if(objetSinistre && _.isObject(objetSinistre) && objetSinistre.hasOwnProperty('infosSinistre') && _.isObject(objetSinistre.infosSinistre)) {

        var infosSinistre = objetSinistre.infosSinistre;
        if(infosSinistre.hasOwnProperty('dateSinistre') && infosSinistre.dateSinistre) {
          params.dateSinistre = moment(infosSinistre.dateSinistre).format('YYYY-MM-DD');
        }
        if(infosSinistre.hasOwnProperty('natureSinistre') && _.isObject(infosSinistre.natureSinistre)) {
          if(infosSinistre.natureSinistre.hasOwnProperty('idNatureSinistre') && infosSinistre.natureSinistre.idNatureSinistre) {
            params.idNatureSinistre = infosSinistre.natureSinistre.idNatureSinistre;
          }
          else if(infosSinistre.natureSinistre.hasOwnProperty('idNatureSinistreGlobal') && infosSinistre.natureSinistre.idNatureSinistreGlobal) {
            params.idNatureSinistreGlobal = infosSinistre.natureSinistre.idNatureSinistreGlobal;
          }
          else if(infosSinistre.natureSinistre.hasOwnProperty('metier') && infosSinistre.natureSinistre.metier && infosSinistre.natureSinistre.hasOwnProperty('libelle') && infosSinistre.natureSinistre.libelle){
            params.metier = infosSinistre.natureSinistre.metier;
            params.libelle = infosSinistre.natureSinistre.libelle;
          }
        }

        if(infosSinistre.hasOwnProperty('expert')){
          if(_.isObject(infosSinistre.expert)) {
            var proxyPersoIcs = new ProxyPersoIcs(infosSinistre.expert);
            params.expert = proxyPersoIcs.toJson();
          }
          else {
            params.expert = false;
          }
        }

        if(infosSinistre.hasOwnProperty('assureur')) {
          if(_.isObject(infosSinistre.assureur)) {
            var proxyContratIcs = new ProxyContratIcs(infosSinistre.assureur);
            params.assureur = proxyContratIcs.toJson();
          }
          else {
            params.assureur = false;
          }
        }
        if(infosSinistre.hasOwnProperty('commentaire') && infosSinistre.commentaire) {
          params.commentaire = infosSinistre.commentaire;
        }

      }
      return params;
    };*/

    Contenu.getListGuid = function (listDocument) {
      var guids = [];
      if(_.isArray(listDocument)) {
        for(var i = 0; i < listDocument.length; i++) {
          if (listDocument[i].isModel && listDocument[i].model === 'Document') guids.push(listDocument[i].getGuid());
        }
      }
      return guids;
    };

    Contenu.Main = Main;
    angular.extend(Contenu.prototype, Main.prototype);

    return Contenu;

  }
})();
