(function () {

    'use strict';

    /**
     * @desc Composant groupe ligne
     * @example <gerer-mes-groupes-ligne></gerer-mes-groupes-ligne>
     */
    angular
        .module('collaboreApp')
        .component('gererMesGroupesLigne', {
            bindings: {
                groupe: '<',     // Object<Groupe>
                onProgress: '&'
            },
            templateUrl: 'app/parametrage/mes-groupes/gerer-mes-groupes-ligne.component.html',
            controllerAs: 'gerermesgroupeslignectrl',
            controller: GererMesGroupesLigneCtrl
        });


    /** @ngInject */
    function GererMesGroupesLigneCtrl($rootScope, ChannelService) {
        var ctrl = this;

        ctrl.onLigneProgress = onLigneProgress;
        ctrl.onChangeAccueil = onChangeAccueil;
        ctrl.onChangeChannel = onChangeChannel;

        ctrl.$onInit = function () {
          ctrl.groupeUser = angular.copy($rootScope.current.userGroupe);
          if (ctrl.groupe.isAll()) {
            ctrl.groupeUser.accueil = ChannelService.listeChannelHasAccueilForGroupe($rootScope.current.userGroupe.getListeChannel(), ctrl.groupeUser);
            ctrl.groupeUser.channel = ChannelService.listeChannelHasChannelForGroupe($rootScope.current.userGroupe.getListeChannel(), ctrl.groupeUser);
          }
          ctrl.groupe.accueil = ChannelService.listeChannelHasAccueilForGroupe($rootScope.current.userGroupe.getListeChannel(), ctrl.groupe);
          ctrl.groupe.channel = ChannelService.listeChannelHasChannelForGroupe($rootScope.current.userGroupe.getListeChannel(), ctrl.groupe);
        };

        function onLigneProgress(progress) {
            ctrl.onProgress({'progress': progress});
        }

        function onChangeAccueil(groupe) {

            ctrl.onProgress({'progress': true});
            // Si coche accueil
            if (groupe.accueil) {

                var channelAccueil = $rootScope.current.userGroupe.getChannelAccueil();
                ChannelService.addAccueil(groupe, channelAccueil).then(function (newChannel) {
                    $rootScope.current.userGroupe.updateChannel(newChannel);
                }, function () {
                    groupe.accueil = !groupe.accueil;
                }).finally(function () {
                    ctrl.onProgress({'progress': false});
                });

                // Si décoche accueil
            } else {
                var channel = $rootScope.current.userGroupe.getAccueilForGroupe(groupe);
                ChannelService.deleteGroupeForChannel(groupe, channel).then(function () {
                    $rootScope.current.userGroupe.removeGroupeFromChannel(groupe, channel);
                }, function () {
                    groupe.accueil = !groupe.accueil;
                }).finally(function () {
                    ctrl.onProgress({'progress': false});
                });
            }
        }

        function onChangeChannel(groupe) {

            ctrl.onProgress({'progress': true});
            // Si coche channel
            if (groupe.channel) {

                ChannelService.addChannel(groupe).then(function (newChannel) {
                    $rootScope.current.userGroupe.addChannel(newChannel);
                }, function () {
                    groupe.channel = !groupe.channel;
                }).finally(function () {
                    ctrl.onProgress({'progress': false});
                });
                // Si décoche channel
            } else {
                var channel = $rootScope.current.userGroupe.getChannelForGroupe(groupe);
                ChannelService.deleteChannel(channel).then(function () {
                    $rootScope.current.userGroupe.removeChannel(channel);
                }, function () {
                    groupe.channel = !groupe.channel;
                }).finally(function () {
                    ctrl.onProgress({'progress': false});
                });
            }
        }

    }
})();
