(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .component('collabEditorSms', {
      templateUrl: 'app/divers/collab-editor-sms/collab-editor-sms.component.html',
      controller: CollabEditorSmsController,
      controllerAs: 'collabeditorsmsctrl',
      bindings: {
        evenement: '<',
        tagPortefeuille: '<',
        texte: '<',
        model: '=',             // Si model alors biding dans les deux sens et il est prioritaire
        params: '<',
        height: '@',
        showTemplates: '<',
        currentTemplate: '<',
        onReady: '&'
      }
    });

  /** @ngInject */
  function CollabEditorSmsController($timeout, uuid, COLLAB_CONF, UtilsService, $rootScope) {
    var ctrl = this;
    var prefixSMS = null;

    ctrl.onFocusTextareaOnMobile = onFocusTextareaOnMobile;
    ctrl.onBlurTextareaOnMobile = onBlurTextareaOnMobile;
    ctrl.onChangeTemplateSms = onChangeTemplateSms;
    ctrl.calcNbResteCaracteresForSMS = calcNbResteCaracteresForSMS;

    ctrl.$onInit = function () {

      ctrl.idEditorSms = uuid.new();

      ctrl.useModel = false;
      if(!_.isNil(ctrl.model))
        ctrl.useModel = true;

      // Si il y a un événement on cherche le tag portefeuille pour prendre le prefix
      if(ctrl.evenement) {
        var tagPortefeuille = ctrl.evenement.getTagForType('Portefeuille');
        if(tagPortefeuille && tagPortefeuille.getPortefeuille() && tagPortefeuille.getPortefeuille().getPortefeuilleInfos()) prefixSMS = tagPortefeuille.getPortefeuille().getPortefeuilleInfos().getPrefixSms();
      }

      // Si il y  aun tag portefueille on prend le portefeuille du tag pour recup le prefix
      if(ctrl.tagPortefeuille && ctrl.tagPortefeuille.getPortefeuille() && ctrl.tagPortefeuille.getPortefeuille().getPortefeuilleInfos()) {
        prefixSMS = ctrl.tagPortefeuille.getPortefeuille().getPortefeuilleInfos().getPrefixSms();
      }

      // Si toujours pas de prefix alors on va chercher les portefeuille du currentGroupe, si il y a un seul portefeuille alors on prend le prefix
      if(!prefixSMS) {
        prefixSMS = UtilsService.getPrefixSmsFromPortefeuilleOrUser();
        /*
        var portefeuilles = UtilsService.getCurrentUser().getListePortefeuille();
        if(portefeuilles.length === 1 && portefeuilles[0].getPortefeuilleInfos()) {
          prefixSMS = portefeuilles[0].getPortefeuilleInfos().getPrefixSms();
        }*/
      }
      // Si toujours pas de préfix alors prend le diese
      //if(!prefixSMS) prefixSMS = UtilsService.getCurrentUser().getDieze();

      var nbCaracteresMaxSms = COLLAB_CONF.NB_MAX_CARACTERES_SMS_UNICODE;
      if(COLLAB_CONF.CHARACTER_SET_SMS === "gsm"){
        ctrl.isGsmCharset = true;
        nbCaracteresMaxSms = COLLAB_CONF.NB_MAX_CARACTERES_SMS_GSM;
        // Recupère le préfix clean avec le bon nombre de caractère
        var infos = UtilsService.getObjectCleanStringForGsm(prefixSMS);
        nbCaracteresMaxSms -= infos.nb;
        prefixSMS = infos.value;
      }
      else {
        nbCaracteresMaxSms -= prefixSMS.length;
      }
      nbCaracteresMaxSms -= COLLAB_CONF.LIAISON_PREFIX_MESSAGE.length;
      ctrl.nbResteCaracteresForSMS = angular.copy(nbCaracteresMaxSms);
      ctrl.nbCaracteresForSMS = angular.copy(nbCaracteresMaxSms);

      ctrl.currentTemplate = null;
      ctrl.templatesSms = UtilsService.getModulesTemplatesObjet('template_sms');

      ctrl.style = {};
      if(!ctrl.height) ctrl.style.height = '100px'
      else ctrl.style.height =  ctrl.height + 'px';

      $timeout(function(){
        var objet = ctrl.texte;
        if(!_.isNil(ctrl.model))
          setValue(ctrl.model);

        onReady(objet);
      });
    };

    ctrl.$onChanges = function(changes) {
      if(changes.currentTemplate) {
        ctrl.currentTemplate = changes.currentTemplate.currentValue;
      }
      if(changes.texte && !changes.texte.isFirstChange()) {
        setValue(changes.texte.currentValue);
      }
    };

    ctrl.$onDestroy = function () {
    };

    /**
     * Permet de cacher le header lorsqu'on focus le textarea
     */
    function onFocusTextareaOnMobile(){
      if(UtilsService.onMobile())
        $rootScope.hideHeaderLayout = true;
    }

    /**
     * Permet de réafficher le header lorsqu'on quitte le textarea
     */
    function onBlurTextareaOnMobile(){
      if(UtilsService.onMobile())
        $rootScope.hideHeaderLayout = false;
    }

    var prevTemplateSms = false, prevObjetSms;
    /**
     * Lors de la selection du model dans le champ select des template SMS
     */
    function onChangeTemplateSms(index) {

      var objet = getValue();

      if (!prevTemplateSms) prevObjetSms = angular.copy(objet);
      else if (prevTemplateSms.objet == '') prevObjetSms = angular.copy(objet);

      if (angular.isNumber(index)) {
        if (ctrl.templatesSms.length <= (index + 1)) ctrl.currentTemplate = ctrl.templatesSms[index]; // Preselectione le 1er modèle
      }
      else if (angular.isObject(index)) {
        if (index.hasOwnProperty('idModuleValeurParams')) {
          // Si il y a des model event
          if (ctrl.templatesSms.length) {
            // Pour chaque model
            for (var i = 0; i < ctrl.templatesSms.length; i++) {
              // Si le model de la boucle en cours est égale à l'id du model demandé
              if (ctrl.templatesSms[i].idModuleValeurParams === index.idModuleValeurParams) {
                ctrl.currentTemplate = ctrl.templatesSms[i];
                break;
              }
            }
          }
        }
      }

      // Si un model est selectionné
      if (angular.isObject(ctrl.currentTemplate)) {
        // Si il y a des model event
        if (ctrl.templatesSms.length) {
          // Pour chaque model
          for (var m = 0; m < ctrl.templatesSms.length; m++) {
            var currentModel = ctrl.templatesSms[m];

            // Si le model de la boucle en cours est égale au model choisi
            if (ctrl.currentTemplate.idModuleValeurParams === currentModel.idModuleValeurParams) {

              // Si il y a un objet
              if (currentModel.objet !== '') objet = angular.copy(currentModel.objet);
              else {
                // Si il y un model choisi avant de changer
                // Si ce model avait un objet prédéfini
                if (angular.isObject(prevTemplateSms) && prevTemplateSms.objet !== '') {
                  if (prevObjetSms) objet = prevObjetSms;
                  else objet = '';
                }
              }
              break;
            }
          }
        }
      }
      else {

        // Si il y a un contenu save
        if (ctrl.objContenuSave) {

          // Set l'objet client dans une variable temporaire du controller
          if (ctrl.objContenuSave.objet !== '' && angular.isDefined(ctrl.objContenuSave.objet)) objet = ctrl.objContenuSave.objet;

        }
        else {
          // Siil y avait un model choisi avant de changer
          if (angular.isObject(prevTemplateSms) && prevTemplateSms.objet !== '') {
            if (prevObjetSms) objet = prevObjetSms;
            else objet = '';
          }
        }
      }
      prevTemplateSms = angular.copy(ctrl.currentTemplate);

      setValue(objet);
      if(!_.isNil(ctrl.model))
        ctrl.model = objet;
    }

    function genereTexteSMS(){
      var msg = '';
      var objet = getValue();
      if (objet !== '' && !_.isNil(objet)) {

        /*
        if (prefixSMS) msg += prefixSMS;
        else if(UtilsService.getCurrentUser().getDieze().length <= 18)  msg += UtilsService.getCurrentUser().getDieze();
        else msg += UtilsService.truncate(UtilsService.getCurrentUser().getDieze(), 15, '...');
        */

        msg += prefixSMS + COLLAB_CONF.LIAISON_PREFIX_MESSAGE + objet;
      }
      return msg;
    }

    function getValue(){
      return angular.element("#"+ctrl.idEditorSms).val();
    }

    function setValue(val, noCount){
      angular.element("#"+ctrl.idEditorSms).val(val);
      if(_.isNil(noCount) || !noCount) calcNbResteCaracteresForSMS();
    }

    /*
    var util = (function($) {
      var _isEncodeableInGsm0338 = function(utfString) {
        var gsmUtfMap = [
            0x0040, 0x00A3, 0x0024, 0x00A5,
            0x00E8, 0x00E9, 0x00F9, 0x00EC, 0x00F2, 0x00E7, 0x000A, 0x00D8,
            0x00F8, 0x000D, 0x00C5, 0x00E5, 0x0394, 0x005F, 0x03A6, 0x0393,
            0x039B, 0x03A9, 0x03A0, 0x03A8, 0x03A3, 0x0398, 0x039E, 0x00A0,
            0x00C6, 0x00E6, 0x00DF, 0x00C9, 0x0020, 0x0021, 0x0022, 0x0023,
            0x00A4, 0x0025, 0x0026, 0x0027, 0x0028, 0x0029, 0x002A, 0x002B,
            0x002C, 0x002D, 0x002E, 0x002F, 0x0030, 0x0031, 0x0032, 0x0033,
            0x0034, 0x0035, 0x0036, 0x0037, 0x0038, 0x0039, 0x003A, 0x003B,
            0x003C, 0x003D, 0x003E, 0x003F, 0x00A1, 0x0041, 0x0042, 0x0043,
            0x0044, 0x0045, 0x0046, 0x0047, 0x0048, 0x0049, 0x004A, 0x004B,
            0x004C, 0x004D, 0x004E, 0x004F, 0x0050, 0x0051, 0x0052, 0x0053,
            0x0054, 0x0055, 0x0056, 0x0057, 0x0058, 0x0059, 0x005A, 0x00C4,
            0x00D6, 0x00D1, 0x00DC, 0x00A7, 0x00BF, 0x0061, 0x0062, 0x0063,
            0x0064, 0x0065, 0x0066, 0x0067, 0x0068, 0x0069, 0x006A, 0x006B,
            0x006C, 0x006D, 0x006E, 0x006F, 0x0070, 0x0071, 0x0072, 0x0073,
            0x0074, 0x0075, 0x0076, 0x0077, 0x0078, 0x0079, 0x007A, 0x00E4,
            0x00F6, 0x00F1, 0x00FC, 0x00E0
          ],
          extGsmUtfMap = [
            // { {Ext GSM,UTF} }
            [ 0x0A, 0x000C ],
            [ 0x14, 0x005E ],
            [ 0x28, 0x007B ],
            [ 0x29, 0x007D ],
            [ 0x2F, 0x005C ],
            [ 0x3C, 0x005B ],
            [ 0x3D, 0x007E ],
            [ 0x3E, 0x005D ],
            [ 0x40, 0x007C ],
            [ 0x65, 0x20AC ]
          ];

        outer: for (var i = 0; i < utfString.length; i++) {
          for (var g = 0; g < gsmUtfMap.length; g++) {
            if (String.fromCharCode(gsmUtfMap[g]) == utfString.charAt(i)) {
              continue outer;
            }
          }
          for (var j = 0; j < extGsmUtfMap.length; j++) {
            if (String.fromCharCode(extGsmUtfMap[j][1]) == utfString.charAt(i)) {
              continue outer;
            }
          }
          return false;
        }
        return true;

      };

      return {
        isEncodeableInGsm0338: function(utfString){
          return _isEncodeableInGsm0338(utfString);
        }
      };
    })($);*/

    function calcNbResteCaracteresForSMS(){
      var value = getValue();
      var newValue = "";
      var hasNotAllowedChar = false;
      var nbReste = ctrl.nbCaracteresForSMS;
      if(COLLAB_CONF.CHARACTER_SET_SMS === "gsm"){
        if(_.isString(value) && value.length) {
          var infos = UtilsService.getObjectCleanStringForGsm(value, ctrl.nbCaracteresForSMS);
          nbReste = infos.nbReste;
          newValue = infos.value;
          hasNotAllowedChar = (infos.nbNotAllowed || infos.nbNotAllowedButConverted);
          if(nbReste <= 0 || hasNotAllowedChar) setValue(newValue, true);
        }
      }
      else {
        nbReste -= (_.isString(value)) ? value.length : 0;
        newValue = value;
        if(nbReste < 0) setValue(newValue.substr(0, ctrl.nbCaracteresForSMS));
      }
      ctrl.nbResteCaracteresForSMS = (nbReste < 0) ? 0 : nbReste;
    }

    /*
    function calcNbResteCaracteresForSMS(ev){
      if(_.isObject(ev)) {
        var character = ev.key;
        if(COLLAB_CONF.CHARACTER_SET_SMS === "gsm"){
          if(!isGSMAlphabet(character)){
            var value = getValue();
            value = value.replace(new RegExp("[^A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]","gmi"), "");
            setValue(value);
          }
        }
      }
    }*/

    /**
     * Appelé quand prêt
     * @param d
     */
    function onReady(objet) {
      if(_.isNil(objet)) objet = getValue();
      ctrl.onReady({
        onReady: {
          getData: function(withoutDiese){
            return (!withoutDiese) ? genereTexteSMS() : objet;
          },
          setData: function(val){
            setValue(val);
          }
        }
      });
    }
  }
})();

