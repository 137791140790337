(function() {
	'use strict';

	/**
	 * @desc Label pour les topic urgent
	 * @example <tag-type-projet tags-type="" libelle="" allow-filtre=""></tag-type-projet>
	 */

	angular
		.module('collaboreApp')
		.component('tagTypeProjet', {
			templateUrl: 'app/topics/components/tag-type-projet.component.html',
			bindings : {
				libelle: '@',		// Titre à placer devans le tag
				tagsType: '<',		// Liste des tags type
				allowFiltre: '<',	// Permet ou non le filtre en cliquant sur le label
				idTopic: '<',		// Id du topic
        eventPresent: '<' // Est-ce qu'il y a un événement dans le topic
			},
			controllerAs: 'tagtypeprojetctrl',
			controller: TagTypeProjetController
		});

	/*@ngInject*/
	function TagTypeProjetController($state, FiltreService, TagService){

		var ctrl = this;

		ctrl.$onInit = function() {
			ctrl.libelleTag = '#';
			ctrl.paramsFiltreByTagType = {};
			ctrl.currentState = $state.current.name;
			ctrl.titre = (ctrl.libelle) ? ctrl.libelle : false;
			if(angular.isDefined(ctrl.tagsType)){
				if(ctrl.tagsType.length){
					var tagProjet = TagService.getTagForType(ctrl.tagsType,'Projet');
					if(tagProjet){
						ctrl.libelleTag = tagProjet.libelle;
						if(ctrl.allowFiltre) {
							ctrl.paramsFiltreByTagType = FiltreService.getObjFiltreByTagType(tagProjet);
						}
					}
				}
			}
		};
	}
})();
