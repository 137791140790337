(function () {
    'use strict';

    angular
        .module('collaboreApp')
        .component('sinistre', {
            templateUrl: 'app/topics/evenement/sinistre/sinistre.component.html',
            controller: SinistreController,
            controllerAs: 'sinistrectrl',
            bindings: {
                evenement: '<',
                infosEvenement: '<',
                onEmit: '&'
            }
        });

    /** @ngInject */
    function SinistreController($scope, $q, SinistreService, ContenuService, UtilsService, DocumentManagerService, ModalsService, ErreurCollabService) {
        var ctrl = this;
        ctrl.nl2br = UtilsService.nl2br;

        ctrl.onClickDeclarerSinistre = onClickDeclarerSinistre;		// Lors du clique sur le bouton pour déclarer un sinistre
        ctrl.onClickDeleteSinistre = onClickDeleteSinistre;       // Lors du clique sur le bouton de suppression du sinistre

        ctrl.onClickImprimerDeclarationSinistre = onClickImprimerDeclarationSinistre;			// Lors du clique sur l'impression de la declaration du sinistre
        ctrl.onClickSendDeclarationSinistreByEmail = onClickSendDeclarationSinistreByEmail;		// Lors du clique sur le bouton permettant d'envoyer par email
        ctrl.onClickEditAssureur = onClickEditAssureur;                 // Lors du clique sur le bouton pour modifier l'assureur
        ctrl.onClickEditExpert = onClickEditExpert;                     // Lors du clique sur le bouton pour modifier l'expert
        //ctrl.onUpdateExpertSinistre = onUpdateExpertSinistre;			// Lors du changement d'expert dans le sinistre
        //ctrl.onUpdateAssureurSinistre = onUpdateAssureurSinistre;		// Lors du changement d'assureur dans le sinistre

        /********** Global **********/
        ctrl.$onInit = function () {
            if(!ctrl.evenement) ModalsService.alertErreur('Pas d\'événement !');
            if(!ctrl.infosEvenement) ctrl.infosEvenement = UtilsService.getInfosEvenement(ctrl.evenement);
        };

        ctrl.$onChanges = function(changes){
        };

        ctrl.$onDestroy = function(){
        };

        /**
         * Sauvegarde du sinistre
         * @param objet
         */
        function saveSinistre(objet) {
            SinistreService
                .saveSinistre(ctrl.evenement, objet)
                .then(function(event){
                    emitSinistre('update', event);
                });
        }

        /**
         * Ouvre le modal de déclaration de sinistre
         * @param paramsSup
         */
        function openDeclarationSinitre(paramsSup) {
            SinistreService
                .openDeclarationSinitre(ctrl.evenement, ctrl.infosEvenement, paramsSup)
                .then(function(event){
                    emitSinistre('update', event);
                });
        }

        /**
         * Permet de générer la declaration de sinistre
         * @param sinistre
         */
        function genereDeclarationSinistre(sinistre) {
            var deferred = $q.defer();
            var modalLoading = ModalsService.loading();
            sinistre
                .generateDeclarationSinistre()
                .then(function (sinistre) {
                    modalLoading.close();
                    deferred.resolve(sinistre.getInfosSiniste().getDocumentDeclarationSinistre());
                })
                .catch(function (msg) {
                    ModalsService.alertErreur(msg);
                });
            return deferred.promise;
        }

        /*
        function onClickSendDeclarationSinistreByEmail(){
            var options = {
                backdrop: 'static'
            };
            var doc = ctrl.evenement.getSinistre().getInfosSinistre().getDocumentDeclarationSinistre();
            doc.checked = true;

            var params = {
                chooseMail: true,
                contenuParent: ctrl.evenement.getSinistre(),
                destinataire: ctrl.evenement.getSinistre().getInfosSinistre().getAssureur(),
                idTopic: ctrl.evenement.getSinistre().getIdTopic(),
                documents: [doc],
                sujet: 'Déclaration de sinistre'
            };

            ModalsService
                .modalComponent('sendMail', params, options)
                .then(function (objet) {
                    $scope.$emit('addMessage', objet.message);
                })
                .catch(function (msg) {
                    if (msg !== 'cancel') ModalsService.alertErreur(msg);
                });
        }*/
        function onClickSendDeclarationSinistreByEmail(){
            var options = {
                backdrop: 'static'
            };
            var doc = ctrl.evenement.getSinistre().getInfosSinistre().getDocumentDeclarationSinistre();
            doc.checked = true;

            var params = {
                typeFormulaire: 'email',
                chooseContact: true,
                useMiniToolbar: true,
                sendByMail: true,
                disallowSelectType: true,
                disallowChooseDestinataires: false,
                idContenuRef: ctrl.evenement.getSinistre().getIdContenu(),
                destinataires: [ctrl.evenement.getSinistre().getInfosSinistre().getAssureur()],
                idTopic: ctrl.evenement.getSinistre().getIdTopic(),
                evenement: ctrl.evenement,
                documents: [doc],
                textSujet: 'Déclaration de sinistre'
            };

            ModalsService
                .modalComponent('formulaireMessage', params, options)
                .then(function (objet) {
                    $scope.$emit('addMessage', objet.contenu);
                })
                .catch(function (msg) {
                    if (msg !== 'cancel') ModalsService.alertErreur(msg);
                });
        }

        /**
         * Permet d'affricher ou de générer la declaration de sinistre
         */
        function onClickImprimerDeclarationSinistre() {
            var doc = ctrl.evenement.getSinistre().getInfosSinistre().getDocumentDeclarationSinistre();
            if (doc) DocumentManagerService.openDocument(doc.guid, ctrl.evenement.getSinistre().getIdContenu());
            else {
                genereDeclarationSinistre(ctrl.evenement.getSinistre()).then(function (iDoc) {
                    if (iDoc) DocumentManagerService.openDocument(iDoc.guid, ctrl.evenement.getSinistre().getIdContenu());
                    else {
                        ModalsService.alertErreur('Problème avec le document... (Plus dans la console)');
                        ErreurCollabService.logErreur(iDoc);
                    }
                });
            }
        }

        /**
         * Permet de supprimer le sinistre de l'événement
         */
        function onClickDeleteSinistre() {
            var options = {
                btnConfirmText: 'Confirmer !',
                type: 'warning'
            };
            ModalsService
                .confirm('Supprimer le sinistre ?', 'Voulez-vous vraiment supprimer le sinistre de cet événement ?', options)
                .then(function () {
                    var modalLoading = ModalsService.loading();
                    ctrl.evenement
                        .deleteSinistre()
                        .then(function () {
                            emitSinistre('delete', ctrl.evenement);
                            modalLoading.close();
                        })
                        .catch(function (msg) {
                            ModalsService.alertErreur(msg);
                        });
                });
        }

        /**
         * Lors du clique sur le bouton pour afficher la fenetre de déclaration du sinistre
         */
        function onClickDeclarerSinistre() {
            openDeclarationSinitre();
        }

        function onClickEditAssureur() {
            openDeclarationSinitre({step: 2});
        }

        function onClickEditExpert() {
            openDeclarationSinitre({step: 3});
        }

        /**
         * Lorsqu'il y a une modification au niveau de l'expert du sinistre
         * @param obj
         */
        /*
        function onUpdateExpertSinistre(obj) {
            var sinistreTmp = new ContenuService.ContenuSinistre();
            sinistreTmp.setIdContenu(ctrl.evenement.getSinistre().getIdContenu());
            sinistreTmp.getInfosSinistre().setExpert(obj.expert);
            saveSinistre(sinistreTmp);         // Save le sinistre
        }*/

        /**
         * Lorsqu'il y a une modification au niveau de l'assureur du sinistre
         * @param obj
         */
        /*
        function onUpdateAssureurSinistre(obj) {
            var sinistreTmp = new ContenuService.ContenuSinistre();
            sinistreTmp.setIdContenu(ctrl.evenement.getSinistre().getIdContenu());
            sinistreTmp.getInfosSinistre().setAssureur(obj.assureur);                  // Update l'assureur du sinistre
            saveSinistre(sinistreTmp);                 // Save le sinistre
        }*/

        /**
         * Fait remonter l'événement
         */
        function emitSinistre(action, event){
            ctrl.evenement.setSinistre(event.getSinistre());
            ctrl.onEmit({
                obj: {
                    action: action,
                    sinistre: ctrl.evenement.getSinistre()
                }
            });
        }
    }
})();

