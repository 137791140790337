(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.DocBoxService
   * @description
   * # DocBoxService
   * Service in the collaboreApp.
   */
  angular
    .module('collaboreApp')
    .factory('DocBoxResource', DocBoxResource);

  /** @ngInject */
  function DocBoxResource(COLLAB_CONF, COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {

    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_DOC_BOX, null, {

      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      }
    });
  }
})();
