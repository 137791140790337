(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .constant('COLLAB_CONF', {

      VERSION_COLLAB: '1.40.3',
      VERSION_COLLAB_BACK: '2.11.0',
      AUTH_KERBEROS: JSON.parse("/* @echo AUTH_KERBEROS */"),
      AUTH_ONELOGIN: JSON.parse("/* @echo AUTH_ONELOGIN */"),

      HOTKEYS_SHOW_OLD_GROUPE_PORTEFEUILLE: {
        combo: 'ctrl+space',
        description: 'Permet d\'afficher les groupes portefeuille renommé avec _old'
      },

      HOTKEYS_ENABLE_ACTION_ON_GROUPE_PORTEFEUILLE: {
        combo: 'ctrl+del',
        description: ' Permet d\'afficher les boutons de suppression des groupes portefeuille et de les déplacer'
      },

      HOTKEYS_SHOW_FEATURES_ICS: {
        combo: 'ctrl+space',
        description: ' Permet d\'afficher des fonctionnalitées ou paramètres disponibles seulement pour l\'équipe ICS'
      },

      HOTKEYS_SHOW_FEATURES_SUPER_ADMIN: {
        combo: 'ctrl+*',
        description: ' Permet d\'afficher des fonctionnalitées ou paramètres disponibles seulement pour les SuperAdmin'
      },

      // Meta Title
      TITLE_DEFAULT: 'Collab',
      NAME_PARAM_HOME_COLLAB: 'stateHomeCollab',
      ARRAY_NAME_PARAM_HOME_COLLAB_ALLOWED: ['topics.list','dashboard.accueil','dashboard.reporting'],
      HOME_STATE: 'topics.list',
      CLIENT: '/* @echo CLIENT */',

      WEBSOCKET_ENABLED_ON_BROWSER: Modernizr.websockets,
      ENABLE_WEBSOCKET_JAVA: false,         // Websocket Java
      ENABLE_COLLAB_NODEJS: true,           // Websocket NodeJS
      ENABLE_SEND_MAIL_CC: true,

      NB_SECONDES_CHECK_WS: 60,

      ENABLE_LIVETCHAT: false,

      DATE_BEGIN_COLLAB: '2015-01-01',
      SOCIETE_CAPFUN: '#CAPFUN',
      SOCIETE_VAGUES_OCEANES: '#VAGUES-OCEANES',
      SOCIETE_CLICOCHIC: '#CLICOCHIC',
      SOCIETE_ICS: '#ICS',
      LIST_MODULE_ONLY_CAPFUN: [
        "model_event_camping_capfun"
      ],
      LIST_MODULE_ONLY_VO: [
        "model_event_camping_vo"
      ],
      LIST_MODULE_ONLY_CLICO: [
        "model_event_camping_clico"
      ],
      COMMUNICATION_CLIENT_CAPFUN_ENABLED: false, // Affiche ou non le client Capfun dans la communication

      KEY_CRYPT_SEARCH: 'CoLlaBIsFunConfig',
      SIZE_LIMIT_UPLOAD_DEFAUT : 2097152, // 2 Mo

      SERVLET_CONNEXION: 'ConnexionServlet',
      SERVLET_AUTHENTIFICATION: 'AuthentificationServlet',
      SERVLET_AUTHENTIFICATION_KERBEROS: 'AuthentificationKerberosServlet',
      SERVLET_AUTHENTIFICATION_ONELOGIN: 'AuthOnelogin',
      SERVLET_LOGOUT: 'LogoutServlet',

      ENABLE_SAVE_AUTO_CONTENU: true, // Sauvegarde automatique des contenu dans le local storage
      ENABLE_CHECK_INFORMATIONS: true, // Appel à la servlet des informations
      NB_SECONDES_CHECK_INFORMATIONS: 1800, // Nombre de secondes entre chaque appel à la servlet des informations
      SERVLET_INFORMATIONS_COLLABORE: 'RechercheInformationCollaboreServlet',

      INTERVAL_SECONDES_BEFORE_REFRESH_HOME: 30, // 2 minutes


      LIBELLE_CHANNEL_ALL: 'Voir tout',

      ///////////////////////////
      //    Notifications      //
      ///////////////////////////
      ENABLE_CHECK_NOTIFICATIONS: false, // Appel à la servlet des notifications
      NB_SECONDES_CHECK_NOTIFICATIONS: 1800, // Nombre de secondes entre chaque appel à la servlet des notifications
      SERVLET_NOTIFICATIONS: 'NotificationServlet',

      /**
       * Retire une notification de la liste
       * idNotification (Int)
       */
      SERVLET_UPDATE_NOTIFICATIONS: 'UpdateNotificationServlet',

      //////////////////
      //    Tags      //
      //////////////////
      NB_CARACTERES_AVANT_APPEL_RECHERCHE_TAG_SERVLET: 2,
      NB_MAX_TAG_SUGGERES_FOR_CONTENU: 3,
      NB_MAX_TAG_SUGGERES_FOR_FILTRE_TYPE_TAG: 3,
      NB_MAX_TAG_TYPE_MORE_USED: 10,

      /**
       * type (Int) : 1 : Tag Typé
       */
      SERVLET_TAGS: 'RechercheTagServlet',

      //SERVLET_INSERTION_TAGS: 'InsertionTagInContenuServlet',
      //SERVLET_INSERTION_TAGS_IN_DOCUMENT: 'InsertionTagInDocumentServlet',
      SERVLET_INSERTION_TAGS: 'InsertionTagInObjectServlet',
      SERVLET_INSERTION_TAGS_IN_DOCUMENT: 'InsertionTagInObjectServlet',

      SERVLET_DELETE_TAGS_IN_TOPIC: 'DeleteTagFromTopicServlet',
      SERVLET_DELETE_TAGS_IN_CONTENU: 'DeleteTagFromContenuServlet',
      SERVLET_DELETE_TAGS_IN_DOCUMENT: 'DeleteTagFromDocumentServlet',

      TAG_EXTRANET: 'Extranet',
      TAG_TOCTOCTOC: 'Toctoctoc',
      TAG_RAPPORT_VISITE: 'Rapport Visite',
      TAG_NORMAL: 'Normal',
      TAG_TYPE_PROJET: 'Projet',
      TAG_TYPE_ORIGINE: 'Origine',
      TAG_TYPE_ORIGINE_COLLAB: 'Origine Collab',
      TAG_TYPE_ORIGINE_ICS: 'Origine ICS',
      TAG_TYPE_ORIGINE_COMPTA: 'Origine Compta',
      TAG_TYPE_ORIGINE_IMMEUBLE: 'Origine Immeuble',
      TAG_TYPE_ORIGINE_GARDIEN: 'Origine Gardien',
      TAG_TYPE_ORIGINE_CAPFUN: 'OrigineCapfun',
      TAG_TYPE_IMMEUBLE: 'Immeuble',
      TAG_TYPE_LOT: 'Lot',
      TAG_TYPE_PROPRIETAIRE: 'Proprietaire',
      TAG_TYPE_COPROPRIETAIRE: 'Coproprietaire',
      TAG_TYPE_LOCATAIRE: 'Locataire',
      TAG_TYPE_MANDAT: 'Mandat',
      TAG_TYPE_PORTEFEUILLE: 'Portefeuille',
      TAG_TYPE_IMPORTANT: 'Important',
      TAG_TYPE_STATUT: 'Statut',
      TAG_TYPE_TYPE: 'Type',
      TAG_TYPE_LOT_MULTICAMP: 'LotMulticamp',
      TAG_TYPE_LIEU_COMMUN_CAPFUN: 'LieuCommunCapfun',
      TAG_TYPE_PRESENCE: 'Presence',
      TAG_TYPE_CLIENT_CAPFUN: 'ClientCapfun',
      TAG_TYPE_TECHNICIEN: 'Technicien',
      TAG_TYPE_MENAGE: 'Menage',
      TAG_TYPE_MOBILE_HOME: 'MobileHome',

      TAG_TYPE_VISICOP_REF_LIAISON_ECCA: 'VisicopRefLiaisonECCA',
      TAG_TYPE_VISICOP_ID_RAPPORT: 'VisicopIdRapport',

      LIBELLE_TAG_TYPE_VISICOP_REF_LIAISON_ECCA: 'Visicop Action',

      TAGS_FROM_OUTSIDE: [
        'Extranet',
        'Toctoctoc',
        'Rapport Visite'
      ],

      ARRAY_TAGS_ORIGINE: [
        'Origine',
        'Origine Collab',
        'Origine ICS',
        'Origine Immeuble',
        'Origine Compta',
        'Origine Gardien',
        'OrigineCapfun'
      ],

      ICON_TAG: {
        Projet: 'fa fa-tag',
        Lot: 'fa fa-home',
        Proprietaire: 'fa fa-user',
        Coproprietaire: 'fa fa-users',
        Locataire: 'fa fa-male',
        Origine: 'fa fa-user',
        Immeuble: 'fa fa-building',
        Mandat: 'fa fa-suitcase',
        Portefeuille: 'fa fa-book',
        Normal: 'fa fa-tag',
        Normaux: 'fa fa-tags',
        Important: 'fa fa-star',
        Statut: 'fa fa-tasks',
        Type: 'fa fa-info-circle',
        LotMulticamp: 'fa fa-home',
        Presence: 'fa fa-key',
        ClientCapfun: 'fa fa-user-circle-o',
        Technicien: 'fa fa-wrench',
        Menage: 'fa fa-user-circle',
        LieuCommunCapfun: 'fa fa-home'
      },

      TAG_TYPE_TYPE_INTERVENTION: 'Intervention',

      TAG_TYPE_PRESENCE_OBLIGATOIRE: 'Obligatoire',
      TAG_TYPE_PRESENCE_FACULTATIVE: 'Facultative',

      // Etat du statut de l'événement
      TAG_TYPE_STATUT_EN_ATTENTE: 'En-Attente',
      TAG_TYPE_STATUT_EN_COURS: 'En-Cours',
      TAG_TYPE_STATUT_TERMINE: 'Termine',
      TAG_TYPE_STATUT_PROBLEME: 'Probleme',

      LIEUX_COMMUNS_CAPFUN: [
        'Sanitaire 1',
        'Sanitaire 2',
        'Sanitaire 3',
        'Sanitaire 4',
        'Aires de jeux',
        'Bar',
        'Barbecue',
        'Boeing Zone',
        'Epicerie',
        'Laverie',
        'Local poubelle',
        'Mini Club',
        'Multisports',
        'Piscine',
        'Plage',
        'Réception',
        'Restaurant'
      ],


      /////////////////////
      //  Communication  //
      /////////////////////

      SERVLET_COMMUNICATION: 'CommunicationServlet',                // OLD
      SERVLET_COMMUNICATION_COLLAB: 'CommunicationCollabServlet',   // NEW

      //////////////////
      //   Annuaire   //
      //////////////////

      SERVLET_PORTEFEUILLE: 'PortefeuilleServlet',
      SERVLET_GROUPE: 'GroupeServlet',
      SERVLET_GROUPE_ASSOCIATION: 'GroupeAssociationServlet',

      LIBELLE_SALARIE_BY_NIVEAU: {
        '02': 'Gardien'
      },

      // Type Groupe Collab
      ORDRE_FICHE_GROUPE: 1,

      // Type Perso ICS
      ORDRE_FICHE_VISICOP: 1,
      ORDRE_FICHE_IMMEUBLE: 2,
      ORDRE_FICHE_BAIL: 3,
      ORDRE_FICHE_LOCATAIRE: 4,
      ORDRE_FICHE_COPROPRIETAIRE: 5,
      ORDRE_FICHE_PROPRIETAIRE: 6,
      ORDRE_FICHE_FOURNISSEUR: 7,

      /**
       * Permet d'avoir la liste des portefeuille de l'user
       */
      SERVLET_RECHERCHE_PORTEFEUILLE: 'RecherchePortefeuilleServlet',

      /**
       * Permet de récupérer la liste des perso
       * type (Int) 0: Perso; 1: Societe; 2: Perso et Societe
       * page (Int)
       * limit (Int)
       */
      SERVLET_ANNUAIRE_PERSO: 'RechercheContactServlet',

      /**
       * Recherche sur collab et les portefeuilles ICS
       * type (String) F: Fournisseur, U: Utilisateur
       * recherche (String) (Obligatoir si pas de "codemetier")
       * codemetier (String) (Obligatoir si pas de "recherche")
       * idPortefeuilles (Int[])
       */
      SERVLET_RECHERCHE_COLLABORATEUR:'RechercheCollaborateurServlet',

      SERVLET_RECHERCHE_HIERARCHIE: 'RechercheHierarchieServlet',

      SERVLET_RECHERCHE_GROUPE: 'RechercheGroupeServlet',

      SIZE_LIMIT_UPLOAD_AVATAR: 2097152, // 2 Mo

      /**
       * Recupère la liste des métier
       * global (String)
       */
      SERVLET_RECHERCHE_METIER_FOURNISSEUR: 'RechercheMetierFournisseurServlet',

      /**
       * Permet de rechercher les info d'une personnalitée
       * groupe (idGroupe)
       */
      SERVLET_RECHERCHE_PERSONNALITE: 'RecherchePersonnaliteServlet',

      /**
       * Permet de rechercher les lots d'une personnalite propriétaire
       * noperpro (noperso ICS)
       */
      SERVLET_RECHERCHE_LOTS: 'RechercheLotServlet',

      /**
       * Permet de rechercher les baux
       * mmmm (Numero mandat ICS)
       * iiii (Numero immeuble ICS)
       * llll (Numero lot ICS)
       */
      SERVLET_RECHERCHE_BAILS: 'RechercheBailServlet',

      /**
       * Permet de rechercher les contrats
       * mandat (Numero mandat ICS)
       * immeuble (Numero immeuble ICS)
       * avecVentilationComptable
       * codeMetier
       * numeroFournisseur
       * refContrat
       */
      SERVLET_RECHERCHE_CONTRATS: 'SearchContratServlet',

      /**
       * Permet de rechercher les digicodes
       * iiii (Numero immeuble ICS)
       */
      SERVLET_RECHERCHE_DIGICODES: 'RechercheDigicodeServlet',


      /**
       * Permet de rechercher les lots de n'importe quel perso
       */
      SERVLET_RECHERCHE_INVERSE: 'RechercheInverseServlet',

      /**
       * Permet de rechercher les groupes fils d'un groupe avec pagination
       */
      SERVLET_RECHERCHE_GROUPE_FILS: 'RechercheGroupeFilsServlet',

      /**
       * Permet de rechercher les groupes parent d'un groupe/user
       * idGroupe (Int)
       */
      SERVLET_RECHERCHE_GROUPE_PERE: 'RechercheGroupePereServlet',

      //////////////////
      //    Fiche     //
      //////////////////
      DEFAULT_LIMIT_FICHE: 999999,
      DEFAULT_PAGE_FICHE: 1,

      /**
       * Ajoute un avatar au compte
       * fileName (type file en form data)
       */
      SERVLET_INSERTION_AVATAR: 'InsertionAvatarServlet',

      /**
       * Ajoute une fiche
       * idFiche (Int)
       * idState (Int)
       * idReference (Int)
       * donnee (String)
       */
      SERVLET_INSERTION_FICHE: 'InsertionFicheServlet',

      /**
       * Update une fiche
       * idFiche (Int)
       * idState (Int)
       * idReference (Int)
       * donnee (String)
       */
      SERVLET_UPDATE_FICHE: 'UpdateFicheServlet',

      /**
       * Delete une fiche
       * idFiche (Int)
       */
      SERVLET_DELETE_FICHE: 'DeleteFicheServlet',

      /**
       * Supprime un renseignement pour une fiche
       * idFiche (Int)
       * idRenseignement (Int)
       */
      SERVLET_DELETE_RENSEIGNEMENT_FROM_FICHE: 'DeleteRenseignementFromFicheServlet',

      //////////////////
      //    Topics    //
      //////////////////

      SERVLET_TOPIC: 'TopicServlet',

      LIST_TOPIC_PAGE_NUM: 1,
      LIST_TOPIC_LIMIT_PAR_PAGE: 10,
      INTERVAL_AUTOSAVE_REPONSE: 20, // Nombre de secondes entre chaque save du contenu
      NB_JOURS_MAX_CONTENUS_SAVE_BEFORE_DELETE: 1,


      //SERVLET_TOPICS: 'RechercheTopicServlet',
      SERVLET_EVENEMENT_BY_TOPICS: 'RechercheEvenementByTopicServlet',
      SERVLET_INSERTION_TOPIC: 'InsertionTopicServlet',
      SERVLET_INSERTION_DESTINATAIRE_TOPIC: 'InsertionDestinataireInTopicServlet',
      SERVLET_UPDATE_TOPIC_STATE: 'UpdateTopicStateServlet',

      /**
       * Modifie le titre du topic si créateur et si il n'y a qu'un contenu
       * idTopic (Int)
       * donnee (String)
       */
      SERVLET_UPDATE_TITRE_TOPIC: 'UpdateTitreTopicServlet',

      // Filtre personnalisé des topics
      SERVLET_RECHERCHE_FILTRE_TOPICS: 'RechercheFiltreServlet',
      SERVLET_INSERTION_FILTRE_TOPICS: 'InsertionFiltreServlet',
      SERVLET_UPDATE_FILTRE_TOPICS: 'UpdateFiltreServlet',
      SERVLET_DELETE_FILTRE_TOPICS: 'DeleteFiltreServlet',

      // Historique avancement
      SERVLET_HISTORIQUE_AVANCEMENT: 'InsertionHistoriqueServlet',

      // Modal de confirmation de cloture d'un topic par le créateur
      TITLE_MSG_CONFIRMATION_CLOTURE_TOPIC_BY_CREATEUR: 'Cloturer le dossier ?',
      TITLE_MSG_CONFIRMATION_REOUVRIR_TOPIC_BY_CREATEUR: 'Réouvrir le dossier ?',

      MSG_CONFIRMATION_CLOTURE_TOPIC_BY_CREATEUR: '<div class="text-left"><strong>Commentaire :</strong><small>(facultatif)</small></div><div class="row"><div class="col-xs-12 padding-left-0"><textarea style="width:100%" rows="3" id="commentaireCloture" class="form-control"></textarea></div></div>',
      BTN_CONFIRMATION_CLOTURE_TOPIC_BY_CREATEUR: 'Oui !',

      // Modal de confirmation de cloture d'un topic par un destinataire du topic
      TITLE_MSG_CONFIRMATION_CLOTURE_TOPIC_BY_NO_CREATEUR: 'Demande de Cloture d\'un événement',
      TITLE_MSG_CONFIRMATION_REOUVRIR_TOPIC_BY_NO_CREATEUR: 'Demande de réouverture d\'un événement',
      TITLE_MSG_CONFIRMATION_CLOTURE_TOPIC_BY_FOURNISSEUR: 'Demande de Cloture',
      MSG_CONFIRMATION_CLOTURE_TOPIC_BY_NO_CREATEUR: 'Vous n\'êtes pas le créateur de ce dossier. Une demande de cloture sera donc envoyée au créateur.</hr>',
      MSG_CONFIRMATION_REOUVRIR_TOPIC_BY_NO_CREATEUR: 'Vous n\'êtes pas le créateur de ce dossier. Une demande de réouverture sera donc envoyée au créateur.</hr><div class="text-left"><strong>Commentaire :</strong><small>(facultatif)</small></div><textarea style="width:100%" rows="3" id="commentaireCloture" class="form-control"></textarea>',
      MSG_CONFIRMATION_CLOTURE_TOPIC_BY_FOURNISSEUR: '<div class="text-left"><strong>Commentaire :</strong><small>(facultatif)</small></div><div class="row"><div class="col-xs-12 padding-left-0"><textarea style="width:100%" rows="3" id="commentaireCloture" class="form-control"></textarea></div></div>',
      BTN_CONFIRMATION_CLOTURE_TOPIC_BY_NO_CREATEUR: 'Demander la cloture !',
      BTN_CONFIRMATION_REOUVRIR_TOPIC_BY_NO_CREATEUR: 'Demander la réouverture !',

      // -- OLD Recherche --
      SERVLET_RECHERCHE_GENERAL:'RechercheGeneralServlet',
      SERVLET_RECHERCHE_COLLAB:'RechercheCollabServlet',
      ENABLE_PAGINATION_RECHERCHE_COLLAB: true,
      DEFAULT_RECHERCHE_COLLAB_PAGE: '1',
      DEFAULT_RECHERCHE_COLLAB_NBPP: '10',
      DEFAULT_TRI_RECHERCHE_COLLAB: 'x_desc',

      RECHERCHE_COLLAB_ARRAY_TRI: [
        {
          libelle: 'Pertinence',
          orderby: 'desc_desc'
        },
        {
          libelle: 'Alphabétique',
          orderby: 'x_desc_asc'
        },
        {
          libelle: 'Alphabétique inverse',
          orderby: 'x_desc_desc'
        },
        {
          libelle: 'Date croissantes',
          orderby: 'x_asc'
        },
        {
          libelle: 'Date décroissantes',
          orderby: 'x_desc'
        }
      ],

      // -- NEW Recherche --
      TYPE_RECHERCHE_LIBRE_COLLAB_TAGS: 1,
      TYPE_RECHERCHE_LIBRE_COLLAB_GROUPE: 2,
      TYPE_RECHERCHE_LIBRE_COLLAB_TITRE: 3,
      TYPE_RECHERCHE_LIBRE_COLLAB_REF: 4,
      TYPE_RECHERCHE_LIBRE_COLLAB_MESSAGE: 5,
      DEFAULT_TRI_RECHERCHE_LIBRE_COLLAB: 'date_desc',

      ARRAY_TRI_RECHERCHE_LIBRE_COLLAB: [
        {
          libelle: 'Date croissantes',
          orderby: 'date_asc'
        },
        {
          libelle: 'Date décroissantes',
          orderby: 'date_desc'
        }
      ],


      //////////////////
      //  Contenus    //
      //////////////////
      OLD_NB_MAX_CARACTERES_SMS: 160,   // Il est utilisé dans les endroits où je ne sais pas si le code st encore utilisé..
      NB_MAX_CARACTERES_SMS_GSM: 300,
      NB_MAX_CARACTERES_SMS_UNICODE: 160,
      CHARACTER_SET_SMS: 'gsm',   // gsm | unicode
      LIST_CHARACTER_COUNT_DOUBLE: ["^","{","}","\\","[","]","~","|","€"],
      REGEX_CHARACTER_COUNT_DOUBLE: /[\f]/gmi,
      //REGEX_CHARACTER_COUNT_DOUBLE: /[\^{}\\\[\]~|€\f]/gmi, // Fonctionne une fois sur deux ??
      LIAISON_PREFIX_MESSAGE: ": ",
      NB_CARACTERES_AFTER_SOCIETE: 2,
      NB_MAX_DOCUMENT_SELECTED_FOR_DOWNLOAD: 20,
      NB_MINUTES_FOR_UPDATE_MESSAGE: 2, // Nombre de minutes où le message du contenu peut être modifié à partir de la date de création
      //SERVLET_CONTENUS: 'RechercheContenuByTopicServlet',
      SERVLET_LECTURE_CONTENU: 'DeleteLectureContenuServlet',
      SERVLET_INSERTION_LECTURE_CONTENU: 'InsertionLectureContenuServlet',
      SERVLET_INSERTION_CONTENU: 'InsertionContenuServlet',
      SERVLET_UPDATE_CONTENU: 'UpdateContenuServlet', // OLD servlet qui est encore utilisé, le but est de la faire disparaitre, remplacer par ServletContenu

      SERVLET_INSERTION_DESTINATAIRE_CONTENU: 'InsertionDestinataireInContenuServlet',
      //SERVLET_RECHERCHE_CONTENU: 'RechercheContenuByIdContenuServlet',

      /**** Contenu ****/
      SERVLET_CONTENU: 'ContenuServlet',

      /**** Message ****/
      SERVLET_MESSAGE: 'MessageServlet',

      /**** Evenement ****/
      SERVLET_EVENEMENT: 'EvenementServlet',

      /**** Demande ****/
      SERVLET_DEMANDE: 'DemandeServlet',

      /**** Devis ****/
      SERVLET_DEVIS: 'DevisServlet',

      /**** Contrat ****/
      SERVLET_CONTRAT: 'ContratServlet',

      /**** Sinistre ****/
      SERVLET_SINISTRE: 'SinistreServlet',

      /**** Facture ****/
      SERVLET_FACTURE: 'FactureServlet',

      /**** Courrier ****/
      SERVLET_COURRIER: 'CourrierServlet',

      /**** Ged ****/
      SERVLET_GED: 'GedServlet',

      /**** Comfact ****/
      SERVLET_COMFACT: 'ComfactServlet',

      /**** Document ****/
      SERVLET_DOCUMENT: 'DocumentServlet',

      /**** Doc Box ****/
      SERVLET_DOC_BOX: 'DocBoxServlet',

      /**** Temporary Doc Box ****/
      SERVLET_TEMP_DOC_BOX: 'TemporaryDocBoxServlet',

      /**** Upload Document ****/
      SERVLET_UPLOAD_DOCUMENT: 'UploadDocumentServlet',

      // Enum des types de document
      TYPE_DOC_ENUMS: {

        DEMANDE_DEVIS: "demande_devis",
        DEVIS: "devis",
        DEVIS_SIGNE: "devis_signe",

        FACTURE: "facture",
        FACTURE_AUTONOME: "facture_autonome",
        DEMANDE_FACTURE: "factureDemande",
        FACTURE_DEVIS_SIGNE: "factureDevisSigne",
        FACTURE_DEMANDE_DEVIS_SIGNE: "factureDemandeDevisSigne",
        DEMANDE_INTERVENTION: "demande_intervention",

        DEMANDE_CONTRAT: "demande_contrat",
        CONTRAT: "contrat",
        DOC_CONTRAT_FOURNISSEUR_SIGNE: "document_contrat_fournisseur_signe",
        DOC_CONTRAT_AGENCE_SIGNE: "document_contrat_agence_signe",

        ATTESTATION_TVA_SIMPLE: "attestation_tva_simple",
        DECLARATION_SINISTRE: "declaration_sinistre",
        DOC_TOPIC: "document_topic",
        DOC_COMFACT: "document_comfact",

        COURRIER_SIMPLE: "courrier_simple",
        DOC_FOURNISSEUR: "document_fournisseur",
        ATTACHMENT: "piece_jointe"
      },

      GED_FOLDER_ENUMS: {
        DOC_BOX: "doc_box"
      },

      TYPE_DOC_ENUMS_ALLOWED_FOR_DEVIS_FACTURE: [
        "document_fournisseur",
        "piece_jointe"
      ],

      /**** Mailbox *****/
      SERVLET_MAILBOX: 'MailboxServlet',

      /**** ElastiSearch *****/
      SERVLET_ELASTICSEARCH: 'ElasticSearchServlet',

      /**** Fiche ****/
      SERVLET_FICHE: 'FicheServlet',

      /**** Calculatrice ****/
      SERVLET_CALCULATRICE: 'CalculatriceServlet',

      /**** ICSCloud ****/
      SERVLET_RECHERCHE_ICSCLOUD: 'RechercheICSCloudServlet',

      /**** Extranet ****/
      SERVLET_EXTRANET: 'ExtranetServlet',


      TYPE_CONTENU_MESSAGE: 1,
      TYPE_CONTENU_EVENEMENT: 2,
      TYPE_CONTENU_DEVIS_INTER: 3,
      TYPE_CONTENU_FACTURE: 5,
      TYPE_CONTENU_SINISTRE: 6,
      TYPE_CONTENU_CONTRAT: 7,

      //////////////////
      //  Documents   //
      //////////////////
      NB_MAX_TAG_SUGGERES_FOR_DOCUMENT: 3,
      SIZE_LIMIT_UPLOAD: 52428800, // 50 Mo
      SIZE_LIMIT_ATTACHMENTS: 20971520, // Poids limit 20 Mo encas d'envoi par email

      SERVLET_DOCUMENTS_DOWNLOAD: 'TelechargementDocumentServlet',
      SERVLET_INSERTION_DOCUMENTS_IN_GROUPE: 'InsertionFileInGroupeServlet',
      SERVLET_INSERTION_DOCUMENTS_IN_SOCIETE: 'InsertionFileInGroupeSocieteServlet',
      SERVLET_DELETE_DOCUMENTS_IN_GROUPE: 'DeleteFileFromGroupeServlet',
      SERVLET_DELETE_DOCUMENTS_IN_CONTENU: 'DeleteFileFromContenuServlet',
      SERVLET_RECHERCHE_DOCUMENTS_PERSO: 'RechercheDocumentPersoServlet',
      SERVLET_UPDATE_DESCRIPTION_DOCUMENT: 'UpdateDocumentServlet',
      SERVLET_TELECHARGEMENT_EVENEMENT_PDF: 'TelechargementEvenementPdfServlet',
      SERVLET_TELECHARGEMENT_PDF: 'TelechargementPdfServlet',

      TYPE_DOC_KLIPPA_ENUMS: {
        invoice: 'Facture',
        receipt: 'Reçu',
        bank_transaction: 'Transaction bancaire',
        bank_overview: 'Aperçu bancaire',
        parking: 'Ticket parking',
        petrol: 'Ticket essence',
        ticket: 'Ticket',
        boarding_pass: 'Carte d\'embarquement',
        booking_payment_confirmation: 'Confirmation de paiement de réservation',
        other: 'Autre'
      },

      //////////////////
      //  Dashboard   //
      //////////////////
      NB_EVENT_LIMIT: 10,

      SERVLET_DASHBOARD: 'DashboardServlet',
      //SERVLET_RECHERCHE_STATISTIQUE: 'RechercheStatistiqueServlet',
      SERVLET_EVENT_STATISTIQUE: 'RechercheStatistiqueEvenementServlet',
      SERVLET_EVENT_DEVIS_STATISTIQUE: 'RechercheStatistiqueEvenementDevisServlet',
      SERVLET_GROUPEMENT_DEVIS_STATISTIQUE: 'RechercheStatistiqueEvenementDevisServlet',
      SERVLET_EVENT_TAGS_TYPE: 'RechercheTagsTypeForEvenementServlet',

      //////////////////
      //  RecapMail   //
      //////////////////
      SERVLET_RECHERCHE_RECAP_MAIL: 'RechercheRecapMailServlet',
      SERVLET_RECAP_MAIL: 'RecapMailServlet',
      SERVLET_SHOOTING_MAIL: 'ShootingMailServlet',

      //////////////////
      //  RecapSms   //
      //////////////////
      SERVLET_RECAP_SMS: 'RecapSmsServlet',

      //////////////////
      //  Parametres  //
      //////////////////
      SERVLET_INSERTION_PORTEFEUILLE: 'InsertionPortefeuilleServlet',
      SERVLET_DELETE_PORTEFEUILLE: 'DeletePortefeuilleServlet',
      SERVLET_MODIFY_PASSWORD: 'UpdatePasswordServlet',
      SERVLET_RESET_PASSWORD: 'UpdatePasswordServlet',

      SERVLET_INSERTION_SIGNATURE: 'InsertionSignatureServlet',
      SERVLET_RECHERCHE_SIGNATURE: 'RechercheSignatureServlet',

      /**
       * Pour recherche des parametrages soit societe ou du groupeUser
       */
      SERVLET_RECHERCHE_PARAMETRAGE: 'RechercheParametrageServlet',

      /**
       * Pour update des parametrages soit societe ou du groupeUser
       */
      SERVLET_UPDATE_PARAMETRAGE: 'UpdateParametrageServlet',
      SERVLET_PARAMETRAGE: 'ParametrageServlet',

      /**
       * Permet d'inserer des paramètres SMTP
       * host (String)
       * port (Int)
       * auth (Bool)
       * tls (Bool)
       * mdp (String)
       */
      SERVLET_INSERTION_SMTP: 'InsertionSmtpServlet',

      /**
       * Permet de signer et tamponner le devis
       * page (Int) 0 : Premiere et dernière page; 1 : 1er page; 2 : dernière page
       */
      SERVLET_SIGN_DOCUMENT: 'InsertionDevisSigneServlet',

      SERVLET_INSERTION_TAMPON: 'InsertionTamponServlet',
      SERVLET_RECHERCHE_TAMPON: 'RechercheTamponServlet',
      SERVLET_DELETE_TAMPON: 'DeleteTamponServlet',

      // A abandonner plus tard quand la colonne "page" de la table module va disparaitre

      /*
       'ARRAY_PAGE_MODULE: [
       {valeur:'new_page',libelle:'Nouvelle page dans collab'},
       {valeur:'new_onglet',libelle:'Nouvel onglet'},
       {valeur:'dashboard',libelle:'Dans le Dashboard (Pas utilisé'}
       ],*/

      ARRAY_MODE_OUVERTURE_COLLAB_MODULE: [
        {valeur:'',libelle:'-- Veuillez choisir un mode d\'ouverture --'},
        {valeur:'new_page',libelle:'Nouvelle page dans collab'}
        //{'valeur':'dashboard','libelle':'Dans Dashboard'}
      ],

      ARRAY_MODE_OUVERTURE_LIEN_EXTERNE_MODULE: [
        {valeur:'',libelle:'-- Veuillez choisir un mode d\'ouverture --'},
        {valeur:'new_navigateur_onglet',libelle:'Nouvel onglet'},
        {valeur:'new_navigateur_popup',libelle:'Popup (Pas utilisé encore)'}
      ],

      ARRAY_CATEGORIES_TEMPLATE: [
        {valeur:'defaut',libelle:'Défaut'}
      ],

      //////////////////
      //     Admin    //
      //////////////////

      SERVLET_ADMIN_GROUPE: 'AdminGroupeServlet',
      SERVLET_ADMIN_TOPIC: 'AdminTopicServlet',
      SERVLET_SUPER_ADMIN_CAPFUN: 'CapfunSuperAdminServlet',
      SERVLET_SUPER_ADMIN_PARAMETRAGE: 'ParametrageSuperAdminServlet',


      /**
       * Recupère la liste des groupes
       */
      SERVLET_RECHERCHE_HIERACHIE_ADMIN: 'RechercheHierarchieAdminServlet',

      /**
       * Recupère la liste de tous les users
       */
      SERVLET_RECHERCHE_USER_ADMIN: 'RechercheContactAdminServlet',

      /**
       * Ajoute un groupe dans un groupe
       * nom (string: @nthirion#ICS)
       * nomPapa (string: @nthirion#ICS)
       */
      SERVLET_INSERTION_GROUPE_ADMIN: 'InsertionGroupeAdminServlet',

      /**
       * Ajoute un groupe dans un groupe
       * groupe (id)
       * groupePapa (id)
       */
      SERVLET_INSERTION_GROUPE_IN_GROUPE_ADMIN: 'InsertionGroupeInGroupeAdminServlet',

      /**
       * Supprime un groupe d'un groupe
       * groupe (id)
       * groupePapa (id)
       */
      SERVLET_DELETE_GROUPE_FROM_GROUPE_ADMIN: 'DeleteGroupeFromGroupeAdminServlet',

      /**
       * Déplace un groupe dans un autre groupe
       * groupe (id)
       * goupeOldPapa (id)
       * groupeNewPapa (id)
       */
      SERVLET_UPDATE_GROUPE_FROM_GROUPE_ADMIN: 'UpdateGroupeFromGroupeAdminServlet',


      /**
       * Recherche tous les portefeuille de la société
       */
      SERVLET_RECHERCHE_PORTEFEUILLE_ADMIN: 'RecherchePortefeuilleAdminServlet',

      /**
       * Ajoute un portefeuille
       * nom (string)
       * cle (string)
       * loginId (string)
       * mdpId (string)
       */
      SERVLET_INSERTION_PORTEFEUILLE_ADMIN: 'InsertionPortefeuilleAdminServlet',

      /**
       * Modifie un portefeuille
       * portefeuille (id)
       * nom (string)
       * cle (string)
       * loginId (string)
       * mdpId (string)
       */
      SERVLET_UPDATE_PORTEFEUILLE_ADMIN: 'UpdatePortefeuilleAdminServlet',
      /**
       * Supprime un portefeuille
       * portefeuille (id)
       */
      SERVLET_DELETE_PORTEFEUILLE_ADMIN: 'DeletePortefeuilleAdminServlet',
      /**
       * Ajoute un portefeuille dans un groupe
       * portefeuille (id)
       * groupe (id)
       */
      SERVLET_INSERTION_PORTEFEUILLE_FROM_GROUPE_ADMIN: 'InsertionPortefeuilleInGroupeAdminServlet',
      /**
       * Supprime un portefeuille d'un groupe
       * portefeuille (id)
       * groupe (id)
       */
      SERVLET_DELETE_PORTEFEUILLE_FROM_GROUPE_ADMIN: 'DeletePortefeuilleFromGroupeServlet',

      /**
       * Ajoute/remplace l'entete d'un portefeuille pour les éditions
       * idPortefeuille
       * fileName (type file en form data)
       */
      SERVLET_ENTETE_PORTEFEUILLE_INFOS_ADMIN: 'EntetePortefeuilleInfosAdminServlet',

      /**
       * Ajoute/remplace le logo collab dans les paramétrages d'un groupe
       * idGroupe (ou non)
       * fileName (type file en form data)
       */
      SERVLET_LOGO_COLLAB_ADMIN_SERVLET: 'LogoCollabAdminServlet',

      SERVLET_LOGO_COLLAB_SERVLET: 'LogoCollabServlet',

      /**
       * Permet de recupérer les liste des type de module
       */
      SERVLET_LIST_TYPE_MODULE_ADMIN: 'RechercheTypeModuleServlet',

      /**
       * Permet de lister les module déjà créé
       */
      SERVLET_LIST_MODULE_ADMIN: 'RechercheModuleAdminServlet',

      /**
       * Ajoute un module
       * objJSON (String)
       */
      SERVLET_INSERTION_MODULE_ADMIN: 'InsertionModuleAdminServlet',

      /**
       * Supprime un module
       * idModule (Int)
       */
      SERVLET_DELETE_MODULE_ADMIN: 'DeleteModuleAdminServlet',

      /**
       * Pour insertion :
       * idGroupe (Int)
       * idModule (Int)
       * OU
       * Pour le delete :
       * idModuleGroupe (Int)
       */
      SERVLET_INSERTION_MODULE_IN_GROUPE_ADMIN: 'InsertionModuleGroupeAdminServlet',


      /**
       * Pour recherche des parametrages de la societe (groupe @All)
       */
      SERVLET_RECHERCHE_PARAMETRAGE_ADMIN: 'RechercheParametrageAdminServlet',

      /**
       * Pour update des parametrages de la societe (groupe @All)
       */
      //SERVLET_UPDATE_PARAMETRAGE_ADMIN: 'UpdateParametrageAdminServlet',



      SERVLET_ADMINCOLLAB_GROUPE: 'GroupeSuperAdminServlet',
      SERVLET_ADMINCOLLAB_CREATION_UTILISATEUR: 'CreationUtilisateurSuperAdminServlet',
      SERVLET_ADMINCOLLAB_RECAPMAIL: 'RecapMailSuperAdminServlet',
      SERVLET_STATS_COLLAB: 'StatsCollabServlet',

      ///////////////////////
      //     Channels      //
      ///////////////////////

      CHANNEL_TYPE_ACCUEIL: 1,
      CHANNEL_TYPE_CHANNEL: 2,

      /**
       * Permet de récupérer les channels configuré de l'user
       * type :
       *  - 1 : Accueil (Pour affiches les topic sur l'accueil)
       *  - 2 : Defaut (channel)
       */
      SERVLET_RECHERCHE_CHANNEL: 'RechercheChannelServlet',

      /**
       * Permet d'ajouter un channel
       *  - type : type de channel à ajouter
       *  - idGroupe : idGroupe à lié au channel
       *  - idChannel : idChannel à utilisé dans le cas où on veux ajouter seulement des groupe au channel existan
       */
      SERVLET_INSERTION_CHANNEL: 'InsertionChannelServlet',

      /**
       * Permet de supprimer un channel ou un groupe d'un channel
       *  - idGroupe : Groupe à supprimer du channel
       *  - idChannel : Si "idGroupe" Channel où il faut supprimer le groupe / Sans "idGroupe" Supprime les groupes et le channel
       */
      SERVLET_DELETE_CHANNEL: 'DeleteChannelServlet',

      ///////////////////////
      //       Divers      //
      ///////////////////////

      SERVLET_RUN_JOB: 'RunJobServlet',

      // Nom du portefeuille ICS
      NOM_PORTEFEUILLE_ICS: 'ICS',

      // Image si pas d'avatar
      SRC_NO_PHOTO: 'images/design/no-photo.jpg',

      //LOADING: '<div class="loadingVert center-block"></div>',
      MINI_LOADING: '<div class="miniLoadingVert"></div>',
      LOADING: '<div class="loadingBig center-block"></div>',

      MIDDLE_LOADING: '<div class="loadingMiddle center-block"></div>',
      MIDDLE_LOADING_SRC: 'images/design/icons/ajax.loader-middle-ring.gif',

      MINI_LOADING_RING: '<div class="loadingMini center-block"></div>',
      MINI_LOADING_SRC: 'images/design/icons/ajax.loader-mini-ring.gif',

      BIG_LOADING: '<div class="loadingBig center-block"></div>',

      IMG_NO_EXIST: 'images/design/icons/image_introuvable.png',

      NON_AUTORISE: 'Vous avez été deconnecté ou n\'êtes pas autorisé !',
      SERVLET_OFF: 'Problème de serveur !',
      ERREUR_500: 'Serveur momentanément indisponible... (Erreur 500)',
      ERREUR_502: 'Serveur momentanément indisponible... (Erreur 502)',
      ERREUR_503: 'Serveur momentanément indisponible... (Erreur 503)',

      // Content type pour les fichier excel en .xlsx
      CONTENT_TYPE_XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

      PREFIX_MOBILE_FRANCAIS: ["06","07"],
      PREFIX_MOBILE_BELGE: ["046","047","048","049"],

      //////////////////////
      //     Evenements   //
      //////////////////////

      URL_LOCATE_ADRESSE: 'http://maps.google.com/?q=',

      // Modal de confirmation de cloture d'événement par le créateur
      TITLE_MSG_CONFIRMATION_CLOTURE_EVENEMENT_BY_CREATEUR: 'Cloturer l\'événement ?',
      TITLE_MSG_CONFIRMATION_REOUVRIR_EVENEMENT_BY_CREATEUR: 'Réouvrir l\'événement ?',

      MSG_CONFIRMATION_CLOTURE_EVENEMENT_BY_CREATEUR: '<div class="text-left"><strong>Commentaire :</strong><small>(facultatif)</small></div><div class="row"><div class="col-xs-12 padding-left-0"><textarea style="width:100%" rows="3" id="commentaireCloture" class="form-control"></textarea></div></div>',
      BTN_CONFIRMATION_CLOTURE_EVENEMENT_BY_CREATEUR: 'Oui !',

      // Modal de confirmation de cloture d'événement par un destinataire de l'événement
      TITLE_MSG_CONFIRMATION_CLOTURE_EVENEMENT_BY_NO_CREATEUR: 'Demande de Cloture d\'un événement',
      TITLE_MSG_CONFIRMATION_REOUVRIR_EVENEMENT_BY_NO_CREATEUR: 'Demande de réouverture d\'un événement',
      TITLE_MSG_CONFIRMATION_CLOTURE_EVENEMENT_BY_FOURNISSEUR: 'Demande de Cloture',
      MSG_CONFIRMATION_CLOTURE_EVENEMENT_BY_NO_CREATEUR: 'Vous n\'êtes pas le créateur de cet événement. Une demande de cloture sera donc envoyée au créateur.</hr>',
      MSG_CONFIRMATION_REOUVRIR_EVENEMENT_BY_NO_CREATEUR: 'Vous n\'êtes pas le créateur de cet événement. Une demande de réouverture sera donc envoyée au créateur.</hr><div class="text-left"><strong>Commentaire :</strong><small>(facultatif)</small></div><textarea style="width:100%" rows="3" id="commentaireCloture" class="form-control"></textarea>',
      MSG_CONFIRMATION_CLOTURE_EVENEMENT_BY_FOURNISSEUR: '<div class="text-left"><strong>Commentaire :</strong><small>(facultatif)</small></div><div class="row"><div class="col-xs-12 padding-left-0"><textarea style="width:100%" rows="3" id="commentaireCloture" class="form-control"></textarea></div></div>',
      BTN_CONFIRMATION_CLOTURE_EVENEMENT_BY_NO_CREATEUR: 'Demander la cloture !',
      BTN_CONFIRMATION_REOUVRIR_EVENEMENT_BY_NO_CREATEUR: 'Demander la réouverture !',

      /**
       * Groupements par défault pour les événements
       */
      GROUPEMENTS_DEFAULT: ['Défaut','Administratif','Infrastructure','Service','Marketing'],

      /**
       * Texte par défault pour une demande de devis
       */
      DEFAULT_DESCRIPTION_DEMANDE_DEVIS: 'Nous vous remercions de prendre connaissance de cette demande. A votre disposition.',

      TOOLBAR_CKEDITOR_MINI: [
        [
          'FontSize',
          'TextColor',
          'BGColor',
          'Bold',
          'Italic',
          'Underline',
          '-',
          'Blockquote',
          'Link',
          'Unlink',
          '-',
          'JustifyLeft',
          'JustifyCenter',
          'JustifyRight',
          'JustifyBlock'
        ]
      ]


    });
})();
