(function () {

  'use strict';

  /**
   * @desc Composant boutton document
   * @example <bouton-document></bouton-document>
   */
  angular
    .module('collaboreApp')
    .component('boutonDocument', {
      bindings: {
        idTopic: '<',
        idDevis: '<',
        facture: '<',
        tagsType: '<',
        displayBtnAction: '<',
        allowDelete: '<',
        documentsComptable: '<',
        documents: '<',
        devisIsIntervention: '<',
        hasDemande: '<',
        hasDevisSigne:'<',
        eventCloture: '<',
        onUpdate: '&'
      },
      templateUrl: 'app/document/bouton-document.component.html',
      controllerAs: 'boutondocumentctrl',
      controller: BoutonDocumentCtrl
    });

  /*@ngInject*/
  function BoutonDocumentCtrl($q, FactureService, ModalsService, uuid, UtilsService) {
    var ctrl = this;
    ctrl.currentGroupe = UtilsService.getCurrentUser();
    ctrl.parseDateHeure = UtilsService.parseDateHeure;
    ctrl.sendToComfactEnable = false;
    ctrl.document = {
      sentToComfactfacture: false,
      dateSentToComfactfacture: null
    };
    ctrl.onClickBoutonFacture = onClickBoutonFacture;
    ctrl.onClickEnvoyerVersComfact = onClickEnvoyerVersComfact;

    ctrl.$onInit = function () {
      ctrl.sendToComfactEnable = UtilsService.getParametrageComfact('allowSendToComfactfacture');
      ctrl.uniqId = uuid.new();
      init();
    };

    ctrl.$onChanges = function (changes) {
      checkSentToComfact();
    };

    function init(){
      if (!ctrl.documents && ctrl.facture) ctrl.documents = ctrl.facture.getListeDocument();
      checkSentToComfact();
    }

    function checkSentToComfact() {
      if (ctrl.documents != null && angular.isArray(ctrl.documents) && ctrl.documents.length) {

        for (var i = 0; i < ctrl.documents.length; i++) {
          if (ctrl.documents[i].isSentToComfactfacture()) {
            ctrl.document.sentToComfactfacture = true;
            ctrl.document.dateSentToComfactfacture = ctrl.documents[i].getDateSentToComfactfacture();
            break;
          }
        }

        if(ctrl.documentsComptable && angular.isArray(ctrl.documentsComptable) && ctrl.documentsComptable.length && !ctrl.document.sentToComfactfacture) {
          for(var d = 0; d < ctrl.documentsComptable.length; d++) {
            if (ctrl.documentsComptable[d].isSentToComfactfacture()) {
              ctrl.document.sentToComfactfacture = true;
              ctrl.document.dateSentToComfactfacture = ctrl.documentsComptable[d].getDateSentToComfactfacture();
              break;
            }
          }
        }
      }
    }

    function onClickBoutonFacture() {
      openViewFacture();
    }

    /*
    function onClickEnvoyerVersComfact() {
      ModalsService.confirm('Envoyer vers Comfact ?').then(function () {
        ModalsService.loading('Envoie en cours');
        FactureService
          .sendFactureToComfact(ctrl.facture.getIdContenu())
          .then(function (facture) {

            ctrl.facture.setData(facture);
            ctrl.documents = facture.getListeDocument();
            checkSentToComfact();
            ctrl.onUpdate({
              objUpdate: {
                update: {
                  contenu: ctrl.facture
                }
              }
            });
            //console.log(json);
            ModalsService.info('Terminé', 'Document envoyé sur Comfact !');
          })
          .catch(function (msg) {
            ModalsService.alertErreur(msg);
          });
      });
    }*/
    function onClickEnvoyerVersComfact() {

      var options = {
        backdrop: 'static'
      };
      var params = {
        idTopic: ctrl.idTopic,
        idDevis: ctrl.idDevis,
        facture: ctrl.facture,
        isIntervention: ctrl.devisIsIntervention,
        tagsType: ctrl.tagsType,
        objHasDocuments: {
          demandeDevis: ctrl.hasDemande,
          devisSigne: ctrl.hasDevisSigne
        },
        showCloture: (!ctrl.eventCloture),
        isCloture: ctrl.eventCloture
      };

      ModalsService
        .modalComponent('comfactFacture', params, options)
        .then(function (obj) {

          if((obj.action === 'sentToComfact' || obj.action === 'sentToComfactAndCloture') && obj.hasOwnProperty('devis')){

            var object = {
              objUpdate: {
                update: {
                  contenu: obj.devis
                }
              }
            };
            if(obj.action === 'sentToComfactAndCloture') object.objUpdate.refreshTopic = true;
            ctrl.onUpdate(object);
          }

          //ctrl.facture.setData(devis.getFacture());
          //ctrl.documents = ctrl.facture.getListeDocument();
          //checkSentToComfact();


          /*
          FactureService
            .sendFactureToComfactFacture(ctrl.facture.getIdContenu(), objet)
            .then(function (facture) {
              ctrl.facture.setData(facture);
              ctrl.documents = facture.getListeDocument();
              checkSentToComfact();
              ctrl.onUpdate({
                objUpdate: {
                  update: {
                    contenu: ctrl.facture
                  }
                }
              });
            })
            .catch(function (msg) {
              ModalsService.alertErreur(msg);
            });*/
        })
        .catch(function (msg) {
          if (msg !== 'cancel') {
            ModalsService.alertErreur(msg);
          }
        });
    }

    function openViewFacture() {
      var options = {
        contenu: ctrl.facture,
        documents: ctrl.documents,
        allowDelete: ctrl.allowDelete,
        saveAuto: false,
        onUpdate: function (obj) {
          var deferred = $q.defer();
          ctrl.onUpdate({objUpdate: obj})
            .then(function (contenu) {
              if(contenu.getFacture()) ctrl.documents = contenu.getFacture().getListeDocument();
              else ctrl.documents = [];
              checkSentToComfact();
              deferred.resolve(ctrl.documents);
            })
            .catch(function (msg) {
              deferred.reject(msg);
            });
          return deferred.promise;
        }
      };
      ModalsService.modalViewDocument(options);
    }
  }
})();
