'use strict';

var RetrievePwdCtrl = function(COLLAB_CONF, $scope, $state, UserService, ConfigService, ResetPwdService, sweet) {
	var vm = this;

	vm.loadingMail = false;

	vm.submitFormMail = function(){
		vm.loadingMail = COLLAB_CONF.LOADING;

		ResetPwdService.resetPwd($scope.Email).then(function(mail){

			if (mail.Resultats[0].success){
				sweet.show({
					title: 'Récupération du mot de passe',
					text: 'Un Email va vous être envoyé avec votre nouveau mot de passe !',
					type: 'success',
					html: true
				});
			}
			vm.loadingMail = false;
			$state.go('login');
		}, function(){
			sweet.show({
				title: 'Récupération du mot de passe',
				text: 'Votre adresse mail est incorrect ou n\'existe pas dans notre base de données !',
				type: 'error',
				html: false
			});
			vm.loadingMail = false;
		});

	};
};

angular.module('collaboreApp').controller('RetrievePwdCtrl',[
	'COLLAB_CONF',
	'$scope',
	'$state',
	'UserService',
	'ConfigService',
	'ResetPwdService',
	'sweet',
	RetrievePwdCtrl
]);