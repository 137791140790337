'use strict';

angular.module('collaboreApp').directive('popupEvenement',['COLLAB_CONF','$timeout','$sce','UtilsService','$parse','$state','FiltreService','TopicStatesService', function(COLLAB_CONF,$timeout,$sce,UtilsService,$parse,$state,FiltreService,TopicStatesService) {
    return {
        restrict: 'A',
        replace: false,
        transclude: true,
        template : '<span popover-is-open="popupevenementctrl.popupIsOpen" popover-class="popover-collab popover-lg" uib-popover-template="\'app/topics/directives/templates/popover-html-evenement.html\'" popover-trigger="none" popover-placement="{{popupevenementctrl.placement}}" popover-append-to-body="true"><ng-transclude></ng-transclude></span>',

        link: function (scope, element, attr){
            var isEnterLabel = false,timoutEnterPromise,timoutLeavePromise;

           var iTopic = $parse(attr.popupEvenement)(scope);

           scope.popupevenementctrl.placement = (attr.popupPlacement) ? attr.popupPlacement : 'right';

            scope.popupIsOpen = false;

            /**
             * Lors du clique
             * @param e
             */
            function onClick(e){
                e.stopPropagation();
            }

            /**
             * Lorsque la souris est entrée dans le label
             */
            function onMouseEnter(){
                isEnterLabel = true;

                scope.popupevenementctrl.popupIsOpen = true;
                scope.popupevenementctrl.loadingEvenementPopup = COLLAB_CONF.MIDDLE_LOADING;

                if(timoutLeavePromise){
                    $timeout.cancel(timoutLeavePromise);
                }
                timoutEnterPromise = $timeout(function() {
                    if(isEnterLabel){


                        TopicStatesService.getEvenementByTopic(iTopic).then(function (evenement) {
                            scope.popupevenementctrl.loadingEvenementPopup = false;

                            scope.popupevenementctrl.evenement = UtilsService.nl2br($sce.trustAsHtml(evenement.objet));
                            //vm.evenement = evenement;

                        });
                    }
                },500);
            }

            /**
             * Lorsque la souris est sortie du label
             */
            function onMouseLeave(){
                isEnterLabel = false;

                if(timoutEnterPromise){
                    $timeout.cancel(timoutEnterPromise);
                }

                timoutLeavePromise = $timeout(function(){
                    if(!scope.popupevenementctrl.isInsidePopup){
                        scope.popupevenementctrl.popupIsOpen = false;
                    }
                },500);
            }

            //$timeout(function(){
                var $element = angular.element(element[0]);

                //var elem = element[0];
                //var span = angular.element(elem).find('span')[0];
                if($element.find('span').length!==0){
                    var elem = $element.find('span')[0];
                    var eventMethod = elem.addEventListener ? 'addEventListener' : 'attachEvent';
                    var removeEventMethod = elem.removeEventListener ? 'removeEventListener' : 'detachEvent';
                    var onClickEvent = eventMethod === 'attachEvent' ? 'onclick' : 'click';
                    var onMouseEnterEvent = eventMethod === 'attachEvent' ? 'onmouseenter' : 'mouseenter';
                    var onMouseLeaveEvent = eventMethod === 'attachEvent' ? 'onmouseleave' : 'mouseleave';

                    // Passe les event en listener pour eviter le digest d'angular
                    elem[eventMethod](onClickEvent,onClick);            // Ajoute un listener sur le clique
                    elem[eventMethod](onMouseEnterEvent,onMouseEnter);  // Ajoute un listener sur l'entrée de la souris dans le label
                    elem[eventMethod](onMouseLeaveEvent,onMouseLeave);  // Ajoute un listener sur la sortie de la souris du label

                    scope.$on('$destroy',function(){
                        elem[eventMethod](onClickEvent,onClick);                    // Supprime le listener du clique
                        elem[removeEventMethod](onMouseEnterEvent, onMouseEnter);   // Supprime le listener du mouse enter
                        elem[removeEventMethod](onMouseLeaveEvent, onMouseLeave);   // Supprime le listener du mouse leave
                    });
                }

            //});
        },
        controller: [function(){

            var vm = this;

            vm.onMouseEnterPopup = function(){
                vm.isInsidePopup = true;
            };
            vm.onMouseLeavePopup = function(){
                vm.isInsidePopup = false;
                vm.popupIsOpen = false;
            };
        }],
        controllerAs: 'popupevenementctrl'
    };
}]);
