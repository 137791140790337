(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .config(config);

  /** @ngInject */
  function config($provide, $httpProvider, $qProvider, $rootScopeProvider, $uibTooltipProvider, $compileProvider, hotkeysProvider, toastyConfigProvider, NotificationsToastProvider) {

    /*
    $provide.decorator('nvFileOverDirective', function ($delegate) {
      var directive = $delegate[0],
          link = directive.link;

      directive.compile = function () {
          return function (scope, element, attrs) {
              var overClass = attrs.overClass || 'nv-file-over';
              link.apply(this, arguments);
              element.on('dragleave', function () {
                console.log('dragleave');
                  element.removeClass(overClass);
              });
          };
      };

      return $delegate;
  });*/
    $rootScopeProvider.digestTtl(10);

    //$httpProvider.defaults.timeout = 600000;  // 10 Minutes
    $httpProvider.interceptors.push('timeoutHttpInterceptor');
    $httpProvider.interceptors.push('addTokenHttpInterceptor');

    NotificationsToastProvider.getPermissionNotificationDesktop();

    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);

    $qProvider.errorOnUnhandledRejections(false);

    // Désactive l'affichage de l'aide des raccourci clavier affiché en tapant "?"
    hotkeysProvider.includeCheatSheet = false;

    /********************** toasty***************************/
    // https://github.com/invertase/angular-toasty
    toastyConfigProvider.setConfig({
      showClose: true,
      clickToClose: false,
      timeout: 0,
      limit: 10,
      html: true,
      sound: false,
      shake: false,
      theme: 'material',
      position: 'top-right'
    });


    $uibTooltipProvider.options({
      //'popupDelay': 0,
      popupCloseDelay: 0,
      animation: false,
      appendToBody: true,
      placement: 'auto'
    });

    /*
            $provide.decorator('dateRangePickerDirective', function ($delegate) {

                var directive = $delegate[0];

                // store the original link fn
                var originalLinkFn = directive.link;

                // replace the compile fn
                directive.compile = function(tElem, tAttr) {


                    return function newLinkFn(scope, elem, attr, modelCtrl) {
                        // fire the originalLinkFn
                        originalLinkFn.apply(directive, arguments);

                        console.log(originalLinkFn);
                        console.log(this);
                        console.log(this._mergeOpts);
                        console.log($delegate);
                    };
                };

                //console.log(originalLinkFn._init);

                // return the $delegate
                return $delegate;
            });*/


    //$uibTooltipProvider.setTriggers({
    //    'openPopup': 'closePopup',
    //    'openNotif': 'closeNotif'/*,
    //    'mouseenter manual': 'none',
    //    'popoverIsopenShow': 'popoverIsopenHide'*/
    //});


    // Desactive les debug, log, info et warn de $log (a commenter quand ui-bootstrap sera à jour et quand les changement de nom seront fait)

    /*
            $logProvider.debugEnabled(false);
            $provide.decorator('$log', function ($delegate) {
                //Original methods
                var origInfo = $delegate.info;
                var origLog = $delegate.log;
                var origWarn = $delegate.warn;

                //Override the default behavior
                $delegate.info = function () {

                    if ($logProvider.debugEnabled()){
                        origInfo.apply(null, arguments);
                    }
                };

                //Override the default behavior
                $delegate.log = function () {

                    if ($logProvider.debugEnabled()){
                        origLog.apply(null, arguments);
                    }

                };

                //Override the default behavior
                $delegate.warn = function () {

                    if ($logProvider.debugEnabled()){
                        origWarn.apply(null, arguments);
                    }
                };

                return $delegate;
            });*/

    /*
            $provide.decorator('uibDatepickerPopupDirective', function ($delegate) {
                var directive = $delegate[0];
                var link = directive.link;

                directive.compile = function () {
                    return function (scope, element) {
                        link.apply(this, arguments);
                        element.mask('99/99/9999');
                    };
                };

                return $delegate;
            });*/

    // Decorator pour $q, ajout d'un serial
    $provide.decorator('$q', function ($delegate) {
      //Helper method copied from q.js.
      var isPromiseLike = function (obj) { return obj && angular.isFunction(obj.then); };

      /*
       * @description Execute a collection of tasks serially.  A task is a function that returns a promise
       *
       * @param {Array.<Function>|Object.<Function>} tasks An array or hash of tasks.  A tasks is a function
       *   that returns a promise.  You can also provide a collection of objects with a success tasks, failure task, and/or notify function
       * @returns {Promise} Returns a single promise that will be resolved or rejected when the last task
       *   has been resolved or rejected.
       */
      function serial(tasks) {
        //Fake a "previous task" for our initial iteration
        var prevPromise;
        var error = new Error();
        angular.forEach(tasks, function (task, key) {
          var success = task.success || task;
          var fail = task.fail;
          var notify = task.notify;
          var nextPromise;

          //First task
          if (!prevPromise) {
            nextPromise = success();
            if (!isPromiseLike(nextPromise)) {
              error.message = 'Task ' + key + ' did not return a promise.';
              throw error;
            }
          }
          else {
            //Wait until the previous promise has resolved or rejected to execute the next task
            nextPromise = prevPromise.then(
              /*success*/function (data) {
                if (!success) return data;
                var ret = success(data);
                if (!isPromiseLike(ret)) {
                  error.message = 'Task ' + key + ' did not return a promise.';
                  throw error;
                }
                return ret;
              },
              /*failure*/function (reason) {
                if (!fail) { return $delegate.reject(reason); }
                var ret = fail(reason);
                if (!isPromiseLike(ret)) {
                  error.message = 'Fail for task ' + key + ' did not return a promise.';
                  throw error;
                }
                return ret;
              },
              notify);
          }
          prevPromise = nextPromise;
        });

        return prevPromise || $delegate.when();
      }

      /*
     * Adapted from the post and comments at http://www.codeducky.org/q-serial/
     * @description Execute a collection of tasks serially.  A task is a function that returns a promise
     *
     * @param {Array.<Function>|Object.<Function>} tasks An array or hash of tasks.  A tasks is a function
     *   that returns a promise.  You can also provide a collection of objects with a success tasks, failure task, and/or notify function
     * @returns {Promise} Returns a single promise that will be resolved or rejected when the last task
     *   has been resolved or rejected.
     */
    function serialWithResponse(tasks) {
      //Fake a “previous task” for our initial iteration
      var prevPromise;
      var error = new Error();
      var results = [];

      var lastTask = function() {
        var endPromise = $delegate.defer();
        endPromise.resolve(results);
        return endPromise.promise;
      }
      tasks = tasks.concat(lastTask);

      angular.forEach(tasks, function(task, key) {

        var success = task.success || task;
        var fail = task.fail;
        var notify = task.notify;
        var nextPromise;

        //First task
        if (!prevPromise) {
          nextPromise = success();
          if (!isPromiseLike(nextPromise)) {
            error.message = "Task " + key + " did not return a promise.";
            throw error;
          }
        } else {
          //Wait until the previous promise has resolved or rejected to execute the next task
          nextPromise = prevPromise.then(
            /*success*/
            function(result) {
              results.push(result);
              if (!success) return results;
              var ret = success(result);
              if (!isPromiseLike(ret)) {
                error.message = "Task " + key + " did not return a promise.";
                throw error;
              }
              return ret;
            },
            /*failure*/
            function(reason) {
              if (!fail) {
                return $delegate.reject(reason);
              }
              var ret = fail(reason);
              if (!isPromiseLike(ret)) {
                error.message = "Fail for task " + key + " did not return a promise.";
                throw error;
              }
              return ret;
            },
            notify);
        }
        prevPromise = nextPromise;
      });

      return prevPromise || $delegate.when();
    }

      $delegate.serial = serial;
      $delegate.serialWithResponse = serialWithResponse;
      return $delegate;
    });

    // Override la directive pour ajouter le son en fichier wav externe pour le HTTPS (avant c'était un base64)
    $provide.decorator('toastyDirective', function ($delegate) {
      $delegate[0].template = '<div id="toasty" ng-class="[position]">'
        + '<audio id="toasty-sound" src="assets/lib/toasty_notification.wav" preload="auto"></audio>'
        + '<div class="toast" ng-repeat="toast in toasty" ng-class="[toast.type, toast.interact, toast.shake, toast.theme]" ng-click="clickToasty(toast)">'
        + '<div ng-click="close(toast.id)" class="close-button" ng-if="toast.showClose"></div>'
        + '<div ng-if="toast.title || toast.msg" class="toast-text">'
        + '<span class="toast-title" ng-if="!toast.html && toast.title" ng-bind="toast.title"></span>'
        + '<span class="toast-title" ng-if="toast.html && toast.title" ng-bind-html="toast.title"></span>'
        + '<br ng-if="toast.title && toast.msg" />'
        + '<span class="toast-msg" ng-if="!toast.html && toast.msg" ng-bind="toast.msg"></span>'
        + '<span class="toast-msg" ng-if="toast.html && toast.msg" ng-bind-html="toast.msg"></span>'
        + '</div>'
        +'</div>'
        + '</div>';

      return $delegate;
    });

    // Decorator pour ajouter
    /*
     $provide.decorator('uiSelectDirective', function( $delegate, $parse ) {
     var directive = $delegate[ 0],
     preCompile = directive.compile;

     directive.compile = function compile() {
     var link = preCompile.apply( this, arguments );

     return function( scope, element, attrs, controller ) {
     link.apply( this, arguments );

     var $select = controller[ 0 ];

     if(attrs.uiSelectOnOpen || attrs.uiSelectOnClose) {

     if(attrs.uiSelectOnOpen){
     $select.onOpenCallback = $parse(attrs.uiSelectOnOpen);
     }

     if(attrs.uiSelectOnClose){
     $select.onCloseCallback = $parse(attrs.uiSelectOnClose);
     }

     scope.$watch('$select.open', function(newV){
     if(newV) {
     if(attrs.uiSelectOnOpen){
     $select.onOpenCallback(scope, {
     $model: $select.parserResult.modelMapper(scope, {})
     })($select);
     }
     } else {
     if(attrs.uiSelectOnClose){
     $select.onCloseCallback(scope, {
     $model: $select.parserResult.modelMapper(scope, {})
     })($select);
     }
     }
     });
     }
     };
     };

     return $delegate;
     });*/


    /*
  $provide.decorator('nvFileOverDirective', function ($delegate) {
    var directive = $delegate[0],
      link = directive.link;

    directive.compile = function () {
      return function (scope, element, attrs) {
        var overClass = attrs.overClass || 'nv-file-over';
        link.apply(this, arguments);
        element.on('dragleave', function () {
          element.removeClass(overClass);
        });
      };
    };

    return $delegate;
  });*/
  }

  angular.module('collaboreApp').value('cgBusyDefaults',{
    message:'Patientez...'
  });

  String.prototype.ucfirst = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };

})();

/* eslint-disable */
angular.module("uib/template/datepicker/datepicker.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("uib/template/datepicker/datepicker.html",
    "<div ng-switch=\"datepickerMode\">\n" +
    "  <div uib-daypicker ng-switch-when=\"day\" tabindex=\"0\" class=\"uib-daypicker\"></div>\n" +
    "  <div uib-monthpicker ng-switch-when=\"month\" tabindex=\"0\" class=\"uib-monthpicker\"></div>\n" +
    "  <div uib-yearpicker ng-switch-when=\"year\" tabindex=\"0\" class=\"uib-yearpicker\"></div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("uib/template/datepicker/day.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("uib/template/datepicker/day.html",
    "<table role=\"grid\" aria-labelledby=\"{{::uniqueId}}-title\" aria-activedescendant=\"{{activeDateId}}\">\n" +
    "  <thead>\n" +
    "    <tr>\n" +
    "      <th><button type=\"button\" class=\"btn btn-default btn-sm pull-left uib-left\" ng-click=\"move(-1)\" tabindex=\"-1\"><i class=\"glyphicon glyphicon-chevron-left\"></i></button></th>\n" +
    "      <th colspan=\"{{::5 + showWeeks}}\"><button id=\"{{::uniqueId}}-title\" role=\"heading\" aria-live=\"assertive\" aria-atomic=\"true\" type=\"button\" class=\"btn btn-default btn-sm uib-title\" ng-click=\"toggleMode()\" ng-disabled=\"datepickerMode === maxMode\" tabindex=\"-1\"><strong>{{title}}</strong></button></th>\n" +
    "      <th><button type=\"button\" class=\"btn btn-default btn-sm pull-right uib-right\" ng-click=\"move(1)\" tabindex=\"-1\"><i class=\"glyphicon glyphicon-chevron-right\"></i></button></th>\n" +
    "    </tr>\n" +
    "    <tr>\n" +
    "      <th ng-if=\"showWeeks\" class=\"text-center\"></th>\n" +
    "      <th ng-repeat=\"label in ::labels track by $index\" class=\"text-center\"><small aria-label=\"{{::label.full}}\">{{::label.abbr}}</small></th>\n" +
    "    </tr>\n" +
    "  </thead>\n" +
    "  <tbody>\n" +
    "    <tr class=\"uib-weeks\" ng-repeat=\"row in rows track by $index\" role=\"row\">\n" +
    "      <td ng-if=\"showWeeks\" class=\"text-center h6\"><em>{{ weekNumbers[$index] }}</em></td>\n" +
    "      <td ng-repeat=\"dt in row\" class=\"uib-day text-center\" role=\"gridcell\"\n" +
    "        id=\"{{::dt.uid}}\"\n" +
    "        ng-class=\"::dt.customClass\">\n" +
    "        <button type=\"button\" class=\"btn btn-default btn-sm\"\n" +
    "          uib-is-class=\"\n" +
    "            'btn-info' for selectedDt,\n" +
    "            'active' for activeDt\n" +
    "            on dt\"\n" +
    "          ng-click=\"select(dt.date)\"\n" +
    "          ng-disabled=\"::dt.disabled\"\n" +
    "          tabindex=\"-1\"><span ng-class=\"::{'text-muted': dt.secondary, 'text-info': dt.current}\">{{::dt.label}}</span></button>\n" +
    "      </td>\n" +
    "    </tr>\n" +
    "  </tbody>\n" +
    "</table>\n" +
    "");
}]);

angular.module("uib/template/datepicker/month.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("uib/template/datepicker/month.html",
    "<table role=\"grid\" aria-labelledby=\"{{::uniqueId}}-title\" aria-activedescendant=\"{{activeDateId}}\">\n" +
    "  <thead>\n" +
    "    <tr>\n" +
    "      <th><button type=\"button\" class=\"btn btn-default btn-sm pull-left uib-left\" ng-click=\"move(-1)\" tabindex=\"-1\"><i class=\"glyphicon glyphicon-chevron-left\"></i></button></th>\n" +
    "      <th colspan=\"{{::yearHeaderColspan}}\"><button id=\"{{::uniqueId}}-title\" role=\"heading\" aria-live=\"assertive\" aria-atomic=\"true\" type=\"button\" class=\"btn btn-default btn-sm uib-title\" ng-click=\"toggleMode()\" ng-disabled=\"datepickerMode === maxMode\" tabindex=\"-1\"><strong>{{title}}</strong></button></th>\n" +
    "      <th><button type=\"button\" class=\"btn btn-default btn-sm pull-right uib-right\" ng-click=\"move(1)\" tabindex=\"-1\"><i class=\"glyphicon glyphicon-chevron-right\"></i></button></th>\n" +
    "    </tr>\n" +
    "  </thead>\n" +
    "  <tbody>\n" +
    "    <tr class=\"uib-months\" ng-repeat=\"row in rows track by $index\" role=\"row\">\n" +
    "      <td ng-repeat=\"dt in row\" class=\"uib-month text-center\" role=\"gridcell\"\n" +
    "        id=\"{{::dt.uid}}\"\n" +
    "        ng-class=\"::dt.customClass\">\n" +
    "        <button type=\"button\" class=\"btn btn-default\"\n" +
    "          uib-is-class=\"\n" +
    "            'btn-info' for selectedDt,\n" +
    "            'active' for activeDt\n" +
    "            on dt\"\n" +
    "          ng-click=\"select(dt.date)\"\n" +
    "          ng-disabled=\"::dt.disabled\"\n" +
    "          tabindex=\"-1\"><span ng-class=\"::{'text-info': dt.current}\">{{::dt.label}}</span></button>\n" +
    "      </td>\n" +
    "    </tr>\n" +
    "  </tbody>\n" +
    "</table>\n" +
    "");
}]);

angular.module("uib/template/datepicker/year.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("uib/template/datepicker/year.html",
    "<table role=\"grid\" aria-labelledby=\"{{::uniqueId}}-title\" aria-activedescendant=\"{{activeDateId}}\">\n" +
    "  <thead>\n" +
    "    <tr>\n" +
    "      <th><button type=\"button\" class=\"btn btn-default btn-sm pull-left uib-left\" ng-click=\"move(-1)\" tabindex=\"-1\"><i class=\"glyphicon glyphicon-chevron-left\"></i></button></th>\n" +
    "      <th colspan=\"{{::columns - 2}}\"><button id=\"{{::uniqueId}}-title\" role=\"heading\" aria-live=\"assertive\" aria-atomic=\"true\" type=\"button\" class=\"btn btn-default btn-sm uib-title\" ng-click=\"toggleMode()\" ng-disabled=\"datepickerMode === maxMode\" tabindex=\"-1\"><strong>{{title}}</strong></button></th>\n" +
    "      <th><button type=\"button\" class=\"btn btn-default btn-sm pull-right uib-right\" ng-click=\"move(1)\" tabindex=\"-1\"><i class=\"glyphicon glyphicon-chevron-right\"></i></button></th>\n" +
    "    </tr>\n" +
    "  </thead>\n" +
    "  <tbody>\n" +
    "    <tr class=\"uib-years\" ng-repeat=\"row in rows track by $index\" role=\"row\">\n" +
    "      <td ng-repeat=\"dt in row\" class=\"uib-year text-center\" role=\"gridcell\"\n" +
    "        id=\"{{::dt.uid}}\"\n" +
    "        ng-class=\"::dt.customClass\">\n" +
    "        <button type=\"button\" class=\"btn btn-default\"\n" +
    "          uib-is-class=\"\n" +
    "            'btn-info' for selectedDt,\n" +
    "            'active' for activeDt\n" +
    "            on dt\"\n" +
    "          ng-click=\"select(dt.date)\"\n" +
    "          ng-disabled=\"::dt.disabled\"\n" +
    "          tabindex=\"-1\"><span ng-class=\"::{'text-info': dt.current}\">{{::dt.label}}</span></button>\n" +
    "      </td>\n" +
    "    </tr>\n" +
    "  </tbody>\n" +
    "</table>\n" +
    "");
}]);

angular.module("uib/template/datepickerPopup/popup.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("uib/template/datepickerPopup/popup.html",
    "<ul class=\"uib-datepicker-popup dropdown-menu uib-position-measure\" dropdown-nested ng-if=\"isOpen\" ng-keydown=\"keydown($event)\" ng-click=\"$event.stopPropagation()\">\n" +
    "  <li ng-transclude></li>\n" +
    "  <li ng-if=\"showButtonBar\" class=\"uib-button-bar\">\n" +
    "    <span class=\"btn-group pull-left\">\n" +
    "      <button type=\"button\" class=\"btn btn-sm btn-info uib-datepicker-current\" ng-click=\"select('today', $event)\" ng-disabled=\"isDisabled('today')\">{{ getText('current') }}</button>\n" +
    "      <button type=\"button\" class=\"btn btn-sm btn-danger uib-clear\" ng-click=\"select(null, $event)\">{{ getText('clear') }}</button>\n" +
    "    </span>\n" +
    "    <button type=\"button\" class=\"btn btn-sm btn-success pull-right uib-close\" ng-click=\"close($event)\">{{ getText('close') }}</button>\n" +
    "  </li>\n" +
    "</ul>\n" +
    "");
}]);
/* eslint-enable */
