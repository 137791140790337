(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.ComfactConfig
   * @description
   * # ComfactConfig
   * Service in the collaboreApp.
   */

  angular.module('collaboreApp').factory('ComfactConfig', ComfactConfigModel);

  /** @ngInject */
  function ComfactConfigModel(Main) {

    function ComfactConfig(objetData) {
      Main.apply(this, arguments);
      this.hydrate(objetData);
    }

    ComfactConfig.prototype = {

      model: 'ComfactConfig',
      isComfactConfig: true,

      // portefeuille
      getPortefeuille: function(){
        return this.portefeuille;
      },
      setPortefeuille: function(id){
        this.portefeuille = id;
      },

      // dossier
      getDossier: function(){
        return this.dossier;
      },
      setDossier: function(dossier){
        this.dossier = dossier;
      },

      // dossier_ged
      getDossier_ged: function(){
        return this.dossier_ged;
      },
      setDossier_ged: function(dossier_ged){
        this.dossier_ged = dossier_ged;
      },

      // titre
      getTitre: function(){
        return this.titre;
      },
      setTitre: function(titre){
        this.titre = titre;
      },

      // type
      getType: function(){
        return this.type;
      },
      setType: function(type){
        this.type = type;
      },

      // cabinet
      getCabinet: function(){
        return this.cabinet;
      },
      setCabinet: function(cabinet){
        this.cabinet = cabinet;
      },

      isGestion: function(){
        if(this.getType() === 1) return true;
        return false;
      },

      isSyndic: function(){
        if(this.getType() === 2) return true;
        return false;
      },

      isSyndicAndGestion: function(){
        if(this.getType() === 0) return true;
        return false;
      }
    };
    angular.extend(ComfactConfig.prototype, Main.prototype);

    return ComfactConfig;

  }
})();
