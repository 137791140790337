(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.Periode
   * @description
   * # Periode
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('Periode',
      PeriodeModel
    );

  /** @ngInject */
  function PeriodeModel(Main) {

    function Periode(objetData) {
      Main.apply(this, arguments);
      this.hydrate(objetData);
    }

    Periode.prototype = {

      isModel: true,
      model: 'Periode',

      getId: function(value){
        return this.id;
      },
      setId: function(val){
        this.id = val;
      },

      getLibelle: function(value){
        return this.libelle;
      },
      setLibelle: function(val){
        this.libelle = val;
      },

      getDescription: function(value){
        return this.description;
      },
      setDescription: function(val){
        this.description = val;
      },

      getOrdre: function(value){
        return this.ordre;
      },
      setOrdre: function(val){
        this.ordre = val;
      },

      getTypePeriodCalendar: function(value){
        return this.typePeriodCalendar;
      },
      setTypePeriodCalendar: function(val){
        this.typePeriodCalendar = val;
      },

      getTypePeriodMoment: function(value){
        return this.typePeriodMoment;
      },
      setTypePeriodMoment: function(val){
        this.typePeriodMoment = val;
      },

      getValuePeriodCalendar: function(value){
        return this.valuePeriodCalendar;
      },
      setValuePeriodCalendar: function(val){
        this.valuePeriodCalendar = val;
      }
    };

    angular.extend(Periode.prototype, Main.prototype);

    return Periode;
  }
})();
