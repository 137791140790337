(function () {
  'use strict';

  /**
   * @desc Page de liste de topic
   * @example <list-topic></list-topic>
   */

  angular
    .module('collaboreApp')
    .component('listTopic', {
      templateUrl: 'app/topics/list-topic/list-topic.component.html',
      controllerAs: 'listtopicctrl',
      bindings: {
        searchByTags: '<',
        onEmit: '&'
      },
      controller: ListTopicController
    });

  /*@ngInject*/
  function ListTopicController(COLLAB_CONF, $rootScope, $scope, $state, $stateParams, $timeout, TopicStatesService, FiltreService, UtilsService, TagService, ConfigService) {

    var ctrl = this;
    ctrl.notMobile = UtilsService.notMobile;
    ctrl.$state = $state;

    ctrl.etat = $stateParams.etat;			//	Type de topics (normaux, archivés ou coup de coeur)

    // Paramètres de recherche par défault
    ctrl.search = getSearch();

    /*
    ctrl.popoverFiltre = {
        'isOpen': false,
        'toggle': function toggle() {
            this.isOpen = !this.isOpen;
        }
    };*/

    ctrl.listTagsDelete = [];
    ctrl.loadingTopicsList = true;											// Loading de la page topicList
    ctrl.topicStatesLimitPerPage = COLLAB_CONF.LIST_TOPIC_LIMIT_PAR_PAGE;	// Nombre de topic par page
    ctrl.refreshTopicList = refreshTopicList;								// Permet de refresh les topics
    ctrl.toggleDemandes = toggleDemandes;									// Affiche/Cache les demandes des topic
    ctrl.toggleCloture = toggleCloture;										// Affiche topic cloturés
    ctrl.toggleNonLu = toggleNonLu;											// Affiche topic non lu
    ctrl.cleanFiltre = cleanFiltre;											// Permet de clean le filtre
    ctrl.displayInformations = displayInformations;							// Permet d'afficher un texte d'information en haut de la liste des topics
    ctrl.removeParamFromFiltre = removeParamFromFiltre;						// Permet de retirer un paramètre du filtre
    ctrl.onEmitFromListTopicState = onEmitFromListTopicState;			    // Lors d'infos remontées depuis le composant list-topic-state

    ctrl.onChangePagination = onChangePagination;                           // Lors du changement de page

    /*
    $scope.$on('togglePopoverFiltre', function () {
        ctrl.popoverFiltre.toggle();
    });*/

    /**
     * Ecoute le changement de valeur de idChannel dans le rootscop pour regenérer les paramètres pour l'URL
     */
    $scope.$watch('$root.current.filtreTopics.idChannel', function(newVal){
      ctrl.paramsFiltreChannel = FiltreService.getObjFiltreByChannel($rootScope.current.filtreTopics.idChannel)
    });

    var listenerChangeStart = null;

    ctrl.$onInit = function () {
      init();

      initPreferenceDisplayDemandes();

      // Lors du changement d'une route pour les channel
      listenerChangeStart = $rootScope.$on('$stateChangeStart', function(event, toState, toParams){
        if(UtilsService.startsWith(toState.name, 'topics.recherche.')) {
          var filtre = FiltreService.parseFiltreFromUrl(toParams, true);
          if(_.isObject(filtre) && _.isObject(filtre.rechercheElasticsearch) && !filtre.rechercheElasticsearch.isActive) {
            event.preventDefault();
            ctrl.idChannel = filtre.idChannel;
            init();
            $state.go(toState.name, toParams, {relead: false, notify: false})
          }
        }
      });
    };

    ctrl.$onChanges = function(changes) {
      if(_.isObject(changes.searchByTags) && !changes.searchByTags.isFirstChange() && changes.searchByTags.currentValue) {
        ctrl.searchByTags = changes.searchByTags.currentValue;
        ctrl.listTagsDelete = [];
        init();
      }
    }

    ctrl.$onDestroy = function () {
      if(!_.isNil(listenerChangeStart)) {
        listenerChangeStart();
      }
    };

    function init(){
      /*
      if(_.isObject(ctrl.searchByTags) && !_.isNil(ctrl.searchByTags.idChannel))
        ctrl.idChannel = ctrl.searchByTags.idChannel;

      if(!_.isNil(ctrl.idChannel))
        $rootScope.current.filtreTopics.idChannel = ctrl.idChannel;
      */
      ctrl.idChannel = $rootScope.current.filtreTopics.idChannel


      $rootScope.current.pageTopic = $stateParams.topicPage;

      // Si le rootscope a un idChannel
      if ($rootScope.current.filtreTopics.hasOwnProperty('idChannel')) {
        // Si cet idChannel est égale à 0
        if ($rootScope.current.filtreTopics.idChannel === 0) {

          // Recupère un éventuel idChannel Accueil
          var channelAccueil = $rootScope.current.userGroupe.getChannelAccueil();

          if (channelAccueil && channelAccueil.hasGroupeChannel())
            $rootScope.current.filtreTopics.idChannel = channelAccueil.idChannel;
        }
        // Si idChannel égale à -1 (on demande de tout voir)
        else if ($rootScope.current.filtreTopics.idChannel === -1)
          $rootScope.current.filtreTopics.idChannel = 0;
      }

      /*
      var isFiltre = FiltreService.parseFiltreFromUrl($stateParams, true);
      // Si il y a un filtre
      if (isFiltre) {
          ctrl.search = getSearch();
      }*/


      ctrl.nomChannel = COLLAB_CONF.LIBELLE_CHANNEL_ALL;

      var idChannelFiltre = FiltreService.filtreIsChannel();
      if (idChannelFiltre) {
        var channel = $rootScope.current.userGroupe.getChannel(idChannelFiltre);
        if (channel) ctrl.nomChannel = channel.nom;
      }

      if(!_.isNil(ctrl.searchByTags)) rechercheByTags(ctrl.searchByTags);
    }

    function initPreferenceDisplayDemandes(){
      ctrl.customPreference = ConfigService.getPreferencesCustom();
      if(!_.isObject(ctrl.customPreference)) {
        ctrl.customPreference = {
          displayDemandesInTopicsList: true
        };
      }
      ctrl.displayDemandesInTopicsList = ctrl.customPreference.displayDemandesInTopicsList;
      ConfigService.setPreferencesCustom(ctrl.customPreference);
    }

    /**
     * Permet de setter les preférences d'affichage des demandes dans la liste des topics
     * @param val
     */
    function setPreferenceDisplayDemandes(val){
      ctrl.customPreference = ConfigService.getPreferencesCustom();
      if(!_.isObject(ctrl.customPreference)) ctrl.customPreference = {};
      ctrl.customPreference.displayDemandesInTopicsList = ctrl.displayDemandesInTopicsList = val;
      ConfigService.setPreferencesCustom(ctrl.customPreference);
    }

    function getSearch(){
      return angular.copy({
        etat: ctrl.etat,
        page: $rootScope.current.pageTopic,
        filtre: $rootScope.current.filtreTopics
      });
    }

    /**
     * Fonction exécuté par le component
     * @param obj
     */
    function onUpdateListTopicStates(obj) {
      if (angular.isObject(obj)) {
        if (obj.hasOwnProperty('nbTotalTopicStates')) {
          ctrl.nbTotalTopicStates = obj.nbTotalTopicStates;
        }

        if (obj.hasOwnProperty('loadingListTopicStates')) {
          ctrl.loadingTopicsList = obj.loadingListTopicStates;
        }
        /*
         if(obj.hasOwnProperty('descriptionFiltre')){
         console.log(obj.descriptionFiltre);
         ctrl.filtreTopics = $rootScope.current.filtreTopics = obj.descriptionFiltre.objFiltre;
         }*/
      }
    }

    /**
     * Affiche/Cache les demandes des topics
     */
    function toggleDemandes(){
      setPreferenceDisplayDemandes(!ctrl.displayDemandesInTopicsList);
      //$scope.$broadcast('customPreferenceChanged');
    }

    /**
     * Affiche tous les topic ou seulement les non cloturés
     */
    function toggleCloture() {
      if ($rootScope.current.filtreTopics.others.clotureEnable) {
        $rootScope.current.filtreTopics.others.clotureEnable = false;
        $rootScope.current.filtreTopics.withCloture = false;
        $rootScope.current.filtreTopics.withoutCloture = true;
      }
      else {
        $rootScope.current.filtreTopics.others.clotureEnable = true;
        $rootScope.current.filtreTopics.withCloture = true;
        $rootScope.current.filtreTopics.withoutCloture = true;
      }

      refreshTopicList();
    }

    /**
     * Affiche les topic non lu
     */
    function toggleNonLu() {
      if ($rootScope.current.filtreTopics.others.nonLuEnable) {
        $rootScope.current.filtreTopics.others.nonLuEnable = false;
      }
      else {
        $rootScope.current.filtreTopics.others.nonLuEnable = true;
      }

      refreshTopicList();
    }

    /**
     * Rappel la liste des topicStates
     * @param params
     */
    function refreshTopicList(params) {
      var stateParams = {},
        objFiltreTopic = $rootScope.current.filtreTopics,
        state = $state.current.name,
        searchTmp = angular.copy(ctrl.search);

      if (_.isObject(params)) {

        // Si ily a un filtre en paramètre
        if (_.isObject(params.objFiltre)) {

          if (params.channel) {

            stateParams = FiltreService.getObjFiltreByChannel(params.channel);
          }
          else {
            objFiltreTopic = FiltreService.parseFiltreToUrl(params.objFiltre);
            stateParams = objFiltreTopic.objSearch;
          }
          searchTmp.filtre = params.objFiltre;

        }

        // Si demande une route
        if (!_.isNil(params.gotToState))
          state = params.gotToState;
      }
      else {
        objFiltreTopic = FiltreService.parseFiltreToUrl($rootScope.current.filtreTopics);
        stateParams = objFiltreTopic.objSearch;
        searchTmp.filtre = angular.copy($rootScope.current.filtreTopics);
      }



      /*
      // Si filtre sur tous les topics
      if ($rootScope.current.filtreTopics.others.clotureEnable) {
          $rootScope.current.filtreTopics.cloturer = 0;
      }

      // Si filtre sur tous les topics
      if ($rootScope.current.filtreTopics.others.nonLuEnable) {
          $rootScope.current.filtreTopics.nonlu = 1;
      }
      else {
          $rootScope.current.filtreTopics.nonlu = 0;
      }*/

      stateParams.topicPage = $rootScope.current.pageTopic;
      searchTmp.page = parseInt(stateParams.topicPage);

      // Re-sette la variable pour le composant enfant detecte le changement
      ctrl.search = searchTmp;

      FiltreService.saveFiltre(searchTmp.filtre);

      // Change l'url
      $state.go(state, stateParams, {'location': true, 'reload': false, 'notify': false});
    }

    /**
     * Permet d'afficher un texte d'information en haut de la liste des topics
     * @returns {string}
     */
    function displayInformations() {
      var retour = 'Informations';
      if (UtilsService.isTopicList()) {
        /*
         // TODO : [Checkbox Topic] Décommenté si utilisation des checkbox
         if(vm.nbTopicChecked===1){
         retour = vm.nbTopicChecked+' topic selectionné';
         }else{
         if(vm.nbTopicChecked>1){
         retour = vm.nbTopicChecked+' topics selectionnés';
         }else{
         */
        retour = FiltreService.genereDescription($rootScope.current.filtreTopics);
        /*
         if($rootScope.current.filtreTopics.libelle){
         retour = $rootScope.current.filtreTopics.libelle;
         }*/
        /*
         }
         }*/
      }
      return retour;
    }

    /**
     * Permet de clean le filtre
     */
    function cleanFiltre() {
      $timeout(function () {
        FiltreService.cleanFiltre();

        ctrl.onEmit({
          obj: {
            action: 'cleanFiltre'
          }
        });
        /*
        // Filtre la liste
        refreshTopicList({'objFiltre': $rootScope.current.filtreTopics});*/
      });

    }

    function removeParamFromFiltre(objUpdate, liste) {
      if (objUpdate.hasOwnProperty('deleteTagFromContenu')) {
        var tagDel = objUpdate.deleteTagFromContenu;
        if (liste.length) {
          liste = TagService.deleteTagFromTags(liste, tagDel);
          ctrl.listTagsDelete.push(tagDel);
          // TODO: Lors du delete d'un tag dans la recherche il faut mettre à jour la liste car sinon lors de la création d'événement il y a toujours le tag et la fiche
          //$rootScope.current.tmpNewEvenement.tagsForOrigine = objetsTags;
          refreshTopicList({'objFiltre': $rootScope.current.filtreTopics});
        }
      }
    }

    /**
     * Lors du changement de page
     */
    function onChangePagination(){
      refreshTopicList({
        objFiltre: $rootScope.current.filtreTopics
      });
    }

    function rechercheByTags(objetsTags) {
      var idChannel = -1;
      if (!_.isNil(ctrl.idChannel)) idChannel = ctrl.idChannel;

      if (objetsTags.hasOwnProperty('addTag')) {
        var listeTagsSearch = [];
        var callTopicList = false;
        //$rootScope.current.filtreTopics.idChannel = idChannel;
        $rootScope.current.filtreTopics.classe = '0';
        $rootScope.current.filtreTopics.tags = [];
        $rootScope.current.filtreTopics.listeFournisseurMail = [];
        $rootScope.current.filtreTopics.listeFournisseurPersoICS = [];

        if(_.isObject($rootScope.current.filtreTopics.rechercheElasticsearch)) {
          $rootScope.current.filtreTopics.rechercheElasticsearch.isActive = false;
        }

        if (objetsTags.addTag.hasOwnProperty('tagsType')) {

          // Cherche dans les tagType
          var listeTagsTypes = TopicStatesService.getTagsTypeAllowForSearchTopic(objetsTags);
          if (listeTagsTypes.length) {
            listeTagsSearch = _.merge(listeTagsSearch, listeTagsTypes);
          }

          // Cherche dans le "tag" qui est normalement un tag origine si ICS le demande ou si pas encore de résultat dans les tag type
          if (UtilsService.isICS()) {
            var tagOrigin = TopicStatesService.getParamsSearchTopicByTagOrigin(objetsTags);
            if (tagOrigin) {
              listeTagsSearch.push(tagOrigin);
            }
          }

          // Si liste de tag trouvé alors recherche les topic avec
          if (listeTagsSearch.length) {
            //$rootScope.current.filtreTopics.tags = listeTagsSearch;
            callTopicList = true;
          }
        }

        // Si toujours pas de callTopicList à true passe par les fiches pour essayer de trouver des infos
        if (!callTopicList) {
          var objParamTopicByFiche = TopicStatesService.getParamsSearchTopicByFiches(objetsTags);
          if (objParamTopicByFiche.success === true) {
            $rootScope.current.filtreTopics.classe = '2';
            var listeFournisseurMailFound = false;
            var listeFournisseurPersoICSFound = false;
            if(ctrl.listTagsDelete.length) {
              for(var i = 0; i < ctrl.listTagsDelete.length; i++){
                if(listeFournisseurMailFound && listeFournisseurPersoICSFound) break;

                if(_.isArray(objParamTopicByFiche.listeFournisseurMail) && objParamTopicByFiche.listeFournisseurMail.length){
                  for(var j = 0; j < objParamTopicByFiche.listeFournisseurMail.length; j++){
                    if(_.eq(ctrl.listTagsDelete[i], objParamTopicByFiche.listeFournisseurMail[j])){
                      listeFournisseurMailFound = true;
                      break;
                    }
                  }
                }
                for(var k = 0; k < objParamTopicByFiche.listeFournisseurPersoICS.length; k++){
                  if(_.isEqual(ctrl.listTagsDelete[i],objParamTopicByFiche.listeFournisseurPersoICS[k])){
                    listeFournisseurPersoICSFound = true;
                    break;
                  }
                }
              }
            }
            if(!listeFournisseurMailFound) $rootScope.current.filtreTopics.listeFournisseurMail = objParamTopicByFiche.listeFournisseurMail;
            if(!listeFournisseurPersoICSFound) $rootScope.current.filtreTopics.listeFournisseurPersoICS = objParamTopicByFiche.listeFournisseurPersoICS;
            callTopicList = true;
          }
        }

        // Si toujours pas de callTopicList à true, tante la recherche avec tag origin
        if (!callTopicList) {
          var tagOrig = TopicStatesService.getParamsSearchTopicByTagOrigin(objetsTags);
          if (tagOrig) {
            listeTagsSearch.push(tagOrig);
            callTopicList = true;
          }
        }

        // Si liste de tag trouvé alors recherche les topic avec
        if (listeTagsSearch.length) {
          $rootScope.current.filtreTopics.tags = listeTagsSearch;
        }

        if (callTopicList) {
          $rootScope.current.tmpNewEvenement.tagsForOrigine = objetsTags;
          refreshTopicList({
            objFiltre: $rootScope.current.filtreTopics,
            //gotToState: 'topics.recherche',
            channel: idChannel,
            reload: false
          });

          /*
          var stateParams = FiltreService.getObjFiltreByChannel(-1);

          // Change l'url
          $state.go('topics.recherche', stateParams, {'location': true, 'reload': false, 'notify': false});*/


          /*
          FiltreService.saveFiltre($rootScope.current.filtreTopics);*/

        }

      }
    }

    /**
     * Lorsque le composant "list-topic-state" remonte une info
     */
    function onEmitFromListTopicState(obj){
      try {
        if (!obj) throw new Error('Il manque le paramètre "obj"');
        if (_.isObject(obj) ) {
          if(obj.action === 'cleanFiltre'){
            cleanFiltre();
          }
          if(obj.action === 'changePage'){
            onChangePagination();
          }
          if(obj.action === 'update'){
            if (!_.isNil(obj.nbTotalTopicStates)) {
              ctrl.nbTotalTopicStates = obj.nbTotalTopicStates;
            }

            if (!_.isNil(obj.loadingListTopicStates)) {
              ctrl.loadingTopicsList = obj.loadingListTopicStates;
            }
          }
        }
      }
      catch (err) {
        console.log('[ListTopicController.onEmitFromListTopicState] Erreur : ' + err.message);
      }
    }
  }

})();
