(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.FactureService
   * @description
   * # FactureService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('FactureService',
      FactureServiceController
    );

  /** @ngInject */
  function FactureServiceController($q, $injector, MainService, FactureAjax, ContenuEvenementFacture, ModalsService) {
    var ctrl = this;

    ctrl.createFactureInDevis = createFactureInDevis; // Créé une facture dans un devis
    ctrl.sendFactureToComfactFacture = sendFactureToComfactFacture; // Envoi la facture vers comfact facture
    ctrl.searchComfactConfigs = searchComfactConfigs; // Recupère les infos config comfact
    ctrl.searchComfactUsers = searchComfactUsers; // Recupère les infos user comfact
    ctrl.deleteFactureAutonome = deleteFactureAutonome; // Supprime une facture autonome

    function postServlet(action, obj) {
      var deferred = $q.defer();
      try {
        if (!action) throw new Error('Le paramètre "action" n\'existe pas !');

        if (!angular.isObject(obj) && !angular.isNumber(obj)) throw new Error('Le paramètre "obj" doit être un objet un un nombre !');
        if (angular.isObject(obj) && !obj.hasOwnProperty('idFacture') && !obj.hasOwnProperty('idDevis') && !obj.hasOwnProperty('idContenu')) throw new Error('Le paramètre "obj" doit avoir un idFacture, idDevis ou idContenu !');

        var sendParams = angular.merge({}, obj);
        sendParams.action = action;
        if(angular.isNumber(obj)) sendParams.idFacture = obj;
        else if(angular.isObject(obj)){
          if(obj.hasOwnProperty('idDevis')) sendParams.idDevis = obj.idDevis;
          else sendParams.idFacture = (obj.hasOwnProperty('idFacture')) ? obj.idFacture : obj.idContenu;
        }
        if(sendParams.hasOwnProperty('idContenu')) delete sendParams.idContenu;

        FactureAjax
          .post(sendParams, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, 'facture', ContenuEvenementFacture, deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    function getServlet(action, obj, attributJson, model) {
      var deferred = $q.defer();
      try {
        if (!action) throw new Error('Le paramètre "action" n\'existe pas !');
        if (!model) throw new Error('Le paramètre "model" n\'existe pas !');
        if (!attributJson) throw new Error('Le paramètre "attributJson" n\'existe pas !');

        if (!angular.isObject(obj) && !angular.isNumber(obj)) throw new Error('Le paramètre "obj" doit être un objet un un nombre !');
        if (angular.isObject(obj) && !obj.hasOwnProperty('idFacture') && !obj.hasOwnProperty('idDevis') && !obj.hasOwnProperty('idContenu')) throw new Error('Le paramètre "obj" doit avoir un idFacture, idDevis ou idContenu !');

        var sendParams = {
          action:action
        };
        if(angular.isNumber(obj)) sendParams.idFacture = obj;
        else if(angular.isObject(obj)){
          if(obj.hasOwnProperty('idDevis')) sendParams.idDevis = obj.idDevis;
          else sendParams.idFacture = (obj.hasOwnProperty('idFacture')) ? obj.idFacture : obj.idContenu;
        }

        FactureAjax
          .get(sendParams, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, attributJson, $injector.get(model), deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    function sendFactureToComfactFacture(data) {
      var deferred = $q.defer();
      postServlet('sendToComfact', data)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de créer une facture dans un devis
     * @param obj
     * @returns {IPromise<T>}
     */
    function createFactureInDevis(obj) {
      var deferred = $q.defer();
      postServlet('createFactureInDevis', obj)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de récupérer la liste des configs comfact
     * @param obj
     */
    function searchComfactConfigs(obj) {
      var deferred = $q.defer();
      getServlet('searchComfactConfigs', obj, 'listComfactConfig', 'ComfactConfig')
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de récupérer la liste des user comfact
     * @param obj
     * @param onlyComptable
     */
    function searchComfactUsers(obj, onlyComptable) {
      var deferred = $q.defer();
      getServlet('searchComfactUsers', obj, 'listComfactUser', 'ComfactUser')
        .then(function (ret) {
          var retour = [];
          if(angular.isArray(ret) && ret.length) {
            for(var i = 0; i < ret.length; i++){
              if(ret[i].getPermission() !== 0) {
                if(onlyComptable){
                  if(ret[i].getInitiales() !== "") retour.push(ret[i]);
                }
                else retour.push(ret[i]);
              }
            }
          }
          deferred.resolve(retour);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de supprimer une facture autonome
     * @param guid
     * @returns {*}
     */
    function deleteFactureAutonome(guid) {
      var deferred = $q.defer();
      try {
        if (!guid) throw new Error('Le paramètre "guid" n\'existe pas !');

        var params = {
          action: 'deleteFactureAutonome',
          guidDocumentFacture: guid
        };
        FactureAjax
          .post(params, function (json) {
            if(json.success) deferred.resolve(json);
            else deferred.resolve(json.message);
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

  }
})();
