(function () {
    'use strict';

    /**
     * @desc Block de communication
     * @example <modal-annuaire></modal-annuaire>
     */

    angular
        .module('collaboreApp')
        .component('modalAnnuaire',{
            templateUrl: 'app/annuaire/modal-annuaire.component.html',
            controllerAs: 'modalannuairectrl',
            controller: ModalAnnuaireCtrl,
            bindings: {
                modalInstance: '<',
                resolve: '<',
                close: '&',
                dismiss: '&'
            }
        });

    /*@ngInject*/
    function ModalAnnuaireCtrl($timeout) {

        var ctrl = this;

        ctrl.onClickClose = onClickClose;
        ctrl.onSubmitAnnuaire = onSubmitAnnuaire;

        ctrl.$onInit = function() {

            ctrl.paramsAnnuaire = ctrl.resolve.params;

            ctrl.limitDestinataire = getParam('limitDestinataire');
            ctrl.stringTo = getParam('stringTo');
            ctrl.createEvent = getParam('createEvent');
            ctrl.currentModelEvenement = getParam('currentModelEvenement');
            ctrl.defautTypeAnnuaire = getParam('defautTypeAnnuaire');
            ctrl.onlyFournisseur = getParam('onlyFournisseur');
            ctrl.infosEvent = getParam('infosEvent');
            ctrl.allCollaborateur = getParam('allCollaborateur');
            ctrl.openFrom = getParam('openFrom');
            ctrl.defaultMetier = getParam('defaultMetier');
            ctrl.onOpened = getParam('onOpened');

            if(ctrl.onOpened && angular.isFunction(ctrl.onOpened)) {
                ctrl.onOpened();
            }

            ctrl.modalInstance.rendered.then(function(){
                $timeout(function(){
                    ctrl.focusChampLibre = true;
                }, 500);
            });
        };

        function getParam(param){
            if(angular.isObject(ctrl.resolve) && ctrl.resolve.hasOwnProperty('params') && angular.isObject(ctrl.resolve.params)) {
                if(ctrl.resolve.params.hasOwnProperty(param) && !angular.isUndefined(ctrl.resolve.params[param])){
                    return ctrl.resolve.params[param];
                }
            }
            return null;
        }

        function onClickClose(){
            ctrl.dismiss({$value: 'cancel'});
        }

        function onSubmitAnnuaire(objAnnuaire){
            ctrl.close({$value: objAnnuaire});
        }

    }
})();
