(function () {
  'use strict';

  /**
   * @desc Collab fournisseur
   * @example <collab-fournisseur></collab-fournisseur>
   */

  angular
    .module('collaboreApp')
    .component('collabFournisseur', {
      templateUrl: 'app/collab-fournisseur/collab-fournisseur.component.html',
      controllerAs: 'collabfournisseurctrl',
      controller: CollabFournisseurController
    });

  /*@ngInject*/
  function CollabFournisseurController(COLLAB_CONF, COLLAB_VALUES) {

    var _this = this;
    _this.loadingComponent = false;

    _this.$onInit = function () {
    };

    _this.$onChanges = function () {
    };

    _this.$onDestroy = function(){
    };

    function init(){

    }
  }
})();
