(function () {
	'use strict';

	angular
		.module('collaboreApp')
		.factory('Resultat', ResultatModel);

	/** @ngInject */
	function ResultatModel(Main) {

		function Resultat(objetData) {
            Main.apply(this, arguments);
            this.hydrate(objetData);
		}

        Resultat.prototype = {
            model: 'Resultat',
            isResultat: true,

			getSuccess: function(){
            	return this.success;
			},
			setSuccess: function(val) {
				this.success = val;
			},

            getEvenement: function(){
                return this.evenement;
            },
            setEvenement: function(val){
                this.evenement = val;
            }

		};

        angular.extend(Resultat.prototype, Main.prototype);
		return Resultat;
	}
})();