(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.TopicStates
   * @description
   * # TopicStates
   * Service in the collaboreApp.
   */

  angular.module('collaboreApp').factory('TopicStates', TopicStatesModel);

  /** @ngInject */
  function TopicStatesModel(Main, $q, TopicResource, State, Topic) {

    function TopicStates(objetData) {
      // Si l'instance actuel contien 'previsualisationMsg' alors on le supprime
      if(this.hasOwnProperty('previsualisationMsg')){
        delete this.previsualisationMsg;
      }

      if (objetData) {
        Main.call(this, objetData);
        //this.setData(objetData);
      }
      else this.setState(new State(State.NORMAL));

      if(_.isObject(this.getTopic())) this.getTopic().state = this.getState();
    }

    TopicStates.prototype = {

      isModel: true,
      isTopicStates: true,

      setData: function(objetData) {

        // Si l'instance actuel contien 'previsualisationMsg' alors on le supprime
        if(this.hasOwnProperty('previsualisationMsg')){
          delete this.previsualisationMsg;
        }
        angular.extend(this, objetData);

        if(objetData.state){
          this.setState(new State(objetData.state));
        }

        if(objetData.topic){
          /*
           var topic = objetData.topic;
           //if(!(objetData.topic instanceof Topic)){
           if(!objetData.topic.isModel){
           topic = new Topic(objetData.topic);
           }*/
          this.setTopic(objetData.topic);
          this.getTopic().state = this.getState();
        }

      },

      getIdTopicState: function(){
        return this.idTopicState;
      },
      setIdTopicState: function(id){
        this.idTopicState = id;
      },

      getState: function(){
        return this.state;
      },
      setState: function(state){
        if(_.isObject(state) && !state.isModel){
          state = new State(state);
        }
        this.state = state;
      },

      getTopic: function(){
        return this.topic;
      },
      setTopic: function(topic){
        //if(!(objetData.topic instanceof Topic)){
        if(_.isObject(topic) && !topic.isModel){
          topic = new Topic(topic);
        }
        this.topic = topic;
      },

      /**
       * Permet de savoir si le topic est normal (pas coup de coeur, pas caché pas archivé..)
       * @param {void}
       * @returns {Bool}
       */
      isNormal: function(){
        if(_.isObject(this.getState())) return this.getState().isNormal();
        return false;
      },

      /**
       * Permet de savoir si le topic est archivé
       * @param {void}
       * @returns {Bool}
       */
      isArchive: function(){
        if(_.isObject(this.getState())) return this.getState().isArchive();
        return false;
      },

      /**
       *	Permet de savoir si le topic est un coup de coeur
       * 	@param {void}
       * 	@returns {Bool}
       */
      isCoupCoeur: function(){
        if(_.isObject(this.getState())) return this.getState().isCoupCoeur();
        return false;
      },

      /**
       * Permet de savoir si le topic est caché
       * @param {void}
       * @returns {Bool}
       */
      isCache: function(){
        if(_.isObject(this.getState())) return this.getState().isCache();
        return false;
      },

      /**
       * Permet de savoir si le topic est caché ou archivé
       * @param {void}
       * @returns {Bool}
       */
      isArchiveOrCache: function(){
        if(_.isObject(this.getState())) return this.getState().isArchiveOrCache();
        return false;
      },

      toggleAddRemoveToCoupCoeur: function(){
        var deferred = $q.defer();
        var idNom = 1; // Coup de coeur
        // Si déjà coup de coeur
        if(this.getState().isCoupCoeur()){
          idNom = 5; // Normal
        }
        this.updateIdNom(idNom).then(function(ts){
          deferred.resolve(ts);
        },function(msg){
          deferred.reject(msg);
        });
        return deferred.promise;
      },

      toggleAddRemoveToArchive: function(){
        var deferred = $q.defer();
        var idNom = 2; // Archive
        // Si déjà archive
        if(this.getState().isArchive()){
          idNom = 5; // Normal
        }
        this.updateIdNom(idNom).then(function(ts){
          // Si en archive après modification
          if(ts.getState().isArchive()){
            // Lecture du topic
            ts.getTopic().lire();
          }
          deferred.resolve(ts);
        },function(msg){
          deferred.reject(msg);
        });
        return deferred.promise;
      },

      toggleAddRemoveToCache: function(){
        var deferred = $q.defer();
        var idNom = 3; // Caché
        // Si déjà archive
        if(this.getState().isCache()){
          idNom = 5; // Normal
        }
        this.updateIdNom(idNom).then(function(ts){
          deferred.resolve(ts);
        },function(msg){
          deferred.reject(msg);
        });
        return deferred.promise;
      },

      /**
       * Change l'état d'un topic
       * @param {Object} objParams - Objet des paramètres à envoyer en post
       * @returns {Promesse}
       */
      updateIdNom: function(idNom){
        var scope = this;

        var deferred = $q.defer();

        var topicAjax = new TopicResource();
        topicAjax.idTopicState = scope.getIdTopicState();
        topicAjax.idNom = idNom;
        topicAjax.$updateState(function(json){
          var retour = false;
          if(json.nb!==0){

            if(json.TopicStates[0]) {
              // Met à jour l'idnom du state
              scope.getState().setIdNom(json.TopicStates[0].state.idNom);
              //scope.setState(new State(json.TopicStates[0].state));
              retour = scope;
            }
          }

          deferred.resolve(retour);
        },function(error){
          var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
          deferred.reject('Problème avec TopicStates.updateIdNom(), '+msgErreur);
        });
        return deferred.promise;
      }

    };

    angular.extend(TopicStates.prototype, Main.prototype);

    return TopicStates;

  }
})();
