(function() {
    'use strict';
    angular
        .module('collaboreApp')
        .controller('ModalFicheCtrl', ModalFicheCtrl);

    /*@ngInject*/
    function ModalFicheCtrl($uibModalInstance, params) {
        var ctrl = this;

        ctrl.groupe = false;
        ctrl.allowAddGroupeToDestinataire = false;
        ctrl.allowAddUserToDestinataire = false;

        if(params){
            if(params.hasOwnProperty('groupe')) {
                ctrl.groupe = params.groupe;
            }
            if(params.hasOwnProperty('allowAddGroupeToDestinataire')) {
                ctrl.allowAddGroupeToDestinataire = params.allowAddGroupeToDestinataire;
            }
            if(params.hasOwnProperty('allowAddUserToDestinataire')) {
                ctrl.allowAddUserToDestinataire = params.allowAddUserToDestinataire;
            }
        }

        /*
        ctrl.onUpdateGroupe = onUpdateGroupe;

        function onUpdateGroupe(groupe) {

        }*/

        //$uibModalInstance.close(objAnnuaire);
        /**
         * Ferme le modal
         */
        ctrl.close = function () {
            $uibModalInstance.dismiss('cancel');
        };

        $uibModalInstance.rendered.then(function(){

        });
    }

})();