'use strict';

/**
 * @ngdoc service
 * @name collaboreApp.ConfigService
 * @description
 * # ConfigService
 * Service in the collaboreApp.
 */
angular.module('collaboreApp').service('ConfigService', ['COLLAB_CONF','localStorageService', function(COLLAB_CONF,localStorageService) {

  var scope = this;
  var crypt = false;
  var keyCrypt= COLLAB_CONF.KEY_CRYPT_SEARCH;
  // Nom de la cle des informations de collabore dans le localStorage
  var key_localstorage = 'collabore-infos';
  var key_localstorage_save_contenu = 'collabore-save-contenus';
  /*
   Exemple :
   {
   user: {
   diese: 'ICS',
   token: '991EB147-27E8-42D2-8A4A-A0F7A9A2F171'
   },
   preferences: {
   typeRecherche: 'tags'
   }
   }
   */

  /******************* Informations ******************/
  /**
   * Recupère l'object JSON de configuration de Collabore dans le localStorage
   * @returns {*}
   */
  scope.getInfos = function(){
    var infosCollabore = localStorageService.get(key_localstorage);
    if(!infosCollabore){
      infosCollabore = {
        user:{},
        preferences:{}
      };
    }else{

      // Si string JSON
      if(angular.isString(infosCollabore)) {
        var pattJson = new RegExp('({"user":)','g');
        if(pattJson.test(infosCollabore)){
          infosCollabore = angular.fromJson(infosCollabore);
        }else{

          var conf;
          if(crypt){
            conf = CryptoJS.AES.decrypt(infosCollabore,keyCrypt);
            infosCollabore = angular.fromJson(conf.toString(CryptoJS.enc.Utf8));
          }else{
            var patt = new RegExp('({)','g');
            if(!patt.test(infosCollabore)){
              conf = CryptoJS.AES.decrypt(infosCollabore,keyCrypt);
              infosCollabore = conf.toString(CryptoJS.enc.Utf8);
            }
            infosCollabore = angular.fromJson(infosCollabore);
          }
        }
      }
    }
    return infosCollabore;
  };

  /**
   * Enregistre les configuration de collabore dans le localStorage
   * @param conf
   */
  scope.setInfos = function(conf){

    // Si il y a "listeContenuSave" dans la conf c'est l'ancienn conf
    if(conf.hasOwnProperty('listeContenuSave')){
      // Sauvegarde les contenus save dans une variable localstorage à part
      scope.setContenusSave(conf.listeContenuSave);
      // Delete cet objet de la conf
      delete conf.listeContenuSave;
    }

    var confObj,confString;
    if(crypt){
      confObj = CryptoJS.AES.encrypt(angular.toJson(conf),keyCrypt);
      confString = confObj.toString();
    }else{
      confString = angular.toJson(conf);
    }

    localStorageService.set(key_localstorage,confString);
    //localStorageService.set(key_localstorage,angular.toJson(conf));
    //localStorageService.set(key_localstorage,'');
  };

  /*
   scope.updateInfos = function(conf){

   };*/

  /******************* Informations User ******************/

  /**
   * Retourne l'object user de conf
   * @returns {boolean|object}
   */
  scope.getUser = function(key){
    var retour = {};
    var objInfos = scope.getInfos();

    if(objInfos.hasOwnProperty('user')) {
      // Si demande d'une clé spécial dans l'user
      if (key) {
        // Si la clé est bien dans l'objet user
        if (objInfos.user.hasOwnProperty(key)) {
          retour = objInfos.user[key];
        }
        // Sinon retourne l'objet user
      } else {
        retour = objInfos.user;
      }
    }else{
      objInfos.user = {};
      retour = objInfos.user;
    }

    return retour;
  };

  /**
   * Update l'objet user
   * @param objUser
   */
  scope.updateUser = function(objUser){
    var newUser = objUser;

    // Parcour l'objet User en paramètre
    angular.forEach(objUser, function (valNew, keyNew) {
      newUser[keyNew] = valNew;
    });

    var objInfos = scope.getInfos();
    objInfos.user = newUser;

    scope.setInfos(objInfos);
  };

  //---------------

  /**
   * Retourne le login de l'user stocké dans le localStorage
   * @returns {boolean|string}
   */
  scope.getUserLogin = function(){
    var retour = '';
    var objUser = scope.getUser();

    // Si la clé 'login' est bien dans l'objet user
    if(objUser.hasOwnProperty('login')) {
      retour = objUser.login;
    }
    return retour;
  };

  /**
   * Sette le login
   * @param valLogin
   */
  scope.setUserLogin = function(valLogin) {
    var objUser = scope.getUser();
    objUser.login = valLogin;
    scope.updateUser(objUser);
  };

  /**
   * Retourne le pass de l'user stocké dans le localStorage
   * @returns {boolean|string}
   */
  scope.getUserPass = function(){
    var retour = '';
    var objUser = scope.getUser();

    // Si la clé 'pass' est bien dans l'objet user
    if(objUser.hasOwnProperty('pass')) {
      if(angular.isString(objUser.pass) && objUser.pass!=='false' && objUser.pass!==false){
        var pass = CryptoJS.AES.decrypt(objUser.pass,'CoLlaBIsFun');
        retour = pass.toString(CryptoJS.enc.Utf8);
      }
    }
    return retour;
  };

  /**
   * Sette le login
   * @param valPass
   */
  scope.setUserPass = function(valPass) {
    var objUser = scope.getUser();
    var hash = valPass;
    if(valPass!==false){
      hash = CryptoJS.AES.encrypt(valPass,'CoLlaBIsFun');
      hash = hash.toString();
    }
    objUser.pass = hash;
    scope.updateUser(objUser);
  };

  /**
   * Retourne le diese de l'user stocké dans le localStorage
   * @returns {boolean|string}
   */
  scope.getUserDiese = function(){
    var retour = '';
    var objUser = scope.getUser();

    // Si la clé 'diese' est bien dans l'objet user
    if(objUser.hasOwnProperty('diese')) {
      retour = objUser.diese;
    }
    return retour;
  };

  /**
   * Sette le diese
   * @param valDiese
   */
  scope.setUserDiese = function(valDiese) {
    var objUser = scope.getUser();
    objUser.diese = valDiese;
    scope.updateUser(objUser);
  };

  /**
   * Retourne le token de l'user stocké dans le localStorage
   * @returns {boolean|string}
   */
  scope.getUserToken = function(){
    var retour = false;
    var objUser = scope.getUser();

    // Si la clé 'token' est bien dans l'objet user
    if(objUser.hasOwnProperty('token')) {
      retour = objUser.token;
    }
    return retour;
  };

  /**
   * Enregistre le token dans l'objet user
   * @param valToken
   */
  scope.setUserToken = function(valToken) {
    var objUser = scope.getUser();
    objUser.token = valToken;
    scope.updateUser(objUser);
  };

  /******************* Informations Préférences ******************/
  /**
   * Retourne l'objet preferences
   * @param key
   * @returns {boolean}
   */
  scope.getPreferences = function(key) {
    var retour = {};
    var objInfos = scope.getInfos();
    if (objInfos.hasOwnProperty('preferences')) {
      // Si demande d'une clé spécial dans les preferences
      if (key) {
        // Si la clé est bien dans l'objet user
        if (objInfos.preferences.hasOwnProperty(key)) {
          retour = objInfos.preferences[key];
        }
        // Sinon retourne l'objet preferences
      } else {
        retour = objInfos.preferences;
      }
    }else{
      objInfos.preferences = {};
      retour = objInfos.preferences;
    }
    return retour;
  };

  /**
   * Update l'objet preferences avant de save dans le localstorage
   * @param objPreferences
   */
  scope.updatePreferences = function(objPreferences){
    var newPref = objPreferences;

    // Parcour l'objet objPreferences en paramètre
    angular.forEach(objPreferences, function (valNew, keyNew) {
      newPref[keyNew] = valNew;
    });

    var objInfos = scope.getInfos();
    objInfos.preferences = newPref;

    scope.setInfos(objInfos);
  };

  //---------------

  /**
   * Retourne l'objet custom des préférences
   */
  scope.getPreferencesCustom = function(){
    var retour = false;
    var objPreferences = scope.getPreferences();

    // Si la clé 'custom' est bien dans l'objet preferences
    if(objPreferences.hasOwnProperty('custom')) {
      retour = objPreferences.custom;
    }
    return retour;
  };

  /**
   * Enregistre l'objet custom dans les préférences
   * @param valTypeRecherche
   */
  scope.setPreferencesCustom = function(valCustom) {
    var objPreferences = scope.getPreferences();
    objPreferences.custom = valCustom;
    scope.updatePreferences(objPreferences);
  };

  //*********** Topics **********/

    // ------- FiltreTopics -----
    // Objet pour configurer le filtre de la liste des topics
    // ---------------------------

    /**
     * Retourne l'objet pour configurer le filtre de la liste des topics
     */
    scope.getPreferencesFiltreTopics = function(){
      var retour = false;
      var objPreferences = scope.getPreferences();

      // Si la clé 'filtreTopics' est bien dans l'objet preferences
      if(objPreferences.hasOwnProperty('filtreTopics')) {

        if(angular.isString(objPreferences.filtreTopics)){
          var parsedWordArray = CryptoJS.enc.Base64.parse(objPreferences.filtreTopics);
          var stringSearch = parsedWordArray.toString(CryptoJS.enc.Utf8);
          retour = angular.fromJson(stringSearch);
        }else{
          retour = objPreferences.filtreTopics;
        }
      }
      return retour;
    };

    /**
     * Enregistre le filtre de recherche des topics dans les préférences
     * @param valFiltreTopics
     */
    scope.setPreferencesFiltreTopics = function(valFiltreTopics) {
      var objPreferences = scope.getPreferences();
      var wordArray = CryptoJS.enc.Utf8.parse(angular.toJson(valFiltreTopics));
      var base64 = CryptoJS.enc.Base64.stringify(wordArray);
      objPreferences.filtreTopics = base64;
      //objPreferences.filtreTopics = valFiltreTopics;
      scope.updatePreferences(objPreferences);
    };

  //*********** Documents **********/

    // ------- ModeRecherche -----
    // Recherche par nom ou par tags
    // ---------------------------

    /**
     * Retourne de modeRecherche des documents des préférences du l'utilisateur
     */
    scope.getPreferencesModeRecherche = function(){
      var retour = false;
      var objPreferences = scope.getPreferences();

      // Si la clé 'typeRecherche' est bien dans l'objet preferences
      if(objPreferences.hasOwnProperty('modeRecherche')) {
        retour = objPreferences.modeRecherche;
      }
      return retour;
    };

    /**
     * Enregistre le mode de recherche des documents dans les préférences
     * @param valTypeRecherche
     */
    scope.setPreferencesModeRecherche = function(valModeRecherche) {
      var objPreferences = scope.getPreferences();
      objPreferences.modeRecherche = valModeRecherche;
      scope.updatePreferences(objPreferences);
    };

    // ------- TypeRecherche -----
    // Recherche dans les doc de la societe ou mes doc
    // ---------------------------

    /**
     * Retourne de typeRecherche des documents des préférences du l'utilisateur
     * [DOCUMENTS]
     */
    scope.getPreferencesTypeRecherche = function(){
      var retour = false;
      var objPreferences = scope.getPreferences();

      // Si la clé 'typeRecherche' est bien dans l'objet preferences
      if(objPreferences.hasOwnProperty('typeRecherche')) {
        retour = objPreferences.typeRecherche;
      }
      return retour;
    };

    /**
     * Enregistre le type de recherche dans les préférences
     * @param valTypeRecherche
     */
    scope.setPreferencesTypeRecherche = function(valTypeRecherche) {
      var objPreferences = scope.getPreferences();
      objPreferences.typeRecherche = valTypeRecherche;
      scope.updatePreferences(objPreferences);
    };

    // ------- ModeDisplayDocuments -----
    // Liste en mode bloc ou list
    // ---------------------------

    /**
     * Retourne le mode de d'affichage des documents des préférences du l'utilisateur
     */
    scope.getPreferencesModeDisplayDocuments = function(){
      var retour = false;
      var objPreferences = scope.getPreferences();

      // Si la clé 'modeDisplayDocuments' est bien dans l'objet preferences
      if(objPreferences.hasOwnProperty('modeDisplayDocuments')) {
        retour = objPreferences.modeDisplayDocuments;
      }
      return retour;
    };

    /**
     * Enregistre le mode de display des documents dans les préférences
     * @param valTypeRecherche
     */
    scope.setPreferencesModeDisplayDocuments = function(valModeDisplay) {
      var objPreferences = scope.getPreferences();
      objPreferences.modeDisplayDocuments = valModeDisplay;
      scope.updatePreferences(objPreferences);
    };



    /**
     * Retourne le mode de d'affichage de la sidebar
     */
    scope.getPreferencesModeDisplayMiniSideBar = function(){
      var retour = false;
      var objPreferences = scope.getPreferences();

      // Si la clé 'modeDisplayMiniSidebar' est bien dans l'objet preferences
      if(objPreferences.hasOwnProperty('modeDisplayMiniSidebar')) {
        retour = objPreferences.modeDisplayMiniSidebar;
      }
      return retour;
    };

    /**
     * Enregistre le mode de display des documents dans les préférences
     * @param valTypeRecherche
     */
    scope.setPreferencesModeDisplayMiniSideBar = function(valModeDisplay) {
      var objPreferences = scope.getPreferences();
      objPreferences.modeDisplayMiniSidebar = valModeDisplay;
      scope.updatePreferences(objPreferences);
    };

    // ------- AnnuaireSourcesChecked -----
    // Les portefeuilles cochés dans l'annuaire
    // ------------------------------------------

    /**
     * Retourne les sources cochés dans l'annuaire
     */
    scope.getPreferencesAnnuaireSourcesChecked = function(){
      var retour = false;
      var objPreferences = scope.getPreferences();

      // Si la clé 'annuaireSourcesChecked' est bien dans l'objet preferences
      if(objPreferences.hasOwnProperty('annuaireSourcesChecked')) {
        retour = objPreferences.annuaireSourcesChecked;
      }
      return retour;
    };

  /**
   * Enregistre les portefeuilles cochés dans l'annuaire
   * @param valPreferencesAnnuaireSourcesChecked
   */
    scope.setPreferencesAnnuaireSourcesChecked = function(valPreferencesAnnuaireSourcesChecked) {
      var objPreferences = scope.getPreferences();
      objPreferences.annuaireSourcesChecked = valPreferencesAnnuaireSourcesChecked;
      scope.updatePreferences(objPreferences);
    };

    /**
     * Retourne le filtre actif pour le tableau de devis du Dashboard
     */
    scope.getFiltresTableauDevisDashboard = function(){
      var retour = false;
      var objPreferences = scope.getPreferences();

      // Si la clé 'filtresTableauDevisDashboard' est bien dans l'objet preferences
      if(objPreferences.hasOwnProperty('filtresTableauDevisDashboard')) {
        retour = objPreferences.filtresTableauDevisDashboard;
      }
      return retour;
    };

  /**
   * Enregistre le filtre actif pour le tableau de devis du Dashboard
   * @param filtres
   */
    scope.setFiltresTableauDevisDashboard = function(filtres) {
      var objPreferences = scope.getPreferences();
      objPreferences.filtresTableauDevisDashboard = filtres;
      scope.updatePreferences(objPreferences);
    };

    /**
     * Retourne les filtres actifs pour le graphique du Dashboard
     */
    scope.getFiltresGraphiqueDashboard = function(){
      var retour = false;
      var objPreferences = scope.getPreferences();

      // Si la clé 'filtresGraphiqueDashboard' est bien dans l'objet preferences
      if(objPreferences.hasOwnProperty('filtresGraphiqueDashboard')) {
        retour = objPreferences.filtresGraphiqueDashboard;
      }
      return retour;
    };

  /**
   * Enregistre les filtres actifs pour le graphique du Dashboard
   * @param filtres
   */
    scope.setFiltresGraphiqueDashboard = function(filtres) {
      var objPreferences = scope.getPreferences();
      objPreferences.filtresGraphiqueDashboard = filtres;
      scope.updatePreferences(objPreferences);
    };

  /******************* Informations listeContenuSave ******************/
  /**
   * Recupère l'object JSON des contenus sauvegardés de Collabore dans le localStorage
   * @returns {*}
   */
  scope.getContenusSave = function(){
    var contenusCollabore = localStorageService.get(key_localstorage_save_contenu);
    if(!contenusCollabore){
      contenusCollabore = {};
    }else{
      // Si string JSON
      if(angular.isString(contenusCollabore)) {
        var conf;
        if(crypt){
          conf = CryptoJS.AES.decrypt(contenusCollabore,keyCrypt);
          contenusCollabore = angular.fromJson(conf.toString(CryptoJS.enc.Utf8));
        }else{

          var pattJson = new RegExp('({)','g');
          if(!pattJson.test(contenusCollabore)){
            conf = CryptoJS.AES.decrypt(contenusCollabore,keyCrypt);
            contenusCollabore = conf.toString(CryptoJS.enc.Utf8);
          }
          contenusCollabore = angular.fromJson(contenusCollabore);
        }
      }
    }
    return contenusCollabore;
  };

  /**
   * Enregistre les contenus sauvegardés automatiqument de collabore, dans le localStorage
   * @param conf
   */
  scope.setContenusSave = function(conf){
    var confObj,confString;
    if(crypt){
      confObj = CryptoJS.AES.encrypt(angular.toJson(conf),keyCrypt);
      confString = confObj.toString();
    }else{
      confString = angular.toJson(conf);
    }
    localStorageService.set(key_localstorage_save_contenu,confString);
  };

  /**
   * Retourne l'objet listeContenuSave
   * @param key
   * @returns {boolean}
   */
  scope.getListeContenuSave = function(idContenu){
    var retour = false;
    var objContenus = scope.getContenusSave();
    var objContenuSave;

    // Si c'est un tableau on le converti en object
    if(angular.isArray(objContenus)){
      objContenus = {};
      // Save dans le localStorage la conversion du tableau en objet
      scope.setContenusSave(objContenus);
    }

    if(angular.isString(objContenus)){
      objContenuSave = angular.fromJson(objContenus);
    }else if(angular.isObject(objContenus)){
      objContenuSave = objContenus;
    }

    // Si demande d'un contenu special
    if(idContenu){

      // Si la clé est bien dans l'objet
      if(objContenuSave.hasOwnProperty(idContenu)) {
        retour = objContenuSave[idContenu];
      }

      // Sinon retourne l'objet
    }else{
      retour = objContenuSave;
    }

    return retour;
  };

  scope.updateListeContenuSave = function(id,objet) {

    var objContenu = {};
    var data;
    var newListeContenuSave = scope.getListeContenuSave();

    //var objContenu = newListeContenuSave[id];

    if(angular.isObject(newListeContenuSave)){
      // Si il y a un objet pour l'id du contenu
      if(newListeContenuSave.hasOwnProperty(id)){
        if(angular.isString(newListeContenuSave[id])){
          objContenu = angular.fromJson(newListeContenuSave[id]);
        }else if(angular.isObject(newListeContenuSave[id])){
          objContenu = newListeContenuSave[id];
        }
      }
    }

    if(objet.hasOwnProperty('reponse')){
      if(objet.reponse.hasOwnProperty('obj')){
        data = objet.reponse.obj;
      }else{
        data = objet.reponse;
      }
      objContenu.reponse = {
        'obj': data,
        'date': new Date()
      };
    }else if(objet.hasOwnProperty('modification')){

      if(objet.modification.hasOwnProperty('obj')){
        data = objet.modification.obj;
      }else{
        data = objet.modification;
      }
      objContenu.modification = {
        'obj': data,
        'date': new Date()
      };
    }else if(objet.hasOwnProperty('nouveau')){

      if(objet.nouveau.hasOwnProperty('obj')){
        data = objet.nouveau.obj;
      }else{
        data = objet.nouveau;
      }
      objContenu.nouveau = {
        'obj': data,
        'date': new Date()
      };

      // Si l'objet contient aussi un attribut "reponse" alors il faut le supprimer car c'est l'ancien objet pour les nouveau topic
      if(objContenu.hasOwnProperty('reponse')){
        delete objContenu.reponse;
      }
    }

    var objInfos = scope.getContenusSave();
    //console.log(objInfos);
    objInfos[id] = objContenu;
    //console.log(objInfos);

    scope.setContenusSave(objInfos);
  };

  scope.deleteListeContenuSave = function(id,isNouveau) {


    var objContenuSave = scope.getContenusSave();
    var objContenu;

    if (objContenuSave[id]) {

      if(angular.isString(objContenuSave[id])){
        objContenu = angular.fromJson(objContenuSave[id]);
      }else if(angular.isObject(objContenuSave[id])){
        objContenu = objContenuSave[id];
      }

      // Si nouveau contenu alors supression de la reponse
      if(isNouveau){
        if(objContenu.hasOwnProperty('reponse')){
          delete objContenu.reponse;
        }
        if(objContenu.hasOwnProperty('nouveau')){
          delete objContenu.nouveau;
        }
        // Sinon supression de la modification
      }else{
        if(objContenu.hasOwnProperty('modification')){
          delete objContenu.modification;
        }
      }

      // Si plus rien à l'index de l'id alors supprime l'index
      if(!objContenu.hasOwnProperty('nouveau') && !objContenu.hasOwnProperty('modification') && !objContenu.hasOwnProperty('reponse')){
        delete objContenuSave[id];
      }else{
        objContenuSave[id] = objContenu;
      }
    }
    scope.setContenusSave(objContenuSave);
    /*
    console.log("ConfigService.deleteListeContenuSave");
    console.log(id);
    console.log(contenu);
    console.log(objInfos);
    console.log(scope.getInfos());
    console.log("-----------");
    */
  };

  /**
   * Permet de clean le localstorage pour la liste des contenu save
   */
  scope.cleanListeContenuSave = function(){

    var nbJour = COLLAB_CONF.NB_JOURS_MAX_CONTENUS_SAVE_BEFORE_DELETE;
    var today = new Date();
    var todayMoinsNbJourBeforeDelete = new Date(today);
    todayMoinsNbJourBeforeDelete.setDate(today.getDate()-nbJour);

    //console.log('today',today);
    //console.log('todayMoinsNbJourBeforeDelete',todayMoinsNbJourBeforeDelete);

    var liste = scope.getListeContenuSave();
    angular.forEach(liste,function(contenu,idContenu){

      var currentDate;

      if(contenu.hasOwnProperty('reponse')){
        if(contenu.reponse.hasOwnProperty('date')){
          currentDate = new Date(contenu.reponse.date);
          if(currentDate<todayMoinsNbJourBeforeDelete){
            delete contenu.reponse;
          }
        }else{
          delete contenu.reponse;
        }
      }

      if(contenu.hasOwnProperty('modification')){
        if(contenu.modification.hasOwnProperty('date')){
          currentDate = new Date(contenu.modification.date);
          if(currentDate<todayMoinsNbJourBeforeDelete){
            delete contenu.modification;
          }
        }else{
          delete contenu.modification;
        }
      }

      if(contenu.hasOwnProperty('nouveau')){
        if(contenu.nouveau.hasOwnProperty('date')){
          currentDate = new Date(contenu.nouveau.date);
          if(currentDate<todayMoinsNbJourBeforeDelete){
            delete contenu.nouveau;
          }
        }else{
          delete contenu.nouveau;
        }
      }

      // Si plus rien à l'index de l'id alors supprime l'index
      if(!contenu.hasOwnProperty('nouveau') && !contenu.hasOwnProperty('modification') && !contenu.hasOwnProperty('reponse')){
        delete liste[idContenu];
      }else{
        liste[idContenu] = contenu;
      }

    });

    scope.setContenusSave(liste);
  };

  scope.cleanListeContenuSave();

  //---------------
}]);
