(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.Parametrage
   * @description
   * # User
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('ParametrageComfact',
      ParametrageComfactModel
    );

  /** @ngInject */
  function ParametrageComfactModel(Main) {

    function ParametrageComfact(objetData) {
      if (angular.isObject(objetData)) {
        Main.apply(this, arguments);
        this.hydrate(objetData);
      }
    }

    ParametrageComfact.prototype = {

      isModel: true,
      model: 'ParametrageComfact',

      // Id du groupe
      getIdGroupe: function(){
        return this.getDefaultValue('idGroupe');
      },
      setIdGroupe: function(id){
        this.setDefaultValue('idGroupe', id);
      },

      // priority
      getPriority: function(){
        return this.getDefaultValue('priority');
      },
      setPriority: function(val){
        this.setDefaultValue('priority', val);
      },

      // allowSendToComfactfacture
      getAllowSendToComfactfacture: function(){
        return this.getDefaultValue('allowSendToComfactfacture');
      },
      setAllowSendToComfactfacture: function(id){
        this.setDefaultValue('allowSendToComfactfacture', id);
      },

      // facturesAutonomesEnabled
      getFacturesAutonomesEnabled: function(){
        return this.getDefaultValue('facturesAutonomesEnabled');
      },
      setFacturesAutonomesEnabled: function(val){
        this.setDefaultValue('facturesAutonomesEnabled', val);
      },

      // ocrEnabled
      getOcrEnabled: function(){
        return this.getDefaultValue('ocrEnabled');
      },
      setOcrEnabled: function(val){
        this.setDefaultValue('ocrEnabled', val);
      },

      // saisieComptesSCOEnabled
      getSaisieComptesSCOEnabled: function(){
        return this.getDefaultValue('saisieComptesSCOEnabled');
      },
      setSaisieComptesSCOEnabled: function(val){
        this.setDefaultValue('saisieComptesSCOEnabled', val);
      },

      // choiceAccountantRequired
      getChoiceAccountantRequired: function(){
        return this.getDefaultValue('choiceAccountantRequired');
      },
      setChoiceAccountantRequired: function(val){
        this.setDefaultValue('choiceAccountantRequired', val);
      },

      /********* Proxy ************/

      getGroupeAllValue: function(key){
        var retour = this[key];
        if(angular.isObject(this[key]) && !angular.isArray(this[key]) && this[key].hasOwnProperty('valGroupeAll')) {
          retour = this[key].valGroupeAll;
        }
        return retour;
      },

      getDefaultValue: function(key){
        var retour = this[key];
        if(angular.isObject(this[key]) && !angular.isArray(this[key]) && this[key].hasOwnProperty('valDefault')) {
          retour = this[key].valDefault;
        }
        return retour;
      },
      setDefaultValue: function(key, val){
        this[key] = val;
      },
    };

    angular.extend(ParametrageComfact.prototype, Main.prototype);

    return ParametrageComfact;
  }
})();
