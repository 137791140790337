(function () {
  'use strict';

  /**
   * @desc Gestion des associations
   * @example <gestion-associations-type></gestion-associations-type>
   */

  angular
    .module('collaboreApp')
    .component('gestionAssociationsType', {
      templateUrl: 'app/parametrage/gestion-associations/gestion-associations-type.component.html',
      bindings: {
        type: '@', // immeuble ou coproprietaire
        portefeuille: '<',
        adminVersion: '<',

        onEmit: '&',

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<'
      },
      controllerAs: 'gestionassocctypectrl',
      controller: GestionAssociationsTypeController
    });

  /*@ngInject*/
  function GestionAssociationsTypeController(COLLAB_CONF, COLLAB_VALUES, $timeout, $q, GroupeService, GroupeAssociationService, UtilsService, ModalsService, ErreurCollabService) {

    var _this = this;
    _this.loadingComponent = false;
    _this.errorComponent = null;

    var options_modal = {
      size: 'lg',
      windowClass: 'modal-collabore',
      backdrop: 'static'
    };

    _this.allowedLimit = [10,20,50,100];

    _this.paramsRecherche = {
      filtreActif: false,
      search: null,
      searchNumeroImmeuble: null,
      onlyWithGroupeAssociation: false,
      currentPage: 1,
      offset: 0,
      limit: 10,
      order: "numero",
      sens: "asc",
      withGroupeAssociation: true,
      withAllCollaborateur: false
    };

    _this.myGroupe = UtilsService.getCurrentUser();
    _this.listGroupeParent = [];

    _this.searchGroupes = searchGroupes;                                    // Permet de rechercher les groupes

    _this.onClickClose = onClickClose;                                      // Si composant utilisé en modal alors ferme la modal
    _this.onKeyUpFiltreLibre = onKeyUpFiltreLibre;                          // A chaque frappe du clavier pour le champ de recherche libre
    _this.clearFilter = clearFilter;                                        // Permet de reset le filtre libre

    _this.onChangeCheckboxAllAssociation = onChangeCheckboxAllAssociation;  // Permet de voir seulement les groupes avec associations
    _this.onClickShowAllChecked = onClickShowAllChecked;                    // Permet de voir seulement les groupes sélectionnés
    _this.onClickAllUncheck = onClickAllUncheck;                            // Permet de decocher tous les groupes sélectionnés
    _this.onClickAddAssociation = onClickAddAssociation;                    // Permet d'ajouter des associations en mass

    _this.onClickDeleteCheckedAssociation = onClickDeleteCheckedAssociation; // Permet de supprimer toutes les associations des groupes sélectionnés
    _this.onEmitFromGestionAssociationsLigneComponent = onEmitFromGestionAssociationsLigneComponent;  // Lorsqu'on agit sur une ligne

    _this.onEmitFromChooseGroupeParent = onEmitFromChooseGroupeParent;

    // Version Admin
    _this.onClickRemoveAssociation = onClickRemoveAssociation;              // Permet de retirer des associations en mass (supprimer la liaison entre un immeuble et un mex par exemple)

    _this.$onInit = function() {
      if(_this.resolve && _this.modalInstance) {
        _this.type = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'type'));
        _this.adminVersion = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'adminVersion'));
        _this.portefeuille = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'portefeuille'));
      }

      _this.paramsRecherche.withAllCollaborateur = (_this.adminVersion && _this.myGroupe.isAdmin());

      if(_this.adminVersion && !_this.myGroupe.isAdmin()){
        _this.errorComponent = "Vous ne pouvez pas accéder à cette fonctionnalitée";
      }

      _this.placeHolderFiltre = "Filtre";
      if(_this.type === "immeuble"){
        _this.placeHolderFiltre = "Numéro ou nom de l'immeuble";
        if(_this.adminVersion) {
          _this.labelOnlyWithAssociation = "Seulement les immeubles associés";
          _this.textDeleteSelected = "Supprimer les immeubles sélectionnés"
        }
        else {
          _this.labelOnlyWithAssociation = "Seulement mes immeubles";
          _this.textDeleteSelected = "Supprimer les immeubles sélectionnés"
        }
      }
      else if(_this.type === "proprietaire"){
        _this.placeHolderFiltre = "Numéro, nom ou prénom du propriétaire";
        if(_this.adminVersion) _this.labelOnlyWithAssociation = "Seulement les propriétaires associés";
        else _this.labelOnlyWithAssociation = "Seulement mes propriétaires";

        _this.textDeleteSelected = "Supprimer les propriétaires sélectionnés"
      }

      init();
    };

    _this.$onChanges = function(changes) {
      if(_.isObject(changes.portefeuille) && !changes.portefeuille.isFirstChange()){
        _this.portefeuille = changes.portefeuille.currentValue;
        init();
      }
    };

    _this.$onDestroy = function(){
    };

    function init(){
      if(_.isNil(_this.portefeuille)) {
        _this.errorComponent = "Il manque le portefeuille !";
      }
      else _this.idPortefeuille = _this.portefeuille.getIdPortefeuille();
      initResultat();
      initParamMass();
      searchGroupes();

      if(!_this.adminVersion) {
        GroupeService
          .searchListGroupeParent(_this.idPortefeuille)
          .then(function(groupes){
          _this.listGroupeParent = groupes;
        });
      }
    }

    function initResultat(){
      _this.resultatGroupe = {
        loading: false,
        nbTotal: 0, // Nombre total de résultat sans prendre en compte loa pagination
        nb: 0,      // Nombre de résultat
        list: []
      };
    }

    function initParamMass(){
      _this.paramsMass = {
        allChecked: false,
        nbGroupeChecked: 0,
        nbGroupeCheckedWithGroupeAssociation: 0,
        nbGroupeCheckedWithoutGroupeAssociation: 0,
        groupesChecked: {},
      };
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      if(_this.modalInstance) _this.close({$value: objRetour});
      else _this.onEmit({obj: objRetour});
    }

    // ----------------- Event -------------------//

    /**
     * Lorsqu'on coche/décoche la checkbox pour toutes les associations immeuble d'un portefeuille
     * @param idPortefeuille
     */
    function onChangeCheckboxAllAssociation(){
      updateList(_this.paramsMass.allChecked);
    }

    function onClickShowAllChecked(){
      if(_.isNil(_this.paramsMass.list)) _this.paramsMass.list = getListGroupeChecked();
      else delete _this.paramsMass.list;
    }

    /**
     * Lors du clique sur le bouton pour retirer toutes la selection, même ceux invisibles
     */
    function onClickAllUncheck(){
      _this.paramsMass.groupesChecked = {};
      updateList();
    }

    /**
     * Lors du clique sur le bouton pour ajouter les groupe séléctionnés
     */
    function onClickAddAssociation(){
      addAssociations(getListGroupeChecked());
    }

    /**
     * Lors du clique sur le bouton pour retirer des collaborateurs aux groupes séléctionnés
     */
    function onClickRemoveAssociation(){
      if(_this.adminVersion) {
        var params = {
          mode: 'remove',
          type: _this.type,
          idPortefeuille: _this.idPortefeuille,
          groupes: getListGroupeChecked(true),
          adminVersion: true
        };
        ModalsService
          .modalComponent('recapAssociations', params, options_modal)
          .then(function(obj) {

            if(_.isObject(obj) && _.isArray(obj.groupes) && obj.groupes.length){
              if(obj.action === 'updateList') {
                updateGroupeInList(obj.groupes, false);
              }
            }
            /*
            //console.log(obj);
            if(_.isObject(obj) && _.isArray(obj.groupesAssocie) && obj.groupesAssocie.length && _.isArray(obj.groupes) && obj.groupes.length){
              if(obj.action === 'remove') {
                /**
                 * obj.groupes - Array<Groupe> = Liste de collaborateur à retirer
                 * obj.groupesAssocie - Array<Groupe> = Liste des groupe auquel il faut retirer les collaborateurs
                 *
                removeGroupeFromGroupeAssociation(obj.groupes, obj.groupesAssocie);
              }
            }*/
          });
      }
    }

    /**
     * Lors du clique sur le bouton pour supprimmer toutes les associations selectionnées
     * @param objetAssocFromListPortefeuille
     */
    function onClickDeleteCheckedAssociation(){
      var listCheckedIdGroupeAssociation = getListIdGroupeAssociationChecked();
      if(listCheckedIdGroupeAssociation.length) {
        ModalsService
          .confirm('Suppression', 'Supprimer les <strong>' + listCheckedIdGroupeAssociation.length + '</strong> élément(s) sélectionné(s) ?', {type: 'warning'})
          .then(function (modal) {
            if(_this.adminVersion) deleteGroupeAssociationFromGroupe(listCheckedIdGroupeAssociation)
            //else removeGroupeFromGroupeAssociation(_this.myGroupe.getIdGroupe(), listCheckedIdGroupeAssociation);
            else removeGroupeFromGroupeAssociation(getListGroupeAssociationGroupeFromGroupeChecked(), listCheckedIdGroupeAssociation);
            modal.close();
          })
          .catch(function(modal){
            modal.close();
          });
      }
    }

    /**
     * Lors de la frappe dans le filtre libre
     * @param event
     * @param objetAssocFromListPortefeuille
     */
    function onKeyUpFiltreLibre(event) {
      // Si tape sur "Entrée"
      if (event.keyCode === 13) {
        // Stop le formulaire
        event.preventDefault();
        searchGroupes(1);
      }
    }

    function clearFilter(){
      _this.paramsRecherche.search = null;
      searchGroupes(1);
    }

    /**
     * Si ouvert en mode Modal
     */
    function onClickClose(){
      if(_this.modalInstance) _this.dismiss({$value: 'cancel'});
      else emit({action: 'closeGestionAssociationType'});
    }

    // ----------------- Actions Servlet -------------------//

    /**
     * Permet de rechercher la liste des groupes par rapport au paramètres de recherche
     */
    function searchGroupes(currentPage){
      if(_.isNumber(currentPage)) _this.paramsRecherche.currentPage = currentPage;

      _this.paramsRecherche.typeGroupe = _this.type;
      _this.paramsRecherche.offset = (_this.paramsRecherche.limit * _this.paramsRecherche.currentPage)-_this.paramsRecherche.limit;
      _this.paramsRecherche.filtreActif = (!_.isNil(_this.paramsRecherche.search));
      _this.resultatGroupe.loading = true;
      GroupeService
        .searchListGroupeForTypeByGroupePortefeuille(_this.paramsRecherche, _this.idPortefeuille)
        .then(function(listImmeubleByPortefeuille){
          if(listImmeubleByPortefeuille.length && _.isObject(listImmeubleByPortefeuille[0]) && listImmeubleByPortefeuille[0].isGroupe) {
            var groupePortefeuille = listImmeubleByPortefeuille[0];

            _this.resultatGroupe.nb = groupePortefeuille.getGroupeInformations().getNbGroupeFils();
            _this.resultatGroupe.nbTotal = groupePortefeuille.getGroupeInformations().getNbGroupeFilsTotal();

            if(_.isArray(groupePortefeuille.getGroupesFils()) && groupePortefeuille.getGroupesFils().length)
              _this.resultatGroupe.list = groupePortefeuille.getGroupesFils();
            else _this.resultatGroupe.list = [];

            updateList();
          }
        })
        .finally(function(){
          _this.resultatGroupe.loading = false;
        });
    }

    function addAssociations(groupeAssocie, groupeCollaborateur){
      // Si pas version admin alors fait tout de suite laction
      if(!_this.adminVersion)  addAssociationListGroupeWithListGroupeAssocie((_.isNil(groupeCollaborateur)) ? _this.myGroupe.getIdGroupe() : groupeCollaborateur, groupeAssocie);
      // Si version admin alors ouvre une modal
      else {
        var params = {
          mode: 'add',
          type: _this.type,
          idPortefeuille: _this.idPortefeuille,
          groupes: groupeAssocie,
          adminVersion: _this.adminVersion
        };
        ModalsService
          .modalComponent('recapAssociations', params, options_modal)
          .then(function(obj) {
            if(_.isObject(obj) && _.isArray(obj.groupes) && obj.groupes.length){
              if(obj.action === 'updateList') {
                updateGroupeInList(obj.groupes, false);
              }
            }
            /*
            if(_.isObject(obj) && _.isArray(obj.groupesAssocie) && obj.groupesAssocie.length && _.isArray(obj.groupes) && obj.groupes.length){
              if(obj.action === 'add') {
                addAssociationListGroupeWithListGroupeAssocie(obj.groupes, obj.groupesAssocie);
              }
            }*/
          });
      }
    }

    /**
     * Permet d'ajouter des associations
     * @param listGroupe
     * @param listGroupeAssocie
     */
    function addAssociationListGroupeWithListGroupeAssocie(listGroupe, listGroupeAssocie){
      setLoading(true, listGroupeAssocie);
      GroupeAssociationService
        .associeListIdGroupeWithListIdGroupeAssocie(_this.idPortefeuille, _this.type, listGroupe, listGroupeAssocie, _this.adminVersion)
        .then(function(listGroupe){
          updateGroupeInList(listGroupe, false);
        })
        .finally(function(){
          setLoading(false, listGroupeAssocie);
        });
    }

    /**
     * Permet de supprimer des liaisons entre une liste d'idGroupe et une liste d'idGroupeAssociation
     * @param idGroupeOrGroupeAssociationGroupe - Array<Groupe> | GroupeAssociationGroupe | idGroupe - Ce sont les idGroupe qui sont récup
     * @param groupeAssocie - Groupe | Array<Groupe> | listIdGroupeAssociation - Ce sont les idGroupeAssociation qui sont récup
     */
    function removeGroupeFromGroupeAssociation(idGroupeOrGroupeAssociationGroupe, groupeAssocie){
      setLoading(true, groupeAssocie);
      GroupeAssociationService
        .removeListGroupeFromListAssociation(_this.type, idGroupeOrGroupeAssociationGroupe, groupeAssocie, _this.adminVersion)
        .then(function(listGroupe){
          updateGroupeInList(listGroupe, false);
          //deleteGroupeFromListGroupeAssociation(idGroupeOrGroupeAssociationGroupe, groupeAssocie);
          //if(showSuccessMessage) ModalsService.success(groupes. + " élément(s) retiré(s) !");
        })
        .catch(function(e){
          ModalsService.alertErreur(e);
        })
        .finally(function(){
          setLoading(false, groupeAssocie);
        });
    }

    /**
     * Permet de supprimer des groupeAssociations
     * @param objetAssocFromListPortefeuille
     * @param association
     * @param showSuccessMessage
     */
    function deleteGroupeAssociationFromGroupe(groupe){
      if(!_this.myGroupe.isAdmin()) ModalsService.alertErreur("Cette action est impossible");
      else {
        setLoading( true, groupe);
        GroupeAssociationService
          .deleteGroupeAssociation(_this.type, groupe)
          .then(function(listGroupe){
            updateGroupeInList(listGroupe, false)
            //deleteGroupeAssociationFromGroupeInList(groupe);
            //if(showSuccessMessage) ModalsService.success(ret + " élément(s) retiré(s) !");
          })
          .catch(function(e){
            ModalsService.alertErreur(e);
            console.error(e);
          })
          .finally(function(){
            setLoading( false, groupe);
          });
      }
    }

    // ----------------- Actions -------------------//

    /**
     * Permet de mettre à jour un objet ou liste d'objet groupe dans la liste actuel
     * @param groupe
     * @param chekUnchek
     */
    function updateGroupeInList(groupe, chekUncheck){
      if(_this.resultatGroupe.list.length) {
        if(!_.isArray(groupe)) {
          for(var i = 0; i < _this.resultatGroupe.list.length; i++){
            var currentGroupe = _this.resultatGroupe.list[i];
            if(_.isObject(currentGroupe)) {

              if(currentGroupe.getIdGroupe() === groupe.getIdGroupe()){
                if(!_.isNil(currentGroupe.getGroupeAssociation())){
                  var displayGroupeAssociationGroupe = currentGroupe.getGroupeAssociation().displayGroupeAssociationGroupe;
                  if(!_.isNil(displayGroupeAssociationGroupe) && _.isObject(groupe.getGroupeAssociation())){
                    groupe.getGroupeAssociation().displayGroupeAssociationGroupe = displayGroupeAssociationGroupe;
                  }
                }
                currentGroupe = groupe;
                adOrRemoveFromGroupesChecked(currentGroupe);

                break;
              }
            }
          }
        }
        else if(_.isArray(groupe) && groupe.length){
          var groupeByIdGroupe = groupe.reduce(function(objetGroupeByIdGroupe, groupe){
            objetGroupeByIdGroupe[groupe.getIdGroupe()] = groupe;
            return objetGroupeByIdGroupe;
          }, {});

          // Passe par une liste temporaire pour remplacer la liste, pour que les composant enfant capte le changement des groupes
          var newList = [];
          for(var j = 0; j < _this.resultatGroupe.list.length; j++){
            var currentGroupe = _this.resultatGroupe.list[j];
            var add = true;
            var found = false;
            if(_.isObject(currentGroupe)) {
              if(groupeByIdGroupe.hasOwnProperty(currentGroupe.getIdGroupe())){

                var newGroupe = groupeByIdGroupe[currentGroupe.getIdGroupe()];
                if(!_.isNil(currentGroupe.getGroupeAssociation())){
                  var displayGroupeAssociationGroupe = currentGroupe.getGroupeAssociation().displayGroupeAssociationGroupe;
                  if(!_.isNil(displayGroupeAssociationGroupe) && _.isObject(newGroupe.getGroupeAssociation())){
                    newGroupe.getGroupeAssociation().displayGroupeAssociationGroupe = displayGroupeAssociationGroupe;
                  }
                }

                currentGroupe = newGroupe;
                adOrRemoveFromGroupesChecked(currentGroupe);
                found = true;
              }
            }

            // Si recherche seulement les groupes associé et que le groupe actuel n'a plus d'association alors on ne le met pas dans la liste
            if(found && _this.paramsRecherche.onlyWithGroupeAssociation && _.isNil(currentGroupe.getGroupeAssociation())){
              add = false;
            }

            if(add) newList.push(currentGroupe);
            else _this.resultatGroupe.nbTotal--;
          }
          _this.resultatGroupe.list = angular.copy(newList);
        }
      }

      if(!_.isNil(chekUncheck) && !chekUncheck){
        _this.paramsMass.groupesChecked = {};
      }

      updateList(chekUncheck);
    }

    /**
     * Permet de retirer le groupeAssociation d'un groupe dans la liste
     * @param groupeOrListGroupeOrId
     * @param association
     */
    /*
    function deleteGroupeAssociationFromGroupeInList(groupeOrListGroupeOrId){
      if(_this.resultatGroupe.list.length) {
        var listIdGroupeAssociation = convertToListIdGroupeAssociation(groupeOrListGroupeOrId);
        if(listIdGroupeAssociation.length){
          _this.resultatGroupe.list.map(function(currentGroupe) {
            var currentGroupeAssociation = currentGroupe.getGroupeAssociation();
            if(_.isObject(currentGroupeAssociation) && listIdGroupeAssociation.indexOf(currentGroupeAssociation.idGroupeAssociation) !== -1)
              delete currentGroupe.groupeAssociation;
            return currentGroupe;
          });
        }
        updateList();
      }
    }
    */
    /**
     * Permet de supprimer les GroupeAssociationsGroupe d'un idGroupe pour une liste de GroupeAssociation
     * @param idGroupeOrGroupeAssociation
     * @param groupe
     */
    /*
    function deleteGroupeFromListGroupeAssociation(idGroupeOrGroupeAssociationGroupe, groupe){
      if(!_.isNil(idGroupeOrGroupeAssociationGroupe) && !_.isNil(groupe) && _this.resultatGroupe.list.length) {
        var listIdGroupe = GroupeAssociationService.getListIdGroupe(idGroupeOrGroupeAssociationGroupe);
        if(_.isArray(listIdGroupe) && listIdGroupe.length) {
          var listIdGroupeAssociation = convertToListIdGroupeAssociation(groupe);
          if(listIdGroupeAssociation.length){
            _this.resultatGroupe.list.map(function(currentGroupe){
              var currentGroupeAssociation = currentGroupe.getGroupeAssociation();
              if(_.isObject(currentGroupeAssociation)){
                // Si l'idGroupeAssociation de l'itération actuelle est dans la liste des idGroupeAssociation où il faut retirer le groupe
                if(listIdGroupeAssociation.indexOf(currentGroupeAssociation.idGroupeAssociation) !== -1){
                  _.remove(currentGroupeAssociation.listGroupeAssociationGroupe, function(groupeAssociationGroupe) {
                    // Renvoie true si le groupe doit etre supprimé, sinon false
                    return (_.isObject(groupeAssociationGroupe.getGroupe()) && listIdGroupe.indexOf(groupeAssociationGroupe.getGroupe().getIdGroupe()) !== -1);
                  });
                }
                // Si plus de groupeAssociationGroupe alors on le supprime du groupe
                if(!currentGroupeAssociation.listGroupeAssociationGroupe.length) {
                  delete currentGroupe.groupeAssociation;
                }
              }
              return currentGroupe;
            });
          }
          updateList();
        }
      }
    }*/

    /**
     * Ajoute ou retire un groupe des groupes cochés
     * @param groupe
     */
    function adOrRemoveFromGroupesChecked(groupe){
      // Si pas checké mais  dans la liste alors on le retire
      if(!groupe.checked && _.isObject(_this.paramsMass.groupesChecked[groupe.getIdGroupe()])) {
        delete _this.paramsMass.groupesChecked[groupe.getIdGroupe()];
        removeGroupeFromMassList(groupe);
      }
      // Si checké mais pas dans la liste alors on l'ajoute
      else if(groupe.checked && !_.isObject(_this.paramsMass.groupesChecked[groupe.getIdGroupe()])) {
        _this.paramsMass.groupesChecked[groupe.getIdGroupe()] = groupe;
      }
    }

    /**
     * Permet de mettre à jour divers infos comme le nombre coché et faire des manip comme décocher si caché etc..
     * Et également à cocher ou decocher en mass si besoin
     * @param checkOrUncheck
     */
    function updateList(checkOrUncheck){
      _this.paramsMass.allChecked = false;
      var currentChecked = 0;
      if(_this.resultatGroupe.list.length) {
        _this.resultatGroupe.list.map(function(currentGroupe) {

          // Si on check ou uncheck en mass la liste actuel
          if(!_.isNil(checkOrUncheck)) {
            currentGroupe.checked = checkOrUncheck;
            adOrRemoveFromGroupesChecked(currentGroupe);
          }
          // Si on met seulement à jour la liste par rapport à ce qui est déjà coché ou non
          else currentGroupe.checked = (_.isObject(_this.paramsMass.groupesChecked[currentGroupe.getIdGroupe()]));

          if(currentGroupe.checked) currentChecked++;

          return currentGroupe;
        });
      }

      _this.paramsMass.nbGroupeChecked = _.size(_this.paramsMass.groupesChecked);

      // Si le nombre de groupe coché dans la liste actuelle est le même que le nombre de la limit de la liste
      // Ou le même que le nombre total de résultat (on affiche tous)
      // alors ils sont tous cochés, on coche la case en mass
      if(currentChecked === _this.paramsRecherche.limit || currentChecked === _this.resultatGroupe.nbTotal) _this.paramsMass.allChecked = true;

      _this.paramsMass.nbGroupeCheckedWithGroupeAssociation = 0;
      _this.paramsMass.nbGroupeCheckedWithoutGroupeAssociation = 0;
      _.forEach(_this.paramsMass.groupesChecked, function(groupe, idGroupe) {
        if(_.isObject(groupe.getGroupeAssociation())) _this.paramsMass.nbGroupeCheckedWithGroupeAssociation++;
        else _this.paramsMass.nbGroupeCheckedWithoutGroupeAssociation++;
      });
    }

    // ----------------- Divers manipulations de données -------------------//

    function getListGroupeAssociationGroupeFromGroupeChecked(){
      return getListGroupeChecked(true).reduce(function(array, groupe) {
        if(_.isObject(groupe.getGroupeAssociation())) {
          array = _.unionBy(array, groupe.getGroupeAssociation().getListGroupeAssociationGroupe(), 'groupe.idGroupe');
        }
        return array;
      }, []);
    }

    /**
     * Permet de récupérer une liste diGroupeAssociation cochés
     * @param objetAssocFromListPortefeuille
     * @returns {T | any}
     */
    function getListIdGroupeAssociationChecked(){
      return getListGroupeChecked(true).reduce(function(array, groupe) {
        if(_.isObject(groupe.getGroupeAssociation())) array.push(groupe.getGroupeAssociation().getIdGroupeAssociation());
        return array;
      }, []);
    }

    /**
     * Permet de convertir l'objet des groupes sélectionnés en un array
     * @returns {*}
     */
    function getListGroupeChecked(withGroupeAssociation){
      return _.reduce(_this.paramsMass.groupesChecked, function(array, groupe){
        var add = true;
        if(!_.isNil(withGroupeAssociation)) {
          // On ajoute pas
          // Si on veut ceux avec un groupeAssociation et qu'il n'en a pas
          // Si on veut sans groupe association et qu'il en a un
          if ((withGroupeAssociation && _.isNil(groupe.getGroupeAssociation())) ||
            (!withGroupeAssociation && !_.isNil(groupe.getGroupeAssociation()))) {
            add = false;
          }
        }
        if(add) array.push(groupe);
        return array;
      }, []);
    }

    function setLoading(value, groupe){
      if(_.isNil(groupe) || _.isArray(groupe) || _.isNumber(groupe)) _this.resultatGroupe.loading = value;
      else if(_.isObject(groupe)) {
        groupe.loading = value;
        if(!value) _this.resultatGroupe.loading = false;
      }

      /*
      if(_.isArray(groupe)){
        groupe.map(function(item){
          if(_.isObject(item)) item.loading = value;
          return item;
        });
      }
      else if(_.isObject(groupe)) groupe.loading = value;*/
    }

    /**
     * Permet de récupérer quoi qu'il arrive une liste d'idGroupe
     * @param groupeOrListGroupeOrId
     * @returns {[]}
     */
    function convertToListIdGroupe(groupeOrListGroupeOrId){
      var listIdGroupe = [];
      if(_.isArray(groupeOrListGroupeOrId)) {
        for(var i = 0; i < groupeOrListGroupeOrId.length; i++){
          if(_.isObject(groupeOrListGroupeOrId[i])) listIdGroupe.push(associationOrListAssociationOrId[i].idGroupe);
          else if(_.isNumber(groupeOrListGroupeOrId[i])) listIdGroupe.push(associationOrListAssociationOrId[i]);
        }
      }
      else if(_.isObject(groupeOrListGroupeOrId)) listIdGroupe.push(associationOrListAssociationOrId.idGroupe);
      else if(_.isNumber(groupeOrListGroupeOrId)) listIdGroupe.push(associationOrListAssociationOrId);

      return listIdGroupe;
    }

    /**
     * Permet de récupérer une liste d'idGroupeAssociation à partir d'un groupe ou d'une liste de groupe
     * @param groupeOrListGroupeOrId
     * @returns {[]}
     */
    function convertToListIdGroupeAssociation(groupeOrListGroupeOrId){
      var listIdGroupe = [];
      if(_.isArray(groupeOrListGroupeOrId)) {
        for(var i = 0; i < groupeOrListGroupeOrId.length; i++) {
          if (_.isObject(groupeOrListGroupeOrId[i])) {
            if(!_.isNil(groupeOrListGroupeOrId[i].idGroupeAssociation)) {
              listIdGroupe.push(groupeOrListGroupeOrId[i].idGroupeAssociation);
            }
            else if( _.isObject(groupeOrListGroupeOrId[i].groupeAssociation)){
              listIdGroupe.push(groupeOrListGroupeOrId[i].groupeAssociation.idGroupeAssociation);
            }
          }
          else if(_.isNumber(groupeOrListGroupeOrId[i])) listIdGroupe.push(groupeOrListGroupeOrId[i]);
        }
      }
      else if(_.isObject(groupeOrListGroupeOrId)){
        if(!_.isNil(groupeOrListGroupeOrId.idGroupeAssociation)) {
          listIdGroupe.push(groupeOrListGroupeOrId.idGroupeAssociation);
        }
        else if( _.isObject(groupeOrListGroupeOrId.groupeAssociation)){
          listIdGroupe.push(groupeOrListGroupeOrId.groupeAssociation.idGroupeAssociation);
        }
      }
      else if(_.isNumber(groupeOrListGroupeOrId)) listIdGroupe.push(groupeOrListGroupeOrId[i]);
      return listIdGroupe;
    }

    function removeGroupeFromMassList(groupeRemove){
      if(_.isArray(_this.paramsMass.list)){
        _.remove(_this.paramsMass.list,function(currentGroupe){
          return (currentGroupe.getIdGroupe() === groupeRemove.getIdGroupe());
        });
        if(!_this.paramsMass.list.length) delete _this.paramsMass.list;
      }
    }
    // ----------------- OnEmit -------------------//

    /**
     * Lorsqu'il y a une remontée d'info du componsant gestion-associations-ligne
     * @param obj
     */
    function onEmitFromGestionAssociationsLigneComponent(obj) {
      try {

        if (!_.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');
        if(!_.isObject(obj.groupe)) throw new Error('Le groupe doit être un objet');

        if(obj.action === 'checkUncheckAssoc')
          updateGroupeInList(obj.groupe);

        // Si ajout d'une association
        if(obj.action === 'addAssoc')
          addAssociations(obj.groupe, obj.groupeCollaborateur);

        // Si suppression d'un gorupe d'une association
        if(obj.action === 'removeAssoc')
          removeGroupeFromGroupeAssociation((_.isNil(obj.groupeAssocationGroupe)) ? _this.myGroupe.getIdGroupe() : obj.groupeAssocationGroupe, obj.groupe);

        if(obj.action === 'deleteAssoc')
          deleteGroupeAssociationFromGroupe(obj.groupe);
      }
      catch (err) {
        ErreurCollabService.logErreur('[GestionAssociationsController.onEmitFromGestionAssociationsLigneComponent] Erreur : ',  err.message);
      }
    }

    function onEmitFromChooseGroupeParent(obj){
      try {

        if (!_.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');

        if(obj.action === 'validSelection')
          addAssociations(getListGroupeChecked(), obj.listGroupeCollaborateurChecked);

      }
      catch (err) {
        ErreurCollabService.logErreur('[GestionAssociationsLigneController.onEmitFromChooseGroupeParent] Erreur : ',  err.message);
      }
    }
  }
})();
