(function () {
  'use strict';

  /**
   * @desc Permet d'envoyer un document dans la ged
   * @example <send-ged></send-ged>
   */

  angular
    .module('collaboreApp')
    .component('sendGed', {
      templateUrl: 'app/topics/send-ged/v1/send-ged.component.html',
      bindings: {
        documents: '<',
        numImmeuble: '<',
        numMandat: '<',
        idEvenement: '<',
        idPortefeuille: '<',
        onEmit: '&',

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<'
      },
      controllerAs: 'sendgedctrl',
      controller: SendGedController
    });

  /*@ngInject*/
  function SendGedController(COLLAB_CONF, $log, $rootScope, $q, GedService, UtilsService, ModalsService) {

    var _this = this;
    _this.listDocumentResult = [];
    _this.folderGed = null;
    _this.gedChemin = null;
    _this.onClickSend = onClickSend;
    _this.onClickClose = onClickClose;
    _this.onEmitFromCheminGed = onEmitFromCheminGed;

    _this.$onInit = function() {
      if(_this.resolve && _this.modalInstance) {
        _this.documents = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'documents'));
        _this.numImmeuble = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'numImmeuble'));
        _this.numMandat = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'numMandat'));
        _this.idEvenement = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'idEvenement'));
        _this.idPortefeuille = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'idPortefeuille'));
        _this.onEmit = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'onEmit'));
      }
      init();
    };

    _this.$onChanges = function(changes) {
    };

    _this.$onDestroy = function(){
    };

    function init(){
      if (_.isNil(_this.numImmeuble))   _this.numImmeuble = "0";
      if (_.isNil(_this.numMandat))     _this.numMandat = "0";
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    /*
    function emit(objRetour){
      if(_this.modalInstance) _this.close({$value: objRetour});
      else _this.onEmit({obj: objRetour});
    }*/

    /**
     * Lors du clique sur le bouton envoyer
     */
    function onClickSend(){
      try {
        var guids = [];
        if(_.isArray(_this.documents)) {
          for(var i = 0; i < _this.documents.length; i++){
            guids = _.union(guids, [_this.documents[i].getGuid()]);
          }
        }
        else if(_.isObject(_this.documents)) {
          guids.push(_this.documents.getGuid());
        }

        _this.loading = true;
        GedService
          .sendToGed(_this.idPortefeuille, _this.gedChemin, guids)
          .then(function(documents){
            _this.listDocumentResult = documents;
            _this.sendProcessed = true;
            emit({'documents': documents});
          })
          .finally(function(){
            _this.loading = false;
          });
      }
      catch(e) {
        ModalsService.alertErreur(e.message);
      }
    }

    function onClickClose(){
      close();
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      _this.onEmit({obj: objRetour});
    }

    /**
     * Si ouvert en mode Modal
     */
    function close(){
      if(_this.modalInstance) _this.dismiss({$value: 'cancel'});
      else emit({action: 'closeSendGed'});
    }

    /**
     * Lors de la remontée d'information depuis le composant de chemin-ged
     * @param obj
     */
    function onEmitFromCheminGed(obj){
      try {
        if(!_.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');
        if(!_.isNil(obj.cheminGed)) _this.gedChemin = obj.cheminGed
      }
      catch (err) {
        $log.log('[SendGedController.onEmitFromCheminGed] Erreur : ' + err.message);
      }
    }
  }
})();
