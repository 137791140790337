(function () {
  'use strict';

  /**
   * @desc Gestion du devis
   * @example <manage-devis></manage-devis>
   */

  angular
    .module('collaboreApp')
    .component('manageDevis', {
      bindings: {
        objetDevis: '<',
        newTopic: '<',
        allowEditEvenement: '<',
        evenement: '<',
        onEmit: '&'
      },
      templateUrl: 'app/topics/evenement/devis/manage-devis.component.html',
      controllerAs: 'managedevisctrl',
      controller: manageDevis
    });

  /*@ngInject*/
  function manageDevis(COLLAB_CONF, COLLAB_VALUES, $rootScope, $scope, $q, FileUploader, TagsService, GroupeService, UserService, UtilsService, sweet, ContenuService, ModalsService, TopicManagerService, RelanceAutoConfig, ErreurCollabService, DocumentManagerService, DemandeOptions) {

    var ctrl = this;

    ctrl.uploaderDevis = {
      //action: 'uploadTypedDocument',
      //typeDocument: COLLAB_CONF.TYPE_DOC_ENUMS.DEVIS,
      uploader: null,
      deferred: null,
      extensionsAllowed: ['pdf','jpg','png','jpeg','bmp','gif']
    };

    ctrl.uploaderDevisFacture = {
      //action: 'uploadTypedDocument',
      //typeDocument: COLLAB_CONF.TYPE_DOC_ENUMS.FACTURE,
      uploader: null,
      deferred: null,
      extensionsAllowed: ['pdf','jpg','png','jpeg','bmp','gif']
    };

    ctrl.uploaderDevisAutreDocument = {
      //action: 'uploadTypedDocument',
      //typeDocument: COLLAB_CONF.TYPE_DOC_ENUMS.DOC_FOURNISSEUR,
      uploader: null,
      deferred: null
    };

    ctrl.tagOrigine = false;         // Tag origine du contenu
    ctrl.tagProprietaire = false;
    ctrl.documentsEvenementPartage = {};
    ctrl.factureIsDeleted = false;

    ctrl.parseStringHTML = UtilsService.parseStringHTML;	// Permet de garder les attributs style du HTML, sinon il sont supprimé
    ctrl.calculDateProchaineRelance = UtilsService.calculDateProchaineRelance;	// Permet de calculer la date de prochaine relance

    ctrl.onChangeDest = onChangeDest;
    // ctrl.onDropDocumentDevisFromWidgetDocuments = onDropDocumentDevisFromWidgetDocuments;
    ctrl.removeDocumentDevis = removeDocumentDevis;

    // ctrl.onDropFactureFromWidgetDocuments = onDropFactureFromWidgetDocuments;
    ctrl.removeDocumentFacture = removeDocumentFacture;

    // ctrl.onDropAutreDocumentFromWidgetDocuments = onDropAutreDocumentFromWidgetDocuments;
    ctrl.removeAutreDocument = removeAutreDocument;

    ctrl.onUpdatePiecesJointes = onUpdatePiecesJointes;					// Lors d'une modification de pièces jointes
    //ctrl.saveAddDevis = saveAddDevis;					// Sauvegarde le devis
    ctrl.onEmitFromDocuments = onEmitFromDocuments;					// Lors d'une modification de pièces jointes
    //ctrl.saveAddDevis = saveAddDevis;					// Sauvegarde le devis
    ctrl.saveDevis = saveDevis;					// Sauvegarde le devis
    //ctrl.onAddDestinataire = onAddDestinataire;		// Lors de l'ajout d'un destinataire
    ctrl.closeManagerDevis = closeManagerDevis;		// Ferme le devis
    ctrl.onChangeCopyDocumentFromEvent = onChangeCopyDocumentFromEvent;		// Lors du changement d'état d'une checbox de copy de document de l'event
    ctrl.onClickOpenCommunication = onClickOpenCommunication;             // Lors du clique pour ouvrire le formaulaire d'envoie de copie de demande

    ctrl.currentTemplateMessage = null;
    ctrl.currentTemplateMessageGravitants = null;
    ctrl.onChangeTemplate = onChangeTemplate;
    ctrl.templatesMessage = UtilsService.getModulesTemplatesObjet('template_desc_devis');

    ctrl.showCheckboxSendDocByMail = true; // Permet d'afficher ou cacher la checkbox pour envoyer les docs par email au destinataire
    ctrl.hasDocumentAttestationTvaSimple = false;   // Permet de savoir si le devis existant en cours de modification à déjà une attestation TVA
    var oldDestinataires = null;
    var oldDemandeDevisActive = null;
    ctrl.onClickChangeDemandeDevisActive = onClickChangeDemandeDevisActive;
    ctrl.onChangeDemandeDevisActive = onChangeDemandeDevisActive;
    ctrl.onEmitSelectSignatureContenu = onEmitSelectSignatureContenu;   // Lors du rmeonté d'infos du composant "select-signature-contenu"

    ctrl.showCheckboxRelanceAuto = true; // Permet d'afficher ou cacher la checkbox pour activer les relances automatiques
    ctrl.relanceAutoConfig = null;
    ctrl.onChangeEnableRelanceAuto = onChangeEnableRelanceAuto;
    ctrl.onStopSlide = onStopSlide;

    ctrl.noGravitants = false;    // Si pas de gravitant
    ctrl.gravitantsChecked = [];  // Liste des gravitants cochés
    ctrl.onEmitFromChooseGravitants = onEmitFromChooseGravitants;   // Lors du rmeonté d'infos du composant "select-signature-contenu"

    ctrl.displayCommunication = displayCommunication;

    ctrl.onEmitFromUploadDevisCollab = onEmitFromUploadDevisCollab;
    ctrl.onEmitFromUploadFactureCollab = onEmitFromUploadFactureCollab;
    ctrl.onEmitFromUploadAutresCollab = onEmitFromUploadAutresCollab;

    ctrl.txtSendOneMailWithAllDest = COLLAB_VALUES.TEXTE_SEND_ONE_MAIL_FOR_ALL_DEST;

    var noSendDocumentParam = null;

    ctrl.$onInit = function () {

      ctrl.topicIsBlocked = TopicManagerService.isBlocked;
      ctrl.documentsDevisDropTmp = [];        // Liste des devis documents temporaire venant du widget des documents
      ctrl.documentsFactureDropTmp = [];      // Liste des facture documents temporaire venant du widget des documents
      ctrl.autreDocumentsDropTmp = [];        // Liste des autres documents temporaire venant du widget des documents

      // Upload de devis
      /*
      ctrl.uploaderDevis = new FileUploader({
        autoUpload: false,
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_DOCUMENTS_IN_GROUPE,
        alias: 'fileName',
        removeAfterUpload: false,
        //queueLimit: 1,
        filters: [{
          name: 'sizeLimit',
          fn: function (item) {
            //COLLAB_CONF.SIZE_LIMIT_UPLOAD
            if (item.size <= COLLAB_CONF.SIZE_LIMIT_UPLOAD) {

              var type = item.type.slice(item.type.lastIndexOf('/') + 1);
              if (type !== 'pdf') {
                sweet.show({
                  title: '<span class="text-danger">' + item.name + '</span> doit être un PDF !',
                  text: 'PDF obligatoire pour le devis',
                  type: 'error',
                  html: true
                });
              } else {
                return true;
              }

            } else {
              sweet.show({
                title: '<span class="text-danger">' + item.name + '</span> trop gros !',
                text: '50 Mo Maximum',
                type: 'error',
                html: true
              });
            }
          }
        }]
      });

      // Upload autres documents
      ctrl.uploaderAutresDocuments = new FileUploader({
        autoUpload: false,
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_DOCUMENTS_IN_GROUPE,
        alias: 'fileName',
        removeAfterUpload: false,
        filters: [{
          name: 'sizeLimit',
          fn: function (item) {
            //COLLAB_CONF.SIZE_LIMIT_UPLOAD
            if (item.size <= COLLAB_CONF.SIZE_LIMIT_UPLOAD) return true;
            sweet.show({
              title: '<span class="text-danger">' + item.name + '</span> trop gros !',
              text: '50 Mo Maximum',
              type: 'error',
              html: true
            });
          }
        }]
      });

      // Upload de facture
      ctrl.uploaderFacture = new FileUploader({
        autoUpload: false,
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_DOCUMENTS_IN_GROUPE,
        alias: 'fileName',
        removeAfterUpload: false,
        queueLimit: 1,
        filters: [{
          name: 'sizeLimit',
          fn: function (item) {
            //COLLAB_CONF.SIZE_LIMIT_UPLOAD
            if (item.size <= COLLAB_CONF.SIZE_LIMIT_UPLOAD) {

              var type = item.type.slice(item.type.lastIndexOf('/') + 1);
              if (type !== 'pdf') {
                sweet.show({
                  title: '<span class="text-danger">' + item.name + '</span> doit être un PDF !',
                  text: 'PDF obligatoire pour les factures',
                  type: 'error',
                  html: true
                });
              } else {
                return true;
              }

            } else {
              sweet.show({
                title: '<span class="text-danger">' + item.name + '</span> trop gros !',
                text: '50 Mo Maximum',
                type: 'error',
                html: true
              });
            }
          }
        }]
      });
      */

      //ctrl.evenement = angular.copy(ctrl.contenuEvenement);

      initDocumentsFromEvent();

      /*
      ctrl.documentsFacture = false;
      var facture = ctrl.objetDevis.facture;
      if (facture && facture.getListeDocument().length) ctrl.documentsFacture = true;
      */

      // Si c'est un contenu avec idContenu
      if (ctrl.objetDevis.idContenu) {
        ctrl.showCheckboxRelanceAuto = false;
        oldDemandeDevisActive = angular.copy(ctrl.objetDevis.demandeDevisActive);
        oldDestinataires = angular.copy(ctrl.objetDevis.getDestinataires());
        ctrl.showCheckboxSendDocByMail = false;
        // Si pas de destinataire dans le contenu déjà enregistré, on permet du coup de supprimer un potentiel destinataire ajouté
        if(!ctrl.objetDevis.getDestinataires().length) ctrl.allowDelete = true;

        if(ctrl.objetDevis.getDocumentAttestationTvaSimple()) ctrl.hasDocumentAttestationTvaSimple = true;
      }
      else{
        if(_.isNil(ctrl.objetDevis.demandeOptions)) ctrl.objetDevis.demandeOptions = new DemandeOptions();
        ctrl.objetDevis.demandeOptions.hideProprietaireInDocument = UtilsService.getParametrage('hideProprietaireInDocument');
        ctrl.tagProprietaire = TagsService.getTagTypeProprietaire(ctrl.evenement.getTagsType());
      }

      ctrl.objetDevis.sendAttachementsWhenCreateDevis = true;

      ctrl.paramSendOneMailForAllDest = UtilsService.getParametrage('sendOneMailForAllDest');
      noSendDocumentParam = UtilsService.getParametrage('createDevisNoSendDocumentsByMail');
      if (noSendDocumentParam !== null) ctrl.objetDevis.sendAttachementsWhenCreateDevis = !noSendDocumentParam;

      checkDisplayOptions();

      // Si appelé depuis la création d'un évenement et d'une demande en même temps
      if(ctrl.newTopic) {
        ctrl.allowEditEvenement = true;

        // Ecoute les chanegment de tag type pour changer les gravitants
        $scope.$watch('managedevisctrl.evenement.tagsType', function(newVal, oldVal) {
          ctrl.tagOrigine = TagsService.getTagTypeOrigine(newVal);
          ctrl.tagProprietaire = TagsService.getTagTypeProprietaire(newVal);
        }, true);

        $scope.$on('getObjetDemande', function(event, successCallback, errorCallback){
          if(successCallback) {
            saveDevis()
              .then(function(objetDemande){
                successCallback(objetDemande);
              })
              .catch(function(msg){
                errorCallback(msg);
              });
          }
          /*
          ctrl.onEmit({
            obj: {
              objetDemande: ctrl.objetDevis
            }
          });*/
        });
      }
      if(!ctrl.objetDevis.isWaiting()) ctrl.collapsedDocumentFacture = true;

      initListPeriode();
    };

    ctrl.$onChanges = function (changes) {
      if(_.isObject(changes)) {
        if(_.isObject(changes.evenement) && !changes.evenement.isFirstChange()) {
          ctrl.evenement = changes.evenement.currentValue;
          initDocumentsFromEvent();
        }
      }
    };

    function initDocumentsFromEvent(){
      // Si le devis à d'autres document et que l'evenement en a aussi
      if (ctrl.objetDevis.listeDocument.length && ctrl.evenement.listeDocument.length) {
        // Parcours les documents du devis
        for (var i = 0; i < ctrl.objetDevis.listeDocument.length; i++) {
          var docDevis = ctrl.objetDevis.listeDocument[i];

          // Parcours les documents de l'événement
          for (var d = 0; d < ctrl.evenement.listeDocument.length; d++) {
            var docEvent = ctrl.evenement.listeDocument[d];

            // Si le doc de l'event est le meme que le doc du devis
            if (docEvent.getGuid() === docDevis.getGuid()) {
              ctrl.documentsEvenementPartage[docDevis.getGuid()] = true;
              break;
            }
          }
        }
      }
    }

    function initListPeriode(){

      var listTicks = [],
          listTicksLabels = [];

      ctrl.listPeriode = UtilsService.getParametragePerso('listPeriode')

      if(_.isArray(ctrl.listPeriode) && ctrl.listPeriode.length){
        for(var i = 0; i < ctrl.listPeriode.length; i++){
          listTicks.push(ctrl.listPeriode[i].getId());
          listTicksLabels.push(ctrl.listPeriode[i].getLibelle());
        }
      }
      ctrl.listTicks = listTicks;
      ctrl.listTicksLabels = listTicksLabels;
    }

    /**
     * Initialisation de l'objet de relance auto config
     */
    function initRelanceAutoConfig(){
      ctrl.relanceAutoConfig = new RelanceAutoConfig({
        periode: angular.copy(ctrl.listPeriode[0])
      });

      if(_.isObject(ctrl.objetDevis.getRelanceAutoConfig()))
        ctrl.relanceAutoConfig = angular.copy(ctrl.objetDevis.getRelanceAutoConfig());

      //_this.dateDepartRelance = _this.demande.getDateDepartRelance();
    }

    /**
     * Lors du changement de période
     * @param event
     * @param value
     */
    function onStopSlide(event, value){
      if(!_.isNil(value) && _.isArray(ctrl.listPeriode) && ctrl.listPeriode.length) {
        for(var i = 0; i < ctrl.listPeriode.length; i++){
          if(ctrl.listPeriode[i].id === value) {
            ctrl.relanceAutoConfig.setPeriode(angular.copy(ctrl.listPeriode[i]));
            break;
          }
        }
      }
    }

    /********** Uploader *************/

    /**
     * Lors du drop d'un document devis depuis le Widget des documents
     */
    /*
    function onDropDocumentDevisFromWidgetDocuments() {
        if (ctrl.documentDevisDrop.getExtension() !== '.pdf') {
            sweet.show({
                title: '<span class="text-danger">' + ctrl.documentDevisDrop.getNom() + '</span> doit être un PDF !',
                text: 'PDF obligatoire pour le devis',
                type: 'error',
                html: true
            });
        }
        else {
            var exist = false;
            if(ctrl.documentsDevisDropTmp.length){
                for(var i = 0; i < ctrl.documentsDevisDropTmp.length; i++) {
                    if(ctrl.documentDevisDrop.getGuid() === ctrl.documentsDevisDropTmp[i].getGuid()) {
                        exist = true;
                        break;
                    }
                }
            }
            if(!exist) ctrl.documentsDevisDropTmp.push(ctrl.documentDevisDrop);
        }
        ctrl.documentDevisDrop = null;
    }*/

    /**
     * Retire le document devis temporaire venant du widget des document
     * @param doc
     */
    function removeDocumentDevis(doc){
      if(ctrl.documentsDevisDropTmp.length){
        for(var i = 0; i < ctrl.documentsDevisDropTmp.length; i++) {
          if(doc.getGuid() === ctrl.documentsDevisDropTmp[i].getGuid()) {
            ctrl.documentsDevisDropTmp.splice(i, 1);
          }
        }
      }
      //ctrl.documentDevisDrop = null;
    }

    /**
     * Ajoute un document venant du widget des document à la liste des facture documents
     */
    /*
    function onDropFactureFromWidgetDocuments(){
        if (ctrl.documentFactureDrop.getExtension() !== '.pdf') {
            sweet.show({
                title: '<span class="text-danger">' + ctrl.documentFactureDrop.getNom() + '</span> doit être un PDF !',
                text: 'PDF obligatoire pour la facture',
                type: 'error',
                html: true
            });
        }
        else {
            var exist = false;
            if(ctrl.documentsFactureDropTmp.length){
                for(var i = 0; i < ctrl.documentsFactureDropTmp.length; i++) {
                    if(ctrl.documentFactureDrop.getGuid() === ctrl.documentsFactureDropTmp[i].getGuid()) {
                        exist = true;
                        break;
                    }
                }
            }
            if(!exist) ctrl.documentsFactureDropTmp.push(ctrl.documentFactureDrop);
        }
        ctrl.documentFactureDrop = null;
    }*/

    /**
     * Retire un document de la liste des documents facture temporaire venant du widget des document
     * @param doc
     */
    function removeDocumentFacture(doc){
      if(ctrl.documentsFactureDropTmp.length){
        for(var i = 0; i < ctrl.documentsFactureDropTmp.length; i++) {
          if(doc.getGuid() === ctrl.documentsFactureDropTmp[i].getGuid()) {
            ctrl.documentsFactureDropTmp.splice(i, 1);
          }
        }
      }
    }

    /**
     * Ajoute un document venant du widget des document à la liste des autres documents
     */
    /*
    function onDropAutreDocumentFromWidgetDocuments(){
      var exist = false;
      if(ctrl.autreDocumentsDropTmp.length){
        for(var i = 0; i < ctrl.autreDocumentsDropTmp.length; i++) {
          if(ctrl.autreDocumentDrop.getGuid() === ctrl.autreDocumentsDropTmp[i].getGuid()) {
            exist = true;
            break;
          }
        }
      }
      if(!exist) ctrl.autreDocumentsDropTmp.push(ctrl.autreDocumentDrop);
      ctrl.autreDocumentDrop = null;
    }*/

    /**
     * Retire un document de la liste des autre document temporaire venant du widget des document
     * @param doc
     */
    function removeAutreDocument(doc){
      if(ctrl.autreDocumentsDropTmp.length){
        for(var i = 0; i < ctrl.autreDocumentsDropTmp.length; i++) {
          if(doc.getGuid() === ctrl.autreDocumentsDropTmp[i].getGuid()) {
            ctrl.autreDocumentsDropTmp.splice(i, 1);
          }
        }
      }
    }

    function onChangeDest(){
      checkDisplayOptions();
    }

    function checkFormulaire() {

      // Si pas de destinataire
      if (!ctrl.objetDevis.destinataires.length) throw new Error('Il manque le/les destinataire(s) de la demande !');

      // Test si il y a bien un message pour les gravitant si il y en a coché
      if (ctrl.gravitantsChecked.length && (!ctrl.objetDevis.messageGravitants || ctrl.objetDevis.messageGravitants === '')) throw new Error('Il manque le message pour les gravitants !');

    }

    /**
     * Permet d'upload les document de la demandes
     * @returns {*}
     */
    function uploadDocs(){

      var promises = [];

      // Si il y aun devis à upload
      if (_.isObject(ctrl.uploaderDevis.uploader) && ctrl.uploaderDevis.uploader.getNotUploadedItems().length) {

        /*
        if(!_.isNil(idContenu)){
          _.forEach(ctrl.uploaderDevis.uploader.getNotUploadedItems(), function(doc) {
            // Ajoute l'idContenu au document qui va être envoyé
            doc.formData.push({idContenu: idContenu});
          });
        }*/
        ctrl.uploaderDevis.deferred = $q.defer();
        ctrl.uploaderDevis.uploader.uploadAll();
        promises.push(ctrl.uploaderDevis.deferred.promise);
      }

      // Si il y a des autres documents du devis à upload
      if (_.isObject(ctrl.uploaderDevisAutreDocument.uploader) && ctrl.uploaderDevisAutreDocument.uploader.getNotUploadedItems().length) {

        /*
        if(!_.isNil(idContenu)) {
          _.forEach(ctrl.uploaderDevisAutreDocument.uploader.getNotUploadedItems(), function (doc) {
            // Ajoute l'idContenu au document qui va être envoyé
            doc.formData.push({idContenu: idContenu});
          });
        }
        */
        ctrl.uploaderDevisAutreDocument.deferred = $q.defer();
        ctrl.uploaderDevisAutreDocument.uploader.uploadAll();
        promises.push(ctrl.uploaderDevisAutreDocument.deferred.promise);
      }

      // Si il y a des factures à upload
      if (_.isObject(ctrl.uploaderDevisFacture.uploader) && ctrl.uploaderDevisFacture.uploader.getNotUploadedItems().length) {

        /*
        if(!_.isNil(idContenu)) {
          _.forEach(ctrl.uploaderDevisFacture.uploader.getNotUploadedItems(), function (doc) {
            // Ajoute l'idContenu au document qui va être envoyé
            doc.formData.push({idContenu: idContenu});
          });
        }
        */
        ctrl.uploaderDevisFacture.deferred = $q.defer();
        ctrl.uploaderDevisFacture.uploader.uploadAll();
        promises.push(ctrl.uploaderDevisFacture.deferred.promise);
      }

      return $q.all(promises);
    }

    /*
    function saveDevis(closeManager) {
      if(!ctrl.newTopic) ctrl.penddingSave = true;
      var globalDeferred = $q.defer();
      try {
        checkFormulaire();

        var promises = [];

        // Si il y aun devis à upload
        if (ctrl.uploaderDevis.getNotUploadedItems().length) {
          deferredDevis = $q.defer();
          ctrl.uploaderDevis.uploadAll();
          promises.push(deferredDevis.promise);
        }
        // Si ajout du document devis depuis le widget des documents
        //else if(ctrl.documentDevisDrop) {
        //  ctrl.objetDevis.setDocumentDevis(angular.copy(ctrl.documentDevisDrop));
        //}
        // Si il y a des documents facture ajouté depuis le widget des documents
        if(ctrl.documentsDevisDropTmp.length){
          for(var d = 0; d < ctrl.documentsDevisDropTmp.length; d++) {
            ctrl.objetDevis.addDocumentDevis(angular.copy(ctrl.documentsDevisDropTmp[d]));
          }
        }

        // Si il y a des autres documents du devis à upload
        if (ctrl.uploaderAutresDocuments.getNotUploadedItems().length) {
          deferredDevisAutreDocument = $q.defer();
          ctrl.uploaderAutresDocuments.uploadAll();
          promises.push(deferredDevisAutreDocument.promise);
        }

        // Si il y a des documents ajouté depuis le widget des documents
        if(ctrl.autreDocumentsDropTmp.length){
          // Parcour les autres documents temporaire déposé depuis le widget
          for(var i = 0; i < ctrl.autreDocumentsDropTmp.length; i++){
            ctrl.objetDevis.addDocument(angular.copy(ctrl.autreDocumentsDropTmp[i]));
          }
        }

        // Si il y a des factures à upload
        if (ctrl.uploaderFacture.getNotUploadedItems().length) {
          deferredFacture = $q.defer();
          ctrl.uploaderFacture.uploadAll();
          promises.push(deferredFacture.promise);
        }

        // Si il y a des documents facture ajouté depuis le widget des documents
        if(ctrl.documentsFactureDropTmp.length){
          if (!ctrl.objetDevis.facture) {
            ctrl.objetDevis.facture = ContenuService.initContenuEvenementFacture();
          }

          for(var j = 0; j < ctrl.documentsFactureDropTmp.length; j++) {
            ctrl.objetDevis.facture.addDocument(angular.copy(ctrl.documentsFactureDropTmp[j]));
          }
        }

        $q.all(promises)
          .then(function () {

            if (ctrl.documentsEvenementPartage) {
              // Parcour les documets qui doivent être partagé
              angular.forEach(ctrl.documentsEvenementPartage, function (bool, guid) {
                if (bool) {
                  // Parcour les documents de l'événement
                  for (var i = 0; i < ctrl.evenement.listeDocument.length; i++) {
                    var doc = ctrl.evenement.listeDocument[i];

                    if (guid === doc.getGuid()) {

                      var canAdd = true;
                      // Parcour les documents du devis
                      for (var d = 0; d < ctrl.objetDevis.listeDocument.length; d++) {
                        // Si il existe déjà on ne l'ajoute pas
                        if (ctrl.objetDevis.listeDocument[d].getGuid() === doc.getGuid()) {
                          canAdd = false;
                        }
                      }

                      // Si pas encore dans le tableau alors on l'ajoute
                      if (canAdd) {
                        ctrl.objetDevis.listeDocument.push(angular.copy(doc));
                      }
                    }
                  }
                } else {
                  // Parcour les documents du devis déjà présent pour supprimer ceux qui ne sont plus coché
                  for (var e = 0; e < ctrl.objetDevis.listeDocument.length; e++) {
                    if (guid === ctrl.objetDevis.listeDocument[e].getGuid()) {
                      ctrl.objetDevis.listeDocument.splice(e, 1);
                    }
                  }
                }
              });
            }

            // Si il y a des destinataires dans l'objet devis
            // Déménagé dans le service
            //if (ctrl.objetDevis.destinataires.length) {
            //    ctrl.objetDevis.listeDestinataires = [];
            //    for (var d = 0; d < ctrl.objetDevis.destinataires.length; d++) {
            //        ctrl.objetDevis.listeDestinataires.push(ctrl.objetDevis.destinataires[d]);
            //    }
                //ctrl.objetDevis.destinataires = [];
            //}
            //console.log(ctrl.objetDevis);

            if(_.isObject(ctrl.signatureSelected) && ctrl.signatureSelected.hasOwnProperty('value')) ctrl.objetDevis.signature = ctrl.signatureSelected.value;

            if(_.isObject(ctrl.relanceAutoConfig)) ctrl.objetDevis.setRelanceAutoConfig(ctrl.relanceAutoConfig);

            // Si nouveau devis
            if (_.isNil(ctrl.objetDevis.idContenu) || !ctrl.objetDevis.idContenu) {

              ctrl.objetDevis.listeDestinatairesMessageGravitants = [];

              // Si le bloc gravitant est affiché et qu'il y a des gravitants cochés
              if(ctrl.displayCommunication() && ctrl.gravitantsChecked.length)  {
                for(var g = 0; g < ctrl.gravitantsChecked.length; g++){
                  if(ctrl.gravitantsChecked[g].groupeCollab) ctrl.objetDevis.listeDestinatairesMessageGravitants.push(ctrl.gravitantsChecked[g].groupeCollab);
                }
              }

              if(!ctrl.objetDevis.listeDestinatairesMessageGravitants.length) delete ctrl.objetDevis.sendOneMailWithAllGravitants;

              // Si pas de destinataires dans la liste des autres destinataire de la demande
              // Déménagé dans le service
              //if (!ctrl.objetDevis.listeDestinatairesMessageGravitants.length) {
              //    ctrl.objetDevis.messageGravitants = '';
              //}

              if(ctrl.newTopic) globalDeferred.resolve(ctrl.objetDevis);
              else {
                ctrl.loading = true;
                // $rootScope.$broadcast('showLoadingContenuEvenementFormulaire');
                ctrl.evenement
                  .createDevis(ctrl.objetDevis)
                  .then(function () {
                    // Ferme le manager devis
                    if (closeManager) closeManagerDevis();
                    globalDeferred.resolve();
                  })
                  .catch(function (msg) {
                    //$rootScope.$broadcast('hideLoadingContenuEvenementFormulaire');
                    ModalsService.alertErreur(msg);
                    globalDeferred.reject(msg);
                  })
                  .finally(function () {
                    ctrl.penddingSave = false;
                    ctrl.loading = false;
                  });
              }
            }
            // Sinon update
            else {
              ctrl.loading = true;
              //$rootScope.$broadcast('showLoadingContenuEvenementFormulaire');
              ctrl.objetDevis
                .updateDevisWithProxyDevis(ctrl.evenement)
                .then(function (listDevis) {
                  // Ferme le manager devis
                  if (closeManager) closeManagerDevis(false, true);
                  globalDeferred.resolve();
                })
                .catch(function (msg) {
                  //$rootScope.$broadcast('hideLoadingContenuEvenementFormulaire');
                  ModalsService.alertErreur(msg);
                  globalDeferred.reject(msg);
                })
                .finally(function () {
                  ctrl.penddingSave = false;
                  ctrl.loading = false;
                });
            }
          })
          .finally(function(){
            //ctrl.documentDevisDrop = null;
            ctrl.documentsDevisDropTmp = [];
            ctrl.documentsFactureDropTmp = [];
            ctrl.autreDocumentsDropTmp = [];
          });
      }
      catch(err) {
        ctrl.penddingSave = false;
        if(!ctrl.newTopic) ModalsService.alertErreur(err.message);
        globalDeferred.reject(err.message);
      }
      return globalDeferred.promise;
    }*/
    function saveDevis(closeManager) {
      if(!ctrl.newTopic) ctrl.penddingSave = true;
      var globalDeferred = $q.defer();
      try {
        checkFormulaire();

        // Si il y a des documents facture ajouté depuis le widget des documents
        if(ctrl.documentsDevisDropTmp.length){
          _.forEach(ctrl.documentsDevisDropTmp, function(doc) {
            ctrl.objetDevis.addDocumentDevis(angular.copy(doc));
          });
        }

        // Si il y a des documents ajouté depuis le widget des documents
        if(ctrl.autreDocumentsDropTmp.length){
          // Parcour les autres documents temporaire déposé depuis le widget
          _.forEach(ctrl.autreDocumentsDropTmp, function(doc) {
            ctrl.objetDevis.addDocument(angular.copy(doc));
          });
        }

        // Si il y a des documents facture ajouté depuis le widget des documents
        if(ctrl.documentsFactureDropTmp.length) {
          if (!ctrl.objetDevis.facture) ctrl.objetDevis.facture = ContenuService.initContenuEvenementFacture();
          _.forEach(ctrl.documentsFactureDropTmp, function (doc) {
            ctrl.objetDevis.facture.addDocument(angular.copy(doc));
          });
        }

        uploadDocs()
          .then(function () {

            if (ctrl.documentsEvenementPartage) {
              // Parcour les documets qui doivent être partagé
              angular.forEach(ctrl.documentsEvenementPartage, function (bool, guid) {
                if (bool) {
                  // Parcour les documents de l'événement
                  for (var i = 0; i < ctrl.evenement.listeDocument.length; i++) {
                    var doc = ctrl.evenement.listeDocument[i];

                    if (guid === doc.getGuid()) {

                      var canAdd = true;
                      // Parcour les documents du devis
                      for (var d = 0; d < ctrl.objetDevis.listeDocument.length; d++) {
                        // Si il existe déjà on ne l'ajoute pas
                        if (ctrl.objetDevis.listeDocument[d].getGuid() === doc.getGuid()) {
                          canAdd = false;
                        }
                      }

                      // Si pas encore dans le tableau alors on l'ajoute
                      if (canAdd) {
                        ctrl.objetDevis.listeDocument.push(angular.copy(doc));
                      }
                    }
                  }
                } else {
                  // Parcour les documents du devis déjà présent pour supprimer ceux qui ne sont plus coché
                  for (var e = 0; e < ctrl.objetDevis.listeDocument.length; e++) {
                    if (guid === ctrl.objetDevis.listeDocument[e].getGuid()) {
                      ctrl.objetDevis.listeDocument.splice(e, 1);
                    }
                  }
                }
              });
            }

            // Si il y a des destinataires dans l'objet devis
            // Déménagé dans le service
            //if (ctrl.objetDevis.destinataires.length) {
            //    ctrl.objetDevis.listeDestinataires = [];
            //    for (var d = 0; d < ctrl.objetDevis.destinataires.length; d++) {
            //        ctrl.objetDevis.listeDestinataires.push(ctrl.objetDevis.destinataires[d]);
            //    }
            //ctrl.objetDevis.destinataires = [];
            //}
            //console.log(ctrl.objetDevis);

            if(_.isObject(ctrl.signatureSelected) && ctrl.signatureSelected.hasOwnProperty('value')) ctrl.objetDevis.signature = ctrl.signatureSelected.value;

            if(_.isObject(ctrl.relanceAutoConfig)) ctrl.objetDevis.setRelanceAutoConfig(ctrl.relanceAutoConfig);

            // Si nouveau devis
            if (_.isNil(ctrl.objetDevis.idContenu) || !ctrl.objetDevis.idContenu) {

              ctrl.objetDevis.listeDestinatairesMessageGravitants = [];

              // Si le bloc gravitant est affiché et qu'il y a des gravitants cochés
              if(ctrl.displayCommunication() && ctrl.gravitantsChecked.length)  {
                for(var g = 0; g < ctrl.gravitantsChecked.length; g++){
                  if(ctrl.gravitantsChecked[g].groupeCollab) ctrl.objetDevis.listeDestinatairesMessageGravitants.push(ctrl.gravitantsChecked[g].groupeCollab);
                }
              }

              if(!ctrl.objetDevis.listeDestinatairesMessageGravitants.length) delete ctrl.objetDevis.sendOneMailWithAllGravitants;

              // Si pas de destinataires dans la liste des autres destinataire de la demande
              // Déménagé dans le service
              //if (!ctrl.objetDevis.listeDestinatairesMessageGravitants.length) {
              //    ctrl.objetDevis.messageGravitants = '';
              //}

              if(ctrl.newTopic) globalDeferred.resolve(ctrl.objetDevis);
              else {
                ctrl.loading = true;
                // $rootScope.$broadcast('showLoadingContenuEvenementFormulaire');
                ctrl.evenement
                  .createDevis(ctrl.objetDevis)
                  .then(function () {
                    // Ferme le manager devis
                    if (closeManager) closeManagerDevis();
                    globalDeferred.resolve();
                  })
                  .catch(function (msg) {
                    //$rootScope.$broadcast('hideLoadingContenuEvenementFormulaire');
                    ModalsService.alertErreur(msg);
                    globalDeferred.reject(msg);
                  })
                  .finally(function () {
                    ctrl.penddingSave = false;
                    ctrl.loading = false;
                  });
              }
            }
            // Sinon update
            else {
              ctrl.loading = true;
              //$rootScope.$broadcast('showLoadingContenuEvenementFormulaire');
              ctrl.objetDevis
                .updateDevisWithProxyDevis(ctrl.evenement)
                .then(function (listDevis) {
                  // Ferme le manager devis
                  if (closeManager) closeManagerDevis(false, true);
                  globalDeferred.resolve();
                })
                .catch(function (msg) {
                  //$rootScope.$broadcast('hideLoadingContenuEvenementFormulaire');
                  ModalsService.alertErreur(msg);
                  globalDeferred.reject(msg);
                })
                .finally(function () {
                  ctrl.penddingSave = false;
                  ctrl.loading = false;
                });
            }
          })
          .catch(function(err){
            ErreurCollabService.onResponseError(err);
            ctrl.penddingSave = false;
          })
          .finally(function(){
            //ctrl.documentDevisDrop = null;
            ctrl.documentsDevisDropTmp = [];
            ctrl.documentsFactureDropTmp = [];
            ctrl.autreDocumentsDropTmp = [];
          });
      }
      catch(err) {
        ctrl.penddingSave = false;
        if(!ctrl.newTopic) ModalsService.alertErreur(err.message);
        globalDeferred.reject(err.message);
      }
      return globalDeferred.promise;
    }

    function closeManagerDevis(withoutEvenement, withContenu) {

      var obj = {action: 'closeManagerDevis'};

      if(!withoutEvenement) obj.evenement = ctrl.evenement
      if(withContenu) obj.contenu = angular.copy(ctrl.objetDevis);
      ctrl.objetDevis = false;
      ctrl.onEmit({
        obj: obj
      });
    }

    function onClickOpenCommunication() {
      ctrl.openCommunication = !ctrl.openCommunication;
    }

    function onChangeCopyDocumentFromEvent() {

      /*
       if(!ctrl.documentsEvenementPartage[document.getGuid()]){
       delete ctrl.documentsEvenementPartage[document.getGuid()];
       }*/
    }

    var prevTemplateDescriptionDevis = false,
      prevObjetTemplateDescriptionDevis,
      prevTemplateMessageGravitants = false,
      prevObjetTemplateMessageGravitants;

    /**
     * Lors de la selection du model dans le champ select des template
     */
    function onChangeTemplate(typeChamp) {


      /*
       if(angular.isNumber(index)){
       if(ctrl.templatesMessage.length<=(index+1)){
       ctrl.currentTemplateMessage = ctrl.templatesMessage[index]; // Preselectione le 1er modèle
       }
       }
       else if(angular.isObject(index)){
       if(index.hasOwnProperty('idModuleValeurParams')){
       // Si il y a des model event
       if(ctrl.templatesMessage.length) {
       // Pour chaque model
       for (var i = 0; i < ctrl.templatesMessage.length; i++) {
       // Si le model de la boucle en cours est égale à l'id du model demandé
       if(ctrl.templatesMessage[i].idModuleValeurParams===index.idModuleValeurParams){
       ctrl.currentTemplateMessage = ctrl.templatesMessage[i];
       break;
       }
       }
       }
       }
       }*/

      if (typeChamp === 'descDevis') {

        if (!prevTemplateDescriptionDevis || prevTemplateDescriptionDevis.objet == '') {
          prevObjetTemplateDescriptionDevis = angular.copy(ctrl.objetDevis.descriptionDevis);
        }

        // Si un model est selectionné
        if (angular.isObject(ctrl.currentTemplateMessage)) {
          // Si il y a des model event
          if (ctrl.templatesMessage.length) {
            // Pour chaque model
            for (var m = 0; m < ctrl.templatesMessage.length; m++) {
              var currentModel = ctrl.templatesMessage[m];


              // Si le model de la boucle en cours est égale au model choisi
              if (ctrl.currentTemplateMessage.idModuleValeurParams === currentModel.idModuleValeurParams) {

                // Si il y a un objet
                if (currentModel.objet !== '') {
                  ctrl.objetDevis.descriptionDevis = angular.copy(currentModel.objet);
                }
                else {
                  // Si il y un model choisi avant de changer
                  if (angular.isObject(prevTemplateDescriptionDevis)) {
                    // Si ce model avait un objet prédéfini
                    if (prevTemplateDescriptionDevis.objet !== '') {
                      if (prevObjetTemplateDescriptionDevis) {
                        ctrl.objetDevis.descriptionDevis = prevObjetTemplateDescriptionDevis;
                      }
                      else {
                        ctrl.objetDevis.descriptionDevis = '';
                      }
                    }
                  }
                }
                break;
              }
            }
          }
        }
        // Siil y avait un model choisi avant de changer
        else if (angular.isObject(prevTemplateDescriptionDevis) && prevTemplateDescriptionDevis.objet !== '') {

          if (prevObjetTemplateDescriptionDevis) {
            ctrl.objetDevis.descriptionDevis = prevObjetTemplateDescriptionDevis;
          }
          else {
            ctrl.objetDevis.descriptionDevis = '';
          }
        }
        prevTemplateDescriptionDevis = angular.copy(ctrl.currentTemplateMessage);
      }
      else if (typeChamp === 'messageGravitants') {

        if (!prevTemplateMessageGravitants || prevTemplateMessageGravitants.objet == '') prevObjetTemplateMessageGravitants = angular.copy(ctrl.objetDevis.messageGravitants);

        // Si un model est selectionné
        if (angular.isObject(ctrl.currentTemplateMessageGravitants)) {
          // Si il y a des model event
          if (ctrl.templatesMessage.length) {
            // Pour chaque model
            for (var n = 0; n < ctrl.templatesMessage.length; n++) {
              var currentModelGravitant = ctrl.templatesMessage[n];

              // Si le model de la boucle en cours est égale au model choisi
              if (ctrl.currentTemplateMessageGravitants.idModuleValeurParams === currentModelGravitant.idModuleValeurParams) {

                // Si il y a un objet
                if (currentModelGravitant.objet !== '') {
                  ctrl.objetDevis.messageGravitants = angular.copy(currentModelGravitant.objet);
                }
                else {
                  // Si il y un model choisi avant de changer
                  if (angular.isObject(prevTemplateMessageGravitants)) {
                    // Si ce model avait un objet prédéfini
                    if (prevTemplateMessageGravitants.objet !== '') {
                      if (prevObjetTemplateMessageGravitants) {
                        ctrl.objetDevis.messageGravitants = prevObjetTemplateMessageGravitants;
                      }
                      else {
                        ctrl.objetDevis.messageGravitants = '';
                      }
                    }
                  }
                }
                break;
              }
            }
          }
        }
        else if (angular.isObject(prevTemplateMessageGravitants) && prevTemplateMessageGravitants.objet !== '') {
          if (prevObjetTemplateMessageGravitants) ctrl.objetDevis.messageGravitants = prevObjetTemplateMessageGravitants;
          else ctrl.objetDevis.messageGravitants = '';
        }

        prevTemplateMessageGravitants = angular.copy(ctrl.currentTemplateMessageGravitants);
      }

    }

    function onClickChangeDemandeDevisActive() {
      ctrl.objetDevis.setDemandeDevisActive(true);
      checkDisplayOptions();
    }

    /**
     * Lorsque l'on clique sur la checkbox pour switcher entre le devis et l'intervention
     */
    function onChangeDemandeDevisActive(){
      checkDisplayOptions();
    }

    function checkDisplayOptions(){
      // Si le contenu existe déjà, du coup c'est un update
      if(ctrl.objetDevis.idContenu) {
        ctrl.showOptions = false;
        // Si demandeActive actuel est différent de celui recupéré lors du chargement du composant alors on change le type du contenu
        // Du coup il faut pouvoir renvoyer les nouveau doc regénérés
        if(ctrl.objetDevis.demandeDevisActive !== oldDemandeDevisActive) {
          ctrl.typeDemandeChanged = true;
          ctrl.showCheckboxSendDocByMail = true;
        }
        else {
          ctrl.typeDemandeChanged = false;
          // Cache la case à cocher pour envoyer les doc par email
          ctrl.showCheckboxSendDocByMail = false;

          // Décoche la case pour envoyer les doc par email
          if(ctrl.objetDevis.sendAttachementsWhenCreateDevis && noSendDocumentParam) ctrl.objetDevis.sendAttachementsWhenCreateDevis = false;

          // Si les destinataires on changés par rapport à avant (càd qu'il n'y en avait pas avant mais maintenant oui)
          if(!oldDestinataires.length && ctrl.objetDevis.destinataires.length) {
            ctrl.showCheckboxSendDocByMail = true;
          }
          else ctrl.showCheckboxSendDocByMail = false;
        }

        // TODO: Si il y a "relanceAutoConfig" alors cocher la case et afficher le paramétrage de la relance auto
      }
      else {
        ctrl.showOptions = true;

        // Si il y a des destinataires
        if(ctrl.objetDevis.destinataires.length) {
          // Affiche la case à cocher pour envoyer les doc par email car pas de destinataire encore
          ctrl.showCheckboxSendDocByMail = true;

          // Si nouveau devis et qu'il y a plus d'un destinataire
          if(ctrl.objetDevis.destinataires.length > 1) {
            // Clear les pièces jointe ajoutées pour le devis
            if(_.isObject(ctrl.uploaderDevis.uploader)) ctrl.uploaderDevis.uploader.clearQueue();
            // Clear les pièces jointe ajoutées pour la facture
            if(_.isObject(ctrl.uploaderDevisFacture.uploader)) ctrl.uploaderDevisFacture.uploader.clearQueue();
          }
        }
        // Cache la case à cocher pour envoyer les doc par email car pas de destinataire encore
        else {
          ctrl.showCheckboxSendDocByMail = false;
          // Décoche la case pour envoyer les doc par email
          if(ctrl.objetDevis.sendAttachementsWhenCreateDevis && noSendDocumentParam) ctrl.objetDevis.sendAttachementsWhenCreateDevis = false;
        }
      }


      // Si demande active à false donc intervention
      if(!ctrl.objetDevis.demandeDevisActive && _.isObject(ctrl.uploaderDevis.uploader)){
        // Clear les pièces jointe ajoutées pour le devis
        ctrl.uploaderDevis.uploader.clearQueue();
      }

      if(ctrl.showCheckboxSendDocByMail || !ctrl.hasDocumentAttestationTvaSimple) ctrl.showOptions = true;
    }

    /**
     * Lors de l'activation/désactivation des relances auto
     */
    function onChangeEnableRelanceAuto(){

      // Si on desactive
      if(!ctrl.relanceAutoEnabled) {
        if(_.isObject(ctrl.relanceAutoConfig)) {
          ctrl.relanceAutoConfig = null;
          //ctrl.relanceAutoConfig.setPeriode(null);
        }
      }
      // Si on active
      else {
        initRelanceAutoConfig();
        ctrl.relanceAutoConfig.setEnabled(true);
      }
    }

    /**
     * Lors de la modification
     * @param objUpdate
     * @returns {IPromise<T>}
     */
    function onUpdatePiecesJointes(objUpdate) {

      //console.log(objUpdate);
      //  var objetUpdate = {'objUpdate':objUpdate};
      try {
        if (!objUpdate) throw new Error('Il manque l\'objet "objUpdate" !');

        //console.log(objUpdate.contenu);

        if (objUpdate.contenu.isFacture) {
          // Si action delete
          if (objUpdate.hasOwnProperty('action') && objUpdate.action === 'delete') {
            ctrl.objetDevis.setFacture(null);
          }
          // Update le contenu venant des pièces jointes
          // ctrl.objetDevis.facture = objUpdate.contenu;
          //ctrl.objetDevis.facture.setUpdate(true);
        }
        else {
          // Update le contenu venant des pièces jointes
          //ctrl.objetDevis = objUpdate.contenu;
        }


        if (objUpdate.saveAuto) {
          saveDevis();
        }

      }
      catch (err) {
        console.log('[ManageDevis.onUpdatePiecesJointes] Erreur : ' + err.message);
      }
    }

    function onEmitFromDocuments(obj, listDocsOrDoc){
      try {
        if (_.isNil(obj) || !_.isObject(obj)) throw new Error('Il manque l\'objet "obj" !');

        if(_.isObject(obj.document)){
          if(obj.action === 'delete') {
            // Si listDocsOrDoc est un array
            if(_.isArray(listDocsOrDoc) && listDocsOrDoc.length) {

              var indexFound = null;
              for(var i = 0; i < listDocsOrDoc.length; i++){
                if(_.isObject(listDocsOrDoc[i]) && listDocsOrDoc[i].guid === obj.document.guid) {
                  indexFound = i;
                  break;
                }
              }
              if(!_.isNil(indexFound)) listDocsOrDoc.splice(indexFound, 1);
            }
            // Sinon si c'est un objet (document ou contenu)
            else if(!_.isArray(listDocsOrDoc) && _.isObject(listDocsOrDoc)) {
              // Si c'est un document
              if(!_.isNil(listDocsOrDoc.guid)){
                if(listDocsOrDoc.guid === obj.document.guid) {
                  listDocsOrDoc = null;
                }
              }
              // Sinon si il y a une propriété et qu'elle existe dans l'objet listDocsOrDoc
              else if(!_.isNil(obj.property) && _.isObject(listDocsOrDoc[obj.property])) {

                if(!_.isNil(listDocsOrDoc[obj.property])){

                  if(_.isArray(listDocsOrDoc[obj.property])){
                    var newList = [];
                    for(var i = 0; i < listDocsOrDoc[obj.property].length; i++){
                      if(_.isObject(listDocsOrDoc[obj.property][i]) && listDocsOrDoc[obj.property][i].guid !== obj.document.guid) {
                        newList.push(listDocsOrDoc[obj.property][i]);
                      }
                    }
                    listDocsOrDoc[obj.property] = angular.copy(newList);
                  }
                  // Si il y a un guid
                  else if(!_.isNil(listDocsOrDoc[obj.property].guid)){
                    if(listDocsOrDoc[obj.property].guid === obj.document.guid) {
                      listDocsOrDoc[obj.property] = null;
                    }
                  }
                  // Sinon si c'est un contenu facture
                  else if(listDocsOrDoc[obj.property].isFacture){
                    var newListFacture = [];
                    for(var i = 0; i < listDocsOrDoc[obj.property].listeDocument.length; i++){
                      if(_.isObject(listDocsOrDoc[obj.property].listeDocument[i]) && listDocsOrDoc[obj.property].listeDocument[i].guid !== obj.document.guid) {
                        newListFacture.push(listDocsOrDoc[obj.property].listeDocument[i]);
                      }
                    }
                    if(newListFacture.length){
                      listDocsOrDoc[obj.property].listeDocument = angular.copy(newListFacture);
                    }
                    else {
                      listDocsOrDoc.setFacture(null);
                    }
                    ctrl.factureIsDeleted = true;
                  }
                }
              }
            }
          }
        }
        console.log(listDocsOrDoc);

      }
      catch (err) {
        console.log('[ManageDevis.onEmitFromDocuments] Erreur : ' + err.message);
      }
    }

    /**
     * Permet de savoir si on doit afficher le boc de communication avec les gravitants
     */
    function displayCommunication(){
      var display = false;

      // Si le contenu n'a pas d'idContenu ET (que ce n'est pas un nouveau topic OU c'est un un nouveau topic avec un tag Origine)
      if(!ctrl.objetDevis.idContenu && (!ctrl.newTopic || (ctrl.newTopic && ctrl.tagOrigine)))
        display = true;

      // Cache si il y a un devis
      if((_.isObject(ctrl.uploaderDevis.uploader) && ctrl.uploaderDevis.uploader.queue.length) || ctrl.documentsDevisDropTmp.length)
        display = false;

      // Cache si il y a une facture
      if((_.isObject(ctrl.uploaderDevisFacture.uploader) && ctrl.uploaderDevisFacture.uploader.queue.length) || ctrl.documentsFactureDropTmp.length)
        display = false;

      return display;
    }

    function checkIfCanShowSendOneMailWithAllDest(listeDest){
      if(!COLLAB_CONF.ENABLE_SEND_MAIL_CC) {
        ctrl.showSendOneMailWithAllGravitants = false;
        ctrl.objetDevis.sendOneMailWithAllGravitants = null;
      }
      else {
        ctrl.showSendOneMailWithAllGravitants = UtilsService.checkIfCanShowSendOneMailWithAllDest(listeDest);
        if(!ctrl.showSendOneMailWithAllGravitants) ctrl.objetDevis.sendOneMailWithAllGravitants = false;
        // Si l'envoie en copie n'a pas été changé par l'user alors on met la valeur du param par défaut
        if(!ctrl.sendOneMailWithAllDestChanged) ctrl.objetDevis.sendOneMailWithAllGravitants = angular.copy(ctrl.paramSendOneMailForAllDest);
      }
    }

    /*
    function emitDevis(){
        if(ctrl.onEmit){
            ctrl.onEmit({
                obj: {
                    devis: ctrl.objetDevis
                }
            });
        }
    }
    */

    function onEmitSelectSignatureContenu(obj){
      try {
        if (!obj) throw new Error('Pas d\'obj !');
        if (angular.isObject(obj) && obj.hasOwnProperty('action') && obj.hasOwnProperty('signature')) {
          if(obj.action === 'changeSignature') ctrl.signatureSelected = obj.signature;
        }
      }
      catch (err) {
        console.log('[manageDevis.onEmitSelectSignatureContenu] Erreur : ' + err.message);
      }
    }

    /**
     * Lors que le composant enfant "choose-gravitants" emit une info
     * @param obj
     */
    function onEmitFromChooseGravitants(obj) {
      if(_.isObject(obj)) {
        if(obj.hasOwnProperty('destinataires') && angular.isArray(obj.destinataires)) ctrl.gravitantsChecked = obj.destinataires;
        if(obj.hasOwnProperty('noDest')) ctrl.noGravitants = obj.noDest;
      }
      checkIfCanShowSendOneMailWithAllDest(ctrl.gravitantsChecked);
    }

    /**
     * Lorsque le composant enfant upload-collab pour les devis emit quelque chose
     * @param obj
     */
    function onEmitFromUploadDevisCollab(obj){
      //console.log(obj);
      try {
        if (!obj) throw new Error('Pas de "obj"');

        // Lorsque l'uploader est bien initialisé
        if(obj.action === 'uploaderReady' && _.isObject(obj.uploader))
          ctrl.uploaderDevis.uploader = obj.uploader;

        // Lorsqu'un document est glissé/déposé depuis le widget des docs temporaire
        if(obj.action === 'updateDroppedDoc' && _.isArray(obj.docs)) {
          var msgErreur = null;
          for (var d = 0; d < obj.docs.length; d++) {
            if (!_.isNil(obj.docs[d].typeDocument) && !UtilsService.contains(COLLAB_CONF.TYPE_DOC_ENUMS_ALLOWED_FOR_DEVIS_FACTURE, obj.docs[d].typeDocument)) {
              msgErreur = "Impossible d'ajouter un document de type \"" + obj.docs[d].getLibelleTypeDocument() + "\" en tant que devis";
              break;
            }
          }
          if (!_.isNil(msgErreur)) ModalsService.alertErreur(msgErreur);
          else ctrl.documentsDevisDropTmp = _.unionBy(ctrl.documentsDevisDropTmp, angular.copy(obj.docs), 'guid');
        }

        // Lorsqu'un fichier est bien upload
        if(obj.action === 'onSuccessItem' && _.isObject(obj.fileItem) && _.isObject(obj.response)){

          // Set le nouveau document devis
          var listDoc = DocumentManagerService.getListDocFromRetourUpload(obj);
          if(_.isArray(listDoc) && listDoc.length) ctrl.objetDevis.addDocumentDevis(listDoc[0]);
        }

        // Lors de l'erreur d'upload d'un fichier
        if(obj.action === 'onErrorItem') {
          obj.title = "Problème lors de l'upload du devis";
          if(_.isObject(obj.fileItem) && _.isObject(obj.fileItem.file)) obj.title += " \"" + obj.fileItem.file.name + "\"";

          ctrl.uploaderDevis.deferred.reject(obj);
        }

        // Quand tout les upload sont términé
        if(obj.action === 'onCompleteAll') ctrl.uploaderDevis.deferred.resolve();
      }
      catch (err) {
        console.log('[manageDevis.onEmitFromUploadDevisCollab] Erreur : ' + err.message);
      }
    }

    /**
     * Lorsque le composant enfant upload-collab pour les factures emit quelque chose
     * @param obj
     */
    function onEmitFromUploadFactureCollab(obj){
      try {
        if (!obj) throw new Error('Pas de "obj"');

        // Lorsque l'uploader est bien initialisé
        if(obj.action === 'uploaderReady' && _.isObject(obj.uploader))
          ctrl.uploaderDevisFacture.uploader = obj.uploader;

        // Lorsqu'un document est glissé/déposé depuis le widget des docs temporaire
        if(obj.action === 'updateDroppedDoc' && _.isArray(obj.docs)){
          var msgErreur = null;
          for (var d = 0; d < obj.docs.length; d++) {
            if (!_.isNil(obj.docs[d].typeDocument) && !UtilsService.contains(COLLAB_CONF.TYPE_DOC_ENUMS_ALLOWED_FOR_DEVIS_FACTURE, obj.docs[d].typeDocument)) {
              msgErreur = "Impossible d'ajouter un document de type \"" + obj.docs[d].getLibelleTypeDocument() + "\" en tant que facture";
              break;
            }
          }
          if (!_.isNil(msgErreur)) ModalsService.alertErreur(msgErreur);
          else ctrl.documentsFactureDropTmp = _.unionBy(ctrl.documentsFactureDropTmp, angular.copy(obj.docs), 'guid');
        }

        // Lorsqu'un fichier est bien upload
        if(obj.action === 'onSuccessItem' && _.isObject(obj.fileItem) && _.isObject(obj.response)){

          if (!ctrl.objetDevis.facture) ctrl.objetDevis.facture = ContenuService.initContenuEvenementFacture();

          // Set le nouveau document devis
          var listDoc = DocumentManagerService.getListDocFromRetourUpload(obj);
          if(_.isArray(listDoc) && listDoc.length &&  _.isObject(ctrl.objetDevis.facture)) {
            ctrl.objetDevis.facture.addDocument(listDoc[0]);
            ctrl.objetDevis.facture.setUpdate(true);
          }
        }

        // Lors de l'erreur d'upload d'un fichier
        if(obj.action === 'onErrorItem') {
          obj.title = "Problème lors de l'upload de la facture";
          if(_.isObject(obj.fileItem) && _.isObject(obj.fileItem.file)) obj.title += " \"" + obj.fileItem.file.name + "\"";

          ctrl.uploaderDevisFacture.deferred.reject(obj);
        }

        // Quand tout les upload sont términé
        if(obj.action === 'onCompleteAll') ctrl.uploaderDevisFacture.deferred.resolve();
      }
      catch (err) {
        console.log('[manageDevis.onEmitFromUploadFactureCollab] Erreur : ' + err.message);
      }
    }

    /**
     * Lorsque le composant enfant upload-collab pour les autres doc emit quelque chose
     * @param obj
     */
    function onEmitFromUploadAutresCollab(obj){
      try {
        if (!obj) throw new Error('Pas de "obj"');

        // Lorsque l'uploader est bien initialisé
        if(obj.action === 'uploaderReady' && _.isObject(obj.uploader))
          ctrl.uploaderDevisAutreDocument.uploader = obj.uploader;

        // Lorsqu'un document est glissé/déposé depuis le widget des docs temporaire
        if(obj.action === 'updateDroppedDoc' && _.isArray(obj.docs))
          ctrl.autreDocumentsDropTmp = _.unionBy(ctrl.autreDocumentsDropTmp, angular.copy(obj.docs), 'guid');

        // Lorsqu'un fichier est bien upload
        if(obj.action === 'onSuccessItem' && _.isObject(obj.fileItem) && _.isObject(obj.response)){
          // Set le nouveau document devis
          var listDoc = DocumentManagerService.getListDocFromRetourUpload(obj);
          if(_.isArray(listDoc) && listDoc.length) ctrl.objetDevis.addDocument(listDoc[0]);
        }

        // Lors de l'erreur d'upload d'un fichier
        if(obj.action === 'onErrorItem') {
          obj.title = "Problème lors de l'upload du document";
          if(_.isObject(obj.fileItem) && _.isObject(obj.fileItem.file)) obj.title += " \"" + obj.fileItem.file.name + "\"";

          ctrl.uploaderDevisAutreDocument.deferred.reject(obj);
        }

        // Quand tout les upload sont términé
        if(obj.action === 'onCompleteAll') ctrl.uploaderDevisAutreDocument.deferred.resolve();

      }
      catch (err) {
        console.log('[manageDevis.onEmitFromUploadAutresCollab] Erreur : ' + err.message);
      }
    }

  }
})();
