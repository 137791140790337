(function() {

  'use strict';

  /**
   * @desc Composant d'upload
   * @example <upload-collab-queue></upload-collab-queue>
   */
  angular
    .module('collaboreApp')
    .component('uploadCollabQueue',{
      templateUrl: 'app/components/upload-collab/upload-collab-queue.component.html',
      controllerAs: 'uploadcollabqueuectrl',
      bindings: {
        uploader: '<',
        documentsDroppedFromDocs: '<',
        onEmit: '&'
      },
      controller: UploadCollabQueueCtrl
    });

  /** @ngInject */
  function UploadCollabQueueCtrl(){
    var _this = this;

    _this.removeDocument = removeDocument;

    _this.$onInit = function() {
      if(_.isNil(_this.documentsDroppedFromDocs)) _this.documentsDroppedFromDocs = [];
    };
    _this.$onChanges = function(changes) {
      if(_.isObject(changes.uploader) && !changes.uploader.isFirstChange()) _this.uploader = changes.uploader.currentValue;
      if(_.isObject(changes.documentsDroppedFromDocs) && !changes.documentsDroppedFromDocs.isFirstChange()) _this.documentsDroppedFromDocs = changes.documentsDroppedFromDocs.currentValue;
    };
    _this.$onDestroy = function() {};

    /**
     * Retire un document de la liste des docs
     * @param document
     */
    function removeDocument(document){
      if (_.isObject(document)) {
        var objEmit = {
          action: 'remove',
          document: document
        };
        if(_.isObject(document.file)) document.remove();
        else {
          _.remove(_this.documentsDroppedFromDocs, function(doc){
            return (document.getGuid() === doc.getGuid());
          });
          objEmit.docs = _this.documentsDroppedFromDocs;
        }
        emit(objEmit);
      }
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      if(_.isObject(objRetour)) objRetour = _.merge(objRetour, {uploader: _this.uploader});
      _this.onEmit({obj: objRetour});
    }

  }
})();
