(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.InfosReportingCamping
   * @description
   * # InfosReportingCamping
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('InfosReportingCamping', InfosReportingCampingModel);

  /** @ngInject */
  function InfosReportingCampingModel(Main, Portefeuille) {

    function InfosReportingCamping(data) {
      Main.apply(this, arguments);
      this.hydrate(data);
    }

    InfosReportingCamping.prototype = {

      model: 'InfosReportingCamping',
      InfosReportingCamping: true,

      // j
      getJ: function(){
        return (!_.isNil(this.j)) ? parseInt(this.j) : "-";
      },
      setJ: function(val){
        this.j = val;
      },

      // jMoins1
      getJMoins1: function(){
        return (!_.isNil(this.jMoins1)) ? parseInt(this.jMoins1) : "-";
      },
      setJMoins1: function(val){
        this.jMoins1 = val;
      },

      // jMoins2
      getJMoins2: function(){
        return (!_.isNil(this.jMoins2)) ? parseInt(this.jMoins2) : "-";
      },
      setJMoins2: function(val){
        this.jMoins2 = val;
      },

      // jMoins3
      getJMoins3: function(){
        return (!_.isNil(this.jMoins3)) ? parseInt(this.jMoins3) : "-";
      },
      setJMoins3: function(val){
        this.jMoins3 = val;
      },

      // jMoins4
      getJMoins4: function(){
        return (!_.isNil(this.jMoins4)) ? parseInt(this.jMoins4) : "-";
      },
      setJMoins4: function(val){
        this.jMoins4 = val;
      },

      // jMoins5
      getJMoins5: function(){
        return (!_.isNil(this.jMoins5)) ? parseInt(this.jMoins5) : "-";
      },
      setJMoins5: function(val){
        this.jMoins5 = val;
      },

      // jMoins6
      getJMoins6: function(){
        return (!_.isNil(this.jMoins6)) ? parseInt(this.jMoins6) : "-";
      },
      setJMoins6: function(val){
        this.jMoins6 = val;
      },

      // jMoins7
      getJMoins7: function(){
        return (!_.isNil(this.jMoins7)) ? parseInt(this.jMoins7) : "-";
      },
      setJMoins7: function(val){
        this.jMoins7 = val;
      },

      // jMoins7EtMoins14
      getJMoins7EtMoins14: function(){
        return (!_.isNil(this.jMoins7EtMoins14)) ? parseInt(this.jMoins7EtMoins14) : "-";
      },
      setJMoins7EtMoins14: function(val){
        this.jMoins7EtMoins14 = val;
      },

      // jMoins14EtMoins30
      getJMoins14EtMoins30: function(){
        return (!_.isNil(this.jMoins14EtMoins30)) ? parseInt(this.jMoins14EtMoins30) : "-";
      },
      setJMoins14EtMoins30: function(val){
        this.jMoins14EtMoins30 = val;
      }
    };

    angular.extend(InfosReportingCamping.prototype, Main.prototype);

    return InfosReportingCamping;

  }
})();
