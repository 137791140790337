'use strict';


var listeTags = function ($parse) {
	var directiveDefinitionObject = {
		restrict: 'EA',
		templateUrl : 'app/scripts/tags/directives/listeTags.html',
		replace: true,
		scope: {
			nbDisplayTags: '@nbVisible',	// <String> Nombre de tags à afficher
			item: '=',						// <Object> Contenu
			tags: '=',						// <Array> Liste des tags
			onClickTag: '&',				// <Function> Fonction à exectuer lors du clique sur le libelle tu tag
			allowDelete: '=',				// <Boolean> Affiche/Cache le bouton delete des tags
			libelle: '@'					// <String> Libelle placé devant la liste des tags
		},
		link: function(scope, element, attrs){
			if(angular.isDefined(attrs.onClickTag)){
				scope.allowClick = true;
			}
			if(angular.isDefined(attrs.nbVisible)){
				scope.nbDisplayTags = $parse(attrs.nbVisible)(scope);
			}
			//scope.allowClick = 'onClickTag' in attrs;
		},
		controller: ['COLLAB_CONF','$scope','$filter','sweet', function(COLLAB_CONF,$scope,$filter,sweet) {

			var vm = this;


			vm.clickTag = function(tag){

				if($scope.allowClick){

					$scope.onClickTag()(tag);
				}
			};

			vm.canDelete = function(tag){
				//tag.tagEvenement
				var isEditable = true;
				if(angular.isObject($scope.item)){

					// Si topic
					if($scope.item.hasOwnProperty('idTopic')){
						// Si il y a un événement dans le topic
						if($scope.item.eventPresent){
							var contenuEvenement = $scope.item.getEvenement();
							//isEditable = $filter('isEditable')(contenuEvenement);
							isEditable = contenuEvenement.isModifiable();
						}
						// Si contenu
					}else{
						// Si contenu de type événement
						if($scope.item.isEvenement){
							//isEditable = $filter('isEditable')($scope.item);
							isEditable = $scope.item.isModifiable();
						}
					}

				}
				// Si c'est un tag d'un événement et que l'user peut editer l'événement
				// OU
				// Si ce n'est pas un tag événement
				if((tag.tagEvenement && isEditable) || !tag.tagEvenement){
					return true;
				}else{
					return false;
				}
			};

			if(!$scope.nbDisplayTags) {$scope.nbDisplayTags = 200;}

			/**
			 * Recupère la liste des destinataire restant qui sont caché
			 * @param {Object} itemContenu - Objet contenu
			 * @returns {String} Liste des destinataires
			 */
			vm.getListAutreTags = function(tags){
				var string = '';
				if(angular.isArray(tags) && $scope.nbDisplayTags!==0){
					for(var i = $scope.nbDisplayTags; i < tags.length; i++){
						string +='<span class="badge badge-bleu-collabore">'+tags[i].libelle+'</span> ';
					}

				}
				//console.log(string);
				return string;
			};

			/**
			 * Supprime un tag de tous les contenus
			 * @param {Object} tag - Objet tag
			 * @returns {void}
			 */
			vm.filtreContenuByTag = function(tag){
				//$scope.filtreByTags.push(tag);
				$scope.$emit('setFilterTags',tag);
			};

			/**
			 * Supprime un tag de tous les contenus
			 * @param {Object} tag - Objet tag
			 * @returns {void}
			 */
			vm.deleteTag = function(Tag){

				sweet.show({
					title: 'Supprimer le tag ?',
					text: '<h4>Voulez-vous vraiment supprimer ce tag ?</h4><div class="alert alert-warning"><i class="fa fa-exclamation-triangle"></i> Ce tag sera supprimé de tous les contenus de ce topic</div>',
					type: 'warning',
					html: true,
					showCancelButton: true,
					confirmButtonColor: '#DD6B55',
					confirmButtonText: 'Oui tous supprimer !',
					closeOnConfirm: false

				},function() {
					// Loading
					sweet.show({
						title: '',
						html: true,
						text: COLLAB_CONF.LOADING
					});

					var idTag = Tag.getIdTag();

					$scope.item.deleteTags(Tag).then(function(){

						$scope.tags = $filter('removeWith')($scope.tags,{idTag:idTag});
						//$scope.tagsFiltre = $filter('removeWith')($scope.tagsFiltre,{idTag:idTag});


						// Emit l'événement 'onDelTagTopic'
						// Provisoir
						// Pour eviter ca il faut que la liste des contenus ne soit pas affiché par vm.contenus mais par Topic.getContenus()
						// Et le filtre par tags doit etre un vrai filtre
						$scope.$emit('onDelTagTopic',idTag);
						//$scope.$emit('onDelTagTopic');

						sweet.show({
							title: 'Tag supprimé !',
							type: 'success',
							timer: 1000,
							showConfirmButton: false
						});

						// Erreur
					},function(msg){
						sweet.show({
							title: 'Erreur !',
							text: msg,
							type: 'error',
							showConfirmButton: true
						});
					});
				});


			};

		}],
		controllerAs: 'listetagsctrl'
	};
	return directiveDefinitionObject;
};

angular.module('collaboreApp').directive('listeTags',[
	'$parse',
	listeTags
]);
