(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name collaboreApp.SinistreService
     * @description
     * # DevisService
     * Service in the collaboreApp.
     */

    angular
        .module('collaboreApp')
        .service('SinistreService', SinistreService);

    /*@ngInject*/
    function SinistreService(MainService, $injector, $q, SinistreAjax, ErreurCollabService, ModalsService, UtilsService) {

        var ctrl = this;

        ctrl.initParamsSinistre = initParamsSinistre;
        ctrl.openDeclarationSinitre = openDeclarationSinitre;
        ctrl.saveSinistre = saveSinistre;

        ctrl.updateFieldByField = updateFieldByField;

        ctrl.genereDeclarationSinistre = genereDeclarationSinistre; // Recupère la liste des NatureSinistre
        ctrl.getListNatureSinistre = getListNatureSinistre; // Recupère la liste des NatureSinistre

        function initParamsSinistre(objetSinistre){

            var params = {};

            if(objetSinistre && angular.isObject(objetSinistre) && objetSinistre.hasOwnProperty('infosSinistre') && angular.isObject(objetSinistre.infosSinistre)) {

                if(objetSinistre.hasOwnProperty('signature') && objetSinistre.signature)  params.signature = objetSinistre.signature;
                else params.signature = false;

                if(!_.isNil(objetSinistre.demandeOptions)){
                  if(!_.isNil(objetSinistre.demandeOptions.hideProprietaireInDocument)) params.hideProprietaireInDocument = objetSinistre.demandeOptions.hideProprietaireInDocument;
                }

                var infosSinistre = objetSinistre.infosSinistre;

                if(infosSinistre.hasOwnProperty('dateSinistre') && infosSinistre.dateSinistre) {
                    params.dateSinistre = moment(infosSinistre.dateSinistre).format('YYYY-MM-DD');
                }
                if(infosSinistre.hasOwnProperty('natureSinistre') && angular.isObject(infosSinistre.natureSinistre)) {
                    if(infosSinistre.natureSinistre.hasOwnProperty('idNatureSinistre') && infosSinistre.natureSinistre.idNatureSinistre) {
                        params.idNatureSinistre = infosSinistre.natureSinistre.idNatureSinistre;
                    }
                    else if(infosSinistre.natureSinistre.hasOwnProperty('idNatureSinistreGlobal') && infosSinistre.natureSinistre.idNatureSinistreGlobal) {
                        params.idNatureSinistreGlobal = infosSinistre.natureSinistre.idNatureSinistreGlobal;
                    }
                    else if(infosSinistre.natureSinistre.hasOwnProperty('metier') && infosSinistre.natureSinistre.metier && infosSinistre.natureSinistre.hasOwnProperty('libelle') && infosSinistre.natureSinistre.libelle){
                        params.metier = infosSinistre.natureSinistre.metier;
                        params.libelle = infosSinistre.natureSinistre.libelle;
                    }
                }

                if(infosSinistre.hasOwnProperty('expert')){
                    if(angular.isObject(infosSinistre.expert)) {
                        var ProxyPersoIcs = $injector.get('ProxyPersoIcs');
                        var proxyPersoIcs = new ProxyPersoIcs(infosSinistre.expert);
                        params.expert = proxyPersoIcs.toJson();
                    }
                    else params.expert = false;
                }

                if(infosSinistre.hasOwnProperty('assureur')) {
                    if(angular.isObject(infosSinistre.assureur)) {
                        var ProxyContratIcs = $injector.get('ProxyContratIcs');
                        var proxyContratIcs = new ProxyContratIcs(infosSinistre.assureur);
                        params.assureur = proxyContratIcs.toJson();
                    }
                    else params.assureur = false;
                }
                if(infosSinistre.hasOwnProperty('commentaire')) {
                    params.commentaire = infosSinistre.commentaire;
                }
                else params.commentaire = false;

              if(infosSinistre.hasOwnProperty('numRar')) {
                params.numRar = infosSinistre.numRar;
              }
              else params.numRar = false;
            }
            return params;
        }

        /**
         * Ouvre le modal de déclaration de sinistre
         * @param objetSinistre
         * @param paramsSup
         * @returns {*}
         */
        function openDeclarationSinitre(objetEvenement, infosEvenement, paramsSup) {
            var deferred = $q.defer();
            var options = {
                backdrop: 'static'
            };

            if(!infosEvenement) infosEvenement = UtilsService.getInfosEvenement(objetEvenement);

            var params = {
                objetSinistre: objetEvenement.getSinistre(),
                infosEvenement: infosEvenement
            };

            if (paramsSup && angular.isObject(paramsSup)) params = angular.merge(params, paramsSup);

            ModalsService
                .modalComponent('modalDeclarerSinistre', params, options)
                .then(function (objet) {
                    saveSinistre(objetEvenement, objet)
                        .then(function(event){
                            deferred.resolve(event);
                        })
                        .catch(function(msg){
                            deferred.reject(msg);
                        });
                })
                .catch(function (msg) {
                    if (msg !== 'cancel') ModalsService.alertErreur(msg);
                    deferred.reject(msg);
                });

            return deferred.promise;
        }

        /**
         * Sauvegarde du sinistre
         * @param objet
         */
        function saveSinistre(objetEvenement, objet) {

            var deferred = $q.defer();
            var modalLoading = ModalsService.loading();

            // Si pas de sinistre dans l'événement
            if (!objetEvenement.hasSinistre()) {
                objetEvenement
                    .createSinistre(objet)
                    .then(function () {
                        deferred.resolve(objetEvenement);
                        modalLoading.close();
                    })
                    .catch(function (msg) {
                        ModalsService.alertErreur(msg);
                        deferred.reject(msg);
                    });
            }
            else {
                objetEvenement
                    .getSinistre()
                    .updateSinistre(objet)
                    .then(function () {
                        deferred.resolve(objetEvenement);
                        modalLoading.close();
                    })
                    .catch(function (msg) {
                        ModalsService.alertErreur(msg);
                        deferred.reject(msg);
                    });
            }
            return deferred.promise;
        }


        function postServlet(action, obj) {
            var deferred = $q.defer();
            try {
                if (!action) throw new Error('Le paramètre "action" n\'existe pas !');

                if (!angular.isObject(obj) && !angular.isNumber(obj)) throw new Error('Le paramètre "obj" doit être un objet un un nombre !');
                if (angular.isObject(obj) && !obj.hasOwnProperty('idSinistre') && !obj.hasOwnProperty('idContenu')) throw new Error('Le paramètre "obj" doit avoir un idSinistre ou idContenu !');

                var sendParams = angular.merge({}, obj);
                sendParams.action = action;
                sendParams.idSinistre = (angular.isNumber(obj)) ? obj : ((obj.hasOwnProperty('idSinistre')) ? obj.idSinistre : obj.idContenu);
                delete sendParams.idContenu;

                SinistreAjax
                    .post(sendParams, function (json) {
                        var ContenuSinistre = $injector.get('ContenuSinistre');
                        deferred.resolve(MainService.convertJsonToModel(json, 'sinistre', ContenuSinistre, deferred));
                    }, function (msg) {
                        deferred.reject(msg);
                    });
            }
            catch (e) {
                ModalsService.alertErreur(e.message);
                deferred.reject(e.message);
            }
            return deferred.promise;
        }

        /**
         * Permet de modifier un evenement champ par champ
         * @param objSinistre
         * @returns {IPromise<T>}
         */
        function updateFieldByField(objSinistre) {
            var deferred = $q.defer();

            var params = initParamsSinistre(objSinistre);
            params.idSinistre = objSinistre.getIdContenu();

            postServlet('update', params)
                .then(function (ret) {
                    deferred.resolve(ret);
                })
                .catch(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function genereDeclarationSinistre(idSinistre) {
            var deferred = $q.defer();
            postServlet('genereDeclarationSinistre', idSinistre)
                .then(function (ret) {
                    deferred.resolve(ret);
                })
                .catch(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function getListNatureSinistre() {
            var deferred = $q.defer();
            var sendParams = {
                action: 'listNatureSinistre'
            };
            SinistreAjax.post(sendParams, function(json) {
                var NatureSinistre = $injector.get('NatureSinistre');
                deferred.resolve(MainService.convertJsonToModel(json, 'NatureSinistres', NatureSinistre));
            },function(error){
                ErreurCollabService.erreurServlet(error, 'Erreur avec SinistreService.getListNatureSinistre()', deferred);
            });

            return deferred.promise;
        }




    }
})();
