(function () {
  'use strict';

  /**
   * @desc Collab fournisseur détail d'une demande
   * @example <collab-fournisseur-detail></collab-fournisseur-detail>
   */

  angular
    .module('collaboreApp')
    .component('collabFournisseurDetail', {
      templateUrl: 'app/collab-fournisseur/collab-fournisseur-detail/collab-fournisseur-detail.component.html',
      controllerAs: 'collabfournisseurdetailctrl',
      bindings: {
        login: '<',
        diese: '<',
        hash: '<',
        idTopic: '<',       // Id du topic
        idContenu: '<',     // Id du contenu qui serait à afficher en arrivant sur la page (Optionnel)
        idFournisseur: '<'  // Id du groupe destinataire de devis pour affichage agence (Optionnel)
      },
      controller: CollabFournisseurDetailController
    });

  /*@ngInject*/
  function CollabFournisseurDetailController($q, $scope, $rootScope, ConfigService, COLLAB_CONF, COLLAB_VALUES, GroupeService, MessageService, UtilsService, TopicStatesService, ModalsService, ContenuService) {

    var _this = this;
    //var nbStep = 5;								    // Nombre d'étapes de base
    //var nbStepContrat = 3;						// Nombre d'étapes de base pour une demande de contrat
    var limitObjetEvenement = 100;     // Limit de caractères pour l'objet événement

    _this.disableFolderLink = false;
    _this.informationsDossier = null;
    _this.origine = null;
    _this.loadingComponent = false;
    _this.errorComponent = null;

    _this.displayInfosEvenement = true;
    _this.infosAgence = null;
    _this.nbDisplayDigicode = 2;      // Nombre de digicode affichés avant le "Voir plus"

    _this.evenementOwner = false;     // Permet de savoir si le composant est ouvert par un destinataire de l'événement
    _this.listDemandes = [];          // Liste des demandes de devis, inter et contrats
    _this.nbDemandeDevisInter = 0;    // Nombre de devis/intervention
    _this.nbDemandeContrat = 0;       // Nombre de contrat

    _this.listMessage = [];

    _this.uploader = {
      config: {
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_CONTENU,
        extensionsAllowed: ['pdf','jpg','png','jpeg','bmp','gif'],
        /*
        whenAddingFile: [
          {idGroupe: UtilsService.getCurrentUser().getIdGroupe()},
          {token: UtilsService.getCurrentUser().getToken()}
        ],*/
        devis: {
          queueLimit: null,
          whenAddingFile: [
            {action: 'addDevis'},
            {convertToPdf: true}
          ]
        },
        facture: {
          queueLimit: 1,
          whenAddingFile: [
            {action: 'addFacture'}
          ]
        },
        contrat: {
          queueLimit: null,
          whenAddingFile: [
            {action: 'addDocumentContrat'},
            {convertToPdf: true}
          ]
        },
        documentsFournisseur:{
          queueLimit: null,
          whenAddingFile: [
            {action: 'addDocumentsFournisseur'}
          ]
        }
      },
      uploaderByIdContenu:{}
    };

    _this.truncate = UtilsService.truncate;
    _this.stripTags = UtilsService.stripTags;
    _this.nl2br = UtilsService.nl2br;

    _this.onClickRefreshDemande = onClickRefreshDemande;						// Lors du clique sur le bouton pour rafraichi la demande
    _this.onClickOpenDemande = onClickOpenDemande;                  // Lors de l'ouverture d'une demande

    // ------------ Devis -------------/
    _this.disableDevis = false;
    _this.openChooseDocumentDevis = openChooseDocumentDevis;        // Permet d'ouvrir le choix des documents pour upload un devis
    _this.onClickSaveDevis = onClickSaveDevis;									    // Lors de la sauvegarde du devis
    _this.onUpdateDocumentDevis = onUpdateDocumentDevis;				    // Lors de l'upload du document devis

    // ------------ Facture -------------/
    _this.disableFacture = false;
    _this.openChooseDocumentFacture = openChooseDocumentFacture;    // Permet d'ouvrir le choix des documents pour upload une facture
    _this.onClickSaveFacture = onClickSaveFacture;								  // Lors de la sauvegarde de la facture

    // ------------ Contrat -------------/
    _this.disableContrat = false;
    _this.openChooseDocumentContrat = openChooseDocumentContrat;    // Permet d'ouvrir le choix des documents pour upload un contrat
    _this.onClickSaveContrat = onClickSaveContrat;								  // Lors de la sauvegarde du Contrat
    _this.onUpdateDocumentContrat = onUpdateDocumentContrat;			  // Lors de l'upload du document Contrat

    // ------------ Documents fournisseur -------------/
    _this.disableDocumentsFournisseur = false;
    _this.hideProprietaire = false;
    _this.allowDeleteDevisForFournisseur = true;
    _this.onClickSaveDocumentsFournisseur = onClickSaveDocumentsFournisseur;  // Lors de la sauvegarde des documents fournisseurs

    // ------------ Commentaire -------------/
    _this.loadingCommentaire = false;
    _this.onClickOpenSendMessage = onClickOpenSendMessage;

    _this.onEmitFromDocuments = onEmitFromDocuments;
    _this.onEmitFromUploadCollab = onEmitFromUploadCollab;
    _this.onEmitFromUploadCollabQueue = onEmitFromUploadCollabQueue;
    _this.onEmitFromAvancementComponent = onEmitFromAvancementComponent;

    _this.$onInit = function () {
      if(_.isNil(_this.idTopic)){
        _this.errorComponent = "Il manque l'id du dossier";
        return;
      }

      if(!_.isNil(_this.diese)){
        var diese = ConfigService.getUserDiese();
        if(!_.isNil(diese) && !_.isEmpty(diese)){
          var dieseFromUrl = angular.copy(_this.diese);
          if(!diese.startsWith("#")) diese = "#" + diese;
          if(!dieseFromUrl.startsWith("#")) dieseFromUrl = "#" + dieseFromUrl;
          if(diese != dieseFromUrl){
            _this.disableFolderLink = true;
          }
        }
      }

      _this.idTopic = parseInt(_this.idTopic);
      if(!_.isNil(_this.idContenu)) _this.idContenu = parseInt(_this.idContenu);
      if(!_.isNil(_this.idFournisseur)) _this.idFournisseur = parseInt(_this.idFournisseur);
      init(_this.idTopic);
      $rootScope.anchorScroll.update(0);
    };

    _this.$onChanges = function (changes) {
      if(_.isObject(changes.idTopic) && !changes.idTopic.isFirstChange() && !_.isNil(changes.idTopic.currentValue)){
        _this.idTopic = parseInt(changes.idTopic.currentValue);
        init(_this.idTopic);
      }
      if(_.isObject(changes.idContenu) && !changes.idContenu.isFirstChange() && !_.isNil(changes.idContenu.currentValue)){
        _this.idContenu = parseInt(changes.idContenu.currentValue);
      }
      if(_.isObject(changes.idFournisseur) && !changes.idFournisseur.isFirstChange() && !_.isNil(changes.idFournisseur.currentValue)){
        _this.idFournisseur = parseInt(changes.idFournisseur.currentValue);
      }
    };

    _this.$onDestroy = function(){
      $rootScope.anchorScroll.init();
      destroyUploader();
    };

    function init(idTopic){
      initInfosFiches();
      callDetailTopic(idTopic);
    }

    function initInfosFiches(){
      _this.infosFiches = {
        lieuIntervention: null,
        origine: null,
        coproprietaire: null,
        proprietaire: null,
        locataire: null,
        colocataire: null,
        digicodes: [],
        digicode: null,
      };
    }

    function callDetailTopic(idTopic){
      _this.loadingComponent = true;
      TopicStatesService
        .getTopicStateByIdTopic(idTopic)
        .then(function (topicState) {
          //console.log(topicState);
          if(_.isObject(topicState) && topicState.isTopicStates) {
            initTopic(topicState.getTopic());
          }
        })
        .catch(function (err){
          console.log(err);
        })
        .finally(function () {
          _this.loadingComponent = false;
        });
    }

    function initTopic(topic){
      if(_.isObject(topic)) {

        _this.topic = topic;

        if(topic.topicInformations){
          if(topic.topicInformations.informationsDossier){
            _this.informationsDossier = topic.topicInformations.informationsDossier;
          }
          if(!_.isNil(topic.topicInformations.deletionDocDevisByFournisseurAllowed)) _this.allowDeleteDevisForFournisseur = topic.topicInformations.deletionDocDevisByFournisseurAllowed;
          if(topic.topicInformations.hideProprietaire) _this.hideProprietaire = true;
        }

        _this.topicIsBlocked = topic.isBlocked;

        // Si ouvert par un destinataire de l'événement
        if (topic.hasEventModifiable()) {
          _this.evenementOwner = true;
          _this.allowDeleteDevisForFournisseur = true;
        }
        // Si ouvert par le fournisseur
        else {
          //topic.lire();

          // Si la page est la page ouverte depuis collab
          //if ($state.is('evenementFournisseur')) _this.isOpenFromCollab = true;

          //WebsocketServices.connectWebsocket();
        }

        if(_.isObject(topic.getTopicInformations()) && _.isObject(topic.getTopicInformations().getPortefeuille()) && _.isObject(topic.getTopicInformations().getPortefeuille().getPortefeuilleInfos())){
          _this.infosAgence = topic.getTopicInformations().getPortefeuille().getPortefeuilleInfos();
        }

        parseObjetFromEvenement(topic);
        initListeDemande(topic.getEvenement());

        _this.listMessage = topic.getContenusMessage();

        if(!_this.hideProprietaire) {
          var tagTypeProprietaire = topic.getEvenement().getTagForType(COLLAB_CONF.TAG_TYPE_PROPRIETAIRE);
          if (tagTypeProprietaire) {
            _this.loadingProprietaire = true;
            var idPortefeuilleProprietaire = tagTypeProprietaire.getPortefeuille().getIdPortefeuille();

            GroupeService
              .searchGroupeByLibelleAndPortefeuilleInMySociete(idPortefeuilleProprietaire, tagTypeProprietaire.getValeurIcs(), topic.getEvenement().getIdContenu())
              .then(function(groupe){
                _this.persoProprietaire = groupe;
              })
              .finally(function(){
                _this.loadingProprietaire = false;
              });
          }
        }
      }
    }

    /**
     * Permet d'extraire des infos à partir de l'objet de l'événement
     * @param evenement
     */
    function parseObjetFromEvenement(topic){
      var evenement = null;
      if(_.isObject(topic)) evenement = topic.getEvenement();

      if(_.isObject(evenement) && evenement.isEvenement){

        var objetSplit = evenement.splitObjet();
        _this.previsualisationMessageEvenement = null;
        if(objetSplit.texteObjet.length > limitObjetEvenement)
          _this.previsualisationMessageEvenement = UtilsService.truncate(UtilsService.stripTags(objetSplit.texteObjet), limitObjetEvenement, '...', true);
        _this.messageEvenement = objetSplit.texteObjet;
        _this.fichesEvenement = objetSplit.texteFiches;
        if(!_.isNil(objetSplit.domFiches)){
          _this.infosFiches.lieuIntervention = evenement.extractLieuInterventionFromFiche(objetSplit.domFiches);
          _this.infosFiches.locataire = evenement.extractLocataireFromFiche(objetSplit.domFiches);
          if(!_this.hideProprietaire) _this.infosFiches.proprietaire = evenement.extractProprietaireFromFiche(objetSplit.domFiches);
          _this.infosFiches.coproprietaire = evenement.extractCoproprietaireFromFiche(objetSplit.domFiches);
          _this.infosFiches.origine = evenement.extractOrigineFromFiche(objetSplit.domFiches, true);
          if(_.isObject(_this.infosFiches.origine) && _this.infosFiches.origine.type === 'proprietaire' && _this.hideProprietaire) {
            _this.infosFiches.origine = undefined;
          }
          _this.infosFiches.digicodes = evenement.extractDigicodesFromFiche(objetSplit.domFiches);
        }
        else if(_.isObject(_this.informationsDossier)){


          if(!_.isNil(_this.informationsDossier.groupeImmeuble) && !_.isNil(_this.informationsDossier.groupeImmeuble.groupeInformations)){
            _this.infosFiches.digicode = _this.informationsDossier.groupeImmeuble.groupeInformations.getDigicode();
          }

          var tagTypeOrigin = evenement.getTagForType(COLLAB_CONF.TAG_TYPE_ORIGINE);
          if(!_.isNil(tagTypeOrigin)){
            var originValeurIcs = tagTypeOrigin.getValeurIcs();
            if(!_.isNil(originValeurIcs)){
              // Si perso
              if(originValeurIcs.length === 6){
                var numProprietaire = (!_.isNil(_this.informationsDossier.groupeProprietaire)) ? _this.informationsDossier.groupeProprietaire.getNumero() : null;
                var numLocataire = (!_.isNil(_this.informationsDossier.groupeLocataire)) ? _this.informationsDossier.groupeLocataire.getNumero() : null;
                var numCoproprietaire = (!_.isNil(_this.informationsDossier.groupeCoproprietaire)) ? _this.informationsDossier.groupeCoproprietaire.getNumero() : null;

                if(numLocataire === originValeurIcs){
                  _this.infosFiches.origine = {
                    libelleType: "Locataire",
                    type: "locataire",
                    groupe: _this.informationsDossier.groupeLocataire
                  };
                }
                else if(numProprietaire === originValeurIcs){
                  _this.infosFiches.origine = {
                    libelleType: "Propriétaire",
                    type: "proprietaire",
                    groupe: _this.informationsDossier.groupeProprietaire
                  };
                }
                else if(numCoproprietaire === originValeurIcs){
                  _this.infosFiches.origine = {
                    libelleType: "Copropriétaire",
                    type: "coproprietaire",
                    groupe: _this.informationsDossier.groupeCoproprietaire
                  };
                }
              }
            }
          }
        }
      }
    }

    /**
     * Permet d'init la liste des demandes
     * @param listeDemande
     */
    function initListeDemande(evenement){
      _this.nbDemandeDevisInter = 0;
      _this.nbDemandeContrat = 0;
      _this.listDemandes = [];
      var listeDemandes = evenement.getListDemande();

      // Si il y a une liste de demande
      if(listeDemandes.length) {
        // Parcour la liste des demandes
        for(var i = 0; i < listeDemandes.length; i++){
          var demande = listeDemandes[i];

          if(!_this.evenementOwner || (_this.evenementOwner && _this.idFournisseur === demande.getDestinataire().getIdGroupe())) {
            initDemande(demande);
            demande.open = (_this.idContenu === demande.getIdContenu());
            _this.listDemandes.push(demande);
          }
        }
      }

      if(!_.isNil(_this.idContenu)){
        UtilsService.scrollToId("demande-" + _this.idContenu);
      }

      //if(_this.listDemandes.length === 1) _this.listDemandes[0].open = true;
    }

    /**
     * Permet d'init chaque demande
     * @param currentDemande
     */
    function initDemande(currentDemande, open){

      currentDemande.currentStep = 0;     // Num étape actuel de la demande

      if(currentDemande.isContenuDevisInter()) {

        _this.nbDemandeDevisInter++;
        //currentDemande.nbStep = angular.copy(nbStep);     // Nombre d'étape de la demande

        /*
        // Recupère les paramétrages
        var defaultParametrage = currentDemande.getExpediteur().getDefaultParametrage();
        if (defaultParametrage) {
          // Recupère le parametrage pour savoir si le fournisseur peux supprimer le devis
          currentDemande.allowDeleteDevisForFournisseur = (defaultParametrage.getDisallowDeleteDevisForFournisseur()) ? false : true;
        }
        // Si la page est ouverte par un destinataire de l'event direct alors rend possible la suppression
        if (_this.evenementOwner) currentDemande.allowDeleteDevisForFournisseur = true;*/

        initUploader(currentDemande, "devis");
        initUploader(currentDemande, "documentsFournisseur");
        initUploader(currentDemande, "facture");

        // Si demande de devis
        if(currentDemande.getDemandeDevisActive()) {
          // Si devis en attente
          if (currentDemande.isWaiting()) {
            currentDemande.currentStep = 0;

            // Si il y a un devis
            if(currentDemande.hasDevis()) currentDemande.currentStep = 1;
          }

          // Si devis accepté
          else if (currentDemande.isAccepte()) {
            currentDemande.currentStep = 2;

            // Si le devis est signé
            if (currentDemande.isSigne()) currentDemande.currentStep = 3;

            // Si le devis est signé et qu'il a une facture
            if (currentDemande.isSigne() && currentDemande.hasFacture()) currentDemande.currentStep = 4;

            // Si la facture est signé
            if (currentDemande.hasFactureTraite()) currentDemande.currentStep = 5;
          }

          // Si devis refusé
          else if (currentDemande.isRefuse())  {
            currentDemande.currentStep = -1;
          }
        }
        // Si demande d'intervention
        else {
          currentDemande.currentStep = 0;

          // Si il y a un devis
          if(currentDemande.hasFacture()) currentDemande.currentStep = 1;

          // Si la facture est signé
          if (currentDemande.hasFactureTraite()) currentDemande.currentStep = 2;
        }

      }
      else if(currentDemande.isContenuContrat()) {
        _this.nbDemandeContrat++;
        //currentDemande.nbStep = angular.copy(nbStepContrat);     // Nombre d'étape de la demande
        //currentDemande.allowDeleteContratForFournisseur = true;

        initUploader(currentDemande, "contrat");
        initUploader(currentDemande, "documentsFournisseur");

        // Si demande en attente du contrat fournisseur signé
        if (currentDemande.isWaiting()) {
          currentDemande.currentStep = 0;

          // Si il y a un contrat signé par le fournisseur
          if(currentDemande.isSignedByFournisseur()) currentDemande.currentStep = 1;
        }

        // Si contrat accepté
        else if (currentDemande.isAccepte()) {
          currentDemande.currentStep = 2;

          // Si le contrat est signé par l'agence
          if (currentDemande.isSignedByAgence()) currentDemande.currentStep = 3;
        }

        // Si contrat refusé
        else if (currentDemande.isRefuse())  {
          currentDemande.currentStep = -1;
        }
      }

      currentDemande.listDocumentAgence = [];
      if(currentDemande.getListeDocument().length){
        currentDemande.listDocumentAgence = currentDemande.getListeDocument().reduce(function(accum, doc){
          if(!doc.isDemande() && !doc.isContrat()) accum.push(doc);
          return accum;
        }, []);
      }
      if(!_.isNil(open)) currentDemande.open = open;
      $scope.$broadcast('updateTimeline', currentDemande);
    }

    /**
     * Permet d'init les demandes par rapport à une liste, ajoute ou modifie si existe
     * @param listDemandes
     */
    function initDemandes(listDemandes){
      if(_.isArray(listDemandes) && listDemandes.length) {

        var listeDemandeEvent = _.concat(_this.topic.getEvenement().getListeDevis(), _this.topic.getEvenement().getListeContrats());

        // Parcour la liste des nouvelles demandes
        for(var p = 0; p <  listDemandes.length; p++){
          var newDemande = listDemandes[p];
          if(newDemande.idTopic === _this.idTopic) {
            var demande = _.find(listeDemandeEvent, { 'idContenu': newDemande.getIdContenu() });

            /*
            if(_.isArray(listeDemandeEvent) && listeDemandeEvent.length) {
              // Parcour la liste des demandes
              for(var i = 0; i < listeDemandeEvent.length; i++){
                var demande = listeDemandeEvent[i];

                // Si l'idContenu de la nouvelle demande est le même que l'idContenu d'une demande dans la liste existante
                if(newDemande.getIdContenu() === demande.getIdContenu()) {
                  var open = (demande.open) ? true : false;
                  demande.setData(newDemande);
                  demande.open = open;
                  exist = true;
                  initDemande(demande);
                  break;
                }
              }
            }*/

            if(_.isNil(demande)) {
              if(newDemande.isContenuDevisInter()) _this.topic.getEvenement().addDevis(newDemande);
              else if(newDemande.isContenuContrat()) _this.topic.getEvenement().addContrat(newDemande);
              initDemande(newDemande, true);
              _this.listDemandes.push(newDemande);
            }
            else {
              var open = (demande.open) ? true : false;
              demande.setData(newDemande);
              demande.open = open;
              initDemande(demande);
            }
          }
        }
      }
    }

    // -------------- Devis ---------------/

    /**
     * Permet d'ouvrir l'upload de document pour des devis
     * @param demande
     */
    function openChooseDocumentDevis(demande){
      var currentObjetUploader = getUploader(demande, "devis");
      //console.log('demande',demande);
      //console.log('currentObjetUploader',currentObjetUploader);
      if(_.isObject(currentObjetUploader)){
        $scope.$broadcast('openUpload', currentObjetUploader.uuid);
        //currentObjetUploader.openChooseDocument = UtilsService.uuid.new();
      }
    }

    /**
     * Sauvegarde du devis
     */
    function onClickSaveDevis(demande) {
      var currentObjetUploader = getUploader(demande, "devis");
      if(_.isObject(currentObjetUploader)) currentObjetUploader.uploader.uploadAll();
    }

    /**
     * Lors de la modification
     * @param objUpdate
     */
    function onUpdateDocumentDevis(obj, demande) {
      try {
        if (!obj) throw new Error('Il manque l\'objet "obj" !');

        // Si action delete
        if (obj.hasOwnProperty('action') && obj.action === 'delete') {

          // Update le contenu venant des pièces jointes
          //if (objUpdate.hasOwnProperty('contenu')) {

          // Certainement une suppression du devis donc repasse le devisConfirmed en false
          //if (demande.documentDevis === '' && objUpdate.contenu.documentDevis === '') {

          var currentObjetUploader = getUploader(demande, "devis");
          if(_.isNil(currentObjetUploader)){
            console.error('_this.uploader',_this.uploader);
            console.error('obj',obj);
            console.error('demande',demande);
            ModalsService.alertErreur("Erreur: Aucun uploader trouvé");
            return;
          }

          demande.loadingAction = true;

          // Delete le document devis
          demande
            .deleteDocumentDevis()
            .then(function () {
              initDemande(demande, true);
            })
            .catch(function (msg) {
              ModalsService.alertErreur(msg);
            })
            .finally(function () {
              demande.loadingAction = false;
            });
        }
      }
      catch (err) {
        console.log('[CollabFournisseurDetailController.onUpdateDocumentDevis] Erreur : ' + err.message);
      }
    }

    // -------------- Factures -----------------/

    /**
     * Permet d'ouvrir l'upload de document pour des factures
     * @param demande
     */
    function openChooseDocumentFacture(demande){
      var currentObjetUploader = getUploader(demande, "facture");
      if(_.isObject(currentObjetUploader)){
        $scope.$broadcast('openUpload', currentObjetUploader.uuid);
        //currentObjetUploader.openChooseDocument = UtilsService.uuid.new();
      }
    }

    /**
     * Sauvegarde de la facture
     */
    function onClickSaveFacture(demande) {
      var currentObjetUploader = getUploader(demande, "facture");
      if(_.isObject(currentObjetUploader)) currentObjetUploader.uploader.uploadAll();
    }

    // -------------- Contrat ---------------/

    /**
     * Permet d'ouvrir l'upload de document pour des contrats
     * @param demande
     */
    function openChooseDocumentContrat(demande){
      var currentObjetUploader = getUploader(demande, "contrat");
      if(_.isObject(currentObjetUploader)){
        $scope.$broadcast('openUpload', currentObjetUploader.uuid);
        //currentObjetUploader.openChooseDocument = UtilsService.uuid.new();
      }
    }

    /**
     * Sauvegarde du devis
     */
    function onClickSaveContrat(demande) {
      var currentObjetUploader = getUploader(demande, "contrat");
      if(_.isObject(currentObjetUploader)) currentObjetUploader.uploader.uploadAll();
    }

    /**
     * Lors de la modification
     * @param objUpdate
     */
    function onUpdateDocumentContrat(obj, demande) {
      try {
        if (!obj) throw new Error('Il manque l\'objet "obj" !');

        // Si action delete
        if (obj.hasOwnProperty('action') && obj.action === 'delete') {

          if (!_.isObject(obj.document)) throw new Error('Il manque l\'objet "document" dans "objUpdate" !');

          var currentObjetUploader = getUploader(demande, "contrat");
          if(_.isNil(currentObjetUploader)){
            console.error('_this.uploader',_this.uploader);
            console.error('obj',obj);
            console.error('demande',demande);
            ModalsService.alertErreur("Erreur: Aucun uploader trouvé");
            return;
          }

          demande.loadingAction = true;

          // Delete le document devis
          demande
            .deleteDocumentFromListDocumentContratFournisseurSigne(obj.document.getGuid())
            .then(function () {
              initDemande(demande, true);
            })
            .catch(function (msg) {
              ModalsService.alertErreur(msg);
            })
            .finally(function () {
              demande.loadingAction = true;
            });
        }
      }
      catch (err) {
        console.log('[CollabFournisseurDetailController.onUpdateDocumentContrat] Erreur : ' + err.message);
      }

    }

    // ----------- Documents fournisseurs --------------------/

    /**
     * Sauvegarde des documents fournisseurs
     */
    function onClickSaveDocumentsFournisseur(demande) {
      var currentObjetUploader = getUploader(demande, "documentsFournisseur");
      if(_.isObject(currentObjetUploader)) currentObjetUploader.uploader.uploadAll();
    }

    // ----------- Divers --------------------/

    function initDisplayUploader(objetUploadByContenu){
      if(_.isObject(objetUploadByContenu)){
        objetUploadByContenu.disableDevis = false;
        objetUploadByContenu.disableFacture = false;
        objetUploadByContenu.disableContrat = false;
        objetUploadByContenu.disableDocumentsFournisseur = false;
      }
    }

    /**
     * Permet de créer une instance d'uploader pour un type
     * @param demande
     */
    function initUploader(demande, type) {

      var idContenu = demande.getIdContenu();
      if(!_.isObject(_this.uploader.uploaderByIdContenu[idContenu]))
        _this.uploader.uploaderByIdContenu[idContenu] = {
          disableDevis: false,
          disableFacture: false,
          disableContrat: false,
          disableDocumentsFournisseur: false
        };

      if(!_.isObject(_this.uploader.uploaderByIdContenu[idContenu][type]))
        _this.uploader.uploaderByIdContenu[idContenu][type] = {};

      var uuid = null;
      if(_this.uploader.uploaderByIdContenu[idContenu][type].uuid) uuid = _this.uploader.uploaderByIdContenu[idContenu][type].uuid;

      _this.uploader.uploaderByIdContenu[idContenu][type] = {
        loading: false,
        uploader: null,
        queueLimit: _this.uploader.config[type].queueLimit,
        //whenAddingFile: angular.copy(_this.uploader.config.whenAddingFile)
        whenAddingFile: angular.copy(_this.uploader.config[type].whenAddingFile),
        listContenu: [],
        uuid: uuid
      };
      // Pour version avec ContenuServlet et paramètre "action", peut être un jour...
      _this.uploader.uploaderByIdContenu[idContenu][type].whenAddingFile.push({idContenu: idContenu});
    }

    function destroyUploader(){
      _.forEach(_this.uploader.uploaderByIdContenu, function(objectForContenu, idContenu) {
        _.forEach(objectForContenu, function(value, type) {
          if(_.isObject(value.uploader)) value.uploader.destroy();
        });
      });
    }

    /**
     * Permet de récupérer l'uploader de la bonne demande pour un type
     * @param demande
     * @param type
     * @returns {null}
     */
    function getUploader(demande, type){
      var currentObjetUploader = null;
      var idContenu = demande.getIdContenu();
      if(_.isObject(_this.uploader.uploaderByIdContenu[idContenu])){
        currentObjetUploader = _this.uploader.uploaderByIdContenu[idContenu];
      }
      if(!_.isNil(type) && _.isObject(_this.uploader.uploaderByIdContenu[idContenu][type])){
        currentObjetUploader = _this.uploader.uploaderByIdContenu[idContenu][type];
      }
      return currentObjetUploader;
    }

    // ----------- Event --------------------/

    /**
     * Lors du clique sur le bouton pour rafraichir la demande
     * @param demande
     */
    function onClickRefreshDemande(demande){
      demande.loading = true;
      ContenuService
        .getContenu(demande.getIdContenu())
        .then(function(contenu){
          demande.setData(contenu);
          initDemande(demande, true);
        })
        .finally(function(){
          demande.loading = false;
        })
    }

    /**
     * Lorsqu'on ouvre une demande
     * @param demande
     */
    function onClickOpenDemande(demande){
      if(!demande.open) UtilsService.scrollToId("demande-" + demande.getIdContenu());
      demande.open = !demande.open;
    }

    // -------------- Commentaire -----------------/

    function onClickOpenSendMessage(demande){
      ModalsService.sweet.show({
        title: 'Votre message à nous transmettre :',
        text: '<textarea style="width:100%;padding:0" rows="4" id="commentaireMessage" class="form-control"></textarea>',
        type: 'info',
        html: true,
        showCancelButton: true,
        //confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Envoyer',
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      }, function () {
        var message = angular.element('#commentaireMessage').val();
        sendMessage(message, demande)
          .then(function(){
            ModalsService.success("Merci <br>Votre commentaire est bien enregistré");
          })
          .catch(function(msg){
            ModalsService.alertErreur('Oops...', msg);
          });
      });
    }

    /**
     * Permet de poster un message
     */
    function sendMessage(message, demande) {

      var deferred = $q.defer();

      if (_.isNil(message) || message === '') {
        ModalsService.alertErreur('Oops...', 'Veuillez renseigner un message');
        return;
      }

      var proxyMessage = new MessageService.ProxyContenuMessage();
      proxyMessage.setListeDestinataires(_this.topic.getEvenement().getDestinataires());
      proxyMessage.setMessage(UtilsService.nl2br(message));
      if(_.isObject(demande)) proxyMessage.setIdContenuParent(demande.getIdContenu());

      var obj = {
        idTopic: _this.topic.getIdTopic(),
        objJSON: proxyMessage
      };


      if(_.isObject(demande)) demande.loadingAction = true;

      MessageService
        .addMessageInTopic(obj)
        .then(function(obj){
          deferred.resolve(obj.message);
        })
        .catch(function(error){
          deferred.reject(error);
        })
        .finally(function(){
          if(_.isObject(demande)) demande.loadingAction = false;
        });

      return deferred.promise;
    }

    // ----------- onEmit --------------------/

    /**
     * Lorsque le composant enfant documents emit quelque chose
     * @param obj
     * @param demande
     */
    function onEmitFromDocuments(obj, demande) {
      try {
        if (!obj) throw new Error('Pas de "obj"');

        // Si action delete
        if (obj.action === 'delete') {

          if (!_.isNil(obj.property)) {
            if(obj.property === 'listeDocumentFournisseur') {
              demande.loadingAction = true;
              demande
                .deleteDocumentFromListeDocumentFournisseur(obj.document.getGuid())
                .then(function () {
                  initDemande(demande, true);
                })
                .catch(function (msg) {
                  ModalsService.alertErreur(msg);
                })
                .finally(function(){
                  demande.loadingAction = false;
                });
            }
            if(obj.property === 'listeDocument') {
              demande.loadingAction = true;
              demande
                .deleteDocumentFromListeDocument(obj.document.getGuid())
                .then(function () {
                  initDemande(demande, true);
                })
                .catch(function (msg) {
                  ModalsService.alertErreur(msg);
                })
                .finally(function(){
                  demande.loadingAction = false;
                });
            }
          }
        }
      }
      catch (err) {
        console.log('[CollabFournisseurDetailController.onEmitFromDocuments] Erreur : ' + err.message);
      }
    }

    /**
     * Lorsque le composant enfant upload-collab emit quelque chose
     * @param obj
     */
    function onEmitFromUploadCollab(obj, type, demande){
      //console.log('onEmitFromUploadCollab', obj);
      try {
        if (!obj) throw new Error('Pas de "obj"');
        if (_.isNil(type)) throw new Error('Il manque le paramètre "type"');
        if (!_.isObject(demande)) throw new Error('Il manque le paramètre "demande"');

        var currentObjetUploader = getUploader(demande);

        if(_.isNil(currentObjetUploader) || !_.isObject(currentObjetUploader[type])){
          console.error('_this.uploader',_this.uploader);
          console.error('obj',obj);
          console.error('demande',demande);
          console.error('type',type);
          ModalsService.alertErreur("Erreur: Aucun uploader trouvé");
          return;
        }

        if(_.isObject(obj.uploader)) currentObjetUploader[type].uploader = obj.uploader;

        if(obj.action === 'uploaderReady'){
          currentObjetUploader[type].uuid = obj.uuid;
        }

        if(obj.action === 'onBeforeUploadItem'){
          demande.loadingAction = true;
        }

        if(obj.action === 'onAfterAddingFile'){
          if(type === "devis"){
            currentObjetUploader.disableFacture = true;
            currentObjetUploader.disableDocumentsFournisseur = true;
          }
          else if(type === "facture"){
            currentObjetUploader.disableDevis = true;
            currentObjetUploader.disableDocumentsFournisseur = true;
          }
          else if(type === "contrat"){
            currentObjetUploader.disableDevis = true;
            currentObjetUploader.disableFacture = true;
            currentObjetUploader.disableDocumentsFournisseur = true;
          }
          else if(type === "documentsFournisseur"){
            currentObjetUploader.disableDevis = true;
            currentObjetUploader.disableFacture = true;
            currentObjetUploader.disableContrat = true;
          }

          //if(currentObjetUploader.uploader.queue.length === 1) UtilsService.scrollToId("demande-actions-" + demande.getIdContenu());
          UtilsService.scrollToId("demande-" + demande.getIdContenu());
          demande.open = true;
        }

        if(_.isObject(obj.fileItem) && _.isObject(obj.response)) {
          if(obj.action === 'onErrorItem') {
            ModalsService.alertErreur('Erreur lors de l\'upload');
            console.error('fileItem',obj.fileItem);
            console.error('response',obj.response);
          }
          if(obj.action === 'onSuccessItem'){
            if(obj.response.success) {
              if(_.isArray(obj.response.result) && obj.response.result.length) {
                currentObjetUploader[type].listContenu = obj.response.result.reduce(function (accum, item) {
                  item = ContenuService.instanceContenuByType(item);
                  accum.push(item);
                  return accum;
                }, currentObjetUploader[type].listContenu);
              }
              else if(_.isObject(obj.response.result)) {
                currentObjetUploader[type].listContenu.push(ContenuService.instanceContenuByType(obj.response.result));
              }
            }
            else {
              var message = 'Erreur du retour de l\'upload';
              if(obj.response.message) message = obj.response.message;
              ModalsService.alertErreur(message);

              console.error('fileItem',obj.fileItem);
              console.error('response',obj.response);
            }
          }
        }

        // Notifie que tous les upload sont terminés
        if(obj.action === 'onCompleteAll') {

          initDisplayUploader(currentObjetUploader);

          if(currentObjetUploader[type].listContenu.length){
            // Cherche la demande actuel dans la liste, si elle est trouvé alors on ne la ferme pas
            var demandeFound = _.find(currentObjetUploader[type].listContenu, { 'idContenu': demande.idContenu });
            if(demandeFound) demande.open = true;
            else demande.open = false;

            initDemandes(currentObjetUploader[type].listContenu);
            currentObjetUploader[type].listContenu = [];
          }
          demande.loadingAction = false;
        }
      }
      catch (err) {
        console.log('[CollabFournisseurDetailController.onEmitFromUploadAutresCollab] Erreur : ' + err.message);
      }
    }

    /**
     * Lorsque le composant collab-upload-queue remonte une information
     * @param obj
     */
    function onEmitFromUploadCollabQueue(obj, type, demande) {
      //console.log(obj);
      try {
        if (!obj) throw new Error('Il manque l\'objet "obj" !');
        if (_.isNil(type)) throw new Error('Il manque le paramètre "type"');

        var currentObjetUploader = getUploader(demande);

        if(_.isNil(currentObjetUploader) || !_.isObject(currentObjetUploader[type])){
          console.error('_this.uploader',_this.uploader);
          console.error('obj',obj);
          console.error('demande',demande);
          console.error('type',type);
          ModalsService.alertErreur("Erreur: Aucun uploader trouvé");
          return;
        }

        if (obj.action === "remove") {
          if(currentObjetUploader[type].uploader.queue.length === 0){
            initDisplayUploader(currentObjetUploader);
          }
        }
      } catch (err) {
        console.log('[CollabFournisseurDetailController.onEmitFromUploadCollabQueue] Erreur : ' + err.message);
      }
    }

    /**
     * Lorsque le composant collab-fournisseur-btn-avancement remonte une information
     * @param obj
     */
    function onEmitFromAvancementComponent(obj, demande){
      //console.log(obj);
      try {
        if (!obj) throw new Error('Il manque l\'objet "obj" !');
        if (!_.isObject(demande)) throw new Error('Il manque le paramètre "demande"');

        if(obj.action === "toggleDisplayAddDocumentsDevis") openChooseDocumentDevis(demande)
        if(obj.action === "toggleDisplayAddDocumentFacture") openChooseDocumentFacture(demande)
        if(obj.action === "toggleDisplayAddDocumentContrat") openChooseDocumentContrat(demande)
      }
      catch (err) {
        console.log('[CollabFournisseurDetailController.onEmitFromAvancementComponent] Erreur : ' + err.message);
      }
    }

  }
})();
