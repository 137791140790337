'use strict';

angular.module('collaboreApp').factory('Bail',[
	'$filter',
	'Personnalite',
	'UtilsService',
	function($filter, Personnalite, UtilsService) {

		function Bail(objetData) {

			if (objetData) {
				this.setData(objetData);
				this.isBail = true;
			}
		}

		Bail.prototype = {


			isModel: true,

			setData: function(objetData) {
				angular.extend(this, objetData);
				if(angular.isObject(objetData)){
					if(objetData.hasOwnProperty('personnalite')){
						this.setPersonnalite(objetData.personnalite);
					}else{
						this.setPersonnalite(false);
					}
				}
			},

			// A partir du
			getApartirdu: function(formatDate){
				return UtilsService.parseDateForProperty('apartirdu',this,formatDate);
			},
			setApartirdu: function(val){
				this.apartirdu = val;
			},

			// Cau1reve
			getCau1reve: function(){
				return this.cau1reve;
			},
			setCau1reve: function(val){
				this.cau1reve = val;
			},

			// Cau2reve
			getCau2reve: function(){
				return this.cau2reve;
			},
			setCau2reve: function(val){
				this.cau2reve = val;
			},

			// codecautio
			getCodecautio: function(){
				return this.codecautio;
			},
			setCodecautio: function(val){
				this.codecautio = val;
			},

			// codedroit
			getCodedroit: function(){
				return this.codedroit;
			},
			setCodedroit: function(val){
				this.codedroit = val;
			},

			// codefraco
			getCodefraco: function(){
				return this.codefraco;
			},
			setCodefraco: function(val){
				this.codefraco = val;
			},

			// codefraqu
			getCodefraqu: function(){
				return this.codefraco;
			},
			setCodefraqu: function(val){
				this.codefraqu = val;
			},

			// codefrare
			getCodefrare: function(){
				return this.codefrare;
			},
			setCodefrare: function(val){
				this.codefrare = val;
			},

			// codetva
			getCodetva: function(){
				return this.codetva;
			},
			setCodetva: function(val){
				this.codetva = val;
			},

			// codprelev
			getCodprelev: function(){
				return this.codprelev;
			},
			setCodprelev: function(val){
				this.codprelev = val;
			},

			// conempadr
			getConempadr: function(){
				return this.conempadr;
			},
			setConempadr: function(val){
				this.conempadr = val;
			},

			// conempcod
			getConempcod: function(){
				return this.conempcod;
			},
			setConempcod: function(val){
				this.conempcod = val;
			},

			// conempnom
			getConempnom: function(){
				return this.conempnom;
			},
			setConempnom: function(val){
				this.conempnom = val;
			},

			// conempvil
			getConempvil: function(){
				return this.conempvil;
			},
			setConempvil: function(val){
				this.conempvil = val;
			},

			// connosecu
			getConnosecu: function(){
				return this.connosecu;
			},
			setConnosecu: function(val){
				this.connosecu = val;
			},

			// conrevenu
			getConrevenu: function(){
				return this.conrevenu;
			},
			setConrevenu: function(val){
				this.conrevenu = val;
			},

			// cumloenc
			getCumloenc: function(){
				return this.cumloenc;
			},
			setCumloenc: function(val){
				this.cumloenc = val;
			},

			// cumloene
			getCumloene: function(){
				return this.cumloene;
			},
			setCumloene: function(val){
				this.cumloene = val;
			},

			// cumprenc1
			getCumprenc1: function(){
				return this.cumprenc1;
			},
			setCumprenc1: function(val){
				this.cumprenc1 = val;
			},

			// cumprenc2
			getCumprenc2: function(){
				return this.cumprenc2;
			},
			setCumprenc2: function(val){
				this.cumprenc2 = val;
			},

			// cumprene1
			getCumprene1: function(){
				return this.cumprene1;
			},
			setCumprene1: function(val){
				this.cumprene1 = val;
			},

			// cumprene2
			getCumprene2: function(){
				return this.cumprene2;
			},
			setCumprene2: function(val){
				this.cumprene2 = val;
			},

			// cycle
			getCycle: function(){
				return this.cycle;
			},
			setCycle: function(val){
				this.cycle = val;
			},

			// datedbail
			getDatedbail: function(formatDate){
				return UtilsService.parseDateForProperty('datedbail',this,formatDate);
			},
			setDatedbail: function(val){
				this.datedbail = val;
			},

			// datedenon
			getDatedenon: function(formatDate){
				return UtilsService.parseDateForProperty('datedenon',this,formatDate);
			},
			setDatedenon: function(val){
				this.datedenon = val;
			},

			// datedepar
			getDatedepar: function(formatDate){
				return UtilsService.parseDateForProperty('datedepar',this,formatDate);
			},
			setDatedepar: function(val){
				this.datedepar = val;
			},

			// datefbail
			getDatefbail: function(formatDate){
				return UtilsService.parseDateForProperty('datefbail',this,formatDate);
			},
			setDatefbail: function(val){
				this.datefbail = val;
			},

			// datepour
			getDatepour: function(formatDate){
				return UtilsService.parseDateForProperty('datepour',this,formatDate);
			},
			setDatepour: function(val){
				this.datepour = val;
			},

			// datepreav
			getDatepreav: function(formatDate){
				return UtilsService.parseDateForProperty('datepreav',this,formatDate);
			},
			setDatepreav: function(val){
				this.datepreav = val;
			},

			// dateproch
			getDateproch: function(formatDate){
				return UtilsService.parseDateForProperty('dateproch',this,formatDate);
			},
			setDateproch: function(val){
				this.dateproch = val;
			},

			// daterevis
			getDaterevis: function(formatDate){
				return UtilsService.parseDateForProperty('daterevis',this,formatDate);
			},
			setDaterevis: function(val){
				this.daterevis = val;
			},

			// deducImm
			getDeducImm: function(){
				return this.deducImm;
			},
			setDeducImm: function(val){
				this.deducImm = val;
			},

			// deducMan
			getDeducMan: function(){
				return this.deducMan;
			},
			setDeducMan: function(val){
				this.deducMan = val;
			},

			// durbail
			getDurbail: function(){
				return this.durbail;
			},
			setDurbail: function(val){
				this.durbail = val;
			},

			// durenou
			getDurenou: function(){
				return this.durenou;
			},
			setDurenou: function(val){
				this.durenou = val;
			},

			// gRL
			getGRL: function(){
				return this.gRL;
			},
			setGRL: function(val){
				this.gRL = val;
			},

			// gURL
			getGURL: function(){
				return this.gURL;
			},
			setGURL: function(val){
				this.gURL = val;
			},

			// iIII
			getIIII: function(){
				return this.iIII;
			},
			setIIII: function(val){
				this.iIII = val;
			},

			// lLLL
			getLLLL: function(){
				return this.lLLL;
			},
			setLLLL: function(val){
				this.lLLL = val;
			},

			// loemnomi
			getLoemnomi: function(){
				return this.loemnomi;
			},
			setLoemnomi: function(val){
				this.loemnomi = val;
			},

			// loempadr
			getLoempadr: function(){
				return this.loempadr;
			},
			setLoempadr: function(val){
				this.loempadr = val;
			},

			// loempcod
			getLoempcod: function(){
				return this.loempcod;
			},
			setLoempcod: function(val){
				this.loempcod = val;
			},

			// loempreve
			getLoempreve: function(){
				return this.loempreve;
			},
			setLoempreve: function(val){
				this.loempreve = val;
			},

			// loempvil
			getLoempvil: function(){
				return this.loempvil;
			},
			setLoempvil: function(val){
				this.loempvil = val;
			},

			// loydatappl
			getLoydatappl: function(){
				return this.loydatappl;
			},
			setLoydatappl: function(val){
				this.loydatappl = val;
			},

			// mMMM
			getMMMM: function(){
				return this.mMMM;
			},
			setMMMM: function(val){
				this.mMMM = val;
			},

			// moidemass
			getMoidemass: function(){
				return this.moidemass;
			},
			setMoidemass: function(val){
				this.moidemass = val;
			},

			// montcautio
			getMontcautio: function(){
				return this.montcautio;
			},
			setMontcautio: function(val){
				this.montcautio = val;
			},

			// montloyer
			getMontloyer: function(){
				return this.montloyer;
			},
			setMontloyer: function(val){
				this.montloyer = val;
			},

			// montprcha
			getMontprcha: function(){
				return this.montprcha;
			},
			setMontprcha: function(val){
				this.montprcha = val;
			},

			// montprovi
			getMontprovi: function(){
				return this.montprovi;
			},
			setMontprovi: function(val){
				this.montprovi = val;
			},

			// noancbail
			getNoancbail: function(){
				return this.noancbail;
			},
			setNoancbail: function(val){
				this.noancbail = val;
			},

			// noconass
			getNoconass: function(){
				return this.noconass;
			},
			setNoconass: function(val){
				this.noconass = val;
			},

			// noperass
			getNnoperass: function(){
				return this.noperass;
			},
			setNoperass: function(val){
				this.noperass = val;
			},

			// noperca1
			getNoperca1: function(){
				return this.noperca1;
			},
			setNoperca1: function(val){
				this.noperca1 = val;
			},

			// noperca2
			getNoperca2: function(){
				return this.noperca2;
			},
			setNoperca2: function(val){
				this.noperca2 = val;
			},

			// nopercol
			getNopercol: function(){
				return this.nopercol;
			},
			setNopercol: function(val){
				this.nopercol = val;
			},

			// noperlocat
			getNoperlocat: function(){
				return this.noperlocat;
			},
			setNoperlocat: function(val){
				this.noperlocat = val;
			},

			// noperpay
			getNoperpay: function(){
				return this.noperpay;
			},
			setNoperpay: function(val){
				this.noperpay = val;
			},

			// nouvLoyer
			getNouvLoyer: function(){
				return this.nouvLoyer;
			},
			setNouvLoyer: function(val){
				this.nouvLoyer = val;
			},

			// nouvRappel
			getNouvRappel: function(){
				return this.nouvRappel;
			},
			setNouvRappel: function(val){
				this.nouvRappel = val;
			},

			// pEC
			getPEC: function(){
				return this.pEC;
			},
			setPEC: function(val){
				this.pEC = val;
			},

			// periodici
			getPeriodici: function(){
				return this.periodici;
			},
			setPeriodici: function(val){
				this.periodici = val;
			},

			// provdatappl
			getProvdatappl: function(){
				return this.provdatappl;
			},
			setProvdatappl: function(val){
				this.provdatappl = val;
			},

			// queradr1
			getQueradr1: function(){
				return this.queradr1;
			},
			setQueradr1: function(val){
				this.queradr1 = val;
			},

			// queradr2
			getQueradr2: function(){
				return this.queradr2;
			},
			setQueradr2: function(val){
				this.queradr2 = val;
			},

			// queradr3
			getQueradr3: function(){
				return this.queradr3;
			},
			setQueradr3: function(val){
				this.queradr3 = val;
			},

			// queradr1
			getQueradr4: function(){
				return this.queradr4;
			},
			setQueradr4: function(val){
				this.queradr4 = val;
			},

			// queradr1
			getQueradr5: function(){
				return this.queradr5;
			},
			setQueradr5: function(val){
				this.queradr5 = val;
			},

			// reautobai
			getReautobai: function(){
				return this.reautobai;
			},
			setReautobai: function(val){
				this.reautobai = val;
			},

			// restedu
			getRestedu: function(){
				return this.restedu;
			},
			setRestedu: function(val){
				this.restedu = val;
			},

			// revdatlim
			getRevdatlim: function(){
				return this.revdatlim;
			},
			setRevdatlim: function(val){
				this.revdatlim = val;
			},

			// revdeinut
			getRevdeinut: function(){
				return this.revdeinut;
			},
			setRevdeinut: function(val){
				this.revdeinut = val;
			},

			// revindeba
			getRevindeba: function(){
				return this.revindeba;
			},
			setRevindeba: function(val){
				this.revindeba = val;
			},

			// revmonfor
			getRevmonfor: function(){
				return this.revmonfor;
			},
			setRevmonfor: function(val){
				this.revmonfor = val;
			},

			// revpour
			getRevpour: function(){
				return this.revpour;
			},
			setRevpour: function(val){
				this.revpour = val;
			},

			// taxeaddit
			getTaxeaddit: function(){
				return this.taxeaddit;
			},
			setTaxeaddit: function(val){
				this.taxeaddit = val;
			},

			// terme
			getTerme: function(){
				return this.terme;
			},
			setTerme: function(val){
				this.terme = val;
			},

			// typebail
			getTypebail: function(){
				return this.typebail;
			},
			setTypebail: function(val){
				this.typebail = val;
			},

			// typereve
			getTypereve: function(){
				return this.typereve;
			},
			setTypereve: function(val){
				this.typereve = val;
			},

			// xXXX
			getXXXX: function(){
				return this.xXXX;
			},
			setXXXX: function(val){
				this.xXXX = val;
			},


			// Personnalite
			getPersonnalite: function(){
				return this.personnalite;
			},
			setPersonnalite: function(val){
				// Si "val" est un string
				if(angular.isString(val)){
					val = new Personnalite({'nomper':val});
				}else{
					// Si val est un objet mais qu'il n'est pas un model
					if(!val.isModel){
						// Si val a l'attribut "nomper"
						if(val.hasOwnProperty('nomper')){
							val = new Personnalite(val);
						}else{
							val = false;
						}
					}
				}

				this.personnalite = val;
			},

			// Nom du locataire
			getNomLocataire: function(){
				var retour = '';
				if(this.getPersonnalite()){
					if(this.getPersonnalite().getCodelib()){
						retour += this.getPersonnalite().getCodelib()+' ';
					}
					retour += this.getPersonnalite().getNomper();
				}
				return retour;
			},

			getInfosLocataire: function(){
				var retour = '';
				if(this.getPersonnalite()){
					var noperso = this.getPersonnalite().getNoperso();
					var nom = this.getNomLocataire();
					if(nom!==''){
						retour += nom;
						if(noperso!==''){retour += ' ['+noperso+']';}
						retour += '<br/>';
					}else{
						if(noperso!==''){retour += 'NoPerso: '+noperso+'<br/>';}
					}


					var tel1 = this.getPersonnalite().getNotel1();
					if(tel1!=''){retour += '<i class="fa fa-phone"></i> '+tel1+'<br/>';}

					var tel2 = this.getPersonnalite().getNotel2();
					if(tel2!=''){retour += '<i class="fa fa-phone"></i> '+tel2+'<br/>';}

					var fax = this.getPersonnalite().getNofax();
					if(fax!=''){retour += '<i class="fa fa-fax"></i> '+fax+'<br/>';}
				}

				retour = retour.slice(0,-5);
				return retour;
			},

			isVacant: function(){
				var retour = false;

				if (angular.isObject(this)) {
					if(!this.hasOwnProperty('noperlocat')) {
						retour = true;
					}
					else {
						if(this.hasOwnProperty('datedepar')) {
							var now = new Date();
							var dates = this.datedepar.split(' ');
							if(dates.length){
								var dateDepart = new Date(dates[0]);
								// Si la date de depart est plus petite que la date du jour
								if(dateDepart < now) {
									retour = true;
								}
							}
						}
					}
				}

				return retour;
			}

		};

		return Bail;
	}
]);