(function(){
	'use strict';

    angular.module('collaboreApp').controller('ModuleCtrl', ModuleCtrl);

    /** @ngInject */
	function ModuleCtrl($rootScope,$stateParams,UtilsService){
		//console.log($stateParams);

		var vm = this;
		vm.currentModule = false;
		vm.urlIFrame = null;

		var idModule = parseInt($stateParams.idModule);

		if($rootScope.current.myModules.length){
			for(var i = 0 ; i < $rootScope.current.myModules.length ; i++){
				var module = $rootScope.current.myModules[i];
				if(module.getIdModule()===idModule){
					vm.currentModule = module;
				}
			}
		}

		if(_.isObject(vm.currentModule)) {
		  if(vm.currentModule.isIframe()) {
        var url = vm.currentModule.getUrlForIframe();
        var params = vm.currentModule.getListeParametres(1);
        if(_.isArray(params) && params.length) {
          var extranet = UtilsService.endsWith(params[0].valeur, "SatisafactionCamping/admin_v2/pages/index.php");
          if(extranet) {
            var currentUser = UtilsService.getCurrentUser();
            var beforeParam = "";

            if(UtilsService.endsWith(url, "index.php")) beforeParam = "?";
            else beforeParam = "&";

            if(currentUser.allowToAccessExtranetCamping()){

              var privilege = "privSiegeCapfun";

              if(currentUser.isAccueilCamping()) privilege = "privAccueilCamping";
              else if(currentUser.isDirectionCamping()) privilege = "privDirectionCamping";
              else if(currentUser.isDirectionCapfun()) privilege = "privDirectionCapfun";

              var t = moment().unix();
              var base64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(t+privilege+t));
              url += beforeParam + "access="+base64;
              url += "&token=" + $rootScope.token;
            }

          }
        }
        vm.urlIFrame = url;
      }
    }

		//console.log(vm.currentModule.getUrlForIframe());
	}


})();
