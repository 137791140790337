(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name collaboreApp.PersoIcs
     * @description
     * # PersoIcs
     * Service in the collaboreApp.
     */

    angular.module('collaboreApp').factory('PersoIcs', PersoIcsModel);

    /** @ngInject */
    function PersoIcsModel(Main, Portefeuille, Renseignement, Personnalite) {

        function PersoIcs(objetData) {
            Main.apply(this, arguments);
            this.hydrate(objetData);
        }

        PersoIcs.prototype = {

            model: 'PersoIcs',
            isPersoIcs: true,

            setPersoIcs: function(obj) {
                if(obj.isModel) {
                    if(obj.isPersonnalite) {

                        this.setLibelle(obj.getNom());
                        this.setNoperso(obj.noperso);

                        if(obj.isPersoFournisseur) {
                            this.setCodemetier(obj.metier);
                            this.setNumero(obj.numero);
                            this.setType('F');
                        }

                        var renseignements = [];

                        if(obj.getNom()) {
                            renseignements.push(
                                new Renseignement({
                                    donnee: obj.getNom(),
                                    reference: 'nom'
                                })
                            );
                        }

                        if(obj.getAdresse()) {
                            renseignements.push(
                                new Renseignement({
                                    donnee: obj.getAdresse(true),
                                    reference: 'adresse'
                                })
                            );
                        }

                        if(obj.getTelephones().length) {
                            var tels = obj.getTelephones();
                            for(var t = 0; t < tels.length; t++) {
                                renseignements.push(
                                    new Renseignement({
                                        donnee: tels[t],
                                        reference: 'telephone'
                                    })
                                );
                            }
                        }

                        if(obj.getNofax()) {
                            renseignements.push(
                                new Renseignement({
                                    donnee: obj.getNofax(),
                                    reference: 'fax'
                                })
                            );
                        }

                        if(obj.getTelephonesFixe().length) {
                            var fixes = obj.getTelephonesFixe();
                            for(var t1 = 0; t1 < fixes.length; t1++) {
                                renseignements.push(
                                    new Renseignement({
                                        donnee: fixes[t1],
                                        reference: 'telephone'
                                    })
                                );
                            }
                        }

                        if(obj.getTelephonesMobile().length) {
                            var portables = obj.getTelephonesMobile();
                            for(var p = 0; p < portables.length; p++) {
                                renseignements.push(
                                    new Renseignement({
                                        donnee: portables[p],
                                        reference: 'portable'
                                    })
                                );
                            }
                        }

                        if(obj.getMails().length) {
                            var mails = obj.getMails();
                            for(var m = 0; m < mails.length; m++) {
                                renseignements.push(
                                    new Renseignement({
                                        donnee: mails[m],
                                        reference: 'email'
                                    })
                                );
                            }
                        }

                        if(renseignements.length) {
                            this.setInformations(renseignements);
                        }
                    }

                }


            },

            // Id Perso ICS
            getIdPersoICS: function(){
                return this.idPersoICS;
            },
            setIdPersoICS: function(id){
                this.idPersoICS = id;
            },

            // noperso
            getNoperso: function(){
                return this.noperso;
            },
            setNoperso: function(id){
                this.noperso = id;
            },

            // Libelle
            getLibelle: function(){
                return this.libelle;
            },
            setLibelle: function(id){
                this.libelle = id;
            },

            // Numero Perso
            getNumero: function(){
                return this.numero;
            },
            setNumero: function(id){
                this.numero = id;
            },

            // Type de perso
            getType: function(){
                return this.type;
            },
            setType: function(val){
                this.type = val;
            },
            getTypeLibelle: function(){
                var lib = null;
                if(this.getType()) {
                    switch(this.getType()) {
                        case 'E':
                            lib = 'Expert';
                            break;
                        case 'F':
                            lib = 'Fournisseur';
                            break;
                        default:
                            lib = this.getType();
                    }
                }
                return lib;
            },

            // Codemetier
            getCodemetier: function(){
                return this.codemetier;
            },
            setCodemetier: function(code){
                this.codemetier = code;
            },

            // Portefeuille
            getPortefeuille: function(){
                return this.portefeuille;
            },
            setPortefeuille: function(portefeuille){
                // Si val est un objet mais qu'il n'est pas un model
                if(!portefeuille.isModel){
                    portefeuille = new Portefeuille(portefeuille);
                }
                this.portefeuille = portefeuille;
            },

            // Informations
            getInformations: function(){
                return this.informations;
            },
            setInformations: function(valInformations){
                var informations = [];
                if(angular.isArray(valInformations)){
                    for(var i = 0; i < valInformations.length; i++) {
                        var information = valInformations[i];
                        if(!information.isModel){
                            information = new Renseignement(information);
                        }
                        informations.push(information);
                    }
                }
                this.informations = informations;
            },
            hasInformations: function(){
                if(angular.isArray(this.getInformations()) && this.getInformations().length) {
                    return true;
                }
                return false;
            },

            // Type de perso
            getIdPortefeuille: function(){
                return this.idPortefeuille;
            },
            setIdPortefeuille: function(id){
                this.idPortefeuille = id;
            },

            getNom: function(){
                var nom = null;

                if(this.infoNom) nom = this.infoNom;
                else if(this.hasInformations()) {

                    var infos = this.getInformations();
                    for(var i = 0; i < infos.length; i++) {
                        if(infos[i].isModel && infos[i].isRenseignement) {
                            if(infos[i].getReference() && infos[i].getReference().isNom()) {
                                nom = infos[i].getDonnee();
                                break;
                            }
                        }
                    }
                    this.infoNom = nom;
                }
                else if(this.getLibelle()){
                    return this.getLibelle();
                }
                return nom;
            },

            getPrenom: function(){
                var prenom = null;

                if(this.infoPrenom) prenom = this.infoPrenom;
                else if(this.hasInformations()) {

                    var infos = this.getInformations();
                    for(var i = 0; i < infos.length; i++) {
                        if(infos[i].isModel && infos[i].isRenseignement) {
                            if(infos[i].getReference() && infos[i].getReference().isPrenom()) {
                                prenom = infos[i].getDonnee();
                                break;
                            }
                        }
                    }
                    this.infoPrenom = prenom;
                }
                return prenom;
            },

            getAdresse: function(){
                var adresse = null;

                if(this.infoAdresse) adresse = this.infoAdresse;
                else if(this.hasInformations()) {

                    var infos = this.getInformations();
                    for(var i = 0; i < infos.length; i++) {
                        if(infos[i].isModel && infos[i].isRenseignement) {
                            if(infos[i].getReference() && infos[i].getReference().isAdresse()) {
                                adresse = infos[i].getDonnee();
                                break;
                            }
                        }
                    }
                    this.infoAdresse = adresse;
                }
                return adresse;
            },

            getEmails: function(){
                var emails = [];

                if(this.infoEmails) emails = this.infoEmails;
                else if(this.hasInformations()) {

                    var infos = this.getInformations();
                    for(var i = 0; i < infos.length; i++) {
                        if(infos[i].isModel && infos[i].isRenseignement) {
                            if(infos[i].getReference() && infos[i].getReference().isEmail()) {
                                emails.push(infos[i].getDonnee());
                            }
                        }
                    }
                    this.infoEmails = emails;
                }
                return emails;
            },

            getTelephones: function(){
                var tels = null;

                if(this.infoTels) tels = this.infoTels;
                else {
                    tels = {
                        fixes: [],
                        portables: []
                    };
                    if(this.hasInformations()){
                        var infos = this.getInformations();
                        for(var i = 0; i < infos.length; i++) {
                            if(infos[i].isModel && infos[i].isRenseignement) {
                                if(infos[i].getReference() && infos[i].getReference().isTelephone()) {
                                    tels.fixes.push(infos[i].getDonnee());
                                }
                                else if(infos[i].getReference() && infos[i].getReference().isPortable()) {
                                    tels.portables.push(infos[i].getDonnee());
                                }
                            }
                        }
                    }
                    this.infoTels = tels;
                }
                return tels;
            },

            getTelephonesFixe: function(){
                var telFixes = [];

                if(this.infoTelsFixe) telFixes = this.infoTelsFixe;
                else if(this.hasInformations()) {

                    var infos = this.getInformations();
                    for(var i = 0; i < infos.length; i++) {
                        if(infos[i].isModel && infos[i].isRenseignement) {
                            if(infos[i].getReference() && infos[i].getReference().isTelephone()) {
                                telFixes.push(infos[i].getDonnee());
                            }
                        }
                    }
                    this.infoTelsFixe = telFixes;
                }
                return telFixes;
            },

            getTelephonesPortable: function(){
                var telPortables = [];

                if(this.infoTelsPortable) telPortables = this.infoTelsPortable;
                else if(this.hasInformations()) {

                    var infos = this.getInformations();
                    for(var i = 0; i < infos.length; i++) {
                        if(infos[i].isModel && infos[i].isRenseignement) {
                            if(infos[i].getReference() && infos[i].getReference().isPortable()) {
                                telPortables.push(infos[i].getDonnee());
                            }
                        }
                    }
                    this.infoTelsPortable = telPortables;
                }
                return telPortables;
            },

            getFaxs: function(){
                var faxs = [];

                if(this.infoFaxs) faxs = this.infoFaxs;
                else if(this.hasInformations()) {

                    var infos = this.getInformations();
                    for(var i = 0; i < infos.length; i++) {
                        if(infos[i].isModel && infos[i].isRenseignement) {
                            if(infos[i].getReference() && infos[i].getReference().isFax()) {
                                faxs.push(infos[i].getDonnee());
                            }
                        }
                    }
                    this.infoFaxs = faxs;
                }
                return faxs;
            },

            // Perso recupéré
            getPerso: function(){
                return this.perso;
            },
            setPerso: function(perso){
                try{
                    if(!perso || !angular.isObject(perso)) throw new Error('Argument invalid!');
                    if(perso.isModel) perso = new Personnalite(perso);
                    this.perso = perso;
                }
                catch(err){
                    console.log('PersoIcs.model Erreur : '+err.message);
                }
            }

        };

        angular.extend(PersoIcs.prototype, Main.prototype);

        return PersoIcs;
    }
})();