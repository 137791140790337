(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .component('adminCollabEditAgence', {
      templateUrl: 'app/admin/comptes/admin-collab-edit-agence/admin-collab-edit-agence.component.html',
      controller: AdminCollabEditAgenceController,
      controllerAs: 'admincollabeditagencectrl',
      bindings: {
        groupe: '<',        // Permet d'afficher ou non le composant "choose-infos-contact"
        onEmit: '&',

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<'
      }
    });

  /** @ngInject */
  function AdminCollabEditAgenceController($injector, AdminCollabService, UtilsService, ModalsService, SocketNotificationService) {
    var _this = this;
    //var oldDiese = null;

    _this.loading = false;
    _this.listGroupePortefeuille = [];
    _this.groupeAgence = {
      idGroupe: null,
      diese: {
        editDisabled: true,
        value: null,
        defaultValue: null
      },
      groupesPortefeuille: []
    };

    _this.onClickEditInput = onClickEditInput;
    _this.onSubmitEditAgence = onSubmitEditAgence;
    //_this.onClickSave = onClickSave;
    _this.onClickClose = onClickClose;
    _this.isNil = _.isNil;

    /********** Global **********/
    _this.$onInit = function () {
      if(_this.resolve && _this.modalInstance) {
        _this.groupe = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'groupe'));
      }
      if(!_this.groupe) ModalsService.alertErreur('Pas de groupe');
      init();
    };

    _this.$onChanges = function(changes){
    };

    _this.$onDestroy = function(){
      SocketNotificationService.removeListener('updateChangeNomSociete');
    };

    function init(){
      _this.typeProgress = 'info';
      _this.step = 0;
      _this.nbStep = 0;
      //oldDiese = angular.copy(_this.groupe.dieze);

      _this.groupeAgence.idGroupe = angular.copy(_this.groupe.idGroupe);
      _this.groupeAgence.diese = {
        editDisabled: true,
        value: angular.copy(_this.groupe.dieze),
        defaultValue: angular.copy(_this.groupe.dieze)
      };

      _this.loading = true;
      AdminCollabService
        .searchGroupesPortefeuille(_this.groupe.idGroupe)
        .then(function(list){
          //_this.listGroupePortefeuille = list;
          if(_.isArray(list) && list.length) {
            for(var i = 0; i < list.length; i++){
              list[i]
              _this.groupeAgence.groupesPortefeuille.push({
                editDisabled: true,
                value: angular.copy(list[i]),
                defaultValue: angular.copy(list[i])
              });
            }
          }
        })
        .finally(function(){
          _this.loading = false;
        });
    }

    function onClickEditInput(obj){
      if(!obj.editDisabled) obj.value = angular.copy(obj.defaultValue);
      obj.editDisabled = !obj.editDisabled;
    }

    function onSubmitEditAgence(){


      var groupeSent = angular.copy(_this.groupeAgence);

      try {


        _this.formSubmit = true;
        _this.nbGroupeUpdate = null;
        _this.nbFicheUpdate = null;
        _this.nbContenuUpdate = null;
        _this.nbTopicUpdate = null;
        _this.nbCollabAuthUser = null;
        _this.nbModuleParamsUpdate = null;
        _this.infosPortefeuilles = {};

        var newDiese = null;

        _this.updateDieseAgence = false;
        if (!groupeSent.diese.editDisabled) {

          if(groupeSent.diese.defaultValue === groupeSent.diese.value) throw new Error('Le diese est le même qu\'avant !');

          newDiese = groupeSent.diese.value;
          _this.nbStep = _this.nbStep + 6;
          _this.updateDieseAgence = true;
        }

        _this.updateGroupesPortefeuille = false;
        _this.listGroupePortefeuilleUpdate = [];

        if(groupeSent.groupesPortefeuille.length) {
          var ProxyDestinataire = $injector.get('ProxyDestinataire');
          for(var p = 0; p < groupeSent.groupesPortefeuille.length; p++){
            var currentPort = groupeSent.groupesPortefeuille[p];
            // Si l'ancienne valeur est différente de la nouvelle
            if(!currentPort.editDisabled && currentPort.defaultValue.libelle !==  currentPort.value.libelle) {
              _this.updateGroupesPortefeuille = true;

              _this.infosPortefeuilles[currentPort.value.idGroupe] = {
                oldLibelle: currentPort.defaultValue.libelle,
                erreurGroupePortefeuille: null,
                nbGroupeUpdatePortefeuille: null,
                nbFicheUpdatePortefeuille: null,
                nbContenuUpdatePortefeuille: null,
                nbTopicUpdatePortefeuille: null,
                nbCollabAuthUserPortefeuille: null,
                nbModuleParamsUpdatePortefeuille: null
              };

              _this.nbStep = _this.nbStep + 6;
              _this.listGroupePortefeuilleUpdate.push(new ProxyDestinataire(currentPort.value));
            }
          }
        }

        SocketNotificationService.on('updateChangeNomSociete', function(json){
          if(_.isObject(json) && json.success) {
            _this.step++;
            if(!_.isNil(json.nbGroupeUpdate))   _this.nbGroupeUpdate = json.nbGroupeUpdate;
            if(!_.isNil(json.nbFicheUpdate))    _this.nbFicheUpdate = json.nbFicheUpdate;
            if(!_.isNil(json.nbContenuUpdate))  _this.nbContenuUpdate = json.nbContenuUpdate;
            if(!_.isNil(json.nbTopicUpdate))    _this.nbTopicUpdate = json.nbTopicUpdate;
            if(!_.isNil(json.nbCollabAuthUser)) _this.nbCollabAuthUser = json.nbCollabAuthUser;
            if(!_.isNil(json.nbModuleParamsUpdate)) _this.nbModuleParamsUpdate = json.nbModuleParamsUpdate;

            if(_.isArray(_this.listGroupePortefeuilleUpdate) && _this.listGroupePortefeuilleUpdate.length) {

              for(var p = 0; p < _this.listGroupePortefeuilleUpdate.length; p++){
                var idGroupe = _this.listGroupePortefeuilleUpdate[p].idGroupe;

                if(!_.isNil(json['nbGroupeUpdatePortefeuille' + idGroupe])) {
                  _this.infosPortefeuilles[idGroupe].nbGroupeUpdatePortefeuille = json['nbGroupeUpdatePortefeuille' + idGroupe];
                }

                if(!_.isNil(json['nbFicheUpdatePortefeuille' + idGroupe])) {
                  _this.infosPortefeuilles[idGroupe].nbFicheUpdatePortefeuille = json['nbFicheUpdatePortefeuille' + idGroupe];
                }

                if(!_.isNil(json['nbContenuUpdatePortefeuille' + idGroupe])) {
                  _this.infosPortefeuilles[idGroupe].nbContenuUpdatePortefeuille = json['nbContenuUpdatePortefeuille' + idGroupe];
                }

                if(!_.isNil(json['nbTopicUpdatePortefeuille' + idGroupe])) {
                  _this.infosPortefeuilles[idGroupe].nbTopicUpdatePortefeuille = json['nbTopicUpdatePortefeuille' + idGroupe];
                }

                if(!_.isNil(json['nbCollabAuthUserPortefeuille' + idGroupe])) {
                  _this.infosPortefeuilles[idGroupe].nbCollabAuthUserPortefeuille = json['nbCollabAuthUserPortefeuille' + idGroupe];
                }

                if(!_.isNil(json['nbModuleParamsUpdatePortefeuille' + idGroupe])) {
                  _this.infosPortefeuilles[idGroupe].nbModuleParamsUpdatePortefeuille = json['nbModuleParamsUpdatePortefeuille' + idGroupe];
                }

                if(!_.isNil(json['erreurGroupePortefeuille' + idGroupe])) {
                  _this.infosPortefeuilles[idGroupe].nbGroupeUpdatePortefeuille = 0;
                  _this.infosPortefeuilles[idGroupe].nbFicheUpdatePortefeuille = 0;
                  _this.infosPortefeuilles[idGroupe].nbContenuUpdatePortefeuille = 0;
                  _this.infosPortefeuilles[idGroupe].nbTopicUpdatePortefeuille = 0;
                  _this.infosPortefeuilles[idGroupe].nbCollabAuthUserPortefeuille = 0;
                  _this.infosPortefeuilles[idGroupe].nbModuleParamsUpdatePortefeuille = 0;
                  _this.infosPortefeuilles[idGroupe].erreurGroupePortefeuille = true;
                  _this.formSubmit = false;
                  _this.step = 0;
                }
              }
            }
          }
        });

        if(_this.updateDieseAgence && !_.isNil(newDiese)) {
          AdminCollabService
            .changeNomSociete(_this.groupe.idGroupe, newDiese, _this.listGroupePortefeuilleUpdate)
            .then(function(groupe){
              if(_.isArray(groupe) && groupe.length) {
                for(var g = 0; g < groupe.length; g++){
                  if(groupe[g].idGroupe === _this.groupe.idGroupe) {
                    _this.groupe = groupe[g];
                  }
                }
              }
              _this.typeProgress = 'success';
            })
            .catch(function(msg){
              ModalsService.alertErreur(msg);
              _this.listGroupePortefeuilleUpdate = [];
              _this.formSubmit = false;
              _this.step = 0;
            })
            .finally(function(){
            });
        }
        // Seulement les portefeuilles
        else if(_this.listGroupePortefeuilleUpdate.length){
          AdminCollabService
            .changeNomPortefeuilles(_this.listGroupePortefeuilleUpdate)
            .then(function(groupe){
              _this.typeProgress = 'success';
            })
            .catch(function(msg){
              ModalsService.alertErreur(msg);
              _this.listGroupePortefeuilleUpdate = [];
              _this.formSubmit = false;
              _this.step = 0;
            })
            .finally(function(){
            });
        }
        else {
          _this.formSubmit = false;
          _this.step = 0;
        }

      }
      catch(err) {
        ModalsService.alertErreur(err.message);
        _this.listGroupePortefeuilleUpdate = [];
        _this.formSubmit = false;
        _this.step = 0;
      }
    }

    /**
     * Lors du clique sur le bouton pour sauvegarder
     */
    /*
    function onClickSave(){
      try {
        if(oldDiese === _this.groupe.dieze) throw new Error('Le diese est le même qu\'avant !');
        _this.formSubmit = true;
        _this.nbGroupeUpdate = null;
        _this.nbFicheUpdate = null;
        _this.nbContenuUpdate = null;
        _this.nbTopicUpdate = null;
        _this.nbCollabAuthUser = null;
        _this.nbModuleParamsUpdate = null;

        SocketNotificationService.on('updateChangeNomSociete', function(json){
          if(_.isObject(json) && json.success) {
            _this.step++;
            if(!_.isNil(json.nbGroupeUpdate))   _this.nbGroupeUpdate = json.nbGroupeUpdate;
            if(!_.isNil(json.nbFicheUpdate))    _this.nbFicheUpdate = json.nbFicheUpdate;
            if(!_.isNil(json.nbContenuUpdate))  _this.nbContenuUpdate = json.nbContenuUpdate;
            if(!_.isNil(json.nbTopicUpdate))    _this.nbTopicUpdate = json.nbTopicUpdate;
            if(!_.isNil(json.nbCollabAuthUser)) _this.nbCollabAuthUser = json.nbCollabAuthUser;
            if(!_.isNil(json.nbModuleParamsUpdate)) _this.nbModuleParamsUpdate = json.nbModuleParamsUpdate;
          }

        });

        AdminCollabService
          .changeNomSociete(_this.groupe.idGroupe, _this.groupe.dieze)
          .then(function(groupe){
            if(_.isArray(groupe) && groupe.length) {
              _this.groupe = groupe[0];
            }
            _this.typeProgress = 'success';
          })
          .catch(function(msg){
            ModalsService.alertErreur(msg);
            _this.formSubmit = false;
            _this.step = 0;
          })
          .finally(function(){
          });
      }
      catch(err) {
        ModalsService.alertErreur(err.message);
        _this.formSubmit = false;
        _this.step = 0;
      }
    }*/

    /**
     * Fermeture du composant si en mode modal
     */
    function onClickClose(){
      if(_this.formSubmit) _this.close({$value: {action: 'updateGroupe', groupe: _this.groupe}});
      else _this.dismiss({$value: 'cancel'});
    }

    /**
     * Permet d'envoyer des infos au composant parent
     */
    function emit(objet){
      if(!_this.modalInstance) {
        _this.onEmit({obj: objet});
      }
    }
  }
})();

