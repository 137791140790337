(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.EvenementService
   * @description
   * # DevisService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('EvenementService', EvenementService);

  /*@ngInject*/
  function EvenementService(MainService, $q, $injector, ContenuEvenement, EvenementAjax, ModalsService) {

    var ctrl = this;

    ctrl.getEvenementForIdTopic = getEvenementForIdTopic;
    ctrl.updateFieldByField = updateFieldByField;

    ctrl.deleteDocumentByGuid = deleteDocumentByGuid;

    ctrl.createDevis = createDevis;
    ctrl.createContrat = createContrat;

    ctrl.createSinistre = createSinistre;
    ctrl.deleteSinistre = deleteSinistre;

    ctrl.changeToEnCours = changeToEnCours;
    ctrl.changeToEnAttente = changeToEnAttente;
    ctrl.changeToTermine = changeToTermine;
    ctrl.changeToTermineCloture = changeToTermineCloture;
    ctrl.changeToProbleme = changeToProbleme;
    ctrl.toggleTagPresence = toggleTagPresence;
    ctrl.changeToPresenceObligatoire = changeToPresenceObligatoire;
    ctrl.changeToPresenceFacultative = changeToPresenceFacultative;

    function postServlet(action, obj) {
      var deferred = $q.defer();
      try {
        if (!action) throw new Error('Le paramètre "action" n\'existe pas !');

        if (!angular.isObject(obj) && !angular.isNumber(obj)) throw new Error('Le paramètre "obj" doit être un objet un un nombre !');
        if (angular.isObject(obj) && !obj.hasOwnProperty('idEvenement') && !obj.hasOwnProperty('idContenu')) throw new Error('Le paramètre "obj" doit avoir un idEvenement ou idContenu !');

        var sendParams = angular.merge({}, obj);
        sendParams.action = action;
        sendParams.idEvenement = (angular.isNumber(obj)) ? obj : ((obj.hasOwnProperty('idEvenement')) ? obj.idEvenement : obj.idContenu);
        delete sendParams.idContenu;

        EvenementAjax
          .post(sendParams, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, 'evenement', ContenuEvenement, deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /* Public Methods */

    function getEvenementForIdTopic(idTopic){
      var deferred = $q.defer();
      postServlet('getEvenementFromTopic', idTopic)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de modifier un evenement champ par champ
     * @param obj
     * @returns {IPromise<T>}
     */
    function updateFieldByField(obj) {
      var deferred = $q.defer();
      postServlet('update', obj)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de supprimer un document du evenement
     * @param obj
     * @returns {IPromise<T>}
     */
    function deleteDocumentByGuid(obj) {
      var deferred = $q.defer();
      postServlet('deleteDocumentByGuid', obj)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }


    /**
     * Permet de créer un Devis dans l'evenement
     * @param idEvenement
     * @param objetDevis
     * @returns {Promise}
     */
    function createDevis(idEvenement, objetDevis) {
      var deferred = $q.defer();

      var copyDevis = angular.copy(objetDevis);

      // Si il y a des destinataires dans l'objet devis, on va les changer de d'attribut car "destinataires" doit disparaitre si possible
      if (copyDevis.destinataires.length) copyDevis.listeDestinataires = angular.copy(copyDevis.destinataires);
      copyDevis.destinataires = [];

      // Si pas de destinataires dans la liste des autres destinataire de la demande
      if (!copyDevis.listeDestinatairesMessageGravitants.length) copyDevis.messageGravitants = '';

      var ProxyContenuDevis = $injector.get('ProxyContenuDevis');
      var params = {
        idEvenement: idEvenement,
        action: 'createDevis',
        json: new ProxyContenuDevis(copyDevis).toJson()
      };
      ProxyContenuDevis = null;

      EvenementAjax
        .post(params, function (json) {
            var ContenuEvenementDevis = $injector.get('ContenuEvenementDevis');
            deferred.resolve(MainService.convertJsonToModel(json, 'listDevis', ContenuEvenementDevis, deferred));
            ContenuEvenementDevis = null;
          },
          function (msg) {
            deferred.reject(msg);
          });
      return deferred.promise;
    }

    /**
     * Permet de créer un Contrat dans l'evenement
     * @param idEvenement
     * @param objetContrat
     * @returns {Promise}
     */
    function createContrat(idEvenement, objetContrat) {
      var deferred = $q.defer();

      var copyContrat = angular.copy(objetContrat);

      // Si il y a des destinataires dans l'objet devis, on va les changer de d'attribut car "destinataires" doit disparaitre si possible
      if (copyContrat.destinataires.length) copyContrat.listeDestinataires = angular.copy(copyContrat.destinataires);
      copyContrat.destinataires = [];

      var ProxyContenuContrat = $injector.get('ProxyContenuContrat');
      var params = {
        idEvenement: idEvenement,
        action: 'createContrat',
        json: new ProxyContenuContrat(copyContrat).toJson()
      };
      ProxyContenuContrat = null;

      EvenementAjax
        .post(params, function (json) {
            deferred.resolve(MainService.convertJsonToModel(json, ['listContrats','contrat'], 'ContenuEvenementContrat', deferred));
          },
          function (msg) {
            deferred.reject(msg);
          });
      return deferred.promise;
    }

    function createSinistre(idEvenement, objSinistre) {
      var deferred = $q.defer();

      var SinistreService = $injector.get('SinistreService');

      var params = SinistreService.initParamsSinistre(objSinistre);
      params.idEvenement = idEvenement;

      postServlet('createSinistre', params)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    function deleteSinistre(idEvenement) {
      var deferred = $q.defer();
      postServlet('deleteSinistre', idEvenement)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    function changeToEnCours(idEvent, typeIntervenant){
      var deferred = $q.defer();
      var params = {
        action: 'changeToEnCours',
        idEvenement: idEvent,
        typeIntervenant: typeIntervenant
      };

      EvenementAjax
        .post(params, function (json) {
          if(json.success) {
            var retour = {
              evenement: null,
              message: null
            };
            retour.evenement = new ContenuEvenement(json.evenement);
            if(json.contenuMessage){
              var ContenuMessage = $injector.get('ContenuMessage');
              retour.message = new ContenuMessage(json.contenuMessage);
              ContenuMessage = null;
            }
            deferred.resolve(retour);
          }
          else deferred.reject(json.message);
        }, function (msg) {
          deferred.reject(msg);
        });
      return deferred.promise;
    }

    function changeToEnAttente(idEvent, typeIntervenant){
      var deferred = $q.defer();
      var params = {
        action: 'changeToEnAttente',
        idEvenement: idEvent,
        typeIntervenant: typeIntervenant
      };

      EvenementAjax
        .post(params, function (json) {
          if(json.success) {
            var retour = {
              evenement: null,
              message: null
            };
            retour.evenement = new ContenuEvenement(json.evenement);
            if(json.contenuMessage){
              var ContenuMessage = $injector.get('ContenuMessage');
              retour.message = new ContenuMessage(json.contenuMessage);
              ContenuMessage = null;
            }
            deferred.resolve(retour);
          }
          else deferred.reject(json.message);
        }, function (msg) {
          deferred.reject(msg);
        });
      return deferred.promise;
    }

    function changeToTermine(idEvent){
      var deferred = $q.defer();
      var params = {
        action: 'changeToTermine',
        idEvenement: idEvent
      };

      EvenementAjax
        .post(params, function (json) {
          if(json.success) {
            var retour = {
              evenement: null,
              message: null
            };
            retour.evenement = new ContenuEvenement(json.evenement);
            if(json.contenuMessage){
              var ContenuMessage = $injector.get('ContenuMessage');
              retour.message = new ContenuMessage(json.contenuMessage);
              ContenuMessage = null;
            }
            deferred.resolve(retour);
          }
          else deferred.reject(json.message);
        }, function (msg) {
          deferred.reject(msg);
        });
      return deferred.promise;
    }

    function changeToTermineCloture(idEvent){
      var deferred = $q.defer();
      var params = {
        action: 'changeToTermineCloture',
        idEvenement: idEvent
      };

      EvenementAjax
        .post(params, function (json) {
          if(json.success) {
            var retour = {
              evenement: null,
              message: null
            };
            retour.evenement = new ContenuEvenement(json.evenement);
            if(json.contenuMessage){
              var ContenuMessage = $injector.get('ContenuMessage');
              retour.message = new ContenuMessage(json.contenuMessage);
              ContenuMessage = null;
            }
            deferred.resolve(retour);
          }
          else deferred.reject(json.message);
        }, function (msg) {
          deferred.reject(msg);
        });
      return deferred.promise;
    }

    function changeToProbleme(idEvent, commentaire, typeIntervenant){
      var deferred = $q.defer();
      var params = {
        action: 'changeToProbleme',
        idEvenement: idEvent,
        commentaire: commentaire,
        typeIntervenant: typeIntervenant
      };

      EvenementAjax
        .post(params, function (json) {
          if(json.success) {
            var retour = {
              evenement: null,
              message: null
            };
            retour.evenement = new ContenuEvenement(json.evenement);
            if(json.contenuMessage){
              var ContenuMessage = $injector.get('ContenuMessage');
              retour.message = new ContenuMessage(json.contenuMessage);
              ContenuMessage = null;
            }
            deferred.resolve(retour);
          }
          else deferred.reject(json.message);
        }, function (msg) {
          deferred.reject(msg);
        });
      return deferred.promise;
    }

    function toggleTagPresence(idEvent){
      var deferred = $q.defer();
      postServlet('toggleTagPresence', idEvent)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    function changeToPresenceObligatoire(idEvent){
      var deferred = $q.defer();
      postServlet('changeToPresenceObligatoire', idEvent)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    function changeToPresenceFacultative(idEvent){
      var deferred = $q.defer();
      postServlet('changeToPresenceFacultative', idEvent)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

  }

})();
