(function () {
  'use strict';

  /**
   * @desc Collab fournisseur détail d'une demande
   * @example <collab-fournisseur-demande-timeline></collab-fournisseur-demande-timeline>
   */

  angular
    .module('collaboreApp')
    .component('collabFournisseurDemandeTimeline', {
      templateUrl: 'app/collab-fournisseur/collab-fournisseur-detail/collab-fournisseur-demande-timeline.component.html',
      controllerAs: 'collabfournisseurdemandetimelinectrl',
      bindings: {
        demande: '<',       // Demande
        listMessage: '<',   // Liste des messages
        onEmit: '&'
      },
      controller: CollabFournisseurDemandeTimelineController
    });

  /*@ngInject*/
  function CollabFournisseurDemandeTimelineController($scope, DocumentManagerService, UtilsService) {

    var _this = this;
    var limitMessageLength = 20;
    var momentNow = moment();

    var myGroupe = UtilsService.getCurrentUser();

    _this.loadingComponent = false;
    _this.errorComponent = null;

    _this.listActionByDay = [];

    _this.onClickDownloadDocument = onClickDownloadDocument;

    _this.$onInit = function () {
      if(_.isNil(_this.demande)){
        _this.errorComponent = "Il manque la demande.";
        return;
      }
      init(_this.demande);
      $scope.$on('updateTimeline', function (event, demande) {
        //console.log('updateTimeline');
        if(_this.demande.getIdContenu() === demande.getIdContenu()){
          _this.demande = demande;
          init(demande);
        }
      });
    };

    _this.$onChanges = function (changes) {
      if(_.isObject(changes.demande) && !changes.demande.isFirstChange() && !_.isNil(changes.demande.currentValue)){
        _this.idTopic = changes.demande.currentValue;
        init(_this.demande);
      }
    };

    _this.$onDestroy = function(){
    };

    function init(demande){
      if(_.isObject(demande)) {
        initCurrentStep(demande);
        _this.listActionByDay = [];

        var objetsByDate = generateObjFromContenu(demande);

        if(_.isArray(_this.listMessage) && _this.listMessage.length){
          for(var i = 0; i < _this.listMessage.length; i++) {
            if(_this.listMessage[i].getIdContenuRef() === demande.getIdContenu()) {
              objetsByDate = generateObjFromContenu(_this.listMessage[i], objetsByDate);
            }
          }
        }

        if(_.size(objetsByDate) !== 0) {
          _.forEach(objetsByDate, function(arrayObjet, date) {
            if(arrayObjet.length) arrayObjet.sort(function(a,b){return new Date(b.date).getTime() - new Date(a.date).getTime()});
            _this.listActionByDay.push({
              date: date,
              infosDate: getInfosDates(date),
              listAction: arrayObjet
            });
          });
        }

        if(_this.listActionByDay.length) {
          _this.listActionByDay.sort(function(a,b){return new Date(b.date).getTime() - new Date(a.date).getTime()});
        }
        //console.log(_this.listActionByDay);
      }
    }

    /**
     * Permet de récupérer une objet avec des format de date
     * @param date
     * @returns {{date: (*), heure: *, jour: *, annee: (null|*), mois: *}}
     */
    function getInfosDates(date){
      var momentDate = (_.isObject(date) && date instanceof moment) ? date : moment(date);
      var isSameYear = momentDate.isSame(momentNow, 'year');
      var retour = {
        date: momentDate.format('YYYY-MM-DD'),
        jour: momentDate.format('DD'),
        mois: momentDate.format('MMMM').ucfirst(),
        annee: (isSameYear) ? null : momentDate.format('YYYY'),
        heure: momentDate.format('HH:mm')
      };

      return retour;
    }

    /**
     * Permet de générer l'objet qui est insérer dans la liste des contenus par date
     * @param contenu
     * @returns {*}
     */
    function generateObjFromContenu(contenu, objets){

      if(_.isNil(objets)) objets = {};

      // Si demande de devis/intervention ou contrat
      if(_.isObject(contenu)){

        // Si contenu message
        if(contenu.isContenuMessage()) {
          var dateContenu = (contenu.getDateModification()) ? contenu.getDateModification() : contenu.getDateCreation();
          var customClass = ['timeline-message'];
          var position = 'right';
          if(_.isNil(contenu.getExpediteur()) || contenu.getExpediteur().isDestinataireEmail || contenu.getExpediteur().getIdGroupe() !== myGroupe.getIdGroupe()){
            customClass.push('action-agence');
          }
          else {
            customClass.push('action-fournisseur');
            position = 'left';
          }

          var message = UtilsService.stripTags(contenu.getMessage());
          var previsualisationMessage = null;
          if(message.length > limitMessageLength) previsualisationMessage = UtilsService.truncate(message, limitMessageLength, '...', true);

          addEtapeToObjetByDate(objets, {
            type: 'message',
            icon: (contenu.getSms()) ? 'fa-mobile' : 'fa-comments-o',
            customClass: customClass,
            position: position,
            date: dateContenu,
            infosDate: getInfosDates(dateContenu),
            contenu: contenu,
            previsualisationMessage: previsualisationMessage
          });
        }

        if(contenu.isContenuDevisInter() || contenu.isContenuContrat()){
          // Ajoute la demande pour sa création
          addEtapeToObjetByDate(objets, {
            type: 'creationDemande',
            libelle: 'Création de la demande',
            icon: 'fa-folder-o',
            customClass: 'action-agence',
            position: 'left',
            date: contenu.getDateCreation(),
            infosDate: getInfosDates(contenu.getDateCreation()),
            document: contenu.getDocumentDemande()
          });

          // Si demande de type devis/inter
          if(contenu.isContenuDevisInter()) {
            // Si il y a une date de reception du devis
            if(contenu.getDateReceptionDevis()) {
              addEtapeToObjetByDate(objets, {
                type: 'receptionDevis',
                libelle: 'Dépot du devis',
                icon: 'fa-inbox',
                customClass: 'action-fournisseur',
                position: 'right',
                date: contenu.getDateReceptionDevis(),
                infosDate: getInfosDates(contenu.getDateReceptionDevis()),
                document: contenu.getDocumentDevis()
              });
            }

            // Si il y a une facture
            if(contenu.hasFacture()){
              addEtapeToObjetByDate(objets, {
                type: 'receptionFacture',
                libelle: 'Dépot de la facture',
                icon: 'fa-inbox',
                customClass: 'action-fournisseur',
                position: 'right',
                date: contenu.getFacture().getDateCreation(),
                infosDate: getInfosDates(contenu.getFacture().getDateCreation()),
                document: contenu.getDocumentFacture()
              });

              // Si la facture est traitée
              if(contenu.hasFactureTraite()){
                addEtapeToObjetByDate(objets, {
                  type: 'factureTraitee',
                  libelle: 'Facture en traitement par l\'agence',
                  icon: 'fa-check',
                  customClass: 'action-agence timeline-demande-end',
                  position: 'left',
                  date: contenu.getDateTraitementFacture(),
                  infosDate: getInfosDates(contenu.getDateTraitementFacture())
                });
              }

            }

            // Si demande acceptée
            if(contenu.isAccepte()) {

              // Si il y a une date d'acceptation de la demande
              if(contenu.getDateAcceptation()) {
                addEtapeToObjetByDate(objets, {
                  type: 'acceptationDemande',
                  libelle: 'Devis validé par l\'agence',
                  icon: 'fa-check',
                  customClass: 'action-agence action-success',
                  position: 'left',
                  date: contenu.getDateAcceptation(),
                  infosDate: getInfosDates(contenu.getDateAcceptation()),
                  document: contenu.getDocumentDevis()
                });
              }

              // Si il y a une date de signature de la demande
              if(contenu.getDateDevisSigne()) {
                addEtapeToObjetByDate(objets, {
                  type: 'signatureDevis',
                  libelle: 'Devis signé par l\'agence',
                  icon: 'fa-pencil',
                  customClass: 'action-agence',
                  position: 'left',
                  date: contenu.getDateDevisSigne(),
                  infosDate: getInfosDates(contenu.getDateDevisSigne()),
                  document: contenu.getDocumentDevisSigne()
                });
              }
            }

            // Si il y a une date de refus du devis alors demande refusée
            else if(contenu.getDateRefus()){
              addEtapeToObjetByDate(objets, {
                type: 'refusDemande',
                libelle: 'Devis refusé par l\'agence',
                icon: 'fa-times',
                customClass: 'action-agence action-danger',
                position: 'left',
                date: contenu.getDateRefus(),
                infosDate: getInfosDates(contenu.getDateRefus())
              });
            }
          }
          // Si demande de type contrat
          else if(contenu.isContenuContrat()) {

            // Si il y a des contrats envoyé par le fournisseur
            if(contenu.hasContratFournisseurSigne()) {
              for(var c = 0; c < contenu.getListDocumentContratFournisseurSigne().length; c++){
                var contratSigne = contenu.getListDocumentContratFournisseurSigne()[c];

                // Reception du contrat signé par le fournisseur
                addEtapeToObjetByDate(objets, {
                  type: 'receptionContratFournisseurSigne',
                  libelle: 'Dépot du contrat <small>('+contratSigne.getNomFichier()+')</small>',
                  icon: 'fa-inbox',
                  customClass: 'action-fournisseur',
                  position: 'right',
                  date: contratSigne.getDateUpload(),
                  infosDate: getInfosDates(contratSigne.getDateUpload()),
                  document: contratSigne
                });

                // Si le contrat est accepté
                if(contratSigne.isAccepted()) {
                  // Acceptation du contrat signé par le fournisseur
                  addEtapeToObjetByDate(objets, {
                    type: 'acceptationContratFournisseurSigne',
                    libelle: 'Contrat validé par l\'agence <small>('+contratSigne.getNomFichier()+')</small>',
                    icon: 'fa-check',
                    customClass: 'action-agence action-success',
                    position: 'left',
                    date: contratSigne.getDateAcceptation(),
                    infosDate: getInfosDates(contratSigne.getDateAcceptation()),
                    document: contratSigne
                  });

                  /*
                  // Si le contrat est signé par l'agence
                  if(contratSigne.isSigned()) {
                      // Acceptation du contrat signé par le fournisseur
                      addEtapeToObjetByDate(objets, {
                          type: 'signatureContratFournisseurSigne',
                          isCopy: true,
                          position: 'left',
                          date: contratSigne.getDateSignature(),
                          dateDay: contratSigne.getDateSignature('YYYY-MM-DD'),
                          contenu: contenu,
                          document: contratSigne
                      });
                  }*/
                }
                // Si le contrat est refusé
                else if(contratSigne.getDateRefus()) {
                  // Acceptation du contrat signé par le fournisseur
                  addEtapeToObjetByDate(objets, {
                    type: 'refusContratFournisseurSigne',
                    libelle: 'Contrat refusé par l\'agence <small>('+contratSigne.getNomFichier()+')</small>',
                    icon: 'fa-times',
                    customClass: 'action-agence action-danger',
                    position: 'left',
                    date: contratSigne.getDateRefus(),
                    infosDate: getInfosDates(contratSigne.getDateRefus()),
                    document: contratSigne
                  });
                }
              }
            }

            // Si il y a des contrats fournisseur signé par l'agence
            if(contenu.hasContratAgenceSigne()) {
              for(var ca = 0; ca < contenu.getListDocumentContratAgenceSigne().length; ca++) {
                var contratAgenceSigne = contenu.getListDocumentContratAgenceSigne()[ca];

                // Signature par l'agence du contrat fournisseur
                addEtapeToObjetByDate(objets, {
                  type: 'signatureContratFournisseurSigne',
                  libelle: 'Contrat signé par l\'agence <small>('+contratAgenceSigne.getNomFichier()+')</small>',
                  icon: 'fa-pencil',
                  customClass: 'action-agence',
                  position: 'left',
                  date: contratAgenceSigne.getDateSignature(),
                  infosDate: getInfosDates(contratAgenceSigne.getDateSignature()),
                  document: contratAgenceSigne
                });
              }
            }

          }

          /*
          // Bug lors de l'update de la timeline, il ne se trouve plus en 1er car c'est basé sur la date et la nouvelle action est plus récente
          if(contenu.currentStep !== -1){
            var currentEtape = getCurrentLibelleState(contenu);
            if(_.isObject(currentEtape)) {
              addEtapeToObjetByDate(objets, {
                type: 'endTimeline',
                libelle: currentEtape.libelle,
                icon: 'fa-clock-o',
                customClass: 'timeline-demande-end '+currentEtape.customClass,
                date: momentNow.format(),
                infosDate: getInfosDates(momentNow),
              });
            }
          }*/

          // Début de la timeline
          /*
          addEtapeToObjetByDate(objets, {
            type: 'startTimeline',
            customClass: 'timeline-demande-start',
            date: contenu.getDateCreation(),
            infosDate: getInfosDates(contenu.getDateCreation())
          });*/
        }

        if(contenu.getListeRelance().length) {
          contenu.getListeRelance().map(function(objRelance){
            addEtapeToObjetByDate(objets, {
              type: 'relanceDemande',
              libelle: 'Relance ' + objRelance.getDateRelanceAgo(),
              icon: 'fa-paper-plane-o',
              customClass: 'action-agence',
              position: 'left',
              date: objRelance.getDateRelance(),
              infosDate: getInfosDates(objRelance.getDateRelance())
            });
          });
        }

        if(contenu.isCloture()) {
          var libelle = 'Cloturé';
          if(_.isObject(contenu.getInfosCloture()) && _.isObject(contenu.getInfosCloture().getGroupe())) libelle += ' par <strong>' + contenu.getInfosCloture().getGroupe().getDefaultLibelle() + '</strong>';
          libelle += ' le <strong>' + contenu.getDateCloture('D MMMM YYYY à HH:mm') + '</strong>';

          addEtapeToObjetByDate(objets, {
            type: 'clotureDemande',
            hideTime: true,
            libelle: libelle,
            icon: 'fa-window-close-o',
            customClass: 'action-agence action-danger',
            position: 'left',
            date: contenu.getDateCloture(),
            infosDate: getInfosDates(contenu.getDateCloture())
          });
        }
      }

      return objets;
    }

    function addContenuInListByDate(contenu){
      var listActionByDay = [];
      var objetsByDate = generateObjFromContenu(contenu);
      if(_.size(objetsByDate) !== 0) {
        _.forEach(objetsByDate, function(arrayObjet, date) {
          if(arrayObjet.length) arrayObjet.sort(function(a,b){return new Date(b.date).getTime() - new Date(a.date).getTime()});
          listActionByDay.push({
            date: date,
            infosDate: getInfosDates(date),
            listAction: arrayObjet
          });
        });
      }
      return listActionByDay;
    }

    /**
     * Permet d'ajouter une etpa à la liste par date
     * @param tmpList
     * @param obj
     * @returns {*}
     */
    function addEtapeToObjetByDate(tmpList, objets){
      if(_.isArray(objets) && objets.length) {
        for(var o = 0; o < objets.length; o++){
          if(_.isObject(objets[o].infosDate)){
            if(!tmpList.hasOwnProperty(objets[o].infosDate.date)) tmpList[objets[o].infosDate.date] = [];
            tmpList[objets[o].infosDate.date].push(objets[o]);
          }
        }
      }
      else if(_.isObject(objets)) {
        if(_.isObject(objets.infosDate)){
          if(!tmpList.hasOwnProperty(objets.infosDate.date)) tmpList[objets.infosDate.date] = [];
          tmpList[objets.infosDate.date].push(objets);
        }
      }
      return tmpList;
    }

    /**
     * Permet d'ajouter un groupe de contenu pour une date dans la discussion
     * @param date
     * @param tab
     */
    function addListContenuForDate(date, tab, closed){
      if(!_.isArray(tab)) tab = [];

      if(tab.length) tab.sort(function(a,b){return new Date(b.date).getTime() - new Date(a.date).getTime()});

      var dateMoment = moment(date);
      _this.listActionByDay.push({
        date: date,
        dateFormated: dateMoment.format('dddd').ucfirst() + ' ' + dateMoment.format('DD') + ' ' + dateMoment.format('MMMM').ucfirst() + ' ' + dateMoment.format('YYYY'),
        listContenuByDate: tab
      });

    }

    /**
     * Permet d'initialiser le numéro de l'étape de la demande
     * @param currentDemande
     */
    function initCurrentStep(currentDemande){

      currentDemande.currentStep = 0;

      if(currentDemande.isContenuDevisInter()) {

        // Si demande de devis
        if(currentDemande.getDemandeDevisActive()) {
          // Si devis en attente
          if (currentDemande.isWaiting()) {
            currentDemande.currentStep = 0;

            // Si il y a un devis
            if(currentDemande.hasDevis()) currentDemande.currentStep = 1;
          }

          // Si devis accepté
          else if (currentDemande.isAccepte()) {
            currentDemande.currentStep = 2;

            // Si le devis est signé
            if (currentDemande.isSigne()) currentDemande.currentStep = 3;

            // Si le devis est signé et qu'il a une facture
            if (currentDemande.isSigne() && currentDemande.hasFacture()) currentDemande.currentStep = 4;

            // Si la facture est signé
            if (currentDemande.hasFactureTraite()) currentDemande.currentStep = 5;
          }

          // Si devis refusé
          else if (currentDemande.isRefuse())  {
            currentDemande.currentStep = -1;
          }
        }
        // Si demande d'intervention
        else {
          currentDemande.currentStep = 0;

          // Si il y a un devis
          if(currentDemande.getFacture()) currentDemande.currentStep = 1;

          // Si la facture est signé
          if (currentDemande.hasFactureTraite()) currentDemande.currentStep = 2;
        }

      }
      else if(currentDemande.isContenuContrat()) {

        // Si demande en attente du contrat fournisseur signé
        if (currentDemande.isWaiting()) {
          currentDemande.currentStep = 0;

          // Si il y a un contrat signé par le fournisseur
          if(currentDemande.isSignedByFournisseur()) currentDemande.currentStep = 1;
        }

        // Si contrat accepté
        else if (currentDemande.isAccepte()) {
          currentDemande.currentStep = 2;

          // Si le contrat est signé par l'agence
          if (currentDemande.isSignedByAgence()) currentDemande.currentStep = 3;
        }

        // Si contrat refusé
        else if (currentDemande.isRefuse())  {
          currentDemande.currentStep = -1;
        }
      }
    }

    /**
     * Permet de retourner la phrase de l'état d'avancement de la demande
     * @param demande
     * @returns {string}
     */
    function getCurrentLibelleState(demande) {
      var retour = null;

      if(demande.isContenuDevisInter()) {
        // Si demande devis
        if(demande.getDemandeDevisActive()) {
          retour = {
            customClass: 'action-fournisseur',
            libelle: "L'agence est en attente de vos devis"
          };
          switch (demande.currentStep) {
            case 1:
              retour = {
                customClass: 'action-agence',
                libelle: "Attente validation du devis par l'agence"
              };
              break;
            case 2:
              retour = {
                customClass: 'action-agence',
                libelle: "Attente signature du devis par l'agence"
              };
              break;
            case 3:
              retour = {
                customClass: 'action-fournisseur',
                libelle: "L'agence est en attente de votre facture"
              };
              break;
            case 4:
              retour = {
                customClass: 'action-agence',
                libelle: "Facture reçu par l'agence"
              };
              break;
            case 5:
              retour = {
                customClass: 'action-agence',
                libelle: "Facture traitée"
              };
              break;
            case 6:
              retour = {
                customClass: 'action-agence',
                libelle: "Demande cloturé par l'agence"
              };
              break;
            default:
          }
        }
        else {
          retour = {
            customClass: 'action-fournisseur',
            libelle: "L'agence est en attente de votre facture"
          };
          switch (demande.currentStep) {
            case 1:
              retour = {
                customClass: 'action-agence',
                libelle: "Facture reçu par l'agence"
              };
              break;
            case 4:
              retour = {
                customClass: 'action-agence',
                libelle: "Demande cloturé par l'agence"
              };
              break;
            default:
          }
        }
      }
      else if(demande.isContenuContrat()) {
        retour = {
          customClass: 'action-fournisseur',
          libelle: "L'agence est en attente de votre contrat signé"
        };
        switch (demande.currentStep) {
          case 1:
            retour = {
              customClass: 'action-agence',
              libelle: "Contrat(s) reçu(s) par l'agence"
            };
            break;
          case 2:
            retour = {
              customClass: 'action-agence',
              libelle: "Attente de signature du contrat par l'agence"
            };
            break;
          case 3:
            retour = {
              customClass: 'action-agence',
              libelle: "Contrat signé par l'agence"
            };
            break;
          case 4:
            retour = {
              customClass: 'action-agence',
              libelle: "Facture traitée"
            };
            break;
          case 5:
            retour = {
              customClass: 'action-agence',
              libelle: "Demande cloturé par l'agence"
            };
            break;

          default:
        }
      }

      return retour;
    }

    /**
     * Lors du clique sur le bouton pour télécharger le document
     * @param doc
     */
    function onClickDownloadDocument(doc){
      DocumentManagerService.downloadDocument(doc.getGuid(), _this.demande.getIdContenu());
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      _this.onEmit({obj: objRetour});
    }

  }
})();
