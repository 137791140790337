(function () {

  'use strict';

  /**
   * @desc Composant AdminCollab smtp
   * @example <admin-collab-smtp></admin-collab-smtp>
   */
  angular
    .module('collaboreApp')
    .component('adminCollabSmtp', {
      templateUrl: 'app/admin/comptes/admin-collab-smtp.component.html',
      controllerAs: 'admincollabsmtpctrl',
      controller: AdminCollabSmtpCtrl,
      bindings: {
        groupeUser: '<'
      }
    });

  /** @ngInject */
  function AdminCollabSmtpCtrl(COLLAB_CONF, AdminCollabService, UtilsService, sweet) {
    var ctrl = this;

    ctrl.onSubmitSmtp = onSubmitSmtp;
    ctrl.sendEmailTest = sendEmailTest;

    ctrl.emailDestinataireTest = 'testics@yopmail.com';
    ctrl.smtp = {
      loading: {
        show: false,
        texte: COLLAB_CONF.MINI_LOADING_RING
      },
      params: {
        idGroupe: null,
        host: '',
        port: 587,
        tls: false,
        auth: true,
        userSmtp: '',
        userSmtpIsExpediteur: false,
        mdp: ''
      },
      test: {
        error: false,
        success: false,
        texte: '',
        emailDestinataire: ''
      },
      success: false,
      erreur: {
        show: false,
        texte: ''
      }
    };

    ctrl.$onInit = function () {
      ctrl.smtp.params.idGroupe = ctrl.groupeUser.idGroupe;

      if (angular.isDefined(ctrl.groupeUser.getHost()) && ctrl.groupeUser.getHost() !== null) {
        ctrl.smtp.params.host = ctrl.groupeUser.getHost();
      }

      if (angular.isDefined(ctrl.groupeUser.getPort()) && ctrl.groupeUser.getPort() !== null) {
        ctrl.smtp.params.port = ctrl.groupeUser.getPort();
      }

      if (angular.isDefined(ctrl.groupeUser.getUserSmtp()) && ctrl.groupeUser.getUserSmtp() !== null) {
        ctrl.smtp.params.userSmtp = ctrl.groupeUser.getUserSmtp();
      }

      if (angular.isDefined(ctrl.groupeUser.getUserSmtpIsExpediteur()) && ctrl.groupeUser.getUserSmtpIsExpediteur() !== null) {
        if (ctrl.groupeUser.getUserSmtpIsExpediteur() === 'true') {
          ctrl.smtp.params.userSmtpIsExpediteur = true;
        }
        else if (ctrl.groupeUser.getUserSmtpIsExpediteur() === 'false') {
          ctrl.smtp.params.userSmtpIsExpediteur = false;
        }
        else {
          ctrl.smtp.params.userSmtpIsExpediteur = ctrl.groupeUser.getUserSmtpIsExpediteur();
        }
      }

      if (angular.isDefined(ctrl.groupeUser.getMdpClaire()) && ctrl.groupeUser.getMdpClaire() !== null) {
        ctrl.smtp.params.mdp = ctrl.groupeUser.getMdpClaire();
      }

      if (angular.isDefined(ctrl.groupeUser.getAuth()) && ctrl.groupeUser.getAuth() !== null) {
        if (ctrl.groupeUser.getAuth() === 'true') {
          ctrl.smtp.params.auth = true;
        }
        else if (ctrl.groupeUser.getAuth() === 'false') {
          ctrl.smtp.params.auth = false;
        }
        else {
          ctrl.smtp.params.auth = ctrl.groupeUser.getAuth();
        }
      }

      if (angular.isDefined(ctrl.groupeUser.getTls()) && ctrl.groupeUser.getTls() !== null) {
        if (ctrl.groupeUser.getTls() === 'true') {
          ctrl.smtp.params.tls = true;
        }
        else if (ctrl.groupeUser.getTls() === 'false') {
          ctrl.smtp.params.tls = false;
        }
        else {
          ctrl.smtp.params.tls = ctrl.groupeUser.getTls();
        }
      }
    };

    /**
     * Lorsque le formulaire du SMTP est submit
     * @param formSmtp - Model du formulaire pour tester le $valid
     */
    function onSubmitSmtp(formSmtp) {

      if (!formSmtp.$valid) {
        return;
      }
      ctrl.smtp.loading.show = true;
      ctrl.smtp.erreur.show = false;
      ctrl.smtp.success = false;

      AdminCollabService
        .changeSmtpForGroupe(ctrl.smtp.params)
        .then(function (groupe) {
          ctrl.smtp.success = true;
          UtilsService.setChamp(ctrl.groupeUser, groupe, 'host');
          UtilsService.setChamp(ctrl.groupeUser, groupe, 'port');
          UtilsService.setChamp(ctrl.groupeUser, groupe, 'auth');
          UtilsService.setChamp(ctrl.groupeUser, groupe, 'tls');
          UtilsService.setChamp(ctrl.groupeUser, groupe, 'userSmtp');
          UtilsService.setChamp(ctrl.groupeUser, groupe, 'userSmtpIsExpediteur');
          UtilsService.setChamp(ctrl.groupeUser, groupe, 'mdpClaire');

        })
        .catch(function (msg) {
          ctrl.smtp.erreur.show = true;
          ctrl.smtp.erreur.texte = msg;
        })
        .finally(function () {
          ctrl.smtp.loading.show = false;
        });

    }

    function sendEmailTest() {
      ctrl.smtp.test.success = false;
      ctrl.smtp.test.error = false;
      ctrl.smtp.test.texte = '';

      if (ctrl.smtp.test.emailDestinataire !== '') {
        ctrl.smtp.loading.show = true;

        sweet.show({
          title: 'Email Test',
          text: 'Envoyer un email test à <strong>' + ctrl.smtp.test.emailDestinataire + '</strong> ?',
          type: 'warning',
          html: true,
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Oui !',
          closeOnConfirm: false,
          showLoaderOnConfirm: true
        }, function (isConfirm) {
          if (isConfirm) {
            AdminCollabService
              .sendEmailTest(ctrl.smtp.params, ctrl.smtp.test.emailDestinataire)
              .then(function () {
                ctrl.smtp.test.success = true;
                ctrl.smtp.test.texte = 'Email envoyé ! N\'oubliez pas de sauvegarder les paramètres !';
              })
              .catch(function (msg) {
                ctrl.smtp.test.error = true;
                ctrl.smtp.test.texte = msg;
              })
              .finally(function () {
                sweet.close();
              });
          }
          ctrl.smtp.loading.show = false;
        });
      }
      else {
        sweet.show('Problème...', 'Veuillez renseigner un email de destinataire', 'error');
      }
    }
  }
})();
