(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.RechercheService
   * @description
   * # RechercheService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('RechercheService',
      RechercheServiceController
    );

  /** @ngInject */
  function RechercheServiceController(MainService, Topic, $q, RechercheAjax, ErreurCollabService) {
    var ctrl = this;


    ctrl.searchCollab = searchCollab;
    ctrl.searchCollabByIdTopic = searchCollabByIdTopic;


    function searchCollab(obj){
      var deferred = $q.defer();

      RechercheAjax.rechercheSearchCollab(obj, function(json){
        var obj = null;
        if(json.error) {
          obj = {error: true};
        }
        else {
          obj = {
            nb: parseInt(json.nb),
            total: parseInt(json.total),
            totalTime: parseInt(json.stats.totalTime),
            topics: MainService.convertJsonToModel(json, 'topics', Topic)
          };
        }

        deferred.resolve(obj);
      },function(error){
        ErreurCollabService.erreurServlet(error,'Erreur avec RechercheService.searchCollab()',deferred);
      });


      return deferred.promise;
    }

    function searchCollabByIdTopic(idTopic){
      var deferred = $q.defer();

      RechercheAjax.get({action: 'searchTopics', searchByidTopic: idTopic}, function(json){
        deferred.resolve(MainService.convertJsonToModel(json, 'TopicStates', 'TopicStates', deferred));
      },function(error){
        ErreurCollabService.erreurServlet(error,'Erreur avec RechercheService.searchCollabByIdTopic()',deferred);
      });
      return deferred.promise;
    }

  }
})();
