'use strict';

/**
 * @ngdoc getNom
 * @name collaboreApp.filter:collaboreFilter
 * @function
 * @description
 * # collaboreFilter
 * Filter in the collaboreApp.
 */
angular.module('collaboreApp').filter('getNom', ['$filter',function($filter) {
	return function (document,displayExtension,limit) {
		
		var nom = '';
		if(angular.isObject(document)){
			if(angular.isDefined(document.nom)){
				if(document.nom.length>limit) {
					nom += $filter('truncate')(document.nom,limit,'...');
				}else{
					nom += document.nom;
				}
			}

			if(displayExtension){
				nom += document.extension;
			}
		}
		return nom;
	};
}]);