(function() {

    'use strict';

    /**
     * @desc Composant de la liste des immeuble ICS de ICSCloud
     * @example <liste-immeuble-ics></liste-immeuble-ics>
     */
    angular
        .module('collaboreApp')
        .component('listeImmeubleIcs',{
            templateUrl: 'app/components/recherche-ics/liste-immeuble-ics/liste-immeuble-ics.component.html',
            controllerAs: 'listeimmeubleicsctrl',
            bindings: {
                search: '<',    // Champ de recherche
                synchrone: '<',
                listIdPortefeuille: '<',
                onEmit: '&'
            },
            controller: ListeImmeubleIcsCtrl
        });

    /** @ngInject */
    function ListeImmeubleIcsCtrl(COLLAB_CONF, $injector, $scope, $rootScope, $state, $q, AnnuaireService, TagsService, UtilsService){
        var ctrl = this;
        ctrl.loading = false;
        ctrl.listAllImmeuble = [];
        ctrl.listImmeuble = [];

        ctrl.onClickOpenImmeuble = onClickOpenImmeuble;             // Lors du clique sur le bouton pour ouvrir ou fermer l'immeuble
        ctrl.onClickFiltreTopic = onClickFiltreTopic;               // Lors du clique sur le bouton pour filtrer les topics
        ctrl.onClickCreateEvenement = onClickCreateEvenement;       // Lors du clique sur le bouton pour créer un événement
        ctrl.onClickCreateDevisInter = onClickCreateDevisInter;     // Lors du clique sur le bouton pour créer un devis ou une intervention

        ctrl.immeublesExpandCallback = immeublesExpandCallback;
        ctrl.immeublesCollapseCallback = immeublesCollapseCallback;

        //ctrl.onClickFiltreTopic = onClickFiltreTopic;               // Lors du clique sur le bouton pour filtrer les topics

        ctrl.$onInit = function() {
            if(!ctrl.synchrone) {
                init();
            }
            else {
                ctrl.loading = true;
                var listener = $scope.$on('initListeImmeubleIcs', function(){
                    init();
                    listener();
                });
            }
        };

        ctrl.$onChanges = function(changes){
            if(!changes.listIdPortefeuille.isFirstChange() && !ctrl.search.isFirstChange()) {
                ctrl.listIdPortefeuille = changes.listIdPortefeuille.currentValue;
                ctrl.search = changes.search.currentValue;
                init();
            }
        };

        /**
         * Permet d'init le composant
         */
        function init(){
            if(_.isNil(ctrl.listIdPortefeuille)) {
                var listePortefeuilles = UtilsService.getCurrentUser().getListePortefeuille();
                if (listePortefeuilles.length) {
                    ctrl.listIdPortefeuille = listePortefeuilles.map(function(port){
                        return port.idPortefeuille;
                    });
                }
            }


            ctrl.loading = true;
            callImmeuble()
                .then(function(listeImmeuble){
                    ctrl.listImmeuble = listeImmeuble;
                })
                .finally(function(){
                    ctrl.loading = false;
                    emit({action: 'finishedCall'});
                });
        }

        /**
         * Permet d'appeler ICSCloud pour récupérer les immeubles
         * @returns {*}
         */
        function callImmeuble(){
            var deferred = $q.defer();

            var paramsBase = {
                avecemail: 0,
                type: 'I',
                recherche: ctrl.search,
                idPortefeuilles: ctrl.listIdPortefeuille,
                commencePar: false
            };

            //ctrl.paramsSearch.filtreAllow = {
            //    ics: true
            //};

            //if (ctrl.paramsSearch.typePerso && ctrl.paramsSearch.typePerso !== 'all')
            //    params.fortype = ctrl.paramsSearch.typePerso;

            //ctrl.loading = true;
            AnnuaireService
                .searchCollaborateur(paramsBase)
                .then(function (objectCollaborateur) {

                    var listImmeuble = [];

                    // Si il y a des perso ou fournisseur
                    if (_.isObject(objectCollaborateur) && _.isArray(objectCollaborateur.listePortefeuilles) && objectCollaborateur.listePortefeuilles.length) {

                        for(var p = 0; p < objectCollaborateur.listePortefeuilles.length; p++){
                            var portefeuille = objectCollaborateur.listePortefeuilles[p];
                            var portefeuilleCopy = angular.copy(portefeuille);
                            delete portefeuilleCopy.listeImmeuble;

                            if (portefeuille.getListeImmeuble().length) {
                                for (var i = 0; i < portefeuille.getListeImmeuble().length; i++) {
                                    listImmeuble.push({
                                        immeuble: portefeuille.getListeImmeuble()[i],
                                        portefeuille: portefeuilleCopy,
                                        immeubleLoading: false,
                                        immeubleIsOpen: false,
                                        coproprietaires: null,
                                        salaries: null
                                    });
                                }
                            }
                        }
                    }
                    deferred.resolve(listImmeuble);
                })
                .catch(function(err) {
                    deferred.reject(err);
                });

            return deferred.promise;
        }

        /**
         * Permet de récupérer la liste d'immeuble d'une personnalité
         * @param objPerso
         * @returns {*}
         */
        function searchCoproprietaires(objImmeuble){

            var defered = $q.defer();
            var resolve = false;
            if (objImmeuble.immeuble.isImmeuble){
                if (!objImmeuble.immeubleIsOpen) {
                    objImmeuble.immeubleIsOpen = true;

                    // Si pas de copro en mémoire
                    if (!_.isArray(objImmeuble.coproprietaires)) {

                        AnnuaireService
                            .searchCoproprietaires(objImmeuble.immeuble.getNumImmeuble(), objImmeuble.portefeuille.getIdPortefeuille())
                            .then(function (objRetour) {

                                /*
                                var telephones, mails, tag;

                                for (var i = 0; i < objRetour.listeCoproprietaires.length; i++) {
                                    var coproprietaire = objRetour.listeCoproprietaires[i];
                                    telephones = coproprietaire.getTelephones();
                                    if (telephones.length) {
                                        coproprietaire.telephone = telephones[0];
                                        coproprietaire.arrayTelephones = telephones;
                                    }

                                    mails = coproprietaire.getMails();
                                    if (mails.length) {
                                        coproprietaire.email = mails[0];
                                        coproprietaire.arrayMails = mails;

                                        if (UtilsService.isMail(mails[0])) {
                                            coproprietaire.isAllowForDestinataire = true;
                                        }
                                    }
                                }*/

                                objImmeuble.coproprietaires = objRetour.listeCoproprietaires;


                                if (objRetour.listeSalaries.length) {

                                    /*
                                    for (var s = 0; s < objRetour.listeSalaries.length; s++) {
                                        var salarie = objRetour.listeSalaries[s];
                                        telephones = salarie.getTelephones();
                                        if (telephones.length) {
                                            salarie.telephone = telephones[0];
                                            salarie.arrayTelephones = telephones;
                                        }

                                        mails = salarie.getMails();
                                        if (mails.length) {
                                            salarie.email = mails[0];
                                            salarie.arrayMails = mails;

                                            if (UtilsService.isMail(mails[0])) {
                                                salarie.isAllowForDestinataire = true;
                                            }
                                        }
                                    }*/

                                    objImmeuble.salaries = objRetour.listeSalaries;
                                }
                                else {
                                    var Salarie = $injector.get('Salarie');
                                    var gardien = new Salarie({
                                        profess: 'Gardien',
                                        niveau: '02',
                                        noperso: '000000'
                                    });
                                    objImmeuble.salaries = [gardien];
                                }

                                //console.log(collaborateur.listeCoproprietaires);
                                defered.resolve(objImmeuble);
                            })
                            .catch(function (msg) {
                                defered.reject(msg);
                            });
                    }
                    else
                        resolve = true;
                }
                else {
                    objImmeuble.immeubleIsOpen = false;
                    resolve = true;
                }
            }
            else
                resolve = true;

            if(resolve) {
                defered.resolve(objImmeuble);
            }
            return defered.promise;
        }

        /**
         * Lors du clique sur le bouton pour ouvrir ou fermer la personnalitée
         * @param objPerso
         */
        function onClickOpenImmeuble(objImmeuble){
            objImmeuble.immeubleLoading = true;
            searchCoproprietaires(objImmeuble)
                .finally(function(){
                    objImmeuble.immeubleLoading = false;
                });
        }

        /**
         * Lors du clique sur le bouton pour filtrer les topics
         * @param objPerso
         * @param objImmeuble
         */
        function onClickFiltreTopic(objPerso, objImmeuble){
            var objetsTags = setTagsForOrigine(null, objPerso, objImmeuble);
            $scope.$emit('rechercheByTags', objetsTags);
        }

        /**
         * Lors du clique sur le bouton pour créer un événement
         * @param objPerso
         * @param objImmeuble
         */
        function onClickCreateEvenement(projet, objImmeuble, perso){
            setTagsForOrigine(projet, objImmeuble, perso);
            $state.go('nouveau.evenement', {loadOrigine: true});
        }

        /**
         * Lors du clique sur le bouton pour créer un devis ou une intervention
         * @param objPerso
         * @param objImmeuble
         */
        function onClickCreateDevisInter(projet, objImmeuble, perso){
            setTagsForOrigine(projet, objImmeuble, perso);
            $state.go('nouveau.evenementAvecDevisOuIntervention', {loadOrigine: true});
        }

        /**
         * Permet de générer et setter la variable "tagsForOrigine" pour l'ajout des tags lors de la création d'événément
         * @param objPerso
         * @param objImmeuble
         */
        function setTagsForOrigine(projet, objImmeuble, perso){

            var obj = {
                origine: objImmeuble.immeuble,
                immeuble: objImmeuble.immeuble,
                projet: (!_.isNil(projet)) ? projet: null,
                portefeuille: objImmeuble.portefeuille,
                //personnalite: (_.isObject(perso)) ? perso : null,
                //typePerso: (_.isObject(perso) && !_.isNil(perso._Type)) ? perso._Type : null,
                type: 'ICS'
            };

            if(!_.isNil(perso)) {
                obj.origine = perso;
            }

            $rootScope.current.tmpNewEvenement.tagsForOrigine = TagsService.genereTagsAndFiches(obj);
            return $rootScope.current.tmpNewEvenement.tagsForOrigine;
        }

        /**
         * Lorsque qu'une pane de l'accordion est ouverte
         * @param index
         */
        function immeublesExpandCallback(index) {
            onClickOpenImmeuble(ctrl.listImmeuble[index]);
        }

        /**
         * Lorsque qu'une pane de l'accordion est fermée
         * @param index
         */
        function immeublesCollapseCallback(index) {
        }

        function emit(objet){
            ctrl.onEmit({
                obj: objet
            });
        }
    }
})();
