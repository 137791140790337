(function() {
    'use strict';

    /**
     * @ngdoc factory
     * @name collaboreApp.ProxyMail
     * @description
     * # ProxyMail
     */

    angular.module('collaboreApp').factory('ProxyMail', ProxyMailModel);

    /** @ngInject */
    function ProxyMailModel(Main) {

        function ProxyMail(objetData) {
            Main.apply(this, arguments);
            this.hydrate(objetData);
        }

        ProxyMail.prototype = {

            isModel: true,
            isProxy: true,

            // listeEmail
            getListeEmail: function(){
                return this.listeEmail;
            },
            setListeEmail: function(val){
                this.listeEmail = val;
            },

            // sujet
            getSujet: function(){
                return this.sujet;
            },
            setSujet: function(val){
                this.sujet = val;
            },

            // message
            getMessage: function(){
                return this.message;
            },
            setMessage: function(val){
                this.message = val;
            }
        };

        angular.extend(ProxyMail.prototype, Main.prototype);

        return ProxyMail;
    }
})();