/**
 * Created by AndyDev on 24/09/2015.
 */
'use strict';

angular.module('collaboreApp').directive('body', [
    '$rootScope',
    '$window',
    '$location',
    function ($rootScope, $window, $location) {
        var directiveDefinitionObject = {
            restrict: 'E',
            link: function(scope,element) {

                $rootScope.forceViewMobileOld = false;

                if($location.search().oldMobile)
                    $rootScope.forceViewMobileOld = true;

                function checkWindowWidth() {
                    $rootScope.modeAvancement = 'full';

                    //$rootScope.$broadcast('onResizeBody',$window.innerHeight);
                    // Si version tablette ou petit dekstop
                    if($window.innerWidth>767 && $window.innerWidth<1200){
                        element.find('.main-wrapper').addClass('sidebar-mini');
                        //angular.element('.main-wrapper').addClass('sidebar-mini');
                        $rootScope.sidebarMini = true;
                        $rootScope.versionMobile = false;

                        if($window.innerWidth<992) {
                            $rootScope.modeAvancement = 'small';
                        }

                    }
                    // Si version mobile la sidebar est normal mais cach�
                    else if($window.innerWidth<=767) {
                        element.find('.main-wrapper').removeClass('sidebar-mini');
                        //angular.element('.main-wrapper').removeClass('sidebar-mini');
                        $rootScope.sidebarMini = false;
                        $rootScope.versionMobile = true;
                        $rootScope.modeAvancement = 'xsmall';
                    }
                }

                function checkWindowWidthAndDigest() {
                    checkWindowWidth();
                    scope.$digest();
                }

                checkWindowWidth();

                //first call of checkWindowWidth when the dom is loaded
                window.document.addEventListener('DOMContentLoaded', checkWindowWidthAndDigest, false);

                //calling checkWindowWidth on resize event
                $window.onresize = checkWindowWidthAndDigest;
            }
        };
        return directiveDefinitionObject;
    }
]);

/**
 * Permet de resize l'annuaire en hauteur par rapport � la taille de la fenetre
 */
angular.module('collaboreApp').directive('resizeHeightAnnuaire',['$parse','$window', function($parse,$window) {
    return {
        restrict: 'A',
        link: function(scope,element,attrs){
            // Array de hauteur � enlever
            var arrayHeight = (attrs.resizeHeightAnnuaire) ? $parse(attrs.resizeHeightAnnuaire)(scope) : [];

            function checkHeight(){
                var hauteursAEnlever = 0;
                var hauteurWindow = $window.innerHeight;
                if(arrayHeight.length){
                    for(var i = 0 ; i < arrayHeight.length ; i++){
                        hauteursAEnlever = hauteursAEnlever+arrayHeight[i];
                    }
                }

                var newHeight = hauteurWindow-hauteursAEnlever;
                angular.element(element).css('height',newHeight+'px');
            }

            scope.checkHeight = checkHeight;

            //$window.onresize = checkHeight;

            if ($window.addEventListener) {
                $window.addEventListener('resize', checkHeight, false);
            } else if ($window.attachEvent) {
                $window.attachEvent('resize', checkHeight);
            }

            checkHeight();

            scope.$on('$destroy',function(){
                if ($window.removeEventListener) {
                    $window.removeEventListener ('resize', checkHeight, false);
                } else if ($window.detachEvent ) {
                    $window.detachEvent ('resize', checkHeight);
                }
            });
        }
    };
}]);


angular.module('collaboreApp').directive('uibModalWindow', [function(){
    return {
        restrict: 'EA',
        controller: ['$element','$scope','$timeout',function($element,$scope,$timeout){

            $scope.floatingElement = [];

            var modal = $element[0];
            var hauteurModalHeader = 0;
            var modalHeader;

            function onScroll(){
                // Hauteur de la fenetre par rapport au haut de la modal
                var scrollTop = $element[0].scrollTop;

                if($scope.floatingElement.length){

                    //console.log('scrollTop',scrollTop);
                    //console.log('hauteurModalHeader',hauteurModalHeader);

                    for(var i = 0 ; i < $scope.floatingElement.length ; i++){
                        var elementFloating =  angular.element($scope.floatingElement[i]);

                        var marginTop = 0;

                        var cssElement = {
                            'margin-top': marginTop+'px'
                        };

                        //if(scrollTop>hauteurModalHeader){

                        if(scrollTop>10){

                            var newScrollTop = scrollTop-10;
                            var newTop = (newScrollTop+hauteurModalHeader)+1;
                            if(elementFloating.hasClass('modal-header')){
                                newTop = newScrollTop;
                            }

                            cssElement['margin-top'] = newTop+'px';

                            //cssElement['margin-top'] = ((scrollTop-hauteurModalHeader)-1)+'px';
                            cssElement['position'] = 'absolute';
                            cssElement['background-color'] = '#F5F7FA';
                            cssElement['z-index'] = '1';
                            cssElement['width'] = '100%';

                        }else{
                            cssElement['position'] = 'relative';
                        }
                        elementFloating.css(cssElement);
                    }

                    /*
                    var bodyAnnuaire =  angular.element('#bodyAnnuaire');
                    var cssElementBody = {};

                    cssElementBody['margin-top'] = hauteurModalHeader+'px';
                    bodyAnnuaire.css(cssElementBody);*/
                }
            }

            $timeout(function(){


                var modalHead = $element.find('.modal-header');
                if(modalHead.length){
                    //console.log('modalHead',modalHead);
                    modalHeader = modalHead[0];
                    // hauteur du modal header
                    hauteurModalHeader = modalHeader.offsetHeight;
                }

                if (modal.addEventListener) {
                    modal.addEventListener('scroll', onScroll);
                } else if (modal.attachEvent) {
                    modal.attachEvent('scroll', onScroll);
                }

                $scope.$on('$destroy',function(){
                    if (modal.removeEventListener) {
                        modal.removeEventListener ('scroll', onScroll);
                    } else if (modal.detachEvent ) {
                        modal.detachEvent ('scroll', onScroll);
                    }
                });
            });

            this.addElement = function(element){
                $scope.floatingElement.push(element);
            };

        }]
    };
}]);

angular.module('collaboreApp').directive('verticalFloating',[function() {
    return {
        require: '?^uibModalWindow',
        restrict: 'A',
        link: function(scope,element,attrs,modalCtrl){
            if(modalCtrl){
                modalCtrl.addElement(element);
            }
        }
    };
}]);

/**
 * Permet d'�couter le changement de width d'un element
 */
angular.module('collaboreApp').directive('listenWidth', ['$timeout',
    function ($timeout) {
        var directiveDefinitionObject = {
            restrict: 'A',
            scope: {
                method:'&listenWidth'
            },
            link: function(scope,element) {
                // element[0].offsetWidth
                // element[0].scrollWidth
                scope.$watch(function(){
                    //return element[0].clientWidth;
                    return element.width();
                }, function(newVal, oldVal) {
                    scope.method({
                        val: newVal
                    });
                });

                var on = scope.$on('getWidthFromListenWidth', function(){
                    $timeout(function(){
                        scope.method({
                            val: element.width()
                        });
                    });
                });

                scope.$on('$destroy', function() {
                    on();
                });
            }
        };
        return directiveDefinitionObject;
    }
]);