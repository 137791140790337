'use strict';

/**
 * @desc Icon pour passer/enlever un topic en coup de coeur
 * @example <icon-coup-de-coeur topic-state="" icon-size="small"></icon-coup-de-coeur>
 */

angular
	.module('collaboreApp')
	.component('iconCoupDeCoeur',{
		templateUrl: 'app/topics/components/icon-coup-de-coeur.component.html',
		bindings : {
			idTopicState: '<',		// <Integer> Id du topicState
			state: '<',				// <Object> State du topicState
			iconSize: '@',			// <String> Taille de l'icone
			showLibelle: '<'
		},
		controllerAs: 'iconcoupdecoeurctrl',
		controller: ['$rootScope','$state','$scope', IconCoupDeCoeurController]
	});

function IconCoupDeCoeurController($rootScope,$state,$scope){

	var ctrl = this;

	ctrl.updateState = updateState;			// Update l'état du state du topic

    var listenerOnUpdateState = $scope.$on('updateState',function(event,idTopicState){
        if(ctrl.idTopicState === idTopicState){
            setLibelle(ctrl.state);
        }
    });

	ctrl.$onInit = function() {

		ctrl.loading = false;
		ctrl.libelleTooltip = '';

		switch(ctrl.iconSize){
			case 'small':
				ctrl.isSmall = true;
				break;
			default:
				ctrl.isSmall = false;
		}
		setLibelle(ctrl.state);
	};

	ctrl.$onDestroy = function(){
        listenerOnUpdateState();
	};

	/**
	 * Set le libelle du tooltip
	 * @param state
     */
	function setLibelle(state){
		if(state.isCoupCoeur()){
			ctrl.libelleTooltip = 'Retirer des Favoris';
		}else{
			ctrl.libelleTooltip = 'Ajouter aux Favoris';
		}
	}

	/**
	 * Permet d'update le state
	 */
	function updateState(){

		try{

			if(angular.isUndefined(ctrl.idTopicState)){
				throw new Error('[iconCoupDeCoeur] Il manque l\'idTopicState !');
			}

			ctrl.loading = true;
			ctrl.state.toggleAddRemoveToCoupCoeur(ctrl.idTopicState).then(function(state){

				setLibelle(state);

				// Le retour du state est différent de coup de coeur et que je suis dans la page des favoris alors on vire le topic car il est repassé en normal
				// Ou différent de archive et que je suis dans la page archive
				if((!state.isCoupCoeur() && $state.is('topics.favoris')) ||
					(!state.isArchive() && $state.is('topics.archives'))) {
					$scope.$emit('removeTopic',ctrl.idTopicState);
				}

				$rootScope.$broadcast('updateState',ctrl.idTopicState);

			},function(msg){
				throw new Error('Erreur : '+msg);
			}).finally(function(){
				ctrl.loading = false;
			});
		}
		catch(err){
			console.log(err.message);
		}
	}



}