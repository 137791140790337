(function () {
    'use strict';

    angular
        .module('collaboreApp')
        .factory('CalculatriceResource', CalculatriceResource);



    /** @ngInject */
    function CalculatriceResource(COLLAB_CONF, COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {


        return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_CALCULATRICE, null, {

            post: {
                method: 'POST',
                transformRequest: function (data) {
                    return $httpParamSerializerJQLike(data);
                },
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            }
        });
    }
})();
