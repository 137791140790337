(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .component('dashboardReporting', {
      templateUrl: 'app/dashboard/dashboard-reporting/dashboard-reporting.component.html',
      bindings: {
        search: '<',
        idChannel: '<',
        onEmit: '&'
      },
      controllerAs: 'dashboardreportingctrl',
      controller: DashboardReportingController
    });

  /*@ngInject*/
  function DashboardReportingController(COLLAB_CONF, $rootScope, $scope, $q, $locale, $timeout, DashboardService, ContenuService, TopicService, ConfigService, sweet, UtilsService, AnnuaireService, TagService, $stateParams, $state, GroupeService, TopicStatesService, ModalsService, TagsService) {

    var ctrl = this;

    var firstTypeSelected = 0;
    var arraySelectTypes = [
      {
        libelle: 'Origines',
        typesTag: [
          COLLAB_CONF.TAG_TYPE_ORIGINE_ICS,
          COLLAB_CONF.TAG_TYPE_ORIGINE_IMMEUBLE,
          COLLAB_CONF.TAG_TYPE_ORIGINE_GARDIEN
        ]
      },
      {
        libelle: 'Propriétaires',
        typesTag: [
          COLLAB_CONF.TAG_TYPE_PROPRIETAIRE
        ]
      },
      {
        libelle: 'Copropriétaires',
        typesTag: [
          COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE
        ]
      },
      {
        libelle: 'Locataires',
        typesTag: [
          COLLAB_CONF.TAG_TYPE_LOCATAIRE
        ]
      },
      {
        libelle: 'Mandats',
        typesTag: [
          COLLAB_CONF.TAG_TYPE_MANDAT
        ]
      },
      {
        libelle: 'Immeubles',
        typesTag: [
          COLLAB_CONF.TAG_TYPE_IMMEUBLE
        ]
      },
      {
        libelle: 'Lots',
        typesTag: [
          COLLAB_CONF.TAG_TYPE_LOT
        ]
      },
      {
        libelle: 'Projets',
        typesTag: [
          COLLAB_CONF.TAG_TYPE_PROJET
        ]
      }
    ];

    ctrl.listCategorieTopic = [];
    /*
    ctrl.tagsTypeDonut = [];
    ctrl.arrayNbDonut = [
      3,
      5,
      10,
      20,
      30,
      40,
      50
    ];
    ctrl.donutTypeTags = {
      modeGraph: true,
      enable: true,
      show: false,
      selectedNb: 10,
      selectedType: arraySelectTypes[firstTypeSelected],
      types: arraySelectTypes,
      instanceChart: false,

      chartConfig: {

        chart: {
          type: 'pie',
					 // marginRight: 120/*,
           //
					 // events: {
					 // redraw: function () {
					 // console.log(this);
					 // var chart = this,
					 // legend = chart.legend;
           //
					 // for (var i = 0, len = legend.allItems.length; i < len; i++) {
					 // (function(i) {
					 // var item = legend.allItems[i].legendItem;
					 // item.on('mouseover', function () {
           //
					 // var tooltip = genereLabelDonutTagsType(chart.series[0].data[i]);
					 // chart.setTitle({
					 // text: tooltip
					 // });
           //
					 // }).on('mouseout', function () {
					 // chart.setTitle({
					 // text: '<strong>'+chart.series[0].name+'</strong>'
					 // });
					 // });
					 // })(i);
					 // }
           //
					 // }
					 // }
        },
        exporting: {
          enabled: false
        },
        tooltip: {
          enabled: false,
          style: {
            fontSize: '12px'
          },
          formatter: function () {
            var tooltip = '<b>' + this.point.name + '</b>';

            if (this.point.hasOwnProperty('tag')) {

              if (this.point.tag.hasOwnProperty('typeTag')) {
                tooltip += '<br/>';
                tooltip += 'Type Tag: <b>' + this.point.tag.typeTag.libelle + '</b>';


                if (this.point.tag.typeTag.libelle === COLLAB_CONF.TAG_TYPE_ORIGINE_IMMEUBLE) {
                  tooltip += '<br/>';
                  tooltip += 'No Immeuble: <b>' + this.point.tag.valeurIcs + '</b>';
                }
                if (this.point.tag.typeTag.libelle === COLLAB_CONF.TAG_TYPE_ORIGINE_ICS) {
                  tooltip += '<br/>';
                  tooltip += 'No Perso: <b>' + this.point.tag.valeurIcs + '</b>';
                }
              }

            }
            tooltip += '<br/>';
            tooltip += '<div style="text-align:center"><strong style="font-size:16px">' + this.y + ' tags</strong></div>';

            return tooltip;
          },
          useHTML: true
        },
        plotOptions: {
          pie: {
            shadow: false,
            innerSize: '70%',
            //center: ['35%', '50%'],
            //center: ['50%', '50%'],
            center: ['50%', '50%'],
            //selected: true,
            showInLegend: true,
            point: {
              events: {
                mouseOver: function () {
                  var tooltip = genereLabelDonutTagsType(this);
                  this.series.chart.setTitle({
                    text: tooltip
                  });


                   // this.series.chart.setTitle({
                   // text: '<strong>'+this.name+'</strong><br />' + this.y
                   // });

                  //this.chart.reflow();
                },
                mouseOut: function () {
                  this.series.chart.setTitle({
                    text: '<strong>' + this.series.name + '</strong>'
                  });
                },
                legendItemClick: function () {
                  //console.log('legendItemClick');
                  // console.log(this);


                   // if (this.hasOwnProperty('portefeuille')) {
                   // delete this.portefeuille.portefeuilleInfos;
                   // }
                  ctrl.filtreTags = [this.tag];
                  getEventDevis();

                  return false;
                },
                select: function () {
                  //console.log('select');
                },
                click: function () {
                  ctrl.filtreTags = [this.tag];
                  getEventDevis();
                  //console.log('click');
                }
              }
            }
          }
        },
        legend: {
          enabled: true,
          //floating: true,
          //borderWidth: 1,
          align: 'left',
          verticalAlign: 'top',
          layout: 'vertical',
          x: 0,
          y: 0,
          //maxHeight: 250,
          labelFormatter: function () {

            var label = this.name;

            if (this.hasOwnProperty('tag')) {
              label += ' <small class="text-muted">' + this.tag.valeurIcs + ' [' + this.tag.typeTag.libelle + ']';

              if (this.tag.hasOwnProperty('portefeuille')) {
                label += ' ' + this.tag.portefeuille.nom;
              }

              label += '</small>';
            }


            // display only if larger than 1
            return label;
          },
          useHTML: true,
          navigation: {
            activeColor: '#3E576F',
            animation: true,
            arrowSize: 12,
            inactiveColor: '#CCC',
            style: {
              fontWeight: 'bold',
              color: '#333',
              fontSize: '12px'
            }
          }
        },
        series: [{
          name: arraySelectTypes[firstTypeSelected].libelle,
          data: [],
          size: '100%',
          dataLabels: {
            enabled: false,
            //distance: -30,
            formatter: function () {

               // var label = this.y > 1 ? '<b>' + this.point.name + ':</b> ' + this.y : null;
               //
               // if(this.point.hasOwnProperty('tag')) {
               //
               // if (this.point.tag.hasOwnProperty('typeTag')) {
               // if(label!==null){
               // if (this.point.tag.typeTag.libelle === COLLAB_CONF.TAG_TYPE_ORIGINE_IMMEUBLE) {
               // label += ' [No Immeuble: ' + this.point.tag.valeurIcs + ']';
               // }
               // if (this.point.tag.typeTag.libelle === COLLAB_CONF.TAG_TYPE_ORIGINE_ICS) {
               // label += ' [No Perso: ' + this.point.tag.valeurIcs + ']';
               // }
               // }
               // }
               // }


              var label = this.y > 1 ? '<b>' + this.point.name + ':</b> ' + this.y : null;

              if (label !== null) {
                if (this.point.hasOwnProperty('tag')) {
                  label += ' <small class="text-muted">' + this.point.tag.valeurIcs + ' [' + this.point.tag.typeTag.libelle + ']';

                  if (this.point.tag.hasOwnProperty('portefeuille')) {
                    label += ' ' + this.point.tag.portefeuille.nom;
                  }

                  label += '</small>';
                }
              }


              // display only if larger than 1
              return label;
            }
          },
          useHTML: true
        }],
        title: {
          text: arraySelectTypes[firstTypeSelected].libelle,
          verticalAlign: 'middle',
          align: 'center',
          //floating: true,
          x: 180,
          y: -20
        },

        credits: {
          enabled: false
        },
        loading: false
      }
    };

    function genereLabelDonutTagsType(_this) {
      //console.log(_this);
      var tooltip = '<b>' + _this.name + '</b>';

      if (_this.hasOwnProperty('tag')) {

        if (_this.tag.hasOwnProperty('typeTag')) {
          tooltip += '<br/>';
          tooltip += 'Type Tag: <b>' + _this.tag.typeTag.libelle + '</b>';

          if (_this.tag.typeTag.libelle === COLLAB_CONF.TAG_TYPE_ORIGINE_IMMEUBLE) {
            tooltip += '<br/>';
            tooltip += 'No Immeuble: <b>' + _this.tag.valeurIcs + '</b>';
          }
          if (_this.tag.typeTag.libelle === COLLAB_CONF.TAG_TYPE_ORIGINE_ICS) {
            tooltip += '<br/>';
            tooltip += 'No Perso: <b>' + _this.tag.valeurIcs + '</b>';
          }
        }

        if (_this.tag.hasOwnProperty('portefeuille')) {
          tooltip += '<br/>';
          tooltip += 'Portefeuille: <b>' + _this.tag.portefeuille.nom + '</b>';
        }
      }
      tooltip += '<br/>';
      tooltip += '<div style="text-align:center;font-weight:bold;font-size:18px">' + _this.y + ' tags</div>';
      return tooltip;
    }*/

    var arrayColSortNotAllowedForES = [
      /*
      'nbDemandeContrat',
      'nbDevisIntervention',
      'nbDevisDemandes',
      'nbDevisReponse',
      'tauxReponse',
      'tauxValide',
      'tauxSigne',
      'nbFacture',*/
    ];

    // Infos du dashboard
    ctrl.infos = {
      nbEcheanceDepassees: 0
    };

    ctrl.loadingSearchExpediteurs = true;
    ctrl.listeGroupesExpediteurs = [];	// Liste filtre expediteurs
    ctrl.listeFournisseurMail = [];
    ctrl.listeFournisseurPersoICS = [];
    ctrl.filtreTags = [];

    // Tableau pour le choix du nombre de lignes
    ctrl.arrayNbResult = [
      10,
      15,
      20,
      50,
      100,
      200,
      500
    ];

    var defaultValuesDatePicker = {
      //startDate: moment().year(2000).dayOfYear(1).startOf('hour'),
      //endDate: moment(),

      startDate: moment().startOf('month'),
      endDate: moment().add(1, 'month').endOf('month'),
      label: 'Toute la période'
    };

    ctrl.optionsDatepicker = {
      showDropdowns: true,
      autoApply: false,
      clearLabel: 'Effacer',
      linkedCalendars: false,
      locale: {
        format: 'DD/MM/YYYY',
        separator: ' au ',
        applyLabel: 'Ok',
        fromLabel: 'Du',
        toLabel: 'au',
        cancelLabel: 'Annuler',
        customRangeLabel: 'Personnaliser',
        daysOfWeek: $locale.DATETIME_FORMATS.SHORTDAY,
        firstDay: 1,
        monthNames: $locale.DATETIME_FORMATS.STANDALONEMONTH
      },
      eventHandlers: {
        'show.daterangepicker': function (ev) {
          if (ev.model.datepicker === 'dateCreation') ctrl.datePickerDateCreationShow = true;
          if (ev.model.datepicker === 'dateEcheance') ctrl.datePickerDateEcheanceShow = true;
        },
        'hide.daterangepicker': function (ev) {
          if (ev.model.datepicker === 'dateCreation') ctrl.datePickerDateCreationShow = false;
          if (ev.model.datepicker === 'dateEcheance') ctrl.datePickerDateEcheanceShow = false;
        },
        'apply.daterangepicker': function (ev) {
          UtilsService.parseDisplayDates(ev.model);
        }
      },
      ranges: {

        'Aujourd\'hui': [moment(), moment()],
        'Hier': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Cette semaine (depuis Lundi)': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
        'Les 7 derniers jours': [moment().subtract(7, 'days'), moment()],
        'Semaine dernière (Lun - Dim)': [moment().subtract(1, 'week').startOf('isoWeek'), moment().subtract(1, 'week').endOf('isoWeek')],
        'Les 14 derniers jours': [moment().subtract(14, 'days'), moment()],
        'Ce mois-ci': [moment().startOf('month'), moment().endOf('month')],
        'Les 30 derniers jours': [moment().subtract(30, 'days'), moment()],
        'Le mois dernier': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Toute la période': [angular.copy(defaultValuesDatePicker.startDate), angular.copy(defaultValuesDatePicker.endDate)]
        //'Toute la période': [moment().year(2000).dayOfYear(1).startOf('hour'), moment()]
      },
      opens: 'right'
    };

    ctrl.filtre = {
      show: true,

      idChannel: null,                            // Filtre par channel
      topicSansEcheance: false,                   // Topic sans date d'échéance

      etat: '0',                                    // Topic sans archives
      classe: '0',                                  // Topic avec événement seulement
      demandeDevis: false,                        // Demande de devis
      demandeDevisNonValide: false,               // Demande de devis non validée
      demandeIntervention: false,                 // Demande intervention
      demandeContrat: false,                      // Demande de contrat
      contratNonRecu: false,                      // Demande de contrat sans contrat recu
      evenementAvecSinistre: false,               // Evénement avec sinistre
      evenementSansSinistre: false,               // Evénement sans sinistre

      demandeDevisAvecFacture: false,             // Demande de devis avec facture
      demandeDevisAvecFactureNonTraitee: false,   // Demande de devis avec facture pas envoyé dans comfact

      avecContenuNonLu: false,                    // Les topic qui ont des contenus non lu

      cloture: false,                             // Cloturé
      nonCloture: true,                          // Non cloturé
      //nonArchive: false,                          // Non archivé

      dureePeriode: 0,						      // Durée de la période
      dureeEcheance: 0,						      // Durée de la période pour l'echeance

      //typeDevis: 'devis_interventions',
      //cloture: '2',
      //withSinistre: 'null',
      filtreExpediteurs: [],
      dateCreation: {                         // Dates de création
        datepicker: 'dateCreation',
        showDates: false,
        isDefault: true,
        startDate: angular.copy(defaultValuesDatePicker.startDate),
        endDate: angular.copy(defaultValuesDatePicker.endDate),
        label: angular.copy(defaultValuesDatePicker.label)
      },
      dateEcheance: {                         // Dates d'échéance
        datepicker: 'dateEcheance',
        showDates: false,
        isDefault: true,
        startDate: angular.copy(defaultValuesDatePicker.startDate),
        endDate: angular.copy(defaultValuesDatePicker.endDate),
        label: angular.copy(defaultValuesDatePicker.label)
      },
      listCategorieTopic: []
      //debutCreation: null,					// Date de début de la création
      //finCreation: null,					// Date de fin de la création
      //debutEcheance: null,					// Date de début de l'échéance
      //finEcheance: null						// Date de fin de l'échéance
    };


    /*
     ctrl.dateDebutCreationPickerOptions = {
     minDate: null,
     startingDay: 1,
     showWeeks: true
     };

     ctrl.dateFinCreationPickerOptions = {
     minDate: null,
     startingDay: 1,
     showWeeks: true
     };

     ctrl.dateDebutEcheancePickerOptions = {
     minDate: null,
     startingDay: 1,
     showWeeks: true
     };

     ctrl.dateFinEcheancePickerOptions = {
     minDate: null,
     startingDay: 1,
     showWeeks: true
     };

     $scope.$watch('dashboardreportingctrl.filtre.debutCreation',function(dateDebut) {
     paramOptionsOnChangeDateDebut(dateDebut,ctrl.filtre.finCreation,ctrl.dateDebutCreationPickerOptions,ctrl.dateFinCreationPickerOptions);
     });
     $scope.$watch('dashboardreportingctrl.filtre.finCreation',function(dateFin) {
     paramOptionsOnChangeDateFin(dateFin,ctrl.dateDebutCreationPickerOptions);
     });

     $scope.$watch('dashboardreportingctrl.filtre.debutEcheance',function(dateDebut) {
     paramOptionsOnChangeDateDebut(dateDebut,ctrl.filtre.finEcheance,ctrl.dateDebutEcheancePickerOptions,ctrl.dateFinEcheancePickerOptions);
     });
     $scope.$watch('dashboardreportingctrl.filtre.finEcheance',function(dateFin) {
     paramOptionsOnChangeDateFin(dateFin,ctrl.dateDebutEcheancePickerOptions);
     });

     function paramOptionsOnChangeDateDebut(dateDebut,dateFin,debutOptions,finOptions) {
     if(dateDebut!=null){
     finOptions.minDate = dateDebut;
     finOptions.initDate = dateDebut;
     }
     else{
     finOptions.minDate = null;
     finOptions.initDate = null;
     // Si il y a une date de fin
     if(dateFin!=null) {
     debutOptions.initDate = angular.copy(dateFin);
     debutOptions.initDate.setDate(1);

     }
     }
     }

     function paramOptionsOnChangeDateFin(dateFin,debutOptions) {
     if(dateFin!=null){
     debutOptions.maxDate = dateFin;	// Max date de début à la date de fin
     }
     else{
     if(debutOptions.maxDate) {
     debutOptions.maxDate = null;
     }
     }
     }
     */

    ctrl.nbTopicSelected = 0;
    ctrl.nbTopicSelectedForCloture = 0;
    ctrl.nbTopicSelectedForReouvrir = 0;
    ctrl.nbTopicSelectedForRelance = 0;
    ctrl.nbTopicSelectedForArchive = 0;
    ctrl.nbTopicSelectedForDesarchive = 0;
    ctrl.allSelected = false;									// Permet de selectionner toute les lignes
    ctrl.enableDetail = false;									// Permet d'afficher ou cacher tous les tags de toutes les lignes

    ctrl.loading = false;

    ctrl.onChangeClasse = onChangeClasse;
    // ctrl.onChangeTagTypeDonut = onChangeTagTypeDonut;						// Lors du changement de type de tag pour le donut
    // ctrl.onClickShowListTagsTypeDonut = onClickShowListTagsTypeDonut;		// Lors du clique sur le bouton pour afficher la liste des tags type en format liste

    // En mass
    ctrl.onClickDetailsAll = onClickDetailsAll;								// Lors du clique sur le bouton pour afficher/cacher les détails de toute les lignes
    ctrl.onChangeAllSelected = onChangeAllSelected;							// Lorsque l'on coche/decoche la case pour selectionner toutes les lignes
    ctrl.onClickArchiveAllTopicSelected = onClickArchiveAllTopicSelected;	// Lorsque l'on clique sur le bouton archiver les topics selectionnés
    ctrl.onClickDesarchiveAllTopicSelected = onClickDesarchiveAllTopicSelected;	// Lorsque l'on clique sur le bouton pour désarchiver les topics selectionnés
    ctrl.onClickCloturerAllTopicSelected = onClickCloturerAllTopicSelected;	// Lorsque l'on clique sur le bouton cloturer les topics selectionnés
    ctrl.onClickReouvrirAllTopicSelected = onClickReouvrirAllTopicSelected;	// Lorsque l'on clique sur le bouton cloturer les topics selectionnés
    ctrl.onClickRelancerAllTopicSelected = onClickRelancerAllTopicSelected;	// Lorsque l'on clique sur le bouton relancer les topics selectionnés

    // Par ligne
    ctrl.onCheckDossier = onCheckDossier;						// Lorsque l'on coche/decoche la case de la ligne du topic
    ctrl.onClickCloturerTopicSelected = onClickCloturerTopicSelected;		// Lorsque l'on clique sur le bouton cloturer d'une ligne
    ctrl.onClickReouvrirTopicSelected = onClickReouvrirTopicSelected;		// Lorsque l'on clique sur le bouton cloturer d'une ligne
    ctrl.onClickArchiveTopicSelected = onClickArchiveTopicSelected;		// Lorsque l'on clique sur le bouton archiver d'une ligne
    ctrl.onClickNormalizeTopicSelected = onClickNormalizeTopicSelected;		// Lorsque l'on clique sur le bouton retirer des archives d'une ligne
    ctrl.onClickRelancerTopicSelected = onClickRelancerTopicSelected;		// Lorsque l'on clique sur le bouton relancer d'une ligne
    ctrl.onClickOpenCommunication = onClickOpenCommunication;

    ctrl.canRelance = canRelance;

    //ctrl.setFiltreTypeDevis = setFiltreTypeDevis;							// Choix du type de résultat
    //ctrl.setFiltreCloture = setFiltreCloture;								// Choix des resultats cloturés/non cloturés
    //ctrl.setFiltreDureePeriode = setFiltreDureePeriode;						// Choix de la durée de la période
    //ctrl.setFiltreDureeEcheance = setFiltreDureeEcheance;					// Choix de la durée de l'echeance

    ctrl.getLigneStatus = getLigneStatus;
    ctrl.getTypeProgressBar = getTypeProgressBar;
    ctrl.calcNbAttenteDevis = calcNbAttenteDevis;
    ctrl.calcNbAttenteDevisValide = calcNbAttenteDevisValide;
    ctrl.calcNbAttenteDevisSigne = calcNbAttenteDevisSigne;
    ctrl.openGroupements = openGroupements;
    ctrl.changeTri = changeTri;
    //ctrl.setLimit = setLimit;
    ctrl.getEventDevis = getEventDevis;
    ctrl.showTags = showTags;									// Permet de voir les tags
    ctrl.onReadyGroupementDevisStatistiques = onReadyGroupementDevisStatistiques;									// Permet de voir les tags
    //ctrl.openDateDebutCreation = openDateDebutCreation;						// Ouvre le datepicker de la date début de la création
    //ctrl.openDateFinCreation = openDateFinCreation;							// Ouvre le datepicker de la date de fin de la création
    //ctrl.openDateDebutEcheance = openDateDebutEcheance;						// Ouvre le datepicker de la date début de l'échéance
    //ctrl.openDateFinEcheance = openDateFinEcheance;							// Ouvre le datepicker de la date de fin de l'échéance
    ctrl.clearDatesCreation = clearDatesCreation;							// Efface les dates de création
    ctrl.clearDatesEcheance = clearDatesEcheance;							// Efface les dates d'echeance
    ctrl.onSubmitRecherche = onSubmitRecherche;								// Lors de la soumission du formulaire de recherche
    ctrl.onClickDownloadExcel = onClickDownloadExcel;

    ctrl.removeTagFromFiltre = removeTagFromFiltre;							// Permet de supprimer un tag filtre
    ctrl.removeParamFromFiltre = removeParamFromFiltre;						// Permet de supprimer un param du filtre
    ctrl.openAnnuaire = openAnnuaire;										// Permet d'ouvrir l'annuaire
    ctrl.openAnnuaireFournisseur = openAnnuaireFournisseur;					// Permet d'ouvrir l'annuaire pour les fournisseurs

    ctrl.onEmitFromSelectCategorieTopic = onEmitFromSelectCategorieTopic;

    /*
     function encodeSearch(search){
     var objCrypt = CryptoJS.enc.Utf8.parse(angular.toJson(search));
     return CryptoJS.enc.Base64.stringify(objCrypt);
     }
     */
    var filtreByTagType = false;

    var filtres = ConfigService.getFiltresTableauDevisDashboard();

    ctrl.order = angular.isDefined(filtres.order) ? filtres.order : 'nbJour';
    ctrl.sens = angular.isDefined(filtres.sens) ? filtres.sens : 'DESC';
    ctrl.limit = angular.isDefined(filtres.limit) ? filtres.limit : COLLAB_CONF.NB_EVENT_LIMIT;
    ctrl.liste_event = [];
    ctrl.search_event_submited = false;

    ctrl.pagination = {
      nbTotal: 0,
      //currentPage: angular.isDefined(filtres.page) ? filtres.page : 1,
      currentPage: 1,
      maxSize: 5,
      pageChanged: function () {
        getEventDevis();
      }
    };

    ctrl.$onInit = function () {
      var filtreTopic = UtilsService.getCurrentFiltreTopics();
      //console.log(filtreTopic);
      $scope.$emit('minimizeLeftSidebar');
      GroupeService
        .searchCollaborateursSender()
        .then(function (listeGroupes) {
          ctrl.listeGroupesExpediteurs = listeGroupes;
        })
        .finally(function () {
          ctrl.loadingSearchExpediteurs = false;
        });

      //if (ctrl.donutTypeTags.enable) getTagsMoreUsed();

      if (!_.isNil(ctrl.search)) {
        var search = UtilsService.decodeSearch(ctrl.search);
        //console.log(search);

        var filtreTmp = null;

        if(search.hasOwnProperty('filtreByTagType')) filtreByTagType = search.filtreByTagType;
        // Si filtre dashboard
        else if(search.hasOwnProperty('filtreDashboard')) ctrl.filtre = _.merge({}, ctrl.filtre, search.filtreDashboard);

        if(search.idChannel) ctrl.filtre.idChannel = search.idChannel;

        if(ctrl.listeFournisseurMail.length === 0) {
          if(_.isArray(search.listeFournisseurMail) && search.listeFournisseurMail.length) ctrl.listeFournisseurMail = search.listeFournisseurMail;
          else if(_.isString(search.listeFournisseurMail)) ctrl.listeFournisseurMail = angular.fromJson(search.listeFournisseurMail);
        }

        if(ctrl.listeFournisseurPersoICS.length === 0) {
          if(_.isArray(search.listeFournisseurPersoICS) && search.listeFournisseurPersoICS.length) ctrl.listeFournisseurPersoICS = search.listeFournisseurPersoICS;
          else if(_.isString(search.listeFournisseurPersoICS)) ctrl.listeFournisseurPersoICS = angular.fromJson(search.listeFournisseurPersoICS);
        }

        if(ctrl.filtreTags.length === 0) {
          if (_.isArray(search.tags) && search.tags.length) ctrl.filtreTags = search.tags;
          else if (_.isString(search.tags)) ctrl.filtreTags = angular.fromJson(search.tags);
        }

        if(ctrl.filtre.topicSansEcheance) ctrl.showAdvancedSearch = true;
        if(ctrl.filtre.dateEcheance) {
          if(_.isObject(ctrl.filtre.dateEcheance.startDate) && ctrl.filtre.dateEcheance.startDate._isAMomentObject && _.isString(ctrl.filtre.dateEcheance.startDate._d)) {
            ctrl.filtre.dateEcheance.startDate = moment(ctrl.filtre.dateEcheance.startDate._d);
          }
          if(_.isObject(ctrl.filtre.dateEcheance.endDate) && ctrl.filtre.dateEcheance.endDate._isAMomentObject && _.isString(ctrl.filtre.dateEcheance.endDate._d)) {
            ctrl.filtre.dateEcheance.endDate = moment(ctrl.filtre.dateEcheance.endDate._d);
          }
          UtilsService.parseDisplayDates(ctrl.filtre.dateEcheance);
        }
        if(ctrl.filtre.dateCreation) {
          if(_.isObject(ctrl.filtre.dateCreation.startDate) && ctrl.filtre.dateCreation.startDate._isAMomentObject && _.isString(ctrl.filtre.dateCreation.startDate._d)) {
            ctrl.filtre.dateCreation.startDate = moment(ctrl.filtre.dateCreation.startDate._d);
          }
          if(_.isObject(ctrl.filtre.dateCreation.endDate) && ctrl.filtre.dateCreation.endDate._isAMomentObject && _.isString(ctrl.filtre.dateCreation.endDate._d)) {
            ctrl.filtre.dateCreation.endDate = moment(ctrl.filtre.dateCreation.endDate._d);
          }
          UtilsService.parseDisplayDates(ctrl.filtre.dateCreation);
        }
        if(ctrl.filtre.idChannel) ctrl.onEmit({obj: {idChannel: ctrl.filtre.idChannel}});

        if(ctrl.filtre.filtreExpediteurs.length !== 0) {
          ctrl.filtre.filtreExpediteurs = ctrl.filtre.filtreExpediteurs.map(function(g){
            return GroupeService.newGroupe(g);
          });
        }

        if(_.isObject(filtreTopic)) {
          //if(!_.isNil(filtreTopic.classe)) ctrl.filtre.classe = filtreTopic.classe;
          //if(!_.isNil(filtreTopic.etat)) ctrl.filtre.etat = filtreTopic.etat;
        }
        //console.log(ctrl.filtre);
        getEventDevis(true);
      }
      //console.log(ctrl.filtre);
      if(_.isObject(filtreTopic)) {
        if(_.isNil(ctrl.filtre.idChannel) && !_.isNil(filtreTopic.idChannel)) ctrl.filtre.idChannel = filtreTopic.idChannel;
        //if(!_.isNil(filtreTopic.classe)) ctrl.filtre.classe = filtreTopic.classe;
        //if(!_.isNil(filtreTopic.etat)) ctrl.filtre.etat = filtreTopic.etat;
      }
    };

    function onEmit(search){
      ctrl.onEmit({
        obj: {
          idChannel: search.idChannel
        }
      });
    }

    /*
    function getTagsMoreUsed(selectedType) {

      ctrl.donutTypeTags.loading = true;
      if (!selectedType) {
        selectedType = ctrl.donutTypeTags.selectedType;
      }
      ctrl.tagsTypeDonut = [];
      TagService.getTagsTypeMoreUsed(selectedType.typesTag, ctrl.donutTypeTags.selectedNb).then(function (tags) {
        ctrl.donutTypeTags.instanceChart = ctrl.donutTypeTags.chartConfig.getChartObj();
        ctrl.tagsTypeDonut = tags;
        var datas = [];
        if (tags.length) {
          for (var i = 0; i < tags.length; i++) {
            datas.push({
              name: tags[i].tag.libelle,
              y: tags[i].nbUsed,
              tag: tags[i].tag
            });
          }
        }

        ctrl.donutTypeTags.instanceChart.series[0].setData(datas);
        ctrl.donutTypeTags.instanceChart.series[0].update({
          name: ctrl.donutTypeTags.selectedType.libelle
        });

        ctrl.donutTypeTags.instanceChart.setTitle({
          text: '<strong>' + ctrl.donutTypeTags.selectedType.libelle + '</strong>'
        });

        //ctrl.donutTypeTags.show = true;
      }).finally(function () {
        ctrl.donutTypeTags.loading = false;
      });
    }*/

    var demandeDevisTmp = null;
    var demandeDevisNonValideTmp = null;
    var demandeInterventionTmp = null;
    var demandeContratTmp = null;
    var contratNonRecuTmp = null;
    var evenementAvecSinistreTmp = null;
    var demandeDevisAvecFactureTmp = null;
    var demandeDevisAvecFactureNonTraiteeTmp = null;

    function onChangeClasse(){

      if(_.isNil(demandeDevisTmp)) demandeDevisTmp = angular.copy(ctrl.filtre.demandeDevis);
      if(_.isNil(demandeDevisNonValideTmp)) demandeDevisNonValideTmp = angular.copy(ctrl.filtre.demandeDevisNonValide);
      if(_.isNil(demandeInterventionTmp)) demandeInterventionTmp = angular.copy(ctrl.filtre.demandeIntervention);
      if(_.isNil(demandeContratTmp)) demandeContratTmp = angular.copy(ctrl.filtre.demandeContrat);
      if(_.isNil(contratNonRecuTmp)) contratNonRecuTmp = angular.copy(ctrl.filtre.contratNonRecu);
      if(_.isNil(evenementAvecSinistreTmp)) evenementAvecSinistreTmp = angular.copy(ctrl.filtre.evenementAvecSinistre);
      if(_.isNil(demandeDevisAvecFactureTmp)) demandeDevisAvecFactureTmp = angular.copy(ctrl.filtre.demandeDevisAvecFacture);
      if(_.isNil(demandeDevisAvecFactureNonTraiteeTmp)) demandeDevisAvecFactureNonTraiteeTmp = angular.copy(ctrl.filtre.demandeDevisAvecFactureNonTraitee);

      if(ctrl.filtre.classe == 1){
        ctrl.filtre.demandeDevis = false;
        ctrl.filtre.demandeDevisNonValide = false;
        ctrl.filtre.demandeIntervention = false;
        ctrl.filtre.demandeContrat = false;
        ctrl.filtre.contratNonRecu = false;
        ctrl.filtre.evenementAvecSinistre = false;
        ctrl.filtre.demandeDevisAvecFacture = false;
        ctrl.filtre.demandeDevisAvecFactureNonTraitee = false;
      }
      else {
        ctrl.filtre.demandeDevis = demandeDevisTmp;
        ctrl.filtre.demandeDevisNonValide = demandeDevisNonValideTmp;
        ctrl.filtre.demandeIntervention = demandeInterventionTmp;
        ctrl.filtre.demandeContrat = demandeContratTmp;
        ctrl.filtre.contratNonRecu = contratNonRecuTmp;
        ctrl.filtre.evenementAvecSinistre = evenementAvecSinistreTmp;
        ctrl.filtre.demandeDevisAvecFacture = demandeDevisAvecFactureTmp;
        ctrl.filtre.demandeDevisAvecFactureNonTraitee = demandeDevisAvecFactureNonTraiteeTmp;
      }
    }

    /*
    function onChangeTagTypeDonut() {
      getTagsMoreUsed();
    }

    function onClickShowListTagsTypeDonut() {
      ctrl.donutTypeTags.modeGraph = !ctrl.donutTypeTags.modeGraph;
    }*/

    /**
     * Lors du clique sur le bouton "Détails" pour ouvrir les détails de tous les événements
     */
    function onClickDetailsAll() {
      ctrl.enableDetail = !ctrl.enableDetail;
      for (var i = 0; i < ctrl.liste_event.length; i++) {
        var enable = false;
        if (ctrl.enableDetail) {
          enable = true;
        }
        ctrl.liste_event[i].openTagsType = enable;
      }
    }

    /**
     * Lors du clique sur la case à coché de tout les topics
     */
    function onChangeAllSelected() {
      ctrl.liste_event.map(function(item){
        item.eventSelected = angular.copy(ctrl.allSelected);
      });
      calculNbSelected();
    }

    function onClickArchiveAllTopicSelected(){
      var topicsId = getTopicsSelectedAllowForArchive();
      if(topicsId.length){
        ModalsService
          .confirm('Archivage', 'Archiver ' + topicsId.length + ' dossier(s) ?', {type: 'info',closeOnConfirm:false,showLoaderOnConfirm:true})
          .then(function (modal) {
            TopicService
              .cacheTopic(topicsId)
              .then(function (topicState) {
                updateObjetReporting(topicState);
                _.remove(ctrl.liste_event, function(item){
                  var remove = false;
                  if(item.eventSelected && ctrl.filtre.etat == 0) remove = true;
                  return remove;
                });
              })
              .finally(function(){
                modal.close();
                deselectAllTopic();
              });
          });
      }
    }

    function onClickDesarchiveAllTopicSelected(){
      var topicsId = getTopicsSelectedAllowForDesarchive();
      if (topicsId.length) {
        ModalsService
          .confirm('Désarchivage', 'Retirer des archives ' + topicsId.length + ' dossier(s) ?', {type: 'info',closeOnConfirm:false,showLoaderOnConfirm:true})
          .then(function (modal) {
            TopicService
              .normalizeTopic(topicsId)
              .then(function (topicState) {
                updateObjetReporting(topicState);
                _.remove(ctrl.liste_event, function(item){
                  var remove = false;
                  if(item.eventSelected && ctrl.filtre.etat == 2) remove = true;
                  return remove;
                });
              })
              .finally(function(){
                modal.close();
                deselectAllTopic();
              });
          });
      }
    }

    function updateObjetReporting(listTopicStates) {
      if(_.isObject(listTopicStates)) {
        // Parcours la liste du dashboard
        ctrl.liste_event.map(function(item){
          if(_.isArray(listTopicStates)) {
            // Parcours la liste des contenus retournés
            for (var j = 0; j < listTopicStates.length; j++) {
              // Si trouvé
              if (item.getIdTopic() === listTopicStates[j].getTopic().getIdTopic()) {
                item.setDateClot(listTopicStates[j].getTopic().getDateCloture());
                item.setTopicState(listTopicStates[j]);
                break;
              }
            }
          }
          else if(_.isObject(listTopicStates)) {
            if (item.getIdTopic() === listTopicStates.getTopic().getIdTopic()) {
              item.setDateClot(listTopicStates.getTopic().getDateCloture());
              item.setTopicState(listTopicStates);
            }
          }
          return item;
        });
      }
    }

    function onClickCloturerAllTopicSelected() {
      var topicsId = getTopicsSelectedAllowForCloture();
      if (topicsId.length) {
        // TODO: Si un des topic est bloqué, il n'aura pas le commentaire (contenu message) de la cloture
        /*
        sweet.show({
            title: 'Cloture',
            text: 'Cloturer ' + topicsId.length + ' topic(s) ?',
            type: 'info',
            showCancelButton: true,
            //cancelButtonText: 'Annuler',
            confirmButtonText: 'Cloturer !',
            closeOnConfirm: false,
            showLoaderOnConfirm: true
        }, function () {
*/
        //ctrl.loading = true;

        TopicService
          .clotureOrReouvre(topicsId, 'topic', 'cloture', true)
          .then(function (listTopicState) {
            var titre = 'Dossier cloturé';
            var topicText = 'dossier selectionné';
            var topicClotureText = 'dossier cloturé';
            if (topicsId.length > 1) topicText = 'dossiers selectionnés';

            var topicStates = [];
            if (listTopicState.length) {
              for (var c = 0; c < listTopicState.length; c++) {
                if (listTopicState[c].getTopic().isCloture()) {
                  topicStates.push(listTopicState[c]);
                }
              }
            }

            if (topicStates.length > 1) {
              titre = 'Dossiers cloturés';
              topicClotureText = 'dossiers cloturés';
            }

            var description = '<strong>' + topicsId.length + '</strong> ' + topicText + '<br />';
            description += '<strong>' + topicStates.length + '</strong> ' + topicClotureText;

            ModalsService.info(titre, description);

            updateObjetReporting(topicStates);
            //getEventDevis();
          })
          .finally(function () {
            deselectAllTopic();
            //sweet.close();
          });
        //});
      }
    }

    function onClickReouvrirAllTopicSelected() {
      var topicsId = getTopicsSelectedAllowForReouvrir();
      if (topicsId.length) {

        TopicService
          .clotureOrReouvre(topicsId, 'topic', 'reouverture', true)
          .then(function (listTopicState) {
            var titre = 'Dossier décloturé';
            var topicText = 'dossier selectionné';
            var topicClotureText = 'dossier décloturé';
            if (topicsId.length > 1) topicText = 'dossiers selectionnés';

            var topicStates = [];
            if (listTopicState.length) {
              for (var c = 0; c < listTopicState.length; c++) {
                if (!listTopicState[c].getTopic().isCloture()) {
                  topicStates.push(listTopicState[c]);
                }
              }
            }

            if (topicStates.length > 1) {
              titre = 'Dossiers décloturés';
              topicClotureText = 'dossiers décloturés';
            }

            var description = '<strong>' + topicsId.length + '</strong> ' + topicText + '<br />';
            description += '<strong>' + topicStates.length + '</strong> ' + topicClotureText;

            ModalsService.info(titre, description);

            updateObjetReporting(topicStates);
          })
          .finally(function () {
            deselectAllTopic();
          });
      }
    }

    /**
     * Lors du clique sur le bouton relancer les devis des événements pour tous les topic selectionnés
     */
    function onClickRelancerAllTopicSelected() {
      sweet.show({
        title: 'Relance',
        text: 'Relancer ' + ctrl.nbTopicSelectedForRelance + ' dossier(s) ?',
        type: 'info',
        showCancelButton: true,
        //cancelButtonText: 'Annuler',
        confirmButtonText: 'Relancer !',
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      }, function () {
        relancerDevis(getTopicsSelectedAllowForRelance()).then(function () {
          deselectAllTopic();
        }).finally(function () {
          sweet.close();
        });
      });
    }

    /**
     * Lors du clique sur la case à coché de la ligne du topic
     * @param event
     */
    function onCheckDossier() {
      calculNbSelected();
    }

    /**
     * Lors du clique sur le bouton cloturer l'événement du topic
     * @param event
     */
    function onClickCloturerTopicSelected(event) {
      if (_.isObject(event)) {
        TopicService
          .clotureOrReouvre(event.idTopic, 'topic', 'cloture')
          .then(function (topicState) {
            updateObjetReporting(topicState);
          })
          .finally(function(){
            calculNbSelected();
          });
        /*
        if(event.idEvenement) {
          ContenuService
            .clotureOrReouvreEvenement(event)
            .then(function (contenu) {
            if (_.isObject(contenu) && contenu.isModel) {
              event.setDateClot(contenu.getDateCloture());
            }
          });
        }
        else {
          TopicService
            .clotureOrReouvre(event)
            .then(function (topic) {
            if (_.isObject(topic) && topic.isModel) {
              event.setDateClot(topic.getDateCloture());
            }
          });
        }*/
      }
    }

    /**
     * Lors du clique sur le bouton cloturer l'événement du topic
     * @param event
     */
    function onClickReouvrirTopicSelected(event) {
      if (_.isObject(event)) {
        TopicService
          .clotureOrReouvre(event.idTopic, 'topic', 'reouverture')
          .then(function (topicState) {
            updateObjetReporting(topicState);
          })
          .finally(function(){
            calculNbSelected();
          });
      }
    }


    /**
     * Lors du clique sur le bouton archiver le topic
     * @param obj
     */
    function onClickArchiveTopicSelected(obj) {
      if (_.isObject(obj)) {
        TopicService
          .cacheTopic(obj.getIdTopic())
          .then(function (topicState) {
            obj.setTopicState(topicState);
          })
          .finally(function(){
            calculNbSelected();
          });
      }
    }

    /**
     * Lors du clique sur le bouton retirer des archives le topic
     * @param obj
     */
    function onClickNormalizeTopicSelected(obj){
      if (_.isObject(obj)) {
        TopicService
          .normalizeTopic(obj.getIdTopic())
          .then(function (topicState) {
            obj.setTopicState(topicState);
          })
          .finally(function(){
            calculNbSelected();
          });
      }
    }

    /**
     * Lors du clique sur le bouton relancer les devis du topic
     * @param event
     */
    function onClickRelancerTopicSelected(event) {
      if (angular.isObject(event)) {
        sweet.show({
          title: 'Relance',
          text: 'Relancer ?',
          type: 'info',
          showCancelButton: true,
          //cancelButtonText: 'Annuler',
          confirmButtonText: 'Relancer !',
          closeOnConfirm: false,
          showLoaderOnConfirm: true
        }, function () {
          relancerDevis([event.idTopic])
            .finally(function () {
              sweet.close();
            });
        });
      }
    }

    function onClickOpenCommunication(event) {

      ModalsService.modalCommunication(event).then(function () {

      });
    }

    /**
     * Permet de récupérer la liste des idTopic selected
     * @returns {Array}
     */
    function getTopicsSelected() {
      var topicsId = [];
      for (var i = 0; i < ctrl.liste_event.length; i++) {
        if (ctrl.liste_event[i].eventSelected) {
          topicsId.push(ctrl.liste_event[i].idTopic);
        }
      }
      return topicsId;
    }

    function calculNbSelected() {
      ctrl.nbTopicSelectedForCloture = getTopicsSelectedAllowForCloture().length;
      ctrl.nbTopicSelectedForReouvrir = getTopicsSelectedAllowForReouvrir().length;
      ctrl.nbTopicSelectedForRelance = getTopicsSelectedAllowForRelance().length;
      ctrl.nbTopicSelectedForArchive = getTopicsSelectedAllowForArchive().length;
      ctrl.nbTopicSelectedForDesarchive = getTopicsSelectedAllowForDesarchive().length;
    }

    /**
     * Permet de récupérer la liste des idTopic selected et qui peuvent être cloturé
     * @returns {Array}
     */
    function getTopicsSelectedAllowForCloture() {
      return ctrl.liste_event.reduce(function(accum, item){
        if (item.eventSelected && _.isNil(item.dateClot)) accum.push(item.idTopic);
        return accum;
      }, []);
    }

    /**
     * Permet de récupérer la liste des idTopic selected et qui peuvent être cloturé
     * @returns {Array}
     */
    function getTopicsSelectedAllowForReouvrir() {
      return ctrl.liste_event.reduce(function(accum, item){
        if (item.eventSelected && !_.isNil(item.dateClot)) accum.push(item.idTopic);
        return accum;
      }, []);
    }

    /**
     * Permet de récupérer la liste des idTopic selected et qui peuvent être archivé
     * @returns {Array}
     */
    function getTopicsSelectedAllowForArchive() {
      return ctrl.liste_event.reduce(function(accum, item){
        if (item.eventSelected && !_.isNil(item.dateClot) && !item.getTopicState().isArchiveOrCache()) accum.push(item.idTopic);
        return accum;
      }, []);
    }

    /**
     * Permet de récupérer la liste des idTopic selected et qui peuvent être desarchivé
     * @returns {Array}
     */
    function getTopicsSelectedAllowForDesarchive() {
      return ctrl.liste_event.reduce(function(accum, item){
        if (item.eventSelected && _.isNil(item.dateClot) && item.getTopicState().isArchiveOrCache()) accum.push(item.idTopic);
        return accum;
      }, []);
    }

    /**
     * Permet de récupérer la liste des idTopic selected et qui peuvent être relancé
     * @returns {Array}
     */
    function getTopicsSelectedAllowForRelance() {
      return ctrl.liste_event.reduce(function(accum, item){
        if (item.eventSelected && canRelance(item)) accum.push(item.idTopic);
        return accum;
      }, []);
    }

    /**
     * Permet de deslectionner toute les lignes
     */
    function deselectAllTopic() {
      ctrl.allSelected = false;
      for (var i = 0; i < ctrl.liste_event.length; i++) {
        ctrl.liste_event[i].eventSelected = false;
      }
      calculNbSelected();
    }

    /**
     * Relance les devis à partir d'une liste d'idTopic
     * @param listIdTopic
     */
    function relancerDevis(listIdTopic) {
      var deferred = $q.defer();
      if (listIdTopic.length) {
        ctrl.loading = true;
        ContenuService.relancerDevis(listIdTopic).then(function () {
          deferred.resolve();
        }, function (msg) {
          deferred.reject(msg);
        }).finally(function () {
          ctrl.loading = false;
          calculNbSelected();
        });
      }
      return deferred.promise;
    }

    /*
    function setFiltreTypeDevis(choix) {
        ctrl.filtre.typeDevis = choix;
        getEventDevis();
    }

    function setFiltreCloture(choix) {
        ctrl.filtre.cloture = choix;
        getEventDevis();
    }


    function setFiltreDureePeriode(choix) {
        if (choix !== 0) {
            var today = new Date();
            var choice = new Date();
            choice.setDate(today.getDate() - choix);

            ctrl.filtre.dureePeriode = choix;
            ctrl.filtre.debutCreation = choice;
            ctrl.filtre.finCreation = today;
        }
        else {
            ctrl.filtre.dureePeriode = 0;
            ctrl.filtre.debutCreation = null;
            ctrl.filtre.finCreation = null;
        }

        getEventDevis();
    }

    function setFiltreDureeEcheance(choix) {
        ctrl.filtre.dureeEcheance = choix;
        getEventDevis();
    }*/

    function canRelance(event) {
      /*
        return (
            (
                event.nbDevisDemandes !== 0 &&
                (
                    (event.nbDevisDemandes - event.nbDevisDemandesFermee) > event.nbFacture
                )
            )
            ||
            (
                event.nbDevisIntervention !== 0 &&
                event.nbDevisIntervention > event.nbDevisInterventionFermee
            )
        );*/

      if(UtilsService.contenuHasTagTypeOldPortefeuille(event)) return false;

      // Si cloturé
      if(!_.isNil(event.dateClot)) return false;

      // Si il y a des demandes de devis ou intervention
      if((!_.isNil(event.nbDevisDemandes) && event.nbDevisDemandes !== 0) || (!_.isNil(event.nbDevisIntervention) && event.nbDevisIntervention !== 0)) {

        var nbDemandesDevisOuvertes = (event.nbDevisDemandes - event.nbDevisDemandesFermee);
        //var nbDemandeDevisEnAttenteTraitement = event.nbDevisReponse - (event.nbDevisSigne + event.nbDevisRefuse);

        // Info: les demande fermée sont des deamndes cloturé. Les demandes refusée sont des demandes pas encore cloturés
        // Le nombre de devis attendu est égale au nombre de demande ouverte moins les demandes refusés
        var nbDevisExpect = nbDemandesDevisOuvertes;

        // Retie le nombre de devis reçu
        nbDevisExpect -= event.nbDevisReponse;

        //Si il reste des deis à recevoir alors on sort direct
        if(nbDevisExpect > 0) return true;

        // Le nombre de facture attendu est égale au nombre de devis reçu (Pour les demandes de devisn, on relance pour une facture quand on a signé le devis reçu)
        var nbFactureExpect = event.nbDevisSigne;

        // On ajoute le nombre d'inter encore ouverte au nombre de facture attendu
        nbFactureExpect += (event.nbDevisIntervention - event.nbDevisInterventionFermee);

        // Retire le nombre de facture reçu du nombre de facture attendu
        nbFactureExpect -= event.nbFacture;

        // Si il reste des factures à recevoir (demande de devis et inter confondus)
        if(nbFactureExpect > 0) return true;

        /*
        // Si il y a des demandes de devis
        if(!_.isNil(event.nbDevisDemandes) && event.nbDevisDemandes !== 0){

          // Si le nombre de demande est égale au nombre de devis refusé OU si il est égale au nombre de demande fermé on ne peut rien relancer
          if(event.nbDevisIntervention === 0 && (event.nbDevisDemandes === event.nbDevisRefuse || event.nbDevisDemandes === event.nbDevisDemandesFermee)) return false;

          var nbDemandeOuvertes = (event.nbDevisDemandes - event.nbDevisDemandesFermee);

          // Si on a pas tout les devis on peux relancer
          if(nbDemandeOuvertes !== event.nbDevisReponse) return true;

          nbFactureExpect += event.nbDevisSigne;

          // Si on a tous les devis et qu'il sont tous signé on continu car il faut prendre en cles interventions plus tards
          //if(nbDemandeOuvertes === event.nbDevisSigne) {
            // Si tous les devis sont signé et que le nombre de demande de devis ouvertes et plus grand que le nombre de facture alors on peux relancer car il manque des factures
          //  if(nbDemandeOuvertes > event.nbFacture) return true;
          //}

          // On ajoute le nombre de devis signé au nombre de facture attendu
          //nbFactureExpect += event.nbDevisSigne;


          // Si le nombre de demande est égale au nombre de devis reçu
          //if(nbDemandeOuvertes == event.nbDevisReponse) {
            // Si le nombre de demande est égale au nombre de devis signé alors on peux relancer pour que le fournisseur intervienne car pas toutes les factures encore
          //  if(nbDemandeOuvertes === event.nbDevisSigne) {
              // Si le nombre de demande de devis ouvertes et plus grand que le nombre de facture alors on peux relancer car il manque des factures
          //    if(nbDemandeOuvertes > event.nbFacture) return true;
          //  }
          }

          var nbDemandeInterOuvertes = (event.nbDevisIntervention - event.nbDevisInterventionFermee);

          // On ajoute le nombre d'inter encore ouverte au nombre de facture attendu
          nbFactureExpect += nbDemandeInterOuvertes;

          // Si il y a des demandes d'intevention
          //if(!_.isNil(event.nbDevisIntervention) && event.nbDevisIntervention !== 0){
          // Si le nombre d'intervention et plus grand que le nombre d'intervention fermé on peu relancer
          // TODO: manque le nombre de facture ? mais comment savoir si c'est une facture d'un devis ?
          //  if(event.nbDevisIntervention > event.nbDevisInterventionFermee) return true;
          //}

          // Retire le nombre de facture reçu du nombre de facture attendu
          nbFactureExpect -= event.nbFacture;

          // Si il reste factre à recevoir
          if(nbFactureExpect > 0) return true;
        }*/

      }

      return false;
    }

    //getEventDevis();

    function getLigneStatus(event) {
      var classRetour = '';
      if (event.dateClot) classRetour = 'cloture';
      else if (event.getTopicState().isArchiveOrCache()) classRetour = 'archive';
      else {
        if (event.tauxReponse === 100 && event.tauxValide === 100 && event.tauxSigne === 100) {
          classRetour = 'bg-success';
        }
        else if (event.tauxReponse === 100 && event.tauxValide === 0 && event.nbDevisRefuse === 0) {
          classRetour = 'bg-danger';
        }
        else if ((event.tauxReponse > 0 && ctrl.calcNbAttenteDevisValide(event) > 0) || (event.tauxReponse > 0 && ctrl.calcNbAttenteDevisSigne(event) > 0)) {
          classRetour = 'bg-warning';
        }
        if (event.actionOpen) {
          classRetour = 'active';
        }
      }

      return classRetour;
    }

    function getTypeProgressBar(taux, origin) {
      var type = 'primary';
      origin = origin !== undefined && origin !== '' ? origin : 0;
      if (taux === 0 && origin > 0) {
        type = 'danger-lighten';
      } else if (taux === 0) {
        type = 'empty';
      } else if (taux < 34) {
        type = 'danger';
      } else if (taux > 66) {
        type = 'success';
      }
      return type;
    }

    function calcNbAttenteDevis(event) {
      return event.nbDevisDemandes - (event.nbDevisReponse + event.nbDevisDemandesFermee);
    }

    function calcNbAttenteDevisValide(event) {
      return event.nbDevisReponse - (event.nbDevisValide + event.nbDevisRefuse);
    }

    function calcNbAttenteDevisSigne(event) {
      return event.nbDevisValide - event.nbDevisSigne;
    }

    /**
     * Ouvre/Ferme la ligne des groupements
     * @param event
     */
    function openGroupements(event) {
      if (event.open) {
        event.open = false;
        event.loadingGroupement = false;
      }
      else {
        event.open = true;
        event.loadingGroupement = true;
      }

    }

    /**
     * Permet de cacher le loading du groupement quand composant ok
     * @param event
     */
    function onReadyGroupementDevisStatistiques(event) {
      event.loadingGroupement = false;
    }

    //function openDateDebutCreation(){ctrl.dateDebutCreationOpened = true;}
    //function openDateFinCreation(){ctrl.dateFinCreationOpened = true;}
    function clearDatesCreation() {
      ctrl.filtre.dureePeriode = 0;
      ctrl.filtre.debutCreation = null;
      ctrl.filtre.finCreation = null;

      ctrl.filtre.dateCreation.startDate = angular.copy(defaultValuesDatePicker.startDate);
      ctrl.filtre.dateCreation.endDate = angular.copy(defaultValuesDatePicker.endDate);
      ctrl.filtre.dateCreation.label = angular.copy(defaultValuesDatePicker.label);

      ctrl.filtre.dateCreation.showDates = false;
      ctrl.filtre.dateCreation.isDefault = true;

      //getEventDevis();
    }

    //function openDateDebutEcheance(){ctrl.dateDebutEcheanceOpened = true;}
    //function openDateFinEcheance(){ctrl.dateFinEcheanceOpened = true;}
    function clearDatesEcheance() {
      ctrl.filtre.debutEcheance = null;
      ctrl.filtre.finEcheance = null;
      ctrl.filtre.dateEcheance.startDate = angular.copy(defaultValuesDatePicker.startDate);
      ctrl.filtre.dateEcheance.endDate = angular.copy(defaultValuesDatePicker.endDate);
      ctrl.filtre.dateEcheance.label = angular.copy(defaultValuesDatePicker.label);
      ctrl.filtre.dateEcheance.showDates = false;
      ctrl.filtre.dateEcheance.isDefault = true;
      //getEventDevis();
    }

    function onSubmitRecherche(tag) {
      if (tag) ctrl.filtreTags = [tag];
      getEventDevis(true);
    }

    function onClickDownloadExcel(){
      var params = initSearchParams();
      params.exportEXCEL = true;
      ctrl.loading = true;
      DashboardService.downloadExcelReporting(params)
        .then(function(data){
          var headers = data.headers;
          var windowUrl = (window.URL || window.webkitURL || window.mozURL);
          var downloadableBlob = windowUrl.createObjectURL(data.response);
          var fileNamePattern = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

          var a = document.createElement('a');
          a.href = downloadableBlob;
          a.download = fileNamePattern.exec(headers['content-disposition'])[1];
          a.target = '_blank';

          document.body.appendChild(a);

          a.click();

          $timeout(function(){
            document.body.removeChild(a);
            windowUrl.revokeObjectURL(downloadableBlob);
          }, 100);

        })
        .finally(function () {
          ctrl.loading = false;
        });
    }

    function changeTri(tri) {
      if(arrayColSortNotAllowedForES.indexOf(tri) !== -1) return;

      if (ctrl.order !== tri) {
        ctrl.order = tri;
        ctrl.sens = 'DESC';
      } else {
        if (ctrl.sens === 'DESC') ctrl.sens = 'ASC'; else ctrl.sens = 'DESC';
      }
      getEventDevis();
    }

    /*
     function setLimit(nb) {
     if(nb){
     ctrl.limit = nb;
     }
     getEventDevis();
     }*/

    function initSearchParams(goPageOne){
      //console.log(ctrl.pagination.currentPage);

      var filtre = angular.copy(ctrl.filtre);

      // Si la date choisi est celle par default alors on supprime les dates
      if (filtre.dateCreation.isDefault) {
        filtre.dateCreation.startDate = null;
        filtre.dateCreation.endDate = null;
      }
      if (filtre.dateEcheance.isDefault) {
        filtre.dateEcheance.startDate = null;
        filtre.dateEcheance.endDate = null;
      }

      /*
       if(filtre.avec_devis && filtre.avec_interventions) {
       filtre.typeDevis = 'devis_interventions';
       }
       else if(filtre.avec_devis) {
       filtre.typeDevis = 'devis';
       }
       else if(filtre.avec_interventions) {
       filtre.typeDevis = 'interventions';
       }

       if(filtre.avec_cloture && filtre.avec_non_cloture) {
       filtre.cloture = 0;
       }
       else if(filtre.avec_cloture) {
       filtre.cloture = 1;
       }
       else if(filtre.avec_non_cloture) {
       filtre.cloture = 2;
       }*/


      if(goPageOne) ctrl.pagination.currentPage = 1;

      var params = {
        order: ctrl.order,
        sens: ctrl.sens,
        limit: ctrl.limit,
        page: ctrl.pagination.currentPage,
        filtre: filtre,
        tags: ctrl.filtreTags,
        listeFournisseurMail: ctrl.listeFournisseurMail,
        listeFournisseurPersoICS: ctrl.listeFournisseurPersoICS
      };

      if (filtreByTagType) {
        if (_.isArray(filtreByTagType)) ctrl.filtreTags = params.tags = filtreByTagType;
        else ctrl.filtreTags = params.tags = [filtreByTagType];
      }

      ConfigService.setFiltresTableauDevisDashboard(params);

      return params;
    }

    /**
     * Recupère la lignes des événements
     */
    function getEventDevis(goPageOne) {

      var params = initSearchParams(goPageOne);

      ctrl.loading = true;
      ctrl.search_event_submited = true;
      DashboardService.searchReportingDashboard(params)
        .then(function (json) {
          ctrl.pagination.nbTotal = json.total;
          ctrl.liste_event = json.liste_events;
          ctrl.infos = json.infos;
          ctrl.resultElasticSearch = json.resultElasticSearch;
        })
        .catch(function (err) {
          ctrl.pagination.nbTotal = 0;
          ctrl.liste_event = [];
        })
        .finally(function () {
          ctrl.loading = false;
          onChangeAllSelected();
          $rootScope.current.filtreTopics.filtreDashboard = angular.copy(filtre);
          $rootScope.current.filtreTopics.listeFournisseurMail = angular.copy(ctrl.listeFournisseurMail);
          $rootScope.current.filtreTopics.listeFournisseurPersoICS = angular.copy(ctrl.listeFournisseurPersoICS);
        });
    }


    /**
     * Affiche/Cache les tags d'un event
     * @param event
     */
    function showTags(event) {
      event.openTagsType = !event.openTagsType;
    }

    function removeTagFromFiltre(objUpdate) {

      if (objUpdate.hasOwnProperty('deleteTagFromContenu')) {
        var tagDel = objUpdate.deleteTagFromContenu;
        if (ctrl.filtreTags.length) {
          if (filtreByTagType) {
            if (filtreByTagType.idTag === tagDel.idTag) {
              delete $stateParams.search;
              $state.reload();
            }
          }
          else {
            ctrl.filtreTags = TagService.deleteTagFromTags(ctrl.filtreTags, tagDel);
            // getEventDevis();
          }
        }
      }
    }

    function removeParamFromFiltre(objUpdate, liste) {
      if (objUpdate.hasOwnProperty('deleteTagFromContenu')) {
        var tagDel = objUpdate.deleteTagFromContenu;
        if (liste.length) {
          TagService.deleteTagFromTags(liste, tagDel);
          // getEventDevis();
        }
      }
    }

    /*
    var tagsTypeAllowForSearch = [
        COLLAB_CONF.TAG_TYPE_COPROPRIETAIRE,
        COLLAB_CONF.TAG_TYPE_PROPRIETAIRE,
        COLLAB_CONF.TAG_TYPE_IMMEUBLE,
        COLLAB_CONF.TAG_TYPE_LOCATAIRE,
        COLLAB_CONF.TAG_TYPE_LOT,
        COLLAB_CONF.TAG_TYPE_MANDAT
    ];

    /*
    var tagsTypeAllowForSearchICS = [
        COLLAB_CONF.TAG_TYPE_PROJET
    ];
*/
    function openAnnuaire() {
      var obj = {
        'openFrom': 'dashboard',
        'stringTo': 'tag',
        'limitDestinataire': 1,
        'defautTypeAnnuaire': 'perso'
      };

      AnnuaireService.openAnnuaire(obj)
        .then(function (objRetour) {

          filtreByTagType = false;
          //console.log('objRetour',objRetour);
          //ctrl.listeFournisseurMail = [];
          //ctrl.listeFournisseurPersoICS = [];
          if (objRetour.isTag) {
            ctrl.filtreTags = [objRetour.tag];
            // getEventDevis();
          }
          else {
            // var objetsTags = TagService.genereTagsForType(objRetour);
            var objetsTags = TagsService.genereTagsAndFiches(objRetour)

            if (objetsTags.hasOwnProperty('addTag')) {
              var listeTagsSearch = [];
              var callTopicList = false;
              if (objetsTags.addTag.hasOwnProperty('tagsType')) {

                // Cherche dans les tagType
                var listeTagsTypes = TopicStatesService.getTagsTypeAllowForSearchTopic(objetsTags);
                if (listeTagsTypes.length) {
                  angular.merge(listeTagsSearch, listeTagsTypes);
                }

                // Cherche dans le "tag" qui est normalement un tag origine si ICS le demande ou si pas encore de résultat dans les tag type
                if (UtilsService.isICS()) {
                  var tagOrigin = TopicStatesService.getParamsSearchTopicByTagOrigin(objetsTags);
                  if (tagOrigin) {
                    listeTagsSearch.push(tagOrigin);
                  }
                }

                // Si liste de tag trouvé alors recherche les topic avec
                if (listeTagsSearch.length) {
                  callTopicList = true;
                }


                /*
                if (angular.isArray(objetsTags.addTag.tagsType)) {

                    for (var t = 0; t < objetsTags.addTag.tagsType.length; t++) {
                        var tag = objetsTags.addTag.tagsType[t];
                        if (tag.hasOwnProperty('typeTag')) {
                            if (UtilsService.isICS()) {
                                if (UtilsService.contains(tagsTypeAllowForSearchICS, tag.typeTag.libelle)) {
                                    listeTagsSearch.push(angular.copy(tag));
                                }
                            }
                            else {
                                if (UtilsService.contains(tagsTypeAllowForSearch, tag.typeTag.libelle)) {
                                    listeTagsSearch.push(angular.copy(tag));
                                }
                            }
                        }
                    }

                    if (objetsTags.addTag.hasOwnProperty('tag') && UtilsService.isICS()) {
                        if (UtilsService.contains(COLLAB_CONF.ARRAY_TAGS_ORIGINE, objetsTags.addTag.tag.typeTag.libelle)) {
                            listeTagsSearch.push(angular.copy(objetsTags.addTag.tag));
                        }
                    }

                    ctrl.filtreTags = listeTagsSearch;

                }*/
              }

              /*
              // Si toujours pas de callTopicList à true passe par les fiches pour essayer de trouver des infos
              if(!callTopicList){
                  var objParamTopicByFiche = TopicStatesService.getParamsSearchTopicByFiche(objetsTags);
                  if(objParamTopicByFiche.success === true) {
                      ctrl.listeFournisseurMail = objParamTopicByFiche.listeFournisseurMail;
                      ctrl.listeFournisseurPersoICS = objParamTopicByFiche.listeFournisseurPersoICS;
                      callTopicList = true;
                  }
              }*/

              // Si toujours pas de callTopicList à true, tante la recherche avec tag origin
              if (!callTopicList) {
                var tagOrig = TopicStatesService.getParamsSearchTopicByTagOrigin(objetsTags);
                if (tagOrig) {
                  listeTagsSearch.push(tagOrig);
                  callTopicList = true;
                }
              }

              // Si liste de tag trouvé alors recherche les topic avec
              if (listeTagsSearch.length) {
                ctrl.filtreTags = listeTagsSearch;
              }

              if (callTopicList) {
                //getEventDevis();
              }
            }
          }
        });
    }

    function openAnnuaireFournisseur() {
      var obj = {
        openFrom: 'dashboard',
        stringTo: 'tag',
        limitDestinataire: 1,
        onlyFournisseur: true
      };

      AnnuaireService.openAnnuaire(obj)
        .then(function (objRetour) {
          //console.log(objRetour);

          ctrl.listeFournisseurMail = [];
          ctrl.listeFournisseurPersoICS = [];

          //var objetsTags = TagService.genereTagsForType(objRetour);
          var objetsTags = TagsService.genereTagsAndFiches(objRetour)
          if (objetsTags.hasOwnProperty('addTag')) {

            // Si toujours pas de callTopicList à true passe par les fiches pour essayer de trouver des infos
            var objParamTopicByFiche = TopicStatesService.getParamsSearchTopicByFiche(objetsTags);
            if (objParamTopicByFiche.success === true) {
              ctrl.listeFournisseurMail = objParamTopicByFiche.listeFournisseurMail;
              ctrl.listeFournisseurPersoICS = objParamTopicByFiche.listeFournisseurPersoICS;
              // getEventDevis();
            }
          }
        });
    }

    /**
     * Lors d'une remonté d'info depuis le composant de selection de catégorie topic
     * @param obj
     */
    function onEmitFromSelectCategorieTopic(obj){
      try {
        if (!obj) throw new Error('Pas d\'objet');
        if (_.isObject(obj)) {
          if(obj.action === 'selectListCategorieTopic') {
            ctrl.filtre.listCategorieTopic = obj.listCategorieTopicChecked;
          }
        }
      }
      catch (err) {
        console.log('[DashboardReportingController.onEmitFromSelectCategorieTopic] Erreur : ' + err.message);
      }
    }

  }
})();
