(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.FiltreService
   * @description
   * # FiltreService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('FiltreService',
      FiltreServiceController
    );

  /** @ngInject */
  function FiltreServiceController($q,$rootScope,$filter,uuid, ConfigService,FiltreTopicsAjax,UtilsService, TagService) {
    /*
     var wordArray = CryptoJS.enc.Utf8.parse(angular.toJson(stateParams));
     var base64 = CryptoJS.enc.Base64.stringify(wordArray);
     console.log(base64);

     var parsedWordArray = CryptoJS.enc.Base64.parse(base64);
     var parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
     console.log(parsedStr);*/

    var scope = this;
    var allowParamForSave = ['classe','cloturer','urgent','sinistre','tagType'];

    /* Public Methods */


    if(!$rootScope.current.filtreTopics){
      $rootScope.current.filtreTopics = ConfigService.getPreferencesFiltreTopics();
    }


    /******************   Filtre Perso *********************/
    /**
     * Recupère la liste des filtre perso enregistrés
     * @returns {a.fn.promise|*|webix.promise|promise.promise}
     */
    scope.getFiltrePersoTopics = function(){
      var deferred = $q.defer();
      var retour = [];
      FiltreTopicsAjax.get(function(json){
        if(json.nb!=='0'){
          retour = json.Filtres;
        }
        deferred.resolve(retour);
      },function(error){
        var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
        deferred.reject('Problème avec FiltreService.getFiltrePersoTopics(), '+msgErreur);
      });
      return deferred.promise;
    };

    /**
     * Sauvegarde un filtre perso
     * @param libelleFiltre
     * @returns {a.fn.promise|*|webix.promise|promise.promise}
     */
    scope.saveFiltrePersoTopics = function(libelleFiltre,idFiltre){

      var deferred = $q.defer();

      var listeCriteres = [];
      if(angular.isObject($rootScope.current.filtreTopics)){

        angular.forEach(allowParamForSave,function(param){
          if($rootScope.current.filtreTopics.hasOwnProperty(param)){
            var objTmp;
            // Si type tag
            if(param==='tagType'){
              objTmp = {
                'libelle': 'idTagType',
                'nb': ($rootScope.current.filtreTopics[param].idTag) ? parseInt($rootScope.current.filtreTopics[param].idTag) : 0
              };
            }else{
              objTmp = {
                'libelle': param,
                'nb': ($rootScope.current.filtreTopics[param]) ? parseInt($rootScope.current.filtreTopics[param]) : 0
              };

            }

            listeCriteres.push(objTmp);
          }
        });

      }

      var filtre = {
        'titre': libelleFiltre,
        'listeCriteres': listeCriteres
      };


      if(idFiltre){
        filtre.idFiltre = idFiltre;
      }

      var objParams = {};
      objParams.objJSON = angular.toJson(filtre);

      // Si nouveau filtre
      if(!idFiltre){
        FiltreTopicsAjax.addFiltre(objParams,function(json){
          if(json.nb!=='0'){
            deferred.resolve(json.Filtres[0]);
          }else{
            deferred.reject('Pas de filtre en retour');
          }


        },function(error){
          var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
          deferred.reject('Problème avec FiltreService.saveFiltrePersoTopics() (Insert), '+msgErreur);
        });

        // Si modification d'un filtre
      }else{
        FiltreTopicsAjax.updateFiltre(objParams,function(json){

          if(json.nb!=='0'){
            deferred.resolve(json.Filtres[0]);
          }else{
            deferred.reject('Pas de filtre en retour');
          }


        },function(error){
          var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
          deferred.reject('Problème avec FiltreService.saveFiltrePersoTopics() (Update), '+msgErreur);
        });
      }


      return deferred.promise;
    };

    /**
     * Update un filtre perso
     * @param filtreObj
     * @returns {a.fn.promise|*|webix.promise|promise.promise}
     */
    scope.updateFiltrePersoTopics = function(filtreObj){

      var deferred = $q.defer();

      FiltreTopicsAjax.updateFiltre(filtreObj,function(json){
        if(json.nb!=='0'){
          deferred.resolve(json.Filtres[0]);
        }else{
          deferred.reject('Pas de filtre en retour');
        }

      },function(error){
        var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
        deferred.reject('Problème avec FiltreService.updateFiltrePersoTopics(), '+msgErreur);
      });

      return deferred.promise;
    };

    /**
     * Supprime un filtr perso
     * @param objFiltre
     * @returns {a.fn.promise|*|webix.promise|promise.promise}
     */
    scope.deleteFiltrePersoTopics = function(objFiltre){
      var deferred = $q.defer();

      var objParam = {
        'idFiltre': objFiltre.idFiltre
      };


      FiltreTopicsAjax.deleteFiltre({'objJSON':angular.toJson(objParam)},function(json){

        if(json.nb!=='0'){
          if(json.Resultats[0].success){
            deferred.resolve(true);
          }else{
            deferred.resolve(false);
          }
        }else{
          deferred.resolve(false);
        }

      },function(error){
        var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
        deferred.reject('Problème avec FiltreService.saveFiltrePersoTopics(), '+msgErreur);
      });

      return deferred.promise;
    };

    scope.parseFiltrePersoToFiltreCollabore = function(objFiltrePerso){

      var objSearch = {};

      if(angular.isObject(objFiltrePerso)){

        var critereClasse = $filter('where')(objFiltrePerso.listeCriteres, {libelle:'classe'});

        if(critereClasse.length){
          switch(critereClasse[0].nb){
            case 1:
              objSearch.evenement = false;
              break;
            case 2:
              objSearch.evenement = {};

              var critereUrgent = $filter('where')(objFiltrePerso.listeCriteres, {libelle:'urgent'});
              if(critereUrgent.length){
                switch(critereUrgent[0].nb){
                  case 1:
                    objSearch.evenement.urgent = true;
                    break;
                  case 2:
                    objSearch.evenement.urgent = false;
                    break;
                  default:
                    objSearch.evenement.urgent = null;
                }
              }

              var critereSinistre = $filter('where')(objFiltrePerso.listeCriteres, {libelle:'sinistre'});
              if(critereSinistre.length){
                switch(critereSinistre[0].nb){
                  case 1:
                    objSearch.evenement.sinistre = true;
                    break;
                  case 2:
                    objSearch.evenement.sinistre = false;
                    break;
                  default:
                    objSearch.evenement.sinistre = null;
                }
              }

              var critereCloturer = $filter('where')(objFiltrePerso.listeCriteres, {libelle:'cloturer'});
              if(critereCloturer.length){
                switch(critereCloturer[0].nb){
                  case 1:
                    objSearch.evenement.cloturer = true;
                    break;
                  case 2:
                    objSearch.evenement.cloturer = false;
                    break;
                  default:
                    objSearch.evenement.cloturer = null;
                }
              }


              if(objFiltrePerso.hasOwnProperty('tagType')){
                if(angular.isObject(objFiltrePerso.tagType)){
                  objSearch.evenement.tagType = objFiltrePerso.tagType;
                }
              }
              /*
               var critereTagType = $filter('where')(objFiltrePerso.listeCriteres, {libelle:'idTagType'});
               if(critereTagType.length){
               objSearch.evenement.tagType = critereTagType[0];
               }*/

              break;
            default:
          }
        }
      }

      return objSearch;
    };

    /******************  Fin Filtre Perso *********************/

    scope.getFiltreTopics = function(){
      return $rootScope.current.filtreTopics;
    };

    /**
     * Init le filtre de base
     */
    scope.getBaseFiltre = function(){
      return angular.copy({
        idChannel: (angular.isDefined($rootScope.current.filtreTopics.idChannel) && $rootScope.current.filtreTopics.idChannel!==0) ? $rootScope.current.filtreTopics.idChannel : 0,
        libelle: false,
        isActive: false,
        tagType: false,
        tags: [],
        idPortefeuille: null,
        topicAvecDemande: null,
        classe:'0',   // Avec et sans event (0), sans event (1), avec event (2)
        cloturer: 2,  // Non cloturé par défaut
        urgent:0,
        sinistre: 0,
        withEvent: null,
        //'withoutEvent': true,
        withCloture: false,
        withoutCloture: true,
        withUrgent: true,
        withoutUrgent: true,
        withSinistre: true,
        withoutSinistre: true,
        listeFournisseurMail: [],
        listeFournisseurPersoICS: [],
        destinataires: [],
        intervenants: [],
        filtreDashboard: null,
        rechercheElasticsearch: null,
        dateCreation: {
          startDate: null,
          endDate: null,
          searchAll: true
        },
        //dateCreationDebut: null,
        //dateCreationFin: null,
        dateClotureDebut: null,
        dateClotureFin: null,
        stillOpenAt: null,
        others: {
          clotureEnable: false,
          nonLuEnable: false
        }
      });
    };


    /**
     * Permet de filtrer la liste des topics
     * Si btn radio alors value en string, si btn checkbox alors value en int
     */
    scope.filtrerTopics = function(norefresh){
      //console.log('FiltreService $stateParams',$stateParams);


      var objFiltre = angular.copy($rootScope.current.filtreTopics);

      // console.log(objFiltre);

      if(_.isObject(objFiltre)){
        //objFiltre.isActive = true;

        // Si recherche autre que tous les topics
        if(objFiltre.classe!=='0' ||
          (!_.isNil(objFiltre.tags) && objFiltre.tags.length) ||
          (!_.isNil(objFiltre.listeDestinataireEvenement) && objFiltre.listeDestinataireEvenement.length) ||
          (!_.isNil(objFiltre.destinataires) && objFiltre.destinataires.length) ||
          (!_.isNil(objFiltre.listeFournisseurMail) && objFiltre.listeFournisseurMail.length) ||
          (!_.isNil(objFiltre.listeFournisseurPersoICS) && objFiltre.listeFournisseurPersoICS.length)){
          //objFiltre.libelleBtn = 'Filtré';
          objFiltre.isActive = true;
          //objFiltre.classe = 0;
        }else{
          objFiltre.isActive = false;
        }

        // Si recherche différent de topics avec événement
        if(objFiltre.classe!=='2'){
          //objFiltre.tagType = false;
          //objFiltre.tags = [];
          objFiltre.listeFournisseurMail = [];
          objFiltre.listeFournisseurPersoICS = [];
          //objFiltre.withCloture = false;
          //objFiltre.withoutCloture = true;
        }

        objFiltre.libelle = scope.genereDescription(objFiltre);

        var params = {
          page: $rootScope.current.pageTopic,
          objFiltre: objFiltre
        };

        // Change l'url sans rafraichir la page et sans notifier les callback
        //{'location': true, 'reload': false, 'notify': false}
        //$state.go($state.current.name,{topicPage: $rootScope.current.pageTopic},{'notify': false});

        if(!norefresh || _.isObject(norefresh)){
          if(_.isObject(norefresh)) {
            params = _.merge(params, norefresh);
          }
          $rootScope.$broadcast('filtreTopicList',params);
        }else{
          return params;
        }

      }
    };

    scope.genereDescription = function(objFiltre){
      var libelle = '';
      switch(objFiltre.classe){
        case '0':
          libelle = 'demandes';
          break;
        case '1':
          libelle = 'demandes sans événements';
          break;
        case '2':
          libelle = 'demandes avec événements';


          switch(objFiltre.urgent){
            case 1:
              libelle += ',urgent';
              break;
            case 2:
              libelle += ', pas urgent';
              break;
            default:
          }

          switch(objFiltre.sinistre){
            case 1:
              libelle += ' avec sinistre';
              break;
            case 2:
              libelle += ' sans sinistre';
              break;
            default:
          }

          break;
      }

      switch(objFiltre.cloturer){
        case 1:
          libelle += ' cloturés';
          break;
        case 2:
          libelle += ' non cloturées';
          break;
        default:
      }


      if(objFiltre.nonlu) libelle += ' non lu';

      /*
      if(_.isObject(objFiltre.tagType)){
        libelle += ' pour le "<strong>'+objFiltre.tagType.typeTag.libelle+'</strong>" '+$filter('parseTagType')(objFiltre.tagType);
      }
      else if(_.isArray(objFiltre.tags) && objFiltre.tags.length){
        libelle += ' filtrés par ';
      }*/

      if(_.isObject(objFiltre.tagType) || (_.isArray(objFiltre.tags) && objFiltre.tags.length)){
        libelle += ' filtrés par tags ';
      }
      /*
      if(_.isArray(objFiltre.listeProxyDestinataireEvenement) && objFiltre.listeProxyDestinataireEvenement.length){
        libelle += ' filtrés par destinataire ';
      }*/


      if(_.isArray(objFiltre.listeFournisseurMail) && objFiltre.listeFournisseurMail.length){
        libelle += ' filtrés par mails ';
      }

      if(_.isArray(objFiltre.listeFournisseurPersoICS) && objFiltre.listeFournisseurPersoICS.length){
        if(_.isArray(objFiltre.listeFournisseurMail) && objFiltre.listeFournisseurMail.length) {libelle += 'et';}
        else {libelle += ' filtrés';}

        libelle += ' par perso ICS ';
      }
      if((_.isArray(objFiltre.listeFournisseurPersoICS) && objFiltre.listeFournisseurPersoICS.length) || (_.isArray(objFiltre.listeFournisseurMail) && objFiltre.listeFournisseurMail.length)) {
        libelle += 'destinataires des demandes de devis ou interventions.';
      }

      return libelle;
    };

    scope.selectTagType = function(tag){
      //console.log(tag);
      $rootScope.current.filtreTopics.tagType = tag;
    };

    scope.deselectTagType = function(){
      //$rootScope.current.filtreTopics.tagType = $filter('removeWith')($rootScope.current.filtreTopics.tagType,{libelle:tag.libelle});
      $rootScope.current.filtreTopics.tagType = false;
    };

    /********** Test Filtres **************/

    /**
     * Test si le filtre est configuré avec un tag type
     * @returns {boolean}
     */
    scope.filtreWithTagsType = function(){
      var retour = false;
      if(angular.isObject($rootScope.current.filtreTopics.tagType)){
        retour = $rootScope.current.filtreTopics.tagType.idTag;
      }
      return retour;
    };

    /**
     * Test si le filtre est configuré pour les evenements
     * @returns {boolean}
     */
    scope.filtreIsEvenement = function(){
      var retour = false;
      if($rootScope.current.filtreTopics.classe==='2'){
        retour = true;
      }
      return retour;
    };

    /**
     * Test si le filtre est configuré pour les cloturés
     * @returns {boolean}
     */
    scope.filtreIsCloture = function(){
      var retour = false;
      if($rootScope.current.filtreTopics.cloturer===1){
        retour = true;
      }
      return retour;
    };

    /**
     * Test si le filtre est configuré pour les urgent
     * @returns {boolean}
     */
    scope.filtreIsUrgent = function(){
      var retour = false;
      if($rootScope.current.filtreTopics.urgent===1){
        retour = true;
      }
      return retour;
    };

    /**
     * Test si le filtre est configuré pour les sinistre
     * @returns {boolean}
     */
    scope.filtreWithSinistre = function(){
      var retour = false;
      if($rootScope.current.filtreTopics.sinistre===1){
        retour = true;
      }
      return retour;
    };

    scope.filtreIsChannel = function(){
      var retour = false;
      if($rootScope.current.filtreTopics.idChannel!==0){
        retour = $rootScope.current.filtreTopics.idChannel;
      }
      return retour;
    };

    /********** Fin Test Filtres **************/

    /******* Début Filtres rapide *********/

    /*
    scope.filtreByChannel = function(idChannel){
        scope.filtreBy({
            'idChannel': idChannel
        });
    };*/

    /**
     * Filtre rapide par type de tag
     * @param tagTypeProjet
     */
    /*
    scope.filtreByTagType = function(tagTypeProjet){
        scope.filtreBy({
            'evenement': {
                'tagType': tagTypeProjet
            }
        });
    };*/

    /**
     * Filtre rapide de topics avec événements
     */
    /*
    scope.filtreByEvenement = function(){
        scope.filtreBy({
            'evenement': true
        });
    };*/

    /**
     * Filtre rapide de topics avec événements urgent
     */
    /*
    scope.filtreByEvenementUrgent = function(){
        scope.filtreBy({
            'evenement': {
                'urgent': true
            }
        });
    };*/

    /**
     * Filtre rapide de topics avec événements avec sinistre
     */
    /*
    scope.filtreByEvenementWithSinistre = function(){
        scope.filtreBy({
            'evenement': {
                'sinistre': true
            }
        });
    };*/

    /**
     * Filtre rapide de topics avec événements cloturé
     */
    /*
    scope.filtreByEvenementCloture = function(){

        scope.filtreBy({
            'evenement': {
                'cloturer': true
            }
        });
    };*/

    scope.getObjFiltreTopicNonLu = function(resetTags){
      var filterParams = angular.copy($rootScope.current.filtreTopics);
      filterParams.others.nonLuEnable = true;
      if(resetTags) filterParams.tags = [];
      var retour = scope.parseFiltreToUrl(filterParams);
      var wordArray = CryptoJS.enc.Utf8.parse(angular.toJson(retour.obj));
      var base64 = CryptoJS.enc.Base64.stringify(wordArray);
      return {
        topicPage: 1,
        search: base64
      };
    };

    scope.getObjFiltreByChannel = function(idChannel){
      /*
       return {
       topicPage: 1,
       'evenement': true
       };*/
      var retour = scope.parseFiltreToUrl($rootScope.current.filtreTopics);

      if(_.isNil(idChannel)) retour.obj.idChannel  = $rootScope.current.filtreTopics.idChannel;
      else retour.obj.idChannel  = idChannel;

      var wordArray = CryptoJS.enc.Utf8.parse(angular.toJson(retour.obj));
      var base64 = CryptoJS.enc.Base64.stringify(wordArray);
      return {
        topicPage: 1,
        idChannel: idChannel,
        search: base64
      };
    };

    scope.getObjFiltreByChannelAndPageCurrent = function(){

      var retour = scope.parseFiltreToUrl($rootScope.current.filtreTopics);
      retour.obj.idChannel  = $rootScope.current.filtreTopics.idChannel;
      retour.obj.pageTopic  = $rootScope.current.pageTopic;

      var wordArray = CryptoJS.enc.Utf8.parse(angular.toJson(retour.obj));
      var base64 = CryptoJS.enc.Base64.stringify(wordArray);
      return {
        topicPage: $rootScope.current.pageTopic,
        idChannel: $rootScope.current.filtreTopics.idChannel,
        search: base64
      };
    };

    scope.getObjFiltreByEvenement = function(){
      /*
       return {
       topicPage: 1,
       'evenement': true
       };*/
      var retour = scope.parseFiltreToUrl($rootScope.current.filtreTopics);
      retour.obj.evenement  = true;

      var wordArray = CryptoJS.enc.Utf8.parse(angular.toJson(retour.obj));
      var base64 = CryptoJS.enc.Base64.stringify(wordArray);
      return {
        topicPage: 1,
        search: base64
      };
    };

    scope.getObjFiltreByEvenementUrgent = function(){
      /*
       return {
       topicPage: 1,
       'evenement': true,
       'urgent': true
       };*/
      var retour = scope.parseFiltreToUrl($rootScope.current.filtreTopics);
      retour.obj.evenement  = true;
      retour.obj.urgent  = true;

      var wordArray = CryptoJS.enc.Utf8.parse(angular.toJson(retour.obj));
      var base64 = CryptoJS.enc.Base64.stringify(wordArray);
      return {
        topicPage: 1,
        search: base64
      };
    };

    scope.getObjFiltreByEvenementWithSinistre = function(){
      var retour = scope.parseFiltreToUrl($rootScope.current.filtreTopics);
      retour.obj.evenement  = true;
      retour.obj.sinistre  = true;

      var wordArray = CryptoJS.enc.Utf8.parse(angular.toJson(retour.obj));
      var base64 = CryptoJS.enc.Base64.stringify(wordArray);
      return {
        topicPage: 1,
        search: base64
      };
    };

    scope.getObjFiltreByEvenementCloture = function(){
      var retour = scope.parseFiltreToUrl($rootScope.current.filtreTopics);
      retour.obj.evenement  = true;
      retour.obj.cloturer  = true;

      var wordArray = CryptoJS.enc.Utf8.parse(angular.toJson(retour.obj));
      var base64 = CryptoJS.enc.Base64.stringify(wordArray);
      return {
        topicPage: 1,
        search: base64
      };
    };

    scope.getObjFiltreByTags = function(idChannel, tags, resetFiltre, mergeFiltre, removeTagWithoutType){

      var currentFiltre = null;
      if(!_.isNil(resetFiltre)) currentFiltre = angular.copy(scope.getBaseFiltre());
      else currentFiltre = angular.copy($rootScope.current.filtreTopics);

      if(_.isObject(mergeFiltre)) _.merge(currentFiltre, mergeFiltre);

      currentFiltre.classe = '0';
      currentFiltre.isActive = true;

      if(!_.isArray(tags)) tags = [tags];
      if(!_.isArray(currentFiltre.tags) || resetFiltre) currentFiltre.tags = [];

      // Si on veux enlever les tags non typé
      if(currentFiltre.tags.length && removeTagWithoutType){
        var listTagDel = [];
        for(var n = 0; n < currentFiltre.tags.length; n++) {
          // Si le tag n'est pas un tag typé
          if (!currentFiltre.tags[n].hasOwnProperty('typeTag')) {
            listTagDel.push(currentFiltre.tags[n]);
          }
        }
        if(listTagDel.length) {
          for(var d = 0; d < listTagDel.length; d++) {
            currentFiltre.tags = TagService.deleteTagFromTags(currentFiltre.tags, listTagDel[d]);
          }
        }
      }

      currentFiltre.tags = _.unionWith(currentFiltre.tags, tags, _.isEqual);
      if(!_.isNil(idChannel)) currentFiltre.idChannel  = idChannel;
      if(currentFiltre.idChannel === 0) currentFiltre.idChannel = -1;

      var retour = scope.parseFiltreToUrl(currentFiltre, (_.isObject(mergeFiltre)));

      // if(_.isNil(idChannel)) retour.obj.idChannel  = currentFiltre.idChannel;
      // else retour.obj.idChannel  = idChannel;

      // if(!_.isArray(tags)) tags = [tags];
      // retour.obj.tags = angular.toJson(tags);

      var wordArray = CryptoJS.enc.Utf8.parse(angular.toJson(retour.obj));
      var base64 = CryptoJS.enc.Base64.stringify(wordArray);
      return {
        topicPage: 1,
        idChannel: idChannel,
        search: base64
      };
    };

    scope.getObjFiltreByTagWithoutEvent = function(idChannel, tags, resetFiltre, removeTagWithoutType){

      var currentFiltre = null;
      if(!_.isNil(resetFiltre) && resetFiltre) currentFiltre = angular.copy(scope.getBaseFiltre());
      else currentFiltre = angular.copy($rootScope.current.filtreTopics);

      currentFiltre.isActive = true;
      /*
      var retour = scope.parseFiltreToUrl(currentFiltre);

      if(_.isNil(idChannel)) retour.obj.idChannel  = currentFiltre.idChannel;
      else retour.obj.idChannel  = idChannel;

      if(!_.isArray(tags)) tags = [tags];
      retour.obj.tags = angular.toJson(tags);
      retour.obj.evenement  = false;
      */

      currentFiltre.classe = '1';
      if(!_.isArray(tags)) tags = [tags];
      if(!_.isArray(currentFiltre.tags)) currentFiltre.tags = [];
      if(tags.length && currentFiltre.tags.length){
        var listTagDel = [];

        for(var n = 0; n < currentFiltre.tags.length; n++){
          // Si on veux enlever les tags non typé et que  le tag n'est pas un tag typé
          if(removeTagWithoutType && !currentFiltre.tags[n].hasOwnProperty('typeTag')) {
            listTagDel.push(currentFiltre.tags[n]);
          }
          else {
            for(var t = 0; t < tags.length; t++) {
              // Si le tag à ajouté est deja dans la liste et que celui de la liste à un idTag alor son le retire
              if(tags[t].libelle === currentFiltre.tags[n].libelle && !_.isNil(currentFiltre.tags[n].idTag)) {
                listTagDel.push(currentFiltre.tags[n]);
              }
            }
          }
        }

        if(listTagDel.length) {
          for(var d = 0; d < listTagDel.length; d++) {
            currentFiltre.tags = TagService.deleteTagFromTags(currentFiltre.tags, listTagDel[d]);
          }
        }
      }

      currentFiltre.tags = _.unionWith(currentFiltre.tags, tags, _.isEqual);
      if(!_.isNil(idChannel)) currentFiltre.idChannel  = idChannel;

      var retour = scope.parseFiltreToUrl(currentFiltre);

      var wordArray = CryptoJS.enc.Utf8.parse(angular.toJson(retour.obj));
      var base64 = CryptoJS.enc.Base64.stringify(wordArray);
      return {
        topicPage: 1,
        idChannel: idChannel,
        search: base64
      };
    };

    scope.getObjFiltreByTagType = function(tagTypeProjet){
      var retour = scope.parseFiltreToUrl($rootScope.current.filtreTopics);
      retour.obj.evenement  = true;
      retour.obj.tagType  = angular.toJson(tagTypeProjet);

      var wordArray = CryptoJS.enc.Utf8.parse(angular.toJson(retour.obj));
      var base64 = CryptoJS.enc.Base64.stringify(wordArray);
      return {
        //'noRefresh': true,
        topicPage: 1,
        search: base64
      };
    };

    scope.getObjFiltreByTagTypeEnCours = function(tagTypeProjet){
      var params  = {
        'evenement': true,
        'cloturer': false,
        'tagType': angular.toJson(tagTypeProjet)
      };
      var wordArray = CryptoJS.enc.Utf8.parse(angular.toJson(params));
      var base64 = CryptoJS.enc.Base64.stringify(wordArray);
      return {
        topicPage: 1,
        search: base64
      };
    };

    scope.getObjFiltreByTagTypeEnCoursUrgent = function(tagTypeProjet){
      var params  = {
        'evenement': true,
        'cloturer': false,
        'urgent': true,
        'tagType': angular.toJson(tagTypeProjet)
      };
      var wordArray = CryptoJS.enc.Utf8.parse(angular.toJson(params));
      var base64 = CryptoJS.enc.Base64.stringify(wordArray);
      return {
        topicPage: 1,
        search: base64
      };
    };

    scope.getObjFiltreByTagTypeEnCoursWithSinistre = function(tagTypeProjet){
      var params  = {
        'evenement': true,
        'cloturer': false,
        'sinistre': true,
        'tagType': angular.toJson(tagTypeProjet)
      };
      var wordArray = CryptoJS.enc.Utf8.parse(angular.toJson(params));
      var base64 = CryptoJS.enc.Base64.stringify(wordArray);
      return {
        topicPage: 1,
        search: base64
      };
    };

    scope.getObjFiltreByRechercheElasticsearch = function(obj){
      var retour = scope.parseFiltreToUrl($rootScope.current.filtreTopics);
      retour.obj.rechercheElasticsearch  = obj;

      var wordArray = CryptoJS.enc.Utf8.parse(angular.toJson(retour.obj));
      var base64 = CryptoJS.enc.Base64.stringify(wordArray);
      return {
        //'noRefresh': true,
        topicPage: 1,
        search: base64
      };
    };

    /******* Fin Filtres rapide *********/

    /**
     * Configure l'URL par rapport au filtre
     * @param params
     * @returns {{}}
     */
    scope.parseFiltreToUrl = function(params, noChangeCurrentFiltre){

      // console.log(params);
      if(_.isNil(params) || params === false){
        params = scope.getBaseFiltre();
        if(_.isNil(noChangeCurrentFiltre) || !noChangeCurrentFiltre) $rootScope.current.filtreTopics = params;
      }

      var retour = {
        hasFiltre: false
      };
      var search = {};

      if(params.idChannel===0)      search.idChannel = null;
      else                          search.idChannel = params.idChannel;

      if(params.classe==='0')       search.evenement = null;
      else if(params.classe==='1')  search.evenement = false;
      else if(params.classe==='2')  search.evenement = true;

      // Si cloturé et non cloturé
      if(params.withCloture && params.withoutCloture){
        params.cloturer = 0;
        search.cloturer = true;
        //$rootScope.current.filtreTopics.others.clotureEnable = false;
      }
      // Si seulement cloturé
      else if(params.withCloture && !params.withoutCloture){  params.cloturer = 1; search.cloturer = true;}
      // Si seulement non cloturé
      else if(!params.withCloture && params.withoutCloture){  params.cloturer = 2;}



      /*
      if(params.others.clotureEnable === true) {
        params.cloturer = 0;
        //params.withCloture = true;
        //params.withoutCloture = true;
        search.cloturer = true;
      }
      else if(params.others.clotureEnable === false) {
        //if(params.isActive) {
          // Si cloturé et non cloturé
          if(params.withCloture && params.withoutCloture){        params.cloturer = 0;}
          // Si seulement cloturé
          else if(params.withCloture && !params.withoutCloture){  params.cloturer = 1;}
          // Si seulement non cloturé
          else if(!params.withCloture && params.withoutCloture){  params.cloturer = 2;}
        // }
        // else params.cloturer = 2;
      }*/


      if(params.cloturer===0)       search.cloturer = null;
      else if(params.cloturer===1)  search.cloturer = true;
      else if(params.cloturer===2)  search.cloturer = false;

      if(params.urgent===0)         search.urgent = null;
      else if(params.urgent===1)    search.urgent = true;
      else if(params.urgent===2)    search.urgent = false;

      if(params.sinistre===0)       search.sinistre = null;
      else if(params.sinistre===1)  search.sinistre = true;
      else if(params.sinistre===2)  search.sinistre = false;

      if(params.others.nonLuEnable === true) {
        if(_.isNil(noChangeCurrentFiltre) || !noChangeCurrentFiltre)$rootScope.current.filtreTopics.nonlu = params.nonlu = 1;
        search.nonlu = true;
      }
      else if(params.others.nonLuEnable === false) {
        if(_.isNil(noChangeCurrentFiltre) || !noChangeCurrentFiltre) $rootScope.current.filtreTopics.nonlu = params.nonlu = 0;
        search.nonlu = false;
      }

      if(params.nonlu===0)          search.nonlu = null;
      else if(params.nonlu===1)     search.nonlu = true;
      else if(params.nonlu===2)     search.nonlu = false;

      if(params.tagType===false)                search.tagType = null;
      else if(angular.isObject(params.tagType)) search.tagType = angular.toJson(params.tagType);

      if(params.tags===false)                   search.tags = null;
      else if(angular.isArray(params.tags))     search.tags = angular.toJson(params.tags);

      if(params.listeDestinataireEvenement===false)             search.listeProxyDestinataireEvenement = null;
      else if(_.isArray(params.listeDestinataireEvenement))     search.listeProxyDestinataireEvenement = angular.toJson(params.listeDestinataireEvenement);

      if(params.listeFournisseurMail===false)                 search.listeFournisseurMail = null;
      else if(angular.isArray(params.listeFournisseurMail))   search.listeFournisseurMail = angular.toJson(params.listeFournisseurMail);

      if(params.listeFournisseurPersoICS===false)               search.listeFournisseurPersoICS = null;
      else if(angular.isArray(params.listeFournisseurPersoICS)) search.listeFournisseurPersoICS = angular.toJson(params.listeFournisseurPersoICS);

      if(params.destinataires===false)          search.destinataires = null;
      else if(_.isArray(params.destinataires))  search.destinataires = angular.toJson(params.destinataires);

      if(params.intervenants===false)          search.intervenants = null;
      else if(_.isArray(params.intervenants))  search.intervenants = angular.toJson(params.intervenants);

      if(angular.isObject(params.filtreDashboard)) search.filtreDashboard = params.filtreDashboard;

      //if(params.rechercheElasticsearch===false){  search.rechercheElasticsearch = null;}
      //else if(angular.isObject(params.rechercheElasticsearch)){  search.rechercheElasticsearch = angular.toJson(params.rechercheElasticsearch);}

      if(_.isObject(params.rechercheElasticsearch)) search.rechercheElasticsearch = params.rechercheElasticsearch;

      if(!_.isNil(params.idPortefeuille))     search.idPortefeuille = params.idPortefeuille;
      if(!_.isNil(params.dateCreation))       search.dateCreation = params.dateCreation;
      //if(!_.isNil(params.dateCreationDebut))  search.dateCreationDebut = params.dateCreationDebut;
      //if(!_.isNil(params.dateCreationFin))    search.dateCreationFin = params.dateCreationFin;
      if(!_.isNil(params.dateClotureDebut))   search.dateClotureDebut = params.dateClotureDebut;
      if(!_.isNil(params.dateClotureFin))     search.dateClotureFin = params.dateClotureFin;
      if(!_.isNil(params.stillOpenAt))        search.stillOpenAt = params.stillOpenAt;

      search.clotureEnable = angular.copy(params.others.clotureEnable);

      var base64 = null;
      if(search.idChannel !== null ||
        search.evenement !== null ||
        search.cloturer !== null ||
        search.urgent !== null ||
        search.sinistre !== null ||
        search.tagType !== null ||
        search.tags !== null ||
        search.listeProxyDestinataireEvenement !== null ||
        search.destinataires !== null ||
        search.intervenants !== null ||
        search.listeFournisseurMail !== null ||
        search.listeFournisseurPersoICS !== null ||
        search.rechercheElasticsearch !== null ||
        search.idPortefeuille !== null ||
        search.filtreDashboard !== null){

        retour.hasFiltre = true;
        var wordArray = CryptoJS.enc.Utf8.parse(angular.toJson(search));
        base64 = CryptoJS.enc.Base64.stringify(wordArray);
      }

      retour.obj = search;
      retour.objSearch = {
        search: base64
      };
      return retour;
    };

    scope.getObjFiltre = function(retour, idChannel, cloture){
      var obj = angular.copy(retour.obj);

      if(_.isNil(idChannel)) obj.idChannel  = $rootScope.current.filtreTopics.idChannel;
      else obj.idChannel  = idChannel;

      if(_.isNil(cloture)) obj.cloturer  = null;
      else{
        if(cloture) obj.cloturer  = true;
        else obj.cloturer  = false;
      }

      obj.uuid = uuid.new();

      var wordArray = CryptoJS.enc.Utf8.parse(angular.toJson(obj));
      var base64 = CryptoJS.enc.Base64.stringify(wordArray);
      return angular.copy({
        topicPage: 1,
        idChannel: obj.idChannel,
        search: base64
      });
    };

    /**
     * Configure le filtre par rapport au params de l'URL
     * @param params
     */
    scope.parseFiltreFromUrl = function(stateParams,norefresh){

      var params = stateParams;

      //console.dir(params);

      var tmpIdChannel = null;

      // Si il y a directement l'idChannel dans l'objet des paramètres du $state on le change dans la variable de filtre
      if(params.hasOwnProperty('idChannel')){
        if(params.idChannel===-1 || params.idChannel===0 || params.idChannel === null){
          tmpIdChannel = params.idChannel;
          /*
          if(params.idChannel===null){
              tmpIdChannel = 0;
          }*/
        }
      }
      //console.log('tmpIdChannel',tmpIdChannel);


      // Si il y a l'attribut search dans les params
      if(stateParams.hasOwnProperty('search')){
        // Si il est défini
        if(angular.isDefined(stateParams.search)){
          // Decode la recherche
          var parsedWordArray = CryptoJS.enc.Base64.parse(stateParams.search);
          var stringSearch = parsedWordArray.toString(CryptoJS.enc.Utf8);

          // Transforme en object
          params = angular.fromJson(stringSearch);
        }
      }

      // console.log('2',params);
      /*
       if(stateParams.hasOwnProperty('noRefresh')){
       norefresh = stateParams.noRefresh;
       }*/

      var retour = false;

      if(params.hasOwnProperty('idChannel') || params.hasOwnProperty('evenement')){

        $rootScope.current.filtreTopics = scope.getBaseFiltre();
        var objFiltre = {};

        if(tmpIdChannel!==null){
          objFiltre.idChannel = tmpIdChannel;
          $rootScope.current.filtreTopics.idChannel = tmpIdChannel;
        }
        else if(params.hasOwnProperty('idChannel')){
          if(params.idChannel){
            objFiltre.idChannel = params.idChannel;
          }
        }

        //if(params.evenement && !params.noRefresh){
        if(params.hasOwnProperty('evenement')){
          //console.log('parseFiltreFromUrl');
          //console.log($state);
          if(params.evenement===true){
            objFiltre.evenement = true;

            if(!_.isObject(objFiltre.evenement)){objFiltre.evenement = {};}

            /*
            if(params.tagType){
              if(!angular.isObject(objFiltre.evenement)){objFiltre.evenement = {};}
              objFiltre.evenement.tagType = angular.fromJson(params.tagType);
            }

            if(params.tags.length){
              if(!angular.isObject(objFiltre.evenement)){objFiltre.evenement = {};}
              objFiltre.evenement.tags = angular.fromJson(params.tags);
            }

            if(params.listeFournisseurMail.length){
              if(!angular.isObject(objFiltre.evenement)){objFiltre.evenement = {};}
              objFiltre.evenement.listeFournisseurMail = angular.fromJson(params.listeFournisseurMail);
            }

            if(params.listeFournisseurPersoICS.length){
              if(!angular.isObject(objFiltre.evenement)){objFiltre.evenement = {};}
              objFiltre.evenement.listeFournisseurPersoICS = angular.fromJson(params.listeFournisseurPersoICS);
            }
            */

            if(params.urgent===true){        objFiltre.evenement.urgent = true;}
            else if(params.urgent===false){  objFiltre.evenement.urgent = false;}

            if(params.sinistre===true){        objFiltre.evenement.sinistre = true;}
            else if(params.sinistre===false){  objFiltre.evenement.sinistre = false;}

          }
          else if(params.evenement===false) objFiltre.evenement = false;
        }


        if(params.cloturer===true){objFiltre.cloturer = true;}
        else if(params.cloturer===false){  objFiltre.cloturer = false;}
        else if(params.cloturer===null){
          if(params.clotureEnable === false) objFiltre.cloturer = false;
          else objFiltre.cloturer = null;
        }



        //if(params.hasOwnProperty('tags')) objFiltre.tags = angular.fromJson(params.tags);
        if(params.tagType) objFiltre.tagType = angular.fromJson(params.tagType);
        if(!_.isNil(params.tags) && params.tags.length) objFiltre.tags = angular.fromJson(params.tags);
        if(!_.isNil(params.listeProxyDestinataireEvenement) && params.listeProxyDestinataireEvenement.length) objFiltre.listeDestinataireEvenement = angular.fromJson(params.listeProxyDestinataireEvenement);
        if(!_.isNil(params.destinataires) && params.destinataires.length) objFiltre.destinataires = angular.fromJson(params.destinataires);
        if(!_.isNil(params.intervenants) && params.intervenants.length) objFiltre.intervenants = angular.fromJson(params.intervenants);
        if(!_.isNil(params.listeFournisseurMail) && params.listeFournisseurMail.length) objFiltre.listeFournisseurMail = angular.fromJson(params.listeFournisseurMail);
        if(!_.isNil(params.listeFournisseurPersoICS) && params.listeFournisseurPersoICS.length) objFiltre.listeFournisseurPersoICS = angular.fromJson(params.listeFournisseurPersoICS);
        if(_.isObject(params.filtreDashboard)) objFiltre.filtreDashboard = params.filtreDashboard;
        if(params.idPortefeuille) objFiltre.idPortefeuille = params.idPortefeuille;
        if(!_.isNil(params.dateCreation))  objFiltre.dateCreation = params.dateCreation;
        //if(!_.isNil(params.dateCreationDebut))  objFiltre.dateCreationDebut = params.dateCreationDebut;
        //if(!_.isNil(params.dateCreationFin))    objFiltre.dateCreationFin = params.dateCreationFin;
        if(!_.isNil(params.dateClotureDebut))   objFiltre.dateClotureDebut = params.dateClotureDebut;
        if(!_.isNil(params.dateClotureFin))     objFiltre.dateClotureFin = params.dateClotureFin;
        if(!_.isNil(params.stillOpenAt))     objFiltre.stillOpenAt = params.stillOpenAt;

        if(_.isNil(params.cloturer)) $rootScope.current.filtreTopics.others.clotureEnable = angular.copy(params.clotureEnable);
        else{
          if(params.cloturer === false) $rootScope.current.filtreTopics.others.clotureEnable = false;
          else                        $rootScope.current.filtreTopics.others.clotureEnable = true;
        }

        $rootScope.current.filtreTopics.others.nonLuEnable = params.nonlu;

        if(!_.isNil(params.rechercheElasticsearch)) objFiltre.rechercheElasticsearch = $rootScope.current.filtreTopics.rechercheElasticsearch = params.rechercheElasticsearch;
        if(!_.isNil(params.topicPage)) $rootScope.current.pageTopic = params.topicPage;
        scope.filtreBy(objFiltre,norefresh);
        retour = (Object.keys(objFiltre).length===0) ? false : objFiltre;
      }

      if(_.isObject($rootScope.current.filtreTopics)) {
        // Si pas de filtre
        if(!retour) $rootScope.current.filtreTopics.idChannel = 0;

        // Si cet idChannel est égale à 0
        if ($rootScope.current.filtreTopics.idChannel === 0) {
          // Recupère un éventuel idChannel Accueil
          var channelAccueil = $rootScope.current.userGroupe.getChannelAccueil();
          if (channelAccueil && channelAccueil.hasGroupeChannel()) {
            $rootScope.current.filtreTopics.idChannel = channelAccueil.idChannel;
          }
        }
        // Si idChannel égale à -1 (on demande de tout voir)
        else if ($rootScope.current.filtreTopics.idChannel === -1) $rootScope.current.filtreTopics.idChannel = 0;
      }

      return retour;
    };

    /**
     * Permet de paramètrer l'objet de filtre du formulaire avec un objet plus simple
     * @param obj
     */
    scope.parseFiltreCollabToForm = function(obj){
      var classe = '0',
        withEvent = false,
        cloturer = false, withCloture = true, withoutCloture = true,
        urgent = false, withUrgent = true, withoutUrgent = true,
        sinistre = false, withSinistre = true, withoutSinistre = true;

      if(!$rootScope.current.filtreTopics || obj.reset) $rootScope.current.filtreTopics = scope.getBaseFiltre();

      if(!_.isNil(obj.idChannel)) $rootScope.current.filtreTopics.idChannel = obj.idChannel;

      // Evenement
      if(obj.hasOwnProperty('evenement')){

        if(_.isObject(obj.evenement)){

          /*
          if(obj.evenement.hasOwnProperty('tagType')){
            if(obj.evenement.tagType){
              $rootScope.current.filtreTopics.tagType = obj.evenement.tagType;
            }
          }

          if(obj.evenement.hasOwnProperty('tags')){
            if(obj.evenement.tags){
              $rootScope.current.filtreTopics.tags = obj.evenement.tags;
            }
          }

          if(obj.evenement.hasOwnProperty('listeFournisseurMail')){
            if(obj.evenement.listeFournisseurMail){
              $rootScope.current.filtreTopics.listeFournisseurMail = obj.evenement.listeFournisseurMail;
            }
          }

          if(obj.evenement.hasOwnProperty('listeFournisseurPersoICS')){
            if(obj.evenement.listeFournisseurPersoICS){
              $rootScope.current.filtreTopics.listeFournisseurPersoICS = obj.evenement.listeFournisseurPersoICS;
            }
          }*/

          // Normalement plus utilié mais je le garde pour les anciens filtre en localstorage
          if(obj.evenement.hasOwnProperty('cloturer')){

            if(obj.evenement.cloturer && obj.evenement.cloturer!==null){
              cloturer = 1;
              withCloture = true;
              withoutCloture = false;
            }
            else if(!obj.evenement.cloturer && obj.evenement.cloturer!==null){
              cloturer = 2;
              withCloture = false;
              withoutCloture = true;
            }else{
              cloturer = 0;
              withCloture = true;
              withoutCloture = true;
            }
            $rootScope.current.filtreTopics.cloturer = cloturer;
            $rootScope.current.filtreTopics.withCloture = withCloture;
            $rootScope.current.filtreTopics.withoutCloture = withoutCloture;
          }

          if(obj.evenement.hasOwnProperty('urgent')){
            if(obj.evenement.urgent && obj.evenement.urgent!==null){
              urgent = 1;
              withUrgent = true;
              withoutUrgent = false;
            }
            else if(!obj.evenement.urgent && obj.evenement.urgent!==null){
              urgent = 2;
              withUrgent = false;
              withoutUrgent = true;
            }
            else{
              urgent = 0;
              withUrgent = true;
              withoutUrgent = true;
            }
            $rootScope.current.filtreTopics.urgent = urgent;
            $rootScope.current.filtreTopics.withUrgent = withUrgent;
            $rootScope.current.filtreTopics.withoutUrgent = withoutUrgent;
          }

          if(obj.evenement.hasOwnProperty('sinistre')){
            if(obj.evenement.sinistre && obj.evenement.sinistre!==null){
              sinistre = 1;
              withSinistre = true;
              withoutSinistre = false;
            }
            else if(!obj.evenement.sinistre && obj.evenement.sinistre!==null){
              sinistre = 2;
              withSinistre = false;
              withoutSinistre = true;
            }
            else{
              sinistre = 0;
              withSinistre = true;
              withoutSinistre = true;
            }
            $rootScope.current.filtreTopics.sinistre = sinistre;
            $rootScope.current.filtreTopics.withSinistre = withSinistre;
            $rootScope.current.filtreTopics.withoutSinistre = withoutSinistre;
          }
          withEvent = true;
          classe = '2';
        }else{
          if(obj.evenement){
            withEvent = true;
            classe = '2';
          }
          else{
            withEvent = false;
            classe = '1';
          }
        }
      }

      if(obj.hasOwnProperty('cloturer')){

        if(obj.cloturer && obj.cloturer!==null){
          cloturer = 1;
          withCloture = true;
          withoutCloture = false;
        }
        else if(!obj.cloturer && obj.cloturer!==null){
          cloturer = 2;
          withCloture = false;
          withoutCloture = true;
        }
        else{
          cloturer = 0;
          withCloture = true;
          withoutCloture = true;
        }
        $rootScope.current.filtreTopics.cloturer = cloturer;
        $rootScope.current.filtreTopics.withCloture = withCloture;
        $rootScope.current.filtreTopics.withoutCloture = withoutCloture;
      }

      if(!_.isEmpty(obj.tags))            $rootScope.current.filtreTopics.tags = obj.tags;
      if(!_.isEmpty(obj.listeDestinataireEvenement))            $rootScope.current.filtreTopics.listeDestinataireEvenement = obj.listeDestinataireEvenement;
      if(!_.isEmpty(obj.tagType))         $rootScope.current.filtreTopics.tagType = obj.tagType;
      if(!_.isEmpty(obj.destinataires))   $rootScope.current.filtreTopics.destinataires = obj.destinataires;
      if(!_.isNil(obj.dateCreation))      $rootScope.current.filtreTopics.dateCreation = obj.dateCreation;
      //if(!_.isNil(obj.dateCreationDebut)) $rootScope.current.filtreTopics.dateCreationDebut = obj.dateCreationDebut;
      //if(!_.isNil(obj.dateCreationFin))   $rootScope.current.filtreTopics.dateCreationFin = obj.dateCreationFin;
      if(!_.isNil(obj.dateClotureDebut))  $rootScope.current.filtreTopics.dateClotureDebut = obj.dateClotureDebut;
      if(!_.isNil(obj.dateClotureFin))    $rootScope.current.filtreTopics.dateClotureFin = obj.dateClotureFin;
      if(!_.isNil(obj.stillOpenAt))    $rootScope.current.filtreTopics.stillOpenAt = obj.stillOpenAt;

      if(!_.isEmpty(obj.listeFournisseurMail)){
        $rootScope.current.filtreTopics.listeFournisseurMail = obj.listeFournisseurMail;
        withEvent = true;
        classe = '2';
      }

      if(!_.isEmpty(obj.listeFournisseurPersoICS)){
        $rootScope.current.filtreTopics.listeFournisseurPersoICS = obj.listeFournisseurPersoICS;
        withEvent = true;
        classe = '2';
      }

      $rootScope.current.filtreTopics.withEvent = withEvent;

      // 0:Tous, 1: Sans event, 2: Avec event
      $rootScope.current.filtreTopics.classe = classe;
    };

    /**
     * Permet de faire une recherche par rapport à un objet simple
     * @param obj
     * @param norefresh
     */
    scope.filtreBy = function(obj,norefresh){

      // Crée l'objet comprehensible par le formulaire à partir de l'objet
      // plus simple et lance la recherche
      scope.parseFiltreCollabToForm(obj);


      // Lance la recherche
      scope.filtrerTopics(norefresh);

    };

    /**
     * Sauvegarde le filtre dans les configs Collabore du localstorage
     * @param objFiltre
     */
    scope.saveFiltre = function(objFiltre){
      ConfigService.setPreferencesFiltreTopics(objFiltre);
    };

    /**
     * Clean le filtre
     */
    scope.cleanFiltre = function(){
      $rootScope.current.pageTopic = 1;
      var idChannel = $rootScope.current.filtreTopics.idChannel;
      var rechercheElasticsearch = $rootScope.current.filtreTopics.rechercheElasticsearch;
      $rootScope.current.filtreTopics = false;
      scope.resetFiltre();
      if(UtilsService.isICS()) {
        var chan = UtilsService.getCurrentUser().getChannelAccueil();
        idChannel = (chan) ? chan.getIdChannel() : null;
      }
      if(idChannel) $rootScope.current.filtreTopics.idChannel = idChannel;

      if(rechercheElasticsearch) {
        rechercheElasticsearch.isActive = false;
        $rootScope.current.filtreTopics.rechercheElasticsearch = rechercheElasticsearch;
      }
    };

    scope.resetFiltre = function(){
      // Reset le filtre
      $rootScope.current.filtreTopics = scope.getBaseFiltre();
      //console.log($rootScope.current.filtreTopics);
    };
  }

})();
