(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.ReceiptVAT
   * @description
   * # ReceiptVAT
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('ReceiptVAT', ReceiptVATModel);

  /** @ngInject */
  function ReceiptVATModel(Main) {

    function ReceiptVAT(data) {
      Main.apply(this, arguments);
      this.hydrate(data);
    }

    ReceiptVAT.prototype = {

      model: 'ReceiptVAT',
      isReceiptVAT: true,

      // amount
      getAmount: function(){
        return this.amount;
      },
      setAmount: function(val){
        this.amount = val;
      },

      // amountExclVat
      getAmountExclVat: function(){
        return this.amountExclVat;
      },
      setAmountExclVat: function(val){
        this.amountExclVat = val;
      },

      // amountInclExclVatEstimated
      getAmountInclExclVatEstimated: function(){
        return this.amountInclExclVatEstimated;
      },
      setAmountInclExclVatEstimated: function(val){
        this.amountInclExclVatEstimated = val;
      },

      // amountInclVat
      getAmountInclVat: function(){
        return this.amountInclVat;
      },
      setAmountInclVat: function(val){
        this.amountInclVat = val;
      },

      // code
      getCode: function(){
        return this.code;
      },
      setCode: function(val){
        this.code = val;
      },

      // percentage
      getPercentage: function(){
        return this.percentage;
      },
      setPercentage: function(val){
        this.percentage = val;
      },
    };

    angular.extend(ReceiptVAT.prototype, Main.prototype);

    return ReceiptVAT;
  }
})();
