'use strict';

/**
 * @desc TagAnnuaire
 * @example <tag-annuaire></tag-annuaire>
 */

angular
  .module('collaboreApp')
  .component('tagsCollab',{
    templateUrl: 'app/tags/tags-collab.component.html',
    bindings: {
      libelle: '@',				// <String> Libelle à afficher
      tags: '<',					// <Array> Liste des tags
      tagCollabHideLibelle: '<',	// <Boolean> Permet de cacher le libelle pour avoir un tag plus petit
      typeTag: '@',				// <String> Type de tag
      tagsFromOutside: '@',    // <String> Tag venant de l'exterieur à afficher (Toctoctoc ou Extranet par exemple)
      //itemContenu: '<',			// <Object> Contenu
      idContenu: '<',				// <Integer> Id Contenu
      idTopic: '<',				// <Integer> Id Topic
      enableTooltip: '<',			// <Boolean> Permet d'afficher un tooltip
      enableIcon: '<',			// <Boolean> Permet d'afficher une icone
      classBadge:'@',				// <String> Class à mettre sur le badge
      limit: '@',					// <String> Nombre de tags à afficher
      disabledBtnFilter: '<',

      allowDeleteTag: '<',		// <Boolean> Affiche/Cache le bouton delete des tags
      onDelete: '&',				// <Function> Fonction à executer lors du clique sur le bouton delete du tag
      onClick: '&'				// <Function> Fonction à exectuer lors du clique sur le libelle tu tag
      //onClickDashboard: '&'		// <Function> Fonction à exectuer lors du clique sur le bouton dashboard du tag
    },
    controllerAs: 'tagscollabctrl',
    controller: TagsCollabCtrl
  });

/*@ngInject*/
function TagsCollabCtrl(COLLAB_CONF,$rootScope,$scope,$window,$state,sweet,TagService,FiltreService,NotificationsToastServices,UtilsService){
  var ctrl = this;

  /*
  console.log('-------------------------------------------');
  console.log('ctrl.typeTag',ctrl.typeTag);
  console.log('ctrl.allowDeleteTag',ctrl.allowDeleteTag);
  console.log('ctrl.limit',ctrl.limit);
  console.log('-------------------------------------------');
  */

  ctrl.getClassBadge 				= getClassBadge;
  ctrl.getIconTag 				= getIconTag;
  ctrl.displayLibelle 			= displayLibelle;			// Permet d'afficher le libelle comme il faut
  ctrl.fctAllowDelete 			= fctAllowDelete;			// Permet de savoir si on peux delete le tag
  ctrl.hasLimit 					= hasLimit;					// Permet de savoir si une limit est défini
  ctrl.hasTypeTag 				= hasTypeTag;				// Permet de savoir si un typeTag est défini
  ctrl.getListAutreTags 			= getListAutreTags;
  ctrl.onClickTag 				= onClickTag;				// Click sur le libelle si il y a une fonction à executer
  ctrl.onClickFiltreByTypeTag 	= onClickFiltreByTypeTag;	// Filtre les topic sur un type tag
  ctrl.onClickDashboard 			= onClickDashboard;			// Lors du click sur le bouton dashboard
  ctrl.onDeleteTag 				= onDeleteTag;				// Delete un tag
  ctrl.parseTagForSearch 			= parseTagForSearch;		// Permet de convertir le tag en recherche

  ctrl.libelleTagCliquable = false;

  ctrl.$onInit = function(){
    // Si l'attribut "onClickTag" est défini, c'est à dire qu'il y a une fonction à executer lors du clique sur le libelle du tag
    if(angular.isDefined(ctrl.onClick)){
      ctrl.libelleTagCliquable = true;
    }

    var listLibelleTag = [];

    if(ctrl.tagsFromOutside) {
      ctrl.typeTag = 'Normal';
      listLibelleTag = COLLAB_CONF.TAGS_FROM_OUTSIDE;
    }

    if(ctrl.typeTag!=='NoTag') {
      if(_.isArray(ctrl.tags) && ctrl.tags.length) {
        for(var i = 0; i < ctrl.tags.length ; i++){
          if(ctrl.tags[i].model !== 'Tag') ctrl.tags[i] = TagService.newTag(ctrl.tags[i]);
        }
      }
      else if(_.isObject(ctrl.tags) && !_.isArray(ctrl.tags)) {
        if(ctrl.tags.model !== 'Tag') ctrl.tags = [TagService.newTag(ctrl.tags)];
      }
    }

    //console.log('tags',ctrl.tags);
    if(ctrl.typeTag === 'Projet') ctrl.allowFiltre = true;
    if(_.isArray(ctrl.tags) && ctrl.tags.length>=1 && ctrl.typeTag){

      if(ctrl.typeTag!=='Normal' && ctrl.typeTag!=='NoTag') {
        var tag = TagService.getTagForType(ctrl.tags,ctrl.typeTag);

        if(tag) ctrl.tags = [tag];
        else    ctrl.tags = [];
      }
      else if(ctrl.typeTag==='Normal' && listLibelleTag.length){
        ctrl.tags = ctrl.tags.reduce(function(list, tag){
          if(listLibelleTag.indexOf(_.trimStart(tag.libelle, '#')) !== -1) list.push(tag);
          return list;
        }, []);
      }



      /*
      for(var i = 0; i < ctrl.tags.length ; i++){
        console.log(ctrl.tags[i]);
        if(ctrl.tags[i].hasOwnProperty('typeTag')){
          if(ctrl.typeTag!==ctrl.tags[i].typeTag.libelle){
            ctrl.tags.splice(i,1);
          }
        }
      }*/
    }
  };

  function hasTypeTag(){
    return (ctrl.typeTag!=='' && angular.isDefined(ctrl.typeTag));
  }

  function hasLimit(){
    return (ctrl.limit!==0 && angular.isDefined(ctrl.limit) && ctrl.limit!=='');
  }

  function fctAllowDelete(tag){
    var retour = (ctrl.allowDeleteTag || angular.isUndefined(ctrl.allowDeleteTag));
    if(retour) {
      // Si c'est ouvert depuis visicop et que le tag est un tag typé immeuble alors on ne le supprimme pas
      if(tag.hasOwnProperty('typeTag') && tag.typeTag.libelle === COLLAB_CONF.TAG_TYPE_IMMEUBLE && $rootScope.openInVisicop) retour = false;
    }

    return retour;
  }

  function getIconTag(tag){
    var libelle = '';
    if(tag.hasOwnProperty('typeTag')){
      libelle = tag.typeTag.libelle;
    }
    return TagService.getIconForTagType(libelle,ctrl.limit);
  }

  function displayLibelle(tag){
    if(tag.hasOwnProperty('libelle')) {
      if(UtilsService.startsWith(tag.libelle, '#')) {
        return angular.copy(tag.libelle.slice(1));
      }
      else if(UtilsService.endsWith(tag.libelle, '-.+')) {
        return angular.copy(tag.libelle.slice(0, -3));
      }
      return tag.libelle;
    }
    else if(tag.hasOwnProperty('type') && tag.type === 'F') {
      return '['+tag.codemetier+'] ' + tag.numero + ' | NoPerso: ' + tag.noperso;
    }
    else if(ctrl.typeTag === 'NoTag') {
      return tag;
    }
    if(_.isObject(tag)){
      if(_.isObject(tag.typeTag) && !_.isNil(tag.typeTag.libelle)) return tag.typeTag.libelle;
    }
    return '?';
  }

  function getClassBadge(tag){
    return TagService.getClassBadge(tag, ctrl.typeTag, ctrl.classBadge, ctrl.tagCollabHideLibelle);

    /*
		var type = ctrl.typeTag;
		if(!hasTypeTag()){
			if(tag.hasOwnProperty('typeTag')){
				type = tag.typeTag.libelle;
			}else{
				type = COLLAB_CONF.TAG_NORMAL;
			}
		}
		var badgeBorderedClass = 'badge-bordered';
		var badgeClass = '';
		switch(type){
			case COLLAB_CONF.TAG_TYPE_PROJET:
				badgeClass = 'badge-rouge-collabore ';
				break;
			//case COLLAB_CONF.TAG_TYPE_ORIGINE:
			//	badgeClass = 'alert-noir-collabore ';
			//	break;
			case COLLAB_CONF.TAG_NORMAL:
				badgeClass = 'badge-bleu-collabore ';
				break;
      case COLLAB_CONF.TAG_TYPE_STATUT:
        if(tag.isTagStatutTermine()) badgeClass = 'badge-success ';
        else if(tag.isTagStatutEnAttente()) badgeClass = 'badge-orange-collabore ';
        else if(tag.isTagStatutProbleme()) badgeClass = 'badge-danger ';
        else badgeClass = 'badge-default-collabore ';
        badgeBorderedClass = '';
        break;
			//case 'Immeuble':
			//	badgeClass = 'alert-orange-collabore ';
			//	break;
			default:
				if(UtilsService.contains(COLLAB_CONF.ARRAY_TAGS_ORIGINE, type)){
					badgeClass = 'badge-noir-collabore ';
				}
				else {
					badgeClass = 'badge-default-collabore ';
				}
		}

		if(ctrl.tagCollabHideLibelle){badgeClass += 'badge-tag-xs ';}

		if(ctrl.classBadge){
			badgeClass = ctrl.classBadge;
		}

		return badgeClass+badgeBorderedClass;
		*/
  }
  /**
   * Lors du clique sur un libelle de tag
   * @param tag
   */
  function onClickTag(tag){
    if(ctrl.libelleTagCliquable && ctrl.onClick){
      ctrl.onClick({'objUpdate':{'clickTag': tag}});
    }
  }

  function openWindowFiltreByTypeTag(tag){
    var parametres = FiltreService.getObjFiltreByTagType(tag);
    var url = $state.href('topics.tous', parametres);
    $window.open(url,'_blank');
  }

  /**
   * Recupère la liste des destinataire restant qui sont caché
   * @returns {String} Liste des destinataires
   */
  function getListAutreTags(){
    var string = '';
    if(angular.isArray(ctrl.tags) && ctrl.hasLimit()){
      for(var i = ctrl.limit; i < ctrl.tags.length; i++){
        string +='<span class="badge badge-bleu-collabore">'+ctrl.tags[i].libelle+'</span> ';
      }
    }
    return string;
  }

  /**
   * Lors du clique sur le bouton filtre d'un type tag
   * @param tag
   */
  function onClickFiltreByTypeTag(tag){

    if (angular.isObject(tag)) {

      // Si le tag à déjà un idTag alors génération du lien de la recherche et on l'execute dans un nouvel onglet
      if (tag.hasOwnProperty('idTag')) {
        openWindowFiltreByTypeTag(tag);
      }else{
        ctrl.filtreLoading = true;
        var libelleCurrentTag = tag.libelle;
        var exist = false;

        var typeTag = ctrl.typeTag;
        if(!ctrl.typeTag){
          if(tag.hasOwnProperty('typeTag')){
            typeTag = tag.typeTag.libelle;
          }
        }

        // Recupère les tag suggéré pour ce tag
        TagService.getWithType(typeTag,libelleCurrentTag,0).then(function(tags){
          // Si il y a des réponses
          if(tags.length){
            // Pour chaque réponse
            for(var j = 0 ; j < tags.length ; j++){
              var tagSuggere = tags[j];
              // Si le libelle du tag correspond exactement au tag suggéré
              if(tagSuggere.getLibelle().toLowerCase() === libelleCurrentTag.toLowerCase()){
                exist = tagSuggere;
                break;
              }
            }
          }
          // Si le tag exist alors on filtre
          if(exist){
            openWindowFiltreByTypeTag(exist);
            // Sinon on affiche un message
          }else{
            NotificationsToastServices.error('Aucun résultats !',libelleCurrentTag);
          }
        }).finally(function(){
          ctrl.filtreLoading = false;
        });
      }
    }
  }

  function onClickDashboard() {
    $scope.$emit('closeModalAnnuaire');
  }

  /**
   * Permet de delete un tag. Si l'itemContenu à un idContenu alors il doit le supprimer de la BDD par le biais de la servlet
   * @param tag <Tag>
   */
  function onDeleteTag(tag){

    // Si c'est un contenu existant
    if(ctrl.idContenu){


      ctrl.loading = true;

      TagService.deleteTagFromContenu(ctrl.idContenu,tag,ctrl.typeTag).then(function(){

        if(ctrl.onDelete){
          ctrl.onDelete({'objUpdate':{'deleteTagFromContenu':tag}});
        }

      },function(err){
        sweet.show({
          title: 'Erreur !',
          text: err,
          type: 'error',
          showConfirmButton: true
        });
      }).finally(function(){
        ctrl.loading = false;
      });


    }
    // Si Topic
    else if(ctrl.idTopic) {
      sweet.show({
        title: 'Supprimer le tag ?',
        text: '<h4>Voulez-vous vraiment supprimer ce tag ?</h4><div class="alert alert-warning"><i class="fa fa-exclamation-triangle"></i> Ce tag sera supprimé de tous les contenus de ce topic</div>',
        type: 'warning',
        html: true,
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Oui tous supprimer !',
        closeOnConfirm: false

      },function() {
        // Loading
        sweet.show({
          title: '',
          html: true,
          text: COLLAB_CONF.LOADING
        });

        TagService.deleteTagFromTopic(ctrl.idTopic,tag,ctrl.typeTag).then(function(){

          if(ctrl.onDelete){
            ctrl.onDelete({'objUpdate':{'deleteTagFromTopic':tag}});
          }

          sweet.show({
            title: 'Tag supprimé !',
            type: 'success',
            timer: 1000,
            showConfirmButton: false
          });

        },function(err){
          sweet.show({
            title: 'Erreur !',
            text: err,
            type: 'error',
            showConfirmButton: true
          });
        }).finally(function(){
          ctrl.loading = false;
        });
      });
    }
    else{
      if(ctrl.onDelete){
        ctrl.onDelete({'objUpdate':{'deleteTagFromContenu':tag}});
      }
    }

  }

  function parseTagForSearch(tag){
    var objCrypt = CryptoJS.enc.Utf8.parse(angular.toJson({filtreByTagType: tag}));
    //var objCrypt = CryptoJS.AES.encrypt(angular.toJson(tag),COLLAB_CONF.KEY_CRYPT_SEARCH);

    //var string = objCrypt.toString();
    //console.log(string);
    return {
      search:CryptoJS.enc.Base64.stringify(objCrypt)
    };
    /*
    var objCrypt = CryptoJS.AES.encrypt(angular.toJson(tag),COLLAB_CONF.KEY_CRYPT_SEARCH);
    console.log(objCrypt.toString());*/
    //return {search:objCrypt.toString()};
  }

}
