(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .component('cheminGedTopic', {
      templateUrl: 'app/topics/send-ged/v1/chemin-ged-topic.component.html',
      controller: CheminGedTopicController,
      controllerAs: 'chemingedtopicctrl',
      bindings: {
        numMandat: '<',
        numImmeuble: '<',
        idPortefeuille: '<',
        idEvenement: '<',
        gedChemin: '=',
        param: '<',
        onEmit: '&',
        onUpdate: '&'
      }
    });

  /** @ngInject */
  function CheminGedTopicController(COLLAB_CONF, $q, $scope, GedService, TopicManagerService, ModalsService) {
    var ctrl = this;

    ctrl.setTheGedChemin = setTheGedChemin;
    ctrl.onCheckedCheminGed = onCheckedCheminGed;
    ctrl.onChangeTypeGed = onChangeTypeGed;

    ctrl.$onInit = function () {

      var idEvenement = ctrl.idEvenement;
      if(!_.isNil(ctrl.param)) {
        ctrl.nomCreationPdf = angular.copy(ctrl.param.nomPdf);

        ctrl.infosCreationPdf = initInfos(ctrl.param.infos);

        if(!_.isNil(ctrl.param.idEvenement)) idEvenement = ctrl.param.idEvenement;
      }

      if(_.isNil(idEvenement)) ModalsService.alertErreur('Il manque l\'idEvenement');

      ctrl.idEvenement = idEvenement;
      if(_.isNil(ctrl.typeGed)) {
        ctrl.typeGed = TopicManagerService.getTypeOfCurrentEvent();
        ctrl.typeGedSelected = angular.copy(ctrl.typeGed);
      }

      searchGedHierarchie();

      $scope.$on('getInfosCreationPdf', function () {
        ctrl.onUpdate({infos: ctrl.param.infos});
      });
    };

    ctrl.$onChanges = function (changes) {
      if (changes.hasOwnProperty('param.nomPdf')) ctrl.nomCreationPdf = angular.copy(changes.param.nomPdf.currentValue);
      if (changes.hasOwnProperty('param.infos')) ctrl.infosCreationPdf = angular.copy(changes.param.infos.currentValue);
    };

    ctrl.$onDestroy = function () {
    };

    /********** Global **********/

    function searchGedHierarchie(){
      var deferred = $q.defer();
      ctrl.loading = true;
      GedService
        .searchGedHierarchie(ctrl.numImmeuble, ctrl.numMandat, ctrl.idPortefeuille, ctrl.idEvenement, ctrl.typeGedSelected)
        .then(function(json) {
          if (json.folder.folders) ctrl.json = json;
          else json.folder = null;
          deferred.resolve(json);
        })
        .catch(function(msg) {
          deferred.reject(msg);
        })
        .finally(function() {
          ctrl.loading = false;
        });
      return deferred.promise;
    }

    function onChangeTypeGed(){
      searchGedHierarchie();
    }

    function setTheGedChemin(chemin) {
      ctrl.gedChemin = chemin;
    }

    function onCheckedCheminGed(obj){

      recurseUnchecked(ctrl.json.folder.folders, obj);
      if(_.isObject(obj) && obj.hasOwnProperty('cheminGed')) {
        ctrl.gedChemin = obj.cheminGed;
        ctrl.onEmit({obj: {cheminGed: ctrl.gedChemin}});
      }
    }

    function recurseUnchecked(dossiers, obj){
      if(dossiers.length) {
        for(var i = 0; i < dossiers.length; i++) {
          if(!obj.id || (obj.id && obj.id !== dossiers[i].id)){
            dossiers[i].checked = false;
          }
          if(dossiers[i].folders.length) {
            recurseUnchecked(dossiers[i].folders, obj);
          }
        }
      }
    }

    function initInfos(infos) {
      if (!infos) {
        return {
          topicPiecesJointes: false,
          topicCourriers: false,
          topicDevisOuIntervention: false,
          topicDevisSigne: false,
          topicSinistre: false,
          topicMessages: false
        };
      }
      return angular.copy(infos);
    }

  }
})();

