(function() {

  'use strict';

  /**
   * @desc Componsant pour choisir un destinataire
   * @example <destinataire-hierarchie-ligne></destinataire-hierarchie-ligne>
   */

  angular
    .module('collaboreApp')
    .component('destinataireHierarchieLigne',{
      templateUrl: 'app/topics/components/destinataire/destinataire-hierarchie-ligne.component.html',
      bindings : {
        groupe: '<',
        onCheck: '&'
      },
      controllerAs: 'destinatairehierarchielignectrl',
      controller: DestinataireHierarchieLigneController
    });

  /*@ngInject*/
  function DestinataireHierarchieLigneController($sce, uuid){

    var _this = this;
    _this.trustAsHtml = $sce.trustAsHtml;
    _this.onCheckGroupe = onCheckGroupe;          // Lorsqu'on coche ou décoche le groupe
    _this.onCheckGroupeFils = onCheckGroupeFils;  // Lorsqu'un groupe fils du groupe est coché ou décoché
    _this.onClickBtnGroupe = onClickBtnGroupe;

    _this.$onInit = function() {
      _this.uuid = uuid.new();  // Identifiant unique pour les id des label et input
    };

    /**
     * Permet de notifié au composant parent l'état du groupe actuel
     */
    function emit(){
      _this.onCheck({groupeChecked: _this.groupe.checked, groupeIndeterminate: _this.groupe.indeterminate});
    }

    /**
     * Lorsqu'on coche ou décoche le groupe. Repercute le changement sur les enfants
     */
    function onCheckGroupe(groupe){
      if(!groupe) groupe = _this.groupe;
      groupe.indeterminate = false;
      var nbFils = groupe.getGroupesFils().length;
      if(nbFils) {
        for (var i = 0; i < nbFils; i++) {
          var fils = groupe.getGroupesFils()[i];
          if(groupe.checked) fils.checked = true;
          else fils.checked = false;
          onCheckGroupe(fils);
        }
      }
      emit();

    }

    /**
     * Appelé lorsqu'un groupe fils du groupe est coché ou décoché
     * @param groupeChecked
     * @param groupeIndeterminate
     */
    function onCheckGroupeFils(groupeChecked, groupeIndeterminate){

      if(!groupeChecked && !groupeIndeterminate) _this.groupe.checked = false;

      var nbFils = _this.groupe.getGroupesFils().length;
      if(nbFils){
        var nbFilsChecked = 0;
        var nbFilsIndeterminate = 0;
        for(var i = 0; i < nbFils; i++){
          var fils = _this.groupe.getGroupesFils()[i];
          if(fils.checked) nbFilsChecked++;
          if(fils.indeterminate) nbFilsIndeterminate++;
        }
        // Si tous les enfants de ce groupe son coché on coche ce groupe également
        if(nbFilsChecked === nbFils) {
          _this.groupe.checked = true;
          _this.groupe.indeterminate = false;
        }
        // Si aucun enfant coché
        else if(nbFilsChecked === 0 && nbFilsIndeterminate === 0) _this.groupe.indeterminate = false;

        // Si au moins un enfant coché ou indeterminé
        else _this.groupe.indeterminate = true;
      }
      emit();
    }

    function onClickBtnGroupe(){
      _this.groupe.checked = !_this.groupe.checked;
      onCheckGroupe();
    }
  }
})();
