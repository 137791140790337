(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.InfosDashboard
   * @description
   * # InfosDashboard
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('InfosReportingCampingDashboard', InfosReportingCampingDashboardModel);

  /** @ngInject */
  function InfosReportingCampingDashboardModel(Main, InfosReportingCamping, Portefeuille) {

    function InfosReportingCampingDashboard() {
      Main.apply(this, arguments);
    }

    InfosReportingCampingDashboard.prototype = {

      model: 'InfosReportingCampingDashboard',
      isInfosReportingCampingDashboard: true,

      // portefeuille
      getPortefeuille: function(){
        return this.portefeuille;
      },
      setPortefeuille: function(port){
        if(!port.isModel){
          port = new Portefeuille(port);
        }
        this.portefeuille = port;
      },

      // nbTotalTopics
      getNbTotalTopics: function(){
        return parseInt(this.nbTotalTopics);
      },
      setNbTotalTopics: function(nb){
        this.nbTotalTopics = nb;
      },

      // nbTotalTopicsWithEvent
      getNbTotalTopicsWithEvent: function(){
        return parseInt(this.nbTotalTopicsWithEvent);
      },
      setNbTotalTopicsWithEvent: function(nb){
        this.nbTotalTopicsWithEvent = nb;
      },

      // nbTotalTopicsWithIntervention
      getNbTotalTopicsWithIntervention: function(){
        return parseInt(this.nbTotalTopicsWithIntervention);
      },
      setNbTotalTopicsWithIntervention: function(nb){
        this.nbTotalTopicsWithIntervention = nb;
      },

      // nbTotalTopicsMenage
      getNbTotalTopicsMenage: function(){
        return parseInt(this.nbTotalTopicsMenage);
      },
      setNbTotalTopicsMenage: function(nb){
        this.nbTotalTopicsMenage = nb;
      },

      // nbTotalTopicsTechnique
      getNbTotalTopicsTechnique: function(){
        return parseInt(this.nbTotalTopicsTechnique);
      },
      setNbTotalTopicsTechnique: function(nb){
        this.nbTotalTopicsTechnique = nb;
      },

      // nbClientUnsatisfied
      getNbClientUnsatisfied: function(){
        return parseInt(this.nbClientUnsatisfied);
      },
      setNbClientUnsatisfied: function(nb){
        this.nbClientUnsatisfied = nb;
      },

      // nbTotalTopicsOpen
      getNbTotalTopicsOpen: function(){
        return parseInt(this.nbTotalTopicsOpen);
      },
      setNbTotalTopicsOpen: function(nb){
        this.nbTotalTopicsOpen = nb;
      },

      // nbTotalTopicsClosed
      getNbTotalTopicsClosed: function(){
        return parseInt(this.nbTotalTopicsClosed);
      },
      setNbTotalTopicsClosed: function(nb){
        this.nbTotalTopicsClosed = nb;
      },

      // nbTotalTopicsProblem
      getNbTotalTopicsProblem: function(){
        return parseInt(this.nbTotalTopicsProblem);
      },
      setNbTotalTopicsProblem: function(nb){
        this.nbTotalTopicsProblem = nb;
      },

      // nbTopicCreated
      getNbTopicCreated: function(){
        return this.nbTopicCreated;
      },
      setNbTopicCreated: function(val){
        if(!val.isModel){
          val = new InfosReportingCamping(val);
        }
        this.nbTopicCreated = val;
      },

      // nbTopicStillOpenAt22h
      getNbTopicStillOpenAt22h: function(){
        return this.nbTopicStillOpenAt22h;
      },
      setNbTopicStillOpenAt22h: function(val){
        if(!val.isModel){
          val = new InfosReportingCamping(val);
        }
        this.nbTopicStillOpenAt22h = val;
      },

      // nbTopicClosedToday
      getNbTopicClosedToday: function(){
        return this.nbTopicClosedToday;
      },
      setNbTopicClosedToday: function(val){
        if(!val.isModel){
          val = new InfosReportingCamping(val);
        }
        this.nbTopicClosedToday = val;
      },

      // listInfosPortefeuille
      getListInfosPortefeuille: function(){
        return this.listInfosPortefeuille;
      },
      setListInfosPortefeuille: function(val){
        if(_.isArray(val)){
          val = val.map(function(infos){
            if(_.isObject(infos)){
              if(!infos.isModel) infos = new InfosReportingCampingDashboard(infos);
              return infos;
            }
          });
        }
        this.listInfosPortefeuille = val;
      }
    };

    angular.extend(InfosReportingCampingDashboard.prototype, Main.prototype);

    return InfosReportingCampingDashboard;

  }
})();
