(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .component('capfunDashboardRecap', {
      templateUrl: 'app/capfun/dashboard/recap/capfun-dashboard-recap.component.html',
      bindings: {
        search: '<',
        idChannel: '<',
        onEmit: '&'
      },
      controllerAs: 'capfundashboardrecapctrl',
      controller: DashboardReportingCampingController
    });

  /*@ngInject*/
  function DashboardReportingCampingController($rootScope, $state, $scope, $q, $timeout, COLLAB_CONF, UtilsService, DashboardService, PortefeuilleService, FiltreService, TagsService, ErreurCollabService) {

    var _this = this;

    _this.readyForSearch = false;

    _this.result = [];
    _this.infosByCat = null;
    _this.filtreTags = [];
    _this.listColorPortefeuille = {};

    var convDay = {
      j: {
        title: 'J',
        interval: [0, null]
      },
      jMoins1: {
        title: 'J-1',
        interval: [1, null]
      },
      jMoins2: {
        title: 'J-2',
        interval: [2, null]
      },
      jMoins3: {
        title: 'J-3',
        interval: [3, null]
      },
      jMoins4: {
        title: 'J-4',
        interval: [4, null]
      },
      jMoins5: {
        title: 'J-5',
        interval: [5, null]
      },
      jMoins6: {
        title: 'J-6',
        interval: [6, null]
      },
      jMoins7: {
        title: 'J-7',
        interval: [7, null]
      },
      jMoins7EtMoins14: {
        title: 'Entre J-7 et J-14',
        interval: [7, 14]
      },
      jMoins14EtMoins30: {
        title: 'Entre J-14 et J-30',
        interval: [14, 30]
      }
    };

    var objRefByQuestion = {
      nbTopicCreated: {
        title: "Nombre de tickets crées",
        fctState: genereFiltreTicketsCree,
        alerts: {
          val: 50,
          colors: {
            j: 'danger',
            jMoins1: 'danger',
            jMoins2: 'danger',
            jMoins3: 'danger',
            jMoins4: 'danger',
            jMoins5: 'danger',
            jMoins6: 'danger',
            jMoins7: 'danger'
          }
        }
      },
      nbTopicClosedToday: {
        title: "Nombre de tickets fermés dans la journée",
        fctState: genereFiltreTicketsFermee
      },
      nbTopicStillOpenAt22h: {
        title: "Nombre de tickets toujours ouverts à minuit",
        fctState: genereFiltreTicketsToujoursOuvertAMinuit,
        alerts: {
          val: 10,
          colors: {
            jMoins1: 'danger',
            jMoins2: 'warning',
            jMoins3: 'warning',
            jMoins4: 'warning',
            jMoins5: 'warning',
            jMoins6: 'warning',
            jMoins7: 'warning'
          }
        }
      },
      nbTotalTopicsOpen: {
        title: "Nombre total de tickets ouverts"
      },
      nbTotalTopicsProblem: {
        title: "Nombre total de tickets en problème"
      }
    };


    _this.order = null;
    _this.sens = null;

    _this.onClickInfo = onClickInfo;
    _this.onClickSearch = onClickSearch;
    _this.onClickSortTable = onClickSortTable;
    _this.onSelectTab = onSelectTab;
    _this.onSelectTabGlobal = onSelectTabGlobal;
    _this.onSelectTabDays = onSelectTabDays;
    _this.getClassSortIcon = getClassSortIcon;
    _this.onInitFromFiltreCapfunComponent = onInitFromFiltreCapfunComponent;
    _this.onEmitFromFiltreCapfunComponent = onEmitFromFiltreCapfunComponent;

    _this.$onInit = function () {
      $scope.$emit('minimizeLeftSidebar');

      if (_.isObject(_this.search)) {
        var search = UtilsService.decodeSearch(_this.search);
        if(search.hasOwnProperty('filtreByTagType')) _this.filtreTags = search.filtreByTagType;
      }
    };

    function onClickInfo(event, params){
      event.preventDefault();
      $state.transitionTo('capfunDashboard.reporting', params);
    }

    /**
     * Permet de récupérer un objet de graphique pie pour les questions global
     * @param title
     * @returns {{chartConfig: {plotOptions: {pie: {cursor: string, dataLabels: {format: string, enabled: boolean}, allowPointSelect: boolean, point: {events: {legendItemClick: (function(): boolean), select: chartConfig.plotOptions.pie.point.events.select, mouseOut: chartConfig.plotOptions.pie.point.events.mouseOut, mouseOver: chartConfig.plotOptions.pie.point.events.mouseOver, click: (function(): boolean)}}, showInLegend: boolean}}, exporting: {enabled: boolean}, credits: {enabled: boolean}, legend: {layout: string, verticalAlign: string, useHTML: boolean, x: number, y: number, align: string, labelFormatter: (function(): string), enabled: boolean}, series: {data: Array, name: *, colorByPoint: boolean}[], title: {text: null}, chart: {plotBackgroundColor: null, width: number, type: string, plotBorderWidth: null, plotShadow: boolean, height: number}}}}
     */
    function getObjChartPieGlobal(title) {
      return {
        chartConfig: {
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: 250,
            width: 400
          },
          title: {
            text: null
          },
          exporting: {
            enabled: false
          },
          legend: {
            enabled: false,
            align: 'left',
            verticalAlign: 'bottom',
            layout: 'vertical',
            x: 0,
            y: 0,
            labelFormatter: function () {
              return this.y + ' ' + this.name;
            },
            useHTML: true
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              // colors: pieColors,
              showInLegend: true,
              dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.y}'
              },
              point: {
                events: {
                  mouseOver: function () {
                    angular.element('.' + this.currentClassCss).addClass('col-camping-hover');
                    /*
                    var desc = '';
                    var lien = '';
                    switch(this.type) {
                      case 'less7':
                        desc = 'depuis moins de 7 jours';
                        lien = $state.href('dashboard.reporting', ctrl.stateNbTopicNonClotureAvecDateEcheanceDepasseeMoinsDe7Jours, {reload:true});
                        break;
                      case '7to14':
                        desc = 'entre 7 et 14 jours';
                        lien = $state.href('dashboard.reporting', ctrl.stateNbTopicNonClotureAvecDateEcheanceDepasseePlusDe7Jours, {reload:true});
                        break;
                      case 'more14':
                        desc = 'depuis plus de 14 jours';
                        lien = $state.href('dashboard.reporting', ctrl.stateNbTopicNonClotureAvecDateEcheanceDepasseePlusDe14Jours, {reload:true});
                        break;
                      default:
                        desc = '';
                    }
                    /*
                    angular.element('#nbTopicEcheanceDepasseAvecDate').html(this.y);
                    angular.element('#descriptionTopicEcheanceDepasseAvecDate').html(desc);
                    angular.element('#lienNbTopicEcheanceDepasseAvecDate').removeAttr('ng-href').attr('href', lien);
                     */
                  },
                  mouseOut: function () {
                    angular.element('.' + this.classCss).removeClass('col-camping-hover');
                  },
                  legendItemClick: function () {
                    return false;
                  },
                  select: function () {
                  },
                  click: function () {
                    //$state.go('dashboard.reporting',  parseSearch({dateEcheance: this.searchByDateEcheance, nonCloture: true}), {location: true, reload: true, inherit: false});
                    return false;
                  }
                }
              }
            }
          },
          series: [{
            name: title,
            colorByPoint: true,
            data: []
          }],
          credits: {
            enabled: false
          }
        }
      };
    }

    /**
     * Permet de récupérer un objet de graphique column pour les questions global
     * @param title
     * @returns {{chartConfig: {plotOptions: {column: {borderWidth: number, pointPadding: number, point: {events: {mouseOut: chartConfig.plotOptions.column.point.events.mouseOut, mouseOver: chartConfig.plotOptions.column.point.events.mouseOver}}}}, yAxis: {min: number, title: {text: *}}, exporting: {enabled: boolean}, xAxis: {categories: Array}, credits: {enabled: boolean}, legend: {enabled: boolean}, series: {data: Array, name: *, colorByPoint: boolean}[], tooltip: {headerFormat: string, useHTML: boolean, pointFormat: string}, title: {text: null}, chart: {type: string}}}}
     */
    function getObjChartColumnGlobal(title) {
      return {
        chartConfig: {
          chart: {
            type: 'column',
            height: 250
          },
          title: {
            text: null
          },
          exporting: {
            enabled: false
          },
          xAxis: {
            categories: []
          },
          yAxis: {
            min: 0,
            title: {
              text: title
            }
          },
          legend: {
            enabled: false
          },
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0,
              point: {
                events: {
                  mouseOver: function () {
                    angular.element('.' + this.currentClassCss).addClass('col-camping-hover');
                  },
                  mouseOut: function () {
                    angular.element('.' + this.classCss).removeClass('col-camping-hover');
                  }
                }
              }
            }
          },
          tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>',
            useHTML: true
          },
          series: [{
            name: title,
            colorByPoint: true,
            data: []
          }],
          credits: {
            enabled: false
          }
        }
      };
    }

    /**
     * Permet de récupérer un objet de graphique column les questions par jour
     * @param title
     * @returns {{chartConfig: {plotOptions: {column: {borderWidth: number, pointPadding: number}}, yAxis: {min: number, title: {text: *}}, exporting: {enabled: boolean}, xAxis: {categories: Array}, credits: {enabled: boolean}, legend: {enabled: boolean}, series: Array, tooltip: {shared: boolean, headerFormat: string, useHTML: boolean, pointFormat: string, footerFormat: string}, title: {text: null}, chart: {width: number, type: string}}}}
     */
    function getObjChartColumnDays(title){
      return {
        chartConfig: {
          chart: {
            type: 'column',
            width: 1000,
            height: 300
          },
          title: {
            text: null
          },
          exporting: {
            enabled: false
          },
          xAxis: {
            categories: []
          },
          yAxis: {
            min: 0,
            title: {
              text: title
            }
          },
          legend: {
            enabled: true
          },
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0
            }
          },
          tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
          },
          series: [],
          credits: {
            enabled: false
          }
        }
      };
    }

    /**
     * Permet d'ajouter un tag type intervention à la liste des tags passée en paramètre
     * @param listTag
     */
    function addTagTypeIntervention(listTag) {
      listTag.push(TagsService.genereTagTypeType(COLLAB_CONF.TAG_TYPE_TYPE_INTERVENTION, null, null));
    }

    /**
     * Permet d'ajouter un tag type statut terminé à la liste des tags passée en paramètre
     * @param listTag
     */
    function addTagTypeStatutTermine(listTag) {
      listTag.push(TagsService.genereTagTypeStatut(COLLAB_CONF.TAG_TYPE_STATUT_TERMINE, null, null));
    }

    /**
     * Permet d'ajouter un tag type statut probleme à la liste des tags passée en paramètre
     * @param listTag
     */
    function addTagTypeStatutProbleme(listTag) {
      listTag.push(TagsService.genereTagTypeStatut(COLLAB_CONF.TAG_TYPE_STATUT_PROBLEME, null, null));
    }

    /**
     * Permet d'ajouter un tag portefeuille à la liste des tags passée en paramètre
     * @param listTag
     * @param portefeuille
     */
    function addTagTypePortefeuille(listTag, portefeuille) {
      if(!_.isNil(portefeuille)) {
        listTag.push(TagsService.genereTagTypePortefeuille(portefeuille.getNom(), portefeuille.getIdPortefeuille(), portefeuille));
      }
    }

    /**
     * Génère l'objet pour le filtre des topics des tickets ouverts
     * @param portefeuille
     * @returns {{search, idChannel, topicPage}}
     */
    function genereFiltreTicketOuvert(portefeuille) {
      if(!UtilsService.getCurrentUser().hasPortefeuille(portefeuille)) return null;
      var tagsType = [];
      addTagTypeIntervention(tagsType);
      addTagTypePortefeuille(tagsType, portefeuille);
      return FiltreService.getObjFiltreByTags(-1, tagsType, true);
    }

    /**
     * Génère l'objet pour le filtre des topics avec tag problème
     * @param portefeuille
     * @returns {{search, idChannel, topicPage}}
     */
    function genereFiltreTagProbleme(portefeuille) {
      if(!UtilsService.getCurrentUser().hasPortefeuille(portefeuille)) return null;
      var tagsType = [];
      addTagTypeStatutProbleme(tagsType);
      addTagTypeIntervention(tagsType);
      addTagTypePortefeuille(tagsType, portefeuille);
      return FiltreService.getObjFiltreByTags(-1, tagsType, true);
    }

    /**
     * Génère l'objet pour le filtre des topics créé entre des dates
     * @param portefeuille
     * @param jDebut
     * @param jFin
     * @returns {{search, idChannel, topicPage}}
     */
    function genereFiltreTicketsCree(portefeuille, jDebut, jFin) {

      if(!UtilsService.getCurrentUser().hasPortefeuille(portefeuille)) return null;

      var tagsType = [];
      addTagTypeIntervention(tagsType);
      addTagTypePortefeuille(tagsType, portefeuille);
      var currentMoment = moment();

      var obj = {
        withCloture: true,
        withoutCloture: true,
        dateCreationDebut: null,
        dateCreationFin: null
      };

      if(!_.isNil(jDebut)) {
        var momentDateDebut = angular.copy(currentMoment);
        if(jDebut != 0) momentDateDebut.subtract(jDebut, 'days');
        obj.dateCreationDebut = momentDateDebut.format('YYYY-MM-DD')
      }

      if(!_.isNil(jFin)) {
        var momentDateFin = angular.copy(currentMoment);
        if(jFin != 0) momentDateFin.subtract(jFin, 'days');
        obj.dateCreationFin = momentDateFin.format('YYYY-MM-DD')
      }
      else if(obj.dateCreationDebut != null){
        obj.dateCreationFin = obj.dateCreationDebut;
      }

      /*
      var groupeInfos = UtilsService.getCurrentUser().getGroupeInformations();
      if(_.isObject(groupeInfos)){
        if(_.isArray(groupeInfos.getListCampingInformations()) && groupeInfos.getListCampingInformations().length) {
          var campingInfos = groupeInfos.getListCampingInformations()[0];
          $rootScope.isInGroupeTechnique = campingInfos.isTypeCompteTechnique();
          $rootScope.isInGroupeMenage = campingInfos.isTypeCompteMenage();
          if(!_.isNil(campingInfos.getNom())) $rootScope.camping = campingInfos.getNom();
        }
      }*/

      if(_this.listDestinataireSelected.length){
        obj.destinataires = _this.listDestinataireSelected.map(function(item) {
          return {
            libelle: '@' + item.id + "-.+",
            dieze: UtilsService.getCurrentUser().getDieze()
          };
        });

        obj.intervenants = angular.copy(_this.listDestinataireSelected);
      }

      return FiltreService.getObjFiltreByTags(-1, tagsType, true, obj);
    }

    /**
     * Génère l'objet pour le filtre des topics cloturé entre des dates
     * @param portefeuille
     * @param jDebut
     * @param jFin
     * @returns {{search, idChannel, topicPage}}
     */
    function genereFiltreTicketsFermee(portefeuille, jDebut, jFin) {
      if(!UtilsService.getCurrentUser().hasPortefeuille(portefeuille)) return null;
      var tagsType = [];
      addTagTypeStatutTermine(tagsType);
      addTagTypeIntervention(tagsType);
      addTagTypePortefeuille(tagsType, portefeuille);
      var currentMoment = moment();

      var obj = {
        withCloture: true,
        withoutCloture: true,
        dateClotureDebut: null,
        dateClotureFin: null
      };

      if(!_.isNil(jDebut)) {
        var momentDateDebut = angular.copy(currentMoment);
        if(jDebut != 0) momentDateDebut.subtract(jDebut, 'days');
        obj.dateClotureDebut = momentDateDebut.format('YYYY-MM-DD')
      }

      if(!_.isNil(jFin)) {
        var momentDateFin = angular.copy(currentMoment);
        if(jFin != 0) momentDateFin.subtract(jFin, 'days');
        obj.dateClotureFin = momentDateFin.format('YYYY-MM-DD')
      }
      else if(obj.dateClotureDebut != null){
        obj.dateClotureFin = obj.dateClotureDebut;
      }

      return FiltreService.getObjFiltreByTags(-1, tagsType, true, obj);
    }

    /**
     * Génère l'objet pour le filtre des topics toujours ouvert à 00h pour un jour donné
     * @param portefeuille
     * @param jDebut
     * @param jFin
     * @returns {null|{search, idChannel, topicPage}}
     */
    function genereFiltreTicketsToujoursOuvertAMinuit(portefeuille, jDebut, jFin) {
      if(!UtilsService.getCurrentUser().hasPortefeuille(portefeuille)) return null;
      var tagsType = [];
      addTagTypeIntervention(tagsType);
      addTagTypePortefeuille(tagsType, portefeuille);
      var currentMoment = moment();

      var obj = {
        withCloture: true,
        withoutCloture: true,
        stillOpenAt: null
      };

      if(!_.isNil(jDebut)) {
        var momentStillOpenAt = angular.copy(currentMoment);
        if(jDebut > 0) momentStillOpenAt.subtract(jDebut, 'days');
        obj.stillOpenAt = momentStillOpenAt.format('YYYY-MM-DD');
      }
      return FiltreService.getObjFiltreByTags(-1, tagsType, true, obj);
    }

    /*
    function stringToColour(str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var colour = '#';
      for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
      }
      return colour;
    }
    */

    /**
     * Permet de récupérer les infos de stats
     */
    function callReportingCampingDashboard(){

      var params = {};

      if (_.isObject(_this.filtreTags)) {
        if (_.isArray(_this.filtreTags)) params.tags = _this.filtreTags;
        else params.tags = [_this.filtreTags];
      }

      if(_this.listPortefeuilleSelected.length){
        params.idPortefeuilles = _this.listPortefeuilleSelected.map(function(item) {
          return item.idPortefeuille;
        });
      }

      if(_this.listDestinataireSelected.length){
        params.typesIntervenant = _this.listDestinataireSelected.map(function(item) {
          return item.id;
        });
      }

      _this.loading = true;
      //DashboardService.searchReportingCampingDashboard(params)
      DashboardService.searchStatsCampingDashboard(params)
        .then(function (result) {

          var listInfosPortefeuille = null;
          if(_.isObject(result) && !_.isArray(result)){
            listInfosPortefeuille = result.getListInfosPortefeuille();
            _this.result = listInfosPortefeuille;
          }

          if(_.isArray(listInfosPortefeuille) && listInfosPortefeuille.length) {

            _this.infosByCat = listInfosPortefeuille.reduce(function(accum, info, index) {

              var idPortefeuille = info.getPortefeuille().getIdPortefeuille();
              var nomPortefeuille = info.getPortefeuille().getNom();

              if(!accum.hasOwnProperty("global")) accum.global = {
                sort: {
                  order: 'camping',
                  sens: 'ASC'
                },
                categories: {}
              };
              if(!accum.hasOwnProperty("days")) accum.days = {
                currentTab: 'tableByDays',
                sort: {
                  order: 'camping',
                  sens: 'ASC'
                },
                portefeuilles: []
              };

              if(!accum.global.categories.hasOwnProperty("nbTotalTopicsOpen")) accum.global.categories.nbTotalTopicsOpen = {
                title: objRefByQuestion.nbTotalTopicsOpen.title,
                charts: {
                  pie: getObjChartPieGlobal(objRefByQuestion.nbTotalTopicsOpen.title),
                  column: getObjChartColumnGlobal(objRefByQuestion.nbTotalTopicsOpen.title)
                },
                portefeuilles: []
              };
              if(!accum.global.categories.hasOwnProperty("nbTotalTopicsProblem")) accum.global.categories.nbTotalTopicsProblem = {
                title: objRefByQuestion.nbTotalTopicsProblem.title,
                charts: {
                  pie: getObjChartPieGlobal(objRefByQuestion.nbTotalTopicsProblem.title),
                  column: getObjChartColumnGlobal(objRefByQuestion.nbTotalTopicsProblem.title)
                },
                portefeuilles: []
              };

              var objPortefeuille = {
                portefeuille: info.getPortefeuille(),
                /*
                charts: {
                  pie: null,
                  column: getObjChartColumnDays(info.getPortefeuille().getNom())
                },*/
                questions: []
              };

              Object.keys(info).map(function(property){
                if(property !== "portefeuille" && _.isObject(info[property])) {

                  var currentQuestion = objRefByQuestion[property];

                  var obj = {
                    title: currentQuestion.title,
                    obj: info[property]
                  };

                  if(_.isObject(currentQuestion)) {

                    if(!_.isNil(currentQuestion.fctState))  obj.states = {};
                    if(!_.isNil(currentQuestion.alerts))    obj.cssClass = {};

                    Object.keys(info[property]).map(function(propertyJ){
                      if(_.isObject(obj.states)) {
                        obj.states[propertyJ] = currentQuestion.fctState(info.getPortefeuille(), convDay[propertyJ].interval[0], convDay[propertyJ].interval[1]);
                      }

                      if(_.isObject(obj.cssClass) && !_.isNil(currentQuestion.alerts.colors[propertyJ])) {
                        obj.cssClass[propertyJ] = (info[property][propertyJ] >= currentQuestion.alerts.val) ? 'badge badge-'+currentQuestion.alerts.colors[propertyJ] : null;
                      }
                    });
                  }
                  objPortefeuille.questions.push(obj);
                }
              });

              accum.days.portefeuilles.push(objPortefeuille);



              /*
              if(!accum.days.categories.hasOwnProperty("nbTopicCreated")) accum.days.categories.nbTopicCreated = {
                title: "Nombre de tickets crées",
                charts: {
                  pie: null,
                  column: getObjChartColumnDays("Nombre de tickets crées")
                },
                portefeuilles: []
              };
              if(!accum.days.categories.hasOwnProperty("nbTopicStillOpenAt22h")) accum.days.categories.nbTopicStillOpenAt22h = {
                title: "Nombre de tickets toujours ouverts à minuit",
                charts: {
                  pie: null,
                  column: getObjChartColumnDays("Nombre de tickets toujours ouverts à minuit")
                },
                portefeuilles: []
              };
              if(!accum.days.categories.hasOwnProperty("nbTopicClosedToday")) accum.days.categories.nbTopicClosedToday = {
                title: "Nombre de tickets fermés dans la journée",
                charts: {
                  pie: null,
                  column: getObjChartColumnDays("Nombre de tickets fermés dans la journée")
                },
                portefeuilles: []
              };*/

              accum.global.categories.nbTotalTopicsOpen.portefeuilles.push({
                portefeuille: info.getPortefeuille(),
                colCss: ['row-nb-total-topics-open', 'row-nb-total-topics-open-'+info.getPortefeuille().getIdPortefeuille()],
                state: genereFiltreTicketOuvert(info.getPortefeuille()),
                value: info.getNbTotalTopicsOpen()
              });

              accum.global.categories.nbTotalTopicsProblem.portefeuilles.push({
                portefeuille: info.getPortefeuille(),
                colCss: ['row-nb-total-topics-problem', 'row-nb-total-topics-problem-'+info.getPortefeuille().getIdPortefeuille()],
                state: genereFiltreTagProbleme(info.getPortefeuille()),
                value: info.getNbTotalTopicsProblem()
              });

              /*
              accum.days.categories.nbTopicCreated.portefeuilles.push({
                portefeuille: info.getPortefeuille(),
                colCss: ['row-nb-topics-create', 'row-nb-topics-create-'+info.getPortefeuille().getIdPortefeuille()],
                obj: info.getNbTopicCreated(),
                states: {
                  j: genereFiltreTicketsCree(info.getPortefeuille(), 0),
                  jMoins1: genereFiltreTicketsCree(info.getPortefeuille(), 1),
                  jMoins2: genereFiltreTicketsCree(info.getPortefeuille(), 2),
                  jMoins3: genereFiltreTicketsCree(info.getPortefeuille(), 3),
                  jMoins4: genereFiltreTicketsCree(info.getPortefeuille(), 4),
                  jMoins5: genereFiltreTicketsCree(info.getPortefeuille(), 5),
                  jMoins6: genereFiltreTicketsCree(info.getPortefeuille(), 6),
                  jMoins7: genereFiltreTicketsCree(info.getPortefeuille(), 7),
                  jMoins7EtMoins14: genereFiltreTicketsCree(info.getPortefeuille(), 7, 14),
                  jMoins14EtMoins30: genereFiltreTicketsCree(info.getPortefeuille(), 14, 30)
                }
              });

              accum.days.categories.nbTopicStillOpenAt22h.portefeuilles.push({
                portefeuille: info.getPortefeuille(),
                colCss: ['row-nb-topics-still-open', 'row-nb-topics-still-open-'+info.getPortefeuille().getIdPortefeuille()],
                obj: info.getNbTopicStillOpenAt22h(),
                states: {
                  j: null,
                  jMoins1: genereFiltreTicketsToujoursOuvertAMinuit(info.getPortefeuille(), 1),
                  jMoins2: genereFiltreTicketsToujoursOuvertAMinuit(info.getPortefeuille(), 2),
                  jMoins3: genereFiltreTicketsToujoursOuvertAMinuit(info.getPortefeuille(), 3),
                  jMoins4: genereFiltreTicketsToujoursOuvertAMinuit(info.getPortefeuille(), 4),
                  jMoins5: genereFiltreTicketsToujoursOuvertAMinuit(info.getPortefeuille(), 5),
                  jMoins6: genereFiltreTicketsToujoursOuvertAMinuit(info.getPortefeuille(), 6),
                  jMoins7: genereFiltreTicketsToujoursOuvertAMinuit(info.getPortefeuille(), 7),
                  jMoins7EtMoins14: null,
                  jMoins14EtMoins30: null
                }
              });

              accum.days.categories.nbTopicClosedToday.portefeuilles.push({
                portefeuille: info.getPortefeuille(),
                colCss: ['row-nb-topics-closed-today', 'row-nb-topics-closed-today-'+info.getPortefeuille().getIdPortefeuille()],
                obj: info.getNbTopicClosedToday(),
                states: {
                  j: genereFiltreTicketsFermee(info.getPortefeuille(), 0),
                  jMoins1: genereFiltreTicketsFermee(info.getPortefeuille(), 1),
                  jMoins2: genereFiltreTicketsFermee(info.getPortefeuille(), 2),
                  jMoins3: genereFiltreTicketsFermee(info.getPortefeuille(), 3),
                  jMoins4: genereFiltreTicketsFermee(info.getPortefeuille(), 4),
                  jMoins5: genereFiltreTicketsFermee(info.getPortefeuille(), 5),
                  jMoins6: genereFiltreTicketsFermee(info.getPortefeuille(), 6),
                  jMoins7: genereFiltreTicketsFermee(info.getPortefeuille(), 7),
                  jMoins7EtMoins14: genereFiltreTicketsFermee(info.getPortefeuille(), 7, 14),
                  jMoins14EtMoins30: genereFiltreTicketsFermee(info.getPortefeuille(), 14, 30)
                }
              });

              */
              return accum;
            }, {});

            //console.log(_this.infosByCat);

            triTab(_this.infosByCat.global);
            triTab(_this.infosByCat.days);

            /*
            Object.keys(_this.infosByCat.days.categories).map(function(property){
              var cat = _this.infosByCat.days.categories[property];
              for(var p = 0; p < cat.portefeuilles.length; p++){
                var val = cat.portefeuilles[p];
                if(_.isObject(val.obj)) {
                  var dataSeries = [];

                  Object.keys(val.obj).map(function(day){
                    if(!_.isNil(cat.charts.column)) {
                      cat.charts.column.chartConfig.xAxis.categories.push(convDay[day]);
                      dataSeries.push(val.obj[day]);
                    }
                  });
                  if(!_.isNil(cat.charts.column)) {
                    cat.charts.column.chartConfig.series.push({
                      name: val.portefeuille.getNom(),
                      classCss: val.colCss[0],
                      currentClassCss: val.colCss[1],
                      //stateSearch: val.states.j,
                      data: dataSeries
                    });
                  }
                }
              }
            });
            */

            _this.infosByCat.days.portefeuilles.map(function(portefeuille){

              if(!_.isNil(portefeuille.charts)){
                portefeuille.questions.map(function(question){
                  if(_.isObject(question.obj)) {
                    var dataSeries = [];
                    Object.keys(question.obj).map(function(day){
                      if(!_.isNil(portefeuille.charts.column)) {
                        portefeuille.charts.column.chartConfig.xAxis.categories.push(convDay[day].title);
                        dataSeries.push(question.obj[day]);
                      }
                    });
                    if(!_.isNil(portefeuille.charts.column)) {
                      portefeuille.charts.column.chartConfig.series.push({
                        name: question.title,
                        //stateSearch: val.states.j,
                        data: dataSeries
                      });
                    }
                  }
                });
              }
            });

            Object.keys(_this.infosByCat.global.categories).map(function(property){
              var cat = _this.infosByCat.global.categories[property];

              for(var p = 0; p < cat.portefeuilles.length; p++){
                var val = cat.portefeuilles[p];
                var dataSeries = {
                  id: val.portefeuille.getIdPortefeuille(),
                  classCss: val.colCss[0],
                  currentClassCss: val.colCss[1],
                  name: val.portefeuille.getNom(),
                  y: val.value,
                  stateSearch: val.state
                };
                if(!_.isNil(cat.charts.pie)) cat.charts.pie.chartConfig.series[0].data[p] = dataSeries;

                if(!_.isNil(cat.charts.column)) {
                  cat.charts.column.chartConfig.xAxis.categories.push(val.portefeuille.getNom());
                  cat.charts.column.chartConfig.series[0].data[p] = dataSeries;
                }
              }
            });
          }
        })
        .catch(function () {
          _this.result = [];
          _this.infosByCat = null;
        })
        .finally(function () {
          _this.loading = false;
        });
    }


    /**
     * Lors du clique sur le bouton rechercher par rapport au filtre
     */
    function onClickSearch(){
      callReportingCampingDashboard();
    }

    function onClickSortTable(obj, order){
      if(obj.sort.order !== order) {
        obj.sort = {
          order: order,
          sens: 'ASC'
        };
      }
      else obj.sort.sens = (obj.sort.sens === 'DESC') ? 'ASC' : 'DESC';
      triTab(obj);
    }

    function triTab(obj){
      if(!_.isNil(obj.categories)) {
        Object.keys(obj.categories).map(function (property){
          var cat = obj.categories[property];
          cat.portefeuilles.sort(function(a,b){
            if(obj.sort.order === 'camping') {
              if(obj.sort.sens === 'ASC') return a.portefeuille.getNom().localeCompare(b.portefeuille.getNom());
              else return b.portefeuille.getNom().localeCompare(a.portefeuille.getNom());
            }
            else if(obj.sort.order === 'value') {
              if(obj.sort.sens === 'ASC') return a.value - b.value;
              else return b.value - a.value;
            }
            else {
              if(a.hasOwnProperty('obj') && a.obj.hasOwnProperty(obj.sort.order)) {
                if(obj.sort.sens === 'ASC') return a.obj[obj.sort.order] - b.obj[obj.sort.order];
                else return b.obj[obj.sort.order] - a.obj[obj.sort.order];
              }
            }
          });
        });
      }
      else if(!_.isNil(obj.portefeuilles)){
        if(obj.sort.order === 'camping') {
          obj.portefeuilles.sort(function(a,b){
            if(obj.sort.sens === 'ASC') return a.portefeuille.getNom().localeCompare(b.portefeuille.getNom());
            else return b.portefeuille.getNom().localeCompare(a.portefeuille.getNom());
          });
        }
        else {
          Object.keys(obj.portefeuilles).map(function (property){
            obj.portefeuilles[property].questions.sort(function(a,b){
              if(obj.sort.order === 'value') {
                if(obj.sort.sens === 'ASC') return a.value - b.value;
                else return b.value - a.value;
              }
              else {
                if(a.hasOwnProperty('obj') && a.obj.hasOwnProperty(obj.sort.order)) {
                  if(obj.sort.sens === 'ASC') return a.obj[obj.sort.order] - b.obj[obj.sort.order];
                  else return b.obj[obj.sort.order] - a.obj[obj.sort.order];
                }
              }
            });

          });
        }
      }

    }

    function onSelectTab(obj, tab){
      obj.currentTab = tab;
    }

    function onSelectTabGlobal(obj, tab){
      obj.currentTab = tab;
    }

    function onSelectTabDays(obj, tab){
      obj.currentTab = tab;
      $timeout(function(){
        _this.displayDaysCharts = (tab === "graph") ? true : false;
      },500);
    }

    function getClassSortIcon(obj, val){
      return {
        'fa-sort-asc': (obj.sort.order === val && obj.sort.sens === 'ASC'),
        'fa-sort-desc': (obj.sort.order === val && obj.sort.sens === 'DESC'),
        'fa-sort': (obj.sort.order !== val)
      };
    }


    function setInfosFromFiltreCapfun(obj){
      if (_.isObject(obj)) {
        _this.readyForSearch = obj.isReady;
        if(_.isArray(obj.listPortefeuilleSelected)) _this.listPortefeuilleSelected = obj.listPortefeuilleSelected;
        if(_.isArray(obj.listIntervenantSelected)) _this.listDestinataireSelected = obj.listIntervenantSelected;
      }
    }

    function onInitFromFiltreCapfunComponent(obj){
      try {
        if (!obj) throw new Error('Il manque le paramètre "obj"');
        if (_.isObject(obj) && obj.isReady) {
          setInfosFromFiltreCapfun(obj);
          callReportingCampingDashboard();
        }
      }
      catch (err) {
        ErreurCollabService.error('[DashboardReportingCampingController.onEmitFromFiltreCapfunComponent] Erreur : ' + err.message, err);
      }
    }

    function onEmitFromFiltreCapfunComponent(obj){
      try {
        if (!obj) throw new Error('Il manque le paramètre "obj"');
        setInfosFromFiltreCapfun(obj);
      }
      catch (err) {
        ErreurCollabService.error('[DashboardReportingCampingController.onEmitFromFiltreCapfunComponent] Erreur : ' + err.message, err);
      }
    }
  }
})();
