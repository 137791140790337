(function() {
  'use strict';

  angular
    .module('collaboreApp')
    .service('SocketNotificationService', SocketNotificationService);

  /*@ngInject*/
  function SocketNotificationService($rootScope, $timeout, $q, COLLAB_CONF, COLLAB_VALUES, socketFactory, UtilsService) {

    var _this = this;
    var mySocket = null;
    var currentGoupeUser = null;
    var mapEventFunc = {};
    //var alertSocketAlreadyshow = false;

    $rootScope.erreurConnexionWebsocket = false;
    _this.connected = false;
    _this.isConnected = isConnected;
    _this.connectSocketIO = connectSocketIO;
    _this.getInstanceSocketIO = getInstanceSocketIO;
    _this.on = on;
    _this.removeListener = removeListener;
    _this.closeSocketIO = closeSocketIO;
    _this.logOut = logOut;

    function connectSocketIO(groupeObj){
      var deferred = $q.defer();
      currentGoupeUser = groupeObj;
      // Si la page est bien dans le context collab
      if (!$rootScope.notInCollab) {
        if(!mySocket && COLLAB_CONF.ENABLE_COLLAB_NODEJS) {

          // https://socket.io/fr/docs/v2/client-initialization
          // https://socket.io/fr/docs/v2/client-api/
          // https://socket.io/fr/docs/v4/client-options/
          var options = {
            reconnection: true,
            reconnectionAttempts: Infinity,
            reconnectionDelay: 2000,
            reconnectionDelayMax: 5000,
            randomizationFactor: 0.1,
            requestTimeout: 3000,
            timeout: 3000
          };

          if(COLLAB_CONF.WEBSOCKET_ENABLED_ON_BROWSER){
            // utilisation du transport WebSocket en premier, si possible
            options.transports = ['websocket', 'polling'];

            // Problème rencontré chez Cristal Habitat avec Edge version 109
            if(UtilsService.isMicrosoftEdgeBrowser()){
              options.transports = ['polling', 'websocket'];

              var versionEdge = UtilsService.getMicrosoftEdgeBrowserVersion();

              if(!_.isNil(versionEdge)){
                console.debug("Edge version:", versionEdge);
                var tabVersion = versionEdge.split('.');
                if(Array.isArray(tabVersion) && tabVersion.length) {

                  console.debug("Edge version parse :", parseInt(tabVersion[0]));

                  /*
                  // Si version Edge 120 ou plus on remet en websocket classique
                  if(parseInt(tabVersion[0]) >= 120) {
                    options.transports = ['websocket', 'polling'];
                  }*/
                }
              }
            }

            // Firefox bug apparemment... donc en attendant
            //if(bowser.firefox) transports = {'transports':['polling']};
          }

          var myIoSocket = io(COLLAB_VALUES.CONF_URL.URL_COLLAB_NODEJS + '/' + COLLAB_VALUES.CONF_URL.NAMESPACE_COLLAB_NODEJS, options);
          mySocket = socketFactory({
            ioSocket: myIoSocket
          });
          /*
          mySocket.forward('connect_error', $rootScope);
          $rootScope.$on('socket:connect_error', function (ev, data) {
            console.log("socket:connect_error", data);
          });*/

          /*
          mySocket.on('connect_timeout', function(err){
            console.debug('connect_timeout', err);
            $rootScope.erreurConnexionWebsocket = true;
            deferred.reject(err);
          });
          */

          mySocket.on('reconnect_attempt', function(){
            console.debug('reconnect_attempt');
          });

          mySocket.on('reconnect_error', function(err) {
            console.debug('reconnect_error', err);
          });

          mySocket.on('error', function(err){
            console.debug('error', err);
            $rootScope.erreurConnexionWebsocket = true;
            deferred.reject(err);
          });

          mySocket.on('connect_error', function(err){
            // retour au fonctionnement classique en cas d'erreur
            myIoSocket.io.opts.transports = ['polling', 'websocket'];
            console.debug('connect_error', err);
            $rootScope.erreurConnexionWebsocket = true;
            /*
            if(!alertSocketAlreadyshow) {
              ModalsService.info("Problème de connexion à la WebSocket", "La connexion à la WebSocket n'a pas pu être établie. Certaine fonctionnalité comme le \"Dashboard\" ne fonctionneront pas.");
              alertSocketAlreadyshow = true;
            }*/
            deferred.reject(err);
          });

          mySocket.on('connect', function(){
            $rootScope.erreurConnexionWebsocket = false;
          });

          mySocket.on('reconnect', function(attempt) {
            console.debug('reconnect', attempt);
          });

          mySocket.on('disconnect', function(reason){
            console.debug('disconnect', reason);
          });

          if(!_.isNil(mySocket) && mySocket) {
            try{
              //if(groupeUser) mySocket.emit('initConnect', groupeUser);
              mySocket.on('getGroupeUser', function(listeDevis){
                mySocket.emit('initConnect', currentGoupeUser.minify());

                _this.connected = true;
                $rootScope.$broadcast('socketConnected');

                deferred.resolve(mySocket);
              }, function(err){
                deferred.reject(err);
              });
            }
            catch(err) {
              deferred.resolve();
              console.log(err.message)
            }
          }
          else deferred.resolve();
        }
        else deferred.resolve(mySocket);
      }
      else deferred.resolve(mySocket);
      return deferred.promise;
    }

    function getInstanceSocketIO() {
      return mySocket;
    }

    function closeSocketIO(close) {
      if(mySocket) {
        mySocket.removeAllListeners();
        mySocket.disconnect(close);
        _this.connected = false;
        mySocket = null;
      }
    }

    function isConnected() {
      return _this.connected;
    }

    /**
     * Permet d'ajouter un listener à la socket.
     * Si déjà connecté ajoute tout de suite
     * Si pas déjà connecté alors ajoute à une liste de function à executé lorsque l'on sera connecté
     * @param event
     * @param func
     * @param functWhenConnected
     * @param execOnIfNotConnected
     */
    function on(event, func, functWhenConnected, execOnIfNotConnected){
      //console.log('on', event, func);
      if(!_.isNil(event) && _.isString(event) && !_.isNil(func) && _.isFunction(func)) {
        if(_this.isConnected()) {
          //console.log(1, event);
          _this.getInstanceSocketIO().on(event, func);
          addEventFunc(event, func);
          if(_.isFunction(functWhenConnected)) {
            $timeout(function(){
              functWhenConnected();
            });
          }
        }
        else {

          // Si doit executer "functWhenConnected" ou une fonction spécial même sans connexion socket
          if(!_.isNil(execOnIfNotConnected)){
            if(_.isFunction(execOnIfNotConnected)) execOnIfNotConnected();
            else if(_.isBoolean(execOnIfNotConnected) && execOnIfNotConnected && _.isFunction(functWhenConnected)) functWhenConnected();
          }
          //console.log(2, event);
          $rootScope.onSocketConnectedFunctions.push(function(){
            //console.log(3);
            _this.getInstanceSocketIO().on(event, func);
            addEventFunc(event, func);
            if(_.isFunction(functWhenConnected) && (_.isNil(execOnIfNotConnected) || _.isFunction(execOnIfNotConnected))){
              $timeout(function(){
                functWhenConnected();
              });
            }
          });
        }
      }
    }

    /**
     * Permet d'ajouter dans la map d'event function
     * @param event
     * @param funct
     */
    function addEventFunc(event, funct){
      //console.log('addEventFunc', event);
      if(!_.isNil(event) && _.isString(event) && !_.isNil(funct) && _.isFunction(funct)) {
        mapEventFunc[event] = funct;
      }
      //console.log(mapEventFunc);
    }

    /**
     * Permet de savoir si un eventFunc existe déj) dans la map
     * @param event
     * @param funct
     * @returns {boolean}
     */
    function existEventFunc(event, funct){
      var exist = false;
      if(!_.isNil(event) && _.isString(event) && !_.isNil(funct) && _.isFunction(funct)) {
        angular.forEach(mapEventFunc, function(func, ev) {
          if(ev === event){
            exist = true;
          }
        });
      }
      return exist;
    }

    /**
     * Permet de retirer le listener du nom de l'event en paramètre. Pour cela les events et functions sont stockés dans un objet et doivent être ajouté par la méthode "on" de cette class
     * @param event
     */
    function removeListener(event){
      //console.log('removeListener', event);
      if(_this.isConnected() && !_.isNil(event) && _.isString(event)) {
        var isRemove = false;
        angular.forEach(mapEventFunc, function(func, ev) {
          if(ev === event){
            _this.getInstanceSocketIO().removeListener(event, func);
            isRemove = true;

            /*
            var copyArrayFunct = angular.copy($rootScope.onSocketConnectedFunctions);
            if(!_.isNil(copyArrayFunct) && _.isArray(copyArrayFunct) && copyArrayFunct.length) {
                for(var i = 0; i < copyArrayFuncts.length; i++) {
                    if(copyArrayFunct[i].toString() === func.toString()){
                        console.log('trouvé');
                        $rootScope.onSocketConnectedFunctions.splice(i, 1);
                    }
                }
            }*/
          }
        });
        if(isRemove) delete mapEventFunc[event];


      }
    }

    function logOut(){
      if(_this.isConnected()) {
        _this.getInstanceSocketIO().removeAllListeners();
        _this.closeSocketIO(true);
      }
    }

  }
})();
