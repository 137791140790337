(function() {
  'use strict';

  angular
    .module('collaboreApp')
    .factory('GroupeAssociationImmeuble', GroupeAssociationImmeubleModel);

  /** @ngInject */
  function GroupeAssociationImmeubleModel(GroupeAssociation) {

    function GroupeAssociationImmeuble(contenuData) {
      GroupeAssociation.apply(this, arguments);
    }

    GroupeAssociationImmeuble.prototype = {
      model: 'GroupeAssociationImmeuble',
      isGroupeAssociationImmeuble: true,
    };

    angular.extend(GroupeAssociationImmeuble.prototype, GroupeAssociation.prototype);

    return GroupeAssociationImmeuble;
  }
})();
