(function () {
  'use strict';

  /**
   * @desc Liste des TopicStates - liste des topics de list-topic-component
   * @example <list-topic-states list-topic-states="<Array>" topic-detail="<String>(true|false)" topic-states-limit-per-page="<Int>"></list-topic-states>
   */

  angular
    .module('collaboreApp')
    .component('listTopicState', {
      templateUrl: 'app/topics/list-topic/list-topic-state/list-topic-state.component.html',
      bindings: {
        objResultats: '<',
        topicStatesSearch: '<',			// <Object> (One-way binding) Paramètres de recherche
        topicStatesLimitPerPage: '@',	// <String> (NoBinding) Nombre de TopicStates à afficher
        noPagination: '@',				// <String> (One-way binding) Pagination ou non
        typePagination: '@',			// <String> (One-way binding) Type de pagination (lazyload|normal)
        customPreference: '<',
        onEmit: '&',                    // Permet de faire remonter une info au composant parent
        onClickMoreResult: '&'
      },
      controllerAs: 'listtopictatectrl',
      controller: ListTopicStateController
    });

  /*@ngInject*/
  function ListTopicStateController(COLLAB_CONF, $timeout, $stateParams, $rootScope, $scope, $state, $injector, FiltreService, TopicStatesService, ConfigService) {

    var ctrl = this;
    ctrl.$state = $state;
    /******** Méthodes du controller ********/

    //ctrl.isTopicDetail = false;							// Permet d'afficher le topic en version page detail
    ctrl.loadingListTopicStates = {show: false};		// Loading des TopicStates
    ctrl.loadingMoreSearch = {							// Loading pour la pagination en lazy load
      show: false,
      text: COLLAB_CONF.MIDDLE_LOADING
    };
    //ctrl.openLastContenu = openLastContenu;				// Recupère le dernier topic
    ctrl.clickMoreTopics = clickMoreTopics;				// Recupère le dernier topic
    ctrl.onClickClearFiltre = onClickClearFiltre;		// Clear le filtre
    ctrl.onChangePagination = onChangePagination;		// Lors du changement de page
    ctrl.onEmitFromTopicStatesLigne = onEmitFromTopicStatesLigne;

    /****** Listeners ******/
      //var listenerOnSocketConnected = null;
    var listenerOnRemoveTopic = $scope.$on('removeTopic', onRemoveTopic);			// Ecoute l'événement 'removeTopic' pour le supprimer un topic de la liste des topicStates

    // Permet de mettre à jour les infos dans la liste des topics
    var listenerOnUpdateCollab = $scope.$on('updateCollab', function (evt, params) {
      if(_.isObject(params.listTopicStates)) updateListTopicStates(params.listTopicStates);
    });

    //var listenerChangeStart = null;
    /****** Initilisation du component ******/
    ctrl.$onInit = function () {

      // ---- Recupération d'éventuel configuration de personnalisation -----
      if(_.isNil(ctrl.customPreference)) ctrl.customPreference = ConfigService.getPreferencesCustom();
      // ------- Fin de la personnalisation --------

      // Paramétrages de l'app
      var defaultParametrage = $rootScope.current.userGroupe.getDefaultParametrage();
      if (defaultParametrage) {
        if (defaultParametrage.hasOwnProperty('displayDetailsInTopicList')) {
          ctrl.displayDetailsInTopicList = defaultParametrage.getDisplayDetailsInTopicList();
        }
      }

      init();
    };

    ctrl.$onChanges = function(changes){
      if(_.isObject(changes.topicStatesSearch) && !changes.topicStatesSearch.isFirstChange() && changes.topicStatesSearch.currentValue){
        if (_.isNil(ctrl.objResultats)) {
          searchTopicStates(changes.topicStatesSearch.currentValue);
        }
        else {
          ctrl.loadingMoreSearch.show = false;
        }
      }
      if(_.isObject(changes.customPreference) && changes.customPreference.currentValue){
        ctrl.customPreference = changes.customPreference.currentValue;
      }
    };

    ctrl.$onDestroy = function () {
      listenerOnRemoveTopic();
      listenerOnUpdateCollab();
    };

    function updateListTopicStates(listTopicStates){
      if(_.isArray(ctrl.listTopicStates) && ctrl.listTopicStates.length && _.isArray(listTopicStates) && listTopicStates.length) {
        for(var i = 0; i < ctrl.listTopicStates.length; i++){
          for(var j = 0; j < listTopicStates.length; j++){
            if(listTopicStates[j].idTopicState === ctrl.listTopicStates[i].idTopicState){
              delete ctrl.listTopicStates[i].pending;
              ctrl.listTopicStates[i] = listTopicStates[j];
              break;
            }
          }
        }
      }
    }

    function init(){
      ctrl.suffixTitre = '';

      var idChannelFiltre = FiltreService.filtreIsChannel();
      if (idChannelFiltre) {
        var channel = $rootScope.current.userGroupe.getChannel(idChannelFiltre);
        if (channel) {
          ctrl.suffixTitre = ' pour le channel "' + channel.nom + '"';
        }
      }

      // Si il y a un nombre limit de topic par page
      if (!_.isNil(ctrl.topicStatesLimitPerPage)) {
        // Si il y a "false"
        if (ctrl.topicStatesLimitPerPage === 'false') {
          ctrl.topicStatesLimitPerPage = false;
        }
        // Si autres
        else {
          ctrl.topicStatesLimitPerPage = parseInt(ctrl.topicStatesLimitPerPage);	// Formate en int
          // TODO : Si on met autre chose qu'un nombre que ce passe t-il ?
        }

      }
      else {
        ctrl.topicStatesLimitPerPage = COLLAB_CONF.LIST_TOPIC_LIMIT_PAR_PAGE;	// Limit au nombre par défaut
      }

      // Si il y a un objet resulats
      if (!_.isNil(ctrl.objResultats)) {


        var listTopicStates = [];
        var nbTotalTopicStates = 0;

        if (angular.isObject(ctrl.objResultats)) {
          if (ctrl.objResultats.hasOwnProperty('listeTopicsStates')) {
            listTopicStates = ctrl.objResultats.listeTopicsStates;
          }
          if (ctrl.objResultats.hasOwnProperty('nbTotalTopics')) {
            nbTotalTopicStates = ctrl.objResultats.nbTotalTopics;
          }
        }

        ctrl.listTopicStates = listTopicStates;					// Liste des TopicsStates
        ctrl.nbTotalTopicStates = nbTotalTopicStates;			// Nombre total de topicStates
      }
      // Si pas d'objet resultats on fait la recherche
      else if(_.isObject(ctrl.topicStatesSearch)) {
        if(idChannelFiltre && !_.isNil(idChannelFiltre)) {
          ctrl.topicStatesSearch.filtre.idChannel = idChannelFiltre;
        }
        $timeout(function() {
          searchTopicStates(ctrl.topicStatesSearch);
        });
      }


      // Si pas de pagination
      if (ctrl.noPagination === 'true') {
        ctrl.withPagination = false;
        ctrl.typePagination = false;
      }
      else {
        ctrl.withPagination = true;
        // Si demande de pagination en lazyload
        if (ctrl.typePagination === 'lazyload') {
          ctrl.typePagination = 'lazyload';
        } else {
          ctrl.typePagination = 'normal';		// Pagination de type "normal"
        }
      }
    }

    function searchTopicStates(obj){
      ctrl.paramsSearch = angular.copy(obj);
      getTopicStates(ctrl.paramsSearch);
    }

    /**
     * Recupère les TopicStates via Ajax
     * @param params
     */
    function getTopicStates(params) {

      ctrl.loadingListTopicStates.show = true;	// Loading

      TopicStatesService
        .getListTopicStates(params)
        .then(function (obj) {
          ctrl.listTopicStates = obj.listTopicStates;	// Liste d'instance de TopicStates
          ctrl.nbTotalTopicStates = obj.total;			// Nombre total de TopicsStates trouvées

          updateHote({
            nbTotalTopicStates: obj.total,	// Met à jour le nombre total de topic stats dans l'hote
            loadingListTopicStates: false	// Passe le loading de l'hote à false
          });

          var nbPageTopic = Math.ceil(obj.total / ctrl.topicStatesLimitPerPage);
          if(nbPageTopic === 0) {
            nbPageTopic = 1
          }

          // Si différent de la page 1 et qu'il n'y à pas de resultat alors renvoi sur la dernière pages
          if (params.page !== 1 && !obj.listTopicStates.length) {
            $rootScope.current.pageTopic = nbPageTopic;
            onChangePagination();
            //$state.go($state.current.name, {topicPage: nbPageTopic});
          }

        })
        .finally(function () {
          ctrl.loadingListTopicStates.show = false;
          updateHote({
            loadingListTopicStates: false	// Passe le loading de l'hote à false
          });
        });
    }

    function clickMoreTopics() {
      // TODO : Passer le loading à false quand retour du resultat
      ctrl.loadingMoreSearch.show = true;
      ctrl.onClickMoreResult();
    }

    /**
     * Permet de remonter l'info du clearFiltre au composant parent
     */
    function onClickClearFiltre() {
      emit({action: 'cleanFiltre'});
    }

    /**
     * Permet de remonter l'info du changement de page au composant parent
     */
    function onChangePagination() {
      emit({action: 'changePage'});
    }

    /**
     * Supprimer un topicStates de la liste des topicStates
     * @param event
     * @param currentTopicStates
     */
    function onRemoveTopic(event, idTopicState) {
      // Parcour les topicStates
      for (var indexTS = 0; indexTS < ctrl.listTopicStates.length; indexTS++) {
        // Si le topic actuellement supprimé est celui de la bouclse
        if (ctrl.listTopicStates[indexTS].getIdTopicState() === idTopicState) {
          // Suppréssion du topic de la liste des topics
          ctrl.listTopicStates.splice(indexTS, 1);
        }
      }
    }

    /**
     * Permet de remonter une info au composant parent
     * @param obj
     */
    function emit(obj){
      ctrl.onEmit({
        obj: obj
      })
    }

    /**
     * Permet de mettre à jour des données dans l'hote
     */
    function updateHote(obj) {
      emit(_.merge({
        action: 'update',
      }, obj));
    }

    function onEmitFromTopicStatesLigne(obj){
      if(_.isObject(obj)) {
        if(obj.action === 'onFiltreByTag') {
          ctrl.paramsSearch.filtre = obj.filtre;
          getTopicStates(ctrl.paramsSearch);
        }
      }
    }
  }

})();

