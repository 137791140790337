(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.ReceiptLineItem
   * @description
   * # ReceiptLineItem
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('ReceiptLineItem', ReceiptLineItemModel);

  /** @ngInject */
  function ReceiptLineItemModel(Main, ReceiptLineItemItem) {

    function ReceiptLineItem(data) {
      Main.apply(this, arguments);
      this.hydrate(data);
    }

    ReceiptLineItem.prototype = {

      model: 'ReceiptLineItem',
      isReceiptLineItem: true,

      // description
      getDescription: function(){
        return this.description;
      },
      setDescription: function(val){
        this.description = val;
      },

      // lineitems
      getLineitems: function(){
        return this.lineitems;
      },
      setLineitems: function(list){
        if(_.isArray(list)){
          list = list.map(function(item) {
            if(!item.isModel) item = new ReceiptLineItemItem(item);
            return item;
          });
        }
        this.lineitems = list;
      }

    };

    angular.extend(ReceiptLineItem.prototype, Main.prototype);

    return ReceiptLineItem;
  }
})();
