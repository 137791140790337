(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.ContenuService
   * @description
   * # ContenuService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('ContenuService', ContenuService);

  /*@ngInject*/
  function ContenuService($injector, MainService, COLLAB_CONF, $q, sweet, ModalsService, UtilsService, MessageService, ContenuMessage, ContenuEvenement, ContenuSinistre, ContenuEvenementDevis, ContenuEvenementIntervention, Courrier, ContenuAjax, MessageAjax, TopicService, CommunicationAjax, ErreurCollabService) {

    var ctrl = this;
    ctrl.ContenuSinistre = ContenuSinistre;
    ctrl.addEvenementTopic = false;           // Permet de savoir si le fomulaire de l'événement affiché et un pour un topic déjà zxistant sans événement

    ctrl.getDevisInterventionsFacturesByGroupement = getDevisInterventionsFacturesByGroupement;
    ctrl.newGroupement = newGroupement;
    ctrl.lireContenus = lireContenus;
    ctrl.save = save;
    //ctrl.update = update;
    ctrl.updateFieldByField = updateFieldByField;
    ctrl.getContenu = getContenu;


    ctrl.instanceContenuByType = instanceContenuByType;             // Permet d'instancier un contenu par rapport à son type

    ctrl.getDateClotureEvenementForListId = getDateClotureEvenementForListId;     // Recupère la date de cloture de l'event

    ctrl.cloturerEvenementWithListId = cloturerEvenementWithListId;               // Cloture les evenements pour une liste de contenu ou topic
    ctrl.cloturerEvenementForContenu = cloturerEvenementForContenu;               // Cloture un evenement pour un idContenu ou idEvenement
    ctrl.cloturerEvenementForTopic = cloturerEvenementForTopic;                   // Cloture un evenement pour un idTopic

    ctrl.rouvrirEvenementWithListId = rouvrirEvenementWithListId;               // Réouvre les evenements pour une liste de contenu ou topic
    ctrl.rouvrirEvenementForContenu = rouvrirEvenementForContenu;               // Réouvre un evenement pour un idContenu ou idEvenement
    ctrl.rouvrirEvenementForTopic = rouvrirEvenementForTopic;                   // Réouvre un evenement pour un idTopic


    ctrl.relancerDevis = relancerDevis;
    ctrl.relancerDevisForContenus = relancerDevisForContenus;
    ctrl.clotureOrReouvreEvenement = clotureOrReouvreEvenement;
    ctrl.sendNewMessage = sendNewMessage;
    ctrl.sendContenu = sendContenu;
    ctrl.sendSmsForNums = sendSmsForNums;

    //ctrl.createCourrierSimple = createCourrierSimple;

    ctrl.deleteDocumentByGuid = deleteDocumentByGuid;                          // Permet de supprimer un document de la listeDocument ou listeDocumentFournisseur

    ctrl.addDestinatairesToContenu = addDestinatairesToContenu;
    ctrl.getSignatures = getSignatures;

    ctrl.initContenuEvenementFacture = initContenuEvenementFacture;           // Permet d'instancier un objet ContenuEvenmentFacture

    ctrl.cloturerContenu = cloturerContenu;
    ctrl.reouvrirContenu = reouvrirContenu;
    ctrl.updateCommentaire = updateCommentaire;
    ctrl.toggleImportant = toggleImportant;

    ctrl.addDocuments = addDocuments;

    function postServlet(action, obj) {
      var deferred = $q.defer();
      try {
        if (!action) throw new Error('Le paramètre "action" n\'existe pas !');

        if (!_.isObject(obj)) throw new Error('Le paramètre "obj" n\'est pas un objet !');
        if (!obj.hasOwnProperty('idContenu')) throw new Error('Le paramètre "obj" doit avoir un idContenu !');

        var sendParams = angular.merge({}, obj);
        sendParams.action = action;
        sendParams.idContenu = obj.idContenu;
        if(!_.isNil(obj.action)) sendParams.action = obj.action;


        ContenuAjax
          .post(sendParams)
          .$promise
          .then(function onSuccess(json) {
            deferred.resolve(MainService.convertJsonToModel(json, 'contenu', null, deferred));
          })
          .catch(function onError(msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /* Public Methods */

    /**
     * Parcour le contenu pour en sortir une liste de contenu groupé par groupement
     * @param itemContenu
     */
    function getDevisInterventionsFacturesByGroupement(itemContenu) {


      var groupementsObj = {};

      //---------- Devis ------------
      //if(itemContenu.hasOwnProperty('getListeDevis')){

      var devis = itemContenu.getListeDevis();
      if (devis.length) {
        angular.forEach(devis, function (devi) {
          var groupement = devi.getGroupement();

          // Si l'objet 'groupementsObj' n'a pas de propriété du nom du groupement
          if (!groupementsObj.hasOwnProperty(groupement)) {
            groupementsObj[groupement] = {};
          }
          // Si l'objet "groupementsObj[NOM]" n'a pas de tableau 'listeDevis'
          if (!groupementsObj[groupement].hasOwnProperty('listeDevis')) {
            groupementsObj[groupement].listeDevis = [];
          }
          // Ajoute le devis au listeDevis du groupement
          groupementsObj[groupement].listeDevis.push(devi);
        });
      }
      //}

      //---------- Interventions ------------
      //if(itemContenu.hasOwnProperty('getListeInterventions')) {

      var interventions = itemContenu.getListeInterventions();
      if (interventions.length) {
        angular.forEach(interventions, function (intervention) {
          var groupement = intervention.getGroupement();

          // Si l'objet 'groupementsObj' n'a pas de propriété du nom du groupement
          if (!groupementsObj.hasOwnProperty(groupement)) {
            groupementsObj[groupement] = {};
          }
          // Si l'objet "groupementsObj[NOM]" n'a pas de tableau 'listeInterventions'
          if (!groupementsObj[groupement].hasOwnProperty('listeInterventions')) {
            groupementsObj[groupement].listeInterventions = [];
          }
          // Ajoute l'intervention au listeInterventions du groupement
          groupementsObj[groupement].listeInterventions.push(intervention);
        });
      }
      // }

      //---------- Factures ------------
      //if(itemContenu.hasOwnProperty('getListeFactures')) {

      var factures = itemContenu.getListeFactures();
      if (factures.length) {
        angular.forEach(factures, function (facture) {
          var groupement = facture.getGroupement();

          /*
           // Si pas de groupement
           if (angular.isUndefined(groupement) || !angular.isDefined(groupement)) {
           // Si la facture est enfant d'un devis
           if (facture.getIdDevisPere()) {
           // Recupère le devis
           var devisPere = itemContenu.getDevis(facture.getIdDevisPere());
           if (devisPere){
           // Une facture ou une liste de facture ?? TMP les deux
           // devisPere.addFacture(facture);  // Ajoute dans la liste des facture
           devisPere.setFacture(facture);  // Set la facture au devis
           }
           }
           }
           else {*/

          // Si l'objet 'groupementsObj' n'a pas de propriété du nom du groupement
          if (!groupementsObj.hasOwnProperty(groupement)) {
            groupementsObj[groupement] = {};
          }
          // Si l'objet "groupementsObj[NOM]" n'a pas de tableau 'listeFactures'
          if (!groupementsObj[groupement].hasOwnProperty('listeFactures')) {
            groupementsObj[groupement].listeFactures = [];
          }
          // Ajoute la facture au listeFactures du groupement
          groupementsObj[groupement].listeFactures.push(facture);

          //}
        });
      }
      //}

      // ----------- Reformate les groupement pour avoir un tableau d'objet groupement----
      var groupements = [];
      /*
       angular.forEach(groupementsObj,function(obj,nomGroupement){
       groupements.push(ctrl.newGroupement(nomGroupement,obj));
       });

       // Parcour les groupement défini par defaut
       angular.forEach(COLLAB_CONF.GROUPEMENTS_DEFAULT,function(nomGroupementDefault){
       var exist = false;
       // Si il y a déjà des groupements
       if(groupements.length){
       // Parcour ces groupements éxistant
       angular.forEach(groupements,function(objGroupement) {
       // Si le nom du groupement existant est le même que celui par défaut qui doit etre ajouté
       if(objGroupement.nom===nomGroupementDefault){
       exist = true;
       }
       });
       }

       if(!exist){
       groupements.push(ctrl.newGroupement(nomGroupementDefault));
       }
       });*/


      // Parcour les groupement défini par defaut
      angular.forEach(COLLAB_CONF.GROUPEMENTS_DEFAULT, function (nomGroupementDefault) {
        groupements.push(ctrl.newGroupement(nomGroupementDefault));
      });

      // Parcour les groupements éxistants dans l'événement
      angular.forEach(groupementsObj, function (obj, nomGroupement) {
        var exist = false;
        // Si il y a déjà des groupements
        if (groupements.length) {
          // Parcour ces groupements éxistants
          angular.forEach(groupements, function (objGroupement, indexGroupement) {
            // Si le nom du groupement existant est le même que celui par défaut ajouté précédement
            if (objGroupement.nom === nomGroupement) {
              exist = indexGroupement;
            }
          });
        }

        // Si il y a un index d'un groupement dans "exist" alors modification de ce groupement
        if (exist !== false) {
          groupements[exist] = ctrl.newGroupement(nomGroupement, obj);
          // Si "exist" est à false alors on ajoute ce groupement
        } else {
          groupements.push(ctrl.newGroupement(nomGroupement, obj));
        }
      });


      //groupements = $filter('orderBy')(groupements, 'nom');

      return groupements;
    }

    function newGroupement(nomGroupement, data) {
      var newObj = {};
      if (data) {
        if (nomGroupement === '') {
          nomGroupement = 'NC';
        }
      }

      newObj.nom = nomGroupement;
      newObj.selected = false;
      newObj.editing = false;

      newObj.index = 0;

      newObj.listeDevis = [];
      newObj.listeInterventions = [];
      newObj.listeFactures = [];

      var nbListe = 0;
      if (data) {
        if (data.hasOwnProperty('listeDevis')) {
          newObj.listeDevis = data.listeDevis;
          nbListe = nbListe + data.listeDevis.length;
        }

        if (data.hasOwnProperty('listeInterventions')) {
          newObj.listeInterventions = data.listeInterventions;
          nbListe = nbListe + data.listeInterventions.length;
        }

        if (data.hasOwnProperty('listeFactures')) {
          newObj.listeFactures = data.listeFactures;
          nbListe = nbListe + data.listeFactures.length;
        }
      }

      newObj.nb = nbListe;

      return newObj;
    }

    /**
     * Lecture des contenus
     * @param {Array} arrayIdContenus - Id du ou des contenus à passé comme lu
     * @returns {Object} Promesse
     */
    function lireContenus(arrayIdContenus) {

      var deferred = $q.defer();

      var contenuAjax = new ContenuAjax();
      contenuAjax.contenu = arrayIdContenus;
      contenuAjax.$lire(function (retour) {
        if (retour.Resultats[0].success) {

          // TODO : [Notification] Lire les notifications des différent contenu ?

          deferred.resolve(retour);
        } else {
          deferred.reject('Problème de retour Ajax ContenuService.lireContenus()');
        }

      }, function () {
        deferred.reject('Problème avec ContenuService.lireContenus()');
      });

      return deferred.promise;
    }

    /**
     * @name save
     * @desc Ajoute un contenu
     * @param {Object} objParams - Objet des paramètres à envoyer en post
     * @returns {Object} Promesse
     */
    /*
     ctrl.save = function(Topic,objParams){

     var deferred = $q.defer();

     var contenuAjax = new ContenuAjax();
     contenuAjax.topic = objParams.topic;
     contenuAjax.message = objParams.message;
     contenuAjax.destinataires = objParams.destinataires;
     contenuAjax.tags = objParams.tags;
     contenuAjax.guids = objParams.guids;
     contenuAjax.$save(function(json){
     var retour = false;
     if(json.nb!==0){
     if(json.Contenus[0]) {
     retour = json.Contenus[0];

     contenusTopic.push(retour);
     Topic.setContenus(contenusTopic);
     //TopicService._poolTopicStates.push()
     }
     }

     deferred.resolve(retour);
     },function(msg){
     deferred.reject(msg);

     });

     return deferred.promise;
     };
     */
    /**
     * @name save
     * @desc Ajoute un contenu
     * @param {Object} Topic - Object Topic
     * @param {Object} objParams - Objet des paramètres à envoyer en post
     * @returns {Object} Promesse
     */

    function save(objParams) {
      /*
      var deferred = $q.defer();
      try {

        var sendParams = angular.merge({}, objParams);
        sendParams.action = 'addMessage';

        MessageAjax
          .post(sendParams, function (json) {
            if(json.success) {
              var retour = null;
              var contenus = [];
              if(json.hasOwnProperty('contenuMessage') && _.isObject(json.contenuMessage)) {
                contenus = MainService.convertJsonToModel(json.contenuMessage, 'contenu', null, deferred);
              }
              deferred.resolve((angular.isArray(contenus)) ? contenus[0] : null);
            }
            else deferred.reject(json.message);

          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
      */
      var deferred = $q.defer();
      ContenuAjax.save(objParams, function (json) {

        //var retour = false;
        //if (json.nb !== '0') {
        //  if (json.Contenus[0]) {
        //    //retour = Topic.addContenu(json.Contenus[0]);
        //    retour = MainService.initContenuByType(json.Contenus[0]);
        //  }
        //}
        //deferred.resolve(retour);
        var contenus = MainService.convertJsonToModel(json, 'contenu', null, deferred);
        deferred.resolve((angular.isArray(contenus)) ? contenus[0] : null);
      }, function (msg) {
        deferred.reject(msg);
      });
      return deferred.promise;
    }

    /**
     * Update un contenus
     * @param Contenu
     * @param objParams
     * @returns {*}
     */
    //ctrl.update = function(Contenu,objParams){
    /*
    function update(objParams) {

      var deferred = $q.defer();

      ContenuAjax.update(objParams, function (json) {

        //var retour = false;
        //if (json.nb !== '0') {
        //  if (json.Contenus[0]) {
        //    //retour = Topic.updateContenu(json.Contenus[0]);
        //    retour = MainService.initContenuByType(json.Contenus[0]);
        //  }
        //}
        deferred.resolve(retour);
        var contenus = MainService.convertJsonToModel(json, 'contenu', null, deferred);
        deferred.resolve((angular.isArray(contenus)) ? contenus[0] : null);
      }, function (msg) {
        deferred.reject(msg);
      });
      return deferred.promise;
    }*/

    /*
     ctrl.createNewMessage = function(objMsg){


     var newMessage = false;

     if(objMsg.hasOwnProperty('destinataires')){

     newMessage = new ContenuMessage(objMsg,true);

     var destintaires;
     if(angular.isArray(objMsg.destinataires)){

     var newDest = [];
     angular.forEach(objMsg.destinataires, function(dest){
     var libDest = '';

     if(angular.isObject(dest)){
     libDest = dest.libelle+dest.dieze;
     }else{
     libDest = dest;
     }

     newDest.push(libDest);
     });

     destintaires = newDest;
     }else if(angular.isString(objMsg.destinataires)){
     destintaires = [objMsg.destinataires];
     }
     newMessage.setDestinataires(destintaires,true);

     var message = 'new';
     if(objMsg.hasOwnProperty('message')){message = objMsg.message;}
     newMessage.setMessage(message);

     var tags = [];
     if(objMsg.hasOwnProperty('tags')){
     if(angular.isArray(objMsg.tags)){
     tags = objMsg.tags;
     }
     }
     newMessage.setTags(tags);

     var guids = [];
     if(objMsg.hasOwnProperty('guids')){
     if(angular.isArray(objMsg.guids)){
     guids = objMsg.guids;
     }
     }
     newMessage.setGuids(guids);

     var tagsType = [];
     if(objMsg.hasOwnProperty('tagsType')){
     if(angular.isArray(objMsg.tagsType)){
     tagsType = objMsg.tagsType;
     }
     }
     newMessage.setTagsType(tagsType);
     }

     return newMessage;
     };
     */
    function getContenu(idContenu) {
      var deferred = $q.defer();
      var contenuAjax = new ContenuAjax();
      contenuAjax.idContenu = idContenu;
      contenuAjax.$get(function (retour) {
        if (retour.success) deferred.resolve(MainService.initContenuByType(retour.contenu));
        else deferred.reject('Problème de retour Ajax ContenuService.getContenu()');
      }, function error() {
        deferred.reject('Problème avec ContenuService.getContenu()');
      });
      return deferred.promise;
    }


    function instanceContenuByType(contenu) {
      return MainService.initContenuByType(contenu);
    }

    /**
     * Permet de recupérer une date de cloture pour un événement ou une liste d'evenement ou topic
     * @param listeId
     * @param type
     * @returns {Promise}
     */
    function getDateClotureEvenementForListId(listeId, type) {
      var deferred = $q.defer();
      var params = {action: 'dateClotureEvenement'};

      if (type === 'topic') {
        if (angular.isArray(listeId)) {
          params.idTopic = listeId.map(function (topic) {
            if (angular.isNumber(topic)) {
              return topic;
            }
            else if (angular.isObject(topic) && topic.hasOwnProperty('idTopic')) {
              return topic.idTopic;
            }
          });
        }
        else if (angular.isNumber(listeId)) {
          params.idTopic = [listeId];
        }
        else if (angular.isObject(listeId) && listeId.hasOwnProperty('idTopic')) {
          params.idTopic = [listeId.idTopic];
        }
      }
      else if (type === 'contenu') {
        if (angular.isArray(listeId)) {
          params.idContenu = listeId.map(function (topic) {
            if (angular.isNumber(topic)) {
              return topic;
            }
            else if (angular.isObject(topic) && topic.hasOwnProperty('idEvenement')) {
              return topic.idEvenement;
            }
            else if (angular.isObject(topic) && topic.hasOwnProperty('idContenu')) {
              return topic.idContenu;
            }
          });
        }
        else if (angular.isNumber(listeId)) {
          params.idContenu = [listeId];
        }
        else if (angular.isObject(listeId) && listeId.hasOwnProperty('idEvenement')) {
          params.idContenu = [listeId.idEvenement];
        }
        else if (angular.isObject(listeId) && listeId.hasOwnProperty('idContenu')) {
          params.idContenu = [listeId.idContenu];
        }
      }

      ContenuAjax.post(params, function (json) {
        var retour = false;
        if (angular.isArray(listeId)) {
          retour = [];
        }

        if (json.nb !== '0' && json.Contenus.length) {
          if (angular.isArray(listeId)) {
            for (var c = 0; c < json.Contenus.length; c++) {
              retour.push(instanceContenuByType(json.Contenus[c]));
            }
            deferred.resolve(retour);
          }
          else {
            deferred.resolve(instanceContenuByType(json.Contenus[0]));
          }
        } else deferred.resolve(retour);

      }, function (error) {
        var msgErreur = 'Erreur (' + error.status + ') : ' + error.statusText;
        deferred.reject('Problème avec ContenuService.getDateClotureEvenementForListId(), ' + msgErreur);
      });
      return deferred.promise;
    }

    /**
     * Permet de cloturer des evenements pas partir d'une liste de topic ou contenu
     * @param listeId
     * @param message
     * @param type
     * @returns {Promise}
     */
    function cloturerEvenementWithListId(listeId, message, type) {

      var deferred = $q.defer();
      var params = {
        action: 'cloturerEvenements'
      };

      if (!type) {
        type = 'contenu';
      }

      if (type === 'topic') {
        if (angular.isArray(listeId)) {
          params.idTopic = listeId.map(function (topic) {
            if (angular.isNumber(topic)) {
              return topic;
            }
            else if (angular.isObject(topic) && topic.hasOwnProperty('idTopic')) {
              return topic.idTopic;
            }
          });
        }
        else if (angular.isNumber(listeId)) {
          params.idTopic = [listeId];
        }
        else if (angular.isObject(listeId) && listeId.hasOwnProperty('idTopic')) {
          params.idTopic = [listeId.idTopic];
        }
      }
      else if (type === 'contenu') {
        if (angular.isArray(listeId)) {
          params.idContenu = listeId.map(function (topic) {
            if (angular.isNumber(topic)) {
              return topic;
            }
            else if (angular.isObject(topic) && topic.hasOwnProperty('idEvenement')) {
              return topic.idEvenement;
            }
            else if (angular.isObject(topic) && topic.hasOwnProperty('idContenu')) {
              return topic.idContenu;
            }
          });
        }
        else if (angular.isNumber(listeId)) {
          params.idContenu = [listeId];
        }
        else if (angular.isObject(listeId) && listeId.hasOwnProperty('idEvenement')) {
          params.idContenu = [listeId.idEvenement];
        }
        else if (angular.isObject(listeId) && listeId.hasOwnProperty('idContenu')) {
          params.idContenu = [listeId.idContenu];
        }
      }

      if (message) {
        params.message = message;
      }

      ContenuAjax.updateEvenement(params, function (json) {

        var retour = false;
        if (angular.isArray(listeId)) {
          retour = [];
        }

        if (json.nb !== '0' && json.Contenus.length) {
          if (angular.isArray(listeId)) {
            for (var c = 0; c < json.Contenus.length; c++) {
              retour.push(instanceContenuByType(json.Contenus[c]));
            }
            deferred.resolve(retour);
          }
          else {
            deferred.resolve(instanceContenuByType(json.Contenus[0]));
          }
        } else deferred.resolve(retour);

      }, function (error) {
        var msgErreur = 'Erreur (' + error.status + ') : ' + error.statusText;
        deferred.reject('Problème avec ContenuService.cloturerEvenementWithListId(), ' + msgErreur);
      });
      return deferred.promise;
    }

    /**
     * Permet de cloturer un événement par rapport à un idTopic
     * @param listeIdTopicOrIdTopic
     * @returns {Promise}
     */
    function cloturerEvenementForTopic(listeIdTopicOrIdTopic, message) {
      var deferred = $q.defer();
      cloturerEvenementWithListId(listeIdTopicOrIdTopic, message, 'topic')
        .then(function (retour) {
          deferred.resolve(retour);
        })
        .catch(function (msg) {
          deferred.reject('Problème avec ContenuService.cloturerEvenementForTopic(), ' + msg);
        });
      return deferred.promise;
    }

    /**
     * Permet de cloturer un événement par rapport à un idTopic
     * @param listeIdContenuOrIdContenu
     * @returns {Promise}
     */
    function cloturerEvenementForContenu(listeIdContenuOrIdContenu, message) {
      var deferred = $q.defer();
      cloturerEvenementWithListId(listeIdContenuOrIdContenu, message, 'contenu')
        .then(function (retour) {
          deferred.resolve(retour);
        })
        .catch(function (msg) {
          deferred.reject('Problème avec ContenuService.cloturerEvenementForContenu(), ' + msg);
        });
      return deferred.promise;
    }


    /**
     * Permet de réouvrir les événement d'une liste d'id ou idContenu
     * @param listeIdTopic
     * @returns {Promise}
     */
    function rouvrirEvenementWithListId(listeId, message, type) {

      var deferred = $q.defer();
      var params = {
        action: 'reouvrirEvenements'
      };

      if (!type) {
        type = 'contenu';
      }

      if (type === 'topic') {
        if (angular.isArray(listeId)) {
          params.idTopic = listeId.map(function (topic) {
            if (angular.isNumber(topic)) {
              return topic;
            }
            else if (angular.isObject(topic) && topic.hasOwnProperty('idTopic')) {
              return topic.idTopic;
            }
          });
        }
        else if (angular.isNumber(listeId)) {
          params.idTopic = [listeId];
        }
        else if (angular.isObject(listeId) && listeId.hasOwnProperty('idTopic')) {
          params.idTopic = [listeId.idTopic];
        }
      }
      else if (type === 'contenu') {
        if (angular.isArray(listeId)) {
          params.idContenu = listeId.map(function (topic) {
            if (angular.isNumber(topic)) {
              return topic;
            }
            else if (angular.isObject(topic) && topic.hasOwnProperty('idEvenement')) {
              return topic.idEvenement;
            }
            else if (angular.isObject(topic) && topic.hasOwnProperty('idContenu')) {
              return topic.idContenu;
            }
          });
        }
        else if (angular.isNumber(listeId)) {
          params.idContenu = [listeId];
        }
        else if (angular.isObject(listeId) && listeId.hasOwnProperty('idEvenement')) {
          params.idContenu = [listeId.idEvenement];
        }
        else if (angular.isObject(listeId) && listeId.hasOwnProperty('idContenu')) {
          params.idContenu = [listeId.idContenu];
        }
      }

      if (message) {
        params.message = message;
      }

      ContenuAjax.updateEvenement(params, function (json) {
        var retour = false;
        if (angular.isArray(listeId)) {
          retour = [];
        }

        if (json.nb !== '0' && json.Contenus.length) {
          if (angular.isArray(listeId)) {
            for (var c = 0; c < json.Contenus.length; c++) {
              retour.push(instanceContenuByType(json.Contenus[c]));
            }
            deferred.resolve(retour);
          }
          else {
            deferred.resolve(instanceContenuByType(json.Contenus[0]));
          }
        } else deferred.resolve(retour);

      }, function (error) {
        var msgErreur = 'Erreur (' + error.status + ') : ' + error.statusText;
        deferred.reject('Problème avec ContenuService.reouvrirEvenements(), ' + msgErreur);
      });
      return deferred.promise;
    }

    /**
     * Permet de cloturer un événement par rapport à un idTopic
     * @param listeIdTopicOrIdTopic
     * @param message
     * @returns {Promise}
     */
    function rouvrirEvenementForTopic(listeIdTopicOrIdTopic, message) {
      var deferred = $q.defer();
      rouvrirEvenementWithListId(listeIdTopicOrIdTopic, message, 'topic')
        .then(function (retour) {
          deferred.resolve(retour);
        })
        .catch(function (msg) {
          deferred.reject('Problème avec ContenuService.rouvrirEvenementForTopic(), ' + msg);
        });
      return deferred.promise;
    }

    /**
     * Permet de cloturer un événement par rapport à un contenu
     * @param listeIdTopicOrIdTopic
     * @param message
     * @returns {Promise}
     */
    function rouvrirEvenementForContenu(listeIdTopicOrIdTopic, message) {
      var deferred = $q.defer();
      rouvrirEvenementWithListId(listeIdTopicOrIdTopic, message, 'contenu')
        .then(function (retour) {
          deferred.resolve(retour);
        })
        .catch(function (msg) {
          deferred.reject('Problème avec ContenuService.rouvrirEvenementForContenu(), ' + msg);
        });
      return deferred.promise;
    }

    /**
     * Permet de relancer les devis d'une liste d'idTopic
     * @param listeIdTopic
     * @returns {Promise}
     */
    function relancerDevis(listeIdTopic, typeContenu) {
      var deferred = $q.defer();
      var params = {
        action: 'relancerDevis'
      };

      if (!typeContenu) {
        params.idTopic = listeIdTopic;
      }
      else {
        params[typeContenu] = listeIdTopic;
      }

      ContenuAjax.updateEvenement(params, function (json) {
        if (json.nb !== '0') {
          deferred.resolve(json.Resultats[0].success);
        } else deferred.reject(0);

      }, function (error) {
        var msgErreur = 'Erreur (' + error.status + ') : ' + error.statusText;
        deferred.reject('Problème avec ContenuService.relancerDevis(), ' + msgErreur);
      });
      return deferred.promise;
    }

    /**
     * Permet de relancer les devis dun ou plusieur contenu
     * @param listeIdContenu
     * @returns {Promise}
     */
    function relancerDevisForContenus(listeIdContenu) {
      var deferred = $q.defer();
      var liste = [];
      if (angular.isNumber(listeIdContenu)) {
        liste.push(listeIdContenu);
      }
      else if (angular.isArray(listeIdContenu)) {
        liste = listeIdContenu;
      }

      ctrl.relancerDevis(liste, 'idContenu')
        .then(function (retour) {
          deferred.resolve(retour);
        })
        .catch(function (msg) {
          deferred.reject('Problème avec ContenuService.relancerDevisForContenus(), ' + msg);
        });
      return deferred.promise;
    }

    function clotureOrReouvreEvenement() {
      var deferred = $q.defer();

      var topicIsBlocked = false;
      try {
        if (!arguments.length) {
          throw new Error('Aucun paramètres !', arguments);
        }

        var obj, idTopic, idContenu, type = 'topic', clotureOuReouverture = 'cloture';
        switch (arguments.length) {
          // Objet Topic ou Contenu
          case 1:
            obj = arguments[0];
            break;

          // Objet Topic ou Contenu et type (topic ou contenu)
          case 2:

            if (arguments[1] === 'topic' || arguments[1] === 'contenu') {
              type = arguments[1];
            }
            else {
              throw new Error('2ème paramètre doit être "topic" ou "contenu" !', arguments);
            }


            if (type === 'topic') {
              idTopic = arguments[0];
            }
            else if (type === 'contenu') {
              idContenu = arguments[0];
            }


            break;
          // Objet Topic ou Contenu et type (topic ou contenu)
          case 3:

            if (arguments[1] === 'topic' || arguments[1] === 'contenu') {
              type = arguments[1];
            }
            else {
              throw new Error('2ème paramètre doit être "topic" ou "contenu" !', arguments);
            }

            if (type === 'topic') {
              idTopic = arguments[0];
            }
            else if (type === 'contenu') {
              idContenu = arguments[0];
            }

            if (arguments[2] === 'cloture' || arguments[2] === 'reouverture') {
              clotureOuReouverture = arguments[2];
            }
            else {
              throw new Error('3ème paramètre doit être "cloture" ou "reouverture" !', arguments);
            }

            break;

          default:
        }

        if (_.isObject(obj)) {
          if (obj.isModel) {
            if (obj.model === 'ContenuEvenement' || obj.model === 'Contenu') {
              idContenu = obj.getIdContenu();
            }
            else if (obj.model === 'Topic') {
              idTopic = obj.getIdTopic();
            }
            else {
              throw new Error('Objet Model inconnu !');
            }
            topicIsBlocked = obj.isBlocked;
          }
          else if (obj.hasOwnProperty('idTopic')) {
            idTopic = obj.idTopic;
            if (obj.hasOwnProperty('topicIsBlocked')) topicIsBlocked = obj.topicIsBlocked;
          }
          else if (obj.hasOwnProperty('idContenu')) {
            idContenu = obj.idContenu;
            if (obj.hasOwnProperty('topicIsBlocked')) topicIsBlocked = obj.topicIsBlocked;
          }
          else if (obj.hasOwnProperty('idEvenement')) {
            idContenu = obj.idEvenement;
            if (obj.hasOwnProperty('topicIsBlocked')) topicIsBlocked = obj.topicIsBlocked;
          }
          else {
            throw new Error('Objet inconnu !');
          }
        }


        if (idTopic || idContenu) {

          var id = idTopic;
          if (idContenu) {
            type = 'contenu';
            id = idContenu;
          }

          var commentaire;
          var options = {
            type: 'warning'
          };

          ModalsService.loading('Chargement...');

          // Si array alors propose la cloture
          if (_.isArray(id)) {


            var textModal = COLLAB_CONF.MSG_CONFIRMATION_CLOTURE_EVENEMENT_BY_CREATEUR;

            if (clotureOuReouverture === 'cloture') {

              ModalsService
                .confirm('Cloturer/Demande de cloture ' + id.length + ' évenement(s)', textModal, options)
                .then(function (modal) {

                  commentaire = angular.element('#commentaireCloture').val();

                  // Cloture l'événement
                  cloturerEvenementWithListId(id, commentaire, type)
                    .then(function (contenu) {
                      deferred.resolve(contenu);
                    })
                    .catch(function (msg) {
                      throw new Error(msg);
                    })
                    .finally(function () {
                      modal.close();
                    });
                })
                .catch(function(modal){
                  deferred.reject(modal);
                });
            }
            else if (clotureOuReouverture === 'reouverture') {

              ModalsService
                .confirm('Rouvrir/Demande de réouverture ' + id.length + ' évenement(s)', textModal, options)
                .then(function (modal) {

                  commentaire = angular.element('#commentaireCloture').val();

                  // Cloture l'événement
                  rouvrirEvenementWithListId(id, commentaire, type)
                    .then(function (contenu) {
                      deferred.resolve(contenu);
                    })
                    .catch(function (msg) {
                      throw new Error(msg);
                    })
                    .finally(function () {
                      modal.close();
                    });
                })
                .catch(function(modal){
                  deferred.reject(modal);
                });

            }

          }
          else if (angular.isNumber(id)) {
            getDateClotureEvenementForListId(id, type)
              .then(function (evenement) {

                var isCreateur = UtilsService.userIsCreateur(evenement);
                var isCloture = evenement.isCloture();

                var titleModal = COLLAB_CONF.TITLE_MSG_CONFIRMATION_CLOTURE_EVENEMENT_BY_CREATEUR;
                var msgModal = '';
                var btnConfModal = COLLAB_CONF.BTN_CONFIRMATION_CLOTURE_EVENEMENT_BY_CREATEUR;

                if (!isCreateur && !evenement.getAllowCloture()) {
                  titleModal = COLLAB_CONF.TITLE_MSG_CONFIRMATION_CLOTURE_EVENEMENT_BY_NO_CREATEUR;
                  msgModal = COLLAB_CONF.MSG_CONFIRMATION_CLOTURE_EVENEMENT_BY_NO_CREATEUR;
                  btnConfModal = COLLAB_CONF.BTN_CONFIRMATION_CLOTURE_EVENEMENT_BY_NO_CREATEUR;
                }

                if(!evenement.isBlocked) {
                  msgModal += COLLAB_CONF.MSG_CONFIRMATION_CLOTURE_EVENEMENT_BY_CREATEUR;
                }

                options.btnConfirmText = btnConfModal;

                // Si pas cloture
                if (!isCloture) {
                  //$timeout(function(){

                  ModalsService
                    .confirm(titleModal, msgModal, options)
                    .then(function (modal) {
                      commentaire = angular.element('#commentaireCloture').val();

                      // Cloture l'événement
                      cloturerEvenementWithListId(id, commentaire, type)
                        .then(function (contenu) {
                          deferred.resolve(contenu);
                        })
                        .catch(function (msg) {
                          throw new Error(msg);
                        })
                        .finally(function () {
                          modal.close();
                        });
                    })
                    .catch(function(modal){
                      deferred.reject(modal);
                    });

                }
                // Si réouverture
                else {
                  titleModal = COLLAB_CONF.TITLE_MSG_CONFIRMATION_REOUVRIR_EVENEMENT_BY_CREATEUR;
                  if (!isCreateur && !evenement.getAllowCloture()) {
                    titleModal = COLLAB_CONF.TITLE_MSG_CONFIRMATION_REOUVRIR_EVENEMENT_BY_NO_CREATEUR;
                    msgModal = COLLAB_CONF.MSG_CONFIRMATION_REOUVRIR_EVENEMENT_BY_NO_CREATEUR;
                    btnConfModal = COLLAB_CONF.BTN_CONFIRMATION_REOUVRIR_EVENEMENT_BY_NO_CREATEUR;
                  }

                  options.btnConfirmText = btnConfModal;

                  ModalsService
                    .confirm(titleModal, msgModal, options)
                    .then(function (modal) {
                      commentaire = angular.element('#commentaireCloture').val();

                      // Réouvre l'événement
                      rouvrirEvenementWithListId(id, commentaire, type)
                        .then(function (contenu) {
                          deferred.resolve(contenu);
                        })
                        .catch(function (msg) {
                          throw new Error(msg);
                        })
                        .finally(function () {
                          modal.close();
                        });
                    })

                    .catch(function(modal){
                      deferred.reject(modal);
                    });

                }

              })
              .catch(function (msg) {
                throw new Error(msg);
              });
          }
          else {
            throw new Error('Problème avec "id" : ' + id);
          }
        }
        else {
          throw new Error('Problème !! Pas d\'idContenu ni d\'idTopic !');
        }
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        //ErreurCollabService.logErreur(e.message);
        deferred.reject(e.message);
      }

      return deferred.promise;
    }


    /**
     * Crée et sauvegarde un nouveau message
     * @param proxyContenuMessage
     * @param objTopic
     * @returns {Promise}
     */
    function sendNewMessage(proxyContenuMessage) {
      var deferred = $q.defer();

      if (angular.isObject(proxyContenuMessage)) {

        sendContenu(proxyContenuMessage)
          .then(function (objRetour) {
            if (objRetour.hasOwnProperty('action')) {
              if (objRetour.action === 'insertContenu') deferred.resolve(objRetour.obj);
              else deferred.reject('Problème sendNewMessage, action inconnu');
            }
            else deferred.reject('Problème senContenu, pas de propriété "action"');
          })
          .catch(function (error) {
            sweet.show({
              title: 'Il y a eu une erreur...',
              text: (angular.isString(error)) ? error : error.statusText + ' (' + error.status + ')',
              type: 'error'
            });

            deferred.reject(error);
          });
      }
      else deferred.reject('ContenuService.sendNewMessage : Pas de proxyContenuMessage...');
      return deferred.promise;
    }

    /**
     * Permet d'enregistrer un nouveau Topic, nouveau Contenu ou update un Contenu
     * @param proxyObj
     * @param objTopic
     * @param titreTopic
     * @returns {Promise}
     */
    function sendContenu(proxyObj, objTopic, titreTopic, cacher) {

      var deferred = $q.defer();

      var idTopic = false;
      if (angular.isObject(objTopic) && objTopic.isModel) idTopic = objTopic.getIdTopic();
      else if(angular.isNumber(objTopic)) idTopic = objTopic;
      else if(proxyObj.hasOwnProperty('idTopic') && proxyObj.idTopic) idTopic = proxyObj.idTopic;

      var params;
      // Ajout d'un contenu
      if (idTopic) {

        // Ajoute l'idTopic si n'existe aps déjà
        if(!proxyObj.hasOwnProperty('idTopic') || !proxyObj.idTopic) proxyObj.idTopic = idTopic;
        params = {objJSON: proxyObj.toJson()};

        // Si update un contenu
        if (proxyObj.idContenu) {

          // Si objet de type evenement
          //if (proxyObj.typeContenu === 2) params = {objJSON: proxyObj.toJson()};

          var obj = {
            idContenu: proxyObj.idContenu,
            message: proxyObj.message
          };

          MessageService
            .updateMessage(obj)
            .then(function(obj){
              deferred.resolve({
                action: 'updateContenu',
                obj: obj.message
              });
            })
            .catch(function(error){
              deferred.reject(error);
            });

          /*
        // Update le contenu
        update(params)
          .then(function (retourContenu) {
            deferred.resolve({
              action: 'updateContenu',
              obj: retourContenu
            });
          })
          // Si erreur
          .catch(function (error) {
            deferred.reject(error);
          });*/
        }
        // Si insert un contenu
        else {

          // Insert le contenu
          save(params)
            .then(function (retourContenu) {
              deferred.resolve({
                action: 'insertContenu',
                obj: retourContenu
              });
            })
            // Si erreur
            .catch(function (msg) {
              deferred.reject(msg);
            });
        }
      }
      // Ajout d'un Topic
      else {

        // Si pas de titre
        if (angular.isUndefined(titreTopic) || titreTopic === '') deferred.reject('titreVide');
        // Si ok
        else {
          proxyObj.update = false;
          params = {titre: titreTopic, objJSON: proxyObj.toJson()};
          if(!_.isNil(cacher)) {
            params.cacher = cacher;
          }
          deferred.notify({displayLoading: true});
          // Insert le topic
          TopicService.save(params)
            .then(function (topic) {
              deferred.resolve({
                action: 'insertTopic',
                obj: topic
              });
            })
            // Si erreur
            .catch(function (msg) {
              deferred.notify({displayLoading: false});
              deferred.reject(msg);
            });
        }
      }
      return deferred.promise;
    }

    /**
     * Permet d'envoyer des SMS
     * @param idContenu
     * @param numeros
     * @returns {Promise}
     */
    function sendSmsForNums(idContenu, numeros) {

      var deferred = $q.defer();

      var params = {
        'idContenu': idContenu,
        'numeros': numeros
      };

      CommunicationAjax.sendSms(params, function (json) {
        deferred.resolve(json);
      }, function (error) {
        ErreurCollabService.erreurServlet(error, 'Erreur avec ContenuService.sendSmsForNums()', deferred);
      });

      return deferred.promise;
    }

    /**
     * Permet de créer un courrier simple
     * @param obj
     * @returns {IPromise<T>}
     */
    /*
    function createCourrierSimple(obj) {
      var deferred = $q.defer();
      try {
        if (!angular.isObject(obj)) throw new Error('Le paramètre n\'est pas un objet !');
        obj.action = 'createCourrierSimple';
        ContenuAjax.update(obj, function (json) {
          deferred.resolve(MainService.convertJsonToModel(json, 'courrier', Courrier));
        }, function (msg) {
          deferred.reject(msg);
        });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }*/


    // --- NEW ---

    /**
     * Permet de modifier un contenu champ par champ
     * @param obj
     * @returns {IPromise<T>}
     */
    function updateFieldByField(obj) {
      var deferred = $q.defer();
      postServlet('update', obj)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de supprimer un document du devis
     * @param obj
     * @returns {IPromise<T>}
     */
    function deleteDocumentByGuid(obj) {
      var deferred = $q.defer();
      postServlet('deleteDocumentByGuid', obj)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de cloturer un contenu
     * @param obj
     * @returns {IPromise<T>}
     */
    function cloturerContenu(obj) {
      var deferred = $q.defer();
      postServlet('cloturerContenu', obj)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de reouvrir un contenu
     * @param obj
     * @returns {*}
     */
    function reouvrirContenu(id) {
      var deferred = $q.defer();
      postServlet('reouvrirContenu', {idContenu: id})
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet de modifier le commentaire d'un contenu autre que le contenu message
     * @param obj
     * @returns {*}
     */
    function updateCommentaire(id, valCommentaire) {
      var deferred = $q.defer();
      postServlet('updateCommentaire', {idContenu: id, commentaire: valCommentaire})
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet d'enlever/supprimer un tagType important d'un contenu
     * @param id
     * @returns {*}
     */
    function toggleImportant(id) {
      var deferred = $q.defer();
      postServlet('toggleImportant', {idContenu: id})
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Permet d'envoyer des documents dans un contenu
     * @param params
     * @returns {*}
     */
    function addDocuments(params) {
      var deferred = $q.defer();
      postServlet('addDocuments', params)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

    /**
     * Ajout d'une liste de destinataire à un contenu
     * @param obj
     */
    function addDestinatairesToContenu(idContenu, destinataires) {
      var obj = {
        idContenu: idContenu,
        destinataires: destinataires
      };
      var deferred = $q.defer();
      ContenuAjax
        .addDestinatairesToContenu(obj, function (json) {
          var Groupe = $injector.get('Groupe');
          deferred.resolve(MainService.convertJsonToModel(json, 'listGroupe', Groupe, deferred));
        }, function (msg) {
          deferred.reject(msg);
        });
      return deferred.promise;
    }

    /**
     * Permet de récupére run objet contenant les différentes signature
     * @returns {{signaturePerso: null, signatureSociete: null, signatures: Array, signatureSelected: null}}
     */
    function getSignatures(signature){
      var objSignatures = {
        signaturePerso: null,
        signatureSociete: null,
        signatures: [],
        signatureSelected: null
      };
      var signaturePerso = UtilsService.getParametragePerso('signature');
      var signatureSociete = UtilsService.getParametrageSociete('signature');
      var objSignaturePerso = null;
      var objSignatureSociete = null;
      if(signaturePerso) {
        objSignaturePerso = {libelle: 'Signature Perso', value: signaturePerso};
        objSignatures.signatures.push(objSignaturePerso);
        objSignatures.signatureSelected = objSignaturePerso;
      }
      if(signatureSociete) {
        objSignatureSociete = {libelle: 'Signature Société', value: signatureSociete};
        objSignatures.signatures.push(objSignatureSociete);
        if(!objSignatures.signatureSelected) objSignatures.signatureSelected = objSignatureSociete;
      }

      // Si il y a une signature en paramètre et qu'on a bien des signatures pour le groupe user
      if(signature && objSignatures.signatures.length) {
        objSignatures.signatureSelected = null;
        for(var i = 0; i < objSignatures.signatures.length; i++) {
          // Si la signature actuel de la boucle et celle passé en paramètre on la select
          if(objSignatures.signatures[i].value === signature) {
            objSignatures.signatureSelected = objSignatures.signatures[i];
            break;
          }
        }
        // Si la signature selected est null alors cette signature n'est plus dans la liste des signature du gorupe user
        if(!objSignatures.signatureSelected) {
          var signatureSelected = {libelle: 'Autre Signature', value: signature};
          objSignatures.signatureSelected = signatureSelected;
          objSignatures.signatures.push(signatureSelected);
        }
      }
      return objSignatures;
    }

    /**
     * Instancie un contenu de type facture
     * @param data
     */
    function initContenuEvenementFacture(data){
      var ContenuEvenementFacture = $injector.get('ContenuEvenementFacture');
      return new ContenuEvenementFacture(data);
    }

    angular.extend(ContenuService.prototype, TopicService);


    //__extends(ContenuService.prototype, TopicService);
  }

})();
