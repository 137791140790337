(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.Digicode
   * @description
   * Service pour faire appel au servlets du ICSCloud par le biais de Collabore. Migrer les méthodes qui appel ICSCloud dans ce service si possible
   */

  angular
      .module('collaboreApp')
      .service('CompteService',
      CompteServiceController
      );

  /** @ngInject */
  function CompteServiceController() {
    var _this = this;
    _this.execOnEachCompte = execOnEachCompte;
    _this.getNbCompte = getNbCompte;
    _this.getReste = getReste;
    _this.getMontant = getMontant;
    _this.initContrattab = initContrattab;
    _this.ventile = ventile;
    _this.ventileContrat = ventileContrat;
    _this.extractComptes = extractComptes;
    _this.listCompteToListCompteByRegroupement = listCompteToListCompteByRegroupement;
    _this.listCompteByRegroupementToListSelectedCompte = listCompteByRegroupementToListSelectedCompte;

    /**
     * Permet d'excuter une fonction pour chaque compte de la hierarchie
     * @param {*} listCompteByBatiment
     * @param {*} fct
     */
    function execOnEachCompte(listCompteByBatiment, fct){
      if(_.isArray(listCompteByBatiment) && !_.isEmpty(listCompteByBatiment)) {
        listCompteByBatiment.map(function(batiment){
          if(_.isArray(batiment.listCompte)){
            batiment.listCompte.map(function(compte){
              if(_.isFunction(fct)) fct(compte);
            });
          }
          if(_.isArray(batiment.listRegroupement)){
            batiment.listRegroupement.map(function(regroupement){
              if(_.isArray(regroupement.listCompte)){
                regroupement.listCompte.map(function(compte){
                  if(_.isFunction(fct)) fct(compte);
                });
              }
              if(_.isArray(regroupement.listRegroupement)){
                regroupement.listRegroupement.map(function(sousRegroupement){
                  if(_.isArray(sousRegroupement.listCompte)){
                    sousRegroupement.listCompte.map(function(compte){
                      if(_.isFunction(fct)) fct(compte);
                    });
                  }
                });
              }
            });
          }
        });
      }
    }

    function getNbCompte(listCompteByBatiment){
      var nbCompte = 0;
      execOnEachCompte(listCompteByBatiment, function(){
        nbCompte += 1;
      });
      return nbCompte;
    }

    /**
     * Recupère le reste à payerà partir de "_this.montant" et la hierarchie "_this.listCompteByBatiment"
     * @returns
     */
    function getReste(montant, listCompteByBatiment){
      var reste = (!_.isNil(montant)) ? angular.copy(montant) : 0;
      if(_.isArray(listCompteByBatiment) && !_.isEmpty(listCompteByBatiment)) {

        listCompteByBatiment.map(function(batiment){
          if(_.isArray(batiment.listCompte)){
            reste = batiment.listCompte.reduce(function (leReste, compte) {
              //delete compte.montantCorrection;
              if (!_.isNil(compte.montant)) leReste = math.subtract(leReste, compte.montant);
              return leReste;
            }, reste);
          }
          if(_.isArray(batiment.listRegroupement)){
            batiment.listRegroupement.map(function(regroupement){
              if(_.isArray(regroupement.listCompte)){
                reste = regroupement.listCompte.reduce(function (leReste, compte) {
                  //delete compte.montantCorrection;
                  if (!_.isNil(compte.montant)) leReste = math.subtract(leReste, compte.montant);
                  return leReste;
                }, reste);
              }
              if(_.isArray(regroupement.listRegroupement)){
                regroupement.listRegroupement.map(function(sousRegroupement){
                  if(_.isArray(sousRegroupement.listCompte)){
                    reste = sousRegroupement.listCompte.reduce(function (leReste, sousCompte) {
                      //delete sousCompte.montantCorrection;
                      if (!_.isNil(sousCompte.montant)) leReste = math.subtract(leReste, sousCompte.montant);
                      return leReste;
                    }, reste);
                  }
                });
              }
            });
          }
        });

        reste = math.floor(reste, 2);
      }
      return reste;
    }

    /**
     * Permet de récupérer le montant total renseigné dans tous les comptes de la hiérarchie
     * @param {*} listCompteByBatiment
     * @returns
     */
    function getMontant(listCompteByBatiment){
      var montant = 0;
      if(_.isArray(listCompteByBatiment) && !_.isEmpty(listCompteByBatiment)) {

        listCompteByBatiment.map(function(batiment){
          if(_.isArray(batiment.listCompte)){
            montant = batiment.listCompte.reduce(function (leMontant, compte) {
              if (!_.isNil(compte.montant)) leMontant = math.add(leMontant, compte.montant);
              return leMontant;
            }, montant);
          }
          if(_.isArray(batiment.listRegroupement)){
            batiment.listRegroupement.map(function(regroupement){
              if(_.isArray(regroupement.listCompte)){
                montant = regroupement.listCompte.reduce(function (leMontant, compte) {
                  if (!_.isNil(compte.montant)) leMontant = math.add(leMontant, compte.montant);
                  return leMontant;
                }, montant);
              }
              if(_.isArray(regroupement.listRegroupement)){
                regroupement.listRegroupement.map(function(sousRegroupement){
                  if(_.isArray(sousRegroupement.listCompte)){
                    montant = sousRegroupement.listCompte.reduce(function (leMontant, sousCompte) {
                      if (!_.isNil(sousCompte.montant)) leMontant = math.add(leMontant, sousCompte.montant);
                      return leMontant;
                    }, montant);
                  }
                });
              }
            });
          }
        });

        montant = math.floor(montant, 2);
      }
      return montant;
    }


    /**
     * Permet d'ajouter une propriété "contrattab" dans les comptes
     * @param {*} listCompteByBatiment
     * @param {*} refContrat
     * @returns
     */
    function initContrattab(listCompteByBatiment, refContrat){
      listCompteByBatiment.map(function(batiment){
        if(_.isArray(batiment.listCompte)){
          batiment.listCompte.map(function(compte){
            compte.contrattab = compte.getContrattab(refContrat);
          });
        }
        if(_.isArray(batiment.listRegroupement)){
          batiment.listRegroupement.map(function(regroupement){
            if(_.isArray(regroupement.listCompte)){
              regroupement.listCompte.map(function(compte){
                compte.contrattab = compte.getContrattab(refContrat);
              });
            }
            if(_.isArray(regroupement.listRegroupement)){
              regroupement.listRegroupement.map(function(sousRegroupement){
                if(_.isArray(sousRegroupement.listCompte)){
                  sousRegroupement.listCompte.map(function(compte){
                    compte.contrattab = compte.getContrattab(refContrat);
                  });
                }
              });
            }
          });
        }
      });
      return listCompteByBatiment;
    }

    /**
     * Permet de ventiller un compte avec montant null par rapport à un reste et une ref de contrat ou non
     * @param {*} compte
     * @param {*} reste
     * @param {*} contrat
     */
    function ventileCompte(compte, montantTotal, contrat, force){
      if(_.isNil(compte.montant) || _.isObject(contrat)) {
        if(_.isObject(contrat)){
          var refContrat = (contrat.isObjetContrat) ? contrat.value : contrat.refCon;
          var contrattab = (_.isObject(compte.contrattab)) ? compte.contrattab : compte.getContrattab(refContrat);
          if(_.isObject(contrattab) && !_.isNil(contrattab.percentQP)){
            var percent = math.divide(contrattab.percentQP, 100);
            var montant = math.floor(math.multiply(montantTotal, percent),2);

            if(montant === 0) {
              delete compte.montant;
              delete compte.montantCorrection;
              delete compte.edited;
            }
            else{
              // Si compte déjà edité à la main on va proposer une correction
              if(compte.edited && !force) {
                if(compte.montant != montant) compte.montantCorrection = montant;
              }
              // Sinon change le montant
              else {
                compte.montant = montant;
                if(force) {
                  delete compte.montantCorrection;
                  delete compte.edited;
                }
              }
            }
          }
        }
      }
    }

    /**
     * Permet de ventiler les comptes des contrats
     * @param {*} reste
     * @param {*} listCompteByBatiment
     * @param {*} contrat
     * @param {*} force
     * @returns
     */
    function ventileContrat(montant, listCompteByBatiment, contrat, force){
      if(_.isNil(contrat.montant)) return;
      if(_.isArray(listCompteByBatiment) && !_.isEmpty(listCompteByBatiment) && _.isObject(contrat)){
        if(force && !_.isNil(montant)) contrat.montant = montant;

        var montantVentile = angular.copy(contrat.montant);
        var reste = montantVentile;
        execOnEachCompte(listCompteByBatiment, function(compte){
          ventileCompte(compte, montantVentile, contrat, force);
          if(!_.isNil(compte.montant)){
            reste = math.round(math.subtract(reste, compte.montant), 2);
            if(math.equal(reste, 0.01)){
              compte.montant = math.round(math.add(compte.montant, reste), 2);
            }
          }
        });

        contrat.cumulMontant = getMontant(listCompteByBatiment);
      }
    }

    /**
     * Permet de ventiler les comptes de la hiérarchie
     * @param {*} reste
     * @param {*} listCompteByBatiment
     * @param {*} contrat
     * @param {*} noAttributReste
     * @returns
     */
    function ventile(montant, listCompteByBatiment, contrat, noAttributReste){
      var reste = 0;
      if(_.isArray(listCompteByBatiment) && !_.isEmpty(listCompteByBatiment)){
        if(_.isObject(contrat)){
          if(_.isObject(contrat.source) && contrat.source.hasBasRep() && !_.isNil(contrat.reste)) reste = contrat.reste;
          else reste = contrat.montant;
        }
        else if(!_.isNil(montant)) reste = montant;
        reste = math.floor(reste, 2);

        execOnEachCompte(listCompteByBatiment, function(compte){
          delete compte.montantCorrection;
        });

        var nbCompte = listCompteByBatiment.reduce(function(nb, batiment){
          if(_.isArray(batiment.listCompte)) nb += batiment.listCompte.length;
          if(_.isArray(batiment.listRegroupement)) {
            nb = batiment.listRegroupement.reduce(function(nb, regroupement){
              if(_.isArray(regroupement.listCompte)) nb += regroupement.listCompte.length;
              if(_.isArray(regroupement.listRegroupement)) {
                nb = regroupement.listRegroupement.reduce(function(nb, sousRegroupement){
                  if(_.isArray(sousRegroupement.listCompte)) nb += sousRegroupement.listCompte.length;
                  return nb;
                }, nb);
              }
              return nb;
            }, nb);
          }
          return nb;
        }, 0);

        var cumul = getMontant(listCompteByBatiment);
        var resteApresAutresComptes = math.floor(math.subtract(reste, cumul),2);
        /*
        // Si le cumul est plus grand que le reste
        if(math.largerEq(cumul, reste)){
          resteApresAutresComptes = math.floor(math.subtract(cumul, reste),2);
        }
        // Si cumul plus petit que le reste
        else if(math.smaller(cumul, reste)){
          resteApresAutresComptes = math.floor(math.subtract(reste, cumul),2);
        }*/

        if(resteApresAutresComptes === 0) return 0;

        // Si le reste est négatif (correction)
        if(math.isNegative(resteApresAutresComptes)){
          execOnEachCompte(listCompteByBatiment, function(compte){
            if(!_.isNil(compte.montant)) {
              if(math.largerEq(compte.montant, math.format(resteApresAutresComptes).replace(/\-/gi,''))){
                if(nbCompte === 1) compte.montant = math.floor(math.add(resteApresAutresComptes, compte.montant),2);
                else compte.montantCorrection = math.floor(math.add(resteApresAutresComptes, compte.montant),2);

                if(compte.montant === 0) delete compte.montant;
              }
            }
            else{
              if(nbCompte === 1) compte.montant = resteApresAutresComptes;
              else compte.montantCorrection = resteApresAutresComptes;
            }
          });
        }
        // Si le reste est positif
        else if(math.isPositive(resteApresAutresComptes)){

          var nbWithMontant = 0;
          listCompteByBatiment.map(function(batiment){
            if(_.isArray(batiment.listCompte)){
              nbWithMontant = batiment.listCompte.reduce(function(nb, compte){
                if(!_.isNil(compte.montant)) nb += 1;
                return nb;
              }, nbWithMontant);
            }

            if(_.isArray(batiment.listRegroupement)) {
              batiment.listRegroupement.map(function(regroupement) {
                if (_.isArray(regroupement.listCompte)) {
                  nbWithMontant = regroupement.listCompte.reduce(function(nb, compte){
                    if(!_.isNil(compte.montant)) nb += 1;
                    return nb;
                  }, nbWithMontant);
                }
                if(_.isArray(regroupement.listRegroupement)) {
                  regroupement.listRegroupement.map(function(sousRegroupement) {
                    if (_.isArray(sousRegroupement.listCompte)) {
                      nbWithMontant = sousRegroupement.listCompte.reduce(function(nb, compte){
                        if(!_.isNil(compte.montant)) nb += 1;
                        return nb;
                      }, nbWithMontant);
                    }
                  });
                }
              });
            }
          });

          // Si il y a un montant sur chaque compte (correction)
          if(nbWithMontant === nbCompte){
            execOnEachCompte(listCompteByBatiment, function(compte){
               // Si seulement un compte et pas modifié à la main, on le modifie
               if(nbCompte === 1 && !compte.edited){
                if(!_.isNil(compte.montant)) compte.montant = math.floor(math.add(compte.montant, resteApresAutresComptes),2);
                else compte.montant = angular.copy(resteApresAutresComptes);
              }
              else {
                // Reste qui prend en compte tous les comptes
                compte.montantCorrection = math.floor(math.add(resteApresAutresComptes, compte.montant),2);
              }
            });
          }
          // Si au moins un compte pas renseigné
          else{
            // Si il manque seulement un compte on lui ajoute le reste (set les montants)
            if(nbWithMontant === nbCompte-1){
              execOnEachCompte(listCompteByBatiment, function(compte){
                if(_.isNil(compte.montant)) compte.montant = angular.copy(resteApresAutresComptes);
             });
            }
            // Si il manque plus d'un compte à renseigner
            else{
              execOnEachCompte(listCompteByBatiment, function(compte){
                if(_.isNil(compte.montant)) compte.montantCorrection = angular.copy(resteApresAutresComptes);
                else compte.montantCorrection = math.floor(math.add(resteApresAutresComptes, compte.montant),2);
             });
            }
          }

          // On récupère le reste qui doit être à 0
          reste = getReste(reste, listCompteByBatiment);

          /*


          // Si il y a un montant sur chaque compte (correction)
          if(nbWithMontant === nbCompte){
            execOnEachCompte(listCompteByBatiment, function(compte){
              //compte.montantCorrection = math.floor(math.add(reste, compte.montant),2);
              if(!_.isNil(compte.montant) && compte.montant != reste) compte.montantCorrection = angular.copy(reste);
            });
          }
          // Si au moins un compte pas renseigné
          else{
            // Si on ne veux pas réattribue le reste
            if(!noAttributReste){
              // Si il manque seulement un compte on lui ajoute le reste (set les montants)
              if(nbWithMontant === nbCompte-1){

                execOnEachCompte(listCompteByBatiment, function(compte){
                  if(_.isNil(compte.montant)) {
                    delete compte.edited;
                    compte.montant = angular.copy(reste);
                  }
                });

                // On récupère le reste qui doit être à 0
                reste = getReste(reste, listCompteByBatiment);
              }
            }
          }
          */
        }
      }

      return reste;
    }

    /**
     * Créé une hiérarchie à partir d'une liste de compte
     * @param {*} listCompte
     * @returns
     */
    function listCompteToListCompteByRegroupement(listCompte, listExclude){
      if(!_.isArray(listCompte) || _.isEmpty(listCompte)) return [];

      return listCompte.reduce(function(listBatiment, itemCompte){

        if(_.isObject(itemCompte.batiment)){

          var compte = angular.copy(itemCompte);
          // Si fait parti de la liste à eclure
          if(_.findIndex(listExclude, {
            CCCCCC: compte.getCCCCCC(),
            BB: compte.getBB()
          }) !== -1) return listBatiment;

          var batimentOfCompte = compte.batiment;
          delete batimentOfCompte.listRegroupement;
          delete batimentOfCompte.listCompte;

          var regroupementOfCompte = compte.regroupement;
          if(_.isObject(regroupementOfCompte)){
            delete regroupementOfCompte.listRegroupement;
            delete regroupementOfCompte.listCompte;
          }

          var sousRegroupementOfCompte = compte.sousRegroupement;
          if(_.isObject(sousRegroupementOfCompte)){
            delete sousRegroupementOfCompte.listRegroupement;
            delete sousRegroupementOfCompte.listCompte;
          }

          var indexBatiment = _.findIndex(listBatiment, {BB: batimentOfCompte.getBB()});
          // Si le batiment est déjà dans la liste
          if(indexBatiment !== -1) batimentOfCompte = listBatiment[indexBatiment];
          // Si pas dans la liste
          else listBatiment.push(batimentOfCompte);

          if(_.isObject(regroupementOfCompte)){

            // Si pas encore de list de regroupement dans le batiment on l'ajoute
            if(!_.isArray(batimentOfCompte.listRegroupement)) batimentOfCompte.listRegroupement = [];

            // Recherche le regroupement dans la liste des regroupements du batiment
            var indexRegroupement = _.findIndex(batimentOfCompte.listRegroupement, {RR: regroupementOfCompte.getRR()});
            // Si le regroupement est déjà dans la liste
            if(indexRegroupement !== -1) regroupementOfCompte = batimentOfCompte.listRegroupement[indexRegroupement];
            // Si pas dans la liste
            else{
              regroupementOfCompte.listCompte = [];
              batimentOfCompte.listRegroupement.push(regroupementOfCompte);
            }

            // Si il y a un sous regroupement
            if(_.isObject(sousRegroupementOfCompte)){

              // Si pas encore de list de sous regroupement dans le regroupement du batiment on l'ajoute
              if(!_.isArray(regroupementOfCompte.listRegroupement)) regroupementOfCompte.listRegroupement = [];

              // Recherche le sous regroupement dans la liste des sous regroupements du regroupement du batiment
              var indexSousRegroupement = _.findIndex(regroupementOfCompte.listRegroupement, {SS: sousRegroupementOfCompte.getSS()});

              // Si le sous regroupement est déjà dans la liste
              if(indexSousRegroupement !== -1) sousRegroupementOfCompte = regroupementOfCompte.listRegroupement[indexSousRegroupement];
              // Si pas dans la liste
              else{
                sousRegroupementOfCompte.listCompte = [];
                regroupementOfCompte.listRegroupement.push(sousRegroupementOfCompte);
              }
              // On l'ajoute si le compte n'est pas déjà dans la liste des comptes du batiment
              if(_.findIndex(sousRegroupementOfCompte.listCompte, {CCCCCC: compte.getCCCCCC()}) === -1)
                sousRegroupementOfCompte.listCompte.push(compte);
            }
            // Ajout du compte dans le regroupement
            else {
              // On l'ajoute si le compte n'est pas déjà dans la liste des comptes du batiment
              if(_.findIndex(regroupementOfCompte.listCompte, {CCCCCC: compte.getCCCCCC()}) === -1)
                regroupementOfCompte.listCompte.push(compte);
            }
          }
          else{

            // Si pas encore de list de compte dans le batiment on l'ajoute
            if(!_.isArray(batimentOfCompte.listCompte)) batimentOfCompte.listCompte = [];

            // On l'ajoute si le compte n'est pas déjà dans la liste des comptes du batiment
            if(_.findIndex(batimentOfCompte.listCompte, {CCCCCC: compte.getCCCCCC()}) === -1)
              batimentOfCompte.listCompte.push(compte);
          }
        }
        return listBatiment;
      }, []);
    }

    /**
     * Extrait les comptes d'une hierarchie
     * @param {*} obj
     * @param {*} listCompte
     * @returns
     */
    function extractComptes(obj, listCompte){
      if(_.isNil(listCompte)) listCompte = [];
      if(_.isObject(obj)){
        if(_.isArray(obj)){
          obj.map(function(subObj){
            listCompte = extractComptes(subObj, listCompte);
          });
        }
        // Si object mais pas un array
        else{
          if(_.isArray(obj.listCompte)){
            listCompte = obj.listCompte.reduce(function(listSelectedCompte, compte){

               if(_.findIndex(listSelectedCompte, {
                  CCCCCC: compte.getCCCCCC(),
                  BB: compte.getBB()
                }) === -1) listSelectedCompte.push(compte);

              return listSelectedCompte;
            }, listCompte);
          }

          if(_.isArray(obj.listRegroupement)){
            listCompte = extractComptes(obj.listRegroupement, listCompte);
          }

          if(_.isArray(obj.listBatiment)){
            listCompte = extractComptes(obj.listBatiment, listCompte);
          }
        }
      }
      return listCompte;
    }

    /**
     * Converti la hierarchie par batiment en simple liste de compte
     * @returns
     */
    function listCompteByRegroupementToListSelectedCompte(selectedComptes, listCompteByBatiment){
      if(_.isArray(listCompteByBatiment) && listCompteByBatiment.length){
        selectedComptes = extractComptes(listCompteByBatiment, selectedComptes);
      }
      return selectedComptes;
    }

  }
})();
