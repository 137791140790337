(function() {

  'use strict';

  /**
   * @desc Composant pour une ligne model
   * @example <modele-ligne></modele-ligne>
   */
  angular
    .module('collaboreApp')
    .component('modeleLigne',{
      templateUrl: 'app/components/modele-ligne/modele-ligne.component.html',
      bindings : {
        modele: '<',
        listModele: '<',
        onEmit: '&'            // Permet de notifier le composant parent
      },
      controllerAs: 'modelelignectrl',
      controller: ModeleLigneController
    });

  /*@ngInject*/
  function ModeleLigneController(ErreurCollabService){

    var _this = this;
    _this.loading = false;
    _this.onEmitFromModeleLigneComponent = onEmitFromModeleLigneComponent;

    _this.$onInit = function() {
      if(_.isNil(_this.modele) && _.isNil(_this.listModele)) {
        ErreurCollabService.logErreur("Il manque le modele");
        return;
      }
      if(_.isNil(_this.listModele) && !_.isNil(_this.modele)) _this.listModele = [_this.modele];
    };

    /**
     * Lors de changements depuis le parent
     * @param changes
     */
    _this.$onChanges = function (changes) {
      console.log(changes);
      if(_.isObject(changes)) {
        if(_.isObject(changes.modele) && !changes.modele.isFirstChange()) {
          _this.modele = angular.copy(changes.modele.currentValue);
        }
        if(_.isObject(changes.listModele) && !changes.listModele.isFirstChange()) {
          _this.listModele = angular.copy(changes.listModele.currentValue);
        }
      }
    };

    _this.$onDestroy = function(){
    };

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      _this.onEmit({obj: objRetour})
    }

    function onEmitFromModeleLigneComponent(obj){
      console.log(obj);
      try {
        if(!_.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');

      }
      catch(err) {
        ErreurCollabService.logErreur('[ModeleLigneController.onEmitFromModeleLigneComponent] - ' + err.message);
      }
    }
  }
})();
