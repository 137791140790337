(function() {

  'use strict';

  /**
   * @desc Composant de la recherche dans collab
   * @example <recherche-collab></recherche-collab>
   */
  angular
    .module('collaboreApp')
    .component('champRechercheCollab',{
      templateUrl: 'app/components/champ-recherche-collab/champ-recherche-collab.component.html',
      controllerAs: 'champrecherchecollabctrl',
      controller: ChampRechercheCollabCtrl
    });

  /** @ngInject */
  function ChampRechercheCollabCtrl($injector,COLLAB_CONF, $stateParams, $scope, $rootScope, $timeout, $state, TagService, FiltreService, UtilsService){
    var ctrl = this;

    ctrl.fastSearchEnabled = UtilsService.getParametrage('fastSearchEnabled');
    ctrl.searchByElasticSearchEnabled = $rootScope.paramsCollab.searchByElasticSearchEnabled;

    // Text du champ de recherche
    ctrl.mainSearchCollabore = '';

    var disableFreeSearch = ($rootScope.isICS && !ctrl.searchByElasticSearchEnabled);

    ctrl.optionsRecherche = [

      {
        libelle: '<i class="fa fa-tags"></i> Recherche par Tag',
        minilibelle: '<i class="fa fa-tags"></i> #tag',
        minilibellePhone: '<i class="fa fa-tags"></i>',
        value: COLLAB_CONF.TYPE_RECHERCHE_LIBRE_COLLAB_TAGS,
        order: 1,
        multi: true,
        checked: true,
        display: (disableFreeSearch) ? false : true
      },

      {
        libelle: '<i class="fa fa-file"></i> Recherche par Contenu',
        minilibelle: '<i class="fa fa-file"></i> Contenu',
        minilibellePhone: '<i class="fa fa-file"></i>',
        value: COLLAB_CONF.TYPE_RECHERCHE_LIBRE_COLLAB_MESSAGE,
        order: 2,
        multi: true,
        /*
        checked: (ctrl.searchByElasticSearchEnabled),
        display: (ctrl.searchByElasticSearchEnabled)
        */
        checked: false,
        display: false
      },
      {
        libelle: '<i class="fa fa-comments"></i> Recherche par Titre',
        minilibelle: '<i class="fa fa-comments"></i> Titre',
        minilibellePhone: '<i class="fa fa-comments"></i>',
        value: COLLAB_CONF.TYPE_RECHERCHE_LIBRE_COLLAB_TITRE,
        order: 3,
        multi: true,
        checked: true,
        display: (disableFreeSearch) ? false : true
      },
      {
        libelle: '<i class="fa fa-cog"></i> Recherche par Référence',
        minilibelle: '<i class="fa fa-cog"></i> Réf.',
        minilibellePhone: '<i class="fa fa-cog"></i>',
        value: COLLAB_CONF.TYPE_RECHERCHE_LIBRE_COLLAB_REF,
        order: 4,
        multi: false,
        checked: false,
        display: true
      }/*,
              {
                  'libelle': '<i class="fa fa-users"></i> Recherche par Utilisateur',
                  'minilibelle': '<i class="fa fa-users"></i> @utilisateur',
                  'value': COLLAB_CONF.TYPE_RECHERCHE_LIBRE_COLLAB_GROUPE,
                  'order': 5,
                  'multi': false,
                  'checked': false,
                  'display': true
              }*/
    ];

    // Si ICS
    if($rootScope.isICS) {
      ctrl.optionsRecherche[0].checked = false;
      ctrl.optionsRecherche[1].checked = false;
      ctrl.optionsRecherche[2].checked = false;
      ctrl.optionsRecherche[3].checked = true;
    }
    // Si pas ICS
    else {
      ctrl.optionsRecherche[0].checked = true;
      //ctrl.optionsRecherche[1].checked = (ctrl.searchByElasticSearchEnabled);
      ctrl.optionsRecherche[1].checked = false;
      ctrl.optionsRecherche[2].checked = true;
      ctrl.optionsRecherche[3].checked = false;
    }



    //ctrl.onToggle = onToggle;
    ctrl.onFocusInputSearch = onFocusInputSearch;
    ctrl.onSelectTypeRecherche = onSelectTypeRecherche;
    ctrl.onSubmitSearchCollabore = onSubmitSearchCollabore;

    ctrl.$onInit = function() {
      $('.dropdown-menu-header-search').click(function(e) {
        e.stopPropagation();
      });

      // Recup le filtre venant de l'url
      var filtre = FiltreService.parseFiltreFromUrl($stateParams, true);
      // Si il y a une recherche elastic search alors set la valeur dans le champ de recherche
      if(_.isObject(filtre) && _.isObject(filtre.rechercheElasticsearch) && !_.isNil(filtre.rechercheElasticsearch.query)) {
        ctrl.mainSearchCollabore = filtre.rechercheElasticsearch.query;
      }
      ctrl.optionsRechercheMiniLibelle = generateMiniLibelle();

      /**
       * Watch la variable de scope "inputSearch"
       */
      /*
      $scope.$watch('mainSearchCollabore',function(newVal){

          if(UtilsService.startsWith(newVal,'#')){
              ctrl.onSelectTypeRecherche(ctrl.optionsRecherche[0], true);
          }else if(UtilsService.startsWith(newVal,'@')){
              ctrl.onSelectTypeRecherche(ctrl.optionsRecherche[4], true);
          }
      });*/

      if(!ctrl.fastSearchEnabled || ctrl.fastSearchEnabled == null) {
        onSelectTypeRecherche(ctrl.optionsRecherche[2], true);
      }
    };

    /*
    function onToggle(open){
      // Si close
      if(!open && ctrl.inputSearchFocus) {
        $timeout(function(){
          var $window = $injector.get('$window');
          var element = $window.document.getElementById("inputRechercheCollab");
          if(element) element.focus();
        });
      }
    }*/

    function onFocusInputSearch(event){

      /*
      if(ctrl.dropdownMenuIsOpen) {
        $timeout(function(){
          var $window = $injector.get('$window');
          var element = $window.document.getElementById(event.target.id);
          if(element) element.focus();
          ctrl.inputSearchFocus=true
        }, 100);
      }
      else {*/
        ctrl.inputSearchFocus=true
      //}
    }

    /**
     * Genere le mini libelle en fonction des cases cochées
     */
    function generateMiniLibelle() {
      var minilibelle = '';

      for (var i = 0; i < ctrl.optionsRecherche.length; i++) {
        if (ctrl.optionsRecherche[i].checked) {
          minilibelle += ((minilibelle.length === 0) ? '' : ' + ') + (($rootScope.notMobile()) ? ctrl.optionsRecherche[i].minilibelle : ctrl.optionsRecherche[i].minilibellePhone);
        }
      }
      return minilibelle;
    }

    /**
     * Lors de la selection du type de recherche
     * @param option
     */
    function onSelectTypeRecherche(option, only, event){
      if(only == null){
        only = true;
      }
      var nbChecked = 0;
      //ctrl.rechercheType = option.value;
      if (!option.multi || only) {
        for (var i = 0; i < ctrl.optionsRecherche.length; i++) {
          ctrl.optionsRecherche[i].checked = false;
        }

        ctrl.optionsRecherche[ctrl.optionsRecherche.indexOf(option)].checked = true;

        nbChecked++;
        //ctrl.optionsRechercheSelected = [option];
      } else {
        for (var j = 0; j < ctrl.optionsRecherche.length; j++) {
          if (!ctrl.optionsRecherche[j].multi)
            ctrl.optionsRecherche[j].checked = false;

          if(ctrl.optionsRecherche[j].checked) nbChecked++;
        }

        if(ctrl.optionsRecherche[ctrl.optionsRecherche.indexOf(option)].checked) nbChecked--;

        ctrl.optionsRecherche[ctrl.optionsRecherche.indexOf(option)].checked = !ctrl.optionsRecherche[ctrl.optionsRecherche.indexOf(option)].checked;

      }

      if(nbChecked===0) {
        $timeout(function(){
          ctrl.optionsRecherche[ctrl.optionsRecherche.indexOf(option)].checked = true;
          ctrl.optionsRechercheMiniLibelle = generateMiniLibelle();
        });
      }
      else ctrl.optionsRechercheMiniLibelle = generateMiniLibelle();

    }

    /**
     * Lors de la soumission de la recherche
     */
    function onSubmitSearchCollabore(){
      var valueArray = [];

      if(_.isEmpty(ctrl.mainSearchCollabore)) {
        return
      }

      for (var i = 0; i < ctrl.optionsRecherche.length; i++)
        if (ctrl.optionsRecherche[i].checked)
          valueArray.push(ctrl.optionsRecherche[i].value);


      var objRecherche = {
        newRecherche: true,
        paramRechercheGenerale: {
          search: ctrl.mainSearchCollabore,
          option: valueArray,
          currentPage: 1,
          currentLimit: 10
        }
      };

      //if(ctrl.fastSearchEnabled === true && valueArray.indexOf(2) < 0 && valueArray.indexOf(4) < 0) {
      if(valueArray.indexOf(2) < 0 && valueArray.indexOf(4) < 0) {

        var searchByElasticSearchEnabled = UtilsService.getParametrage('searchByElasticSearchEnabled');

        var params = {
          isActive: true,
          query: ctrl.mainSearchCollabore,
          tri: (searchByElasticSearchEnabled) ? COLLAB_CONF.DEFAULT_TRI_RECHERCHE_LIBRE_COLLAB : COLLAB_CONF.DEFAULT_TRI_RECHERCHE_COLLAB,
          page: COLLAB_CONF.DEFAULT_RECHERCHE_COLLAB_PAGE,
          nbpp: COLLAB_CONF.DEFAULT_RECHERCHE_COLLAB_NBPP
        };

        params.type = (valueArray.indexOf(3) >= 0) ? '1' : '0';
        params.type += (valueArray.indexOf(1) >= 0) ? '1' : '0';
        params.type += (valueArray.indexOf(5) >= 0) ? '1' : '0';

        $rootScope.current.filtreTopics.rechercheElasticsearch = params;
        $rootScope.current.filtreTopics.idChannel = -1;
        $state.go('topics.recherche.tous', FiltreService.getObjFiltreByRechercheElasticsearch(params), {location:true, reload:true, inherit:true});
        //$state.go('rechercheCollab', objRecherche, {location:true, reload:false, inherit:false});
      }
      else {
        objRecherche.paramRechercheGenerale.option = valueArray[0];
        $rootScope.current.rechercheGenerale.paramRechercheGenerale = objRecherche.paramRechercheGenerale;
        $state.go('recherche',objRecherche,{'location':true,'reload':false,'inherit':false});
      }
    }

  }
})();
