'use strict';

/**
 * @ngdoc service
 * @name collaboreApp.Params
 * @description
 * # Params
 * Service in the collaboreApp.
 */
var ParamsService= function(COLLAB_CONF,$q,ParamsAjax) {

	var vm = this;

	function initParamsService(){
		vm.portefeuille = {};
	}
	initParamsService();

	/**
	 * Recupère les infos d'un portefeuille avec son nom
	 * @params {string} key
	 * @returns {Object} Promesse avec objet du portefeuille
	 */
	vm.get = function(key) {

		var deferred = $q.defer();

		var paramsAjax = new ParamsAjax();
		paramsAjax.cle = key;
		paramsAjax.$get(function(portefeuilleObj){

		//ParamsAjax.get({cle:key},function(portefeuilleObj){


			// Si portefeuille OK
			if(portefeuilleObj.nb==='1') {
				deferred.resolve(portefeuilleObj.Portefeuilles[0]);
				//console.log(portefeuilleObj.Portefeuilles[0].nom);
				vm.portefeuille = portefeuilleObj;
			}
			else {
				deferred.reject('Pas de portefeuille trouvé');
			}

		},function(error){
			var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
			deferred.reject('Problème avec ParamsService.get(), '+msgErreur);
		});

		return deferred.promise;
	};

	vm.getPortefeuille = function() {
		return vm.portefeuille;
	};
/*
	this.addNewPortefeuille = function(nom, cle) {

	};

	this.deleteThisPortefeuille = function(nom, cle) {

	};*/

	vm.sendNewPwd = function(curPwd, newPwd){
		var deferred = $q.defer();

		var paramsAjax = new ParamsAjax();
		paramsAjax.oldPassword = curPwd;
		paramsAjax.newPassword = newPwd;
		paramsAjax.$updateNewPassword(function(password){
			if (password.Resultats[0].success) {
				deferred.resolve(password);
			}
			else {
				deferred.reject(password);
			}
		//ParamsAjax.get({cle:key},function(portefeuilleObj){


			// Si portefeuille OK
/*			if(==='1') {
				deferred.resolve('Mot de passe mis à jour !');
			}
			else {
				deferred.reject('Ancien mot de passe incorrect !');
			}*/

		},function(error){
			var msgErreur = 'Erreur ('+error.status+') : '+error.statusText;
			deferred.reject('Problème avec ParamsService.sendNewPwd(), '+msgErreur);
		});

		return deferred.promise;
	};


	/**
	 * Permet d'ajouter un SMTP
	 * @param obj
	 * @returns {IPromise<T>}
     */
	vm.addSmtp = function(obj){

		var deferred = $q.defer();

		var params = {
			'host': obj.host,
			'port': obj.port,
			'auth': obj.auth,
			'tls': obj.tls,
			'userSmtp': obj.userSmtp,
			'mdp': obj.mdp
		};

		ParamsAjax.addSmtp(params,function(resultats){
			if(resultats.Resultats[0].success){
				deferred.resolve();
			}else{
				deferred.reject('Problème de retour ParamsService.addSmtp(), '+angular.toJson(resultats));
			}
		},function(error){


			var msgErreur = 'Problème avec ParamsService.addSmtp(),  Erreur ('+error.status+') : '+error.statusText;
			if(error.status===400){
				msgErreur = 'Connexion impossible, veuillez vérifier vos paramètres';
			}

			deferred.reject(msgErreur);
		});

		return deferred.promise;
	};



	vm.destroy = function(){
		initParamsService();
	};
	
};

angular
	.module('collaboreApp')
	.service('ParamsService', [
		'COLLAB_CONF',
		'$q',
		'ParamsAjax',
		ParamsService
	]);
