'use strict';

angular.module('collaboreApp').factory('Metierfournisseur',[
	function() {

		function Metierfournisseur(objetData) {

			if (objetData) {
				this.setData(objetData);
			}
		}

		Metierfournisseur.prototype = {


			isModel: true,
			isMetierfournisseur: true,

			setData: function(objetData) {
				angular.extend(this, objetData);
			},

			// idmetier
			getIdmetier: function(){
				return this.idmetier;
			},
			setIdmetier: function(val){
				this.idmetier = val;
			},

			// libellemetier
			getLibellemetier: function(){
				return this.libellemetier;
			},
			setLibellemetier: function(val){
				this.libellemetier = val;
			},

			// codemetier
			getCodemetier: function(){
				return this.codemetier;
			},
			setCodemetier: function(val){
				this.codemetier = val;
			},

			// codemetSEL
			getCodemetSEL: function(){
				return this.codemetSEL;
			},
			setCodemetSEL: function(val){
				this.codemetSEL = val;
			}


		};

		return Metierfournisseur;
	}
]);