'use strict';

/**
 * @ngdoc filter
 * @name collaboreApp.filter:collaboreFilter
 * @function
 * @description Filtre un array qui contient un tableau de tags par rapport à un tableau de tags donnée
 * # collaboreFilter
 * Filter in the collaboreApp.
 */

var filterByTag = function () {
	return function (arraySource,arrayTags) {

		var nbTagFiltre = arrayTags.length;
		// Si pas de tag dans le filtre retourne la source
		if(!nbTagFiltre){
			return arraySource;
		}


		var arrayRetour = [];

		// Parcour le tableau source
		angular.forEach(arraySource, function(itemSource) {

			var tagsTmp = [];

			// Si il y a des tags
			if(itemSource.tags.length){

				// Parcour la liste des tags de l'item source
				angular.forEach(itemSource.tags, function(tagItemSource,tagIndexSource) {

					// Parcour la liste des tags filtre
					angular.forEach(arrayTags, function(tagFiltre) {

						// Si lidTag de l'item source est égale à celui du tag filtre actuel
						if(tagItemSource.idTag===tagFiltre.idTag){
							tagsTmp.push(tagItemSource);
							itemSource.tags[tagIndexSource].selected = true;
						}
					});

				});

				var nbTagTrouve = tagsTmp.length;

				if(nbTagTrouve===nbTagFiltre){
					console.log(itemSource);
					arrayRetour.push(itemSource);
				}
			}
		});


		return arrayRetour;
	};
};

angular.module('collaboreApp').filter('filterByTag', [
	filterByTag
]);


/**
 * Renvoi le tag dans son badge
 * @param {Object/String} itemTag - objet tag ou libelle tag
 * @returns {String}
 */
/*
angular.module('collaboreApp').filter('parseTagType', [function() {
	return function (itemTag) {
		var libelle;

		// Si object tag
		if(angular.isObject(itemTag)){
			libelle = itemTag.libelle;
		// Si string tag
		}else if(angular.isString(itemTag)){
			libelle = itemTag;
		}

		return '<span class="badge badge-tag badge-bordered badge-rouge-collabore tag-text">'+libelle+'</span>';

	};
}]);*/
