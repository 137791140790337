(function() {

  'use strict';

  /**
   * @desc Componsant utilisé dans le composant choose-groupe
   * @example <choose-groupe-ligne></choose-groupe-ligne>
   */
  angular
    .module('collaboreApp')
    .component('chooseGroupeImmeubleLigne',{
      /*
      require: {
        chooseGroupeCtrl: '^chooseGroupe'
      },*/
      templateUrl: 'app/components/choose-groupe-immeuble/choose-groupe-immeuble-ligne.component.html',
      bindings : {
        groupe: '<',
        idGroupePortefeuille: '<',
        onCheck: '&'
      },
      controllerAs: 'choosegroupeimmeublelignectrl',
      controller: ChooseGroupeImmeubleLigneController
    });

  /*@ngInject*/
  function ChooseGroupeImmeubleLigneController($sce, uuid){

    var _this = this;
    _this.onCheckGroupe = onCheckGroupe;          // Lorsqu'on coche ou décoche le groupe
    _this.getClassIcon = getClassIcon;

    _this.$onInit = function() {
      _this.uuid = uuid.new();  // Identifiant unique pour les id des label et input
    };

    /**
     * Permet de récupérer l'icon de fontawesome par rapport au type de groupe
     * @returns {string}
     */
    function getClassIcon(){
      var icon = null;

      if(_this.groupe.isUser()) icon = 'fa-user';
      else if(_this.groupe.isImmeuble()) icon = 'fa-building-o';
      return icon;
    }

    /**
     * Permet de notifié au composant parent l'état du groupe actuel
     */
    function emit(){
      _this.onCheck({
        groupeChecked: {
          groupe: _this.groupe,
          idGroupePortefeuille: _this.idGroupePortefeuille
        }
      });
    }

    /**
     * Lorsqu'on coche ou décoche le groupe. Repercute le changement sur les enfants
     */
    function onCheckGroupe(){
      emit();
    }
  }
})();
