(function () {
  'use strict';

  /**
   * @desc Gestion des associations
   * @example <add-associations></add-associations>
   */

  angular
    .module('collaboreApp')
    .component('recapAssociations', {
      templateUrl: 'app/parametrage/gestion-associations/recap-associations.component.html',
      bindings: {
        mode: '@',    // 'add' ou 'remove'
        type: '<',
        groupes: '<',   // Liste des groupe à asocier (immeuble et prop)
        tags: '<',      // Tags immeuble ou prop qui vont servir à récupérer le groupe si "groupes" n'est pas rensiegné
        adminVersion: '<',
        idPortefeuille: '<',
        onEmit: '&',

        // Si Modal
        resolve: '<',
        close: '&',
        dismiss: '&',
        modalInstance: '<'
      },
      controllerAs: 'recapassocctypectrl',
      controller: RecapAssociationsController
    });

  /*@ngInject*/
  function RecapAssociationsController($q, GroupeAssociationService, UtilsService, ModalsService, ErreurCollabService) {

    var _this = this;
    _this.loadingComponent = false;
    _this.errorComponent = null;
    _this.myGroupe = UtilsService.getCurrentUser();
    _this.libelleType = "";
    _this.listGroupeCollaborateur = [];             // Liste des collaborateurs choisi par l'admin pour être ajouté
    _this.listGroupeCollaborateurRemove = [];       // Liste des collaborateurs choisi par l'admin pour être retiré

    _this.onClickSave = onClickSave;     // Permet de sauvegarder
    _this.onClickClose = onClickClose;    // Si composant utilisé en modal alors ferme la modal
    _this.onChangeCollaborateur = onChangeCollaborateur;
    _this.onEmitFromGestionAssociationsLigneComponent = onEmitFromGestionAssociationsLigneComponent;
    _this.onDeleteGroupeFromGroupeCollabComponent = onDeleteGroupeFromGroupeCollabComponent;

    _this.$onInit = function() {

      if(_this.resolve && _this.modalInstance) {
        _this.mode = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'mode'));
        _this.type = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'type'));
        _this.groupes = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'groupes'));
        _this.adminVersion = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'adminVersion'));
        _this.idPortefeuille = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'idPortefeuille'));
        _this.tags = angular.copy(UtilsService.getParamModalComponent(_this.resolve, 'tags'));
      }

      if(_.isNil(_this.idPortefeuille)) {
        _this.errorComponent = "Il manque le portefeuille !";
      }
      if(_.isNil(_this.groupes)) _this.groupes = [];


      if(_this.adminVersion && !_this.myGroupe.isAdmin()){
        _this.errorComponent = "Vous ne pouvez pas accéder à cette fonctionnalitée";
      }
      else {
        if(_.isObject(_this.groupes) && !_.isArray(_this.groupes)) {
          _this.groupes = [_this.groupes];
        }

        init();
      }
    };

    _this.$onChanges = function(changes) {
    };

    _this.$onDestroy = function(){
    };

    function init(){
      _this.titleComponent = "Récapitulatif";

      if(_this.mode === 'add') _this.titleComponent += " d'ajout";
      else if(_this.mode === 'remove') _this.titleComponent += " de suppression";

      if(_this.type === "immeuble") {
        _this.libelleType = "immeubles";
        _this.titleComponent += " des associations avec des immeubles";
      }
      else if(_this.type === "proprietaire") {
        _this.libelleType = "propriétaires";
        _this.titleComponent += " des associations des propriétaires";
      }

      _this.configScrollbar = {
        autoHideScrollbar: false,
        theme: 'dark-3',
        advanced: {
          updateOnContentResize: true
        },
        scrollButtons: {
          scrollAmount: 'auto', // scroll amount when button pressed
          enable: false // enable scrolling buttons by default
        },
        axis: 'y',
        //setHeight: 200,
        scrollInertia: 100
      };

      if(_this.groupes.length === 0 && _.isObject(_this.tags) ){
        var tags = [];
        if(_.isArray(_this.tags)) tags = _this.tags;
        else tags.push(_this.tags);

        var tasks = tags.map(function(tag){
          return tag.getGroupe();
        });

        _this.loadingComponent = true;
        $q.all(tasks)
          .then(function (groupes) {
            _this.groupes = groupes;
            initGroupes();
          })
          .finally(function(e){
            _this.loadingComponent = false;
          });
      }
      else initGroupes();
    }

    function initGroupes(){
      _this.groupes = _.sortBy(_this.groupes, ['groupeInformations.numero']);

      if(_this.mode === 'remove' || (_this.mode === 'add' && _this.groupes.length === 1)) {
        _this.listGroupeCollaborateur = _this.groupes.reduce(function(arrayGroupe, groupe) {
          if(_.isObject(groupe) && _.isObject(groupe.getGroupeAssociation())) {

            var groupeAssociation = groupe.getGroupeAssociation();
            if(_.isArray(groupeAssociation.getListGroupeAssociationGroupe()) && groupeAssociation.getListGroupeAssociationGroupe().length){

              groupeAssociation.getListGroupeAssociationGroupe().reduce(function(accumGroupe, groupeAssociationGroupe){

                if(_.findIndex(accumGroupe, { 'idGroupe': groupeAssociationGroupe.getGroupe().getIdGroupe() }) === -1){

                  var groupeCollaborateur = groupeAssociationGroupe.getGroupe()
                  if(_this.mode === 'add' && _this.groupes.length === 1){
                    groupeCollaborateur.disabledCheckbox = true;
                  }
                  accumGroupe.push(groupeCollaborateur);
                }

                return accumGroupe;
              }, arrayGroupe)
            }
          }
          return arrayGroupe;
        }, []);
        //console.log(_this.listGroupeCollaborateur);
      }
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      if(_this.modalInstance) _this.close({$value: objRetour});
      else _this.onEmit({obj: objRetour});
    }

    // ----------------- Event -------------------//

    function onClickSave(){
      var groupesChecked = [];
      if(_this.groupes.length) {
        if(_this.groupes.length > 1){
          groupesChecked = _this.groupes.reduce(function(array, groupe){
            if(groupe.checked) array.push(groupe);
            return array;
          }, []);
        }
        else groupesChecked.push(_this.groupes[0]);
      }

      var groupes = [];
      if(!_this.adminVersion){
        groupes.push(_this.myGroupe);
        if(!groupes.length) {
          ModalsService.alertErreur("Il y a un problème, voir les logs");
          ErreurCollabService.logErreur('RecapAssociationsController', _this);
        }
      }
      else {
        if(_this.mode === 'add'){
          groupes = _this.listGroupeCollaborateur;
          if(!groupes.length) ModalsService.alertErreur("Vous devez choisir des collaborateurs");
        }
        else if(_this.mode === 'remove'){
          groupes = _this.listGroupeCollaborateurRemove;
          if(!groupes.length) ModalsService.alertErreur("Vous devez choisir des collaborateurs");
        }
      }
      if(!groupesChecked.length) ModalsService.alertErreur("Vous devez choisir des " + _this.libelleType);

      if(groupes.length && groupesChecked.length) {

        _this.loadingComponent = true;
        if(_this.mode === 'add'){
          GroupeAssociationService
            .associeListIdGroupeWithListIdGroupeAssocie(_this.idPortefeuille, _this.type, groupes, groupesChecked, _this.adminVersion)
            .then(function(listGroupe){
              emit({
                action: "updateList",
                groupes: listGroupe
              });
            })
            .finally(function(){
              _this.loadingComponent = false;
            });
        }
        else if(_this.mode === 'remove'){
          GroupeAssociationService
            .removeListGroupeFromListAssociation(_this.type, groupes, groupesChecked, _this.adminVersion)
            .then(function(listGroupe){
              emit({
                action: "updateList",
                groupes: listGroupe
              });
            })
            .catch(function(e){
              ModalsService.alertErreur(e);
            })
            .finally(function(){
              _this.loadingComponent = false;
            });
        }

        /*
        emit({
          action: _this.mode,
          groupes: groupes,
          groupesAssocie: groupesChecked
        });*/
      }
    }

    /**
     * Si ouvert en mode Modal
     */
    function onClickClose(){
      if(_this.modalInstance) _this.dismiss({$value: 'cancel'});
      else emit({action: 'closeRecapAssociations'});
    }

    // ----------------- Actions Servlet -------------------//


    // ----------------- Actions -------------------//

    /**
     * Permet de mettre à jour un objet groupe dans la liste actuel
     * @param groupe
     */
    function updateGroupeInList(groupe){
      if(_this.resultatGroupe.list.length) {
        if(!_.isArray(groupe)) {
          for(var i = 0; i < _this.resultatGroupe.list.length; i++){
            var currentGroupe = _this.resultatGroupe.list[i];
            if(_.isObject(currentGroupe)) {

              if(currentGroupe.getIdGroupe() === groupe.getIdGroupe()){
                currentGroupe = groupe;
                break;
              }
            }
          }
        }
      }
    }

    function addGroupeToList(groupe, list){
      if(_.findIndex(list, { 'idGroupe': groupe.getIdGroupe() }) === -1){
        list.push(groupe);
      }
    }
    /**
     * Permet de retirer un groupe de la liste "listGroupeCollaborateur"
     * @param groupeRemove
     */
    function removeGroupeFormList(groupeRemove, list){
      // Retire le groupe de la liste
      _.remove(list, function(groupe){
        return (groupe.getIdGroupe() === groupeRemove.getIdGroupe());
      });
    }

    // ----------------- Divers manipulations de données -------------------//


    // ----------------- OnEmit -------------------//

    /**
     * Lorsque l'on choisi des destinataires
     * @param obj
     */
    function onChangeCollaborateur(obj){
      try {
        if (!_.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');

        // Liste des perso avec email checked venant du choose infos contact
        if(_.isArray(obj.collaborateurs)) _this.listGroupeCollaborateur = obj.collaborateurs;
      }
      catch (err) {
        ErreurCollabService.logErreur('[RecapAssociationsController.onChangeCollaborateur] Erreur : ',  err.message);
      }
    }

    /**
     * Lorsqu'il y a une remontée d'info du composant gestion-associations-ligne
     * @param obj
     */
    function onEmitFromGestionAssociationsLigneComponent(obj) {
      try {
        if (!_.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');

        if(obj.action === 'checkUncheckAssoc')
          updateGroupeInList(obj.groupe);
      }
      catch (err) {
        ErreurCollabService.logErreur('[RecapAssociationsController.onEmitFromGestionAssociationsLigneComponent] Erreur : ',  err.message);
      }
    }

    /**
     * Lorsqu'il y a une remontée d'info du composant groupe-collab
     * @param obj
     */
    function onDeleteGroupeFromGroupeCollabComponent(obj, cancel) {
      try {
        if (!_.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');
        if (!_.isObject(obj.groupe)) throw new Error('L\'objet "obj" doit avec un groupe');

        // Si on annule alors on le remet dans liste normal et on le supprime de celle des groupe remove
        if(cancel) {
          removeGroupeFormList(obj.groupe, _this.listGroupeCollaborateurRemove);
          addGroupeToList(obj.groupe, _this.listGroupeCollaborateur);
        }
        else {
          removeGroupeFormList(obj.groupe, _this.listGroupeCollaborateur);
          addGroupeToList(obj.groupe, _this.listGroupeCollaborateurRemove);
        }
      }
      catch (err) {
        ErreurCollabService.logErreur('[RecapAssociationsController.onDeleteGroupeFromGroupeCollabComponent] Erreur : ',  err.message);
      }
    }


  }
})();
