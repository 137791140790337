(function () {
  'use strict';

  /**
   * @desc Formulaire de contenu type Message
   * @example <contenu-message-formulaire></contenu-message-formulaire>
   */

  angular
    .module('collaboreApp')
    .component('contenuFormulaire', {
      transclude: true,
      require: {
        communicationComponentCtrl: '?^communication',
        contenuMessageComponentCtrl: '?^contenuMessage',
        contenuEvenementComponentCtrl: '?^contenuEvenement',
        layoutTopicDetailCtrl: '?^layoutTopicDetail'
      },
      template: '<div ng-transclude></div>',
      bindings: {
        parentContenu: '<',	// <Object> Contenu parent si réponse d'un message ou edition du dernier message
        defaultParams: '<',
        onUpdate: '&',		// <Function> Lors de la modification d'un ContenuMessage
        onSubmit: '&',		// <Function> Lors du submit d'un ContenuMessage
        onInsert: '&',		// <Function> Lors de l'insertion d'un ContenuMessage
        onError: '&',		// <Function> Lors d'une erreur
        onClickCommunication: '&'
      },
      controllerAs: 'contenuformulairectrl',
      controller: ContenuFormulaireController
    });

  /*@ngInject*/
  function ContenuFormulaireController($injector, $rootScope, $q, $timeout, COLLAB_CONF, COLLAB_VALUES, $state, $scope, sweet, ContenuMessage, ProxyContenuMessage, NotificationsToastServices, UtilsService, UserService, FileUploader, ContenuService) {

    var ctrl = this;

    ctrl.instanceCKEDITOR = false;			// Instance CKEDITOR pour l'objet
    ctrl.instanceFichesCKEDITOR = false;	// Instance CKEDITOR pour les fiches
    ctrl.signatures = [];
    ctrl.signaturePerso = null;
    ctrl.signatureSociete = null;
    ctrl.signatureSelected = null;
    ctrl.isNewTopic = false;

    ctrl.ckeditorOptions = {
      on: {
        instanceReady: function () {
          this.dataProcessor.htmlFilter.addRules({
            elements: {
              img: function (el) {
                var isBase64 = UtilsService.test(el.attributes.src, 'base64', 'i');
                if (isBase64) {
                  el.addClass('image-max-editor');
                }
              }
            }
          });
        },
        focus: function () {
          // Ferme le popover des tags si ouvert
          $rootScope.$broadcast('closePopoverTag');
          $rootScope.$broadcast('closeTooltipDestinataire');
        }
      }
    };


    // Destinataire
    //ctrl.groupeAll = null;      // Groupe All temporaire avec sa hiérarchie pour éviter de recupérer à chaque fois tant que le composant n'est pas destroy
    //ctrl.onEndGetGroupeAllFromDestinataireComponent = onEndGetGroupeAllFromDestinataireComponent;


    ctrl.ckeditorOnReady = ckeditorOnReady;	// Appelé lorsque le WYSIWYG est bien ready

    ctrl.focusTitle = focusTitle;						// Permet de focus le champ title
    ctrl.hasDefaultDestinataire = hasDefaultDestinataire;		// Permet de savoir si des destinataire temporaire doivent être ajoutés
    ctrl.addDefaultDestinataires = addDefaultDestinataires;		// Permet d'ajouter des destinataires
    ctrl.parseDestinataires = parseDestinataires;		// Parcour chaque destinataires pour créer un array avec le libelle et le dieze concaténé.
    ctrl.focusEndEditor = UtilsService.focusEndEditor;	// Permet de focus le WYSIWYG

    ctrl.isImage = isImage;
    //ctrl.getObjContenu = getObjContenu;					// Permet de re
    ctrl.sendNewMessage = sendNewMessage;				// Crée un nouveau message et le sauvegarde
    ctrl.notifySuccessSave = notifySuccessSave;			// Permet d'afficher une notification de success
    ctrl.sendContenu = sendContenu;						// Save le contenu
    ctrl.openCommunication = openCommunication;				// Ouvre la box communication

    ctrl.$onInit = function () {

      if (angular.isObject(ctrl.defaultParams) && ctrl.defaultParams.hauteurEditor) {
        // Options des WYSIWYG
        ctrl.ckeditorOptions.autoGrow_minHeight = ctrl.defaultParams.hauteurEditor;	// Hauteur
      }

      // Si le composant n'est pas chargé depuis un composant contenu message ou contenu evenement ou communication ou direct dans le layout detail ou pas de params par default, c'est un nouveau Topic
      if (!ctrl.communicationComponentCtrl && !ctrl.contenuMessageComponentCtrl && !ctrl.contenuEvenementComponentCtrl && !ctrl.layoutTopicDetailCtrl && !ctrl.defaultParams) {
        ctrl.isNewTopic = true;
      }
      else {

        ctrl.parentComponent = false;
        ctrl.topicDetailCtrl = false;

        if (angular.isObject(ctrl.defaultParams)) {
          if (ctrl.defaultParams.topicDetailCtrl) ctrl.topicDetailCtrl = ctrl.defaultParams.topicDetailCtrl;
          if (ctrl.defaultParams.topicStates) ctrl.topicStates = ctrl.defaultParams.topicStates;
          if (ctrl.defaultParams.idTopic) ctrl.idTopic = ctrl.defaultParams.idTopic;
        }

        // Si form appelé depuis un composant contenu-message.component
        if (ctrl.contenuMessageComponentCtrl) {
          ctrl.parentComponent = ctrl.contenuMessageComponentCtrl;
          ctrl.topicDetailCtrl = ctrl.contenuMessageComponentCtrl.layoutTopicDetailCtrl;
          ctrl.topicStates = ctrl.contenuMessageComponentCtrl.layoutTopicDetailCtrl.currentTopicStates;
        }
        // Si form appelé depuis un composant contenu-evenement.component
        else if (ctrl.contenuEvenementComponentCtrl) {
          ctrl.parentComponent = ctrl.contenuEvenementComponentCtrl;
          ctrl.topicDetailCtrl = ctrl.contenuEvenementComponentCtrl.layoutTopicDetailCtrl;
          ctrl.topicStates = ctrl.contenuEvenementComponentCtrl.layoutTopicDetailCtrl.currentTopicStates;
        }
        // Si form appelé depuis un composant communication.component
        else if (ctrl.communicationComponentCtrl) {
          if (ctrl.communicationComponentCtrl.layoutTopicDetailCtrl) {
            ctrl.parentComponent = ctrl.communicationComponentCtrl.layoutTopicDetailCtrl;
            ctrl.topicDetailCtrl = ctrl.communicationComponentCtrl.layoutTopicDetailCtrl;
            ctrl.topicStates = ctrl.communicationComponentCtrl.layoutTopicDetailCtrl.currentTopicStates;
          }
          else {
            ctrl.topicStates = ctrl.communicationComponentCtrl.topicState;
          }

        }
        // Si form appelé depuis le topic
        else if (ctrl.layoutTopicDetailCtrl) {
          ctrl.parentComponent = ctrl;
          ctrl.topicDetailCtrl = ctrl.layoutTopicDetailCtrl;
          ctrl.topicStates = ctrl.layoutTopicDetailCtrl.currentTopicStates;
        }

        if (ctrl.topicStates) ctrl.idTopic = ctrl.topicStates.getTopic().getIdTopic();

        if (ctrl.parentComponent) ctrl.parseObjetMessage = ctrl.parentComponent.parseObjetMessage;
      }

      //console.log('contenu-formulaire','$onInit');
    };

    ctrl.$onDestroy = function(){
      $rootScope.current.tmpNewEvenement.destinataires = [];

      var GroupeService = $injector.get('GroupeService');
      GroupeService.destroy();
    };
    /*
     $scope.$watchCollection('contenuformulairectrl.parentContenu',function(newVal){
     console.log('contenu-formulaire',newVal);
     });*/

    /************** Pieces jointes à migrer dans un component *******************/

    ctrl.uploader = new FileUploader({
      autoUpload: true,
      url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_INSERTION_DOCUMENTS_IN_GROUPE,
      alias: 'fileName',
      removeAfterUpload: false,

      filters: [{
        name: 'sizeLimit',
        fn: function (item) {
          //COLLAB_CONF.SIZE_LIMIT_UPLOAD
          if (item.size <= COLLAB_CONF.SIZE_LIMIT_UPLOAD) {
            return true;
          } else {
            sweet.show({
              title: '<span class="text-danger">' + item.name + '</span> trop gros !',
              text: '50 Mo Maximum',
              type: 'error',
              html: true
            });
          }
        }
      }]
    });

    // Après l'ajout d'un fichier en pièce jointe
    ctrl.uploader.onAfterAddingFile = function (fileItem) {
      fileItem.formData.push({idTopic: ctrl.idTopic});
      fileItem.formData.push({idGroupe: UserService.getGroupe().getIdGroupe()});
      fileItem.formData.push({token: UserService.getGroupe().getToken()});
    };

    ctrl.initNotifWaitUpload = function () {
      ctrl.notifWaitUpload = {
        'progress': 0,
        'obj': false,
        'show': false
      };
    };

    ctrl.initNotifWaitUpload();

    ctrl.uploader.onProgressAll = function (progress) {

      ctrl.notifWaitUpload.progress = progress;
      if (!ctrl.notifWaitUpload.show) {
        ctrl.notifWaitUpload.show = true;
        NotificationsToastServices.wait('Patientez', 'Upload des documents en cours...', function (notif) {
          ctrl.notifWaitUpload.obj = notif;
        });
      }
    };

    /**
     * Supprime une pièce jointe de la listre est de la BDD
     * @param {Object} fileItem - Item du fichier
     * @returns {void}
     */
    /*
     ctrl.removePiecesJointes = function(fileItem){

     // Si item venant de la liste d'upload
     if(angular.isDefined(fileItem._file)){

     // Si object document existe
     if(angular.isObject(fileItem._file.document)){

     var docObjet = fileItem._file.document;
     DocumentService.deleteDocumentPerso(docObjet).then(function(){

     // Supprime la pièce jointe de la liste
     $scope.uploader.removeFromQueue(fileItem);

     // Supprime le document des pièces jointes
     ctrl.piecesJointesContenu = UtilsService.removeWith(ctrl.piecesJointesContenu,{guid:docObjet.getGuid()});
     ctrl.piecesJointesExclus = UtilsService.removeWith(ctrl.piecesJointesExclus,{guid:docObjet.getGuid()});

     //$rootScope.$broadcast('refreshListeDocuments');

     },function(msg){
     console.log('Erreur formulaireContenu.removeFromQueue()');
     console.log(msg);
     });

     // Sinon supprime seulement le fichier de la liste d'upload
     }else{
     $scope.uploader.removeFromQueue(fileItem);
     }

     // Sinon si item venant de la GED perso
     }else if(fileItem.dateUpload){

     var guidFile = fileItem.guid;
     // Supprime le document des pièces jointes
     ctrl.piecesJointesContenu = UtilsService.removeWith(ctrl.piecesJointesContenu,{guid:guidFile});

     //$scope.documentsGed = $filter('removeWith')($scope.documentsGed,{guid:guidFile});
     }
     };*/

    /************** Fin Pieces jointes à migrer dans un component *******************/

    /**
     * Fonction d'édition du dernier contenu
     * Sauvegarde le message modifié
     */
    ctrl.updateContenuMessage = function () {

      var message = ctrl.instanceCKEDITOR.getData();

      // Si le message obligatoire n'est pas rempli
      if (angular.isUndefined(message) || message === '') {
        sweet.show('Oops...', 'Veuillez renseigner un message', 'error');
        return;
      }
      var instanceProxy = new ProxyContenuMessage(ctrl.parentContenu);
      instanceProxy.setMessage(message);
      //console.log(instanceProxy);
      ctrl.sendContenu(instanceProxy).then(function (retourObj) {

        if (retourObj.action === 'updateContenu') {

          var newMessage = new ContenuMessage(retourObj.obj);
          var idTopic = newMessage.getIdTopic();

          if ($state.includes('topics.detail')) {
            $state.go('topics.detail', {'topicId': idTopic}, {'reload': true});
          } else {
            $state.reload();
          }
        }
      }, function (error) {
        // Si erreur 403 alors le dernier message ne peux plus être modifié
        if (error.status === 403) {
          sweet.show({
            title: 'Oops...',
            text: 'Un autre message à été posté entre temps...',
            type: 'error',
            showCancelButton: false,
            confirmButtonText: 'Ok...',
            closeOnConfirm: true
          }, function () {
            // Reload la page pour afficher les nouveau contenu
            $state.go('topics.detail', {'topicId': ctrl.contenuMessageComponentCtrl.idTopic}, {'reload': true});
          });
        }
      });

    };

    /**
     * Permet de focus le champ title
     * @param val
     */
    function focusTitle(val) {
      if (val) {
        ctrl.titleFocus = true;
      }
      else {
        ctrl.titleFocus = false;
      }
    }

    function hasDefaultDestinataire() {
      if ($rootScope.current.tmpNewEvenement.destinataires.length) {
        return true;
      }
      return false;
    }

    function addDefaultDestinataires(destinataires) {

      $timeout(function () {
        // Si il y a des destinataires à ajouter en arrivant sur la page
        if ($rootScope.current.tmpNewEvenement.destinataires.length) {
          for (var g = 0; g < $rootScope.current.tmpNewEvenement.destinataires.length; g++) {
            var exist = false;
            if (destinataires.length) {
              for (var d = 0; d < destinataires.length; d++) {
                if (destinataires[d].hasOwnProperty('destinataire') && $rootScope.current.tmpNewEvenement.destinataires[g].hasOwnProperty('destinataire')) {
                  if (destinataires[d].destinataire === $rootScope.current.tmpNewEvenement.destinataires[g].destinataire) {
                    exist = true;
                    break;
                  }
                }
              }
            }
            if (!exist) {
              destinataires.push($rootScope.current.tmpNewEvenement.destinataires[g]);
            }
          }
          //$rootScope.current.tmpNewEvenement.destinataires = [];
        }
      });
    }

    /**
     * Parcour chaque destinataires pour créer un array avec le libelle et le dieze concaténé.
     * @param arrayDestinataires
     * @returns {Array}
     */
    function parseDestinataires(arrayDestinataires) {
      var retourArrayDestinataires = [];
      angular.forEach(arrayDestinataires, function (destinataire) {
        retourArrayDestinataires.push(destinataire.libelle + destinataire.dieze);
      });
      return retourArrayDestinataires;
    }

    /**
     * Appelé lorsque le WYSIWYG est bien ready
     * @param idContenu
     * @param isFiches
     */
    function ckeditorOnReady(idContenu, isFiches) {
      if (!idContenu) {
        idContenu = 0;
      }
      var instance = ctrl.instanceCKEDITOR;
      if (isFiches) {
        instance = ctrl.instanceFichesCKEDITOR;
        idContenu += '_Fiches';
      }
      // Ajout l'instance qui vien d'être créé dans le pool d'instance de CKEDITOR
      $rootScope._poolInstancesCKEDITOR[idContenu] = instance;
    }

    /**
     * Ajoute un contenu au topic actuel
     * @param {Object} formRepondre - Formulaire
     * @returns {void}
     */
    // TODO : Mettre cette fonction dans un service (OK, reste a voir la page fournisseur) (utilisé egalement pour la page fournisseur simplifiée)
    function sendContenu(proxyObj, titreTopic, cacher) {

      var deferred = $q.defer();

      var objTopic = false;
      if (ctrl.topicStates) {
        objTopic = ctrl.topicStates.getTopic();
      }

      ContenuService
        .sendContenu(proxyObj, objTopic, titreTopic, cacher)
        .then(function (objRetour) {

          if (objRetour.hasOwnProperty('action')) {

            if (objRetour.action === 'updateContenu') {

              if (ctrl.parentContenu) {
                // Mise à jour le contenu
                ctrl.parentContenu.setData(objRetour.obj);
                if (proxyObj.typeContenu === 1) {
                  ctrl.parentComponent.closeContenu(ctrl.parentContenu);
                }
              }

              // Met à jour le contenu dans le composant hote
              ctrl.onUpdate({
                objUpdate: {
                  contenu: objRetour.obj
                }
              });
              deferred.resolve(objRetour);
            }
            else if (objRetour.action === 'insertContenu') {
              // Ajoute le contenu dans le composant hote
              ctrl.onInsert({
                onInsert: {
                  insertContenu: objRetour.obj
                }
              });
              deferred.resolve(objRetour);
            }
            else if (objRetour.action === 'insertTopic') {
              // Ajoute le contenu dans le composant hote
              ctrl.onInsert({
                onInsert: {
                  insertTopic: objRetour.obj
                }
              });
              deferred.resolve(objRetour);
            }
            else {
              deferred.reject('Problème senContenu, action inconnu');
            }
          }
          else {
            deferred.reject('Problème senContenu, pas de propriété "action"');
          }
        }, null, function (val) {
          console.log(val);
        })
        .catch(function (error) {
          deferred.reject(error);
        })
        .finally(function () {

        }, function (val) { // Notify
          if (angular.isObject(val)) {
            if (val.hasOwnProperty('displayLoading')) {
              if (val.displayLoading) {
                if (proxyObj.isMessage) {
                  $scope.$emit('displayLoading', 'list');
                } else if (proxyObj.isEvenement) {
                  $scope.$emit('displayLoading', 'detail');
                } else {
                  $scope.$emit('displayLoading');
                }
              }
              else {
                $scope.$emit('hideLoading');
              }
            }
          }
        });
      return deferred.promise;
    }

    /**
     * Crée et sauvegarde un nouveau message
     * @param objMsg
     * @returns {IPromise<T>}
     */
    function sendNewMessage(proxyContenuMessage) {
      var deferred = $q.defer();

      //var contenuMsg = ContenuService.createNewMessage(objMsg);
      if (angular.isObject(proxyContenuMessage)) {
        sendContenu(proxyContenuMessage).then(function (retourObj) {
          deferred.resolve(retourObj);
        }, function (error) {
          sweet.show({
            title: 'Il y a eu une erreur...',
            text: error.statusText + ' (' + error.status + ')',
            type: 'error'
          });

          deferred.reject(error);
        });
      } else {
        deferred.reject('Pas de destinataires...');
      }
      return deferred.promise;
    }

    /**
     * Permet de faire une notification de success
     */
    function notifySuccessSave() {
      NotificationsToastServices.success('', '<strong>Sauvegardé !</strong>');
    }

    /**
     * Permet de tester si l'item est une image
     * @param item
     * @returns {boolean}
     */
    function isImage(item) {
      var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
      return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
    }

    function openCommunication() {
      if (ctrl.onClickCommunication) {
        ctrl.onClickCommunication();
      }
    }

    /*
    function onEndGetGroupeAllFromDestinataireComponent(groupeAll) {
      ctrl.groupeAll = groupeAll;
    }*/


    /*
    $scope.$on('$destroy', function () {
      $rootScope.current.tmpNewEvenement.destinataires = [];
    });*/

    /*
     function getObjContenu() {
     // Est utilisé pour la sauvegarde automatique dans le local storage
     var copyForSave = angular.copy($scope.itemContenu);
     copyForSave.setMessage($scope.itemContenu.message);
     return copyForSave;
     }*/

  }


})();
