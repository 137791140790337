(function () {
  'use strict';

  /**
   * @ngdoc factory
   * @name collaboreApp.timeoutHttpInterceptor
   * @description
   * # timeoutHttpInterceptor
   * Permet la modification des timeout pour les appels ajax
   */

  angular
    .module('collaboreApp')
    .factory('timeoutHttpInterceptor', TimeoutHttpInterceptor);

  /*@ngInject*/
  function TimeoutHttpInterceptor() {
    return {
      'request': function(config) {
        config.timeout = 600000; // 10 Minutes
        return config;
      }
    }
  }

})();
