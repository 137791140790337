(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.DemandeService
   * @description
   * # DemandeService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('DemandeService', DemandeService);

  /*@ngInject*/
  function DemandeService(MainService, $q, DemandeAjax, ModalsService) {

    var ctrl = this;

    ctrl.saveRelance = saveRelance;

    function postServlet(action, obj) {
      var deferred = $q.defer();
      try {
        if (!action) throw new Error('Le paramètre "action" n\'existe pas !');

        if (!_.isObject(obj) && !angular.isNumber(obj)) throw new Error('Le paramètre "obj" doit être un objet un un nombre !');
        if (_.isObject(obj) && !obj.hasOwnProperty('idContenu')) throw new Error('Le paramètre "obj" doit avoir un idContenu !');

        var sendParams = angular.merge({}, obj);
        sendParams.action = action;
        sendParams.idContenu = (_.isNumber(obj)) ? obj : ((obj.hasOwnProperty('idDemande')) ? obj.idDemande : obj.idContenu);

        DemandeAjax
          .post(sendParams, function (json) {
            var model = null;
            if(_.isObject(json)) {
              if(json.hasOwnProperty('devis')) model = 'ContenuEvenementDevis';
              else if(json.hasOwnProperty('contrat')) model = 'ContenuEvenementContrat';
            }
            deferred.resolve(MainService.convertJsonToModel(json, ['devis', 'contrat'], model, deferred));
          }, function (msg) {
            deferred.reject(msg);
          });
      }
      catch (e) {
        ModalsService.alertErreur(e.message);
        deferred.reject(e.message);
      }
      return deferred.promise;
    }

    /* Public Methods */

    /**
     * Permet de sauvegarder une relance
     * @param idDevis
     * @param idPeriode
     * @param enabled
     * @returns {angular.IPromise<T>}
     */
    function saveRelance(idContenu, idPeriode, enabled, relanceFromToday, doRelance){
      var deferred = $q.defer();
      var obj = {
        idContenu: idContenu,
        idPeriode: idPeriode,
        relanceAutoEnabled: (_.isNil(enabled)) ? false : enabled,
        relanceFromToday: (_.isNil(relanceFromToday)) ? false : relanceFromToday,
        doRelance: doRelance
      };
      postServlet('saveRelance', obj)
        .then(function (ret) {
          deferred.resolve(ret);
        })
        .catch(function (reason) {
          deferred.reject(reason);
        });
      return deferred.promise;
    }

  }

})();
