(function() {

  'use strict';

  /**
   * @desc Composant facture collab
   * @example <facture-collab></facture-collab>
   */
  angular
    .module('collaboreApp')
    .component('factureCollab',{
      templateUrl: 'app/facture-manager/facture-collab/facture-collab.component.html',
      controllerAs: 'facturecollabctrl',
      bindings: {
        idTopic: '<',
        idFacture: '<',
        onEmit: '&'
      },
      controller: FactureCollabCtrl
    });

  /** @ngInject */
  function FactureCollabCtrl(COLLAB_CONF, bowser, $q, $scope, $state, $stateParams, $sce, $timeout, UtilsService, TopicService, DocumentManagerService, ContenuService, ModalsService, GroupeService, FiltreService, ErreurCollabService){
    var _this = this;

    _this.myGroupe = UtilsService.getCurrentUser();

    _this.loadingFacture = false;         // Loading facture
    _this.currentPage = 1;             // Page actuelle (Facture)
    _this.listFacture = [];            // Liste des facture pour la liste déroulante
    _this.paramsComfactfacture = null;   // Objet avec comme clé l'idPortefeuille et comme valeur un objet avec configs et comptables

    _this.listExpediteur = [];
    _this.showChooseCollaborateur = false;

    _this.classPanelDevis = classPanelDevis;
    _this.onChangeFacture = onChangeFacture;
    _this.onClickCollapseDevisSigne = onClickCollapseDevisSigne;
    _this.onClickSearchFactures = onClickSearchFactures;
    _this.onClickRefreshFacture = onClickRefreshFacture;
    _this.onClickPreviousFacture = onClickPreviousFacture;
    _this.onClickNextFacture = onClickNextFacture;
    _this.onClickVoirTopic = onClickVoirTopic;
    _this.onClickCommunication = onClickCommunication;
    _this.onSubmitComfactfacture = onSubmitComfactfacture;
    _this.onEmitComfactfacture = onEmitComfactfacture;
    _this.onClickCloturer = onClickCloturer;
    _this.onChangeCollaborateur = onChangeCollaborateur;
    _this.toggleChooseCollaborateur = toggleChooseCollaborateur;
    _this.onRemoveDestinataire = onRemoveDestinataire;

    _this.$onInit = function() {
      /*
      console.log($state);
      console.log($stateParams);
      console.log('idTopic', _this.idTopic);
      console.log('idFacture', _this.idFacture);
      */

      _this.sendToComfactEnable = UtilsService.getParametrageComfact('allowSendToComfactfacture');
      if($stateParams.listGroupe && angular.isArray($stateParams.listGroupe)) _this.listExpediteur = $stateParams.listGroupe;
      if($stateParams.paramsComfactfacture) _this.paramsComfactfacture = $stateParams.paramsComfactfacture;

      initChannel();

      if(_this.idTopic) {
        _this.idTopic = parseInt(_this.idTopic);
        searchFactureIdTopic(_this.idTopic);
      }
      else if(_this.idFacture) {
        _this.idFacture = parseInt(_this.idFacture);
        searchFactureIdFacture(_this.idFacture);
      }
      else if($stateParams.page) searchFactureByPage($stateParams.page);
      else searchFactureByPage(_this.currentPage);

      $scope.$on('goToOtherFacture', function() {
        goToOtherFacture();
      });
    };

    /**
     * Initialisation des variables
     */
    function init(){
      _this.showChooseCollaborateur = false;

      _this.previousContenu = null;
      _this.nextContenu = null;
      _this.nbPreviousFacture = false;   // Nombre de facture précédente
      _this.nbNextFacture = false;       // Nombre de facture suivante
      _this.currentContenu = null;       // Facture actuelle
      _this.factureSelected = null;      // Facture séléctionnée dans la liste déroulante

      _this.currentTopic = null;         // Topic actuel
      _this.currentEvenement = null;     // Evenement actuel
      _this.currentDevis = null;         // Devis actuel
      _this.currentFacture = null;       // Facture actuel

      _this.messageEvenement = null;
      _this.fichesEvenement = null;

      _this.currentDocumentFacture = null;  // Document actuellement en consultation

      // PDF Devis
      _this.loadingPdfDevis = false;     // Loading du pdf devis
      _this.urlPdfDevis = null;          // Url du PDF devis signé ou devis pour ouvrir en lecture

      _this.objHasDocuments = {
        demandeDevis: null,
        devisSigne: null
      };
    }

    /**
     * Inialisation du channel
     * @param idChannel
     */
    function initChannel(idChannel) {
      var nomChannel = COLLAB_CONF.LIBELLE_CHANNEL_ALL;
      var channel = null;
      _this.currentIdChannel = -1;

      if(_.isNil(_this.idTopic)){
        // Si il y a "search" dans l'url
        if(_.isString($stateParams.search)) {
          // Recup l'objet de recherche par rapport au string de "search"
          var objFiltre = FiltreService.parseFiltreFromUrl($stateParams);
          // Si l'objet recup est bien un object avec idChannel dedans et différent de null ou undefined
          if(_.isObject(objFiltre) && !_.isNil(objFiltre.idChannel)) {
            // Recup l'idChannel de l'url
            _this.currentIdChannel =  objFiltre.idChannel;
          }
        }

        // Si pas de channel dans "search" alors on va chercher celui dans le filtre principal
        if(_.isNil(_this.currentIdChannel)) {
          _this.currentIdChannel = FiltreService.filtreIsChannel();
        }

        channel = UtilsService.getCurrentUser().getChannel(_this.currentIdChannel);
        if (!_.isNil(channel)) {
          nomChannel = channel.nom;
        }

        _this.nomChannel = nomChannel;
      }
    }

    function emit(obj){
      _this.onEmit({obj: obj});
    }

    /**
     * Permet de récupérer une facture par un numéro de page
     * @param nPage
     */
    function searchFactureByPage(nPage){
      searchFacture({page:nPage});
    }

    /**
     * Permet de récupérer une facture par son idFacture
     * @param id
     */
    function searchFactureIdFacture(id){
      searchFacture({idFacture:id});
    }

    /**
     * Permet de récupérer des factures d'un topic
     * @param id
     */
    function searchFactureIdTopic(id){
      var obj = {topicId:id};
      if(_this.idFacture) obj.idFacture = _this.idFacture;
      searchFacture(obj);
    }

    /**
     * Permet de récupérer une facture avec la précédente, la suivante et des infos
     * @param nPage
     */
    function searchFacture(obj){
      init();
      _this.loadingFacture = true;

      if(_this.listExpediteur.length) {
        var listIdGroupeUserExpediteur = [];
        var listIdGroupeDestinataire = [];
        for(var i = 0; i < _this.listExpediteur.length; i++) {
          if(_this.listExpediteur[i].isUser()) listIdGroupeUserExpediteur.push(_this.listExpediteur[i].getIdGroupe());
          else listIdGroupeDestinataire.push(_this.listExpediteur[i].getIdGroupe());
        }
        obj.listIdGroupeExpediteur = listIdGroupeUserExpediteur;
        obj.listIdGroupeDestinataire = listIdGroupeDestinataire;
      }

      if(_.isObject(obj)) {
        if(_.isNumber(_this.currentIdChannel)) {
          obj.idChannel = _this.currentIdChannel;
        }
        if(_.isNumber(obj.idTopic)) {
          obj.topicId = obj.idTopic;
          delete obj.idTopic;
        }
      }

      /*
      if(_this.idTopic && (_.isNil(obj.topicId) || !obj.topicId))
        obj.topicId = _this.idTopic;*/

      TopicService
        .getTopicWithFactureNoSentToComfact(obj)
        .then(function(topicStateFacture){
          //console.log(topicStateFacture);
          if(_.isObject(topicStateFacture)) {
            _this.currentContenu = topicStateFacture.getCurrentContenu();

            //if(!_this.currentContenu) searchFactureByPage(1);
            if(!_this.currentContenu) throw new Error('facture_not_exist');

            // Création d'un objet de facture selected pour le champ select
            _this.factureSelected = angular.copy(_this.currentContenu);
            delete _this.factureSelected.nbFacture;
            delete _this.factureSelected.nbFactureNoComfact;
            delete _this.factureSelected.titreTopic;
            delete _this.factureSelected.topicState;

            // Liste des options du champ select
            _this.listFacture = topicStateFacture.getListFactureNoSentToComfact();
            _this.nbTotalTopicState = topicStateFacture.getNbTotalTopicState();
            _this.nbFacture = topicStateFacture.getNbTotalFacture();

            if(topicStateFacture.getPreviousContenu()) {
              _this.previousContenu = topicStateFacture.getPreviousContenu();
              _this.nbPreviousFacture = topicStateFacture.getNbPreviousFacture();
            }
            if(topicStateFacture.getNextContenu()) {
              _this.nextContenu = topicStateFacture.getNextContenu();
              _this.nbNextFacture = topicStateFacture.getNbNextFacture();
            }

            if(topicStateFacture.getListGroupeDestinataire().length) {
              _this.listExpediteur = [];
              for(var i = 0; i < topicStateFacture.getListGroupeDestinataire().length; i++) {
                var g = topicStateFacture.getListGroupeDestinataire()[i];
                if(g.idGroupe !== _this.myGroupe.idGroupe){
                  _this.listExpediteur.push(g);
                }
              }
            }

            // Topic actuel
            _this.currentTopic = topicStateFacture.getCurrentContenu().getTopicState().getTopic();
            //if(!_this.currentTopic) ModalsService.alertErreur('Pas de topic !');
            if(!_this.currentTopic) throw new Error('Pas de topic !');

            // Evenement actuel
            _this.currentEvenement = _this.currentTopic.getContenus()[0];

            var arrayContents = angular.element(_this.currentEvenement.getObjet());
            if (arrayContents.length === 2) {
              _this.messageEvenement = $sce.trustAsHtml(arrayContents[0].innerHTML);
              _this.fichesEvenement = $sce.trustAsHtml(arrayContents[1].innerHTML);
            }
            else _this.messageEvenement = $sce.trustAsHtml(_this.currentEvenement.getObjet());

            // if(!_this.currentTopic) ModalsService.alertErreur('Pas d\'événement !');
            if(!_this.currentEvenement) throw new Error('Pas d\'événement !');

            // Devis actuel
            _this.currentDevis = _this.currentEvenement.getListeDevis()[0];
            if(!_this.currentDevis) throw new Error('Pas de devis !');

            _this.currentFacture = _this.currentDevis.getFacture();
            if(!_this.currentFacture) throw new Error('Pas de facture !');

            if(_this.currentDevis.getDocumentDemande()) _this.objHasDocuments.demandeDevis = true;

            var guidDevisSigne = null;
            if(_this.currentDevis.getDocumentDevisSigne()) {
              guidDevisSigne = _this.currentDevis.getDocumentDevisSigne().getGuid();
              _this.objHasDocuments.devisSigne = true;
            }
            else if(_this.currentDevis.getDocumentDevis()) guidDevisSigne = _this.currentDevis.getDocumentDevis().getGuid();
            _this.guidDevis = guidDevisSigne;

            //if(!_this.currentDevis) ModalsService.alertErreur('Pas de devis !');
            //if(!_this.currentDevis.getFacture()) ModalsService.alertErreur('Pas de facture !');

            // Recupération de la facture PDF
            _this.currentDocumentFacture = _this.currentFacture.getListeDocument()[0];
            if(_.isNil(_this.currentDocumentFacture)) throw new Error("facture_not_exist");

            var searchObject = getObjectSearch(_this.currentContenu);
            $state.go($state.current.name, searchObject, {reload: false, notify: false, location: true});
            emit({
              action: 'initAndLoadPdf',
              document: _this.currentDocumentFacture,
              infos: {
                idDevis: _this.currentDevis.getIdContenu(),
                idFacture: _this.currentContenu.idFacture,
                idTopic: _this.currentTopic.getIdTopic()
              },
              states: {
                stateName: $state.current.name,
                stateParams: angular.copy($stateParams)
              }
            });
            _this.panelComfactIsCollapsed = true;
          }
        })
        .catch(function(msg){
          switch(msg.message){
            case 'facture_not_exist':
              //$state.go($state.current.name, {page: 1, idFacture: null, listGroupe: _this.listExpediteur, paramsComfactfacture: _this.paramsComfactfacture});
              $state.go($state.current.name, getObjectSearch());
              break;
            default:
              ModalsService.alertErreur(msg);
          }
        })
        .finally(function(){
          _this.loadingFacture = false;
        });
    }

    /**
     * Permet de mettre la bonne class au panel du devis
     * @returns {*}
     */
    function classPanelDevis(){
      // Si pas de guidDevis
      // OU
      // Si panel ouvert, pas de loading et pas d'url pdf trouvé
      if(!_this.guidDevis || (_this.panelDevisSigneIsCollapsed && !_this.loadingPdfDevis && !_this.urlPdfDevis)) return 'panel-danger';
        // Si bien un guidDevis, panel ouvert
        // ET
        // Loading OU une url de devis trouvé
        // OU
      // Si panel fermé et qu'il y a bien un gruid devis
      else if((_this.panelDevisSigneIsCollapsed && (_this.loadingPdfDevis || _this.urlPdfDevis)) || (!_this.panelDevisSigneIsCollapsed && _this.guidDevis)) return 'panel-default';
    }

    /**
     * Lors du choix d'une facture avec le champ select
     */
    function onChangeFacture(){
      var obj = getObjectSearch(_this.factureSelected);
      if(_.isObject(obj) && !_.isNil(obj.idFacture)) searchFacture(obj);
    }

    /**
     * Lorsque l'on ouvre/ferme le bloc du devis
     */
    function onClickCollapseDevisSigne(){
      _this.panelDevisSigneIsCollapsed = !_this.panelDevisSigneIsCollapsed;
      if(_this.panelDevisSigneIsCollapsed && _this.guidDevis && !_this.urlPdfDevis) {
        _this.loadingPdfDevis = true;
        getUrlPdf(_this.guidDevis)
          .then(function(urlPdf){
            _this.urlPdfDevis = urlPdf;
          })
          .catch(function(msg){
            _this.urlPdfDevis = null;
          })
          .finally(function(){
            _this.loadingPdfDevis = false;
          });
        /*
        emit({
          action: 'loadPdf',
          guid: _this.guidDevis
        });*/
      }
    }

    /**
     * Permet de récupérer l'url d'un PDF avec son Guid
     * @param guid
     * @returns {*}
     */
    function getUrlPdf(guid){
      var deferred = $q.defer();
      if (bowser.msie) deferred.resolve($sce.trustAsResourceUrl(encodeURIComponent(DocumentManagerService.getUrlStream(guid, _this.idFacture, _this.idTopic))));
      else {
        // Recupère le PDF
        DocumentManagerService
          .getStream(guid, _this.idFacture, _this.idTopic)
          .then(function (url) {
            deferred.resolve(encodeURIComponent(url));
          })
          .catch(function(msg){
            deferred.reject(msg.statusText);
          });
      }
      return deferred.promise;
    }

    /**
     * Lors du clique sur le bouton de recherche de factures
     */
    function onClickSearchFactures(){
      searchFactureByPage(1);
    }

    /**
     * Lors du clique sur le bouton  voir le topic
     */
    function onClickRefreshFacture(){
      var obj = getObjectSearch(_this.currentContenu);
      if(_.isObject(obj) && !_.isNil(obj.idFacture)) searchFacture(obj);
    }

    /**
     * Lors du clique sur la facture précédente
     */
    function onClickPreviousFacture(){
      goPreviousFacture();
    }

    /**
     * Génère l'objet de recherche pour le state
     * @param objet
     * @returns {{idFacture: null, listGroupe: (Array|*), paramsComfactfacture: (null|*|{})}}
     */
    function getObjectSearch(objet, devisProcessed){
      var obj = {
        idFacture: null,
        listGroupe: _this.listExpediteur,
        paramsComfactfacture: _this.paramsComfactfacture
      };

      if(_this.idTopic && $state.current.name === 'facturesTopic') {
        obj.idTopic = objet.idTopic;
      }

      if(_.isObject(objet) && !_.isNil(objet.idFacture)) {
        obj.idFacture = objet.idFacture;
      }

      // Si il y a un devis qui vient d'être traité, on va envoyé l'id au back pour ne pas la recupérer et forcer le passage à la suivante
      if(_.isObject(devisProcessed) && !_.isNil(devisProcessed.idContenu)){
        obj.idDevisProcessed = devisProcessed.idContenu
      }

      if(_.isNumber(_this.currentIdChannel)) obj.idChannel = _this.currentIdChannel;
      return obj;
    }

    /**
     * Facture précédente
     */
    function goPreviousFacture(devisProcessed){
      //if(_this.previousContenu) $state.go($state.current.name, getObjectSearch(_this.previousContenu));
      if(_this.previousContenu) {
        var obj = getObjectSearch(_this.previousContenu);
        if(_.isObject(obj) && !_.isNil(obj.idFacture)) searchFacture(obj);
      }
    }

    /**
     * Lors du clique sur la facture suivante
     */
    function onClickNextFacture(){
      goNextFacture();
    }

    /**
     * Facture suivante
     */
    function goNextFacture(devisProcessed){
      //if(_this.nextContenu) $state.go($state.current.name, getObjectSearch(_this.nextContenu), {reload: false, notify: true, location: false});
      if(_this.nextContenu) {
        var obj = getObjectSearch(_this.nextContenu, devisProcessed);
        if(_.isObject(obj) && !_.isNil(obj.idFacture)) searchFacture(obj);
      }
    }

    /**
     * Permet de passer à une autre facture suivante ou précédente selon si il en reste après ou pas
     */
    function goToOtherFacture(devisProcessed){
      if(_this.nextContenu) goNextFacture(devisProcessed);
      else if(_this.previousContenu) goPreviousFacture(devisProcessed);
      else {
        _this.listFacture = [];
        emit({
          action: 'cleanInit',
          document: null,
          infos: {
            idDevis: null,
            idFacture: null,
            idTopic: null
          },
          states: {
            stateName: $state.current.name,
            stateParams: angular.copy($stateParams)
          }
        });
      }
    }

    /**
     * Lors du clique sur voir le topic
     */
    function onClickVoirTopic(){
      $state.go('topics.detail', {topicId: _this.currentContenu.idTopic});
    }

    /**
     * Lors du clique sur la communication
     */
    function onClickCommunication(){
      ModalsService.modalCommunication(_this.currentEvenement, function(){
        $timeout(function(){
          $rootScope.$broadcast('openMessageForDevis', _this.currentDevis);
        });
      });
    }

    /**
     * Lorsque le formulaire comfact est terminé
     * @param obj
     */
    function onSubmitComfactfacture(obj) {
      if(angular.isObject(obj) && obj.hasOwnProperty('action')) {

        // Si envoyé sur Comfact, passe à une autre facture
        if(obj.action === 'sentToComfact' || obj.action === 'sentToComfactAndCloture') {
          $timeout(function(){
            goToOtherFacture(obj.devis);
          });
        }

        // Surement seulement la cloture
        //else if(obj.hasOwnProperty('contenu')){}
      }
    }

    function onEmitComfactfacture(obj){
      if(angular.isObject(obj) && obj.hasOwnProperty('idPortefeuille')) {
        if(!angular.isObject(_this.paramsComfactfacture)) _this.paramsComfactfacture = {};
        if(!angular.isObject(_this.paramsComfactfacture[obj.idPortefeuille])) _this.paramsComfactfacture[obj.idPortefeuille] = {};
        if(obj.hasOwnProperty('configs')) _this.paramsComfactfacture[obj.idPortefeuille].configs = obj.configs;
        if(obj.hasOwnProperty('users')) _this.paramsComfactfacture[obj.idPortefeuille].users = obj.users;
      }
    }

    /**
     * Lors du clique sur le bouton pour cloturé l'événement du topic
     */
    function onClickCloturer(){
      ModalsService.loading('Cloture en cours...');
      ContenuService.clotureOrReouvreEvenement({idEvenement: _this.currentEvenement.getIdContenu()}).then(function (contenu) {
        if (angular.isObject(contenu) && contenu.isModel) {
          goToOtherFacture();
          /*
          if(contenu.getListeDevis() && contenu.getListeDevis().length) {
            var listDevis = [];
            for(var d = 0; d < contenu.getListeDevis().length; d++) {
              var devis = contenu.getListeDevis()[d];
              if(devis.getIdContenu() === _this.currentDevis.getIdContenu()) {
                listDevis.push(devis);
                break;
              }
            }
            contenu.setListeDevis(listDevis);
          }
          _this.currentDevis.setData(contenu);
          _this.currentTopic.setEventCloturer(true);*/
        }
      });
    }

    function onChangeCollaborateur(obj){
      UtilsService.getCurrentFiltreTopics().idChannel = _this.currentIdChannel = null;
      if(obj.hasOwnProperty('collaborateurs')) _this.listExpediteur = obj.collaborateurs;
    }

    function toggleChooseCollaborateur(){
      _this.showChooseCollaborateur = !_this.showChooseCollaborateur;
    }

    /**
     * Lorsqu'un groupe est retiré des destinataires avec la petite croix
     * @param obj
     */
    function onRemoveDestinataire(obj){
      UtilsService.getCurrentFiltreTopics().idChannel = _this.currentIdChannel = null;
      if(obj.groupe) _this.listExpediteur = GroupeService.removeGroupeFromList(obj.groupe, _this.listExpediteur);
    }

  }
})();
