(function () {
  'use strict';


  /**
   * @ngdoc service
   * @name collaboreApp.LotMulticamp
   * @description
   * # LotMulticamp
   * Service in the collaboreApp.
   */
  angular
    .module('collaboreApp')
    .factory('LotMulticamp', LotMulticampModel);

  function LotMulticampModel (Main, UtilsService) {

    function LotMulticamp(objet) {
      Main.call(this, objet);
    }

    LotMulticamp.prototype = {

      model: 'LotMulticamp',
      isLotMulticamp: true,

      // idsite
      getIdsite: function(){
        return parseInt(this.idsite);
      },
      setIdsite: function(valInt){
        this.idsite = valInt;
      },

      // camping
      getCamping: function(){
        return this.camping;
      },
      setCamping: function(val){
        this.camping = val;
      },

      // idcatego
      getIdcatego: function(){
        return parseInt(this.idcatego);
      },
      setIdcatego: function(valInt){
        this.idcatego = valInt;
      },

      // categorie
      getCategorie: function(){
        return this.categorie;
      },
      setCategorie: function(val){
        this.categorie = val;
      },

      // place_id
      getPlace_id: function(){
        return parseInt(this.place_id);
      },
      setPlace_id: function(valInt){
        this.place_id = valInt;
      },

      // place_name
      getPlace_name: function(){
        return this.place_name;
      },
      setPlace_name: function(val){
        this.place_name = val;
      },

      // identerprise
      getIdenterprise: function(){
        return parseInt(this.identerprise);
      },
      setIdenterprise: function(valInt){
        this.identerprise = valInt;
      },

      // idPerson
      getIdPerson: function(){
        return parseInt(this.idPerson);
      },
      setIdPerson: function(val){
        this.idPerson = val;
      },

      // firstName
      getFirstName: function(){
        return this.firstName;
      },
      setFirstName: function(val){
        this.firstName = val;
      },

      // lastName
      getLastName: function(){
        return this.lastName;
      },
      setLastName: function(val){
        this.lastName = val;
      },

      getNom: function(){
        return this.getLastName() + " " + this.getFirstName();
      },

      // phone
      getPhone: function(){
        return this.phone;
      },
      setPhone: function(val){
        this.phone = val;
      },

      // phoneMobil
      getPhoneMobil: function(){
        return this.phoneMobil;
      },
      setPhoneMobil: function(val){
        this.phoneMobil = val;
      },

      getTelephones: function(){
        var tels = [];
        if(!_.isNil(this.getPhone()) && _.isString(this.getPhone()) && this.getPhone() !== "") {
          tels.push(this.getPhone());
        }
        if(!_.isNil(this.getPhoneMobil()) && _.isString(this.getPhoneMobil()) && this.getPhoneMobil() !== "") {
          tels.push(this.getPhoneMobil());
        }
        return tels;
      },

      // mail
      getMail: function(){
        return this.mail;
      },
      setMail: function(val){
        this.mail = val;
      },

      // begin
      getBegin: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format) format = null;
          return UtilsService.parseDate(this.begin, format);
        }
        return this.begin;
      },
      setBegin: function(val){
        this.begin = val;
      },

      // end
      getEnd: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format) format = null;
          return UtilsService.parseDate(this.end, format);
        }
        return this.end;
      },
      setEnd: function(val){
        this.end = val;
      }
    };

    angular.extend(LotMulticamp.prototype, Main.prototype);
    return LotMulticamp;
  }
})();
