(function() {
  'use strict';

  /**
   * @desc Formulaire pour envoyer un SMS
   * @example <form-send-sms contenu="" parent-contenu="" disable-num-libre="true"></form-send-sms>
   */

  angular
    .module('collaboreApp')
    .component('formulaireSms',{
      templateUrl: 'app/topics/formulaire-sms/formulaire-sms.component.html',
      bindings : {
        idTopic: '<',
        groupeDestinataire: '<',	// <Groupe> Groupe destinataire
        persoMulticamp: '<',	// <Groupe> Perso Multicamp
        contenu: '<',				// <Contenu> Contenu où il faut chercher le destinataire pour envoyer un SMS
        evenement: '<',				// <Evenement>
        //parentContenu: '<',			// <Contenu> Contenu parent, souvent l'evenement
        defaultParams: '<',			// <Object> Params par defaut à donner au formulaire
        disableNumLibre: '<',		// <Boolean> Permet de désactiver la possibilité de renseigner un numéro de portable
        onInsert: '&',				// <Function> Fonction appelé lors de l'insertion d'un contenu
        onSubmit: '&'				// <Function> Fonction appelé lors du submit
      },
      controllerAs: 'formulairesmsctrl',
      controller: FormulaireSmsController
    });

  /*@ngInject*/
  function FormulaireSmsController(COLLAB_CONF, $injector, $q, UtilsService, GroupeService, ErreurCollabService){

    var ctrl = this;

    ctrl.destinataire = false;
    ctrl.loading = {
      'show': true,
      'message': 'Chargement...'
    };

    ctrl.mobilePriveChecked = false;

    ctrl.nbNumPersoICS = 0;
    ctrl.groupeCollabHasPortablePrive = false;
    ctrl.personnalitesICS = [];       // Perso ICS trouvées
    ctrl.numerosCollab = [];       	// Numéros de mobile venant des fiche Collab
    ctrl.numeroCustom = '';
    ctrl.errorNumeroCustom = {
      'etat': false,
      'message': ''
    };

    ctrl.onChangeMobilePrive = onChangeMobilePrive;		// Lorsque l'on coche/decoche le numero de tel prive
    ctrl.onChangeMobileAutres = onChangeMobileAutres;	// Lorsque l'on coche/decoche un des numéro sauf le prive
    ctrl.onSubmitSms = onSubmitSms;						// Appelé lors du submit
    ctrl.onInsertContenuSms = onInsertContenuSms;		// Appelé lors de l'ajout d'un contenu
    ctrl.onEmitFromFormulaireMessageComponent = onEmitFromFormulaireMessageComponent;		// Appelé lors d'une remontée d'info du formulaire message
    ctrl.onError = onError;								// Appelé si erreur

    ctrl.$onInit = function() {
      init();
    };

    /**
     * Permet d'init les infos
     */
    function init() {
      //console.log(ctrl.defaultParams);
      if(!_.isObject(ctrl.defaultParams)) ctrl.defaultParams = {};
      if(!_.isArray(ctrl.defaultParams.destinataires)) ctrl.defaultParams.destinataires = [];
      if(!_.isArray(ctrl.defaultParams.destinatairesExclus)) ctrl.defaultParams.destinatairesExclus = [];
      if(!_.isArray(ctrl.defaultParams.destinatairesHide)) ctrl.defaultParams.destinatairesHide = [];

      /*
      ctrl.defaultParams.communication
      ctrl.listDestinataire = ctrl.defaultParams.destinataires
      ctrl.defaultParams.destinatairesExclus
      ctrl.defaultParams.destinatairesHide
      ctrl.defaultParams.disallowReplyWithUrlToSMS
      ctrl.defaultParams.focus
      ctrl.defaultParams.hauteurEditor
      ctrl.defaultParams.message
      ctrl.defaultParams.prefixSms
      ctrl.defaultParams.sms
      ctrl.defaultParams.smsAdvance
      */

      ctrl.loading.show = true;
      ctrl.loading.message = 'Chargement des numéros...';
      var groupe = null;

      // Si il y a bien un destinataire pour le contenu
      if (ctrl.contenu && ctrl.contenu.getDestinataires().length) groupe = ctrl.contenu.getDestinataires()[0];
      else if(ctrl.groupeDestinataire) groupe = ctrl.groupeDestinataire;

      if(!_.isNil(groupe)) {
        getGroupe(groupe.getIdGroupe())
          .then(function(groupe){

            ctrl.destinataire = groupe;

            var numerosMobileCollab = [];
            var fichePerso = groupe.getUser().getFichePerso();

            // Numéro des surfiches
            var mobileSurfiche = fichePerso.getTelephonesPortable();
            if (mobileSurfiche.length) {
              for (var f = 0; f < mobileSurfiche.length; f++) {
                numerosMobileCollab.push({
                  checked: false,
                  numero: mobileSurfiche[f].getDonnee()
                });
              }
            }

            /*
                      // Si il y a une fiche père
                      if (fichePerso.hasFichePere()) {

                          // Recupère les fax de la fichePere et parcour les resultats
                          var arrayFichePereMobile = fichePerso.getFichePere().getTelephonesPortable();
                          if (arrayFichePereMobile.length) {
                              for (var f2 = 0; f2 < arrayFichePereMobile.length; f2++) {
                                  numerosMobileCollab.push({
                                      'checked': true,
                                      'numero': arrayFichePereMobile[f2].getDonnee()
                                  });
                              }
                          }
                      }*/

            if (numerosMobileCollab.length) {
              ctrl.numerosCollab = numerosMobileCollab;
            }

            if(groupe) {
              getPersoICS(groupe)
                .then(function(personnalitesICS){
                  ctrl.personnalitesICS = personnalitesICS;
                })
                .catch(function(msg){
                  ErreurCollabService.alert(msg);
                })
                .finally(function(){
                  ctrl.loading.show = false;
                });
            }
            else {
              ErreurCollabService.alert('Pas de groupe trouvé !');
              ctrl.loading.show = false;
            }
          })
          .catch(function(msg){
            ErreurCollabService.alert(msg);
            ctrl.loading.show = false;
          });
      }
      else if(ctrl.persoMulticamp){
        // Prendre tel de la perso multicamp

        var numerosMobileCollab = [];
        var telephones = ctrl.persoMulticamp.getTelephones();
        if (telephones.length) {
          for (var f = 0; f < telephones.length; f++) {
            if(UtilsService.isTelMobile(telephones[f])) {
              numerosMobileCollab.push({
                checked: false,
                numero: telephones[f]
              });
            }
          }
        }
        if (numerosMobileCollab.length) ctrl.numerosCollab = numerosMobileCollab;
        ctrl.loading.show = false;
      }
    }

    /**
     * Recupère un groupe par son id et verifie si il a un num de portable privé renseigné dans une fiche non visible
     * @param contenu
     * @returns {Promise}
     */
    function getGroupe(idGroupe){
      var deferred = $q.defer();

      // Si il y a bien un idGroupe
      if (idGroupe) {

        // Recup le groupe par son idGroupe
        GroupeService
          .getInfosGroupe(idGroupe)
          .then(function (groupeFind) {
            if (groupeFind) {
              // Si le groupe à un portable privé
              if(groupeFind.getGroupeInformations() && groupeFind.getGroupeInformations().hasNumeroPortablePrive) {
                ctrl.groupeCollabHasPortablePrive = true;
              }
            }
            deferred.resolve(groupeFind);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });
      }
      else {
        deferred.resolve(false);
      }

      return deferred.promise;
    }

    /**
     * Recupère les infos des perosICS du destinataire
     * @param destinataire
     * @returns {Promise}
     */
    function getPersoICS(destinataire){
      var deferred = $q.defer();

      // Recupère les infos perso des portefeuilles
      GroupeService
        .getInfosPersonnalite(destinataire, true)
        .then(function (arrayPortefeuille) {
          //console.log(arrayPortefeuille);

          var personnalitesICS = [];

          if (arrayPortefeuille.length) {
            for (var i = 0; i < arrayPortefeuille.length; i++) {
              var portefeuille = arrayPortefeuille[i];
              if (portefeuille.listePersonnalite.length) {
                for (var j = 0; j < portefeuille.listePersonnalite.length; j++) {
                  var personnalite = portefeuille.listePersonnalite[j];
                  var telPortables = personnalite.getPortables();

                  personnalite.telephonesPortable = [];
                  if (telPortables.length) {
                    for (var n = 0; n < telPortables.length; n++) {
                      personnalite.telephonesPortable.push({
                        'checked': false,
                        'numero': telPortables[n]
                      });
                    }
                  }
                  personnalitesICS.push(personnalite);
                }
              }
            }
          }
          deferred.resolve(personnalitesICS);
        })
        .catch(function (msg) {
          deferred.reject(msg);
        });

      return deferred.promise;
    }


    /**
     * Lorsque l'on coche/decoche le numero de tel prive
     */
    function onChangeMobilePrive(){
      if(ctrl.mobilePriveChecked) {
        ctrl.numeroCustom = '';
        if(ctrl.personnalitesICS.length){
          for(var i = 0; i < ctrl.personnalitesICS.length; i++) {
            if(ctrl.personnalitesICS[i].telephonesPortable.length) {
              for(var p = 0; p < ctrl.personnalitesICS[i].telephonesPortable.length; p++) {
                ctrl.personnalitesICS[i].telephonesPortable[p].checked = false;
              }
            }
          }
        }
        if(ctrl.numerosCollab.length){
          for(var n = 0; n < ctrl.numerosCollab.length; n++) {
            ctrl.numerosCollab[n].checked = false;
          }
        }
      }
    }

    /**
     * Lorsque l'on coche/decoche un des numéro sauf le prive
     * @param obj
     */
    function onChangeMobileAutres(obj){
      // Si string alors c'est le champ libre
      if(angular.isString(obj) && obj !== '') {
        ctrl.mobilePriveChecked = false;
      }
      // Si objet alors c'est une case à cocher
      else if(angular.isObject(obj) && obj.checked) {
        ctrl.mobilePriveChecked = false;
      }

    }

    /**
     * Lors du submit du SMS
     * @param onSubmit
     * @returns {boolean}
     */
    function onSubmitSms(onSubmit){

      try{
        var retour = false;
        var persoIcsHasNum = false;
        var numeros = [];
        var numeroParse;

        // Si il y a des numéros venant des perso ICS
        if(ctrl.personnalitesICS.length){
          // Parcours les numéro pour garder ceux cochés
          for(var p = 0 ; p < ctrl.personnalitesICS.length ; p++){
            // Si la personnalité a des telephones
            if(ctrl.personnalitesICS[p].telephonesPortable.length){
              persoIcsHasNum = true;
              // Parcours les numéro de la persoICS
              for(var n = 0 ; n < ctrl.personnalitesICS[p].telephonesPortable.length ; n++){
                var objNumeroICS = ctrl.personnalitesICS[p].telephonesPortable[n];
                // Si coché
                if(objNumeroICS.checked){
                  numeroParse = UtilsService.cleanPhoneNumber(objNumeroICS.numero);
                  if(UtilsService.isTelMobile(numeroParse)){
                    numeros.push(numeroParse);
                  }
                }
              }
            }
          }
        }

        // Si il y a des numéros de portable sur les fiches collab
        // OU
        // Si il y a un numéro de tel libre
        // OU
        // Si la piche perso du destinataire à un portable privé
        if(ctrl.numerosCollab.length || ctrl.numeroCustom!=='' || ctrl.groupeCollabHasPortablePrive || numeros.length){

          // Si il y a des numéros venant des fiches collab
          if(ctrl.numerosCollab.length) {
            // Parcour les numéro pour garder ceux cochés
            for(var i = 0 ; i < ctrl.numerosCollab.length ; i++){
              var objNumero = ctrl.numerosCollab[i];
              // Si coché
              if(objNumero.checked){
                numeroParse = UtilsService.cleanPhoneNumber(objNumero.numero);
                if(UtilsService.isTelMobile(numeroParse)){
                  numeros.push(numeroParse);
                }
              }
            }
          }

          if(ctrl.numeroCustom !== '') {
            ctrl.numeroCustom = UtilsService.cleanPhoneNumber(ctrl.numeroCustom);
            if(UtilsService.isTelMobile(ctrl.numeroCustom)){

              // Si ce n'est pas une perso multicamp on peu ajouter le numéro à la fiche perso
              if(ctrl.destinataire && !ctrl.persoMulticamp) {
                var fichePerso = ctrl.destinataire.getUser().getFichePerso();
                fichePerso.saveTelMobile(ctrl.numeroCustom, ctrl.destinataire);
              }
              numeros.push(ctrl.numeroCustom);
            }
            else{
              ctrl.errorNumeroCustom.etat = true;
              ctrl.errorNumeroCustom.message = 'Numéro incorrect...';
              if(ctrl.numeroCustom===''){
                ctrl.errorNumeroCustom.message = 'Numéro vide...';
              }
              /*
                          if(ctrl.numeroCustom.length!==10){
                              ctrl.errorNumeroCustom.message = 'Le numéro doit avoir 10 chiffres';
                          }*/
            }
          }

          // Si il reste des numéros ou il y a num privé dans une fiche que l'on ne voit pas
          if(numeros.length || ctrl.groupeCollabHasPortablePrive){

            ctrl.loading.show = true;
            ctrl.loading.message = 'Envoi en cours...';

            //console.log(onSubmit);
            if(onSubmit.hasOwnProperty('contenu')) {

              // Si persoMulticamp
              if(_.isObject(ctrl.persoMulticamp)) {

                var ProxyDestinataire = $injector.get("ProxyDestinataire");
                var proxyDest = new ProxyDestinataire();
                //proxyDest.setTypeDestinataire('destinataireSms');
                proxyDest.setListeNumeroTelephone(numeros);
                proxyDest.setNom(ctrl.persoMulticamp.getLastName());
                proxyDest.setPrenom(ctrl.persoMulticamp.getFirstName());
                //proxyDest.setLibelleDestinataireSms(ctrl.persoMulticamp.getNom());
                proxyDest.setAllowSendEmail(false);

                proxyDest.setLibelle(UtilsService.genereLibelleClientCapfun(ctrl.persoMulticamp));
                proxyDest.setDieze(COLLAB_CONF.SOCIETE_CAPFUN);
                proxyDest.setEmail(UtilsService.genereYopmailClientCapfun(ctrl.persoMulticamp));

                ProxyDestinataire = null;

                onSubmit.contenu.destinataires.push(proxyDest);
              }
              else if(_.isObject(ctrl.destinataire) && onSubmit.contenu.hasOwnProperty('destinataires') && onSubmit.contenu.destinataires.length){
                // Si il y a des numéro choisi, envoi sur ces numéros
                if(numeros.length) {
                  // Parcours les destinataire du contenu pour trouvé le bon destinataire
                  // Il faut lui ajouter la liste des numéros où il faut communiquer
                  for(var d = 0; d < onSubmit.contenu.destinataires.length; d++){
                    var dest = onSubmit.contenu.destinataires[d];
                    if(_.isObject(dest) && dest.isModel && dest.model === "Groupe") {
                      // Si destinataire trouvé
                      if(dest.getIdGroupe() === ctrl.destinataire.getIdGroupe()) {
                        dest.listeNumeroTelephone = numeros;
                        break;
                      }
                    }
                  }
                }
              }
            }
            retour = true;
          }
          else {
            // Si erreur dans le numéro libre
            if(ctrl.errorNumeroCustom.etat) {
              ErreurCollabService.alert(ctrl.errorNumeroCustom.message);
            }
            // Si il y a des numéro dispo, pas de num libre et aucun coché
            else if((persoIcsHasNum || ctrl.numerosCollab.length) && !numeros.length){
              ErreurCollabService.alert('Veuillez cocher au moins un numéro de téléphone !');
            }
          }
        }

        // Si pas de numéro privé, ni de numéro trouvé dans les persoICS ou fiche collab et pas de numéro libre renseigné
        if(!ctrl.groupeCollabHasPortablePrive && !persoIcsHasNum && !ctrl.numerosCollab.length && ctrl.numeroCustom==='') {
          ErreurCollabService.alert('Veuillez renseigner un numéro de téléphone !');
        }

        if(retour) ctrl.onSubmit({onSubmit: onSubmit});
      }
      catch(e) {
        ctrl.loading.show = false;
        throw new Error(e.message);
      }
      return retour;
    }

    /**
     * Appelé lors de l'ajout d'un contenu
     * @param onInsert
     * @param type
     */
    function onInsertContenuSms(onInsert) {
      ctrl.onInsert({onInsert: onInsert});
      ctrl.loading.show = false;
    }

    /**
     * Lorsque le composant "formulaire-message" emit quelque chose
     * @param obj
     */
    function onEmitFromFormulaireMessageComponent(obj){
      try {
        if (!obj)
          throw new Error('Pas de "obj"');

        if(obj.action === 'addMessage' && _.isObject(obj.contenu)){
          onInsertContenuSms({insertContenu: obj.contenu});
        }
      }
      catch (err) {
        console.log('[FormulaireSmsController.onEmitFromFormulaireMessageComponent] Erreur : ' + err.message);
      }
    }

    /**
     * Appelé si il y a une erreur
     * @param obj
     */
    function onError(error){
      if(error) {
        ctrl.loading.show = false;
      }
    }
  }


})();
