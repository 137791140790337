(function () {
  'use strict';

  /**
   * @desc Page de liste de topic
   * @example <layout-topic-main></layout-topic-main>
   */

  angular
    .module('collaboreApp')
    .component('layoutTopicMain', {
      templateUrl: 'app/layout/layout-topic-main/layout-topic-main.component.html',
      controllerAs: 'layouttopicmainctrl',
      controller: LayoutTopicMainController
    });

  /*@ngInject*/
  function LayoutTopicMainController($injector, $scope, SocketNotificationService) {
    var ctrl = this;

    ctrl.$onInit = function () {
      SocketNotificationService.on('update-collab', onUpdateCollab);
    };

    ctrl.$onDestroy = function () {
      SocketNotificationService.removeListener('update-collab');
    };

    function onUpdateCollab(json){
      if(_.isObject(json) && json.success) {

        // Reception des infos désynchronisées concernant les topic states
        if(_.isArray(json.TopicStates) && json.TopicStates.length) {
          var TopicStates = $injector.get('TopicStates');
          var listTopicStates = json.TopicStates.map(function(t){
            return new TopicStates(t);
          });
          TopicStates = null;
          $scope.$broadcast('updateCollab', {listTopicStates: listTopicStates});
        }

        // Reception des infos désynchronisées concernant les infos dashboard dans la liste des topics (Nb tag extranet etc...)
        if(_.isObject(json.infosDashboard)) {
          var InfosDashboardModel = $injector.get('InfosDashboard');
          $scope.$broadcast('updateCollab', {infosDashboard: new InfosDashboardModel(json.infosDashboard)});
          InfosDashboardModel = null;
        }
      }
    }
  }
})();
