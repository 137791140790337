(function() {
  'use strict';

  /**
   * @desc Menu pour les demandes
   * @example <menu-demande></menu-demande>
   */

  angular
    .module('collaboreApp')
    .component('menuDemande',{
      templateUrl: 'app/topics/components/menu-demande/menu-demande.component.html',
      bindings : {
        evenement: '<',
        contenu: '<',
        onEmit: '&'
      },
      controllerAs: 'menudemandectrl',
      controller: MenuDemandeController
    });

  /*@ngInject*/
  function MenuDemandeController(sweet, TagService, ModalsService, UtilsService, uuid, TopicManagerService){

    var ctrl = this;

    ctrl.isGestion = false;
    ctrl.sentToComfactfacture = false;
    ctrl.dateSentToComfactfacture = null;
    ctrl.parseDateHeure = UtilsService.parseDateHeure;

    ctrl.onClickSendTo = onClickSendTo;                                 // Lorsqu'on clique sur le bouton pour envoyer au gravitants
    ctrl.onClickRelancerDemande = onClickRelancerDemande;               // Lorsqu'on clique sur le bouton pour faire une relance
    ctrl.onClickEnvoyerVersComfact = onClickEnvoyerVersComfact;         // Lorsqu'on clique sur le bouton pour envoyer la facture vers comfact

    ctrl.$onInit = function() {
      init();
    };

    function init(){
      ctrl.uuid = uuid.new();
      ctrl.sendToComfactEnable = UtilsService.getParametrageComfact('allowSendToComfactfacture');
      ctrl.topicIsBlocked = TopicManagerService.isBlocked;
      // Si il y a un tag "Propriétaire" alors c'est un événement de gestion
      if(ctrl.evenement && TagService.hasTagTypeProprietaire(ctrl.evenement.getTagsType())) ctrl.isGestion = true;
      if(!ctrl.documentsFacture && _.isObject(ctrl.contenu) && _.isObject(ctrl.contenu.facture)) ctrl.documentsFacture = ctrl.contenu.facture.getListeDocument();
      checkSentToComfact();
    }

    function checkSentToComfact() {
      if (ctrl.documentsFacture != null && angular.isArray(ctrl.documentsFacture) && ctrl.documentsFacture.length) {

        for (var i = 0; i < ctrl.documentsFacture.length; i++) {
          if (ctrl.documentsFacture[i].isSentToComfactfacture()) {
            ctrl.sentToComfactfacture = true;
            ctrl.dateSentToComfactfacture = ctrl.documentsFacture[i].getDateSentToComfactfacture();
            break;
          }
        }

        if(angular.isObject(ctrl.contenu) && ctrl.contenu.getListeDocumentComptable() && angular.isArray(ctrl.contenu.getListeDocumentComptable()) && ctrl.contenu.getListeDocumentComptable().length && !ctrl.sentToComfactfacture) {
          for(var d = 0; d < ctrl.contenu.getListeDocumentComptable().length; d++) {
            if (ctrl.contenu.getListeDocumentComptable()[d].isSentToComfactfacture()) {
              ctrl.sentToComfactfacture = true;
              ctrl.dateSentToComfactfacture = ctrl.contenu.getListeDocumentComptable()[d].getDateSentToComfactfacture();
              break;
            }
          }
        }
      }
    }

    /**
     * Lors du clique sur le bouton pour envoyer le devis au propriétaire ou CS
     * @param devis
     */
    function onClickSendTo() {
      var options = {
        size: 'lg',
        windowClass: 'modal-collabore',
        backdrop: 'static'
      };

      var params = {
        devis: ctrl.contenu,
        evenement: ctrl.evenement,
        isGestion: ctrl.isGestion,
        documents: [ctrl.contenu.getDocumentDevis()]
      };

      ModalsService
        .modalComponent('sendToGravitant', params, options)
        .then(function(obj) {
          ctrl.onEmit({obj: obj});
        });
    }

    /**
     * Permet de relancer la demande
     * @param devis
     */
    function onClickRelancerDemande() {
      if (_.isObject(ctrl.contenu)) {

        ModalsService
          .modalRelanceDemande(ctrl.contenu)
          .then(function(retourDevis){
            var object = {
              obj: {
                action: 'updateDevis',
                contenu: retourDevis
              }
            };
            ctrl.onEmit(object);
          });
        /*
        sweet.show({
          title: 'Relance Devis',
          text: 'Relancer ' + ctrl.contenu.destinataires[0].libelle + ctrl.contenu.destinataires[0].dieze + ' ?',
          type: 'info',
          showCancelButton: true,
          //cancelButtonText: 'Annuler',
          confirmButtonText: 'Relancer !',
          closeOnConfirm: false,
          showLoaderOnConfirm: true
        }, function () {
          ctrl.contenu.relancer()
            .then(function(){
              ModalsService.alert('Relance', 'Relance effectuée !');
            })
            .catch(function (msg) {
              ModalsService.alertErreur(msg);
            })
            .finally(function () {
              //sweet.close();
            });
          //saveDevis();
        });
         */
      }

      /*
      if (angular.isObject(devis)) {
          ModalsService
              .confirm('Relance', 'Voulez-vous vraiment faire une relance ?')
              .then(function(dialog){
                  ContenuService.relancerDevisForContenus(devis.idContenu)
                      .finally(function () {
                          dialog.close();
                          // TODO : retirer le timeout et trouver un autre moyen d'attendre le close de la modal
                          $timeout(function(){
                              ModalsService.alert('Relance', 'Relance effectuée !');
                          },100);
                      });
              });
      }*/
    }

    /**
     * Lorsque l'on clique sur le bouton pour envoyer la facture vers comfact
     */
    function onClickEnvoyerVersComfact() {

      if(ctrl.sendToComfactEnable && angular.isObject(ctrl.evenement) && angular.isObject(ctrl.contenu) && angular.isObject(ctrl.contenu.facture)) {
        var options = {
          backdrop: 'static'
        };
        var params = {
          idTopic: ctrl.contenu.idTopic,
          idDevis: ctrl.contenu.idContenu,
          facture: ctrl.contenu.facture,
          isIntervention: ctrl.contenu.isDemandeIntervention(),
          tagsType: ctrl.evenement.getTagsType(),
          objHasDocuments: {
            demandeDevis: ctrl.contenu.hasDocumentDemande(),
            devisSigne: ctrl.contenu.hasDevisSigne()
          },
          showCloture: (!ctrl.evenement.isCloture()),
          isCloture: ctrl.evenement.isCloture()
        };

        ModalsService
          .modalComponent('comfactFacture', params, options)
          .then(function (obj) {

            if((obj.action === 'sentToComfact' || obj.action === 'sentToComfactAndCloture') && obj.hasOwnProperty('devis')){

              var object = {
                obj: {
                  action: 'updateDevis',
                  contenu: obj.devis
                }
              };
              if(obj.action === 'sentToComfactAndCloture') object.obj.refreshTopic = true;
              ctrl.onEmit(object);
            }
          })
          .catch(function (msg) {
            if (msg !== 'cancel') {
              ModalsService.alertErreur(msg);
            }
          });
      }
    }
  }

})();
