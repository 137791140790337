(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .factory('Main', MainModel);

  /** @ngInject */
  function MainModel($injector) {
    var Main = function (data, required, objectConversion) {
      if (_.isObject(data) || required || objectConversion) {
        this.hydrate(data, required, objectConversion);
      }
    };
    Main.prototype = {
      isModel: true,
      hydrate: function (data, required, objectConversion) {
        var scope = this, attributExclus = ['dateCreationFormated'];
        if(data && data.hasOwnProperty('typeContenu')) this.setTypeContenu(data.typeContenu);

        if (required) {
          if (angular.isArray(required)) {
            for (var i = 0; i < required.length; i++) {
              if(!required[i].hasOwnProperty('exclu') || !required[i].exclu) {
                var attr = scope.searchConversion(required[i].attribut, objectConversion);
                var setter = 'set' + attr.ucfirst();
                if (angular.isFunction(scope[setter])) scope[setter](required[i].defaultValue);
              }
              else attributExclus.push(required[i].attribut);
            }
          }
        }
        if (data) {
          // Hydratation
          angular.forEach(data, function (val, key) {
            var attr = scope.searchConversion(key, objectConversion);
            if(attributExclus.indexOf(key) === -1) {
              var setter = 'set' + attr.ucfirst();
              if (angular.isFunction(scope[setter])) scope[setter](val);
            }
          });
        }
      },

      setData: function(objet){
        this.clean();
        this.hydrate(objet);
      },

      clean: function(){
        if(angular.isObject(this)) {
          var _this = this;
          angular.forEach(this, function(value, key) {
            _this[key] = null;
          });
        }
      },

      /**
       * Permet de set les attributs minimum d'un objet
       * @param scope
       * @param attributMinimum
       */
      setAttributesRequired: function(scope, attributMinimum){
        // Parcour les attributs minimum
        for(var i = 0 ; i < attributMinimum.length ; i++){
          // Si l'attribut n'est pas dans l'objet on le met avec sa valeur par defaut
          if(!scope.hasOwnProperty(attributMinimum[i].attribut)){
            var setter = 'set' + attributMinimum[i].attribut.ucfirst();
            if (angular.isFunction(scope[setter])) {
              scope[setter](attributMinimum[i].defaultValue);
            }
          }
        }
      },

      /**
       * Permet de changer le nom de l'attribut si celui du JSON est différent du Model
       * @param attribut
       * @param objectConversion
       * @returns {*}
       */
      searchConversion: function(attribut, objectConversion){
        var retour = attribut;
        if(angular.isObject(objectConversion) && objectConversion.hasOwnProperty(attribut)) {
          retour = objectConversion[attribut];
        }
        return retour;
      },

      toJson: function () {
        this.recurCheckToJson(this);
        return angular.toJson(this);
      },

      recurCheckToJson: function (data) {
        angular.forEach(data, function (val, key) {
          if (angular.isObject(val)) {
            this.recurCheckToJson(val);
          } else if (angular.isString(key)) {
            var setter = 'set' + key.ucfirst();
            if (!angular.isFunction(this[setter])) {
              delete this[key];
            }
          }
        }, this);
      }
    };
    Main.$injector = $injector;
    return Main;
  }
})();
