(function () {
  "use strict";

  angular.module("collaboreApp").factory("Modele", ModeleModel);

  /** @ngInject */
  function ModeleModel(Main, Tantcolonne) {
    function Modele(contenuData) {
      Main.apply(this, arguments);
    }

    Modele.prototype = {
      model: "Modele",
      isModele: true,

      isCompteGeneraux: function () {
        return this.getRR() === "00";
      },

      isRegroupement: function () {
        return (
          this.getCCCCCC() === "000000" &&
          this.getBB() === "00" &&
          this.getSS() === "00"
        );
      },

      isSousRegroupement: function () {
        return this.getCCCCCC() === "000000" && this.getSS() !== "00";
      },

      isBatiment: function () {
        return (
          this.getCCCCCC() === "000000" &&
          this.getBB() !== "00" &&
          this.getSS() === "00"
        );
      },

      isTousBatiment: function () {
        return (
          this.getCCCCCC() === "000000" &&
          this.getBB() === "00" &&
          this.getSS() === "00"
        );
      },

      isCompte: function () {
        return this.getCCCCCC() !== "000000" && this.getSS() !== "00";
      },

      // tantcolonne
      hasTantcolonne: function () {
        return (
          _.isObject(this.getTantcolonne()) &&
          !_.isNil(this.getTantcolonne().IIII)
        );
      },
      getTantcolonne: function () {
        return this.tantcolonne;
      },
      setTantcolonne: function (val) {
        if (!_.isNil(val) && !val.isModel) val = new Tantcolonne(val);
        this.tantcolonne = val;
      },

      // IIII
      getIIII: function () {
        return this.IIII;
      },
      setIIII: function (val) {
        this.IIII = val;
      },

      // RR
      getRR: function () {
        return this.RR;
      },
      setRR: function (val) {
        this.RR = val;
      },

      // BB
      getBB: function () {
        return this.BB;
      },
      setBB: function (val) {
        this.BB = val;
      },

      // SS
      getSS: function () {
        return this.SS;
      },
      setSS: function (val) {
        this.SS = val;
      },

      // CCCCCC
      getCCCCCC: function () {
        return this.CCCCCC;
      },
      setCCCCCC: function (val) {
        this.CCCCCC = val;
      },

      // Lib
      getLib: function () {
        return this.Lib;
      },
      setLib: function (val) {
        this.Lib = val;
      },

      hasListRegroupement: function () {
        return (
          _.isArray(this.getListRegroupement()) &&
          this.getListRegroupement().length
        );
      },
      // listRegroupement
      getListRegroupement: function () {
        return this.listRegroupement;
      },
      setListRegroupement: function (val) {
        if (_.isArray(val) && val.length) {
          val = val.map(function (item) {
            if (!item.isModel) item = new Modele(item);
            return item;
          });
        }
        this.listRegroupement = val;
      },

      hasListBatiment: function () {
        return (
          _.isArray(this.getListBatiment()) && this.getListBatiment().length
        );
      },
      // listBatiment
      getListBatiment: function () {
        return this.listBatiment;
      },
      setListBatiment: function (val) {
        if (_.isArray(val) && val.length) {
          val = val.map(function (item) {
            if (!item.isModel) item = new Modele(item);
            return item;
          });
        }
        this.listBatiment = val;
      },

      hasListCompte: function () {
        return _.isArray(this.getListCompte()) && this.getListCompte().length;
      },
      // listCompte
      getListCompte: function () {
        return this.listCompte;
      },
      setListCompte: function (val) {
        if (_.isArray(val) && val.length) {
          val = val.map(function (item) {
            if (!item.isModel) item = new Modele(item);
            return item;
          });
        }
        this.listCompte = val;
      },

      hasListModele: function () {
        return _.isArray(this.getListModele()) && this.getListModele().length;
      },
      // listModele
      getListModele: function () {
        return this.listModele;
      },
      setListModele: function (val) {
        if (_.isArray(val) && val.length) {
          val = val.map(function (item) {
            if (!item.isModel) item = new Modele(item);
            return item;
          });
        }
        this.listModele = val;
      },

      // AGE
      getAGE: function () {
        return this.AGE;
      },
      setAGE: function (val) {
        this.AGE = val;
      },

      // APOGEE
      getAPOGEE: function () {
        return this.APOGEE;
      },
      setAPOGEE: function (val) {
        this.APOGEE = val;
      },

      // BudN
      getBudN: function () {
        return this.BudN;
      },
      setBudN: function (val) {
        this.BudN = val;
      },

      // BudNM1
      getBudNM1: function () {
        return this.BudNM1;
      },
      setBudNM1: function (val) {
        this.BudNM1 = val;
      },

      // BudNM2
      getBudNM2: function () {
        return this.BudNM2;
      },
      setBudNM2: function (val) {
        this.BudNM2 = val;
      },

      // BudNP1
      getBudNP1: function () {
        return this.BudNP1;
      },
      setBudNP1: function (val) {
        this.BudNP1 = val;
      },

      // CPT
      getCPT: function () {
        return this.CPT;
      },
      setCPT: function (val) {
        this.CPT = val;
      },

      // ChgN
      getChgN: function () {
        return this.ChgN;
      },
      setChgN: function (val) {
        this.ChgN = val;
      },

      // ChgNM1
      getChgNM1: function () {
        return this.ChgNM1;
      },
      setChgNM1: function (val) {
        this.ChgNM1 = val;
      },

      // ChgNM2
      getChgNM2: function () {
        return this.ChgNM2;
      },
      setChgNM2: function (val) {
        this.ChgNM2 = val;
      },

      // Chgfonc
      getChgfonc: function () {
        return this.Chgfonc;
      },
      setChgfonc: function (val) {
        this.Chgfonc = val;
      },

      // Clerep
      getClerep: function () {
        return this.Clerep;
      },
      setClerep: function (val) {
        this.Clerep = val;
      },

      // Codrub
      getCodrub: function () {
        return this.Codrub;
      },
      setCodrub: function (val) {
        this.Codrub = val;
      },

      // Codtrait
      getCodtrait: function () {
        return this.Codtrait;
      },
      setCodtrait: function (val) {
        this.Codtrait = val;
      },

      // Codtva
      getCodtva: function () {
        return this.Codtva;
      },
      setCodtva: function (val) {
        this.Codtva = val;
      },

      // Codvar
      getCodvar: function () {
        return this.Codvar;
      },
      setCodvar: function (val) {
        this.Codvar = val;
      },

      // Deduc
      getDeduc: function () {
        return this.Deduc;
      },
      setDeduc: function (val) {
        this.Deduc = val;
      },

      // FLUIDE
      getFLUIDE: function () {
        return this.FLUIDE;
      },
      setFLUIDE: function (val) {
        this.FLUIDE = val;
      },

      // Fdr
      getFdr: function () {
        return this.Fdr;
      },
      setFdr: function (val) {
        this.Fdr = val;
      },

      // GUI
      getGUI: function () {
        return this.GUI;
      },
      setGUI: function (val) {
        this.GUI = val;
      },

      // Montva
      getMontva: function () {
        return this.Montva;
      },
      setMontva: function (val) {
        this.Montva = val;
      },

      // SYBELL
      getSYBELL: function () {
        return this.SYBELL;
      },
      setSYBELL: function (val) {
        this.SYBELL = val;
      },

      // Typbud
      getTypbud: function () {
        return this.Typbud;
      },
      setTypbud: function (val) {
        this.Typbud = val;
      },

      // Typcpt
      getTypcpt: function () {
        return this.Typcpt;
      },
      setTypcpt: function (val) {
        this.Typcpt = val;
      },

      // coddec
      getCoddec: function () {
        return this.coddec;
      },
      setCoddec: function (val) {
        this.coddec = val;
      },

      // montant
      getMontant: function () {
        return this.montant;
      },
      setMontant: function (val) {
        this.montant = val;
      },

      // montantCorrection
      getMontantCorrection: function () {
        return this.montantCorrection;
      },
      setMontantCorrection: function (val) {
        this.montantCorrection = val;
      },

      // contrats
      getContrats: function () {
        return this.contrats;
      },
      setContrats: function (val) {
        var list = [];
        if (_.isArray(val) && val.length) {
          var Contrat = Main.$injector.get("Contrat");
          for (var i = 0; i < val.length; i++) {
            if (_.isObject(val[i]) && !val[i].isModel) {
              var contrat = new Contrat(val[i]);
              list.push(contrat);
            }
          }
          Contrat = null;
        }
        this.contrats = list;
      },

      getContrat: function (refCon) {
        var contra = null;
        if (_.isArray(this.contrats) && this.contrats.length) {
          for (var i = 0; i < this.contrats.length; i++) {
            if (this.contrats[i].getRefCon() == refCon) {
              contra = this.contrats[i];
              break;
            }
          }
        }
        return contra;
      },

      /**
       * Permet de récupérer le contratab qui correspond au CCCCCC et a la référence du contrat en paramètre
       * @returns
       */
      getContrattab: function (refCon) {
        var contrat = this.getContrat(refCon);
        if (_.isObject(contrat)) {
          return contrat.getContrattab(this.getCCCCCC());
        }
        return null;
      },

      title: function(){
        var title = this.getCCCCCC()+' - '+this.getLib() + ' - Clé: ' + this.getClerep();
        if(_.isArray(this.contrats) && this.contrats.length){
          title = this.getCCCCCC()+' - '+this.getLib();
        }
        return title;
      },
      desc: function(){
        var desc = this.libelleBatiment;
        if(!_.isNil(this.libelleSousRegroupement)){
          desc = this.libelleRegroupement + ' - ' + this.libelleSousRegroupement;
        }
        else if(_.isNil(this.libelleSousRegroupement) && !_.isNil(this.libelleRegroupement)){
          desc = this.libelleRegroupement;
        }
        return desc;
      },
      infos: function(){
        var infos = '';
        if(!_.isNil(this.getBB())) infos += "BB:"+this.getBB()+" ";
        if(!_.isNil(this.getRR())) infos += "RR:"+this.getRR()+" ";
        if(!_.isNil(this.getSS())) infos += "SS:"+this.getSS()+" ";
        if(_.isArray(this.contrats) && this.contrats.length){
          infos += "<br/>";
          if(this.contrats.length > 1){
            var refContrats = this.contrats.reduce(function(accum, contrat){
              accum.push(contrat.refCon);
              return accum;
            }, []);
            infos += refContrats.length + ' contrats - Réf(s). '+refContrats.join(', ');
          }
          else{
            infos += 'Contrat ' + this.contrats[0].lib1 + ' - Réf. '+this.contrats[0].refCon;
          }
        }
        return (_.isEmpty(infos)) ? undefined : infos;
      }
    };

    angular.extend(Modele.prototype, Main.prototype);

    return Modele;
  }
})();
