'use strict';

/**
 * @desc Tags
 * @example <tag-popover></tag-popover>
 */

angular
	.module('collaboreApp')
	.component('tagPopover',{
		templateUrl: 'app/tags/tag-popover.component.html',
		require: {
			tagCtrl: '^tag'
		},
		controllerAs: 'tagpopoverctrl',
		controller: ['$scope','$timeout','TagService','UtilsService','ModalsService','EvenementService',TagPopoverController]
	});

function TagPopoverController($scope,$timeout,TagService,UtilsService,ModalsService,EvenementService){

	var ctrl = this;

    var listenerOnClosePopoverTag = $scope.$on('closePopoverTag',function(){
        $timeout(function(){
            ctrl.popoverTags.close();
        });

    });

	ctrl.$onInit = function(){

		var classPopover = 'popover-collabore';
		if(ctrl.tagCtrl.typeTag){
			classPopover += ' popover-tag-type';
		}else{
			classPopover += ' popover-tag';
		}
		ctrl.popoverClass = classPopover;

		ctrl.popoverPlacement = (angular.isDefined(ctrl.tagCtrl.popoverPlacement)) ? ctrl.tagCtrl.popoverPlacement : 'top';
		ctrl.popoverTitle = (angular.isDefined(ctrl.tagCtrl.popoverTitle)) ? ctrl.tagCtrl.popoverTitle : (ctrl.tagCtrl.libelle) ? ctrl.tagCtrl.libelle : 'Tag';

		ctrl.iconClass = false;
		if(angular.isDefined(ctrl.icon)){
			ctrl.iconClass = ctrl.icon;
		}else{
			ctrl.iconClass = TagService.getIconForTagType(ctrl.tagCtrl.typeTag,ctrl.tagCtrl.limit);
		}


		// Ecoute le changement de modèle
		$scope.$watchCollection('tagpopoverctrl.tagCtrl.currentModelEvenement', function(){
			majSuggestionFromModele(ctrl.tagCtrl.currentModelEvenement);
		});

		// Ecoute la variable des tags pour modifier la liste si les tags sont modifié dans le contenu
		$scope.$watchCollection('tagpopoverctrl.tagCtrl.tags', function(){
			majSuggestionFromModele(ctrl.tagCtrl.currentModelEvenement);
		});


	};

	ctrl.$onDestroy = function(){
        listenerOnClosePopoverTag();
	};


	ctrl.suffixPopoverTitle = '';		// Suffix du titre du popover
	ctrl.modeleWithTags = false;		// Permet de savoir si un eventuel modèle d'événement utilise une liste de tags autorisées

	ctrl.paramsFiltreByTagType = false; // Paramètres pour le ui-sref du tag type

	ctrl.onAddTag = onAddTag;			// Lors de l'ajout d'un tag
	ctrl.onClickTag = onClickTag;		// Lors du click sur un tag
	ctrl.onDeleteTag = onDeleteTag;		// Lors de la suppression d'un tag
  ctrl.onClickChangeTagPresence = onClickChangeTagPresence;

	// Gestion de la popover
	ctrl.popoverTags = {
		'isOpen': false,
		'toggle': function toggle(event){

			this.isOpen = !this.isOpen;

			if(event){
				event.stopPropagation();
			}
		},
		'close': function close(){
			this.isOpen = false;
		},
		'submit': function submit(){
			//$scope.$broadcast('submitPopoverTags');
		}
	};

	/**
	 * Met à jour les suggestion si il y a un modèle d'événement et par rapport au tag existant dans l'événement
	 * @param newValue
	 */
	function majSuggestionFromModele(newValue){

		ctrl.tagsFermes = {
			'show': false,
			'liste': []
		};

		ctrl.allowSearchSuggestion = true;

		if(angular.isObject(newValue)){
			if(newValue.tags.length){
				//console.log($scope);
				ctrl.modeleWithTags = true;

				if(!ctrl.tagCtrl.typeTag){
					if(newValue.isModeleAgence){
						ctrl.allowSearchSuggestion = true;
					}else{
						ctrl.allowSearchSuggestion = false;
					}

					ctrl.tagsFermes.show = true;

					var tagsTmp = [];
					// Pour chaque tag autorisé par le modèle d'évenement
					for(var t = 0 ; t < newValue.tags.length ; t++){

						var tag = newValue.tags[t];

						tag = UtilsService.ltrim(tag,'#');
						var tagExist = false;
						if(angular.isArray(ctrl.tagCtrl.tags)){
							// Si il y a des tags déjà choisis dans l'événement
							if(ctrl.tagCtrl.tags.length){

								var searchTag = {'libelle':'#'+tag};
								/*
								if(ctrl.tagCtrl.typeTag){
									searchTag = {'libelle':'#'+tag, typeTag: {'libelle':ctrl.tagCtrl.typeTag}};
								}*/
								tagExist = TagService.tagExistIn(ctrl.tagCtrl.tags,searchTag);
							}
						}

						// Si le tag suggere n'est pas dans le tableau des tags
						if(!tagExist){
							//tagsTmp.push(TagService.newTag(tag,ctrl.tagCtrl.typeTag));
							tagsTmp.push(TagService.newTag(tag));
						}
					}

					ctrl.tagsFermes.liste = tagsTmp;

					// Change le suffix du titre du popover
					ctrl.suffixPopoverTitle = ' "' + newValue.libelle + '"';
				}


			}
		}else{
			ctrl.tagsFermes.show = false;
			ctrl.allowSearchSuggestion = true;

			// Change la variable dans le scope du popover qui permet de savoir si le model à une liste de tags autorisés
			ctrl.modeleWithTags = false;
			// Change le suffix du titre du popover
			ctrl.suffixPopoverTitle = '';
		}
	}




	/**
	 * Lors de l'ajout d'un tag
	 * @param tag
	 */
	function onAddTag(tag){
		if (!tag) {
			return;
		}
		ctrl.tagCtrl.update({
			'addTag': tag
		});
	}

	/**
	 * Lors du clique sur un tag
	 */
	function onClickTag(){

	}

	/**
	 * Lors de la suppression d'un tag
	 * @param tag
	 */
	function onDeleteTag(objUpdate){
		ctrl.tagCtrl.update(objUpdate);
	}


	function onClickChangeTagPresence(){
	  if(ctrl.tagCtrl.limit === "1" && ctrl.tagCtrl.tags.length === 1){

      if(ctrl.tagCtrl.tags[0].isTagPresence()){
        if(ctrl.tagCtrl.idContenu) {
          var texte = "Passer la présence en \"Obligatoire\" ?<br\><span class=\"text-primary\">Le client veut être présent !</span>";
          if(ctrl.tagCtrl.tags[0].libelle === "#Obligatoire") texte = "Passer la présence en \"Faculative\" ? <br><span class=\"text-primary\">Le client ne veut pas forcément être présent !</span>";

          ModalsService.confirm("Présence du client", texte).then(function () {
            var modalLoading = ModalsService.loading('Patientez...');

            EvenementService
              .toggleTagPresence(ctrl.tagCtrl.idContenu)
              .then(function (event) {
                ctrl.tagCtrl.onUpdate({
                  objUpdate: {
                    evenement: event
                  }
                });

                modalLoading.close();
              })
              .catch(function (msg) {
                ModalsService.alertErreur(msg);
              });
          });
        }
        else {
          if(ctrl.tagCtrl.tags[0].libelle === "Obligatoire") ctrl.tagCtrl.tags[0].libelle = "Facultative";
          else ctrl.tagCtrl.tags[0].libelle = "Obligatoire";
        }
      }
    }
  }
}



