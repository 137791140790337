'use strict';

angular.module('collaboreApp').factory('Typelot',[
	function() {

		function Typelot(objetData) {

			if (objetData) {
				this.setData(objetData);
				this.isTypelot = true;
			}
		}

		Typelot.prototype = {


			isModel: true,

			setData: function(objetData) {
				angular.extend(this, objetData);
			},

			// Libelle type
			getLibelleType: function(){
				return this.libelleType;
			},
			setLibelleType: function(libelleType){
				this.libelleType = libelleType;
			}

		};

		return Typelot;
	}
]);