(function () {
  'use strict';


  /**
   * @ngdoc service
   * @name collaboreApp.SharedDocument
   * @description
   * # SharedDocument
   * Service in the collaboreApp.
   */
  angular
    .module('collaboreApp')
    .factory('SharedDocument', SharedDocumentModel);

  function SharedDocumentModel (Main, MainService, Groupe, Document, UtilsService, SharedDocumentGroupe) {

    function SharedDocument(objet) {
      Main.call(this, objet);
    }

    SharedDocument.prototype = {

      model: 'SharedDocument',
      isSharedDocument: true,

      // idSharedDocument
      getIdSharedDocument: function(){
        return parseInt(this.idSharedDocument);
      },
      setIdSharedDocument: function(valInt){
        this.idSharedDocument = valInt;
      },

      // document
      getDocument: function(){
        return this.document;
      },
      setDocument: function(doc){
        // Si c'est l'objet document on recupèrte le guid
        if (_.isObject(doc)) {
          if(!doc.isModel) doc = new Document(doc);
          this.document = doc;
        }
      },

      // dateCreate
      getDateCreate: function (format) {
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateCreate, format);
        }
        return this.dateCreate;
      },
      setDateCreate: function (date) {
        this.dateCreate = date;
      },
      getDateCreateAgo: function(){
        var retour = this.dateCreate;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },

      // groupeCreator
      getGroupeCreator: function(){
        return this.groupeCreator;
      },
      setGroupeCreator: function(val){
        if(_.isObject(val)){
          if(!val.isModel) val = new Groupe(val);
          this.groupeCreator = val;
        }
      },

      // usedByGroupe
      getUsedByGroupe: function(){
        return this.usedByGroupe;
      },
      setUsedByGroupe: function(val){
        if(_.isObject(val)){
          if(!val.isModel) val = new Groupe(val);
          this.usedByGroupe = val;
        }
      },

      // usedInContenus
      getUsedInContenus: function(){
        return (_.isNil(this.usedInContenus)) ? [] : this.usedInContenus;
      },
      setUsedInContenus: function(arrayContenus){
        if(_.isArray(arrayContenus)) {
          arrayContenus = arrayContenus.map(function(c){
            if(_.isObject(c)){
              if(!c.isModel) c = MainService.initContenuByType(c)
            }
            return c;
          });
        }
        this.usedInContenus = arrayContenus;
      },


      // listSharedDocumentGroupe
      getListSharedDocumentGroupe: function(){
        return this.listSharedDocumentGroupe;
      },
      setListSharedDocumentGroupe: function(array){
        if(_.isArray(array)) {
          array = array.map(function(sharedDocumentGroupe){
            if(_.isObject(sharedDocumentGroupe)){
              if(!sharedDocumentGroupe.isModel) sharedDocumentGroupe = new SharedDocumentGroupe(sharedDocumentGroupe)
            }
            return sharedDocumentGroupe;
          });
        }
        this.listSharedDocumentGroupe = array;
      },

      // canDelete
      getCanDelete: function(){
        return this.canDelete;
      },
      setCanDelete: function(val){
        this.canDelete = val;
      },

      // canEdit
      getCanEdit: function(){
        return this.canEdit;
      },
      setCanEdit: function(val){
        this.canEdit = val;
      },

      // canShare
      getCanShare: function(){
        return this.canShare;
      },
      setCanShare: function(val){
        this.canShare = val;
      },

      isUsed: function(){
        return (!_.isNil(this.getUsedByGroupe()) || (_.isArray(this.getUsedInContenus() && this.getUsedInContenus().length))) ? true : false;
      },

      isShared: function(){
        return (_.isArray(this.getListSharedDocumentGroupe()) && this.getListSharedDocumentGroupe().length) ? true : false;
      }
    };

    angular.extend(SharedDocument.prototype, Main.prototype);
    return SharedDocument;

  }
})();
