(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name collaboreApp.ContenuEvenementIntervention
     * @description
     * # ContenuEvenementIntervention
     * Service in the collaboreApp.
     */

    angular.module('collaboreApp').factory('ContenuEvenementIntervention', ContenuEvenementInterventionModel);

    /** @ngInject */
    function ContenuEvenementInterventionModel(Contenu, ContenuEvenementFacture) {

        var ContenuEvenementIntervention = function() {
            Contenu.apply(this, arguments);
            this.typeContenu = 4;

            var boolProxy = false;
            var factureInstance;
            if(arguments[1]){
                boolProxy = arguments[1];
            }

            if(angular.isDefined(this.facture)){
                if(this.facture){
                    factureInstance = new ContenuEvenementFacture(this.facture,boolProxy);
                    this.setFacture(factureInstance);
                }
            }

			/*
			 if(angular.isDefined(this.persoICS)){
			 if(this.persoICS){
			 this.setPersoICS(new PersoIcs(this.persoICS,boolProxy));
			 }
			 }*/

            if(boolProxy){
                if(this.destinataires.length){
                    var destinataires = [];
                    for(var i = 0 ; i < this.destinataires.length ; i++){
                        if(angular.isObject(this.destinataires[i])){
                            destinataires.push(this.destinataires[i].libelle+this.destinataires[i].dieze);
                        }else{
                            destinataires.push(this.destinataires[i]);
                        }
                    }
                    this.setDestinataires(destinataires,true);
                }
            }

            // Il y a un idContenu et que c'est une instance proxy
            if(angular.isDefined(this.getIdContenu()) && boolProxy) {
                if(factureInstance){
                    this.setProxyFacture(factureInstance);
                }
                // Si il y a un attribut "facture" on le delete car c'est proxyFacture qu'il faut utiliser
                if(this.hasOwnProperty('facture')){
                    delete this.facture;
                }

            }else{
                if(factureInstance){
                    this.setFacture(factureInstance);
                }
            }
			/*
			 if(angular.isDefined(this.facture)){
			 if(this.facture){
			 var factureInstance = new ContenuEvenementFacture(this.facture,boolProxy);
			 this.setFacture(factureInstance);
			 }
			 }*/

            /*** Proxy ****/
			/*
			 // Ici crée les objet dans les bon attribut pour le proxy
			 if(angular.isDefined(this.proxyFacture)){
			 if(this.proxyFacture){
			 var proxyFactureInstance = new ContenuEvenementFacture(this.proxyFacture,boolProxy);
			 this.setProxyFacture(proxyFactureInstance);
			 }
			 }*/
        };

        ContenuEvenementIntervention.prototype = {

            model: 'ContenuEvenementIntervention',
            isDevis: false,
            isIntervention: true,
            isFacture: false,

            // DescriptionIntervention
            getDescriptionIntervention: function(){
                return this.descriptionIntervention;
            },
            setDescriptionIntervention: function(string){
                this.descriptionIntervention = string;
            },

            // TagFournisseur
            getTagFournisseur: function(){
                return this.tagFournisseur;
            },
            setTagFournisseur: function(proxyTag){
                this.tagFournisseur = proxyTag;
            },

            // InterventionEffectue
            getInterventionEffectue: function(){
                return this.interventionEffectue;
            },
            setInterventionEffectue: function(bool){
                this.interventionEffectue = bool;
            },

            // DateIntervention
            getDateIntervention: function(){
                return this.dateIntervention;
            },
            setDateIntervention: function(date){
                this.dateIntervention = date;
            },

            // Groupement
            getGroupement: function(){
                return this.groupement;
            },
            setGroupement: function(string){
                this.groupement = string;
            },

            // IdDevisPere
            getIdDevisPere: function(){
                return this.idDevisPere;
            },
            setIdDevisPere: function(id){
                this.idDevisPere = id;
            },

            // ContenuEvenementIntervention intervention
            getIntervention: function(){
                return this.intervention;
            },
            setIntervention: function(objectIntervention){
                this.intervention = objectIntervention;
            },

            // ContenuEvenementFacture facture
            getFacture: function(){
                return this.facture;
            },
            setFacture: function(objectFacture){
                this.facture = objectFacture;
            },

            /****** Proxy ***********/

            // ProxyFacture
            getProxyFacture: function(){
                return this.proxyFacture;
            },
            setProxyFacture: function(proxyFacture){
                this.proxyFacture = proxyFacture;
            },


            changeEffectue: function(val){
                this.setInterventionEffectue(val);
                this.setUpdate(true);
            },
            changeDescription: function(val){
                this.setDescriptionIntervention(val);
                this.setUpdate(true);
            },

            isWaiting: function(){
                if(!this.getInterventionEffectue()){
                    return true;
                }
                return false;
            },

            isEffectue: function(){
                if(this.getInterventionEffectue()){
                    return true;
                }
                return false;
            }



        };

        // Copie le prototype de Contenu dans le prototype de ContenuEvenementIntervention
        // ContenuEvenementIntervention hérite donc des methodes de Contenu
        angular.extend(ContenuEvenementIntervention.prototype, Contenu.prototype);

        return ContenuEvenementIntervention;
    }
})();