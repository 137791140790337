(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.TopicInformations
   * @description
   * # TopicInformations
   * Service in the collaboreApp.
   */

  angular.module('collaboreApp').factory('TopicInformations', TopicInformationsModel);

  /** @ngInject */
  function TopicInformationsModel(Main) {

    var TopicInformations = function(objetData) {
      Main.call(this, objetData);
      //this.hydrate(objetData);
    };

    TopicInformations.prototype = {

      isTopicInformations: true,

      // idTopic
      getIdTopic: function(){
        return this.idTopic;
      },
      setIdTopic: function(id){
        this.idTopic = id;
      },

      // titreTopic
      getTitreTopic: function(){
        return this.titreTopic;
      },
      setTitreTopic: function(id){
        this.titreTopic = id;
      },

      // nbDevis
      getNbDevis: function(){
        return this.nbDevis;
      },
      setNbDevis: function(nb){
        this.nbDevis = nb;
      },

      // nbInterventions
      getNbInterventions: function(){
        return this.nbInterventions;
      },
      setNbInterventions: function(nb){
        this.nbInterventions = nb;
      },

      // nbContrats
      getNbContrats: function(){
        return this.nbContrats;
      },
      setNbContrats: function(nb){
        this.nbContrats = nb;
      },

      // nbNotificationsNonLus
      getNbNotificationsNonLus: function(){
        return this.nbNotificationsNonLus;
      },
      setNbNotificationsNonLus: function(nb){
        this.nbNotificationsNonLus = nb;
      },

      // nbNotificationsLus
      getNbNotificationsLus: function(){
        return this.nbNotificationsLus;
      },
      setNbNotificationsLus: function(nb){
        this.nbNotificationsLus = nb;
      },

      // nbFacture
      getNbFacture: function(){
        return this.nbFacture;
      },
      setNbFacture: function(nb){
        this.nbFacture = nb;
      },

      // nbFactureNoComfact
      getNbFactureNoComfact: function(){
        return this.nbFactureNoComfact;
      },
      setNbFactureNoComfact: function(nb){
        this.nbFactureNoComfact = nb;
      },

      // nbDayBetweenCreationAndClosing
      getNbDayBetweenCreationAndClosing: function(){
        return this.nbDayBetweenCreationAndClosing;
      },
      setNbDayBetweenCreationAndClosing: function(nb){
        this.nbDayBetweenCreationAndClosing = nb;
      },

      // hasEvent
      getHasEvent: function(){
        return this.hasEvent;
      },
      setHasEvent: function(nb){
        this.hasEvent = nb;
      },

      // eventModifiable
      getEventModifiable: function(){
        return this.eventModifiable;
      },
      setEventModifiable: function(nb){
        this.eventModifiable = nb;
      },

      // hasSinistre
      getHasSinistre: function(){
        return this.hasSinistre;
      },
      setHasSinistre: function(nb){
        this.hasSinistre = nb;
      },

      // hasContenuImportant
      getHasContenuImportant: function(){
        return this.hasContenuImportant;
      },
      setHasContenuImportant: function(nb){
        this.hasContenuImportant = nb;
      },

      // lastContenuDate
      getLastContenuDate: function(){
        return this.lastContenuDate;
      },
      setLastContenuDate: function(val){
        this.lastContenuDate = val;
      },

      // portefeuille
      getPortefeuille: function(){
        return this.portefeuille;
      },
      setPortefeuille: function(portefeuille){
        // Si val est un objet mais qu'il n'est pas un model
        if(!portefeuille.isModel){
          var Portefeuille = Main.$injector.get('Portefeuille');
          portefeuille = new Portefeuille(portefeuille);
          Portefeuille = null;
        }
        this.portefeuille = portefeuille;
      },

      // topicIsBlocked
      getTopicIsBlocked: function(){
        return this.topicIsBlocked;
      },
      setTopicIsBlocked: function(val){
        this.topicIsBlocked = val;
      },

      // allowCloture
      getAllowCloture: function () {
        return this.allowCloture;
      },
      setAllowCloture: function (bool) {
        this.allowCloture = bool;
      },

      // listeGroupeImmeubleAssocie
      getListeGroupeImmeubleAssocie: function(){
        return this.listeGroupeImmeubleAssocie;
      },
      setListeGroupeImmeubleAssocie: function(val){
        if(_.isArray(val) && val.length){
          var GroupeAssociationImmeuble = Main.$injector.get('GroupeAssociationImmeuble');
          val = val.map(function(item){
            if(_.isObject(item) && !item.isModel) item = new GroupeAssociationImmeuble(item);
            return item;
          });
          GroupeAssociationImmeuble = null;
        }
        this.listeGroupeImmeubleAssocie = val;
      },

      // listeGroupeProprietaireAssocie
      getListeGroupeProprietaireAssocie: function(){
        return this.listeGroupeProprietaireAssocie;
      },
      setListeGroupeProprietaireAssocie: function(val){
        if(_.isArray(val) && val.length){
          var GroupeAssociationProprietaire = Main.$injector.get('GroupeAssociationProprietaire');
          val = val.map(function(item){
            if(_.isObject(item) && !item.isModel) item = new GroupeAssociationProprietaire(item);
            return item;
          });
          GroupeAssociationProprietaire = null;
        }
        this.listeGroupeProprietaireAssocie = val;
      },

      // informationsDossier
      getInformationsDossier: function () {
        return this.informationsDossier;
      },
      setInformationsDossier: function (val) {
        if(_.isObject(val)) {
          if(!val.isModel) {
            var InformationsDossier = Main.$injector.get('InformationsDossier');
            val = new InformationsDossier(val);
            InformationsDossier = null;
          }
        }
        this.informationsDossier = val;
      },

      // groupeImmeuble
      getGroupeImmeuble: function () {
        return (!_.isNil(this.getInformationsDossier())) ? this.getInformationsDossier().groupeImmeuble : this.groupeImmeuble;
      },
      /**
       * @deprecated TODO: A virer car transféré dans "informationsDossier"
       * @param groupe
       */
      setGroupeImmeuble: function (groupe) {
        if(_.isObject(groupe)) {
          if(!groupe.isModel) {
            var Groupe = Main.$injector.get('Groupe');
            groupe = new Groupe(groupe);
            Groupe = null;
          }
        }
        this.groupeImmeuble = groupe;
      },

      // groupeCoproprietaire
      getGroupeCoproprietaire: function () {
        return (!_.isNil(this.getInformationsDossier())) ? this.getInformationsDossier().groupeCoproprietaire : this.groupeCoproprietaire;
      },
      /**
       * @deprecated TODO: A virer car transféré dans "informationsDossier"
       * @param groupe
       */
      setGroupeCoproprietaire: function (groupe) {
        if(_.isObject(groupe)) {
          if(!groupe.isModel) {
            var Groupe = Main.$injector.get('Groupe');
            groupe = new Groupe(groupe);
            Groupe = null;
          }
        }
        this.groupeCoproprietaire = groupe;
      },

      // groupeProprietaire
      getGroupeProprietaire: function () {
        return (!_.isNil(this.getInformationsDossier())) ? this.getInformationsDossier().groupeProprietaire : this.groupeProprietaire;
      },
      /**
       * @deprecated TODO: A virer car transféré dans "informationsDossier"
       * @param groupe
       */
      setGroupeProprietaire: function (groupe) {
        if(_.isObject(groupe)) {
          if(!groupe.isModel) {
            var Groupe = Main.$injector.get('Groupe');
            groupe = new Groupe(groupe);
            Groupe = null;
          }
        }
        this.groupeProprietaire = groupe;
      },

      // groupeLocataire
      getGroupeLocataire: function () {
        return (!_.isNil(this.getInformationsDossier())) ? this.getInformationsDossier().groupeLocataire : this.groupeLocataire;
      },
      /**
       * @deprecated TODO: A virer car transféré dans "informationsDossier"
       * @param groupe
       */
      setGroupeLocataire: function (groupe) {
        if(_.isObject(groupe)) {
          if(!groupe.isModel) {
            var Groupe = Main.$injector.get('Groupe');
            groupe = new Groupe(groupe);
            Groupe = null;
          }
        }
        this.groupeLocataire = groupe;
      },

      // deletionDocDevisByFournisseurAllowed
      getDeletionDocDevisByFournisseurAllowed: function(){
        return this.deletionDocDevisByFournisseurAllowed;
      },
      setDeletionDocDevisByFournisseurAllowed: function(val){
        this.deletionDocDevisByFournisseurAllowed = val;
      },

      // hideProprietaire
      getHideProprietaire: function(){
        return this.hideProprietaire;
      },
      setHideProprietaire: function(val){
        this.hideProprietaire = val;
      },
    };

    angular.extend(TopicInformations.prototype, Main.prototype);

    TopicInformations.prototype.model = 'TopicInformations';

    return TopicInformations;
  }
})();
