(function() {

  'use strict';

  /**
   * @desc Composant document-ocr-manager
   * @example <document-ocr-manager></document-ocr-manager>
   */
  angular
    .module('collaboreApp')
    .component('documentOcrManager',{
      templateUrl: 'app/facture-manager/document-ocr-manager/document-ocr-manager.component.html',
      controllerAs: 'documentocrmanagerctrl',
      bindings: {
        guid: '<',
        documentCollab: '<',
        onEmit: '&'
      },
      controller: DocumentOcrManagerCtrl
    });

  /** @ngInject */
  function DocumentOcrManagerCtrl(COLLAB_CONF, $q, DocumentManagerService, ErreurCollabService){
    var _this = this;

    _this.loadingDocument = false;
    _this.loadingOcr = false;

    _this.currentDocument = null;

    _this.getInfosOCR = getInfosOCR;
    _this.displayTypeDocument = displayTypeDocument;
    _this.displayMontant = displayMontant;
    _this.onClickExecuteOcr = onClickExecuteOcr;
    _this.onClickRetryExecuteOcr = onClickRetryExecuteOcr;


    _this.$onInit = function() {
      init();

      if(_.isNil(_this.guid) && _.isNil(_this.documentCollab)) {
        ErreurCollabService.alert("Impossible de récupérer les informations");
        ErreurCollabService.logErreur("Il faut renseigner un \"guid\" ou un \"documentCollab\"");
      }

      // Recherche par guid du document
      if(_.isNil(_this.documentCollab) && !_.isNil(_this.guid)){
        searchDocumentByGuid(_this.guid);
      }
      // Récupération de l'objet DocumentOCR passé en paramètre
      else if(_.isObject(_this.documentCollab)){
        _this.currentDocument = angular.copy(_this.documentCollab);
      }
    };

    _this.$onChanges = function(changes){
      //console.log(changes);
      //previousValue
      /*
      if(_.isObject(changes.guid) && !changes.guid.isFirstChange() && !_.isNil(changes.guid.currentValue)){
        console.log(changes.guid);
      }*/
    };

    /**
     * Initialisation des variables
     */
    function init(){
    }

    function getInfosOCR(){
      return _this.currentDocument.getDocumentOCR().getReceiptBody().getReceiptData();
    }

    function displayTypeDocument(){
      var invoicetype = getInfosOCR().getInvoiceType();
      if(_.isEmpty(invoicetype)) return 'Inconnu';
      return COLLAB_CONF.TYPE_DOC_KLIPPA_ENUMS[_.toLower(invoicetype)];
    }

    function displayMontant(val){
      return (!_.isNil(val)) ? val/100 : null;
    }

    function emit(obj){
      _this.onEmit({obj: obj});
    }

    function searchDocumentByGuid(guid){
      var deferred = $q.defer();

      _this.loadingDocument = true;
      DocumentManagerService
        .getDocumentByGuid(guid)
        .then(function(document){
          _this.currentDocument = document;
          emit({action: 'retrievedDocument', document: document});
          /*
          if(_.isObject(_this.currentDocument.getDocumentOCR())){
            console.log(_this.currentDocument.getDocumentOCR().getReceiptBody());
          }*/
        })
        .catch(function(msg){
          ErreurCollabService.alert(msg);
        })
        .finally(function(){
          _this.loadingDocument = false;
        });

      return deferred.promise;
    }

    function executeOcr(document, force){
      var deferred = $q.defer();
      _this.loadingOcr = true;
      DocumentManagerService
        .submitDocumentToOCR(document, force)
        .then(function(doc){
          emit({action: 'resultOCR', document: doc});
          deferred.resolve(doc);
        })
        .catch(function(msg){
          ErreurCollabService.alert(msg);
        })
        .finally(function (){
          _this.loadingOcr = false;
        });
      return deferred.promise;
    }

    function onClickExecuteOcr(){
      if(_.isNil(_this.currentDocument)){
        ErreurCollabService.alert("Aucun document à analyser");
        return;
      }
      executeOcr(_this.currentDocument).then(function(doc){
        _this.currentDocument = doc;
      });
    }

    function onClickRetryExecuteOcr(){
      if(_.isNil(_this.currentDocument)){
        ErreurCollabService.alert("Aucun document à analyser");
        return;
      }
      executeOcr(_this.currentDocument, true).then(function(doc){
        _this.currentDocument = doc;
      });
    }


  }
})();
