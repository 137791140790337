(function() {
  'use strict';

  angular.module('collaboreApp').service('RecapMailService', RecapMailServiceCtrl);

  /*@ngInject*/
  function RecapMailServiceCtrl(MainService, $q, ErreurCollabService, RecapMail, RecapMailAjax, UtilsService){
    var ctrl = this;

    ctrl.checkIfExistInListe 	= checkIfExistInListe;	// Check si le recapMail exist dans une liste
    ctrl.getRecapMail 			= getRecapMail;			// Permet de récupérer la liste des recapMail
    ctrl.resendMail 			= resendMail;			// Permet de renvoyer un RecapMail
    ctrl.resendMails 			= resendMails;			// Permet de renvoyer une liste de RecapMail
    ctrl.sendAllMail 			= sendAllMail;			// Permet de renvoyer tous les RecapMail en attente ou erreur de ma société

    function checkIfExistInListe(obj,liste){
      var retour =  false;
      if(angular.isArray(liste) && angular.isObject(obj) && obj.hasOwnProperty('idRecapMail')) {
        if(liste.length){
          for(var i = 0; i < liste.length; i++) {
            if(liste[i].idRecapMail === obj.idRecapMail) {
              retour = true;
              break;
            }
          }
        }
      }
      return retour;
    }

    /**
     * Recupère une liste de RecapMail
     * @param obj
     * @returns {Promise}
     */
    function getRecapMail(obj){
      var deferred = $q.defer();

      try{

        var param = {
          dateFin: moment().format('YYYY-MM-DD')
        };

        if(angular.isObject(obj)) {
          if(obj.hasOwnProperty('dateDebut')){	param.dateDebut = obj.dateDebut;}

          if(obj.hasOwnProperty('dateFin')){		param.dateFin = obj.dateFin;}
        }

        RecapMailAjax.getRecapMail(param,function(json){

          deferred.resolve(MainService.convertJsonToModel(json, 'listRecapMail', RecapMail, deferred));
          /*
          var liste = [];
          if(json.nb !== '0'){
            if(angular.isArray(json.RecapMails)) {
              for(var i = 0; i < json.RecapMails.length; i++) {
                liste.push(new RecapMail(json.RecapMails[i]));
              }
            }
          }
          deferred.resolve(liste);*/
        },function(error){
          ErreurCollabService.erreurServlet(error,'Erreur avec RecapMailService.getRecapMail()',deferred);
        });

      }
      catch(err){
        console.error('[RecapMailService.getRecapMail] Erreur : '+err.message);
        deferred.reject();
      }

      return deferred.promise;
    }

    /**
     * Permet de renvoyer des RecapMail à partir d'une liste de RecapMail ou d'idRecapMail
     * @param array
     * @returns {Promise}
     */
    function resendMails(array){
      var deferred = $q.defer();

      try{

        var listeId = [];
        if(angular.isArray(array)) {
          for(var a = 0; a < array.length; a++) {
            if(angular.isNumber(array[a])){
              listeId.push(array[a]);
            }
            else {
              if(angular.isObject(array[a])){
                if(array[a].isModel && array[a].model === 'RecapMail'){
                  listeId.push(array[a].getIdRecapMail());
                }
                else {
                  if(array[a].hasOwnProperty('idRecapMail')){
                    listeId.push(array[a].idRecapMail);
                  }
                }
              }
            }
          }
        }

        sendMail(listeId).then(function(liste){
          deferred.resolve(liste);
        }).catch(function(msg) {
          throw new Error(msg);
        });

      }
      catch(err){
        console.error('[RecapMailService.resendMails] Erreur : '+err.message);
        deferred.reject();
      }

      return deferred.promise;
    }

    /**
     * Permet de renvoyer un RecapMail à partir d'un objet RecapMail ou avec propriété idRecapMail
     * @param obj
     * @returns {Promise}
     */
    function resendMail(obj){
      var deferred = $q.defer();
      try{

        var listeId = [];
        if(angular.isObject(obj)){
          if(obj.isModel &&obj.model === 'RecapMail'){
            listeId.push(obj.getIdRecapMail());
          }
          else {
            if(obj.hasOwnProperty('idRecapMail')){
              listeId.push(obj.idRecapMail);
            }
          }
        }

        sendMail(listeId).then(function(liste){
          deferred.resolve(liste);
        }).catch(function(msg) {
          throw new Error(msg);
        });
      }
      catch(err){
        console.error('[RecapMailService.resendMail] Erreur : '+err.message);
        deferred.reject();
      }
      return deferred.promise;
    }

    /**
     * Permet de renvoyer des RecapMail
     * @param array
     * @returns {Promise}
     */
    function sendMail(array){
      var deferred = $q.defer();
      try {
        RecapMailAjax.resendMail({idRecapMail: array},function(json){
          var liste = [];
          if(json.nb !== '0'){
            if(angular.isArray(json.RecapMails)) {
              for(var i = 0; i < json.RecapMails.length; i++) {
                liste.push(new RecapMail(json.RecapMails[i]));
              }
            }
          }
          deferred.resolve(liste);
        },function(error){
          ErreurCollabService.erreurServlet(error,'Erreur avec RecapMailService.sendMail()',deferred);
        });
      }
      catch(err){
        console.error('[RecapMailService.sendMail] Erreur : '+err.message);
        deferred.reject();
      }
      return deferred.promise;
    }

    /**
     * Permet de renvoyer tous les RecapMail en attente ou en erreur
     * @returns {Promise}
     */
    function sendAllMail(){
      var deferred = $q.defer();
      try {
        RecapMailAjax.resendAllMail(function(json){
          if(UtilsService.ifResult(json)){
            deferred.resolve();
          }
        },function(error){
          ErreurCollabService.erreurServlet(error,'Erreur avec RecapMailService.sendAllMail()',deferred);
        });
      }
      catch(err){
        console.error('[RecapMailService.sendAllMail] Erreur : '+err.message);
        deferred.reject();
      }
      return deferred.promise;
    }
  }
})();
