(function () {
  'use strict';

  angular
    .module('collaboreApp')
    .factory('LoginResource', LoginResource);

  /** @ngInject */
  function LoginResource(COLLAB_CONF, COLLAB_VALUES, $resource, $httpParamSerializerJQLike) {
    return $resource(COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_AUTHENTIFICATION, null, {
      post: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      },
      connexionCollab: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_AUTHENTIFICATION
      },
      connexionKerberos: {
        method: 'POST',
        transformRequest: function (data) {
          return $httpParamSerializerJQLike(data);
        },
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_AUTHENTIFICATION_KERBEROS
      },
      connexionKerberosGet: {
        method: 'GET',
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_AUTHENTIFICATION_KERBEROS
      },
      logout: {
        method: 'GET',
        params: {
          logoutOnelogin: '@logoutOnelogin'
        },
        url: COLLAB_VALUES.CONF_URL.PATH_TOMCAT + COLLAB_CONF.SERVLET_LOGOUT
      }
    });
  }
})();
