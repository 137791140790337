(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.AnnuaireService
   * @description
   * # AnnuaireService
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .service('AnnuaireService', AnnuaireServiceCtrl);

  /** @ngInject */
  function AnnuaireServiceCtrl(COLLAB_CONF, $injector, $rootScope, $q, $filter, ErreurCollabService, AnnuaireAjax, GroupeService, PersonnaliteAjax, ContratIcsCloudResource, Groupe, Portefeuille, Metierfournisseur, Contrat, Fiche, Lot, Bail, Inverse, Salarie, UtilsService, ModalsService) {

    var ctrl = this;
    var defaultLimit = COLLAB_CONF.DEFAULT_LIMIT_FICHE;
    var defaultPage = COLLAB_CONF.DEFAULT_PAGE_FICHE;

    ctrl.currentGroupe = false;
    ctrl.ficheCurrentUser = false;
    ctrl.listeContact = [];
    ctrl.listeFiches = [];
    ctrl.listeFichesPerso = [];
    ctrl.listeFichesPartages = [];
    ctrl.listeMetiersFournisseur = [];
    ctrl._poolFiches = {};

    ctrl.init = init;
    ctrl.setAnnuaire = setAnnuaire;
    ctrl.destroy = destroy;
    ctrl.setGroupe = setGroupe;
    ctrl.getGroupe = getGroupe;
    ctrl.callContacts = callContacts;
    ctrl.callAnnuaire = callAnnuaire;
    ctrl.refreshAnnuaire = refreshAnnuaire;
    ctrl.getListeContact = getListeContact;
    ctrl.getListeFiches = getListeFiches;
    ctrl.getListeFichesPerso = getListeFichesPerso;
    ctrl.getListeFichesPartages = getListeFichesPartages;
    ctrl.getFicheForGroupe = getFicheForGroupe;
    ctrl.getAutresFiches = getAutresFiches;
    ctrl.setFiche = setFiche;
    ctrl.newFiche = newFiche;
    ctrl.setListeFichesPerso = setListeFichesPerso;
    ctrl.setListeFichesPartages = setListeFichesPartages;
    ctrl.updateListeContact = updateListeContact;
    ctrl.setFicheCurrentUser = setFicheCurrentUser;
    ctrl.getFicheCurrentUser = getFicheCurrentUser;
    ctrl.getListMetiersFournisseur = getListMetiersFournisseur;
    ctrl.searchPersonnalite = searchPersonnalite;
    ctrl.searchCollaborateur = searchCollaborateur;
    ctrl.searchCoproprietaires = searchCoproprietaires;
    ctrl.getGroupeCollab = getGroupeCollab;
    ctrl.getPerso = getPerso;
    ctrl.searchLots = searchLots;
    ctrl.searchInverses = searchInverses;
    ctrl.searchBaux = searchBaux;
    ctrl.searchContrats = searchContrats;
    ctrl.searchImmeubles = searchImmeubles;
    ctrl.openAnnuaire = openAnnuaire;
    ctrl.searchFournisseurByNum = searchFournisseurByNum;
    ctrl.searchImmeubleByNum = searchImmeubleByNum;
    ctrl.searchCoproprietaireByNoPersoForNumImmeuble = searchCoproprietaireByNoPersoForNumImmeuble;
    ctrl.searchLot = searchLot;
    ctrl.searchMandat = searchMandat;
    ctrl.genereCollaborateur = genereCollaborateur;
    ctrl.getImmeubles = getImmeubles;
    ctrl.triInverses = triInverses;

    function destroy() {
      ctrl.currentGroupe = false;
      ctrl.ficheCurrentUser = false;
      ctrl.listeContact = [];
      ctrl.listeFiches = [];
      ctrl.listeFichesPerso = [];
      ctrl.listeFichesPartages = [];
      ctrl.listeMetiersFournisseur = [];
      ctrl._poolFiches = {};
    }

    function setGroupe(groupe) {
      setFicheCurrentUser(groupe);
      ctrl.currentGroupe = groupe;
    }

    function getGroupe() {
      return ctrl.currentGroupe;
    }

    function setFicheCurrentUser(groupe) {
      var obj = {
        'idFiche': groupe.getUser().getFichePerso().getIdFiche(),
        'idGroupe': groupe.getIdGroupe(),
        'informations': groupe.getUser().getFichePerso().getInformations(),
        'nom': groupe.getUser().getFichePerso().getNom(),
        'user': {
          'id': groupe.getUser().getIdUser(),
          'nomAvatar': groupe.getUser().getNomAvatar()
        }
      };
      ctrl.ficheCurrentUser = new Fiche(obj);
    }

    function getFicheCurrentUser() {
      return ctrl.ficheCurrentUser;
    }

    /**
     * Initialise les variables de fiches
     * @param {Int,Int} page,limit
     * @returns {Bool} Promesse avec un boolean
     */
    function init(page, limit) {
      var deferred = $q.defer();

      callContacts(page, limit).then(function (contacts) {

        setAnnuaire(contacts);

        deferred.resolve(true);
      }, function (msg) {
        deferred.reject(msg);
      });

      return deferred.promise;
    }

    function dedoublonneFiches(overrides) {
      // Parcour les fiches
      angular.forEach(ctrl._poolFiches, function (ficheOne) {

        var idFiche = ficheOne.idFiche;

        // Pour chaque fiche, parcour une deuxième fois les fiches
        angular.forEach(ctrl._poolFiches, function (ficheTwo) {
          // Si la fiche à une fiche pere
          if (ficheTwo.hasFichePere()) {
            var fichePere = ficheTwo.getFichePere();

            // Si l'idFiche de la 1er fiche est trouvé dans une fiche pere, on supprime la 1er fiche
            if (idFiche === fichePere.idFiche) {
              // Si overrides est égale à l'id fiche de la surcouche et que la 1er fiche est trouvé en papa de la surcouche, on supprime la surcouche
              if (overrides === fichePere.idFiche) {
                delete ctrl._poolFiches[ficheTwo.idFiche];
              } else {
                // il y a des infos dans la surfiche
                if (ficheTwo.informations.length) {
                  // Supprime la fiche de base
                  delete ctrl._poolFiches[idFiche];
                } else {
                  // supprime la surcouche
                  delete ctrl._poolFiches[ficheTwo.idFiche];
                }
              }
            }
          }
        });
      });
    }

    function setAnnuaire(contacts) {

      //console.log(contacts);
      if (angular.isArray(contacts)) {


        var listeContact = [];
        angular.forEach(contacts, function (contact) {

          angular.forEach(contact.listeFiches, function (fiche) {
            if (!fiche.isModel) {
              fiche = new Fiche(fiche);
            }
            if (fiche.idGroupe === getGroupe().getIdGroupe()) {
              ctrl._poolFiches[fiche.idFiche] = getFicheCurrentUser();
            } else {
              ctrl._poolFiches[fiche.idFiche] = fiche;
            }
          });

          if (contact.libelle === 'Societe') {
            setListeFichesPartages(ctrl._poolFiches);
          }
          if (contact.libelle === 'Perso') {
            setListeFichesPerso(ctrl._poolFiches);
          }

          // Créée un tableau avec la liste des fiche dédoublonné et un avec la liste des nom de contact dédoublonnée
          if (angular.isArray(contact.listeFiches)) {
            angular.forEach(contact.listeFiches, function (fiche) {

              listeContact.push(fiche.nom);
              //listeFiches.push(new Fiche(fiche));
            });
          }
        });

        // Dédoublonne la liste de nom de contact
        if (listeContact.length) {
          listeContact = $filter('unique')(listeContact);
        }

        // Dédoublonne la liste de fiche
        /*
         var newListeFiches = [];
         console.log(listeFiches);
         if(listeFiches.length) {


         // Parcour la liste des fiches
         angular.forEach(listeFiches,function(ficheOne){

         var idGroupeOne = ficheOne.idGroupe,
         idFicheOne = ficheOne.idFiche,
         ficheOneExist = false;

         // Pour chaque fiche, reparcour la meme liste des fiches
         angular.forEach(newListeFiches,function(ficheTwo,indexFicheTwo){
         // Si la fiche n'est pas une note
         if(!ficheOne.isNote()){

         // Si la fiche existe déjà dans le nouveau tableau
         if(ficheTwo.idGroupe === idGroupeOne){

         ficheOneExist = true;

         // Si la fiche est différente
         if(ficheTwo.idFiche !== idFicheOne){

         // Si la fiche 1 est une fiche perso, remplace la fiche dans le nouveau tableau
         if(ficheOne.isPerso()){
         newListeFiches[indexFicheTwo] = ficheOne;
         }
         }
         }

         }
         });

         // Si la fiche n'existe pas dans le noiuveau tableau on l'ajoute
         if(!ficheOneExist){
         newListeFiches.push(ficheOne);
         }
         });

         //listeFiches = $filter('unique')(listeFiches,'idFiche');
         }*/


        dedoublonneFiches();


        ctrl.listeContact = listeContact;

      } else if (angular.isObject(contacts)) {

        var fiches = [];
        angular.forEach(contacts.listeFiches, function (fiche) {
          if (!fiche.isModel) {
            fiche = new Fiche(fiche);
          }
          fiches.push(fiche);

          if (fiche.idGroupe === getGroupe().getIdGroupe()) {
            ctrl._poolFiches[fiche.idFiche] = getFicheCurrentUser();
          } else {
            ctrl._poolFiches[fiche.idFiche] = fiche;
          }
        });

        if (contacts.libelle === 'Societe') {
          setListeFichesPartages(fiches);
        }
        if (contacts.libelle === 'Perso') {
          setListeFichesPerso(fiches);
        }

        // Créée un tableau avec la liste des fiche dédoublonné et un avec la liste des nom de contact dédoublonnée
        if (angular.isArray(contacts.listeFiches)) {
          angular.forEach(contacts.listeFiches, function (fiche) {
            ctrl.listeContact.push(fiche.nom);
            //ctrl.listeFiches.push(new Fiche(fiche));
          });
        }

        // Dédoublonne la liste de nom de contact
        if (ctrl.listeContact.length) {
          ctrl.listeContact = $filter('unique')(ctrl.listeContact);
        }

        dedoublonneFiches();
      }
    }

    /**
     * Recupère l'annuaire par le biais de la servlet
     * @param {void} type,page,limit
     * @returns {Object} Promesse avec array de fiche
     */
    function callContacts(page, limit) {

      var deferred = $q.defer();

      if (!page) {
        page = defaultPage;
      }
      if (!limit) {
        limit = defaultLimit;
      }

      var params = {
        type: 2,
        page: page,
        limit: limit
      };

      var contacts = [];
      AnnuaireAjax.getAnnuairePerso(params, function (json) {

        // Si Réponse dans annuaire
        if (json.nb !== '0') {
          contacts = json.ListeContacts;
        }

        deferred.resolve(contacts);

      }, function (error) {

        var msgErreur = 'Erreur (' + error.status + ') : ' + error.statusText;
        deferred.reject('Problème avec AnnuaireService.callContacts(), ' + msgErreur);
        $rootScope.$broadcast('dislayError', msgErreur);

      }).$promise.finally(function () {
        contacts = null;
      });
      return deferred.promise;
    }

    /**
     * Recupère l'annuaire par le biais de la servlet
     * @param {void} type,page,limit
     * @returns {Object} Promesse avec array de fiche
     */
    function callAnnuaire(type, page, limit) {

      var deferred = $q.defer();

      if (!type) {
        type = 0;
      }
      if (!page) {
        page = defaultPage;
      }
      if (!limit) {
        limit = defaultLimit;
      }

      var params = {
        type: type,
        page: page,
        limit: limit
      };

      var annuaire = [];

      AnnuaireAjax.getAnnuairePerso(params, function (json) {

        // Si Réponse dans annuaire
        if (json.nb !== '0') {
          annuaire = json.Fiches;
        }

        deferred.resolve(annuaire);

      }, function (error) {

        var msgErreur = 'Erreur (' + error.status + ') : ' + error.statusText;
        deferred.reject('Problème avec AnnuaireService.callAnnuaire(), ' + msgErreur);
        $rootScope.$broadcast('dislayError', msgErreur);

      }).$promise.finally(function () {
        annuaire = null;
      });
      return deferred.promise;
    }

    function refreshAnnuaire() {
      var deferred = $q.defer();
      // Initialise l'annuaire
      init().then(function () {
        deferred.resolve();
      });
      return deferred.promise;
    }

    /**
     * Parcour les fiches perso et partages et crée une liste de contact avec des string
     * @param {void}
     * @returns {Array} Promesse avec simple tableau de contact
     */
    function getListeContact() {
      return ctrl.listeContact;
    }

    /**
     * Recupère la liste des fiches
     * @param {void}
     * @returns {Object} Promesse avec array de fiche
     */
    function getListeFiches() {
      return ctrl._poolFiches;
    }

    /**
     * Recupère la liste des contact perso
     * @param {void}
     * @returns {Object} Promesse avec array de fiche
     */
    function getListeFichesPerso() {
      return ctrl.listeFichePerso;
    }

    /**
     * Recupère la liste des contact partage
     * @param {void}
     * @returns {Object} Promesse avec array de fiche
     */
    function getListeFichesPartages() {
      return ctrl.listeFichesPartages;
    }


    /**
     * Permet de récupérer une fiche dans les contact par rapport à un idGroupe
     * @param groupe
     * @returns {boolean}
     */
    function getFicheForGroupe(obj) {


      var idGroupe = false, idFiche = false, retourFiche = false;

      if (angular.isObject(obj)) {
        if (obj.isGroupe) {
          idGroupe = obj.idGroupe;
        } else if (obj.isFiche) {
          idFiche = obj.idFiche;
        } else {
          if (obj.hasOwnProperty('idGroupe')) {
            idGroupe = obj.idGroupe;
          } else if (obj.hasOwnProperty('idFiche')) {
            idFiche = obj.idFiche;
          }
        }

      } else if (angular.isNumber(obj)) {
        idGroupe = obj;
      }

      if (idGroupe) {
        angular.forEach(ctrl._poolFiches, function (fiche, indexFiche) {
          if (fiche.idGroupe === idGroupe) {
            retourFiche = ctrl._poolFiches[indexFiche];
          }
        });
      } else if (idFiche) {
        if (ctrl._poolFiches.hasOwnProperty(idFiche)) {
          retourFiche = ctrl._poolFiches[idFiche];
        }
      }

      return retourFiche;

    }

    function getAutresFiches() {

      var retourFiche = [];
      var fiches = getListeFiches();
      angular.forEach(fiches, function (fiche) {
        if (fiche.idGroupe === null) {
          retourFiche.push(fiche);
        }
      });

      return retourFiche;
    }

    function setFiche(newFiche, overrides) {

      var deferred = $q.defer();

      if (angular.isObject(newFiche)) {

        var fiche = new Fiche(newFiche);
        if (newFiche.hasOwnProperty('idGroupe')) {
          if (newFiche.idGroupe === getGroupe().getIdGroupe()) {

            getGroupe().getUser().setFichePerso(fiche);
            ctrl._poolFiches[newFiche.idFiche] = fiche;

          } else {
            ctrl._poolFiches[newFiche.idFiche] = fiche;
          }
        } else {
          ctrl._poolFiches[newFiche.idFiche] = fiche;
        }

        if (overrides) {
          dedoublonneFiches(fiche.idFiche);
        } else {
          dedoublonneFiches();
        }


        deferred.resolve();
      }
      // TODO : Si fiche à false alors il faut supprimer la fiche que pour les notes normalement

      return deferred.promise;
    }

    function newFiche(fiche) {
      return new Fiche(fiche);
    }

    /**
     * setListeFichesPerso
     * @desc Set la liste des fiches perso dans une variable
     * @param {Array} fiches - Tableau des fiches
     * @returns {Array} Tableau des fiches perso
     */
    function setListeFichesPerso(fiches) {
      ctrl.listeFichesPerso = fiches;
    }

    /**
     * setListeFichesPartages
     * @desc Set la liste des fiches partagés dans une variable
     * @param {Array} fiches - Tableau des fiches
     * @returns {Array} Tableau des fiches partagés
     */
    function setListeFichesPartages(fiches) {
      ctrl.listeFichesPartages = fiches;
    }

    /**
     * updateListeContact
     * @desc Retourne une liste de contact sans les contact déjà présent dans le paramètre
     * @param {Array} arrayDestinataires - Tableau des destinataires déjà présent
     * @returns {Array} Tableau des destinataires mis à jour
     */
    function updateListeContact(arrayDestinataires) {
      var contacts = getListeContact();

      var newContacts = [];
      if (angular.isArray(contacts)) {

        var dejaDestinataires = angular.copy(arrayDestinataires);

        angular.forEach(contacts, function (contact) {

          var tabTmp = contact.split('#');
          var destinataireExiste = false;

          // Si il y a des destinataires pour la réponse
          if (angular.isArray(dejaDestinataires)) {

            angular.forEach(dejaDestinataires, function (destinataire) {
              // Si le destinataire existe déjà dans le tableau temporaire
              if (angular.equals(destinataire.libelle, tabTmp[0]) && angular.equals(destinataire.dieze, '#' + tabTmp[1])) {
                destinataireExiste = true;
              }
            });
          }

          // Si le destinataire n'existe pas
          if (!destinataireExiste) {
            // Si le contact est différent de l'utilisateur connecté
            if (!UtilsService.isUser(contact)) {
              newContacts.push(contact);
            }
          }
        });
      }

      return newContacts;
    }


    /**
     * Recupère la liste des métiers fournisseur
     * @param val
     * @returns {promise|*|Promise|capability.promise|a.fn.promise}
     */
    function getListMetiersFournisseur(val) {
      var deferred = $q.defer();

      var params = {
        'global': val
      };

      // Si la liste est vide on fait appel à la servlet
      if (!ctrl.listeMetiersFournisseur.length) {
        AnnuaireAjax.getMetiersFournisseur(params, function (json) {

          if (json.nb !== '0') {
            var metiers = [];
            for (var i = 0; i < json.metiers.length; i++) {
              metiers.push(new Metierfournisseur(json.metiers[i]));
            }
            ctrl.listeMetiersFournisseur = metiers;
          }

          deferred.resolve(ctrl.listeMetiersFournisseur);

        }, function (error) {
          ErreurCollabService.erreurServlet(error, 'Erreur avec AnnuaireService.getListMetiersFournisseur()', deferred);
        });

        // Si la liste est rempli on retourne la liste existante
      } else {
        deferred.resolve(ctrl.listeMetiersFournisseur);
      }

      return deferred.promise;
    }


    /**
     * Permet de recupérer le paramètre portefeuille pour la recherche
     * @param portefeuille
     * @returns {IPromise<T>}
     */
    function getParamPortefeuille(portefeuille) {
      var deferred = $q.defer();
      var paramsSearch = {};

      if (portefeuille) {
        if (angular.isObject(portefeuille)) paramsSearch.idPortefeuilles = [portefeuille.idPortefeuille];
        else if (angular.isNumber(portefeuille)) paramsSearch.idPortefeuilles = [portefeuille];
        else if (angular.isString(portefeuille)) paramsSearch.clePortefeuille = portefeuille;
      }
      // Si toujours pas de portefeuille prend le 1er qui vient en mémoire
      if (!paramsSearch.idPortefeuilles && !paramsSearch.clePortefeuille) {
        UtilsService
          .getPortefeuilles()
          .then(function (portefeuilles) {
            if (portefeuilles.length) paramsSearch.idPortefeuilles = [portefeuilles[0].idPortefeuille];
            deferred.resolve(paramsSearch);
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });
      }
      else deferred.resolve(paramsSearch);
      return deferred.promise;
    }

    /**
     * Recherche une personnalité ou fournisseur
     * @param params
     * @returns {IPromise<T>}
     */
    function searchPersonnalite(params) {

      var deferred = $q.defer();

      if (angular.isString(params)) {
        params = {
          'recherche': params
        };
      } else if (angular.isNumber(params)) {
        params = {
          'groupe': params
        };
      }


      PersonnaliteAjax.getPersonnalite(params, function (json) {
        if (json.nb !== '0') {

          var retour = [];
          angular.forEach(json.Portefeuilles, function (portefeuille) {
            retour.push(new Portefeuille(portefeuille));
          });
          deferred.resolve(retour);

        } else {
          deferred.resolve([]);
        }
      }, function (error) {
        ErreurCollabService.erreurServlet(error, 'Erreur avec AnnuaireService.searchPersonnalite()', deferred);
      });

      return deferred.promise;
    }

    /**
     * Recherche un collaborateur parmis Collab et les portefeuille ICS sur SRV1
     * @param params
     * @returns {IPromise<T>}
     */
    function searchCollaborateur(params) {

      var deferred = $q.defer();


      PersonnaliteAjax.getCollaborateur(params, function (json) {

        if (json.nb !== '0') {

          var retour = {
            listePortefeuilles: [],
            listeGroupes: []
          };
          var objectCollaborateur = json.ListeCollaborateurs[0];

          // Liste des Perso ou Fournisseur
          if (objectCollaborateur.hasOwnProperty('listePortefeuilles')) {
            angular.forEach(objectCollaborateur.listePortefeuilles, function (portefeuille) {
              retour.listePortefeuilles.push(new Portefeuille(portefeuille));
            });
          }

          // Liste des Groupes avec et sans User Collab
          if (objectCollaborateur.hasOwnProperty('listeGroupes')) {
            angular.forEach(objectCollaborateur.listeGroupes, function (groupe) {
              retour.listeGroupes.push(new Groupe(groupe));
            });
          }

          deferred.resolve(retour);

        } else {
          deferred.resolve(false);
        }
      }, function (error) {
        ErreurCollabService.erreurServlet(error, 'Erreur avec AnnuaireService.searchCollaborateur()', deferred);
      });

      return deferred.promise;
    }

    /**
     * Recherche les copropriétaire par le biais de la table inverse
     * Il faut tous récupérer pour lié le copro au propriétaire pour avoir
     * les infos comme le lot
     * @param noimme
     * @returns {Promise}
     */
    function searchCoproprietaires(noimme, idportefeuille) {

      var deferred = $q.defer();
      var params = {
        iiii: noimme,
        idPortefeuille: idportefeuille
      };

      PersonnaliteAjax.getInverses(params, function (json) {

        var retour = {
          listeCoproprietaires: [],
          listeSalaries: []
        };

        if (json.nb !== '0') {
          var listeSalarieInverse = json.ListeSalarieCoproprietaires[0];
          //var listeInverseByIno = {};
          var listeCoproprietaireByNoPerso = {};

          if (listeSalarieInverse.listeInverses.length) {
            // Parcour les inverses
            for (var i = 0; i < listeSalarieInverse.listeInverses.length; i++) {
              var instanceInverse = new Inverse(listeSalarieInverse.listeInverses[i]);
              var numPerso = instanceInverse.getNumPerso();
              if (instanceInverse.getType() === 'Coproprietaire') {
                var personnalite = instanceInverse.getPersonnalite();
                if(personnalite) {
                  personnalite.setMails(instanceInverse.getMails());
                  personnalite.setType(instanceInverse.getType());
                  listeCoproprietaireByNoPerso[numPerso] = personnalite;
                }
              }
              /*
               if(!listeInverseByIno.hasOwnProperty(numPerso)){
               listeInverseByIno[numPerso] = [];
               }
               listeInverseByIno[numPerso].push(instanceInverse);*/
            }
            retour.listeCoproprietaires = UtilsService.toArray(listeCoproprietaireByNoPerso);
          }


          if (listeSalarieInverse.listeSalaries.length) {
            // Parcour les salarie
            for (var s = 0; s < listeSalarieInverse.listeSalaries.length; s++) {
              var instanceSalarie = new Salarie(listeSalarieInverse.listeSalaries[s]);

              // Si Gardien
              if (instanceSalarie.getNiveau() === '02') {
                retour.listeSalaries.push(instanceSalarie);
              }

            }
          }


          /*
           angular.forEach(listeInverseByIno, function(inverses) {

           var proprietaire = false;
           var coproprietaire = false;
           for (var inv = 0; inv < inverses.length; inv++) {
           if(inverses[inv].getType() === 'Coproprietaire') {
           coproprietaire = inverses[inv].personnalite;
           listeCoproprietaireByNoPerso[coproprietaire.getNoperso()] = coproprietaire;
           }
           if(inverses[inv].getType() === 'Proprietaire') {
           proprietaire = inverses[inv].personnalite;
           }
           }
           });*/


        }
        deferred.resolve(retour);

      }, function (error) {
        ErreurCollabService.erreurServlet(error, 'Erreur avec AnnuaireService.searchCoproprietaires()', deferred);
      });

      return deferred.promise;
    }

    /**
     * Permet de rechercher un Groupe collab par rapport à un numéro de perso
     * @param val
     * @param idPortefeuille
     * @returns {IPromise<T>}
     */
    function getGroupeCollab(val, idPortefeuille) {
      var deferred = $q.defer();

      var retourObj = {
        groupe: false,
        portefeuille: false
      };
      /*
      var paramsSearch = {
        recherche: '@' + val + '\\_',
        type: 'U',
        usersCollab: 1,
        idPortefeuilles: [idPortefeuille],
        filtreParPortefeuille: true
      };*/

      UtilsService
        .getPortefeuille(idPortefeuille)
        .then(function(portefeuille){
          retourObj.portefeuille = portefeuille;
          GroupeService
            .searchGroupeByLibelleAndPortefeuilleInMySociete(idPortefeuille, val)
            .then(function(groupe){
              retourObj.groupe = groupe;
              deferred.resolve(retourObj);
            })
            .catch(function (msg) {
              deferred.reject(msg);
            });
        })
        .catch(function (msg) {
          deferred.reject(msg);
        });


      /*
      searchCollaborateur(paramsSearch).then(function (retour) {
        if (retour.listePortefeuilles.length) retourObj.portefeuille = retour.listePortefeuilles[0];

        if (retour.listeGroupes.length === 1) {
          retourObj.groupe = retour.listeGroupes[0];
          //deferred.resolve(retour.listeGroupes[0]);
        }
        else if (retour.listeGroupes.length) {
          for (var l = 0; l < retour.listeGroupes.length; l++) {
            retour.listeGroupes[l].portef = retour.listeGroupes[l].libelle.replaceAll('@' + val + '_', '');
          }
          retourObj.groupe = retour.listeGroupes;
          //deferred.resolve(retour.listeGroupes);
        }
        deferred.resolve(retourObj);
      });*/
      return deferred.promise;
    }

    /**
     * Permet de rechercher une Perso par rapport à un numéro de perso
     * @param noPerso
     * @param idPortefeuille
     * @returns {IPromise<T>}
     */
    function getPerso(noPerso, portefeuille) {
      var deferred = $q.defer();

      var retourObj = {
        perso: false,
        portefeuille: false
      };
      /*
      UtilsService
        .getPortefeuille(portefeuille)
        .then(function(portef) {
          retourObj.portefeuille = portef;
          GroupeService
            .searchGroupeByLibelleAndPortefeuilleInMySociete(portef.getIdPortefeuille(), noPerso)
            .then(function(groupe){
              retourObj.perso = groupe;
            })
            .catch(function (msg) {
              deferred.reject(msg);
            });
        })
        .catch(function (msg) {
          deferred.reject(msg);
        });*/

      var paramsSearch = {
        type: 'U',
        avecemail: 0,
        noperso: noPerso
      };

      getParamPortefeuille(portefeuille).then(function (params) {

        paramsSearch = angular.merge(paramsSearch, params);

        searchCollaborateur(paramsSearch).then(function (retour) {
          if (retour.listePortefeuilles.length) {
            var portefeuille = retour.listePortefeuilles[0];

            if (portefeuille.listePersonnalite.length === 1) {
              retourObj.perso = portefeuille.listePersonnalite[0];
            }
            else if (portefeuille.listePersonnalite.length) {
              for (var l = 0; l < portefeuille.listePersonnalite.length; l++) {
                portefeuille.listePersonnalite[l].portef = portefeuille.listePersonnalite[l].libelle.replaceAll('@' + noPerso + '_', '');
              }
              retourObj.perso = portefeuille.listePersonnalite;
            }
            delete portefeuille.listePersonnalite;
            retourObj.portefeuille = portefeuille;

          }
          deferred.resolve(retourObj);
        }).catch(function (msg) {
          deferred.reject(msg);
        });

      }).catch(function (msg) {
        deferred.reject(msg);
      });

      return deferred.promise;
    }

    /**
     * Recherche les lots d'une personnalite propriétaire sur SRV1
     * @param params
     * @returns {IPromise<T>}
     */
    function searchLots(noperpro) {

      var deferred = $q.defer();

      PersonnaliteAjax.getLots({'noperpro': noperpro}, function (json) {

        var retour = {
          listeMandats: [],
          listeLotsParImmeubles: []
        };
        if (json.nb !== '0') {
          var objImmeubles = {};
          // Parcour les lots trouvés
          for (var i = 0; i < json.lots.length; i++) {
            var instanceLot = new Lot(json.lots[i]);
            var nomImmeuble = instanceLot.getImmeuble().getNom();
            if (nomImmeuble) {
              if (!objImmeubles.hasOwnProperty(nomImmeuble)) {
                objImmeubles[nomImmeuble] = {
                  'lots': []
                };
              }
              objImmeubles[nomImmeuble].lots.push(instanceLot);

              // Recupère le mandat pour alimenter la liste des mandats
              var numMandat = instanceLot.getNumMandat();
              if (numMandat !== '') {
                if (retour.listeMandats.indexOf(numMandat) === -1) {
                  retour.listeMandats.push(numMandat);
                }
              }
            }
          }

          // Pour chaque attribut de l'objet "objImmeubles", recrée un tableau d'objet immeuble avec ses lots
          angular.forEach(objImmeubles, function (objImmeuble, keyImmeuble) {
            var objTmp = {
              'nom': keyImmeuble,
              'numero': objImmeuble.lots[0].getNumImmeuble(),
              'lots': objImmeuble.lots
            };
            retour.listeLotsParImmeubles.push(objTmp);
          });
        }
        deferred.resolve(retour);
      }, function (error) {
        ErreurCollabService.erreurServlet(error, 'Erreur avec AnnuaireService.searchLots()', deferred);
      });

      return deferred.promise;
    }

    /**
     * Recherche les inverses d'une personnalite sur SRV1
     * @param params
     * @returns {IPromise<T>}
     */
    function searchInverses(noperso, idportefeuille) {

      var deferred = $q.defer();

      PersonnaliteAjax.getInverses({'noperso': noperso, 'idPortefeuille': idportefeuille}, function (json) {

        var nbPasImmeuble = 0;
        var retour = {
          'Locataire': {
            listeLotsParImmeubles: []

          },
          /*
           'Colocataire': {
           listeLotsParImmeubles: []
           },*/
          'Coproprietaire': {
            listeImmeubles: []
          },
          'Proprietaire': {
            listeMandats: []
          }
        };
        if (json.nb !== '0') {
          var listeByTypePerso = {
            'Locataire': {
              listeLotsParImmeubles: {}
            },
            /*
             'Colocataire': {
             listeLotsParImmeubles: []
             },*/
            'Coproprietaire': {
              listeImmeubles: {}
            },
            'Proprietaire': {
              listeMandats: {}
            }
          };
          // Parcour les lots trouvés
          for (var i = 0; i < json.inverses.length; i++) {
            var instanceInverse = new Inverse(json.inverses[i]);
            var lot = instanceInverse.getLot();
            var immeuble = instanceInverse.getImmeuble();


            if(!_.isObject(lot)) {
              lot = new Lot();
            }

            if (_.isObject(lot) && (!_.isNil(lot.iiii) || (instanceInverse.isLocataire() || instanceInverse.isColocataire()))) {
              lot.setNumMandat(instanceInverse.getNumMandat());
              // Si il y a un typelot dans l'instance de inverse alors on l'ajoute dans le lot
              if (instanceInverse.getTypelot()) {
                lot.setTypelot(instanceInverse.getTypelot());
              }

              // Ajout du type de l'inverse dans le lot (Locataire etc...)
              if (instanceInverse.getType()) {
                lot.setType(instanceInverse.getType());
              }

              if (instanceInverse.getBail()) {
                lot.setBail(instanceInverse.getBail());
              }
            }


            if (immeuble) {

              var numImmeuble = immeuble.getNumImmeuble();
              if (numImmeuble) {

                // Si locataire
                if (instanceInverse.isLocataire() || instanceInverse.isColocataire()) {

                  if (_.isNil(lot.iiii)) {
                    lot.setNumImmeuble(numImmeuble);
                  }

                  if (!_.isNil(lot.iiii)) {
                    var personnalite = instanceInverse.getPersonnalite();
                    if (personnalite) {
                      lot.setProprietaire(personnalite);
                    }

                    // Si pas encore le num de l'immeuble en cle de l'objet des lots par immeuble du locataire
                    if (!listeByTypePerso.Locataire.listeLotsParImmeubles.hasOwnProperty(numImmeuble)) {
                      listeByTypePerso.Locataire.listeLotsParImmeubles[numImmeuble] = {
                        'inverse': instanceInverse,
                        'immeuble': immeuble, 	// Ajout des infos de l'immeuble
                        'lots': []				// Nouveau array de lots
                      };
                    }
                    // Ajoute le lots à la liste des lots de l'immeuble
                    listeByTypePerso.Locataire.listeLotsParImmeubles[numImmeuble].lots.push(lot);
                    retour.isLocataire = true;
                    if (instanceInverse.isColocataire()) {
                      retour.isColocataire = true;
                    }
                  }

                }
                /*
                 // Si locataire
                 else if(instanceInverse.isColocataire()) {

                 var personnaliteProprietaire = instanceInverse.getPersonnalite();
                 if(personnaliteProprietaire){
                 lot.setProprietaire(personnaliteProprietaire);
                 }

                 // Si pas encore le num de l'immeuble en cle de l'objet des lots par immeuble du colocataire
                 if(!listeByTypePerso.Colocataire.listeLotsParImmeubles.hasOwnProperty(numImmeuble)){
                 listeByTypePerso.Colocataire.listeLotsParImmeubles[numImmeuble] = {
                 'inverse':instanceInverse,
                 'immeuble': immeuble, 	// Ajout des infos de l'immeuble
                 'lots': []				// Nouveau array de lots
                 };
                 }
                 // Ajoute le lots à la liste des lots de l'immeuble
                 listeByTypePerso.Colocataire.listeLotsParImmeubles[numImmeuble].lots.push(lot);
                 retour.isLocataire = true;


                 }*/
                // Si copropriétaire
                else if (instanceInverse.isCoproprietaire()) {

                  // Si pas encore le num de l'immeuble en cle de l'objet des lots par immeuble du coproprietaire
                  if (!listeByTypePerso.Coproprietaire.listeImmeubles.hasOwnProperty(numImmeuble)) {
                    listeByTypePerso.Coproprietaire.listeImmeubles[numImmeuble] = {
                      'inverse': instanceInverse,
                      'immeuble': immeuble	// Ajout des infos de l'immeuble
                    };
                  }
                  retour.isCoproprietaire = true;


                }
                // Si proprietaire alors ajout le lot à la liste des lots de l'immeuble
                else if (instanceInverse.isProprietaire()) {

                  // Recupère le mandat pour alimenter la liste des mandats
                  var numMandat = instanceInverse.getNumMandat();
                  if (numMandat !== '' && numMandat !== '0000') {
                    if (!listeByTypePerso.Proprietaire.listeMandats.hasOwnProperty(numMandat)) {
                      listeByTypePerso.Proprietaire.listeMandats[numMandat] = {
                        'inverse': instanceInverse,
                        'num': numMandat,
                        'listeLotsParImmeubles': {}
                      };
                    }

                    // Si pas encore le num de l'immeuble en cle de l'objet des lots par immeuble du proprietaire
                    if (!listeByTypePerso.Proprietaire.listeMandats[numMandat].listeLotsParImmeubles.hasOwnProperty(numImmeuble)) {
                      listeByTypePerso.Proprietaire.listeMandats[numMandat].listeLotsParImmeubles[numImmeuble] = {
                        'immeuble': immeuble,	// Infos de l'immeuble
                        'lots': []				// Nouveau array de lots
                      };
                    }

                    if (_.isObject(lot) && !_.isNil(lot.iiii)) {
                      // Ajoute le lots à la liste des lots de l'immeuble
                      listeByTypePerso.Proprietaire.listeMandats[numMandat].listeLotsParImmeubles[numImmeuble].lots.push(lot);
                    }
                    retour.isProprietaire = true;
                  }
                }
              }
            }
            else {
              if (!immeuble) {
                nbPasImmeuble++;
              }
            }
          }

          angular.forEach(listeByTypePerso, function (objType, typePerso) {

            switch (typePerso) {
              case 'Coproprietaire':
                angular.forEach(objType.listeImmeubles, function (objImmeuble) {
                  retour.Coproprietaire.listeImmeubles.push(objImmeuble);
                });
                break;
              case 'Locataire':
                angular.forEach(objType.listeLotsParImmeubles, function (objImmeuble) {
                  retour.Locataire.listeLotsParImmeubles.push(objImmeuble);
                });
                break;
              /*
               case 'Colocataire':
               angular.forEach(objType.listeLotsParImmeubles,function(objImmeuble){
               retour.Colocataire.listeLotsParImmeubles.push(objImmeuble);
               });
               break;*/
              case 'Proprietaire':
                // Parcour les mandats
                angular.forEach(objType.listeMandats, function (objMandat) {
                  var mandat = {
                    'isMandat': true,
                    'inverse': objMandat.inverse,
                    'num': objMandat.num,
                    'listeLotsParImmeubles': []
                  };
                  // Parcour les immeubles du mandat
                  angular.forEach(objMandat.listeLotsParImmeubles, function (objImmeuble) {
                    mandat.listeLotsParImmeubles.push(objImmeuble);
                  });
                  retour.Proprietaire.listeMandats.push(mandat);
                });
                break;
            }
          });

          // Si tous les retours n'ont pas d'immeuble
          if (nbPasImmeuble === parseInt(json.nb)) {
            retour = false;
          }
        } else {
          retour = false;
        }
        deferred.resolve(retour);
      }, function (error) {
        ErreurCollabService.erreurServlet(error, 'Erreur avec AnnuaireService.searchInverses()', deferred);
      });

      return deferred.promise;
    }

    /**
     * Recherche la liste des baux pour un numéro de mandat, immeuble et lot
     * @param mmmm
     * @param iiii
     * @param llll
     * @param idportefeuille
     * @returns {Promise}
     */
    function searchBaux(mmmm, iiii, llll, idportefeuille) {
      var deferred = $q.defer();

      var retour = {};
      var params = {
        'mmmm': mmmm,
        'iiii': iiii,
        'llll': llll,
        'idPortefeuille': idportefeuille
      };

      PersonnaliteAjax.getBaux(params, function (json) {
        if (json.nb !== '0') {
          var baux = [];
          for (var i = 0; i < json.bails.length; i++) {
            baux.push(new Bail(json.bails[i]));
          }
          retour = baux;
        } else {
          retour = false;
        }
        deferred.resolve(retour);

      }, function (error) {
        ErreurCollabService.erreurServlet(error, 'Erreur avec AnnuaireService.searchBaux()', deferred);
      });

      return deferred.promise;
    }

    /**
     * Recherche la liste des contrats pour un numéro de mandat et/ou immeuble
     * Les contrats seront rangés par métier et les metiers par immeuble
     * @param mmmm
     * @param iiii
     * @param idportefeuille
     * @returns {Promise}
     */
    function searchContrats(mmmm, iiii, idportefeuille, listeMetiers) {
      var deferred = $q.defer();

      var retour = {};
      var params = {
        action: 'searchListContrat',
        idPortefeuille: idportefeuille
      };

      if (mmmm) {
        params.mandat = mmmm;
      }
      if (iiii) {
        params.immeuble = iiii;
      }

      var listeCodeMetier = [];
      if (angular.isArray(listeMetiers) && listeMetiers.length) {
        for (var l = 0; l < listeMetiers.length; l++) {
          listeCodeMetier.push(listeMetiers[l].codemetier);
        }
      }

      ContratIcsCloudResource.getContrats(params, function (json) {
        if (json.nb !== '0') {


          var contratsByImmeuble = [];

          // Parcour les contrats retournés par la servlet
          for (var c = 0; c < json.contrats.length; c++) {
            var contrat = new Contrat(json.contrats[c]);

            var immeubleExist = false;
            var metierExist = false;
            var immeuble, metier, indexMetier;
            // Parcour les immeubles
            for (var i = 0; i < contratsByImmeuble.length; i++) {

              // Si l'immeuble est trouvé
              if (contratsByImmeuble[i].getNumImmeuble() === contrat.getImmeuble().getNumImmeuble()) {
                immeubleExist = true;

                // Parcours les métiers de l'immeuble trouvé
                for (var m = 0; m < contratsByImmeuble[i].metiers.length; m++) {

                  // Si le métier est trouvé dans l'immeuble
                  if (contratsByImmeuble[i].metiers[m].getIdmetier() === contrat.getMetierfournisseur().getIdmetier()) {
                    metierExist = true;

                    // Ajoute le contrat au bon endroit
                    contratsByImmeuble[i].metiers[m].contrats.push(contrat);
                    break;
                  }
                }

                if (!metierExist) {
                  indexMetier = false;
                  if (listeCodeMetier.length) {
                    indexMetier = listeCodeMetier.indexOf(contrat.getMetierfournisseur().getCodemetier());
                  }

                  if (indexMetier !== -1) {
                    metier = angular.copy(contrat.getMetierfournisseur());			// Recup le metier du contrat actuel
                    metier.contrats = [];											// Créé un tableau "contrats" dans le métier
                    metier.contrats.push(contrat);									// Ajoute le contrat dans le nouveau tableau "contrats"
                    contratsByImmeuble[i].metiers.push(metier);						// Ajoute le métier dans l'immeuble
                  }
                }
                break;
              }
            }

            // L'immeuble n'existe pas encore dans le tableau
            if (!immeubleExist) {

              indexMetier = false;
              if (listeCodeMetier.length) {
                indexMetier = listeCodeMetier.indexOf(contrat.getMetierfournisseur().getCodemetier());
              }

              if (indexMetier !== -1) {
                immeuble = angular.copy(contrat.getImmeuble());					// Recup l'immeuble
                metier = angular.copy(contrat.getMetierfournisseur());			// Recup le metier
                metier.contrats = [];											// Créé un tableau "contrats" dans le métier
                metier.contrats.push(contrat);									// Ajoute le contrat dans le nouveau tableau "contrats"
                immeuble.metiers = [];											// Créé un tableau "metiers" dans l'immeuble
                immeuble.metiers.push(metier);									// Ajoute le metier dans le nouveau tableau "metiers"
                contratsByImmeuble.push(immeuble);								// Ajoute l'immeuble dans les contrats par immeuble
              }
            }
          }
          retour = contratsByImmeuble;
        } else {
          retour = false;
        }
        deferred.resolve(retour);

      }, function (error) {
        ErreurCollabService.erreurServlet(error, 'Erreur avec AnnuaireService.searchContrats()', deferred);
      });

      return deferred.promise;
    }

    function searchImmeubles(noperso, idportefeuille) {
      var deferred = $q.defer();
      var immeubles = [];

      searchInverses(noperso, idportefeuille)
        .then(function (objTypePerso) {

          if (objTypePerso) {
            if (objTypePerso.Coproprietaire.listeImmeubles.length) {
              // Parcours les immeubles
              for (var i = 0; i < objTypePerso.Coproprietaire.listeImmeubles.length; i++) {
                immeubles.push({
                  immeuble: objTypePerso.Coproprietaire.listeImmeubles[i].immeuble,
                  nbLots: 0
                });
              }
            }

            if (objTypePerso.Locataire.listeLotsParImmeubles.length) {

              // Parcours les lots par immeubles
              for (var li = 0; li < objTypePerso.Locataire.listeLotsParImmeubles.length; li++) {
                immeubles.push({
                  immeuble: objTypePerso.Locataire.listeLotsParImmeubles[li].immeuble,
                  nbLots: objTypePerso.Locataire.listeLotsParImmeubles[li].lots.length
                });
              }
            }

            // Si il y a des mandats
            if (objTypePerso.Proprietaire.listeMandats.length) {

              // Parcours les mandats
              for (var m = 0; m < objTypePerso.Proprietaire.listeMandats.length; m++) {
                var mandat = objTypePerso.Proprietaire.listeMandats[m];

                // Si le mandat a des lots
                if (mandat.listeLotsParImmeubles.length) {

                  // Parcours les lots par immeubles
                  for (var mi = 0; mi < mandat.listeLotsParImmeubles.length; mi++) {
                    immeubles.push({
                      immeuble: mandat.listeLotsParImmeubles[mi].immeuble,
                      nbLots: mandat.listeLotsParImmeubles[mi].lots.length
                    });
                  }
                }
              }
            }

            // Traitement pour ouvrir les box si un immeuble etc...
            var listeImm = [];

            // Si propriétaire et 1 mandat
            if (objTypePerso.isProprietaire && objTypePerso.Proprietaire.listeMandats.length === 1) {
              objTypePerso.Proprietaire.listeMandats[0].isOpen = true;
              listeImm = objTypePerso.Proprietaire.listeMandats[0].listeLotsParImmeubles;
            }
            // Si locataire
            else if (objTypePerso.isLocataire) {
              listeImm = objTypePerso.Locataire.listeLotsParImmeubles;
            }

            // Si il y a des immeubles
            if (listeImm.length > 1) {
              // Si un immeuble dans la liste alors expand l'accordion
              if (listeImm.length === 1) {
                listeImm[0].isExpanded = true;
              }
              // Si plus d'1 immeuble
              else {
                for (var j = 0; j < listeImm.length; j++) {
                  listeImm[j].isExpanded = false;
                }
              }
            }
            listeImm = null;
          }

          deferred.resolve({
            objTypePerso: objTypePerso,
            immeubles: immeubles
          });
        });

      return deferred.promise;
    }


    function openAnnuaire(obj) {

      var deferred = $q.defer();

      var options = {
        size: 'full',
        windowClass: 'modal-collabore modal-collabore-full',
        backdrop: 'static'
      };

      if (!obj) {
        obj = {};
      }

      obj.onOpened = function () {
        $rootScope.$broadcast('closePopoverDestinataire');
      };

      ModalsService
        .modalComponent('modalAnnuaire', obj, options)
        .then(function (objRetour) {
          deferred.resolve(objRetour);
        })
        .catch(function (msg) {
          deferred.reject(msg);
        });
      /*

       var modalInstance = $uibModal.open({
       animation: true,

       templateUrl: 'app/annuaire/modal-annuaire.html',
       controller: 'ModalAnnuaireCtrl',
       controllerAs: 'modalannuairectrl',
       size: 'full',
       windowClass: 'modal-collabore modal-collabore-full modal-annuaire-collab',
       backdrop: 'static',
       resolve: {
       params: function() {
       return obj;
       }
       }
       });

       modalInstance.opened.then(function() {
       $rootScope.$broadcast('closePopoverDestinataire');
       });

       modalInstance.result.then(function(objRetour) {
       deferred.resolve(objRetour);
       }, function () {
       deferred.reject();
       });*/

      return deferred.promise;
    }


    /**
     * Recup une perso fournisseur par rapport à un numéro fournisseur et un code metier pour un portefeuille
     * @param numFournisseur
     * @param codeMetier
     * @param portefeuille
     * @returns {IPromise<T>}
     */
    function searchFournisseurByNum(numFournisseur, codeMetier, portefeuille) {
      var deferred = $q.defer();

      try {
        var paramsSearch = {
          type: 'F',
          avecemail: 0
        };

        var retour = {
          portefeuille: null,
          perso: null
        };

        // Recup un portefeuille soit par le param soit le 1er du client
        getParamPortefeuille(portefeuille).then(function (params) {
          paramsSearch = angular.merge(paramsSearch, params);

          // Si code metier
          if (angular.isString(codeMetier)) {

            // Recup la liste des metier fournisseur du client
            getListMetiersFournisseur(codeMetier)
              .then(function (listeMetier) {
                var metierFind = null;
                if (listeMetier.length) {
                  for (var i = 0; i < listeMetier.length; i++) {
                    if (listeMetier[i].codemetier === codeMetier) {
                      metierFind = listeMetier[i];
                      break;
                    }
                  }
                }

                // Si pas de metier trouvé
                if (!angular.isObject(metierFind)) throw new Error('Pas metier trouvé');

                paramsSearch.numFournisseur = numFournisseur;
                paramsSearch.codemetier = metierFind.getCodemetier();

                // Recup la liste des fournisseur pour ce metier et ce num fournisseur
                searchCollaborateur(paramsSearch)
                  .then(function (retourCollaborateur) {
                    if (retourCollaborateur.listePortefeuilles.length && retourCollaborateur.listePortefeuilles[0].listePersonnalite.length) {
                      retour.portefeuille = new Portefeuille({
                        idPortefeuille: retourCollaborateur.listePortefeuilles[0].idPortefeuille,
                        nom: retourCollaborateur.listePortefeuilles[0].nom
                      });
                      retour.perso = retourCollaborateur.listePortefeuilles[0].listePersonnalite[0];
                      deferred.resolve(retour);
                    }
                    else {
                      console.error(retourCollaborateur);
                      deferred.reject('Fournisseur introuvable');
                    }
                  })
                  .catch(function (msg) {
                    deferred.reject(msg);
                  });
              })
              .catch(function (msg) {
                deferred.reject(msg);
              });
          }
          else {
            paramsSearch.numFournisseur = numFournisseur;

            // Recup la liste des fournisseur pour ce metier et ce num fournisseur
            searchCollaborateur(paramsSearch)
              .then(function (retourCollaborateur) {
                if (retourCollaborateur.listePortefeuilles.length && retourCollaborateur.listePortefeuilles[0].listePersonnalite.length) {
                  retour.portefeuille = new Portefeuille({
                    idPortefeuille: retourCollaborateur.listePortefeuilles[0].idPortefeuille,
                    nom: retourCollaborateur.listePortefeuilles[0].nom
                  });
                  retour.perso = retourCollaborateur.listePortefeuilles[0].listePersonnalite;
                  deferred.resolve(retour);
                }
                else {
                  console.error(retourCollaborateur);
                  deferred.reject('Fournisseur introuvable');
                }
              })
              .catch(function (msg) {
                deferred.reject(msg);
              });
          }
        })
          .catch(function (msg) {
            deferred.reject(msg);
          });
      }
      catch (err) {
        deferred.reject('Erreur AnnuaireService.searchFournisseurByNum : ' + err);
      }

      return deferred.promise;
    }

    /**
     * Recup un immeuble par rapport à un numéro d'immeuble et un portefeuille
     * @param numImmeuble
     * @param portefeuille
     * @returns {IPromise<T>}
     */
    function searchImmeubleByNum(numImmeuble, portefeuille) {
      var deferred = $q.defer();

      try {
        var paramsSearch = {
          type: 'I',
          avecemail: 0
        };

        var retour = {
          portefeuille: null,
          immeuble: null
        };

        // Recup un portefeuille soit par le param soit le 1er du client
        getParamPortefeuille(portefeuille).then(function (params) {
          paramsSearch = angular.merge(paramsSearch, params);

          // Si pas de numéro immeuble
          if (!numImmeuble) throw new Error('Pas de numéro immeuble');

          paramsSearch.numImmeuble = numImmeuble;

          // Recup la liste des fournisseur pour ce metier et ce num fournisseur
          searchCollaborateur(paramsSearch)
            .then(function (retourCollaborateur) {
              if (retourCollaborateur.listePortefeuilles.length && retourCollaborateur.listePortefeuilles[0].listeImmeuble.length) {
                retour.portefeuille = new Portefeuille({
                  idPortefeuille: retourCollaborateur.listePortefeuilles[0].idPortefeuille,
                  nom: retourCollaborateur.listePortefeuilles[0].nom
                });
                retour.immeuble = retourCollaborateur.listePortefeuilles[0].listeImmeuble[0];
                deferred.resolve(retour);
              }
              else {
                console.error(retourCollaborateur);
                deferred.reject('Immeuble introuvable');
              }
            })
            .catch(function (msg) {
              deferred.reject(msg);
            });
        })
          .catch(function (msg) {
            deferred.reject(msg);
          });
      }
      catch (err) {
        deferred.reject('Erreur AnnuaireService.searchImmeubleByNum : ' + err);
      }

      return deferred.promise;
    }

    /**
     * Recup un coproprietaire, immeuble et portefeuille par rapport à un numéro immeuble, un noperso pour un portefeuille
     * @param numImmeuble
     * @param noPerso
     * @param portefeuille
     * @returns {IPromise<T>}
     */
    function searchCoproprietaireByNoPersoForNumImmeuble(numImmeuble, noPerso, portefeuille) {
      var deferred = $q.defer();

      try {
        var retour = {
          portefeuille: null,
          immeuble: null,
          perso: null
        };

        // Si pas de numéro perso
        if (!noPerso) throw new Error('Pas de numéro perso');

        // Recup l'immeuble pour avoir le portefeuille en entier
        searchImmeubleByNum(numImmeuble, portefeuille)
          .then(function (retImm) {
            if (!angular.isObject(retImm.portefeuille) || !retImm.portefeuille.idPortefeuille) throw new Error('Pas de portefeuille');
            if (!angular.isObject(retImm.immeuble) || !retImm.immeuble) throw new Error('Pas d\'immeuble');

            retour.immeuble = retImm.immeuble;

            // Recup la perso par rapport à son numéro perso et le portefeuille trouvé pour l'immeuble
            getPerso(noPerso, retImm.portefeuille.idPortefeuille)
              .then(function (perso) {

                retour.perso = perso.perso;

                retour.perso.setType('Coproprietaire'); // Set la perso en copro car par dans le retour JSON à cause des arguments.
                retour.portefeuille = perso.portefeuille;
                deferred.resolve(retour);
              })
              .catch(function (err) {
                deferred.reject(err);
              });
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });
      }
      catch (err) {
        deferred.reject('Erreur AnnuaireService.searchCoproprietaireByNoPersoForNumImmeuble : ' + err);
      }

      return deferred.promise;
    }

    /**
     * Permet de trouver un lot par le biais d'
     * @param numImmeuble
     * @param noPerso
     * @param numLot
     * @param numMandat
     * @param portefeuille
     * @returns {IPromise<T>}
     */
    function searchLot(numImmeuble, noPerso, numLot, portefeuille, numMandat, xxxx) {
      var deferred = $q.defer();

      try {
        var retour = {
          portefeuille: null,
          immeuble: null,
          perso: null,
          lot: null,
          bail: null
        };

        getPerso(noPerso, portefeuille)
          .then(function (retPerso) {

            retour.perso = retPerso.perso;
            retour.portefeuille = retPerso.portefeuille;

            if (retour.perso.isLocataire()) retour.locataire = retour.perso;

            searchInverses(noPerso, retPerso.portefeuille.idPortefeuille)
              .then(function (ret) {

                var lot = null;
                if (ret.isLocataire && ret.Locataire.listeLotsParImmeubles.length && (!ret.isProprietaire || _.isNil(xxxx))) {

                  var imm = searchImmInListImmeuble(numImmeuble, ret.Locataire.listeLotsParImmeubles);
                  if(_.isObject(imm)) {
                    retour.immeuble = imm.immeuble;
                    lot = searchLotInListLot(numLot, numMandat, imm.lots);
                    if(_.isObject(lot)) retour.lot = lot;
                  }

                  /*
                  for (var i = 0; i < ret.Locataire.listeLotsParImmeubles.length; i++) {
                    var imm = ret.Locataire.listeLotsParImmeubles[i];
                    if (imm.immeuble.noimme === numImmeuble) {
                      retour.immeuble = imm.immeuble;
                      for (var l = 0; l < imm.lots.length; l++) {
                        var lot = imm.lots[l];
                        if (lot.getNumLot() === numLot) {
                          retour.lot = lot;
                          break;
                        }
                      }
                      break;
                    }
                  }*/

                }
                else if (ret.isProprietaire && ret.Proprietaire.listeMandats.length) {

                  if(!_.isNil(numMandat)) {
                    var mandat = searchMandatInListMandat(numMandat, ret.Proprietaire.listeMandats);
                    if(_.isObject(mandat)) {
                      var imm = searchImmInListImmeuble(numImmeuble, mandat.listeLotsParImmeubles);
                      if(_.isObject(imm)) {
                        retour.immeuble = imm.immeuble;
                        lot = searchLotInListLot(numLot, numMandat, imm.lots);
                        if(_.isObject(lot)) retour.lot = lot;
                      }
                    }
                  }
                  else {
                    if(_.isArray(ret.Proprietaire.listeMandats) && ret.Proprietaire.listeMandats.length) {
                      for (var i = 0; i < ret.Proprietaire.listeMandats.length; i++) {
                        var imm = searchImmInListImmeuble(numImmeuble, ret.Proprietaire.listeMandats[i].listeLotsParImmeubles);
                        if(_.isObject(imm)) {
                          retour.immeuble = imm.immeuble;
                          lot = searchLotInListLot(numLot, numMandat, imm.lots);
                          if(_.isObject(lot)) {
                            retour.lot = lot;
                            break;
                          }
                        }
                      }
                    }
                  }
                }

                if(!_.isNil(xxxx) && !_.isNil(lot)){
                  UtilsService
                    .getPortefeuilles()
                    .then(function (portefeuilles) {
                      if (portefeuilles.length) {
                        var idPortefeuille = null;
                        for(var p = 0; p < portefeuilles.length; p++){
                          if(portefeuilles[p].cle == portefeuille){
                            idPortefeuille = portefeuilles[p].idPortefeuille;
                            break;
                          }
                        }
                        if(!_.isNil(idPortefeuille)){
                          searchBaux(lot.mmmm, lot.iiii, lot.llll, idPortefeuille)
                            .then(function(baux){
                              if(_.isArray(baux) && baux.length) {
                                for(var b = 0; b < baux.length; b++){
                                  if(baux[b].getXXXX() === xxxx){

                                    lot.setProprietaire(retour.perso);
                                    lot.setType("Locataire");

                                    var locataire = baux[b].personnalite;
                                    locataire.setType("Locataire");

                                    retour.locataire = locataire;
                                    retour.bail =  baux[b];

                                    break;
                                  }
                                }
                              }
                              deferred.resolve(retour);
                            })
                            .catch(function (msg) {
                              console.error(msg);
                              deferred.resolve(retour);
                            });
                        }
                        else deferred.resolve(retour);
                      }
                      else deferred.resolve(retour);
                    })
                    .catch(function (msg) {
                      console.error(msg);
                      deferred.resolve(retour);
                    });
                }
                else deferred.resolve(retour);
              })
              .catch(function (msg) {
                deferred.reject(msg);
              });
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });
      }
      catch (err) {
        deferred.reject('Erreur AnnuaireService.searchLot : ' + err);
      }

      return deferred.promise;
    }

    function searchMandatInListMandat(numMandat, listMandat){
      var retour = null;
      if(!_.isNil(numMandat) && _.isArray(listMandat) && listMandat.length) {
        for (var i = 0; i < listMandat.length; i++) {
          var mandat = listMandat[i];
          if (mandat.num === numMandat) {
            retour = mandat;
            break;
          }
        }
      }
      return retour;
    }

    function searchImmInListImmeuble(numImmeuble, listImm){
      var retour = null;
      if(!_.isNil(numImmeuble) && _.isArray(listImm) && listImm.length) {
        for (var i = 0; i < listImm.length; i++) {
          var imm = listImm[i];
          if (imm.immeuble.noimme === numImmeuble) {
            retour = imm;
            break;
          }
        }
      }
      return retour;
    }

    function searchLotInListLot(numLot, numMandat, listLot){
      var retour = null;
      //var onlyLot = null;
      if(!_.isNil(numLot) && _.isArray(listLot) && listLot.length) {
        for (var l = 0; l < listLot.length; l++) {
          var lot = listLot[l];
          // Si  c'est le même numéro de lot
          if (lot.getNumLot() === numLot) {
            // Si on a un numéro de mandat
            if(!_.isNil(numMandat)) {
              //onlyLot = lot; // Garde le lot car si on ne trouve pas le mandat, qu'on garde au moins le lot meme si c'est pas le meme mandat
              // Si on a un numéro de mandat alors lot doit avoir le même
              if(lot.getNumMandat() === numMandat) retour = lot;
            }
            else retour = lot;
          }
          if(!_.isNil(retour)) break;
        }
      }
      //if(!_.isNil(retour) && !_.isNil(onlyLot))  retour = onlyLot;
      return retour;
    }

    /**
     * Recup un objet mandat pour un perso et un num de mandat
     * @param noPerso
     * @param numMandat
     * @param portefeuille
     * @returns {IPromise<T>}
     */
    function searchMandat(noPerso, numMandat, portefeuille) {
      var deferred = $q.defer();

      try {
        var retour = {
          portefeuille: null,
          immeuble: null,
          perso: null,
          mandat: null
        };

        getPerso(noPerso, portefeuille)
          .then(function (retPerso) {

            retour.perso = retPerso.perso;
            retour.portefeuille = retPerso.portefeuille;

            searchInverses(noPerso, retPerso.portefeuille.idPortefeuille)
              .then(function (ret) {
                if (ret.isProprietaire && ret.Proprietaire.listeMandats.length) {
                  for(var m = 0; m < ret.Proprietaire.listeMandats.length; m++) {
                    var mandat = ret.Proprietaire.listeMandats[m];
                    if(mandat.num === numMandat) {
                      retour.mandat = mandat;
                      break;
                    }
                  }
                }
                deferred.resolve(retour);
              })
              .catch(function (msg) {
                deferred.reject(msg);
              });
          })
          .catch(function (msg) {
            deferred.reject(msg);
          });
      }
      catch (err) {
        deferred.reject('Erreur AnnuaireService.searchLot : ' + err);
      }

      return deferred.promise;
    }

    function genereCollaborateur(objet, portefeuille, filtreCollabore, filtreIcs, hotlineActif) {

      var TagService = $injector.get('TagService');

      var collaborateur = null;
      var retour = {
        filtreCollabore: filtreCollabore,
        filtreIcs: filtreIcs
      };

      if (_.isObject(objet)) {
        // Si groupe collab
        if (objet.isGroupe) {
          var groupe = objet;
          collaborateur = {
            isCollab: true,
            isAllowForDestinataire: true,
            avatar: false,
            idGroupe: groupe.getIdGroupe(),
            groupe: groupe,
            isSameGroupe: UtilsService.inSameGroup(groupe),
            nom: groupe.getDefaultNom(),
            prenom: '-',
            libelle: groupe.getLibelle(),
            telephone: '-',
            source: groupe.getDieze(),
            bloque: false,
            oldPortefeuille: groupe.isOld()
          };

          if (groupe.hasOwnProperty('user')) {
            collaborateur.isUser = true;
            collaborateur.codemetier = 'user';
            if (filtreCollabore) retour.filtreCollabore.user.nb++;

            var ficheCollab = groupe.getUser().getFichePerso();

            if (ficheCollab) {
              //collaborateur.popover.data.fiche = ficheCollab;
              var telephone = ficheCollab.getDefaultTelephone();

              collaborateur.groupe.nom = ficheCollab.getDefaultNom();
              collaborateur.nom = ficheCollab.getDefaultNom();
              collaborateur.prenom = ficheCollab.getDefaultPrenom();
              if (telephone) collaborateur.telephone = telephone.getDonnee();
              if (ficheCollab.hasAvatar()) collaborateur.avatar = groupe.getUser().getNomAvatar();
            }

          }
          else {

            var nomGroupe = groupe.getDefaultNom();
            var ficheGroupe = groupe.getFiche();

            if (ficheGroupe) nomGroupe = ficheGroupe.getNomPrenom();

            collaborateur.nom = nomGroupe;
            collaborateur.isGroupe = true;
            collaborateur.codemetier = 'groupe';

            if (filtreCollabore) {
              retour.filtreCollabore.groupe.nb++;
              if (groupe.isImmeuble()) {
                retour.filtreCollabore.immeuble.nb++;
              }
            }
          }
        }
        // Si Immeuble
        else if (objet.isImmeuble) {
          var currentImmeuble = objet;
          collaborateur = {
            isCollaborateur: true,
            isImm: true,
            isAllowForDestinataire: false,
            type: 'immeuble',
            noimme: currentImmeuble.getNumImmeuble(),
            immeuble: currentImmeuble,
            nom: currentImmeuble.getNom(),
            adresse: currentImmeuble.getAdresse(),
            adresse1: currentImmeuble.getAd1(),
            adresse2: currentImmeuble.getAd2(),
            codePostal: currentImmeuble.getAd3(),
            ville: currentImmeuble.getAd4(),
            source: (portefeuille) ? portefeuille.getNom(hotlineActif) : null,
            portefeuille: {
              idPortefeuille: (portefeuille) ? portefeuille.getIdPortefeuille() : null,
              nom: (portefeuille) ? portefeuille.getNom(hotlineActif) : null
            },
            popover: {
              title: currentImmeuble.getNom(),
              template: 'app/popovers/popover-immeuble.html'
            }
          };
        }
        // Si Personnalite
        else if (objet.isPersonnalite) {
          var perso = objet;
          var numero;
          if (perso.isPersoFournisseur) numero = perso.getNumero();
          if (angular.isUndefined(numero) || numero === '' || !numero) numero = perso.getNoperso();

          collaborateur = {
            isCollaborateur: true,
            isPerso: true,
            isProprietaire: false,
            isCopro: false,
            isLocataire: false,
            isColocataire: false,
            isFournisseur: false,
            isSala: false,
            isCaution: false,
            isAssureur: false,
            isPayeur: false,
            isEmployeur: false,
            isICS: false,
            isClient: false,
            isProprietaireMeuble: false,
            isIndivision: false,
            isAllowForDestinataire: false,
            type: 'perso',
            noperso: perso.getNoperso(),
            perso: perso,
            nom: perso.getNom(hotlineActif),
            prenom: numero,
            ville: perso.getVille(),
            libelle: '-',
            telephone: '-',
            source: (portefeuille) ? portefeuille.getNom(hotlineActif) : null,
            codemetier: '-',
            bloque: perso.isBloque(),
            portefeuille: {
              idPortefeuille: (portefeuille) ? portefeuille.getIdPortefeuille() : null,
              nom: (portefeuille) ? portefeuille.getNom(hotlineActif) : null
            },

            popover: {
              title: perso.getNom(hotlineActif),
              template: 'app/popovers/popover-personnalite.html'
            },
            arrayMails: [],
            arrayTelephones: []
          };

          var mails = perso.getMails();
          if (mails.length) {
            collaborateur.libelle = mails[0];
            collaborateur.arrayMails = mails;
            if (UtilsService.isMail(mails[0])) collaborateur.isAllowForDestinataire = true;
          }

          var telephones = perso.getTelephones();
          if (telephones.length) collaborateur.arrayTelephones = _.concat(collaborateur.arrayTelephones, telephones);

          var telephonesFixe = perso.getTelephonesFixe();
          if (telephonesFixe.length) collaborateur.arrayTelephones = _.concat(collaborateur.arrayTelephones, telephonesFixe);

          var telephonesMobile = perso.getTelephonesMobile();
          if (telephonesMobile.length) collaborateur.arrayTelephones = _.concat(collaborateur.arrayTelephones, telephonesMobile);

          if(collaborateur.arrayTelephones.length)collaborateur.telephone = collaborateur.arrayTelephones[0];


          if (perso.isPersoFournisseur) {
            if (perso.getMetier()) {
              collaborateur.codemetier = perso.getMetier();
              collaborateur.isFournisseur = true;
            }
          }
          else {
            // Génération du tag type "Projet"
            collaborateur.tagProjet = TagService.genereTag(perso, 'Projet');
          }

          if (perso.isProprietaire()) {
            collaborateur.isProprietaire = true;
            if (filtreIcs) retour.filtreIcs.proprietaire.nb++;
          }

          if (perso.isCoproprietaire()) {
            collaborateur.isCopro = true;
            if (filtreIcs) retour.filtreIcs.coproprietaire.nb++;
          }

          if (perso.isLocataire()) {
            collaborateur.isLocataire = true;
            if (filtreIcs) retour.filtreIcs.locataire.nb++;
          }

          if (perso.isColocataire()) {
            collaborateur.isColocataire = true;
            if (filtreIcs) retour.filtreIcs.colocataire.nb++;
          }

          if (perso.isFournisseur()) {
            collaborateur.isFournisseur = true;
            if (filtreIcs) retour.filtreIcs.fournisseur.nb++;
          }

          if (perso.isSalarie()) {
            collaborateur.isSala = true;
            if (filtreIcs) retour.filtreIcs.salarie.nb++;
          }

          if (perso.isCaution()) {
            collaborateur.isCaution = true;
            if (filtreIcs) retour.filtreIcs.caution.nb++;
          }

          if (perso.isAssureur()) {
            collaborateur.isAssureur = true;
            if (filtreIcs) retour.filtreIcs.assureur.nb++;
          }
          if (perso.isPayeur()) {
            collaborateur.isPayeur = true;
            if (filtreIcs) retour.filtreIcs.payeur.nb++;
          }
          if (perso.isEmployeur()) {
            collaborateur.isEmployeur = true;
            if (filtreIcs) retour.filtreIcs.employeur.nb++;
          }
          if (perso.isICS()) {
            collaborateur.isICS = true;
            if (filtreIcs) retour.filtreIcs.ics_.nb++;
          }
          if (perso.isClient()) {
            collaborateur.isClient = true;
            if (filtreIcs) retour.filtreIcs.client.nb++;
          }
          if (perso.isProprietaireMeuble()) {
            collaborateur.isProprietaireMeuble = true;
            if (filtreIcs) retour.filtreIcs.proprietairemeuble.nb++;
          }
          if (perso.isIndivision()) {
            collaborateur.isIndivision = true;
            if (filtreIcs) retour.filtreIcs.indivision.nb++;
          }

          if (filtreIcs) retour.filtreIcs.nb++;
        }
        // Si Lot Multicamp
        else if(objet.isLotMulticamp){
          collaborateur = {
            isCollaborateur: true,
            isLotMulticamp: true,
            isAllowForDestinataire: false,
            type: 'lotMulticamp',
            camping: objet.getCamping(),
            lotMulticamp: objet,
            numlot: objet.getPlace_name(),
            categorie: objet.getCategorie(),
            idPerson: objet.getIdPerson(),
            client: objet.getNom(),
            begin: objet.getBegin(),
            end: objet.getEnd(),
            telephones: objet.getTelephones(),
            source: (portefeuille) ? portefeuille.getNom(hotlineActif) : null,
            portefeuille: {
              idPortefeuille: (portefeuille) ? portefeuille.getIdPortefeuille() : null,
              nom: (portefeuille) ? portefeuille.getNom(hotlineActif) : null
            },
            popover: null
          };
        }
        // Si LieuCommuns
        else if(objet.isLieuCommunCapfun){
          collaborateur = {
            isCollaborateur: true,
            isLieuCommunCapfun: true,
            isAllowForDestinataire: false,
            type: 'lieuCommunCapfun',
            camping: objet.getCamping(),
            lieuCommunCapfun: objet,
            lieu: objet.getLieu(),
            source: (portefeuille) ? portefeuille.getNom(hotlineActif) : null,
            portefeuille: {
              idPortefeuille: (portefeuille) ? portefeuille.getIdPortefeuille() : null,
              nom: (portefeuille) ? portefeuille.getNom(hotlineActif) : null
            },
            popover: null
          };
        }
      }

      retour.collaborateur = collaborateur;

      return retour;
    }

    function getImmeubles(noperso, idportefeuille) {
      var deferred = $q.defer();
      var immeubles = [];

      PersonnaliteAjax
        .getInverses({noperso: noperso, idPortefeuille: idportefeuille})
        .$promise
        .then(function onSuccess(json){
          //console.log(json);
          if (json.nb !== '0' && _.isArray(json.inverses) && json.inverses.length) {

            immeubles = triInverses(json.inverses);

            /*
            // Parcour les inverses
            for (var i = 0; i < json.inverses.length; i++) {
                var instanceInverse = new Inverse(json.inverses[i]);
                var immeuble = instanceInverse.getImmeuble();

                if(_.isObject(immeuble) && !_.isNil(immeuble.getNumImmeuble())) {

                    var numImmeuble = immeuble.getNumImmeuble();
                    var iiii = instanceInverse.getNumImmeuble();
                    var mmmm = instanceInverse.getNumMandat();

                    var addImm = false;
                    var currentImmeuble = _.find(immeubles, { 'iiii': iiii, 'mmmm': mmmm });
                    if(_.isNil(currentImmeuble)) {
                        currentImmeuble = {
                            typePerso: (instanceInverse.getType()) ? instanceInverse.getType() : null,
                            mmmm: instanceInverse.getNumMandat(),
                            iiii: instanceInverse.getNumImmeuble(),
                            xxxx: instanceInverse.getNumCoproprietaire(),
                            autre: instanceInverse.getNumAutre(),
                            immeuble: immeuble,
                            personnalite: instanceInverse.getPersonnalite(),
                            lots: []
                        };
                        addImm = true;
                    }

                    var lot = instanceInverse.getLot();
                    if (_.isObject(lot) && !_.isNil(lot.iiii)) {

                        lot.setNumMandat(instanceInverse.getNumMandat());

                        // Si il y a un typelot dans l'instance de inverse alors on l'ajoute dans le lot
                        if (instanceInverse.getTypelot()) {
                            lot.setTypelot(instanceInverse.getTypelot());
                        }

                        // Ajout du type de l'inverse dans le lot (Locataire etc...)
                        if (instanceInverse.getType()) {
                            lot.setType(instanceInverse.getType());
                        }

                        // Ajout du bail dans le lots si existe
                        if (instanceInverse.getBail()) {
                            lot.setBail(instanceInverse.getBail());
                        }
                        currentImmeuble.lots.push(lot);
                    }

                    // Si locataire
                    if (instanceInverse.isLocataire() || instanceInverse.isColocataire()) {

                    }
                    // Si copropriétaire
                    else if (instanceInverse.isCoproprietaire()) {

                    }
                    // Si proprietaire alors ajout le lot à la liste des lots de l'immeuble
                    else if (instanceInverse.isProprietaire()) {

                    }


                    if(addImm) {
                        immeubles.push(currentImmeuble);
                    }
                }


            }*/
          }
          deferred.resolve(immeubles);
        })
        .catch(function onError(error){
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function triInverses(inverses){
      var immeubles = [];
      if (_.isArray(inverses) && inverses.length) {

        // Parcour les inverses
        for (var i = 0; i < inverses.length; i++) {
          var instanceInverse = new Inverse(inverses[i]);
          var immeuble = instanceInverse.getImmeuble();

          if(_.isObject(immeuble) && !_.isNil(immeuble.getNumImmeuble())) {

            var numImmeuble = immeuble.getNumImmeuble();
            var iiii = instanceInverse.getNumImmeuble();
            var mmmm = instanceInverse.getNumMandat();

            var addImm = false;
            var currentImmeuble = _.find(immeubles, { 'iiii': iiii, 'mmmm': mmmm });
            if(_.isNil(currentImmeuble)) {
              currentImmeuble = {
                typePerso: (instanceInverse.getType()) ? instanceInverse.getType() : null,
                mmmm: instanceInverse.getNumMandat(),
                iiii: instanceInverse.getNumImmeuble(),
                xxxx: instanceInverse.getNumCoproprietaire(),
                autre: instanceInverse.getNumAutre(),
                immeuble: immeuble,
                personnalite: instanceInverse.getPersonnalite(),
                lots: []
              };
              addImm = true;
            }


            var lot = instanceInverse.getLot();
            if (_.isObject(lot) && (!_.isNil(lot.getNumImmeuble()) || (instanceInverse.isLocataire() || instanceInverse.isColocataire()))) {

              if(_.isNil(lot.getNumImmeuble())) {
                lot.setNumImmeuble(numImmeuble);
              }

              lot.setNumMandat(instanceInverse.getNumMandat());

              // Si il y a un typelot dans l'instance de inverse alors on l'ajoute dans le lot
              if (instanceInverse.getTypelot()) {
                lot.setTypelot(instanceInverse.getTypelot());
              }

              // Ajout du type de l'inverse dans le lot (Locataire etc...)
              if (instanceInverse.getType()) {
                lot.setType(instanceInverse.getType());
              }

              // Ajout du bail dans le lots si existe
              if (instanceInverse.getBail()) {
                lot.setBail(instanceInverse.getBail());
              }
              var found = false;
              if(currentImmeuble.lots.length) {
                for(var j = 0; j < currentImmeuble.lots.length; j++){
                  if(_.isEqual(lot, currentImmeuble.lots[j])) {
                    found = true;
                    break;
                  }
                }
              }
              if(!found) {
                currentImmeuble.lots.push(lot);
              }
            }

            /*
            // Si locataire
            if (instanceInverse.isLocataire() || instanceInverse.isColocataire()) {

            }
            // Si copropriétaire
            else if (instanceInverse.isCoproprietaire()) {

            }
            // Si proprietaire alors ajout le lot à la liste des lots de l'immeuble
            else if (instanceInverse.isProprietaire()) {

            }*/

            if(addImm) {
              immeubles.push(currentImmeuble);
            }
          }


        }
      }
      return immeubles;
    }
  }
})();
