(function() {

  'use strict';

  /**
   * @desc Composant du choix de contrat
   * @example <choose-contrat></choose-contrat>
   */
  angular
    .module('collaboreApp')
    .component('chooseContrat',{
      templateUrl: 'app/components/choose-contrat/choose-contrat.component.html',
      controllerAs: 'choosecontratctrl',
      bindings: {
        contrats: '<',
        fournisseur: '<',
        immeuble: '<',
        onEmit: '&'
      },
      controller: ChooseFournisseurCtrl
    });

  /** @ngInject */
  function ChooseFournisseurCtrl($scope, $q, $timeout, UtilsService, ContratIcsCloudService, ComfactService){
    var _this = this;
    _this.loading = false;
    _this.selectedContrat = null;
    _this.filtreContrat = null;
    _this.listPortefeuille = [];
    _this.listContrat = [];

    _this.onSelect = onSelect;
    _this.groupeBy = groupeBy;
    _this.onKeyDown = onKeyDown;
    _this.onClickClearContrat = onClickClearContrat;

    var listernerClearSelectedContrat = null, listenerFocus = null;

    _this.$onInit = function() {
      listenerFocus = $scope.$on('focusContrat', function () {
        if(_.isNil(_this.selectedContrat)){
          $timeout(function(){
            _this.focusClear = true;
            $scope.$broadcast('focusSelectContrat');
          });
        }
        else{
          emit({action: 'nextField'});
        }
      });

      listernerClearSelectedContrat = $scope.$on("clearSelectedContrat", function (event) {
        clearContrat();
      });

    };

    _this.$onChanges = function(changes){
      if(_.isObject(changes.contrats)){

        if(_.isArray(changes.contrats.currentValue)){
          var listContrats = [];
          if(_.isArray(_this.contrats) && _this.contrats.length){
            listContrats = _this.contrats.map(function(contrat){
              return ComfactService.initObjetContrat(contrat);
            });
          }
          _this.listContrat = listContrats;

          clearContrat();

          // Si seulement un contrat dans la liste est que l'on a choisi un fournisseur alors on selectionne ce contrat
          if(_this.listContrat.length === 1 && _.isObject(_this.fournisseur)) {
            setSelectedContrat(_this.listContrat[0], true);
          }
          refreshViewSelect();
        }
      }
    };

    _this.$onDestroy = function(){
      if(!_.isNil(listenerFocus)) listenerFocus();
      if(!_.isNil(listernerClearSelectedContrat)) listernerClearSelectedContrat();
    };

    function refreshViewSelect(){
      $timeout(function() {
        var uiSelect = angular.element("[name=chooseContrat]").controller('uiSelect');
        if(!_.isNil(uiSelect)){
          uiSelect.items = _this.listContrat;
          uiSelect.refreshing = true;
        }
      });
    }


    /**
     * Lors de la selection d'un fournisseur
     * @param item
     */
    function onSelect(item){
      setSelectedContrat(item);
    }

    function groupeBy(item){
      var sugg = "Suggestion(s)";

      if(item.numImm == '0000') sugg = "Suggestion(s) comptes généraux";
      else if(_.isObject(_this.immeuble) && !_.isNil(_this.immeuble.libelle)) sugg += " pour l'immeuble " + _this.immeuble.libelle;

      return sugg;
    }

    function setSelectedContrat(obj, onInit){
      _this.selectedContrat = (_.isObject(obj) && !obj.isObjetContrat) ? ComfactService.initObjetContrat(obj) : obj;
      _this.focusClear = false;
      emit({action: 'selectedContrat', next: !onInit, onInit: onInit, contrat: _this.selectedContrat});
    }

    function onKeyDown(event){
      if(event.keyCode === 9){
        if(event.shiftKey) emit({action: 'previousField'});
        else emit({action: 'nextField'});
        _this.focusClear = false;
      }
    }

    function onClickClearContrat(){
      clearContrat();
    }

    function clearContrat(){
      _this.selectedContrat = null;
      _this.filtreContrat = '';
      _this.focusClear = false;
      emit({action: 'selectedContrat', next: false, contrat: null});
      $scope.$broadcast('focusSelectContrat');
    }

    function emit(obj){
      _this.onEmit({obj: obj});
    }

  }
})();
