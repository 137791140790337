(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.Barcode
   * @description
   * # Barcode
   * Service in the collaboreApp.
   */

  angular
    .module('collaboreApp')
    .factory('Barcode', BarcodeModel);

  /** @ngInject */
  function BarcodeModel(Main) {

    function Barcode(data) {
      Main.apply(this, arguments);
      this.hydrate(data);
    }

    Barcode.prototype = {

      model: 'Barcode',
      isBarcode: true,

      // value
      getValue: function(){
        return this.value;
      },
      setValue: function(val){
        this.value = val;
      },

      /**
       * AZTEC
       * CODABAR
       * CODE39
       * CODE93
       * CODE128
       * DATAMATRIX
       * EAN8
       * EAN13
       * ITF
       * MAXICODE
       * PDF417
       * QRCODE
       * RSS14
       * RSSEXPANDED
       * UPCA
       * UPCE
       * @returns {*}
       */
      getType: function(){
        return this.type;
      },
      setType: function(list){
        this.type = list;
      }

    };

    angular.extend(Barcode.prototype, Main.prototype);

    return Barcode;
  }
})();
