/* eslint-disable lodash/prefer-includes */
(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name collaboreApp.Mailbox
   */

  angular
    .module('collaboreApp')
    .factory('Mailbox', MailboxModel);

  /** @ngInject */
  function MailboxModel($sce, Main, Topic, Groupe, UtilsService) {

    function Mailbox(objetData) {
      if (angular.isObject(objetData)) {
        Main.apply(this, arguments);
        this.hydrate(objetData);
      }
    }

    Mailbox.prototype = {

      isModel: true,
      model: 'Mailbox',

      // Id Mailbox
      getIdMailbox: function(){
        return parseInt(this.idMailbox);
      },
      setIdMailbox: function(id){
        this.idMailbox = id;
      },

      // mailType
      getMailType: function(){
        return this.mailType;
      },
      setMailType: function(val){
        this.mailType = val;
      },

      // erreurType
      getErreurType: function(){
        return this.erreurType;
      },
      setErreurType: function(val){
        this.erreurType = val;
      },

      getErreurTypeMessage: function(){
        var msg = null;
        if(!_.isNil(this.erreurType)){
          if(!_.isNil(this.erreurMessage)) msg = this.erreurMessage;
          else{
            switch(this.erreurType){
              case 'EMPTY_SUBJECT':
                  msg = 'Le sujet du message est vide';
                break;
              case 'EMPTY_MESSAGE':
                  msg = 'Le message est vide';
                break;
              case 'AUTO_RESPONSE_SENDER':
                  msg = 'Adresse de l\'expéditeur invalide';
                break;
              case 'MULTI_TOPIC':
                  msg = 'Plusieur dossier trouvé';
                break;
              case 'CONTENU_NOT_FOUND':
                  msg = 'Le message ou la demande de référence est introuvable';
                break;
              case 'CONTENU_NOT_FOUND':
                  msg = 'Le message ou la demande de référence n\'est pas dans le dossier trouvé';
                break;
              case 'TOPIC_NOT_FOUND':
                  msg = 'La référence du dossier n\'a pas été trouvée. Ou la référence trouvé correspond à aucun dossier.';
                break;
              case 'TOPIC_NOT_FROM_DIESE':
                  msg = 'La référence du dossier ne corréspond pas à l\'agence';
                break;
                default:
                  msg = 'L\'erreur est inconnue';
            }
          }
        }
        return msg;
      },

      // erreurMessage
      getErreurMessage: function(){
        return this.erreurMessage;
      },
      setErreurMessage: function(val){
        this.erreurMessage = val;
      },

      // lastErreurProcessed
      getLastErreurProcessed: function(){
        return this.lastErreurProcessed;
      },
      setLastErreurProcessed: function(val){
        this.lastErreurProcessed = val;
      },

      // nameExpediteur
      getNameExpediteur: function(){
        return this.nameExpediteur;
      },
      setNameExpediteur: function(val){
        this.nameExpediteur = val;
      },

      // emailExpediteur
      getEmailExpediteur: function(){
        return this.emailExpediteur;
      },
      setEmailExpediteur: function(val){
        this.emailExpediteur = val;
      },

      // libelleExpediteur
      getLibelleExpediteur: function(){
        return this.libelleExpediteur;
      },
      setLibelleExpediteur: function(val){
        this.libelleExpediteur = val;
      },

      // emailDestinataire
      getEmailDestinataire: function(){
        return this.emailDestinataire;
      },
      setEmailDestinataire: function(val){
        this.emailDestinataire = val;
      },

      // Date de création
      getDateCreation: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateCreation, format);
        }
        return this.dateCreation;
      },
      getDateCreationAgo: function(){
        var retour = this.dateCreation;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },
      setDateCreation: function(date){
        this.dateCreation = date;
        this.setDateCreationFormated(date);
      },

      // dateCreationFormated
      getDateCreationFormated: function(){
        return this.dateCreationFormated;
      },
      setDateCreationFormated: function(date){
        this.dateCreationFormated = UtilsService.dateParser(date);
      },

      // Date de lecture
      getDateLecture: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateLecture, format);
        }
        return this.dateLecture;
      },
      getDateLectureAgo: function(){
        var retour = this.dateLecture;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },
      setDateLecture: function(date){
        this.dateLecture = date;
        this.setDateLectureFormated(date);
      },

      // dateLectureFormated
      getDateLectureFormated: function(){
        return this.dateLectureFormated;
      },
      setDateLectureFormated: function(date){
        this.dateLectureFormated = UtilsService.dateParser(date);
      },

      // Date de suppression
      getDateSuppression: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.dateSuppression, format);
        }
        return this.dateSuppression;
      },
      getDateSuppressionAgo: function(){
        var retour = this.dateSuppression;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },
      setDateSuppression: function(date){
        this.dateSuppression = date;
        this.setDateSuppressionFormated(date);

        var paramDateMoment = moment(date);

        if(paramDateMoment.isValid()) {
          // Si aujourd'hui retourne juste l'heure
          if(moment().format('MDYYYY') === paramDateMoment.format('MDYYYY')) this.setLibelleDateSuppressionFormated('Supprimé à ' + this.dateSuppressionFormated);
          // Sinon retourne le jour et l'heure
          else this.setLibelleDateSuppressionFormated('Supprimé le ' + this.dateSuppressionFormated);
        }
      },

      // dateSuppressionFormated
      getDateSuppressionFormated: function(){
        return this.dateSuppressionFormated;
      },
      setDateSuppressionFormated: function(date){
        this.dateSuppressionFormated = UtilsService.dateParser(date);
      },

      // libelleDateSuppressionFormated
      getLibelleDateSuppressionFormated: function(){
        return this.libelleDateSuppressionFormated;
      },
      setLibelleDateSuppressionFormated: function(libelle){
        this.libelleDateSuppressionFormated = libelle;
      },

      // Dernière date de traitement
      getLastDateProcessed: function(format){
        if(!_.isNil(format)) {
          if(!_.isString(format) && format){
            format = null;
          }
          return UtilsService.parseDate(this.lastDateProcessed, format);
        }
        return this.lastDateProcessed;
      },
      getLastDateProcessedAgo: function(){
        var retour = this.lastDateProcessed;
        if(retour) retour = moment.duration(moment(new Date()).diff(retour)).humanize();
        return retour;
      },
      setLastDateProcessed: function(date){
        this.lastDateProcessed = date;
        this.setLastDateProcessedFormated(date);
      },

      getLastDateProcessedFormated: function(){
        return this.lastDateProcessedFormated;
      },
      setLastDateProcessedFormated: function(date){
        this.lastDateProcessedFormated = UtilsService.dateParser(date);
      },

      // sujet
      getSujet: function(){
        return this.sujet;
      },
      setSujet: function(val){
        this.sujet = val;
      },

      // message
      getMessage: function(){
        return this.message;
      },
      setMessage: function(val){
        this.message = val;
      },
      getTrustAsHtmlMessage: function(){
        return $sce.trustAsHtml(this.message);
      },


      hasUnsafeImg: function(){
        var patt = new RegExp('unsafe-src', 'ig');
        return patt.test(this.message);
      },

      // fullMessage
      getFullMessage: function(){
        return this.fullMessage;
      },
      setFullMessage: function(val){
        this.fullMessage = val;
      },

      // Pièces jointes
      getAttachements: function() {
        return this.attachements;
      },
      setAttachements: function(liste) {
        var listeDocument = [];
        if(angular.isArray(liste) && liste.length) {
          var Document = Main.$injector.get('Document');
            for(var i = 0; i < liste.length; i++){
                if(angular.isObject(liste[i]) && !liste[i].isModel){
                    liste[i] = new Document(liste[i]);
                }
                listeDocument.push(liste[i]);
            }
        }
        this.attachements = listeDocument;
      },

      // idTopicFound
      getIdTopicFound: function(){
        return this.idTopicFound;
      },
      setIdTopicFound: function(val){
        this.idTopicFound = val;
      },

      // idContenuFound
      getIdContenuFound: function(){
        return this.idContenuFound;
      },
      setIdContenuFound: function(val){
        this.idContenuFound = val;
      },

      // groupeDestinataire
      getGroupeDestinataire: function(){
        return this.groupeDestinataire;
      },
      setGroupeDestinataire: function(groupe){
        var instanceGroupe = groupe;
        if(_.isObject(groupe) && !groupe.hasOwnProperty('isModel')){
          instanceGroupe = new Groupe(groupe);
        }
        this.groupeDestinataire = instanceGroupe;
      },
      getLibelleGroupeDestinataire: function(){
        var libelle = null;
        if(_.isObject(this.getGroupeDestinataire())){
          if(this.getGroupeDestinataire().getLibelle().toLowerCase() === '@all'){
            libelle = this.getGroupeDestinataire().getDieze();
          }
          else{
            libelle = this.getGroupeDestinataire().getLibelleDieze();
          }
        }
        return libelle;
      },

      // titreTopic
      getTitreTopic: function(){
        return this.titreTopic;
      },
      setTitreTopic: function(val){
        this.titreTopic = val;
      },

      // contenuReference
      getContenuReference: function(){
        return this.contenuReference;
      },
      setContenuReference: function(val){
        if(_.isObject(val) && !val.isModel) val = Topic.initContenu(val);
        this.contenuReference = val;
      },

      isLu: function(){
        return !_.isNil(this.dateLecture);
      },

      isDeleted: function(){
        return !_.isNil(this.dateSuppression);
      },

      /**
       * Peut être retraité si il a un type de mail et pas d'erreur
       * @returns
       */
      allowedToProcessed: function(){
        var listErreurAllowed = [
          'CONTENU_NOT_IN_TOPIC',
          'CONTENU_NOT_FOUND',
          'EMPTY_MESSAGE'
        ];

        //return !this.isDeleted() && ((!_.isNil(this.mailType) && _.isNil(this.erreurType)) || listErreurAllowed.indexOf(this.erreurType) !== -1);
        //return !_.isNil(this.idTopicFound) && ((!_.isNil(this.mailType) && _.isNil(this.erreurType)) || listErreurAllowed.indexOf(this.erreurType) !== -1);
        return !_.isNil(this.idTopicFound) && ((!_.isNil(this.mailType) && _.isNil(this.erreurType)) || (_.isNil(this.mailType) && _.isNil(this.erreurType)) || listErreurAllowed.indexOf(this.erreurType) !== -1);
      },

      allowedToChangeIdTopic: function(){
        var listErreurAllowedChangeIdTopic = [
          'EMPTY_SUBJECT',
          'EMPTY_MESSAGE',
          'TOPIC_NOT_FOUND',
          'MULTI_TOPIC',
          'CONTENU_NOT_FOUND',
          'CONTENU_NOT_IN_TOPIC',
          'TOPIC_NOT_FROM_DIESE'
        ];
        //return !this.isDeleted() && listErreurAllowedChangeIdTopic.indexOf(this.erreurType) !== -1;
        return listErreurAllowedChangeIdTopic.indexOf(this.erreurType) !== -1;
      },

      allowedToDelete: function(){
        return !this.isDeleted();
      }

    };

    angular.extend(Mailbox.prototype, Main.prototype);

    /*
      EMPTY_SUBJECT,
      AUTO_RESPONSE_SENDER,
      TOPIC_NOT_FOUND,
      EMPTY_MESSAGE,
      MULTI_TOPIC,
      CONTENU_NOT_FOUND,
      CONTENU_NOT_IN_TOPIC,
      TOPIC_NOT_FROM_DIESE
    */

    return Mailbox;
  }
})();
