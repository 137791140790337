(function() {

  'use strict';

  /**
   * @desc Composant gerer mes groupes
   * @example <admin-gestion-des-groupes-portefeuille></admin-gestion-des-groupes-portefeuille>
   */
  angular
    .module('collaboreApp')
    .component('adminGestionDesGroupesPortefeuille',{
      bindings: {
        portefeuilles: '<'
      },
      templateUrl: 'app/parametrage/admin/gestion-des-groupes/admin-gestion-des-groupes-portefeuille.html',
      controllerAs: 'admingestiondesgroupesportefeuillectrl',
      controller: AdminGestionDesGroupesPortefeuilleCtrl
    });


  /** @ngInject */
  function AdminGestionDesGroupesPortefeuilleCtrl($rootScope,COLLAB_CONF,sweet,ErreurCollabService,GroupesAdminService,UtilsService){
    var ctrl = this;

    ctrl.currentPortefeuilleEdit = {};

    ctrl.search = '';
    ctrl.listePortefeuille = [];
    ctrl.onSubmitFilterPortefeuille = onSubmitFilterPortefeuille;

    ctrl.startDragPortefeuille = startDragPortefeuille;
    ctrl.onSubmitPortefeuille = onSubmitPortefeuille;
    ctrl.onClickAddPortefeuille = onClickAddPortefeuille;
    ctrl.onClickCancelPortefeuille = onClickCancelPortefeuille;
    ctrl.onClickEditPortefeuille = onClickEditPortefeuille;
    ctrl.onClickDeletePortefeuille = onClickDeletePortefeuille;

    ctrl.loading = {
      'show': false,
      'content': COLLAB_CONF.MIDDLE_LOADING
    };

    ctrl.$onInit = function(){

      if(angular.isUndefined(ctrl.portefeuilles)){
        ctrl.loading.show = true;
        GroupesAdminService.getAllPortefeuilles().then(function(portefeuilles){
          ctrl.portefeuilles = portefeuilles;
          ctrl.listePortefeuille = portefeuilles;
        }).finally(function(){
          ctrl.loading.show = false;
        });
      }
    };


    function onSubmitFilterPortefeuille(){
      ctrl.listePortefeuille = UtilsService.fuzzyBy(ctrl.portefeuilles,'nom',ctrl.search);
    }

    function showFormPortefeuille(){
      ctrl.showFormPortefeuille = true;
    }

    function hideFormPortefeuille(){
      ctrl.showFormPortefeuille = false;
      ctrl.currentPortefeuilleEdit = {};
    }

    /**
     * Lors du start d'un drag and drop d'un portefeuille
     */
    function startDragPortefeuille(event, ui) {
      angular.element(ui.helper).addClass('ui-draggable-portefeuille-helper');
    }

    /**
     * Lors de la soummission du formulaire d'ajout/update de portefeuille
     */
    function onSubmitPortefeuille(){


      // Si update
      if(ctrl.currentPortefeuilleEdit.idPortefeuille){

        ctrl.loading.show = true;
        GroupesAdminService.updatePortefeuille(ctrl.currentPortefeuilleEdit).then(function(portefeuille){
          for(var i = 0 ; i < ctrl.portefeuilles.length ; i++){
            if(ctrl.portefeuilles[i].getIdPortefeuille()===portefeuille.getIdPortefeuille()){
              ctrl.portefeuilles[i] = portefeuille;
            }
          }
          hideFormPortefeuille();

          $rootScope.$broadcast('updatePortefeuille',portefeuille);

          //updatePortefeuilleInHierarchie(GroupesAdminService.hierarchieGroupe.getGroupes(),portefeuille);
        },function(msg){
          ErreurCollabService.alert(msg);
        }).finally(function(){
          ctrl.loading.show = false;
        });
        // Si ajout
      }else{
        ctrl.loading.show = true;
        GroupesAdminService.addPortefeuille(ctrl.currentPortefeuilleEdit).then(function(portefeuilles){
          ctrl.portefeuilles = portefeuilles;
          hideFormPortefeuille();
        },function(msg){
          ErreurCollabService.alert(msg);
        }).finally(function(){
          ctrl.loading.show = false;
        });
      }


    }


    /**
     * Lors du clique sur le bouton "Ajouter"
     */
    function onClickAddPortefeuille(){
      showFormPortefeuille();
    }

    /**
     * Lors du clique sur le bouton "Annuler"
     */
    function onClickCancelPortefeuille(){
      hideFormPortefeuille();
    }

    /**
     * Lors du clique sur le bouton "Editer" du portefeuille
     * @param portefeuille
     */
    function onClickEditPortefeuille(portefeuille){
      ctrl.currentPortefeuilleEdit = angular.copy(portefeuille);
      showFormPortefeuille();
    }

    /**
     * Lors du clique sur le bouton suppression d'un portefeuille
     * @param portefeuille
     */
    function onClickDeletePortefeuille(portefeuille){
      var texte = 'Voulez-vous vraiment supprimer le portefeuille :<br /><strong>'+portefeuille.nom+'</strong><br />'+portefeuille.cle;
      sweet.show({
        title: 'Supprimer un portefeuille',
        text: texte,
        type: 'warning',
        html:true,
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Oui !',
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      },function(isConfirm){
        if(isConfirm){
          //console.log('ctrl.portefeuilles',ctrl.portefeuilles);
          GroupesAdminService.deletePortefeuille(portefeuille).then(function(){
            for(var i = 0 ; i < ctrl.portefeuilles.length ; i++){
              if(ctrl.portefeuilles[i].getIdPortefeuille()===portefeuille.getIdPortefeuille()){
                //console.log('i',i);
                //console.log('ctrl.portefeuilles[i]',ctrl.portefeuilles[i]);
                ctrl.portefeuilles.splice(i,1);
              }
            }
            $rootScope.$broadcast('removePortefeuille',portefeuille.getIdPortefeuille());
            sweet.close();
          });
        }else{
          sweet.close();
        }
      });
    }



  }
})();
