(function () {
  'use strict';

  /**
   * @desc Permet d'afficher un contenu de type evenement
   * @example <contenu-evenement contenu="" id-topic=""></contenu-evenement>
   */

  angular
    .module('collaboreApp')
    .component('contenuEvenement',{
      require: {
        layoutTopicDetailCtrl: '?^layoutTopicDetail'
      },
      templateUrl: 'app/topics/contenu-evenement.component.html',
      bindings : {
        evenement: '<',	// <Object> Objet contenu Evenement
        idTopic: '@',	// <Number> Id topic du contenu
        categorieTopic: '<',
        topicInformations: '<',
        onUpdate: '&',
        onClickCommunication: '&',
        onEmit: '&'
      },
      controllerAs: 'contenuevenementctrl',
      controller: ContenuEvenementController
    });

  /*@ngInject*/
  function ContenuEvenementController($log, $scope, $rootScope, $timeout, UtilsService, ErreurCollabService, TopicManagerService){
    var ctrl = this;
    ctrl.notMobile = UtilsService.notMobile;

    ctrl.parseObjetMessage = UtilsService.parseObjetMessage;	// Permet de garder les attributs style du HTML, sinon il sont supprim�
    ctrl.ajouterMessage = ajouterMessage;
    ctrl.onUpdateContenuEvenement = onUpdateContenuEvenement;
    ctrl.onInsertContenuEvenement = onInsertContenuEvenement;
    //ctrl.openCommunication = openCommunication;				// Ouvre la box communication
    ctrl.allowEditEvenement = allowEditEvenement;
    ctrl.onEmitFromListLigneDemandeFournisseur = onEmitFromListLigneDemandeFournisseur;
    ctrl.onEmitFromFormulaireMessage = onEmitFromFormulaireMessage;

    ctrl.$onInit = function() {
      if(angular.isDefined(ctrl.idTopic)){
        ctrl.idTopic = parseInt(ctrl.idTopic);
        ctrl.listTopicRef = TopicManagerService.getListTopicRefFromCurrentTopic();
        ctrl.topicIsBlocked = TopicManagerService.isBlocked;
        /*
        if(listTopicRef.length) {
          ctrl.createurTopicRef = topicRef.getGroupeCreateur();
          ctrl.titreTopicRef = topicRef.getTitre();
          ctrl.evenementFromtTopicRef = topicRef.getContenuEvenement();
        }*/
        if($rootScope.isCapfun) ctrl.isEventIntervention = TopicManagerService.currentEventIsIntervention();
      }

      updateNbDemandes();
    };

    ctrl.$onChanges = function(changes) {
      if(_.isObject(changes.categorieTopic) && !changes.categorieTopic.isFirstChange()) {
        ctrl.categorieTopic = changes.categorieTopic.currentValue;
      }
      if(_.isObject(changes.evenement) && !changes.evenement.isFirstChange()) {
        ctrl.evenement = changes.evenement.currentValue;
      }
    };

    /**
     * Update le nombre de demandes
     */
    function updateNbDemandes() {
      if (ctrl.evenement && (ctrl.evenement.listeDevis.length || ctrl.evenement.listeContrats.length)) {

        var arrayContrats = ctrl.evenement.listeContrats;
        var arrayDevis = ctrl.evenement.listeDevis;
        var listeDevis = [];
        var listeIntervention = [];
        if(arrayDevis.length){
          for (var d = 0; d < arrayDevis.length; d++) {
            if (arrayDevis[d].demandeDevisActive) listeDevis.push(arrayDevis[d]);
            else listeIntervention.push(arrayDevis[d]);
          }
        }

        ctrl.nbContrats = arrayContrats.length;
        ctrl.nbDevis = listeDevis.length;
        ctrl.nbInterventions = listeIntervention.length;
        ctrl.nbDevisInterventions = listeDevis.length + listeIntervention.length;

        var nbDemandesEnAttentes = 0,
          nbDemandesAcceptes = 0,
          nbDemandesRefuses = 0;

        if(arrayDevis.length){
          for (var i = 0; i < listeDevis.length; i++) {
            if (listeDevis[i].isWaiting()) nbDemandesEnAttentes++;
            if (listeDevis[i].isAccepte()) nbDemandesAcceptes++;
            if (listeDevis[i].isRefuse()) nbDemandesRefuses++;
          }
        }

        if(arrayContrats.length){
          for (var c = 0; c < arrayContrats.length; c++) {
            if (arrayContrats[c].isWaiting()) nbDemandesEnAttentes++;
            if (arrayContrats[c].isAccepte()) nbDemandesAcceptes++;
            if (arrayContrats[c].isRefuse()) nbDemandesRefuses++;
          }
        }
        ctrl.nbDemandesEnAttentes = nbDemandesEnAttentes;
        ctrl.nbDemandesAcceptes = nbDemandesAcceptes;
        ctrl.nbDemandesRefuses = nbDemandesRefuses;
      }
    }

    function ajouterMessage(){
      // Permet d'ouvrir et fermer le formulaire
      ctrl.openFormMessage = true;
      // Scroll la page vers le fomulaire de réponse
      UtilsService.scrollToFormReponse(ctrl.evenement);
      /*
      ctrl.evenement.nouveau = true;
      if(!ctrl.openFormMessage) {

        // Initialise la liste des destinataires dans une varable qui sera utilisé dans la directive du formulaire
        ctrl.evenement.reponseDestinataires = angular.copy(ctrl.evenement.destinataires);

        // Ouvre le formulaire
        ctrl.evenement.repondreAllOpen = true;

        // Scroll la page vers le fomulaire de réponse
        UtilsService.scrollToFormReponse(ctrl.evenement);

      }else{
        //stopAutoSave(itemContenu);

        // Ferme le formulaire
        ctrl.evenement.repondreAllOpen = false;

        $rootScope.destroyInstandeCKEDITOR(ctrl.evenement);
      }*/
    }

    /**
     * Ferme le formulaire de réponse
     * @param item
     */
    function closeReply(contenu){
      ctrl.openFormMessage = false;
      $rootScope.destroyInstandeCKEDITOR(contenu);
    }


    function updateEvenement(event){
      ctrl.evenement = event;

      // Fait remonter le contenu evenement dans le parent
      ctrl.onUpdate({objUpdate:{contenu:event}});
    }

    function onUpdateContenuEvenement(objUpdate){

      if(objUpdate.hasOwnProperty('contenu')){
        objUpdate.contenu.open = true;
        updateEvenement(objUpdate.contenu);
      }

      if(objUpdate.hasOwnProperty('listeDocument')){
        ctrl.evenement.listeDocument = objUpdate.listeDocument;
      }

      if(objUpdate.hasOwnProperty('addTag') || objUpdate.hasOwnProperty('deleteTagFromContenu') || objUpdate.hasOwnProperty('deleteTagsFromContenu')){
        ctrl.onUpdate({objUpdate:objUpdate});
      }

      if(objUpdate.hasOwnProperty('message')){
        // Ajoute le message à la page détail
        ctrl.layoutTopicDetailCtrl.addContenuMessage(objUpdate.message,true);
      }
    }

    function onInsertContenuEvenement(){
    }


    /*
    function openCommunication(){
      if(ctrl.onClickCommunication){
        ctrl.onClickCommunication();
      }
    }*/

    /**
     * Permet de savoir si l'événement est éditable par l'utilisateur
     * @returns {boolean}
     */
    function allowEditEvenement() {
      if (ctrl.evenement.isModifiable()) return true;
      return false;
    }

    /**
     * Lorsque l'on reçoit une action du composant liste-ligne-demande-fournisseur
     * @param obj
     */
    function onEmitFromListLigneDemandeFournisseur(obj){
      try {
        if(!_.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');
        if(_.isNil(obj.action) && _.isNil(obj.actions)) throw new Error('Pas de propriété "action" ou "actions" dans le paramètre obj');

        if(obj.emitToParent) emit(obj);
        else {
          if(_.isObject(obj.actions)) {
            if(!_.isNil(obj.actions.updateEvenement)) updateEvenement(obj.actions.updateEvenement);
            if(!_.isNil(obj.actions.addMessages)) emit({action : 'addMessages', messages: obj.actions.addMessages });
          }
          else {
            if(obj.action === 'onClickCommunication') ctrl.onClickCommunication({obj: {devis: obj.devis}});
            else if(obj.action === 'onClickAjouterDemande' || obj.action === 'onClickAjouterDemandeContrat' || obj.action === 'onClickVoirDemande') emit(obj);
            else if(obj.action === 'addOrUpdateDemande') emit(obj);
          }
        }
      }
      catch(err) {
        ErreurCollabService.logErreur('[ContenuEvenement.onEmitFromListLigneDemandeFournisseur] - ' + err.message);
      }

    }

    /**
     * Lorsque le formulaire de message remonte une information
     * @param obj
     */
    function onEmitFromFormulaireMessage(obj) {
      try {
        if (!_.isObject(obj)) throw new Error('Le paramètre "obj" doit être un objet');
        if (!obj.hasOwnProperty('action'))throw new Error('La propriété "action" n\'existe pas');

        if(obj.action === 'closeReply') closeReply(obj.contenu);
        if(obj.action === 'addMessage' && _.isObject(obj.contenu)) {

          emit({action: obj.action, contenu: obj.contenu});
          /*
          if(obj.contenu.insertContenu){

            // Si composant appelé depuis la page detail
            if(ctrl.layoutTopicDetailCtrl){

              // Close le formulaire de réponse
              ctrl.layoutTopicDetailCtrl.openContenu(obj.contenu.insertContenu);

              // Ajoute le message à la page détail
              ctrl.layoutTopicDetailCtrl.addContenuMessage(obj.contenu.insertContenu,true);

            }
            $timeout(function(){
              UtilsService.scrollToContenu(obj.contenu.insertContenu);
            });

          }*/
        }
      }
      catch (err) {
        $log.log('[ContenuEvenementCtrl.onEmitFromFormulaireMessage] Erreur : ' + err.message);
      }
    }

    /**
     * Permet d'emit vers composant parent
     * @param objRetour
     */
    function emit(objRetour){
      ctrl.onEmit({obj: objRetour});
    }
  }
})();
